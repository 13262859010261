import { useContext } from 'react';
import { toast } from 'react-toastify';
import { AuthContext } from '../../Contexts/AuthContext';
import { logoutWithAccessToken } from '../../Contexts/helperFunctions';

export const useLogout = () => {
    const { authData, updateToken } = useContext(AuthContext);

    // setAnchorEl(null);
    return async () => {
        try {
            let refresh_token =
                localStorage.getItem('refreshToken') ||
                sessionStorage.getItem('refreshToken');
            if (refresh_token === null)
                throw new Error('Refresh authData not found');
            else {
                let refresh_resp: any = await logoutWithAccessToken(
                    refresh_token,
                    authData.details?.user_id ?? ''
                );
                if (refresh_resp) {
                    localStorage.removeItem('showOnboardingOnRefresh');
                    localStorage.removeItem('refreshToken');
                    sessionStorage.removeItem('refreshToken');
                    localStorage.removeItem('idToken');
                    sessionStorage.removeItem('idToken');
                    updateToken('', [], null);
                    //toast.success('Logged out successfully!');
                    if (caches) {
                        const names = await caches.keys();
                        //
                        await Promise.all(
                            names.map((name) => caches.delete(name))
                        );
                    }
                }
            }
        } catch (err) {
            toast.error('Failed to logout');
        }
    };
};
