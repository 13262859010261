import { Box, Divider, Grid, InputAdornment, MenuItem } from '@mui/material';
import { FWCurrencyTypoGraphyWithCurrencyUid } from '../../Common/CurrencyUtilsComponent';
import { FWIconButton } from '../../Common/FWButton';
import { FWInput } from '../../Common/FWInput';
import FWSelect from '../../Common/FWSelect';
import { FWTypography } from '../../Common/FWTypograhy';
import {
    calculateTotalAdditionalCostAndDeviation,
    calculateTotalAdditionalCostAndDeviationEventLevel,
} from '../helpers';
import {
    AllocationTypeEnum,
    AllocationTypeEnumToLabelMap,
    CostTypeEnum,
    CostTypeEnumToLabelMap,
    IAdditionalCost,
    IAdditionalCostWithIsRequired,
    IAdditionalCostsWithValue,
} from '../models/AdditionalCost.model';

export const AdditionalCostTaxListPopUp = ({
    localCopyOfAdditionalCost,
    updateSingleAdditionalCost,
    currency_code_abbreviation,
    currency_code_symbol,
    measurementUnitName,
    baseQuantity,
    baseRate,
    removeSingleAdditionalCost,
    availableAdditionalCostTemplateOptions,
    addNewAdditionalCost,
    selectedAdditionalCostTemplateOptions,
    totalAdditionalCost,
    additionalCostTemplateOptions,
    conversionRate,
    conversionPrecision,
    rfqCurrencyAbbreviation,
    isEventLevel,
    eventSubtotal,
    eventTotal,
}: {
    localCopyOfAdditionalCost: IAdditionalCostsWithValue[];
    updateSingleAdditionalCost: (costValue: string, idx: number) => void;
    currency_code_abbreviation: string;
    currency_code_symbol: string;
    measurementUnitName: string;
    baseRate: number | string;
    baseQuantity: number | string;
    removeSingleAdditionalCost: (idx: number) => void;
    availableAdditionalCostTemplateOptions: IAdditionalCost[];
    addNewAdditionalCost: () => void;
    selectedAdditionalCostTemplateOptions: (
        costName: string,
        idx: number
    ) => void;
    totalAdditionalCost: {
        additionalCostValue: number;
        effectiveRateDeviation: number;
    };
    additionalCostTemplateOptions: IAdditionalCostWithIsRequired[];
    conversionRate: number;
    conversionPrecision: number;
    rfqCurrencyAbbreviation: string;
    isEventLevel: boolean;
    eventSubtotal: number | string;
    eventTotal: number | string;
}) => {
    return (
        <>
            {/* header of list of addition cost */}
            <Grid container xs={12} rowSpacing={1} marginBottom={1}>
                <Grid item xs={12}>
                    <Grid container xs={12} columnSpacing={1}>
                        <Grid item xs={2}>
                            <FWTypography color="text.secondary">
                                Name
                            </FWTypography>
                        </Grid>
                        <Grid item xs={2}>
                            <FWTypography color="text.secondary">
                                Type
                            </FWTypography>
                        </Grid>
                        <Grid item xs={2.5}>
                            <FWTypography color="text.secondary">
                                Allocation
                            </FWTypography>
                        </Grid>
                        <Grid item xs={2.5}>
                            <FWTypography color="text.secondary">
                                Rate
                            </FWTypography>
                        </Grid>
                        <Grid item xs={2} paddingLeft={4}>
                            <FWTypography color="text.secondary">
                                Value
                            </FWTypography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container xs={12} rowSpacing={1}>
                {localCopyOfAdditionalCost?.map((cost, idx) => {
                    return cost.costName?.trim() !== '' ? (
                        <Grid item xs={12} key={idx}>
                            <Grid
                                container
                                xs={12}
                                columnSpacing={2}
                                alignItems={'center'}
                                style={{
                                    marginBottom: '0.5rem',
                                }}
                            >
                                <Grid item xs={2}>
                                    <FWTypography>{cost.costName}</FWTypography>
                                </Grid>
                                <Grid item xs={2}>
                                    <FWTypography>
                                        {CostTypeEnumToLabelMap[cost.costType]}
                                    </FWTypography>
                                </Grid>
                                <Grid item xs={2.5}>
                                    <FWTypography>
                                        {cost.costType ===
                                        CostTypeEnum.ABSOLUTE_VALUE
                                            ? cost.allocationType &&
                                              AllocationTypeEnumToLabelMap[
                                                  cost.allocationType
                                              ]
                                            : '-'}
                                    </FWTypography>
                                </Grid>
                                <Grid item xs={2.5}>
                                    <Box
                                        sx={{
                                            paddingRight: '1rem',
                                            position: 'relative',
                                        }}
                                    >
                                        <FWInput
                                            value={cost.value}
                                            onChange={(e) => {
                                                updateSingleAdditionalCost(
                                                    e.target.value,
                                                    idx
                                                );
                                            }}
                                            allowOnly={
                                                cost.costType ===
                                                CostTypeEnum.PERCENTAGE
                                                    ? 'PERCENT'
                                                    : 'DECIMAL_NUMBER'
                                            }
                                            maxNumberOfDecimal={
                                                cost.costType ===
                                                CostTypeEnum.PERCENTAGE
                                                    ? 4
                                                    : 10
                                            }
                                            InputProps={{
                                                ...(cost.costType ===
                                                CostTypeEnum.PERCENTAGE
                                                    ? {
                                                          startAdornment: (
                                                              <InputAdornment
                                                                  position={
                                                                      'start'
                                                                  }
                                                                  sx={{
                                                                      m: '0 0.85rem',
                                                                  }}
                                                              ></InputAdornment>
                                                          ),
                                                          endAdornment: (
                                                              <InputAdornment
                                                                  position={
                                                                      'end'
                                                                  }
                                                                  sx={{
                                                                      m: '0 0.75rem',
                                                                  }}
                                                              >
                                                                  %
                                                              </InputAdornment>
                                                          ),
                                                      }
                                                    : {
                                                          startAdornment: (
                                                              <InputAdornment
                                                                  position={
                                                                      'start'
                                                                  }
                                                                  sx={{
                                                                      m: '0 0.65rem',
                                                                  }}
                                                              >
                                                                  {
                                                                      currency_code_symbol
                                                                  }
                                                              </InputAdornment>
                                                          ),
                                                          endAdornment: (
                                                              <InputAdornment
                                                                  position={
                                                                      'end'
                                                                  }
                                                                  sx={{
                                                                      m: '0 0.15rem',
                                                                  }}
                                                              >
                                                                  <FWTypography
                                                                      sx={{
                                                                          width:
                                                                              cost.allocationType ===
                                                                              AllocationTypeEnum.PER_UNIT
                                                                                  ? '45px'
                                                                                  : '0px',
                                                                      }}
                                                                  >
                                                                      {cost.allocationType ===
                                                                          AllocationTypeEnum.PER_UNIT &&
                                                                          `/${measurementUnitName}`}
                                                                  </FWTypography>
                                                              </InputAdornment>
                                                          ),
                                                      }),
                                            }}
                                            error={
                                                (additionalCostTemplateOptions.find(
                                                    (opt) =>
                                                        opt.costName?.trim() ===
                                                        cost.costName?.trim()
                                                )?.is_required &&
                                                    cost.value === '') ||
                                                (additionalCostTemplateOptions.find(
                                                    (opt) =>
                                                        opt.costName?.trim() ===
                                                        cost.costName?.trim()
                                                )?.is_required &&
                                                    cost.value === '0')
                                            }
                                            helper={{
                                                text:
                                                    additionalCostTemplateOptions.find(
                                                        (opt) =>
                                                            opt.costName?.trim() ===
                                                            cost.costName?.trim()
                                                    )?.is_required &&
                                                    cost.value === ''
                                                        ? 'Input required'
                                                        : additionalCostTemplateOptions.find(
                                                              (opt) =>
                                                                  opt.costName?.trim() ===
                                                                  cost.costName?.trim()
                                                          )?.is_required &&
                                                          cost.value === '0'
                                                        ? 'The input value should be greater than 0'
                                                        : '',
                                            }}
                                        />
                                        {cost.value !== '' &&
                                            conversionRate !== 1 &&
                                            cost.costType !==
                                                CostTypeEnum.PERCENTAGE && (
                                                <FWTypography
                                                    style={{
                                                        position: 'absolute',
                                                    }}
                                                    color={'orange'}
                                                >
                                                    {/* <FWCurrencyTypoGraphyWithCurrencyUid
                                                        currencyUidOrAbbrevaition={
                                                            rfqCurrencyAbbreviation
                                                        }
                                                        currency_code_abbreviation={
                                                            rfqCurrencyAbbreviation
                                                        }
                                                        value={
                                                            Number(cost.value) *
                                                            conversionRate
                                                        }
                                                        measurementUnitName={
                                                            cost.allocationType ===
                                                            AllocationTypeEnum.PER_UNIT
                                                                ? measurementUnitName
                                                                : ''
                                                        }
                                                    /> */}
                                                    {cost.allocationType ===
                                                    AllocationTypeEnum.PER_UNIT ? (
                                                        <FWCurrencyTypoGraphyWithCurrencyUid
                                                            currencyUidOrAbbrevaition={
                                                                rfqCurrencyAbbreviation
                                                            }
                                                            currency_code_abbreviation={
                                                                rfqCurrencyAbbreviation
                                                            }
                                                            value={
                                                                Number(
                                                                    cost.value
                                                                ) *
                                                                conversionRate
                                                            }
                                                            measurementUnitName={
                                                                measurementUnitName
                                                            }
                                                        />
                                                    ) : (
                                                        <FWCurrencyTypoGraphyWithCurrencyUid
                                                            currencyUidOrAbbrevaition={
                                                                rfqCurrencyAbbreviation
                                                            }
                                                            currency_code_abbreviation={
                                                                rfqCurrencyAbbreviation
                                                            }
                                                            value={
                                                                Number(
                                                                    cost.value
                                                                ) *
                                                                conversionRate
                                                            }
                                                        />
                                                    )}
                                                </FWTypography>
                                            )}
                                    </Box>
                                </Grid>
                                <Grid item xs={1.5} paddingLeft={4}>
                                    <FWTypography>
                                        {/* {FWNumberFormatter(
                                                calculateAddtionalCost({
                                                    additionalCost: cost,
                                                    baseRate: baseRate,
                                                    baseQuantity: baseQuantity,
                                                }).additionalCostValue,
                                                currency_code_abbreviation
                                            )} */}
                                        <FWCurrencyTypoGraphyWithCurrencyUid
                                            currencyUidOrAbbrevaition={
                                                currency_code_abbreviation
                                            }
                                            currency_code_abbreviation={
                                                currency_code_abbreviation
                                            }
                                            value={
                                                isEventLevel
                                                    ? calculateTotalAdditionalCostAndDeviationEventLevel(
                                                          {
                                                              additionalCost:
                                                                  cost,
                                                              eventSubTotal:
                                                                  eventSubtotal,
                                                              eventTotal:
                                                                  eventTotal,
                                                              type: 'Taxes',
                                                          }
                                                      ).additionalCostValue
                                                    : calculateTotalAdditionalCostAndDeviation(
                                                          {
                                                              additionalCost:
                                                                  cost,
                                                              baseRate:
                                                                  baseRate,
                                                              baseQuantity:
                                                                  baseQuantity,
                                                          }
                                                      ).additionalCostValue
                                            }
                                        />
                                    </FWTypography>
                                    {conversionRate !== 1 && (
                                        <FWTypography
                                            // paddingY={
                                            //     paddingForAllFields
                                            // }
                                            style={{
                                                position: 'absolute',
                                                paddingTop: '0.5rem',
                                            }}
                                            color={'orange'}
                                        >
                                            <FWCurrencyTypoGraphyWithCurrencyUid
                                                currencyUidOrAbbrevaition={
                                                    rfqCurrencyAbbreviation
                                                }
                                                currency_code_abbreviation={
                                                    rfqCurrencyAbbreviation
                                                }
                                                value={
                                                    isEventLevel
                                                        ? calculateTotalAdditionalCostAndDeviationEventLevel(
                                                              {
                                                                  additionalCost:
                                                                      cost,
                                                                  eventSubTotal:
                                                                      eventSubtotal,
                                                                  eventTotal:
                                                                      eventTotal,
                                                                  type: 'Taxes',
                                                              }
                                                          )
                                                              .additionalCostValue *
                                                          conversionRate
                                                        : calculateTotalAdditionalCostAndDeviation(
                                                              {
                                                                  additionalCost:
                                                                      cost,
                                                                  baseRate:
                                                                      baseRate,
                                                                  baseQuantity:
                                                                      baseQuantity,
                                                              }
                                                          )
                                                              .additionalCostValue *
                                                          conversionRate
                                                }
                                            />
                                        </FWTypography>
                                    )}
                                </Grid>
                                <Grid item xs={1} display={'flex'}>
                                    <FWIconButton
                                        color="error"
                                        onClick={() => {
                                            removeSingleAdditionalCost(idx);
                                        }}
                                    >
                                        <i className="bi bi-dash-circle"></i>
                                    </FWIconButton>
                                    {localCopyOfAdditionalCost?.length - 1 ===
                                        idx &&
                                        availableAdditionalCostTemplateOptions.length >
                                            0 && (
                                            <FWIconButton
                                                disabled={
                                                    localCopyOfAdditionalCost.length ===
                                                        1 &&
                                                    localCopyOfAdditionalCost[0]
                                                        .costName === ''
                                                }
                                                color="primary"
                                                onClick={() => {
                                                    addNewAdditionalCost();
                                                }}
                                            >
                                                <i className="bi bi-plus-circle"></i>
                                            </FWIconButton>
                                        )}
                                </Grid>
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid item xs={12} key={idx}>
                            <Grid
                                container
                                xs={12}
                                columnSpacing={2}
                                alignItems={'center'}
                            >
                                <Grid item xs={2}>
                                    <FWSelect
                                        fullWidth
                                        sx={{
                                            '&': {
                                                backgroundColor: 'white',
                                            },
                                        }}
                                    >
                                        {availableAdditionalCostTemplateOptions?.map(
                                            (option, optionIdx) => (
                                                <MenuItem
                                                    key={optionIdx}
                                                    onClick={() => {
                                                        selectedAdditionalCostTemplateOptions(
                                                            option.costName,
                                                            idx
                                                        );
                                                    }}
                                                    value={option.costName}
                                                >
                                                    {option.costName}
                                                </MenuItem>
                                            )
                                        )}
                                    </FWSelect>
                                </Grid>
                                <Grid item xs={2}>
                                    <FWTypography>-</FWTypography>
                                </Grid>
                                <Grid item xs={2.5}>
                                    <FWTypography>-</FWTypography>
                                </Grid>
                                <Grid item xs={2.5}>
                                    <Box
                                        sx={{
                                            paddingRight: '1rem',
                                        }}
                                    >
                                        <FWInput
                                            value={cost.value}
                                            onChange={(e) => {
                                                updateSingleAdditionalCost(
                                                    e.target.value,
                                                    idx
                                                );
                                            }}
                                            disabled
                                            allowOnly="DECIMAL_NUMBER"
                                            {...(cost.costType ===
                                            CostTypeEnum.PERCENTAGE
                                                ? {
                                                      maxNumberOfDecimal: 4,
                                                  }
                                                : {})}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={1.5} paddingLeft={4}>
                                    -
                                </Grid>
                                <Grid item xs={1} display={'flex'}>
                                    {localCopyOfAdditionalCost?.filter(
                                        (cost) =>
                                            cost.costName?.trim().length > 0
                                    ).length > 0 && (
                                        <FWIconButton
                                            color="error"
                                            onClick={() => {
                                                removeSingleAdditionalCost(idx);
                                            }}
                                        >
                                            <i className="bi bi-dash-circle"></i>
                                        </FWIconButton>
                                    )}
                                    {localCopyOfAdditionalCost?.length - 1 ===
                                        idx &&
                                        availableAdditionalCostTemplateOptions.length >
                                            0 &&
                                        localCopyOfAdditionalCost?.filter(
                                            (cost) =>
                                                cost.costName?.trim().length ===
                                                0
                                        ).length <
                                            availableAdditionalCostTemplateOptions.length && (
                                            <FWIconButton
                                                disabled={
                                                    localCopyOfAdditionalCost.length ===
                                                        1 &&
                                                    localCopyOfAdditionalCost[0]
                                                        .costName === ''
                                                }
                                                color="primary"
                                                onClick={() => {
                                                    addNewAdditionalCost();
                                                }}
                                            >
                                                <i className="bi bi-plus-circle"></i>
                                            </FWIconButton>
                                        )}
                                </Grid>
                            </Grid>
                        </Grid>
                    );
                })}
            </Grid>

            <br />
            <Divider />
            <br />
            <Grid container xs={12} rowSpacing={1} marginBottom={1}>
                <Grid item xs={12}>
                    <Grid container xs={12} columnSpacing={1}>
                        <Grid item xs={2}>
                            <FWTypography fontWeight="500">Total</FWTypography>
                        </Grid>
                        <Grid item xs={2}>
                            -
                        </Grid>
                        <Grid item xs={2.5}>
                            -
                        </Grid>
                        {isEventLevel ? (
                            <Grid item xs={2.5}>
                                -
                            </Grid>
                        ) : (
                            <Grid item xs={2.3}>
                                <FWTypography textOverflow={'ellipsis'}>
                                    <FWCurrencyTypoGraphyWithCurrencyUid
                                        currencyUidOrAbbrevaition={
                                            currency_code_abbreviation
                                        }
                                        currency_code_abbreviation={
                                            currency_code_abbreviation
                                        }
                                        value={
                                            totalAdditionalCost.effectiveRateDeviation
                                        }
                                        measurementUnitName={
                                            measurementUnitName
                                        }
                                    />
                                    <FWTypography
                                        sx={{
                                            position: 'absolute',
                                            paddingTop: '0.5rem',
                                        }}
                                        color={'orange'}
                                    >
                                        {conversionRate !== 1 && (
                                            <FWCurrencyTypoGraphyWithCurrencyUid
                                                currencyUidOrAbbrevaition={
                                                    rfqCurrencyAbbreviation
                                                }
                                                currency_code_abbreviation={
                                                    rfqCurrencyAbbreviation
                                                }
                                                value={
                                                    Number(
                                                        totalAdditionalCost.effectiveRateDeviation
                                                    ) * conversionRate
                                                }
                                                measurementUnitName={
                                                    measurementUnitName
                                                }
                                            />
                                        )}
                                    </FWTypography>
                                </FWTypography>
                            </Grid>
                        )}
                        <Grid item xs={0.2}></Grid>
                        <Grid item xs={2}>
                            <FWTypography fontWeight="500">
                                <FWCurrencyTypoGraphyWithCurrencyUid
                                    currencyUidOrAbbrevaition={
                                        currency_code_abbreviation
                                    }
                                    currency_code_abbreviation={
                                        currency_code_abbreviation
                                    }
                                    value={
                                        totalAdditionalCost.additionalCostValue
                                    }
                                />
                                <FWTypography
                                    sx={{
                                        position: 'absolute',
                                        paddingTop: '0.5rem',
                                    }}
                                    color={'orange'}
                                >
                                    {conversionRate !== 1 && (
                                        <FWCurrencyTypoGraphyWithCurrencyUid
                                            currencyUidOrAbbrevaition={
                                                currency_code_abbreviation
                                            }
                                            currency_code_abbreviation={
                                                currency_code_abbreviation
                                            }
                                            value={
                                                Number(
                                                    totalAdditionalCost.additionalCostValue
                                                ) * conversionRate
                                            }
                                        />
                                    )}
                                </FWTypography>
                            </FWTypography>
                        </Grid>
                        <Grid item xs={0.5}></Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default AdditionalCostTaxListPopUp;
