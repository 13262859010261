import { Box } from '@mui/material';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {
    BarElement,
    CategoryScale,
    ChartData,
    Chart as ChartJS,
    ChartOptions,
    Legend,
    LineElement,
    LinearScale,
    PointElement,
    Title,
    Tooltip,
} from 'chart.js';
import { max } from 'lodash';
import { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { useSelector } from 'react-redux';
import { FWTypography } from '../../../Common/FWTypograhy';
import { getFWDate } from '../../../Common/Utils/DateUtils';
import { IGlobalAppStore } from '../../../Redux/Store';
import {
    ChartLocaleMap,
    CurrencySymbolMap,
} from '../../Constants/ChartLocaleMap';
import { DynamicAnalyticsItemPriceData } from '../../Interfaces/DynamicAnalyticsInterfaces.model';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels
);

export default function PricingAnalyticsChart({
    currencyCodeAbbreviation,
    currencyCodeSymbol,
    measurementUnitName,
    visibleData,
}: {
    currencyCodeSymbol: string;
    currencyCodeAbbreviation: string;
    measurementUnitName: string;
    visibleData: DynamicAnalyticsItemPriceData[];
}) {
    const [graphData, setGraphData] = useState<{
        xLabels: string[];
        toolTip1: string[];
        toolTip2: string[];
        barData: (number | null)[];
        lineData: (number | null)[];
    }>({
        xLabels: [],
        toolTip1: [],
        toolTip2: [],
        barData: [],
        lineData: [],
    });

    useEffect(() => {
        let xLabels: string[] = [];
        let toolTip1: string[] = [];
        let toolTip2: string[] = [];
        let barData: (number | null)[] = [];
        let lineData: (number | null)[] = [];
        visibleData.forEach((item) => {
            toolTip1.push(item.poDetails.customPoId);
            xLabels.push(getFWDate(item.poDetails.poIssueDate));
            toolTip2.push(item.poDetails.vendorName);
            barData.push(item.quantity);
            lineData.push(item.effectiveRate);
        });

        setGraphData({
            toolTip1,
            xLabels,
            toolTip2,
            barData,
            lineData,
        });
    }, [visibleData]);

    const { number_format } = useSelector(
        (store: IGlobalAppStore) => store.UserDefaultsStore
    );

    // logic to align step size of y axis on either sides of the graph
    var maxticks = 8;
    var max1: number = max(graphData.barData) || 0;
    var max2: number = max(graphData.lineData) || 0;
    var stepSize1 = Math.round(max1 / maxticks);
    var stepSize2 = Math.round(max2 / maxticks);

    const options: ChartOptions<any> = {
        locale:
            ChartLocaleMap[number_format as 'INR' | 'USD' | 'EUR'] || 'en-US',
        responsive: true,
        plugins: {
            title: {
                display: false, // set to true to display the title
                text: 'Pricing Analytics', // title text
                font: {
                    size: 15,
                },
            },
            datalabels: {
                display: false,
            },
            legend: {
                position: 'top' as const,
            },
            tooltip: {
                callback: {
                    label: (tooltipItem: any, data: any) => {
                        //
                        // const datasetLabel =
                        //     data.datasets[tooltipItem.datasetIndex].label || '';
                        // const dataValue = tooltipItem.yLabel;
                        // const tooltip =
                        //     data.datasets[tooltipItem.datasetIndex].tooltips?.[
                        //         tooltipItem.index
                        //     ];

                        const length = graphData.barData.length - 1;
                        return tooltipItem.datasetIndex === 0
                            ? `Quantity: ${
                                  graphData.barData[
                                      length - tooltipItem.dataIndex
                                  ]
                              } ${measurementUnitName} | ${
                                  graphData.toolTip2[
                                      length - tooltipItem.dataIndex
                                  ]
                              }: ${
                                  graphData.toolTip1[
                                      length - tooltipItem.dataIndex
                                  ]
                              }`
                            : `Rate: ${
                                  CurrencySymbolMap[
                                      number_format as 'INR' | 'USD' | 'EUR'
                                  ]
                              }${
                                  graphData.lineData[
                                      length - tooltipItem.dataIndex
                                  ]
                              } | ${
                                  graphData.toolTip2[
                                      length - tooltipItem.dataIndex
                                  ]
                              }: ${
                                  graphData.toolTip1[
                                      length - tooltipItem.dataIndex
                                  ]
                              }`;
                    },
                },
            },
        },
        scales: {
            x: {
                grid: {
                    display: false, // hide the vertical grid lines for the x-axis
                },
                ticks: {
                    callback: function (
                        val: string | number,
                        index: number
                    ): string {
                        return typeof val === 'number'
                            ? `${(this as any)
                                  .getLabelForValue(val)
                                  .substring(0, 10)}${
                                  (this as any).getLabelForValue(val).length >
                                  10
                                      ? '...'
                                      : ''
                              }`
                            : '';
                    },
                },
            },
            y: {
                type: 'linear',
                beginAtZero: true,
                grace: '20%',
                ticks: {
                    stepSize: stepSize1,
                },
                // min: 0,
                autoSkip: false,
                position: 'left',
                title: {
                    display: true,
                    text: `Quantity (${measurementUnitName})`,
                },
            },
            y1: {
                type: 'linear',
                beginAtZero: true,
                grace: '20%',
                ticks: {
                    stepSize: stepSize2,
                },
                // min: 0,
                autoSkip: false,
                position: 'right',
                title: {
                    display: true,
                    text: `Rate (${currencyCodeSymbol}/${measurementUnitName})`,
                },
                grid: {
                    drawOnChartArea: false,
                },
            },
        },
    };

    const labels = [
        ...graphData.xLabels.map(
            (poDate, index) =>
                `${poDate}\nPO ID: ${graphData.toolTip1[index]}\nVendor name: ${graphData.toolTip2[index]}`
        ),
    ].reverse();

    const data: ChartData<any> = {
        labels,
        datasets: [
            // {
            //     label: `MPOB Rate ($/unit)`,
            //     data: [82.53, 82.55, 82.35, 81.9, 82.84],
            //     backgroundColor: 'rgba(0, 0, 0, 1)',
            //     borderColor: 'rgba(0, 0, 0, 1)',
            //     // type: 'bar',
            //     yAxisID: 'y2',
            //     order: 2,
            // },
            {
                label: `Quantity (${measurementUnitName})`,
                data: [...graphData.barData].reverse(),
                backgroundColor: 'rgba(159, 197, 232, 1)',
                type: 'bar',
                yAxisID: 'y',
                order: 1,
            },
            {
                label: `Rate (${currencyCodeSymbol}/${measurementUnitName})`,
                data: [...graphData.lineData].reverse(),
                borderColor: '#38B000',
                backgroundColor: '#38B000',
                yAxisID: 'y1',
                order: 0,
            },
        ],
    };
    return visibleData.length === 0 ? (
        <Box className="flex flex--jcc flex--aic" height="270px">
            <FWTypography color="text.secondary" variant="caption">
                No pricing data available for the item
            </FWTypography>
        </Box>
    ) : (
        <Line
            options={options}
            data={data}
            style={{ display: 'unset !important' }}
        />
    );
}
