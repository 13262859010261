import {
    Avatar,
    Box,
    styled,
    Typography,
    TypographyProps,
} from '@mui/material';
import { useContext } from 'react';
import { FWTypography } from '../../Common/FWTypograhy';
import { getFWDate, getFWDateDifference } from '../../Common/Utils/DateUtils';
import { AuthContext } from '../../Contexts/AuthContext';
import { useLazyLoadList } from '../Hooks/LazyLoadListHook';
import { IUserSession } from '../Interfaces/Chat.model';
import { useDispatch, useSelector } from 'react-redux';
import { updateCurrentlyOpenedSession } from '../Slice/Chat.slice';
import { IGlobalAppStore } from '../../Redux/Store';

const StyledBox = styled(Box)(({ theme }) => ({
    cursor: 'pointer',
    padding: 8,
    '&:hover': {
        backgroundColor: theme.palette.background.paper,
        background: '#e5f1ff',
    },
    // borderBottom: '1px solid',
    borderColor: theme.palette.dividerColor.main,
    // background: '#ebf5ff',
    margin: 15,
    borderRadius: 15,
    transition: 'all 0.2s ease-in-out',
}));

interface IChatSessionRows {
    session_details: IUserSession;
    highlightBox: boolean;
    updateSelectedSessionId: (newSessionID: IUserSession) => void;
    containerSelector: string;
    user_type: 'buyer' | 'seller';
    unreadMsgCount: number;
    idx: number;
    setSelectedSessionAvatarColor: React.Dispatch<React.SetStateAction<string>>;
    updateLastReadMsgId: (newMsgId: string) => void;
}

const CustomTypography = styled(Typography)<TypographyProps>(({ theme }) => ({
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
}));

export default function ChatSessionRows(props: IChatSessionRows) {
    const [loaded, elRef] = useLazyLoadList(props.containerSelector);
    const { authData } = useContext(AuthContext);

    const latestMsg = props.session_details &&
        props.session_details.session &&
        props.session_details.session.last_msg && {
            senderName:
                props.session_details.session.last_msg &&
                authData.details?.user_id ===
                    props.session_details.session.last_msg.sender_id
                    ? 'You'
                    : props.session_details.session.last_msg.sender_name,
            latestMsg:
                props.session_details.session.last_msg &&
                props.session_details.session.last_msg.attachments.length > 0
                    ? 'Attachment'
                    : props.session_details.session.last_msg.message,
            latestMsgTime: (() => {
                if (props.session_details.session.last_msg.posted_time) {
                    switch (
                        getFWDateDifference(
                            props.session_details.session.last_msg.posted_time
                        )
                    ) {
                        case 0:
                            return 'Today';
                        case 1:
                            return 'Yesterday';
                        default:
                            return getFWDate(
                                props.session_details.session.last_msg
                                    .posted_time
                            );
                    }
                } else {
                    return '';
                }
            })(),
        };

    const colors: string[] = [
        '#85C7F2',
        '#FFD447',
        '#9D44B5',
        '#2CE9C6',
        '#724E91',
        '#A9E5BB',
        '#EDB458',
        '#FF6B6B',
        '#A3D5FF',
        '#F283B6',
        '#E85F5C',
    ];

    const dispatch = useDispatch();
    const currentlyOpenedSession = useSelector(
        (globalState: IGlobalAppStore) =>
            globalState.ChatStore.currentlyOpenedSession
    );

    return (
        <StyledBox
            onClick={() => {
                !currentlyOpenedSession &&
                    props.updateLastReadMsgId(
                        props.session_details.last_acknowledged?.message_id ??
                            ''
                    );
                props.updateSelectedSessionId(props.session_details);
                dispatch(
                    updateCurrentlyOpenedSession(
                        props.session_details.session.session_id
                    )
                );
                props.setSelectedSessionAvatarColor(
                    colors[
                        parseInt(
                            props.idx.toString()[
                                props.idx.toString().length - 1
                            ]
                        )
                    ]
                );
            }}
            bgcolor={props.highlightBox ? '#f0f0f2' : 'white'}
            ref={elRef}
        >
            {loaded ? (
                <Box className="flex flex--aic">
                    <Avatar
                        sx={{
                            marginRight: 1,
                            bgcolor:
                                colors[
                                    parseInt(
                                        props.idx.toString()[
                                            props.idx.toString().length - 1
                                        ]
                                    )
                                ],
                        }}
                    />
                    <Box width="100%" minWidth={'100px'}>
                        <Box
                            className="flex flex--jcsb flex--aic"
                            padding="3px"
                        >
                            <Box
                                className="flex flex--aic flex--jcsb"
                                width={'100%'}
                            >
                                <Box className="flex flex--aic">
                                    <FWTypography
                                        maxWidth={'150px'}
                                        fontWeight={600}
                                        color={'#4e4e4e'}
                                    >
                                        {props.session_details.session
                                            .session_type === 'INTERNAL'
                                            ? `${props.session_details.session.buyer_entity_name} (Internal)`
                                            : props.user_type === 'seller'
                                            ? props.session_details.session
                                                  .buyer_entity_name
                                            : props.session_details.session
                                                  .seller_entity_name}
                                        {props.session_details.session
                                            .session_type === 'SELF' &&
                                            `${authData.details?.name} (You)`}
                                    </FWTypography>
                                    {props.session_details.mute && (
                                        <>
                                            &nbsp;
                                            <Box
                                                color="text.secondary"
                                                fontSize={'1.25rem'}
                                            >
                                                <i className="bi bi-volume-mute"></i>
                                            </Box>
                                        </>
                                    )}
                                </Box>
                                {props.session_details.session.session_type ===
                                'SELF' ? (
                                    <Box color={'gray'}>
                                        <i className="bi bi-pin-angle-fill"></i>
                                    </Box>
                                ) : (
                                    props.unreadMsgCount > 0 && (
                                        <Box
                                            color="success.main"
                                            fontSize="10px"
                                        >
                                            <i className="bi bi-circle-fill"></i>
                                        </Box>
                                    )
                                )}
                            </Box>
                        </Box>
                        {!latestMsg ? (
                            <FWTypography
                                fontSize={'0.75rem'}
                                color="text.secondary"
                                padding="5px"
                            >
                                No messages yet
                            </FWTypography>
                        ) : (
                            <Box
                                className="flex flex--jcsb flex--aic"
                                padding="5px"
                                color="text.secondary"
                                fontSize={'0.8rem'}
                            >
                                <CustomTypography
                                    color="inherit"
                                    fontSize={'inherit'}
                                    flex={1}
                                >
                                    {`${latestMsg?.senderName} : ${latestMsg?.latestMsg}`}
                                </CustomTypography>
                                <CustomTypography
                                    color="inherit"
                                    fontSize={'inherit'}
                                    // maxWidth={'80px'}
                                    marginLeft={1}
                                >
                                    {latestMsg?.latestMsgTime}
                                </CustomTypography>
                            </Box>
                        )}
                    </Box>
                </Box>
            ) : (
                <Box className="flex flex--jcsb" padding="3px">
                    <Box>
                        <Box
                            bgcolor={'background.paper'}
                            width="250px"
                            height="50px"
                        />
                    </Box>
                    <Box className="flex flex--aic">
                        <i className="bi bi-chevron-right"></i>
                    </Box>
                </Box>
            )}
        </StyledBox>
    );
}
