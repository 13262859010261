import { Box, Grid, IconButton, Skeleton } from '@mui/material';
import { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
    FWAccordion,
    FWAccordionDetails,
    FWAccordionSummary,
} from '../../../Common/FWAccordian';
import { FWTooltip } from '../../../Common/FWCustomTooltip';
import { FWTypography } from '../../../Common/FWTypograhy';
import { IBuyerItemList } from '../../../Events/Interfaces/Buyer/RFQ.model';
import { IGlobalAppStore } from '../../../Redux/Store';
import {
    CART_ITEM_PER_PAGE,
    ITEM_ANALYTICS_FIELD_SIZE,
    ITEM_ANALYTICS_GAP,
    ITEM_ANALYTICS_ROW_GAP,
    ItemCartModuleSectionEnum,
} from '../../Interfaces/ItemAnalyticsInterfaces';
import { CartItemDetails } from '../../Interfaces/itemCartItemInterfaces';
import { ItemCartTemplateStandardFieldsEnum } from '../../Interfaces/ItemCartTemplateInterfaces';
import { ItemCartAccessContext } from '../../Pages/ItemCartAccess';
import BlankItemSelection from './BlankItemSelection';

export function SkeletonComponent() {
    return <Skeleton animation={'pulse'} height={'3.5rem'} />;
}

export default function BlankCartAItem({
    tab,
    buyerMasterItemList,
    hasMoreItemsinList,
    fetchingItemDetails,
    itemAddedSuccessfully,
    fetchAndLoadSelectedItemDetails,
    onShowMoreItems,
    onSearchItem,
    searchText,
}: {
    tab: ItemCartModuleSectionEnum;
    buyerMasterItemList: IBuyerItemList[];
    hasMoreItemsinList: boolean;
    fetchingItemDetails: boolean;
    itemAddedSuccessfully: boolean | null;
    fetchAndLoadSelectedItemDetails: (
        enterprise_item_id: string,
        itemAlreadyPresentInTab: CartItemDetails | null,
        additionalQuantityForCurrentItem?: number | undefined
    ) => Promise<void>;
    onSearchItem: (searchText: string) => void;
    searchText: string;
    onShowMoreItems: () => void;
}) {
    const { currentPage, hideBlankItemAccordion, templateDetails } = useContext(
        ItemCartAccessContext
    );

    const itemCount = useSelector((store: IGlobalAppStore) =>
        tab === ItemCartModuleSectionEnum.CART
            ? store.ItemCartStore.cartDetails.filter(
                  (item) => !item.save_for_later
              ).length
            : store.ItemCartStore.cartDetails.filter(
                  (item) => item.save_for_later
              ).length
    );

    useEffect(() => {}, [searchText]);

    // useEffect(() => {
    //     if (itemAddedSuccessfully) {
    //         hideBlankItemAccordion(true);
    //     }
    // }, [hideBlankItemAccordion, itemAddedSuccessfully]);

    useEffect(() => {}, []);

    return (
        <Box marginY={4} marginX={4}>
            <FWAccordion expanded={true}>
                <FWAccordionSummary
                    expandIcon={<></>}
                    sx={{
                        '&.Mui-focusVisible': {
                            backgroundColor: 'white',
                        },
                    }}
                >
                    <Box width="100%">
                        <Box
                            marginLeft={'1rem'}
                            className="flex flex--aic flex--jcsb"
                            height={'3rem'}
                        >
                            <BlankItemSelection
                                sortedBuyerMasterItemList={buyerMasterItemList}
                                hasMoreItemsinList={hasMoreItemsinList}
                                onShowMoreItems={onShowMoreItems}
                                searchText={searchText}
                                fetchAndLoadSelectedItemDetails={
                                    fetchAndLoadSelectedItemDetails
                                }
                                onSearchItemList={onSearchItem}
                                itemCount={
                                    itemCount +
                                    (currentPage - 1) * CART_ITEM_PER_PAGE
                                }
                                disableNewItemSelection={fetchingItemDetails}
                            />
                            <Box className="flex flex--aic">
                                <FWTooltip title="Cancel">
                                    <Box>
                                        <IconButton
                                            color="error"
                                            disabled={fetchingItemDetails}
                                            sx={{ fontSize: '1.5rem' }}
                                            onClick={() => {
                                                hideBlankItemAccordion(false);
                                            }}
                                        >
                                            <i className="bi bi-trash3"></i>
                                        </IconButton>
                                    </Box>
                                </FWTooltip>
                                &nbsp;
                                <IconButton disabled>
                                    <i className="bi bi-chevron-up"></i>
                                </IconButton>
                            </Box>
                        </Box>
                        <br />
                        <Grid
                            container
                            gap={ITEM_ANALYTICS_GAP}
                            marginTop={2}
                            marginX={2}
                        >
                            {templateDetails.fieldSettings.standardFields[
                                ItemCartTemplateStandardFieldsEnum.QUANTITY
                            ].buyerSettings.showField && (
                                <Grid item xs={ITEM_ANALYTICS_FIELD_SIZE}>
                                    <FWTypography color={'text.secondary'}>
                                        {
                                            templateDetails.fieldSettings
                                                .standardFields[
                                                ItemCartTemplateStandardFieldsEnum
                                                    .QUANTITY
                                            ].label
                                        }
                                    </FWTypography>
                                    <SkeletonComponent />
                                </Grid>
                            )}
                            {templateDetails.fieldSettings.standardFields[
                                ItemCartTemplateStandardFieldsEnum.TARGET_RATE
                            ].buyerSettings.showField && (
                                <Grid item xs={ITEM_ANALYTICS_FIELD_SIZE}>
                                    <FWTypography color={'text.secondary'}>
                                        {
                                            templateDetails.fieldSettings
                                                .standardFields[
                                                ItemCartTemplateStandardFieldsEnum
                                                    .TARGET_RATE
                                            ].label
                                        }
                                    </FWTypography>
                                    <SkeletonComponent />
                                </Grid>
                            )}
                            {templateDetails.fieldSettings.standardFields[
                                ItemCartTemplateStandardFieldsEnum
                                    .ADDITIONAL_COSTS
                            ].buyerSettings.showField && (
                                <Grid item xs={ITEM_ANALYTICS_FIELD_SIZE}>
                                    <FWTypography color={'text.secondary'}>
                                        {
                                            templateDetails.fieldSettings
                                                .standardFields[
                                                ItemCartTemplateStandardFieldsEnum
                                                    .ADDITIONAL_COSTS
                                            ].label
                                        }
                                    </FWTypography>
                                    <SkeletonComponent />
                                </Grid>
                            )}
                            {templateDetails.fieldSettings.standardFields[
                                ItemCartTemplateStandardFieldsEnum.DISCOUNT
                            ].buyerSettings.showField && (
                                <Grid item xs={ITEM_ANALYTICS_FIELD_SIZE}>
                                    <FWTypography color={'text.secondary'}>
                                        {
                                            templateDetails.fieldSettings
                                                .standardFields[
                                                ItemCartTemplateStandardFieldsEnum
                                                    .DISCOUNT
                                            ].label
                                        }
                                    </FWTypography>
                                    <SkeletonComponent />
                                </Grid>
                            )}
                            <Grid item xs={ITEM_ANALYTICS_FIELD_SIZE}>
                                <FWTypography color={'text.secondary'}>
                                    Effective rate
                                </FWTypography>
                                <SkeletonComponent />
                            </Grid>
                            <Grid item xs={ITEM_ANALYTICS_FIELD_SIZE}>
                                <FWTypography color={'text.secondary'}>
                                    Total amount
                                </FWTypography>
                                <SkeletonComponent />
                            </Grid>
                        </Grid>
                    </Box>
                </FWAccordionSummary>
                <FWAccordionDetails>
                    <Grid
                        container
                        gap={ITEM_ANALYTICS_GAP}
                        rowGap={ITEM_ANALYTICS_ROW_GAP}
                        marginTop={2}
                        marginX={2}
                    >
                        {templateDetails.sections.PAYMENT_AND_DELIVERY_TERMS
                            .fieldList?.length > 0 && (
                            <Grid item xs={12} className="flex flex--aic">
                                <IconButton size="small" disabled>
                                    <i className="bi bi-chevron-right"></i>
                                </IconButton>
                                &nbsp;
                                <FWTypography variant="h4" fontWeight={500}>
                                    {
                                        templateDetails.sections
                                            .PAYMENT_AND_DELIVERY_TERMS.label
                                    }
                                </FWTypography>
                            </Grid>
                        )}
                        {templateDetails.sections.VENDOR_INFORMATION.fieldList
                            ?.length > 0 && (
                            <Grid item xs={12} className="flex flex--aic">
                                <IconButton size="small" disabled>
                                    <i className="bi bi-chevron-right"></i>
                                </IconButton>
                                &nbsp;
                                <FWTypography variant="h4" fontWeight={500}>
                                    {
                                        templateDetails.sections
                                            .VENDOR_INFORMATION.label
                                    }
                                </FWTypography>
                            </Grid>
                        )}

                        {templateDetails.sections.ADDITIONAL_DETAILS.fieldList
                            ?.length > 0 && (
                            <Grid item xs={12} className="flex flex--aic">
                                <IconButton size="small" disabled>
                                    <i className="bi bi-chevron-right"></i>
                                </IconButton>
                                &nbsp;
                                <FWTypography variant="h4" fontWeight={500}>
                                    {
                                        templateDetails.sections
                                            .ADDITIONAL_DETAILS.label
                                    }
                                </FWTypography>
                            </Grid>
                        )}
                    </Grid>
                </FWAccordionDetails>
            </FWAccordion>
        </Box>
    );
}
