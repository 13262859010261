import {
    INotification,
    NotificationStatus,
} from '../../Global/Interfaces/NotificationInterfaces';
import { INotificationState } from '../Slices/NotificationsSlice';
import produce from 'immer';

export enum NotificationActions {
    INIT = 'INIT',
    ADD = 'ADD',
    REMOVE = 'REMOVE',
}

export interface INotificationAddAction {
    type: string;
    payload: {
        type: NotificationActions;
        value: INotification[];
    };
}

export interface INotificationRemoveAction {
    type: string;
    payload: {
        type: NotificationActions;
        value: number;
    };
}

export interface INotificationsStatusActions {
    payload: {
        id: string;
        value: NotificationStatus;
    };
}

export type INotificationsListActions = INotificationAddAction;

export const updateNotificationListReducer = produce(
    (state: INotificationState, action: INotificationsListActions) => {
        const newNotifications = action.payload.value as INotification[];
        switch (action.payload.type) {
            case NotificationActions.INIT:
                state.notificationsList = newNotifications;
                break;
            case NotificationActions.ADD:
                state.notificationsList = [
                    ...newNotifications,
                    ...state.notificationsList,
                ];
                break;
            case NotificationActions.REMOVE:
                // state.pastList = action.payload.value;
                break;
        }
    }
);

export const updateNotificationReducer = produce(
    (state: INotificationState, action: INotificationsStatusActions) => {
        const notifications = state.notificationsList;
        let notificationRead = notifications.filter(
            (notificationRead) => notificationRead.id === action.payload.id
        )[0];
        notificationRead.status = action.payload.value;
    }
);
