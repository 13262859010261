import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { instance, appConfig } from './config';

const API_URL = appConfig.baseUrl;

export const get = <T>(url: string, config?: AxiosRequestConfig<any>) => {
    return instance.get<T, AxiosResponse<T>>(API_URL + url, config);
};

export const post = <REQ, RES>(
    url: string,
    payload: any,
    config?: AxiosRequestConfig<any>
) => {
    return instance.post<REQ, AxiosResponse<RES>>(
        API_URL + url,
        payload,
        config
    );
};

export const put = <REQ, RES>(
    url: string,
    payload: any,
    config?: AxiosRequestConfig<any>
) => {
    return instance.put<REQ, AxiosResponse<RES>>(
        API_URL + url,
        payload,
        config
    );
};

export const del = <REQ>(url: string) => {
    return instance.delete<REQ, AxiosResponse<any>>(API_URL + url);
};

export const delWpl = <REQ>(url: string, payload: any) => {
    return instance.delete<REQ, AxiosResponse<any>>(API_URL + url, {
        data: payload,
    });
};

export const patch = <REQ, RES>(url: string, payload: any) => {
    return instance.patch<REQ, AxiosResponse<RES>>(API_URL + url, payload);
};

export const postToS3 = <REQ, RES>(url: string, payload: any) => {
    const config = {
        headers: {
            'content-type': 'multipart/form-data',
        },
    };
    return axios.post<REQ, AxiosResponse<RES>>(url, payload, config);
};
