/*
    A global Component to provide a simple popover that takes a button and a popup content to show.
    Will add to global files when it used in more than 2 places.
*/

import React, { useState } from 'react';
import FWPopover from '../../Common/FWPopover';

const InfoPopOver = ({
    button = <></>,
    onPopContent = <></>,
    popoverHeight,
}: {
    button: React.ReactElement;
    onPopContent: React.ReactElement;
    popoverHeight?: number;
}) => {
    const [anchorEl, setAnchorEl] = useState<HTMLAnchorElement | null>(null);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const handleClick = (event: any) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <div
                ref={(ref) => {
                    if (!ref) return;
                    ref.onclick = handleClick;
                }}
            >
                {button}
            </div>

            <FWPopover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                sx={{
                    height: popoverHeight
                        ? `${popoverHeight}px`
                        : 'fit-context',
                }}
            >
                {onPopContent}
            </FWPopover>
        </>
    );
};

export default InfoPopOver;
