import { IAdditionalCostsBackend } from '../../AdditionalCost/models/AdditionalCost.model';
import {
    ITemplateAdditionalCostOptions,
    ITemplateCustomFieldOptions,
    ITemplateDiscountOptions,
    ITemplatePaymentTermsOptions,
    ITemplateTaxesOptions,
} from '../../Global/Interfaces/TemplateInterface';

export enum RequisitionNewTemplateStatusEnum {
    DRAFT = 'DRAFT',
}

export interface IRequisitionNewTemplateDetails {
    template_id: string;
    type: 'ITEM_CART';
    name: string;
    tags: string[];
    status: RequisitionNewTemplateStatusEnum;
    published_on: null;
    description: '';
    is_global: false;
    is_default: false;
    created_on: string;
    creator_name: string;
    last_edited_on: string;
    entity_id: string;
    entity_name: string;
    auto_reminders: {};
    last_edited_by_name: string;
    assigned_users: [];
    section_list: {
        section_id: string;
        name: string;
        assigned_users: [];
    }[];
}

export interface IRequisitionNewTemplateResponse {
    type: 'ITEM_CART';
    count: number;
    templates: IRequisitionNewTemplateDetails[];
}

export interface IRequisitionNewTemplateDetailResponse {
    template_id: string;
    section_list: SectionList[];
    entity: Entity;
    last_edited_by_name: string;
    assigned_users: any[];
    created_datetime: string;
    modified_datetime: string;
    deleted_datetime: string | null;
    name: string;
    type: string;
    description: string;
    tags: string[];
    is_default: boolean;
    is_global: boolean;
    auto_reminders: {
        initial_reminder_hours: number;
        is_remainder_required: boolean;
        max_reminders: number;
        reminder_interval: number;
    };
    status: string;
    published_on: string | null;
    created_on: string;
    last_edited_on: string;
    created_by_user: string;
    modified_by_user: string;
    deleted_by_user: string | null;
    enterprise: string;
    creator: string;
    last_edited_by: string;
}

export interface SectionList {
    section_id: string;
    field_level: 'ITEM' | 'OTHER' | 'DELIVERY_SCHEDULE';
    section_items: SectionItem[];
    assigned_users: any[];
    created_datetime: string;
    modified_datetime: string;
    deleted_datetime: any;
    name: string;
    alternate_name: string;
    section_type: string;
    is_builtin: boolean;
    description: string;
    sequence: number;
    is_visible: boolean;
    is_optional: boolean;
    created_by_user: string;
    modified_by_user: string;
    deleted_by_user: any;
    template: string;
}

export interface SectionItem {
    section_item_id: string;
    options?: Option[];
    created_datetime: string;
    modified_datetime: string;
    deleted_datetime: any;
    sequence: number;
    name: string;
    alternate_name: string;
    description: string;
    constraints: {
        max_limit?: number;
        min_limit?: number;
        field_type: string;
        decimal_places?: number;
        choices?: string[];
        choice_type?: 'DROPDOWN' | 'MULTI_SELECT' | undefined;
    };
    field_level: 'ITEM' | 'OTHER' | 'DELIVERY_SCHEDULE';
    can_remove: boolean;
    is_builtin_field: boolean;
    is_required: boolean;
    is_variable: boolean;
    is_mandatory: boolean;
    additional_information: {
        is_locked: boolean;
        is_visible: boolean;
        is_negotiable: boolean;
        is_hidden: boolean;
        additional_cost_information?: Omit<
            IAdditionalCostsBackend,
            'cost_value' | 'cost_name'
        >;
        shipping_rate_information?: Omit<
            IAdditionalCostsBackend,
            'cost_value' | 'cost_name'
        >;
        taxes_information?: Omit<
            IAdditionalCostsBackend,
            'cost_value' | 'cost_name'
        >;
        discount_information?: Omit<
            IAdditionalCostsBackend,
            'cost_value' | 'cost_name'
        >;
    };
    created_by_user: string;
    modified_by_user: string;
    deleted_by_user: any;
    section: string;
    parent_section_item: string | null;
}

export interface Option {
    section_item_option_id: string;
    created_datetime: string;
    modified_datetime: string;
    deleted_datetime: any;
    name: string;
    alternate_name: string;
    description: string;
    is_builtin: boolean;
    conditions: Condition[];
    created_by_user: string;
    modified_by_user: string;
    deleted_by_user: any;
    send_to_po?: boolean;
    can_split?: boolean;
    section_item: string;
}

export interface Condition {
    name: string;
    value: string;
}

export interface Constraints {
    max_limit?: number;
    min_limit?: number;
    field_type: string;
    decimal_places?: number;
    choices?: string[];
    selected?: Selected;
    choice_type?: 'DROPDOWN' | 'MULTI_SELECT';
}

export interface Selected {
    choice: string;
}

export interface AdditionalInformation {
    is_hidden: boolean;
    is_visible: boolean;
    is_locked: boolean;
    is_negotiable?: boolean;
    additional_cost_information?: AdditionalCostInformation;
    discount_information?: DiscountInformation;
}

export interface AdditionalCostInformation {
    cost_type: string;
    allocation_type: string;
    additional_cost_id: string;
}

export interface DiscountInformation {
    cost_type: string;
    allocation_type: string;
}

export interface Entity {
    entity_name: string;
    entity_id: string;
}

export interface AutoReminders {}

export enum RequisitionNewTemplateStandardFieldsEnum {
    REQUISITION_ID = 'REQUISITION_ID',
    REQUISITION_NAME = 'REQUISITION_NAME',
    CURRENCY = 'CURRENCY',
    REQUISITION_CREATOR = 'REQUISITION_CREATOR',
    REQUISITION_STATUS = 'REQUISITION_STATUS',
    BUYER_ENTITY = 'BUYER_ENTITY',
    DELIVERY_DATE = 'DELIVERY_DATE',
    QUANTITY = 'QUANTITY',
    COST_CENTER = 'COST_CENTER',
    GL = 'GL',
    PROJECT = 'PROJECT',
    TAX = 'TAX',
    ADDITIONAL_COSTS = 'ADDITIONAL_COSTS',
    DISCOUNT = 'DISCOUNT',
    TARGET_RATE = 'TARGET_RATE',
    PAYMENT_TERMS = 'PAYMENT_TERMS',
    LEAD_TIME = 'LEAD_TIME',
    INCOTERMS = 'INCOTERMS',
    GR_TOLERANCE = 'GR_TOLERANCE',
    SHIPPING_ADDRESS = 'SHIPPING_ADDRESS',
    VENDOR = 'VENDOR',
    ADDITIONAL_INFORMATION = 'ADDITIONAL_INFORMATION',
}

export const RequisitionNewTemplateStandardFieldBackendNames: {
    [BackendTemplateItemName: string]: RequisitionNewTemplateStandardFieldsEnum;
} = {
    'Requisition ID': RequisitionNewTemplateStandardFieldsEnum.REQUISITION_ID,
    'Requisition name':
        RequisitionNewTemplateStandardFieldsEnum.REQUISITION_NAME,
    Creator: RequisitionNewTemplateStandardFieldsEnum.REQUISITION_CREATOR,
    Currency: RequisitionNewTemplateStandardFieldsEnum.CURRENCY,
    'Requisition status':
        RequisitionNewTemplateStandardFieldsEnum.REQUISITION_STATUS,
    'Buyer entity': RequisitionNewTemplateStandardFieldsEnum.BUYER_ENTITY,
    'Delivery date': RequisitionNewTemplateStandardFieldsEnum.DELIVERY_DATE,
    Quantity: RequisitionNewTemplateStandardFieldsEnum.QUANTITY,
    'Cost center': RequisitionNewTemplateStandardFieldsEnum.COST_CENTER,
    GL: RequisitionNewTemplateStandardFieldsEnum.GL,
    Project: RequisitionNewTemplateStandardFieldsEnum.PROJECT,
    'Target rate': RequisitionNewTemplateStandardFieldsEnum.TARGET_RATE,
    Taxes: RequisitionNewTemplateStandardFieldsEnum.TAX,
    'Additional costs':
        RequisitionNewTemplateStandardFieldsEnum.ADDITIONAL_COSTS,
    Discount: RequisitionNewTemplateStandardFieldsEnum.DISCOUNT,
    'Payment terms': RequisitionNewTemplateStandardFieldsEnum.PAYMENT_TERMS,
    Incoterms: RequisitionNewTemplateStandardFieldsEnum.INCOTERMS,
    'Lead time': RequisitionNewTemplateStandardFieldsEnum.LEAD_TIME,
    'GR tolerance': RequisitionNewTemplateStandardFieldsEnum.GR_TOLERANCE,
    'Shipping Address':
        RequisitionNewTemplateStandardFieldsEnum.SHIPPING_ADDRESS,
    Vendor: RequisitionNewTemplateStandardFieldsEnum.VENDOR,
    'Additional information':
        RequisitionNewTemplateStandardFieldsEnum.ADDITIONAL_INFORMATION,
};
export enum RequisitionNewStandardSections {
    REQUISITION_DETAILS = 'REQUISITION_DETAILS',
    ITEM_TERMS = 'ITEM_TERMS',
    VENDOR_INFORMATION = 'VENDOR_INFORMATION',
    PAYMENT_AND_DELIVERY_TERMS = 'PAYMENT_AND_DELIVERY_TERMS',
    ADDITIONAL_DETAILS = 'ADDITIONAL_DETAILS',
}

export type RequisitionNewTemplateStandardFieldSettingsObj = {
    [RequisitionNewTemplateStandardFieldsEnum.REQUISITION_CREATOR]: IRequisitionNewTemplateStandardFieldOptions;
    [RequisitionNewTemplateStandardFieldsEnum.REQUISITION_ID]: IRequisitionNewTemplateStandardFieldOptions;
    [RequisitionNewTemplateStandardFieldsEnum.REQUISITION_NAME]: IRequisitionNewTemplateStandardFieldOptions;
    [RequisitionNewTemplateStandardFieldsEnum.REQUISITION_STATUS]: IRequisitionNewTemplateStandardFieldOptions;
    [RequisitionNewTemplateStandardFieldsEnum.CURRENCY]: IRequisitionNewTemplateStandardFieldOptions;
    [RequisitionNewTemplateStandardFieldsEnum.BUYER_ENTITY]: IRequisitionNewTemplateStandardFieldOptions;
    [RequisitionNewTemplateStandardFieldsEnum.DELIVERY_DATE]: IRequisitionNewTemplateStandardFieldOptions;
    [RequisitionNewTemplateStandardFieldsEnum.QUANTITY]: IRequisitionNewTemplateStandardFieldOptions;
    [RequisitionNewTemplateStandardFieldsEnum.COST_CENTER]: IRequisitionNewTemplateStandardFieldOptions;
    [RequisitionNewTemplateStandardFieldsEnum.GL]: IRequisitionNewTemplateStandardFieldOptions;
    [RequisitionNewTemplateStandardFieldsEnum.PROJECT]: IRequisitionNewTemplateStandardFieldOptions;
    [RequisitionNewTemplateStandardFieldsEnum.TAX]: ITemplateTaxesOptions;
    [RequisitionNewTemplateStandardFieldsEnum.ADDITIONAL_COSTS]: ITemplateAdditionalCostOptions;
    [RequisitionNewTemplateStandardFieldsEnum.DISCOUNT]: ITemplateDiscountOptions;
    [RequisitionNewTemplateStandardFieldsEnum.TARGET_RATE]: IRequisitionNewTemplateStandardFieldOptions;
    [RequisitionNewTemplateStandardFieldsEnum.PAYMENT_TERMS]: ITemplatePaymentTermsOptions;
    [RequisitionNewTemplateStandardFieldsEnum.INCOTERMS]: IRequisitionNewTemplateStandardFieldOptions;
    [RequisitionNewTemplateStandardFieldsEnum.LEAD_TIME]: IRequisitionNewTemplateStandardFieldOptions;
    [RequisitionNewTemplateStandardFieldsEnum.GR_TOLERANCE]: IRequisitionNewTemplateStandardFieldOptions;
    [RequisitionNewTemplateStandardFieldsEnum.SHIPPING_ADDRESS]: IRequisitionNewTemplateStandardFieldOptions;
    [RequisitionNewTemplateStandardFieldsEnum.VENDOR]: IRequisitionNewTemplateStandardFieldOptions;
    [RequisitionNewTemplateStandardFieldsEnum.ADDITIONAL_INFORMATION]: IRequisitionNewTemplateStandardFieldOptions;
};

export type IRequisitionNewTemplateStandardFieldOptionsBase = {
    label: string;
    description: string;
    buyerSettings: {
        showField: boolean;
        required: boolean;
    };
    sellerSettings: {
        showField: boolean;
        negotiable: boolean;
        isMandatory: boolean;
    };
    fieldLevel: 'OTHER' | 'DELIVER_SCHEDULE' | 'ITEM';
};

export type IRequisitionNewTemplateStandardFieldOptions =
    IRequisitionNewTemplateStandardFieldOptionsBase & {
        standardFieldCategory: 'STANDARD';
    };

export interface IRFQTemplateFieldSetting {
    standardFields: RequisitionNewTemplateStandardFieldSettingsObj;
    customFields: {
        [field: string]: ITemplateCustomFieldOptions;
    };
}

export type IRequisitionNewTemplateSections = {
    [section in RequisitionNewStandardSections]: IRequisitionNewTemplateSectionDetails;
};

export interface IRequisitionNewTemplateSectionDetails {
    label: string;
    fieldList: ISectionField[];
}

export type ISectionField =
    | {
          fieldType: 'STANDARD';
          field: RequisitionNewTemplateStandardFieldsEnum;
      }
    | {
          fieldType: 'CUSTOM';
          field: string;
      };

export interface IRequisitionNewTemplate {
    templateId: string;
    templateName: string;
    fieldSettings: IRFQTemplateFieldSetting;
    sections: IRequisitionNewTemplateSections;
}
