import { IUserDetails } from '../../../Models/UserDetails.model';
import { get, post, put } from '../../../Utils/api';
import {
    IPostUserProfileDetails,
    IUserManagerDetails,
} from '../Interfaces/UserProfile.model';

export const postNewUserDetails = async (data: IUserDetails) => {
    let payload = {
        name: data.name,
        description: data.description,
        homepage: data.defaults!.homepage,
        preferred_timezone: data.defaults!.timezone,
        number_format: data.defaults!.number_format,
        primary_entity: data.defaults!.primary_entity_id,
        contacts: data.contacts,
    };
    let resp = put<IPostUserProfileDetails, any>(
        `/organization/users/update/`,
        payload
    );
    return resp;
};

export const postResendVerificationEmail = async (
    user_id: string,
    email: string
) => {
    try {
        await post<{}, any>(
            `/authentication/user/${user_id}/verification/resend/`,
            {
                email: email,
            }
        );
        return true;
    } catch (err) {
        return false;
    }
};

export const getCurrentUsersManager = async (
    user_id: string
): Promise<IUserManagerDetails[]> => {
    let resp = await get<{ parent_user_list: IUserManagerDetails[] }>(
        `/organization/user_tree?child_user_id=${user_id}`
    );
    if ('parent_user_list' in resp.data) {
        return resp.data.parent_user_list;
    } else {
        return [];
    }
};

export const updatePasswordService = async (payload: {
    old_password: string;
    new_password: string;
    access_token: string;
}) => {
    await post<any, any>(`/organization/users/password/change/`, payload);
};
