import { Box, InputAdornment, TextField } from '@mui/material';
import { debounce } from 'lodash';
import { useContext, useEffect, useMemo, useState } from 'react';
import FWAutocomplete from '../../../Common/FWAutocomplete';
import { FWMenuItem } from '../../../Common/FWCustomMenu';
import { FWTypography } from '../../../Common/FWTypograhy';
import { AuthContext } from '../../../Contexts/AuthContext';
import { StyledRFQItemNumberDiv } from '../../../Events/Constants/Buyer/RFQHelperFunctions';
import { itemIsDisabled } from '../../../Events/Containers/Buyer/AddBuyerMasterItemContainer';
import { IBuyerItemList } from '../../../Events/Interfaces/Buyer/RFQ.model';
import { CartItemDetails } from '../../Interfaces/itemCartItemInterfaces';

export interface IBlankItemSelectionProps {
    itemCount: number;
    disableNewItemSelection: boolean;
    onShowMoreItems: () => void;
    hasMoreItemsinList: boolean;
    sortedBuyerMasterItemList: IBuyerItemList[];
    fetchAndLoadSelectedItemDetails: (
        enterprise_item_id: string,
        itemAlreadyPresentInTab: CartItemDetails | null,
        additionalQuantityForCurrentItem?: number
    ) => Promise<void>;
    onSearchItemList: (search_text: string) => void;
    searchText: string;
}

export default function BlankItemSelection({
    itemCount,
    disableNewItemSelection,
    onShowMoreItems,
    hasMoreItemsinList,
    sortedBuyerMasterItemList,
    searchText: searchItemText,
    fetchAndLoadSelectedItemDetails,
    onSearchItemList,
}: IBlankItemSelectionProps) {
    const [selectedItem, setSelectedItem] = useState<IBuyerItemList | null>(
        null
    );
    const [searchText, setSearchText] = useState(searchItemText);

    const { authData } = useContext(AuthContext);

    const OPTIONS = useMemo(() => {
        /* DISABLED FOR SANDBOX_DEPLOY */
        return !hasMoreItemsinList &&
            authData.details &&
            authData.details.role === 'ADMIN'
            ? [...sortedBuyerMasterItemList]
            : sortedBuyerMasterItemList;
    }, [authData.details, hasMoreItemsinList, sortedBuyerMasterItemList]);

    const debounceSearch = useMemo(
        () =>
            debounce(async (searchText: string) => {
                onSearchItemList(searchText);
            }, 500),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    useEffect(() => {
        debounceSearch(searchText);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchText]);

    return (
        <>
            <StyledRFQItemNumberDiv
                width={{
                    xs: '10rem',
                    sm: '30rem',
                    lg: '80%',
                }}
            >
                <FWTypography className="itemBoxHeaderNumber">
                    {itemCount + 1 < 10 ? '0' + (itemCount + 1) : itemCount + 1}
                </FWTypography>
                <FWAutocomplete
                    disabled={disableNewItemSelection}
                    fullWidth
                    // clearOnBlur={true}
                    noOptionsText={<FWTypography>No items found</FWTypography>}
                    value={selectedItem}
                    onChange={(event, value) => {
                        if (value === null) {
                            setSelectedItem(null);
                        } else if (value.entity_item_id !== '') {
                            setSelectedItem(value);

                            fetchAndLoadSelectedItemDetails(
                                value.enterprise_item.enterprise_item_id,
                                null
                            );
                            // a function that gets Item details from the api
                        }
                    }}
                    onInputChange={(event: any, value) => {
                        if (event === null) return;

                        if (event?._reactName === 'onChange')
                            setSearchText(value);
                    }}
                    popupIcon={
                        <Box position={'relative'}>
                            <Box fontSize="1rem">
                                <i className="bi bi-chevron-down"></i>
                            </Box>
                        </Box>
                    }
                    options={OPTIONS}
                    getOptionLabel={(option) => {
                        if (option.entity_item_id !== '') {
                            let specsString =
                                ' | ' +
                                option.enterprise_item.attributes
                                    .map(
                                        (attr: any) =>
                                            `${
                                                attr.attribute_name
                                            }: ${attr.attribute_values
                                                .map((v: any) => v.value)
                                                .join(' / ')}`
                                    )
                                    .join(' | ');
                            return `${option.enterprise_item.name}${specsString} | ${option.enterprise_item.code}`;
                        } else {
                            return `Add ${
                                searchText === ''
                                    ? 'new item'
                                    : `"${searchText}"`
                            } to directory`;
                        }
                    }}
                    renderOption={(props, option: IBuyerItemList) => {
                        const isShowMore = option.hasNext;
                        const isDisabled = itemIsDisabled(option);
                        const isAddNewOption =
                            !option.hasNext && option.entity_item_id === '';
                        let specsString =
                            isAddNewOption ||
                            option.enterprise_item.attributes.length === 0
                                ? ''
                                : ' | ' +
                                  option.enterprise_item.attributes
                                      .map(
                                          (attr: any) =>
                                              `${
                                                  attr.attribute_name
                                              }: ${attr.attribute_values
                                                  .map((v: any) => v.value)
                                                  .join(' / ')}`
                                      )
                                      .join(' | ');
                        return (
                            <FWMenuItem
                                {...props}
                                disabled={isDisabled}
                                sx={{
                                    fontStyle: isDisabled
                                        ? 'italic'
                                        : undefined,
                                    color: isDisabled
                                        ? 'text.secondary'
                                        : isAddNewOption
                                        ? 'primary.main'
                                        : undefined,
                                }}
                            >
                                {isAddNewOption ? (
                                    <FWTypography
                                        sx={{
                                            wordWrap: 'break-word',
                                            whiteSpace: 'pre-wrap',
                                        }}
                                    >
                                        {`Add ${
                                            searchText === ''
                                                ? 'new item'
                                                : `"${searchText}"`
                                        } to directory`}
                                    </FWTypography>
                                ) : isShowMore ? (
                                    <FWTypography
                                        sx={{
                                            wordWrap: 'break-word',
                                            whiteSpace: 'pre-wrap',
                                            color: 'primary.main',
                                            width: '100%',
                                        }}
                                        onClick={(e: React.MouseEvent) => {
                                            e.stopPropagation();
                                            onShowMoreItems();
                                        }}
                                    >
                                        Show more results...
                                    </FWTypography>
                                ) : (
                                    <FWTypography
                                        sx={{
                                            wordWrap: 'break-word',
                                            whiteSpace: 'pre-wrap',
                                        }}
                                    >
                                        {`${isDisabled ? '(Disabled) ' : ''}${
                                            option.enterprise_item.name
                                        } ${specsString} | ${
                                            option.enterprise_item.code
                                        }`}
                                    </FWTypography>
                                )}
                            </FWMenuItem>
                        );
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            InputProps={{
                                ...params.InputProps,
                                startAdornment: (
                                    <Box marginLeft={1}>
                                        <InputAdornment position="start">
                                            <i className="bi bi-search"></i>
                                        </InputAdornment>
                                    </Box>
                                ),
                            }}
                        />
                    )}
                    filterOptions={(options, state) => {
                        return options;
                    }}
                />
            </StyledRFQItemNumberDiv>
        </>
    );
}
