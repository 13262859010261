import { ChartDownloadColumn } from '../Interfaces/IHistoricalAnalytics.model';

/**
 * Kept these as functions rather than constants so that
 * we can add params for excluding specific fields or including
 * specific additional fields.
 */

export const getStandardPoColumns = (): ChartDownloadColumn[] => {
    const columns: ChartDownloadColumn[] = [
        {
            field: 'po__custom_purchase_order_id',
            label: 'PO ID',
        },
        {
            field: 'po__status',
            label: 'PO status',
        },
        {
            field: 'po__submission_datetime',
            label: 'PO issue date',
        },
        {
            field: 'currency_code__currency_code_abbreviation',
            label: 'Currency',
        },
        {
            field: 'po__pricing_information__total',
            label: 'Total PO value',
        },
        {
            field: 'po__item_count',
            label: 'Item count',
        },
        {
            field: 'seller_entity__entity_name',
            label: 'Vendor name',
        },
        {
            field: 'enterprise_vendor_master__vendor_code',
            label: 'Vendor code',
        },
        {
            field: 'enterprise_vendor_master__tags',
            label: 'Vendor tags',
        },
        {
            field: 'po__created_by_user__name',
            label: 'PO creator',
        },
        {
            field: 'po__final_buyer_approval__name',
            label: 'PO approver',
        },
        {
            field: 'event__custom_event_id',
            label: 'Event ID',
        },
        {
            field: 'event__event_type',
            label: 'Event type',
        },
        {
            field: 'event__original_event_creator__name',
            label: 'Event creator',
        },
        {
            field: 'rfq_event__final_approver__name',
            label: 'Event approver',
        },
        {
            field: 'event__live_event_start_datetime',
            label: 'Event start date',
        },
        {
            field: 'po__buyer_information__shipping_address',
            label: 'PO shipping address',
        },
        {
            field: 'po__buyer_information__billing_address',
            label: 'PO billing address',
        },
        {
            field: 'po__pricing_information__discount_percentage',
            label: 'Discount %',
        },
    ];
    return columns;
};

export const getStandardPoItemColumns = (): ChartDownloadColumn[] => {
    const columns: ChartDownloadColumn[] = [
        {
            field: 'po__custom_purchase_order_id',
            label: 'PO ID',
        },
        {
            field: 'po__status',
            label: 'PO status',
        },
        {
            field: 'po__submission_datetime',
            label: 'PO issue date',
        },
        {
            field: 'enterprise_item__code',
            label: 'Item code',
        },
        {
            field: 'enterprise_item__name',
            label: 'Item name',
        },
        {
            field: 'enterprise_item__tags',
            label: 'Item tags',
        },
        {
            field: 'measurement_unit__measurement_unit_primary_name',
            label: 'Measurement unit',
        },
        {
            field: 'po_item__quantity_information__quantity',
            label: 'PO item quantity',
        },
        {
            field: 'currency_code__currency_code_abbreviation',
            label: 'Currency',
        },
        {
            field: 'po_item__pricing_information__total',
            label: 'PO item total value',
        },
        {
            field: 'po__pricing_information__discount_percentage',
            label: 'PO discount %',
        },
        {
            field: 'seller_entity__entity_name',
            label: 'Vendor name',
        },
        {
            field: 'enterprise_vendor_master__vendor_code',
            label: 'Vendor code',
        },
        {
            field: 'enterprise_vendor_master__tags',
            label: 'Vendor tags',
        },
        {
            field: 'entity_vendor_master__status',
            label: 'Vendor preference status',
        },
        {
            field: 'po_item__payment_terms__term',
            label: 'Payment terms',
        },
        {
            field: 'po_item__payment_terms__period',
            label: 'Payment terms period',
        },
        {
            field: 'po_item__payment_terms__applied_from',
            label: 'Payment terms applied from',
        },
        {
            field: 'po_item__procurement_information__prepayment_percentage',
            label: 'Prepayment %',
        },
        {
            field: 'po_item__procurement_information__lead_time',
            label: 'Lead time',
        },
        {
            field: 'po_item__procurement_information__lead_time_period',
            label: 'Lead time period',
        },
        {
            field: 'po_item__incoterm__incoterm_abbreviation',
            label: 'Incoterms',
        },
        {
            field: 'po__created_by_user__name',
            label: 'PO Creator',
        },
        {
            field: 'po__final_buyer_approval__name',
            label: 'PO Approver',
        },
        {
            field: 'event__custom_event_id',
            label: 'Event ID',
        },
        {
            field: 'event__event_type',
            label: 'Event type',
        },
        {
            field: 'event__original_event_creator__name',
            label: 'Event creator',
        },
        {
            field: 'rfq_event__final_approver__name',
            label: 'Event approver',
        },
        {
            field: 'event__live_event_start_datetime',
            label: 'Event start date',
        },
        {
            field: 'po__buyer_information__shipping_address',
            label: 'PO shipping address',
        },
        {
            field: 'po__buyer_information__billing_address',
            label: 'PO billing address',
        },
    ];
    return columns;
};

export const getStandardInvoiceItemColumns = (): ChartDownloadColumn[] => {
    const columns: ChartDownloadColumn[] = [
        {
            field: 'invoice__custom_invoice_id',
            label: 'Invoice ID',
        },
        {
            field: 'invoice__status',
            label: 'Invoice status',
        },
        {
            field: 'invoice__invoice_date',
            label: 'Invoice issue date',
        },
        {
            field: 'invoice__dispatch_date',
            label: 'Invoice dispatch date',
        },
        {
            field: 'invoice__created_by_user__name',
            label: 'Invoice creator',
        },
        {
            field: 'po__custom_purchase_order_id',
            label: 'PO ID',
        },
        {
            field: 'po__status',
            label: 'PO status',
        },
        {
            field: 'po__submission_datetime',
            label: 'PO issue date',
        },
        {
            field: 'enterprise_item__code',
            label: 'Item code',
        },
        {
            field: 'enterprise_item__name',
            label: 'Item name',
        },
        {
            field: 'enterprise_item__tags',
            label: 'Item tags',
        },
        {
            field: 'measurement_unit__measurement_unit_primary_name',
            label: 'Measurement unit',
        },
        {
            field: 'invoice_item__quantity_information__quantity',
            label: 'Invoice item quantity',
        },
        {
            field: 'currency_code__currency_code_abbreviation',
            label: 'Currency',
        },
        {
            field: 'invoice_item__pricing_information__total_amount',
            label: 'Invoice item total value',
        },
        {
            field: 'invoice_item__payment_due_date',
            label: 'Payment due date',
        },
        {
            field: 'invoice_item__payment_completed_datetime',
            label: 'Payment completion date',
        },
        {
            field: 'invoice_item__pricing_information__rejection_amount',
            label: 'Total rejection value',
        },
        {
            field: 'invoice_item__fulfilment_information__gr_rejection',
            label: 'Total GR rejected quantity',
        },
        {
            field: 'invoice_item__fulfilment_information__qc_rejection',
            label: 'Total QC rejected quantity',
        },
        {
            field: 'seller_entity__entity_name',
            label: 'Vendor name',
        },
        {
            field: 'enterprise_vendor_master__vendor_code',
            label: 'Vendor code',
        },
        {
            field: 'enterprise_vendor_master__tags',
            label: 'Vendor tags',
        },
        {
            field: 'entity_vendor_master__status',
            label: 'Vendor preference status',
        },
        {
            field: 'invoice_item__payment_terms__term',
            label: 'Payment terms',
        },
        {
            field: 'invoice_item__payment_terms__period',
            label: 'Payment terms period',
        },
        {
            field: 'invoice_item__payment_terms__applied_from',
            label: 'Payment terms applied from',
        },
        {
            field: 'po_item__pricing_information__total',
            label: 'PO item total value',
        },
        {
            field: 'po_item__procurement_information__lead_time',
            label: 'Lead time',
        },
        {
            field: 'po_item__procurement_information__lead_time_period',
            label: 'Lead time period',
        },
        {
            field: 'invoice_item__incoterm__incoterm_abbreviation',
            label: 'Incoterms',
        },
        {
            field: 'po__created_by_user__name',
            label: 'PO creator',
        },
        {
            field: 'po__final_buyer_approval__name',
            label: 'PO approver',
        },
        {
            field: 'event__custom_event_id',
            label: 'Event ID',
        },
        {
            field: 'event__event_type',
            label: 'Event type',
        },
        {
            field: 'po__buyer_information__shipping_address',
            label: 'PO shipping address',
        },
        {
            field: 'po__buyer_information__billing_address',
            label: 'PO billing address',
        },
    ];
    return columns;
};

export const getStandardSellerEventAccessColumns =
    (): ChartDownloadColumn[] => {
        const columns: ChartDownloadColumn[] = [
            {
                field: 'event__custom_event_id',
                label: 'Event ID',
            },
            {
                field: 'event__event_type',
                label: 'Event type',
            },
            {
                field: 'event__original_event_creator__name',
                label: 'Event creator',
            },
            {
                field: 'rfq_event__final_approver__name',
                label: 'Event approver',
            },
            {
                field: 'event__live_event_start_datetime',
                label: 'Event submission datetime (live_event_start_datetime)',
            },
            {
                field: 'rfq_event__final_approver__name',
                label: 'Event approver',
            },
            {
                field: 'seller_entity__entity_name',
                label: 'Vendor name',
            },
            {
                field: 'enterprise_vendor_master__vendor_code',
                label: 'Vendor code',
            },
            {
                field: 'enterprise_vendor_master__tags',
                label: 'Vendor tags (multiple columns?)',
            },
            {
                field: 'seller_event_access__status',
                label: 'Seller event status',
            },
        ];
        return columns;
    };

export const getStandardEventColumns = (): ChartDownloadColumn[] => {
    const columns: ChartDownloadColumn[] = [
        {
            field: 'event__custom_event_id',
            label: 'Event ID',
        },
        {
            field: 'event__event_type',
            label: 'Event type',
        },
        {
            field: 'event__original_event_creator__name',
            label: 'Event creator',
        },
        {
            field: 'rfq_event__final_approver__name',
            label: 'Event approver',
        },
        {
            field: 'event__live_event_start_datetime',
            label: 'Event start date',
        },
        {
            field: 'event__status',
            label: 'Event status',
        },
        {
            field: 'rfq_event__event_end_datetime',
            label: 'Event end date',
        },
        {
            field: 'rfq_event__address_information__billing_address',
            label: 'Billing address',
        },
        {
            field: 'rfq_event__address_information__shipping_address',
            label: 'Shipping address',
        },
    ];
    return columns;
};
