import { Box, Chip } from '@mui/material';
import { useState } from 'react';
import VendorContactDetailedPopup from './VendorContactDetailedPopup';

interface IVendorContactDetailChipLayout {
    type: 'CHIP';
}

interface IVendorContactDetailCustom {
    type: 'CUSTOM';
    customComponent: React.ReactElement;
}
interface IVendorContactDetailChip {
    sellerContact: {
        vendor_contact_id: string;
        full_name: string;
        primary_email: string;
        emails: {
            type: string;
            email: string;
        }[];
        phone_numbers: string[];
        tags: string[];
    }[];
    display?: IVendorContactDetailChipLayout | IVendorContactDetailCustom;
}
export const VendorContactDetailChip = ({
    sellerContact,
    display = { type: 'CHIP' },
}: IVendorContactDetailChip) => {
    const [anchorEl, setAnchorEl] = useState<HTMLAnchorElement | null>(null);
    const handleClick = (event: any) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    if (!sellerContact || sellerContact.length === 0) return <></>;

    return (
        <>
            <Box
                component="span"
                onClick={handleClick}
                style={{ cursor: 'pointer' }}
            >
                {display.type === 'CHIP' ? (
                    <Chip
                        size="small"
                        label={
                            sellerContact.length === 1
                                ? sellerContact[0].full_name
                                : `${sellerContact.length} Contacts`
                        }
                        sx={{
                            marginRight: '0.25rem',
                            backgroundColor: ' #007AFF1A',
                        }}
                    ></Chip>
                ) : (
                    display.customComponent
                )}
            </Box>

            <VendorContactDetailedPopup
                sellerContact={sellerContact}
                anchorEl={anchorEl}
                handleClose={handleClose}
            />
        </>
    );
};
