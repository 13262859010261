export const ChartLocaleMap = {
    INR: 'en-IN',
    USD: 'en-US',
    EUR: 'en-GB',
};

export const CurrencySymbolMap = {
    INR: '₹',
    USD: '$',
    EUR: '€',
};
