import { useEffect, useState } from 'react';
import {
    IRfqTemplate,
    TemplateStandardFieldsEnum,
} from '../../Global/Interfaces/TemplateInterface';
import { extractFields } from '../Helpers/ItemCartIntegration';

const useIntegrationWarningHook = (
    reqTemplate: IRfqTemplate,
    rfqTemplate: IRfqTemplate | undefined,
    requisition: any,
    handleFieldsForExportChange: (fields: any[], requistionId: string) => void
) => {
    const [fields, setFields] = useState<any[]>([]);
    const keyMap = ['PROJECT', 'GL', 'COST_CENTER'];
    const processFields = () => {
        try {
            let newFields: any[] = [];
            if (rfqTemplate) {
                const reqFields = extractFields(reqTemplate.sections);
                const rfqFields = extractFields(rfqTemplate.sections);
                const eventDetails =
                    rfqTemplate.sections.EVENT_DETAILS.fieldList.map(
                        (data) => data.field
                    );
                reqFields.forEach((field) => {
                    if (
                        (eventDetails.includes(field) &&
                            !keyMap.includes(field)) ||
                        !rfqTemplate.fieldSettings.standardFields[
                            field as TemplateStandardFieldsEnum
                        ]
                    ) {
                    } else {
                        newFields = [
                            ...newFields,
                            {
                                label: reqTemplate.fieldSettings.standardFields[
                                    field as TemplateStandardFieldsEnum
                                ]
                                    ? reqTemplate.fieldSettings.standardFields[
                                          field as TemplateStandardFieldsEnum
                                      ].label
                                    : reqTemplate.fieldSettings.customFields[
                                          field
                                      ].label,
                                isChecked: true,
                                fieldExists:
                                    (rfqFields.includes(field) ||
                                        field === 'DELIVERY_DATE') &&
                                    (rfqTemplate.fieldSettings.standardFields[
                                        field as TemplateStandardFieldsEnum
                                    ]
                                        ? rfqTemplate.fieldSettings
                                              .standardFields[
                                              field as TemplateStandardFieldsEnum
                                          ].buyerSettings.showField
                                        : rfqTemplate.fieldSettings
                                              .customFields[field].buyerSettings
                                              .showField)
                                        ? true
                                        : false,
                                heirarchy: keyMap.includes(field)
                                    ? validatePGC(field)
                                    : true,
                                fieldType: reqTemplate.fieldSettings
                                    .standardFields[
                                    field as TemplateStandardFieldsEnum
                                ]
                                    ? 'STANDARD'
                                    : 'CUSTOM',
                            },
                        ];
                    }
                });

                // newFields = [
                //     ...newFields,
                //     {
                //         label: reqTemplate.fieldSettings.standardFields
                //             .DELIVERY_DATE.label,
                //         isChecked: true,
                //         fieldExists:
                //             rfqTemplate.fieldSettings.standardFields
                //                 .DELIVERY_DATE.buyerSettings.showField,
                //         heirarchy: true,
                //         fieldType: 'STANDARD',
                //     },
                // ];
            }
            // let rfqAI =
            //     rfqTemplate?.fieldSettings.standardFields
            //         .ADDITIONAL_INFORMATION;
            // let reqAI =
            //     reqTemplate?.fieldSettings.standardFields
            //         .ADDITIONAL_INFORMATION;
            // newFields = [
            //     ...newFields,
            //     {
            //         label: reqAI.label,
            //         isChecked: true,
            //         fieldExists: rfqAI?.buyerSettings.showField,
            //         heirarchy: true,
            //         fieldTyoe: 'STANDARD',
            //     },
            // ];
            // console.log(newFields);
            setFields(newFields);
        } catch (err) {}
    };

    useEffect(() => {
        processFields();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rfqTemplate]);
    const validatePGC = (field: string) => {
        if (rfqTemplate) {
            const reqSettings =
                reqTemplate.fieldSettings.standardFields[
                    field as TemplateStandardFieldsEnum
                ];
            const rfqSettings =
                rfqTemplate.fieldSettings.standardFields[
                    field as TemplateStandardFieldsEnum
                ];
            if (reqSettings.fieldLevel === rfqSettings.fieldLevel) return true;
            else if (reqSettings.fieldLevel === 'OTHER') return true;
            else if (
                reqSettings.fieldLevel === 'ITEM' &&
                rfqSettings.fieldLevel === 'DELIVERY_SCHEDULE'
            )
                return true;
            else return false;
        }
    };
    // useEffect(() => {
    //     let temp = fields.filter(
    //         (field) => field.isChecked && field.fieldExists && field.heirarchy
    //     );
    //     if (setFieldsForExport) {
    //         if (
    //             fieldsForExport &&
    //             fieldsForExport[requisition.requisition_id]
    //         ) {
    //             delete fieldsForExport[requisition.requisition_id];
    //         }
    //         setFieldsForExport({
    //             ...fieldsForExport,
    //             [requisition.requisition_id]: temp,
    //         });
    //     }
    //     //eslint-disable-next-line
    // }, [fields, setFieldsForExport]);
    useEffect(() => {
        try {
            if (fields.length !== 0) {
                let temp = fields.filter(
                    (field) =>
                        field.isChecked && field.fieldExists && field.heirarchy
                );
                handleFieldsForExportChange(temp, requisition.requisition_id);
            }
        } catch (err) {}
        //eslint-disable-next-line
    }, [fields]);
    return {
        fields,
        setFields,
        validatePGC,
    };
};

export default useIntegrationWarningHook;

// Object.values(RfqStandardSections).forEach((section) => {
//     reqFields = reqTemplate.sections[
//         section as RfqStandardSections
//     ].fieldList.map((field) => field.field);
//     rfqFields = rfqTemplate.sections[
//         section as RfqStandardSections
//     ].fieldList.map((field) => field.field);
//     reqFields.forEach((field) => {
//         if (
//             section === RfqStandardSections.EVENT_DETAILS &&
//             !keyMap.includes(field)
//         ) {
//         } else {
//             newFields = [
//                 ...newFields,
//                 {
//                     label: reqTemplate.fieldSettings.standardFields[
//                         field as TemplateStandardFieldsEnum
//                     ]
//                         ? reqTemplate.fieldSettings.standardFields[
//                               field as TemplateStandardFieldsEnum
//                           ].label
//                         : reqTemplate.fieldSettings.customFields[
//                               field as TemplateStandardFieldsEnum
//                           ].label,
//                     isChecked: true,
//                     showField: reqTemplate.fieldSettings
//                         .standardFields[
//                         field as TemplateStandardFieldsEnum
//                     ]
//                         ? reqTemplate.fieldSettings.standardFields[
//                               field as TemplateStandardFieldsEnum
//                           ].buyerSettings.showField
//                         : reqTemplate.fieldSettings.customFields[
//                               field as TemplateStandardFieldsEnum
//                           ].buyerSettings.showField,
//                     type: rfqFields.includes(field) ? 1 : 2,
//                     fieldLevel: reqTemplate.fieldSettings
//                         .standardFields[
//                         field as TemplateStandardFieldsEnum
//                     ]
//                         ? reqTemplate.fieldSettings.standardFields[
//                               field as TemplateStandardFieldsEnum
//                           ].fieldLevel
//                         : reqTemplate.fieldSettings.customFields[
//                               field as TemplateStandardFieldsEnum
//                           ].fieldLevel,
//                     heirarchy: keyMap.includes(field)
//                         ? validatePGC(field)
//                         : true,
//                 },
//             ];
//         }
//     });
// });
