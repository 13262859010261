import { executeFunction, executeOperator } from './supportedFunctions';
import { TokenType, type TokenNode } from './types';

export function evaluateTokenNodes(
    tokenNodes: TokenNode[],
    getPropertyValue: (v: string | string[]) => string,
    customField: string
): string {
    let result = '';
    for (const node of tokenNodes) {
        result += evaluateNode(node, getPropertyValue, customField);
    }
    return result;
}

function getNestedValue(node: any): string {
    if (node.value !== '') {
        return node.value;
    } else if (node.innerNodes && node.innerNodes.length > 0) {
        return getNestedValue(node.innerNodes[0]);
    } else {
        return '';
    }
}

function evaluateNode(
    node: TokenNode,
    getPropertyValue: (v: string | string[]) => string,
    customfield: string
): string {
    if (node.type === TokenType.Operator) {
        const parameters = node.innerNodes.map((x) =>
            evaluateNode(x, getPropertyValue, customfield)
        );
        return executeOperator(node.value, parameters);
    } else if (node.type === TokenType.FunctionName) {
        if (node.value.toLowerCase() === 'datetime') {
            if (node.innerNodes.length > 1) {
                const parameters = node.innerNodes.map((x) =>
                    getNestedValue(x)
                );
                return executeFunction(node.value, [
                    getPropertyValue(parameters),
                ]);
            }
        }
        const parameters = node.innerNodes.map((x) =>
            evaluateNode(x, getPropertyValue, customfield)
        );

        return executeFunction(node.value, parameters);
    } else if (node.type === TokenType.ReferenceName) {
        return getPropertyValue(node.value);
    } else if (node.type === TokenType.String) {
        return node.value;
    } else if (node.type === TokenType.Number) {
        return node.value;
    } else if (node.type === TokenType.Boolean) {
        return node.value;
    } else if (node.type === TokenType.Group) {
        return node.innerNodes.reduce(
            (out, childNode) =>
                out + evaluateNode(childNode, getPropertyValue, customfield),
            ''
        );
    }
    return '';
}
