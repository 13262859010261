import { del, post } from '../../Utils/api';

interface clonePoGroupResp {
    po_group_id: string;
}

export const clonePoGroupApi = (
    poGroupSubEventId: string,
    newPoGroupName: string
): Promise<clonePoGroupResp> => {
    return new Promise(async (resolve, reject) => {
        try {
            let clone_resp = await post<string, any>(
                `/events/po_group/${poGroupSubEventId}/clone/`,
                { event_name: newPoGroupName }
            );
            resolve({
                ...clone_resp.data,
            });
        } catch (err) {
            reject();
        }
    });
};

export const deletePoGroupApi = (poGroupSubEventId: string): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            let del_resp = await del(
                `/events/po_group/${poGroupSubEventId}/delete/`
            );
            resolve({
                ...del_resp.data,
            });
        } catch (err) {
            reject();
        }
    });
};
