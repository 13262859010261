import { Avatar, Box, Chip } from '@mui/material';
import React from 'react';
import { FWTypography } from '../../../../Common/FWTypograhy';
import CommaSeparatedLinks from '../../../../Organizations/Admin/Components/CommaSeparatedLinks';
import { IEntityDetails } from '../../../../Global/Interfaces/EntityInterface';
import { useFetchUrlUsingAttachmentId } from '../../../../Global/Hooks/useFetchUrlUsingAttachmentId';
import { VLNProfileStyledBox } from '../../Common/VLNProfileStyledBox';

// const ProgressData = [
//     {
//         name: 'Pricing',
//         value: 50,
//     },
//     {
//         name: 'Quality',
//         value: 70,
//     },
//     {
//         name: 'On-time delivery',
//         value: 47,
//     },
// ];

const VLNProfileAboutMainInfo = ({
    sellerEntityDetails,
    productTags,
}: {
    sellerEntityDetails: IEntityDetails;
    productTags: string[];
}) => {
    const { url } = useFetchUrlUsingAttachmentId(
        sellerEntityDetails.entity_logo?.[0]?.attachment_id ?? ''
    );

    return (
        <VLNProfileStyledBox
            // padding={4}
            // marginBottom={4}
            className="flex flex--aifs flex--jcsb"
            width={'100%'}
            gap={5}
            sx={{
                md: {
                    flexDirection: 'column',
                },
            }}
        >
            <Box className="flex" flex={1}>
                <Avatar
                    sx={{
                        width: '100px',
                        height: '100px',
                        marginRight: '20px',
                    }}
                    src={url || ''}
                    alt={sellerEntityDetails.entity_logo?.[0]?.file_name}
                />
                <div>
                    <FWTypography variant={'h4'} fontWeight={500}>
                        {sellerEntityDetails.entity_name}
                    </FWTypography>
                    <FWTypography color={'text.secondary'} marginBottom={0.5}>
                        {sellerEntityDetails.enterprise_name}
                    </FWTypography>
                    <Box marginBottom={1}>
                        {sellerEntityDetails.contacts.websites?.[0] ? (
                            <CommaSeparatedLinks
                                value={
                                    sellerEntityDetails.contacts.websites?.[0]
                                }
                            />
                        ) : (
                            '-'
                        )}
                    </Box>
                    <Box className="flex" marginY={1} gap={1} flexWrap={'wrap'}>
                        {productTags.map((tag, idx) => (
                            <Chip
                                key={idx}
                                label={tag}
                                sx={{
                                    borderRadius: '12px',
                                    backgroundColor: '#E8F0FE',
                                }}
                            />
                        ))}
                    </Box>
                </div>
            </Box>
            <Box className="flex flex--aic" flex={1} gap={4}>
                {/* <Box width={'60%'} maxWidth={'500px'}>
                    {ProgressData.map(({ name, value }, idx) => (
                        <Box marginBottom={2} key={idx}>
                            <FWTypography
                                color={'text.secondary'}
                                marginBottom={0.5}
                                fontWeight={500}
                            >
                                {name}
                            </FWTypography>
                            <LinearProgress
                                variant="determinate"
                                value={value}
                            />
                        </Box>
                    ))}
                </Box>
                <div className={'flex flex--aic'}>
                    <Typography
                        variant={'h2'}
                        color={'secondary.main'}
                        fontSize={'4rem'}
                    >
                        3.5
                    </Typography>
                    &nbsp;
                    <Box color={'#FFD700'} fontSize={30}>
                        <i className="bi bi-star-fill" />
                    </Box>
                </div> */}
            </Box>
        </VLNProfileStyledBox>
    );
};

export default VLNProfileAboutMainInfo;
