import { Box, IconButton, Typography } from '@mui/material';
import React from 'react';
import { downloadFile } from '../../../../Global/Services/FileStorageService';
import { toast } from 'react-toastify';
import { IVLNAboutCertificate } from '../../../Interfaces/VendorLNInterfaces';

const CertificateBGLogo = ({ type }: { type: string }) => (
    <Box
        className={'flex flex--aic flex--jcc'}
        color={'white'}
        height={'100%'}
        sx={{ opacity: 0.6, position: 'relative' }}
    >
        <i
            className={`bi bi-filetype-${type}`}
            aria-hidden="true"
            style={{
                fontSize: '100px',
            }}
        />
    </Box>
);

const VLNProfileCertificateComponent = ({
    attachment,
}: {
    attachment: IVLNAboutCertificate;
}) => {
    const handleFileDownloadFile = async () => {
        try {
            const fileURl = await downloadFile(
                attachment.files[0].attachment_id
            );
            window.open(fileURl, '_self');
        } catch (error) {
            toast.error('Error downloading file');
        }
    };

    const fileType =
        attachment?.files[0]?.file_name.split('.').pop()?.toLowerCase() ??
        'doc';
    return (
        <Box
            borderRadius={1.5}
            // width={'100%'}
            // height={'150px'}
            bgcolor={'lightgrey'}
            sx={{
                aspectRatio: '2/1',
                border: '1px solid lightgrey',
            }}
            overflow={'hidden'}
            height={'100%'}
            className="flex flex--fdc flex--jcsb"
        >
            <Box height={'100%'} width={'100%'} overflow={'hidden'}>
                {attachment.link !== '' ? (
                    <Box
                        component={'img'}
                        height={'200px'}
                        width={'100%'}
                        sx={{
                            objectFit: 'fill',
                        }}
                        src={attachment.link}
                        alt={''}
                    />
                ) : (
                    <CertificateBGLogo type={fileType} />
                )}
            </Box>
            <Box
                className={'flex flex--aic flex--jcsb'}
                paddingX={2}
                bgcolor={'white'}
            >
                <div>
                    <Typography>{attachment.files[0].file_name}</Typography>
                    {/* <Typography color={'text.secondary'} fontSize={13}>
                        Expires: 20 Jun 2025
                    </Typography> */}
                </div>
                <IconButton color={'primary'} onClick={handleFileDownloadFile}>
                    <i className="bi bi-arrow-down-circle" />
                </IconButton>
            </Box>
        </Box>
    );
};

export default VLNProfileCertificateComponent;
