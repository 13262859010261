import { Box, DialogContent, Grid } from '@mui/material';
import { debounce } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { FWInput } from '../../../Common/FWInput';
import { FWPopupWithChildren } from '../../../Common/FWPopupWithChildren';
import { FWTypography } from '../../../Common/FWTypograhy';
import { getFWDate } from '../../../Common/Utils/DateUtils';
import IAPagination from '../../Constants/IAPagination';
import { IAPaginationContext } from '../../Helpers/IAPaginationHelpers';
import { cartTabType } from '../../Hooks/Relationship/useItemCartDashboard';
import {
    ICartItemHistoryLogs,
    ITEM_ANALYTICS_ROW_GAP,
} from '../../Interfaces/ItemAnalyticsInterfaces';
import { useGetCartItemHistoryDashboardMutation } from '../../Services/ItemAnalytics.service';

export interface ICartItemHistoryLogProps {
    open: boolean;
    handleClose: () => void;
    title: string;
    itemId: string;
}

export default function CartItemHistoryLog({
    open,
    handleClose,
    title,
    itemId,
}: ICartItemHistoryLogProps) {
    const [search, setSearch] = useState('');

    const [currentPage, setCurrentPage] = useState(1);

    const [totalPages, setTotalPages] = useState(1);

    const onPageChange = (newPage: number) => {
        setCurrentPage(newPage);
    };

    const [itemHistory, setHistory] = useState<ICartItemHistoryLogs[]>([]);

    const [fetchCartItemHistory, { data: cartItemHistory }] =
        useGetCartItemHistoryDashboardMutation({
            fixedCacheKey: 'cartItemHistory',
        });

    useEffect(() => {
        if (cartItemHistory) {
            setHistory(cartItemHistory.data);
            setTotalPages(cartItemHistory.metadata.total_pages);
        }
    }, [cartItemHistory]);

    useEffect(() => {
        if (open) {
            fetchCartItemHistory({
                items_per_page: 10,
                page_number: currentPage,
                query_data: {
                    enterprise_item_id: itemId,
                },
                search_text: search,
                sort_fields: [],
                tab: cartTabType.ALL,
            });
        }
    }, [currentPage, fetchCartItemHistory, itemId, open, search]);

    const debouncedSearch = useMemo(
        () =>
            debounce((text: string) => {
                setSearch(text);
            }, 1000),
        []
    );

    return (
        <FWPopupWithChildren
            handleClose={handleClose}
            open={open}
            title={title}
            applyTitleStyling={false}
            sx={{ paddingX: 2 }}
        >
            <DialogContent>
                <Grid container rowGap={ITEM_ANALYTICS_ROW_GAP}>
                    <Grid item xs={12} marginBottom={2}>
                        <FWInput
                            onChange={(e: any) => {
                                debouncedSearch(e.target.value);
                            }}
                            placeholder={'Search'}
                            iconStart={<i className="bi bi-search" />}
                            style={{ width: '100%' }}
                        />
                    </Grid>

                    {itemHistory?.map((itemLog, index) => {
                        return (
                            <Grid
                                key={index}
                                item
                                xs={12}
                                sx={{ backgroundColor: '#F8F8FB' }}
                                borderRadius={1}
                                padding={2}
                            >
                                <Box
                                    sx={{
                                        width: '100%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <FWTypography fontWeight={600}>
                                        {
                                            itemLog.purchase_order
                                                .seller_entity_name
                                        }
                                    </FWTypography>
                                </Box>
                                <Box
                                    sx={{
                                        width: '100%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '10px',
                                        }}
                                    >
                                        <FWTypography>
                                            Rate:{' '}
                                            {
                                                itemLog.pricing_information
                                                    .currency_symbol
                                            }{' '}
                                            {itemLog.pricing_information.price};
                                        </FWTypography>
                                        <FWTypography>
                                            Quantity:{' '}
                                            {
                                                itemLog.quantity_information
                                                    .quantity
                                            }{' '}
                                            {
                                                itemLog.quantity_information
                                                    .measurement_unit_name
                                            }
                                        </FWTypography>
                                    </Box>
                                    <FWTypography>
                                        {getFWDate(
                                            itemLog.purchase_order
                                                .submission_datetime
                                        )}
                                    </FWTypography>
                                </Box>
                            </Grid>
                        );
                    })}
                </Grid>

                <Box
                    sx={{ marginLeft: 'auto', width: 'fit-content' }}
                    marginTop={4}
                    marginBottom={2}
                >
                    <IAPaginationContext.Provider
                        value={{
                            currentPage: currentPage,
                            limitItemPerPage: 10,
                            totalPages: totalPages,
                            onPageChange: onPageChange,
                        }}
                    >
                        <IAPagination />
                    </IAPaginationContext.Provider>
                </Box>
            </DialogContent>
        </FWPopupWithChildren>
    );
}
