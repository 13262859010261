import { useContext, useState } from 'react';
import { Box, Tabs, Tab, Typography } from '@mui/material';
import { FWButton } from '../../../Common/FWButton';
import LoadingBackDrop from '../../../Components/Shared/LoadingBackdrop';
import UserAvatar from '../Components/UserAvatar';
import UserDefaults from '../Components/UserDefaults';
import UserDetails from '../Components/UserDetails';
import { validateUserProfileDetails } from '../helper/helperFunction';
import useUserProfile from '../hooks/UserProfileHook';
import { FWInput } from '../../../Common/FWInput';
import PasswordValidationList from '../../../Organizations/Signup/component/PasswordValidationList';
import { AuthContext } from '../../../Contexts/AuthContext';

export default function UserProfileContainer(props: {
    user_id: string;
    pageAfterSave?: 'HOME_PAGE' | 'PREVIOUS_PAGE';
}) {
    const {
        selectableEntities,
        selectableHomePages,
        userDetailsState,
        errors,
        updateUserDetails,
        saveDetails,
        loading,
        managersList,
        updatePassowrdData,
        passwordUpdateChangeHandler,
        updatePasswordErrors,
        updatePassword,
    } = useUserProfile(props.user_id, props.pageAfterSave ?? 'HOME_PAGE');

    const [tabValue, setTabValue] = useState<number>(0);

    const { authData } = useContext(AuthContext);

    const handleUpdate = (e: any) => {
        e.preventDefault();
        updatePassword(authData.access);
    };

    if (loading || userDetailsState === null) {
        return <LoadingBackDrop />;
    } else {
        return (
            <>
                {/* <Box display="flex" justifyContent={'center'}> */}
                <Box sx={{ margin: '5rem 5rem 5rem 5rem' }}>
                    <UserAvatar user_details={userDetailsState} />
                    <br />
                    <Tabs value={tabValue} onChange={(e, v) => setTabValue(v)}>
                        <Tab label={'User Details'} />
                        <Tab label={'Security'} />
                    </Tabs>
                    <br />
                    <br />
                    {tabValue === 0 ? (
                        <>
                            <Box
                                display="grid"
                                gridTemplateColumns="auto auto"
                                columnGap="25px"
                                rowGap="25px"
                                marginTop={'50px'}
                            >
                                <UserDetails
                                    managersList={managersList}
                                    user_details={userDetailsState}
                                    updateDetails={updateUserDetails}
                                    errors={errors}
                                />
                                <UserDefaults
                                    selectableEntities={selectableEntities}
                                    selectableHomePages={selectableHomePages}
                                    user_details={userDetailsState}
                                    updateDetails={updateUserDetails}
                                    errors={errors}
                                />
                            </Box>
                            <Box
                                display="flex"
                                justifyContent={'center'}
                                margin="3rem 0"
                            >
                                <Box className="flex flex--jcc">
                                    <FWButton
                                        variant="contained"
                                        color="primary"
                                        disabled={
                                            Object.keys(
                                                validateUserProfileDetails(
                                                    'ALL',
                                                    userDetailsState,
                                                    {}
                                                )
                                            ).length > 0
                                        }
                                        onClick={saveDetails}
                                    >
                                        Save
                                    </FWButton>
                                </Box>
                            </Box>
                        </>
                    ) : (
                        <Box width={'50%'}>
                            <Typography variant="h4">
                                Change Password
                            </Typography>
                            <br />
                            <form onSubmit={handleUpdate}>
                                <FWInput
                                    type="password"
                                    label={'Old password'}
                                    name={'oldPassword'}
                                    sx={{ marginBottom: 2 }}
                                    onChange={passwordUpdateChangeHandler}
                                    required
                                    error={
                                        'oldPassword' in updatePasswordErrors
                                    }
                                    helper={{
                                        text:
                                            updatePasswordErrors?.oldPassword ??
                                            '',
                                    }}
                                />
                                <FWInput
                                    type="password"
                                    label={'New password'}
                                    name={'newPassword'}
                                    onChange={passwordUpdateChangeHandler}
                                    required
                                    error={
                                        'newPassword' in updatePasswordErrors
                                    }
                                />
                                <Box marginBottom={2}>
                                    <PasswordValidationList
                                        password={
                                            updatePassowrdData.newPassword
                                        }
                                    />
                                </Box>
                                <FWInput
                                    type="password"
                                    label={'Confirm New password'}
                                    name={'confirmPassword'}
                                    sx={{ marginBottom: 2 }}
                                    onChange={passwordUpdateChangeHandler}
                                    required
                                    error={
                                        'confirmPassword' in
                                        updatePasswordErrors
                                    }
                                    helper={{
                                        text:
                                            updatePasswordErrors?.confirmPassword ??
                                            '',
                                    }}
                                />
                                <br />
                                <br />
                                <FWButton
                                    variant="contained"
                                    type="submit"
                                    disabled={
                                        updatePassowrdData.oldPassword === '' ||
                                        updatePassowrdData.newPassword === '' ||
                                        updatePassowrdData.confirmPassword ===
                                            '' ||
                                        Object.keys(updatePasswordErrors)
                                            .length > 0
                                    }
                                >
                                    Update Password
                                </FWButton>
                            </form>
                        </Box>
                    )}
                </Box>
                {/* </Box> */}
            </>
        );
    }
}
