import { IGetBuyerRFQBidWithoutBidItems } from '../../../../Models/RFQ.model';
import { baseApiSlice } from '../../../../Redux/Slices/BaseApiSlice';
import { fetchRFQBidDetailsWithOutItemsForAllVendors } from '../../../../Services/events/eventOperations';

export const fetchAllBidDetailsForMultipleVendorApiSlice =
    baseApiSlice.injectEndpoints({
        endpoints: (build) => ({
            fetchAllBidDetailsForMultipleVendor: build.query<
                {
                    [seller_entity_id: string]: {
                        ongoing_history:
                            | IGetBuyerRFQBidWithoutBidItems[]
                            | null;
                        invalid_draft: IGetBuyerRFQBidWithoutBidItems | null;
                        lastestProxyDraft: IGetBuyerRFQBidWithoutBidItems | null;
                        current_draft: IGetBuyerRFQBidWithoutBidItems | null;
                        latestCO: IGetBuyerRFQBidWithoutBidItems | null;
                        latestQuote: IGetBuyerRFQBidWithoutBidItems | null;
                        latest_bid_type:
                            | 'COUNTEROFFER'
                            | 'QUOTE'
                            | 'PROXY_QUOTE';
                        latestDraftCO: IGetBuyerRFQBidWithoutBidItems | null;
                    };
                },
                { rfq_entry_id: string; seller_entity_ids: string[] }
            >({
                queryFn: async ({ rfq_entry_id, seller_entity_ids }) => {
                    try {
                        let allVendorResponse =
                            await fetchRFQBidDetailsWithOutItemsForAllVendors(
                                rfq_entry_id
                            );

                        const responseoFAllVendorResponse =
                            Object.values(allVendorResponse);

                        const response: {
                            [key: string]: {
                                ongoing_history:
                                    | IGetBuyerRFQBidWithoutBidItems[]
                                    | null;
                                invalid_draft: IGetBuyerRFQBidWithoutBidItems | null;
                                lastestProxyDraft: IGetBuyerRFQBidWithoutBidItems | null;
                                current_draft: IGetBuyerRFQBidWithoutBidItems | null;
                                latestCO: IGetBuyerRFQBidWithoutBidItems | null;
                                latestQuote: IGetBuyerRFQBidWithoutBidItems | null;
                                latest_bid_type:
                                    | 'COUNTEROFFER'
                                    | 'QUOTE'
                                    | 'PROXY_QUOTE';
                                latestDraftCO: IGetBuyerRFQBidWithoutBidItems | null;
                            };
                        } = {};

                        responseoFAllVendorResponse.forEach(
                            (bidDetailsForSingleVendor) => {
                                if (bidDetailsForSingleVendor.length > 0) {
                                    let res = bidDetailsForSingleVendor;

                                    let ongoing_history = res
                                        .filter(
                                            (bid) =>
                                                bid.bid_type === 'PROXY_QUOTE'
                                        )
                                        .concat(
                                            res.filter(
                                                (bid) =>
                                                    bid.bid_type !==
                                                        'PROXY_QUOTE' &&
                                                    (bid.status === 'ONGOING' ||
                                                        bid.status ===
                                                            'INACTIVE' ||
                                                        bid.status ===
                                                            'REVISED')
                                            )
                                        )
                                        .filter(
                                            (bid) =>
                                                bid.status !== 'DELETED_DRAFT'
                                        );
                                    if (ongoing_history.length > 0) {
                                        let latest_bid_type:
                                            | 'COUNTEROFFER'
                                            | 'QUOTE'
                                            | 'PROXY_QUOTE' =
                                            ongoing_history[
                                                ongoing_history.length - 1
                                            ].bid_type;
                                        let latestQuote: IGetBuyerRFQBidWithoutBidItems | null =
                                            null;
                                        let latestCO: IGetBuyerRFQBidWithoutBidItems | null =
                                            null;
                                        let latestDraftCO: IGetBuyerRFQBidWithoutBidItems | null =
                                            null;
                                        let latestInvalidDraftCO: IGetBuyerRFQBidWithoutBidItems | null =
                                            null;
                                        let latestDraftProxy: IGetBuyerRFQBidWithoutBidItems | null =
                                            null;
                                        //fetch latest ongoing CO and Quote
                                        for (
                                            let i = ongoing_history.length - 1;
                                            i >= 0;
                                            i--
                                        ) {
                                            if (
                                                ongoing_history[i].bid_type ===
                                                    'QUOTE' &&
                                                latestQuote === null
                                            ) {
                                                latestQuote =
                                                    ongoing_history[i];
                                            }
                                            if (
                                                ongoing_history[i].bid_type ===
                                                    'COUNTEROFFER' &&
                                                latestCO === null
                                            ) {
                                                latestCO = ongoing_history[i];
                                            }
                                            if (
                                                ongoing_history[i].bid_type ===
                                                'PROXY_QUOTE'
                                            ) {
                                                latestDraftProxy =
                                                    ongoing_history[i];
                                            }
                                            if (
                                                latestQuote !== null &&
                                                latestCO !== null &&
                                                latestDraftProxy !== null
                                            ) {
                                                break;
                                            }
                                        }
                                        //fetch latest draft CO and invalid draft CO if any before the latest draft CO
                                        for (
                                            let i = res.length - 1;
                                            i >= 0;
                                            i--
                                        ) {
                                            if (
                                                res[i].status === 'DRAFT' &&
                                                res[i].bid_type ===
                                                    'COUNTEROFFER'
                                            ) {
                                                latestDraftCO = res[i];
                                                break;
                                            }
                                            if (
                                                res[i].status === 'INVALID'
                                                //  &&
                                                // res[i].rfq_bid_items.length > 0
                                            ) {
                                                latestInvalidDraftCO = res[i];
                                                break;
                                            }
                                            if (
                                                res[i].bid_type ===
                                                    'COUNTEROFFER' &&
                                                res[i].status === 'ONGOING'
                                            )
                                                break;
                                        }
                                        // setFullBidHistory(res)
                                        response[res[0].seller_entity] = {
                                            ongoing_history: ongoing_history,
                                            invalid_draft: latestInvalidDraftCO,
                                            current_draft: latestDraftCO,
                                            latestCO: latestCO,
                                            latestQuote: latestQuote,
                                            latest_bid_type: latest_bid_type,
                                            latestDraftCO: latestDraftCO,
                                            lastestProxyDraft: latestDraftProxy,
                                        };
                                    }
                                }
                            }
                        );

                        return { data: response };
                    } catch (err: any) {
                        return { error: err };
                    }
                },
            }),
        }),
    });

export const { useFetchAllBidDetailsForMultipleVendorQuery } =
    fetchAllBidDetailsForMultipleVendorApiSlice;
