import { IRFQItemCustomIDs } from '../../Events/Interfaces/Buyer/SharedRFQ.model';
import { IPOItemHoldTerminationInfo } from '../../Global/Interfaces/ItemAndPOHelperInterfaces';
import { IAttachment } from '../../Models/RFQ.model';
import { IAcceptedRejectedList } from '../../QualityCheck/Interfaces/QCInterface';
import { IPendingAmountData } from '../Components/PendingAmountPopover';
export interface IPageDetails {
    current_page: number;
    has_next: boolean;
    has_previous: boolean;
    total_pages: number;
}

export enum PaymentItemType {
    PREPAYMENT = 'Prepayment',
    GOODS_PAYMENT = 'Goods payment',
}

export enum CreditType {
    PREPAYMENT = 'Prepayment',
    GR_REJECTIONS = 'GR Rejection',
    QC_REJECTIONS = 'QC rejection',
    INVOICE_ITEM_TERMINATION = 'Item termination',
}

export enum PaymentItemStatus {
    PAYMENT_PENDING = 'PAYMENT_PENDING',
    PAYMENT_COMPLETE = 'PAYMENT_COMPLETE',
    GOODS_PENDING = 'GOODS_PENDING',
    GOODS_RECEIVED = 'GOODS_RECEIVED',
    COMPLETED = 'COMPLETED',
    TERMINATED = 'TERMINATED',
}

export enum PaymentStatus {
    PAYMENT_DUE = 'PAYMENT_DUE',
    OVERDUE = 'OVERDUE',
    PREPAYMENT = 'PREPAYMENT',
    COMPLETED = 'COMPLETED',
}

export interface IPaymentsPendingListSummary
    extends IPOItemHoldTerminationInfo {
    IPaymentsPendingListSummary: true;
    id: number;
    itemUid: string;
    invoiceItemUid: string;
    entityName: string;
    entityUid: string;
    poId: string;
    poUid: string;
    invoiceId: string | null;
    invoiceUuid: string;
    vendorName: string;
    vendorUid: string;
    vendorContact: string;
    vendorContactUid: string;
    itemDetails: IPaymentsItemSummary;
    paymentType: PaymentItemType;
    paymentStatus: PaymentStatus;
    paymentDueDate: number | null;
    amountPending: number;
    totalAmount: number;
    currency: string;
    currencyUid: string;
    totalInfo: IPaymentTotalInfo;
    rejectedAmount: number;
    creditedAmount: number;
    completedPayment: number;
    invoiceItemUids: string[];
    onHoldTerminationAmount: number;
    onHoldTerminationQuantity: number;
    showPrepayment: boolean;
    grRejectedAmount: number;
    qcRejectedAmount: number;
    gr_status: PaymentItemStatus;
    qc_status: 'PENDING' | 'COMPLETE';
    buyerEntityId: string;
}

export interface IPaymentsCompletedListSummary {
    paymentId: string;
    paymentUid: string;
    poIdList: string[];
    invoiceIdList: string[];
    entityName: string;
    vendorName: string;
    totalItems: number;
    transactionId: string;
    amountPaid: number;
    creditAmount: number;
    transactionAmount: number;
    // status: string; // not required
    currency: string;
    currencySymbol: string;
    paymentType: PaymentItemType;
    itemNames: string[];
}

interface IItemAttributes {
    buyerAttributes: {
        name: string;
        value: string[];
    }[];
    customAttributes: {
        name: string;
        value: string[];
    }[];
}

export interface IPaymentsItemSummary {
    itemId: string; // ths is buyer item id
    itemUid: string; // this is global item uid
    itemName: string;
    description: string;
    additionalInfo: string;
    attributes: IItemAttributes;
    customIDs: IRFQItemCustomIDs[];
    tags: string[];
}

export interface IPaymentsListContext {
    totalAmountToPay: number;
    totalCreditAmount: number;
    entityUid: string;
    handleItemPopupToggle: (item: IPaymentsItemSummary) => void;
    handlePaymentItemAmountUpdate: (
        item: IPaymentsItemSummary,
        amount: number
    ) => void;
    handleCreditRequestedAmountUpdate: (
        item: ICreditListItemSummary,
        amount: number
    ) => void;
    handleItemDetailPopoverToggle: (
        e: HTMLElement,
        item: IPaymentListItemSummary
    ) => void;
    handleCreditDetailPopoverToggle: (
        e: HTMLElement,
        item: ICreditListItemSummary
    ) => void;
    handleTotalPopoverToggle: (
        el: HTMLElement,
        data: IPaymentTotalInfo
    ) => void;
    handlePendingPopoverToggle: (
        el: HTMLElement,
        data: IPendingAmountData
    ) => void;
    handlePrepaymentInvoicesPopoupToggle: (poUid: string) => void;
}

export interface IPaymentTotalInfo {
    measurementUnit: string;
    shippingPerUnit: string;
    quantity: string;
    taxList: {
        charge_name: string;
        charge_value: string;
    }[];
    subTotal?: number;
    currency: string;
    currencySymbol: string;
    price: string;
    discount: number;
    formattedTotal: number;
}

export interface IPaymentListItemSummary extends IPOItemHoldTerminationInfo {
    id: number;
    invoiceItemUid: string;
    remainingDays: number | null;
    poId: string;
    poQuantity: string;
    poUid: string;
    poItemUid: string;
    invoiceId: string;
    invoiceUid: string;
    grnId: string;
    grnUid: string;
    arNo: {
        arId: string;
        arType: string;
    }[];
    itemDetails: IPaymentsItemSummary;
    paymentType: PaymentItemType;
    quantity: string;
    measurementUnit: string;
    rate: number;
    totalAmount: number;
    amountPending: number;
    amountToPay: string;
    currency: string;
    currencySymbol: string;
    currencyUid: string;
    status: PaymentItemStatus;
    invoiceVerificationStatus: string;
    totalInfo: IPaymentTotalInfo;
    rejectedAmount: number;
    creditedAmount: number;
    completedPayment: number;
    onHoldTerminationAmount: number;
    onHoldTerminationQuantity: number;
    showPrepayment: boolean;
    grRejectedAmount: number;
    qcRejectedAmount: number;
    qcDone: boolean;
    buyerEntityId: string;
}

export interface ICreditListItemSummary {
    id: number;
    creditUid: string;
    poId: string;
    invoiceId: string;
    itemName: string;
    totalAmount: number;
    reasonForRejection: string;
    creditType: string;
    availableAmount: number;
    requestedAmount: string;
    currency: string;
    currencySymbol: string;
    currencyUid: string;
    selected: boolean;
    batchId: string;
    rejectionGr: string;
    rejectionArn: string;
    rejectionDate: string;
    poContact: string;
    poContactUid: string;
    terminatedByContact: string;
    terminatedByContactUid: string;
}

export enum TransactionType {
    ONLINE = 'Online',
    OFFLINE = 'Offline',
    BALANCE_ONLY = 'Credit',
}

export interface IPaymentListSummary {
    transactionId: string;
    transactionType: TransactionType | '';
    amount: string;
    date: string;
    attachments: IAttachment[];
}

export interface IPaymentDetailSummary {
    status: string;
    amountPaid: number;
    totalTransactionAmount: number;
    totalCreditAmount: number;
    paidBy: string;
    paymentDate: string;
    currency: string;
    currencySymbol: string;
    transactionId: string;
    transactionType: TransactionType;
    items: IPaymentCompletedItemsList[];
    paymentType: PaymentItemType;
    buyerEntityName: string;
    sellerEntityName: string;
    attachments: IAttachment[];
    vendorContact: string;
    vendorContactUid: string;
}

export interface IPaymentCompletedItemsList {
    itemDetails: IPaymentsItemSummary;
    amountPaid: number;
    invoiceId: string;
    poId: string;
    currency: string;
    currencySymbol: string;
    totalAmount: number;
    totalInfo: IPaymentTotalInfo;
    paymentType: PaymentItemType;
    vendorContact: string;
    vendorContactUid: string;
}

export interface IPaymentSuccessSummary {
    vendorName: string;
    status: string;
    date: string;
    referenceId: string;
    amountPaid: number;
    currency: string;
    paymentUid: string;
}

export interface IPaymentItemGroupSummary {}

export interface IPaymentItemGroupDict {
    [key: string]: IPaymentItemGroupSummary;
}

// export interface IPrepaymentSummary extends IPOHoldTerminationInfo {
//     eventName: string;
//     poId: string;
//     poUid: string;
//     vendor: string;
//     totalPrepayment: number;
//     amountPaid: number;
//     pendingPrepayment: number;
//     amountToPay: string;
//     transactionDate: string;
//     transactionType: TransactionType;
//     transactionId: string;
//     currency: string;
//     currencySymbol: string;
// }

export interface IPrepaymentItemsList extends IPaymentsItemSummary {
    quantity: number;
    rate: number;
    total: number;
    prepaymentAmount: number;
    measurementUnit: string;
    currency: string;
}

export interface IPOPrepaymentInvoiceSummary {
    invoiceId: string;
    invoiceUid: string;
    itemCount: number;
    totalAmount: number;
    currency: string;
    itemsList: IPrepaymentItemsList[];
}

export interface IPaymentItemRejectionInfo {
    grn: {
        invoice_item_batch_id?: string;
        grAccepted: string;
        grRejected: string;
        grTolerance: string;
        grOverdelivery: string;
        grNo: string;
        batchId: string;
        measurementUnit: string;
        totalQty: string;
        grUid: string;
    }[];
    qc: IAcceptedRejectedList[];
}

export interface IDefaultViewPaymentItem extends IPOItemHoldTerminationInfo {
    IDefaultViewPaymentItem: boolean;
    id: number;
    invoiceItemUid: string;
    entityName: string;
    entityUid: string;
    poId: string;
    poUid: string;
    invoiceId: string;
    vendorName: string;
    vendorUid: string;
    items: number;
    itemNames: Set<string>;
    dueDate: number | null;
    totalAmount: number;
    pendingAmount: number;
    paymentType: PaymentItemType;
    showPrepayment: boolean;
    currency: string;
    currencyUid: string;
    invoiceItemUids: string[];
    onHoldTerminationQuantity: number;
    totalInfo: IPaymentTotalInfo;
    vendorContact: string;
    vendorContactUid: string;
    onHoldTerminationAmount: number;
    gr_status: string;
    qc_status: 'PENDING' | 'PARTIAL' | 'COMPLETE';
    all_qc_status: 'PENDING' | 'PARTIAL' | 'COMPLETE';
    allItemTermination: boolean;
    allItemsonHold: boolean;
    canMakePayment: boolean;
}

export interface IInvoiceViewPaymentItem extends IPOItemHoldTerminationInfo {
    IInvoiceViewPaymentItem: boolean;
    id: number;
    invoiceItemUid: string;
    entityName: string;
    entityUid: string;
    poId: string;
    poUid: string;
    invoiceId: string;
    vendorName: string;
    vendorUid: string;
    items: number;
    itemNames: Set<string>;
    dueDate: number | null;
    totalAmount: number;
    pendingAmount: number;
    paymentType: PaymentItemType;
    showPrepayment: boolean;
    currency: string;
    currencyUid: string;
    invoiceItemUids: string[];
    onHoldTerminationQuantity: number;
    totalInfo: IPaymentTotalInfo;
    vendorContact: string;
    vendorContactUid: string;
    onHoldTerminationAmount: number;
    gr_status: string;
    qc_status: 'PENDING' | 'PARTIAL' | 'COMPLETE';
    all_qc_status: 'PENDING' | 'PARTIAL' | 'COMPLETE';
    allItemTermination: boolean;
    allItemsonHold: boolean;
    canMakePayment: boolean;
}
