import { Box, Chip } from '@mui/material';
import { useState } from 'react';
import { FWTooltip } from '../../../../Common/FWCustomTooltip';
import { FWTypography } from '../../../../Common/FWTypograhy';
import { PaymentsItemPopup } from '../../../../Payments/Components/PaymentsItemPopup';
import {
    IItemEntityDetail,
    IItemSummary,
    ItemStatus,
} from '../../Interfaces/ItemDirectoryInterface';
import ItemEntitiesListOptionsMenu from '../../Pages/ItemDirectory/ItemEntitiesListOptionsMenu';
import ItemDirectoryListOptionsMenu from '../../Pages/ItemDirectory/ItemsListOptionsMenu';
import { ItemCellActions } from '../../Pages/ItemDirectory/ItemsListPage';
import { ItemCellActions as EntityItemCellActions } from '../../Pages/ItemDirectory/EntityItemsListPage';

export const ItemIDCell = ({ data }: { data: IItemSummary }) => {
    return <FWTypography color="text.secondary">{data.itemCode}</FWTypography>;
};

export const ItemNameCell = ({
    data,
    context,
}: {
    data: IItemSummary;
    context: any;
}) => {
    const [open, setOpen] = useState<boolean>(false);
    // const itemDetails: IPaymentsItemSummary = {
    //     itemId: data.itemCode,
    //     itemUid: data.id,
    //     itemName: data.name,
    //     description: data.description,
    //     additionalInfo: data.notes,
    //     attributes: {
    //         buyerAttributes: data.specifications,
    //         customAttributes: [],
    //     },
    //     customIDs: data.customIds,
    //     tags: data.tags,
    // };
    return (
        <Box
            color="primary.main"
            ref={(ref: HTMLDivElement) => {
                if (!ref) return;
                ref.onclick = (e: any) => {
                    e.stopPropagation();
                    setOpen(true);
                    // context.handleCellHover(
                    //     ItemCellActions.ENTER,
                    //     data,
                    //     e.currentTarget,
                    //     ItemCellActions.NAME
                    // );
                };
            }}
            // onMouseLeave={(event: any) =>
            //     context.handleCellHover(
            //         ItemCellActions.LEAVE,
            //         data,
            //         event,
            //         ItemCellActions.NAME
            //     )
            // }
            className="flex flex--aic"
        >
            <FWTypography>{data.name}</FWTypography>
            {data.status === ItemStatus.INACTIVE && (
                <Chip className="ml--15" size="small" label="Disabled" />
            )}

            <PaymentsItemPopup
                open={open}
                setOpen={setOpen}
                itemDetails={{
                    type: 'ITEM_SUMMARY',
                    data,
                    allMeasurementUnits: context.allMeasurementUnits,
                }}
            />
        </Box>
    );
};

export const EntitiesCell = ({
    data,
    context,
}: {
    data: IItemSummary;
    context: any;
}) => {
    return (
        <Box className="flex flex--aic flex--jcc" style={{ width: '100%' }}>
            <Box
                color={
                    data.entities.length > 0 ? 'primary.main' : 'text.secondary'
                }
                ref={(ref: HTMLDivElement) => {
                    if (!ref) return;
                    ref.onclick = (e: any) => {
                        e.stopPropagation();
                        context.handleCellHover(
                            ItemCellActions.ENTER,
                            data,
                            e.currentTarget,
                            ItemCellActions.ENTITY
                        );
                    };
                }}
                // onMouseLeave={(event: any) =>
                //     context.handleCellHover(
                //         ItemCellActions.LEAVE,
                //         data,
                //         event.currentTarget,
                //         ItemCellActions.ENTITY
                //     )
                // }
            >
                {data.entities.length}
            </Box>
            {data.locked && (
                <FWTooltip title="Cannot be linked to more entities">
                    <i className="bi bi-lock-fill ml--5"></i>
                </FWTooltip>
            )}
        </Box>
    );
};

export const OptionsCell = ({
    data,
    context,
}: {
    data: IItemSummary;
    context: any;
}) => (
    <div className="flex expand flex--jcc flex--aic">
        <ItemDirectoryListOptionsMenu
            emitItemAction={context.handleItemEmitAction}
            row={data}
        />
    </div>
);

export const PreferredVendorsCell = ({
    data,
    context,
}: {
    data: IItemSummary;
    context: any;
}) => (
    <Box className="flex flex--aic flex--jcc" style={{ width: '100%' }}>
        <Box
            color={
                data.preferredVendors.length > 0
                    ? 'primary.main'
                    : 'text.secondary'
            }
            ref={(ref: HTMLDivElement) => {
                if (!ref) return;
                ref.onclick = (e: any) => {
                    e.stopPropagation();
                    context.handleCellHover(
                        EntityItemCellActions.ENTER,
                        data,
                        e.currentTarget,
                        EntityItemCellActions.VENDOR
                    );
                };
            }}
        >
            {data.preferredVendorsCount}
        </Box>
    </Box>
);

export const EntityOptionsCell = ({
    data,
    context,
}: {
    data: IItemEntityDetail;
    context: any;
}) => (
    <div className="flex expand flex--jcc flex--aic">
        <ItemEntitiesListOptionsMenu
            emitItemAction={context.handleItemEmitAction}
            row={data}
        />
    </div>
);
