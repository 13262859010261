import { useState } from 'react';

export type ConfirmationColors =
    | 'primary'
    | 'success'
    | 'error'
    | 'warning'
    | undefined;

interface IConfirmationDetails {
    open: boolean; // use to show hide the confirmation popup
    title: string; // title of the confirmation popup
    message: string; // message of the confirmation popup
    key: string; // the key to update
    value: any; // the value which will be provided to the final payload
    data?: any; // the extra parameters you want to access
    color?: ConfirmationColors; // colors you wish to use on the buttons of confirmation popup
    type: 'ENABLE_VENDOR' | 'DISBALE_VENDOR' | 'DELETE_INVITATION' |'RESEND_INVITATION'|'NONE'; // the type of action to call the correct API
}

const initialConfirmation: IConfirmationDetails = {
    open: false,
    title: '',
    message: '',
    key: '',
    value: '',
    data: null,
    color: 'primary',
    type: 'NONE',
};

export const useConfirmation = () => {
    const [confirmationValues, setConfirmationValues] =
        useState<IConfirmationDetails>({
            ...initialConfirmation,
        });

    const updateConfirmationValues = ({
        title,
        message,
        key,
        value,
        type = 'NONE',
        data,
        color,
    }: {
        title: string;
        message: string;
        key: string;
        value: boolean | string | any[];
        type?:
            | 'ENABLE_VENDOR'
            | 'DISBALE_VENDOR'
            | 'DELETE_INVITATION'
            | 'RESEND_INVITATION'
            | 'NONE';
        data?: any;
        color?: ConfirmationColors | undefined;
    }) => {
        setConfirmationValues({
            open: !confirmationValues.open,
            title,
            message,
            key,
            value,
            data,
            color,
            type,
        });
    };

    const resetConfirmationValues = () => {
        setConfirmationValues({
            open: false,
            title: '',
            message: '',
            key: '',
            value: '',
            type: 'NONE',
            data: null,
            color: 'primary',
        });
    };

    return [
        confirmationValues,
        updateConfirmationValues,
        resetConfirmationValues,
    ] as const;
};
