import { Box, styled, Typography } from '@mui/material';
import { FWTypography } from '../../Common/FWTypograhy';
import { downloadAllChatAttachments } from '../../Common/Utils/chatUtils';
import { getFWTime } from '../../Common/Utils/DateUtils';
import {
    ChatTypes,
    ISessionMessage,
    IUserSession,
} from '../Interfaces/Chat.model';
import ChatDocument from './ChatDocument';

const StyledBox = styled(Box)(({ theme }) => ({
    padding: '0.3rem',
    maxWidth: '300px',
    minWidth: '200px',
    width: 'max-content',
}));

interface IBroadcastMessageRows {
    message_details: ISessionMessage;
    containerSelector: string;
    index: number;
    boxPosition: 'LEFT' | 'RIGHT';
    showSenderName: boolean;
    reduceMargins: boolean;
    sessionList: IUserSession[];
}

export default function BroadcastMessageRows(props: IBroadcastMessageRows) {
    // const [loaded, elRef] = useLazyLoadList(props.containerSelector);

    const getUserNameList = (): string[] => {
        let userNames: string[] = [];
        props.message_details.session_id_list.session_id_list.forEach(
            (receiver) => {
                let user = props.sessionList.find(
                    ({ session }) => session.session_id === receiver
                );
                if (user) {
                    userNames.push(user.session.seller_entity_name);
                }
            }
        );
        //
        return userNames;
    };

    //

    return (
        <StyledBox
            bgcolor={
                props.boxPosition === 'LEFT' ? 'background.default' : '#3495ff'
            }
            // ref={elRef}
            margin={`${props.reduceMargins ? '0.25rem' : '1rem'} 1rem 0 ${
                props.boxPosition === 'LEFT' ? '1rem' : 'auto'
            }
            `}
            borderRadius={`0.75rem 0.75rem ${
                props.boxPosition === 'RIGHT' ? '0rem' : '0.75rem'
            } ${props.boxPosition === 'LEFT' ? '0rem' : '0.75rem'}`}
        >
            {/* {loaded ? ( */}
            <Box>
                {props.showSenderName && (
                    <FWTypography fontWeight={500}>
                        {props.message_details.sender_name}
                    </FWTypography>
                )}
                <Box
                    marginLeft={0.5}
                    marginBottom={
                        props.message_details.attachments.length > 0 ? 0.5 : 0
                    }
                >
                    <FWTypography
                        color={'white'}
                        fontSize={11}
                        fontWeight={'400'}
                    >
                        From: {props.message_details.sender_name}
                    </FWTypography>
                    <FWTypography
                        color={'white'}
                        fontSize={11}
                        fontWeight={'400'}
                        style={{
                            whiteSpace: 'normal',
                        }}
                    >
                        To:{' '}
                        {getUserNameList().map(
                            (name, i) =>
                                `${name}${
                                    i !== getUserNameList().length - 1
                                        ? ', '
                                        : ''
                                }`
                        )}
                    </FWTypography>
                </Box>

                {props.message_details.attachments.length > 0 &&
                    props.message_details.attachments.map(
                        ({ file_name, attachment_id }, idx) => (
                            <ChatDocument
                                key={idx}
                                attachment_id={attachment_id}
                                boxPosition={props.boxPosition}
                                file_name={file_name}
                            />
                        )
                    )}

                {props.message_details.attachments.length > 1 && (
                    <Typography
                        fontSize={'0.75rem'}
                        color={props.boxPosition === 'LEFT' ? 'unset' : 'white'}
                        sx={{
                            cursor: 'pointer',
                            '&:hover': {
                                textDecoration: 'underline',
                            },
                            display: 'flex',
                            justifyContent: 'flex-end',
                        }}
                        textAlign={'right'}
                        marginRight={1}
                        onClick={() =>
                            downloadAllChatAttachments(
                                props.message_details.attachments
                            )
                        }
                    >
                        Download all
                        <span style={{ marginLeft: 5, scale: '0.9' }}>
                            <i className="bi bi-arrow-down-circle"></i>
                        </span>
                    </Typography>
                )}

                <Typography margin={0.5} color={'white'}>
                    {props.message_details.message}
                </Typography>
                <Box
                    width="100%"
                    className="flex flex--aic flex--jcfe"
                    color="text.secondary"
                    fontSize={'0.75rem'}
                >
                    {props.message_details.message_type ===
                        ChatTypes.BROADCAST && (
                        <>
                            <Box color="text.secondary">
                                <i
                                    className="bi bi-megaphone"
                                    style={{
                                        color: 'white',
                                        opacity: '0.7',
                                    }}
                                />
                            </Box>
                            &nbsp;
                        </>
                    )}
                    <FWTypography
                        fontSize={'inherit'}
                        color={'white'}
                        style={{ opacity: '0.7' }}
                    >
                        {getFWTime(props.message_details.created_datetime)}
                    </FWTypography>
                </Box>
            </Box>
            {/* ) : (
            <Box className="flex flex--jcsb" padding="5px">
                <Box>
                    <Box
                        bgcolor={'background.paper'}
                        width="250px"
                        height="50px"
                    />
                </Box>
                <Box></Box>
            </Box>
            )} */}
        </StyledBox>
    );
}
