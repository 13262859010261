import { Box, Typography } from '@mui/material';
import React, { useContext } from 'react';
import DynamicAnalyticsVendor from '../../../Historical/Containers/DynamicAnalyticsVendor';
import { VLNContext } from '../../../Events/Components/Buyer/VendorLinkedInPopup';

const ratings = [
    {
        text: 'Negotiated pricing',
        rating: 3.5,
    },
    {
        text: 'On-time delivery',
        rating: 3.5,
    },
    {
        text: 'Quality',
        rating: 3.5,
    },
    {
        text: 'Overall',
        rating: 3.5,
    },
];
const VLNRelationshipInsightsSection = () => {
    const {
        currencyDetails,
        enterpriseItemUuid,
        itemName,
        measurementUnitName,
        measurementUnitUuid,
        sellerEntityName,
        sellerEntityUuid,
    } = useContext(VLNContext);

    return (
        <>
            {false && (
                <Box className={'flex flex--aic'}>
                    {ratings.map((item, idx) => (
                        <>
                            <Box key={idx}>
                                <Box className={'flex flex--fdc'} flex={1}>
                                    <Typography color={'text.secondary'}>
                                        {item.text}
                                    </Typography>
                                    <Box className={'flex flex--aic'}>
                                        <Typography
                                            variant={'h2'}
                                            color={'secondary.main'}
                                            fontSize={'2rem'}
                                            sx={{
                                                color: '#FFD700',
                                            }}
                                        >
                                            {item.rating}
                                        </Typography>
                                        &nbsp;
                                        <Box color={'#FFD700'} fontSize={30}>
                                            <i className="bi bi-star-fill" />
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                            <Box
                                height={'50px'}
                                width={'1px'}
                                bgcolor={'lightgrey'}
                                marginX={'5vw'}
                            ></Box>
                        </>
                    ))}
                </Box>
            )}
            <DynamicAnalyticsVendor
                enterpriseItemUuid={enterpriseItemUuid}
                itemName={itemName}
                currencyDetails={currencyDetails}
                measurementUnitUuid={measurementUnitUuid}
                measurementUnitName={measurementUnitName}
                sellerEntityUuid={sellerEntityUuid}
                sellerEntityName={sellerEntityName}
            />
        </>
    );
};

export default VLNRelationshipInsightsSection;
