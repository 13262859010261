import { useCallback, useEffect, useState } from 'react';
import { HookStateValue, useHookState } from '../../Common/Hooks/StateHook';
import { getEntityDetails } from '../../PurchaseOrders/Services/PO.service';

export const useVLN = (sellerEntityID: string) => {
    const [vendorProfileID, setVendorProfileID] = useState<string | null>(null);
    const { hookState, updateHookState } = useHookState(HookStateValue.INITIAL);

    const fetchVendorProfileID = useCallback(async () => {
        try {
            updateHookState(HookStateValue.LOADING);
            const resp = await getEntityDetails(sellerEntityID);
            setVendorProfileID(resp?.vendor_profile?.vendor_profile_id ?? null);
            updateHookState(HookStateValue.READY);
        } catch (error) {
            updateHookState(HookStateValue.ERROR);
        }
    }, [sellerEntityID, updateHookState]);

    useEffect(() => {
        fetchVendorProfileID();
    }, [fetchVendorProfileID]);

    return {
        vendorProfileID,
        hookState,
    };
};
