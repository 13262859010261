import { mergedNotificationTypes } from '../Constants/NotificationsMap';

export enum NotificationStatus {
    READ = 'READ',
    UNREAD = 'UNREAD',
}

export interface INotifications {
    notificationItems: INotification[];
}

export interface INotification {
    id: string;
    title: string;
    message: string;
    url?: string | null;
    userDisplayName: string;
    time: string;
    status: NotificationStatus;
    type: keyof typeof mergedNotificationTypes;
}
