import VLNProfileItemCatalogFilters from '../../Components/Profile/Item Catalogue/VLNProfileItemCatalogFilters';
import VLNProfileItemCatalogSearchBar from '../../Components/Profile/Item Catalogue/VLNProfileItemCatalogSearchBar';
import VLNProfileItemCatalogItems from '../../Components/Profile/Item Catalogue/VLNProfileItemCatalogItems';
import { IVLNItemCatalogueNew } from '../../Interfaces/VendorLNInterfaces';
import { useVLNItemCatalogue } from '../../Hooks/useVLNItemCatalogue';
import { FWTypography } from '../../../Common/FWTypograhy';
import { Box } from '@mui/material';

const VLNProfileItemCatalogSection = ({
    itemsCatalogueList,
    currentCataloguePage,
    setCurrentCataloguePage,
    totalPages,
}: {
    itemsCatalogueList: IVLNItemCatalogueNew[];
    currentCataloguePage: number;
    setCurrentCataloguePage: React.Dispatch<React.SetStateAction<number>>;
    totalPages: number;
}) => {
    const {
        tagsWithCount,
        setSearchText,
        selectedFilter,
        handleFilterChange,
        filteredItemsCatalogueList,
    } = useVLNItemCatalogue(itemsCatalogueList);

    return itemsCatalogueList.length > 0 ? (
        <>
            <VLNProfileItemCatalogSearchBar setSearchText={setSearchText} />
            <VLNProfileItemCatalogFilters
                tagsWithCount={tagsWithCount}
                selectedFilter={selectedFilter}
                onFilterChange={handleFilterChange}
            />
            <VLNProfileItemCatalogItems
                itemsCatalogueList={filteredItemsCatalogueList}
                currentCataloguePage={currentCataloguePage}
                setCurrentCataloguePage={setCurrentCataloguePage}
                totalPages={totalPages}
            />
        </>
    ) : (
        <Box
            className="flex flex--aic flex--jcc"
            height={'calc(100vh - 450px)'}
        >
            <FWTypography color={'text.secondary'}>No items found</FWTypography>
        </Box>
    );
};

export default VLNProfileItemCatalogSection;
