import { Box } from '@mui/material';
import { ApplyColumnStateParams, IHeaderParams } from 'ag-grid-community';
import { cloneDeep } from 'lodash';
import React, { useCallback, useEffect } from 'react';
import { FWTypography } from '../../Common/FWTypograhy';

interface ICustomHeaderParams extends IHeaderParams {
    sortFields: {
        field: string;
        ascending: boolean;
    }[];
    applyDashboardColumnOrder?: boolean;
    allColumnDraggable?: boolean;
    suppressMovable?: boolean;
    setSortFields?: React.Dispatch<
        React.SetStateAction<
            {
                field: string;
                ascending: boolean;
            }[]
        >
    >;
    onSortChanged?: (
        sortFields: {
            field: string;
            ascending: boolean;
        }[]
    ) => void;
    cookieName?: string;
}

// const SortIcon = ({
//     enableSorting,
//     sortData,
//     onSortRequested,
// }: {
//     enableSorting: boolean | undefined;
//     sortData:
//         | {
//               field: string;
//               ascending: boolean;
//           }
//         | undefined;
//     onSortRequested: (order: 'asc' | 'desc' | null, event: any) => void;
// }) => {
//     if (enableSorting) {
//         return (
//             <div
//                 style={{ display: 'inline-block' }}
//                 className="ag-header-icon ag-header-label-icon"
//             >
//                 {sortData?.ascending && (
//                     <div
//                         onTouchEnd={(event) => onSortRequested('desc', event)}
//                         className={`customSortDownLabel`}
//                     >
//                         <i className="ag-icon ag-icon-asc" />
//                     </div>
//                 )}
//                 {sortData && sortData?.ascending === false && (
//                     <div
//                         // onClick={(event) => onSortRequested(null, event)}
//                         onTouchEnd={(event) => onSortRequested(null, event)}
//                         className={`customSortUpLabel`}
//                     >
//                         <i className="ag-icon ag-icon-desc" />
//                     </div>
//                 )}

//                 {sortData && sortData?.ascending === null && (
//                     <div
//                         // onClick={(event) => onSortRequested('asc', event)}
//                         onTouchEnd={(event) => onSortRequested('asc', event)}
//                         className={`customSortRemoveLabel`}
//                     >
//                         <i className="bi bi-x" style={{ opacity: '0' }} />
//                     </div>
//                 )}
//             </div>
//         );
//     } else {
//         return <></>;
//     }
// };

const CustomDashboardGridHeader = (props: ICustomHeaderParams) => {
    const onSortRequested = (order: 'asc' | 'desc' | null, event: any) => {
        props.setSort(order, event.shiftKey);
    };

    const sortData = props.sortFields.find((item) => {
        return item.field === props.column.getColId();
    });

    const applyColumnState = useCallback(() => {

        if (props.columnApi && props.cookieName) {
            let colState: any = localStorage.getItem(props.cookieName);
            if (colState) {
                colState = JSON.parse(colState) as ApplyColumnStateParams;
                props.columnApi.applyColumnState({
                    state: colState,
                    applyOrder: true,
                });
            }
        }
    }, [props.columnApi, props.cookieName]);

    useEffect(() => {
        if (props.applyDashboardColumnOrder) {
            applyColumnState();
        }
    }, [applyColumnState, props.applyDashboardColumnOrder]);

    const sortIcon = () => {
        if (props.enableSorting) {
            return (
                <div
                    style={{ display: 'inline-block' }}
                    className="ag-header-icon ag-header-label-icon"
                >
                    {sortData?.ascending && (
                        <div
                            onTouchEnd={(event) =>
                                onSortRequested('desc', event)
                            }
                            className={`customSortDownLabel`}
                        >
                            <i className="ag-icon ag-icon-asc" />
                        </div>
                    )}
                    {sortData && sortData?.ascending === false && (
                        <div
                            onTouchEnd={(event) => onSortRequested(null, event)}
                            className={`customSortUpLabel`}
                        >
                            <i className="ag-icon ag-icon-desc" />
                        </div>
                    )}

                    {sortData && sortData?.ascending === null && (
                        <div
                            onTouchEnd={(event) =>
                                onSortRequested('asc', event)
                            }
                            className={`customSortRemoveLabel`}
                        >
                            <i className="bi bi-x" style={{ opacity: '0' }} />
                        </div>
                    )}
                </div>
            );
        } else {
            return <></>;
        }
    };

    return (
        <Box
            className="flex flex--aic"
            width={'100%'}
            sx={{
                cursor:
                    props.enableSorting || props.allColumnDraggable
                        ? 'pointer'
                        : 'default',
            }}
            onClick={(event) => {
                if (!props.enableSorting) return;

                let newSortFields = cloneDeep(props.sortFields);

                switch (sortData?.ascending) {
                    case null:
                        newSortFields = newSortFields.map((item) => {
                            if (item.field === props.column.getColId()) {
                                return {
                                    ...item,
                                    ascending: true,
                                };
                            }
                            return item;
                        });
                        break;
                    case true:
                        newSortFields = newSortFields.map((item) => {
                            if (item.field === props.column.getColId()) {
                                return {
                                    ...item,
                                    ascending: false,
                                };
                            }
                            return item;
                        });
                        break;
                    case false:
                        newSortFields = newSortFields.filter((item) => {
                            return item.field !== props.column.getColId();
                        });
                        break;
                    default:
                        if (props.column.getColId() !== '0') {
                            newSortFields = [
                                {
                                    field: props.column.getColId(),
                                    ascending: true,
                                },
                            ];
                        }
                        break;
                }

                props.setSortFields && props.setSortFields(newSortFields);
                props.onSortChanged && props.onSortChanged(newSortFields);
            }}
        >
            <FWTypography color={'#1a1a1fa6'}>{props.displayName}</FWTypography>
            {sortIcon()}
        </Box>
    );
};

export default CustomDashboardGridHeader;
