import { toast } from 'react-toastify';
import { ICurrencyDetails } from '../../../Models/Currency.model';
import { get, post } from '../../../Utils/api';
import { IUserInviteSignupDetails } from '../../Admin/Interfaces/UsersInterface';
import {
    ISignUpUserDetails,
    ISignupCompanyDetails,
    ISignupEntity,
} from '../interface/Shared.interface';

export const validateIfUserWorkEmailExists = async (
    email: string
): Promise<boolean> => {
    try {
        const response = await get<{ 'user count: ': number }>(
            `/authentication/user/email/count/?email=${email}`
        );
        return response.data['user count: '] > 0;
    } catch (err) {
        return false;
    }
};

export const validateIfUsernameExists = async (
    email: string,
    emailOrUser?:boolean
): Promise<boolean> => {
    try {
        if(emailOrUser===undefined){
            const response = await get<{ user_exists: boolean }>(
                `/authentication/user/validate/?username=${email}`
            );
            return response.data.user_exists;
        }
        else{
            const response = await get<{ user_exists: boolean }>(
                `/authentication/user/validate/?email=${email}`
            );
            return response.data.user_exists;
        }
    } catch (err) {
        return true;
    }
};

export const registerUserWithEnterprise = async (
    userDetails: ISignUpUserDetails,
    companyDetails: ISignupCompanyDetails
): Promise<boolean> => {
    try {
        const urlParams = new URLSearchParams(window.location.search);
        let plan_id = urlParams.get('plan_id');
        if (plan_id) {
            const currency = plan_id.split('_');
            currency.pop();
            const allCurrencies = await fectAllCurrenices();
            const searhedCurrency = allCurrencies.find(
                ({ entry_id }) =>
                    entry_id === companyDetails.billing_currency_id
            );
            if (searhedCurrency) {
                currency[currency.length] =
                    searhedCurrency.currency_code_abbreviation.toLocaleLowerCase();
                plan_id = currency.join('_');
            }
        }

        await post(`/authentication/signup/enterprise/`, {
            plan_id: plan_id || '',
            name: userDetails.full_name,
            username: userDetails.username,
            password: userDetails.password,
            email: userDetails.email,
            enterprise_name: companyDetails.company_name,
            dba: companyDetails.DBA_name,
            billing_currency_id: companyDetails.billing_currency_id,
            websites:
                companyDetails.website_URL.length > 0
                    ? [`http://${companyDetails.website_URL}`]
                    : [],
        });
        return true;
    } catch (error) {
        return false;
    }
};

export const fectAllCurrenices = async (): Promise<ICurrencyDetails[]> => {
    return new Promise(async (resolve, reject) => {
        try {
            const resp = await get<ICurrencyDetails[]>(
                `/authentication/currency_code/`,
                {}
            );
            resolve(resp.data);
        } catch (error: any) {
            toast.error('Error fetching currencies');
        }
    });
};

export const requestUserToJoin = async (
    entity_id: string,
    userDetails: ISignUpUserDetails,
    enterpriseUid: string,
    notes: string
): Promise<boolean> => {
    try {
        await post(`/authentication/signup/request/`, {
            password: userDetails.password,
            name: userDetails.full_name,
            username: userDetails.username,
            enterprise_id: enterpriseUid,
            email: userDetails.email,
            notes: notes,
            entity_list: [entity_id],
        });
        return true;
    } catch (error: any) {
        toast.error(error.response.data.ErrorCode);
        return false;
    }
};

export const postLoginCredentialsToEmail = async (
    email: string
): Promise<boolean> => {
    try {
        await get(`/authentication/user/credentials/request/?email=${email}`);
        return true;
    } catch (error: any) {
        toast.error(error.response.data.ErrorCode);
        return false;
    }
};

export const searchForEnterprises = async (
    enterprise_name_query: string,
    enterprise_website_query: string
): Promise<ISignupEntity[]> => {
    try {
        const response: any = await get(
            `/authentication/enterprise/search/?name=${enterprise_name_query}&website=${enterprise_website_query}`
        );
        return response.data.map((data: any, idx: number) => {
            const newEnt: ISignupEntity = {
                enterpriseUid: data.enterprise.enterprise_id,
                enterpriseName: data.enterprise.enterprise_name,
                entityUid: data.entity_id,
                entityName: data.entity_name,
                enitityWebsite: data.contacts?.websites || '',
            };
            return newEnt;
        });
    } catch (error) {
        return [];
    }
};

export const verifyUserEmail = async (
    invitationUid: string,
    invitationCode: string
): Promise<any> => {
    try {
        await post(`/invitation/validate/email/confirm/`, {
            invitation_id: invitationUid,
            invitation_code: invitationCode,
        });
        return true;
    } catch (err: any) {
        return err;
    }
};

export const fetchUserInviteSignupDetails = async (
    invitationUid: string,
    invitationCode: string
): Promise<IUserInviteSignupDetails> => {
    let response: any = await post(`/invitation/validate/`, {
        invitation_id: invitationUid,
        invitation_code: invitationCode,
    });
    const data: any = response.data;

    if (data.invitation_status === 'INVITATION_REVOKED') {
        throw new Error(`Invitation has been removed`);
    }

    if (data) {
        const userSignupDetail: IUserInviteSignupDetails = {
            invitedByEnterprise: data.invited_by_enterprise_name,
            invitedByUser: data.invited_by_name || '-',
            invitedByUserEmail: data.invited_by_email,
            invitedFor: data.invited_for_name || '',
            inviteValid: data.invitation_status === 'INVITATION_ACTIVE',
            fullName: data.invited_name,
            email: data.invited_email,
            userName: '',
            password: '',
            confirmPassword: '',
            invitationCode,
            invitationUid,
            inviteType: data.invitation_type,
            inviteStatus: data.invitation_status,
            invite_type:
                data.invitation_type === 'BUYER_ENTERPRISE_INVITE' ||
                data.invitation_type === 'BUYER_CONTACT_INVITE' ||
                data.invitation_type === 'JOIN_ORGANIZATION_BUYER_INVITE'
                    ? 'BUYER'
                    : 'SELLER',
        };

        return userSignupDetail;
    }
    return response;
};

export const addAdminInvitedUser = async (
    details: IUserInviteSignupDetails
): Promise<boolean> => {
    try {
        await post(`/authentication/signup/`, {
            password: details.password,
            name: details.fullName,
            username: details.userName,
            invitation_code: details.invitationCode,
            invitation_id: details.invitationUid,
        });
        return true;
    } catch (error) {
        return false;
    }
};

export const addInvitedUser = async (
    details: IUserInviteSignupDetails
): Promise<boolean> => {
    try {
        await post(`/authentication/signup/user/invite/`, {
            password: details.password,
            name: details.fullName,

            username: details.userName,
            email: details.email,
            invitation_code: details.invitationCode,
            invitation_id: details.invitationUid,
            invite_type: details.invite_type,
        });
        return true;
    } catch (error) {
        return false;
    }
};

export const registerInvitedUserWithEnterprise = async (
    userDetails: ISignUpUserDetails,
    companyDetails: ISignupCompanyDetails,
    invitationUid: string,
    invitationCode: string
): Promise<boolean> => {
    try {
        await post(`/authentication/signup/enterprise/invite/`, {
            name: userDetails.full_name,
            username: userDetails.username,
            password: userDetails.password,
            email: userDetails.email,
            enterprise_name: companyDetails.company_name,
            dba: companyDetails.DBA_name,
            websites:
                companyDetails.website_URL.length > 0
                    ? [`http://${companyDetails.website_URL}`]
                    : [],
            invitation_id: invitationUid,
            invitation_code: invitationCode,
            billing_currency_id: companyDetails.billing_currency_id,
            invite_type: userDetails.invite_type,
        });
        return true;
    } catch (error) {
        return false;
    }
};

export const requestInvitedUserToJoin = async (
    userDetails: ISignUpUserDetails,
    entityUid: string,
    invitation_id: string,
    invitation_code: string
): Promise<boolean> => {
    try {
        await post(`/authentication/signup/request/enterprise/invite/`, {
            name: userDetails.full_name,
            username: userDetails.username,
            password: userDetails.password,
            email: userDetails.email,
            entity_list: [entityUid],
            invitation_id: invitation_id,
            invitation_code: invitation_code,
            invite_type: userDetails.invite_type,
        });
        return true;
    } catch (error) {
        return false;
    }
};
