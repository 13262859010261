import {
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    IconButton,
    styled,
    Typography,
} from '@mui/material';
import React from 'react';
import { FWButton } from './FWButton';

export const CustomDialog = styled(Dialog)((props) => ({
    '& .MuiPaper-root': {
        backgroundColor: '#ffffff',
        borderRadius: '12px',
        [props.theme.breakpoints.up('md')]: {
            minWidth: '560px',
        },
        [props.theme.breakpoints.down('md')]: {
            minWidth: '260px',
        },
    },
}));

export const FWPopup = ({
    open,
    setOpen,
    onClickConfirm,
    title,
    msg,
    btnColor = 'primary',
    btnTitle = 'Confirm',
    secondaryBtnTitle = 'Cancel',
    secondaryBtnOnClickConfirm,
    btnLoading = false,
    hideCancel = false,
    hideConfirm = false,
    hideX = false,
    preLine = false,
}: {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    onClickConfirm: () => void;
    title: string;
    msg: string;
    btnColor?: 'primary' | 'error' | 'warning' | 'success';
    btnTitle?: string;
    secondaryBtnTitle?: string;
    secondaryBtnOnClickConfirm?: () => void;
    btnLoading?: boolean;
    hideCancel?: boolean;
    hideConfirm?: boolean;
    hideX?: boolean;
    preLine?: boolean; // if true, msg will be displayed in pre-line format
}) => {
    const handleClose = () => setOpen(false);

    return (
        <CustomDialog
            open={open}
            // onClose={handleClose}
        >
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '24px 24px 0px 24px',
                }}
            >
                <Typography variant="h4">{title}</Typography>
                {!hideX && (
                    <IconButton
                        size="small"
                        onClick={(e) => {
                            e.stopPropagation();
                            handleClose();
                        }}
                    >
                        <i className="bi bi-x" style={{ fontSize: '2rem' }} />
                    </IconButton>
                )}
            </div>
            <DialogContent sx={{ padding: '0px 24px 12px 24px' }}>
                <Typography whiteSpace={preLine ? 'pre-line' : undefined}>
                    {msg}
                </Typography>
            </DialogContent>
            <DialogActions sx={{ padding: '12px 24px 24px 24px' }}>
                {!hideCancel && (
                    <FWButton
                        variant="outlined"
                        color={btnColor}
                        onClick={(e) => {
                            e.stopPropagation();
                            secondaryBtnOnClickConfirm
                                ? secondaryBtnOnClickConfirm()
                                : handleClose();
                        }}
                    >
                        {secondaryBtnTitle}
                    </FWButton>
                )}
                {!hideConfirm && (
                    <FWButton
                        variant="contained"
                        color={btnColor}
                        onClick={(e) => {
                            e.stopPropagation();
                            onClickConfirm();
                        }}
                        disabled={btnLoading}
                    >
                        {btnLoading ? (
                            <CircularProgress
                                size={24}
                                style={{ color: '#c4c4c8' }}
                            />
                        ) : (
                            btnTitle
                        )}
                    </FWButton>
                )}
            </DialogActions>
        </CustomDialog>
    );
};
