import { store } from '../../Redux/Store';

const locale: { [key: string]: string } = {
    USD: 'en-US',
    INR: 'en-IN',
    EUR: 'de-DE',
};

export const FWNumberFormatter = (
    number: number,
    currency_code_abbreviation: string | null = null,
    minimumFractionDigits: number = 2
): string => {
    const data = store.getState().UserDefaultsStore;
    let number_format = 'en-US';
    if (data && data.number_format) {
        number_format = locale[data.number_format];
    }
    if (
        currency_code_abbreviation !== null &&
        currency_code_abbreviation !== ''
    ) {
        return new Intl.NumberFormat(number_format, {
            minimumFractionDigits,
            style: 'currency',
            currency: currency_code_abbreviation,
        }).format(number);
    } else {
        return new Intl.NumberFormat(number_format, {
            minimumFractionDigits,
        }).format(number);
    }
};
