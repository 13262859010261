import FWPopover from '../../../../Common/FWPopover';
import { FWTypography } from '../../../../Common/FWTypograhy';
import { IItemSummary } from '../../Interfaces/ItemDirectoryInterface';

interface IItemDirectoryEntitiesPopoverProps {
    data: IItemSummary;
    open: boolean;
    anchor: HTMLElement | null;
    handleClose: () => void;
}

const ItemDirectoryEntitiesPopover = ({
    data,
    open,
    anchor,
    handleClose,
}: IItemDirectoryEntitiesPopoverProps) => {
    return (
        <FWPopover
            open={open}
            anchorEl={anchor}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            onClose={handleClose}
        >
            <div className="p--5">
                <div className="mb--5">
                    <FWTypography variant="h5" sx={{ fontWeight: '500' }}>
                        Entities
                    </FWTypography>
                </div>
                <FWTypography>
                    {data.entities
                        .sort((vendorA, vendorB) =>
                            vendorA.localeCompare(vendorB)
                        )
                        .map((vendor, index) => {
                            return (
                                <FWTypography>
                                    {index + 1}. {vendor}
                                    {index + 1 !== data.entities.length ? (
                                        <br />
                                    ) : (
                                        ''
                                    )}
                                </FWTypography>
                            );
                        })}
                </FWTypography>
            </div>
        </FWPopover>
    );
};

export default ItemDirectoryEntitiesPopover;
