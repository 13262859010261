import { Grid } from '@mui/material';
import { FWInput } from '../../Common/FWInput';
import { ISingleFieldBaseInfo } from '../interface/SingleFieldStructure';
import { getLocalISOTime } from '../helpers/projectHelpers';

export interface ISingleDateTimeFieldRendererProps {
    rendererProperty: ISingleFieldBaseInfo;
    value: string | null;
    updateHandler: (newValue: string | null) => void;
    error?: boolean;
    helper?: {
        text: string;
        textColor?: 'primary' | 'error' | 'warning' | 'success' | 'text';
        icon?: boolean;
        iconColor?: 'primary' | 'error' | 'warning' | 'success';
    };
    minimumDate?: string;
    viewMode?: boolean;
}

const convertDateTimeTomyRequiredString = (str: string) => {
    const date = new Date(str);

    const yyyy = date.getFullYear();
    const mm = date.getMonth() + 1;
    const dd = date.getDate();

    const hh = date.getHours();
    const min = date.getMinutes();

    return `${yyyy}-${mm < 10 ? '0' : ''}${mm}-${dd < 10 ? '0' : ''}${dd}T${
        hh < 10 ? '0' : ''
    }${hh}:${min < 10 ? '0' : ''}${min}:00Z`;
};

const SingleDateTimeFieldRenderer = ({
    rendererProperty,
    updateHandler,
    value,
    error,
    helper,
    minimumDate,
    viewMode,
}: ISingleDateTimeFieldRendererProps) => {
    const onChangeHandler = (e: any) => {
        if (e.target.value !== '')
            updateHandler(convertDateTimeTomyRequiredString(e.target.value));
        else updateHandler(null);
    };

    return (
        <Grid item xs={rendererProperty.gridSize}>
            <FWInput
                type="datetime-local"
                value={value ? getLocalISOTime(value).replace(':00Z', '') : ''}
                onChange={onChangeHandler}
                label={
                    rendererProperty.label +
                    (rendererProperty.required ? '' : ' (optional)')
                }
                error={error}
                helper={helper}
                {...(minimumDate && {
                    InputProps: {
                        inputProps: { min: minimumDate },
                    },
                })}
                disabled={viewMode}
            />
        </Grid>
    );
};

export default SingleDateTimeFieldRenderer;
