import { Box } from '@mui/material';

import { memo } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { CustomFieldType } from '../../../Global/Interfaces/TemplateInterface';
import { FieldTypeLabel } from '../../constants/projectDashboardConstants';
import { DashboardSort } from '../../interface/project.model';
import ProjectDashboardSortFieldRowContent from './ProjectDashboardSortFieldRowContent';

const ProjectDashboardSortFieldRow = ({
    index,
    fieldTypeLabels,
    updateSortList,
    sortRow,
    singleSortField,
    removeSortRow,
    selectedFields,
}: {
    index: number;
    singleSortField: boolean;
    sortRow: DashboardSort;
    removeSortRow: (index: number) => void;
    updateSortList: (
        index: any,
        updatedField:
            | {
                  type: 'FIELD';
                  value: string;
                  isBuiltIn: boolean | null;
                  fieldType: CustomFieldType;
              }
            | {
                  type: 'ORDER';
                  ascending: boolean;
              }
    ) => void;
    selectedFields: string[];
    fieldTypeLabels: FieldTypeLabel[];
}) => {
    return (
        <Draggable
            key={sortRow.field + `${sortRow.ascending}`}
            draggableId={sortRow.field + `${sortRow.ascending}`}
            index={index}
            isDragDisabled={false}
        >
            {(provided, snapshot) => (
                <Box
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                >
                    <ProjectDashboardSortFieldRowContent
                        selectedFields={selectedFields}
                        fieldTypeLabels={fieldTypeLabels}
                        removeSortRow={removeSortRow}
                        index={index}
                        provided={provided}
                        singleSortField={singleSortField}
                        sortRow={sortRow}
                        updateSortList={updateSortList}
                    />
                </Box>
            )}
        </Draggable>
    );
};

export default memo(ProjectDashboardSortFieldRow);
