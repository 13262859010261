import { Box } from '@mui/material';
import { ColumnApi, GridApi, RowClassRules, RowNode } from 'ag-grid-community';
import { isEmpty, isNull } from 'lodash';
import { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FWButton } from '../../Common/FWButton';
import { FWTooltip } from '../../Common/FWCustomTooltip';
import FWDataGrid from '../../Common/FWDataGrid';
import { FWTypography } from '../../Common/FWTypograhy';
import { HookStateValue } from '../../Common/Hooks/StateHook';
import { FWNumberFormatter } from '../../Common/Utils/CurrencyUtils';
import ViewTaxAndShippingPopup from '../../Components/Shared/TaxAndShippingPopup';
import { useFeatureQuota } from '../../FeatureAccess/Hooks/FeatureQuotaHook';
import { planUpgradeTooltipText } from '../../FeatureAccess/Utils/planUpgradeTooltipText';
import usePermissionCheck from '../../Global/Hooks/usePermissionCheck';
import MakePaymentPortal from '../Components/MakePaymentPortal';
import { PaymentsItemPopup } from '../Components/PaymentsItemPopup';
import PendingAmountPopover, {
    IPendingAmountData,
} from '../Components/PendingAmountPopover';
import { paymentsPendingInvoiceColumns } from '../Constants/PaymentsDashboardConstants';
import {
    IPaymentsPendingListProviders,
    usePaymentsPendingList,
} from '../Hooks/PaymentsDashboardHook';
import {
    IInvoiceViewPaymentItem,
    IPaymentsItemSummary,
    IPaymentTotalInfo,
    PaymentItemType,
} from '../Interfaces/PaymentsInterface';
import PrepaymentInvoicesPopup from '../Pages/PrepaymentInvoicesPopup';
import { PendingView } from './PaymentsPendingListContainer';

const PaymentsPendingListInvoiceViewContainer = ({
    search,
    allowPaymentBeforeQcForEntity,
}: {
    search: string;
    filter: boolean;
    allowPaymentBeforeQcForEntity: {
        [entityUid: string]: boolean;
    };
}) => {
    const history = useHistory();
    const [gridApi, setGridApi] = useState<GridApi | null>(null);
    const [columnApi, setColumnApi] = useState<ColumnApi | null>(null);

    const {
        hookState,
        pendingList,
        setSelectedInvoiceItems,
        makePaymentForInvoiceGroupedItems,
    }: IPaymentsPendingListProviders = usePaymentsPendingList(
        PendingView.INVOICE
    );

    const [payPermissions] = usePermissionCheck('BUYER', 'PAYMENT', [
        'VIEW',
        'EDIT',
    ]);

    const invoiceViewItems: IInvoiceViewPaymentItem[] = useMemo(() => {
        // pendingList.length > 0 && 'IInvoiceViewPaymentItem' in pendingList
        //     ? (pendingList as unknown as IInvoiceViewPaymentItem[])
        //     : [],

        if (
            pendingList.length > 0 &&
            'IInvoiceViewPaymentItem' in pendingList[0]
        ) {
            return pendingList as IInvoiceViewPaymentItem[];
        }
        return [];

        // ),
    }, [pendingList]);

    const [pendingAmountPopupDetails, setPendingAmountPopupDetails] =
        useState<IPendingAmountData | null>(null);

    const [totalPopupDetails, setTotalPopupDetails] =
        useState<IPaymentTotalInfo | null>(null);
    const [totalAnchorEl, setTotalAnchorEl] = useState<HTMLElement | null>(
        null
    );
    const [pendingAmountAnchorEl, setPendingAmountAnchorEl] =
        useState<HTMLElement | null>(null);

    const [prepaymentInvoicesPopupPOUid, setPrepaymentInvoicesPopupPOUid] =
        useState<string | null>(null);

    const [itemPopupDetails, setItemPopupDetails] =
        useState<IPaymentsItemSummary | null>(null);

    const handleItemPopupToggle = (item: IPaymentsItemSummary) => {
        setItemPopupDetails(item);
    };

    const [canSelectOnly, setCanSelectOnly] = useState<{
        entityUid: string;
        vendorUid: string;
        prePayIndex: null | number;
        currencyUid: string;
        poId: string;
    }>({
        entityUid: '',
        vendorUid: '',
        prePayIndex: null,
        currencyUid: '',
        poId: '',
    });
    const [selectedItemsIndex, setSelectedItemsIndex] = useState<number[]>([]);

    const canMakePayment = useMemo(() => {
        let can = false;
        for (const entityUid in payPermissions) {
            if (
                Object.prototype.hasOwnProperty.call(payPermissions, entityUid)
            ) {
                const permission = payPermissions[entityUid];
                if (permission.EDIT) {
                    can = true;
                    break;
                }
            }
        }
        if (!can && columnApi) {
            columnApi.setColumnVisible('selection', false);
        }
        if (can && columnApi) {
            columnApi.setColumnVisible('selection', true);
        }
        return can;
    }, [payPermissions, columnApi]);

    const rowClassRules: RowClassRules = useMemo(() => {
        return {
            'disable-checkbox': ({
                data,
            }: {
                data: IInvoiceViewPaymentItem;
            }) => {
                if (
                    payPermissions[data.entityUid] &&
                    !payPermissions[data.entityUid].EDIT
                ) {
                    return true;
                }
                if (
                    data.poOnHold ||
                    data.poTermination ||
                    data.allItemsonHold ||
                    data.allItemTermination ||
                    data.canMakePayment
                ) {
                    return true;
                }

                if (
                    data.paymentType !== PaymentItemType.PREPAYMENT &&
                    data.all_qc_status === 'PENDING' &&
                    (!Object.keys(allowPaymentBeforeQcForEntity).includes(
                        data.entityUid //If entity is not present in the list, then it means it is not allowed to make payment before QC
                    ) ||
                        !allowPaymentBeforeQcForEntity[data.entityUid])
                    //If entity is present in the list, then check if it is allowed to make payment before QC
                ) {
                    return true;
                }

                if (
                    !isEmpty(canSelectOnly.entityUid) &&
                    !isEmpty(canSelectOnly.vendorUid) &&
                    isNull(canSelectOnly.prePayIndex)
                ) {
                    return (
                        data.entityUid !== canSelectOnly.entityUid ||
                        data.vendorUid !== canSelectOnly.vendorUid ||
                        data.paymentType === PaymentItemType.PREPAYMENT
                    );
                }

                if (!isNull(canSelectOnly.prePayIndex)) {
                    return (
                        data.poId !== canSelectOnly.poId &&
                        data.paymentType === PaymentItemType.GOODS_PAYMENT
                    );
                }

                return data.pendingAmount <= 0;
            },
        };
    }, [canSelectOnly, payPermissions, allowPaymentBeforeQcForEntity]);

    // const handleRowClick = ({ data }: { data: IInvoiceViewPaymentItem }) => {
    //     const entity = data;

    //     localStorage.setItem(
    //         'paymentItemIds',
    //         JSON.stringify(data.invoiceItemUids)
    //     );
    //     localStorage.setItem(
    //         'paymentEntityDetails',
    //         JSON.stringify({
    //             buyerEntityUid: entity.entityUid,
    //             buyerEntityName: entity.entityName,
    //             sellerEntityUid: entity.vendorUid,
    //             sellerEntityName: entity.vendorName,
    //             currencyUid: entity.currencyUid,
    //         })
    //     );
    //     // history.push(`/buyer/payments/invoice/${data.invoiceId}`);
    //     history.push(`/buyer/payments/make-payment`);
    // };

    useEffect(() => {
        if (selectedItemsIndex.length === 0) {
            setCanSelectOnly({
                entityUid: '',
                vendorUid: '',
                prePayIndex: null,
                currencyUid: '',
                poId: '',
            });
        }
        if (
            invoiceViewItems.length > 0 &&
            selectedItemsIndex.length > 0 &&
            gridApi
        ) {
            const item = invoiceViewItems[selectedItemsIndex[0]];
            setCanSelectOnly({
                entityUid: item.entityUid,
                vendorUid: item.vendorUid,
                prePayIndex:
                    item.paymentType === PaymentItemType.PREPAYMENT
                        ? selectedItemsIndex[0]
                        : null,
                currencyUid: item.currencyUid,
                poId: item.poId,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedItemsIndex]);

    useEffect(() => {
        if (gridApi)
            gridApi.forEachNode((node: RowNode) => {
                const newData = {
                    ...node.data,
                    trigger: !node.data.trigger,
                };
                node.setData(newData);
            });
    }, [canSelectOnly, gridApi]);

    const selectedItems = useMemo(() => {
        const items = invoiceViewItems.filter((item) =>
            selectedItemsIndex.includes(item.id)
        );
        setSelectedInvoiceItems(items);
        return items;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedItemsIndex, invoiceViewItems]);

    const handleMakePayments = () => {
        makePaymentForInvoiceGroupedItems();
        if (selectedItems[0].paymentType === PaymentItemType.PREPAYMENT) {
            history.push(`/buyer/payments/make-prepayment`);
        } else {
            history.push(`/buyer/payments/make-payment`);
        }
    };

    useLayoutEffect(() => {
        if (gridApi) gridApi.onFilterChanged();
    }, [canSelectOnly, gridApi]);

    const doesExternalFilterPass = ({
        data,
    }: {
        data: IInvoiceViewPaymentItem;
    }): boolean => {
        if (data.pendingAmount === 0 && data.onHoldTerminationAmount === 0) {
            return false;
        }
        if (selectedItemsIndex.length > 0) {
            if (
                data.paymentType === PaymentItemType.PREPAYMENT &&
                isNull(canSelectOnly.prePayIndex)
            ) {
                return false;
            }
            if (data.paymentType === PaymentItemType.PREPAYMENT) {
                return (
                    data.poId === canSelectOnly.poId &&
                    data.paymentType === PaymentItemType.PREPAYMENT
                );
            }
            if (
                data.paymentType === PaymentItemType.GOODS_PAYMENT &&
                !isNull(canSelectOnly.prePayIndex)
            ) {
                return false;
            }
            if (data.pendingAmount === 0) return false;
            return (
                data.entityUid === canSelectOnly.entityUid &&
                data.vendorUid === canSelectOnly.vendorUid &&
                data.currencyUid === canSelectOnly.currencyUid &&
                !(
                    data.poOnHold ||
                    data.poTermination ||
                    data.allItemsonHold ||
                    data.allItemTermination
                )
            );
        }
        return true;
    };

    const handleTotalPopoverToggle = (
        el: HTMLElement,
        data: IPaymentTotalInfo
    ) => {
        setTotalAnchorEl(el);
        setTotalPopupDetails(data);
    };

    const handlePendingPopoverToggle = (
        el: HTMLElement,
        data: IPendingAmountData
    ) => {
        setPendingAmountAnchorEl(el);
        setPendingAmountPopupDetails(data);
    };

    const handlePrepaymentInvoicesPopoupToggle = (poUid: string) => {
        setPrepaymentInvoicesPopupPOUid(poUid);
    };

    const { quota, getQuotaData, quotaHookState } = useFeatureQuota();

    useEffect(() => {
        getQuotaData();
    }, [getQuotaData]);

    return (
        <>
            <FWDataGrid
                rows={
                    hookState.state === HookStateValue.LOADING
                        ? null
                        : invoiceViewItems
                }
                columns={paymentsPendingInvoiceColumns(
                    allowPaymentBeforeQcForEntity
                )}
                fixedHeight={610}
                search={search}
                context={{
                    handleItemPopupToggle,
                    handleTotalPopoverToggle,
                    handlePendingPopoverToggle,
                    handlePrepaymentInvoicesPopoupToggle,
                }}
                // onRowClicked={handleRowClick}
                onRowSelect={(items) => {
                    setSelectedItemsIndex(items);
                }}
                selectedRows={selectedItemsIndex}
                onReady={(params) => {
                    setGridApi(params.api);
                    setColumnApi(params.columnApi);
                }}
                rowClassRules={rowClassRules}
                doesExternalFilterPass={doesExternalFilterPass}
                isExternalFilterPresent={() => {
                    return true;
                }}
            />

            <div className="mt--5">
                <FWTypography>
                    Item(s) selected: {selectedItems.length}
                </FWTypography>
            </div>

            {canMakePayment && (
                <MakePaymentPortal>
                    <FWTooltip
                        title={
                            quotaHookState.state === HookStateValue.READY &&
                            quota['feature-payment-create-count']?.remaining ===
                                0
                                ? planUpgradeTooltipText.quotaExhaustedText
                                : ''
                        }
                    >
                        <Box>
                            <FWButton
                                disabled={
                                    (quotaHookState.state ===
                                        HookStateValue.READY &&
                                        quota['feature-payment-create-count']
                                            ?.remaining === 0) ||
                                    selectedItems.length === 0
                                }
                                variant="contained"
                                onClick={handleMakePayments}
                            >
                                Make payments
                            </FWButton>
                        </Box>
                    </FWTooltip>
                </MakePaymentPortal>
            )}

            <PaymentsItemPopup
                open={itemPopupDetails !== null}
                setOpen={() => {
                    setItemPopupDetails(null);
                }}
                itemDetails={{
                    type: 'PAYMENT_SUMMARY',
                    data: itemPopupDetails,
                }}
            />

            {totalPopupDetails && (
                <ViewTaxAndShippingPopup
                    measurement_unit_name={totalPopupDetails.measurementUnit}
                    shipping_per_unit={totalPopupDetails.shippingPerUnit}
                    quantity={totalPopupDetails.quantity}
                    tax_list={totalPopupDetails.taxList}
                    subTotal={
                        typeof totalPopupDetails.subTotal !== 'number'
                            ? 0
                            : totalPopupDetails.subTotal
                    }
                    currency_code_abbreviation={totalPopupDetails.currency}
                    anchorEl={totalAnchorEl}
                    setAnchorEl={setTotalAnchorEl}
                    formattedTotal={FWNumberFormatter(
                        totalPopupDetails.formattedTotal,
                        totalPopupDetails.currency
                    )}
                    discount={totalPopupDetails.discount}
                />
            )}

            {pendingAmountPopupDetails && (
                <PendingAmountPopover
                    data={pendingAmountPopupDetails}
                    anchorEl={pendingAmountAnchorEl}
                    setAnchorEl={setPendingAmountAnchorEl}
                />
            )}

            {!isNull(prepaymentInvoicesPopupPOUid) && (
                <PrepaymentInvoicesPopup
                    open={!isNull(prepaymentInvoicesPopupPOUid)}
                    poUid={prepaymentInvoicesPopupPOUid}
                    handleClose={() => {
                        setPrepaymentInvoicesPopupPOUid(null);
                    }}
                    user_type={'BUYER'}
                />
            )}

            <div className="flex flex--jcc">
                {' '}
                <FWTooltip
                    title={
                        quotaHookState.state === HookStateValue.READY &&
                        quota['feature-payment-create-count']?.remaining === 0
                            ? planUpgradeTooltipText.quotaExhaustedText
                            : ''
                    }
                >
                    <Box>
                        <FWButton
                            disabled={
                                (quotaHookState.state ===
                                    HookStateValue.READY &&
                                    quota['feature-payment-create-count']
                                        ?.remaining === 0) ||
                                selectedItems.length === 0
                            }
                            variant="contained"
                            onClick={handleMakePayments}
                        >
                            Make payment
                        </FWButton>
                    </Box>
                </FWTooltip>
            </div>
        </>
    );
};

export default PaymentsPendingListInvoiceViewContainer;
