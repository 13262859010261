import { Badge, Box, Divider, IconButton, Link, styled } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FWMenu } from '../../Common/FWCustomMenu';
import { FWTooltip } from '../../Common/FWCustomTooltip';
import FWIcon from '../../Common/FWIcon';
import {
    MarkReadActions,
    NotificationItem,
} from '../Components/NotificationItem';
import { useNotifications } from '../Hooks/useNotifications';
import {
    INotification,
    NotificationStatus,
} from '../Interfaces/NotificationInterfaces';
import {
    AdminNotificationType,
    BuyerNotificationType,
    SellerNotificationType,
} from '../Constants/NotificationsMap';

const NotificationBadge = styled(Badge)({
    '.MuiBadge-anchorOriginTopRightRectangular': {
        fontSize: '10px',
        minWidth: '13px',
        height: '18px',
        padding: '0 5px',
        top: '5px',
    },
});

const NotificationsListContainer = () => {
    const {
        notificationsList,
    }: {
        notificationsList: INotification[];
    } = useSelector((state: any) => state.NotificationsStore);

    const { markRead, markAllRead } = useNotifications();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) =>
        setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    const history = useHistory();

    const handleMarkRead = async (
        notificationId: string,
        notificationUrl?: string,
        action?: MarkReadActions
    ) => {
        const markedRead = await markRead(notificationId);
        if (markedRead) {
            if (action === MarkReadActions.REDIRECT) {
                notificationUrl && history.push(notificationUrl);
                handleClose();
            }
        } else toast.error(`There was an error processing your request`);
    };

    const location = useLocation();
    const getIdFromRoute = () => {
        const path = location.pathname.slice(1).split('/');
        let id = path[0];

        if (path.length === 2 && path[1] === 'invoices') {
            id += 'InvoiceDashboard';
        } else if (path.includes('summary')) {
            id += 'InvoiceSummary';
        } else if (path.includes('prepayment')) {
            if (path.includes('create')) {
                id +=
                    'PrepaymentInvoice_createPrepaymentInvoicePageNotifications';
                return id;
            } else {
                id += 'PrepaymentInvoice_PrepaymentInvoicePageNotifications';
                return id;
            }
        }

        id += '_notifications';
        return id;
    };

    const [currentPortal, setCurrentPortal] = useState('');

    useEffect(() => {
        const path = location.pathname.split('/');
        setCurrentPortal(path[1]);
    }, [location.pathname]);

    const NotificationTypeToCheck = (currentPortal: string) => {
        switch (currentPortal) {
            case 'buyer':
                return BuyerNotificationType;
            case 'seller':
                return SellerNotificationType;
            case 'admin':
                return AdminNotificationType;
            default:
                return {};
        }
    };

    const filteredNotificationList = notificationsList.filter((notification) =>
        Object.values(
            NotificationTypeToCheck(
                currentPortal === 'user'
                    ? localStorage
                          .getItem('lastClickedPortal')
                          ?.toLocaleLowerCase() ?? ''
                    : currentPortal
            )
        ).includes(notification.type as any)
    );

    const unreadNotifications: number = filteredNotificationList.reduce(
        (prev, next) => {
            return prev + (next.status === NotificationStatus.UNREAD ? 1 : 0);
        },
        0
    );

    const handleMarkAllRead = async () => {
        const markedAllRead = await markAllRead(filteredNotificationList);
        if (markedAllRead) handleClose();
        else toast.error(`There was an error processing your request`);
    };

    return (
        <>
            <FWTooltip
                title={
                    filteredNotificationList.length === 0
                        ? "You've got no notifications"
                        : ''
                }
            >
                <div className="flex flex--aic">
                    <IconButton
                        id={getIdFromRoute()}
                        color="primary"
                        onClick={handleClick}
                        disabled={filteredNotificationList.length === 0}
                    >
                        <NotificationBadge
                            badgeContent={unreadNotifications}
                            color="primary"
                        >
                            <FWIcon name="bi bi-bell" size={23} />
                        </NotificationBadge>
                    </IconButton>
                </div>
            </FWTooltip>
            <FWMenu
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                keepMounted
                open={open}
                onClose={handleClose}
                sx={{
                    maxHeight: '80vh',
                    padding: 0,
                    top: '4px',
                }}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        width: '350px',
                        overflow: 'visible',
                        '.MuiList-root': {
                            padding: 0,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 'calc(50% - 5px)',
                            width: 10,
                            height: 10,
                            bgcolor: '#fff',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
            >
                <div
                    className="flex flex--jcfe pt--10 pb--10"
                    style={{
                        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
                    }}
                >
                    <Link
                        className="fs--12 flex flex--aic pr--40"
                        color="primary.main"
                        style={{
                            textDecoration: 'none',
                            height: '25px',
                            opacity: unreadNotifications === 0 ? 0.7 : 1,
                            pointerEvents:
                                unreadNotifications === 0 ? 'none' : 'all',
                        }}
                        onClick={(e) => {
                            e.stopPropagation();
                            handleMarkAllRead();
                        }}
                    >
                        <FWIcon
                            name="bi bi-check2-all"
                            className="mr--5 fs--18"
                            style={{
                                marginTop: '3px',
                            }}
                        />
                        Mark{unreadNotifications === 0 && 'ed'} all as read
                    </Link>
                </div>
                <Box
                    sx={{
                        overflow: 'hidden',
                        overflowY: 'scroll',
                        height: '350px',
                        padding: '0',
                        width: '100%',
                        borderRadius: '12px',
                        '&:-webkit-scrollbar': {
                            display: 'none',
                        },
                    }}
                >
                    {filteredNotificationList.map((notification, idx) => {
                        return (
                            <div key={notification.id}>
                                <NotificationItem
                                    id={notification.id}
                                    emitMarkRead={handleMarkRead}
                                />
                                {idx !==
                                    filteredNotificationList.length - 1 && (
                                    <Divider sx={{ margin: '0 !important' }} />
                                )}
                            </div>
                        );
                    })}
                </Box>
            </FWMenu>
        </>
    );
};

export default NotificationsListContainer;
