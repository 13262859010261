/*
    This is a popup that will have a form inside to take necessary informations required to create a new template.
    The informations are:
        1. Template Name
        2. Template Description
        3. Template Tags
        4. Template Entities

    Validation on this form is:
        1. Template Name is required
        2. Template Name should be unique. (No two templates can have same name)
        3. Template Name should not be more than 150 characters
        4. Template Description should not be more than 750 characters
        5. Template Tags should not be more than 12

    Issues to be discussed:
        1. Templates can't have same name but how to check if the name is unique or not? What if the  template is in draft mode?
        2. How to to ensure template name across entinties are unique? What if the template is in draft mode or not yet shared but the template exists in other entity?

    
*/

import { CircularProgress, DialogContent, Grid } from '@mui/material';
import { FormikProvider, useFormik } from 'formik';
import { cloneDeep, debounce, isEmpty } from 'lodash';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import * as Yup from 'yup';
import { FWButton } from '../../Common/FWButton';
import { FWMenuItem } from '../../Common/FWCustomMenu';
import { FWInput } from '../../Common/FWInput';
import { FWPopupWithChildren } from '../../Common/FWPopupWithChildren';
import FWSelect from '../../Common/FWSelect';
import { FWTypography } from '../../Common/FWTypograhy';
import TagsInput from '../../Global/Components/TagsInput';
import { fetchEntities } from '../../Organizations/Admin/Services/EnterpriseService';
import { useGetAssignUserListQuery } from '../../ProjectGlCostCenter/services/project.services';
import {
    TemplateFormDetails,
    templateType,
} from '../Interfaces/TemplatePageInterfaces';
import { checkDuplicateTemplateName } from '../Services/template.service';

export const defaultAutoReminderData: {
    default_max_reminders: number;
    default_reminder_interval: number;
    default_reminder_required: boolean;
    default_intital_reminder_hours: number;
} = {
    default_reminder_required: true,
    default_intital_reminder_hours: 12,
    default_reminder_interval: 24,
    default_max_reminders: 4,
};

const AddTemplatePopUp = ({
    open,
    handleClose,
    templateModule,
    onSubmit,
    mode,
    templateData,
}: {
    open: boolean;
    mode: 'ADD' | 'EDIT';
    handleClose: () => void;
    templateModule: templateType;
    onSubmit: (values: TemplateFormDetails) => void;
    templateData?: TemplateFormDetails;
}) => {
    const initialTemplateData: TemplateFormDetails = {
        template_name: 'Template' + moment().format('YYYYMMDD'),
        entity_id: '',
        template_type: 'RFQ',
        tags: [],
        template_id: '',
        description: '',
        auto_reminders: {
            initial_reminder_hours:
                defaultAutoReminderData.default_intital_reminder_hours,
            is_remainder_required:
                defaultAutoReminderData.default_reminder_required,
            max_reminders: defaultAutoReminderData.default_max_reminders,
            reminder_interval:
                defaultAutoReminderData.default_reminder_interval,
        },
    };
    const [entities, setEntities] = React.useState<
        {
            entity_id: string;
            entity_name: string;
        }[]
    >([]);

    const [selectedEntity, setSelectedEntity] = React.useState<string>('');

    useEffect(() => {
        if (open && entities[0] && !formController.values.entity_id) {
            formController.setFieldValue('entity_id', entities[0].entity_id);
        }
        //Had to do this cause dont want go in infinite loop for formController
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, entities]);

    useEffect(() => {
        if (open && mode === 'EDIT' && templateData) {
            formController.setValues(cloneDeep(templateData));
        }
        //Had to do this cause dont want go in infinite loop for formController
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, mode, templateData]);
    useEffect(() => {
        if (open && mode === 'ADD' && templateModule) {
            formController.setValues((values) => ({
                ...values,
                template_type: templateModule,
            }));
        }
        //Had to do this cause module is not loading in the first render
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    const handleClosePopup = () => {
        handleClose();
        formController.resetForm();
        formController.setValues(cloneDeep(initialTemplateData));
    };

    const handleSubmit = (values: TemplateFormDetails) => {
        handleClosePopup();
        onSubmit(cloneDeep(values));
    };

    const formController = useFormik<TemplateFormDetails>({
        initialValues: cloneDeep(initialTemplateData),
        validationSchema: Yup.object({
            template_name: Yup.string()
                .required('Name is required')
                .max(150, 'Name should not be more than 150 characters')
                .test('unique', 'Name should be unique', async (value) => {
                    if (!value) return false;
                    if (templateData?.template_name === value) return true;
                    if (selectedEntity) {
                        const res = await checkDuplicateTemplateName(
                            value,
                            templateModule,
                            selectedEntity
                        );
                        return !res;
                    }
                    return true;
                }),

            description: Yup.string().max(
                750,
                'Description should not be more than 750 characters'
            ),
            tags: Yup.array().max(12, 'Tags should not be more than 12'),
            entity_id: Yup.string().required('Template Entities is required'),
        }),
        onSubmit: handleSubmit,
        validateOnChange: true,
        validateOnBlur: true,
    });

    // debounced function to validate work email
    const debouncedValidate = useMemo(
        () => debounce(formController.validateForm, 300),
        [formController.validateForm]
    );

    useEffect(() => {
        debouncedValidate(formController.values);
    }, [formController.values, debouncedValidate]);

    useEffect(() => {
        if (open)
            fetchEntities().then((res) => {
                const newEntities: {
                    entity_id: string;
                    entity_name: string;
                }[] = [];

                res.forEach((entity) => {
                    newEntities.push({
                        entity_id: entity.entityId,
                        entity_name: entity.entityName,
                    });
                });
                setEntities(newEntities);
            });
    }, [open]);

    const { data: userPermissionList } = useGetAssignUserListQuery(
        {
            permissionList: ['PROJECT_TEMPLATE'],
            entityid: '',
            allUsers: false,
        },
        {
            skip: templateModule !== 'PROJECT',
        }
    );

    const [
        listOfEntitiesForCurrentUserBasedOnPermission,
        setListOfEntitiesForCurrentUserBasedOnPermission,
    ] = useState<string[]>([]);

    useEffect(() => {
        if (userPermissionList) {
            const listOnEntities = userPermissionList.map(
                (user) => user.entity_id
            );

            setListOfEntitiesForCurrentUserBasedOnPermission(
                Array.from(new Set([...listOnEntities]))
            );
        }
    }, [userPermissionList]);

    useEffect(() => {
        setSelectedEntity(formController.values.entity_id);
    }, [formController.values.entity_id]);

    return (
        <FWPopupWithChildren
            title={`New ${templateModule} Template`}
            applyTitleStyling={false}
            open={open}
            handleClose={() => {
                handleClosePopup();
            }}
        >
            <DialogContent>
                <FormikProvider value={formController}>
                    <form onSubmit={formController.handleSubmit}>
                        <Grid container rowSpacing={4}>
                            <Grid item xs={12}>
                                <FWInput
                                    label="Template Name"
                                    value={formController.values.template_name}
                                    name="template_name"
                                    onChange={formController.handleChange}
                                    onBlur={formController.handleBlur}
                                    error={
                                        !isEmpty(
                                            formController.errors.template_name
                                        )
                                    }
                                    helper={{
                                        text: formController.errors
                                            .template_name
                                            ? formController.errors
                                                  .template_name
                                            : '',
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FWInput
                                    label="Template Type"
                                    value={formController.values.template_type}
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FWInput
                                    label="Template Description (optional)"
                                    name="description"
                                    multiline
                                    value={formController.values.description}
                                    onChange={formController.handleChange}
                                    onBlur={formController.handleBlur}
                                    error={
                                        formController.touched.description &&
                                        !isEmpty(
                                            formController.errors.description
                                        )
                                    }
                                    helper={{
                                        text:
                                            formController.touched
                                                .description &&
                                            formController.errors.description
                                                ? formController.errors
                                                      .description
                                                : '',
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FWTypography color="text.secondary">
                                    Template Entities
                                </FWTypography>
                                {entities.length === 1 ? (
                                    <FWSelect
                                        disabled
                                        value={entities[0].entity_id}
                                        fullWidth
                                        MenuProps={{
                                            PaperProps: {
                                                sx: {
                                                    backgroundColor: 'white',
                                                    borderRadius: '12px',
                                                    boxShadow:
                                                        '0px 5px 16px #C4C4c8',
                                                    width: 'calc(100% / 4)',
                                                },
                                            },
                                        }}
                                    >
                                        <FWMenuItem
                                            value={entities[0].entity_id}
                                        >
                                            {entities[0].entity_name}
                                        </FWMenuItem>
                                    </FWSelect>
                                ) : (
                                    <>
                                        <FWSelect
                                            fullWidth
                                            MenuProps={{
                                                PaperProps: {
                                                    sx: {
                                                        backgroundColor:
                                                            'white',
                                                        borderRadius: '12px',
                                                        boxShadow:
                                                            '0px 5px 16px #C4C4c8',
                                                        width: 'calc(100% / 4)',
                                                    },
                                                },
                                            }}
                                            value={
                                                formController.values.entity_id
                                            }
                                            onChange={(event) => {
                                                formController.setFieldValue(
                                                    'entity_id',
                                                    event.target.value
                                                );
                                            }}
                                            disabled={
                                                mode === 'EDIT' ? true : false
                                            }
                                        >
                                            {entities
                                                ?.filter(
                                                    (entity) =>
                                                        listOfEntitiesForCurrentUserBasedOnPermission.includes(
                                                            entity.entity_id
                                                        ) ||
                                                        templateModule !==
                                                            'PROJECT'
                                                )
                                                .map((u) => (
                                                    <FWMenuItem
                                                        value={u.entity_id}
                                                    >
                                                        {u.entity_name}
                                                    </FWMenuItem>
                                                ))}
                                        </FWSelect>
                                        <FWTypography
                                            color="error"
                                            variant="caption"
                                        >
                                            {formController.touched.entity_id &&
                                            !isEmpty(
                                                formController.errors.entity_id
                                            )
                                                ? formController.errors
                                                      .entity_id
                                                : ''}
                                        </FWTypography>
                                    </>
                                )}
                            </Grid>
                            <Grid item xs={12}>
                                <TagsInput
                                    label="Template Tags"
                                    value={formController.values.tags}
                                    options={[]}
                                    updateData={(value: string[]) => {
                                        formController.setFieldValue(
                                            'tags',
                                            value
                                        );
                                        if (!formController.touched.tags)
                                            formController.setFieldTouched(
                                                'tags',
                                                true
                                            );
                                    }}
                                />
                                <FWTypography color="error" variant="caption">
                                    {formController.touched.tags &&
                                    !isEmpty(formController.errors.tags)
                                        ? formController.errors.tags
                                        : ''}
                                </FWTypography>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                display={'flex'}
                                justifyContent={'flex-end'}
                            >
                                <FWButton
                                    variant="outlined"
                                    sx={{
                                        marginRight: '20px',
                                    }}
                                    onClick={() => {
                                        handleClosePopup();
                                    }}
                                >
                                    Cancel
                                </FWButton>
                                <FWButton
                                    variant="contained"
                                    type="submit"
                                    disabled={
                                        !formController.isValid ||
                                        formController.isSubmitting
                                    }
                                >
                                    {formController.isSubmitting ? (
                                        <CircularProgress
                                            size={24}
                                            style={{ color: '#c4c4c8' }}
                                        />
                                    ) : (
                                        'Save'
                                    )}
                                </FWButton>
                            </Grid>
                        </Grid>
                    </form>
                </FormikProvider>
            </DialogContent>
        </FWPopupWithChildren>
    );
};

export default AddTemplatePopUp;
