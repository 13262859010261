import { Box, DialogContent, Grid } from '@mui/material';
import React from 'react';
import { FWPopupWithChildren } from '../../../../Common/FWPopupWithChildren';
import { IVLNAboutNewFactoryLocations } from '../../../Interfaces/VendorLNInterfaces';
import VLNProfileAboutLocationComponent from './VLNProfileAboutLocationComponent';

const VLNProfileAboutOtherInfoLocationsPopup = ({
    locationsList,
    open,
    handleClose,
}: {
    locationsList: IVLNAboutNewFactoryLocations[];
    open: boolean;
    handleClose: () => void;
}) => {
    return (
        <FWPopupWithChildren
            title="Locations"
            open={open}
            handleClose={handleClose}
            size={'custom'}
            popupWidth={'1300px'}
        >
            <DialogContent>
                <Box height={'450px'}>
                    <Grid container spacing={2} height={'100%'}>
                        {locationsList
                            ?.filter(
                                (vendorAdd) =>
                                    vendorAdd.is_public &&
                                    vendorAdd.status === 'ACTIVE'
                            )
                            ?.map((locationData, idx) => (
                                <Grid
                                    key={idx}
                                    item
                                    xs={6}
                                    // overflow={'scroll'}
                                    // height={'450px'}
                                >
                                    <React.Fragment>
                                        <VLNProfileAboutLocationComponent
                                            locationData={locationData}
                                        />
                                        <Box height={15} />
                                    </React.Fragment>
                                </Grid>
                            ))}
                        {/* <Grid item xs={6}>
                            <Box
                                bgcolor={'lightgray'}
                                width={'100%'}
                                height={'100%'}
                                borderRadius={1}
                                overflow={'hidden'}
                            >
                                <iframe
                                    title="map"
                                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15087.564051460633!2d72.8397202!3d19.0245236!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7cec657dce6e5%3A0x2a51a2a29b1d6274!2sAshok%20Vada%20Pav!5e0!3m2!1sen!2sin!4v1688971952027!5m2!1sen!2sin"
                                    style={{
                                        border: 0,
                                        width: '100%',
                                        height: '100%',
                                    }}
                                    allowFullScreen
                                    referrerPolicy="no-referrer-when-downgrade"
                                ></iframe>
                            </Box>
                        </Grid> */}
                    </Grid>
                </Box>
            </DialogContent>
        </FWPopupWithChildren>
    );
};

export default VLNProfileAboutOtherInfoLocationsPopup;
