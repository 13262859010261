import { Grid, Typography } from '@mui/material';
import { FWTypography } from '../../Common/FWTypograhy';
import { getFWDate } from '../../Common/Utils/DateUtils';
import SignupPendingIcon from '../../Global/Components/SignupPendingIcon';
import VendorVerificationStatusIcon from '../../Global/Components/VendorVerificationStatusIcon';
import { IPurchaseOrder, PurchaseOrderStatus } from '../Interfaces/PO.model';
import {
    getPurchaseOrderDisplayStatusBuyer,
    getPurchaseOrderDisplayStatusSeller,
} from '../Misc/helperFunctions';
import BuyerPurchaseOrderStatusChip from './Buyer/BuyerPurchaseOrderStatusChip';
import SellerPurchaseOrderStatusChip from './Seller/SellerPurchaseOrderStatusChip';

interface IPoReviewBuyerAndVendorInfo {
    currentPurchaseOrder: IPurchaseOrder;
    enterpriseType: 'BUYER' | 'SELLER';
}

export default function PoReviewBuyerAndVendorInfo({
    currentPurchaseOrder,
    enterpriseType,
}: IPoReviewBuyerAndVendorInfo) {
    const displayStatusBuyer = getPurchaseOrderDisplayStatusBuyer(
        currentPurchaseOrder.status,
        currentPurchaseOrder.hold_information !== null,
        currentPurchaseOrder.termination_information !== null
    );
    const statusMessageBuyer =
        currentPurchaseOrder.status === PurchaseOrderStatus.DECLINED
            ? currentPurchaseOrder.seller_notes
            : currentPurchaseOrder.termination_information !== null
            ? currentPurchaseOrder.termination_information.buyer_notes
            : currentPurchaseOrder.hold_information !== null
            ? currentPurchaseOrder.hold_information.notes
            : currentPurchaseOrder.additional_information?.last_action_information?.notes?.trim();

    const displayStatusSeller = getPurchaseOrderDisplayStatusSeller(
        currentPurchaseOrder.status,
        currentPurchaseOrder.hold_information !== null,
        currentPurchaseOrder.termination_information !== null
    );

    const statusMessageSeller =
        currentPurchaseOrder.status === PurchaseOrderStatus.DECLINED
            ? currentPurchaseOrder.seller_notes
            : currentPurchaseOrder.termination_information !== null
            ? currentPurchaseOrder.termination_information.buyer_notes
            : '';

    return (
        <Grid
            container
            columnSpacing={3}
            margin="20px 20px 0 20px "
            rowSpacing={1}
            style={{
                border: '2px solid #BBBBBF',
                borderRadius: '12px',
                padding: '8px',
                width: '97%',
            }}
        >
            <Grid
                item
                md={6.3}
                xs={6.3}
                position={'relative'}
                sx={{
                    '&::after': {
                        content: '""',
                        position: 'absolute',
                        right: '0',
                        bottom: '5%',
                        borderRight: '2px solid #BBBBBF',
                        zIndex: '-1',
                        height: '85%',
                        width: '0%',
                    },
                }}
            >
                <FWTypography variant="h4" className="flex flex--jcsb">
                    Vendor details
                </FWTypography>

                <div className="flex flex--aic">
                    <FWTypography variant="h5" className="flex flex--jcsb">
                        {
                            currentPurchaseOrder.seller_information
                                .seller_entity_name
                        }
                    </FWTypography>
                    &nbsp;
                    {enterpriseType === 'BUYER' && (
                        <>
                            <VendorVerificationStatusIcon
                                verificationStatus={
                                    currentPurchaseOrder.seller_information
                                        .verification_status
                                }
                            />
                        </>
                    )}
                    {currentPurchaseOrder.seller_information.is_virtual && (
                        <>
                            &nbsp;
                            <SignupPendingIcon />
                        </>
                    )}
                </div>
                <Grid item md={12} className="flex">
                    {currentPurchaseOrder.seller_information
                        .seller_entity_address && (
                        <Typography
                            sx={{
                                color: 'text.primary',
                            }}
                        >
                            {
                                currentPurchaseOrder.seller_information
                                    .seller_entity_address
                            }
                        </Typography>
                    )}
                </Grid>
                {currentPurchaseOrder.seller_contact_list !== null &&
                    currentPurchaseOrder.seller_contact_list.length > 0 &&
                    currentPurchaseOrder.seller_contact_list.map(
                        (contact, idx) => {
                            let display_idx = '';
                            if (
                                currentPurchaseOrder.buyer_contact_list.length >
                                1
                            ) {
                                display_idx = (idx + 1).toString();
                            }
                            return (
                                <Grid
                                    item
                                    md={12}
                                    key={idx}
                                    // style={{
                                    //     display: 'grid',
                                    //     gridTemplateColumns: '21.8% 3.4% 65%',
                                    //     gridTemplateRows: 'auto auto',
                                    // }}
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                    }}
                                >
                                    <Grid item xs={2.6}>
                                        <FWTypography
                                            sx={{
                                                color: 'text.secondary',
                                                width: 'max-content',
                                            }}
                                        >
                                            Contact person {display_idx}
                                        </FWTypography>
                                    </Grid>
                                    <Grid item xs={0.45}>
                                        <FWTypography
                                            sx={{ color: 'text.secondary' }}
                                        >
                                            :
                                        </FWTypography>
                                    </Grid>

                                    <Grid
                                        item
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                        }}
                                    >
                                        <FWTypography>
                                            {contact.seller_contact_name?.trim()}
                                            ,
                                        </FWTypography>
                                        <span></span>
                                        <span></span>
                                        <Typography>
                                            {contact.seller_contact_phone
                                                ? `${contact.seller_contact_email}, `
                                                : `${contact.seller_contact_email}`}
                                            {contact.seller_contact_phone}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            );
                        }
                    )}
                {currentPurchaseOrder.seller_information
                    .seller_identification_info.length > 0 ? (
                    <>
                        {currentPurchaseOrder.seller_information.seller_identification_info.map(
                            (identification, idx) => (
                                <Grid
                                    item
                                    md={12}
                                    key={idx}
                                    style={{
                                        display: 'grid',
                                        gridTemplateColumns: '21.8% 3.4% 65%',
                                        gridTemplateRows: 'auto auto',
                                    }}
                                >
                                    <FWTypography
                                        sx={{ color: 'text.secondary' }}
                                    >
                                        {identification.identification_name}
                                    </FWTypography>
                                    <FWTypography
                                        sx={{ color: 'text.secondary' }}
                                    >
                                        :
                                    </FWTypography>
                                    <FWTypography>
                                        {identification.identification_value}
                                    </FWTypography>
                                </Grid>
                            )
                        )}
                    </>
                ) : (
                    <Grid
                        item
                        md={12}
                        style={{ display: 'flex', alignItems: 'center' }}
                    >
                        <FWTypography
                            sx={{ color: 'text.secondary' }}
                        ></FWTypography>
                    </Grid>
                )}
            </Grid>
            <Grid item md={5.7} xs={5.7}>
                <FWTypography
                    variant="h4"
                    // sx={{ color: 'text.secondary' }}
                >
                    PO details
                </FWTypography>
                <Grid item md={12} className="flex">
                    <Grid item md={2.43} className="flex flex--jcsb">
                        <FWTypography sx={{ color: 'text.secondary' }}>
                            Status
                        </FWTypography>
                        <FWTypography sx={{ color: 'text.secondary' }}>
                            :
                        </FWTypography>
                    </Grid>
                    <Grid item md={0.3}></Grid>

                    <Grid item md={8}>
                        {enterpriseType === 'BUYER' ? (
                            <BuyerPurchaseOrderStatusChip
                                displayStatus={displayStatusBuyer}
                                hoverText={statusMessageBuyer}
                                isAmended={Boolean(
                                    Object.keys(
                                        currentPurchaseOrder.past_versions ?? {}
                                    ).length
                                )}
                            />
                        ) : (
                            <SellerPurchaseOrderStatusChip
                                displayStatus={displayStatusSeller}
                                hoverText={statusMessageSeller}
                            />
                        )}
                    </Grid>
                </Grid>
                {/* {dept_num && (
                    <Grid item md={12} className="flex">
                        <Grid item md={2.43} className="flex flex--jcsb">
                            <FWTypography sx={{ color: 'text.secondary' }}>
                                GL No
                            </FWTypography>
                            <FWTypography sx={{ color: 'text.secondary' }}>
                                :
                            </FWTypography>
                        </Grid>
                        <Grid item md={0.3}></Grid>

                        <Grid item md={8}>
                            <FWTypography>{dept_num}</FWTypography>
                        </Grid>
                    </Grid>
                )} */}
                <Grid item md={12} className="flex">
                    <Grid item md={2.43} className="flex flex--jcsb">
                        <FWTypography sx={{ color: 'text.secondary' }}>
                            PO ID
                        </FWTypography>
                        <FWTypography sx={{ color: 'text.secondary' }}>
                            :
                        </FWTypography>
                    </Grid>
                    <Grid item md={0.3}></Grid>

                    <Grid item md={8}>
                        <FWTypography>
                            {currentPurchaseOrder.custom_purchase_order_id.trim()}
                        </FWTypography>
                    </Grid>
                </Grid>

                <Grid item md={12} className="flex">
                    <Grid item md={2.43} className="flex flex--jcsb">
                        <FWTypography sx={{ color: 'text.secondary' }}>
                            Issue date
                        </FWTypography>
                        <FWTypography sx={{ color: 'text.secondary' }}>
                            :
                        </FWTypography>
                    </Grid>
                    <Grid item md={0.3}></Grid>

                    <Grid item md={8}>
                        <FWTypography>
                            {currentPurchaseOrder.submission_datetime !==
                                null &&
                            currentPurchaseOrder.submission_datetime.trim()
                                .length > 0
                                ? getFWDate(
                                      currentPurchaseOrder.submission_datetime
                                  )
                                : '-'}
                        </FWTypography>
                    </Grid>
                </Grid>
                <Grid item md={12} className="flex">
                    <Grid item md={2.43} className="flex flex--jcsb">
                        <FWTypography sx={{ color: 'text.secondary' }}>
                            Accepted date
                        </FWTypography>
                        <FWTypography sx={{ color: 'text.secondary' }}>
                            :
                        </FWTypography>
                    </Grid>
                    <Grid item md={0.3}></Grid>

                    <Grid item md={8}>
                        <FWTypography>
                            {currentPurchaseOrder.acceptance_datetime !==
                                null &&
                            currentPurchaseOrder.acceptance_datetime.trim()
                                .length > 0
                                ? getFWDate(
                                      currentPurchaseOrder.acceptance_datetime
                                  )
                                : '-'}
                        </FWTypography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}
