import { useCallback, useEffect, useState } from 'react';
import {
    IHelpGlossary,
    IHelpInformationItem,
} from '../Interfaces/HelpInformationInterface';
import {
    fetchGlossaryData,
    fetchHelpSections,
} from '../Services/HelpInformationService';

const useHelpInformation = () => {
    const [helpData, setHelpData] = useState<IHelpInformationItem[]>([]);

    const getHelpInformationData = useCallback(async () => {
        try {
            const location = window.location.pathname;
            const currentPortal = location.split('/')[1];

            const helpData = await fetchHelpSections(currentPortal);
            setHelpData(helpData);
        } catch (error) {}
    }, []);

    useEffect(() => {
        getHelpInformationData();
    }, [getHelpInformationData]);

    return { helpData };
};

const useGlossaryInformation = () => {
    const [glossaryData, setGlossaryData] = useState<IHelpGlossary[]>([]);

    const glossaryInformationData = useCallback(async () => {
        try {
            const glossaryData = await fetchGlossaryData();
            setGlossaryData(glossaryData);
        } catch (error) {}
    }, []);

    useEffect(() => {
        glossaryInformationData();
    }, [glossaryInformationData]);

    return { glossaryData };
};

export { useGlossaryInformation, useHelpInformation };
