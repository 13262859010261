import {
    IRfqTemplate,
    TemplateStandardFieldsEnum,
} from '../../Global/Interfaces/TemplateInterface';
import {
    IItemCartTemplate,
    ItemCartTemplateStandardFieldsEnum,
} from '../Interfaces/ItemCartTemplateInterfaces';

const keyMap = [{ TARGET_RATE: 'BASE_RATE' }];

const keyMapPGC = ['PROJECT', 'GL', 'COST_CENTER'];

export const extractFields = (obj: any) => {
    const fields: string[] = [];
    for (const key in obj) {
        if (obj[key].fieldList) {
            obj[key].fieldList.forEach((field: any) => {
                if (field.field) {
                    fields.push(field.field);
                }
            });
        }
    }
    return fields;
};

export function convertName(name: string): string {
    return name
        .replace(/_/g, ' ')
        .toLowerCase()
        .replace(/^./, (s) => s.toUpperCase());
}

export const processFields = (
    cartTemplate: IItemCartTemplate,
    buyerTemplate: IRfqTemplate
) => {
    try {
        let newFields: any[] = [];
        if (buyerTemplate) {
            const reqFields = extractFields(cartTemplate.sections);
            const rfqFields = extractFields(buyerTemplate.sections);
            const eventDetails =
                buyerTemplate.sections.EVENT_DETAILS.fieldList.map(
                    (data) => data.field
                );
            reqFields.forEach((field) => {
                if (
                    eventDetails.includes(field) &&
                    !keyMapPGC.includes(field)
                ) {
                } else if (
                    !cartTemplate.fieldSettings.standardFields[
                        field as ItemCartTemplateStandardFieldsEnum
                    ]
                ) {
                } else {
                    newFields = [
                        ...newFields,
                        {
                            label: cartTemplate.fieldSettings.standardFields[
                                field as ItemCartTemplateStandardFieldsEnum
                            ]
                                ? cartTemplate.fieldSettings.standardFields[
                                      field as ItemCartTemplateStandardFieldsEnum
                                  ].label
                                : cartTemplate.fieldSettings.customFields[field]
                                      .label,
                            isChecked: true,
                            fieldExists:
                                field === Object.keys(keyMap[0])[0]
                                    ? //IF THE FIELD IS IN KEYMAP WE ALREADY KNOW IT EXISTS,
                                      //JUST UNDER A DIFFERENT NAME
                                      //THEREFORE NO ADDITIONAL CHECK REQUIRED
                                      buyerTemplate.fieldSettings
                                          .standardFields[
                                          Object.values(
                                              keyMap[0]
                                          )[0] as TemplateStandardFieldsEnum
                                      ].buyerSettings.showField
                                    : //WE CHECK FOR IF THE FIELD IS PRESENT IN THE RFQ TEMPLATE
                                    //AND CHECK FOR VISIBLITY
                                    rfqFields.includes(field) &&
                                      (buyerTemplate.fieldSettings
                                          .standardFields[
                                          field as TemplateStandardFieldsEnum
                                      ]
                                          ? buyerTemplate.fieldSettings
                                                .standardFields[
                                                field as TemplateStandardFieldsEnum
                                            ].buyerSettings.showField
                                          : buyerTemplate.fieldSettings
                                                .customFields[field]
                                                .buyerSettings.showField)
                                    ? true
                                    : false,
                            heirarchy: keyMapPGC.includes(field)
                                ? validatePGC(
                                      field,
                                      cartTemplate,
                                      buyerTemplate
                                  )
                                : true,
                            fieldType: buyerTemplate.fieldSettings
                                .standardFields[
                                field as TemplateStandardFieldsEnum
                            ]
                                ? 'STANDARD'
                                : 'CUSTOM',
                        },
                    ];
                }
            });
        }
        return newFields;
    } catch (err) {}
};

//FIELDS TO BE CREATED ON RFQ PAGE
export const getInCartTemplateFields = (
    cartTemplate: IItemCartTemplate,
    buyerTemplate: IRfqTemplate
) => {
    let cartFields = extractFields(cartTemplate.sections);
    let buyerFields = extractFields(buyerTemplate.sections);
    let inCartFields = cartFields.filter(
        (field) => !buyerFields.includes(field)
    );
    let standardFields = Object.keys(
        cartTemplate.fieldSettings.standardFields
    ).map((field) => field);
    let response = inCartFields;
    let finalResponse: any = [];
    response.forEach((data) => {
        if (standardFields.includes(data)) {
        } else {
            finalResponse = [
                ...finalResponse,
                {
                    label: cartTemplate.fieldSettings.standardFields[
                        data as ItemCartTemplateStandardFieldsEnum
                    ]
                        ? cartTemplate.fieldSettings.standardFields[
                              data as ItemCartTemplateStandardFieldsEnum
                          ].label
                        : cartTemplate.fieldSettings.customFields[
                              data as ItemCartTemplateStandardFieldsEnum
                          ].label,
                    isChecked: false,
                    showField: cartTemplate.fieldSettings.standardFields[
                        data as ItemCartTemplateStandardFieldsEnum
                    ]
                        ? cartTemplate.fieldSettings.standardFields[
                              data as ItemCartTemplateStandardFieldsEnum
                          ].buyerSettings.showField
                        : cartTemplate.fieldSettings.customFields[
                              data as ItemCartTemplateStandardFieldsEnum
                          ].buyerSettings.showField,
                },
            ];
        }
    });
    return finalResponse;
};

//MISMATCH FIELDS
export const getInBuyerTemplateFields = (
    cartTemplate: IItemCartTemplate,
    buyerTemplate: IRfqTemplate
): any[] => {
    let cartFields = extractFields(cartTemplate.sections);
    let buyerFields = extractFields(buyerTemplate.sections);
    let inBuyerFields = buyerFields.filter(
        (field) => !cartFields.includes(field)
    );
    let response = inBuyerFields;
    let eventFields = buyerTemplate.sections.EVENT_DETAILS.fieldList.map(
        (data) => data.field
    );
    let finalResponse: any[] = [];
    let values: any = [];
    keyMap.map((value) =>
        Object.values(value).forEach((field) => {
            values = [...values, field];
        })
    );
    response.forEach((data) => {
        let showField = buyerTemplate.fieldSettings.standardFields[
            data as TemplateStandardFieldsEnum
        ]
            ? buyerTemplate.fieldSettings.standardFields[
                  data as TemplateStandardFieldsEnum
              ].buyerSettings.showField
            : buyerTemplate.fieldSettings.customFields[
                  data as TemplateStandardFieldsEnum
              ].buyerSettings.showField;
        if (
            (eventFields.includes(data) && !keyMapPGC.includes(data)) ||
            values.includes(data) ||
            !showField
        ) {
        } else {
            finalResponse = [
                ...finalResponse,
                {
                    label: buyerTemplate.fieldSettings.standardFields[
                        data as TemplateStandardFieldsEnum
                    ]
                        ? buyerTemplate.fieldSettings.standardFields[
                              data as TemplateStandardFieldsEnum
                          ].label
                        : buyerTemplate.fieldSettings.customFields[
                              data as TemplateStandardFieldsEnum
                          ].label,
                    isChecked: false,
                    showField: buyerTemplate.fieldSettings.standardFields[
                        data as TemplateStandardFieldsEnum
                    ]
                        ? buyerTemplate.fieldSettings.standardFields[
                              data as TemplateStandardFieldsEnum
                          ].buyerSettings.showField
                        : buyerTemplate.fieldSettings.customFields[
                              data as TemplateStandardFieldsEnum
                          ].buyerSettings.showField,
                    heirarchy: keyMapPGC.includes(data)
                        ? validatePGC(data, cartTemplate, buyerTemplate)
                        : true,
                },
            ];
        }
    });
    return finalResponse;
};

const validatePGC = (field: string, cartTemplate: any, rfqTemplate: any) => {
    if (rfqTemplate) {
        const reqSettings =
            cartTemplate.fieldSettings.standardFields[
                field as TemplateStandardFieldsEnum
            ];
        const rfqSettings =
            rfqTemplate.fieldSettings.standardFields[
                field as TemplateStandardFieldsEnum
            ];
        if (reqSettings.fieldLevel === rfqSettings.fieldLevel) return true;
        else if (reqSettings.fieldLevel === 'OTHER') return true;
        else if (
            reqSettings.fieldLevel === 'ITEM' &&
            rfqSettings.fieldLevel === 'DELIVERY_SCHEDULE'
        )
            return true;
        else return false;
    }
};

// export const workingFunction = (
//     cartTemplate: IItemCartTemplate,
//     buyerTemplate: IRfqTemplate
// ) => {
//     const { cartCustomFields, cartStandardFields } =
//         extractFieldsFromCart(cartTemplate);
//     const { eventCustomFields, eventStandardFields } =
//         extractFieldsForEvent(buyerTemplate);

//     //COMPARE THE CUSTOM FIELDS STRAIGHT

//     //USE SECTIONS TO GET THE EVENT DETAILS AND DON'T COMPARE THEM OR SHOW THEM, SO BASICALLY FILTER THEM OUT
// };

// const getFieldsToBeExported = () => {};
// const getFieldsToBeCreated = () => {};
// const getMismatchFields = () => {};

// const extractFieldsForEvent = (obj: IRfqTemplate) => {
//     let eventCustomFields = Object.keys(obj.fieldSettings.customFields).map(
//         (key) => ({
//             // key: key,
//             // value: obj.fieldSettings.customFields[key].buyerSettings,
//             label: obj.fieldSettings.customFields[key].label,
//             isChecked: false,
//             isRequired:
//                 obj.fieldSettings.customFields[key].buyerSettings.required,
//             showField:
//                 obj.fieldSettings.customFields[key].buyerSettings.showField,
//         })
//     );
//     let eventStandardFields = Object.keys(obj.fieldSettings.standardFields).map(
//         (key) => ({
//             // key: key,
//             // value: obj.fieldSettings.standardFields[
//             //     key as TemplateStandardFieldsEnum
//             // ].buyerSettings,
//             label: obj.fieldSettings.standardFields[
//                 key as TemplateStandardFieldsEnum
//             ].label,
//             isChecked: false,
//             isRequired:
//                 obj.fieldSettings.standardFields[
//                     key as TemplateStandardFieldsEnum
//                 ].buyerSettings.required,
//             showField:
//                 obj.fieldSettings.standardFields[
//                     key as TemplateStandardFieldsEnum
//                 ].buyerSettings.showField,
//         })
//     );
//     return { eventCustomFields, eventStandardFields };
// };

// const extractFieldsFromCart = (obj: IItemCartTemplate) => {
//     let cartCustomFields = [];
//     cartCustomFields = Object.keys(obj.fieldSettings.customFields).map(
//         (key) => ({
//             // key: key,
//             // value: obj.fieldSettings.customFields[key].buyerSettings,
//             label: obj.fieldSettings.customFields[key].label,
//             isChecked: false,
//             isRequired:
//                 obj.fieldSettings.customFields[key].buyerSettings.required,
//             showField:
//                 obj.fieldSettings.customFields[key].buyerSettings.showField,
//         })
//     );
//     let cartStandardFields = Object.keys(obj.fieldSettings.standardFields).map(
//         (key: any) => ({
//             // key: key,
//             // value: obj.fieldSettings.standardFields[
//             //     key as ItemCartTemplateStandardFieldsEnum
//             // ].buyerSettings,
//             label: obj.fieldSettings.standardFields[
//                 key as ItemCartTemplateStandardFieldsEnum
//             ].label,
//             isChecked: false,
//             isRequired:
//                 obj.fieldSettings.standardFields[
//                     key as ItemCartTemplateStandardFieldsEnum
//                 ].buyerSettings.required,
//             showField:
//                 obj.fieldSettings.standardFields[
//                     key as ItemCartTemplateStandardFieldsEnum
//                 ].buyerSettings.showField,
//         })
//     );
//     return { cartCustomFields, cartStandardFields };
// };
//FIELDS TO BE EXPORTED
export const getCommonTemplateFields = (
    cartTemplate: IItemCartTemplate,
    buyerTemplate: IRfqTemplate
) => {
    let cartFields = extractFields(cartTemplate.sections);
    let buyerFields = extractFields(buyerTemplate.sections);
    let commonFields = cartFields.filter((field) =>
        buyerFields.includes(field)
    );
    let values: any[] = [];
    keyMap.map((value) =>
        Object.values(value).forEach((field) => {
            values = [...values, field];
        })
    );
    let eventFields = buyerTemplate.sections.EVENT_DETAILS.fieldList.map(
        (data) => data.field
    );
    let response = commonFields;
    let finalResponse: any = [];
    response.forEach((data) => {
        if (eventFields.includes(data) && !keyMapPGC.includes(data)) {
        } else {
            finalResponse = [
                ...finalResponse,
                {
                    label: buyerTemplate.fieldSettings.standardFields[
                        data as TemplateStandardFieldsEnum
                    ]
                        ? buyerTemplate.fieldSettings.standardFields[
                              data as TemplateStandardFieldsEnum
                          ].label
                        : buyerTemplate.fieldSettings.customFields[
                              data as TemplateStandardFieldsEnum
                          ].label,
                    isChecked: true,
                    showField: buyerTemplate.fieldSettings.standardFields[
                        data as TemplateStandardFieldsEnum
                    ]
                        ? buyerTemplate.fieldSettings.standardFields[
                              data as TemplateStandardFieldsEnum
                          ].buyerSettings.showField
                        : buyerTemplate.fieldSettings.customFields[
                              data as TemplateStandardFieldsEnum
                          ].buyerSettings.showField,
                    heirarchy: keyMapPGC.includes(data)
                        ? validatePGC(data, cartTemplate, buyerTemplate)
                        : true,
                },
            ];
        }
    });
    keyMap.forEach((key) => {
        let value = Object.values(key)[0];
        if (
            buyerTemplate.fieldSettings.standardFields[
                value as TemplateStandardFieldsEnum
            ] ||
            buyerTemplate.fieldSettings.customFields[value]
        ) {
            finalResponse.push({
                label: buyerTemplate.fieldSettings.standardFields[
                    value as TemplateStandardFieldsEnum
                ]
                    ? buyerTemplate.fieldSettings.standardFields[
                          value as TemplateStandardFieldsEnum
                      ].label
                    : buyerTemplate.fieldSettings.customFields[
                          value as TemplateStandardFieldsEnum
                      ].label,
                isChecked: true,
                showField: buyerTemplate.fieldSettings.standardFields[
                    value as TemplateStandardFieldsEnum
                ]
                    ? buyerTemplate.fieldSettings.standardFields[
                          value as TemplateStandardFieldsEnum
                      ].buyerSettings.showField
                    : buyerTemplate.fieldSettings.customFields[
                          value as TemplateStandardFieldsEnum
                      ].buyerSettings.showField,
                heirarchy: keyMapPGC.includes(value)
                    ? validatePGC(value, cartTemplate, buyerTemplate)
                    : true,
            });
        }
    });
    return finalResponse;
};
