import React from 'react';
import { Box, DialogContent, Typography, Button } from '@mui/material';
import { FWPopupWithChildren } from '../../Common/FWPopupWithChildren';

type Props = {
    isOpen: boolean;
    onClose: () => void;
};

const WelcomePopup = (props: Props) => {
    return (
        <FWPopupWithChildren
            open={props.isOpen}
            handleClose={() => {}}
            size={'large'}
            popupWidth={'80%'}
            title={'Welcome to Factwise!'}
            hideTitle={true}
            customHeaderElement={
                // <>
                //     <Box
                //         padding={'15px 0px'}
                //         bgcolor={'#f5f5f9'}
                //         display="flex"
                //         justifyContent="center"
                //     >
                //         <img
                //             style={{ height: 'auto', width: '2rem' }}
                //             src="/logo.png"
                //             alt="factwise logo"
                //         />
                //         <Typography
                //             fontWeight={600}
                //             fontSize={'1.5rem'}
                //             textAlign={'center'}
                //         >
                //             FactWise
                //         </Typography>
                //     </Box>
                // </>
                <></>
            }
        >
            <DialogContent sx={{ padding: '0px', overflow: 'hidden' }}>
                <Box marginBottom={'20px'} display="flex">
                    <Box flex="4" padding={'20px'}>
                        <Typography
                            variant={'h4'}
                            fontSize={'2rem'}
                            fontWeight="600"
                        >
                            We've upgraded you! Enjoy all premium features of
                            FactWise with your free trial
                        </Typography>
                        <Typography
                            variant={'body1'}
                            color="text.secondary"
                            marginTop={'20px'}
                        >
                            Customize events, issue POs, view all our insights,
                            create goods receipt, quality check, payments and
                            much more!
                        </Typography>
                        <Typography
                            variant={'body1'}
                            color="text.secondary"
                            marginTop={'20px'}
                        >
                            At the end of your trial you will be moved to free
                            plan unless you choose to upgrade.
                        </Typography>
                    </Box>
                    <Box flex="1" position={'relative'}>
                        <Box position={'absolute'} right={'0px'}>
                            <img
                                style={{ height: 'auto' }}
                                src="/res/welcomeCircle1.svg"
                                alt="factwise logo"
                            />
                        </Box>
                        <Box position={'absolute'} right={'0px'}>
                            <img
                                style={{ height: 'auto' }}
                                src="/res/welcomeCircle2.svg"
                                alt="factwise logo"
                            />
                        </Box>
                    </Box>
                </Box>
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems={'end'}
                >
                    <Box flex="1"></Box>
                    <Box flex="1" display="flex" justifyContent={'center'}>
                        <img
                            style={{ height: 'auto', width: '50%' }}
                            src="/res/welcome.png"
                            alt="factwise logo"
                        />
                    </Box>
                    <Box
                        flex="1"
                        display="flex"
                        justifyContent={'end'}
                        alignItems={'end'}
                    >
                        <Box padding={'5px'}>
                            <Button
                                sx={{
                                    fontSize: '2rem',
                                    fontWeight: 600,
                                    opacity: 0.7,
                                }}
                                onClick={props.onClose}
                            >
                                <i className="bi bi-arrow-right-circle-fill"></i>
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </DialogContent>
        </FWPopupWithChildren>
    );
};

export default WelcomePopup;
