import { CustomFieldType } from '../../SRM/Constants/Interfaces/documentTemplate';
import { IFieldTypeConstraints } from '../FieldTypes.model';

export const getFieldTypeContraintsFromBackend = ({
    field_type,
    choices,
    max_limit,
    min_limit,
    choice_type,
}: {
    field_type: string;
    choices?: string[];
    max_limit?: number;
    min_limit?: number;
    choice_type?: string;
}): IFieldTypeConstraints => {
    switch (field_type) {
        case 'BOOLEAN':
            return {
                fieldType: CustomFieldType.BOOLEAN,
            };
        case 'DATE':
            return {
                fieldType: CustomFieldType.DATE,
            };
        case 'DATETIME':
            return {
                fieldType: CustomFieldType.DATETIME,
            };
        // case 'COLLECTION':
        //     return {
        //         fieldType: 'COLLECTION',
        //     };
        // case 'LOCATION':
        //     return {
        //         // fieldType: 'LOCATION',
        //     };
        case 'INTEGER':
            return {
                fieldType: CustomFieldType.INTEGER,
                maxLimit: max_limit,
                minLimit: min_limit,
            };

        case 'FLOAT':
            return {
                fieldType: CustomFieldType.FLOAT,
                maxLimit: max_limit,
                minLimit: min_limit,
            };
        case 'SHORTTEXT':
            return {
                fieldType: CustomFieldType.SHORTTEXT,
                maxLimit: max_limit,
                minLimit: min_limit,
            };
        case 'LONGTEXT':
            return {
                fieldType: CustomFieldType.LONGTEXT,
                maxLimit: max_limit,
                minLimit: min_limit,
            };
        case 'CHOICE':
            if (choice_type) {
                return {
                    fieldType: CustomFieldType.CHOICE,
                    choices: choices ?? [],
                    choiceType: choice_type as 'DROPDOWN' | 'MULTI_SELECT',
                };
            }
            return {
                fieldType: CustomFieldType.CHOICE,
                choices: choices ?? [],
                choiceType: 'DROPDOWN',
            };
        case 'ATTACHMENT': {
            return {
                fieldType: CustomFieldType.ATTACHMENT,
            };
        }
        case 'TEMPLATES': {
            return {
                fieldType: CustomFieldType.TEMPLATE,
            };
        }
        case 'CURRENCY': {
            return {
                fieldType: CustomFieldType.CURRENCY,
                choices: [],
            };
        }
        default:
            return {
                fieldType: CustomFieldType.SHORTTEXT,
                maxLimit: 15,
                minLimit: 0,
            };
    }
};
