import { Avatar, Grid, Typography } from '@mui/material';
import { FWTypography } from '../../Common/FWTypograhy';
import { IPurchaseOrder } from '../Interfaces/PO.model';

interface IPoReviewTopLevelLogoInfo {
    currentPurchaseOrder: IPurchaseOrder;
    pdfWindowOpened: boolean;
}

export default function PoReviewTopLevelLogoInfo({
    currentPurchaseOrder,
    pdfWindowOpened,
}: IPoReviewTopLevelLogoInfo) {
    return (
        <>
            <Grid
                container
                columnSpacing={3}
                margin="20px 0 0 20px"
                style={{
                    border: '2px solid #BBBBBF',
                    borderRadius: '12px',
                    padding: '8px',
                    width: '97%',
                }}
            >
                <Grid
                    item
                    xs={6.3}
                    position={'relative'}
                    sx={{
                        '&::after': {
                            content: '""',
                            position: 'absolute',
                            right: '0',
                            bottom: '5%',
                            borderRight: '2px solid #BBBBBF',
                            zIndex: '-1',
                            height: '85%',
                            width: '0%',
                        },
                    }}
                    paddingLeft={'12px'}
                >
                    <Grid item xs={12} className="flex">
                        <Grid
                            item
                            xs={pdfWindowOpened ? 4 : 3}
                            height={'auto'}
                            width={'auto'}
                            display={'flex'}
                            alignContent={'center'}
                            justifyContent={'start'}
                            alignItems={'center'}
                        >
                            <Avatar
                                alt={
                                    currentPurchaseOrder.buyer_information
                                        .buyer_entity_name
                                }
                                src={
                                    currentPurchaseOrder.buyer_information
                                        .buyer_entity_logo
                                }
                                sx={{
                                    height: 'auto',
                                    width: '150px',
                                    aspectRatio: '1/1',
                                }}
                            />
                        </Grid>
                        <Grid item xs={pdfWindowOpened ? 8 : 9}>
                            <Grid item xs={12}>
                                <FWTypography variant="h4">
                                    {
                                        currentPurchaseOrder.buyer_information
                                            .buyer_entity_name
                                    }
                                </FWTypography>
                            </Grid>
                            <Grid item xs={12} className="flex">
                                <Grid
                                    item
                                    xs={2.43}
                                    className="flex flex--jcsb"
                                >
                                    <FWTypography
                                        sx={{ color: 'text.secondary' }}
                                    >
                                        Bill to
                                    </FWTypography>
                                    <FWTypography
                                        sx={{ color: 'text.secondary' }}
                                    >
                                        :
                                    </FWTypography>
                                </Grid>
                                <Grid item xs={0.3}></Grid>
                                <Grid item xs={8}>
                                    <Typography>
                                        {currentPurchaseOrder.buyer_information
                                            .billing_address ??
                                            'No buyer address found'}
                                    </Typography>
                                </Grid>
                            </Grid>
                            {currentPurchaseOrder.buyer_information
                                .buyer_identification_info.length > 0 ? (
                                <>
                                    {currentPurchaseOrder.buyer_information.buyer_identification_info.map(
                                        (identification, idx) => (
                                            <Grid item xs={12} className="flex">
                                                <Grid
                                                    item
                                                    xs={2.43}
                                                    className="flex flex--jcsb"
                                                >
                                                    <FWTypography
                                                        sx={{
                                                            color: 'text.secondary',
                                                        }}
                                                    >
                                                        {
                                                            identification.identification_name
                                                        }
                                                    </FWTypography>
                                                    <FWTypography
                                                        sx={{
                                                            color: 'text.secondary',
                                                        }}
                                                    >
                                                        :
                                                    </FWTypography>
                                                </Grid>
                                                <Grid item xs={0.3}></Grid>
                                                <Grid item xs={8}>
                                                    <Typography>
                                                        {
                                                            identification.identification_value
                                                        }
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        )
                                    )}
                                </>
                            ) : (
                                <Grid
                                    item
                                    xs={12}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <FWTypography
                                        sx={{ color: 'text.secondary' }}
                                    ></FWTypography>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={5.7}>
                    {/* //Created to maintain height otherwise no use */}
                    <FWTypography variant="h4" sx={{ opacity: '0' }}>
                        {
                            currentPurchaseOrder.buyer_information
                                .buyer_entity_name
                        }
                    </FWTypography>
                    <Grid item xs={12} className="flex">
                        <Grid item xs={2.43} className="flex flex--jcsb">
                            <FWTypography sx={{ color: 'text.secondary' }}>
                                Ship to
                            </FWTypography>
                            <FWTypography sx={{ color: 'text.secondary' }}>
                                :
                            </FWTypography>
                        </Grid>
                        <Grid item xs={0.3}></Grid>
                        <Grid item xs={8}>
                            <Typography>
                                {currentPurchaseOrder.buyer_information
                                    .shipping_address ??
                                    'No shipping address found'}
                            </Typography>
                        </Grid>
                    </Grid>
                    {currentPurchaseOrder.buyer_contact_list !== null &&
                        currentPurchaseOrder.buyer_contact_list.length > 0 &&
                        currentPurchaseOrder.buyer_contact_list.map(
                            (contact, idx) => {
                                return (
                                    <Grid item xs={12} className="flex">
                                        <Grid
                                            item
                                            xs={2.43}
                                            className="flex flex--jcsb"
                                        >
                                            <FWTypography
                                                sx={{ color: 'text.secondary' }}
                                            >
                                                Contact
                                            </FWTypography>
                                            <FWTypography
                                                sx={{ color: 'text.secondary' }}
                                            >
                                                :
                                            </FWTypography>
                                        </Grid>
                                        <Grid item xs={0.3}></Grid>
                                        <Grid item xs={8}>
                                            <Typography>
                                                <FWTypography
                                                    sx={{
                                                        ...(pdfWindowOpened && {
                                                            whiteSpace:
                                                                'pre-wrap',
                                                            wordBreak:
                                                                'break-all',
                                                        }),
                                                    }}
                                                >
                                                    {contact.buyer_contact_name?.trim()}
                                                    &nbsp; &middot; &nbsp;
                                                    {contact.buyer_contact_phone
                                                        ? `${contact.buyer_contact_email}, `
                                                        : `${contact.buyer_contact_email}`}
                                                    {
                                                        contact.buyer_contact_phone
                                                    }
                                                </FWTypography>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                );
                            }
                        )}
                </Grid>
            </Grid>
        </>
    );
}
