import { get } from '../../Utils/api';
import { IPOIDList } from '../Interfaces/Invoice.model';
import { IPODetails } from '../Interfaces/PO.model';

export const getPendingPOIDs = async (): Promise<IPOIDList[]> => {
    let resp = await get<any[]>(`/purchase_orders/invoices/`);
    let po_id_list = resp.data.map((po, idx) => ({
        po_id: po.purchase_order_id,
        custom_po_id: po.custom_purchase_order_id,
        termination_info: po.termination_information,
        hold_information: po.hold_information,
        buyer_entity_name: po.buyer_information.buyer_entity_name,
        item_count: po.additional_information.purchase_order_items_count,
        po_amount: '',
        po_issued_date: po.submission_datetime,
    }));
    return po_id_list;
};

export const getPODetails = async (
    newPOID: string,
    user_type: 'SELLER' | 'BUYER'
): Promise<IPODetails> => {
    let resp = await get<any>(
        `/purchase_orders/${newPOID}/?enterprise_type=${user_type}`
    );
    return resp.data;
};

export const getPODeliverySchedule = async (
    newPOID: string,
    user_type: 'SELLER' | 'BUYER'
): Promise<any[]> => {
    let resp = await get<any>(
        `/delivery_schedules/purchase_order/${newPOID}/?enterprise_type=${user_type}`
    );
    return resp.data;
};
