import {
    Box,
    Button,
    Checkbox,
    DialogActions,
    DialogContent,
    Divider,
    FormControlLabel,
    FormGroup,
} from '@mui/material';
import { cloneDeep } from 'lodash';
import { useEffect, useState } from 'react';
import { FWInput } from '../../Common/FWInput';
import { FWPopupWithChildren } from '../../Common/FWPopupWithChildren';
import { FWTypography } from '../../Common/FWTypograhy';
import { IEventItemDetails } from '../../Events/Interfaces/Buyer/RFQ.model';
import { IAddedVendorList } from '../../Events/Services/Buyer/RFQ.service';

interface IVendorSelectPopup {
    open: boolean;
    onClose(): void;
    vendors: IAddedVendorList[];
    onSubmit: (selectedVendorIDs: Set<string>) => void;
    items: IEventItemDetails[];
    itemVendors: { [key: string]: IAddedVendorList[] };
    preSelectedVendorIDs: Set<string>;
    preSelectedItemIDs: Set<string>;
}

export default function VendorSelectPopup(props: IVendorSelectPopup) {
    const [selectedVendorIDs, setSelectedVendorIDs] = useState<Set<string>>(
        new Set()
    );
    const [selectedItemIDs, setSelectedItemIDs] = useState<Set<string>>(
        new Set()
    );
    const [searchTerm, setSearchTerm] = useState<string>('');

    const handleSubmit = () => {
        // if (selectedItemIDs.size === 0 && selectedVendorIDs.size !== 0) {
        //     setSelectedVendorIDs(new Set());
        //     return;
        // }
        // if (selectedVendorIDs.size === 0 && selectedItemIDs.size !== 0) {
        //     setSelectedItemIDs(new Set());
        //     return;
        // }

        // take all the vendors checked(selected) by the user
        // also take all the items checked(selected) by the user
        // and add the vendor for that item to the vendor set to send the msg to
        let newSelectedVendorIDs = cloneDeep(selectedVendorIDs);
        for (let selectedItemID of Array.from(selectedItemIDs)) {
            const vendors = props.itemVendors[selectedItemID];
            for (let vendor of vendors) {
                newSelectedVendorIDs.add(vendor.entity_id);
            }
        }
        setSelectedVendorIDs(newSelectedVendorIDs);
        props.onSubmit(newSelectedVendorIDs);
    };

    useEffect(() => {
        if (props.open) {
            setSelectedVendorIDs(props.preSelectedVendorIDs);
            setSelectedItemIDs(props.preSelectedItemIDs);
        }
    }, [props.open, props.preSelectedItemIDs, props.preSelectedVendorIDs]);

    return (
        <FWPopupWithChildren
            open={props.open}
            handleClose={props.onClose}
            title={'Select Recipients'}
        >
            <DialogContent sx={{ margin: '12px' }}>
                <FWInput
                    placeholder="Search"
                    iconStart={<i className="bi bi-search" />}
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
                <Box height={'1rem'}></Box>
                <FWTypography color="text.secondary" margin="6px 0">
                    Vendors
                </FWTypography>
                <Divider />
                <FormGroup>
                    {/* make a select all button */}
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={
                                    selectedVendorIDs.size ===
                                    props.vendors.length
                                }
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        // setSelectedItemIDs((prev) => {
                                        //     let newset = cloneDeep(prev);
                                        //     props.items.forEach((item) => {
                                        //         newset.add(
                                        //             item.rfq_item_entry_id
                                        //         );
                                        //     });
                                        //     return newset;
                                        // });
                                        setSelectedVendorIDs((prev) => {
                                            let newset = cloneDeep(prev);
                                            props.items.forEach((item) => {
                                                const vendors =
                                                    props.itemVendors[
                                                        item.rfq_item_entry_id
                                                    ];
                                                for (let vendor of vendors) {
                                                    newset.add(
                                                        vendor.entity_id
                                                    );
                                                }
                                            });
                                            return newset;
                                        });
                                    } else {
                                        // setSelectedItemIDs((prev) => {
                                        //     let newset = cloneDeep(prev);
                                        //     props.items.forEach((item) => {
                                        //         newset.delete(
                                        //             item.rfq_item_entry_id
                                        //         );
                                        //     });
                                        //     return newset;
                                        // });
                                        setSelectedVendorIDs((prev) => {
                                            let newset = cloneDeep(prev);
                                            props.items.forEach((item) => {
                                                const vendors =
                                                    props.itemVendors[
                                                        item.rfq_item_entry_id
                                                    ];
                                                for (let vendor of vendors) {
                                                    newset.delete(
                                                        vendor.entity_id
                                                    );
                                                }
                                            });
                                            return newset;
                                        });
                                    }
                                }}
                            />
                        }
                        label="Select All"
                    />
                    {props.vendors
                        .filter((vendor) => {
                            if (searchTerm.length > 0) {
                                return vendor.entity_name
                                    .toLocaleLowerCase()
                                    .includes(searchTerm.toLocaleLowerCase());
                            } else {
                                return true;
                            }
                        })
                        .map((vendor) => (
                            <FormControlLabel
                                key={vendor.entity_id}
                                control={
                                    <Checkbox
                                        checked={selectedVendorIDs.has(
                                            vendor.entity_id
                                        )}
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                setSelectedVendorIDs((prev) => {
                                                    let newset =
                                                        cloneDeep(prev);
                                                    newset.add(
                                                        vendor.entity_id
                                                    );
                                                    return newset;
                                                });
                                                // setSelectedItemIDs((prev) => {
                                                //     let newset =
                                                //         cloneDeep(prev);
                                                //         // newset.forEach((itemID) => {
                                                //         //     const allVendorsExists = true;
                                                //         //     props.itemVendors[itemID].forEach((vendor) => {
                                                //         //         if(vendor.entity_id )
                                                //         //     });
                                                //         // });
                                                //         Object.keys(props.itemVendors).forEach((itemID)=>{
                                                //             props.itemVendors[itemID].
                                                //         })
                                                // });
                                            } else {
                                                setSelectedVendorIDs((prev) => {
                                                    let newset =
                                                        cloneDeep(prev);
                                                    newset.delete(
                                                        vendor.entity_id
                                                    );
                                                    return newset;
                                                });
                                            }
                                        }}
                                    />
                                }
                                label={vendor.entity_name}
                            />
                        ))}
                    <FWTypography color="text.secondary" margin="6px 0">
                        By Item(s)
                    </FWTypography>
                    <Divider />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={
                                    selectedItemIDs.size === props.items.length
                                }
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        setSelectedItemIDs((prev) => {
                                            let newset = cloneDeep(prev);
                                            props.items.forEach((item) => {
                                                newset.add(
                                                    item.rfq_item_entry_id
                                                );
                                            });
                                            return newset;
                                        });
                                        // setSelectedVendorIDs((prev) => {
                                        //     let newset = cloneDeep(prev);
                                        //     props.items.forEach((item) => {
                                        //         const vendors =
                                        //             props.itemVendors[
                                        //                 item.rfq_item_entry_id
                                        //             ];
                                        //         for (let vendor of vendors) {
                                        //             newset.add(vendor.entity_id);
                                        //         }
                                        //     });
                                        //     return newset;
                                        // });
                                    } else {
                                        setSelectedItemIDs((prev) => {
                                            let newset = cloneDeep(prev);
                                            props.items.forEach((item) => {
                                                newset.delete(
                                                    item.rfq_item_entry_id
                                                );
                                            });
                                            return newset;
                                        });
                                        // setSelectedVendorIDs((prev) => {
                                        //     let newset = cloneDeep(prev);
                                        //     props.items.forEach((item) => {
                                        //         const vendors =
                                        //             props.itemVendors[
                                        //                 item.rfq_item_entry_id
                                        //             ];
                                        //         for (let vendor of vendors) {
                                        //             newset.delete(vendor.entity_id);
                                        //         }
                                        //     });
                                        //     return newset;
                                        // });
                                    }
                                }}
                            />
                        }
                        label="Select All"
                    />
                    {props.items
                        .filter((item) => {
                            if (searchTerm.length > 0) {
                                return item.item_information.buyer_item_name
                                    .toLowerCase()
                                    .includes(searchTerm.toLowerCase());
                            } else {
                                return true;
                            }
                        })
                        .map((item) => (
                            <FormControlLabel
                                key={item.rfq_item_entry_id}
                                checked={
                                    Array.from(selectedItemIDs).some(
                                        (id) => id === item.rfq_item_entry_id
                                    ) ||
                                    Array.from(selectedVendorIDs).some((id) =>
                                        props.itemVendors[
                                            item.rfq_item_entry_id
                                        ].some(
                                            (vendor) => vendor.entity_id === id
                                        )
                                    )
                                }
                                control={
                                    <Checkbox
                                        checked={selectedItemIDs.has(
                                            item.rfq_item_entry_id
                                        )}
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                setSelectedItemIDs((prev) => {
                                                    let newset =
                                                        cloneDeep(prev);
                                                    newset.add(
                                                        item.rfq_item_entry_id
                                                    );
                                                    return newset;
                                                });
                                                setSelectedVendorIDs((prev) => {
                                                    let newset =
                                                        cloneDeep(prev);
                                                    const vendors =
                                                        props.itemVendors[
                                                            item
                                                                .rfq_item_entry_id
                                                        ];
                                                    for (let vendor of vendors) {
                                                        newset.add(
                                                            vendor.entity_id
                                                        );
                                                    }
                                                    return newset;
                                                });
                                            } else {
                                                setSelectedItemIDs((prev) => {
                                                    let newset =
                                                        cloneDeep(prev);
                                                    newset.delete(
                                                        item.rfq_item_entry_id
                                                    );
                                                    return newset;
                                                });
                                                // setSelectedVendorIDs((prev) => {
                                                //     let newset =
                                                //         cloneDeep(prev);
                                                //     const vendors =
                                                //         props.itemVendors[
                                                //             item
                                                //                 .rfq_item_entry_id
                                                //         ];
                                                //     for (let vendor of vendors) {
                                                //         newset.delete(
                                                //             vendor.entity_id
                                                //         );
                                                //     }
                                                //     return newset;
                                                // });
                                            }
                                        }}
                                    />
                                }
                                label={
                                    <FWTypography maxWidth={'400px'}>
                                        {item.item_information.buyer_item_name}{' '}
                                        (
                                        {item.rfq_item_entry_id in
                                            props.itemVendors &&
                                            props.itemVendors[
                                                item.rfq_item_entry_id
                                            ]
                                                .map(
                                                    (vendor) =>
                                                        vendor.entity_name
                                                )
                                                .join(', ')}
                                        )
                                    </FWTypography>
                                }
                            />
                        ))}
                </FormGroup>
            </DialogContent>
            <DialogActions sx={{ margin: '12px' }}>
                <Button
                    variant="contained"
                    onClick={() => {
                        handleSubmit();
                        props.onClose();
                    }}
                >
                    Done
                </Button>
            </DialogActions>
        </FWPopupWithChildren>
    );
}
