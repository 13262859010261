import React, { useContext } from 'react';
import VLNRelationshipMenuChips from '../../Components/Relationship/VLNRelationshipMenuChips';
import VLNRelationshipInsightsSection from './VLNRelationshipInsightsSection';
import VLNRelationshipTransactionSection from './VLNRelationshipTransactionSection';
import VLNRelationshipNotesSection from './VLNRelationshipNotesSection';
import ChatContainer from '../../../Chats/Containers/ChatContainer';
import VLNRelationshipInvoiceDashboard from '../../Components/Relationship/VLNRelationshipInvoiceDashboard';
import VLNRelationshipContractsDashboard from '../../Components/Relationship/VLNRelationshipContractsDashboard';
import { VLNContext } from '../../../Events/Components/Buyer/VendorLinkedInPopup';
import { Box } from '@mui/material';

const VendorLNRelationshipMainContainer = ({
    vendor_profile_id,
}: {
    vendor_profile_id: string | null;
}) => {
    const [currentSection, setCurrentSection] = React.useState<
        | 'Insights'
        | 'Transactions'
        | 'Invoices'
        | 'Chat'
        | 'E-mail'
        | 'Contracts'
        | 'Notes'
    >('Insights');

    const { sellerEntityUuid } = useContext(VLNContext);

    return (
        <>
            <VLNRelationshipMenuChips
                currentSection={currentSection}
                setCurrentSection={setCurrentSection}
            />
            <Box
                padding={currentSection !== 'Chat' ? 4 : 0}
                // paddingX={currentSection === 'Chat' ? 2 : undefined}
            >
                {(() => {
                    switch (currentSection) {
                        case 'Insights':
                            return <VLNRelationshipInsightsSection />;
                        case 'Transactions':
                            return (
                                <VLNRelationshipTransactionSection
                                    vendor_profile_id={vendor_profile_id}
                                />
                            );
                        case 'Invoices':
                            return (
                                <VLNRelationshipInvoiceDashboard
                                    sellerEntityUuid={sellerEntityUuid}
                                />
                            );
                        case 'Chat':
                            return (
                                <Box marginX={2.5}>
                                    <ChatContainer
                                        user_type="buyer"
                                        sellerEntityUuid={sellerEntityUuid}
                                    />
                                </Box>
                            );
                        case 'Contracts':
                            return (
                                <VLNRelationshipContractsDashboard
                                    sellerEntityUuid={sellerEntityUuid}
                                />
                            );
                        case 'Notes':
                            return (
                                <VLNRelationshipNotesSection
                                    vendor_profile_id={vendor_profile_id}
                                />
                            );
                    }
                })()}
            </Box>
        </>
    );
};

export default VendorLNRelationshipMainContainer;
