import axios, { AxiosInstance } from 'axios';

export const appConfig = {
    baseUrl: process.env.REACT_APP_API_URL, // getting from from /env/.env.*
};

// baseURL for the server
export let instance: AxiosInstance;
let axiosInterceptor: number;

export const createNewInstance = () => {
    instance = axios.create({
        // baseURL: `${appConfig.baseUrl}/${appConfig.dataPathContext}/`,
        baseURL: appConfig.baseUrl,
    });
};

createNewInstance();

// Add a request interceptor
export const addInterceptor = (
    authData: string,
    instance: AxiosInstance
): void => {
    /*
        authData: a valid JWT string representing the access authData
        instance: an Axios Instance
        Response: void
    */

    axiosInterceptor = instance.interceptors.request.use(
        (config) => {
            if (authData === '') {
                config.headers = {
                    'Content-Type': 'application/json',
                };
            } else {
                config.headers = {
                    Authorization: `Bearer ${authData}`,
                    'Content-Type': 'application/json',
                };
            }
            return config;
        },
        (error) => {
            Promise.reject(error);
        }
    );

    // Add a response interceptor
    axios.interceptors.response.use(
        function (response) {
            // Any status code that lie within the range of 2xx cause this function to trigger
            // Do something with response data
            return response;
        },
        function (error) {
            // Any status codes that falls outside the range of 2xx cause this function to trigger
            // Do something with response error
            if (
                error.response.status === 403 ||
                error.response.status === 401
            ) {
                window.location.href = '/';
            }
            return Promise.reject(error);
        }
    );
};

// Add a request interceptor
export const removeInterceptor = (instance: AxiosInstance | any): void => {
    /*
        instance: an Axios Instance
        Response: void
    */
    instance.interceptors.request.eject(axiosInterceptor);
};
