import {
    Box,
    InputAdornment,
    InputBaseComponentProps,
    InputProps,
    SxProps,
    TextField,
    styled,
} from '@mui/material';
import { isEmpty } from 'lodash';
import { CSSProperties, ReactElement, Ref, useEffect, useState } from 'react';
// import { removeDecimalIfNoDigitAfterIt } from '../Contexts/helperFunctions';
import { FWTooltip } from './FWCustomTooltip';
import { FWTypography } from './FWTypograhy';
import { InputRegEx, addCommaSeparators } from './Utils/ValidationUtils';

enum ColorName {
    default = 'default',
    primary = 'primary',
    success = 'success',
    error = 'error',
    warning = 'warning',
}

enum ColorValue {
    default = 'error.main',
    primary = 'primary.main',
    success = 'success.main',
    error = 'error.main',
    warning = 'warning.main',
    text = 'text.secondary',
}
const BaseInput = styled(TextField)(({ theme }) => ({
    '& .MuiOutlinedInput-root': {
        height: 'auto',
        padding: 0,
    },
    '&.success': {
        '.Mui-focused .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-notchedOutline':
            {
                borderColor: theme.palette.success.main,
                boxShadowColor: theme.palette.success.main,
            },
    },
    '&.warning': {
        '.Mui-focused .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-notchedOutline':
            {
                borderColor: theme.palette.warning.main,
                boxShadowColor: theme.palette.warning.main,
            },
    },
    '&.error': {
        '.Mui-focused .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-notchedOutline':
            {
                borderColor: theme.palette.error.main,
                boxShadowColor: theme.palette.error.main,
            },
    },
    '&.resize-vertical textarea': {
        resize: 'vertical',
    },
    '& .MuiInputBase-input': {
        padding: '8px',
        height: '40px',
        boxSizing: 'border-box',
        '&:disabled': {
            cursor: 'not-allowed',
        },
    },
    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderWidth: '1px',
    },
    '& :not(.Mui-error)': {
        '.MuiInputBase-input': {
            '&:placeholder-shown ~ .MuiOutlinedInput-notchedOutline': {
                borderColor: theme.palette.text.secondary,
            },
            // '&:not(:placeholder-shown) ~ .MuiOutlinedInput-notchedOutline': {
            //     borderColor: theme.palette.error.main,
            // },
        },
    },
    '& .MuiFormHelperText-root': {
        margin: '0px 0 0',
        display: 'flex',
        flexWrap: 'nowrap',
        '& .MuiTypography-root': {
            fontSize: '12px',
        },
    },
    // '&.readonly': {
    //     input: {
    //         cursor: 'context-menu',
    //         '-webkit-text-fill-color': '#000',
    //     },
    // },
}));

const BaseLabel = styled(FWTypography)({
    '&': {
        letterSpacing: '-0.1px',
        label: {
            '&.error': {
                color: ColorValue.error,
            },
        },
    },
});

const Icon = styled('i')({
    '&': {
        fontSize: '16px',
        lineHeight: '16px',
        '&.bi-info-circle': {
            marginLeft: '4px',
        },
        '&.bi-exclamation-circle': {
            marginRight: '4px',
        },
    },
});

interface IFWInputProps {
    autoComplete?: string;
    label?: string;
    onChange?: (event: any) => void;
    onKeyUp?: (event: any) => void;
    onKeyDown?: (event: any) => void;
    value?: string | number;
    id?: string;
    rows?: number;
    onBlur?: (event: any) => any;
    defaultValue?: string | number;
    error?: boolean;
    warning?: boolean;
    name?: string;
    inputProps?: InputBaseComponentProps;
    disabled?: boolean;
    placeholder?: string;
    type?: string;
    className?: string;
    InputProps?: InputProps;
    size?: 'medium' | 'small';
    required?: boolean;
    multiline?: boolean;
    allowOnly?:
        | 'TEXT'
        | 'NUMBER'
        | 'EMAIL'
        | 'DECIMAL_NUMBER'
        | 'TEL'
        | 'PERCENT'
        | 'DISCOUNT';
    tooltip?: {
        text: string;
        color?: 'primary' | 'error' | 'warning';
    };
    helper?: {
        text: string;
        textColor?: 'primary' | 'error' | 'warning' | 'success' | 'text';
        icon?: boolean;
        iconColor?: 'primary' | 'error' | 'warning' | 'success';
    };
    iconStart?: ReactElement | string;
    iconEnd?: ReactElement | string;
    style?: CSSProperties;
    sx?: SxProps;
    onFocus?: any;
    maxRows?: number;
    minRows?: number;
    readOnly?: boolean;
    inputRef?: Ref<any>;
    autoFocus?: boolean;
    color?: 'primary' | 'error' | 'warning' | 'success' | 'info' | 'secondary';
    onClick?: React.MouseEventHandler<HTMLDivElement> | undefined;
    variant?: 'outlined' | 'filled' | 'standard';
    maxNumberOfDecimal?: number;
}

export const FWInput = (props: IFWInputProps): ReactElement => {
    const {
        label,
        onChange,
        onKeyUp,
        onKeyDown,
        value,
        rows,
        id,
        tooltip,
        onBlur,
        defaultValue,
        disabled,
        size,
        className,
        helper,
        error,
        iconStart,
        iconEnd,
        name,
        placeholder,
        multiline,
        type,
        required,
        inputProps,
        allowOnly,
        onFocus,
        style,
        sx,
        InputProps,
        maxRows,
        minRows,
        readOnly = false,
        inputRef,
        autoFocus,
        autoComplete,
        color,
        onClick,
        variant = 'outlined',
        maxNumberOfDecimal,
    }: IFWInputProps = props;

    const UID = btoa(new Date().toTimeString() + label);

    const handleChange = (event: any) => {
        const removedCommaValue = event.target.value.split(',').join('');
        if (typeof onChange !== typeof undefined) {
            switch (allowOnly) {
                case 'TEXT':
                    if (InputRegEx({})[allowOnly].test(event.target.value)) {
                        // setCommaFormattedValue(event.target.value);
                        onChange!(event);
                    }
                    break;
                case 'NUMBER':
                    if (+removedCommaValue <= 999999999) {
                        if (InputRegEx({})[allowOnly].test(removedCommaValue)) {
                            event.target.value = removedCommaValue;
                            // setCommaFormattedValue(event.target.value);
                            onChange!(event);
                        }
                    }
                    break;
                case 'DECIMAL_NUMBER':
                    if (removedCommaValue === '') {
                        // Allow empty input to be just empty, do not default to zero
                        onChange!(event);
                    } else if (+removedCommaValue <= 999999999.99) {
                        if (removedCommaValue[0] !== '.') {
                            if (
                                InputRegEx({
                                    decimalPlaces: maxNumberOfDecimal,
                                })[allowOnly].test(removedCommaValue)
                            ) {
                                let beforeDecimal =
                                    removedCommaValue.split('.')[0];
                                if (+beforeDecimal !== 0) {
                                    event.target.value =
                                        removedCommaValue.replace(/^0+/, '');
                                } else {
                                    if (removedCommaValue.includes('.')) {
                                        event.target.value = removedCommaValue;
                                    } else {
                                        event.target.value = +removedCommaValue;
                                    }
                                }
                                onChange!(event);
                            }
                        }
                    }
                    break;

                case 'PERCENT':
                    if (+removedCommaValue <= 100) {
                        if (removedCommaValue.split('')[0] !== '.')
                            if (
                                InputRegEx({
                                    decimalPlaces: maxNumberOfDecimal,
                                })[allowOnly].test(removedCommaValue)
                            ) {
                                if (
                                    removedCommaValue.length > 1 &&
                                    removedCommaValue[0] === '0' &&
                                    removedCommaValue[1] !== '.'
                                )
                                    event.target.value =
                                        removedCommaValue.replace(/^0+/, '');
                                else event.target.value = removedCommaValue;
                                // setCommaFormattedValue(event.target.value);
                                onChange!(event);
                            }
                    }
                    break;

                case 'DISCOUNT':
                    if (+removedCommaValue < 100) {
                        if (removedCommaValue.split('')[0] !== '.')
                            if (
                                InputRegEx({
                                    decimalPlaces: maxNumberOfDecimal,
                                })[allowOnly].test(removedCommaValue)
                            ) {
                                if (
                                    removedCommaValue.length > 1 &&
                                    removedCommaValue[0] === '0' &&
                                    removedCommaValue[1] !== '.'
                                )
                                    event.target.value =
                                        removedCommaValue.replace(/^0+/, '');
                                else event.target.value = removedCommaValue;
                                // setCommaFormattedValue(event.target.value);
                                onChange!(event);
                            }
                    }
                    break;

                case 'EMAIL':
                    if (InputRegEx({})[allowOnly].test(event.target.value))
                        // setCommaFormattedValue(event.target.value);
                        onChange!(event);
                    break;
                case 'TEL':
                    if (InputRegEx({})[allowOnly].test(event.target.value))
                        // setCommaFormattedValue(event.target.value);
                        onChange!(event);
                    break;
                default:
                    // setCommaFormattedValue(event.target.value);
                    onChange!(event);
                    break;
            }
        }
    };

    const [commaFormattedValue, setCommaFormattedValue] = useState(value);
    const [isFocused, setIsFocused] = useState(false);

    useEffect(() => {
        if (
            value &&
            (allowOnly === 'NUMBER' ||
                allowOnly === 'DECIMAL_NUMBER' ||
                allowOnly === 'PERCENT' ||
                allowOnly === 'DISCOUNT')
        ) {
            setCommaFormattedValue(addCommaSeparators(value.toString()));
        } else {
            setCommaFormattedValue(value);
        }
    }, [allowOnly, value]);

    return (
        <>
            {label && (
                <div className="flex flex--aic label-div">
                    <label
                        htmlFor={id ? id : UID}
                        className={error ? 'error' : ''}
                        style={{ maxWidth: '100%' }}
                    >
                        <BaseLabel
                            color={
                                helper?.text
                                    ? helper?.textColor
                                    : ColorValue.text
                            }
                        >
                            {label}
                        </BaseLabel>
                    </label>
                    &nbsp;
                    {tooltip && !isEmpty(tooltip.text) && (
                        <FWTooltip title={tooltip?.text}>
                            <Box
                                color={
                                    ColorValue[
                                        tooltip.color || ColorName.primary
                                    ]
                                }
                            >
                                <Icon className="bi bi-info-circle" />
                            </Box>
                        </FWTooltip>
                    )}
                </div>
            )}
            <BaseInput
                onClick={onClick}
                autoComplete={autoComplete}
                fullWidth
                multiline={multiline}
                maxRows={maxRows}
                minRows={minRows}
                rows={rows}
                onChange={handleChange}
                inputProps={inputProps}
                onBlur={(e) => {
                    setIsFocused(false);
                    const inpVal = e.target.value;
                    !inpVal && setCommaFormattedValue('');
                    if (
                        allowOnly === 'DECIMAL_NUMBER' ||
                        allowOnly === 'NUMBER' ||
                        allowOnly === 'PERCENT' ||
                        allowOnly === 'DISCOUNT'
                    ) {
                        setCommaFormattedValue(addCommaSeparators(inpVal));
                        if (allowOnly === 'NUMBER' && inpVal)
                            setCommaFormattedValue(
                                addCommaSeparators(
                                    (inpVal as string).split('.')[0]
                                )
                            );
                    }
                    // if (allowOnly === 'DECIMAL_NUMBER') {
                    //     setCommaFormattedValue(
                    //         removeDecimalIfNoDigitAfterIt(e)
                    //     );
                    //     if (removeDecimalIfNoDigitAfterIt(e)) {
                    //         e.target.value = removeDecimalIfNoDigitAfterIt(
                    //             e
                    //         ) as string;
                    //     }
                    //     // if (onChange) onChange(e);
                    // }
                    onBlur && onBlur(e);
                }}
                onFocus={(e) => {
                    setIsFocused(true);
                    setCommaFormattedValue(value);
                    onFocus && onFocus(e);
                }}
                value={
                    allowOnly === 'DECIMAL_NUMBER' ||
                    allowOnly === 'NUMBER' ||
                    allowOnly === 'DISCOUNT' ||
                    allowOnly === 'PERCENT'
                        ? isFocused
                            ? value
                            : value === '0'
                            ? '0'
                            : value && value?.toString()?.length === 0
                            ? ''
                            : commaFormattedValue
                        : value
                }
                onWheel={(e: any) => e.target.blur()}
                variant={variant}
                placeholder={placeholder}
                disabled={disabled}
                id={id ? id : UID}
                name={name}
                type={
                    allowOnly === 'NUMBER' || allowOnly === 'DECIMAL_NUMBER'
                        ? 'text'
                        : allowOnly === 'TEL'
                        ? 'tel'
                        : type
                }
                defaultValue={defaultValue}
                size={size}
                required={required}
                error={error}
                color={color}
                style={style}
                sx={sx}
                className={readOnly ? 'readonly' : ' ' + className}
                inputRef={inputRef}
                autoFocus={autoFocus}
                helperText={
                    typeof helper !== typeof undefined && (
                        <>
                            {helper?.icon && (
                                <FWTypography
                                    variant="caption"
                                    color={
                                        ColorValue[
                                            helper?.iconColor ||
                                                helper?.textColor ||
                                                ColorName.primary
                                        ]
                                    }
                                >
                                    <Icon className="bi bi-exclamation-circle" />
                                </FWTypography>
                            )}
                            <FWTypography
                                variant="caption"
                                color={
                                    ColorValue[
                                        helper?.textColor || ColorName.default
                                    ]
                                }
                            >
                                {helper?.text}
                            </FWTypography>
                        </>
                    )
                }
                InputProps={{
                    startAdornment: (
                        <>
                            {iconStart && (
                                <InputAdornment
                                    position="end"
                                    style={{ paddingLeft: '5px' }}
                                >
                                    {iconStart}
                                </InputAdornment>
                            )}
                        </>
                    ),
                    endAdornment: (
                        <>
                            {iconEnd && (
                                <InputAdornment
                                    position="start"
                                    style={{ paddingRight: '5px' }}
                                >
                                    {iconEnd}
                                </InputAdornment>
                            )}
                        </>
                    ),
                    readOnly,

                    ...InputProps,
                }}
                onKeyUp={onKeyUp}
                onKeyDown={onKeyDown}
            />
        </>
    );
};
