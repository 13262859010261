import { Badge, styled } from '@mui/material';

const CustomDotBadge = styled(Badge)({
    '&.MuiBadge-root': {
        width: 'auto !important',
    },
    '.MuiBadge-anchorOriginTopRightRectangular': {
        top: '0px',
        position: 'relative',
        transform: 'none',
        width: '10px',
        height: '10px',
        borderRadius: '50%',
    },
});

const DotBadge = ({
    color,
}: {
    color: 'error' | 'success' | 'primary' | 'warning';
}) => {
    return (
        <CustomDotBadge
            variant="dot"
            badgeContent={1}
            color={color}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            style={{
                width: '100%',
                lineHeight: 0,
            }}
        ></CustomDotBadge>
    );
};

export default DotBadge;
