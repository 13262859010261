import { cloneDeep } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { HookStateValue, useHookState } from '../../Common/Hooks/StateHook';
import { getInitItemPriceGraphSettings } from '../Constants/Dynamic_ItemPricing';
import {
    DynamicAnalyticsItemPriceData,
    DynamicAnalyticsItemPriceResponseType,
    DynamicAnalyticsUserFilterType,
    DynamicPricingAnalyticsHookDataType,
} from '../Interfaces/DynamicAnalyticsInterfaces.model';
import { IHistoricalAnalyticsChart } from '../Interfaces/IHistoricalAnalytics.model';
import { IHASemiProcessedResponseType } from '../Interfaces/IHistoricalAnalyticsApi.model';
import { fetchGraphData } from '../Services/HAService';
import { getHADataRequestPayload } from '../helperFunctions/PayloadHelper';
import { shallowEqual, useSelector } from 'react-redux';
import { IGlobalAppStore } from '../../Redux/Store';

export const useItemPriceDynamicAnalytics = (
    hookData: DynamicPricingAnalyticsHookDataType,
    clickedFirstTime = false
) => {
    const { hookState, updateHookState } = useHookState(HookStateValue.INITIAL);
    const userFilterLastXOptions = [5, 10];
    const userFilterXDaysOptions = [30, 60, 90, 180, 365];
    const [userFilter, setUserFilter] =
        useState<DynamicAnalyticsUserFilterType>({
            filterType: 'last_x',
            count: 5,
        });

    const [filterCurrencies, setFilterCurrencies] = useState<boolean>(false);

    const updateFilter = useCallback(
        (
            filterType: DynamicAnalyticsUserFilterType['filterType'],
            value?: number
        ) => {
            switch (filterType) {
                case 'all':
                    setUserFilter({
                        filterType: 'all',
                    });
                    break;
                case 'last_x':
                    if (value) {
                        setUserFilter({
                            filterType: 'last_x',
                            count: value,
                        });
                    }
                    break;
                case 'x_days':
                    if (value) {
                        setUserFilter({
                            filterType: 'x_days',
                            days: value,
                        });
                    }
                    break;
            }
        },
        []
    );

    const [itemPricingData, setItemPricingData] = useState<
        DynamicAnalyticsItemPriceData[]
    >([]);

    const [visibleData, setVisibleData] = useState<
        DynamicAnalyticsItemPriceData[]
    >([]);

    const [curGraphSettings, setCurGraphSettings] =
        useState<IHistoricalAnalyticsChart | null>(null);

    const processResponse = useCallback(
        (
            responseDataList: IHASemiProcessedResponseType[][]
        ): DynamicAnalyticsItemPriceData[] => {
            const processedData: DynamicAnalyticsItemPriceData[] = [];
            for (let i = 0; i < responseDataList.length; i++) {
                const curMetric = i === 0 ? 'quantity' : 'effectiveRate';
                for (let rawDataPoint of responseDataList[i]) {
                    const typedDataPoint =
                        rawDataPoint as any as DynamicAnalyticsItemPriceResponseType;
                    let matchingRes = processedData.find(
                        (r) =>
                            r.poDetails.poItemUuid ===
                            typedDataPoint.group_details
                                .po_item__purchase_order_item_id
                    );
                    //

                    if (!matchingRes) {
                        const curData: DynamicAnalyticsItemPriceData = {
                            poDetails: {
                                poItemUuid:
                                    typedDataPoint.group_details
                                        .po_item__purchase_order_item_id,
                                customPoId:
                                    typedDataPoint.group_details
                                        .po__custom_purchase_order_id,
                                poIssueDate:
                                    typedDataPoint.group_details
                                        .po__submission_datetime__date,
                                vendorName:
                                    typedDataPoint.group_details
                                        .seller_entity__entity_name,
                                currencyId:
                                    typedDataPoint.group_details
                                        .currency_code__entry_id,
                            },
                            effectiveRate: null,
                            quantity: null,
                        };
                        processedData.push(curData);
                        matchingRes = curData;
                    }

                    matchingRes[curMetric] = typedDataPoint.metric_value;
                }
            }
            processedData.sort((a, b) =>
                b.poDetails.poIssueDate.localeCompare(a.poDetails.poIssueDate)
            );
            return processedData;
        },
        []
    );

    const fetchData = useCallback(
        async (settings: IHistoricalAnalyticsChart) => {
            updateHookState(HookStateValue.LOADING);
            try {
                // NOTE: ADDED THIS TO USE DYNAMIC ANALYTICS AGAIN (EVERYWHERE)
                const payloadList = settings.metrics.map((curMetric) =>
                    getHADataRequestPayload({
                        metricSettings: curMetric,
                        groupingOptions: {
                            groupBy: settings.groupBy,
                            groupFilter: settings.groupFilter,
                            groupSort: settings.groupSort,
                        },
                        filterOptions: settings.filterContoller,
                        currencySettings: settings.currencySettings,
                    })
                );
                const promiseList = payloadList.map((curPayload) =>
                    fetchGraphData(curPayload)
                );
                const responseDataList = await Promise.all(promiseList);
                const processedData = processResponse(responseDataList);
                setItemPricingData(processedData);
                updateHookState(HookStateValue.READY);
            } catch (e) {
                updateHookState(HookStateValue.ERROR);
            }
        },
        [processResponse, updateHookState]
    );
    const arePoAvailable = useSelector(
        (store: IGlobalAppStore) => store.EventCreationStore.arePoAvailable,
        shallowEqual
    );
    const isRequisitionAvailable = useSelector(
        (store: IGlobalAppStore) =>
            store.RequisitionStore.requisitionName !== ''
    );
    // const showWarnings = useSelector(
    //     (store: IGlobalAppStore) =>
    //         store.EventCreationStore.eventDetails?.showWarnings ?? true,
    //     shallowEqual
    // );
    //Fetches fresh data when graph settings change. Should only happen once

    useEffect(() => {
        if (isRequisitionAvailable) {
            if (curGraphSettings && clickedFirstTime) {
                fetchData(curGraphSettings);
            }
        } else {
            if (curGraphSettings && arePoAvailable && clickedFirstTime) {
                fetchData(curGraphSettings);
            }
        }
    }, [
        fetchData,
        curGraphSettings,
        arePoAvailable,
        clickedFirstTime,
        isRequisitionAvailable,
    ]);

    //Initializes graph settings. Runs if props change (should never happen)
    useEffect(() => {
        setCurGraphSettings(
            getInitItemPriceGraphSettings({
                currencyDetails: {
                    currencyCodeAbbreviation:
                        hookData.currencyDetails.currencyCodeAbbreviation,
                    currencyUuid: hookData.currencyDetails.currencyUuid,
                    currencyName: hookData.currencyDetails.currencyName,
                    currencySymbol: hookData.currencyDetails.currencySymbol,
                },
                enterpriseItemUuid: hookData.enterpriseItemUuid,
                measurementUnitUuid: hookData.measurementUnitUuid,
            })
        );
    }, [
        hookData.currencyDetails.currencyCodeAbbreviation,
        hookData.currencyDetails.currencyName,
        hookData.currencyDetails.currencySymbol,
        hookData.currencyDetails.currencyUuid,
        hookData.enterpriseItemUuid,
        hookData.measurementUnitUuid,
    ]);

    //Calculates visible data using all data + user filter
    const getVisibleData = useCallback(() => {
        if (itemPricingData.length === 0) {
            return [];
        }
        let filteredData = cloneDeep(itemPricingData);

        if (filterCurrencies) {
            filteredData = filteredData.filter(
                (curData) =>
                    curData.poDetails.currencyId ===
                    hookData.currencyDetails.currencyUuid
            );
        }
        switch (userFilter.filterType) {
            case 'last_x':
                return filteredData.slice(0, userFilter.count);
            case 'x_days':
                const curDate = new Date();
                const curDateStr = curDate.toISOString().split('T')[0];
                const curDateObj = new Date(curDateStr);
                const startDate = new Date(
                    curDateObj.setDate(curDateObj.getDate() - userFilter.days)
                );
                return filteredData.filter(
                    (curData) =>
                        new Date(curData.poDetails.poIssueDate) >= startDate
                );
            case 'all':
                return filteredData;
        }
    }, [
        itemPricingData,
        userFilter,
        filterCurrencies,
        hookData.currencyDetails.currencyUuid,
    ]);

    const toggleCurrencyFilter = useCallback(() => {
        setFilterCurrencies((prev) => !prev);
    }, []);

    //Updates visible data whenever user filter changes or data changes
    useEffect(() => {
        setVisibleData(getVisibleData());
    }, [getVisibleData, userFilter, itemPricingData, filterCurrencies]);

    return {
        hookState,
        itemPricingData,
        visibleData,
        userFilter,
        updateFilter,
        userFilterLastXOptions,
        userFilterXDaysOptions,
        toggleCurrencyFilter,
        filterCurrencies,
    };
};
