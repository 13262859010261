import { IHistoricalAnalyticsChart } from '../Interfaces/IHistoricalAnalytics.model';
import {
    getEventStartDateFilter,
    getItemFilterCategory,
    getPoIssueDateFilter,
    getUserFilterCategory,
    getVendorFilterCategory,
} from './CommonFilters';
import {
    getStandardInvoiceItemColumns,
    getStandardPoColumns,
    getStandardPoItemColumns,
    getStandardSellerEventAccessColumns,
} from './DownloadColumns';

export const HA_Vendors_TotalSpend: IHistoricalAnalyticsChart = {
    chartId: 'HA_Vendors_TotalSpend',
    title: 'Vendor Total Spend',
    downloadSettings: {
        object: 'po',
        columns: getStandardPoColumns(),
    },
    chartType: 'pie',
    currencySettings: {
        showTargetCurrency: true,
        targetCurrency: null,
        filterCurrenciesIds: [],
    },
    filterContoller: {
        fixedFilters: [
            {
                filterId: '',
                filterType: 'notNull',
                filterLabel: '',
                filterFields: {
                    metricFieldType: 'universal',
                    fieldName: 'po__purchase_order_id',
                },
                filterApplied: true,
            },
            {
                filterId: '',
                filterType: 'multiselectDropdown',
                filterLabel: '',
                filterFields: {
                    metricFieldType: 'universal',
                    fieldName: 'po__status',
                },
                filterApplied: true,
                modifierType: 'CONSOLIDATED',
                modifierValue: 'IN',
                values: ['ISSUED', 'ONGOING', 'TERMINATED', 'COMPLETED'],
            },
            {
                filterId: '',
                filterType: 'multiselectDropdown',
                filterLabel: '',
                filterFields: {
                    metricFieldType: 'universal',
                    fieldName: 'po__purchase_order_type',
                },
                filterApplied: true,
                modifierType: 'CONSOLIDATED',
                modifierValue: 'NOT_IN',
                values: ['STAGING'],
            },
        ],
        customizableFilters: {
            categoryConnector: 'AND',
            categories: [
                {
                    categoryLabel: 'Date Range',
                    filterConnector: 'AND',
                    filters: [getPoIssueDateFilter({})],
                    expanded: true,
                },
                getItemFilterCategory(),
                getVendorFilterCategory(),
                getUserFilterCategory({
                    event__original_event_creator: 'APPLICABLE',
                    po__created_by_user: 'DEFAULT',
                    po__final_buyer_approval: 'APPLICABLE',
                    rfq_event__final_approver: 'APPLICABLE',
                }),
            ],
        },
    },
    metrics: [
        {
            metricId: 'poValue',
            displayModifier: 'currency',
            metricLegendLabel: 'PO Value',
            metricDefinition: {
                metricType: 'base',
                metricField: 'po__pricing_information__total',
                aggregation: 'sum',
            },
        },
    ],
    groupBy: [
        {
            type: 'str',
            field: 'seller_entity',
            includeInGroupName: false,
        },
        {
            type: 'str',
            field: 'seller_entity__entity_name',
            includeInGroupName: true,
        },
    ],
    groupSort: {
        sortBy: 'value',
        sortOrder: 'desc',
    },
    groupFilter: {
        filterBy: 'top',
        filterNumber: 10,
        customFilter: null,
    },
};

export const HA_Vendors_Quality: IHistoricalAnalyticsChart = {
    chartId: 'HA_Vendors_Quality',
    title: 'Goods Quality',
    downloadSettings: {
        object: 'invoice_item',
        columns: getStandardInvoiceItemColumns(),
    },
    chartType: 'bar',
    axisLabelY: '% Acceptance By Value',
    axisLabelX: 'Vendor',
    currencySettings: {
        showTargetCurrency: false,
        targetCurrency: null,
        filterCurrenciesIds: [],
    },
    filterContoller: {
        fixedFilters: [
            {
                filterId: '',
                filterType: 'notNull',
                filterLabel: '',
                filterFields: {
                    metricFieldType: 'universal',
                    fieldName: 'invoice_item__invoice_item_id',
                },
                filterApplied: true,
            },
            {
                filterId: '',
                filterType: 'notNull',
                filterLabel: '',
                filterFields: {
                    metricFieldType: 'universal',
                    /*
                    Keeping atleast 1 gr exists as condition to make it consistent
                    May have to change in future
                     */
                    // fieldName: 'batch_1_qc_1__quality_check_id',
                    fieldName: 'batch_1_gr__goods_receipt_id',
                },
                filterApplied: true,
            },
        ],
        customizableFilters: {
            categoryConnector: 'AND',
            categories: [
                {
                    categoryLabel: 'Date Range',
                    filterConnector: 'AND',
                    filters: [getPoIssueDateFilter({})],
                    expanded: true,
                },
                getItemFilterCategory(),
                getVendorFilterCategory(),
                getUserFilterCategory({
                    event__original_event_creator: 'APPLICABLE',
                    po__created_by_user: 'DEFAULT',
                    po__final_buyer_approval: 'APPLICABLE',
                    rfq_event__final_approver: 'APPLICABLE',
                }),
            ],
        },
    },
    metrics: [
        {
            metricId: 'acceptanceValuePercentage',
            displayModifier: 'percentage',
            display: 'bar',
            metricLegendLabel: 'Acceptance %',
            metricDefinition: {
                metricType: 'percentage',
                numerator: {
                    metricType: 'base',
                    metricField:
                        'invoice_item__pricing_information__total_amount - invoice_item__pricing_information__rejection_amount',
                    aggregation: 'sum',
                },
                denominator: {
                    metricType: 'base',
                    metricField:
                        'invoice_item__pricing_information__total_amount',
                    aggregation: 'sum',
                },
            },
        },
    ],
    groupBy: [
        {
            type: 'str',
            field: 'seller_entity__entity_id',
            includeInGroupName: false,
        },
        {
            type: 'str',
            field: 'seller_entity__entity_name',
            includeInGroupName: true,
        },
    ],
    groupSort: {
        sortBy: 'value',
        sortOrder: 'desc',
    },
    groupFilter: {
        filterBy: 'none',
        filterNumber: null,
        customFilter: null,
    },
};

export const HA_Vendors_ResponseRate: IHistoricalAnalyticsChart = {
    chartId: 'HA_Vendors_ResponseRate',
    title: 'Vendor Responses',
    downloadSettings: {
        object: 'seller_event_access',
        columns: getStandardSellerEventAccessColumns(),
    },
    chartType: 'bar',
    axisLabelY: 'RFQ count',
    axisLabelY2: 'Events won',
    axisLabelX: 'Vendor',
    currencySettings: {
        showTargetCurrency: false,
        targetCurrency: null,
        filterCurrenciesIds: [],
    },
    filterContoller: {
        fixedFilters: [
            {
                filterId: 'eventTypeRfq',
                filterType: 'multiselectDropdown',
                filterLabel: '',
                filterFields: {
                    metricFieldType: 'universal',
                    fieldName: 'event__event_type',
                },
                filterApplied: true,
                modifierType: 'CONSOLIDATED',
                modifierValue: 'IN',
                values: ['RFQ'],
            },
            {
                filterId: 'eventResponded',
                filterType: 'multiselectDropdown',
                filterLabel: '',
                filterFields: {
                    metricFieldType: 'metricSpecific',
                    metricFields: {
                        respondedEventCount: 'seller_event_access__status',
                    },
                },
                filterApplied: true,
                modifierType: 'CONSOLIDATED',
                modifierValue: 'IN',
                values: [
                    'BID_SUBMITTED',
                    'BID_RECEIVED',
                    'PO_PENDING',
                    'PO_REVIEWED',
                    'DEAL_LOST',
                ],
            },
            {
                filterId: 'eventNotResponded',
                filterType: 'multiselectDropdown',
                filterLabel: '',
                filterFields: {
                    metricFieldType: 'metricSpecific',
                    metricFields: {
                        notRespondedEventCount: 'seller_event_access__status',
                    },
                },
                filterApplied: true,
                modifierType: 'CONSOLIDATED',
                modifierValue: 'NOT_IN',
                values: [
                    'BID_SUBMITTED',
                    'BID_RECEIVED',
                    'PO_PENDING',
                    'PO_RECEIVED',
                    'PO_REVIEWED',
                    'DEAL_WON',
                    'DEAL_LOST',
                ],
            },
            {
                filterId: 'eventWon',
                filterType: 'multiselectDropdown',
                filterLabel: '',
                filterFields: {
                    metricFieldType: 'metricSpecific',
                    metricFields: {
                        winningEventCount: 'seller_event_access__status',
                    },
                },
                filterApplied: true,
                modifierType: 'CONSOLIDATED',
                modifierValue: 'IN',
                values: ['PO_RECEIVED', 'DEAL_WON'],
            },
        ],
        customizableFilters: {
            categoryConnector: 'AND',
            categories: [
                {
                    categoryLabel: 'Date Range',
                    filterConnector: 'AND',
                    filters: [getEventStartDateFilter({})],
                    expanded: true,
                },
                getVendorFilterCategory(),
                getUserFilterCategory({
                    event__original_event_creator: 'APPLICABLE',
                    rfq_event__final_approver: 'APPLICABLE',
                }),
            ],
        },
    },
    metrics: [
        {
            metricId: 'winningEventCount',
            displayModifier: 'count',
            display: 'bar',
            metricLegendLabel: 'Won',
            metricDefinition: {
                metricType: 'base',
                metricField: 'event__event_id',
                aggregation: 'count',
            },
        },
        {
            metricId: 'respondedEventCount',
            displayModifier: 'count',
            display: 'bar',
            metricLegendLabel: 'Responded',
            metricDefinition: {
                metricType: 'base',
                metricField: 'event__event_id',
                aggregation: 'count',
            },
        },
        {
            metricId: 'notRespondedEventCount',
            displayModifier: 'count',
            display: 'bar',
            metricLegendLabel: 'Not responded',
            metricDefinition: {
                metricType: 'base',
                metricField: 'event__event_id',
                aggregation: 'count',
            },
        },
    ],
    groupBy: [
        {
            type: 'str',
            field: 'seller_entity__entity_id',
            includeInGroupName: false,
        },
        {
            type: 'str',
            field: 'seller_entity__entity_name',
            includeInGroupName: true,
        },
    ],
    groupSort: {
        sortBy: 'value',
        sortOrder: 'desc',
    },
    groupFilter: {
        filterBy: 'none',
        filterNumber: null,
        customFilter: null,
    },
};

//PO Value by vendor tags
export const HA_Vendors_PoValueByTag: IHistoricalAnalyticsChart = {
    chartId: 'HA_Vendors_PoValueByTag',
    title: 'Vendor Categories',
    downloadSettings: {
        object: 'po_item',
        columns: getStandardPoItemColumns(),
    },
    chartType: 'bar',
    axisLabelY: 'PO Value',
    axisLabelX: 'Vendor Tag',
    currencySettings: {
        showTargetCurrency: true,
        targetCurrency: null,
        filterCurrenciesIds: [],
    },
    filterContoller: {
        fixedFilters: [
            {
                filterId: '',
                filterType: 'notNull',
                filterLabel: '',
                filterFields: {
                    metricFieldType: 'universal',
                    fieldName: 'po__purchase_order_id',
                },
                filterApplied: true,
            },
            {
                filterId: '',
                filterType: 'multiselectDropdown',
                filterLabel: '',
                filterFields: {
                    metricFieldType: 'universal',
                    fieldName: 'po__status',
                },
                filterApplied: true,
                modifierType: 'CONSOLIDATED',
                modifierValue: 'IN',
                values: ['ISSUED', 'ONGOING', 'TERMINATED', 'COMPLETED'],
            },
            {
                filterId: '',
                filterType: 'multiselectDropdown',
                filterLabel: '',
                filterFields: {
                    metricFieldType: 'universal',
                    fieldName: 'po__purchase_order_type',
                },
                filterApplied: true,
                modifierType: 'CONSOLIDATED',
                modifierValue: 'NOT_IN',
                values: ['STAGING'],
            },
        ],
        customizableFilters: {
            categoryConnector: 'AND',
            categories: [
                {
                    categoryLabel: 'Date Range',
                    filterConnector: 'AND',
                    filters: [getPoIssueDateFilter({})],
                    expanded: true,
                },
                getItemFilterCategory(),
                getVendorFilterCategory(),
                getUserFilterCategory({
                    event__original_event_creator: 'APPLICABLE',
                    po__created_by_user: 'DEFAULT',
                    po__final_buyer_approval: 'APPLICABLE',
                    rfq_event__final_approver: 'APPLICABLE',
                }),
            ],
        },
    },
    metrics: [
        {
            display: 'bar',
            metricId: 'poValue',
            displayModifier: 'currency',
            metricLegendLabel: 'PO Value',
            metricDefinition: {
                metricType: 'base',
                metricField: 'po__pricing_information__total',
                aggregation: 'sum',
            },
        },
    ],
    groupBy: [
        {
            type: 'str',
            field: 'enterprise_vendor_master__tags',
            includeInGroupName: true,
        },
    ],
    groupSort: {
        sortBy: 'value',
        sortOrder: 'desc',
    },
    groupFilter: {
        filterBy: 'top',
        filterNumber: 10,
        customFilter: null,
    },
};
