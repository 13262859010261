import { IAdditionalCostsBackend } from '../../../AdditionalCost/models/AdditionalCost.model';
import { IAdditionalCostStructureForQuote } from '../../../Costing/Services/CostingApisCalls.service';
import { ICustomAttributeNew } from '../../../Models/Attributes.model';
import { IAttachment } from '../../../Models/RFQ.model';
import { IItemTemplateCustomField } from '../Helpers/ItemHelpers';
import { CustomTemplateItemTypes } from './AdminSettingsInterface';
import { IEntity } from './EnterpriseInterface';

export enum ItemStatus {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
    ALL = 'ALL',
}

export enum PreferredVendorAssignedStatus {
    ASSIGNED = 'ASSIGNED',
    UNASSIGNED = 'UNASSIGNED',
    PARTIAL = 'PARTIAL',
}

export interface IItemSummary {
    index?: number;
    id: string;
    itemCode: string;
    name: string;
    entities: string[];
    entities_id: { buyer_entity_name: string; buyer_entity_id: string }[];
    locked: boolean;
    lastPrice: string;
    description: string;
    status: ItemStatus;
    specifications: ICustomAttributeNew[];
    measurementUnits: string[];
    notes: string;
    tags: string[];
    customIds: IItemCustomId[];
    preferredVendorAssignedStatus: PreferredVendorAssignedStatus;
    preferredVendorsCount: number;
    preferredVendors: {
        seller_entity_id: string;
        seller_entity_name: string;
    }[];
    entityStatus: ItemEntityStatus;
    modifiedDateTime: string;
    item_type?: 'FINISHED_GOOD' | 'RAW_MATERIAL';
    showMore?: boolean;
    hasNext?: boolean;
    isBuyer?: boolean;
    isSeller?: boolean;
    buyerPricingInformation?: {
        currency_code_abbreviation: string | null;
        currency_code_id: string | null;
        currency_name: string | null;
        currency_symbol: string | null;
        price: string | null;
    } | null;
    sellerPricingInformation?: {
        currency_code_abbreviation: string | null;
        currency_code_id: string | null;
        currency_name: string | null;
        currency_symbol: string | null;
        price: string | null;
    } | null;
    bom_template: {
        template_id: string;
    } | null;
}

export interface IItemStandardTermsOptions {
    value: string | null;
    option: string | null;
}

export interface IItemStandardTerms {
    lead: IItemStandardTermsOptions | null;
    payment: IItemStandardTermsOptions | null;
    prePayment: IItemStandardTermsOptions | null;
}

export interface IItemSpecification {
    name: string;
    value: string[];
}

export interface ISKUVendorSpecification {
    vendor_name: string;
    vendor_sku_id: string[];
}

export interface IItemCustomId {
    name: string;
    value: string;
}
export interface IItemCustomFieldsForItemTemplate {}

export interface IItemCustomFieldTemplate {
    name: string;
    value: string;
    type: string;
}

export interface IItemCustomField {
    name: string;
    description: string;
    type: CustomTemplateItemTypes;
    value: string | null | string[] | boolean;
    is_required: boolean;
    is_locked: boolean;
}

export interface IItemCustomFieldsBackend {
    section_list: {
        name: string;
        fields: IItemTemplateCustomField[];
    }[];
}
export interface IItemDetails {
    template_id?: string;
    bom_template: {
        template_id: string;
    } | null;
    id: string;
    itemCode: string;
    itemName: string;
    description: string;
    notes: string | null;
    internal_notes: string | null;
    measurementUnits: IMeasurement[];
    rss_feed_links: string[];
    secondary_indices: string[];
    linked_currencies: string[];
    primary_index: string | null;
    image: IAttachment[];
    isBuyer: boolean;
    isSeller: boolean;
    buyerPricingInformation: {
        price: number | null;
        currencyCodeId: string | null;
        currency_code_abbreviation?: string | null;
        currency_name?: string | null;
        currency_symbol?: string | null;
        additionalCost?: IAdditionalCostsBackend[];
    };
    sellerPricingInformation: {
        price: number | null;
        currencyCodeId: string | null;
        currency_code_abbreviation?: string | null;
        currency_name?: string | null;
        currency_symbol?: string | null;
        additionalCost?: IAdditionalCostsBackend[];
    };
    currency_code_id?: string | null; //for payload
    standardTerms: IItemStandardTerms;
    specifications?: IItemSpecification[];
    attributes?: ICustomAttributeNew[];
    custom_fields: IItemCustomFieldsBackend;
    customIds: IItemCustomId[];
    status: ItemStatus;
    taxes?: IAdditionalCostsBackend[];
    additionalCost: IAdditionalCostsBackend[];
    tags: string[];
    item_type: 'FINISHED_GOOD' | 'RAW_MATERIAL';
}

export interface IItemDetailsForQuote {
    template_id?: string;
    id: string;
    itemCode: string;
    itemName: string;
    description: string;
    notes: string | null;
    internal_notes: string | null;
    measurementUnits: IMeasurement[];
    isBuyer: boolean;
    isSeller: boolean;
    buyerPricingInformation: {
        price: number | null;
        currencyCodeId: string | null;
        currency_code_abbreviation?: string | null;
        currency_name?: string | null;
        currency_symbol?: string | null;
        additionalCost?: IAdditionalCostStructureForQuote[];
    };
    sellerPricingInformation: {
        price: number | null;
        currencyCodeId: string | null;
        currency_code_abbreviation?: string | null;
        currency_name?: string | null;
        currency_symbol?: string | null;
        additionalCost?: IAdditionalCostStructureForQuote[];
    };
    currency_code_id?: string | null; //for payload
    standardTerms: IItemStandardTerms;
    specifications: IItemSpecification[];
    custom_fields: IItemCustomFieldsBackend;
    customIds: IItemCustomId[];
    status: ItemStatus;
    taxes?: IAdditionalCostStructureForQuote[];
    additionalCost: IAdditionalCostStructureForQuote[];
    tags: string[];
    item_type?: 'FINISHED_GOOD' | 'RAW_MATERIAL';
}

export interface IMeasurement {
    id: string;
    name: string;
    category: string;
    type: string;
    isVerifiedUnit: boolean;
    abbreviation: string;
}

export interface IItemMeasurementUnits {
    [key: string]: IMeasurement;
}

export interface IMeasurementUnitSynonym {
    synonymName: string;
    primaryName: string;
    measurementUnitUuid: string;
}

export enum ItemEntityStatus {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
}
export interface IItemEntityDetail extends IEntity {
    preferredVendors: number;
    blockedVendors: number;
    status: ItemEntityStatus;
    linked: boolean;
}

export enum PreferredVendorStatus {
    STANDARD = 'STANDARD',
    PREFERRED = 'PREFERRED',
}

export interface IPreferredVendor {
    vendorName: string;
    vendorCode: string;
    vendorUid: string;
    vendorDirUid: string;
    primaryContact: {
        fullName: string;
        email: string;
    };
    preferredVendorStatus: PreferredVendorStatus;
}
