import moment from 'moment';
import {
    DeliveryScheduleConfirmationStatus,
    IDeliveryScheduleInformation,
    IDeliveryScheduleItem,
    INewDeliveryScheduleInformation,
    IPurchaseOrder,
} from '../Interfaces/PO.model';

export const blankDsInfo: IDeliveryScheduleInformation = {
    delivery_schedule_id: '',
    status: 'ACTIVE',
    confirmation_status:
        DeliveryScheduleConfirmationStatus.DS_SELLER_CONFIRMATION_PENDING,
    delivery_schedule_items: [],
    seller_notes: null,
};

export const blankBuyerNewDsInfo: INewDeliveryScheduleInformation = {
    confirmation_status:
        DeliveryScheduleConfirmationStatus.DS_BUYER_CONFIRMATION_PENDING,
    delivery_schedule_id: '',
    status: 'ACTIVE',
    delivery_schedule_items: [],
    seller_notes: null,
};

export const sortedDeliverySchedule = (dsItems: IDeliveryScheduleItem[]) => {
    let nonTbdItemsSorted: IDeliveryScheduleItem[] = dsItems
        .filter((row) => row.delivery_date !== null && row.delivery_date !== '')
        .sort((x, y) => x.delivery_date!.localeCompare(y.delivery_date!));
    let tbdItems: IDeliveryScheduleItem[] = dsItems
        .filter((row) => row.delivery_date === null || row.delivery_date === '')
        .sort((x, y) => parseFloat(x.quantity) - parseFloat(y.quantity));
    return nonTbdItemsSorted.concat(tbdItems);
};

export const deliverySchedulesAreSame = (
    dsItems1: IDeliveryScheduleItem[],
    dsItems2: IDeliveryScheduleItem[]
) => {
    let sortedDs1 = sortedDeliverySchedule(dsItems1);
    let sortedDs2 = sortedDeliverySchedule(dsItems2);
    if (sortedDs1.length !== sortedDs2.length) {
        return false;
    }
    for (let i = 0; i < sortedDs1.length; i++) {
        if (
            (sortedDs1[i].delivery_date === null &&
                sortedDs2[i].delivery_date !== null) ||
            (sortedDs1[i].delivery_date !== null &&
                sortedDs2[i].delivery_date === null) ||
            (sortedDs1[i].delivery_date !== null &&
                sortedDs2[i].delivery_date !== null &&
                !moment(sortedDs1[i].delivery_date).isSame(
                    sortedDs2[i].delivery_date
                ))
        ) {
            return false;
        }
        if (
            isNaN(parseFloat(sortedDs1[i].quantity)) ||
            isNaN(parseFloat(sortedDs2[i].quantity))
        ) {
            return false;
        }
        if (
            parseFloat(sortedDs1[i].quantity) !==
            parseFloat(sortedDs2[i].quantity)
        ) {
            return false;
        }
    }
    return true;
};

export const approvalPendingPoHasValidDeliverySchedule = (
    purchaseOrderData: IPurchaseOrder
) => {
    for (let someItem of purchaseOrderData.purchase_order_items) {
        for (let someDelivery of someItem.delivery_schedule_information
            .delivery_schedule_items) {
            if (someDelivery.delivery_date !== null) {
                if (moment(someDelivery.delivery_date).isBefore(moment())) {
                    return false;
                }
            }
        }
    }
    return true;
};
