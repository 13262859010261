import { Box, Chip, styled, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import { FWTypography } from '../../Common/FWTypograhy';
import { downloadAllChatAttachments } from '../../Common/Utils/chatUtils';
import { getFWTime } from '../../Common/Utils/DateUtils';
import { ChatTypes, ISessionMessage } from '../Interfaces/Chat.model';
import ChatDocument from './ChatDocument';
import { useLocation } from 'react-router-dom';

interface IChatMessageRows {
    message_details: ISessionMessage;
    containerSelector: string;
    index: number;
    boxPosition: 'LEFT' | 'RIGHT';
    showSenderName: boolean;
    reduceMargins: boolean;
    openedFromPopup?: boolean;
    floatingChat?: boolean;
    lastReadMsgId: string;
    lastMsgCount: number;
    newMessageRef: React.RefObject<HTMLDivElement>;
}

export default function ChatMessageRows(props: IChatMessageRows) {
    // const [loaded, elRef] = useLazyLoadList(props.containerSelector);
    const StyledBox = styled(Box)(({ theme }) => ({
        // bgcolor="background.default"
        // key={props.index}
        padding: '0.3rem',
        maxWidth: props.floatingChat
            ? '250px'
            : props.openedFromPopup
            ? '300px'
            : '500px',
        minWidth: '200px',
        width: 'max-content',
    }));

    const location = useLocation();
    const currentPortalString = location.pathname.split('/')[1] ?? '';

    return (
        <>
            <StyledBox
                sx={{
                    '&::selection': {
                        color: 'red',
                        background: 'yellow',
                    },
                }}
                bgcolor={
                    props.boxPosition === 'LEFT'
                        ? 'background.default'
                        : '#3495ff'
                }
                // ref={elRef}
                margin={`${props.reduceMargins ? '0.25rem' : '1rem'} 1rem 0 ${
                    props.boxPosition === 'LEFT' ? '1rem' : 'auto'
                }
                `}
                borderRadius={`0.75rem 0.75rem ${
                    props.boxPosition === 'RIGHT' ? '0rem' : '0.75rem'
                } ${props.boxPosition === 'LEFT' ? '0rem' : '0.75rem'}`}
            >
                {/* {loaded ? ( */}
                <Box>
                    {props.showSenderName && (
                        <FWTypography
                            fontWeight={500}
                            marginLeft={0.5}
                            fontSize={
                                props.openedFromPopup || props.floatingChat
                                    ? '14px'
                                    : ''
                            }
                        >
                            {props.message_details.sender_name}
                        </FWTypography>
                    )}
                    {props.message_details.attachments?.length > 0 &&
                        props.message_details.attachments.map(
                            ({ file_name, attachment_id }, idx) => (
                                <ChatDocument
                                    key={idx}
                                    attachment_id={attachment_id}
                                    boxPosition={props.boxPosition}
                                    file_name={file_name}
                                />
                            )
                        )}
                    {props.message_details.attachments?.length > 1 && (
                        <Typography
                            fontSize={'0.75rem'}
                            color={
                                props.boxPosition === 'LEFT' ? 'unset' : 'white'
                            }
                            sx={{
                                cursor: 'pointer',
                                '&:hover': {
                                    textDecoration: 'underline',
                                },
                                display: 'flex',
                                justifyContent: 'flex-end',
                            }}
                            textAlign={'right'}
                            marginRight={1}
                            onClick={() =>
                                downloadAllChatAttachments(
                                    props.message_details.attachments
                                )
                            }
                        >
                            Download all
                            <span style={{ marginLeft: 5, scale: '0.9' }}>
                                <i className="bi bi-arrow-down-circle"></i>
                            </span>
                        </Typography>
                    )}
                    <Typography
                        // margin={'0.25rem 0'}
                        margin={0.5}
                        sx={{
                            wordBreak: 'break-word',
                            color:
                                props.boxPosition === 'LEFT'
                                    ? 'unset'
                                    : 'white',
                            fontSize:
                                props.openedFromPopup || props.floatingChat
                                    ? '14px'
                                    : '',
                        }}
                    >
                        {props.message_details.message}
                    </Typography>
                    <Box
                        width="100%"
                        className="flex flex--aic flex--jcfe"
                        color="text.secondary"
                        fontSize={'0.75rem'}
                    >
                        {currentPortalString !== 'seller' &&
                            props.message_details.message_type ===
                                ChatTypes.BROADCAST && (
                                <>
                                    <Box color="text.secondary">
                                        <i
                                            className="bi bi-megaphone"
                                            style={{
                                                color:
                                                    props.boxPosition === 'LEFT'
                                                        ? 'unset'
                                                        : 'white',
                                                opacity: '0.7',
                                            }}
                                        />
                                    </Box>
                                    &nbsp;
                                </>
                            )}
                        <FWTypography
                            fontSize={'inherit'}
                            style={{
                                color:
                                    props.boxPosition === 'LEFT'
                                        ? 'unset'
                                        : 'white',
                                opacity: '0.7',
                            }}
                        >
                            {getFWTime(props.message_details.created_datetime)}
                        </FWTypography>
                    </Box>
                </Box>
                {/* ) : (
                    <Box className="flex flex--jcsb" padding="5px">
                        <Box>
                            <Box
                                bgcolor={'background.paper'}
                                width="250px"
                                height="50px"
                            />
                        </Box>
                        <Box></Box>
                    </Box>
                )} */}
            </StyledBox>
            {
                /* !(
                process.env.REACT_APP_ENV === 'production' ||
                process.env.REACT_APP_ENV === 'newdbtest1'
            ) && */
                props.message_details.message_id === props.lastReadMsgId &&
                    props.lastReadMsgId?.length > 0 &&
                    props.lastMsgCount > 0 && (
                        <Box
                            marginY="1.5rem"
                            width={'80%'}
                            marginX={'auto'}
                            ref={props.newMessageRef}
                        >
                            <Divider>
                                <Chip
                                    sx={{
                                        background: '#3495ff',
                                        color: 'white',
                                    }}
                                    label={`${props.lastMsgCount} new message${
                                        props.lastMsgCount > 1 ? 's' : ''
                                    }`}
                                />
                            </Divider>
                        </Box>
                    )
            }
        </>
    );
}
