import { useContext, useEffect, useState } from 'react';
import { Avatar, IconButton } from '@mui/material';
import { Box } from '@mui/system';
import { FWTypography } from '../../Common/FWTypograhy';
import { ISessionUser, IUserSession } from '../Interfaces/Chat.model';
import { AuthContext } from '../../Contexts/AuthContext';
import ChatSectionsExpandButton from './ChatSectionsExpandButton';
import { useLocation } from 'react-router-dom';
import ChatHeaderNotes from './ChatHeaderNotes';

interface IChatMessageBoxHeaderProps {
    sessionID: string;
    noOfUsers: number;
    sessionUsers: ISessionUser[];
    selectedSessionMuteState: boolean;
    updateSessionMuteState(newState: boolean): void;
    user_type: 'buyer' | 'seller';
    buyer_entity_name: string;
    seller_entity_name: string;
    session_type: 'CHAT' | 'INTERNAL' | 'BROADCAST' | 'SELF' | undefined;
    selectedSessionAvatarColor: string;
    openedFromPopup?: boolean;
    floatingChat?: boolean;
    setSelectedSession?: React.Dispatch<
        React.SetStateAction<IUserSession | null>
    >;
    hideSessionListContainer?: boolean;
    setHideSessionListContainer?: React.Dispatch<React.SetStateAction<boolean>>;
    module_id: string;
    onNoteClick: (
        noteId: { id: string; status: 'ACTIVE' | 'INACTIVE' } | null
    ) => void;
    selfNoteContainerOpenCallback: (open: boolean) => void;
    subEventId: string;
}

const ChatMessageBoxHeader = ({
    sessionID,
    noOfUsers,
    sessionUsers,
    selectedSessionMuteState,
    user_type,
    updateSessionMuteState,
    buyer_entity_name,
    seller_entity_name,
    session_type,
    selectedSessionAvatarColor,
    openedFromPopup,
    floatingChat,
    setSelectedSession,
    hideSessionListContainer,
    setHideSessionListContainer,
    module_id,
    onNoteClick,
    selfNoteContainerOpenCallback,
    subEventId,
}: IChatMessageBoxHeaderProps) => {
    // const [isSessionMuted, setIsSessionMuted] = useState<boolean>(
    //     selectedSessionMuteState
    // );

    // const memoizedUpdateSessionMuteState = useMemo(
    //     () =>
    //         debounce((mute_state: boolean) => {
    //             if (sessionID !== '') {
    //                 postMuteSession(sessionID, mute_state)
    //                     .then(() => {
    //                         if (mute_state) {
    //                             toast.success('Group muted successfully');
    //                         } else {
    //                             toast.success('Group unmuted successfully');
    //                         }
    //                         updateSessionMuteState(mute_state);
    //                     })
    //                     .catch((err) =>
    //                         mute_state
    //                             ? toast.error('Unable to mute group')
    //                             : toast.error('Unable to unmute group')
    //                     );
    //             }
    //             //removed debouce
    //         }, 0),
    //     [sessionID, updateSessionMuteState]
    // );

    const { authData } = useContext(AuthContext);
    const location = useLocation();
    const currentPortalString = location.pathname.split('/')[1] ?? '';

    const [selfNoteContainerOpen, setSelfNoteContainerOpen] = useState(true);

    useEffect(() => {
        if (session_type === 'SELF') {
            selfNoteContainerOpenCallback(selfNoteContainerOpen);
        }
    }, [selfNoteContainerOpen, selfNoteContainerOpenCallback, session_type]);

    return (
        <Box
            bgcolor="background.default"
            className="flex flex--aic flex--jcsb"
            // overflow="scroll"
            position={'relative'}
        >
            <Box
                className="flex flex--aic"
                flex={'80%'}
                zIndex={2}
                padding={1}
                bgcolor={'white'}
            >
                {floatingChat && setSelectedSession && (
                    <IconButton
                        sx={{ marginRight: 0.5 }}
                        size="small"
                        onClick={() => setSelectedSession(null)}
                    >
                        <i className="bi bi-chevron-left"></i>
                    </IconButton>
                )}
                {currentPortalString !== 'seller' &&
                    openedFromPopup &&
                    setHideSessionListContainer && (
                        <ChatSectionsExpandButton
                            onClick={() => {
                                setHideSessionListContainer &&
                                    setHideSessionListContainer(
                                        (prev) => !prev
                                    );
                            }}
                            hidden={hideSessionListContainer}
                        />
                    )}
                <Avatar
                    sx={{
                        height:
                            openedFromPopup || floatingChat ? '40px' : '50px',
                        width:
                            openedFromPopup || floatingChat ? '40px' : '50px',
                        bgcolor: selectedSessionAvatarColor,
                    }}
                />
                &nbsp;
                <Box width={'100%'}>
                    <FWTypography
                        // fontSize={'20px'}
                        fontWeight={600}
                        color={'#4e4e4e'}
                    >
                        {session_type === 'INTERNAL'
                            ? `${buyer_entity_name} (Internal)`
                            : user_type === 'seller'
                            ? buyer_entity_name
                            : seller_entity_name}
                        {session_type === 'SELF' &&
                            `${authData.details?.name} (You)`}
                    </FWTypography>

                    <FWTypography
                        sx={{ whiteSpace: 'pre' }}
                        color="text.secondary"
                        fontSize={'0.9rem'}
                        width={floatingChat ? '35%' : '345px'}
                        margin={'0 -40px 0 0'}
                    >
                        {/* {noOfUsers} People */}

                        {/* SHowing names of users in the header of chatbox */}
                        {sessionUsers.map((user, index) => {
                            return `${
                                authData.details?.user_id === user.user_id
                                    ? 'You'
                                    : user.name
                            }${index < sessionUsers.length - 1 ? ', ' : ''}`;
                        })}
                    </FWTypography>
                </Box>
            </Box>
            {session_type === 'SELF' && (
                <ChatHeaderNotes
                    selfNoteContainerOpen={selfNoteContainerOpen}
                    setSelfNoteContainerOpen={setSelfNoteContainerOpen}
                    moduleId={module_id}
                    noteClickHandler={onNoteClick}
                    sessionID={sessionID}
                    subEventId={subEventId}
                />
            )}
            {/* {session_type !== 'SELF' && (
                <Box flex={'20%'} className="flex flex--jcfe">
                    <FWTooltip
                        title={
                            selectedSessionMuteState
                                ? 'Unmute group'
                                : 'Mute group'
                        }
                    >
                        <Box>
                            <IconButton
                                color="primary"
                                onClick={() => {
                                    memoizedUpdateSessionMuteState(
                                        !isSessionMuted
                                    );
                                    setIsSessionMuted(!isSessionMuted);
                                }}
                            >
                                <i
                                    className={
                                        selectedSessionMuteState
                                            ? 'bi bi-volume-mute'
                                            : 'bi bi-volume-up'
                                    }
                                />
                            </IconButton>
                        </Box>
                    </FWTooltip>
                </Box>
            )} */}
        </Box>
    );
};

export default ChatMessageBoxHeader;
