import { Box, Grid, Typography } from '@mui/material';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { FWTypography } from '../../Common/FWTypograhy';
import { getFWDateTime } from '../../Common/Utils/DateUtils';
import { AuthContext } from '../../Contexts/AuthContext';
import DisplaySavedStatus from '../../Events/Components/Shared/DisplaySavedStatus';
import { ILoading } from '../../Events/Interfaces/Buyer/SharedRFQ.model';
import { checkIfValueIsLink } from '../../FieldTypes/components/SingleFieldTypeRenderer';
import { downloadFile } from '../../Global/Services/FileStorageService';
import {
    DocumentStateEnum,
    IDocumentDetails,
    IDocumentDetailsErrors,
    INewDocument,
} from '../Constants/Document.model';
import { IDocumentPageMode } from '../Constants/Interfaces/documentAccess';
import { IDocumentTemplate } from '../Constants/Interfaces/documentTemplate';
import { STANDARD_GRID_COL_GAP } from '../Pages/DraftDocumentPage';
import SellerDraftCustomFields from './SellerDraftCustomFields';

export interface ISellerDraftDocumentDetailsProps {
    sellerDocumentDetails: IDocumentDetails;
    buyerDocumentDetails: IDocumentDetails;
    errors: IDocumentDetailsErrors;
    templateDetails: IDocumentTemplate;
    sellerDocumentPayload: INewDocument;
    loadingState: ILoading;
    pageMode: IDocumentPageMode;
    isBuyerSide?: boolean;
    updateDocumentDetails: (
        field: keyof INewDocument,
        value: any
    ) => Promise<void>;
}

export default function SellerDraftDocumentDetails({
    sellerDocumentDetails,
    buyerDocumentDetails,
    errors,
    templateDetails,
    sellerDocumentPayload,
    loadingState,
    pageMode,
    isBuyerSide,
    updateDocumentDetails,
}: ISellerDraftDocumentDetailsProps) {
    const { authData } = useContext(AuthContext);

    const ENTITY_LEVEL_PERMISSION: 'VIEW' | 'EDIT' =
        authData.modulePermissions.SELLER &&
        authData.modulePermissions.SELLER
            .SUPPLIER_RELATIONSHIP_MANAGEMENT_SELLER &&
        authData.modulePermissions.SELLER
            .SUPPLIER_RELATIONSHIP_MANAGEMENT_SELLER.EDIT &&
        authData.modulePermissions.SELLER.SUPPLIER_RELATIONSHIP_MANAGEMENT_SELLER.EDIT.has(
            sellerDocumentDetails?.seller_entity_id ?? ''
        )
            ? 'EDIT'
            : 'VIEW';

    const [documentBanner, setDocumentBanner] = useState<string>('');

    const [documentLogo, setDocumentLogo] = useState<string>('');

    const docBannerAttachmentId = useMemo(() => {
        if (
            buyerDocumentDetails.document_banner &&
            buyerDocumentDetails.document_banner?.length > 0
        ) {
            return buyerDocumentDetails.document_banner[0].attachment_id;
        }
        return null;
    }, [buyerDocumentDetails.document_banner]);

    const getDocumentBanner = useCallback(async () => {
        try {
            if (docBannerAttachmentId) {
                const documentBannerURL = await downloadFile(
                    docBannerAttachmentId
                );

                setDocumentBanner(documentBannerURL);
            }
        } catch (err) {}
    }, [docBannerAttachmentId]);

    useEffect(() => {
        getDocumentBanner();
    }, [getDocumentBanner]);

    const docLogoAttachmentId = useMemo(() => {
        if (
            buyerDocumentDetails.document_logo &&
            buyerDocumentDetails.document_logo?.length > 0
        ) {
            return buyerDocumentDetails.document_logo[0].attachment_id;
        }
        return null;
    }, [buyerDocumentDetails.document_logo]);

    const getDocumentLogo = useCallback(async () => {
        try {
            if (docLogoAttachmentId) {
                const documentLogoURL = await downloadFile(docLogoAttachmentId);

                setDocumentLogo(documentLogoURL);
                return;
            }
        } catch (err) {}
    }, [docLogoAttachmentId]);

    useEffect(() => {
        getDocumentLogo();
    }, [getDocumentLogo]);

    return (
        <Grid
            container
            gap={STANDARD_GRID_COL_GAP}
            padding={4}
            paddingTop={0}
            sx={{
                display: 'grid',
                gridTemplateColumns: '10% 70% 15%',
                justifyContent: 'space-between',
                gridColumnGap: '3rem',
                gridRowGap: '5px',
            }}
        >
            <Grid item></Grid>
            <Grid
                justifyContent={'center'}
                // sx={{ background: 'aliceblue' }}
            >
                {(documentBanner || documentLogo) && (
                    <Grid
                        container
                        rowGap={2}
                        columnGap={4}
                        sx={{
                            borderBottomLeftRadius: '8px',
                            borderBottomRightRadius: '8px',
                            // borderTop: '8px solid',
                            backgroundColor: '#ebf4ff',
                            backgroundImage: `url(${documentBanner})`,
                            height: '250px',
                            // width: '100%',
                            // position: 'relative',
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            // backgroundImage: documentBanner,
                            borderColor: 'primary.main',

                            zIndex: -2,
                            // background: 'white',
                            boxShadow:
                                '0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12)',
                        }}
                        padding={4}
                    >
                        {documentLogo && (
                            <Grid
                                item
                                xs={12}
                                sx={{ display: 'flex', gap: '20px' }}
                            >
                                <Box
                                    component={'img'}
                                    src={documentLogo}
                                    sx={{
                                        width: '175px',
                                        height: '175px',
                                        marginY: 'auto',
                                        borderRadius: '100%',

                                        aspectRatio: '1/1',
                                        objectFit: 'cover',
                                    }}
                                ></Box>
                            </Grid>
                        )}
                        {sellerDocumentPayload?.document_banner_id?.length ===
                            0 ||
                            (sellerDocumentPayload?.document_banner_id
                                ?.length === 0 && (
                                <>
                                    <FWTypography
                                        fontSize={24}
                                        textAlign={'center'}
                                        marginX={'auto'}
                                    >
                                        {sellerDocumentPayload
                                            ? sellerDocumentPayload.document_name
                                            : ''}
                                    </FWTypography>
                                    <FWTypography
                                        sx={{
                                            whiteSpace: 'normal',
                                        }}
                                    >
                                        {
                                            sellerDocumentPayload.document_description
                                        }
                                    </FWTypography>
                                </>
                            ))}
                    </Grid>
                )}

                <Grid
                    container
                    rowGap={2}
                    columnGap={4}
                    marginY={4}
                    sx={{
                        borderRadius: '8px',
                        borderTop: '8px solid',
                        borderColor: 'primary.main',
                        background: 'white',
                        boxShadow:
                            '0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12)',
                    }}
                    padding={4}
                >
                    <Grid item xs={12}>
                        <FWTypography textAlign={'right'} fontWeight={500}>
                            Valid till:{' '}
                            {sellerDocumentPayload.document_validity_datetime
                                ? getFWDateTime(
                                      sellerDocumentPayload.document_validity_datetime
                                  )
                                : getFWDateTime(
                                      buyerDocumentDetails.document_validity_datetime
                                  )}
                        </FWTypography>
                    </Grid>
                    <Grid item xs={12}>
                        <FWTypography
                            fontSize={30}
                            fontWeight={600}
                            textAlign={'center'}
                            marginX={'auto'}
                        >
                            {buyerDocumentDetails.document_name}
                        </FWTypography>
                    </Grid>
                    {checkIfValueIsLink(
                        sellerDocumentPayload.document_description ?? ''
                    ) ? (
                        <a
                            href={
                                (sellerDocumentPayload.document_description as string) ??
                                ''
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ textDecoration: 'none' }}
                        >
                            <Typography
                                color={'primary'}
                                sx={{
                                    textDecoration: 'none',
                                    textOverflow: 'ellipsis',
                                    overflow: 'hidden',
                                }}
                            >
                                {
                                    sellerDocumentPayload.document_description as string
                                }
                            </Typography>
                        </a>
                    ) : (
                        <FWTypography
                            sx={{
                                whiteSpace: 'normal',
                            }}
                        >
                            {sellerDocumentPayload.document_description}
                        </FWTypography>
                    )}
                </Grid>

                {Object.keys(templateDetails.sections).map((section) => {
                    return (
                        <>
                            <Grid item xs={12}>
                                <SellerDraftCustomFields
                                    buyerDocumentDetails={buyerDocumentDetails}
                                    sellerDocumentDetails={
                                        sellerDocumentDetails
                                    }
                                    errors={errors}
                                    // allowTemplateUpload={
                                    //     sellerDocumentDetails.state ===
                                    //         DocumentStateEnum.DRAFT ||
                                    //     pageMode === 'EDIT'
                                    // }
                                    gridSize={isBuyerSide ? 5.15 : 4.3}
                                    customGridSizeForTemp={
                                        isBuyerSide ? 10 : 8.5
                                    }
                                    sellerDocument={sellerDocumentPayload}
                                    allowFileUpload={
                                        sellerDocumentDetails.state ===
                                            DocumentStateEnum.DRAFT ||
                                        pageMode === 'EDIT'
                                    }
                                    showRemoveButton={
                                        sellerDocumentDetails.state ===
                                            DocumentStateEnum.DRAFT ||
                                        pageMode === 'EDIT'
                                    }
                                    templateDetails={templateDetails}
                                    updateDocumentDetails={
                                        updateDocumentDetails
                                    }
                                    sectionName={section}
                                />
                            </Grid>
                        </>
                    );
                })}
            </Grid>

            <Grid item>
                {ENTITY_LEVEL_PERMISSION === 'EDIT' &&
                    (sellerDocumentDetails.state === DocumentStateEnum.DRAFT ||
                        pageMode === 'EDIT') && (
                        <Grid
                            item
                            sx={{
                                justifyContent: 'center',
                                background: 'white',
                                borderRadius: '8px',
                                display: 'flex',
                                position: 'fixed',
                                width: '13%',
                                borderTop: '8px solid black',
                                height: 'fit-content',
                                borderColor: loadingState.saved
                                    ? 'success.main'
                                    : loadingState.error
                                    ? 'error.main'
                                    : loadingState.loading
                                    ? 'warning.main'
                                    : 'white',
                                boxShadow:
                                    '0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12)',
                            }}
                            padding={2}
                            // marginLeft={'auto'}
                        >
                            {(sellerDocumentDetails.state ===
                                DocumentStateEnum.DRAFT ||
                                pageMode === 'EDIT') && (
                                <Box>
                                    <DisplaySavedStatus
                                        saved={loadingState.saved}
                                        error={loadingState.error}
                                        loading={loadingState.loading}
                                        error_msg={loadingState.error_msg}
                                    />
                                </Box>
                            )}
                        </Grid>
                    )}
            </Grid>
        </Grid>
    );
}
