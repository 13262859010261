import { ReactElement } from 'react';
import {
    IAdditionalCostsBackend,
    IAdditionalCostsWithValue,
    IDiscountWithValue,
} from '../../../AdditionalCost/models/AdditionalCost.model';
import { CustomTemplateItemTypes } from '../../../Global/Interfaces/CustomTemplate.interface';
import { EntityStatus } from '../../../Global/Interfaces/EntityInterface';
import { IPaymentTerms } from '../../../Global/Interfaces/PaymentTermsInterface';
import { ICustomAttributeNew } from '../../../Models/Attributes.model';
import { ICurrencyDetails } from '../../../Models/Currency.model';
import { IIncotermDetails } from '../../../Models/Incoterms.model';
import {
    BackendDeliverablePaymentTerms,
    BackendInvoicePaymentTerms,
    IAttachment,
} from '../../../Models/RFQ.model';
import { IPreSelectedAdditionalCostFromApi } from '../../../Organizations/Admin/Hooks/ItemDirectory/ItemAdditionalCostHook';
import { VerificationStatus } from '../../../Redux/Slices/VerificationSlice';
import {
    IRFQItemAttributes,
    IRFQItemCustomIDs,
    IRFQItemPricingInformation,
} from './SharedRFQ.model';

export const custom_field_backend_section_names = {
    essential_terms_template_name: 'essential_terms',
    payment_and_delivery_terms_template_name: 'payment_and_delivery_terms',
    additional_information_template_name: 'additional_details',
};

export type type_custom_field_backend_section_names =
    keyof typeof custom_field_backend_section_names;

export interface ICreateEventPageData {
    entities: {
        entity_id: string;
        entity_name: string;
        default_currency_id: string;
    }[];
    currencies: ICurrencyDetails[];
    incoterms: IIncotermDetails[];
}

export interface IRFQLevelCustomField {
    name: string;
    type: string;
    value: string | boolean | string[] | null;
    is_locked: boolean;
    is_visible: boolean;
    description: string;
    is_required: boolean;
    is_negotiable: boolean;
}

export interface ICustomEventDetails {
    entity_id: string;
    entity_name: string;
    event_type: 'RFQ' | 'RFP' | 'AUCTION';
    additional_costs: IAdditionalCostsWithValue[];
    discounts: IAdditionalCostsWithValue[];
    taxes: IAdditionalCostsWithValue[];
    import_from_requisition: boolean;
    currency_id: string;
    currency_symbol: string;
    currency_code_abbreviation: string;
    default_item_terms:
        | 'FROM_PO'
        | 'CUSTOM'
        | 'NONE'
        | 'IMPORT_ITEMS'
        | 'IMPORT_CART_ORDERS'
        | 'IMPORT_REQUISITIONS';
    default_reminder_required: boolean;
    default_reminder_module: 'EVENT' | 'TEMPLATE';
    default_initial_reminder_hours: number;
    default_reminder_interval: number;
    default_max_reminders: number;
    lead_time: string;
    lead_time_period: string | null; //can be null,
    incoterm_id: string;
    requisition_no: string;
    requisition_approved: boolean;
    delivery_date: string | null;
    event_name: string;
    event_deadline: string | null;
    billing_id: string | null;
    billing_address_name: string;
    shipping_id: string | null;
    shipping_address_name: string;
    sub_event_id: string;
    parent_sub_event_id: string | null;
    event_id: string;
    event_custom_ID: string;
    event_status: string;
    sub_event_status: string;
    event_creator_name: string;
    event_creator_id: string;
    is_event_confidential: boolean;
    last_action_notes: string;
    template_id: string;
    default_event_project_id: string;
    project_id: string;
    project_name?: string;
    project_code?: string;
    default_event_gl_id: string;
    gl_id: string;
    default_event_cost_centre_id: string;
    cost_centre_id: string;
    custom_additional_information: string;
    gr_tolerance: string;
    default_event_quantity_tolerance_percentage?: string;
    default_event_item_additional_information: string;
    paymentTerms: IPaymentTerms;
    custom_fields: {
        section_list: {
            name: string;
            fields: IRFQLevelCustomField[];
        }[];
    };
    custom_fields_negotiate: {
        section_list: {
            name: string;
            fields: IRFQLevelCustomField[];
        }[];
    };
    default_customer_entity_id: string | null;
    customer_entity_id: string | null;
    showWarnings: boolean;
    total: string;
    item_total: string;
    item_subtotal: string;
    item_additional_costs_total: string;
    item_taxes_total: string;
    item_discounts_total: string;
    vendor_item_total: string;
    vendor_item_subtotal: string;
    vendor_item_additional_costs_total: string;
    vendor_item_taxes_total: string;
    vendor_item_discounts_total: string;
    // template_name: string;
}

export interface ICustomEventDetailsWithSelectedVendorInfo
    extends ICustomEventDetails {
    vendor_details: any[];
}

export type CreateEventErrorDesc = {
    type: 'WARNING' | 'ERROR';
    message: string;
};

export enum CreateEventErrorFieldEnum {
    event_name = 'event_name',
    event_deadline = 'event_deadline',
    entity_id = 'entity_id',
    customer_entity_id = 'customer_entity_id',
    default_customer_entity_id = 'default_customer_entity_id',
    template_id = 'template_id',
    currency_id = 'currency_id',
    incoterm_id = 'incoterm_id',
    billing_id = 'billing_id',
    shipping_id = 'shipping_id',
    lead_time = 'lead_time',
    lead_time_period = 'lead_time_period',
    requisition_no = 'requisition_no',
    delivery_date = 'delivery_date',
    paymentTerms_prepayment = 'paymentTerms_prepayment',
    paymentTerms_invoiceItem_terms = 'paymentTerms_terms',
    paymentTerms_invoiceItem_period = 'paymentTerms_period',
    paymentTerms_invoiceItem_appliedFrom = 'paymentTerms_appliedFrom',
    paymentTerms_poItem_overallPercentage = 'paymentTerms_poItem_overallPercentage',
    default_event_quantity_tolerance_percentage = 'default_event_quantity_tolerance_percentage',
    default_event_item_additional_information = 'default_event_item_additional_information',
    custom_additional_information = 'custom_additional_information',
    gr_tolerance = 'gr_tolerance',
    paymentTerms_poItem_allocationRows = 'paymentTerms_poItem_allocationRows',
    default_event_project_id = 'default_event_project_id',
    default_event_gl_id = 'default_event_gl_id',
    default_event_cost_centre_id = 'default_event_cost_centre_id',
    project_id = 'project_id',
    gl_id = 'gl_id',
    cost_centre_id = 'cost_centre_id',
    custom_fields = 'custom_fields',
    custom_fields_negotiate = 'custom_fields_negotiate',
}

export type updateCustomEventDetailsActionType = keyof ICustomEventDetails;

export type PaymentTermUpdateField =
    | 'paymentTerms_prepayment'
    | 'paymentTerms_invoiceItem_terms'
    | 'paymentTerms_invoiceItem_period'
    | 'paymentTerms_invoiceItem_appliedFrom'
    | 'paymentTerms_poItem_allocationRows';

export type IEventItemFieldValidation = {
    type: 'WARNING' | 'ERROR';
    fieldMessageText: string;
    popupMessageText: string;
    suppress: boolean;
    HelperComponent?: () => ReactElement;
};

export type IEventItemValidation = {
    specifications?: IEventItemFieldValidation;
    quantity?: IEventItemFieldValidation;
    measurementUnit?: IEventItemFieldValidation;
    price?: IEventItemFieldValidation;
    shipping?: IEventItemFieldValidation;
    additional_charges?: IEventItemFieldValidation;
    lead_time?: IEventItemFieldValidation;
    lead_time_period?: IEventItemFieldValidation;
    excessive_lead_time?: IEventItemFieldValidation;
    incoterm_id?: IEventItemFieldValidation;
    vendor_count?: IEventItemFieldValidation;
    quantity_tolerance_percentage?: IEventItemFieldValidation;
    requisition_number?: IEventItemFieldValidation;
    requisition_approval?: IEventItemFieldValidation;
    item_additional_information?: IEventItemFieldValidation;
    customFields: {
        [sectionName: string]: {
            [customFieldName: string]: IEventItemFieldValidation;
        };
    };
    deliveryScheduleOverall?: IEventItemFieldValidation;
    deliveryScheduleRows: {
        quantity?: IEventItemFieldValidation;
        date?: IEventItemFieldValidation;
        customerEntityId?: IEventItemFieldValidation;
        cost_centre_id?: IEventItemFieldValidation;
        project_id?: IEventItemFieldValidation;
        general_ledger_id?: IEventItemFieldValidation;
    }[];
    paymentTerms_prepayment?: IEventItemFieldValidation;
    paymentTerms_invoiceItem_terms?: IEventItemFieldValidation;
    paymentTerms_invoiceItem_period?: IEventItemFieldValidation;
    paymentTerms_invoiceItem_appliedFrom?: IEventItemFieldValidation;
    paymentTerms_poItem_overallPercentage?: IEventItemFieldValidation;
    paymentTerms_poItem_allocationRows: {
        rowAllocationPercentage?: IEventItemFieldValidation;
        rowTerms?: IEventItemFieldValidation;
        rowPeriod?: IEventItemFieldValidation;
        rowDeliverableName?: IEventItemFieldValidation;
        rowDate?: IEventItemFieldValidation;
    }[];
    cheaperContractAvailable?: IEventItemFieldValidation;
    noContractVendorSelected?: IEventItemFieldValidation;
    taxes?: IEventItemFieldValidation;
    additional_cost?: IEventItemFieldValidation;
    discounts?: IEventItemFieldValidation;
};

// interface to capture errors on create event for
export type ICustomEventDetailsErrors = {
    event_name?: CreateEventErrorDesc;
    event_deadline?: CreateEventErrorDesc;
    customer_entity_id?: CreateEventErrorDesc;
    default_customer_entity_id?: CreateEventErrorDesc;
    entity_id?: CreateEventErrorDesc;
    template_id?: CreateEventErrorDesc;
    currency_id?: CreateEventErrorDesc;
    billing_id?: CreateEventErrorDesc;
    shipping_id?: CreateEventErrorDesc;
    lead_time?: CreateEventErrorDesc;
    lead_time_period?: CreateEventErrorDesc;
    delivery_date?: CreateEventErrorDesc;
    requisition_no?: CreateEventErrorDesc;
    deliveryScheduleOverall?: CreateEventErrorDesc;
    paymentTerms_prepayment?: CreateEventErrorDesc;
    paymentTerms_invoiceItem_terms?: CreateEventErrorDesc;
    paymentTerms_invoiceItem_period?: CreateEventErrorDesc;
    paymentTerms_invoiceItem_appliedFrom?: CreateEventErrorDesc;
    paymentTerms_poItem_overallPercentage?: CreateEventErrorDesc;
    paymentTerms_poItem_allocationRows: {
        rowAllocationPercentage?: CreateEventErrorDesc;
        rowTerms?: CreateEventErrorDesc;
        rowPeriod?: CreateEventErrorDesc;
        rowDeliverableName?: CreateEventErrorDesc;
        rowDate?: CreateEventErrorDesc;
    }[];
    default_item_terms?: CreateEventErrorDesc;
    default_event_project_id?: CreateEventErrorDesc;
    default_event_gl_id?: CreateEventErrorDesc;
    default_event_cost_centre_id?: CreateEventErrorDesc;
    project_id?: CreateEventErrorDesc;
    gl_id?: CreateEventErrorDesc;
    cost_centre_id?: CreateEventErrorDesc;
    custom_fields?: {
        [key: string]: CreateEventErrorDesc;
    };
    custom_fields_negotiate?: {
        [key: string]: CreateEventErrorDesc;
    };
    default_event_quantity_tolerance_percentage?: CreateEventErrorDesc;
    default_event_item_additional_information?: CreateEventErrorDesc;
    custom_additional_information?: CreateEventErrorDesc;
    gr_tolerance?: CreateEventErrorDesc;
    [key: string]:
        | CreateEventErrorDesc
        | {
              [key: string]: CreateEventErrorDesc;
          }
        | {
              rowAllocationPercentage?: CreateEventErrorDesc;
              rowTerms?: CreateEventErrorDesc;
              rowPeriod?: CreateEventErrorDesc;
              rowDeliverableName?: CreateEventErrorDesc;
              rowDate?: CreateEventErrorDesc;
          }[]
        | undefined;
};

export interface IEventDetails {
    additional_details: {
        item_count: string;
        child_rfq_id: null | string;
        vendor_count: string;
        parent_rfq_id: null | string;
        last_action_information: { notes: string; user_id: string };
    };
    address_information: {
        billing_address: string;
        shipping_address: string;
        billing_address_id: string | null;
        shipping_address_id: string | null;
    };
    customer_entity_id: string;
    buyer_entity: {
        buyer_entity_id: string;
        buyer_entity_name: string;
    };
    pricing_information: {
        currency_name: string;
        currency_symbol: string;
        currency_code_id: string;
        currency_code_abbreviation: string;
        total_price: string;
        item_total: string;
        item_subtotal: string;
        item_additional_costs_total: string;
        item_taxes_total: string;
        item_discounts_total: string;
        vendor_item_total: string;
        vendor_item_subtotal: string;
        vendor_item_additional_costs_total: string;
        vendor_item_taxes_total: string;
        vendor_item_discounts_total: string;
    };
    custom_event_id: string;
    taxes: IAdditionalCostsBackend[];
    additional_costs: IAdditionalCostsBackend[];
    discounts: IAdditionalCostsBackend[];
    rfq_default_information: {
        default_event_item_additional_information?: string;
        default_event_quantity_tolerance_percentage?: string;
        default_event_payment_terms: BackendInvoicePaymentTerms;
        default_event_deliverables_payment_terms: BackendDeliverablePaymentTerms;
        default_event_prepayment_percentage: string | number | null;
        standard_terms_type: 'LOAD_FROM_PO' | 'CUSTOM' | 'NONE';
        default_currency_abbreviation: string;
        default_currency_id: string;
        default_currency_symbol: string;
        default_delivery_date: string;
        default_customer_entity_id: string;
        default_intital_reminder_hours: number;
        default_event_incoterm_abbreviation: string;
        default_event_incoterm_id: string;
        default_event_lead_time: string;
        default_event_lead_time_period: string;
        default_event_project_id: string | null;
        default_event_gl_id: string | null;
        default_event_cost_centre_id: string | null;
        defaultPaymentTerms: IPaymentTerms;
        default_reminder_required: boolean;
        default_reminder_module: 'EVENT' | 'TEMPLATE';
        default_initial_reminder_hours: number;
        default_reminder_interval: number;
        default_max_reminders: number;
    };
    requisition_information: {
        requisition_number: string;
        requisition_approved: boolean;
    }[];
    event_additional_details: {
        access: string;
        template_id: string;
    } | null;
    pause_information: { notes: string; pause_datetime: string } | null;
    termination_information: { notes: string } | null;
    event_details: {
        event_id: string;
        custom_event_id: string;
        event_name: string;
        event_type: string;
        status: string;
        live_event_start_datetime: string | null;
        additional_details: {
            access: string;
        } | null;
        pause_information: { notes: string; pause_datetime: string } | null;
        termination_information: { notes: string } | null;
        enterprise: string;
        original_event_creator: string;
        original_event_creator_name: string;
    };
    event_id: string;
    event_status: string;
    status: string;
    live_event_start_datetime: string | null;
    version: string;
    event_name: string;
    original_event_creator_name: string;
    final_approver: string | null;
    event_type: 'RFQ' | 'PO_GROUP';
    event_end_datetime: string | null;
    rfq_entry_id?: string;
    rfq_event_creator?: string;
    po_group_entry_id?: string;
    po_group_event_creator?: string;
    cost_centre_id: string | null;
    gl_id: string | null;
    project_id: string | null;
    lead_time: string | null;
    lead_time_period: string | null;
    incoterm_id: string | null;
    custom_additional_information: string | null;
    gr_tolerance: string | null;
    project?: {
        project_code: string;
        project_name: string;
    };
    custom_fields: {
        section_list: {
            name: string;
            fields: IRFQLevelCustomField[];
        }[];
    };
    custom_fields_negotiate: {
        section_list: {
            name: string;
            fields: IRFQLevelCustomField[];
        }[];
    };
    showWarnings: boolean;
    linked_vendor?: string;
}

export interface IParentSubBomItemForIBomdeliveryScheduleStrutcure {
    entry_id: string;
    sub_bom: {
        enterprise_bom_id: string;
        bom_code: string;
        quantity: string;
        measurement_unit: string;
        measurement_unit_abbreviation: string;
        measurement_unit_name: string;
    } | null;
    enterprise_bom: {
        entry_id: string;
        bom_code: string;
        quantity: string;
        measurement_unit: string;
        measurement_unit_abbreviation: string;
        measurement_unit_name: string;
    } | null;
    quantity: string;
    measurement_unit: string;
    measurement_unit_abbreviation: string;
    measurement_unit_name: string;
    parent_sub_bom_item: IParentSubBomItemForIBomdeliveryScheduleStrutcure | null;
}

export interface IBomItemDeliverScheduleStructure {
    bom_item_id: string;
    bom_item: { quantity: number };
    entry_id: string;
    index: string;
    enterprise_bom: {
        entry_id: string;
        bom_code: string;
        quantity: string;
        measurement_unit: string;
        measurement_unit_abbreviation: string;
        measurement_unit_name: string;
    } | null;
    quantity: string;
    measurement_unit: string;
    measurement_unit_abbreviation: string;
    measurement_unit_name: string;
    parent_sub_bom_item: IParentSubBomItemForIBomdeliveryScheduleStrutcure | null;
    alternate_parent_bom_item: {
        raw_material_name: string;
        entry_id: string;
    } | null;
}

export interface IEventItemDetails {
    id?: number;
    rfq_item_entry_id: string;
    rfq_item_id: string;
    additional_details: {
        offer_count: string;
        purchase_order_average_rate: string;
        purchase_order_count: string;
        purchase_order_total_amount: string;
        purchase_order_vendor_count: string;
        vendor_count: string;
    };
    item_information: {
        buyer_item_id: string;
        global_item_id: string;
        buyer_item_name: string;
        custom_item_name: string;
        buyer_item_description: string;
        item_additional_details: string;
        custom_ids?: IRFQItemCustomIDs[];
    };
    attribute_information: IRFQItemAttributes;
    attributes: ICustomAttributeNew[];
    incoterm_abbreviation: string;
    fulfilment_information: {
        awarded_quantity: string;
        po_item_approval_pending_quantity: string;
        po_item_issued_quantity: string;
        po_item_accepted_quantity: string;
    };
    property_information: Array<{
        property_name: string;
        property_value: string;
    }>;
    pricing_information: IRFQItemPricingInformation;
    quantity_information: {
        quantity: string;
        quantity_tolerance_percentage: string;
        measurement_unit_id: string;
        measurement_unit_name: string;
        max_awardable_quantity?: string;
    };
    delivery_information: Array<{
        bom_item: IBomItemDeliverScheduleStructure | null;
        quantity: string;
        delivery_date: string | null;
        cost_centre_id: string | null;
        cost_centre_name: string | null;
        gl_code: string | null;
        general_ledger_id: string | null;
        project_code: string | null;
        project_id: string | null;
        parent_delivery_schedule_item?: {
            delivery_schedule_item_id: string | null;
            parent_delivery_schedule_item: string | null;
            quantity: number;
            requisition_item: {
                custom_requisition_id: string;
                requisition_id: string;
                shipping_address: {
                    shipping_address_id: string;
                    shipping_full_address: string;
                };
            };
        } | null;
    }>;
    delivery_schedule: Array<{
        bom_item: IBomItemDeliverScheduleStructure | null;
        quantity: string;
        delivery_date: string | null;
        cost_centre_id: string | null;
        cost_centre_name: string | null;
        gl_code: string | null;
        customer_entity_id: string | null;
        general_ledger_id: string | null;
        project_code: string | null;
        project_id: string | null;
        delivery_schedule_item_id?: string | null;
        parent_delivery_schedule_item?: {
            delivery_schedule_item_id: string | null;
            parent_delivery_schedule_item: string | null;
            quantity: number;
            requisition_item: {
                custom_requisition_id: string;
                requisition_id: string;
                requisition_source_type: string;
                shipping_address: {
                    shipping_address_id: string;
                    shipping_full_address: string;
                };
            };
        } | null;
    }>;
    buyer_delivery_schedule: {
        bom_item: IBomItemDeliverScheduleStructure | null;
        cost_centre: string | null;
        customer_entity: string | null;
        delivery_date: string | null;
        delivery_schedule_item_id: string | null;
        general_ledger: string | null;
        project: string | null;
        quantity: string;
    }[];
    enterprise_item: string;
    procurement_information: {
        lead_time: string;
        lead_time_period: string;
        allow_substitutes: boolean;
        is_price_confidential: boolean;
        post_to_global_marketplace: boolean;
    };
    requisition_information: {
        requisition_number: string;
        requisition_approved: boolean;
    }[];
    rfq_event: string;
    item: string;
    incoterm: string;
    attachments: IAttachment[];
    custom_fields: IEventItemCustomFieldsBackend;
    custom_fields_negotiate: IEventItemCustomFieldsBackend;
    purchase_order_status?:
        | 'PURCHASE_ORDER_PENDING'
        | 'PURCHASE_ORDER_REJECTED'
        | 'PURCHASE_ORDER_ISSUED'
        | 'PURCHASE_ORDER_COMPLETED';
    tags: string[];
    paymentTerms: IPaymentTerms;
    prepayment_percentage: string | number | null;
    payment_terms: BackendInvoicePaymentTerms;
    deliverables_payment_terms: BackendDeliverablePaymentTerms;
}

export interface ICustomEventItemDetails {
    rfq_item_id: string;
    enterprise_item_id: string;
    buyer_item_name: string;
    buyer_item_code: string;
    incoterm_name: string;
    event_item_entry_id: string;
    shipping: string;
    additional_charges:
        | {
              charge_name: string;
              charge_value: string;
          }[];
    price: string;
    currency_code_id: string;
    currency_code_abbreviation: string;
    currency_code_symbol: string;
    specifications: IRFQItemAttributes;
    attributes: ICustomAttributeNew[];
    custom_ids?: {
        name: string;
        value: string;
    }[];
    quantity: string;
    quantity_tolerance_percentage: string;
    measurement_unit_id: string;
    measurement_unit_name: string;
    item_description: string;
    item_additional_information: string;
    is_price_confidential: boolean;
    attachments: IAttachment[];
    delivery_schedule: {
        bom_item: IBomItemDeliverScheduleStructure | null;
        delivery_date: string | null;
        quantity: string;
        customer_entity: string | null;
        project_id: string | null;
        cost_centre_id: string | null;
        general_ledger_id: string | null;
        parent_delivery_schedule_item?: {
            delivery_schedule_item_id: string | null | undefined;
            parent_delivery_schedule_item: string | null | undefined;
            quantity: number | undefined;
            requisition_item: {
                custom_requisition_id: string | undefined;
                requisition_id: string | undefined;
                requisition_source_type: string | undefined;
                shipping_address: {
                    shipping_address_id: string | undefined;
                    shipping_full_address: string | undefined;
                };
            };
        } | null;
    }[];
    requisition_number: string;
    requisition_approved: boolean;
    incoterm_id: string;
    lead_time: string;
    lead_time_period: string | null;
    paymentTerms: IPaymentTerms;
    po_status:
        | 'PURCHASE_ORDER_PENDING'
        | 'PURCHASE_ORDER_REJECTED'
        | 'PURCHASE_ORDER_ISSUED'
        | 'PURCHASE_ORDER_COMPLETED'
        | 'PURCHASE_ORDER_APPROVAL_PENDING'
        | 'PURCHASE_ORDER_REWORK'
        | null;
    po_item_approval_pending_quantity: string;
    po_item_issued_quantity: string;
    po_item_accepted_quantity: string;
    custom_fields_sections: IEventItemCustomFieldSections;
    taxes: IAdditionalCostsWithValue[];
    additional_costs: IAdditionalCostsWithValue[];
    discounts: IDiscountWithValue[];
    tags: string[];
    total: string;
    item_total: string;
    item_subtotal: string;
    item_additional_costs_total: string;
    item_taxes_total: string;
    item_discounts_total: string;
    vendor_item_total: string;
    vendor_item_subtotal: string;
    vendor_item_additional_costs_total: string;
    vendor_item_taxes_total: string;
    vendor_item_discounts_total: string;
}

export interface ICustomField {
    field_name: string;
    field_value: string;
    field_type: string;
    field_visible: boolean;
}

export type updateEventItemDetailsActionType =
    | keyof ICustomEventItemDetails
    | 'paymentTerms_prepaymentPercentage'
    | 'paymentTerms_invoiceItem_terms'
    | 'paymentTerms_invoiceItem_period'
    | 'paymentTerms_invoiceItem_appliedFrom'
    | 'paymentTerms_poItem_allocationRows'
    | 'deliveryScheduleOverall';

// interface to capture errors on create event form
export type IEventItemDetailsError = {
    [key in
        | updateEventItemDetailsActionType
        | 'delivery_information_total'
        | string]: {
        type: 'WARNING' | 'ERROR';
        fieldMessageText: string;
        popupMessageText: string;
        suppress: boolean;
        HelperComponent?: () => React.ReactElement;
    };
};

export interface IEventItemVendorDetails {
    id?: number;
    entity_vendor_master_id: string;
    enterprise_item: string;
    buyer_enterprise: string;
    status: 'PREFERRED';
    seller_entity: {
        entity_id: string;
        enterprise: string;
        entity_name: string;
        entity_logo: null | string;
        entity_description: string;
        buyer_status: EntityStatus;
        seller_status: EntityStatus;
        contacts: { [key: string]: string };
    };
}

export interface IBuyerItemList {
    entity_item_id: string;
    preferred_vendors_count: number;
    preferred_vendors: {
        seller_entity_id: string;
        seller_entity_name: string;
    }[];
    enterprise_item: IBuyerEnterpriseItemDetails;
    status: 'ACTIVE' | 'INACTIVE';
    buyer_enterprise: string;
    buyer_entity: string;
    hasNext?: boolean;
}

export interface IBuyerEnterpriseItemDetails {
    enterprise_item_id: string;
    code: string;
    name: string;
    description: string;
    notes: string;
    item_type: 'RAW_MATERIAL' | 'FINISHED_GOOD';
    measurement_units: {
        item_measurement_units: {
            measurement_unit_id: string;
            measurement_unit_category: string;
            measurement_unit_value_type: string;
            measurement_unit_primary_name: string;
            abbreviation: string;
        }[];
    };
    pricing_information?: {
        price: string;
        currency_code_id: string;
        currency_code_abbreviation: string;
        currency_symbol: string;
        currency_name: string;
    };
    attributes: ICustomAttributeNew[];
    custom_ids: {
        custom_ids: {
            name: string;
            value: string;
        }[];
    };
    status: 'ACTIVE' | 'INACTIVE';
    buyer_enterprise: string;
    tags: string[];
    buyer_pricing_information: {
        price: string | null;
        currency_name: string | null;
        currency_symbol: string | null;
        currency_code_id: string | null;
        currency_code_abbreviation: string | null;
    } | null;
    buyer_additional_costs: IPreSelectedAdditionalCostFromApi[];
    seller_pricing_information: {
        price: string | null;
        currency_name: string | null;
        currency_symbol: string | null;
        currency_code_id: string | null;
        currency_code_abbreviation: string | null;
    } | null;
    seller_additional_costs: IPreSelectedAdditionalCostFromApi[];
    custom_fields?: IEventItemCustomFieldsBackend;
}

export interface IVendorForEventItem {
    id?: string;
    no: number;
    entity_vendor_master_id: string;
    enterprise_item: string;
    buyer_enterprise: string;
    status: 'PREFERRED' | 'STANDARD';
    vendor_code: string;
    seller_entity: {
        entity_id: string;
        enterprise: string;
        entity_name: string;
        entity_logo: null | string;
        entity_description: string;
        buyer_status: EntityStatus;
        seller_status: EntityStatus;
        contacts: { [key: string]: string | string[] };
        is_virtual: boolean;
        verification_status: VerificationStatus;
    };
    seller_contacts: {
        vendor_contact_id: string;
        full_name: string;
        primary_email: string;
        emails: {
            type: string;
            email: string;
        }[];
        phone_numbers: string[];
        tags: string[];
    }[];
    contract_id?: string;
}

export interface IEventList {
    additional_details: {
        item_count: string;
        child_rfq_id: null | string;
        vendor_count: string;
        parent_rfq_id: null | string;
    };
    buyer_entity: { buyer_entity_id: string; buyer_entity_name: string };
    custom_event_id: string;
    event_creator: string;
    event_end_datetime: string;
    event_id: string;
    event_name: string;
    event_type: string;
    live_event_start_datetime: null | string;
    rfq_entry_id: string;
    rfq_event_creator: string;
    status: string;
    version: string;
}

export interface IEventItemCustomField {
    name: string;
    description: string;
    type: CustomTemplateItemTypes;
    value: string | null | string[] | boolean;
    is_required: boolean;
    is_visible: boolean;
    is_locked: boolean;
    is_negotiable: boolean;
}

export interface IEventItemCustomFieldSections {
    existingSections: {
        essential_terms: IEventItemCustomField[];
        payment_and_delivery_terms: IEventItemCustomField[];
        additional_details: IEventItemCustomField[];
    };
    newSections: {
        //Section name: Array of custom fields
        [key: string]: IEventItemCustomField[];
    };
}
export interface IEventItemCustomFieldsBackend {
    section_list: {
        name: string;
        fields: IEventItemCustomField[];
    }[];
}

export interface IErrorsRelevantToAccordions {
    main_accordion: {
        error: boolean;
        suppress: boolean;
    };
    additional_details_accordion: {
        error: boolean;
        suppress: boolean;
    };
    procurement_details_accordion: {
        error: boolean;
        suppress: boolean;
    };
    vendors_accordion: {
        error: boolean;
        suppress: boolean;
    };
}
