import { Divider, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import Fuse from 'fuse.js';
import React from 'react';
import {
    FWAccordion,
    FWAccordionDetails,
    FWAccordionSummary,
} from '../../Common/FWAccordian';
import { FWTypography } from '../../Common/FWTypograhy';
import {
    HelpInternalPageName,
    HelpPageName,
} from '../../Redux/Slices/HelpInformationSlice';
import { IHelpInformationItem } from '../Interfaces/HelpInformationInterface';
import { IUserViewPermissions } from '../Interfaces/HelpPermissionsInterface';
import { IHelpSBS } from '../Interfaces/HelpSBSInterface';
import HelpTabPanel from './HelpTabPanel';

interface HelpTabPanelInterface {
    tabValue: string;
    helpData: IHelpInformationItem[];
    searchText: string;
    toggleAccordion: Set<string>;
    setToggleAccordion: React.Dispatch<React.SetStateAction<Set<string>>>;
    pageName: HelpPageName | null;
    internalPageName: HelpInternalPageName | null;
    viewGuide: IHelpSBS;
    setViewGuide: React.Dispatch<React.SetStateAction<IHelpSBS>>;
    userViewPermissions: IUserViewPermissions;
    defaultOpen: boolean;
    setDefaultOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const VideosHelpTabPanel = ({
    tabValue,
    helpData,
    searchText,
    toggleAccordion,
    setToggleAccordion,
    pageName,
    internalPageName,
    viewGuide,
    setViewGuide,
    userViewPermissions,
    defaultOpen,
    setDefaultOpen,
}: HelpTabPanelInterface) => {
    const sectionTitleSet = new Set();
    const titleSet = new Set();
    return (
        <HelpTabPanel value={tabValue} index={'VIDEOS'}>
            {helpData
                .filter(
                    (val) =>
                        userViewPermissions[
                            val.sectionIdentifier as keyof IUserViewPermissions
                        ] && val.sectionVideos.length > 0
                )
                .filter((val) => {
                    if (
                        internalPageName ===
                            HelpInternalPageName.PO_ALLOCATIONS ||
                        internalPageName === HelpInternalPageName.PO_STRATEGY ||
                        internalPageName === HelpInternalPageName.PO_REVIEW
                    ) {
                        return val.sectionIdentifier === HelpPageName.EVENT;
                    }

                    if (
                        internalPageName ===
                            HelpInternalPageName.D2PO_DASHBOARD ||
                        internalPageName === HelpInternalPageName.CREATE_D2PO ||
                        internalPageName ===
                            HelpInternalPageName.ADD_EDIT_PO_GROUP
                    ) {
                        return (
                            val.sectionIdentifier ===
                            HelpPageName.PURCHASE_ORDER
                        );
                    }
                    if (
                        internalPageName ===
                        HelpInternalPageName.ITEM_ANALYTICS_DASHBOARD
                    ) {
                        return (
                            val.sectionIdentifier ===
                            HelpPageName.ITEM_ANALYTICS
                        );
                    }
                    if (
                        internalPageName === HelpInternalPageName.CART_EDIT_PAGE
                    ) {
                        return val.sectionIdentifier === HelpPageName.CART;
                    }
                    // if (
                    //     internalPageName ===
                    //     HelpInternalPageName.TEMPLATES_DASHBOARD
                    // ) {
                    //     return val.sectionIdentifier === HelpPageName.TEMPLATES;
                    // }
                    if (
                        internalPageName ===
                        HelpInternalPageName.INVOICE_DASHBOARD
                    ) {
                        return val.sectionIdentifier === HelpPageName.INVOICE;
                    }
                    // if (
                    //     internalPageName ===
                    //         HelpInternalPageName.SELLER_EVENTS_DASHBOARD ||
                    //     internalPageName ===
                    //         HelpInternalPageName.SELLER_OPENING_BID ||
                    //     internalPageName ===
                    //         HelpInternalPageName.SELLER_EVENTS_PURCHASE_ORDER
                    // ) {
                    //     return val.sectionIdentifier === HelpPageName.EVENT;
                    // }
                    if (
                        internalPageName !== undefined &&
                        val.sectionPageIdentifier !== undefined
                    ) {
                        return internalPageName === val.sectionPageIdentifier;
                    } else return true;
                })
                .map((item, idx) => {
                    const index = Fuse.createIndex(
                        ['title'],
                        item.sectionVideos
                    );
                    const fusedItemData = new Fuse(
                        item.sectionVideos,
                        {
                            keys: ['title'],
                            findAllMatches: true,
                            threshold: 0.3,
                        },
                        index
                    );
                    const x =
                        searchText.trim() === ''
                            ? item.sectionVideos
                            : fusedItemData
                                  .search(searchText)
                                  .map(({ item }) => item);
                    let value = sectionTitleSet.size;
                    sectionTitleSet.add(item.sectionTitle);
                    let finalValue = sectionTitleSet.size;
                    return (
                        <div
                            key={idx}
                            data-scroll-section={`help-scroll-section-${item.sectionIdentifier}`}
                        >
                            <FWAccordion
                                sx={{
                                    '&.MuiPaper-root': {
                                        minWidth: 'auto',
                                        p: 0,
                                        border: 0,
                                        alignContent: 'center',
                                        alignItems: 'center',
                                    },
                                    '.MuiButtonBase-root': {
                                        width: 'max-content !important',
                                    },
                                    '.MuiAccordionSummary-content': {
                                        width: 'max-content !important',
                                    },
                                    '.MuiAccordionSummary-expandIconWrapper': {
                                        ml: '10px',
                                        zoom: 0.7,
                                    },
                                }}
                                expanded={
                                    toggleAccordion.has(item.sectionTitle) ||
                                    defaultOpen ||
                                    searchText.length > 0
                                }
                                onClick={() => {
                                    if (
                                        toggleAccordion.has(item.sectionTitle)
                                    ) {
                                        setToggleAccordion((prev) => {
                                            let newSet = new Set(prev);
                                            newSet.delete(item.sectionTitle);
                                            return newSet;
                                        });
                                        setDefaultOpen((prev) => {
                                            return false;
                                        });
                                    } else {
                                        setToggleAccordion((prev) => {
                                            let newSet = new Set(prev);
                                            newSet.add(item.sectionTitle);
                                            return newSet;
                                        });
                                        setDefaultOpen((prev) => {
                                            return false;
                                        });
                                    }
                                }}
                            >
                                {value !== finalValue && (
                                    <FWAccordionSummary>
                                        <FWTypography
                                            fontSize="20px"
                                            style={{ paddingTop: '10px' }}
                                        >
                                            {item.sectionTitle}
                                        </FWTypography>
                                    </FWAccordionSummary>
                                )}
                                <FWAccordionDetails
                                    onClick={(e) => {
                                        e.stopPropagation();
                                    }}
                                >
                                    {x.map(
                                        (
                                            {
                                                url,
                                                title,
                                                para,
                                                duration,
                                                stepByStep,
                                            },
                                            i
                                        ) => {
                                            let value = titleSet.size;
                                            titleSet.add(title);
                                            let finalValue = titleSet.size;
                                            return (
                                                <Box key={i}>
                                                    {value !== finalValue && (
                                                        <div>
                                                            <Typography
                                                                fontWeight={600}
                                                                fontSize={18}
                                                                color={
                                                                    'text.secondary'
                                                                }
                                                                marginBottom={
                                                                    0.5
                                                                }
                                                            >
                                                                {title}
                                                            </Typography>
                                                            <Typography
                                                                marginBottom={2}
                                                            >
                                                                {para}
                                                            </Typography>
                                                            <iframe
                                                                style={{
                                                                    border: 0,
                                                                    width: '100%',
                                                                    height: '350px',
                                                                    verticalAlign:
                                                                        'middle',
                                                                    marginBottom:
                                                                        '10px',
                                                                }}
                                                                src={url}
                                                                title="Vid"
                                                                allow="accelerometer; encrypted-media; gyroscope; fullscreen"
                                                            />
                                                            <Grid
                                                                container
                                                                alignItems={
                                                                    'center'
                                                                }
                                                                justifyContent={
                                                                    'space-between'
                                                                }
                                                            >
                                                                <Grid item>
                                                                    {/* <FWTypography
                                                    {titleSet.size !==
                                                        titleSet.add(title)
                                                            .size && (
                                                        <div>
                                                            <Typography
                                                                fontWeight={600}
                                                                fontSize={18}
                                                                color={
                                                                    'text.secondary'
                                                                }
                                                                marginBottom={
                                                                    0.5
                                                                }
                                                            >
                                                                {title}
                                                            </Typography>
                                                            <Typography
                                                                marginBottom={2}
                                                            >
                                                                {para}
                                                            </Typography>
                                                            <iframe
                                                                style={{
                                                                    border: 0,
                                                                    width: '100%',
                                                                    height: '350px',
                                                                    verticalAlign:
                                                                        'middle',
                                                                    marginBottom:
                                                                        '10px',
                                                                }}
                                                                src={url}
                                                                title="Vid"
                                                                allow="accelerometer; encrypted-media; gyroscope; fullscreen"
                                                            />
                                                            <Grid
                                                                container
                                                                alignItems={
                                                                    'center'
                                                                }
                                                                justifyContent={
                                                                    'space-between'
                                                                }
                                                            >
                                                                <Grid item>
                                                                    {/* <FWTypography
                                                                fontWeight={600}
                                                                fontSize={18}
                                                                color={
                                                                    'text.secondary'
                                                                }
                                                            >
                                                                {title} (
                                                                {duration})
                                                            </FWTypography> */}
                                                                </Grid>
                                                                <Grid item>
                                                                    {stepByStep.steps.filter(
                                                                        (
                                                                            step
                                                                        ) =>
                                                                            step.point !==
                                                                            ''
                                                                    ).length >
                                                                        0 && (
                                                                        <Typography
                                                                            fontSize={
                                                                                13
                                                                            }
                                                                            sx={{
                                                                                textDecoration:
                                                                                    'underline',
                                                                                cursor: 'pointer',
                                                                            }}
                                                                            color={
                                                                                'primary.main'
                                                                            }
                                                                            onClick={() =>
                                                                                setViewGuide(
                                                                                    {
                                                                                        state:
                                                                                            stepByStep
                                                                                                .steps
                                                                                                .length >
                                                                                            0,
                                                                                        sbsArr: {
                                                                                            ...stepByStep,
                                                                                            title,
                                                                                        },
                                                                                    }
                                                                                )
                                                                            }
                                                                        >
                                                                            View
                                                                            guide
                                                                        </Typography>
                                                                    )}
                                                                </Grid>
                                                            </Grid>
                                                            <br />
                                                            <Divider light />
                                                            <br />
                                                        </div>
                                                    )}
                                                </Box>
                                            );
                                        }
                                    )}
                                </FWAccordionDetails>
                            </FWAccordion>
                        </div>
                    );
                })}
        </HelpTabPanel>
    );
};

export default VideosHelpTabPanel;
