import { toast } from 'react-toastify';
import { IEventItemDetails } from '../../../../Events/Interfaces/Buyer/RFQ.model';
import { transformPaymentTermsFromBackendToFrontend } from '../../../../Global/Helpers/paymentTermsHelpers';
import { JsonNumsToStrings } from '../../../../Global/Services/JsonProcessingService';
import * as types from '../../../../Models/RFQSummary.model';
import { IEventApproval } from '../../../../Models/RFQSummary.model';
import {
    IAllUserEventPermissions,
    IUserEventPermission,
    IUserPermission,
} from '../../../../Models/UserDetails.model';
import { deletePoGroupApi } from '../../../../PurchaseOrders/Services/PoGroup.service';
import { baseApiSlice } from '../../../../Redux/Slices/BaseApiSlice';
import { getFlatUserHierarchy } from '../../../../Services/Organization/organizationOperations';
import { userIsInCreatorHierarchy } from '../../../../Services/events/eventOperations';
import { del, get, post, put } from '../../../../Utils/api';

export const sendReminderApi = (
    rfq_event_id: string,
    seller_entity_id: string
): Promise<void> => {
    return new Promise(async (resolve, reject) => {
        try {
            await post<any, any>(
                `/events/rfq/${rfq_event_id}/seller/${seller_entity_id}/remind/`,
                {}
            );
            toast.success('Reminder sent successfully');
        } catch (err) {
            toast.error('Failed to send reminder');
            reject(err);
        }
    });
};

export const fetchSellerReminderDetails = async (
    rfq_entry_id: string
): Promise<
    {
        resource_entity: string;
        resource_entity_name: string;
        action_time: string;
    }[]
> => {
    const response: any = await get<any>(
        `/events/rfq/${rfq_entry_id}/sellers/remind/latest/`
    );
    const data: {
        resource_entity: string;
        resource_entity_name: string;
        action_time: string;
    }[] = response.data;
    if (data) {
        return data;
    }
    return response;
};

export const { useFetchSellerReminderDetailsQuery } =
    baseApiSlice.injectEndpoints({
        endpoints: (build) => ({
            fetchSellerReminderDetails: build.query<
                {
                    resource_entity: string;
                    resource_entity_name: string;
                    action_time: string;
                }[],
                { rfq_entry_id: string }
            >({
                queryFn: async ({ rfq_entry_id }) => {
                    try {
                        const response: any = await get<any>(
                            `/events/rfq/${rfq_entry_id}/sellers/remind/latest/`
                        );
                        const data: {
                            resource_entity: string;
                            resource_entity_name: string;
                            action_time: string;
                        }[] = response.data;
                        if (data) {
                            return {
                                data: data,
                            };
                        }
                        return {
                            data: response,
                        };
                    } catch (err: any) {
                        return { error: err };
                    }
                },
            }),
        }),
    });

export const fetchRFQItemList = (
    event_id: string
): Promise<IEventItemDetails[]> => {
    return new Promise<IEventItemDetails[]>(async (resolve, reject) => {
        try {
            let resp = await get<IEventItemDetails[]>(
                `/events/rfq/${event_id}/items/?enterprise_type=BUYER`
            );
            resp.data.forEach((item) => {
                const itemAsAny = item as any;
                item.paymentTerms = transformPaymentTermsFromBackendToFrontend({
                    prepayment_percentage: itemAsAny.prepayment_percentage,
                    payment_terms: itemAsAny.payment_terms,
                    deliverables_payment_terms:
                        itemAsAny.deliverables_payment_terms,
                });
            });
            JsonNumsToStrings(resp.data);
            resolve(resp.data);
        } catch (err) {
            reject();
        }
    });
};

export const getAvailableApprovers = async (
    rfqId: string
): Promise<types.IGetAvailableApproversResponse> => {
    const getAvailableApproversResponse = await get<any>(
        `/events/rfq/${rfqId}/next_approvers/`
    );
    const availableApproversList: any[] = getAvailableApproversResponse.data;
    const availableApprovers: types.IAvailableApprover[] = [];
    availableApproversList.forEach((availableApprover) => {
        availableApprovers.push({
            userId: availableApprover.user_id,
            userName: availableApprover.username,
            name: availableApprover.name,
            userRole: availableApprover.user_role,
        });
    });
    return {
        availableApprovers: availableApprovers,
    };
};

export const getEventApprovals = async (
    rfqId: string
): Promise<types.IEventApprovalsResponse> => {
    const eventApprovalsResponse = await get<any>(
        `/events/rfq/${rfqId}/approvers/`
    );
    const eventApprovalsList: any[] = eventApprovalsResponse.data;
    const eventApprovals: types.IEventApproval[] = [];

    eventApprovalsList.forEach((eventApproval) => {
        eventApprovals.push({
            requestorId: eventApproval.requestor,
            requestorUserFullName: eventApproval.requestor_name,
            requestorNotes: eventApproval.requestor_notes,
            approverId: eventApproval.approver,
            approverUserFullName: eventApproval.approver_name,
            approverNotes: eventApproval.approver_notes,
            status: eventApproval.status,
        });
    });
    return {
        eventApprovals: eventApprovals,
    };
};

export const deletePOGroup = async (sub_event_id: string): Promise<void> => {
    await deletePoGroupApi(sub_event_id);
};

export const deleteEvent = async (subEventId: string): Promise<void> => {
    await del<void>(`/events/rfq/${subEventId}/delete/`);
};

export const sendRfqForApproval = async (
    rfqId: string,
    notes: string
): Promise<void> => {
    const request = {
        notes: notes,
        status: 'APPROVAL_PENDING',
    };
    await put<string, any>(`/events/rfq/${rfqId}/state/`, request);
};

export const rejectEvent = async (
    rfqId: string,
    approverId: string,
    notes: string
): Promise<void> => {
    const request = {
        approver: approverId,
        notes: notes,
        status: 'REJECTED',
    };
    await post<string, any>(`/events/rfq/${rfqId}/state/`, request);
};

export const sendForRework = async (
    rfqId: string,
    approverId: string,
    notes: string
): Promise<void> => {
    const request = {
        approver: approverId,
        notes: notes,
        status: 'REWORK',
    };
    await put<string, any>(`/events/rfq/${rfqId}/state/`, request);
};

export const approveAndSubmit = async (
    rfqId: string,
    approverId: string,
    notes: string
): Promise<void> => {
    const request = {
        approver: approverId,
        notes: notes,
        status: 'ONGOING',
    };
    await put<string, any>(`/events/rfq/${rfqId}/state/`, request);
};

export const tautology = (): Promise<boolean> => {
    return new Promise(async (resolve, reject) => {
        resolve(true);
    });
};

export const getApprovalButtonBools = (
    current_user_id: string,
    event_creator: string | null,
    rfq_entry_id: string,
    event_status: string,
    existingApprovers: IEventApproval[]
): Promise<{
    sendForApproval: boolean;
    cancelApproval: boolean;
    approveAndSubmit: boolean;
    reject: boolean;
    rework: boolean;
}> => {
    return new Promise(async (resolve, reject) => {
        //CREATOR - already in creator hierarchy
        let promise_creatorHierarchy;
        if (event_creator !== null && current_user_id === event_creator) {
            promise_creatorHierarchy = tautology();
        } else {
            promise_creatorHierarchy = userIsInCreatorHierarchy(
                rfq_entry_id,
                current_user_id
            );
        }
        let promise_canApprove = canUserApproveEvent(rfq_entry_id);
        if (event_status === 'REJECTED') {
            //No actions available for rejected events.
            resolve({
                sendForApproval: false,
                cancelApproval: false,
                approveAndSubmit: false,
                reject: false,
                rework: false,
            });
        } else if (['DRAFT', 'REWORK'].includes(event_status)) {
            //DRAFT, REWORK - only need to check creator hierarchy, and if current user can approve the event.
            Promise.all([promise_creatorHierarchy, promise_canApprove])
                .then((resArr) => {
                    let isInCreatorHierarchy = resArr[0];
                    let userCanApprovePo = resArr[1];
                    let answer = {
                        sendForApproval: false,
                        cancelApproval: false,
                        approveAndSubmit: false,
                        reject: false,
                        rework: false,
                    };
                    if (isInCreatorHierarchy) {
                        if (userCanApprovePo) {
                            answer.approveAndSubmit = true;
                        } else {
                            answer.sendForApproval = true;
                        }
                    }
                    resolve(answer);
                })
                .catch((err) => {
                    reject(err);
                });
        } else if (event_status === 'APPROVAL_PENDING') {
            //APPROVAL_PENDING - also need to check approval hierarchy
            let currentPendingApprover = null;
            let pendingApprovals = existingApprovers.filter(
                (approval) => approval.status === 'PENDING'
            );
            if (pendingApprovals.length > 0) {
                currentPendingApprover = pendingApprovals[0].approverId;
                let promise_approverHierarchy = userIsInApproversHierarchy(
                    currentPendingApprover,
                    current_user_id
                );
                Promise.all([
                    promise_creatorHierarchy,
                    promise_canApprove,
                    promise_approverHierarchy,
                ])
                    .then((resArr) => {
                        let answer = {
                            sendForApproval: false,
                            cancelApproval: false,
                            approveAndSubmit: false,
                            reject: false,
                            rework: false,
                        };
                        let isInCreatorHierarchy = resArr[0];
                        let userCanApprovePo = resArr[1];
                        let isInApproverHierarchy = resArr[2];
                        if (isInCreatorHierarchy) {
                            if (isInApproverHierarchy) {
                                answer.reject = true;
                                answer.rework = true;
                                if (userCanApprovePo) {
                                    answer.approveAndSubmit = true;
                                } else {
                                    answer.sendForApproval = true;
                                }
                            } else {
                                answer.cancelApproval = true;
                            }
                        }
                        resolve(answer);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            }
        }
    });
};

export const userIsInApproversHierarchy = (
    approver_id: string,
    current_user_id: string
): Promise<boolean> => {
    return new Promise(async (resolve, reject) => {
        getFlatUserHierarchy(approver_id)
            .then((approverHierarchy) => {
                for (let hierarchyUser of approverHierarchy) {
                    if (hierarchyUser.user_id === current_user_id) {
                        resolve(true);
                    }
                }
                resolve(false);
            })
            .catch((err) => reject(err));
    });
};

export const canUserApproveEvent = (rfq_event_id: string): Promise<boolean> => {
    return new Promise(async (resolve, reject) => {
        try {
            let resp = await get<{ can_approve: false }>(
                `/events/rfq/${rfq_event_id}/can_approve/`
            );
            resolve(resp.data.can_approve);
        } catch (err) {
            reject();
        }
    });
};

export interface INewUserSharePermissions {
    user_permissions: {
        user_id: string;
        permissions: string[];
    }[];
    access?: 'ACCESS_DEFAULT' | 'ACCESS_RESTRICTED' | null;
}

export const putNewUserSharePermissions = async (
    rfqId: string,
    permission_obj: INewUserSharePermissions
): Promise<void> => {
    await put<string, any>(
        `/events/rfq/${rfqId}/permissions/update/`,
        permission_obj
    );
};

export interface ISharePopupData {
    [key: string]: ISingleUserSharePopupData;
}

export interface ISingleUserSharePopupData {
    event_permission_level: null | 'VIEW' | 'EDIT' | 'ADMIN';
    max_permission_level: null | 'VIEW' | 'EDIT' | 'ADMIN';
    name: string;
}

export const getUserEntityPermissionLevel = (
    entity_id: string,
    user_entity_permissions_arr: IUserPermission[]
): null | 'VIEW' | 'EDIT' | 'ADMIN' => {
    let filtered_permissions_arr = user_entity_permissions_arr.filter(
        (row) =>
            row.entity_id === entity_id &&
            [
                'BUYER_EVENT',
                'BUYER_EVENT_DRAFT_CREATION',
                'BUYER_EVENT_DRAFT_ADMIN',
            ].includes(row.action_api_group)
    );
    if (filtered_permissions_arr.length === 0) {
        return null;
    }

    let editFound = false;
    for (let permission of filtered_permissions_arr) {
        if (permission.action_api_group === 'BUYER_EVENT_DRAFT_ADMIN') {
            return 'ADMIN';
        } else if (
            permission.action_api_group === 'BUYER_EVENT_DRAFT_CREATION'
        ) {
            editFound = true;
        }
    }
    return editFound ? 'EDIT' : 'VIEW';
};

const getUserEventPermission = (
    user_event_permission_arr: IUserEventPermission[]
): null | 'VIEW' | 'EDIT' | 'ADMIN' => {
    if (user_event_permission_arr.length === 0) {
        return null;
    }
    let editFound = false;
    for (let permission of user_event_permission_arr) {
        if (permission.action_api_group === 'BUYER_EVENT_DRAFT_ADMIN') {
            return 'ADMIN';
        } else if (
            permission.action_api_group === 'BUYER_EVENT_DRAFT_CREATION'
        ) {
            editFound = true;
        }
    }
    return editFound ? 'EDIT' : 'VIEW';
};

export const getDraftEventSharingData = (
    sub_event_id: string,
    entity_id: string
): Promise<ISharePopupData> => {
    return new Promise((resolve, reject) => {
        //  fetch all permissions of all users that belong to this enterprise
        let promise1 = get<{ [key: string]: IUserPermission[] }>(
            `/organization/user_permission?all_users=true`
        );

        // fetch list of users already having permission for this event
        let promise2 = get<IAllUserEventPermissions>(
            `/events/rfq/${sub_event_id}/permissions/`
        );

        Promise.all([promise1, promise2])
            .then((res) => {
                let user_permission_data: ISharePopupData = {};
                let user_entity_permissions = res[0].data;
                let user_event_permissions = res[1].data;
                delete user_event_permissions['all_users'];
                let enterprise_users_arr = Object.keys(user_entity_permissions);
                for (let user_id of enterprise_users_arr) {
                    let entity_permission_level = getUserEntityPermissionLevel(
                        entity_id,
                        user_entity_permissions[user_id]
                    );
                    if (entity_permission_level !== null) {
                        let currentEventPermission = null;
                        if (user_id in user_event_permissions) {
                            currentEventPermission = getUserEventPermission(
                                user_event_permissions[user_id]
                            );
                        }

                        user_permission_data[user_id] = {
                            event_permission_level: currentEventPermission,
                            max_permission_level: entity_permission_level,
                            name:
                                user_entity_permissions[user_id][0].name ??
                                'John Doe',
                        };
                    }
                }
                resolve(user_permission_data);
            })
            .catch((err) => {
                reject();
            });
    });
};
