import { Box } from '@mui/material';
import React from 'react';
import FWPopover from '../../../../Common/FWPopover';
import { FWTypography } from '../../../../Common/FWTypograhy';

export default function ItemsLinkAndPopover({
    event_items,
    item_count,
    openPopup,
    setOpenPopup,
    anchorEl,
}: {
    event_items: {
        buyer_item_id: string;
        buyer_item_name: string;
        custom_item_name: string;
    }[];
    item_count: number;
    openPopup: boolean;
    anchorEl: HTMLElement | null;
    setOpenPopup: React.Dispatch<React.SetStateAction<boolean>>;
}) {
    return (
        <>
            {event_items && event_items.length === item_count && (
                <Box
                    style={{
                        margin: '0',
                        textAlign: 'left',
                    }}
                    color="primary.main"
                    display={'flex'}
                    justifyContent={'flex-start'}
                >
                    <FWPopover
                        open={openPopup}
                        anchorEl={anchorEl}
                        onClose={() => setOpenPopup(false)}
                        sx={{ height: '290px' }}
                    >
                        {event_items.length === 0 ? (
                            <>
                                <FWTypography color="text.secondary">
                                    No items added
                                </FWTypography>
                            </>
                        ) : (
                            <>
                                {event_items.map((item, index) => (
                                    <FWTypography key={index}>
                                        {index + 1}. {item.buyer_item_name}
                                    </FWTypography>
                                ))}
                            </>
                        )}
                    </FWPopover>
                </Box>
            )}
        </>
    );
}
