import { useContext } from 'react';
import { Box, IconButton, styled, Typography } from '@mui/material';
import { FWPopupWithChildren } from '../../Common/FWPopupWithChildren';
// import onboardingBg from '../../Assets/Images/OnboardingImages/obBg.png';
import adminSetupImg from '../../Assets/Images/OnboardingImages/adminSetupImg.svg';
import eventD2POImg from '../../Assets/Images/OnboardingImages/eventD2POImg.svg';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { OnboardingVideoPopupType } from '../Interfaces/OnboardingActionTypes';
import { FWButton } from '../../Common/FWButton';
import { AuthContext } from '../../Contexts/AuthContext';
import { InvitationType } from '../../Models/UserDetails.model';
import { updateOnboardingInformation } from '../../Organizations/Admin/Services/EnterpriseService';

// const StyledBgImg = styled('img')(() => ({
//     position: 'absolute',
//     zIndex: 0,
//     top: 0,
//     right: 0,
// }));

const StyledImg = styled('img')(() => ({
    height: '160px',
    borderRadius: '5px',
}));

const OnboardingPopup = ({
    open,
    setOpen,
    setVideoPopupOpen,
}: {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setVideoPopupOpen: React.Dispatch<
        React.SetStateAction<OnboardingVideoPopupType | null>
    >;
}) => {
    const history = useHistory();

    const [dontShowAgain, setDontShowAgain] = React.useState(true);

    const { authData, checkPermission } = useContext(AuthContext);

    const userSeenOnboardingPopup = () => {
        if (authData.details && authData.details.user_id) {
            localStorage.setItem(
                'showOnboardingOnRefresh',
                JSON.stringify({
                    [authData?.details?.user_id]: true,
                })
            );
        }
    };

    const isSeller =
        authData.details &&
        authData.details.invitation_type &&
        (authData.details.invitation_type ===
            InvitationType.JOIN_ORGANIZATION_VENDOR_INVITE ||
            authData.details.invitation_type ===
                InvitationType.VENDOR_CONTACT_INVITE ||
            authData.details.invitation_type ===
                InvitationType.VENDOR_ENTERPRISE_INVITE);

    const options = {
        adminSetup: checkPermission('GLOBAL_ADMIN', null, null, null),
        createEvent:
            !isSeller && checkPermission('BUYER', 'EVENT', 'EDIT', null),
        createPO:
            !isSeller &&
            checkPermission('BUYER', 'PURCHASE_ORDER', 'EDIT', null),
    };

    const optionsCount =
        (options.adminSetup ? 1 : 0) +
        (options.createEvent ? 1 : 0) +
        (options.createPO ? 1 : 0);

    const leftSideAvailable = options.adminSetup;
    const rightSideAvailable = options.createEvent || options.createPO;

    return (
        <FWPopupWithChildren
            hideHeader
            title=""
            open={open && optionsCount > 0}
            handleClose={() => {
                userSeenOnboardingPopup();
                setOpen(false);
            }}
            size={leftSideAvailable && rightSideAvailable ? 'large' : 'small'}
            sx={{ zIndex: 1000 }}
        >
            <Box padding={5}>
                {/* <StyledBgImg src={onboardingBg} alt="onboardingBg" /> */}
                {/* Adding box to fix indexing with the bg img */}
                <Box position={'relative'}>
                    <Box className="flex flex--jcsb flex--aic">
                        <Typography variant={'h3'} fontWeight={'bold'}>
                            Begin your journey
                        </Typography>
                        <IconButton
                            size="large"
                            onClick={() => {
                                userSeenOnboardingPopup();
                                setOpen(false);
                            }}
                        >
                            {/* <Box color={'primary.main'}> */}
                            <i className="bi bi-x" />
                            {/* </Box> */}
                        </IconButton>
                    </Box>
                    <br />
                    {optionsCount > 1 && (
                        <>
                            <Typography
                                color={'secondary.main'}
                                fontSize={20}
                                fontWeight={'bold'}
                            >
                                What do you want to get started with?
                            </Typography>
                            <br />
                            <br />
                        </>
                    )}
                    <Box className="flex flex--jcsb" height={350}>
                        {options.adminSetup && (
                            <Box
                                className="flex flex--fdc"
                                // border={'1px solid gray'}
                                width={rightSideAvailable ? '48%' : '96%'}
                                borderRadius={1}
                                padding={3}
                                sx={{ backgroundColor: '#F2F7FE' }}
                            >
                                <StyledImg
                                    src={adminSetupImg}
                                    alt="adminSetupBg"
                                />
                                <br />
                                <Box className="flex flex--aic">
                                    <Typography
                                        color={'primary.main'}
                                        fontSize={20}
                                        fontWeight={500}
                                        sx={{
                                            '&:hover': {
                                                cursor: 'pointer',
                                                textDecoration: 'underline',
                                            },
                                        }}
                                        onClick={() => {
                                            // if (dontShowAgain) {
                                            //     localStorage.setItem(
                                            //         'dontShowAgain',
                                            //         'true'
                                            //     );
                                            // }
                                            history.push({
                                                pathname: '/admin/home',
                                                state: { fromOnboarding: true },
                                            });
                                            userSeenOnboardingPopup();
                                            setOpen(false);

                                            // added timeout so that the video popup comes before the Setup Information popup
                                            setTimeout(() => {
                                                setVideoPopupOpen(
                                                    'ADMIN_SETUP'
                                                );
                                            }, 100);
                                        }}
                                    >
                                        Admin set-up
                                    </Typography>
                                    <Box
                                        color={'primary.main'}
                                        fontSize={'1rem'}
                                        marginLeft={0.5}
                                    >
                                        <i
                                            style={{ WebkitTextStroke: 1 }}
                                            className="bi bi-play-circle"
                                        />
                                    </Box>
                                </Box>
                                <Typography
                                    color={'secondary.main'}
                                    fontSize={15}
                                >
                                    Takes about 10 minutes
                                </Typography>
                            </Box>
                        )}
                        {(options.createEvent || options.createPO) && (
                            <>
                                <Box
                                    className="flex flex--fdc"
                                    // border={'1px solid gray'}
                                    width={leftSideAvailable ? '48%' : '96%'}
                                    borderRadius={1}
                                    padding={3}
                                    sx={{ backgroundColor: '#F2F7FE' }}
                                >
                                    <StyledImg
                                        src={eventD2POImg}
                                        alt="eventAndPoBg"
                                    />
                                    <br />
                                    {options.createEvent && (
                                        <>
                                            <Box className="flex flex--aic">
                                                <Typography
                                                    color={'primary.main'}
                                                    fontSize={20}
                                                    fontWeight={500}
                                                    sx={{
                                                        '&:hover': {
                                                            cursor: 'pointer',
                                                            textDecoration:
                                                                'underline',
                                                        },
                                                    }}
                                                    onClick={() => {
                                                        // if (dontShowAgain) {
                                                        //     localStorage.setItem(
                                                        //         'dontShowAgain',
                                                        //         'true'
                                                        //     );
                                                        // }
                                                        history.push({
                                                            pathname:
                                                                '/buyer/events/create',
                                                            state: {
                                                                fromOnboarding:
                                                                    true,
                                                            },
                                                        });
                                                        userSeenOnboardingPopup();
                                                        setOpen(false);

                                                        setVideoPopupOpen(
                                                            'CREATE_EVENT'
                                                        );
                                                    }}
                                                >
                                                    Create an event
                                                </Typography>
                                                <Box
                                                    color={'primary.main'}
                                                    fontSize={'1rem'}
                                                    marginLeft={0.5}
                                                >
                                                    <i
                                                        style={{
                                                            WebkitTextStroke: 1,
                                                        }}
                                                        className="bi bi-play-circle"
                                                    />
                                                </Box>
                                            </Box>
                                            <Typography
                                                color={'secondary.main'}
                                                fontSize={15}
                                            >
                                                Takes about 2 minutes
                                            </Typography>
                                        </>
                                    )}
                                    {options.createEvent &&
                                        options.createPO && <br />}

                                    {options.createPO && (
                                        <>
                                            <Box className="flex flex--aic">
                                                <Typography
                                                    color={'primary.main'}
                                                    fontSize={20}
                                                    fontWeight={500}
                                                    sx={{
                                                        '&:hover': {
                                                            cursor: 'pointer',
                                                            textDecoration:
                                                                'underline',
                                                        },
                                                    }}
                                                    onClick={() => {
                                                        // if (dontShowAgain) {
                                                        //     localStorage.setItem(
                                                        //         'dontShowAgain',
                                                        //         'true'
                                                        //     );
                                                        // }
                                                        history.push({
                                                            pathname:
                                                                '/buyer/purchase_orders/direct/create',
                                                            state: {
                                                                fromOnboarding:
                                                                    true,
                                                            },
                                                        });
                                                        userSeenOnboardingPopup();
                                                        setOpen(false);

                                                        setVideoPopupOpen(
                                                            'CREATE_PO'
                                                        );
                                                    }}
                                                >
                                                    Create a direct purchase
                                                    order
                                                </Typography>
                                                <Box
                                                    color={'primary.main'}
                                                    fontSize={'1rem'}
                                                    marginLeft={0.5}
                                                >
                                                    <i
                                                        style={{
                                                            WebkitTextStroke: 1,
                                                        }}
                                                        className="bi bi-play-circle"
                                                    />
                                                </Box>
                                            </Box>
                                            <Typography
                                                color={'secondary.main'}
                                                fontSize={15}
                                            >
                                                Takes about 2 minutes
                                            </Typography>
                                        </>
                                    )}
                                </Box>
                            </>
                        )}
                    </Box>
                    <br />
                    <br />
                    <Box className="flex flex--aic flex--jcsb">
                        <Box>
                            <input
                                type="checkbox"
                                style={{ scale: '1.2' }}
                                checked={dontShowAgain}
                                onChange={(e) =>
                                    setDontShowAgain(e.target.checked)
                                }
                            />
                            <Typography
                                display={'inline'}
                                fontWeight={500}
                                color={'secondary.main'}
                                sx={{ cursor: 'pointer' }}
                                onClick={() => setDontShowAgain(!dontShowAgain)}
                            >
                                &nbsp; Do not show this popup again
                            </Typography>
                        </Box>
                        <FWButton
                            variant="contained"
                            onClick={() => {
                                setTimeout(() => {
                                    setOpen(false);
                                }, 1000);

                                if (dontShowAgain) {
                                    updateOnboardingInformation({
                                        onboarding_information: {
                                            onboarding: false,
                                            welcome_pop_up: false,
                                            checklist:
                                                authData?.details
                                                    ?.onboarding_information
                                                    ?.checklist ?? false,
                                        },
                                    });
                                } else {
                                    userSeenOnboardingPopup();
                                }
                            }}
                        >
                            Close
                        </FWButton>
                    </Box>
                </Box>
            </Box>
        </FWPopupWithChildren>
    );
};

export default OnboardingPopup;
