import { Box, Typography } from '@mui/material';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { FWLink } from '../Common/FWLink';
import { FWTypography } from '../Common/FWTypograhy';
import UserProfileContainer from '../Users/Profiles/Container/UserProfileContainer';
import { postResendVerificationEmail } from '../Users/Profiles/services/userProfile.service';
import Navbar from './Navbar';

export function NoPermissionsPopup() {
    return (
        <Box>
            <Navbar />
            <Box className="flex flex--aic flex--col" marginTop={'3rem'}>
                <Typography fontSize="24px" fontWeight={500}>
                    Awaiting admin setup
                </Typography>
                <Typography>
                    Seems like your permissions havent been set up, please
                    contact your ADMIN.
                </Typography>
            </Box>
        </Box>
    );
}

export function PendingAdminRequestPopup({
    entity_name,
    email,
    username,
}: {
    entity_name: string;
    email: string;
    username: string;
}) {
    return (
        <Box>
            <Navbar />
            <Box className="flex flex--aic flex--col" marginTop={'3rem'}>
                <Typography fontSize="24px" fontWeight={500}>
                    Awaiting admin approval
                </Typography>
                <Typography>
                    Your request to join {entity_name} as {username} is not yet
                    approved.
                </Typography>
                <Typography>
                    You'll receive an email on {email} when your request is
                    approved.
                </Typography>
            </Box>
        </Box>
    );
}

export function InactiveAccountPopup() {
    return (
        <Box>
            <Navbar />
            <Box className="flex flex--aic flex--col" marginTop={'3rem'}>
                <Typography fontSize="24px" fontWeight={500}>
                    Account disabled
                </Typography>
                <Typography>
                    Your account has been disabled, please contact your admin to
                    enable your account.
                </Typography>
            </Box>
        </Box>
    );
}

export function UnverifiedEmailPopup(props: {
    email: string;
    user_id: string;
}) {
    const [disableButton, setDisableButton] = useState(false);

    const resendVerificationEmail = async () => {
        postResendVerificationEmail(props.user_id, props.email)
            .then((res) => {
                if (res) {
                    toast.success('Verification email sent');
                    setDisableButton(true);
                } else {
                    toast.error('Sorry, we faced some error');
                    setDisableButton(false);
                }
            })
            .catch((err) => {
                toast.error('Sorry, we faced some error');
                setDisableButton(false);
            });
    };

    return (
        <Box>
            <Navbar />
            <Box className="flex flex--aic flex--col" marginTop={'3rem'}>
                <Typography fontSize="24px" fontWeight={500}>
                    Awaiting email verification
                </Typography>
                <Typography>Seems like your email isn't verified</Typography>
                <Typography>
                    Please check your inbox ({props.email}) for a verification
                    link.
                </Typography>
                <br />
                {disableButton ? (
                    <FWTypography color="success.main">
                        Email sent successfully
                    </FWTypography>
                ) : (
                    <FWLink onClick={resendVerificationEmail}>
                        Resend Verification Email
                    </FWLink>
                )}
            </Box>
        </Box>
    );
}

export function NoHomePage({ user_id }: { user_id: string }) {
    return (
        <Box>
            <Navbar hideVerificationChip={true} />
            <Box marginTop={'2rem'}>
                <FWTypography
                    variant="h4"
                    textAlign="center"
                    color="text.primary"
                >
                    Setup your account
                </FWTypography>
            </Box>
            <UserProfileContainer user_id={user_id} />
        </Box>
    );
}
