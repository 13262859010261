import { Box, FormControl, FormHelperText } from '@mui/material';
import React from 'react';
import { FWMenuItem } from '../../../Common/FWCustomMenu';
import { FWInput } from '../../../Common/FWInput';
import FWSelect from '../../../Common/FWSelect';
import { IMeasurementUnitDetails } from '../../../Models/MeasurementUnit.model';
import { updateEventItemDetailsActionType } from '../../Interfaces/Buyer/RFQ.model';

interface IQuantityAndDSCombinedFieldProps {
    quantity: string;
    measurement_unit_id: string;
    measurement_units: IMeasurementUnitDetails[];
    updateItemData: (
        params: {
            field: updateEventItemDetailsActionType;
            value: any;
        }[]
    ) => void;
    error: boolean;
    helperText: string;
    disabled?: boolean;
    setOpenDS?: React.Dispatch<React.SetStateAction<boolean>>;
    DSLength?: number;
}

export default function QuantityAndDSCombinedField(
    props: IQuantityAndDSCombinedFieldProps
) {
    // Save measurement unit name on page load so that unit is updated for other fields
    // in case of measurement unit change in admin
    // const { measurement_units, updateItemData } = props;
    // const isMounted = React.useRef(false);
    // React.useEffect(() => {
    //     if (!isMounted.current) {
    //         isMounted.current = true;
    //         if (measurement_units.length === 1) {
    //             updateItemData([
    //                 {
    //                     field: 'measurement_unit_name',
    //                     value: measurement_units[0].measurement_unit_primary_name,
    //                 },
    //             ]);
    //         }
    //     }
    // }, [measurement_units, updateItemData]);

    return (
        <Box>
            <Box
                display={'grid'}
                gridTemplateColumns="60% 40%"
                alignItems={'flex-end'}
            >
                <FWInput
                    disabled={props.disabled}
                    required
                    allowOnly={'DECIMAL_NUMBER'}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            borderRadius: '12px 0 0 12px',
                        },
                    }}
                    value={props.quantity}
                    onChange={(e) => {
                        props.updateItemData([
                            { field: 'quantity', value: e.target.value },
                        ]);
                    }}
                    error={props.error}
                />
                {props.measurement_units &&
                props.measurement_units.length === 1 ? (
                    <FWInput
                        sx={{
                            '& .MuiInputBase-root': {
                                borderRadius: '0px 12px 12px 0',
                            },
                            minWidth: '75px',
                            pointerEvents: 'none',
                        }}
                        readOnly
                        value={`${props.measurement_units[0].measurement_unit_primary_name}(s)`}
                    />
                ) : (
                    <FormControl
                        sx={{
                            '& .MuiInputBase-root': {
                                borderRadius: '0px 12px 12px 0',
                            },
                            minWidth: '75px',
                        }}
                        fullWidth
                        required
                        error={props.error}
                        disabled={props.disabled}
                    >
                        <FWSelect
                            value={props.measurement_unit_id}
                            onChange={(e) =>
                                props.updateItemData([
                                    {
                                        field: 'measurement_unit_id',
                                        value: e.target.value,
                                    },
                                    {
                                        field: 'measurement_unit_name',
                                        value: props.measurement_units.find(
                                            (unit) =>
                                                unit.measurement_unit_id ===
                                                e.target.value
                                        )!.measurement_unit_primary_name,
                                    },
                                ])
                            }
                        >
                            {props.measurement_units.map((unit, idx) => (
                                <FWMenuItem
                                    key={idx}
                                    value={unit.measurement_unit_id}
                                >
                                    {unit.measurement_unit_primary_name.endsWith(
                                        's'
                                    )
                                        ? unit.measurement_unit_primary_name
                                        : `${unit.measurement_unit_primary_name}(s)`}
                                </FWMenuItem>
                            ))}
                        </FWSelect>
                    </FormControl>
                )}
            </Box>
            {props.error && (
                <FormHelperText
                    sx={{ color: 'error.main', fontSize: '0.8rem' }}
                >
                    {props.helperText}
                </FormHelperText>
            )}
        </Box>
    );
}
