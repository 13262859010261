import { Grid, IconButton, InputLabel } from '@mui/material';
import { ColumnApi, GridReadyEvent, RowNode } from 'ag-grid-community';
import {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { useSelector } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';
import { CustomFormControl } from '../../../../Common/FWCustomInputs';
import { FWMenuItem } from '../../../../Common/FWCustomMenu';
import { FWTooltip } from '../../../../Common/FWCustomTooltip';
import FWIcon from '../../../../Common/FWIcon';
import { FWInput } from '../../../../Common/FWInput';
import FWSelect from '../../../../Common/FWSelect';
import {
    IFileResourceType,
    IResourceFields,
} from '../../../../Components/Shared/UploadFile';
import BulkImportPopup from '../../../../Global/Containers/BulkImportPopup';
import { IGlobalAppStore } from '../../../../Redux/Store';

import { cloneDeep, debounce, isEqual } from 'lodash';
import { toast } from 'react-toastify';
import { AuthContext } from '../../../../Contexts/AuthContext';
import { useGetListOfTemplatesForEntityQuery } from '../../../../Global/Helpers/templateApiSlice';
import { getBulkImportTemplateColumns } from '../../../../Global/Services/BulkImportService';
import { PaymentsItemPopup } from '../../../../Payments/Components/PaymentsItemPopup';
import ItemDirectoryDetailsPopover from '../../Components/ItemDirectory/ItemDetailsPopover';
import ItemDirectoryEntitiesPopover from '../../Components/ItemDirectory/ItemEntitiesPopover';
import PageHeader, { HeaderType } from '../../Components/PageHeader';
import {
    itemAdminFieldTypeLabel,
    itemsListColumns,
} from '../../Constants/ItemDirectoryConstants';
import {
    IEntityListProviders,
    useEntitiesList,
} from '../../Hooks/EnterpriseHook';
import { useDownloadItemsAndVendors } from '../../Hooks/ItemDirectory/ItemDirectoryHook';
import {
    IItemMeasurementUnits,
    IItemSummary,
    ItemEntityStatus,
    ItemStatus,
    PreferredVendorAssignedStatus,
} from '../../Interfaces/ItemDirectoryInterface';
import {
    fetchItemMeasurements,
    fetchItemsList,
} from '../../Services/ItemDirectoryService';
import { IItemUpdateValues } from './ItemsListOptionsMenu';
import FWDashboardDataGrid from '../../../../Common/FWDashboardDataGrid';
import { FWTypography } from '../../../../Common/FWTypograhy';
import { AgGridReact } from 'ag-grid-react';
import {
    DashBoardFilter,
    DashboardSort,
} from '../../../../ProjectGlCostCenter/interface/project.model';
import { BLANK_DASHBOARD_SORT } from '../../../../ProjectGlCostCenter/constants/projectDashboardSortConstants';
import { BLANK_DASHBOARD_FILTER } from '../../../../ProjectGlCostCenter/constants/projectDashboardConstants';
import { GridApi } from '@mui/x-data-grid';
import {
    ItemsAdminBuiltInFieldsMap,
    ItemsAdminFilterFieldToNameMap,
} from './ItemsAdminBuiltIn';

export type sortFields =
    | 'itemCode'
    | 'name'
    | 'preferredVendors'
    | 'entities'
    | 'modifiedDateTime';

export enum ItemCellActions {
    NONE = 'NONE',
    NAME = 'NAME',
    ENTITY = 'ENTITY',
    ENTER = 'ENTER',
    LEAVE = 'LEAVE',
}
// const sortColumnsNames = {
//     itemCode: 'code',
//     name: 'name',
//     preferredVendors: 'preferred_vendor_status',
//     entities: 'entity_count',
//     modifiedDateTime: 'modified_datetime',
// };

enum resourceFields {
    MEASUREMENT_UNIT = 'Measurement unit',
    IDENTIFIER_NAME = 'Identifier name',
    SPECIFICATION_NAME = 'Specification name',
    PREFERRED_VENDOR_CODE = 'Preferred vendor code',
    TAGS = 'Tags',
}

const initialTemplateDownloadValues: IResourceFields[] = [
    // { key: resourceFields.MEASUREMENT_UNIT, value: 2 },
    // { key: resourceFields.IDENTIFIER_NAME, value: 2 },
    // { key: resourceFields.SPECIFICATION_NAME, value: 2 },
    { key: resourceFields.PREFERRED_VENDOR_CODE, value: 2 },
    // { key: resourceFields.TAGS, value: 1 },
];

const initialSelected: IItemSummary = {
    id: '',
    itemCode: '',
    name: '',
    entities: [],
    entities_id: [],
    locked: false,
    lastPrice: '',
    description: '',
    status: ItemStatus.ACTIVE,
    specifications: [],
    measurementUnits: [],
    notes: '',
    tags: [],
    customIds: [],
    preferredVendorAssignedStatus: PreferredVendorAssignedStatus.ASSIGNED,
    preferredVendorsCount: 0,
    preferredVendors: [],
    entityStatus: ItemEntityStatus.ACTIVE,
    modifiedDateTime: '',
    buyerPricingInformation: null,
    sellerPricingInformation: null,
    isBuyer: false,
    isSeller: false,
    bom_template: null,
};

const ItemsListPage = () => {
    const history = useHistory();
    const currentPath = history.location.pathname;
    useEffect(() => {
        if (currentPath === '/admin/items') {
            history.replace('/admin/items/item-directory');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [rowData, setRowData] = useState<IItemSummary[] | null>(null);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [searchText, setSearchText] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const SINGLE_ENTITY = useSelector(
        (store: IGlobalAppStore) => store.VerificationStore.hasSignleEntity
    );
    const gridRef = useRef<AgGridReact | null>(null);
    const [showRefreshIcon, setShowRefreshIcon] = useState(false);
    const [gridColumnApi, setGridColumnApi] = useState<ColumnApi | null>(null);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [gridApi] = useState<GridApi | undefined>(undefined);
    const [totalItems, setTotalItems] = useState<number>(0);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [isLoading, setIsLoading] = useState(false);

    const [sortField, setSortField] = useState<DashboardSort[]>([
        BLANK_DASHBOARD_SORT,
    ]);
    const [dashboardFilter, setDashboardFilter] =
        useState<DashBoardFilter | null>({
            and_: [
                {
                    and_: [],
                    or_: [],
                    single: BLANK_DASHBOARD_FILTER,
                },
            ],
            or_: [],
            single: null,
        });

    const { entities }: IEntityListProviders = useEntitiesList();
    const { authData } = useContext(AuthContext);

    const { ItemsOrBuyersOrVendorsListCSV } =
        useDownloadItemsAndVendors('ITEM');

    const cookieName: string = useMemo(() => {
        return authData.details?.name
            ? `${authData.details?.name}Admin - dashboard`
            : 'Admin-dashboard';
    }, [authData.details?.name]);

    const dashboardFilterKey = useMemo(() => {
        return `${cookieName}-Filters`;
    }, [cookieName]);

    const dashboardSortKey = useMemo(() => {
        return `${cookieName}-Sort`;
    }, [cookieName]);

    const [selectedItem, setSelectedItem] = useState<IItemSummary>({
        ...initialSelected,
    });

    const [filterBy, setFilterBy] = useState<ItemStatus>(ItemStatus.ACTIVE);
    const [columnApi] = useState<ColumnApi | null>(null);

    const tab =
        filterBy === ItemStatus.ACTIVE
            ? 'active'
            : filterBy === ItemStatus.INACTIVE
            ? 'disabled'
            : 'all';

    const [allMeasurementUnits, setAllMeasurementUnits] =
        useState<IItemMeasurementUnits>({});

    const [open, setOpen] = useState<boolean>(false);

    const [itemDetails, setItemDetails] = useState<IItemSummary | null>(null);

    const getItemMeasurements = useCallback(async () => {
        try {
            const resp = await fetchItemMeasurements();
            setAllMeasurementUnits(resp);
        } catch (err) {}
    }, []);

    useEffect(() => {
        getItemMeasurements();
    }, [getItemMeasurements]);

    const hitFetchAPI = useCallback(async () => {
        // Construct the payload
        const payload = {
            tab: tab,
            searchText: searchText,
            itemsPerPage: rowsPerPage,
            pageNumber: currentPage,
            sortFields: sortField,
            filters: dashboardFilter, // Use the merged filters
        };
        setIsLoading(true); // Set loading to true before API call
        try {
            const resp = await fetchItemsList(payload);
            return resp;
        } catch (error) {
            console.error('Error fetching data: ', error);
            throw error;
        } finally {
            setIsLoading(false); // Set loading to false after API call completes
        }
    }, [currentPage, dashboardFilter, rowsPerPage, searchText, sortField, tab]);

    const fetchData = useCallback(async () => {
        try {
            if (
                !(sortField.length === 1 && !Boolean(sortField[0].field)) &&
                !isEqual(dashboardFilter?.single, BLANK_DASHBOARD_FILTER)
            ) {
                let resp = await hitFetchAPI();
                setCurrentPage((prev) => {
                    if (prev > resp.pageDetails.totalPages) {
                        return 1;
                    } else {
                        return prev;
                    }
                });
                setRowData(resp.items);
                setTotalPages(resp.pageDetails.totalPages);
                setTotalCount(resp.pageDetails.total_count);
            }
        } catch (error) {
            console.error('Fetch data error: ', error); // Log the actual error
            toast.error(`Some error occurred: ${error}`);
        }
    }, [dashboardFilter?.single, hitFetchAPI, sortField]);

    useEffect(() => {
        setTotalItems(totalCount);
    }, [totalCount]);

    // useEffect(() => {
    //     // setRowData(null);
    //     setCurrentPage(1);

    //     fetchData(1, tab, searchText, [], dashboardFilter);
    // }, [dashboardFilter, fetchData, searchText, tab, sortField]);

    // //MrTea:Made a seprate useEffect so that it runs on page change only.
    // //Otherwise the data is loaded on the previous set page when other datas is changed
    // useEffect(
    //     () => {
    //         // setRowData(null);
    //         fetchData(currentPage, tab, searchText, sortField, dashboardFilter);
    //     },
    //     // disabled because we want to run only for page change
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    //     [currentPage]
    // );

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    useEffect(() => {
        // setRowData(null);
        setCurrentPage(1);
        fetchData();
        // disabled because we want to run only for sort columns
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sortField, filterBy]);

    const debouncedSearch = useMemo(
        () =>
            debounce((text: string) => {
                setSearchText(text);
            }, 1000),
        []
    );

    useEffect(() => {
        const interval = setInterval(async () => {
            try {
                let resp = await hitFetchAPI();
                const isListEqual = isEqual(
                    resp.items.map((data, i) => ({ ...data, id: i })),
                    rowData
                );
                setShowRefreshIcon(!isListEqual);
            } catch (err) {}
        }, 1000 * 120);
        return () => {
            clearInterval(interval);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rowData]);

    const onGridReady = useCallback(
        (event: GridReadyEvent) => {
            try {
                // setGridApi(event.api);
                setGridColumnApi(event.columnApi);
                const colData = event.columnApi.getColumnState();
                const sortedColumn = colData?.find((col) => col.sort !== null);
                if (sortedColumn && !sortField) {
                }
            } catch {
                event.api.retryServerSideLoads();
            }
        },
        [setGridColumnApi, sortField]
    );
    const onPageChange = useCallback((newPage: number) => {
        setCurrentPage(newPage);
    }, []);

    const onRowsPerPageChange = useCallback((rowsPerPage: number) => {
        setRowsPerPage(rowsPerPage);
    }, []);

    const onSortFieldChange = useCallback(
        (sortFields: DashboardSort[]) => {
            setSortField(sortFields);
            if (sortFields) {
                localStorage.setItem(
                    dashboardSortKey,
                    JSON.stringify(sortFields)
                );
            } else {
                localStorage.removeItem(dashboardSortKey);
            }
            // setSortField(sortFields);
        },
        [dashboardSortKey]
    );

    const onDashboardFilterChange = useCallback(
        (dashboardFilter: DashBoardFilter | null) => {
            setDashboardFilter(dashboardFilter);
            setCurrentPage(1);
            if (dashboardFilter) {
                localStorage.setItem(
                    dashboardFilterKey,
                    JSON.stringify(dashboardFilter)
                );
            } else {
                localStorage.removeItem(dashboardFilterKey);
            }
        },
        [dashboardFilterKey]
    );

    useEffect(() => {
        const sortDataFromLS = localStorage.getItem(dashboardSortKey);

        if (sortDataFromLS !== null) {
            const parsedSortedData = JSON.parse(sortDataFromLS);
            setSortField(parsedSortedData);
        } else {
            setSortField([]);
        }
    }, [dashboardSortKey]);

    useEffect(() => {
        const filterDataFromLS = localStorage.getItem(dashboardFilterKey);

        if (filterDataFromLS !== null) {
            const parsedFilterData = JSON.parse(filterDataFromLS);
            setDashboardFilter(parsedFilterData);
        } else {
            setDashboardFilter(null);
        }
    }, [dashboardFilterKey]);

    const applyColumnState = useCallback(() => {
        if (gridApi && cookieName && gridColumnApi) {
            // let colState: any = localStorage.getItem(cookieName);
            let filterState: any = localStorage.getItem(`${cookieName}-filter`);
            // if (colState) {
            //     colState = JSON.parse(colState) as ApplyColumnStateParams;
            //     gridColumnApi.applyColumnState({
            //         state: colState,
            //         applyOrder: true,
            //     });
            // }
            if (filterState) {
                filterState = JSON.parse(filterState);
                let _filteredColumns: string[] = [];
                Object.keys(filterState).forEach((key) => {
                    let col = gridColumnApi.getColumn(key);
                    let colData = col?.getColDef();
                    if (col && colData) {
                        _filteredColumns.push(colData.headerName ?? '');
                    }
                });
                // gridApi.setFilterModel(filterState);
            }
        }
    }, [cookieName, gridApi, gridColumnApi]);

    useEffect(() => {
        applyColumnState();
    }, [applyColumnState]);
    const rowClickHandler = ({ data }: { data: IItemSummary }) => {
        if (authData?.details?.role === 'ADMIN') {
            if (rowData && data.status !== ItemStatus.INACTIVE)
                history.push(`/admin/items/item-directory/edit/${data.id}`);
        } else {
            setItemDetails(data);
        }
    };

    useEffect(() => {
        if (itemDetails) {
            setOpen(true);
        } else {
            setOpen(false);
        }
    }, [itemDetails]);

    const handleItemEmitAction = (data: IItemUpdateValues) => {
        fetchData();
    };

    const handleCellHover = (
        type: ItemCellActions,
        row: IItemSummary,
        target: HTMLElement,
        popover: ItemCellActions
    ) => {
        switch (popover) {
            case ItemCellActions.NAME:
                switch (type) {
                    case ItemCellActions.ENTER:
                        setNamePopoverAnchorEl(target);
                        break;
                    case ItemCellActions.LEAVE:
                        setNamePopoverAnchorEl(null);
                        break;
                }
                break;
            case ItemCellActions.ENTITY:
                switch (type) {
                    case ItemCellActions.ENTER:
                        if (row.entities.length)
                            setEntityPopoverAnchorEl(target);
                        break;
                    case ItemCellActions.LEAVE:
                        if (row.entities.length) setEntityPopoverAnchorEl(null);
                        break;
                }
                break;
        }
        setSelectedItem(row);
    };

    const handleFilterChange = (e: any) => {
        setFilterBy(e.target.value);
    };

    const [namePopoverAnchorEl, setNamePopoverAnchorEl] =
        useState<HTMLElement | null>(null);
    const openNamePopover = Boolean(namePopoverAnchorEl);

    const [entityPopoverAnchorEl, setEntityPopoverAnchorEl] =
        useState<HTMLElement | null>(null);
    const openEntityPopover = Boolean(entityPopoverAnchorEl);

    const isExternalFilterPresent = (): boolean => filterBy !== ItemStatus.ALL;

    const doesExternalFilterPass = (node: RowNode): boolean =>
        node.data.status === filterBy;

    const handleEntityChange = (event: any) => {
        const entityId = event.target.value;
        history.push(`/admin/items/item-directory/${entityId}`);
    };

    // useEffect(() => {
    //     if (SINGLE_ENTITY && entities.length) {
    //         history.replace(`/admin/items/item-directory/${entities[0].entityId}`);
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [entities, SINGLE_ENTITY]);

    const [templateDownloadValues, setTemplateDownloadValues] = useState<
        IResourceFields[]
    >(cloneDeep(initialTemplateDownloadValues));

    const [importPopupToggle, setImportPopupToggle] = useState<boolean>(false);

    const handleImportPopupToggle = () => {
        setImportPopupToggle(!importPopupToggle);
        setTemplateDownloadValues(cloneDeep(initialTemplateDownloadValues));
    };

    const handleTemplateDownloadValues = (e: any, index: number) => {
        setTemplateDownloadValues((prevValues) => {
            prevValues[index].value = +e.target.value;
            return [...prevValues];
        });
    };

    const resetTemplateDownloadValues = () => {
        // setTemplateDownloadValues((prevValues) => {
        //     prevValues.map((value) => {
        //         value.value = 2;
        //         return value;
        //     });
        //     return [...prevValues];
        // });
    };

    useEffect(() => {
        if (columnApi && SINGLE_ENTITY) {
            columnApi.setColumnVisible('entities', false);
        }
        if (columnApi && !SINGLE_ENTITY) {
            columnApi.setColumnVisible('entities', true);
        }
    }, [columnApi, SINGLE_ENTITY]);

    const [bulkImportTemplateColumns, setBulkImportTemplateColumns] = useState<
        IResourceFields[]
    >([]);

    const { data: listOfTemplate } = useGetListOfTemplatesForEntityQuery({
        templateType: 'ITEM',
    });

    //  && listOfTemplate.find((item) => item.template_status === 'ONGOING')

    const { templateIdForItems, templateNameForItems } = useMemo(() => {
        if (listOfTemplate) {
            const template = listOfTemplate[0];
            // .find(
            //     (item) => item.template_status === 'ONGOING'
            // );
            return {
                templateIdForItems: template?.template_id,
                templateNameForItems: template?.template_name,
            };
        }
        return {
            templateIdForItems: undefined,
            templateNameForItems: undefined,
        };
    }, [listOfTemplate]);

    const getTemplateColumns = useCallback(async () => {
        if (templateIdForItems)
            try {
                const resp = await getBulkImportTemplateColumns(
                    templateIdForItems
                );

                setBulkImportTemplateColumns(
                    resp
                        .filter((val) => !val.parent_name)
                        .map((val) => ({
                            key: val.alternate_name,
                            value:
                                val.alternate_name === 'Identifier'
                                    ? 1
                                    : val.alternate_name === 'Specification'
                                    ? 1
                                    : val.alternate_name === 'Tag'
                                    ? 1
                                    : val.is_required
                                    ? 1
                                    : 0,
                            isOptional: !val.is_required,
                        }))
                );
            } catch (error) {
                toast.error('Error fetching template columns');
            }
    }, [templateIdForItems]);

    const finalTemplateColumnsHeader = useMemo(() => {
        if (bulkImportTemplateColumns) {
            return [...templateDownloadValues, ...bulkImportTemplateColumns];
        }

        return [...templateDownloadValues];
    }, [templateDownloadValues, bulkImportTemplateColumns]);

    useEffect(() => {
        getTemplateColumns();
    }, [getTemplateColumns]);

    return (
        <>
            <PageHeader
                title={`${
                    SINGLE_ENTITY ? 'Entity' : 'Enterprise'
                } Item Directory`}
                switchEntity={
                    !SINGLE_ENTITY
                        ? {
                              selectedEntity: 'global',
                              entityList: entities,
                          }
                        : undefined
                }
                type={HeaderType.GLOBAL}
                emitEntityChange={handleEntityChange}
                enterprise={!SINGLE_ENTITY}
            />
            {console.log('totalItems', totalItems)}
            <Grid container className="p--20">
                <Grid container>
                    <Grid item xs={12}>
                        <FWDashboardDataGrid
                            // isAdmin
                            currentTab="ITEMS"
                            isLoading={isLoading}
                            onReady={onGridReady}
                            gridRef={gridRef}
                            totalItems={totalItems}
                            // onFilterChanged={(event) => {
                            //     const filtersFromGrid =
                            //         event.api.getFilterModel();
                            //     handleFilterChanged(filtersFromGrid);
                            // }}
                            dashboardFilter={dashboardFilter}
                            sortField={sortField}
                            handleRowsPerPageChange={onRowsPerPageChange}
                            handleFilterApply={onDashboardFilterChange}
                            handleSortApply={onSortFieldChange}
                            dashboardType="itemAdmin"
                            disableFESearch
                            onTextChange={debouncedSearch}
                            fieldTypeLabels={itemAdminFieldTypeLabel(
                                'ITEMS',
                                SINGLE_ENTITY
                            )}
                            builtInFieldsMap={ItemsAdminBuiltInFieldsMap}
                            fixedHeight={560}
                            filterFieldToNameMap={
                                ItemsAdminFilterFieldToNameMap
                            }
                            cookieName={cookieName}
                            defaultColDef={{
                                resizable: true,
                                headerComponent: (props: any) => {
                                    return (
                                        <FWTypography
                                            style={{
                                                color: 'black',
                                                fontWeight: 300,
                                            }}
                                        >
                                            {props.displayName}
                                        </FWTypography>
                                    );
                                },
                            }}
                            showSearchBar
                            rows={rowData}
                            columns={itemsListColumns(
                                authData.details?.role === 'ADMIN',
                                SINGLE_ENTITY
                            )}
                            onRowClicked={rowClickHandler}
                            saveDashboardColumnOrder={true}
                            context={{
                                handleCellHover,
                                handleItemEmitAction,
                                allMeasurementUnits,
                            }}
                            doesExternalFilterPass={doesExternalFilterPass}
                            isExternalFilterPresent={isExternalFilterPresent}
                            getRowClass={({ data }: { data: IItemSummary }) => {
                                return data.status === ItemStatus.INACTIVE
                                    ? 'disabled'
                                    : '';
                            }}
                            suppressPaginationPanel
                            useCustomPagination={totalPages}
                            customPaginationOnChange={(e, pageNumber) => {
                                onPageChange(pageNumber);
                            }}
                            customPaginationPageNumber={currentPage}
                            showRefreshIcon={showRefreshIcon}
                            onRefreshIconClick={() => {
                                setShowRefreshIcon(false);
                                fetchData();
                            }}
                            showFilterColumnMenu
                            CustomeAdmin={
                                <CustomFormControl>
                                    <InputLabel id="item-directory-filter-label">
                                        Filter by:
                                    </InputLabel>
                                    <FWSelect
                                        labelId="item-directory-filter-label"
                                        id="item-directory-filter"
                                        label="Filter by:"
                                        style={{ width: '200px' }}
                                        onChange={handleFilterChange}
                                        value={filterBy}
                                    >
                                        <FWMenuItem value={ItemStatus.ALL}>
                                            All
                                        </FWMenuItem>
                                        <FWMenuItem value={ItemStatus.ACTIVE}>
                                            Active
                                        </FWMenuItem>
                                        <FWMenuItem value={ItemStatus.INACTIVE}>
                                            Disabled
                                        </FWMenuItem>
                                    </FWSelect>
                                </CustomFormControl>
                            }
                            CustomeBulkandPlus={
                                <div className="flex flex--aic">
                                    {authData.details?.role === 'ADMIN' && (
                                        <>
                                            <FWTooltip title="Export Items">
                                                <IconButton
                                                    size="small"
                                                    color="primary"
                                                    onClick={
                                                        ItemsOrBuyersOrVendorsListCSV
                                                    }
                                                    sx={{
                                                        marginRight: '12px',
                                                        fontSize: '24px',
                                                    }}
                                                >
                                                    <i className="bi bi-box-arrow-up"></i>
                                                </IconButton>
                                            </FWTooltip>
                                            <FWTooltip
                                                title="Bulk import"
                                                onClick={
                                                    handleImportPopupToggle
                                                }
                                            >
                                                <IconButton color="primary">
                                                    <FWIcon
                                                        name="bi bi-box-arrow-down"
                                                        size={24}
                                                    />
                                                </IconButton>
                                            </FWTooltip>
                                            <NavLink
                                                to="/admin/items/item-directory/add/new"
                                                style={{
                                                    textDecoration: 'none',
                                                }}
                                            >
                                                <FWTooltip title="Add new item">
                                                    <IconButton color="primary">
                                                        <FWIcon
                                                            name="bi bi-plus-circle"
                                                            size={24}
                                                        />
                                                    </IconButton>
                                                </FWTooltip>
                                            </NavLink>
                                        </>
                                    )}
                                </div>
                            }
                        />
                    </Grid>
                </Grid>
            </Grid>

            <ItemDirectoryDetailsPopover
                data={selectedItem}
                open={openNamePopover}
                anchor={namePopoverAnchorEl}
                handleClose={() => {
                    setNamePopoverAnchorEl(null);
                }}
            />

            <ItemDirectoryEntitiesPopover
                data={selectedItem}
                open={openEntityPopover}
                anchor={entityPopoverAnchorEl}
                handleClose={() => {
                    setEntityPopoverAnchorEl(null);
                }}
            />

            <BulkImportPopup
                templateId={templateIdForItems}
                popupToggle={importPopupToggle}
                title=" Bulk upload: Item Directory"
                helpText="Generate a template per your requirements, fill in data accurately, and then upload it to your Item Directory"
                handlePopupToggle={handleImportPopupToggle}
                resourceType={IFileResourceType.ITEM}
                resource_fields={finalTemplateColumnsHeader}
                onSuccessDownload={resetTemplateDownloadValues}
                label="Attach file for upload"
                templateName={templateNameForItems}
            >
                <>
                    {/* <div className="mb--15 mt--5">
                        <FWInput
                            label="Maximum number of measurement units per item"
                            type="text"
                            allowOnly="NUMBER"
                            style={{ width: '100%' }}
                            value={templateDownloadValues[0]?.value || ''}
                            onChange={(e) => handleTemplateDownloadValues(e, 0)}
                        />
                    </div>
                    <div className="mb--15 mt--5">
                        <FWInput
                            label="Maximum number of identifications per item"
                            type="text"
                            name="item_identification"
                            allowOnly="NUMBER"
                            style={{ width: '100%' }}
                            value={templateDownloadValues[1]?.value || ''}
                            onChange={(e) => handleTemplateDownloadValues(e, 1)}
                        />
                    </div>
                    <div className="mb--15 mt--5">
                        <FWInput
                            label="Maximum number of specifications per item"
                            type="text"
                            name="item_specification"
                            allowOnly="NUMBER"
                            style={{ width: '100%' }}
                            value={templateDownloadValues[2]?.value || ''}
                            onChange={(e) => handleTemplateDownloadValues(e, 2)}
                        />
                    </div> */}

                    <div className="mb--25 mt--5">
                        <FWInput
                            label="Maximum number of preferred vendors per item"
                            type="text"
                            name="preferred_vendors"
                            allowOnly="NUMBER"
                            style={{ width: '100%' }}
                            value={templateDownloadValues[0]?.value || ''}
                            onChange={(e) => handleTemplateDownloadValues(e, 0)}
                        />
                    </div>
                    {/* <div className="mb--25 mt--5">
                        <FWInput
                            label="Maximum number of tags per item"
                            type="text"
                            name="preferred_vendors"
                            allowOnly="NUMBER"
                            style={{ width: '100%' }}
                            value={templateDownloadValues[4]?.value || ''}
                            onChange={(e) => handleTemplateDownloadValues(e, 4)}
                        />
                    </div> */}

                    {bulkImportTemplateColumns.map(({ key }, idx) => (
                        <div className="mb--25 mt--5">
                            <FWInput
                                label={`Maximum number of ${
                                    key === 'Identifier'
                                        ? 'identifications'
                                        : key
                                } per item`}
                                type="text"
                                name={key}
                                allowOnly="NUMBER"
                                style={{ width: '100%' }}
                                value={
                                    bulkImportTemplateColumns[
                                        idx
                                    ]?.value?.toString() ?? ''
                                }
                                onChange={(e) =>
                                    setBulkImportTemplateColumns((prev) => {
                                        const temp = cloneDeep(prev);
                                        temp[idx].value = e.target.value;
                                        return temp;
                                    })
                                }
                            />
                        </div>
                    ))}
                </>
            </BulkImportPopup>
            <PaymentsItemPopup
                open={open}
                setOpen={setOpen}
                itemDetails={{
                    type: 'ITEM_SUMMARY',
                    data: itemDetails,
                    allMeasurementUnits,
                }}
            />
        </>
    );
};

export default ItemsListPage;
