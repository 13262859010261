import { DialogActions, DialogContent } from '@mui/material';
import { useEffect, useState } from 'react';
import { FWButton } from '../../Common/FWButton';
import { FWInput } from '../../Common/FWInput';
import { FWPopupWithChildren } from '../../Common/FWPopupWithChildren';

const CloneCostingSheetPopup = ({
    type,
    initialSheetName,
    onClick,
    onClickConfirm,
}: // costingList,
{
    type: 'ICON' | 'MENU_ITEM';
    initialSheetName: string;
    onClick: () => void;
    onClickConfirm: (newSheetName: string) => void;
    // costingList: ICostingSheetData[];
}) => {
    const [open, setOpen] = useState(false);
    const [sheetName, setSheetName] = useState<string>(
        initialSheetName + ' - cloned'
    );

    const [isDuplicate, setIsDuplicate] = useState(false);

    useEffect(() => {
        initialSheetName && setSheetName(initialSheetName + ' - cloned');
    }, [initialSheetName]);

    // useEffect(() => {
    //     costingList.some((sheet) => {
    //         if (sheet.name === sheetName.trim()) {
    //             setIsDuplicate(true);
    //             return true;
    //         }
    //         setIsDuplicate(false);
    //         return false;
    //     });
    // }, [costingList, sheetName]);

    // const [
    //     recheckCostingSheetduplicateName,
    //     { data: isCostingSheetDuplicate },
    // ] = useCheckCostingSheetDuplicateNameMutation({
    //     fixedCacheKey: 'checkCloneCostingSheetDuplcate',
    // });

    // useEffect(() => {
    //     recheckCostingSheetduplicateName({
    //         name: sheetName,
    //     });
    // }, [recheckCostingSheetduplicateName, sheetName]);

    // const debounceTemplateSearch = useMemo(
    //     () =>
    //         debounce((searchText: string) => {
    //             setSheetName(searchText);
    //         }, 500),
    //     []
    // );

    // useEffect(() => {
    //     if (isCostingSheetDuplicate !== undefined) {
    //         setIsDuplicate(isCostingSheetDuplicate.costing_sheet_exists);
    //     }
    // }, [isCostingSheetDuplicate]);

    return (
        <>
            {/* {type === 'ICON' ? (
                <IconButton
                    disabled={!initialSheetName}
                    color={'primary'}
                    onClick={(e: any) => {
                        // handleCloneSheet(sheetId);
                        setOpen(true);
                        // e.stopImmediatePropagation();
                        onClick && onClick();
                    }}
                >
                    <i className={`bi bi-back`} />
                </IconButton>
            ) : (
                <FWMenuItem
                    onClick={(e: any) => {
                        // handleCloneSheet(sheetId);
                        setOpen(true);
                        e.stopImmediatePropagation();
                        onClick && onClick();
                    }}
                    sx={{ color: 'primary.main' }}
                >
                    Clone Sheet
                </FWMenuItem>
            )} */}
            <FWPopupWithChildren
                title="Clone sheet"
                open={open}
                handleClose={() => {
                    setOpen(false);
                    setIsDuplicate(false);
                    setSheetName(initialSheetName + ' - cloned');
                }}
            >
                <DialogContent>
                    <FWInput
                        label="Enter sheet name"
                        value={sheetName}
                        onChange={(e) => {
                            const value = e.target.value;
                            setSheetName(value);
                            // costingList.some((sheet) => {
                            //     if (sheet.name === value.trim()) {
                            //         setIsDuplicate(true);
                            //         return true;
                            //     }
                            //     setIsDuplicate(false);
                            //     return false;
                            // });
                        }}
                        error={isDuplicate}
                        helper={{
                            text: isDuplicate
                                ? 'Sheet name already exists'
                                : '',
                        }}
                    />
                </DialogContent>
                <DialogActions sx={{ padding: '20px 24px' }}>
                    <FWButton
                        variant="outlined"
                        onClick={() => {
                            setOpen(false);
                            setIsDuplicate(false);
                            setSheetName(initialSheetName + ' - cloned');
                        }}
                    >
                        Cancel
                    </FWButton>
                    <FWButton
                        variant="contained"
                        onClick={() => {
                            onClickConfirm(sheetName);
                            setOpen(false);
                            setIsDuplicate(false);
                        }}
                        disabled={isDuplicate}
                    >
                        Clone
                    </FWButton>
                </DialogActions>
            </FWPopupWithChildren>
        </>
    );
};

export default CloneCostingSheetPopup;
