import { FWNumberFormatter } from '../../Common/Utils/CurrencyUtils';
import { getFWDateTime } from '../../Common/Utils/DateUtils';
import { transformPaymentTermsFromBackendToFrontend } from '../../Global/Helpers/paymentTermsHelpers';
import { IEntityDetails } from '../../Global/Interfaces/EntityInterface';
import { fetchExportFileURL } from '../../Historical/Services/HADownloadService';
import {
    IPostBuyerAward,
    IPurchaseOrderItem,
} from '../../Models/AwardsAndPO.model';
import { IVendorMasterRawData } from '../../Organizations/Admin/Interfaces/VendorsInterface';
import {
    // DashBoardFilter,
    TPOUserList,
} from '../../ProjectGlCostCenter/interface/project.model';
import { baseApiSlice } from '../../Redux/Slices/BaseApiSlice';
import { postBuyerAward } from '../../Services/events/eventOperations';
import { del, get, post, put } from '../../Utils/api';
import { POGroupResponse } from '../Interfaces/APIResponses/POGroupResponse';
import { POSellerDashBoardResponse } from '../Interfaces/APIResponses/POSellerDashBoardResponse';
import { IInvoiceForPo } from '../Interfaces/Invoice.model';
import {
    IPageDetails,
    IPurchaseOrder,
    IPurchaseOrderApproval,
    IPurchaseOrderComment,
    IStatusMap,
} from '../Interfaces/PO.model';
import {
    IBuyerPODashboardInterface,
    ISellerPODashboardInterface,
} from '../Interfaces/shared.model';
import { IDirectPODashboardInfo } from '../Misc/datagridColumns';

export const getPurchaseOrder = async (
    purchaseOrderId: string,
    enterpriseType: 'BUYER' | 'SELLER'
): Promise<IPurchaseOrder> => {
    let resp = await get<any>(
        `/purchase_orders/${purchaseOrderId}/?enterprise_type=${enterpriseType}`
    );
    let processedData: IPurchaseOrder = resp.data;

    processedData.purchase_order_items.forEach((item) => {
        item.paymentTerms = transformPaymentTermsFromBackendToFrontend({
            prepayment_percentage: (item as any).prepayment_percentage,
            payment_terms: (item as any).payment_terms,
            deliverables_payment_terms: (item as any)
                .deliverables_payment_terms,
        });
    });
    return processedData;
};

const purchaseOrderApiSlice = baseApiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getPurchaseOrder: builder.query<
            IPurchaseOrder,
            { purchaseOrderId: string; enterpriseType: 'BUYER' | 'SELLER' }
        >({
            query: ({ purchaseOrderId, enterpriseType }) =>
                `/purchase_orders/${purchaseOrderId}/?enterprise_type=${enterpriseType}`,
            transformResponse: (response: IPurchaseOrder) => {
                response.purchase_order_items.forEach((item) => {
                    item.paymentTerms =
                        transformPaymentTermsFromBackendToFrontend({
                            prepayment_percentage: (item as any)
                                .prepayment_percentage,
                            payment_terms: (item as any).payment_terms,
                            deliverables_payment_terms: (item as any)
                                .deliverables_payment_terms,
                        });
                });
                return response;
            },
        }),
        getPOListOfAllUserList: builder.query<TPOUserList, {}>({
            query: () => ({
                url: '/purchase_orders/columns/?enterprise_type=BUYER',
            }),
        }),
    }),
});
export const { useGetPurchaseOrderQuery, useGetPOListOfAllUserListQuery } =
    purchaseOrderApiSlice;

export const getPreviewPurchaseOrder = async (
    purchaseOrderId: string
): Promise<IPurchaseOrder> => {
    let resp = await get<any>(
        `/authentication/purchase_order/${purchaseOrderId}/`
    );

    let processedData: IPurchaseOrder = resp.data;
    return processedData;
};

export const getPreviewEntityDetails = async (
    entityId: string
): Promise<IEntityDetails> => {
    return new Promise(async (resolve, reject) => {
        try {
            let resp = await get<IEntityDetails>(
                `/authentication/entity/${entityId}`
            );
            resolve(resp.data);
        } catch (e) {
            reject(e);
        }
    });
};

export const getVendorInfo = async (
    sellerEntityId: string
): Promise<IVendorMasterRawData | null> => {
    let resp = await get<IVendorMasterRawData[]>(
        `/organization/vendor_master/admin/?seller_entity_id=${sellerEntityId}`
    );
    if (resp.data.length > 0) {
        return resp.data[0];
    }
    return null;
};

export const getEntityDetails = async (
    entityId: string
): Promise<IEntityDetails> => {
    return new Promise(async (resolve, reject) => {
        try {
            let resp = await get<IEntityDetails>(
                `/organization/entity/${entityId}`
            );
            resolve(resp.data);
        } catch (e) {
            reject(e);
        }
    });
};

export const { useGetEntityDetailsQuery } = baseApiSlice.injectEndpoints({
    endpoints: (build) => ({
        getEntityDetails: build.query<IEntityDetails, string>({
            queryFn: async (entityId) => {
                try {
                    let resp = await get<IEntityDetails>(
                        `/organization/entity/${entityId}`
                    );
                    return { data: resp.data };
                } catch (e) {
                    return { error: e as any };
                }
            },
        }),
    }),
});

export const rescindPurchaseOrder = async (
    purchaseOrderId: string,
    event_type: 'FROM_EVENT' | 'DIRECT',
    msg: string
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        let data = {
            status: 'RESCINDED',
            notes: msg,
            purchase_order_items: [],
        };
        try {
            let url =
                event_type === 'DIRECT'
                    ? `/purchase_orders/po_group/${purchaseOrderId}/state/`
                    : `/purchase_orders/${purchaseOrderId}/state/`;
            let resp = await put<any, any>(url, data);
            resolve(resp.data);
        } catch (err) {
            reject(err);
        }
    });
};

interface IPutHold {
    hold: boolean;
    reason?: string;
}

export const putPurchaseOrderHold = async (
    purchaseOrderId: string,
    notes: string
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        let data: IPutHold = {
            hold: true,
            reason: notes.trim(),
        };
        try {
            let resp = await put<any, any>(
                `/purchase_orders/${purchaseOrderId}/hold/`,
                data
            );
            resolve(resp.data);
        } catch (err) {
            reject(err);
        }
    });
};

export const removePurchaseOrderHold = async (
    purchaseOrderId: string
): Promise<void> => {
    return new Promise(async (resolve, reject) => {
        let data: IPutHold = {
            hold: false,
        };
        try {
            await put<any, any>(
                `/purchase_orders/${purchaseOrderId}/hold/`,
                data
            );
            resolve();
        } catch (err) {
            reject(err);
        }
    });
};

enum putPoTerminationStatus {
    PO_TERMINATION_REQUESTED = 'REQUESTED',
    PO_TERMINATION_REVOKED = 'REVOKED',
    PO_TERMINATION_SELLER_ACCEPTED = 'ACCEPTED',
}

interface poTerminationPutData {
    status: putPoTerminationStatus;
    notes?: string;
}

export const putPurchaseOrderTermination = async (
    purchaseOrderId: string,
    notes: string
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        let data: poTerminationPutData = {
            notes: notes.trim(),
            status: putPoTerminationStatus.PO_TERMINATION_REQUESTED,
        };
        try {
            let resp = await put<any, any>(
                `/purchase_orders/${purchaseOrderId}/terminate/`,
                data
            );
            resolve(resp);
        } catch (err) {
            reject(err);
        }
    });
};

export const cancelPurchaseOrderTermination = async (
    purchaseOrderId: string
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        let data: poTerminationPutData = {
            status: putPoTerminationStatus.PO_TERMINATION_REVOKED,
        };
        try {
            let resp = await put<any, any>(
                `/purchase_orders/${purchaseOrderId}/terminate/`,
                data
            );
            resolve(resp);
        } catch (err) {
            reject(err);
        }
    });
};

export const getUnresolvedInvoicesForPurchaseOrder = async (
    purchaseOrderId: string
): Promise<IInvoiceForPo[]> => {
    let resp = await get<IInvoiceForPo[]>(
        `/purchase_orders/${purchaseOrderId}/unresolved_invoices/`
    );
    return resp.data;
};

export const getAllInvoicesForPurchaseOrder = async (
    purchaseOrderId: string,
    enterprise_type: 'BUYER' | 'SELLER'
): Promise<IInvoiceForPo[]> => {
    let resp = await get<IInvoiceForPo[]>(
        `/invoices/?purchase_order_id=${purchaseOrderId}&enterprise_type=${enterprise_type}`
    );
    return resp.data;
};

export const acceptPurchaseOrderTermination = async (
    purchaseOrderId: string
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        let data: poTerminationPutData = {
            status: putPoTerminationStatus.PO_TERMINATION_SELLER_ACCEPTED,
        };
        try {
            let resp = await put<any, any>(
                `/purchase_orders/${purchaseOrderId}/terminate/`,
                data
            );
            resolve(resp);
        } catch (err) {
            reject(err);
        }
    });
};
export interface IPoTabCountResponse {
    purchase_order_tab_counts: {
        all: string;
        pending: string;
        ongoing: string;
        finished: string;
    };
}
export const getBuyerPODashboardTabCount = async (): Promise<{
    direct_PO_groups: string;
    pending: string;
    ongoing: string;
    finished: string;
}> => {
    let po_promise = post<
        {
            dashboard_view: string;
        },
        {
            tab_counts: {
                pending: string;
                ongoing: string;
                all: string;
                finished: string;
            };
        }
    >(`/dashboard/tab_counts/`, {
        dashboard_view: 'purchase_order_buyer',
    });
    let d2PO_promise = post<
        {
            dashboard_view: string;
        },
        {
            tab_counts: {
                all: string;
            };
        }
    >('/dashboard/tab_counts/', {
        dashboard_view: 'po_group',
    });

    let res = await Promise.all([po_promise, d2PO_promise]);
    return {
        direct_PO_groups: res[1].data.tab_counts.all,
        pending: res[0].data.tab_counts.pending,
        ongoing: res[0].data.tab_counts.ongoing,
        finished: res[0].data.tab_counts.finished,
    };
};

//Mr Tea: Paginated Dashboard Changes Start Here
const statusMap: IStatusMap = {
    direct_PO_groups: 'all',
    pending: 'pending',
    ongoing: 'ongoing',
    finished: 'finished',
    received: 'received',
};

interface IPurchaseOrderPayloadInterface {
    dashboard_view: string;
    tab: string;
    search_text: string;
    sort_fields: {
        field: string;
        ascending: boolean;
    }[];
    items_per_page: number;
    page_number: number;
}

export const getBuyerPODashboardRows = async ({
    tab,
    sortFields,
    searchText,
    pageNumber,
    itemsPerPage,
}: {
    tab: keyof IStatusMap;
    sortFields: {
        field: string;
        ascending: boolean;
    }[];
    searchText: string;
    pageNumber: number;
    itemsPerPage: number;
}): Promise<{
    purchase_orders: IBuyerPODashboardInterface[] | IDirectPODashboardInfo[];
    pageDetails: IPageDetails;
}> => {
    let purchase_orders = [];
    let page_details = {
        current_page: 1,
        has_next: false,
        has_previous: false,
        total_pages: 1,
    };
    if (tab !== 'direct_PO_groups') {
        const postData: IPurchaseOrderPayloadInterface = {
            dashboard_view: 'purchase_order_buyer',
            tab: statusMap[tab] ?? '',
            search_text:
                searchText?.length && searchText?.length > 0 ? searchText : '',
            sort_fields: sortFields ?? [],
            items_per_page: itemsPerPage,
            page_number: pageNumber,
        };
        let resp = await post<any, any>(`/dashboard/`, postData);
        purchase_orders = [
            ...resp.data.data.map((po: any, idx: number) => {
                let data: IBuyerPODashboardInterface = {
                    id: idx,
                    po_id: po.purchase_order_id,
                    po_type: po.purchase_order_type,
                    custom_event_id:
                        'event_details' in po.additional_information
                            ? po.additional_information.event_details
                                  .custom_event_id
                            : 'Old PO',
                    event_name:
                        'event_details' in po.additional_information
                            ? po.additional_information.event_details.event_name
                            : 'Old PO',
                    custom_po_id: po.custom_purchase_order_id,
                    buyer_entity_name: po.buyer_information.buyer_entity_name,
                    seller_entity_name: po.seller_entity_name,
                    po_no_of_items:
                        po.additional_information.purchase_order_items_count,
                    po_delivery_schedule: '',
                    no_of_invoices_in_po: po.invoice_count,
                    po_total_amount: FWNumberFormatter(
                        parseFloat(po.pricing_information.total),
                        po.pricing_information.currency_code_abbreviation
                    ),
                    po_status: po.status,
                    po_hold_or_termination:
                        po.termination_information !== null
                            ? 'TERMINATION_REQUESTED'
                            : po.hold_information !== null
                            ? 'HOLD'
                            : null,
                    delivery_schedule_warning: po.warning_information
                        ? po.warning_information.units_on_hold
                            ? 'UNITS_ON_HOLD'
                            : po.warning_information.rejected_delivery_schedule
                            ? 'REJECTED'
                            : po.warning_information
                                  .unconfirmed_delivery_schedule
                            ? 'UNCONFIRMED'
                            : null
                        : null,
                    isssued_date: getFWDateTime(po.submission_datetime),
                };

                return data;
            }),
        ];
        page_details = resp.data.metadata;
    } else {
        const postData = {
            dashboard_view: 'po_group',
            tab: statusMap[tab],
            search_text:
                searchText?.length && searchText?.length > 0 ? searchText : '',
            sort_fields: sortFields ?? [],
            items_per_page: itemsPerPage,
            page_number: pageNumber,
        };
        let resp = await post<any, any>(`/dashboard/`, postData);
        purchase_orders = resp.data.data.map(
            (poGroup: POGroupResponse, idx: number) => {
                let data = {
                    id: idx,
                    po_group_status: poGroup.status,
                    custom_po_group_id: poGroup.event_details.custom_event_id,
                    po_group_name: poGroup.event_details.event_name,
                    no_of_items: poGroup.additional_details.item_count,
                    buyer_entity_name:
                        poGroup.event_details.buyer_entity.buyer_entity_name,
                    creator_name:
                        poGroup.event_details.original_event_creator_name ?? '',
                    created_datetime: getFWDateTime(poGroup.created_datetime),
                    modified_datetime: getFWDateTime(
                        poGroup.event_details.last_modified_datetime
                    ),
                    sub_event_id: poGroup.po_group_entry_id,
                };

                return data;
            }
        );

        page_details = resp.data.metadata;
    }

    return {
        purchase_orders,
        pageDetails: page_details,
    };
};

// export const { useFetchPODashboardDataMutation } = baseApiSlice.injectEndpoints({
//     endpoints: (builder) => ({
//         fetchPODashboardData: builder.mutation<
//             { purchase_orders: IBuyerPODashboardInterface[] | IDirectPODashboardInfo[], pageDetails: IPageDetails },
//             {
//                 tab: keyof IStatusMap;
//                 sortFields: { field: string; ascending: boolean }[];
//                 searchText: string;
//                 pageNumber: number;
//                 itemsPerPage: number;
//                 filters: DashBoardFilter | null;
//             }
//         >({
//             query: ({ tab, sortFields, searchText, pageNumber, itemsPerPage, filters }) => {
//                 const postData: IPurchaseOrderPayloadInterface = {
//                     dashboard_view: tab !== 'direct_PO_groups' ? 'purchase_order_buyer' : 'po_group',
//                     tab: statusMap[tab] ?? '',
//                     search_text: searchText?.length && searchText?.length > 0 ? searchText : '',
//                     sort_fields: sortFields ?? [],
//                     items_per_page: itemsPerPage,
//                     page_number: pageNumber,
//                     filters,
//                 };

//                 return {
//                     url: '/dashboard/',
//                     method: 'POST',
//                     body: postData,
//                 };
//             },
//             transformResponse: (response: any, meta, arg) => {
//                 let purchase_orders = [];
//                 let page_details = {
//                     current_page: 1,
//                     has_next: false,
//                     has_previous: false,
//                     total_pages: 1,
//                 };

//                 if (arg.tab !== 'direct_PO_groups') {
//                     purchase_orders = response.data.data.map((po: any, idx: number) => ({
//                         id: idx,
//                         po_id: po.purchase_order_id,
//                         po_type: po.purchase_order_type,
//                         custom_event_id: 'event_details' in po.additional_information ? po.additional_information.event_details.custom_event_id : 'Old PO',
//                         event_name: 'event_details' in po.additional_information ? po.additional_information.event_details.event_name : 'Old PO',
//                         custom_po_id: po.custom_purchase_order_id,
//                         buyer_entity_name: po.buyer_information.buyer_entity_name,
//                         seller_entity_name: po.seller_entity_name,
//                         po_no_of_items: po.additional_information.purchase_order_items_count,
//                         po_delivery_schedule: '',
//                         no_of_invoices_in_po: po.invoice_count,
//                         po_total_amount: FWNumberFormatter(parseFloat(po.pricing_information.total), po.pricing_information.currency_code_abbreviation),
//                         po_status: po.status,
//                         po_hold_or_termination: po.termination_information !== null ? 'TERMINATION_REQUESTED' : po.hold_information !== null ? 'HOLD' : null,
//                         delivery_schedule_warning: po.warning_information ? po.warning_information.units_on_hold ? 'UNITS_ON_HOLD' : po.warning_information.rejected_delivery_schedule ? 'REJECTED' : po.warning_information.unconfirmed_delivery_schedule ? 'UNCONFIRMED' : null : null,
//                         isssued_date: getFWDateTime(po.submission_datetime),
//                     }));
//                     page_details = response.data.metadata;
//                 } else {
//                     purchase_orders = response.data.data.map((poGroup: any, idx: number) => ({
//                         id: idx,
//                         po_group_status: poGroup.status,
//                         custom_po_group_id: poGroup.event_details.custom_event_id,
//                         po_group_name: poGroup.event_details.event_name,
//                         no_of_items: poGroup.additional_details.item_count,
//                         buyer_entity_name: poGroup.event_details.buyer_entity.buyer_entity_name,
//                         creator_name: poGroup.event_details.original_event_creator_name ?? '',
//                         created_datetime: getFWDateTime(poGroup.created_datetime),
//                         modified_datetime: getFWDateTime(poGroup.event_details.last_modified_datetime),
//                         sub_event_id: poGroup.po_group_entry_id,
//                     }));
//                     page_details = response.data.metadata;
//                 }

//                 return {
//                     purchase_orders,
//                     pageDetails: page_details,
//                 };
//             },
//         }),
//     }),
// });

export interface ISellerPoTabCount {
    tab_counts: {
        pending?: string;
        received: string;
        ongoing: string;
        finished: string;
    };
}
export const getSellerPODashboardTabCount = async (): Promise<{
    received: string;
    ongoing: string;
    finished: string;
}> => {
    let resp = await post<ISellerPoTabCount, any>(`/dashboard/tab_counts/`, {
        dashboard_view: 'purchase_order_seller',
    });
    return resp.data.tab_counts;
};

export const getSellerPODashboardRows = async ({
    tab,
    sortFields,
    searchText,
    pageNumber,
    itemsPerPage,
}: {
    tab: keyof IStatusMap;
    sortFields: {
        field: string;
        ascending: boolean;
    }[];
    searchText: string;
    pageNumber: number;
    itemsPerPage: number;
}): Promise<{
    purchase_orders: ISellerPODashboardInterface[];
    pageDetails: IPageDetails;
}> => {
    let purchase_orders = [];
    let page_details = {
        current_page: 1,
        has_next: false,
        has_previous: false,
        total_pages: 1,
        total_count: 1,
    };

    const postData = {
        dashboard_view: 'purchase_order_seller',
        tab: statusMap[tab],
        search_text:
            searchText?.length && searchText?.length > 0 ? searchText : '',
        sort_fields: sortFields ?? [],
        items_per_page: itemsPerPage,
        page_number: pageNumber,
    };
    let resp = await post<any, any>(`/dashboard/`, postData);
    purchase_orders = [
        ...resp.data.data.map((po: POSellerDashBoardResponse, idx: number) => {
            let data: ISellerPODashboardInterface = {
                id: idx,
                po_id: po.purchase_order_id,
                custom_event_id:
                    'event_details' in po.additional_information
                        ? po.additional_information.event_details
                              .custom_event_id
                        : 'Old PO',
                event_name:
                    'event_details' in po.additional_information
                        ? po.additional_information.event_details.event_name
                        : 'Old PO',
                custom_po_id: po.custom_purchase_order_id,
                buyer_entity_name: po.buyer_information.buyer_entity_name,
                seller_entity_id: po.seller_entity,
                seller_entity_name: po.seller_information.seller_entity_name,
                po_no_of_items:
                    po.additional_information.purchase_order_items_count,
                po_delivery_schedule: '',
                no_of_invoices_in_po: po.invoice_count,
                po_total_amount: FWNumberFormatter(
                    parseFloat(po.pricing_information.total),
                    po.pricing_information.currency_code_abbreviation
                ),
                po_status: po.po_status,
                po_hold_or_termination:
                    po.termination_information !== null
                        ? 'TERMINATION_REQUESTED'
                        : po.hold_information !== null
                        ? 'HOLD'
                        : null,
                delivery_schedule_warning: null,
                // unconfirmed_delivery_schedule:
                //     po.warning_information.unconfirmed_delivery_schedule,
            };

            return data;
        }),
    ];
    page_details = resp.data.metadata;

    return {
        purchase_orders,
        pageDetails: page_details,
    };
};

export const getPurchaseOrderApprovals = async (
    po_id: string
): Promise<IPurchaseOrderApproval[]> => {
    const poApprovalsResponse = await get<any>(
        `/purchase_orders/${po_id}/approvers/`
    );
    const poApprovalsList: any[] = poApprovalsResponse.data;
    const poApprovals: IPurchaseOrderApproval[] = [];

    poApprovalsList.forEach((poApproval) => {
        poApprovals.push({
            requestorId: poApproval.requestor,
            requestorUserName: poApproval.requestor_username,
            requestorFirstName: poApproval.requestor_first_name,
            requestorLastName: poApproval.requestor_last_name,
            requestorNotes: poApproval.requestor_notes,
            approverId: poApproval.approver,
            approverUserName: poApproval.approver_username,
            approverFirstName: poApproval.approver_first_name,
            approverLastName: poApproval.approver_last_name,
            approverNotes: poApproval.approver_notes,
            status: poApproval.status,
        });
    });
    return poApprovals;
};

export const deletePurchaseOrder = async (
    po_id: string,
    event_type: 'FROM_EVENT' | 'DIRECT'
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            let url =
                event_type === 'FROM_EVENT'
                    ? `/purchase_orders/${po_id}/delete/`
                    : `/purchase_orders/po_group/${po_id}/delete/`;
            let resp = await del<any>(url);
            resolve(resp);
        } catch (err) {
            reject(err);
        }
    });
};

export const rejectOrReworkPurchaseOrder = async (
    po_id: string,
    msg: string,
    event_type: 'FROM_EVENT' | 'DIRECT',
    status: 'REJECTED' | 'REWORK'
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        let data = {
            status: status,
            notes: msg,
            purchase_order_items: [],
        };
        try {
            let url =
                event_type === 'FROM_EVENT'
                    ? `/purchase_orders/${po_id}/state/`
                    : `/purchase_orders/po_group/${po_id}/state/`;
            let resp = await put<any, any>(url, data);
            resolve(resp);
        } catch (err) {
            reject(err);
        }
    });
};

export const submitPurchaseOrder = async (
    po_id: string,
    notes: string,
    event_type: 'FROM_EVENT' | 'DIRECT'
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        let data = {
            status: 'ISSUED',
            notes: notes,
            purchase_order_items: [],
        };
        try {
            let url =
                event_type === 'FROM_EVENT'
                    ? `/purchase_orders/${po_id}/state/`
                    : `/purchase_orders/po_group/${po_id}/state/`;
            let resp = await put<any, any>(url, data);
            resolve(resp);
        } catch (err) {
            reject(err);
        }
    });
};

export const sellerDeclinePurchaseOrder = async (
    po_id: string,
    msg: string,
    event_type: 'FROM_EVENT' | 'DIRECT'
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        let data = {
            status: 'DECLINED',
            notes: msg.trim() === '' ? '-' : msg,
            purchase_order_items: [],
        };
        try {
            let url =
                event_type === 'DIRECT'
                    ? `/purchase_orders/po_group/${po_id}/state/`
                    : `/purchase_orders/${po_id}/state/`;

            let resp = await put<any, any>(url, data);
            resolve(resp);
        } catch (err) {
            reject(err);
        }
    });
};

export const sellectAcceptPurchaseOrder = async (
    po_id: string,
    msg: string,
    event_type: 'FROM_EVENT' | 'DIRECT'
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        let data: {
            status: string;
            notes?: string;
            purchase_order_items: any;
        } = {
            status: 'ONGOING',
            purchase_order_items: [],
        };
        if (msg.trim().length > 0) {
            data['notes'] = msg.trim();
        }
        try {
            let url =
                event_type === 'DIRECT'
                    ? `/purchase_orders/po_group/${po_id}/state/`
                    : `/purchase_orders/${po_id}/state/`;
            let resp = await put<any, any>(url, data);
            resolve(resp);
        } catch (err) {
            reject(err);
        }
    });
};

export const createBuyerAward = (
    dataToPost: IPostBuyerAward,
    type: 'DIRECT' | 'FROM_EVENT'
): Promise<IPurchaseOrderItem> => {
    return new Promise((resolve, reject) => {
        let processedData: any = dataToPost;
        processedData.po_group_item_id = processedData.rfq_item_id;
        delete processedData.rfq_item_id;
        postBuyerAward(processedData, type)
            .then((res) => {
                resolve(res);
            })
            .catch((err) => reject());
    });
};

export const getPurchaseOrderComments = (
    purchase_order_uuid: string
): Promise<IPurchaseOrderComment[]> => {
    return new Promise(async (resolve, reject) => {
        try {
            let resp = await get<
                {
                    comment_id: string;
                    message: string;
                    name: string;
                    resource_id: string;
                    type: 'PURCHASE_ORDER';
                    user: string;
                    username: string;
                    enterprise_details: {
                        enterprise_id: string;
                        enterprise_name: string;
                    };
                    created_datetime: string;
                }[]
            >(`/purchase_orders/${purchase_order_uuid}/comments/`);

            let comments: IPurchaseOrderComment[] = [];

            resp.data.forEach((comment) => {
                comments.push({
                    message: comment.message,
                    userId: comment.user,
                    userName: comment.username,
                    fullName: comment.name,
                    createdDatetime: comment.created_datetime,
                    enterpriseDetails: {
                        enterpriseId: comment.enterprise_details.enterprise_id,
                        enterpriseName:
                            comment.enterprise_details.enterprise_name,
                    },
                });
            });
            resolve(comments);
        } catch (err) {
            reject(err);
        }
    });
};

export const addPurchaseOrderComment = (
    purchase_order_uuid: string,
    message: string
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            let resp = await post<{ message: string }, any>(
                `/purchase_orders/${purchase_order_uuid}/comments/create`,
                {
                    message: message,
                }
            );
            resolve(resp);
        } catch (err) {
            reject(err);
        }
    });
};

export const exportPOAPI = async (currentTab: string): Promise<any> => {
    currentTab = currentTab.toUpperCase();
    let status_list: string[] = [];
    if (currentTab === 'ONGOING') {
        status_list = ['ONGOING'];
    } else if (currentTab === 'PENDING') {
        status_list = ['APPROVAL_PENDING', 'ISSUED'];
    }
    return new Promise(async (resolve, reject) => {
        try {
            const exportId = await post<any, any>(`export/po/download/`, {
                status_list,
            });
            const resp2 = await fetchExportFileURL(
                exportId.data,
                'PURCHASE_ORDER'
            );
            resolve(resp2);
        } catch (err) {
            reject(err);
        }
    });
};
