import { Box, Typography } from '@mui/material';
import { FWTypography } from '../../../../Common/FWTypograhy';
import { IVLNAboutNewFactoryLocations } from '../../../Interfaces/VendorLNInterfaces';

const VLNProfileAboutLocationComponent = ({
    locationData,
}: {
    locationData: IVLNAboutNewFactoryLocations;
}) => {
    // const { url } = useFetchUrlUsingAttachmentId(
    //     locationData.images?.[0]?.attachment_id || ''
    // );

    return (
        <Box className="flex flex--aifs" gap={2}>
            <Box
                component={'img'}
                src={
                    // url ||
                    'https://images.unsplash.com/photo-1516937941344-00b4e0337589?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80'
                }
                alt={'location'}
                sx={{
                    height: '120px',
                    aspectRatio: '2/1',
                    objectFit: 'cover',
                    borderRadius: 1,
                    background: 'lightgray',
                }}
            />
            <Box className={'flex flex--fdc flex--jcsb'} height={'117px'}>
                <div>
                    <div className="flex flex--aic">
                        <FWTypography color={'text.secondary'}>
                            {locationData?.address?.address_nickname}
                        </FWTypography>
                        {/* &nbsp; &nbsp;
                        <Chip
                            label="Primary"
                            color={'success'}
                            size={'small'}
                            sx={{ color: 'white', fontSize: 12 }}
                        /> */}
                    </div>
                    <Typography
                        sx={{
                            display: '-webkit-box',
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: 'vertical',
                            overflow: 'hidden',
                        }}
                    >
                        {[
                            locationData?.address?.address1,
                            locationData?.address?.address2,
                            locationData?.address?.address3,
                        ]
                            ?.filter((val) => val?.trim() !== '')
                            ?.join(' | ')}
                    </Typography>
                    <Typography color={'text.secondary'} fontSize={13}>
                        {locationData?.address?.city},{' '}
                        {locationData?.address?.country}
                    </Typography>
                </div>
                {locationData?.address?.address_url && (
                    <Typography color={'primary'} fontSize={13}>
                        <a
                            href={locationData?.address?.address_url}
                            target={'_blank'}
                            rel="noreferrer"
                            style={{ color: 'currentColor' }}
                        >
                            Get directions
                        </a>
                    </Typography>
                )}
            </Box>
        </Box>
    );
};

export default VLNProfileAboutLocationComponent;
