import { cloneDeep } from 'lodash';
import moment from 'moment';
import { FWNumberFormatter } from '../../Common/Utils/CurrencyUtils';
import {
    CustomTemplateItemTypes,
    CustomTemplateTypes,
} from '../../Global/Interfaces/CustomTemplate.interface';
import {
    IInvoiceItem,
    IInvoiceTopLevelDetails,
    InvoiceStatus,
    InvoiceType,
    IPostInvoiceDetails,
    IPostInvoiceItemDetails,
    IPrepaymentInvoiceItemDetails,
} from '../Interfaces/Invoice.model';
import {
    INewGetInvoiceBatchDetail,
    INewGetInvoiceItemDetail,
    INewInvoice,
    INewInvoiceBatchDetail,
    INewInvoiceItemDetail,
    INewPostInvoiceDetails,
} from '../Interfaces/NewInvoice.model';
import { IPurchaseOrderItem } from '../Interfaces/PO.model';

export const transformNewInvoiceDataToSave = (
    postInvoiceDetails: INewPostInvoiceDetails
): INewPostInvoiceDetails => {
    let newObj = cloneDeep<INewPostInvoiceDetails>(postInvoiceDetails);
    // newObj.invoice_date = newObj.invoice_date === "" ? null : newObj.invoice_date
    newObj.dispatch_date =
        newObj.dispatch_date === '' ? null : newObj.dispatch_date;
    newObj.expected_delivery_date =
        newObj.expected_delivery_date === ''
            ? null
            : newObj.expected_delivery_date;

    newObj.invoice_items = newObj.invoice_items.map(
        (invoice_item: INewInvoiceItemDetail): INewInvoiceItemDetail => {
            invoice_item.batch_items = invoice_item.batch_items.map(
                (
                    item_batch: INewInvoiceBatchDetail
                ): INewInvoiceBatchDetail => {
                    item_batch.batch_expiry_date =
                        item_batch.batch_expiry_date === ''
                            ? null
                            : item_batch.batch_expiry_date;
                    return item_batch;
                }
            );
            return invoice_item;
        }
    );

    return newObj;
};

export const transformInvoiceDataToSave = (
    details: IPostInvoiceDetails
): IPostInvoiceDetails => {
    let newObj: any = cloneDeep(details);
    // convert all dates to null if they're empty
    newObj.invoice_date =
        newObj.invoice_date === '' ? null : newObj.invoice_date;
    newObj.expected_delivery_date =
        newObj.expected_delivery_date === ''
            ? null
            : newObj.expected_delivery_date;
    newObj.dispatch_date =
        newObj.dispatch_date === '' ? null : newObj.dispatch_date;
    //
    let arr: any[] = [];
    for (let po_item_id of Object.keys(newObj.invoice_items)) {
        let _invoice_item = details.invoice_items[po_item_id];
        let newArr = [
            ..._invoice_item.all_batch_info.map((batch) => {
                let obj: any = {
                    invoice_item_id: batch.invoice_item_id,
                    batch_expiry_date:
                        batch.batch_expiry_date === ''
                            ? null
                            : batch.batch_expiry_date,
                    batch_number: batch.batch_number,
                    purchase_order_item_id:
                        _invoice_item.purchase_order_item_id,
                    quantity: batch.quantity === '' ? null : batch.quantity,
                    measurement_unit_id: batch.measurement_unit_id,
                    price:
                        _invoice_item.price === '' ? null : _invoice_item.price,
                    currency_code_id: _invoice_item.currency_code_id,
                    shipping_per_unit:
                        _invoice_item.shipping_per_unit === ''
                            ? null
                            : _invoice_item.shipping_per_unit,
                    additional_charges: _invoice_item.additional_charges,
                    notes: batch.notes,
                    payment_terms: _invoice_item.payment_terms,
                    payment_terms_period: _invoice_item.payment_terms_period,
                    payment_terms_applied_from:
                        _invoice_item.payment_terms_applied_from,
                };
                if (batch.custom_fields.template_id !== '') {
                    obj['custom_fields'] = {
                        template_id: batch.custom_fields.template_id,
                        template_name: batch.custom_fields.template_name,
                        template_type: batch.custom_fields.type,
                        template_items: batch.custom_fields.items.map(
                            (template_item) => ({
                                template_item_id:
                                    template_item.template_item_id,
                                value: template_item.value,
                                template_item_name:
                                    template_item.template_item_name,
                                template_item_type:
                                    template_item.template_item_type,
                                is_required: template_item.is_required,
                                template: batch.custom_fields.template_id,
                            })
                        ),
                    };
                }
                return obj;
            }),
        ];
        arr = arr.concat(newArr);
    }
    newObj.invoice_items = arr;

    return newObj;
};

export const transformPrepayInvoiceDataToSave = (
    details: INewPostInvoiceDetails,
    po_id: string,
    currency_code_id: string
): any => {
    let newObj = {
        purchase_order_id: po_id,
        invoice_date: details.invoice_date === '' ? null : details.invoice_date,
        action: details.action,
        notes: details.notes === '' ? null : details.notes,
        currency_code_id: currency_code_id,
        custom_invoice_id: details.custom_invoice_id,
        purchase_order_items: details.invoice_items.map((invoice_item) => ({
            purchase_order_item_id: invoice_item.purchase_order_item_id,
            amount: invoice_item.price,
            notes: invoice_item.notes === '' ? null : invoice_item.notes,
        })),
    };
    return newObj;
};

// function to validate details like invoice_id, date, dispatch_date and expected delivery_date
export const validateInvoiceTopLevelDetails = (
    data: IInvoiceTopLevelDetails,
    error: { [key: string]: string },
    field: string,
    po_issued_date: string,
    check_dispatch: boolean,
    nonAllowedInvoiceNames: Set<string>
) => {
    let newError = cloneDeep(error);
    if (field === 'custom_invoice_id' || field === 'ALL') {
        if (data.custom_invoice_id.length === 0) {
            newError['custom_invoice_id'] = 'Please enter a valid invoice ID';
        } else if (nonAllowedInvoiceNames.has(data.custom_invoice_id.trim())) {
            newError['custom_invoice_id'] = 'Please enter a unique invoice ID';
        } else {
            delete newError['custom_invoice_id'];
        }
    }
    if (field === 'invoice_date' || field === 'ALL') {
        if (
            !moment(data.invoice_date).isValid() ||
            moment(data.invoice_date).isBefore(moment(po_issued_date), 'day')
        ) {
            newError['invoice_date'] = 'Please enter a valid invoice date';
        } else if (
            moment(data.expected_delivery_date).isValid() &&
            moment(data.expected_delivery_date).isBefore(
                moment(data.invoice_date),
                'day'
            )
        ) {
            newError['invoice_date'] = 'Should be less than delivery date';
        } else if (
            check_dispatch &&
            moment(data.dispatch_date).isValid() &&
            moment(data.dispatch_date).isBefore(
                moment(data.invoice_date),
                'day'
            )
        ) {
            newError['invoice_date'] = 'Should be less than dispatch date';
        } else {
            delete newError['invoice_date'];
        }
    }
    if (
        check_dispatch &&
        (field === 'dispatch_date' ||
            field === 'expected_delivery_date' ||
            field === 'ALL')
    ) {
        if (
            !moment(data.dispatch_date).isValid() ||
            moment(data.dispatch_date).isBefore(moment(po_issued_date), 'day')
        ) {
            newError['dispatch_date'] = 'Please enter a valid dispatch date';
        } else if (
            moment(data.expected_delivery_date).isValid() &&
            moment(data.expected_delivery_date).isBefore(
                moment(data.dispatch_date),
                'day'
            )
        ) {
            newError['dispatch_date'] = 'Should be less than delivery date';
        } else if (
            check_dispatch &&
            moment(data.dispatch_date).isValid() &&
            moment(data.dispatch_date).isBefore(
                moment(data.invoice_date),
                'day'
            )
        ) {
            newError['dispatch_date'] = 'Should be greater than invoice date';
        } else {
            delete newError['dispatch_date'];
        }
    }
    if (
        (field === 'dispatch_date' ||
            field === 'expected_delivery_date' ||
            field === 'ALL') &&
        data.expected_delivery_date !== ''
    ) {
        if (
            !moment(data.expected_delivery_date).isValid() ||
            moment(data.expected_delivery_date).isBefore(moment(), 'day')
        ) {
            newError['expected_delivery_date'] =
                'Please enter a valid expected delivery date';
        } else if (
            moment(data.invoice_date).isValid() &&
            moment(data.expected_delivery_date).isBefore(
                moment(data.invoice_date),
                'day'
            )
        ) {
            newError['expected_delivery_date'] =
                'Should be greater than invoice date';
        } else if (
            check_dispatch &&
            moment(data.dispatch_date).isValid() &&
            moment(data.expected_delivery_date).isBefore(
                moment(data.dispatch_date),
                'day'
            )
        ) {
            newError['expected_delivery_date'] =
                'Should be greater than dispatch date';
        } else {
            delete newError['expected_delivery_date'];
        }
    }
    return newError;
};

export const validateAuditInvoiceTopLevelDetails = (
    data: IInvoiceTopLevelDetails,
    error: { [key: string]: string },
    field: string,
    po_issued_date: string
) => {
    let newError = cloneDeep(error);
    if (field === 'invoice_date' || field === 'ALL') {
        if (
            !moment(data.invoice_date).isValid() ||
            moment(data.invoice_date).isBefore(moment(po_issued_date), 'day')
        ) {
            newError['invoice_date'] = 'Please enter a valid invoice date';
        } else {
            delete newError['invoice_date'];
        }
    }
    return newError;
};

// function to validate invoice item details
export const validateInvoiceItemDetails = (
    invoice_item: IPostInvoiceItemDetails,
    field: string,
    batch_idx: number,
    errors: {
        [key: string]: { error_type: 'ERROR' | 'WARNING'; error_msg: string };
    },
    po_item: IPurchaseOrderItem
): {
    [key: string]: { error_type: 'ERROR' | 'WARNING'; error_msg: string };
} => {
    let all_item_data = invoice_item.all_batch_info;
    let all_batches = invoice_item.all_batch_info;
    let newError = cloneDeep(errors);
    if (field === 'DELETE') {
        delete newError[`batch_number_${batch_idx}`];
        delete newError[`quantity_${batch_idx}`];
        delete newError[`quantity`];
        delete newError[`price`];
        delete newError[`shipping_per_unit`];
        delete newError[`additional_charges`];
        delete newError[`batch_expiry_date_${batch_idx}`];
    }
    if (field === 'price' || field === 'ALL') {
        let key = `price`;
        if (
            isNaN(parseFloat(invoice_item.price)) ||
            parseFloat(invoice_item.price) <= 0
        ) {
            newError[key] = {
                error_type: 'ERROR',
                error_msg: `Enter a valid price`,
            };
        } else if (
            parseFloat(invoice_item.price) !==
            parseFloat(po_item.pricing_information.price)
        ) {
            newError[key] = {
                error_type: 'WARNING',
                error_msg: `Mismatch from PO price`,
            };
        } else {
            delete newError[key];
        }
    }
    if (field === 'shipping_per_unit' || field === 'ALL') {
        let key = `shipping_per_unit`;
        if (isNaN(parseFloat(invoice_item.shipping_per_unit))) {
            newError[key] = {
                error_type: 'ERROR',
                error_msg: `Enter a shipping amount`,
            };
        } else if (
            parseFloat(invoice_item.shipping_per_unit) !==
            parseFloat(po_item.pricing_information.shipping_per_unit)
        ) {
            newError[key] = {
                error_type: 'WARNING',
                error_msg: `Mismatch from PO item shipping`,
            };
        } else {
            delete newError[key];
        }
    }

    if (field === 'additional_charges' || field === 'ALL') {
        let total_PO_tax_perc = 0;
        po_item.pricing_information.additional_charges.forEach((tax) => {
            total_PO_tax_perc += parseFloat(tax.charge_value);
        });
        let total_invoice_tax_perc = 0;
        invoice_item.additional_charges.forEach((tax: any) => {
            total_invoice_tax_perc += parseFloat(tax.charge_value);
        });
        let key = `additional_charges`;
        if (total_invoice_tax_perc !== total_PO_tax_perc) {
            newError[key] = {
                error_type: 'WARNING',
                error_msg: `Mismatch from PO tax for this item`,
            };
        } else {
            delete newError[key];
        }
    }
    let idx = 0;
    for (let batch of all_batches) {
        if (field === 'ALL' || batch_idx === idx) {
            if (field === 'batch_number' || field === 'ALL') {
                // check for matching batch nos
                let matching_batch_idx: number[] = [];
                all_item_data.forEach((batch, idx) => {
                    if (
                        batch_idx !== idx &&
                        batch.batch_number ===
                            all_batches[batch_idx].batch_number
                    ) {
                        matching_batch_idx.push(idx + 1);
                    }
                });
                let key = `batch_number_${batch_idx}`;
                if (matching_batch_idx.length > 0) {
                    newError[key] = {
                        error_type: 'ERROR',
                        error_msg: `Matches with batch ${matching_batch_idx.join(
                            ', '
                        )}`,
                    };
                }
                // check if batch no is present
                else if (batch.batch_number.trim().length === 0) {
                    newError[key] = {
                        error_type: 'ERROR',
                        error_msg: `Enter a valid batch no`,
                    };
                } else {
                    delete newError[key];
                }
            }
            if (field === 'quantity' || field === 'ALL') {
                // invoicable_qty account for "inoviced qty" but max_invoicable_qty account for the actual "delivered qty"
                // i.e. "inoviced qty" also accounts for the qty in transit but "delivered qty" doesn't
                // i.e So we allow user to double invoice the qty in transit and show a yellow warning
                let sum_of_all_batch_qty = 0;
                all_item_data.forEach((item) => {
                    if (!isNaN(parseFloat(item.quantity)))
                        sum_of_all_batch_qty += parseFloat(item.quantity);
                });
                let invoicable_qty = Math.max(
                    0,
                    parseFloat(po_item.quantity_information.quantity) -
                        parseFloat(po_item.fulfilment_information.invoiced) +
                        parseFloat(po_item.fulfilment_information.rescheduled) +
                        parseFloat(
                            po_item.fulfilment_information.over_delivered
                        )
                );
                let max_invoicable_qty = Math.max(
                    0,
                    parseFloat(po_item.quantity_information.quantity) -
                        parseFloat(po_item.fulfilment_information.delivered) +
                        parseFloat(po_item.fulfilment_information.rescheduled)
                );
                let key = `quantity_${batch_idx}`;
                // check if qty is valid
                if (
                    isNaN(parseFloat(batch.quantity)) ||
                    parseFloat(batch.quantity) <= 0
                ) {
                    newError[key] = {
                        error_type: 'ERROR',
                        error_msg: `Enter a valid quantity`,
                    };
                } else {
                    delete newError[key];
                }
                // qty is more than invoicable but less than max_invoicable
                if (
                    sum_of_all_batch_qty > invoicable_qty &&
                    sum_of_all_batch_qty <= max_invoicable_qty
                ) {
                    newError['quantity'] = {
                        error_type: 'WARNING',
                        error_msg: `Total exceeds remaining quantity`,
                    };
                }
                // qty is more than max_invoicable
                else if (sum_of_all_batch_qty > max_invoicable_qty) {
                    newError['quantity'] = {
                        error_type: 'ERROR',
                        error_msg: `Cannot exceed PO pending quantity`,
                    };
                } else {
                    delete newError['quantity'];
                }
            }
            if (field === 'batch_expiry_date' || field === 'ALL') {
                let key = `batch_expiry_date_${batch_idx}`;
                if (
                    batch.batch_expiry_date !== '' &&
                    (!moment(batch.batch_expiry_date).isValid() ||
                        moment(batch.batch_expiry_date).isBefore(
                            moment().format('YYYY-MM-DD')
                        ))
                ) {
                    newError[key] = {
                        error_type: 'ERROR',
                        error_msg: `Please enter a valid date`,
                    };
                } else {
                    delete newError[key];
                }
            }
            let idx = 0;
            for (let custom_field_item of batch.custom_fields.items) {
                let key = `custom_field_${batch_idx}_${idx}`;
                if (
                    (field === key || field === 'ALL') &&
                    custom_field_item.is_required &&
                    custom_field_item.value === ''
                ) {
                    newError[key] = {
                        error_type: 'ERROR',
                        error_msg: `Please enter a value`,
                    };
                } else if (
                    custom_field_item.template_item_type ===
                        CustomTemplateItemTypes.FLOAT &&
                    isNaN(+custom_field_item.value)
                ) {
                    newError[key] = {
                        error_type: 'ERROR',
                        error_msg: `Please enter a valid number`,
                    };
                } else {
                    delete newError[key];
                }
                idx++;
            }
        }
        idx++;
    }
    return newError;
};

export const validateNewInvoiceItemDetails = (
    invoice_item: INewInvoiceItemDetail,
    field:
        | keyof INewInvoiceItemDetail
        | keyof INewInvoiceBatchDetail
        | 'DELETE'
        | 'ALL',
    batch_idx: number,
    errors: {
        [key: string]: { error_type: 'ERROR' | 'WARNING'; error_msg: string };
    },
    po_item: IPurchaseOrderItem
): {
    [key: string]: { error_type: 'ERROR' | 'WARNING'; error_msg: string };
} => {
    let newError = { ...errors };

    // clearing all errors
    if (field === 'DELETE') {
        newError = {};
    }
    // pricing
    if (field === 'price' || field === 'ALL') {
        let key = `price`;
        if (
            isNaN(parseFloat(invoice_item.price)) ||
            parseFloat(invoice_item.price) <= 0
        ) {
            newError[key] = {
                error_type: 'ERROR',
                error_msg: `Enter a valid price`,
            };
        } else if (
            parseFloat(invoice_item.price) !==
            parseFloat(po_item.pricing_information.price)
        ) {
            newError[key] = {
                error_type: 'WARNING',
                error_msg: `Mismatch from PO price`,
            };
        } else {
            delete newError[key];
        }
    }

    // checking shipping
    if (field === 'shipping_per_unit' || field === 'ALL') {
        let key = `shipping_per_unit`;
        if (isNaN(parseFloat(invoice_item.shipping_per_unit))) {
            newError[key] = {
                error_type: 'ERROR',
                error_msg: `Enter a shipping amount`,
            };
        } else if (
            parseFloat(invoice_item.shipping_per_unit) !==
            parseFloat(po_item.pricing_information.shipping_per_unit)
        ) {
            newError[key] = {
                error_type: 'WARNING',
                error_msg: `Mismatch from PO item shipping`,
            };
        } else {
            delete newError[key];
        }
    }

    // tax of invoice item
    if (field === 'additional_charges' || field === 'ALL') {
        let total_PO_tax_perc = 0;
        po_item.pricing_information.additional_charges.forEach((tax) => {
            total_PO_tax_perc += parseFloat(tax.charge_value);
        });
        let total_invoice_tax_perc = 0;
        invoice_item.additional_charges.forEach((tax: any) => {
            total_invoice_tax_perc += parseFloat(tax.charge_value);
        });
        let key = `additional_charges`;
        if (total_invoice_tax_perc !== total_PO_tax_perc) {
            newError[key] = {
                error_type: 'WARNING',
                error_msg: `Mismatch from PO tax for this item`,
            };
        } else {
            delete newError[key];
        }
    }

    // checking every batch
    invoice_item.batch_items.forEach((outerBatch, outIdx) => {
        // batch number error handling
        if (field === 'ALL' || field === 'custom_batch_id') {
            let keyForBatchNumberError = `batch_number_${outIdx}`;
            let matching_batch_idx: number[] = [];
            invoice_item.batch_items.forEach((innerBatch, innerIdx) => {
                if (
                    outIdx !== innerIdx &&
                    outerBatch.custom_batch_id === innerBatch.custom_batch_id
                ) {
                    matching_batch_idx.push(innerIdx + 1);
                }
            });
            if (matching_batch_idx.length > 0) {
                newError[keyForBatchNumberError] = {
                    error_type: 'ERROR',
                    error_msg: `Matches with batch ${matching_batch_idx.join(
                        ','
                    )}`,
                };
            } else if (outerBatch.custom_batch_id.trim().length === 0) {
                newError[keyForBatchNumberError] = {
                    error_type: 'ERROR',
                    error_msg: 'Enter a valid batch no',
                };
            } else {
                delete newError[keyForBatchNumberError];
            }
        }

        // batch quantiy error handling
        if (field === 'ALL' || field === 'quantity') {
            let invoicable_qty = Math.max(
                0,
                parseFloat(po_item.quantity_information.quantity) -
                    parseFloat(po_item.fulfilment_information.invoiced) +
                    parseFloat(po_item.fulfilment_information.rescheduled) +
                    parseFloat(po_item.fulfilment_information.over_delivered)
            );
            let max_invoicable_qty = Math.max(
                0,
                parseFloat(po_item.quantity_information.quantity) -
                    parseFloat(po_item.fulfilment_information.delivered) +
                    parseFloat(po_item.fulfilment_information.rescheduled)
            );
            let keyForBatchQuantityError = `quantity_${outIdx}`;
            if (
                isNaN(parseFloat(outerBatch.quantity)) ||
                parseFloat(outerBatch.quantity) <= 0
            ) {
                newError[keyForBatchQuantityError] = {
                    error_type: 'ERROR',
                    error_msg: `Enter a valid quantity`,
                };
            } else {
                delete newError[keyForBatchQuantityError];
            }

            // qty is more than invoicable but less than max_invoicable
            let sum_of_all_batch_qty = !isNaN(parseFloat(invoice_item.quantity))
                ? parseFloat(invoice_item.quantity)
                : 0;
            if (
                sum_of_all_batch_qty > invoicable_qty &&
                sum_of_all_batch_qty <= max_invoicable_qty
            ) {
                newError['quantity'] = {
                    error_type: 'WARNING',
                    error_msg: `Total exceeds remaining quantity`,
                };
            }
            // qty is more than max_invoicable
            else if (sum_of_all_batch_qty > max_invoicable_qty) {
                newError['quantity'] = {
                    error_type: 'ERROR',
                    error_msg: `Cannot exceed PO pending quantity`,
                };
            } else {
                delete newError['quantity'];
            }
        }

        if (field === 'batch_expiry_date' || field === 'ALL') {
            let key = `batch_expiry_date_${outIdx}`;
            if (
                (outerBatch.batch_expiry_date !== '' &&
                    outerBatch.batch_expiry_date !== null &&
                    !moment(outerBatch.batch_expiry_date).isValid()) ||
                moment(outerBatch.batch_expiry_date).isBefore(
                    moment().format('YYYY-MM-DD')
                )
            ) {
                newError[key] = {
                    error_type: 'ERROR',
                    error_msg: `Please enter a valid date`,
                };
            } else {
                delete newError[key];
            }
        }

        // custom fields

        outerBatch.custom_fields?.template_items?.forEach(
            (custom_field_item, custom_field_item_idx) => {
                let key = `custom_field_${batch_idx}_${custom_field_item_idx}`;
                if (
                    (field === 'custom_fields' || field === 'ALL') &&
                    custom_field_item.is_required &&
                    custom_field_item.value === ''
                ) {
                    newError[key] = {
                        error_type: 'ERROR',
                        error_msg: `Please enter a value`,
                    };
                } else if (
                    custom_field_item.template_item_type ===
                        CustomTemplateItemTypes.FLOAT &&
                    isNaN(+custom_field_item.value)
                ) {
                    newError[key] = {
                        error_type: 'ERROR',
                        error_msg: `Please enter a valid number`,
                    };
                } else {
                    delete newError[key];
                }
            }
        );
    });
    return newError;
};

export const validateInvoiceItemDetailsForTier2Invoice = (
    invoice_item: IPostInvoiceItemDetails,
    field: string,
    batch_idx: number,
    errors: {
        [key: string]: { error_type: 'ERROR' | 'WARNING'; error_msg: string };
    },
    po_item: IPurchaseOrderItem
): {
    [key: string]: { error_type: 'ERROR' | 'WARNING'; error_msg: string };
} => {
    let newError = cloneDeep(errors);
    if (field === 'DELETE' && batch_idx >= 0) {
        delete newError[`batch_number_${batch_idx}`];
        delete newError[`quantity_${batch_idx}`];
        delete newError[`quantity`];
        delete newError[`price_${batch_idx}`];
        delete newError[`shipping_per_unit_${batch_idx}`];
        delete newError[`additional_charges_${batch_idx}`];
        delete newError[`batch_expiry_date_${batch_idx}`];
    }
    if (field === 'price' || field === 'ALL') {
        let key = `price`;
        if (
            isNaN(parseFloat(invoice_item.price)) ||
            parseFloat(invoice_item.price) <= 0
        ) {
            newError[key] = {
                error_type: 'ERROR',
                error_msg: `Enter a valid price`,
            };
        } else if (
            parseFloat(invoice_item.price) !==
            parseFloat(po_item.pricing_information.price)
        ) {
            newError[key] = {
                error_type: 'WARNING',
                error_msg: `Mismatch from PO price`,
            };
        } else {
            delete newError[key];
        }
    }
    if (field === 'shipping_per_unit' || field === 'ALL') {
        let key = `shipping_per_unit`;
        if (isNaN(parseFloat(invoice_item.shipping_per_unit))) {
            newError[key] = {
                error_type: 'ERROR',
                error_msg: `Enter a shipping amount`,
            };
        } else if (
            parseFloat(invoice_item.shipping_per_unit) !==
            parseFloat(po_item.pricing_information.shipping_per_unit)
        ) {
            newError[key] = {
                error_type: 'WARNING',
                error_msg: `Mismatch from PO item shipping`,
            };
        } else {
            delete newError[key];
        }
    }

    if (field === 'additional_charges' || field === 'ALL') {
        let total_PO_tax_perc = 0;
        po_item.pricing_information.additional_charges.forEach((tax) => {
            total_PO_tax_perc += parseFloat(tax.charge_value);
        });
        let total_invoice_tax_perc = 0;
        invoice_item.additional_charges.forEach((tax: any) => {
            total_invoice_tax_perc += parseFloat(tax.charge_value);
        });
        let key = `additional_charges`;
        if (total_invoice_tax_perc !== total_PO_tax_perc) {
            newError[key] = {
                error_type: 'WARNING',
                error_msg: `Mismatch from PO tax for this item`,
            };
        } else {
            delete newError[key];
        }
    }
    let idx = 0;
    let all_batches = invoice_item.all_batch_info;
    for (let batch of all_batches) {
        if (field === 'ALL' || batch_idx === idx) {
            if (field === 'batch_expiry_date' || field === 'ALL') {
                let key = `batch_expiry_date_${batch_idx}`;
                if (
                    batch.batch_expiry_date !== '' &&
                    (!moment(batch.batch_expiry_date).isValid() ||
                        moment(batch.batch_expiry_date).isBefore(
                            moment().format('YYYY-MM-DD')
                        ))
                ) {
                    newError[key] = {
                        error_type: 'ERROR',
                        error_msg: `Please enter a valid date`,
                    };
                } else {
                    delete newError[key];
                }
            }
        }
        idx++;
    }
    return newError;
};

// this function is similar to the previous validateInvoiceItemDetails popup but only marks warnings when the invoice item details are not better (worse) than the PO item details
export const validateInvoiceItemDetailsForInvoiceHoldPopup = (
    invoice_item: IPostInvoiceItemDetails,
    field: string,
    batch_idx: number,
    errors: {
        [key: string]: { error_type: 'ERROR' | 'WARNING'; error_msg: string };
    },
    validate_batch_and_qty = true,
    po_item: IPurchaseOrderItem
): {
    [key: string]: { error_type: 'ERROR' | 'WARNING'; error_msg: string };
} => {
    let all_item_data = invoice_item.all_batch_info;
    let all_batches = invoice_item.all_batch_info;
    let newError = cloneDeep(errors);
    if (field === 'DELETE') {
        delete newError[`batch_number_${batch_idx}`];
        delete newError[`quantity_${batch_idx}`];
        delete newError[`quantity`];
        delete newError[`price_${batch_idx}`];
        delete newError[`shipping_per_unit_${batch_idx}`];
        delete newError[`additional_charges_${batch_idx}`];
        delete newError[`batch_expiry_date_${batch_idx}`];
    }
    if (field === 'price' || field === 'ALL') {
        let key = `price`;
        if (
            isNaN(parseFloat(invoice_item.price)) ||
            parseFloat(invoice_item.price) <= 0
        ) {
            newError[key] = {
                error_type: 'ERROR',
                error_msg: `Enter a valid price`,
            };
        } else if (
            parseFloat(invoice_item.price) >
            parseFloat(po_item.pricing_information.price)
        ) {
            newError[key] = {
                error_type: 'WARNING',
                error_msg: `Mismatch from PO price`,
            };
        } else {
            delete newError[key];
        }
    }
    if (field === 'shipping_per_unit' || field === 'ALL') {
        let key = `shipping_per_unit`;
        if (isNaN(parseFloat(invoice_item.shipping_per_unit))) {
            newError[key] = {
                error_type: 'ERROR',
                error_msg: `Enter a shipping amount`,
            };
        } else if (
            parseFloat(invoice_item.shipping_per_unit) >
            parseFloat(po_item.pricing_information.shipping_per_unit)
        ) {
            newError[key] = {
                error_type: 'WARNING',
                error_msg: `Mismatch from PO item shipping`,
            };
        } else {
            delete newError[key];
        }
    }
    if (field === 'additional_charges' || field === 'ALL') {
        let total_PO_tax_perc = 0;
        po_item.pricing_information.additional_charges.forEach((tax) => {
            total_PO_tax_perc += parseFloat(tax.charge_value);
        });
        let total_invoice_tax_perc = 0;
        invoice_item.additional_charges.forEach((tax: any) => {
            total_invoice_tax_perc += parseFloat(tax.charge_value);
        });
        let key = `additional_charges`;
        if (total_invoice_tax_perc > total_PO_tax_perc) {
            newError[key] = {
                error_type: 'WARNING',
                error_msg: `Mismatch from PO tax for this item`,
            };
        } else {
            delete newError[key];
        }
    }

    // check for matching batch nos
    for (let batch of all_batches) {
        let matching_batch_idx: number[] = [];
        all_item_data.forEach((batch, idx) => {
            if (
                batch_idx !== idx &&
                batch.batch_number === all_batches[batch_idx].batch_number
            ) {
                matching_batch_idx.push(idx + 1);
            }
        });
        if (
            validate_batch_and_qty &&
            (field === 'batch_number' || field === 'ALL')
        ) {
            let key = `batch_number_${batch_idx}`;
            if (matching_batch_idx.length > 0) {
                newError[key] = {
                    error_type: 'ERROR',
                    error_msg: `Matches with batch ${matching_batch_idx.join(
                        ', '
                    )}`,
                };
            }
            // check if batch no is present
            else if (batch.batch_number.trim().length === 0) {
                newError[key] = {
                    error_type: 'ERROR',
                    error_msg: `Enter a valid batch no`,
                };
            } else {
                delete newError[key];
            }
        }
        if (
            validate_batch_and_qty &&
            (field === 'quantity' || field === 'ALL')
        ) {
            // invoicable_qty account for "inoviced qty" but max_invoicable_qty account for the actual "delivered qty"
            // i.e. "inoviced qty" also accounts for the qty in transit but "delivered qty" doesn't
            // i.e So we allow user to double invoice the qty in transit and show a yellow warning
            let sum_of_all_batch_qty = 0;
            all_item_data.forEach((item) => {
                if (!isNaN(parseFloat(item.quantity)))
                    sum_of_all_batch_qty += parseFloat(item.quantity);
            });
            // let invoicable_qty = Math.max(
            //     0,
            //     parseFloat(po_item.quantity_information.quantity) -
            //         parseFloat(po_item.fulfilment_information.invoiced) +
            //         parseFloat(po_item.fulfilment_information.rescheduled) +
            //         parseFloat(po_item.fulfilment_information.over_delivered)
            // );
            let max_invoicable_qty = Math.max(
                0,
                parseFloat(po_item.quantity_information.quantity) -
                    parseFloat(po_item.fulfilment_information.delivered) +
                    parseFloat(po_item.fulfilment_information.rescheduled)
            );
            let key = `quantity_${batch_idx}`;
            // check if qty is valid
            if (
                isNaN(parseFloat(batch.quantity)) ||
                parseFloat(batch.quantity) <= 0
            ) {
                newError[key] = {
                    error_type: 'ERROR',
                    error_msg: `Enter a valid quantity`,
                };
            } else {
                delete newError[key];
            }
            if (sum_of_all_batch_qty > max_invoicable_qty) {
                newError['quantity'] = {
                    error_type: 'ERROR',
                    error_msg: `Cannot exceed PO pending quantity`,
                };
            } else {
                delete newError['quantity'];
            }
        }
        if (field === 'batch_expiry_date' || field === 'ALL') {
            let key = `batch_expiry_date_${batch_idx}`;
            if (
                batch.batch_expiry_date !== '' &&
                !moment(batch.batch_expiry_date).isValid()
            ) {
                newError[key] = {
                    error_type: 'ERROR',
                    error_msg: `Please enter a valid date`,
                };
            } else {
                delete newError[key];
            }
        }
        let idx = 0;
        for (let custom_field_item of batch.custom_fields.items) {
            let key = `custom_field_${batch_idx}_${idx}`;
            if (
                (field === key || field === 'ALL') &&
                custom_field_item.is_required &&
                custom_field_item.value === ''
            ) {
                newError[key] = {
                    error_type: 'ERROR',
                    error_msg: `Please enter a value`,
                };
            } else if (
                custom_field_item.template_item_type ===
                    CustomTemplateItemTypes.FLOAT &&
                isNaN(+custom_field_item.value)
            ) {
                newError[key] = {
                    error_type: 'ERROR',
                    error_msg: `Please enter a valid number`,
                };
            } else {
                delete newError[key];
            }
            idx++;
        }
    }
    return newError;
};

export const validateNewInvoiceItemDetailsForInvoiceHoldPopup = (
    invoice_item: INewInvoiceItemDetail,
    field:
        | keyof INewInvoiceItemDetail
        | keyof INewInvoiceBatchDetail
        | 'DELETE'
        | 'ALL',
    batch_idx: number,
    errors: {
        [key: string]: { error_type: 'ERROR' | 'WARNING'; error_msg: string };
    },
    validate_batch_and_qty = true,
    po_item: IPurchaseOrderItem
): {
    [key: string]: { error_type: 'ERROR' | 'WARNING'; error_msg: string };
} => {
    let newError = { ...errors };

    // clearing all errors
    if (field === 'DELETE') {
        newError = {};
    }
    // pricing
    if (field === 'price' || field === 'ALL') {
        let key = `price`;
        if (
            isNaN(parseFloat(invoice_item.price)) ||
            parseFloat(invoice_item.price) <= 0
        ) {
            newError[key] = {
                error_type: 'ERROR',
                error_msg: `Enter a valid price`,
            };
        } else if (
            parseFloat(invoice_item.price) >
            parseFloat(po_item.pricing_information.price)
        ) {
            newError[key] = {
                error_type: 'WARNING',
                error_msg: `Mismatch from PO price`,
            };
        } else {
            delete newError[key];
        }
    }

    // checking shipping
    if (field === 'shipping_per_unit' || field === 'ALL') {
        let key = `shipping_per_unit`;
        if (isNaN(parseFloat(invoice_item.shipping_per_unit))) {
            newError[key] = {
                error_type: 'ERROR',
                error_msg: `Enter a shipping amount`,
            };
        } else if (
            parseFloat(invoice_item.shipping_per_unit) !==
            parseFloat(po_item.pricing_information.shipping_per_unit)
        ) {
            newError[key] = {
                error_type: 'WARNING',
                error_msg: `Mismatch from PO item shipping`,
            };
        } else {
            delete newError[key];
        }
    }

    // tax of invoice item
    if (field === 'additional_charges' || field === 'ALL') {
        let total_PO_tax_perc = 0;
        po_item.pricing_information.additional_charges.forEach((tax) => {
            total_PO_tax_perc += parseFloat(tax.charge_value);
        });
        let total_invoice_tax_perc = 0;
        invoice_item.additional_charges.forEach((tax: any) => {
            total_invoice_tax_perc += parseFloat(tax.charge_value);
        });
        let key = `additional_charges`;
        if (total_invoice_tax_perc !== total_PO_tax_perc) {
            newError[key] = {
                error_type: 'WARNING',
                error_msg: `Mismatch from PO tax for this item`,
            };
        } else {
            delete newError[key];
        }
    }

    // checking every batch
    invoice_item.batch_items.forEach(
        (outerBatch: INewInvoiceBatchDetail, outIdx: number) => {
            // batch number error handling
            if (
                validate_batch_and_qty &&
                (field === 'ALL' || field === 'custom_batch_id')
            ) {
                let keyForBatchNumberError = `batch_number_${outIdx}`;
                let matching_batch_idx: number[] = [];
                invoice_item.batch_items.forEach((innerBatch, innerIdx) => {
                    if (
                        outIdx !== innerIdx &&
                        outerBatch.custom_batch_id ===
                            innerBatch.custom_batch_id
                    ) {
                        matching_batch_idx.push(innerIdx + 1);
                    }
                });
                if (matching_batch_idx.length > 0) {
                    newError[keyForBatchNumberError] = {
                        error_type: 'ERROR',
                        error_msg: `Matches with batch ${matching_batch_idx.join(
                            ','
                        )}`,
                    };
                } else if (outerBatch.custom_batch_id.trim().length === 0) {
                    newError[keyForBatchNumberError] = {
                        error_type: 'ERROR',
                        error_msg: 'Enter a valid batch no',
                    };
                } else {
                    delete newError[keyForBatchNumberError];
                }
            }

            // batch quantiy error handling
            if (
                validate_batch_and_qty &&
                (field === 'ALL' || field === 'quantity')
            ) {
                // let invoicable_qty = Math.max(
                //     0,
                //     parseFloat(po_item.quantity_information.quantity) -
                //     parseFloat(po_item.fulfilment_information.invoiced) +
                //     parseFloat(po_item.fulfilment_information.rescheduled) +
                //     parseFloat(
                //         po_item.fulfilment_information.over_delivered
                //     )
                // );
                let max_invoicable_qty = Math.max(
                    0,
                    parseFloat(po_item.quantity_information.quantity) -
                        parseFloat(po_item.fulfilment_information.delivered) +
                        parseFloat(po_item.fulfilment_information.rescheduled)
                );
                let keyForBatchQuantityError = `quantity_${outIdx}`;
                if (
                    isNaN(parseFloat(outerBatch.quantity)) ||
                    parseFloat(outerBatch.quantity) <= 0
                ) {
                    newError[keyForBatchQuantityError] = {
                        error_type: 'ERROR',
                        error_msg: `Enter a valid quantity`,
                    };
                } else {
                    delete newError[keyForBatchQuantityError];
                }

                if (
                    isNaN(parseFloat(outerBatch.quantity)) ||
                    parseFloat(outerBatch.quantity) <= 0
                ) {
                    newError[keyForBatchQuantityError] = {
                        error_type: 'ERROR',
                        error_msg: `Enter a valid quantity`,
                    };
                } else {
                    delete newError[keyForBatchQuantityError];
                }

                // qty is more than invoicable but less than max_invoicable
                let sum_of_all_batch_qty = !isNaN(
                    parseFloat(invoice_item.quantity)
                )
                    ? parseFloat(invoice_item.quantity)
                    : 0;
                if (sum_of_all_batch_qty <= max_invoicable_qty) {
                    newError['quantity'] = {
                        error_type: 'WARNING',
                        error_msg: `Total exceeds remaining quantity`,
                    };
                }
                // qty is more than max_invoicable
                if (sum_of_all_batch_qty > max_invoicable_qty) {
                    newError['quantity'] = {
                        error_type: 'ERROR',
                        error_msg: `Cannot exceed PO pending quantity`,
                    };
                } else {
                    delete newError['quantity'];
                }
            }

            if (field === 'batch_expiry_date' || field === 'ALL') {
                let key = `batch_expiry_date_${batch_idx}`;
                if (
                    outerBatch.batch_expiry_date !== '' &&
                    outerBatch.batch_expiry_date !== null &&
                    (!moment(outerBatch.batch_expiry_date).isValid() ||
                        moment(outerBatch.batch_expiry_date).isBefore(
                            moment().format('YYYY-MM-DD')
                        ))
                ) {
                    newError[key] = {
                        error_type: 'ERROR',
                        error_msg: `Please enter a valid date`,
                    };
                } else {
                    delete newError[key];
                }
            }

            // custom fields
            outerBatch.custom_fields?.template_items?.forEach(
                (custom_field_item, custom_field_item_idx) => {
                    let key = `custom_field_${batch_idx}_${custom_field_item_idx}`;
                    if (
                        (field === 'custom_fields' || field === 'ALL') &&
                        custom_field_item.is_required &&
                        custom_field_item.value.trim() === ''
                    ) {
                        newError[key] = {
                            error_type: 'ERROR',
                            error_msg: `Please enter a value`,
                        };
                    } else if (
                        custom_field_item.template_item_type ===
                            CustomTemplateItemTypes.FLOAT &&
                        isNaN(+custom_field_item.value)
                    ) {
                        newError[key] = {
                            error_type: 'ERROR',
                            error_msg: `Please enter a valid number`,
                        };
                    } else {
                        delete newError[key];
                    }
                }
            );
        }
    );
    return newError;
};

// this function is similar to the previous validateInvoiceItemDetails popup but only marks warnings when the invoice item details are not better (worse) than the PO item details
export const validateProformaInvoiceItemDetails = (
    invoice_item: INewInvoiceItemDetail,
    field: string,
    batch_idx: number,
    errors: {
        [key: string]: { error_type: 'ERROR' | 'WARNING'; error_msg: string };
    },
    invoicePendingAmt: string
): {
    [key: string]: { error_type: 'ERROR' | 'WARNING'; error_msg: string };
} => {
    let newError = cloneDeep(errors);
    if (field === 'DELETE') {
        delete newError[`price_${batch_idx}`];
    }
    if (field === 'price' || field === 'ALL') {
        let key = `price`;
        if (
            isNaN(parseFloat(invoice_item.price)) ||
            parseFloat(invoice_item.price) <= 0
        ) {
            newError[key] = {
                error_type: 'ERROR',
                error_msg: `Enter a valid Prepayment Amount`,
            };
        } else if (parseFloat(invoice_item.price) > +invoicePendingAmt) {
            newError[key] = {
                error_type: 'ERROR',
                error_msg: `Prepayment amount cannot exceed ${FWNumberFormatter(
                    +invoicePendingAmt
                )}`,
            };
        } else {
            delete newError[key];
        }
    }
    return newError;
};

export const invoiceItemEffectiveRate = (
    price: string,
    shipping_per_unit: string,
    additional_charges: { charge_name: string; charge_value: string }[],
    discount_percentage: number
): number => {
    let baseRate = parseFloat(price);
    let itemTaxPercentage = 0;
    for (let tax of additional_charges) {
        itemTaxPercentage += parseFloat(tax.charge_value);
    }
    let taxRate = (itemTaxPercentage / 100) * baseRate;
    let shippingRate = parseFloat(shipping_per_unit);
    let rateBeforeDiscount = baseRate + taxRate + shippingRate;
    let discountRate = (discount_percentage / 100) * rateBeforeDiscount;
    let rateAfterDiscount = rateBeforeDiscount - discountRate;
    return rateAfterDiscount;
};

export const invoiceItemPayableQuantity = (
    invoiceItem: INewGetInvoiceBatchDetail
): number => {
    return (
        parseFloat(invoiceItem.quantity_information.batch_quantity) -
        parseFloat(
            invoiceItem.fulfilment_information.gr_quantity_rejected
                ? invoiceItem.fulfilment_information.gr_quantity_rejected
                : '0'
        ) -
        parseFloat(
            invoiceItem.fulfilment_information.qc_quantity_rejected
                ? invoiceItem.fulfilment_information.qc_quantity_rejected
                : '0'
        ) -
        parseFloat(
            invoiceItem.fulfilment_information.quantity_terminated
                ? invoiceItem.fulfilment_information.quantity_terminated
                : '0'
        )
    );
};

export const newInvoiceItemBatchPayableQuantity = (
    invoiceItemBatch: INewGetInvoiceBatchDetail
): number => {
    return (
        parseFloat(invoiceItemBatch.quantity_information.batch_quantity) -
        parseFloat(
            invoiceItemBatch.fulfilment_information.gr_quantity_rejected
                ? invoiceItemBatch.fulfilment_information.gr_quantity_rejected
                : '0'
        ) -
        parseFloat(
            invoiceItemBatch.fulfilment_information.qc_quantity_rejected
                ? invoiceItemBatch.fulfilment_information.qc_quantity_rejected
                : '0'
        ) -
        parseFloat(
            invoiceItemBatch.fulfilment_information.quantity_terminated
                ? invoiceItemBatch.fulfilment_information.quantity_terminated
                : '0'
        )
    );
};

export const invoiceItemNetPaidQuantity = (
    price: string,
    shipping_per_unit: string,
    additional_charges: { charge_name: string; charge_value: string }[],
    invoiceItem: INewGetInvoiceItemDetail,
    discount_percentage: number
): number => {
    let paidValue =
        invoiceItem.payment_information.completed &&
        !isNaN(parseFloat(invoiceItem.payment_information.completed))
            ? parseFloat(invoiceItem.payment_information.completed)
            : 0;

    let creditValue =
        invoiceItem.payment_information.credited &&
        !isNaN(parseFloat(invoiceItem.payment_information.credited))
            ? parseFloat(invoiceItem.payment_information.credited)
            : 0;

    let netPaidValue = paidValue - creditValue;
    let effectiveRate = invoiceItemEffectiveRate(
        price,
        shipping_per_unit,
        additional_charges,
        discount_percentage
    );
    let netPaidQty = netPaidValue / effectiveRate;

    return netPaidQty;
};

export const initialInvoiceData = {
    invoice_id: '',
    invoice_type: InvoiceType.SELLER_GOODS,
    status: InvoiceStatus.DELETED,
    custom_invoice_id: '',
    purchase_order: '',
    created_by_user_name: '',
    event: null,
    buyer_enterprise: '',
    buyer_entity: '',
    buyer_information: {
        buyer_entity_name: '',
        buyer_entity_address: '',
        buyer_identification_info: [],
        billing_address_id: '',
        billing_address: '',
        shipping_address_id: '',
        shipping_address: '',
    },

    seller_enterprise: '',
    seller_entity: '',
    seller_information: {
        seller_entity_name: '',
        seller_entity_address: '',
        seller_identification_information: [],
        seller_address_id: '',
    },

    invoice_date: null,
    dispatch_date: null,
    expected_delivery_date: null,
    pricing_information: {
        total: '0',
        shipping: '0',
        subtotal: '0',
        currency_name: '',
        currency_symbol: '',
        taxes_percentage: '0',
        discount_percentage: '0',
        currency_code_abbreviation: '',
    },
    notes: '',
    approver_information: null,
    invoice_items: [],
};

export const createInvoiceInitialState: INewPostInvoiceDetails = {
    purchase_order_id: '',
    custom_invoice_id: '',
    reference_id: null,
    invoice_date: moment().format('YYYY-MM-DD'),
    seller_identifications: [],
    seller_address_id: '',
    action: 'DRAFT',
    notes: '',
    discount_percentage: '',
    expected_delivery_date: '',
    dispatch_date: '',
    invoice_items: [],
    attachment_ids: [],
    seller_custom_identifications: [],
    reference_dates: null,
};

export const groupBatchesByPoItemId = (batch_items: IInvoiceItem[]) => {
    let grouped_batch_items: { [keys: string]: IPostInvoiceItemDetails } = {};
    for (let batch of batch_items) {
        let po_item_id = batch.purchase_order_item.purchase_order_item_id;
        let batch_line_item = {
            invoice_item_id: batch.invoice_item_id,
            item_batch_id: batch.item_batch_id,
            batch_expiry_date: batch.batch_expiry_date,
            batch_number: batch.batch_number,
            quantity: batch.quantity_information.quantity,
            measurement_unit_id: batch.quantity_information.measurement_unit_id,
            notes: batch.notes,
            fulfillment_information: {
                gr_acceptance: +batch.fulfilment_information.gr_acceptance,
                gr_overdelivery: +batch.fulfilment_information.gr_overdeliver,
                gr_rejection: +batch.fulfilment_information.gr_rejection,
                qc_rejection: +batch.fulfilment_information.qc_rejection,
                invoice_termination_rejection: 0,
            },
            custom_fields: batch.custom_fields,
        };
        if (
            !(
                batch.purchase_order_item.purchase_order_item_id in
                grouped_batch_items
            )
        ) {
            grouped_batch_items[po_item_id] = {
                purchase_order_item_id:
                    batch.purchase_order_item.purchase_order_item_id,
                price: batch.pricing_information.price,
                currency_code_id: batch.pricing_information.currency_code_id,
                shipping_per_unit: batch.pricing_information.shipping_per_unit,
                additional_charges:
                    batch.pricing_information.additional_charges,
                payment_terms: batch.payment_terms_information.payment_terms,
                payment_terms_period:
                    batch.payment_terms_information.payment_terms_period,
                payment_terms_applied_from:
                    batch.payment_terms_information.payment_terms_applied_from,
                all_batch_info: [],
            };
        }
        grouped_batch_items[po_item_id].all_batch_info.push(batch_line_item);
    }
    return grouped_batch_items;
};

export const groupPrepayBatchesByPoItemId = (
    batch_items: IPrepaymentInvoiceItemDetails[]
) => {
    let grouped_batch_items: { [keys: string]: IPostInvoiceItemDetails } = {};
    for (let batch of batch_items) {
        let po_item_id = batch.purchase_order_item.purchase_order_item_id;
        let batch_line_item = {
            invoice_item_id: batch.invoice_item_id,
            item_batch_id: batch.batch_number ?? '',
            batch_expiry_date: batch.batch_expiry_date ?? '',
            batch_number: batch.batch_number ?? '',
            quantity: batch.quantity_information.quantity,
            measurement_unit_id: batch.quantity_information.measurement_unit_id,
            notes: batch.notes ?? '',
            custom_fields: {
                template_id: '',
                template_name: '',
                type: CustomTemplateTypes.INVOICE_BATCH_ITEM_CUSTOM_FIELDS,
                enterprise: '',
                entity: '',
                items: [],
            },
        };
        if (
            !(
                batch.purchase_order_item.purchase_order_item_id in
                grouped_batch_items
            )
        ) {
            grouped_batch_items[po_item_id] = {
                purchase_order_item_id:
                    batch.purchase_order_item.purchase_order_item_id,
                price: batch.pricing_information.total_amount,
                currency_code_id: batch.pricing_information.currency_code_id,
                shipping_per_unit: '0',
                additional_charges: [],
                payment_terms:
                    batch.payment_terms_information === null
                        ? ''
                        : batch.payment_terms_information.payment_terms,
                payment_terms_period:
                    batch.payment_terms_information === null
                        ? ''
                        : batch.payment_terms_information.payment_terms_period,
                payment_terms_applied_from:
                    batch.payment_terms_information === null
                        ? ''
                        : batch.payment_terms_information
                              .payment_terms_applied_from,
                all_batch_info: [],
            };
        }
        grouped_batch_items[po_item_id].all_batch_info.push(batch_line_item);
    }
    return grouped_batch_items;
};

export const getPOStatus = (
    po_status: string,
    is_termination_requested: boolean,
    is_on_hold: boolean,
    is_terminated: boolean = false
) => {
    if (is_terminated) {
        return 'TERMINATED';
    } else if (is_termination_requested) {
        return 'TERMINATION_REQUESTED';
    } else if (is_on_hold) {
        return 'ON_HOLD';
    } else {
        return po_status;
    }
};

export const transformINewInvoiceToInewPostInvoiceDetails = (
    apiInvoice: INewInvoice
): INewPostInvoiceDetails => {
    const invoice_items = apiInvoice.invoice_items.map(
        (invoice_item): INewInvoiceItemDetail => {
            const batch_items = invoice_item.batch_items.map(
                (item_batch): INewInvoiceBatchDetail => ({
                    quantity: item_batch.quantity_information.batch_quantity,
                    batch_expiry_date: item_batch.batch_expiry_date,
                    custom_batch_id: item_batch.custom_batch_id,
                    notes: item_batch.notes,
                    ...(item_batch.fulfilment_information && {
                        fulfillment_information: {
                            gr_acceptance:
                                parseFloat(
                                    item_batch.fulfilment_information
                                        ?.gr_quantity_accepted
                                ) ?? 0.0,
                            gr_rejection:
                                parseFloat(
                                    item_batch.fulfilment_information
                                        ?.gr_quantity_rejected
                                ) ?? 0.0,
                            gr_overdelivery:
                                parseFloat(
                                    item_batch.fulfilment_information
                                        ?.gr_quantity_over_delivered
                                ) ?? 0.0,
                            qc_rejection:
                                parseFloat(
                                    item_batch.fulfilment_information
                                        ?.qc_quantity_rejected
                                ) ?? 0.0,
                            invoice_termination_rejection:
                                parseFloat(
                                    item_batch.fulfilment_information
                                        ?.quantity_terminated
                                ) ?? 0.0,
                        },
                    }),
                    custom_fields: item_batch.custom_fields,
                })
            );

            return {
                invoice_item_id: invoice_item.invoice_item_id,
                purchase_order_item_id:
                    invoice_item.purchase_order_item.purchase_order_item_id,
                quantity: invoice_item.quantity_information.quantity,
                measurement_unit_id:
                    invoice_item.quantity_information.measurement_unit_id,
                price: invoice_item.pricing_information.price,
                currency_code_id:
                    invoice_item.pricing_information.currency_code_id,
                shipping_per_unit:
                    invoice_item.pricing_information.shipping_per_unit,
                additional_charges:
                    invoice_item.pricing_information.additional_charges,
                // payment_terms:
                //     invoice_item.payment_terms_information.payment_terms,
                // payment_terms_period:
                //     invoice_item.payment_terms_information.payment_terms_period,
                // payment_terms_applied_from:
                //     invoice_item.payment_terms_information
                //         .payment_terms_applied_from,
                payment_type: invoice_item.payment_type,
                deliverables_payment_terms:
                    invoice_item.deliverables_payment_terms,
                payment_terms: invoice_item.payment_terms,
                prepayment_percentage: invoice_item.prepayment_percentage,
                notes: invoice_item.notes,
                batch_items,
            };
        }
    );

    return {
        reference_dates: null,

        purchase_order_id: apiInvoice.purchase_order,
        custom_invoice_id: apiInvoice.custom_invoice_id,
        reference_id: apiInvoice.reference_id,
        invoice_date: apiInvoice.invoice_date ?? '',
        seller_identifications:
            apiInvoice.seller_information.seller_identification_information?.map(
                (sii) => sii.identification_id
            ) ?? [],
        seller_address_id: apiInvoice.seller_information.seller_address_id,
        action: 'DRAFT',
        notes: apiInvoice.notes,
        discount_percentage: apiInvoice.pricing_information.discount_percentage,
        expected_delivery_date: apiInvoice.expected_delivery_date,
        dispatch_date: apiInvoice.dispatch_date,
        attachment_ids:
            apiInvoice.attachment_list.attachment_items?.map(
                (ai) => ai.attachment_id
            ) ?? [],
        seller_custom_identifications: [],
        invoice_items,
    };
};

export const transformINewGetInvoiceBatchDetailToINewInvoiceBatchDetail = (
    item_batch: INewGetInvoiceBatchDetail
): INewInvoiceBatchDetail => ({
    batch_expiry_date: item_batch.batch_expiry_date,
    custom_batch_id: item_batch.custom_batch_id,
    notes: item_batch.notes,
    quantity: item_batch.quantity_information.batch_quantity,
    custom_fields: item_batch.custom_fields,
    ...(item_batch.fulfilment_information && {
        fulfillment_information: {
            gr_acceptance:
                +item_batch.fulfilment_information.gr_quantity_accepted,
            gr_overdelivery:
                +item_batch.fulfilment_information.gr_quantity_over_delivered,
            gr_rejection:
                +item_batch.fulfilment_information.gr_quantity_rejected,
            invoice_termination_rejection:
                +item_batch.fulfilment_information.quantity_terminated,
            qc_rejection:
                +item_batch.fulfilment_information.qc_quantity_rejected,
        },
    }),
});

export const transformINewGetInvoiceItemDetailToINewInvoiceItemDetail = (
    invoice_item: INewGetInvoiceItemDetail
): INewInvoiceItemDetail => ({
    additional_charges: invoice_item.pricing_information.additional_charges,
    currency_code_id: invoice_item.pricing_information.currency_code_id,
    invoice_item_id: invoice_item.invoice_item_id,
    batch_items: invoice_item.batch_items.map((item_batch) =>
        transformINewGetInvoiceBatchDetailToINewInvoiceBatchDetail(item_batch)
    ),
    measurement_unit_id: invoice_item.quantity_information.measurement_unit_id,
    notes: invoice_item.notes,
    // payment_terms: invoice_item.payment_terms_information.payment_terms,
    // payment_terms_applied_from:
    //     invoice_item.payment_terms_information.payment_terms_applied_from,
    // payment_terms_period:
    //     invoice_item.payment_terms_information.payment_terms_period,
    deliverables_payment_terms: invoice_item.deliverables_payment_terms,
    payment_terms: invoice_item.payment_terms,
    prepayment_percentage: invoice_item.prepayment_percentage,
    price: invoice_item.pricing_information.price,
    purchase_order_item_id:
        invoice_item.purchase_order_item.purchase_order_item_id,
    payment_type: invoice_item.payment_type,
    quantity: invoice_item.quantity_information.quantity,
    shipping_per_unit: invoice_item.pricing_information.shipping_per_unit,
});

export const setCustomFieldToNull = (invoice: INewPostInvoiceDetails) => {
    const newInvoice = { ...invoice };
    newInvoice.invoice_items.forEach((invoice_item) => {
        invoice_item.batch_items.forEach((batch_item) => {
            if (
                batch_item.custom_fields?.template_id === '' ||
                batch_item.custom_fields?.template_id === undefined
            ) {
                batch_item.custom_fields = null;
            }
        });
    });
    return newInvoice;
};
