import { Grid } from '@mui/material';
import { useContext } from 'react';
import CartItemAccordion from '../../Components/ItemCart/CartItemAccordion';
import useItemCart from '../../Hooks/Relationship/useItemCart';
import {
    ITEM_ANALYTICS_GAP,
    ItemCartModuleSectionEnum,
} from '../../Interfaces/ItemAnalyticsInterfaces';
import { IItemCartTemplate } from '../../Interfaces/ItemCartTemplateInterfaces';
import { ItemCartAccessContext } from '../../Pages/ItemCartAccess';
import LoadingBackDrop from '../../../Components/Shared/LoadingBackdrop';

export interface ICartMainContainerProps {
    tab: ItemCartModuleSectionEnum;
    templateDetails: IItemCartTemplate;
    cartId: string | null;
    buyerEntityId: string;
}

export default function CartMainContainer({ tab }: ICartMainContainerProps) {
    const { cartId, buyerEntityId } = useContext(ItemCartAccessContext);

    // cart id won't be null as it comes from the URL
    const { allItems, isLoading } = useItemCart(cartId!, buyerEntityId);

    return (
        <Grid
            container
            gap={ITEM_ANALYTICS_GAP}
            rowGap={5}
            marginY={4}
            marginX={4}
        >
            {isLoading && <LoadingBackDrop />}
            {tab === ItemCartModuleSectionEnum.CART &&
                allItems
                    .filter((item) => !item.save_for_later)
                    .map((item, index) => (
                        <Grid item xs={12}>
                            <CartItemAccordion
                                accordion_index={index}
                                cart_item_id={item.cart_item_id}
                                itemInCart={true}
                            />
                        </Grid>
                    ))}

            {tab === ItemCartModuleSectionEnum.SAVE_FOR_LATER &&
                allItems
                    .filter((item) => item.save_for_later)
                    .map((item, index) => (
                        <Grid item xs={12}>
                            <CartItemAccordion
                                accordion_index={index}
                                cart_item_id={item.cart_item_id}
                                itemInCart={false}
                            />
                        </Grid>
                    ))}

            {tab === ItemCartModuleSectionEnum.ALL &&
                allItems.map((cartItem, index) => (
                    <Grid item xs={12}>
                        <CartItemAccordion
                            accordion_index={index}
                            cart_item_id={cartItem.cart_item_id}
                            itemInCart={!cartItem.save_for_later}
                        />
                    </Grid>
                ))}
            {
                tab === ItemCartModuleSectionEnum.ORDERS && <></>
                // allItems.map((cartItem, index) => (
                //     <Grid item xs={12}>
                //         <CartItemAccordion
                //             accordion_index={index}
                //             cart_item_id={cartItem.cart_item_id}
                //             itemInCart={!cartItem.save_for_later}
                //         />
                //     </Grid>
                // ))
            }
        </Grid>
    );
}
