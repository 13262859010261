import { FWTypography } from '../../Common/FWTypograhy';
import { VerificationStatus } from '../../Redux/Slices/VerificationSlice';
import { verificationStatusMap } from '../Constants/VerificationConstants';

export type VerificationColours = 'success' | 'error' | 'warning' | '';

export const getVerificationStatus = (status: string) => {
    let color: VerificationColours = '';
    let text: string = verificationStatusMap[status];
    switch (status) {
        case VerificationStatus.PENDING:
            color = 'warning';
            break;
        case VerificationStatus.UNVERIFIED:
            color = 'error';
            break;
        case VerificationStatus.REJECTED:
            color = 'error';
            break;
        case VerificationStatus.VERIFIED:
            color = 'success';
            break;
    }
    return {
        color,
        text,
    };
};

const VerificationStatusChip = ({ status }: { status: string }) => {
    const { color, text } = getVerificationStatus(status);
    return <FWTypography color={color + '.main'}>{text}</FWTypography>;
};

export default VerificationStatusChip;
