import { Box, IconButton, styled } from '@mui/material';
import { useSelector } from 'react-redux';
import { FWMenuItem } from '../../Common/FWCustomMenu';
import FWIcon from '../../Common/FWIcon';
import { FWTypography } from '../../Common/FWTypograhy';
import { INotificationState } from '../../Redux/Slices/NotificationsSlice';
import { NotificationStatus } from '../Interfaces/NotificationInterfaces';

export enum MarkReadActions {
    REDIRECT = 'REDIRECT',
}

interface INotificationItemProps {
    id: string;
    emitMarkRead: (
        notificationId: string,
        notificationUrl: string,
        action?: MarkReadActions
    ) => void;
}

// const DotBadge = styled(Badge)({
//     '.MuiBadge-anchorOriginTopRightRectangular': {
//         top: '11px',
//     },
// });

const NotiDescription = styled('p')({
    whiteSpace: 'normal',
    lineHeight: '1.4',
    maxHeight: 63,
    width: '100%',
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: '3',
    WebkitBoxOrient: 'vertical',
});

export const NotificationItem = ({
    id,
    emitMarkRead,
}: INotificationItemProps) => {
    const notification = useSelector(
        ({ NotificationsStore }: { NotificationsStore: INotificationState }) =>
            NotificationsStore.notificationsList.find((noti) => noti.id === id)
    );

    const handleMarkRead = (action?: MarkReadActions) => {
        if (notification && notification.url)
            emitMarkRead(notification.id, notification.url, action);
    };

    return (
        <div style={{ width: '100%' }}>
            <FWMenuItem
                sx={{
                    cursor: notification?.url ? 'pointer' : 'default',
                    padding: '10px 16px 13px',
                    width: '100%',
                }}
                onClick={() => {
                    handleMarkRead(MarkReadActions.REDIRECT);
                }}
            >
                {/* <DotBadge
                    variant="dot"
                    badgeContent={
                        notification?.status === NotificationStatus.UNREAD
                            ? 1
                            : 0
                    }
                    color="primary"
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    style={{
                        width: '100%',
                    }}
                >
                </DotBadge> */}
                <div style={{ width: '100%' }}>
                    <div
                        className="flex flex--jcsb flex--aic"
                        style={{ height: '27px' }}
                    >
                        <FWTypography
                            sx={{
                                fontSize: '12px',
                            }}
                            color="text.secondary"
                        >
                            {notification?.time}
                        </FWTypography>
                        {notification?.status === NotificationStatus.UNREAD && (
                            <IconButton
                                color="primary"
                                className="fs--12 flex flex--aic"
                                style={{
                                    textDecoration: 'none',
                                    fontSize: '16px',
                                    padding: 0,
                                    width: 25,
                                    height: 25,
                                }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleMarkRead();
                                }}
                                title="Mark as read"
                            >
                                <FWIcon
                                    name="bi bi-check2-all"
                                    style={{
                                        marginTop: '3px',
                                    }}
                                />
                            </IconButton>
                        )}
                    </div>
                    <Box
                        sx={{
                            '.MuiTypography-root': {
                                fontSize: '15px',
                            },
                        }}
                    >
                        <FWTypography
                            sx={{
                                fontWeight:
                                    notification?.status ===
                                    NotificationStatus.UNREAD
                                        ? 'bold'
                                        : 'normal',
                            }}
                        >
                            {notification?.title}
                        </FWTypography>
                        <Box
                            color="text.secondary"
                            style={{
                                fontSize: '15px',
                            }}
                        >
                            <NotiDescription>
                                {notification?.message}
                            </NotiDescription>
                        </Box>
                    </Box>
                </div>
            </FWMenuItem>
        </div>
    );
};
