import { Box } from '@mui/material';
import React from 'react';
import { FWTypography } from '../../../../Common/FWTypograhy';
import { IVLNNote } from '../../../Interfaces/VendorLNInterfaces';
import { getFWTime } from '../../../../Common/Utils/DateUtils';
import { getTextFromHTMLString } from '../../Helpers/getTextFromHTMLString';

const VLNRelationshipNotesListItem = ({
    onNoteSelect,
    isSelected,
    noteData,
}: {
    onNoteSelect: () => void;
    isSelected: boolean;
    noteData: IVLNNote;
}) => {
    return (
        <Box
            borderRadius={1.5}
            width={'100%'}
            bgcolor={isSelected ? '#e5f1ff' : 'transparent'}
            padding={2}
            marginBottom={2}
            sx={{
                '&:hover': {
                    bgcolor: '#f0f0f2',
                },
                cursor: 'pointer',
                transition: 'all 0.2s ease-in-out',
            }}
            onClick={onNoteSelect}
        >
            <Box
                className={'flex flex--aic flex--jcsb'}
                marginBottom={1}
                gap={2}
            >
                <Box overflow={'hidden'}>
                    <FWTypography fontWeight={500} fontSize={17}>
                        {noteData.title}
                    </FWTypography>
                </Box>
                {/* <Box className={'flex flex--aic'}>
                    <Chip label={'Important'} size={'small'} />
                    &nbsp;
                    <Box color={'secondary.main'}>
                        <i className={'bi bi-pin-angle-fill'} />
                    </Box>
                </Box> */}
            </Box>

            <Box className={'flex flex--aic flex--jcsb'} gap={1}>
                <Box overflow={'hidden'}>
                    <FWTypography
                        fontWeight={500}
                        fontSize={13}
                        color={'text.secondary'}
                    >
                        {/* <Box
                            component="span"
                            dangerouslySetInnerHTML={{
                                __html: noteData.notes,
                            }}
                        ></Box> */}
                        {getTextFromHTMLString(noteData.notes)}
                    </FWTypography>
                </Box>
                <Box>
                    <FWTypography color={'text.secondary'} fontSize={13}>
                        {getFWTime(noteData.modified_datetime)}
                    </FWTypography>
                </Box>
            </Box>
        </Box>
    );
};

export default VLNRelationshipNotesListItem;
