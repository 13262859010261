import { IAttachment } from '../../../Models/RFQ.model';
import { get, post, put } from '../../../Utils/api';
import { identificationVerificationStatusMapService } from '../Constants/IdentificationsConstants';
import {
    IIdentificationDetail,
    IdentificationStatus,
} from '../Interfaces/IdentificationInterface';

export const getIdentificationDetail = async (
    entityId: string,
    identificationId: string
): Promise<IIdentificationDetail> => {
    const response: any = await get<any>(
        `/organization/entity/${entityId}/identifications/${identificationId}`
    );
    const data = response.data;
    if (data) {
        const attachments: IAttachment[] = data.attachments.map(
            (attachment: any) => {
                const na: IAttachment = {
                    attachment_id: attachment.attachment_id,
                    storage_id: attachment.storage_id,
                    file_name: attachment.file_name,
                };
                return na;
            }
        );
        const detail: IIdentificationDetail = {
            id: data.identification_id,
            name: data.identification_name,
            value: data.identification_value,
            country: data?.country,
            linked_addresses: data.linked_addresses,
            isDefault: data.is_default,
            isPublic: data.is_public,
            status: data.status,
            notes: data.notes,
            identificationStatus:
                identificationVerificationStatusMapService[
                    data.verification_status
                ],
            attachments,
        };
        return detail;
    }
    return response;
};

export const getIdentificationList = async (
    entityId: string
): Promise<IIdentificationDetail[]> => {
    const response: any = await get<any>(
        `/organization/entity/${entityId}/identifications/`
    );
    const data: any[] = response.data;
    if (data) {
        return data
            .sort((a, b) => {
                if (
                    a.identification_name.toLowerCase() <
                    b.identification_name.toLowerCase()
                ) {
                    return -1;
                }
                if (
                    a.identification_name.toLowerCase() >
                    b.identification_name.toLowerCase()
                ) {
                    return 1;
                }
                return 0;
            })
            .map((identification, index: number) => {
                const attachments: IAttachment[] =
                    identification.attachments.map((attachment: any) => {
                        const na: IAttachment = {
                            attachment_id: attachment.attachment_id,
                            storage_id: attachment.storage_id,
                            file_name: attachment.file_name,
                        };
                        return na;
                    });
                const detail: IIdentificationDetail = {
                    index,
                    id: identification.identification_id,
                    name: identification.identification_name,
                    value: identification.identification_value,
                    linked_addresses: identification.linked_addresses,
                    isDefault: identification.is_default,
                    isPublic: identification.is_public,
                    status: identification.status,
                    entityId: identification.entity,
                    notes: identification.notes,
                    identificationStatus:
                        identificationVerificationStatusMapService[
                            identification.verification_status
                        ],
                    attachments,
                };
                return detail;
            });
    }
    return response;
};

export const updateIdentificationDetail = async (
    entityId: string,
    identificationId: string,
    identificationDetail: IIdentificationDetail
): Promise<
    | { isDefault: boolean; isPublic: boolean; status: IdentificationStatus }
    | undefined
> => {
    try {
        const resp = await put<any, any>(
            `/organization/entity/${entityId}/identifications/${identificationId}/update/`,
            {
                is_public: identificationDetail.isPublic,
                is_default: identificationDetail.isDefault,
                status: identificationDetail.status,
                notes: identificationDetail.notes,
                attachment_ids: identificationDetail.attachments.map(
                    (a) => a.attachment_id
                ),
            }
        );
        return {
            isDefault: resp.data.is_default,
            isPublic: resp.data.is_public,
            status: resp.data.status,
        };
    } catch (error) {
        return;
    }
};

export const createIdentification = async (
    entityId: string,
    identificationDetail: IIdentificationDetail
): Promise<void> => {
    await post<any, any>(
        `/organization/entity/${entityId}/identifications/create/`,
        {
            name: identificationDetail.name.trim(),
            value: identificationDetail.value.trim(),
            is_public: identificationDetail.isPublic,
            is_default: identificationDetail.isDefault,
            country: identificationDetail.country,
            notes: identificationDetail.notes
                ? identificationDetail.notes.trim()
                : '',
            status: identificationDetail.status,
            attachment_ids: identificationDetail.attachments.map(
                (a) => a.attachment_id
            ),
        }
    );
};
