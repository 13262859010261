import { Box } from '@mui/material';
import { FWTooltip } from '../../Common/FWCustomTooltip';
import { VerificationStatus } from '../../Redux/Slices/VerificationSlice';

type VendorVerificationStatusIconProps = {
    verificationStatus: VerificationStatus;
};

export default function VendorVerificationStatusIcon(
    props: VendorVerificationStatusIconProps
) {
    let tooltip = '';
    let iconName = '';
    let iconColor = '';
    if (props.verificationStatus === VerificationStatus.VERIFIED) {
        tooltip = 'Verified company';
        iconName = 'bi bi-card-checklist';
        iconColor = 'success.main';
    } else {
        tooltip = 'Unverified company';
        iconName = 'bi bi-exclamation-circle';
        iconColor = 'warning.main';
    }
    return (
        <FWTooltip title={tooltip}>
            <Box color={iconColor}>
                <i className={iconName} />
            </Box>
        </FWTooltip>
    );
}
