import { FWTypography } from '../../../Common/FWTypograhy';
import { Box } from '@mui/material';
import { FWTooltip } from '../../../Common/FWCustomTooltip';
import { PurchaseOrderDisplayStatusBuyer } from '../../Interfaces/PO.model';

const BuyerPurchaseOrderStatusChip = ({
    displayStatus,
    hoverText = '',
    isAmended = false,
}: {
    displayStatus: PurchaseOrderDisplayStatusBuyer;
    hoverText?: string;
    isAmended?: boolean;
}) => {
    const StatusBlock = (param_status: string, param_color: string) => {
        return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <FWTypography sx={{ color: param_color }}>
                    {param_status}
                </FWTypography>
                &nbsp;
                {hoverText.length > 0 && (
                    <FWTooltip title={hoverText}>
                        <Box color={param_color}>
                            <i className="bi bi-info-circle" />
                        </Box>
                    </FWTooltip>
                )}
                &nbsp;
                {isAmended && (
                    <FWTooltip title="Amended">
                        <Box color="primary">
                            <i className="bi bi-info-circle" />
                        </Box>
                    </FWTooltip>
                )}
            </div>
        );
    };

    switch (displayStatus) {
        case PurchaseOrderDisplayStatusBuyer.APPROVAL_PENDING:
            return StatusBlock('Approval pending', 'warning.main');
        case PurchaseOrderDisplayStatusBuyer.ISSUED:
            return StatusBlock('Issued', 'success.main');
        case PurchaseOrderDisplayStatusBuyer.ONGOING:
            return StatusBlock('Ongoing', 'success.main');
        case PurchaseOrderDisplayStatusBuyer.COMPLETED:
            return StatusBlock('Completed', 'success.main');
        case PurchaseOrderDisplayStatusBuyer.RESCINDED:
            return StatusBlock('Rescinded', 'error.main');
        case PurchaseOrderDisplayStatusBuyer.REWORK:
            return StatusBlock('Changes Requested', 'warning.main');
        case PurchaseOrderDisplayStatusBuyer.REJECTED:
            return StatusBlock('Leader rejected', 'error.main');
        case PurchaseOrderDisplayStatusBuyer.DECLINED:
            return StatusBlock('Seller rejected', 'error.main');
        case PurchaseOrderDisplayStatusBuyer.TERMINATED:
            return StatusBlock('Terminated', 'error.main');
        case PurchaseOrderDisplayStatusBuyer.ON_HOLD:
            return StatusBlock('On hold', 'warning.main');
        case PurchaseOrderDisplayStatusBuyer.TERMINATION_REQUESTED:
            return StatusBlock('Termination requested', 'error.main');
        case PurchaseOrderDisplayStatusBuyer.REVISING:
            return StatusBlock('Revising', 'warning.main');
        default:
            return StatusBlock(displayStatus, 'primary.main');
    }
};

export default BuyerPurchaseOrderStatusChip;
