import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { HookStateValue, useHookState } from '../../Common/Hooks/StateHook';
import { IUserDetails } from '../../Models/UserDetails.model';
import { IInviteeResponse } from '../Interfaces/NavbarUsersInterfaces';
import {
    getAllEnterpriseUsers,
    getApprovalPendingUsers,
    getEnterpriseEventCount,
} from '../Services/QuickUserActionsService';

export default function useQuickUserActions() {
    const { hookState, updateHookState } = useHookState(HookStateValue.LOADING);

    const [popupState, setPopupState] = useState<'invite' | 'approve' | 'none'>(
        'none'
    );
    const [allEnterpriseUsers, setAllEnterpriseUsers] = useState<
        IUserDetails[]
    >([]);

    const [currentPortal, setCurrentPortal] = useState<
        'ADMIN' | 'CUSTOM' | 'BILLING' | 'BUYER' | 'SELLER' | null
    >(null);

    const loc = useLocation();

    useEffect(() => {
        let portal = loc.pathname.split('/').filter(Boolean)[0];

        setCurrentPortal(() => {
            switch (portal) {
                case 'seller':
                    return 'SELLER';
                case 'buyer':
                    return 'BUYER';
                case 'admin':
                    return 'ADMIN';
                case 'custom':
                    return 'CUSTOM';
                case 'billing':
                    return 'BILLING';
                default:
                    return null;
            }
        });
    }, [loc.pathname]);

    const [approvalPendingUsers, setApprovalPendingUsers] = useState<
        IInviteeResponse[]
    >([]);
    const [enterpriseEventCount, setEnterpriseEventCount] = useState<number>(0);

    const fetchData = useCallback(async () => {
        updateHookState(HookStateValue.LOADING);
        try {
            const [
                allEnterpriseUsersResp,
                approvalPendingUsersResp,
                enterpriseEventCountResp,
            ] = await Promise.all([
                getAllEnterpriseUsers(),
                getApprovalPendingUsers(),
                getEnterpriseEventCount(),
            ]);
            setAllEnterpriseUsers(allEnterpriseUsersResp);
            setApprovalPendingUsers(approvalPendingUsersResp);
            setEnterpriseEventCount(enterpriseEventCountResp.event_count);
            updateHookState(HookStateValue.READY);
        } catch (err) {
            updateHookState(HookStateValue.ERROR);
        }
    }, [updateHookState]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const refreshData = () => {
        fetchData();
        setPopupState('none');
    };

    return {
        hookState,
        popupState,
        setPopupState,
        allEnterpriseUsers,
        approvalPendingUsers,
        enterpriseEventCount,
        refreshData,
        currentPortal,
    };
}
