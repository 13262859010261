// store/slices/specificationDashboardSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface SpecificationStore {
    dashboard_view: string;
    tab: string;
    search_text: string;
    sort_fields: string[];
    items_per_page: number;
    page_number: number;
    query_data: object;
}

const initialState: SpecificationStore = {
    dashboard_view: 'attribute',
    tab: 'active',
    search_text: '',
    sort_fields: [],
    items_per_page: 10,
    page_number: 1,
    query_data: {},
};

const SpecificationSlice = createSlice({
    name: 'specificationDashboard',
    initialState,
    reducers: {
        setDashboardView: (state, action: PayloadAction<string>) => {
            state.dashboard_view = action.payload;
        },
        setTab: (state, action: PayloadAction<string>) => {
            state.tab = action.payload;
        },
        setSearchText: (state, action: PayloadAction<string>) => {
            state.search_text = action.payload;
        },
        setSortFields: (state, action: PayloadAction<string[]>) => {
            state.sort_fields = action.payload;
        },
        setItemsPerPage: (state, action: PayloadAction<number>) => {
            state.items_per_page = action.payload;
        },
        setPageNumber: (state, action: PayloadAction<number>) => {
            state.page_number = action.payload;
            console.log('page_number', state.page_number);
        },
        setQueryData: (state, action: PayloadAction<object>) => {
            state.query_data = action.payload;
        },
    },
});

export const {
    setDashboardView,
    setTab,
    setSearchText,
    setSortFields,
    setItemsPerPage,
    setPageNumber,
    setQueryData,
} = SpecificationSlice.actions;

export default SpecificationSlice.reducer;
