import { DateRangeType } from '../Interfaces/IHistoricalAnalytics.model';

export const dateFilterOptions: { id: DateRangeType; label: string }[] = [
    {
        id: 'last_30_days',
        label: 'Last 30 days',
    },
    {
        id: 'last_90_days',
        label: 'Last 90 days',
    },
    {
        id: 'last_180_days',
        label: 'Last 180 days',
    },
    {
        id: 'last_365_days',
        label: 'Last 365 days',
    },
    {
        id: 'custom',
        label: 'Custom',
    },
];
