import { Box, Grid } from '@mui/material';
import React from 'react';
import VLNProfileItemComponent from './VLNProfileItemComponent';
import { IVLNItemCatalogueNew } from '../../../Interfaces/VendorLNInterfaces';
import ItemPagination from '../../../../Requisitions/Components/EditRequisition/ItemPagination';

const VLNProfileItemCatalogItems = ({
    itemsCatalogueList,
    currentCataloguePage,
    setCurrentCataloguePage,
    totalPages,
    canEdit,
}: {
    itemsCatalogueList: IVLNItemCatalogueNew[];
    currentCataloguePage: number;
    setCurrentCataloguePage: React.Dispatch<React.SetStateAction<number>>;
    totalPages: number;
    canEdit?: boolean;
}) => {
    return (
        <>
            <Grid container spacing={4}>
                {itemsCatalogueList.map((itemsCatalogueData, i) => (
                    <Grid item xs={12} sm={6} md={4} lg={4} xl={3} key={i}>
                        <VLNProfileItemComponent
                            itemsCatalogueData={itemsCatalogueData}
                            canEdit={canEdit}
                        />
                    </Grid>
                ))}
            </Grid>
            <Box display={'flex'} justifyContent={'end'} marginTop={2}>
                {itemsCatalogueList.length > 0 && (
                    <ItemPagination
                        totalPages={totalPages}
                        page={currentCataloguePage}
                        setPage={setCurrentCataloguePage}
                    />
                )}
            </Box>
        </>
    );
};

export default VLNProfileItemCatalogItems;
