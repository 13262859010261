import { FWTypography } from '../../Common/FWTypograhy';
import { Box } from '@mui/material';
import { FWTooltip } from '../../Common/FWCustomTooltip';
const InvoiceStatusChip = ({
    status,
    hoverText = '',
}: {
    status: string;
    hoverText?: string;
}) => {
    const StatusBlock = (param_status: string, param_color: string) => {
        return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <FWTypography sx={{ color: param_color }}>
                    {param_status}
                </FWTypography>
                &nbsp;
                {hoverText.length > 0 && (
                    <FWTooltip title={hoverText}>
                        <Box color={param_color}>
                            <i className="bi bi-info-circle" />
                        </Box>
                    </FWTooltip>
                )}
            </div>
        );
    };

    switch (status) {
        case 'DRAFT':
            return StatusBlock('Draft', '');
        case 'ISSUED':
            return StatusBlock('Issued', 'success.main');
        case 'ONGOING':
        case 'GOODS_RECEIVED':
            return StatusBlock('Ongoing', 'success.main');
        case 'TERMINATED':
            return StatusBlock('Terminated', 'error.main');
        case 'TERMINATION_REQUESTED':
            return StatusBlock('Termination Requested', 'error.main');
        case 'ON_HOLD':
            return StatusBlock('On hold', 'warning.main');
        case 'RESCINDED':
            return StatusBlock('Rescinded', 'error.main');
        case 'DELETED':
            return StatusBlock('Deleted', 'error.main');
        case 'COMPLETED':
            return StatusBlock('Completed', 'success.main');
        case 'INVOICE_REQUIRED':
            return StatusBlock('Invoice required', 'warning.main');
        case 'CONFIRMATION_REQUIRED':
            return StatusBlock('Confirmation required', 'warning.main');
        case 'PREPAYMENT':
            return StatusBlock('Prepayment', 'success.main');

        default:
            return StatusBlock(status, '');
    }
};

export default InvoiceStatusChip;
