import {
    IGRInvoiceDetails,
    IGRInvoiceListSummary,
    IGRPOListSummary,
    IGroupedInvoiceItemsSummary,
    IInvoiceItemsSummary,
} from '../../GoodsReceipt/Interfaces/GRInterface';
import {
    IDefaultViewPaymentItem,
    // IPrepaymentSummary,
    IPaymentListItemSummary,
    IPaymentsPendingListSummary,
} from '../../Payments/Interfaces/PaymentsInterface';
import {
    IQCCompletedListSummary,
    IQCDetails,
    IQCGRNListSummary,
} from '../../QualityCheck/Interfaces/QCInterface';

type WarningsColors = 'warning' | 'error' | 'primary' | 'success';

export interface IItemAndPOWarningResponse {
    warnings: string[];
    color: WarningsColors;
}

type POHoldTerminationInfoUnion =
    | IGRInvoiceDetails
    // | IPrepaymentSummary
    | IGRPOListSummary;

type ItemHoldTerminationInfoUnion =
    | IInvoiceItemsSummary
    | IGroupedInvoiceItemsSummary
    | IDefaultViewPaymentItem;

type HoldTerminationInfoUnion =
    | IQCGRNListSummary
    | IQCDetails
    | IQCCompletedListSummary
    | IPaymentListItemSummary
    | IPaymentsPendingListSummary
    | IGRInvoiceListSummary
    | IDefaultViewPaymentItem;

export const generatePOWarnings = (
    item: HoldTerminationInfoUnion | POHoldTerminationInfoUnion | any
): IItemAndPOWarningResponse => {
    let color: WarningsColors = 'warning';
    let warnings: string[] = [];
    if (item.poOnHold) {
        warnings.push('PO is on hold');
    }
    if (item.poTermination) {
        color = 'error';
        warnings.push('PO termination requested');
    }
    if (item.poTerminationAccepted) {
        color = 'error';
        warnings = ['PO terminated'];
    }
    return { warnings, color };
};

export const generateItemWarnings = (
    item: HoldTerminationInfoUnion | ItemHoldTerminationInfoUnion | any
): IItemAndPOWarningResponse => {
    let color: WarningsColors = 'warning';
    const warnings: string[] = [];

    if (item.itemTerminated) {
        color = 'error';
        warnings.push('Invoice item terminated ');
    } else if (item.itemTermination) {
        color = 'error';
        warnings.push('Invoice item termination requested');
    } else if (item.itemOnHold) {
        color = 'warning';
        warnings.push('Invoice item is on hold');
    }
    return { warnings, color };
};

export const generatePOandItemWarnings = (
    item: HoldTerminationInfoUnion
): IItemAndPOWarningResponse => {
    let color: WarningsColors = 'warning';
    const itemWarnings = generateItemWarnings(item);
    const poWarnings = generatePOWarnings(item);
    const warnings: string[] = [
        ...itemWarnings.warnings,
        ...poWarnings.warnings,
    ];
    if (itemWarnings.color === 'error' || poWarnings.color === 'error')
        color = 'error';
    return { warnings, color };
};

const units = [
    '',
    'One',
    'Two',
    'Three',
    'Four',
    'Five',
    'Six',
    'Seven',
    'Eight',
    'Nine',
];
const teens = [
    'Eleven',
    'Twelve',
    'Thirteen',
    'Fourteen',
    'Fifteen',
    'Sixteen',
    'Seventeen',
    'Eighteen',
    'Nineteen',
];
const tens = [
    '',
    'Ten',
    'Twenty',
    'Thirty',
    'Forty',
    'Fifty',
    'Sixty',
    'Seventy',
    'Eighty',
    'Ninety',
];
const thousands = ['', 'Thousand', 'Million', 'Billion'];
export function numberToWords(number: number, currency: string): string {
    const integerPart = Math.floor(number);
    const decimalPart = Math.round((number - integerPart) * 100); // Round to two decimal places

    const integerPartWords = numberToWordsLessThanBillion(integerPart);

    const decimalPartWords =
        currency !== 'USD'
            ? decimalPart
                  .toString()
                  .split('')
                  .map((unitPlace) => units[parseInt(unitPlace)])
                  .join(' ')
            : numberToWordsLessThanHundred(decimalPart);

    let result = integerPartWords;

    if (decimalPart > 0) {
        result +=
            currency !== 'USD'
                ? ' point ' + decimalPartWords
                : ' and ' + decimalPartWords + ' Cents';
    }
    return result;
}

function numberToWordsLessThanBillion(number: number): string {
    if (number === 0) {
        return 'Zero';
    } else if (number < 0) {
        return 'Negative ' + numberToWordsLessThanBillion(-number);
    }

    let result = '';
    let i = 0;

    while (number > 0) {
        if (number % 1000 !== 0) {
            const part = numberToWordsLessThanThousand(number % 1000);
            result = part + ' ' + thousands[i] + ' ' + result;
        }

        number = Math.floor(number / 1000);
        i++;
    }

    return result.trim();
}

function numberToWordsLessThanThousand(number: number): string {
    if (number === 0) {
        return '';
    } else if (number < 10) {
        return units[number];
    } else if (number < 20) {
        return teens[number - 11];
    } else if (number < 100) {
        const ten = Math.floor(number / 10);
        const remainder = number % 10;
        return tens[ten] + (remainder > 0 ? ' ' + units[remainder] : '');
    } else {
        const hundred = Math.floor(number / 100);
        const remainder = number % 100;
        return (
            units[hundred] +
            ' Hundred' +
            (remainder > 0
                ? ' ' + numberToWordsLessThanThousand(remainder)
                : '')
        );
    }
}

function numberToWordsLessThanHundred(number: number): string {
    if (number < 10) {
        return units[number];
    } else if (number < 20) {
        return teens[number - 11];
    } else {
        const ten = Math.floor(number / 10);
        const remainder = number % 10;
        return tens[ten] + (remainder > 0 ? ' ' + units[remainder] : '');
    }
}

// export function numberToWordsINR(num: number): string {
//     const units: string[] = ['', 'Thousand', 'Million', 'Billion', 'Trillion'];
//     const digits: string[] = [
//         '',
//         'One',
//         'Two',
//         'Three',
//         'Four',
//         'Five',
//         'Six',
//         'Seven',
//         'Eight',
//         'Nine',
//     ];
//     const teens: string[] = [
//         '',
//         'Eleven',
//         'Twelve',
//         'Thirteen',
//         'Fourteen',
//         'Fifteen',
//         'Sixteen',
//         'Seventeen',
//         'Eighteen',
//         'Nineteen',
//     ];
//     const tens: string[] = [
//         '',
//         'Ten',
//         'Twenty',
//         'Thirty',
//         'Forty',
//         'Fifty',
//         'Sixty',
//         'Seventy',
//         'Eighty',
//         'Ninety',
//     ];

//     let [rupees, paisa] = num.toString().split('.').map(Number);

//     function convertThreeDigits(num: number): string {
//         if (num === 0) {
//             return '';
//         } else if (num < 10) {
//             return digits[num];
//         } else if (num < 20) {
//             return teens[num - 10];
//         } else {
//             return tens[Math.floor(num / 10)] + ' ' + digits[num % 10];
//         }
//     }

//     function convertChunk(num: number): string {
//         let chunk = '';
//         if (Math.floor(num / 100) > 0) {
//             chunk += digits[Math.floor(num / 100)] + ' Hundred';
//             if (num % 100 > 0) {
//                 chunk += ' and ';
//             }
//         }
//         chunk += convertThreeDigits(num % 100);
//         return chunk;
//     }

//     let rupeesInWords = '';
//     let chunkCount = 0;

//     while (rupees > 0) {
//         const chunk = rupees % 1000;
//         if (chunk > 0) {
//             rupeesInWords =
//                 convertChunk(chunk) +
//                 ' ' +
//                 units[chunkCount] +
//                 ' ' +
//                 rupeesInWords;
//         }
//         rupees = Math.floor(rupees / 1000);
//         chunkCount++;
//     }

//     if (rupeesInWords === '') {
//         rupeesInWords = 'Zero';
//     }

//     let paisaInWords = '';
//     if (paisa > 0) {
//         paisaInWords = 'and ' + paisa + '/100';
//     }

//     const result = rupeesInWords + ' Rupees ' + paisaInWords + ' Paisa';
//     return result;
// }

export function numberToWordsINR(amount: number): string {
    function convertToWords(number: number): string {
        const words: string[] = [
            '',
            'One',
            'Two',
            'Three',
            'Four',
            'Five',
            'Six',
            'Seven',
            'Eight',
            'Nine',
            'Ten',
            'Eleven',
            'Twelve',
            'Thirteen',
            'Fourteen',
            'Fifteen',
            'Sixteen',
            'Seventeen',
            'Eighteen',
            'Nineteen',
        ];
        const tensWords: string[] = [
            '',
            '',
            'Twenty',
            'Thirty',
            'Forty',
            'Fifty',
            'Sixty',
            'Seventy',
            'Eighty',
            'Ninety',
        ];

        if (number < 20) {
            return words[number];
        } else if (number < 100) {
            return (
                tensWords[Math.floor(number / 10)] +
                (number % 10 !== 0 ? ' ' + words[number % 10] : '')
            );
        } else {
            return (
                words[Math.floor(number / 100)] +
                ' Hundred' +
                (number % 100 !== 0 ? ' ' + convertToWords(number % 100) : '')
            );
        }
    }

    if (amount < 1 || amount > 100000000000) {
        return '';
    }

    const crore = Math.floor(amount / 10000000);
    const lakh = Math.floor((amount % 10000000) / 100000);
    const thousands = Math.floor((amount % 100000) / 1000);
    const rupees = Math.floor(amount % 1000);
    const paisa = Math.round((amount - parseInt(amount.toString())) * 100);

    const croreInWords: string =
        crore > 0 ? convertToWords(crore) + ' Crore' : '';
    const lakhInWords: string = lakh > 0 ? convertToWords(lakh) + ' Lakh' : '';
    const rupeesInWords: string =
        rupees > 0 ? convertToWords(rupees) + ' Rupees' : '';
    const paisaInWords: string =
        paisa > 0 ? convertToWords(paisa) + ' Paisa' : '';

    const thousandInWords: string =
        thousands > 0 ? convertToWords(thousands) + ' thousand' : '';

    let result: string = '';
    if (croreInWords) {
        result += croreInWords + ' ';
    }
    if (lakhInWords) {
        result += lakhInWords + ' ';
    }
    if (thousandInWords) {
        result += thousandInWords + ' ';
    }

    if (rupeesInWords) {
        result += rupeesInWords + ' ';
    }
    if (paisaInWords) {
        result += paisaInWords + ' ';
    }

    return result.trim();
}
