import { isEmpty } from 'lodash';
import { toast } from 'react-toastify';
import {
    convertAdditionalCostBackendToFrontend,
    convertAdditionalCostFrontendToBackend,
} from '../../AdditionalCost/helpers';
import { IAdditionalCostsWithValue } from '../../AdditionalCost/models/AdditionalCost.model';
import { transformPaymentTermsFromBackendToFrontend } from '../../Global/Helpers/paymentTermsHelpers';
import { JsonNumsToStrings } from '../../Global/Services/JsonProcessingService';
import {
    IPOAllocatedDetail,
    IPOIssuedDetail,
    IPOItemDetail,
    IPOPendingDetail,
    IPurchaseOrderItem,
    IPurchaseOrderWithItems,
} from '../../Models/AwardsAndPO.model';
import {
    IAvailableApprover,
    IEventApproval,
} from '../../Models/RFQSummary.model';
import { del, get, post, put } from '../../Utils/api';
// import { additionalCostApiSlice } from '../../AdditionalCost/Services/additionalCosts.services';

export const fetchPOList = (
    enterprise_type: string,
    purchase_order_type?: string | null,
    po_statuses?: string[] | null,
    seller_entity_id?: string | null,
    event_id?: string | null
): Promise<IPurchaseOrderWithItems[]> => {
    return new Promise(async (resolve, reject) => {
        try {
            let queryStr = `enterprise_type=${enterprise_type}`;
            if (purchase_order_type !== null) {
                queryStr += `&purchase_order_type=${purchase_order_type}`;
            }
            if (po_statuses !== null && po_statuses!.length > 0) {
                queryStr += `&purchase_order_status=[${po_statuses!.join(
                    ', '
                )}]`;
            }
            if (seller_entity_id !== null) {
                queryStr += `&seller_entity_id=${seller_entity_id}`;
            }
            if (event_id !== null) {
                queryStr += `&event_id=${event_id}`;
            }
            let resp = await get<any[]>(`/purchase_orders/?${queryStr}`);
            let processedPoList = resp.data;
            //TODO: Temporary fix due to backend change
            for (let po of processedPoList) {
                if (
                    po.seller_information.seller_identification_information &&
                    !po.seller_information.seller_identification_info
                ) {
                    po.seller_information.seller_identification_info =
                        po.seller_information.seller_identification_information;
                }
                po.additional_costs = convertAdditionalCostBackendToFrontend(
                    po.additional_costs
                );
                po.taxes = convertAdditionalCostBackendToFrontend(po.taxes);
                po.discounts = convertAdditionalCostBackendToFrontend(
                    po.discounts
                );
            }
            resolve(processedPoList);
        } catch (err) {
            reject();
        }
    });
};

export const fetchPODetails = (
    po_id: string,
    enterprise_type: string
): Promise<IPurchaseOrderWithItems> => {
    return new Promise(async (resolve, reject) => {
        try {
            let resp = await get<any>(
                `/purchase_orders/${po_id}?enterprise_type=${enterprise_type}`
            );
            //TODO: Temporary fix due to backend change
            let processedData = resp.data;
            if (
                processedData.seller_information
                    .seller_identification_information &&
                !processedData.seller_information.seller_identification_info
            ) {
                processedData.seller_information.seller_identification_info =
                    processedData.seller_information.seller_identification_information;
            }
            processedData.purchase_order_items.forEach((item: any) => {
                item.paymentTerms = transformPaymentTermsFromBackendToFrontend({
                    prepayment_percentage: item.prepayment_percentage,
                    payment_terms: item.payment_terms,
                    deliverables_payment_terms: item.deliverables_payment_terms,
                });
            });
            resolve(processedData);
        } catch (err) {
            reject();
        }
    });
};

export const postBuyerStagingPO = (obj: any): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            let resp: any = await post<any, any>(
                `/purchase_orders/create/`,
                obj
            );
            resolve(resp.data);
        } catch (err: any) {
            if (err.response.data.ErrorCode === 'Exceeded Quota Limit') {
                toast.error(err.response.data.ErrorCode);
                reject();
            }
            reject();
        }
    });
};

export const putBuyerStagingPO = async (
    purchase_order_id: string,
    additional_costs: IAdditionalCostsWithValue[],
    taxes: IAdditionalCostsWithValue[],
    discounts: IAdditionalCostsWithValue[]
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            let data = {
                discount_percentage: '0',
                additional_costs:
                    convertAdditionalCostFrontendToBackend(additional_costs),
                taxes: convertAdditionalCostFrontendToBackend(taxes),
                discounts: convertAdditionalCostFrontendToBackend(discounts),
            };
            let resp = await put<any, any>(
                `/purchase_orders/${purchase_order_id}/update/`,
                data
            );
            resolve(resp.data);
        } catch (err) {
            reject();
        }
    });
};

export const fetchNextApproverListForExistingPurchaseOrder = ({
    poId,
}: {
    poId: string;
}): Promise<IAvailableApprover[]> => {
    return new Promise<IAvailableApprover[]>(async (resolve, reject) => {
        try {
            const payload = {
                purchase_order_id: poId,
            };
            let resp = await post<{ purchase_order_id: string }, any[]>(
                `/purchase_orders/next_approvers/`,
                payload
            );
            const availableApprovers: IAvailableApprover[] = [];

            resp.data.forEach((availableApprover) => {
                availableApprovers.push({
                    userId: availableApprover.user_id,
                    userName: availableApprover.username,
                    name: availableApprover.name,
                    userRole: availableApprover.user_role,
                });
            });
            resolve(availableApprovers);
        } catch (e) {
            reject(e);
        }
    });
};

type NewPoNextApproverPayload = {
    purchase_order_items: {
        purchase_order_item_id: string;
        delivery_schedule_items: string[];
    }[];
    discount_percentage: number;
    buyer_entity_id: string;
    event_id: string;
};

export const fetchNextApproverListForNewPurchaseOrder = ({
    poItems,
    buyerEntityId,
    discountPercentage,
    eventId,
}: {
    poItems: {
        purchase_order_item_id: string;
        delivery_schedule_items: string[];
    }[];
    buyerEntityId: string;
    discountPercentage: string;
    eventId: string;
}): Promise<IAvailableApprover[]> => {
    return new Promise<IAvailableApprover[]>(async (resolve, reject) => {
        try {
            const payload: NewPoNextApproverPayload = {
                buyer_entity_id: buyerEntityId,
                discount_percentage: +discountPercentage,
                purchase_order_items: poItems,
                event_id: eventId,
            };
            let resp = await post<NewPoNextApproverPayload, any[]>(
                `/purchase_orders/next_approvers/`,
                payload
            );

            const availableApprovers: IAvailableApprover[] = [];
            resp.data.forEach((availableApprover) => {
                availableApprovers.push({
                    userId: availableApprover.user_id,
                    userName: availableApprover.username,
                    name: availableApprover.name,
                    userRole: availableApprover.user_role,
                });
            });
            resolve(availableApprovers);
        } catch (e) {
            reject(e);
        }
    });
};

export const fetchNextApproverListForPurchaseOrderBulk = ({
    toPost,
}: {
    toPost: any[];
}): Promise<IAvailableApprover[]> => {
    return new Promise<IAvailableApprover[]>(async (resolve, reject) => {
        try {
            let resp = await post<NewPoNextApproverPayload, any[]>(
                `/purchase_orders/bulk_next_approvers/`,
                { purchase_orders: toPost }
            );

            const availableApprovers: IAvailableApprover[] = [];
            resp.data.forEach((availableApprover) => {
                if (availableApprover.approvers.length > 0) {
                    availableApprover.approvers.forEach((approver: any) => {
                        availableApprovers.push({
                            userId: approver.user_id,
                            userName: approver.username,
                            name: approver.name,
                            userRole: approver.user_role,
                            resource_id: availableApprover.resource_id,
                        });
                    });
                }
            });

            resolve(availableApprovers);
        } catch (e) {
            reject(e);
        }
    });
};

// export const fetchNextApproverListForPurchaseOrderBulk = ({
//     toPost,
// }: {
//     toPost: any[];
// }): Promise<IAvailableApprover[]> => {
//     return new Promise<IAvailableApprover[]>(async (resolve, reject) => {
//         try {
//             let resp = await post<NewPoNextApproverPayload, any[]>(
//                 `/purchase_orders/bulk_next_approvers/`,
//                 { purchase_orders: toPost }
//             );
//
//             const availableApprovers: IAvailableApprover[] = [];
//             resp.data.forEach((availableApprover) => {
//

//                 if (availableApprover.approvers.length > 0) {
//                     availableApprover.approvers.forEach((approver: any) => {
//                         availableApprovers.push({
//                             userId: approver.user_id,
//                             userName: approver.username,
//                             name: approver.name,
//                             userRole: approver.user_role,
//                             resource_id: availableApprover.resource_id,
//                         });
//                     });
//                 }
//             });

//             resolve(availableApprovers);
//         } catch (e) {
//             reject(e);
//         }
//     });
// };

export const createNewBuyerPO = async (
    PurchaseOrderData: IPurchaseOrderWithItems,
    status: 'SEND_FOR_APPROVAL' | 'APPROVE_AND_SUBMIT',
    event_type: 'FROM_EVENT' | 'DIRECT'
): Promise<any> => {
    //For virtual vendors, seller address will be null
    let vendor_is_virtual = PurchaseOrderData.seller_information.is_virtual
        ? true
        : false;

    return new Promise(async (resolve, reject) => {
        try {
            let items_to_post = [];
            for (let cur_item of PurchaseOrderData.purchase_order_items) {
                let cur_item_to_post = {
                    purchase_order_item_id:
                        cur_item.delivery_schedule_items[0].purchase_order_item,
                    internal_notes: cur_item.internal_notes.notes,
                    external_notes: cur_item.external_notes.notes,
                    quantity_tolerance_percentage:
                        cur_item.quantity_information
                            .quantity_tolerance_percentage,
                    delivery_schedule_items:
                        cur_item.delivery_schedule_items.map(
                            (delivery_item) =>
                                delivery_item.delivery_schedule_item_id
                        ),
                };
                items_to_post.push(cur_item_to_post);
            }

            let po_to_post = {
                event_id: PurchaseOrderData.event,
                buyer_entity_id: PurchaseOrderData.buyer_entity,
                shipping_address_id:
                    PurchaseOrderData.buyer_information.shipping_address_id,
                billing_address_id:
                    PurchaseOrderData.buyer_information.billing_address_id,
                seller_entity_id: PurchaseOrderData.seller_entity,
                seller_address_id:
                    PurchaseOrderData.seller_information.seller_address_id,
                seller_full_address:
                    PurchaseOrderData.seller_information.seller_entity_address,
                buyer_identifications:
                    PurchaseOrderData.buyer_information.buyer_identification_info
                        .filter(
                            (identification_row) =>
                                identification_row.identification_id.length > 0
                        )
                        .map(
                            (identification_row) =>
                                identification_row.identification_id
                        ),
                seller_identifications:
                    PurchaseOrderData.seller_information.seller_identification_info
                        .filter(
                            (identification_row) =>
                                identification_row.identification_id.length > 0
                        )
                        .map(
                            (identification_row) =>
                                identification_row.identification_id
                        ),
                seller_custom_identifications:
                    PurchaseOrderData.seller_information.seller_identification_info
                        .filter(
                            (identification_row) =>
                                identification_row.identification_id.length ===
                                0
                        )
                        .map((identification_row) => {
                            return {
                                identification_name:
                                    identification_row.identification_name,
                                identification_value:
                                    identification_row.identification_value,
                            };
                        }),
                discount_percentage:
                    PurchaseOrderData.pricing_information?.discount_percentage,
                buyer_contact_list: PurchaseOrderData.buyer_contact_list.map(
                    (contactInfo) => contactInfo.buyer_contact_id
                ),
                seller_contact_list: Array.from(
                    new Set(
                        PurchaseOrderData.seller_contact_list
                            .filter(
                                (contactInfo) =>
                                    contactInfo.user !== null &&
                                    contactInfo.user.trim() !== ''
                            )
                            .map((contactInfo) => contactInfo.user)
                    )
                ),
                terms_and_conditions: {
                    terms_and_conditions_id: isEmpty(
                        PurchaseOrderData.terms_and_conditions
                            .terms_and_conditions_id
                    )
                        ? null
                        : PurchaseOrderData.terms_and_conditions
                              .terms_and_conditions_id,
                    name: PurchaseOrderData.terms_and_conditions.name,
                    data: PurchaseOrderData.terms_and_conditions.data,
                },
                notes: PurchaseOrderData.notes,
                purchase_order_items: items_to_post,
                action: status,
                cost_centre_id: PurchaseOrderData.cost_centre_id,
                gl_id: PurchaseOrderData.gl_id,
                project_id: PurchaseOrderData.project_id,
                lead_time: PurchaseOrderData.lead_time,
                lead_time_period: PurchaseOrderData.lead_time_period,
                incoterm_id: PurchaseOrderData.incoterm_id,
                custom_fields: PurchaseOrderData.custom_fields,
                custom_additional_information:
                    PurchaseOrderData.custom_additional_information,
                gr_tolerance: PurchaseOrderData.gr_tolerance,
                requisition_information:
                    PurchaseOrderData.requisition_information,
                prepayment_percentage: PurchaseOrderData.prepayment_percentage,
                payment_type: PurchaseOrderData.payment_type,
                payment_terms: PurchaseOrderData.payment_terms,
                deliverables_payment_terms:
                    PurchaseOrderData.deliverables_payment_terms,
                additional_costs: convertAdditionalCostFrontendToBackend(
                    PurchaseOrderData.additional_costs
                ),
                discounts: convertAdditionalCostFrontendToBackend(
                    PurchaseOrderData.discounts
                ),
                taxes: convertAdditionalCostFrontendToBackend(
                    PurchaseOrderData.taxes
                ),
            };
            if (event_type === 'FROM_EVENT') {
                if (vendor_is_virtual) {
                    let resp = await post<any, any>(
                        `/purchase_orders/virtual_vendor/create/`,
                        po_to_post
                    );

                    resolve(resp.data);
                } else {
                    let resp: any = await post<any, any>(
                        `/purchase_orders/create/`,
                        po_to_post
                    );

                    resolve(resp.data);
                }
            } else {
                if (vendor_is_virtual) {
                    let resp = await post<any, any>(
                        `/purchase_orders/po_group/virtual_vendor/create/`,
                        po_to_post
                    );

                    resolve(resp.data);
                } else {
                    let resp: any = await post<any, any>(
                        `/purchase_orders/po_group/create/`,
                        po_to_post
                    );

                    resolve(resp.data);
                }
            }
        } catch (err: any) {
            if (err.response.data.ErrorCode === 'Exceeded Quota Limit') {
                toast.error(err.response.data.ErrorCode);
                reject();
            }
            reject();
        }
    });
};

export const reviseBuyerPO = async (
    purchase_order_id: string,
    obj: any,
    type: 'FROM_EVENT' | 'DIRECT'
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            if (type === 'FROM_EVENT') {
                let resp: any = await post<any, any>(
                    `/purchase_orders/${purchase_order_id}/revise/`,
                    obj
                );
                resolve(resp.data);
            } else {
                let resp: any = await post<any, any>(
                    `/purchase_orders/po_group/${purchase_order_id}/revise/`,
                    obj
                );
                resolve(resp.data);
            }
        } catch (err: any) {
            if (err.response.data.ErrorCode === 'Exceeded Quota Limit') {
                toast.error(err.response.data.ErrorCode);
                reject();
            }
            reject();
        }
    });
};

export const getPoApprovals = async (
    po_id: string
): Promise<IEventApproval[]> => {
    const poApprovalsResponse = await get<any>(
        `/purchase_orders/${po_id}/approvers/`
    );
    const poApprovalsList: any[] = poApprovalsResponse.data;
    const poApprovals: IEventApproval[] = [];

    poApprovalsList.forEach((poApproval) => {
        poApprovals.push({
            requestorId: poApproval.requestor,
            requestorUserFullName: poApproval.requestor_name,
            requestorNotes: poApproval.requestor_notes,
            approverId: poApproval.approver,
            approverUserFullName: poApproval.approver_name,
            approverNotes: poApproval.approver_notes,
            status: poApproval.status,
        });
    });
    return poApprovals;
};

export const getPoApprovalsBulk = async (
    poIdList: string[]
): Promise<IEventApproval[]> => {
    const queryStr = poIdList
        .map((poId) => `purchase_order_id_list=${poId}`)
        .join('&');
    // const poApprovalsResponse = await get<any>(`/purchase_orders/bulk/approvers/`);
    const poApprovalsResponse = await get<any>(
        `/purchase_orders/bulk/approvers/?${queryStr}`
    );

    const poApprovalsList: any[] = poApprovalsResponse.data;
    const poApprovals: IEventApproval[] = [];

    poApprovalsList.forEach((poApproval) => {
        poApproval.approvers_list.forEach((approval: any) => {
            poApprovals.push({
                requestorId: approval.requestor,
                requestorUserFullName: approval.requestor_name,
                requestorNotes: approval.requestor_notes,
                approverId: approval.approver,
                approverUserFullName: approval.approver_name,
                approverNotes: approval.approver_notes,
                status: approval.status,
                poId: poApproval.purchase_order_id,
            });
        });
    });

    return poApprovals;
};

export const deletePo = async (
    po_id: string,
    event_type: 'DIRECT' | 'FROM_EVENT'
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            let url =
                event_type === 'FROM_EVENT'
                    ? `/purchase_orders/${po_id}/delete/`
                    : `/purchase_orders/po_group/${po_id}/delete/`;
            let resp = await del<any>(url);
            resolve(resp);
        } catch (err) {
            reject(err);
        }
    });
};

export const addApprovalToPo = async (
    po_id: string,
    event_type: 'DIRECT' | 'FROM_EVENT'
): Promise<void> => {
    return new Promise(async (resolve, reject) => {
        let data = {
            purchase_order_items: [],
            status: 'APPROVAL_PENDING',
        };
        try {
            let url =
                event_type === 'FROM_EVENT'
                    ? `/purchase_orders/${po_id}/state/`
                    : `/purchase_orders/po_group/${po_id}/state/`;
            await put<any, any>(url, data);
            resolve();
        } catch (err) {
            reject(err);
        }
    });
};

export const reworkPo = async (
    po_id: string,
    event_type: 'DIRECT' | 'FROM_EVENT',
    msg: string
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        let data = {
            purchase_order_items: [],
            status: 'REWORK',
            notes: msg,
        };
        try {
            let url =
                event_type === 'FROM_EVENT'
                    ? `/purchase_orders/${po_id}/state/`
                    : `/purchase_orders/po_group/${po_id}/state/`;
            let resp = await put<any, any>(url, data);
            resolve(resp);
        } catch (err) {
            reject(err);
        }
    });
};

//Leader rejection
export const rejectPo = async (
    po_id: string,
    event_type: 'DIRECT' | 'FROM_EVENT',
    msg: string
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        let data = {
            purchase_order_items: [],
            status: 'REJECTED',
            notes: msg,
        };
        try {
            let url =
                event_type === 'FROM_EVENT'
                    ? `/purchase_orders/${po_id}/state/`
                    : `/purchase_orders/po_group/${po_id}/state/`;
            let resp = await put<any, any>(url, data);
            resolve(resp);
        } catch (err) {
            reject(err);
        }
    });
};

export const submitPo = async (
    po_id: string,
    event_type: 'FROM_EVENT' | 'DIRECT'
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        let data = {
            purchase_order_items: [],
            status: 'ISSUED',
            notes: '',
        };
        try {
            let url =
                event_type === 'FROM_EVENT'
                    ? `/purchase_orders/${po_id}/state/`
                    : `/purchase_orders/po_group/${po_id}/state/`;
            let resp = await put<any, any>(url, data);
            resolve(resp);
        } catch (err) {
            reject(err);
        }
    });
};

export const rescindPo = async (
    po_id: string,
    event_type: 'DIRECT' | 'FROM_EVENT',
    msg: string
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        let data = {
            purchase_order_items: [],
            status: 'RESCINDED',
            notes: msg,
        };
        try {
            let url =
                event_type === 'FROM_EVENT'
                    ? `/purchase_orders/${po_id}/state/`
                    : `/purchase_orders/po_group/${po_id}/state/`;
            let resp = await put<any, any>(url, data);
            resolve(resp);
        } catch (err) {
            reject(err);
        }
    });
};

export const sellerDeclinePo = async (
    po_id: string,
    msg: string,
    event_type: 'FROM_EVENT' | 'DIRECT'
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        let data = {
            purchase_order_items: [],
            status: 'DECLINED',
            notes: msg.trim() === '' ? '-' : msg.trim(),
        };
        try {
            let url =
                event_type === 'FROM_EVENT'
                    ? `/purchase_orders/${po_id}/state/`
                    : `/purchase_orders/po_group/${po_id}/state/`;
            let resp = await put<any, any>(url, data);
            resolve(resp);
        } catch (err) {
            reject(err);
        }
    });
};

export const sellectAcceptPo = async (
    po_id: string,
    event_type: 'FROM_EVENT' | 'DIRECT'
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        let data: {
            status: string;
            purchase_order_items: [];
        } = {
            status: 'ONGOING',
            purchase_order_items: [],
        };
        try {
            let url =
                event_type === 'FROM_EVENT'
                    ? `/purchase_orders/${po_id}/state/`
                    : `/purchase_orders/po_group/${po_id}/state/`;
            let resp = await put<any, any>(url, data);
            resolve(resp);
        } catch (err) {
            reject(err);
        }
    });
};

export const fetchPOItemDetails = async (
    itemId: string
): Promise<IPOItemDetail> => {
    // const response: any = await get<any>(
    //     `/purchase_orders/item/${itemId}`
    // );
    // const data: any[] = response.data;
    const data: {
        item_name: string;
        item_code: string;
        allocated: any[];
        pending: any[];
        issued: any[];
    } = {
        item_name: 'Ascorbic Acid',
        item_code: 'I000033',
        allocated: [
            {
                vendorName: 'Hufort Healthcare',
                deliveryDate: '',
                quantity: '10',
                unit: 'kg',
                rate: '300',
                total: '3000',
                currency: 'INR',
            },
            {
                vendorName: 'Nikeon Corporation',
                deliveryDate: '10-12-2022',
                quantity: '20',
                unit: 'kg',
                rate: '200',
                total: '4000',
                currency: 'INR',
            },
        ],
        pending: [
            {
                po_id: 'ID000006',
                po_status: 'APPROVAL_PENDING',
                quantity: '20',
                unit: 'kg',
                rate: '200',
                total: '4000',
                currency: 'INR',
                creator: 'Nilesh Anam',
            },
        ],
        issued: [
            {
                po_id: 'ID000006',
                po_status: 'APPROVAL_PENDING',
                quantity: '20',
                unit: 'kg',
                rate: '200',
                total: '4000',
                currency: 'INR',
                creator: 'Nilesh Anam',
            },
        ],
    };
    if (data) {
        const newItem: IPOItemDetail = {
            itemName: data.item_name,
            itemCode: data.item_code,
            allocated: data.allocated.map((allocated: any, idx: number) => {
                const allocatedData: IPOAllocatedDetail = {
                    index: idx,
                    vendorName: allocated.vendorName,
                    deliveryDate: allocated.deliveryDate,
                    quantity: allocated.quantity,
                    unit: allocated.unit,
                    rate: allocated.rate,
                    total: allocated.total,
                    currency: allocated.currency,
                };
                return allocatedData;
            }),
            totalQuantity: 70,
            totalAllocated: data.allocated.reduce(
                (prev, current) => prev + parseFloat(current.quantity),
                0
            ),
            measurementUnit: 'kg',
            pending: data.pending.map((pending: any, idx: number) => {
                const pendingData: IPOPendingDetail = {
                    index: idx,
                    poId: pending.po_id,
                    poStatus: pending.po_status,
                    quantity: pending.quantity,
                    rate: pending.rate,
                    total: pending.total,
                    currency: pending.currency,
                    creator: pending.creator,
                };
                return pendingData;
            }),
            totalPending: data.pending.reduce(
                (prev, current) => prev + parseFloat(current.quantity),
                0
            ),
            issued: data.issued.map((issued: any, idx: number) => {
                const issuedData: IPOIssuedDetail = {
                    index: idx,
                    poId: issued.po_id,
                    poStatus: issued.po_status,
                    quantity: issued.quantity,
                    rate: issued.rate,
                    total: issued.total,
                    currency: issued.currency,
                    creator: issued.creator,
                };
                return issuedData;
            }),
            totalIssued: data.issued.reduce(
                (prev, current) => prev + parseFloat(current.quantity),
                0
            ),
        };
        return newItem;
    }
    return data;
};

export const getLatestPoItem = (
    enterpriseItemId: string,
    currency_code_id: string | null,
    measurement_unit_id: string | null,
    paymentType?: 'PER_INVOICE_ITEM' | 'PER_DELIVERABLE'
): Promise<IPurchaseOrderItem | null> => {
    return new Promise(async (resolve, reject) => {
        try {
            let req_URL = `/purchase_orders/items/enterprise_item/latest/?enterprise_item_id=${enterpriseItemId}&purchase_order_type[]=FINAL&purchase_order_type[]=PO_GROUP${
                paymentType ? `&payment_type=${paymentType}` : ''
            }`;
            if (
                currency_code_id !== null &&
                currency_code_id !== '' &&
                measurement_unit_id !== null &&
                measurement_unit_id !== ''
            ) {
                req_URL = `${req_URL}&currency_code_id=${currency_code_id}&measurement_unit_id=${measurement_unit_id}`;
            }
            let resp = await get<IPurchaseOrderItem>(req_URL);
            if (typeof resp.data !== 'string') {
                resolve(resp.data);
            } else {
                resolve(null);
            }
            resp.data.paymentTerms = transformPaymentTermsFromBackendToFrontend(
                {
                    prepayment_percentage: (resp.data as any)
                        .prepayment_percentage,
                    payment_terms: (resp.data as any).payment_terms,
                    deliverables_payment_terms: (resp.data as any)
                        .deliverables_payment_terms,
                }
            );
            JsonNumsToStrings(resp.data);
        } catch (err) {
            reject(err);
        }
    });
};
