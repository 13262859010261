import { IconButton } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { toast } from 'react-toastify';
import { FWTypography } from '../../Common/FWTypograhy';
import { downloadFile } from '../../Global/Services/FileStorageService';

const ChatDocument = ({
    file_name,
    attachment_id,
    boxPosition,
}: {
    file_name: string;
    attachment_id: string;
    boxPosition: 'LEFT' | 'RIGHT';
}) => {
    const downloadChatAttachment = async () => {
        try {
            const resp = await downloadFile(attachment_id);
            window.location.href = resp;
        } catch (error) {
            toast.error('Error downloading file');
        }
    };

    return (
        <Box
            marginBottom={0.5}
            bgcolor={boxPosition === 'LEFT' ? '#f1f1f1' : '#207ce1'}
            padding="0.85rem 0.5rem"
            borderRadius={'0.5rem'}
            className="flex flex--aic flex--jcsb"
        >
            <Box className="flex flex--aifs" maxWidth={'80%'}>
                <Box
                    color={boxPosition === 'LEFT' ? 'grey' : 'white'}
                    marginRight={0.5}
                    fontSize={20}
                >
                    <i className="bi bi-file-earmark-fill"></i>
                </Box>
                <FWTypography
                    color={boxPosition === 'LEFT' ? 'grey' : 'white'}
                    textOverflow={'ellipsis'}
                >
                    {file_name}
                </FWTypography>
            </Box>
            <IconButton
                size={'small'}
                // sx={{ marginLeft: 5 }}
                onClick={() => downloadChatAttachment()}
            >
                <Box
                    color={boxPosition === 'LEFT' ? 'grey' : 'white'}
                    fontSize={18}
                    margin={0.2}
                >
                    <i className="bi bi-arrow-down-circle"></i>
                </Box>
            </IconButton>
        </Box>
    );
};

export default ChatDocument;
