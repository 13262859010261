import { createSlice } from '@reduxjs/toolkit';
import { IPaymentsTabCount } from '../Components/PaymentsDashboardTabs';
import {
    IDefaultViewPaymentItem,
    IInvoiceViewPaymentItem,
    IPaymentsCompletedListSummary,
    IPaymentsPendingListSummary,
} from '../Interfaces/PaymentsInterface';
import {
    IGRDashboardListActions,
    initDashboardListReducer,
    ITabsChangeAction,
    ITabsCountAction,
    ITabsViewChangeAction,
    setSelectedTabReducer,
    setTabCountReducer,
} from '../Reducers/PaymentsDashboardReducers';
import { PendingView } from '../Containers/PaymentsPendingListContainer';

export enum PaymentsDashboardType {
    PENDING = 'pending',
    COMPLETED = 'completed',
}

export interface IPaymentsDashboardState {
    pendingList:
        | IPaymentsPendingListSummary[]
        | IDefaultViewPaymentItem[]
        | IInvoiceViewPaymentItem[];
    completedList: IPaymentsCompletedListSummary[];
    tabsCount: IPaymentsTabCount;
    selectedTab: PaymentsDashboardType;
    selectedTabView: PendingView;
}

export const PaymentsDashboardSlice = createSlice<
    IPaymentsDashboardState,
    {
        initDashboardList: (
            state: IPaymentsDashboardState,
            action: IGRDashboardListActions
        ) => void;
        setTabCount: (
            state: IPaymentsDashboardState,
            action: ITabsCountAction
        ) => void;
        setSelectedTab: (
            state: IPaymentsDashboardState,
            action: ITabsChangeAction
        ) => void;
        setSelectedTabView: (
            state: IPaymentsDashboardState,
            action: ITabsViewChangeAction
        ) => void;
    },
    string
>({
    name: 'PaymentsDashboardStore',
    initialState: {
        pendingList: [],
        completedList: [],
        tabsCount: {
            pending: 0,
            completed: 0,
        },
        selectedTab: PaymentsDashboardType.PENDING,
        selectedTabView: PendingView.DEFAULT,
    },
    reducers: {
        initDashboardList: initDashboardListReducer,
        setTabCount: setTabCountReducer,
        setSelectedTab: setSelectedTabReducer,
        setSelectedTabView: (state, action) => {
            state.selectedTabView = action.payload;
        },
    },
});

export const {
    initDashboardList,
    setTabCount,
    setSelectedTab,
    setSelectedTabView,
} = PaymentsDashboardSlice.actions;

const PaymentsDashboardStore = PaymentsDashboardSlice.reducer;
export default PaymentsDashboardStore;
