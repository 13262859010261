import { createSlice } from '@reduxjs/toolkit';
import { INotification } from '../../Global/Interfaces/NotificationInterfaces';
import {
    INotificationsListActions,
    INotificationsStatusActions,
    updateNotificationListReducer,
    updateNotificationReducer,
} from '../Reducers/NotificationsReducers';

export interface INotificationState {
    notificationsList: INotification[];
}

export const NotificationsSlice = createSlice<
    INotificationState,
    {
        updateNotificationList: (
            state: INotificationState,
            action: INotificationsListActions
        ) => void;
        updateNotification: (
            state: INotificationState,
            action: INotificationsStatusActions
        ) => void;
    },
    string
>({
    name: 'NotificationsStore',
    initialState: {
        notificationsList: [],
    },
    reducers: {
        updateNotificationList: updateNotificationListReducer,
        updateNotification: updateNotificationReducer,
    },
});

export const { updateNotificationList, updateNotification } =
    NotificationsSlice.actions;

const NotificationsStore = NotificationsSlice.reducer;
export default NotificationsStore;
