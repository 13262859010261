import {
    Box,
    CircularProgress,
    DialogActions,
    DialogContent,
    IconButton,
    ListSubheader,
    MenuItem,
    Typography,
} from '@mui/material';
import React from 'react';
import { FWTypography } from '../../Common/FWTypograhy';
import FWSelect from '../../Common/FWSelect';
import { FWInput } from '../../Common/FWInput';
import { FWTooltip } from '../../Common/FWCustomTooltip';
import { useChatNotes } from '../Hooks/useChatNotes';
import { HookStateValue } from '../../Common/Hooks/StateHook';
import { FWPopupWithChildren } from '../../Common/FWPopupWithChildren';
import { FWButton } from '../../Common/FWButton';
import {
    fetchNoteMessages,
    fetchSessionMessages,
} from '../Services/chat.service';
import { toast } from 'react-toastify';
import { getFWDate } from '../../Common/Utils/DateUtils';
import FWPopover from '../../Common/FWPopover';
import { FWMenuItem } from '../../Common/FWCustomMenu';
import Papa from 'papaparse';

const ChatHeaderNotes = ({
    selfNoteContainerOpen,
    setSelfNoteContainerOpen,
    moduleId,
    noteClickHandler,
    sessionID,
    subEventId,
}: {
    selfNoteContainerOpen: boolean;
    setSelfNoteContainerOpen: React.Dispatch<React.SetStateAction<boolean>>;
    moduleId: string;
    noteClickHandler: (
        noteId: { id: string; status: 'ACTIVE' | 'INACTIVE' } | null
    ) => void;
    sessionID: string;
    subEventId: string;
}) => {
    const { notesList, notesListHookState, addCustomNote, rfqItem } =
        useChatNotes(moduleId, subEventId);
    const [searchText, setSearchText] = React.useState('');
    const [addCustomNoteOpen, setAddCustomNoteOpen] = React.useState(false);
    const [customNoteTitle, setCustomNoteTitle] = React.useState('');

    const handleAddCustomNote = () => {
        addCustomNote(customNoteTitle);
        setAddCustomNoteOpen(false);
    };

    const [customNoteWordLength, setCustomNoteWordLength] = React.useState(0);

    //

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const id = open ? 'delivery-schedule-popover' : undefined;
    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
        event.stopPropagation();
    };
    const handleClose = () => setAnchorEl(null);

    const [noteId, setNoteId] = React.useState<string>('SELF');

    const onClickDownloadNotes = (type: 'txt' | 'csv') => {
        const apiToHit =
            noteId === 'SELF'
                ? fetchSessionMessages(sessionID)
                : fetchNoteMessages(noteId);
        apiToHit
            .then((messages) => {
                let file: Blob;

                if (type === 'txt') {
                    let messageString: string = '';
                    messages.forEach((message) => {
                        messageString += `(${getFWDate(
                            message.created_datetime,
                            'DD MMM, hh:mm A'
                        )}): ${message.message}\n`;
                    });
                    file = new Blob([messageString], {
                        type: 'text/plain',
                    });
                } else {
                    const messageArray: any[] = [];
                    messages.forEach((message, i) => {
                        messageArray.push([
                            `${getFWDate(
                                message.created_datetime,
                                'DD MMM, hh:mm A'
                            )}`,
                            message.message,
                        ]);
                    });

                    var csv = Papa.unparse({
                        fields: ['Time', 'Message'],
                        data: messageArray,
                    });

                    file = new Blob([csv], {
                        type: 'text/csv',
                    });
                }
                const element = document.createElement('a');
                element.href = URL.createObjectURL(file);
                element.download = `Notes.${type}`;
                document.body.appendChild(element); // Required for this to work in FireFox
                element.click();
                document.body.removeChild(element);
            })
            .catch((err) => {
                toast.error("Couldn't fetch messages");
            });
        handleClose();
    };

    return (
        <Box
            position={'absolute'}
            width={'-webkit-fill-available'}
            bgcolor={'white'}
            height={'100%'}
            top={selfNoteContainerOpen ? '100%' : '-23%'}
            sx={{ transition: 'all 0.3s ease-in-out' }}
            left={0}
            zIndex={1}
        >
            {/* UI block */}
            <Box
                position={'relative'}
                bgcolor={'white'}
                width={'100%'}
                // height={'100%'}
                // padding={1}
            >
                <Box
                    padding={1}
                    className="flex flex--aife flex--jcsb"
                    gap={1}
                    width={'100%'}
                >
                    <Box width={'40%'}>
                        <FWTypography color={'text.secondary'}>
                            Self Notes
                        </FWTypography>
                        <FWSelect
                            fullWidth
                            placeholder="Select notebook"
                            onChange={(e: any) => {
                                setNoteId(e.target.value);
                                noteClickHandler({
                                    id: e.target.value,
                                    status:
                                        e.target.value === 'SELF'
                                            ? 'ACTIVE'
                                            : [
                                                  ...notesList.custom,
                                                  ...notesList.sellers,
                                                  ...notesList.items,
                                              ].find(
                                                  ({ note_id }) =>
                                                      note_id === e.target.value
                                              )?.status ?? 'INACTIVE',
                                });
                            }}
                            onClose={(e) => {
                                setTimeout(() => {
                                    setSearchText('');
                                }, 500);
                            }}
                            // value={selectedNote}
                            defaultValue={'SELF'}
                        >
                            <Box padding={1}>
                                <FWInput
                                    onChange={(e) =>
                                        setSearchText(e.target.value)
                                    }
                                    onKeyDown={(e) => e.stopPropagation()}
                                    placeholder="Search here"
                                    iconStart={<i className="bi bi-search"></i>}
                                />
                            </Box>
                            {'general'.includes(searchText) && (
                                <MenuItem value={'SELF'}>General</MenuItem>
                            )}
                            <ListSubheader>
                                <div className="flex flex--aic flex--jcsb">
                                    Custom
                                    <FWTooltip title="Add cutom notebook">
                                        <Box>
                                            <IconButton
                                                size={'small'}
                                                sx={{
                                                    fontSize: '1.1rem',
                                                }}
                                                color="primary"
                                                onClick={() =>
                                                    setAddCustomNoteOpen(true)
                                                }
                                            >
                                                <i className="bi bi-plus-lg"></i>
                                            </IconButton>
                                        </Box>
                                    </FWTooltip>
                                </div>
                            </ListSubheader>
                            {notesListHookState.state ===
                            HookStateValue.LOADING ? (
                                <Box
                                    marginY={1}
                                    className="flex flex--aic flex--jcc"
                                >
                                    <CircularProgress size={'20px'} />
                                </Box>
                            ) : notesList.custom.length > 0 ? (
                                notesList.custom
                                    .filter(
                                        ({ title }) =>
                                            title
                                                .toLowerCase()
                                                .includes(
                                                    searchText.toLowerCase()
                                                ) || searchText === ''
                                    )
                                    .map(({ note_id, title }) => (
                                        <MenuItem
                                            value={note_id}
                                            sx={{
                                                paddingLeft: '30px',
                                            }}
                                        >
                                            {title}
                                        </MenuItem>
                                    ))
                            ) : (
                                <Typography
                                    color={'secondary.main'}
                                    fontSize={13}
                                    textAlign={'center'}
                                    marginY={1}
                                >
                                    No custom notes added
                                </Typography>
                            )}
                            <ListSubheader>Vendors</ListSubheader>
                            {notesListHookState.state ===
                            HookStateValue.LOADING ? (
                                <Box
                                    marginY={1}
                                    className="flex flex--aic flex--jcc"
                                >
                                    <CircularProgress size={'20px'} />
                                </Box>
                            ) : notesList.sellers.length > 0 ? (
                                notesList.sellers
                                    .filter(
                                        ({ title }) =>
                                            title
                                                .toLowerCase()
                                                .includes(
                                                    searchText.toLowerCase()
                                                ) || searchText === ''
                                    )
                                    .map(({ note_id, title }) => (
                                        <MenuItem
                                            value={note_id}
                                            sx={{
                                                paddingLeft: '30px',
                                            }}
                                        >
                                            {title}
                                        </MenuItem>
                                    ))
                            ) : (
                                <Typography
                                    color={'secondary.main'}
                                    fontSize={13}
                                    textAlign={'center'}
                                    marginY={1}
                                >
                                    No vendors found
                                </Typography>
                            )}
                            <ListSubheader>Items</ListSubheader>
                            {notesListHookState.state ===
                            HookStateValue.LOADING ? (
                                <Box
                                    marginY={1}
                                    className="flex flex--aic flex--jcc"
                                >
                                    <CircularProgress size={'20px'} />
                                </Box>
                            ) : notesList.items.length > 0 ? (
                                notesList.items
                                    .filter(
                                        ({ title }) =>
                                            title
                                                .toLowerCase()
                                                .includes(
                                                    searchText.toLowerCase()
                                                ) || searchText === ''
                                    )
                                    .map(({ note_id, title }) => (
                                        <MenuItem
                                            value={note_id}
                                            sx={{
                                                paddingLeft: '30px',
                                            }}
                                        >
                                            <FWTypography
                                                width={'100%'}
                                                maxWidth={'250px'}
                                            >
                                                {title}
                                                {rfqItem?.[title] ? ' | ' : ''}
                                                {rfqItem?.[title]
                                                    ?.map(
                                                        (data) =>
                                                            `${
                                                                data.attribute_name
                                                            }: ${data.attribute_value.join(
                                                                '/'
                                                            )}`
                                                    )
                                                    ?.join(` | `)}
                                            </FWTypography>
                                        </MenuItem>
                                    ))
                            ) : (
                                <Typography
                                    color={'text.secondary'}
                                    fontSize={13}
                                    textAlign={'center'}
                                    marginY={1}
                                >
                                    No items found
                                </Typography>
                            )}
                        </FWSelect>
                    </Box>
                    <Box className="flex flex--aic flex--jcsb" gap={1}>
                        {/* 
                            TODO: Share button
                            should implement it when doing forwards
                         */}

                        {false && (
                            <FWTooltip title="Share">
                                <Box>
                                    <IconButton
                                        size={'small'}
                                        sx={{ fontSize: '0.95rem' }}
                                        color="primary"
                                    >
                                        <i className="bi bi-share"></i>
                                    </IconButton>
                                </Box>
                            </FWTooltip>
                        )}
                        <FWTooltip title="Download">
                            <Box>
                                <IconButton
                                    size={'small'}
                                    sx={{ fontSize: '1.1rem' }}
                                    color="primary"
                                    onClick={handleClick}
                                >
                                    <i className="bi bi-download"></i>
                                </IconButton>
                            </Box>
                        </FWTooltip>
                        <FWPopover
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            // remove padding from popover
                            PaperProps={{
                                sx: {
                                    padding: '0 !important',
                                },
                            }}
                        >
                            <FWMenuItem
                                sx={{ paddingY: 1, paddingX: 2 }}
                                onClick={() => onClickDownloadNotes('txt')}
                            >
                                Text(.txt)
                            </FWMenuItem>
                            <FWMenuItem
                                sx={{ paddingY: 1, paddingX: 2 }}
                                onClick={() => onClickDownloadNotes('csv')}
                            >
                                Excel(.csv)
                            </FWMenuItem>
                        </FWPopover>
                    </Box>
                </Box>
                <Box
                    position={'absolute'}
                    bgcolor={'white'}
                    boxShadow={2}
                    padding={1}
                    right={10}
                    top={'100%'}
                    borderRadius={'0 0 20px 20px'}
                    zIndex={1}
                    onClick={() => setSelfNoteContainerOpen((prev) => !prev)}
                    sx={{ cursor: 'pointer' }}
                >
                    <Box
                        sx={{
                            transform: selfNoteContainerOpen
                                ? 'rotate(180deg)'
                                : 'rotate(0deg)',
                            transition: 'all 0.3s ease-in-out',
                        }}
                    >
                        <i className="bi bi-chevron-down"></i>
                    </Box>
                </Box>
            </Box>
            <FWPopupWithChildren
                title={'Add custom note'}
                open={addCustomNoteOpen}
                handleClose={() => setAddCustomNoteOpen(false)}
            >
                <DialogContent>
                    <FWInput
                        inputProps={{ maxLength: 24 }}
                        placeholder="Enter note title..."
                        onChange={(e) => {
                            setCustomNoteTitle(e.target.value);
                            setCustomNoteWordLength(e.target.value.length);
                        }}
                    />
                    <Typography
                        color={'text.secondary'}
                        fontSize={12}
                        textAlign={'right'}
                    >
                        {customNoteWordLength}/24
                    </Typography>
                </DialogContent>
                <DialogActions sx={{ padding: '20px 24px' }}>
                    <FWButton
                        variant={'outlined'}
                        onClick={() => setAddCustomNoteOpen(false)}
                    >
                        Cancel
                    </FWButton>
                    <FWButton
                        variant={'contained'}
                        onClick={handleAddCustomNote}
                    >
                        Save
                    </FWButton>
                </DialogActions>
            </FWPopupWithChildren>
        </Box>
    );
};

export default ChatHeaderNotes;
