import { Box } from '@mui/material';
import { ColDef } from 'ag-grid-community';
import React from 'react';
import { FWIconButton } from '../../Common/FWButton';
import { FWMenu, FWMenuItem } from '../../Common/FWCustomMenu';
import { FWTooltip } from '../../Common/FWCustomTooltip';
import { FWTypography } from '../../Common/FWTypograhy';
import { getStatusLabelAndColor } from '../HelperFunctions/ItemAnalyticsHelper';
import { ICLMDashboardDetails } from '../Interface/ContractInterfaces';
import ItemsPopOverCLM from './ItemsPopOverCLM';

interface CLMDashboardColProps {
    isVLNDashboard?: boolean;
    isIADashboard?: boolean;
    editPermissions?: boolean;
    onClickCloneOption: (
        contract_uuid: string,
        template_id: string,
        contract_name: string
    ) => void;
    onClickDeleteOption: (contract_uuid: string) => void;
    tab_name: string | null;
}

export const DashCols: ({
    isVLNDashboard,
    isIADashboard,
    editPermissions,
    onClickCloneOption,
    onClickDeleteOption,
    tab_name,
}: CLMDashboardColProps) => ColDef[] = ({
    isVLNDashboard,
    isIADashboard,
    onClickCloneOption,
    editPermissions,
    onClickDeleteOption,
    tab_name,
}) => [
    {
        field: 'contract_id',
        headerName: 'Contract ID',
        // width: 130,
        flex: 1.5,
        cellRenderer: (params: { data: ICLMDashboardDetails }) => {
            return (
                <FWTypography color="text.primary">
                    {params.data.contract_id}
                </FWTypography>
            );
        },
        filter: true,
        sortable: true,
    },
    {
        field: 'contract_name',
        headerName: 'Contract Name',
        // minWidth: 275,
        flex: 2.75,
        cellRenderer: (params: { data: ICLMDashboardDetails }) => (
            <FWTypography>{params.data.contract_name}</FWTypography>
        ),
        filter: true,
        sortable: true,
    },
    {
        field: 'contract_status',
        headerName: 'Status',
        // width: 130,
        flex: 1.5,
        cellRenderer: (params: { data: ICLMDashboardDetails }) => {
            const { color, label } = getStatusLabelAndColor(params.data.status);
            return (
                <FWTypography color={color}>
                    {/* 
                {params.value === 'SUBMITTED'
                    ? 'Ongoing'
                    : params.value.slice(0, 1) +
                      params.value.slice(1).toLowerCase()} */}
                    {label}
                </FWTypography>
            );
        },

        filter: true,
        sortable: true,
    },
    ...(isIADashboard
        ? []
        : [
              {
                  field: 'contract_item_count',
                  headerName: 'Items',
                  // width: 130,
                  flex: 1,
                  cellRenderer: (params: { data: ICLMDashboardDetails }) => (
                      <ItemsPopOverCLM
                          item_details={params.data.item_details}
                          item_count={params.data.contract_item_count}
                      />
                  ),
                  filter: true,
                  sortable: true,
              },
          ]),
    {
        field: 'contract_start_date',
        headerName: 'Contract Start',
        // width: 130,
        flex: 1.5,
        headerClass: 'center',
        cellRenderer: (params: { data: ICLMDashboardDetails }) => (
            <FWTypography color="text.secondary">
                {params.data.contract_start_date}
            </FWTypography>
        ),
        sortable: true,
        filter: true,
    },
    {
        field: 'contract_end_date',
        headerName: 'Contract End',
        minWidth: 150,
        flex: 1.5,
        cellRenderer: (params: { data: ICLMDashboardDetails }) => {
            return (
                <FWTypography color="text.primary">
                    {params.data.contract_end_date}
                </FWTypography>
            );
        },
        filter: true,
        sortable: true,
    },
    {
        field: 'buyer_entity_name',
        headerName: 'Buyer Entity',
        cellRenderer: (params: { data: ICLMDashboardDetails }) => {
            return (
                <FWTypography color="text.primary">
                    {params.data.buyer_entity_name}
                </FWTypography>
            );
        },
        // minWidth: 150,
        flex: 1.5,
        filter: true,
        sortable: true,
    },
    ...(isVLNDashboard
        ? []
        : [
              {
                  field: 'seller_entity_name',
                  headerName: 'Seller Entity',
                  //   minWidth: 200,
                  flex: 2,
                  cellRenderer: (params: { data: ICLMDashboardDetails }) => (
                      <FWTypography color="text.primary">
                          {params.data.seller_entity_name}
                      </FWTypography>
                  ),
                  filter: true,
                  sortable: true,
              },
          ]),

    ...(isIADashboard
        ? [
              {
                  field: 'project_code',
                  headerName: 'Project Code',
                  //   minWidth: 145,
                  flex: 1.5,
                  cellRenderer: (params: { data: ICLMDashboardDetails }) => (
                      <FWTypography color="text.primary">
                          {params.data.project_information?.project_code ?? '-'}
                      </FWTypography>
                  ),
                  filter: true,
                  sortable: true,
              },
          ]
        : []),

    ...(!editPermissions
        ? []
        : [
              {
                  field: 'options',
                  headerName: 'Options',
                  width: 105,
                  flex: 1,
                  cellRenderer: (params: { data: ICLMDashboardDetails }) => {
                      return (
                          <Box margin={'0px auto'}>
                              <OptionsCLM
                                  tab_name={tab_name}
                                  contract_uuid={params.data.contract_uuid}
                                  template_id={params.data.template_id ?? ''}
                                  contract_name={params.data.contract_name}
                                  onDelete={onClickDeleteOption}
                                  onClone={onClickCloneOption}
                              />
                          </Box>
                      );
                  },
                  filter: true,
              },
          ]),
];

function OptionsCLM({
    tab_name,
    contract_uuid,
    template_id,
    contract_name,
    onDelete,
    onClone,
}: {
    tab_name: string | null;
    contract_uuid: string;
    template_id: string;
    contract_name: string;
    onDelete: (contract_uuid: string) => void;
    onClone: (
        contract_uuid: string,
        template_id: string,
        contract_name: string
    ) => void;
}) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event: any) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => setAnchorEl(null);

    return (
        <>
            <FWTooltip title={'Options'}>
                <Box
                    ref={(ref: any) => {
                        if (!ref) return;
                        ref.onclick = (e: any) => {
                            e.stopImmediatePropagation();
                            handleClick(e);
                        };
                    }}
                >
                    <FWIconButton color="primary" onClick={handleClick}>
                        <i className="bi bi-three-dots"></i>
                    </FWIconButton>
                </Box>
            </FWTooltip>
            <FWMenu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {tab_name === 'drafts' ? (
                    <>
                        <FWMenuItem
                            onClick={() =>
                                onClone(
                                    contract_uuid,
                                    template_id,
                                    contract_name
                                )
                            }
                        >
                            <FWTypography color={'primary.main'}>
                                Clone contract
                            </FWTypography>
                        </FWMenuItem>
                        <FWMenuItem onClick={() => onDelete(contract_uuid)}>
                            <FWTypography color={'error.main'}>
                                Delete contract
                            </FWTypography>
                        </FWMenuItem>
                    </>
                ) : tab_name === 'ongoing' ? (
                    <>
                        <FWMenuItem
                            onClick={() =>
                                onClone(
                                    contract_uuid,
                                    template_id,
                                    contract_name
                                )
                            }
                        >
                            <FWTypography color={'primary.main'}>
                                Clone contract
                            </FWTypography>
                        </FWMenuItem>
                    </>
                ) : tab_name === 'finished' ? (
                    <FWMenuItem
                        onClick={() =>
                            onClone(contract_uuid, template_id, contract_name)
                        }
                    >
                        <FWTypography color={'primary.main'}>
                            Clone contract
                        </FWTypography>
                    </FWMenuItem>
                ) : (
                    <FWMenuItem
                        onClick={() =>
                            onClone(contract_uuid, template_id, contract_name)
                        }
                    >
                        <FWTypography color={'primary.main'}>
                            Clone contract
                        </FWTypography>
                    </FWMenuItem>
                )}
            </FWMenu>
        </>
    );
}
