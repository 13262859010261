import { useCallback, useEffect, useState } from 'react';
import { IBuyerItemList } from '../../Events/Interfaces/Buyer/RFQ.model';
import { toast } from 'react-toastify';
import { HookStateValue, useHookState } from '../../Common/Hooks/StateHook';
import {
    addItemCatalogueApi,
    // fetchItemCatalogueListApi,
} from '../../VendorLinkedIn/Services/VendorLinkedIn.service';
import { IVLNItemCatalogueNew } from '../../VendorLinkedIn/Interfaces/VendorLNInterfaces';
import useSellerProfileItems from '../../Costing/Hooks/useSellerProfileItems';
import useSellerItemCatalogueItems from './useSellerItemCatalogueItems';

export const useSellerProfileItemCatalogue = (
    entityId: string,
    vendorProfileId: string
) => {
    const { hookState } = useHookState(HookStateValue.READY);
    const [buyerItems, setBuyerItems] = useState<IBuyerItemList[]>([]);
    const [filteredBuyerItems, setFilteredBuyerItems] = useState<
        IBuyerItemList[]
    >([]);
    const [itemCatalogueList, setItemCatalogueList] = useState<
        IVLNItemCatalogueNew[]
    >([]);
    const [selectedItem, setSelectedItem] = useState<IBuyerItemList | null>(
        null
    );
    const {
        catalogueItems,
        currentCataloguePage,
        setCurrentCataloguePage,
        totalPages,
        setTotalPages,
        fetchCatalogueItems,
    } = useSellerItemCatalogueItems(vendorProfileId);
    const { onSearchItem, onShowMoreItems, buyerMaterItemList } =
        useSellerProfileItems(entityId);
    // useEffect(() => {
    //     if (entityId && vendorProfileId) {
    //         updateHookState(HookStateValue.LOADING);
    //         // const itemsMasterPromise = getBuyerMasterItemsForEntity(entityId);
    //         const itemsCataloguePromise =
    //             fetchItemCatalogueListApi(vendorProfileId);
    //         Promise.all([itemsCataloguePromise])
    //             .then((data) => {
    //                 // setBuyerItems(data[0]);
    //                 setItemCatalogueList(data[0]);
    //                 updateHookState(HookStateValue.READY);
    //             })
    //             .catch(() => {
    //                 updateHookState(HookStateValue.ERROR);
    //                 toast.error('Failed to fetch items/item catalogue');
    //             });
    //     }
    // }, [entityId, updateHookState, vendorProfileId]);

    useEffect(() => {
        setItemCatalogueList(catalogueItems);
    }, [catalogueItems]);

    useEffect(() => {
        setBuyerItems(buyerMaterItemList);
    }, [buyerMaterItemList]);

    const addItemToCatalogue = useCallback(
        async (
            item: IBuyerItemList,
            data: {
                name: string;
                price: string;
                imageUrl: string;
                measurementUnitUuid: string;
                currencyCodeUuid: string;
            }
        ) => {
            try {
                await addItemCatalogueApi(vendorProfileId, item, data);
                //REFETCH HERE
                fetchCatalogueItems();
                // setItemCatalogueList((prev) => [...prev, resp]);
                setSelectedItem(null);
            } catch (error) {
                toast.error('Failed to add item to catalogue');
            }
        },
        [fetchCatalogueItems, vendorProfileId]
    );

    const onItemSelect = (item: IBuyerItemList | null) => {
        setSelectedItem(item);
    };

    const onAddItemToCatalogue = async (data: {
        name: string;
        price: string;
        imageUrl: string;
        measurementUnitUuid: string;
        currencyCodeUuid: string;
    }) => {
        if (!selectedItem) return;
        addItemToCatalogue(selectedItem, data);
    };

    const removeItemFromList = (item_catalogue_id: string) => {
        setItemCatalogueList((prev) =>
            prev.filter((item) => item.item_catalogue_id !== item_catalogue_id)
        );
    };

    const updateItemCatalogueList = (item: IVLNItemCatalogueNew) => {
        setItemCatalogueList((prev) =>
            prev.map((prevItem) =>
                prevItem.item_catalogue_id === item.item_catalogue_id
                    ? item
                    : prevItem
            )
        );
    };

    useEffect(() => {
        if (buyerItems.length > 0 && itemCatalogueList.length > 0) {
            const filteredItems = buyerItems.filter(
                (item) =>
                    !itemCatalogueList.find(
                        (catalogueItem) =>
                            catalogueItem.enterprise_item.enterprise_item_id ===
                            item.enterprise_item.enterprise_item_id
                    )
            );
            setFilteredBuyerItems(filteredItems);
        } else {
            setFilteredBuyerItems(buyerItems);
        }
    }, [buyerItems, itemCatalogueList]);

    return {
        buyerItems,
        filteredBuyerItems,
        hookState,
        itemCatalogueList,
        onItemSelect,
        selectedItem,
        onAddItemToCatalogue,
        removeItemFromList,
        updateItemCatalogueList,
        onSearchItem,
        onShowMoreItems,
        currentCataloguePage,
        setCurrentCataloguePage,
        totalPages,
        setTotalPages,
    };
};
