/*****
 PO List hook
 *****/

import {
    Dispatch,
    SetStateAction,
    useCallback,
    useEffect,
    useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    HookStateValue,
    IHookState,
    useHookState,
} from '../../Common/Hooks/StateHook';
import { IGlobalAppStore } from '../../Redux/Store';
import { PendingView } from '../Containers/PaymentsPendingListContainer';
import {
    IDefaultViewPaymentItem,
    IInvoiceViewPaymentItem,
    IPaymentsCompletedListSummary,
    IPaymentsPendingListSummary,
} from '../Interfaces/PaymentsInterface';
import {
    fetchPaymentsCompletedList,
    fetchPaymentsPendingListDefaultView,
    fetchPaymentsPendingListInvoiceView,
    fetchPaymentsPendingListItemsView,
} from '../Services/PaymentsService';
import {
    PaymentsDashboardType,
    initDashboardList,
    setTabCount,
} from '../Slices/PaymentsDashboardSlice';
import { IPaymentEntityDetails } from './MakePaymentHook';

export interface IPaymentsPendingListProviders {
    hookState: IHookState;
    pendingList:
        | IPaymentsPendingListSummary[]
        | IDefaultViewPaymentItem[]
        | IInvoiceViewPaymentItem[];
    setSelectedItemisedItems: Dispatch<
        SetStateAction<IPaymentsPendingListSummary[]>
    >;
    setSelectedDefaultItems: Dispatch<
        SetStateAction<IDefaultViewPaymentItem[]>
    >;
    setSelectedInvoiceItems: Dispatch<
        SetStateAction<IInvoiceViewPaymentItem[]>
    >;
    makePaymentForItemisedGroupedItems: () => void;
    makePaymentForDefaultGroupedItems: () => void;
    makePaymentForInvoiceGroupedItems: () => void;
}

export const usePaymentsPendingList = (view: PendingView) => {
    const dispatch = useDispatch();
    const { hookState, updateHookState } = useHookState(HookStateValue.READY);

    const { pendingList } = useSelector(
        (state: IGlobalAppStore) => state.PaymentsDashboardStore
    );

    const [selectedItemisedItems, setSelectedItemisedItems] = useState<
        IPaymentsPendingListSummary[]
    >([]);

    const [selectedDefaultItems, setSelectedDefaultItems] = useState<
        IDefaultViewPaymentItem[]
    >([]);

    const [selectedInvoiceItems, setSelectedInvoiceItems] = useState<
        IInvoiceViewPaymentItem[]
    >([]);

    const getPaymentsPendingList = async () => {
        try {
            updateHookState(HookStateValue.LOADING);
            let paymentsPendingList:
                | IPaymentsPendingListSummary[]
                | IDefaultViewPaymentItem[]
                | IInvoiceViewPaymentItem[] = [];

            if (view === PendingView.ITEMISED) {
                paymentsPendingList = await fetchPaymentsPendingListItemsView();
            } else if (view === PendingView.DEFAULT) {
                paymentsPendingList =
                    await fetchPaymentsPendingListDefaultView();
            } else if (view === PendingView.INVOICE) {
                paymentsPendingList =
                    await fetchPaymentsPendingListInvoiceView();
            }

            dispatch(
                initDashboardList({
                    value: paymentsPendingList,
                    type: PaymentsDashboardType.PENDING,
                })
            );
            dispatch(
                setTabCount({
                    type: PaymentsDashboardType.PENDING,
                    value: paymentsPendingList.length,
                })
            );
            updateHookState(HookStateValue.READY);
        } catch (error) {
            updateHookState(HookStateValue.ERROR);
        }
    };

    const setStorageDetails = (
        itemIds: string[],
        entity:
            | IPaymentsPendingListSummary
            | IDefaultViewPaymentItem
            | IInvoiceViewPaymentItem
    ) => {
        localStorage.setItem('paymentItemIds', JSON.stringify(itemIds));
        const details: IPaymentEntityDetails = {
            buyerEntityUid: entity.entityUid,
            buyerEntityName: entity.entityName,
            sellerEntityUid: entity.vendorUid,
            sellerEntityName: entity.vendorName,
            currencyUid: entity.currencyUid,
            vendorContact: entity.vendorContact,
            vendorContactUid: entity.vendorContactUid,
        };
        localStorage.setItem('paymentEntityDetails', JSON.stringify(details));
    };

    const makePaymentForItemisedGroupedItems = useCallback(() => {
        const itemIds = selectedItemisedItems.flatMap(
            (item) => item.invoiceItemUid
        );
        const entity = selectedItemisedItems[0];
        setStorageDetails(itemIds, entity);
    }, [selectedItemisedItems]);

    const makePaymentForDefaultGroupedItems = useCallback(() => {
        const itemIds = selectedDefaultItems.flatMap(
            (item) => item.invoiceItemUids
        );
        const entity = selectedDefaultItems[0];
        setStorageDetails(itemIds, entity);
    }, [selectedDefaultItems]);

    const makePaymentForInvoiceGroupedItems = useCallback(() => {
        const itemIds = selectedInvoiceItems.flatMap(
            (item) => item.invoiceItemUids
        );
        const entity = selectedInvoiceItems[0];
        setStorageDetails(itemIds, entity);
    }, [selectedInvoiceItems]);

    useEffect(() => {
        getPaymentsPendingList();

        return () => {
            setSelectedItemisedItems([]);
            setSelectedDefaultItems([]);
            setSelectedInvoiceItems([]);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const paymentsPendingListProviders: IPaymentsPendingListProviders = {
        hookState,
        pendingList,
        setSelectedItemisedItems,
        makePaymentForItemisedGroupedItems,
        setSelectedDefaultItems,
        setSelectedInvoiceItems,
        makePaymentForDefaultGroupedItems,
        makePaymentForInvoiceGroupedItems,
    };

    return paymentsPendingListProviders;
};

export interface IPaymentsCompletedListProviders {
    hookState: IHookState;
    completedList: IPaymentsCompletedListSummary[];
}

export const usePaymentsCompletedList = () => {
    const dispatch = useDispatch();
    const { hookState, updateHookState } = useHookState(HookStateValue.READY);

    const { completedList } = useSelector(
        (state: any) => state.PaymentsDashboardStore
    );

    const getPaymentsCompletedList = async () => {
        try {
            updateHookState(HookStateValue.LOADING);
            const completedListItems = await fetchPaymentsCompletedList();
            dispatch(
                initDashboardList({
                    value: completedListItems,
                    type: PaymentsDashboardType.COMPLETED,
                })
            );
            dispatch(
                setTabCount({
                    type: PaymentsDashboardType.COMPLETED,
                    value: completedListItems.length,
                })
            );
            updateHookState(HookStateValue.READY);
        } catch (error) {
            updateHookState(HookStateValue.ERROR);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    };

    useEffect(() => {
        getPaymentsCompletedList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const grPastListProviders: IPaymentsCompletedListProviders = {
        hookState,
        completedList,
    };

    return grPastListProviders;
};
