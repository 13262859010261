import { Box, Grid } from '@mui/material';
import { isEmpty } from 'lodash';
import { useContext } from 'react';
import { FWTooltip } from '../../../../Common/FWCustomTooltip';
import { FWInput } from '../../../../Common/FWInput';
import { FWLink } from '../../../../Common/FWLink';
import { FWTypography } from '../../../../Common/FWTypograhy';
import { AuthContext } from '../../../../Contexts/AuthContext';
import { InfoBlock } from '../../Containers/ItemDirectory/ItemDetailsContainer';
import { IItemCustomId } from '../../Interfaces/ItemDirectoryInterface';
import { getDuplicateNames } from '../Settings/GRNQC/GRNQCSettings';

export enum CustomIdsInfo {
    NAME = 'name',
    VALUE = 'value',
}

interface IItemCustomIdsProps {
    data: IItemCustomId[];
    emitCustomIdsAction: (value: IItemCustomId[]) => void;
}

const ItemCustomIds = ({ data, emitCustomIdsAction }: IItemCustomIdsProps) => {
    const { authData } = useContext(AuthContext);

    const handleAddCustomIds = () => {
        data.push({
            name: '',
            value: '',
        });
        emitCustomIdsAction([...data]);
    };

    const handleCustomIdsChange = (event: any, index: number) => {
        let thisCustomId = data[index];
        thisCustomId[event.target.name as keyof IItemCustomId] =
            event.target.value;
        emitCustomIdsAction([...data]);
    };

    const handleRemoveCustomIds = (index: number) => {
        data.splice(index, 1);
        emitCustomIdsAction([...data]);
    };

    const duplicateCustomIds = getDuplicateNames(
        data.map((c) => c.name.toLowerCase().trim()).filter((c) => !isEmpty(c))
    );

    return (
        <Grid container className="flex flex--aife">
            <Grid item xs={data.length > 0 ? 11 : 0}>
                {data.length > 0 && (
                    <Grid item className="flex" xs={12}>
                        <Grid item xs={12} className="flex">
                            <Grid item xs={5}>
                                <FWTypography color="text.secondary">
                                    Custom ID name
                                </FWTypography>
                            </Grid>
                            <Grid item xs={4.7}>
                                <FWTypography color="text.secondary">
                                    Custom ID value
                                </FWTypography>
                            </Grid>
                        </Grid>
                    </Grid>
                )}

                {data.map((customId: IItemCustomId, index: number) => {
                    return (
                        <Grid
                            item
                            className="flex flex--aifs"
                            sx={{ mb: '15px' }}
                            xs={12}
                            key={index}
                        >
                            <>
                                <Grid item xs={12} className="flex">
                                    <InfoBlock item xs={5} sx={{ mb: 0 }}>
                                        <FWInput
                                            value={customId.name}
                                            name={CustomIdsInfo.NAME}
                                            onChange={(event: any) => {
                                                handleCustomIdsChange(
                                                    event,
                                                    index
                                                );
                                            }}
                                            error={duplicateCustomIds.includes(
                                                customId.name
                                                    .toLowerCase()
                                                    .trim()
                                            )}
                                            helper={{
                                                text: duplicateCustomIds.includes(
                                                    customId.name
                                                        .toLowerCase()
                                                        .trim()
                                                )
                                                    ? `Duplicate name ${customId.name}`
                                                    : '',
                                            }}
                                            iconEnd={
                                                <FWTooltip title="Delete Custom ID">
                                                    <div>
                                                        <Box
                                                            color="error.main"
                                                            onClick={() => {
                                                                handleRemoveCustomIds(
                                                                    index
                                                                );
                                                            }}
                                                            sx={{
                                                                cursor: 'pointer',
                                                            }}
                                                        >
                                                            <i className="bi bi-trash flex flex--aic"></i>
                                                        </Box>
                                                    </div>
                                                </FWTooltip>
                                            }
                                            required
                                            disabled={
                                                authData.details?.role !==
                                                'ADMIN'
                                            }
                                        />
                                    </InfoBlock>

                                    <InfoBlock item xs={4.7} sx={{ mb: 0 }}>
                                        <FWInput
                                            value={customId.value}
                                            name={CustomIdsInfo.VALUE}
                                            onChange={(event: any) => {
                                                handleCustomIdsChange(
                                                    event,
                                                    index
                                                );
                                            }}
                                            required
                                            disabled={
                                                authData.details?.role !==
                                                'ADMIN'
                                            }
                                        />
                                    </InfoBlock>
                                </Grid>
                            </>
                        </Grid>
                    );
                })}
            </Grid>

            <Grid
                item
                xs={12}
                sx={{
                    mb:
                        data.length > 0 &&
                        duplicateCustomIds.includes(
                            data[data.length - 1].name.toLowerCase().trim()
                        )
                            ? '33px'
                            : '14px',
                }}
            >
                {authData.details?.role === 'ADMIN' ? (
                    <FWLink onClick={handleAddCustomIds}>
                        Add new Custom ID
                    </FWLink>
                ) : (
                    '-'
                )}
            </Grid>
        </Grid>
    );
};

export default ItemCustomIds;
