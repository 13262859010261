import { FWTypography } from '../../../../Common/FWTypograhy';
import { prefVenAssgnStatusMap } from '../../Constants/ItemDirectoryConstants';
import { PreferredVendorAssignedStatus } from '../../Interfaces/ItemDirectoryInterface';

type PreferredVendorStatusColors = 'success' | 'error' | 'warning';

export const getPreferredVendorStatus = (
    status: string
): {
    color: PreferredVendorStatusColors;
    label: string;
} => {
    let color: PreferredVendorStatusColors = 'success';
    let label = prefVenAssgnStatusMap[status];
    switch (status) {
        case PreferredVendorAssignedStatus.ASSIGNED:
            color = 'success';
            break;
        case PreferredVendorAssignedStatus.UNASSIGNED:
            color = 'error';
            break;
        case PreferredVendorAssignedStatus.PARTIAL:
            color = 'warning';
            break;
    }
    return {
        color,
        label,
    };
};

const PreferredVendorStatusChip = ({ status }: { status: string }) => {
    const { label, color } = getPreferredVendorStatus(status);
    return (
        <FWTypography
            color={color + '.main'}
            variant="caption"
            sx={{ display: 'block', fontSize: '1rem' }}
        >
            {label}
        </FWTypography>
    );
};

export default PreferredVendorStatusChip;
