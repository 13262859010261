export const replaceUrlWithLink = (text: string) => {
    var pattern =
        /(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[A-Z0-9+&@#/%=~_|$])/gim;
    var out = text.replace(pattern, (url: string) => {
        if (!url.match('/(?:(?:https?|ftp|file)://)/')) {
            url = 'http://' + url;
        }
        return `<a href='${url}' target='_blank'>${url}</a>`;
    });

    return out;
};
