import { createSlice } from '@reduxjs/toolkit';
import { IGRTabCount } from '../Components/GRDashboardTabs';
import {
    IGRPastListSummary,
    IGRInvoiceListSummary,
} from '../Interfaces/GRInterface';
import {
    IGRDashboardListActions,
    initDashboardListReducer,
    ITabsChangeAction,
    ITabsCountAction,
    ITabsViewChangeAction,
    setSelectedTabReducer,
    setTabCountReducer,
} from '../Reducers/GRDashboardReducers';
import { PendingView } from '../Containers/GRPendingListContainer';

export enum GRDashboardType {
    PENDING = 'pending',
    PAST = 'past',
}

export interface IGRDashboardState {
    pendingList: IGRInvoiceListSummary[];
    pastList: IGRPastListSummary[];
    tabsCount: IGRTabCount;
    selectedTab: GRDashboardType;
    selectedTabView: PendingView;
}

export const GRDashboardSlice = createSlice<
    IGRDashboardState,
    {
        initDashboardList: (
            state: IGRDashboardState,
            action: IGRDashboardListActions
        ) => void;
        setTabCount: (
            state: IGRDashboardState,
            action: ITabsCountAction
        ) => void;
        setSelectedTab: (
            state: IGRDashboardState,
            action: ITabsChangeAction
        ) => void;
        setSelectedTabVew: (
            state: IGRDashboardState,
            action: ITabsViewChangeAction
        ) => void;
    },
    string
>({
    name: 'GRDashboardStore',
    initialState: {
        pendingList: [],
        pastList: [],
        tabsCount: {
            pending: 0,
            past: 0,
        },
        selectedTab: GRDashboardType.PENDING,
        selectedTabView: PendingView.PURCHASE_ORDER,
    },
    reducers: {
        initDashboardList: initDashboardListReducer,
        setTabCount: setTabCountReducer,
        setSelectedTab: setSelectedTabReducer,
        setSelectedTabVew: (state, action) => {
            state.selectedTabView = action.payload;
        },
    },
});

export const {
    initDashboardList,
    setTabCount,
    setSelectedTab,
    setSelectedTabVew,
} = GRDashboardSlice.actions;

const GRDashboardStore = GRDashboardSlice.reducer;
export default GRDashboardStore;
