import { DialogContent, Divider, Grid, IconButton } from '@mui/material';
import { isEmpty } from 'lodash';
import { useState } from 'react';
import { FWTooltip } from '../../Common/FWCustomTooltip';
import FWIcon from '../../Common/FWIcon';
import FWMultipleTooltipMessages from '../../Common/FWMultipleTooltipMessages';
import FWPopover from '../../Common/FWPopover';
import { FWTypography } from '../../Common/FWTypograhy';
import { FWNumberFormatter } from '../../Common/Utils/CurrencyUtils';
import DotBadge from '../../Components/Shared/DotBadge';
import { generateItemWarnings } from '../../Global/Helpers/ItemAndPOHelpers';
import {
    ICreditListItemSummary,
    IDefaultViewPaymentItem,
    IInvoiceViewPaymentItem,
    IPaymentCompletedItemsList,
    IPaymentListItemSummary,
    IPaymentsCompletedListSummary,
    IPaymentsListContext,
    IPaymentsPendingListSummary,
    PaymentItemStatus,
    PaymentItemType,
    PaymentStatus,
} from '../Interfaces/PaymentsInterface';
import { IPendingAmountData } from './PendingAmountPopover';

export const PaymentsPendingItemNameCell = ({
    data,
    context,
}: {
    data: IPaymentsPendingListSummary;
    context: IPaymentsListContext;
}) => {
    return (
        <div
            className="flex flex--aic"
            style={{
                width: '100%',
            }}
        >
            <FWTypography
                color="primary"
                onClick={(e: any) => {
                    context.handleItemPopupToggle(data.itemDetails);
                }}
                style={{ cursor: 'pointer' }}
            >
                {data.itemDetails.itemName}
            </FWTypography>
        </div>
    );
};

export const PaymentsPendingDueDateCell = ({
    data,
    context,
}: {
    data: IPaymentsPendingListSummary;
    context: IPaymentsListContext;
}) => {
    return (
        <>
            {/* {data.paymentType === PaymentItemType.PREPAYMENT ? (
                <FWTypography color="success.main">
                    {data.paymentType}
                </FWTypography>
            ) : (
                <>
                    {data.amountPending === 0 &&
                    data.onHoldTerminationAmount > 0 ? (
                        <FWTypography>No payment due</FWTypography>
                    ) : (
                        <FWTypography
                            color={
                                data.paymentDueDate < 7
                                    ? data.paymentDueDate < 0
                                        ? 'error.main'
                                        : 'warning.main'
                                    : 'success.main'
                            }
                        >
                            {data.paymentDueDate < 0 ? (
                                'Overdue'
                            ) : (
                                <>
                                    {data.paymentDueDate + ' day'}
                                    {data.paymentDueDate === 1 ? '' : 's'}
                                </>
                            )}
                        </FWTypography>
                    )}
                </>
            )} */}
            {data.paymentType === PaymentItemType.PREPAYMENT ? (
                <FWTypography color="success.main">
                    {data.paymentType}
                </FWTypography>
            ) : data.paymentStatus === PaymentStatus.COMPLETED ? (
                <FWTypography color="success.main">Paid</FWTypography>
            ) : data.paymentDueDate === null ? (
                <FWTypography>-</FWTypography>
            ) : data.paymentDueDate < 0 ? (
                <FWTypography color={'error.main'}>Overdue</FWTypography>
            ) : (
                <FWTypography
                    color={
                        data.paymentDueDate < 7
                            ? 'warning.main'
                            : 'success.main'
                    }
                >
                    {data.paymentDueDate + ' day'}
                    {data.paymentDueDate === 1 ? '' : 's'}
                </FWTypography>
            )}
        </>
    );
};

export const PaymentCompletedItemNameCell = ({
    data,
    context,
}: {
    data: IPaymentCompletedItemsList;
    context: IPaymentsListContext;
}) => {
    return (
        <div
            className="flex flex--aic"
            style={{
                width: '100%',
            }}
        >
            <FWTypography
                color="primary"
                onClick={(e: any) => {
                    context.handleItemPopupToggle(data.itemDetails);
                }}
                style={{ cursor: 'pointer' }}
            >
                {data.itemDetails.itemName}
            </FWTypography>
        </div>
    );
};

export const MakePaymentsItemNameCell = ({
    data,
    context,
}: {
    data: IPaymentListItemSummary;
    context: IPaymentsListContext;
}) => {
    const itemWarnings = generateItemWarnings(data);

    if (data.onHoldTerminationQuantity > 0) {
        itemWarnings.warnings.push(
            `Item has ${data.onHoldTerminationQuantity} ${data.totalInfo.measurementUnit}(s) on hold/termination`
        );
    }

    return (
        <div
            className="flex flex--aic"
            style={{
                width: '100%',
            }}
        >
            <FWTypography
                color="primary"
                onClick={(e: any) => {
                    context.handleItemPopupToggle(data.itemDetails);
                }}
                sx={{
                    cursor: 'pointer',
                    maxWidth: '100%',
                }}
                id={'makePaymentPage_itemDetails'}
            >
                {data.itemDetails.itemName}
            </FWTypography>
            {itemWarnings.warnings.length > 0 && (
                <div className="ml--5">
                    <FWMultipleTooltipMessages
                        messages={itemWarnings.warnings}
                        iconColor={itemWarnings.color + '.main'}
                        icon={
                            <div style={{ lineHeight: 0 }}>
                                <DotBadge color={itemWarnings.color} />
                            </div>
                        }
                    />
                </div>
            )}
        </div>
    );
};

export const PaymentItemDetailCell = ({
    data,
    context,
}: {
    data: IPaymentListItemSummary;
    context: IPaymentsListContext;
}) => {
    return (
        <div
            style={{
                margin: '0 auto',
                cursor: 'pointer',
            }}
            onClick={(e: any) => {
                context.handleItemDetailPopoverToggle(e.target, data);
            }}
        >
            <FWIcon
                name="bi bi-info-circle"
                className="fs--18"
                color={
                    data.poOnHold ||
                    data.poTermination ||
                    (data.status !== PaymentItemStatus.GOODS_RECEIVED &&
                        data.paymentType !== PaymentItemType.PREPAYMENT)
                        ? 'warning.main'
                        : 'primary.main'
                }
            />
        </div>
    );
};

export const CreditDetailCell = ({
    data,
    context,
}: {
    data: ICreditListItemSummary;
    context: IPaymentsListContext;
}) => {
    return (
        <div
            style={{
                margin: '0 auto',
                cursor: 'pointer',
            }}
            onClick={(e: any) => {
                context.handleCreditDetailPopoverToggle(e.target, data);
            }}
        >
            <FWIcon
                name="bi bi-info-circle"
                className="fs--18"
                color="primary.main"
            />
        </div>
    );
};

export const PaymentItemTotalInfoCell = ({
    data,
    context,
}: {
    data:
        | IPaymentsPendingListSummary
        | IPaymentListItemSummary
        | IPaymentCompletedItemsList;
    context: IPaymentsListContext;
}) => {
    return data.paymentType === PaymentItemType.GOODS_PAYMENT ? (
        <div
            style={{
                marginLeft: 'auto',
                cursor: 'pointer',
                width: '100%',
            }}
            onClick={(e: any) => {
                context.handleTotalPopoverToggle(e.target, data.totalInfo);
            }}
        >
            <FWTypography
                sx={{
                    width: '100%',
                    textAlign: 'right',
                }}
                color="primary.main"
            >
                {FWNumberFormatter(
                    data.totalAmount < 0 ? 0 : data.totalAmount,
                    data.currency
                )}
            </FWTypography>
        </div>
    ) : (
        <div
            style={{
                marginLeft: 'auto',
                width: '100%',
            }}
        >
            <FWTypography
                sx={{
                    width: '100%',
                    textAlign: 'right',
                }}
            >
                {FWNumberFormatter(
                    data.totalAmount < 0 ? 0 : data.totalAmount,
                    data.currency
                )}
            </FWTypography>
        </div>
    );
};

export const PaymentPendingInfoCell = ({
    data,
    context,
}: {
    data: IPaymentsPendingListSummary | IPaymentListItemSummary;
    context: IPaymentsListContext;
}) => {
    const pendingData: IPendingAmountData = {
        totalAmount: data.totalAmount,
        amountPending: data.amountPending,
        rejectedAmount: data.rejectedAmount,
        amountPaid: data.completedPayment,
        currency: data.currency,
        creditedAmount: data.creditedAmount,
        onHoldTerminationAmount: data.onHoldTerminationAmount,
        grRejectedAmount: data.grRejectedAmount,
        qcRejectedAmount: data.qcRejectedAmount,
        invoiceItemUid: data.invoiceItemUid,
        totalInvoicedQty: data.totalInfo.quantity,
        measurementUnit: data.totalInfo.measurementUnit,
        buyerEntityId: data.buyerEntityId,
    };
    return data.paymentType === PaymentItemType.GOODS_PAYMENT ? (
        <div
            style={{
                marginLeft: 'auto',
                cursor: 'pointer',
                width: '100%',
            }}
            onClick={(e: any) => {
                context.handlePendingPopoverToggle(e.target, pendingData);
            }}
        >
            <FWTypography
                sx={{
                    width: '100%',
                    textAlign: 'right',
                }}
                color="primary.main"
            >
                {FWNumberFormatter(
                    data.amountPending < 0 ? 0 : data.amountPending,
                    data.currency
                )}
            </FWTypography>
        </div>
    ) : (
        <div
            style={{
                marginLeft: 'auto',
                width: '100%',
            }}
        >
            <FWTypography
                sx={{
                    width: '100%',
                    textAlign: 'right',
                }}
            >
                {FWNumberFormatter(
                    data.amountPending < 0 ? 0 : data.amountPending,
                    data.currency
                )}
            </FWTypography>
        </div>
    );
};

export const PrepaymentInvoicesDetailCell = ({
    data,
    context,
}: {
    data:
        | IPaymentsPendingListSummary
        | IPaymentListItemSummary
        | IDefaultViewPaymentItem;
    context: IPaymentsListContext;
}) => {
    return data.paymentType === PaymentItemType.PREPAYMENT ? (
        <div
            style={{
                cursor: 'pointer',
                width: '100%',
            }}
            className="flex flex--aic"
        >
            <FWTypography
                sx={{
                    maxWidth: '100%',
                }}
            >
                {data.invoiceId || '-'}
            </FWTypography>
            &nbsp;
            {data.showPrepayment && (
                <FWTooltip title="Prepayment invoice">
                    <div
                        style={{
                            lineHeight: 1,
                        }}
                    >
                        <IconButton
                            color="primary"
                            onClick={(e: any) => {
                                context.handlePrepaymentInvoicesPopoupToggle(
                                    data.poUid
                                );
                            }}
                            sx={{
                                ml: '5px',
                                p: 0,
                            }}
                        >
                            <FWIcon name="bi bi-file-earmark-ruled" size={22} />
                        </IconButton>
                    </div>
                </FWTooltip>
            )}
        </div>
    ) : (
        <FWTypography>{data.invoiceId || '-'}</FWTypography>
    );
};

export const PrepaymentPendingInvoicesDetailCell = ({
    data,
    context,
    allowPaymentBeforeQcForEntity,
}: {
    data:
        | IPaymentsPendingListSummary
        | IDefaultViewPaymentItem
        | IInvoiceViewPaymentItem;
    context: IPaymentsListContext;
    allowPaymentBeforeQcForEntity: {
        [entityUid: string]: boolean;
    };
}) => {
    const itemWarnings = generateItemWarnings(data);

    if (data.onHoldTerminationQuantity > 0) {
        itemWarnings.warnings.push(
            `Item has ${data.onHoldTerminationQuantity} ${data.totalInfo.measurementUnit}(s) on hold/termination`
        );
    }
    if (data.gr_status === PaymentItemStatus.GOODS_PENDING) {
        itemWarnings.warnings.push('GR pending');
    }
    if (data.qc_status !== 'COMPLETE') {
        if (
            (
                data as
                    | IPaymentsPendingListSummary
                    | IDefaultViewPaymentItem
                    | IInvoiceViewPaymentItem
            ).paymentType !== PaymentItemType.PREPAYMENT
        ) {
            itemWarnings.warnings.push('QC pending');
            if (
                !Object.keys(allowPaymentBeforeQcForEntity).includes(
                    data.entityUid
                ) ||
                !allowPaymentBeforeQcForEntity[data.entityUid]
            ) {
                itemWarnings.color = 'error';
            }
        }
    }

    return data.paymentType === PaymentItemType.PREPAYMENT ? (
        <div
            style={{
                cursor: 'pointer',
                width: '100%',
            }}
            className="flex flex--aic"
        >
            <FWTypography
                sx={{
                    maxWidth: '100%',
                }}
            >
                {data.invoiceId || '-'}
            </FWTypography>
            {data.showPrepayment && (
                <FWTooltip title="Prepayment invoice">
                    <div
                        style={{
                            lineHeight: 1,
                        }}
                    >
                        <IconButton
                            color="primary"
                            onClick={(e: any) => {
                                context.handlePrepaymentInvoicesPopoupToggle(
                                    data.poUid
                                );
                            }}
                            sx={{
                                ml: '5px',
                                p: 0,
                            }}
                            id="paymentsDashboard_sellerPrepaymentInvoiceButton"
                        >
                            <FWIcon name="bi bi-file-earmark-ruled" size={22} />
                        </IconButton>
                    </div>
                </FWTooltip>
            )}
            {itemWarnings.warnings.length > 0 && (
                <div className="ml--5">
                    <FWMultipleTooltipMessages
                        messages={itemWarnings.warnings}
                        iconColor={itemWarnings.color + '.main'}
                        icon={
                            <div style={{ lineHeight: 0 }}>
                                <DotBadge color={itemWarnings.color} />
                            </div>
                        }
                    />
                </div>
            )}
        </div>
    ) : (
        <div
            style={{
                cursor: 'pointer',
                width: '100%',
            }}
            className="flex flex--aic"
        >
            <FWTypography
                sx={{
                    maxWidth: '100%',
                }}
            >
                {data.invoiceId || '-'}
            </FWTypography>
            {itemWarnings.warnings.length > 0 && (
                <div className="ml--5">
                    <FWMultipleTooltipMessages
                        messages={itemWarnings.warnings}
                        iconColor={itemWarnings.color + '.main'}
                        icon={
                            <div style={{ lineHeight: 0 }}>
                                <DotBadge color={itemWarnings.color} />
                            </div>
                        }
                    />
                </div>
            )}
        </div>
    );
};

export const PaymentCompletedTotalAmountCell = ({
    data,
    context,
}: {
    data: IPaymentsCompletedListSummary;
    context: any;
}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
        event.stopPropagation();
    };
    const handleClose = () => setAnchorEl(null);
    return (
        <>
            <div
                style={{
                    marginLeft: 'auto',
                    cursor: 'pointer',
                }}
                ref={(ref) => {
                    if (!ref) return;
                    ref.onclick = (e: any) => {
                        e.stopPropagation();
                        handleClick(e);
                    };
                }}
            >
                <FWTypography
                    sx={{ width: '100%', textAlign: 'right' }}
                    color="primary.main"
                >
                    {FWNumberFormatter(
                        data.amountPaid,
                        isEmpty(data.currency) ? null : data.currency
                    )}
                </FWTypography>
            </div>
            <FWPopover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <DialogContent sx={{ m: 0, p: 0 }}>
                    <Grid container spacing={1} style={{ width: '300px' }}>
                        <Grid
                            item
                            xs={12}
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                        >
                            <FWTypography color="text.secondary">
                                Amount paid
                            </FWTypography>
                            <FWTypography>
                                {FWNumberFormatter(
                                    data.transactionAmount,
                                    data.currency
                                )}
                            </FWTypography>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                        >
                            <FWTypography color="text.secondary">
                                Credit used
                            </FWTypography>
                            <FWTypography>
                                {FWNumberFormatter(
                                    data.creditAmount,
                                    data.currency
                                )}
                            </FWTypography>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                        >
                            <FWTypography fontWeight={500}>
                                Total amount
                            </FWTypography>
                            <FWTypography fontWeight={500}>
                                {FWNumberFormatter(
                                    data.amountPaid,
                                    data.currency
                                )}
                            </FWTypography>
                        </Grid>
                    </Grid>
                </DialogContent>
            </FWPopover>
        </>
    );
};
