import { createSlice } from '@reduxjs/toolkit';
import {
    IUserDefaultsActions,
    updateUserDefaultsReducer,
} from '../Reducers/UserDefaultsReducer';

export interface IUserDefaultsState {
    homepage?: string;
    timezone?: string;
    number_format: string;
    primary_entity_id?: string;
    enterpriseId?: string;
}

export const userDefaultsSlice = createSlice<
    IUserDefaultsState | null,
    {
        updateUserDefaults: (
            state: IUserDefaultsState | null,
            action: IUserDefaultsActions
        ) => void;
    },
    string
>({
    name: 'UserDefaultsStore',
    initialState: null,
    reducers: {
        updateUserDefaults: updateUserDefaultsReducer,
    },
});

export const { updateUserDefaults } = userDefaultsSlice.actions;

const UserDefaultsStore = userDefaultsSlice.reducer;
export default UserDefaultsStore;
