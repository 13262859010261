import { ColDef } from 'ag-grid-community';
import moment from 'moment';
import { FWTooltip } from '../../../Common/FWCustomTooltip';
import FWIcon from '../../../Common/FWIcon';
import { FWTypography } from '../../../Common/FWTypograhy';
import ItemPreferredVendorsCell from '../Components/ItemDirectory/ItemPreferredVendorsCell';
import PreferredVendorStatusChip from '../Components/ItemDirectory/ItemPreferredVendorStatusChip';
import {
    EntitiesCell,
    EntityOptionsCell,
    ItemIDCell,
    ItemNameCell,
    OptionsCell,
    PreferredVendorsCell,
} from '../Components/ItemDirectory/ItemsCellRenderers';
import {
    IItemSummary,
    ItemEntityStatus,
    PreferredVendorAssignedStatus,
    PreferredVendorStatus,
} from '../Interfaces/ItemDirectoryInterface';
import EntityItemsListOptionsMenu from '../Pages/ItemDirectory/EntityItemsListOptionsMenu';
import {
    DASHBOARD_FIELD,
    DASHBOARD_FIELD_COLUMN,
    DASHBOARD_FIELD_NAME,
    DASHBOARD_FIELD_TYPE,
    DASHBOARD_SECTION_NAME,
    TEMPLATE_FIELD_TYPE,
} from '../../../ProjectGlCostCenter/interface/project.model';
import { CustomFieldType } from '../../../SRM/Constants/Interfaces/documentTemplate';
import { IFieldTypeLabel } from '../../../ProjectGlCostCenter/helpers/projectDashboardHelper';

export const itemsListColumns: (
    showOptions: boolean,
    isSingleEntity: boolean
) => ColDef[] = (showOptions, isSingleEntity) => {
    const columns: ColDef[] = [
        {
            field: 'itemCode',
            headerName: 'Item ID',
            width: 230,
            flex: 1,
            pinned: 'left',
            lockPinned: true,
            cellClass: 'lock-pinned',
            refData: {
                [DASHBOARD_FIELD_NAME]: 'code',
                [DASHBOARD_FIELD_COLUMN]: 'Item ID',
                [DASHBOARD_SECTION_NAME]: '',
                [DASHBOARD_FIELD]: 'BUILTIN',
                [DASHBOARD_FIELD_TYPE]: 'SHORTTEXT',
                [TEMPLATE_FIELD_TYPE]: 'FW',
            },
            cellRenderer: ItemIDCell,
            sortable: true,
            filter: true,
            valueGetter: ({ data }: { data: IItemSummary }) => data.itemCode,
            getQuickFilterText: ({ data }: { data: IItemSummary }) =>
                data.itemCode,
        },
        {
            field: 'name',
            headerName: 'Item Name',
            flex: 1,
            minWidth: 962,
            lockPinned: true,
            cellClass: 'lock-pinned',
            refData: {
                [DASHBOARD_FIELD_NAME]: 'name',
                [DASHBOARD_FIELD_COLUMN]: 'Item Name',
                [DASHBOARD_SECTION_NAME]: '',
                [DASHBOARD_FIELD]: 'BUILTIN',
                [DASHBOARD_FIELD_TYPE]: 'SHORTTEXT',
                [TEMPLATE_FIELD_TYPE]: 'FW',
            },
            cellRenderer: ItemNameCell,
            sortable: true,
            filter: true,
            valueGetter: ({ data }: { data: IItemSummary }) => data.name,
            getQuickFilterText: ({ data }: { data: IItemSummary }) => data.name,
        },
        {
            field: 'preferredVendors',
            headerName: 'Preferred vendors',
            flex: 1,
            width: 200,
            lockPinned: true,
            cellClass: 'lock-pinned',
            refData: {
                [DASHBOARD_FIELD_NAME]: 'preferred_vendor_status',
                [DASHBOARD_FIELD_COLUMN]: 'Preferred vendors',
                [DASHBOARD_SECTION_NAME]: '',
                [DASHBOARD_FIELD]: 'BUILTIN',
                [DASHBOARD_FIELD_TYPE]: 'SHORTTEXT',
                [TEMPLATE_FIELD_TYPE]: 'FW',
            },
            cellRenderer: ({ data }: { data: IItemSummary }) => (
                <PreferredVendorStatusChip
                    status={data.preferredVendorAssignedStatus}
                />
            ),
            sortable: true,
            filter: true,
            valueGetter: ({ data }: { data: IItemSummary }) =>
                prefVenAssgnStatusMap[data.preferredVendorAssignedStatus],
            getQuickFilterText: ({ data }: { data: IItemSummary }) =>
                prefVenAssgnStatusMap[data.preferredVendorAssignedStatus],
        },
        {
            field: 'modifiedDateTime',
            headerName: 'Modified on',
            minWidth: 220,
            lockPinned: true,
            cellClass: 'lock-pinned',
            cellRenderer: ({ data }: { data: IItemSummary }) => (
                <FWTypography color="text.secondary">
                    {data.modifiedDateTime}
                </FWTypography>
            ),
            refData: {
                [DASHBOARD_FIELD_NAME]: 'modified_datetime',
                [DASHBOARD_FIELD_COLUMN]: 'Modified on',
                [DASHBOARD_SECTION_NAME]: '',
                [DASHBOARD_FIELD]: 'BUILTIN',
                [DASHBOARD_FIELD_TYPE]: CustomFieldType.DATETIME,
                [TEMPLATE_FIELD_TYPE]: 'FW',
            },
            sortable: true,
            filter: true,
            valueGetter: ({ data }: { data: IItemSummary }) =>
                data.modifiedDateTime,
            getQuickFilterText: ({ data }: { data: IItemSummary }) =>
                data.modifiedDateTime,
            comparator: (a, b) => {
                return moment(a).isAfter(moment(b)) ? 1 : -1;
            },
        },
        {
            field: 'options',
            headerName: 'Options',
            pinned: 'right',
            lockPinned: true,
            cellClass: 'lock-pinned',
            maxWidth: 120,
            headerClass: 'center',
            cellRenderer: OptionsCell,
            sortable: false,
            filter: false,
        },
    ];

    // Add the "Assigned entities" column only if not in SINGLE_ENTITY mode
    if (!isSingleEntity) {
        columns.splice(3, 0, {
            field: 'entities',
            headerName: 'Assigned entities',
            width: 190,
            lockPinned: true,
            cellClass: 'lock-pinned',
            refData: {
                [DASHBOARD_FIELD_NAME]: 'entity_count',
                [DASHBOARD_FIELD_COLUMN]: 'Assigned entities',
                [DASHBOARD_SECTION_NAME]: '',
                [DASHBOARD_FIELD]: 'BUILTIN',
                [DASHBOARD_FIELD_TYPE]: 'SHORTTEXT',
                [TEMPLATE_FIELD_TYPE]: 'FW',
            },
            headerClass: 'center',
            cellRenderer: EntitiesCell,
            sortable: true,
            filter: true,
            valueGetter: ({ data }: { data: IItemSummary }) =>
                data.entities.length,
            getQuickFilterText: ({ data }: { data: IItemSummary }) =>
                data.entities.join(', '),
        });
    }

    return columns;
};

export const itemAdminFieldTypeLabel = (
    currentTab: string,
    isSingleEntity: boolean
): IFieldTypeLabel[] => {
    const fields: IFieldTypeLabel[] = [
        {
            label: 'Item ID',
            column: 'Item ID',
            fieldLabelWithSectionName: 'Item ID',
            isFieldFWFieldInCustomSection: false,
            fieldType: 'SHORTTEXT',
            is_built_in: true,
            sectionName: null,
            sectionAlternameName: null,
            choices: [],
        },
        {
            label: 'Item Name',
            column: 'Item Name',
            fieldLabelWithSectionName: 'Item Name',
            isFieldFWFieldInCustomSection: false,
            fieldType: 'SHORTTEXT',
            is_built_in: true,
            sectionName: null,
            sectionAlternameName: null,
            choices: [],
        },
        {
            label: 'Preferred vendors',
            column: 'Preferred vendors',
            fieldLabelWithSectionName: 'Preferred vendors',
            isFieldFWFieldInCustomSection: false,
            fieldType: CustomFieldType.CHOICE,
            is_built_in: true,
            sectionName: null,
            sectionAlternameName: null,
            choices: ['Assigned', 'Not Assigned'],
        },
        // {
        //     label: 'Assigned entities',
        //     column: 'Assigned entities',
        //     fieldLabelWithSectionName: 'Assigned entities',
        //     isFieldFWFieldInCustomSection: false,
        //     fieldType: 'SHORTTEXT',
        //     is_built_in: true,
        //     sectionName: null,
        //     sectionAlternameName: null,
        //     choices: [],
        // },
        {
            label: 'Modified on',
            column: 'Modified on',
            fieldLabelWithSectionName: 'Modified on',
            isFieldFWFieldInCustomSection: false,
            fieldType: CustomFieldType.DATETIME,
            is_built_in: true,
            sectionName: null,
            sectionAlternameName: null,
            choices: [],
        },
    ];
    if (!isSingleEntity) {
        fields.push({
            label: 'Assigned entities',
            column: 'Assigned entities',
            fieldLabelWithSectionName: 'Assigned entities',
            isFieldFWFieldInCustomSection: false,
            fieldType: 'SHORTTEXT',
            is_built_in: true,
            sectionName: null,
            sectionAlternameName: null,
            choices: [],
        });
    }

    return fields;
};
export const entityItemsListColumns: (showOptions: boolean) => ColDef[] = (
    showOptions
) => [
    {
        field: 'itemCode',
        headerName: 'Item ID',
        width: 150,
        cellRenderer: ItemIDCell,
        sortable: true,
        filter: true,
        valueGetter: ({ data }: { data: IItemSummary }) => data.itemCode,
        getQuickFilterText: ({ data }: { data: IItemSummary }) => data.itemCode,
    },
    {
        field: 'name',
        headerName: 'Item Name',
        flex: 1,
        minWidth: 200,
        cellRenderer: ItemNameCell,
        sortable: true,
        filter: true,
        valueGetter: ({ data }: { data: IItemSummary }) => data.name,
        getQuickFilterText: ({ data }: { data: IItemSummary }) => data.name,
    },
    {
        field: 'preferredVendors',
        headerName: 'Preferred vendors',
        width: 200,
        cellRenderer: PreferredVendorsCell,
        sortable: true,
        filter: true,
        valueGetter: ({ data }: { data: IItemSummary }) =>
            data.preferredVendorsCount,
    },
    {
        field: 'modifiedDateTime',
        headerName: 'Modified on',
        width: 220,
        cellRenderer: ({ data }: { data: IItemSummary }) => (
            <FWTypography color="text.secondary">
                {data.modifiedDateTime}
            </FWTypography>
        ),
        sortable: true,
        filter: true,
        valueGetter: ({ data }: { data: IItemSummary }) =>
            data.modifiedDateTime,
        getQuickFilterText: ({ data }: { data: IItemSummary }) =>
            data.modifiedDateTime,
        comparator: (a, b) => {
            return moment(a).isAfter(moment(b)) ? 1 : -1;
        },
    },
    ...(showOptions
        ? [
              {
                  field: 'options',
                  headerName: 'Options',
                  width: 100,
                  headerClass: 'center',
                  cellRenderer: EntityItemsListOptionsMenu,
              },
          ]
        : []),
];

export const itemEntitiesListColumns: (showOptions: boolean) => ColDef[] = (
    showOptions
) => [
    {
        field: 'id',
        headerName: 'No.',
        width: 90,
        cellRenderer: (params: any) => +params.data.id + 1,
        sortable: true,
        filter: true,
        valueGetter: (params) => +params.data.id,
    },
    {
        field: 'entityName',
        headerName: 'Entity name',
        minWidth: 210,
        flex: 1,
        cellRenderer: (params: any) => (
            <div className="flex flex--aic">
                <FWTypography>{params.data.entityName}</FWTypography>
                {params.data.status === ItemEntityStatus.ACTIVE && (
                    <FWTooltip
                        title={`Linked to ${params.data.entityName}`}
                        placement="right"
                    >
                        <div className="ml--10">
                            <FWIcon
                                name="bi bi-check-circle"
                                color="success.main"
                            />
                        </div>
                    </FWTooltip>
                )}
            </div>
        ),
        sortable: true,
        filter: true,
        valueGetter: (params) => params.data.entityName,
    },
    {
        field: 'preferredVendors',
        headerName: 'Preferred Vendors',
        width: 200,
        cellRenderer: ItemPreferredVendorsCell,
        sortable: true,
        filter: true,
        valueGetter: (params) => +params.data.preferredVendors,
    },
    ...(showOptions
        ? [
              {
                  field: 'options',
                  headerName: 'Options',
                  width: 100,
                  headerClass: 'center',
                  cellRenderer: EntityOptionsCell,
              },
          ]
        : []),
];

export const prefVenAssgnStatusMap: { [key: string]: string } = {
    [PreferredVendorAssignedStatus.ASSIGNED]: 'Assigned',
    [PreferredVendorAssignedStatus.UNASSIGNED]: 'Not assigned',
    [PreferredVendorAssignedStatus.PARTIAL]: 'Partial',
};

export const prefVenAssgnStatusMapService: {
    [key: string]: PreferredVendorAssignedStatus;
} = {
    ASSIGNED: PreferredVendorAssignedStatus.ASSIGNED,
    UNASSIGNED: PreferredVendorAssignedStatus.UNASSIGNED,
    PARTIAL: PreferredVendorAssignedStatus.PARTIAL,
};

export const preferredVendorStatusMap: { [key: string]: string } = {
    [PreferredVendorStatus.STANDARD]: 'Inactive',
    [PreferredVendorStatus.PREFERRED]: 'Active',
};

export const preferredVendorStatusMapService: {
    [key: string]: PreferredVendorStatus;
} = {
    STANDARD: PreferredVendorStatus.STANDARD,
    PREFERRED: PreferredVendorStatus.PREFERRED,
};

export const itemEntityStatusStatusMap: { [key: string]: string } = {
    [ItemEntityStatus.INACTIVE]: 'Inactive',
    [ItemEntityStatus.ACTIVE]: 'Active',
};

export const itemEntityStatusStatusMapService: {
    [key: string]: ItemEntityStatus;
} = {
    ACTIVE: ItemEntityStatus.ACTIVE,
    INACTIVE: ItemEntityStatus.INACTIVE,
};
