import { Pagination } from '@mui/material';
import { useContext } from 'react';
import { IAPaginationContext } from '../Helpers/IAPaginationHelpers';

export default function IAPagination() {
    const { currentPage, onPageChange, totalPages } =
        useContext(IAPaginationContext);

    return (
        <Pagination
            count={totalPages}
            onChange={(e, newPage) => {
                onPageChange(newPage);

                window.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                });
            }}
            page={currentPage}
        />
    );
}
