import { ThemeProvider } from '@mui/material/styles';
import React, { lazy, Suspense } from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { Slide, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ErrorBoundary } from './Components/Shared/ErrorBoundary';
import LazyFallback from './Components/Shared/LazyFallBack';
import NotFound404 from './Components/Shared/NotFound404';
import { defaultTheme } from './Components/Shared/Theme';
import AuthContextProvider from './Contexts/AuthContext';
import PurchaseOrderReviewPage from './PurchaseOrders/Pages/PurchaseOrderReviewPage';
import CacheBuster from './Utils/CacheBuster';

const LazySignupPage = lazy(
    () => import('./Organizations/Signup/Pages/SignupPage')
);
const LazySignupViaAdminInvitePage = lazy(
    () => import('./Organizations/Signup/Pages/SignupViaAdminInvitePage')
);
const LazySignupViaInvitePage = lazy(
    () => import('./Organizations/Signup/Pages/SignupViaInvitePage')
);
const LazyVerifyEmailPage = lazy(
    () => import('./Organizations/Signup/Pages/VerifyEmailPage')
);

const LazyResetPassword = lazy(
    () => import('./ResetPassword/Pages/ResetPassword')
);

const LazyInvalidResetPasswordUrl = lazy(
    () => import('./ResetPassword/Pages/InvalidUrlPage')
);

const LazyTerms = lazy(() => import('./FWTnC/Pages/FWTnCTerms'));
const LazyPrivacyPolicy = lazy(
    () => import('./FWTnC/Pages/FWTnCPrivacyPolicy')
);

function App() {
    return (
        <React.StrictMode>
            <ThemeProvider theme={defaultTheme}>
                <ErrorBoundary>
                    <Router>
                        <CacheBuster>
                            <Suspense fallback={<LazyFallback />}>
                                <Switch>
                                    <Route
                                        path="/signup"
                                        component={LazySignupPage}
                                        exact
                                    />
                                    <Route
                                        path="/ais/:invitationUid/:invitationCode"
                                        component={LazySignupViaAdminInvitePage}
                                        exact
                                    />
                                    <Route
                                        path="/is/:invitationUid/:invitationCode"
                                        component={LazySignupViaInvitePage}
                                        exact
                                    />
                                    <Route
                                        path="/verify-email/:userUid/:invitationUid/:invitationCode"
                                        component={LazyVerifyEmailPage}
                                        exact
                                    />
                                    <Route
                                        path="/reset-password/:userId/:resetCode/"
                                        component={LazyResetPassword}
                                    />
                                    <Route
                                        path="/reset-password/invalid-url"
                                        component={LazyInvalidResetPasswordUrl}
                                    />
                                    <Route
                                        path="/terms"
                                        component={LazyTerms}
                                    />
                                    <Route
                                        path="/privacy-policy"
                                        component={LazyPrivacyPolicy}
                                    />

                                    {/* Pdf previews from email */}
                                    <Route
                                        path="/po/preview/:poPreviewId"
                                        component={() => (
                                            <PurchaseOrderReviewPage enterpriseType="SELLER" />
                                        )}
                                    />
                                    {/* Pdf previews from email end */}

                                    <Route
                                        path="/"
                                        component={AuthContextProvider}
                                    />
                                    <Route component={NotFound404} />
                                </Switch>
                            </Suspense>
                        </CacheBuster>
                    </Router>
                    <ToastContainer
                        hideProgressBar={true}
                        autoClose={3000}
                        transition={Slide}
                        style={{
                            left: 'calc(50% + (78px / 2))',
                            padding: '0px',
                            top: '8rem',
                        }}
                        closeButton={false}
                        position="top-center"
                    />
                </ErrorBoundary>
            </ThemeProvider>
        </React.StrictMode>
    );
}
export default App;
