import { Box, IconButton } from '@mui/material';
import { ColDef } from 'ag-grid-community';
import moment from 'moment';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { FWMenu, FWMenuItem } from '../../../../Common/FWCustomMenu';
import { FWTypography } from '../../../../Common/FWTypograhy';
import { getFWDate, getFWDateTime } from '../../../../Common/Utils/DateUtils';
import { getBuyerEventDisplayStatus } from '../../../../Events/Constants/Buyer/helperFunctions';
import { IRFQDashboardInfo } from '../../../../Models/RFQ.model';
import {
    DASHBOARD_FIELD,
    DASHBOARD_FIELD_COLUMN,
    DASHBOARD_FIELD_NAME,
    DASHBOARD_FIELD_TYPE,
    DASHBOARD_SECTION_NAME,
    TEMPLATE_FIELD_TYPE,
    TEventsUserList,
} from '../../../../ProjectGlCostCenter/interface/project.model';
import StatusChip from '../../../Shared/StatusChip';
import ItemsLinkAndPopover from './ItemsLinkAndPopover';
import { CustomFieldType } from '../../../../Global/Interfaces/TemplateInterface';
import {
    IFieldTypeLabel,
    PROJECT_DASHBOARD_FIELD_DELIMITER,
} from '../../../../ProjectGlCostCenter/helpers/projectDashboardHelper';
import { cloneDeep } from 'lodash';

export interface IDeleteEventProps {
    entitiesWhereUserHasEventEditPerm: string[];
    initiateEventDeleteProcess: (rfqEventId: string) => void;
}

export const columns = (
    draft_tab: boolean,
    deleteEventProps: IDeleteEventProps,
    columnState: {
        [key: string]: {
            hide: boolean;
        };
    }
): ColDef[] => {
    return [
        {
            field: 'custom_event_id',
            headerName: 'Event ID',
            pinned: 'left',
            lockPinned: true,
            cellClass: 'lock-pinned',
            minWidth: 200,
            refData: {
                [DASHBOARD_FIELD_NAME]: 'event_id',
                [DASHBOARD_FIELD_COLUMN]: 'Event ID',
                [DASHBOARD_SECTION_NAME]: '',
                [DASHBOARD_FIELD]: 'BUILTIN',
                [DASHBOARD_FIELD_TYPE]: 'SHORTTEXT',
                [TEMPLATE_FIELD_TYPE]: 'FW',
            },
            cellRenderer: (params: any) => (
                <FWTypography color="text.secondary" fontSize={16}>
                    {params.data.event.custom_event_id}
                </FWTypography>
            ),
            // pinned: "left",
            // lockPinned: true,
            filter: true,
            sortable: true,
            // checkboxSelection: true,
            // headerCheckboxSelection: true,
            // headerCheckboxSelectionFilteredOnly: true,
            valueGetter: (params: { data: IRFQDashboardInfo }) =>
                params.data.event.custom_event_id
                    ? params.data.event.custom_event_id
                    : 'Not Found',
        } as ColDef,
        {
            field: 'event_status',
            headerName: 'Status',
            width: 165,
            lockPinned: true,
            cellClass: 'lock-pinned',
            refData: {
                [DASHBOARD_FIELD_NAME]: 'event_status',
                [DASHBOARD_FIELD_COLUMN]: 'Status',
                [DASHBOARD_SECTION_NAME]: '',
                [DASHBOARD_FIELD]: 'BUILTIN',
                [DASHBOARD_FIELD_TYPE]: CustomFieldType.CHOICE,
                [TEMPLATE_FIELD_TYPE]: 'FW',
            },
            cellRenderer: (params: any) => (
                <StatusChip status={params.data.event.status} />
            ),
            valueGetter: (params: any) =>
                getBuyerEventDisplayStatus(params.data.event.status),
            filter: true,
            sortable: true,
        },
        {
            field: 'event_name',
            headerName: 'Name',
            flex: 1,
            minWidth: 220,
            lockPinned: true,
            cellClass: 'lock-pinned',
            refData: {
                [DASHBOARD_FIELD_NAME]: 'event_name',
                [DASHBOARD_FIELD_COLUMN]: 'Name',
                [DASHBOARD_SECTION_NAME]: '',
                [DASHBOARD_FIELD]: 'BUILTIN',
                [DASHBOARD_FIELD_TYPE]: 'LONGTEXT',
                [TEMPLATE_FIELD_TYPE]: 'FW',
            },
            cellRenderer: (params: any) => {
                const messages = [];
                if (+params.data.additional_details.vendor_count === 0)
                    messages.push(`Event item(s) have missing vendors`);
                return (
                    <div className="flex expand flex--aic flex--jcsb">
                        <FWTypography fontSize={16} color="text.primary">
                            {params.data.event.event_name}
                        </FWTypography>
                        {/* <FWMultipleTooltipMessages
                        messages={messages}
                        icon={<i className="bi bi-exclamation-circle"></i>}
                        iconColor="warning.main"
                    /> */}
                    </div>
                );
            },
            filterValueGetter: (params: any) => params.data.event.event_name,
            filter: true,
            sortable: true,
        },
        {
            field: 'event_type',
            headerName: 'Type',
            width: 100,
            lockPinned: true,
            cellClass: 'lock-pinned',
            refData: {
                [DASHBOARD_FIELD_NAME]: 'event_type',
                [DASHBOARD_FIELD_COLUMN]: 'Type',
                [DASHBOARD_SECTION_NAME]: '',
                [DASHBOARD_FIELD]: 'BUILTIN',
                [DASHBOARD_FIELD_TYPE]: 'SHORTTEXT',
                [TEMPLATE_FIELD_TYPE]: 'FW',
            },
            cellRenderer: (params: any) => (
                <FWTypography fontSize={16} color="text.primary">
                    {params.data.event.event_type}
                </FWTypography>
            ),
            filterValueGetter: (params: any) => params.data.event.event_type,
            filter: true,
            sortable: true,
        },
        {
            field: draft_tab ? 'created_datetime' : 'live_event_start_datetime',
            headerName: draft_tab ? 'Created' : 'Starts',
            width: 150,
            lockPinned: true,
            cellClass: 'lock-pinned',
            refData: {
                [DASHBOARD_FIELD_NAME]: 'created_at',
                [DASHBOARD_FIELD_COLUMN]: 'Created',
                [DASHBOARD_SECTION_NAME]: '',
                [DASHBOARD_FIELD]: 'BUILTIN',
                [DASHBOARD_FIELD_TYPE]: 'DATETIME',
                [TEMPLATE_FIELD_TYPE]: 'FW',
            },
            cellRenderer: (params: any) => {
                return (
                    <FWTypography fontSize={16} color="text.secondary">
                        {params.value === null
                            ? 'Not yet started'
                            : getFWDate(
                                  draft_tab
                                      ? params.data.event.created_datetime
                                      : params.data.event
                                            .live_event_start_datetime
                              )}
                    </FWTypography>
                );
            },
            filter: 'agDateColumnFilter',
            sortable: true,
            filterParams: {
                debounceMs: 6000,
                maxNumConditions: 1,
                suppressAndOrCondition: true,
            },
            filterValueGetter: (params: any) =>
                params.data.created_datetime === null
                    ? 'Not yet started'
                    : moment(
                          getFWDate(
                              draft_tab
                                  ? params.data.event.created_datetime
                                  : params.data.event.live_event_start_datetime
                          )
                      )
                          .startOf('day')
                          .toDate(),
            getQuickFilterText: (params: any) => getFWDate(params.value),
        },
        {
            field: 'event_end_datetime',
            headerName: 'Ends',
            width: 200,
            lockPinned: true,
            cellClass: 'lock-pinned',
            refData: {
                [DASHBOARD_FIELD_NAME]: 'event_end_datetime',
                [DASHBOARD_FIELD_COLUMN]: 'Ends',
                [DASHBOARD_SECTION_NAME]: '',
                [DASHBOARD_FIELD]: 'BUILTIN',
                [DASHBOARD_FIELD_TYPE]: 'DATETIME',
                [TEMPLATE_FIELD_TYPE]: 'FW',
            },
            cellRenderer: (params: any) => {
                return (
                    <FWTypography fontSize={16} color="text.secondary">
                        {getFWDateTime(params.data.event_end_datetime)}
                    </FWTypography>
                );
            },
            filter: 'agDateColumnFilter',
            sortable: true,
            flex: 10,
            // sort: 'asc',
            filterParams: {
                debounceMs: 6000,
                maxNumConditions: 1,
                suppressAndOrCondition: true,
            },
            filterValueGetter: (params: any) =>
                moment(getFWDateTime(params.data.event_end_datetime))
                    .startOf('day')
                    .toDate(),

            getQuickFilterText: (params: any) => getFWDateTime(params.value),
        },
        {
            field: 'item_count',
            headerName: 'Item(s)',
            width: 110,
            lockPinned: true,
            cellClass: 'lock-pinned',
            headerClass: 'center',
            refData: {
                [DASHBOARD_FIELD_NAME]: 'item_count',
                [DASHBOARD_FIELD_COLUMN]: 'Item(s)',
                [DASHBOARD_SECTION_NAME]: '',
                [DASHBOARD_FIELD]: 'BUILTIN',
                [DASHBOARD_FIELD_TYPE]: 'SHORTTEXT',
                [TEMPLATE_FIELD_TYPE]: 'FW',
            },
            // valueGetter: (params: any) =>
            //     params.data.additional_details.item_count,
            cellRenderer: ItemCountCell,
            sortable: true,
            filter: true,
            hide: columnState['item_count']?.hide ?? false,
            getQuickFilterText: (params: { data: IRFQDashboardInfo }) => {
                return getItemSearchString(
                    params.data.event_items.map((item) => {
                        return {
                            buyer_item_id: item.item_information.buyer_item_id,
                            buyer_item_name:
                                item.item_information.buyer_item_name,
                            custom_item_name:
                                item.item_information.custom_item_name,
                        };
                    })
                );
            },
            filterValueGetter: (params: any) =>
                +params.data.additional_details?.item_count ?? 0,
            comparator: (valueA: any, valueB: any, nodeA: any, nodeB: any) => {
                return (
                    nodeA.data.additional_details.item_count -
                    nodeB.data.additional_details.item_count
                );
            },
        },
        {
            field: 'project',
            headerName: 'Project',
            flex: 1,
            lockPinned: true,
            cellClass: 'lock-pinned',
            minWidth: 120,
            refData: {
                [DASHBOARD_FIELD_NAME]: 'project_name',
                [DASHBOARD_FIELD_COLUMN]: 'Project',
                [DASHBOARD_SECTION_NAME]: '',
                [DASHBOARD_FIELD]: 'BUILTIN',
                [DASHBOARD_FIELD_TYPE]: CustomFieldType.CHOICE,
                [TEMPLATE_FIELD_TYPE]: 'FW',
            },
            valueGetter: (params: any) => params.data.buyer_entity_name,
            cellRenderer: (params: { data: IRFQDashboardInfo }) => (
                <FWTypography fontSize={16} color="text.primary">
                    {params.data.project === null
                        ? '-'
                        : params.data.project?.project_name}
                </FWTypography>
            ),
            filterValueGetter: (params: any) =>
                params.data.project?.project_name
                    ? params.data.project?.project_name
                    : 'Not found',
            filter: true,
            sortable: false,
            hide: columnState['project']?.hide ?? false,
        },
        {
            field: 'entity',
            headerName: 'Entity',
            flex: 1,
            lockPinned: true,
            cellClass: 'lock-pinned',
            minWidth: 120,
            refData: {
                [DASHBOARD_FIELD_NAME]: 'buyer_entity_name',
                [DASHBOARD_FIELD_COLUMN]: 'Entity',
                [DASHBOARD_SECTION_NAME]: '',
                [DASHBOARD_FIELD]: 'BUILTIN',
                [DASHBOARD_FIELD_TYPE]: CustomFieldType.CHOICE,
                [TEMPLATE_FIELD_TYPE]: 'FW',
            },
            valueGetter: (params: any) => params.data.buyer_entity_name,
            cellRenderer: (params: any) => (
                <FWTypography fontSize={16} color="text.primary">
                    {params.data.buyer_entity_name}
                </FWTypography>
            ),
            filter: true,
            sortable: true,
            hide: columnState['entity']?.hide ?? false,
        },

        {
            field: 'original_event_creator_name',
            headerName: 'Creator',
            flex: 1,
            lockPinned: true,
            cellClass: 'lock-pinned',
            minWidth: 130,
            refData: {
                [DASHBOARD_FIELD_NAME]: 'created_by',
                [DASHBOARD_FIELD_COLUMN]: 'Creator',
                [DASHBOARD_SECTION_NAME]: '',
                [DASHBOARD_FIELD]: 'BUILTIN',
                [DASHBOARD_FIELD_TYPE]: CustomFieldType.CHOICE,
                [TEMPLATE_FIELD_TYPE]: 'FW',
            },
            cellRenderer: (params: any) => (
                <FWTypography fontSize={16} color="text.primary">
                    {params.data.original_event_creator_name}
                </FWTypography>
            ),
            filter: true,
            sortable: true,
            hide: columnState['original_event_creator_name']?.hide,
        },
        {
            field: 'customer_entity',
            headerName: 'Customer name',
            lockPinned: true,
            cellClass: 'lock-pinned',
            flex: 1,
            minWidth: 120,
            refData: {
                [DASHBOARD_FIELD_NAME]: 'customer_entity',
                [DASHBOARD_FIELD_COLUMN]: 'Customer name',
                [DASHBOARD_SECTION_NAME]: '',
                [DASHBOARD_FIELD]: 'BUILTIN',
                [DASHBOARD_FIELD_TYPE]: CustomFieldType.CHOICE,
                [TEMPLATE_FIELD_TYPE]: 'FW',
            },
            cellRenderer: (params: { data: IRFQDashboardInfo }) => (
                <FWTypography fontSize={16} color="text.primary">
                    {params.data.customer_entity === null
                        ? '-'
                        : params.data.customer_entity?.entity_name}
                </FWTypography>
            ),
            filter: true,
            sortable: false,
            hide: columnState['customer_entity']?.hide,
        },
        {
            field: 'options',
            headerName: 'Options',
            lockPinned: true,
            cellClass: 'lock-pinned',
            pinned: 'right',
            flex: 1,
            minWidth: 120,
            headerClass: 'center',
            cellRenderer: (params: any) => (
                <div style={{ margin: '0 auto' }}>
                    <EventMenu
                        rfqEventId={params.data.rfq_entry_id}
                        entityId={params.data.buyer_entity}
                        eventStatus={params.data.event.status}
                        deleteEventProps={deleteEventProps}
                    />
                </div>
            ),
            sortable: false,
            filter: false,
        },
    ];
};
// <SwapVertRoundedIcon />
export const statusDisplayNameMap: Record<string, string> = {
    Draft: 'DRAFT',
    'Approval Pending': 'Approval Pending',
    'Changes Requested': 'Changes Requested',
    Negotiations: 'Negotiations',
    'Award Stage': 'Award Stage',
    Rejected: 'REJECTED',
    'PO(s) Issued': 'PO ISSUED / POS ISSUED',
    'Po(s) Accepted': 'PO ACCEPTED / POS ACCEPTED',
    Paused: 'Paused',
    Assigned: 'ASSIGNED',
};

export const getStatusChoices = (currentTab: string): string[] => {
    switch (currentTab) {
        case 'drafts':
            return ['Draft', 'Approval Pending', 'Changes Requested'];
        case 'ongoing':
            return ['Negotiations', 'Award Stage', 'PO(s) Issued', 'Paused'];
        case 'finished':
            return ['Rejected', 'Po(s) Accepted'];
        case 'all':
        default:
            return [
                'Draft',
                'Approval Pending',
                'Changes Requested',
                'Negotiations',
                'Award Stage',
                'PO(s) Issued',
                'Paused',
                'Po(s) Accepted',
                'Rejected',
            ];
    }
};

export const eventsFieldTypeLabel = (currentTab: string): IFieldTypeLabel[] => [
    {
        label: 'Event ID',
        column: 'event_id',
        fieldLabelWithSectionName: 'Event ID',
        isFieldFWFieldInCustomSection: false,
        fieldType: 'SHORTTEXT',
        is_built_in: true,
        sectionName: null,
        sectionAlternameName: null,
        choices: [],
    },
    {
        label: 'Status',
        column: 'event_status',
        fieldLabelWithSectionName: 'Status',
        isFieldFWFieldInCustomSection: false,
        fieldType: CustomFieldType.CHOICE,
        is_built_in: true,
        sectionName: null,
        sectionAlternameName: null,
        choices: getStatusChoices(currentTab),
    },
    {
        label: 'Name',
        column: 'event_name',
        fieldLabelWithSectionName: 'Name',
        isFieldFWFieldInCustomSection: false,
        fieldType: 'LONGTEXT',
        is_built_in: true,
        sectionName: null,
        sectionAlternameName: null,
        choices: [],
    },
    {
        label: 'Type',
        column: 'event_type',
        fieldLabelWithSectionName: 'Type',
        isFieldFWFieldInCustomSection: false,
        fieldType: 'SHORTTEXT',
        is_built_in: true,
        sectionName: null,
        sectionAlternameName: null,
        choices: [],
    },
    {
        label: 'Created',
        column: 'created_at',
        fieldLabelWithSectionName: 'Created',
        isFieldFWFieldInCustomSection: false,
        fieldType: 'DATETIME',
        is_built_in: true,
        sectionName: null,
        sectionAlternameName: null,
        choices: [],
    },
    {
        label: 'Ends',
        column: 'event_end_datetime',
        fieldLabelWithSectionName: 'Ends',
        isFieldFWFieldInCustomSection: false,
        fieldType: 'DATETIME',
        is_built_in: true,
        sectionName: null,
        sectionAlternameName: null,
        choices: [],
    },
    {
        label: 'Item(s)',
        column: 'item_count',
        fieldLabelWithSectionName: 'Item(s)',
        isFieldFWFieldInCustomSection: false,
        fieldType: 'SHORTTEXT',
        is_built_in: true,
        sectionName: null,
        sectionAlternameName: null,
        choices: [],
    },
    {
        label: 'Project',
        column: 'Project',
        fieldLabelWithSectionName: 'Project',
        isFieldFWFieldInCustomSection: false,
        fieldType: CustomFieldType.CHOICE,
        is_built_in: true,
        sectionName: null,
        sectionAlternameName: null,
        choices: [],
    },
    {
        label: 'Entity',
        column: 'Entity',
        fieldLabelWithSectionName: 'Entity',
        isFieldFWFieldInCustomSection: false,
        fieldType: CustomFieldType.CHOICE,
        is_built_in: true,
        sectionName: null,
        sectionAlternameName: null,
        choices: [],
    },
    {
        label: 'Creator',
        column: 'ACreator',
        fieldLabelWithSectionName: 'Creator',
        isFieldFWFieldInCustomSection: false,
        fieldType: CustomFieldType.CHOICE,
        is_built_in: true,
        sectionName: null,
        sectionAlternameName: null,
        choices: [],
    },
    {
        label: 'Customer name',
        column: 'Customer name',
        fieldLabelWithSectionName: 'Customer name',
        isFieldFWFieldInCustomSection: false,
        fieldType: CustomFieldType.CHOICE,
        is_built_in: true,
        sectionName: null,
        sectionAlternameName: null,
        choices: [],
    },
];
export const getEventsDashboardFields = (
    listOffAllEventUSer: TEventsUserList,
    // eventsFieldTypeLabel: IFieldTypeLabel[]
    currentTab: string
) => {
    const fieldTypeLabels = [...eventsFieldTypeLabel(currentTab)];
    const fieldTypeLabelWithOption = getEventTypeLabelsWithOptions(
        listOffAllEventUSer,
        fieldTypeLabels
    );

    return {
        eventsFieldTypeLabel: fieldTypeLabelWithOption,
    };
};

const getEventTypeLabelsWithOptions = (
    listOffAllEventUSer: TEventsUserList,
    fieldTypeLabels: IFieldTypeLabel[]
) => {
    const fieldTypeLabelsWithOptions = fieldTypeLabels.map((option) => {
        if (option.column === 'Project') {
            let updatedOption = cloneDeep(option);
            updatedOption.choices = listOffAllEventUSer.projects
                .filter(
                    (project) =>
                        Boolean(project.project_name) &&
                        Boolean(project.project_id)
                )
                .map(
                    (manager) =>
                        `${manager.project_code} (${manager.project_name})${PROJECT_DASHBOARD_FIELD_DELIMITER}${manager.project_id}`
                );

            return updatedOption;
        } else if (option.column === 'Entity') {
            let updatedOption = cloneDeep(option);
            updatedOption.choices = listOffAllEventUSer.buyer_entities
                .filter(
                    (entity) =>
                        Boolean(entity.buyer_entity_name) &&
                        Boolean(entity.buyer_entity_name)
                )
                .map(
                    (entity) =>
                        `${entity.buyer_entity_name}${PROJECT_DASHBOARD_FIELD_DELIMITER}${entity.buyer_entity_name}`
                );
            return updatedOption;
        } else if (option.column === 'ACreator') {
            let updatedOption = cloneDeep(option);
            updatedOption.choices = listOffAllEventUSer.created_by_users
                .filter(
                    (creator) =>
                        Boolean(creator.name) && Boolean(creator.user_id)
                )
                .map(
                    (creator) =>
                        `${creator.name}${PROJECT_DASHBOARD_FIELD_DELIMITER}${creator.user_id}`
                );
            return updatedOption;
        } else if (option.column === 'Customer name') {
            let updatedOption = cloneDeep(option);
            updatedOption.choices = listOffAllEventUSer.customers
                .filter(
                    (customers) =>
                        Boolean(customers.customer_entity_name) &&
                        Boolean(customers.customer_entity_id)
                )
                .map(
                    (customers) =>
                        `${customers.customer_entity_name}${PROJECT_DASHBOARD_FIELD_DELIMITER}${customers.customer_entity_id}`
                );
            return updatedOption;
        }
        return option;
    });

    return fieldTypeLabelsWithOptions;
};

const ItemCountCell = ({ data }: { data: IRFQDashboardInfo }) => {
    const [openPopup, setOpenPopup] = React.useState<boolean>(false);
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

    return (
        <>
            <Box
                ref={(ref: any) => {
                    if (!ref) return;
                    ref.onclick = (e: any) => {
                        if (data.event_items.length > 0) {
                            e.stopPropagation();
                            setAnchorEl(e.target);
                            setOpenPopup(true);
                        }
                    };
                }}
                sx={{
                    width: '100%',
                }}
            >
                <FWTypography
                    sx={{ cursor: 'pointer' }}
                    fontSize={16}
                    color={data.event_items.length > 0 ? 'primary.main' : ''}
                    onClick={() => {
                        setOpenPopup(true);
                    }}
                >
                    {data.additional_details.item_count}
                </FWTypography>
            </Box>
            <ItemsLinkAndPopover
                event_items={data.event_items.map((item) => {
                    return {
                        buyer_item_id: item.item_information.buyer_item_id,
                        buyer_item_name: item.item_information.buyer_item_name,
                        custom_item_name:
                            item.item_information.custom_item_name,
                    };
                })}
                anchorEl={anchorEl}
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
                item_count={data.additional_details.item_count}
            />
        </>
    );
};

const getItemSearchString = (
    event_items: {
        buyer_item_id: string;
        buyer_item_name: string;
        custom_item_name: string;
    }[]
) => {
    let searchString = '';
    event_items.forEach((item) => {
        searchString += `${item.buyer_item_id} ${item.buyer_item_name} ${item.custom_item_name}`;
    });
    return searchString;
};

export default function EventMenu({
    rfqEventId,
    entityId,
    eventStatus,
    deleteEventProps,
}: {
    rfqEventId: string;
    entityId: string;
    eventStatus: string;
    deleteEventProps: IDeleteEventProps;
}) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) =>
        setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    const history = useHistory();
    const showSummary = ![
        'DRAFT',
        'APPROVAL_PENDING',
        'REJECTED',
        'REWORK',
    ].includes(eventStatus);

    const showNegotiations = ![
        'DRAFT',
        'APPROVAL_PENDING',
        'REJECTED',
        'REWORK',
    ].includes(eventStatus);
    const showPoAllocations = ![
        'DRAFT',
        'APPROVAL_PENDING',
        'REJECTED',
        'ONGOING',
        'NEGOTIATIONS',
        'PAUSED',
        'REWORK',
    ].includes(eventStatus);
    const showPoStrategy = ![
        'DRAFT',
        'APPROVAL_PENDING',
        'REJECTED',
        'ONGOING',
        'NEGOTIATIONS',
        'PAUSED',
        'REWORK',
    ].includes(eventStatus);

    const showDelete =
        deleteEventProps.entitiesWhereUserHasEventEditPerm.includes(entityId) &&
        ['DRAFT', 'REWORK'].includes(eventStatus);

    const enableMenu =
        showNegotiations || showPoAllocations || showPoStrategy || showDelete;

    return (
        <div>
            <IconButton
                id="table-event-menu-button"
                aria-controls={open ? 'event-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                color="primary"
                disabled={!enableMenu}
                ref={(ref: any) => {
                    if (!ref) return;
                    ref.onclick = (e: any) => {
                        e.stopImmediatePropagation();
                        handleClick(e);
                    };
                }}
            >
                <i className="bi bi-three-dots"></i>
            </IconButton>
            <FWMenu
                id="event-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'table-event-menu-button',
                }}
            >
                {showSummary && (
                    <FWMenuItem
                        onClick={() => {
                            history.push(`/buyer/events/${rfqEventId}/summary`);
                        }}
                        sx={{ color: 'primary.main' }}
                    >
                        Event summary
                    </FWMenuItem>
                )}

                {showNegotiations && (
                    <FWMenuItem
                        onClick={() => {
                            history.push(
                                `/buyer/events/live/${rfqEventId}/quotes`
                            );
                        }}
                        sx={{ color: 'primary.main' }}
                    >
                        Negotiations
                    </FWMenuItem>
                )}
                {showPoAllocations && (
                    <FWMenuItem
                        onClick={() => {
                            history.push(`/buyer/events/${rfqEventId}/awards`);
                        }}
                        sx={{ color: 'primary.main' }}
                    >
                        PO Allocations
                    </FWMenuItem>
                )}
                {showPoStrategy && (
                    <FWMenuItem
                        onClick={() => {
                            history.push(
                                `/buyer/events/${rfqEventId}/purchase_order_strategy`
                            );
                        }}
                        sx={{ color: 'primary.main' }}
                    >
                        PO Strategy
                    </FWMenuItem>
                )}
                {showDelete && (
                    <FWMenuItem
                        onClick={() => {
                            handleClose();
                            deleteEventProps.initiateEventDeleteProcess(
                                rfqEventId
                            );
                        }}
                        sx={{ color: 'error.main' }}
                    >
                        Delete event
                    </FWMenuItem>
                )}
            </FWMenu>
        </div>
    );
}
