import { Menu, MenuItem, MenuItemProps, MenuProps } from '@mui/material';
import { styled } from '@mui/material/styles';

const CustomMenu = styled(Menu)((theme) => ({
    '& .MuiMenu-paper': {
        backgroundColor: 'white',
        borderRadius: '12px',
        boxShadow: '0px 5px 16px #00000024',
    },
    '& .MuiList-root': {
        // padding: '0px',
    },
}));

export const FWMenu = (props: MenuProps) => (
    <CustomMenu
        disableScrollLock
        sx={{ borderColor: 'dividerColor.main' }}
        {...props}
    />
);

const CustomMenuItem = styled(MenuItem)({
    '& .MuiMenuItem-root': {
        padding: '16px 8px',
    },
});

export const FWMenuItem = (props: MenuItemProps) => (
    <CustomMenuItem {...props} />
);
