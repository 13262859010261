export const scrollWindow = (section: HTMLElement, offset: number = 74) => {
    setTimeout(() => {
        window.scrollTo({
            top: section.offsetTop - offset,
            behavior: 'smooth',
        });
    }, 300);
};

export const customScrollbar = {
    '&::-webkit-scrollbar': {
        width: '7px !important',
    },
    '&::-webkit-scrollbar-thumb': {
        borderRadius: '10px !important',
        visibility: 'hidden !important',
        minHeight: '5rem !important',
    },
    '&:hover::-webkit-scrollbar-thumb': {
        visibility: 'visible !important',
    },
};
