import { createSlice } from '@reduxjs/toolkit';

export type eventLevelAccessType = 'NONE' | 'VIEW' | 'EDIT';

/*
    A person with FULL access can 
    1) approve draft event 
    2) hotfix quantities 
    3) approve LEM revisions
    
    Who has full access? A person who meets all the following conditions
    1) Has entity level approval rights
    2) had event level EDIT access
    3) Is in the creator's hierarchy    
*/

export interface IEventAccessStore {
    subEventId: string;
    eventLevelAccess: eventLevelAccessType;
    userIsInCreatorHierarchy: boolean;
    hasFullAccess: boolean;
}

interface ILoadEventAccessSlice {
    type: string;
    payload: {
        subEventId: string;
        eventLevelAccess: eventLevelAccessType;
        userIsInCreatorHierarchy: boolean;
        hasFullAccess: boolean;
    };
}

const resetEventAccess = (state: IEventAccessStore) => {
    state.subEventId = '';
    state.eventLevelAccess = 'NONE';
    state.userIsInCreatorHierarchy = false;
    state.hasFullAccess = false;
};

const loadEventAccess = (
    state: IEventAccessStore,
    action: ILoadEventAccessSlice
) => {
    return action.payload;
};

export const eventAccessSlice = createSlice<
    IEventAccessStore,
    {
        resetEventAccessSlice: (state: IEventAccessStore) => void;
        loadEventAccessSlice: (
            state: IEventAccessStore,
            action: ILoadEventAccessSlice
        ) => void;
    },
    string
>({
    name: 'eventAccess',
    initialState: {
        subEventId: '',
        eventLevelAccess: 'NONE',
        userIsInCreatorHierarchy: false,
        hasFullAccess: false,
    },
    reducers: {
        resetEventAccessSlice: resetEventAccess,
        loadEventAccessSlice: loadEventAccess,
    },
});

export const { resetEventAccessSlice, loadEventAccessSlice } =
    eventAccessSlice.actions;

const EventAccessStore = eventAccessSlice.reducer;
export default EventAccessStore;
