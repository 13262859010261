import { useCallback, useEffect, useState } from 'react';
import {
    HookStateValue,
    IHookState,
    useHookState,
} from '../../Common/Hooks/StateHook';
import { IPOPrepaymentInvoiceSummary } from '../Interfaces/PaymentsInterface';
import {
    fetchPOPrepaymentInvoices,
    fetchPrepaymentInvoiceDetails,
} from '../Services/PaymentsService';

export interface IPOPrepaymentInvoicesProvider {
    hookState: IHookState;
    prepaymentInvoicesList: IPOPrepaymentInvoiceSummary[];
}

export const usePrepaymentInvoices = (
    poUid: string,
    user_type: 'BUYER' | 'SELLER'
) => {
    const { hookState, updateHookState } = useHookState(HookStateValue.LOADING);

    const [prepaymentInvoicesList, setPrepaymentInvoicesList] = useState<
        IPOPrepaymentInvoiceSummary[]
    >([]);

    const getPrepaymentInvoices = useCallback(async () => {
        updateHookState(HookStateValue.LOADING);
        try {
            const prepaymentInvoicesList = await fetchPOPrepaymentInvoices(
                poUid,
                user_type
            );
            const prepaymentInvoicesListWithDetails: IPOPrepaymentInvoiceSummary[] =
                await Promise.all(
                    prepaymentInvoicesList.map(async (prepaymentInvoice) => {
                        //
                        const res: IPOPrepaymentInvoiceSummary =
                            await fetchPrepaymentInvoiceDetails(
                                prepaymentInvoice.invoiceUid,
                                user_type
                            );
                        return res;
                    })
                );
            //
            setPrepaymentInvoicesList(prepaymentInvoicesListWithDetails);
            updateHookState(HookStateValue.READY);
        } catch (error) {
            updateHookState(HookStateValue.ERROR);
        }
    }, [poUid, updateHookState, user_type]);

    useEffect(() => {
        getPrepaymentInvoices();
    }, [getPrepaymentInvoices]);

    const prepaymentInvoicesProviders: IPOPrepaymentInvoicesProvider = {
        hookState,
        prepaymentInvoicesList,
    };

    return prepaymentInvoicesProviders;
};

export interface IPrepaymentInvoiceDetailProvider {
    hookState: IHookState;
    prepaymentInvoiceDetails: IPOPrepaymentInvoiceSummary;
}

const initialInvoiceDetails: IPOPrepaymentInvoiceSummary = {
    invoiceId: '',
    invoiceUid: '',
    itemsList: [],
    itemCount: 0,
    totalAmount: 0,
    currency: '',
};

export const usePrepaymentInvoiceDetail = (
    invoiceUid: string,
    user_type: 'BUYER' | 'SELLER'
) => {
    const { hookState, updateHookState } = useHookState(HookStateValue.LOADING);

    const [prepaymentInvoiceDetails, setPrepaymentInvoiceDetails] =
        useState<IPOPrepaymentInvoiceSummary>({ ...initialInvoiceDetails });

    const getPrepaymentInvoiceDetails = useCallback(async () => {
        updateHookState(HookStateValue.LOADING);
        try {
            const prepaymentInvoiceDetail = await fetchPrepaymentInvoiceDetails(
                invoiceUid,
                user_type
            );
            setPrepaymentInvoiceDetails(prepaymentInvoiceDetail);
            updateHookState(HookStateValue.READY);
        } catch (error) {
            updateHookState(HookStateValue.ERROR);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [invoiceUid]);

    useEffect(() => {
        getPrepaymentInvoiceDetails();
    }, [getPrepaymentInvoiceDetails]);

    const prepaymentInvoicesProviders: IPrepaymentInvoiceDetailProvider = {
        hookState,
        prepaymentInvoiceDetails,
    };

    return prepaymentInvoicesProviders;
};
