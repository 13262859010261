import { FWTooltip } from '../../Common/FWCustomTooltip';
import FWIcon from '../../Common/FWIcon';

const DeadlinePassed = () => {
    return (
        <FWTooltip title="Event deadline passed">
            <span>
                <FWIcon
                    name="bi bi-exclamation-circle"
                    className="ml--5"
                    color="error.main"
                />
            </span>
        </FWTooltip>
    );
};

export default DeadlinePassed;
