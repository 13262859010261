import { isEqual } from 'lodash';
import { useCallback, useState } from 'react';
import {
    AllocationTypeEnum,
    CostSourceEnum,
    CostTypeEnum,
} from '../../../../AdditionalCost/models/AdditionalCost.model';
import {
    HookStateValue,
    useHookState,
} from '../../../../Common/Hooks/StateHook';
import { ItemPageType } from '../../Containers/ItemDirectory/ItemAddEditContainer';
import {
    IItemAdditionalCostField,
    IItemCustomField,
    IItemField,
    convertTemplateDetailsToItemAdditionalCosts,
    convertTemplateDetailsToItemCustomFieldsForItem2,
    convertTemplateDetailsToItemFields,
    fetchPreselectedAdditionalCostFromApi,
} from '../../Helpers/ItemHelpers';
import {
    IItemCustomFieldsBackend,
    IItemDetails,
} from '../../Interfaces/ItemDirectoryInterface';
import {
    IITemTemplateFieldResponse,
    getItemTemplateFields,
} from '../../Services/ItemDirectoryService';
import { ItemReducerAction, ItemUpdateActions } from './ItemDirectoryHook';

export interface IINewItemTemplate {
    customFields: IItemCustomFieldsBackend;
    additionalCosts: {
        name: string;
        fields: IItemAdditionalCostField[];
        isHidden: boolean;
    }[];
    itemFields: {
        [key: string]: IItemCustomField;
    };
}

export interface IPreSelectedAdditionalCostFromApi {
    additional_cost: {
        additional_cost_id: string | null;
        cost_name: string;
        cost_type: CostTypeEnum;
        allocation_type: AllocationTypeEnum | null;
        cost_value: number;
    };
    cost_source: CostSourceEnum | null;
    cost_value: number;
}

export interface IItemAdditionalCostBackend {
    cost_name: string;
    cost_type: CostTypeEnum;
    cost_source: CostSourceEnum | null;
    allocation_type: AllocationTypeEnum | null;
    cost_value: number;
    additional_cost_id: string | null; // mandatory for additional cost
}

export const useItemAdditionalCost = (
    type: ItemPageType,
    itemId: string,
    handleChange: (action: ItemReducerAction) => void,
    data: IItemDetails
) => {
    const [additionalCostsToDisplay, setAdditionalCostToDisplay] = useState<
        string[]
    >([]);

    const [preSelectedAdditionalCosts, setPreSelectedAdditionalCosts] =
        useState<IItemAdditionalCostBackend[]>([]);

    const [itemTemplateSectionList, setItemTemplateSectionList] = useState<
        IINewItemTemplate | undefined
    >(undefined);

    const [buyerAdditionalCost, setBuyerAdditionalCost] = useState<
        IItemAdditionalCostBackend[]
    >([]);
    const [sellerAdditionalCost, setSellerAdditionalCost] = useState<
        IItemAdditionalCostBackend[]
    >([]);

    const { hookState, updateHookState } = useHookState(HookStateValue.LOADING);

    const getTemplateFields = useCallback(() => {
        getItemTemplateFields().then((res) => {
            filterItemSectionList(res.templateFields)
                .then(() => {
                    updateHookState(HookStateValue.READY);
                })
                .then(() => {
                    getPreselectedAdditionalCost(itemId);
                });
            handleChange({
                type: ItemUpdateActions.TEMPLATE_ID,
                value: res.template_id,
            });
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const formattedAdditionalCost = (
        additionalCosts: {
            name: string;
            fields: IItemAdditionalCostField[];
            isHidden: boolean;
        }[]
    ) => {
        let additionalCostForHandleChange: IItemAdditionalCostBackend[] =
            additionalCosts[0].fields.map((cost) => {
                let object: IItemAdditionalCostBackend = {
                    allocation_type: cost.additional_cost_information
                        .allocation_type as AllocationTypeEnum,
                    cost_source: cost.additional_cost_information.cost_source,
                    cost_name: cost.name,
                    cost_type: cost.additional_cost_information
                        .cost_type as CostTypeEnum,
                    cost_value: cost.value as any,
                    additional_cost_id:
                        cost.additional_cost_information.additional_cost_id ??
                        '',
                };
                return object;
            });

        return additionalCostForHandleChange;
    };

    const filterItemSectionList = async (
        itemTemplateField: IITemTemplateFieldResponse
    ) => {
        let additionalCostsSelectedValues:
            | {
                  buyerAdditionalCosts: IItemAdditionalCostBackend[];
                  sellerAdditionalCosts: IItemAdditionalCostBackend[];
              }
            | undefined = await fetchPreselectedAdditionalCostFromApi(itemId);

        let { customFields } =
            convertTemplateDetailsToItemCustomFieldsForItem2(itemTemplateField);

        if (
            isEqual(data.custom_fields, {}) ||
            data.custom_fields?.section_list?.length !== 0 ||
            data.custom_fields.section_list[0]?.fields?.length !== 0
        ) {
            handleChange({
                type: ItemUpdateActions.CUSTOM_FIELDS,
                value: customFields,
            });
        }

        let additionalCosts =
            convertTemplateDetailsToItemAdditionalCosts(itemTemplateField);

        let additionalCostForHandleChange =
            formattedAdditionalCost(additionalCosts);

        additionalCostForHandleChange.forEach((cost) => {
            if (
                additionalCostsSelectedValues?.buyerAdditionalCosts.find(
                    (c) => c.additional_cost_id === cost.additional_cost_id
                ) &&
                additionalCostsSelectedValues?.buyerAdditionalCosts.find(
                    (c) => c.additional_cost_id === cost.additional_cost_id
                )?.cost_value !== null
            ) {
                cost.cost_value = +(
                    additionalCostsSelectedValues?.buyerAdditionalCosts.find(
                        (c) => c.additional_cost_id === cost.additional_cost_id
                    )?.cost_value ?? ''
                );
            }
            if (
                additionalCostsSelectedValues?.sellerAdditionalCosts.find(
                    (c) => c.additional_cost_id === cost.additional_cost_id
                ) &&
                additionalCostsSelectedValues?.sellerAdditionalCosts.find(
                    (c) => c.additional_cost_id === cost.additional_cost_id
                )?.cost_value !== null
            ) {
                cost.cost_value = +(
                    additionalCostsSelectedValues?.sellerAdditionalCosts.find(
                        (c) => c.additional_cost_id === cost.additional_cost_id
                    )?.cost_value ?? ''
                );
            }
        });

        let itemFields = convertTemplateDetailsToItemFields(itemTemplateField);

        setItemTemplateSectionList({
            customFields,
            additionalCosts,
            itemFields,
        });
    };

    const getPreselectedAdditionalCostNames = (
        additionalCost:
            | {
                  buyerAdditionalCosts: IItemAdditionalCostBackend[];
                  sellerAdditionalCosts: IItemAdditionalCostBackend[];
              }
            | undefined
    ) => {
        let buyerAdditionalCostNames =
            additionalCost?.buyerAdditionalCosts
                ?.filter((cost) => cost.cost_value !== null)
                ?.map((cost) => cost.cost_name) ?? [];

        let sellerAdditionalCostNames =
            additionalCost?.sellerAdditionalCosts
                ?.filter((cost) => cost.cost_value !== null)
                ?.map((cost) => cost.cost_name) ?? [];

        return Array.from(
            new Set([...buyerAdditionalCostNames, ...sellerAdditionalCostNames])
        );
    };

    const getUniquelyMergedAdditionalCost = (
        additionalCost:
            | {
                  buyerAdditionalCosts: IItemAdditionalCostBackend[];
                  sellerAdditionalCosts: IItemAdditionalCostBackend[];
              }
            | undefined
    ) => {
        let uniqueAdditionalCost: IItemAdditionalCostBackend[] = [];

        additionalCost?.buyerAdditionalCosts.forEach((cost) => {
            if (
                !uniqueAdditionalCost.find(
                    (uniqueCost) => uniqueCost.cost_name === cost.cost_name
                )
            ) {
                uniqueAdditionalCost.push(cost);
            }
        });

        additionalCost?.sellerAdditionalCosts.forEach((cost) => {
            if (
                !uniqueAdditionalCost.find(
                    (uniqueCost) => uniqueCost.cost_name === cost.cost_name
                )
            ) {
                uniqueAdditionalCost.push(cost);
            }
        });

        return uniqueAdditionalCost;
    };

    const getPreselectedAdditionalCost = useCallback(
        async (itemId) => {
            if (type === ItemPageType.EDIT && itemId !== '') {
                let additionalCosts:
                    | {
                          buyerAdditionalCosts: IItemAdditionalCostBackend[];
                          sellerAdditionalCosts: IItemAdditionalCostBackend[];
                          custom_fields: IItemCustomFieldsBackend;
                      }
                    | undefined = await fetchPreselectedAdditionalCostFromApi(
                    itemId
                );

                if (
                    !isEqual(additionalCosts?.custom_fields, {}) &&
                    additionalCosts?.custom_fields &&
                    additionalCosts?.custom_fields?.section_list?.length !== 0
                ) {
                    handleChange({
                        type: ItemUpdateActions.CUSTOM_FIELDS,
                        value: additionalCosts?.custom_fields,
                    });
                }

                setBuyerAdditionalCost(
                    additionalCosts?.buyerAdditionalCosts ?? []
                );
                setSellerAdditionalCost(
                    additionalCosts?.sellerAdditionalCosts ?? []
                );

                let additionalCostNames =
                    getPreselectedAdditionalCostNames(additionalCosts);

                // setPreSelectedAdditionalCosts();

                let uniqueAdditionalCost =
                    getUniquelyMergedAdditionalCost(additionalCosts);

                // if (itemTemplateSectionList?.additionalCosts[0].fields) {
                //     let mappedAdditionalCost: IItemAdditionalCostBackend[] =
                //         itemTemplateSectionList?.additionalCosts[0].fields.map(
                //             (cost) => {
                //                 let obj: IItemAdditionalCostBackend = {
                //                     allocation_type:
                //                         (cost.additional_cost_information
                //                             .allocation_type as AllocationTypeEnum) ??
                //                         null,
                //                     cost_source:
                //                         cost.additional_cost_information
                //                             .cost_source,
                //                     cost_name: cost.name,
                //                     cost_type:
                //                         (cost.additional_cost_information
                //                             .cost_type as CostTypeEnum) ?? '',
                //                     cost_value: +(cost.value?.toString() ?? ''),
                //                     additional_cost_id:
                //                         cost.additional_cost_information
                //                             .additional_cost_id,
                //                 };
                //                 return obj;
                //             }
                //         );

                //
                // }

                setPreSelectedAdditionalCosts((prev) => [
                    ...uniqueAdditionalCost,
                ]);

                setAdditionalCostToDisplay((prev) => [...additionalCostNames]);

                return { additionalCosts, additionalCostNames };
            }
        },

        [handleChange, type]
    );

    return {
        buyerAdditionalCost,
        sellerAdditionalCost,
        additionalCostsToDisplay,
        preSelectedAdditionalCosts,
        itemTemplateSectionList,
        getTemplateFields,
        getPreselectedAdditionalCost,
        setPreSelectedAdditionalCosts,
        setAdditionalCostToDisplay,
        setItemTemplateSectionList,
        hookState,
    };
};

export const useNewItemAdditionalCost = (
    data: IItemDetails,
    handleChange: (action: ItemReducerAction) => void,
    type: ItemPageType
) => {
    const { hookState: newItemhookState, updateHookState } = useHookState(
        HookStateValue.LOADING
    );

    const [newItemTemplateSectionList, setNewItemTemplateSectionList] =
        useState<IINewItemTemplate | undefined>(undefined);

    const [customFieldSetting, setCustomFieldSetting] = useState<{
        [field: string]: IItemField;
    }>({});

    const newGetTemplateFields = useCallback(() => {
        getItemTemplateFields().then((res) => {
            filterItemSectionList(res.templateFields).then(() => {
                updateHookState(HookStateValue.READY);
            });
            handleChange({
                type: ItemUpdateActions.TEMPLATE_ID,
                value: res.template_id,
            });
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const filterItemSectionList = async (
        itemTemplateField: IITemTemplateFieldResponse
    ) => {
        let { customFields, customFieldsSettings } =
            convertTemplateDetailsToItemCustomFieldsForItem2(itemTemplateField);

        if (type === ItemPageType.ADD) {
            handleChange({
                type: ItemUpdateActions.CUSTOM_FIELDS,
                value: customFields,
            });
        }

        setCustomFieldSetting(customFieldsSettings);

        let additionalCosts =
            convertTemplateDetailsToItemAdditionalCosts(itemTemplateField);

        // let additionalCostForHandleChange =
        //     formattedAdditionalCost(additionalCosts);

        let itemFields = convertTemplateDetailsToItemFields(itemTemplateField);

        setNewItemTemplateSectionList({
            customFields,
            additionalCosts,
            itemFields,
        });
    };

    return {
        newItemTemplateSectionList,
        customFieldSetting,
        newGetTemplateFields,
        setNewItemTemplateSectionList,
        newItemhookState,
    };
};
