import { FWTypography } from '../../../../Common/FWTypograhy';

type VendorContactStatusColors = 'success' | 'error' | 'warning';

export const getVendorContactStatus = (
    status: string
): {
    color: VendorContactStatusColors;
    label: string;
} => {
    let color: VendorContactStatusColors = 'success';
    let label = '';
    switch (status) {
        case 'INVITED':
            color = 'warning';
            label = 'Signup pending';
            break;
        case 'ACTIVE':
            color = 'success';
            label = 'Active';
            break;
    }
    return {
        color,
        label,
    };
};

const VendorContactStatusChip = ({ status }: { status: string }) => {
    const { label, color } = getVendorContactStatus(status);
    return (
        <FWTypography
            color={color + '.main'}
            variant="caption"
            sx={{ display: 'block', fontSize: '1rem' }}
        >
            {label}
        </FWTypography>
    );
};

export default VendorContactStatusChip;
