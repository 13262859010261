import { isEmpty } from 'lodash';
import { getFWDateTime } from '../../../Common/Utils/DateUtils';
import { IAttachment } from '../../../Models/RFQ.model';
import { baseApiSlice } from '../../../Redux/Slices/BaseApiSlice';
import { get, post, put } from '../../../Utils/api';
import { identificationVerificationStatusMapService } from '../Constants/IdentificationsConstants';
import {
    AddressState,
    IAddress,
    IAddressDetail,
    ICountryList,
    IEntitiesDetails,
    IEntityAddress,
    IEntityAddressIdentification,
} from '../Interfaces/AddressInterface';
import { IIdentificationDetail } from '../Interfaces/IdentificationInterface';

export const getAddressList = async (
    filter?: boolean
): Promise<IAddress[] | []> => {
    const response: any = await get<any>(`/organization/address/`);
    return response.data
        .filter((address: any) => {
            if (filter) return address.status === 'ACTIVE';
            else return true;
        })
        .sort((a: any, b: any) => {
            if (
                a.address_nickname.toLowerCase() <
                b.address_nickname.toLowerCase()
            ) {
                return -1;
            }
            if (
                a.address_nickname.toLowerCase() >
                b.address_nickname.toLowerCase()
            ) {
                return 1;
            }
            return 0;
        })
        .map((item: any, index: number) => {
            let [isPrimary, primaryEntities]: [boolean, string[]] = [false, []];
            const entities: IEntitiesDetails[] = item.entities
                .filter((e: any) => e.status === 'ACTIVE')
                .map((entity: any) => {
                    if (entity.is_primary_address) {
                        isPrimary = true;
                        primaryEntities.push(entity.entity_name);
                    }
                    return {
                        addressId: entity.address,
                        entityId: entity.entity,
                        entityName: entity.entity_name,
                    };
                });

            let fulladdress = item.address1;
            if (!isEmpty(item.address2)) {
                fulladdress += `, ${item.address2}`;
            }
            if (!isEmpty(item.address3)) {
                fulladdress += `, ${item.address3}`;
            }
            const newItem: IAddress = {
                id: index,
                nickName: item.address_nickname,
                address: fulladdress,
                state: item.state_or_territory,
                addressId: item.address_id,
                city: item.city,
                zipCode: item.postal_code,
                entities: entities,
                entitiesCount: !isEmpty(entities) ? entities.length : -1,
                addressInstruction: item.notes,
                country: item.country_name,
                status: item.status,
                isPrimary,
                primaryEntities,
                modifiedDateTime: getFWDateTime(item.modified_datetime),
            };
            return newItem;
        });
};

export const statusUpdate = async ({
    address_id,
    status,
}: {
    address_id: string;
    status: string;
}): Promise<{ error: boolean }> => {
    try {
        await put<string, { status: string }>(
            `/organization/address/${address_id}/admin/update/`,
            { status }
        );
        return { error: false };
    } catch (e) {
        return { error: true };
    }
};

export interface IAddressCreation {
    id?: number;
    addressName: string;
    address1: string;
    address_id?: string;
    address2: string;
    address3: string;
    addressInstruction: string;
    stateOrTerritory: string;
    postalCode: string;
    city: string;
    country: string;
    address_url?: string | null;
    identifications: string[];
}
interface IAddressCreationResponse {
    addressId: string;
}

export const addressCreateForEnterprise = async (
    addressDetails: IAddressCreation
): Promise<IAddressCreationResponse> => {
    const address_details = {
        address_nickname: addressDetails.addressName,
        address1: addressDetails.address1,
        address2: addressDetails.address2,
        address3: addressDetails.address3,
        notes: addressDetails.addressInstruction,
        state_or_territory: addressDetails.stateOrTerritory,
        identifications: addressDetails.identifications,
        postal_code: addressDetails.postalCode,
        city: addressDetails.city,
        country: addressDetails.country,
        address_url: Boolean(addressDetails.address_url)
            ? addressDetails.address_url
            : null,
    };
    let response: any = await post<string, IAddressCreation>(
        `/organization/address/admin/create/`,
        address_details
    );
    const data = {
        addressId: response.data.address_id,
    };
    return data;
};

export const addressCreateForEntity = async (
    addressDetails: IAddressCreation,
    entity_id: string
): Promise<IAddressCreationResponse> => {
    const address_details = {
        address_nickname: addressDetails.addressName,
        address1: addressDetails.address1,
        address2: addressDetails.address2,
        address3: addressDetails.address3,
        notes: addressDetails.addressInstruction,
        state_or_territory: addressDetails.stateOrTerritory,
        identifications: addressDetails.identifications,
        postal_code: addressDetails.postalCode,
        city: addressDetails.city,
        country: addressDetails.country,
        address_url: Boolean(addressDetails.address_url)
            ? addressDetails.address_url
            : null,
    };
    let response: any = await post<string, IAddressCreation>(
        `/organization/address/entity/${entity_id}/create/`,
        address_details
    );
    const data = {
        addressId: response.data.address.address_id,
    };
    return data;
};

export const addressUpdate = async (
    addressDetails: IAddressDetail
): Promise<boolean> => {
    const address_details = {
        address_nickname: addressDetails.addressName,
        address1: addressDetails.address1,
        address2: addressDetails.address2,
        address3: addressDetails.address3,
        notes: addressDetails.addressInstruction,
        state_or_territory: addressDetails.stateOrTerritory,
        postal_code: addressDetails.postalCode,
        city: addressDetails.city,
        country: addressDetails.country,
        status: addressDetails.status,
    };
    await put<string, IAddressCreation>(
        `/organization/address/${addressDetails.address_id}/admin/update/`,
        address_details
    );
    return true;
};

export const listCountries = async (): Promise<ICountryList[]> => {
    const response: any = await get<any>(`/backbone/countries/`);
    return response.data.map((country: any) => {
        return { name: country.name, countryCode: country.iso };
    });
};
export const listAddressName = async (): Promise<string[]> => {
    const response: any = await get<string>(`/organization/address/`);
    return response.data.map((item: any) => {
        return item.address_nickname.trim().toLowerCase();
    });
};

export const { useGetEntityIdentificationsQuery } =
    baseApiSlice.injectEndpoints({
        endpoints: (builder) => ({
            getEntityIdentifications: builder.query<
                IIdentificationDetail[],
                { entityId: string }
            >({
                query: ({ entityId }) =>
                    `/organization/entity/${entityId}/identifications/`,
                transformResponse: (response: any[]) => {
                    return response
                        .sort((a, b) => {
                            if (
                                a.identification_name.toLowerCase() <
                                b.identification_name.toLowerCase()
                            ) {
                                return -1;
                            }
                            if (
                                a.identification_name.toLowerCase() >
                                b.identification_name.toLowerCase()
                            ) {
                                return 1;
                            }
                            return 0;
                        })
                        .map((identification, index: number) => {
                            const attachments: IAttachment[] =
                                identification.attachments.map(
                                    (attachment: any) => {
                                        const na: IAttachment = {
                                            attachment_id:
                                                attachment.attachment_id,
                                            storage_id: attachment.storage_id,
                                            file_name: attachment.file_name,
                                        };
                                        return na;
                                    }
                                );
                            const detail: IIdentificationDetail = {
                                index,
                                id: identification.identification_id,
                                name: identification.identification_name,
                                value: identification.identification_value,
                                isDefault: identification.is_default,
                                isPublic: identification.is_public,
                                status: identification.status,
                                linked_addresses:
                                    identification?.linked_addresses ?? [],
                                entityId: identification.entity,
                                notes: identification.notes,
                                identificationStatus:
                                    identificationVerificationStatusMapService[
                                        identification.verification_status
                                    ],
                                attachments,
                            };
                            return detail;
                        })
                        .filter((id) => id.status === 'ACTIVE');
                },
            }),
        }),
    });

export const entityAddressCreate = async (
    addressDetails: IEntityAddress[],
    addressId: string,
    inactiveIdentifications: {
        [key: string]: string[];
    }
): Promise<{ addressId: string }> => {
    const entityAddressDetails = addressDetails.map(
        (entityAddress: IEntityAddress) => {
            let inActiveIds = inactiveIdentifications[
                entityAddress.entityId
            ]?.map((id) => ({ identification_id: id, status: 'INACTIVE' }));
            let obj = {
                entity_id: entityAddress.entityId,
                is_primary_address: entityAddress.primary,
                is_shipping_address: entityAddress.shipping,
                is_billing_address: entityAddress.billing,
                is_public: entityAddress.public,
                identifications: entityAddress.identifications.map((id) => ({
                    identification_id:
                        id.entity_identification.identification_id,
                    status: 'ACTIVE',
                })),

                status: !isEmpty(entityAddress.status)
                    ? entityAddress.status
                    : AddressState.ACTIVE,
            };

            if (inActiveIds) {
                if (obj.identifications)
                    obj.identifications = [
                        ...obj.identifications,
                        ...inActiveIds,
                    ];
                else obj.identifications = [...inActiveIds];
            }

            return obj;
        }
    );

    let response: any = await put<string, IAddressCreation>(
        `/organization/address/${addressId}/admin/entity/update/`,
        entityAddressDetails
    );

    return response;
};

export const entityAddressUpdate = async (
    addressDetails: IEntityViewAddress[],
    entityId: string
): Promise<{ addressId: string }> => {
    const entityAddressList = addressDetails.map(
        (entityAddress: IEntityViewAddress) => {
            return {
                address_id: entityAddress.id,
                is_primary_address: entityAddress.primary,
                is_shipping_address: entityAddress.shipping,
                is_billing_address: entityAddress.billing,
                is_public: entityAddress.public,
                status: !isEmpty(entityAddress.status)
                    ? entityAddress.status
                    : AddressState.ACTIVE,
            };
        }
    );
    let response: any = await put<string, IAddressCreation>(
        `/organization/address/admin/entity/${entityId}/address/update/`,
        entityAddressList
    );
    return response;
};

export const getAddressDetail = async (
    address_id: string
): Promise<IAddressDetail> => {
    const response: any = await get<{ address_id: string }>(
        `/organization/address/${address_id}/`
    );
    let data = response.data;
    const entities: IEntityAddress[] = data.entities
        .filter(
            (entityAddress: IEntityAddress) =>
                entityAddress.status === AddressState.ACTIVE
        )
        .map((entity: any) => {
            return {
                id: entity.entity,
                entityName: entity.entity_name,
                entityId: entity.entity,
                addressId: entity.address,
                public: entity.is_public,
                primary: entity.is_primary_address,
                isDefaultPrimary: entity.is_primary_address,
                shipping: entity.is_shipping_address,
                billing: entity.is_billing_address,
                status: entity.status,
                address_url: entity.address_url,
                identifications: entity?.linked_identifications ?? [],
            };
        });
    return {
        addressName: data.address_nickname,
        address1: data.address1,
        address2: data.address2,
        address3: data.address3,
        addressInstruction: data.notes,
        stateOrTerritory: data.state_or_territory,
        address_id: data.address_id,
        city: data.city,
        postalCode: data.postal_code,
        entities: entities,
        country: data.country_name,
        status: data.status,
        address_url: data?.address_url,
    };
};
export interface IEntityViewAddress {
    id?: string;
    isNew?: boolean;
    isReAdded?: boolean;
    isEdited?: boolean;
    address: {
        addressName: string;
        fullAddress: string;
        addressInstruction: string;
        stateOrTerritory: string;
        addressId: string;
        city: string;
        postalCode: string;
        country: string;
        status: string;
        linked_identifications?: IEntityAddressIdentification[];
    };
    entityId: string;
    primary: boolean;
    shipping: boolean;
    billing: boolean;
    public: boolean;
    status: string;
}

export const getEntityAddressDetail = async (entityId: string) => {
    const response: any = await get<{ entityId: string }>(
        `/organization/address/admin/entity/${entityId}/`
    );
    let data = response.data;
    const allEntityAddresses: IEntityViewAddress[] = data
        .filter((address: any) => {
            return address.address.status === 'ACTIVE';
        })
        .map((entityAddresses: any): IEntityViewAddress => {
            return {
                address: {
                    addressName: entityAddresses.address.address_nickname,
                    fullAddress: entityAddresses.address.full_address,
                    addressInstruction: entityAddresses.address.notes,
                    stateOrTerritory:
                        entityAddresses.address.state_or_territory,
                    addressId: entityAddresses.address.address_id,
                    city: entityAddresses.address.city,
                    postalCode: entityAddresses.address.postal_code,
                    country: entityAddresses.address.country_name,
                    status: entityAddresses.address.status,
                },
                id: entityAddresses.address.address_id,
                entityId: entityAddresses.entity,
                primary: entityAddresses.is_primary_address,
                shipping: entityAddresses.is_shipping_address,
                billing: entityAddresses.is_billing_address,
                public: entityAddresses.is_public,
                status: entityAddresses.status,
            };
        })
        .sort((a: IEntityViewAddress, b: IEntityViewAddress) => {
            if (
                a.address.addressName.toLowerCase() <
                b.address.addressName.toLowerCase()
            ) {
                return -1;
            }
            if (
                a.address.addressName.toLowerCase() >
                b.address.addressName.toLowerCase()
            ) {
                return 1;
            }
            return 0;
        });
    return allEntityAddresses;
};
