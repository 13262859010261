import { Box, Typography } from '@mui/material';
import {
    IAggregateOfCurrency,
    IAggregateOfProject,
} from '../../../Historical/Containers/DynamicAnalyticsItemPrice';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LineElement,
    LinearScale,
    PointElement,
    Tooltip,
} from 'chart.js';
import FWSelect from '../../../Common/FWSelect';
import { FWMenuItem } from '../../../Common/FWCustomMenu';
import { useEffect, useState } from 'react';
import { BarElement } from 'chart.js';
import { Title } from 'chart.js';
import { FWTypography } from '../../../Common/FWTypograhy';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels
);

interface IContractGraphData {
    contractName: string;
    vendorName: string;
    value: string;
    contractID: string;
    currencyUid: string;
    currencyName: string;
    currencySymbol: string;
    currencyAbbreviation: string;
}

const ContractBarChart = ({
    graphData,
    aggregateOfCurrency,
    aggregateOfProject,
    handleFilterChange,
    onBarClick,
}: {
    graphData: IContractGraphData[];
    handleFilterChange: (
        currencyUid: string | null,
        projectUid: string | null
    ) => void;
    aggregateOfCurrency: IAggregateOfCurrency;
    aggregateOfProject: IAggregateOfProject;
    onBarClick: (contractID: string) => void;
}) => {
    const options: any = {
        locale: 'en-IN',
        responsive: true,
        plugins: {
            datalabels: {
                display: false,
            },
            tooltip: {
                enabled: true,
                mode: 'index',
                callbacks: {
                    title: function (tooltipItems: any): string {
                        return `${
                            graphData[tooltipItems[0].dataIndex].contractName
                        } (${graphData[tooltipItems[0].dataIndex].vendorName})`;
                    },
                    label: function (tooltipItems: any): string {
                        return `${
                            graphData[tooltipItems.dataIndex].currencySymbol
                        }${graphData[tooltipItems.dataIndex].value}`;
                    },
                },
            },
        },
        scales: {
            x: {
                grid: {
                    display: false, // hide the vertical grid lines for the x-axis
                },
                ticks: {
                    callback: function (
                        val: string | number,
                        index: number
                    ): string {
                        return typeof val === 'number'
                            ? `${(this as any)
                                  .getLabelForValue(val)
                                  .substring(0, 10)}${
                                  (this as any).getLabelForValue(val).length >
                                  10
                                      ? '...'
                                      : ''
                              }`
                            : '';
                    },
                },
            },
            y: {
                grace: '5%',
                title: {
                    display: true,
                    text: 'Rate',
                },
            },
        },
        onClick: (evt: any, element: any) => {
            var idx = element[0]?.index;
            if (idx !== undefined) {
                onBarClick(graphData[idx].contractID);
            }
        },
        onHover: (evt: any, element: any) => {
            if (evt?.native?.target?.style) {
                evt.native.target.style.cursor = element[0]
                    ? 'pointer'
                    : 'default';
            }
        },
    };

    const data = {
        labels: graphData.map((item) => item.vendorName),
        datasets: [
            {
                label: 'Rate',
                data: graphData.map((item) => parseInt(item.value)),
                backgroundColor: 'rgb(255, 99, 132)',
            },
        ],
    };

    const [filterArgs, setFilterArgs] = useState<{
        currencyUid: string | null;
        projectUid: string | null;
    }>({
        currencyUid: null,
        projectUid: null,
    });

    useEffect(() => {
        //
        handleFilterChange(filterArgs.currencyUid, filterArgs.projectUid);
    }, [filterArgs.currencyUid, filterArgs.projectUid, handleFilterChange]);

    return (
        <>
            <Box className="flex flex--aic flex--jcsb">
                <Typography
                    variant="h5"
                    fontWeight={500}
                    color="secondary.main"
                >
                    Contract analytics
                </Typography>
                <Box className="flex flex--aic">
                    <FWSelect
                        size={'small'}
                        sx={{
                            scale: '0.9',
                            width: '160px',
                        }}
                        defaultValue={'allCurrencies'}
                        onChange={(e: any) => {
                            setFilterArgs((prev) => ({
                                ...prev,
                                currencyUid:
                                    e.target.value === 'allCurrencies'
                                        ? null
                                        : e.target.value,
                            }));
                        }}
                    >
                        <FWMenuItem
                            key={'allCurrencies'}
                            value={'allCurrencies'}
                        >
                            All currencies
                        </FWMenuItem>
                        {Object.keys(aggregateOfCurrency).map((currencyId) => (
                            <FWMenuItem key={currencyId} value={currencyId}>
                                {`${aggregateOfCurrency[currencyId].currencyAbbreviation} (${aggregateOfCurrency[currencyId].currencySymbol})`}
                            </FWMenuItem>
                        ))}
                    </FWSelect>
                    <FWSelect
                        size={'small'}
                        sx={{
                            scale: '0.9',
                            width: '160px',
                        }}
                        defaultValue={'allProjects'}
                        onChange={(e: any) => {
                            setFilterArgs((prev) => ({
                                ...prev,
                                projectUid:
                                    e.target.value === 'allProjects'
                                        ? null
                                        : e.target.value,
                            }));
                        }}
                    >
                        <FWMenuItem key={'allProjects'} value={'allProjects'}>
                            All projects
                        </FWMenuItem>
                        {Object.keys(aggregateOfProject).map((projectId) => (
                            <FWMenuItem key={projectId} value={projectId}>
                                {`${aggregateOfProject[projectId].projectName} (${aggregateOfProject[projectId].projectCode})`}
                            </FWMenuItem>
                        ))}
                    </FWSelect>
                </Box>
            </Box>
            {graphData.length === 0 ? (
                <Box className="flex flex--jcc flex--aic" height="270px">
                    <FWTypography color="text.secondary" variant="caption">
                        No data available
                    </FWTypography>
                </Box>
            ) : (
                <Bar options={options} data={data} />
            )}
        </>
    );
};

export default ContractBarChart;
