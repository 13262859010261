export enum ChatTypes {
    'BROADCAST' = 'broadcast',
    'DIRECT' = 'message',
}
export interface IChatEvent {
    custom_event_id: string;
    status: string;
    event_id: string;
    event_name: string;
    event_type: string;
    live_event_start_datetime: string;
    event_end_datetime: string;
    buyer_entity: {
        buyer_entity_id: string;
        buyer_entity_name: string;
    };
    additional_details: {
        access: 'ACCESS_RESTRICTED' | 'ACCESS_DEFAULT';
    };
    sub_event_id: string;
    sub_event_additional_details: {
        item_count: number;
        child_rfq_id: string | null;
        vendor_count: number;
        parent_rfq_id: string | null;
    };
    original_event_creator_name: string;
    sub_event_creator: string;
}

export interface ISessionMessage {
    message: string;
    message_id: string;
    message_type: ChatTypes;
    module_id: string;
    session_id: string;
    session_id_list: { session_id_list: string[] };
    sender_id: string;
    sender_name: string;
    sender_enterprise_id: string;
    created_datetime: string;
    attachments: {
        attachment_id: string;
        file_name: string;
    }[];
}

export interface IBroadcastSessionMessage {
    message_id: string;
    message: string;
    message_type: string;
    sequence_number: number;
    module_id: string;
    session: {
        session_id: string;
        session_name: string;
        seller_entity_id: string;
        seller_entity_name: string;
        buyer_entity_id: string;
        buyer_entity_name: string;
    };
    sender_id: string;
    sender_name: string;
    sender_enterprise_id: string;
    created_datetime: string;
}

export interface ISessionMessageToSend {
    message: string;
    message_id: string;
    message_type: ChatTypes;
    module_id: string;
    session_id: string;
    seller_entity_list: string[];
    attachment_ids: string[];
}

export interface IUserSession {
    session: {
        session_id: string;
        session_name: string;
        seller_entity_id: string;
        buyer_entity_id: string;
        seller_entity_name: string;
        buyer_entity_name: string;
        session_type: 'CHAT' | 'INTERNAL' | 'BROADCAST' | 'SELF';
        last_msg: {
            message: string;
            message_id: string;
            posted_time: string;
            sender_id: string;
            sender_name: string;
            attachments: any[];
        } | null;
    };
    mute: boolean;
    last_acknowledged: {
        message_id: string;
        message: string;
    } | null;
    user: string;
    user_session_id: string;
    last_message: {
        message: string;
        message_id: string;
        posted_time: string;
        sender_id: string;
        sender_name: string;
        attachments: any[];
    } | null;
    action_api_group: 'EVENT_VIEW' | 'EVENT_EDIT';
}

export interface IChatSliceState {
    currentlyOpenedSession: string | null;
    messagesToSend: ISessionMessageToSend[];
    messagesReceived: ISessionMessage[];
    messageCountDictionary: {
        // {module_id: {session_id: count}}
        [key: string | 'total_count']:
            | {
                  [key: string | 'total_count']: number;
              }
            | number;
    };
}
export interface ISession {
    session_id: string;
    session_name: string;
    buyer_entity: string;
    buyer_entity_name: string;
    seller_entity: string;
    seller_entity_name: string;
    module_id: string;
    module_type: 'EVENT';
    session_users: ISessionUser[];
    session_type: 'CHAT' | 'INTERNAL' | 'BROADCAST';
}

export interface ISessionUser {
    user_id: string;
    name: string;
    email: string;
    status: string | 'ACTIVE'; // this is intentionally done we are only concerned about active users
}

export interface IChatNotesListData {
    status: 'ACTIVE' | 'INACTIVE';
    note_id: string;
    title: string;
}
export interface IChatNotesList {
    custom: IChatNotesListData[];
    items: IChatNotesListData[];
    sellers: IChatNotesListData[];
}
