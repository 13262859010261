import { useEffect } from 'react';
import FWDataGrid from '../../Common/FWDataGrid';
import { HookStateValue } from '../../Common/Hooks/StateHook';
import { pendingColumns } from '../Constants/GRDashboardConstants';
import { IGRPOListProviders, useGRPOList } from '../Hooks/GRDashboardHook';
import { IGRInvoiceListSummary } from '../Interfaces/GRInterface';

const GRPendingCombinedView = ({
    searchText,
    handleDeliveryScheduleToggle,
    handleRowClick,
    searchList,
}: {
    searchText: string;
    handleDeliveryScheduleToggle: (
        poUid: string,
        measurementUnit: string,
        el: HTMLElement
    ) => void;
    searchList: () => void;
    handleRowClick: ({ data }: { data: IGRInvoiceListSummary }) => void;
}) => {
    const { hookState, pendingList }: IGRPOListProviders = useGRPOList();
    useEffect(() => {
        if (hookState.state === HookStateValue.READY) searchList();
    }, [pendingList, searchList, hookState]);
    return (
        <FWDataGrid
            rows={
                hookState.state === HookStateValue.LOADING ? null : pendingList
            }
            columns={pendingColumns}
            fixedHeight={610}
            search={searchText}
            context={{ handleDeliveryScheduleToggle }}
            onRowClicked={handleRowClick}
            // getRowClass={({ data }: { data: IGRInvoiceListSummary }) => {
            //     return !grPermissions[data.entityUid].EDIT
            //         ? 'disabled'
            //         : '';
            // }}
        />
    );
};

export default GRPendingCombinedView;
