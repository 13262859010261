import { get, post } from '../../Utils/api';
import { verificationStatusMapService } from '../Constants/VerificationConstants';
import {
    IVerificationDetails,
    IVerificationEntitySummary,
} from '../Interfaces/OrganizationVerificationInterface';

export const fetchEntityDetails = async (
    entityUid: string
): Promise<IVerificationEntitySummary> => {
    let response: any = await get<string>(`/organization/entity/${entityUid}/`);
    const data: any = response.data;

    if (data) {
        const entity: IVerificationEntitySummary = {
            entityUid: data.entity_id,
            entityName: data.entity_name,
            verificationStatus:
                verificationStatusMapService[data.verification_status],
            verificationDetails: [
                {
                    verificationIdType: '',
                    verificationIdValue: '',
                    notes: '',
                    attachments: [],
                },
            ],
        };
        return entity;
    }
    return response;
};

export const saveVerification = async (
    details: IVerificationDetails[],
    entityUid: string
): Promise<void> => {
    const payload = {
        identifications: details.map((detail) => ({
            attachment_id: detail.attachments
                .map((attachment) => attachment.attachment_id)
                .join(''),
            name: detail.verificationIdType,
            value: detail.verificationIdValue,
            notes: detail.notes,
            category: 'OTHERS',
        })),
    };
    await post<any, any>(
        `/organization/entity/${entityUid}/verification/create/`,
        payload
    );
};
