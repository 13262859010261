const fixedColors = [
    // '#17becf',
    // '#1b9bc2',
    // '#9467bd',
    // '#694488',
    // '#381d4f',
    '#A891D3',
    '#00BFBF',
    '#9DCD7B',
    '#E6E6FA',
    '#B4D8E7',
    '#DAA3A3',
    '#FFDAB9',
    '#FFFDD0',
    '#D2B48C',
];

const getGraphColor = (index: number): string => {
    if (index < fixedColors.length) {
        return fixedColors[index];
    }

    const baseColor = fixedColors[index % fixedColors.length];

    let red = parseInt(baseColor.slice(1, 3), 16);
    let green = parseInt(baseColor.slice(3, 5), 16);
    let blue = parseInt(baseColor.slice(5, 7), 16);

    /**
     * Modify the color such that it is similar to the base color
     * but not the same.
     **/

    const modificationAmount = Math.floor(index / fixedColors.length) / 50;

    red = Math.floor(red * (1 + modificationAmount));
    green = Math.floor(green * (1 + modificationAmount));
    blue = Math.floor(blue * (1 + modificationAmount));

    return `#${(red % 16).toString(16)}${(green % 16).toString(16)}${(
        blue % 16
    ).toString(16)}`;
};

export const graphColor = Array.from(Array(200).keys()).map((i) =>
    getGraphColor(i)
);
