import { getFWDateTime } from '../../Common/Utils/DateUtils';
import { baseApiSlice } from '../../Redux/Slices/BaseApiSlice';
import { post, put } from '../../Utils/api';
import {
    ICostCenterGet,
    ICostCenterPayload,
} from '../interface/costCenter.modal';

export const createCostCenterApi = async (costCenter: ICostCenterPayload) => {
    try {
        const { data } = await post(
            '/organization/cost_centre/create/',
            costCenter
        );
        return data;
    } catch (error) {
        throw error;
    }
};

export const updateCostCenterPost = async (
    costCenterUid: string,
    costCenter: ICostCenterPayload
) => {
    const newCostCenter = {
        ...costCenter,
        notes: costCenter.notes?.trim() !== '' ? costCenter.notes : null,
        description:
            costCenter.description?.trim() !== ''
                ? costCenter.description
                : null,
    };
    try {
        const { data } = await put(
            `/organization/cost_centre/${costCenterUid}/update/`,
            newCostCenter
        );
        return data;
    } catch (error) {
        throw error;
    }
};

interface ICostCenterBackendResponse {
    cost_centre_entry_id: string;
    buyer_entity_count: number;
    buyer_entities:
        | Array<{
              entity_cost_centre_id: string;
              buyer_entity_name: string;
              created_datetime: string;
              modified_datetime: string;
              deleted_datetime: any;
              status: string;
              created_by_user: string;
              modified_by_user: string;
              deleted_by_user: any;
              cost_centre: string;
              buyer_entity: string;
              currency_code: string;
          }>
        | undefined;
    created_datetime: string;
    modified_datetime: string;
    deleted_datetime: any;
    cost_centre_id: string;
    cost_centre_name: string;
    description: any;
    notes: any;
    validity_from: any;
    validity_to: any;
    status: string;
    custom_fields: {
        section_list: Array<any>;
    };
    created_by_user: string;
    modified_by_user: string;
    deleted_by_user: any;
    enterprise: string;
}
interface ICostCenterBackendResponsePagiation {
    data: ICostCenterBackendResponse[];
    metadata: {
        current_page: number;
        has_next: boolean;
        has_previous: boolean;
        total_pages: number;
        page_range: {
            start: number;
            stop: number;
        };
        count: number;
    };
    counts: {
        all: number;
    };
}

interface ICostCenterBackendResponseEntityPagiation {
    data: {
        cost_centre: ICostCenterBackendResponse;
    }[];
    metadata: {
        current_page: number;
        has_next: boolean;
        has_previous: boolean;
        total_pages: number;
        page_range: {
            start: number;
            stop: number;
        };
        count: number;
    };
    counts: {
        all: number;
    };
}

export interface ICostCenteDashboard {
    buyerEntites: {
        entity_cost_centre_id: string;
        buyer_entity_name: string;
        created_datetime: string;
        modified_datetime: string;
        deleted_datetime: any;
        status: string;
        created_by_user: string;
        modified_by_user: string;
        deleted_by_user: any;
        cost_centre: string;
        buyer_entity: string;
        currency_code: string;
    }[];
    costCenterUid: string;
    costCenterName: string;
    costCenterId: string;
    type: string;
    createdOn: string;
    createdBy: string;
    buyerEntityCount: number;
    status: string;
    entitiesNames: string[];
}

export const CostCenterApiSlice = baseApiSlice.injectEndpoints({
    endpoints: (builder) => ({
        listOfCostCenter: builder.mutation<
            {
                data: ICostCenteDashboard[];
                metadata: {
                    current_page: number;
                    has_next: boolean;
                    has_previous: boolean;
                    total_pages: number;
                    page_range: {
                        start: number;
                        stop: number;
                    };
                    count: number;
                };
                counts: {
                    all: number;
                };
            },
            {
                dashboard_view: string;
                tab: string;
                search_text?: string;
                sort_fields?: { field: string; ascending: boolean }[];
                items_per_page?: number;
                page_number: number;
                entity_id?: string;
            }
        >({
            query: ({
                dashboard_view,
                tab,
                search_text = '',
                sort_fields = [],
                items_per_page = 10,
                page_number,
                entity_id,
            }) => ({
                url: '/dashboard/',
                method: 'POST',
                body: {
                    dashboard_view: `${entity_id ? 'entity_' : ''}cost_centre`,
                    tab,
                    page_number: page_number,
                    search_text,
                    sort_fields,
                    items_per_page,
                    ...(entity_id && {
                        query_data: {
                            entity_id,
                        },
                    }),
                },
            }),
            transformResponse: (
                response:
                    | ICostCenterBackendResponsePagiation
                    | ICostCenterBackendResponseEntityPagiation,
                meta,
                args
            ) => {
                if (args.entity_id) {
                    return {
                        data: (
                            response as ICostCenterBackendResponseEntityPagiation
                        ).data.map(({ cost_centre: cc }) => ({
                            costCenterUid: cc.cost_centre_entry_id,
                            costCenterId: cc.cost_centre_id,
                            costCenterName: cc.cost_centre_name,
                            type: cc.status,
                            createdOn: getFWDateTime(cc.created_datetime),
                            buyerEntityCount: cc.buyer_entity_count,
                            status: cc.status,
                            createdBy: cc.created_by_user,
                            entitiesNames:
                                cc.buyer_entities?.map(
                                    (entity) => entity.buyer_entity_name
                                ) ?? [],
                            buyerEntites: cc.buyer_entities ?? [],
                        })),
                        metadata: response.metadata,
                        counts: response.counts,
                    };
                }

                return {
                    data: (
                        response as ICostCenterBackendResponsePagiation
                    ).data.map((cc) => ({
                        costCenterUid: cc.cost_centre_entry_id,
                        costCenterId: cc.cost_centre_id,
                        costCenterName: cc.cost_centre_name,
                        type: cc.status,
                        createdOn: getFWDateTime(cc.created_datetime),
                        buyerEntityCount: cc.buyer_entity_count,
                        status: cc.status,
                        createdBy: cc.created_by_user,
                        entitiesNames:
                            cc.buyer_entities?.map(
                                (entity) => entity.buyer_entity_name
                            ) ?? [],
                        buyerEntites: cc.buyer_entities ?? [],
                    })),
                    metadata: response.metadata,
                    counts: response.counts,
                };
            },
        }),
        getCostCenterDetails: builder.query<ICostCenterGet, string>({
            query: (costCenterUid) =>
                `/organization/cost_centre/${costCenterUid}/`,
        }),
        getListOfAllCostCenter: builder.query<
            ICostCenteDashboard[],
            {
                entity_id?: string;
                isActive?: boolean;
            }
        >({
            query: () => `/organization/cost_centre/`,
            transformResponse: (
                response: ICostCenterBackendResponse[],
                meta,
                { entity_id, isActive }
            ) =>
                response
                    .filter((costCenter) => {
                        if (entity_id !== undefined && isActive !== undefined) {
                            return (
                                (
                                    costCenter.buyer_entities?.map(
                                        (entity) => entity.buyer_entity
                                    ) ?? []
                                ).includes(entity_id) &&
                                (isActive
                                    ? costCenter.status === 'ACTIVE'
                                    : costCenter.status === 'INACTIVE')
                            );
                        } else if (entity_id !== undefined) {
                            return (
                                costCenter.buyer_entities?.map(
                                    (entity) => entity.buyer_entity
                                ) ?? []
                            ).includes(entity_id);
                        } else if (isActive !== undefined) {
                            return isActive
                                ? costCenter.status === 'ACTIVE'
                                : costCenter.status === 'INACTIVE';
                        }

                        return true;
                    })
                    .map((cc) => ({
                        costCenterUid: cc.cost_centre_entry_id,
                        costCenterId: cc.cost_centre_id,
                        costCenterName: cc.cost_centre_name,
                        type: cc.status,
                        entitiesNames:
                            cc.buyer_entities?.map(
                                (entity) => entity.buyer_entity_name
                            ) ?? [],
                        buyerEntites: cc.buyer_entities ?? [],
                        buyerEntityCount: cc.buyer_entity_count,
                        status: cc.status,
                        createdBy: cc.created_by_user,
                        createdOn: getFWDateTime(cc.created_datetime),
                    })),
        }),
        getCostCenterTabCount: builder.mutation<
            {
                tab_counts: {
                    active: number;
                    disabled: number;
                    all: number;
                };
            },
            {}
        >({
            query: () => ({
                url: '/dashboard/tab_counts/',
                method: 'POST',
                body: {
                    dashboard_view: 'cost_centre',
                },
            }),
        }),
    }),
});

export const {
    useGetCostCenterTabCountMutation,
    useListOfCostCenterMutation,
    useGetCostCenterDetailsQuery,
    useGetListOfAllCostCenterQuery,
} = CostCenterApiSlice;

export const updateStatusOfCostCenter = async (
    costCenterUid: string,
    status: 'ACTIVE' | 'INACTIVE'
) => {
    try {
        const response = await put(
            `/organization/cost_centre/${costCenterUid}/update/state/`,
            {
                status,
            }
        );
        return response;
    } catch (err) {}
};
