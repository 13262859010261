import { Box } from '@mui/material';
import { FWTooltip } from '../../../Common/FWCustomTooltip';
import { FWInput } from '../../../Common/FWInput';
import { FWTypography } from '../../../Common/FWTypograhy';
import { IUserDetails } from '../../../Models/UserDetails.model';
import {
    IUserManagerDetails,
    IUserProfileError,
    IUserProfileKeys,
} from '../Interfaces/UserProfile.model';

interface IUserBasicDetails {
    user_details: IUserDetails;
    updateDetails: (field: IUserProfileKeys, value: any) => void;
    errors: IUserProfileError;
    managersList: IUserManagerDetails[];
}

export default function UserDetails(props: IUserBasicDetails) {
    return (
        <>
            {/* TODO: Removed email from here and moved to avatar since it is currently non-editable */}
            {/* <Box>
                <FWTypography color="text.secondary">Email</FWTypography>
                < className="flex flex--aife">
                    <FWTypography>{props.user_details.email}</FWTypography>
                    {// <span style={{ width: '0.75rem' }}></span>
                    //<FWTooltip title="Visible to FW community">
                    //    <IconButton size="small" sx={{ padding: '0px' }}>
                    //        <i
                    //            className={
                    //                true ? 'bi bi-eye' : 'bi bi-eye-slash'
                    //            }
                    //        ></i>
                    //    </IconButton>
                    //</FWTooltip>
                </Box>
            </Box> */}
            {/* <Box>
                <FWTypography color="text.secondary">Password</FWTypography>
                <FWTypography>**************</FWTypography>
            </Box> */}
            <Box>
                <FWTypography color="text.secondary">Name</FWTypography>
                <FWInput
                    disabled
                    value={props.user_details.name}
                    onChange={(e) =>
                        props.updateDetails('name', e.target.value)
                    }
                    error={'name' in props.errors}
                    helper={{
                        text:
                            'name' in props.errors
                                ? props.errors.name!.msg
                                : '',
                    }}
                />
            </Box>
            <Box>
                <Box className="flex flex--aic">
                    <FWTypography color="text.secondary">
                        Contact number (optional)
                    </FWTypography>
                    &nbsp;
                    <FWTooltip
                        title="Visible to FW community"
                        placement="right"
                    >
                        <Box color="primary.main">
                            <i className="bi bi-info-circle"></i>
                        </Box>
                    </FWTooltip>
                    {/* TODO: Removed plus button. Allow multiple when we have time for the integration */}
                    {/* <span style={{ width: '0.75rem' }}></span>
                    <FWTooltip title="Add 1 more">
                        <IconButton
                            size="small"
                            color="primary"
                            onClick={() =>
                                props.updateDetails('contacts', {
                                    ...props.user_details.contacts,
                                    phone_numbers: [
                                        ...props.user_details.contacts!
                                            .phone_numbers,
                                        {
                                            phone: '',
                                            is_public: true,
                                        },
                                    ],
                                })
                            }
                        >
                            <i className="bi bi-plus-circle"></i>
                        </IconButton>
                    </FWTooltip> */}
                </Box>
                {props.user_details.contacts!.phone_numbers.map(
                    (phone_number, idx) => (
                        <Box
                            className="flex flex--aifs"
                            key={idx}
                            // marginBottom={'1rem'}
                        >
                            <FWInput
                                allowOnly="TEL"
                                value={phone_number.phone}
                                onChange={(e) =>
                                    props.updateDetails(
                                        `phone_${idx}`,
                                        e.target.value.trim()
                                    )
                                }
                                error={`phone_${idx}` in props.errors}
                                helper={{
                                    text:
                                        `phone_${idx}` in props.errors
                                            ? props.errors[`phone_${idx}`]!.msg
                                            : '',
                                }}
                            />
                            {/* Removing options for deleting number & changing visibility as we will only have 1 for now */}
                            {/* &nbsp;
                            <FWTooltip title="Delete row">
                                <IconButton
                                    size="small"
                                    color="error"
                                    onClick={() =>
                                        props.updateDetails(
                                            `phone_${idx}_delete`,
                                            null
                                        )
                                    }
                                >
                                    <i className="bi bi-trash"></i>
                                </IconButton>
                            </FWTooltip>
                            &nbsp;
                            <FWTooltip
                                title={
                                    phone_number.is_public
                                        ? 'Visible to FW community'
                                        : 'Not visible to FW community'
                                }
                            >
                                <IconButton
                                    size="small"
                                    color="primary"
                                    onClick={() =>
                                        props.updateDetails(
                                            `phone_${idx}_visible`,
                                            null
                                        )
                                    }
                                >
                                    {phone_number.is_public ? (
                                        <i className="bi bi-eye"></i>
                                    ) : (
                                        <i className="bi bi-eye-slash"></i>
                                    )}
                                </IconButton>
                            </FWTooltip> */}
                        </Box>
                    )
                )}
            </Box>

            <Box gridColumn={'1 / span 2'}>
                <FWTypography color="text.secondary">
                    Bio (optional)
                </FWTypography>
                <FWInput
                    multiline
                    rows={3}
                    value={props.user_details.description ?? ''}
                    onChange={(e) =>
                        props.updateDetails('description', e.target.value)
                    }
                    error={'description' in props.errors}
                    helper={{
                        text:
                            'description' in props.errors
                                ? props.errors.first_name!.msg
                                : '',
                    }}
                />
            </Box>

            {/* Secondary emails removed for now as integration in rest of app requires thinking */}
            {/* <Box gridColumn={'1 / span 2'}>
                <Box className="flex flex--aic">
                    <FWTypography color="text.secondary">
                        Secondary email
                    </FWTypography>
                    <span style={{ width: '0.75rem' }}></span>
                    <FWTooltip title="Add 1 more">
                        <IconButton
                            size="small"
                            color="primary"
                            onClick={() =>
                                props.updateDetails('contacts', {
                                    ...props.user_details.contacts,
                                    emails: [
                                        ...props.user_details.contacts!.emails,
                                        {
                                            email: '',
                                            is_public: true,
                                        },
                                    ],
                                })
                            }
                        >
                            <i className="bi bi-plus-circle"></i>
                        </IconButton>
                    </FWTooltip>
                </Box>
                {props.user_details.contacts !== null &&
                    props.user_details.contacts.emails.map((email, idx) => (
                        <Box
                            className="flex flex--aifs"
                            key={idx}
                            marginBottom={'1rem'}
                        >
                            <FWInput
                                allowOnly="EMAIL"
                                value={email.email}
                                onChange={(e) =>
                                    props.updateDetails(
                                        `email_${idx}`,
                                        e.target.value.trim()
                                    )
                                }
                                error={`email_${idx}` in props.errors}
                                helper={{
                                    text:
                                        `email_${idx}` in props.errors
                                            ? props.errors[`email_${idx}`]!.msg
                                            : '',
                                }}
                            />
                            &nbsp;
                            <FWTooltip title="Delete row">
                                <IconButton
                                    size="small"
                                    color="error"
                                    onClick={() =>
                                        props.updateDetails(
                                            `email_${idx}_delete`,
                                            null
                                        )
                                    }
                                >
                                    <i className="bi bi-trash"></i>
                                </IconButton>
                            </FWTooltip>
                            &nbsp;
                            <FWTooltip
                                title={
                                    email.is_public
                                        ? 'Visible to FW community'
                                        : 'Not visible to FW community'
                                }
                            >
                                <IconButton
                                    size="small"
                                    color="primary"
                                    onClick={() =>
                                        props.updateDetails(
                                            `email_${idx}_visible`,
                                            null
                                        )
                                    }
                                >
                                    {email.is_public ? (
                                        <i className="bi bi-eye"></i>
                                    ) : (
                                        <i className="bi bi-eye-slash"></i>
                                    )}
                                </IconButton>
                            </FWTooltip>
                        </Box>
                    ))}
            </Box> */}
            {props.managersList.length > 0 && (
                <Box gridColumn={'1 / span 2'}>
                    <FWTypography color="text.secondary">Managers</FWTypography>
                    <FWTypography>
                        {
                            props.managersList.map(
                                (manager_details) =>
                                    `${manager_details.parent_user_name}`
                            )
                            /* .join(', ') */
                        }
                    </FWTypography>
                </Box>
            )}
        </>
    );
}
