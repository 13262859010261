import { Box, DialogContent, InputAdornment, TextField } from '@mui/material';
import { debounce } from 'lodash';
import React, { useEffect, useMemo } from 'react';
import FWAutocomplete from '../../../Common/FWAutocomplete';
import { FWMenuItem } from '../../../Common/FWCustomMenu';
import FWErrorRipple from '../../../Common/FWErrorRipple';
import { FWLink } from '../../../Common/FWLink';
import { FWPopupWithChildren } from '../../../Common/FWPopupWithChildren';
import { FWTypography } from '../../../Common/FWTypograhy';
import { AuthContext } from '../../../Contexts/AuthContext';
import ItemAddContainer from '../../../Organizations/Admin/Containers/ItemDirectory/ItemAddContainer';
import PreferredVendorsContainer from '../../../Organizations/Admin/Containers/ItemDirectory/PreferredVendorsContainer';
import { useGetAdminSettings } from '../../../Organizations/Admin/Hooks/AdminSettingsHook';
import {
    IVendorsListProviders,
    useVendorsList,
} from '../../../Organizations/Admin/Hooks/Vendors/VendorsListHook';
import { ASModuleSetting } from '../../../Organizations/Admin/Interfaces/AdminSettingsInterface';
import { StyledRFQItemNumberDiv } from '../../Constants/Buyer/RFQHelperFunctions';
import { itemIsDisabled } from '../../Containers/Buyer/AddBuyerMasterItemContainer';
import useEventOnboardingChecklist from '../../Hooks/useEventOnboardingChecklist';
import { IBuyerItemList } from '../../Interfaces/Buyer/RFQ.model';
import { OnboardingCheckListSubMenuKeys } from '../../Slices/OnBoardingEventChecklist';

interface IBlankItemSelectionTemplateProps {
    sortedBuyerMasterItemList: IBuyerItemList[];
    hasMoreItemsinList: boolean;
    openAddItemContainer: boolean;
    item_count: number;
    fetchAndLoadSelectedItemDetails: (
        enterprise_item_id: string,
        isFinishedGood: boolean
    ) => void;
    disabled: boolean;
    entity_id: string;
    setLatestItemIndexAddedInThisSession: (index: number) => void;
    onShowMoreItems: () => void;
    onSearchItemList: (search_text: string) => void;
}

//  TODO: disabled on SANDBOX_DEPLOY
const DEFAULT_ADD_OPTION: IBuyerItemList = {
    entity_item_id: '',
    preferred_vendors_count: 0,
    preferred_vendors: [],
    enterprise_item: {
        item_type: 'RAW_MATERIAL',
        enterprise_item_id: '',
        buyer_additional_costs: [],
        seller_additional_costs: [],
        code: '',
        description: '',
        attributes: [],
        custom_ids: {
            custom_ids: [],
        },
        measurement_units: {
            item_measurement_units: [],
        },
        name: '',
        status: 'ACTIVE',
        notes: '',
        buyer_enterprise: '',
        tags: [],
        buyer_pricing_information: null,
        seller_pricing_information: null,
    },
    status: 'ACTIVE',
    buyer_enterprise: '',
    buyer_entity: '',
};

export default function BlankItemSelectionTemplate(
    props: IBlankItemSelectionTemplateProps
) {
    const { onShowMoreItems } = props;
    const [customAllowAddingItemSetting] = useGetAdminSettings(
        props.entity_id,
        ASModuleSetting.EVENT_ALLOW_ADDING_ITEMS
    );
    const { authData } = React.useContext(AuthContext);
    const [searchText, setSearchText] = React.useState('');
    const [selectedItem, setSelectedItem] =
        React.useState<IBuyerItemList | null>(null);
    const [addMasterItem, setAddMasterItem] = React.useState(false);

    const [addPreferredVendors, setAddPreferredVendors] = React.useState(false);
    const [addedItem, setAddedItem] = React.useState<string | null>(null);

    const { vendorsList }: IVendorsListProviders = useVendorsList();

    const onItemAdded = (
        new_enterprise_item_id: string,
        isFinishedGood: boolean
    ) => {
        setAddMasterItem(false);
        if (vendorsList.length > 0) {
            setAddPreferredVendors(true);
            setAddedItem(new_enterprise_item_id);
        } else {
            props.fetchAndLoadSelectedItemDetails(
                new_enterprise_item_id,
                isFinishedGood
            );
            props.setLatestItemIndexAddedInThisSession(props.item_count);
        }
    };

    React.useEffect(() => {
        if (props.openAddItemContainer) {
            setSelectedItem(null);
            setAddMasterItem(false);
            setSearchText('');
        }
    }, [props.openAddItemContainer]);

    const OPTIONS = useMemo(() => {
        /* DISABLED FOR SANDBOX_DEPLOY */
        return !props.hasMoreItemsinList &&
            ((authData.details && authData.details.role === 'ADMIN') ||
                customAllowAddingItemSetting.enabled)
            ? [...props.sortedBuyerMasterItemList, DEFAULT_ADD_OPTION]
            : props.sortedBuyerMasterItemList;
    }, [
        authData.details,
        customAllowAddingItemSetting,
        props.sortedBuyerMasterItemList,
        props.hasMoreItemsinList,
    ]);

    const debounceSearch = useMemo(
        () =>
            debounce(async (searchText: string) => {
                props.onSearchItemList(searchText);
            }, 500),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );
    useEffect(() => {
        debounceSearch(searchText);
        //Only want it to work on text change
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchText]);
    const { getLatestOnOnboardingChecklist } = useEventOnboardingChecklist();

    return (
        <>
            <StyledRFQItemNumberDiv
                width={{
                    xs: '10rem',
                    sm: '30rem',
                    lg: '80%',
                }}
            >
                <FWTypography className="itemBoxHeaderNumber">
                    {props.item_count + 1 < 10
                        ? '0' + (props.item_count + 1)
                        : props.item_count + 1}
                </FWTypography>
                <FWAutocomplete
                    disabled={props.disabled}
                    fullWidth
                    role="list-box"
                    clearOnBlur={true}
                    // option to show when user enters something not in the list
                    //  TODO: disabled on SANDBOX_DEPLOY
                    noOptionsText={
                        /* DISABLED FOR SANDBOX_DEPLOY */
                        (authData.details &&
                            authData.details.role === 'ADMIN') ||
                        customAllowAddingItemSetting.enabled ? (
                            <FWLink
                                onClick={() => {
                                    setAddMasterItem(true);
                                }}
                            >
                                Add {searchText} to directory
                            </FWLink>
                        ) : (
                            <FWTypography>No items found</FWTypography>
                        )
                    }
                    value={selectedItem}
                    onChange={(event, value) => {
                        if (value === null) {
                            setSelectedItem(null);
                        } else if (value.entity_item_id !== '') {
                            setSelectedItem(value);
                            props.fetchAndLoadSelectedItemDetails(
                                value.enterprise_item.enterprise_item_id,
                                value.enterprise_item.item_type ===
                                    'FINISHED_GOOD'
                            );
                            props.setLatestItemIndexAddedInThisSession(
                                props.item_count
                            );
                        } else {
                            setAddMasterItem(true);
                        }
                    }}
                    onInputChange={(event, value) => {
                        if (event === null) return;
                        setSearchText(value);
                    }}
                    popupIcon={
                        <Box position={'relative'}>
                            {getLatestOnOnboardingChecklist() ===
                                OnboardingCheckListSubMenuKeys.EVENT_ITEMS && (
                                <FWErrorRipple
                                    top="0"
                                    left="0"
                                    color="primary"
                                />
                            )}
                            <Box fontSize="1rem">
                                <i className="bi bi-chevron-down"></i>
                            </Box>
                        </Box>
                    }
                    options={OPTIONS}
                    getOptionLabel={(option) => {
                        if (option.entity_item_id !== '') {
                            let specsString =
                                ' | ' +
                                option.enterprise_item.attributes
                                    .map(
                                        (attr: any) =>
                                            `${
                                                attr.attribute_name
                                            }: ${attr.attribute_values
                                                .map(
                                                    (value: any) => value.value
                                                )
                                                .join(' / ')}`
                                    )
                                    .join(' | ');
                            return `${option.enterprise_item.name}${specsString} | ${option.enterprise_item.code}`;
                        } else {
                            return `Add ${
                                searchText === ''
                                    ? 'new item'
                                    : `"${searchText}"`
                            } to directory`;
                        }
                    }}
                    renderOption={(props, option: IBuyerItemList) => {
                        const isShowMore = option.hasNext;
                        const isDisabled = itemIsDisabled(option);
                        const isAddNewOption =
                            !option.hasNext && option.entity_item_id === '';
                        let specsString =
                            isAddNewOption ||
                            option.enterprise_item.attributes.length === 0
                                ? ''
                                : ' | ' +
                                  option.enterprise_item.attributes
                                      .map(
                                          (attr: any) =>
                                              `${
                                                  attr.attribute_name
                                              }: ${attr.attribute_values
                                                  .map(
                                                      (value: any) =>
                                                          value.value
                                                  )
                                                  .join(' / ')}`
                                      )
                                      .join(' | ');
                        return (
                            <FWMenuItem
                                {...props}
                                disabled={isDisabled}
                                sx={{
                                    fontStyle: isDisabled
                                        ? 'italic'
                                        : undefined,
                                    color: isDisabled
                                        ? 'text.secondary'
                                        : isAddNewOption
                                        ? 'primary.main'
                                        : undefined,
                                }}
                                role="list-box"
                            >
                                {isAddNewOption ? (
                                    <FWTypography
                                        sx={{
                                            wordWrap: 'break-word',
                                            whiteSpace: 'pre-wrap',
                                        }}
                                    >
                                        {`Add ${
                                            searchText === ''
                                                ? 'new item'
                                                : `"${searchText}"`
                                        } to directory`}
                                    </FWTypography>
                                ) : isShowMore ? (
                                    <FWTypography
                                        sx={{
                                            wordWrap: 'break-word',
                                            whiteSpace: 'pre-wrap',
                                            color: 'primary.main',
                                            width: '100%',
                                        }}
                                        role="list-box"
                                        onClick={(e: React.MouseEvent) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            onShowMoreItems();
                                        }}
                                    >
                                        Show more results...
                                    </FWTypography>
                                ) : (
                                    <FWTypography
                                        sx={{
                                            wordWrap: 'break-word',
                                            whiteSpace: 'pre-wrap',
                                        }}
                                        role="list-box"
                                    >
                                        {`${isDisabled ? '(Disabled) ' : ''}${
                                            option.enterprise_item.name
                                        } ${specsString} | ${
                                            option.enterprise_item.code
                                        }`}
                                    </FWTypography>
                                )}
                            </FWMenuItem>
                        );
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            InputProps={{
                                ...params.InputProps,
                                startAdornment: (
                                    <Box marginLeft={1}>
                                        <InputAdornment position="start">
                                            <i className="bi bi-search"></i>
                                        </InputAdornment>
                                    </Box>
                                ),
                            }}
                        />
                    )}
                    filterOptions={(options, state) => {
                        return options;
                    }}
                />
            </StyledRFQItemNumberDiv>
            {/* NOTE: this is done this way to avoid API calls from ItemAddContainer to happen */}
            {addMasterItem && (
                <FWPopupWithChildren
                    size={'large'}
                    open={addMasterItem}
                    handleClose={() => setAddMasterItem(false)}
                    title={'Add item'}
                >
                    <DialogContent>
                        <div
                            style={{
                                marginRight: '-15px',
                            }}
                        >
                            <ItemAddContainer
                                onSuccess={(
                                    new_enterprise_item_id: string,
                                    isFinishedGood: boolean
                                ) => {
                                    onItemAdded(
                                        new_enterprise_item_id,
                                        isFinishedGood
                                    );
                                }}
                                onCancel={() => setAddMasterItem(false)}
                                event
                                defaultItemName={searchText.split('"')[1]}
                                entityIDToLink={props.entity_id}
                                shouldRedirect={false}
                            />
                        </div>
                    </DialogContent>
                </FWPopupWithChildren>
            )}
            {addPreferredVendors && addedItem && (
                <FWPopupWithChildren
                    size={'large'}
                    open={addPreferredVendors}
                    handleClose={() => setAddPreferredVendors(false)}
                    title={'Add preferred vendors'}
                    showClose={true}
                >
                    <DialogContent>
                        <div
                            style={{
                                marginRight: '-15px',
                            }}
                        >
                            <PreferredVendorsContainer
                                onSaveSuccess={() => {
                                    setAddPreferredVendors(false);
                                    props.fetchAndLoadSelectedItemDetails(
                                        addedItem,
                                        false
                                    );
                                    props.setLatestItemIndexAddedInThisSession(
                                        props.item_count
                                    );
                                }}
                                entityUid={props.entity_id}
                                itemUid={addedItem}
                                requireOnePreferredVendor
                            />
                        </div>
                    </DialogContent>
                </FWPopupWithChildren>
            )}
        </>
    );
}
