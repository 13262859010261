import { cloneDeep } from 'lodash';
import { isValueValidForStringArrNull } from '../../Global/Helpers/helpers';
import {
    DELIVERABLE_LENGTH_LIMIT,
    transformPaymentTermsFromBackendToFrontend,
    validatePaymentTerms,
} from '../../Global/Helpers/paymentTermsHelpers';
import {
    ContractDetailsErrors,
    IndividualValidationDetailsNew,
    ItemDetailsErrors,
    ItemDetailsNewErrors,
    paymentTermErrors,
    VendorDetailsErrors,
} from '../Interface/CLMErrorsInterface';
import { IContractItemDetailsNew } from '../Interface/ContractInterfaces';
import {
    CLMNewTemplateStandardFieldsEnum,
    ICLMNewTemplate,
} from '../Interface/NewCLMTemplateInterface';
import {
    IBuyerDetails,
    IContractDetails,
    IVendorDetails,
} from '../Slices/CLMSlice';

export const validateContractDetails = ({
    currentErrors,
    contractDetails,
    buyerDetails,
    validationType,
    template,
    project,
    customFields,
}: {
    currentErrors: ContractDetailsErrors;
    contractDetails: IContractDetails;
    buyerDetails: IBuyerDetails;
    validationType: {
        fieldType: 'ALL' | 'STANDARD' | 'CUSTOM';
        field: string;
        visible: boolean;
    };
    template: ICLMNewTemplate | null;
    project: {
        projectCode: string;
        projectName: string;
        projectId: string;
    };
    customFields: any;
}) => {
    const newErrors = cloneDeep(currentErrors);

    const validateAll = validationType.fieldType === 'ALL';
    const standardFieldToValidate =
        validationType.fieldType === 'STANDARD' ? validationType.field : null;
    const customFieldToValidate =
        validationType.fieldType === 'CUSTOM' ? validationType.field : null;

    const visible = validationType.visible;

    if (validateAll || standardFieldToValidate === 'contract_name') {
        if (
            newErrors.contract_name?.message !== 'Contract name already exists'
        ) {
            newErrors.contract_name = null;
            const trimmedName = contractDetails.contractName.trim();

            if (!trimmedName) {
                newErrors.contract_name = {
                    validationType: 'ERROR',
                    message: 'Input required',
                    visible,
                };
            }
        }
    }

    if (
        validateAll ||
        ['contract_start_date', 'contract_end_date'].includes(
            standardFieldToValidate ?? ''
        )
    ) {
        const settings =
            template?.fieldSettings.standardFields.CONTRACT_START_DATE;

        newErrors.contract_start_date = null;
        if (settings?.buyerSettings.showField) {
            if (
                settings.buyerSettings.required &&
                !contractDetails.contractStartDate
            ) {
                newErrors.contract_start_date = {
                    validationType: 'ERROR',
                    message: 'Input required',
                    visible,
                };
            }
            // TODO: add more, e.g. length, non-duplicate, etc.
        }
    }
    if (
        validateAll ||
        ['contract_start_date', 'contract_end_date'].includes(
            standardFieldToValidate ?? ''
        )
    ) {
        const settings =
            template?.fieldSettings.standardFields.CONTRACT_END_DATE;

        newErrors.contract_end_date = null;
        if (settings?.buyerSettings.showField) {
            if (
                settings.buyerSettings.required &&
                !contractDetails.contractEndDate
            ) {
                newErrors.contract_end_date = {
                    validationType: 'ERROR',
                    message: 'Input required',
                    visible,
                };
            } else if (
                contractDetails.contractStartDate >
                contractDetails.contractEndDate
            ) {
                newErrors.contract_end_date = {
                    validationType: 'ERROR',
                    message: 'End date must be after start date',
                    visible,
                };
            }

            // TODO: add more, e.g. length, non-duplicate, etc.
        }
    }
    if (validateAll || standardFieldToValidate === 'buyer_identifications_id') {
        const settings =
            template?.fieldSettings.standardFields.BUYER_IDENTIFICATION;

        newErrors.buyer_identifications_id = null;
        if (settings?.buyerSettings.showField) {
            if (
                settings.buyerSettings.required &&
                !buyerDetails.buyerIdentificationId
            ) {
                newErrors.buyer_identifications_id = {
                    validationType: 'ERROR',
                    message: 'Input required',
                    visible,
                };
            }
            // TODO: add more, e.g. length, non-duplicate, etc.
        }
    }

    if (validateAll || standardFieldToValidate === 'buyer_contact_id') {
        const settings = template?.fieldSettings.standardFields.BUYER_CONTACT;

        newErrors.buyer_contact_id = null;
        if (settings?.buyerSettings.showField) {
            if (
                settings.buyerSettings.required &&
                !buyerDetails.buyerContactId
            ) {
                newErrors.buyer_contact_id = {
                    validationType: 'ERROR',
                    message: 'Input required',
                    visible,
                };
            }
            // TODO: add more, e.g. length, non-duplicate, etc.
        }
    }

    if (validateAll || standardFieldToValidate === 'buyer_address_id') {
        const settings = template?.fieldSettings.standardFields.BUYER_ADDRESS;

        newErrors.buyer_address_id = null;
        if (settings?.buyerSettings.showField) {
            if (
                settings.buyerSettings.required &&
                !buyerDetails.buyerAddressId
            ) {
                newErrors.buyer_address_id = {
                    validationType: 'ERROR',
                    message: 'Input required',
                    visible,
                };
            }
            // TODO: add more, e.g. length, non-duplicate, etc.
        }
    }

    if (validateAll || standardFieldToValidate === 'project_id') {
        const settings = template?.fieldSettings.standardFields.PROJECT;

        newErrors.project_id = null;
        if (settings?.buyerSettings.showField) {
            if (settings.buyerSettings.required && !project.projectId) {
                newErrors.project_id = {
                    validationType: 'ERROR',
                    message: 'Input required',
                    visible,
                };
            }
            // TODO: add more, e.g. length, non-duplicate, etc.
        }
    }
    // Custom fields
    if (validateAll || customFieldToValidate) {
        for (let sec of customFields.section_list) {
            if (sec.name === 'contract_details') {
                for (let cf of sec.fields) {
                    const { name, value } = cf;
                    const settings = template?.fieldSettings.customFields[name];
                    // If settings don't exist then it is 'on the fly' so assume it is shown and required

                    const showField = settings
                        ? settings.buyerSettings.showField
                        : true;
                    const required = settings
                        ? settings.buyerSettings.required
                        : true;

                    newErrors.custom_fields[name] = null;

                    if (!showField) {
                        continue;
                    } else if (
                        required &&
                        !isValueValidForStringArrNull(value)
                    ) {
                        newErrors.custom_fields[name] = {
                            validationType: 'ERROR',
                            message: 'Input required',
                            visible,
                        };
                        continue;
                    }

                    // TODO: Any others from use cases, e.g. length.
                }
            }
        }
    }
    return newErrors;
};

export const ItemDetailsErrorsTemplate = {
    item_currency_code: null,
    item_pricing_value: null,
    item_quantity: null,
    item_measurement_unit_id: null,
    lead_time: null,
    lead_time_period: null,
    incoterm_id: null,
    custom_fields: {},
    item_payment_terms: {
        paymentTerms_prepayment: null,
        paymentTerms_invoiceItem_terms: null,
        paymentTerms_invoiceItem_period: null,
        paymentTerms_invoiceItem_appliedFrom: null,
        paymentTerms_poItem_overallPercentage: null,
        paymentTerms_poItem_allocationRows: [],
    },
    additional_costs: null,
    discounts: null,
    taxes: null,
} as ItemDetailsNewErrors;

export const getUpdatedItemDetailsErrors = ({
    currentErrors,
    item,
    template,
    validationType,
    index,
}: {
    currentErrors: ItemDetailsNewErrors;
    item: IContractItemDetailsNew;
    template: ICLMNewTemplate;
    validationType:
        | {
              field_type: 'STANDARD';
              field: keyof IContractItemDetailsNew | keyof ItemDetailsErrors;
              visible: boolean;
          }
        | {
              field_type: 'CUSTOM';
              field: string;
              visible: boolean;
          }
        | {
              field_type: 'ALL';
              visible: boolean;
          };
    index: number;
}): ItemDetailsNewErrors => {
    const newErrors = cloneDeep(currentErrors);
    const validateAll = validationType.field_type === 'ALL';
    const standardFieldToValidate =
        validationType.field_type === 'STANDARD' ? validationType.field : null;
    const customFieldToValidate =
        validationType.field_type === 'CUSTOM' ? validationType.field : null;

    const visible = validationType.visible;

    const shouldCheckPrice =
        template.fieldSettings.standardFields.PRICE.buyerSettings.showField &&
        template.fieldSettings.standardFields.PRICE.buyerSettings.required;

    const shouldCheckQuantity =
        template.fieldSettings.standardFields.QUANTITY.buyerSettings
            .showField &&
        template.fieldSettings.standardFields.QUANTITY.buyerSettings.required;

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const shouldCheckPrepayment =
        template.fieldSettings.standardFields.PAYMENT_TERMS.buyerSettings
            .showField &&
        template.fieldSettings.standardFields.PAYMENT_TERMS.paymentTermOptions
            .prepaymentAllowed;

    const templatePaymentTermsOptions =
        template.fieldSettings.standardFields.PAYMENT_TERMS;
    const ptValidation = validatePaymentTerms({
        paymentTerms: item.item_paymentTerms!,
        prepaymentAllowed:
            templatePaymentTermsOptions.paymentTermOptions.prepaymentAllowed,
    });

    const shouldCheckAdditionalCosts =
        template.fieldSettings.standardFields.ADDITIONAL_COSTS.buyerSettings
            .showField;
    const shouldCheckDiscounts =
        template.fieldSettings.standardFields.ADDITIONAL_COSTS.buyerSettings
            .showField;
    const shouldCheckTaxes =
        template.fieldSettings.standardFields.ADDITIONAL_COSTS.buyerSettings
            .showField;

    const shouldCheckLeadTerms =
        template.fieldSettings.standardFields.LEAD_TIME.buyerSettings
            .showField &&
        template.fieldSettings.standardFields.LEAD_TIME.buyerSettings.required;

    const shouldCheckIncoterms =
        template.fieldSettings.standardFields.INCOTERMS.buyerSettings
            .showField &&
        template.fieldSettings.standardFields.INCOTERMS.buyerSettings.required;

    if (
        template.fieldSettings.standardFields[
            CLMNewTemplateStandardFieldsEnum.PAYMENT_TERMS
        ].buyerSettings.showField &&
        template.sections.PAYMENT_AND_DELIVERY_TERMS.fieldList.some(
            (field) =>
                field.field === CLMNewTemplateStandardFieldsEnum.PAYMENT_TERMS
        ) &&
        (validateAll || standardFieldToValidate === 'item_payment_terms') &&
        newErrors &&
        newErrors.item_payment_terms
    ) {
        Object.keys(newErrors.item_payment_terms).forEach((key) => {
            delete newErrors.item_payment_terms[key as keyof paymentTermErrors];
        });

        newErrors.item_payment_terms.paymentTerms_poItem_allocationRows = [];

        if (ptValidation.overallErrors.prepaymentBlank) {
            newErrors.item_payment_terms.paymentTerms_prepayment = {
                validationType: 'ERROR',
                fieldMessage: 'Input required',
                popupMessage: 'Prepayment input required',
                visible,
            };
        } else if (ptValidation.overallErrors.prepaymentAbove100) {
            newErrors.item_payment_terms.paymentTerms_prepayment = {
                validationType: 'ERROR',
                fieldMessage: 'Cannot exceed 100%',
                popupMessage: 'Invalid prepayment percentage',
                visible,
            };
        }
        if (ptValidation.overallErrors.termsBlank) {
            newErrors.item_payment_terms.paymentTerms_invoiceItem_terms = {
                validationType: 'ERROR',
                fieldMessage: 'Input required',
                popupMessage: 'Payment terms input required',
                visible,
            };
        }
        if (ptValidation.overallErrors.periodBlank) {
            newErrors.item_payment_terms.paymentTerms_invoiceItem_period = {
                validationType: 'ERROR',
                fieldMessage: 'Input required',
                popupMessage: 'Payment terms period input required',
                visible,
            };
        }
        if (ptValidation.overallErrors.appliedFromBlank) {
            newErrors.item_payment_terms.paymentTerms_invoiceItem_appliedFrom =
                {
                    validationType: 'ERROR',
                    fieldMessage: 'Input required',
                    popupMessage: 'Payment terms reference date required',
                    visible,
                };
        }
        if (ptValidation.overallErrors.allocationNot100) {
            const msg =
                +(item.item_paymentTerms?.prepaymentPercentage ?? '0') > 0
                    ? 'Allocations and prepayment must add up to 100%'
                    : 'Allocations must add up to 100%';
            newErrors.item_payment_terms.paymentTerms_poItem_overallPercentage =
                {
                    validationType: 'ERROR',
                    fieldMessage: msg,
                    popupMessage: msg,
                    visible,
                };
        }
        for (let rowIdx = 0; rowIdx < ptValidation.rowErrors.length; rowIdx++) {
            const curRowErrors: {
                rowAllocationPercentage?: IndividualValidationDetailsNew;
                rowTerms?: IndividualValidationDetailsNew;
                rowPeriod?: IndividualValidationDetailsNew;
                rowDeliverableName?: IndividualValidationDetailsNew;
                rowDate?: IndividualValidationDetailsNew;
            } = {};
            if (ptValidation.rowErrors[rowIdx].allocationBlank) {
                curRowErrors.rowAllocationPercentage = {
                    fieldMessage: 'Input required',
                    popupMessage: 'Payment terms invalid',

                    validationType: 'ERROR',
                    visible,
                };
            } else if (ptValidation.rowErrors[rowIdx].allocationOver100) {
                curRowErrors.rowAllocationPercentage = {
                    fieldMessage: 'Cannot exceed 100',
                    popupMessage: 'Payment terms invalid',

                    validationType: 'ERROR',
                    visible,
                };
            }
            if (ptValidation.rowErrors[rowIdx].dateBlank) {
                curRowErrors.rowDate = {
                    fieldMessage: 'Input required',
                    popupMessage: 'Payment terms invalid',

                    validationType: 'ERROR',
                    visible,
                };
            } else if (ptValidation.rowErrors[rowIdx].datePast) {
                curRowErrors.rowDate = {
                    fieldMessage: 'Input invalid',
                    popupMessage: 'Payment terms invalid',

                    validationType: 'ERROR',
                    visible,
                };
            }
            if (ptValidation.rowErrors[rowIdx].termsBlank) {
                curRowErrors.rowTerms = {
                    fieldMessage: 'Input required',
                    popupMessage: 'Payment terms invalid',

                    validationType: 'ERROR',
                    visible,
                };
            }
            if (ptValidation.rowErrors[rowIdx].periodBlank) {
                curRowErrors.rowPeriod = {
                    fieldMessage: 'Input required',
                    popupMessage: 'Payment terms invalid',

                    validationType: 'ERROR',
                    visible,
                };
            }
            if (ptValidation.rowErrors[rowIdx].deliverableBlank) {
                curRowErrors.rowDeliverableName = {
                    fieldMessage: 'Input required',
                    popupMessage: 'Payment terms invalid',

                    validationType: 'ERROR',
                    visible,
                };
            } else if (ptValidation.rowErrors[rowIdx].deliverableTooLong) {
                curRowErrors.rowDeliverableName = {
                    fieldMessage: `Cannot exceed ${DELIVERABLE_LENGTH_LIMIT} characters`,
                    popupMessage: 'Payment terms invalid',

                    validationType: 'ERROR',
                    visible,
                };
            } else if (ptValidation.rowErrors[rowIdx].deliverableInvalid) {
                curRowErrors.rowDeliverableName = {
                    fieldMessage: 'Input invalid',
                    popupMessage: 'Payment terms invalid',

                    validationType: 'ERROR',
                    visible,
                };
            }

            newErrors.item_payment_terms.paymentTerms_poItem_allocationRows?.push(
                curRowErrors
            );
        }
    }
    if (validateAll) {
        if (shouldCheckAdditionalCosts) {
            const requiredAdditionalCost =
                template.fieldSettings.standardFields.ADDITIONAL_COSTS.additionalCostOptions.filter(
                    (opt) => opt.is_required
                );
            if (requiredAdditionalCost.length > 0) {
                const isThereError = requiredAdditionalCost.reduce(
                    (acc, additionalCostopt) => {
                        const foundItemAdditionalCost =
                            item.additional_costs.find(
                                (costAdditional) =>
                                    costAdditional.cost_name.trim() ===
                                    additionalCostopt.costName.trim()
                            );

                        if (foundItemAdditionalCost) {
                            if (
                                typeof foundItemAdditionalCost.cost_value ===
                                    'string' &&
                                foundItemAdditionalCost.cost_value.trim() === ''
                            ) {
                                return acc || true;
                            } else {
                                return acc || false;
                            }
                        } else {
                            return acc || true;
                        }
                    },
                    false
                );
                if (isThereError) {
                    newErrors.additional_costs = {
                        validationType: 'ERROR',
                        fieldMessage: 'Additional cost is required',
                        popupMessage: 'Additional cost is required',
                        visible: true,
                    };
                } else newErrors.additional_costs = null;
            } else {
                newErrors.additional_costs = null;
            }
        }

        if (shouldCheckTaxes) {
            const requiredAdditionalCost =
                template.fieldSettings.standardFields.TAX.taxesOptions.filter(
                    (opt) => opt.is_required
                );
            if (requiredAdditionalCost.length > 0) {
                const isThereError = requiredAdditionalCost.reduce(
                    (acc, additionalCostopt) => {
                        const foundItemAdditionalCost = item.taxes.find(
                            (costAdditional) =>
                                costAdditional.cost_name.trim() ===
                                additionalCostopt.costName.trim()
                        );

                        if (foundItemAdditionalCost) {
                            if (
                                typeof foundItemAdditionalCost.cost_value ===
                                    'string' &&
                                foundItemAdditionalCost.cost_value.trim() === ''
                            ) {
                                return acc || true;
                            } else {
                                return acc || false;
                            }
                        } else {
                            return acc || true;
                        }
                    },
                    false
                );
                if (isThereError) {
                    newErrors.taxes = {
                        validationType: 'ERROR',
                        fieldMessage: 'Taxes is required',
                        popupMessage: 'Taxes is required',
                        visible: true,
                    };
                } else newErrors.taxes = null;
            } else {
                newErrors.taxes = null;
            }
        }
        if (shouldCheckDiscounts) {
            const requiredAdditionalCost =
                template.fieldSettings.standardFields.DISCOUNT.discountOptions.filter(
                    (opt) => opt.is_required
                );
            if (requiredAdditionalCost.length > 0) {
                const isThereError = requiredAdditionalCost.reduce(
                    (acc, additionalCostopt) => {
                        const foundItemAdditionalCost = item.discounts.find(
                            (costAdditional) =>
                                costAdditional.cost_name.trim() ===
                                additionalCostopt.costName.trim()
                        );

                        if (foundItemAdditionalCost) {
                            if (
                                typeof foundItemAdditionalCost.cost_value ===
                                    'string' &&
                                foundItemAdditionalCost.cost_value.trim() === ''
                            ) {
                                return acc || true;
                            } else {
                                return acc || false;
                            }
                        } else {
                            return acc || true;
                        }
                    },
                    false
                );
                if (isThereError) {
                    newErrors.discounts = {
                        validationType: 'ERROR',
                        fieldMessage: 'Discount is required',
                        popupMessage: 'Discount is required',
                        visible: true,
                    };
                } else newErrors.discounts = null;
            } else {
                newErrors.discounts = null;
            }
        }
    }

    if (validateAll || standardFieldToValidate === 'item_currency_code') {
        newErrors.item_currency_code = null;
        if (shouldCheckPrice && !item.item_currency_code) {
            newErrors.item_currency_code = {
                validationType: 'ERROR',
                fieldMessage: 'Input required',
                popupMessage: 'Input required',
                visible,
            };
        }
    }

    if (validateAll || standardFieldToValidate === 'item_pricing_value') {
        newErrors.item_pricing_value = null;

        if (shouldCheckPrice) {
            if (!item.item_pricing_value) {
                newErrors.item_pricing_value = {
                    validationType: 'ERROR',
                    fieldMessage: 'Input required',
                    popupMessage: 'Input required',
                    visible,
                };
            } else if (+item.item_pricing_value === 0) {
                newErrors.item_pricing_value = {
                    validationType: 'ERROR',
                    fieldMessage: 'Value should be greater than 0',
                    popupMessage: 'Value should be greater than 0',
                    visible,
                };
            }
        }
    }

    if (validateAll || standardFieldToValidate === 'item_measurement_unit_id') {
        newErrors.item_measurement_unit_id = null;

        if (shouldCheckPrice && !item.item_measurement_unit_id) {
            newErrors.item_measurement_unit_id = {
                validationType: 'ERROR',
                fieldMessage: 'Input required',
                popupMessage: 'Input required',
                visible,
            };
        }
    }

    if (validateAll || standardFieldToValidate === 'item_quantity') {
        newErrors.item_quantity = null;
        if (shouldCheckQuantity) {
            let fieldMessage: string | null = null;
            let popupMessage: string | null = null;
            if (!item.item_quantity || item.item_quantity === '') {
                fieldMessage = 'Input required';
                popupMessage = 'Quantity input required';
            } else if (+item.item_quantity === 0) {
                fieldMessage = 'Must be greater than 0';
                popupMessage = 'Invalid quantity';
            }

            if (fieldMessage && popupMessage) {
                newErrors.item_quantity = {
                    validationType: 'ERROR',
                    fieldMessage: fieldMessage,
                    popupMessage: popupMessage,
                    visible,
                };
            }
        }
    }

    if (validateAll || standardFieldToValidate === 'lead_time') {
        newErrors.lead_time = null;
        if (shouldCheckLeadTerms) {
            if (item.lead_time === '') {
                newErrors.lead_time = {
                    validationType: 'ERROR',
                    fieldMessage: 'Input required',
                    popupMessage: 'Lead time input required',
                    visible,
                };
            } else if (+item.lead_time === 0) {
                newErrors.lead_time = {
                    validationType: 'ERROR',
                    fieldMessage: 'Lead time should be greater than 0',
                    popupMessage: 'Lead time should be greater than 0',
                    visible,
                };
            }
        }
    }
    if (validateAll || standardFieldToValidate === 'incoterm_id') {
        newErrors.incoterm_id = null;
        if (shouldCheckIncoterms) {
            if (item.lead_time === '') {
                newErrors.incoterm_id = {
                    validationType: 'ERROR',
                    fieldMessage: 'Input required',
                    popupMessage: 'Incoterms required',
                    visible,
                };
            }
        }
    }

    if (validateAll || standardFieldToValidate === 'lead_time_period') {
        newErrors.lead_time_period = null;

        if (shouldCheckLeadTerms && !item.lead_time_period) {
            newErrors.lead_time_period = {
                validationType: 'ERROR',
                fieldMessage: 'Input required',
                popupMessage: 'Input required',
                visible,
            };
        }
    }

    if (customFieldToValidate || validateAll) {
        for (let sec of item.item_custom_fields.section_list) {
            for (let cf of sec.fields) {
                const { name, value } = cf;
                if (validateAll || name === customFieldToValidate) {
                    newErrors.custom_fields[name] = null;

                    const settingsItems =
                        template.fieldSettings.customFields[cf.name];

                    const showFieldItems = settingsItems
                        ? settingsItems.buyerSettings.showField
                        : true;
                    const requiredItems = settingsItems
                        ? settingsItems.buyerSettings.required
                        : true;

                    if (!showFieldItems) {
                        continue;
                    } else if (
                        requiredItems &&
                        !isValueValidForStringArrNull(value)
                    ) {
                        newErrors.custom_fields[name] = {
                            validationType: 'ERROR',
                            fieldMessage: 'Input required',
                            popupMessage: name + ' required',
                            visible,
                        };
                        continue;
                    }

                    if (!validateAll) break;
                }
            }
        }
    }
    return newErrors;
};

export const convertBackendContractItemToFrontEnd = (backendItem: any) => {
    return {
        isDisabled: false,
        isItemCreated: true,
        item_paymentTerms: transformPaymentTermsFromBackendToFrontend({
            prepayment_percentage: backendItem.prepayment_percentage,
            payment_terms: backendItem.payment_terms,
            deliverables_payment_terms: backendItem.deliverables_payment_terms,
        }),
        item_uuid: backendItem.contract_item_id,
        item_attachments: backendItem.attachments,
        item_attributes: backendItem.attributes.map((att: any) => {
            return {
                attribute_name: att.attribute_name,
                attribute_values: att.attribute_values,
                // attribute_exclude: att.attribute_exclude,
            };
        }),
        additional_costs: backendItem.additional_costs,
        taxes: backendItem.taxes,
        discounts: backendItem.discounts,
        item_name: backendItem.enterprise_item_details.name,
        item_id: backendItem.enterprise_item_details.enterprise_item_id,
        item_description: backendItem.enterprise_item_details.description,
        item_code: backendItem.enterprise_item_details.code,
        item_tags: backendItem.tags ?? [],
        item_measurement_units:
            backendItem.enterprise_item_details.measurement_units
                .item_measurement_units,
        item_measurement_unit_id: backendItem.measurement_unit,
        item_measurement_unit_category: '',
        item_measurement_unit_value_type: '',
        item_measurement_unit_primary_name:
            backendItem.measurement_unit_details.measurement_unit_primary_name,
        item_currency_code: backendItem.pricing_information.currency_code_id,
        item_currency_abbreivation:
            backendItem.pricing_information.currency_code_abbreviation,
        item_currency_symbol: backendItem.pricing_information.currency_symbol,
        item_pricing_value: backendItem.pricing_information.desired_price,
        item_quantity: backendItem.quantity,
        lead_time: backendItem.procurement_information.lead_time,
        lead_time_period: backendItem.procurement_information.lead_time_period,
        item_custom_fields: backendItem.custom_fields,
        item_buyer_skus: backendItem.buyer_sku_details,
        item_payment_terms: backendItem.deliverables_payment_terms,
        incoterm_id: backendItem.incoterm,
    } as IContractItemDetailsNew;
};

export const getCustomFieldsForContractFromTemplate = (
    templateDetails: ICLMNewTemplate | null
) => {
    if (templateDetails) {
        let itemTerms = {
            name: 'essential_terms',
            fields: templateDetails.sections.ESSENTIAL_TERMS.fieldList
                .filter(
                    (fieldDetails: any) => fieldDetails.fieldType === 'CUSTOM'
                )
                .map((data) => {
                    let fieldDetails =
                        templateDetails.fieldSettings.customFields[
                            data.field as any
                        ];
                    return {
                        name: fieldDetails.label,
                        type: fieldDetails.type,
                        value: null,
                        is_locked: fieldDetails.buyerSettings.required,
                        is_visible: fieldDetails.buyerSettings.showField,
                        description: fieldDetails.description,
                        is_required: fieldDetails.buyerSettings.required,
                    };
                }),
        };
        let paymentTerms = {
            name: 'payment_terms',
            fields: templateDetails.sections.PAYMENT_AND_DELIVERY_TERMS.fieldList
                .filter(
                    (fieldDetails: any) => fieldDetails.fieldType === 'CUSTOM'
                )
                .map((data) => {
                    let fieldDetails =
                        templateDetails.fieldSettings.customFields[
                            data.field as any
                        ];
                    return {
                        name: fieldDetails.label,
                        type: fieldDetails.type,
                        value: null,
                        is_locked: fieldDetails.buyerSettings.required,
                        is_visible: fieldDetails.buyerSettings.showField,
                        is_required: fieldDetails.buyerSettings.required,
                        description: fieldDetails.description,
                    };
                }),
        };

        return [itemTerms, paymentTerms];
    }
    return null;
};

export const allItemDetailsAreValid = ({
    errorObj,
}: {
    errorObj: ItemDetailsNewErrors[];
}) => {
    let temp = errorObj.map((itemError) => {
        return validateSingleItemErrors(itemError);
    });
    let errArray = [];
    for (let i = 0; i < temp.length; i++) {
        if (temp[i] === false) {
            errArray.push(i + 1);
        }
    }
    let areItemsValid = {
        bool: temp.every((item) => item === true),
        errorInItemIndexes: errArray,
    };
    return areItemsValid;
};

export const getUpdatedVendorDetailsErrors = ({
    currentErrors,
    vendor,
    template,
    validationType,
}: {
    currentErrors: VendorDetailsErrors;
    vendor: IVendorDetails;
    template: ICLMNewTemplate;
    validationType:
        | {
              field_type: 'STANDARD';
              field: keyof IVendorDetails | keyof VendorDetailsErrors;
              visible: boolean;
          }
        | {
              field_type: 'CUSTOM';
              field: string;
              visible: boolean;
          }
        | {
              field_type: 'ALL';
              visible: boolean;
          };
}): VendorDetailsErrors => {
    const newErrors = cloneDeep(currentErrors);
    const validateAll = validationType.field_type === 'ALL';
    const standardFieldToValidate =
        validationType.field_type === 'STANDARD' ? validationType.field : null;
    const visible = validationType.visible;

    if (validateAll || standardFieldToValidate === 'vendor_entity_id') {
        newErrors.vendor_entity_id = null;
        const trimmedName = vendor.vendorEntityId.trim();

        if (!trimmedName) {
            newErrors.vendor_entity_id = {
                validationType: 'ERROR',
                message: 'Input required',
                visible,
            };
        }
    }

    if (validateAll || standardFieldToValidate === 'vendor_contact_id') {
        newErrors.vendor_contact_id = null;
        const trimmedName = vendor.vendorContactId.trim();
        if (trimmedName === '') {
            newErrors.vendor_contact_id = {
                validationType: 'ERROR',
                message: 'Input required',
                visible,
            };
        }
    }

    if (
        validateAll ||
        standardFieldToValidate === 'vendor_identifications_name'
    ) {
        newErrors.vendor_identifications_name = null;
        const trimmedName = vendor.vendorIdentificationName.trim();

        if (!trimmedName) {
            newErrors.vendor_identifications_name = {
                validationType: 'ERROR',
                message: 'Input required',
                visible,
            };
        }
    }

    return newErrors;
};

export const allVendorDetailsAreValid = ({
    errorObj,
    checkVisibility = false,
}: {
    errorObj: VendorDetailsErrors;
    checkVisibility?: boolean;
}) => {
    for (let key of Object.keys(errorObj)) {
        let typedKey = key as keyof VendorDetailsErrors;
        if (typedKey === 'custom_fields') {
            for (let fieldName of Object.keys(errorObj.custom_fields)) {
                let validationDetails = errorObj.custom_fields[fieldName];
                if (
                    validationDetails &&
                    validationDetails.validationType === 'ERROR'
                ) {
                    if (checkVisibility && !validationDetails.visible) {
                        return;
                    }
                    return false;
                }
            }
        } else {
            let validationDetails = errorObj[typedKey];
            if (
                validationDetails &&
                validationDetails.validationType === 'ERROR'
            ) {
                if (checkVisibility && !validationDetails.visible) {
                    return;
                }
                return false;
            }
        }
    }
    return true;
};

export const validateSingleItemErrors = (itemError: ItemDetailsNewErrors) => {
    let standardFieldsValid = true;
    for (let key in itemError) {
        if (key === 'custom_fields' || key === 'item_payment_terms') continue;

        if (itemError[key as keyof ItemDetailsNewErrors] !== null) {
            standardFieldsValid = false;
        }
    }
    let customFieldsValid = true;
    for (let key in itemError.custom_fields) {
        if (itemError.custom_fields[key] !== null) customFieldsValid = false;
    }
    let paymentTermsValid = true;
    if (Object.keys(itemError.item_payment_terms).length > 1)
        paymentTermsValid = false;
    return standardFieldsValid && customFieldsValid && paymentTermsValid;
};
