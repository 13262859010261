import { ColDef } from 'ag-grid-community';
import { FWTypography } from '../../Common/FWTypograhy';
import { FWNumberFormatter } from '../../Common/Utils/CurrencyUtils';
import CreditAmountCell from '../Components/CreditAmountCell';
import PaymentAmountCell from '../Components/PaymentAmountCell';
import {
    CreditDetailCell,
    MakePaymentsItemNameCell,
    PaymentItemDetailCell,
    PaymentItemTotalInfoCell,
    PaymentPendingInfoCell,
    PrepaymentInvoicesDetailCell,
} from '../Components/PaymentsCellRenderers';
import {
    PaymentItemType,
    TransactionType,
} from '../Interfaces/PaymentsInterface';

export const paymentItemsColumns: ColDef[] = [
    {
        field: 'selection',
        headerName: '',
        width: 50,
        cellRenderer: (params: any) => '',
        headerCheckboxSelection: true,
        checkboxSelection: true,
    },
    {
        field: 'remainingDays',
        headerName: 'Due in',
        width: 170,
        cellRenderer: (params: any) => (
            <>
                {params.data.paymentType === PaymentItemType.PREPAYMENT ? (
                    <FWTypography color="success.main">
                        {params.data.paymentType}
                    </FWTypography>
                ) : params.data.remainingDays === null ? (
                    <FWTypography>-</FWTypography>
                ) : params.data.remainingDays < 0 ? (
                    <FWTypography color={'error.main'}>Overdue</FWTypography>
                ) : (
                    <FWTypography
                        color={
                            params.data.remainingDays < 7
                                ? 'warning.main'
                                : 'success.main'
                        }
                    >
                        {params.data.remainingDays + ' day'}
                        {params.data.remainingDays === 1 ? '' : 's'}
                    </FWTypography>
                )}
            </>
        ),
        sortable: true,
        filter: true,
        valueGetter: (params) =>
            params.data.paymentType === PaymentItemType.PREPAYMENT
                ? params.data.paymentType
                : params.data.remainingDays + '',
        getQuickFilterText: (params) =>
            params.data.paymentType === PaymentItemType.PREPAYMENT
                ? params.data.paymentType
                : params.data.remainingDays + '',
        comparator: function (number1, number2) {
            const x = number1 === 'Prepayment' ? 0 : number1;
            const y = number2 === 'Prepayment' ? 0 : number2;
            return parseFloat(x) - parseFloat(y);
        },
    },
    // {
    //     field: 'invoiceId',
    //     headerName: 'Invoice ID',
    //     width: 180,
    //     cellRenderer: (params: any) => {
    //         return <FWTypography>{params.data.invoiceId}</FWTypography>;
    //     },
    //     sortable: true,
    //     filter: true,
    //     valueGetter: (params) => params.data.invoiceId,
    //     getQuickFilterText: (params) => params.data.invoiceId,
    // },
    {
        field: 'details',
        headerName: 'Details',
        headerClass: 'center',
        width: 90,
        cellRenderer: PaymentItemDetailCell,
    },
    {
        field: 'poId',
        headerName: 'PO ID',
        width: 150,
        cellRenderer: (params: any) => {
            return <FWTypography>{params.data.poId}</FWTypography>;
        },
        hide: true,
    },
    {
        field: 'itemName',
        headerName: 'Item name',
        minWidth: 180,
        flex: 1,
        cellRenderer: MakePaymentsItemNameCell,
        sortable: true,
        filter: true,
        valueGetter: (params) => params.data.itemDetails.itemName,
        getQuickFilterText: (params) => params.data.itemDetails.itemName,
    },
    {
        field: 'quantity',
        headerName: 'Quantity',
        headerClass: 'center',
        minWidth: 120,
        cellRenderer: (params: any) => {
            return (
                <FWTypography
                    sx={{
                        width: '100%',
                        textAlign: 'right',
                    }}
                >
                    {FWNumberFormatter(params.data.quantity)}{' '}
                    {params.data.measurementUnit}(s)
                </FWTypography>
            );
        },
        sortable: true,
        filter: true,
        valueGetter: (params) => parseFloat(params.data.quantity),
        getQuickFilterText: (params) => params.data.quantity,
    },
    {
        field: 'invoiceId',
        headerName: 'Invoice ID',
        width: 230,
        cellRenderer: PrepaymentInvoicesDetailCell,
        sortable: true,
        filter: true,
        valueGetter: (params) => params.data.invoiceId,
        getQuickFilterText: (params) => params.data.invoiceId,
        // hide: true,
    },
    {
        field: 'totalAmount',
        headerName: 'Total amount',
        width: 160,
        cellRenderer: PaymentItemTotalInfoCell,
        sortable: true,
        filter: true,
        valueGetter: (params) => params.data.totalAmount,
        getQuickFilterText: (params) => params.data.totalAmount,
    },
    {
        field: 'amountPending',
        headerName: 'Amount pending',
        width: 180,
        cellRenderer: PaymentPendingInfoCell,
        // cellRenderer: (params: any) => (
        //     <FWTypography
        //         sx={{
        //             width: '100%',
        //             textAlign: 'right',
        //         }}
        //     >
        //         {FWNumberFormatter(
        //             params.data.amountPending,
        //             params.data.currency
        //         )}
        //     </FWTypography>
        // ),
        sortable: true,
        filter: true,
        valueGetter: (params) => params.data.amountPending,
        getQuickFilterText: (params) => params.data.amountPending,
    },
    {
        field: 'amountToPay',
        headerName: 'Amount to pay',
        width: 180,
        cellRenderer: PaymentAmountCell,
    },
];

export const creditListColumns: ColDef[] = [
    {
        field: 'selection',
        headerName: '',
        width: 50,
        cellRenderer: (params: any) => '',
        checkboxSelection: true,
    },
    {
        field: 'poId',
        headerName: 'PO ID',
        minWidth: 160,
        flex: 1,
        cellRenderer: (params: any) => (
            <FWTypography>{params.data.poId}</FWTypography>
        ),
        sortable: true,
        filter: true,
        valueGetter: (params) => params.data.poId,
        getQuickFilterText: (params) => params.data.poId,
    },
    {
        field: 'details',
        headerName: 'Details',
        headerClass: 'center',
        width: 110,
        cellRenderer: CreditDetailCell,
    },
    {
        field: 'creditType',
        headerName: 'Balance type',
        width: 180,
        cellRenderer: (params: any) => (
            <FWTypography>{params.data.creditType}</FWTypography>
        ),
        sortable: true,
        filter: true,
        valueGetter: (params) => params.data.creditType,
        getQuickFilterText: (params) => params.data.creditType,
    },
    {
        field: 'totalAmount',
        headerName: 'Total credit amount',
        width: 220,
        cellRenderer: (params: any) => (
            <FWTypography
                sx={{
                    width: '100%',
                    textAlign: 'right',
                }}
            >
                {FWNumberFormatter(
                    params.data.totalAmount,
                    params.data.currency
                )}
            </FWTypography>
        ),
        sortable: true,
        filter: true,
        valueGetter: (params) => params.data.creditType,
        getQuickFilterText: (params) => params.data.creditType,
    },
    {
        field: 'availableAmount',
        headerName: 'Pending credit amount',
        width: 250,
        cellRenderer: (params: any) => (
            <FWTypography
                sx={{
                    width: '100%',
                    textAlign: 'right',
                }}
            >
                {FWNumberFormatter(
                    params.data.availableAmount,
                    params.data.currency
                )}
            </FWTypography>
        ),
        sortable: true,
        filter: true,
        valueGetter: (params) => params.data.creditType,
        getQuickFilterText: (params) => params.data.creditType,
    },
    {
        field: 'requestedAmount',
        headerName: 'Credit requested',
        width: 250,
        cellRenderer: CreditAmountCell,
    },
];

export const transactionTypeMap: { [key: string]: string } = {
    [TransactionType.ONLINE]: 'ONLINE',
    [TransactionType.OFFLINE]: 'OFFLINE',
    [TransactionType.BALANCE_ONLY]: 'BALANCE_ONLY',
};

export const transactionTypeMapService: { [key: string]: TransactionType } = {
    ONLINE: TransactionType.ONLINE,
    OFFLINE: TransactionType.OFFLINE,
    BALANCE_ONLY: TransactionType.BALANCE_ONLY,
};
