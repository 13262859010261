import { useCallback, useState } from 'react';
import { HookStateValue, useHookState } from '../../../Common/Hooks/StateHook';
import {
    VendorStatus,
    VendorVerificationStatus,
} from '../../../Organizations/Admin/Interfaces/VendorsInterface';
import {
    IVendorSearchedData,
    runVendorSearch,
} from '../../../Organizations/Admin/Services/VendorsService';
import { VerificationStatus } from '../../../Redux/Slices/VerificationSlice';
import { uniqueId } from 'lodash';

const useSearchForVendors = (entity_id: string) => {
    const { hookState, updateHookState } = useHookState(HookStateValue.LOADING);

    const [allVendorsFromSearch, setAllVendorsFromSearch] = useState<
        IVendorSearchedData[] | null
    >([]);

    const searchForVendors = useCallback(
        async (
            query: string,
            allowInviteNewVendors: boolean
        ): Promise<boolean> => {
            updateHookState(HookStateValue.LOADING);
            try {
                if (query && query.length > 0) {
                    let vendors: IVendorSearchedData[] = await runVendorSearch({
                        searchByQuery: {
                            query: query,
                            entityId: entity_id,
                        },
                    });
                    vendors = vendors.filter(
                        (vendor) => vendor.vendorUid !== entity_id
                    );
                    if (allowInviteNewVendors) {
                        vendors.push(inviteNewVendorRow(query, entity_id));
                    }
                    setAllVendorsFromSearch(
                        vendors.length === 0 ? null : vendors
                    );
                    updateHookState(HookStateValue.READY);
                    return true;
                } else {
                    setAllVendorsFromSearch(null);
                    updateHookState(HookStateValue.READY);
                    return true;
                }
            } catch (error) {
                updateHookState(HookStateValue.ERROR);
                return false;
            }
        },
        [entity_id, updateHookState]
    );

    return {
        hookState,
        allVendorsFromSearch,
        searchForVendors,
    };
};

export default useSearchForVendors;

export const inviteNewVendorRow = (
    searchTerm: string,
    entityId: string
): IVendorSearchedData => {
    return {
        vendorCode: '',
        notes: '',
        tags: [],
        description: '',
        website: '',
        globalName: '',
        globalEmail: '',
        email: '',
        telephone: '',
        primaryContacts: [
            {
                parent: null,
                uid: uniqueId(),
                default: true,
                email: '',
                fullName: '',
                contactNumber: '',
                notes: '',
                isPresentInFW: false,
                selected: true,
                internalName: '',
                isCCEmail: false,
                isSecondaryEmail: false,
            },
        ],
        entities: [entityId],
        is_virtual: false,
        verification_status: VerificationStatus.UNVERIFIED,
        vendorUid: '',
        vendorEnterpriseUid: '',
        vendorEnterpriseName: '',
        enterpriseVendorMasterId: '',
        vendorName: searchTerm,
        vendorStatus: VendorStatus.INVITED,
        vendorVerificationStatus: VendorVerificationStatus.INVITATION_PENDING,
        is_new_invitation_row: true,
        is_entity_linked: true,
        isCustom: true,
        vendor_internal_name: searchTerm,
        is_Dir_Vendor: false,
        address_info: null,
        entity_doing_business_as: '',
        vendor_contact_name: '',
        vendor_contact_email: '',
        seller_address_information: [],
        seller_identifications: [],
        seller_contacts: [],
    };
};
