import { cloneDeep } from 'lodash';
import { useEffect, useState } from 'react';
import {
    HookStateValue,
    IHookState,
    useHookState,
} from '../../Common/Hooks/StateHook';
import { useGetListOfFormulaQuery } from '../../Formula/servies/formula.services';
import { blankProjectTemplate } from '../../Global/Helpers/templateHelpers';
import {
    IProjectTemplate,
    ProjectTemplateStandardFieldSettingObjOptions,
} from '../interface/project.model';
import { useGetProjectTemplateByIdQuery } from '../services/project.services';

export interface IProjectTemplateReturn {
    hookState: IHookState;
    templateDetails: IProjectTemplate;
    refetch: () => void;
}
export const useProjectTemplateHooks: (
    entityId: string,
    templateId: string | 'DEFAULT' | null
) => IProjectTemplateReturn = (
    entityId: string,
    templateId: string | 'DEFAULT' | null
) => {
    const { hookState, updateHookState } = useHookState(HookStateValue.LOADING);

    const {
        data: templateData,
        isLoading,
        isError,
        error,
        refetch: refetchProjectTemplateDetails,
    } = useGetProjectTemplateByIdQuery(
        {
            entityId: entityId
                ? entityId
                : '097adb21-7e53-47aa-896c-fa2197396662',
            templateId: templateId ? templateId : '',
        },
        {
            skip: !Boolean(templateId) || !Boolean(entityId),
        }
    );

    const { data: listOfFormulaForCustomFields } = useGetListOfFormulaQuery({
        filterByField: 'CUSTOM_FIELD',
    });

    const [templateDetails, setTemplateDetails] =
        useState<IProjectTemplate>(blankProjectTemplate);

    useEffect(() => {
        if (isLoading) {
            updateHookState(HookStateValue.LOADING);
        }
    }, [isLoading, updateHookState]);

    useEffect(() => {
        if (
            templateData &&
            listOfFormulaForCustomFields !== undefined &&
            isLoading === false
        ) {
            const temp = cloneDeep(templateData);

            for (let formulaFieldName of listOfFormulaForCustomFields.filter(
                (field) =>
                    (field.formula.formula_string?.length ?? 0) > 0 ||
                    field.formula.operands.length > 0
            )) {
                const sections = Object.keys(temp.fieldSettings) as (
                    | 'PROJECT_DETAILS'
                    | string
                )[];
                for (let section of sections) {
                    // checking for standard field
                    for (let field of Object.keys(
                        temp.fieldSettings[section].standardFields
                    ) as ProjectTemplateStandardFieldSettingObjOptions[]) {
                        if (field === formulaFieldName.field_name) {
                            temp.fieldSettings[section].standardFields = {
                                ...temp.fieldSettings[section].standardFields,
                                [field]: {
                                    ...temp.fieldSettings[section]
                                        .standardFields[field],
                                    formula: formulaFieldName.formula,
                                },
                            };
                        }
                    }

                    // checking for custom fields
                    for (let field of Object.keys(
                        temp.fieldSettings[section].customFields
                    ) as string[]) {
                        if (field === formulaFieldName.field_name) {
                            temp.fieldSettings[section].customFields = {
                                ...temp.fieldSettings[section].customFields,
                                [field]: {
                                    ...temp.fieldSettings[section].customFields[
                                        field
                                    ],
                                    formula: formulaFieldName.formula,
                                },
                            };
                        }
                    }
                }
            }

            setTemplateDetails(temp);

            updateHookState(HookStateValue.READY);
        }
    }, [
        isLoading,
        listOfFormulaForCustomFields,
        templateData,
        updateHookState,
    ]);

    useEffect(() => {
        if (isError && templateId !== '') {
            updateHookState(HookStateValue.ERROR);
        }
    }, [isError, templateId, updateHookState, error]);

    return {
        hookState,
        templateDetails: templateDetails,
        refetch: refetchProjectTemplateDetails,
    };
};
