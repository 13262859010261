import React, { useRef, useEffect, useMemo, useState } from 'react';
import { Box, styled } from '@mui/system';
import { debounce } from 'lodash';
import { customScrollbar } from './Utils/UIUtils';

const StyledBox = styled(Box)(({ theme }) => ({
    position: 'absolute',
    right: '1.5rem',
    bottom: '1.5rem',
    cursor: 'pointer',
    padding: '5px',
    // scale: 0,
    background: 'white',
    borderRadius: '50%',
    height: '30px',
    width: '30px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: '0.8',
    zIndex: 2,
    transition: '.2s all ease-in-out',
    '&:hover': {
        scale: 1,
        opacity: '1',
    },
}));

const FWScrollToBottom = ({
    variablesToCheck,
    children,
    height,
    maxHeight,
    onReachedBottom,
    style = {},
}: {
    variablesToCheck: any[];
    children: JSX.Element | undefined;
    height?: string | number;
    maxHeight?: string | number;
    onReachedBottom?: () => void;
    style?: React.CSSProperties;
}) => {
    const list_ref = useRef<HTMLDivElement>(null);
    const [showScrollDwnBtn, setShowScrollDwnBtn] = useState(false);

    useEffect(() => {
        if (list_ref.current) {
            list_ref.current.scroll({
                top: list_ref.current.scrollHeight,
                // behavior: 'smooth',
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...variablesToCheck]);

    const msgListScrollHandler = useMemo(
        () =>
            debounce(() => {
                const isScrolledToBottom =
                    list_ref.current &&
                    list_ref.current.scrollTop <=
                        list_ref.current.scrollHeight -
                            list_ref.current.offsetHeight -
                            5;

                setShowScrollDwnBtn(isScrolledToBottom || false);
                if (isScrolledToBottom) {
                    onReachedBottom && onReachedBottom();
                }
            }, 100),

        // eslint-disable-next-line react-hooks/exhaustive-deps
        [list_ref, setShowScrollDwnBtn]
    );

    const handleScrollToBottomClick = () => {
        if (list_ref.current) {
            // scroll to bottom of chats
            list_ref.current.scroll({
                top: list_ref.current.scrollHeight,
                behavior: 'smooth',
            });
        }
    };

    return (
        <div
            style={{
                height: '100%',
                position: 'relative',
                overflow: 'hidden',
                ...style,
            }}
        >
            {showScrollDwnBtn && (
                <StyledBox onClick={handleScrollToBottomClick}>
                    <Box color={'gray'}>
                        <i className="bi bi-chevron-down"></i>
                    </Box>
                </StyledBox>
            )}
            <Box
                ref={list_ref}
                onScroll={msgListScrollHandler}
                overflow="scroll"
                height={'100%'}
                sx={customScrollbar}
            >
                {children}
            </Box>
        </div>
    );
};

export default FWScrollToBottom;
