import { ColDef } from 'ag-grid-community';
import { FWTypography } from '../../Common/FWTypograhy';
import { FWTooltip } from '../../Common/FWCustomTooltip';
import { Box } from '@mui/material';
import BuyerPurchaseOrderStatusChip from '../../PurchaseOrders/Components/Buyer/BuyerPurchaseOrderStatusChip';
import { getPurchaseOrderDisplayStatusBuyer } from '../../PurchaseOrders/Misc/helperFunctions';
import moment from 'moment';

export const columns: ColDef[] = [
    {
        field: 'custom_po_id',
        headerName: 'PO ID',
        flex: 1,
        cellRenderer: (params: any) => {
            return (
                <div className="flex flex--aic">
                    <FWTypography color="text.secondary">
                        {params.value}
                    </FWTypography>
                    &nbsp;
                    {params.data.po_status === 'ONGOING' &&
                        params.data.po_hold_or_termination !== null && (
                            <FWTooltip
                                title={
                                    params.data.po_hold_or_termination ===
                                    'HOLD'
                                        ? 'PO on hold'
                                        : 'Termination requested for PO'
                                }
                            >
                                <Box
                                    color={
                                        params.data.po_hold_or_termination ===
                                        'HOLD'
                                            ? 'warning.main'
                                            : 'error.main'
                                    }
                                    style={{ fontSize: '40px' }}
                                >
                                    <i className="bi bi-dot"></i>
                                </Box>
                            </FWTooltip>
                        )}
                </div>
            );
        },
        filter: true,
        sortable: true,
        width: 150,
    },
    {
        field: 'buyer_entity_name',
        headerName: 'Entity',
        flex: 1,
        minWidth: 120,
        cellRenderer: (params: any) => (
            <FWTypography color="text.primary">{params.value}</FWTypography>
        ),
        filter: true,
        sortable: true,
    },
    {
        field: 'po_status',
        headerName: 'Status',
        width: 200,
        cellRenderer: (params: any) => (
            <BuyerPurchaseOrderStatusChip
                displayStatus={getPurchaseOrderDisplayStatusBuyer(
                    params.value,
                    params.data.po_hold_or_termination === 'HOLD',
                    params.data.po_hold_or_termination ===
                        'TERMINATION_REQUESTED'
                )}
                isAmended={false}
            />
        ),
        filter: true,
        sortable: true,
    },
    {
        field: 'created_datetime',
        headerName: 'Created on',
        width: 80,
        cellRenderer: (params: any) => {
            return (
                <FWTypography color="text.primary">
                    {params.data.isssued_date}
                </FWTypography>
            );
        },
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            const date1 = moment(valueA);
            const date2 = moment(valueB);
            return date1.isSame(date2) ? 0 : date1.isAfter(date2) ? 1 : -1;
        },
        filter: true,
        sortable: true,
    },
    // {
    //     field: 'created_by_user_name',
    //     headerName: 'Created by',
    //     flex: 1,
    //     minWidth: 120,
    //     cellRenderer: (params: any) => (
    //         <FWTypography color="text.primary">{params.data}</FWTypography>
    //     ),
    //     filter: true,
    //     sortable: true,
    // },
    {
        field: 'po_no_of_items',
        headerName: 'Item(s)',
        cellRenderer: (params: any) => (
            <FWTypography color="text.primary" textAlign="center" width={200}>
                {params.value}
            </FWTypography>
        ),
        filter: true,
        sortable: true,
        width: 100,
    },
    {
        field: 'po_total_amount',
        headerName: 'PO Total',
        width: 180,
        cellRenderer: (params: any) => (
            <FWTypography color="text.primary" textAlign="right" width={200}>
                {params.value}
            </FWTypography>
        ),
        filter: true,
        sortable: true,
    },
];
