import { Pagination } from '@mui/material';
import { Dispatch, memo, SetStateAction } from 'react';
import { updateCLMStore } from '../../../CLM/Slices/CLMSlice';
import { useDispatch } from 'react-redux';
import { updateRequisitionStore } from '../../Slices/RequisitionSlice';

const ItemPagination = ({
    totalPages,
    page,
    setPage,
}: {
    totalPages: number;
    page: number;
    setPage: Dispatch<SetStateAction<number>>;
}) => {
    const dispatch = useDispatch();
    return (
        <>
            {' '}
            <Pagination
                count={totalPages}
                onChange={(e, newPage) => {
                    window.scrollTo({
                        top: 0,
                        behavior: 'smooth',
                    });
                    setPage(newPage);
                    dispatch(
                        updateCLMStore({
                            type: 'UPDATE_PAGE_NUMBER',
                            value: newPage,
                        })
                    );
                    dispatch(
                        updateRequisitionStore({
                            type: 'UPDATE_PAGE_NUMBER',
                            value: page + 1,
                        })
                    );
                    // debounce(refetch, 1000)();
                }}
                page={page}
            />
        </>
    );
};

export default memo(ItemPagination);
