import FWAutocomplete from '../../Common/FWAutocomplete';
import { IBuyerItemList } from '../../Events/Interfaces/Buyer/RFQ.model';
import { CircularProgress, InputAdornment, TextField } from '@mui/material';
import { itemIsDisabled } from '../../Events/Containers/Buyer/AddBuyerMasterItemContainer';
import { FWMenuItem } from '../../Common/FWCustomMenu';
import { FWTypography } from '../../Common/FWTypograhy';
// import useSellerProfileItems from '../Hooks/useSellerProfileItems';
import { useMemo } from 'react';
import { debounce } from 'lodash';
import useSellerProfileItems from '../Hooks/useSellerProfileItems';

// const DEFAULT_ADD_OPTION: IBuyerItemList = {
//     entity_item_id: '',
//     preferred_vendors_count: 0,
//     enterprise_item: {
//         enterprise_item_id: '',
//         code: '',
//         description: '',
//         attributes: {
//             attributes: [],
//         },
//         custom_ids: {
//             custom_ids: [],
//         },
//         measurement_units: {
//             item_measurement_units: [],
//         },
//         name: '',
//         status: 'ACTIVE',
//         notes: '',
//         buyer_enterprise: '',
//         tags: [],
//     },
//     status: 'ACTIVE',
//     buyer_enterprise: '',
//     buyer_entity: '',
// };

const CostingItemAutocomplete = ({
    // value,
    // handleCostingDataChange,
    // itemIndex,
    entityId,
    buyerItems,
    onChanges,
    placeholder,
    onSearchItem,
    onShowMoreItems,
}: {
    // value: string | null;
    // handleCostingDataChange: IHandleCostingDataChangeFunction;
    // itemIndex: number;
    entityId: string;
    buyerItems: IBuyerItemList[];
    // selectedBuyerItems: Set<string>;
    onChanges: (value: IBuyerItemList | null) => void;
    placeholder?: string;
    onSearchItem: (searchText: string) => void;
    onShowMoreItems: () => void;
}) => {
    const { isLoading } = useSellerProfileItems(entityId);
    const debounceSearch = useMemo(
        () =>
            debounce(async (searchText: string) => {
                onSearchItem(searchText);
            }, 500),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    return (
        <FWAutocomplete
            fullWidth
            isOptionEqualToValue={(
                option: IBuyerItemList,
                value: IBuyerItemList
            ) => {
                return (
                    option.enterprise_item.enterprise_item_id ===
                    value.enterprise_item.enterprise_item_id
                );
            }}
            // value={
            //     buyerItems.find(
            //         (i) => i.enterprise_item.enterprise_item_id === value
            //     ) || null
            // }
            onChange={(event, value: IBuyerItemList | null) => {
                // handleCostingDataChange({
                //     index: itemIndex,
                //     type: 'ITEM_ITEM_CHANGE',
                //     value: value?.enterprise_item.enterprise_item_id || null,
                // });
                onChanges(value);
            }}
            onInputChange={(event, value) => {
                // //
                debounceSearch(value);
            }}
            options={buyerItems}
            getOptionLabel={(option) => {
                // if (option.entity_item_id !== '') {
                // let specsString =
                //     ' | ' +
                //     option.enterprise_item.attributes.attributes
                //         .map(
                //             (attr: any) =>
                //                 `${
                //                     attr.attribute_name
                //                 }: ${attr.attribute_value.join(' / ')}`
                //         )
                //         .join(' | ');
                // return `${option.enterprise_item.name}${specsString} | ${option.enterprise_item.code}`;
                console.log(option, 'sdkjfhskdf');
                return `${option.enterprise_item.name} | ${option.enterprise_item.code}`;
                // } else {
                // return `Add "${
                //     searchText === '' ? 'new item' : searchText
                // }" to directory`;
                // }
            }}
            renderInput={(params) => (
                <TextField
                    placeholder={placeholder}
                    {...params}
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                            <InputAdornment position="start">
                                <i className="bi bi-search"></i>
                            </InputAdornment>
                        ),
                    }}
                />
            )}
            filterOptions={(options) => options}
            renderOption={(props, option) => {
                const isDisabled = itemIsDisabled(option); /* ||
                    selectedBuyerItems.has(option.entity_item_id); */
                const isAddNewOption = option.entity_item_id === '';
                // let specsString =
                //     isAddNewOption ||
                //     option.enterprise_item.attributes.attributes.length === 0
                //         ? ''
                //         : ' | ' +
                //           option.enterprise_item.attributes.attributes
                //               .map(
                //                   (attr: any) =>
                //                       `${
                //                           attr.attribute_name
                //                       }: ${attr.attribute_value.join(' / ')}`
                //               )
                //               .join(' | ');
                return isLoading ? (
                    <FWMenuItem>
                        <CircularProgress size={20} />
                    </FWMenuItem>
                ) : option.entity_item_id === 'SHOW_MORE_ITEMS' ? (
                    <FWMenuItem
                        key={option.projectUUid}
                        onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            onShowMoreItems();
                        }}
                    >
                        <FWTypography color={'primary.main'}>
                            Show more results...
                        </FWTypography>
                    </FWMenuItem>
                ) : (
                    <FWMenuItem
                        {...props}
                        disabled={isDisabled}
                        sx={{
                            fontStyle:
                                isDisabled || isAddNewOption
                                    ? 'italic'
                                    : undefined,
                            color:
                                isDisabled || isAddNewOption
                                    ? 'text.secondary'
                                    : undefined,
                        }}
                    >
                        <FWTypography
                            sx={{
                                wordWrap: 'break-word',
                                whiteSpace: 'pre-wrap',
                            }}
                        >
                            {/* {`${isDisabled ? '(Disabled) ' : ''}${
                                    option.enterprise_item.name
                                } ${specsString} | ${
                                    option.enterprise_item.code
                                }`} */}
                            {`${isDisabled ? '(Disabled) ' : ''}${
                                option.enterprise_item.name
                            } | ${option.enterprise_item.code}`}
                        </FWTypography>
                    </FWMenuItem>
                );
            }}
        />
    );
};

export default CostingItemAutocomplete;
