import { IRequisitionNewTemplate } from '../Interfaces/RequisitionTemplateInterfacesNew';

export const blankRequisitionTemplate: IRequisitionNewTemplate = {
    templateId: '',
    templateName: '',
    fieldSettings: {
        standardFields: {
            REQUISITION_CREATOR: {
                standardFieldCategory: 'STANDARD',
                label: 'Quantity',
                description: '',
                buyerSettings: {
                    required: true,
                    showField: true,
                },
                sellerSettings: {
                    negotiable: true,
                    showField: true,
                    isMandatory: false,
                },
                fieldLevel: 'OTHER',
            },
            BUYER_ENTITY: {
                standardFieldCategory: 'STANDARD',
                label: 'Quantity',
                description: '',
                buyerSettings: {
                    required: true,
                    showField: true,
                },
                sellerSettings: {
                    negotiable: true,
                    showField: true,
                    isMandatory: false,
                },
                fieldLevel: 'OTHER',
            },
            CURRENCY: {
                standardFieldCategory: 'STANDARD',
                label: 'Quantity',
                description: '',
                buyerSettings: {
                    required: true,
                    showField: true,
                },
                sellerSettings: {
                    negotiable: true,
                    showField: true,
                    isMandatory: false,
                },
                fieldLevel: 'OTHER',
            },
            REQUISITION_STATUS: {
                standardFieldCategory: 'STANDARD',
                label: 'Quantity',
                description: '',
                buyerSettings: {
                    required: true,
                    showField: true,
                },
                sellerSettings: {
                    negotiable: true,
                    showField: true,
                    isMandatory: false,
                },
                fieldLevel: 'OTHER',
            },
            REQUISITION_NAME: {
                standardFieldCategory: 'STANDARD',
                label: 'Quantity',
                description: '',
                buyerSettings: {
                    required: true,
                    showField: true,
                },
                sellerSettings: {
                    negotiable: true,
                    showField: true,
                    isMandatory: false,
                },
                fieldLevel: 'OTHER',
            },
            REQUISITION_ID: {
                standardFieldCategory: 'STANDARD',
                label: 'Quantity',
                description: '',
                buyerSettings: {
                    required: true,
                    showField: true,
                },
                sellerSettings: {
                    negotiable: true,
                    showField: true,
                    isMandatory: false,
                },
                fieldLevel: 'OTHER',
            },
            QUANTITY: {
                standardFieldCategory: 'STANDARD',
                label: 'Quantity',
                description: '',
                buyerSettings: {
                    required: true,
                    showField: true,
                },
                sellerSettings: {
                    negotiable: true,
                    showField: true,
                    isMandatory: false,
                },
                fieldLevel: 'OTHER',
            },
            DELIVERY_DATE: {
                standardFieldCategory: 'STANDARD',
                label: 'Delivery date',
                description: '',
                buyerSettings: {
                    required: true,
                    showField: true,
                },
                sellerSettings: {
                    negotiable: true,
                    showField: true,
                    isMandatory: false,
                },
                fieldLevel: 'OTHER',
            },
            COST_CENTER: {
                standardFieldCategory: 'STANDARD',
                label: 'Cost center',
                description: '',
                buyerSettings: {
                    required: true,
                    showField: true,
                },
                sellerSettings: {
                    negotiable: true,
                    showField: true,
                    isMandatory: false,
                },
                fieldLevel: 'OTHER',
            },
            GL: {
                standardFieldCategory: 'STANDARD',
                label: 'GL',
                description: '',
                buyerSettings: {
                    required: true,
                    showField: true,
                },
                sellerSettings: {
                    negotiable: true,
                    showField: true,
                    isMandatory: false,
                },
                fieldLevel: 'OTHER',
            },
            SHIPPING_ADDRESS: {
                standardFieldCategory: 'STANDARD',
                label: 'Shipping address',
                description: '',
                buyerSettings: {
                    required: true,
                    showField: true,
                },
                sellerSettings: {
                    negotiable: true,
                    showField: true,
                    isMandatory: false,
                },
                fieldLevel: 'OTHER',
            },
            PROJECT: {
                standardFieldCategory: 'STANDARD',
                label: 'Project',
                description: '',
                buyerSettings: {
                    required: true,
                    showField: true,
                },
                sellerSettings: {
                    negotiable: true,
                    showField: true,
                    isMandatory: false,
                },
                fieldLevel: 'OTHER',
            },
            TARGET_RATE: {
                standardFieldCategory: 'STANDARD',
                label: 'Target rate',
                description: '',
                buyerSettings: {
                    required: true,
                    showField: true,
                },
                sellerSettings: {
                    negotiable: true,
                    showField: true,
                    isMandatory: false,
                },
                fieldLevel: 'OTHER',
            },
            ADDITIONAL_INFORMATION: {
                standardFieldCategory: 'STANDARD',
                label: 'Event type',
                description: '',
                buyerSettings: {
                    required: true,
                    showField: true,
                },
                sellerSettings: {
                    negotiable: true,
                    showField: true,
                    isMandatory: false,
                },
                fieldLevel: 'OTHER',
            },
            VENDOR: {
                standardFieldCategory: 'STANDARD',
                label: '',
                description: '',
                buyerSettings: {
                    showField: false,
                    required: false,
                },
                sellerSettings: {
                    showField: false,
                    negotiable: false,
                    isMandatory: false,
                },
                fieldLevel: 'OTHER',
            },
            GR_TOLERANCE: {
                standardFieldCategory: 'STANDARD',
                label: '',
                description: '',
                buyerSettings: {
                    showField: false,
                    required: false,
                },
                sellerSettings: {
                    showField: false,
                    negotiable: false,
                    isMandatory: false,
                },
                fieldLevel: 'OTHER',
            },
            INCOTERMS: {
                standardFieldCategory: 'STANDARD',
                label: '',
                description: '',
                buyerSettings: {
                    showField: false,
                    required: false,
                },
                sellerSettings: {
                    showField: false,
                    negotiable: false,
                    isMandatory: false,
                },
                fieldLevel: 'OTHER',
            },
            LEAD_TIME: {
                standardFieldCategory: 'STANDARD',
                label: '',
                description: '',
                buyerSettings: {
                    showField: false,
                    required: false,
                },
                sellerSettings: {
                    showField: false,
                    negotiable: false,
                    isMandatory: false,
                },
                fieldLevel: 'OTHER',
            },

            TAX: {
                standardFieldCategory: 'TAX',
                taxesOptions: [],
                label: '',
                description: '',
                buyerSettings: {
                    showField: false,
                    required: false,
                },
                sellerSettings: {
                    showField: false,
                    negotiable: false,
                    isMandatory: false,
                },
            },

            PAYMENT_TERMS: {
                standardFieldCategory: 'PAYMENT_TERMS',
                label: '',
                description: '',
                buyerSettings: {
                    showField: false,
                    required: false,
                },
                sellerSettings: {
                    showField: false,
                    negotiable: false,
                    isMandatory: false,
                },
                paymentTermOptions: {
                    paymentType: 'poItemPercentages',
                    prepaymentAllowed: true,
                    allowOtherDeliverableNames: false,
                    deliverableNameOptions: [],
                },
            },

            ADDITIONAL_COSTS: {
                standardFieldCategory: 'ADDITIONAL_COSTS',
                label: '',
                buyerSettings: {
                    required: false,
                    showField: false,
                },
                description: '',
                sellerSettings: {
                    negotiable: false,
                    showField: false,
                    isMandatory: false,
                },
                additionalCostOptions: [],
            },
            DISCOUNT: {
                standardFieldCategory: 'DISCOUNT',
                label: '',
                buyerSettings: {
                    required: false,
                    showField: false,
                },
                description: '',
                sellerSettings: {
                    isMandatory: false,
                    negotiable: false,
                    showField: false,
                },
                discountOptions: [],
            },
        },
        customFields: {},
    },
    sections: {
        REQUISITION_DETAILS: {
            label: '',
            fieldList: [],
        },
        ITEM_TERMS: {
            label: '',
            fieldList: [],
        },
        PAYMENT_AND_DELIVERY_TERMS: {
            label: '',
            fieldList: [],
        },
        ADDITIONAL_DETAILS: {
            label: '',
            fieldList: [],
        },
        VENDOR_INFORMATION: {
            label: '',
            fieldList: [],
        },
    },
};
