import { useCallback, useEffect, useState } from 'react';
import { IVLNItemCatalogueNew } from '../../VendorLinkedIn/Interfaces/VendorLNInterfaces';
import { fetchItemCatalogueListApiNew } from '../../VendorLinkedIn/Services/VendorLinkedIn.service';

const useSellerItemCatalogueItems = (vendor_profile_id: string) => {
    const [currentCataloguePage, setCurrentCataloguePage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [catalogueItems, setCatalogueItems] = useState<
        IVLNItemCatalogueNew[]
    >([]);

    const fetchCatalogueItems = useCallback(async () => {
        const payload = {
            dashboard_view: 'item_catalogue',
            tab: 'all',
            search_text: '',
            sort_fields: [],
            page_number: currentCataloguePage,
            items_per_page: 10,
            query_data: {
                vendor_profile_id: vendor_profile_id,
            },
            filters: null,
        };
        const response = await fetchItemCatalogueListApiNew(payload);
        setCatalogueItems(response.data);
        setTotalPages(response.metadata.total_pages);
    }, [currentCataloguePage, vendor_profile_id]);
    useEffect(() => {
        fetchCatalogueItems();
    }, [currentCataloguePage, fetchCatalogueItems]);
    return {
        catalogueItems,
        currentCataloguePage,
        setCurrentCataloguePage,
        totalPages,
        setTotalPages,
        fetchCatalogueItems,
    };
};

export default useSellerItemCatalogueItems;
