import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { IEventItemDetails } from '../../Events/Interfaces/Buyer/RFQ.model';
import {
    fetchRFQItemsList,
    fetchVendorListAddedToRFQItem,
    // fetchVendorListAddedToRFQItem,
    IAddedVendorList,
    IRfqSellerBackendResponse,
    // IRfqSellerBackendResponse,
} from '../../Events/Services/Buyer/RFQ.service';
import { IUserSession } from '../Interfaces/Chat.model';
import ChatMessageDisplayContainer from './ChatMessageBoxContainer';
// import { useState } from 'react';
import ChatSessionDisplayContainer from './ChatSessionDisplayContainer';
import { useDispatch } from 'react-redux';
import { updateCurrentlyOpenedSession } from '../Slice/Chat.slice';
import { fetchModuleUserSessions } from '../Services/chat.service';
import { get } from '../../Utils/api';
// import { get } from '../../Utils/api';
// import { IRFQItemAddedVendors } from '../../Models/RFQ.model';

export interface IChatSessionAndMessageContainer {
    selectedEventID: {
        event_id: string;
        sub_event_id: string;
    };
    user_type: 'buyer' | 'seller';
    openedFromPopup: boolean;
    isSessionContainerOpen: boolean;
    floatingChat?: boolean;
    hideBroadcast?: boolean;
    currentlySelectedEventStatus: string;
    currentlySelectedEventID?: string | null;
    showEventsSection?: boolean;
    setShowEventsSection?: React.Dispatch<React.SetStateAction<boolean>>;
    hideSessionListContainer?: boolean;
    setHideSessionListContainer?: React.Dispatch<React.SetStateAction<boolean>>;
    sellerEntityUuid?: string;
}

export default function ChatSessionAndMessageContainer(
    props: IChatSessionAndMessageContainer
) {
    const [selectedSession, setSelectedSession] = useState<IUserSession | null>(
        null
    );
    const [isBroadCastSessionSelected, setIsBroadCastSessionSelected] =
        useState(false);
    // const [selectedSessionMuteState, setSelectedSessionMuteState] =
    //     useState<boolean>(false);
    const [eventItemAndVendorDetails, setEventItemAndVendorDetails] = useState<{
        items: IEventItemDetails[];
        vendors: { [key: string]: IAddedVendorList[] };
    } | null>(null);

    // const [selectedSessionEventPermission, setSelectedSessionEventPermission] =
    //     useState<'EVENT_VIEW' | 'EVENT_EDIT'>('EVENT_VIEW');

    useEffect(() => {
        if (props.sellerEntityUuid && props.currentlySelectedEventID) {
            fetchModuleUserSessions(props.currentlySelectedEventID).then(
                (res) => {
                    if (res) {
                        const sellerChat = res.find(
                            (val) =>
                                val.session.seller_entity_id ===
                                props.sellerEntityUuid
                        );
                        sellerChat && setSelectedSession(sellerChat);
                    }
                }
            );
        }
    }, [props.currentlySelectedEventID, props.sellerEntityUuid]);

    const dispatch = useDispatch();

    const updateSelectedSession = useCallback(
        (newSession: IUserSession | null) => {
            setIsBroadCastSessionSelected(false);
            // setSelectedSessionMuteState(
            //     newSession === null ? false : newSession.mute
            // );
            setSelectedSession(newSession);
            dispatch(
                updateCurrentlyOpenedSession(
                    newSession?.session?.session_id || null
                )
            );
            // setSelectedSessionEventPermission(
            //     newSession?.action_api_group ?? 'EVENT_VIEW'
            // );
        },
        [dispatch]
    );

    const updateBroadcastSessionSelection = (data: IUserSession | null) => {
        data && setSelectedSession(data);
        setIsBroadCastSessionSelected(true);
    };

    const [selectedSessionAvatarColor, setSelectedSessionAvatarColor] =
        useState<string>('#85C7F2');

    const [containerToShow, setContainerToShow] = useState<
        'session' | 'message'
    >();

    useEffect(() => {
        if (containerToShow === 'message') {
            dispatch(updateCurrentlyOpenedSession(null));
        }
    }, [containerToShow, dispatch]);

    const selectedSessionId = selectedSession?.session.session_id ?? '';
    const selectedSessionMuteState =
        selectedSession === null ? false : selectedSession.mute;
    const selectedSessionEventPermission =
        selectedSession?.action_api_group ?? 'EVENT_VIEW';
    const selectedSessionType = selectedSession?.session.session_type ?? null;

    const updateSessionMuteState = (muteState: boolean) => {
        setSelectedSession((prev) =>
            prev ? { ...prev, mute: muteState } : null
        );
    };

    useEffect(() => {
        if (selectedSessionId.length > 0 && selectedSessionId !== 'broadcast') {
            setContainerToShow('message');
        } else {
            setContainerToShow('session');
        }
    }, [selectedSessionId]);

    // forget selectedsessionid when eventid changes
    useEffect(() => {
        setSelectedSession(null);
        if (props.user_type === 'buyer' && props.isSessionContainerOpen) {
            fetchRFQItemsList(
                props.selectedEventID.sub_event_id,
                props.user_type === 'buyer' ? 'BUYER' : 'SELLER',
                'FROM_EVENT'
            )
                .then((item_res) => {
                    let all_item_ids: string[] = item_res.map(
                        (item) => item.rfq_item_entry_id
                    );
                    let vendor_promise: Promise<IAddedVendorList[]>[] = [];
                    for (let item_id of all_item_ids) {
                        vendor_promise.push(
                            fetchVendorListAddedToRFQItem(
                                props.selectedEventID.sub_event_id,
                                item_id,
                                'FROM_EVENT'
                            )
                        );
                    }
                    let obj: { [key: string]: IAddedVendorList[] } = {};

                    // Promise.all(vendor_promise)
                    //     .then((vendor_res) => {
                    //         vendor_res.forEach((vendors, i) => {
                    //             obj[all_item_ids[i]] = vendors;
                    //         });

                    //         setEventItemAndVendorDetails({
                    //             items: item_res.sort((itemA, itemB) =>
                    //                 itemA.item_information.buyer_item_name <
                    //                 itemB.item_information.buyer_item_name
                    //                     ? -1
                    //                     : itemA.item_information
                    //                           .buyer_item_name >
                    //                       itemB.item_information.buyer_item_name
                    //                     ? 1
                    //                     : 0
                    //             ),
                    //             vendors: obj,
                    //         });
                    //     })
                    //     .catch((err) => {
                    //         toast.error("Couldn't fetch event details");
                    //     });

                    // merged api call
                    get<IRfqSellerBackendResponse[]>(
                        `/events/rfq/${props.selectedEventID.sub_event_id}/items/sellers/`
                    ).then((res) => {
                        res.data.forEach((vendorItem) => {
                            if (vendorItem.rfq_item_id in obj) {
                                obj[vendorItem.rfq_item_id].push({
                                    enterprise_name:
                                        vendorItem.seller_entity
                                            .enterprise_name,
                                    entity_id:
                                        vendorItem.seller_entity.entity_id,
                                    entity_logo: '',
                                    entity_name:
                                        vendorItem.seller_entity.entity_name,
                                    entity_website_url: '',
                                    is_virtual:
                                        vendorItem.seller_entity.is_virtual,
                                    seller_contacts: vendorItem.seller_contacts,
                                    verification_status:
                                        vendorItem.seller_entity
                                            .verification_status,
                                    contacts: {},
                                    vendor_code: '',
                                });
                            } else {
                                obj[vendorItem.rfq_item_id] = [
                                    {
                                        enterprise_name:
                                            vendorItem.seller_entity
                                                .enterprise_name,
                                        entity_id:
                                            vendorItem.seller_entity.entity_id,
                                        entity_logo: '',
                                        entity_name:
                                            vendorItem.seller_entity
                                                .entity_name,
                                        entity_website_url: '',
                                        is_virtual:
                                            vendorItem.seller_entity.is_virtual,
                                        seller_contacts:
                                            vendorItem.seller_contacts,
                                        verification_status:
                                            vendorItem.seller_entity
                                                .verification_status,
                                        contacts: {},
                                        vendor_code:
                                            vendorItem.seller_contacts.length >
                                            0
                                                ? vendorItem.seller_contacts[0]
                                                      .enterprise_vendor_master
                                                      .vendor_code
                                                : '',
                                    },
                                ];
                            }
                        });

                        setEventItemAndVendorDetails({
                            items: item_res.sort((itemA, itemB) =>
                                itemA.item_information.buyer_item_name <
                                itemB.item_information.buyer_item_name
                                    ? -1
                                    : itemA.item_information.buyer_item_name >
                                      itemB.item_information.buyer_item_name
                                    ? 1
                                    : 0
                            ),
                            vendors: obj,
                        });
                    });
                })
                .catch((err) => {
                    toast.error("Couldn't fetch event details");
                });
        }
    }, [
        props.isSessionContainerOpen,
        props.selectedEventID.sub_event_id,
        props.user_type,
    ]);

    const [lastReadMsgId, setLastReadMsgId] = useState('');
    //
    const updateLastReadMsgId = (newMsgId: string) => {
        //
        setLastReadMsgId(newMsgId);
    };

    return (
        <>
            {props.floatingChat === true ? ( //open only 1 container at a time for floating chat
                <>
                    {containerToShow === 'session' ? (
                        <ChatSessionDisplayContainer
                            selectedEventID={props.selectedEventID.event_id}
                            selectedSessionID={selectedSessionId}
                            updateSelectedSession={updateSelectedSession}
                            user_type={props.user_type}
                            selectedSessionMuteState={selectedSessionMuteState}
                            isBroadCastSessionSelected={
                                isBroadCastSessionSelected
                            }
                            updateBroadCastSessionSelected={
                                updateBroadcastSessionSelection
                            }
                            setSelectedSessionAvatarColor={
                                setSelectedSessionAvatarColor
                            }
                            floatingChat={props.floatingChat}
                            hideBroadcast={props.hideBroadcast}
                            currentlySelectedEventStatus={
                                props.currentlySelectedEventStatus
                            }
                            currentlySelectedEventID={
                                props.currentlySelectedEventID
                            }
                            showEventsSection={props.showEventsSection}
                            setShowEventsSection={props.setShowEventsSection}
                            updateLastReadMsgId={updateLastReadMsgId}
                            lastReadMsgId={lastReadMsgId}
                        />
                    ) : (
                        <ChatMessageDisplayContainer
                            eventItemAndVendorDetails={
                                eventItemAndVendorDetails
                            }
                            selectedSessionID={selectedSessionId}
                            module_id={props.selectedEventID.event_id}
                            subEventId={props.selectedEventID.sub_event_id}
                            user_type={props.user_type}
                            updateSessionMuteState={updateSessionMuteState}
                            selectedSessionMuteState={selectedSessionMuteState}
                            isBroadCastSessionSelected={
                                isBroadCastSessionSelected
                            }
                            selectedSessionAvatarColor={
                                selectedSessionAvatarColor
                            }
                            openedFromPopup={props.openedFromPopup}
                            floatingChat={props.floatingChat}
                            setSelectedSession={setSelectedSession}
                            hideSessionListContainer={
                                props.hideSessionListContainer
                            }
                            setHideSessionListContainer={
                                props.setHideSessionListContainer
                            }
                            lastReadMsgId={lastReadMsgId}
                            setLastReadMsgId={setLastReadMsgId}
                            currentlySelectedEventEditStatus={
                                selectedSessionEventPermission
                            }
                            selectedSessionType={selectedSessionType}
                        />
                    )}
                </>
            ) : (
                <>
                    {!props.sellerEntityUuid && (
                        <ChatSessionDisplayContainer
                            selectedEventID={props.selectedEventID.event_id}
                            selectedSessionID={selectedSessionId}
                            updateSelectedSession={updateSelectedSession}
                            user_type={props.user_type}
                            selectedSessionMuteState={selectedSessionMuteState}
                            isBroadCastSessionSelected={
                                isBroadCastSessionSelected
                            }
                            updateBroadCastSessionSelected={
                                updateBroadcastSessionSelection
                            }
                            setSelectedSessionAvatarColor={
                                setSelectedSessionAvatarColor
                            }
                            hideBroadcast={props.hideBroadcast}
                            currentlySelectedEventStatus={
                                props.currentlySelectedEventStatus
                            }
                            currentlySelectedEventID={
                                props.currentlySelectedEventID
                            }
                            showEventsSection={props.showEventsSection}
                            setShowEventsSection={props.setShowEventsSection}
                            updateLastReadMsgId={updateLastReadMsgId}
                            lastReadMsgId={lastReadMsgId}
                        />
                    )}
                    <ChatMessageDisplayContainer
                        eventItemAndVendorDetails={eventItemAndVendorDetails}
                        selectedSessionID={selectedSessionId}
                        module_id={props.selectedEventID.event_id}
                        subEventId={props.selectedEventID.sub_event_id}
                        user_type={props.user_type}
                        updateSessionMuteState={updateSessionMuteState}
                        selectedSessionMuteState={selectedSessionMuteState}
                        isBroadCastSessionSelected={isBroadCastSessionSelected}
                        selectedSessionAvatarColor={selectedSessionAvatarColor}
                        openedFromPopup={props.openedFromPopup}
                        hideSessionListContainer={
                            props.hideSessionListContainer
                        }
                        setHideSessionListContainer={
                            props.setHideSessionListContainer
                        }
                        lastReadMsgId={lastReadMsgId}
                        setLastReadMsgId={setLastReadMsgId}
                        currentlySelectedEventEditStatus={
                            selectedSessionEventPermission
                        }
                        selectedSessionType={selectedSessionType}
                    />
                </>
            )}
        </>
    );
}
