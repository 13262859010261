import React from 'react';
import { useLocation } from 'react-router-dom';

declare global {
    var lastUpdated: string;
}
fetch(`/meta.json`, { cache: 'no-cache' })
    .then((response) => response.json())
    .then((meta) => {
        global.lastUpdated = meta.lastUpdated;
    });

interface ICacheBuster {
    children: React.ReactNode;
}

const CacheBuster = ({ children }: ICacheBuster) => {
    const location = useLocation();
    const refreshCacheAndReload = async () => {
        //Keeping this to imform the user that the page is reloading
        //
        if (caches) {
            const names = await caches.keys();
            await Promise.all(names.map((name) => caches.delete(name)));
        }
        window.location.reload();
    };

    const CheckVersion = React.useCallback(() => {
        fetch(`/meta.json`, { cache: 'no-cache' })
            .then((response) => response.json())
            .then((meta) => {
                const latestVersion = meta.lastUpdated;
                const currentVersion = global.lastUpdated;

                const shouldForceRefresh = latestVersion !== currentVersion;
                if (shouldForceRefresh) {
                    refreshCacheAndReload();
                }
            });
    }, []);
    React.useEffect(() => {
        CheckVersion();
    }, [CheckVersion, location.pathname]);

    return <>{children}</>;
};

export default CacheBuster;
