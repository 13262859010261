import { Box, Typography } from '@mui/material';
import moment, { Moment } from 'moment';
import React, { useMemo } from 'react';
import AdditionalCostPopover from '../../AdditionalCost/components/AdditionalCostPopover';
import {
    calculateAddtionalCost,
    convertAdditionalCostBackendToFrontend,
} from '../../AdditionalCost/helpers';
import '../../Assets/PDFStyles/index.css';
import { FWCurrencyTypoGraphyWithCurrencyUid } from '../../Common/CurrencyUtilsComponent';
import { FWAccordionDetails } from '../../Common/FWAccordian';
import { FWTooltip } from '../../Common/FWCustomTooltip';
import FWMultipleTooltipMessages from '../../Common/FWMultipleTooltipMessages';
import { FWTypography } from '../../Common/FWTypograhy';
// import { FWNumberFormatter } from '../../Common/Utils/CurrencyUtils';
import { getFWDate } from '../../Common/Utils/DateUtils';
import {
    StyledAccordion,
    StyledAccordionSummary,
    boxContentStyle,
    boxHeaderStyle,
} from '../../Components/Buyer/Events/PO/POReview/ItemAccordionStyles';
import UploadedFilesList from '../../Components/Shared/UploadedFilesList';
import { leadTimeSurpassed } from '../../Components/Shared/Utils';
import {
    calculateNewItemSubtotalWithDecimal,
    calculateNewItemTotalWithDecimalPlaces,
} from '../../Components/Shared/totalCalculator';
import { useGetSelectedCurrency } from '../../Events/Services/Buyer/shared.service';
import { IRfqTemplate } from '../../Global/Interfaces/TemplateInterface';
import {
    IPurchaseOrderItem,
    PurchaseOrderStatus,
} from '../Interfaces/PO.model';
import {
    getPoReviewColumnWidths,
    getPoReviewSectionStrings,
    tableFieldDesc,
} from '../Misc/poReviewUiHelpers';

interface IPoReviewItemAccordion {
    item: IPurchaseOrderItem;
    idx: number;
    purchase_order_status: PurchaseOrderStatus;
    pdfWindowOpened?: boolean;
    rfqTemplate: IRfqTemplate;
}

// const formatCurrency = (value: number, currency_code_abbreviation: string) =>
//     FWNumberFormatter(value, currency_code_abbreviation);

export default function PoReviewItemAccordion({
    item,
    idx,
    purchase_order_status,
    pdfWindowOpened,
    rfqTemplate,
}: IPoReviewItemAccordion) {
    const [open, setOpen] = React.useState<boolean>(false);

    const subTotal = React.useMemo(() => {
        return calculateNewItemSubtotalWithDecimal({
            decimalPlaces: 2,
            item_price: item.pricing_information.price,
            discount: convertAdditionalCostBackendToFrontend(
                item.pricing_information.discounts
            ),
            itemDS:
                item.delivery_schedule_information.delivery_schedule_items
                    .length === 0
                    ? [
                          {
                              quantity: item.quantity_information.quantity,
                          },
                      ]
                    : item.delivery_schedule_information
                          .delivery_schedule_items,
        });
    }, [
        item.pricing_information.price,
        item.pricing_information.discounts,
        item.delivery_schedule_information.delivery_schedule_items,
        item.quantity_information.quantity,
    ]);

    const dsQuantityDiffersFromItemQuantity = React.useMemo(() => {
        let dsQuantity = 0;
        for (let dsItem of item.delivery_schedule_information
            .delivery_schedule_items) {
            dsQuantity += +dsItem.quantity;
        }

        return parseFloat(item.quantity_information.quantity) !== dsQuantity;
    }, [
        item.delivery_schedule_information.delivery_schedule_items,
        item.quantity_information.quantity,
    ]);

    const total = React.useMemo(() => {
        return calculateNewItemTotalWithDecimalPlaces({
            additional_cost: convertAdditionalCostBackendToFrontend(
                item.pricing_information.additional_costs
            ),
            decimalPlaces: 2,
            discount: convertAdditionalCostBackendToFrontend(
                item.pricing_information.discounts
            ),
            item_price: item.pricing_information.price,
            itemDS:
                item.delivery_schedule_information.delivery_schedule_items
                    .length === 0
                    ? [
                          {
                              quantity: item.quantity_information.quantity,
                          },
                      ]
                    : item.delivery_schedule_information
                          .delivery_schedule_items,
            tax: convertAdditionalCostBackendToFrontend(
                item.pricing_information.taxes
            ),
        });
    }, [
        item.pricing_information.additional_costs,
        item.pricing_information.discounts,
        item.pricing_information.price,
        item.pricing_information.taxes,
        item.delivery_schedule_information.delivery_schedule_items,
        item.quantity_information.quantity,
    ]);
    const isDeliveryDateInvalid = () => {
        // returns list of delivery dates
        if (purchase_order_status === PurchaseOrderStatus.APPROVAL_PENDING) {
            if (
                item.delivery_schedule_information.delivery_schedule_items
                    .length === 1
            ) {
                if (
                    item.delivery_schedule_information
                        .delivery_schedule_items[0].delivery_date === null
                ) {
                    return false;
                }
            }
            const deliveryDatesMoments: Moment[] = [];
            item.delivery_schedule_information.delivery_schedule_items.forEach(
                (d) => {
                    if (d.delivery_date) {
                        deliveryDatesMoments.push(moment(d.delivery_date));
                    }
                }
            );
            if (deliveryDatesMoments.length > 0)
                return moment
                    .min(deliveryDatesMoments)
                    .isSameOrBefore(
                        moment(
                            moment().add(
                                item.procurement_information.lead_time,
                                item.procurement_information.lead_time_period.toLocaleLowerCase() as any
                            )
                        )
                    );
            else return false;
        } else return false;
    };

    const deliveryScheduleText =
        item.delivery_schedule_information.delivery_schedule_items.length === 1
            ? item.delivery_schedule_information.delivery_schedule_items[0]
                  .delivery_date === null ||
              item.delivery_schedule_information.delivery_schedule_items[0]
                  .delivery_date === ''
                ? 'TBD'
                : getFWDate(
                      item.delivery_schedule_information
                          .delivery_schedule_items[0].delivery_date
                  )
            : (dsQuantityDiffersFromItemQuantity
                  ? '(Includes rescheduled rejections) '
                  : '') +
              item.delivery_schedule_information.delivery_schedule_items
                  .map((del) => {
                      if (
                          del.delivery_date === null ||
                          del.delivery_date === ''
                      ) {
                          return `${del.quantity} ${item.quantity_information.measurement_unit_name} on TBD date`;
                      }
                      return `${del.quantity} ${
                          item.quantity_information.measurement_unit_name
                      } on ${getFWDate(del.delivery_date)}`;
                  })
                  .join(' | ');

    const selectedCurrency = useGetSelectedCurrency(
        item.pricing_information.currency_code_abbreviation
    );
    const {
        essentialTermsText,
        essentialTermsDescriptions,
        descriptionText,
        buyerItemSpecsText,
        buyerItemCustomSpecsText,
        paymentAndDeliveryText,
        paymentAndDeliveryDescriptions,
        additionalDetailsText,
        additionalDetailsDescriptions,
        additionalCostText,
        discountsText,
        taxesText,
    } = getPoReviewSectionStrings(
        item,
        rfqTemplate,
        selectedCurrency?.decimals ?? 2
    );

    const colWidthsSummary = getPoReviewColumnWidths({
        rfqTemplate,
        pdfWindowOpened,
        type: 'SUMMARY',
    });

    const colWidthsDetails = getPoReviewColumnWidths({
        rfqTemplate,
        pdfWindowOpened,
        type: 'DETAILS',
    });

    const totalTaxes = useMemo(() => {
        return calculateAddtionalCost({
            additionalCost: convertAdditionalCostBackendToFrontend(
                item.pricing_information.additional_costs
            ),
            baseRate: item.pricing_information.price,
            baseQuantity: item.quantity_information.quantity,
            type: 'TAX',
            discountAdditionalCost: convertAdditionalCostBackendToFrontend(
                item.pricing_information.discounts
            ),
        });
    }, [
        item.pricing_information.additional_costs,
        item.pricing_information.discounts,
        item.pricing_information.price,
        item.quantity_information.quantity,
    ]);

    const totalAdditionalCost = useMemo(() => {
        return calculateAddtionalCost({
            additionalCost: convertAdditionalCostBackendToFrontend(
                item.pricing_information.additional_costs
            ),
            baseRate: item.pricing_information.price,
            baseQuantity: item.quantity_information.quantity,
            type: 'ADDITIONAL_COST',
        });
    }, [
        item.pricing_information.price,
        item.pricing_information.additional_costs,
        item.quantity_information.quantity,
    ]);

    const totalDiscount = useMemo(() => {
        return calculateAddtionalCost({
            additionalCost: convertAdditionalCostBackendToFrontend(
                item.pricing_information.additional_costs
            ),
            baseRate: item.pricing_information.price,
            baseQuantity: item.quantity_information.quantity,
            type: 'DISCOUNT',
        });
    }, [
        item.pricing_information.additional_costs,
        item.pricing_information.price,
        item.quantity_information.quantity,
    ]);

    return (
        <div style={{ marginTop: '0px' }} className={'pageBreak'}>
            <StyledAccordion
                elevation={0}
                disableGutters={true}
                onChange={() => setOpen(!open)}
                expanded={pdfWindowOpened ? true : open}
            >
                <StyledAccordionSummary
                    expandIcon={<></>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Box
                        sx={{
                            width: '100%',
                            borderRadius: '10px',
                            paddingBottom: '1rem',
                        }}
                    >
                        <Box
                            style={{
                                ...boxHeaderStyle,
                                gridTemplateColumns: colWidthsSummary,
                            }}
                        >
                            <FWTypography sx={{ color: 'text.secondary' }}>
                                Sr.
                            </FWTypography>
                            <FWTypography sx={{ color: 'text.secondary' }}>
                                Item
                            </FWTypography>
                            <div className="flex flex--aic">
                                <FWTypography sx={{ color: 'text.secondary' }}>
                                    {
                                        rfqTemplate.fieldSettings.standardFields
                                            .QUANTITY.label
                                    }
                                </FWTypography>
                                {tableFieldDesc(
                                    rfqTemplate.fieldSettings.standardFields
                                        .QUANTITY.description
                                )}
                            </div>
                            <div className="flex flex--aic">
                                <FWTypography sx={{ color: 'text.secondary' }}>
                                    {
                                        rfqTemplate.fieldSettings.standardFields
                                            .BASE_RATE.label
                                    }
                                </FWTypography>
                                {tableFieldDesc(
                                    rfqTemplate.fieldSettings.standardFields
                                        .BASE_RATE.description
                                )}
                            </div>
                            <FWTypography sx={{ color: 'text.secondary' }}>
                                Subtotal
                            </FWTypography>
                            {/* taxes */}
                            {rfqTemplate.fieldSettings.standardFields.TAX
                                .buyerSettings.showField && (
                                <div className="flex flex--aic">
                                    <FWTypography
                                        sx={{ color: 'text.secondary' }}
                                    >
                                        {
                                            rfqTemplate.fieldSettings
                                                .standardFields.TAX.label
                                        }
                                    </FWTypography>
                                    <FWTypography>
                                        {tableFieldDesc(
                                            rfqTemplate.fieldSettings
                                                .standardFields.TAX.description
                                        )}
                                    </FWTypography>
                                </div>
                            )}
                            {/* additional cost  */}
                            {rfqTemplate.fieldSettings.standardFields
                                .ADDITIONAL_COSTS.buyerSettings.showField && (
                                <div className="flex flex--aic">
                                    <FWTypography
                                        sx={{ color: 'text.secondary' }}
                                    >
                                        {
                                            rfqTemplate.fieldSettings
                                                .standardFields.ADDITIONAL_COSTS
                                                .label
                                        }
                                    </FWTypography>
                                    <FWTypography>
                                        {tableFieldDesc(
                                            rfqTemplate.fieldSettings
                                                .standardFields.ADDITIONAL_COSTS
                                                .description
                                        )}
                                    </FWTypography>
                                </div>
                            )}
                            {/* discount */}
                            {rfqTemplate.fieldSettings.standardFields.DISCOUNT
                                .buyerSettings.showField && (
                                <div className="flex flex--aic">
                                    <FWTypography
                                        sx={{ color: 'text.secondary' }}
                                    >
                                        {
                                            rfqTemplate.fieldSettings
                                                .standardFields.DISCOUNT.label
                                        }
                                    </FWTypography>
                                    <FWTypography>
                                        {tableFieldDesc(
                                            rfqTemplate.fieldSettings
                                                .standardFields.DISCOUNT
                                                .description
                                        )}
                                    </FWTypography>
                                </div>
                            )}
                            {/* FWTypography not rendering in the accordion */}
                            {/* <FWTypography sx={{ color: 'text.secondary' }}>
                                Total amount
                            </FWTypography> */}
                            <Typography
                                sx={{
                                    color: 'text.secondary',
                                    width: 'max-content',
                                }}
                            >
                                Total amount
                            </Typography>
                        </Box>
                        <br />
                        <Box
                            style={{
                                ...boxContentStyle,
                                gridTemplateColumns: colWidthsSummary,
                            }}
                        >
                            <FWTypography>{idx + 1}</FWTypography>
                            <Box
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'start',
                                }}
                            >
                                <FWTypography>
                                    {item.item_information.buyer_item_name}
                                </FWTypography>
                                &nbsp;
                                {!pdfWindowOpened && (
                                    <i
                                        className="bi bi-chevron-down"
                                        style={{
                                            display: 'inline-block',
                                            transition:
                                                'transform 0.2s ease-in-out',
                                            transform: open
                                                ? `rotate(180deg)`
                                                : `rotate(0deg)`,
                                        }}
                                    ></i>
                                )}
                            </Box>
                            <FWTypography>
                                {item.quantity_information.quantity}{' '}
                                {
                                    item.quantity_information
                                        .measurement_unit_name
                                }
                                (s)
                            </FWTypography>
                            <FWTypography sx={{ width: 'fit-content' }}>
                                <FWCurrencyTypoGraphyWithCurrencyUid
                                    currencyUidOrAbbrevaition={
                                        item.pricing_information
                                            .currency_code_abbreviation
                                    }
                                    currency_code_abbreviation={
                                        item.pricing_information
                                            .currency_code_abbreviation
                                    }
                                    value={item.pricing_information.price}
                                />
                            </FWTypography>
                            <FWTypography sx={{ width: 'fit-content' }}>
                                <FWCurrencyTypoGraphyWithCurrencyUid
                                    currencyUidOrAbbrevaition={
                                        item.pricing_information
                                            .currency_code_abbreviation
                                    }
                                    currency_code_abbreviation={
                                        item.pricing_information
                                            .currency_code_abbreviation
                                    }
                                    value={subTotal}
                                />
                            </FWTypography>
                            {/* rfqTemplate.fieldSettings.standardFields.TAX
                                .buyerSettings.showField &&  */}

                            {rfqTemplate.fieldSettings.standardFields.TAX
                                .buyerSettings.showField && (
                                <AdditionalCostPopover
                                    additionalCost={convertAdditionalCostBackendToFrontend(
                                        item.pricing_information.taxes
                                    )}
                                    baseQuantity={
                                        item.quantity_information.quantity
                                    }
                                    baseRate={item.pricing_information.price}
                                    currency_code_abbreviation={
                                        item.pricing_information
                                            .currency_code_abbreviation
                                    }
                                    currency_code_symbol={
                                        item.pricing_information.currency_symbol
                                    }
                                    measurementUnitName={
                                        item.quantity_information
                                            .measurement_unit_name
                                    }
                                    clickable={false}
                                    showAdditionalCostsRate={false}
                                    type="Taxes"
                                    discountAdditionalCost={convertAdditionalCostBackendToFrontend(
                                        item.pricing_information.discounts
                                    )}
                                />
                            )}

                            {rfqTemplate.fieldSettings.standardFields
                                .ADDITIONAL_COSTS.buyerSettings.showField && (
                                <AdditionalCostPopover
                                    additionalCost={convertAdditionalCostBackendToFrontend(
                                        item.pricing_information
                                            .additional_costs
                                    )}
                                    baseQuantity={
                                        item.quantity_information.quantity
                                    }
                                    baseRate={item.pricing_information.price}
                                    currency_code_abbreviation={
                                        item.pricing_information
                                            .currency_code_abbreviation
                                    }
                                    currency_code_symbol={
                                        item.pricing_information.currency_symbol
                                    }
                                    measurementUnitName={
                                        item.quantity_information
                                            .measurement_unit_name
                                    }
                                    clickable={false}
                                    showAdditionalCostsRate={false}
                                    type="AdditionalCost"
                                />
                            )}

                            {rfqTemplate.fieldSettings.standardFields.DISCOUNT
                                .buyerSettings.showField && (
                                <AdditionalCostPopover
                                    additionalCost={convertAdditionalCostBackendToFrontend(
                                        item.pricing_information.discounts,
                                        false
                                    )}
                                    baseQuantity={
                                        item.quantity_information.quantity
                                    }
                                    baseRate={item.pricing_information.price}
                                    currency_code_abbreviation={
                                        item.pricing_information
                                            .currency_code_abbreviation
                                    }
                                    currency_code_symbol={
                                        item.pricing_information.currency_symbol
                                    }
                                    measurementUnitName={
                                        item.quantity_information
                                            .measurement_unit_name
                                    }
                                    clickable={false}
                                    showAdditionalCostsRate={false}
                                    type="AdditionalCost"
                                />
                            )}

                            <FWTypography sx={{ width: 'fit-content' }}>
                                <FWCurrencyTypoGraphyWithCurrencyUid
                                    currencyUidOrAbbrevaition={
                                        item.pricing_information
                                            .currency_code_abbreviation
                                    }
                                    currency_code_abbreviation={
                                        item.pricing_information
                                            .currency_code_abbreviation
                                    }
                                    value={total}
                                />
                            </FWTypography>
                        </Box>
                    </Box>
                </StyledAccordionSummary>
                <FWAccordionDetails>
                    <Box
                        sx={{
                            display: 'grid',
                            gridTemplateColumns: colWidthsDetails,
                            gridColumnGap: '17.5px',
                            rowGap: '1rem',
                        }}
                        marginTop="1rem"
                    >
                        {essentialTermsText.length > 0 && (
                            <>
                                <>
                                    <Box></Box>
                                    <Box className="flex flex--aic">
                                        <FWTypography variant="h5">
                                            {
                                                rfqTemplate.sections
                                                    .ESSENTIAL_TERMS.label
                                            }
                                            :
                                        </FWTypography>
                                        {essentialTermsDescriptions.length >
                                            0 && (
                                            <>
                                                &nbsp;
                                                <FWMultipleTooltipMessages
                                                    messages={essentialTermsDescriptions.map(
                                                        (d) =>
                                                            `${d.label}: ${d.description}`
                                                    )}
                                                    iconColor={'primary.main'}
                                                    icon={
                                                        <i className="bi bi-info-circle" />
                                                    }
                                                />
                                            </>
                                        )}
                                    </Box>
                                    <FWTypography
                                        color="text.secondary"
                                        sx={{
                                            wordWrap: 'break-word',
                                            whiteSpace: 'pre-wrap',
                                        }}
                                    >
                                        {essentialTermsText}
                                    </FWTypography>
                                    <Box></Box>
                                </>
                            </>
                        )}
                        {descriptionText.length > 0 && (
                            <>
                                <Box></Box>
                                <FWTypography variant="h5">
                                    Description:
                                </FWTypography>
                                <FWTypography
                                    color="text.secondary"
                                    sx={{
                                        wordWrap: 'break-word',
                                        whiteSpace: 'pre-wrap',
                                    }}
                                >
                                    {descriptionText}
                                </FWTypography>
                                <Box></Box>
                            </>
                        )}
                        {(buyerItemSpecsText.length > 0 ||
                            item.attributes.length > 0) && (
                            <>
                                <Box></Box>
                                <FWTypography variant="h5">
                                    Specifications:
                                </FWTypography>
                                <Box
                                    className="flex flex--aic"
                                    flexWrap={'wrap'}
                                >
                                    <Typography
                                        sx={{
                                            wordWrap: 'break-word',
                                            whiteSpace: 'pre-wrap',
                                        }}
                                    >
                                        {buyerItemSpecsText.trim().length >
                                            0 && <>{buyerItemSpecsText}</>}
                                        {buyerItemCustomSpecsText.length > 0 &&
                                            ' | '}

                                        <Box
                                            color="warning.main"
                                            component="span"
                                            sx={{
                                                wordWrap: 'break-word',
                                                whiteSpace: 'pre-wrap',
                                            }}
                                        >
                                            {buyerItemCustomSpecsText.join(
                                                ' | '
                                            )}
                                        </Box>
                                    </Typography>
                                </Box>

                                <Box></Box>
                            </>
                        )}

                        {paymentAndDeliveryText.length > 0 && (
                            <>
                                <Box></Box>
                                <div className="flex flex--aic">
                                    <FWTypography variant="h5">
                                        {
                                            rfqTemplate.sections
                                                .PAYMENT_AND_DELIVERY_TERMS
                                                .label
                                        }
                                    </FWTypography>
                                    {paymentAndDeliveryDescriptions.length >
                                        0 && (
                                        <>
                                            &nbsp;
                                            <FWMultipleTooltipMessages
                                                messages={paymentAndDeliveryDescriptions.map(
                                                    (d) =>
                                                        `${d.label}: ${d.description}`
                                                )}
                                                iconColor={'primary.main'}
                                                icon={
                                                    <i className="bi bi-info-circle" />
                                                }
                                            />
                                        </>
                                    )}
                                    {rfqTemplate.fieldSettings.standardFields
                                        .LEAD_TIME.sellerSettings.showField &&
                                        leadTimeSurpassed(
                                            item.procurement_information
                                                .lead_time,
                                            item.procurement_information
                                                .lead_time_period
                                        ) && (
                                            <>
                                                &nbsp;
                                                <FWTooltip title="Lead time exceeds 1 year">
                                                    <Box color="warning.main">
                                                        <i className="bi bi-info-circle"></i>
                                                    </Box>
                                                </FWTooltip>
                                            </>
                                        )}
                                </div>
                                <FWTypography
                                    color="text.secondary"
                                    sx={{
                                        wordWrap: 'break-word',
                                        whiteSpace: 'pre-wrap',
                                    }}
                                >
                                    {paymentAndDeliveryText}
                                </FWTypography>
                                <Box></Box>
                            </>
                        )}
                        <>
                            <Box></Box>
                            <div className="flex flex--aic">
                                <FWTypography variant="h5">
                                    Delivery schedule:
                                </FWTypography>
                                {isDeliveryDateInvalid() && (
                                    <>
                                        &nbsp;
                                        <FWTooltip title="Delivery date is earlier than the lead time provided">
                                            <Box color="warning.main">
                                                <i className="bi bi-exclamation-circle fs--16" />
                                            </Box>
                                        </FWTooltip>
                                    </>
                                )}
                            </div>
                            <FWTypography
                                color="text.secondary"
                                sx={{
                                    wordWrap: 'break-word',
                                    whiteSpace: 'pre-wrap',
                                }}
                            >
                                {deliveryScheduleText}
                            </FWTypography>
                            <Box></Box>
                        </>
                        {item.attachments.length > 0 && (
                            <>
                                <Box></Box>
                                <FWTypography variant="h5">
                                    Attachments:
                                </FWTypography>
                                <UploadedFilesList
                                    existingFiles={item.attachments}
                                    showRemove={false}
                                    type={'COMMA_SEPARATED'}
                                />
                                <Box></Box>
                            </>
                        )}
                        {additionalDetailsText.length > 0 && (
                            <>
                                <Box></Box>
                                <Box className="flex flex--aic">
                                    <FWTypography variant="h5">
                                        {
                                            rfqTemplate.sections
                                                .ADDITIONAL_DETAILS.label
                                        }
                                        :
                                    </FWTypography>
                                    {additionalDetailsDescriptions.length >
                                        0 && (
                                        <>
                                            &nbsp;
                                            <FWMultipleTooltipMessages
                                                messages={additionalDetailsDescriptions.map(
                                                    (d) =>
                                                        `${d.label}: ${d.description}`
                                                )}
                                                iconColor={'primary.main'}
                                                icon={
                                                    <i className="bi bi-info-circle" />
                                                }
                                            />
                                        </>
                                    )}
                                </Box>
                                <FWTypography
                                    color="text.secondary"
                                    sx={{
                                        wordWrap: 'break-word',
                                        whiteSpace: 'pre-wrap',
                                    }}
                                >
                                    {additionalDetailsText}
                                </FWTypography>
                                <Box></Box>
                            </>
                        )}
                        {Boolean(totalTaxes.additionalCostValue) &&
                            taxesText.length > 0 && (
                                <>
                                    <Box></Box>
                                    <div className="flex flex--aic">
                                        <FWTypography variant="h5">
                                            {
                                                rfqTemplate.fieldSettings
                                                    .standardFields.TAX.label
                                            }
                                            :
                                        </FWTypography>
                                    </div>
                                    <FWTypography
                                        color="text.secondary"
                                        sx={{
                                            wordWrap: 'break-word',
                                            whiteSpace: 'pre-wrap',
                                        }}
                                    >
                                        {taxesText}
                                    </FWTypography>
                                    <Box></Box>
                                </>
                            )}
                        {Boolean(totalAdditionalCost.additionalCostValue) &&
                            additionalCostText.length > 0 && (
                                <>
                                    <Box></Box>
                                    <div className="flex flex--aic">
                                        <FWTypography variant="h5">
                                            {
                                                rfqTemplate.fieldSettings
                                                    .standardFields
                                                    .ADDITIONAL_COSTS.label
                                            }
                                            :
                                        </FWTypography>
                                    </div>
                                    <FWTypography
                                        color="text.secondary"
                                        sx={{
                                            wordWrap: 'break-word',
                                            whiteSpace: 'pre-wrap',
                                        }}
                                    >
                                        {additionalCostText}
                                    </FWTypography>
                                    <Box></Box>
                                </>
                            )}
                        {Boolean(totalDiscount.additionalCostValue) &&
                            discountsText.length > 0 && (
                                <>
                                    <Box></Box>
                                    <div className="flex flex--aic">
                                        <FWTypography variant="h5">
                                            {
                                                rfqTemplate.fieldSettings
                                                    .standardFields.DISCOUNT
                                                    .label
                                            }
                                            :
                                        </FWTypography>
                                    </div>
                                    <FWTypography
                                        color="text.secondary"
                                        sx={{
                                            wordWrap: 'break-word',
                                            whiteSpace: 'pre-wrap',
                                        }}
                                    >
                                        {discountsText}
                                    </FWTypography>
                                    <Box></Box>
                                </>
                            )}
                        {item.external_notes.notes &&
                            item.external_notes.notes.trim().length > 0 && (
                                <>
                                    <Box></Box>
                                    <FWTypography variant="h5">
                                        PO Item Notes:
                                    </FWTypography>
                                    <FWTypography
                                        color="text.secondary"
                                        sx={{
                                            wordWrap: 'break-word',
                                            whiteSpace: 'pre-wrap',
                                        }}
                                    >
                                        {item.external_notes.notes.trim()}
                                    </FWTypography>
                                    <Box></Box>
                                </>
                            )}
                    </Box>
                </FWAccordionDetails>
            </StyledAccordion>
        </div>
    );
}
