import { DialogContent, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { DebouncedFunc } from 'lodash';
import { useEffect, Fragment, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import FWAutocomplete from '../../../Common/FWAutocomplete';
import { FWButton } from '../../../Common/FWButton';
import { FWMenuItem } from '../../../Common/FWCustomMenu';
import { FWTooltip } from '../../../Common/FWCustomTooltip';
import { FWInput } from '../../../Common/FWInput';
import { FWPopupWithChildren } from '../../../Common/FWPopupWithChildren';
import { FWTypography } from '../../../Common/FWTypograhy';
import LoadingBackDrop from '../../../Components/Shared/LoadingBackdrop';
import SignupPendingIcon from '../../../Global/Components/SignupPendingIcon';
import VendorVerificationStatusIcon from '../../../Global/Components/VendorVerificationStatusIcon';
import {
    IVendorAddDetails,
    IVendorListSummary,
    VendorStatus,
} from '../../../Organizations/Admin/Interfaces/VendorsInterface';
import VendorAddPopup from '../../../Organizations/Admin/Pages/Vendors/VendorAddPopup';
import {
    
    IVendorSearchedData,
    updateVendorItems,
    useFetchVendorsListQuery,
} from '../../../Organizations/Admin/Services/VendorsService';
import { IGlobalAppStore } from '../../../Redux/Store';
import { FWPopup } from '../../../Common/FWPopup';

const VendorAutoComplete = ({
    allVendorsFromSearch,
    debounceSearch,
    addedVendorsUid,
    addVendorToList,
    onInviteNewVendorSuccess,
    disableText,
}: {
    allVendorsFromSearch: IVendorSearchedData[] | null;
    debounceSearch: DebouncedFunc<(searchText: any) => Promise<void>>;
    addedVendorsUid: string[];
    addVendorToList: (vendor: IVendorAddDetails) => void;
    onInviteNewVendorSuccess: (vendorCode: any) => Promise<void>;
    disableText: string;
}) => {
    const allUserEntities = useSelector(
        (store: IGlobalAppStore) => store.VerificationStore.allUserEntities
    );
    const buyerEntity = useSelector(
        (store: IGlobalAppStore) =>
            store.EventCreationStore.eventDetails?.entity_id
    );

    const [existingEnterpriseVendors, setExistingEnterpriseVendors] = useState<
        IVendorListSummary[] | null
    >(null);
    const [openInvitationPopup, setOpenInvitationPopup] = useState(false);
    const [openAllVendorsPopup, setOpenAllVendorsPopup] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [alreadyAddedVendors, setAlreadyAddedVendors] = useState<string[]>(
        []
    );
    const [openLinkVendorPopup, setOpenLinkVendorPopup] = useState<{
        open: boolean;
        vendor: IVendorSearchedData;
    } | null>(null);
    const [invitedVendorRow, setInvitedVendorRow] =
        useState<IVendorAddDetails | null>(null);

    const addVendor = useCallback(
        async (vendor: IVendorSearchedData) => {
            setAlreadyAddedVendors((prev) => [...prev, vendor.vendorUid]);
            if (!vendor.is_Dir_Vendor) {
                setInvitedVendorRow(vendor);
                setOpenInvitationPopup(true);
            } else {
                if (!vendor.is_entity_linked) {
                    setOpenLinkVendorPopup({
                        open: true,
                        vendor: vendor,
                    });
                } else {
                    addVendorToList(vendor);
                }
            }
        },

        [addVendorToList]
    );

    const { data: vendorDirectoryRes } = useFetchVendorsListQuery({});
    useEffect(() => {
        if (vendorDirectoryRes) {
            setExistingEnterpriseVendors(vendorDirectoryRes);
        }
    }, [vendorDirectoryRes]);
    useEffect(() => {
        setAlreadyAddedVendors(addedVendorsUid);
    }, [addedVendorsUid]);
    useEffect(() => {
        debounceSearch(searchText);
    }, [searchText, debounceSearch]);

    return (
        <FWTooltip title={disableText} placement="bottom-start">
            <Box>
                <FWAutocomplete
                    // open={typing}
                    forcePopupIcon={false}
                    disabled={disableText.length > 0}
                    options={
                        allVendorsFromSearch
                            ? allVendorsFromSearch.length > 11
                                ? [
                                      ...(allVendorsFromSearch.slice(0, 11) ??
                                          []),
                                      ...(allVendorsFromSearch.slice(-1) ?? []),
                                  ]
                                : allVendorsFromSearch
                            : []
                    }
                    noOptionsText={
                        allVendorsFromSearch === null
                            ? 'No vendors found for this search'
                            : 'Loading...'
                    }
                    loading={allVendorsFromSearch !== null}
                    popupIcon={
                        <Typography>
                            <i className="bi bi-chevron-down" />
                        </Typography>
                    }
                    // autoComplete
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            placeholder="Search vendors by entity, enterprise, website, location, or user email"
                        />
                    )}
                    onInputChange={(e, value) => {
                        if (e === null) return;
                        if (searchText.trim() === '') debounceSearch(value);
                    }}
                    value={null}
                    isOptionEqualToValue={(a, b) => false}
                    getOptionLabel={(option: IVendorAddDetails) => {
                        return '';
                    }}
                    renderOption={(props: any, option: IVendorSearchedData) => {
                        const alreadyAdded = addedVendorsUid.includes(
                            option.vendorUid
                        );
                        const adminDisabled =
                            option.vendorStatus === VendorStatus.INACTIVE ||
                            option.vendorStatus === VendorStatus.DELETED;
                        const showInternalName =
                            option.vendor_internal_name &&
                            option.vendor_internal_name !== option.vendorName
                                ? true
                                : false;
                        return (
                            <Fragment key={option.vendorUid}>
                                <FWTooltip
                                    title={
                                        alreadyAdded
                                            ? 'Vendor already added'
                                            : adminDisabled
                                            ? 'Disabled by admin'
                                            : ''
                                    }
                                >
                                    <div>
                                        <FWMenuItem
                                            {...props}
                                            disabled={
                                                alreadyAdded || adminDisabled
                                            }
                                            sx={{
                                                color:
                                                    alreadyAdded ||
                                                    adminDisabled
                                                        ? 'text.secondary'
                                                        : undefined,
                                            }}
                                        >
                                            {option.is_new_invitation_row ? (
                                                <Box
                                                    display="flex"
                                                    flexDirection="column"
                                                >
                                                    {allVendorsFromSearch &&
                                                    allVendorsFromSearch.length >
                                                        11 ? (
                                                        <Box
                                                            display="flex"
                                                            justifyContent={
                                                                'center'
                                                            }
                                                            alignItems="center"
                                                            color="primary.main"
                                                        >
                                                            <Box
                                                                display="flex"
                                                                alignItems="center"
                                                                justifyContent="center"
                                                                flex="1"
                                                                fontSize={
                                                                    '1.5rem'
                                                                }
                                                                bgcolor="#007aff22"
                                                                borderRadius="50% !important"
                                                                width="40px"
                                                                height="40px"
                                                                padding="5px"
                                                                marginLeft="5px"
                                                            >
                                                                <i className="bi bi-three-dots"></i>
                                                            </Box>
                                                            &nbsp;
                                                            <Typography
                                                                padding="0px 10px 0px 5px"
                                                                fontSize={
                                                                    '1rem'
                                                                }
                                                                fontWeight="500"
                                                            >
                                                                {`Show All ${
                                                                    allVendorsFromSearch.length -
                                                                    1
                                                                } results`}
                                                            </Typography>
                                                        </Box>
                                                    ) : (
                                                        <Box
                                                            display="flex"
                                                            justifyContent={
                                                                'center'
                                                            }
                                                            alignItems="center"
                                                            color="primary.main"
                                                        >
                                                            <Box
                                                                display="flex"
                                                                alignItems="center"
                                                                justifyContent="center"
                                                                flex="1"
                                                                fontSize={
                                                                    '1.5rem'
                                                                }
                                                                bgcolor="#007aff22"
                                                                borderRadius="50% !important"
                                                                width="40px"
                                                                height="40px"
                                                                padding="5px"
                                                                marginLeft="5px"
                                                            >
                                                                <i className="bi bi-person-plus"></i>
                                                            </Box>
                                                            &nbsp;
                                                            <Typography
                                                                padding="0px 10px 0px 5px"
                                                                fontSize={
                                                                    '1rem'
                                                                }
                                                                fontWeight="500"
                                                            >
                                                                {`Invite vendor entity '${option.vendorName}' to join FactWise`}
                                                            </Typography>
                                                        </Box>
                                                    )}
                                                </Box>
                                            ) : (
                                                <>
                                                    <Box
                                                        display="flex"
                                                        justifyContent={
                                                            'center'
                                                        }
                                                        alignItems="center"
                                                    >
                                                        <Box
                                                            flex="1"
                                                            padding="5px"
                                                        >
                                                            {option.is_Dir_Vendor ? (
                                                                <FWTooltip
                                                                    title={
                                                                        'Vendor present in the directory'
                                                                    }
                                                                >
                                                                    <Box
                                                                        color="text.secondary"
                                                                        fontSize={
                                                                            '1.6rem'
                                                                        }
                                                                        bgcolor="#f2f2f2"
                                                                        borderRadius="50% !important"
                                                                        width="40px"
                                                                        height="40px"
                                                                        display="flex"
                                                                        alignItems="center"
                                                                        justifyContent="center"
                                                                    >
                                                                        <i className="bi bi-folder"></i>
                                                                    </Box>
                                                                </FWTooltip>
                                                            ) : (
                                                                <FWTooltip
                                                                    title={
                                                                        'Vendor not present in the directory'
                                                                    }
                                                                >
                                                                    <Box
                                                                        color="text.secondary"
                                                                        fontSize={
                                                                            '1.6rem'
                                                                        }
                                                                        bgcolor="#f2f2f2"
                                                                        borderRadius="50% !important"
                                                                        width="40px"
                                                                        height="40px"
                                                                        display="flex"
                                                                        alignItems="center"
                                                                        justifyContent="center"
                                                                    >
                                                                        <i className="bi bi-globe"></i>
                                                                    </Box>
                                                                </FWTooltip>
                                                            )}
                                                        </Box>
                                                        <Box padding="0px 10px 0px 5px">
                                                            <Box display="flex">
                                                                <Typography
                                                                    fontSize={
                                                                        '1rem'
                                                                    }
                                                                    fontWeight="500"
                                                                >
                                                                    {
                                                                        option.vendorName
                                                                    }
                                                                    &nbsp;
                                                                </Typography>
                                                                <VendorVerificationStatusIcon
                                                                    verificationStatus={
                                                                        option.verification_status
                                                                    }
                                                                />
                                                                {option.is_virtual && (
                                                                    <>
                                                                        &nbsp;
                                                                        <SignupPendingIcon />
                                                                    </>
                                                                )}
                                                                <Typography color="text.secondary">
                                                                    &nbsp;
                                                                    {option.is_Dir_Vendor
                                                                        ? showInternalName
                                                                            ? ` (${option.vendorCode} \u00B7 ${option.vendor_internal_name})`
                                                                            : ` (${option.vendorCode})`
                                                                        : ''}
                                                                </Typography>
                                                            </Box>
                                                            <Typography
                                                                fontSize={
                                                                    '0.9rem'
                                                                }
                                                                color="text.secondary"
                                                            >
                                                                {[
                                                                    option.entity_doing_business_as,
                                                                    option.website,
                                                                    [
                                                                        option
                                                                            .address_info
                                                                            ?.city,
                                                                        option
                                                                            .address_info
                                                                            ?.country,
                                                                    ]
                                                                        .filter(
                                                                            Boolean
                                                                        )
                                                                        .join(
                                                                            ', '
                                                                        ),
                                                                ]
                                                                    .filter(
                                                                        Boolean
                                                                    )
                                                                    .join(
                                                                        ' \u00B7 '
                                                                    )}
                                                            </Typography>
                                                            <Typography
                                                                fontSize={
                                                                    '0.9rem'
                                                                }
                                                                color="text.secondary"
                                                            >
                                                                {option.is_Dir_Vendor
                                                                    ? [
                                                                          option.vendor_contact_name,
                                                                          option.vendor_contact_email,
                                                                      ]
                                                                          .filter(
                                                                              Boolean
                                                                          )
                                                                          .join(
                                                                              ' \u00B7 '
                                                                          )
                                                                    : [
                                                                          option.globalName,
                                                                          option.globalEmail,
                                                                      ]
                                                                          .filter(
                                                                              Boolean
                                                                          )
                                                                          .join(
                                                                              ' \u00B7 '
                                                                          )}
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                </>
                                            )}
                                        </FWMenuItem>
                                    </div>
                                </FWTooltip>
                            </Fragment>
                        );
                    }}
                    onChange={(e, value: IVendorSearchedData) => {
                        if (
                            value.is_new_invitation_row &&
                            allVendorsFromSearch &&
                            allVendorsFromSearch.length > 11
                        ) {
                            setOpenAllVendorsPopup(true);
                            setSearchText(value.vendorName);
                        } else {
                            addVendor(value);
                        }
                    }}
                    clearOnEscape={true}
                    // blurOnSelect={true}
                    // clearOnBlur={false}
                    filterOptions={(options, state) => options}
                />
                <VendorAddPopup
                    popupToggle={
                        openInvitationPopup &&
                        existingEnterpriseVendors !== null
                    }
                    allEnterpriseVendors={existingEnterpriseVendors ?? []}
                    entities={allUserEntities}
                    handlePopupToggle={() => {
                        setOpenInvitationPopup(false);
                        //To avoid opening Blank Screen on same ID
                        setInvitedVendorRow(null);
                    }}
                    fixedVendorDetails={invitedVendorRow}
                    onSuccess={(
                        enterprise_vendor_master_id: string,
                        vendor_code: string
                    ) => {
                        onInviteNewVendorSuccess(vendor_code);
                    }}
                    onFailure={() => {
                        setAlreadyAddedVendors((prev) => {
                            if (prev) {
                                prev.pop();
                                return [...prev];
                            }
                            return [];
                        });
                    }}
                />
                {/* Popup To confirm wether to link an entity */}
                <FWPopup
                    preLine
                    open={openLinkVendorPopup?.open ?? false}
                    msg={
                        'This Vendor Entity is not Linked to your buyer entity? Do you want to link it?'
                    }
                    onClickConfirm={async () => {
                        if (!openLinkVendorPopup) return;
                        const vendorLinked = await updateVendorItems(
                            openLinkVendorPopup?.vendor
                                .enterpriseVendorMasterId ?? '',
                            {
                                buyer_entity: buyerEntity ?? '',
                                status: 'STANDARD',
                                items: [],
                            }
                        );
                        if (vendorLinked) {
                            addVendorToList(openLinkVendorPopup?.vendor ?? {});
                        }
                        setOpenLinkVendorPopup(null);
                    }}
                    title={'Link Vendor Entity'}
                    setOpen={() => setOpenLinkVendorPopup(null)}
                    btnColor={'primary'}
                />
                {/* Popup To show all the vendors from search results */}
                <FWPopupWithChildren
                    open={openAllVendorsPopup}
                    title="Search Vendors"
                    handleClose={() => {
                        setSearchText('');
                        debounceSearch('');
                        setOpenAllVendorsPopup(false);
                    }}
                    size="large"
                >
                    <DialogContent sx={{ paddingTop: '0px' }}>
                        <Box>
                            <Box paddingBottom="15px">
                                <FWInput
                                    onChange={(e: any) => {
                                        setSearchText(e.target.value);
                                    }}
                                    sx={{
                                        marginBottom: '10px',
                                    }}
                                    value={searchText}
                                    placeholder="Search vendors by entity, enterprise, website, location, or user email"
                                />
                                {allVendorsFromSearch && (
                                    <Typography color="text.secondary">
                                        Showing{' '}
                                        {allVendorsFromSearch?.length - 1}{' '}
                                        Results
                                    </Typography>
                                )}
                            </Box>
                            <Box
                                overflow={'scroll'}
                                height="400px"
                                sx={{
                                    '&::-webkit-scrollbar': {
                                        width: '0.3em',
                                    },
                                }}
                            >
                                {allVendorsFromSearch?.map((vendor) => {
                                    const alreadyAdded =
                                        alreadyAddedVendors.includes(
                                            vendor.vendorUid
                                        );
                                    const adminDisabled =
                                        vendor.vendorStatus ===
                                            VendorStatus.INACTIVE ||
                                        vendor.vendorStatus ===
                                            VendorStatus.DELETED;
                                    const showInternalName =
                                        vendor.vendor_internal_name &&
                                        vendor.vendor_internal_name !==
                                            vendor.vendorName
                                            ? true
                                            : false;
                                    return (
                                        <>
                                            {vendor.is_new_invitation_row ? (
                                                <Box
                                                    display="flex"
                                                    justifyContent="start"
                                                    alignItems="center"
                                                    color="primary.main"
                                                    marginLeft="8px"
                                                >
                                                    <Box
                                                        display="flex"
                                                        alignItems="center"
                                                        justifyContent="center"
                                                        fontSize={'1.5rem'}
                                                        bgcolor="#007aff22"
                                                        borderRadius="50% !important"
                                                        width="40px"
                                                        height="40px"
                                                        padding="5px"
                                                    >
                                                        <i className="bi bi-person-plus"></i>
                                                    </Box>
                                                    &nbsp;
                                                    <Box
                                                        onClick={() => {
                                                            addVendor(vendor);
                                                        }}
                                                        sx={{
                                                            cursor: 'pointer',
                                                            padding: '5px',
                                                            borderRadius: '5px',
                                                            '&:hover': {
                                                                backgroundColor:
                                                                    '#007aff11',
                                                            },
                                                        }}
                                                    >
                                                        <Typography
                                                            padding="0px 10px 0px 5px"
                                                            fontSize={'1rem'}
                                                            fontWeight="500"
                                                        >
                                                            {`Invite vendor entity '${vendor.vendorName}' to join FactWise`}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            ) : (
                                                <>
                                                    <Box
                                                        display="flex"
                                                        justifyContent={
                                                            'center'
                                                        }
                                                        alignItems="center"
                                                        marginBottom="10px"
                                                    >
                                                        <Box
                                                            flex="0.4"
                                                            padding="5px"
                                                            color={
                                                                alreadyAdded ||
                                                                adminDisabled
                                                                    ? 'text.secondary'
                                                                    : 'text.primary'
                                                            }
                                                        >
                                                            {alreadyAdded ||
                                                            vendor.is_Dir_Vendor ? (
                                                                <FWTooltip
                                                                    title={
                                                                        'Vendor present in the directory'
                                                                    }
                                                                >
                                                                    <Box
                                                                        color="text.secondary"
                                                                        fontSize={
                                                                            '1.6rem'
                                                                        }
                                                                        bgcolor="#f2f2f2"
                                                                        borderRadius="50% !important"
                                                                        width="40px"
                                                                        height="40px"
                                                                        display="flex"
                                                                        alignItems="center"
                                                                        justifyContent="center"
                                                                    >
                                                                        <i className="bi bi-folder"></i>
                                                                    </Box>
                                                                </FWTooltip>
                                                            ) : (
                                                                <FWTooltip
                                                                    title={
                                                                        'Vendor not present in the directory'
                                                                    }
                                                                >
                                                                    <Box
                                                                        color="text.secondary"
                                                                        fontSize={
                                                                            '1.6rem'
                                                                        }
                                                                        bgcolor="#f2f2f2"
                                                                        borderRadius="50% !important"
                                                                        width="40px"
                                                                        height="40px"
                                                                        display="flex"
                                                                        alignItems="center"
                                                                        justifyContent="center"
                                                                    >
                                                                        <i className="bi bi-globe"></i>
                                                                    </Box>
                                                                </FWTooltip>
                                                            )}
                                                        </Box>
                                                        <Box
                                                            padding="0px 10px 0px 5px"
                                                            flex="5"
                                                            color={
                                                                alreadyAdded
                                                                    ? 'text.secondary'
                                                                    : 'text.primary'
                                                            }
                                                        >
                                                            <Box
                                                                display="flex"
                                                                alignItems="center"
                                                            >
                                                                <FWTypography
                                                                    fontSize={
                                                                        '1rem'
                                                                    }
                                                                    fontWeight="500"
                                                                >
                                                                    {
                                                                        vendor.vendorName
                                                                    }
                                                                    &nbsp;
                                                                </FWTypography>

                                                                <VendorVerificationStatusIcon
                                                                    verificationStatus={
                                                                        vendor.verification_status
                                                                    }
                                                                />
                                                                {vendor.is_virtual && (
                                                                    <>
                                                                        &nbsp;
                                                                        <SignupPendingIcon />
                                                                    </>
                                                                )}
                                                                <FWTypography color="text.secondary">
                                                                    &nbsp;
                                                                    {vendor.is_Dir_Vendor
                                                                        ? showInternalName
                                                                            ? ` (${vendor.vendorCode} \u00B7 ${vendor.vendor_internal_name})`
                                                                            : ` (${vendor.vendorCode})`
                                                                        : ''}
                                                                </FWTypography>
                                                            </Box>
                                                            <Typography
                                                                fontSize={
                                                                    '0.9rem'
                                                                }
                                                                color="text.secondary"
                                                            >
                                                                {[
                                                                    vendor.entity_doing_business_as,
                                                                    vendor.website,
                                                                    [
                                                                        vendor
                                                                            .address_info
                                                                            ?.city,
                                                                        vendor
                                                                            .address_info
                                                                            ?.country,
                                                                    ]
                                                                        .filter(
                                                                            Boolean
                                                                        )
                                                                        .join(
                                                                            ', '
                                                                        ),
                                                                ]
                                                                    .filter(
                                                                        Boolean
                                                                    )
                                                                    .join(
                                                                        ' \u00B7 '
                                                                    )}
                                                            </Typography>
                                                            <Typography
                                                                fontSize={
                                                                    '0.9rem'
                                                                }
                                                                color="text.secondary"
                                                            >
                                                                {vendor.is_Dir_Vendor
                                                                    ? [
                                                                          vendor.vendor_contact_name,
                                                                          vendor.vendor_contact_email,
                                                                      ]
                                                                          .filter(
                                                                              Boolean
                                                                          )
                                                                          .join(
                                                                              ' \u00B7 '
                                                                          )
                                                                    : [
                                                                          vendor.globalName,
                                                                          vendor.globalEmail,
                                                                      ]
                                                                          .filter(
                                                                              Boolean
                                                                          )
                                                                          .join(
                                                                              ' \u00B7 '
                                                                          )}
                                                            </Typography>
                                                        </Box>
                                                        <Box
                                                            display={'flex'}
                                                            flex="3"
                                                            justifyContent="end"
                                                            marginRight="10px"
                                                        >
                                                            <FWButton
                                                                disabled={
                                                                    alreadyAdded ||
                                                                    adminDisabled
                                                                }
                                                                onClick={() => {
                                                                    addVendor(
                                                                        vendor
                                                                    );
                                                                }}
                                                            >
                                                                {!adminDisabled
                                                                    ? alreadyAdded
                                                                        ? !vendor.is_Dir_Vendor
                                                                            ? 'Added to Event and Directory'
                                                                            : 'Added to Event'
                                                                        : !vendor.is_Dir_Vendor
                                                                        ? 'Add to Event and Directory'
                                                                        : 'Add to Event'
                                                                    : 'Vendor is Disabled'}
                                                            </FWButton>
                                                        </Box>
                                                    </Box>
                                                </>
                                            )}
                                        </>
                                    );
                                }) ?? (
                                    <Typography
                                        textAlign={'center'}
                                        color="text.secondary"
                                    >
                                        No vendors found for this search
                                    </Typography>
                                )}
                            </Box>
                        </Box>
                    </DialogContent>
                </FWPopupWithChildren>
                {openInvitationPopup && existingEnterpriseVendors === null && (
                    <LoadingBackDrop />
                )}
            </Box>
        </FWTooltip>
    );
};

export default VendorAutoComplete;
