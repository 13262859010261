import { post } from '../../Utils/api';
import { IHADownloadPayloadType } from '../Interfaces/IHistoricalAnalyticsApi.model';

/**
 * TODO:
 * Type and rename this service based on the actual implementation.
 * The URL should be updated too
 * I assume this is likely to return a download URL.
 */
export const downloadDataService = async (
    payload: IHADownloadPayloadType
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await post<IHADownloadPayloadType, any>(
                `/export/analytics/download/`,
                payload
            );
            resolve(response.data);
        } catch (e) {
            reject(e);
        }
    });
};

export const fetchExportFileURL = async (
    exportId: string,
    resource_type: string
) => {
    try {
        const response = await post<any, any>(`/export/url/generate/download`, {
            export_id: exportId,
            resource_type,
        });
        return response.data;
    } catch (e) {
        throw e;
    }
};
