import { Box, Divider, IconButton } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FWMenu, FWMenuItem } from '../../Common/FWCustomMenu';
import { FWTooltip } from '../../Common/FWCustomTooltip';
import FWIcon from '../../Common/FWIcon';
import { IGlobalAppStore } from '../../Redux/Store';

const actionPaths = {
    addItemPath: '/admin/items/item-directory/add/new',
    addVendorPath: '/admin/vendors',
    addUsersPath: '/admin/users',
    manageAddressesPath: '/admin/address',
    settingsPath: '/admin/settings',
    editCompanyInfo: '/admin/home',
    editCompanyId: '/admin/home/entity/',
    companyProfile: '/seller/profile/',
};

// const addActions = [
//     {
//         text: 'Add item(s)',
//         value: 'ADD_ITEM',
//         path: actionPaths.addItemPath,
//     },
//     {
//         text: 'Add vendor(s)',
//         value: 'ADD_VENDOR',
//         path: actionPaths.addVendorPath,
//     },
//     {
//         text: 'Add users(s)',
//         value: 'ADD_USERS',
//         path: actionPaths.addUsersPath,
//     },
// ];

const editActions = [
    {
        text: 'Edit Company Information',
        value: 'EDIT_COMPANY_INFO',
        path: actionPaths.editCompanyInfo,
    },
    {
        text: 'Edit Company Identifications',
        value: 'EDIT_COMPANY_ID',
        path: actionPaths.editCompanyId,
    },
    {
        text: 'Edit Company Addresses',
        value: 'EDIT_COMPANY_ADDRESSES',
        path: actionPaths.manageAddressesPath,
    },
];

const AdminActionsForSellerContainer = ({
    entityId,
}: {
    entityId: string | undefined;
}) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const SINGLE_ENTITY = useSelector(
        (store: IGlobalAppStore) => store.VerificationStore.hasSignleEntity
    );

    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) =>
        setAnchorEl(event.currentTarget);
    const handleClose = () => {
        setAnchorEl(null);
        // setTimeout(() => {
        //     // setMoreOptionsClicked(false);
        // }, 150);
    };

    const history = useHistory();
    const { pathname } = history.location;

    // const [adminAction, setAdminAction] = useState<
    //     | 'ADD_ITEM'
    //     | 'ADD_VENDOR'
    //     | 'ADD_USERS'
    //     | 'MANAGE_ADDRESSES'
    //     | 'SETTINGS'
    //     | null
    // >(null);

    const [adminAction, setAdminAction] = useState<
        | 'EDIT_COMPANY_INFO'
        | 'EDIT_COMPANY_ID'
        | 'EDIT_COMPANY_ADDRESSES'
        | 'COMPANY_PROFILE'
        | null
    >(null);

    useEffect(() => {
        if (pathname.includes(actionPaths.editCompanyInfo)) {
            setAdminAction('EDIT_COMPANY_INFO');
        } else if (pathname.includes(actionPaths.manageAddressesPath)) {
            setAdminAction('EDIT_COMPANY_ADDRESSES');
        } else if (pathname.includes(actionPaths.settingsPath)) {
            setAdminAction('EDIT_COMPANY_ID');
        } else if (pathname.includes(actionPaths.companyProfile)) {
            setAdminAction('COMPANY_PROFILE');
        } else {
            setAdminAction(null);
        }
    }, [pathname]);

    return (
        <>
            <FWTooltip title="Admin actions">
                <Box className="flex flex--aic">
                    <IconButton color="primary" onClick={handleClick}>
                        <FWIcon name="bi bi-gear" size={23} />
                    </IconButton>
                </Box>
            </FWTooltip>
            <FWMenu
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                sx={{
                    maxHeight: '80vh',
                    padding: 0,
                    top: '4px',
                    width: 300,
                }}
            >
                {editActions.map(({ text, path, value }, idx) => {
                    if (SINGLE_ENTITY && value === 'EDIT_COMPANY_ID')
                        return <></>;

                    return (
                        <FWMenuItem
                            key={idx}
                            value={value}
                            onClick={() => {
                                if (adminAction !== value) {
                                    if (
                                        value === 'EDIT_COMPANY_ID' &&
                                        entityId
                                    ) {
                                        history.push({
                                            pathname: `${path}${entityId}`,
                                        });
                                    } else
                                        history.push({
                                            pathname: path,
                                        });
                                }
                                localStorage.setItem(
                                    'openAdminActionPopup',
                                    JSON.stringify({
                                        popUpToOpen: value,
                                        openPopup: true,
                                    })
                                );
                                handleClose();
                            }}
                        >
                            {text}
                        </FWMenuItem>
                    );
                })}

                <Divider />
                <Box
                    sx={{
                        // height: moreOptionsClicked ? '70px' : '0px',
                        overflow: 'hidden',
                        transition: 'height 0.3s ease-in-out',
                    }}
                >
                    <FWMenuItem
                        value={'COMPANY_PROFILE'}
                        onClick={() => {
                            history.push(actionPaths.companyProfile);
                            handleClose();
                        }}
                        disabled={adminAction === 'COMPANY_PROFILE'}
                    >
                        <Box color="primary.main">Manage Company Profile</Box>
                    </FWMenuItem>
                </Box>
            </FWMenu>
        </>
    );
};

export default AdminActionsForSellerContainer;
