import { Backdrop, CircularProgress } from '@mui/material';
// import loadingGif from '../../Assets/Images/FwLogo.gif';

export default function LoadingBackDrop() {
    return (
        <Backdrop
            style={{
                zIndex: 1000000,
                color: '#fff',
            }}
            open={true}
        >
            <CircularProgress style={{ color: 'black' }} />
            {/* <img src={loadingGif} alt="loading" width="250px" height="250px" /> */}
        </Backdrop>
    );
}
