import { Box } from '@mui/material';
import { FWTooltip } from '../../Common/FWCustomTooltip';

export default function SignupPendingIcon() {
    let tooltip = 'Signup pending';
    let iconName = 'bi bi-hourglass-split';
    let iconColor = 'warning.main';
    return (
        <FWTooltip title={tooltip}>
            <Box color={iconColor}>
                <i className={iconName} />
            </Box>
        </FWTooltip>
    );
}
