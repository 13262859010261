import { useEffect, useState } from 'react';
import {
    HookStateValue,
    useHookState,
} from '../../../../Common/Hooks/StateHook';
import { blankVendorTemplate } from '../../Helpers/VendorHelpers';
import { IVendorTemplateFrontEnd } from '../../Interfaces/VendorsInterface';
import {
    useGetVendorTemplateByIdQuery,
    useGetVendortemplateDetailsQuery,
} from '../../Services/VendorsService';

export const useVendorTemplateHook = () => {
    const { hookState, updateHookState } = useHookState(HookStateValue.LOADING);

    const [vendorIds, setVendorIds] = useState<{
        templateId: string;
        entityId: string;
    }>({
        entityId: '',
        templateId: '',
    });
    const {
        data: vendorTemplates,
        isLoading,
        error,
        isError: vendorTemplatesError,
    } = useGetVendortemplateDetailsQuery({});

    useEffect(() => {
        if (vendorTemplates && vendorTemplates.length > 0) {
            let templateId = vendorTemplates[0]?.templates[0]?.template_id;
            let entityId = vendorTemplates[0]?.templates[0]?.entity_id;

            setVendorIds({ entityId, templateId });
        }
    }, [vendorTemplates]);

    const { data: VendorTemplateDetails, isError: vendorTemplateDetailsError } =
        useGetVendorTemplateByIdQuery(
            { templateId: vendorIds.templateId, entityId: vendorIds.entityId },
            { skip: vendorIds.entityId === '' || vendorIds.templateId === '' }
        );

    const [templateDetails, setTemplateDetails] =
        useState<IVendorTemplateFrontEnd>(blankVendorTemplate);
    useEffect(() => {
        if (isLoading && !VendorTemplateDetails) {
            updateHookState(HookStateValue.LOADING);
        }
    }, [VendorTemplateDetails, isLoading, updateHookState]);

    useEffect(() => {
        if (VendorTemplateDetails && isLoading === false) {
            setTemplateDetails(VendorTemplateDetails);
            updateHookState(HookStateValue.READY);
        }

        // else if (isLoading === false)
    }, [VendorTemplateDetails, isLoading, updateHookState]);
    useEffect(() => {
        if (
            (vendorTemplateDetailsError && vendorIds.templateId !== '') ||
            vendorTemplatesError
        ) {
            updateHookState(HookStateValue.ERROR);
        }
    }, [
        updateHookState,
        error,
        vendorTemplateDetailsError,
        vendorIds.templateId,
        vendorTemplatesError,
    ]);

    return {
        hookState,
        templateDetails,
    };
};
