import { IconButton, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import HelpSectionImage from '../Components/HelpDisplayDataComponents/HelpSectionImage';
import { IHelpSBS } from '../Interfaces/HelpSBSInterface';

interface HelpStepByStepProps {
    viewGuide: IHelpSBS;
    setViewGuide: React.Dispatch<React.SetStateAction<IHelpSBS>>;
    setSearchText: React.Dispatch<React.SetStateAction<string>>;
}

const HelpStepByStep = ({
    setViewGuide,
    viewGuide,
    setSearchText,
}: HelpStepByStepProps) => {
    const { sbsArr } = viewGuide;
    return (
        <Box className="pr--20 pb--20 pl--20" overflow={'scroll'}>
            <IconButton
                style={{ padding: 0 }}
                color="primary"
                onClick={() => {
                    setViewGuide({ state: false, sbsArr: null });
                    setSearchText('');
                }}
            >
                <i className="bi bi-arrow-left"></i>
            </IconButton>

            <Typography variant={'h4'} fontSize={20}>
                {sbsArr?.title}
            </Typography>
            {sbsArr?.startPara && <Typography>{sbsArr?.startPara}</Typography>}
            <Box>
                {sbsArr?.steps.map(({ customHeading, point, imageUrl }, i) => (
                    <Box className="mt--10">
                        <Typography variant={'h5'}>
                            Step {i + 1}: {customHeading ?? ''}
                        </Typography>
                        <Typography>{point}</Typography>
                        {imageUrl && (
                            <div className="mb--20">
                                <HelpSectionImage image={imageUrl} />
                            </div>
                        )}
                    </Box>
                ))}
            </Box>
        </Box>
    );
};

export default HelpStepByStep;
