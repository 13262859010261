import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { isEmpty } from 'lodash';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import PreferredVendorsContainer from '../../Containers/ItemDirectory/PreferredVendorsContainer';
import { usePreferredVendors } from '../../Hooks/ItemDirectory/ItemPreferredVendorsHook';
import {
    IItemEntityDetail,
    ItemEntityStatus,
} from '../../Interfaces/ItemDirectoryInterface';
import ItemPreferredVendorsPopup from '../../Pages/ItemDirectory/ItemPreferredVendorsPopup';

const ItemPreferredVendorsCell = ({
    data,
    context,
}: {
    data: IItemEntityDetail;
    context: {
        itemUid: string;
        handlePreferredVendorsCountUpdate: (
            count: number,
            index: number
        ) => void;
        autoOpen: string;
        setAutoOpen: Dispatch<SetStateAction<string>>;
    };
}) => {
    const {
        itemUid,
        handlePreferredVendorsCountUpdate,
        autoOpen,
        setAutoOpen,
    } = context;
    const [open, setOpen] = useState<boolean>(false);

    const handlePopupToggle = () => {
        if (!isEmpty(autoOpen)) {
            setAutoOpen('');
            setOpen(false);
            return;
        }
        setOpen(!open);
    };

    const { preferredVendors } = usePreferredVendors(data.entityId, itemUid);

    useEffect(() => {
        handlePreferredVendorsCountUpdate(preferredVendors.length, data.id!);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [preferredVendors, data.id]);

    useEffect(() => {
        if (!isEmpty(autoOpen) && autoOpen === data.entityId) {
            setOpen(true);
        }
    }, [autoOpen, data.entityId]);

    return (
        <>
            <Box color="primary.main"></Box>
            <Typography
                sx={{
                    cursor:
                        data.status === ItemEntityStatus.INACTIVE
                            ? undefined
                            : 'pointer',
                    textAlign: 'center',
                    margin: '0 auto',
                }}
                color={
                    data.status === ItemEntityStatus.INACTIVE
                        ? 'text.secondary'
                        : 'primary.main'
                }
                ref={(ref: HTMLParagraphElement) => {
                    if (!ref) return;
                    ref.onclick = (e: any) => {
                        e.stopPropagation();
                        if (data.status === ItemEntityStatus.ACTIVE)
                            handlePopupToggle();
                    };
                }}
            >
                {data.status === ItemEntityStatus.ACTIVE
                    ? data.preferredVendors
                    : 0}
            </Typography>
            <ItemPreferredVendorsPopup
                popupToggle={open}
                handlePopupToggle={handlePopupToggle}
            >
                <PreferredVendorsContainer
                    entityUid={data.entityId}
                    itemUid={itemUid}
                    onSaveSuccess={() => {
                        handlePopupToggle();
                    }}
                    onVendorsLoaded={(pv) => {
                        handlePreferredVendorsCountUpdate(pv.length, data.id!);
                    }}
                />
            </ItemPreferredVendorsPopup>
        </>
    );
};

export default ItemPreferredVendorsCell;
