import { createSlice } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';
import { customObject } from '../../Components/Seller/Events/OpeningBid/helperfunctions';
import { IEventItemDetails } from '../../Events/Interfaces/Buyer/RFQ.model';
import { ICustomSellerBid } from '../../Models/SellerRFQ.model';

export interface IRFQOpeningBidStore {
    customBidObject: ICustomSellerBid;
    errors: {
        [rfq_item_entry_id: string]: {
            error: boolean;
            msg: string;
            rfqData: IEventItemDetails | null;
            itemNo: number;
        };
    };
    errorsCopy: {
        [rfq_item_entry_id: string]: {
            error: boolean;
            msg: string;
            rfqData: IEventItemDetails | null;
            itemNo: number;
        };
    };
    filteringType: 'ALL_ITEMS' | 'ITEMS_WITH_ERRORS' | 'EXCLUDED_ITEMS';
}

export type IRFQOpeningBidStoreActions = {
    type: string;
    payload:
        | {
              type: 'UPDATE_CUSTOM_BID_OBJECT';
              value: ICustomSellerBid;
          }
        | {
              type: 'UPDATE_SINGLE_BID_OBJECT_ERR';
              value: {
                  error: boolean;
                  msg: string;
                  rfq_item_entry_id: string;
              };
          }
        | {
              type: 'UPDATE_MULTIPLE_BID_OBJECT_ERR';
              value: {
                  [rfq_item_entry_id: string]: {
                      error: boolean;
                      msg: string;
                  };
              };
          }
        | {
              type: 'UPDATE_SINGLE_BID_OBJECT_ERR_RFQ_DATA';
              value: {
                  rfq_item_entry_id: string;
                  rfqData: IEventItemDetails;
                  itemNo: number;
              };
          }
        | {
              type: 'UPDATE_MULTIPLE_BID_OBJECT_ERR_RFQ_DATA';
              value: {
                  [rfq_item_entry_id: string]: {
                      rfqData: IEventItemDetails;
                      itemNo: number;
                  };
              };
          }
        | {
              type: 'UPDATE_SEARCH_FILTERS';
              value: {
                  filteringType?:
                      | 'ALL_ITEMS'
                      | 'ITEMS_WITH_ERRORS'
                      | 'EXCLUDED_ITEMS';
                  currentPage?: number;
              };
          }
        | {
              type: 'RESET';
              value: null;
          };
};

export const RfqOpeningBidSlice = createSlice<
    IRFQOpeningBidStore,
    {
        updateRfqOpeningBid: (
            state: IRFQOpeningBidStore,
            action: IRFQOpeningBidStoreActions
        ) => void;
    },
    string
>({
    initialState: {
        customBidObject: customObject,
        errors: {},
        errorsCopy: {},
        filteringType: 'ALL_ITEMS',
    },
    name: 'RfqOpeningBid',
    reducers: {
        updateRfqOpeningBid: (
            state: IRFQOpeningBidStore,
            action: IRFQOpeningBidStoreActions
        ) => {
            switch (action.payload.type) {
                case 'UPDATE_CUSTOM_BID_OBJECT':
                    state.customBidObject = action.payload.value;
                    break;

                case 'UPDATE_MULTIPLE_BID_OBJECT_ERR':
                    const listOfErrorsBeingUpdated = Object.keys(
                        action.payload.value
                    );

                    const payloadValue1 = action.payload.value;
                    listOfErrorsBeingUpdated.forEach((key) => {
                        if (payloadValue1[key])
                            if (state.errors[key]) {
                                state.errors[key].error =
                                    payloadValue1[key].error;
                                state.errors[key].msg = payloadValue1[key].msg;
                            } else {
                                state.errors[key] = {
                                    error: payloadValue1[key].error,
                                    msg: payloadValue1[key].msg,
                                    rfqData: null,
                                    itemNo: 0,
                                };
                            }
                    });

                    if (state.filteringType !== 'ITEMS_WITH_ERRORS') {
                        state.errorsCopy = cloneDeep(state.errors);
                    }

                    break;
                case 'UPDATE_SINGLE_BID_OBJECT_ERR':
                    state.errors = {
                        ...state.errors,
                        [action.payload.value.rfq_item_entry_id]: {
                            ...state.errors[
                                action.payload.value.rfq_item_entry_id
                            ],
                            error: action.payload.value.error,
                            msg: action.payload.value.msg,
                        },
                    };
                    if (state.filteringType !== 'ITEMS_WITH_ERRORS') {
                        state.errorsCopy = cloneDeep(state.errors);
                    }
                    break;

                case 'UPDATE_SINGLE_BID_OBJECT_ERR_RFQ_DATA':
                    state.errors[
                        action.payload.value.rfq_item_entry_id
                    ].rfqData = action.payload.value.rfqData;
                    if (state.filteringType !== 'ITEMS_WITH_ERRORS') {
                        state.errorsCopy = cloneDeep(state.errors);
                    }
                    break;

                case 'UPDATE_MULTIPLE_BID_OBJECT_ERR_RFQ_DATA':
                    const listOfErrorsBeingUpdated1 = Object.keys(
                        action.payload.value
                    );

                    const payloadValue = action.payload.value;

                    for (let key of listOfErrorsBeingUpdated1) {
                        if (state.errors[key]) {
                            state.errors[key].rfqData =
                                payloadValue[key].rfqData;
                            state.errors[key].itemNo = payloadValue[key].itemNo;
                        } else {
                            state.errors[key] = {
                                error: false,
                                msg: '',
                                rfqData: payloadValue[key].rfqData,
                                itemNo: payloadValue[key].itemNo,
                            };
                        }
                    }
                    if (state.filteringType !== 'ITEMS_WITH_ERRORS') {
                        state.errorsCopy = cloneDeep(state.errors);
                    }

                    break;

                case 'UPDATE_SEARCH_FILTERS':
                    if (action.payload.value.filteringType) {
                        state.filteringType =
                            action.payload.value.filteringType;

                        if (state.filteringType !== 'ITEMS_WITH_ERRORS') {
                            state.errorsCopy = cloneDeep(state.errors);
                        }
                    }

                    break;

                case 'RESET':
                    state.customBidObject = customObject;
                    state.errors = {};
                    state.errorsCopy = {};
                    state.filteringType = 'ALL_ITEMS';
                    break;
            }
        },
    },
});

export const { updateRfqOpeningBid } = RfqOpeningBidSlice.actions;
