import { Grid } from '@mui/material';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { FWButton } from '../../Common/FWButton';
import { FWTypography } from '../../Common/FWTypograhy';

interface ITitleBar {
    title: string;
    back?: string;
    children?: React.ReactNode;
    hideBackButton?: boolean;
    gotoURL?: string;
    top?: string;
    replacePreviousUrl?: boolean;
    padding?: string;
}

export default function TitleBar({
    back,
    title,
    children,
    hideBackButton = false,
    gotoURL,
    top = '54px',
    replacePreviousUrl = false,
    padding,
}: ITitleBar) {
    const history = useHistory();

    const goBack = () => {
        if (typeof gotoURL === typeof undefined) history.go(-1);
        else {
            replacePreviousUrl
                ? history.replace(gotoURL!)
                : history.push(gotoURL!);
        }
    };

    return (
        <Grid
            container
            padding={padding || '12px 24px'}
            alignItems="center"
            justifyContent="center"
            sx={{
                position: 'sticky',
                top: top,
                backgroundColor: 'white',
                zIndex: 990,
            }}
        >
            <Grid item xs={2.5}>
                {!hideBackButton && (
                    <FWButton
                        onClick={goBack}
                        startIcon={
                            <i
                                style={{ fontSize: '1.55rem' }}
                                className="bi bi-chevron-left"
                            ></i>
                        }
                        sx={{ color: 'text.secondary' }}
                    >
                        {back ? back : 'Back'}
                    </FWButton>
                )}
            </Grid>
            <Grid item xs={7}>
                <FWTypography
                    variant="h4"
                    textAlign="center"
                    color="text.primary"
                    maxWidth={'450px'}
                    margin={'0 auto'}
                >
                    {title}
                </FWTypography>
            </Grid>
            <Grid
                item
                xs={2.5}
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                }}
            >
                {children}
            </Grid>
        </Grid>
    );
}
