import React from 'react';
import VLNProfileCertificateComponent from '../../Components/Profile/Certification/VLNProfileCertificateComponent';
import { Box, Grid } from '@mui/material';
import { IVLNAboutCertificate } from '../../Interfaces/VendorLNInterfaces';
import { useVLNCertifications } from '../../Hooks/useVLNCertifications';
import { FWTypography } from '../../../Common/FWTypograhy';

const VLNProfileCertificationSection = ({
    certificates,
}: {
    certificates: IVLNAboutCertificate[];
}) => {
    // const allCertificates: { file_name: string; attachment_id: string }[] =
    //     certificates.map((item) => item.files).flat();

    const {
        filteredCertificates,
        // filters,
        // handleFilterChange,
        // selectedFilter,
    } = useVLNCertifications(certificates);
    return (
        <>
            {/* <VLNProfileCertificationFilters
                filters={filters}
                handleFilterChange={handleFilterChange}
                selectedFilter={selectedFilter}
            /> */}
            {certificates?.length > 0 ? (
                <Grid container spacing={4}>
                    {filteredCertificates.map((certificate, i) => {
                        if (certificate?.files?.length > 0) {
                            return (
                                <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    md={4}
                                    lg={4}
                                    xl={3}
                                    key={i}
                                >
                                    <VLNProfileCertificateComponent
                                        attachment={certificate}
                                    />
                                </Grid>
                            );
                        } else return <></>;
                    })}
                    {/* {filteredCertificates.map(
                        (certificate, indexOfCertificate) => {
                            return (
                                <Grid container key={indexOfCertificate}>
                                    {certificate.files.map(
                                        (attachment, idx) => (
                                            <Grid
                                                item
                                                xs={12}
                                                sm={6}
                                                md={4}
                                                lg={4}
                                                xl={3}
                                                key={idx}
                                            >
                                                <VLNProfileCertificateComponent
                                                    attachment={attachment}
                                                />
                                            </Grid>
                                        )
                                    )}
                                </Grid>
                            );
                        }
                    )} */}
                </Grid>
            ) : (
                <Box
                    className="flex flex--aic flex--jcc"
                    height={'calc(100vh - 450px)'}
                >
                    <FWTypography color={'text.secondary'}>
                        No certificates found
                    </FWTypography>
                </Box>
            )}
        </>
    );
};

export default VLNProfileCertificationSection;
