import { createSlice } from '@reduxjs/toolkit';
import { IAdditionalCostsBackend } from '../../AdditionalCost/models/AdditionalCost.model';
import { IPaymentTerms } from '../../Global/Interfaces/PaymentTermsInterface';
import { PeriodEnum } from '../../Global/Interfaces/PeriodInterface';
import {
    IItemCartLevelCustomField,
    ISellerContact,
    ISellerEntity,
} from '../../ItemAnalytics/Interfaces/itemCartItemInterfaces';
import { ICurrencyDetails } from '../../Models/Currency.model';
import { BackendDeliverablePaymentTerms } from '../../Models/RFQ.model';
import { cloneDeep } from 'lodash';
import { RequisitionItemBackend } from '../../ItemAnalytics/Interfaces/OrderInterfaces';
import { IRequisitionNewTemplate } from '../Interfaces/RequisitionTemplateInterfacesNew';
import { blankRequisitionTemplate } from '../Constants/RequisitionTemplateConstants';
import {
    AdditionalCharge,
    IRequisitionItemValidation,
} from '../Interfaces/Requisitions.interface';
import { checkValidityOfItem } from '../Helpers/RequisitionEditPageHelpers';
import { ICustomAttributeNew } from '../../Models/Attributes.model';
// import { checkValidityOfItem } from '../Helpers/RequisitionEditPageHelpers';
// import { checkValidityOfItem } from '../Helpers/RequisitionEditPageHelpers';

export interface IRequisitionStore {
    buyerEnterPrise: string;
    buyerEntity: string;
    buyerEntityName: string;
    createdByUserName: string;
    craetedByID: string;
    customRequisitionId: string;
    currencyId: string;
    requisitionId: string;
    requisitionName: string;
    status: string;
    requisitionItems: RequisitionItem[];
    templateId: string;
    requisitionSaveState: {
        saveState: 'SAVED' | 'UNSAVED' | 'ERROR';
        validity: 'INVALID' | 'VALID';
    };
    listOfErrorsAccrossPages: {
        requisitionItemId: string;
        validity: 'INVALID' | 'VALID';
        noOfVendorsSelected: number;
        pageNoItem: number;
        itemNumber: number;
        itemNo: number;
    }[];
    searchQuery: string;
    filteringType: 'ALL_ITEMS' | 'ITEMS_WITH_ERRORS' | 'ITEMS_WITH_WARNINGS';
    filteringCurrentPage: number;
    templateDetails: IRequisitionNewTemplate;
    errors: {
        reqName: {
            error: boolean;
            message: string;
        };
    };
    allCurrencies: ICurrencyDetails[];
    itemCount: number;
    allItems: { [key: string]: RequisitionItem };
    pageNumber: number;
}

export interface IRequisition {
    requisitionItems: RequisitionItem[];
    itemCount: number;
    requisitionId: string;
    createdByUserName: string;
    modifiedByUserName: string | null;
    deletedDateTime: string | null;
    buyerEntityName: string;
    customRequisitionId: string;
    requisitionName: string;
    source_type: string;
    notes: string;
    requisitionDefaultInformation: {
        defaultCurrencyId: string;
    };
    status: string;
    tags: string[] | null;
    additionalDetails: {
        templateId: string;
    };
    total: number;
    createdByUser: string;
    modifiedByUser: string | null;
    deletedByUser: string | null;
    buyerEnterprise: string;
    buyerEntity: string;
}

export interface RequisitionItem {
    requisitionItemId: string;
    tags: string[] | null;
    isLinkedToEntity: boolean;
    measurementUnitDetails: {
        measurementUnitName: string;
        measurementUnitAbbreviation: string;
    };
    attributes: ICustomAttributeNew[];
    additionalCosts: IAdditionalCostsBackend[];
    taxes: IAdditionalCostsBackend[];
    discounts: IAdditionalCostsBackend[];
    createdDatetime: string;
    modifiedDatetime: string;
    deletedDatetime: string | null;
    itemInformation: {
        itemId: string;
        itemCode: string;
        itemName: string;
        customIds: {
            name: string;
            value: string;
        }[];
        customItemName: string;
        itemDescription: string;
        itemAdditionalDetails: string;
    };
    sellerContactsInformation: ISellerContact[];
    sellerEntitiesInformation: ISellerEntity[];
    paymentTerms: IPaymentTerms;
    attributeInformation: {
        buyerItemAttributes: {
            attribute_name: string;
            attribute_value: string[];
            attribute_exclude: boolean;
        }[];
        customItemAttributes: {
            attribute_name: string;
            attribute_value: string[];
            attribute_exclude: boolean;
        }[];
    };
    quantity: number;
    prepaymentPercentage: number | null;
    quantityTolerancePercentage: number;
    paymentType: 'poItemPercentages' | 'invoiceItemValues';
    payment_terms: {
        applied_from: string;
        term: number;
        period: PeriodEnum | '';
    } | null;
    deliverables_payment_terms: BackendDeliverablePaymentTerms[];
    procurementInformation: {
        leadTime: number | null | string;
        leadTimePeriod: string | null;
    };
    pricingInformation: {
        currencyName: string;
        desiredPrice: number;
        currencySymbol: string;
        currencyCodeId: string;
        shippingPerUnit: string | null;
        additionalCharges: AdditionalCharge[];
        currencyCodeAbbreviation: string;
    };
    deliveryInformation: {
        delivery_schedule_item_id: string | null;
        requisition_item_id: string | null;
        parent_delivery_schedule_item_id: string | null;
        delivery_date: string | null;
        quantity: string;
        project_id: string | null;
        project_code: string | null;
        cost_centre_id: string | null;
        cost_centre_name: string | null;
        general_ledger_id: string | null;
        general_ledger_code: string | null;
    }[];
    deliverySchedule: {
        delivery_schedule_item_id: string | null;
        requisition_item_id: string | null;
        parent_delivery_schedule_item_id: string | null;
        delivery_date: string | null;
        quantity: string;
        project_id: string | null;
        project_code: string | null;
        cost_centre_id: string | null;
        cost_centre_name: string | null;
        general_ledger_id: string | null;
        general_ledger_code: string | null;
    }[];
    shipping_address?: {
        shippingAddressId: string | null;
        shippingFullAddress: string | null;
    };
    customFields: {
        sectionList: {
            name: string;
            fields: IItemCartLevelCustomField[];
        }[];
    };
    questions: {
        sectionItems: [];
    };
    createdByUser: string;
    modifiedByUser: string;
    deletedByUser: string | null;
    enterpriseItem: string;
    measurementUnit: string;
    currency: string;
    incoterm: string | null;
    sellerEntities: string[];
    sellerContacts: string[];
    requisition: string;
    shippingAddress: {
        shippingAddressId: string;
        shippingFullAddress: string;
    };
    total: string | number;
    prefferedVendorCount?: number;
    nonPrefferedVendorCount?: number;
    allItemVendors?: any[];
    errors: IRequisitionItemValidation;
    itemSaveState: {
        saveState: 'SAVED' | 'UNSAVED' | 'ERROR';
        validity: 'INVALID' | 'VALID';
    };
}
export interface IUpdateRequisition {
    type: string;
    payload:
        | {
              type: 'UPDATE_REQUISITION';
              value: any;
          }
        | {
              type: 'UPDATE_TEMPLATE_DETAILS';
              value: IRequisitionNewTemplate;
          }
        | {
              type: 'UPDATE_ALL_ITEMS';
              value: RequisitionItem[];
          }
        | {
              type: 'UPDATE_REQUISITION_NAME';
              value: string;
          }
        | {
              type: 'UPDATE_REQUISITION_CURRENCY';
              value: string;
          }
        | {
              type: 'SET_ALL_CURRENCIES';
              value: ICurrencyDetails[];
          }
        | {
              type: 'CHANGE_ITEM_COUNT';
              value: number;
          }
        | {
              type: 'UPDATE_REQUISITION_ITEMS';
              value: RequisitionItemBackend[];
          }
        | {
              type: 'UPDATE_PREFFERED_VENDOR_COUNT';
              value: {
                  value: number;
                  idx: string;
              };
          }
        | {
              type: 'UPDATE_NONPREFFERED_VENDOR_COUNT';
              value: {
                  value: number;
                  idx: string;
              };
          }
        // | {
        //       type: 'UPDATE_CART_ITEM_DETAILS';
        //       value: {
        //           [cart_item_id: string]: CartItemDetails;
        //       };
        //   }
        // | {
        //       type: 'UPDATE_SAVED_FOR_LATER_DETAILS';
        //       value: {
        //           [cart_item_id: string]: CartItemDetails;
        //       };
        //   }
        | {
              type: 'UPDATE_REQUISITION_SAVE_STATE';
              value: 'SAVED' | 'UNSAVED' | 'ERROR';
          }
        | { type: 'UPDATE_REQUISITION_VALIDITY'; value: 'INVALID' | 'VALID' }
        | { type: 'UPDATE_ALL_REQUISITION_ITEMS'; value: RequisitionItem[] }
        | {
              type: 'UPDATE_SINGLE_REQUISITION_ITEM';
              value: {
                  idx: string;
                  data: RequisitionItem;
              };
          }
        | {
              type: 'DELETE_SINGLE_REQUISITION_ITEM';
              value: string;
          }
        // | {
        //       type: 'SHIFT_ITEM_TO_CART';
        //       value: string;
        //   }
        // | {
        //       type: 'SAVE_ITEM_FOR_LATER';
        //       value: string;
        //   }
        | {
              type: 'ADD_SINGLE_REQUISITION_ITEM';
              value: RequisitionItem;
          }
        | {
              type: 'UPDATE_ITEM_SAVE_STATE';
              value: {
                  idx1: number;
                  data1: 'SAVED' | 'UNSAVED' | 'ERROR';
              };
          }
        | {
              type: 'UPDATE_VENDOR_FOR_ITEM';
              value: {
                  idx: string;
                  vendor: string[];
                  information: any;
              };
          }
        | {
              type: 'UPDATE_VENDOR_FOR_ITEM_NEW';
              value: {
                  idx: string;
                  vendor: string[];
              };
          }
        | {
              type: 'UPDATE_VENDOR_CONTACT_FOR_ITEM';
              value: {
                  idx: string;
                  vendorContact: string[];
                  information: any;
              };
          }
        // | {
        //       type: 'UPDATE_VENDOR_FOR_ALL_ITEM';
        //       value: {
        //           [requisiton_item_id: string]: string[];
        //       };
        //   }
        | {
              type: 'UPDATE_ALL_ITEM_IDS';
              value: string[];
          }
        | {
              type: 'UPDATE_ITEM_VENDORS';
              value: {
                  idx: string;
                  value: any;
              };
          }
        | {
              type: 'UPDATE_CURRENT_PAGE';
              value: number;
          }
        | {
              // filteringCurrentPage
              type: 'UPDATE_FILTERING_CURRENT_PAGE';
              value: number;
          }
        | {
              type: 'UPDATE_ALL_ITEM_SAVE_STATE';
              value: {
                  allData: 'SAVED' | 'UNSAVED' | 'ERROR';
              };
          }
        | {
              type: 'UPDATE_ITEM_COUNT';
              value: number;
          }
        | {
              type: 'UPDATE_ITEM_VALIDITY';
              value: {
                  idx2: number;
                  data2: 'VALID' | 'INVALID';
              };
          }
        | {
              type: 'UPDATE_ALL_ITEM_VALIDITY';
              value: {
                  allData2: 'VALID' | 'INVALID';
              };
          }
        | {
              type: 'UPDATE_SEARCH_QUERY';
              value: string;
          }
        | {
              type: 'UPDATE_ITEM_IN_ALL_ITEMS';
              value: {
                  idx: string;
                  value: RequisitionItem;
                  errors: IRequisitionItemValidation;
              };
          }
        | {
              type: 'UPDATE_SEARCH_FILTERS';
              value: {
                  filteringType?:
                      | 'ALL_ITEMS'
                      | 'ITEMS_WITH_ERRORS'
                      | 'ITEMS_WITH_WARNINGS';
                  currentPage?: number;
              };
          }
        | {
              type: 'UPDATE_ITEMS_ON_PAGE_CHANGE';
              value: {
                  page: number;
                  itemsPerPage: number;
              };
          }
        | {
              type: 'UPDATE_ITEMS_ON_SEARCH';
              value: string[];
          }
        | {
              type: 'UPDATE_REQUISITON_ERRORS';
              value: any;
          }
        | {
              type: 'RESET_REQUISITION_STATE';
              value: any;
          }
        | {
              type: 'UPDATE_ITEM_ERRORS';
              value: {
                  idx: string;
                  error: IRequisitionItemValidation;
              };
          }
        | {
              type: 'UPDATE_PAGE_NUMBER';
              value: number;
          };
}

export const RequisitionSlice = createSlice<
    IRequisitionStore,
    {
        updateRequisitionStore: (
            state: IRequisitionStore,
            action: IUpdateRequisition
        ) => void;
    },
    string
>({
    name: 'RequisitionStore',
    initialState: {
        itemCount: 0,
        pageNumber: 1,
        buyerEnterPrise: '',
        createdByUserName: '',
        craetedByID: '',
        buyerEntity: '',
        buyerEntityName: '',
        customRequisitionId: '',
        currencyId: '',
        requisitionId: '',
        requisitionName: '',
        status: '',
        requisitionItems: [],
        templateId: '',
        requisitionSaveState: {
            saveState: 'UNSAVED',
            validity: 'VALID',
        },
        searchQuery: '',
        filteringType: 'ALL_ITEMS',
        filteringCurrentPage: 1,
        templateDetails: blankRequisitionTemplate,
        listOfErrorsAccrossPages: [],
        errors: {
            reqName: {
                error: false,
                message: '',
            },
        },
        allCurrencies: [],
        allItems: {},
    },
    reducers: {
        updateRequisitionStore: (
            state: IRequisitionStore,
            action: IUpdateRequisition
        ) => {
            try {
                switch (action.payload.type) {
                    case 'UPDATE_TEMPLATE_DETAILS':
                        state.templateDetails = action.payload
                            .value as IRequisitionNewTemplate;
                        break;
                    case 'UPDATE_REQUISITION':
                        state.buyerEnterPrise =
                            action.payload.value.buyer_enterprise;
                        state.buyerEntity = action.payload.value.buyer_entity;
                        state.buyerEntityName =
                            action.payload.value.buyer_entity_name;
                        state.customRequisitionId =
                            action.payload.value.custom_requisition_id;
                        state.currencyId =
                            action.payload.value.requisition_default_information.default_currency_id;
                        state.requisitionId =
                            action.payload.value.requisition_id;
                        state.templateId =
                            action.payload.value.additional_details.template_id;
                        state.requisitionName =
                            action.payload.value.requisition_name;
                        state.status = action.payload.value.status;
                        state.createdByUserName =
                            action.payload.value.created_by_user_name;
                        state.craetedByID =
                            action.payload.value.created_by_user;
                        state.errors = {
                            reqName: {
                                error: false,
                                message: '',
                            },
                        };
                        break;
                    case 'UPDATE_ALL_ITEMS':
                        let allItems = cloneDeep(state.allItems);
                        action.payload.value.forEach((item) => {
                            allItems[item.requisitionItemId] = item;
                        });
                        state.allItems = allItems;
                        break;
                    case 'CHANGE_ITEM_COUNT':
                        state.itemCount =
                            state.itemCount + action.payload.value;
                        break;
                    case 'UPDATE_REQUISITION_NAME':
                        state.requisitionName = action.payload.value;
                        break;
                    case 'UPDATE_REQUISITION_CURRENCY':
                        state.currencyId = action.payload.value;
                        break;
                    case 'UPDATE_ITEMS_ON_PAGE_CHANGE':
                        // let page = action.payload.value.page;
                        // let itemsPerPage = action.payload.value.itemsPerPage;
                        // let items = cloneDeep(state.allItems);
                        // state.requisitionItems = items.slice(
                        //     (page - 1) * itemsPerPage,
                        //     page * itemsPerPage
                        // );
                        break;
                    case 'UPDATE_PREFFERED_VENDOR_COUNT':
                        state.allItems[
                            action.payload.value.idx
                        ].prefferedVendorCount = action.payload.value.value;
                        break;
                    case 'UPDATE_NONPREFFERED_VENDOR_COUNT':
                        state.allItems[
                            action.payload.value.idx
                        ].nonPrefferedVendorCount = action.payload.value.value;
                        break;
                    case 'UPDATE_REQUISITION_ITEMS':
                        // let temp: RequisitionItem[];
                        // temp = action.payload.value.map((item) => {
                        //     return {
                        //         requisitionItemId: item.requisition_item_id,
                        //         isLinkedToEntity: item.is_linked_to_entity,
                        //         additionalCosts: item.additional_costs.map(
                        //             (ac) => ac
                        //         ),
                        //         taxes: item.taxes.map((tax) => tax),
                        //         discounts: item.discounts.map(
                        //             (discount) => discount
                        //         ),
                        //         createdDatetime: item.created_datetime,
                        //         modifiedDatetime: item.modified_datetime,
                        //         deletedDatetime: item.deleted_datetime,
                        //         itemInformation: {
                        //             itemAdditionalDetails:
                        //                 item.item_information
                        //                     .item_additional_details,
                        //             itemCode: item.item_information.item_code,
                        //             itemDescription:
                        //                 item.item_information.item_description,
                        //             itemId: item.item_information.item_id,
                        //             itemName: item.item_information.item_name,
                        //             customItemName:
                        //                 item.item_information.custom_item_name,
                        //             customIds: item.item_information.custom_ids,
                        //         },
                        //         attributeInformation: {
                        //             buyerItemAttributes:
                        //                 item.attribute_information.buyer_item_attributes.map(
                        //                     (data) => data
                        //                 ),
                        //             customItemAttributes: {},
                        //         },
                        //         sellerContactsInformation:item.seller_contacts_information.map((ci:any)=>ci),
                        //         sellerEntityInformation:item.seller_entities_information.map((ci:any)=>ci),
                        //         quantity:item.quantity,
                        //         prepaymentPercentage:item.prepayment_percentage,
                        //         quantityTolerancePercentage:item.quantity_tolerance_percentage,
                        //         paymentType:item.pa
                        //     };
                        // });
                        break;
                    case 'UPDATE_ITEM_COUNT':
                        state.itemCount = action.payload.value;
                        break;
                    case 'SET_ALL_CURRENCIES':
                        state.allCurrencies = action.payload.value;
                        break;
                    case 'UPDATE_REQUISITON_ERRORS':
                        state.errors = action.payload.value;
                        break;
                    case 'ADD_SINGLE_REQUISITION_ITEM':
                        const newItem = cloneDeep(action.payload.value);
                        state.requisitionItems = [
                            ...state.requisitionItems,
                            newItem,
                        ];
                        break;
                    case 'UPDATE_REQUISITION_SAVE_STATE':
                        state.requisitionSaveState.saveState = action.payload
                            .value as 'SAVED' | 'UNSAVED' | 'ERROR';
                        break;
                    case 'UPDATE_ITEM_VENDORS':
                        state.allItems[
                            action.payload.value.idx
                        ].allItemVendors = action.payload.value.value;
                        break;
                    case 'UPDATE_REQUISITION_VALIDITY':
                        state.requisitionSaveState.validity = action.payload
                            .value as 'INVALID' | 'VALID';
                        break;
                    case 'UPDATE_ALL_REQUISITION_ITEMS':
                        state.requisitionItems = action.payload
                            .value as RequisitionItem[];
                        // let item_id_arr: string[] = [];
                        // let save_state_arr: any[] = [];
                        // (action.payload.value as RequisitionItem[]).forEach(
                        //     (item, idx) => {
                        //         item_id_arr.push(item.requisitionItemId);

                        //             state.cartItems[item.cart_item_id] = item;

                        //         save_state_arr.push({
                        //             save_state: 'SAVED',
                        //             validity: 'VALID',
                        //         });
                        //     }
                        // );

                        // state.item_state = save_state_arr;
                        break;
                    case 'UPDATE_SINGLE_REQUISITION_ITEM':
                        const { idx, data } = action.payload.value as {
                            idx: string;
                            data: RequisitionItem;
                        };
                        if (!state.allItems[idx]) {
                            state.allItems[idx] = data;
                        } else {
                            state.allItems[idx] = cloneDeep(data);
                        }
                        break;
                    case 'UPDATE_VENDOR_FOR_ITEM':
                        state.allItems[
                            action.payload.value.idx
                        ].sellerEntities = action.payload.value.vendor;
                        state.allItems[
                            action.payload.value.idx
                        ].sellerEntitiesInformation =
                            action.payload.value.information;
                        break;
                    case 'UPDATE_VENDOR_FOR_ITEM_NEW':
                        state.allItems[
                            action.payload.value.idx
                        ].sellerEntities = action.payload.value.vendor;
                        break;
                    case 'UPDATE_VENDOR_CONTACT_FOR_ITEM':
                        state.allItems[
                            action.payload.value.idx
                        ].sellerContacts = action.payload.value.vendorContact;
                        break;
                    case 'UPDATE_ITEM_ERRORS':
                        let temp = {};
                        let validity = checkValidityOfItem(
                            action.payload.value.error
                        );
                        Object.keys(
                            action.payload.value.error.customFields
                        ).forEach((value) => {
                            temp = {
                                ...temp,
                                [value]: {},
                            };
                        });
                        // const itemIndexInAllItems = state.allItems.findIndex(
                        //     (item) =>
                        //         item.requisitionItemId ===
                        //         state.requisitionItems[action.payload.value.idx]
                        //             .requisitionItemId
                        // );

                        state.allItems[action.payload.value.idx].itemSaveState =
                            {
                                validity: validity ? 'VALID' : 'INVALID',
                                saveState: 'SAVED',
                            };

                        state.allItems[action.payload.value.idx].errors =
                            action.payload.value.error;
                        break;
                    case 'UPDATE_ITEMS_ON_SEARCH':
                        // state.requisitionItems = state.allItems.filter((item) =>
                        //     action.payload.value.includes(
                        //         item.requisitionItemId
                        //     )
                        // );
                        break;
                    case 'UPDATE_ITEM_IN_ALL_ITEMS':
                        // let newItemInAllItems = cloneDeep(
                        //     action.payload.value.value
                        // );
                        // const itemIndexToUpdateInAll = state.allItems.findIndex(
                        //     (item) =>
                        //         item.requisitionItemId ===
                        //         newItemInAllItems.requisitionItemId
                        // );
                        // newItemInAllItems.errors = action.payload.value.errors;
                        // state.allItems[itemIndexToUpdateInAll] =
                        //     newItemInAllItems;
                        break;
                    case 'RESET_REQUISITION_STATE':
                        state.buyerEnterPrise = '';
                        state.buyerEntity = '';
                        state.buyerEntityName = '';
                        state.customRequisitionId = '';
                        state.currencyId = '';
                        state.requisitionId = '';
                        state.requisitionName = '';
                        state.status = '';
                        state.requisitionItems = [];
                        state.templateId = '';
                        state.requisitionSaveState = {
                            saveState: 'UNSAVED',
                            validity: 'VALID',
                        };
                        state.allItems = {};
                        state.itemCount = 0;
                        state.searchQuery = '';
                        state.filteringType = 'ALL_ITEMS';
                        state.filteringCurrentPage = 1;
                        state.pageNumber = 1;
                        state.templateDetails = blankRequisitionTemplate;
                        state.listOfErrorsAccrossPages = [];
                        state.errors = {
                            reqName: {
                                error: false,
                                message: '',
                            },
                        };
                        break;
                    case 'UPDATE_PAGE_NUMBER':
                        state.pageNumber = action.payload.value;
                        break;
                    case 'DELETE_SINGLE_REQUISITION_ITEM':
                        try {
                            delete state.allItems[action.payload.value];
                        } catch (err) {}
                        break;
                }
            } catch (err) {}
        },
    },
});

export const { updateRequisitionStore } = RequisitionSlice.actions;

const RequisitionStore = RequisitionSlice.reducer;
export default RequisitionStore;
