import { isEqual } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import FWDataGrid from '../../Common/FWDataGrid';
import CustomDashboardGridHeader from '../../Global/Components/CustomDashboardGridHeader';
import { pendingPOColumns } from '../Constants/GRDashboardConstants';
import { IGRInvoiceListSummary } from '../Interfaces/GRInterface';
import { fetchGRByPO } from '../Services/GRService';
// 'buyer_enterprise', 'seller_enterprise', 'event', 'po_id', 'buyer_entity', 'seller_entity',
// 'po_type', 'modified_datetime', 'invoice_count', 'total_amount', 'status', 'po_status',
// 'invoice_item', 'invoice_item_count',
//  'po_item_count', 'created_by', 'created_at', 'invoice_delivery_date', 'po_delivery_date', 'min_delivery_date'
export type sortFields =
    | 'poId'
    | 'invoiceId'
    | 'invoiceDate'
    | 'vendorName'
    | 'deliveryDate'
    | 'noOfItems'
    | 'shippingAddress'
    | 'buyerEntityName';

const sortColumnsNames = {
    poId: 'po_id',
    invoiceId: 'invoice_id',
    invoiceDate: 'invoice_date',
    vendorName: 'seller_entity',
    deliveryDate: 'po_delivery_date',
    noOfItems: 'po_item_count',
    shippingAddress: 'shipping_address',
    buyerEntityName: 'buyer_entity',
};

const GRPendingPo = ({
    handleDeliveryScheduleToggle,
    handleRowClick,
    searchText,
}: {
    handleDeliveryScheduleToggle: (
        poUid: string,
        measurementUnit: string,
        el: HTMLElement
    ) => void;
    handleRowClick: ({ data }: { data: IGRInvoiceListSummary }) => void;
    searchText: string;
}) => {
    const [rows, setRows] = useState<IGRInvoiceListSummary[] | null>(null);
    const [sortFields, setSortFields] = useState<
        {
            field: sortFields;
            ascending: boolean;
        }[]
    >([]);

    const [totalPages, setTotalPages] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState(1);
    const hitFetchAPI = useCallback(
        async (currentPage, searchText, sortFields) => {
            //Pass necessary payloads for the API call.
            // All of them are states
            const sortFieldsArray = sortFields.map(
                (sortField: { field: sortFields; ascending: boolean }) => {
                    return {
                        field: sortColumnsNames[sortField.field],
                        ascending: sortField.ascending,
                    };
                }
            );
            let resp = await fetchGRByPO({
                searchText: searchText,
                itemsPerPage: 10,
                pageNumber: currentPage,
                sortFields: sortFieldsArray as any,
            });
            return resp;
        },
        []
    );

    const fetchData = useCallback(
        async (currentPage, searchText, sortFields) => {
            try {
                //Pass necessary payloads for the API call.
                // All of them are states
                setRows(null);
                let resp = await hitFetchAPI(
                    currentPage,
                    searchText,
                    sortFields
                );
                //take to page 1 incase of extra
                setCurrentPage((prev) => {
                    if (prev > resp.pageDetails.total_pages) {
                        return 1;
                    } else {
                        return prev;
                    }
                });
                setRows(
                    resp.grInoviceList.map((data: any, i: number) => ({
                        ...data,
                        id: i,
                    }))
                );
                setTotalPages(resp.pageDetails.total_pages);
            } catch {
                toast.error('Some error');
            }
        },
        [hitFetchAPI]
    );

    useEffect(() => {
        // setRowData(null);
        setCurrentPage(1);
        setSortFields([]);
        fetchData(1, searchText, []);
    }, [fetchData, searchText]);

    //MrTea:Made a seprate useEffect so that it runs on page change only.
    //Otherwise the data is loaded on the previous set page when other datas is changed
    useEffect(() => {
        // setRowData(null);
        fetchData(currentPage, searchText, sortFields);
        // disabled because we want to run only for page change
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage]);

    useEffect(() => {
        // setRowData(null);
        setCurrentPage(1);
        fetchData(1, searchText, sortFields);
        // disabled because we want to run only for sort columns
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sortFields]);

    const [showRefreshIcon, setShowRefreshIcon] = useState(false);
    useEffect(() => {
        const interval = setInterval(async () => {
            try {
                let resp = await hitFetchAPI(
                    currentPage,
                    searchText,
                    sortFields
                );
                const isListEqual = isEqual(
                    resp.grInoviceList.map((data: any, i: number) => ({
                        ...data,
                        id: i,
                    })),
                    rows
                );
                setShowRefreshIcon(!isListEqual);
            } catch (err) {}
        }, 1000 * 120); // fetch every 120 seconds and compare with previous data
        return () => {
            clearInterval(interval);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rows]);
    return (
        <FWDataGrid
            disableFESearch
            fixedHeight={605}
            defaultColDef={{
                resizable: true,
                headerComponent: (props: any) => (
                    <CustomDashboardGridHeader
                        {...props}
                        sortFields={sortFields}
                        setSortFields={setSortFields}
                    />
                ),
            }}
            rows={rows}
            columns={pendingPOColumns}
            context={{ handleDeliveryScheduleToggle }}
            onRowClicked={handleRowClick}
            suppressPaginationPanel
            useCustomPagination={totalPages}
            customPaginationOnChange={(e, pageNumber) => {
                setCurrentPage(pageNumber);
            }}
            customPaginationPageNumber={currentPage}
            showRefreshIcon={showRefreshIcon}
            onRefreshIconClick={() => {
                setShowRefreshIcon(false);
                fetchData(1, '', []);
            }}
            showFilterColumnMenu
            // getRowClass={({ data }: { data: IGRInvoiceListSummary }) => {
            //     return !grPermissions[data.entityUid].EDIT
            //         ? 'disabled'
            //         : '';
            // }}
        />
    );
};

export default GRPendingPo;
