import styled from '@emotion/styled';
import {
    FormControl,
    FormHelperText,
    MenuProps,
    SxProps,
    Theme,
} from '@mui/material';
import React from 'react';
import { FWCustomGridKeyValue } from './FWCustomGridKeyValue';
import { FWInput } from './FWInput';
import FWSelect, { OpenDownIcon } from './FWSelect';

const CustomFormControlLeft = styled(FormControl)({
    '& .MuiInputBase-root': {
        borderRadius: '0px',
        borderTopLeftRadius: '12px',
        borderBottomLeftRadius: '12px',
        height: '40px',
    },
});

const CustomFormControlRight = styled(FormControl)({
    '& .MuiInputBase-root': {
        borderRadius: '0px',
        borderTopRightRadius: '12px',
        borderBottomRightRadius: '12px',
        height: '40px',
    },
});

interface IcombinedInput {
    isDropLeft?: boolean;
    label?: string;
    textFieldplaceholder?: string;
    selectFieldplaceholder?: string;
    fieldSize?: 'small' | 'medium' | 'xs';
    textFieldValue: any;
    textFieldOnChange: (e: any) => any;
    textFieldOnBlur?: (e: any) => any;
    selectFieldValue: any;
    selectFieldOnChange: (e: any) => any;
    selectFieldMenuItems: React.ReactNode;
    allowOnly?: 'TEXT' | 'NUMBER' | 'EMAIL' | 'DECIMAL_NUMBER';
    required?: boolean;
    error?: boolean;
    helperText?: string;
    textDisabled?: boolean;
    selectDisabled?: boolean;
    inputClassName?: string;
    selectClassName?: string;
    selectIconComponent?: React.FunctionComponent;
    inputAdornment?: React.ReactNode;
    adornMentPosition?: 'start' | 'end';
    inputDisabled?: boolean;
    inputSx?: SxProps<Theme> | undefined;
    dropdownSx?: SxProps<Theme> | undefined;
    MenuProps?: Partial<MenuProps>;
    onClickTextField?: (e: any) => void;
    warning?: boolean;
}

export const FWCombinedInput = ({
    isDropLeft = true,
    label = '',
    textFieldplaceholder = '',
    selectFieldplaceholder = '',
    fieldSize,
    textFieldValue,
    textFieldOnChange,
    textFieldOnBlur,
    selectFieldValue,
    selectFieldOnChange,
    selectFieldMenuItems,
    allowOnly,
    required,
    error,
    MenuProps,
    helperText,
    selectDisabled,
    inputClassName,
    selectClassName,
    selectIconComponent = OpenDownIcon,
    inputAdornment,
    adornMentPosition = 'end',
    inputDisabled,
    inputSx = {},
    dropdownSx = {},
    textDisabled,
    onClickTextField,
    warning,
}: IcombinedInput) => {
    return (
        <>
            <FWCustomGridKeyValue title={label}>
                <div
                    style={{
                        display: 'flex',
                        width: '100%',
                        flexDirection: isDropLeft ? 'row-reverse' : 'row',
                        alignItems: 'flex-end',
                    }}
                >
                    <FWInput
                        onClick={(e) => onClickTextField && onClickTextField(e)}
                        id={
                            'FWCombinedInput_FWInput_' +
                            label +
                            textFieldplaceholder
                        }
                        required={required}
                        allowOnly={allowOnly}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                borderRadius: isDropLeft
                                    ? '0px 12px 12px 0'
                                    : '12px 0 0 12px',
                            },
                            '& .MuiInputBase-input': {
                                height: fieldSize === 'xs' ? '30px' : '40px',
                            },
                            width: '100%',
                            ...(inputSx as any),
                        }}
                        placeholder={textFieldplaceholder}
                        error={error}
                        // helper={{
                        //     text: 'Requisition not approved',
                        //     textColor: 'warning',
                        // }}
                        // color={'success'}
                        size={fieldSize === 'xs' ? 'small' : fieldSize}
                        value={textFieldValue}
                        onBlur={textFieldOnBlur}
                        onChange={textFieldOnChange}
                        className={inputClassName}
                        InputProps={{
                            endAdornment:
                                adornMentPosition === 'start' ? (
                                    <></>
                                ) : (
                                    inputAdornment
                                ),
                            startAdornment:
                                adornMentPosition === 'end' ? (
                                    <></>
                                ) : (
                                    inputAdornment
                                ),
                        }}
                        disabled={inputDisabled}
                    />
                    {isDropLeft ? (
                        <CustomFormControlLeft
                            fullWidth
                            required={required}
                            error={error}
                        >
                            <FWSelect
                                id={
                                    'FWCombinedInput_FWSelect_' +
                                    label +
                                    selectFieldplaceholder
                                }
                                sx={{
                                    height:
                                        fieldSize === 'xs'
                                            ? '28px!important'
                                            : '40x',
                                    ...(dropdownSx as any),
                                    'MuiMenu-list': {
                                        maxHeight: '120px',
                                    },
                                }}
                                placeholder={selectFieldplaceholder}
                                MenuProps={MenuProps}
                                size={fieldSize === 'xs' ? 'small' : fieldSize}
                                value={selectFieldValue}
                                onChange={selectFieldOnChange}
                                disabled={selectDisabled}
                                className={selectClassName}
                                IconComponent={selectIconComponent}
                            >
                                {selectFieldMenuItems}
                            </FWSelect>
                        </CustomFormControlLeft>
                    ) : (
                        <CustomFormControlRight
                            fullWidth
                            required={required}
                            error={error}
                        >
                            <FWSelect
                                id={
                                    'FWCombinedInput_FWSelect_' +
                                    label +
                                    selectFieldplaceholder
                                }
                                sx={{
                                    height:
                                        fieldSize === 'xs'
                                            ? '30px!important'
                                            : 'auto',
                                    ...(dropdownSx as any),
                                }}
                                placeholder={selectFieldplaceholder}
                                size={fieldSize === 'xs' ? 'small' : fieldSize}
                                value={selectFieldValue}
                                onChange={selectFieldOnChange}
                                disabled={selectDisabled}
                                className={selectClassName}
                                IconComponent={selectIconComponent}
                            >
                                {selectFieldMenuItems}
                            </FWSelect>
                        </CustomFormControlRight>
                    )}
                </div>
            </FWCustomGridKeyValue>
            {error && (
                <FormHelperText
                    sx={{ color: 'error.main', fontSize: '0.8rem' }}
                >
                    {helperText}
                </FormHelperText>
            )}
            {warning && (
                <FormHelperText
                    sx={{ color: 'warning.main', fontSize: '0.8rem' }}
                >
                    {helperText}
                </FormHelperText>
            )}
        </>
    );
};
