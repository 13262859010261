import Decimal from 'decimal.js';
import { isBoolean, isEmpty, isNull } from 'lodash';
import moment from 'moment';
import { calculateItemTotal } from '../../Components/Shared/totalCalculator';
import {
    ICreditListItemSummary,
    // IDefaultViewPaymentItem,
    // IInvoiceViewPaymentItem,
    IPaymentListItemSummary,
    IPaymentListSummary,
    // IPaymentsPendingListSummary,
    PaymentItemStatus,
    PaymentItemType,
} from '../Interfaces/PaymentsInterface';

export const isPaymentItemAmountValid = (
    item: IPaymentListItemSummary,
    allowExtraPayment?: boolean
): boolean => {
    if (isEmpty(item.amountToPay)) return false;
    if (parseFloat(item.amountToPay) === 0) return false;
    if (
        parseFloat(item.amountToPay) > item.amountPending &&
        !allowExtraPayment
    ) {
        return false;
    }
    return true;
};

export const isCreditAmountValid = (
    credit: ICreditListItemSummary,
    totalAmountToPay?: number
): boolean => {
    if (isEmpty(credit.requestedAmount)) return false;
    if (parseFloat(credit.requestedAmount) === 0) return false;
    if (totalAmountToPay) {
        if (parseFloat(credit.requestedAmount) > totalAmountToPay) {
            return false;
        }
    }
    if (parseFloat(credit.requestedAmount) > credit.availableAmount)
        return false;
    return true;
};

export const isPaymentDetailValid = (detail: IPaymentListSummary): boolean => {
    // if (isEmpty(detail.amount)) return false;
    // if (parseFloat(detail.amount) === 0) return false;
    if (isEmpty(detail.transactionType)) return false;
    if (isEmpty(detail.transactionId)) return false;
    if (moment(detail.date).add(1, 'day').diff(moment(), 'days') > 0)
        return false;
    return true;
};

export const isPaymentItemValid = (
    item: IPaymentListItemSummary,
    allowedWithoutQC?: boolean
): boolean => {
    if (isBoolean(allowedWithoutQC) && !allowedWithoutQC && !item.qcDone) {
        return false;
    }
    if (item.itemOnHold || item.itemTermination) {
        return false;
    }
    if (item.poOnHold || item.poTermination) {
        return false;
    }
    if (
        item.status !== PaymentItemStatus.GOODS_RECEIVED &&
        item.status !== PaymentItemStatus.GOODS_PENDING &&
        item.paymentType !== PaymentItemType.PREPAYMENT
    ) {
        return false;
    }
    if (item.amountPending === 0) {
        return false;
    }
    // TODO: Enable when tier 2 GR is done
    // if (item.invoiceVerificationStatus === 'PENDING') {
    //     return false;
    // }
    return true;
};

export const calculatePendingAmount = (
    totalAmount: number,
    completedPayment: number,
    creditedAmount: number,
    rejectedAmount: number
    // onHoldAmount: number
): number => {
    let pendingAmount = parseFloat(
        new Decimal(totalAmount).minus(completedPayment).toFixed(2)
    );
    pendingAmount = parseFloat(
        new Decimal(pendingAmount).minus(creditedAmount).toFixed(2)
    );
    pendingAmount = parseFloat(
        new Decimal(pendingAmount).minus(rejectedAmount).toFixed(2)
    );
    // pendingAmount = parseFloat(
    //     new Decimal(pendingAmount).minus(onHoldAmount).toFixed(2)
    // );
    return pendingAmount;
};

export const calculateQuantityToPayFor = (
    quantity: number,
    grRejection: number,
    qcRejection: number
): number => {
    return new Decimal(new Decimal(quantity).minus(grRejection).toNumber())
        .minus(qcRejection)
        .toNumber();
};

export const setPaymentItemRow = (item: any) => {
    const completedPayment = parseFloat(item.payment_information.completed);
    const creditedAmount = parseFloat(item.payment_information.credited);
    const shippingPerUnit = item.pricing_information
        ? item.pricing_information.shipping_per_unit
        : '0';
    const taxes =
        item.pricing_information && item.pricing_information.additional_charges
            ? item.pricing_information.additional_charges
            : [];
    const quantity = item.quantity_information
        ? item.quantity_information.quantity
        : '0';
    const price = item.pricing_information
        ? item.pricing_information.price
        : '0';
    const totalAmountWithTSD = parseFloat(
        item.pricing_information ? item.pricing_information.total_amount : '0'
    );
    const grRejection =
        item.fulfilment_information && item.fulfilment_information.gr_rejection
            ? +item.fulfilment_information.gr_rejection
            : 0;
    const grOverdelivery =
        item.fulfilment_information &&
        item.fulfilment_information.gr_overdeliver
            ? +item.fulfilment_information.gr_overdeliver
            : 0;
    const qcRejection =
        item.fulfilment_information && item.fulfilment_information.qc_rejection
            ? +item.fulfilment_information.qc_rejection
            : 0;

    const totalWithoutDiscount = calculateItemTotal(
        quantity,
        price,
        shippingPerUnit,
        taxes
    );

    const subTotal = calculateItemTotal(quantity, price, '0', []);

    const discount = new Decimal(totalWithoutDiscount)
        .minus(totalAmountWithTSD)
        .toNumber();

    // const discountedAmount = new Decimal(subTotal).minus(discount).toNumber();

    const rejectedQuantity = grRejection + grOverdelivery + qcRejection;

    // const discountedPrice = new Decimal(discountedAmount)
    //     .dividedBy(parseFloat(quantity))
    //     .toFixed(2);

    const itemRate = new Decimal(totalAmountWithTSD)
        .dividedBy(quantity)
        .toFixed(3);

    const rejectedAmount = +(+itemRate * rejectedQuantity).toFixed(2);

    const grRejectedAmount = +(
        +itemRate *
        (grRejection + grOverdelivery)
    ).toFixed(2);

    const qcRejectedAmount = +(+itemRate * qcRejection).toFixed(2);

    const onHoldAmount = +(
        +itemRate *
        (item.termination_information !== null
            ? +item.termination_information.quantity
            : 0)
    ).toFixed(2);

    const amountPending = Math.max(
        calculatePendingAmount(
            totalAmountWithTSD,
            completedPayment,
            creditedAmount,
            rejectedAmount
            // onHoldAmount
        ),
        0
    );

    const formattedTotal = new Decimal(
        calculateItemTotal(quantity, price, shippingPerUnit, taxes)
    )
        .minus(discount)
        .toNumber();

    return {
        rejectedAmount,
        amountPending,
        totalAmountWithTSD,
        subTotal,
        discount,
        formattedTotal,
        onHoldAmount,
        grRejectedAmount,
        qcRejectedAmount,
    };
};

export const setPaymentDetailItemRow = (item: any) => {
    const batch = item.invoice_item;

    let totalQuantity = item.invoice_item.quantity_information.quantity;

    let totalAmount: string =
        item.invoice_item.pricing_information.total_amount;

    let measurementUnit = batch.quantity_information.measurement_unit_name;

    let currency = batch.pricing_information.currency_code_abbreviation;
    let currencySymbol = batch.pricing_information.currency_symbol;

    let subTotal = calculateItemTotal(
        totalQuantity,
        batch.pricing_information ? batch.pricing_information.price : '0',
        '0',
        []
    );

    const shippingPerUnit = batch.pricing_information
        ? batch.pricing_information.shipping_per_unit
        : '0';
    const taxes =
        batch.pricing_information &&
        batch.pricing_information.additional_charges
            ? batch.pricing_information.additional_charges
            : [];
    const price = batch.pricing_information
        ? batch.pricing_information.price
        : '0';
    const totalAmountWithTSD = parseFloat(totalAmount);

    const totalWithoutDiscount = calculateItemTotal(
        totalQuantity,
        price,
        shippingPerUnit,
        taxes
    );

    const discount = new Decimal(totalWithoutDiscount)
        .minus(totalAmountWithTSD)
        .toNumber();

    const formattedTotal = new Decimal(
        calculateItemTotal(totalQuantity, price, shippingPerUnit, taxes)
    )
        .minus(discount)
        .toNumber();

    return {
        discount,
        formattedTotal,
        totalQuantity,
        totalAmount,
        measurementUnit,
        subTotal,
        shippingPerUnit,
        taxes,
        price,
        currency,
        currencySymbol,
    };
};

export const groupPaymentInvoiceItem = (items: any[]) => {
    const poInvoiceItemDict: { [key: string]: any } = {};
    items.forEach((item) => {
        let payType = item.item_type.includes('PREPAYMENT')
            ? PaymentItemType.PREPAYMENT
            : PaymentItemType.GOODS_PAYMENT;

        const poInvoiceItemMap =
            item.invoice + '__' + item.purchase_order_item + '__' + payType;

        if (
            item.pricing_information &&
            item.pricing_information.price &&
            payType === PaymentItemType.GOODS_PAYMENT
        ) {
            let poInvoiceItem = poInvoiceItemDict[poInvoiceItemMap];
            const totalRejections = +(
                +item.fulfilment_information.gr_rejection +
                +item.fulfilment_information.gr_overdeliver +
                +item.fulfilment_information.qc_rejection
            ).toFixed(2);
            const holdQty = item.hold_information
                ? new Decimal(parseFloat(item.quantity_information.quantity))
                      .minus(totalRejections)
                      .toFixed(2)
                : '0.00';

            if (!poInvoiceItem) {
                poInvoiceItemDict[poInvoiceItemMap] = item;
                poInvoiceItemDict[poInvoiceItemMap].qcDone =
                    +poInvoiceItemDict[poInvoiceItemMap]['qc_count'] >= 1;
                poInvoiceItemDict[poInvoiceItemMap]['invoice_item_uids'] = [
                    item.invoice_item_id,
                ];
                poInvoiceItemDict[poInvoiceItemMap]['invoiceUuid'] =
                    item.invoice;
                if (
                    item.quantity_information &&
                    (!isNull(item.hold_information) ||
                        !isNull(item.termination_information))
                ) {
                    poInvoiceItemDict[poInvoiceItemMap][
                        'on_hold_termination_quantity'
                    ] = holdQty;
                } else {
                    poInvoiceItemDict[poInvoiceItemMap][
                        'on_hold_termination_quantity'
                    ] = '0';
                }
            } else {
                if (
                    !isNull(item.hold_information) ||
                    !isNull(item.termination_information)
                ) {
                    poInvoiceItemDict[poInvoiceItemMap][
                        'on_hold_termination_quantity'
                    ] = addNumbers(
                        poInvoiceItem.on_hold_termination_quantity,
                        holdQty
                    );
                }

                // completed
                poInvoiceItem.payment_information.completed = addNumbers(
                    poInvoiceItem.payment_information.completed,
                    item.payment_information.completed
                );

                // credited
                poInvoiceItem.payment_information.credited = addNumbers(
                    poInvoiceItem.payment_information.credited,
                    item.payment_information.credited
                );

                // quantity
                poInvoiceItem.quantity_information.quantity = addNumbers(
                    poInvoiceItem.quantity_information.quantity,
                    item.quantity_information.quantity
                );

                // total_amount
                poInvoiceItem.pricing_information.total_amount = addNumbers(
                    poInvoiceItem.pricing_information.total_amount,
                    item.pricing_information.total_amount
                );

                // gr_rejection
                poInvoiceItem.fulfilment_information.gr_rejection =
                    +poInvoiceItem.fulfilment_information.gr_rejection +
                    +item.fulfilment_information.gr_rejection +
                    +item.fulfilment_information.gr_overdeliver;

                // qc_rejection
                poInvoiceItem.fulfilment_information.qc_rejection = addNumbers(
                    poInvoiceItem.fulfilment_information.qc_rejection,
                    item.fulfilment_information.qc_rejection
                );

                // qc_status
                poInvoiceItem.qcDone =
                    poInvoiceItem.qcDone && item.qc_count > 0;

                poInvoiceItem.invoice_item_uids.push(item.invoice_item_id);

                poInvoiceItem.hold_information =
                    poInvoiceItem.hold_information || item.hold_information;
                poInvoiceItem.termination_information =
                    poInvoiceItem.termination_information ||
                    item.termination_information;

                // if (!isNull(item.hold_information)) {
                //     poInvoiceItem.hold_information = item.hold_information;
                // }
                // if (!isNull(item.termination_information)) {
                //     poInvoiceItem.termination_information =
                //         item.termination_information;
                // }
            }
        }
        if (payType === PaymentItemType.PREPAYMENT) {
            let poInvoiceItem = poInvoiceItemDict[poInvoiceItemMap];

            if (!poInvoiceItem) {
                item.fulfilment_information = {
                    qc_rejection: '0',
                    gr_rejection: '0',
                };
                // item.quantity_information = {
                //     quantity: '1',
                // };
                item.on_hold_termination_quantity = '0';
                item.invoice_item_uids = [item.invoice_item_id];
                item.qc_count = 1;
                poInvoiceItemDict[poInvoiceItemMap] = item;
            }
        }
    });

    const flatPOInvoiceItemDict = Object.keys(poInvoiceItemDict).map(
        (dictIds) => poInvoiceItemDict[dictIds]
    );

    return flatPOInvoiceItemDict;
};

const addNumbers = (a: string | number, b: string | number) => {
    const aStr = a + '';
    const bStr = b + '';
    return new Decimal(parseFloat(aStr) || 0)
        .plus(parseFloat(bStr) || 0)
        .toFixed(2);
};

// export const groupPaymentItemsByDueDate = (
//     items: IPaymentsPendingListSummary[]
// ): IDefaultViewPaymentItem[] => {
//     const groupMap: { [key: string]: IDefaultViewPaymentItem } = {};
//     items.forEach((item) => {
//         if (!isEmpty(item.invoiceUuid)) {
//             const itemAddress = `${item.invoiceUuid}__${item.paymentDueDate}__${item.paymentType}`;
//             const itemFromMap = groupMap[itemAddress];
//             if (!itemFromMap) {
//                 const itemDetails: IDefaultViewPaymentItem = {
//                     id: 0,
//                     invoiceItemUid: item.invoiceItemUid,
//                     entityName: item.entityName,
//                     entityUid: item.entityUid,
//                     poId: item.poId,
//                     poUid: item.poUid,
//                     invoiceId: item.invoiceId || '',
//                     vendorName: item.vendorName,
//                     vendorUid: item.vendorUid,
//                     vendorContact: item.vendorContact,
//                     vendorContactUid: item.vendorContactUid,
//                     items: 1,
//                     itemNames: new Set([item.itemDetails.itemName]),
//                     dueDate: item.paymentDueDate,
//                     totalAmount: item.totalAmount,
//                     pendingAmount: item.amountPending,
//                     itemOnHold: item.itemOnHold,
//                     itemTermination: item.itemTermination,
//                     poOnHold: item.poOnHold,
//                     poTermination: item.poTermination,
//                     poTerminationAccepted: item.poTerminationAccepted,
//                     paymentType: item.paymentType,
//                     showPrepayment: item.showPrepayment,
//                     currency: item.currency,
//                     currencyUid: item.currencyUid,
//                     invoiceItemUids: item.invoiceItemUids,
//                     onHoldTerminationQuantity: 0,
//                     totalInfo: {
//                         measurementUnit: '',
//                         shippingPerUnit: '',
//                         quantity: '',
//                         taxList: [],
//                         subTotal: 0,
//                         currency: '',
//                         currencySymbol: '',
//                         price: '',
//                         discount: 0,
//                         formattedTotal: 0,
//                     },
//                     allItemTermination: false,
//                     onHoldTerminationAmount: item.onHoldTerminationAmount,
//                     gr_status: item.gr_status,
//                     qc_status: item.qc_status,
//                 };
//                 groupMap[itemAddress] = itemDetails;
//             } else {
//                 let qcStatus: 'PENDING' | 'PARTIAL' | 'COMPLETE' =
//                     groupMap[itemAddress].qc_status === 'PENDING' &&
//                     item.qc_status === 'PENDING'
//                         ? 'PENDING'
//                         : groupMap[itemAddress].qc_status === 'PENDING' &&
//                           item.qc_status === 'COMPLETE'
//                         ? 'PARTIAL'
//                         : groupMap[itemAddress].qc_status === 'PARTIAL'
//                         ? 'PARTIAL'
//                         : groupMap[itemAddress].qc_status === 'COMPLETE' &&
//                           item.qc_status === 'PENDING'
//                         ? 'PARTIAL'
//                         : 'COMPLETE';

//                 groupMap[itemAddress] = {
//                     ...itemFromMap,
//                     items: itemFromMap.items + 1,
//                     dueDate:
//                         item.paymentDueDate !== null &&
//                         itemFromMap.dueDate !== null
//                             ? Math.min(item.paymentDueDate, itemFromMap.dueDate)
//                             : item.paymentDueDate ?? itemFromMap.dueDate,
//                     totalAmount: parseFloat(
//                         addNumbers(itemFromMap.totalAmount, item.totalAmount)
//                     ),
//                     pendingAmount: parseFloat(
//                         addNumbers(
//                             itemFromMap.pendingAmount,
//                             item.amountPending
//                         )
//                     ),
//                     itemOnHold: item.itemOnHold && itemFromMap.itemOnHold,
//                     itemTermination:
//                         item.itemTermination && itemFromMap.itemTermination,
//                     poOnHold: item.poOnHold ? true : itemFromMap.poOnHold,
//                     poTermination: item.poTermination
//                         ? true
//                         : itemFromMap.poTermination,
//                     poTerminationAccepted: item.poTerminationAccepted
//                         ? true
//                         : itemFromMap.poTerminationAccepted,
//                     invoiceItemUids: [
//                         ...itemFromMap.invoiceItemUids,
//                         ...item.invoiceItemUids,
//                     ],
//                     itemNames: itemFromMap.itemNames.add(
//                         item.itemDetails.itemName
//                     ),
//                     onHoldTerminationAmount: new Decimal(
//                         item.onHoldTerminationAmount
//                     )
//                         .add(itemFromMap.onHoldTerminationAmount)
//                         .toNumber(),
//                     qc_status: qcStatus,
//                 };
//             }
//         }
//     });
//     return Object.keys(groupMap).map((group, idx) => ({
//         ...groupMap[group],
//         id: idx,
//     }));
// };

// export const groupPaymentItemsByInvoice = (
//     items: IPaymentsPendingListSummary[]
// ): IInvoiceViewPaymentItem[] => {
//     const groupMap: { [key: string]: IInvoiceViewPaymentItem } = {};
//     items.forEach((item) => {
//         if (!isEmpty(item.invoiceUuid)) {
//             const itemAddress = item.invoiceUuid || '';
//             const itemFromMap = groupMap[itemAddress];
//             if (!itemFromMap) {
//                 const itemDetails: IInvoiceViewPaymentItem = {
//                     id: 0,
//                     invoiceItemUid: item.invoiceItemUid,
//                     entityName: item.entityName,
//                     entityUid: item.entityUid,
//                     poId: item.poId,
//                     poUid: item.poUid,
//                     invoiceId: item.invoiceId || '',
//                     vendorName: item.vendorName,
//                     vendorUid: item.vendorUid,
//                     vendorContact: item.vendorContact,
//                     vendorContactUid: item.vendorContactUid,
//                     items: 1,
//                     itemNames: new Set([item.itemDetails.itemName]),
//                     dueDate: item.paymentDueDate,
//                     totalAmount: item.totalAmount,
//                     pendingAmount: item.amountPending,
//                     itemOnHold: item.itemOnHold,
//                     itemTermination: item.itemTermination,
//                     poOnHold: item.poOnHold,
//                     poTermination: item.poTermination,
//                     poTerminationAccepted: item.poTerminationAccepted,
//                     paymentType: item.paymentType,
//                     showPrepayment: item.showPrepayment,
//                     currency: item.currency,
//                     currencyUid: item.currencyUid,
//                     invoiceItemUids: item.invoiceItemUids,
//                     onHoldTerminationQuantity: 0,
//                     totalInfo: {
//                         measurementUnit: '',
//                         shippingPerUnit: '',
//                         quantity: '',
//                         taxList: [],
//                         subTotal: 0,
//                         currency: '',
//                         currencySymbol: '',
//                         price: '',
//                         discount: 0,
//                         formattedTotal: 0,
//                     },
//                     allItemTermination: false,
//                     onHoldTerminationAmount: item.onHoldTerminationAmount,
//                     gr_status: item.gr_status,
//                     qc_status: item.qc_status,
//                 };
//                 groupMap[itemAddress] = itemDetails;
//             } else {
//                 let qcStatus: 'PENDING' | 'PARTIAL' | 'COMPLETE' =
//                     groupMap[itemAddress].qc_status === 'PENDING' &&
//                     item.qc_status === 'PENDING'
//                         ? 'PENDING'
//                         : groupMap[itemAddress].qc_status === 'PENDING' &&
//                           item.qc_status === 'COMPLETE'
//                         ? 'PARTIAL'
//                         : groupMap[itemAddress].qc_status === 'PARTIAL'
//                         ? 'PARTIAL'
//                         : groupMap[itemAddress].qc_status === 'COMPLETE' &&
//                           item.qc_status === 'PENDING'
//                         ? 'PARTIAL'
//                         : 'COMPLETE';

//                 if (
//                     groupMap[itemAddress].qc_status === 'PENDING' &&
//                     item.qc_status === 'COMPLETE'
//                 ) {
//                     qcStatus = 'PARTIAL';
//                 } else if (
//                     groupMap[itemAddress].qc_status === 'PARTIAL' &&
//                     item.qc_status === 'PENDING'
//                 ) {
//                     qcStatus = 'PARTIAL';
//                 } else if (
//                     groupMap[itemAddress].qc_status === 'COMPLETE' &&
//                     item.qc_status === 'PENDING'
//                 ) {
//                     qcStatus = 'PARTIAL';
//                 }
//                 groupMap[itemAddress] = {
//                     ...itemFromMap,
//                     items: itemFromMap.items + 1,
//                     dueDate:
//                         item.paymentDueDate !== null &&
//                         itemFromMap.dueDate !== null
//                             ? Math.min(item.paymentDueDate, itemFromMap.dueDate)
//                             : item.paymentDueDate ?? itemFromMap.dueDate,
//                     totalAmount: parseFloat(
//                         addNumbers(itemFromMap.totalAmount, item.totalAmount)
//                     ),
//                     pendingAmount: parseFloat(
//                         addNumbers(
//                             itemFromMap.pendingAmount,
//                             item.amountPending
//                         )
//                     ),
//                     itemOnHold: item.itemOnHold && itemFromMap.itemOnHold,
//                     itemTermination:
//                         item.itemTermination && itemFromMap.itemTermination,
//                     poOnHold: item.poOnHold ? true : itemFromMap.poOnHold,
//                     poTermination: item.poTermination
//                         ? true
//                         : itemFromMap.poTermination,
//                     poTerminationAccepted: item.poTerminationAccepted
//                         ? true
//                         : itemFromMap.poTerminationAccepted,
//                     invoiceItemUids: [
//                         ...itemFromMap.invoiceItemUids,
//                         ...item.invoiceItemUids,
//                     ],
//                     itemNames: itemFromMap.itemNames.add(
//                         item.itemDetails.itemName
//                     ),
//                     onHoldTerminationAmount: new Decimal(
//                         item.onHoldTerminationAmount
//                     )
//                         .add(itemFromMap.onHoldTerminationAmount)
//                         .toNumber(),
//                     gr_status:
//                         item.gr_status === itemFromMap.gr_status
//                             ? itemFromMap.gr_status
//                             : [item.gr_status, itemFromMap.gr_status].includes(
//                                   PaymentItemStatus.GOODS_PENDING
//                               )
//                             ? PaymentItemStatus.GOODS_PENDING
//                             : PaymentItemStatus.GOODS_RECEIVED,
//                     qc_status: qcStatus,
//                 };
//             }
//         }
//     });
//     return Object.keys(groupMap).map((group, idx) => ({
//         ...groupMap[group],
//         id: idx,
//     }));
// };
