import { IForumlaWithIfElseAndOperators } from '../../AdditionalCost/Interfaces/AdditionalCostInterfaces';
import { DashBoardFilter } from '../../ProjectGlCostCenter/interface/project.model';
import { baseApiSlice } from '../../Redux/Slices/BaseApiSlice';
import { get, post } from '../../Utils/api';
import {
    IGetFormulaResponse,
    IPageDetails,
    IStatusMaping,
} from '../Models/formula.model';

export interface ICreateFormulaPayload {
    field: 'CUSTOM_FIELD';
    field_name: string;
    formula: IForumlaWithIfElseAndOperators;
}

const statuseMapping: IStatusMaping = {
    all: 'all',
};

export interface IFetchFormulaDataPayload {
    dashboard_view: string;
    tab: string;
    search_text: string;
    sort_fields: string[];
    items_per_page: number;
    page_number: number;
    filters: DashBoardFilter | null;
}

export const createFormula = async (payload: ICreateFormulaPayload) => {
    try {
        const resp = await post(`/formula/create/`, payload);
        return resp.data;
    } catch (err) {
        throw err;
    }
};

export const { useCreateFormulaMutation } = baseApiSlice.injectEndpoints({
    endpoints: (build) => ({
        createFormula: build.mutation<any, ICreateFormulaPayload>({
            query: (payload) => ({
                url: `/formula/create/`,
                method: 'POST',
                body: payload,
            }),
        }),
    }),
});

export const getListOfFormula = async (
    filterByField?: 'ADDITIONAL_COST' | 'CUSTOM_FIELD'
) => {
    try {
        const resp = await get<IGetFormulaResponse[]>(`/formula/`);

        return resp.data.filter((field) => {
            if (filterByField !== undefined) {
                return field.field === filterByField;
            } else {
                return true;
            }
        });
    } catch (err) {
        throw err;
    }
};

// Json
// {
//     "dashboard_view": "formula",
//     "tab": "active",
//     "search_text": "",
//     "sort_fields": [],
//     "page_number": 1,
//     "items_per_page": 10
// }

export const fetchFormulaData = async ({
    tab,
    search_text,
    sort_fields,
    page_number,
    items_per_page,
    filters,
}: {
    tab: keyof IStatusMaping;
    search_text: string;
    sort_fields: string[];
    page_number: number;
    items_per_page: number;
    filters: DashBoardFilter | null;
}): Promise<{
    formulaList: IGetFormulaResponse[];
    pageDetails: IPageDetails;
}> => {
    const postData: IFetchFormulaDataPayload = {
        dashboard_view: 'formula',
        tab: statuseMapping[tab],
        search_text:
            search_text?.length && search_text?.length > 0 ? search_text : '',
        sort_fields: sort_fields ?? [],
        items_per_page: items_per_page,
        page_number: page_number,
        filters: filters,
    };

    const resp = await post<IFetchFormulaDataPayload, any>(
        `/dashboard/`,
        postData
    );
    return {
        formulaList: resp.data.results,
        pageDetails: resp.data.pagination,
    };
};

export const { useGetListOfFormulaQuery } = baseApiSlice.injectEndpoints({
    endpoints: (build) => ({
        getListOfFormula: build.query<
            IGetFormulaResponse[],
            {
                filterByField?: 'ADDITIONAL_COST' | 'CUSTOM_FIELD';
            }
        >({
            queryFn: async ({ filterByField }) => {
                try {
                    const resp = await get<IGetFormulaResponse[]>(`/formula/`);

                    return {
                        data: resp.data.filter((field) => {
                            if (filterByField !== undefined) {
                                return field.field === filterByField;
                            } else {
                                return true;
                            }
                        }),
                    };
                } catch (err: any) {
                    return { error: err };
                }
            },
        }),
    }),
});
