export interface INotificationValues {
    actionUser: string; // which user did the action on an event (send for approval, add, edit, etc)
    actionEntity: string; // the entity name of the user who did an action
    actionTime: string; // what time the action was done
    resourceId: string; // the event UUID on which the actions are taking place
    resourceName: string; // the name of the event on which the actions are taking place
    resourceEntityName: string; // the name of the entity the action is performed for
    resourseEntityId?: string; // the UUID of the entity the action is performed for
}

export interface INotificationDetails {
    TITLE: string;
    MESSAGE: string;
    URL?: string;
}

export enum BuyerNotificationType {
    EVENT_NAME_UPDATE_BUYER = 'EVENT_NAME_UPDATE_BUYER',
    RFQ_APPROVAL_REQUEST = 'RFQ_APPROVAL_REQUEST',
    RFQ_REVISED_APPROVAL_REQUEST = 'RFQ_REVISED_APPROVAL_REQUEST',
    RFQ_ONGOING_BUYER = 'RFQ_ONGOING_BUYER',
    RFQ_REWORK = 'RFQ_REWORK',
    RFQ_BUYER_QUOTE = 'RFQ_BUYER_QUOTE',
    RFQ_BUYER_QUOTE_OTHERS = 'RFQ_BUYER_QUOTE_OTHERS',
    RFQ_DEADLINE_UPDATE_BUYER = 'RFQ_DEADLINE_UPDATE_BUYER',
    PO_APPROVAL_REQUEST = 'PO_APPROVAL_REQUEST',
    PO_APPROVAL_REJECTED = 'PO_APPROVAL_REJECTED',
    PO_REWORK = 'PO_REWORK',
    PO_ISSUED_BUYER = 'PO_ISSUED_BUYER',
    PO_DECLINED_BUYER = 'PO_DECLINED_BUYER',
    PO_RESCINDED_BUYER = 'PO_RESCINDED_BUYER',
    PO_ONGOING_BUYER = 'PO_ONGOING_BUYER',
    PO_HOLD_BUYER = 'PO_HOLD_BUYER',
    PO_TERMINATION_REQUESTED_BUYER = 'PO_TERMINATION_REQUESTED_BUYER',
    PO_DS_UPDATE_BUYER = 'PO_DS_UPDATE_BUYER',
    INVOICE_ITEM_TERMINATION_REQUESTED_BUYER = 'INVOICE_ITEM_TERMINATION_REQUESTED_BUYER',
    INVOICE_AUTO_HOLD_BUYER = 'INVOICE_AUTO_HOLD_BUYER',
    PREPAYMENT_RECEIVED_BUYER = 'PREPAYMENT_RECEIVED_BUYER',
    PAYMENT_COMPLETED_BUYER = 'PAYMENT_COMPLETED_BUYER',
    PAYMENT_PARTIAL_BUYER = 'PAYMENT_PARTIAL_BUYER',
    EVENT_DEADLINE_APPROACHING_BUYER = 'EVENT_DEADLINE_APPROACHING_BUYER',
    PO_TERMINATION_ACCEPTED_BUYER = 'PO_TERMINATION_ACCEPTED_BUYER',
    PO_DS_CONF_PENDING_BUYER = 'PO_DS_CONF_PENDING_BUYER',
    PO_DS_CONF_REJECTED_BUYER = 'PO_DS_CONF_REJECTED_BUYER',
    PREPAYMENT_INVOICE_AUTOGENERATED_BUYER = 'PREPAYMENT_INVOICE_AUTOGENERATED_BUYER',
    INVOICE_PROVISIONAL_CREATE_BUYER = 'INVOICE_PROVISIONAL_CREATE_BUYER',
    INVOICE_VERIFIED_BUYER = 'INVOICE_VERIFIED_BUYER',
    GR_CREATE_BUYER = 'GR_CREATE_BUYER',
    QC_CREATE_BUYER = 'QC_CREATE_BUYER',
    RFQ_REJECTED = 'RFQ_REJECTED',
    INVOICE_ISSUED = 'INVOICE_ISSUED',
    INVOICE_ITEM_TERMINATION_ACCEPTED = 'INVOICE_ITEM_TERMINATION_ACCEPTED',
    PAYMENT_OVERDUE = 'PAYMENT_OVERDUE',
    RFQ_PERMISSIONS_UPDATE = 'RFQ_PERMISSIONS_UPDATE',
}

export enum SellerNotificationType {
    EVENT_NAME_UPDATE_SELLER = 'EVENT_NAME_UPDATE_SELLER',
    RFQ_ONGOING_SELLER = 'RFQ_ONGOING_SELLER',
    REVISION_RFQ_ONGOING_SELLER = 'REVISION_RFQ_ONGOING_SELLER',
    RFQ_HOTFIX_SELLER = 'RFQ_HOTFIX_SELLER',
    RFQ_PAUSED_SELLER = 'RFQ_PAUSED_SELLER',
    RFQ_REMIND_SELLER = 'RFQ_REMIND_SELLER',
    RFQ_SELLER_QUOTE = 'RFQ_SELLER_QUOTE',
    PO_ISSUED_SELLER = 'PO_ISSUED_SELLER',
    RFQ_DEADLINE_UPDATE_SELLER = 'RFQ_DEADLINE_UPDATE_SELLER',
    PO_RESCINDED_SELLER = 'PO_RESCINDED_SELLER',
    PO_HOLD_SELLER = 'PO_HOLD_SELLER',
    PO_TERMINATION_REQUESTED_SELLER = 'PO_TERMINATION_REQUESTED_SELLER',
    INVOICE_ITEM_TERMINATION_REQUESTED_SELLER = 'INVOICE_ITEM_TERMINATION_REQUESTED_SELLER',
    INVOICE_AUTO_HOLD_SELLER = 'INVOICE_AUTO_HOLD_SELLER',
    PREPAYMENT_RECEIVED_SELLER = 'PREPAYMENT_RECEIVED_SELLER',
    PAYMENT_COMPLETED_SELLER = 'PAYMENT_COMPLETED_SELLER',
    PAYMENT_PARTIAL_SELLER = 'PAYMENT_PARTIAL_SELLER',
    EVENT_DEADLINE_APPROACHING_SELLER = 'EVENT_DEADLINE_APPROACHING_SELLER',
    PO_DS_CONF_PENDING_SELLER = 'PO_DS_CONF_PENDING_SELLER',
    PO_DS_CONF_REJECTED_SELLER = 'PO_DS_CONF_REJECTED_SELLER',
    PREPAYMENT_INVOICE_AUTOGENERATED_SELLER = 'PREPAYMENT_INVOICE_AUTOGENERATED_SELLER',
    INVOICE_PROVISIONAL_CREATE_SELLER = 'INVOICE_PROVISIONAL_CREATE_SELLER',
    GR_CREATE_SELLER = 'GR_CREATE_SELLER',
    QC_CREATE_SELLER = 'QC_CREATE_SELLER',
}

export enum AdminNotificationType {
    SIGN_UP_REQUEST = 'SIGN_UP_REQUEST',
    SIGN_UP_ACCEPTED = 'SIGN_UP_ACCEPTED',
    ENTITY_VERIFIED = 'ENTITY_VERIFIED',
}

export const mergedNotificationTypes = {
    ...BuyerNotificationType,
    ...SellerNotificationType,
    ...AdminNotificationType,
};

type INotificationMap = {
    [key in keyof typeof mergedNotificationTypes]: (
        values: INotificationValues
    ) => INotificationDetails;
};

const NOTIFICATION_MAP: INotificationMap = {
    // When a draft RFQ has been sent for approval
    RFQ_APPROVAL_REQUEST: (values: INotificationValues) => ({
        TITLE: `Event update: Approval requested`,
        URL: `/buyer/events/rfq/${values.resourceId}`,
        MESSAGE: `Event ${values.resourceName} has been sent for your review by ${values.actionUser}`,
    }),
    // RFQ_APPROVAL_REQUEST_OTHERS: (values: INotificationValues) => ({
    //     TITLE: `Event update: Approval requested`,
    //     URL: `/buyer/events/${values.resourceId}/summary`,
    //     MESSAGE: `Event ${values.resourceName} has been sent for review by ${values.actionUser}`,
    // }),

    // When an event is live
    RFQ_ONGOING_BUYER: (values: INotificationValues) => ({
        TITLE: `Event update: New live event`,
        URL: `/buyer/events/${values.resourceId}/summary`,
        MESSAGE: `Event ${values.resourceName} from ${values.actionEntity} is now live and accepting bids`,
    }),
    RFQ_ONGOING_SELLER: (values: INotificationValues) => ({
        TITLE: `Event invitation received`,
        URL: `/seller/events/${values.resourseEntityId}/${values.resourceId}/rfqinvitation`,
        MESSAGE: `Event ${values.resourceName} from ${values.actionEntity} is now live and accepting bids from ${values.resourceEntityName}`,
    }),

    RFQ_HOTFIX_SELLER: (values: INotificationValues) => ({
        TITLE: `Event update: Changes to event`,
        URL: `/seller/events/${values.resourseEntityId}/${values.resourceId}/rfqinvitation`,
        MESSAGE: `${values.actionUser} has made changes to ${values.resourceName}`,
    }),

    RFQ_PAUSED_SELLER: (values: INotificationValues) => ({
        TITLE: `Event update: Event paused`,
        URL: `/seller/events/${values.resourseEntityId}/${values.resourceId}/summary`,
        MESSAGE: `Event ${values.resourceName} is paused and will not be accepting bids`,
    }),

    // Bid sent to the buyer
    RFQ_BUYER_QUOTE: (values: INotificationValues) => ({
        TITLE: `Event update: Bid received`,
        URL: `/buyer/events/live/${values.resourceId}/quotes`,
        MESSAGE: `${values.actionEntity} has sent a quote for event ${values.resourceName}`,
        // MESSAGE: `A quote for event ${values.resourceName} has been received from ${values.actionEntity}`,
    }),
    RFQ_BUYER_QUOTE_OTHERS: (values: INotificationValues) => ({
        TITLE: `Event update: Bid submitted`,
        URL: `/buyer/events/live/${values.resourceId}/quotes`,
        MESSAGE: `${values.actionUser} has sent a quote to ${values.resourceEntityName} for event ${values.resourceName}`,
    }),

    // Bid sent to the seller
    RFQ_SELLER_QUOTE: (values: INotificationValues) => ({
        TITLE: `Event update: Bid received`,
        URL: `/seller/events/${values.resourseEntityId}/${values.resourceId}/rfqnegotiation`,
        MESSAGE: `${values.actionEntity} has sent a quote for event ${values.resourceName}`,
    }),

    RFQ_REMIND_SELLER: (values: INotificationValues) => ({
        TITLE: `Event update: Bid reminder`,
        URL: `/seller/events/${values.resourseEntityId}/${values.resourceId}/rfqinvitation`,
        MESSAGE: `${values.actionEntity} has sent a reminder for event ${values.resourceName}`,
    }),

    // PO sent for approval

    PO_APPROVAL_REQUEST: (values: INotificationValues) => ({
        TITLE: `PO update: Approval requested`,
        URL: `/buyer/purchase_orders/${values.resourceId}/review/`,
        MESSAGE: `A draft PO ${values.resourceName} to ${values.resourceEntityName} has been sent for your review by ${values.actionUser}`,
        // MESSAGE: `A draft PO for event ${values.resourceName} has been sent for your review by ${values.actionUser}`,
    }),

    // PO is issued

    PO_ISSUED_BUYER: (values: INotificationValues) => ({
        TITLE: `PO update: PO issued`,
        URL: `/buyer/purchase_orders/${values.resourceId}/review/`,
        MESSAGE: `PO ${values.resourceName} has been issued to ${values.resourceEntityName} by ${values.actionUser}`,
        // MESSAGE: `A PO has been sent for event ${values.resourceName} by ${values.actionUser}`,
    }),

    PO_ISSUED_SELLER: (values: INotificationValues) => ({
        TITLE: `PO update: PO issued`,
        URL: `/seller/purchase_orders/${values.resourceId}/review/`,
        MESSAGE: ` PO ${values.resourceName} has been issued to you by ${values.actionUser} from ${values.actionEntity}`,
    }),

    // PO is rejected

    PO_DECLINED_BUYER: (values: INotificationValues) => ({
        TITLE: `PO update: PO declined`,
        URL: `/buyer/purchase_orders/${values.resourceId}/review/`,
        MESSAGE: `${values.actionEntity} has declined PO ${values.resourceName}`,
        // MESSAGE: `A PO for event ${values.resourceName} has been declined`,
    }),

    // PO is rescinded

    PO_RESCINDED_BUYER: (values: INotificationValues) => ({
        TITLE: `PO update: PO rescinded`,
        URL: `/buyer/purchase_orders/${values.resourceId}/review/`,
        MESSAGE: `PO ${values.resourceName} to ${values.resourceEntityName} has been rescinded by ${values.actionUser}`,
    }),

    PO_RESCINDED_SELLER: (values: INotificationValues) => ({
        TITLE: `PO update: PO rescinded`,
        URL: `/seller/purchase_orders/${values.resourceId}/review/`,
        MESSAGE: `PO ${values.resourceName} from ${values.actionEntity} has been rescinded`,
    }),

    // PO is accepted
    PO_ONGOING_BUYER: (values: INotificationValues) => ({
        TITLE: `PO update: PO accepted`,
        URL: `/buyer/purchase_orders/${values.resourceId}/review/`,
        MESSAGE: `${values.actionEntity} has accepted PO ${values.resourceName}`,
    }),

    // PO_ACCEPTED_SELLER: (values: INotificationValues) => ({
    //     TITLE: `Event update: PO accepted`,
    //     URL: `/seller/events/${values.resourceId}/purchaseorders`,
    //     MESSAGE: `${values.actionEntity} has received your confirmation of a PO for event ${values.resourceName}`,
    // }),

    // Event name changed
    EVENT_NAME_UPDATE_BUYER: (values: INotificationValues) => ({
        TITLE: `Event update: Event name`,
        URL: `/buyer/events/${values.resourceId}/summary`,
        MESSAGE: `An event has been renamed to ${values.resourceName} by ${values.actionUser}`,
    }),
    EVENT_NAME_UPDATE_SELLER: (values: INotificationValues) => ({
        TITLE: `Event update: Event name`,
        URL: `/seller/events/${values.resourseEntityId}/${values.resourceId}/rfqinvitation`,
        MESSAGE: `An event has been renamed to ${values.resourceName} by ${values.actionEntity}`,
    }),

    // RFQ deadline changed
    RFQ_DEADLINE_UPDATE_BUYER: (values: INotificationValues) => ({
        TITLE: `Event update: Event deadline`,
        URL: `/buyer/events/${values.resourceId}/summary`,
        MESSAGE: `${values.resourceName} deadline has been changed by ${values.actionUser}`,
    }),
    RFQ_DEADLINE_UPDATE_SELLER: (values: INotificationValues) => ({
        TITLE: `Event update: Event deadline`,
        URL: `/seller/events/${values.resourseEntityId}/${values.resourceId}/rfqinvitation`,
        MESSAGE: `${values.resourceName} deadline has been changed by ${values.actionEntity}`,
    }),

    // When an Approval Pending RFQ has been sent for rework
    RFQ_REWORK: (values: INotificationValues) => ({
        TITLE: `Action required: Changes requested on draft event`,
        URL: `/buyer/events/rfq/${values.resourceId}`,
        MESSAGE: `Event '${values.resourceName}' has been sent to you with changes requested by ${values.actionUser}`,
    }),
    // An invoice has been put on automatic hold (primary - buyer)
    INVOICE_AUTO_HOLD_BUYER: (values: INotificationValues) => ({
        TITLE: `Invoice Update: Auto-hold placed on invoice`,
        URL: `/buyer/invoices/${values.resourceId}/summary/`,
        MESSAGE: `An auto-hold has been placed on invoice ${values.resourceName} due to a price mismatch with the associated PO`,
    }),
    INVOICE_AUTO_HOLD_SELLER: (values: INotificationValues) => ({
        TITLE: `Invoice Update: Auto-hold placed on invoice`,
        URL: `/seller/invoices/${values.resourceId}/summary/`,
        MESSAGE: `An auto-hold has been placed on invoice ${values.resourceName} due to a price mismatch with the associated PO`,
    }),
    // When payment has been completed for invoices against a seller. (primary - seller) - final
    PAYMENT_COMPLETED_BUYER: (values: INotificationValues) => ({
        TITLE: `Invoice Update: Payment Complete`,
        URL: `/buyer/invoices/${values.resourceId}/summary/`,
        MESSAGE: `Payment complete for invoice ${values.resourceName} to ${values.resourceEntityName}`,
    }),
    PAYMENT_COMPLETED_SELLER: (values: INotificationValues) => ({
        TITLE: `Invoice Update: Payment Complete`,
        URL: `/seller/invoices/${values.resourceId}/summary/`,
        MESSAGE: `Payment complete for invoice ${values.resourceName} from ${values.actionEntity}`,
    }),
    // Termination is requested for an invoice item (Primary - seller)
    INVOICE_ITEM_TERMINATION_REQUESTED_BUYER: (
        values: INotificationValues
    ) => ({
        TITLE: `Invoice Update: Item termination requested`,
        URL: `/buyer/invoices/${values.resourceId}/summary/`,
        MESSAGE: `Termination has been requested for item belonging to invoice ${values.resourceName}`,
    }),
    INVOICE_ITEM_TERMINATION_REQUESTED_SELLER: (
        values: INotificationValues
    ) => ({
        TITLE: `Invoice Update: Item termination requested`,
        URL: `/seller/invoices/${values.resourceId}/summary/`,
        MESSAGE: `Termination has been requested for item belonging to invoice ${values.resourceName}`,
    }),
    // Termination has been requested for PO (Primary - seller) - final
    PO_TERMINATION_REQUESTED_BUYER: (values: INotificationValues) => ({
        TITLE: `PO Update: Termination requested`,
        URL: `/buyer/purchase_orders/${values.resourceId}/summary`,
        MESSAGE: `A termination request has been generated for PO ${values.resourceName} issued  to ${values.resourceEntityName}`,
    }),
    PO_TERMINATION_REQUESTED_SELLER: (values: INotificationValues) => ({
        TITLE: `PO Update: Termination requested`,
        URL: `/seller/purchase_orders/${values.resourceId}/summary`,
        MESSAGE: `A termination request has been generated for PO ${values.resourceName} issued by ${values.actionEntity}`,
    }),
    // Whenever a PO is placed on hold (Primary - seller) - final
    PO_HOLD_BUYER: (values: INotificationValues) => ({
        TITLE: `PO Update: Hold placed`,
        URL: `/buyer/purchase_orders/${values.resourceId}/summary`,
        MESSAGE: `A hold has been generated for PO ${values.resourceName} issued to ${values.resourceEntityName}`,
    }),
    PO_HOLD_SELLER: (values: INotificationValues) => ({
        TITLE: `PO Update: Hold placed`,
        URL: `/seller/purchase_orders/${values.resourceId}/summary`,
        MESSAGE: `A hold has been generated for PO ${values.resourceName} issued by ${values.actionEntity}`,
    }),
    SIGN_UP_REQUEST: (values: INotificationValues) => ({
        TITLE: `User join request received`,
        URL: `/admin/users`,
        MESSAGE: `'${values.resourceName}' has requested to join your enterprise.`,
    }),
    RFQ_REVISED_APPROVAL_REQUEST: (values: INotificationValues) => ({
        TITLE: `Live event update: Approval requested`,
        URL: `/buyer/events/live/${values.resourceId}/review`,
        MESSAGE: `Event '${values.resourceName}' has been revised and sent to you for approval by ${values.actionUser}`,
    }),
    REVISION_RFQ_ONGOING_SELLER: (values: INotificationValues) => ({
        TITLE: `Event update: Event Revised`,
        URL: `/seller/events/${values.resourseEntityId}/${values.resourceId}/rfqinvitation`,
        MESSAGE: `Event '${values.resourceName}' has been revised and is accepting bids from ${values.resourceEntityName}`,
    }),
    PO_APPROVAL_REJECTED: (values: INotificationValues) => ({
        TITLE: `PO update: PO rejected by approver`,
        URL: `/buyer/purchase_orders/${values.resourceId}/review/`,
        MESSAGE: `${values.actionUser} has rejected PO ${values.resourceName}`,
    }),
    PREPAYMENT_RECEIVED_SELLER: (values: INotificationValues) => ({
        TITLE: `PO Update : Prepayment received`,
        URL: `/seller/invoices/${values.resourceId}/prepayment`,
        MESSAGE: `Prepayment received for PO ${values.resourceName} issued by ${values.actionEntity}`,
    }),

    PAYMENT_PARTIAL_SELLER: (values: INotificationValues) => ({
        TITLE: `Payment Update: Partial payment received`,
        URL: `/seller/invoices/${values.resourceId}/summary/`,
        MESSAGE: `Partial payment has been received for invoice ${values.resourceName} issued to ${values.actionEntity}.`,
    }),
    ENTITY_VERIFIED: () => ({
        MESSAGE: '',
        TITLE: '',
        URL: '',
    }),
    PAYMENT_PARTIAL_BUYER: () => ({
        MESSAGE: '',
        TITLE: '',
    }),
    PO_DS_UPDATE_BUYER: () => ({
        MESSAGE: '',
        TITLE: '',
    }),
    PO_REWORK: () => ({
        MESSAGE: '',
        TITLE: '',
    }),
    PREPAYMENT_RECEIVED_BUYER: () => ({
        MESSAGE: '',
        TITLE: '',
    }),
    SIGN_UP_ACCEPTED: () => ({
        MESSAGE: '',
        TITLE: '',
    }),
    EVENT_DEADLINE_APPROACHING_BUYER: (values: INotificationValues) => ({
        TITLE: `Event Update: Event Deadline`,
        URL: `/buyer/events/${values.resourceId}/summary`,
        MESSAGE: `${values.resourceName} created by ${values.actionUser} is approaching its Deadline.`,
    }),
    EVENT_DEADLINE_APPROACHING_SELLER: (values: INotificationValues) => ({
        TITLE: `Event Update: Event Deadline`,
        URL: `/seller/events/${values.resourseEntityId}/${values.resourceId}/rfqinvitation`,
        MESSAGE: `${values.resourceName} from ${values.actionEntity} is approaching its deadline.`,
    }),
    RFQ_REJECTED: (values: INotificationValues) => ({
        TITLE: `Event Update: Event Rejected`,
        URL: `/buyer/events/rfq/${values.resourceId}`,
        MESSAGE: ` RFQ ${values.resourceName} that you sent for approval has been rejected by ${values.actionUser}`,
    }),
    PO_TERMINATION_ACCEPTED_BUYER: (values: INotificationValues) => ({
        TITLE: `PO update: PO termination request accepted`,
        URL: `/buyer/purchase_orders/${values.resourceId}/review/`,
        MESSAGE: `Your termination request for PO ${values.resourceName} has been accepted by ${values.actionUser} from ${values.resourceEntityName}`,
    }),
    PO_DS_CONF_PENDING_BUYER: (values: INotificationValues) => ({
        TITLE: `PO update: Delivery schedule modified`,
        URL: `/buyer/purchase_orders/${values.resourceId}/summary/`,
        MESSAGE: `Delivery schedule for items in PO ${values.resourceName} has been modified by ${values.actionUser} from ${values.actionEntity}`,
    }),
    PO_DS_CONF_PENDING_SELLER: (values: INotificationValues) => ({
        TITLE: `PO update: Delivery schedule modified`,
        URL: `/seller/purchase_orders/${values.resourceId}/summary/`,
        MESSAGE: `Delivery schedule for items in ${values.resourceName} has been modified by ${values.actionUser} from ${values.actionEntity}`,
    }),
    PO_DS_CONF_REJECTED_BUYER: (values: INotificationValues) => ({
        TITLE: `PO update: Delivery schedule rejected`,
        URL: `/buyer/purchase_orders/${values.resourceId}/summary/`,
        MESSAGE: `Delivery schedule for items in PO ${values.resourceName} has been rejected by ${values.actionUser} from ${values.actionEntity}`,
    }),
    PO_DS_CONF_REJECTED_SELLER: (values: INotificationValues) => ({
        TITLE: `PO update: Delivery schedule rejected`,
        URL: `/seller/purchase_orders/${values.resourceId}/summary/`,
        MESSAGE: `Delivery schedule for items in PO ${values.resourceName} has been rejected by ${values.actionUser} from ${values.actionEntity}`,
    }),
    PREPAYMENT_INVOICE_AUTOGENERATED_BUYER: (values: INotificationValues) => ({
        TITLE: `Invoice Update: Prepayment invoice generated`,
        URL: `/buyer/invoices/${values.resourceId}/prepay/view`,
        MESSAGE: `Prepayment invoice has been autogenerated for purchase order ${values.resourceName} to ${values.resourceEntityName} `,
    }),
    PREPAYMENT_INVOICE_AUTOGENERATED_SELLER: (values: INotificationValues) => ({
        TITLE: `Invoice Update: Prepayment invoice generated`,
        URL: `/seller/invoices/${values.resourceId}/prepay/view`,
        MESSAGE: `Prepayment invoice has been autogenerated for purchase order ${values.resourceName} from ${values.actionEntity} `,
    }),
    INVOICE_ISSUED: (values: INotificationValues) => ({
        TITLE: `Invoice update: Invoice Issued`,
        URL: `/buyer/invoices/${values.resourceId}/summary/`,
        MESSAGE: `Invoice ${values.resourceName} has been issued to you by ${values.actionUser} from ${values.actionEntity}`,
    }),
    INVOICE_ITEM_TERMINATION_ACCEPTED: (values: INotificationValues) => ({
        TITLE: `Invoice update: Termination Request Accepted`,
        URL: `/buyer/invoices/${values.resourceId}/summary/`,
        MESSAGE: `Your termination request for an item in ${values.resourceName} has been accepted by ${values.actionUser} from ${values.actionEntity}`,
    }),
    INVOICE_PROVISIONAL_CREATE_BUYER: (values: INotificationValues) => ({
        TITLE: `Invoice Update: Confirmation required`,
        URL: `/buyer/invoices/${values.resourceId}/tier2`,
        MESSAGE: `Confirmation required for invoice autogenerated for purchase order ${values.resourceName} to ${values.resourceEntityName} `,
    }),
    INVOICE_PROVISIONAL_CREATE_SELLER: (values: INotificationValues) => ({
        TITLE: `Invoice Update: Confirmation required`,
        URL: `/seller/invoices/${values.resourceId}/tier2`,
        MESSAGE: `Confirmation required for invoice autogenerated for purchase order ${values.resourceName} from ${values.actionEntity} `,
    }),
    INVOICE_VERIFIED_BUYER: (values: INotificationValues) => ({
        TITLE: `Invoice update: Invoice Verified`,
        URL: `/buyer/invoices/${values.resourceId}/summary/`,
        MESSAGE: `Invoice ${values.resourceName} has been verified by ${values.actionUser} from ${values.actionEntity}`,
    }),
    PAYMENT_OVERDUE: (values: INotificationValues) => ({
        TITLE: `Payment Update: Payment Overdue`,
        URL: `/buyer/invoices/${values.resourceId}`,
        MESSAGE: `Reminder: Payment for Invoice ${values.resourceName} is now overdue . Prompt action is necessary to settle this invoice and avoid any disruptions.`,
    }),
    GR_CREATE_BUYER: (values: INotificationValues) => ({
        TITLE: `Invoice Update: GR Created`,
        URL: `buyer/goods-receipt/view/${values.resourceId}`,
        MESSAGE: `The Goods Receipt (GR) for your ${values.resourceName} is complete. Your item has been received and verified successfully.`,
    }),
    GR_CREATE_SELLER: (values: INotificationValues) => ({
        TITLE: `Invoice Update: GR Created`,
        URL: `/seller/invoices/${values.resourceId}/summary/`,
        MESSAGE: `The Goods Receipt (GR) for your ${values.resourceName} is complete. Your item has been received and verified successfully.`,
    }),
    QC_CREATE_BUYER: (values: INotificationValues) => ({
        TITLE: `Invoice Update: GR Completed`,
        URL: `buyer/quality-check/detail/${values.resourceId}/`,
        MESSAGE: `The Quality Check (QC) for your ${values.resourceName} is complete.`,
    }),
    QC_CREATE_SELLER: (values: INotificationValues) => ({
        TITLE: `Invoice Update: QC Completed`,
        URL: `/seller/invoices/${values.resourceId}/summary/`,
        MESSAGE: `The Quality Check (QC) for your ${values.resourceName} is complete.`,
    }),
    RFQ_PERMISSIONS_UPDATE: (values: INotificationValues) => ({
        TITLE: `Event update: Event shared`,

        URL: `/buyer/events/rfq/${values.resourceId}`,

        MESSAGE: `Event ${values.resourceName} has been shared with you by ${values.actionUser}`,
    }),
};

const getNotificationDetails = (
    notificationType: string,
    notificationValues: INotificationValues
): INotificationDetails => {
    if (
        NOTIFICATION_MAP[
            notificationType as keyof typeof mergedNotificationTypes
        ]
    )
        return NOTIFICATION_MAP[
            notificationType as keyof typeof mergedNotificationTypes
        ](notificationValues);
    return {
        TITLE: '',
        MESSAGE: '',
        URL: '',
    };
};

export default getNotificationDetails;
