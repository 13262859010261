import { Grid } from '@mui/material';
import FWDataGrid from '../../../Common/FWDataGrid';
import React, { useCallback, useState } from 'react';
import { IBuyerPODashboardInterface } from '../../../PurchaseOrders/Interfaces/shared.model';
import { IDirectPODashboardInfo } from '../../../PurchaseOrders/Misc/datagridColumns';
import { getBuyerPODashboardRows } from '../../../PurchaseOrders/Services/PO.service';
import { IStatusMap } from '../../../PurchaseOrders/Interfaces/PO.model';
import { toast } from 'react-toastify';
import { debounce, isEqual } from 'lodash';
import CustomDashboardGridHeader from '../../../Global/Components/CustomDashboardGridHeader';
import { columns } from '../../Constants/DashboardConstants';
import { useHistory } from 'react-router-dom';

export type sortFields =
    | 'custom_event_id'
    | 'custom_po_id'
    | 'buyer_entity_name'
    | 'seller_entity_name'
    | 'po_no_of_items'
    | 'po_total_amount'
    | 'po_status'
    | 'po_group_status'
    | 'custom_po_group_id'
    | 'po_group_name'
    | 'no_of_items'
    | 'creator_name'
    | 'created_datetime'
    | 'no_of_invoices_in_po'
    | 'Event'
    | 'isssued_date';

const sortColumnsNames = {
    custom_event_id: 'event',
    custom_po_id: 'po_id',
    buyer_entity_name: 'buyer_entity',
    seller_entity_name: 'seller_entity',
    isssued_date: 'po_issued_date',
    po_no_of_items: 'po_item_count',
    po_total_amount: 'total_amount',
    po_status: 'po_status',
    po_group_status: 'event_status',
    custom_po_group_id: 'event_id',
    po_group_name: 'name',
    no_of_items: 'po_item_count',
    creator_name: 'creator',
    created_datetime: 'created_at',
    no_of_invoices_in_po: 'invoice_count',
    Event: 'event_id',
    modified_datetime: 'modified_datetime',
};

export enum PODashboardTabs {
    APPROVAL_PENDING = 'pending',
    APPROVED = 'ongoing',
    COMPLETED = 'finished',
}

const AnalyticsPoDashboard = () => {
    const history = useHistory();
    const [rowData, setRowData] = React.useState<
        IBuyerPODashboardInterface[] | IDirectPODashboardInfo[] | null
    >(null);

    const [sortFields, setSortFields] = React.useState<
        {
            field: sortFields;
            ascending: boolean;
        }[]
    >([]);
    const [showRefreshIcon, setShowRefreshIcon] = React.useState(false);
    const [totalPages, setTotalPages] = React.useState<number>(0);
    const [searchText, setSearchText] = React.useState('');
    const [currentTab, setCurrentTab] = useState(
        PODashboardTabs.APPROVAL_PENDING
    );
    const [currentPage, setCurrentPage] = React.useState(1);
    const handleTabChange = (newValue: PODashboardTabs) => {
        setCurrentTab(newValue);
    };
    const hitFetchAPI = useCallback(
        async (currentPage, currentTab, searchText, sortFields) => {
            //Pass necessary payloads for the API call.
            // All of them are states
            const sortFieldsArray = sortFields.map(
                (sortField: { field: sortFields; ascending: boolean }) => {
                    return {
                        field: sortColumnsNames[sortField.field],
                        ascending: sortField.ascending,
                    };
                }
            );
            let resp = await getBuyerPODashboardRows({
                tab: currentTab as keyof IStatusMap,
                searchText: searchText,
                itemsPerPage: 10,
                pageNumber: currentPage,
                sortFields: sortFieldsArray as any,
                // filters: null,
            });
            return resp;
        },
        []
    );
    const fetchData = React.useCallback(
        async (currentPage, currentTab, searchText, sortFields) => {
            try {
                //Pass necessary payloads for the API call.
                // All of them are states
                setRowData(null);
                let resp = await hitFetchAPI(
                    currentPage,
                    currentTab,
                    searchText,
                    sortFields
                );
                //take to page 1 incase of extra
                setCurrentPage((prev) => {
                    if (prev > resp.pageDetails.total_pages) {
                        return 1;
                    } else {
                        return prev;
                    }
                });
                setRowData(resp.purchase_orders);
                setTotalPages(resp.pageDetails.total_pages);
            } catch (e) {
                toast.error('Some error');
            }
        },
        [hitFetchAPI]
    );
    const debouncedSearch = React.useMemo(
        () =>
            debounce((text: string) => {
                setSearchText(text);
            }, 1000),
        []
    );
    React.useEffect(() => {
        fetchData(currentPage, currentTab, searchText, sortFields);
        //eslint-disable-next-line
    }, [currentTab]);
    React.useEffect(() => {
        const interval = setInterval(async () => {
            try {
                let resp = await hitFetchAPI(
                    currentPage,
                    currentTab,
                    searchText,
                    sortFields
                );
                const isListEqual = isEqual(
                    resp.purchase_orders.map((data, i) => ({ ...data, id: i })),
                    rowData
                );
                setShowRefreshIcon(!isListEqual);
            } catch (err) {
                // toast.error('Some error');
            }
        }, 1000 * 120); // fetch every 120 seconds and compare with previous data
        return () => {
            clearInterval(interval);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rowData]);
    const onClickRowHandler = (clickedRowID: number | null) => {
        if (rowData !== null && clickedRowID !== null) {
            history.push(
                `/buyer/purchase_orders/${
                    (rowData as IBuyerPODashboardInterface[])[clickedRowID]
                        .po_id
                }/review`
            );
        }
    };

    return (
        <>
            <Grid container rowSpacing={2} alignItems="center">
                {/* <Tabs value={currentTab}>
                    <Tab
                        label="Approval Pending"
                        value={PODashboardTabs.APPROVAL_PENDING}
                        sx={{ padding: '20px 50px' }}
                        onClick={() =>
                            handleTabChange(PODashboardTabs.APPROVAL_PENDING)
                        }
                    />
                    <Tab
                        label="Approved"
                        value={PODashboardTabs.APPROVED}
                        sx={{ padding: '20px 50px' }}
                        onClick={() =>
                            handleTabChange(PODashboardTabs.APPROVED)
                        }
                    />
                    <Tab
                        label="Completed"
                        value={PODashboardTabs.COMPLETED}
                        sx={{ padding: '20px 50px' }}
                        onClick={() =>
                            handleTabChange(PODashboardTabs.COMPLETED)
                        }
                    />
                </Tabs> */}
                <Grid item xs={12} sx={{ mb: '20px' }}>
                    <FWDataGrid
                        disableFESearch
                        onTextChange={debouncedSearch}
                        defaultColDef={{
                            resizable: true,
                            headerComponent: (props: any) => (
                                <CustomDashboardGridHeader
                                    {...props}
                                    sortFields={sortFields}
                                    setSortFields={setSortFields}
                                />
                            ),
                        }}
                        columns={columns}
                        rows={rowData}
                        onRowClicked={(e) => {
                            onClickRowHandler(e.data.id);
                        }}
                        fixedHeight={605}
                        showSearchBar
                        suppressPaginationPanel
                        useCustomPagination={totalPages}
                        customPaginationOnChange={(e, pageNumber) => {
                            setCurrentPage(pageNumber);
                        }}
                        customPaginationPageNumber={currentPage}
                        showRefreshIcon={showRefreshIcon}
                        onRefreshIconClick={() => {
                            setShowRefreshIcon(false);
                            fetchData(1, currentTab, '', []);
                        }}
                        showTabs={true}
                        currentTab={currentTab}
                        handleTabChange={handleTabChange}
                    />
                </Grid>
            </Grid>
        </>
    );
};
export default AnalyticsPoDashboard;
