import { cloneDeep } from 'lodash';
import {
    CustomTemplateItemTypes,
    ICustomTemplateList,
} from '../../Global/Interfaces/CustomTemplate.interface';
import {
    CustomFieldType,
    IRfqTemplate,
} from '../../Global/Interfaces/TemplateInterface';
import {
    IEventItemCustomField,
    IEventItemCustomFieldSections,
    IEventItemCustomFieldsBackend,
    custom_field_backend_section_names,
} from '../Interfaces/Buyer/RFQ.model';
import { IBomTemplate } from '../../BOM/Admin/inerfaces/IBomTemplateInterface';

export const processAdminCustomFieldsResp = (
    adminTemplatesResp: ICustomTemplateList[]
): IEventItemCustomFieldSections => {
    let processedData: IEventItemCustomFieldSections = {
        existingSections: {
            essential_terms: [],
            payment_and_delivery_terms: [],
            additional_details: [],
        },
        newSections: {},
    };

    for (let template of adminTemplatesResp) {
        let fieldList: IEventItemCustomField[] = [];
        for (let field of template.items) {
            let processedField: IEventItemCustomField = {
                name: field.template_item_name,
                description: '',
                type: field.template_item_type,
                value: '',
                is_required: field.is_required,
                is_visible: field.additional_information?.is_visible ?? true,
                is_locked: true,
                is_negotiable:
                    field.additional_information?.is_negotiable ?? false,
            };
            fieldList.push(processedField);
        }
        switch (template.template_name) {
            case custom_field_backend_section_names.payment_and_delivery_terms_template_name:
                processedData.existingSections.payment_and_delivery_terms =
                    fieldList;
                break;
            case custom_field_backend_section_names.additional_information_template_name:
                processedData.existingSections.additional_details = fieldList;
                break;
            default:
                // processedData.newSections[template.template_name] = fieldList;
                break;
        }
    }
    return processedData;
};

export const convertTemplateTypeToCustomFieldItemTemplate = (
    type: CustomFieldType,
    choiceType?: 'DROPDOWN' | 'MULTI_SELECT',
    allowDatetime?: boolean
) => {
    switch (type) {
        case CustomFieldType.BOOLEAN:
            return CustomTemplateItemTypes.BOOLEAN;
        case CustomFieldType.CHOICE:
            if (choiceType === 'MULTI_SELECT') {
                return CustomTemplateItemTypes.MULTI_CHOICE;
            }
            return CustomTemplateItemTypes.CHOICE;
        case CustomFieldType.DATE:
            return CustomTemplateItemTypes.DATE;
        case CustomFieldType.DATETIME:
            return allowDatetime
                ? CustomTemplateItemTypes.DATETIME
                : CustomTemplateItemTypes.DATE;
        case CustomFieldType.DECIMAL:
            return CustomTemplateItemTypes.FLOAT;
        case CustomFieldType.FLOAT:
            return CustomTemplateItemTypes.FLOAT;
        case CustomFieldType.INTEGER:
            return CustomTemplateItemTypes.INTEGER;
        case CustomFieldType.LONG_TEXT:
            return CustomTemplateItemTypes.LONGTEXT;
        case CustomFieldType.SHORT_TEXT:
            return CustomTemplateItemTypes.SHORTTEXT;

        default:
            return CustomTemplateItemTypes.SHORTTEXT;
    }
};

export const processTemplateCustomFieldsResp = (
    templatesResp: IRfqTemplate
): IEventItemCustomFieldSections => {
    let processedData: IEventItemCustomFieldSections = {
        existingSections: {
            essential_terms: [],
            payment_and_delivery_terms: [],
            additional_details: [],
        },
        newSections: {},
    };

    templatesResp.sections.ESSENTIAL_TERMS.fieldList.forEach((field) => {
        if (field.fieldType === 'STANDARD') {
            // processedData.existingSections.essential_terms.push({
            //     is_locked: true,
            //     is_negotiable:
            //         templatesResp.fieldSettings.standardFields[field.field]
            //             .sellerSettings.negotiable,
            //     is_required:
            //         templatesResp.fieldSettings.standardFields[field.field]
            //             .buyerSettings.required,
            //     is_visible:
            //         templatesResp.fieldSettings.standardFields[field.field]
            //             .buyerSettings.showField,
            //     name: field.field,
            //     type: CustomTemplateItemTypes.LONGTEXT,
            //     value: '',
            // });
        } else {
            let choiceType: 'DROPDOWN' | 'MULTI_SELECT' | undefined;
            const templateConstraint =
                templatesResp.fieldSettings.customFields[field.field]
                    .constraints;
            if (templateConstraint.fieldType === 'CHOICE') {
                choiceType = templateConstraint.choiceType;
            }

            processedData.existingSections.essential_terms.push({
                is_locked: true,
                is_negotiable:
                    templatesResp.fieldSettings.customFields[field.field]
                        .sellerSettings.negotiable,
                is_required:
                    templatesResp.fieldSettings.customFields[field.field]
                        .buyerSettings.required,
                is_visible:
                    templatesResp.fieldSettings.customFields[field.field]
                        .sellerSettings.showField,
                name: field.field,
                // type: templatesResp.fieldSettings.customFields[field.field]
                //     .type as CustomTemplateItemTypes.SHORTTEXT,
                description:
                    templatesResp.fieldSettings.customFields[field.field]
                        .description ?? '',
                type: convertTemplateTypeToCustomFieldItemTemplate(
                    templatesResp.fieldSettings.customFields[field.field].type,
                    choiceType
                ),
                value: '',
            });
        }
    });

    templatesResp.sections.PAYMENT_AND_DELIVERY_TERMS.fieldList.forEach(
        (field) => {
            if (field.fieldType === 'STANDARD') {
                // processedData.existingSections.payment_and_delivery_terms.push({
                //     is_locked: true,
                //     is_negotiable:
                //         templatesResp.fieldSettings.standardFields[field.field]
                //             .sellerSettings.negotiable,
                //     is_required:
                //         templatesResp.fieldSettings.standardFields[field.field]
                //             .buyerSettings.required,
                //     is_visible:
                //         templatesResp.fieldSettings.standardFields[field.field]
                //             .buyerSettings.showField,
                //     name: field.field,
                //     type: CustomTemplateItemTypes.LONGTEXT,
                //     value: '',
                // });
            } else {
                let choiceType: 'DROPDOWN' | 'MULTI_SELECT' | undefined;
                const templateConstraint =
                    templatesResp.fieldSettings.customFields[field.field]
                        .constraints;
                if (templateConstraint.fieldType === 'CHOICE') {
                    choiceType = templateConstraint.choiceType;
                }
                processedData.existingSections.payment_and_delivery_terms.push({
                    is_locked: true,
                    is_negotiable:
                        templatesResp.fieldSettings.customFields[field.field]
                            .sellerSettings.negotiable,
                    is_required:
                        templatesResp.fieldSettings.customFields[field.field]
                            .buyerSettings.required,
                    is_visible:
                        templatesResp.fieldSettings.customFields[field.field]
                            .sellerSettings.showField,
                    name: field.field,
                    description:
                        templatesResp.fieldSettings.customFields[field.field]
                            .description ?? '',
                    // type: templatesResp.fieldSettings.customFields[field.field]
                    //     .type as CustomTemplateItemTypes.SHORTTEXT,
                    type: convertTemplateTypeToCustomFieldItemTemplate(
                        templatesResp.fieldSettings.customFields[field.field]
                            .type,
                        choiceType
                    ),
                    value: '',
                });
            }
        }
    );

    templatesResp.sections.ADDITIONAL_DETAILS.fieldList.forEach((field) => {
        if (field.fieldType === 'STANDARD') {
            // processedData.existingSections.additional_details.push({
            //     is_locked: true,
            //     is_negotiable:
            //         templatesResp.fieldSettings.standardFields[field.field]
            //             .sellerSettings.negotiable,
            //     is_required:
            //         templatesResp.fieldSettings.standardFields[field.field]
            //             .buyerSettings.required,
            //     is_visible:
            //         templatesResp.fieldSettings.standardFields[field.field]
            //             .buyerSettings.showField,
            //     name: field.field,
            //     type: CustomTemplateItemTypes.LONGTEXT,
            //     value: '',
            // });
        } else {
            let choiceType: 'DROPDOWN' | 'MULTI_SELECT' | undefined;
            const templateConstraint =
                templatesResp.fieldSettings.customFields[field.field]
                    .constraints;
            if (templateConstraint.fieldType === 'CHOICE') {
                choiceType = templateConstraint.choiceType;
            }
            processedData.existingSections.additional_details.push({
                is_locked: true,
                is_negotiable:
                    templatesResp.fieldSettings.customFields[field.field]
                        .sellerSettings.negotiable,
                is_required:
                    templatesResp.fieldSettings.customFields[field.field]
                        .buyerSettings.required,
                is_visible:
                    templatesResp.fieldSettings.customFields[field.field]
                        .sellerSettings.showField,
                name: field.field,
                description:
                    templatesResp.fieldSettings.customFields[field.field]
                        .description ?? '',
                // type: templatesResp.fieldSettings.customFields[field.field]
                //     .type as CustomTemplateItemTypes.SHORTTEXT,
                type: convertTemplateTypeToCustomFieldItemTemplate(
                    templatesResp.fieldSettings.customFields[field.field].type,
                    choiceType
                ),
                value: '',
            });
        }
    });

    //

    return processedData;
};

export const translateBackendCustomFieldSectionsToFrontEnd = (
    backendCustomFields: IEventItemCustomFieldsBackend,
    backendNegotiableCustomFields: IEventItemCustomFieldsBackend
): IEventItemCustomFieldSections => {
    let processedData: IEventItemCustomFieldSections = {
        existingSections: {
            essential_terms: [],
            payment_and_delivery_terms: [],
            additional_details: [],
        },
        newSections: {},
    };
    if (backendCustomFields.section_list) {
        for (let section of backendCustomFields.section_list) {
            if (section.name === 'item_terms') {
                section.name =
                    custom_field_backend_section_names.essential_terms_template_name;
            }
            if (
                section.name ===
                    custom_field_backend_section_names.essential_terms_template_name ||
                section.name ===
                    custom_field_backend_section_names.payment_and_delivery_terms_template_name ||
                section.name ===
                    custom_field_backend_section_names.additional_information_template_name
            ) {
                processedData.existingSections[
                    section.name as
                        | 'payment_and_delivery_terms'
                        | 'additional_details'
                        | 'essential_terms'
                ] = section.fields.map((field) => ({
                    ...field,
                    is_negotiable: false,
                }));
            } else {
                processedData.newSections[
                    section.name as
                        | 'payment_and_delivery_terms'
                        | 'additional_details'
                        | 'essential_terms'
                ] = section.fields.map((field) => ({
                    ...field,
                    is_negotiable: false,
                }));
            }
        }
    }
    if (backendNegotiableCustomFields.section_list) {
        for (let section of backendNegotiableCustomFields.section_list) {
            if (section.name === 'item_terms') {
                section.name =
                    custom_field_backend_section_names.essential_terms_template_name;
            }
            if (
                section.name ===
                    custom_field_backend_section_names.essential_terms_template_name ||
                section.name ===
                    custom_field_backend_section_names.payment_and_delivery_terms_template_name ||
                section.name ===
                    custom_field_backend_section_names.additional_information_template_name
            ) {
                if (
                    !processedData.existingSections[
                        section.name as
                            | 'payment_and_delivery_terms'
                            | 'additional_details'
                            | 'essential_terms'
                    ]
                ) {
                    processedData.existingSections[
                        section.name as
                            | 'payment_and_delivery_terms'
                            | 'additional_details'
                            | 'essential_terms'
                    ] = [];
                }
                processedData.existingSections[
                    section.name as
                        | 'payment_and_delivery_terms'
                        | 'additional_details'
                        | 'essential_terms'
                ] = processedData.existingSections[
                    section.name as
                        | 'payment_and_delivery_terms'
                        | 'additional_details'
                        | 'essential_terms'
                ].concat([
                    ...section.fields.map((field) => ({
                        ...field,
                        is_negotiable: true,
                    })),
                ]);
            } else {
                if (!processedData.newSections[section.name]) {
                    processedData.newSections[section.name] = [];
                }
                processedData.newSections[section.name] =
                    processedData.newSections[section.name].concat(
                        section.fields.map((field) => ({
                            ...field,
                            is_negotiable: true,
                        }))
                    );
            }
        }
    }
    return processedData;
};

export const translateFrontEndCustomFieldSectionsToBackend = (
    frontendCustomFields: IEventItemCustomFieldSections,
    negotiableValue?: 'NEGOTIABLE' | 'NON_NEGOTIABLE'
): IEventItemCustomFieldsBackend => {
    let processedData: IEventItemCustomFieldsBackend = {
        section_list: [],
    };

    // for (let sectionName in frontendCustomFields.newSections) {
    //     let section = {
    //         name: sectionName,
    //         fields: cloneDeep(frontendCustomFields.newSections[sectionName]),
    //     };
    //     processedData.section_list.push(section);
    // }
    let section_payment_and_delivery_terms = {
        name: custom_field_backend_section_names.payment_and_delivery_terms_template_name,
        fields: frontendCustomFields.existingSections
            .payment_and_delivery_terms,
    };
    processedData.section_list.push(section_payment_and_delivery_terms);

    let section_additional_details = {
        name: custom_field_backend_section_names.additional_information_template_name,
        fields: frontendCustomFields.existingSections.additional_details,
    };
    processedData.section_list.push(section_additional_details);
    processedData.section_list = processedData.section_list.filter(
        (section) => section.fields.length > 0
    );

    let section_essential_details = {
        // name: custom_field_backend_section_names.essential_terms_template_name,
        name: 'item_terms',
        fields: frontendCustomFields.existingSections.essential_terms,
    };

    processedData.section_list.push(section_essential_details);
    processedData.section_list = processedData.section_list.filter(
        (section) => section.fields.length > 0
    );

    if (negotiableValue === 'NEGOTIABLE') {
    }
    if (negotiableValue) {
        processedData.section_list.forEach(
            (section) =>
                (section.fields = section.fields.filter(
                    (field) =>
                        field.is_negotiable ===
                        (negotiableValue === 'NEGOTIABLE')
                ))
        );
    }

    processedData.section_list = processedData.section_list.filter(
        (section) => section.fields.length > 0
    );

    return processedData;
};

export const addNonExistingFieldInCustomFieldBasedOntemplate = (
    existingCustomFields: IEventItemCustomFieldsBackend,
    templateDetails: IRfqTemplate,
    negotiableValueFields: boolean
) => {
    const copyOfexistingCustomFields = cloneDeep(existingCustomFields);

    // item_terms
    const listOfCustomFieldsInEssentialTerms =
        templateDetails.sections.ESSENTIAL_TERMS.fieldList.filter((field) => {
            if (field.fieldType === 'CUSTOM') {
                return (
                    negotiableValueFields ===
                    templateDetails.fieldSettings.customFields[field.field]
                        .sellerSettings.negotiable
                );
            }
            return false;
        });

    const sectionIndexOfEssentialTermsWrongOne =
        copyOfexistingCustomFields.section_list.findIndex(
            (section) => section.name === 'Item Terms'
        );

    if (sectionIndexOfEssentialTermsWrongOne > -1)
        copyOfexistingCustomFields.section_list.splice(
            sectionIndexOfEssentialTermsWrongOne,
            1
        );

    let sectionIndexOfEssentialTerms =
        copyOfexistingCustomFields.section_list.findIndex(
            (section) => section.name === 'item_terms'
        );

    if (sectionIndexOfEssentialTerms === -1) {
        copyOfexistingCustomFields.section_list.push({
            fields: [],
            name: 'item_terms',
        });
        sectionIndexOfEssentialTerms =
            copyOfexistingCustomFields.section_list.findIndex(
                (section) => section.name === 'item_terms'
            );
    } else {
        copyOfexistingCustomFields.section_list[
            sectionIndexOfEssentialTerms
        ].name = 'item_terms';
    }

    for (let fieldInTemplate of listOfCustomFieldsInEssentialTerms) {
        const foundFieldIndex = copyOfexistingCustomFields.section_list[
            sectionIndexOfEssentialTerms
        ].fields.findIndex(
            (field) =>
                field.name.toLowerCase() === fieldInTemplate.field.toLowerCase()
        );
        const fieldSettingFromTemplate =
            templateDetails.fieldSettings.customFields[fieldInTemplate.field];
        if (fieldSettingFromTemplate)
            if (
                foundFieldIndex === -1 &&
                templateDetails.fieldSettings.customFields[
                    fieldInTemplate.field
                ]
            ) {
                copyOfexistingCustomFields.section_list[
                    sectionIndexOfEssentialTerms
                ].fields.push({
                    description: fieldSettingFromTemplate.description,
                    type: convertTemplateTypeToCustomFieldItemTemplate(
                        fieldSettingFromTemplate.type
                    ),
                    is_locked: true,
                    is_negotiable:
                        fieldSettingFromTemplate.sellerSettings.negotiable,
                    is_required:
                        fieldSettingFromTemplate.buyerSettings.required,
                    is_visible:
                        fieldSettingFromTemplate.sellerSettings.showField,
                    name: fieldInTemplate.field,
                    value:
                        fieldSettingFromTemplate.type ===
                            CustomFieldType.LONG_TEXT ||
                        fieldSettingFromTemplate.type ===
                            CustomFieldType.SHORT_TEXT ||
                        fieldSettingFromTemplate.type ===
                            CustomFieldType.DECIMAL ||
                        fieldSettingFromTemplate.type ===
                            CustomFieldType.INTEGER
                            ? ''
                            : fieldSettingFromTemplate.type ===
                              CustomFieldType.MULTI_CHOICE
                            ? []
                            : null,
                });
            } else {
                copyOfexistingCustomFields.section_list[
                    sectionIndexOfEssentialTerms
                ].fields[foundFieldIndex].is_locked = true;
                copyOfexistingCustomFields.section_list[
                    sectionIndexOfEssentialTerms
                ].fields[foundFieldIndex].is_negotiable =
                    fieldSettingFromTemplate.sellerSettings.negotiable;
                copyOfexistingCustomFields.section_list[
                    sectionIndexOfEssentialTerms
                ].fields[foundFieldIndex].is_required =
                    fieldSettingFromTemplate.buyerSettings.required;
                copyOfexistingCustomFields.section_list[
                    sectionIndexOfEssentialTerms
                ].fields[foundFieldIndex].is_visible =
                    fieldSettingFromTemplate.sellerSettings.showField;
                copyOfexistingCustomFields.section_list[
                    sectionIndexOfEssentialTerms
                ].fields[foundFieldIndex].description =
                    fieldSettingFromTemplate.description;
            }
    }

    // payment_and_delivery_terms
    const listOfCustomFieldsInPaymentAndDeliveryTerms =
        templateDetails.sections.PAYMENT_AND_DELIVERY_TERMS.fieldList.filter(
            (field) => {
                if (field.fieldType === 'CUSTOM') {
                    return (
                        negotiableValueFields ===
                        templateDetails.fieldSettings.customFields[field.field]
                            .sellerSettings.negotiable
                    );
                }
                return false;
            }
        );
    let sectionIndexOfPaymentAndDeliveryTerms =
        copyOfexistingCustomFields.section_list.findIndex(
            (section) => section.name === 'payment_and_delivery_terms'
        );
    if (sectionIndexOfPaymentAndDeliveryTerms === -1) {
        copyOfexistingCustomFields.section_list.push({
            fields: [],
            name: 'payment_and_delivery_terms',
        });
        sectionIndexOfPaymentAndDeliveryTerms =
            copyOfexistingCustomFields.section_list.findIndex(
                (section) => section.name === 'payment_and_delivery_terms'
            );
    }

    for (let fieldInTemplate of listOfCustomFieldsInPaymentAndDeliveryTerms) {
        const foundFieldIndex = copyOfexistingCustomFields.section_list[
            sectionIndexOfPaymentAndDeliveryTerms
        ].fields.findIndex(
            (field) =>
                field.name.toLowerCase() === fieldInTemplate.field.toLowerCase()
        );
        const fieldSettingFromTemplate =
            templateDetails.fieldSettings.customFields[fieldInTemplate.field];
        if (fieldSettingFromTemplate)
            if (
                foundFieldIndex === -1 &&
                templateDetails.fieldSettings.customFields[
                    fieldInTemplate.field
                ]
            ) {
                copyOfexistingCustomFields.section_list[
                    sectionIndexOfPaymentAndDeliveryTerms
                ].fields.push({
                    description: fieldSettingFromTemplate.description,
                    type: convertTemplateTypeToCustomFieldItemTemplate(
                        fieldSettingFromTemplate.type
                    ),
                    is_locked: true,
                    is_negotiable:
                        fieldSettingFromTemplate.sellerSettings.negotiable,
                    is_required:
                        fieldSettingFromTemplate.buyerSettings.required,
                    is_visible:
                        fieldSettingFromTemplate.sellerSettings.showField,
                    name: fieldInTemplate.field,
                    value:
                        fieldSettingFromTemplate.type ===
                            CustomFieldType.LONG_TEXT ||
                        fieldSettingFromTemplate.type ===
                            CustomFieldType.SHORT_TEXT ||
                        fieldSettingFromTemplate.type ===
                            CustomFieldType.DECIMAL ||
                        fieldSettingFromTemplate.type ===
                            CustomFieldType.INTEGER
                            ? ''
                            : fieldSettingFromTemplate.type ===
                              CustomFieldType.MULTI_CHOICE
                            ? []
                            : null,
                });
            } else {
                copyOfexistingCustomFields.section_list[
                    sectionIndexOfPaymentAndDeliveryTerms
                ].fields[foundFieldIndex].is_locked = true;
                copyOfexistingCustomFields.section_list[
                    sectionIndexOfPaymentAndDeliveryTerms
                ].fields[foundFieldIndex].is_negotiable =
                    fieldSettingFromTemplate.sellerSettings.negotiable;
                copyOfexistingCustomFields.section_list[
                    sectionIndexOfPaymentAndDeliveryTerms
                ].fields[foundFieldIndex].is_required =
                    fieldSettingFromTemplate.buyerSettings.required;
                copyOfexistingCustomFields.section_list[
                    sectionIndexOfPaymentAndDeliveryTerms
                ].fields[foundFieldIndex].is_visible =
                    fieldSettingFromTemplate.sellerSettings.showField;
                copyOfexistingCustomFields.section_list[
                    sectionIndexOfPaymentAndDeliveryTerms
                ].fields[foundFieldIndex].description =
                    fieldSettingFromTemplate.description;
            }
    }

    // additional_details
    const listOfCustomFieldsInAdditionalDetails =
        templateDetails.sections.ADDITIONAL_DETAILS.fieldList.filter(
            (field) => {
                if (field.fieldType === 'CUSTOM') {
                    return (
                        negotiableValueFields ===
                        templateDetails.fieldSettings.customFields[field.field]
                            .sellerSettings.negotiable
                    );
                }
                return false;
            }
        );
    let sectionIndexOfAdditionalDetails =
        copyOfexistingCustomFields.section_list.findIndex(
            (section) => section.name === 'additional_details'
        );
    if (sectionIndexOfAdditionalDetails === -1) {
        copyOfexistingCustomFields.section_list.push({
            fields: [],
            name: 'additional_details',
        });
        sectionIndexOfAdditionalDetails =
            copyOfexistingCustomFields.section_list.findIndex(
                (section) => section.name === 'additional_details'
            );
    }
    for (let fieldInTemplate of listOfCustomFieldsInAdditionalDetails) {
        const foundFieldIndex = copyOfexistingCustomFields.section_list[
            sectionIndexOfAdditionalDetails
        ].fields.findIndex(
            (field) =>
                field.name.toLowerCase() === fieldInTemplate.field.toLowerCase()
        );
        const fieldSettingFromTemplate =
            templateDetails.fieldSettings.customFields[fieldInTemplate.field];
        if (fieldSettingFromTemplate)
            if (
                foundFieldIndex === -1 &&
                templateDetails.fieldSettings.customFields[
                    fieldInTemplate.field
                ]
            ) {
                copyOfexistingCustomFields.section_list[
                    sectionIndexOfAdditionalDetails
                ].fields.push({
                    description: fieldSettingFromTemplate.description,
                    type: convertTemplateTypeToCustomFieldItemTemplate(
                        fieldSettingFromTemplate.type
                    ),
                    is_locked: true,
                    is_negotiable:
                        fieldSettingFromTemplate.sellerSettings.negotiable,
                    is_required:
                        fieldSettingFromTemplate.buyerSettings.required,
                    is_visible:
                        fieldSettingFromTemplate.sellerSettings.showField,
                    name: fieldInTemplate.field,
                    value:
                        fieldSettingFromTemplate.type ===
                            CustomFieldType.LONG_TEXT ||
                        fieldSettingFromTemplate.type ===
                            CustomFieldType.SHORT_TEXT ||
                        fieldSettingFromTemplate.type ===
                            CustomFieldType.DECIMAL ||
                        fieldSettingFromTemplate.type ===
                            CustomFieldType.INTEGER
                            ? ''
                            : fieldSettingFromTemplate.type ===
                              CustomFieldType.MULTI_CHOICE
                            ? []
                            : null,
                });
            } else {
                copyOfexistingCustomFields.section_list[
                    sectionIndexOfAdditionalDetails
                ].fields[foundFieldIndex].is_locked = true;
                copyOfexistingCustomFields.section_list[
                    sectionIndexOfAdditionalDetails
                ].fields[foundFieldIndex].is_negotiable =
                    fieldSettingFromTemplate.sellerSettings.negotiable;
                copyOfexistingCustomFields.section_list[
                    sectionIndexOfAdditionalDetails
                ].fields[foundFieldIndex].is_required =
                    fieldSettingFromTemplate.buyerSettings.required;
                copyOfexistingCustomFields.section_list[
                    sectionIndexOfAdditionalDetails
                ].fields[foundFieldIndex].is_visible =
                    fieldSettingFromTemplate.sellerSettings.showField;
                copyOfexistingCustomFields.section_list[
                    sectionIndexOfAdditionalDetails
                ].fields[foundFieldIndex].description =
                    fieldSettingFromTemplate.description;
            }
    }

    return copyOfexistingCustomFields;
};

export const addNonExistingFieldInCustomFieldBasedOntemplateOfBom = (
    existingCustomFields: IEventItemCustomFieldsBackend,
    templateDetails: IBomTemplate,
    negotiableValueFields?: boolean
) => {
    const copyOfexistingCustomFields = cloneDeep(existingCustomFields);

    // item_terms
    const listOfCustomFieldsInEssentialTerms =
        templateDetails.section.ITEM_TERMS.fieldList.filter((field) => {
            if (field.fieldType === 'CUSTOM') {
                return (
                    negotiableValueFields === undefined ||
                    negotiableValueFields ===
                        templateDetails.fieldSettings.customFields[field.field]
                            .sellerSettings.negotiable
                );
            }
            return false;
        });
    let sectionIndexOfEssentialTerms =
        copyOfexistingCustomFields.section_list.findIndex(
            (section) =>
                section.name === 'item_terms' || section.name === 'Item Terms'
        );

    if (sectionIndexOfEssentialTerms === -1) {
        copyOfexistingCustomFields.section_list.push({
            fields: [],
            name: 'item_terms',
        });
        sectionIndexOfEssentialTerms =
            copyOfexistingCustomFields.section_list.findIndex(
                (section) => section.name === 'item_terms'
            );
    }
    copyOfexistingCustomFields.section_list[sectionIndexOfEssentialTerms].name =
        'item_terms';

    for (let fieldInTemplate of listOfCustomFieldsInEssentialTerms) {
        const foundFieldIndex = copyOfexistingCustomFields.section_list[
            sectionIndexOfEssentialTerms
        ].fields.findIndex(
            (field) =>
                field.name.toLowerCase() === fieldInTemplate.field.toLowerCase()
        );

        const fieldSettingFromTemplate =
            templateDetails.fieldSettings.customFields[fieldInTemplate.field];
        if (fieldSettingFromTemplate)
            if (
                foundFieldIndex === -1 &&
                templateDetails.fieldSettings.customFields[
                    fieldInTemplate.field
                ]
            ) {
                copyOfexistingCustomFields.section_list[
                    sectionIndexOfEssentialTerms
                ].fields.push({
                    description: fieldSettingFromTemplate.description,
                    type: convertTemplateTypeToCustomFieldItemTemplate(
                        fieldSettingFromTemplate.type
                    ),
                    is_locked: true,
                    is_negotiable:
                        fieldSettingFromTemplate.sellerSettings.negotiable,
                    is_required:
                        fieldSettingFromTemplate.buyerSettings.required,
                    is_visible:
                        fieldSettingFromTemplate.sellerSettings.showField,
                    name: fieldInTemplate.field,
                    value:
                        fieldSettingFromTemplate.type ===
                            CustomFieldType.LONG_TEXT ||
                        fieldSettingFromTemplate.type ===
                            CustomFieldType.SHORT_TEXT ||
                        fieldSettingFromTemplate.type ===
                            CustomFieldType.DECIMAL ||
                        fieldSettingFromTemplate.type ===
                            CustomFieldType.INTEGER
                            ? ''
                            : fieldSettingFromTemplate.type ===
                              CustomFieldType.MULTI_CHOICE
                            ? []
                            : null,
                });
            } else {
                copyOfexistingCustomFields.section_list[
                    sectionIndexOfEssentialTerms
                ].fields[foundFieldIndex].is_locked = true;
                copyOfexistingCustomFields.section_list[
                    sectionIndexOfEssentialTerms
                ].fields[foundFieldIndex].is_negotiable =
                    fieldSettingFromTemplate.sellerSettings.negotiable;
                copyOfexistingCustomFields.section_list[
                    sectionIndexOfEssentialTerms
                ].fields[foundFieldIndex].is_required =
                    fieldSettingFromTemplate.buyerSettings.required;
                copyOfexistingCustomFields.section_list[
                    sectionIndexOfEssentialTerms
                ].fields[foundFieldIndex].is_visible =
                    fieldSettingFromTemplate.sellerSettings.showField;
                copyOfexistingCustomFields.section_list[
                    sectionIndexOfEssentialTerms
                ].fields[foundFieldIndex].description =
                    fieldSettingFromTemplate.description;
            }
    }

    return copyOfexistingCustomFields;
};
