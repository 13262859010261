import {
    Box,
    IconButton,
    PaperProps,
    SxProps,
    Theme,
    Typography,
} from '@mui/material';
import * as React from 'react';
import { toTitleCase } from '../Global/Helpers/helpers';
import { CustomDialog } from './FWPopup';

interface IFWPopupWithChildrenProps {
    open: boolean;
    handleClose: (e?: any) => void;
    title: string | React.ReactNode;
    hideTitle?: boolean;
    children: React.ReactNode;
    // FIXME: Implement enums for size of popup, refer at FWPopupWithEdit
    size?: 'medium' | 'small' | 'large' | 'xsmall' | 'fullscreen' | 'custom';
    popupWidth?: string | number;
    showClose?: boolean;
    disableClose?: boolean;
    disablePortal?: boolean;
    PaperProps?: PaperProps;
    preTitleElement?: any;
    titleAddOnElement?: any;
    closeOnBackdropClick?: boolean;
    hideHeader?: boolean;
    sx?: SxProps<Theme> | undefined;
    style?: React.CSSProperties | undefined;
    closeIconColor?: 'primary' | 'secondary' | string | undefined;
    customHeaderElement?: React.ReactNode;
    stickyHeader?: boolean;
    applyTitleStyling?: boolean;
}

const setPopupWidth = (
    size: 'medium' | 'small' | 'large' | 'xsmall' | 'custom',
    popupWidth: string | number,
    sx: any
): SxProps<Theme> => {
    if (size === 'small') return {};
    else if (size === 'medium')
        return {
            '& .MuiPaper-root': {
                minWidth: '700px! important',
                ...(sx || {}),
            },
        };
    else if (size === 'xsmall')
        return {
            '& .MuiPaper-root': {
                maxWidth: '300px! important',
                ...(sx || {}),
            },
        };
    else if (size === 'custom')
        return {
            '& .MuiPaper-root': {
                minWidth: `${popupWidth} !important`,
                ...(sx || {}),
            },
        };
    else
        return {
            '& .MuiPaper-root': {
                minWidth: '950px!important',
                ...(sx || {}),
            },
        };
};

export const FWPopupWithChildren = ({
    open,
    handleClose,
    title,
    hideTitle = false,
    children,
    size = 'small',
    popupWidth = 0,
    showClose = true,
    disableClose = false,
    disablePortal,
    PaperProps,
    titleAddOnElement,
    preTitleElement,
    customHeaderElement,
    closeOnBackdropClick,
    hideHeader = false,
    sx,
    style,
    closeIconColor,
    stickyHeader,
    applyTitleStyling = true,
}: IFWPopupWithChildrenProps) => {
    /*
        To use this component pass your own dialogContent and dialogActions
    */

    return (
        <CustomDialog
            open={open}
            // onClose={handleClose}
            sx={
                size === 'fullscreen'
                    ? undefined
                    : setPopupWidth(size, popupWidth, sx)
            }
            disablePortal={disablePortal}
            PaperProps={PaperProps}
            BackdropProps={{
                onClick: (e) => {
                    e.stopPropagation();
                },
            }}
            onBackdropClick={closeOnBackdropClick ? handleClose : undefined}
            fullScreen={size === 'fullscreen' ? true : false}
            // disableScrollLock
        >
            <Box
                sx={{
                    ...(stickyHeader
                        ? {
                              position: 'sticky',
                              top: '0px',
                              backgroundColor: 'white',
                              zIndex: 100,
                          }
                        : {}),
                }}
            >
                {customHeaderElement ? (
                    <>{customHeaderElement}</>
                ) : (
                    !hideHeader && (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                padding: '24px 24px 02px 24px',
                                alignItems: 'center',
                                ...style,
                            }}
                        >
                            {!hideTitle ? (
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    {preTitleElement && (
                                        <div className="mr--10">
                                            {preTitleElement}
                                        </div>
                                    )}
                                    {typeof title === 'string' ? (
                                        <Typography variant="h4">
                                            {applyTitleStyling
                                                ? toTitleCase(title)
                                                : title}
                                        </Typography>
                                    ) : (
                                        title
                                    )}

                                    {titleAddOnElement && (
                                        <div className="ml--10">
                                            {titleAddOnElement}
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <>
                                    {titleAddOnElement ? (
                                        titleAddOnElement
                                    ) : (
                                        <></>
                                    )}
                                </>
                            )}
                            {showClose && (
                                <IconButton
                                    size="small"
                                    sx={{ color: closeIconColor }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleClose(e);
                                    }}
                                    disabled={disableClose}
                                >
                                    <i
                                        className="bi bi-x"
                                        style={{ fontSize: '2rem' }}
                                    />
                                </IconButton>
                            )}
                        </div>
                    )
                )}
            </Box>
            {children}
        </CustomDialog>
    );
};
