import { useEffect, useState } from 'react';
import { downloadFile } from '../Services/FileStorageService';

export const useFetchUrlUsingAttachmentId = (attachmentId: string) => {
    const [url, setUrl] = useState<string | null>(null);

    const fetchImageURL = async (attachmentId: string) => {
        try {
            const resp = await downloadFile(attachmentId);
            setUrl(resp);
        } catch (err) {}
    };

    useEffect(() => {
        attachmentId && fetchImageURL(attachmentId);
    }, [attachmentId]);

    return { url };
};
