import { Box } from '@mui/system';
import Fuse from 'fuse.js';
import React from 'react';
import {
    FWAccordion,
    FWAccordionDetails,
    FWAccordionSummary,
} from '../../Common/FWAccordian';
import { FWTypography } from '../../Common/FWTypograhy';
import {
    HelpInternalPageName,
    HelpPageName,
} from '../../Redux/Slices/HelpInformationSlice';
import {
    DataType,
    IHelpInformationItem,
} from '../Interfaces/HelpInformationInterface';
import HelpTabPanel from './HelpTabPanel';
import BlocksDataContainer from '../Components/HelpDisplayDataComponents/BlocksDataComponent';
import ListDataContainer from '../Components/HelpDisplayDataComponents/ListDataComponent';
import { IUserViewPermissions } from '../Interfaces/HelpPermissionsInterface';

interface HelpTabPanelInterface {
    tabValue: string;
    helpData: IHelpInformationItem[];
    searchText: string;
    toggleAccordion: Set<string>;
    setToggleAccordion: React.Dispatch<React.SetStateAction<Set<string>>>;
    pageName: HelpPageName | null;
    internalPageName: HelpInternalPageName | null;
    userViewPermissions: IUserViewPermissions;
    defaultOpen: boolean;
    setDefaultOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const FAQHelpTabPanel = ({
    tabValue,
    helpData,
    searchText,
    toggleAccordion,
    setToggleAccordion,
    pageName,
    internalPageName,
    userViewPermissions,
    defaultOpen,
    setDefaultOpen,
}: HelpTabPanelInterface) => {
    let sectionTitleSet = new Set();
    return (
        <HelpTabPanel value={tabValue} index={'FAQS'}>
            {helpData
                .filter(
                    (val) =>
                        userViewPermissions[
                            val.sectionIdentifier as keyof IUserViewPermissions
                        ]
                )
                .filter((val) => {
                    if (
                        internalPageName !== undefined &&
                        val.sectionPageIdentifier !== undefined
                    ) {
                        if (pageName === HelpPageName.PURCHASE_ORDER) {
                            return pageName === val.sectionIdentifier;
                        }
                        return internalPageName === val.sectionPageIdentifier;
                    } else return true;
                })
                .map((item, idx) => {
                    const index = Fuse.createIndex(['question'], item.data);
                    const fusedItemData = new Fuse(
                        item.data,
                        {
                            keys: ['question'],
                            findAllMatches: true,
                            threshold: 0.3,
                        },
                        index
                    );
                    const x =
                        searchText.trim() === ''
                            ? item.data
                            : fusedItemData
                                  .search(searchText)
                                  .map(({ item }) => item);
                    return (
                        <div
                            key={idx}
                            data-scroll-section={`help-scroll-section-${item.sectionIdentifier}`}
                        >
                            <FWAccordion
                                sx={{
                                    '&.MuiPaper-root': {
                                        minWidth: 'auto',
                                        p: 0,
                                        border: 0,
                                    },
                                    '.MuiButtonBase-root': {
                                        width: 'max-content !important',
                                    },
                                    '.MuiAccordionSummary-content': {
                                        width: 'max-content !important',
                                    },
                                    '.MuiAccordionSummary-expandIconWrapper': {
                                        ml: '10px',
                                        zoom: 0.7,
                                    },
                                }}
                                expanded={
                                    toggleAccordion.has(item.sectionTitle) ||
                                    defaultOpen ||
                                    searchText.length > 0
                                }
                                onClick={() => {
                                    setDefaultOpen(false);
                                    if (
                                        toggleAccordion.has(item.sectionTitle)
                                    ) {
                                        setToggleAccordion((prev) => {
                                            let newSet = new Set(prev);
                                            newSet.delete(item.sectionTitle);
                                            return newSet;
                                        });
                                    } else {
                                        setToggleAccordion((prev) => {
                                            let newSet = new Set(prev);
                                            newSet.add(item.sectionTitle);
                                            return newSet;
                                        });
                                    }
                                }}
                            >
                                {sectionTitleSet.size !==
                                    sectionTitleSet.add(item.sectionTitle)
                                        .size && (
                                    <FWAccordionSummary>
                                        <FWTypography fontSize="20px">
                                            {item.sectionTitle}
                                        </FWTypography>
                                    </FWAccordionSummary>
                                )}
                                <FWAccordionDetails
                                    onClick={(e) => e.stopPropagation()}
                                >
                                    {x.map((data, idx2) => {
                                        switch (data.type) {
                                            case DataType.BLOCKS:
                                                return (
                                                    <BlocksDataContainer
                                                        key={idx2}
                                                        {...data}
                                                    />
                                                );
                                            case DataType.FAQS:
                                                return (
                                                    <Box key={idx2}>faqs</Box>
                                                );
                                            case DataType.LIST:
                                                return (
                                                    <ListDataContainer
                                                        key={idx2}
                                                        {...data}
                                                    />
                                                );
                                            default:
                                                return null;
                                        }
                                    })}
                                </FWAccordionDetails>
                            </FWAccordion>
                        </div>
                    );
                })}
        </HelpTabPanel>
    );
};

export default FAQHelpTabPanel;
