import { Grid, IconButton } from '@mui/material';
import { FWInput } from '../../../Common/FWInput';
import {
    IVendorInfoErrors,
    VendorActions,
    VendorDetailActions,
} from '../Hooks/Vendors/VendorAddHook';
import { IVendorAddDetails } from '../Interfaces/VendorsInterface';
import { getDuplicateNames } from './Settings/Events/EventSettings';
import { isEmpty } from 'lodash';

const VendorIdentifications = ({
    data,
    validateInfo,
    updateVendorDetails,
    vendorInfoErrors,
    index,
    identificationsLength,
    identification_name,
    identification_value,
    vendorDetails,
    disabled,
    isLastItem,
}: {
    data: {
        identification_name: string;
        identification_value: string;
    }[]
    | null;
    vendorDetails: IVendorAddDetails;
    validateInfo: (event: any, index?: number | undefined) => void;
    updateVendorDetails: (action: VendorActions) => void;
    vendorInfoErrors: IVendorInfoErrors;
    index: number;
    identificationsLength: number;
    identification_name: string;
    identification_value: string;
    disabled?: boolean;
    isLastItem: boolean;
}) => {
    const getValue = (name: string) => {
        if (name.trim()) return name
        else return name.trim()
    }
    let duplicateIdentifications: string[] = []
    if (data !== null) {
        duplicateIdentifications = (
            getDuplicateNames(
                data.map((c) => c.identification_name.toLowerCase().trim()).filter((c) => !isEmpty(c))
            )
        );
    }
    return (
        <>
            <Grid item xs={5}>
                <FWInput
                    label={'Identification name'}
                    name={VendorDetailActions.IDENTIFICATION_NAME}
                    value={getValue(identification_name)}
                    onChange={(e) => {
                        validateInfo(e, index);
                        updateVendorDetails({
                            type: VendorDetailActions.IDENTIFICATION_NAME,
                            value: e.target.value,
                            index,
                        });
                    }}
                    // onBlur={handleFieldBlur}
                    error={
                        identification_value && !identification_name
                            ? true
                            : false
                            || duplicateIdentifications.includes(identification_name.toLowerCase().trim())
                    }
                    helper={{
                        text:
                            identification_value && !identification_name
                                ? 'Please enter a identification name'
                                : ''
                                || duplicateIdentifications.includes(identification_name.toLowerCase().trim())
                                    ? `Duplicate name ${identification_name}`
                                    : '',
                        textColor: 'error',
                    }}
                    disabled={disabled}
                />
            </Grid>
            <Grid item xs={5}>
                <FWInput
                    label={'Identification value'}
                    name={VendorDetailActions.IDENTIFICATION_VALUE}
                    value={getValue(identification_value)}
                    onChange={(e) => {
                        validateInfo(e, index);
                        updateVendorDetails({
                            type: VendorDetailActions.IDENTIFICATION_VALUE,
                            value: e.target.value,
                            index,
                        });
                    }}
                    // onBlur={handleFieldBlur}
                    // error={
                    //     identification_name && !identification_value
                    //         ? true
                    //         : false
                    // }
                    // helper={{
                    //     text:
                    //         identification_name && !identification_value
                    //             ? 'Please enter a identification value'
                    //             : '',
                    //     textColor: 'error',
                    // }}
                    disabled={disabled}
                />
            </Grid>
            {!disabled && (
                <Grid item xs={2} alignSelf={'flex-end'}>
                    <IconButton
                        color={'error'}
                        size={'small'}
                        disabled={index === 0 && identificationsLength === 1}
                        onClick={() => {
                            updateVendorDetails({
                                type: VendorDetailActions.IDENTIFICATION_REMOVE,
                                index,
                            });
                        }}
                    >
                        <i className="bi bi-dash-circle" />
                    </IconButton>
                    {isLastItem && (
                        <IconButton
                            color={'primary'}
                            size={'small'}
                            onClick={() => {
                                updateVendorDetails({
                                    type: VendorDetailActions.IDENTIFICATION_ADD,
                                });
                            }}
                        >
                            <i className="bi bi-plus-circle" />
                        </IconButton>
                    )}
                </Grid>
            )}
        </>
    );
};

export default VendorIdentifications;
