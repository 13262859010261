import { Box, Grid } from '@mui/material';
import { Fragment, useState } from 'react';
import FWPopover from '../../Common/FWPopover';
import { FWTypography } from '../../Common/FWTypograhy';
import IProject from '../interface/project.model';

const ProjectManagerCell = ({
    data,
    context,
}: {
    data: IProject;
    context: {
        updateStatusOfProject: () => void;
        canUserDeleteProject: boolean;
    };
}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClose = () => setAnchorEl(null);
    const handleOpen = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    return (
        <>
            <Box
                color={
                    data.projectManagers?.length === 0
                        ? 'text.secondary'
                        : data.projectManagers?.length === 1
                        ? ''
                        : data.projectManagers?.length > 0
                        ? 'primary.main'
                        : ''
                }
                sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-start',
                }}
            >
                <div
                    ref={(ref) => {
                        if (!ref) return;
                        ref.onclick = (e: any) => {
                            e.stopPropagation();
                            if (data.projectManagers?.length > 0) handleOpen(e);
                        };
                    }}
                >
                    {data.projectManagers?.length === 0 ? (
                        ''
                    ) : data.projectManagers?.length === 1 ? (
                        <FWTypography style={{
                        fontFamily: 'Inter, sans-serif',
                        fontStyle: 'normal',
                        fontSize: '16px'
                    }}>
                            {data.projectManagers[0]?.name}
                        </FWTypography>
                    ) : (
                        data.projectManagers?.length
                    )}
                </div>
            </Box>

            <FWPopover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Grid container>
                    {data.projectManagers?.length > 0 ? (
                        <div className="p--5">
                            <div className="mb--5">
                                <FWTypography color="text.secondary">
                                    Project managerr(s)
                                </FWTypography>
                            </div>
                            {data.projectManagers.map((manager, idx) => (
                                <Fragment key={idx} >
                                    <Grid
                                        item
                                        md={12}
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                        }}
                                    >
                                        <FWTypography>
                                            {idx + 1}. {manager?.name}
                                        </FWTypography>
                                    </Grid>
                                </Fragment>
                            ))}
                        </div>
                    ) : (
                        <FWTypography>-</FWTypography>
                    )}
                </Grid>
            </FWPopover>
        </>
    );
};

export default ProjectManagerCell;
