import DialogContent from '@mui/material/DialogContent';
import { Grid, Typography } from '@mui/material';
import { FWTypography } from '../../Common/FWTypograhy';
import { FWPopupWithChildren } from '../../Common/FWPopupWithChildren';
import { isEmpty } from 'lodash';
import TagsDisplay from '../../Global/Components/TagsDisplay';
import {
    IItemMeasurementUnits,
    IItemSummary,
} from '../../Organizations/Admin/Interfaces/ItemDirectoryInterface';
import { IPaymentsItemSummary } from '../Interfaces/PaymentsInterface';
import { ICustomAttributeNew } from '../../Models/Attributes.model';

interface IDatagridItemPopup {
    open: boolean;
    setOpen: (State: boolean) => void;
    itemDetails:
        | { type: 'PAYMENT_SUMMARY'; data: IPaymentsItemSummary | null }
        | {
              type: 'ITEM_SUMMARY';
              data: IItemSummary | null;
              allMeasurementUnits: IItemMeasurementUnits;
          };
}

export const PaymentsItemPopup = ({
    open,
    setOpen,
    itemDetails,
}: IDatagridItemPopup) => {
    const handleClose = () => setOpen(false);

    return (
        <FWPopupWithChildren
            title={
                itemDetails.type === 'PAYMENT_SUMMARY' && itemDetails.data
                    ? itemDetails.data.itemName
                    : itemDetails.type === 'ITEM_SUMMARY' && itemDetails.data
                    ? itemDetails.data.name
                    : ''
            }
            open={open}
            handleClose={handleClose}
        >
            {itemDetails ? (
                <DialogContent sx={{ m: '0px', pt: 0 }}>
                    <Grid container columnSpacing={2} rowSpacing={2}>
                        <Grid item xs={12}>
                            <FWTypography sx={{ color: 'text.secondary' }}>
                                Item ID
                            </FWTypography>
                            <FWTypography>
                                {itemDetails.type === 'PAYMENT_SUMMARY' &&
                                itemDetails.data
                                    ? itemDetails.data.itemId
                                    : itemDetails.type === 'ITEM_SUMMARY' &&
                                      itemDetails.data
                                    ? itemDetails.data.itemCode
                                    : ''}
                            </FWTypography>
                        </Grid>
                        {!isEmpty(itemDetails.data?.description) && (
                            <Grid item xs={12}>
                                <FWTypography sx={{ color: 'text.secondary' }}>
                                    Description
                                </FWTypography>
                                <FWTypography>
                                    {itemDetails.type === 'PAYMENT_SUMMARY' &&
                                    itemDetails.data
                                        ? itemDetails.data.description
                                        : itemDetails.type === 'ITEM_SUMMARY' &&
                                          itemDetails.data?.description}
                                </FWTypography>
                            </Grid>
                        )}
                        {itemDetails.type === 'ITEM_SUMMARY' &&
                            itemDetails.data &&
                            itemDetails.data.measurementUnits?.length > 0 && (
                                <Grid item xs={12}>
                                    <FWTypography
                                        sx={{ color: 'text.secondary' }}
                                    >
                                        Measurement Units
                                    </FWTypography>
                                    <FWTypography>
                                        {itemDetails.data.measurementUnits
                                            .map(
                                                (val) =>
                                                    itemDetails
                                                        .allMeasurementUnits?.[
                                                        val
                                                    ]?.name
                                            )
                                            .join(', ')}
                                    </FWTypography>
                                </Grid>
                            )}

                        {itemDetails.type === 'ITEM_SUMMARY' &&
                            itemDetails.data &&
                            +(
                                itemDetails.data.buyerPricingInformation
                                    ?.price || 0
                            ) > 0 && (
                                <Grid item xs={12}>
                                    <FWTypography
                                        sx={{ color: 'text.secondary' }}
                                    >
                                        Buyer default price
                                    </FWTypography>
                                    <FWTypography>
                                        {`${itemDetails.data.buyerPricingInformation?.currency_symbol}${itemDetails.data.buyerPricingInformation?.price}`}
                                    </FWTypography>
                                </Grid>
                            )}
                        {itemDetails.type === 'ITEM_SUMMARY' &&
                            itemDetails.data &&
                            +(
                                itemDetails.data.sellerPricingInformation
                                    ?.price || 0
                            ) > 0 && (
                                <Grid item xs={12}>
                                    <FWTypography
                                        sx={{ color: 'text.secondary' }}
                                    >
                                        Seller default price
                                    </FWTypography>
                                    <FWTypography>
                                        {`${itemDetails.data.sellerPricingInformation?.currency_symbol}${itemDetails.data.sellerPricingInformation?.price}`}
                                    </FWTypography>
                                </Grid>
                            )}

                        {((itemDetails.type === 'PAYMENT_SUMMARY' &&
                            itemDetails.data &&
                            itemDetails.data.additionalInfo) ||
                            (itemDetails.type === 'ITEM_SUMMARY' &&
                                itemDetails.data &&
                                itemDetails.data.notes)) && (
                            <Grid item md={12}>
                                <FWTypography sx={{ color: 'text.secondary' }}>
                                    Additional information
                                </FWTypography>
                                <FWTypography>
                                    {itemDetails.type === 'PAYMENT_SUMMARY' &&
                                    itemDetails.data
                                        ? itemDetails.data.additionalInfo
                                        : itemDetails.type === 'ITEM_SUMMARY' &&
                                          itemDetails.data?.notes}
                                </FWTypography>
                            </Grid>
                        )}

                        {(itemDetails.type === 'PAYMENT_SUMMARY' &&
                        itemDetails.data
                            ? itemDetails.data.customIDs
                            : itemDetails.type === 'ITEM_SUMMARY' &&
                              itemDetails.data?.customIds
                            ? itemDetails.data.customIds
                            : []
                        )?.length > 0 && (
                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    {(itemDetails.type === 'PAYMENT_SUMMARY' &&
                                    itemDetails.data
                                        ? itemDetails.data.customIDs
                                        : itemDetails.type === 'ITEM_SUMMARY' &&
                                          itemDetails.data?.customIds
                                        ? itemDetails.data.customIds
                                        : []
                                    ).map((custom_id, idx) => (
                                        <Grid item xs={'auto'} key={idx}>
                                            <FWTypography color="text.secondary">
                                                {custom_id.name}
                                            </FWTypography>
                                            <FWTypography>
                                                {custom_id.value}
                                            </FWTypography>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grid>
                        )}
                        <br />
                        {(itemDetails.type === 'PAYMENT_SUMMARY' &&
                        itemDetails?.data
                            ? itemDetails?.data?.attributes?.buyerAttributes
                            : itemDetails?.type === 'ITEM_SUMMARY' &&
                              itemDetails?.data
                            ? itemDetails?.data?.specifications
                            : []
                        )?.length > 0 && (
                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    {itemDetails.type === 'PAYMENT_SUMMARY' &&
                                        itemDetails.data &&
                                        itemDetails.data.attributes.buyerAttributes.map(
                                            (
                                                attribute: {
                                                    name: string;
                                                    value: string[];
                                                },
                                                idx: number
                                            ) => (
                                                <Grid
                                                    item
                                                    xs={'auto'}
                                                    key={idx}
                                                >
                                                    <FWTypography
                                                        sx={{
                                                            color: 'text.secondary',
                                                        }}
                                                    >
                                                        {attribute.name}
                                                    </FWTypography>
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            alignItems:
                                                                'center',
                                                        }}
                                                    >
                                                        <FWTypography>
                                                            {attribute.value.join(
                                                                ' / '
                                                            )}
                                                        </FWTypography>
                                                    </div>
                                                </Grid>
                                            )
                                        )}

                                    {itemDetails.type === 'ITEM_SUMMARY' &&
                                        itemDetails.data &&
                                        itemDetails.data.specifications.map(
                                            (
                                                attribute: ICustomAttributeNew,
                                                idx: number
                                            ) => (
                                                <Grid
                                                    item
                                                    xs={'auto'}
                                                    key={idx}
                                                >
                                                    <FWTypography
                                                        sx={{
                                                            color: 'text.secondary',
                                                        }}
                                                    >
                                                        {
                                                            attribute.attribute_name
                                                        }
                                                    </FWTypography>
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            alignItems:
                                                                'center',
                                                        }}
                                                    >
                                                        <FWTypography>
                                                            {attribute.attribute_values
                                                                .map(
                                                                    (val) =>
                                                                        val.value
                                                                )
                                                                .join(' / ')}
                                                        </FWTypography>
                                                    </div>
                                                </Grid>
                                            )
                                        )}
                                </Grid>
                            </Grid>
                        )}
                        {/* {(itemDetails.type === 'PAYMENT_SUMMARY' &&
                        itemDetails.data
                            ? itemDetails.data.attributes.customAttributes
                            : itemDetails.type === 'ITEM_SUMMARY' &&
                              itemDetails.data
                            ? itemDetails.data.specifications
                            : []
                        ).map((attribute, idx) => (
                            <Grid item xs={'auto'} key={idx}>
                                <FWTypography sx={{ color: 'text.secondary' }}>
                                    {attribute.name}
                                </FWTypography>
                                <FWTypography>
                                    {attribute.value.join(' / ')}
                                </FWTypography>
                            </Grid>
                        ))} */}

                        {itemDetails.data && itemDetails.data.tags?.length > 0 && (
                            <Grid item md={12}>
                                <FWTypography sx={{ color: 'text.secondary' }}>
                                    Item tags
                                </FWTypography>
                                <FWTypography>
                                    <TagsDisplay tags={itemDetails.data.tags} />
                                </FWTypography>
                            </Grid>
                        )}
                    </Grid>
                </DialogContent>
            ) : (
                <DialogContent sx={{ m: '0px' }}>
                    <Typography textAlign="center" color="text.secondary">
                        No data
                    </Typography>
                </DialogContent>
            )}
        </FWPopupWithChildren>
    );
};
