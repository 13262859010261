import { Box } from '@mui/material';
import React from 'react';
import { FWTypography } from '../../../Common/FWTypograhy';

const DisplayLink = ({ link, name }: { link: string; name: string }) => {
    return (
        <Box
            className="flex flex--aife"
            bgcolor={'#F8F8FB'}
            padding={2}
            borderRadius={1}
        >
            <Box color="primary.main" fontSize={15}>
                <i className="bi bi-link-45deg" />
            </Box>
            &nbsp;
            <FWTypography
                color={'primary.main'}
                sx={{
                    cursor: 'pointer',
                    textDecoration: 'underline',
                }}
                onClick={() =>
                    window.open(
                        link.includes('http') ? link : `http://${link}`,
                        '_blank'
                    )
                }
            >
                {name}
            </FWTypography>
            &nbsp;
        </Box>
    );
};

export default DisplayLink;
