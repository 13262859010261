export interface IUserPermission {
    user_id: string;
    name: string;
    entity_id: string;
    entity_name: string;
    action_api_group: string;
    criteria: string | null;
    created_by_user_id: string;
    modified_by_user_id: string;
    deleted_by_user_id: string | null;
    created_datetime: string | null;
    modified_datetime: string | null;
    deleted_datetime: string | null;
}

export enum InvitationType {
    INVITATION_BY_ADMIN = 'INVITATION_BY_ADMIN',
    SELF_SIGN_UP = 'SELF_SIGN_UP',
    VENDOR_ENTERPRISE_INVITE = 'VENDOR_ENTERPRISE_INVITE', //seller
    VENDOR_CONTACT_INVITE = 'VENDOR_CONTACT_INVITE', //seller
    ENTERPRISE_SIGNUP_REQUEST = 'ENTERPRISE_SIGNUP_REQUEST',
    EMAIL_VERIFICATION = 'EMAIL VERIFICATION',
    JOIN_ORGANIZATION = 'JOIN_ORGANIZATION',
    JOIN_ORGANIZATION_VENDOR_INVITE = 'JOIN_ORGANIZATION_VENDOR_INVITE', //seller
}

export interface IUserDetails {
    user_id: string;
    username: string;
    enterprise_id: string;
    enterprise_name: string;
    role: 'ADMIN' | 'USER' | 'ADMIN_VIEW';
    is_email_verified: boolean;
    description: string | null;
    status: 'ACTIVE' | 'INACTIVE' | 'INVITED' | 'REQUESTED' | 'SIGNED_UP'; // INVITED USERS WOULD NEVER COME TO AUTHCONTEXT
    email: string;
    name: string;
    user_picture_url: string;
    contacts: {
        emails: {
            email: string;
            is_public: boolean;
        }[];
        phone_numbers: {
            phone: string;
            is_public: boolean;
        }[];
    } | null;
    defaults: {
        homepage: string;
        timezone: string;
        number_format: string;
        primary_entity_id: string;
    } | null;
    additional_details: {} | null;
    enterprise: {
        enterprise_id: string;
        enterprise_name: string;
        enterprise_logo: string | null;
        enterprise_doing_business_as: string;
        module_counter_information: {
            event: number;
            payment: number;
            goods_receipt: number;
            quality_check: number;
            purchase_order: number;
            prepayment_invoice: number;
            quality_check_primary_accepted: number;
            quality_check_primary_rejected: number;
            quality_check_secondary_accepted: number;
            quality_check_secondary_rejected: number;
            quality_check_production_line_accepted: number;
            quality_check_production_line_rejected: number;
        };
        description: string;
        contacts: {
            emails: string[];
            websites: string[];
            phone_numbers: string[];
        };
        domain: null | 'string';
        status: string;
        created_by_user: string;
        modified_by_user: string;
        deleted_by_user: string | null;
        billing_currency: string;
    };
    invitation_type?: InvitationType;
    onboarding_information: {
        onboarding: boolean;
        welcome_pop_up: boolean;
        checklist: boolean;
    };
}

export interface IAllUserEventPermissions {
    [key: string]: IUserEventPermission[];
}

export interface IUserEventPermission {
    entry_id: string;
    event_id: string;
    user_id: string;
    action_api_group: string;
    user_first_name: string;
    user_last_name: string;
}

export enum PermissionEnum {
    PURCHASE_ORDER_VIEW = 'BUYER_PURCHASE_ORDER',
    PURCHASE_ORDER_APPROVAL = 'BUYER_PURCHASE_ORDER_APPROVAL',
    PURCHASE_ORDER_MANAGEMENT = 'BUYER_PURCHASE_ORDER_MANAGEMENT',
    PURCHASE_ORDER_ADMING = 'BUYER_PURCHASE_ORDER_MANAGEMENT_ADMIN',
}
