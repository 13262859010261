import { Box, CircularProgress, IconButton } from '@mui/material';
import React, { useEffect } from 'react';
import ReactQuill from 'react-quill';
import { IVLNNote } from '../../../Interfaces/VendorLNInterfaces';
import { FWTypography } from '../../../../Common/FWTypograhy';
import { FWPopup } from '../../../../Common/FWPopup';
import { HookStateValue, IHookState } from '../../../../Common/Hooks/StateHook';
import { IVLNNoteUpdate } from '../../../Hooks/Relationship/useVLNRelationshipNotes';
import VLNRNotesEditTitle from './VLNRNotesEditTitle';
import { isEqual } from 'lodash';

const VLNRelationshipNotesEditSection = ({
    selectedVLNNote,
    onDeleteNote,
    updateNoteNoteHookState,
    updateNoteTitleHookState,
    updateNote,
}: {
    selectedVLNNote: IVLNNote;
    onDeleteNote: (note_id: string, buyer_entity_id: string) => void;
    updateNoteNoteHookState: IHookState;
    updateNoteTitleHookState: IHookState;
    updateNote: IVLNNoteUpdate;
}) => {
    const [notes, setNotes] = React.useState(selectedVLNNote.notes);

    useEffect(() => {
        if (!isEqual(notes, selectedVLNNote.notes)) {
            updateNote(selectedVLNNote, {
                type: 'notes',
                value: notes,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notes]);

    const [deletePopupOpen, setDeletePopupOpen] = React.useState(false);
    const [editNoteTitle, setEditNoteTitle] = React.useState(false);

    return (
        <>
            <Box className={'flex flex--jcsb'} marginBottom={1}>
                {!editNoteTitle ? (
                    <div className={'flex flex--aic'}>
                        <Box overflow={'hidden'} maxWidth={'50vw'}>
                            <FWTypography variant="h4">
                                {selectedVLNNote.title}
                            </FWTypography>
                        </Box>
                        &nbsp;
                        <IconButton
                            color={'primary'}
                            size={'small'}
                            onClick={() => {
                                setEditNoteTitle(true);
                            }}
                        >
                            <i className="bi bi-pencil-fill" />
                        </IconButton>
                    </div>
                ) : (
                    <VLNRNotesEditTitle
                        onClose={() => {
                            setEditNoteTitle(false);
                        }}
                        oldTitle={selectedVLNNote.title}
                        onClickSave={(newTitle) => {
                            updateNote(
                                selectedVLNNote,
                                {
                                    type: 'title',
                                    value: newTitle,
                                },
                                () => {
                                    setEditNoteTitle(false);
                                }
                            );
                        }}
                        isLoading={
                            updateNoteTitleHookState.state ===
                            HookStateValue.LOADING
                        }
                    />
                )}
                <Box className={'flex flex--aic'}>
                    {updateNoteNoteHookState.state ===
                        HookStateValue.LOADING && (
                        <CircularProgress size={20} color={'primary'} />
                    )}
                    <IconButton
                        color={'error'}
                        size={'small'}
                        onClick={() => {
                            setDeletePopupOpen(true);
                        }}
                    >
                        <i className="bi bi-trash3" />
                    </IconButton>
                </Box>
            </Box>
            <ReactQuill
                value={notes}
                placeholder="Write a note..."
                onChange={(newvalue) => {
                    setNotes(newvalue);
                }}
                style={{
                    height: 'calc(100vh - 450px)',
                }}
                // formats={['font', 'sizes']}
                // modules={{
                //     toolbar: [
                //         {
                //             font: [
                //                 { label: 'Small', value: '12px' },
                //                 { label: 'Normal', value: '16px' },
                //                 { label: 'Large', value: '20px' },
                //             ],
                //         },
                //     ],
                // }}
            />
            <FWPopup
                title="Delete Note"
                open={deletePopupOpen}
                onClickConfirm={() => {
                    onDeleteNote(
                        selectedVLNNote.vendor_profile_note_id,
                        selectedVLNNote.buyer_entity
                    );
                    setDeletePopupOpen(false);
                }}
                msg="Are you sure you want to delete this note?"
                setOpen={setDeletePopupOpen}
                btnColor={'error'}
            />
        </>
    );
};

export default VLNRelationshipNotesEditSection;
