import { Box } from '@mui/material';
import { FWTooltip } from './FWCustomTooltip';

interface IFWMultipleTooltipMessagesProps {
    title?: string;
    messages: string[];
    iconColor: string;
    icon: JSX.Element;
    keepShowingIcon?: boolean;
    placement?:
        | 'bottom-end'
        | 'bottom-start'
        | 'bottom'
        | 'left-end'
        | 'left-start'
        | 'left'
        | 'right-end'
        | 'right-start'
        | 'right'
        | 'top-end'
        | 'top-start'
        | 'top';
    marginLeft?: string;
    removeNumberring?: boolean;
}

const FWMultipleTooltipMessages = ({
    title = '',
    messages,
    icon,
    iconColor,
    placement,
    keepShowingIcon,
    marginLeft = '2px',
    removeNumberring = false,
}: IFWMultipleTooltipMessagesProps) => {
    const messagesList = messages.map((message, idx) => {
        return (
            <div key={idx}>
                {removeNumberring ? '' : `${idx + 1}.`} {message}
            </div>
        );
    });

    const titleElem = title.length > 0 ? <div>{title}</div> : null;

    const messagesWithTitle = titleElem
        ? [titleElem, ...messagesList]
        : messagesList;

    return (
        <>
            {keepShowingIcon && messages.length === 0 && (
                <Box color={iconColor} style={{ marginLeft: marginLeft }}>
                    {icon}
                </Box>
            )}
            {messages.length === 1 && title.length === 0 && (
                <>
                    <FWTooltip title={messages[0] || ''} placement={placement}>
                        <Box
                            color={iconColor}
                            style={{ marginLeft: marginLeft }}
                        >
                            {icon}
                        </Box>
                    </FWTooltip>
                </>
            )}
            {(title.length > 0 || messages.length > 1) && (
                <>
                    <FWTooltip title={messagesWithTitle} placement={placement}>
                        <Box
                            color={iconColor}
                            style={{ marginLeft: marginLeft }}
                        >
                            {icon}
                        </Box>
                    </FWTooltip>
                </>
            )}
        </>
    );
};

export default FWMultipleTooltipMessages;
