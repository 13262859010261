import { Typography } from '@mui/material';
import { ColDef } from 'ag-grid-community';
import FWMultipleTooltipMessages from '../../Common/FWMultipleTooltipMessages';
import { FWTypography } from '../../Common/FWTypograhy';
import { FWNumberFormatter } from '../../Common/Utils/CurrencyUtils';
import DotBadge from '../../Components/Shared/DotBadge';
import { generatePOWarnings } from '../../Global/Helpers/ItemAndPOHelpers';
import {
    PaymentCompletedTotalAmountCell,
    PaymentItemTotalInfoCell,
    PaymentPendingInfoCell,
    PaymentsPendingDueDateCell,
    PaymentsPendingItemNameCell,
    PrepaymentPendingInvoicesDetailCell,
} from '../Components/PaymentsCellRenderers';
import {
    CreditType,
    IDefaultViewPaymentItem,
    IInvoiceViewPaymentItem,
    IPaymentsCompletedListSummary,
    IPaymentsPendingListSummary,
    PaymentItemType,
    PaymentStatus,
} from '../Interfaces/PaymentsInterface';

export const paymentsPendingColumns = (allowPaymentBeforeQcForEntity: {
    [entityUid: string]: boolean;
}): ColDef[] => {
    return [
        {
            field: 'selection',
            headerName: '',
            width: 50,
            cellRenderer: (params: any) => '',
            checkboxSelection: true,
        },
        {
            field: 'entityName',
            headerName: 'Entity',
            width: 180,
            cellRenderer: (params: any) => (
                <FWTypography>{params.data.entityName}</FWTypography>
            ),
            sortable: true,
            filter: true,
            valueGetter: (params) => params.data.entityName,
            getQuickFilterText: (params) => params.data.entityName,
        },
        {
            field: 'poId',
            headerName: 'PO ID',
            width: 180,
            cellRenderer: (params: any) => {
                const poWarnings = generatePOWarnings(params.data);
                return (
                    <div
                        className="flex flex--aic"
                        style={{
                            width: '100%',
                        }}
                    >
                        <FWTypography sx={{ maxWidth: '100%' }}>
                            {params.data.poId}
                        </FWTypography>
                        {poWarnings.warnings.length > 0 && (
                            <div className="ml--5">
                                <FWMultipleTooltipMessages
                                    messages={poWarnings.warnings}
                                    iconColor={poWarnings.color + '.main'}
                                    icon={
                                        <div style={{ lineHeight: 0 }}>
                                            <DotBadge
                                                color={poWarnings.color}
                                            />
                                        </div>
                                    }
                                />
                            </div>
                        )}
                    </div>
                );
            },
            sortable: true,
            filter: true,
            valueGetter: (params) => params.data.poId,
            getQuickFilterText: (params) => params.data.poId,
        },
        {
            field: 'invoiceId',
            headerName: 'Invoice ID',
            width: 250,
            cellRenderer: (params: any) => (
                <PrepaymentPendingInvoicesDetailCell
                    data={params.data}
                    context={params.context}
                    allowPaymentBeforeQcForEntity={
                        allowPaymentBeforeQcForEntity
                    }
                />
            ),
            sortable: true,
            filter: true,
            valueGetter: (params) => params.data.invoiceId,
            getQuickFilterText: (params) => params.data.invoiceId,
        },
        {
            field: 'vendorName',
            headerName: 'Vendor name',
            minWidth: 180,
            flex: 1,
            cellRenderer: (params: any) => (
                <FWTypography>{params.data.vendorName}</FWTypography>
            ),
            sortable: true,
            filter: true,
            valueGetter: (params) => params.data.vendorName,
            getQuickFilterText: (params) => params.data.vendorName,
        },
        {
            field: 'itemName',
            headerName: 'Item name',
            minWidth: 180,
            flex: 1,
            cellRenderer: PaymentsPendingItemNameCell,
            sortable: true,
            filter: true,
            valueGetter: (params) => params.data.itemDetails.itemName,
            getQuickFilterText: (params) => params.data.itemDetails.itemName,
        },
        {
            field: 'paymentDueDate',
            headerName: 'Payment due',
            width: 170,
            cellRenderer: PaymentsPendingDueDateCell,
            sortable: true,
            filter: true,
            valueGetter: ({ data }: { data: IPaymentsPendingListSummary }) =>
                data.paymentStatus === PaymentStatus.COMPLETED
                    ? 'Paid'
                    : data.paymentStatus !== PaymentStatus.PAYMENT_DUE
                    ? data.paymentDueDate
                    : data.paymentStatus,
            getQuickFilterText: ({
                data,
            }: {
                data: IPaymentsPendingListSummary;
            }) =>
                data.paymentStatus === PaymentStatus.COMPLETED
                    ? 'Paid'
                    : data.paymentStatus !== PaymentStatus.PAYMENT_DUE
                    ? data.paymentDueDate?.toString() ?? ''
                    : data.paymentStatus.toString(),
            comparator: function (number1, number2, a) {
                let x = number1 === 'Prepayment' ? 0 : number1;
                let y = number2 === 'Prepayment' ? 0 : number2;
                if (x === 'No payment due') {
                    return 2;
                }
                // if (y === 'No payment due') {
                //     y = 0;
                // }
                return parseFloat(x) - parseFloat(y);
            },
        },
        {
            field: 'totalAmount',
            headerName: 'Total amount',
            width: 160,
            cellRenderer: PaymentItemTotalInfoCell,
            sortable: true,
            filter: true,
            valueGetter: (params) => params.data.totalAmount + '',
            getQuickFilterText: (params) => params.data.totalAmount,
        },
        {
            field: 'amountPending',
            headerName: 'Amount pending',
            width: 180,
            cellRenderer: PaymentPendingInfoCell,
            sortable: true,
            filter: true,
            valueGetter: (params) =>
                params.data.amountPending < 0
                    ? 0
                    : params.data.amountPending + '',
            getQuickFilterText: (params) =>
                params.data.amountPending < 0
                    ? '0'
                    : params.data.amountPending + '',
        },
    ];
};

export const paymentsCompletedColumns: ColDef[] = [
    {
        field: 'paymentId',
        headerName: 'Payment ID',
        width: 200,
        cellRenderer: (params: any) => (
            <FWTypography>{params.data.paymentId}</FWTypography>
        ),
        sortable: true,
        filter: true,
        valueGetter: (params) => params.data.paymentId,
        getQuickFilterText: (params) => params.data.paymentId,
    },
    {
        field: 'entityName',
        headerName: 'Entity',
        minWidth: 180,
        flex: 1,
        cellRenderer: (params: any) => (
            <FWTypography>{params.data.entityName}</FWTypography>
        ),
        sortable: true,
        filter: true,
        valueGetter: (params) => params.data.entityName,
        getQuickFilterText: (params) => params.data.entityName,
    },
    {
        field: 'vendorName',
        headerName: 'Vendor',
        minWidth: 180,
        flex: 1,
        cellRenderer: (params: any) => (
            <FWTypography>{params.data.vendorName}</FWTypography>
        ),
        sortable: true,
        filter: true,
        valueGetter: (params) => params.data.vendorName,
        getQuickFilterText: (params) => params.data.vendorName,
    },
    {
        field: 'poIdList',
        headerName: 'PO ID',
        width: 180,
        cellRenderer: (params: any) => (
            <FWTypography>{params.data.poIdList.join(', ')}</FWTypography>
        ),
        valueGetter: (params) => params.data.poIdList.join(', '),
        getQuickFilterText: (params) => params.data.poIdList.join(', '),
        hide: true,
    },
    {
        field: 'invoiceIdList',
        headerName: 'PO ID',
        width: 180,
        cellRenderer: (params: any) => (
            <FWTypography>{params.data.invoiceIdList.join(', ')}</FWTypography>
        ),
        valueGetter: (params) => params.data.invoiceIdList.join(', '),
        getQuickFilterText: (params) => params.data.invoiceIdList.join(', '),
        hide: true,
    },
    {
        field: 'totalPos',
        headerName: 'PO(s)',
        width: 140,
        cellRenderer: (params: any) => (
            <div
                style={{
                    marginLeft: 'auto',
                    paddingRight: '20px',
                }}
            >
                <FWMultipleTooltipMessages
                    messages={params.data.poIdList}
                    iconColor="primary.main"
                    icon={params.data.poIdList.length}
                />
            </div>
        ),
        sortable: true,
        filter: true,
        valueGetter: (params) => params.data.poIdList.length + '',
        getQuickFilterText: (params) => params.data.poIdList.length,
    },
    {
        field: 'totalInvoices',
        headerName: 'Invoice(s)',
        width: 140,
        cellRenderer: (params: any) => (
            <div
                style={{
                    marginLeft: 'auto',
                    paddingRight: '20px',
                }}
            >
                <FWMultipleTooltipMessages
                    messages={params.data.invoiceIdList}
                    iconColor="primary.main"
                    icon={params.data.invoiceIdList.length}
                />
            </div>
        ),
        sortable: true,
        filter: true,
        valueGetter: (params) => params.data.invoiceIdList.length + '',
        getQuickFilterText: (params) => params.data.invoiceIdList.length,
    },
    {
        field: 'totalItems',
        headerName: 'Item(s)',
        width: 110,
        cellRenderer: ({ data }: { data: IPaymentsCompletedListSummary }) => (
            <div
                className="flex flex--jcfe"
                style={{
                    width: '100%',
                }}
            >
                <FWMultipleTooltipMessages
                    messages={data.itemNames}
                    iconColor="primary"
                    icon={
                        <Typography
                            sx={{ width: '100%', textAlign: 'right' }}
                            color="primary.main"
                        >
                            {data.totalItems}
                        </Typography>
                    }
                />
            </div>
        ),
        sortable: true,
        filter: true,
        valueGetter: (params) => params.data.totalItems + '',
        getQuickFilterText: (params) => params.data.totalItems,
    },
    {
        field: 'itemNames',
        headerName: 'itemNames',
        width: 110,
        sortable: true,
        filter: true,
        valueGetter: ({ data }: { data: IPaymentsCompletedListSummary }) =>
            data.itemNames.join(' '),
        getQuickFilterText: ({
            data,
        }: {
            data: IPaymentsCompletedListSummary;
        }) => data.itemNames.join(' '),
        hide: true,
    },
    {
        field: 'transactionId',
        headerName: 'Transaction ID',
        width: 200,
        cellRenderer: (params: any) => (
            <FWTypography>{params.data.transactionId}</FWTypography>
        ),
        sortable: true,
        filter: true,
        valueGetter: (params) => params.data.transactionId,
        getQuickFilterText: (params) => params.data.transactionId,
    },
    {
        field: 'paymentType',
        headerName: 'Payment type',
        width: 160,
        cellRenderer: (params: any) => (
            <FWTypography>{params.data.paymentType}</FWTypography>
        ),
        sortable: true,
        filter: true,
        valueGetter: (params) => params.data.paymentType,
        getQuickFilterText: (params) => params.data.paymentType,
    },
    {
        field: 'amountPaid',
        headerName: 'Total amount',
        width: 180,
        cellRenderer: PaymentCompletedTotalAmountCell,
        sortable: true,
        filter: true,
        valueGetter: (params) => params.data.amountPaid,
        getQuickFilterText: (params) => params.data.amountPaid,
    },
];

export const paymentsHeaderTabs = [
    {
        name: 'pending',
        color: '#007aff',
        id: 'paymentsDashboard_pendingTab',
    },
    {
        name: 'completed',
        color: '#007aff',
        id: 'paymentsDashboard_completedTab',
    },
];

export const paymentStatusMap: { [key: string]: string } = {
    COMPLETED: 'Completed',
};

export const creditTypeMap: { [key: string]: string } = {
    GR_REJECTIONS: CreditType.GR_REJECTIONS,
    QC_REJECTIONS: CreditType.QC_REJECTIONS,
    INVOICE_ITEM_TERMINATION: CreditType.INVOICE_ITEM_TERMINATION,
    PRE_PAYMENT: CreditType.PREPAYMENT,
};

export const paymentsPendingDefaultColumns = (allowPaymentBeforeQcForEntity: {
    [entityUid: string]: boolean;
}): ColDef[] => {
    return [
        {
            field: 'selection',
            headerName: '',
            width: 50,
            cellRenderer: (params: any) => '',
            checkboxSelection: true,
        },
        {
            field: 'entityName',
            headerName: 'Entity',
            width: 180,
            cellRenderer: ({ data }: { data: IDefaultViewPaymentItem }) => (
                <FWTypography>{data.entityName}</FWTypography>
            ),
            sortable: true,
            filter: true,
            valueGetter: ({ data }: { data: IDefaultViewPaymentItem }) =>
                data.entityName,
            getQuickFilterText: ({ data }: { data: IDefaultViewPaymentItem }) =>
                data.entityName,
        },
        {
            field: 'poId',
            headerName: 'PO ID',
            width: 180,
            cellRenderer: ({ data }: { data: IDefaultViewPaymentItem }) => {
                const poWarnings = generatePOWarnings(data);
                return (
                    <div
                        className="flex flex--aic"
                        style={{
                            width: '100%',
                        }}
                    >
                        <FWTypography sx={{ maxWidth: '100%' }}>
                            {data.poId}
                        </FWTypography>
                        {poWarnings.warnings.length > 0 && (
                            <div className="ml--5">
                                <FWMultipleTooltipMessages
                                    messages={poWarnings.warnings}
                                    iconColor={poWarnings.color + '.main'}
                                    icon={
                                        <div style={{ lineHeight: 0 }}>
                                            <DotBadge
                                                color={poWarnings.color}
                                            />
                                        </div>
                                    }
                                />
                            </div>
                        )}
                    </div>
                );
            },
            sortable: true,
            filter: true,
            valueGetter: ({ data }: { data: IDefaultViewPaymentItem }) =>
                data.poId,
            getQuickFilterText: ({ data }: { data: IDefaultViewPaymentItem }) =>
                data.poId,
        },
        {
            field: 'invoiceId',
            headerName: 'Invoice ID',
            width: 250,
            cellRenderer: (params: any) => (
                <PrepaymentPendingInvoicesDetailCell
                    data={params.data}
                    context={params.context}
                    allowPaymentBeforeQcForEntity={
                        allowPaymentBeforeQcForEntity
                    }
                />
            ),
            sortable: true,
            filter: true,
            valueGetter: ({ data }: { data: IDefaultViewPaymentItem }) =>
                data.invoiceId,
            getQuickFilterText: ({ data }: { data: IDefaultViewPaymentItem }) =>
                data.invoiceId,
        },
        {
            field: 'vendorName',
            headerName: 'Vendor name',
            minWidth: 180,
            flex: 1,
            cellRenderer: ({ data }: { data: IDefaultViewPaymentItem }) => (
                <FWTypography>{data.vendorName}</FWTypography>
            ),
            sortable: true,
            filter: true,
            valueGetter: ({ data }: { data: IDefaultViewPaymentItem }) =>
                data.vendorName,
            getQuickFilterText: ({ data }: { data: IDefaultViewPaymentItem }) =>
                data.vendorName,
        },
        {
            field: 'items',
            headerName: 'Item(s)',
            minWidth: 110,
            flex: 1,
            cellRenderer: ({ data }: { data: IDefaultViewPaymentItem }) => {
                return (
                    <div
                        className="flex flex--aic"
                        style={{
                            width: '100%',
                        }}
                    >
                        <FWMultipleTooltipMessages
                            messages={Array.from(data.itemNames)}
                            iconColor={'primary.main'}
                            icon={
                                <div style={{ lineHeight: 0 }}>
                                    {data.items}
                                </div>
                            }
                        />
                    </div>
                );
            },
            sortable: true,
            filter: true,
            valueGetter: ({ data }: { data: IDefaultViewPaymentItem }) =>
                data.items + '',
            getQuickFilterText: ({ data }: { data: IDefaultViewPaymentItem }) =>
                data.items + '',
        },
        {
            field: 'itemNames',
            headerName: 'itemNames',
            width: 110,
            sortable: true,
            filter: true,
            valueGetter: ({ data }: { data: IDefaultViewPaymentItem }) =>
                Array.from(data.itemNames).join(' '),
            getQuickFilterText: ({ data }: { data: IDefaultViewPaymentItem }) =>
                Array.from(data.itemNames).join(' '),
            hide: true,
        },
        {
            field: 'paymentDueDate',
            headerName: 'Payment due',
            width: 170,
            cellRenderer: ({ data }: { data: IDefaultViewPaymentItem }) => (
                <>
                    {data.paymentType === PaymentItemType.PREPAYMENT ? (
                        <FWTypography color="success.main">
                            {data.paymentType}
                        </FWTypography>
                    ) : data.dueDate === null ? (
                        <FWTypography>-</FWTypography>
                    ) : data.dueDate < 0 ? (
                        <FWTypography color={'error.main'}>
                            Overdue
                        </FWTypography>
                    ) : (
                        <FWTypography
                            color={
                                data.dueDate < 7
                                    ? 'warning.main'
                                    : 'success.main'
                            }
                        >
                            {data.dueDate + ' day'}
                            {data.dueDate === 1 ? '' : 's'}
                        </FWTypography>
                    )}
                </>
            ),
            sortable: true,
            filter: true,
            valueGetter: ({ data }: { data: IDefaultViewPaymentItem }) =>
                data.paymentType === PaymentItemType.PREPAYMENT
                    ? data.paymentType
                    : data.dueDate + '',
            getQuickFilterText: ({ data }: { data: IDefaultViewPaymentItem }) =>
                data.paymentType === PaymentItemType.PREPAYMENT
                    ? data.paymentType
                    : data.dueDate + '',
            comparator: function (number1, number2) {
                const x = number1 === 'Prepayment' ? 0 : number1;
                const y = number2 === 'Prepayment' ? 0 : number2;
                return parseFloat(x) - parseFloat(y);
            },
        },
        {
            field: 'totalAmount',
            headerName: 'Total amount',
            width: 160,
            cellRenderer: ({ data }: { data: IDefaultViewPaymentItem }) => {
                return (
                    <FWTypography
                        sx={{
                            width: '100%',
                            textAlign: 'right',
                        }}
                    >
                        {FWNumberFormatter(data.totalAmount, data.currency)}
                    </FWTypography>
                );
            },
            sortable: true,
            filter: true,
            valueGetter: ({ data }: { data: IDefaultViewPaymentItem }) =>
                data.totalAmount + '',
            getQuickFilterText: ({ data }: { data: IDefaultViewPaymentItem }) =>
                data.totalAmount + '',
        },
        {
            field: 'amountPending',
            headerName: 'Amount pending',
            width: 180,
            cellRenderer: ({ data }: { data: IDefaultViewPaymentItem }) => {
                return (
                    <FWTypography
                        sx={{
                            width: '100%',
                            textAlign: 'right',
                        }}
                    >
                        {FWNumberFormatter(data.pendingAmount, data.currency)}
                    </FWTypography>
                );
            },
            sortable: true,
            filter: true,
            valueGetter: ({ data }: { data: IDefaultViewPaymentItem }) =>
                data.pendingAmount + '',
            getQuickFilterText: ({ data }: { data: IDefaultViewPaymentItem }) =>
                data.pendingAmount + '',
        },
    ];
};

export const paymentsPendingInvoiceColumns = (allowPaymentBeforeQcForEntity: {
    [entityUid: string]: boolean;
}): ColDef[] => {
    return [
        {
            field: 'selection',
            headerName: '',
            width: 50,
            cellRenderer: (params: any) => '',
            checkboxSelection: true,
        },
        {
            field: 'entityName',
            headerName: 'Entity',
            width: 180,
            cellRenderer: ({ data }: { data: IInvoiceViewPaymentItem }) => (
                <FWTypography>{data.entityName}</FWTypography>
            ),
            sortable: true,
            filter: true,
            valueGetter: ({ data }: { data: IInvoiceViewPaymentItem }) =>
                data.entityName,
            getQuickFilterText: ({ data }: { data: IInvoiceViewPaymentItem }) =>
                data.entityName,
        },
        {
            field: 'poId',
            headerName: 'PO ID',
            width: 180,
            cellRenderer: ({ data }: { data: IInvoiceViewPaymentItem }) => {
                const poWarnings = generatePOWarnings(data);
                return (
                    <div
                        className="flex flex--aic"
                        style={{
                            width: '100%',
                        }}
                    >
                        <FWTypography sx={{ maxWidth: '100%' }}>
                            {data.poId}
                        </FWTypography>
                        {poWarnings.warnings.length > 0 && (
                            <div className="ml--5">
                                <FWMultipleTooltipMessages
                                    messages={poWarnings.warnings}
                                    iconColor={poWarnings.color + '.main'}
                                    icon={
                                        <div style={{ lineHeight: 0 }}>
                                            <DotBadge
                                                color={poWarnings.color}
                                            />
                                        </div>
                                    }
                                />
                            </div>
                        )}
                    </div>
                );
            },
            sortable: true,
            filter: true,
            valueGetter: ({ data }: { data: IInvoiceViewPaymentItem }) =>
                data.poId,
            getQuickFilterText: ({ data }: { data: IInvoiceViewPaymentItem }) =>
                data.poId,
        },
        {
            field: 'invoiceId',
            headerName: 'Invoice ID',
            width: 250,
            cellRenderer: (params: any) => (
                <PrepaymentPendingInvoicesDetailCell
                    data={params.data}
                    context={params.context}
                    allowPaymentBeforeQcForEntity={
                        allowPaymentBeforeQcForEntity
                    }
                />
            ),
            sortable: true,
            filter: true,
            valueGetter: ({ data }: { data: IInvoiceViewPaymentItem }) =>
                data.invoiceId,
            getQuickFilterText: ({ data }: { data: IInvoiceViewPaymentItem }) =>
                data.invoiceId,
        },
        {
            field: 'vendorName',
            headerName: 'Vendor name',
            minWidth: 180,
            flex: 1,
            cellRenderer: ({ data }: { data: IInvoiceViewPaymentItem }) => (
                <FWTypography>{data.vendorName}</FWTypography>
            ),
            sortable: true,
            filter: true,
            valueGetter: ({ data }: { data: IInvoiceViewPaymentItem }) =>
                data.vendorName,
            getQuickFilterText: ({ data }: { data: IInvoiceViewPaymentItem }) =>
                data.vendorName,
        },
        {
            field: 'items',
            headerName: 'Item(s)',
            minWidth: 110,
            flex: 1,
            cellRenderer: ({ data }: { data: IInvoiceViewPaymentItem }) => {
                return (
                    <div
                        className="flex flex--aic"
                        style={{
                            width: '100%',
                        }}
                    >
                        <FWMultipleTooltipMessages
                            messages={Array.from(data.itemNames)}
                            iconColor={'primary.main'}
                            icon={
                                <div style={{ lineHeight: 0 }}>
                                    {data.items}
                                </div>
                            }
                            title={
                                data.itemNames.size === data.items
                                    ? ''
                                    : 'Items with pending payment:'
                            }
                        />
                    </div>
                );
            },
            sortable: true,
            filter: true,
            valueGetter: ({ data }: { data: IInvoiceViewPaymentItem }) =>
                data.items + '',
            getQuickFilterText: ({ data }: { data: IInvoiceViewPaymentItem }) =>
                data.items + '',
        },
        {
            field: 'itemNames',
            headerName: 'itemNames',
            minWidth: 110,
            flex: 1,

            sortable: true,
            filter: true,
            valueGetter: ({ data }: { data: IInvoiceViewPaymentItem }) =>
                Array.from(data.itemNames).join(' '),
            getQuickFilterText: ({ data }: { data: IInvoiceViewPaymentItem }) =>
                Array.from(data.itemNames).join(' '),
            hide: true,
        },
        {
            field: 'paymentDueDate',
            headerName: 'Earliest payment due',
            width: 170,
            cellRenderer: ({ data }: { data: IInvoiceViewPaymentItem }) => (
                <>
                    {data.paymentType === PaymentItemType.PREPAYMENT ? (
                        <FWTypography color="success.main">
                            {data.paymentType}
                        </FWTypography>
                    ) : data.dueDate === null ? (
                        <FWTypography>-</FWTypography>
                    ) : data.dueDate < 0 ? (
                        <FWTypography color={'error.main'}>
                            Overdue
                        </FWTypography>
                    ) : (
                        <FWTypography
                            color={
                                data.dueDate < 7
                                    ? 'warning.main'
                                    : 'success.main'
                            }
                        >
                            {data.dueDate + ' day'}
                            {data.dueDate === 1 ? '' : 's'}
                        </FWTypography>
                    )}
                </>
            ),
            sortable: true,
            filter: true,
            valueGetter: ({ data }: { data: IInvoiceViewPaymentItem }) =>
                data.paymentType === PaymentItemType.PREPAYMENT
                    ? data.paymentType
                    : data.dueDate + '',
            getQuickFilterText: ({ data }: { data: IInvoiceViewPaymentItem }) =>
                data.paymentType === PaymentItemType.PREPAYMENT
                    ? data.paymentType
                    : data.dueDate + '',
            comparator: function (number1, number2) {
                const x = number1 === 'Prepayment' ? 0 : number1;
                const y = number2 === 'Prepayment' ? 0 : number2;
                return parseFloat(x) - parseFloat(y);
            },
        },
        {
            field: 'totalAmount',
            headerName: 'Total amount',
            width: 160,
            cellRenderer: ({ data }: { data: IInvoiceViewPaymentItem }) => {
                return (
                    <FWTypography
                        sx={{
                            width: '100%',
                            textAlign: 'right',
                        }}
                    >
                        {FWNumberFormatter(data.totalAmount, data.currency)}
                    </FWTypography>
                );
            },
            sortable: true,
            filter: true,
            valueGetter: ({ data }: { data: IInvoiceViewPaymentItem }) =>
                data.totalAmount + '',
            getQuickFilterText: ({ data }: { data: IInvoiceViewPaymentItem }) =>
                data.totalAmount + '',
        },
        {
            field: 'amountPending',
            headerName: 'Amount pending',
            width: 180,
            cellRenderer: ({ data }: { data: IInvoiceViewPaymentItem }) => {
                return (
                    <FWTypography
                        sx={{
                            width: '100%',
                            textAlign: 'right',
                        }}
                    >
                        {FWNumberFormatter(data.pendingAmount, data.currency)}
                    </FWTypography>
                );
            },
            sortable: true,
            filter: true,
            valueGetter: ({ data }: { data: IInvoiceViewPaymentItem }) =>
                data.pendingAmount + '',
            getQuickFilterText: ({ data }: { data: IInvoiceViewPaymentItem }) =>
                data.pendingAmount + '',
        },
    ];
};
