import { cloneDeep, isEmpty } from 'lodash';
import moment from 'moment';
import { useMemo } from 'react';
import { toast } from 'react-toastify';
import {
    AllocationTypeEnum,
    CostSourceEnum,
    CostTypeEnum,
    IAdditionalCostsBackend,
} from '../../../AdditionalCost/models/AdditionalCost.model';
import { TemplateData } from '../../../BuyerTemplates/Interfaces/TemplatePageInterfaces';
import { getFWDateTime } from '../../../Common/Utils/DateUtils';
import { EntityStatus } from '../../../Global/Interfaces/EntityInterface';
import { IRFQTemplateBackendResponse } from '../../../Global/Interfaces/TemplateInterface';
import {
    ICustomAttributeNew,
    ICustomAttributePayloadNew,
} from '../../../Models/Attributes.model';
import { DashBoardFilter } from '../../../ProjectGlCostCenter/interface/project.model';
import { baseApiSlice } from '../../../Redux/Slices/BaseApiSlice';
import { VerificationStatus } from '../../../Redux/Slices/VerificationSlice';
import { del, get, post, put } from '../../../Utils/api';
import { BuyerSellerStatus } from '../../SellerAdmin/BuyerMaster/Interfaces/SellerAdminBuyerInterfaces.model';
import { ILinkItemToTag } from '../Components/ItemDirectory/LinkItemsToVendor';
import {
    itemEntityStatusStatusMapService,
    prefVenAssgnStatusMapService,
    preferredVendorStatusMapService,
} from '../Constants/ItemDirectoryConstants';
import { convertAdditionalCostInterface } from '../Helpers/ItemHelpers';
import {
    IItemCustomFieldsBackend,
    IItemCustomId,
    IItemDetails,
    IItemEntityDetail,
    IItemMeasurementUnits,
    IItemSummary,
    IMeasurement,
    IMeasurementUnitSynonym,
    IPreferredVendor,
    ItemEntityStatus,
    ItemStatus,
    PreferredVendorStatus,
} from '../Interfaces/ItemDirectoryInterface';

export interface IItemTemplateData {
    created_on: string;
    creator_name: string;
    description: string;
    entity_id: string;
    entity_name: string;
    is_default: boolean;
    is_global: boolean;
    last_edited_by_name: string;
    last_edited_on: string;
    name: string;
    published_on: string | null;
    status: string;
    tags: string[];
    template_id: string;
    type: string;
}

export interface IITemTemplateFieldResponse {
    template_id: string;
    section_list: ISectionList[];
    entity: Entity;
    last_edited_by_name: string;
    created_datetime: Date;
    modified_datetime: Date;
    deleted_datetime: null;
    name: string;
    type: string;
    description: string;
    tags: any[];
    is_default: boolean;
    is_global: boolean;
    status: string;
    published_on: null;
    created_on: Date;
    last_edited_on: Date;
    created_by_user: string;
    modified_by_user: string;
    deleted_by_user: null;
    enterprise: string;
    creator: string;
    last_edited_by: string;
}

export interface Entity {
    entity_name: string;
    entity_id: string;
}

export interface ISectionList {
    section_id: string;
    section_items: SectionItem[];
    created_datetime: string;
    modified_datetime: string | null;
    deleted_datetime: string | null;
    name: string;
    alternate_name: string;
    section_type: string;
    description: string;
    sequence: number;
    is_visible: boolean;
    is_optional: boolean;
    created_by_user: string;
    modified_by_user: string;
    deleted_by_user: string | null;
    template: string;
}

export interface SectionItem {
    section_item_id: string;
    options: any[] | null;
    created_datetime: string;
    modified_datetime: string | null;
    deleted_datetime: string | null;
    sequence: number;
    name: string;
    alternate_name: string;
    description: string;
    constraints: IConstraints;
    can_remove: boolean;
    is_builtin_field: boolean;
    is_required: boolean;
    is_variable: boolean;
    additional_information: IAdditionalInformation;
    created_by_user: string;
    modified_by_user: string;
    deleted_by_user: string | null;
    section: string;
    parent_section_item: null | string;
}

export interface IAdditionalInformation {
    is_hidden: boolean;
    additional_cost_information?: IAdditionalCostInformation;
    is_visible?: boolean;
    is_negotiable?: boolean;
}

export interface IAdditionalCostInformation {
    cost_type?: CostTypeEnum;
    allocation_type?: AllocationTypeEnum;
    additional_cost_id?: string;
    cost_source?: CostSourceEnum | null;
}

export interface IConstraints {
    max_limit?: number;
    min_limit?: number;
    field_type: string;
    decimal_places?: number;
    choices?: string[];
    selected?: string | null;
    choice_type?: string;
}

export interface ITemplateResponse {
    type: string;
    count: number;
    templates: IItemTemplateData[];
}

export interface IItemLinkData {
    name: string;
    tag_id: string;
    // item_count: number;
    // vendor_count: number;
    created_by_user_name?: string;
    created_datetime: string;
    modified_datetime: string;
}

interface IItemTagsCount {
    all: number;
    active: number;
    disabled: number;
}

export interface IItemLinkPageDetails {
    current_page: number;
    has_next: boolean;
    has_previous: boolean;
    total_pages: number;
    count: number;
    page_range: {
        start: number;
        stop: number;
    };
}

interface IFetchItemLinkData {
    data: IItemLinkData[];
    metadata: IItemLinkPageDetails;
    counts: IItemTagsCount;
}

export interface ITag {
    created_by_user: string | null;
    deleted_by_user: string | null;
    deleted_datetime: string | null;
    modified_by_user: string | null;
    modified_datetime: string;
    enterprise: string;
    created_datetime: string;
    name: string;
    tag_id: string;
    tag_type: 'ITEM';
}

export interface IEntity {
    entity_id: string;
    created_datetime: string;
    modified_datetime: string;
    deleted_datetime: string | null;
    entity_name: string;
    entity_description: string;
    entity_doing_business_as: string;
    buyer_status: BuyerSellerStatus;
    seller_status: BuyerSellerStatus;
    verification_status: VerificationStatus;
    setup_information: {
        buyer: {
            item: boolean;
            vendor: boolean;
            identification: boolean;
            billing_address: boolean;
            shipping_address: boolean;
            terms_and_conditions: boolean;
        };
        seller: {
            identification: boolean;
            primary_address: boolean;
        };
    };
    is_virtual: boolean;
    is_user_email_verified: boolean;
    contacts: {
        emails: string[];
        websites: string[];
        phone_numbers: string[];
    };
    created_by_user: string;
    modified_by_user: string;
    deleted_by_user: string | null;
    enterprise: string;
    default_procurement_currency: string;
}

export type IVendorList = {
    entity_vendor_master_id: string;
    seller_entity: {
        entity_id: string;
        enterprise: string;
        entity_name: string;
        entity_logo: Array<{
            attachment_id: string;
            attachment_type: string;
            storage_id: string;
            resource_id: string;
            file_name: string;
            attachment_status: string;
        }>;
        entity_description: string | null;
        buyer_status: EntityStatus | null;
        seller_status: EntityStatus | null;
        contacts: {
            emails: Array<any>;
            websites: Array<any>;
            phone_numbers: Array<any>;
        } | null;
        verification_status: VerificationStatus;
        is_virtual: boolean;
    };
    enterprise_vendor_master: {
        enterprise_vendor_master_id: string;
        vendor_code: string;
        vendor_name: string;
        status: string;
        primary_vendor_contact: {
            vendor_contact_id: string;
            buyer_entity: string | null;
            seller_entity: string;
            full_name: string;
            primary_email: string;
            emails: Array<string> | null;
            phone_numbers: Array<string> | null;
            tags: Array<string> | null;
            is_primary: boolean;
            user: string;
            status: string;
        };
        secondary_vendor_contacts: Array<{
            vendor_contact_id: string;
            buyer_entity: string;
            seller_entity: string;
            full_name: string;
            primary_email: string;
            emails: Array<string> | null;
            phone_numbers: Array<string> | null;
            tags: Array<string> | null;
            is_primary: boolean;
            user: string;
            status: string;
        }>;
    };
    primary_vendor_contact: {
        vendor_contact_id: string;
        buyer_entity: string;
        seller_entity: string;
        full_name: string;
        primary_email: string;
        emails: Array<string> | null;
        phone_numbers: Array<string> | null;
        tags: Array<string> | null;
        is_primary: boolean;
        user: string;
        status: string;
    };
    created_datetime: string;
    modified_datetime: string;
    deleted_datetime: string | null;
    status: string;
    created_by_user: string | null;
    modified_by_user: string | null;
    deleted_by_user: string | null;
    buyer_enterprise: string;
    buyer_entity: string;
    seller_enterprise: string;
    enterprise_item: string | null;
};

export interface IPreferredVendorList {
    vendor_name: string;
    vendor_code: string;
    seller_entity_id: string;
    seller_entity_name: string;
    seller_entity_verification_status: VerificationStatus;
    item_count: number | null;
}

export const oldfetchItemsList = async (): Promise<IItemSummary[]> => {
    const response: any = await get<any>(`/organization/items/admin/`);
    const data: any[] = response.data;
    if (data) {
        return data
            .sort((a, b) => {
                if (
                    moment(a.created_datetime).isBefore(
                        moment(b.created_datetime)
                    )
                ) {
                    return 1;
                }
                if (
                    moment(a.created_datetime).isAfter(
                        moment(b.created_datetime)
                    )
                ) {
                    return -1;
                }
                return 0;
            })
            .sort((a, b) => {
                if (a.name.toLowerCase() < b.name.toLowerCase()) {
                    return -1;
                }
                if (a.name.toLowerCase() > b.name.toLowerCase()) {
                    return 1;
                }
                return 0;
            })
            .map((item, index: number) => {
                const newItem: IItemSummary = {
                    index,
                    id: item.enterprise_item_id,
                    itemCode: item.code,
                    name: item.name,
                    entities: item.entities.map(
                        (entity: any) => entity.buyer_entity_name
                    ),
                    entities_id: item.entities,
                    lastPrice: item.lastPrice,
                    status: item.status,
                    locked: item.locked,
                    description: item.description || '-',
                    specifications: item.attributes.attributes.map(
                        (attribute: any) => ({
                            name: attribute.attribute_name,
                            value: attribute.attribute_value,
                        })
                    ),
                    measurementUnits:
                        item.measurement_units.item_measurement_units.map(
                            (unit: any) => unit.measurement_unit_id
                        ),
                    notes: item.notes,
                    tags: item.tags,
                    customIds: Array.from(item.custom_ids ?? []).map(
                        (customId: any) => {
                            return {
                                category: customId.category,
                                name: customId.name,
                                value: customId.value,
                            };
                        }
                    ),
                    preferredVendorAssignedStatus:
                        prefVenAssgnStatusMapService[
                            item.preferred_vendor_status
                        ],
                    preferredVendorsCount: 0, // not needed in global dashboard
                    preferredVendors: [], // not needed in global dashboard
                    entityStatus: ItemEntityStatus.ACTIVE, // not needed in global dashboard
                    modifiedDateTime: getFWDateTime(item.modified_datetime),
                    buyerPricingInformation: null,
                    sellerPricingInformation: null,
                    isBuyer: false,
                    isSeller: false,
                    bom_template: item.bom_template,
                };
                return newItem;
            });
    }
    return data;
};

export interface ISingleItemDashBoardRespone {
    bom_template: { template_id: string } | null;
    enterprise_item_id: string;
    entity_count: number;
    entities: {
        buyer_entity_name: string;
        buyer_entity_id: string;
    }[];
    created_datetime: string;
    modified_datetime: string;
    deleted_datetime: any;
    code: string;
    name: string;
    description: string;
    notes: string;
    measurement_units: {
        item_measurement_units: {
            measurement_unit_id: string;
            measurement_unit_category: string;
            measurement_unit_value_type: string;
            measurement_unit_primary_name: string;
        }[];
    };
    attributes: ICustomAttributeNew[];
    custom_ids: {
        name: string;
        value: string;
    }[];
    pricing_information: {
        price: any;
        currency_name: string;
        currency_symbol: string;
        currency_code_id: string;
        currency_code_abbreviation: string;
    };
    status: ItemStatus;
    preferred_vendor_status: string;
    tags: string[];
    created_by_user: string;
    modified_by_user: string;
    deleted_by_user: any;
    buyer_enterprise: string;
    item_type: 'FINISHED_GOOD' | 'RAW_MATERIAL';
}
export interface ItemDashBoardResponse {
    data: ISingleItemDashBoardRespone[];
    metadata: {
        current_page: number;
        has_next: boolean;
        has_previous: boolean;
        total_pages: number;
        count: number;
    };
    total_count: number;
}
export const fetchItemsList = async ({
    tab,
    sortFields,
    searchText,
    pageNumber,
    itemsPerPage,
    filters,
}: {
    tab: string;
    sortFields: {
        field: string;
        ascending: boolean;
    }[];
    searchText: string;
    pageNumber: number;
    itemsPerPage: number;
    filters: DashBoardFilter | null;
}): Promise<{
    items: IItemSummary[];
    pageDetails: {
        currentPage: number;
        totalPages: number;
        count: number;
        total_count: number;
    };
}> => {
    const response: any = await post<any, any>(`/dashboard/`, {
        dashboard_view: 'enterprise_item',
        tab: tab,
        sort_fields: sortFields,
        search_text: searchText,
        items_per_page: itemsPerPage,
        page_number: pageNumber,
        filters: filters,
    });
    const data: ItemDashBoardResponse = response.data;
    let items: IItemSummary[] = [];
    let pageDetails: {
        currentPage: number;
        totalPages: number;
        count: number;
        total_count: number;
    } = {
        currentPage: 0,
        totalPages: 0,
        count: 0,
        total_count: 0,
    };
    if (data) {
        console.log('meow meow', data.data);
        items = data.data.map((item: any, index: number) => {
            const newItem: IItemSummary = {
                index,
                id: item.enterprise_item_id,
                itemCode: item.code,
                name: item.name,
                entities: item.entities.map(
                    (entity: any) => entity.buyer_entity_name
                ),
                entities_id: item.entities,
                lastPrice: '',
                status: item.status,
                locked: false,
                description: item.description || '-',
                specifications: item.attributes,

                // specifications: item.attributes.attributes.map(
                //     (attribute: any) => ({
                //         name: attribute.attribute_name,
                //         value: attribute.attribute_value,
                //     })
                // ),
                measurementUnits:
                    item.measurement_units.item_measurement_units.map(
                        (unit: any) => unit.measurement_unit_id
                    ),
                notes: item.notes,
                tags: item.tags,
                customIds: Array.from(item?.custom_ids?.custom_ids ?? []).map(
                    (customId: any) => {
                        return {
                            // category: customId.category,
                            name: customId.name,
                            value: customId.value,
                        };
                    }
                ),
                preferredVendorAssignedStatus:
                    prefVenAssgnStatusMapService[item.preferred_vendor_status],
                preferredVendorsCount: 0, // not needed in global dashboard
                preferredVendors: [], // not needed in global dashboard
                entityStatus: ItemEntityStatus.ACTIVE, // not needed in global dashboard
                modifiedDateTime: getFWDateTime(item.modified_datetime),
                item_type: item.item_type,
                buyerPricingInformation: item.buyer_pricing_information,
                sellerPricingInformation: item.seller_pricing_information,
                isBuyer: item.is_buyer,
                isSeller: item.is_seller,
                bom_template: item.bom_template,
            };
            return newItem;
        });

        pageDetails = {
            currentPage: data.metadata.current_page,
            totalPages: data.metadata.total_pages,
            count: data.metadata.count,
            total_count: response.data.counts.all,
        };
    }

    return {
        items: items,
        pageDetails: pageDetails,
    };
};

// TODO: Add Response Interface
export const newFetchEntityItemsList = async ({
    sortFields,
    searchText,
    pageNumber,
    itemsPerPage,
    entityUid,
}: {
    sortFields: {
        field: string;
        ascending: boolean;
    }[];
    searchText: string;
    pageNumber: number;
    itemsPerPage: number;
    entityUid: string;
}): Promise<{
    items: IItemSummary[];
    pageDetails: {
        currentPage: number;
        totalPages: number;
        count: number;
    };
}> => {
    const response: any = await post<any, any>(`/dashboard/`, {
        dashboard_view: 'entity_item',
        tab: 'active',
        sort_fields: sortFields,
        search_text: searchText,
        items_per_page: itemsPerPage,
        page_number: pageNumber,
        query_data: {
            entity_id: entityUid,
        },
    });
    const data: any = response.data;
    let items: IItemSummary[] = [];
    let pageDetails: {
        currentPage: number;
        totalPages: number;
        count: number;
    } = {
        currentPage: 0,
        totalPages: 0,
        count: 0,
    };
    if (data) {
        items = data.data.map((item: any, index: number) => {
            const newItem: IItemSummary = {
                index,
                id: item.enterprise_item.enterprise_item_id,
                itemCode: item.enterprise_item.code,
                name: item.enterprise_item.name,
                entities: [],
                entities_id: [],
                lastPrice: '',
                status: item.enterprise_item.status,
                locked: false,
                description: item.enterprise_item.description || '-',
                specifications: item.enterprise_item.attributes.attributes.map(
                    (attribute: any) => ({
                        name: attribute.attribute_name,
                        value: attribute.attribute_value,
                    })
                ),
                measurementUnits:
                    item.enterprise_item.measurement_units.item_measurement_units.map(
                        (unit: any) => unit.measurement_unit_id
                    ),
                notes: item.enterprise_item.notes,
                tags: item?.enterprise_item?.tags,
                customIds: Array.from(
                    item.enterprise_item.custom_ids ?? []
                ).map((customId: any) => {
                    return {
                        category: customId.category,
                        name: customId.name,
                        value: customId.value,
                    };
                }),
                preferredVendorAssignedStatus:
                    prefVenAssgnStatusMapService[
                        item.enterprise_item.preferred_vendor_status
                    ],
                preferredVendorsCount: item.preferred_vendors_count, // not needed in global dashboard
                preferredVendors: [], // not needed in global dashboard
                entityStatus: ItemEntityStatus.ACTIVE, // not needed in global dashboard
                modifiedDateTime: getFWDateTime(item.modified_datetime),
                buyerPricingInformation: item.buyer_pricing_information,
                sellerPricingInformation: item.seller_pricing_information,
                isBuyer: item.is_buyer,
                isSeller: item.is_seller,
                bom_template: item.bom_template,
            };
            return newItem;
        });

        pageDetails = {
            currentPage: data.metadata.current_page,
            totalPages: data.metadata.total_pages,
            count: data.metadata.count,
        };
    }

    return {
        items: items,
        pageDetails: pageDetails,
    };
};

// TODO: Add interface for response
export const fetchNotInEntityItemsList = async ({
    sortFields,
    searchText,
    pageNumber,
    itemsPerPage,
    entityUid,
}: {
    sortFields: {
        field: string;
        ascending: boolean;
    }[];
    searchText: string;
    pageNumber: number;
    itemsPerPage: number;
    entityUid: string;
}): Promise<{
    items: IItemSummary[];
    pageDetails: {
        currentPage: number;
        totalPages: number;
        count: number;
    };
}> => {
    const response: any = await post<any, any>(`/dashboard/`, {
        dashboard_view: 'non_entity_enterprise_item',
        tab: 'all',
        sort_fields: sortFields,
        search_text: searchText,
        items_per_page: itemsPerPage,
        page_number: pageNumber,
        query_data: {
            entity_id: entityUid,
        },
    });
    const data: ItemDashBoardResponse = response.data;
    let items: IItemSummary[] = [];
    let pageDetails: {
        currentPage: number;
        totalPages: number;
        count: number;
    } = {
        currentPage: 0,
        totalPages: 0,
        count: 0,
    };
    if (data) {
        items = data.data.map((item, index: number) => {
            const newItem: IItemSummary = {
                index,
                id: item.enterprise_item_id,
                itemCode: item.code,
                name: item.name,
                entities: item.entities.map(
                    (entity: any) => entity.buyer_entity_name
                ),
                entities_id: item.entities,
                lastPrice: '',
                status: item.status,
                locked: false,
                description: item.description || '-',
                specifications: item.attributes,
                // specifications: item.attributes.attributes.map(
                //     (attribute: any) => ({
                //         name: attribute.attribute_name,
                //         value: attribute.attribute_value,
                //     })
                // ),
                measurementUnits:
                    item.measurement_units.item_measurement_units.map(
                        (unit: any) => unit.measurement_unit_id
                    ),
                notes: item.notes,
                tags: item.tags,
                customIds: Array.from(item.custom_ids ?? []).map(
                    (customId: any) => {
                        return {
                            category: customId.category,
                            name: customId.name,
                            value: customId.value,
                        };
                    }
                ),
                preferredVendorAssignedStatus:
                    prefVenAssgnStatusMapService[item.preferred_vendor_status],
                preferredVendorsCount: 0, // not needed in global dashboard
                preferredVendors: [], // not needed in global dashbaord
                entityStatus: ItemEntityStatus.ACTIVE, // not needed in global dashboard
                modifiedDateTime: getFWDateTime(item.modified_datetime),
                buyerPricingInformation: null,
                sellerPricingInformation: null,
                isBuyer: false,
                isSeller: false,
                bom_template: item.bom_template,
            };
            return newItem;
        });

        pageDetails = {
            currentPage: data.metadata.current_page,
            totalPages: data.metadata.total_pages,
            count: data.metadata.count,
        };
    }

    return {
        items: items,
        pageDetails: pageDetails,
    };
};

export const fetchEntityItemsList = async (
    entityUid: string
): Promise<IItemSummary[]> => {
    const response: any = await get<any>(
        `/organization/items/entity/${entityUid}/`
    );
    const data: any[] = response.data;
    if (data) {
        const items: IItemSummary[] = data
            .filter(
                (item) =>
                    item.enterprise_item.status === ItemStatus.ACTIVE &&
                    item.status === ItemEntityStatus.ACTIVE
            )
            .sort((a, b) => {
                if (
                    a.enterprise_item.name.toLowerCase() <
                    b.enterprise_item.name.toLowerCase()
                ) {
                    return -1;
                }
                if (
                    a.enterprise_item.name.toLowerCase() >
                    b.enterprise_item.name.toLowerCase()
                ) {
                    return 1;
                }
                return 0;
            })
            .map((item, index) => {
                const newItem: IItemSummary = {
                    index,
                    id: item.enterprise_item.enterprise_item_id,
                    itemCode: item.enterprise_item.code,
                    name: item.enterprise_item.name,
                    entities: [], // not needed in entity dashboard
                    entities_id: item.entities ?? [],
                    lastPrice: item.lastPrice,
                    status: item.enterprise_item.status,
                    locked: false,
                    description: item.enterprise_item.description,
                    specifications:
                        item.enterprise_item.attributes.attributes.map(
                            (attribute: any) => ({
                                name: attribute.attribute_name,
                                value: attribute.attribute_value,
                            })
                        ),
                    measurementUnits:
                        item.enterprise_item.measurement_units.item_measurement_units.map(
                            (unit: any) => unit.measurement_unit_id
                        ),
                    notes: item.enterprise_item.notes,
                    tags: item?.enterprise_item?.tags,
                    customIds: item.enterprise_item.custom_ids.custom_ids.map(
                        (customId: any) => {
                            return {
                                category: customId.category,
                                name: customId.name,
                                value: customId.value,
                            };
                        }
                    ),
                    preferredVendorAssignedStatus:
                        prefVenAssgnStatusMapService[
                            item.preferred_vendor_status
                        ],
                    preferredVendorsCount: item.preferred_vendors_count,
                    preferredVendors: [],
                    entityStatus: itemEntityStatusStatusMapService[item.status],
                    modifiedDateTime: getFWDateTime(item.modified_datetime),
                    buyerPricingInformation: null,
                    sellerPricingInformation: null,
                    isBuyer: false,
                    isSeller: false,
                    bom_template: item.bom_template,
                };
                return newItem;
            });
        return items;
    }
    return response;
};

export interface IItemValuePayload {
    template_id?: string;
    name: string;
    code: string;
    description: string;
    status: ItemStatus;
    image_id?: string[];
    rss_feed_links: string[];
    linked_currencies?: string[] | null;
    attributes: ICustomAttributePayloadNew[];
    measurement_units: string[];
    // currency_code_id: string | null;
    // price: number | null;
    buyer_pricing_information: {
        price: number | null;
        currency_code_id: string | null;
        additional_costs: IAdditionalCostsBackend[];
        taxes: IAdditionalCostsBackend[];
    };
    seller_pricing_information: {
        price: number | null;
        currency_code_id: string | null;
        additional_costs: IAdditionalCostsBackend[];
        taxes: IAdditionalCostsBackend[];
    };
    is_buyer: boolean;
    is_seller: boolean;
    notes: string | null;
    internal_notes: string | null;
    custom_fields: IItemCustomFieldsBackend;
    secondary_indices: string[];
    primary_index: string | null;
    custom_ids: {
        custom_ids: IItemCustomId[];
    };
    item_type: 'FINISHED_GOOD' | 'RAW_MATERIAL';
    tags: string[];
}

export const updateItemState = async (
    itemId: string,
    payload: {
        status: 'ACTIVE' | 'INACTIVE';
    }
): Promise<boolean> => {
    try {
        await put<any, any>(
            `/organization/items/${itemId}/admin/update/state/`,
            payload
        );
        return true;
    } catch (error) {
        return false;
    }
};

export const updateItemValue = async (
    itemId: string,
    payload: IItemValuePayload
): Promise<boolean> => {
    try {
        await put<any, any>(`/organization/items/${itemId}/admin/update/`, {
            ...payload,
            seller_pricing_information:
                JSON.stringify(payload.seller_pricing_information) === '{}'
                    ? null
                    : payload.seller_pricing_information,
            buyer_pricing_information:
                JSON.stringify(payload.buyer_pricing_information) === '{}'
                    ? null
                    : payload.buyer_pricing_information,
        });
        return true;
    } catch (error) {
        return false;
    }
};

export const updateItemStatus = async (
    itemId: string,
    payload: {
        status: 'ACTIVE' | 'INACTIVE';
    }
): Promise<boolean> => {
    try {
        await put<any, any>(
            `/organization/items/${itemId}/admin/update/state/`,
            payload
        );
        return true;
    } catch (error) {
        return false;
    }
};

export const fetchItemDetails = async (
    itemId: string
): Promise<IItemDetails> => {
    try {
        const response: any = await get<any>(
            `/organization/items/${itemId}/admin/`
        );
        const data: any = response.data;
        console.log('awdbuda', data);

        if (isEmpty(data.pricing_information)) {
            data.pricing_information = null;
        }
        if (data) {
            const additionalCost = convertAdditionalCostInterface(
                data.buyer_additional_costs
            );

            convertAdditionalCostInterface(
                data.seller_additional_costs
            ).forEach((cost) => {
                if (
                    additionalCost.find(
                        (c) => c.additional_cost_id === cost.additional_cost_id
                    ) === undefined
                ) {
                    additionalCost.push(cost);
                }
            });
            const matchData: IItemDetails = {
                id: data.enterprise_item_id,
                itemCode: data.code,
                itemName: data.name,
                bom_template: data.bom_template,
                image: data.image,
                rss_feed_links: [],
                description: data.description,
                additionalCost: additionalCost?.filter(
                    (cost) => cost.cost_value !== null
                ),
                linked_currencies: data.linked_currencies,
                primary_index: data.primary_index,
                secondary_indices: data.secondary_indices,

                internal_notes: data.internal_notes,
                custom_fields: data.custom_fields ?? [],
                notes: data.notes,
                measurementUnits:
                    data.measurement_units.item_measurement_units.map(
                        (unit: any) => {
                            const measurement: IMeasurement = {
                                id: unit.measurement_unit_id,
                                name: unit.measurement_unit_primary_name,
                                category: unit.measurement_unit_category,
                                type: unit.measurement_unit_value_type,
                                abbreviation: unit.abbreviation ?? '',
                                isVerifiedUnit: unit.is_default ?? false,
                            };
                            return measurement;
                        }
                    ),
                buyerPricingInformation: {
                    ...data.buyer_pricing_information,
                    price: data.buyer_pricing_information
                        ? data.buyer_pricing_information.price
                        : null,
                    currencyCodeId: data.buyer_pricing_information
                        ? data.buyer_pricing_information.currency_code_id
                        : null,
                    additionalCost: convertAdditionalCostInterface(
                        data.buyer_additional_costs
                    )?.filter((cost) => cost.cost_value !== null),
                },
                sellerPricingInformation: {
                    ...data.buyer_pricing_information,
                    price: data.seller_pricing_information
                        ? data.seller_pricing_information.price
                        : null,
                    currencyCodeId: data.seller_pricing_information
                        ? data.seller_pricing_information.currency_code_id
                        : null,
                    additionalCost: convertAdditionalCostInterface(
                        data.seller_additional_costs
                    )?.filter((cost) => cost.cost_value !== null),
                },
                isBuyer: data.is_buyer,
                isSeller: data.is_seller,
                standardTerms: {
                    lead: null,
                    payment: null,
                    prePayment: null,
                },
                attributes: data.attributes,
                // specifications: data.attributes.map(
                //     (attribute: ICustomAttributePayloadNew) => ({
                //         attribute_linkage_id: null,
                //         attribute_id: attribute.attribute_id || null,
                //         attribute_name: attribute.attribute_name,
                //         attribute_type: attribute.attribute_type,
                //         attribute_values: attribute.attribute_values.map(
                //             (value) => ({
                //                 attribute_value_linkage_id: null, // Set as per your requirement
                //                 attribute_value_id:
                //                     value.attribute_value_id || null,
                //                 value: value.value,
                //                 measurement_unit_id:
                //                     value.measurement_unit_id || null,
                //                 currency_id: value.currency_id || null,
                //             })
                //         ),
                //     })
                // ),

                // specifications:data.attributes
                specifications: data.attributes.map((attribute: any) => ({
                    name: attribute.attribute_name,
                    value: attribute.attribute_value,
                })),
                customIds: data.custom_ids.custom_ids.map((customId: any) => {
                    return {
                        category: customId.category,
                        name: customId.name,
                        value: customId.value,
                    };
                }),
                status: data.status,
                tags: data.tags,
                item_type: data.item_type,
            };

            return matchData;
        }
        return data;
    } catch (error) {
        let x: any;
        return x;
    }
};

export const { useFetchItemDetailsQueryQuery } = baseApiSlice.injectEndpoints({
    endpoints: (build) => ({
        fetchItemDetailsQuery: build.query<
            IItemDetails,
            {
                itemid: string;
            }
        >({
            queryFn: async ({ itemid }) => {
                try {
                    const resp = await fetchItemDetails(itemid);

                    return {
                        data: resp,
                    };
                } catch (err: any) {
                    return { error: err };
                }
            },
        }),
    }),
});

export const fetchItemMeasurements =
    async (): Promise<IItemMeasurementUnits> => {
        const response: any = await get<any>(
            `/organization/measurement_unit?status=ACTIVE`
        );

        const data: any[] = response.data;
        if (data) {
            const measurements: IItemMeasurementUnits = {};
            data.forEach((unit) => {
                measurements[unit.measurement_unit.measurement_unit_id] = {
                    id: unit.measurement_unit.measurement_unit_id,
                    name: unit.measurement_unit.measurement_unit_primary_name,
                    category: unit.measurement_unit.measurement_unit_category,
                    type: unit.measurement_unit.measurement_unit_value_type,
                    isVerifiedUnit: unit.measurement_unit.is_default ?? false,
                    abbreviation: unit.measurement_unit.abbreviation,
                };
            });

            return measurements;
        }
        return response;
    };

export const fetchMeasurementUnitSynonyms = async (): Promise<
    IMeasurementUnitSynonym[]
> => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await get<
                {
                    measurement_unit: string;
                    measurement_unit_primary_name: string;
                    synonym: string;
                }[]
            >(`/backbone/measurement_unit_synonyms`);
            const returnData: IMeasurementUnitSynonym[] = [];

            response.data.forEach((item) => {
                returnData.push({
                    synonymName: item.synonym,
                    primaryName: item.measurement_unit_primary_name,
                    measurementUnitUuid: item.measurement_unit,
                });
            });
            resolve(returnData);
        } catch (e) {
            reject(e);
        }
    });
};

export const saveNewMeasurementUnit = async (
    name: string,
    category: string,
    valueType: string,
    symbol?: string,
    synonyms?: string[],
    conversion_multiplier?: number
): Promise<{
    measurementUnitUuid: string;
}> => {
    return new Promise(async (resolve, reject) => {
        try {
            const payload = {
                measurement_unit_name: name,
                measurement_unit_category: category,
                measurement_unit_value_type:
                    valueType === 'INTEGER' ? 'INT' : 'DECIMAL',
                measurement_unit_synonyms: synonyms,
                abbreviation: symbol,
                conversion_multiplier: conversion_multiplier,
            };
            const response = await post<any, { measurement_unit_id: string }>(
                `/backbone/measurement_unit/add/`,
                payload
            );
            const data = response.data;
            resolve({ measurementUnitUuid: data.measurement_unit_id });
        } catch (e) {
            reject(e);
        }
    });
};

export interface IItemAddEditResponse {
    itemId: string;
    success: boolean;
    isFinishedGood: boolean;
}

export const addNewItemToEnterprise = async (
    payload: IItemDetails
): Promise<IItemAddEditResponse> => {
    const body: IItemValuePayload = {
        template_id: payload.template_id,
        primary_index: payload.primary_index ?? null,
        rss_feed_links: payload.rss_feed_links,
        secondary_indices: payload.secondary_indices ?? [],
        name: payload.itemName.trim(),
        code: payload.itemCode.trim(),
        description: payload.description.trim(),
        notes: payload.notes?.trim() ?? null,
        internal_notes: payload.internal_notes?.trim() ?? null,
        attributes: (payload.attributes ?? []).map((singleAttribute) => ({
            attribute_linkage_id: null,
            attribute_id: singleAttribute.attribute_id,
            attribute_name: singleAttribute.attribute_name,
            attribute_type: singleAttribute.attribute_type,
            attribute_values: singleAttribute.attribute_values.map(
                (singleVal) => ({
                    attribute_value_linkage_id:
                        singleVal.attribute_value_linkage_id || null,
                    attribute_value_id: singleVal.attribute_value_id || null,
                    value: singleVal.value,
                    measurement_unit_id:
                        singleVal.measurement_unit?.measurement_unit_id ?? null,
                    currency_id: singleVal.currency?.entry_id ?? null,
                })
            ),
        })),
        // Narutoooooooo
        // attributes: {
        //     attributes: (payload.specifications || []).map((spec) => {
        //         return {
        //             attribute_name: spec.name.trim(),
        //             attribute_value: spec.value,
        //             attribute_value_type: 'TEXT',
        //             attribute_exclude: false,
        //         };
        //     }),
        // },

        custom_ids: {
            custom_ids: payload.customIds,
        },
        custom_fields: payload.custom_fields,
        measurement_units: payload.measurementUnits.map((m) => m.id),
        // currency_code_id: payload.defaultCurrency?.entry_id || null,
        // price: payload.defaultPrice,
        tags: payload.tags,
        buyer_pricing_information: {
            price: payload.buyerPricingInformation.price,
            currency_code_id: payload.buyerPricingInformation.currencyCodeId,
            additional_costs:
                payload.buyerPricingInformation.additionalCost ?? [],
            taxes: [],
        },
        seller_pricing_information: {
            price: payload.sellerPricingInformation.price,
            currency_code_id: payload.sellerPricingInformation.currencyCodeId,
            additional_costs:
                payload.buyerPricingInformation.additionalCost ?? [],
            taxes: [],
        },
        item_type: payload.item_type ?? 'RAW_MATERIAL',
        is_buyer: payload.isBuyer,
        is_seller: payload.isSeller,
        status: payload.status,
    };

    console.log('Pokemon', cloneDeep(payload), cloneDeep(body));

    const response: any = await post<any, any>(
        `/organization/items/admin/create/`,
        body
    );
    const data: any = response.data;
    if (data.item_id) {
        return {
            itemId: data.item_id,
            isFinishedGood:
                (payload.item_type ?? 'RAW_MATERIAL') === 'FINISHED_GOOD',
            success: true,
        };
    }
    return {
        itemId: '',
        isFinishedGood: false,
        success: false,
    };
};

export const addNewItemToEntity = async (
    payload: IItemDetails,
    entity_id: string
): Promise<IItemAddEditResponse> => {
    const body: IItemValuePayload = {
        template_id: payload.template_id,
        custom_fields: payload.custom_fields,
        name: payload.itemName.trim(),
        rss_feed_links: payload.rss_feed_links,
        code: payload.itemCode.trim(),
        description: payload.description.trim(),
        notes: payload.notes?.trim() ?? null,
        internal_notes: payload.internal_notes
            ? payload.internal_notes.trim() ?? null
            : null,
        primary_index: null,
        secondary_indices: [],
        // Narutoooo
        attributes: (payload.attributes ?? []).map((singleAttribute) => ({
            attribute_linkage_id: singleAttribute.attribute_linkage_id || null,
            attribute_id: singleAttribute.attribute_id || null,
            attribute_name: singleAttribute.attribute_name,
            attribute_type: singleAttribute.attribute_type,
            attribute_values: singleAttribute.attribute_values.map(
                (singleVal) => ({
                    attribute_value_linkage_id:
                        singleVal.attribute_value_linkage_id || null,
                    attribute_value_id: singleVal.attribute_value_id || null,
                    value: singleVal.value,
                    measurement_unit_id:
                        singleVal.measurement_unit?.measurement_unit_id ?? null,
                    currency_id: singleVal.currency?.entry_id ?? null,
                })
            ),
        })),

        // attributes: {
        //     attributes: (payload.specifications || []).map((spec) => {
        //         return {
        //             attribute_name: spec.name.trim(),
        //             attribute_value: spec.value,
        //             attribute_value_type: 'TEXT',
        //             attribute_exclude: false,
        //         };
        //     }),
        // },
        custom_ids: {
            custom_ids: payload.customIds,
        },
        measurement_units: payload.measurementUnits.map((m) => m.id),
        // currency_code_id: payload.defaultCurrency?.entry_id || null,
        // price: payload.defaultPrice,
        tags: payload.tags,
        buyer_pricing_information: {
            price: payload.buyerPricingInformation.price,
            currency_code_id: payload.buyerPricingInformation.currencyCodeId,
            additional_costs: [],
            taxes: [],
        },
        seller_pricing_information: {
            price: payload.sellerPricingInformation.price,
            currency_code_id: payload.sellerPricingInformation.currencyCodeId,
            additional_costs: payload.additionalCost,
            taxes: [],
        },
        item_type: payload.item_type ?? 'RAW_MATERIAL',
        is_buyer: payload.isBuyer,
        is_seller: payload.isSeller,
        status: payload.status,
    };
    const response: any = await post<any, any>(
        `/organization/items/entity/${entity_id}/create/`,
        body
    );
    const data: any = response.data;
    if (data.item_id) {
        return {
            itemId: data.item_id,
            isFinishedGood:
                (payload.item_type ?? 'RAW_MATERIAL') === 'FINISHED_GOOD',
            success: true,
        };
    }
    return {
        itemId: '',
        isFinishedGood: false,
        success: false,
    };
};

export const fetchItemEntitiesList = async (
    itemId: string
): Promise<IItemEntityDetail[]> => {
    const response: any = await get<any>(
        `/organization/items/${itemId}/entity/`
    );
    const data: any[] = response.data;
    if (data) {
        return data.map((entity, index: number) => {
            const newEntity: IItemEntityDetail = {
                id: index,
                entityId: entity.buyer_entity,
                entityName: entity.buyer_entity_name,
                verificationStatus: VerificationStatus.UNVERIFIED,
                entityType: '',
                preferredVendors: 0,
                blockedVendors: 0,
                status: entity.status,
                linked: true,
                setupPending: false,
                createdOn: '',
                default_procurement_currency: {
                    decimals: 2,
                    entry_id: '',
                    currency_code_abbreviation: '',
                    currency_name: '',
                    currency_symbol: '',
                },
            };
            return newEntity;
        });
    }
    return response;
};

export const fetchGlobalItemTags = async (): Promise<string[]> => {
    const response: any = await get<any>(`/organization/tags?tag_type=ITEM`);
    const data: any[] = response.data;
    if (data) {
        return data.map((entity, index: number) => {
            return entity.name;
        });
    }
    return response;
};

export const updateEntitiesList = async (
    itemId: string,
    payload: {
        entityId: string;
        status: ItemEntityStatus;
    }
): Promise<boolean> => {
    try {
        await put<any, any>(
            `/organization/items/${itemId}/admin/entity/update/`,
            [
                {
                    entity_id: payload.entityId,
                    item_status: payload.status,
                },
            ]
        );
        return true;
    } catch (error) {
        return false;
    }
};

export const fetchPreferredVendors = async (
    entityUid: string,
    itemUid: string
): Promise<IPreferredVendor[]> => {
    const response: any = await get<any>(
        `/organization/vendor_master/entity/${entityUid}/item/${itemUid}/`
    );
    const data: any[] = response.data;
    if (data) {
        return data
            .filter(
                (vendor) => vendor.status === PreferredVendorStatus.PREFERRED
            )
            .map((vendor, index: number) => {
                const newVendor: IPreferredVendor = {
                    vendorName: vendor.seller_entity.entity_name,
                    vendorCode: vendor.enterprise_vendor_master.vendor_code,
                    vendorUid: vendor.seller_entity.entity_id,
                    vendorDirUid:
                        vendor.enterprise_vendor_master
                            .enterprise_vendor_master_id,
                    primaryContact: {
                        // required in API
                        fullName: 'John Doe',
                        email:
                            vendor.seller_entity.contacts &&
                            vendor.seller_entity.contacts.emails.length > 0
                                ? vendor.seller_entity.contacts.emails[0]
                                : '',
                    },
                    preferredVendorStatus:
                        preferredVendorStatusMapService[
                            PreferredVendorStatus.PREFERRED
                        ],
                };
                return newVendor;
            });
    }
    return response;
};

export const fetchItemsOrBuyersOrVendorsListCSVLink = async (
    type: 'ITEM' | 'VENDORS' | 'BUYERS',
    buyer_entity_id?: string
): Promise<any> => {
    try {
        const response: any = await post<any, any>(
            type === 'ITEM'
                ? `/export/items/download/`
                : `/export/${
                      type === 'BUYERS' ? 'buyers' : 'vendors'
                  }/download/`,
            {
                ...(buyer_entity_id ? { buyer_entity_id } : {}),
            }
        );
        const data: any = response.data;
        const getUrl = await post(`/export/url/generate/download/`, {
            export_id: data,
            resource_type:
                type === 'ITEM'
                    ? 'ITEM'
                    : type === 'BUYERS'
                    ? 'BUYER'
                    : 'VENDOR',
        });

        return getUrl.data;
    } catch (error) {}
};

export const getItemTemplateDetails = async () => {
    let res = await get<any>('/module_templates/?template_type=ITEM');

    return res.data;
};

export const getItemTemplateFields = async () => {
    let templateDetails: ITemplateResponse[] = await getItemTemplateDetails();

    //
    if (templateDetails.length > 0) {
        let res = await get<any>(
            `/module_templates/${templateDetails[0].templates[0].entity_id}/${templateDetails[0].templates[0].template_id}/`
        );

        let templateFields: IITemTemplateFieldResponse = res.data;

        //

        return {
            templateFields,
            template_id: templateDetails[0].templates[0].template_id,
        };
    }
    let templateFields: IITemTemplateFieldResponse = {
        template_id: '',
        section_list: [],
        entity: {
            entity_id: '',
            entity_name: '',
        },
        last_edited_by_name: '',
        created_datetime: new Date(),
        modified_datetime: new Date(),
        deleted_datetime: null,
        name: '',
        type: '',
        description: '',
        tags: [],
        is_default: true,
        is_global: true,
        status: '',
        published_on: null,
        created_on: new Date(),
        last_edited_on: new Date(),
        created_by_user: '',
        modified_by_user: '',
        deleted_by_user: null,
        enterprise: '',
        creator: '',
        last_edited_by: '',
    };
    return {
        templateFields,
        template_id: '',
    };
};

export const fetchItemTagsData = async ({
    search_text,
    items_per_page,
    sort_fields,
    page_number,
}: {
    page_number: number;
    items_per_page: number;
    sort_fields: { field: string; ascending: boolean }[];
    search_text: string;
}): Promise<{
    additionalCostList: IItemLinkData[];
    pageDetails: IItemLinkPageDetails;
    countDetails: IItemTagsCount;
}> => {
    let payload = {
        dashboard_view: 'linked_item_tags_vendor',
        tab: 'all',
        query_data: {
            tag_type: 'ITEM',
        },
        search_text,
        page_number,
        sort_fields,
        items_per_page,
    };

    let res = await post<IItemLinkData, IFetchItemLinkData>(
        '/dashboard/',
        payload
    );

    return {
        additionalCostList: res.data.data,
        pageDetails: res.data.metadata,
        countDetails: res.data.counts,
    };
};

export const itemDirectoryApiSlice = baseApiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getItemDirectoryTabCount: builder.mutation<
            {
                tab_counts: {
                    active: number;
                    disabled: number;
                    all: number;
                };
            },
            {}
        >({
            query: () => ({
                url: '/dashboard/tab_counts/',
                method: 'POST',
                body: {
                    dashboard_view: 'enterprise_item',
                },
            }),
        }),
        getItemTagDashboardData: builder.mutation<
            {
                additionalCostList: IItemLinkData[];
                pageDetails: IItemLinkPageDetails;
                countDetails: IItemTagsCount;
            },
            {
                page_number: number;
                items_per_page: number;
                sort_fields: { field: string; ascending: boolean }[];
                search_text: string;
            }
        >({
            query: ({
                search_text,
                items_per_page,
                page_number,
                sort_fields,
            }) => ({
                url: `/dashboard/`,
                method: 'POST',
                body: {
                    dashboard_view: 'linked_item_tags_vendor',
                    tab: 'all',
                    query_data: {
                        tag_type: 'ITEM',
                    },
                    page_number,
                    items_per_page,
                    sort_fields,
                    search_text,
                },
            }),
            transformResponse: (response: IFetchItemLinkData) => {
                return {
                    additionalCostList: response.data,
                    pageDetails: response.metadata,
                    countDetails: response.counts,
                };
            },
        }),
        getItemMeasurements: builder.query<IItemMeasurementUnits, {}>({
            query: () => ({
                url: `/organization/measurement_unit?status=ACTIVE`,
            }),
            transformResponse(baseQueryReturnValue: any, meta, arg) {
                const measurements: IItemMeasurementUnits = {};

                baseQueryReturnValue.forEach((unit: any) => {
                    measurements[unit.measurement_unit.measurement_unit_id] = {
                        id: unit.measurement_unit.measurement_unit_id,
                        name: unit.measurement_unit
                            .measurement_unit_primary_name,
                        category:
                            unit.measurement_unit.measurement_unit_category,
                        type: unit.measurement_unit.measurement_unit_value_type,
                        isVerifiedUnit:
                            unit.measurement_unit.is_default ?? false,
                        abbreviation: unit.measurement_unit.abbreviation,
                    };
                });

                return measurements;
            },
        }),
    }),
});

export async function getTagNames() {
    let res = await get<ITag[]>(`/organization/tags?tag_type=ITEM`);

    return res.data;
}

export async function getEntitiesLinkToTag(tagName: string) {
    let res = await get<IEntity[]>(
        `/organization/items/tags/entities/?tag=${tagName}`
    );

    return res.data;
}

export async function getAllPreferredVendor(
    tagName: string,
    buyerEntityId: string
) {
    let res = await get<IPreferredVendorList[]>(
        `/organization/vendor_master/admin/items/tags/?tag=${tagName}&buyer_entity_id=${buyerEntityId}`
    );

    return res.data;
}

export async function getAllEntitiesList(entityId: string, searchText: string) {
    let payload = {
        dashboard_view: 'entity_vendor',
        tab: 'all',
        search_text: searchText,
        page_number: 1,
        sort_fields: [],
        items_per_page: 10,
        query_data: {
            entity_id: entityId,
        },
    };

    let res = await post<IVendorList[], any>(`/dashboard/`, payload);

    return res.data;
}

interface ILinkItemToTagPayload {
    tag: string;
    buyer_entity_id: string;
    add_existing: boolean;
    delete_existing: boolean;
    seller_entity_ids_to_add: string[];
    seller_entity_ids_to_delete: string[];
}

export async function linkItemTagToVendors(
    data: ILinkItemToTag,
    sellerEntityIds: string[],
    removedEntityId: string[]
) {
    try {
        let payload: ILinkItemToTagPayload = {
            tag: data.tag,
            buyer_entity_id: data.buyer_entity_id,
            add_existing: data.add_existing,
            delete_existing: data.delete_existing,
            seller_entity_ids_to_add: sellerEntityIds,
            seller_entity_ids_to_delete: removedEntityId,
        };

        let res = await put<ILinkItemToTag, any>(
            `/organization/vendor_master/admin/items/tags/update/`,
            payload
        );

        return res.data;
    } catch (e) {
        toast.error('Error linking item tag to vendor');
    }
}

export const deleteItemTagLinkage = async (tag_id: string) => {
    try {
        await del<{
            tag_id: string;
        }>(`/organization/items/tags/${tag_id}/delete/`);
    } catch (err) {
        throw new Error('Failed to delete Item tag linkage.');
    }
};

export const itemTagLinkToVendorApiSlice = baseApiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getItemTagLinkToVendorTabCount: builder.mutation<
            {
                tab_counts: {
                    all: number;
                };
            },
            {
                query_data: {
                    tag_type: string;
                };
            }
        >({
            query: () => ({
                url: '/dashboard/tab_counts/',
                method: 'POST',
                body: {
                    dashboard_view: 'linked_item_tags_vendor',
                    query_data: {
                        tag_type: 'ITEM',
                    },
                },
            }),
        }),
        getEntitiesList: builder.query<IEntity[], { tag: string }>({
            query: ({ tag }) => ({
                url: `/organization/items/tags/entities/?tag=${tag}`,
                method: 'GET',
            }),
        }),
        getTagsList: builder.query<ITag[], {}>({
            query: () => ({
                url: `/organization/tags?tag_type=ITEM`,
                method: 'GET',
            }),
        }),
        getPreferredVendors: builder.query<
            IPreferredVendorList[],
            { entityId: string; tag: string }
        >({
            query: ({ tag, entityId }) => ({
                url: `/organization/vendor_master/admin/items/tags/?tag=${tag}&buyer_entity_id=${entityId}`,
                method: 'GET',
            }),
        }),
        getAllEntitiesList: builder.mutation<
            IEntity[],
            {
                query_data: {
                    entity_id: string;
                };
            }
        >({
            query: (entity_id) => ({
                url: '/dashboard/',
                method: 'POST',
                body: {
                    dashboard_view: 'entity_vendor',
                    tab: 'all',
                    search_text: '',
                    page_number: 1,
                    sort_fields: [],
                    items_per_page: 10,
                    query_data: {
                        entity_id: entity_id,
                    },
                },
            }),
        }),
    }),
});
export const {
    useGetItemDirectoryTabCountMutation,
    useGetItemTagDashboardDataMutation,
    useGetItemMeasurementsQuery,
} = itemDirectoryApiSlice;

export const {
    useGetItemTagLinkToVendorTabCountMutation,
    useGetEntitiesListQuery,
    useGetTagsListQuery,
    useGetPreferredVendorsQuery,
    useGetAllEntitiesListMutation,
} = itemTagLinkToVendorApiSlice;

export const { useGetDefaultItemTemplateQuery } = baseApiSlice.injectEndpoints({
    endpoints: (build) => ({
        getDefaultItemTemplate: build.query<
            IRFQTemplateBackendResponse,
            { entityId: string }
        >({
            queryFn: async ({ entityId }) => {
                try {
                    const listOfTemplate = await get<
                        {
                            templates: TemplateData[];
                        }[]
                    >(`/module_templates/?entity_id=&template_type=${'ITEM'}`);

                    let templateId =
                        listOfTemplate.data[0].templates[0].template_id;

                    const templateDetails =
                        await get<IRFQTemplateBackendResponse>(
                            `/module_templates/${entityId}/${templateId}`
                        );

                    return { data: templateDetails.data };
                } catch (err: any) {
                    return { error: err };
                }
            },
        }),
    }),
});

export const useGetAdditionalCostFromItemTemplate = (entityUid: string) => {
    const { data: templateItemDetails } = useGetDefaultItemTemplateQuery(
        { entityId: entityUid },
        {
            skip: entityUid.trim() === '',
        }
    );

    const listOfAdditionalCost = useMemo(() => {
        const additionalCost =
            templateItemDetails?.section_list?.[0]?.section_items.find(
                (item) => item.name === 'Additional costs'
            );

        if (additionalCost) {
            return (
                templateItemDetails?.section_list?.[0]?.section_items.filter(
                    (item) =>
                        item.parent_section_item ===
                        additionalCost.section_item_id
                ) ?? []
            );
        }
        return [];
    }, [templateItemDetails?.section_list]);

    return listOfAdditionalCost;
};

export const validateItemCode = async (codeName: string) => {
    try {
        let resp = await get(
            `/organization/items/admin/exists/?code=${codeName}`
        );
        return resp;
    } catch (err) {}
};
