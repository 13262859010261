import { Box, Grid, SxProps } from '@mui/material';
import DeadlinePassed from '../Components/Shared/DeadlinePassed';
import StatusChip from '../Components/Shared/StatusChip';
import { replaceUrlWithLink } from '../Global/Constants/WebUrlRegex';
import { FWTooltip } from './FWCustomTooltip';
import { FWTypography } from './FWTypograhy';

interface IGridKeyValue {
    title: string | JSX.Element;
    value: string | JSX.Element;
    previousValue?: string | null;
    numCols?: number;
    xs?: number;
    lg?: number;
    isNew?: boolean;
    isDeleted?: boolean;
    useStatusChip?: boolean;
    isIncoterms?: boolean;
    isNegotiable?: boolean;
    deadlinePassed?: boolean;
    sx?: SxProps;
    titleWeight?: number;
    textWeight?: number;
    rightIcon?: React.ReactElement;
    showTooltip?: Boolean;
    tooltipText?: string;
    customComponentForTitle?: boolean;
}

export default function FWGridKeyValue({
    title,
    value,
    previousValue = null,
    numCols = 2,
    xs = 2,
    lg = 2,
    isNew = false,
    isDeleted = false,
    useStatusChip = false,
    deadlinePassed,
    sx,
    titleWeight,
    textWeight,
    showTooltip,
    tooltipText,
    rightIcon = <></>,
    customComponentForTitle = false,
}: IGridKeyValue) {
    if (isDeleted) {
        return (
            <Grid item md={numCols} xs={xs} lg={lg} sx={sx}>
                <div className="flex flex--aic">
                    <FWTypography
                        sx={{
                            textDecoration: 'line-through',
                            color: 'text.secondary',
                        }}
                        fontWeight={titleWeight}
                    >
                        {title}
                    </FWTypography>

                    <>
                        &nbsp;
                        {rightIcon}
                    </>
                    {deadlinePassed && <DeadlinePassed />}
                </div>

                <FWTypography
                    sx={{
                        textDecoration: 'line-through',
                        color: 'text.secondary',
                    }}
                    fontWeight={textWeight}
                >
                    {typeof value === 'string' ? (
                        <Box
                            component="span"
                            dangerouslySetInnerHTML={{
                                __html: replaceUrlWithLink(value ?? ''),
                            }}
                        ></Box>
                    ) : (
                        value
                    )}
                </FWTypography>
            </Grid>
        );
    } else if (isNew) {
        return (
            <Grid item md={numCols} xs={xs} lg={lg} sx={sx}>
                <div className="flex flex--aic">
                    <FWTypography
                        sx={{ color: 'success.main' }}
                        fontWeight={titleWeight}
                    >
                        {title}
                    </FWTypography>
                    <>
                        &nbsp;
                        {rightIcon}
                    </>
                    {deadlinePassed && <DeadlinePassed />}
                </div>
                <FWTypography
                    sx={{ color: 'success.main' }}
                    fontWeight={textWeight}
                >
                    {typeof value === 'string' ? (
                        <Box
                            component="span"
                            dangerouslySetInnerHTML={{
                                __html: replaceUrlWithLink(value ?? ''),
                            }}
                        ></Box>
                    ) : (
                        value
                    )}
                </FWTypography>
            </Grid>
        );
    } else if (previousValue === null) {
        if (useStatusChip)
            return (
                <Grid item xs={xs} lg={lg} sx={sx}>
                    <FWTypography
                        sx={{ color: 'text.secondary' }}
                        fontWeight={titleWeight}
                    >
                        {title}
                    </FWTypography>

                    {typeof value === 'string' ? (
                        <StatusChip status={value} />
                    ) : (
                        value
                    )}
                </Grid>
            );
        else
            return (
                <Grid item xs={xs} lg={lg} sx={sx}>
                    <div className="flex flex--aic">
                        {customComponentForTitle ? (
                            <>{title}</>
                        ) : (
                            <Grid
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '0.5rem',
                                }}
                            >
                                <FWTypography
                                    sx={{ color: 'text.secondary' }}
                                    fontWeight={titleWeight}
                                >
                                    {title}
                                </FWTypography>

                                {showTooltip && (
                                    <FWTooltip
                                        title={tooltipText ?? ''}
                                        placement="right"
                                    >
                                        <Box color="primary.main">
                                            <i className="bi bi-info-circle" />
                                        </Box>
                                    </FWTooltip>
                                )}
                            </Grid>
                        )}

                        <>
                            &nbsp;
                            {rightIcon}
                        </>
                        {deadlinePassed && <DeadlinePassed />}
                    </div>
                    <FWTypography fontWeight={textWeight}>
                        {typeof value === 'string' ? (
                            <Box
                                component="span"
                                dangerouslySetInnerHTML={{
                                    __html: replaceUrlWithLink(value ?? ''),
                                }}
                            ></Box>
                        ) : (
                            value
                        )}
                    </FWTypography>
                </Grid>
            );
    } else {
        return (
            <Grid item md={numCols} xs={xs} lg={lg} sx={sx}>
                <div className="flex flex--aic">
                    <FWTypography
                        sx={{ color: 'text.secondary' }}
                        fontWeight={titleWeight}
                    >
                        {title}
                    </FWTypography>
                    <>
                        &nbsp;
                        {rightIcon}
                    </>
                    {deadlinePassed && <DeadlinePassed />}
                </div>
                <FWTypography
                    sx={{
                        color: deadlinePassed ? 'error.main' : 'success.main',
                    }}
                >
                    {value}
                </FWTypography>
                <FWTypography
                    sx={{
                        textDecoration: 'line-through',
                        color: 'text.secondary',
                    }}
                >
                    <Box
                        component="span"
                        dangerouslySetInnerHTML={{
                            __html: replaceUrlWithLink(previousValue ?? ''),
                        }}
                    ></Box>
                </FWTypography>
            </Grid>
        );
    }
}
