import produce from 'immer';
import {
    AdminSettingsUpdateType,
    IAdminSettingsState,
    IAdminSettingsUpdateActions,
} from '../Interfaces/AdminSettingsReduxInterface';

export const updateAdminSettingsReducer = produce(
    (state: IAdminSettingsState, action: IAdminSettingsUpdateActions) => {
        switch (action.payload.type) {
            case AdminSettingsUpdateType.INIT_ENTITY:
                state[action.payload.entityUid] = action.payload.value;
                break;
        }
        //     return { ...action.payload.value };
        // // GR START
        // case AdminSettingsUpdateType.GRN_CUSTOM_FIELDS_ADD:
        //     state.grnCustomFields.push(action.payload.value);
        //     break;
        // case AdminSettingsUpdateType.GRN_CUSTOM_FIELDS_REMOVE:
        //     state.grnCustomFields.splice(action.payload.value, 1);
        //     break;
        // case AdminSettingsUpdateType.GRN_CUSTOM_FIELDS_UPDATE:
        //     const grnField =
        //         state.grnCustomFields[action.payload.value.index];
        //     switch (action.payload.value.field) {
        //         case 'NAME':
        //             grnField.name = action.payload.value.data as string;
        //             break;
        //         case 'TYPE':
        //             grnField.type = action.payload.value
        //                 .data as CustomTemplateItemTypes;
        //             break;
        //         case 'MANDATORY':
        //             grnField.mandatory = action.payload.value
        //                 .data as boolean;
        //             break;
        //     }
        //     break;
        // case AdminSettingsUpdateType.GRN_CHECKLIST_ADD:
        //     state.grnChecklist.push(action.payload.value);
        //     break;
        // case AdminSettingsUpdateType.GRN_CHECKLIST_UPDATE:
        //     state.grnChecklist.splice(
        //         action.payload.value.index,
        //         1,
        //         action.payload.value.data
        //     );
        //     break;
        // case AdminSettingsUpdateType.GRN_CHECKLIST_REMOVE:
        //     state.grnChecklist.splice(action.payload.value, 1);
        //     break;
        // // GR END
        // // QC START
        // case AdminSettingsUpdateType.QC_CUSTOM_FIELDS_ADD:
        //     state.qcCustomFields.push(action.payload.value);
        //     break;
        // case AdminSettingsUpdateType.QC_CUSTOM_FIELDS_REMOVE:
        //     state.qcCustomFields.splice(action.payload.value, 1);
        //     break;
        // case AdminSettingsUpdateType.QC_CUSTOM_FIELDS_UPDATE:
        //     const qcField =
        //         state.qcCustomFields[action.payload.value.index];
        //     switch (action.payload.value.field) {
        //         case 'NAME':
        //             qcField.name = action.payload.value.data as string;
        //             break;
        //         case 'TYPE':
        //             qcField.type = action.payload.value
        //                 .data as CustomTemplateItemTypes;
        //             break;
        //         case 'MANDATORY':
        //             qcField.mandatory = action.payload.value
        //                 .data as boolean;
        //             break;
        //     }
        //     break;
        // case AdminSettingsUpdateType.QC_CHECKLIST_ADD:
        //     state.qcChecklist.push(action.payload.value);
        //     break;
        // case AdminSettingsUpdateType.QC_CHECKLIST_UPDATE:
        //     state.qcChecklist.splice(
        //         action.payload.value.index,
        //         1,
        //         action.payload.value.data
        //     );
        //     break;
        // case AdminSettingsUpdateType.QC_CHECKLIST_REMOVE:
        //     state.qcChecklist.splice(action.payload.value, 1);
        //     break;
        // // QC END
    }
);
