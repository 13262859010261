import {
    DialogContent,
    DialogActions,
    Grid,
    IconButton,
    Typography,
    // Chip,
    // TextField,
} from '@mui/material';
import { cloneDeep, isEmpty } from 'lodash';
import React from 'react';
import { toast } from 'react-toastify';
// import FWAutocomplete from '../../../../../Common/FWAutocomplete';
import { FWButton } from '../../../Common/FWButton';
import { FWInput } from '../../../Common/FWInput';
import { FWPopupWithChildren } from '../../../Common/FWPopupWithChildren';
import { FWTypography } from '../../../Common/FWTypograhy';
import { IGetAttributeDetails } from '../../../Models/Attributes.model';

interface ICustomAttributeInterface {
    idx: number;
    id: string | null;
    name: string;
    value: string[];
}

interface IAddAttributePopup {
    open: boolean;
    setOpen: React.Dispatch<
        React.SetStateAction<{
            isOpen: boolean;
            currentAttribute: ICustomAttributeInterface | null;
        }>
    >;
    all_attributes: IGetAttributeDetails[] | null;
    updateAttribute(
        attribute_id: string | null,
        attribute_name: string | null,
        attribute_value: string[] | null,
        index_of_attribute_to_be_edited: number
    ): void;
    currentAttribute: ICustomAttributeInterface | null;
    deleteAttribute(idx: number): void;
}

export default function AddSpecificationPopup({
    open,
    setOpen,
    all_attributes,
    updateAttribute,
    deleteAttribute,
    currentAttribute,
}: IAddAttributePopup) {
    const handleClose = () =>
        setOpen({ isOpen: false, currentAttribute: null });
    // stores the currently selected Attrbute
    const [selectedAttribute, setSelectedAttribute] = React.useState<{
        id: string | null;
        name: string;
    } | null>(null);
    // stores the value type of the currently selected attribute
    const [selectedAttributeValue, setSelectedAttributeValue] = React.useState<
        string[]
    >([]);

    const [attributeError, setAttributeError] = React.useState<{
        is_warning?: boolean;
        is_error?: boolean;
        attributeWarn_name?: string;
        attributeError_Value?: { [id: number]: string };
        attributeError_Clone?: string;
    }>();

    const [typedFields, setTypedFields] = React.useState<{
        is_NameTyped?: boolean;
        is_ValueTyped?: boolean;
    }>({
        is_NameTyped: false,
        is_ValueTyped: false,
    });

    React.useEffect(() => {
        if (open) {
            let updatedAttributeError = {};
            if (
                !selectedAttribute ||
                selectedAttribute?.name.trim().length === 0
            ) {
                updatedAttributeError = {
                    ...updatedAttributeError,
                    attributeWarn_name: 'Input Required',
                    is_error: true,
                };
            } else if (
                all_attributes &&
                all_attributes.find(
                    (attr) =>
                        attr.name ===
                            selectedAttribute?.name.trim().toUpperCase() &&
                        attr.name !==
                            currentAttribute?.name.trim().toUpperCase()
                )
            ) {
                updatedAttributeError = {
                    ...updatedAttributeError,
                    attributeWarn_name: 'Duplicate specification',
                    is_warning: true,
                };
            }
            if (selectedAttributeValue.join('').trim().length === 0) {
                updatedAttributeError = {
                    ...updatedAttributeError,
                    attributeError_Value: {
                        0: 'Input Required',
                    },
                    is_error: true,
                };
            } else if (selectedAttributeValue.toString().length !== 0) {
                let seenAttributeValues: Set<string> = new Set();
                selectedAttributeValue
                    .filter((attr) => !isEmpty(attr.trim()))
                    .forEach((attr_value, idx) =>
                        seenAttributeValues.has(attr_value.toUpperCase())
                            ? (updatedAttributeError = {
                                  ...updatedAttributeError,
                                  attributeError_Value: {
                                      [idx]: 'Duplicate value',
                                  },
                                  is_error: true,
                              })
                            : seenAttributeValues.add(attr_value.toUpperCase())
                    );
            }
            //Check if attribute already exists with same values
            if (selectedAttribute?.name !== currentAttribute?.name) {
                const similarAttribute = all_attributes?.find(
                    (attr) =>
                        attr.name ===
                        selectedAttribute?.name.trim().toUpperCase()
                );
                if (
                    similarAttribute &&
                    similarAttribute.value.length ===
                        selectedAttributeValue.length
                ) {
                    let flag: boolean = true;
                    selectedAttributeValue.forEach((attr_val) => {
                        if (
                            !similarAttribute.value.includes(
                                attr_val.trim().toUpperCase()
                            )
                        ) {
                            flag = false;
                        }
                    });
                    if (flag) {
                        updatedAttributeError = {
                            ...updatedAttributeError,
                            attributeError_Clone:
                                'Attribute already exists with same values',
                            is_error: true,
                        };
                    }
                }
            }
            setAttributeError(updatedAttributeError);
        }
    }, [
        all_attributes,
        currentAttribute,
        selectedAttribute,
        selectedAttributeValue,
        open,
    ]);

    React.useEffect(() => {
        const setup = async () => {
            if (open) {
                if (currentAttribute !== null) {
                    setSelectedAttribute({
                        id: currentAttribute.id,
                        name: currentAttribute.name,
                    });
                    setSelectedAttributeValue(currentAttribute.value);
                } else {
                    setSelectedAttribute(null);
                    setSelectedAttributeValue(['']);
                    setAttributeError({ is_error: true });
                    setTypedFields({
                        is_NameTyped: false,
                        is_ValueTyped: false,
                    });
                }
            }
        };
        setup();
    }, [currentAttribute, open, all_attributes]);

    const saveAttribute = () => {
        let seenAttributeValues: Set<string> = new Set();
        let duplicateAttributeValue: Set<string> = new Set();
        selectedAttributeValue
            .filter((attr) => !isEmpty(attr.trim()))
            .forEach((attr_value) =>
                seenAttributeValues.has(attr_value.toUpperCase())
                    ? duplicateAttributeValue.add(attr_value.toUpperCase())
                    : seenAttributeValues.add(attr_value.toUpperCase())
            );

        if (!(selectedAttribute !== null)) {
            toast.warning('Please select valid item specifications');
        } else if (duplicateAttributeValue.size > 0) {
            toast.warning(
                `Duplicate values found: ${Array.from(
                    duplicateAttributeValue
                ).join(' / ')}`
            );
        } else {
            const trimmedSelectedAttributeValue = selectedAttributeValue.filter(
                (attr) => !isEmpty(attr.trim())
            );
            updateAttribute(
                selectedAttribute.id,
                selectedAttribute.name,
                trimmedSelectedAttributeValue,
                currentAttribute === null ? -1 : currentAttribute.idx
            );
        }
    };

    const deleteAttributeValue = (idx: number) => {
        setSelectedAttributeValue((prev) => {
            let newObj = cloneDeep(prev);
            newObj.splice(idx, 1);
            return newObj;
        });
    };

    return (
        <span onClick={(e) => e.stopPropagation()}>
            <FWPopupWithChildren
                title="Add specifications"
                open={open}
                showClose={true}
                handleClose={handleClose}
                PaperProps={{
                    onClick: (e) => {
                        e.stopPropagation();
                    },
                }}
            >
                <DialogContent sx={{ padding: '24px' }}>
                    <Grid container rowSpacing={2} alignItems="flex-end">
                        <Grid item xs={9}>
                            <FWInput
                                label="Specification name"
                                defaultValue={
                                    selectedAttribute === null
                                        ? ''
                                        : selectedAttribute.name
                                }
                                onChange={(event) => {
                                    if (typedFields.is_NameTyped === false) {
                                        setTypedFields({
                                            ...typedFields,
                                            is_NameTyped: true,
                                        });
                                    }
                                    setSelectedAttribute({
                                        id:
                                            selectedAttribute === null
                                                ? null
                                                : selectedAttribute.id,
                                        name: event.target.value as string,
                                    });
                                }}
                                error={
                                    attributeError &&
                                    !attributeError.is_warning &&
                                    attributeError.attributeWarn_name &&
                                    attributeError.is_error &&
                                    typedFields.is_NameTyped
                                        ? true
                                        : false
                                }
                                helper={{
                                    text:
                                        attributeError &&
                                        attributeError.attributeWarn_name &&
                                        typedFields.is_NameTyped
                                            ? attributeError.attributeWarn_name
                                            : '',
                                    textColor:
                                        attributeError &&
                                        attributeError.attributeWarn_name &&
                                        attributeError.is_warning &&
                                        typedFields.is_NameTyped
                                            ? 'warning'
                                            : undefined,
                                }}
                                color={
                                    attributeError &&
                                    attributeError.attributeWarn_name &&
                                    attributeError.is_warning &&
                                    typedFields.is_NameTyped
                                        ? 'warning'
                                        : 'info'
                                }
                            />
                        </Grid>
                        <Grid item xs={3}></Grid>
                        {selectedAttributeValue.map((attribute_value, idx) => (
                            <React.Fragment key={idx}>
                                <Grid item xs={9}>
                                    {idx === 0 && (
                                        <FWTypography color="text.secondary">
                                            Value
                                        </FWTypography>
                                    )}
                                    <FWInput
                                        onChange={(event: any) => {
                                            if (
                                                typedFields.is_ValueTyped ===
                                                false
                                            ) {
                                                setTypedFields({
                                                    ...typedFields,
                                                    is_ValueTyped: true,
                                                });
                                            }
                                            setSelectedAttributeValue(
                                                (prev) => {
                                                    let newArr = [...prev];
                                                    newArr[idx] = event.target
                                                        .value as string;
                                                    return newArr;
                                                }
                                            );
                                        }}
                                        error={
                                            attributeError &&
                                            attributeError.attributeError_Value &&
                                            attributeError.attributeError_Value[
                                                idx
                                            ] &&
                                            typedFields.is_ValueTyped
                                                ? true
                                                : false
                                        }
                                        helper={{
                                            text:
                                                attributeError &&
                                                attributeError.attributeError_Value &&
                                                idx in
                                                    attributeError.attributeError_Value &&
                                                typedFields.is_ValueTyped
                                                    ? '' +
                                                      attributeError
                                                          .attributeError_Value[
                                                          idx
                                                      ]
                                                    : '',
                                        }}
                                        value={attribute_value}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                        className="pl--10"
                                    >
                                        <FWTypography color="text.secondary">
                                            OR
                                        </FWTypography>
                                        &nbsp;&nbsp;
                                        <IconButton
                                            size="small"
                                            color="error"
                                            onClick={() =>
                                                deleteAttributeValue(idx)
                                            }
                                            disabled={
                                                selectedAttributeValue.length ===
                                                1
                                            }
                                        >
                                            <i className="bi bi-dash-circle"></i>
                                        </IconButton>
                                        {idx ===
                                            selectedAttributeValue.length -
                                                1 && (
                                            <IconButton
                                                size="small"
                                                color="primary"
                                                onClick={() =>
                                                    setSelectedAttributeValue(
                                                        (prev) => [...prev, '']
                                                    )
                                                }
                                            >
                                                <i className="bi bi-plus-circle"></i>
                                            </IconButton>
                                        )}
                                    </div>
                                </Grid>
                            </React.Fragment>
                        ))}
                        <Typography color="error" variant="caption">
                            {attributeError &&
                                attributeError.attributeError_Clone}
                        </Typography>
                    </Grid>
                </DialogContent>
                <DialogActions sx={{ padding: '24px' }}>
                    <FWButton
                        color="error"
                        onClick={() => {
                            if (currentAttribute !== null) {
                                deleteAttribute(currentAttribute.idx);
                            }
                            handleClose();
                        }}
                        variant="outlined"
                    >
                        Delete
                    </FWButton>
                    <FWButton
                        onClick={() => {
                            if (!(attributeError && attributeError.is_error)) {
                                saveAttribute();
                            }
                        }}
                        variant="contained"
                        disabled={attributeError && attributeError.is_error}
                        id="CreateEvent_addSpecificationsSaveButton"
                    >
                        Save
                    </FWButton>
                </DialogActions>
            </FWPopupWithChildren>
        </span>
    );
}
