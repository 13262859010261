export const getNewItemTag = (
    newTagList: string[],
    existingTagLit: string[]
) => {
    const newItemTag = newTagList.filter(
        (newTag) =>
            !existingTagLit
                .map((oldTag) => oldTag.toLowerCase().trim())
                .some((oldTag) => oldTag === newTag.toLowerCase().trim())
    );

    if (newTagList?.length > 0) {
        return newItemTag[0];
    }
    return null;
};

export const getMatchingItemTag = (
    newtag: string,
    existingTagList: string[],
    option: string[]
) => {
    const newTagInOption = option.find(
        (opt) => opt.toLowerCase().trim() === newtag?.toLowerCase()?.trim()
    );

    // is new Tag is in the option
    if (newTagInOption) {
        return [...existingTagList, newTagInOption];
    } else {
        return [...existingTagList, newtag];
    }
};
