import { debounce } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useGetEntityListQuery } from '../../../Organizations/Admin/Services/EnterpriseService';
import {
    IItemCartDashboardData,
    itemCartTabSortFieldColumns,
} from '../../Interfaces/ItemAnalyticsInterfaces';
import { useGetItemCartDashboardMutation } from '../../Services/ItemAnalytics.service';

export enum cartTabType {
    ALL = 'all',
    CART = 'cart_items',
    SAVE_FOR_LATER = 'save_for_later',
}

export default function useItemCartDashboard() {
    const [currentPage, setCurrentPage] = useState(1);

    const [cartTab, setCartTab] = useState<cartTabType>(cartTabType.ALL);

    const [buyerEntity, setBuyerEntity] = useState<string>('');

    const updateBuyerEntity = (newBuyerEntity: string) => {
        setBuyerEntity(newBuyerEntity);
    };

    const [itemCartList, setItemCartList] = useState<IItemCartDashboardData[]>(
        []
    );

    const { data: listOfEntities } = useGetEntityListQuery({});

    const [totalPages, setTotalPages] = useState(1);

    const [searchText, setSearchText] = useState('');
    const [sortFields, setSortFields] = useState<
        {
            field: itemCartTabSortFieldColumns;
            ascending: boolean;
        }[]
    >([]);

    const debouncedSearch = useMemo(
        () =>
            debounce((text: string) => {
                setSearchText(text);
            }, 1000),
        []
    );

    const [fetchItemCartDetails, { data: itemCartDetails }] =
        useGetItemCartDashboardMutation({
            fixedCacheKey: 'itemCartDashboard',
        });

    useEffect(() => {
        if (itemCartDetails) {
            setItemCartList(itemCartDetails.data);
            setTotalPages(itemCartDetails.metadata.total_pages);
        }
    }, [itemCartDetails]);

    useEffect(() => {
        fetchItemCartDetails({
            items_per_page: 10,
            page_number: currentPage,
            query_data: {
                buyer_entity_id: buyerEntity ? buyerEntity : null,
                cart_permission: 'CART_VIEW',
                user_permission: 'CART_VIEW',
            },
            tab: cartTab,
            search_text: searchText,
            sort_fields: sortFields,
        });
    }, [
        buyerEntity,
        cartTab,
        currentPage,
        fetchItemCartDetails,
        searchText,
        sortFields,
    ]);

    return {
        itemCartList,
        searchText,
        sortFields,
        setSortFields,
        listOfEntities,
        debouncedSearch,
        currentPage,
        totalPages,
        setCurrentPage,
        updateBuyerEntity,
        setCartTab,
    };
}
