import { Divider, Grid } from '@mui/material';
import { Fragment, useMemo, useState } from 'react';
import { capitaliseFirstLetter } from '../../BuyerTemplates/Component/TemplateSuggestedFieldsSection';
import { FWCurrencyTypoGraphyWithCurrencyUid } from '../../Common/CurrencyUtilsComponent';
import { FWLink } from '../../Common/FWLink';
import FWPopover from '../../Common/FWPopover';
import { FWTypography } from '../../Common/FWTypograhy';
import {
    calculateAddtionalCost,
    calculateTotalAdditionalCostAndDeviation,
} from '../helpers';
import {
    CostTypeEnumToLabelMap,
    IAdditionalCostsWithValue,
} from '../models/AdditionalCost.model';

export type IAdditionalCostPopoverProps =
    | {
          type: 'AdditionalCost' | 'Discount';
          additionalCost: IAdditionalCostsWithValue[];
          baseRate: number | string;
          baseQuantity: number | string;
          currency_code_abbreviation: string;
          currency_code_symbol: string;
          measurementUnitName: string;
          clickable?: boolean;
          showAdditionalCostsRate?: boolean;
          dontShowIncludedInRateText?: boolean;
          singleAdditionalCost?: boolean;
          fontWeight?: string | number | undefined;
      }
    | {
          type: 'Taxes';
          additionalCost: IAdditionalCostsWithValue[];
          discountAdditionalCost: IAdditionalCostsWithValue[];
          baseRate: number | string;
          baseQuantity: number | string;
          currency_code_abbreviation: string;
          currency_code_symbol: string;
          measurementUnitName: string;
          clickable?: boolean;
          showAdditionalCostsRate?: boolean;
          dontShowIncludedInRateText?: boolean;
          singleAdditionalCost?: boolean;
          fontWeight?: string | number | undefined;
      };

const AdditionalCostPopover = (props: IAdditionalCostPopoverProps) => {
    const {
        additionalCost,
        // type,
        baseQuantity,
        baseRate,
        currency_code_abbreviation,
        measurementUnitName,
        clickable = true,
        showAdditionalCostsRate = true,
        dontShowIncludedInRateText = false,
        singleAdditionalCost = false,
        fontWeight,
    } = props;

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const open = Boolean(anchorEl);

    const handleClose = () => setAnchorEl(null);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) =>
        setAnchorEl(event.currentTarget);

    const discountAdditionalCostDeviation = useMemo(() => {
        if (props.type === 'Taxes') {
            return props.discountAdditionalCost
                .filter((cost) => cost.is_calculated ?? true)
                .reduce(
                    (acc, curr) => {
                        const { additionalCostValue, effectiveRateDeviation } =
                            calculateTotalAdditionalCostAndDeviation({
                                additionalCost: curr,
                                baseRate: baseRate,
                                baseQuantity: baseQuantity,
                            });

                        return {
                            additionalCostValue:
                                acc.additionalCostValue + additionalCostValue,
                            effectiveRateDeviation:
                                acc.effectiveRateDeviation +
                                effectiveRateDeviation,
                        };
                    },
                    { additionalCostValue: 0, effectiveRateDeviation: 0 }
                ).effectiveRateDeviation;
        }
        return 0;
    }, [baseQuantity, baseRate, props]);

    const totalAdditionalCost = useMemo(() => {
        return additionalCost
            .filter((cost) => {
                if (singleAdditionalCost) return true;
                return cost.is_calculated ?? true;
            })
            .reduce(
                (acc, curr) => {
                    const { additionalCostValue, effectiveRateDeviation } =
                        calculateTotalAdditionalCostAndDeviation({
                            additionalCost: curr,
                            baseRate:
                                +baseRate - discountAdditionalCostDeviation,
                            baseQuantity: baseQuantity,
                        });

                    return {
                        additionalCostValue:
                            acc.additionalCostValue + additionalCostValue,
                        effectiveRateDeviation:
                            acc.effectiveRateDeviation + effectiveRateDeviation,
                    };
                },
                { additionalCostValue: 0, effectiveRateDeviation: 0 }
            );
    }, [
        additionalCost,
        singleAdditionalCost,
        baseRate,
        discountAdditionalCostDeviation,
        baseQuantity,
    ]);

    const displayText = useMemo(() => {
        if (showAdditionalCostsRate) {
            return totalAdditionalCost.effectiveRateDeviation > 0 ||
                dontShowIncludedInRateText ? (
                <FWCurrencyTypoGraphyWithCurrencyUid
                    currencyUidOrAbbrevaition={currency_code_abbreviation}
                    currency_code_abbreviation={currency_code_abbreviation}
                    value={+totalAdditionalCost.effectiveRateDeviation}
                    measurementUnitName={measurementUnitName}
                    fontWeight={fontWeight}
                />
            ) : (
                'Included in rate'
            );
        } else {
            return totalAdditionalCost.additionalCostValue > 0 ||
                dontShowIncludedInRateText ? (
                <FWCurrencyTypoGraphyWithCurrencyUid
                    currencyUidOrAbbrevaition={currency_code_abbreviation}
                    currency_code_abbreviation={currency_code_abbreviation}
                    value={+totalAdditionalCost.additionalCostValue}
                    fontWeight={fontWeight}
                />
            ) : (
                'Included in rate'
            );
        }
    }, [
        showAdditionalCostsRate,
        totalAdditionalCost.effectiveRateDeviation,
        totalAdditionalCost.additionalCostValue,
        dontShowIncludedInRateText,
        currency_code_abbreviation,
        measurementUnitName,
        fontWeight,
    ]);

    return (
        <>
            {clickable && +totalAdditionalCost.additionalCostValue ? (
                <FWLink
                    onClick={(e: any) => {
                        handleClick(e);
                        e.stopPropagation();
                    }}
                >
                    {displayText}
                </FWLink>
            ) : (
                <FWTypography
                    onClick={(e) => e.stopPropagation()}
                    sx={{ width: 'fit-content' }}
                >
                    {displayText}
                </FWTypography>
            )}

            <FWPopover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <Grid
                    width={'700px'}
                    paddingX={1}
                    paddingTop={2}
                    paddingBottom={1}
                    container
                    rowSpacing={1}
                    xs={12}
                >
                    <Grid item xs={2}>
                        <FWTypography color={'secondary'} textAlign={'left'}>
                            {/* {type === 'AdditionalCost' ? 'Tax' : 'Discount'}{' '} */}
                            Name
                        </FWTypography>
                    </Grid>
                    <Grid item xs={0.4}></Grid>
                    <Grid item xs={1.5}>
                        <FWTypography color={'secondary'} textAlign={'left'}>
                            Type
                        </FWTypography>
                    </Grid>
                    <Grid item xs={0.4}></Grid>
                    <Grid item xs={1.5}>
                        <FWTypography color={'secondary'} textAlign={'left'}>
                            Allocation
                        </FWTypography>
                    </Grid>
                    <Grid item xs={0.4}></Grid>
                    <Grid item xs={2.5}>
                        <FWTypography color={'secondary'} textAlign={'left'}>
                            {/* {type === 'AdditionalCost' ? 'Tax' : 'Discount'}{' '} */}
                            Rate
                        </FWTypography>
                    </Grid>
                    <Grid item xs={0.4}></Grid>
                    <Grid item xs={2.5}>
                        <FWTypography color={'secondary'} textAlign={'left'}>
                            {/* {type === 'AdditionalCost' ? 'Tax' : 'Discount'}{' '} */}
                            Value
                        </FWTypography>
                    </Grid>
                    {additionalCost.map((item, index) => {
                        const currentAdditionalCost =
                            props.type === 'Taxes'
                                ? calculateAddtionalCost({
                                      additionalCost: [item],
                                      baseRate: +baseRate,
                                      baseQuantity: +baseQuantity,
                                      discountAdditionalCost:
                                          props.discountAdditionalCost,
                                      type: 'TAX',
                                  })
                                : calculateAddtionalCost({
                                      additionalCost: [item],
                                      baseRate: +baseRate,
                                      baseQuantity: +baseQuantity,
                                      type: 'ADDITIONAL_COST',
                                  });

                        // calculateTotalAdditionalCostAndDeviation({
                        //     additionalCost: item,
                        //     baseRate: baseRate,
                        //     baseQuantity: baseQuantity,
                        // });
                        return (
                            <Fragment key={index}>
                                <Grid item xs={2}>
                                    <FWTypography>{item.costName}</FWTypography>
                                </Grid>
                                <Grid item xs={0.4}></Grid>
                                <Grid item xs={1.5}>
                                    <FWTypography
                                        sx={{ display: 'flex', gap: '0.25rem' }}
                                    >
                                        {CostTypeEnumToLabelMap[item.costType]}
                                    </FWTypography>
                                </Grid>
                                <Grid item xs={0.4}></Grid>
                                <Grid item xs={1.5}>
                                    <FWTypography>
                                        {item.costType === 'ABSOLUTE_VALUE'
                                            ? item.allocationType &&
                                              capitaliseFirstLetter(
                                                  item.allocationType
                                                      .toLowerCase()
                                                      .split('_')
                                                      .join(' ')
                                              )
                                            : '-'}
                                    </FWTypography>
                                </Grid>
                                <Grid item xs={0.4}></Grid>
                                <Grid item xs={2.5}>
                                    <FWTypography textAlign={'left'}>
                                        <FWCurrencyTypoGraphyWithCurrencyUid
                                            currencyUidOrAbbrevaition={
                                                currency_code_abbreviation
                                            }
                                            currency_code_abbreviation={
                                                currency_code_abbreviation
                                            }
                                            value={
                                                +currentAdditionalCost.effectiveRateDeviation
                                            }
                                            measurementUnitName={
                                                measurementUnitName
                                            }
                                        />
                                    </FWTypography>
                                </Grid>
                                <Grid item xs={0.4}></Grid>
                                <Grid item xs={2.5}>
                                    <FWTypography textAlign={'left'}>
                                        <FWCurrencyTypoGraphyWithCurrencyUid
                                            currencyUidOrAbbrevaition={
                                                currency_code_abbreviation
                                            }
                                            currency_code_abbreviation={
                                                currency_code_abbreviation
                                            }
                                            value={
                                                +currentAdditionalCost.additionalCostValue
                                            }
                                        />
                                    </FWTypography>
                                </Grid>
                            </Fragment>
                        );
                    })}

                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={2}>
                        <FWTypography fontWeight={'500'}>Total</FWTypography>
                    </Grid>
                    <Grid item xs={0.4}></Grid>
                    <Grid item xs={1.5}>
                        <FWTypography
                            fontWeight={'500'}
                            sx={{ display: 'flex', gap: '0.25rem' }}
                        >
                            -
                        </FWTypography>
                    </Grid>
                    <Grid item xs={0.4}></Grid>
                    <Grid item xs={1.5}>
                        <FWTypography fontWeight={'500'}>-</FWTypography>
                    </Grid>
                    <Grid item xs={0.4}></Grid>
                    <Grid item xs={2.5}>
                        <FWTypography fontWeight={'500'} textAlign={'left'}>
                            <FWCurrencyTypoGraphyWithCurrencyUid
                                currencyUidOrAbbrevaition={
                                    currency_code_abbreviation
                                }
                                currency_code_abbreviation={
                                    currency_code_abbreviation
                                }
                                value={
                                    +totalAdditionalCost.effectiveRateDeviation
                                }
                                measurementUnitName={measurementUnitName}
                            />
                        </FWTypography>
                    </Grid>
                    <Grid item xs={0.4}></Grid>
                    <Grid item xs={2.5}>
                        <FWTypography fontWeight={'500'} textAlign={'left'}>
                            <FWCurrencyTypoGraphyWithCurrencyUid
                                currencyUidOrAbbrevaition={
                                    currency_code_abbreviation
                                }
                                currency_code_abbreviation={
                                    currency_code_abbreviation
                                }
                                value={+totalAdditionalCost.additionalCostValue}
                            />
                        </FWTypography>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                </Grid>
            </FWPopover>
        </>
    );
};

export default AdditionalCostPopover;
