import { IFileResourceType } from '../../Components/Shared/UploadFile';
import { IAttachment } from '../../Models/RFQ.model';
import { get, post, postToS3 } from '../../Utils/api';

export interface IUploadUrlResponse {
    attachment_id: string;
    url: string;
    fields: {
        AWSAccessKeyId: string;
        key: string;
        policy: string;
        signature: string;
        'x-amz-security-authData': string;
    };
}

export const generateUploadUrl = async (
    resourceId: string,
    resourceType: string,
    fileName: string
): Promise<IUploadUrlResponse> => {
    const generateUploadUrlResponse: any = await post<any, any>(
        `/attachment/url/generate/upload/`,
        {
            resource_id: resourceId,
            file_name: fileName,
            module_type: resourceType,
        }
    );
    return {
        attachment_id: generateUploadUrlResponse.data['attachment_id'],
        url: generateUploadUrlResponse.data['attachment_response']['url'],
        fields: generateUploadUrlResponse.data['attachment_response']['fields'],
    };
};

export const uploadFileToGeneratedUrl = async (
    url: string,
    fields: any,
    file: File
): Promise<any> => {
    const formData = new FormData();
    for (const key in fields) {
        formData.append(key, fields[key]);
    }
    process.env.REACT_APP_ENV === 'newdbtest1' ||
    process.env.REACT_APP_ENV === 'production'
        ? formData.append('File', file)
        : formData.append('file', file);
    let res = await postToS3(url, formData);

    return res.data;
};

export const confirmFileUpload = async (
    resourceId: string,
    resourceType: string,
    fileName: string,
    attachmentId: string
): Promise<any> => {
    const confirmUploadResponse = await post<any, any>(`/attachment/confirm/`, {
        resource_id: resourceId,
        file_name: fileName,
        module_type: resourceType,
        attachment_id: attachmentId,
    });
    return confirmUploadResponse.data;
};

export const deleteUploadedFile = async (
    resourceId: string,
    resourceType: string,
    fileName: string,
    attachmentId: string
): Promise<any> => {
    const deleteResponse = await post<any, any>(`/attachment/delete/`, {
        resource_id: resourceId,
        file_name: fileName,
        module_type: resourceType,
        attachment_id: attachmentId,
    });
    return deleteResponse.data;
};

const getPayloadFromResourceType = (fileName: string, resourceType: string) => {
    if (resourceType === 'DOCUMENT_TEMPLATE') {
        return {
            file_name: fileName,
            module_type: 'DOCUMENT',
            attachment_type: 'TEMPLATE',
        };
    } else if (resourceType === 'DOCUMENT_ATTACHMENT') {
        return {
            file_name: fileName,
            module_type: 'DOCUMENT',
            attachment_type: 'ATTACHMENT',
        };
    } else if (resourceType === 'DOCUMENT_LOGO') {
        return {
            file_name: fileName,
            module_type: 'DOCUMENT',
            attachment_type: 'LOGO',
        };
    } else if (resourceType === 'DOCUMENT_BANNER') {
        return {
            file_name: fileName,
            module_type: 'DOCUMENT',
            attachment_type: 'BANNER',
        };
    } else if (resourceType === 'ENTERPRISE_ITEM') {
        return {
            file_name: fileName,
            module_type: 'ENTERPRISE_ITEM',
            attachment_type: 'LOGO',
        };
    } else {
        return {
            file_name: fileName,
            module_type: resourceType,
        };
    }
};

export const fetchUploadUrl = async (
    resourceType: string,
    fileName: string
): Promise<IUploadUrlResponse> => {
    const payload = getPayloadFromResourceType(fileName, resourceType);
    const generateUploadUrlResponse: any = await post<any, any>(
        `/attachment/url/generate/upload/`,
        payload
    );
    return {
        attachment_id: generateUploadUrlResponse.data['attachment_id'],
        url: generateUploadUrlResponse.data['attachment_response']['url'],
        fields: generateUploadUrlResponse.data['attachment_response']['fields'],
    };
};

export const uploadFile = async (
    file: File,
    resourceType: IFileResourceType
): Promise<IAttachment> => {
    return new Promise(async (resolve, reject) => {
        try {
            const { attachment_id, url, fields } = await fetchUploadUrl(
                resourceType,
                file.name
            );

            await uploadFileToGeneratedUrl(url, fields, file);

            await confirmAttachment(attachment_id);

            const attachment: IAttachment = {
                attachment_id,
                storage_id: '',
                file_name: file.name,
                to_be_deleted: false,
                isNew: true,
            };

            resolve(attachment);
        } catch (e) {
            reject();
        }
    });
};

export const confirmAttachment = async (
    attachmentId: string
): Promise<void> => {
    await post<any, any>(`/attachment/confirm/`, {
        attachment_id: attachmentId,
    });
};

export const downloadFile = (attachmentId: string): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const downloadURL = await post<any, string>(
                `/attachment/url/generate/download/`,
                {
                    attachment_id: attachmentId,
                }
            );
            resolve(downloadURL.data);
        } catch (err) {
            reject(err);
        }
    });
};

export const bulkDownloadFiles = (payload: {
    [documentId: string]: {
        [fieldName: string]: string[];
    };
}): Promise<string> => {
    return new Promise(async (resolve, reject) => {
        try {
            const downloadURL = await post<any, string>(
                `/document/generate/bulk_download/`,
                payload
            );

            resolve(downloadURL.data);
        } catch (err) {
            reject(err);
        }
    });
};

export const getAttachmentsList = async (
    resourceUid: string,
    module: IFileResourceType
): Promise<IAttachment[]> => {
    try {
        let attachments: any = await get(
            `/attachment/?resource_id=${resourceUid}&module_type=${module}`
        );
        return attachments.data;
    } catch (error) {
        return [];
    }
};

export const getAttachmentsListFromId = async (
    attachment_ids: string[]
): Promise<IAttachment[]> => {
    try {
        const joinedattachment = attachment_ids.join(', ');

        let attachments = await get<IAttachment[]>(
            `/attachment/?attachment_ids[]=[${joinedattachment}]`
        );
        return attachments.data;
    } catch (error) {
        return [];
    }
};
