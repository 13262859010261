import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { HookStateValue, useHookState } from '../../../Common/Hooks/StateHook';
import { ITranscations } from '../../Interfaces/VendorLNInterfaces';
import { fetchTranscationsApi } from '../../Services/VendorLinkedIn.service';

export const useVLNRelationshipTransaction = (
    vendor_profile_id: string | null
) => {
    const { hookState, updateHookState } = useHookState(HookStateValue.INITIAL);

    const [transactions, setTransactions] = useState<ITranscations[] | null>(
        null
    );

    const fetchRelationshipTransactionList = useCallback(async () => {
        try {
            if (!vendor_profile_id) return;
            updateHookState(HookStateValue.LOADING);
            const relationshipTransactionListPromise =
                fetchTranscationsApi(vendor_profile_id);

            const [relationshipTransactionListResponse] = await Promise.all([
                relationshipTransactionListPromise,
            ]);

            setTransactions(relationshipTransactionListResponse);

            updateHookState(HookStateValue.READY);
        } catch (error) {
            updateHookState(HookStateValue.ERROR);

            toast.error('Error fetching transactions list');
        }
    }, [updateHookState, vendor_profile_id]);

    useEffect(() => {
        fetchRelationshipTransactionList();
    }, [fetchRelationshipTransactionList]);

    return {
        hookState,
        transactions,
    };
};
