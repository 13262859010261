import {
    ITermsAndConditionsDetails,
    ITermsAndConditionsListItem,
} from '../../Models/AwardsAndPO.model';
import { IUserDetails } from '../../Models/UserDetails.model';
import { ITermsAndConditionsEntityList } from '../../Organizations/Admin/Interfaces/TnCInterface';
import { get } from '../../Utils/api';

export const fetchSpecificUserDetails = (
    userId: string
): Promise<IUserDetails> => {
    return new Promise(async (resolve, reject) => {
        try {
            let resp = await get<IUserDetails>(
                `/organization/organization_user?user_id=${userId}`
            );
            resolve(resp.data);
        } catch (err) {
            reject();
        }
    });
};

export const getPurchaseOrderTermsAndConditionsList = (
    entityId: string
): Promise<ITermsAndConditionsListItem[]> => {
    return new Promise(async (resolve, reject) => {
        try {
            let resp = await get<ITermsAndConditionsListItem[]>(
                `/organization/terms_and_conditions/entity/${entityId}/`
            );
            resolve(resp.data);
        } catch (err) {
            reject();
        }
    });
};

export const getTermsAndConditionsDetails = (
    tnc_id: string
): Promise<ITermsAndConditionsDetails> => {
    return new Promise(async (resolve, reject) => {
        try {
            let resp = await get<ITermsAndConditionsDetails>(
                `/organization/terms_and_conditions/${tnc_id}/admin/`
            );
            resolve(resp.data);
        } catch (err) {
            reject();
        }
    });
};

export const getTermsAndConditionsEntitiesList = (
    tncId: string
): Promise<ITermsAndConditionsEntityList[]> => {
    return new Promise(async (resolve, reject) => {
        try {
            let resp = await get<any[]>(
                `/organization/terms_and_conditions/${tncId}/admin/entities/`
            );
            const entitiesList = resp.data
                .map((tnc: any, index: number) => {
                    const newItem: ITermsAndConditionsEntityList = {
                        id: tnc.terms_and_conditions,
                        tncId: tnc.terms_and_conditions,
                        entityName: tnc.entity_name,
                        entity: tnc.entity,
                        entryId: tnc.entry_id,
                        default: tnc.is_default,
                        status: tnc.status,
                    };
                    return newItem;
                })
                .filter((entity) => entity.status === 'ACTIVE');
            resolve(entitiesList);
        } catch (err) {
            reject();
        }
    });
};

export const getStructuredUserHierarchy = (
    child_user_id: string
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            let resp = await get<any>(
                `/organization/user_tree?child_user_id=${child_user_id}`
            );
            resolve(resp.data);
        } catch (err) {
            reject();
        }
    });
};

export const getFlatUserHierarchy = (
    child_user_id: string
): Promise<IUserDetails[]> => {
    return new Promise(async (resolve, reject) => {
        try {
            let resp = await get<any>(
                `/organization/user/${child_user_id}/ancestors/`
            );
            resolve(resp.data);
        } catch (err) {
            reject();
        }
    });
};
