import styled from '@emotion/styled';
import { Tooltip, TooltipProps } from '@mui/material';

const StyledTooltip = styled(Tooltip)({
    '&': {
        borderRadius: '2px',
    },
    '& .MuiTooltip-popper': {
        borderRadius: '2px',
    },
    '& .MuiTooltip-tooltip': {
        borderRadius: '2px',
    },
    '& .MuiTooltip-tooltipPlacementBottom': {
        borderRadius: '2px',
    },
});

export const FWTooltip = (props: TooltipProps) => {
    return <StyledTooltip placement="bottom" arrow {...props} />;
};
