import { CircularProgress, DialogContent, Grid } from '@mui/material';
import { FWPopupWithChildren } from '../../Common/FWPopupWithChildren';
import { FWTypography } from '../../Common/FWTypograhy';
import { HookStateValue } from '../../Common/Hooks/StateHook';
import PrepaymentInvoiceDetail from '../Containers/PrepaymentInvoiceDetail';
import {
    IPOPrepaymentInvoicesProvider,
    usePrepaymentInvoices,
} from '../Hooks/PrepaymentInvoicesHook';

interface IPrepaymentInvoicesPopupProps {
    open: boolean;
    poUid: string;
    handleClose: () => void;
    user_type: 'BUYER' | 'SELLER';
}

const PrepaymentInvoicesPopup = ({
    open,
    poUid,
    handleClose,
    user_type,
}: IPrepaymentInvoicesPopupProps) => {
    const { hookState, prepaymentInvoicesList }: IPOPrepaymentInvoicesProvider =
        usePrepaymentInvoices(poUid, user_type);

    return (
        <FWPopupWithChildren
            open={open}
            handleClose={handleClose}
            title="Seller prepayment invoices"
            size={'medium'}
            // popupWidth={'720px'}
            sx={{ maxWidth: '50vw !important' }}
        >
            <DialogContent sx={{ padding: '0 24px 24px' }}>
                <Grid container sx={{ minWidth: '100%', width: 'auto' }}>
                    {hookState.state === HookStateValue.LOADING ? (
                        <Grid
                            item
                            xs={12}
                            className="pb--15 pr--15 flex flex--jcc"
                        >
                            <CircularProgress size={30} />
                        </Grid>
                    ) : (
                        prepaymentInvoicesList.map((invoice, idx) => {
                            return (
                                <Grid item xs={12} key={idx}>
                                    <PrepaymentInvoiceDetail
                                        invoiceUid={invoice.invoiceUid}
                                        user_type={user_type}
                                        hookState={hookState}
                                        prepaymentInvoiceDetails={invoice}
                                    />
                                </Grid>
                            );
                        })
                    )}
                </Grid>
                {hookState.state === HookStateValue.READY &&
                    prepaymentInvoicesList.length === 0 && (
                        <FWTypography textAlign="center">
                            No invoices available
                        </FWTypography>
                    )}
            </DialogContent>
        </FWPopupWithChildren>
    );
};

export default PrepaymentInvoicesPopup;
