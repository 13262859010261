import { ICurrencyDetails } from '../../Models/Currency.model';
import { baseApiSlice } from '../../Redux/Slices/BaseApiSlice';

export const sharedApiSlice = baseApiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getAllCurrencies: builder.query<ICurrencyDetails[], {}>({
            query: () => '/backbone/currency_code',
            transformResponse: (response: ICurrencyDetails[]) => {
                return response.sort((a, b) =>
                    a.currency_code_abbreviation.localeCompare(
                        b.currency_code_abbreviation
                    )
                );
            },
        }),
    }),
});

export const { useGetAllCurrenciesQuery } = sharedApiSlice;
