import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getUserEventOnboardingStatus } from '../../Services/events/eventOperations';
import { cloneDeep } from 'lodash';

export enum OnboardingCheckListSubMenuKeys {
    EVENT_DEFAULTS = 'EVENT_DEFAULTS',
    EVENT_ADDRESS = 'EVENT_ADDRESS',
    EVENT_ITEMS = 'EVENT_ITEMS',
    EVENT_VENDORS = 'EVENT_VENDORS',
    EVENT_SUBMIT = 'EVENT_SUBMIT',
    BUYER_REVIEW_BID = 'BUYER_REVIEW_BID',
    BUYER_NEGOTIATE = 'BUYER_NEGOTIATE',
    EVENT_END = 'EVENT_END',
    PO_ITEMS = 'PO_ITEMS',
    PO_SUBMIT = 'PO_SUBMIT',
}
export interface IOpenSections {
    create_event: boolean;
    negotiate: boolean;
    purchase_order: boolean;
}

export interface IOnboardingCheckListData {
    module: string;
    checked: boolean;
    menuKey: string;
    title: string;
    videoLink: string;
    subtitle: string;
    moduleClass: string;
    subMenus: {
        subMenuKey: OnboardingCheckListSubMenuKeys;
        subMenuText: string;
        subMenuChecked: boolean;
        subMenuClass?: string;
    }[];
}

const sampleData = [
    {
        module: 'EVENT',
        checked: false,
        menuKey: 'create_event',
        title: 'Create Event',
        moduleClass: 'onboarding-module-create-event',
        subtitle: 'Lets create an event to get started',
        videoLink:
            'https://www.loom.com/embed/9cece65a64814d258a5ffd1b30fc1910/',
        subMenus: [
            {
                subMenuKey: OnboardingCheckListSubMenuKeys.EVENT_DEFAULTS,
                subMenuText: ' Select event defaults',
                subMenuChecked: false,
                subMenuClass: 'onboarding-submenu-event-defaults',
            },
            {
                subMenuKey: OnboardingCheckListSubMenuKeys.EVENT_ADDRESS,
                subMenuText: 'Add address',
                subMenuChecked: false,
                subMenuClass: 'onboarding-submenu-event-address',
            },
            {
                subMenuKey: OnboardingCheckListSubMenuKeys.EVENT_ITEMS,
                subMenuText: 'Select an item to add',
                subMenuChecked: false,
                subMenuClass: 'onboarding-submenu-event-items',
            },
            {
                subMenuKey: OnboardingCheckListSubMenuKeys.EVENT_VENDORS,
                subMenuText: 'Confirm the vendors (FW test vendors)',
                subMenuChecked: false,
                subMenuClass: 'onboarding-submenu-event-vendors',
            },
            {
                subMenuKey: OnboardingCheckListSubMenuKeys.EVENT_SUBMIT,
                subMenuText: 'Review and Submit the event to vendors',
                subMenuChecked: false,
                subMenuClass: 'onboarding-submenu-event-submit',
            },
        ],
    },
    {
        module: 'NEGOTIATE',
        checked: false,
        menuKey: 'negotiate',
        title: 'Negotiate',
        moduleClass: 'onboarding-module-negotiate',
        subtitle: 'Lets negotiate with the vendors',
        videoLink:
            'https://www.loom.com/embed/3fd72b10c87745c2bf8322dfaec797ca',
        subMenus: [
            {
                subMenuKey: OnboardingCheckListSubMenuKeys.BUYER_REVIEW_BID,
                subMenuText: 'Review bid',
                subMenuChecked: false,
                subMenuClass: 'onboarding-submenu-buyer-review-bid',
            },
            {
                subMenuKey: OnboardingCheckListSubMenuKeys.BUYER_NEGOTIATE,
                subMenuText: 'Negotiate',
                subMenuChecked: false,
                subMenuClass: 'onboarding-submenu-buyer-negotiate',
            },
            {
                subMenuKey: OnboardingCheckListSubMenuKeys.EVENT_END,
                subMenuText: 'End event',
                subMenuChecked: false,
                subMenuClass: 'onboarding-submenu-event-end',
            },
        ],
    },
    {
        module: 'AWARD',
        checked: false,
        menuKey: 'purchase_order',
        title: 'Purchase Orders',
        moduleClass: 'onboarding-module-purchase-order',
        subtitle: 'Lets create a purchase order',
        videoLink:
            'https://www.loom.com/embed/a1e7d8741b634f1c9b9cb3403e050dc1',
        subMenus: [
            {
                subMenuKey: OnboardingCheckListSubMenuKeys.PO_ITEMS,
                subMenuText: 'Allocate quantities across vendors',
                subMenuChecked: false,
                subMenuClass: 'onboarding-submenu-po-items',
            },
            {
                subMenuKey: OnboardingCheckListSubMenuKeys.PO_SUBMIT,
                subMenuText: 'Review and submit PO',
                subMenuChecked: false,
                subMenuClass: 'onboarding-submenu-po-submit',
            },
        ],
    },
];

const storeOnboardingChecklistData = (
    state: IOnboardingCheckListData[],
    action: {
        type: string;
        payload: { type: string; data: IOnboardingCheckListData[] };
    }
) => {
    if (action.payload.type === 'STORE_DATA') return action.payload.data;
};

const setTrueOnboardingChecklistData = (
    state: IOnboardingCheckListData[],
    action: {
        type: string;
        payload: { type: string; data: IOnboardingCheckListData[] };
    }
) => {
    if (action.payload.type === 'SET_TRUE') {
        state.map((item) =>
            item.subMenus.map((subItem) => {
                subItem.subMenuChecked = true;
                return subItem;
            })
        );
    }
};

export const OnBoardingChecklistSlice = createSlice<
    IOnboardingCheckListData[],
    {
        storeDataInChecklistStore: (
            state: IOnboardingCheckListData[],
            action: {
                type: string;
                payload: { type: string; data: IOnboardingCheckListData[] };
            }
        ) => void;
        setTrueChecklistData: (
            state: IOnboardingCheckListData[],
            action: {
                type: string;
                payload: { type: string; data: IOnboardingCheckListData[] };
            }
        ) => void;
    },
    string
>({
    name: 'OnBoardingChecklistState',
    initialState: sampleData,
    reducers: {
        storeDataInChecklistStore: storeOnboardingChecklistData,
        setTrueChecklistData: setTrueOnboardingChecklistData,
    },

    extraReducers: (builder) => {
        builder.addCase(fetchCheckListData.fulfilled, (state, action) => {
            if (action.payload) {
                state = action.payload;
                return state;
            }
        });
    },
});

export const { storeDataInChecklistStore, setTrueChecklistData } =
    OnBoardingChecklistSlice.actions;
const OnBoardingChecklistStore = OnBoardingChecklistSlice.reducer;
export default OnBoardingChecklistStore;

export const fetchCheckListData = createAsyncThunk(
    'checklist/fetch',
    async (userRole: string) => {
        const eventOnboarding = await getUserEventOnboardingStatus(
            userRole === 'USER' ? 'EXECUTIVE' : 'ADMIN'
        );
        let newOnBoardingCheckList = cloneDeep(sampleData);
        newOnBoardingCheckList = newOnBoardingCheckList.map((menuItem) => {
            let module_checked = true;
            menuItem.subMenus = menuItem.subMenus.map((subMenu) => {
                subMenu.subMenuChecked =
                    eventOnboarding[menuItem.module][subMenu.subMenuKey];
                if (!eventOnboarding[menuItem.module][subMenu.subMenuKey]) {
                    module_checked = false;
                }
                return subMenu;
            });
            menuItem.checked = module_checked;
            return menuItem;
        });
        return newOnBoardingCheckList;
    }
);
