import { baseApiSlice } from '../../Redux/Slices/BaseApiSlice';
import { get, post, put } from '../../Utils/api';
import { StatusFilterEnum } from '../Hooks/UOM/useMeasurementView';
import {
    IEnterpriseMeasurementUnitItem,
    IMeasurementUnit,
    IPaginatedBuyerMeasurementUnitsApiResponse,
} from '../Interfaces/UOMInterface';

export const fetchPaginatedBuyerMeasurementUnitConversionsApi = ({
    pageNumber,
    searchText,
    status,
    entityId,
}: {
    searchText: string;
    pageNumber: number;
    status: 'ACTIVE' | 'DISABLED' | null;
    entityId: string;
}): Promise<IPaginatedBuyerMeasurementUnitsApiResponse> => {
    return new Promise(async (resolve, reject) => {
        try {
            let resp: any = await post('/dashboard/', {
                dashboard_view: 'measurement_unit_conversions_buyer',
                tab: 'all',
                search_text: searchText,
                sort_fields: [],
                items_per_page: 30,
                page_number: pageNumber,
                query_data: {
                    measurement_status: status,
                    // entity_id: entityId === 'global' ? null : entityId,
                },
            });
            resolve(resp.data);
        } catch (error) {
            reject(error);
        }
    });
};

export const fetchMeasurementUnitsListApi = (
    measurement_unit_id?: string,
    status?: 'ACTIVE' | 'DISABLED'
): Promise<IMeasurementUnit[]> => {
    return new Promise(async (resolve, reject) => {
        try {
            let resp = await get(`/organization/measurement_unit/`);
            //
            resolve(resp.data as any);
        } catch (error) {
            reject(error);
        }
    });
};

interface IMeasurementViewApiResponse {
    data: IMeasurementUnit[];
    metadata: {
        current_page: number;
        has_next: boolean;
        has_previous: boolean;
        total_pages: number;
        page_range: {
            start: number;
            stop: number;
        };
        count: number;
    };
}

export const fetchMeasurementViewListApi = ({
    pageNumber,
    sortFields,
    searchText,
}: {
    pageNumber: number;
    sortFields: {
        field: string;
        ascending: boolean;
    }[];
    searchText: string;
}): Promise<IMeasurementViewApiResponse> => {
    return new Promise(async (resolve, reject) => {
        try {
            let resp = await post('/dashboard/', {
                dashboard_view: 'measurement_unit_buyer',
                tab: 'all',
                search_text: searchText,
                sort_fields: sortFields,
                items_per_page: 10,
                page_number: pageNumber,
                query_data: {},
            });
            resolve(resp.data as any);
        } catch (error) {
            reject(error);
        }
    });
};

export const { useGetUOMDataMutation, useGetMeasurementUnitListQuery } =
    baseApiSlice.injectEndpoints({
        endpoints: (builder) => ({
            getMeasurementUnitList: builder.query<IMeasurementUnit[], {}>({
                query: () => ({
                    url: `/organization/measurement_unit/`,
                }),
            }),
            getUOMData: builder.mutation<
                IMeasurementViewApiResponse,
                {
                    page_number: number;
                    items_per_page: number;
                    sort_fields: { field: string; ascending: boolean }[];
                    search_text: string;
                    query_data: {
                        search: string;
                        measurement_status: StatusFilterEnum;
                    };
                }
            >({
                query: ({
                    page_number,
                    items_per_page,
                    search_text,
                    sort_fields,
                    query_data,
                }) => ({
                    url: `/dashboard/`,
                    method: 'POST',
                    body: {
                        dashboard_view: 'measurement_unit_buyer',
                        tab: 'all',
                        query_data: {
                            search: query_data.search,
                            measurement_status:
                                query_data.measurement_status ===
                                StatusFilterEnum.ALL
                                    ? null
                                    : query_data.measurement_status,
                        },
                        search_text: search_text,
                        page_number,
                        sort_fields,
                        items_per_page,
                    },
                }),
            }),
        }),
    });

export const updateBuyerMeasurementUnitConversionApi = ({
    measurementUnitId,
    toMeasurementUnitId,
    value,
    status,
    enterpriseItemIdList = [],
    itemsToDelete = [],
    custom,
    enterprise_measurement_unit_id,
    to_enterprise_measurement_unit_id,
}: {
    measurementUnitId: string;
    toMeasurementUnitId: string;
    value: string;
    status: 'ACTIVE' | 'DISABLED';
    enterpriseItemIdList?: string[];
    enterprise_measurement_unit_id: string;
    to_enterprise_measurement_unit_id: string;
    itemsToDelete?: string[];
    custom: boolean;
}): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            let resp = await put(
                '/organization/measurement_unit/items/update/',
                {
                    measurement_unit_id: measurementUnitId,
                    deleted_enterprise_item_id_list: itemsToDelete,
                    enterprise_item_id_list: enterpriseItemIdList,
                    to_measurement_unit_id: toMeasurementUnitId,
                    enterprise_measurement_unit_id,
                    to_enterprise_measurement_unit_id,
                    value,
                    status,
                    custom,
                }
            );
            resolve(resp.data);
        } catch (error) {
            reject(error);
        }
    });
};

export interface IItemsForAUnit {
    enterprise_item_id: string;
    code: string;
    name: string;
}

export const fetchItemsForAUnitApi = (
    measurementUnitId: string,
    toMeasurementUnitId: string,
    enterprise_measurement_unit_id: string,
    to_enterprise_measurement_unit_id: string
): Promise<IItemsForAUnit[]> => {
    return new Promise(async (resolve, reject) => {
        try {
            let resp = await post(
                `/organization/measurement_unit/conversion/items/`,
                {
                    measurement_unit_id: measurementUnitId,
                    to_measurement_unit_id: toMeasurementUnitId,
                    enterprise_measurement_unit_id,
                    to_enterprise_measurement_unit_id,
                }
            );
            resolve(resp.data as any);
        } catch (error) {
            reject(error);
        }
    });
};

export const UOMConversionAlreadyExistsApi = ({
    measurementUnitId,
    toMeasurementUnitId,
    value,
}: {
    measurementUnitId: string;
    toMeasurementUnitId: string;
    value: string;
}): Promise<boolean> => {
    return new Promise(async (resolve, reject) => {
        try {
            let resp = await post(
                '/organization/measurement_unit/conversion/exists/',
                {
                    measurement_unit_id: measurementUnitId,
                    to_measurement_unit_id: toMeasurementUnitId,
                    value,
                }
            );
            resolve((resp.data as any).conversion_exists as boolean);
        } catch (error) {
            reject(error);
        }
    });
};

export const fetchUOMConversionVariantsApi = ({
    measurementUnitId,
    toMeasurementUnitId,
    status = null,
    enterprise_measurement_unit_id,
    to_enterprise_measurement_unit_id,
}: {
    measurementUnitId: string;
    toMeasurementUnitId: string;
    enterprise_measurement_unit_id: string;
    to_enterprise_measurement_unit_id: string;
    status: 'ACTIVE' | 'DISABLED' | null;
}): Promise<IEnterpriseMeasurementUnitItem[]> => {
    return new Promise(async (resolve, reject) => {
        try {
            let resp = await post(`/organization/measurement_unit/items/`, {
                measurement_unit_id: measurementUnitId,
                to_measurement_unit_id: toMeasurementUnitId,
                status,
                enterprise_measurement_unit_id,
                to_enterprise_measurement_unit_id,
            });
            resolve(resp.data as any);
        } catch (error) {
            reject(error);
        }
    });
};

export const updateMeasurementUnitApi = ({
    measurementUnitId,
    synonyms,
}: {
    measurementUnitId: string;
    synonyms: string[];
}): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            let resp = await put(
                `/organization/measurement_unit/${measurementUnitId}/update/`,
                {
                    measurement_unit_synonyms: synonyms,
                }
            );
            resolve(resp.data as any);
        } catch (error) {
            reject(error);
        }
    });
};

export const updateMeasurementUnitStatusApi = ({
    measurementUnitId,
    status,
}: {
    measurementUnitId: string;
    status: 'ACTIVE' | 'DISABLED';
}): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            let resp = await put(
                `/organization/measurement_unit/${measurementUnitId}/update/state/`,
                {
                    status,
                }
            );
            resolve(resp.data as any);
        } catch (error) {
            reject(error);
        }
    });
};

export const checkMeasurementUnitNameExistsApi = (
    name: string
): Promise<boolean> => {
    return new Promise(async (resolve, reject) => {
        try {
            let resp = await get(
                `/organization/measurement_unit/term/exists/?term=${name}`
            );
            resolve((resp.data as any).term_exists as boolean);
        } catch (error) {
            reject(error);
        }
    });
};
