import { Box } from '@mui/material';
import React, { memo } from 'react';
interface FWItemsPerPageProps {
    rowsPerPage: number;
    currentPage?: number;
    totalItems: number;
}

const FWItemsPerPage: React.FC<FWItemsPerPageProps> = ({
    rowsPerPage,
    currentPage = 1,
    totalItems,
}) => {
    const startItem = (currentPage - 1) * rowsPerPage + 1;
    const endItem = Math.min(currentPage * rowsPerPage, totalItems);

    return (
        <Box sx={{ fontSize: '16px' }}>
            {startItem} - {endItem} of {totalItems}
        </Box>
    );
};

export default memo(FWItemsPerPage);
