import { Box, Checkbox, Chip, Grid, Select } from '@mui/material';
import { cloneDeep } from 'lodash';
import {
    CostTypeEnum,
    IAdditionalCostsBackend,
} from '../../../../AdditionalCost/models/AdditionalCost.model';
import { FWIconButton } from '../../../../Common/FWButton';
import { FWMenuItem } from '../../../../Common/FWCustomMenu';
import { FWTooltip } from '../../../../Common/FWCustomTooltip';
import FWIcon from '../../../../Common/FWIcon';
import { FWInput } from '../../../../Common/FWInput';
import { OpenDownIcon } from '../../../../Common/FWSelect';
import { FWTypography } from '../../../../Common/FWTypograhy';
import { IINewItemTemplate } from '../../Hooks/ItemDirectory/ItemAdditionalCostHook';
import {
    ItemReducerAction,
    ItemUpdateActions,
} from '../../Hooks/ItemDirectory/ItemDirectoryHook';
import { IItemDetails } from '../../Interfaces/ItemDirectoryInterface';

export interface IItemAdditionalCostProps {
    templateDetails: IINewItemTemplate;
    data: IItemDetails;
    handleChange: (action: ItemReducerAction) => void;
    buyerCurrency: string;
    sellerCurrency: string;
    misMatchedCosts: IAdditionalCostsBackend[];
    tooltipTextForEachRemove: string;
    removeAdditionalCosts: (
        type: 'SINGLE' | 'ALL',
        costId?: string | null
    ) => void;
}

const ItemAdditionalCost = ({
    templateDetails,
    data,
    handleChange,
    buyerCurrency,
    sellerCurrency,
    misMatchedCosts,
    removeAdditionalCosts,
    tooltipTextForEachRemove,
}: IItemAdditionalCostProps) => {
    const handleSelectAllClick = () => {
        const allCostsSelected =
            data.additionalCost?.filter((cost) =>
                templateDetails.additionalCosts[0]?.fields.some(
                    (c) =>
                        c.additional_cost_information.additional_cost_id ===
                        cost.additional_cost_id
                )
            )?.length === templateDetails?.additionalCosts[0]?.fields?.length;

        if (allCostsSelected) {
            handleChange({
                type: ItemUpdateActions.ADDITIONAL_COST,
                value: [],
            });
            handleChange({
                type: ItemUpdateActions.BUYER_ADDITIONAL_COST,
                value: [],
            });
            handleChange({
                type: ItemUpdateActions.SELLER_ADDITIONAL_COST,
                value: [],
            });
        } else {
            const temp = cloneDeep(data.additionalCost);

            templateDetails?.additionalCosts[0].fields.forEach((cost) => {
                if (cost.is_hidden) return;

                if (
                    data.additionalCost.find(
                        (c) =>
                            c.additional_cost_id ===
                            cost.additional_cost_information.additional_cost_id
                    ) === undefined
                ) {
                    temp.push({
                        allocation_type:
                            cost.additional_cost_information.allocation_type ??
                            null,
                        cost_name: cost.name,
                        cost_source:
                            cost.additional_cost_information.cost_source ??
                            null,
                        cost_type:
                            cost.additional_cost_information.cost_type ??
                            CostTypeEnum.PERCENTAGE,
                        cost_value: 0,
                        additional_cost_id:
                            cost.additional_cost_information.additional_cost_id,
                    });
                }
            });

            handleChange({
                type: ItemUpdateActions.ADDITIONAL_COST,
                value: temp,
            });
            handleChange({
                type: ItemUpdateActions.BUYER_ADDITIONAL_COST,
                value: temp,
            });
            handleChange({
                type: ItemUpdateActions.SELLER_ADDITIONAL_COST,
                value: temp,
            });
        }
    };

    const handleAdditionalCostValueChange = (
        costId: string | null,
        newValue: string
    ) => {
        const additionalCostInData = data.additionalCost?.find(
            (cost) => cost.additional_cost_id === costId
        );

        if (additionalCostInData) {
            additionalCostInData.cost_value = newValue;
        }
        handleChange({
            type: ItemUpdateActions.ADDITIONAL_COST,
            value: data.additionalCost,
        });
        handleChange({
            type: ItemUpdateActions.BUYER_ADDITIONAL_COST,
            value: data.additionalCost,
        });
        handleChange({
            type: ItemUpdateActions.SELLER_ADDITIONAL_COST,
            value: data.additionalCost,
        });
    };

    const ToolTipRender = ({ index }: { index: number }) => {
        if (
            Boolean(data?.buyerPricingInformation?.currencyCodeId) &&
            Boolean(data.sellerPricingInformation.currencyCodeId)
        ) {
            return (
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <FWTooltip
                        title={`For buyer, the currency is ${buyerCurrency} and for seller, the currency is ${sellerCurrency} `}
                    >
                        <Box>
                            <FWIcon
                                color={'primary.main'}
                                size={16}
                                name="bi bi-info-circle"
                            />
                        </Box>
                    </FWTooltip>
                </Box>
            );
        } else if (
            Boolean(data.buyerPricingInformation?.currencyCodeId) &&
            !Boolean(data.sellerPricingInformation.currencyCodeId)
        ) {
            return (
                <FWTooltip
                    title={`For buyer, the currency is ${buyerCurrency}`}
                >
                    <Box>
                        <FWIcon
                            color={'primary.main'}
                            size={16}
                            name="bi bi-info-circle"
                        />
                    </Box>
                </FWTooltip>
            );
        } else if (
            !Boolean(data.buyerPricingInformation.currencyCodeId) &&
            Boolean(data.sellerPricingInformation.currencyCodeId)
        ) {
            return (
                <FWTooltip
                    title={`For seller, the currency is ${sellerCurrency}`}
                >
                    <Box>
                        <FWIcon
                            color={'primary.main'}
                            size={16}
                            name="bi bi-info-circle"
                        />
                    </Box>
                </FWTooltip>
            );
        }
        return <></>;
    };

    const filterAdditionalCost = (cost: IAdditionalCostsBackend) => {
        if (data.additionalCost) {
            if (
                data.additionalCost.some(
                    (buyerAC) =>
                        buyerAC.additional_cost_id === cost.additional_cost_id
                )
            ) {
                let filteredBuyerAdditionalCost = data.additionalCost.filter(
                    (buyerAC) =>
                        buyerAC.additional_cost_id !== cost.additional_cost_id
                );

                handleChange({
                    type: ItemUpdateActions.BUYER_ADDITIONAL_COST,
                    value: filteredBuyerAdditionalCost,
                });

                handleChange({
                    type: ItemUpdateActions.ADDITIONAL_COST,
                    value: filteredBuyerAdditionalCost,
                });
            } else {
                let obj: IAdditionalCostsBackend = {
                    cost_source: cost.cost_source ?? null,
                    allocation_type: cost.allocation_type ?? null,
                    cost_name: cost.cost_name,
                    cost_type: cost.cost_type,
                    cost_value: cost.cost_value,
                    additional_cost_id: cost.additional_cost_id,
                };

                handleChange({
                    type: ItemUpdateActions.BUYER_ADDITIONAL_COST,
                    value: [...data.additionalCost, obj],
                });

                handleChange({
                    type: ItemUpdateActions.ADDITIONAL_COST,
                    value: [...data.additionalCost, obj],
                });
            }
        }
    };

    return (
        <Grid container>
            <Grid item xs={11.25}>
                <FWTypography color={'text.secondary'}>
                    Select additional costs
                </FWTypography>

                <Select
                    fullWidth
                    IconComponent={OpenDownIcon}
                    size="small"
                    value={data.additionalCost}
                    multiple
                    renderValue={() => {
                        return data.additionalCost
                            ?.filter((cost) =>
                                templateDetails.additionalCosts[0]?.fields.some(
                                    (c) =>
                                        c.additional_cost_information
                                            .additional_cost_id ===
                                        cost.additional_cost_id
                                )
                            )
                            ?.map((cost) => (
                                <Chip
                                    label={cost.cost_name}
                                    sx={{
                                        marginRight: '5px',
                                    }}
                                />
                            ));
                    }}
                    MenuProps={{
                        PaperProps: {
                            sx: {
                                bgcolor: 'white',
                                maxHeight: '300px',
                                boxShadow: '0px 5px 16px #C4C4c8',
                            },
                        },
                    }}
                >
                    <FWMenuItem
                        value={'SELECT_ALL'}
                        sx={{ display: 'flex' }}
                        onClick={handleSelectAllClick}
                    >
                        <Checkbox
                            sx={{
                                '&.MuiCheckbox-root': {
                                    padding: '0px 9px',
                                },
                            }}
                            checked={
                                data.additionalCost?.filter((cost) =>
                                    templateDetails.additionalCosts[0]?.fields.some(
                                        (c) =>
                                            c.additional_cost_information
                                                .additional_cost_id ===
                                            cost.additional_cost_id
                                    )
                                )?.length ===
                                templateDetails?.additionalCosts[0]?.fields
                                    ?.length
                            }
                        />
                        <FWTypography
                            sx={{
                                marginRight: '10px',
                            }}
                        >
                            Select all
                        </FWTypography>
                    </FWMenuItem>
                    {templateDetails.additionalCosts[0]?.fields.map(
                        (option) => (
                            <FWMenuItem
                                value={option.name}
                                sx={{ display: 'flex' }}
                                onClick={() => {
                                    filterAdditionalCost({
                                        additional_cost_id:
                                            option.additional_cost_information
                                                .additional_cost_id,
                                        allocation_type:
                                            option.additional_cost_information
                                                .allocation_type ?? null,
                                        cost_source:
                                            option.additional_cost_information
                                                .cost_source ?? null,
                                        cost_name: option.name,
                                        cost_type:
                                            option.additional_cost_information
                                                .cost_type ??
                                            CostTypeEnum.PERCENTAGE,
                                        cost_value:
                                            data.additionalCost.find(
                                                (cost) =>
                                                    cost.additional_cost_id ===
                                                    option
                                                        .additional_cost_information
                                                        .additional_cost_id
                                            )?.cost_value ?? 0,
                                    });
                                }}
                            >
                                <Checkbox
                                    sx={{
                                        '&.MuiCheckbox-root': {
                                            padding: '0px 9px',
                                        },
                                    }}
                                    checked={data.additionalCost.some(
                                        (cost) =>
                                            cost.additional_cost_id ===
                                            option.additional_cost_information
                                                .additional_cost_id
                                    )}
                                />
                                <FWTypography
                                    sx={{
                                        marginRight: '10px',
                                    }}
                                >
                                    {option.name}
                                </FWTypography>
                            </FWMenuItem>
                        )
                    )}
                </Select>
            </Grid>

            <Grid container>
                {data.additionalCost?.map((cost, indexOfCost) => {
                    let costInfoFromTemplate =
                        templateDetails.additionalCosts[0]?.fields?.find(
                            (tempCost) =>
                                tempCost.additional_cost_information
                                    .additional_cost_id ===
                                cost.additional_cost_id
                        );

                    if (costInfoFromTemplate) {
                        if (costInfoFromTemplate.is_hidden) {
                            return <></>;
                        } else {
                            return (
                                <Grid
                                    container
                                    key={indexOfCost}
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                    gap={1.25}
                                    marginTop={2}
                                >
                                    <Grid item xs={10}>
                                        <FWInput
                                            label={cost.cost_name}
                                            required={true}
                                            allowOnly={
                                                cost.cost_type ===
                                                CostTypeEnum.PERCENTAGE
                                                    ? 'PERCENT'
                                                    : 'DECIMAL_NUMBER'
                                            }
                                            value={cost.cost_value}
                                            maxNumberOfDecimal={
                                                cost.cost_type ===
                                                CostTypeEnum.PERCENTAGE
                                                    ? 4
                                                    : 10
                                            }
                                            onChange={(e) => {
                                                handleAdditionalCostValueChange(
                                                    cost.additional_cost_id,
                                                    e.target.value
                                                );
                                            }}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={'auto'}
                                        paddingTop={2.5}
                                        marginY={'auto'}
                                    >
                                        <ToolTipRender index={indexOfCost} />
                                    </Grid>
                                    <Grid
                                        item
                                        // marginLeft={2}
                                        xs={'auto'}
                                        paddingTop={2.5}
                                        marginY={'auto'}
                                    >
                                        <FWTooltip
                                            title={`Remove additional cost`}
                                        >
                                            <Box>
                                                <FWIconButton
                                                    color="error"
                                                    onClick={() =>
                                                        filterAdditionalCost(
                                                            cost
                                                        )
                                                    }
                                                    sx={{
                                                        width: '30px',
                                                        height: '30px',
                                                    }}
                                                >
                                                    <FWIcon
                                                        name="bi bi-dash-circle"
                                                        size={22}
                                                    />
                                                </FWIconButton>
                                            </Box>
                                        </FWTooltip>
                                    </Grid>
                                </Grid>
                            );
                        }
                    } else {
                        return <></>;
                    }
                })}
            </Grid>

            {misMatchedCosts && (
                <Grid item xs={12}>
                    <MismatchedAdditionalCost
                        misMatchedCosts={misMatchedCosts}
                        mode="EDIT"
                        tooltipTextForEachRemove={
                            'The cost is no longer in the template'
                        }
                        gridSizeForViewMode={12}
                        gridSizeForEditModeInputField={10}
                        removeAdditionalCosts={(
                            type: 'SINGLE' | 'ALL',
                            costId?: string | null
                        ) => {
                            removeAdditionalCosts(type, costId);
                        }}
                        alignVertically={true}
                        hideRemoveButtonForViewMode={false}
                    />
                </Grid>
            )}
        </Grid>
    );
};

export interface IMisMatchedAdditionalCostProps {
    misMatchedCosts: IAdditionalCostsBackend[];
    tooltipTextForEachRemove: string;
    removeAdditionalCosts: (
        type: 'SINGLE' | 'ALL',
        costId?: string | null
    ) => void;
    alignVertically: boolean;
    mode: 'VIEW' | 'EDIT';
    gridSizeForViewMode: number;
    gridSizeForEditModeInputField: number;
    hideRemoveButtonForViewMode: boolean;
}

export const MismatchedAdditionalCost = ({
    misMatchedCosts,
    removeAdditionalCosts,
    tooltipTextForEachRemove,
    alignVertically,
    mode,
    gridSizeForViewMode,
    hideRemoveButtonForViewMode,
    gridSizeForEditModeInputField,
}: IMisMatchedAdditionalCostProps) => {
    const ToolTipRender = () => (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <FWTooltip title={tooltipTextForEachRemove}>
                <Box>
                    <FWIcon
                        color={'primary.main'}
                        size={16}
                        name="bi bi-info-circle"
                    />
                </Box>
            </FWTooltip>
        </Box>
    );
    return (
        <Grid
            // display={alignVertically ? '' : 'flex'}
            style={{
                display: alignVertically ? '' : 'grid',
                gridTemplateColumns: alignVertically ? '' : 'repeat(4, 1fr)',
                gap: alignVertically ? '' : '10px',
            }}
        >
            {misMatchedCosts?.map((cost, indexOfMismatchedCost) => {
                return mode === 'EDIT' ? (
                    <Grid
                        container
                        key={indexOfMismatchedCost}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                        gap={1.25}
                        marginTop={2}
                    >
                        <Grid item xs={gridSizeForEditModeInputField}>
                            <FWTypography color={'text.secondary'}>
                                {cost.cost_name}
                            </FWTypography>
                            <FWInput
                                required={true}
                                allowOnly={
                                    cost.cost_type === CostTypeEnum.PERCENTAGE
                                        ? 'PERCENT'
                                        : 'DECIMAL_NUMBER'
                                }
                                value={cost.cost_value}
                                disabled={true}
                                error={true}
                                helper={{
                                    text: 'Invalid additional cost',
                                }}
                            />
                        </Grid>{' '}
                        <Grid item xs={'auto'} marginY={'auto'}>
                            <ToolTipRender />
                        </Grid>
                        <Grid item xs={'auto'} marginY={'auto'}>
                            <FWTooltip title={`Remove additional cost`}>
                                <Box>
                                    <FWIconButton
                                        color="error"
                                        onClick={() =>
                                            removeAdditionalCosts(
                                                'SINGLE',
                                                cost.additional_cost_id
                                            )
                                        }
                                        sx={{
                                            width: '20px',
                                            height: '20px',
                                        }}
                                    >
                                        <FWIcon
                                            name="bi bi-dash-circle"
                                            size={22}
                                        />
                                    </FWIconButton>
                                </Box>
                            </FWTooltip>
                        </Grid>
                    </Grid>
                ) : (
                    <Grid
                        container
                        key={indexOfMismatchedCost}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                        gap={1.5}
                        marginTop={2}
                    >
                        <Grid item xs={'auto'}>
                            <FWTypography
                                color={'error.main'}
                                sx={{ display: 'flex', gap: '4.5px' }}
                            >
                                {cost.cost_name}
                                <ToolTipRender />
                            </FWTypography>
                            <FWTypography color={'text.secondary'}>
                                {cost.cost_value}
                            </FWTypography>
                        </Grid>

                        {!hideRemoveButtonForViewMode && (
                            <Grid
                                item
                                xs={'auto'}
                                marginY={'auto'}
                                marginLeft={2}
                            >
                                <FWTooltip title={`Remove additional cost`}>
                                    <Box>
                                        <FWIconButton
                                            color="error"
                                            onClick={() =>
                                                removeAdditionalCosts(
                                                    'SINGLE',
                                                    cost.additional_cost_id
                                                )
                                            }
                                            sx={{
                                                width: '24px',
                                                height: '24px',
                                            }}
                                        >
                                            <FWIcon
                                                name="bi bi-dash-circle"
                                                size={22}
                                            />
                                        </FWIconButton>
                                    </Box>
                                </FWTooltip>
                            </Grid>
                        )}
                    </Grid>
                );
            })}
        </Grid>
    );
};

export default ItemAdditionalCost;
