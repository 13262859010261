import { Box, IconButton } from '@mui/material';
import { Dispatch, SetStateAction, useContext, useState } from 'react';
import { FWTooltip } from '../../../../../Common/FWCustomTooltip';
import FWIcon from '../../../../../Common/FWIcon';
import { FWTypography } from '../../../../../Common/FWTypograhy';
import { ICustomTemplateList } from '../../../../../Global/Interfaces/CustomTemplate.interface';
import {
    ASModule,
    IAdminCustomFieldsAndChecklist,
} from '../../../Interfaces/AdminSettingsInterface';
import ChecklistPopup from './ChecklistPopup';
import ChecklistAddPopup from './ChecklistAddPopup';
import { useFeatureAccess } from '../../../../../FeatureAccess/Hooks/FeatureAccessHook';
import { planUpgradeTooltipText } from '../../../../../FeatureAccess/Utils/planUpgradeTooltipText';
import { AuthContext } from '../../../../../Contexts/AuthContext';

interface IChecklist {
    checkLists: ICustomTemplateList[];
    setAdminTemplateSettings: Dispatch<
        SetStateAction<IAdminCustomFieldsAndChecklist>
    >;
    selectedEntity: string;
    type: 'GRN' | 'QC';
}

const Checklist = ({
    checkLists,
    setAdminTemplateSettings,
    selectedEntity,
    type,
}: IChecklist) => {
    const [open, setOpen] = useState<boolean>(false);

    const handleAddChecklistPopupToggle = () => {
        setOpen(!open);
    };
    const { isFeatureAvailable } = useFeatureAccess();
    const { authData } = useContext(AuthContext);

    return (
        <>
            <Box>
                <div className="flex flex--aic">
                    <FWTypography color="text.secondary">
                        Checklist
                    </FWTypography>
                    {authData.details?.role === 'ADMIN' && (
                        <FWTooltip
                            title={
                                isFeatureAvailable(
                                    type === 'GRN'
                                        ? 'feature-gr-customchecklists-enabled'
                                        : 'feature-qc-customchecklists-enabled'
                                )
                                    ? checkLists.length >= 3
                                        ? 'Reached max number of checklists'
                                        : ''
                                    : planUpgradeTooltipText.featureUnavailableText
                            }
                        >
                            <div>
                                <FWTooltip title="Add checklist">
                                    <IconButton
                                        sx={{ ml: '5px' }}
                                        color="primary"
                                        onClick={handleAddChecklistPopupToggle}
                                        disabled={
                                            !isFeatureAvailable(
                                                type === 'GRN'
                                                    ? 'feature-gr-customchecklists-enabled'
                                                    : 'feature-qc-customchecklists-enabled'
                                            ) || checkLists.length >= 3
                                        }
                                    >
                                        <FWIcon
                                            name="bi bi-plus-circle"
                                            size={20}
                                        />
                                    </IconButton>
                                </FWTooltip>
                            </div>
                        </FWTooltip>
                    )}
                </div>
                {checkLists.map((checklist, index) => {
                    return authData.details?.role === 'ADMIN' ? (
                        <ChecklistPopup
                            index={index}
                            data={checklist}
                            key={index}
                            module_name={
                                type === 'GRN'
                                    ? ASModule.GOODS_RECEIPT
                                    : ASModule.QUALITY_CHECK
                            }
                            setAdminTemplateSettings={setAdminTemplateSettings}
                            disableCheckList={
                                !isFeatureAvailable(
                                    type === 'GRN'
                                        ? 'feature-gr-customchecklists-enabled'
                                        : 'feature-qc-customchecklists-enabled'
                                )
                            }
                        />
                    ) : (
                        <FWTypography>
                            {`${index + 1}. ${checklist.template_name}`}
                        </FWTypography>
                    );
                })}
                <ChecklistAddPopup
                    module_name={
                        type === 'GRN'
                            ? ASModule.GOODS_RECEIPT
                            : ASModule.QUALITY_CHECK
                    }
                    selectedEntity={selectedEntity}
                    open={open}
                    handleClose={handleAddChecklistPopupToggle}
                    setAdminTemplateSettings={setAdminTemplateSettings}
                />
            </Box>
        </>
    );
};

export default Checklist;
