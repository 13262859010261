import { createSlice } from '@reduxjs/toolkit';
import { IAdminSettingModule } from '../../Organizations/Admin/Interfaces/AdminSettingsInterface';

export interface IAdminSelectedSettingState {
    selectedEntity: string | null;
    selectedSetting: IAdminSettingModule[] | null;
}

export const AdminSelectedSettingSlice = createSlice<
    IAdminSelectedSettingState,
    {
        updateSelectedSetting: (
            state: IAdminSelectedSettingState,
            action: { payload: IAdminSelectedSettingState | null }
        ) => void;
    }
>({
    name: 'AdminSelectedSetting',
    initialState: {
        selectedEntity: null,
        selectedSetting: null,
    },
    reducers: {
        updateSelectedSetting: (state, action) => {
            state.selectedSetting = action.payload?.selectedSetting ?? null;
            state.selectedEntity = action.payload?.selectedEntity ?? null;
        },
    },
});

export const { updateSelectedSetting } = AdminSelectedSettingSlice.actions;

export default AdminSelectedSettingSlice.reducer;
