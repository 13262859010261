import { Tab, Tabs } from '@mui/material';
import React, { useContext } from 'react';
import { TVendorProfileChip } from '../Interfaces/VendorLNInterfaces';
import { AuthContext } from '../../Contexts/AuthContext';

const VendorLNMainTab = ({
    tabValue,
    setTabValue,
}: {
    tabValue: TVendorProfileChip;
    setTabValue: React.Dispatch<React.SetStateAction<TVendorProfileChip>>;
}) => {
    const { authData } = useContext(AuthContext);
    return (
        <>
            <Tabs
                value={tabValue}
                onChange={(e, newValue) => {
                    setTabValue(newValue as any);
                }}
            >
                <Tab label="Profile" value={'profile'} />
                {authData?.details?.role !== 'ADMIN_VIEW' && (
                    <Tab label="Relationship" value={'relationship'} />
                )}
                {/* <Tab label="FW insights" value={'insights'} /> */}
            </Tabs>
        </>
    );
};

export default VendorLNMainTab;
