import { useCallback, useContext, useLayoutEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AuthContext } from '../../Contexts/AuthContext';
import {
    PermissionModule,
    PermissionPortal,
    PermissionType,
    PermissionTypes,
} from '../../Models/AuthContext.model';
import { IVerificationState } from '../../Redux/Slices/VerificationSlice';

export interface IEntityPermissionMap {
    [key: string]: {
        [key in PermissionTypes]: boolean;
    };
}

const usePermissionCheck = (
    permissionPortal: PermissionPortal,
    permissionModule: PermissionModule,
    permissionKeys: PermissionType[]
) => {
    const { checkPermission } = useContext(AuthContext);

    const { allUserEntities } = useSelector(
        ({ VerificationStore }: { VerificationStore: IVerificationState }) =>
            VerificationStore
    );

    const [permissions, setPermissions] = useState<IEntityPermissionMap>({});

    const getPermissions = useCallback(() => {
        const entityUids = allUserEntities.map((e) => e.entityId);
        const permMap: IEntityPermissionMap = {};
        entityUids.forEach((entity) => {
            permissionKeys.forEach((key) => {
                const perm = checkPermission(
                    permissionPortal,
                    permissionModule,
                    key,
                    entity
                );
                if (key) permMap[entity] = { ...permMap[entity], [key]: perm };
            });
        });
        setPermissions(permMap);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allUserEntities]);

    useLayoutEffect(() => {
        getPermissions();
    }, [getPermissions]);

    return [permissions] as const;
};

export default usePermissionCheck;
