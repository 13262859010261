import {
    Box,
    Chip,
    DialogContent,
    Grid,
    IconButton,
    Typography,
} from '@mui/material';
import { debounce, isEmpty } from 'lodash';
import { useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { FWTooltip } from '../../../../Common/FWCustomTooltip';
import FWPopover from '../../../../Common/FWPopover';
import { FWPopup } from '../../../../Common/FWPopup';
import { FWTypography } from '../../../../Common/FWTypograhy';
import {
    IEntityVendorListSummary,
    IVendorContact,
    IVendorListSummary,
    VendorStatus,
    VendorVerificationStatus,
} from '../../Interfaces/VendorsInterface';
import VendorEnterpriseContactPopup from '../../Pages/Vendors/VendorEnterpriseContactPopup';
import VendorEntityContactPopup from '../../Pages/Vendors/VendorEntityContactPopup';
import {
    resendVendorEnterpriseInvitation,
    resendVendorEntitiesInvitation,
} from '../../Services/VendorsService';
import VendorContactStatusChip, {
    getVendorContactStatus,
} from './VendorContactStatusChip';

export const EnterprisePrimaryContactCell = ({
    data,
    context,
}: {
    data: IVendorListSummary;
    context: {
        currentPage: number;
        searchText: string;
        sortFields: {
            field: string;
            ascending: boolean;
        }[];
        refetchVendorData: any;
        showOptions: boolean;
        portal: 'VENDOR_MASTER' | 'BUYER_MASTER';
    };
}) => {
    const { currentPage, searchText, sortFields, refetchVendorData } = context;
    const [openPopup, setOpenPopup] = useState<boolean>(false);

    const handlePopupToggle = () => {
        setOpenPopup(!openPopup);
    };
    const [openInvitationPopup, setOpenInvitationPopup] = useState(false);
    const isInvitePending =
        data.primaryContact.invitationStatus === VendorStatus.INVITED;
    const sendInvitationMail = useMemo(
        () =>
            debounce(async (vendorDirUid, vendorStatus) => {
                setOpenInvitationPopup(false);
                const statusChanged =
                    vendorStatus === VendorStatus.INVITED
                        ? await resendVendorEnterpriseInvitation(
                              vendorDirUid,
                              data.primaryContact.email,
                              context.portal
                          )
                        : await resendVendorEntitiesInvitation(
                              vendorDirUid,
                              [data.primaryContact.email],
                              context.portal
                          );
                if (statusChanged) {
                    toast.success(`Invitation sent successfully`);
                } else {
                    toast.error(
                        `Error occurred while sending invitation, please try later`
                    );
                }
            }, 300),
        [context.portal, data.primaryContact.email]
    );

    const vendorContact: IVendorContact = useMemo(() => {
        const { primaryContact: pc } = data;
        const details: IVendorContact = {
            fullName: pc.fullName,
            sellerEmails: [],
            buyerEmails: [
                {
                    email: pc.email,
                    visible: true,
                },
            ],
            sellerContacts: [],
            buyerContacts:
                pc.contacts?.length > 0
                    ? pc.contacts.map((contact) => ({
                          number: contact,
                          visible: true,
                      }))
                    : [
                          {
                              number: '',
                              visible: true,
                          },
                      ],
            userUid: pc.userUid,
            vendorContactUid: pc.vendorContactUid,
            isPrimary: true,
            invitationStatus: pc.invitationStatus,
            notes: pc.notes,
            tags: [],
            CCEmail: [],
            secondaryEmails: [],
        };
        return details;
    }, [data]);

    return (
        <div className="flex flex--aic" style={{ maxWidth: '100%' }}>
            <Box
                color="primary.main"
                sx={{ maxWidth: '100%' }}
                ref={(ref: HTMLDivElement) => {
                    if (!ref) return;
                    ref.onclick = (e: any) => {
                        e.stopPropagation();
                        context.showOptions && handlePopupToggle();
                    };
                }}
            >
                <Typography sx={{ maxWidth: '100%' }}>
                    {data.primaryContact.fullName}
                </Typography>
            </Box>

            {data.primaryContact.invitationStatus === 'INVITED' && (
                <Chip
                    label={
                        getVendorContactStatus(
                            data.primaryContact.invitationStatus
                        ).label
                    }
                    size="small"
                    className="ml--15"
                    variant={isInvitePending ? 'outlined' : 'filled'}
                    color={isInvitePending ? 'primary' : undefined}
                    sx={{
                        backgroundColor: isInvitePending
                            ? 'rgba(35, 131, 226, 0.2)'
                            : '',
                        color: isInvitePending
                            ? 'rgb(35, 131, 226)'
                            : 'text.secondary',
                        border: isInvitePending ? ' 0px' : '',
                    }}
                />
            )}
            {context.showOptions && isInvitePending && (
                <FWTooltip title="Resend Invitation">
                    <IconButton
                        sx={{
                            fontSize: '1.3rem',
                            color: 'rgb(35, 131, 226)',
                            marginLeft: '0.5rem',
                            borderRadius: '50%!important',
                        }}
                        ref={(ref) => {
                            if (!ref) return;
                            ref.onclick = (e: any) => {
                                e.stopPropagation();
                                setOpenInvitationPopup(true);
                            };
                        }}
                    >
                        <i className="bi bi-envelope"></i>
                    </IconButton>
                </FWTooltip>
            )}

            <VendorEnterpriseContactPopup
                open={openPopup}
                data={vendorContact}
                vendorUid={data.vendorUid}
                isVerified={
                    data.vendorVerificationStatus ===
                    VendorVerificationStatus.VERIFIED
                }
                onUpdateSuccess={() =>
                    refetchVendorData({
                        tab: 'active',
                        items_per_page: 10,
                        page_number: currentPage,
                        search_text: searchText,
                        sort_fields: sortFields,
                    })
                }
                handlePopupToggle={handlePopupToggle}
            />
            <FWPopup
                title={`Resend invitation`}
                msg={`The invitation will be resent to ${data.primaryContact.fullName} (${data.primaryContact.email}) for entity ${data.vendorName}.`}
                btnColor={'primary'}
                btnTitle="Confirm"
                open={openInvitationPopup}
                setOpen={setOpenInvitationPopup}
                onClickConfirm={() => {
                    sendInvitationMail(data.vendorDirUid, data.vendorStatus);
                }}
            />
        </div>
    );
};

export const EntityPrimaryContactCell = ({
    data,
    context,
}: {
    data: IEntityVendorListSummary;
    context: {
        entityUid: string;
        getVendorsList: () => void;
        showOptions: boolean;
    };
}) => {
    const { entityUid, getVendorsList } = context;
    const [openPopup, setOpenPopup] = useState<boolean>(false);

    const handlePopupToggle = () => {
        setOpenPopup(!openPopup);
    };

    const vendorContact: IVendorContact = useMemo(() => {
        const { primaryContact: pc } = data;
        const details: IVendorContact = {
            fullName: pc.fullName,
            sellerEmails: [],
            buyerEmails: [
                {
                    email: pc.email,
                    visible: true,
                },
            ],
            sellerContacts: [],
            buyerContacts:
                pc.contacts.length > 0
                    ? pc.contacts.map((contact) => ({
                          number: contact,
                          visible: true,
                      }))
                    : [
                          {
                              number: '',
                              visible: true,
                          },
                      ],
            userUid: pc.userUid,
            vendorContactUid: pc.vendorContactUid,
            isPrimary: true,
            invitationStatus: 'INVITATION_PENDING', // TODO: hard-coded
            notes: '', // TODO: hard-coded
            tags: [],
            CCEmail: [],
            secondaryEmails: [],
        };
        return details;
    }, [data]);

    return (
        <div className="flex flex--aic" style={{ width: '100%' }}>
            <Box
                color="primary.main"
                sx={{ maxWidth: '100%' }}
                ref={(ref: HTMLDivElement) => {
                    if (!ref) return;
                    ref.onclick = (e: any) => {
                        e.stopPropagation();
                        context.showOptions && handlePopupToggle();
                    };
                }}
            >
                <Typography>{data.primaryContact.fullName}</Typography>
            </Box>

            <VendorEntityContactPopup
                open={openPopup}
                data={vendorContact}
                vendorUid={data.vendorUid}
                onUpdateSuccess={getVendorsList}
                handlePopupToggle={handlePopupToggle}
                entityUid={entityUid}
                portal="VENDOR_MASTER"
            />
        </div>
    );
};

export const EnterpriseSecondaryContactCell = ({
    data,
    context,
}: {
    data: IVendorListSummary;
    context: {
        currentPage: number;
        searchText: string;
        sortFields: {
            field: string;
            ascending: boolean;
        }[];
        refetchVendorData: any;
        showOptions: boolean;
        portal: 'VENDOR_MASTER' | 'BUYER_MASTER';
    };
}) => {
    const [openPopup, setOpenPopup] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const [openInvitationPopup, setOpenInvitationPopup] = useState<{
        open: boolean;
        id: number;
    }>({
        open: false,
        id: -1,
    });

    const sendInvitationMail = useMemo(
        () =>
            debounce(async (vendorDirUid, vendorStatus, email) => {
                setOpenInvitationPopup((prev) => ({
                    ...prev,
                    open: false,
                }));
                const statusChanged =
                    vendorStatus === VendorStatus.INVITED
                        ? await resendVendorEnterpriseInvitation(
                              vendorDirUid,
                              email,
                              context.portal
                          )
                        : await resendVendorEntitiesInvitation(
                              vendorDirUid,
                              [email],
                              context.portal
                          );
                if (statusChanged) {
                    toast.success(`Invitation sent successfully`);
                } else {
                    toast.error(
                        `Error occurred while sending invitation, please try later`
                    );
                }
            }, 300),
        [context.portal]
    );

    return (
        <>
            <Box
                ref={(ref: any) => {
                    if (!ref) return;
                    ref.onclick = (e: any) => {
                        if (data.secondaryContact.length > 0) {
                            e.stopPropagation();
                            setAnchorEl(e.target);
                            setOpenPopup(true);
                        }
                    };
                }}
                sx={{
                    width: '100%',
                }}
                // marginLeft={'30px'}
                className="flex"
            >
                <FWTypography
                    sx={{ cursor: 'pointer', marginLeft: 'auto' }}
                    color={
                        data.secondaryContact.length > 0
                            ? 'primary.main'
                            : undefined
                    }
                >
                    {data.secondaryContact.length > 0
                        ? data.secondaryContact.length
                        : '-'}
                </FWTypography>
            </Box>
            {!isEmpty(data?.secondaryContact) && (
                <FWPopover
                    open={openPopup}
                    anchorEl={anchorEl}
                    onClose={() => {
                        setOpenPopup(false);
                    }}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <DialogContent
                        sx={{ m: '0px', p: '0.625rem', width: '350px' }}
                    >
                        <Grid container gap={1.5}>
                            <Grid item xs={6}>
                                <FWTypography
                                    color="text.secondary"
                                    marginBottom={1}
                                >
                                    Name
                                </FWTypography>
                                {data?.secondaryContact?.map((contact, idx) => {
                                    return (
                                        <Grid
                                            item
                                            xs={12}
                                            key={idx}
                                            marginBottom={0.25}
                                        >
                                            <FWTypography
                                                padding={0}
                                                textAlign={'left'}
                                            >
                                                {idx + 1}. {contact?.fullName}{' '}
                                            </FWTypography>
                                        </Grid>
                                    );
                                })}
                            </Grid>
                            <Grid item xs={4.5}>
                                <FWTypography
                                    color="text.secondary"
                                    marginBottom={1}
                                >
                                    Status
                                </FWTypography>
                                {data?.secondaryContact?.map((contact, idx) => {
                                    const isInvitePending =
                                        contact.invitationStatus ===
                                        VendorStatus.INVITED;
                                    return (
                                        <Grid
                                            container
                                            key={idx}
                                            marginLeft={'auto'}
                                            marginBottom={0.25}
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Grid
                                                item
                                                xs={isInvitePending ? 11.5 : 12}
                                            >
                                                <VendorContactStatusChip
                                                    status={
                                                        contact.invitationStatus
                                                    }
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                xs={isInvitePending ? 0.5 : 0}
                                            >
                                                {isInvitePending && (
                                                    <FWTooltip title="Resend Invitation">
                                                        <IconButton
                                                            sx={{
                                                                fontSize:
                                                                    '1.3rem',
                                                                color: 'rgb(35, 131, 226)',
                                                                marginLeft:
                                                                    '0.5rem',
                                                                borderRadius:
                                                                    '50%!important',
                                                                paddingY:
                                                                    '0 !important',
                                                            }}
                                                            ref={(ref) => {
                                                                if (!ref)
                                                                    return;
                                                                ref.onclick = (
                                                                    e: any
                                                                ) => {
                                                                    e.stopPropagation();
                                                                    setOpenInvitationPopup(
                                                                        () => ({
                                                                            open: true,
                                                                            id: idx,
                                                                        })
                                                                    );
                                                                };
                                                            }}
                                                        >
                                                            <i className="bi bi-envelope"></i>
                                                        </IconButton>
                                                    </FWTooltip>
                                                )}
                                            </Grid>
                                        </Grid>
                                    );
                                })}
                            </Grid>
                            <FWPopup
                                title={`Resend invitation`}
                                msg={`The invitation will be resent to ${
                                    data.secondaryContact[
                                        openInvitationPopup.id
                                    ]?.fullName
                                } (${
                                    data.secondaryContact[
                                        openInvitationPopup.id
                                    ]?.primary_email
                                }) for entity ${data.vendorName}.`}
                                btnColor={'primary'}
                                btnTitle="Confirm"
                                open={openInvitationPopup.open}
                                setOpen={() => {
                                    setOpenInvitationPopup((prev) => ({
                                        ...prev,
                                        open: !prev.open,
                                    }));
                                }}
                                onClickConfirm={() => {
                                    sendInvitationMail(
                                        data.vendorDirUid,
                                        data.vendorStatus,
                                        data.secondaryContact[
                                            openInvitationPopup.id
                                        ].primary_email
                                    );
                                }}
                            />
                        </Grid>
                        {/* <Grid
                            container
                            columnSpacing={0}
                            rowSpacing={0}
                            style={{
                                width: 'min-content',
                            }}
                        >
                            <div className="mb--5">
                                <FWTypography variant="h5" fontWeight={'500'}>
                                    Name and status
                                </FWTypography>
                            </div>
                            {data?.secondaryContact?.map((contact, idx) => {
                                return (
                                    <Grid
                                        item
                                        xs={12}
                                        key={idx}
                                        sx={{ display: 'flex', gap: '10px' }}
                                    >
                                        <FWTypography padding={0}>
                                            {idx + 1}. {contact?.fullName}{' '}
                                        </FWTypography>
                                        <VendorContactStatusChip
                                            status={contact.invitationStatus}
                                        />
                                    </Grid>
                                );
                            })}
                        </Grid> */}
                    </DialogContent>
                </FWPopover>
            )}
        </>
    );
};
