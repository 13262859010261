import { Box } from '@mui/material';
import { useContext, useEffect, useMemo, useState } from 'react';
import { AuthContext } from '../../../Contexts/AuthContext';
import { cartTabType } from '../../../ItemAnalytics/Hooks/Relationship/useItemCartDashboard';
import ItemCartAccess from '../../../ItemAnalytics/Pages/ItemCartAccess';
import { useGetItemCartDashboardMutation } from '../../../ItemAnalytics/Services/ItemAnalytics.service';

const ItemAnalyticsCartContainer = () => {
    const { authData } = useContext(AuthContext);

    const primayEntityId = useMemo(() => {
        return authData.details?.defaults?.primary_entity_id;
    }, [authData.details?.defaults?.primary_entity_id]);

    const currentUserId = useMemo(() => {
        return authData.details?.user_id;
    }, [authData.details?.user_id]);

    const [cartId, setCartId] = useState<string | null>(null);

    const [fetchItemCartDetails, { data: itemCartDetails }] =
        useGetItemCartDashboardMutation({
            fixedCacheKey: 'itemCartDashboard',
        });

    useEffect(() => {
        if (primayEntityId) {
            fetchItemCartDetails({
                items_per_page: 10,
                page_number: 1,
                query_data: {
                    buyer_entity_id: primayEntityId ? primayEntityId : null,
                    cart_permission: 'CART_VIEW',
                    user_permission: 'CART_VIEW',
                },
                tab: cartTabType.ALL,
                search_text: '',
                sort_fields: [],
            });
        }
    }, [fetchItemCartDetails, primayEntityId]);

    useEffect(() => {
        if (itemCartDetails) {
            const isCurrentUserOwnerOfCart = itemCartDetails.data.find(
                (cart) => cart.cart_owner === currentUserId
            );

            if (isCurrentUserOwnerOfCart) {
                setCartId(isCurrentUserOwnerOfCart.cart_id);
            } else if (itemCartDetails.data?.length > 0) {
                setCartId(itemCartDetails.data[0].cart_id);
            }
        }
    }, [currentUserId, itemCartDetails]);

    // const updateCurrentCartSection = (
    //     newSection: ItemCartModuleSectionEnum
    // ) => {
    //     setCurrentCartSection(newSection);
    // };

    // const updateSearchTerm = (newSearch: string) => {
    //     return setSearchTerm(newSearch);
    // };

    return (
        <>
            {/* <ItemCartModuleChips
                currentSection={ItemCartModuleNameMapping[currentCartSection]}
                updateSection={updateCurrentCartSection}
                searchTerm={searchTerm}
                updateSearchTerm={updateSearchTerm}
            /> */}

            <Box>
                {cartId && (
                    <ItemCartAccess
                        cartId={cartId}
                        buyerEntityId={primayEntityId}
                    />
                )}
            </Box>
        </>
    );
};

export default ItemAnalyticsCartContainer;
