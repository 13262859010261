import { Divider, Grid, Typography } from '@mui/material';
import React from 'react';
import ReactQuill from 'react-quill';
import { convertAdditionalCostBackendToFrontend } from '../../AdditionalCost/helpers';
import {
    FWCurrencyTypoGraphyWithCurrencyUid,
    roundToNDecimalPlace,
} from '../../Common/CurrencyUtilsComponent';
import { FWTypography } from '../../Common/FWTypograhy';
import { getBuyerItemName } from '../../Components/Shared/Utils';
import { calculateNewItemTotalWithDecimalPlaces } from '../../Components/Shared/totalCalculator';
import {
    numberToWords,
    numberToWordsINR,
} from '../../Global/Helpers/ItemAndPOHelpers';
import { IRfqTemplate } from '../../Global/Interfaces/TemplateInterface';
import { IPurchaseOrderItem } from '../Interfaces/PO.model';

interface IPOReviewFooter {
    items: IPurchaseOrderItem[];
    discount_perc: string;
    notes?: string;
    tncText: string;
    rfqTemplate: IRfqTemplate;
    event_type: 'DIRECT' | 'FROM_EVENT';
}

// const formatCurrency = (value: number, currency_code_abbreviation: string) =>
//     FWNumberFormatter(value, currency_code_abbreviation);

export default function POReviewFooter({
    items,
    discount_perc,
    notes,
    tncText,
    rfqTemplate,
    event_type,
}: IPOReviewFooter) {
    const [discountAmount, total] = React.useMemo(() => {
        let total: number = 0;

        for (let item of items) {
            let currItemTotal = calculateNewItemTotalWithDecimalPlaces({
                additional_cost: convertAdditionalCostBackendToFrontend(
                    item.pricing_information.additional_costs
                ),
                decimalPlaces: 2,
                discount: convertAdditionalCostBackendToFrontend(
                    item.pricing_information.discounts
                ),
                item_price: item.pricing_information.price,
                itemDS:
                    item.delivery_schedule_information.delivery_schedule_items
                        .length === 0
                        ? [
                              {
                                  quantity: item.quantity_information.quantity,
                              },
                          ]
                        : item.delivery_schedule_information
                              .delivery_schedule_items,
                tax: convertAdditionalCostBackendToFrontend(
                    item.pricing_information.taxes
                ),
            });
            total += currItemTotal;
        }

        let discountAmount = (+discount_perc / 100) * total;

        return [discountAmount, total];
    }, [discount_perc, items]);

    //

    return (
        <>
            {items.length > 0 && (
                <div>
                    {/* <div
                        style={{
                            display: 'grid',
                            gridTemplateColumns: '500px 500px',
                            justifyContent: 'space-between',
                            gridRowGap: '5px',
                            margin: '0px 24px 0px 24px',
                        }}
                    >
                        <div>
                            <>
                                <FWTypography
                                    color="text.secondary"
                                    sx={{ paddingLeft: '12px' }}
                                >
                                    Remarks
                                </FWTypography>
                                <div
                                    style={{
                                        borderRadius: '12px',
                                        margin: '0',
                                        border: '1px solid #c4c4c8',
                                        height: '180px',
                                        padding: '10px',
                                        overflow: 'scroll',
                                    }}
                                >
                                    <Typography>{notes}</Typography>
                                </div>
                            </>
                        </div>
                        <div
                            style={{
                                display: 'grid',
                                gridTemplateColumns: '250px 250px',
                                justifyContent: 'flex-end',
                                gridRowGap: '5px',
                                gridTemplateRows: `repeat(${
                                    3 +
                                    (event_type === 'FROM_EVENT' ? 3 : 0) +
                                    items.length
                                },max-content)`,
                            }}
                        >
                            <FWTypography
                                variant="h4"
                                style={{
                                    paddingRight: 20,
                                    height: 'fit-content',
                                }}
                            >
                                Summary
                            </FWTypography>
                            <FWTypography sx={{ height: 'fit-content' }}>
                                {' '}
                            </FWTypography>
                            <FWTypography
                                sx={{
                                    color: 'text.secondary',
                                    height: 'fit-content',
                                }}
                                style={{ paddingRight: 20 }}
                            >
                                No.of items:
                            </FWTypography>
                            <FWTypography
                                textAlign="right"
                                sx={{ height: 'fit-content' }}
                            >
                                {items.length}
                            </FWTypography>
                            {items.map((item) => (
                                <>
                                    <FWTypography
                                        sx={{
                                            color: 'text.secondary',
                                            height: 'fit-content',
                                        }}
                                        style={{ paddingRight: 20 }}
                                    >
                                        {getBuyerItemName(
                                            item.item_information
                                        )}
                                    </FWTypography>
                                    <FWCurrencyTypoGraphyWithCurrencyUid
                                        textAlign={'right'}
                                        sx={{ height: 'fit-content' }}
                                        currencyUidOrAbbrevaition={
                                            items[0].pricing_information
                                                .currency_code_abbreviation
                                        }
                                        currency_code_abbreviation={
                                            items[0].pricing_information
                                                .currency_code_abbreviation
                                        }
                                        value={calculateNewItemTotalWithDecimalPlaces(
                                            {
                                                additional_cost:
                                                    convertAdditionalCostBackendToFrontend(
                                                        item.pricing_information
                                                            .additional_costs
                                                    ),
                                                decimalPlaces: 2,
                                                discount:
                                                    convertAdditionalCostBackendToFrontend(
                                                        item.pricing_information
                                                            .discounts
                                                    ),
                                                item_price:
                                                    item.pricing_information
                                                        .price,
                                                itemDS: item
                                                    .delivery_schedule_information
                                                    .delivery_schedule_items,
                                                tax: convertAdditionalCostBackendToFrontend(
                                                    item.pricing_information
                                                        .taxes
                                                ),
                                            }
                                        )}
                                    />
                                </>
                            ))}
                            <Divider />
                            <Divider />


                            {event_type === 'FROM_EVENT' && (
                                <>
                                    <FWTypography
                                        sx={{
                                            color: 'text.secondary',
                                            height: 'fit-content',
                                        }}
                                        style={{ paddingRight: 20 }}
                                    >
                                        Total before RFQ Discount:
                                    </FWTypography>
                                    <FWCurrencyTypoGraphyWithCurrencyUid
                                        textAlign={'right'}
                                        sx={{ height: 'fit-content' }}
                                        currencyUidOrAbbrevaition={
                                            items[0].pricing_information
                                                .currency_code_abbreviation
                                        }
                                        currency_code_abbreviation={
                                            items[0].pricing_information
                                                .currency_code_abbreviation
                                        }
                                        value={total}
                                    />

                                    {discountAmount > 0 ? (
                                        <>
                                            <FWTypography
                                                sx={{
                                                    color: 'text.secondary',
                                                    height: 'fit-content',
                                                }}
                                                style={{ paddingRight: 20 }}
                                            >
                                                RFQ Discount ({discount_perc}%):
                                            </FWTypography>
                                            <FWCurrencyTypoGraphyWithCurrencyUid
                                                textAlign="right"
                                                sx={{ height: 'fit-content' }}
                                                currencyUidOrAbbrevaition={
                                                    items[0].pricing_information
                                                        .currency_code_abbreviation
                                                }
                                                currency_code_abbreviation={
                                                    items[0].pricing_information
                                                        .currency_code_abbreviation
                                                }
                                                value={discountAmount}
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <FWTypography
                                                sx={{ color: 'text.secondary' }}
                                                style={{
                                                    paddingRight: 20,
                                                    height: 'fit-content',
                                                }}
                                            >
                                                RFQ Discount:
                                            </FWTypography>
                                            <FWTypography textAlign="right">
                                                -
                                            </FWTypography>
                                        </>
                                    )}
                                    <Divider
                                        sx={{
                                            height: '8px',
                                        }}
                                    />
                                    <Divider
                                        sx={{
                                            height: '8px',
                                        }}
                                    />
                                </>
                            )}
                            

                            <FWTypography
                                sx={{
                                    fontWeight: '500',
                                    height: 'fit-content',
                                }}
                                style={{ paddingRight: 20 }}
                            >
                                Total value:
                            </FWTypography>
                            <FWCurrencyTypoGraphyWithCurrencyUid
                                textAlign={'right'}
                                sx={{ height: 'fit-content' }}
                                currencyUidOrAbbrevaition={
                                    items[0].pricing_information
                                        .currency_code_abbreviation
                                }
                                currency_code_abbreviation={
                                    items[0].pricing_information
                                        .currency_code_abbreviation
                                }
                                value={total - discountAmount}
                            />
                            
                        </div>
                    </div> */}
                    <div
                        className="pageBreak"
                        style={{
                            display: 'grid',
                            gridTemplateColumns: '30vw 60vw',
                            justifyContent: 'space-between',
                            gridColumnGap: '3rem',
                            gridRowGap: '5px',
                            margin: '0px 24px 50px 24px',
                        }}
                    >
                        <div>
                            <>
                                <FWTypography
                                    color="text.secondary"
                                    sx={{ paddingLeft: '12px' }}
                                >
                                    Remarks
                                </FWTypography>
                                <div
                                    style={{
                                        borderRadius: '12px',
                                        margin: '0',
                                        border: '1px solid #c4c4c8',
                                        height: '180px',
                                        padding: '10px',
                                        overflow: 'scroll',
                                    }}
                                >
                                    <Typography>{notes}</Typography>
                                </div>
                            </>
                        </div>

                        <Grid>
                            <div
                                style={{
                                    display: 'grid',
                                    marginLeft: 'auto',
                                    gridTemplateColumns: '250px 250px',
                                    justifyContent: 'flex-end',
                                    gridRowGap: '5px',
                                    gridTemplateRows: `repeat(${
                                        3 +
                                        (event_type === 'FROM_EVENT' ? 3 : 0) +
                                        items.length
                                    },max-content)`,
                                }}
                            >
                                <FWTypography
                                    variant="h4"
                                    style={{
                                        paddingRight: 20,
                                        height: 'fit-content',
                                    }}
                                >
                                    Summary
                                </FWTypography>
                                <FWTypography sx={{ height: 'fit-content' }}>
                                    {' '}
                                </FWTypography>
                                <FWTypography
                                    sx={{ color: 'text.secondary' }}
                                    style={{
                                        paddingRight: 20,
                                        height: 'fit-content',
                                    }}
                                >
                                    No.of items:
                                </FWTypography>
                                <FWTypography
                                    sx={{ height: 'fit-content' }}
                                    textAlign="right"
                                >
                                    {items.length}
                                </FWTypography>

                                {items.map((item) => (
                                    <>
                                        <FWTypography
                                            sx={{
                                                color: 'text.secondary',
                                                height: 'fit-content',
                                            }}
                                            style={{ paddingRight: 20 }}
                                        >
                                            {getBuyerItemName(
                                                item.item_information
                                            )}
                                        </FWTypography>
                                        <FWCurrencyTypoGraphyWithCurrencyUid
                                            textAlign={'right'}
                                            sx={{ height: 'fit-content' }}
                                            currencyUidOrAbbrevaition={
                                                items[0].pricing_information
                                                    .currency_code_abbreviation
                                            }
                                            currency_code_abbreviation={
                                                items[0].pricing_information
                                                    .currency_code_abbreviation
                                            }
                                            value={calculateNewItemTotalWithDecimalPlaces(
                                                {
                                                    additional_cost:
                                                        convertAdditionalCostBackendToFrontend(
                                                            item
                                                                .pricing_information
                                                                .additional_costs
                                                        ),
                                                    decimalPlaces: 2,
                                                    discount:
                                                        convertAdditionalCostBackendToFrontend(
                                                            item
                                                                .pricing_information
                                                                .discounts
                                                        ),
                                                    item_price:
                                                        item.pricing_information
                                                            .price,
                                                    itemDS:
                                                        item
                                                            .delivery_schedule_information
                                                            .delivery_schedule_items
                                                            .length === 0
                                                            ? [
                                                                  {
                                                                      quantity:
                                                                          item
                                                                              .quantity_information
                                                                              .quantity,
                                                                  },
                                                              ]
                                                            : item
                                                                  .delivery_schedule_information
                                                                  .delivery_schedule_items,
                                                    tax: convertAdditionalCostBackendToFrontend(
                                                        item.pricing_information
                                                            .taxes
                                                    ),
                                                }
                                            )}
                                        />
                                    </>
                                ))}
                                <Divider
                                    sx={{
                                        height: '8px',
                                    }}
                                />
                                <Divider
                                    sx={{
                                        height: '8px',
                                    }}
                                />
                                {event_type === 'FROM_EVENT' && (
                                    <>
                                        <FWTypography
                                            sx={{ color: 'text.secondary' }}
                                            style={{
                                                paddingRight: 20,
                                                height: 'fit-content',
                                            }}
                                        >
                                            Total before RFQ Discount:
                                        </FWTypography>
                                        <FWCurrencyTypoGraphyWithCurrencyUid
                                            textAlign={'right'}
                                            sx={{ height: 'fit-content' }}
                                            currencyUidOrAbbrevaition={
                                                items[0].pricing_information
                                                    .currency_code_abbreviation
                                            }
                                            currency_code_abbreviation={
                                                items[0].pricing_information
                                                    .currency_code_abbreviation
                                            }
                                            value={total}
                                        />

                                        {discountAmount > 0 ? (
                                            <>
                                                <FWTypography
                                                    sx={{
                                                        color: 'text.secondary',
                                                        height: 'fit-content',
                                                    }}
                                                    style={{ paddingRight: 20 }}
                                                >
                                                    RFQ Discount (
                                                    {discount_perc}%):
                                                </FWTypography>
                                                <FWCurrencyTypoGraphyWithCurrencyUid
                                                    textAlign="right"
                                                    sx={{
                                                        height: 'fit-content',
                                                    }}
                                                    currencyUidOrAbbrevaition={
                                                        items[0]
                                                            .pricing_information
                                                            .currency_code_abbreviation
                                                    }
                                                    currency_code_abbreviation={
                                                        items[0]
                                                            .pricing_information
                                                            .currency_code_abbreviation
                                                    }
                                                    value={discountAmount}
                                                />
                                            </>
                                        ) : (
                                            <>
                                                <FWTypography
                                                    sx={{
                                                        color: 'text.secondary',
                                                    }}
                                                    style={{
                                                        paddingRight: 20,
                                                        height: 'fit-content',
                                                    }}
                                                >
                                                    Discount:
                                                </FWTypography>
                                                <FWTypography
                                                    sx={{
                                                        height: 'fit-content',
                                                    }}
                                                    textAlign="right"
                                                >
                                                    -
                                                </FWTypography>
                                            </>
                                        )}
                                        <Divider
                                            sx={{
                                                height: '8px',
                                            }}
                                        />
                                        <Divider
                                            sx={{
                                                height: '8px',
                                            }}
                                        />
                                    </>
                                )}

                                <FWTypography
                                    sx={{
                                        fontWeight: '500',
                                        height: 'fit-content',
                                    }}
                                    style={{ paddingRight: 20 }}
                                >
                                    Total value:
                                </FWTypography>

                                <FWCurrencyTypoGraphyWithCurrencyUid
                                    sx={{ height: 'fit-content' }}
                                    textAlign="right"
                                    currencyUidOrAbbrevaition={
                                        items[0].pricing_information
                                            .currency_code_abbreviation
                                    }
                                    currency_code_abbreviation={
                                        items[0].pricing_information
                                            .currency_code_abbreviation
                                    }
                                    value={total - discountAmount}
                                />
                            </div>

                            <Grid
                                item
                                sx={{
                                    marginTop: '1rem',
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                }}
                            >
                                <Grid item xs={1.75}>
                                    <Typography fontWeight={500}>
                                        In words :
                                    </Typography>
                                </Grid>
                                <Typography textAlign={'justify'}>
                                    {
                                        items[0].pricing_information
                                            .currency_code_abbreviation
                                    }{' '}
                                    {items[0].pricing_information
                                        .currency_code_abbreviation === 'INR'
                                        ? numberToWordsINR(
                                              roundToNDecimalPlace(
                                                  total - discountAmount,
                                                  2
                                              )
                                          )
                                        : numberToWords(
                                              roundToNDecimalPlace(
                                                  total - discountAmount,
                                                  2
                                              ),
                                              items[0].pricing_information
                                                  .currency_code_abbreviation
                                          )}{' '}
                                    Only
                                </Typography>
                            </Grid>
                        </Grid>
                    </div>

                    <Grid item md={12}>
                        <div style={{ margin: '10px 35px' }}>
                            <FWTypography
                                variant="h4"
                                style={{
                                    margin: '10px 0px',
                                }}
                            >
                                Terms & Conditions
                            </FWTypography>
                        </div>
                        <div
                            style={{
                                borderRadius: '12px',
                                margin: '10px 16px 10px 24px',
                                border: '1px solid #c4c4c8',
                                // '.ql-toolbar': {
                                //     display: 'none',
                                // },
                            }}
                        >
                            {/* <FWTypography
                                        style={{
                                            margin: '16px',
                                            fontFamily:
                                                "'Source Sans Pro', sans-serif",
                                            whiteSpace: 'pre-line',
                                        }}
                                    >
                                        {tncText}
                                    </FWTypography> */}
                            <ReactQuill
                                modules={{
                                    toolbar: false,
                                }}
                                readOnly
                                value={tncText}
                            />
                        </div>
                    </Grid>
                </div>
            )}
        </>
    );
}
