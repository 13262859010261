import { getNextToken } from './tokenizer';
import { TokenType, TokenValueType, type Token } from './types';
import { DateRegex, DateTimeRegex } from './validator';

export function getTokens(formula: string) {
    return processTokenStream(formula);
}

function processTokenStream(formula: string) {
    const tokens: Token[] = [];
    let position = 0;
    const skip = (amount = 1) => {
        position += amount;
    };
    const match = (pattern: RegExp, move?: boolean, take = 0) => {
        const match = formula.substring(position).match(pattern);
        if (match) {
            if (move) {
                position += (match[take] || '').length;
            }
            return match[take];
        }
    };

    let prev: TokenType | null = null;
    while (position < formula.length) {
        const startingPosition = position;
        let tokenType = getNextToken({ match, skip, prev });
        if (startingPosition === position) {
            throw new Error('Tokenizer did not move forward');
        }
        if (tokenType === TokenType.EmptyStringAndDoubleQuoteEnd) {
            tokens.push({
                type: TokenType.String,
                value: '',
                position: startingPosition,
                valueType: TokenValueType.None,
            });
            tokenType = TokenType.DoubleQuoteEnd;
        } else if (tokenType === TokenType.EmptyStringAndQuoteEnd) {
            tokens.push({
                type: TokenType.String,
                value: '',
                position: startingPosition,
                valueType: TokenValueType.None,
            });
            tokenType = TokenType.QuoteEnd;
        }
        if (
            tokenType === TokenType.String &&
            new RegExp(DateRegex).test(
                formula.substring(startingPosition, position)
            )
        ) {
            tokens.push({
                type: tokenType,
                value: formula.substring(startingPosition, position),
                valueType: TokenValueType.Date,

                position: startingPosition,
            });
        } else if (
            tokenType === TokenType.String &&
            new RegExp(DateTimeRegex).test(
                formula.substring(startingPosition, position)
            )
        ) {
            tokens.push({
                type: tokenType,
                value: formula.substring(startingPosition, position),
                valueType: TokenValueType.DateTime,

                position: startingPosition,
            });
        } else {
            tokens.push({
                type: tokenType,
                value: formula.substring(startingPosition, position),
                valueType: TokenValueType.None,

                position: startingPosition,
            });
        }

        if (tokenType !== TokenType.Whitespace) {
            prev = tokenType;
        }
    }

    return tokens;
}
