import { Box, Grid, Skeleton, styled } from '@mui/material';
import { useContext } from 'react';
import { FWLink } from '../../Common/FWLink';
import { FWTypography } from '../../Common/FWTypograhy';
import { HookStateValue } from '../../Common/Hooks/StateHook';
import { AuthContext } from '../../Contexts/AuthContext';
import { VerificationStatus } from '../../Redux/Slices/VerificationSlice';
import VerificationStatusChip from '../Components/VerificationStatusChip';
import { useVerification } from '../Hooks/useVerificationStatus';

interface IEntityVerificationContainerProps {
    entityUid: string;
    onVerifyNow: (data: { name: string; uid: string }) => void;
}

const CustomSkeleton = styled(Skeleton)({
    borderRadius: '5px',
});

const EntityVerificationContainer = ({
    entityUid,
    onVerifyNow,
}: IEntityVerificationContainerProps) => {
    const { checkPermission } = useContext(AuthContext);
    const isAdmin = checkPermission('GLOBAL_ADMIN', null, null, null);

    // const [loading, setLoading] = useState<boolean>(false);

    const {
        hookState,
        entityDetails,
        // isValidDetails,
        // updateVerificationDetails,
        // sumbitVerification,
    } = useVerification(entityUid);

    // const idTypeNames = useMemo(
    //     () =>
    //         entityDetails.verificationDetails.map(
    //             (ver) => ver.verificationIdType
    //         ),
    //     [entityDetails]
    // );
    // const duplicateIdTypes = useMemo(
    //     () =>
    //         idTypeNames.filter(
    //             (item, index) => idTypeNames.indexOf(item) !== index
    //         ),
    //     [idTypeNames]
    // );

    const uploadDocuments =
        entityDetails.verificationStatus === VerificationStatus.REJECTED ||
        entityDetails.verificationStatus === VerificationStatus.UNVERIFIED;

    // const handleSubmitVerification = async () => {
    //     setLoading(true);
    //     const verificationSubmitted = await sumbitVerification();
    //     if (!verificationSubmitted) {
    //         toast.error(`Error submitting verification`);
    //     }
    //     setLoading(false);
    // };

    return (
        <Box
            sx={{
                border: '1px solid #e0e0e0',
                borderRadius: '12px',
                padding: '16px 20px 20px',
            }}
        >
            <Grid container>
                <Grid item xs={12}>
                    <div className="flex flex--aic">
                        <FWTypography variant="h4" marginRight={'5px'}>
                            Entity:
                        </FWTypography>
                        {hookState.state === HookStateValue.LOADING ? (
                            <CustomSkeleton
                                variant="text"
                                width={150}
                                height={36}
                            />
                        ) : (
                            <FWTypography variant="h4" color="primary">
                                {entityDetails.entityName}
                            </FWTypography>
                        )}
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className="flex flex--aic">
                        <FWTypography marginRight={'5px'}>
                            Current status:
                        </FWTypography>
                        {hookState.state === HookStateValue.LOADING ? (
                            <CustomSkeleton
                                variant="text"
                                width={150}
                                height={24}
                            />
                        ) : (
                            <div className="flex flex--aic">
                                <VerificationStatusChip
                                    status={entityDetails.verificationStatus}
                                />
                                {isAdmin && uploadDocuments && (
                                    <>
                                        <div className="ml--5 mr--5">-</div>
                                        <FWLink
                                            onClick={() => {
                                                onVerifyNow({
                                                    name: entityDetails.entityName,
                                                    uid: entityUid,
                                                });
                                            }}
                                        >
                                            Verify now
                                        </FWLink>
                                    </>
                                )}
                            </div>
                        )}
                    </div>
                </Grid>
                {/* {USER_TYPE !== UserType.USER && uploadDocuments && (
                    <>
                        {entityDetails.verificationDetails.map(
                            (detail, idx) => {
                                return (
                                    <Fragment key={idx}>
                                        <Grid item xs={12} marginBottom="20px">
                                            <VerificationDetailsRow
                                                idx={idx}
                                                entityUid={entityUid}
                                                detail={detail}
                                                updateVerificationDetails={
                                                    updateVerificationDetails
                                                }
                                                duplicateIdTypes={
                                                    duplicateIdTypes
                                                }
                                            />
                                        </Grid>
                                    </Fragment>
                                );
                            }
                        )}
                        <Grid
                            item
                            xs={12}
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                            }}
                        >
                            <FWButton
                                variant="contained"
                                disabled={
                                    !isValidDetails ||
                                    duplicateIdTypes.length > 0 ||
                                    loading
                                }
                                onClick={handleSubmitVerification}
                            >
                                {loading ? (
                                    <CircularProgress
                                        size={24}
                                        style={{ color: '#c4c4c8' }}
                                    />
                                ) : (
                                    'Submit'
                                )}
                            </FWButton>
                        </Grid>
                    </>
                )} */}
            </Grid>
        </Box>
    );
};

export default EntityVerificationContainer;
