import { CircularProgress, Select, SelectProps, styled } from '@mui/material';

const StyledSelect = styled(Select)(({ theme }) => ({
    '&': {
        height: '40px',
    },
    '& .MuiSelect-icon': {
        top: '20%',
    },
    '&.success .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.success.main,
        boxShadowColor: theme.palette.success.main,
    },
    '&.warning .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.warning.main,
        boxShadowColor: theme.palette.warning.main,
    },
    '&.error .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.error.main,
        boxShadowColor: theme.palette.error.main,
    },
}));

export const OpenDownIcon: React.FunctionComponent = (props: SelectProps) => {
    return (
        <i
            className={`bi bi-chevron-down ${props.className}`}
            style={{ marginRight: '0.25rem' }}
        ></i>
    );
};

export const LoaderIcon: React.FunctionComponent = (props: SelectProps) => {
    return (
        <CircularProgress
            size={'20px'}
            sx={{ marginRight: '5px' }}
            style={{ color: 'black' }}
        />
    );
};

export const EmptyIcon: React.FunctionComponent = (props: SelectProps) => {
    return <></>;
};

export default function FWSelect(props: SelectProps) {
    return (
        <StyledSelect
            IconComponent={OpenDownIcon}
            sx={{ maxHeight: '40px', padding: '0px' }}
            MenuProps={{
                disableScrollLock: true,
                PaperProps: {
                    sx: {
                        backgroundColor: 'white',
                        borderRadius: '12px',
                        boxShadow: '0px 5px 16px 3px #00000024',
                    },
                },
            }}
            {...props}
        />
    );
}
