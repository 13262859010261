import CircularProgress from '@mui/material/CircularProgress';

export default function LazyFallback() {
    return (
        <div
            style={{
                width: '100vw',
                minHeight: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <CircularProgress style={{ color: 'black' }} />
        </div>
    );
}
