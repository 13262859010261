import { Box, Grid, Typography } from '@mui/material';
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { PROJECT_GRAPH_DIMENSIONS } from './ProjectGraphsPopup';

ChartJS.register(ArcElement, Tooltip, Legend);

export default function ProjectTimeSpentByTeams() {
    const labels = ['P00001', 'P00002', 'P00003', 'P00004', 'P00005'];

    const options = {
        plugins: {},
        responsive: true,
        scales: {
            x: {
                stacked: true,
                title: {
                    display: true,
                    text: 'Projects',
                },
            },
            y: {
                type: 'linear' as any,
                // stacked: true,
                title: {
                    display: true,
                    text: 'Hours',
                },
            },
            y1: {
                grid: { display: false },
                // stacked: true,
                type: 'linear' as any,
                position: 'right' as const,
                title: {
                    display: true,
                    text: 'Cost',
                },
                ticks: {
                    // Include a dollar sign in the ticks
                    callback: function (value: any, index: number, ticks: any) {
                        return '$' + value;
                    },
                },
                max: 70000,
            },
        },
    };

    const data = {
        labels,
        datasets: [
            {
                type: 'bar' as any,
                label: 'Marketing',
                yAxisId: 'y',
                data: [17, 26, 35, 22, 14],
                stack: 'stack-0',
                order: 1,
                backgroundColor: 'rgba(75, 192, 192, 1)',
            },
            {
                type: 'bar' as any,
                label: 'Procurement',
                yAxisId: 'y',
                data: [25, 37, 12, 37, 45],
                stack: 'stack-0',
                order: 1,
                backgroundColor: 'rgba(255, 99, 132,1)',
            },
            {
                label: 'Cost',
                data: [30000, 42000, 15000, 55000, 50000],
                backgroundColor: '#4F1271',
                borderColor: '#4F1271',
                type: 'line' as any,
                yAxisID: 'y1',
            },
        ],
    };
    return (
        <Grid
            sx={{
                cursor: 'pointer',
                transition: 'all 0.3s ease-in-out',
                '&:hover': {
                    boxShadow: '0px 3px 15px rgba(0,0,0,0.2)',
                },
            }}
            boxShadow={'0px 3px 15px rgb(0 0 0 / 7%)'}
            border={'3px solid transparent'}
            width={PROJECT_GRAPH_DIMENSIONS}
            height={PROJECT_GRAPH_DIMENSIONS}
            // height={'400px'}
            // bgcolor={'#F0F0F2'}
            borderRadius={1}
            item
            padding={2}
            paddingTop={0}
            paddingBottom={4}
            xs={5.8}
        >
            <Box paddingY={1}>
                <Typography variant="h6" sx={{ padding: 1, fontSize: '1rem' }}>
                    Time spent on projects
                </Typography>
            </Box>

            <Bar options={options} data={data} />
        </Grid>
    );
}
