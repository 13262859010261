import { IconButton, Box } from '@mui/material';
import React from 'react';

const ChatSectionsExpandButton = ({
    onClick,
    hidden,
}: {
    onClick: React.MouseEventHandler<HTMLButtonElement> | undefined;
    hidden?: boolean;
}) => {
    return (
        <Box className="flex flex--aic">
            <IconButton
                sx={{ cursor: 'pointer', marginRight: 0.5, fontSize: '1rem' }}
                size="small"
                onClick={onClick}
            >
                <i
                    className={`bi bi-chevron-double-${
                        hidden ? 'right' : 'left'
                    }`}
                    style={{ WebkitTextStrokeWidth: 0.5 }}
                ></i>
            </IconButton>
        </Box>
    );
};

export default ChatSectionsExpandButton;
