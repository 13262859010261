import { Box, Checkbox, Grid, InputAdornment, TextField } from '@mui/material';
import FWAutocomplete from '../../../../Common/FWAutocomplete';
import { BOMListCard } from '../../../../Organizations/Admin/Pages/BOM/Interface/BOMInterface';
import { FWPopupWithChildren } from '../../../../Common/FWPopupWithChildren';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFetchAllBOMQuery } from '../../../../Organizations/Admin/Pages/BOM/Services/bom.service';
import { shallowEqual, useSelector } from 'react-redux';
import { IGlobalAppStore } from '../../../../Redux/Store';
import { FWTypography } from '../../../../Common/FWTypograhy';
import { FWButton } from '../../../../Common/FWButton';
import { FWTooltip } from '../../../../Common/FWCustomTooltip';
import { FWMenuItem } from '../../../../Common/FWCustomMenu';
import { useDispatch } from 'react-redux';
import { updateEventDataStore } from '../../../Slices/EventCreationSlice';
import { batch } from 'react-redux';

export interface IBomSelectionPopupProps {
    finishedGoodId: string;
    fetchAndLoadSelectedItemDetails: (
        enterprise_item_id: string,
        isFinishedGood: boolean
    ) => Promise<void>;
    handleSelection: () => void;
    setShowBomPopup: React.Dispatch<React.SetStateAction<string | null>>;
}

const BomSelectionPopup = ({
    finishedGoodId,
    fetchAndLoadSelectedItemDetails,
    handleSelection,
    setShowBomPopup,
}: IBomSelectionPopupProps) => {
    const dispatch = useDispatch();
    const selectedEntity = useSelector(
        (store: IGlobalAppStore) =>
            store.EventCreationStore.eventDetails?.entity_id
    );

    const dictOfBoms = useSelector(
        (store: IGlobalAppStore) => store.EventCreationStore.dictOfBoms,
        shallowEqual
    );
    const [selectedBom, setSelectedBom] = useState<BOMListCard | null>(null);
    const [isToBeUseAsRawMaterial, setIsToBeUseAsRawMaterial] = useState(false);

    const { data: listOfBomFromQuery } = useFetchAllBOMQuery({
        finished_good_id: finishedGoodId,
    });

    const listOfBom = useMemo(() => {
        if (listOfBomFromQuery && selectedEntity) {
            return (
                listOfBomFromQuery
                    // .filter((bom) => bom.bom_status === 'ONGOING')
                    .filter((bom) => bom.status === 'ACTIVE')
                    .filter((bom) => {
                        if (
                            bom.entities.some((currententity) => {
                                return [selectedEntity].includes(
                                    currententity.buyer_entity_id
                                );
                            })
                        ) {
                            return true;
                        }

                        return false;
                    })
            );
        }
        return [];
    }, [listOfBomFromQuery, selectedEntity]);

    useEffect(() => {
        if (listOfBom && selectedBom === null) {
            const bomToBeSelectedByDefault =
                listOfBom.filter((bom) => bom.bom_status === 'ONGOING')
                    .length === 1
                    ? listOfBom.filter((bom) => bom.bom_status === 'ONGOING')[0]
                    : listOfBom
                          .filter((bom) => bom.bom_status === 'ONGOING')
                          .find((bom) => bom.is_primary);

            if (bomToBeSelectedByDefault)
                setSelectedBom(bomToBeSelectedByDefault);
        }
    }, [listOfBom, selectedBom]);

    const disableConfirmButton = useMemo(() => {
        if (isToBeUseAsRawMaterial) {
            return false;
        } else {
            if (selectedBom) {
                return false;
            } else {
                return true;
            }
        }
    }, [isToBeUseAsRawMaterial, selectedBom]);

    const handleClose = useCallback(() => {
        setShowBomPopup(null);
    }, [setShowBomPopup]);

    const handleConfirm = useCallback(() => {
        if (isToBeUseAsRawMaterial) {
            setShowBomPopup(null);
            fetchAndLoadSelectedItemDetails(finishedGoodId, false);
        } else {
            setShowBomPopup(null);
            batch(() => {
                dispatch(
                    updateEventDataStore({
                        type: 'UPDATE_SELECTED_BOM_ID_FOR_BOM_CONFIGURATION',
                        value: selectedBom?.enterprise_bom_id ?? null,
                    })
                );
                dispatch(
                    updateEventDataStore({
                        type: 'UPDATE_SHOW_BOM_CONFIGURATION_TYPE',
                        value: 'MANUAL',
                    })
                );
            });
        }
    }, [
        dispatch,
        fetchAndLoadSelectedItemDetails,
        finishedGoodId,
        isToBeUseAsRawMaterial,
        selectedBom?.enterprise_bom_id,
        setShowBomPopup,
    ]);

    return (
        <>
            <FWPopupWithChildren
                open={true}
                handleClose={() => {
                    handleClose();
                }}
                title="Select Bom"
                stickyHeader
            >
                <Grid
                    container
                    xs={12}
                    rowSpacing={2}
                    paddingX={'24px'}
                    paddingBottom={'24px'}
                >
                    <Grid item xs={12}>
                        <FWAutocomplete
                            disabled={isToBeUseAsRawMaterial}
                            value={selectedBom}
                            options={listOfBom ?? []}
                            onChange={(e, selectedBomValue) => {
                                setSelectedBom(selectedBomValue);
                            }}
                            getOptionDisabled={(option: BOMListCard) =>
                                option.enterprise_bom_id in dictOfBoms ||
                                option.bom_status === 'DRAFT'
                            }
                            renderOption={(props, option: BOMListCard) => (
                                <FWMenuItem
                                    style={{
                                        paddingLeft: '36px',
                                        width: '100%',
                                    }}
                                    onClick={() => {
                                        setSelectedBom(option);
                                    }}
                                    disabled={option.bom_status === 'DRAFT'}
                                >
                                    <div className="flex flex--row row-gap-36">
                                        <div>
                                            <FWTypography>
                                                {option.bom_code}
                                            </FWTypography>
                                        </div>
                                        {option.bom_status === 'DRAFT' && (
                                            <FWTypography paddingLeft={0.5}>
                                                (Draft)
                                            </FWTypography>
                                        )}
                                        {option.is_subcontract && (
                                            <div
                                                style={{
                                                    paddingLeft: '12px',
                                                }}
                                            >
                                                <FWTooltip title="Sub Contracted BOM">
                                                    <Box
                                                        fontSize={'1.5rem'}
                                                        color={'#ed6c02'}
                                                    >
                                                        <i
                                                            style={{
                                                                fontSize:
                                                                    '1.5rem',
                                                            }}
                                                            className="bi bi-exclamation-circle"
                                                        ></i>
                                                    </Box>
                                                </FWTooltip>
                                            </div>
                                        )}
                                    </div>
                                </FWMenuItem>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    InputProps={{
                                        ...params.InputProps,
                                        startAdornment: (
                                            <Box marginLeft={1}>
                                                <InputAdornment position="start">
                                                    <i className="bi bi-search"></i>
                                                </InputAdornment>
                                            </Box>
                                        ),
                                    }}
                                />
                            )}
                            getOptionLabel={(option: BOMListCard) =>
                                option.bom_code
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <div className="flex flex--row flex--aic">
                            <Checkbox
                                checked={isToBeUseAsRawMaterial}
                                onChange={(e, checked) =>
                                    setIsToBeUseAsRawMaterial(checked)
                                }
                            />
                            <FWTypography>Add as a raw material</FWTypography>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <div className="flex flex--row-reverse col-gap-8">
                            <div>
                                <FWButton
                                    variant="contained"
                                    color="primary"
                                    onClick={handleConfirm}
                                    disabled={disableConfirmButton}
                                >
                                    Confirm
                                </FWButton>
                            </div>
                            <div>
                                <FWButton
                                    variant="outlined"
                                    color="error"
                                    onClick={() => {
                                        handleClose();
                                    }}
                                >
                                    Cancel
                                </FWButton>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </FWPopupWithChildren>
        </>
    );
};

export default BomSelectionPopup;
