import Decimal from 'decimal.js';
import { isEmpty } from 'lodash';
import { FWTooltip } from '../../Common/FWCustomTooltip';
import { FWInput } from '../../Common/FWInput';
import { isCreditAmountValid } from '../Helpers/PaymentsHelpers';
import {
    ICreditListItemSummary,
    IPaymentsListContext,
} from '../Interfaces/PaymentsInterface';

const CreditAmountCell = ({
    data: creditItem,
    context,
}: {
    data: ICreditListItemSummary;
    context: IPaymentsListContext;
}) => {
    const {
        handleCreditRequestedAmountUpdate,
        totalAmountToPay,
        totalCreditAmount,
    } = context;

    let message = '';

    if (parseFloat(creditItem.requestedAmount) === 0) {
        message = 'Requested amount cannot be zero';
    }
    if (totalAmountToPay) {
        if (parseFloat(creditItem.requestedAmount) > totalAmountToPay) {
            message = 'Cannot exceed amount to pay';
        }
    }

    return (
        <FWTooltip title={message}>
            <div
                className="flex flex--aic"
                style={{
                    width: '100%',
                    paddingRight: '10px',
                }}
            >
                <FWInput
                    value={creditItem.requestedAmount}
                    allowOnly="DECIMAL_NUMBER"
                    iconStart={creditItem.currencySymbol}
                    onChange={(event: any) => {
                        handleCreditRequestedAmountUpdate(
                            event.target.value,
                            creditItem.id
                        );
                    }}
                    disabled={
                        !creditItem.selected ||
                        (new Decimal(totalAmountToPay)
                            .minus(totalCreditAmount)
                            .toNumber() === 0 &&
                            isEmpty(creditItem.requestedAmount))
                    }
                    error={!isCreditAmountValid(creditItem, totalAmountToPay)}
                />
            </div>
        </FWTooltip>
    );
};

export default CreditAmountCell;
