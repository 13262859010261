import { useEffect, useState } from 'react';
import { HookStateValue, useHookState } from '../../../Common/Hooks/StateHook';
import { blankItemCartTemplate } from '../../Helpers/itemAnalyticsTemplateHelpers';
import { IItemCartTemplate } from '../../Interfaces/ItemCartTemplateInterfaces';
import {
    useGetItemCartTemplateByIdQuery,
    useGetItemCartTemplatesQuery,
} from '../../Services/ItemAnalytics.service';

export const useItemCartTemplate = (entityId: string) => {
    const { hookState, updateHookState } = useHookState(HookStateValue.LOADING);

    const [templateDetails, setTemplateDetails] = useState<IItemCartTemplate>(
        blankItemCartTemplate
    );

    const [itemCartTemplateId, setItemCartTemplateId] = useState<string | null>(
        null
    );

    const { data: listOfItemCartTemplate } = useGetItemCartTemplatesQuery(
        {
            entityId: entityId,
        },
        {
            skip: !Boolean(entityId),
        }
    );

    useEffect(() => {
        if (listOfItemCartTemplate && listOfItemCartTemplate?.length > 0) {
            setItemCartTemplateId(listOfItemCartTemplate[0].template_id);
        }
    }, [listOfItemCartTemplate]);

    const {
        refetch: refetchItemCartTemplate,
        data: itemCartTemplateDetails,
        isLoading,
    } = useGetItemCartTemplateByIdQuery(
        {
            entityId: entityId,
            templateId: itemCartTemplateId ?? '',
        },
        {
            skip: !Boolean(entityId) || !Boolean(itemCartTemplateId),
        }
    );

    useEffect(() => {
        if (isLoading) {
            updateHookState(HookStateValue.LOADING);
        }
    }, [isLoading, updateHookState]);

    useEffect(() => {
        if (itemCartTemplateDetails) {
            setTemplateDetails(itemCartTemplateDetails);
            updateHookState(HookStateValue.READY);
        }
    }, [itemCartTemplateDetails, updateHookState]);

    useEffect(() => {
        if (itemCartTemplateId) {
            try {
                refetchItemCartTemplate();
            } catch (e) {}
        }
    }, [itemCartTemplateId, refetchItemCartTemplate]);

    return {
        templateDetails,
        hookState,
    };
};
