import { Divider, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useRef, useState } from 'react';
import { List } from '../../Interfaces/HelpInformationInterface';
import HelpSectionImage from './HelpSectionImage';
import { StyledBox, StyledGradient } from './ReadMoreStyle';

const ListDataComponent = ({ question, lists, image }: List) => {
    const [viewMore, setViewMore] = useState(false);
    const [clientHeight, setClientHeight] = useState(0);
    const elemRef = useRef<HTMLDivElement>();

    const innerElemRef = useRef<HTMLDivElement>();

    useEffect(() => {
        if (elemRef.current && clientHeight > 125) {
            if (viewMore) {
                elemRef.current.style.height = 'auto';
            } else {
                elemRef.current.style.height = '125px';
            }
        } else {
        }
    }, [viewMore, clientHeight]);

    useEffect(() => {
        innerElemRef.current?.clientHeight &&
            setClientHeight(innerElemRef.current?.clientHeight);
    }, [innerElemRef.current?.clientHeight]);

    return (
        <>
            <Box marginBottom={2}>
                <StyledBox ref={elemRef}>
                    <Box paddingY={2}>
                        <Typography fontWeight={500} marginBottom={0.5}>
                            {question}
                        </Typography>
                        {lists.map((listItem, idx3) => {
                            return (
                                <Box key={idx3} ref={innerElemRef}>
                                    <Typography
                                        color={'text.secondary'}
                                        marginBottom={1}
                                    >
                                        {listItem.listText
                                            ?.split('\n')
                                            .map((text) => (
                                                <>
                                                    {text}
                                                    <br />
                                                </>
                                            ))}
                                    </Typography>
                                    <ul className="ml--30">
                                        {listItem.listData.map(
                                            (listData, idx4) => (
                                                <li key={idx4}>
                                                    <Typography
                                                        color={'text.secondary'}
                                                    >
                                                        {listData}
                                                    </Typography>
                                                </li>
                                            )
                                        )}
                                    </ul>
                                    <HelpSectionImage image={image} />
                                </Box>
                            );
                        })}
                    </Box>
                </StyledBox>
                {clientHeight > 125 && (
                    <StyledGradient>
                        {!viewMore && <div className="gradient"></div>}
                        <Typography
                            paddingBottom={3}
                            position={'relative'}
                            textAlign={'right'}
                            color={'primary.main'}
                            fontSize={12}
                            onClick={(e) => {
                                e.stopPropagation();
                                setViewMore((prev) => !prev);
                            }}
                            style={{ cursor: 'pointer' }}
                        >
                            {viewMore ? 'Show Less' : 'Read more'}
                        </Typography>
                    </StyledGradient>
                )}
            </Box>
            <Box marginBottom={2} position={'relative'}>
                <Divider />
            </Box>
        </>
    );
};

export default ListDataComponent;
