import { createSlice } from '@reduxjs/toolkit';
import {
    IHelpInformationActions,
    updateHelpInformationReducer,
} from '../Reducers/HelpInformationReducers';
import { store } from '../Store';

export enum HelpPageName {
    GLOBAL = 'GLOBAL',
    EVENT = 'EVENT',
    PURCHASE_ORDER = 'PURCHASE_ORDER',
    INVOICE = 'INVOICE',
    GOODS_RECEIPT = 'GOODS_RECEIPT',
    QUALITY_CHECK = 'QUALITY_CHECK',
    PAYMENT = 'PAYMENT',
    ADMIN = 'ADMIN',
    PRICING_AND_SUBSCRIPTION = 'PRICING_AND_SUBSCRIPTION',
    QUOTE_CALCULATOR = 'QUOTE_CALCULATOR',
    PROJECT = 'PROJECT',
    TEMPLATES = 'TEMPLATES',
    ADDITIONAL_FIELDS = 'ADDITIONAL_FIELDS',
    ITEM_TAG_LINKAGE = 'ITEM_TAG_LINKAGE',
    ITEM_ANALYTICS = 'ITEM_ANALYTICS',
    HISTORICAL_ANALYTICS = 'HISTORICAL_ANALYTICS',
    CUSTOM_ANALYTICS = 'CUSTOM_ANALYTICS',
    CART = 'CART',
    SUPPLIER_RELATIONSHIP_MANAGEMENT_BUYER = 'SUPPLIER_RELATIONSHIP_MANAGEMENT_BUYER',
    CONTRACT_LIFECYCLE_MANAGEMENT = 'CONTRACT_LIFECYCLE_MANAGEMENT',
    // CUSTOMER_PORTAL=" CUSTOMER_PORTAL"
}
export enum HelpInternalPageName {
    EVENT_DASHBOARD = 'EVENT_DASHBOARD', // buyer/events
    CREATE_EVENT = 'CREATE_EVENT', //buyer/events/create/
    ADD_EDIT_ITEMS = 'ADD_EDIT_ITEMS', //buyer/events/rfq/1c60c211-8afd-46db-9f07-7b88a117f3c3
    REVIEW_PAGE = 'REVIEW_PAGE', //buyer/events/rfq/270abcbc-ac95-4199-9a50-c298a67b802b
    BUYER_EVENTS_SUMMARY = 'BUYER_EVENTS_SUMMARY', //buyer/events/270abcbc-ac95-4199-9a50-c298a67b802b/summary
    BUYER_EVENTS_NEGOTIATIONS = 'BUYER_EVENTS_NEGOTIATIONS', ///buyer/events/live/923982ec-4921-4e33-8ef2-53c4556b8c7f/quotes
    RFQ_ANALYTICS = 'RFQ_ANALYTICS', //buyer/events/rfq-analytics/5b11abc1-cba3-4b70-a961-feea468198f8/923982ec-4921-4e33-8ef2-53c4556b8c7f
    PO_ALLOCATIONS = 'PO_ALLOCATIONS', //buyer/events/923982ec-4921-4e33-8ef2-53c4556b8c7f/awards                      //
    PO_STRATEGY = 'PO_STRATEGY', //buyer/events/923982ec-4921-4e33-8ef2-53c4556b8c7f/purchase_order_strategy
    PO_REVIEW = 'PO_REVIEW', //buyer/events/923982ec-4921-4e33-8ef2-53c4556b8c7f/purchase_order_review?type=All
    EVENTS_PURCHASE_ORDER = 'PURCHASE_OREDER', //buyer/purchase_orders

    // ADMIN DASHBOAD
    ADMIN_COMPANY_DETIALS_DASHBOARD = 'ADMIN_COMPANY_DETAILS_DASHBOARD', //admin/home/entity/56ce668c-0c25-4bad-9464-19f74d042c46
    ITEMS_DASHBOARD = 'ITEM_DASHBOARD', // admin/items/item-directory
    VENDOR_DASHBOARD = 'VENDOR_DASHBOARD', // admin/vendors
    ADMIN_BUYER_DASHBOARD = 'ADMIN_BUYER_DASHBOARD',
    ADMIN_ADDRESSES = 'ADMIN_ADDRESSES',
    ADMIN_USERS = 'ADMIN_USERS',
    ADMIN_TERMS_CONDITIONS = 'ADMIN_TERMS_CONDITIONS', // admin/templates/terms-and-conditions
    ADMIN_SETTINGS = 'ADMIN_SETTINGS',
    ADMIN_CONVERSIONS = 'ADMIN_CONVERSIONS', //admin/currency
    ADMIN_UOM_DASHBOARD = 'ADMIN_UOM_DASHBOARD',

    //

    /// D2PO////

    D2PO_DASHBOARD = 'PURCHASE_OREDER', //  buyer/purchase_orders
    CREATE_D2PO = 'CREATE_D2PO', // buyer/purchase_orders/direct/create
    ADD_EDIT_PO_GROUP = 'ADD_EDIT_PO_GROUP', // buyer/purchase_orders/direct/debc8bb0-dd8a-449d-80fa-533a4938961b
    D2PO_SUMMARY = 'D2PO_SUMMARY', // buyer/purchase_orders/direct/debc8bb0-dd8a-449d-80fa-533a4938961b/purchase_order_review?type=All

    //SELLER EVENTS ///
    SELLER_EVENTS_DASHBOARD = 'SELLER_EVENTS_DASHBOARD', // seller/events
    SELLER_OPENING_BID = 'SELLER_OPENING_BID', // seller/events/dfac9c09-4d46-40ec-86a0-65af3ab5ff61/10609373-d7c6-42c5-a7b1-c752d51b980e/rfqopeningbid
    SELLER_NEGOTIATIONS_PAGE = 'SELLER_NEGOTIATIONS_PAGE', // seller/events/c6102fb4-1445-4065-bb8d-54d5bfa0bf26/a1d2254e-3e2e-411a-9064-d2359eb8cb04/rfqnegotiation
    SELLER_SUMMARY_PAGE = 'SELLER_SUMMARY_PAGE', // seller/events/c6102fb4-1445-4065-bb8d-54d5bfa0bf26/eb2a7b5b-e1d1-496a-8328-a8673e26242e/summary

    SELLER_EVENTS_PURCHASE_ORDER = 'SELLER_EVENTS_PURCHASE_ORDER', // seller/purchase_orders

    //INVOICE//
    INVOICE_DASHBOARD = 'INVOICE_DASHBOARD', //buyer/invoices

    //GOODS RECEIPT//
    GOODS_RECEIPT_DASHBOARD = 'GOODS_RECEIPT_DASHBOARD', // buyer/goods-receipt?type=pending&view=purchase_order

    //QUALITY CHECK DASHBOARD
    QUALITY_CHECK_DASHBOARD = 'QUALITY_CHECK_DASHBOARD', // buyer/quality-check?type=pending

    //PAYMENTS_DASHBOARD//

    PAYMENTS_DASHBOARD = 'PAYMENTS_DASHBOARD',

    /// PRICING_AND_SUBSCRIPTION_DASHBOARD// buyer/payments?type=pending&view=DEFAULT

    PRICING_AND_SUBSCRIPTION_DASHBOARD = 'PRICING_AND_SUBSCRIPTION_DASHBOARD', //

    // TEMPLATES_DASHBOARD//

    TEMPLATES_DASHBOARD = 'TEMPLATES_DASHBOARD', // custom/templates/

    // PROJECT_DASHBOARD //

    PROJECT_DASHBOARD = 'PROJECT_DASHBOARD', // custom/cost-tracking/projects

    // Additional_Costs_Dashboard //

    Additional_Costs_Dashboard = 'Additional_Costs_Dashboard', // custom/additional-fields/additional-costs

    //ITEM_TAG_LINKAGE_DASHBOARD/////

    ITEM_TAG_LINKAGE_DASHBOARD = 'ITEM_TAG_LINKAGE_DASHBOARD', // custom/tags/linking-tags

    // HISTORICAL_ANALYTICS_DASHBOARD///

    HISTORICAL_ANALYTICS_DASHBOARD = 'HISTORICAL_ANALYTICS_DASHBOARD', // historical-analytics?tab=Financials and // historical-analytics?tab=Vendors // historical-analytics?tab=Items // historical-analytics?tab=Users

    //CUSTOM_ANALYTICS_DASHBOARD //

    CUSTOM_ANALYTICS_DASHBOARD = 'CUSTOM_ANALYTICS_DASHBOARD', // historical-analytics?tab=Custom+Analytics

    // QUOTE_CALCULATOR_DASHBOARD

    QUOTE_CALCULATOR_DASHBOARD = 'QUOTE_CALCULATOR_DASHBOARD', //seller/costing

    // Item Analytics ///
    ITEM_ANALYTICS_DASHBOARD = 'ITEM_ANALYTICS_DASHBOARD', //  buyer/item-analytics
    CART_EDIT_PAGE = 'CART_EDIT_PAGE', // buyer/cart

    //SRM
    SUPPLIER_RELATIONSHIP_MANAGEMENT_BUYER_DASHBOARD = 'SUPPLIER_RELATIONSHIP_MANAGEMENT_BUYER_DASHBOARD',

    //VENDOR SRM
    SELLER_DOCUMENTS_DASHBOARD = 'SELLER_DOCUMENTS_DASHBOARD',

    //CLM
    CREATE_CONTRACT = 'CREATE_CONTRACT',
    EDIT_CONTRACT = 'EDIT_CONTRACT',
    CONTRACT_REVIEW = 'CONTRACT_REVIEW',
    CLM_CHOOSE_TEMPLATE = 'CLM_CHOOSE_TEMPLATE',
    CLM_DASHBOARD = 'CLM_DASHBOARD',
}

const uuidRegexString =
    '[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}';

export const getHelpInternalPageNameBasedOnUrl: (
    url: string
) => undefined | keyof typeof HelpInternalPageName = (url) => {
    //Rahul to do here ADD THE VIDEO TO THIS
    if (new RegExp('^([/]{0,1})(buyer/events)([/]{0,1})$').test(url)) {
        return 'EVENT_DASHBOARD';
    }

    if (new RegExp('^([/]{0,1})(buyer/events/create)([/]{0,1})$').test(url)) {
        return 'CREATE_EVENT';
    }
    if (
        new RegExp(
            `^([/]{0,1})(buyer/events/rfq/)(${uuidRegexString})([/]{0,1})$`
        ).test(url)
    ) {
        return 'ADD_EDIT_ITEMS';
    }
    if (
        new RegExp(
            `^([/]{0,1})(buyer/events/)(${uuidRegexString})(/summary)([/]{0,1})$`
        ).test(url)
    ) {
        return 'BUYER_EVENTS_SUMMARY';
    }
    // buyer/events/live/923982ec-4921-4e33-8ef2-53c4556b8c7f/quotes
    if (
        new RegExp(
            `^([/]{0,1})(buyer/events/live/)(${uuidRegexString})(/quotes)([/]{0,1})$`
        ).test(url)
    ) {
        return 'BUYER_EVENTS_NEGOTIATIONS';
    }
    // buyer/events/rfq-analytics/5b11abc1-cba3-4b70-a961-feea468198f8/923982ec-4921-4e33-8ef2-53c4556b8c7f
    if (
        new RegExp(
            `^([/]{0,1})(buyer/events/rfq-analytics/)(${uuidRegexString})(/)(${uuidRegexString})([/]{0,1})$`
        ).test(url)
    ) {
        return 'RFQ_ANALYTICS';
    }
    // buyer/events/923982ec-4921-4e33-8ef2-53c4556b8c7f/awards
    if (
        new RegExp(
            `^([/]{0,1})(buyer/events/)(${uuidRegexString})(/awards)([/]{0,1})$`
        ).test(url)
    ) {
        return 'PO_ALLOCATIONS';
    }
    // buyer/events/923982ec-4921-4e33-8ef2-53c4556b8c7f/purchase_order_strategy
    if (
        new RegExp(
            `^([/]{0,1})(buyer/events/)(${uuidRegexString})(/purchase_order_strategy)([/]{0,1})$`
        ).test(url)
    ) {
        return 'PO_STRATEGY';
    }
    if (
        new RegExp(`^([/]{0,1})(custom/tags/linking-tags)([/]{0,1})$`).test(url)
    ) {
        return 'ITEM_TAG_LINKAGE_DASHBOARD';
    }
    // buyer/events/923982ec-4921-4e33-8ef2-53c4556b8c7f/purchase_order_review?type=All
    if (
        new RegExp(
            `^([/]{0,1})(buyer/events/)(${uuidRegexString})(/purchase_order_review)([/]{0,1})`
        ).test(url)
    ) {
        return 'PO_REVIEW';
    }
    // buyer/purchase_orders
    if (new RegExp(`([/]{0,1})(buyer/purchase_orders)([/]{0,1})$`).test(url)) {
        return 'EVENTS_PURCHASE_ORDER';
    }

    // admin/home
    if (new RegExp(`([/]{0,1})(admin/)([/]{0,1})`).test(url)) {
        return 'ADMIN_COMPANY_DETIALS_DASHBOARD';
    }
    if (new RegExp(`([/]{0,1})(buyer/purchase_orders)([/]{0,1})$`).test(url)) {
        return 'D2PO_DASHBOARD';
    }
    if (
        new RegExp(
            `([/]{0,1})(buyer/purchase_orders/direct/create)([/]{0,1})$`
        ).test(url)
    ) {
        return 'CREATE_D2PO';
    }
    if (
        new RegExp(
            `([/]{0,1})(buyer/purchase_orders/direct/)(${uuidRegexString})([/]{0,1})$`
        ).test(url)
    ) {
        return 'ADD_EDIT_PO_GROUP';
    }
    if (
        new RegExp(
            `([/]{0,1})(seller/events/)(${uuidRegexString})(/)(${uuidRegexString})(/rfqnegotiation)([/]{0,1})$`
        ).test(url)
    ) {
        return 'SELLER_NEGOTIATIONS_PAGE';
    }
    if (
        new RegExp(
            `([/]{0,1})(buyer/purchase_orders/direct/)(${uuidRegexString})(/purchase_order_review)([/]{0,1})$`
        ).test(url)
    ) {
        return 'D2PO_SUMMARY';
    }
    if (
        new RegExp(
            `^([/]{0,1})(seller/events/)(${uuidRegexString})(/)(${uuidRegexString})(/summary)([/]{0,1})$`
        ).test(url)
    ) {
        return 'SELLER_SUMMARY_PAGE';
    }
    if (
        new RegExp(
            `([/]{0,1})(buyer/purchase_orders/)(${uuidRegexString})(/summary)([/]{0,1})$`
        ).test(url)
    ) {
        return 'D2PO_DASHBOARD';
    }
    if (new RegExp(`([/]{0,1})(seller/events)([/]{0,1})$`).test(url)) {
        return 'SELLER_EVENTS_DASHBOARD';
    }
    if (
        new RegExp(
            `([/]{0,1})(seller/events/)(${uuidRegexString})/((${uuidRegexString}))(/rfqopeningbid)([/]{0,1})$`
        ).test(url) ||
        new RegExp(
            `([/]{0,1})(seller/events/)(${uuidRegexString})/((${uuidRegexString}))(/rfqinvitation)([/]{0,1})$`
        ).test(url)
    ) {
        return 'SELLER_OPENING_BID';
    }
    if (new RegExp(`([/]{0,1})(seller/purchase_orders)([/]{0,1})$`).test(url)) {
        return 'SELLER_EVENTS_PURCHASE_ORDER';
    }
    if (
        new RegExp(
            `([/]{0,1})(seller/purchase_orders)(/)(${uuidRegexString})(/review)([/]{0,1})$`
        ).test(url)
    ) {
        return 'SELLER_EVENTS_PURCHASE_ORDER';
    }
    if (
        new RegExp(
            `([/]{0,1})(seller/purchase_orders)(/)(${uuidRegexString})(/summary)([/]{0,1})$`
        ).test(url)
    ) {
        return 'SELLER_EVENTS_PURCHASE_ORDER';
    }
    if (new RegExp(`([/]{0,1})(buyer/invoices)([/]{0,1})$`).test(url)) {
        return 'INVOICE_DASHBOARD';
    }
    if (new RegExp(`([/]{0,1})(buyer/goods-receipt)([/]{0,1})$`).test(url)) {
        return 'GOODS_RECEIPT_DASHBOARD';
    }
    if (new RegExp(`([/]{0,1})(buyer/quality-check)([/]{0,1})$`).test(url)) {
        return 'QUALITY_CHECK_DASHBOARD';
    }
    if (new RegExp(`([/]{0,1})(buyer/payments)([/]{0,1})$`).test(url)) {
        return 'PAYMENTS_DASHBOARD';
    }
    if (new RegExp(`([/]{0,1})(custom/templates)([/]{0,1})$`).test(url)) {
        return 'TEMPLATES_DASHBOARD';
    }
    // if(new RegExp(`([/]{0,1})(buyer/historical-analytics?tab=Custom+Analytics([/]{0,1})$`).test(url)){
    //     return 'CUSTOM_ANALYTICS_DASHBOARD'
    // }
    if (
        new RegExp(`([/]{0,1})(buyer/historical-analytics)([/]{0,1})`).test(url)
    ) {
        if (window.location.search === '?tab=Custom+Analytics') {
            return 'CUSTOM_ANALYTICS_DASHBOARD';
        }
        return 'HISTORICAL_ANALYTICS_DASHBOARD';
    }
    if (
        new RegExp(
            `([/]{0,1})(custom/additional-fields/additional-costs)([/]{0,1})$`
        ).test(url)
    ) {
        return 'Additional_Costs_Dashboard';
    }
    if (
        new RegExp(`([/]{0,1})(seller/costing)([/]{0,1})$`).test(url) ||
        new RegExp(
            `([/]{0,1})(seller/costing)(/)()${uuidRegexString}([/]{0,1})$`
        ).test(url)
    ) {
        return 'QUOTE_CALCULATOR_DASHBOARD';
    }
    if (new RegExp(`([/]{0,1})(buyer/item-analytics)([/]{0,1})$`).test(url)) {
        return 'ITEM_ANALYTICS_DASHBOARD';
    }
    if (new RegExp(`([/]{0,1})(buyer/cart)([/]{0,1})([/]{0,1})$`).test(url)) {
        return 'CART_EDIT_PAGE';
    }
    if (
        new RegExp(
            `([/]{0,1})(buyer/carts/)(${uuidRegexString})/((${uuidRegexString}))([/]{0,1})$`
        ).test(url)
    ) {
        return 'CART_EDIT_PAGE';
    }
    if (new RegExp(`([/]{0,1})(seller/invoices)([/]{0,1})$`).test(url)) {
        return 'INVOICE_DASHBOARD';
    }
    if (
        new RegExp(`([/]{0,1})(buyer/srm/documents)([/]{0,1})$`).test(url) ||
        new RegExp(
            `([/]{0,1})(buyer/srm/documents/((${uuidRegexString})))([/]{0,1})$`
        ).test(url) ||
        new RegExp(
            `([/]{0,1})(buyer/srm/documents/((${uuidRegexString}))/draft)([/]{0,1})$`
        ).test(url) ||
        new RegExp(`([/]{0,1})(buyer/srm)([/]{0,1})$`).test(url)
    ) {
        return 'SUPPLIER_RELATIONSHIP_MANAGEMENT_BUYER_DASHBOARD';
    }
    if (
        new RegExp(`([/]{0,1})(seller/profile)([/]{0,1})$`).test(url) ||
        new RegExp(`([/]{0,1})(seller/profile/documents/)([/]{0,1})$`).test(
            url
        ) ||
        new RegExp(
            `([/]{0,1})(seller/profile/documents/((${uuidRegexString}))/((${uuidRegexString})))([/]{0,1})$`
        ).test(url)
    ) {
        return 'SELLER_DOCUMENTS_DASHBOARD';
    }
    if (
        new RegExp(`([/]{0,1})(custom/cost-tracking/projects)([/]{0,1})$`).test(
            url
        ) ||
        new RegExp(
            `([/]{0,1})(custom/cost-tracking/projects)(/)(${uuidRegexString})(/)(draft)([/]{0,1})$`
        ).test(url)
    ) {
        return 'PROJECT_DASHBOARD';
    }
    if (new RegExp(`([/]{0,1})(buyer/item-analytics)([/]{0,1})$`).test(url)) {
        return 'ITEM_ANALYTICS_DASHBOARD';
    }
    if (
        new RegExp(`([/]{0,1})(buyer/clm)([/]{0,1})$`).test(url) ||
        new RegExp(`([/]{0,1})(buyer/CLM)([/]{0,1})$`).test(url)
    ) {
        return 'CLM_DASHBOARD';
    }
    if (new RegExp(`([/]{0,1})(buyer/CLM/template)([/]{0,1})$`).test(url)) {
        return 'CLM_CHOOSE_TEMPLATE';
    }
    if (
        new RegExp(
            `([/]{0,1})(buyer/CLM/template)(/)(${uuidRegexString})(/contract)([/]{0,1})$`
        ).test(url)
    ) {
        return 'CREATE_CONTRACT';
    }
    if (
        new RegExp(
            `([/]{0,1})(buyer/CLM/template)(/)(${uuidRegexString})(/contract/)(${uuidRegexString})([/]{0,1})$`
        ).test(url) &&
        !store.getState().CLMStore.viewMode
    ) {
        return 'EDIT_CONTRACT';
    }
    if (
        new RegExp(
            `([/]{0,1})(buyer/CLM/template)(/)(${uuidRegexString})(/contract/)(${uuidRegexString})([/]{0,1})$`
        ).test(url) &&
        store.getState().CLMStore.viewMode
    ) {
        return 'CONTRACT_REVIEW';
    }

    return undefined;
};

export interface IHelpInformationState {
    openHelp: boolean;
    pageName: HelpPageName | null;
    pageInternalName: HelpInternalPageName | null;
}

export const HelpInformationSlice = createSlice<
    IHelpInformationState,
    {
        updateHelpInformation: (
            state: IHelpInformationState,
            action: IHelpInformationActions
        ) => void;
    },
    string
>({
    name: 'HelpInformationStore',
    initialState: {
        pageName: null,
        openHelp: false,
        pageInternalName: null,
    },
    reducers: {
        updateHelpInformation: updateHelpInformationReducer,
    },
});

export const { updateHelpInformation } = HelpInformationSlice.actions;

const HelpInformationStore = HelpInformationSlice.reducer;
export default HelpInformationStore;
