import { Box, Grid } from '@mui/material';
import { FWTooltip } from '../../../Common/FWCustomTooltip';
import { FWTypography } from '../../../Common/FWTypograhy';
import {
    IRfqTemplate,
    TemplateStandardFieldsEnum,
} from '../../../Global/Interfaces/TemplateInterface';

interface IAdditionalDetailsAtEventLevel {
    templateDetails: IRfqTemplate;
    accessedBy: 'BUYER' | 'VENDOR';
    gridSize: number;
    requisition_no: string;
    requisition_approved: boolean;
    default_event_item_additional_information: string;
}

const AdditionalDetailsAtEventLevelViewMode = ({
    templateDetails,
    accessedBy,
    gridSize,
    default_event_item_additional_information,
    requisition_approved,
    requisition_no,
}: IAdditionalDetailsAtEventLevel) => {
    return (
        <>
            {templateDetails.sections.EVENT_DETAILS.fieldList.map(
                (singleField, idx) => {
                    if (singleField.fieldType === 'STANDARD') {
                        if (
                            templateDetails.fieldSettings.standardFields[
                                singleField.field
                            ].buyerSettings.showField
                        )
                            switch (singleField.field) {
                                case TemplateStandardFieldsEnum.REQUISITION:
                                    return (
                                        (accessedBy === 'BUYER' ||
                                            (accessedBy === 'VENDOR' &&
                                                templateDetails.fieldSettings
                                                    .standardFields[
                                                    TemplateStandardFieldsEnum
                                                        .REQUISITION
                                                ].sellerSettings
                                                    .showField)) && (
                                            <Grid item xs={gridSize}>
                                                <div className="flex flex--aic">
                                                    <FWTypography color="text.secondary">
                                                        {
                                                            templateDetails
                                                                .fieldSettings
                                                                .standardFields[
                                                                singleField
                                                                    .field
                                                            ].label
                                                        }
                                                    </FWTypography>
                                                    &nbsp;
                                                    {templateDetails
                                                        .fieldSettings
                                                        .standardFields[
                                                        singleField.field
                                                    ]?.description && (
                                                        <FWTooltip
                                                            title={
                                                                templateDetails
                                                                    .fieldSettings
                                                                    .standardFields[
                                                                    singleField
                                                                        .field
                                                                ]?.description
                                                            }
                                                            placement="right"
                                                        >
                                                            <Box color="primary.main">
                                                                <i className="bi bi-info-circle" />
                                                            </Box>
                                                        </FWTooltip>
                                                    )}
                                                </div>

                                                <FWTypography>
                                                    {requisition_no === '' ||
                                                    requisition_no === null
                                                        ? '-'
                                                        : `${requisition_no} ${
                                                              !requisition_approved &&
                                                              '(Not Approved)'
                                                          } `}
                                                </FWTypography>

                                                {/* {'requisition_approval' in
                                                          props.errors && (
                                                          <>
                                                              &nbsp;
                                                              <FWTooltip title="Requisition not approved">
                                                                  <Box color="warning.main">
                                                                      <i className="bi bi-exclamation-circle" />
                                                                  </Box>
                                                              </FWTooltip>
                                                          </>
                                                      )} */}
                                            </Grid>
                                        )
                                    );
                                case TemplateStandardFieldsEnum.ADDITIONAL_INFORMATION:
                                    return (
                                        (accessedBy === 'BUYER' ||
                                            (accessedBy === 'VENDOR' &&
                                                templateDetails.fieldSettings
                                                    .standardFields[
                                                    TemplateStandardFieldsEnum
                                                        .ADDITIONAL_INFORMATION
                                                ].sellerSettings
                                                    .showField)) && (
                                            <Grid item xs={gridSize}>
                                                <div className="flex flex--aic">
                                                    <FWTypography color="text.secondary">
                                                        {
                                                            templateDetails
                                                                .fieldSettings
                                                                .standardFields[
                                                                singleField
                                                                    .field
                                                            ].label
                                                        }
                                                    </FWTypography>
                                                    &nbsp;
                                                    {templateDetails
                                                        .fieldSettings
                                                        .standardFields[
                                                        singleField.field
                                                    ]?.description && (
                                                        <FWTooltip
                                                            title={
                                                                templateDetails
                                                                    .fieldSettings
                                                                    .standardFields[
                                                                    singleField
                                                                        .field
                                                                ]?.description
                                                            }
                                                            placement="right"
                                                        >
                                                            <Box color="primary.main">
                                                                <i className="bi bi-info-circle" />
                                                            </Box>
                                                        </FWTooltip>
                                                    )}
                                                </div>

                                                <FWTypography>
                                                    {!Boolean(
                                                        default_event_item_additional_information
                                                    )
                                                        ? '-'
                                                        : default_event_item_additional_information}
                                                </FWTypography>
                                            </Grid>
                                        )
                                    );
                            }
                        else return null;
                    } else {
                        return <></>;
                    }
                    return <></>;
                }
            )}
        </>
    );
};

export default AdditionalDetailsAtEventLevelViewMode;
