import { Box, Grid, IconButton } from '@mui/material';
import FWDashboardDataGrid from '../../Common/FWDashboardDataGrid';

import { ColumnApi, GridApi } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
// import { capitaliseFirstLetter } from '../../BuyerTemplates/Component/TemplateSuggestedFieldsSection';
import { TemplateData } from '../../BuyerTemplates/Interfaces/TemplatePageInterfaces';
import { FWButton } from '../../Common/FWButton';
import { FWMenu, FWMenuItem } from '../../Common/FWCustomMenu';
import { FWTooltip } from '../../Common/FWCustomTooltip';
import { FWPopup } from '../../Common/FWPopup';
// import { getFWDateTime } from '../../Common/Utils/DateUtils';
import TitleBar from '../../Components/Shared/TitleBar';
import { AuthContext } from '../../Contexts/AuthContext';
import { NavContext } from '../../Contexts/NavContext';
// import CustomDashboardGridHeader from '../../Global/Components/CustomDashboardGridHeader';
import { downloadFileFromURL } from '../../Global/Helpers/csvHelpers';
import { IEntity } from '../../Organizations/Admin/Interfaces/EnterpriseInterface';
import { ProjectPermissions } from '../../Organizations/Admin/Interfaces/UsersInterface';
import { fetchEntities } from '../../Organizations/Admin/Services/EnterpriseService';
import { IGlobalAppStore } from '../../Redux/Store';
import { post } from '../../Utils/api';
import AssignUserAndManagerFOrProject from '../components/AssignUserAndMangerForProject';
import EntityAndTemplateSelectionPopup from '../components/EntityandTemplateSelectionPopup';
import ProjetAnalyticsGraph from '../components/Graphs/ProjectGraphsPopup';
// import ProjectManagerCell from '../components/ProjectManagerPopover';
import { NavLink } from 'react-router-dom';
import { useGetAllCurrencyOptionsQuery } from '../../AdditionalCost/Services/additionalCosts.services';
import LoadingBackDrop from '../../Components/Shared/LoadingBackdrop';
import { PROJECT_DASHBOARD_FIELD_DELIMITER } from '../helpers/projectDashboardHelper';
import {
    checkPermissionInTemplateList,
    findUserInSectionAssignedUsersList,
} from '../helpers/projectHelpers';
import useProjectCreationHook from '../hooks/useProjectCreationHook';
import { useProjectDashboard } from '../hooks/useProjectDashboard';
import { useProjectTemplateHooks } from '../hooks/useProjectTemplateHooks';
import IProject, {
    IprojectDashboardContext,
    IProjectEssentials,
    ProjectStatusEnum,
} from '../interface/project.model';
import {
    deleteProject,
    updateStatusOfProject,
    useGetListOfAllProjectTemplatesQuery,
    useGetProjectTabCountMutation,
    useListProjectsViaDasboardMutation,
} from '../services/project.services';
import {
    builtInFieldsMap,
    filterFieldToNameMap,
} from '../constants/projectDashboardConstants';

export type sortFieldsProjectEnterprise =
    | 'projectCode'
    | 'projectName'
    | 'modified_datetime'
    | 'createdOn'
    | 'status'
    | 'createdBy'
    | 'modified_by_user_name'
    | 'customer_entity'
    | 'linkedCostCenterCount';

// const sortFieldsBackendMapping = {
//     projectCode: 'project_code',
//     projectName: 'project_name',
//     createdOn: 'created_datetime',
//     createdBy: 'created_by_user_name',
//     modified_by_user_name: 'modified_by_user_name',
//     customer_entity: 'customer_entity',
//     status: 'project_status',
//     modified_datetime: 'modified_datetime',
//     linkedCostCenterCount: 'cost_centre_count',
// };

const ProjectDashBoard = () => {
    const [fetchList] = useListProjectsViaDasboardMutation();

    const [fetchProjectTabCount] = useGetProjectTabCountMutation({
        fixedCacheKey: 'ProjectTabCount',
    });

    const [isDataLoaded, setIsDataLoaded] = useState(false);
    // const [loading, setLoading] = useState<boolean>(false);

    const { currentTab } = useContext(NavContext);

    const [listOfEntities, setListOfEntities] = useState<IEntity[]>([]);
    useEffect(() => {
        fetchEntities().then((res) => {
            setListOfEntities(res);
        });
    }, []);

    const { createProject, updateCreateProjectPayload } =
        useProjectCreationHook();

    const [filterByStatus, setFilterByStatus] = useState<string>('all');
    const [gridColumnApi, setGridColumnApi] = useState<ColumnApi | null>(null);

    const { authData } = useContext(AuthContext);

    const currentUserId = useMemo(() => {
        return authData.details?.user_id ?? '';
    }, [authData.details?.user_id]);

    const { data: projectTemplateList } = useGetListOfAllProjectTemplatesQuery({
        templateType: 'PROJECT',
    });

    const { data: allCurrencies } = useGetAllCurrencyOptionsQuery({});

    const {
        canUserEditOrDeleteProject,
        canUserAssignPMFromAdmin,
        canUserAssignUserFromAdmin,
        canUserCreateProject,
        dashboardColumns,
        templateSectionList,
        filterByEntity,
        dashboardFilter,
        rowData,
        page,
        search,
        totalPages,
        sortField,
        totalItems,
        showRefreshIcon,

        isLoading,
        fieldTypeLabels,
        onPageChange,
        onSearchChange,
        onDashboardFilterChange,
        onRowsPerPageChange,
        handleBuyerEntityChange,
        onChangeShowRefreshIconShow,
        handleSelectedTemplateSectionChange,
        onSortFieldChange,
        onRefreshIconClick,
    } = useProjectDashboard();

    console.log('fieldTypeLabels', fieldTypeLabels);

    const MAXIMUM_COLUMNS_ON_DASHBOARD = 10;

    const [listOfUsableTemplate, setListOfUsableTemplates] = useState<
        TemplateData[]
    >([]);

    useEffect(() => {
        if (
            projectTemplateList &&
            authData &&
            projectTemplateList[0].templates
        ) {
            const templateList = checkPermissionInTemplateList(
                projectTemplateList[0].templates.filter(
                    (temp) => temp.status === 'ONGOING'
                ),
                'CREATE',
                authData?.details?.user_id ?? '',
                'TEMPLATE'
            );

            setListOfUsableTemplates(templateList);
            const timer = setTimeout(() => {
                setIsDataLoaded(true);
            }, 1000);
            return () => clearTimeout(timer);
        }
    }, [authData, projectTemplateList]);

    useEffect(() => {
        if (filterByEntity !== 'global') {
            setFilterByStatus('all');
        }
    }, [filterByEntity]);

    const timerRef = useRef<any>(null);
    const debouncedSearch = (newText: string) => {
        if (timerRef.current !== null) {
            clearTimeout(timerRef.current);
        }
        timerRef.current = setTimeout(() => {
            onSearchChange(newText);
        }, 500);
    };

    const updateStatusOfProject = useCallback(() => {
        try {
            // fetchProjectTabCount();
            fetchList({
                page_number: dashboardFilter ? 1 : page,
                search_text: search,
                entity_id:
                    filterByEntity === 'global' ? undefined : filterByEntity,
                tab: currentTab === '' ? 'in_progress' : currentTab,
                query_data:
                    filterByStatus === 'all'
                        ? {}
                        : {
                              status:
                                  filterByStatus === 'active'
                                      ? ProjectStatusEnum.ACTIVE
                                      : ProjectStatusEnum.INACTIVE,
                          },
                sort_fields: sortField,
                filters: dashboardFilter,
            });
        } catch (err) {}
    }, [
        fetchList,
        page,
        search,
        filterByEntity,
        currentTab,
        filterByStatus,
        sortField,
        dashboardFilter,
    ]);

    const cookieName: string = useMemo(() => {
        return authData.details?.name
            ? `${authData.details?.name}project - dashboard`
            : 'project-dashboard';
    }, [authData.details?.name]);

    const [assignUserAndPMForProjectInfo, setAssignUserAndPMForProjectInfo] =
        useState<IProjectEssentials>({
            projectId: '',
            buyerEntityId: '',
            projectName: '',
            projectCreationDate: '',
            isUserShortlistedAsPM: false,
        });

    const [openAssignUserPopup, setOpenAssignUserPopup] = useState(false);

    const handleAssignableProjectIdChange = useCallback(
        (
            projectId: string,
            projectName: string,
            projectCreationDate: string,
            buyerEntityId: string,
            isUserShortlistedAsPM: boolean
        ) => {
            setAssignUserAndPMForProjectInfo({
                projectId,
                projectName,
                projectCreationDate,
                isUserShortlistedAsPM,
                buyerEntityId,
            });

            setOpenAssignUserPopup(projectId !== '');
        },
        []
    );

    const SINGLE_ENTITY = useSelector(
        (store: IGlobalAppStore) => store.VerificationStore.hasSignleEntity
    );

    const [selectEntityAndTemplate, setSelectEntityAndTemplate] =
        useState<boolean>(false);

    const [openProjetAnalyticsGraph, setOpenProjetAnalyticsGraph] =
        useState(false);

    const [isExportLoading, setIsExportLoading] = useState(false);
    const handleExportButton = useCallback(async () => {
        try {
            setIsExportLoading(true);
            const exportDataForProject = await post(
                `/export/project/download/`,
                {
                    template_id: projectTemplateList
                        ? projectTemplateList[0].templates.filter(
                              (temp) => temp.is_default === true
                          )
                            ? projectTemplateList[0].templates.filter(
                                  (temp) => temp.is_default === true
                              )[0].template_id
                            : projectTemplateList[0].templates.filter(
                                  (temp) => temp.status === 'ONGOING'
                              )[0].template_id
                        : null,
                    ...(filterByEntity !== 'global'
                        ? { buyer_entity_id: filterByEntity }
                        : {}),
                }
            );
            const getUrlForProject = await post<any, string>(
                `/export/url/generate/download/`,
                {
                    export_id: exportDataForProject.data,
                    resource_type: 'PROJECT',
                }
            );
            downloadFileFromURL(getUrlForProject.data, '');
            setIsExportLoading(false);
        } catch (error) {
            setIsExportLoading(false);
        }
    }, [filterByEntity, projectTemplateList]);

    const gridRef = useRef<AgGridReact | null>(null);

    // const handleColumnMoved = useCallback(
    //     (columnState: ColumnState[]) => {
    //         try {
    //             if (cookieName) {
    //                 localStorage.setItem(
    //                     cookieName,
    //                     JSON.stringify(columnState)
    //                 );
    //             }
    //         } catch (err) {}
    //     },
    //     [cookieName]
    // );

    // const handleFilterChanged = useCallback(
    //     (filterState: { [key: string]: any }) => {
    //         try {
    //             if (cookieName) {
    //                 localStorage.setItem(
    //                     `${cookieName}-filter`,
    //                     JSON.stringify(filterState)
    //                 );
    //             }
    //         } catch (err) {}
    //     },
    //     [cookieName]
    // );

    const [gridApi, setGridApi] = useState<GridApi | undefined>(undefined);

    const applyColumnState = useCallback(() => {
        if (gridApi && cookieName && gridColumnApi) {
            // let colState: any = localStorage.getItem(cookieName);
            let filterState: any = localStorage.getItem(`${cookieName}-filter`);
            // if (colState) {
            //     colState = JSON.parse(colState) as ApplyColumnStateParams;
            //     gridColumnApi.applyColumnState({
            //         state: colState,
            //         applyOrder: true,
            //     });
            // }
            if (filterState) {
                filterState = JSON.parse(filterState);
                let _filteredColumns: string[] = [];
                Object.keys(filterState).forEach((key) => {
                    let col = gridColumnApi.getColumn(key);
                    let colData = col?.getColDef();
                    if (col && colData) {
                        _filteredColumns.push(colData.headerName ?? '');
                    }
                });

                // gridApi.setFilterModel(filterState);
            }
        }
    }, [cookieName, gridApi, gridColumnApi]);

    useEffect(() => {
        applyColumnState();
    }, [applyColumnState]);

    const showLoader = useMemo(() => {
        return !isDataLoaded;
    }, [isDataLoaded]);

    return showLoader ? (
        <LoadingBackDrop />
    ) : (
        <>
            <TitleBar title="Project Dashboard" hideBackButton={true}>
                <NavLink to="/custom/cost-tracking/projects/graphs/">
                    <IconButton color="primary" sx={{ marginRight: 2 }}>
                        <i className="bi bi-bar-chart-line"></i>
                    </IconButton>
                </NavLink>
                {(authData.details?.role === 'ADMIN' ||
                    canUserCreateProject) && (
                    <FWTooltip
                        title={
                            listOfUsableTemplate?.length === 0
                                ? 'You do not have create rights for any project template'
                                : ''
                        }
                    >
                        <Box>
                            <FWButton
                                variant="contained"
                                disabled={listOfUsableTemplate?.length === 0}
                                // onClick={() => history.push('/buyer/events/create')}
                                onClick={() => setSelectEntityAndTemplate(true)}
                            >
                                New Project
                            </FWButton>
                        </Box>
                    </FWTooltip>
                )}
            </TitleBar>

            <Grid
                container
                xs={12}
                margin={'auto'}
                marginTop={1}
                paddingX={2.5}
            >
                <Grid item xs={12} margin={'auto'}>
                    <FWDashboardDataGrid
                        // onColumnResized={(event) => {
                        //     const columnState =
                        //         event.columnApi.getColumnState();
                        //     localStorage.setItem(
                        //         'dashboardColumnState',
                        //         JSON.stringify(columnState)
                        //     );
                        // }}
                        dashboardType="project"
                        totalItems={totalItems}
                        displayLoader={false}
                        currentTab={currentTab}
                        gridRef={gridRef}
                        onReady={(event) => {
                            try {
                                setGridApi(event.api);
                                setGridColumnApi(event.columnApi);

                                // Restoring column state
                                // const savedState = localStorage.getItem(
                                //     'dashboardColumnState'
                                // );
                                // if (savedState) {
                                //     const columnState = JSON.parse(savedState);
                                //     event.columnApi.applyColumnState({
                                //         state: columnState,
                                //         applyOrder: true,
                                //     });
                                // }

                                const colData =
                                    event.columnApi.getColumnState();
                                const sortedColumn = colData?.find(
                                    (col) => col.sort !== null
                                );
                                if (sortedColumn && !sortField) {
                                }
                            } catch (error) {
                                console.error(
                                    'Error during grid setup:',
                                    error
                                );
                            }
                        }}
                        fieldTypeLabels={fieldTypeLabels}
                        builtInFieldsMap={builtInFieldsMap}
                        filterFieldToNameMap={filterFieldToNameMap}
                        onTextChange={debouncedSearch}
                        allowFilterProjectByStatus={true}
                        filterByEntity={filterByEntity}
                        maximumColumnsOnDashboard={10}
                        // allowFilterByStatus={true}
                        filterByStatus={filterByStatus?.toUpperCase()}
                        setFilterByStatus={setFilterByStatus}
                        templateSections={templateSectionList}
                        templateSectionDelimiter={
                            PROJECT_DASHBOARD_FIELD_DELIMITER
                        }
                        handleSelectedTemplateSectionChange={
                            handleSelectedTemplateSectionChange
                        }
                        saveDashboardColumnOrder={true}
                        buyerEntities={[
                            {
                                entityName: 'Global',
                                entityUid: 'GLOBAL_ENTITY',
                                preferredItemsCount: 0,
                                vendorEntitySelected: false,
                            },
                            ...listOfEntities.map((entity) => ({
                                entityName: entity.entityName,
                                entityUid: entity.entityId,
                                preferredItemsCount: 0,
                                vendorEntitySelected: false,
                            })),
                        ]}
                        allowFilterByBuyerEntity={!SINGLE_ENTITY}
                        onBuyerEntityChange={handleBuyerEntityChange}
                        showSearchBar
                        showExportButton
                        disableExportButton={isExportLoading}
                        exportBtnOnClick={handleExportButton}
                        isLoading={isLoading}
                        fixedHeight={560}
                        cookieName={cookieName}
                        columns={dashboardColumns}
                        sortField={sortField}
                        dashboardFilter={dashboardFilter}
                        // onColumnMoved={(event) => {
                        // handleColumnMoved(event.columnApi.getColumnState());
                        // }}
                        handleRowsPerPageChange={onRowsPerPageChange}
                        handleFilterApply={onDashboardFilterChange}
                        handleSortApply={onSortFieldChange}
                        onRowClicked={() => {
                            // the following is done because on clicking on attachment button, it should not redirect the user.
                            // history.push(
                            //     `/custom/cost-tracking/projects/${event.data.projectUUid}/draft`
                            // );
                        }}
                        // resetFilters={resetFilters}
                        // onFilterChanged={(event) => {
                        //     const filtersFromGrid = event.api.getFilterModel();
                        //     // gridFilterToDBeFilterMap(filtersFromGrid);
                        //     handleFilterChanged(filtersFromGrid);
                        // }}
                        onSortChanged={(event) => {
                            const colData = event.columnApi.getColumnState();
                            const sortedColumn = colData.find(
                                (col) => col.sort !== null
                            );
                            let colState: any =
                                localStorage.getItem(cookieName);
                            colState = JSON.parse(colState);
                            const localSortField = colState.find(
                                (col: any) => col.sort !== null
                            );

                            if (
                                sortedColumn &&
                                (localSortField === undefined ||
                                    sortedColumn.colId !==
                                        localSortField.colId ||
                                    (sortedColumn.colId ===
                                        localSortField.colId &&
                                        sortedColumn.sort !==
                                            localSortField.sort))
                            ) {
                                // let col = event.columnApi.getColumn(
                                //     sortedColumn.colId
                                // );
                                // const sortField = {
                                //     // field: sortedColumn.colId as sortFieldsProjectEnterprise,
                                //     ascending:
                                //         sortedColumn.sort === 'asc'
                                //             ? true
                                //             : false,
                                //     type:
                                //         col?.getColDef().refData?.[
                                //             DASHBOARD_FIELD_TYPE
                                //         ] || null,
                                //     field:
                                //         col?.getColDef().refData?.[
                                //             DASHBOARD_FIELD_NAME
                                //         ] ?? '',
                                //     field_type:
                                //         col?.getColDef().refData?.[
                                //             DASHBOARD_FIELD
                                //         ] || '',
                                // };
                                // onSortFieldChange([sortField]);
                            }
                            // handleColumnMoved(colData);
                        }}
                        defaultColDef={{
                            resizable: true,
                        }}
                        rows={rowData}
                        suppressPaginationPanel
                        useCustomPagination={totalPages}
                        customPaginationOnChange={(e, pageNumber) => {
                            onPageChange(pageNumber);
                        }}
                        customPaginationPageNumber={page}
                        context={
                            {
                                updateStatusOfProject,
                                canUserEditOrDeleteProject,
                                fetchList,
                                currentUserId,
                                handleAssignableProjectIdChange,
                                canUserAssignPMFromAdmin,
                                canUserAssignUserFromAdmin,
                                allCurrencies,
                                fetchProjectTabCount,
                                currentTab,
                                MAXIMUM_COLUMNS_ON_DASHBOARD,
                            } as IprojectDashboardContext
                        }
                        showRefreshIcon={showRefreshIcon}
                        onRefreshIconClick={() => {
                            onChangeShowRefreshIconShow(false);
                            onRefreshIconClick();
                        }}
                        showFilterColumnMenu
                    />
                </Grid>

                <EntityAndTemplateSelectionPopup
                    open={selectEntityAndTemplate}
                    handleClose={() => setSelectEntityAndTemplate(false)}
                    customHandlerForSave={createProject}
                    callDefaultSaveHandler={false}
                    accessDefaultTemplateList={false}
                    customTemplateList={listOfUsableTemplate}
                    customUpdateHandler={updateCreateProjectPayload}
                    title="Select entity and template for the project"
                    showCancel={true}
                    url=""
                    entityIdUpdateKey="buyer_entity_id"
                    templateType="PROJECT"
                />
            </Grid>

            <AssignUserAndManagerFOrProject
                canUserAssignPMFromAdmin={canUserAssignPMFromAdmin}
                canUserAssignUserFromAdmin={canUserAssignUserFromAdmin}
                currentUserId={authData?.details?.user_id ?? ''}
                projectEssentials={assignUserAndPMForProjectInfo}
                isUserShortlistedAsPMForProject={
                    assignUserAndPMForProjectInfo.isUserShortlistedAsPM
                }
                handleClose={() => {
                    setOpenAssignUserPopup(false);
                    handleAssignableProjectIdChange('', '', '', '', false);
                }}
                open={openAssignUserPopup}
            />
            <ProjetAnalyticsGraph
                open={openProjetAnalyticsGraph}
                handleClose={() => setOpenProjetAnalyticsGraph(false)}
                title="Project analytics"
            />
        </>
    );
};

export default ProjectDashBoard;

export const MenuRender = (props: {
    data: IProject;
    context: IprojectDashboardContext;
}) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClose = () => setAnchorEl(null);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) =>
        setAnchorEl(event.currentTarget);

    const { checkPermission } = useContext(AuthContext);

    // UPDATE THE STATUS WHEN BE IS DONE
    const showDisableProjectPermission = useMemo(() => {
        return (
            props.data.projectStatus !== 'DRAFT' &&
            props.context.canUserEditOrDeleteProject
        );
    }, [props.context.canUserEditOrDeleteProject, props.data.projectStatus]);

    const { templateDetails } = useProjectTemplateHooks(
        props.data.entityUid,
        props.data.templateId
    );
    const [fetchProjectTabCount] = useGetProjectTabCountMutation({
        fixedCacheKey: 'ProjectTabCount',
    });

    const allowUserToDeleteProject = useMemo(() => {
        const isUserTheCreatorOfTheProject =
            props.data.createdByUserId === props.context.currentUserId;

        const isUserAssignedToAnySection =
            props.data.projectCustomSections?.some((customSection) =>
                customSection.assigned_users?.includes(
                    props.context.currentUserId
                )
            );

        const isUserShortlistedAsPM =
            templateDetails.assigned_users.TEMPLATE_LEVEL?.length > 0
                ? templateDetails.assigned_users.TEMPLATE_LEVEL?.some(
                      (user) =>
                          user.user_id === props.context.currentUserId &&
                          user.permission ===
                              ProjectPermissions.PROJECT_ASSIGN_MANAGERS
                  )
                : checkPermission(
                      'CUSTOMIZE',
                      'PROJECT',
                      'PROJECT_ASSIGN_MANAGERS',
                      props.data.entityUid
                  );

        const isUserShortlistedForASectionInTemplate = Object.keys(
            templateDetails.sections
        )?.some((section) =>
            templateDetails.assigned_users[section]?.length > 0
                ? templateDetails.assigned_users[section].some(
                      (user) => user.user_id === props.context.currentUserId
                  )
                : checkPermission(
                      'CUSTOMIZE',
                      'PROJECT',
                      'PROJECT_EDIT',
                      props.data.entityUid
                  )
        );

        return (
            isUserShortlistedForASectionInTemplate &&
            (isUserTheCreatorOfTheProject ||
                isUserShortlistedAsPM ||
                isUserAssignedToAnySection)
        );
    }, [
        checkPermission,
        props.context.currentUserId,
        props.data.createdByUserId,
        props.data.entityUid,
        props.data.projectCustomSections,
        templateDetails.assigned_users,
        templateDetails.sections,
    ]);

    const showAssignUsersOption = useMemo(() => {
        if (templateDetails) {
            const templateStatus = findUserInSectionAssignedUsersList(
                props.context.currentUserId,
                templateDetails
            );

            const isUserPMForProject =
                props.context.canUserAssignPMFromAdmin &&
                ((templateDetails.assigned_users.TEMPLATE_LEVEL?.length > 0
                    ? templateDetails.assigned_users.TEMPLATE_LEVEL.some(
                          (user) =>
                              user.user_id === props.context.currentUserId &&
                              user.permission ===
                                  ProjectPermissions.PROJECT_ASSIGN_MANAGERS
                      )
                    : true) ||
                    props.data.projectManagers.some(
                        (manager) =>
                            manager.user_id === props.context.currentUserId
                    ));

            if (
                isUserPMForProject &&
                props.context.currentTab === 'in_progress'
            ) {
                return true;
            }

            if (
                props.context.canUserEditOrDeleteProject &&
                (templateStatus.noUserShortlistedForAnySection ||
                    templateStatus.userShortlistedForAnySection) &&
                props.context.currentTab === 'in_progress'
            ) {
                return (
                    props.data.projectStatus === 'DRAFT' &&
                    props.context.canUserAssignUserFromAdmin
                );
            }
        }
        return false;
    }, [
        props.context.canUserAssignPMFromAdmin,
        props.context.canUserAssignUserFromAdmin,
        props.context.canUserEditOrDeleteProject,
        props.context.currentTab,
        props.context.currentUserId,
        props.data,
        templateDetails,
    ]);

    const [openDeletePopup, setOpenDeletePopup] = useState(false);

    const isCurrentUserPM = useMemo(() => {
        return props.data.projectManagers.some(
            (manager) => manager.user_id === props.context.currentUserId
        ) || templateDetails.assigned_users.TEMPLATE_LEVEL?.length > 0
            ? templateDetails.assigned_users.TEMPLATE_LEVEL.some(
                  (user) =>
                      user.user_id === props.context.currentUserId &&
                      user.permission ===
                          ProjectPermissions.PROJECT_ASSIGN_MANAGERS
              )
            : props.context.canUserAssignPMFromAdmin;
    }, [
        props.context.canUserAssignPMFromAdmin,
        props.context.currentUserId,
        props.data.projectManagers,
        templateDetails.assigned_users.TEMPLATE_LEVEL,
    ]);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const disableOptionAccess = useMemo(() => {
        return (
            !showAssignUsersOption &&
            !allowUserToDeleteProject &&
            !showDisableProjectPermission
        );
    }, [
        allowUserToDeleteProject,
        showAssignUsersOption,
        showDisableProjectPermission,
    ]);

    return (
        <>
            <Box
                display={'flex'}
                width={'100%'}
                justifyContent={'center'}
                alignItems={'center'}
            >
                <IconButton
                    disabled={disableOptionAccess}
                    id="table-event-menu-button"
                    color="primary"
                    ref={(ref: any) => {
                        if (!ref) return;
                        ref.onclick = (e: any) => {
                            e.stopImmediatePropagation();
                            handleClick(e);
                        };
                    }}
                >
                    <i className="bi bi-three-dots"></i>
                </IconButton>
            </Box>
            <FWMenu
                id="event-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'table-event-menu-button',
                }}
            >
                {showAssignUsersOption && (
                    <FWMenuItem
                        onClick={() => {
                            props.context.handleAssignableProjectIdChange(
                                props.data.projectUUid,
                                props.data.projectName,
                                props.data.createdOn,
                                props.data.entityUid,
                                isCurrentUserPM
                            );
                        }}
                        sx={{
                            color: 'primary.main',
                        }}
                    >
                        Assign users
                    </FWMenuItem>
                )}
                {allowUserToDeleteProject && (
                    <FWMenuItem
                        onClick={() => {
                            setOpenDeletePopup(true);
                        }}
                        sx={{
                            color: 'error.main',
                        }}
                    >
                        Delete project
                    </FWMenuItem>
                )}

                {showDisableProjectPermission &&
                    (props.data.status === 'ACTIVE' ? (
                        <FWMenuItem
                            onClick={() => {
                                updateStatusOfProject(
                                    props.data.projectUUid,
                                    'INACTIVE'
                                ).then(() => {
                                    props.context.updateStatusOfProject();
                                });
                            }}
                            sx={{
                                color: 'error.main',
                            }}
                        >
                            Deactivate project
                        </FWMenuItem>
                    ) : (
                        <FWMenuItem
                            onClick={() => {
                                updateStatusOfProject(
                                    props.data.projectUUid,
                                    'ACTIVE'
                                ).then(() => {
                                    props.context.updateStatusOfProject();
                                });
                            }}
                            sx={{
                                color: 'primary.main',
                            }}
                        >
                            Activate project
                        </FWMenuItem>
                    ))}
            </FWMenu>

            <FWPopup
                title="Delete Project"
                btnColor="error"
                setOpen={() => setOpenDeletePopup(false)}
                open={openDeletePopup}
                msg="This project will be deleted and will not be accessible later."
                onClickConfirm={() => {
                    deleteProject(props.data.projectUUid)
                        .then(() => {
                            try {
                                toast.success('Project deleted successfully');
                                props.context.updateStatusOfProject();
                                // props.context.fetchProjectTabCount();
                            } catch (err) {}
                        })
                        .catch((err) => {
                            toast.error('Failed to delete project');
                        })
                        .finally(() => {
                            try {
                                fetchProjectTabCount({});
                            } catch (err) {}
                        });
                    setOpenDeletePopup(false);
                }}
            />
        </>
    );
};
