import { ColDef } from 'ag-grid-community';
import { FWTypography } from '../../Common/FWTypograhy';

export const BUYER_EVENT_STATUSES_LIST = {
    DECISION_PENDING: 'Decision Pending',
    DEAL_WON: 'Deal Won',
    DEAL_LOST: 'Deal Lost',
    REJECTED: 'Rejected',
};

export const transactionColDef: ColDef[] = [
    {
        headerName: 'Event ID',
        field: 'event__custom_event_id',
        sortable: true,
        filter: true,
        resizable: true,
        width: 180,
        cellRenderer: (params: any) => (
            <FWTypography>{params.value}</FWTypography>
        ),
    },
    {
        headerName: 'Buyer entity',
        field: 'buyer_entity__entity_name',
        sortable: true,
        filter: true,
        resizable: true,
        width: 200,
        flex: 1,
        cellRenderer: (params: any) => (
            <FWTypography>{params.value}</FWTypography>
        ),
    },
    // {
    //     headerName: 'Amount',
    //     field: 'amount',
    //     sortable: true,
    //     filter: true,
    //     resizable: true,
    //     width: 180,
    //     cellRenderer: (params: any) => <FWTypography>{100000}</FWTypography>,
    // },
    // {
    //     headerName: 'Bids',
    //     field: 'bids',
    //     sortable: true,
    //     filter: true,
    //     resizable: true,
    //     width: 150,
    //     cellRenderer: (params: any) => <FWTypography>{10}</FWTypography>,
    // },
    {
        headerName: 'PO(s)',
        field: 'po_count',
        sortable: true,
        filter: true,
        resizable: true,
        width: 160,
        cellRenderer: (params: any) => (
            <FWTypography>{params.value}</FWTypography>
        ),
        comparator: (valueA, valueB) => {
            return valueA - valueB;
        },
    },
    {
        headerName: 'Invoice(s)',
        field: 'invoice_count',
        sortable: true,
        filter: true,
        resizable: true,
        width: 160,
        cellRenderer: (params: any) => (
            <FWTypography>{params.value}</FWTypography>
        ),
        comparator: (valueA, valueB) => {
            return valueA - valueB;
        },
    },
    {
        headerName: 'Item(s)',
        field: 'rfq_item_count',
        sortable: true,
        filter: true,
        resizable: true,
        width: 160,
        cellRenderer: (params: any) => (
            <FWTypography>{params.value}</FWTypography>
        ),
        comparator: (valueA, valueB) => {
            return valueA - valueB;
        },
    },
    {
        headerName: 'Status',
        field: 'seller_status',
        sortable: true,
        filter: true,
        resizable: true,
        width: 160,
        cellRenderer: (params: any) => (
            <FWTypography>
                {(BUYER_EVENT_STATUSES_LIST as any)?.[params.value] ||
                    'Decision Pending'}
            </FWTypography>
        ),
    },
];
