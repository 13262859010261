/*****
 PO List hook
 *****/

import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    HookStateValue,
    IHookState,
    useHookState,
} from '../../Common/Hooks/StateHook';
import {
    IGRInvoiceListSummary,
    IGRPastListSummary,
} from '../Interfaces/GRInterface';
import { fetchGRInvoiceList } from '../Services/GRService';
import {
    GRDashboardType,
    initDashboardList,
    setTabCount,
} from '../Slices/GRDashboardSlice';

export interface IGRPastListProviders {
    hookState: IHookState;
    pastList: IGRPastListSummary[];
}

export interface IGRPOListProviders {
    hookState: IHookState;
    pendingList: IGRInvoiceListSummary[];
}

export const useGRPOList = () => {
    const dispatch = useDispatch();
    const { hookState, updateHookState } = useHookState(HookStateValue.LOADING);

    const { pendingList } = useSelector((state: any) => state.GRDashboardStore);

    const getGRPurchaseOrders = useCallback(async () => {
        updateHookState(HookStateValue.LOADING);
        try {
            const poListItems = await fetchGRInvoiceList();
            dispatch(
                initDashboardList({
                    value: poListItems,
                    type: GRDashboardType.PENDING,
                })
            );
            dispatch(
                setTabCount({
                    type: GRDashboardType.PENDING,
                    value: poListItems.length,
                })
            );
            updateHookState(HookStateValue.READY);
        } catch (error) {
            updateHookState(HookStateValue.ERROR);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateHookState]);

    useEffect(() => {
        getGRPurchaseOrders();
    }, [getGRPurchaseOrders]);

    const grPOListProviders: IGRPOListProviders = {
        hookState,
        pendingList,
    };

    return grPOListProviders;
};

// export const useGRPastList = () => {
//     const dispatch = useDispatch();
//     const { hookState, updateHookState } = useHookState(HookStateValue.LOADING);

//     const { pastList } = useSelector((state: any) => state.GRDashboardStore);

//     const getGRPurchaseOrders = useCallback(async () => {
//         updateHookState(HookStateValue.LOADING);
//         try {
//             const pastListItems = await fetchPastGR();
//             dispatch(
//                 initDashboardList({
//                     value: pastListItems.goodsReceiptsList,
//                     type: GRDashboardType.PAST,
//                 })
//             );
//             dispatch(
//                 setTabCount({
//                     type: GRDashboardType.PAST,
//                     value: pastListItems.goodsReceiptsList.length,
//                 })
//             );
//             updateHookState(HookStateValue.READY);
//         } catch (error) {
//
//             updateHookState(HookStateValue.ERROR);
//         }
//         // eslint-disable-next-line react-hooks/exhaustive-deps
//     }, [updateHookState]);

//     useEffect(() => {
//         getGRPurchaseOrders();
//     }, [getGRPurchaseOrders]);

//     const grPastListProviders: IGRPastListProviders = {
//         hookState,
//         pastList,
//     };

//     return grPastListProviders;
// };
