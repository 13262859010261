import { Box, Chip, CircularProgress } from '@mui/material';
import moment from 'moment';
import { Fragment, useContext, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import FWScrollToBottom from '../../Common/FWScrollToBottom';
import { getFWDate } from '../../Common/Utils/DateUtils';
import { customScrollbar } from '../../Common/Utils/UIUtils';
import { AuthContext } from '../../Contexts/AuthContext';
import { IGlobalAppStore } from '../../Redux/Store';
import BroadcastMessageRows from '../Components/BroadcastMessageRows';
import {
    ChatTypes,
    ISessionMessage,
    IUserSession,
} from '../Interfaces/Chat.model';
import {
    acknowledgeSessionMessages,
    fetchModuleUserSessions,
    // fetchBroadcastMessages,
    fetchSessionMessages,
} from '../Services/chat.service';
import { updateReceivedMessages } from '../Slice/Chat.slice';

interface IBroadcastMessageListContainer {
    module_id: string;
    session_id: string;
}

const getTimeDifferences = (today: string, yesterday: string | null) => {
    if (yesterday === null) {
        return {
            isADayApart: false,
            isWithin15Minutes: false,
        };
    } else {
        let moment_yesterday = moment(yesterday);
        let moment_today = moment(today);
        return {
            isADayApart: moment_today.date() > moment_yesterday.date(),
            isWithin15Minutes:
                moment_today.diff(moment_yesterday, 'minutes') <= 15,
        };
    }
};

export default function BroadcastMessageListContainer(
    props: IBroadcastMessageListContainer
) {
    const [messageList, setMessageList] = useState<ISessionMessage[] | null>(
        null
    );
    const [sessionList, setSessionList] = useState<IUserSession[]>([]);
    // get list of messagesReceived from chat store
    const messagesReceived = useSelector(
        (state: IGlobalAppStore) => state.ChatStore.messagesReceived
    );
    const { authData } = useContext(AuthContext);
    const user_id = authData.details === null ? '' : authData.details.user_id;
    const dispatch = useDispatch();

    useEffect(() => {
        setMessageList([]);
        props.session_id &&
            props.module_id &&
            Promise.all([
                fetchSessionMessages(props.session_id),
                fetchModuleUserSessions(props.module_id),
            ])
                .then(([messages, sessionList]) => {
                    // scroll to bottom of chats
                    setMessageList(messages);
                    setSessionList(sessionList);

                    if (
                        props.session_id !== '' &&
                        messages[messages.length - 1]?.message_id
                    ) {
                        acknowledgeSessionMessages({
                            session_id: props.session_id,
                            message_id:
                                messages[messages.length - 1]?.message_id,
                        });
                    }
                })
                .catch((err) => {
                    toast.error("Couldn't fetch messages");
                });
    }, [props.module_id, props.session_id]);

    // we listen to newly arriving messages
    // and if there are new msgs we check whether they belong to currently opened session
    // if yes we update the msg list and also update the sent messages in slice
    useEffect(() => {
        let read_messages: string[] = []; // list of message ids to be marked as read
        let currentSessionMsgToRead = messagesReceived.filter((msg) => {
            if (
                msg.module_id === props.module_id &&
                msg.message_type === ChatTypes.BROADCAST &&
                msg.sender_id === user_id &&
                msg.session_id === props.session_id
            ) {
                read_messages.push(msg.message_id);
                return true;
            }
            return false;
        });
        if (read_messages.length > 0) {
            dispatch(updateReceivedMessages(read_messages));
        }
        setMessageList((prev) =>
            prev === null
                ? prev
                : prev.concat(
                      currentSessionMsgToRead
                      //   .map((msg) => ({
                      //       message_id: msg.message_id,
                      //       message: msg.message,
                      //       message_type: msg.message_type,
                      //       sequence_number: 0,
                      //       module_id: msg.module_id,
                      //       session: {
                      //           session_id: msg.session_id,
                      //           session_name: '',
                      //           seller_entity_id: '',
                      //           seller_entity_name: '',
                      //           buyer_entity_id: '',
                      //           buyer_entity_name: '',
                      //       },
                      //       sender_id: msg.sender_id,
                      //       sender_name: msg.sender_name,
                      //       sender_enterprise_id: msg.sender_enterprise_id,
                      //       created_datetime: msg.created_datetime,
                      //   }))
                  )
        );
    }, [
        messagesReceived,
        dispatch,
        props.module_id,
        user_id,
        props.session_id,
    ]);

    if (messageList === null) {
        return (
            <Box flex={1} overflow="auto" className="flex flex--aic flex--jcc ">
                <CircularProgress color="primary" size={60} />
            </Box>
        );
    }
    return (
        <FWScrollToBottom variablesToCheck={[messageList]}>
            <Box
                flex={1}
                // maxHeight={'calc(100vh - 310px)'}
                overflow="auto"
                className="chat-list"
                padding={'1rem 0'}
                sx={customScrollbar}
            >
                {messageList !== null &&
                    messageList.map((message, idx) => {
                        let timeDiff = getTimeDifferences(
                            messageList[idx].created_datetime,
                            idx > 0
                                ? messageList[idx - 1].created_datetime
                                : null
                        );
                        return (
                            <Fragment key={idx}>
                                {(idx === 0 || timeDiff.isADayApart) && (
                                    <Box
                                        className="flex flex--jcc"
                                        margin="0.5rem 0"
                                    >
                                        <Chip
                                            sx={{ color: 'grey' }}
                                            label={getFWDate(
                                                messageList[idx]
                                                    .created_datetime
                                            )}
                                        />
                                    </Box>
                                )}
                                <BroadcastMessageRows
                                    sessionList={sessionList}
                                    boxPosition={'RIGHT'}
                                    showSenderName={false}
                                    reduceMargins={
                                        !timeDiff.isADayApart &&
                                        timeDiff.isWithin15Minutes
                                    }
                                    key={idx}
                                    message_details={message}
                                    containerSelector={'chat-list'}
                                    index={idx}
                                />
                            </Fragment>
                        );
                    })}
            </Box>
        </FWScrollToBottom>
    );
}
