import { cloneDeep } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import {
    HookStateValue,
    IHookState,
    useHookState,
} from '../../../../Common/Hooks/StateHook';
import {
    IPreferredVendor,
    PreferredVendorStatus,
} from '../../Interfaces/ItemDirectoryInterface';
import {
    IVendorListSummary,
    PreferredItemStatus,
} from '../../Interfaces/VendorsInterface';
import { fetchPreferredVendors } from '../../Services/ItemDirectoryService';
import {
    IPayloadItem,
    IVendorItemUpdatePayload,
    updateVendorItems,
} from '../../Services/VendorsService';

export interface IPreferredVendorsProviders {
    hookState: IHookState;
    preferredVendors: IPreferredVendor[];
    addPreferredVendor: (vendor: IVendorListSummary) => void;
    removePreferredVendor: (index: number) => void;
}

export const usePreferredVendors = (
    entityUid: string,
    itemUid: string,
    update?: boolean
) => {
    const { hookState, updateHookState } = useHookState(HookStateValue.INITIAL);

    const [preferredVendors, setPreferredVendors] = useState<
        IPreferredVendor[]
    >([]);

    const getPreferredItems = useCallback(async () => {
        updateHookState(HookStateValue.LOADING);
        try {
            const preferredVendors = await fetchPreferredVendors(
                entityUid,
                itemUid
            );
            setPreferredVendors(preferredVendors);
            updateHookState(HookStateValue.READY);
        } catch (error) {
            updateHookState(HookStateValue.ERROR);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [entityUid, itemUid]);

    const addPreferredVendor = async (
        vendor: IVendorListSummary
    ): Promise<boolean> => {
        updateHookState(HookStateValue.LOADING);
        try {
            const item: IPayloadItem = {
                enterprise_item: itemUid,
                status: PreferredItemStatus.PREFERRED,
            };
            const payload: IVendorItemUpdatePayload = {
                buyer_entity: entityUid,
                status: 'STANDARD',
                items: [item],
            };

            const vendorUpdated = await updateVendorItems(
                vendor.vendorDirUid,
                payload
            );

            if (vendorUpdated) {
                const updatedItem: IPreferredVendor = {
                    vendorName: vendor.vendorName,
                    vendorCode: vendor.vendorCode,
                    vendorUid: vendor.vendorUid,
                    vendorDirUid: vendor.vendorDirUid,
                    primaryContact: {
                        fullName: vendor.primaryContact.fullName,
                        email: vendor.primaryContact.email,
                    },
                    preferredVendorStatus: PreferredVendorStatus.PREFERRED,
                };
                setPreferredVendors((prevVendors) => {
                    const vendors = cloneDeep(prevVendors);
                    vendors.push(updatedItem);
                    return [...vendors];
                });
            }
            updateHookState(HookStateValue.READY);
            return vendorUpdated;
        } catch (error) {
            updateHookState(HookStateValue.ERROR);
            return false;
        }
    };
    const removePreferredVendor = async (index: number): Promise<boolean> => {
        updateHookState(HookStateValue.LOADING);
        try {
            const vendor = preferredVendors[index];

            const item: IPayloadItem = {
                enterprise_item: itemUid,
                status: PreferredItemStatus.STANDARD,
            };
            const payload: IVendorItemUpdatePayload = {
                buyer_entity: entityUid,
                status: 'STANDARD',
                items: [item],
            };

            const vendorUpdated = await updateVendorItems(
                vendor.vendorDirUid,
                payload
            );

            if (vendorUpdated) {
                setPreferredVendors((prevVendors) => {
                    const vendors = cloneDeep(prevVendors);
                    vendors.splice(index, 1);
                    return [...vendors];
                });
            }
            updateHookState(HookStateValue.READY);
            return vendorUpdated;
        } catch (error) {
            updateHookState(HookStateValue.ERROR);
            return false;
        }
    };

    useEffect(() => {
        getPreferredItems();
    }, [getPreferredItems]);

    const preferredItemsProvider: IPreferredVendorsProviders = {
        hookState,
        preferredVendors,
        addPreferredVendor,
        removePreferredVendor,
    };

    return preferredItemsProvider;
};
