import {
    Avatar,
    Box,
    styled,
    Typography,
    TypographyProps,
} from '@mui/material';
import { useContext } from 'react';
import { FWTypography } from '../../Common/FWTypograhy';
import { getFWDate } from '../../Common/Utils/DateUtils';
import { AuthContext } from '../../Contexts/AuthContext';
import { useLazyLoadList } from '../Hooks/LazyLoadListHook';
import { IUserSession } from '../Interfaces/Chat.model';

const StyledBox = styled(Box)(({ theme }) => ({
    cursor: 'pointer',
    // padding: '0 8px',
    padding: 8,
    '&:hover': {
        backgroundColor: '#e5f1ff',
    },
    // borderBottom: '1px solid',
    // borderColor: theme.palette.dividerColor.main,
    margin: '8px 15px',
    borderRadius: 15,
    transition: 'all 0.2s ease-in-out',
}));

const CustomTypography = styled(Typography)<TypographyProps>(({ theme }) => ({
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
}));

interface IBroadcastSessionRows {
    highlightBox: boolean;
    updateBroadcastSessionSelection: () => void;
    containerSelector: string;
    user_type: 'buyer' | 'seller';
    unreadMsgCount: number;
    session_details: IUserSession | undefined;
}

export default function BroadcastSessionRows(props: IBroadcastSessionRows) {
    const [loaded, elRef] = useLazyLoadList(props.containerSelector);
    const { authData } = useContext(AuthContext);

    const latestMsg = props.session_details &&
        props.session_details.last_message && {
            senderName:
                props.session_details.last_message &&
                authData.details?.user_id ===
                    props.session_details.last_message.sender_id
                    ? 'You'
                    : props.session_details.last_message.sender_name,
            latestMsg:
                props.session_details.last_message &&
                props.session_details.last_message.attachments.length > 0
                    ? 'Attachment'
                    : props.session_details.last_message.message,
            latestMsgTime:
                props.session_details.last_message &&
                getFWDate(props.session_details.last_message.posted_time),
        };

    return (
        <StyledBox
            onClick={() => props.updateBroadcastSessionSelection()}
            bgcolor={props.highlightBox ? 'background.paper' : 'transparent'}
            ref={elRef}
        >
            {loaded ? (
                <Box className="flex flex--aic">
                    <Avatar sx={{ marginRight: 1, bgcolor: '#FF9500' }}>
                        <Box color="white" style={{ fontSize: '18px' }}>
                            <i className="bi bi-megaphone"></i>
                        </Box>
                    </Avatar>
                    <Box
                        width="100%"
                        height={'60px'}
                        minWidth={'100px'}
                        // className="flex flex--col flex--jcsb flex--aic"
                    >
                        <Box
                            className="flex flex--jcsb flex--aic"
                            width="100%"
                            padding="3px"
                        >
                            <Box className="flex flex--aic">
                                <FWTypography
                                    // maxWidth={'200px'}
                                    fontWeight={600}
                                    color={'#4e4e4e'}
                                >
                                    Broadcast
                                </FWTypography>
                            </Box>
                            {/* <Box>
                                <i className="bi bi-chevron-right"></i>
                            </Box> */}
                        </Box>
                        {!latestMsg ? (
                            <FWTypography
                                fontSize={'0.75rem'}
                                color="text.secondary"
                                padding="5px"
                            >
                                No messages yet
                            </FWTypography>
                        ) : (
                            <Box
                                className="flex flex--jcsb flex--aic"
                                padding="5px"
                                color="text.secondary"
                                fontSize={'0.8rem'}
                            >
                                <CustomTypography
                                    color="inherit"
                                    fontSize={'inherit'}
                                    flex={1}
                                >
                                    {`${latestMsg?.senderName} : ${latestMsg?.latestMsg}`}
                                </CustomTypography>
                                <CustomTypography
                                    color="inherit"
                                    fontSize={'inherit'}
                                    // maxWidth={'80px'}
                                    marginLeft={1}
                                >
                                    {getFWDate(latestMsg.latestMsgTime)}
                                </CustomTypography>
                            </Box>
                        )}
                    </Box>
                </Box>
            ) : (
                <Box className="flex flex--jcsb" padding="3px">
                    <Box>
                        <Box
                            bgcolor={'background.paper'}
                            width="250px"
                            height="50px"
                        />
                    </Box>
                    <Box className="flex flex--aic">
                        <i className="bi bi-chevron-right"></i>
                    </Box>
                </Box>
            )}
        </StyledBox>
    );
}
