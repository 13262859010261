import { Box } from '@mui/material';
import React from 'react';
import FWPopover from '../../Common/FWPopover';
import { FWTypography } from '../../Common/FWTypograhy';

export default function ItemsLinkAndPopover({
    item_details,
    item_count,
}: {
    item_details: {
        item_name: string;
        item_code: string;
    }[];
    item_count: number;
}) {
    interface itemPopoverDataType {
        ancholEl: null | HTMLElement;
        item_details: {
            item_name: string;
            item_code: string;
        }[];
    }
    const [itemPopoverData, setItemPopoverData] =
        React.useState<itemPopoverDataType>({
            ancholEl: null,
            item_details: [],
        });
    const handleItemsPopoverOpen = (
        e: React.MouseEvent<HTMLElement>,
        item_details: {
            item_name: string;
            item_code: string;
        }[]
    ) => {
        // //
        setItemPopoverData({
            ancholEl: e.currentTarget,
            item_details: item_details,
        });
    };

    const handleItemsPopoverClose = () => {
        // //
        setItemPopoverData({
            ancholEl: null,
            item_details: [],
        });
    };

    return (
        <>
            {item_details &&
            item_details.length === item_count &&
            item_count > 0 ? (
                <Box
                    style={{
                        margin: '0',
                        textAlign: 'left',
                    }}
                    color="primary.main"
                    display={'flex'}
                    justifyContent={'flex-start'}
                >
                    <span
                        onMouseEnter={(e: any) => {
                            handleItemsPopoverOpen(e, item_details);
                        }}
                        onMouseLeave={(e: any) => {
                            handleItemsPopoverClose();
                        }}
                    >
                        {item_details.length}
                    </span>

                    {itemPopoverData.ancholEl && (
                        <FWPopover
                            open={itemPopoverData.ancholEl ? true : false}
                            anchorEl={itemPopoverData.ancholEl}
                            onClose={handleItemsPopoverClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            sx={{
                                pointerEvents: 'none',
                            }}
                        >
                            {itemPopoverData.item_details.length === 0 ? (
                                <>
                                    <FWTypography color="text.secondary">
                                        No items added
                                    </FWTypography>
                                </>
                            ) : (
                                <>
                                    {itemPopoverData.item_details.map(
                                        (item, index) => (
                                            <FWTypography key={index}>
                                                {index + 1}. {item.item_name}
                                            </FWTypography>
                                        )
                                    )}
                                </>
                            )}
                        </FWPopover>
                    )}
                </Box>
            ) : (
                <FWTypography
                    color="text.primary"
                    style={{
                        textAlign: 'left',
                        width: '100%',
                    }}
                >
                    {item_count}
                </FWTypography>
            )}
        </>
    );
}
