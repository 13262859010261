import { IAdditionalCostsBackend } from '../../AdditionalCost/models/AdditionalCost.model';
import {
    ITemplateAdditionalCostOptions,
    ITemplateDiscountOptions,
    ITemplatePaymentTermsOptions,
    ITemplateTaxesOptions,
} from '../../Global/Interfaces/TemplateInterface';
import { ITemplateCustomFieldOptions } from './CLMTemplateInterface';

export enum CLMNewTemplateStatusEnum {
    DRAFT = 'DRAFT',
}

export interface CLMNewTemplateDetails {
    template_id: string;
    type: 'CLM';
    name: string;
    tags: string[];
    status: CLMNewTemplateStatusEnum;
    published_on: null;
    description: '';
    is_global: false;
    is_default: false;
    created_on: string;
    creator_name: string;
    last_edited_on: string;
    entity_id: string;
    entity_name: string;
    auto_reminders: {};
    last_edited_by_name: string;
    assigned_users: [];
    section_list: {
        section_id: string;
        name: string;
        assigned_users: [];
    }[];
}

export interface IRequisitionNewTemplateResponse {
    type: 'CLM';
    count: number;
    templates: CLMNewTemplateDetails[];
}

export interface ICLMNewTemplateDetailsResponse {
    template_id: string;
    section_list: SectionList[];
    entity: Entity;
    last_edited_by_name: string;
    assigned_users: any[];
    created_datetime: string;
    modified_datetime: string;
    deleted_datetime: string | null;
    name: string;
    type: string;
    description: string;
    tags: string[];
    is_default: boolean;
    is_global: boolean;
    auto_reminders: {
        initial_reminder_hours: number;
        is_remainder_required: boolean;
        max_reminders: number;
        reminder_interval: number;
    };
    status: string;
    published_on: string | null;
    created_on: string;
    last_edited_on: string;
    created_by_user: string;
    modified_by_user: string;
    deleted_by_user: string | null;
    enterprise: string;
    creator: string;
    last_edited_by: string;
}
export interface SectionList {
    section_id: string;
    field_level: 'ITEM' | 'OTHER' | 'DELIVERY_SCHEDULE';
    section_items: SectionItem[];
    assigned_users: any[];
    created_datetime: string;
    modified_datetime: string;
    deleted_datetime: any;
    name: string;
    alternate_name: string;
    section_type: string;
    is_builtin: boolean;
    description: string;
    sequence: number;
    is_visible: boolean;
    is_optional: boolean;
    created_by_user: string;
    modified_by_user: string;
    deleted_by_user: any;
    template: string;
}
export interface SectionItem {
    section_item_id: string;
    options?: Option[];
    created_datetime: string;
    modified_datetime: string;
    deleted_datetime: any;
    sequence: number;
    name: string;
    alternate_name: string;
    description: string;
    constraints: {
        max_limit?: number;
        min_limit?: number;
        field_type: string;
        decimal_places?: number;
        choices?: string[];
        choice_type?: 'DROPDOWN' | 'MULTI_SELECT' | undefined;
    };
    field_level: 'ITEM' | 'OTHER' | 'DELIVERY_SCHEDULE';
    can_remove: boolean;
    is_builtin_field: boolean;
    is_required: boolean;
    is_variable: boolean;
    is_mandatory: boolean;
    additional_information: {
        is_locked: boolean;
        is_visible: boolean;
        is_negotiable: boolean;
        is_hidden: boolean;
        additional_cost_information?: Omit<
            IAdditionalCostsBackend,
            'cost_value' | 'cost_name'
        >;
        shipping_rate_information?: Omit<
            IAdditionalCostsBackend,
            'cost_value' | 'cost_name'
        >;
        taxes_information?: Omit<
            IAdditionalCostsBackend,
            'cost_value' | 'cost_name'
        >;
        discount_information?: Omit<
            IAdditionalCostsBackend,
            'cost_value' | 'cost_name'
        >;
    };
    created_by_user: string;
    modified_by_user: string;
    deleted_by_user: any;
    section: string;
    parent_section_item: string | null;
}
export interface Option {
    section_item_option_id: string;
    created_datetime: string;
    modified_datetime: string;
    deleted_datetime: any;
    name: string;
    alternate_name: string;
    description: string;
    is_builtin: boolean;
    conditions: Condition[];
    created_by_user: string;
    modified_by_user: string;
    deleted_by_user: any;
    send_to_po?: boolean;
    can_split?: boolean;
    section_item: string;
}
export interface Condition {
    name: string;
    value: string;
}
export interface Constraints {
    max_limit?: number;
    min_limit?: number;
    field_type: string;
    decimal_places?: number;
    choices?: string[];
    selected?: Selected;
    choice_type?: 'DROPDOWN' | 'MULTI_SELECT';
}
export interface Selected {
    choice: string;
}

export interface AdditionalInformation {
    is_hidden: boolean;
    is_visible: boolean;
    is_locked: boolean;
    is_negotiable?: boolean;
    additional_cost_information?: AdditionalCostInformation;
    discount_information?: DiscountInformation;
}

export interface AdditionalCostInformation {
    cost_type: string;
    allocation_type: string;
    additional_cost_id: string;
}

export interface DiscountInformation {
    cost_type: string;
    allocation_type: string;
}

export interface Entity {
    entity_name: string;
    entity_id: string;
}

export enum CLMNewTemplateStandardFieldsEnum {
    CONTRACT_ID = 'CONTRACT_ID',
    CONTRACT_NAME = 'CONTRACT_NAME',
    CONTRACT_START_DATE = 'CONTRACT_START_DATE',
    CONTRACT_END_DATE = 'CONTRACT_END_DATE',
    BUYER_ENTITY = 'BUYER_ENTITY',
    BUYER_IDENTIFICATION = 'BUYER_IDENTIFICATION',
    BUYER_CONTACT = 'BUYER_CONTACT',
    BUYER_ADDRESS = 'BUYER_ADDRESS',
    PROJECT = 'PROJECT',
    COST_CENTER = 'COST_CENTER',
    GL = 'GL',
    VENDOR_ENTITY = 'VENDOR_ENTITY',
    VENDOR_IDENTIFICATION = 'VENDOR_IDENTIFICATION',
    VENDOR_CONTACT = 'VENDOR_CONTACT',
    VENDOR_ADDRESS = 'VENDOR_ADDRESS',
    PRICE = 'PRICE',
    TAX = 'TAX',
    ADDITIONAL_COSTS = 'ADDITIONAL_COSTS',
    DISCOUNT = 'DISCOUNT',
    QUANTITY = 'QUANTITY',
    PAYMENT_TERMS = 'PAYMENT_TERMS',
    LEAD_TIME = 'LEAD_TIME',
    INCOTERMS = 'INCOTERMS',
    GENERAL_TERMS = 'GENERAL_TERMS',
}

export const CLMNewTemplateStandardFieldBackendNames: {
    [BackendTemplateItemName: string]: CLMNewTemplateStandardFieldsEnum;
} = {
    'Contract ID': CLMNewTemplateStandardFieldsEnum.CONTRACT_ID,
    'Contract name': CLMNewTemplateStandardFieldsEnum.CONTRACT_NAME,
    'Contract start date': CLMNewTemplateStandardFieldsEnum.CONTRACT_START_DATE,
    'Contract end date': CLMNewTemplateStandardFieldsEnum.CONTRACT_END_DATE,
    'Buyer entity': CLMNewTemplateStandardFieldsEnum.BUYER_ENTITY,
    'Buyer identification':
        CLMNewTemplateStandardFieldsEnum.BUYER_IDENTIFICATION,
    'Buyer contact': CLMNewTemplateStandardFieldsEnum.BUYER_CONTACT,
    'Buyer address': CLMNewTemplateStandardFieldsEnum.BUYER_ADDRESS,
    Project: CLMNewTemplateStandardFieldsEnum.PROJECT,
    'Cost center': CLMNewTemplateStandardFieldsEnum.COST_CENTER,

    GL: CLMNewTemplateStandardFieldsEnum.GL,

    'Vendor entity': CLMNewTemplateStandardFieldsEnum.VENDOR_ENTITY,
    'Vendor identification':
        CLMNewTemplateStandardFieldsEnum.VENDOR_IDENTIFICATION,
    'Vendor contact': CLMNewTemplateStandardFieldsEnum.VENDOR_CONTACT,
    'Vendor address': CLMNewTemplateStandardFieldsEnum.VENDOR_ADDRESS,
    Price: CLMNewTemplateStandardFieldsEnum.PRICE,
    Quantity: CLMNewTemplateStandardFieldsEnum.QUANTITY,
    Taxes: CLMNewTemplateStandardFieldsEnum.TAX,
    'Additional costs': CLMNewTemplateStandardFieldsEnum.ADDITIONAL_COSTS,
    Discount: CLMNewTemplateStandardFieldsEnum.DISCOUNT,
    'Payment terms': CLMNewTemplateStandardFieldsEnum.PAYMENT_TERMS,
    'Lead time': CLMNewTemplateStandardFieldsEnum.LEAD_TIME,
    Incoterms: CLMNewTemplateStandardFieldsEnum.INCOTERMS,
    'General terms': CLMNewTemplateStandardFieldsEnum.GENERAL_TERMS,
};
export enum CLMNewStandardSections {
    CONTRACT_DETAILS = 'CONTRACT_DETAILS',
    ESSENTIAL_TERMS = 'ESSENTIAL_TERMS',
    VENDOR_DETAILS = 'VENDOR_DETAILS',
    PAYMENT_AND_DELIVERY_TERMS = 'PAYMENT_AND_DELIVERY_TERMS',
    TERMS_AND_CONDITIONS = 'TERMS_AND_CONDITIONS',
}
export type CLMNewTemplateStandardFieldSettingsObj = {
    [CLMNewTemplateStandardFieldsEnum.CONTRACT_ID]: ICLMNewTemplateStandardFieldOptions;
    [CLMNewTemplateStandardFieldsEnum.CONTRACT_NAME]: ICLMNewTemplateStandardFieldOptions;
    [CLMNewTemplateStandardFieldsEnum.CONTRACT_START_DATE]: ICLMNewTemplateStandardFieldOptions;
    [CLMNewTemplateStandardFieldsEnum.CONTRACT_END_DATE]: ICLMNewTemplateStandardFieldOptions;
    [CLMNewTemplateStandardFieldsEnum.BUYER_ENTITY]: ICLMNewTemplateStandardFieldOptions;
    [CLMNewTemplateStandardFieldsEnum.BUYER_IDENTIFICATION]: ICLMNewTemplateStandardFieldOptions;
    [CLMNewTemplateStandardFieldsEnum.BUYER_CONTACT]: ICLMNewTemplateStandardFieldOptions;
    [CLMNewTemplateStandardFieldsEnum.BUYER_ADDRESS]: ICLMNewTemplateStandardFieldOptions;
    [CLMNewTemplateStandardFieldsEnum.PROJECT]: ICLMNewTemplateStandardFieldOptions;
    [CLMNewTemplateStandardFieldsEnum.COST_CENTER]: ICLMNewTemplateStandardFieldOptions;
    [CLMNewTemplateStandardFieldsEnum.GL]: ICLMNewTemplateStandardFieldOptions;
    [CLMNewTemplateStandardFieldsEnum.VENDOR_ENTITY]: ICLMNewTemplateStandardFieldOptions;
    [CLMNewTemplateStandardFieldsEnum.VENDOR_IDENTIFICATION]: ICLMNewTemplateStandardFieldOptions;
    [CLMNewTemplateStandardFieldsEnum.VENDOR_CONTACT]: ICLMNewTemplateStandardFieldOptions;
    [CLMNewTemplateStandardFieldsEnum.VENDOR_ADDRESS]: ICLMNewTemplateStandardFieldOptions;
    [CLMNewTemplateStandardFieldsEnum.PRICE]: ICLMNewTemplateStandardFieldOptions;
    [CLMNewTemplateStandardFieldsEnum.TAX]: ITemplateTaxesOptions;
    [CLMNewTemplateStandardFieldsEnum.ADDITIONAL_COSTS]: ITemplateAdditionalCostOptions;
    [CLMNewTemplateStandardFieldsEnum.DISCOUNT]: ITemplateDiscountOptions;
    [CLMNewTemplateStandardFieldsEnum.QUANTITY]: ICLMNewTemplateStandardFieldOptions;
    [CLMNewTemplateStandardFieldsEnum.PAYMENT_TERMS]: ITemplatePaymentTermsOptions;
    [CLMNewTemplateStandardFieldsEnum.LEAD_TIME]: ICLMNewTemplateStandardFieldOptions;
    [CLMNewTemplateStandardFieldsEnum.INCOTERMS]: ICLMNewTemplateStandardFieldOptions;
    [CLMNewTemplateStandardFieldsEnum.GENERAL_TERMS]: ICLMNewTemplateStandardFieldOptions;
};

export type ICLMNewTemplateStandardFieldOptionsBase = {
    label: string;
    description: string;
    buyerSettings: {
        showField: boolean;
        required: boolean;
    };
    sellerSettings: {
        showField: boolean;
        negotiable: boolean;
        isMandatory: boolean;
    };
    fieldLevel: 'OTHER' | 'DELIVER_SCHEDULE' | 'ITEM';
};

export type ICLMNewTemplateStandardFieldOptions =
    ICLMNewTemplateStandardFieldOptionsBase & {
        standardFieldCategory: 'STANDARD';
    };

export type ICLMNewTemplateSections = {
    [section in CLMNewStandardSections]: ICLMNewTemplateSectionDetails;
};

export interface ICLMNewTemplateSectionDetails {
    label: string;
    fieldList: ISectionField[];
}

export type ISectionField =
    | {
          fieldType: 'STANDARD';
          field: CLMNewTemplateStandardFieldsEnum;
      }
    | {
          fieldType: 'CUSTOM';
          field: string;
      };

export interface ICLMNewTemplate {
    templateId: string;
    templateName: string;
    buyerEntityId: string;
    fieldSettings: IRFQTemplateFieldSetting;
    sections: ICLMNewTemplateSections;
}

export interface IRFQTemplateFieldSetting {
    standardFields: CLMNewTemplateStandardFieldSettingsObj;
    customFields: {
        [field: string]: ITemplateCustomFieldOptions;
    };
}
