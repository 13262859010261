import React from 'react';
import { HookStateValue, useHookState } from '../../Common/Hooks/StateHook';
import { AuthContext } from '../../Contexts/AuthContext';
import {
    IPurchaseOrder,
    PurchaseOrderStatus,
    PurchaseOrderType,
} from '../Interfaces/PO.model';
import { rescindPurchaseOrder } from '../Services/PO.service';
import usePermissionCheck from '../../Global/Hooks/usePermissionCheck';

export const usePurchaseOrderIssuedBuyerActions = (
    purchaseOrderData: IPurchaseOrder
) => {
    const { hookState, updateHookState } = useHookState(HookStateValue.READY);
    const { checkPermission } = React.useContext(AuthContext);

    const [showRescind, setShowRescind] = React.useState(false);

    const rescindPo = React.useCallback(
        (msg: string): Promise<void> => {
            return new Promise<void>(async (resolve, reject) => {
                try {
                    await rescindPurchaseOrder(
                        purchaseOrderData.purchase_order_id,
                        purchaseOrderData.purchase_order_type ===
                            PurchaseOrderType.DIRECT_PO
                            ? 'DIRECT'
                            : 'FROM_EVENT',
                        msg
                    );
                    resolve();
                } catch {
                    reject();
                }
            });
        },
        [
            purchaseOrderData.purchase_order_id,
            purchaseOrderData.purchase_order_type,
        ]
    );

    const [entityPoPermissionsMap] = usePermissionCheck(
        'BUYER',
        'PURCHASE_ORDER',
        ['VIEW', 'EDIT', 'ADMIN']
    );

    React.useEffect(() => {
        if (purchaseOrderData.status === PurchaseOrderStatus.ISSUED) {
            // let hasEntityPoApprovalPermission = checkPermission(
            //     'BUYER',
            //     'PURCHASE_ORDER',
            //     'APPROVE',
            //     purchaseOrderData.buyer_entity
            // );

            //
            //

            if (
                purchaseOrderData.purchase_order_items.reduce(
                    (pv, nv) => pv + +nv.fulfilment_information.delivered,
                    0
                ) > 0
            ) {
                setShowRescind(false);
            } else if (
                entityPoPermissionsMap[purchaseOrderData.buyer_entity] &&
                entityPoPermissionsMap[purchaseOrderData.buyer_entity].EDIT
            ) {
                setShowRescind(true);
            } else {
                setShowRescind(false);
            }
        } else {
            setShowRescind(false);
        }
    }, [
        purchaseOrderData,
        updateHookState,
        checkPermission,
        entityPoPermissionsMap,
    ]);

    return {
        hookState,
        showRescind,
        rescindPo,
    };
};
