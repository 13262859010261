import { Chip, Grid } from '@mui/material';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

type SessionType = 'INTERNAL' | 'EXTERNAL' | 'ALL';

interface IFilterSessionTypeProps {
    sessionType: SessionType;
    setSessionType: React.Dispatch<React.SetStateAction<SessionType>>;
}

const FilterSessionType = ({
    sessionType,
    setSessionType,
}: IFilterSessionTypeProps) => {
    const history = useHistory();
    const location = useLocation();

    const handleChange = (sessionTypeToUpdate: SessionType) => {
        setSessionType(sessionTypeToUpdate);
        history.replace({
            pathname: location.pathname,
            search: `?sessionType=${sessionTypeToUpdate}`,
        });
    };

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const myParam = urlParams.get('sessionType');
        myParam && setSessionType(myParam as SessionType);
    }, [setSessionType]);

    return (
        <Grid
            container
            justifyContent={'space-evenly'}
            columnGap={2}
            padding={1}
        >
            <Chip
                label="All"
                color={sessionType === 'ALL' ? 'primary' : 'default'}
                onClick={() => handleChange('ALL')}
                style={{ flex: 1 }}
            />
            <Chip
                label="Internal"
                onClick={() => handleChange('INTERNAL')}
                color={sessionType === 'INTERNAL' ? 'primary' : 'default'}
                style={{ flex: 1 }}
            />
            <Chip
                label="External"
                onClick={() => handleChange('EXTERNAL')}
                color={sessionType === 'EXTERNAL' ? 'primary' : 'default'}
                style={{ flex: 1 }}
            />
        </Grid>
    );
};

export default FilterSessionType;
