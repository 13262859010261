import { createSlice } from '@reduxjs/toolkit';
import {
    IEventApprovalAndUserRolesStore,
    ILoadEventApprovalAndUserRolesSlice,
} from '../Interfaces/Buyer/eventApprovalAndUserRoles.interface';

const resetEventApprovalAndUserRoles = (
    state: IEventApprovalAndUserRolesStore
) => {
    state.subEventId = '';
    state.parentEventStatusType = 'DRAFT';
    state.eventApprovalLog = [];
    state.userEventRoleData = {};
    state.eventCreatorId = '';
    state.managersOfCurrentUser = [];
    state.nextApproverList = [];
    state.userIsCurrentPendingApproverOrCurrentPendingApproverAncestor = false;
    state.eventIsConfidential = false;
};

const loadEventApprovalAndUserRoles = (
    state: IEventApprovalAndUserRolesStore,
    action: ILoadEventApprovalAndUserRolesSlice
) => {
    return action.payload;
};

export const eventApprovalAndUserRolesSlice = createSlice<
    IEventApprovalAndUserRolesStore,
    {
        resetEventApprovalAndUserRolesSlice: (
            state: IEventApprovalAndUserRolesStore
        ) => void;
        loadEventApprovalAndUserRolesSlice: (
            state: IEventApprovalAndUserRolesStore,
            action: ILoadEventApprovalAndUserRolesSlice
        ) => void;
    },
    string
>({
    name: 'eventApprovalAndUserRoles',
    initialState: {
        subEventId: '',
        parentEventStatusType: 'DRAFT',
        eventApprovalLog: [],
        userEventRoleData: {},
        eventCreatorId: '',
        managersOfCurrentUser: [],
        nextApproverList: [],
        userIsCurrentPendingApproverOrCurrentPendingApproverAncestor: false,
        eventIsConfidential: false,
    },
    reducers: {
        resetEventApprovalAndUserRolesSlice: resetEventApprovalAndUserRoles,
        loadEventApprovalAndUserRolesSlice: loadEventApprovalAndUserRoles,
    },
});

export const {
    resetEventApprovalAndUserRolesSlice,
    loadEventApprovalAndUserRolesSlice,
} = eventApprovalAndUserRolesSlice.actions;

const EventApprovalAndUserRolesStore = eventApprovalAndUserRolesSlice.reducer;
export default EventApprovalAndUserRolesStore;
