import { FWTypography } from '../../../Common/FWTypograhy';
import { Box } from '@mui/material';
import { FWTooltip } from '../../../Common/FWCustomTooltip';
import { PurchaseOrderDisplayStatusSeller } from '../../Interfaces/PO.model';

const SellerPurchaseOrderStatusChip = ({
    displayStatus,
    hoverText = '',
}: {
    displayStatus: PurchaseOrderDisplayStatusSeller;
    hoverText?: string;
}) => {
    const StatusBlock = (param_status: string, param_color: string) => {
        return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <FWTypography sx={{ color: param_color }}>
                    {param_status}
                </FWTypography>
                &nbsp;
                {hoverText.length > 0 && (
                    <FWTooltip title={hoverText}>
                        <Box color={param_color}>
                            <i className="bi bi-info-circle" />
                        </Box>
                    </FWTooltip>
                )}
            </div>
        );
    };

    switch (displayStatus) {
        case PurchaseOrderDisplayStatusSeller.ISSUED:
            return StatusBlock('Issued', 'success.main');
        case PurchaseOrderDisplayStatusSeller.ONGOING:
            return StatusBlock('Ongoing', 'success.main');
        case PurchaseOrderDisplayStatusSeller.COMPLETED:
            return StatusBlock('Completed', 'success.main');
        case PurchaseOrderDisplayStatusSeller.RESCINDED:
            return StatusBlock('Rescinded', 'error.main');
        case PurchaseOrderDisplayStatusSeller.DECLINED:
            return StatusBlock('Declined', 'error.main');
        case PurchaseOrderDisplayStatusSeller.TERMINATED:
            return StatusBlock('Terminated', 'error.main');
        case PurchaseOrderDisplayStatusSeller.ON_HOLD:
            return StatusBlock('On hold', 'warning.main');
        case PurchaseOrderDisplayStatusSeller.TERMINATION_REQUESTED:
            return StatusBlock('Termination requested', 'error.main');
        case PurchaseOrderDisplayStatusSeller.REVISING:
            return StatusBlock('Revising', 'warning.main');
        default:
            return StatusBlock(displayStatus, 'primary.main');
    }
};

export default SellerPurchaseOrderStatusChip;
