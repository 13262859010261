import {
    IBomItemDeliverScheduleStructure,
    IParentSubBomItemForIBomdeliveryScheduleStrutcure,
} from '../../Interfaces/Buyer/RFQ.model';

export const getMostDeepestSubBomCode = (
    bomdsInfo: IParentSubBomItemForIBomdeliveryScheduleStrutcure | null,
    depth: number = 1
): { bomCode: string; depth: number } | null => {
    if (bomdsInfo) {
        if (bomdsInfo.parent_sub_bom_item?.parent_sub_bom_item) {
            const temp = getMostDeepestSubBomCode(
                bomdsInfo.parent_sub_bom_item,
                depth + 1
            );
            if (temp) {
                temp.depth += 1;
                return temp;
            }
            return null;
        } else if (bomdsInfo.parent_sub_bom_item) {
            return {
                bomCode: bomdsInfo.sub_bom?.bom_code ?? '',
                depth: depth + 1,
            };
        } else {
            if (bomdsInfo.sub_bom) {
                return {
                    bomCode: bomdsInfo.sub_bom.bom_code,
                    depth: depth,
                };
            } else {
                return null;
            }
        }
    } else {
        return null;
    }
};

export const getBomHierarachyLabelFromBoms = (
    dsBomItem: IBomItemDeliverScheduleStructure
) => {
    const output: string[] = [];

    output.push(dsBomItem.enterprise_bom?.bom_code ?? '');

    if (dsBomItem.parent_sub_bom_item) {
        getBomHierarachyLabelFromBomsInner(
            dsBomItem.parent_sub_bom_item,
            output
        );
    }

    return output.reverse().join('->');
};

const getBomHierarachyLabelFromBomsInner = (
    bomdsInfo: IParentSubBomItemForIBomdeliveryScheduleStrutcure,
    list: string[]
) => {
    if (bomdsInfo.parent_sub_bom_item) {
        // if (bomdsInfo.parent_sub_bom_item.parent_sub_bom_item)
        getBomHierarachyLabelFromBomsInner(bomdsInfo.parent_sub_bom_item, list);
    }
    list.push(bomdsInfo.sub_bom?.bom_code ?? '');
};

export const getBomHierarachyEntryIdFromBoms = (
    dsBomItem: IBomItemDeliverScheduleStructure
) => {
    const output: string[] = [];

    if (dsBomItem.parent_sub_bom_item) {
        getBomHierarachyEntryIdFromBomsInner(
            dsBomItem.parent_sub_bom_item,
            output
        );
    }
    return output[output.length - 1];
};

const getBomHierarachyEntryIdFromBomsInner = (
    bomdsInfo: IParentSubBomItemForIBomdeliveryScheduleStrutcure,
    output: string[]
) => {
    if (bomdsInfo.parent_sub_bom_item) {
        // if (bomdsInfo.parent_sub_bom_item.parent_sub_bom_item)
        getBomHierarachyEntryIdFromBomsInner(
            bomdsInfo.parent_sub_bom_item,
            output
        );
    }
    output.push(bomdsInfo.entry_id);
};

export const doesThisDsInfoHaveSubBomItemId = (
    bomInfo: IBomItemDeliverScheduleStructure,
    sub_bom_item_id: string
) => {
    if (bomInfo.entry_id === sub_bom_item_id) {
        return true;
    } else if (bomInfo.parent_sub_bom_item) {
        return doesThisSubDsInfoHaveSubBomItemId(
            bomInfo.parent_sub_bom_item,
            sub_bom_item_id
        );
    }
};

export const doesThisSubDsInfoHaveSubBomItemId: (
    bomInfo: IParentSubBomItemForIBomdeliveryScheduleStrutcure,
    sub_bom_item_id: string
) => boolean = (bomInfo, sub_bom_item_id) => {
    if (bomInfo.entry_id === sub_bom_item_id) {
        return true;
    } else if (bomInfo.parent_sub_bom_item) {
        return doesThisSubDsInfoHaveSubBomItemId(
            bomInfo.parent_sub_bom_item,
            sub_bom_item_id
        );
    }

    return false;
};
