import { useLayoutEffect } from 'react';
import { createPortal } from 'react-dom';

const MakePaymentPortal = ({ children }: { children: JSX.Element }) => {
    const mount = document.getElementById('make-payment-portal-root');
    const el = document.createElement('div');

    useLayoutEffect(() => {
        if (mount) {
            mount.appendChild(el);
        }
        return () => {
            if (mount) {
                mount.removeChild(el);
            }
        };
    }, [el, mount]);

    return createPortal(children, el);
};

export default MakePaymentPortal;
