import { Grid, Divider } from '@mui/material';
import ProfileMenu from '../../Components/Shared/ProfileMenu';
import { HelpPageName } from '../../Redux/Slices/HelpInformationSlice';
// import { headerTabs } from '../Constants/GRDashboardConstants';
// import GRDashboardTabs from './GRDashboardTabs';

const ChatNavBar = () => {
    return (
        <Grid
            container
            sx={{
                position: 'sticky',
                top: 0,
                left: 0,
                zIndex: 10,
                background: '#fff',
            }}
        >
            <Grid
                item
                xs={12}
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
            >
                <ProfileMenu currentPage={HelpPageName.CART} />
            </Grid>
            <Grid item xs={12}>
                <Divider />
            </Grid>
        </Grid>
    );
};

export default ChatNavBar;
