import {
    Box,
    Checkbox,
    DialogActions,
    DialogContent,
    FormControlLabel,
    FormGroup,
    ListItemText,
    MenuItem,
    Select,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { FWButton, FWIconButton } from '../../Common/FWButton';
import { FWMenuItem } from '../../Common/FWCustomMenu';
import { FWTooltip } from '../../Common/FWCustomTooltip';
import { FWInput } from '../../Common/FWInput';
import { FWPopupWithChildren } from '../../Common/FWPopupWithChildren';
import FWSelect, { OpenDownIcon } from '../../Common/FWSelect';
import { FWTypography } from '../../Common/FWTypograhy';
import { HookStateValue } from '../../Common/Hooks/StateHook';
import LoadingBackDrop from '../../Components/Shared/LoadingBackdrop';
import useQuickUserInvites from '../Hooks/useQuickUserInvites';
import { inviteeRoleList } from '../Interfaces/NavbarUsersInterfaces';

interface IQuickInviteUserPopupProps {
    isOpen: boolean;
    onClose: () => void;
    onSuccess: () => void;
    showAtOnboarding?: boolean;
    authUserName?: string;
    isSellerSide?: boolean;
    isGlobalAdmin?: boolean;
}

export default function QuickInviteUserPopup(
    props: IQuickInviteUserPopupProps
) {
    const handleClose = () => {
        props.onClose();
    };

    const {
        hookState,
        allEntities,
        allEnterpriseUsers,
        inviteList,
        editEmail,
        toggleSkipOnboarding,
        editManagers,
        toggleAdmin,
        changeRole,
        updateEntities,
        addRow,
        removeRow,
        resetForm,
        emailErrors,
        sendInvites,
        sendingInvites,
    } = useQuickUserInvites();

    useEffect(() => {
        if (props.isOpen) {
            resetForm(props.showAtOnboarding ?? false);
        }
    }, [props.isOpen, resetForm, props.showAtOnboarding]);

    const [showAllColumns, setShowAllColumns] = useState<boolean>(false);

    const emailErrorLabel = (index: number) => {
        if (emailErrors[index]) {
            switch (emailErrors[index]) {
                case 'NONE':
                    return '';
                case 'BLANK':
                    return '';
                case 'INVALID':
                    return 'Invalid email';
                case 'DUPLICATE':
                    return 'Duplicate email';
                case 'ALREADY_IN_ORG':
                    return 'User already present';
            }
        }
        return '';
    };

    const disableConfirm =
        emailErrors.filter((err) => err !== 'NONE').length > 0 ||
        inviteList.length -
            inviteList.filter((invitee) => {
                return invitee.email === '' || invitee.entities.length === 0;
            }).length ===
            0;

    return (
        <FWPopupWithChildren
            open={props.isOpen}
            handleClose={handleClose}
            title={!props.showAtOnboarding ? 'Invite Users' : ''}
            customHeaderElement={
                props.showAtOnboarding ? (
                    <>
                        {/* <Box
                            padding={'15px 0px'}
                            bgcolor={'#f5f5f9'}
                            display="flex"
                            justifyContent="center"
                        >
                            <img
                                style={{ height: 'auto', width: '2rem' }}
                                src="/logo.png"
                                alt="factwise logo"
                            />
                            <FWTypography
                                fontWeight={600}
                                fontSize={'1.5rem'}
                                textAlign={'center'}
                            >
                                FactWise
                            </FWTypography>
                        </Box> */}
                        <Box padding={'40px 25px 10px 25px'}>
                            <FWTypography
                                variant="h5"
                                fontWeight={500}
                                fontSize={'1.5rem'}
                                letterSpacing={'-0.2px'}
                            >
                                {`Add your colleagues, ${props.authUserName}!`}
                            </FWTypography>
                            {/* <FWTypography
                                fontWeight={500}
                                fontSize={'1rem'}
                                color={'text.secondary'}
                            >
                                Get more done with your team
                            </FWTypography> */}
                        </Box>
                    </>
                ) : undefined
            }
            size={showAllColumns ? 'custom' : 'large'}
            popupWidth={showAllColumns ? '80%' : '0'}
        >
            {(hookState.state === HookStateValue.LOADING || sendingInvites) && (
                <LoadingBackDrop />
            )}
            {hookState.state === HookStateValue.READY && (
                <>
                    <DialogContent
                        sx={{ padding: '24px', marginBottom: '20px' }}
                    >
                        <Box
                            display="grid"
                            flex={1}
                            alignItems="center"
                            columnGap={2}
                            rowGap={2}
                            gridTemplateColumns={
                                props.showAtOnboarding
                                    ? showAllColumns
                                        ? allEntities.length > 1
                                            ? '23% 23% 23% 24% 0%'
                                            : '30% 31% 31% 0%'
                                        : '43% 44% 3%'
                                    : showAllColumns
                                    ? allEntities.length > 1
                                        ? '22% 22% 22% 22% 0%'
                                        : '29% 29% 29% 0%'
                                    : '40% 40% 10%'
                            }
                        >
                            <Box>
                                <FWTypography variant="h5">Email</FWTypography>
                            </Box>
                            {showAllColumns && (
                                <Box>
                                    <FWTypography variant="h5">
                                        Manager(s)
                                    </FWTypography>
                                </Box>
                            )}
                            <Box>
                                <FWTypography variant="h5">Role</FWTypography>
                            </Box>
                            {showAllColumns && allEntities.length > 1 && (
                                <Box>
                                    <FWTypography variant="h5">
                                        Entities
                                    </FWTypography>
                                </Box>
                            )}
                            <Box></Box>

                            {inviteList.map((invitee, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        {/* EMAIL */}
                                        <Box
                                            sx={{
                                                position: 'relative',
                                                top: 23,
                                            }}
                                        >
                                            <FWInput
                                                // value={invitee.email}
                                                onChange={(e) => {
                                                    editEmail(
                                                        index,
                                                        e.target.value
                                                    );
                                                }}
                                                error={
                                                    emailErrors[index] &&
                                                    emailErrors[index] !==
                                                        'NONE' &&
                                                    emailErrors[index] !==
                                                        'BLANK'
                                                }
                                                helper={{
                                                    text: emailErrorLabel(
                                                        index
                                                    ),
                                                }}
                                            />
                                            <FormGroup>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={
                                                                invitee.skip_onboarding
                                                            }
                                                            name="skip_onboarding"
                                                            onChange={() => {
                                                                toggleSkipOnboarding(
                                                                    index
                                                                );
                                                            }}
                                                        />
                                                    }
                                                    label="Skip onboarding"
                                                />
                                            </FormGroup>
                                        </Box>
                                        {/* MANAGER */}
                                        {showAllColumns && (
                                            <FWSelect
                                                size="small"
                                                fullWidth
                                                multiple
                                                value={invitee.managers}
                                                onChange={(event) => {
                                                    const {
                                                        target: { value },
                                                    }: { target: any } = event;
                                                    editManagers(
                                                        index,
                                                        // On autofill we get a stringified value.
                                                        typeof value ===
                                                            'string'
                                                            ? value.split(',')
                                                            : value
                                                    );
                                                }}
                                                renderValue={(selected: any) =>
                                                    allEnterpriseUsers
                                                        .filter(
                                                            (u) =>
                                                                selected.indexOf(
                                                                    u.user_id
                                                                ) > -1
                                                        )
                                                        .map((u) => u.name)
                                                        .join(', ')
                                                }
                                                MenuProps={{
                                                    PaperProps: {
                                                        sx: {
                                                            bgcolor: 'white',
                                                            maxHeight: '300px',
                                                            boxShadow:
                                                                '0px 5px 16px #00000024 !important',
                                                        },
                                                    },
                                                }}
                                            >
                                                {allEnterpriseUsers.map((u) => (
                                                    <FWMenuItem
                                                        key={u.user_id}
                                                        value={u.user_id}
                                                    >
                                                        <Checkbox
                                                            checked={
                                                                invitee.managers.indexOf(
                                                                    u.user_id
                                                                ) > -1
                                                            }
                                                            sx={{
                                                                '&.MuiCheckbox-root':
                                                                    {
                                                                        padding:
                                                                            '0px 9px',
                                                                    },
                                                            }}
                                                        />
                                                        <ListItemText
                                                            primary={u.name}
                                                        />
                                                    </FWMenuItem>
                                                ))}
                                            </FWSelect>
                                        )}
                                        {/* ADMIN */}
                                        {showAllColumns && (
                                            <Box
                                                sx={{
                                                    // display: 'flex',
                                                    justifyContent: 'center',
                                                    display: 'none', // TODO: remove this line when we want to enable admin
                                                }}
                                            >
                                                {/* <Switch
                                                    checked={invitee.isAdmin}
                                                    onChange={() => {
                                                        toggleAdmin(index);
                                                    }}
                                                /> */}
                                                <Checkbox
                                                    checked={invitee.isAdmin}
                                                    onChange={() => {
                                                        toggleAdmin(index);
                                                    }}
                                                />
                                            </Box>
                                        )}
                                        {/* ROLE */}
                                        <Box>
                                            <FWSelect
                                                value={invitee.role}
                                                fullWidth
                                                // disabled={invitee.isAdmin}
                                                MenuProps={{
                                                    PaperProps: {
                                                        sx: {
                                                            bgcolor: 'white',
                                                            boxShadow:
                                                                '0px 5px 16px #00000024 !important',
                                                        },
                                                    },
                                                }}
                                            >
                                                {(props.isSellerSide
                                                    ? props.isGlobalAdmin
                                                        ? inviteeRoleList.slice(
                                                              0,
                                                              2
                                                          )
                                                        : inviteeRoleList.slice(
                                                              1,
                                                              2
                                                          )
                                                    : inviteeRoleList
                                                ).map(
                                                    (
                                                        role: any,
                                                        index2: any
                                                    ) => {
                                                        return (
                                                            <FWMenuItem
                                                                key={index2}
                                                                value={
                                                                    role.value
                                                                }
                                                                onClick={() => {
                                                                    if (
                                                                        index2 ===
                                                                        0
                                                                    ) {
                                                                        toggleAdmin(
                                                                            index
                                                                        );
                                                                    } else {
                                                                        changeRole(
                                                                            index,
                                                                            role.value
                                                                        );
                                                                    }
                                                                }}
                                                            >
                                                                {role.label}
                                                            </FWMenuItem>
                                                        );
                                                    }
                                                )}
                                            </FWSelect>
                                        </Box>
                                        {/* ENTITIES */}
                                        {showAllColumns &&
                                            allEntities.length > 1 && (
                                                <Box>
                                                    <Select
                                                        size="small"
                                                        fullWidth
                                                        labelId="demo-multiple-checkbox-label"
                                                        id="demo-multiple-checkbox"
                                                        multiple
                                                        value={invitee.entities}
                                                        error={
                                                            invitee.entities
                                                                .length === 0
                                                        }
                                                        disabled={
                                                            invitee.isAdmin ||
                                                            (allEntities.length <
                                                                2 &&
                                                                invitee.entities
                                                                    .length !==
                                                                    0)
                                                        }
                                                        onChange={(event) => {
                                                            const {
                                                                target: {
                                                                    value,
                                                                },
                                                            } = event;
                                                            updateEntities(
                                                                index,
                                                                // On autofill we get a stringified value.
                                                                typeof value ===
                                                                    'string'
                                                                    ? value.split(
                                                                          ','
                                                                      )
                                                                    : value
                                                            );
                                                        }}
                                                        renderValue={(
                                                            selected
                                                        ) =>
                                                            allEntities
                                                                .filter(
                                                                    (entity) =>
                                                                        selected.indexOf(
                                                                            entity.entityId
                                                                        ) > -1
                                                                )
                                                                .map(
                                                                    (entity) =>
                                                                        entity.entityName
                                                                )
                                                                .join(', ')
                                                        }
                                                        MenuProps={{
                                                            PaperProps: {
                                                                sx: {
                                                                    bgcolor:
                                                                        'white',
                                                                    maxHeight:
                                                                        '300px',
                                                                },
                                                            },
                                                        }}
                                                        IconComponent={
                                                            OpenDownIcon
                                                        }
                                                    >
                                                        {allEntities.map(
                                                            (entity) => (
                                                                <MenuItem
                                                                    key={
                                                                        entity.entityId
                                                                    }
                                                                    value={
                                                                        entity.entityId
                                                                    }
                                                                >
                                                                    <Checkbox
                                                                        checked={
                                                                            invitee.entities.indexOf(
                                                                                entity.entityId
                                                                            ) >
                                                                            -1
                                                                        }
                                                                        sx={{
                                                                            '&.MuiCheckbox-root':
                                                                                {
                                                                                    padding:
                                                                                        '0px 9px',
                                                                                },
                                                                        }}
                                                                    />
                                                                    <ListItemText
                                                                        primary={
                                                                            entity.entityName
                                                                        }
                                                                    />
                                                                </MenuItem>
                                                            )
                                                        )}
                                                    </Select>
                                                </Box>
                                            )}
                                        {/* Add/Remove user */}
                                        <Box className="flex flex--aic">
                                            {!props.showAtOnboarding && (
                                                <FWTooltip title="Remove User">
                                                    <Box>
                                                        <FWIconButton
                                                            onClick={() => {
                                                                removeRow(
                                                                    index
                                                                );
                                                            }}
                                                            color="error"
                                                            disabled={
                                                                inviteList.length ===
                                                                1
                                                            }
                                                        >
                                                            <i className="bi bi-dash-circle" />
                                                        </FWIconButton>
                                                    </Box>
                                                </FWTooltip>
                                            )}
                                            {index ===
                                                inviteList.length - 1 && (
                                                <FWTooltip title="Add User">
                                                    <Box>
                                                        <FWIconButton
                                                            onClick={() => {
                                                                addRow();
                                                            }}
                                                            color="primary"
                                                        >
                                                            <i className="bi bi-plus-circle" />
                                                        </FWIconButton>
                                                    </Box>
                                                </FWTooltip>
                                            )}
                                        </Box>
                                    </React.Fragment>
                                );
                            })}
                            {props.showAtOnboarding && (
                                <FWButton
                                    variant="outlined"
                                    onClick={() =>
                                        setShowAllColumns((prev) => !prev)
                                    }
                                >
                                    <Box display={'flex'} alignItems={'center'}>
                                        {showAllColumns
                                            ? 'Less Options'
                                            : 'More Options'}
                                        &nbsp;
                                        <Box fontSize={12}>
                                            <i
                                                className={
                                                    showAllColumns
                                                        ? 'bi bi-chevron-left'
                                                        : 'bi bi-chevron-right'
                                                }
                                            ></i>
                                        </Box>
                                    </Box>
                                </FWButton>
                            )}
                        </Box>
                    </DialogContent>
                    <DialogActions
                        sx={{
                            padding: props.showAtOnboarding
                                ? '48px 24px 18px 24px'
                                : '12px 24px 24px 24px',
                        }}
                    >
                        <Box className="flex flex--jcsb" width={'100%'}>
                            {!props.showAtOnboarding && !props.isSellerSide ? (
                                <FWButton
                                    variant="outlined"
                                    onClick={() =>
                                        setShowAllColumns((prev) => !prev)
                                    }
                                >
                                    <Box display={'flex'} alignItems={'center'}>
                                        {showAllColumns
                                            ? 'Less Options'
                                            : 'More Options'}
                                        &nbsp;
                                        <Box fontSize={12}>
                                            <i
                                                className={
                                                    showAllColumns
                                                        ? 'bi bi-chevron-left'
                                                        : 'bi bi-chevron-right'
                                                }
                                            ></i>
                                        </Box>
                                    </Box>
                                </FWButton>
                            ) : (
                                <Box></Box>
                            )}

                            <Box className="flex">
                                <FWButton
                                    onClick={handleClose}
                                    color={
                                        props.showAtOnboarding
                                            ? 'primary'
                                            : 'error'
                                    }
                                    variant="outlined"
                                >
                                    {props.showAtOnboarding ? 'Skip' : 'Cancel'}
                                </FWButton>
                                &nbsp; &nbsp;
                                <FWButton
                                    onClick={() => {
                                        sendInvites().then(() => {
                                            props.onSuccess();
                                            if (
                                                document.location.pathname ===
                                                '/admin/users'
                                            ) {
                                                document.location.reload();
                                            }
                                        });
                                    }}
                                    color="primary"
                                    autoFocus
                                    variant="contained"
                                    disabled={disableConfirm}
                                >
                                    Confirm
                                </FWButton>
                            </Box>
                        </Box>
                    </DialogActions>
                </>
            )}
        </FWPopupWithChildren>
    );
}
