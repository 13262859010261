import { CircularProgress, Grid, styled } from '@mui/material';
import { isEmpty } from 'lodash';
import { Fragment } from 'react';
import {
    FWAccordion,
    FWAccordionDetails,
    FWAccordionSummary,
} from '../../Common/FWAccordian';
import FWIcon from '../../Common/FWIcon';
import { FWTypography } from '../../Common/FWTypograhy';
import { HookStateValue } from '../../Common/Hooks/StateHook';
import { FWNumberFormatter } from '../../Common/Utils/CurrencyUtils';
import PaymentInvoiceItemAccordion from '../Components/PaymentInvoiceItemAccordion';

import { IPOPrepaymentInvoiceSummary } from '../Interfaces/PaymentsInterface';

interface IPrepaymentInvoiceDetailProps {
    invoiceUid: string;
    user_type: 'BUYER' | 'SELLER';
    hookState: any;
    prepaymentInvoiceDetails: IPOPrepaymentInvoiceSummary;
}

const TH = styled('th')({
    padding: '10px 16px',
    textAlign: 'left',
    borderTop: '1px solid #C4C4c8',
    borderBottom: '1px solid #C4C4c8',
    backgroundColor: '#f0f0f2',
    '&:not(:nth-of-type(1))': {
        textAlign: 'right',
    },
});

const PrepaymentInvoiceDetail = ({
    invoiceUid,
    user_type,
    hookState,
    prepaymentInvoiceDetails,
}: IPrepaymentInvoiceDetailProps) => {
    // const {
    //     hookState,
    //     prepaymentInvoiceDetails,
    // }: IPrepaymentInvoiceDetailProvider = usePrepaymentInvoiceDetail(
    //     invoiceUid,
    //     user_type
    // );

    return (
        <>
            {hookState.state === HookStateValue.LOADING ? (
                <Grid container sx={{ minWidth: '100%', width: 'auto' }}>
                    <Grid item xs={12} className="pb--15 pr--15 flex flex--jcc">
                        <CircularProgress size={30} />
                    </Grid>
                </Grid>
            ) : (
                <FWAccordion
                    style={{
                        padding: 0,
                        border: 0,
                        width: '100%',
                        minWidth: 'unset',
                    }}
                >
                    <FWAccordionSummary
                        style={{
                            padding: '4px 16px',
                        }}
                        sx={{
                            display: 'inline-flex',
                            paddingLeft: '0 !important',
                            paddingRight: '0 !important',
                            '.MuiAccordionSummary-expandIconWrapper': {
                                marginLeft: '10px',
                            },
                            '.MuiAccordionSummary-content': {
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            },
                        }}
                        expandIcon={
                            <FWIcon
                                name="bi bi-chevron-down"
                                size={24}
                                color="primary.main"
                            />
                        }
                    >
                        <FWTypography
                            color="primary"
                            // marginRight={
                            //     hookState.state === HookStateValue.LOADING
                            //         ? '10px'
                            //         : undefined
                            // }
                        >
                            Invoice ID: {prepaymentInvoiceDetails.invoiceId} |
                            Item(s): {prepaymentInvoiceDetails.itemCount} |
                            Total amount:{' '}
                            {FWNumberFormatter(
                                prepaymentInvoiceDetails.totalAmount,
                                prepaymentInvoiceDetails.currency
                            )}
                        </FWTypography>
                    </FWAccordionSummary>

                    <FWAccordionDetails sx={{ paddingBottom: '0 !important' }}>
                        <Grid
                            container
                            marginBottom="25px"
                            sx={{
                                'td *': {
                                    whiteSpace: 'normal !important',
                                },
                            }}
                        >
                            <Grid item xs={12}>
                                <table
                                    style={{
                                        width: '100%',
                                        borderSpacing: '0',
                                    }}
                                >
                                    <thead>
                                        <tr>
                                            <TH style={{ width: '60%' }}>
                                                <FWTypography fontWeight={500}>
                                                    Item name
                                                </FWTypography>
                                            </TH>
                                            {/* <TH style={{ width: '12%' }}>
                                        <FWTypography fontWeight={500}>
                                            Quantity
                                        </FWTypography>
                                    </TH>
                                    <TH style={{ width: '12%' }}>
                                        <FWTypography fontWeight={500}>
                                            Rate
                                        </FWTypography>
                                    </TH>
                                    <TH style={{ width: '20%' }}>
                                        <FWTypography fontWeight={500}>
                                            Total (inc. tax + shipping)
                                        </FWTypography>
                                    </TH> */}
                                            <TH style={{ width: '40%' }}>
                                                <FWTypography fontWeight={500}>
                                                    Prepayment amount
                                                </FWTypography>
                                            </TH>
                                        </tr>
                                    </thead>
                                    {prepaymentInvoiceDetails.itemsList.map(
                                        (itemDetails, idx) => {
                                            return !isEmpty(
                                                itemDetails.itemName
                                            ) ? (
                                                <PaymentInvoiceItemAccordion
                                                    data={itemDetails}
                                                    key={idx}
                                                />
                                            ) : (
                                                <Fragment key={idx}></Fragment>
                                            );
                                        }
                                    )}
                                </table>
                            </Grid>
                        </Grid>
                    </FWAccordionDetails>
                </FWAccordion>
            )}
        </>
    );
};

export default PrepaymentInvoiceDetail;
