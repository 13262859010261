export const ItemsAdminBuiltInFieldsMap: Record<string, string> = {
    'Item ID': 'code',
    'Item Name': 'name',
    'Preferred vendors': 'preferred_vendor_status',
    'Assigned entities': 'entity_count',
    'Modified on': 'modified_datetime',
};

export const ItemsAdminFilterFieldToNameMap: Record<string, string> = {
    code: 'Item ID',
    name: 'Item Name',
    preferred_vendor_status: 'Preferred vendors',
    entity_count: 'Assigned entities',
    modified_datetime: 'Modified on',
};
