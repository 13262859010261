import {
    IQCCompletedListSummary,
    IQCGRNListSummary,
} from '../Interfaces/QCInterface';
import { QCDashboardType, IQCDashboardState } from '../Slices/QCDashboardSlice';

export interface IGRDashboardListActions {
    type: string;
    payload: {
        type: QCDashboardType;
        value: IQCGRNListSummary[] | IQCCompletedListSummary[];
    };
}

export interface ITabsCountAction {
    payload: {
        type: QCDashboardType;
        value: number;
    };
}

export interface ITabsChangeAction {
    payload: QCDashboardType;
}

export const initDashboardListReducer = (
    state: IQCDashboardState,
    action: IGRDashboardListActions
) => {
    switch (action.payload.type) {
        case QCDashboardType.PENDING:
            state.pendingList = action.payload.value as IQCGRNListSummary[];
            break;
        case QCDashboardType.COMPLETED:
            state.completedList = action.payload
                .value as IQCCompletedListSummary[];
            break;
    }
};

export const setTabCountReducer = (
    state: IQCDashboardState,
    action: ITabsCountAction
) => {
    const tabsCount = state.tabsCount;
    switch (action.payload.type) {
        case QCDashboardType.PENDING:
            tabsCount.pending = action.payload.value;
            break;
        case QCDashboardType.COMPLETED:
            tabsCount.completed = action.payload.value;
            break;
    }
};

export const setSelectedTabReducer = (
    state: IQCDashboardState,
    action: ITabsChangeAction
) => {
    state.selectedTab = action.payload;
};
