// import moment from 'moment';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { getFWDateTime } from '../../Common/Utils/DateUtils';
import { get, post } from '../../Utils/api';
import getNotificationDetails, {
    INotificationValues,
} from '../Constants/NotificationsMap';
import { INotification } from '../Interfaces/NotificationInterfaces';

export const fetchNotifications = async (): Promise<INotification[]> => {
    const respose: any = await get<any>(`/notifications/`);
    const data: any[] = respose.data;
    if (data) {
        const notificationList = data
            .sort((a, b) => {
                // sort notification by date and time
                if (moment(a.action_time).isBefore(moment(b.action_time)))
                    return 1;
                if (moment(a.action_time).isAfter(moment(b.action_time)))
                    return -1;
                return 0;
            })
            .filter(
                // TODO: BE to not show the current user his own notifications
                (notification) =>
                    notification.action_by_user !== notification.target_user
            )
            .map((notification: any): INotification => {
                const notificationValues: INotificationValues = {
                    resourceId: notification.resource_id,
                    actionUser: notification.action_by_user_full_name,
                    actionEntity: notification.action_entity_name,
                    actionTime: notification.action_time,
                    resourceName: notification.resource_name,
                    resourceEntityName: notification.resource_entity_name,
                    resourseEntityId: notification.resource_entity,
                };
                const actions = getNotificationDetails(
                    notification.notification_type,
                    notificationValues
                );
                const noti: INotification = {
                    title: actions.TITLE,
                    message: actions.MESSAGE,
                    url: actions.URL,
                    id: notification.notification_id,
                    userDisplayName: notification.target_type,
                    time: getFWDateTime(notification.action_time),
                    status: notification.status,
                    type: notification.notification_type,
                };
                return noti;
            })
            .filter((notification) => !isEmpty(notification.title));
        return notificationList;
    }
    return respose;
};

export const acknowledgeNotifications = async (
    notificationIds: string[]
): Promise<boolean> => {
    const payload = {
        notification_ids: notificationIds,
    };
    try {
        await post<any, any>(`/notifications/acknowledge/`, payload);
        return true;
    } catch (error) {
        return false;
    }
};
