import React, { useCallback, useContext, useEffect } from 'react';
import { fetchVendorContactsList } from '../../../Organizations/Admin/Services/VendorsService';
import { Box, Checkbox, CircularProgress, IconButton } from '@mui/material';
import { FWButton } from '../../../Common/FWButton';
import AddVendorContactPopup from '../../../Organizations/Admin/Pages/Vendors/AddVendorContactPopup';
import { IVendorContact } from '../../../Organizations/Admin/Interfaces/VendorsInterface';
import FWPopover from '../../../Common/FWPopover';
import { FWTypography } from '../../../Common/FWTypograhy';
import { AuthContext } from '../../../Contexts/AuthContext';
import { FWTooltip } from '../../../Common/FWCustomTooltip';
interface IManageVendorContacts {
    anchorEl: HTMLAnchorElement | null;
    handleClose: () => void;
    vendorName: string;
    entity_id: string;
    seller_id: string;
    selectedVendorContacts: string[];
    updateVendorContacts: (
        vendor_id: string,
        contacts: {
            vendor_contact_id: string;
            full_name: string;
            primary_email: string;
            emails: {
                email: string;
                type: string;
            }[];
            phone_numbers: string[];
            tags: string[];
        }[]
    ) => void;
    enterpriseItemUuid: string;
}

const ManageVendorContacts = ({
    anchorEl,
    handleClose,
    vendorName,
    entity_id,
    seller_id,
    selectedVendorContacts,
    updateVendorContacts,
    enterpriseItemUuid,
}: IManageVendorContacts) => {
    const enterprise_id =
        useContext(AuthContext).authData.details?.enterprise_id;
    const [openAddContact, setOpenAddContact] = React.useState(false);
    const [vendorContacts, setVendorContacts] = React.useState<
        IVendorContact[]
    >([]);
    const [selectedContacts, setSelectedContacts] = React.useState<string[]>(
        []
    );
    const [openContacts, setOpenContact] = React.useState<Number[]>([]);

    useEffect(() => {
        if (anchorEl) {
            setSelectedContacts(selectedVendorContacts);
        }
        return () => {
            setSelectedContacts([]);
        };
    }, [selectedVendorContacts, anchorEl]);

    useEffect(() => {
        if (anchorEl) {
            let isCancelled = false;
            const fetchData = async () => {
                const contacts = await fetchVendorContactsList(
                    entity_id,
                    seller_id,
                    'VENDOR_MASTER'
                );
                if (!isCancelled) {
                    setVendorContacts(contacts);
                }
            };

            fetchData();

            return () => {
                isCancelled = true;
            };
        }
    }, [entity_id, seller_id, anchorEl, enterpriseItemUuid]);
    const handleSubmit = useCallback(() => {
        const selectedContactsDetails = vendorContacts
            .filter((vendor) => {
                return selectedContacts.includes(vendor.vendorContactUid);
            })
            .map((vendor) => {
                const allMails = [
                    ...(vendor.secondaryEmails?.map((email) => ({
                        email: email,
                        type: 'SECONDARY',
                    })) ?? []),
                    ...(vendor.CCEmail?.map((email) => ({
                        email: email,
                        type: 'CC',
                    })) ?? []),
                ];
                const allPhoneNumber = [
                    ...(vendor.buyerContacts ?? []),
                    ...(vendor.sellerContacts ?? []),
                ].filter(
                    (contact) => contact.number.trim() !== '' && contact.visible
                );
                return {
                    vendor_contact_id: vendor.vendorContactUid,
                    full_name: vendor.fullName,
                    primary_email: vendor.sellerEmails[0].email,
                    emails: allMails,
                    phone_numbers: allPhoneNumber[0]
                        ? [allPhoneNumber[0]?.number]
                        : [],
                    tags: vendor.tags,
                };
            });

        setSelectedContacts([]);
        setVendorContacts([]);
        updateVendorContacts(seller_id, selectedContactsDetails);
        handleClose();
    }, [
        selectedContacts,
        seller_id,
        updateVendorContacts,
        vendorContacts,
        handleClose,
    ]);
    const handleSelectAllContacts = () => {
        if (selectedContacts.length !== vendorContacts.length) {
            setSelectedContacts([
                ...vendorContacts
                    .filter((vc) => vc.isPrimary)
                    .map((vc) => vc.vendorContactUid),
                ...vendorContacts
                    .filter((vc) => !vc.isPrimary)
                    .map((vc) => vc.vendorContactUid),
            ]);
        } else {
            setSelectedContacts(
                vendorContacts
                    .filter((vc) => vc.isPrimary)
                    .map((vc) => vc.vendorContactUid)
            );
        }
    };
    return (
        <>
            <FWPopover
                open={anchorEl ? true : false}
                anchorEl={anchorEl}
                onClose={handleSubmit}
            >
                {vendorContacts.length !== 0 ? (
                    <div style={{ display: 'flex', direction: 'ltr' }}>
                        <Checkbox
                            size="small"
                            onClick={() => {
                                handleSelectAllContacts();
                            }}
                            checked={
                                selectedContacts.length ===
                                vendorContacts.length
                            }
                        />
                        <FWTypography style={{ marginTop: '8px' }}>
                            Select all
                        </FWTypography>
                    </div>
                ) : (
                    <></>
                )}
                {vendorContacts.map((vendor, index) => {
                    const allMails = Array.from(
                        new Set([
                            ...(vendor.secondaryEmails ?? []),
                            ...(vendor.CCEmail ?? []),
                        ])
                    );

                    const allPhoneNumber = [
                        ...(vendor.buyerContacts ?? []),
                        ...(vendor.sellerContacts ?? []),
                    ].filter(
                        (contact) =>
                            contact.number.trim() !== '' && contact.visible
                    );
                    return (
                        <Box
                            display={'flex'}
                            alignItems={'center'}
                            key={vendor.vendorContactUid}
                        >
                            <Box
                                key={vendor.vendorContactUid}
                                marginBottom={'5px'}
                            >
                                <Box
                                    display={'flex'}
                                    justifyContent={'space-between'}
                                    alignItems={'center'}
                                    marginBottom={'5px'}
                                >
                                    <Checkbox
                                        size="small"
                                        checked={selectedContacts.includes(
                                            vendor.vendorContactUid
                                        )}
                                        disabled={vendor.isPrimary}
                                        onClick={() => {
                                            if (
                                                selectedContacts.includes(
                                                    vendor.vendorContactUid
                                                )
                                            ) {
                                                setSelectedContacts((prev) => {
                                                    const newContacts = [
                                                        ...prev,
                                                    ];
                                                    const index =
                                                        newContacts.indexOf(
                                                            vendor.vendorContactUid
                                                        );
                                                    newContacts.splice(
                                                        index,
                                                        1
                                                    );

                                                    return newContacts;
                                                });
                                            } else {
                                                setSelectedContacts((prev) => {
                                                    const newContacts = [
                                                        ...prev,
                                                    ];
                                                    newContacts.push(
                                                        vendor.vendorContactUid
                                                    );
                                                    return newContacts;
                                                });
                                            }
                                        }}
                                    />
                                    <FWTypography fontSize={'0.95rem'}>
                                        <Box
                                            component="span"
                                            fontWeight={'500'}
                                        >
                                            {vendor.fullName}
                                        </Box>
                                        &nbsp; ({vendor.sellerEmails[0].email})
                                    </FWTypography>
                                    {(allMails.length > 0 ||
                                        allPhoneNumber?.length > 0) && (
                                        <IconButton size="small">
                                            <Box
                                                color={'primary.main'}
                                                onClick={() => {
                                                    setOpenContact((prev) => {
                                                        const newOpenContacts =
                                                            [...prev];
                                                        if (
                                                            newOpenContacts.includes(
                                                                index
                                                            )
                                                        ) {
                                                            newOpenContacts.splice(
                                                                newOpenContacts.indexOf(
                                                                    index
                                                                ),
                                                                1
                                                            );
                                                        } else {
                                                            newOpenContacts.push(
                                                                index
                                                            );
                                                        }
                                                        return newOpenContacts;
                                                    });
                                                }}
                                            >
                                                <i
                                                    className={
                                                        openContacts.includes(
                                                            index
                                                        )
                                                            ? 'bi bi-chevron-up'
                                                            : 'bi bi-chevron-down'
                                                    }
                                                ></i>
                                            </Box>
                                        </IconButton>
                                    )}
                                </Box>
                                {openContacts.includes(index) && (
                                    <Box
                                        marginLeft="1.3rem"
                                        marginTop={'-10px'}
                                    >
                                        {allMails &&
                                            allMails.length > 0 &&
                                            allMails.map((email) => (
                                                <FWTypography
                                                    fontSize={'0.87rem'}
                                                    marginBottom={'3px'}
                                                >
                                                    <i className="bi bi-envelope"></i>{' '}
                                                    &nbsp;
                                                    {email}
                                                </FWTypography>
                                            ))}
                                        {allPhoneNumber[0] &&
                                            allPhoneNumber[0].number.trim() !==
                                                '' && (
                                                <FWTypography
                                                    fontSize={'0.87rem'}
                                                >
                                                    <i className="bi bi-telephone"></i>
                                                    &nbsp;
                                                    {allPhoneNumber[0].number}
                                                </FWTypography>
                                            )}
                                    </Box>
                                )}
                            </Box>
                        </Box>
                    );
                })}

                {vendorContacts.length !== 0 ? (
                    <div style={{ display: 'flex', direction: 'ltr' }}>
                        <FWTooltip
                            title={
                                enterprise_id ===
                                'efe4f580-badc-4210-9b9f-761dccdd74f9'
                                    ? 'Feature not available in demo mode'
                                    : ''
                            }
                        >
                            <FWButton
                                disabled={
                                    enterprise_id ===
                                    'efe4f580-badc-4210-9b9f-761dccdd74f9'
                                }
                                onClick={() => {
                                    setOpenAddContact(true);
                                }}
                            >
                                Add new user to {vendorName}
                            </FWButton>
                        </FWTooltip>
                    </div>
                ) : (
                    <CircularProgress style={{ color: '#c4c4c8' }} size={24} />
                )}
            </FWPopover>

            <AddVendorContactPopup
                open={openAddContact}
                handleClose={() => {
                    setOpenAddContact(false);
                }}
                entityUid={entity_id}
                vendorUid={seller_id}
                allContacts={vendorContacts}
                onContactAddSuccess={(contact) => {
                    setVendorContacts((prev) => {
                        const newContacts = [...prev];
                        const newContact = {
                            vendorContactUid: contact.vendor_contact_id,
                            fullName: contact.fullName,
                            primary_Email: contact.email,
                            emails: [],
                            phoneNumbers: [],
                            tags: [],
                            sellerEmails: [
                                {
                                    email: contact.email,
                                    visible: true,
                                },
                            ],
                            buyerEmails: [],
                            sellerContacts: [
                                {
                                    number: contact.phone_numbers[0],
                                    visible: true,
                                },
                            ],
                            buyerContacts: [],
                            userUid: '',
                            isPrimary: false,
                            invitationStatus: 'INVITED',
                            notes: '',
                            CCEmail: contact.CCEmails,
                            secondaryEmails: contact.secondaryEmails,
                        };
                        newContacts.push(newContact);
                        return newContacts;
                    });
                    setSelectedContacts((prev) => [
                        ...prev,
                        contact.vendor_contact_id,
                    ]);
                }}
                portal="VENDOR_MASTER"
            />
        </>
    );
};

export default ManageVendorContacts;
