import { Box, IconButton } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { FWAccordion, FWAccordionSummary } from '../../../Common/FWAccordian';
import { FWTooltip } from '../../../Common/FWCustomTooltip';
import { FWTypography } from '../../../Common/FWTypograhy';
import { IPurchaseOrderItem } from '../../../Models/AwardsAndPO.model';
import { IMeasurementUnitDetails } from '../../../Models/MeasurementUnit.model';
import { IGlobalAppStore } from '../../../Redux/Store';
import BlankItemTemplate from '../../Components/Buyer/BlankItemDetailsTemplate';
import BlankItemSelectionTemplate from '../../Components/Buyer/BlankItemSelectionTemplate';
import useQuery from '../../Components/Shared/QueryHook';
import useAddBuyerMasterItemHook from '../../Hooks/Buyer/AddBuyerMasterItemHook';
import {
    IBuyerItemList,
    ICustomEventItemDetails,
} from '../../Interfaces/Buyer/RFQ.model';
import { RFQ_ITEM_PER_PAGE } from '../../Services/Buyer/RFQ.service';
import { useGetAllIncotermsQuery } from '../../Services/Buyer/shared.service';
import BomSelectionPopup from './BomContainers/BomSelectionPopup';

interface IAddBuyerMasterItemContainer {
    buyerMasterItemList: IBuyerItemList[];
    hasMoreItemsinList: boolean;

    type: 'FROM_EVENT' | 'DIRECT';

    onSuccess: (
        new_item_event_entry_id: string,
        last_po_details_of_item: IPurchaseOrderItem | null,
        measurement_units: IMeasurementUnitDetails[],
        newItem: ICustomEventItemDetails
    ) => void;
    item_count: number;
    openAddItemContainer: boolean;
    setOpenAddItemContainer: (open: boolean) => void;
    latestItemIndexAddedInThisSession: number | null;
    setLatestItemIndexAddedInThisSession: (new_index: number | null) => void;
    entity_id: string;
    onShowMoreItems: () => void;
    onSearchItemList: (search_text: string) => void;
    fromPoRevise?: boolean;
}

export const itemIsDisabled = (item: IBuyerItemList) => {
    return (
        item.status === 'INACTIVE' || item.enterprise_item.status === 'INACTIVE'
    );
};

export default function AddBuyerMasterItemContainer({
    buyerMasterItemList,
    hasMoreItemsinList,
    type,
    onSuccess,
    item_count,
    openAddItemContainer,
    setOpenAddItemContainer,
    latestItemIndexAddedInThisSession,
    setLatestItemIndexAddedInThisSession,
    entity_id,
    onShowMoreItems,
    onSearchItemList,
    fromPoRevise = false,
}: IAddBuyerMasterItemContainer) {
    const { data: incoterms = [] } = useGetAllIncotermsQuery({});

    const handleClose = () => setOpenAddItemContainer(false);
    let query = useQuery();
    const OPEN_ADD_ITEM_BOX = query.get('action') === 'add_item';
    const {
        fetchAndLoadSelectedItemDetails,
        dataState,
        showBomPopup,
        setShowBomPopup,
    } = useAddBuyerMasterItemHook(
        handleClose,
        incoterms,
        type,
        onSuccess,
        entity_id
    );

    // open the add item box as per conditions
    const currentPage = useSelector(
        (store: IGlobalAppStore) => store.EventCreationStore.currentPage
    );
    const searchQuery = useSelector(
        (store: IGlobalAppStore) => store.EventCreationStore.searchQuery
    );
    const filteringType = useSelector(
        (store: IGlobalAppStore) => store.EventCreationStore.filteringType
    );
    React.useEffect(() => {
        if (OPEN_ADD_ITEM_BOX) {
            setOpenAddItemContainer(true);
        } else if (
            item_count === 0 &&
            searchQuery.trim().length === 0 &&
            filteringType === 'ALL_ITEMS' &&
            currentPage === 1
        ) {
            setOpenAddItemContainer(true);
        } else if (latestItemIndexAddedInThisSession === null) {
            setOpenAddItemContainer(false);
        }
    }, [
        OPEN_ADD_ITEM_BOX,
        item_count,
        setLatestItemIndexAddedInThisSession,
        setOpenAddItemContainer,
        searchQuery,
        latestItemIndexAddedInThisSession,
        filteringType,
        currentPage,
    ]);

    // scroll to the bottom of the page i.e. the add item box
    React.useLayoutEffect(() => {
        if (OPEN_ADD_ITEM_BOX) window.scrollTo(0, document.body.scrollHeight);
    }, [OPEN_ADD_ITEM_BOX]);

    const animationType = dataState.loading ? 'wave' : false;

    if (openAddItemContainer) {
        return (
            <Box width={'100%'}>
                <FWAccordion expanded={false}>
                    <FWAccordionSummary
                        expandIcon={<></>}
                        sx={{
                            '&.Mui-focusVisible': {
                                backgroundColor: 'white',
                            },
                        }}
                    >
                        <Box width="100%">
                            <Box
                                marginLeft={'1rem'}
                                className="flex flex--aic flex--jcsb"
                                height={'3rem'}
                            >
                                <BlankItemSelectionTemplate
                                    sortedBuyerMasterItemList={
                                        buyerMasterItemList
                                    }
                                    hasMoreItemsinList={hasMoreItemsinList}
                                    onShowMoreItems={onShowMoreItems}
                                    onSearchItemList={onSearchItemList}
                                    openAddItemContainer={openAddItemContainer}
                                    item_count={
                                        item_count +
                                        (currentPage - 1) * RFQ_ITEM_PER_PAGE
                                    }
                                    fetchAndLoadSelectedItemDetails={
                                        fetchAndLoadSelectedItemDetails
                                    }
                                    disabled={dataState.loading}
                                    entity_id={entity_id}
                                    setLatestItemIndexAddedInThisSession={
                                        setLatestItemIndexAddedInThisSession
                                    }
                                />
                                <Box className="flex flex--aic">
                                    <FWTooltip title="Cancel">
                                        <Box>
                                            <IconButton
                                                color="error"
                                                disabled={dataState.loading}
                                                sx={{ fontSize: '1.5rem' }}
                                                onClick={() => {
                                                    setOpenAddItemContainer(
                                                        false
                                                    );
                                                    setLatestItemIndexAddedInThisSession(
                                                        null
                                                    );
                                                }}
                                            >
                                                <i className="bi bi-trash3"></i>
                                            </IconButton>
                                        </Box>
                                    </FWTooltip>
                                    &nbsp;
                                    <IconButton disabled>
                                        <i className="bi bi-chevron-up"></i>
                                    </IconButton>
                                </Box>
                            </Box>
                            <br />
                            <BlankItemTemplate animation_type={animationType} />
                        </Box>
                    </FWAccordionSummary>
                </FWAccordion>
                {showBomPopup && showBomPopup.length > 0 && (
                    <BomSelectionPopup
                        finishedGoodId={showBomPopup}
                        setShowBomPopup={setShowBomPopup}
                        handleSelection={() => {}}
                        fetchAndLoadSelectedItemDetails={
                            fetchAndLoadSelectedItemDetails
                        }
                    />
                )}
            </Box>
        );
    } else if (item_count === 0) {
        return (
            <Box marginTop={'-2rem'} textAlign={'center'} margin={'auto'}>
                <FWTypography color="text.secondary" textAlign={'center'}>
                    No items found{' '}
                    {filteringType === 'ITEMS_WITH_ERRORS'
                        ? 'with errors'
                        : filteringType === 'ITEMS_WITH_WARNINGS'
                        ? 'with warnings'
                        : ''}
                </FWTypography>
            </Box>
        );
    } else return <></>;
}
