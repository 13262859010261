export enum CustomTemplateTypes {
    GRN_CHECKLIST = 'GRN_CHECKLIST',
    QC_CHECKLIST = 'QC_CHECKLIST',
    RFQ_ITEM_CUSTOM_FIELDS = 'RFQ_ITEM_CUSTOM_FIELDS',
    INVOICE_BATCH_ITEM_CUSTOM_FIELDS = 'INVOICE_BATCH_ITEM_CUSTOM_FIELDS',
    GRN_CUSTOM_FIELDS = 'GRN_CUSTOM_FIELDS',
    QC_CUSTOM_FIELDS = 'QC_CUSTOM_FIELDS',
}

export enum CustomTemplateItemTypes {
    SHORTTEXT = 'SHORTTEXT',
    LONGTEXT = 'LONGTEXT',
    FLOAT = 'FLOAT',
    CURRENCY = 'CURRENCY',
    INTEGER = 'INTEGER',
    CHOICE = 'CHOICE',
    MULTI_CHOICE = 'MULTI_CHOICE',
    BOOLEAN = 'BOOLEAN',
    LOCATION = 'LOCATION',
    DATE = 'DATE',
    DATETIME = 'DATETIME',
}

// auto_reminders: {
//         is_remainder_required: boolean;
//         initial_reminder_hours: number;
//         reminder_interval: number;
//         max_reminders: number;
//     };
export interface ICustomTemplateList {
    template_id: string;
    template_name: string;
    type: CustomTemplateTypes;
    enterprise: string;
    entity: string;
    items: {
        template_item_id: string;
        template_item_name: string;
        template_item_type: CustomTemplateItemTypes;
        is_required: boolean;
        template: string;
        additional_information: {
            is_negotiable?: boolean;
            is_visible?: boolean;
        };
    }[];
}
