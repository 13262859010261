import { VerificationStatus } from '../../Redux/Slices/VerificationSlice';

export const verificationStatusMap: {
    [key: string]: string;
} = {
    [VerificationStatus.PENDING]: 'Verification Pending',
    [VerificationStatus.VERIFIED]: 'Verified',
    [VerificationStatus.UNVERIFIED]: 'Unverified',
};

export const verificationStatusMapService: {
    [key: string]: VerificationStatus;
} = {
    PENDING: VerificationStatus.PENDING,
    VERIFIED: VerificationStatus.VERIFIED,
    UNVERIFIED: VerificationStatus.UNVERIFIED,
};
