import { Divider, Grid } from '@mui/material';
import { isUndefined } from 'lodash';
import { useState } from 'react';
import { FWLink } from '../../Common/FWLink';
import FWPopover from '../../Common/FWPopover';
import { FWTypography } from '../../Common/FWTypograhy';
import { FWNumberFormatter } from '../../Common/Utils/CurrencyUtils';
import ItemRejectionDetails from './ItemRejectionDetails';

export interface IPendingAmountData {
    totalAmount: number;
    amountPending: number;
    rejectedAmount: number;
    grRejectedAmount?: number;
    qcRejectedAmount?: number;
    onHoldTerminationAmount: number;
    creditedAmount: number;
    amountPaid: number;
    currency: string;
    invoiceItemUid?: string;
    totalInvoicedQty?: string;
    measurementUnit: string;
    buyerEntityId: string;
}

interface IPendingAmountPopoverProps {
    data: IPendingAmountData;
    anchorEl: any;
    setAnchorEl: any;
    isMakePaymentPage?: boolean;
    onClose?: () => void;
}

const PendingAmountPopover = ({
    data,
    anchorEl,
    setAnchorEl,
    isMakePaymentPage,
    onClose,
}: IPendingAmountPopoverProps) => {
    const [showMoreDetails, setShowMoreDetails] = useState<boolean>(false);

    const open = Boolean(anchorEl);
    const handleClose = () => {
        setAnchorEl(null);
        setShowMoreDetails(false);
        if (onClose) {
            onClose();
        }
    };

    return (
        <FWPopover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'center',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'center',
                horizontal: 'right',
            }}
            PaperProps={{
                sx: {
                    minWidth:
                        isMakePaymentPage && showMoreDetails
                            ? '750px'
                            : undefined,
                    maxHeight: '500px',
                },
            }}
        >
            <div
                style={{
                    width:
                        isMakePaymentPage && showMoreDetails
                            ? '750px'
                            : '450px',
                }}
            >
                {isMakePaymentPage &&
                    data.totalInvoicedQty &&
                    data.measurementUnit && (
                        <div>
                            <Grid
                                container
                                columnSpacing={1}
                                rowSpacing={1}
                                style={{
                                    padding: '8px',
                                }}
                                alignItems="center"
                            >
                                <Grid item xs={6}>
                                    <FWTypography color="text.secondary">
                                        Total invoiced quantity
                                    </FWTypography>
                                </Grid>
                                <Grid item xs={6}>
                                    <FWTypography textAlign="right">
                                        {data.totalInvoicedQty}{' '}
                                        {data.measurementUnit}(s)
                                    </FWTypography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider />
                                </Grid>
                            </Grid>
                        </div>
                    )}
                <div>
                    <Grid
                        container
                        columnSpacing={1}
                        rowSpacing={1}
                        style={{
                            padding: '8px',
                        }}
                        alignItems="center"
                    >
                        <Grid item xs={6}>
                            <FWTypography color="text.secondary">
                                Total amount
                            </FWTypography>
                        </Grid>
                        <Grid item xs={6}>
                            <FWTypography textAlign="right">
                                {FWNumberFormatter(
                                    data.totalAmount,
                                    data.currency
                                )}
                            </FWTypography>
                        </Grid>

                        <Grid item xs={12}>
                            <Divider />
                        </Grid>

                        {data.rejectedAmount > 0 &&
                            isUndefined(data.grRejectedAmount) &&
                            isUndefined(data.qcRejectedAmount) && (
                                <>
                                    <Grid item xs={6}>
                                        <FWTypography color="text.secondary">
                                            Rejected amount
                                        </FWTypography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FWTypography textAlign="right">
                                            -{' '}
                                            {FWNumberFormatter(
                                                data.rejectedAmount,
                                                data.currency
                                            )}
                                        </FWTypography>
                                    </Grid>
                                </>
                            )}

                        {!isUndefined(data.grRejectedAmount) &&
                            data.grRejectedAmount > 0 && (
                                <>
                                    <Grid item xs={6}>
                                        <FWTypography color="text.secondary">
                                            GR rejected amount
                                        </FWTypography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FWTypography textAlign="right">
                                            -{' '}
                                            {FWNumberFormatter(
                                                data.grRejectedAmount,
                                                data.currency
                                            )}
                                        </FWTypography>
                                    </Grid>
                                </>
                            )}

                        {!isUndefined(data.qcRejectedAmount) &&
                            data.qcRejectedAmount > 0 && (
                                <>
                                    <Grid item xs={6}>
                                        <FWTypography color="text.secondary">
                                            QC rejected amount
                                        </FWTypography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FWTypography textAlign="right">
                                            -{' '}
                                            {FWNumberFormatter(
                                                data.qcRejectedAmount,
                                                data.currency
                                            )}
                                        </FWTypography>
                                    </Grid>
                                </>
                            )}

                        {data.onHoldTerminationAmount > 0 && (
                            <>
                                <Grid item xs={6}>
                                    <FWTypography color="text.secondary">
                                        On hold/termination
                                    </FWTypography>
                                </Grid>
                                <Grid item xs={6}>
                                    <FWTypography textAlign="right">
                                        -{' '}
                                        {FWNumberFormatter(
                                            data.onHoldTerminationAmount,
                                            data.currency
                                        )}
                                    </FWTypography>
                                </Grid>
                            </>
                        )}

                        <Grid item xs={6}>
                            <FWTypography color="text.secondary">
                                Amount paid
                            </FWTypography>
                        </Grid>
                        <Grid item xs={6}>
                            <FWTypography textAlign="right">
                                -{' '}
                                {FWNumberFormatter(
                                    data.amountPaid,
                                    data.currency
                                )}
                            </FWTypography>
                        </Grid>

                        {data.creditedAmount > 0 && (
                            <>
                                <Grid item xs={6}>
                                    <FWTypography color="text.secondary">
                                        Credit amount
                                    </FWTypography>
                                </Grid>
                                <Grid item xs={6}>
                                    <FWTypography textAlign="right">
                                        +{' '}
                                        {FWNumberFormatter(
                                            data.creditedAmount,
                                            data.currency
                                        )}
                                    </FWTypography>
                                </Grid>
                            </>
                        )}

                        <Grid item xs={12}>
                            <Divider />
                        </Grid>

                        <Grid item xs={6}>
                            <FWTypography color="text.secondary">
                                Pending amount
                            </FWTypography>
                        </Grid>
                        <Grid item xs={6}>
                            <FWTypography textAlign="right">
                                {FWNumberFormatter(
                                    data.amountPending < 0
                                        ? 0
                                        : data.amountPending,
                                    data.currency
                                )}
                            </FWTypography>
                        </Grid>
                    </Grid>
                </div>
            </div>
            <div
                style={{
                    width:
                        isMakePaymentPage && showMoreDetails
                            ? '750px'
                            : '450px',
                }}
            >
                {isMakePaymentPage && (
                    <Grid
                        container
                        style={{
                            padding: '8px',
                        }}
                    >
                        {showMoreDetails && data.invoiceItemUid && (
                            <Grid item xs={12}>
                                <ItemRejectionDetails
                                    invoiceItemUid={data.invoiceItemUid}
                                    measurementUnitName={data.measurementUnit}
                                    currentPortal={'BUYER'}
                                    buyerEntityId={data.buyerEntityId}
                                />
                            </Grid>
                        )}
                        {!showMoreDetails && (
                            <Grid
                                item
                                xs={12}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                }}
                            >
                                <FWLink
                                    color="primary.main"
                                    onClick={() => {
                                        setShowMoreDetails(!showMoreDetails);
                                    }}
                                >
                                    {showMoreDetails
                                        ? 'View less details'
                                        : 'View more details'}
                                </FWLink>
                            </Grid>
                        )}
                    </Grid>
                )}
            </div>
        </FWPopover>
    );
};

export default PendingAmountPopover;
