import React from 'react';
import { IVLNAboutCustomSection } from '../../../Interfaces/VendorLNInterfaces';
import { FWTypography } from '../../../../Common/FWTypograhy';
import DisplayLink from '../../Common/DisplayLink';
import DisplayAttachment from '../../Common/DisplayAttachment';
import { Box, Grid, Typography } from '@mui/material';

const VLNProfileAboutCustomSectionItem = ({
    customSectionData,
}: {
    customSectionData: IVLNAboutCustomSection;
}) => {
    return (
        <Box
            borderRadius={1}
            border={'1px solid lightgray'}
            padding={2}
            marginBottom={2}
        >
            <FWTypography
                variant={'h5'}
                fontSize={'1.3rem'}
                marginBottom={0.5}
                sx={{ textTransform: 'capitalize' }}
            >
                {customSectionData.name}
            </FWTypography>
            <FWTypography fontWeight={500} marginBottom={0.5}>
                Notes
            </FWTypography>
            <Typography>{customSectionData.body}</Typography>
            {/* &nbsp; */}
            {customSectionData.links.length > 0 && (
                <>
                    &nbsp;
                    <FWTypography fontWeight={500} marginBottom={0.5}>
                        Links
                    </FWTypography>
                    <Grid container spacing={2}>
                        {customSectionData.links.map((item, idx) => (
                            <Grid key={idx} item xs={12} sm={6} md={4}>
                                <DisplayLink link={item.url} name={item.name} />
                            </Grid>
                        ))}
                    </Grid>
                </>
            )}
            {customSectionData.files.length > 0 && (
                <>
                    &nbsp;
                    <FWTypography fontWeight={500} marginBottom={0.5}>
                        Documents
                    </FWTypography>
                    <Grid container spacing={2}>
                        {customSectionData.files.map((item, idx) => (
                            <Grid key={idx} item xs={12} sm={6} md={4}>
                                <DisplayAttachment
                                    attachment_id={item.attachment_id}
                                    file_name={item.file_name}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </>
            )}
            &nbsp;
        </Box>
    );
};

export default VLNProfileAboutCustomSectionItem;
