import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    LineElement,
    PointElement,
    ChartOptions,
    ChartData,
} from 'chart.js';
import { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { ChartLocaleMap } from '../../../Constants/ChartLocaleMap';
import { graphColor } from '../../../Constants/HAGraphColor';
import { DynamicAnalyticsVendorPoTypesOverTimeData } from '../../../Interfaces/DynamicAnalyticsInterfaces.model';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { max } from 'lodash';
import {
    getStepSize,
    numberToAbbreviation,
} from '../../../helperFunctions/chartHelpers';
import { getFWDate } from '../../../../Common/Utils/DateUtils';

ChartJS.register(
    CategoryScale,
    LinearScale,
    LineElement,
    PointElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels
);

export default function POTypesOverTimeGraph({
    visibleData,
    number_format,
    // sellerEntityName,
    currencyCodeSymbol,
}: {
    visibleData: DynamicAnalyticsVendorPoTypesOverTimeData[];
    number_format: string;
    // sellerEntityName: string;
    currencyCodeSymbol: string;
}) {
    const [graphData, setGraphData] = useState<{
        xLabels: string[];
        bar1Data: (number | null)[];
        bar2Data: (number | null)[];
    }>({
        xLabels: [],
        bar1Data: [],
        bar2Data: [],
    });

    const [currencyUnit, setCurrencyUnit] = useState<string | null>(null);

    useEffect(() => {
        let xLabels: string[] = [];
        let bar1Data: (number | null)[] = [];
        let bar2Data: (number | null)[] = [];
        visibleData.forEach((item) => {
            xLabels.push(
                getFWDate(
                    `${item.period.month}-1-${item.period.year}`,
                    "MMM 'YY"
                )
            );
            bar1Data.push(item.rfqPoCount);
            bar2Data.push(item.directPoCount);
        });
        const maxVal1 = max([
            ...bar1Data.map((val, i) => val || 0).filter((val) => val !== null),
            ...bar2Data.map((val, i) => val || 0).filter((val) => val !== null),
        ]);
        // const maxVal2 = max(
        //     bar2Data.map((val, i) => val || 0).filter((val) => val !== null)
        // );

        const {
            abbrevitedNumberArr: abbrevitedNumberArr1,
            currencyUnit: currencyUnit1,
        } = numberToAbbreviation(
            maxVal1 || 0,
            bar1Data,
            number_format === 'EUR' ? 'USD' : (number_format as 'USD' | 'INR')
        );
        const { abbrevitedNumberArr: abbrevitedNumberArr2 } =
            numberToAbbreviation(
                maxVal1 || 0,
                bar2Data,
                number_format === 'EUR'
                    ? 'USD'
                    : (number_format as 'USD' | 'INR')
            );

        setCurrencyUnit(currencyUnit1);
        bar1Data = abbrevitedNumberArr1;
        bar2Data = abbrevitedNumberArr2;

        setGraphData({
            xLabels,
            bar1Data,
            bar2Data,
        });
    }, [number_format, visibleData]);

    var stepSize = getStepSize(
        max(
            graphData.bar1Data
                .map((val, i) => (val || 0) + (graphData.bar2Data[i] || 0))
                .filter((val) => val !== null)
        ) || 0,
        6
    );

    const options: ChartOptions<any> = {
        locale:
            ChartLocaleMap[number_format as 'INR' | 'USD' | 'EUR'] || 'en-US',
        responsive: true,
        plugins: {
            // legend: {
            //     position: 'top' as const,
            // },
            datalabels: {
                display: 'auto',
                align: 'top',
                anchor: 'end',
                clip: true,
                clamp: true,
                color: '#003F91',
            },
        },
        scales: {
            y: {
                grace: '30%',
                // stacked: true,
                type: 'linear',
                display: true,
                position: 'left',
                title: {
                    display: true,
                    text: `PO count ${
                        currencyUnit ? `(In ${currencyUnit})` : ''
                    }`,
                },
                // max: graphMaxVal([
                //     ...graphData.bar1Data,
                //     ...graphData.bar2Data,
                // ]),
                min: 0,
                ticks: {
                    stepSize: stepSize,
                    // callback: function (
                    //     val: string | number,
                    //     index: number
                    // ): string {
                    //     return typeof val === 'number'
                    //         ? `${currencyCodeSymbol}${(
                    //               this as any
                    //           ).getLabelForValue(val)}`
                    //         : '';
                    // },
                },
            },
            x: {
                // stacked: true,
                title: {
                    display: true,
                    text: 'Months',
                },
                grid: {
                    display: false, // hide the vertical grid lines for the x-axis
                },
                ticks: {
                    callback: function (
                        val: string | number,
                        index: number
                    ): string {
                        return typeof val === 'number'
                            ? (this as any)
                                  .getLabelForValue(val)
                                  .substring(0, 10)
                            : '';
                    },
                },
            },
        },
    };

    const labels: any[] = graphData.xLabels;

    const data: ChartData<any> = {
        labels,
        datasets: [
            {
                label: `POs from RFQ count`,
                // data: graphData.bar1Data,
                data: graphData.bar1Data,
                type: 'bar',
                backgroundColor: graphColor[0],
                borderWidth: 0,
                // stack: 'stack1',
                // datalabels: {
                //     labels: {
                //         title: null, //hides datalabel for this metric
                //     },
                // },
                maxBarThickness: 80,
            },
            {
                label: 'Direct PO count',

                data: graphData.bar2Data,
                // data: [110, 115],
                type: 'bar',
                backgroundColor: graphColor[1],
                borderWidth: 0,
                // stack: 'stack1',
                // datalabels: {
                //     labels: {
                //         title: null,
                //     },
                // },
                maxBarThickness: 80,
            },
        ],
    };
    return <Bar options={options} data={data} plugins={[ChartDataLabels]} />;
}
