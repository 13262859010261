/*
    This file contains hardcoded data that are used in some places
    to show icons for items and vendors for SK's demo.
    THIS FILE IS MADE FOR ETERNITY .
*/

interface ISomeInterface {
    [key: string]: {
        type: string;
        icon: string[];
        iconValues: (number | null)[];
        iconTooltips: string[];
        isPopup: boolean[];
        iconColors: string[];
    };
}

export const vendorAndItemIconMap: ISomeInterface = {
    'c8e4d15a-9294-45e1-8282-0f51d6df924c': {
        type: 'vendor',
        icon: ['bi bi-star-fill'],
        iconValues: [4.5],
        iconTooltips: [''],
        isPopup: [false],
        iconColors: ['warning.main'],
    },
    '88b96b6b-7abf-43d3-813e-63651bb5c6ff': {
        type: 'vendor',
        icon: ['bi bi-star-fill'],
        iconValues: [3.9],
        iconTooltips: [''],
        isPopup: [false],
        iconColors: ['warning.main'],
    },
    '27c6b697-1624-4418-bc50-430b8d87255f': {
        type: 'vendor',
        icon: ['bi bi-star-fill', 'bi bi-clipboard-check'],
        iconValues: [4.1, null],
        iconTooltips: ['', 'Vendor contract exists'],
        isPopup: [false, false],
        iconColors: ['warning.main', 'primary.main'],
    },
    '41fbb6fe-64f7-4481-91c7-72a62fab2aaa': {
        type: 'vendor',
        icon: ['bi bi-star-fill'],
        iconValues: [3.7],
        iconTooltips: [''],
        isPopup: [false],
        iconColors: ['warning.main'],
    },
    '23b69470-7cfd-44cf-8879-b55ef7e523cd': {
        type: 'vendor',
        icon: ['bi bi-star-fill'],
        iconValues: [4.0],
        iconTooltips: [''],
        isPopup: [false],
        iconColors: ['warning.main'],
    },
    '17ca2abd-9e82-4134-bedb-8b71212ba157': {
        type: 'vendor',
        icon: ['bi bi-star-fill'],
        iconValues: [3.8],
        iconTooltips: [''],
        isPopup: [false],
        iconColors: ['warning.main'],
    },
    '1acf851a-903c-479b-a345-0a934f12dd14': {
        type: 'vendor',
        icon: ['bi bi-star-fill'],
        iconValues: [4.3],
        iconTooltips: [''],
        isPopup: [false],
        iconColors: ['warning.main'],
    },
    '8dcd18be-dd37-405e-bd88-30f9a16108b9': {
        type: 'vendor',
        icon: ['bi bi-star-fill'],
        iconValues: [3.8],
        iconTooltips: [''],
        isPopup: [false],
        iconColors: ['warning.main'],
    },
    'fe7b74a1-7bd7-4445-a250-8b6ced759b38': {
        type: 'vendor',
        icon: ['bi bi-star-fill'],
        iconValues: [4.2],
        iconTooltips: [''],
        isPopup: [false],
        iconColors: ['warning.main'],
    },
    '67ceb8ff-f01f-415b-8449-47100668ef27': {
        type: 'vendor',
        icon: ['bi bi-star-fill', 'bi bi-map'],
        iconValues: [4.3, null],
        iconTooltips: ['', 'Vendor catalogue derived from apptricity'],
        isPopup: [false, false],
        iconColors: ['warning.main', 'primary.main'],
    },
    '8769a85d-892b-4914-ae1c-7d060c8e17cb': {
        type: 'vendor',
        icon: ['bi bi-star-fill'],
        iconValues: [4.7],
        iconTooltips: [''],
        isPopup: [false],
        iconColors: ['warning.main'],
    },
    '112042b2-fd3f-48b6-bc30-a9572be7b20b': {
        type: 'vendor',
        icon: ['bi bi-star-fill'],
        iconValues: [3.9],
        iconTooltips: [''],
        isPopup: [false],
        iconColors: ['warning.main'],
    },
    'c815bf26-cb47-47c2-ae24-972254cc623e': {
        type: 'vendor',
        icon: ['bi bi-star-fill'],
        iconValues: [4.0],
        iconTooltips: [''],
        isPopup: [false],
        iconColors: ['warning.main'],
    },
    '01beb07f-86de-43fc-9eb8-3a81765a8c25': {
        type: 'item',
        icon: [''],
        iconValues: [null],
        iconTooltips: ['Item in inventory'],
        isPopup: [true],
        iconColors: ['primary.main'],
    },
    '87104263-7c5e-4e64-82d2-e466d187b690': {
        type: 'item',
        icon: ['', 'bi bi-map'],
        iconValues: [null, null],
        iconTooltips: ['Item in inventory', 'Item in catalogue'],
        isPopup: [true, false],
        iconColors: ['primary.main', 'primary.main'],
    },
    '9f683d77-ca3d-41f2-9210-7443f5d92485': {
        type: 'item',
        icon: ['', 'bi bi-map'],
        iconValues: [null, null],
        iconTooltips: ['Item in inventory', 'Item in catalogue'],
        isPopup: [true, false],
        iconColors: ['primary.main', 'primary.main'],
    },
    '1ae74742-6654-4067-89ee-e19e35749c11': {
        type: 'item',
        icon: [''],
        iconValues: [null],
        iconTooltips: ['Item in inventory'],
        isPopup: [true],
        iconColors: ['primary.main'],
    },
    '7ddcc8f2-4d32-43de-b2bf-14ee7cd4380e': {
        type: 'item',
        icon: ['bi bi-clipboard-check', ''],
        iconValues: [null, null],
        iconTooltips: ['Contract item', 'Item in inventory'],
        isPopup: [false, true],
        iconColors: ['primary.main', 'primary.main'],
    },
    'de548b23-b186-42bd-bdca-d24531dd59c6': {
        type: 'item',
        icon: ['bi bi-map'],
        iconValues: [null],
        iconTooltips: ['Item in catalogue'],
        isPopup: [false],
        iconColors: ['primary.main'],
    },
    '69727c3e-4fae-43dd-8b63-67444fbe75e6': {
        type: 'item',
        icon: ['', 'bi bi-map'],
        iconValues: [null, null],
        iconTooltips: ['Item in inventory', 'Item in catalogue'],
        isPopup: [true, false],
        iconColors: ['primary.main', 'primary.main'],
    },
    '25a16f31-4702-4294-8912-13675b8cc43d': {
        type: 'item',
        icon: [''],
        iconValues: [null],
        iconTooltips: ['Item in inventory'],
        isPopup: [true],
        iconColors: ['primary.main'],
    },
    '91ea773f-9baf-42d8-b485-7a43dd1e405f': {
        type: 'item',
        icon: [''],
        iconValues: [null],
        iconTooltips: ['Item in inventory'],
        isPopup: [true],
        iconColors: ['primary.main'],
    },
};
