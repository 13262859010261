import { Box, Chip } from '@mui/material';
import { FWInput } from '../../../Common/FWInput';
import {
    ItemCartModuleEnumMapping,
    ItemCartModuleNameMapping,
    ItemCartModuleSectionEnum,
} from '../../Interfaces/ItemAnalyticsInterfaces';

export interface IItemCartModuleChipsProps {
    currentSection: string;
    updateSection: (newSection: ItemCartModuleSectionEnum) => void;
    updateSearchTerm: (newSearch: string) => void;
    showEntityFilter?: boolean;
    allowPositionSticky?: boolean;
}

export const ItemCartModuleChips = ({
    currentSection,
    updateSearchTerm,
    updateSection,
    showEntityFilter,
    allowPositionSticky = true,
}: IItemCartModuleChipsProps) => {
    const cartSectionName = Object.values(ItemCartModuleNameMapping);

    return (
        <Box
            className={'flex flex--aic flex--jcsb'}
            gap={2}
            position={allowPositionSticky ? 'sticky' : 'unset'}
            top={'121px'}
            width={'100%'}
            bgcolor={'white'}
            padding={4}
            paddingTop={0}
            paddingBottom={1}
            zIndex={allowPositionSticky ? 10 : 0}
        >
            <Box className={'flex flex--aic'} gap={2}>
                {cartSectionName.map((section, index) => (
                    <Chip
                        key={index}
                        label={section}
                        color={
                            currentSection === section ? 'primary' : 'default'
                        }
                        onClick={() =>
                            updateSection(ItemCartModuleEnumMapping[section])
                        }
                    />
                ))}
            </Box>

            <Box className={'flex flex--aic'} gap={2}>
                <FWInput
                    // value={searchTerm}
                    onChange={(e: any) => {
                        updateSearchTerm(e.target.value);
                    }}
                    placeholder={'Search'}
                    iconStart={<i className="bi bi-search" />}
                    style={{ width: 400 }}
                />
            </Box>
        </Box>
    );
};

export default ItemCartModuleChips;
