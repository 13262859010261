import { createContext } from 'react';
import { IItemAnalyticsPaginationContext } from '../../Events/Interfaces/Buyer/itemAnalytics.interface';

export const IAPaginationContext =
    createContext<IItemAnalyticsPaginationContext>({
        currentPage: 1,
        limitItemPerPage: 10,
        totalPages: 1,
        onPageChange: () => {},
    });
