import { IServiceRowMainDV } from './MainDataViewsService';

import {
    AllocationTypeEnum,
    CostTypeEnum,
    IAdditionalCostsBackend,
    IAdditionalCostsWithValue,
} from '../../../AdditionalCost/models/AdditionalCost.model';
import {
    ICostingTemplate,
    TemplateStandardFieldsEnum,
} from '../../../Costing/Interfaces/CostingTemplate.interface';
import { convertRfqAdditionalCostToQouteCalculatorAdditionalCost } from '../../../Costing/Misc/helperFunctions';
import { CustomTemplateItemTypes } from '../../../Global/Interfaces/CustomTemplate.interface';
import { IRfqTemplate } from '../../../Global/Interfaces/TemplateInterface';
import { convertAdditionalCostInterface } from '../../../Organizations/Admin/Helpers/ItemHelpers';
// import { IItemDetails } from '../../../Organizations/Admin/Interfaces/ItemDirectoryInterface';
// import { fetchItemDetails } from '../../../Organizations/Admin/Services/ItemDirectoryService';
import { IAdditionalCost } from '../../../Organizations/Admin/Services/VendorsService';
import { get, post } from '../../../Utils/api';
import { convertTemplateTypeToCustomFieldItemTemplate } from '../../Constants/CustomFieldHelpers';
import { IEventItemCustomFieldsBackend } from '../../Interfaces/Buyer/RFQ.model';

interface ICostingItemPayloadForExportToQoute {
    enterprise_item: string;
    enterprise_bom: null;
    costing_sheet_item_boms: any[];
    quantity: number;
    rate: number;
    notes: string | null;
    additional_costs: {
        cost_name: string;
        cost_type: CostTypeEnum;
        cost_allocation: AllocationTypeEnum | null;
        cost_value: string;
    }[];
    custom_fields: IEventItemCustomFieldsBackend;
    custom_fields_negotiate: IEventItemCustomFieldsBackend;
    vendor_entity: string;
}

export interface IGetResponseForMultipleItemAdditionalCost {
    enterprise_item_id: string;
    buyer_additional_costs: Array<IAdditionalCostsBackend>;
    buyer_pricing_information: {
        price: number;
        taxes: any;
        currency_name: string;
        currency_symbol: string;
        additional_costs: any;
        currency_code_id: string;
        currency_code_abbreviation: string;
    };
    seller_additional_costs: Array<IAdditionalCostsBackend>;
    seller_pricing_information: {
        price: number;
        taxes: any;
        currency_name: string;
        currency_symbol: string;
        additional_costs: any;
        currency_code_id: string;
        currency_code_abbreviation: string;
    };
}

export interface IFetchMultipleSellerAdditionalCost {
    enterprise_vendor_master_id: string;
    buyer_enterprise: string;
    seller_enterprise: string;
    seller_entity: {
        entity_id: string;
        enterprise: string;
        entity_name: string;
        entity_logo: Array<any>;
        entity_description: string;
        buyer_status: string;
        seller_status: string;
        contacts: {
            emails: Array<any>;
            websites: Array<any>;
            phone_numbers: Array<any>;
        };
        verification_status: string;
        is_virtual: boolean;
    };
    vendor_code: string;
    vendor_name: string;
    status: string;
    seller_address_information: Array<string>;
    seller_identifications: Array<{
        identification_name: string;
        identification_value: string;
    }>;
    notes: any;
    tags: Array<string>;
    buyer_entity_count: number;
    primary_vendor_contact: {
        vendor_contact_id: string;
        buyer_entity: any;
        seller_entity: string;
        full_name: string;
        primary_email: string;
        emails: Array<any>;
        phone_numbers: Array<any>;
        tags: Array<any>;
        is_primary: boolean;
        user: string;
        status: string;
    };
    created_datetime: string;
    modified_datetime: string;
    custom_fields: {
        section_list: Array<{
            name: string;
            fields: Array<{
                name: string;
                type: string;
                value: any;
                is_locked: boolean;
                description: string;
                is_required: boolean;
            }>;
        }>;
    };
    additional_costs: Array<IAdditionalCost>;
}

export const fetchMultipleSellersAdditionalCosts = async (
    sellerEntityIds: string[]
) => {
    try {
        const resp = await get<IFetchMultipleSellerAdditionalCost[]>(
            `/organization/vendor_master/admin/?${sellerEntityIds
                .map((sellerEntityId) => `seller_entity_id=${sellerEntityId}`)
                .join('&')}`
        );

        return resp.data.reduce(
            (acc, vendorInformation) => ({
                ...acc,
                [vendorInformation.seller_entity.entity_id]:
                    vendorInformation.additional_costs.map(
                        (cost: IAdditionalCost) =>
                            ({
                                cost_name: cost.additional_cost.cost_name,
                                cost_type: cost.additional_cost.cost_type,
                                allocation_type:
                                    cost.additional_cost.allocation_type,
                                cost_source: cost.cost_source,
                                cost_value: cost.cost_value,
                                additional_cost_id:
                                    cost.additional_cost.additional_cost_id,
                            } as IAdditionalCostsBackend)
                    ),
            }),
            {} as { [key: string]: IAdditionalCostsBackend[] }
        );
    } catch (err) {
        throw err;
    }
};

// cost_name: cost.additional_cost.cost_name,
//                             cost_type: cost.additional_cost.cost_type,
//                             allocation_type: cost.additional_cost.allocation_type,
//                             cost_source: cost.cost_source,
//                             cost_value: cost.cost_value,
//                             additional_cost_id: cost.additional_cost.additional_cost_id,

export const fetchMultipleItemAdditionalCost = async (itemsid: string[]) => {
    try {
        const resp = await post<
            {
                item_ids: string;
            },
            IGetResponseForMultipleItemAdditionalCost[]
        >(`/organization/items/additional_costs/`, {
            item_ids: itemsid,
        });

        let data: {
            [item_id: string]: IGetResponseForMultipleItemAdditionalCost;
        } = {};

        resp.data.forEach((item) => {
            data[item.enterprise_item_id] = {
                ...item,
                seller_additional_costs: convertAdditionalCostInterface(
                    item.seller_additional_costs as any
                ),
                buyer_additional_costs: convertAdditionalCostInterface(
                    item.buyer_additional_costs as any
                ),
            };
        });

        return data;
    } catch (err) {
        throw err;
    }
};

export const createCostingItemsFromitemEventDetailsAndmatchingFieldsDetails: (
    bidinfo: {
        [rfqItemId: string]: IServiceRowMainDV[];
    },
    fieldsToCopy: {
        [TemplateStandardFieldsEnum.BASE_RATE]: boolean;
        [TemplateStandardFieldsEnum.QUANTITY]: boolean;
        [TemplateStandardFieldsEnum.ADDITIONAL_COSTS]: {
            [costName: string]: boolean;
        };
        custom_fields: {
            [customFieldName: string]: boolean;
        };
    },
    costingTemplateDetails: ICostingTemplate,
    rfqTemplateDetails: IRfqTemplate
) => Promise<ICostingItemPayloadForExportToQoute[]> = async (
    bidinfo,
    fieldsToCopy,
    costingTemplateDetails,
    rfqTemplateDetails
) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const listOfEnterpriseIds = Object.keys(bidinfo).map(
        (rfqid) => bidinfo[rfqid][0].rfqItem.itemInformation.globalItemId
    );

    let itemDetails:
        | {
              [item_id: string]: IGetResponseForMultipleItemAdditionalCost;
          }
        | undefined = undefined;
    try {
        itemDetails = await fetchMultipleItemAdditionalCost(
            listOfEnterpriseIds
        );
    } catch (err) {
        console.error(err);
    }

    const response: ICostingItemPayloadForExportToQoute[] = [];

    Object.keys(bidinfo).forEach((singleBidInfoKey) => {
        if (bidinfo[singleBidInfoKey].length > 0) {
            let additionCostForPayload: IAdditionalCostsWithValue[] = [];

            // filtering for additional cost based on fields selected and matching
            Object.keys(
                fieldsToCopy[TemplateStandardFieldsEnum.ADDITIONAL_COSTS]
            ).forEach((costName) => {
                if (
                    fieldsToCopy[TemplateStandardFieldsEnum.ADDITIONAL_COSTS][
                        costName
                    ]
                ) {
                    let foundAddtionalCost:
                        | IAdditionalCostsWithValue
                        | undefined = undefined;
                    //
                    // 3 rds priority autofilling additional cost from item directory from buyer side
                    if (
                        itemDetails &&
                        itemDetails[
                            bidinfo[singleBidInfoKey][0].rfqItem.itemInformation
                                .globalItemId
                        ] &&
                        itemDetails[
                            bidinfo[singleBidInfoKey][0].rfqItem.itemInformation
                                .globalItemId
                        ].buyer_additional_costs
                    ) {
                        const temp = itemDetails[
                            bidinfo[singleBidInfoKey][0].rfqItem.itemInformation
                                .globalItemId
                        ].buyer_additional_costs.find(
                            (c) => c.cost_name.trim() === costName.trim()
                        );
                        if (
                            temp &&
                            temp.cost_type === CostTypeEnum.PERCENTAGE
                        ) {
                            foundAddtionalCost = {
                                costName: temp.cost_name,
                                additional_cost_id: temp.additional_cost_id,
                                cost_source: temp.cost_source ?? null,
                                allocationType: null,
                                costType: temp.cost_type,
                                value: temp.cost_value,
                            };
                        } else if (
                            temp &&
                            temp.cost_type === CostTypeEnum.ABSOLUTE_VALUE
                        ) {
                            foundAddtionalCost = {
                                costName: temp.cost_name,
                                additional_cost_id: temp.additional_cost_id,
                                cost_source: temp.cost_source ?? null,
                                allocationType: temp.allocation_type!,
                                costType: temp.cost_type,
                                value: temp.cost_value,
                            };
                        }
                    }
                    // 2nd priority autofilling additional cost from item directory from buyer side
                    if (
                        itemDetails &&
                        itemDetails[
                            bidinfo[singleBidInfoKey][0].rfqItem.itemInformation
                                .globalItemId
                        ] &&
                        itemDetails[
                            bidinfo[singleBidInfoKey][0].rfqItem.itemInformation
                                .globalItemId
                        ].seller_additional_costs
                    ) {
                        const temp = itemDetails[
                            bidinfo[singleBidInfoKey][0].rfqItem.itemInformation
                                .globalItemId
                        ].seller_additional_costs.find(
                            (c) => c.cost_name.trim() === costName.trim()
                        );
                        if (
                            temp &&
                            temp.cost_type === CostTypeEnum.PERCENTAGE
                        ) {
                            foundAddtionalCost = {
                                costName: temp.cost_name,
                                additional_cost_id: temp.additional_cost_id,
                                cost_source: temp.cost_source ?? null,
                                allocationType: null,
                                costType: temp.cost_type,
                                value: temp.cost_value,
                            };
                        } else if (
                            temp &&
                            temp.cost_type === CostTypeEnum.ABSOLUTE_VALUE
                        ) {
                            foundAddtionalCost = {
                                costName: temp.cost_name,
                                additional_cost_id: temp.additional_cost_id,
                                cost_source: temp.cost_source ?? null,
                                allocationType: temp.allocation_type!,
                                costType: temp.cost_type,
                                value: temp.cost_value,
                            };
                        }
                    }

                    // 1st priority
                    foundAddtionalCost =
                        bidinfo[
                            singleBidInfoKey
                        ][0].pricingInformation.additionalCosts.find(
                            (cost) => cost.costName.trim() === costName.trim()
                        ) || foundAddtionalCost;

                    if (foundAddtionalCost)
                        additionCostForPayload.push(foundAddtionalCost);
                }
            });

            // fetching additional cost from item directory

            costingTemplateDetails.fieldSettings.standardFields[
                TemplateStandardFieldsEnum.ADDITIONAL_COSTS
            ].additionalCostOptions.forEach((cost) => {
                if (
                    additionCostForPayload.find(
                        (c) => c.costName.trim() === cost.costName.trim()
                    ) === undefined
                ) {
                    let foundAddtionalCost:
                        | IAdditionalCostsWithValue
                        | undefined = undefined;

                    // 3 rds priority autofilling additional cost from item directory from buyer side
                    if (
                        itemDetails &&
                        itemDetails[
                            bidinfo[singleBidInfoKey][0].rfqItem.itemInformation
                                .globalItemId
                        ] &&
                        itemDetails[
                            bidinfo[singleBidInfoKey][0].rfqItem.itemInformation
                                .globalItemId
                        ].buyer_additional_costs
                    ) {
                        const temp = itemDetails[
                            bidinfo[singleBidInfoKey][0].rfqItem.itemInformation
                                .globalItemId
                        ].buyer_additional_costs.find(
                            (c) => c.cost_name.trim() === cost.costName.trim()
                        );
                        if (
                            temp &&
                            temp.cost_type === CostTypeEnum.PERCENTAGE
                        ) {
                            foundAddtionalCost = {
                                costName: temp.cost_name,
                                additional_cost_id: temp.additional_cost_id,
                                cost_source: temp.cost_source ?? null,
                                allocationType: null,
                                costType: temp.cost_type,
                                value: temp.cost_value,
                            };
                        } else if (
                            temp &&
                            temp.cost_type === CostTypeEnum.ABSOLUTE_VALUE
                        ) {
                            foundAddtionalCost = {
                                costName: temp.cost_name,
                                additional_cost_id: temp.additional_cost_id,
                                cost_source: temp.cost_source ?? null,
                                allocationType: temp.allocation_type!,
                                costType: temp.cost_type,
                                value: temp.cost_value,
                            };
                        }
                    }
                    // 2nd priority autofilling additional cost from item directory from buyer side
                    if (
                        itemDetails &&
                        itemDetails[
                            bidinfo[singleBidInfoKey][0].rfqItem.itemInformation
                                .globalItemId
                        ] &&
                        itemDetails[
                            bidinfo[singleBidInfoKey][0].rfqItem.itemInformation
                                .globalItemId
                        ].seller_additional_costs
                    ) {
                        const temp = itemDetails[
                            bidinfo[singleBidInfoKey][0].rfqItem.itemInformation
                                .globalItemId
                        ].seller_additional_costs.find(
                            (c) => c.cost_name.trim() === cost.costName.trim()
                        );
                        if (
                            temp &&
                            temp.cost_type === CostTypeEnum.PERCENTAGE
                        ) {
                            foundAddtionalCost = {
                                costName: temp.cost_name,
                                additional_cost_id: temp.additional_cost_id,
                                cost_source: temp.cost_source ?? null,
                                costType: temp.cost_type,
                                value: temp.cost_value,
                                allocationType: null,
                            };
                        } else if (
                            temp &&
                            temp.cost_type === CostTypeEnum.ABSOLUTE_VALUE
                        ) {
                            foundAddtionalCost = {
                                costName: temp.cost_name,
                                additional_cost_id: temp.additional_cost_id,
                                cost_source: temp.cost_source ?? null,
                                allocationType: temp.allocation_type!,
                                costType: temp.cost_type,
                                value: temp.cost_value,
                            };
                        }
                    }

                    if (foundAddtionalCost) {
                        additionCostForPayload.push(foundAddtionalCost);
                    }
                }
            });

            let customFieldForPayload: {
                name: string;
                value: string;
                type: CustomTemplateItemTypes;
            }[] = [];
            // filtering custom fields from here
            Object.keys(fieldsToCopy.custom_fields).forEach(
                (customFieldName) => {
                    if (fieldsToCopy.custom_fields[customFieldName]) {
                        let foundCustomField = bidinfo[
                            singleBidInfoKey
                        ][0].customFields.find(
                            (field) => field.name === customFieldName
                        );

                        if (foundCustomField)
                            customFieldForPayload.push(foundCustomField);
                    }
                }
            );

            Object.keys(
                costingTemplateDetails.fieldSettings.customFields
            ).forEach((fieldName) => {
                if (
                    customFieldForPayload.find(
                        (field) => field.name === fieldName
                    ) === undefined
                ) {
                    customFieldForPayload.push({
                        name: fieldName,
                        type: convertTemplateTypeToCustomFieldItemTemplate(
                            costingTemplateDetails.fieldSettings.customFields[
                                fieldName
                            ].type
                        ),
                        value: '',
                    });
                }
            });

            response.push({
                additional_costs: additionCostForPayload.map((cost) => {
                    const temp =
                        convertRfqAdditionalCostToQouteCalculatorAdditionalCost(
                            cost
                        );
                    return {
                        cost_name: temp.cost_name,
                        cost_type: temp.cost_type,
                        cost_allocation: temp.allocation_type, // check this
                        additional_cost_id: temp.additional_cost_id,
                        cost_value: temp.cost_value?.toString(),
                    };
                }),
                costing_sheet_item_boms: [],
                custom_fields: {
                    section_list: [
                        {
                            fields: customFieldForPayload.map((field) => {
                                return {
                                    description: '',
                                    is_locked: true,
                                    is_negotiable:
                                        costingTemplateDetails.fieldSettings
                                            .customFields[field.name]
                                            ?.sellerSettings?.negotiable ??
                                        true,
                                    is_required:
                                        costingTemplateDetails.fieldSettings
                                            .customFields[field.name]
                                            ?.buyerSettings?.required ?? false,
                                    is_visible:
                                        costingTemplateDetails.fieldSettings
                                            .customFields[field.name]
                                            ?.sellerSettings?.showField ?? true,
                                    name: field.name,
                                    type: field.type,
                                    value:
                                        field.type !==
                                        CustomTemplateItemTypes.BOOLEAN
                                            ? field.value
                                            : field.value === 'Yes'
                                            ? true
                                            : false,
                                };
                            }),
                            name: 'item_terms',
                        },
                    ],
                },
                custom_fields_negotiate: {
                    section_list: [],
                },
                enterprise_bom: null,
                enterprise_item:
                    bidinfo[singleBidInfoKey][0].rfqItem.itemInformation
                        .globalItemId,
                notes: null,
                quantity: fieldsToCopy[TemplateStandardFieldsEnum.QUANTITY]
                    ? +bidinfo[singleBidInfoKey][0].quantityInformation.quantity
                    : 0,
                rate: fieldsToCopy[TemplateStandardFieldsEnum.BASE_RATE]
                    ? +bidinfo[singleBidInfoKey][0].pricingInformation.price
                    : 0,
                vendor_entity:
                    bidinfo[singleBidInfoKey][0].sellerEntity.entityId,
            });
        }
    });

    return response;
};

// export const exporttocostcalculator = baseApiSlice.injectEndpoints({
//     endpoints: (builder) => ({
//         getListOfshortlistedItems: builder.query<
//             {
//                 [rfqItemId: string]: IServiceRowMainDV[];
//             },
//             { rfqEntryId: string }
//         >({
//             query: ({ rfqEntryId }) =>
//                 `events/rfq/${rfqEntryId}/bids/sellers/items/latest/`,
//             transformResponse: (
//                 baseQueryReturnValue: IBackendSingleLatestBidResponse[]
//             ) => {
//                 const formattedData = getFormattedRowData(baseQueryReturnValue);
//                 const uniqueRfqEventItems = new Set(
//                     formattedData.map((item) => item.rfqItem.rfqItemId)
//                 );

//

//                 const listOfShortlistedRfqItem: {
//                     [key: string]: IServiceRowMainDV[];
//                 } = {};

//                 uniqueRfqEventItems.forEach((rfqitemid) => {
//                     const listOfShortlistedItems = formattedData
//                         .filter((item) => item.shortlisted)
//                         .filter((item) => item.rfqItem.rfqItemId === rfqitemid);

//

//                     listOfShortlistedRfqItem[rfqitemid] =
//                         listOfShortlistedItems.sort(
//                             (a, b) =>
//                                 +a.pricingInformation.effectiveRate -
//                                 +b.pricingInformation.effectiveRate
//                         );
//
//                 });

//

//                 return listOfShortlistedRfqItem;
//             },
//         }),
//     }),
// });

// export const { useGetListOfshortlistedItemsQuery } = exporttocostcalculator;
