export const getTextFromHTMLString = (htmlString: string) => {
    // Create a new DOMParser
    const parser = new DOMParser();

    // Parse the HTML string
    const parsedHtml = parser.parseFromString(htmlString, 'text/html');

    // Get the first element in the parsed HTML
    const firstElement = parsedHtml.querySelector('*');

    // Retrieve the inner text of the first element
    const innerText = firstElement?.textContent;

    //

    return innerText;
};
