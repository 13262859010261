import { createSlice } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';
import { ICurrencyDetails } from '../../Models/Currency.model';
import { CartItemDetails } from '../Interfaces/itemCartItemInterfaces';

export interface IItemCartStore {
    cartDetails: CartItemDetails[];
    allCurrencies: ICurrencyDetails[];
    cart_save_state: {
        save_state: 'SAVED' | 'UNSAVED' | 'ERROR';
        validity: 'INVALID' | 'VALID';
    };
    cartItems: {
        [cart_item_id: string]: CartItemDetails;
    };
    itemsSavedForLater: {
        [cart_item_id: string]: CartItemDetails;
    };
    item_state: {
        save_state: 'SAVED' | 'UNSAVED' | 'ERROR';
        validity: 'INVALID' | 'VALID';
    }[];
    itemIDs: string[];
    item_vendors: {
        [key: string]: string[];
    };
    currentPage: number;
    listOfErrorsAccrossPages: {
        rfq_item_entery_id: string;
        itemDetails: CartItemDetails | null;
        validity: 'INVALID' | 'VALID';
        noOfVendorsSelected: number;
        pageNoItem: number;
        itemNumber: number;
        itemNo: number;
    }[];
    searchQuery: string;
    filteringType: 'ALL_ITEMS' | 'ITEMS_WITH_ERRORS' | 'ITEMS_WITH_WARNINGS';
    filteringCurrentPage: number;
    shipping_address: string | null;
    fieldsForExport: {
        customFields: any;
        standardFields: any;
    };
    payloadForEventCreation: any[];
    source: string;
}

export interface IUpdateCartStoreAction {
    type: string;
    payload:
        | {
              type: 'SET_ALL_CURRENCIES';
              value: ICurrencyDetails[];
          }
        | {
              type: 'UPDATE_CART_DETAILS';
              value: CartItemDetails[];
          }
        | {
              type: 'UPDATE_CART_ITEM_DETAILS';
              value: {
                  [cart_item_id: string]: CartItemDetails;
              };
          }
        | {
              type: 'UPDATE_SAVED_FOR_LATER_DETAILS';
              value: {
                  [cart_item_id: string]: CartItemDetails;
              };
          }
        | {
              type: 'UPDATE_CART_SAVE_STATE';
              value: 'SAVED' | 'UNSAVED' | 'ERROR';
          }
        | { type: 'UPDATE_CART_VALIDITY'; value: 'INVALID' | 'VALID' }
        | { type: 'UPDATE_ALL_CART_ITEMS'; value: CartItemDetails[] }
        | {
              type: 'UPDATE_SINGLE_CART_ITEM';
              value: {
                  idx: number;
                  data: CartItemDetails;
              };
          }
        | {
              type: 'DELETE_SINGLE_CART_ITEM';
              value: string;
          }
        | {
              type: 'SHIFT_ITEM_TO_CART';
              value: string;
          }
        | {
              type: 'SAVE_ITEM_FOR_LATER';
              value: string;
          }
        | {
              type: 'ADD_SINGLE_CART_ITEM';
              value: CartItemDetails;
          }
        | {
              type: 'UPDATE_ITEM_SAVE_STATE';
              value: {
                  idx1: number;
                  data1: 'SAVED' | 'UNSAVED' | 'ERROR';
              };
          }
        | {
              type: 'UPDATE_VENDOR_FOR_ITEM';
              value: {
                  cart_item_id: string;
                  vendor: string[];
              };
          }
        | {
              type: 'UPDATE_VENDOR_CONTACT_FOR_ITEM';
              value: {
                  cart_item_id: string;
                  vendorContact: string[];
              };
          }
        | {
              type: 'UPDATE_VENDOR_FOR_ALL_ITEM';
              value: {
                  [cart_item_id: string]: string[];
              };
          }
        | {
              type: 'UPDATE_ALL_ITEM_IDS';
              value: string[];
          }
        | {
              type: 'UPDATE_CURRENT_PAGE';
              value: number;
          }
        | {
              // filteringCurrentPage
              type: 'UPDATE_FILTERING_CURRENT_PAGE';
              value: number;
          }
        | {
              type: 'UPDATE_ALL_ITEM_SAVE_STATE';
              value: {
                  allData: 'SAVED' | 'UNSAVED' | 'ERROR';
              };
          }
        | {
              type: 'UPDATE_ITEM_VALIDITY';
              value: {
                  idx2: number;
                  data2: 'VALID' | 'INVALID';
              };
          }
        | {
              type: 'UPDATE_ALL_ITEM_VALIDITY';
              value: {
                  allData2: 'VALID' | 'INVALID';
              };
          }
        | {
              type: 'UPDATE_SEARCH_QUERY';
              value: string;
          }
        | {
              type: 'UPDATE_SEARCH_FILTERS';
              value: {
                  filteringType?:
                      | 'ALL_ITEMS'
                      | 'ITEMS_WITH_ERRORS'
                      | 'ITEMS_WITH_WARNINGS';
                  currentPage?: number;
              };
          }
        | {
              type: 'RESET_CART_STATE';
              value: any;
          }
        | {
              type: 'UPDATE_SHIPPING_ADDRESS';
              value: string | null;
          }
        | {
              type: 'UPDATE_CUSTOM_FIELDS';
              value: any;
          }
        | {
              type: 'UPDATE_STANDARD_FIELDS';
              value: any;
          }
        | {
              type: 'UPDATE_PAYLOAD_FOR_EVENT_CREATION';
              value: any;
          }
        | {
              type: 'UPDATE_SOURCE';
              value: string;
          };
}

export const ItemCartSlice = createSlice<
    IItemCartStore,
    {
        updateCartDataStore: (
            state: IItemCartStore,
            action: IUpdateCartStoreAction
        ) => void;
    },
    string
>({
    name: 'ItemCartStore',
    initialState: {
        cartItems: {},
        itemIDs: [],
        allCurrencies: [],
        itemsSavedForLater: {},
        cart_save_state: {
            save_state: 'SAVED',
            validity: 'VALID',
        },
        searchQuery: '',
        cartDetails: [],
        currentPage: 1,
        filteringCurrentPage: 1,
        filteringType: 'ALL_ITEMS',
        item_state: [],
        item_vendors: {},
        listOfErrorsAccrossPages: [],
        shipping_address: null,
        fieldsForExport: {
            customFields: [],
            standardFields: [],
        },
        payloadForEventCreation: [],
        source: '',
    },
    reducers: {
        updateCartDataStore: (
            state: IItemCartStore,
            action: IUpdateCartStoreAction
        ) => {
            try {
                switch (action.payload.type) {
                    case 'SET_ALL_CURRENCIES':
                        state.allCurrencies = action.payload.value;
                        break;
                    case 'UPDATE_CART_DETAILS':
                        state.cartDetails = action.payload
                            .value as CartItemDetails[];

                        let cartItems: {
                            [cart_item_id: string]: CartItemDetails;
                        } = {};

                        let itemsSavedForLater: {
                            [cart_item_id: string]: CartItemDetails;
                        } = {};

                        action.payload.value.forEach((item) => {
                            if (item.save_for_later) {
                                itemsSavedForLater[item.cart_item_id] = item;
                            } else {
                                cartItems[item.cart_item_id] = item;
                            }
                        });

                        state.cartItems = cloneDeep(cartItems);
                        state.itemsSavedForLater =
                            cloneDeep(itemsSavedForLater);

                        break;
                    case 'ADD_SINGLE_CART_ITEM':
                        const newItem = cloneDeep(action.payload.value);
                        const newitemIds = cloneDeep(state.itemIDs);
                        newitemIds.push(action.payload.value.cart_item_id);
                        state.itemIDs = newitemIds;
                        state.cartDetails = [...state.cartDetails, newItem];
                        if (newItem.save_for_later) {
                            state.itemsSavedForLater[newItem.cart_item_id] =
                                newItem;
                        } else {
                            state.cartItems[newItem.cart_item_id] = newItem;
                        }

                        break;
                    case 'UPDATE_ALL_ITEM_IDS':
                        state.itemIDs = action.payload.value;
                        break;
                    case 'SHIFT_ITEM_TO_CART':
                        let itemInformation =
                            state.itemsSavedForLater[action.payload.value];

                        itemInformation.save_for_later = false;

                        delete state.itemsSavedForLater[action.payload.value];

                        state.cartItems[action.payload.value] =
                            cloneDeep(itemInformation);

                        const itemIndexInList = state.cartDetails.findIndex(
                            (item) => item.cart_item_id === action.payload.value
                        );

                        if (itemIndexInList !== -1) {
                            state.cartDetails[itemIndexInList] =
                                cloneDeep(itemInformation);
                        }

                        break;
                    case 'SAVE_ITEM_FOR_LATER':
                        let itemInfo = state.cartItems[action.payload.value];

                        itemInfo.save_for_later = true;

                        delete state.cartItems[action.payload.value];

                        state.itemsSavedForLater[action.payload.value] =
                            cloneDeep(itemInfo);

                        const itemIdx = state.cartDetails.findIndex(
                            (item) => item.cart_item_id === action.payload.value
                        );

                        if (itemIdx !== -1) {
                            state.cartDetails[itemIdx] = cloneDeep(itemInfo);
                        }

                        break;
                    case 'UPDATE_CART_ITEM_DETAILS':
                        state.cartItems = action.payload.value;
                        break;
                    case 'UPDATE_SAVED_FOR_LATER_DETAILS':
                        state.itemsSavedForLater = action.payload.value;
                        break;
                    case 'UPDATE_CART_SAVE_STATE':
                        state.cart_save_state.save_state = action.payload
                            .value as 'SAVED' | 'UNSAVED' | 'ERROR';
                        break;
                    case 'UPDATE_CART_VALIDITY':
                        state.cart_save_state.validity = action.payload
                            .value as 'INVALID' | 'VALID';
                        break;
                    case 'UPDATE_ALL_CART_ITEMS':
                        state.cartDetails = action.payload
                            .value as CartItemDetails[];
                        let item_id_arr: string[] = [];
                        let save_state_arr: any[] = [];
                        (action.payload.value as CartItemDetails[]).forEach(
                            (item, idx) => {
                                item_id_arr.push(item.cart_item_id);
                                if (item.save_for_later) {
                                    state.itemsSavedForLater[
                                        item.cart_item_id
                                    ] = item;
                                } else {
                                    state.cartItems[item.cart_item_id] = item;
                                }

                                save_state_arr.push({
                                    save_state: 'SAVED',
                                    validity: 'VALID',
                                });
                            }
                        );

                        state.item_state = save_state_arr;
                        break;

                    case 'UPDATE_SINGLE_CART_ITEM':
                        const { idx, data } = action.payload.value as {
                            idx: number;
                            data: CartItemDetails;
                        };
                        if (idx === -1) {
                            state.cartDetails.push(data);
                            if (data.save_for_later) {
                                state.itemsSavedForLater[data.cart_item_id] =
                                    data;
                            } else {
                                state.cartItems[data.cart_item_id] = data;
                            }
                            state.item_state.push({
                                save_state: 'SAVED',
                                validity: 'INVALID',
                            });
                        } else {
                            if (data.save_for_later) {
                                state.itemsSavedForLater[data.cart_item_id] =
                                    data;
                            } else {
                                state.cartItems[data.cart_item_id] = data;
                            }
                            state.cartDetails[idx] = data;
                            if (data.save_for_later) {
                                state.itemsSavedForLater[data.cart_item_id] =
                                    data;
                            } else {
                                state.cartItems[data.cart_item_id] = data;
                            }
                        }
                        break;

                    case 'DELETE_SINGLE_CART_ITEM':
                        try {
                            const itemToBeDeletedIndex =
                                state.cartDetails.findIndex(
                                    (item) =>
                                        item.cart_item_id ===
                                        action.payload.value
                                );

                            if (itemToBeDeletedIndex !== -1) {
                                const itemToBeDeleted = cloneDeep(
                                    state.cartDetails[itemToBeDeletedIndex]
                                );

                                if (itemToBeDeleted.save_for_later) {
                                    delete state.itemsSavedForLater[
                                        itemToBeDeleted.cart_item_id
                                    ];
                                } else {
                                    delete state.cartItems[
                                        itemToBeDeleted.cart_item_id
                                    ];
                                }

                                state.cartDetails.splice(
                                    itemToBeDeletedIndex as number,
                                    1
                                );

                                state.item_state.splice(
                                    itemToBeDeletedIndex as number,
                                    1
                                );

                                delete state.item_vendors[
                                    state.cartDetails[
                                        itemToBeDeletedIndex as number
                                    ].cart_item_id
                                ];
                            }
                        } catch (err) {}
                        break;
                    case 'UPDATE_ITEM_SAVE_STATE':
                        const { idx1, data1 } = action.payload.value as {
                            idx1: number;
                            data1: 'SAVED' | 'UNSAVED' | 'ERROR';
                        };

                        if (state.item_state[idx1]) {
                            state.item_state[idx1].save_state = data1;
                        }
                        break;
                    case 'UPDATE_ALL_ITEM_SAVE_STATE':
                        const { allData } = action.payload.value as {
                            allData: 'SAVED' | 'UNSAVED' | 'ERROR';
                        };

                        state.item_state.forEach((singleItemState) => {
                            singleItemState.save_state = allData;
                        });

                        break;
                    case 'UPDATE_ITEM_VALIDITY':
                        const { idx2, data2 } = action.payload.value as {
                            idx2: number;
                            data2: 'VALID' | 'INVALID';
                        };

                        if (state.item_state[idx2]) {
                            state.item_state[idx2].validity = data2;
                        }
                        break;
                    case 'UPDATE_ALL_ITEM_VALIDITY':
                        const { allData2 } = action.payload.value as {
                            allData2: 'VALID' | 'INVALID';
                        };

                        state.item_state.forEach((singleItemState) => {
                            singleItemState.validity = allData2;
                        });
                        break;
                    case 'UPDATE_VENDOR_FOR_ITEM':
                        const { cart_item_id, vendor } = action.payload
                            .value as {
                            cart_item_id: string;
                            vendor: string[];
                        };
                        state.item_vendors[cart_item_id] = vendor;

                        const itemIndex = state.cartDetails.findIndex(
                            (item) => item.cart_item_id === cart_item_id
                        );

                        if (itemIndex !== -1) {
                            const isItemInCart =
                                !state.cartDetails[itemIndex].save_for_later;

                            if (isItemInCart) {
                                state.cartItems[cart_item_id].seller_entities =
                                    vendor;
                            } else {
                                state.itemsSavedForLater[
                                    cart_item_id
                                ].seller_entities = vendor;
                            }

                            state.cartDetails[itemIndex].seller_entities =
                                vendor;
                        }
                        break;
                    case 'UPDATE_VENDOR_CONTACT_FOR_ITEM':
                        const { cart_item_id: cartItemId, vendorContact } =
                            action.payload.value;

                        const itemIndexInCart = state.cartDetails.findIndex(
                            (item) => item.cart_item_id === cartItemId
                        );

                        if (itemIndexInCart !== -1) {
                            const isItemInCart =
                                !state.cartDetails[itemIndexInCart]
                                    .save_for_later;

                            if (isItemInCart) {
                                state.cartItems[cartItemId].seller_contacts =
                                    vendorContact;
                            } else {
                                state.itemsSavedForLater[
                                    cartItemId
                                ].seller_contacts = vendorContact;
                            }

                            state.cartDetails[itemIndexInCart].seller_contacts =
                                vendorContact;
                        }
                        break;

                    case 'UPDATE_VENDOR_FOR_ALL_ITEM':
                        state.item_vendors = action.payload.value;

                        break;

                    case 'UPDATE_CURRENT_PAGE':
                        state.currentPage = action.payload.value;

                        break;
                    case 'UPDATE_FILTERING_CURRENT_PAGE':
                        state.filteringCurrentPage = action.payload.value;
                        break;

                    case 'UPDATE_SEARCH_QUERY':
                        state.searchQuery = action.payload.value as string;
                        break;
                    case 'RESET_CART_STATE':
                        state.cart_save_state = {
                            save_state: 'SAVED',
                            validity: 'VALID',
                        };
                        state.searchQuery = '';
                        state.cartDetails = [];
                        state.currentPage = 1;
                        state.filteringCurrentPage = 1;
                        state.filteringType = 'ALL_ITEMS';
                        state.item_state = [];
                        state.item_vendors = {};
                        state.listOfErrorsAccrossPages = [];
                        state.cartItems = {};
                        state.itemIDs = [];
                        state.allCurrencies = [];
                        state.itemsSavedForLater = {};
                        break;
                    case 'UPDATE_SHIPPING_ADDRESS':
                        state.shipping_address = action.payload.value;
                        break;
                    case 'UPDATE_CUSTOM_FIELDS':
                        state.fieldsForExport.customFields =
                            action.payload.value;
                        break;
                    case 'UPDATE_STANDARD_FIELDS':
                        state.fieldsForExport.standardFields =
                            action.payload.value;
                        break;
                    case 'UPDATE_PAYLOAD_FOR_EVENT_CREATION':
                        state.payloadForEventCreation = action.payload.value;
                        break;
                    case 'UPDATE_SOURCE':
                        state.source = action.payload.value;
                        break;
                }
            } catch (err) {}
        },
    },
});

export const { updateCartDataStore } = ItemCartSlice.actions;

const ItemCartStore = ItemCartSlice.reducer;
export default ItemCartStore;
