import { Box, IconButton } from '@mui/material';
import { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { FWTooltip } from '../../../Common/FWCustomTooltip';
import { FWTypography } from '../../../Common/FWTypograhy';
import DeleteItemButton from '../../../Events/Components/Buyer/DeleteItemButton';
import DisplaySavedStatus from '../../../Events/Components/Shared/DisplaySavedStatus';
import { StyledRFQItemNumberDiv } from '../../../Events/Constants/Buyer/RFQHelperFunctions';
import TagsDisplay from '../../../Global/Components/TagsDisplay';
import { IGlobalAppStore } from '../../../Redux/Store';
import { IAPaginationContext } from '../../Helpers/IAPaginationHelpers';
import { ItemCartAccessContext } from '../../Pages/ItemCartAccess';
import { CartItemAccordionContext } from './CartItemAccordion';
import CartItemHistoryLog from './CartItemHistoryLog';

export default function CartItemAccordionHeader() {
    const { limitItemPerPage } = useContext(IAPaginationContext);

    const { currentPage } = useContext(ItemCartAccessContext);

    const {
        dataState,
        accordion_index,
        cartItemId,
        itemInCart,
        open,
        saveItemForLater,
        addItemToCart,
        deleteItem,
    } = useContext(CartItemAccordionContext);

    const itemInformation = useSelector((store: IGlobalAppStore) =>
        itemInCart
            ? store.ItemCartStore.cartItems[cartItemId]
            : store.ItemCartStore.itemsSavedForLater[cartItemId]
    );

    const [openItemHistoryPopup, setOpenItemHistoryPopup] = useState(false);

    return (
        <>
            <Box>
                <Box
                    className="flex flex--jcsb flex--aic"
                    margin={'0 1rem'}
                    height={'3rem'}
                >
                    <Box className="flex flex--aic" width={'max-content'}>
                        <StyledRFQItemNumberDiv
                            maxWidth={{ lg: '40rem', sm: '25rem', xs: '10rem' }}
                        >
                            <FWTypography className="itemBoxHeaderNumber">
                                {accordion_index +
                                    (currentPage - 1) * limitItemPerPage +
                                    1 <
                                10
                                    ? '0' +
                                      (accordion_index +
                                          (currentPage - 1) * limitItemPerPage +
                                          1)
                                    : accordion_index +
                                      (currentPage - 1) * limitItemPerPage +
                                      1}
                            </FWTypography>
                            <FWTypography
                                variant="h4"
                                fontWeight={500}
                                className="itemBoxHeaderName"
                            >
                                {itemInformation.item_information.item_name}
                            </FWTypography>
                        </StyledRFQItemNumberDiv>
                    </Box>
                    <Box className="flex flex--aic" width={'max-content'}>
                        <DisplaySavedStatus
                            saved={dataState.saved}
                            error={dataState.error}
                            loading={dataState.loading}
                            error_msg={dataState.error_msg}
                        />
                        <Box width="0.75rem"></Box>
                        {
                            <FWTooltip title="View item history logs">
                                <Box>
                                    <IconButton
                                        color="primary"
                                        sx={{
                                            fontSize: '1.5rem',
                                        }}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setOpenItemHistoryPopup(true);
                                        }}
                                    >
                                        <i className="bi bi-clock-history"></i>
                                    </IconButton>
                                </Box>
                            </FWTooltip>
                        }
                        {/* <Box width="0.75rem"></Box> */}
                        {itemInCart && (
                            <FWTooltip title="Save item">
                                <Box>
                                    <IconButton
                                        color="primary"
                                        sx={{
                                            fontSize: '1.5rem',
                                        }}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            saveItemForLater();
                                        }}
                                    >
                                        <i className="bi bi-bookmark"></i>
                                    </IconButton>
                                </Box>
                            </FWTooltip>
                        )}
                        {!itemInCart && (
                            <FWTooltip title="Add item to cart">
                                <Box>
                                    <IconButton
                                        color="primary"
                                        sx={{
                                            fontSize: '1.5rem',
                                        }}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            addItemToCart();
                                        }}
                                    >
                                        <i className="bi bi-cart4"></i>
                                    </IconButton>
                                </Box>
                            </FWTooltip>
                        )}
                        <DeleteItemButton deleteItem={deleteItem} />
                        <Box width="0.75rem"></Box>
                        <Box color="primary.main" fontSize={'1.5rem'}>
                            {open ? (
                                <i className="bi bi-chevron-up"></i>
                            ) : (
                                <i className="bi bi-chevron-down"></i>
                            )}
                        </Box>
                    </Box>
                </Box>
                {itemInformation?.tags?.length > 0 && (
                    <Box margin={'0 1rem'} height={'3rem'}>
                        <TagsDisplay
                            tags={itemInformation?.tags ?? []}
                            maxTags={5}
                        />
                    </Box>
                )}
            </Box>
            <CartItemHistoryLog
                open={openItemHistoryPopup}
                itemId={itemInformation?.enterprise_item}
                handleClose={() => setOpenItemHistoryPopup(false)}
                title="History logs"
            />
        </>
    );
}
