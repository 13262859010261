import { Autocomplete } from '@mui/material';
import React from 'react';
import { AutocompleteStyledPaper } from '../../Common/FWAutocomplete';
import { FWInput } from '../../Common/FWInput';
import { getMatchingItemTag, getNewItemTag } from '../Helpers/itemTagHelper';

type TagsProps = {
    value: string[];
    updateData: (value: string[]) => void;
    options?: string[];
    label?: string;
    errorText?: string;
    customError?: boolean;
    disabled?: boolean;
};
export default function TagsInput({
    label,
    value,
    updateData,
    options,
    customError,
    errorText,
    disabled = false,
}: TagsProps) {
    const handleKeyDown = (event: any) => {
        switch (event.key) {
            case ',': {
                event.preventDefault();
                event.stopPropagation();
                break;
            }
            default:
        }
    };

    const [error, setError] = React.useState(false);

    return (
        <Autocomplete
            disabled={disabled}
            size="small"
            multiple
            options={options ?? []}
            freeSolo
            PaperComponent={(props: any) => (
                <AutocompleteStyledPaper {...props} />
            )}
            renderOption={(props: any, option: string) => {
                return (
                    <li {...props} key={option}>
                        {option}
                    </li>
                );
            }}
            onInputChange={(event, newInputValue) => {
                if (
                    value.some(
                        (soloValue) =>
                            soloValue.toLocaleLowerCase() ===
                            newInputValue.toLowerCase()
                    )
                ) {
                    setError(true);
                } else {
                    setError(false);
                }
            }}
            value={value ?? []}
            onChange={(event, newValue) => {
                const isTagDeleted = newValue?.length < value?.length;

                if (isTagDeleted) {
                    updateData(newValue);
                } else {
                    const newTag = getNewItemTag(newValue, value);

                    if (newTag) {
                        const newValue = getMatchingItemTag(
                            newTag,
                            value,
                            options ?? []
                        );

                        updateData(newValue);
                    }
                }

                // let appliedVal: string[] = [];
                // if (typeof newValue === 'string') {
                //     appliedVal = [newValue];
                // } else {
                //     appliedVal = (newValue as string[]).filter(
                //         (val) => val.trim() !== ''
                //     );
                // }

                // const uniqueTags = Array.from(
                //     new Set(newValue.map((val) => val.toLowerCase()))
                // );
                // const isDuplicatePresent = uniqueTags?.length === value?.length;
                // if (!isDuplicatePresent) updateData(appliedVal);
            }}
            filterOptions={(option, state) => {
                const uniqueOptions = option.filter(
                    (option) =>
                        !value.some(
                            (val) => val.toLowerCase() === option.toLowerCase()
                        ) &&
                        option
                            .toLowerCase()
                            .includes(state.inputValue?.toLowerCase())
                );
                return uniqueOptions;
            }}
            filterSelectedOptions
            renderInput={(params) => {
                params.inputProps.onKeyDown = handleKeyDown;
                return (
                    <FWInput
                        name="tags"
                        label={label ?? ''}
                        {...params}
                        error={error || customError}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                padding: '0px 20px 0px 6px!important',
                            },
                        }}
                        helper={{
                            text:
                                error || customError
                                    ? errorText ?? 'tag already exists'
                                    : '',
                            textColor: 'error',
                        }}
                    />
                );
            }}
            openOnFocus={true}
        />
    );
}
