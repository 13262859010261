import React, { useMemo } from 'react';
import { FWTypography } from '../../../../Common/FWTypograhy';
import { IVLNAboutQualityCompliance } from '../../../Interfaces/VendorLNInterfaces';
import { Box } from '@mui/system';
import DisplayLink from '../../Common/DisplayLink';
import DisplayAttachment from '../../Common/DisplayAttachment';
import { Grid } from '@mui/material';

const VLNProfileAboutQualityandComplianceComponent = ({
    qNcData,
}: {
    qNcData: IVLNAboutQualityCompliance[];
}) => {
    const qNcLinks = useMemo(() => {
        return qNcData.filter((item) => item.link.length > 0);
    }, [qNcData]);
    const qNcAttachments = useMemo(() => {
        return qNcData.filter((item) => item.files.length > 0);
    }, [qNcData]);

    return (
        <Box
        // marginTop={5}
        >
            <FWTypography variant={'h4'} fontWeight={500}>
                Quality and compliance
            </FWTypography>
            {qNcLinks.length === 0 && qNcAttachments.length === 0 ? (
                <></>
            ) : (
                <>
                    {qNcLinks.length > 0 && (
                        <>
                            <FWTypography fontWeight={500} marginBottom={0.5}>
                                Links
                            </FWTypography>
                            <Grid container spacing={2}>
                                {qNcLinks.map((item, idx) => (
                                    <Grid key={idx} item xs={12} sm={6} md={4}>
                                        <DisplayLink
                                            link={item.link}
                                            name={item.name}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        </>
                    )}
                    {qNcAttachments.length > 0 && (
                        <>
                            <FWTypography fontWeight={500} marginBottom={0.5}>
                                Documents
                            </FWTypography>
                            <Grid container spacing={2}>
                                {qNcAttachments.map((item, idx) => (
                                    <Grid
                                        key={idx}
                                        item
                                        xs={12}
                                        sm={6}
                                        md={4}
                                        // maxWidth={'200px'}
                                    >
                                        <DisplayAttachment
                                            attachment_id={
                                                item.files?.[0]?.attachment_id
                                            }
                                            file_name={
                                                item.files?.[0]?.file_name
                                            }
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        </>
                    )}
                </>
            )}
        </Box>
    );
};

export default VLNProfileAboutQualityandComplianceComponent;
