import { Box } from '@mui/material';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import moment from 'moment';
import { FWTooltip } from '../../Common/FWCustomTooltip';
import FWMultipleTooltipMessages from '../../Common/FWMultipleTooltipMessages';
import { FWTypography } from '../../Common/FWTypograhy';
import { getFWDate } from '../../Common/Utils/DateUtils';
import DotBadge from '../../Components/Shared/DotBadge';
import {
    generateItemWarnings,
    generatePOWarnings,
} from '../../Global/Helpers/ItemAndPOHelpers';
import {
    GRDeliveryScheduleCell,
    GRPastListItemNameCell,
    PastGRDeliveryScheduleCell,
} from '../Components/GRCellRenderers';
import GRNStatusIcon, { getGRNStatus } from '../Components/GRNStatusIcon';
import GRStatusDot from '../Components/GRStatusDot';
import {
    IGRInvoiceListSummary,
    IGRPastListSummary,
} from '../Interfaces/GRInterface';

export const pendingColumns: ColDef[] = [
    {
        field: 'poId',
        headerName: 'PO ID',
        width: 180,
        cellRenderer: (params: any) => {
            const poWarnings = generatePOWarnings(params.data);
            return (
                <div
                    className="flex flex--aic"
                    style={{
                        width: '100%',
                    }}
                >
                    <FWTypography sx={{ maxWidth: '100%' }}>
                        {params.data.poId}
                    </FWTypography>
                    {poWarnings.warnings.length > 0 && (
                        <FWMultipleTooltipMessages
                            messages={poWarnings.warnings}
                            iconColor={poWarnings.color + '.main'}
                            icon={
                                <div
                                    style={{ lineHeight: 0 }}
                                    className="ml--5"
                                >
                                    <DotBadge color={poWarnings.color} />
                                </div>
                            }
                        />
                    )}
                </div>
            );
        },
        sortable: true,
        filter: true,
        valueGetter: (params) => params.data.poId,
        getQuickFilterText: (params) => params.data.poId,
    },
    {
        field: 'invoiceId',
        headerName: 'Invoice ID',
        width: 190,
        cellRenderer: (params: any) => {
            const itemWarnings = generateItemWarnings(params.data);
            return (
                <div
                    className="flex flex--aic"
                    style={{
                        width: '100%',
                    }}
                >
                    <FWTypography sx={{ maxWidth: '100%' }}>
                        {params.data.invoiceId}
                    </FWTypography>
                    {itemWarnings.warnings.length > 0 && (
                        <FWMultipleTooltipMessages
                            messages={itemWarnings.warnings}
                            iconColor={itemWarnings.color + '.main'}
                            icon={
                                <div
                                    style={{ lineHeight: 0 }}
                                    className="ml--5"
                                >
                                    <DotBadge color={itemWarnings.color} />
                                </div>
                            }
                        />
                    )}
                </div>
            );
        },
        sortable: true,
        filter: true,
        valueGetter: (params) => params.data.invoiceId,
        getQuickFilterText: (params) => params.data.invoiceId,
    },
    {
        field: 'invoiceDate',
        headerName: 'Invoice Date',
        width: 190,
        cellRenderer: (params: ICellRendererParams) => {
            return (
                <FWTypography sx={{ maxWidth: '100%' }}>
                    {params.value}
                </FWTypography>
            );
        },
        sortable: true,
        filter: true,
        valueGetter: (params) => {
            if (params.data.invoiceDate === '') {
                return '-';
            }
            return getFWDate(params.data.invoiceDate);
        },
        getQuickFilterText: (params) =>
            params.value + ' ' + params.data.invoiceDate,
    },
    {
        field: 'vendorName',
        headerName: 'Vendor',
        minWidth: 190,
        flex: 1,
        cellRenderer: (params: any) => (
            <FWTypography>{params.data.vendorName}</FWTypography>
        ),
        sortable: true,
        filter: true,
        valueGetter: (params) => params.data.vendorName,
        getQuickFilterText: (params) => params.data.vendorName,
    },
    {
        field: 'deliveryDate',
        headerName: 'Delivery date',
        width: 180,
        cellRenderer: GRDeliveryScheduleCell,
        sortable: false,
        filter: true,
        valueGetter: ({ data }: { data: IGRInvoiceListSummary }) =>
            (data.deliveryDate || data.lots) + '',
        getQuickFilterText: ({ data }: { data: IGRInvoiceListSummary }) =>
            (data.deliveryDate || data.lots) + '',
        comparator: (a, b) => {
            let ia: number = +a;
            let ib: number = +b;

            if (!isNaN(ia) && !isNaN(ib)) {
                return ia > ib ? 1 : -1;
            }
            if (isNaN(ia) && !isNaN(ib)) {
                return 1;
            }
            if (!isNaN(ia) && isNaN(ib)) {
                return -1;
            }

            if (isNaN(ia) && isNaN(ib)) {
                return moment(new Date(a)).isAfter(moment(new Date(b)))
                    ? 1
                    : -1;
            }

            return 0;
        },
    },
    {
        field: 'noOfItems',
        headerName: 'Item(s)',
        width: 130,
        headerClass: 'center',
        cellRenderer: (params: any) => (
            <div
                className="flex flex--aic flex--jcfe"
                style={{
                    width: '100%',
                }}
            >
                <FWTypography
                    style={{
                        paddingRight: '10px',
                    }}
                >
                    {params.data.noOfItems}
                </FWTypography>
                {params.data.status !== 'DELIVERY_PENDING' && (
                    <GRStatusDot
                        status={params.data.status}
                        style={{ marginRight: -10 }}
                    />
                )}
            </div>
        ),
        sortable: true,
        filter: true,
        valueGetter: (params) => parseFloat(params.data.noOfItems) + '',
        getQuickFilterText: (params) => params.data.noOfItems + '',
    },
    {
        field: 'shippingAddress',
        headerName: 'Shipping address',
        width: 210,
        cellRenderer: (params: any) => (
            <>
                <FWTooltip
                    title={`[${params.data.shippingAddress.nickName}]: ${params.data.shippingAddress.fullAddress}`}
                >
                    <Box
                        style={{
                            maxWidth: '100%',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                        }}
                        color="primary.main"
                    >
                        {params.data.shippingAddress.nickName}
                    </Box>
                </FWTooltip>
            </>
        ),
        sortable: true,
        filter: true,
        valueGetter: (params) => params.data.shippingAddress.nickName,
        getQuickFilterText: (params) => params.data.shippingAddress.nickName,
    },
    {
        field: 'buyerEntityName',
        headerName: 'Entity',
        width: 200,
        cellRenderer: (params: any) => (
            <FWTypography>{params.value}</FWTypography>
        ),
        sortable: true,
        filter: true,
    },
    // {
    //     field: 'options',
    //     headerName: 'Options',
    //     width: 100,
    //     headerClass: 'center',
    //     cellRenderer: (params: any) => (
    //         <div style={{ margin: '0 auto' }}>
    //             <GRInvoiceListOptionsMenu row={params.data} />
    //         </div>
    //     ),
    // },
];

export const pendingPOColumns: ColDef[] = [
    {
        field: 'poId',
        headerName: 'PO ID',
        width: 150,
        cellRenderer: (params: any) => {
            const poWarnings = generatePOWarnings(params.data);
            return (
                <div
                    className="flex flex--aic"
                    style={{
                        width: '100%',
                    }}
                >
                    <FWTypography sx={{ maxWidth: '100%' }}>
                        {params.data.poId}
                    </FWTypography>
                    {poWarnings.warnings.length > 0 && (
                        <FWMultipleTooltipMessages
                            messages={poWarnings.warnings}
                            iconColor={poWarnings.color + '.main'}
                            icon={
                                <div
                                    style={{ lineHeight: 0 }}
                                    className="ml--5"
                                >
                                    <DotBadge color={poWarnings.color} />
                                </div>
                            }
                        />
                    )}
                </div>
            );
        },
        sortable: true,
        filter: true,
        valueGetter: (params) => params.data.poId,
        getQuickFilterText: (params) => params.data.poId,
    },
    {
        field: 'vendorName',
        headerName: 'Vendor',
        minWidth: 220,
        flex: 1,
        cellRenderer: (params: any) => (
            <FWTypography>{params.data.vendorName}</FWTypography>
        ),
        sortable: true,
        filter: true,
        valueGetter: (params) => params.data.vendorName,
        getQuickFilterText: (params) => params.data.vendorName,
    },
    {
        field: 'deliveryDate',
        headerName: 'Delivery date',
        width: 180,
        cellRenderer: GRDeliveryScheduleCell,
        sortable: true,
        filter: true,
        valueGetter: ({ data }: { data: IGRInvoiceListSummary }) =>
            (data.deliveryDate || data.lots) + '',
        getQuickFilterText: ({ data }: { data: IGRInvoiceListSummary }) =>
            (data.deliveryDate || data.lots) + '',
        comparator: (a, b) => {
            let ia: number = +a;
            let ib: number = +b;

            if (!isNaN(ia) && !isNaN(ib)) {
                return ia > ib ? 1 : -1;
            }
            if (isNaN(ia) && !isNaN(ib)) {
                return 1;
            }
            if (!isNaN(ia) && isNaN(ib)) {
                return -1;
            }

            if (isNaN(ia) && isNaN(ib)) {
                return moment(new Date(a)).isAfter(moment(new Date(b)))
                    ? 1
                    : -1;
            }

            return 0;
        },
    },
    {
        field: 'noOfItems',
        headerName: 'Item(s)',
        width: 130,
        headerClass: 'center',
        cellRenderer: (params: any) => (
            <div
                className="flex flex--aic flex--jcfe"
                style={{
                    width: '100%',
                }}
            >
                <FWTypography
                    style={{
                        paddingRight: '10px',
                    }}
                >
                    {params.data.noOfItems}
                </FWTypography>
                {params.data.status !== 'DELIVERY_PENDING' && (
                    <GRStatusDot
                        status={params.data.status}
                        style={{ marginRight: -10 }}
                    />
                )}
            </div>
        ),
        sortable: true,
        filter: true,
        valueGetter: (params) => parseFloat(params.data.noOfItems) + '',
        getQuickFilterText: (params) => params.data.noOfItems + '',
    },
    {
        field: 'shippingAddress',
        headerName: 'Shipping address',
        width: 210,
        cellRenderer: (params: any) => (
            <>
                <FWTooltip
                    title={`[${params.data.shippingAddress.nickName}]: ${params.data.shippingAddress.fullAddress}`}
                >
                    <Box
                        style={{
                            maxWidth: '100%',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                        }}
                        color="primary.main"
                    >
                        {params.data.shippingAddress.nickName}
                    </Box>
                </FWTooltip>
            </>
        ),
        sortable: true,
        filter: true,
        valueGetter: (params) => params.data.shippingAddress.nickName,
        getQuickFilterText: (params) => params.data.shippingAddress.nickName,
    },
    {
        field: 'buyerEntityName',
        headerName: 'Entity',
        width: 230,
        cellRenderer: (params: any) => (
            <FWTypography>{params.value}</FWTypography>
        ),
        sortable: true,
        filter: true,
    },
    // {
    //     field: 'options',
    //     headerName: 'Options',
    //     width: 100,
    //     headerClass: 'center',
    //     cellRenderer: (params: any) => (
    //         <div style={{ margin: '0 auto' }}>
    //             <GRInvoiceListOptionsMenu row={params.data} />
    //         </div>
    //     ),
    // },
];
export const pastColumns: ColDef[] = [
    {
        field: 'grnNo',
        headerName: 'GR ID',
        width: 180,
        cellRenderer: (params: any) => (
            <FWTypography>{params.data.grnNo}</FWTypography>
        ),
        sortable: true,
        filter: true,
        valueGetter: (params) => params.data.grnNo,
        getQuickFilterText: (params) => params.data.grnNo,
    },
    {
        field: 'invoiceId',
        headerName: 'Invoice ID',
        width: 180,
        cellRenderer: (params: any) => (
            <FWTypography>{params.data.invoiceId}</FWTypography>
        ),
        sortable: true,
        filter: true,
        valueGetter: (params) => params.data.invoiceId,
        getQuickFilterText: (params) => params.data.invoiceId,
        hide: true,
    },
    {
        field: 'poId',
        headerName: 'PO ID',
        width: 180,
        cellRenderer: (params: any) => (
            <FWTypography>{params.data.poId}</FWTypography>
        ),
        sortable: true,
        filter: true,
        valueGetter: (params) => params.data.poId,
        getQuickFilterText: (params) => params.data.poId,
        hide: true,
    },
    {
        field: 'itemName',
        headerName: 'Item name',
        minWidth: 120,
        flex: 1,
        cellRenderer: GRPastListItemNameCell,
        sortable: true,
        filter: true,
        valueGetter: (params) => params.data.itemName,
        getQuickFilterText: (params) => params.data.itemName,
    },
    {
        field: 'vendorName',
        headerName: 'Vendor',
        width: 240,
        cellRenderer: (params: any) => (
            <FWTypography>{params.data.vendorName}</FWTypography>
        ),
        sortable: true,
        filter: true,
        valueGetter: (params) => params.data.vendorName,
        getQuickFilterText: (params) => params.data.vendorName,
    },
    {
        field: 'deliveryDate',
        headerName: 'Delivery date',
        width: 180,
        cellRenderer: PastGRDeliveryScheduleCell,
        sortable: true,
        filter: true,
        valueGetter: (params) => params.data.deliveryDate,
        getQuickFilterText: (params) => params.data.deliveryDate,
    },
    {
        field: 'grnIssueDate',
        headerName: 'GR issue date',
        width: 200,
        cellRenderer: (params: any) => (
            <FWTypography>{getFWDate(params.data.grnIssueDate)}</FWTypography>
        ),
        sortable: true,
        filter: true,
        valueGetter: (params) => params.data.grnIssueDate,
        getQuickFilterText: (params) => getFWDate(params.data.grnIssueDate),
    },
    {
        field: 'grnCreator',
        headerName: 'GR creator',
        width: 200,
        cellRenderer: (params: any) => (
            <FWTypography>{params.data.grnCreator}</FWTypography>
        ),
        sortable: true,
        filter: true,
        valueGetter: (params) => params.data.grnCreator,
        getQuickFilterText: (params) => params.data.grnCreator,
    },
    {
        field: 'buyerEntityName',
        headerName: 'Entity',
        width: 240,
        cellRenderer: (params: any) => (
            <FWTypography>{params.value}</FWTypography>
        ),
        sortable: true,
        filter: true,
    },
    {
        field: 'status',
        headerName: 'Status',
        width: 120,
        headerClass: 'center',
        cellRenderer: ({ data }: { data: IGRPastListSummary }) => (
            <Box style={{ margin: '0 auto' }}>
                <GRNStatusIcon
                    iconName="fw--grn-done"
                    acceptedQty={data.grnAcceptedQty}
                    rejectedQty={data.grnRejectedQty}
                    className="fs--26"
                />
            </Box>
        ),
        sortable: true,
        filter: true,
        valueGetter: ({ data }: { data: IGRPastListSummary }) =>
            getGRNStatus(data.grnAcceptedQty, data.grnRejectedQty).status,
        getQuickFilterText: ({ data }: { data: IGRPastListSummary }) =>
            getGRNStatus(data.grnAcceptedQty, data.grnRejectedQty).status,
    },
    {
        field: 'itemId',
        headerName: 'Item ID',
        width: 200,
        cellRenderer: ({ data }: { data: IGRPastListSummary }) =>
            data.itemDetails.itemId,
        valueGetter: ({ data }: { data: IGRPastListSummary }) =>
            data.itemDetails.itemId,
        getQuickFilterText: ({ data }: { data: IGRPastListSummary }) =>
            data.itemDetails.itemId,
        hide: true,
    },
];

export const headerTabs = [
    {
        name: 'pending',
        color: '#007aff',
    },
    {
        name: 'past',
        color: '#007aff',
    },
];
