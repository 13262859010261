import { Box } from '@mui/system';
import React from 'react';

interface HelpTabPanelProps {
    value: any;
    index: any;
    children: React.ReactNode;
}

const HelpTabPanel = ({ index, value, children }: HelpTabPanelProps) => {
    if (index !== value) return null;
    return <Box>{children}</Box>;
};

export default HelpTabPanel;
