import {
    ChartFilterCategory,
    DateRangeType,
    SingleFilterDate,
    SingleFilterMultiselectDropdownConsolidated,
    SingleFilterSingleselectDropdown,
} from '../Interfaces/IHistoricalAnalytics.model';

export const getPoIssueDateFilter = ({
    dateRange = 'last_365_days',
}: {
    dateRange?: DateRangeType;
}): SingleFilterDate => {
    return {
        filterId: 'poIssueDate',
        filterType: 'date',
        filterLabel: 'PO Issue Date',
        filterApplied: true,
        filterFields: {
            metricFieldType: 'universal',
            fieldName: 'po__submission_datetime',
        },
        dateRange: dateRange,
        customRange: {
            startDate: '',
            endDate: '',
        },
    };
};

export const getInvoiceDateFilter = ({
    dateRange = 'last_365_days',
}: {
    dateRange?: DateRangeType;
}): SingleFilterDate => {
    return {
        filterId: 'invoiceDate',
        filterType: 'date',
        filterLabel: 'Invoice Date',
        filterApplied: true,
        filterFields: {
            metricFieldType: 'universal',
            fieldName: 'invoice__invoice_date',
        },
        dateRange: dateRange,
        customRange: {
            startDate: '',
            endDate: '',
        },
    };
};

export const getPaymentDueDateFilter = ({
    dateRange = 'last_365_days',
}: {
    dateRange?: DateRangeType;
}): SingleFilterDate => {
    return {
        filterId: 'paymentDueDate',
        filterType: 'date',
        filterLabel: 'Payment Due Date',
        filterApplied: true,
        filterFields: {
            metricFieldType: 'universal',
            fieldName: 'invoice_item__payment_due_date',
        },
        dateRange: dateRange,
        customRange: {
            startDate: '',
            endDate: '',
        },
    };
};

export const getEventStartDateFilter = ({
    dateRange = 'last_365_days',
}: {
    dateRange?: DateRangeType;
}): SingleFilterDate => {
    return {
        filterId: 'eventStartDate',
        filterType: 'date',
        filterLabel: 'Event Start Date',
        filterApplied: true,
        filterFields: {
            metricFieldType: 'universal',
            fieldName: 'event__live_event_start_datetime',
        },
        dateRange: dateRange,
        customRange: {
            startDate: '',
            endDate: '',
        },
    };
};

/**Not currently used anywhere */
export const getCurrencyFilter = ({
    currencyIds,
}: {
    currencyIds: string[];
}): SingleFilterMultiselectDropdownConsolidated => {
    return {
        filterId: 'currency',
        filterType: 'multiselectDropdown',
        filterFields: {
            metricFieldType: 'universal',
            fieldName: 'currency_code__entry_id',
        },
        filterLabel: 'Currency',
        modifierType: 'CONSOLIDATED',
        modifierValue: 'IN',
        values: currencyIds,
        filterApplied: true,
    };
};

export const getItemFilter = ({
    enterpriseItemIds,
}: {
    enterpriseItemIds: string[];
}): SingleFilterMultiselectDropdownConsolidated => {
    return {
        filterId: 'itemName',
        filterType: 'multiselectDropdown',
        filterFields: {
            metricFieldType: 'universal',
            fieldName: 'enterprise_item__enterprise_item_id',
        },
        filterLabel: 'Item',
        modifierType: 'CONSOLIDATED',
        modifierValue: 'IN',
        values: enterpriseItemIds,
        filterApplied: true,
    };
};

export const getMeasurementUnitFilter = ({
    measurementUnitId,
}: {
    measurementUnitId: string;
}): SingleFilterSingleselectDropdown => {
    return {
        filterId: 'measurmentUnit',
        filterType: 'singleselectDropdown',
        filterFields: {
            metricFieldType: 'universal',
            fieldName: 'measurement_unit__measurement_unit_id',
        },
        filterLabel: 'Measurement Unit',
        modifier: 'EQUALS',
        value: measurementUnitId,
        filterApplied: true,
    };
};

export const getSellerEntityFilter = ({
    sellerEntityId,
}: {
    sellerEntityId: string;
}): SingleFilterSingleselectDropdown => {
    return {
        filterId: 'sellerEntityId',
        filterType: 'singleselectDropdown',
        filterFields: {
            metricFieldType: 'universal',
            fieldName: 'seller_entity__entity_id',
        },
        filterLabel: 'Vendor',
        modifier: 'EQUALS',
        value: sellerEntityId,
        filterApplied: true,
    };
};

export const getItemFilterCategory = (
    filterSelection: {
        items: boolean;
        itemTags: boolean;
    } = {
        items: true,
        itemTags: true,
    }
): ChartFilterCategory => {
    const category: ChartFilterCategory = {
        categoryLabel: 'Items',
        expanded: true,
        filterConnector: 'OR',
        filters: [],
    };
    if (filterSelection.items) {
        category.filters.push({
            filterId: 'itemName',
            dropdownId: 'enterprise_item__enterprise_item_id',
            filterLabel: 'Items',
            filterApplied: true,
            filterType: 'multiselectDropdown',
            filterFields: {
                metricFieldType: 'universal',
                fieldName: 'enterprise_item__enterprise_item_id',
            },
            modifierType: 'CONSOLIDATED',
            modifierValue: 'IN',
            values: [],
        });
    }
    if (filterSelection.itemTags) {
        category.filters.push({
            filterId: 'itemTags',
            dropdownId: 'enterprise_item__tags',
            filterLabel: 'Item tags',
            filterApplied: true,
            filterType: 'multiselectDropdown',
            filterFields: {
                metricFieldType: 'universal',
                fieldName: 'enterprise_item__tags',
            },
            modifierType: 'SEPARATE',
            modifierValue: 'CONTAINS',
            values: [],
        });
    }
    return category;
};

export const getVendorFilterCategory = (
    filterSelection: {
        vendors: boolean;
        vendorTags: boolean;
    } = {
        vendors: true,
        vendorTags: true,
    }
): ChartFilterCategory => {
    const category: ChartFilterCategory = {
        categoryLabel: 'Vendors',
        expanded: true,
        filterConnector: 'OR',
        filters: [],
    };
    if (filterSelection.vendors) {
        category.filters.push({
            filterId: 'vendorName',
            dropdownId: 'seller_entity__entity_id',
            filterLabel: 'Vendors',
            filterApplied: true,
            filterType: 'multiselectDropdown',
            filterFields: {
                metricFieldType: 'universal',
                fieldName: 'seller_entity__entity_id',
            },
            modifierType: 'CONSOLIDATED',
            modifierValue: 'IN',
            values: [],
        });
    }
    if (filterSelection.vendorTags) {
        category.filters.push({
            filterId: 'vendorTags',
            dropdownId: 'enterprise_vendor_master__tags',
            filterLabel: 'Vendor tags',
            filterApplied: true,
            filterType: 'multiselectDropdown',
            filterFields: {
                metricFieldType: 'universal',
                fieldName: 'enterprise_vendor_master__tags',
            },
            modifierType: 'SEPARATE',
            modifierValue: 'CONTAINS',
            values: [],
        });
    }
    return category;
};

export const getUserFilterCategory = ({
    event__original_event_creator = 'NOT_APPLICABLE',
    rfq_event__final_approver = 'NOT_APPLICABLE',
    po__created_by_user = 'NOT_APPLICABLE',
    po__final_buyer_approval = 'NOT_APPLICABLE',
}: {
    event__original_event_creator?: 'DEFAULT' | 'APPLICABLE' | 'NOT_APPLICABLE';
    rfq_event__final_approver?: 'DEFAULT' | 'APPLICABLE' | 'NOT_APPLICABLE';
    po__created_by_user?: 'DEFAULT' | 'APPLICABLE' | 'NOT_APPLICABLE';
    po__final_buyer_approval?: 'DEFAULT' | 'APPLICABLE' | 'NOT_APPLICABLE';
}): ChartFilterCategory => {
    const userCategory: ChartFilterCategory = {
        categoryLabel: 'Users',
        expanded: true,
        filterConnector: 'OR',
        filters: [],
        connectorEditable: true,
    };
    if (event__original_event_creator !== 'NOT_APPLICABLE') {
        userCategory.filters.push({
            filterId: 'eventCreator',
            dropdownId: 'event__original_event_creator',
            filterLabel: 'Event Creator',
            filterType: 'multiselectDropdown',
            filterApplied: event__original_event_creator === 'DEFAULT',
            filterFields: {
                metricFieldType: 'universal',
                fieldName: 'event__original_event_creator',
            },
            modifierType: 'CONSOLIDATED',
            modifierValue: 'IN',
            values: [],
        });
    }
    if (rfq_event__final_approver !== 'NOT_APPLICABLE') {
        userCategory.filters.push({
            filterId: 'eventApprover',
            dropdownId: 'rfq_event__final_approver',
            filterLabel: 'Event Approver',
            filterType: 'multiselectDropdown',
            filterApplied: rfq_event__final_approver === 'DEFAULT',
            filterFields: {
                metricFieldType: 'universal',
                fieldName: 'rfq_event__final_approver',
            },
            modifierType: 'CONSOLIDATED',
            modifierValue: 'IN',
            values: [],
        });
    }
    if (po__created_by_user !== 'NOT_APPLICABLE') {
        userCategory.filters.push({
            filterId: 'poCreator',
            dropdownId: 'po__created_by_user',
            filterLabel: 'PO Creator',
            filterType: 'multiselectDropdown',
            filterApplied: po__created_by_user === 'DEFAULT',
            filterFields: {
                metricFieldType: 'universal',
                fieldName: 'po__created_by_user',
            },
            modifierType: 'CONSOLIDATED',
            modifierValue: 'IN',
            values: [],
        });
    }
    if (po__final_buyer_approval !== 'NOT_APPLICABLE') {
        userCategory.filters.push({
            filterId: 'poApprover',
            dropdownId: 'po__final_buyer_approval',
            filterLabel: 'PO Approver',
            filterType: 'multiselectDropdown',
            filterApplied: po__final_buyer_approval === 'DEFAULT',
            filterFields: {
                metricFieldType: 'universal',
                fieldName: 'po__final_buyer_approval',
            },
            modifierType: 'CONSOLIDATED',
            modifierValue: 'IN',
            values: [],
        });
    }

    //Sort the filters - filters with filterApplied=True should be first
    userCategory.filters.sort((a, b) => {
        if (a.filterApplied && !b.filterApplied) {
            return -1;
        } else if (!a.filterApplied && b.filterApplied) {
            return 1;
        } else {
            return 0;
        }
    });

    return userCategory;
};
