import { useLayoutEffect } from 'react';
import { createPortal } from 'react-dom';

interface IFWPortalProps {
    children: JSX.Element;
    id: string;
}

const FWPortal = ({ children, id }: IFWPortalProps) => {
    const mount = document.getElementById(id);
    const el = document.createElement('div');

    useLayoutEffect(() => {
        if (mount) {
            mount.appendChild(el);
        }
        return () => {
            if (mount) {
                mount.removeChild(el);
            }
        };
    }, [el, mount]);

    return createPortal(children, el);
};

export default FWPortal;
