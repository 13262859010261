import { getFWDateTime } from '../../../Common/Utils/DateUtils';
import { IBuyerItemList } from '../../../Events/Interfaces/Buyer/RFQ.model';
import {
    itemEntityStatusStatusMapService,
    prefVenAssgnStatusMapService,
} from '../Constants/ItemDirectoryConstants';
import {
    IItemSummary,
    ItemEntityStatus,
    ItemStatus,
} from '../Interfaces/ItemDirectoryInterface';
import { IVendorTemplateFrontEnd } from '../Interfaces/VendorsInterface';

export const blankVendorTemplate: IVendorTemplateFrontEnd = {
    templateId: '',
    templateName: '',
    fieldSettings: {
        standardFields: {
            PRIMARY_CONTACT: {
                standardFieldCategory: 'STANDARD',
                label: 'Primary contact',
                description: '',
                additional_information: {
                    is_hidden: false,
                },
                constraints: {
                    field_type: 'COLLECTION',
                },
                is_builtin_field: true,
                is_required: true,
                is_mandatory: false,
            },
            ADDITIONAL_COSTS: {
                standardFieldCategory: 'STANDARD',
                label: 'Primary contact',
                description: '',
                additional_information: {
                    is_hidden: false,
                },
                constraints: {
                    field_type: 'COLLECTION',
                },
                is_builtin_field: true,
                is_required: true,
                is_mandatory: false,
            },
            PRIMARY_CONTACT_EMAIL: {
                standardFieldCategory: 'STANDARD',
                label: 'Primary contact email',
                description: '',
                additional_information: {
                    is_hidden: false,
                },
                constraints: {
                    max_limit: 100,
                    min_limit: 1,
                    field_type: 'SHORTTEXT',
                },
                is_builtin_field: true,
                is_required: true,
                is_mandatory: false,
            },
            PRIMARY_CONTACT_FULL_NAME: {
                standardFieldCategory: 'STANDARD',
                label: 'Primary contact full name',
                description: '',
                additional_information: {
                    is_hidden: false,
                },
                constraints: {
                    max_limit: 50,
                    min_limit: 1,
                    field_type: 'SHORTTEXT',
                },
                is_builtin_field: true,
                is_required: true,
                is_mandatory: false,
            },
            PRIMARY_CONTACT_NOTES: {
                standardFieldCategory: 'STANDARD',
                label: 'Primary contact notes',
                description: '',
                additional_information: {
                    is_hidden: false,
                },
                constraints: {
                    max_limit: 1000,
                    min_limit: 1,
                    field_type: 'LONGTEXT',
                },
                is_builtin_field: true,
                is_required: false,
                is_mandatory: false,
            },
            PRIMARY_CONTACT_PHONE_NUMBER: {
                standardFieldCategory: 'STANDARD',
                label: 'Primary contact phone number',
                description: '',
                additional_information: {
                    is_hidden: false,
                },
                constraints: {
                    max_limit: 20,
                    min_limit: 1,
                    field_type: 'SHORTTEXT',
                },
                is_builtin_field: true,
                is_required: false,
                is_mandatory: false,
            },
            PRIMARY_CONTACT_SECONDARY_EMAIL: {
                standardFieldCategory: 'STANDARD',
                label: 'Primary contact secondary email',
                description: '',
                additional_information: {
                    is_hidden: false,
                },
                constraints: {
                    max_limit: 1000,
                    min_limit: 1,
                    field_type: 'LONGTEXT',
                },
                is_builtin_field: true,
                is_required: false,
                is_mandatory: false,
            },
            PRIMARY_CONTACT_CC_EMAIL: {
                standardFieldCategory: 'STANDARD',
                label: 'Primary contact cc email',
                description: '',
                additional_information: {
                    is_hidden: false,
                },
                constraints: {
                    max_limit: 1000,
                    min_limit: 1,
                    field_type: 'LONGTEXT',
                },
                is_builtin_field: true,
                is_required: false,
                is_mandatory: false,
            },
            SECONDARY_CONTACT: {
                standardFieldCategory: 'STANDARD',
                label: 'Secondary contact',
                description: '',
                additional_information: {
                    is_hidden: false,
                },
                constraints: {
                    field_type: 'COLLECTION',
                },
                is_builtin_field: true,
                is_required: false,
                is_mandatory: false,
            },
            SECONDARY_CONTACT_FULL_NAME: {
                standardFieldCategory: 'STANDARD',
                label: 'Secondary contact full name',
                description: '',
                additional_information: {
                    is_hidden: false,
                },
                constraints: {
                    max_limit: 50,
                    min_limit: 1,
                    field_type: 'SHORTTEXT',
                },
                is_builtin_field: true,
                is_required: false,
                is_mandatory: false,
            },
            SECONDARY_CONTACT_EMAIL: {
                standardFieldCategory: 'STANDARD',
                label: 'Secondary contact email',
                description: '',
                additional_information: {
                    is_hidden: false,
                },
                constraints: {
                    max_limit: 100,
                    min_limit: 1,
                    field_type: 'SHORTTEXT',
                },
                is_builtin_field: true,
                is_required: false,
                is_mandatory: false,
            },
            SECONDARY_CONTACT_PHONE_NUMBER: {
                standardFieldCategory: 'STANDARD',
                label: 'Secondary contact phone number',
                description: '',
                additional_information: {
                    is_hidden: false,
                },
                constraints: {
                    max_limit: 20,
                    min_limit: 1,
                    field_type: 'SHORTTEXT',
                },
                is_builtin_field: true,
                is_required: false,
                is_mandatory: false,
            },
            SECONDARY_CONTACT_SECONDARY_EMAIL: {
                standardFieldCategory: 'STANDARD',
                label: 'Secondary contact secondary email',
                description: '',
                additional_information: {
                    is_hidden: false,
                },
                constraints: {
                    max_limit: 1000,
                    min_limit: 1,
                    field_type: 'LONGTEXT',
                },
                is_builtin_field: true,
                is_required: false,
                is_mandatory: false,
            },
            SECONDARY_CONTACT_CC_EMAIL: {
                standardFieldCategory: 'STANDARD',
                label: 'Secondary contact cc email',
                description: '',
                additional_information: {
                    is_hidden: false,
                },
                constraints: {
                    max_limit: 1000,
                    min_limit: 1,
                    field_type: 'LONGTEXT',
                },
                is_builtin_field: true,
                is_required: false,
                is_mandatory: false,
            },
            SECONDARY_CONTACT_NOTES: {
                standardFieldCategory: 'STANDARD',
                label: 'Secondary contact notes',
                description: '',
                additional_information: {
                    is_hidden: false,
                },
                constraints: {
                    max_limit: 1000,
                    min_limit: 1,
                    field_type: 'LONGTEXT',
                },
                is_builtin_field: true,
                is_required: false,
                is_mandatory: false,
            },
            NOTES: {
                standardFieldCategory: 'STANDARD',
                label: 'Notes',
                description: '',
                additional_information: {
                    is_hidden: false,
                },
                constraints: {
                    max_limit: 1000,
                    min_limit: 1,
                    field_type: 'LONGTEXT',
                },
                is_builtin_field: true,
                is_required: false,
                is_mandatory: false,
            },
            VENDOR_CODE: {
                standardFieldCategory: 'STANDARD',
                label: 'Vendor code',
                description: '',
                additional_information: {
                    is_hidden: false,
                },
                constraints: {
                    max_limit: 200,
                    min_limit: 1,
                    field_type: 'SHORTTEXT',
                },
                is_builtin_field: true,
                is_required: true,
                is_mandatory: false,
            },
            VENDOR_NAME: {
                standardFieldCategory: 'STANDARD',
                label: 'Vendor name',
                description: '',
                additional_information: {
                    is_hidden: false,
                },
                constraints: {
                    max_limit: 200,
                    min_limit: 1,
                    field_type: 'SHORTTEXT',
                },
                is_builtin_field: true,
                is_required: true,
                is_mandatory: false,
            },
            VENDOR_ADDRESS: {
                standardFieldCategory: 'STANDARD',
                label: 'Vendor address',
                description: '',
                additional_information: {
                    is_hidden: false,
                },
                constraints: {
                    max_limit: 200,
                    min_limit: 1,
                    field_type: 'SHORTTEXT',
                },
                is_builtin_field: true,
                is_required: false,
                is_mandatory: false,
            },
            VENDOR_IDENTIFICATION_VALUE: {
                standardFieldCategory: 'STANDARD',
                label: 'Vendor identification value',
                description: '',
                additional_information: {
                    is_hidden: false,
                },
                constraints: {
                    max_limit: 100,
                    min_limit: 1,
                    field_type: 'SHORTTEXT',
                },
                is_builtin_field: true,
                is_required: false,
                is_mandatory: false,
            },
            VENDOR_IDENTIFICATION_NAME: {
                standardFieldCategory: 'STANDARD',
                label: 'Vendor identification name',
                description: '',
                additional_information: {
                    is_hidden: false,
                },
                constraints: {
                    max_limit: 100,
                    min_limit: 1,
                    field_type: 'SHORTTEXT',
                },
                is_builtin_field: true,
                is_required: false,
                is_mandatory: false,
            },
            VENDOR_IDENTIFICATION: {
                standardFieldCategory: 'STANDARD',
                label: 'Vendor identification',
                description: '',
                additional_information: {
                    is_hidden: false,
                },
                constraints: {
                    field_type: 'COLLECTION',
                },
                is_builtin_field: true,
                is_required: true,
                is_mandatory: false,
            },
            TAG: {
                standardFieldCategory: 'STANDARD',
                label: 'Tag',
                description: '',
                additional_information: {
                    is_hidden: false,
                },
                constraints: {
                    max_limit: 100,
                    min_limit: 1,
                    field_type: 'SHORTTEXT',
                },
                is_builtin_field: true,
                is_required: false,
                is_mandatory: false,
            },
        },
        customFields: {},
        additionalCosts: {},
    },
    sections: {
        VENDOR_DETAILS: {
            fieldList: [],
            label: '',
        },
    },
};

export const convertRawItemDataToItemSummary: (
    itemsList: IBuyerItemList[]
) => IItemSummary[] = (data) => {
    const items: IItemSummary[] = data
        .filter(
            (item) =>
                item.enterprise_item.status === ItemStatus.ACTIVE &&
                item.status === ItemEntityStatus.ACTIVE
        )
        .sort((a, b) => {
            if (
                a.enterprise_item.name.toLowerCase() <
                b.enterprise_item.name.toLowerCase()
            ) {
                return -1;
            }
            if (
                a.enterprise_item.name.toLowerCase() >
                b.enterprise_item.name.toLowerCase()
            ) {
                return 1;
            }
            return 0;
        })
        .map((item, index) => {
            const newItem: IItemSummary = {
                index,
                id: item.enterprise_item.enterprise_item_id,
                itemCode: item.enterprise_item.code,
                name: item.enterprise_item.name,
                entities: [], // not needed in entity dashboard
                entities_id: [],
                lastPrice: /* item.lastPrice */ '0',
                status: item.enterprise_item.status as ItemStatus,
                locked: false,
                description: item.enterprise_item.description,
                specifications: item.enterprise_item.attributes,
                measurementUnits:
                    item.enterprise_item.measurement_units.item_measurement_units.map(
                        (unit: any) => unit.measurement_unit_id
                    ),
                notes: item.enterprise_item.notes,
                tags: item?.enterprise_item?.tags,
                customIds: item.enterprise_item.custom_ids.custom_ids.map(
                    (customId: any) => {
                        return {
                            category: customId.category,
                            name: customId.name,
                            value: customId.value,
                        };
                    }
                ),
                preferredVendorAssignedStatus:
                    prefVenAssgnStatusMapService[
                        (item as any).enterprise_item.preferred_vendor_status
                    ],
                preferredVendorsCount: item.preferred_vendors_count,
                preferredVendors: item.preferred_vendors,
                entityStatus: itemEntityStatusStatusMapService[item.status],
                modifiedDateTime: getFWDateTime(
                    (item as any).modified_datetime
                ),
                buyerPricingInformation: null,
                sellerPricingInformation: null,
                isBuyer: false,
                isSeller: false,
                hasNext: item.hasNext,
                bom_template: null,
            };
            return newItem;
        });
    return items;
};

export const convertSummaryItemToRawItemData: (
    itemsList: IItemSummary[]
) => IBuyerItemList[] = (data) => {
    const items: IBuyerItemList[] = data.map((item) => {
        const newItem: IBuyerItemList = {
            enterprise_item: {
                enterprise_item_id: item.id,
                code: item.itemCode,
                buyer_additional_costs: [],
                seller_additional_costs: [],
                name: item.name,
                status: item.status as 'ACTIVE' | 'INACTIVE',
                description: item.description,
                attributes: [],
                measurement_units: {
                    item_measurement_units: [],
                },
                notes: item.notes,
                tags: item.tags,
                custom_ids: {
                    custom_ids: [],
                },
                // preferred_vendor_status:
                //     item.preferredVendorAssignedStatus,
                buyer_enterprise: '',
                buyer_pricing_information: null,
                item_type: 'FINISHED_GOOD',
                seller_pricing_information: null,
            },
            status: item.entityStatus as ItemEntityStatus,
            preferred_vendors_count: item.preferredVendorsCount,
            preferred_vendors: item.preferredVendors,
            hasNext: item.hasNext,
            buyer_enterprise: '',
            buyer_entity: '',
            entity_item_id: '',
        };
        return newItem;
    });
    return items;
};
