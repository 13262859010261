import { Box } from '@mui/material';
import { useState } from 'react';
import { FWTypography } from '../../../../Common/FWTypograhy';
import { IVLNAboutNewFactoryLocations } from '../../../Interfaces/VendorLNInterfaces';
import { VLNProfileStyledBox } from '../../Common/VLNProfileStyledBox';
import VLNProfileAboutLocationComponent from './VLNProfileAboutLocationComponent';
import VLNProfileAboutOtherInfoLocationsPopup from './VLNProfileAboutOtherInfoLocationsPopup';

const VLNProfileAboutOtherInfoLocations = ({
    locationsList,
}: {
    locationsList: IVLNAboutNewFactoryLocations[];
}) => {
    const [open, setOpen] = useState(false);
    

    const newLocationList = locationsList?.filter(
        (vendorAdd) =>
            vendorAdd.is_public &&
            vendorAdd.status === 'ACTIVE' &&
            vendorAdd.address.status === 'ACTIVE'
    );

    return (
        <VLNProfileStyledBox>
            <Box className="flex flex--aifs flex--jcsb" marginBottom={2}>
                <FWTypography variant={'h4'} fontWeight={500}>
                    Locations ({newLocationList?.length})
                </FWTypography>
                {newLocationList?.length > 3 && (
                    <FWTypography
                        color={'primary.main'}
                        sx={{
                            textDecoration: 'underline',
                            cursor: 'pointer',
                        }}
                        onClick={() => setOpen(true)}
                    >
                        View all
                    </FWTypography>
                )}
            </Box>
            {newLocationList.length > 0 ? (
                newLocationList.slice(0, 3).map((item, idx) => (
                    <Box
                        key={idx}
                        marginBottom={
                            idx === newLocationList.slice(0, 3).length - 1
                                ? 0
                                : 3
                        }
                    >
                        <VLNProfileAboutLocationComponent locationData={item} />
                    </Box>
                ))
            ) : (
                <FWTypography color={'text.secondary'} textAlign={'center'}>
                    No locations added
                </FWTypography>
            )}

            <VLNProfileAboutOtherInfoLocationsPopup
                locationsList={newLocationList}
                open={open}
                handleClose={() => setOpen(false)}
            />
        </VLNProfileStyledBox>
    );
};

export default VLNProfileAboutOtherInfoLocations;
