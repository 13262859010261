import { Box, Chip, DialogActions, DialogContent } from '@mui/material';
import { isArray } from 'lodash';
import React from 'react';
import FWAutocomplete from '../../../Common/FWAutocomplete';
import { FWButton } from '../../../Common/FWButton';
import { FWMenuItem } from '../../../Common/FWCustomMenu';
import { FWInput } from '../../../Common/FWInput';
import { FWPopupWithChildren } from '../../../Common/FWPopupWithChildren';
import FWSelect from '../../../Common/FWSelect';
import { FWTypography } from '../../../Common/FWTypograhy';
import { measurementUnitCategories } from '../../../Organizations/Admin/Containers/ItemDirectory/AddMeasurementUnitContainer';
import {
    TUOMCategories,
    TUOMTypes,
    useUOMMeasurementViewPopup,
} from '../../Hooks/UOM/useUOMMeasurementViewPopup';

const UOMMeasurementViewPopup = ({
    handleClose,
    open,
    onSaved,
    initialData,
}: {
    open: boolean;
    handleClose: () => void;
    onSaved: (measurementUditId: string) => void;
    initialData?: {
        name: string;
        symbol: string;
        synonyms: {
            editable: string[];
            nonEditable: string[];
        };
        category: TUOMCategories;
        type: TUOMTypes;
        measurementUnitId: string;
        multiplier: string;
    };
}) => {
    const {
        measurementData,
        handleNameChange,
        handleAbbreviationChange,
        handleSynonymsChange,
        handleCategoryChange,
        handleTypeChange,
        handleSubmit,
        errors,
        isValid,
        submitting,
        resetState,
        handleMultiplierChange,
        // handleValueChange,
        standardConversionUnit,
    } = useUOMMeasurementViewPopup(initialData);

    const handleCloseWithReset = () => {
        handleClose();
        resetState();
    };

    const [error, setError] = React.useState<boolean>(false);
    const handleKeyDown = (event: any) => {
        switch (event.key) {
            case ',': {
                event.preventDefault();
                event.stopPropagation();
                break;
            }
            case 'Enter': {
                if (error) {
                    event.preventDefault();
                    event.stopPropagation();
                }
                break;
            }
            default:
        }
    };
    // ${!!initialData ? 'Edit' : 'Add'} a new Measurement Unit
    return (
        <FWPopupWithChildren
            title={`${
                !!initialData ? 'Edit measurement unit' : 'New measurement unit'
            } `}
            open={open}
            applyTitleStyling={false}
            handleClose={handleCloseWithReset}
            size="custom"
            popupWidth={'650px'}
        >
            <DialogContent>
                <Box className="flex flex--aic flex--jcsb" gap={2}>
                    <Box width={'100%'}>
                        <FWTypography color={'text.secondary'}>
                            Name
                        </FWTypography>
                        <FWInput
                            value={measurementData.name}
                            onChange={(e) => handleNameChange(e.target.value)}
                            // onChange={(e) =>
                            //     handleValueChange({
                            //         key: 'name',
                            //         value: e.target.value,
                            //     })
                            // }
                            error={!!errors.name}
                            helper={{
                                text: errors.name,
                            }}
                            disabled={!!initialData}
                        />
                    </Box>
                    <Box width={'100%'}>
                        <FWTypography color={'text.secondary'}>
                            Abbreviation
                        </FWTypography>
                        <FWInput
                            sx={{ width: '100%' }}
                            value={measurementData.symbol}
                            onChange={(e) =>
                                handleAbbreviationChange(e.target.value)
                            }
                            // onChange={(e) =>
                            //     handleValueChange({
                            //         key: 'symbol',
                            //         value: e.target.value,
                            //     })
                            // }
                            error={!!errors.symbol}
                            helper={{
                                text: errors.symbol,
                            }}
                            disabled={!!initialData}
                        />
                    </Box>
                </Box>
                &nbsp;
                <Box>
                    <FWTypography color="text.secondary">
                        Measurement Unit Category
                    </FWTypography>
                    <FWSelect
                        value={measurementData.category}
                        fullWidth
                        onChange={(e) =>
                            handleCategoryChange(
                                e.target.value as TUOMCategories
                            )
                        }
                        // onChange={(e) =>
                        //     handleValueChange({
                        //         key: 'category',
                        //         value: e.target.value as TUOMCategories,
                        //     })
                        // }
                        disabled={!!initialData}
                    >
                        {measurementUnitCategories.map((category) => (
                            <FWMenuItem value={category.apiName}>
                                {category.displayName}
                            </FWMenuItem>
                        ))}
                    </FWSelect>
                </Box>
                {!initialData && measurementData.category !== 'OTHER' && (
                    <>
                        &nbsp;
                        <Box className={'flex flex--aic'}>
                            <FWTypography
                                sx={{ maxWidth: '50%' }}
                            >{`1 ${measurementData.symbol}`}</FWTypography>
                            <FWTypography>{`→`}</FWTypography>
                            &nbsp;
                            <FWInput
                                value={measurementData.multiplier}
                                onChange={(e) =>
                                    handleMultiplierChange(e.target.value)
                                }
                                // onChange={(e) =>
                                //     handleValueChange({
                                //         key: 'multiplier',
                                //         value: e.target.value,
                                //     })
                                // }
                                type="number"
                                allowOnly="DECIMAL_NUMBER"
                                sx={{
                                    flex: '1',
                                }}
                                error={!!errors.multiplier}
                                helper={{
                                    text: errors.multiplier,
                                }}
                                iconEnd={`${
                                    standardConversionUnit?.measurement_unit
                                        .measurement_unit_primary_name
                                        ? `${standardConversionUnit?.measurement_unit.measurement_unit_primary_name}(s)`
                                        : ''
                                }`}
                            />
                        </Box>
                    </>
                )}
                &nbsp;
                {initialData && (
                    <>
                        &nbsp;
                        <Box className={'flex flex--aic'}>
                            <FWTypography
                                sx={{ maxWidth: '50%' }}
                            >{`1 ${measurementData.symbol}`}</FWTypography>
                            &nbsp;
                            <FWTypography>{`→`}</FWTypography>
                            &nbsp;
                            <FWInput
                                value={measurementData.multiplier}
                                onChange={(e) =>
                                    handleMultiplierChange(e.target.value)
                                }
                                // onChange={(e) =>
                                //     handleValueChange({
                                //         key: 'multiplier',
                                //         value: e.target.value,
                                //     })
                                // }
                                disabled={true}
                                type="number"
                                allowOnly="DECIMAL_NUMBER"
                                sx={{
                                    flex: '1',
                                }}
                                error={!!errors.multiplier}
                                helper={{
                                    text: errors.multiplier,
                                }}
                                iconEnd={`${
                                    standardConversionUnit?.measurement_unit
                                        .measurement_unit_primary_name
                                        ? `${standardConversionUnit?.measurement_unit.measurement_unit_primary_name}(s)`
                                        : ''
                                }`}
                            />
                        </Box>
                    </>
                )}
                <Box>
                    <FWTypography color="text.secondary">
                        Value type
                    </FWTypography>
                    <FWSelect
                        value={measurementData.type}
                        fullWidth
                        onChange={(e) =>
                            handleTypeChange(e.target.value as TUOMTypes)
                        }
                        // onChange={(e) =>
                        //     handleValueChange({
                        //         key: 'type',
                        //         value: e.target.value as TUOMTypes,
                        //     })
                        // }
                        disabled={!!initialData}
                    >
                        <FWMenuItem value={'INTEGER'}>Integer</FWMenuItem>
                        <FWMenuItem value={'DECIMAL'}>Decimal</FWMenuItem>
                    </FWSelect>
                </Box>
                &nbsp;
                <FWTypography color={'text.secondary'}>Synonyms</FWTypography>
                <FWAutocomplete
                    freeSolo
                    multiple
                    disableClearable
                    options={[
                        ...(isArray(measurementData?.synonyms?.editable)
                            ? measurementData.synonyms?.editable
                            : []),
                        ...(isArray(measurementData?.synonyms?.nonEditable)
                            ? measurementData.synonyms?.nonEditable
                            : []),
                    ]}
                    value={
                        [
                            ...(isArray(measurementData?.synonyms?.editable)
                                ? measurementData.synonyms?.nonEditable
                                : []),
                            ...(isArray(measurementData?.synonyms?.nonEditable)
                                ? measurementData.synonyms?.editable
                                : []),
                        ] ?? []
                    }
                    filterSelectedOptions
                    onChange={(e, newVal) => {
                        if (typeof newVal === 'string') {
                            handleSynonymsChange([newVal]);
                        } else {
                            handleSynonymsChange(newVal);
                        }
                    }}
                    // onChange={(e, newVal) =>
                    //     handleValueChange({
                    //         key: 'type',
                    //         value:
                    //             typeof newVal === 'string' ? [newVal] : newVal,
                    //     })
                    // }
                    onInputChange={(event, newInputValue) => {
                        if (
                            measurementData.synonyms?.editable?.some(
                                (soloValue) =>
                                    soloValue.trim().toLocaleLowerCase() ===
                                    newInputValue.trim().toLowerCase()
                            ) ||
                            measurementData.synonyms?.nonEditable?.some(
                                (soloValue) =>
                                    soloValue.trim().toLocaleLowerCase() ===
                                    newInputValue.trim().toLowerCase()
                            )
                        ) {
                            setError(true);
                        } else {
                            setError(false);
                        }
                    }}
                    //                     renderTags={(value: readonly string[], getTagProps) =>
                    //                         value.map((option: string, index: number) =>  {
                    // measurementData?.synonyms?.editable?.some((syn) => syn === option) ? (

                    // <Chip label={option} {...getTagProps({ index })} />|
                    // ) : (
                    //     <Chip label={option} {...getTagProps({ index })} />|
                    // )
                    //                         }
                    //                         )
                    //                     }
                    renderTags={(value: readonly string[], getTagProps) => {
                        return value.map((option: string, index: number) => {
                            if (
                                measurementData?.synonyms?.nonEditable?.some(
                                    (syn) => syn === option
                                )
                            ) {
                                return (
                                    <>
                                        <Chip
                                            label={option}
                                            sx={{
                                                marginRight: '5px',
                                                marginY: '2.5px',
                                            }}
                                        />
                                    </>
                                );
                            }
                            return (
                                <>
                                    <Chip
                                        label={option}
                                        {...getTagProps({ index })}
                                        sx={{
                                            marginRight: '5px',
                                            marginY: '2.5px',
                                        }}
                                    />
                                </>
                            );
                        });
                    }}
                    renderInput={(params) => {
                        params.inputProps.onKeyDown = handleKeyDown;

                        return (
                            <FWInput
                                {...params}
                                placeholder={''}
                                value={measurementData.name}
                                rows={5}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        padding: '0px 20px 0px 6px!important',
                                    },
                                    overflowY: 'scroll',
                                }}
                                error={!!errors.synonyms}
                                helper={{
                                    text: errors.synonyms,
                                }}
                            />
                        );
                    }}
                />
            </DialogContent>
            <DialogActions
                sx={{
                    padding: '20px 24px',
                }}
            >
                <FWButton variant="outlined" onClick={handleCloseWithReset}>
                    Cancel
                </FWButton>
                <FWButton
                    variant="contained"
                    onClick={() => {
                        handleSubmit((success, measurementUnitUid) => {
                            if (success) {
                                handleCloseWithReset();
                                onSaved(measurementUnitUid);
                            }
                        });
                    }}
                    disabled={!isValid || submitting}
                >
                    {submitting ? 'Saving...' : 'Save'}
                </FWButton>
            </DialogActions>
        </FWPopupWithChildren>
    );
};

export default UOMMeasurementViewPopup;
