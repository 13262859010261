export function handleError(err: any) {
    if (err.response?.status === 404 || err.response?.status === 405)
        return {
            error: true,
            msg: 'We could not find the resource you requested',
        };
    else if (err.response?.status === 401 || err.response?.status === 400)
        return {
            error: true,
            msg: 'Incorrect Password',
        };
    else if (err.response?.status === 500)
        return {
            error: 'true',
            msg: 'Incorrect Password',
        };
    else {
        return {
            error: true,
            msg: 'Request failed please try again',
        };
    }
}
export function otpLoginErrorHndler(err: any) {
    if (err.response?.status === 404 || err.response?.status === 405)
        return {
            error: true,
            msg: 'We could not find the resource you requested',
        };
    else if (err.response.data.ErrorCode.includes('OTP expired')) {
        return {
            error: 'true',
            msg: 'OTP expired! Please generate a new OTP',
        };
    } else if (err.response?.status === 401 || err.response?.status === 400)
        return {
            error: true,
            msg: err.response.data.ErrorCode,
        };
    else if (
        err.response?.status === 500 &&
        err.response.data.ErrorCode.includes('NotAuthorizedException')
    )
        return {
            error: 'true',
            msg: 'Session Expired, please try again later',
        };
    else if (err.response?.status === 500)
        return {
            error: 'true',
            msg: 'Incorrect OTP',
        };
    else {
        return {
            error: true,
            msg: 'Request failed please try again',
        };
    }
}
export function otpInitialiseErrorHndler(err: any) {
    if (err.response?.status === 404 || err.response?.status === 405)
        return {
            error: true,
            msg: 'We could not find the resource you requested',
        };
    else if (err.response?.status === 401 || err.response?.status === 400)
        return {
            error: true,
            msg: err.response.data.ErrorCode,
        };
    else if (err.response?.status === 500)
        return {
            error: 'true',
            msg: 'Request failed please try again later',
        };
    else {
        return {
            error: true,
            msg: 'Request failed please try again',
        };
    }
}
export function emailLoginErrorHandler(err: any) {
    if (err.response?.status === 404 || err.response?.status === 405)
        return {
            error: true,
            msg: 'We could not find the resource you requested',
        };
    else if (err.response?.status === 401 || err.response?.status === 400)
        return {
            error: true,
            msg: err.response.data.ErrorCode,
        };
    else if (err.response?.status === 500)
        return {
            error: 'true',
            msg: 'Enter valid login credentials',
        };
    else {
        return {
            error: true,
            msg: 'Request failed please try again',
        };
    }
}
