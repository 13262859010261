import { useContext, useEffect, useRef } from 'react';
import {
    Box,
    Chip,
    CircularProgress,
    Divider,
    IconButton,
} from '@mui/material';
import { cloneDeep } from 'lodash';
import { Fragment, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { FWInput } from '../../Common/FWInput';
import { FWTypography } from '../../Common/FWTypograhy';
import { IEventItemDetails } from '../../Events/Interfaces/Buyer/RFQ.model';
import { IAddedVendorList } from '../../Events/Services/Buyer/RFQ.service';
import VendorSelectPopup from '../Components/VendorSelectPopup';
import { ChatTypes } from '../Interfaces/Chat.model';
import { addMessagesReceived, addMessagesToSend } from '../Slice/Chat.slice';
import { uploadFile } from '../../Global/Services/FileStorageService';
import { IFileResourceType } from '../../Components/Shared/UploadFile';
import { toast } from 'react-toastify';
import { FWTooltip } from '../../Common/FWCustomTooltip';
import { getMessageCount, sendNotesMessage } from '../Services/chat.service';
import { AuthContext } from '../../Contexts/AuthContext';

function makeid(length: number) {
    var result = '';
    var characters =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(
            Math.floor(Math.random() * charactersLength)
        );
    }
    return result;
}

export function BroadcastMessageButtonContainer(props: {
    module_id: string;
    session_id: string;
    eventItemAndVendorDetails: {
        items: IEventItemDetails[];
        vendors: { [key: string]: IAddedVendorList[] };
    };
    showInput: boolean;
}) {
    const [message, setMessage] = useState<string>('');
    const [openVendorSelectPopup, setOpenVendorSelectPopup] =
        useState<boolean>(false);
    const [selectedVendorIDs, setSelectedVendorIDs] = useState<Set<string>>(
        new Set()
    );

    const dispatch = useDispatch();

    const uniqueVendors = useMemo(() => {
        const uniqueIDs: Set<string> = new Set();
        const uniqueVendors: IAddedVendorList[] = [];
        // map over vendors and add ids to set and filter and return unique vendors
        Object.values(props.eventItemAndVendorDetails.vendors).forEach(
            (vendorList) => {
                vendorList.forEach((vendor) => {
                    if (!uniqueIDs.has(vendor.entity_id)) {
                        uniqueIDs.add(vendor.entity_id);
                        uniqueVendors.push(vendor);
                    }
                });
            }
        );
        return uniqueVendors.sort((vendorA, vendorB) =>
            vendorA.entity_name < vendorB.entity_name
                ? -1
                : vendorA.entity_name > vendorB.entity_name
                ? 1
                : 0
        );
    }, [props.eventItemAndVendorDetails.vendors]);

    const preSelecetedItemIDs = useMemo(() => {
        let itemIds: Set<string> = new Set();
        for (let item of Object.keys(props.eventItemAndVendorDetails.vendors)) {
            let allVendorsSelectedForThisItem = true;
            for (let vendor of props.eventItemAndVendorDetails.vendors[item]) {
                if (!selectedVendorIDs.has(vendor.entity_id)) {
                    allVendorsSelectedForThisItem = false;
                    break;
                }
            }
            if (allVendorsSelectedForThisItem) {
                itemIds.add(item);
            }
        }
        return itemIds;
    }, [props.eventItemAndVendorDetails.vendors, selectedVendorIDs]);

    const [attachmentFile, setAttachmentFile] = useState<File[] | null>(null);
    const [attachments, setAttachments] = useState<string[]>([]);
    const [uploadingFiles, setUploadingFiles] = useState<boolean>(false);
    const [removedFiles, setRemovedFiles] = useState<Set<string>>(new Set());

    const sendMessage = () => {
        if (selectedVendorIDs.size > 0) {
            if (attachments.length > 0) {
                dispatch(
                    addMessagesToSend({
                        message: message,
                        session_id: props.session_id,
                        module_id: props.module_id,
                        message_type: ChatTypes.BROADCAST,
                        seller_entity_list: Array.from(selectedVendorIDs),
                        message_id: makeid(32),
                        attachment_ids: attachments,
                    })
                );
                setMessage('');
                attachments.length > 0 && setAttachments([]);
                attachmentFile &&
                    attachmentFile.length > 0 &&
                    setAttachmentFile(null);
                setRemovedFiles(new Set());
            } else if (message.trim().length > 0) {
                dispatch(
                    addMessagesToSend({
                        message: message,
                        session_id: props.session_id,
                        module_id: props.module_id,
                        message_type: ChatTypes.BROADCAST,
                        seller_entity_list: Array.from(selectedVendorIDs),
                        message_id: makeid(32),
                    })
                );
                setMessage('');
            }
        }
    };

    const inputRef = useRef<HTMLInputElement>(null);
    useEffect(() => {
        if (attachmentFile && attachmentFile.length > 0) {
            setUploadingFiles(true);
            const uploadPromise: Promise<any>[] = [];
            attachmentFile.forEach((file) => {
                uploadPromise.push(uploadFile(file, IFileResourceType.CHAT));
            });

            (async () => {
                try {
                    const res = await Promise.all(uploadPromise);
                    setAttachments(
                        res.map(({ attachment_id }) => attachment_id)
                    );
                    setUploadingFiles(false);
                } catch (error) {
                    toast.error('Error uploading file');
                    setUploadingFiles(false);
                }
            })();
        }
    }, [attachmentFile]);

    const handleChatAttachment = (e: any) => {
        setRemovedFiles(new Set());
        setAttachmentFile(Array.from(e.target.files || []));
    };

    return (
        <Box bgcolor="background.default" position={'relative'}>
            {!props.showInput && (
                <FWTooltip
                    title={`Cannot message as you do not have edit permission for this event`}
                >
                    <Box
                        position={'absolute'}
                        width={'100%'}
                        height={'100%'}
                        bgcolor={'#ffffffb5'}
                        zIndex={2}
                    ></Box>
                </FWTooltip>
            )}
            <Box className="flex flex--aic flex--jcsb" padding={'5px 10px'}>
                <Box className="flex flex--aic" flexWrap={'wrap'}>
                    <FWTypography>To:</FWTypography>
                    &nbsp;
                    {/* show all selected vendors as deletable MUI chips */}
                    {uniqueVendors.map((vendor) => {
                        if (selectedVendorIDs.has(vendor.entity_id)) {
                            return (
                                <Chip
                                    sx={{ margin: '6px' }}
                                    key={vendor.entity_id}
                                    label={vendor.entity_name}
                                    onDelete={() => {
                                        setSelectedVendorIDs((prev) => {
                                            let newSet = cloneDeep(prev);
                                            newSet.delete(vendor.entity_id);
                                            return newSet;
                                        });
                                    }}
                                />
                            );
                        } else
                            return <Fragment key={vendor.entity_id}></Fragment>;
                    })}
                </Box>
                <FWTooltip title="Add vendors">
                    <div>
                        <IconButton
                            color="primary"
                            onClick={() => setOpenVendorSelectPopup(true)}
                        >
                            <i className="bi bi-plus-circle" />
                        </IconButton>
                    </div>
                </FWTooltip>
            </Box>
            <Divider />
            <Box width={'100%'} display={'flex'} flexWrap={'wrap'}>
                {attachmentFile &&
                    attachmentFile.length > 0 &&
                    attachmentFile
                        .filter((val) => !removedFiles.has(val.name))
                        .map((file, idx) => (
                            <Box
                                bgcolor={'white'}
                                // width={'50%'}
                                border={'1px solid #b4b4b4'}
                                borderRadius={'12px'}
                                width={'auto'}
                                maxWidth={'30%'}
                                margin={1}
                                key={idx}
                                className="flex flex--aic flex--jcsb"
                            >
                                <FWTypography
                                    paddingY={0.7}
                                    paddingX={1}
                                    color={'grey'}
                                    fontSize={'15px'}
                                >
                                    {file.name}
                                </FWTypography>
                                <Box
                                    margin={1}
                                    sx={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        setRemovedFiles((prev) => {
                                            let newSet = cloneDeep(prev);
                                            newSet.add(file.name);
                                            return newSet;
                                        });
                                        setAttachments((prev) =>
                                            prev.filter(
                                                (attachment, idx2) =>
                                                    idx !== idx2
                                            )
                                        );
                                    }}
                                >
                                    <i className="bi bi-x-circle"></i>
                                </Box>
                            </Box>
                        ))}
            </Box>
            <Box className="flex flex--aic" padding={'10px'}>
                <FWInput
                    inputProps={{
                        style: {
                            margin: '2px 45px 2px 2px',
                        },
                    }}
                    multiline
                    maxRows={3}
                    placeholder="Your message"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    onKeyDown={(e: any) => {
                        if (e.keyCode === 13 && !e.shiftKey) {
                            sendMessage();
                            e.preventDefault();
                        }
                    }}
                    sx={{
                        // overflow: 'hidden',
                        ':-webkit-scrollbar': {
                            display: 'none !important',
                        },
                    }}
                    iconEnd={
                        <label htmlFor="chat-attachment">
                            {/* {!(message.trim().length > 0) && ( */}
                            <IconButton
                                size={'small'}
                                color={'primary'}
                                sx={{
                                    zIndex: 1,
                                    position: 'absolute',
                                    right: 10,
                                    top: '50%',
                                    transform: 'translateY(-50%)',
                                }}
                                onClick={() => {
                                    inputRef.current?.click();
                                }}
                            >
                                <input
                                    hidden
                                    id="chat-attachment"
                                    name="chat-attachment"
                                    type="file"
                                    ref={inputRef}
                                    multiple
                                    onChange={handleChatAttachment}
                                    onClick={(e: any) => {
                                        e.target.value = null;
                                    }}
                                />
                                <i
                                    style={{
                                        transform: 'rotate(45deg)',
                                    }}
                                    className="bi bi-paperclip"
                                ></i>
                            </IconButton>
                            {/* )} */}
                        </label>
                    }
                />
                &nbsp; &nbsp;
                {uploadingFiles ? (
                    <Box padding={'7px'}>
                        <CircularProgress size={30} />
                    </Box>
                ) : (
                    <FWTooltip
                        title={
                            selectedVendorIDs.size === 0
                                ? 'Please add vendors to send messages to'
                                : ''
                        }
                    >
                        <Box>
                            <IconButton
                                disabled={selectedVendorIDs.size === 0}
                                color="primary"
                                onClick={sendMessage}
                            >
                                <i className="bi bi-send"></i>
                            </IconButton>
                        </Box>
                    </FWTooltip>
                )}
            </Box>
            <VendorSelectPopup
                open={openVendorSelectPopup}
                onClose={() => setOpenVendorSelectPopup(false)}
                vendors={uniqueVendors}
                onSubmit={setSelectedVendorIDs}
                itemVendors={props.eventItemAndVendorDetails.vendors}
                items={props.eventItemAndVendorDetails.items}
                preSelectedVendorIDs={selectedVendorIDs}
                preSelectedItemIDs={preSelecetedItemIDs}
            />
        </Box>
    );
}

export const ChatMessageButtonContainer = (props: {
    session_id: string;
    module_id: string;
    setLastReadMsgId: React.Dispatch<React.SetStateAction<string>>;
    showInput: boolean;
    noteId: { id: string; status: 'ACTIVE' | 'INACTIVE' } | null;
    selectedSessionType: 'CHAT' | 'INTERNAL' | 'BROADCAST' | 'SELF' | null;
}) => {
    const [message, setMessage] = useState<string>('');

    const dispatch = useDispatch();

    const [attachmentFile, setAttachmentFile] = useState<File[] | null>(null);
    const [attachments, setAttachments] = useState<string[]>([]);
    const [removedFiles, setRemovedFiles] = useState<Set<string>>(new Set());

    const { authData } = useContext(AuthContext);

    const sendMessage = () => {
        if (attachments.length > 0) {
            dispatch(
                addMessagesToSend({
                    message: message,
                    session_id: props.session_id,
                    module_id: props.module_id,
                    message_type: ChatTypes.DIRECT,
                    message_id: makeid(32),
                    attachment_ids: attachments,
                })
            );
            setMessage('');
            attachments.length > 0 && setAttachments([]);
            attachmentFile &&
                attachmentFile.length > 0 &&
                setAttachmentFile(null);
            setRemovedFiles(new Set());
            props.setLastReadMsgId('');
        } else if (message.trim().length > 0) {
            if (props.noteId && props.noteId.id !== 'SELF') {
                sendNotesMessage(props.noteId.id, message).then((res) => {
                    const user_id = authData.details?.user_id;
                    dispatch(
                        addMessagesReceived({
                            api_resp: { ...res, type: 'NOTE' },
                            receiver_id: user_id,
                        })
                    );
                    (async () => {
                        const getChatMessageCount = await getMessageCount();

                        if (user_id !== null) {
                            dispatch(
                                addMessagesReceived({
                                    api_resp: getChatMessageCount,
                                    receiver_id: user_id,
                                })
                            );
                        }
                    })();
                });
            } else {
                dispatch(
                    addMessagesToSend({
                        message,
                        session_id: props.session_id,
                        module_id: props.module_id,
                        message_type: ChatTypes.DIRECT,
                        message_id: makeid(32),
                    })
                );
            }
            setMessage('');
            props.setLastReadMsgId('');
        }
    };

    const inputRef = useRef<HTMLInputElement>(null);
    const [uploadingFiles, setUploadingFiles] = useState<boolean>(false);

    useEffect(() => {
        if (attachmentFile && attachmentFile.length > 0) {
            setUploadingFiles(true);
            const uploadPromise: Promise<any>[] = [];
            attachmentFile.forEach((file) => {
                uploadPromise.push(uploadFile(file, IFileResourceType.CHAT));
            });

            (async () => {
                try {
                    const res = await Promise.all(uploadPromise);
                    setAttachments(
                        res.map(({ attachment_id }) => attachment_id)
                    );
                    setUploadingFiles(false);
                } catch (error) {
                    toast.error('Error uploading file');
                    setUploadingFiles(false);
                }
            })();
        }
    }, [attachmentFile]);

    const handleChatAttachment = (e: any) => {
        setRemovedFiles(new Set());
        setAttachmentFile(Array.from(e.target.files || []));
    };

    return (
        <Box
            bgcolor="background.default"
            className="flex flex--fdc flex--aic flex--jcsb"
            padding={'10px'}
            position={'relative'}
            // overflow={'hidden'}
        >
            {(!props.showInput || props.noteId?.status === 'INACTIVE') && (
                <FWTooltip
                    title={
                        props.noteId?.status === 'INACTIVE'
                            ? 'Item/vendor was removed from the event'
                            : `Cannot message as you do not have edit permission for this event`
                    }
                >
                    <Box
                        position={'absolute'}
                        width={'100%'}
                        height={'100%'}
                        bgcolor={'#ffffffb5'}
                        zIndex={2}
                    ></Box>
                </FWTooltip>
            )}

            <Box width={'100%'} display={'flex'} flexWrap={'wrap'}>
                {attachmentFile &&
                    attachmentFile.length > 0 &&
                    attachmentFile
                        .filter((val) => !removedFiles.has(val.name))
                        .map((file, idx) => (
                            <Box
                                bgcolor={'white'}
                                // width={'50%'}
                                border={'1px solid #b4b4b4'}
                                borderRadius={'12px'}
                                width={'auto'}
                                maxWidth={'30%'}
                                margin={1}
                                key={idx}
                                className="flex flex--aic flex--jcsb"
                            >
                                <FWTypography
                                    paddingY={0.7}
                                    paddingX={1}
                                    color={'grey'}
                                    fontSize={'15px'}
                                >
                                    {file.name}
                                </FWTypography>
                                <Box
                                    margin={1}
                                    sx={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        setRemovedFiles((prev) => {
                                            let newSet = cloneDeep(prev);
                                            newSet.add(file.name);
                                            return newSet;
                                        });
                                        setAttachments((prev) =>
                                            prev.filter(
                                                (attachment, idx2) =>
                                                    idx !== idx2
                                            )
                                        );
                                    }}
                                >
                                    <i className="bi bi-x-circle"></i>
                                </Box>
                            </Box>
                        ))}
            </Box>
            <Box width={'100%'} className="flex flex--aic">
                <FWInput
                    inputProps={{
                        style: {
                            margin: '2px 45px 2px 2px',
                        },
                    }}
                    sx={{ marginRight: 1 }}
                    multiline
                    maxRows={3}
                    placeholder="Your message"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    onKeyDown={(e: any) => {
                        if (e.keyCode === 13 && !e.shiftKey) {
                            sendMessage();
                            e.preventDefault();
                        }
                    }}
                    iconEnd={
                        !(props.selectedSessionType === 'SELF') ? (
                            <label htmlFor="chat-attachment">
                                <IconButton
                                    size={'small'}
                                    color={'primary'}
                                    sx={{
                                        zIndex: 1,
                                        position: 'absolute',
                                        right: 10,
                                        top: '50%',
                                        transform: 'translateY(-50%)',
                                    }}
                                    onClick={() => {
                                        inputRef.current?.click();
                                    }}
                                >
                                    <input
                                        hidden
                                        id="chat-attachment"
                                        name="chat-attachment"
                                        type="file"
                                        ref={inputRef}
                                        multiple
                                        onChange={handleChatAttachment}
                                        onClick={(e: any) => {
                                            e.target.value = null;
                                        }}
                                    />
                                    <i
                                        style={{
                                            transform: 'rotate(45deg)',
                                        }}
                                        className="bi bi-paperclip"
                                    ></i>
                                </IconButton>
                            </label>
                        ) : undefined
                    }
                />
                {uploadingFiles ? (
                    <Box padding={'7px'}>
                        <CircularProgress size={30} />
                    </Box>
                ) : (
                    <IconButton color="primary" onClick={sendMessage}>
                        <i className="bi bi-send"></i>
                    </IconButton>
                )}
            </Box>
        </Box>
    );
};
