import {
    Grid,
    IconButton,
    LinearProgress,
    styled,
    TextField,
} from '@mui/material';
import { useContext, useEffect, useMemo } from 'react';
import FWAutocomplete from '../../../../Common/FWAutocomplete';
import { FWButton } from '../../../../Common/FWButton';
import { FWMenuItem } from '../../../../Common/FWCustomMenu';
import { FWTooltip } from '../../../../Common/FWCustomTooltip';
import FWIcon from '../../../../Common/FWIcon';
import { FWTypography } from '../../../../Common/FWTypograhy';
import { HookStateValue } from '../../../../Common/Hooks/StateHook';
import {
    IPreferredVendorsProviders,
    usePreferredVendors,
} from '../../Hooks/ItemDirectory/ItemPreferredVendorsHook';
import {
    IVendorsListProviders,
    useVendorsList,
} from '../../Hooks/Vendors/VendorsListHook';
import {
    IPreferredVendor,
    PreferredVendorStatus,
} from '../../Interfaces/ItemDirectoryInterface';
import {
    IVendorListSummary,
    VendorStatus,
} from '../../Interfaces/VendorsInterface';
import { AuthContext } from '../../../../Contexts/AuthContext';

const TH = styled('th')({
    padding: '16px',
    textAlign: 'left',
    backgroundColor: '#f0f0f2',
    fontWeight: 500,
});

const TD = styled('td')({
    padding: '10px 16px',
    borderTop: '1px solid #C4C4c8',
});

interface IPreferredVendorsContainerProps {
    entityUid: string;
    itemUid: string;
    hideDone?: boolean;
    placeholder?: string;
    onSaveSuccess?: () => void;
    onVendorsLoaded?: (preferredVendors: IPreferredVendor[]) => void;
    requireOnePreferredVendor?: boolean;
}

const PreferredVendorsContainer = ({
    entityUid,
    itemUid,
    onSaveSuccess,
    onVendorsLoaded,
    hideDone,
    placeholder = 'Search vendor by name or code',
    requireOnePreferredVendor = false,
}: IPreferredVendorsContainerProps) => {
    const { vendorsList }: IVendorsListProviders = useVendorsList();
    const { authData } = useContext(AuthContext);

    const {
        hookState,
        preferredVendors,
        addPreferredVendor,
        removePreferredVendor,
    }: IPreferredVendorsProviders = usePreferredVendors(entityUid, itemUid);

    const filteredPreferredVendors = useMemo(() => {
        return preferredVendors.filter(
            (vendor) =>
                vendor.preferredVendorStatus === PreferredVendorStatus.PREFERRED
        );
    }, [preferredVendors]);

    const usedVendorIds = useMemo(() => {
        return filteredPreferredVendors.map((vendor) => vendor.vendorUid);
    }, [filteredPreferredVendors]);

    const remainingVendors = useMemo(() => {
        return vendorsList.filter(
            (vendor) =>
                !usedVendorIds.includes(vendor.vendorUid) &&
                vendor.vendorName !== 'FW Test Vendor 02' &&
                vendor.vendorName !== 'FW Test Vendor 01'
        );
    }, [usedVendorIds, vendorsList]);

    useEffect(() => {
        if (onVendorsLoaded) onVendorsLoaded(preferredVendors);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [preferredVendors]);

    return (
        <Grid container>
            {authData.details?.role === 'ADMIN' && (
                <FWTooltip
                    title={
                        remainingVendors.length === 0
                            ? filteredPreferredVendors.length > 0
                                ? 'All vendors assigned'
                                : 'No vendors in directory yet'
                            : ''
                    }
                >
                    <Grid item xs={12}>
                        <FWAutocomplete
                            options={remainingVendors}
                            disabled={remainingVendors.length === 0}
                            value={null}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    autoFocus
                                    autoComplete="off"
                                    placeholder={placeholder}
                                />
                            )}
                            getOptionLabel={(option: IVendorListSummary) =>
                                `${option.vendorName} | ${option.vendorCode}`
                            }
                            renderOption={(
                                props: any,
                                option: IVendorListSummary
                            ) => {
                                return (
                                    <FWMenuItem
                                        {...props}
                                        key={option.vendorUid}
                                        disabled={
                                            option.vendorStatus ===
                                                VendorStatus.INACTIVE ||
                                            option.vendorStatus ===
                                                VendorStatus.DELETED
                                        }
                                    >
                                        {option.vendorName} |{' '}
                                        {option.vendorCode}
                                    </FWMenuItem>
                                );
                            }}
                            onChange={(
                                event,
                                vendor: IVendorListSummary | null
                            ) => {
                                if (vendor) addPreferredVendor(vendor);
                            }}
                            clearOnEscape
                            popupIcon={<></>}
                            clearOnBlur
                            blurOnSelect
                        />
                    </Grid>
                </FWTooltip>
            )}
            <Grid item xs={12}>
                {hookState.state === HookStateValue.LOADING && (
                    <div className="mt--10 mb--10">
                        <LinearProgress />
                    </div>
                )}
                <table
                    style={{
                        width: '100%',
                        borderSpacing: '0',
                        border: '1px solid rgba(0, 0, 0, 0.23)',
                        borderRadius: '12px',
                        overflow: 'hidden',
                        marginTop: '10px',
                    }}
                >
                    <thead>
                        <tr>
                            <TH>Vendor code</TH>
                            <TH>Vendor name</TH>
                            <TH>Vendor email</TH>
                            {authData.details?.role === 'ADMIN' && <TH></TH>}
                        </tr>
                    </thead>
                    <tbody>
                        {filteredPreferredVendors.length > 0 ? (
                            filteredPreferredVendors.map((vendor, idx) => {
                                return (
                                    <tr key={idx}>
                                        <TD>{vendor.vendorCode}</TD>
                                        <TD>{vendor.vendorName}</TD>
                                        <TD>{vendor.primaryContact.email}</TD>
                                        {authData.details?.role === 'ADMIN' && (
                                            <TD>
                                                <FWTooltip
                                                    title={
                                                        'Remove preferred vendor'
                                                    }
                                                >
                                                    <div>
                                                        <IconButton
                                                            color="error"
                                                            onClick={() => {
                                                                removePreferredVendor(
                                                                    idx
                                                                );
                                                            }}
                                                        >
                                                            <FWIcon
                                                                name="bi bi-dash-circle"
                                                                size={20}
                                                            />
                                                        </IconButton>
                                                    </div>
                                                </FWTooltip>
                                            </TD>
                                        )}
                                    </tr>
                                );
                            })
                        ) : (
                            <tr>
                                <TD colSpan={4} style={{ textAlign: 'center' }}>
                                    <FWTypography>
                                        No vendors added
                                    </FWTypography>
                                </TD>
                            </tr>
                        )}
                    </tbody>
                </table>
            </Grid>
            <Grid
                item
                xs={12}
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
                marginTop="16px"
            >
                {authData.details?.role === 'ADMIN' && !hideDone && (
                    <FWButton
                        variant="contained"
                        onClick={onSaveSuccess}
                        disabled={
                            authData.details?.role !== 'ADMIN' ||
                            (requireOnePreferredVendor &&
                                filteredPreferredVendors.length === 0)
                        }
                    >
                        Done
                    </FWButton>
                )}
            </Grid>
        </Grid>
    );
};

export default PreferredVendorsContainer;
