import { createSlice } from "@reduxjs/toolkit";
export interface ICountStore {
    val: number;
}

const initialState={
    val:0,
}
export const CountSlice = createSlice({
    name: "count",
    initialState,
    reducers: {
        incrementByAmount: (state, action) => {
            state.val = action.payload
          },
    }
})

export const { incrementByAmount } =
    CountSlice.actions;

const CountStore = CountSlice.reducer;
export default CountStore;
