import { Box, Chip } from '@mui/material';
import React from 'react';

const VLNProfileMenuChips = ({
    currentSection,
    setCurrentSection,
}: {
    currentSection: 'about' | 'certifications' | 'itemCatalog';
    setCurrentSection: React.Dispatch<
        React.SetStateAction<'about' | 'certifications' | 'itemCatalog'>
    >;
}) => {
    return (
        <Box
            // marginBottom={4}
            className={'flex flex--aic'}
            gap={2}
            position={'sticky'}
            top={'121px'}
            bgcolor={'white'}
            padding={4}
            zIndex={100}
        >
            <Chip
                label="About"
                color={currentSection === 'about' ? 'primary' : 'default'}
                onClick={() => setCurrentSection('about')}
            />
            <Chip
                label="Certifications"
                color={
                    currentSection === 'certifications' ? 'primary' : 'default'
                }
                onClick={() => setCurrentSection('certifications')}
            />
            <Chip
                label="Item catalogue"
                color={currentSection === 'itemCatalog' ? 'primary' : 'default'}
                onClick={() => setCurrentSection('itemCatalog')}
            />
        </Box>
    );
};

export default VLNProfileMenuChips;
