import { useState } from 'react';
import FWPopover from '../../../Common/FWPopover';
import { FWTypography } from '../../../Common/FWTypograhy';
import { Box, IconButton } from '@mui/material';

interface IVendorContactDetailedPopup {
    sellerContact: {
        vendor_contact_id: string;
        full_name: string;
        primary_email: string;
        emails: {
            type: string;
            email: string;
        }[];
        phone_numbers: string[];
        tags: string[];
    }[];
    anchorEl: HTMLAnchorElement | null;
    handleClose: () => void;
}

const VendorContactDetailedPopup = ({
    sellerContact,
    anchorEl,
    handleClose,
}: IVendorContactDetailedPopup) => {
    const [openContacts, setOpenContact] = useState<Number[]>([]);

    if (!sellerContact) return <></>;
    return (
        <FWPopover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleClose}
        >
            {sellerContact.map((contact, index) => {
                if (!contact) return <></>;
                const allMails = Array.from(
                    new Set(contact.emails?.map((email) => email.email))
                );
                return (
                    <Box key={contact.vendor_contact_id} marginBottom={'5px'}>
                        <Box
                            display={'flex'}
                            justifyContent={'space-between'}
                            alignItems={'center'}
                            marginBottom={'5px'}
                        >
                            <FWTypography fontSize={'0.95rem'}>
                                <Box component="span" fontWeight={'500'}>
                                    {sellerContact.length > 1 &&
                                        index + 1 + '. '}
                                    {contact.full_name}
                                </Box>
                                &nbsp; ({contact.primary_email})
                            </FWTypography>
                            {(allMails?.length > 0 ||
                                contact.phone_numbers?.length > 0) && (
                                <IconButton
                                    size="small"
                                    onClick={() => {
                                        setOpenContact((prev) => {
                                            const newOpenContacts = [...prev];
                                            if (
                                                newOpenContacts.includes(index)
                                            ) {
                                                newOpenContacts.splice(
                                                    newOpenContacts.indexOf(
                                                        index
                                                    ),
                                                    1
                                                );
                                            } else {
                                                newOpenContacts.push(index);
                                            }
                                            return newOpenContacts;
                                        });
                                    }}
                                >
                                    <Box color={'primary.main'}>
                                        <i
                                            className={
                                                openContacts.includes(index)
                                                    ? 'bi bi-chevron-up'
                                                    : 'bi bi-chevron-down'
                                            }
                                        ></i>
                                    </Box>
                                </IconButton>
                            )}
                        </Box>
                        {openContacts.includes(index) && (
                            <Box marginLeft="1.3rem" marginTop={'-10px'}>
                                {allMails &&
                                    allMails.length > 0 &&
                                    allMails.map((email) => (
                                        <FWTypography
                                            fontSize={'0.87rem'}
                                            marginBottom={'3px'}
                                        >
                                            <i className="bi bi-envelope"></i>{' '}
                                            &nbsp;
                                            {email}
                                        </FWTypography>
                                    ))}
                                {contact.phone_numbers[0] &&
                                    contact.phone_numbers[0].trim() !== '' && (
                                        <FWTypography fontSize={'0.87rem'}>
                                            <i className="bi bi-telephone"></i>
                                            &nbsp;{contact.phone_numbers[0]}
                                        </FWTypography>
                                    )}
                            </Box>
                        )}
                    </Box>
                );
            })}
        </FWPopover>
    );
};

export default VendorContactDetailedPopup;
