import React from 'react';
import { HookStateValue, useHookState } from '../../Common/Hooks/StateHook';
import { AuthContext } from '../../Contexts/AuthContext';
import {
    IPurchaseOrder,
    PurchaseOrderStatus,
    PurchaseOrderType,
} from '../Interfaces/PO.model';
import {
    deletePurchaseOrder,
    rejectOrReworkPurchaseOrder,
    submitPurchaseOrder,
} from '../Services/PO.service';
import { getPoApprovalLog } from '../../Components/Buyer/Events/PO/POReview/helperfunctions';
import {
    addApprovalToPo,
    fetchNextApproverListForExistingPurchaseOrder,
} from '../../Services/purchaseOrders/purchaseOrderOperations';
import { toast } from 'react-toastify';

export const usePurchaseOrderApprovalActions = (
    purchaseOrderData: IPurchaseOrder
) => {
    const { hookState, updateHookState } = useHookState(HookStateValue.READY);
    const { authData } = React.useContext(AuthContext);

    const [showApprove, setShowApprove] = React.useState(false);
    const [showSubmit, setShowSubmit] = React.useState(false);
    const [showReject, setShowReject] = React.useState(false);
    const [showRework, setShowRework] = React.useState(false);
    const [showDelete, setShowDelete] = React.useState(false);

    const initButtons = React.useCallback(async () => {
        updateHookState(HookStateValue.LOADING);
        try {
            const curUserId = authData.details!.user_id;
            // let hasEntityPoApprovalPermission = checkPermission(
            //     'BUYER',
            //     'PURCHASE_ORDER',
            //     'APPROVE',
            //     purchaseOrderData.buyer_entity
            // );

            const [nextApproverList, approvalLog] = await Promise.all([
                fetchNextApproverListForExistingPurchaseOrder({
                    poId: purchaseOrderData.purchase_order_id,
                }),
                getPoApprovalLog(purchaseOrderData.purchase_order_id),
            ]);

            const nextApproversExist = nextApproverList.length > 0;

            const userApprovalIsIssued = approvalLog.some(
                (row) => row.approverId === curUserId && row.status === 'ISSUED'
            );
            const userApprovalIsPending = approvalLog.some(
                (row) =>
                    row.approverId === curUserId && row.status === 'PENDING'
            );

            const numberOfPendingApprovals = approvalLog.filter(
                (row) => row.status === 'PENDING'
            ).length;

            if (userApprovalIsIssued) {
                setShowApprove(false);
                setShowSubmit(false);
                setShowReject(false);
                setShowRework(false);
                setShowDelete(true);
            } else if (userApprovalIsPending) {
                const isFinalApprover =
                    !nextApproversExist && numberOfPendingApprovals === 1;
                setShowApprove(!isFinalApprover);
                setShowSubmit(isFinalApprover);
                setShowReject(true);
                setShowRework(true);
                setShowDelete(false);
            } else {
                setShowApprove(false);
                setShowSubmit(false);
                setShowReject(false);
                setShowRework(false);
                setShowDelete(false);
            }
        } catch (e) {
            toast.error(
                'Failed to fetch authorization data, some actions may be unavailable'
            );

            setShowApprove(false);
            setShowSubmit(false);
            setShowReject(false);
            setShowRework(false);
            setShowDelete(false);
        } finally {
            updateHookState(HookStateValue.READY);
        }
    }, [
        authData.details,
        purchaseOrderData.purchase_order_id,
        updateHookState,
    ]);

    const approvePo = React.useCallback((): Promise<void> => {
        return new Promise<void>(async (resolve, reject) => {
            try {
                await addApprovalToPo(
                    purchaseOrderData.purchase_order_id,
                    purchaseOrderData.purchase_order_type ===
                        PurchaseOrderType.DIRECT_PO
                        ? 'DIRECT'
                        : 'FROM_EVENT'
                );
                initButtons();
                resolve();
            } catch (e) {
                reject(e);
            }
        });
    }, [
        purchaseOrderData.purchase_order_id,
        initButtons,
        purchaseOrderData.purchase_order_type,
    ]);

    const submitPo = React.useCallback((): Promise<void> => {
        return new Promise<void>(async (resolve, reject) => {
            try {
                await submitPurchaseOrder(
                    purchaseOrderData.purchase_order_id,
                    '',
                    purchaseOrderData.purchase_order_type ===
                        PurchaseOrderType.DIRECT_PO
                        ? 'DIRECT'
                        : 'FROM_EVENT'
                );
                resolve();
            } catch {
                reject();
            }
        });
    }, [
        purchaseOrderData.purchase_order_id,
        purchaseOrderData.purchase_order_type,
    ]);
    const rejectPo = React.useCallback(
        (msg: string): Promise<void> => {
            return new Promise<void>(async (resolve, reject) => {
                try {
                    await rejectOrReworkPurchaseOrder(
                        purchaseOrderData.purchase_order_id,
                        msg,
                        purchaseOrderData.purchase_order_type ===
                            PurchaseOrderType.DIRECT_PO
                            ? 'DIRECT'
                            : 'FROM_EVENT',
                        'REJECTED'
                    );
                    resolve();
                } catch {
                    reject();
                }
            });
        },
        [
            purchaseOrderData.purchase_order_id,
            purchaseOrderData.purchase_order_type,
        ]
    );

    const reworkPo = React.useCallback(
        (msg: string): Promise<void> => {
            return new Promise<void>(async (resolve, reject) => {
                try {
                    await rejectOrReworkPurchaseOrder(
                        purchaseOrderData.purchase_order_id,
                        msg,
                        purchaseOrderData.purchase_order_type ===
                            PurchaseOrderType.DIRECT_PO
                            ? 'DIRECT'
                            : 'FROM_EVENT',
                        'REWORK'
                    );
                    resolve();
                } catch {
                    reject();
                }
            });
        },
        [
            purchaseOrderData.purchase_order_id,
            purchaseOrderData.purchase_order_type,
        ]
    );

    const deletePo = React.useCallback((): Promise<void> => {
        return new Promise<void>(async (resolve, reject) => {
            try {
                await deletePurchaseOrder(
                    purchaseOrderData.purchase_order_id,
                    purchaseOrderData.purchase_order_type ===
                        PurchaseOrderType.DIRECT_PO
                        ? 'DIRECT'
                        : 'FROM_EVENT'
                );
                resolve();
            } catch {
                reject();
            }
        });
    }, [
        purchaseOrderData.purchase_order_id,
        purchaseOrderData.purchase_order_type,
    ]);

    React.useEffect(() => {
        if (purchaseOrderData.status === PurchaseOrderStatus.APPROVAL_PENDING) {
            initButtons();
        } else {
            setShowApprove(false);
            setShowSubmit(false);
            setShowReject(false);
            setShowRework(false);
            setShowDelete(false);
        }
    }, [initButtons, purchaseOrderData.status]);

    return {
        hookState,
        showApprove,
        showSubmit,
        showReject,
        showRework,
        showDelete,
        approvePo,
        submitPo,
        rejectPo,
        reworkPo,
        deletePo,
    };
};
