import { store } from '../../Redux/Store';
/* eslint-disable no-useless-escape */

export const isValidEmail = (email: string) => {
    if (
        String(email)
            .toLowerCase()
            .match(
                /^[^<>()[\]\\,;:\%#^\s@\"$&!@]+@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}))$/
            )
    ) {
        return true;
    }
    return false;
};

export const isValidUrl = (url: string) =>
    String(url).toLowerCase().match(InputRegEx({}).URL);

export const isPasswordValid = (password: string) => {
    let errors = [];
    if (password.length < 8) {
        errors.push('Your password must be at least 8 characters');
    }
    if (password.search(/[A-Z]/) < 0) {
        errors.push(
            'Your password must contain at least one upper case character.'
        );
    }
    if (password.search(/(?=.*[!@#$%^&*])/) < 0) {
        errors.push(
            'Your password must contain at least one special character.'
        );
    }
    if (password.search(/[a-z]/) < 0) {
        errors.push(
            'Your password must contain at least one lower case character.'
        );
    }
    if (password.search(/[0-9]/) < 0) {
        errors.push('Your password must contain at least one digit.');
    }
    if (errors.length > 0) {
        return {
            state: true,
            errors,
        };
    } else {
        return {
            state: false,
            errors: errors,
        };
    }
};

export const InputRegEx = ({ decimalPlaces = 10 }) => ({
    TEXT: new RegExp('^([a-z ]+)?$', 'i'),
    NUMBER: new RegExp('^([0-9]+)?$'),
    DECIMAL_NUMBER: new RegExp(
        `^([0-9]+)?([.]{1})?([0-9]{0,${decimalPlaces}})?$`
    ),
    PERCENT: new RegExp(`^([0-9]+)?([.]{1})?([0-9]{0,${decimalPlaces}})?$`),
    DISCOUNT: new RegExp(`^([0-9]+)?([.]{1})?([0-9]{0,${decimalPlaces}})?$`),
    EMAIL: new RegExp(
        '^([0-9a-z._]+)?([@]+)?([0-9a-z-]+)?([.]+)?([a-z]+)?([.])?([a-z]+)?$',
        'i'
    ),
    CURRENCY: /\B(?=(\d{2})+(?!\d))/g,
    URL: /[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/,
    TEL: new RegExp('^([0-9()#+-]+)?$'),
    // CURRENCY: /(\d)(?=(\d{3})+(\.(\d){0,2})*$)/g, // us format working
    // CURRENCY: /(\d)(?=(\d{2})+(\.(\d){0,2})*$)/g, // indian format try
    // URL: new RegExp(
    //     '(https?://(?:www.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}|www.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}|https?://(?:www.|(?!www))[a-zA-Z0-9]+.[^s]{2,}|www.[a-zA-Z0-9]+.[^s]{2,})',
    //     'gi'
    // ),
});

export const UuidRegEx =
    /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

export const addCommaSeparators = (value: string) => {
    // return value.split(',').join('').replace(InputRegEx.CURRENCY, ','); // us format working
    let afterPoint = '';

    if (value === '' || value === '.' || value === '0.') return value;

    if (value.indexOf('.') > 0)
        afterPoint = value.substring(value.indexOf('.'), value.length);
    let x = Math.floor(+value);
    if (x === 0) return value;
    let y = x.toString();
    let lastThree = y.substring(y.length - 3);
    const otherNumbers = y.substring(0, y.length - 3);
    if (otherNumbers !== '') lastThree = ',' + lastThree;
    const data = store.getState().UserDefaultsStore?.number_format;

    if (data === 'INR') {
        const res =
            otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ',') +
            lastThree +
            afterPoint;
        return res;
    } else {
        const res =
            otherNumbers.replace(/\B(?=(\d{3})+(?!\d))/g, ',') +
            lastThree +
            afterPoint;
        return res;
    }

    // const res =
    //     otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ',') +
    //     lastThree +
    //     afterPoint;
    // return res;
};
