import { Typography } from '@mui/material';
import { isNull } from 'lodash';
import { FWTooltip } from '../../Common/FWCustomTooltip';
import { FWTypography } from '../../Common/FWTypograhy';
import DotBadge from '../../Components/Shared/DotBadge';
import {
    IGRInvoiceListSummary,
    IGRPastListSummary,
    IInvoiceItemsSummary,
} from '../Interfaces/GRInterface';
import { IInvoiceItemListContext } from '../Pages/GRCreatePage';
// import GRInvoiceItemsListOptionsMenu from '../Pages/GRInvoiceItemsListOptionsMenu';

export const GRItemNameCell = ({
    data: invoiceItem,
    context,
}: {
    data: IInvoiceItemsSummary;
    context: IInvoiceItemListContext;
}) => {
    return (
        <div
            className="flex flex--aic"
            style={{
                width: '100%',
            }}
        >
            <FWTypography
                color="primary"
                onClick={(e: any) => {
                    context.handleItemPopupToggle(invoiceItem);
                }}
                style={{ cursor: 'pointer' }}
            >
                {invoiceItem.name}
            </FWTypography>
            {invoiceItem.itemOnHold && (
                <FWTooltip title="Item is on hold">
                    <div style={{ lineHeight: 0 }}>
                        <DotBadge color="error" />
                    </div>
                </FWTooltip>
            )}
        </div>
    );
};

export const GRPastListItemNameCell = ({
    data,
    context,
}: {
    data: IGRPastListSummary;
    context: IInvoiceItemListContext;
}) => {
    return (
        <div
            className="flex flex--aic"
            style={{
                cursor: 'pointer',
                maxWidth: '100%',
            }}
            ref={(ref) => {
                if (!ref) return;
                ref.onclick = (e: any) => {
                    e.stopPropagation();
                    context.handleItemPopupToggle(
                        data.itemDetails as IInvoiceItemsSummary
                    );
                };
            }}
        >
            <FWTypography color="primary">{data.itemName}</FWTypography>
        </div>
    );
};

export const GRDeliveryScheduleCell = ({
    data,
    context,
}: {
    data: IGRInvoiceListSummary;
    context: IInvoiceItemListContext;
}) => {
    return (
        <>
            {(data.hasInvoice && data.deliveryDate) || +data.lots <= 1 ? (
                /*
                This captures the following cases:
                1) Invoice present and has expected delivery date
                2) Invoice present but no expected delivery date, and PO has only 1 dated lot, or is TBD (lots = 0)
                */
                <>
                    <FWTypography>
                        {data.deliveryDate ? data.deliveryDate : 'TBD'}
                    </FWTypography>
                </>
            ) : (
                //Lot count > 1
                <>
                    <Typography
                        color="primary.main"
                        ref={(ref) => {
                            if (!ref) return;
                            ref.onclick = (e: any) => {
                                e.stopPropagation();
                                context.handleDeliveryScheduleToggle(
                                    data.poUid,
                                    data.measurementUnit,
                                    e.target
                                );
                            };
                        }}
                    >
                        {data.lots}{' '}
                        {data.lots > 1 || data.lots < 1 ? 'lots' : 'lot'}
                    </Typography>
                </>
            )}
        </>
    );
};

export const PastGRDeliveryScheduleCell = ({
    data,
    context,
}: {
    data: IGRPastListSummary;
    context: IInvoiceItemListContext;
}) => {
    return (
        <>
            {!isNull(data.deliveryDate) && (
                <FWTypography>{data.deliveryDate}</FWTypography>
            )}
            {isNull(data.deliveryDate) && (
                <FWTypography>NA</FWTypography>
                // <IconButton
                //     color="primary"
                //     ref={(ref) => {
                //         if (!ref) return;
                //         ref.onclick = (e: any) => {
                //             e.stopPropagation();
                //             context.handleDeliveryScheduleToggle(
                //                 data.poItemUid,
                //                 data.measurementUnit,
                //                 e.target
                //             );
                //         };
                //     }}
                // >
                //     <i className="bi bi-truck fs--14"></i>
                // </IconButton>
            )}
        </>
    );
};

// export const GRInvoiceItemOptionsCell = ({
//     data: invoiceItem,
//     context,
// }: {
//     data: IInvoiceItemsSummary;
//     context: IInvoiceItemListContext;
// }) => {
//     return (
//         <div style={{ margin: '0 auto' }} className="gr--options">
//             <GRInvoiceItemsListOptionsMenu row={invoiceItem} />
//         </div>
//     );
// };
