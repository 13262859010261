import { Box, Chip } from '@mui/material';
import React from 'react';

const sections = [
    'Insights',
    'Transactions',
    'Invoices',
    'Chat',
    // 'E-mail',
    'Contracts',
    'Notes',
];

const VLNRelationshipMenuChips = ({
    currentSection,
    setCurrentSection,
}: {
    currentSection:
        | 'Insights'
        | 'Transactions'
        | 'Invoices'
        | 'Chat'
        | 'E-mail'
        | 'Contracts'
        | 'Notes';
    setCurrentSection: React.Dispatch<
        React.SetStateAction<
            | 'Insights'
            | 'Transactions'
            | 'Invoices'
            | 'Chat'
            | 'E-mail'
            | 'Contracts'
            | 'Notes'
        >
    >;
}) => {
    return (
        <Box
            // marginBottom={4}
            className={'flex flex--aic'}
            gap={2}
            position={'sticky'}
            top={'121px'}
            bgcolor={'white'}
            padding={4}
            paddingBottom={1}
            zIndex={10}
        >
            {sections.map((section, idx) => (
                <Chip
                    key={idx}
                    label={section}
                    color={currentSection === section ? 'primary' : 'default'}
                    onClick={() => setCurrentSection(section as any)}
                />
            ))}
        </Box>
    );
};

export default VLNRelationshipMenuChips;
