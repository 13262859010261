import { Grid, styled } from '@mui/material';
import { isEmpty } from 'lodash';
import { useState } from 'react';
import {
    FWAccordion,
    FWAccordionDetails,
    FWAccordionSummary,
} from '../../Common/FWAccordian';
import FWIcon from '../../Common/FWIcon';
import { FWTypography } from '../../Common/FWTypograhy';
import { FWNumberFormatter } from '../../Common/Utils/CurrencyUtils';
import { IPrepaymentItemsList } from '../Interfaces/PaymentsInterface';

interface IPaymentInvoiceItemAccordionProps {
    data: IPrepaymentItemsList;
}

const TD = styled('td')({
    padding: '10px 16px',
    borderBottom: '1px solid #C4C4c8',
    '&.open': {
        borderBottom: '0',
    },
});

const PaymentInvoiceItemAccordion = ({
    data: itemDetails,
}: IPaymentInvoiceItemAccordionProps) => {
    const [open, setOpen] = useState<boolean>(false);
    return (
        <tbody>
            <tr>
                <TD
                    onClick={() => {
                        setOpen(!open);
                    }}
                    className={open ? 'open' : ''}
                    style={{ width: '60%' }}
                >
                    <div
                        className="flex flex--aic"
                        style={{
                            cursor: 'pointer',
                            width: 'max-content',
                        }}
                    >
                        <FWTypography color="primary">
                            {itemDetails.itemName}
                        </FWTypography>
                        <div
                            style={{
                                transform: `rotate(${
                                    open ? '180deg' : '0deg'
                                })`,
                                transition: 'transform 0.2s ease-in-out',
                            }}
                            className="ml--5"
                        >
                            <FWIcon
                                name="bi bi-chevron-down"
                                color="primary.main"
                            />
                        </div>
                    </div>
                </TD>
                {/* <TD
                    style={{ textAlign: 'right' }}
                    className={open ? 'open' : ''}
                >
                    <FWTypography>
                        {FWNumberFormatter(itemDetails.quantity)}{' '}
                        {itemDetails.measurementUnit}(s)
                    </FWTypography>
                </TD>
                <TD
                    style={{ textAlign: 'right' }}
                    className={open ? 'open' : ''}
                >
                    <FWTypography>
                        {FWNumberFormatter(
                            itemDetails.rate,
                            itemDetails.currency
                        )}
                    </FWTypography>
                </TD>
                <TD
                    style={{ textAlign: 'right' }}
                    className={open ? 'open' : ''}
                >
                    <FWTypography>
                        {FWNumberFormatter(
                            itemDetails.total,
                            itemDetails.currency
                        )}
                    </FWTypography>
                </TD> */}
                <TD
                    style={{ textAlign: 'right', width: '40%' }}
                    className={open ? 'open' : ''}
                >
                    <FWTypography>
                        {FWNumberFormatter(
                            itemDetails.prepaymentAmount,
                            itemDetails.currency
                        )}
                    </FWTypography>
                </TD>
            </tr>
            <tr>
                <TD
                    colSpan={5}
                    sx={{ p: 0 }}
                    style={{ borderTop: '0 !important' }}
                >
                    <FWAccordion
                        style={{
                            padding: 0,
                            border: 0,
                            width: '100%',
                            minWidth: 'unset',
                        }}
                        expanded={open}
                    >
                        <FWAccordionSummary
                            style={{
                                padding: '4px 16px',
                                display: 'none',
                            }}
                            sx={{
                                '.MuiAccordionSummary-content': {
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                },
                            }}
                        >
                            {itemDetails.itemName}
                        </FWAccordionSummary>
                        <FWAccordionDetails>
                            <Grid
                                container
                                columnSpacing={2}
                                rowSpacing={2}
                                sx={{
                                    marginLeft: '0',
                                    width: '100%',
                                    // padding: '16px 0',
                                }}
                            >
                                <Grid item xs={4} sx={{ pr: '16px' }}>
                                    <FWTypography
                                        sx={{ color: 'text.secondary' }}
                                    >
                                        Item ID
                                    </FWTypography>
                                    <FWTypography>
                                        {itemDetails.itemId}
                                    </FWTypography>
                                </Grid>
                                {!isEmpty(itemDetails.description) && (
                                    <Grid item xs={8} sx={{ pr: '16px' }}>
                                        <FWTypography
                                            sx={{
                                                color: 'text.secondary',
                                            }}
                                        >
                                            Description
                                        </FWTypography>
                                        <FWTypography>
                                            {itemDetails.description}
                                        </FWTypography>
                                    </Grid>
                                )}
                                {!isEmpty(itemDetails.additionalInfo) && (
                                    <Grid item md={12} sx={{ pr: '16px' }}>
                                        <FWTypography
                                            sx={{
                                                color: 'text.secondary',
                                            }}
                                        >
                                            Additional information
                                        </FWTypography>
                                        <FWTypography>
                                            {itemDetails.additionalInfo}
                                        </FWTypography>
                                    </Grid>
                                )}
                                {itemDetails.attributes.buyerAttributes.map(
                                    (attribute, idx) => (
                                        <Grid item xs={4} key={idx}>
                                            <FWTypography
                                                sx={{
                                                    color: 'text.secondary',
                                                }}
                                            >
                                                {attribute.name}
                                            </FWTypography>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <FWTypography>
                                                    {attribute.value.join(
                                                        ' / '
                                                    )}
                                                </FWTypography>
                                            </div>
                                        </Grid>
                                    )
                                )}
                                {itemDetails.attributes.customAttributes.map(
                                    (attribute, idx) => (
                                        <Grid item xs={4} key={idx}>
                                            <FWTypography
                                                sx={{
                                                    color: 'text.secondary',
                                                }}
                                            >
                                                {attribute.name}
                                            </FWTypography>
                                            <FWTypography>
                                                {attribute.value.join(' / ')}
                                            </FWTypography>
                                        </Grid>
                                    )
                                )}
                            </Grid>
                        </FWAccordionDetails>
                    </FWAccordion>
                </TD>
            </tr>
        </tbody>
    );
};

export default PaymentInvoiceItemAccordion;
