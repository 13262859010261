import { styled } from '@mui/material';
import { FWTooltip } from '../../Common/FWCustomTooltip';
import { QCState, QCType } from '../Interfaces/QCInterface';

const QCIcon = styled('div')(({ theme }) => ({
    minWidth: '25px',
    width: '25px',
    height: '25px',
    fontSize: '11px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.primary.main,
    borderRadius: '50% !important',
    border: '1px solid',
    borderColor: theme.palette.primary.main,
}));

interface IQCTypeIconProps {
    type: string;
    className?: string;
}

export const qcLabelTypeMap: {
    [key: string]: string;
} = {
    P: QCState.PRIMARY,
    S: QCState.SECONDARY,
    PL: QCState.PRODUCTION_LINE,
};

export const qcLabelTypeMapService: {
    [key: string]: string;
} = {
    [QCType.PRIMARY]: 'P',
    [QCType.SECONDARY]: 'S',
    [QCType.PRODUCTION_LINE]: 'PL',
};

const QCTypeIcon = ({ type, className }: IQCTypeIconProps) => {
    let innerType = '';
    if (type?.includes('P')) innerType = 'P';
    if (type?.includes('PL')) innerType = 'PL';
    if (type?.includes('S')) innerType = 'S';
    let typeName = qcLabelTypeMap[innerType];
    return (
        <FWTooltip title={typeName}>
            <QCIcon className={className}>{type}</QCIcon>
        </FWTooltip>
    );
};

export default QCTypeIcon;
