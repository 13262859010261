import { store } from '../../../Redux/Store';
import {
    PeriodOptions,
    convertingDifferentPeriodsToMillisecond,
} from './types';

const supportedFunctions = {
    uppercase: (params: string[]) => {
        return params.map((param) => param.toUpperCase()).join('');
    },
    lowercase: (params: string[]) => {
        return params.map((param) => param.toLowerCase()).join('');
    },
    concatenate: (params: string[]) => {
        return params.join('');
    },
    round: (params: string[]) => {
        if (params.length > 0) {
            const out = (Number.EPSILON + Number(params[0] || '')).toFixed(
                Number(params[1]) || 0
            );
            if (paramAsBooleanIsSet(params[2])) {
                return out;
            }
            return stripLastZeroesAfterDot(out);
        }
        return Number.NaN.toString();
    },
    ceil: (params: string[]) => {
        if (params.length > 0) {
            const mult = Math.pow(10, Number(params[1]) || 0);
            const out = (Math.ceil(Number(params[0]) * mult) / mult).toFixed(
                Number(params[1]) || 0
            );
            if (paramAsBooleanIsSet(params[2])) {
                return out;
            }
            return stripLastZeroesAfterDot(out);
        }
        return Number.NaN.toString();
    },
    floor: (params: string[]) => {
        if (params.length > 0) {
            const mult = Math.pow(10, Number(params[1]) || 0);
            const out = (Math.floor(Number(params[0]) * mult) / mult).toFixed(
                Number(params[1]) || 0
            );
            if (paramAsBooleanIsSet(params[2])) {
                return out;
            }
            return out;
        }
        return Number.NaN.toString();
    },
    add: (params: string[]) => {
        return params.reduce((out: string, param) => {
            if (!isNaN(Number(out))) {
                if (!isNaN(Number(param))) {
                    return toNumberString(Number(out) + Number(param));
                } else if (!isNaN(Date.parse(param))) {
                    // maybe need to localtimezon dif

                    return toNumberString(Number(out) + Date.parse(param));
                } else if (
                    Object.keys(PeriodOptions).includes(param.split(' ')[1])
                ) {
                    const [period, periodType] = param.split(' ');

                    const timeinMs =
                        +period *
                        convertingDifferentPeriodsToMillisecond[
                            periodType as PeriodOptions
                        ];

                    return toNumberString(Number(out) + timeinMs);
                } else {
                    return Number.NaN.toString();
                }
            } else {
                return Number.NaN.toString();
            }
        }, '0');
    },
    multiply: (params: string[]) => {
        return params.reduce((out: string, param) => {
            if (!isNaN(Number(out)) && !isNaN(Number(param))) {
                return toNumberString(Number(out) * Number(param));
            } else {
                return Number.NaN.toString();
            }
        }, '1');
    },
    subtract: (params: string[]) => {
        params = [...params];
        const first = params.shift();
        const rest = supportedFunctions.add(params);
        if (!isNaN(Number(first)) && !isNaN(Number(rest))) {
            return toNumberString(Number(first) - Number(rest));
        }
        return Number.NaN.toString();
    },
    divide: (params: string[]) => {
        params = [...params];
        const first = params.shift();
        const rest = supportedFunctions.multiply(params);
        if (!isNaN(Number(first)) && !isNaN(Number(rest)) && Number(rest)) {
            return toNumberString(Number(first) / Number(rest));
        }
        return Number.NaN.toString();
    },
    pow: (params: string[]) => {
        if (
            !isNaN(Number(params[0] || '0')) &&
            !isNaN(Number(params[1] || '0'))
        ) {
            return toNumberString(
                Math.pow(Number(params[0] || '0'), Number(params[1] || '0'))
            );
        }
        return Number.NaN.toString();
    },
    max: (params: string[]) => {
        if (!params.length) {
            return Number.NaN.toString();
        }
        return params.reduce((out: string, param) => {
            if (!isNaN(Number(out)) && !isNaN(Number(param))) {
                return Math.max(Number(out), Number(param)).toString();
            } else {
                return Number.NaN.toString();
            }
        }, params[0]);
    },
    min: (params: string[]) => {
        if (!params.length) {
            return Number.NaN.toString();
        }
        return params.reduce((out: string, param) => {
            if (!isNaN(Number(out)) && !isNaN(Number(param))) {
                return Math.min(Number(out), Number(param)).toString();
            } else {
                return Number.NaN.toString();
            }
        }, params[0]);
    },
    lt: (params: string[]) => {
        return compare(params, '<');
    },
    lte: (params: string[]) => {
        return compare(params, '<=');
    },
    eq: (params: string[]) => {
        return compare(params, '=');
    },
    gte: (params: string[]) => {
        return compare(params, '>=');
    },
    gt: (params: string[]) => {
        return compare(params, '>');
    },
    if: (params: string[]) => {
        if (params.length < 2) {
            return '';
        }
        if (['', '0', 'true'].includes(params[0])) {
            return params[2] || '';
        }
        return params[1];
    },
    and: (params: string[]) => {
        return params.length > 0 &&
            params.reduce(
                (acc, next) =>
                    acc && !['', '0', 'false'].includes(next.toLowerCase()),
                true
            )
            ? 'true'
            : 'false';
    },
    or: (params: string[]) => {
        return params.length > 0 &&
            params.reduce(
                (acc, next) =>
                    acc || !['', '0', 'false'].includes(next.toLowerCase()),
                true
            )
            ? 'true'
            : 'false';
    },
    date: (params: string[]) => {
        if (params.length > 0) {
            const date = new Date(params[0]);
            const dd = date.getDate();
            const mm = date.getMonth() + 1;

            const yyyy = date.getFullYear();
            return `${yyyy}-${mm < 10 ? '0' : ''}${mm}-${
                dd < 10 ? '0' : ''
            }${dd}`;
        }
        return '';
    },
    datetime: (params: string[]) => {
        if (params.length > 0) {
            const date = new Date(params[0]);
            const dd = date.getDate();
            const mm = date.getMonth() + 1;
            const yyyy = date.getFullYear();

            const hh = date.getHours();
            const min = date.getMinutes();
            const sec = date.getSeconds();

            return `${yyyy}-${mm < 10 ? '0' : ''}${mm}-${
                dd < 10 ? '0' : ''
            }${dd}T${hh < 10 ? '0' : ''}${hh}:${min < 10 ? '0' : ''}${min}:${
                sec < 10 ? '0' : ''
            }${sec}`;
        }
        return '';
    },
    workdays: (params: string[]) => {
        const nonWorkingDay =
            store.getState().AdminHolidaySettingStore.nonWorkingInfo;
        const holidays = store
            .getState()
            .AdminHolidaySettingStore.holdays.map(
                (holiday) => new Date(holiday.date)
            );

        if (params.length > 0) {
            const date = new Date(params[0]);
            if (date.toString() !== 'Invalid Date') {
                if (params.length > 1) {
                    const number = +params[1];
                    if (!Number.isNaN(number)) {
                        let i = 0;
                        while (i < number) {
                            date.setDate(date.getDate() + 1);
                            if (
                                nonWorkingDay.includes(date.getDay()) ===
                                    false &&
                                holidays.find(
                                    (holidayDate) =>
                                        date.toDateString() ===
                                        holidayDate.toDateString()
                                ) === undefined
                            ) {
                                i++;
                            }
                        }
                    }
                }

                while (
                    !(
                        nonWorkingDay.includes(date.getDay()) === false &&
                        holidays.find(
                            (holidayDate) =>
                                date.toDateString() ===
                                holidayDate.toDateString()
                        ) === undefined
                    )
                ) {
                    date.setDate(date.getDate() - 1);
                }
            }

            const dd = date.getDate();
            const mm = date.getMonth() + 1;
            const yyyy = date.getFullYear();

            const hh = date.getHours();
            const min = date.getMinutes();
            const sec = date.getSeconds();

            return `${yyyy}-${mm < 10 ? '0' : ''}${mm}-${
                dd < 10 ? '0' : ''
            }${dd}T${hh < 10 ? '0' : ''}${hh}:${min < 10 ? '0' : ''}${min}:${
                sec < 10 ? '0' : ''
            }${sec}`;
        }

        return '';
    },
    workdayswithoutHolidays: (params: string[]) => {
        if (params.length > 0) {
            const date = new Date(params[0]);
            if (params.length > 1) {
                const number = +params[1];
                if (!Number.isNaN(number)) {
                    let i = 0;

                    while (i < number) {
                        date.setDate(date.getDate() + 1);
                        if (date.getDay() !== 0 && date.getDay() !== 6) {
                            i++;
                        }
                    }
                }
            }

            while (date.getDay() === 0 || date.getDay() === 6) {
                date.setDate(date.getDate() - 1);
            }

            const dd = date.getDate();
            const mm = date.getMonth() + 1;
            const yyyy = date.getFullYear();

            const hh = date.getHours();
            const min = date.getMinutes();
            const sec = date.getSeconds();

            return `${yyyy}-${mm < 10 ? '0' : ''}${mm}-${
                dd < 10 ? '0' : ''
            }${dd}T${hh < 10 ? '0' : ''}${hh}:${min < 10 ? '0' : ''}${min}:${
                sec < 10 ? '0' : ''
            }${sec}`;
        }

        return '';
    },
    months: (params: string[]) => {
        return `${params[0] || 0} ${PeriodOptions.Months}`;
    },
    weeks: (params: string[]) => {
        return `${params[0] || 0} ${PeriodOptions.Weeks}`;
    },
    days: (params: string[]) => {
        return `${params[0] || 0} ${PeriodOptions.Days}`;
    },
    hours: (params: string[]) => {
        return `${params[0] || 0} ${PeriodOptions.Hours}`;
    },
    minutes: (params: string[]) => {
        return `${params[0] || 0} ${PeriodOptions.Minutes}`;
    },
    seconds: (params: string[]) => {
        return `${params[0] || 0} ${PeriodOptions.Seconds}`;
    },
};

const supportedOperators = {
    '&': supportedFunctions.concatenate,
    '+': supportedFunctions.add,
    '-': supportedFunctions.subtract,
    '/': supportedFunctions.divide,
    '*': supportedFunctions.multiply,
    '^': supportedFunctions.pow,
    '<': supportedFunctions.lt,
    '<=': supportedFunctions.lte,
    '=': supportedFunctions.eq,
    '>=': supportedFunctions.gte,
    '>': supportedFunctions.gt,
};

export function executeOperator(operator: string, parameters: string[]) {
    if (operator in supportedOperators) {
        return supportedOperators[operator as keyof typeof supportedOperators](
            parameters
        );
    }
    return '';
}

export function executeFunction(name: string, parameters: string[]) {
    name = name.toLowerCase();
    if (name in supportedFunctions) {
        return supportedFunctions[name as keyof typeof supportedFunctions](
            parameters
        );
    }
    return '';
}

export function functionIsSupported(name: string) {
    name = name.toLowerCase();
    return name in supportedFunctions;
}

export function toNumberString(n: number) {
    return Number(n.toFixed(10)).toString();
}

function paramAsBooleanIsSet(param?: string | undefined) {
    param = (param || '').toLowerCase();
    return param && param !== '0' && param !== 'false' && param !== 'no';
}

function stripLastZeroesAfterDot(param: string) {
    if (param.match(/\./)) {
        return param.replace(/0+$/, '').replace(/\.$/, '');
    }
    return param;
}

function compare(params: string[], operator: '<' | '<=' | '=' | '>=' | '>') {
    if (params.length < 2) {
        return '0';
    }
    const p0 = isNaN(Number(params[0])) ? params[0].trim() : Number(params[0]);
    const p1 = isNaN(Number(params[1])) ? params[1].trim() : Number(params[1]);

    if (
        operator.includes('=') &&
        (p0 === p1 ||
            p0.toString().toLowerCase() === p1.toString().toLowerCase())
        // ||
        // p1.toString().toLowerCase().includes(p0.toString().toLowerCase()))
    ) {
        return '1';
    }
    if (operator.includes('<') && p0 < p1) {
        return '1';
    }
    if (operator.includes('>') && p0 > p1) {
        return '1';
    }
    return '0';
}
