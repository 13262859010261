import { Box, CircularProgress, Typography } from '@mui/material';
import React from 'react';
import VLNRelationshipNotesList from '../../Components/Relationship/Notes/VLNRelationshipNotesList';
import { useVLNRelationshipNotes } from '../../Hooks/Relationship/useVLNRelationshipNotes';
import { HookStateValue } from '../../../Common/Hooks/StateHook';
import VLNRelationshipNotesEditSection from '../../Components/Relationship/Notes/VLNRelationshipNotesEditSection';

const VLNRelationshipNotesSection = ({
    vendor_profile_id,
}: {
    vendor_profile_id: string | null;
}) => {
    const {
        hookState,
        VLNNotes,
        onAddNote,
        selectedVLNNote,
        selectedNoteIndex,
        onNoteSelect,
        onDeleteNote,
        updateNoteNoteHookState,
        updateNoteTitleHookState,
        updateNote,
    } = useVLNRelationshipNotes(vendor_profile_id);

    return (
        <>
            {hookState.state !== HookStateValue.READY ? (
                <Box
                    className={'flex flex--aic flex--jcc'}
                    width={'100%'}
                    height={'100%'}
                >
                    <CircularProgress sx={{ color: 'black' }} />
                </Box>
            ) : (
                <Box className={'flex'}>
                    <Box width={'400px'} minWidth={'400px'}>
                        <VLNRelationshipNotesList
                            VLNNotes={VLNNotes}
                            onAddNote={onAddNote}
                            onNoteSelect={onNoteSelect}
                            selectedNoteIndex={selectedNoteIndex}
                        />
                    </Box>
                    <Box width={'100%'}>
                        {selectedVLNNote ? (
                            <VLNRelationshipNotesEditSection
                                key={selectedVLNNote.vendor_profile_note_id}
                                selectedVLNNote={selectedVLNNote}
                                onDeleteNote={onDeleteNote}
                                updateNoteNoteHookState={
                                    updateNoteNoteHookState
                                }
                                updateNoteTitleHookState={
                                    updateNoteTitleHookState
                                }
                                updateNote={updateNote}
                            />
                        ) : (
                            <Box
                                className={'flex flex--aic flex--jcc'}
                                width={'100%'}
                                height={'100%'}
                            >
                                <Typography
                                    color={'text.secondary'}
                                    textAlign={'center'}
                                    fontSize={13}
                                >
                                    Click on a note to view/edit
                                </Typography>
                            </Box>
                        )}
                    </Box>
                </Box>
            )}
        </>
    );
};

export default VLNRelationshipNotesSection;
