import { Badge, styled } from '@mui/material';
import { CSSProperties } from 'react';
import { FWTooltip } from '../../Common/FWCustomTooltip';

const DotBadge = styled(Badge)({
    '&.MuiBadge-root': {
        width: 'auto !important',
    },
    '.MuiBadge-anchorOriginTopRightRectangular': {
        top: '0px',
        position: 'relative',
        transform: 'none',
        width: '10px',
        height: '10px',
        borderRadius: '50%',
    },
});

interface IGRStatusDotProps {
    status: string;
    style?: CSSProperties;
}

const GRStatusDot = ({ status, style }: IGRStatusDotProps) => {
    let [tooltip, color]: [
        string,
        (
            | 'default'
            | 'warning'
            | 'primary'
            | 'secondary'
            | 'error'
            | 'info'
            | 'success'
            | undefined
        )
    ] = ['', 'default'];
    switch (status) {
        case 'DELIVERY_PENDING':
            tooltip = 'Delivery pending';
            color = 'warning';
            break;
        case 'PARTIALLY_PENDING':
            tooltip = 'Partially pending';
            color = 'warning';
            break;
    }
    return (
        <FWTooltip title={tooltip}>
            <DotBadge
                variant="dot"
                badgeContent={1}
                color={color}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                style={{ ...style, width: '100%' }}
            ></DotBadge>
        </FWTooltip>
    );
};

export default GRStatusDot;
