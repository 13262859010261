import FWDataGrid from '../../../Common/FWDataGrid';
import { transactionColDef } from '../../Constants/transactionColDef';
import { useVLNRelationshipTransaction } from '../../Hooks/Relationship/useVLNRelationshipTransaction';

const VLNRelationshipTransactionSection = ({
    vendor_profile_id,
}: {
    vendor_profile_id: string | null;
}) => {
    const { transactions } = useVLNRelationshipTransaction(vendor_profile_id);
    return (
        <>
            <FWDataGrid
                columns={transactionColDef}
                rows={transactions}
                showSearchBar
            />
        </>
    );
};

export default VLNRelationshipTransactionSection;
