import { cloneDeep } from 'lodash';
import { IAdditionalCost } from '../../AdditionalCost/models/AdditionalCost.model';
import { calculateAdditionalCostForFormulaCustomFields } from '../../AdditionalCost/shared/AdditionalCostAutofill';
import { roundToNDecimalPlace } from '../../Common/CurrencyUtilsComponent';
import { ICostingItemPayloadForExportToQoute } from '../../Events/RFQAnalytics/Helpers/exportToQouteCalculator';
import { IGetFormulaResponse } from '../../Formula/Models/formula.model';
import { blankQuoteItemDSForPayload } from '../Constants/QuoteCalculatorConstants';
import {
    AdditionalFinalCostingDataInterface,
    IQuoteCustomField,
    IQUoteItemDeliveryScheduleBomItem,
} from '../Interfaces/CostingInterfaces.interface.';
import {
    IBOMItem,
    IQuoteBomResponse,
    IQuoteSubBom,
} from '../Interfaces/QuoteBom.interface';
import {
    IQuoteCreateNewBomPayloadBomDetails,
    newQuoteSheetItemPayload,
} from '../Interfaces/QuoteItem.interface';
import {
    ICostingCalculatorApiResponse,
    ICostingItemDashboardResponse,
} from '../Services/CostingApisCalls.service';
import { IQuoteCalculatorStore } from '../Store/Quote.store';
import {
    calculateFormulaAdditionalCostForQuoteItemAdditionalCost,
    calculateQuoteTotalAndSubtotal,
} from './QuoteHelperFunctions';
import { calculateTotalForAnItem } from './helperFunctions';

const LEAD_TIME = 2;
const LEAD_TIME_PERIOD = 'WEEKS';
export interface ICreateNewBom {
    quantity: number;
    rate: number;
    enterprise_item_id: string | null;
    enterprise_bom_id: string | null;
    vendor_rate: number;
    costing_sheet_item_boms: ICreateNewBom[];
    custom_fields: {
        section_list: {
            name: string;
            fields: IQuoteCustomField[];
        }[];
    };
    lead_time: number;
    lead_time_period: string;
    additional_costs: AdditionalFinalCostingDataInterface[];
}

export const convertBomItemToICostingItemPayloadForExportToQoute = (
    bomItem: IBOMItem
): ICostingItemPayloadForExportToQoute => {
    return {
        additional_costs: [],
        custom_fields: {
            section_list: [],
        },
        custom_sections: [],
        enterprise_bom_item_id: null,
        delivery_schedule: [],
        bomItemTrace: [],
        quantity: bomItem.quantity ?? 0,
        rate: 0,
        vendor_rate: 0,
        custom_fields_negotiate: {
            section_list: [],
        },
        bom_item_id: bomItem.entry_id,
        lead_time: LEAD_TIME.toString(),
        lead_time_period: LEAD_TIME_PERIOD,
        notes: null,
        projects: [],
        costing_sheet_item_boms:
            bomItem.sub_bom_items?.map((subBomItem) =>
                convertBomItemToICostingItemPayloadForExportToQoute(subBomItem)
            ) ?? [],
        enterprise_bom_id: bomItem.sub_bom?.enterprise_bom_id ?? null,
        enterprise_item_id:
            bomItem.raw_material_item?.enterprise_item_id ?? null,
        measurement_unit_id: bomItem.measurement_unit,
        rfq_bid_item_id: null,
        rfq_event_item_id: null,
        vendor_currency_id: null,
        vendor_entity_id: null,
        costing_sheet_item_id: null,
    };
};

export const convertBomItemToCreateApiPayload = (
    bomItem: IBOMItem,
    customFields: {
        section_list: {
            name: string;
            fields: IQuoteCustomField[];
        }[];
    }
): ICreateNewBom => {
    return {
        additional_costs: bomItem.additional_costs,
        custom_fields: customFields,
        quantity: bomItem.quantity ?? 0,
        rate: 0,
        vendor_rate: 0,
        lead_time: LEAD_TIME,
        lead_time_period: LEAD_TIME_PERIOD,
        enterprise_bom_id: bomItem.sub_bom?.enterprise_bom_id ?? null,
        enterprise_item_id:
            bomItem.raw_material_item?.enterprise_item_id ??
            bomItem?.sub_bom?.enterprise_item?.enterprise_item_id ??
            null,
        costing_sheet_item_boms:
            bomItem.sub_bom_items?.map((subBomItem) =>
                convertBomItemToCreateApiPayload(subBomItem, customFields)
            ) ?? [],
    };
};

export const convertIQuoteBomResponseToICostingItemPayloadForExportToQoute = (
    data: IQuoteBomResponse
): ICostingItemPayloadForExportToQoute => {
    return {
        additional_costs: data.additional_costs,
        delivery_schedule: [],
        custom_fields: {
            section_list: [],
        },
        custom_sections: [],
        costing_sheet_item_boms:
            data.bom_items?.map((bomItem) =>
                convertBomItemToICostingItemPayloadForExportToQoute(bomItem)
            ) ?? [],
        custom_fields_negotiate: {
            section_list: [],
        },
        enterprise_bom_item_id: data.entry_id,
        bomItemTrace: [],
        enterprise_bom_id: data.entry_id ?? null,
        enterprise_item_id: data.enterprise_item?.enterprise_item_id ?? null,
        lead_time: LEAD_TIME?.toString(),
        lead_time_period: LEAD_TIME_PERIOD,
        measurement_unit_id: data.measurement_unit.measurement_unit_id,
        bom_item_id: data.base_bom_id,
        notes: null,
        projects: [],
        quantity: data.quantity ?? 0,
        rate: 0,
        rfq_bid_item_id: null,
        rfq_event_item_id: null,
        vendor_currency_id: null,
        vendor_entity_id: null,
        vendor_rate: 0,
        costing_sheet_item_id: null,
    };
};

export const convertIQuoteBomResponseToICostingCalculatorApiResponse = (
    data: IQuoteBomResponse,
    customFields: {
        section_list: {
            name: string;
            fields: IQuoteCustomField[];
        }[];
    }
): ICreateNewBom => {
    return {
        additional_costs: data.additional_costs,
        quantity: data.quantity ?? 0,
        rate: 0,
        vendor_rate: 0,
        custom_fields: customFields,
        lead_time: LEAD_TIME,
        lead_time_period: LEAD_TIME_PERIOD,
        enterprise_bom_id: data.entry_id,
        enterprise_item_id: data.enterprise_item?.enterprise_item_id ?? null,
        costing_sheet_item_boms: data.bom_items.map((bomItem, index) =>
            convertBomItemToCreateApiPayload(bomItem, customFields)
        ),
    };
};

export const getBomHeirarchyFromQuoteItem = (
    QuoteItem: IQUoteItemDeliveryScheduleBomItem
) => {
    const ids: {
        entry_id: string;
        bom_code: string;
        index: number;
    }[] = [];

    function parentRecursive(
        currentItem: IQUoteItemDeliveryScheduleBomItem | null
    ) {
        if (currentItem !== null) {
            const entry_id = currentItem.entry_id;
            const bom_code = currentItem.sub_bom?.bom_code ?? '';
            const index = cloneDeep(currentItem.index) - 1;
            ids.push({
                bom_code,
                entry_id,
                index,
            });
            parentRecursive(currentItem.parent_sub_bom_item);
        }
        if (
            currentItem?.parent_sub_bom_item === null &&
            currentItem.enterprise_bom?.entry_id &&
            currentItem.enterprise_bom?.bom_code
        ) {
            ids.push({
                bom_code: currentItem.enterprise_bom?.bom_code,
                entry_id: currentItem.enterprise_bom?.entry_id,
                index: 0,
            });
        }
    }

    parentRecursive(QuoteItem.parent_sub_bom_item);
    if (
        QuoteItem.parent_sub_bom_item === null &&
        QuoteItem.enterprise_bom?.entry_id &&
        QuoteItem.enterprise_bom?.bom_code
    ) {
        ids.push({
            bom_code: QuoteItem.enterprise_bom?.bom_code,
            entry_id: QuoteItem.enterprise_bom?.entry_id,
            index: QuoteItem.index - 1,
        });
    }
    return ids;
};

export const convertIBOMItemToIQuoteSubBom = (
    bomItem: IBOMItem,
    subBomListForcurrentEnterpriseItem: {
        [subBomId: string]: IQuoteSubBom;
    },
    currentQuoteItemList: {
        [quoteItemId: string]: ICostingCalculatorApiResponse;
    },
    subBomQuantity: number,
    bomItemIdsMappedToIndex: {
        [costing_sheet_item_id: string]: {
            [bom_item_id: string]: number;
        };
    },
    parentBomEntryId: string[]
): IQuoteSubBom => {
    let currentItemCostingSheetItemid: string | null = null;
    let item_index: number = -1;

    let newParentBomEntryIdList = cloneDeep(parentBomEntryId);

    newParentBomEntryIdList.push(bomItem.entry_id ?? bomItem.bom_item_id);

    Object.keys(currentQuoteItemList).some((quoteItemId) => {
        const currentQuoteItemData = currentQuoteItemList[quoteItemId];

        const findCurrItemDS = currentQuoteItemData.delivery_schedule.find(
            (dsItem) =>
                dsItem.bom_item !== null &&
                dsItem.bom_item?.entry_id === bomItem.entry_id
        );

        if (findCurrItemDS) {
            currentItemCostingSheetItemid = cloneDeep(
                currentQuoteItemData.costing_sheet_item_id
            );

            item_index = bomItemIdsMappedToIndex[currentItemCostingSheetItemid]
                ? bomItemIdsMappedToIndex[currentItemCostingSheetItemid][
                      bomItem.entry_id
                  ]
                : -1;
            return true;
        }

        return false;
    });

    const structuredData: IQuoteSubBom = {
        bom_items:
            bomItem.sub_bom_items?.map((subBomItem) =>
                convertIBOMItemToIQuoteSubBom(
                    subBomItem,
                    subBomListForcurrentEnterpriseItem,
                    currentQuoteItemList,
                    bomItem.quantity ?? 0,
                    bomItemIdsMappedToIndex,
                    newParentBomEntryIdList
                )
            ) ?? [],
        additional_costs: bomItem.additional_costs,
        parent_bom_items: parentBomEntryId,
        isRawMaterial:
            bomItem.sub_bom_items?.length === 0 ||
            !Boolean(bomItem.sub_bom_items),
        isAvailable:
            currentItemCostingSheetItemid === null
                ? bomItem.sub_bom_items?.length > 0
                : item_index !== -1,
        rawMaterialQuantityPerUnitBom: (bomItem.quantity ?? 0) / subBomQuantity,
        bom_code:
            bomItem.sub_bom === null
                ? bomItem.raw_material_item?.code ?? ''
                : bomItem.sub_bom?.bom_code ?? '',
        bom_name:
            bomItem.sub_bom === null
                ? bomItem.raw_material_item?.name ?? ''
                : bomItem.sub_bom?.enterprise_item?.name ?? '',
        entry_id: bomItem.entry_id ?? bomItem.bom_item_id,
        measurement_unit_data: {
            measurement_unit_id:
                bomItem?.sub_bom?.enterprise_item?.measurement_units
                    ?.item_measurement_units[0]?.measurement_unit_id ?? '',
            measurement_unit_primary_name:
                bomItem?.sub_bom?.enterprise_item?.measurement_units
                    ?.item_measurement_units[0]
                    ?.measurement_unit_primary_name ?? '',
        },
        item_index,
        quantity: bomItem.quantity ?? 0,
        total: bomItem.subtotal,
        costing_sheet_item_id: currentItemCostingSheetItemid,
    };

    if (bomItem?.sub_bom_items?.length > 0) {
        subBomListForcurrentEnterpriseItem[
            bomItem.entry_id ?? bomItem.bom_item_id
        ] = structuredData;
    }

    return structuredData;
};

export const convertIQuoteBomResponseToIQuoteSubBom = (
    enterpriseBomData: IQuoteBomResponse,
    subBomListForcurrentEnterpriseItem: {
        [subBomId: string]: IQuoteSubBom;
    },
    currentQuoteItemList: {
        [quoteItemId: string]: ICostingCalculatorApiResponse;
    },
    bomItemIdsMappedToIndex: {
        [costing_sheet_item_id: string]: {
            [bom_item_id: string]: number;
        };
    }
): IQuoteSubBom => {
    let currentItemCostingSheetItemid: string | null = null;
    let item_index: number = -1;

    let parentBomEntryId: string[] = [
        enterpriseBomData.entry_id ?? enterpriseBomData.bom_item_id,
    ];

    Object.keys(currentQuoteItemList).some((quoteItemId) => {
        const currentQuoteItemData = currentQuoteItemList[quoteItemId];

        const findCurrItemDS = currentQuoteItemData.delivery_schedule.find(
            (dsItem) => dsItem.bom_item?.entry_id === enterpriseBomData.entry_id
        );

        if (findCurrItemDS) {
            currentItemCostingSheetItemid =
                currentQuoteItemData.costing_sheet_item_id;

            item_index = bomItemIdsMappedToIndex[currentItemCostingSheetItemid]
                ? bomItemIdsMappedToIndex[currentItemCostingSheetItemid][
                      enterpriseBomData.entry_id
                  ]
                : -1;
            return true;
        }
        return false;
    });

    const structuredData: IQuoteSubBom = {
        quantity: enterpriseBomData.quantity ?? 0,
        additional_costs: enterpriseBomData.additional_costs,
        entry_id: enterpriseBomData.entry_id ?? enterpriseBomData.bom_item_id,
        bom_code:
            enterpriseBomData.enterprise_bom?.bom_code ??
            enterpriseBomData?.bom_code ??
            '',
        parent_bom_items: [],
        rawMaterialQuantityPerUnitBom: enterpriseBomData.quantity ?? 0,
        isRawMaterial: !Boolean(enterpriseBomData.bom_items),
        isAvailable:
            currentItemCostingSheetItemid === null
                ? enterpriseBomData.bom_items?.length > 0
                : item_index !== -1,
        bom_name:
            enterpriseBomData?.enterprise_bom?.enterprise_item?.name ??
            enterpriseBomData?.enterprise_item?.name ??
            '',
        bom_items: enterpriseBomData.bom_items?.map((bomItem) =>
            convertIBOMItemToIQuoteSubBom(
                bomItem,
                subBomListForcurrentEnterpriseItem,
                currentQuoteItemList,
                enterpriseBomData.quantity ?? 0,
                bomItemIdsMappedToIndex,
                parentBomEntryId
            )
        ),
        item_index,
        measurement_unit_data: enterpriseBomData?.measurement_unit,
        total: enterpriseBomData.total,
        costing_sheet_item_id: currentItemCostingSheetItemid,
    };
    if (enterpriseBomData.bom_items?.length > 0) {
        subBomListForcurrentEnterpriseItem[
            enterpriseBomData.entry_id ?? enterpriseBomData.bom_item_id
        ] = structuredData;
    }

    return structuredData;
};

export const getAllSubBomsFromEnterpriseBOM = (
    enterpriseBomList: {
        [enterpriseBOMId: string]: IQuoteBomResponse;
    },

    currentQuoteItemList: {
        [quoteItemId: string]: ICostingCalculatorApiResponse;
    },
    bomItemIdsMappedToIndex: {
        [costing_sheet_item_id: string]: {
            [bom_item_id: string]: number;
        };
    }
) => {
    let subBomList: {
        [enterpriseBOMIdList: string]: {
            index: any;
            parentBom: IQuoteSubBom;
            [subBomId: string]: IQuoteSubBom;
        };
    } = {};

    Object.keys(enterpriseBomList).forEach((enterpriseBOMId, index) => {
        const currentBomData = cloneDeep(enterpriseBomList[enterpriseBOMId]);
        const subBomListForcurrentEnterpriseItem: {
            [subBomId: string]: IQuoteSubBom;
        } = {};

        const formattedData = convertIQuoteBomResponseToIQuoteSubBom(
            currentBomData,
            subBomListForcurrentEnterpriseItem,
            currentQuoteItemList,
            bomItemIdsMappedToIndex
        );

        subBomList[enterpriseBOMId] = {
            index: index as any,
            parentBom: formattedData,
        };

        subBomList[enterpriseBOMId] = {
            ...subBomList[enterpriseBOMId],
            ...subBomListForcurrentEnterpriseItem,
        };
    });

    return subBomList;
};

export const quoteBoMUpdateQUantityOfBOMs = (
    currentSubBomItemToBeUpdated: IQuoteSubBom,
    enterpriseBomData: {
        [subBomId: string]: IQuoteSubBom;
        index: any;
        parentBom: IQuoteSubBom;
    },
    bomItemQUantity: number,
    newQuoteItemDetails: {
        [quoteItemId: string]: ICostingCalculatorApiResponse[];
    },
    subBomData: {
        [subBomId: string]: IQuoteSubBom;
    },
    currencyCodeAbbreviation: string,
    listOfFormulaForCustomFields: IGetFormulaResponse[],
    state: IQuoteCalculatorStore,
    enterpriseBomId: string
) => {
    const currentSubBomData =
        enterpriseBomData[currentSubBomItemToBeUpdated.entry_id];

    for (let item of currentSubBomItemToBeUpdated.bom_items) {
        const itemInCurrentSubBom = currentSubBomData.bom_items.findIndex(
            (it) => it.bom_name === item.bom_name
        );

        if (item.bom_name === 'Header, 1x8, 2.54mm pitch') {
        }

        const bomCPU = item.total / item.quantity;

        const rawMaterialPerUnitQuantity =
            itemInCurrentSubBom !== -1
                ? currentSubBomData.bom_items[itemInCurrentSubBom]
                      .rawMaterialQuantityPerUnitBom
                : item.rawMaterialQuantityPerUnitBom;

        item.quantity = rawMaterialPerUnitQuantity * bomItemQUantity;

        item.total = bomCPU * item.quantity;

        if (item.costing_sheet_item_id && item.item_index !== -1) {
            newQuoteItemDetails[item.costing_sheet_item_id][
                item.item_index
            ].quantity = (
                rawMaterialPerUnitQuantity * bomItemQUantity
            )?.toString();

            if (item.bom_items?.length > 0) {
                quoteBoMUpdateQUantityOfBOMs(
                    item,
                    enterpriseBomData,
                    bomItemQUantity * rawMaterialPerUnitQuantity,
                    newQuoteItemDetails,
                    subBomData,
                    currencyCodeAbbreviation,
                    listOfFormulaForCustomFields,
                    state,
                    enterpriseBomId
                );
                state.enterpriseBomData[enterpriseBomId][item.entry_id] = item;
            } else if (item.costing_sheet_item_id && item.item_index !== -1) {
                const newAdditionalCostsWithFormulaValue =
                    calculateFormulaAdditionalCostForQuoteItemAdditionalCost(
                        newQuoteItemDetails[item.costing_sheet_item_id][
                            item.item_index
                        ].additional_costs,
                        newQuoteItemDetails[item.costing_sheet_item_id][
                            item.item_index
                        ].quantity.toString(),
                        newQuoteItemDetails[item.costing_sheet_item_id][
                            item.item_index
                        ].rate,
                        currencyCodeAbbreviation,
                        newQuoteItemDetails[item.costing_sheet_item_id][
                            item.item_index
                        ].custom_sections,
                        newQuoteItemDetails[item.costing_sheet_item_id][
                            item.item_index
                        ].enterprise_item_details.code
                    );

                newQuoteItemDetails[item.costing_sheet_item_id][
                    item.item_index
                ].additional_costs = newAdditionalCostsWithFormulaValue;

                newQuoteItemDetails[item.costing_sheet_item_id][
                    item.item_index
                ] = calculateAdditionalCostForFormulaCustomFields({
                    item: newQuoteItemDetails[item.costing_sheet_item_id][
                        item.item_index
                    ],
                    listOfFormulaForCustomFields,
                });
            }
        } else {
            const itemInCurrentSubBom = currentSubBomData.bom_items.findIndex(
                (it) => it.bom_name === item.bom_name
            );

            const bomCPU = item.total / item.quantity;

            const rawMaterialPerUnitQuantity =
                itemInCurrentSubBom !== -1
                    ? currentSubBomData.bom_items[itemInCurrentSubBom]
                          .rawMaterialQuantityPerUnitBom
                    : item.rawMaterialQuantityPerUnitBom;

            item.quantity = rawMaterialPerUnitQuantity * bomItemQUantity;

            item.total = bomCPU * item.quantity;

            item.total = bomCPU * item.quantity;
            if (item.bom_items?.length > 0) {
                quoteBoMUpdateQUantityOfBOMs(
                    item,
                    enterpriseBomData,
                    bomItemQUantity * rawMaterialPerUnitQuantity,
                    newQuoteItemDetails,
                    subBomData,
                    currencyCodeAbbreviation,
                    listOfFormulaForCustomFields,
                    state,
                    enterpriseBomId
                );
                state.enterpriseBomData[enterpriseBomId][item.entry_id] = item;
            } else if (item.costing_sheet_item_id && item.item_index !== -1) {
                const newAdditionalCostsWithFormulaValue =
                    calculateFormulaAdditionalCostForQuoteItemAdditionalCost(
                        newQuoteItemDetails[item.costing_sheet_item_id][
                            item.item_index
                        ].additional_costs,
                        newQuoteItemDetails[item.costing_sheet_item_id][
                            item.item_index
                        ].quantity.toString(),
                        newQuoteItemDetails[item.costing_sheet_item_id][
                            item.item_index
                        ].rate,
                        currencyCodeAbbreviation,
                        newQuoteItemDetails[item.costing_sheet_item_id][
                            item.item_index
                        ].custom_sections,
                        newQuoteItemDetails[item.costing_sheet_item_id][
                            item.item_index
                        ].enterprise_item_details.code
                    );

                newQuoteItemDetails[item.costing_sheet_item_id][
                    item.item_index
                ].additional_costs = newAdditionalCostsWithFormulaValue;

                newQuoteItemDetails[item.costing_sheet_item_id][
                    item.item_index
                ] = calculateAdditionalCostForFormulaCustomFields({
                    item: newQuoteItemDetails[item.costing_sheet_item_id][
                        item.item_index
                    ],
                    listOfFormulaForCustomFields,
                });
            }
        }
    }
};

export const getBomitemIndexMappingusingSplitItems = (
    quoteItemDetails: {
        [quoteItemId: string]: ICostingCalculatorApiResponse[];
    },
    quoteItemIds: string[]
) => {
    let bomItemIndexMapping: {
        [costing_sheet_item_id: string]: {
            [bom_item_id: string]: number;
        };
    } = {};

    for (let quoteItemId of quoteItemIds) {
        if (bomItemIndexMapping[quoteItemId] === undefined)
            bomItemIndexMapping[quoteItemId] = {};
        for (
            let index = 0;
            index < quoteItemDetails[quoteItemId].length;
            index++
        ) {
            const item = quoteItemDetails[quoteItemId][index];

            const bomItemIdData = item.delivery_schedule.find(
                (dsItem) => dsItem.bom_item?.entry_id
            );

            if (bomItemIdData && bomItemIdData.bom_item?.entry_id) {
                bomItemIndexMapping[quoteItemId] = {
                    ...bomItemIndexMapping[quoteItemId],
                    [bomItemIdData.bom_item?.entry_id]: index,
                };
            }
        }
    }

    return bomItemIndexMapping;
};

export const getCostingSheetItemId = (
    bomItem: IQuoteSubBom,
    uniqueCostingSheetItemId: Set<string>
) => {
    if (bomItem.costing_sheet_item_id) {
        uniqueCostingSheetItemId.add(bomItem.costing_sheet_item_id);
    } else {
        bomItem.bom_items.forEach((subBomItem) => {
            getCostingSheetItemId(subBomItem, uniqueCostingSheetItemId);
        });
    }
};

export const getRawMaterialIdFromEnterpriseBomData = (
    enterpriseBomData: {
        [enterpriseBOMIdList: string]: {
            index: any;
            [subBomId: string]: IQuoteSubBom;
            parentBom: IQuoteSubBom;
        };
    },
    selectedEnterpriseBomId: string | null,
    selectedSubBomEntryId: string | null
) => {
    let uniqueCostingSheetItemId = new Set<string>();
    const enterpriseBomDataForSelectedBOM = selectedEnterpriseBomId
        ? enterpriseBomData[selectedEnterpriseBomId]
        : null;

    if (enterpriseBomDataForSelectedBOM) {
        if (selectedSubBomEntryId) {
            (
                enterpriseBomDataForSelectedBOM[selectedSubBomEntryId]
                    .bom_items ?? []
            ).forEach((bomItem) => {
                getCostingSheetItemId(bomItem, uniqueCostingSheetItemId);
            });
        } else {
            (enterpriseBomDataForSelectedBOM.parentBom.bom_items ?? []).forEach(
                (bomItem) => {
                    getCostingSheetItemId(bomItem, uniqueCostingSheetItemId);
                }
            );
        }
    }

    const uniqueItemArray = Array.from(uniqueCostingSheetItemId);

    return uniqueItemArray;
};

// export const recursiverlyCalculateTotalForBOM = (
//     costing_sheet_item_boms: ICostingCalculatorApiResponse[],
//     enterprise_bom_id: string | null,
//     additional_costs: AdditionalFinalCostingDataInterface[],
//     rate: string,
//     quantity: string
// ) => {
//     console.log(
//         'calculating total for item again',
//         costing_sheet_item_boms,
//         enterprise_bom_id,
//         additional_costs,
//         rate,
//         quantity
//     );
//     if (Boolean(enterprise_bom_id)) {
//         const bomRateArr = costing_sheet_item_boms.map((bomItem) => {
//             return recursiverlyCalculateTotalForBOM(
//                 bomItem.costing_sheet_item_boms ?? [],
//                 bomItem.enterprise_bom_details?.enterprise_bom_id ?? null,
//                 bomItem.additional_costs,
//                 bomItem.rate,
//                 bomItem.quantity
//             );
//         });
//         const bomRateItemsTotal = bomRateArr.reduce((a, b) => a + b, 0);
//         let res: number = bomRateItemsTotal;
//
//         return res;
//     } else {
//         let val = calculateTotalForAnItem(
//             additional_costs,
//             rate,
//             +quantity
//         ).total;
//
//         return val;
//     }
// };
// export const recursiverlyCalculateEffectiveRateForBOM = (
//     data: ICostingCalculatorApiResponse
// ) => {
//     const clonedData = cloneDeep(data);

//     if (Boolean(clonedData.enterprise_bom_details?.enterprise_bom_id)) {
//         const bomRateArr = clonedData.costing_sheet_item_boms.map((bomItem) => {
//             return recursiverlyCalculateTotalForBOM(
//                 bomItem.costing_sheet_item_boms ?? [],
//                 bomItem.enterprise_bom_details?.enterprise_bom_id ?? null,
//                 bomItem.additional_costs,
//                 bomItem.rate,
//                 bomItem.quantity
//             );
//         });
//         const bomRateItemsTotal = bomRateArr.reduce((a, b) => a + b, 0);
//         let res: number = bomRateItemsTotal;
//         return res;
//     } else {
//         return calculateTotalForAnItem(
//             clonedData.additional_costs,
//             clonedData.rate,
//             +clonedData.quantity
//         ).effectiveRate;
//     }
// };

// export const appendBomItemsInQuoteItemDetails = (
//     bomItem: ICostingCalculatorApiResponse,
//     newBOMItems: {
//         [quoteItemId: string]: ICostingCalculatorApiResponse;
//     }
// ) => {
//     if (bomItem.enterprise_bom_details?.enterprise_bom_id) {
//         for (let subBomItem of bomItem.costing_sheet_item_boms) {
//             newBOMItems[bomItem.costing_sheet_item_id] = bomItem;
//             appendBomItemsInQuoteItemDetails(subBomItem, newBOMItems);
//         }
//     } else {
//         return (newBOMItems[bomItem.costing_sheet_item_id] = bomItem);
//     }
// };

// export const recursivelyCalculateBOMData = (
//     currentItem: ICostingCalculatorApiResponse
// ) => {
//     const data = cloneDeep(currentItem);
//     if (data.enterprise_bom_details === null) {
//         return currentItem;
//     } else {
//         const children = data.costing_sheet_item_boms?.map((bom_item) =>
//             recursivelyCalculateBOMData(bom_item)
//         );

//         const bomRate = +children.reduce((a, b) => {
//             const childTotal = calculateTotalForAnItem(
//                 b?.additional_costs ?? [],
//                 +(b?.rate || 0),
//                 +(b?.quantity || 0)
//             ).total;

//             return +a + +childTotal;
//         }, 0);

//         data.rate = (+(bomRate / +data.quantity).toFixed(2)).toString();
//         if (+data.rate === 0) data.rate = '';
//         data.costing_sheet_item_boms = children;
//         return { ...data };
//     }
// };

// export const recursivelyGetQuoteItemIds = (
//     value: ICostingCalculatorApiResponse[],
//     itemIds: string[]
// ) => {
//     for (const item of value) {
//         if (!Boolean(item.enterprise_bom_details?.enterprise_bom_id)) {
//             itemIds.push(item.costing_sheet_item_id);
//         } else {
//             recursivelyGetQuoteItemIds(item.costing_sheet_item_boms, itemIds);
//         }
//     }
// };

export const getMatchingBOMCustomFieldsFromTemplate = (
    templateCustomFields: {
        name: string;
        section_type: 'ITEM';
        custom_fields: IQuoteCustomField[];
    }[],
    bomCustomFields: {
        start_time: string | null;
        last_modified_time: string | null;
        assigned_user_ids: string[];
        edit_user_ids: string[];
        view_user_ids: string[];
        name: string;
        section_type: 'ITEM';
        custom_fields: IQuoteCustomField[];
    }[]
): {
    start_time: string | null;
    last_modified_time: string | null;
    assigned_user_ids: string[];
    edit_user_ids: string[];
    view_user_ids: string[];
    name: string;
    section_type: 'ITEM';
    custom_fields: IQuoteCustomField[];
}[] => {
    let customFieldList: {
        start_time: string | null;
        last_modified_time: string | null;
        assigned_user_ids: string[];
        edit_user_ids: string[];
        view_user_ids: string[];
        name: string;
        section_type: 'ITEM';
        custom_fields: IQuoteCustomField[];
    }[] = [
        {
            start_time: null,
            last_modified_time: null,
            assigned_user_ids: [],
            edit_user_ids: [],
            view_user_ids: [],
            name: templateCustomFields[0].name,
            section_type: 'ITEM',
            custom_fields: [],
        },
    ];

    const allBOMCustomFields = (bomCustomFields ?? [])
        .map((section) => section.custom_fields)
        .flat();

    if ((bomCustomFields ?? []).length === 0) {
        return [
            {
                ...templateCustomFields[0],
                start_time: null,
                last_modified_time: null,
                assigned_user_ids: [],
                edit_user_ids: [],
                view_user_ids: [],
            },
        ];
    } else {
        templateCustomFields[0].custom_fields.forEach((field) => {
            const templateCustomFieldInBOMIndex = allBOMCustomFields.findIndex(
                (bomCustomField) =>
                    bomCustomField.name === field.name &&
                    bomCustomField.type === field.type
            );

            if (
                customFieldList[0]?.custom_fields !== undefined ||
                customFieldList[0]?.custom_fields !== null
            ) {
                if (templateCustomFieldInBOMIndex === -1) {
                    customFieldList[0].custom_fields.push(field);
                } else {
                    customFieldList[0].custom_fields.push(
                        allBOMCustomFields[templateCustomFieldInBOMIndex]
                    );
                }
            }
        });
    }

    return customFieldList;
};

export const getAdditionalCostFromTemplate = (
    additionalCostOptions: ({
        is_required: boolean;
    } & IAdditionalCost)[]
): AdditionalFinalCostingDataInterface[] => {
    return additionalCostOptions.map((cost, index) => ({
        additional_cost_id: cost.additional_cost_id ?? null,
        additional_cost_linkage_id: null,
        allocation_type: cost.allocationType,
        cost_name: cost.costName,
        cost_source: cost.cost_source,
        cost_type: cost.costType,
        cost_value: 0,
        formula: cost.formula
            ? cost.formula?.formula_string === undefined
                ? { ...cost.formula, formula_string: null }
                : cost.formula
            : null,
        is_calculated: cost.is_calculated ?? true,
        source_value: 0,
        conversion_rate: 1,
        source_currency_id: cost.source_currency_id ?? null,
        sequence: index + 1,
    }));
};

export const getRawMaterialsListFromIQuoteBomResponseSubBoms = (
    subBoms: IBOMItem[],
    bomDetails: IQuoteCreateNewBomPayloadBomDetails
) => {
    const payload: newQuoteSheetItemPayload[] = [];

    for (let bomItem of subBoms) {
        const currentBomItemIndexInList = bomDetails.bom_items?.findIndex(
            (item) => item.bom_item_id === bomItem.bom_item_id
        );
        if (currentBomItemIndexInList === -1) {
            bomDetails.bom_items.push({
                bom_item_id: bomItem.bom_item_id,
                quantity: bomItem.quantity,
                total: 0,
            });
        }
        if (
            bomItem.sub_bom_items?.length === 0 ||
            bomItem?.sub_bom_items === null
        ) {
            payload.push({
                additional_costs: [],
                custom_fields: {
                    section_list: [],
                },
                custom_sections: [],
                measurement_unit_id: bomItem.measurement_unit ?? null,
                delivery_schedule: [
                    {
                        ...blankQuoteItemDSForPayload,
                        quantity: bomItem.quantity,
                        bom_item_id: bomItem.bom_item_id,
                    },
                ],
                enterprise_item_id:
                    bomItem.raw_material_item?.enterprise_item_id ?? null,
                lead_time: LEAD_TIME,
                lead_time_period: LEAD_TIME_PERIOD,
                quantity: bomItem.quantity,
                rate: bomItem.cost_per_unit,
                bom_item_id: bomItem.bom_item_id,
            });
        } else {
            payload.push(
                ...getRawMaterialsListFromIQuoteBomResponseSubBoms(
                    bomItem.sub_bom_items,
                    bomDetails
                )
            );
        }
    }
    return payload;
};

export const getRawMaterialsListFromIQuoteBomResponse = (
    enterpriseBomData: IQuoteBomResponse,
    bomDetails: IQuoteCreateNewBomPayloadBomDetails
) => {
    const payload: newQuoteSheetItemPayload[] = [];

    bomDetails.quantity = enterpriseBomData.quantity ?? 0;

    for (let bomItem of enterpriseBomData.bom_items) {
        const currentBomItemIndexInList = bomDetails.bom_items.findIndex(
            (item) => item.bom_item_id === bomItem.bom_item_id
        );
        if (currentBomItemIndexInList === -1) {
            bomDetails.bom_items.push({
                bom_item_id: bomItem.bom_item_id,
                quantity: bomItem.quantity,
                total: 0,
            });
        }
        if (
            bomItem.sub_bom_items?.length === 0 ||
            bomItem.sub_bom_items === null
        ) {
            payload.push({
                additional_costs: [],
                custom_fields: {
                    section_list: [],
                },
                custom_sections: [],
                bom_item_id: bomItem.bom_item_id,
                delivery_schedule: [
                    {
                        ...blankQuoteItemDSForPayload,
                        quantity: bomItem.quantity,
                        bom_item_id: bomItem.bom_item_id,
                    },
                ],
                measurement_unit_id: bomItem.measurement_unit ?? null,
                enterprise_item_id:
                    bomItem.raw_material_item?.enterprise_item_id ?? null,
                lead_time: LEAD_TIME,
                lead_time_period: LEAD_TIME_PERIOD,
                quantity: bomItem.quantity,
                rate: bomItem.cost_per_unit,
            });
        } else {
            payload.push(
                ...getRawMaterialsListFromIQuoteBomResponseSubBoms(
                    bomItem.sub_bom_items,
                    bomDetails
                )
            );
        }
    }
    return payload;
};

export const getEnterpriseBomIdListFromItemDashboardResponse = (
    costingItemDashboardResponse: ICostingItemDashboardResponse
) => {
    const enterpriseBomIDSet = new Set<string>();

    costingItemDashboardResponse.data.forEach((item) => {
        item?.delivery_schedule?.forEach((dsItem) => {
            const enterprieBomIdOfDSItem =
                dsItem?.bom_item?.enterprise_bom?.entry_id ?? null;

            if (enterprieBomIdOfDSItem !== null) {
                enterpriseBomIDSet.add(enterprieBomIdOfDSItem);
            }
        });
    });
    const enterpriseBomIDList = Array.from(enterpriseBomIDSet);

    return enterpriseBomIDList;
};

export const getAllRawMaterialsInsideSubBOM = (
    subBomData: IQuoteSubBom | null
) => {
    const rawMaterialCostingSheetItemIdList = new Set<string>();

    const getRawMaterialId = (subBom: IQuoteSubBom) => {
        if (subBom?.costing_sheet_item_id !== null) {
            rawMaterialCostingSheetItemIdList.add(
                subBom?.costing_sheet_item_id
            );
        }

        for (let subBomItem of subBom.bom_items) {
            getRawMaterialId(subBomItem);
        }
    };

    if (subBomData !== null) {
        if (subBomData?.costing_sheet_item_id !== null) {
            rawMaterialCostingSheetItemIdList.add(
                subBomData?.costing_sheet_item_id
            );
        }
        for (let subBomItem of subBomData.bom_items) {
            getRawMaterialId(subBomItem);
        }
    }

    const uniqueIds = Array.from(rawMaterialCostingSheetItemIdList).filter(
        Boolean
    );
    return uniqueIds ?? [];
};

export const calculateSubBomTotal = (
    enterpriseBomData: {
        [enterpriseBOMIdList: string]: {
            [subBomId: string]: IQuoteSubBom;
            index: any;
            parentBom: IQuoteSubBom;
        };
    },
    currency_code_abbreviation: string,
    enterpriseBomId: string,
    sub_bom_id: string,
    listOfFormulaForCustomFields: IGetFormulaResponse[],
    quoteItemData: {
        [quoteItemId: string]: ICostingCalculatorApiResponse[];
    }
) => {
    const subBomData = enterpriseBomData[enterpriseBomId]
        ? enterpriseBomData[enterpriseBomId][sub_bom_id]
        : null;
    const allRawMaterialsInsideSubBOM =
        getAllRawMaterialsInsideSubBOM(subBomData);

    const { finalTotal } = calculateQuoteTotalAndSubtotal(
        allRawMaterialsInsideSubBOM,
        quoteItemData,
        [],
        currency_code_abbreviation,
        listOfFormulaForCustomFields,
        enterpriseBomData,
        enterpriseBomId,
        sub_bom_id
    );

    return finalTotal;
};

export const getAllBomEntryIdsAndQuantityFromEnterpriseBOM = (
    enterpriseBomData: {
        [enterpriseBOMIdList: string]: {
            [subBomId: string]: IQuoteSubBom;
            index: any;
            parentBom: IQuoteSubBom;
        };
    },
    currency_code_abbreviation: string,
    enterpriseBomId: string,
    sub_bom_id: string,
    listOfFormulaForCustomFields: IGetFormulaResponse[],
    quoteItemData: {
        [quoteItemId: string]: ICostingCalculatorApiResponse[];
    }
) => {
    const enterpriseBomTotal = calculateSubBomTotal(
        enterpriseBomData,
        currency_code_abbreviation,
        enterpriseBomId,
        sub_bom_id,
        listOfFormulaForCustomFields,
        quoteItemData
    );

    let bomDetails: IQuoteCreateNewBomPayloadBomDetails = {
        bom_items: [],
        quantity: 0,
        total: +enterpriseBomTotal.toFixed(4),
        enterprise_bom_id: enterpriseBomId ?? '',
        additional_costs: [],
    };

    const recursivelyCalculateBomDetailsFromSubBomItems = (
        bom_items: IQuoteSubBom[]
    ) => {
        for (let bomItem of bom_items) {
            const currentBomItemIndexInList = bomDetails.bom_items.findIndex(
                (item) => item.bom_item_id === bomItem.entry_id
            );
            let currentBomItemData = {
                bom_item_id: bomItem.entry_id,
                total: 0,
                quantity: bomItem.quantity,
                additional_costs: [] as AdditionalFinalCostingDataInterface[],
            };

            if (bomItem.costing_sheet_item_id && bomItem.item_index !== -1) {
                const currentItemData = quoteItemData[
                    bomItem.costing_sheet_item_id
                ]
                    ? quoteItemData[bomItem.costing_sheet_item_id][
                          bomItem.item_index
                      ]
                    : null;

                if (currentItemData) {
                    currentBomItemData.additional_costs =
                        currentItemData.additional_costs.map((cost) => ({
                            ...cost,
                            cost_value: roundToNDecimalPlace(
                                +cost.cost_value,
                                10
                            ).toString(),
                        }));
                    const itemTotal = calculateTotalForAnItem(
                        currentItemData.additional_costs.filter(
                            (cost) => cost.is_calculated
                        ),
                        currentItemData.rate ?? 0,
                        +currentItemData.quantity
                    )?.total;

                    currentBomItemData.total = isNaN(itemTotal)
                        ? 0
                        : +itemTotal.toFixed(4);
                }
            } else if (bomItem.entry_id && !bomItem.isRawMaterial) {
                const subBomTotal = calculateSubBomTotal(
                    enterpriseBomData,
                    currency_code_abbreviation,
                    enterpriseBomId,
                    bomItem.entry_id,
                    listOfFormulaForCustomFields,
                    quoteItemData
                );
                currentBomItemData.total = isNaN(subBomTotal)
                    ? 0
                    : +subBomTotal.toFixed(4);
            }

            if (currentBomItemIndexInList === -1) {
                bomDetails.bom_items.push(currentBomItemData);
            }
            if (bomItem.bom_items?.length > 0 && Boolean(bomItem.bom_items)) {
                recursivelyCalculateBomDetailsFromSubBomItems(
                    bomItem.bom_items
                );
            }
        }
    };

    const currentEnterpriseBomData = enterpriseBomId
        ? enterpriseBomData[enterpriseBomId]
        : null;

    if (
        currentEnterpriseBomData &&
        enterpriseBomId &&
        currentEnterpriseBomData[enterpriseBomId]
    ) {
        const enterpriseBomDetails = cloneDeep(
            currentEnterpriseBomData[enterpriseBomId]
        );
        bomDetails.quantity = enterpriseBomDetails.quantity ?? 0;
        bomDetails.additional_costs =
            enterpriseBomDetails.additional_costs ?? [];

        for (let bomItem of enterpriseBomDetails.bom_items) {
            const currentBomItemIndexInList = bomDetails.bom_items.findIndex(
                (item) => item.bom_item_id === bomItem.entry_id
            );
            let currentBomItemData = {
                bom_item_id: bomItem.entry_id,
                total: 0,
                quantity: bomItem.quantity,
                additional_costs: [] as AdditionalFinalCostingDataInterface[],
            };

            if (bomItem.costing_sheet_item_id && bomItem.item_index !== -1) {
                const currentItemData = quoteItemData[
                    bomItem.costing_sheet_item_id
                ]
                    ? quoteItemData[bomItem.costing_sheet_item_id][
                          bomItem.item_index
                      ]
                    : null;

                if (currentItemData) {
                    currentBomItemData.additional_costs =
                        currentItemData.additional_costs.map((cost) => ({
                            ...cost,
                            cost_value: roundToNDecimalPlace(
                                +cost.cost_value,
                                10
                            ).toString(),
                        }));
                    const itemTotal = calculateTotalForAnItem(
                        currentItemData.additional_costs.filter(
                            (cost) => cost.is_calculated
                        ),
                        currentItemData.rate ?? 0,
                        +currentItemData.quantity
                    )?.total;

                    currentBomItemData.total = isNaN(itemTotal)
                        ? 0
                        : Number(itemTotal.toFixed(4));
                }
            } else if (bomItem.entry_id && !bomItem.isRawMaterial) {
                const subBomTotal = calculateSubBomTotal(
                    enterpriseBomData,
                    currency_code_abbreviation,
                    enterpriseBomId,
                    bomItem.entry_id,
                    listOfFormulaForCustomFields,
                    quoteItemData
                );
                currentBomItemData.total = isNaN(subBomTotal)
                    ? 0
                    : Number(subBomTotal.toFixed(4));
            }

            if (currentBomItemIndexInList === -1) {
                bomDetails.bom_items.push(currentBomItemData);
            }

            if (bomItem.bom_items?.length > 0 && Boolean(bomItem.bom_items)) {
                recursivelyCalculateBomDetailsFromSubBomItems(
                    bomItem.bom_items
                );
            }
        }
    }

    return bomDetails;
};
