import { DashCols } from '../../../CLM/Components/CLMDashboardColumns';
import { useDashBoard } from '../../../CLM/Hooks/useDashBoard';
import FWDataGrid from '../../../Common/FWDataGrid';
import EventsDashboardCustomGridHeader from '../../../Global/Components/CustomDashboardGridHeader';

const VLNRelationshipContractsDashboard = ({
    sellerEntityUuid,
}: {
    sellerEntityUuid: string;
}) => {
    const {
        sortFields,
        currentPage,
        setContractsDashboardSettings,
        debouncedSearch,
        searchText,
        clmDashboardData,
        fetchContractDashboardDetails,
        totalPages,
    } = useDashBoard(sellerEntityUuid, true);

    const onDelete = () => {};
    const onClone = () => {};

    return (
        <>
            <FWDataGrid
                disableFESearch
                onTextChange={debouncedSearch}
                columns={DashCols({
                    tab_name: 'all',
                    onClickDeleteOption: onDelete,
                    onClickCloneOption: onClone,
                    isVLNDashboard: true,
                })}
                defaultColDef={{
                    resizable: true,
                    headerComponent: (props: any) => (
                        <EventsDashboardCustomGridHeader
                            {...props}
                            sortFields={sortFields}
                            setSortFields={(
                                newSortFields: {
                                    field: string;
                                    ascending: boolean;
                                }[]
                            ) => {
                                setContractsDashboardSettings((prev) => ({
                                    ...prev,
                                    sortFields: newSortFields,
                                }));
                            }}
                        />
                    ),
                }}
                rows={clmDashboardData}
                // onRowClicked={onRowClicked}
                // fixedHeight={605}
                showSearchBar
                suppressPaginationPanel
                useCustomPagination={totalPages}
                customPaginationOnChange={(e, pageNumber) => {
                    setContractsDashboardSettings((prev) => ({
                        ...prev,
                        currentPage: pageNumber,
                    }));
                }}
                customPaginationPageNumber={currentPage}
                showFilterColumnMenu
                context={{
                    fetchContractDashboardDetails,
                    currentPage,
                    searchText,
                    sortFields,
                }}
            />
        </>
    );
};

export default VLNRelationshipContractsDashboard;
