import { Box, IconButton, Typography } from '@mui/material';
import React from 'react';
import { useGlossaryInformation } from '../Hooks/useHelpInformation';

interface HelpGlossaryProps {
    setViewGlossary: React.Dispatch<React.SetStateAction<boolean>>;
    setSearchText: React.Dispatch<React.SetStateAction<string>>;
}

const HelpGlossary = ({
    setSearchText,
    setViewGlossary,
}: HelpGlossaryProps) => {
    const { glossaryData } = useGlossaryInformation();

    return (
        <Box className="pr--20 pb--20 pl--20" overflow={'scroll'}>
            <Box className="flex flex--aic">
                <IconButton
                    style={{ padding: 0 }}
                    color="primary"
                    onClick={() => {
                        setViewGlossary(false);
                        setSearchText('');
                    }}
                >
                    <i className="bi bi-arrow-left"></i>
                </IconButton>
                <Typography
                    marginLeft={2}
                    variant={'h4'}
                    fontWeight={500}
                    // fontSize={20}
                >
                    Glossary
                </Typography>
            </Box>
            <Box marginTop={2}>
                {glossaryData
                    .sort((a, b) => a.title.localeCompare(b.title))
                    .map(({ title, subtext }, i) => (
                        <Box key={i}>
                            <Typography
                                variant={'h4'}
                                fontWeight={600}
                                fontSize={18}
                                color={'text.secondary'}
                                marginBottom={0.5}
                            >
                                {title}
                            </Typography>
                            <Typography marginBottom={2}>{subtext}</Typography>
                        </Box>
                    ))}
            </Box>
        </Box>
    );
};

export default HelpGlossary;
