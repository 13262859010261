import { FormGroup } from '@mui/material';
import { Dispatch, Fragment, SetStateAction } from 'react';
import { ICustomTemplateList } from '../../../../../Global/Interfaces/CustomTemplate.interface';
import { ICurrencyDetails } from '../../../../../Models/Currency.model';
import { asModuleValuesMap } from '../../../Constants/AdminSettingsConstants';
import {
    IAdminCustomFieldsAndChecklist,
    IAdminSettingModule,
    IASSettings,
} from '../../../Interfaces/AdminSettingsInterface';
import { Spacer } from '../../../Pages/Settings/AdminSettingsHomePage';
import ModuleSetting from '../ModuleSetting';

interface IEventSettings {
    settings: IASSettings[];
    currencies: ICurrencyDetails[];
    setModuleValues: Dispatch<SetStateAction<IAdminSettingModule>>;
    customFieldsAndChecklist: {
        customFields: ICustomTemplateList[];
        checklist: ICustomTemplateList[];
    };
    setAdminTemplateSettings: Dispatch<
        SetStateAction<IAdminCustomFieldsAndChecklist>
    >;
    selectedEntity: string;
}

export const getDuplicateNames = (names: string[]): string[] => {
    const namesList = names.map((item) => item);
    return namesList.filter((item, index) => namesList.indexOf(item) !== index);
};

const EventSettings = ({
    settings,
    currencies,
    setModuleValues,
    customFieldsAndChecklist,
    setAdminTemplateSettings,
    selectedEntity,
}: IEventSettings) => {
    return (
        <>
            <FormGroup>
                {settings.map((settingItem, index) => {
                    return settingItem.option in asModuleValuesMap &&
                        !asModuleValuesMap[settingItem.option].hidden ? (
                        <Fragment key={settingItem.option}>
                            <ModuleSetting
                                setting={settingItem}
                                currencies={currencies}
                                setModuleValues={setModuleValues}
                                childIndex={index}
                            />
                            {settings.length - 1 !== index && <Spacer />}
                        </Fragment>
                    ) : (
                        <Fragment key={settingItem.option}></Fragment>
                    );
                })}
            </FormGroup>
            {/* <CustomFieldsDrawer
                moduleName={ASModule.EVENT}
                sectionTitle="Custom Fields for payment and delivery terms"
                customFields={customFieldsAndChecklist.customFields[0]}
                customFieldIndexToUpdate={0}
                setAdminTemplateSettings={setAdminTemplateSettings}
                defaultTemplateName={
                    custom_field_backend_section_names.payment_and_delivery_terms_template_name
                }
                maximumAllowedCustomFields="unlimited"
            />
            <Spacer />

            <CustomFieldsDrawer
                moduleName={ASModule.EVENT}
                sectionTitle="Custom Fields for additional information"
                customFields={customFieldsAndChecklist.customFields[1]}
                customFieldIndexToUpdate={1}
                setAdminTemplateSettings={setAdminTemplateSettings}
                defaultTemplateName={
                    custom_field_backend_section_names.additional_information_template_name
                }
                maximumAllowedCustomFields="unlimited"
            /> */}
        </>
    );
};

export default EventSettings;
