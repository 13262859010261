import { functionIsSupported } from './supportedFunctions';
import {
    ErrorType,
    TokenType,
    operatorAllowedAfter,
    operatorAllowedAfterComma,
    type Token,
    type ValidationError,
} from './types';

const operatorRequiredMap: Partial<Record<TokenType, ErrorType>> = {
    [TokenType.Number]: ErrorType.OperatorRequiredBeforeNumber,
    [TokenType.FunctionName]: ErrorType.OperatorRequiredBeforeFunction,
    [TokenType.QuoteStart]: ErrorType.OperatorRequiredBeforeQuote,
    [TokenType.DoubleQuoteStart]: ErrorType.OperatorRequiredBeforeQuote,
};

const startToEndMap: Partial<Record<TokenType, TokenType>> = {
    [TokenType.QuoteEnd]: TokenType.QuoteStart,
    [TokenType.DoubleQuoteEnd]: TokenType.DoubleQuoteStart,
};

const valueAllowedAfter = [
    TokenType.Operator,
    TokenType.Comma,
    TokenType.BracketStart,
];

const unclosedErrorMap: Partial<Record<TokenType, ErrorType>> = {
    [TokenType.QuoteStart]: ErrorType.UnclosedQuote,
    [TokenType.DoubleQuoteStart]: ErrorType.UnclosedDoubleQuote,
    [TokenType.FunctionName]: ErrorType.UnclosedBracket,
    [TokenType.Group]: ErrorType.UnclosedBracket,
    [TokenType.ReferenceName]: ErrorType.UnclosedReferenceBracket,
};

const noOfTokenValueInsideAFunction: { [key: string]: number } = {
    date: 1,
    dateTime: 2,
    months: 1,
    weeks: 1,
    days: 1,
    hours: 1,
    minutes: 1,
    seconds: 1,
};

export const DateRegex = '^\\d{2}/\\d{2}/\\d{4}$';
// export const DateTimeRegex = '^\\d{2}/\\d{2}/\\d{4} d{2}:d{2}:d{2}$';
export const DateTimeRegex = '^\\d{2}/\\d{2}/\\d{4} \\d{2}:\\d{2}:\\d{2}$'; // this accepts multi params
const RegexForValidParameters: { [key: string]: string } = {
    date: DateRegex,
    dateTime: DateTimeRegex,
    months: `^\\d+$`,
    weeks: `^\\d+$`,
    days: `^\\d+$`,
    hours: `^\\d+$`,
    minutes: `^\\d+$`,
    seconds: `^\\d+$`,
};

export function getValidationErrors(tokens: Token[], supportedRefs?: string[]) {
    const errors: ValidationError[] = [];
    const unclosedTokens: {
        token: Token;
        tokenIndex: number;
        type: TokenType;
    }[] = [];
    const supportedRefsLowerCase = supportedRefs?.map((ref) =>
        ref.toLowerCase()
    );

    let functionLevel = 0;
    let LastFunctionName: string[] = [];
    let FunctionNamesParametersCount: { [key: string]: number } = {};
    let prev: Token | null = null;
    let prevIndex = 0;
    for (let tokenIndex = 0; tokenIndex < tokens.length; tokenIndex++) {
        const addError = (errorType: ErrorType) =>
            errors.push({ token, tokenIndex, errorType });
        const token = tokens[tokenIndex];

        if (token.type === TokenType.Operator && !'+-'.includes(token.value)) {
            if (!prev || !operatorAllowedAfter.includes(prev.type)) {
                addError(ErrorType.UnexpectedOperator);
            }
        }

        if (
            operatorRequiredMap[token.type] &&
            prev &&
            !valueAllowedAfter.includes(prev.type)
        ) {
            addError(operatorRequiredMap[token.type] as ErrorType);
        }

        if (
            token.type === TokenType.FunctionName &&
            !functionIsSupported(token.value)
        ) {
            addError(ErrorType.InvalidFunction);
        }
        if (
            token.type === TokenType.FunctionName &&
            functionIsSupported(token.value)
        ) {
            LastFunctionName.push(
                token.value.toLowerCase() + '-' + token.position
            );
            FunctionNamesParametersCount[
                token.value.toLowerCase() + '-' + token.position
            ] = 0;
        }

        if (LastFunctionName.length > 0 && token.type === TokenType.Number) {
            const LastFunctionNameValue =
                LastFunctionName[LastFunctionName.length - 1];
            FunctionNamesParametersCount[LastFunctionNameValue]++;
            const temp = LastFunctionNameValue.split('-')[0].toLowerCase();
            if (
                temp in RegexForValidParameters &&
                !new RegExp(RegexForValidParameters[temp]).test(
                    `${token.value}`
                )
            ) {
                addError(ErrorType.InvalidValueParameters);
            }
        }

        if (LastFunctionName.length > 0 && token.type === TokenType.String) {
            const LastFunctionNameValue =
                LastFunctionName[LastFunctionName.length - 1];
            const temp = LastFunctionNameValue.split('-')[0].toLowerCase();

            if (
                temp in RegexForValidParameters &&
                !new RegExp(RegexForValidParameters[temp]).test(token.value)
            ) {
                addError(ErrorType.InvalidValueParameters);
            }
        }
        if (
            LastFunctionName.length > 0 &&
            token.type === TokenType.ReferenceBracketStart
        ) {
            const LastFunctionNameValue =
                LastFunctionName[LastFunctionName.length - 1];
            FunctionNamesParametersCount[LastFunctionNameValue]++;
        }

        if (
            [TokenType.QuoteStart, TokenType.DoubleQuoteStart].includes(
                token.type
            )
        ) {
            if (LastFunctionName.length > 0) {
                const LastFunctionNameValue =
                    LastFunctionName[LastFunctionName.length - 1];
                FunctionNamesParametersCount[LastFunctionNameValue]++;
            }
            unclosedTokens.push({ token, tokenIndex, type: token.type });
        }

        if (startToEndMap[token.type]) {
            if (
                unclosedTokens.length &&
                unclosedTokens[unclosedTokens.length - 1].token.type ===
                    startToEndMap[token.type]
            ) {
                unclosedTokens.pop();
            }
        }

        if (token.type === TokenType.Comma) {
            if (
                functionLevel <= 0 ||
                !prev ||
                !operatorAllowedAfterComma.includes(prev.type)
            ) {
                addError(ErrorType.UnexpectedComma);
            }
        }

        if (token.type === TokenType.Error) {
            addError(ErrorType.InvalidCharacter);
        }

        if (token.type === TokenType.BracketStart) {
            unclosedTokens.push({
                token,
                tokenIndex,
                type:
                    prev?.type === TokenType.FunctionName
                        ? TokenType.FunctionName
                        : TokenType.Group,
            });
            if (prev?.type === TokenType.FunctionName) {
                functionLevel++;
            } else if (prev && !valueAllowedAfter.includes(prev.type)) {
                addError(ErrorType.OperatorRequiredBeforeBracket);
            }
        }

        if (token.type === TokenType.BracketEnd) {
            const LastFunctionNameValue =
                LastFunctionName[LastFunctionName.length - 1];
            const LastFunctionNameValueParameterCount =
                FunctionNamesParametersCount[LastFunctionNameValue];

            let temp;
            if (LastFunctionNameValue)
                temp = LastFunctionNameValue.split('-')[0];

            if (
                temp &&
                noOfTokenValueInsideAFunction[temp] &&
                LastFunctionNameValueParameterCount !==
                    noOfTokenValueInsideAFunction[temp]
            ) {
                addError(ErrorType.InvalidNumberOfParameters);
            }

            if (
                unclosedTokens.length &&
                unclosedTokens[unclosedTokens.length - 1].type ===
                    TokenType.FunctionName
            ) {
                functionLevel--;
                unclosedTokens.pop();

                if (
                    !prev ||
                    (!operatorAllowedAfter.includes(prev.type) &&
                        prev.type !== TokenType.BracketStart &&
                        prev.type !== TokenType.Boolean)
                ) {
                    addError(ErrorType.UnexpectedBracket);
                }
            } else if (
                unclosedTokens.length &&
                unclosedTokens[unclosedTokens.length - 1].type ===
                    TokenType.Group
            ) {
                unclosedTokens.pop();
                if (
                    !prev ||
                    (!operatorAllowedAfter.includes(prev.type) &&
                        prev.type !== TokenType.BracketStart)
                ) {
                    addError(ErrorType.UnexpectedBracket);
                }
            } else {
                addError(ErrorType.UnexpectedBracket);
            }
        }

        if (token.type === TokenType.ReferenceBracketStart) {
            unclosedTokens.push({
                token,
                tokenIndex,
                type: TokenType.ReferenceName,
            });

            if (prev && !valueAllowedAfter.includes(prev.type)) {
                addError(ErrorType.OperatorRequiredBeforeReference);
            }
        }

        if (
            token.type === TokenType.ReferenceName &&
            token.value &&
            supportedRefsLowerCase &&
            !supportedRefsLowerCase.includes(token.value.toLowerCase())
        ) {
            addError(ErrorType.UnsupportedReferenceName);
        }

        if (token.type === TokenType.ReferenceBracketEnd) {
            if (
                unclosedTokens.length &&
                unclosedTokens[unclosedTokens.length - 1].type ===
                    TokenType.ReferenceName
            ) {
                unclosedTokens.pop();
                if (!prev || prev.type !== TokenType.ReferenceName) {
                    addError(ErrorType.ReferenceNameRequiredInBrackets);
                }
            } else {
                addError(ErrorType.UnexpectedReferenceBracket);
            }
        }

        if (token.type !== TokenType.Whitespace) {
            prev = token;
            prevIndex = tokenIndex;
        }
    }

    if (prev?.type === TokenType.Operator) {
        errors.push({
            token: prev,
            tokenIndex: prevIndex,
            errorType: ErrorType.ValueRequiredAfterOperator,
        });
    }

    unclosedTokens.forEach(({ token, tokenIndex, type }) => {
        if (unclosedErrorMap[type]) {
            errors.push({
                token,
                tokenIndex,
                errorType: unclosedErrorMap[type] as ErrorType,
            });
        }
    });
    return errors;
}

export function getTokenDependenciesDeep(
    tokensByReferences: Record<string, Token[]>
) {
    const dependencyMap: Record<string, string[]> = {};
    const definedReferences: Record<string, boolean> = {};
    Object.entries(tokensByReferences).forEach(([referenceName, tokens]) => {
        referenceName = referenceName.toLowerCase();
        definedReferences[referenceName] = true;
        tokens.forEach((token) => {
            if (token.type === TokenType.ReferenceName) {
                if (!dependencyMap[referenceName]) {
                    dependencyMap[referenceName] = [];
                }
                dependencyMap[referenceName].push(token.value.toLowerCase());
            }
        });
    });
    const getAllReferences = (referenceName: string) => {
        const dependencies: Record<string, boolean> = {};
        const processedReferences: Record<string, boolean> = {};
        const run = (referenceName: string) => {
            if (
                dependencyMap[referenceName] &&
                !processedReferences[referenceName]
            ) {
                processedReferences[referenceName] = true;
                dependencyMap[referenceName].forEach((dependencyName) => {
                    dependencies[dependencyName] = true;
                    run(dependencyName);
                });
            }
        };
        run(referenceName);
        return dependencies;
    };
    return Object.keys(tokensByReferences).reduce(
        (out: Record<string, string[]>, referenceName) => {
            referenceName = referenceName.toLowerCase();
            out[referenceName] = Object.keys(getAllReferences(referenceName))
                .filter((key) => definedReferences[key])
                .sort((key1, key2) => key1.localeCompare(key2));
            return out;
        },
        {}
    );
}

export function getCircularValidationErrors(
    referenceName: string,
    tokensByReferences: Record<string, Token[]>
) {
    const tokens = tokensByReferences[referenceName] || [];
    referenceName = referenceName.toLowerCase();
    const errors: ValidationError[] = [];
    const dependenciesByReferences =
        getTokenDependenciesDeep(tokensByReferences);
    for (let tokenIndex = 0; tokenIndex < tokens.length; tokenIndex++) {
        const token = tokens[tokenIndex];
        if (token.type === TokenType.ReferenceName) {
            const tokenReferenceName = token.value.toLowerCase();
            if (tokenReferenceName === referenceName) {
                errors.push({
                    token,
                    tokenIndex,
                    errorType: ErrorType.CircularReferenceToItself,
                });
            } else if (
                dependenciesByReferences[tokenReferenceName]?.includes(
                    referenceName
                )
            ) {
                errors.push({
                    token,
                    tokenIndex,
                    errorType: ErrorType.CircularReference,
                });
            }
        }
    }
    return errors;
}
