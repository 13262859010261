import React from 'react';
import { FWInput } from '../../../../Common/FWInput';
import VLNRelationshipNotesListItem from './VLNRelationshipNotesListItem';
import { Box } from '@mui/system';
import { customScrollbar } from '../../../../Common/Utils/UIUtils';
import { IconButton, Typography } from '@mui/material';
import { IVLNNote } from '../../../Interfaces/VendorLNInterfaces';
import { getTextFromHTMLString } from '../../Helpers/getTextFromHTMLString';

const VLNRelationshipNotesList = ({
    VLNNotes,
    onAddNote,
    onNoteSelect,
    selectedNoteIndex,
}: {
    VLNNotes: IVLNNote[];
    onAddNote: () => void;
    onNoteSelect: (index: number) => void;
    selectedNoteIndex: number | null;
}) => {
    const [searchText, setSearchText] = React.useState('');

    return (
        <>
            <Box className={'flex flex--aic'} padding={1}>
                <FWInput
                    placeholder="Search notes"
                    iconStart={<i className="bi bi-bi-search" />}
                    onChange={(e) => setSearchText(e.target.value)}
                />
                &nbsp;
                <IconButton color={'primary'} onClick={onAddNote}>
                    <i className="bi bi-plus-circle" />
                </IconButton>
            </Box>
            <Box
                sx={{ overflowY: 'scroll', ...customScrollbar }}
                padding={1}
                height={'calc(100vh - 410px)'}
                position={'relative'}
            >
                {VLNNotes.length > 0 ? (
                    VLNNotes.filter(
                        (data) =>
                            data.title
                                .trim()
                                .toLowerCase()
                                .includes(searchText.trim().toLowerCase()) ||
                            getTextFromHTMLString(data.notes)
                                ?.trim()
                                .toLowerCase()
                                .includes(searchText.trim().toLowerCase())
                    ).map((data, index) => (
                        <VLNRelationshipNotesListItem
                            key={index}
                            onNoteSelect={() => {
                                onNoteSelect(index);
                            }}
                            isSelected={selectedNoteIndex === index}
                            noteData={data}
                        />
                    ))
                ) : (
                    <Typography
                        color={'text.secondary'}
                        textAlign={'center'}
                        fontSize={13}
                    >
                        No notes found
                    </Typography>
                )}
            </Box>
        </>
    );
};

export default VLNRelationshipNotesList;
