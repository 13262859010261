import { cloneDeep } from 'lodash';
import {
    AllocationTypeEnum,
    CostTypeEnum,
    IAdditionalCostsWithValue,
} from '../../AdditionalCost/models/AdditionalCost.model';
import {
    calculateAdditionalCostForFormula,
    calculateAdditionalCostForFormulaCustomFields,
    calculateAdditionalCostInterface,
} from '../../AdditionalCost/shared/AdditionalCostAutofill';
import { IGetFormulaResponse } from '../../Formula/Models/formula.model';
import { IEventApproval } from '../../Models/RFQSummary.model';
import {
    AdditionalFinalCostingDataInterface,
    CostingSheetStatusEnum,
    IQuoteCustomField,
    IQUoteItemDeliverySchedule,
    IQUoteItemDeliveryScheduleBomItem,
    IQUoteItemDeliverySchedulePayload,
    IQuoteItemUpdatePayload,
} from '../Interfaces/CostingInterfaces.interface.';
import {
    IQuoteApprover,
    QuoteApproverStatus,
} from '../Interfaces/QuoteApprovals.interface';
import { IQuoteSubBom } from '../Interfaces/QuoteBom.interface';
import { ICostingCalculatorApiResponse } from '../Services/CostingApisCalls.service';
import { calculateTotalForAnItem } from './helperFunctions';
import { convertCostingTemplateCFToPayload } from './costingTemplateHelper';
import {
    CostingStandardSections,
    ICostingTemplate,
} from '../Interfaces/CostingTemplate.interface';
import moment from 'moment';

export const getProjectStatusTextAndColor = ({
    status,
}: {
    status: 'ASSIGNING_USERS' | 'DRAFT' | 'SUBMITTED' | 'REVISED';
}) => {
    switch (status) {
        case 'ASSIGNING_USERS':
            return {
                label: 'Pending',
                color: '',
            };
        case 'REVISED':
            return {
                label: 'Revising',
                color: 'warning.main',
            };
        case 'DRAFT':
            return {
                label: 'Draft',
                color: '',
            };
        case 'SUBMITTED':
            return {
                label: 'Submitted',
                color: 'success.main',
            };
    }
};

export const convertTemplateDetailsToCFPayload = (
    customFields: {
        section_list: {
            name: string;
            fields: IQuoteCustomField[];
        }[];
    },
    costingTemplateDetails: ICostingTemplate
) => {
    let newCustomFields = cloneDeep(customFields);

    costingTemplateDetails.sections.QUOTE_DETAILS.fieldList
        ?.filter((field) => field.fieldType === 'CUSTOM')
        .forEach((field) => {
            const currentFieldIndexInPayload =
                newCustomFields.section_list?.length > 0
                    ? newCustomFields.section_list[0]?.fields?.findIndex(
                          (fieldInPayload) =>
                              fieldInPayload.name === field.field
                      )
                    : -1;

            if (currentFieldIndexInPayload === -1) {
                const fieldInfo = convertCostingTemplateCFToPayload(
                    field,
                    costingTemplateDetails,
                    undefined
                );
                if (newCustomFields.section_list?.length > 0) {
                    newCustomFields.section_list[0].fields.push(fieldInfo);
                } else if (newCustomFields.section_list) {
                    newCustomFields.section_list.push({
                        name: costingTemplateDetails.sections.QUOTE_DETAILS
                            .label,
                        fields: [fieldInfo],
                    });
                } else {
                    newCustomFields = {
                        section_list: [
                            {
                                name: costingTemplateDetails.sections
                                    .QUOTE_DETAILS.label,
                                fields: [fieldInfo],
                            },
                        ],
                    };
                }
            }
        });

    return newCustomFields;
};

export const convertTemplateDetailsToNewCFPayload = (
    customSections: {
        // custom_section_id: string | null;
        start_time: string | null;
        status: 'ASSIGNING_USERS' | 'DRAFT' | 'SUBMITTED' | 'REVISED';
        view_users: string[];
        edit_users: string[];
        assigned_users: string[];
        name: string;
        section_type: 'OTHER' | 'BOM' | 'ITEM';
        custom_fields: IQuoteCustomField[];
    }[],
    costingTemplateDetails: ICostingTemplate
) => {
    let newCustomFields = cloneDeep(customSections);

    Object.keys(costingTemplateDetails.sections).forEach((section) => {
        const sectionDetails =
            costingTemplateDetails.sections[section as CostingStandardSections];

        let sectionIdxInPayload = newCustomFields?.findIndex(
            (sectionPayload) => sectionPayload?.name === sectionDetails?.label
        );
        if (sectionIdxInPayload === -1) {
            newCustomFields.push({
                // custom_section_id: null,
                start_time:
                    section === CostingStandardSections.QUOTE_DETAILS
                        ? moment().toISOString()
                        : null,
                status:
                    section === CostingStandardSections.QUOTE_DETAILS
                        ? 'DRAFT'
                        : 'ASSIGNING_USERS',
                view_users: [],
                edit_users: [],
                assigned_users: [],
                name: sectionDetails.label,
                section_type: sectionDetails.section_type,
                custom_fields: [],
            });
            sectionIdxInPayload = newCustomFields.length - 1;
        }

        if (sectionDetails.section_type === 'OTHER') {
            sectionDetails.fieldList
                ?.filter((field) => field.fieldType === 'CUSTOM')
                .forEach((field) => {
                    const currentFieldIndexInPayload = newCustomFields[
                        sectionIdxInPayload
                    ].custom_fields.findIndex(
                        (fieldInPayload) => fieldInPayload.name === field.field
                    );

                    if (currentFieldIndexInPayload === -1) {
                        const fieldInfo = convertCostingTemplateCFToPayload(
                            field,
                            costingTemplateDetails,
                            undefined
                        );
                        newCustomFields[sectionIdxInPayload].custom_fields.push(
                            fieldInfo
                        );
                    }
                });
        }
    });

    console.log(newCustomFields);

    return newCustomFields;
};

export const getCostPerUnitOfACOQFromUpdatedItem = (
    combinedAdditionalCostValue: AdditionalFinalCostingDataInterface[],
    totalItemQuantity: number
) => {
    let costPerUnitMap: {
        [costName: string]: number;
    } = {};

    for (let cost of combinedAdditionalCostValue) {
        if (
            cost.cost_type === CostTypeEnum.ABSOLUTE_VALUE &&
            cost.allocation_type === AllocationTypeEnum.OVERALL_QUANTITY
        ) {
            costPerUnitMap[cost.cost_name] =
                +cost.cost_value / +totalItemQuantity;
        }
    }

    return costPerUnitMap;
};

export const updateSplittedItemsAdditionalCostUsingCostPerUnit = ({
    costPerUnitMap,
    splittedItem,
    skipIndex,
    currencyCodeAbbreviation,
    calculateFormula = true,
    listOfFormulaForCustomFields,
}: {
    splittedItem: ICostingCalculatorApiResponse[];
    costPerUnitMap: {
        [costName: string]: number;
    };
    skipIndex?: number;
    calculateFormula?: boolean;
    currencyCodeAbbreviation: string;
    listOfFormulaForCustomFields: IGetFormulaResponse[];
}) => {
    let updatedSplitteditems = cloneDeep(splittedItem);

    for (let index = 0; index < splittedItem.length; index++) {
        let itemData = splittedItem[index];

        if (skipIndex !== undefined ? skipIndex !== index : true) {
            for (let cost of itemData.additional_costs) {
                if (
                    cost.cost_type === CostTypeEnum.ABSOLUTE_VALUE &&
                    cost.allocation_type === AllocationTypeEnum.OVERALL_QUANTITY
                ) {
                    cost.cost_value =
                        costPerUnitMap[cost.cost_name] * +itemData.quantity;
                }
            }

            if (calculateFormula === undefined || calculateFormula === true) {
                const additionalCostPostFormulaCalculation =
                    calculateFormulaAdditionalCostForQuoteItemAdditionalCost(
                        itemData.additional_costs,
                        itemData.quantity,
                        itemData.rate,
                        currencyCodeAbbreviation,
                        itemData.custom_sections,
                        itemData.enterprise_item_details.code
                    );

                itemData.additional_costs =
                    additionalCostPostFormulaCalculation;

                const { custom_fields } =
                    calculateAdditionalCostForFormulaCustomFields({
                        item: itemData,
                        listOfFormulaForCustomFields,
                    });

                itemData.custom_fields = custom_fields;
            }
        }
    }

    return updatedSplitteditems;
};

export const combineAdditionalCostValueFromDS = (
    quoteSheetItemDetails: ICostingCalculatorApiResponse[]
) => {
    let additionalCostMap: {
        [costName: string]: {
            [index: number]: number;
            totalValue: number;
        };
    } = {};

    quoteSheetItemDetails.forEach((item, itemIndex) => {
        for (let cost of item.additional_costs) {
            if (
                cost.cost_type === CostTypeEnum.ABSOLUTE_VALUE &&
                cost.allocation_type === AllocationTypeEnum.OVERALL_QUANTITY
            ) {
                if (additionalCostMap[cost.cost_name] === undefined) {
                    additionalCostMap[cost.cost_name] = {
                        totalValue: 0,
                    };
                }

                additionalCostMap[cost.cost_name] = {
                    ...additionalCostMap[cost.cost_name],
                    [itemIndex]: +cost.cost_value,
                    totalValue:
                        additionalCostMap[cost.cost_name].totalValue +
                        +cost.cost_value,
                };
            }
        }
    });

    const updatedAdditionalCostValue = cloneDeep(
        quoteSheetItemDetails[0].additional_costs
    );

    updatedAdditionalCostValue.forEach((cost) => {
        if (
            cost.cost_type === CostTypeEnum.ABSOLUTE_VALUE &&
            cost.allocation_type === AllocationTypeEnum.OVERALL_QUANTITY
        ) {
            cost.cost_value = additionalCostMap[cost.cost_name].totalValue;
        }

        return cost;
    });

    return updatedAdditionalCostValue;
};

export const convertIQUoteItemDeliveryScheduleToIQUoteItemDeliverySchedulePayload =
    (itemDeliverySchedule: IQUoteItemDeliverySchedule[]) => {
        const formattedDS: IQUoteItemDeliverySchedulePayload[] =
            itemDeliverySchedule.map((dsItem) => ({
                bom_item_id: dsItem.bom_item?.entry_id ?? null,
                customer_entity_id: dsItem.customer_entity,
                delivery_schedule_item_id: dsItem.delivery_schedule_item_id,
                parent_delivery_schedule_item_id: null,
                cost_centre_id: dsItem.cost_centre_id,
                general_ledger_id: dsItem.general_ledger_id,
                project_id: dsItem.project_id,
                quantity: dsItem.quantity,
                delivery_date: dsItem.delivery_date || null,
                requisition_item_id: null,
            }));

        return formattedDS;
    };

export const convertICostingCalculatorApiResponseToIQuoteItemUpdatePayload = (
    itemData: ICostingCalculatorApiResponse
) => {
    return {
        delivery_schedule:
            convertIQUoteItemDeliveryScheduleToIQUoteItemDeliverySchedulePayload(
                itemData.delivery_schedule
            ),
        conversion_rate: itemData.conversion_rate,
        enterprise_bom_id: null,
        custom_fields: itemData.custom_fields,
        custom_fields_negotiate: itemData.custom_fields_negotiate,
        enterprise_item_id:
            itemData.enterprise_item_details.enterprise_item_id ?? null,
        lead_time: +(itemData.procurement_information?.lead_time ?? 0),
        lead_time_period: itemData.procurement_information?.lead_time_period,
        measurement_unit_id:
            itemData.enterprise_item_details.measurement_units
                ?.item_measurement_units[0]?.measurement_unit_id ?? null,
        notes: itemData.notes,
        projects: itemData.projects?.map((project) => project.project_id),
        quantity: +itemData.quantity,
        rate: +itemData.rate,
        rfq_bid_item_id:
            itemData?.rfq_bid_item_details?.rfq_bid_item_id ?? null,
        rfq_event_item_id: itemData?.rfq_event_item_id ?? null,
        costing_sheet_item_id: itemData.costing_sheet_item_id,
        vendor_currency_id: itemData?.vendor_currency_details?.entry_id ?? null,
        vendor_entity_id: itemData.vendor_entity_details?.entity_id ?? null,
        vendor_rate: itemData.vendor_rate,
        additional_costs: itemData.additional_costs.map((cost, index) => {
            if (cost.sequence === null || cost.sequence === undefined) {
                cost.sequence = index + 1;
            }

            return cost;
        }),
        custom_sections: itemData.custom_sections.map((section) => ({
            ...section,
            last_modified_time: null,
            assigned_user_ids: [],
            edit_user_ids: [],
            view_user_ids: [],
        })),
    } as IQuoteItemUpdatePayload;
};

export const splitQuoteSheetItemFromDS = (
    item: ICostingCalculatorApiResponse
) => {
    const splittedItemList: ICostingCalculatorApiResponse[] = [];

    item.delivery_schedule.forEach((dsItem) => {
        const currentBomItemIdIndex = splittedItemList.findIndex(
            (splitedDSItem) =>
                Boolean(dsItem.bom_item?.entry_id)
                    ? splitedDSItem.delivery_schedule.some(
                          (ds) =>
                              ds.bom_item?.entry_id ===
                              dsItem.bom_item?.entry_id
                      )
                    : !splitedDSItem.delivery_schedule.some(
                          (ds) => ds.bom_item?.entry_id
                      )
        );

        if (currentBomItemIdIndex === -1) {
            splittedItemList.push({
                ...item,
                quantity: dsItem.quantity?.toString(),
                delivery_schedule: [dsItem],
            });
        } else {
            splittedItemList[currentBomItemIdIndex].quantity = (
                +splittedItemList[currentBomItemIdIndex].quantity +
                dsItem.quantity
            ).toString();
            splittedItemList[currentBomItemIdIndex].delivery_schedule.push(
                dsItem
            );
        }
    });

    /*
     * if item has no DS or item does not have any co-relation to a BOM, then just append the item in the list. There won't be any splitting required.
     */

    if (
        item.delivery_schedule.length === 0 ||
        !item.delivery_schedule.some((ds) => Boolean(ds.bom_item?.entry_id))
    ) {
        splittedItemList.push(item);
    }
    return splittedItemList;
};

export const combineMultipleQuoteSheetItemsWithoutCalcultion = (
    splittedQuoteSheetitems: ICostingCalculatorApiResponse[],
    combineAdditionalCosts: boolean = false
) => {
    const itemForCalculation = cloneDeep(splittedQuoteSheetitems[0]);
    const itemDeliverySchedule: IQUoteItemDeliverySchedule[] = [];

    let parentBomDataForEachDSItem: {
        bom_code: string;
        entry_id: string;
    }[] = [];

    let totalItemQuantity = 0;

    for (let item of splittedQuoteSheetitems) {
        totalItemQuantity += +item.quantity;
        for (let dsItem of item.delivery_schedule) {
            const parentBomCode =
                dsItem.bom_item?.parent_sub_bom_item === null
                    ? dsItem.bom_item.enterprise_bom?.bom_code
                    : dsItem.bom_item?.parent_sub_bom_item?.sub_bom?.bom_code;

            const parentBomEntryid =
                dsItem.bom_item?.parent_sub_bom_item === null
                    ? dsItem.bom_item.enterprise_bom?.entry_id
                    : dsItem.bom_item?.parent_sub_bom_item?.entry_id;

            if (parentBomCode && parentBomEntryid) {
                parentBomDataForEachDSItem.push({
                    bom_code: parentBomCode,
                    entry_id: parentBomEntryid,
                });
            }
            itemDeliverySchedule.push(dsItem);
        }
    }

    itemForCalculation.quantity = totalItemQuantity?.toString();
    itemForCalculation.delivery_schedule = itemDeliverySchedule;

    if (combineAdditionalCosts) {
        const combinedAdditionalCostValue = combineAdditionalCostValueFromDS(
            splittedQuoteSheetitems
        );

        itemForCalculation.additional_costs = combinedAdditionalCostValue;
    }
    return { itemForCalculation, parentBomDataForEachDSItem };
};

export const combineMultipleQuoteSheetItem = (
    splittedQuoteSheetitems: ICostingCalculatorApiResponse[],
    currencyCodeAbbreviation: string,
    listOfFormulaForCustomFields: IGetFormulaResponse[],
    performCalculation: boolean = true
) => {
    const itemForCalculation = cloneDeep(splittedQuoteSheetitems[0]);

    const itemDeliverySchedule: IQUoteItemDeliverySchedule[] = [];
    let totalItemQuantity = 0;

    for (let item of splittedQuoteSheetitems) {
        totalItemQuantity += +item.quantity;

        itemDeliverySchedule.push(...item.delivery_schedule);
    }

    const additionalCostValuePreFormula = combineAdditionalCostValueFromDS(
        splittedQuoteSheetitems
    );

    const postAdditionalCostFormulaCalculation =
        calculateFormulaAdditionalCostForQuoteItemAdditionalCost(
            additionalCostValuePreFormula,
            totalItemQuantity?.toString(),
            itemForCalculation.rate?.toString(),
            currencyCodeAbbreviation,
            itemForCalculation.custom_sections,
            itemForCalculation.enterprise_item_details.code
        );

    itemForCalculation.delivery_schedule = itemDeliverySchedule;
    itemForCalculation.additional_costs = postAdditionalCostFormulaCalculation;
    itemForCalculation.quantity = totalItemQuantity?.toString();

    const customFieldFormulaCalculation =
        calculateAdditionalCostForFormulaCustomFields({
            item: {
                ...itemForCalculation,
                quantity: totalItemQuantity?.toString(),
            },
            listOfFormulaForCustomFields: listOfFormulaForCustomFields,
        });
    itemForCalculation.custom_fields =
        customFieldFormulaCalculation.custom_fields;

    return itemForCalculation;
};

export const convertQuoteItemDetailToPayload = (
    quoteSheetItemDetails: ICostingCalculatorApiResponse[],
    currencyCodeAbbreviation: string,
    listOfFormulaForCustomFields: IGetFormulaResponse[]
) => {
    const combinedItem = combineMultipleQuoteSheetItem(
        quoteSheetItemDetails,
        currencyCodeAbbreviation,
        listOfFormulaForCustomFields
    );

    const payload =
        convertICostingCalculatorApiResponseToIQuoteItemUpdatePayload(
            combinedItem
        );

    return payload;
};

export const convertAdditionalCostStructureForTotalCalculation = (
    additionalCost: AdditionalFinalCostingDataInterface
) => {
    let convertedCost: IAdditionalCostsWithValue = {
        additional_cost_id: additionalCost.additional_cost_id,
        allocationType: additionalCost.allocation_type,
        cost_source: additionalCost.cost_source,
        costName: additionalCost.cost_name,
        costType: additionalCost.cost_type,
        value: additionalCost.cost_value,
        source_currency_currency_code_abbreviation:
            additionalCost.source_currency_currency_code_abbreviation,
        source_currency_currency_code_symbol:
            additionalCost.source_currency_currency_code_abbreviation,
        source_currency_id: additionalCost.source_currency_id,
        source_value: additionalCost.source_value,
        formula: additionalCost.formula ?? null,
        is_calculated: additionalCost.is_calculated,
        conversion_rate: additionalCost.conversion_rate,
    };

    return convertedCost;
};

export const cleanUpCostValue = (costValue: string | number) => {
    return costValue === -1 ? 0 : costValue;
};

export const convertAdditionalCostStructureForFormula = (
    additional_costs: AdditionalFinalCostingDataInterface[]
) => {
    const convertedAdditionalCosts: calculateAdditionalCostInterface[] =
        additional_costs.map((cost) => ({
            additional_cost_id: cost.additional_cost_id,
            additional_cost_linkage_id: cost.additional_cost_linkage_id ?? null,
            allocationType: cost.allocation_type,
            sequence: cost.sequence,
            costName: cost.cost_name,
            costSource: cost.cost_source,
            costType: cost.cost_type,
            costValue: cost.cost_value,
            is_calculated: cost.is_calculated,
            conversionRate: cost.conversion_rate,
            formula: cost.formula,
            source_currency_id: cost.source_currency_id,
            sourceCurrencyCodeAbbreviation:
                cost.source_currency_currency_code_abbreviation,
            sourceCurrencySymbol: cost.source_currency_currency_symbol,
            sourceValue: cost.source_value ?? 0,
        }));

    return convertedAdditionalCosts;
};

export const revertAdditionalCostStructureForFormula = (
    calculatedAdditionalCosts: calculateAdditionalCostInterface[]
) => {
    const convertedAdditionalCosts: AdditionalFinalCostingDataInterface[] =
        calculatedAdditionalCosts.map((cost, index) => ({
            additional_cost_id: cost.additional_cost_id,
            additional_cost_linkage_id: cost.additional_cost_linkage_id ?? null,
            allocation_type: cost.allocationType,
            sequence: cost.sequence === null ? index + 1 : cost.sequence,
            cost_name: cost.costName,
            cost_source: cost.costSource,
            cost_type: cost.costType,
            cost_value: isNaN(+cost.costValue)
                ? 0
                : +cost.costValue < 0
                ? 0
                : +cost.costValue,
            is_calculated: cost.is_calculated,
            conversion_rate: cost.conversionRate,
            formula: cost.formula,
            source_currency_currency_code_abbreviation:
                cost.sourceCurrencyCodeAbbreviation,
            source_currency_currency_symbol: cost.sourceCurrencySymbol,
            source_currency_id: cost.source_currency_id,
            source_value: +(cost.sourceValue ?? 0),
        }));

    return convertedAdditionalCosts;
};

export const formatCustomFieldsForFormula = (
    custom_sections: {
        name: string;
        section_type: 'ITEM';
        start_time: string | null;
        custom_fields: IQuoteCustomField[];
    }[]
) => {
    return custom_sections.flatMap((section) =>
        section.custom_fields
            .map((field) => {
                return {
                    name: field.name,
                    value: field.value as string,
                    type: field.type as any,
                };
            })
            .filter((field) => field.name !== '')
    );
};

export const calculateFormulaAdditionalCostForQuoteItem = (
    item: ICostingCalculatorApiResponse,
    currencyAbbreviation: string,
    combinedQuantity: number
) => {
    let updatedItemValueWithFormula = cloneDeep(item);

    // const currencyAbbreviation =
    //     store.getState().QuoteCalculatorStore.quoteCurrencyDetails
    //         .currency_code_abbreviation;

    let calculatedAdditionalCosts = calculateAdditionalCostForFormula({
        additionalCosts: convertAdditionalCostStructureForFormula(
            updatedItemValueWithFormula.additional_costs
        ),
        custom_fields: formatCustomFieldsForFormula(
            updatedItemValueWithFormula.custom_sections
        ),
        quantity: +combinedQuantity,
        targetRate: +updatedItemValueWithFormula.rate,
        currencyAbbreviation: currencyAbbreviation,
    });

    updatedItemValueWithFormula = {
        ...updatedItemValueWithFormula,
        additional_costs: revertAdditionalCostStructureForFormula(
            calculatedAdditionalCosts
        ),
    };

    return updatedItemValueWithFormula;
};

export const calculateFormulaAdditionalCostForQuoteItemAdditionalCost = (
    currAdditionalCost: AdditionalFinalCostingDataInterface[],
    quantity: string,
    rate: string,
    currencyAbbreviation: string,
    custom_sections: {
        name: string;
        section_type: 'ITEM';
        start_time: string | null;
        custom_fields: IQuoteCustomField[];
    }[],
    name?: string
) => {
    let newAdditionalCostsWithFormulaValue = cloneDeep(currAdditionalCost);

    let calculatedAdditionalCosts = calculateAdditionalCostForFormula({
        additionalCosts:
            convertAdditionalCostStructureForFormula(currAdditionalCost),
        custom_fields: formatCustomFieldsForFormula(custom_sections),
        quantity: +quantity,
        targetRate: +rate,
        currencyAbbreviation: currencyAbbreviation,
    });

    newAdditionalCostsWithFormulaValue =
        revertAdditionalCostStructureForFormula(calculatedAdditionalCosts);

    return newAdditionalCostsWithFormulaValue;
};

export const calculateQuoteItemTotalAndSubtotal = (
    quoteItemIds: string[],
    quoteBOMIds: string[],
    quoteItemDetails: {
        [quoteItemId: string]: ICostingCalculatorApiResponse;
    },
    quoteAdditionalCosts: AdditionalFinalCostingDataInterface[],
    selectedBomId: string | null
) => {
    const subTotalForItems = quoteItemIds
        .filter((quoteId) =>
            selectedBomId === null ? true : quoteId === selectedBomId
        )
        .reduce((accumulator, currentValue) => {
            const currentItemData = cloneDeep(quoteItemDetails[currentValue]);

            const itemTotal =
                calculateTotalForAnItem(
                    currentItemData.additional_costs.filter(
                        (cost) => cost.is_calculated
                    ),
                    currentItemData.rate,
                    +currentItemData.quantity
                )?.total ?? 0;

            return accumulator + itemTotal;
        }, 0);

    const subTotalForBOM = quoteBOMIds
        .filter((quoteId) =>
            selectedBomId === null ? true : quoteId === selectedBomId
        )
        .reduce((accumulator, currentValue) => {
            const currentItemData = cloneDeep(quoteItemDetails[currentValue]);

            if (currentItemData) {
                const itemTotal =
                    calculateTotalForAnItem(
                        currentItemData.additional_costs.filter(
                            (cost) => cost.is_calculated
                        ),
                        currentItemData.rate,
                        +currentItemData.quantity
                    )?.total ?? 0;

                return accumulator + itemTotal;
            } else {
                return accumulator;
            }
        }, 0);
    const subTotal = subTotalForBOM + subTotalForItems;

    const finalTotal =
        quoteAdditionalCosts.reduce((accumulator, currentValue) => {
            if (currentValue.cost_type === 'PERCENTAGE') {
                return (
                    accumulator + (+currentValue.cost_value / 100) * subTotal
                );
            }
            return accumulator + +currentValue.cost_value;
        }, 0) + subTotal;

    return { subTotal, finalTotal };
};

export const recursiveCalculateTotalOfSubBom = (
    quoteItemDetails: {
        [quoteItemId: string]: ICostingCalculatorApiResponse[];
    },
    enterpriseBomId: string | null,
    selectedEnterpriseBomId: string | null = null,
    enterpriseBomData: {
        [enterpriseBOMIdList: string]: {
            [subBomId: string]: IQuoteSubBom;
            index: any;
            parentBom: IQuoteSubBom;
        };
    }
) => {
    const allCostingSheetItemsForSelectedBOM =
        enterpriseBomId === null || selectedEnterpriseBomId === null
            ? []
            : enterpriseBomData[enterpriseBomId][selectedEnterpriseBomId]
                  .bom_items;

    let total = 0;

    for (let item of allCostingSheetItemsForSelectedBOM) {
        const isCurrentItemRawMaterial = item.bom_items.length === 0;

        if (isCurrentItemRawMaterial) {
            if (item.costing_sheet_item_id) {
                const currentItemData =
                    quoteItemDetails[item.costing_sheet_item_id][
                        item.item_index
                    ];

                const itemTotal = calculateTotalForAnItem(
                    currentItemData.additional_costs.filter(
                        (cost) => cost.is_calculated
                    ),
                    currentItemData.rate ?? 0,
                    +currentItemData.quantity
                ).total;

                total += itemTotal;
            }
        } else {
            // deal with sub Bom here
            const subBomTotal = recursiveCalculateTotalOfSubBom(
                quoteItemDetails,
                enterpriseBomId,
                item.entry_id,
                enterpriseBomData
            );

            total += subBomTotal;
        }
    }

    return total;
};

export const calculateQuoteTotalAndSubtotal = (
    quoteItemIds: string[],
    quoteItemDetails: {
        [quoteItemId: string]: ICostingCalculatorApiResponse[];
    },
    quoteAdditionalCosts: AdditionalFinalCostingDataInterface[],
    currencyCodeAbbreviation: string,
    listOfFormulaForCustomFields: IGetFormulaResponse[],
    enterpriseBomData: {
        [enterpriseBOMIdList: string]: {
            [subBomId: string]: IQuoteSubBom;
            index: any;
            parentBom: IQuoteSubBom;
        };
    },
    enterpriseBomId: string | null,
    selectedEnterpriseBomId: string | null = null
) => {
    // const memo: { [key: string]: number } = {};

    const allCostingSheetItemsForSelectedBOM =
        enterpriseBomId === null || selectedEnterpriseBomId === null
            ? []
            : cloneDeep(enterpriseBomData)[enterpriseBomId]
            ? cloneDeep(enterpriseBomData)[enterpriseBomId][
                  selectedEnterpriseBomId
              ].bom_items
            : [];

    const calculateItemTotal = () => {
        let total = 0;

        for (let item of allCostingSheetItemsForSelectedBOM) {
            const isCurrentItemRawMaterial = item.bom_items.length === 0;
            if (isCurrentItemRawMaterial) {
                if (item.costing_sheet_item_id) {
                    const currentItemData =
                        quoteItemDetails[item.costing_sheet_item_id][
                            item.item_index
                        ];

                    const itemTotal = calculateTotalForAnItem(
                        currentItemData.additional_costs.filter(
                            (cost) => cost.is_calculated
                        ),
                        currentItemData.rate ?? 0,
                        +currentItemData.quantity
                    ).total;

                    total += itemTotal;
                }
            } else {
                const subBomTotal = recursiveCalculateTotalOfSubBom(
                    quoteItemDetails,
                    enterpriseBomId,
                    item.entry_id,
                    cloneDeep(enterpriseBomData)
                );

                total += subBomTotal;

                // deal with sub Bom here
            }
        }

        return total;
    };

    let subTotal = 0;
    for (let i = 0; i < quoteItemIds.length; i++) {}
    subTotal += calculateItemTotal();

    let finalTotal = subTotal;
    for (let i = 0; i < quoteAdditionalCosts.length; i++) {
        const currentValue = quoteAdditionalCosts[i];
        if (currentValue.cost_type === 'PERCENTAGE') {
            finalTotal +=
                (parseFloat(currentValue.cost_value?.toString() ?? '0') / 100) *
                subTotal;
        } else {
            finalTotal += parseFloat(
                currentValue.cost_value?.toString() ?? '0'
            );
        }
    }
    return { subTotal, finalTotal };
};

export const calculateQuoteTotalAndSubtotalForAllItems = (
    quoteItemIds: string[],
    quoteItemDetails: {
        [quoteItemId: string]: ICostingCalculatorApiResponse[];
    },
    quoteAdditionalCosts: AdditionalFinalCostingDataInterface[],
    currencyCodeAbbreviation: string,
    listOfFormulaForCustomFields: IGetFormulaResponse[],
    selectedEnterpriseBomId: string | null = null
) => {
    const memo: { [key: string]: number } = {};

    const calculateItemTotal = (itemId: string) => {
        if (memo[itemId] !== undefined) {
            return memo[itemId];
        }

        const currentItemDataList = quoteItemDetails[itemId].filter((item) =>
            selectedEnterpriseBomId === null
                ? true
                : item.delivery_schedule.some(
                      (ds) =>
                          ds.bom_item?.enterprise_bom?.entry_id ===
                          selectedEnterpriseBomId
                  )
        );

        const combinedItem = combineMultipleQuoteSheetItem(
            currentItemDataList,
            currencyCodeAbbreviation,
            listOfFormulaForCustomFields
        );

        const itemTotal = calculateTotalForAnItem(
            combinedItem.additional_costs.filter((cost) => cost.is_calculated),
            combinedItem.rate ?? 0,
            +combinedItem.quantity
        ).total;

        memo[itemId] = itemTotal;
        return itemTotal;
    };

    let subTotal = 0;
    for (let i = 0; i < quoteItemIds.length; i++) {
        subTotal += calculateItemTotal(quoteItemIds[i]);
    }

    let finalTotal = subTotal;
    for (let i = 0; i < quoteAdditionalCosts.length; i++) {
        const currentValue = quoteAdditionalCosts[i];

        if (currentValue.cost_type === 'PERCENTAGE') {
            finalTotal +=
                (parseFloat(currentValue.cost_value?.toString() ?? '0') / 100) *
                subTotal;
        } else {
            finalTotal += parseFloat(
                currentValue.cost_value?.toString() ?? '0'
            );
        }
    }

    return { subTotal, finalTotal };
};

// export const calculateQuoteTotalAndSubtotal = (
//     quoteItemIds: string[],
//     quoteItemDetails: {
//         [quoteItemId: string]: ICostingCalculatorApiResponse;
//     },
//     quoteAdditionalCosts: AdditionalFinalCostingDataInterface[]
// ) => {
//     const subTotal = quoteItemIds.reduce((accumulator, currentValue) => {
//         const currentItemData = quoteItemDetails[currentValue];

//         return (
//             accumulator +
//             calculateTotalForAnItem(
//                 currentItemData.additional_costs.filter(
//                     (cost) => cost.is_calculated
//                 ),
//                 currentItemData.rate,
//                 +currentItemData.quantity
//             ).total
//         );
//     }, 0);

//     const finalTotal =
//         quoteAdditionalCosts.reduce((accumulator, currentValue) => {
//             if (currentValue.cost_type === 'PERCENTAGE') {
//                 return (
//                     accumulator + (+currentValue.cost_value / 100) * subTotal
//                 );
//             }
//             return accumulator + +currentValue.cost_value;
//         }, 0) + subTotal;

//     // return { subTotal, finalTotal };

//     return { subTotal, finalTotal };
// };

export const getQuoteSheetMode = (
    status: CostingSheetStatusEnum,
    isCurrentUserTopInHeirarchy: boolean
): 'VIEW' | 'EDIT' => {
    switch (status) {
        case CostingSheetStatusEnum.SUBMITTED:
        case CostingSheetStatusEnum.APPROVAL_PENDING:
        case CostingSheetStatusEnum.REJECTED:
        case CostingSheetStatusEnum.CUSTOMER_REJECTED:
        case CostingSheetStatusEnum.REWORK:
            return 'VIEW';
        case CostingSheetStatusEnum.PAUSE_AND_EDIT:
            return isCurrentUserTopInHeirarchy ? 'EDIT' : 'VIEW';
        default:
            return 'EDIT';
    }
};

export const getQuoteSheetStatusTooltip = (
    quoteApprovals: IQuoteApprover[],
    costingStatus: CostingSheetStatusEnum
) => {
    let sheetStatusTooltips: {
        approver: string;
        approver_name: string;
        notes: string;
    }[] = [];

    switch (costingStatus) {
        case CostingSheetStatusEnum.REWORK:
            sheetStatusTooltips = quoteApprovals
                .filter(
                    (approver) =>
                        approver.status === QuoteApproverStatus.PENDING &&
                        Boolean(approver.approver_notes)
                )
                .map((approver) => ({
                    approver: approver.approver,
                    approver_name: approver.approver_name,
                    notes: approver.approver_notes ?? '',
                }));

            break;
        case CostingSheetStatusEnum.REJECTED:
            let rejectedApprover = quoteApprovals.find(
                (approver) =>
                    approver.status === QuoteApproverStatus.REJECTED &&
                    Boolean(approver.approver_notes)
            );

            if (rejectedApprover !== undefined) {
                sheetStatusTooltips = [
                    {
                        approver: rejectedApprover.approver,
                        approver_name: rejectedApprover.approver_name,
                        notes: rejectedApprover.approver_notes ?? '',
                    },
                ];
            }
            break;
        default:
            sheetStatusTooltips = [];
            break;
    }

    return sheetStatusTooltips;
};

export const convertQuoteApproverStructureForPopover = (
    quoteApprovers: IQuoteApprover[]
): IEventApproval[] => {
    return quoteApprovers.map((approver) => ({
        approverId: approver.approver,
        approverNotes: approver.approver_notes ?? '',
        approverUserFullName: approver.approver_name,
        requestorId: approver.requestor,
        requestorNotes: approver.requestor_notes ?? '',
        requestorUserFullName: approver.requestor_name,
        status: approver.status,
    }));
};

export const getSheetTotal = (
    quoteItemIds: string[],
    quoteItemDetails: {
        [quoteItemId: string]: ICostingCalculatorApiResponse[];
    },
    quoteAdditionalCosts: AdditionalFinalCostingDataInterface[],
    currencyCodeAbbreviation: string,
    listOfFormulaForCustomFields: IGetFormulaResponse[],
    selectedEnterpriseBomId: string | null = null,
    enterpriseBomData: {
        [enterpriseBOMIdList: string]: {
            [subBomId: string]: IQuoteSubBom;
            index: any;
            parentBom: IQuoteSubBom;
        };
    },
    bomEntryId: string | null
) => {
    if (bomEntryId) {
        const { finalTotal, subTotal } = calculateQuoteTotalAndSubtotal(
            quoteItemIds,
            quoteItemDetails,
            quoteAdditionalCosts,
            currencyCodeAbbreviation,
            listOfFormulaForCustomFields,
            enterpriseBomData,
            selectedEnterpriseBomId,
            bomEntryId
        );

        return {
            finalTotal,
            subTotal,
        };
    } else {
        let additionalCostFromBom: AdditionalFinalCostingDataInterface[] = [];

        for (let enterpriseBOMSelectedTemp of Object.keys(enterpriseBomData)) {
            for (let key of Object.keys(
                enterpriseBomData[enterpriseBOMSelectedTemp]
            )) {
                if (
                    key !== 'index' &&
                    key !== 'parentBom'
                    //  &&
                    // key !== enterpriseBOMSelectedTemp
                ) {
                    additionalCostFromBom.push(
                        ...enterpriseBomData[enterpriseBOMSelectedTemp][key]
                            .additional_costs
                    );
                }
            }
        }

        const { finalTotal, subTotal } =
            calculateQuoteTotalAndSubtotalForAllItems(
                quoteItemIds,
                quoteItemDetails,
                [...quoteAdditionalCosts, ...additionalCostFromBom],
                currencyCodeAbbreviation,
                listOfFormulaForCustomFields,
                selectedEnterpriseBomId
            );
        return {
            finalTotal,
            subTotal,
        };
    }
};

export const getAllTheParentSubBOmDetails: (
    bomItem: IQUoteItemDeliveryScheduleBomItem | null
) => string[] = (bomItem: IQUoteItemDeliveryScheduleBomItem | null) => {
    if (bomItem && bomItem.parent_sub_bom_item) {
        return [
            bomItem.parent_sub_bom_item.entry_id,
            ...getAllTheParentSubBOmDetails(
                bomItem.parent_sub_bom_item.parent_sub_bom_item
            ),
        ];
    } else if (bomItem) {
        return [bomItem.entry_id];
    }
    return [];
};
