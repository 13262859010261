export const CartItemBlankDeliveryInformation: {
    delivery_schedule_item_id: string | null;
    requisition_item_id: string | null;
    parent_delivery_schedule_item_id: string | null;
    delivery_date: string | null;
    quantity: number | null;
    project_id: string | null;
    cost_centre_id: string | null;
    general_ledger_id: string | null;
}[] = [
    {
        delivery_schedule_item_id: null,
        requisition_item_id: null,
        parent_delivery_schedule_item_id: null,
        delivery_date: null,
        quantity: null,
        project_id: null,
        cost_centre_id: null,
        general_ledger_id: null,
    },
];
