// Description: The below will enable or disable the features in the application. Doen't has to do anything with quotas.
export type TFeatureNames =
    | 'feature-admin-allsettings-enabled'
    | 'feature-admin-eventsettings-enabled'
    | 'feature-event-customattributes-enabled'
    | 'feature-event-customfields-enabled'
    | 'feature-event-module-enabled'
    | 'feature-gr-customchecklists-enabled'
    | 'feature-gr-customfields-enabled'
    | 'feature-admin-grsettings-enabled'
    | 'feature-gr-moduleaccess-enabled'
    | 'feature-invoice-management-enabled'
    | 'feature-po-management-enabled'
    | 'feature-qc-customchecklists-enabled'
    | 'feature-qc-customfields-enabled'
    | 'feature-admin-qcsettings-enabled'
    | 'feature-qc-moduleaccess-enabled'
    | 'feature-event-rfqanalytics-enabled'
    | 'feature-payment-module-enabled'
    | 'feature-invoice-module-enabled';

export type TFeatures = TFeatureNames[];

export enum eQuotaNames {
    'feature-entity-creation-count',
    'feature-event-create-count',
    'feature-event-cloning-count',
    'feature-gr-create-count',
    'feature-payment-create-count',
    'feature-po-direct-count',
    'feature-po-events-count',
    'feature-po-total-count',
    'feature-qc-create-count',
}

export type TQuotaNames =
    | 'feature-entity-creation-count'
    | 'feature-event-create-count'
    | 'feature-event-cloning-count'
    | 'feature-gr-create-count'
    | 'feature-payment-create-count'
    | 'feature-po-direct-count'
    | 'feature-po-events-count'
    | 'feature-po-total-count'
    | 'feature-qc-create-count';

export type TQuota = {
    [key in TQuotaNames]?: {
        max: number;
        used: number;
        remaining: number;
    };
};
