// Used for showing users list
export interface IAddress {
    id?: string | number;
    nickName: string;
    address: string;
    state: string;
    city: string;
    addressId: string;
    zipCode: string;
    entities: IEntitiesDetails[];
    entitiesCount: number;
    country: string;
    addressInstruction: string;
    status: string;
    isPrimary: boolean;
    primaryEntities: string[];
    modifiedDateTime: string;
}
export enum AddressState {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
    ALL = 'ALL',
}

export interface IEntityAddressIdentification {
    entity_identification: {
        identification_id: string;
        identification_name: string;
        identification_value: string;
    };
}

export interface IEntityAddress {
    id?: string;
    entityName: string;
    entityId: string;
    primary: boolean;
    isDefaultPrimary: boolean;
    shipping: boolean;
    public: boolean;
    billing: boolean;
    status: string;
    isNew?: boolean;
    identifications: IEntityAddressIdentification[];
}
export interface IEntitiesDetails {
    addressId: string;
    entityId: string;
    entityName: string;
}

export interface IAddressDetail {
    id?: number;
    addressName: string;
    address1: string;
    address2: string;
    address3: string;
    stateOrTerritory: string;
    address_id: string;
    city: string;
    postalCode: string;
    addressInstruction: string;
    entities: IEntityAddress[] | [];
    country: string;
    status: string;
    address_url?: string;
}

export interface ICountryList {
    name: string;
    countryCode: string;
}

export interface IEntityIdentification {
    entity_id: string;
    identification: IEntityAddressIdentification[];
}
