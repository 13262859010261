import { cloneDeep } from 'lodash';
import { useEffect, useState } from 'react';
import { useGetAdditionalCostFromAdminQuery } from '../../AdditionalCost/Services/additionalCosts.services';
import { HookStateValue, useHookState } from '../../Common/Hooks/StateHook';
import { useGetTemplateByIdQuery } from '../Helpers/templateApiSlice';
import {
    blankRfqTemplate,
    defaultFWTemplateFE,
} from '../Helpers/templateHelpers';
import {
    IRfqTemplate,
    TemplateStandardFieldsEnum,
} from '../Interfaces/TemplateInterface';

export const useRfqTemplate = (
    templateId: string | 'DEFAULT' | null,
    entityId: string,
    filterCostFromAdmin: boolean = false
) => {
    const { hookState, updateHookState } = useHookState(HookStateValue.LOADING);
    const {
        data: templateData,
        isLoading,
        isError,
        error,
    } = useGetTemplateByIdQuery(
        {
            entityId: entityId
                ? entityId
                : '097adb21-7e53-47aa-896c-fa2197396662',
            templateId: templateId ? templateId : '',
        },
        {
            skip:
                templateId === '' ||
                templateId === null ||
                templateId === 'DEFAULT' ||
                entityId === '',
        }
    );

    const [templateDetails, setTemplateDetails] =
        useState<IRfqTemplate>(blankRfqTemplate);

    useEffect(() => {
        if (isLoading) {
            updateHookState(HookStateValue.LOADING);
        }
    }, [isLoading, updateHookState]);
    useEffect(() => {
        if (templateId === 'DEFAULT' || templateId === null) {
            setTemplateDetails(defaultFWTemplateFE);
            updateHookState(HookStateValue.READY);
        }
    }, [templateId, updateHookState]);

    const { data: adminAdditionalCost } = useGetAdditionalCostFromAdminQuery(
        {}
    );

    useEffect(() => {
        if (templateData && isLoading === false) {
            //

            // setTemplateDetails(templateData);

            if (filterCostFromAdmin && adminAdditionalCost) {
                let tempDetailsToBeEdited = cloneDeep(templateData);

                tempDetailsToBeEdited.fieldSettings.standardFields[
                    TemplateStandardFieldsEnum.ADDITIONAL_COSTS
                ].additionalCostOptions = tempDetailsToBeEdited.fieldSettings.standardFields[
                    TemplateStandardFieldsEnum.ADDITIONAL_COSTS
                ].additionalCostOptions?.filter((tempAC) =>
                    adminAdditionalCost?.some(
                        (adminAC) =>
                            adminAC.additional_cost_id ===
                            tempAC.additional_cost_id
                    )
                );

                setTemplateDetails(tempDetailsToBeEdited);
            } else {
                setTemplateDetails(templateData);
            }

            // setTemplateDetails(templateData);

            updateHookState(HookStateValue.READY);
        }
    }, [
        adminAdditionalCost,
        filterCostFromAdmin,
        isLoading,
        templateData,
        updateHookState,
    ]);

    useEffect(() => {
        if (isError && templateId !== '') {
            updateHookState(HookStateValue.ERROR);
            // console.error('Error fetching template data');
        }
    }, [isError, templateId, updateHookState, error]);

    // const fetchData = useCallback(async () => {
    //     try {
    //         updateHookState(HookStateValue.LOADING);
    //         const template = await fetchRfqTemplate(templateId);
    //         const templates = await fetchTemplateData(templateId);
    //         setTemplateDetails(template);
    //         updateHookState(HookStateValue.READY);
    //     } catch (error) {
    //
    //         updateHookState(HookStateValue.ERROR);
    //     }
    // }, [updateHookState, templateId, fetchTemplateData]);

    // useEffect(() => {
    //     fetchData();
    // }, [fetchData]);

    return {
        hookState,
        templateDetails,
    };
};
