import React from 'react';
import { IconButton, Box } from '@mui/material';
import FWImagePopup from '../../Common/FWImagePopup';
import incotermsImage from '../../Assets/Images/incoterms.png';

const IncotermsGraph = () => {
    const [openIncoterms, setOpenIncoterms] = React.useState<boolean>(false);
    return (
        <>
            <IconButton
                sx={{
                    maxWidth: '30px',
                    display: 'flex',
                    alignItems: 'center',
                    padding: 0,
                    lineHeight: 0,
                    ':hover': {
                        background: 'transparent',
                    },
                    '.MuiTouchRipple-root': {
                        display: 'none',
                    },
                }}
                onClick={() => {
                    setOpenIncoterms(!openIncoterms);
                }}
            >
                <Box color="primary.main" fontSize="16px">
                    <i className="bi bi-question-circle"></i>
                </Box>
            </IconButton>
            <FWImagePopup
                imageSrc={incotermsImage}
                open={openIncoterms}
                handleOpen={setOpenIncoterms}
                imageStyle={{ maxHeight: '75vh', width: 'auto' }}
                dialogStyle={{ maxWidth: '95vw' }}
            />
        </>
    );
};

export default IncotermsGraph;
