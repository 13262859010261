import { Box } from '@mui/material';
import { CSSProperties } from 'react';

interface IFWIconProps {
    name: string;
    className?: string;
    color?: string;
    style?: CSSProperties;
    size?: number;
    height?: number;
}

const FWIcon = ({ name, color, className, style, size, height }: IFWIconProps) => {
    return (
        <Box
            color={color ? color : 'currentColor'}
            style={{ display: 'inline-flex', alignItems: 'center', ...style }}
        >
            <i
                className={`${name} ${className ? className : ``}`}
                style={{
                    fontSize: `${size}px`,
                    ...(height && { height: `${height}px` }),
                }
                }
            />
        </Box>
    );
};

export default FWIcon;
