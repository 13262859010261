import React from 'react';
import { HookStateValue, useHookState } from '../../Common/Hooks/StateHook';
import { AuthContext } from '../../Contexts/AuthContext';
import { IPurchaseOrder, PurchaseOrderStatus } from '../Interfaces/PO.model';
import {
    sellectAcceptPurchaseOrder,
    sellerDeclinePurchaseOrder,
} from '../Services/PO.service';

export const usePurchaseOrderIssuedSellerActions = (
    purchaseOrderData: IPurchaseOrder
) => {
    const { hookState } = useHookState(HookStateValue.READY);
    const { checkPermission } = React.useContext(AuthContext);

    const [showButtons, setShowButtons] = React.useState(false);

    const acceptPo = React.useCallback(
        (event_type: 'DIRECT' | 'FROM_EVENT'): Promise<void> => {
            return new Promise<void>(async (resolve, reject) => {
                try {
                    await sellectAcceptPurchaseOrder(
                        purchaseOrderData.purchase_order_id,
                        '',
                        event_type
                    );
                    resolve();
                } catch {
                    reject();
                }
            });
        },
        [purchaseOrderData.purchase_order_id]
    );

    const declinePo = React.useCallback(
        (event_type: 'DIRECT' | 'FROM_EVENT', notes: string): Promise<void> => {
            return new Promise<void>(async (resolve, reject) => {
                try {
                    await sellerDeclinePurchaseOrder(
                        purchaseOrderData.purchase_order_id,
                        notes,
                        event_type
                    );
                    resolve();
                } catch {
                    reject();
                }
            });
        },
        [purchaseOrderData.purchase_order_id]
    );

    React.useEffect(() => {
        if (purchaseOrderData.status === PurchaseOrderStatus.ISSUED) {
            let hasSellerPermission = checkPermission(
                'SELLER',
                null,
                null,
                null
            );
            if (hasSellerPermission) {
                setShowButtons(true);
            } else {
                setShowButtons(false);
            }
        } else {
            setShowButtons(false);
        }
    }, [purchaseOrderData, checkPermission]);

    return {
        hookState,
        showButtons,
        acceptPo,
        declinePo,
    };
};
