import { Box } from '@mui/material';
import React, { useContext } from 'react';
import VendorLNMainTab from '../Components/VendorLNMainTab';
import VendorLNProfileMainContainer from '../Containers/Profile/VendorLNProfileMainContainer';
import { TVendorProfileChip } from '../Interfaces/VendorLNInterfaces';
// import VendorLNRelationshipMainContainer from '../Containers/Relationship/VendorLNRelationshipMainContainer';
import { HookStateValue } from '../../Common/Hooks/StateHook';
import LazyFallback from '../../Components/Shared/LazyFallBack';
import { VLNContext } from '../../Events/Components/Buyer/VendorLinkedInPopup';
import VendorLNInsightsMainContainer from '../Containers/Insights/ItemAnalyticsCartContainer';
import VLNRelationshipInsightsSection from '../Containers/Relationship/VLNRelationshipInsightsSection';
import VendorLNRelationshipMainContainer from '../Containers/Relationship/VendorLNRelationshipMainContainer';
import { useVLN } from '../Hooks/useVLN';

const VendorLNMainPage = () => {
    const [tabValue, setTabValue] =
        React.useState<TVendorProfileChip>('profile');

    const { sellerEntityUuid } = useContext(VLNContext);
    const { hookState, vendorProfileID } = useVLN(sellerEntityUuid);

    return !(hookState.state === HookStateValue.READY) ? (
        <LazyFallback />
    ) : vendorProfileID ? (
        <>
            <Box
                paddingX={4}
                position={'sticky'}
                top={'73px'}
                bgcolor={'white'}
                zIndex={10}
            >
                <VendorLNMainTab
                    tabValue={tabValue}
                    setTabValue={setTabValue}
                />
            </Box>
            <Box>
                {(() => {
                    switch (tabValue) {
                        case 'profile':
                            return (
                                <VendorLNProfileMainContainer
                                    vendor_profile_id={vendorProfileID}
                                />
                            );
                        case 'relationship':
                            return (
                                <VendorLNRelationshipMainContainer
                                    vendor_profile_id={vendorProfileID}
                                />
                                // <IARelationshipMainContainer />
                            );
                        case 'insights':
                            return <VendorLNInsightsMainContainer />;
                    }
                })()}
            </Box>
        </>
    ) : (
        <Box padding={4}>
            <VLNRelationshipInsightsSection />
        </Box>
    );
};

export default VendorLNMainPage;
