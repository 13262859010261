import { debounce } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { HookStateValue, useHookState } from '../../../Common/Hooks/StateHook';
import { IBuyerInvoiceDashboardInterface } from '../../../Invoices/Interfaces/Shared.model';
import { getBuyerInvoiceDashboardRows } from '../../../Invoices/Services/invoices.service';

export type sortFields =
    | 'custom_po_id'
    | 'custom_invoice_id'
    | 'invoice_document_type'
    | 'buyer_entity_name'
    | 'seller_entity_name'
    | 'invoice_item_count'
    | 'invoice_status'
    | 'expected_delivery_date'
    | 'invoice_payment_pending_amount';

const sortColumnsNames = {
    custom_po_id: 'po_id',
    custom_invoice_id: 'invoice_id',
    invoice_document_type: 'invoice_type',
    buyer_entity_name: 'buyer_entity',
    seller_entity_name: 'seller_entity',
    invoice_item_count: 'invoice_item_count',
    invoice_status: 'status',
    expected_delivery_date: 'delivery_date',
    invoice_payment_pending_amount: 'amount_pending',
};

export const useVLNRelationshipInvoiceDashboard = (
    sellerEntityUuid: string
) => {
    const { hookState, updateHookState } = useHookState(HookStateValue.INITIAL);
    const [rows, setRows] = useState<IBuyerInvoiceDashboardInterface[] | null>(
        null
    );
    const [sortFields, setSortFields] = useState<
        {
            field: sortFields;
            ascending: boolean;
        }[]
    >([]);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [searchText, setSearchText] = useState('');
    const [currentPage, setCurrentPage] = useState(1);

    const debouncedSearch = useMemo(
        () =>
            debounce((text: string) => {
                setSearchText(text);
            }, 1000),
        []
    );

    const fetchCostingDashboardData = useCallback(
        //     async () => {
        //     try {
        //         updateHookState(HookStateValue.LOADING);
        //         const resp = await costingDashboardApi();
        //
        //         setCostingSheetsList(resp.data);
        //     } catch (error) {
        //
        //     }
        //     updateHookState(HookStateValue.READY);
        // }
        async (currentPage, searchText, sortFields) => {
            //Pass necessary payloads for the API call.
            // All of them are states

            try {
                setRows(null);
                const sortFieldsArray = sortFields.map(
                    (sortField: { field: sortFields; ascending: boolean }) => {
                        return {
                            field: sortColumnsNames[sortField.field],
                            ascending: sortField.ascending,
                        };
                    }
                );
                let costingDashboardPromise =
                    await getBuyerInvoiceDashboardRows({
                        searchText: searchText,
                        itemsPerPage: 10,
                        pageNumber: currentPage,
                        sortFields: sortFieldsArray as any,
                        tab: 'all',
                        sellerEntityUuid,
                    });

                const [costingItemsResp] = await Promise.all([
                    costingDashboardPromise,
                ]);

                setRows(costingItemsResp.invoices);
                setTotalPages(costingItemsResp.pageDetails.total_pages);
                updateHookState(HookStateValue.READY);
            } catch (error) {}
        },
        [sellerEntityUuid, updateHookState]
    );

    useEffect(() => {
        fetchCostingDashboardData(currentPage, searchText, sortFields);
    }, [currentPage, fetchCostingDashboardData, searchText, sortFields]);

    return {
        rows,
        searchText,
        sortFields,
        setSortFields,
        totalPages,
        setCurrentPage,
        currentPage,
        debouncedSearch,
        hookState,
    };
};
