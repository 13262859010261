import { DialogTitle, SxProps } from '@mui/material';
import { CSSProperties, Dispatch, SetStateAction } from 'react';
import { CustomDialog } from './FWPopup';

interface IImagePopupProps {
    imageSrc: string;
    open: boolean;
    handleOpen: Dispatch<SetStateAction<boolean>>;
    title?: string;
    imageStyle?: CSSProperties;
    dialogStyle?: SxProps;
}

const FWImagePopup = ({
    imageSrc,
    open,
    handleOpen,
    title,
    imageStyle,
    dialogStyle,
}: IImagePopupProps) => {
    const handleClose = () => handleOpen(!open);
    return (
        <>
            <CustomDialog
                onClose={handleClose}
                open={open}
                PaperProps={{
                    style: {
                        maxWidth: 'unset',
                        maxHeight: 'unset',
                    },
                }}
            >
                {title && <DialogTitle>{title}</DialogTitle>}
                <img src={imageSrc} alt={title} style={imageStyle} />
            </CustomDialog>
        </>
    );
};

export default FWImagePopup;
