import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
    IItemAddProviders,
    IItemDirectoryProviders,
    ItemReducerAction,
    useItemAdd,
    useItemsList,
} from '../../Hooks/ItemDirectory/ItemDirectoryHook';
import { IItemDetails } from '../../Interfaces/ItemDirectoryInterface';
import { IItemAddEditResponse } from '../../Services/ItemDirectoryService';
import ItemAddEditContainer, { ItemPageType } from './ItemAddEditContainer';

interface IItemAddContainerProps {
    event?: boolean;
    onSuccess?: (
        new_enterprise_item_id: string,
        isFinishedGood: boolean
    ) => void;
    onCancel?: () => void;
    defaultItemName?: string;
    // if below prop is passed the newly created item will be linked to this entity
    // else if not passed it'll be added only to an enterprise and not to any entity (user will have to link it manually)
    entityIDToLink?: string;
    shouldRedirect?: boolean;
}

const ItemAddContainer = ({
    event,
    onSuccess,
    onCancel,
    defaultItemName,
    entityIDToLink,
    shouldRedirect = true,
}: IItemAddContainerProps) => {
    const history = useHistory();

    const { itemDirectoryList }: IItemDirectoryProviders = useItemsList();

    const usedItemCodes = useMemo(
        () => itemDirectoryList.map((item) => item.itemCode.toLowerCase()),
        [itemDirectoryList]
    );

    const {
        newItemDetails,
        updateItemDetails,
        measurements,
        currencies,
        isValidDetails,
        handleItemSave,
        globalTags,
        fetchItemMeasurements,
    }: IItemAddProviders = useItemAdd(
        defaultItemName ?? '',
        entityIDToLink ?? null
    );

    const handleDetailChange = (data: ItemReducerAction) =>
        updateItemDetails(data);

    const handleEmitSaveAction = (
        msg: IItemAddEditResponse,
        isFinishedGood: boolean
    ) => {
        if (msg.success) {
            if (onSuccess) {
                onSuccess(msg.itemId, isFinishedGood);
            } else {
                history.replace(
                    `/admin/items/item-directory/edit/${msg.itemId}`
                );
            }
        } else {
            toast.error(`Item add failed`);
        }
    };

    const codeUsed = useMemo(
        () => usedItemCodes.includes(newItemDetails.itemCode.toLowerCase()),
        [usedItemCodes, newItemDetails.itemCode]
    );

    return (
        <>
            {/* {hookState.state === HookStateValue.LOADING && <LoadingBackDrop />} */}
            <ItemAddEditContainer
                type={ItemPageType.ADD}
                data={newItemDetails}
                fetchItemMeasurements={fetchItemMeasurements}
                handleChange={handleDetailChange}
                measurements={measurements}
                currencies={currencies}
                isValidDetails={isValidDetails}
                handleItemSave={handleItemSave}
                emitSaveAction={handleEmitSaveAction}
                codeUsed={codeUsed}
                event={event}
                ogData={{} as IItemDetails}
                globalTags={globalTags}
                shouldRedirect={shouldRedirect}
            />
        </>
    );
};

export default ItemAddContainer;
