import { Grid, IconButton, InputLabel } from '@mui/material';
import { debounce, isEmpty } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { FWInput } from '../../Common/FWInput';
import { DeliverySchedulePopup } from '../../Common/FWDeliverySchedulePopup';
import { NavLink, useHistory } from 'react-router-dom';
import { IGRInvoiceListSummary } from '../Interfaces/GRInterface';
import FWIcon from '../../Common/FWIcon';
import { FWTooltip } from '../../Common/FWCustomTooltip';
import { CustomFormControl } from '../../Common/FWCustomInputs';
import FWSelect from '../../Common/FWSelect';
import { FWMenuItem } from '../../Common/FWCustomMenu';
import GRPendingCombinedView from './GRPendingCombinedView';
import GRPendingInvoiceView from './GRPendingInvoiceView';
import GRPendingPo from './GRPendingPO';
import { setSelectedTabVew } from '../Slices/GRDashboardSlice';
import { useDispatch } from 'react-redux';

export enum PendingView {
    PURCHASE_ORDER = 'purchase_order',
    DEFAULT = 'combined',
    INVOICE = 'invoice',
}

const GRPendingListContainer = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [search, setSearch] = useState<string>('');

    let params = new URLSearchParams(window.location.search);
    let initView =
        (params.get('view') as PendingView) || PendingView.PURCHASE_ORDER;
    const [view, setView] = useState<PendingView>(initView);
    // useEffect(() => {
    //     fetchTabCount(GRDashboardType.PENDING, view).then((count: number) => {
    //         dispatch(
    //             setTabCount({
    //                 type: GRDashboardType.PENDING,
    //                 value: count,
    //             })
    //         );
    //     });
    // }, [view, dispatch]);

    const [deliveryScheduleOptions, setDeliveryScheduleOptions] = useState({
        poUid: '',
        measurementUnit: '',
    });

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    // const [grPermissions] = usePermissionCheck('BUYER', 'GOODS_RECEIPT', [
    //     'VIEW',
    //     'EDIT',
    // ]);

    const handleDeliveryScheduleToggle = (
        poUid: string,
        measurementUnit: string,
        el: HTMLElement
    ) => {
        setAnchorEl(el);
        setDeliveryScheduleOptions({
            poUid,
            measurementUnit,
        });
    };

    const handleRowClick = ({ data }: { data: IGRInvoiceListSummary }) => {
        // if (
        //     !grPermissions[data.entityUid].EDIT &&
        //     grPermissions[data.entityUid].VIEW
        // ) {
        //     return false;
        // }
        //
        if (data.pendingInvoiceCount && data.pendingInvoiceCount > 0) {
            history.push(`/buyer/goods-receipt/create/new/${data.poUid}`);
            return;
        }
        if (data.hasInvoice) {
            history.push(`/buyer/goods-receipt/create/${data.invoiceUid}`);
        } else {
            history.push(
                `/buyer/goods-receipt/create/invoice/dash-inv/${data.poUid}`
            );
        }
    };

    const [showPlusButton, setShowPlusButton] = useState<boolean>(false);

    const searchList = useMemo(
        () =>
            debounce(() => {
                const rows = document.querySelectorAll(
                    '.ag-center-cols-container .ag-row'
                );
                if (rows.length === 0) {
                    setShowPlusButton(true);
                } else {
                    setShowPlusButton(false);
                }
            }, 1000),
        []
    );
    const debouncedSearch = useMemo(
        () =>
            debounce((text: string) => {
                setSearch(text);
            }, 1000),
        []
    );

    useEffect(() => {
        history.replace(`/buyer/goods-receipt?type=pending&view=${view}`);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [view]);

    useEffect(() => {
        dispatch(setSelectedTabVew(view));
    }, [view, dispatch]);

    useEffect(() => {
        if (view !== PendingView.DEFAULT) searchList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [view, search]);

    return (
        <>
            <Grid
                item
                xs={12}
                className="flex flex--aic flex--jcfe"
                sx={{ mb: '20px' }}
            >
                <div className="flex flex--aic flex--jcsb">
                    <CustomFormControl>
                        <InputLabel id="view-select-label">View</InputLabel>
                        <FWSelect
                            labelId="view-select-label"
                            label="View"
                            onChange={(e) => {
                                setView(e.target.value as PendingView);
                            }}
                            value={view}
                            sx={{ minWidth: '150px' }}
                        >
                            <FWMenuItem
                                id="grDashboard_defaultView"
                                value={PendingView.DEFAULT}
                            >
                                Default
                            </FWMenuItem>
                            <FWMenuItem
                                id="grDashboard_InvoiceView"
                                value={PendingView.INVOICE}
                            >
                                Invoice
                            </FWMenuItem>
                            <FWMenuItem
                                id="grDashboard_POView"
                                value={PendingView.PURCHASE_ORDER}
                            >
                                Purchase Order
                            </FWMenuItem>
                        </FWSelect>
                    </CustomFormControl>
                    <div className="mr--15"></div>
                    <FWInput
                        onChange={(e: any) => debouncedSearch(e.target.value)}
                        placeholder={'Search'}
                        iconStart={<i className="bi bi-search" />}
                        style={{ width: 400 }}
                    />
                </div>

                {showPlusButton && (
                    <>
                        <div className="ml--5"></div>
                        <FWTooltip title="Add invoice">
                            <div>
                                <NavLink
                                    to={`/buyer/goods-receipt/create/new?id=${search}`}
                                    style={{
                                        textDecoration: 'none',
                                    }}
                                >
                                    <IconButton color="primary">
                                        <FWIcon
                                            name="bi bi-plus-circle"
                                            size={20}
                                        />
                                    </IconButton>
                                </NavLink>
                            </div>
                        </FWTooltip>
                    </>
                )}
            </Grid>
            {view === PendingView.PURCHASE_ORDER && (
                <GRPendingPo
                    searchText={search}
                    handleDeliveryScheduleToggle={handleDeliveryScheduleToggle}
                    handleRowClick={handleRowClick}
                />
            )}
            {view === PendingView.INVOICE && (
                <GRPendingInvoiceView
                    searchText={search}
                    handleDeliveryScheduleToggle={handleDeliveryScheduleToggle}
                    handleRowClick={handleRowClick}
                />
            )}
            {view === PendingView.DEFAULT && (
                <GRPendingCombinedView
                    searchText={search}
                    handleDeliveryScheduleToggle={handleDeliveryScheduleToggle}
                    handleRowClick={handleRowClick}
                    searchList={searchList}
                />
            )}

            <DeliverySchedulePopup
                open={!isEmpty(deliveryScheduleOptions.poUid)}
                setOpen={() => {
                    setDeliveryScheduleOptions({
                        poUid: '',
                        measurementUnit: '',
                    });
                }}
                uidInfo={{
                    id: deliveryScheduleOptions.poUid,
                    type: 'MULTIPLE',
                }}
                anchorEl={anchorEl}
            />
        </>
    );
};

export default GRPendingListContainer;
