import {
    Avatar,
    Box,
    CircularProgress,
    DialogActions,
    DialogContent,
    Grid,
} from '@mui/material';
import { FieldArray, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { cloneDeep, isEmpty } from 'lodash';
import { useContext, useEffect } from 'react';
import { FWButton } from '../../../../Common/FWButton';
import { FWInput } from '../../../../Common/FWInput';
import { FWPopupWithChildren } from '../../../../Common/FWPopupWithChildren';
import { FWTypography } from '../../../../Common/FWTypograhy';
import { IVendorContact } from '../../Interfaces/VendorsInterface';
import { updateGlobalContactInDirectory } from '../../Services/VendorsService';
import { toast } from 'react-toastify';
import VendorContactStatusChip from '../../Components/Vendors/VendorContactStatusChip';
import { AuthContext } from '../../../../Contexts/AuthContext';

interface IVendorContactBoxProps {
    open: boolean;
    data: IVendorContact;
    vendorUid: string;
    isVerified: boolean;
    onUpdateSuccess: () => void;
    handlePopupToggle: () => void;
    readOnly?: boolean;
}

const VendorEnterpriseContactPopup = ({
    open,
    data,
    vendorUid,
    onUpdateSuccess,
    handlePopupToggle,
    isVerified,
    readOnly,
}: IVendorContactBoxProps) => {
    const handleClosePopup = () => {
        formController.setValues(cloneDeep(data));
        handlePopupToggle();
    };

    const { authData } = useContext(AuthContext);

    const handleSubmit = async (values: IVendorContact) => {
        const contactUpdated = await updateGlobalContactInDirectory(
            data.vendorContactUid,
            vendorUid,
            values
        );
        if (contactUpdated) {
            handlePopupToggle();
            onUpdateSuccess();
        } else {
            toast.error(`Error updating primary contact`);
        }
    };

    const formController = useFormik<IVendorContact>({
        initialValues: cloneDeep(data),
        validationSchema: Yup.object({
            fullName: Yup.string().trim().required('Full name is required'),
            buyerContacts: Yup.array().of(
                Yup.object().shape({
                    number: Yup.string()
                        .min(8, 'Min 8 chars')
                        .max(10, 'Max 10 chars'),
                })
            ),
            // buyerEmails: Yup.array().of(
            //     Yup.object().shape({
            //         email: Yup.string()
            //             .required('Email is required')
            //             .email('Must be a valid email'),
            //     })
            // ),
        }),
        onSubmit: handleSubmit,
    });

    const emails = [
        ...data.sellerEmails.filter((e) => e.visible).map((e) => e.email),
        ...data.buyerEmails.filter((e) => e.visible).map((e) => e.email),
    ];

    useEffect(() => {
        formController.setValues(cloneDeep(data));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    return (
        <>
            {/* contact edit popup */}
            <FWPopupWithChildren
                open={open}
                handleClose={handleClosePopup}
                title={`${
                    authData?.details?.role === 'ADMIN' ? 'Edit' : ''
                } Global Primary Contact`}
            >
                <FormikProvider value={formController}>
                    <form onSubmit={formController.handleSubmit}>
                        <DialogContent sx={{ padding: '12px 24px' }}>
                            <Grid container marginTop="15px" columnSpacing={2}>
                                <Grid
                                    item
                                    xs={12}
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                    }}
                                    marginBottom="10px"
                                >
                                    <Avatar
                                        alt={data.fullName}
                                        src={data.fullName}
                                        className="mb--5"
                                        style={{
                                            width: 60,
                                            height: 60,
                                        }}
                                    />
                                    <FWTypography sx={{ maxWidth: '100%' }}>
                                        {data.fullName}
                                    </FWTypography>
                                    <FWTypography
                                        sx={{ maxWidth: '100%' }}
                                        variant="caption"
                                        color="text.secondary"
                                    >
                                        {emails.join(', ')}
                                    </FWTypography>
                                    <VendorContactStatusChip
                                        status={data.invitationStatus}
                                    />
                                </Grid>

                                <Grid item xs={12} marginTop="15px">
                                    <Grid
                                        container
                                        columnSpacing={2}
                                        rowGap={2}
                                    >
                                        <Grid item xs={12}>
                                            <FWInput
                                                disabled={
                                                    data.userUid === null ||
                                                    readOnly
                                                }
                                                label="Full name"
                                                value={
                                                    formController.values
                                                        .fullName
                                                }
                                                name="fullName"
                                                onChange={
                                                    formController.handleChange
                                                }
                                                onBlur={
                                                    formController.handleBlur
                                                }
                                                error={
                                                    formController.touched
                                                        .fullName &&
                                                    !isEmpty(
                                                        formController.errors
                                                            .fullName
                                                    )
                                                }
                                                helper={{
                                                    text:
                                                        (formController.touched
                                                            .fullName &&
                                                            formController
                                                                .errors
                                                                .fullName) ||
                                                        '',
                                                }}
                                                allowOnly="TEXT"
                                                required
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FieldArray
                                                name="buyerContacts"
                                                render={(arrayHelpers) => (
                                                    <>
                                                        {/* <div className="flex flex--aic">
                                                            <FWTypography
                                                                sx={{
                                                                    marginBottom:
                                                                        '5px',
                                                                    color: 'text.secondary',
                                                                }}
                                                            >
                                                                Contact number
                                                            </FWTypography>
                                                            <FWTooltip title="Add contact">
                                                                <div>
                                                                    <IconButton
                                                                        color="primary"
                                                                        onClick={() =>
                                                                            arrayHelpers.push(
                                                                                {
                                                                                    number: '',
                                                                                    visible:
                                                                                        true,
                                                                                }
                                                                            )
                                                                        }
                                                                        sx={{
                                                                            ml: '5px',
                                                                            padding: 0,
                                                                            height: '24px',
                                                                        }}
                                                                        disableRipple
                                                                    >
                                                                        <FWIcon
                                                                            name="bi bi-plus-circle"
                                                                            size={
                                                                                17
                                                                            }
                                                                        />
                                                                    </IconButton>
                                                                </div>
                                                            </FWTooltip>
                                                        </div> */}
                                                        {/* {data.sellerContacts
                                                            .filter(
                                                                (con) =>
                                                                    con.visible
                                                            )
                                                            .map(
                                                                (
                                                                    { number },
                                                                    idx
                                                                ) => {
                                                                    return (
                                                                        <FWInput
                                                                            key={
                                                                                idx
                                                                            }
                                                                            value={
                                                                                number
                                                                            }
                                                                            sx={{
                                                                                mb: '10px',
                                                                            }}
                                                                            disabled
                                                                        />
                                                                    );
                                                                }
                                                            )} */}
                                                        {formController.values.buyerContacts.map(
                                                            (
                                                                { number },
                                                                index
                                                            ) => {
                                                                const touched: any =
                                                                    formController
                                                                        .touched
                                                                        .buyerContacts &&
                                                                    formController
                                                                        .touched
                                                                        .buyerContacts[
                                                                        index
                                                                    ];
                                                                const isTouched =
                                                                    touched &&
                                                                    touched.number;
                                                                const error: any =
                                                                    formController
                                                                        .errors
                                                                        .buyerContacts &&
                                                                    formController
                                                                        .errors
                                                                        .buyerContacts[
                                                                        index
                                                                    ];
                                                                const hasError =
                                                                    !isEmpty(
                                                                        error &&
                                                                            error.number
                                                                    );
                                                                return (
                                                                    <div
                                                                        key={
                                                                            index
                                                                        }
                                                                    >
                                                                        <FWInput
                                                                            disabled={
                                                                                data.userUid ===
                                                                                    null ||
                                                                                readOnly
                                                                            }
                                                                            label="Phone (optional)"
                                                                            value={
                                                                                number
                                                                            }
                                                                            allowOnly="TEL"
                                                                            name={`buyerContacts.${index}.number`}
                                                                            onChange={
                                                                                formController.handleChange
                                                                            }
                                                                            onBlur={
                                                                                formController.handleBlur
                                                                            }
                                                                            // iconEnd={
                                                                            //     <IconButton
                                                                            //         color="error"
                                                                            //         onClick={() => {
                                                                            //             arrayHelpers.remove(
                                                                            //                 index
                                                                            //             );
                                                                            //         }}
                                                                            //         sx={{
                                                                            //             padding: 0,
                                                                            //         }}
                                                                            //         disabled={
                                                                            //             formController
                                                                            //                 .values
                                                                            //                 .buyerContacts
                                                                            //                 .length ===
                                                                            //             1
                                                                            //         }
                                                                            //     >
                                                                            //         <FWIcon
                                                                            //             name="bi bi-dash-circle"
                                                                            //             size={
                                                                            //                 20
                                                                            //             }
                                                                            //         />
                                                                            //     </IconButton>
                                                                            // }
                                                                            error={
                                                                                isTouched &&
                                                                                hasError
                                                                            }
                                                                            helper={{
                                                                                text:
                                                                                    (isTouched &&
                                                                                        error &&
                                                                                        error.number) ||
                                                                                    '',
                                                                            }}
                                                                            // required
                                                                        />
                                                                    </div>
                                                                );
                                                            }
                                                        )}
                                                    </>
                                                )}
                                            />
                                        </Grid>
                                        {/* <Grid item xs={12}>
                                            <FieldArray
                                                name="buyerEmails"
                                                render={(arrayHelpers) => (
                                                    <>
                                                        <div className="flex flex--aic">
                                                            <FWTypography
                                                                sx={{
                                                                    marginBottom:
                                                                        '5px',
                                                                    color: 'text.secondary',
                                                                }}
                                                            >
                                                                Contact number
                                                            </FWTypography>
                                                            <FWTooltip title="Add contact">
                                                                <div>
                                                                    <IconButton
                                                                        color="primary"
                                                                        onClick={() =>
                                                                            arrayHelpers.push(
                                                                                {
                                                                                    number: '',
                                                                                    visible:
                                                                                        true,
                                                                                }
                                                                            )
                                                                        }
                                                                        sx={{
                                                                            ml: '5px',
                                                                            padding: 0,
                                                                            height: '24px',
                                                                        }}
                                                                        disableRipple
                                                                    >
                                                                        <FWIcon
                                                                            name="bi bi-plus-circle"
                                                                            size={
                                                                                17
                                                                            }
                                                                        />
                                                                    </IconButton>
                                                                </div>
                                                            </FWTooltip>
                                                        </div>
                                                        {data.sellerContacts
                                                            .filter(
                                                                (con) =>
                                                                    con.visible
                                                            )
                                                            .map(
                                                                (
                                                                    { number },
                                                                    idx
                                                                ) => {
                                                                    return (
                                                                        <FWInput
                                                                            key={
                                                                                idx
                                                                            }
                                                                            value={
                                                                                number
                                                                            }
                                                                            sx={{
                                                                                mb: '10px',
                                                                            }}
                                                                            disabled
                                                                        />
                                                                    );
                                                                }
                                                            )}
                                                        {formController.values.buyerEmails.map(
                                                            (
                                                                { email },
                                                                index
                                                            ) => {
                                                                const touched: any =
                                                                    formController
                                                                        .touched
                                                                        .buyerEmails &&
                                                                    formController
                                                                        .touched
                                                                        .buyerEmails[
                                                                        index
                                                                    ];
                                                                const isTouched =
                                                                    touched &&
                                                                    touched.email;
                                                                const error: any =
                                                                    formController
                                                                        .errors
                                                                        .buyerEmails &&
                                                                    formController
                                                                        .errors
                                                                        .buyerEmails[
                                                                        index
                                                                    ];
                                                                const hasError =
                                                                    !isEmpty(
                                                                        error &&
                                                                            error.email
                                                                    );
                                                                return (
                                                                    <div
                                                                        key={
                                                                            index
                                                                        }
                                                                    >
                                                                        <FWInput
                                                                            label="Email"
                                                                            value={
                                                                                email
                                                                            }
                                                                            allowOnly="EMAIL"
                                                                            name={`buyerEmails.${index}.email`}
                                                                            onChange={
                                                                                formController.handleChange
                                                                            }
                                                                            onBlur={
                                                                                formController.handleBlur
                                                                            }
                                                                            error={
                                                                                isTouched &&
                                                                                hasError
                                                                            }
                                                                            helper={{
                                                                                text:
                                                                                    (isTouched &&
                                                                                        error &&
                                                                                        error.email) ||
                                                                                    '',
                                                                            }}
                                                                            disabled={
                                                                                data.invitationStatus !==
                                                                                'VERIFIED'
                                                                            }
                                                                            required
                                                                        />
                                                                    </div>
                                                                );
                                                            }
                                                        )}
                                                    </>
                                                )}
                                            />
                                        </Grid> */}
                                        {/* <Grid item xs={12}>
                                            <FWInput
                                                label="Notes (optional)"
                                                value={
                                                    formController.values.notes
                                                }
                                                name="notes"
                                                onChange={
                                                    formController.handleChange
                                                }
                                                onBlur={
                                                    formController.handleBlur
                                                }
                                                error={
                                                    formController.touched
                                                        .notes &&
                                                    !isEmpty(
                                                        formController.errors
                                                            .notes
                                                    )
                                                }
                                                helper={{
                                                    text:
                                                        (formController.touched
                                                            .notes &&
                                                            formController
                                                                .errors
                                                                .notes) ||
                                                        '',
                                                }}
                                                required
                                            />
                                        </Grid> */}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        {readOnly && (
                            <Box sx={{ padding: '12px 24px 24px 24px' }}></Box>
                        )}
                        {authData?.details?.role === 'ADMIN' && !readOnly && (
                            <DialogActions
                                sx={{ padding: '12px 24px 24px 24px' }}
                            >
                                <FWButton
                                    variant="outlined"
                                    onClick={handleClosePopup}
                                >
                                    Cancel
                                </FWButton>
                                <span style={{ width: '0.75rem' }}></span>
                                {/* {isVerified && ( */}
                                {data.userUid && (
                                    <FWButton
                                        variant="contained"
                                        type="submit"
                                        disabled={
                                            !formController.isValid ||
                                            formController.isSubmitting
                                        }
                                    >
                                        {formController.isSubmitting ? (
                                            <CircularProgress
                                                size={24}
                                                style={{ color: '#c4c4c8' }}
                                            />
                                        ) : (
                                            'Save'
                                        )}
                                    </FWButton>
                                )}
                                {/* )} */}
                            </DialogActions>
                        )}
                    </form>
                </FormikProvider>
            </FWPopupWithChildren>
        </>
    );
};

export default VendorEnterpriseContactPopup;
