import {
    Box,
    CircularProgress,
    DialogContent,
    Divider,
    Grid,
    IconButton,
    Typography,
} from '@mui/material';
import { cloneDeep } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useGetContractDetailsForItemQuery } from '../../CLM/Services/CLM.service';
import { FWMenuItem } from '../../Common/FWCustomMenu';
import { FWTooltip } from '../../Common/FWCustomTooltip';
import FWPopover from '../../Common/FWPopover';
import FWSelect from '../../Common/FWSelect';
import { HookStateValue } from '../../Common/Hooks/StateHook';
import ContractBarChart from '../../Events/Components/Buyer/ContractBarChart';
import PricingAnalyticsChart from '../Components/Graphs/PricingAnalyticsChart';
import { useItemPriceDynamicAnalytics } from '../Hooks/useItemPriceDynamicAnalytics';
import { DynamicPricingAnalyticsHookDataType } from '../Interfaces/DynamicAnalyticsInterfaces.model';
import { CurrencyDetails } from '../Interfaces/IHistoricalAnalyticsDropdowns.model';
import { useSelector } from 'react-redux';
import { IGlobalAppStore } from '../../Redux/Store';
// import ContractBarChart from '../../Events/Components/Buyer/ContractBarChart';

export interface IAggregateOfCurrency {
    [currencyUid: string]: {
        currencyName: string;
        currencySymbol: string;
        currencyAbbreviation: string;
    };
}

export interface IAggregateOfProject {
    [projectUid: string]: {
        projectName: string;
        projectCode: string;
    };
}

export default function DynamicAnalyticsItemPrice({
    enterpriseItemUuid,
    currencyDetails,
    measurementUnitUuid,
    measurementUnitName,
    buttonPadding,
    entityId,
    handleSelectionOfContract,
    neglectStoreAndShowGraph,
    showForItem,
    clickedFirstTime,
    setClickedFirstTime,
}: // contractDetails,
{
    enterpriseItemUuid: string;
    currencyDetails: CurrencyDetails;
    measurementUnitUuid: string;
    measurementUnitName: string;
    buttonPadding?: string;
    entityId?: string;
    neglectStoreAndShowGraph?: boolean;
    handleSelectionOfContract?: (contractUid: string) => void;
    showForItem?: boolean;
    clickedFirstTime?: boolean;
    setClickedFirstTime?: React.Dispatch<React.SetStateAction<boolean>>;
    // contractDetails?: {
    //     [contractUid: string]: IGetContractDetailsForItem;
    // };
}) {
    const hookData: DynamicPricingAnalyticsHookDataType = {
        enterpriseItemUuid,
        currencyDetails,
        measurementUnitUuid,
    };

    const {
        hookState,
        // itemPricingData,
        visibleData,
        userFilter,
        updateFilter,
        userFilterLastXOptions,
        userFilterXDaysOptions,
        filterCurrencies,
        toggleCurrencyFilter,
    } = useItemPriceDynamicAnalytics(hookData, clickedFirstTime);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [open, setOpen] = useState<boolean>(false);

    const { data: listOfContract } = useGetContractDetailsForItemQuery(
        {
            enterpriseItemUid: enterpriseItemUuid,
            entityUid: entityId ? entityId : '',
        },
        {
            skip: entityId === undefined,
        }
    );

    const [
        dataForContractHistoricalViewRates,
        setDataForContractHistoricalViewRates,
    ] = useState<
        {
            contractName: string;
            vendorName: string;
            value: string;
            contractID: string;
            currencyUid: string;
            currencyName: string;
            currencySymbol: string;
            currencyAbbreviation: string;
        }[]
    >([]);

    const [aggregateOfCurrency, setAggregateOfCurrency] =
        useState<IAggregateOfCurrency>({});
    const [aggregateOfProject, setAggregateOfProject] =
        useState<IAggregateOfProject>({});

    useEffect(() => {
        if (listOfContract) {
            const tempListOfContract = cloneDeep(listOfContract);
            let tempAggregationOfCurrency: {
                [currencyUid: string]: {
                    currencyName: string;
                    currencySymbol: string;
                    currencyAbbreviation: string;
                };
            } = {};
            let tempAggregationOfProject: {
                [projectUid: string]: {
                    projectName: string;
                    projectCode: string;
                };
            } = {};
            setDataForContractHistoricalViewRates(
                tempListOfContract
                    .map((contract) => {
                        // contract.contract_items =
                        contract?.contract_items?.forEach((contractItem) => {
                            if (
                                !(
                                    contractItem.currency in
                                    tempAggregationOfCurrency
                                )
                            ) {
                                tempAggregationOfCurrency[
                                    contractItem.currency
                                ] = {
                                    currencyName:
                                        contractItem.currency_details
                                            .currency_name,
                                    currencySymbol:
                                        contractItem.currency_details
                                            .currency_symbol,
                                    currencyAbbreviation:
                                        contractItem.currency_details
                                            .currency_code_abbreviation,
                                };
                            }

                            // return (
                            //     contractItem.enterprise_item ===
                            //     enterpriseItemUuid
                            // );
                        });

                        contract.contract_items =
                            contract.contract_items.filter((contractItem) => {
                                return (
                                    contractItem.enterprise_item ===
                                    enterpriseItemUuid
                                );
                            });

                        if (
                            contract.project_information &&
                            !(
                                contract.project_information.project_id in
                                tempAggregationOfProject
                            )
                        ) {
                            tempAggregationOfProject[
                                contract.project_information.project_id
                            ] = {
                                projectName:
                                    contract.project_information.project_name,
                                projectCode:
                                    contract.project_information.project_code,
                            };
                        }

                        return contract;
                    })
                    .map((contract) => ({
                        contractName: contract.contract_name,
                        vendorName:
                            contract.seller_entity_details.seller_entity_name,
                        value: contract.contract_items[0]?.rate,
                        contractID: contract.contract_id,
                        currencyUid: contract.contract_items[0]?.currency,
                        currencyName:
                            contract.contract_items[0]?.currency_details
                                .currency_name,
                        currencySymbol:
                            contract.contract_items[0]?.currency_details
                                .currency_symbol,
                        currencyAbbreviation:
                            contract.contract_items[0]?.currency_details
                                .currency_code_abbreviation,
                    }))
            );
            setAggregateOfCurrency(tempAggregationOfCurrency);
            setAggregateOfProject(tempAggregationOfProject);
        }
    }, [enterpriseItemUuid, listOfContract]);

    const handleFilterChange = useCallback(
        (currencyUid: string | null, projectUid: string | null) => {
            const tempListOfContract = cloneDeep(listOfContract);

            if (tempListOfContract) {
                setDataForContractHistoricalViewRates(
                    tempListOfContract
                        .map((contract) => {
                            contract.contract_items =
                                contract.contract_items.filter(
                                    (contractItem) => {
                                        return (
                                            contractItem.enterprise_item ===
                                            enterpriseItemUuid
                                        );
                                    }
                                );

                            return contract;
                        })
                        .filter((contract) => {
                            if (currencyUid) {
                                return contract.contract_items
                                    .map(
                                        (contractItem) => contractItem.currency
                                    )
                                    .includes(currencyUid);
                            }
                            return true;
                        })
                        .filter((contract) => {
                            if (projectUid) {
                                return (
                                    contract.project_information?.project_id ===
                                    projectUid
                                );
                            }
                            return true;
                        })
                        .map((contract) => ({
                            contractName: contract.contract_name,
                            vendorName:
                                contract.seller_entity_details
                                    .seller_entity_name,
                            value: contract.contract_items[0].rate,
                            contractID: contract.contract_id,
                            currencyUid: contract.contract_items[0].currency,
                            currencyName:
                                contract.contract_items[0].currency_details
                                    .currency_name,
                            currencySymbol:
                                contract.contract_items[0].currency_details
                                    .currency_symbol,
                            currencyAbbreviation:
                                contract.contract_items[0].currency_details
                                    .currency_code_abbreviation,
                        }))
                );
                // setAggregateOfCurrency(tempAggregationOfCurrency);
                // setAggregateOfProject(tempAggregationOfProject);
            }
        },
        [enterpriseItemUuid, listOfContract]
    );
    //
    // const dataForContractHistoricalViewRates: {
    //     contractName: string;
    //     vendorName: string;
    //     value: string;
    // }[] = useMemo(() => {
    // if (listOfContract) {
    //     return listOfContract
    //         .map((contract) => {
    //             contract.contract_items = contract.contract_items.filter(
    //                 (contractItem) => {
    //                     return (
    //                         contractItem.enterprise_item ===
    //                         enterpriseItemUuid
    //                     );
    //                 }
    //             );
    //             return contract;
    //         })
    //         .map((contract) => ({
    //             contractName: contract.contract_name,
    //             vendorName:
    //                 contract.seller_entity_details.seller_entity_name,
    //             value: contract.contract_items[0].rate,
    //         }));
    // }
    // return [];
    // }, [enterpriseItemUuid, listOfContract]);

    // for alex to use it
    // const dataForContractHistoricalViewRates: {
    //     contractName: string;
    //     vendorName: string;
    //     value: string;
    // }[] = useMemo(() => {
    //     if (contractDetails) {
    //         const listOFcontracts = Object.keys(contractDetails).map(
    //             (contractUid) => {
    //                 return contractDetails[contractUid];
    //             }
    //         );

    //         return listOFcontracts.map((contract) => ({
    //             contractName: contract.contract_name,
    //             vendorName:
    //                 contract.vendor_contact_information?.vendor_contact_name ??
    //                 contract.seller_entity_details.seller_entity_name,
    //             value: contract.contract_items[0].rate,
    //         }));
    //     } else {
    //         return [];
    //     }
    // }, [contractDetails]);

    const onBarClick = (contractID: string) => {
        if (handleSelectionOfContract) {
            handleSelectionOfContract(contractID);
        }
    };
    const showWarnings = useSelector(
        (state: IGlobalAppStore) =>
            state.EventCreationStore.eventDetails?.showWarnings ?? false
    );

    return (
        <>
            {(neglectStoreAndShowGraph || showWarnings || showForItem) && (
                <FWTooltip
                    title={
                        !clickedFirstTime
                            ? ''
                            : visibleData.length !== 0 ||
                              dataForContractHistoricalViewRates.length !== 0
                            ? 'Pricing analytics'
                            : 'No pricing data available for the item'
                    }
                >
                    <div>
                        <IconButton
                            // disabled={!arePoAvailable}
                            // disabled={
                            //     visibleData.length === 0 &&
                            //     dataForContractHistoricalViewRates.length ===
                            //         0 &&
                            //     clickedFirstTime
                            // }
                            // style={{ height: 'max-content', width: 'max-content' }}
                            onClick={(e) => {
                                e.stopPropagation();
                                setAnchorEl(e.currentTarget);
                                setOpen(true);
                                if (setClickedFirstTime !== undefined) {
                                    setClickedFirstTime(true);
                                }
                            }}
                            style={{
                                ...(buttonPadding
                                    ? {
                                          paddingTop: buttonPadding,
                                          paddingBottom: buttonPadding,
                                      }
                                    : {}),
                            }}
                        >
                            <img
                                style={{
                                    height: 'auto',
                                    width: '1.5rem',
                                    filter:
                                        visibleData.length === 0 &&
                                        dataForContractHistoricalViewRates.length ===
                                            0 &&
                                        clickedFirstTime
                                            ? // ? 'grayscale(100%)'
                                              'none'
                                            : 'none',
                                    opacity:
                                        visibleData.length === 0 ? '1' : '1',
                                    // we might need this conditionn in future hence for now keeping values same on both sides
                                }}
                                src="/logo.png"
                                alt="factwise logo"
                                className="EventsAddItemStage_ClickOnPricingAnalyticsIcon"
                            />
                        </IconButton>
                    </div>
                </FWTooltip>
            )}
            <FWPopover
                open={open}
                anchorEl={anchorEl}
                onClose={() => {
                    setOpen(false);
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                // sx={{ position: 'absolute', top: '-15% !important' }}
            >
                <DialogContent sx={{ m: '0px', p: 0 }}>
                    <Grid
                        container
                        columnSpacing={1}
                        rowSpacing={1}
                        style={{
                            width: visibleData.length !== 0 ? '550px' : '400px',
                        }}
                    >
                        <Grid item xs={12}>
                            {hookState.state === HookStateValue.READY ? (
                                <div className="flex flex--col">
                                    <div className="flex flex--aic flex--jcsb">
                                        <Typography
                                            variant="h5"
                                            fontWeight={500}
                                            color="secondary.main"
                                        >
                                            Historical rates
                                        </Typography>
                                        {visibleData.length !== 0 && (
                                            <Box className="flex flex--aic">
                                                <FWSelect
                                                    size={'small'}
                                                    sx={{
                                                        scale: '0.9',
                                                        width: '160px',
                                                    }}
                                                    value={
                                                        filterCurrencies
                                                            ? 'filteredCurrencies'
                                                            : 'allCurrencies'
                                                    }
                                                    onChange={() => {
                                                        toggleCurrencyFilter();
                                                    }}
                                                >
                                                    <FWMenuItem
                                                        key={'allCurrencies'}
                                                        value={'allCurrencies'}
                                                    >
                                                        All currencies
                                                    </FWMenuItem>
                                                    <FWMenuItem
                                                        key={
                                                            'filteredCurrencies'
                                                        }
                                                        value={
                                                            'filteredCurrencies'
                                                        }
                                                    >
                                                        {`${hookData.currencyDetails.currencyCodeAbbreviation} only`}
                                                    </FWMenuItem>
                                                </FWSelect>
                                                <FWSelect
                                                    size={'small'}
                                                    sx={{
                                                        scale: '0.9',
                                                        width: '160px',
                                                    }}
                                                    value={(() => {
                                                        switch (
                                                            userFilter.filterType
                                                        ) {
                                                            case 'all':
                                                                return 'all';
                                                            case 'last_x':
                                                                return userFilter.count;
                                                            case 'x_days':
                                                                return userFilter.days;
                                                            default:
                                                                break;
                                                        }
                                                    })()}
                                                    // value={180}
                                                >
                                                    {userFilterLastXOptions.map(
                                                        (option) => (
                                                            <FWMenuItem
                                                                key={option}
                                                                value={option}
                                                                selected={
                                                                    userFilter.filterType ===
                                                                        'last_x' &&
                                                                    userFilter.count ===
                                                                        option
                                                                }
                                                                onClick={() => {
                                                                    updateFilter(
                                                                        'last_x',
                                                                        option
                                                                    );
                                                                }}
                                                            >
                                                                Last {option}{' '}
                                                                rates
                                                            </FWMenuItem>
                                                        )
                                                    )}
                                                    {userFilterXDaysOptions.map(
                                                        (option) => (
                                                            <FWMenuItem
                                                                key={option}
                                                                value={option}
                                                                selected={
                                                                    userFilter.filterType ===
                                                                        'x_days' &&
                                                                    userFilter.days ===
                                                                        option
                                                                }
                                                                onClick={() => {
                                                                    updateFilter(
                                                                        'x_days',
                                                                        option
                                                                    );
                                                                }}
                                                            >
                                                                Last {option}{' '}
                                                                days
                                                            </FWMenuItem>
                                                        )
                                                    )}
                                                    <FWMenuItem
                                                        value="all"
                                                        selected={
                                                            userFilter.filterType ===
                                                            'all'
                                                        }
                                                        onClick={() => {
                                                            updateFilter('all');
                                                        }}
                                                    >
                                                        All prices
                                                    </FWMenuItem>
                                                </FWSelect>
                                            </Box>
                                        )}
                                    </div>

                                    <div style={{ width: '100%' }}>
                                        <PricingAnalyticsChart
                                            currencyCodeAbbreviation={
                                                hookData.currencyDetails
                                                    .currencyCodeAbbreviation
                                            }
                                            currencyCodeSymbol={
                                                hookData.currencyDetails
                                                    .currencySymbol
                                            }
                                            measurementUnitName={
                                                measurementUnitName
                                            }
                                            visibleData={visibleData}
                                        />
                                    </div>
                                </div>
                            ) : (
                                <Box
                                    className="flex flex--aic flex--jcc"
                                    height={'250px'}
                                >
                                    <CircularProgress />
                                </Box>
                            )}
                        </Grid>
                    </Grid>
                    {dataForContractHistoricalViewRates.length > 0 && (
                        <>
                            <Divider
                                sx={{
                                    marginY: 2,
                                    marginX: 2,
                                }}
                            />
                            <ContractBarChart
                                graphData={dataForContractHistoricalViewRates}
                                handleFilterChange={handleFilterChange}
                                aggregateOfCurrency={aggregateOfCurrency}
                                aggregateOfProject={aggregateOfProject}
                                onBarClick={onBarClick}
                            />
                        </>
                    )}
                </DialogContent>
            </FWPopover>
        </>
    );
}
