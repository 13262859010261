import { getFWDateTime } from '../../Common/Utils/DateUtils';
import { baseApiSlice } from '../../Redux/Slices/BaseApiSlice';
import { post, put } from '../../Utils/api';
import { IGeneralLedgerPayload } from '../interface/generalLedger.modal';

export const createGeneralLedgerApi = async (
    generalLedger: IGeneralLedgerPayload
) => {
    try {
        const { data } = await post(
            '/organization/general_ledger/create/',
            generalLedger
        );
        return data;
    } catch (error) {
        throw error;
    }
};

export const updateGeneralLedgerApi = async (
    glUid: string,
    generalLedger: IGeneralLedgerPayload
) => {
    try {
        const { data } = await put(
            `/organization/general_ledger/${glUid}/update/`,
            {
                ...generalLedger,
                description:
                    generalLedger.description?.trim() === ''
                        ? null
                        : generalLedger.description,
                notes:
                    generalLedger.notes?.trim() === ''
                        ? null
                        : generalLedger.notes,
                account_type:
                    generalLedger.account_type?.trim() === ''
                        ? null
                        : generalLedger.account_type,
            }
        );
        return data;
    } catch (error) {
        throw error;
    }
};

export interface GeneralLedgerApiResponse {
    general_ledger_id: string;
    buyer_entity_count: number;
    buyer_entities?: Array<{
        entity_cost_centre_id: string;
        buyer_entity_name: string;
        created_datetime: string;
        modified_datetime: string;
        deleted_datetime: any;
        status: string;
        created_by_user: string;
        modified_by_user: string;
        deleted_by_user: any;
        cost_centre: string;
        buyer_entity: string;
        currency_code: string;
    }>;
    created_datetime: string;
    modified_datetime: string;
    deleted_datetime: any;
    general_ledger_code: string;
    general_ledger_account_name: string;
    description: string;
    notes: string;
    account_type: string;
    status: string;
    custom_fields: {
        section_list: Array<any>;
    };
    created_by_user: string;
    modified_by_user: string;
    deleted_by_user: any;
    enterprise: string;
    users: {
        users: string[];
    } | null;
    cost_centres: Array<{
        cost_centre_general_ledger_linkage_id: string;
        cost_centre: {
            cost_centre_entry_id: string;
            created_datetime: string;
            modified_datetime: string;
            deleted_datetime: any;
            cost_centre_id: string;
            cost_centre_name: string;
            description: string;
            notes: string;
            validity_from: any;
            validity_to: any;
            users?: {
                users: Array<string>;
            };
            status: string;
            custom_fields: {
                section_list: Array<any>;
            };
            created_by_user: string;
            modified_by_user: string;
            deleted_by_user: any;
            enterprise: string;
        };
        created_datetime: string;
        modified_datetime: string;
        deleted_datetime: any;
        created_by_user: string;
        modified_by_user: string;
        deleted_by_user: any;
        general_ledger: string;
    }>;
}

export interface GeneralLedger {
    glUid: string;
    glCode: string;
    glAccountName: string;
    status: string;
    buyerEntityCount: number;
    entitiesNames: string[];
    createdOn: string;
    buyerEntites: {
        entity_cost_centre_id: string;
        buyer_entity_name: string;
        created_datetime: string;
        modified_datetime: string;
        deleted_datetime: any;
        status: string;
        created_by_user: string;
        modified_by_user: string;
        deleted_by_user: any;
        cost_centre: string;
        buyer_entity: string;
        currency_code: string;
    }[];
}

export interface IGeneralLedgerDashboardEntityResponse {
    data: {
        general_ledger: GeneralLedgerApiResponse;
    }[];
    metadata: {
        current_page: number;
        has_next: boolean;
        has_previous: boolean;
        total_pages: number;
        page_range: {
            start: number;
            stop: number;
        };
        count: number;
    };
    counts: {
        all: number;
    };
}

export interface IGeneralLedgerDashboardResponse {
    data: GeneralLedgerApiResponse[];
    metadata: {
        current_page: number;
        has_next: boolean;
        has_previous: boolean;
        total_pages: number;
        page_range: {
            start: number;
            stop: number;
        };
        count: number;
    };
    counts: {
        all: number;
    };
}

export interface IGeneralLedgerDashboard {
    data: GeneralLedger[];
    metadata: {
        current_page: number;
        has_next: boolean;
        has_previous: boolean;
        total_pages: number;
        page_range: {
            start: number;
            stop: number;
        };
        count: number;
    };
    counts: {
        all: number;
    };
}

export const generalLedgerApiSlice = baseApiSlice.injectEndpoints({
    endpoints: (builder) => ({
        listGeneralLedger: builder.query<
            GeneralLedger[],
            {
                entity_id?: string;
                isActive?: boolean;
            }
        >({
            query: () => '/organization/general_ledger/',
            transformResponse: (
                response: GeneralLedgerApiResponse[],
                meta,
                { entity_id, isActive }
            ) => {
                return response
                    .filter((generalLedger) => {
                        if (entity_id !== undefined && isActive !== undefined) {
                            return (
                                (
                                    generalLedger.buyer_entities?.map(
                                        (entity) => entity.buyer_entity
                                    ) ?? []
                                ).includes(entity_id) &&
                                (isActive
                                    ? generalLedger.status === 'ACTIVE'
                                    : generalLedger.status === 'INACTIVE')
                            );
                        } else if (entity_id !== undefined) {
                            return (
                                generalLedger.buyer_entities?.map(
                                    (entity) => entity.buyer_entity
                                ) ?? []
                            ).includes(entity_id);
                        } else if (isActive !== undefined) {
                            return isActive
                                ? generalLedger.status === 'ACTIVE'
                                : generalLedger.status === 'INACTIVE';
                        }

                        return true;
                    })
                    .map((generalLedger) => ({
                        createdOn: getFWDateTime(
                            generalLedger.created_datetime
                        ),
                        glUid: generalLedger.general_ledger_id,
                        buyerEntityCount: generalLedger.buyer_entity_count,
                        glCode: generalLedger.general_ledger_code,
                        glAccountName:
                            generalLedger.general_ledger_account_name,
                        status: generalLedger.status,
                        entitiesNames:
                            generalLedger.buyer_entities?.map(
                                (entity) => entity.buyer_entity_name
                            ) ?? [],
                        buyerEntites: generalLedger.buyer_entities ?? [],
                    }));
            },
        }),
        listGeneralLedgerViaDashboard: builder.mutation<
            IGeneralLedgerDashboard,
            {
                page_number?: number;
                search_text?: string;
                sort_fields?: { field: string; ascending: boolean }[];
                items_per_page?: number;
                entity_id?: string;
                tab: string;
            }
        >({
            query: ({
                page_number = 1,
                items_per_page = 10,
                search_text = '',
                sort_fields = [],
                entity_id,
                tab,
            }) => ({
                url: '/dashboard/',
                method: 'POST',
                body: {
                    dashboard_view: `${
                        entity_id ? 'entity_' : ''
                    }general_ledger`,
                    tab,
                    page_number: page_number,
                    search_text,
                    sort_fields,
                    items_per_page,
                    ...(entity_id && {
                        query_data: {
                            entity_id,
                        },
                    }),
                },
            }),
            transformResponse: (
                response:
                    | IGeneralLedgerDashboardResponse
                    | IGeneralLedgerDashboardEntityResponse,
                meta,
                args
            ) => {
                if (args.entity_id) {
                    return {
                        data: (
                            response as IGeneralLedgerDashboardEntityResponse
                        ).data.map(({ general_ledger: generalLedger }) => ({
                            createdOn: getFWDateTime(
                                generalLedger.created_datetime
                            ),
                            glUid: generalLedger.general_ledger_id,
                            buyerEntityCount: generalLedger.buyer_entity_count,
                            glCode: generalLedger.general_ledger_code,
                            glAccountName:
                                generalLedger.general_ledger_account_name,
                            status: generalLedger.status,
                            entitiesNames:
                                generalLedger.buyer_entities?.map(
                                    (entity) => entity.buyer_entity_name
                                ) ?? [],

                            buyerEntites: generalLedger.buyer_entities ?? [],
                        })),
                        metadata: response.metadata,
                        counts: response.counts,
                    };
                } else
                    return {
                        data: (
                            response as IGeneralLedgerDashboardResponse
                        ).data.map((generalLedger) => ({
                            createdOn: getFWDateTime(
                                generalLedger.created_datetime
                            ),
                            glUid: generalLedger.general_ledger_id,
                            buyerEntityCount: generalLedger.buyer_entity_count,
                            glCode: generalLedger.general_ledger_code,
                            glAccountName:
                                generalLedger.general_ledger_account_name,
                            status: generalLedger.status,
                            entitiesNames:
                                generalLedger.buyer_entities?.map(
                                    (entity) => entity.buyer_entity_name
                                ) ?? [],
                            buyerEntites: generalLedger.buyer_entities ?? [],
                        })),
                        metadata: response.metadata,
                        counts: response.counts,
                    };
            },
        }),
        getGeneralLedgerDetails: builder.query<
            GeneralLedgerApiResponse,
            string
        >({
            query: (glUid) => `/organization/general_ledger/${glUid}/`,
        }),
        getGeneralLedgerTabCount: builder.mutation<
            {
                tab_counts: {
                    active: number;
                    disabled: number;
                    all: number;
                };
            },
            {}
        >({
            query: () => ({
                url: '/dashboard/tab_counts/',
                method: 'POST',
                body: {
                    dashboard_view: 'general_ledger',
                },
            }),
        }),
    }),
});

export const {
    useListGeneralLedgerQuery,
    useGetGeneralLedgerTabCountMutation,
    useGetGeneralLedgerDetailsQuery,
    useListGeneralLedgerViaDashboardMutation,
} = generalLedgerApiSlice;

export const updateStatusOfGeneralLedger = async (
    generalLedgerUid: string,
    status: 'ACTIVE' | 'INACTIVE'
) => {
    try {
        const response = await put(
            `/organization/general_ledger/${generalLedgerUid}/update/state/`,
            {
                status,
            }
        );
        return response;
    } catch (err) {}
};
