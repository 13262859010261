import {
    Box,
    // ButtonGroup,
    Divider,
    Grid,
    InputAdornment,
    MenuItem,
    ToggleButton,
    ToggleButtonGroup,
} from '@mui/material';
import { FWCurrencyTypoGraphyWithCurrencyUid } from '../../Common/CurrencyUtilsComponent';
import { FWIconButton } from '../../Common/FWButton';
import { FWTooltip } from '../../Common/FWCustomTooltip';
import FWIcon from '../../Common/FWIcon';
import { FWInput } from '../../Common/FWInput';
import FWSelect from '../../Common/FWSelect';
import { FWTypography } from '../../Common/FWTypograhy';
import { calculateTotalAdditionalCostAndDeviationEventLevel } from '../helpers';
import {
    AllocationTypeEnum,
    AllocationTypeEnumToLabelMap,
    // CostSourceEnum,
    // CostSourceEnumToLabelMap,
    CostTypeEnum,
    CostTypeEnumToLabelMap,
    IAdditionalCost,
    IAdditionalCostWithIsRequired,
    IAdditionalCostsWithValue,
} from '../models/AdditionalCost.model';

export const AdditionalDiscountWithThreshold = ({
    localCopyOfAdditionalCost,
    updateSingleAdditionalCost,
    updateSingleAdditionalCostThresholdValue,
    updateSingleAdditionalCostThresholdAppliedOn,
    currency_code_abbreviation,
    currency_code_symbol,
    measurementUnitName,
    removeSingleAdditionalCost,
    availableAdditionalCostTemplateOptions,
    addNewAdditionalCost,
    selectedAdditionalCostTemplateOptions,
    totalAdditionalCost,
    additionalCostTemplateOptions,
    conversionRate,
    conversionPrecision,
    rfqCurrencyAbbreviation,
    displayIconNextToRate,
    isEventLevel,
    eventSubtotal,
    eventTotal,
}: {
    localCopyOfAdditionalCost: IAdditionalCostsWithValue[];
    updateSingleAdditionalCost: (costValue: string, idx: number) => void;
    updateSingleAdditionalCostThresholdValue: (
        thresholdValue: string,
        idx: number
    ) => void;
    updateSingleAdditionalCostThresholdAppliedOn: (
        thresholdAppliedOn: 'TOTAL' | 'SUBTOTAL',
        idx: number
    ) => void;
    currency_code_abbreviation: string;
    currency_code_symbol: string;
    measurementUnitName: string;
    // baseRate: number | string;
    // baseQuantity: number | string;
    removeSingleAdditionalCost: (idx: number) => void;
    availableAdditionalCostTemplateOptions: IAdditionalCost[];
    addNewAdditionalCost: () => void;
    selectedAdditionalCostTemplateOptions: (
        costName: string,
        idx: number
    ) => void;
    totalAdditionalCost: {
        additionalCostValue: number;
        effectiveRateDeviation: number;
    };
    additionalCostTemplateOptions: IAdditionalCostWithIsRequired[];
    conversionRate: number;
    conversionPrecision: number;
    rfqCurrencyAbbreviation: string;
    displayIconNextToRate?: boolean;
    iconToDisplayNextToRate?: React.ReactNode;
    isEventLevel: boolean;
    eventSubtotal: number | string;
    eventTotal: number | string;
}) => {
    // const [buttonGroupValue, setButtonGroupValue] = useState<
    //     'TOTAL' | 'SUBTOTAL'
    // >('TOTAL');

    //

    const columnSizes = {
        name: 1.7,
        type: 1.7,
        allocation: 1.7,
        // source: 1.7,
        rate: 1.7,
        threshold: 1.7,
        thresholdAppliedOn: 1.7,
        value: 1.7,
    };

    return (
        <>
            {/* header of list of addition cost */}
            <Grid container xs={12} rowSpacing={1} marginBottom={1}>
                <Grid item xs={12}>
                    <Grid container xs={12} columnSpacing={1}>
                        <Grid item xs={columnSizes.name}>
                            <FWTypography color="text.secondary">
                                Name
                            </FWTypography>
                        </Grid>
                        <Grid item xs={columnSizes.type}>
                            <FWTypography color="text.secondary">
                                Type
                            </FWTypography>
                        </Grid>
                        <Grid item xs={columnSizes.allocation}>
                            <FWTypography color="text.secondary">
                                Allocation
                            </FWTypography>
                        </Grid>
                        {/* <Grid item xs={columnSizes.source}>
                            <FWTypography color="text.secondary">
                                Source
                            </FWTypography>
                        </Grid> */}

                        <Grid item xs={columnSizes.rate}>
                            <FWTypography color="text.secondary">
                                Rate
                            </FWTypography>
                        </Grid>

                        {/* Conditional Rendering for new fields */}
                        {isEventLevel && (
                            <>
                                <Grid item xs={columnSizes.threshold}>
                                    <Box
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <FWTypography color="text.secondary">
                                            Threshold
                                        </FWTypography>
                                        <FWTooltip
                                            title={
                                                'Minimum amount to be achieved to receive a discount'
                                            }
                                        >
                                            <Box color="primary.main">
                                                <i
                                                    className="bi bi-info-circle"
                                                    style={{
                                                        width: '14px',
                                                        paddingLeft: '5px',
                                                    }}
                                                ></i>
                                            </Box>
                                        </FWTooltip>
                                    </Box>
                                </Grid>
                                <Grid item xs={columnSizes.thresholdAppliedOn}>
                                    <FWTypography color="text.secondary">
                                        Applied On
                                    </FWTypography>
                                </Grid>
                            </>
                        )}
                        <Grid
                            item
                            xs={columnSizes.value}
                            paddingLeft={isEventLevel ? 0 : 4}
                        >
                            {' '}
                            {/* Adjust padding if necessary */}
                            <FWTypography color="text.secondary">
                                Value
                            </FWTypography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container xs={12} rowSpacing={1}>
                {localCopyOfAdditionalCost?.map((cost, idx) => {
                    if (isEventLevel) {
                        if (!Boolean(cost.threshold_applied_on)) {
                            cost.threshold_applied_on = 'TOTAL';
                            updateSingleAdditionalCostThresholdAppliedOn(
                                'TOTAL',
                                idx
                            );
                        }
                    }
                    let rateError = Boolean(
                        !cost.value && cost.threshold && isEventLevel
                    );
                    let thresholdError = Boolean(
                        !cost.threshold && cost.value && isEventLevel
                    );
                    return cost.costName?.trim() !== '' ? (
                        <Grid item xs={12} key={idx}>
                            <Grid
                                container
                                xs={12}
                                columnSpacing={2}
                                alignItems={'center'}
                                style={{
                                    marginBottom: '0.5rem',
                                }}
                            >
                                <Grid item xs={columnSizes.name}>
                                    <FWTypography>{cost.costName}</FWTypography>
                                </Grid>
                                <Grid item xs={columnSizes.type}>
                                    <FWTypography>
                                        {CostTypeEnumToLabelMap[cost.costType]}
                                    </FWTypography>
                                </Grid>
                                <Grid item xs={columnSizes.allocation}>
                                    <FWTypography>
                                        {cost.costType ===
                                        CostTypeEnum.ABSOLUTE_VALUE
                                            ? cost.allocationType
                                                ? AllocationTypeEnumToLabelMap[
                                                      cost.allocationType
                                                  ]
                                                : AllocationTypeEnumToLabelMap[
                                                      AllocationTypeEnum
                                                          .OVERALL_QUANTITY
                                                  ]
                                            : 'Overall quantity'}
                                    </FWTypography>
                                </Grid>
                                {/* <Grid item xs={columnSizes.source}>
                                    <FWTypography color="text.secondary">
                                        {
                                            CostSourceEnumToLabelMap[
                                                cost.cost_source
                                                    ? cost.cost_source
                                                    : CostSourceEnum.NONE
                                            ]
                                        }
                                    </FWTypography>
                                </Grid> */}

                                <Grid item xs={columnSizes.rate}>
                                    <Box
                                        sx={{
                                            // paddingRight: '1rem',
                                            position: 'relative',
                                        }}
                                    >
                                        <FWInput
                                            sx={{
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                position: 'absolute',
                                                top: -20,
                                            }}
                                            value={cost.value}
                                            onChange={(e) => {
                                                updateSingleAdditionalCost(
                                                    e.target.value,
                                                    idx
                                                );
                                            }}
                                            allowOnly={
                                                cost.costType ===
                                                CostTypeEnum.PERCENTAGE
                                                    ? 'PERCENT'
                                                    : 'DECIMAL_NUMBER'
                                            }
                                            maxNumberOfDecimal={
                                                cost.costType ===
                                                CostTypeEnum.PERCENTAGE
                                                    ? 4
                                                    : 10
                                            }
                                            InputProps={{
                                                ...(cost.costType ===
                                                CostTypeEnum.PERCENTAGE
                                                    ? {
                                                          startAdornment: (
                                                              <InputAdornment
                                                                  position={
                                                                      'start'
                                                                  }
                                                                  sx={{
                                                                      m: '0 0.75rem',
                                                                  }}
                                                              ></InputAdornment>
                                                          ),
                                                          endAdornment: (
                                                              <InputAdornment
                                                                  position={
                                                                      'end'
                                                                  }
                                                                  sx={{
                                                                      m: '0 0.75rem',
                                                                  }}
                                                              >
                                                                  %
                                                              </InputAdornment>
                                                          ),
                                                      }
                                                    : {
                                                          startAdornment: (
                                                              <InputAdornment
                                                                  position={
                                                                      'start'
                                                                  }
                                                                  sx={{
                                                                      m: '0 0.25rem 0 0.75rem',
                                                                  }}
                                                              >
                                                                  {
                                                                      currency_code_symbol
                                                                  }
                                                              </InputAdornment>
                                                          ),
                                                          endAdornment: (
                                                              <InputAdornment
                                                                  position={
                                                                      'end'
                                                                  }
                                                                  sx={{
                                                                      m: '0 0.15rem',
                                                                  }}
                                                              >
                                                                  <FWTypography
                                                                      sx={{
                                                                          width:
                                                                              cost.allocationType ===
                                                                              AllocationTypeEnum.PER_UNIT
                                                                                  ? '45px'
                                                                                  : '0px',
                                                                      }}
                                                                  >
                                                                      {cost.allocationType ===
                                                                          AllocationTypeEnum.PER_UNIT &&
                                                                          `/${measurementUnitName}`}
                                                                  </FWTypography>
                                                              </InputAdornment>
                                                          ),
                                                      }),
                                            }}
                                            error={
                                                (additionalCostTemplateOptions.find(
                                                    (opt) =>
                                                        opt.costName?.trim() ===
                                                        cost.costName?.trim()
                                                )?.is_required &&
                                                    cost.value === '') ||
                                                rateError
                                            }
                                            helper={{
                                                text: rateError
                                                    ? 'Rate is required when threshold is applied'
                                                    : additionalCostTemplateOptions.find(
                                                          (opt) =>
                                                              opt.costName?.trim() ===
                                                              cost.costName?.trim()
                                                      )?.is_required &&
                                                      cost.value === ''
                                                    ? 'Input required'
                                                    : '',
                                            }}
                                        />
                                        {cost.value !== '' &&
                                            conversionRate !== 1 &&
                                            cost.costType !==
                                                CostTypeEnum.PERCENTAGE && (
                                                <FWTypography
                                                    // paddingY={
                                                    //     paddingForAllFields
                                                    // }
                                                    style={{
                                                        position: 'absolute',
                                                        paddingTop: '1.5rem',
                                                    }}
                                                    color={'orange'}
                                                >
                                                    {cost.allocationType ===
                                                    AllocationTypeEnum.PER_UNIT ? (
                                                        <FWCurrencyTypoGraphyWithCurrencyUid
                                                            currencyUidOrAbbrevaition={
                                                                rfqCurrencyAbbreviation
                                                            }
                                                            currency_code_abbreviation={
                                                                rfqCurrencyAbbreviation
                                                            }
                                                            value={
                                                                Number(
                                                                    cost.value
                                                                ) *
                                                                conversionRate
                                                            }
                                                            measurementUnitName={
                                                                measurementUnitName
                                                            }
                                                        />
                                                    ) : (
                                                        <FWCurrencyTypoGraphyWithCurrencyUid
                                                            currencyUidOrAbbrevaition={
                                                                rfqCurrencyAbbreviation
                                                            }
                                                            currency_code_abbreviation={
                                                                rfqCurrencyAbbreviation
                                                            }
                                                            value={
                                                                Number(
                                                                    cost.value
                                                                ) *
                                                                conversionRate
                                                            }
                                                        />
                                                    )}
                                                </FWTypography>
                                            )}
                                    </Box>
                                </Grid>

                                {displayIconNextToRate && (
                                    <Grid item xs={0.5}>
                                        {Boolean(cost.source_currency_id) && (
                                            <FWTooltip title={'test'}>
                                                <Box>
                                                    <FWIcon
                                                        name="bi bi-alarm"
                                                        color="primary.main"
                                                        size={16}
                                                    />
                                                </Box>
                                            </FWTooltip>
                                        )}
                                    </Grid>
                                )}
                                {/* <Grid item xs={0.5}>
                                    <FWTypography color="text.secondary"></FWTypography>
                                </Grid> */}
                                {isEventLevel && (
                                    <>
                                        <Grid item xs={columnSizes.threshold}>
                                            <Box
                                                sx={{
                                                    // paddingRight: '1rem',
                                                    position: 'relative',
                                                }}
                                            >
                                                <FWInput
                                                    // label="Threshold"
                                                    sx={{
                                                        position: 'absolute',
                                                        top: -20,
                                                    }}
                                                    type="DECIMAL_NUMBER"
                                                    allowOnly="DECIMAL_NUMBER"
                                                    value={cost.threshold ?? ''}
                                                    error={thresholdError}
                                                    helper={{
                                                        text: thresholdError
                                                            ? 'Threshold is required when rate is applied'
                                                            : '',
                                                    }}
                                                    onChange={(e) => {
                                                        updateSingleAdditionalCostThresholdValue(
                                                            e.target.value,
                                                            idx
                                                        );
                                                    }}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment
                                                                position={
                                                                    'start'
                                                                }
                                                                sx={{
                                                                    m: '0 0.25rem 0 0.75rem',
                                                                }}
                                                            >
                                                                {
                                                                    currency_code_symbol
                                                                }
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </Box>
                                            {cost.threshold !== '' &&
                                                cost.threshold !== null &&
                                                cost.threshold !== undefined &&
                                                conversionRate !== 1 && (
                                                    <FWTypography
                                                        // paddingY={
                                                        //     paddingForAllFields
                                                        // }
                                                        style={{
                                                            position:
                                                                'absolute',
                                                            paddingTop:
                                                                '1.5rem',
                                                        }}
                                                        color={'orange'}
                                                    >
                                                        <FWCurrencyTypoGraphyWithCurrencyUid
                                                            currencyUidOrAbbrevaition={
                                                                rfqCurrencyAbbreviation
                                                            }
                                                            currency_code_abbreviation={
                                                                rfqCurrencyAbbreviation
                                                            }
                                                            value={
                                                                Number(
                                                                    cost.threshold
                                                                ) *
                                                                conversionRate
                                                            }
                                                        />
                                                    </FWTypography>
                                                )}
                                        </Grid>
                                        <Grid
                                            item
                                            xs={columnSizes.thresholdAppliedOn}
                                        >
                                            <ToggleButtonGroup
                                                color="primary"
                                                value={
                                                    cost.threshold_applied_on
                                                }
                                                exclusive
                                                fullWidth
                                                onChange={(
                                                    event: React.MouseEvent<
                                                        HTMLElement,
                                                        MouseEvent
                                                    >,
                                                    value: 'TOTAL' | 'SUBTOTAL'
                                                ) => {
                                                    updateSingleAdditionalCostThresholdAppliedOn(
                                                        value,
                                                        idx
                                                    );
                                                    // value === 'TOTAL'
                                                    //     ? setButtonGroupValue(
                                                    //           'TOTAL'
                                                    //       )
                                                    //     : setButtonGroupValue(
                                                    //           'SUBTOTAL'
                                                    //       );
                                                }}
                                                aria-label="Platform"
                                            >
                                                <ToggleButton value="TOTAL">
                                                    <FWTypography>
                                                        Total
                                                    </FWTypography>
                                                </ToggleButton>
                                                <ToggleButton value="SUBTOTAL">
                                                    <FWTypography>
                                                        Subtotal
                                                    </FWTypography>
                                                </ToggleButton>
                                            </ToggleButtonGroup>
                                        </Grid>
                                    </>
                                )}
                                <Grid
                                    item
                                    xs={columnSizes.value}
                                    paddingLeft={4}
                                >
                                    <FWCurrencyTypoGraphyWithCurrencyUid
                                        currencyUidOrAbbrevaition={
                                            currency_code_abbreviation
                                        }
                                        currency_code_abbreviation={
                                            currency_code_abbreviation
                                        }
                                        value={
                                            calculateTotalAdditionalCostAndDeviationEventLevel(
                                                {
                                                    additionalCost: cost,
                                                    eventSubTotal:
                                                        eventSubtotal,
                                                    eventTotal: eventTotal,
                                                    type: 'Discount',
                                                }
                                            ).additionalCostValue
                                        }
                                    />
                                    {conversionRate !== 1 && (
                                        <FWTypography
                                            // paddingY={
                                            //     paddingForAllFields
                                            // }
                                            style={{
                                                position: 'absolute',
                                                paddingTop: '0.5rem',
                                            }}
                                            color={'orange'}
                                        >
                                            <FWCurrencyTypoGraphyWithCurrencyUid
                                                currencyUidOrAbbrevaition={
                                                    rfqCurrencyAbbreviation
                                                }
                                                currency_code_abbreviation={
                                                    rfqCurrencyAbbreviation
                                                }
                                                value={
                                                    calculateTotalAdditionalCostAndDeviationEventLevel(
                                                        {
                                                            additionalCost:
                                                                cost,
                                                            eventSubTotal:
                                                                eventSubtotal,
                                                            eventTotal:
                                                                eventTotal,
                                                            type: 'Discount',
                                                        }
                                                    ).additionalCostValue *
                                                    conversionRate
                                                }
                                            />
                                        </FWTypography>
                                    )}
                                </Grid>
                                {/* <Grid item xs={0.5} display={'flex'}>
                                    <FWIconButton
                                        color="error"
                                        disabled={
                                            additionalCostTemplateOptions.find(
                                                (opt) =>
                                                    opt.costName?.trim() ===
                                                    cost.costName?.trim()
                                            )?.is_required
                                        }
                                        onClick={() => {
                                            removeSingleAdditionalCost(idx);
                                        }}
                                    >
                                        <i className="bi bi-dash-circle"></i>
                                    </FWIconButton>

                                    {localCopyOfAdditionalCost?.length - 1 ===
                                        idx &&
                                        availableAdditionalCostTemplateOptions.length >
                                            0 && (
                                            <FWIconButton
                                                color="primary"
                                                onClick={() => {
                                                    addNewAdditionalCost();
                                                }}
                                            >
                                                <i className="bi bi-plus-circle"></i>
                                            </FWIconButton>
                                        )}
                                </Grid> */}
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid item xs={12} key={idx}>
                            <Grid
                                container
                                xs={12}
                                columnSpacing={2}
                                alignItems={'center'}
                            >
                                <Grid item xs={columnSizes.name}>
                                    <FWSelect
                                        fullWidth
                                        sx={{
                                            '&': {
                                                backgroundColor: 'white',
                                            },
                                        }}
                                    >
                                        {availableAdditionalCostTemplateOptions.map(
                                            (option, optionIdx) => (
                                                <MenuItem
                                                    key={optionIdx}
                                                    onClick={() => {
                                                        selectedAdditionalCostTemplateOptions(
                                                            option.costName,
                                                            idx
                                                        );
                                                    }}
                                                    value={option.costName}
                                                >
                                                    {option.costName}
                                                </MenuItem>
                                            )
                                        )}
                                    </FWSelect>
                                </Grid>
                                <Grid item xs={columnSizes.type}>
                                    <FWTypography>-</FWTypography>
                                </Grid>
                                <Grid item xs={columnSizes.allocation}>
                                    <FWTypography>-</FWTypography>
                                </Grid>
                                {/* <Grid item xs={columnSizes.source}>
                                    <FWTypography>-</FWTypography>
                                </Grid> */}

                                <Grid item xs={columnSizes.rate}>
                                    <Box
                                        sx={{
                                            paddingRight: '1rem',
                                        }}
                                    >
                                        <FWInput
                                            value={cost.value}
                                            onChange={(e) => {
                                                updateSingleAdditionalCost(
                                                    e.target.value,
                                                    idx
                                                );
                                            }}
                                            disabled
                                            allowOnly="DECIMAL_NUMBER"
                                            {...(cost.costType ===
                                            CostTypeEnum.PERCENTAGE
                                                ? {
                                                      maxNumberOfDecimal: 4,
                                                  }
                                                : {})}
                                        />
                                    </Box>
                                </Grid>

                                {isEventLevel && (
                                    <>
                                        <Grid item xs={columnSizes.threshold}>
                                            <FWTypography>-</FWTypography>
                                        </Grid>
                                        <Grid item xs={columnSizes.threshold}>
                                            <FWTypography>-</FWTypography>
                                        </Grid>
                                    </>
                                )}
                                {/* <Grid item xs={0.5}>
                                    <FWTypography color="text.secondary"></FWTypography>
                                </Grid> */}
                                <Grid
                                    item
                                    xs={columnSizes.value}
                                    // paddingLeft={4}
                                >
                                    -
                                </Grid>
                                <Grid item xs={0.5} display={'flex'}>
                                    {localCopyOfAdditionalCost?.filter(
                                        (cost) =>
                                            cost.costName?.trim().length > 0
                                    ).length > 0 && (
                                        <FWIconButton
                                            color="error"
                                            onClick={() => {
                                                removeSingleAdditionalCost(idx);
                                            }}
                                        >
                                            <i className="bi bi-dash-circle"></i>
                                        </FWIconButton>
                                    )}
                                    {localCopyOfAdditionalCost?.length - 1 ===
                                        idx &&
                                        availableAdditionalCostTemplateOptions.length >
                                            0 &&
                                        localCopyOfAdditionalCost?.filter(
                                            (cost) =>
                                                cost.costName?.trim().length ===
                                                0
                                        ).length <
                                            availableAdditionalCostTemplateOptions.length && (
                                            <FWIconButton
                                                color="primary"
                                                onClick={() => {
                                                    addNewAdditionalCost();
                                                }}
                                            >
                                                <i className="bi bi-plus-circle"></i>
                                            </FWIconButton>
                                        )}
                                </Grid>
                            </Grid>
                        </Grid>
                    );
                })}
            </Grid>

            <br />
            <Divider />
            <br />
            {/* <Grid container xs={12} rowSpacing={1} marginBottom={1}>
                <Grid item xs={12}>
                    <Grid container xs={12} columnSpacing={1}>
                        <Grid item xs={columnSizes.name}>
                            <FWTypography fontWeight="500">Total</FWTypography>
                        </Grid>
                        <Grid item xs={columnSizes.type}>
                            -
                        </Grid>
                        <Grid item xs={columnSizes.allocation}>
                            -
                        </Grid>
                        <Grid item xs={columnSizes.source}>
                            -
                        </Grid>

                        {!isEventLevel ? (
                            <Grid item xs={columnSizes.rate}>
                                <FWTypography
                                    style={{
                                        width: 'fit-content',
                                    }}
                                >
                                    <FWCurrencyTypoGraphyWithCurrencyUid
                                        currencyUidOrAbbrevaition={
                                            currency_code_abbreviation
                                        }
                                        currency_code_abbreviation={
                                            currency_code_abbreviation
                                        }
                                        value={
                                            totalAdditionalCost.effectiveRateDeviation
                                        }
                                        measurementUnitName={
                                            measurementUnitName
                                        }
                                    />
                                </FWTypography>
                                <FWTypography
                                    style={{
                                        position: 'absolute',
                                    }}
                                    color={'orange'}
                                >
                                    {conversionRate !== 1 && (
                                        <FWCurrencyTypoGraphyWithCurrencyUid
                                            currencyUidOrAbbrevaition={
                                                rfqCurrencyAbbreviation
                                            }
                                            currency_code_abbreviation={
                                                rfqCurrencyAbbreviation
                                            }
                                            value={
                                                Number(
                                                    totalAdditionalCost.effectiveRateDeviation
                                                ) * conversionRate
                                            }
                                            measurementUnitName={
                                                measurementUnitName
                                            }
                                        />
                                    )}
                                </FWTypography>
                            </Grid>
                        ) : (
                            <Grid item xs={columnSizes.rate}>
                                -
                            </Grid>
                        )}

                        {isEventLevel && (
                            <>
                                <Grid item xs={columnSizes.threshold}>
                                    <FWTypography>-</FWTypography>
                                </Grid>
                                <Grid item xs={columnSizes.threshold}>
                                    <FWTypography>-</FWTypography>
                                </Grid>
                            </>
                        )}
                        <Grid item xs={columnSizes.value}>
                            <FWTypography
                                fontWeight="500"
                                style={{
                                    width: 'fit-content',
                                }}
                            >
                                <FWCurrencyTypoGraphyWithCurrencyUid
                                    currencyUidOrAbbrevaition={
                                        currency_code_abbreviation
                                    }
                                    currency_code_abbreviation={
                                        currency_code_abbreviation
                                    }
                                    value={
                                        totalAdditionalCost.additionalCostValue
                                    }
                                />
                                <FWTypography
                                    style={{
                                        position: 'absolute',
                                    }}
                                    color={'orange'}
                                >
                                    {conversionRate !== 1 && (
                                        <FWCurrencyTypoGraphyWithCurrencyUid
                                            currencyUidOrAbbrevaition={
                                                rfqCurrencyAbbreviation
                                            }
                                            currency_code_abbreviation={
                                                rfqCurrencyAbbreviation
                                            }
                                            value={
                                                Number(
                                                    totalAdditionalCost.additionalCostValue
                                                ) * conversionRate
                                            }
                                        />
                                    )}
                                </FWTypography>
                            </FWTypography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid> */}
        </>
    );
};

export default AdditionalDiscountWithThreshold;
