import DialogContent from '@mui/material/DialogContent';
import { CircularProgress, Grid } from '@mui/material';
import { FWTypography } from './FWTypograhy';
import { Fragment, useMemo } from 'react';
import { getFWDate } from './Utils/DateUtils';
import { HookStateValue } from './Hooks/StateHook';
import FWPopover from './FWPopover';
import { useDeliverySchedule } from './Hooks/DeliveryScheduleHook';
import { isNull } from 'lodash';

interface IDeliverySchedulePopupProps {
    open: boolean;
    uidInfo: {
        id: string;
        type: 'MULTIPLE' | 'SINGLE';
    };
    anchorEl: HTMLElement | null;
    deliverySchedule?: IDeliveryScheduleList[];
    setOpen: () => void;
}

export interface IDeliveryScheduleList {
    itemName: string;
    quantity: number;
    deliveryDate: string | null;
    measurementUnit: string;
    poItemUid: string;
}

export const DeliverySchedulePopup = ({
    open,
    setOpen,
    uidInfo,
    anchorEl,
    deliverySchedule,
}: IDeliverySchedulePopupProps) => {
    let { hookState, schedule } = useDeliverySchedule(uidInfo);

    const finalDates = useMemo(() => {
        const dd = (deliverySchedule ? deliverySchedule : schedule)
            .filter((d) => !isNull(d.deliveryDate))
            .map((d) => d.deliveryDate);
        const udd = new Set(dd);
        return Array.from(udd);
    }, [deliverySchedule, schedule]);

    return (
        <FWPopover
            open={open}
            anchorEl={anchorEl}
            onClose={setOpen}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
        >
            <DialogContent sx={{ m: '0px', p: 0 }}>
                <Grid
                    container
                    columnSpacing={1.5}
                    rowSpacing={1.5}
                    style={{ width: '200px' }}
                >
                    {hookState.state === HookStateValue.LOADING &&
                    !deliverySchedule ? (
                        <Grid item md={12} className="flex flex--jcc">
                            <CircularProgress size={24} color="primary" />
                        </Grid>
                    ) : (deliverySchedule || schedule).length > 0 ? (
                        <>
                            <Grid item md={12}>
                                <FWTypography sx={{ fontWeight: 500 }}>
                                    Delivery schedule
                                </FWTypography>
                            </Grid>
                            <Grid item md={12}>
                                <FWTypography color="text.secondary">
                                    Date(s)
                                </FWTypography>
                            </Grid>
                            {finalDates.length === 0 && (
                                <Grid item md={12}>
                                    <FWTypography>TBD</FWTypography>
                                </Grid>
                            )}
                            {finalDates.map((date, idx) => (
                                <Grid item md={12} key={idx}>
                                    <FWTypography>
                                        {getFWDate(date)}
                                    </FWTypography>
                                </Grid>
                            ))}
                        </>
                    ) : (
                        <Grid item md={12}>
                            <FWTypography textAlign="center">
                                No delivery schedule available
                            </FWTypography>
                        </Grid>
                    )}
                </Grid>
            </DialogContent>
        </FWPopover>
    );
};
