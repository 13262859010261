import { Button } from '@mui/material';
import { useHistory } from 'react-router-dom';

interface IBackButtonProps {
    title?: string;
    disabled?: boolean;
    onBackClick?: () => void;
}

const BackButton = ({
    title = 'Back',
    disabled,
    onBackClick,
}: IBackButtonProps) => {
    const history = useHistory();
    const goBack = () => history.go(-1);

    return (
        <Button
            onClick={onBackClick ? onBackClick : goBack}
            startIcon={<i className="bi bi-chevron-left fs--20"></i>}
            disabled={disabled}
            sx={{
                color: 'text.secondary',
                padding: 0,
                ':hover': {
                    background: 'transparent',
                },
                textTransform: 'none',
            }}
        >
            {title}
        </Button>
    );
};

export default BackButton;
