import { get, post, put } from '../../Utils/api';
import {
    IBroadcastSessionMessage,
    IChatEvent,
    IChatNotesList,
    IChatNotesListData,
    ISession,
    ISessionMessage,
    IUserSession,
} from '../Interfaces/Chat.model';

export const getAllEventsListForBuyer = async (
    event_type: string,
    pageNo: number,
    searchText: string,
    sellerEntityUuid?: string
): Promise<{
    data: IChatEvent[];
    metadata: {
        has_next: boolean;
    };
}> => {
    // if (event_type === null) {
    //     const eventsList = await get<IChatEvent[]>(
    //         `/events/dashboard/buyer/${
    //             sellerEntityUuid
    //                 ? `?event_status=[ONGOING, PAUSED, AWARD_STAGE, PURCHASE_ORDER_ISSUED, PURCHASE_ORDER_ACCEPTED, COMPLETED, TERMINATED]&seller_entity=[${sellerEntityUuid}]`
    //                 : ``
    //         }`
    //     );
    //     return eventsList.data;
    // } else {
    //     const eventsList = await get<IChatEvent[]>(
    //         `/events/dashboard/buyer/?event_status=[${event_type}]${
    //             sellerEntityUuid ? `&seller_entity=[${sellerEntityUuid}]` : ``
    //         }`
    //     );
    //     return eventsList.data;
    // }
    const eventList = await post<any, any>(`/dashboard/`, {
        dashboard_view: 'chat_buyer',
        tab:
            sellerEntityUuid && event_type === 'all'
                ? 'all_vendors'
                : event_type,
        search_text: searchText,
        sort_fields: [],
        items_per_page: 30,
        page_number: pageNo,
        query_data: {
            ...(sellerEntityUuid
                ? { seller_entity_list: [sellerEntityUuid] }
                : {}),
        },
    });
    return eventList.data;
};

export const getAllEventsListForSeller = async (
    status: string | null,
    pageNo: number,
    searchText: string
): Promise<{
    data: IChatEvent[];
    metadata: {
        has_next: boolean;
    };
}> => {
    try {
        // let resp;
        // if (status !== null) {
        //     resp = await get<any>(
        //         `/events/dashboard/seller/?status=[${status}]`
        //     );
        // } else {
        //     resp = await get<any>(`/events/dashboard/seller/`);
        // }
        // return resp.data.map((row: any, idx: number) => ({
        //     custom_event_id: row.custom_event_id,
        //     status: row.status,
        //     event_id: row.event,
        //     event_name: row.event_name,
        //     event_type: row.event_type,
        //     live_event_start_datetime: row.live_event_start_datetime,
        //     event_end_datetime: row.event_end_datetime,
        //     buyer_entity: row.buyer_entity,
        //     additional_details: {
        //         access: 'ACCESS_DEFAULT',
        //     },
        //     sub_event_id: row.rfq_event_id,
        //     sub_event_additional_details: {
        //         item_count: row.item_count,
        //         child_rfq_id: null,
        //         vendor_count: 0,
        //         parent_rfq_id: null,
        //     },
        //     original_event_creator_name:
        //         row.event_details.original_event_creator,
        //     sub_event_creator: row.event_details.original_event_creator,
        // }));
        const eventList: any = await post<any, any>(`/dashboard/`, {
            dashboard_view: 'chat_seller',
            tab: status,
            search_text: searchText,
            sort_fields: [],
            items_per_page: 30,
            page_number: pageNo,
            query_data: {},
        });
        eventList.data.data = eventList.data.data.map((val: any) => val.event);
        return eventList.data;
    } catch (err) {
        throw err;
    }
};

export const fetchModuleUserSessions = (
    module_id: string
): Promise<IUserSession[]> => {
    return new Promise(async (resolve, reject) => {
        try {
            const resp = await get<IUserSession[]>(
                `/socket/sessions/user/?module_id=${module_id}`
            );
            const sortedSessions = resp.data
                .sort((a, b) =>
                    a.session.session_type === 'INTERNAL' ? -1 : 1
                )
                .sort((a, b) => (a.session.session_type === 'SELF' ? -1 : 1));
            resolve(sortedSessions);
        } catch (err) {
            reject();
        }
    });
};

export const fetchSessionMessages = (
    session_id: string | undefined
): Promise<ISessionMessage[]> => {
    return new Promise(async (resolve, reject) => {
        try {
            let resp = await get<ISessionMessage[]>(
                `/socket/sessions/${session_id}/messages`
            );
            resolve(resp.data);
        } catch (err) {
            reject();
        }
    });
};

export const fetchBroadcastMessages = (
    module_id: string | undefined
): Promise<IBroadcastSessionMessage[]> => {
    return new Promise(async (resolve, reject) => {
        try {
            let resp = await get<{
                broadcast_message_list: IBroadcastSessionMessage[];
            }>(`/socket/session/broadcast/messages/?module_id=${module_id}`);
            resolve(resp.data.broadcast_message_list);
            //
        } catch (err) {
            reject();
        }
    });
};

export const fetchSessionDetails = (
    session_id: string | undefined,
    signal: AbortSignal
): Promise<ISession> => {
    return new Promise(async (resolve, reject) => {
        try {
            let resp = await get<ISession>(`/socket/sessions/${session_id}/`, {
                signal,
            });
            resolve(resp.data);
        } catch (err) {
            reject(err);
        }
    });
};

export const postMuteSession = (
    session_id: string | undefined,
    mute_state: boolean // indicative of whether to mute or unmute session
): Promise<void> => {
    return new Promise(async (resolve, reject) => {
        try {
            let resp = await put<
                {
                    mute: boolean;
                },
                void
            >(`/socket/sessions/${session_id}/mute/`, {
                mute: mute_state,
            });
            resolve(resp.data);
        } catch (err) {
            reject();
        }
    });
};

export const acknowledgeSessionMessages = ({
    session_id,
    message_id,
}: {
    session_id: string;
    message_id: string;
}) => {
    return new Promise(async (resolve, reject) => {
        try {
            let resp = await put<{}, void>(
                `socket/sessions/acknowledge/update/?session_id=${session_id}&message_id=${message_id}`,
                {}
            );
            resolve(resp.data);
        } catch (err) {
            reject();
        }
    });
};

export const getMessageCount = () => {
    return new Promise(async (resolve, reject) => {
        try {
            let resp = await get<number>(`/socket/sessions/unread/`);
            resolve(resp.data);
        } catch (err) {
            reject();
        }
    });
};

export const fetchChatNotesList: (
    moduleId: string
) => Promise<IChatNotesList> = async (moduleId: string) => {
    return new Promise(async (resolve, reject) => {
        try {
            const resp: any = await get<any>(`/events/${moduleId}/notes/`);
            resolve(resp.data as IChatNotesList);
        } catch (err) {
            reject(err);
        }
    });
};

export const fetchNoteMessages: (
    moduleId: string
) => Promise<ISessionMessage[]> = async (noteId: string) => {
    return new Promise(async (resolve, reject) => {
        try {
            const resp: any = await get<any>(
                `/events/note/${noteId}/messages/`
            );
            resolve(resp.data as ISessionMessage[]);
        } catch (err) {
            reject(err);
        }
    });
};

export const sendNotesMessage: (
    noteId: string,
    message: string
) => Promise<ISessionMessage> = async (noteId: string, message: string) => {
    return new Promise(async (resolve, reject) => {
        try {
            const resp: any = await post<any, ISessionMessage>(
                `/events/note/${noteId}/add/`,
                {
                    message,
                }
            );
            resolve(resp.data as ISessionMessage);
        } catch (err) {
            reject(err);
        }
    });
};

export const createNewCustomNote = async (
    moduleId: string,
    noteName: string
): Promise<IChatNotesListData> => {
    return new Promise(async (resolve, reject) => {
        try {
            const resp: any = await post<any, any>(
                `/events/${moduleId}/note/create/`,
                {
                    title: noteName,
                    resource_type: 'CUSTOM',
                }
            );
            resolve(resp.data as IChatNotesListData);
        } catch (err) {
            reject(err);
        }
    });
};
