import React from 'react';
import VLNProfileAboutMainInfo from '../../Components/Profile/About/VLNProfileAboutMainInfo';
import VLNProfileAboutReviews from '../../Components/Profile/About/VLNProfileAboutReviews';
import { IEntityDetails } from '../../../Global/Interfaces/EntityInterface';
import { IVLNAbout } from '../../Interfaces/VendorLNInterfaces';
import { Box, Grid } from '@mui/material';
import VLNProfileAboutOtherInfoCompetency from '../../Components/Profile/About/VLNProfileAboutOtherInfoCompetency';
import VLNProfileAboutQualityandComplianceComponent from '../../Components/Profile/About/VLNProfileAboutQualityandComplianceComponent';
import { FWTypography } from '../../../Common/FWTypograhy';
import VLNProfileAboutCustomSectionItem from '../../Components/Profile/About/VLNProfileAboutCustomSectionItem';
import VLNProfileAboutOtherInfoLocations from '../../Components/Profile/About/VLNProfileAboutOtherInfoLocations';
import VLNProfileAboutOtherInfoEmployees from '../../Components/Profile/About/VLNProfileAboutOtherInfoEmployees';

const VLNProfileAboutSection = ({
    sellerEntityDetails,
    vendorProfileDetails,
}: {
    sellerEntityDetails: IEntityDetails;
    vendorProfileDetails: IVLNAbout;
}) => {
    return (
        <>
            <Grid container spacing={4}>
                <Grid item xs={7}>
                    <Box marginBottom={4}>
                        <VLNProfileAboutMainInfo
                            sellerEntityDetails={sellerEntityDetails}
                            productTags={vendorProfileDetails.product_tags}
                        />
                    </Box>

                    <Box marginBottom={4}>
                        <VLNProfileAboutOtherInfoCompetency
                            competencyList={vendorProfileDetails.competency}
                        />
                    </Box>

                    <Box marginBottom={4}>
                        <VLNProfileAboutQualityandComplianceComponent
                            qNcData={
                                vendorProfileDetails.quality_and_compliance
                            }
                        />
                    </Box>

                    {vendorProfileDetails.custom_section.length > 0 && (
                        <FWTypography variant={'h4'} fontWeight={500}>
                            Additional info
                        </FWTypography>
                    )}

                    {vendorProfileDetails.custom_section.map((data, idx) => (
                        <VLNProfileAboutCustomSectionItem
                            customSectionData={data}
                        />
                    ))}
                </Grid>
                <Grid item xs={5}>
                    <Box marginBottom={4}>
                        <VLNProfileAboutOtherInfoLocations
                            locationsList={
                                vendorProfileDetails.factory_locations
                            }
                        />
                    </Box>

                    <Box marginBottom={4}>
                        <VLNProfileAboutOtherInfoEmployees
                            employeesList={vendorProfileDetails.employees}
                        />
                    </Box>
                </Grid>
            </Grid>
            {false && <VLNProfileAboutReviews />}
        </>
    );
};

export default VLNProfileAboutSection;
