import { useEffect, useState, useRef, MutableRefObject } from 'react';

export const useLazyLoadList = (
    containerSelector: string
): [boolean, MutableRefObject<Element>] => {
    const [loaded, setLoaded] = useState(false);
    const elRef = useRef() as MutableRefObject<Element>;

    useEffect(() => {
        let observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    const { isIntersecting } = entry;
                    if (isIntersecting) {
                        setLoaded(true);
                        observer.disconnect();
                    } else {
                        setLoaded(false);
                    }
                });
            },
            {
                root: document.querySelector(containerSelector),
            }
        );
        if (elRef.current) {
            observer.observe(elRef.current);
        }
    }, [containerSelector]);

    return [loaded, elRef];
};
