import { Box } from '@mui/material';
import React from 'react';
import ChatEventDisplayContainer from './ChatEventDisplayContainer';
import ChatSessionAndMessageContainer from './ChatSessionAndMessageContainer';

interface IChatContainer {
    user_type: 'buyer' | 'seller';
    sellerEntityUuid?: string;
}

export default function ChatContainer(props: IChatContainer) {
    const [currentlySelectedEventID, setCurrentlySelectedEventID] =
        React.useState<{
            event_id: string;
            sub_event_id: string;
        } | null>(null);

    const [currentlySelectedEventStatus, setCurrentlySelectedEventStatus] =
        React.useState<string>('');

    const [showEventsSection, setShowEventsSection] =
        React.useState<boolean>(true);

    return (
        <Box
            display="grid"
            flex={1}
            gridTemplateColumns={
                props.user_type === 'buyer'
                    ? props.sellerEntityUuid
                        ? `max(25%, 300px) auto`
                        : showEventsSection
                        ? 'max(25%, 300px) max(25%, 300px) auto'
                        : '0 max(25%, 300px) auto'
                    : 'max(25%, 300px) auto'
            }
            gridTemplateRows={
                props.sellerEntityUuid
                    ? 'calc(100vh - 265px)'
                    : 'calc(100vh - 117px)'
            }
            sx={{ transition: 'all 0.2s ease-in-out' }}
        >
            <ChatEventDisplayContainer
                setCurrentlySelectedEventStatus={
                    setCurrentlySelectedEventStatus
                }
                updateEventID={(newEventID: string, newSubEventID: string) =>
                    setCurrentlySelectedEventID((prev) =>
                        prev?.event_id !== newEventID ||
                        prev?.sub_event_id !== newSubEventID
                            ? {
                                  event_id: newEventID,
                                  sub_event_id: newSubEventID,
                              }
                            : prev
                    )
                }
                currentlySelectedEventID={
                    currentlySelectedEventID === null
                        ? null
                        : currentlySelectedEventID?.event_id
                }
                user_type={props.user_type}
                setShowEventsSection={setShowEventsSection}
                sellerEntityUuid={props.sellerEntityUuid}
            />
            {currentlySelectedEventID !== null && (
                <ChatSessionAndMessageContainer
                    selectedEventID={currentlySelectedEventID}
                    user_type={props.user_type}
                    openedFromPopup={false}
                    currentlySelectedEventStatus={currentlySelectedEventStatus}
                    currentlySelectedEventID={
                        currentlySelectedEventID === null
                            ? null
                            : currentlySelectedEventID?.event_id
                    }
                    isSessionContainerOpen={true}
                    showEventsSection={showEventsSection}
                    setShowEventsSection={setShowEventsSection}
                    sellerEntityUuid={props.sellerEntityUuid}
                />
            )}
        </Box>
    );
}
