import Typography, { TypographyProps } from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import React from 'react';
import { FWTooltip } from './FWCustomTooltip';

const CustomTypography = styled(Typography)<TypographyProps>(({ theme }) => ({
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
}));

export const getInitials = (name: string) => {
    if (name.length === 0) {
        return '';
    }
    let nameArr = name.split(' ').filter((namepart) => namepart.length > 0);

    return nameArr.map((namePart) => namePart[0].toUpperCase()).join('');
};

export const FWTypography = React.forwardRef(
    (
        props: {
            hideTooltip?: boolean;
            paddingLeft?: string | number;
        } & TypographyProps,
        ref: any
    ) => {
        const textElementRef = React.useRef<HTMLSpanElement>(null);
        const [hoverStatus, setHover] = React.useState(false);
        const { hideTooltip = false, paddingLeft } = props;
        const compareSize = React.useCallback(() => {
            // setTimeout(() => {
            // }, 100);
            if (
                typeof textElementRef.current !== typeof undefined &&
                textElementRef.current
            ) {
                const compare =
                    textElementRef.current.scrollWidth >
                    textElementRef.current.clientWidth;
                setHover(compare);
            }
        }, []);

        React.useEffect(() => {
            if (textElementRef && textElementRef.current) {
                new ResizeObserver(compareSize).observe(textElementRef.current);
            }

            // compareSize();
            // window.addEventListener('resize', compareSize);

            // return () => {
            //     window.removeEventListener('resize', compareSize);
            // };
        }, [textElementRef, compareSize]);

        React.useEffect(() => {
            compareSize();
        }, [compareSize, props]);

        return (
            <FWTooltip
                title={
                    typeof props.children === typeof undefined || hideTooltip
                        ? ''
                        : (props.children as any)
                }
                disableHoverListener={!hoverStatus}
            >
                <CustomTypography
                    ref={textElementRef}
                    paddingLeft={paddingLeft}
                    {...props}
                />
            </FWTooltip>
        );
    }
);
