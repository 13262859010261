import { useMemo, useState } from 'react';
import { IVLNAboutCertificate } from '../Interfaces/VendorLNInterfaces';

export const useVLNCertifications = (
    certificationsList: IVLNAboutCertificate[]
) => {
    const [selectedFilter, setSelectedFilter] = useState<string>('All');

    const filteredCertificates = useMemo(() => {
        if (selectedFilter === 'All') {
            return certificationsList;
        }
        return certificationsList.filter((certificate) => {
            return certificate.files.filter((certification) => {
                const fileType = certification.file_name.split('.').pop()!;

                return fileType === selectedFilter;
            });
        });
        // return certificationsList.filter((certification) => {
        //     const fileType = certification.file_name.split('.').pop()!;
        //
        //     return fileType === selectedFilter;
        // });
    }, [certificationsList, selectedFilter]);

    const filters = useMemo(() => {
        let filters: { [key: string]: number } = {};
        certificationsList.forEach((certificate) => {
            certificate.files.forEach((certification) => {
                const fileType = certification.file_name.split('.').pop()!;
                if (filters[fileType]) {
                    filters[fileType] += 1;
                    return;
                }
                filters[fileType] = 1;
            });
        });
        // certificationsList.forEach((certification) => {
        //     const fileType = certification.file_name.split('.').pop()!;
        //     if (filters[fileType]) {
        //         filters[fileType] += 1;
        //         return;
        //     }
        //     filters[fileType] = 1;
        // });
        filters.All = certificationsList.length;
        return filters;
    }, [certificationsList]);

    const handleFilterChange = (filter: string) => {
        setSelectedFilter(filter);
    };

    return {
        filteredCertificates,
        filters,
        handleFilterChange,
        selectedFilter,
    };
};
