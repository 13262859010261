import { Box, styled, SxProps } from '@mui/material';

interface IErrorRippleProps {
    top: string;
    left: string;
    color: 'warning' | 'error' | 'primary' | 'success';
    sx?: SxProps;
}

const CustomBox = styled(Box)(({ theme }) => ({
    '&.warning': {
        '&::before': {
            backgroundColor: theme.palette.warning.main,
        },
    },
    '&.error': {
        '&::before': {
            backgroundColor: theme.palette.error.main,
        },
    },
    '&.primary': {
        '&::before': {
            backgroundColor: theme.palette.primary.main,
        },
    },
    '&.success': {
        '&::before': {
            backgroundColor: theme.palette.success.main,
        },
    },
}));

const FWErrorRipple = ({ top, left, color, sx }: IErrorRippleProps) => {
    return (
        <CustomBox
            className={'ripple ' + color}
            sx={{
                ...sx,
                '&:before': {
                    top,
                    left,
                },
            }}
        ></CustomBox>
    );
};

export default FWErrorRipple;
