import { cloneDeep } from 'lodash';
import { IUserDetails } from '../../../Models/UserDetails.model';
import {
    IUserProfileError,
    IUserProfileKeys,
} from '../Interfaces/UserProfile.model';

const REGEX =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const validateUserProfileDetails = (
    field: IUserProfileKeys,
    userProfileDetails: IUserDetails,
    error: IUserProfileError
) => {
    let newError: any = cloneDeep(error);

    let field_keys = field.split('_');
    if (field === 'name' || field === 'ALL') {
        if (userProfileDetails.name.trim() === '') {
            newError['name'] = {
                type: 'ERROR',
                msg: 'Please enter a valid first name',
            };
        } else {
            delete newError['name'];
        }
    }

    if (field === 'ALL') {
        let idx = 0;

        // TODO: Removing error check for phone numbers as we will currently always display just 1 field and allow user to leave it blank.

        // for (let phone of userProfileDetails.contacts!.phone_numbers) {
        //     let field = `phone_${idx++}`;
        //     if (phone.phone === '') {
        //         newError[field] = {
        //             type: 'ERROR',
        //             msg: 'Please enter a valid phone number',
        //         };
        //     } else {
        //         delete newError[field];
        //     }
        // }

        for (let email of userProfileDetails.contacts!.emails) {
            let field = `phone_${idx++}`;
            if (!String(email.email).toLowerCase().match(REGEX)) {
                newError[field] = {
                    type: 'ERROR',
                    msg: 'Please enter a valid email',
                };
            } else {
                delete newError[field];
            }
        }
    }

    //TODO: Removing this validation as we will allow blank phone number for now. We will get rid of the blank when saving.
    // if (field_keys.length === 2 && field_keys[0] === 'phone') {
    //     if (
    //         userProfileDetails.contacts!.phone_numbers[+field_keys[1]].phone ===
    //         ''
    //     ) {
    //         newError[field] = {
    //             type: 'ERROR',
    //             msg: 'Please enter a valid phone number',
    //         };
    //     } else {
    //         delete newError[field];
    //     }
    // }

    if (field_keys.length === 2 && field_keys[0] === 'email') {
        if (
            !String(userProfileDetails.contacts!.emails[+field_keys[1]].email)
                .toLowerCase()
                .match(REGEX)
        ) {
            newError[field] = {
                type: 'ERROR',
                msg: 'Please enter a valid email',
            };
        } else {
            delete newError[field];
        }
    }

    if (field === 'default_entity' || field === 'ALL') {
        if (userProfileDetails.defaults!.primary_entity_id === '') {
            newError['default_entity'] = {
                type: 'ERROR',
                msg: 'Please select a valid entity',
            };
        } else {
            delete newError['default_entity'];
        }
    }

    if (field === 'default_homepage' || field === 'ALL') {
        if (userProfileDetails.defaults!.homepage === '') {
            newError['default_homepage'] = {
                type: 'ERROR',
                msg: 'Please select a valid homepage',
            };
        } else {
            delete newError['default_homepage'];
        }
    }

    if (field === 'default_number_format' || field === 'ALL') {
        if (userProfileDetails.defaults!.number_format === '') {
            newError['default_number_format'] = {
                type: 'ERROR',
                msg: 'Please select a valid number format',
            };
        } else {
            delete newError['default_number_format'];
        }
    }
    return newError;
};
