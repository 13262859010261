import { Decimal } from 'decimal.js';
import {
    calculateAddtionalCost,
    calculateTotalAdditionalCostAndDeviation,
} from '../../AdditionalCost/helpers';
import {
    AllocationTypeEnum,
    IAdditionalCostsBackend,
    IAdditionalCostsWithValue,
    IDiscountWithValue,
} from '../../AdditionalCost/models/AdditionalCost.model';

export const calculateItemTotal = (
    item_qty: string | number,
    item_price: string | number,
    shipping_per_unit: string | number,
    tax_list: { charge_name: string; charge_value: string | number }[]
) => {
    /*
        This function calculates the item level total using the quantity, price, taxes and shipping.    
    */
    let item_subtotal = new Decimal(+item_qty).mul(+item_price).toNumber();
    let total_taxes = 0;
    for (let tax of tax_list)
        total_taxes = new Decimal(total_taxes)
            .plus(+tax.charge_value)
            .toNumber();
    if (total_taxes > 0) {
        item_subtotal = new Decimal(item_subtotal)
            .mul(
                new Decimal(1)
                    .plus(new Decimal(total_taxes).dividedBy(100).toNumber())
                    .toNumber()
            )
            .toNumber();
    }
    if (+shipping_per_unit > 0) {
        let total_shipping = new Decimal(+item_qty)
            .mul(+shipping_per_unit)
            .toNumber();
        item_subtotal = new Decimal(item_subtotal)
            .plus(total_shipping)
            .toNumber();
    }
    return item_subtotal;
};

export const calculateNewItemTotal = (
    item_qty: string | number,
    item_price: string | number,
    additionCost: IAdditionalCostsWithValue[],
    discount: IDiscountWithValue[]
) => {
    /*
        This function calculates the item level total using the quantity, price, taxes and shipping.    
    */
    const { effectiveRateDeviation: effectiveRateDeviationAdditionalCost } =
        additionCost.reduce(
            (acc, curr) => {
                const { additionalCostValue, effectiveRateDeviation } =
                    calculateTotalAdditionalCostAndDeviation({
                        additionalCost: curr,
                        baseRate: item_price,
                        baseQuantity: item_qty,
                    });

                return {
                    additionalCostValue:
                        acc.additionalCostValue + additionalCostValue,
                    effectiveRateDeviation:
                        acc.effectiveRateDeviation + effectiveRateDeviation,
                };
            },
            { additionalCostValue: 0, effectiveRateDeviation: 0 }
        );

    const { effectiveRateDeviation: effectiveRateDeviationDiscount } =
        discount.reduce(
            (acc, curr) => {
                const { additionalCostValue, effectiveRateDeviation } =
                    calculateTotalAdditionalCostAndDeviation({
                        additionalCost: curr,
                        baseRate: item_price,
                        baseQuantity: item_qty,
                    });

                return {
                    additionalCostValue:
                        acc.additionalCostValue + additionalCostValue,
                    effectiveRateDeviation:
                        acc.effectiveRateDeviation + effectiveRateDeviation,
                };
            },
            { additionalCostValue: 0, effectiveRateDeviation: 0 }
        );

    const effectiveRateDeviation =
        effectiveRateDeviationAdditionalCost - effectiveRateDeviationDiscount;

    return +item_qty * (+item_price + effectiveRateDeviation);
};

export const calculateNewItemTotalWithDecimalPlaces = ({
    itemDS,
    item_price,
    decimalPlaces,
    additional_cost = [],
    discount = [],
    tax = [],
}: {
    itemDS: { quantity: string | number }[];
    item_price: number | string;
    decimalPlaces: number;
    tax: IAdditionalCostsWithValue[];
    additional_cost: IAdditionalCostsWithValue[];
    discount: IAdditionalCostsWithValue[];
}) => {
    let totalQuantity = itemDS.reduce(
        (sum, singleDS) => sum + +singleDS.quantity,
        0
    );
    let effectiveRate = calculateNewItemEffectiveRateWithDecimal({
        itemDS,
        item_price,
        decimalPlaces,
        additional_cost,
        discount,
        tax,
    });
    return isNaN(totalQuantity * effectiveRate)
        ? 0
        : totalQuantity * effectiveRate;
};

export const calculateItemEffectiveRate = (
    item_price: string | number,
    shipping_per_unit: string | number,
    tax_list: { charge_name: string; charge_value: string | number }[]
) => {
    /*
        This function calculates the effective item rate using the taxes and shipping.(by assuming quantity as 1)    
    */
    let item_subtotal = new Decimal(1).mul(+item_price).toNumber();
    let total_taxes = 0;
    for (let tax of tax_list)
        total_taxes = new Decimal(total_taxes)
            .plus(+tax.charge_value)
            .toNumber();
    if (total_taxes > 0) {
        item_subtotal = new Decimal(item_subtotal)
            .mul(
                new Decimal(1)
                    .plus(new Decimal(total_taxes).dividedBy(100).toNumber())
                    .toNumber()
            )
            .toNumber();
    }
    if (+shipping_per_unit > 0) {
        let total_shipping = new Decimal(1).mul(+shipping_per_unit).toNumber();
        item_subtotal = new Decimal(item_subtotal)
            .plus(total_shipping)
            .toNumber();
    }
    return item_subtotal;
};

export const calculateNewItemEffectiveRate = (
    item_price: string | number,
    item_qty: string | number,
    additionCost: IAdditionalCostsWithValue[],
    discount: IDiscountWithValue[]
) => {
    const { effectiveRateDeviation: effectiveRateDeviationAdditionalCost } =
        additionCost.reduce(
            (acc, curr) => {
                const { additionalCostValue, effectiveRateDeviation } =
                    calculateTotalAdditionalCostAndDeviation({
                        additionalCost: curr,
                        baseRate: item_price,
                        baseQuantity: item_qty,
                    });

                return {
                    additionalCostValue:
                        acc.additionalCostValue + additionalCostValue,
                    effectiveRateDeviation:
                        acc.effectiveRateDeviation + effectiveRateDeviation,
                };
            },
            { additionalCostValue: 0, effectiveRateDeviation: 0 }
        );

    const { effectiveRateDeviation: effectiveRateDeviationDiscount } =
        discount.reduce(
            (acc, curr) => {
                const { additionalCostValue, effectiveRateDeviation } =
                    calculateTotalAdditionalCostAndDeviation({
                        additionalCost: curr,
                        baseRate: item_price,
                        baseQuantity: item_qty,
                    });

                return {
                    additionalCostValue:
                        acc.additionalCostValue + additionalCostValue,
                    effectiveRateDeviation:
                        acc.effectiveRateDeviation + effectiveRateDeviation,
                };
            },
            { additionalCostValue: 0, effectiveRateDeviation: 0 }
        );

    const effectiveRateDeviation =
        effectiveRateDeviationAdditionalCost - effectiveRateDeviationDiscount;

    return +item_price + effectiveRateDeviation;
};

export const calculateNewItemEffectiveRateWithDecimal = ({
    itemDS,
    item_price,
    decimalPlaces,
    additional_cost = [],
    discount = [],
    tax = [],
}: {
    itemDS: { quantity: string | number }[];
    item_price: number | string;
    decimalPlaces: number;
    tax: IAdditionalCostsWithValue[];
    additional_cost: IAdditionalCostsWithValue[];
    discount: IAdditionalCostsWithValue[];
}) => {
    try {
        let totalQuantity = itemDS.reduce(
            (sum, singleDS) => sum + +singleDS.quantity,
            0
        );
        if (item_price === 0) {
            return 0;
        }
        const additionalCost = calculateAddtionalCost({
            additionalCost: additional_cost,
            baseQuantity: totalQuantity,
            baseRate: item_price,
            type: 'ADDITIONAL_COST',
        });
        // additional_cost.reduce(
        //     (acc, singleAdditionalCost) => {
        //         const currrAdditionalCost = calculateAddtionalCost({
        //             additionalCost: singleAdditionalCost,
        //             baseQuantity: totalQuantity,
        //             baseRate: item_price,
        //         });
        //         return {
        //             effectiveRateDeviation:
        //                 acc.effectiveRateDeviation +
        //                 currrAdditionalCost.effectiveRateDeviation,
        //             additionalCostValue:
        //                 acc.additionalCostValue +
        //                 currrAdditionalCost.additionalCostValue,
        //         };
        //     },
        //     {
        //         effectiveRateDeviation: 0,
        //         additionalCostValue: 0,
        //     }
        // );

        const discountAdditioncalCost = calculateAddtionalCost({
            additionalCost: discount,
            baseQuantity: totalQuantity,
            baseRate: item_price,
            type: 'DISCOUNT',
        });
        // discount.reduce(
        //     (acc, singleAdditionalCost) => {
        //         const currrAdditionalCost = calculateAddtionalCost({
        //             additionalCost: singleAdditionalCost,
        //             baseQuantity: totalQuantity,
        //             baseRate: item_price,
        //         });
        //         return {
        //             effectiveRateDeviation:
        //                 acc.effectiveRateDeviation +
        //                 currrAdditionalCost.effectiveRateDeviation,
        //             additionalCostValue:
        //                 acc.additionalCostValue +
        //                 currrAdditionalCost.additionalCostValue,
        //         };
        //     },
        //     {
        //         effectiveRateDeviation: 0,
        //         additionalCostValue: 0,
        //     }
        // );

        const TaxAdditionalCost = calculateAddtionalCost({
            additionalCost: tax,
            baseQuantity: totalQuantity,
            baseRate: item_price,
            type: 'TAX',
            discountAdditionalCost: discount,
        });
        // tax.reduce(
        //     (acc, singleAdditionalCost) => {
        //         const currrAdditionalCost = calculateAddtionalCost({
        //             additionalCost: singleAdditionalCost,
        //             baseQuantity: totalQuantity,
        //             baseRate:
        //                 +item_price -
        //                 discountAdditioncalCost.effectiveRateDeviation,
        //         });
        //         return {
        //             effectiveRateDeviation:
        //                 acc.effectiveRateDeviation +
        //                 currrAdditionalCost.effectiveRateDeviation,
        //             additionalCostValue:
        //                 acc.additionalCostValue +
        //                 currrAdditionalCost.additionalCostValue,
        //         };
        //     },
        //     {
        //         effectiveRateDeviation: 0,
        //         additionalCostValue: 0,
        //     }
        // );

        // const subTotal = +item_price * 0;
        let newItemSubtotal =
            (+item_price - discountAdditioncalCost.effectiveRateDeviation) *
            totalQuantity;

        let final =
            totalQuantity > 0
                ? (newItemSubtotal +
                      additionalCost.additionalCostValue +
                      TaxAdditionalCost.additionalCostValue) /
                  totalQuantity
                : 0;
        return final > 0 ? final : 0;
    } catch (err) {
        return 0;
    }
};

// newest function to be used

export const calculateNewItemSubtotalWithDecimal = ({
    itemDS,
    item_price,
    decimalPlaces,
    discount = [],
}: {
    itemDS: { quantity: string | number }[];
    item_price: number | string;
    decimalPlaces: number;
    discount: IAdditionalCostsWithValue[];
}) => {
    let totalQuantity = itemDS.reduce(
        (sum, singleDS) => sum + +singleDS.quantity,
        0
    );

    if (item_price === 0) {
        return 0;
    }

    const discountAdditioncalCost = calculateAddtionalCost({
        additionalCost: discount,
        baseQuantity: totalQuantity,
        baseRate: item_price,
        type: 'DISCOUNT',
    });

    let newItemSubtotal =
        (+item_price - discountAdditioncalCost.effectiveRateDeviation) *
        totalQuantity;

    return newItemSubtotal > 0 ? newItemSubtotal : 0;
};

export const convertIAdditionalCostBackendToIAdditionalCostWithValue = (
    costList: IAdditionalCostsBackend[]
) => {
    let formattedCostList = costList.map((cost) => {
        let newObj: IAdditionalCostsWithValue = {
            allocationType: cost.allocation_type as AllocationTypeEnum,
            additional_cost_id: cost.additional_cost_id,
            cost_source: cost.cost_source,

            costName: cost.cost_name,
            costType: cost.cost_type,
            value: cost.cost_value,
        };

        return newObj;
    });

    return formattedCostList;
};
