import { Fragment } from 'react';
import { FWTypography } from '../../../Common/FWTypograhy';
import { stringToArray } from '../../../Common/Utils/ArrayUtils';

interface ICommaSeparatedLinksProps {
    value: string;
    type?: string;
    noLink?: boolean;
}

const CommaSeparatedLinks = ({
    value,
    type,
    noLink,
}: ICommaSeparatedLinksProps) => {
    const data = stringToArray(value);

    const items = data.map((item) => {
        if (type) return item;
        if (item.includes('http')) {
            return 'http://' + item.split('//')[1];
        }
        return 'http://' + item;
    });

    return (
        <>
            {items.map((item, idx) => {
                return (
                    <Fragment key={idx}>
                        {noLink ? (
                            <span>{item.trim()}</span>
                        ) : (
                            <FWTypography
                                variant="caption"
                                color={!noLink ? 'primary' : ''}
                            >
                                <a
                                    href={`${type ? type + ':' : ''}${item}`}
                                    target="_blank"
                                    rel="noreferrer"
                                    style={{
                                        fontSize: '16px',
                                        color: noLink
                                            ? 'black'
                                            : 'currentColor',
                                        textDecoration: noLink ? 'none' : '',
                                    }}
                                >
                                    {item.trim()}
                                </a>
                            </FWTypography>
                        )}
                        {idx < data.length - 1 ? ', ' : ''}
                    </Fragment>
                );
            })}
        </>
    );
};

export default CommaSeparatedLinks;
