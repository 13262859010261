import { IHistoricalAnalyticsChart } from '../Interfaces/IHistoricalAnalytics.model';
import {
    getEventStartDateFilter,
    getItemFilterCategory,
    getPoIssueDateFilter,
    getUserFilterCategory,
    getVendorFilterCategory,
} from './CommonFilters';
import {
    getStandardEventColumns,
    getStandardInvoiceItemColumns,
    getStandardPoColumns,
    getStandardPoItemColumns,
} from './DownloadColumns';

export const HA_Users_PurchaseOrderValue: IHistoricalAnalyticsChart = {
    chartId: 'HA_Users_PurchaseOrderValue',
    title: 'PO Value by Creator',
    downloadSettings: {
        object: 'po',
        columns: getStandardPoColumns(),
    },
    chartType: 'bar',
    axisLabelY: 'PO Value',
    axisLabelX: 'User',
    currencySettings: {
        showTargetCurrency: true,
        targetCurrency: null,
        filterCurrenciesIds: [],
    },
    filterContoller: {
        fixedFilters: [
            {
                filterId: '',
                filterType: 'notNull',
                filterLabel: '',
                filterFields: {
                    metricFieldType: 'universal',
                    fieldName: 'po__purchase_order_id',
                },
                filterApplied: true,
            },
            {
                filterId: '',
                filterType: 'multiselectDropdown',
                filterLabel: '',
                filterFields: {
                    metricFieldType: 'universal',
                    fieldName: 'po__status',
                },
                filterApplied: true,
                modifierType: 'CONSOLIDATED',
                modifierValue: 'IN',
                values: ['ISSUED', 'ONGOING', 'TERMINATED', 'COMPLETED'],
            },
            {
                filterId: '',
                filterType: 'multiselectDropdown',
                filterLabel: '',
                filterFields: {
                    metricFieldType: 'universal',
                    fieldName: 'po__purchase_order_type',
                },
                filterApplied: true,
                modifierType: 'CONSOLIDATED',
                modifierValue: 'NOT_IN',
                values: ['STAGING'],
            },
        ],
        customizableFilters: {
            categoryConnector: 'AND',
            categories: [
                {
                    categoryLabel: 'Date Range',
                    filterConnector: 'AND',
                    filters: [getPoIssueDateFilter({})],
                    expanded: true,
                },
                getItemFilterCategory(),
                getVendorFilterCategory(),
                getUserFilterCategory({
                    event__original_event_creator: 'APPLICABLE',
                    po__created_by_user: 'DEFAULT',
                    po__final_buyer_approval: 'APPLICABLE',
                    rfq_event__final_approver: 'APPLICABLE',
                }),
            ],
        },
    },
    metrics: [
        {
            metricId: 'poValue',
            display: 'bar',
            displayModifier: 'currency',
            metricLegendLabel: 'PO Value',
            metricDefinition: {
                metricType: 'base',
                metricField: 'po__pricing_information__total',
                aggregation: 'sum',
            },
        },
    ],
    groupBy: [
        {
            type: 'str',
            field: 'event__original_event_creator',
            includeInGroupName: false,
        },
        {
            type: 'str',
            field: 'event__original_event_creator__name',
            includeInGroupName: true,
        },
    ],
    groupSort: {
        sortBy: 'value',
        sortOrder: 'desc',
    },
    groupFilter: {
        filterBy: 'top',
        filterNumber: 10,
        customFilter: null,
    },
};

export const HA_Users_RfqCounts: IHistoricalAnalyticsChart = {
    chartId: 'HA_Users_RfqCounts',
    title: 'User RFQ Events',
    downloadSettings: {
        object: 'event',
        columns: getStandardEventColumns(),
    },
    chartType: 'bar',
    axisLabelY: 'Event Count',
    axisLabelX: 'User',
    currencySettings: {
        showTargetCurrency: false,
        targetCurrency: null,
        filterCurrenciesIds: [],
    },
    filterContoller: {
        fixedFilters: [
            {
                filterId: '',
                filterType: 'multiselectDropdown',
                filterLabel: '',
                filterFields: {
                    metricFieldType: 'universal',
                    fieldName: 'event__event_type',
                },
                filterApplied: true,
                modifierType: 'CONSOLIDATED',
                modifierValue: 'IN',
                values: ['RFQ'],
            },
            // Not sure why the filter is required
            // {
            //     filterId: '',
            //     filterType: 'multiselectDropdown',
            //     filterLabel: '',
            //     filterFields: {
            //         metricFieldType: 'universal',
            //         fieldName: 'event__status',
            //     },
            //     filterApplied: true,
            //     modifierType: 'CONSOLIDATED',
            //     modifierValue: 'NOT_IN',
            //     values: [
            //         'ONGOING',
            //         'PAUSED',
            //         'AWARD_STAGE',
            //         'PO_ISSUED',
            //         'PO_ACCEPTED',
            //     ],
            // },
            {
                filterId: '',
                filterType: 'multiselectDropdown',
                filterLabel: '',
                filterFields: {
                    metricFieldType: 'metricSpecific',
                    metricFields: {
                        poValuePreferred: 'entity_vendor_master__status',
                    },
                },
                filterApplied: true,
                modifierType: 'CONSOLIDATED',
                modifierValue: 'IN',
                values: ['PREFERRED'],
            },
            {
                filterId: '',
                filterType: 'multiselectDropdown',
                filterLabel: '',
                filterFields: {
                    metricFieldType: 'metricSpecific',
                    metricFields: {
                        poValueNonpreferred: 'event__event_type',
                    },
                },
                filterApplied: true,
                modifierType: 'CONSOLIDATED',
                modifierValue: 'NOT_IN',
                values: ['PREFERRED'],
            },
        ],
        customizableFilters: {
            categoryConnector: 'AND',
            categories: [
                {
                    categoryLabel: 'Date Range',
                    filterConnector: 'AND',
                    filters: [getEventStartDateFilter({})],
                    expanded: true,
                },
                getItemFilterCategory(),
                getVendorFilterCategory(),
                getUserFilterCategory({
                    event__original_event_creator: 'DEFAULT',
                    rfq_event__final_approver: 'APPLICABLE',
                }),
            ],
        },
    },
    metrics: [
        {
            metricId: 'rfqCount',
            display: 'bar',
            displayModifier: 'count',
            metricLegendLabel: 'RFQ count',
            metricDefinition: {
                metricType: 'base',
                metricField: 'event__event_id',
                aggregation: 'count',
            },
        },
    ],
    groupBy: [
        {
            type: 'str',
            field: 'event__original_event_creator',
            includeInGroupName: false,
        },
        {
            type: 'str',
            field: 'event__original_event_creator__name',
            includeInGroupName: true,
        },
    ],
    groupSort: {
        sortBy: 'value',
        sortOrder: 'desc',
    },
    groupFilter: {
        filterBy: 'top',
        filterNumber: 10,
        customFilter: null,
    },
};

export const HA_Users_PurchaseOrderCountByEventType: IHistoricalAnalyticsChart =
    {
        chartId: 'HA_Users_PurchaseOrderCountByEventType',
        title: 'User POs by Event Type',
        downloadSettings: {
            object: 'po',
            columns: getStandardPoColumns(),
        },
        chartType: 'bar',
        axisLabelY: 'PO Count',
        axisLabelX: 'User',
        currencySettings: {
            showTargetCurrency: false,
            targetCurrency: null,
            filterCurrenciesIds: [],
        },
        filterContoller: {
            fixedFilters: [
                {
                    filterId: '',
                    filterType: 'notNull',
                    filterLabel: '',
                    filterFields: {
                        metricFieldType: 'universal',
                        fieldName: 'po__purchase_order_id',
                    },
                    filterApplied: true,
                },
                {
                    filterId: '',
                    filterType: 'multiselectDropdown',
                    filterLabel: '',
                    filterFields: {
                        metricFieldType: 'universal',
                        fieldName: 'po__status',
                    },
                    filterApplied: true,
                    modifierType: 'CONSOLIDATED',
                    modifierValue: 'IN',
                    values: ['ISSUED', 'ONGOING', 'TERMINATED', 'COMPLETED'],
                },
                {
                    filterId: '',
                    filterType: 'multiselectDropdown',
                    filterLabel: '',
                    filterFields: {
                        metricFieldType: 'universal',
                        fieldName: 'po__purchase_order_type',
                    },
                    filterApplied: true,
                    modifierType: 'CONSOLIDATED',
                    modifierValue: 'NOT_IN',
                    values: ['STAGING'],
                },
                {
                    filterId: '',
                    filterType: 'multiselectDropdown',
                    filterLabel: '',
                    filterFields: {
                        metricFieldType: 'metricSpecific',
                        metricFields: {
                            poCountFromRfq: 'event__event_type',
                        },
                    },
                    filterApplied: true,
                    modifierType: 'CONSOLIDATED',
                    modifierValue: 'IN',
                    values: ['RFQ'],
                },
                {
                    filterId: '',
                    filterType: 'multiselectDropdown',
                    filterLabel: '',
                    filterFields: {
                        metricFieldType: 'metricSpecific',
                        metricFields: {
                            poCountFromPoGroup: 'event__event_type',
                        },
                    },
                    filterApplied: true,
                    modifierType: 'CONSOLIDATED',
                    modifierValue: 'IN',
                    values: ['PO_GROUP'],
                },
            ],
            customizableFilters: {
                categoryConnector: 'AND',
                categories: [
                    {
                        categoryLabel: 'Date Range',
                        filterConnector: 'AND',
                        filters: [getPoIssueDateFilter({})],
                        expanded: true,
                    },
                    getItemFilterCategory(),
                    getVendorFilterCategory(),
                    getUserFilterCategory({
                        event__original_event_creator: 'APPLICABLE',
                        po__created_by_user: 'DEFAULT',
                        po__final_buyer_approval: 'APPLICABLE',
                        rfq_event__final_approver: 'APPLICABLE',
                    }),
                ],
            },
        },
        metrics: [
            {
                metricId: 'poCountFromRfq',
                display: 'bar',
                displayModifier: 'count',
                metricLegendLabel: 'RFQ',
                metricDefinition: {
                    metricType: 'base',
                    metricField: 'po__purchase_order_id',
                    aggregation: 'count',
                },
            },
            {
                metricId: 'poCountFromPoGroup',
                displayModifier: 'count',
                display: 'bar',
                metricLegendLabel: 'D2PO',
                metricDefinition: {
                    metricType: 'base',
                    metricField: 'po__purchase_order_id',
                    aggregation: 'count',
                },
            },
        ],
        groupBy: [
            {
                type: 'str',
                field: 'po__created_by_user',
                includeInGroupName: false,
            },
            {
                type: 'str',
                field: 'po__created_by_user__name',
                includeInGroupName: true,
            },
        ],
        groupSort: {
            sortBy: 'value',
            sortOrder: 'desc',
        },
        groupFilter: {
            filterBy: 'top',
            filterNumber: 10,
            customFilter: null,
        },
    };

export const HA_Users_ItemsAndVendors: IHistoricalAnalyticsChart = {
    chartId: 'HA_Users_ItemsAndVendors',
    title: 'Items and vendors',
    downloadSettings: {
        object: 'po_item',
        columns: getStandardPoItemColumns(),
    },
    chartType: 'bar',
    axisLabelY: 'Vendor Count',
    axisLabelY2: 'Item Count',
    axisLabelX: 'User',
    currencySettings: {
        showTargetCurrency: false,
        targetCurrency: null,
        filterCurrenciesIds: [],
    },
    filterContoller: {
        fixedFilters: [
            {
                filterId: '',
                filterType: 'notNull',
                filterLabel: '',
                filterFields: {
                    metricFieldType: 'universal',
                    fieldName: 'po__purchase_order_id',
                },
                filterApplied: true,
            },
            {
                filterId: '',
                filterType: 'multiselectDropdown',
                filterLabel: '',
                filterFields: {
                    metricFieldType: 'universal',
                    fieldName: 'po__status',
                },
                filterApplied: true,
                modifierType: 'CONSOLIDATED',
                modifierValue: 'IN',
                values: ['ISSUED', 'ONGOING', 'TERMINATED', 'COMPLETED'],
            },
            {
                filterId: '',
                filterType: 'multiselectDropdown',
                filterLabel: '',
                filterFields: {
                    metricFieldType: 'universal',
                    fieldName: 'po__purchase_order_type',
                },
                filterApplied: true,
                modifierType: 'CONSOLIDATED',
                modifierValue: 'NOT_IN',
                values: ['STAGING'],
            },
        ],
        customizableFilters: {
            categoryConnector: 'AND',
            categories: [
                {
                    categoryLabel: 'Date Range',
                    filterConnector: 'AND',
                    filters: [getPoIssueDateFilter({})],
                    expanded: true,
                },
                getItemFilterCategory(),
                getVendorFilterCategory(),
                getUserFilterCategory({
                    event__original_event_creator: 'APPLICABLE',
                    po__created_by_user: 'DEFAULT',
                    po__final_buyer_approval: 'APPLICABLE',
                    rfq_event__final_approver: 'APPLICABLE',
                }),
            ],
        },
    },
    metrics: [
        {
            metricId: 'itemCount',
            display: 'bar',
            displayModifier: 'count',
            metricLegendLabel: 'Items',
            metricDefinition: {
                metricType: 'base',
                metricField: 'enterprise_item_id',
                aggregation: 'count',
            },
        },
        {
            metricId: 'vendorCount',
            displayModifier: 'count',
            display: 'line',
            metricLegendLabel: 'Vendors',
            metricDefinition: {
                metricType: 'base',
                metricField: 'seller_entity_id',
                aggregation: 'count',
            },
        },
    ],
    groupBy: [
        {
            type: 'str',
            field: 'po__created_by_user',
            includeInGroupName: false,
        },
        {
            type: 'str',
            field: 'po__created_by_user__name',
            includeInGroupName: true,
        },
    ],
    groupSort: {
        sortBy: 'value',
        sortOrder: 'desc',
    },
    groupFilter: {
        filterBy: 'none',
        filterNumber: null,
        customFilter: null,
    },
};

export const HA_Users_ItemsAndUsers: IHistoricalAnalyticsChart = {
    chartId: 'HA_Users_ItemsAndUsers',
    title: 'Goods Quality',
    downloadSettings: {
        object: 'invoice_item',
        columns: getStandardInvoiceItemColumns(),
    },
    chartType: 'bar',
    axisLabelY: '% Acceptance By Value',
    axisLabelY2: 'PO value',
    axisLabelX: 'User',
    currencySettings: {
        showTargetCurrency: true,
        targetCurrency: null,
        filterCurrenciesIds: [],
    },
    filterContoller: {
        fixedFilters: [
            {
                filterId: '',
                filterType: 'notNull',
                filterLabel: '',
                filterFields: {
                    metricFieldType: 'universal',
                    fieldName: 'invoice_item__invoice_item_id',
                },
                filterApplied: true,
            },
            {
                filterId: '',
                filterType: 'notNull',
                filterLabel: '',
                filterFields: {
                    metricFieldType: 'universal',
                    fieldName: 'batch_1_gr__goods_receipt_id',
                },
                filterApplied: true,
            },
        ],
        customizableFilters: {
            categoryConnector: 'AND',
            categories: [
                {
                    categoryLabel: 'Date Range',
                    filterConnector: 'AND',
                    filters: [getPoIssueDateFilter({})],
                    expanded: true,
                },
                getItemFilterCategory(),
                getVendorFilterCategory(),
                getUserFilterCategory({
                    event__original_event_creator: 'APPLICABLE',
                    po__created_by_user: 'DEFAULT',
                    po__final_buyer_approval: 'APPLICABLE',
                    rfq_event__final_approver: 'APPLICABLE',
                }),
            ],
        },
    },
    metrics: [
        {
            metricId: 'acceptanceValuePercentage',
            displayModifier: 'percentage',
            display: 'bar',
            metricLegendLabel: 'Acceptance %',
            metricDefinition: {
                metricType: 'percentage',
                numerator: {
                    metricType: 'base',
                    metricField:
                        'invoice_item__pricing_information__total_amount - invoice_item__pricing_information__rejection_amount',
                    aggregation: 'sum',
                },
                denominator: {
                    metricType: 'base',
                    metricField:
                        'invoice_item__pricing_information__total_amount',
                    aggregation: 'sum',
                },
            },
        },
        /**
         * The below metric is for PO value but it only includes POs which have
         * at least 1 GR, so it can be misleading.
         */
        {
            display: 'line',
            metricId: 'poValueIssued',
            metricLegendLabel: 'Issued',
            displayModifier: 'currency',
            metricDefinition: {
                metricType: 'base',
                metricField: 'po__pricing_information__total',
                aggregation: 'sum',
            },
        },
    ],
    groupBy: [
        {
            type: 'str',
            field: 'po__created_by_user__user_id',
            includeInGroupName: false,
        },
        {
            type: 'str',
            field: 'po__created_by_user__name',
            includeInGroupName: true,
        },
    ],
    groupSort: {
        sortBy: 'value',
        sortOrder: 'desc',
    },
    groupFilter: {
        filterBy: 'none',
        filterNumber: null,
        customFilter: null,
    },
};

export const HA_Users_PurchaseOrderValueAndVendors: IHistoricalAnalyticsChart =
    {
        chartId: 'HA_Users_PurchaseOrderValueAndVendors',
        title: 'User POs Vendor Preference Status',
        downloadSettings: {
            object: 'po_item',
            columns: getStandardPoItemColumns(),
        },
        chartType: 'bar',
        axisLabelY: 'PO Value',
        axisLabelX: 'User',
        currencySettings: {
            showTargetCurrency: true,
            targetCurrency: null,
            filterCurrenciesIds: [],
        },
        filterContoller: {
            fixedFilters: [
                {
                    filterId: '',
                    filterType: 'notNull',
                    filterLabel: '',
                    filterFields: {
                        metricFieldType: 'universal',
                        fieldName: 'po__purchase_order_id',
                    },
                    filterApplied: true,
                },
                {
                    filterId: '',
                    filterType: 'multiselectDropdown',
                    filterLabel: '',
                    filterFields: {
                        metricFieldType: 'universal',
                        fieldName: 'po__status',
                    },
                    filterApplied: true,
                    modifierType: 'CONSOLIDATED',
                    modifierValue: 'IN',
                    values: ['ISSUED', 'ONGOING', 'TERMINATED', 'COMPLETED'],
                },
                {
                    filterId: '',
                    filterType: 'multiselectDropdown',
                    filterLabel: '',
                    filterFields: {
                        metricFieldType: 'universal',
                        fieldName: 'po__purchase_order_type',
                    },
                    filterApplied: true,
                    modifierType: 'CONSOLIDATED',
                    modifierValue: 'NOT_IN',
                    values: ['STAGING'],
                },
                {
                    filterId: '',
                    filterType: 'multiselectDropdown',
                    filterLabel: '',
                    filterFields: {
                        metricFieldType: 'metricSpecific',
                        metricFields: {
                            poValuePreferred: 'entity_vendor_master__status',
                        },
                    },
                    filterApplied: true,
                    modifierType: 'CONSOLIDATED',
                    modifierValue: 'IN',
                    values: ['PREFERRED'],
                },
                {
                    filterId: '',
                    filterType: 'multiselectDropdown',
                    filterLabel: '',
                    filterFields: {
                        metricFieldType: 'metricSpecific',
                        metricFields: {
                            poValueNonpreferred: 'event__event_type',
                        },
                    },
                    filterApplied: true,
                    modifierType: 'CONSOLIDATED',
                    modifierValue: 'NOT_IN',
                    values: ['PREFERRED'],
                },
            ],
            customizableFilters: {
                categoryConnector: 'AND',
                categories: [
                    {
                        categoryLabel: 'Date Range',
                        filterConnector: 'AND',
                        filters: [getPoIssueDateFilter({})],
                        expanded: true,
                    },
                    getItemFilterCategory(),
                    getVendorFilterCategory(),
                    getUserFilterCategory({
                        event__original_event_creator: 'APPLICABLE',
                        po__created_by_user: 'DEFAULT',
                        po__final_buyer_approval: 'APPLICABLE',
                        rfq_event__final_approver: 'APPLICABLE',
                    }),
                ],
            },
        },
        metrics: [
            {
                metricId: 'poValuePreferred',
                display: 'bar',
                displayModifier: 'currency',
                metricLegendLabel: 'Preferred Vendors',
                metricDefinition: {
                    metricType: 'base',
                    metricField: 'po__pricing_information__total',
                    aggregation: 'sum',
                },
            },
            {
                metricId: 'poValueNonpreferred',
                display: 'bar',
                displayModifier: 'currency',
                metricLegendLabel: 'Nonpreferred Vendors',
                metricDefinition: {
                    metricType: 'base',
                    metricField: 'po__pricing_information__total',
                    aggregation: 'sum',
                },
            },
        ],
        groupBy: [
            {
                type: 'str',
                field: 'po__created_by_user__user_id',
                includeInGroupName: false,
            },
            {
                type: 'str',
                field: 'po__created_by_user__name',
                includeInGroupName: true,
            },
        ],
        groupSort: {
            sortBy: 'value',
            sortOrder: 'desc',
        },
        groupFilter: {
            filterBy: 'top',
            filterNumber: 10,
            customFilter: null,
        },
    };

export const HA_Users_PurchaseOrderValueByEventType: IHistoricalAnalyticsChart =
    {
        chartId: 'HA_Users_PurchaseOrderValueByEventType',
        title: 'PO Value by Creator and Event Type',
        downloadSettings: {
            object: 'po',
            columns: getStandardPoColumns(),
        },
        chartType: 'bar',
        axisLabelY: 'PO Value',
        axisLabelY2: 'PO Count',
        axisLabelX: 'User',
        currencySettings: {
            showTargetCurrency: true,
            targetCurrency: null,
            filterCurrenciesIds: [],
        },
        filterContoller: {
            fixedFilters: [
                {
                    filterId: '',
                    filterType: 'notNull',
                    filterLabel: '',
                    filterFields: {
                        metricFieldType: 'universal',
                        fieldName: 'po__purchase_order_id',
                    },
                    filterApplied: true,
                },
                {
                    filterId: '',
                    filterType: 'multiselectDropdown',
                    filterLabel: '',
                    filterFields: {
                        metricFieldType: 'universal',
                        fieldName: 'po__status',
                    },
                    filterApplied: true,
                    modifierType: 'CONSOLIDATED',
                    modifierValue: 'IN',
                    values: ['ISSUED', 'ONGOING', 'TERMINATED', 'COMPLETED'],
                },
                {
                    filterId: '',
                    filterType: 'multiselectDropdown',
                    filterLabel: '',
                    filterFields: {
                        metricFieldType: 'universal',
                        fieldName: 'po__purchase_order_type',
                    },
                    filterApplied: true,
                    modifierType: 'CONSOLIDATED',
                    modifierValue: 'NOT_IN',
                    values: ['STAGING'],
                },
                {
                    filterId: '',
                    filterType: 'multiselectDropdown',
                    filterLabel: '',
                    filterFields: {
                        metricFieldType: 'metricSpecific',
                        metricFields: {
                            poValueFromRfq: 'event__event_type',
                        },
                    },
                    filterApplied: true,
                    modifierType: 'CONSOLIDATED',
                    modifierValue: 'IN',
                    values: ['RFQ'],
                },
                {
                    filterId: '',
                    filterType: 'multiselectDropdown',
                    filterLabel: '',
                    filterFields: {
                        metricFieldType: 'metricSpecific',
                        metricFields: {
                            poValueFromPoGroup: 'event__event_type',
                        },
                    },
                    filterApplied: true,
                    modifierType: 'CONSOLIDATED',
                    modifierValue: 'IN',
                    values: ['PO_GROUP'],
                },
            ],
            customizableFilters: {
                categoryConnector: 'AND',
                categories: [
                    {
                        categoryLabel: 'Date Range',
                        filterConnector: 'AND',
                        filters: [getPoIssueDateFilter({})],
                        expanded: true,
                    },
                    getItemFilterCategory(),
                    getVendorFilterCategory(),
                    getUserFilterCategory({
                        event__original_event_creator: 'APPLICABLE',
                        po__created_by_user: 'DEFAULT',
                        po__final_buyer_approval: 'APPLICABLE',
                        rfq_event__final_approver: 'APPLICABLE',
                    }),
                ],
            },
        },
        metrics: [
            {
                metricId: 'poValueFromRfq',
                display: 'bar',
                displayModifier: 'currency',
                metricLegendLabel: 'RFQ',
                metricDefinition: {
                    metricType: 'base',
                    metricField: 'po__pricing_information__total',
                    aggregation: 'sum',
                },
            },
            {
                metricId: 'poValueFromPoGroup',
                displayModifier: 'currency',
                display: 'bar',
                metricLegendLabel: 'D2PO',
                metricDefinition: {
                    metricType: 'base',
                    metricField: 'po__pricing_information__total',
                    aggregation: 'sum',
                },
            },
            {
                display: 'line',
                metricId: 'poCount',
                metricLegendLabel: 'PO Count',
                displayModifier: 'count',
                metricDefinition: {
                    metricType: 'base',
                    metricField: 'po__purchase_order_id',
                    aggregation: 'count',
                },
            },
        ],
        groupBy: [
            {
                type: 'str',
                field: 'event__original_event_creator__user_id',
                includeInGroupName: false,
            },
            {
                type: 'str',
                field: 'event__original_event_creator__name',
                includeInGroupName: true,
            },
        ],
        groupSort: {
            sortBy: 'value',
            sortOrder: 'desc',
        },
        groupFilter: {
            filterBy: 'top',
            filterNumber: 10,
            customFilter: null,
        },
    };
