import { createSlice } from '@reduxjs/toolkit';
import {
    ISetupWizardActions,
    updateSetupWizardDetailsReducer,
} from '../Reducers/SetupWizardReducers';

export enum SetupWizardStatus {
    PENDING = 'PENDING',
}

export interface ISetupWizardState {
    setupEntityUid: string;
}

export const SetupWizardSlice = createSlice<
    ISetupWizardState,
    {
        updateSetupWizardDetails: (
            state: ISetupWizardState,
            action: ISetupWizardActions
        ) => void;
    },
    string
>({
    name: 'SetupWizardStore',
    initialState: {
        setupEntityUid: '',
    },
    reducers: {
        updateSetupWizardDetails: updateSetupWizardDetailsReducer,
    },
});

export const { updateSetupWizardDetails } = SetupWizardSlice.actions;

const SetupWizardStore = SetupWizardSlice.reducer;
export default SetupWizardStore;
