import { FWTypography } from '../../Common/FWTypograhy';
import { Box } from '@mui/material';
import { FWTooltip } from '../../Common/FWCustomTooltip';
import { InvoiceItemStatusForDisplay } from '../Interfaces/Invoice.model';

const InvoiceItemStatusChip = ({
    displayStatus,
    hoverText = '',
}: {
    displayStatus: InvoiceItemStatusForDisplay;
    hoverText?: string;
}) => {
    const StatusBlock = (
        param_status: InvoiceItemStatusForDisplay,
        param_color: string,
        icon_color: string = 'warning.main',
        icon_hover_text: string = ''
    ) => {
        return (
            <div className="flex flex--aic">
                {(icon_hover_text.length > 0 || hoverText.length > 0) && (
                    <>
                        <FWTooltip
                            title={
                                icon_hover_text.length > 0
                                    ? icon_hover_text
                                    : hoverText
                            }
                        >
                            <Box color={icon_color} className="flex flex--aic">
                                <i
                                    className="bi bi-circle-fill"
                                    style={{ fontSize: '0.5rem' }}
                                ></i>
                            </Box>
                        </FWTooltip>
                        &nbsp;
                    </>
                )}
                <FWTypography sx={{ color: param_color }}>
                    {param_status}
                </FWTypography>
            </div>
        );
    };

    switch (displayStatus) {
        case InvoiceItemStatusForDisplay.GOODS_PENDING:
            return StatusBlock(
                InvoiceItemStatusForDisplay.GOODS_PENDING,
                'warning.main'
            );
        case InvoiceItemStatusForDisplay.GOODS_RECEIVED:
            return StatusBlock(
                InvoiceItemStatusForDisplay.GOODS_RECEIVED,
                'success.main'
            );
        case InvoiceItemStatusForDisplay.COMPLETED:
            return StatusBlock(
                InvoiceItemStatusForDisplay.COMPLETED,
                'success.main'
            );
        case InvoiceItemStatusForDisplay.ON_HOLD:
            return StatusBlock(
                InvoiceItemStatusForDisplay.ON_HOLD,
                'warning.main'
            );
        case InvoiceItemStatusForDisplay.TERMINATION_REQUESTED:
            return StatusBlock(
                InvoiceItemStatusForDisplay.TERMINATION_REQUESTED,
                'error.main'
            );
        case InvoiceItemStatusForDisplay.TERMINATED:
            return StatusBlock(
                InvoiceItemStatusForDisplay.TERMINATED,
                'error.main'
            );
        case InvoiceItemStatusForDisplay.GOODS_PENDING_WITH_HOLD:
            return StatusBlock(
                InvoiceItemStatusForDisplay.GOODS_PENDING,
                'warning.main',
                'warning.main',
                'On hold'
            );
        case InvoiceItemStatusForDisplay.GOODS_PENDING_WITH_TERMINATED:
            return StatusBlock(
                InvoiceItemStatusForDisplay.GOODS_PENDING,
                'warning.main',
                'error.main',
                'Terminated'
            );
        case InvoiceItemStatusForDisplay.GOODS_PENDING_WITH_TERMINATION_REQUEST:
            return StatusBlock(
                InvoiceItemStatusForDisplay.GOODS_PENDING,
                'warning.main',
                'error.main',
                'Termination requested'
            );
        case InvoiceItemStatusForDisplay.GOODS_RECEIVED_WITH_HOLD:
            return StatusBlock(
                InvoiceItemStatusForDisplay.GOODS_RECEIVED,
                'success.main',
                'warning.main',
                'On hold'
            );
        case InvoiceItemStatusForDisplay.GOODS_RECEIVED_WITH_TERMINATED:
            return StatusBlock(
                InvoiceItemStatusForDisplay.GOODS_RECEIVED,
                'success.main',
                'error.main',
                'Terminated'
            );
        case InvoiceItemStatusForDisplay.GOODS_RECEIVED_WITH_TERMINATION_REQUEST:
            return StatusBlock(
                InvoiceItemStatusForDisplay.GOODS_RECEIVED,
                'success.main',
                'error.main',
                'Termination requested'
            );
        case InvoiceItemStatusForDisplay.QUALITY_CHECK:
            return StatusBlock(
                InvoiceItemStatusForDisplay.QUALITY_CHECK,
                'success.main',
                'error.main'
            );
        case InvoiceItemStatusForDisplay.QUALITY_CHECK_WITH_HOLD:
            return StatusBlock(
                InvoiceItemStatusForDisplay.QUALITY_CHECK,
                'success.main',
                'error.main',
                'On hold'
            );
        case InvoiceItemStatusForDisplay.QUALITY_CHECK_WITH_TERMINATED:
            return StatusBlock(
                InvoiceItemStatusForDisplay.QUALITY_CHECK,
                'success.main',
                'error.main',
                'some batches are terminated'
            );
        case InvoiceItemStatusForDisplay.QUALITY_CHECK_WITH_TERMINATION_REQUEST:
            return StatusBlock(
                InvoiceItemStatusForDisplay.QUALITY_CHECK,
                'success.main',
                'error.main',
                'Termination requested'
            );
        default:
            return StatusBlock(InvoiceItemStatusForDisplay.BLANK, 'error.main');
    }
};

export default InvoiceItemStatusChip;
