import { getTemplateFrontOptionFromListOfTemplateItems } from '../../../AdditionalCost/helpers';
import { getFieldTypeContraintsFromBackend } from '../../../FieldTypes/helpers/fieldTypesHelpers';
import {
    convertTemplateConstraintsItemToCustomFIeldType,
    dummyPaymentTermsOptions,
    getPaymentTermsDetails,
} from '../../../Global/Helpers/templateApiSlice';
import { ITemplatePaymentTermsOptions } from '../../../Global/Interfaces/TemplateInterface';
import { blankRequisitionTemplate } from '../../../Requisitions/Constants/RequisitionTemplateConstants';
import {
    IRequisitionNewTemplate,
    IRequisitionNewTemplateDetailResponse,
    RequisitionNewTemplateStandardFieldBackendNames,
    RequisitionNewTemplateStandardFieldsEnum,
} from '../../../Requisitions/Interfaces/RequisitionTemplateInterfacesNew';
import { getAllCurrencies } from '../../Services/Buyer/shared.service';

export const fetchCurrencies = async () => {
    return new Promise(async (resolve, reject) => {
        try {
            let response = await getAllCurrencies();
            resolve(response);
        } catch (err) {
            reject(err);
        }
    });
};

export const isDuplicateItem = (data: any[]) => {
    let bool = false;
    Object.values(data).forEach((item) => {
        if (item.length > 1) bool = true;
    });
    return bool;
};

export const createPayloadForIntegration = (
    itemsForEventCreation: any,
    fieldsToExport: any,
    selectedReqId: string,
    itemsAvailable: any[] | undefined
): any => {
    let payload: any = [];
    Object.keys(itemsForEventCreation).forEach((key) => {
        let item: any;
        if (itemsAvailable) {
            item = itemsAvailable.find(
                (item) => item.requisition_item_id === key
            );
        }
        payload = [
            ...payload,
            {
                requisition_item_id: key,
                combined_requisition_item_ids: itemsForEventCreation[key],
                custom_fields: extractCustomFields(
                    fieldsToExport.customFields,
                    item,
                    item ? item.requisition_item_id === selectedReqId : false
                ),
                custom_fields_negotiate: { section_list: [] },
                fields_to_autofill: extractStandardFields(
                    fieldsToExport.standardFields[item.requisition]
                ),
            },
        ];
    });
    return payload;
};

const extractCustomFields = (
    customFields: any,
    item: any,
    itemExportedFromCart: boolean
) => {
    if (!item) {
        return { section_list: [] };
    }
    return { section_list: [] };
    // let response = cloneDeep(item.custom_fields);
    // let fields: any = [];
    // item.custom_fields.section_list.forEach((data: any) =>
    //     data.fields.forEach((field: any) => {
    //         fields = [...fields, field.name];
    //     })
    // );
    // if (
    //     fields.length === 0 ||
    //     !itemExportedFromCart ||
    //     customFields.length === 0
    // ) {
    //     const finalResponse = {
    //         section_list: response.section_list.map((data: any) => {
    //             return {
    //                 name:
    //                     data.name === 'Item Terms'
    //                         ? 'item_terms'
    //                         : data.name === 'Payment and Delivery Terms'
    //                         ? 'payment_and_delivery_terms'
    //                         : data.name === 'Additional Information'
    //                         ? 'additional_details'
    //                         : 'vendor_information',
    //                 fields: data.fields.filter((value: any) => value !== null),
    //             };
    //         }),
    //     };
    //     return finalResponse;
    // }
    // let fieldMap: any = [];
    // let sum: number = 0;
    // item.custom_fields.section_list.forEach((data: any) => {
    //     sum += data.fields.length;
    //     fieldMap = [...fieldMap, sum];
    // });
    // let customFieldLabels = customFields.map((data: any) => data.label);
    // for (let i = 0; i < fields.length; i++) {
    //     if (!customFieldLabels.includes(fields[i])) {
    //         if (i < fieldMap[0]) {
    //             response.section_list[0].fields[i] = null;
    //         } else if (i < fieldMap[1]) {
    //             response.section_list[1].fields[i - fieldMap[0]] = null;
    //         } else {
    //             response.section_list[2].fields[i - fieldMap[1]] = null;
    //         }
    //     }
    // }
    // const finalResponse = {
    //     section_list: response.section_list.map((data: any) => {
    //         return {
    //             name:
    //                 data.name === 'Item Terms'
    //                     ? 'item_terms'
    //                     : data.name === ''
    //                     ? 'payment_and_delivery_terms'
    //                     : data.name === ''
    //                     ? 'additional_details'
    //                     : 'vendor_information',
    //             fields: data.fields.filter((value: any) => value !== null),
    //         };
    //     }),
    // };
    // return finalResponse;
};
const extractStandardFields = (
    standardFields: any,
    itemExportedFromCart?: boolean
) => {
    //AUTOFILL ONLY the boolean, values already there in the item
    let labels = standardFields.map((data: any) => data.label);
    let response: any;
    response = {
        cost_centre: labels.includes('Cost center'),
        gl: labels.includes('GL'),
        base_rate: labels.includes('Target rate'),
        additional_costs: labels.includes('Additional costs'),
        taxes: labels.includes('Taxes'),
        discounts: labels.includes('Discount'),
        payment_terms: labels.includes('Payment terms'),
        incoterms: labels.includes('Incoterms'),
        lead_time: labels.includes('Lead time'),
        additional_information: labels.includes('Additional information'),
        project: labels.includes('Project'),
        delivery_date: labels.includes('Delivery date'),
        shipping_address: true,
        vendor: labels.includes('Vendor'),
        gr_tolerance: labels.includes('GR Tolerance'),
    };
    return response;
};

export const validateTemplates = (
    requisitionTemplates: any[],
    selectedTemplate: any[]
) => {};

export const convertTemplateFromBackendToFrontend = (
    baseQueryReturnValue: IRequisitionNewTemplateDetailResponse
) => {
    const response: IRequisitionNewTemplate = JSON.parse(
        JSON.stringify(blankRequisitionTemplate)
    );
    response.templateId = baseQueryReturnValue.template_id;
    response.templateName = baseQueryReturnValue.name;
    const templateSections = baseQueryReturnValue.section_list.map(
        (section) => {
            return {
                ...section,
                section_items: section.section_items.map((item: any) => {
                    return {
                        ...item,
                        alternate_name: item.alternate_name.replace('_', ''),
                        name: item.name.replace('_', ''),
                    };
                }),
            };
        }
    );
    baseQueryReturnValue = {
        ...baseQueryReturnValue,
        section_list: templateSections,
    };
    const requisitonDetailsfromBackend = baseQueryReturnValue.section_list.find(
        (section) => section.name === 'Requisition Details'
    );
    response.sections.REQUISITION_DETAILS = {
        fieldList: [],
        label:
            requisitonDetailsfromBackend &&
            requisitonDetailsfromBackend?.alternate_name
                ? requisitonDetailsfromBackend.alternate_name
                : 'Requisition Details',
    };
    requisitonDetailsfromBackend?.section_items.forEach((item) => {
        if (
            Object.keys(
                RequisitionNewTemplateStandardFieldBackendNames
            ).includes(item.name)
        ) {
            if (!item.additional_information.is_hidden) {
                response.sections.REQUISITION_DETAILS.fieldList.push({
                    fieldType: 'STANDARD',
                    field: RequisitionNewTemplateStandardFieldBackendNames[
                        item.name
                    ],
                });
            }

            let formattedData = {
                label: item.alternate_name ? item.alternate_name : item.name,
                description: item.description,
                buyerSettings: {
                    required: item.is_required,
                    showField: !(
                        item.additional_information.is_hidden ?? false
                    ),
                },
                sellerSettings: {
                    negotiable:
                        !(item.additional_information.is_hidden ?? false) &&
                        (item.additional_information.is_visible ?? true) &&
                        (item.additional_information.is_negotiable ?? true),
                    showField:
                        !(item.additional_information.is_hidden ?? false) &&
                        (item.additional_information.is_visible ?? true),
                    isMandatory: item.is_mandatory,
                },
                fieldLevel: item.field_level,
            };
            response.fieldSettings.standardFields = {
                ...response.fieldSettings.standardFields,
                [RequisitionNewTemplateStandardFieldBackendNames[item.name]]:
                    formattedData,
            };
        } else if (item.name !== 'Discounts' && item.name !== 'Tax') {
            if (!item.additional_information.is_hidden)
                response.sections.REQUISITION_DETAILS.fieldList.push({
                    fieldType: 'CUSTOM',
                    field: item.name,
                });
            response.fieldSettings.customFields[item.name] = {
                label: item.alternate_name ? item.alternate_name : item.name,
                description: item.description,
                buyerSettings: {
                    required: item.is_required,
                    showField: !(
                        item.additional_information.is_hidden ?? false
                    ),
                },
                sellerSettings: {
                    negotiable:
                        !(item.additional_information.is_hidden ?? false) &&
                        (item.additional_information.is_visible ?? true) &&
                        (item.additional_information.is_negotiable ?? true),
                    showField:
                        !(item.additional_information.is_hidden ?? false) &&
                        (item.additional_information.is_visible ?? true),
                    isMandatory: item.is_mandatory,
                },
                type: convertTemplateConstraintsItemToCustomFIeldType(
                    item.constraints.field_type
                ),
                constraints: getFieldTypeContraintsFromBackend({
                    field_type: item.constraints.field_type,
                    choices: item.constraints.choices,
                    max_limit: item.constraints.max_limit,
                    min_limit: item.constraints.min_limit,
                    choice_type: item.constraints.choice_type,
                }),
            };
        }
    });
    const essentialTermsfromBackend = baseQueryReturnValue.section_list.find(
        (section) => section.name === 'Item Terms'
    );
    // response.sections.ESSENTIAL_TERMS = {};
    response.sections.ITEM_TERMS = {
        fieldList: [],
        label:
            essentialTermsfromBackend &&
            essentialTermsfromBackend?.alternate_name
                ? essentialTermsfromBackend.alternate_name
                : 'Item Terms',
    };
    const essentialTermsItemsfromBackend =
        essentialTermsfromBackend!.section_items
            .filter(
                (item) =>
                    [
                        'Quantity',
                        'Delivery date',
                        'Cost center',
                        'GL',
                        'Project',
                        'Additional costs and Taxes',
                        'Discount',
                    ].includes(item.name) || item.parent_section_item === null
            )
            .filter(
                (item) =>
                    ![
                        'Item',
                        'Specification',
                        'Measurement unit',
                        'Quantity Information',
                        'Discount information',
                        'Overall Discount information',
                    ].includes(item.name)
            );
    essentialTermsItemsfromBackend.forEach((item) => {
        if (
            Object.keys(
                RequisitionNewTemplateStandardFieldBackendNames
            ).includes(item.name)
        ) {
            if (!item.additional_information.is_hidden) {
                response.sections.ITEM_TERMS.fieldList.push({
                    fieldType: 'STANDARD',
                    field: RequisitionNewTemplateStandardFieldBackendNames[
                        item.name
                    ],
                });
            }

            let formattedData = {
                label: item.alternate_name ? item.alternate_name : item.name,
                description: item.description,
                buyerSettings: {
                    required: item.is_required,
                    showField: !(
                        item.additional_information.is_hidden ?? false
                    ),
                },
                sellerSettings: {
                    negotiable:
                        !(item.additional_information.is_hidden ?? false) &&
                        (item.additional_information.is_visible ?? true) &&
                        (item.additional_information.is_negotiable ?? true),
                    showField:
                        !(item.additional_information.is_hidden ?? false) &&
                        (item.additional_information.is_visible ?? true),
                    isMandatory: item.is_mandatory,
                },
                fieldLevel: item.field_level,
            };
            if (
                RequisitionNewTemplateStandardFieldBackendNames[item.name] ===
                RequisitionNewTemplateStandardFieldBackendNames.PAYMENT_TERMS
            ) {
                const formattedDataWithPaymentOptions: ITemplatePaymentTermsOptions =
                    {
                        ...formattedData,
                        paymentTermOptions:
                            // TODO: Remove dummy data
                            dummyPaymentTermsOptions,
                        standardFieldCategory: 'PAYMENT_TERMS',
                    };

                response.fieldSettings.standardFields[
                    RequisitionNewTemplateStandardFieldsEnum.PAYMENT_TERMS
                ] = formattedDataWithPaymentOptions;
            } else if (
                RequisitionNewTemplateStandardFieldBackendNames[item.name] ===
                RequisitionNewTemplateStandardFieldsEnum.TAX
            ) {
                response.fieldSettings.standardFields[
                    RequisitionNewTemplateStandardFieldsEnum.TAX
                ] = {
                    ...formattedData,
                    standardFieldCategory: 'TAX',
                    taxesOptions: getTemplateFrontOptionFromListOfTemplateItems(
                        essentialTermsfromBackend?.section_items.filter(
                            (sectionItem) =>
                                sectionItem.parent_section_item ===
                                item.section_item_id
                        ) ?? []
                    ),
                    buyerSettings: {
                        ...formattedData.buyerSettings,
                        showField: formattedData.buyerSettings.showField
                            ? getTemplateFrontOptionFromListOfTemplateItems(
                                  essentialTermsfromBackend?.section_items.filter(
                                      (sectionItem) =>
                                          sectionItem.parent_section_item ===
                                          item.section_item_id
                                  ) ?? []
                              ).length > 0
                            : false,
                    },
                    sellerSettings: {
                        ...formattedData.sellerSettings,
                        showField: formattedData.sellerSettings.showField
                            ? getTemplateFrontOptionFromListOfTemplateItems(
                                  essentialTermsfromBackend?.section_items.filter(
                                      (sectionItem) =>
                                          sectionItem.parent_section_item ===
                                          item.section_item_id
                                  ) ?? []
                              ).length > 0
                            : false,
                    },
                };
            } else if (
                RequisitionNewTemplateStandardFieldBackendNames[item.name] ===
                RequisitionNewTemplateStandardFieldsEnum.ADDITIONAL_COSTS
            ) {
                response.fieldSettings.standardFields[
                    RequisitionNewTemplateStandardFieldsEnum.ADDITIONAL_COSTS
                ] = {
                    ...formattedData,
                    standardFieldCategory: 'ADDITIONAL_COSTS',
                    additionalCostOptions:
                        getTemplateFrontOptionFromListOfTemplateItems(
                            essentialTermsfromBackend?.section_items.filter(
                                (sectionItem) =>
                                    sectionItem.parent_section_item ===
                                    item.section_item_id
                            ) ?? []
                        ),
                    buyerSettings: {
                        ...formattedData.buyerSettings,
                        showField: formattedData.buyerSettings.showField
                            ? getTemplateFrontOptionFromListOfTemplateItems(
                                  essentialTermsfromBackend?.section_items.filter(
                                      (sectionItem) =>
                                          sectionItem.parent_section_item ===
                                          item.section_item_id
                                  ) ?? []
                              ).length > 0
                            : false,
                    },
                    sellerSettings: {
                        ...formattedData.sellerSettings,
                        showField: formattedData.sellerSettings.showField
                            ? getTemplateFrontOptionFromListOfTemplateItems(
                                  essentialTermsfromBackend?.section_items.filter(
                                      (sectionItem) =>
                                          sectionItem.parent_section_item ===
                                          item.section_item_id
                                  ) ?? []
                              ).length > 0
                            : false,
                    },
                };
            } else if (
                RequisitionNewTemplateStandardFieldBackendNames[item.name] ===
                RequisitionNewTemplateStandardFieldsEnum.DISCOUNT
            ) {
                const listOfSectionItemsFromTemplate =
                    essentialTermsfromBackend?.section_items.filter(
                        (sectionItem) =>
                            sectionItem.parent_section_item ===
                            item.section_item_id
                    ) ?? [];

                listOfSectionItemsFromTemplate.push(item);

                response.fieldSettings.standardFields[
                    RequisitionNewTemplateStandardFieldsEnum.DISCOUNT
                ] = {
                    ...formattedData,
                    standardFieldCategory: 'DISCOUNT',
                    discountOptions:
                        getTemplateFrontOptionFromListOfTemplateItems(
                            listOfSectionItemsFromTemplate
                        ),
                    buyerSettings: {
                        ...formattedData.buyerSettings,
                        showField: formattedData.buyerSettings.showField
                            ? getTemplateFrontOptionFromListOfTemplateItems(
                                  listOfSectionItemsFromTemplate
                              ).length > 0
                            : false,
                    },
                    sellerSettings: {
                        ...formattedData.sellerSettings,
                        showField: formattedData.sellerSettings.showField
                            ? getTemplateFrontOptionFromListOfTemplateItems(
                                  listOfSectionItemsFromTemplate
                              ).length > 0
                            : false,
                    },
                };
            } else {
                response.fieldSettings.standardFields = {
                    ...response.fieldSettings.standardFields,
                    [RequisitionNewTemplateStandardFieldBackendNames[
                        item.name
                    ]]: formattedData,
                };
            }
        } else if (item.name !== 'Discounts' && item.name !== 'Tax') {
            if (!item.additional_information.is_hidden)
                response.sections.ITEM_TERMS.fieldList.push({
                    fieldType: 'CUSTOM',
                    field: item.name,
                });
            response.fieldSettings.customFields[item.name] = {
                label: item.alternate_name ? item.alternate_name : item.name,
                description: item.description,
                buyerSettings: {
                    required: item.is_required,
                    showField: !(
                        item.additional_information.is_hidden ?? false
                    ),
                },
                sellerSettings: {
                    negotiable:
                        !(item.additional_information.is_hidden ?? false) &&
                        (item.additional_information.is_visible ?? true) &&
                        (item.additional_information.is_negotiable ?? true),
                    showField:
                        !(item.additional_information.is_hidden ?? false) &&
                        (item.additional_information.is_visible ?? true),
                    isMandatory: item.is_mandatory,
                },
                type: convertTemplateConstraintsItemToCustomFIeldType(
                    item.constraints.field_type
                ),
                constraints: getFieldTypeContraintsFromBackend({
                    field_type: item.constraints.field_type,
                    choices: item.constraints.choices,
                    max_limit: item.constraints.max_limit,
                    min_limit: item.constraints.min_limit,
                    choice_type: item.constraints.choice_type,
                }),
            };
        }
    });
    const paymentDeliveryfromBackend = baseQueryReturnValue.section_list.find(
        (section) => section.name === 'Payment and Delivery Terms'
    );
    response.sections.PAYMENT_AND_DELIVERY_TERMS.label =
        paymentDeliveryfromBackend && paymentDeliveryfromBackend.alternate_name
            ? paymentDeliveryfromBackend.alternate_name
            : 'Payment and Delivery Terms';
    const paymentDeliveryItemsfromBackend =
        paymentDeliveryfromBackend!.section_items
            // .filter((item) => item.parent_section_item === null)
            .filter((item) => ![''].includes(item.name));

    paymentDeliveryItemsfromBackend.forEach((item) => {
        if (
            Object.keys(
                RequisitionNewTemplateStandardFieldBackendNames
            ).includes(item.name)
        ) {
            if (!item.additional_information.is_hidden)
                response.sections.PAYMENT_AND_DELIVERY_TERMS.fieldList.push({
                    fieldType: 'STANDARD',
                    field: RequisitionNewTemplateStandardFieldBackendNames[
                        item.name
                    ],
                });

            const formattedData = {
                label: item.alternate_name ? item.alternate_name : item.name,
                description: item.description,
                buyerSettings: {
                    required: item.is_required,
                    showField: !(
                        item.additional_information.is_hidden ?? false
                    ),
                },
                sellerSettings: {
                    negotiable:
                        !(item.additional_information.is_hidden ?? false) &&
                        (item.additional_information.is_visible ?? true) &&
                        (item.additional_information.is_negotiable ?? true),
                    showField:
                        !(item.additional_information.is_hidden ?? false) &&
                        (item.additional_information.is_visible ?? true),
                    isMandatory: item.is_mandatory,
                },
                fieldLevel: item.field_level,
            };
            if (
                RequisitionNewTemplateStandardFieldBackendNames[item.name] ===
                RequisitionNewTemplateStandardFieldsEnum.PAYMENT_TERMS
            ) {
                const paymentTermsOption = getPaymentTermsDetails(
                    paymentDeliveryfromBackend!.section_items
                );
                const formattedDataWithPaymentOptions: ITemplatePaymentTermsOptions =
                    {
                        ...formattedData,
                        paymentTermOptions: paymentTermsOption,
                        standardFieldCategory: 'PAYMENT_TERMS',
                    };
                response.fieldSettings.standardFields[
                    RequisitionNewTemplateStandardFieldsEnum.PAYMENT_TERMS
                ] = formattedDataWithPaymentOptions;
            } else {
                response.fieldSettings.standardFields[
                    RequisitionNewTemplateStandardFieldBackendNames[item.name]
                ] = formattedData as any;
            }
        } else if (item.name !== 'Prepayment percentage') {
            // if (!item.additional_information.is_hidden)
            response.sections.PAYMENT_AND_DELIVERY_TERMS.fieldList.push({
                fieldType: 'CUSTOM',
                field: item.name,
            });
            response.fieldSettings.customFields[item.name] = {
                label: item.alternate_name ? item.alternate_name : item.name,
                description: item.description,
                buyerSettings: {
                    required: item.is_required,
                    showField: !(
                        item.additional_information.is_hidden ?? false
                    ),
                },
                sellerSettings: {
                    negotiable:
                        !(item.additional_information.is_hidden ?? false) &&
                        (item.additional_information.is_visible ?? true) &&
                        (item.additional_information.is_negotiable ?? true),
                    showField:
                        !(item.additional_information.is_hidden ?? false) &&
                        (item.additional_information.is_visible ?? true),
                    isMandatory: item.is_mandatory,
                },
                type: convertTemplateConstraintsItemToCustomFIeldType(
                    item.constraints.field_type
                ),
                constraints: getFieldTypeContraintsFromBackend({
                    field_type: item.constraints.field_type,
                    choices: item.constraints.choices,
                    max_limit: item.constraints.max_limit,
                    min_limit: item.constraints.min_limit,
                    choice_type: item.constraints.choice_type,
                }),
            };
        }
    });
    const addtionalInformationfromBackend =
        baseQueryReturnValue.section_list.find(
            (section) => section.name === 'Additional Information'
        );
    response.sections.ADDITIONAL_DETAILS.label =
        addtionalInformationfromBackend &&
        addtionalInformationfromBackend.alternate_name
            ? addtionalInformationfromBackend.alternate_name
            : 'Additional Information';
    const addtionalInformationItemsfromBackend =
        addtionalInformationfromBackend!.section_items
            // .filter((item) => item.parent_section_item === null)
            .filter((item) => ![''].includes(item.name));
    addtionalInformationItemsfromBackend.forEach((item) => {
        if (
            Object.keys(
                RequisitionNewTemplateStandardFieldBackendNames
            ).includes(item.name)
        ) {
            if (!item.additional_information.is_hidden) {
                response.sections.ADDITIONAL_DETAILS.fieldList.push({
                    fieldType: 'STANDARD',
                    field: RequisitionNewTemplateStandardFieldBackendNames[
                        item.name
                    ],
                });

                const formattedData = {
                    label: item.alternate_name
                        ? item.alternate_name
                        : item.name,
                    description: item.description,
                    buyerSettings: {
                        required: item.is_required,
                        showField: !(
                            item.additional_information.is_hidden ?? false
                        ),
                    },
                    sellerSettings: {
                        negotiable:
                            !(item.additional_information.is_hidden ?? false) &&
                            (item.additional_information.is_visible ?? true) &&
                            (item.additional_information.is_negotiable ?? true),
                        showField:
                            !(item.additional_information.is_hidden ?? false) &&
                            (item.additional_information.is_visible ?? true),
                    },
                    fieldLevel: item.field_level,
                };

                response.fieldSettings.standardFields = {
                    ...response.fieldSettings.standardFields,
                    [RequisitionNewTemplateStandardFieldBackendNames[
                        item.name
                    ]]: formattedData,
                };
            }
        } else {
            response.sections.ADDITIONAL_DETAILS.fieldList.push({
                fieldType: 'CUSTOM',
                field: item.name,
            });
            response.fieldSettings.customFields[item.name] = {
                label: item.alternate_name ? item.alternate_name : item.name,
                description: item.description,
                buyerSettings: {
                    required: item.is_required,
                    showField: !(
                        item.additional_information.is_hidden ?? false
                    ),
                },
                sellerSettings: {
                    negotiable:
                        !(item.additional_information.is_hidden ?? false) &&
                        (item.additional_information.is_visible ?? true) &&
                        (item.additional_information.is_negotiable ?? true),
                    showField:
                        !(item.additional_information.is_hidden ?? false) &&
                        (item.additional_information.is_visible ?? true),
                    isMandatory: item.is_mandatory,
                },
                type: convertTemplateConstraintsItemToCustomFIeldType(
                    item.constraints.field_type
                ),
                constraints: getFieldTypeContraintsFromBackend({
                    field_type: item.constraints.field_type,
                    choices: item.constraints.choices,
                    max_limit: item.constraints.max_limit,
                    min_limit: item.constraints.min_limit,
                    choice_type: item.constraints.choice_type,
                }),
            };
        }
    });
    const vendorInformationFromBackend = baseQueryReturnValue.section_list.find(
        (section) => section.name === 'Vendor Information'
    );

    response.sections.VENDOR_INFORMATION.label =
        vendorInformationFromBackend &&
        vendorInformationFromBackend.alternate_name
            ? vendorInformationFromBackend.alternate_name
            : 'Vendor Information';
    const vendorInformationItemsfromBackend =
        vendorInformationFromBackend!.section_items
            // .filter((item) => item.parent_section_item === null)
            .filter((item) => ![''].includes(item.name));

    vendorInformationItemsfromBackend.forEach((item) => {
        if (
            Object.keys(
                RequisitionNewTemplateStandardFieldBackendNames
            ).includes(item.name)
        ) {
            if (!item.additional_information.is_hidden) {
                response.sections.VENDOR_INFORMATION.fieldList.push({
                    fieldType: 'STANDARD',
                    field: RequisitionNewTemplateStandardFieldBackendNames[
                        item.name
                    ],
                });
                const formattedData = {
                    label: item.alternate_name
                        ? item.alternate_name
                        : item.name,
                    description: item.description,
                    buyerSettings: {
                        required: item.is_required,
                        showField: !(
                            item.additional_information.is_hidden ?? false
                        ),
                    },
                    sellerSettings: {
                        negotiable:
                            !(item.additional_information.is_hidden ?? false) &&
                            (item.additional_information.is_visible ?? true) &&
                            (item.additional_information.is_negotiable ?? true),
                        showField:
                            !(item.additional_information.is_hidden ?? false) &&
                            (item.additional_information.is_visible ?? true),
                    },
                    fieldLevel: item.field_level,
                };
                response.fieldSettings.standardFields = {
                    ...response.fieldSettings.standardFields,
                    [RequisitionNewTemplateStandardFieldBackendNames[
                        item.name
                    ]]: formattedData,
                };
            }
        } else {
            response.sections.ADDITIONAL_DETAILS.fieldList.push({
                fieldType: 'CUSTOM',
                field: item.name,
            });
            response.fieldSettings.customFields[item.name] = {
                label: item.alternate_name ? item.alternate_name : item.name,
                description: item.description,
                buyerSettings: {
                    required: item.is_required,
                    showField: !(
                        item.additional_information.is_hidden ?? false
                    ),
                },
                sellerSettings: {
                    negotiable:
                        !(item.additional_information.is_hidden ?? false) &&
                        (item.additional_information.is_visible ?? true) &&
                        (item.additional_information.is_negotiable ?? true),
                    showField:
                        !(item.additional_information.is_hidden ?? false) &&
                        (item.additional_information.is_visible ?? true),
                    isMandatory: item.is_mandatory,
                },
                type: convertTemplateConstraintsItemToCustomFIeldType(
                    item.constraints.field_type
                ),
                constraints: getFieldTypeContraintsFromBackend({
                    field_type: item.constraints.field_type,
                    choices: item.constraints.choices,
                    max_limit: item.constraints.max_limit,
                    min_limit: item.constraints.min_limit,
                    choice_type: item.constraints.choice_type,
                }),
            };
        }
    });
    return response;
};
