import { get } from '../../Utils/api';
import {
    CustomTemplateTypes,
    ICustomTemplateList,
} from '../Interfaces/CustomTemplate.interface';

export const getAllTemplatesForEntity = (
    entity_id: string,
    template_type: CustomTemplateTypes[]
): Promise<ICustomTemplateList[]> => {
    return new Promise(async (resolve, reject) => {
        try {
            const arr = template_type.map(
                (template) => `template_type[]=${template}&`
            );
            let resp = await get<any>(
                `/templates/entity/${entity_id}/?${arr.join('')}items=true`
            );
            resolve(resp.data);
        } catch (err) {
            reject();
        }
    });
};
