import { DynamicPricingAnalyticsHookDataType } from '../Interfaces/DynamicAnalyticsInterfaces.model';
import { IHistoricalAnalyticsChart } from '../Interfaces/IHistoricalAnalytics.model';
import {
    getItemFilter,
    getMeasurementUnitFilter,
    getPoIssueDateFilter,
} from './CommonFilters';

export const getInitItemPriceGraphSettings = (
    hookData: DynamicPricingAnalyticsHookDataType
): IHistoricalAnalyticsChart => {
    return {
        chartType: 'bar',
        title: 'Item Price',
        chartId: 'itemPriceAnalytics',
        axisLabelY: '',
        axisLabelX: '',
        currencySettings: {
            showTargetCurrency: false,
            targetCurrency: hookData.currencyDetails,
            filterCurrenciesIds: [],
        },
        filterContoller: {
            fixedFilters: [
                getItemFilter({
                    enterpriseItemIds: [hookData.enterpriseItemUuid],
                }),
                getMeasurementUnitFilter({
                    measurementUnitId: hookData.measurementUnitUuid,
                }),
                {
                    filterId: '',
                    filterType: 'notNull',
                    filterLabel: '',
                    filterFields: {
                        metricFieldType: 'universal',
                        fieldName: 'po__purchase_order_id',
                    },
                    filterApplied: true,
                },
                {
                    filterId: '',
                    filterType: 'multiselectDropdown',
                    filterLabel: '',
                    filterFields: {
                        metricFieldType: 'universal',
                        fieldName: 'po__status',
                    },
                    filterApplied: true,
                    modifierType: 'CONSOLIDATED',
                    modifierValue: 'IN',
                    values: ['ISSUED', 'ONGOING', 'TERMINATED', 'COMPLETED'],
                },
                {
                    filterId: '',
                    filterType: 'multiselectDropdown',
                    filterLabel: '',
                    filterFields: {
                        metricFieldType: 'universal',
                        fieldName: 'po__purchase_order_type',
                    },
                    filterApplied: true,
                    modifierType: 'CONSOLIDATED',
                    modifierValue: 'NOT_IN',
                    values: ['STAGING'],
                },
            ],
            customizableFilters: {
                categoryConnector: 'AND',
                categories: [
                    {
                        categoryLabel: 'Date Range',
                        filterConnector: 'AND',
                        filters: [getPoIssueDateFilter({})],
                        expanded: true,
                    },
                ],
            },
        },
        metrics: [
            {
                metricId: 'quantity',
                display: 'bar',
                displayModifier: 'none',
                metricLegendLabel: 'Quantity',
                metricDefinition: {
                    metricType: 'base',
                    metricField: 'po_item__quantity_information__quantity',
                    aggregation: 'avg',
                },
            },
            {
                metricId: 'effectiveRate',
                display: 'bar',
                displayModifier: 'currency',
                metricLegendLabel: 'Effective Rate',
                metricDefinition: {
                    metricType: 'base',
                    metricField:
                        'po_item__pricing_information__total / po_item__quantity_information__quantity',
                    aggregation: 'avg',
                },
            },
        ],
        groupBy: [
            {
                type: 'str',
                field: 'po_item__purchase_order_item_id',
                includeInGroupName: false,
            },
            {
                type: 'str',
                field: 'po__custom_purchase_order_id',
                includeInGroupName: true,
            },
            {
                type: 'date',
                field: 'po__submission_datetime',
                period: 'date',
            },
            {
                type: 'str',
                field: 'seller_entity__entity_name',
                includeInGroupName: true,
            },
            {
                type: 'str',
                field: 'currency_code__entry_id',
                includeInGroupName: false,
            },
        ],
        groupSort: {
            sortBy: 'none',
            sortOrder: 'asc',
        },
        groupFilter: {
            filterBy: 'none',
            filterNumber: 0,
            customFilter: '',
        },
    };
};
