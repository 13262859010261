import produce, { current } from 'immer';
import { IUserDefaultsState } from '../Slices/userDefaultsSlice';

export enum UserDefaultsActions {
    UPDATE_USER_DEFAULTS = 'UPDATE_USER_DEFAULTS',
    UPDATE_USER_NUMBER_FORMAT = 'USER_USER_NUMBER_FORMAT',
    UPDATE_ENTERPRISE_ID = 'UPDATE_ENTERPRISE_ID',
}

export interface IUpdateUserDefaultsAction {
    type: UserDefaultsActions.UPDATE_USER_DEFAULTS;
    value: IUserDefaultsState | null;
}
export interface IUpdateUserDefaultsActionEnterPriseId {
    type: UserDefaultsActions.UPDATE_ENTERPRISE_ID;
    value: string;
}
export interface IUserDefaultUpdateNumberFormatAction {
    type: UserDefaultsActions.UPDATE_USER_NUMBER_FORMAT;
    value: string;
}

export interface IUserDefaultsActions {
    type: string;
    payload:
        | IUpdateUserDefaultsAction
        | IUserDefaultUpdateNumberFormatAction
        | IUpdateUserDefaultsActionEnterPriseId;
}

export const updateUserDefaultsReducer = produce(
    (state, action: IUserDefaultsActions) => {
        switch (action.payload.type) {
            case UserDefaultsActions.UPDATE_USER_DEFAULTS:
                state = action.payload.value;
                return state;
            case UserDefaultsActions.UPDATE_ENTERPRISE_ID:
                if (state) {
                    (state as IUserDefaultsState).enterpriseId =
                        action.payload.value;
                }
                break;
            case UserDefaultsActions.UPDATE_USER_NUMBER_FORMAT: {
                let prevState = state ? { ...current(state) } : {};
                state = {
                    prevState,
                    number_format: action.payload.value,
                };
                return state;
            }
        }
    }
);
