import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { HookStateValue, useHookState } from '../../Common/Hooks/StateHook';
import { TQuota } from '../Interfaces/FeaturesInterface';
import { fetchQuota } from '../Services/FeatureQuota.service';

export const useFeatureQuota = () => {
    const { hookState, updateHookState } = useHookState(HookStateValue.LOADING);
    const [quota, setQuota] = useState<TQuota>({});

    const getQuotaData = useCallback(async () => {
        try {
            const quotaData: TQuota = await fetchQuota();
            setQuota(quotaData);
            updateHookState(HookStateValue.READY);
        } catch (error) {
            toast.error('Error fetching quota data');
            updateHookState(HookStateValue.ERROR);
        }
    }, [updateHookState]);

    // const quoteLimitExceeded = useMemo(() => {
    //     return quota.used === quota.max;
    // }, [quota]);

    return {
        getQuotaData,
        quota,
        // quoteLimitExceeded,
        quotaHookState: hookState,
    };
};
