import { Box, FormControl, FormHelperText } from '@mui/material';
import { FWMenuItem } from '../../../Common/FWCustomMenu';
import { FWTooltip } from '../../../Common/FWCustomTooltip';
import FWSelect from '../../../Common/FWSelect';
import { FWTypography } from '../../../Common/FWTypograhy';
import { IUserDetails } from '../../../Models/UserDetails.model';
import {
    IUserProfileError,
    IUserProfileKeys,
} from '../Interfaces/UserProfile.model';

interface IUserDefaults {
    selectableEntities: { [key: string]: string };
    selectableHomePages: { [key: string]: string };
    user_details: IUserDetails;
    updateDetails: (field: IUserProfileKeys, value: any) => void;
    errors: IUserProfileError;
}

export default function UserDefaults(props: IUserDefaults) {
    return (
        <>
            <Box>
                <FWTypography color="text.secondary">
                    Primary entity
                </FWTypography>
                <FormControl error={'default_entity' in props.errors} fullWidth>
                    <FWTooltip
                        title={
                            !props.selectableEntities ||
                            Object.keys(props.selectableEntities).length < 1
                                ? 'No permisssions assigned for any entity. Contact your admin for assistance'
                                : ''
                        }
                    >
                        <div>
                            <FWSelect
                                fullWidth
                                value={
                                    props.user_details.defaults!
                                        .primary_entity_id
                                }
                                onChange={(e) => {
                                    props.updateDetails(
                                        'default_entity',
                                        e.target.value
                                    );
                                }}
                            >
                                {Object.keys(props.selectableEntities).map(
                                    (entity_id, idx) => (
                                        <FWMenuItem value={entity_id} key={idx}>
                                            {
                                                props.selectableEntities[
                                                    entity_id
                                                ]
                                            }
                                        </FWMenuItem>
                                    )
                                )}
                            </FWSelect>
                        </div>
                    </FWTooltip>
                    {'default_entity' in props.errors && (
                        <FormHelperText>
                            {props.errors.default_entity?.msg}
                        </FormHelperText>
                    )}
                </FormControl>
            </Box>
            <Box>
                <FWTypography color="text.secondary">
                    Default homepage
                </FWTypography>
                <FormControl
                    disabled={
                        props.user_details.defaults!.primary_entity_id === ''
                    }
                    error={'default_homepage' in props.errors}
                    fullWidth
                >
                    <FWTooltip
                        title={
                            !props.selectableHomePages ||
                            Object.keys(props.selectableHomePages).length < 1
                                ? 'No permissions assigned for any module. Contact your admin for assistance'
                                : ''
                        }
                    >
                        <div>
                            <FWSelect
                                fullWidth
                                value={props.user_details.defaults!.homepage}
                                onChange={(e) => {
                                    props.updateDetails(
                                        'default_homepage',
                                        e.target.value
                                    );
                                }}
                            >
                                {Object.keys(props.selectableHomePages).map(
                                    (home_page_permission, idx) => (
                                        <FWMenuItem
                                            value={home_page_permission}
                                            key={idx}
                                        >
                                            {
                                                props.selectableHomePages[
                                                    home_page_permission
                                                ]
                                            }
                                        </FWMenuItem>
                                    )
                                )}
                            </FWSelect>
                        </div>
                    </FWTooltip>
                    {'default_homepage' in props.errors && (
                        <FormHelperText>
                            {props.errors.default_homepage?.msg}
                        </FormHelperText>
                    )}
                </FormControl>
            </Box>
            <Box>
                <FWTypography color="text.secondary">
                    Default number format
                </FWTypography>
                <FormControl
                    error={'default_number_format' in props.errors}
                    fullWidth
                >
                    <FWSelect
                        fullWidth
                        value={props.user_details.defaults!.number_format}
                        onChange={(e) => {
                            props.updateDetails(
                                'default_number_format',
                                e.target.value
                            );
                        }}
                    >
                        <FWMenuItem value="USD">
                            Standard (
                            {Intl.NumberFormat('en-US', {
                                minimumFractionDigits: 2,
                                style: 'currency',
                                currency: 'USD',
                            }).format(1000000.0)}
                            )
                        </FWMenuItem>
                        <FWMenuItem value="INR">
                            Indian (
                            {Intl.NumberFormat('en-IN', {
                                minimumFractionDigits: 2,
                                style: 'currency',
                                currency: 'INR',
                            }).format(1000000.0)}
                            )
                        </FWMenuItem>
                        <FWMenuItem value="EUR">
                            European (
                            {Intl.NumberFormat('de-DE', {
                                minimumFractionDigits: 2,
                                style: 'currency',
                                currency: 'EUR',
                            }).format(1000000.0)}
                            )
                        </FWMenuItem>
                    </FWSelect>
                    {'default_number_format' in props.errors && (
                        <FormHelperText>
                            {props.errors.default_number_format?.msg}
                        </FormHelperText>
                    )}
                </FormControl>
            </Box>

            <Box>
                {/* <FWTypography color="text.secondary">
                    Default timezone
                </FWTypography>
                <FWSelect fullWidth>
                    <FWMenuItem>Buyer events</FWMenuItem>
                </FWSelect> */}
            </Box>
        </>
    );
}
