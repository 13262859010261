import {
    Accordion,
    AccordionDetails,
    AccordionDetailsProps,
    AccordionProps,
    AccordionSummary,
    AccordionSummaryProps,
    styled,
} from '@mui/material';

const StyledFWAccordion = styled(Accordion)((args) => ({
    '&': {
        padding: '4px 16px 4px 16px',
        border: '1px solid',
        borderColor: args.theme.palette.dividerColor.main,
        background: 'transparent',
    },
}));

const StyledFWAccordionSummary = styled(AccordionSummary)({
    '&': {
        padding: '0',
        margin: '5px 0',
    },
    '& .MuiAccordionSummary-content': {
        margin: '0',
        width: '100%',
    },
});

const StyledFWAccordionDetails = styled(AccordionDetails)({
    '&': {
        padding: '0 0 16px',
    },
});

export const FWAccordion = (props: AccordionProps) => (
    <StyledFWAccordion disableGutters {...props} />
);

export const FWAccordionSummary = (props: AccordionSummaryProps) => (
    <StyledFWAccordionSummary
        expandIcon={
            <i
                className="bi bi-chevron-down"
                style={{ fontSize: '1.5rem' }}
            ></i>
        }
        {...props}
    />
);

export const FWAccordionDetails = (props: AccordionDetailsProps) => (
    <StyledFWAccordionDetails {...props} />
);

// TODO: delete the below components and replce ytheir usages with the above
export const CustomAccordionSummary = ({
    icon,
    children,
    props,
}: {
    icon: boolean;
    children: any;
    props: any;
}) => {
    return (
        <AccordionSummary
            expandIcon={
                icon ? (
                    <i
                        style={{ fontSize: '1.5rem' }}
                        className="bi bi-chevron-down"
                    ></i>
                ) : (
                    <></>
                )
            }
            {...props}
        >
            {children}
        </AccordionSummary>
    );
};

export const CustomBidAccordionSummary = (props: any) => {
    return (
        <AccordionSummary
            expandIcon={
                <i
                    style={{ fontSize: '1.2rem', marginTop: '12px' }}
                    className="bi bi-chevron-down"
                ></i>
            }
            {...props}
        >
            {props.children}
        </AccordionSummary>
    );
};
