import { Box } from '@mui/material';
import {
    FWAccordion,
    FWAccordionDetails,
    FWAccordionSummary,
} from '../../Common/FWAccordian';
import { FWTooltip } from '../../Common/FWCustomTooltip';

const ExpandableMenuItem = ({
    open,
    toggleFunction,
    title,
    subMenu,
    showBorder = true,
    menuFor,
    showIcon = true,
    showToolTip = false,
    tooltipTitle = '',
}: {
    open: boolean;
    toggleFunction: () => void;
    title: React.ReactElement;
    subMenu: React.ReactElement;
    showBorder?: boolean;
    menuFor?: string;
    showIcon?: boolean;
    showToolTip?: boolean;
    tooltipTitle?: string;
}) => {
    return (
        <FWAccordion
            // expanded={expanded}
            // onChange={() => setExpanded(!expanded)}
            expanded={open}
            sx={{
                overflow: 'hidden !important',
                borderRadius:
                    menuFor === 'eventPermissionHistory'
                        ? '8px'
                        : '0px 12px 0 0 !important',
                padding:
                    menuFor !== 'subMenuEventPermissioHistory'
                        ? '8px !important'
                        : '8px 0px !important',
                border:
                    showBorder && menuFor === 'eventPermissionHistory'
                        ? '1px solid #e0e0e0 !important'
                        : 'none !important',

                borderBottom: showBorder
                    ? '1px solid #e0e0e0 !important'
                    : 'none !important',
            }}
        >
            <FWAccordionSummary
                onClick={() => {
                    toggleFunction();
                }}
                sx={{
                    padding: '0px!important',
                    margin: '0 !important',
                    minHeight: 'auto',
                }}
                expandIcon={<></>}
            >
                <Box
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                    width={'100%'}
                >
                    {title}
                    {showToolTip ? (
                        <FWTooltip title={tooltipTitle}>
                            <Box
                                sx={{
                                    transform: open
                                        ? 'rotateZ(180deg)'
                                        : 'unset',
                                    transition: 'all 0.2 ease-in-out',
                                }}
                            >
                                {showIcon && (
                                    <i className="bi bi-chevron-down"></i>
                                )}
                            </Box>
                        </FWTooltip>
                    ) : (
                        <Box
                            sx={{
                                transform: open ? 'rotateZ(180deg)' : 'unset',
                                transition: 'all 0.2 ease-in-out',
                            }}
                        >
                            {showIcon && <i className="bi bi-chevron-down"></i>}
                        </Box>
                    )}
                </Box>
            </FWAccordionSummary>
            <FWAccordionDetails
                sx={{
                    padding: '8px 0px 0px 0px !important',
                    margin: '0 !important',
                }}
            >
                {subMenu}
            </FWAccordionDetails>
        </FWAccordion>
    );
};

export default ExpandableMenuItem;
