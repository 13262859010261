import { TemplateItem } from '../../BuyerTemplates/Interfaces/TemplatePageInterfaces';
import { IRFQTemplateSectionItemBackendResponse } from '../../Global/Interfaces/TemplateInterface';
import {
    AllocationTypeEnum,
    CostTypeEnum,
    IAdditionalCost,
    IAdditionalCostsBackend,
    IAdditionalCostsBackendTemplate,
    IAdditionalCostsWithValue,
    IDiscount,
    OperatorType,
} from '../models/AdditionalCost.model';

export const calculateTotalAdditionalCostAndDeviation = ({
    baseQuantity,
    baseRate,
    additionalCost,
}: {
    baseQuantity: string | number;
    baseRate: string | number;
    additionalCost: IAdditionalCostsWithValue;
}) => {
    let effectiveRateDeviation = 0;
    let additionalCostValue = 0;

    baseQuantity = +baseQuantity;
    baseRate = +baseRate;

    if (additionalCost.costType === CostTypeEnum.PERCENTAGE) {
        let baseTotal = Number(baseQuantity) * Number(baseRate);
        let TotalWithAdditionalCost =
            Number(baseQuantity) *
            Number(baseRate * (1 + Number(additionalCost.value) / 100));
        additionalCostValue = TotalWithAdditionalCost - baseTotal;
        if (baseQuantity === 0) {
            effectiveRateDeviation = 0;
        } else {
            effectiveRateDeviation = additionalCostValue / Number(baseQuantity);
        }
    } else {
        if (
            additionalCost.allocationType ===
            AllocationTypeEnum.OVERALL_QUANTITY
        ) {
            additionalCostValue = Number(+additionalCost.value);
            if (baseQuantity === 0) {
                effectiveRateDeviation = 0;
            } else {
                effectiveRateDeviation =
                    additionalCostValue / Number(baseQuantity);
            }
        } else {
            effectiveRateDeviation = Number(+additionalCost.value);
            additionalCostValue =
                Number(+additionalCost.value) * Number(baseQuantity);
        }
    }

    return {
        effectiveRateDeviation,
        additionalCostValue,
    };
};

export const calculateTotalAdditionalCostAndDeviationEventLevel = ({
    eventTotal,
    eventSubTotal,
    additionalCost,
    type,
    applyThreshold = true,
}: {
    eventTotal: string | number;
    eventSubTotal: string | number;
    additionalCost: IAdditionalCostsWithValue;
    type: 'AdditionalCost' | 'Discount' | 'Taxes';
    applyThreshold?: boolean;
}) => {
    let effectiveRateDeviation = 0;
    let additionalCostValue = 0;

    eventTotal = +eventTotal;
    eventSubTotal = +eventSubTotal;

    let baseTotal =
        additionalCost.threshold_applied_on === 'TOTAL' || !applyThreshold
            ? eventTotal
            : eventSubTotal;
    if (additionalCost.costType === CostTypeEnum.PERCENTAGE) {
        additionalCostValue =
            eventTotal * (1 + Number(additionalCost.value) / 100);
        additionalCostValue = additionalCostValue - eventTotal;
    } else {
        additionalCostValue = Number(+additionalCost.value);
    }

    if (additionalCost.threshold !== null) {
        if (type === 'Discount') {
            additionalCostValue =
                Number(additionalCost.threshold) > baseTotal
                    ? 0
                    : additionalCostValue;
        } else if (type === 'AdditionalCost') {
            additionalCostValue =
                Number(additionalCost.threshold) < baseTotal
                    ? 0
                    : additionalCostValue;
        }
    }

    if (additionalCostValue < 0) {
        additionalCostValue = 0;
    }
    effectiveRateDeviation = additionalCostValue;
    return {
        effectiveRateDeviation,
        additionalCostValue,
    };
};

export const calculateAddtionalCost = (
    params:
        | {
              baseQuantity: string | number;
              baseRate: string | number;
              additionalCost: IAdditionalCostsWithValue[];
              type: 'ADDITIONAL_COST' | 'DISCOUNT';
          }
        | {
              baseQuantity: string | number;
              baseRate: string | number;
              additionalCost: IAdditionalCostsWithValue[];
              type: 'TAX';
              discountAdditionalCost: IAdditionalCostsWithValue[];
          }
) => {
    let { baseQuantity, baseRate, additionalCost, type } = params;
    let discountAdditionalCost: IAdditionalCostsWithValue[] | undefined =
        params.type === 'TAX' ? params.discountAdditionalCost : undefined;

    baseQuantity = +baseQuantity;
    baseRate = +baseRate;

    let totalAdditionalCostAndDeviation = 0;

    if (type === 'TAX' && discountAdditionalCost) {
        totalAdditionalCostAndDeviation =
            discountAdditionalCost?.reduce(
                (acc, curr) => {
                    const { additionalCostValue, effectiveRateDeviation } =
                        calculateTotalAdditionalCostAndDeviation({
                            additionalCost: curr,
                            baseRate: baseRate,
                            baseQuantity: baseQuantity,
                        });

                    return {
                        additionalCostValue:
                            acc.additionalCostValue + additionalCostValue,
                        effectiveRateDeviation:
                            acc.effectiveRateDeviation + effectiveRateDeviation,
                    };
                },
                { additionalCostValue: 0, effectiveRateDeviation: 0 }
            ).effectiveRateDeviation ?? 0;
    }

    return additionalCost
        ?.filter((cost) => cost.is_calculated ?? true)
        .reduce(
            (acc, curr) => {
                const { additionalCostValue, effectiveRateDeviation } =
                    calculateTotalAdditionalCostAndDeviation({
                        additionalCost: curr,
                        baseRate: +baseRate - totalAdditionalCostAndDeviation,
                        baseQuantity: baseQuantity,
                    });

                return {
                    additionalCostValue:
                        acc.additionalCostValue + additionalCostValue,
                    effectiveRateDeviation:
                        acc.effectiveRateDeviation + effectiveRateDeviation,
                };
            },
            { additionalCostValue: 0, effectiveRateDeviation: 0 }
        );
};

export const calculateAddtionalCostEventLevel = (params: {
    eventTotal: string | number;
    eventSubTotal: string | number;
    additionalCost: IAdditionalCostsWithValue[];
    type: 'ADDITIONAL_COST' | 'DISCOUNT' | 'TAX';
}) => {
    let { eventTotal, eventSubTotal, additionalCost, type } = params;

    const additionalCostsMap = {
        TAX: 'Taxes',
        DISCOUNT: 'Discount',
        ADDITIONAL_COST: 'AdditionalCost',
    };

    eventTotal = +eventTotal;
    eventSubTotal = +eventSubTotal;

    return additionalCost
        ?.filter((cost) => cost.is_calculated ?? true)
        .reduce(
            (acc, curr) => {
                const { additionalCostValue, effectiveRateDeviation } =
                    calculateTotalAdditionalCostAndDeviationEventLevel({
                        eventTotal: +eventTotal,
                        eventSubTotal: +eventSubTotal,
                        additionalCost: curr,
                        type: additionalCostsMap[type] as
                            | 'Taxes'
                            | 'AdditionalCost'
                            | 'Discount',
                    });

                return {
                    additionalCostValue:
                        acc.additionalCostValue + additionalCostValue,
                    effectiveRateDeviation:
                        acc.effectiveRateDeviation + effectiveRateDeviation,
                };
            },
            { additionalCostValue: 0, effectiveRateDeviation: 0 }
        );
};

// export const calculateTotalAddtionalCosts = ({
//     baseQuantity,
//     baseRate,
//     additionalCosts,
// }: {
//     baseQuantity: string | number;
//     baseRate: string | number;
//     additionalCosts: IAdditionalCostsWithValue[];
// }) => {
//     return additionalCosts.reduce(
//         (acc, cost) => {
//             const { additionalCostValue, effectiveRateDeviation } =
//                 calculateAddtionalCost({
//                     additionalCost: cost,
//                     baseQuantity,
//                     baseRate,
//                 });

//             return {
//                 effectiveRateDeviation:
//                     acc.effectiveRateDeviation + effectiveRateDeviation,
//                 additionalCostValue:
//                     acc.additionalCostValue + additionalCostValue,
//             };
//         },
//         {
//             effectiveRateDeviation: 0,
//             additionalCostValue: 0,
//         }
//     );
// };

export const additioncostDummyData: IAdditionalCost[] = [
    {
        allocationType: AllocationTypeEnum.OVERALL_QUANTITY,
        costName: 'cgst',
        costType: CostTypeEnum.ABSOLUTE_VALUE,
        additional_cost_id: null,
        cost_source: null,
    },
    {
        costName: 'sgst',
        costType: CostTypeEnum.PERCENTAGE,
        additional_cost_id: null,
        allocationType: null,
        cost_source: null,
    },
    {
        costName: 'shivang123',
        costType: CostTypeEnum.ABSOLUTE_VALUE,
        allocationType: AllocationTypeEnum.PER_UNIT,
        additional_cost_id: null,
        cost_source: null,
    },
];

export const discountDummyData: IDiscount[] = [
    {
        allocationType: AllocationTypeEnum.OVERALL_QUANTITY,
        costName: 'discount1',
        costType: CostTypeEnum.ABSOLUTE_VALUE,
        additional_cost_id: null,
        cost_source: null,
    },
    {
        costName: 'discount12',
        costType: CostTypeEnum.PERCENTAGE,
        allocationType: null,
        additional_cost_id: null,
        cost_source: null,
    },
    {
        costName: 'dis',
        costType: CostTypeEnum.ABSOLUTE_VALUE,
        allocationType: AllocationTypeEnum.PER_UNIT,
        additional_cost_id: null,
        cost_source: null,
    },
];

export const convertAdditionalCostBackendToFrontendSingle = (
    item: IAdditionalCostsBackend,
    processNumber = true
): IAdditionalCostsWithValue => {
    if (
        item.cost_type === CostTypeEnum.ABSOLUTE_VALUE &&
        item.allocation_type
    ) {
        return {
            costName: item.cost_name,
            costType: CostTypeEnum.ABSOLUTE_VALUE,
            allocationType: item.allocation_type,
            additional_cost_id: item?.additional_cost_id ?? null,
            cost_source: item?.cost_source,
            value: processNumber
                ? (+item.cost_value).toString()
                : item.cost_value,
        };
    } else {
        return {
            costName: item.cost_name,
            costType: CostTypeEnum.PERCENTAGE,
            cost_source: item?.cost_source,
            allocationType: null,
            additional_cost_id: item?.additional_cost_id ?? null,
            value: processNumber
                ? (+item.cost_value).toString()
                : item.cost_value,
        };
    }
};

export const convertAdditionalCostBackendToFrontend = (
    response: IAdditionalCostsBackend[],
    processNumber = true
): IAdditionalCostsWithValue[] => {
    if (response && response.length > 0)
        if ('cost_name' in response[0])
            return response.map((item): IAdditionalCostsWithValue => {
                if (
                    item.cost_type === CostTypeEnum.ABSOLUTE_VALUE &&
                    item.allocation_type
                ) {
                    return {
                        costName: item.cost_name,
                        costType: CostTypeEnum.ABSOLUTE_VALUE,
                        additional_cost_id: item?.additional_cost_id ?? null,
                        allocationType: item.allocation_type,
                        cost_source: item?.cost_source,
                        threshold: item.threshold,
                        threshold_applied_on: item.threshold_applied_on,
                        value: processNumber
                            ? item.cost_value === null
                                ? ''
                                : (+item.cost_value).toString()
                            : item.cost_value,
                    };
                } else {
                    return {
                        costName: item.cost_name,
                        additional_cost_id: item?.additional_cost_id ?? null,
                        costType: CostTypeEnum.PERCENTAGE,
                        cost_source: item?.cost_source,
                        allocationType: null,
                        threshold: item.threshold,
                        threshold_applied_on: item.threshold_applied_on,
                        value: processNumber
                            ? item.cost_value === null
                                ? ''
                                : (+item.cost_value).toString()
                            : item.cost_value,
                    };
                }
            });
        else return response as any[];
    else return [];
};

export const convertAdditionalCostBackendToFrontendForDraftBid = (
    response: IAdditionalCostsBackend[],
    processNumber = true
): IAdditionalCostsWithValue[] => {
    if (response)
        if ('costName' in response[0])
            return response.map((item: any): IAdditionalCostsWithValue => {
                if (
                    item.cost_type === CostTypeEnum.ABSOLUTE_VALUE &&
                    item.allocation_type
                ) {
                    return {
                        costName: item.cost_name,
                        costType: CostTypeEnum.ABSOLUTE_VALUE,
                        cost_source: item?.cost_source,
                        allocationType: item.allocation_type,
                        value: item.value,
                        additional_cost_id: item?.additional_cost_id ?? null,
                    };
                } else {
                    return {
                        costName: item.cost_name,
                        costType: CostTypeEnum.PERCENTAGE,
                        allocationType: null,
                        cost_source: item?.cost_source,
                        value: item.value,
                        additional_cost_id: item?.additional_cost_id ?? null,
                    };
                }
            });
        else return response as any[];
    else return [];
};

export const convertAdditionalCostFrontendToBackendSingle = (
    additionalCost: IAdditionalCostsWithValue,
    processNumber = true
): IAdditionalCostsBackend => {
    return {
        cost_name: additionalCost.costName,
        cost_type: additionalCost.costType,
        cost_source: additionalCost.cost_source,
        additional_cost_id: additionalCost.additional_cost_id,
        cost_value: processNumber
            ? (+additionalCost.value).toString()
            : additionalCost.value,
        allocation_type:
            additionalCost.costType === CostTypeEnum.ABSOLUTE_VALUE
                ? additionalCost.allocationType
                : null,
    };
};

export const convertAdditionalCostFrontendToBackend = (
    additionalCost: IAdditionalCostsWithValue[],
    processNumber = true
): IAdditionalCostsBackend[] => {
    return additionalCost
        .map((item) => ({
            cost_name: item.costName,
            cost_type: item.costType,
            cost_source: item.cost_source,
            additional_cost_id: item.additional_cost_id,
            threshold: item.threshold,
            threshold_applied_on: item.threshold_applied_on,
            cost_value: processNumber
                ? (+item.value)?.toFixed(0).toString()
                : item.value,
            allocation_type:
                item.costType === CostTypeEnum.ABSOLUTE_VALUE
                    ? item.allocationType
                    : null,
        }))
        .filter((item) => item.cost_name?.trim().length > 0);
};

export const converttemplateinformationIAdditionalCostsBackend: (
    listOfTemplateChildItems: TemplateItem[]
) => IAdditionalCostsBackendTemplate[] = (listOfTemplateChildItems) => {
    let res: IAdditionalCostsBackendTemplate[] = [];

    listOfTemplateChildItems.forEach((item) => {
        if (item.additional_information.additional_cost_information) {
            res.push({
                sequence: item.sequence,
                allocation_type:
                    item.additional_information.additional_cost_information
                        .allocation_type,
                cost_name: item.is_builtin_field
                    ? item.alternate_name
                    : item.name,
                is_required: item.is_required,
                is_visible: item.additional_information.is_visible,
                is_negotiable: item.additional_information.is_negotiable,
                cost_source:
                    item.additional_information.additional_cost_information
                        .cost_source,
                is_mandatory: item.is_mandatory,
                is_factwise_default: item.is_builtin_field,
                is_hidden: item.additional_information.is_hidden,
                cost_type:
                    item.additional_information.additional_cost_information
                        .cost_type,
                template_section_item_id: item.section_item_id,
                additional_cost_id:
                    item.additional_information.additional_cost_information
                        .additional_cost_id ?? null,
            });
        }

        if (item.additional_information.shipping_rate_information) {
            res.push({
                sequence: item.sequence,
                allocation_type:
                    item.additional_information.shipping_rate_information
                        .allocation_type,
                cost_name: item.name,
                is_required: item.is_required,
                is_mandatory: item.is_mandatory,
                is_factwise_default: item.is_builtin_field,
                is_hidden: item.additional_information.is_hidden,
                is_visible: item.additional_information.is_visible,
                is_negotiable: item.additional_information.is_negotiable,
                cost_source:
                    item.additional_information.additional_cost_information
                        ?.cost_source ?? null,
                cost_type:
                    item.additional_information.shipping_rate_information
                        .cost_type,
                template_section_item_id: item.section_item_id,
                additional_cost_id: item.additional_information
                    ?.additional_cost_information?.additional_cost_id
                    ? item.additional_information.additional_cost_information
                          .additional_cost_id
                    : null,
            });
        }

        if (item.additional_information.taxes_information) {
            res.push({
                sequence: item.sequence,

                allocation_type:
                    item.additional_information.taxes_information
                        .allocation_type,
                cost_source:
                    item.additional_information.additional_cost_information
                        ?.cost_source ?? null,
                cost_name: item.name,
                is_required: item.is_required,
                is_mandatory: item.is_mandatory,
                is_factwise_default: item.is_builtin_field,
                is_hidden: item.additional_information.is_hidden,
                is_visible: item.additional_information.is_visible,
                is_negotiable: item.additional_information.is_negotiable,
                cost_type:
                    item.additional_information.taxes_information.cost_type,
                template_section_item_id: item.section_item_id,
                additional_cost_id: item.additional_information
                    ?.additional_cost_information?.additional_cost_id
                    ? item.additional_information.additional_cost_information
                          .additional_cost_id
                    : null,
            });
        }
        if (item.additional_information.discount_information) {
            res.push({
                sequence: item.sequence,

                allocation_type:
                    item.additional_information.discount_information
                        .allocation_type,
                cost_source:
                    item.additional_information.additional_cost_information
                        ?.cost_source ?? null,
                cost_name: item.name,
                is_required: item.is_required,
                is_mandatory: item.is_mandatory,
                is_factwise_default: item.is_builtin_field,
                is_hidden: item.additional_information.is_hidden,
                is_visible: item.additional_information.is_visible,
                is_negotiable: item.additional_information.is_negotiable,
                cost_type:
                    item.additional_information.discount_information.cost_type,
                template_section_item_id: item.section_item_id,
                additional_cost_id: item.additional_information
                    ?.additional_cost_information?.additional_cost_id
                    ? item.additional_information.additional_cost_information
                          .additional_cost_id
                    : null,
            });
        }
    });

    return res;
};
export const getTemplateFrontOptionFromListOfTemplateItems = (
    listOfTemplateItems: IRFQTemplateSectionItemBackendResponse[]
) => {
    let additionalCost: ({
        is_required: boolean;
        sequence: number;
    } & IAdditionalCost)[] = [];

    listOfTemplateItems.forEach((item) => {
        if (!item.additional_information.is_hidden) {
            if (item.additional_information.additional_cost_information) {
                if (
                    item.additional_information.additional_cost_information
                        .cost_type === CostTypeEnum.ABSOLUTE_VALUE &&
                    item.additional_information.additional_cost_information
                        .allocation_type
                ) {
                    additionalCost.push({
                        sequence: item.sequence,
                        is_required: item.is_required,
                        costName: item.alternate_name,
                        costType: CostTypeEnum.ABSOLUTE_VALUE,
                        cost_source:
                            item.additional_information
                                .additional_cost_information?.cost_source ??
                            null,
                        additional_cost_id:
                            item.additional_information
                                .additional_cost_information.additional_cost_id,
                        allocationType:
                            item.additional_information
                                .additional_cost_information.allocation_type,
                        formula: undefined,
                    });
                } else {
                    additionalCost.push({
                        sequence: item.sequence,
                        costName: item.alternate_name,
                        costType: CostTypeEnum.PERCENTAGE,
                        cost_source:
                            item.additional_information
                                .additional_cost_information?.cost_source ??
                            null,
                        allocationType: null,
                        is_required: item.is_required,
                        additional_cost_id:
                            item.additional_information
                                .additional_cost_information.additional_cost_id,
                    });
                }
            }

            if (item.additional_information.shipping_rate_information) {
                if (
                    item.additional_information.shipping_rate_information
                        .cost_type === CostTypeEnum.ABSOLUTE_VALUE &&
                    item.additional_information.shipping_rate_information
                        .allocation_type
                ) {
                    additionalCost.push({
                        sequence: item.sequence,
                        costName: item.alternate_name,
                        costType: CostTypeEnum.ABSOLUTE_VALUE,
                        cost_source:
                            item.additional_information
                                .additional_cost_information?.cost_source ??
                            null,
                        allocationType:
                            item.additional_information
                                .shipping_rate_information.allocation_type,
                        is_required: item.is_required,
                        additional_cost_id:
                            item.additional_information
                                .shipping_rate_information.additional_cost_id,
                    });
                } else {
                    additionalCost.push({
                        sequence: item.sequence,
                        costName: item.alternate_name,
                        cost_source:
                            item.additional_information
                                .additional_cost_information?.cost_source ??
                            null,
                        costType: CostTypeEnum.PERCENTAGE,
                        allocationType: null,
                        is_required: item.is_required,
                        additional_cost_id:
                            item.additional_information
                                .shipping_rate_information.additional_cost_id,
                    });
                }
            }

            if (item.additional_information.taxes_information) {
                if (
                    item.additional_information.taxes_information.cost_type ===
                        CostTypeEnum.ABSOLUTE_VALUE &&
                    item.additional_information.taxes_information
                        .allocation_type
                ) {
                    additionalCost.push({
                        sequence: item.sequence,
                        costName: item.alternate_name,
                        costType: CostTypeEnum.ABSOLUTE_VALUE,
                        cost_source:
                            item.additional_information
                                .additional_cost_information?.cost_source ??
                            null,
                        allocationType:
                            item.additional_information.taxes_information
                                .allocation_type,
                        is_required: item.is_required,
                        additional_cost_id: null,
                    });
                } else {
                    additionalCost.push({
                        sequence: item.sequence,
                        costName: item.alternate_name,
                        costType: CostTypeEnum.PERCENTAGE,
                        cost_source:
                            item.additional_information
                                .additional_cost_information?.cost_source ??
                            null,
                        is_required: item.is_required,
                        allocationType: null,
                        additional_cost_id: null,
                    });
                }
            }
            if (item.additional_information.discount_information) {
                if (
                    item.additional_information.discount_information
                        .cost_type === CostTypeEnum.ABSOLUTE_VALUE &&
                    item.additional_information.discount_information
                        .allocation_type
                ) {
                    additionalCost.push({
                        sequence: item.sequence,
                        costName: item.alternate_name,
                        costType: CostTypeEnum.ABSOLUTE_VALUE,
                        cost_source:
                            item.additional_information
                                .additional_cost_information?.cost_source ??
                            null,
                        allocationType:
                            item.additional_information.discount_information
                                .allocation_type ?? AllocationTypeEnum.PER_UNIT,
                        is_required: item.is_required,
                        additional_cost_id: null,
                    });
                } else {
                    additionalCost.push({
                        sequence: item.sequence,
                        costName: item.alternate_name,
                        costType: CostTypeEnum.PERCENTAGE,
                        cost_source:
                            item.additional_information
                                .additional_cost_information?.cost_source ??
                            null,
                        allocationType: null,
                        is_required: item.is_required,
                        additional_cost_id: null,
                    });
                }
            }
        }
    });

    return additionalCost;
};

export const formulaSymbolToShow = (operatorType: OperatorType) => {
    switch (operatorType) {
        case OperatorType.ADD:
            return '+';
        case OperatorType.SUBTRACT:
            return '-';
        case OperatorType.MULTIPLY:
            return 'x';
        case OperatorType.DIVIDE:
            return '/';
        case OperatorType.OPENING_PARANTHESIS:
            return '(';
        case OperatorType.CLOSING_PARANTHESIS:
            return ')';
        default:
            return '^';
    }
};

export const formulaSymbolToMap = (operator: string) => {
    switch (operator) {
        case '+':
            return OperatorType.ADD;
        case '-':
            return OperatorType.SUBTRACT;
        case 'x':
            return OperatorType.MULTIPLY;
        case '/':
            return OperatorType.DIVIDE;
        case '(':
            return OperatorType.OPENING_PARANTHESIS;
        case ')':
            return OperatorType.CLOSING_PARANTHESIS;
        default:
            return OperatorType.POWER;
    }
};
