import { Badge, Box, IconButton } from '@mui/material';
import { useContext, useState } from 'react';
import { FWMenu, FWMenuItem } from '../../Common/FWCustomMenu';
import { FWTooltip } from '../../Common/FWCustomTooltip';
import FWIcon from '../../Common/FWIcon';
import { HookStateValue } from '../../Common/Hooks/StateHook';
import { AuthContext } from '../../Contexts/AuthContext';
import useQuickUserActions from '../Hooks/useQuickUserActions';
import QuickApproveUserPopup from './QuickApproveUserPopup';
import QuickInviteUserPopup from './QuickInviteUserPopup';

export default function QuickUserActionsContainer() {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) =>
        setAnchorEl(event.currentTarget);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const {
        hookState,
        popupState,
        setPopupState,
        allEnterpriseUsers,
        approvalPendingUsers,
        enterpriseEventCount,
        refreshData,
        currentPortal,
    } = useQuickUserActions();

    const { checkPermission } = useContext(AuthContext);
    const isGlobalAdmin = checkPermission('GLOBAL_ADMIN', null, null, null);
    const showButton =
        hookState.state === HookStateValue.READY &&
        ((approvalPendingUsers.length > 0 && isGlobalAdmin) ||
            (allEnterpriseUsers.length < 10 && enterpriseEventCount < 50));

    if (!showButton) {
        return <></>;
    }

    // This was added as a condition in-place-of 'true'
    // process.env.REACT_APP_ENV !== 'production' &&
    //             process.env.REACT_APP_ENV !== 'newdbtest1' &&
    return (
        <>
            {true && (
                <FWTooltip title="Invite / approve users">
                    <Box className="flex flex--aic">
                        <IconButton color="primary" onClick={handleClick}>
                            {approvalPendingUsers.length > 0 ? (
                                <Badge variant="dot" color="error">
                                    <FWIcon
                                        name="bi bi-people-fill"
                                        size={23}
                                    />
                                </Badge>
                            ) : (
                                <FWIcon name="bi bi-people-fill" size={23} />
                            )}
                        </IconButton>
                    </Box>
                </FWTooltip>
            )}

            <FWMenu
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                sx={{
                    maxHeight: '80vh',
                    padding: 0,
                    top: '4px',
                    width: 230,
                }}
            >
                <FWMenuItem
                    onClick={() => {
                        setPopupState('invite');
                        handleClose();
                    }}
                >
                    <Box color="primary.main">
                        Invite{' '}
                        {currentPortal && currentPortal === 'SELLER'
                            ? 'Your Colleagues'
                            : 'users'}
                    </Box>
                </FWMenuItem>
                {isGlobalAdmin && (
                    <FWMenuItem
                        onClick={() => {
                            setPopupState('approve');
                            handleClose();
                        }}
                        disabled={approvalPendingUsers.length === 0}
                    >
                        <Box color="primary.main">
                            {`Approve users ${
                                approvalPendingUsers.length
                                    ? `(${approvalPendingUsers.length})`
                                    : ''
                            }`}
                        </Box>
                    </FWMenuItem>
                )}
            </FWMenu>
            <QuickInviteUserPopup
                isOpen={popupState === 'invite'}
                onClose={() => setPopupState('none')}
                isSellerSide={
                    currentPortal ? currentPortal === 'SELLER' : false
                }
                isGlobalAdmin={isGlobalAdmin}
                onSuccess={() => {
                    refreshData();
                }}
            />
            <QuickApproveUserPopup
                isOpen={popupState === 'approve'}
                onClose={() => setPopupState('none')}
                onSuccess={() => {
                    refreshData();
                }}
            />
        </>
    );
}
