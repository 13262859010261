import { Box, Divider, Grid } from '@mui/material';
import { isUndefined } from 'lodash';
import React from 'react';
import { FWTooltip } from '../../Common/FWCustomTooltip';
import FWPopover from '../../Common/FWPopover';
import { FWTypography } from '../../Common/FWTypograhy';
import { FWNumberFormatter } from '../../Common/Utils/CurrencyUtils';

interface ItaxPopup {
    tax_list: {
        charge_name: string | null;
        charge_value: string;
    }[];
    subTotal: number;
    currency_code_abbreviation: string;
    anchorEl: any;
    setAnchorEl: any;
    shipping_per_unit: string;
    quantity: string;
    formattedTotal: string;
    measurement_unit_name: string;
    discount?: number;
    templateInfo?: {
        includeShipping: boolean;
        shippingLabel: string;
        includeTaxes: boolean;
        taxesLabel: string;
    };
}

const formatCurrency = (value: number, currency_code_abbreviation: string) =>
    FWNumberFormatter(value, currency_code_abbreviation);

const ViewTaxAndShippingPopup = ({
    tax_list,
    subTotal,
    currency_code_abbreviation,
    anchorEl,
    setAnchorEl,
    shipping_per_unit,
    quantity,
    formattedTotal,
    measurement_unit_name,
    discount,
    templateInfo = {
        includeShipping: true,
        shippingLabel: 'Shipping',
        includeTaxes: true,
        taxesLabel: 'Taxes',
    },
}: ItaxPopup) => {
    const open = Boolean(anchorEl);
    const id = open ? 'tax-popover' : undefined;

    const handleClose = () => setAnchorEl(null);

    // get a total for all the taxes
    const getTotalTaxWithCurrency = React.useMemo(() => {
        let total_tax = 0;
        let tax = 0;
        tax_list.forEach((tax) => {
            total_tax += parseFloat(tax.charge_value);
        });
        if (subTotal) {
            tax = (total_tax / 100) * subTotal;
        } else {
            return `${total_tax}%`;
        }
        if (tax === 0) {
            return '-';
        } else if (currency_code_abbreviation) {
            return formatCurrency(tax, currency_code_abbreviation);
        } else {
            return tax.toFixed(2);
        }
    }, [subTotal, tax_list, currency_code_abbreviation]);

    // get value for each row tax
    const getTaxWithCurrency = (tax_perc: number) => {
        let tax = 0;
        if (subTotal) {
            tax = (tax_perc / 100) * subTotal;
        } else {
            return `${tax_perc}%`;
        }
        if (currency_code_abbreviation) {
            return formatCurrency(tax, currency_code_abbreviation);
        } else {
            return tax.toFixed(2);
        }
    };

    return (
        <FWPopover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
        >
            <div style={{ width: '500px' }}>
                {/* totals container */}
                <Grid
                    container
                    columnSpacing={1}
                    rowSpacing={1}
                    style={{
                        padding: '8px',
                    }}
                    alignItems="center"
                >
                    <Grid item xs={7}>
                        <FWTypography color="text.secondary">
                            Item subtotal:
                        </FWTypography>
                    </Grid>

                    <Grid item xs={5}>
                        <FWTypography textAlign="right">
                            {formatCurrency(
                                subTotal!,
                                currency_code_abbreviation!
                            )}
                        </FWTypography>
                    </Grid>

                    {templateInfo.includeTaxes && (
                        <>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                            <Grid item xs={7}>
                                <FWTypography color="text.secondary">
                                    {`Total ${templateInfo.taxesLabel}:`}
                                </FWTypography>
                            </Grid>
                            <Grid item xs={5}>
                                {tax_list.length > 0 ? (
                                    <FWTypography textAlign="right">
                                        + {getTotalTaxWithCurrency}
                                    </FWTypography>
                                ) : (
                                    <FWTypography
                                        textAlign="right"
                                        color="text.secondary"
                                    >
                                        Included in rate
                                    </FWTypography>
                                )}
                            </Grid>
                            {tax_list.length > 0 && (
                                <>
                                    {tax_list.map((tax, idx) => (
                                        <React.Fragment key={idx}>
                                            <Grid item xs={11} margin="0 auto">
                                                <Grid container>
                                                    <Grid item xs={4}>
                                                        <FWTypography color="text.secondary">
                                                            {tax.charge_name}
                                                        </FWTypography>
                                                    </Grid>
                                                    <Grid item xs={3}>
                                                        <FWTypography
                                                            textAlign="right"
                                                            color="text.secondary"
                                                        >
                                                            {tax.charge_value}%
                                                        </FWTypography>
                                                    </Grid>
                                                    <Grid item xs={5}>
                                                        <FWTypography
                                                            textAlign="right"
                                                            color="text.secondary"
                                                        >
                                                            {getTaxWithCurrency(
                                                                parseFloat(
                                                                    tax.charge_value
                                                                )
                                                            )}
                                                        </FWTypography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </React.Fragment>
                                    ))}
                                </>
                            )}
                        </>
                    )}

                    {templateInfo.includeShipping && (
                        <>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                            <Grid item xs={4} className="flex flex--aic">
                                <FWTypography color="text.secondary">
                                    {`${templateInfo.shippingLabel}:`}
                                </FWTypography>
                                &nbsp;
                                {+shipping_per_unit > 0 && (
                                    <>
                                        <FWTooltip
                                            title={`Includes taxes on ${templateInfo.shippingLabel}`}
                                        >
                                            <Box color="primary.main">
                                                <i className="bi bi-info-circle"></i>
                                            </Box>
                                        </FWTooltip>
                                    </>
                                )}
                            </Grid>
                            <Grid item xs={3}>
                                <FWTypography color="text.secondary">
                                    {+shipping_per_unit > 0
                                        ? `(${formatCurrency(
                                              parseFloat(shipping_per_unit),
                                              currency_code_abbreviation!
                                          )}/${measurement_unit_name})`
                                        : ''}
                                </FWTypography>
                            </Grid>
                            <Grid item xs={5}>
                                {+shipping_per_unit > 0 ? (
                                    <FWTypography textAlign="right">
                                        +{' '}
                                        {`${formatCurrency(
                                            +shipping_per_unit * +quantity,
                                            currency_code_abbreviation!
                                        )}`}
                                    </FWTypography>
                                ) : (
                                    <FWTypography
                                        textAlign="right"
                                        color="text.secondary"
                                    >
                                        {`Included in rate`}
                                    </FWTypography>
                                )}
                            </Grid>
                        </>
                    )}

                    {!isUndefined(discount) && discount > 0 && (
                        <>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                            <Grid item xs={7}>
                                <FWTypography color="text.secondary">
                                    Total discount:
                                </FWTypography>
                            </Grid>
                            <Grid item xs={5}>
                                <FWTypography textAlign="right">
                                    -{' '}
                                    {formatCurrency(
                                        discount,
                                        currency_code_abbreviation!
                                    )}
                                </FWTypography>
                            </Grid>
                        </>
                    )}
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={7}>
                        <FWTypography color="text.secondary">
                            Item total:
                        </FWTypography>
                    </Grid>
                    <Grid item xs={5}>
                        <FWTypography textAlign={'right'}>
                            {formattedTotal}
                        </FWTypography>
                    </Grid>
                </Grid>
            </div>
        </FWPopover>
    );
};

export default ViewTaxAndShippingPopup;
