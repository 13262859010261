import { Box, Grid, Typography } from '@mui/material';
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { PROJECT_GRAPH_DIMENSIONS } from './ProjectGraphsPopup';

ChartJS.register(ArcElement, Tooltip, Legend);

export default function ProjectPieChart() {
    const options = {
        // responsive: true,
        maintainAspectRatio: false,
    };
    const data = {
        labels: ['Contract', 'Internal operations', 'Capital'],
        datasets: [
            {
                label: '# of projects',
                data: [27, 43, 40],
                backgroundColor: [
                    'rgba(255, 99, 132,1)',
                    'rgba(75, 192, 192, 2)',
                    '#4F1271',
                ],
                borderColor: [
                    'rgba(255, 99, 132,1)',
                    'rgba(75, 192, 192, 1)',
                    '#4F1271',
                ],
                borderWidth: 1,
            },
        ],
    };
    return (
        <Grid
            sx={{
                cursor: 'pointer',
                transition: 'all 0.3s ease-in-out',
                '&:hover': {
                    boxShadow: '0px 3px 15px rgba(0,0,0,0.2)',
                },
            }}
            boxShadow={'0px 3px 15px rgb(0 0 0 / 7%)'}
            border={'3px solid transparent'}
            width={PROJECT_GRAPH_DIMENSIONS}
            height={PROJECT_GRAPH_DIMENSIONS}
            borderRadius={1}
            item
            padding={2}
            paddingTop={0}
            xs={5.8}
        >
            <Box paddingY={1}>
                <Typography variant="h6" sx={{ padding: 1, fontSize: '1rem' }}>
                    Category wise projects
                </Typography>
            </Box>

            <Box sx={{ height: '450px' }}>
                <Pie options={options} data={data} />
            </Box>
        </Grid>
    );
}
