import { cloneDeep } from 'lodash';
import { IAttachment } from '../../Models/RFQ.model';
import { createInvoiceInitialState } from '../Constants/helperFunctions';
import { IInvoiceTopLevelDetails } from '../Interfaces/Invoice.model';
import {
    INewInvoiceItemDetail,
    INewPostInvoiceDetails,
} from '../Interfaces/NewInvoice.model';
import { IUpdateInvoiceDetailsAction } from '../Interfaces/Shared.model';

export const updateInvoiceDetailsReducer = (
    state: INewPostInvoiceDetails,
    action: IUpdateInvoiceDetailsAction
) => {
    let newState = { ...state, invoice_items: [...state.invoice_items] };
    switch (action.payload.type) {
        case 'UPDATE_IDENTIFICATIONS':
            newState.seller_identifications = action.payload.value as string[];
            break;
        case 'UPDATE_CUSTOM_IDENTIFICATIONS':
            newState.seller_custom_identifications = action.payload.value as {
                identification_name: string;
                identification_value: string;
            }[];
            break;
        case 'UPDATE_DETAILS':
            newState.custom_invoice_id = (
                action.payload.value as IInvoiceTopLevelDetails
            ).custom_invoice_id;
            newState.invoice_date = (
                action.payload.value as IInvoiceTopLevelDetails
            ).invoice_date;
            newState.expected_delivery_date = (
                action.payload.value as IInvoiceTopLevelDetails
            ).expected_delivery_date;
            newState.dispatch_date = (
                action.payload.value as IInvoiceTopLevelDetails
            ).dispatch_date;
            newState.reference_id = (
                action.payload.value as IInvoiceTopLevelDetails
            ).reference_id;
            break;
        case 'UPDATE_ITEM_DETAILS':
            // here the payload value is in the structure of the item to be ipdated (po_item_id should already be present in the global state)
            let item_to_be_updated = action.payload
                .value as INewInvoiceItemDetail;
            const index = newState.invoice_items.findIndex(
                (value) =>
                    value.purchase_order_item_id ===
                    item_to_be_updated.purchase_order_item_id
            );
            if (index >= 0) newState.invoice_items[index] = item_to_be_updated;
            break;
        case 'ADD_MULTIPLE_ITEMS': // here the payload value is a list in the structure of the item to be added
            (action.payload.value as INewInvoiceItemDetail[]).forEach(
                (item) => {
                    if (
                        !newState.invoice_items.find(
                            (existingItem) =>
                                existingItem.purchase_order_item_id ===
                                item.purchase_order_item_id
                        )
                    ) {
                        newState.invoice_items.push(item);
                    }
                }
            );
            break;
        case 'ADD_ITEM': // here the payload value is in the structure of the item to be added
            let item_to_add = action.payload.value as INewInvoiceItemDetail;
            newState.invoice_items.push(item_to_add);
            break;
        case 'REMOVE_ITEM': // here the payload value is the po_item_id to be deleted
            let findIndex = newState.invoice_items.findIndex(
                (item) => item.purchase_order_item_id === action.payload.value
            );
            newState.invoice_items.splice(findIndex, 1);
            break;
        case 'UPDATE_FOOTER_DETAILS':
            newState.notes = (action.payload.value as any).notes;
            newState.discount_percentage = (
                action.payload.value as any
            ).discount_percentage;
            break;
        case 'UPDATE_METADATA':
            newState.purchase_order_id = (action.payload.value as any).po_id;
            newState.seller_address_id = (
                action.payload.value as any
            ).seller_address_id;
            newState.action = (action.payload.value as any).action;
            break;
        case 'UPDATE_ATTACHMENTS':
            newState.attachment_ids = [
                ...newState.attachment_ids,
                ...(action.payload.value as IAttachment[]).map(
                    (a) => a.attachment_id
                ),
            ];
            break;
        case 'INIT_ATTACHMENTS':
            newState.attachment_ids = [
                ...(action.payload.value as IAttachment[]).map(
                    (a) => a.attachment_id
                ),
            ];
            break;
        case 'RESET_STATE':
            newState = cloneDeep(createInvoiceInitialState);
            break;
    }
    return newState;
};
