import { createSlice } from '@reduxjs/toolkit';
import { IQCTabCount } from '../Components/QCDashboardTabs';
import {
    IQCCompletedListSummary,
    IQCGRNListSummary,
} from '../Interfaces/QCInterface';
import {
    IGRDashboardListActions,
    initDashboardListReducer,
    ITabsChangeAction,
    ITabsCountAction,
    setSelectedTabReducer,
    setTabCountReducer,
} from '../Reducers/QCDashboardReducers';

export enum QCDashboardType {
    PENDING = 'pending',
    COMPLETED = 'completed',
}

export interface IQCDashboardState {
    pendingList: IQCGRNListSummary[];
    completedList: IQCCompletedListSummary[];
    tabsCount: IQCTabCount;
    selectedTab: QCDashboardType;
}

export const QCDashboardSlice = createSlice<
    IQCDashboardState,
    {
        initDashboardList: (
            state: IQCDashboardState,
            action: IGRDashboardListActions
        ) => void;
        setTabCount: (
            state: IQCDashboardState,
            action: ITabsCountAction
        ) => void;
        setSelectedTab: (
            state: IQCDashboardState,
            action: ITabsChangeAction
        ) => void;
    },
    string
>({
    name: 'QCDashboardStore',
    initialState: {
        pendingList: [],
        completedList: [],
        tabsCount: {
            pending: 0,
            completed: 0,
        },
        selectedTab: QCDashboardType.PENDING,
    },
    reducers: {
        initDashboardList: initDashboardListReducer,
        setTabCount: setTabCountReducer,
        setSelectedTab: setSelectedTabReducer,
    },
});

export const { initDashboardList, setTabCount, setSelectedTab } =
    QCDashboardSlice.actions;

const QCDashboardStore = QCDashboardSlice.reducer;
export default QCDashboardStore;
