import { Grid, Typography } from '@mui/material';
import { Fragment, useState } from 'react';
import FWPopover from '../../../Common/FWPopover';
import { FWTypography } from '../../../Common/FWTypograhy';
import { getFWDate } from '../../../Common/Utils/DateUtils';
import { IPaymentTermsPoItemPercentages } from '../../../Global/Interfaces/PaymentTermsInterface';

export const PaymentTermsCount = ({
    paymentTerms,
    showPrepayment,
}: {
    paymentTerms: IPaymentTermsPoItemPercentages;
    showPrepayment: boolean;
}) => {
    let displayString = `${paymentTerms?.list.length} terms`;
    if (showPrepayment) {
        if (+paymentTerms?.prepaymentPercentage === 100) {
            displayString = '100% prepaid';
        } else {
            displayString += `+ ${(+(
                paymentTerms?.prepaymentPercentage ?? 0
            )).toFixed(2)}%  Prepayment`;
        }
    }
    return (
        <Typography
            sx={{
                cursor: 'pointer',
                textDecoration: '',
                ':hover': {
                    textDecoration: 'underline',
                },
            }}
        >
            {displayString.trim() ? displayString.trim() : 'Edit payment Terms'}
        </Typography>
    );
};

const PaymentTermsPopover = ({
    label,
    paymentTerms,
    hideLabel,
    darkLabel,
    disabledStyle,
    color,
    showPrepayment,
}: {
    label: string;
    paymentTerms: IPaymentTermsPoItemPercentages;
    hideLabel?: boolean;
    darkLabel?: boolean;
    disabledStyle?: boolean;
    color?: string;
    showPrepayment: boolean;
}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const paymentTermsPopoverOpen = Boolean(anchorEl);
    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
        event.stopPropagation();
    };
    const handleClose = () => setAnchorEl(null);
    let displayString = `${paymentTerms?.list?.length} terms`;
    if (showPrepayment) {
        if (+paymentTerms?.prepaymentPercentage === 100) {
            displayString = '100% prepaid';
        } else {
            displayString += ` | Prepayment : ${paymentTerms?.prepaymentPercentage}%`;
        }
    }
    return (
        <>
            <div className="flex felx--aic">
                {!hideLabel && (
                    <FWTypography color={!darkLabel ? 'text.secondary' : ''}>
                        {`${label}:`} &nbsp;
                    </FWTypography>
                )}
                <FWTypography
                    onClick={handleClick}
                    // onMouseOut={handleClose}
                    color={
                        color
                            ? color
                            : disabledStyle
                            ? 'text.secondary'
                            : 'primary.main'
                    }
                    sx={{
                        cursor: 'pointer',
                        textDecoration: disabledStyle ? 'line-through' : '',
                        ':hover': {
                            textDecoration: disabledStyle ? '' : 'underline',
                        },
                    }}
                >
                    {displayString}
                </FWTypography>
            </div>
            <FWPopover
                open={paymentTermsPopoverOpen}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                // remove padding from popover
                PaperProps={{
                    sx: {
                        width: '500px !important',
                    },
                }}
            >
                <Grid container width={'100%'} rowSpacing={1} columnSpacing={2}>
                    <Grid item xs={5}>
                        <Typography color={'text.secondary'}>
                            Percentage
                        </Typography>
                    </Grid>
                    <Grid item xs={7}>
                        <Typography color={'text.secondary'}>Terms</Typography>
                    </Grid>
                    {paymentTerms?.list?.map((row, idx) => (
                        <Fragment key={idx}>
                            <Grid item xs={5}>
                                <Typography>{`${+row.poItemPercentage}%`}</Typography>
                            </Grid>
                            <Grid item xs={7}>
                                <Typography>
                                    {(() => {
                                        switch (row.milestoneType) {
                                            case 'FIXED_DATE':
                                                return `on ${getFWDate(
                                                    row.paymentDate
                                                )}`;
                                            case 'DELIVERABLE':
                                                if (+row.terms > 0) {
                                                    return `${
                                                        row.terms
                                                    } ${row.period.toLowerCase()} from ${
                                                        row.deliverableName
                                                    }`;
                                                } else {
                                                    return `${(+row.poItemPercentage).toFixed(
                                                        2
                                                    )}% on ${
                                                        row.deliverableName
                                                    }`;
                                                }

                                            default:
                                                return '';
                                        }
                                    })()}
                                </Typography>
                            </Grid>
                        </Fragment>
                    ))}
                </Grid>
            </FWPopover>
        </>
    );
};

export default PaymentTermsPopover;
