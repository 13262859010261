import { cloneDeep } from 'lodash';
import moment from 'moment';
import { toast } from 'react-toastify';
import { getTemplateFrontOptionFromListOfTemplateItems } from '../../AdditionalCost/helpers';
import { getFWDateTime } from '../../Common/Utils/DateUtils';
import { IUpdateEventAccessApi } from '../../Events/Services/Buyer/RFQ.service';
import { getFieldTypeContraintsFromBackend } from '../../FieldTypes/helpers/fieldTypesHelpers';
import { transformPaymentTermsFromBackendToFrontend } from '../../Global/Helpers/paymentTermsHelpers';
import {
    convertTemplateConstraintsItemToCustomFIeldType,
    dummyPaymentTermsOptions,
    getPaymentTermsDetails,
} from '../../Global/Helpers/templateApiSlice';
import { ITemplatePaymentTermsOptions } from '../../Global/Interfaces/TemplateInterface';
import {
    IRequistionDetailsResponse,
    RequisitionItemBackend,
} from '../../ItemAnalytics/Interfaces/OrderInterfaces';
import { baseApiSlice } from '../../Redux/Slices/BaseApiSlice';
import { del, get, post, put } from '../../Utils/api';
import { blankRequisitionTemplate } from '../Constants/RequisitionTemplateConstants';
import { NewRequisitionItemForPayload } from '../Helpers/RequisitionEditPageHelpers';
import { convertBackendRequisitionItemToFrontEnd } from '../Helpers/RequisitionServiceHelpers';
import {
    IRequisitionNewTemplate,
    IRequisitionNewTemplateDetailResponse,
    RequisitionNewTemplateStandardFieldBackendNames,
    RequisitionNewTemplateStandardFieldsEnum,
} from '../Interfaces/RequisitionTemplateInterfacesNew';
import { RequisitionItem } from '../Slices/RequisitionSlice';

export interface RequisitionTemplateData {
    template_id: string;
    type: string;
    name: string;
    tags: Array<any>;
    status: string;
    published_on: any;
    description: string;
    is_global: boolean;
    is_default: boolean;
    created_on: string;
    creator_name: string;
    last_edited_on: string;
    last_edited_by_name: string;
    entity_id: string;
    entity_name: string;
}

export interface RequisitionTemplateAPIResponse {
    type: string;
    count: number;
    templates: Array<{
        template_id: string;
        type: string;
        name: string;
        tags: Array<any>;
        status: string;
        published_on: any;
        description: string;
        is_global: boolean;
        is_default: boolean;
        created_on: string;
        creator_name: string;
        last_edited_on: string;
        last_edited_by_name: string;
        entity_id: string;
        entity_name: string;
    }>;
}

export const fetchAllTemplates = async ({
    entityId,
}: {
    entityId: string;
}): Promise<RequisitionTemplateData[]> => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await get<any>(
                `/module_templates/?entity_id=${entityId}&template_type=REQUISITION`
            );
            resolve(response.data[0].templates);
        } catch (e) {
            console.error(e);
        }
    });
};

export type createRequisitionTemplate = {
    requisition_name: string;
    buyer_entity_id: string;
    notes: any;
    approval: string;
    default_currency_id: string;
    template_id: string;
};

export const createRequisition = async ({
    requisition_name,
    buyer_entity_id,
    notes,
    approval,
    default_currency_id,
    template_id,
}: {
    requisition_name: string;
    buyer_entity_id: string;
    notes: any;
    approval: string;
    default_currency_id: string;
    template_id: string;
}): Promise<any> => {
    const response = await post<createRequisitionTemplate, any>(
        '/requisitions/create/',
        {
            requisition_name: requisition_name,
            buyer_entity_id: buyer_entity_id,
            notes: notes,
            approval: approval,
            default_currency_id: default_currency_id,
            template_id: template_id,
        }
    );

    return response.data;
};
export const { useGetRequisitionDashboardDataMutation } =
    baseApiSlice.injectEndpoints({
        endpoints: (builder) => ({
            getRequisitionDashboardData: builder.mutation<
                IRequistionDetailsResponse,
                {
                    dashboard_view: string;
                    page_number: number;
                    search_text: string;
                    tab: string;
                }
            >({
                query: ({
                    dashboard_view = 'requisition',
                    page_number,
                    search_text,
                    tab,
                }) => ({
                    url: `/dashboard/`,
                    method: 'POST',
                    body: {
                        dashboard_view: dashboard_view,
                        items_per_page: 10,
                        page_number: page_number,
                        tab: tab,
                        search_text:
                            search_text?.length && search_text?.length > 0
                                ? search_text
                                : '',
                        sort_fields: [],
                    },
                }),
                transformResponse: (
                    baseQueryReturnValue: IRequistionDetailsResponse,
                    meta,
                    arg
                ) => {
                    return baseQueryReturnValue;
                },
            }),
        }),
    });

export const { useGetRequisitionTemplateByIdQuery } =
    baseApiSlice.injectEndpoints({
        endpoints: (builder) => ({
            getRequisitionTemplateById: builder.query<
                IRequisitionNewTemplate,
                { templateId: string; entityId: string }
            >({
                query: ({ templateId, entityId }) =>
                    `/module_templates/${entityId}/${templateId}`,
                transformResponse: (
                    baseQueryReturnValue: IRequisitionNewTemplateDetailResponse,
                    meta,
                    args
                ) => {
                    const response: IRequisitionNewTemplate = JSON.parse(
                        JSON.stringify(blankRequisitionTemplate)
                    );
                    response.templateId = baseQueryReturnValue.template_id;
                    response.templateName = baseQueryReturnValue.name;
                    const templateSections =
                        baseQueryReturnValue.section_list.map((section) => {
                            return {
                                ...section,
                                section_items: section.section_items.map(
                                    (item: any) => {
                                        return {
                                            ...item,
                                            alternate_name:
                                                item.alternate_name.replace(
                                                    '_',
                                                    ''
                                                ),
                                            name: item.name.replace('_', ''),
                                        };
                                    }
                                ),
                            };
                        });
                    baseQueryReturnValue = {
                        ...baseQueryReturnValue,
                        section_list: templateSections,
                    };
                    const requisitonDetailsfromBackend =
                        baseQueryReturnValue.section_list.find(
                            (section) => section.name === 'Requisition Details'
                        );
                    response.sections.REQUISITION_DETAILS = {
                        fieldList: [],
                        label:
                            requisitonDetailsfromBackend &&
                            requisitonDetailsfromBackend?.alternate_name
                                ? requisitonDetailsfromBackend.alternate_name
                                : 'Requisition Details',
                    };
                    requisitonDetailsfromBackend?.section_items.forEach(
                        (item) => {
                            if (
                                Object.keys(
                                    RequisitionNewTemplateStandardFieldBackendNames
                                ).includes(item.name)
                            ) {
                                if (!item.additional_information.is_hidden) {
                                    response.sections.REQUISITION_DETAILS.fieldList.push(
                                        {
                                            fieldType: 'STANDARD',
                                            field: RequisitionNewTemplateStandardFieldBackendNames[
                                                item.name
                                            ],
                                        }
                                    );
                                }

                                let formattedData = {
                                    label: item.alternate_name
                                        ? item.alternate_name
                                        : item.name,
                                    description: item.description,
                                    buyerSettings: {
                                        required: item.is_required,
                                        showField: !(
                                            item.additional_information
                                                .is_hidden ?? false
                                        ),
                                    },
                                    sellerSettings: {
                                        negotiable:
                                            !(
                                                item.additional_information
                                                    .is_hidden ?? false
                                            ) &&
                                            (item.additional_information
                                                .is_visible ??
                                                true) &&
                                            (item.additional_information
                                                .is_negotiable ??
                                                true),
                                        showField:
                                            !(
                                                item.additional_information
                                                    .is_hidden ?? false
                                            ) &&
                                            (item.additional_information
                                                .is_visible ??
                                                true),
                                        isMandatory: item.is_mandatory,
                                    },
                                    fieldLevel: item.field_level,
                                };
                                response.fieldSettings.standardFields = {
                                    ...response.fieldSettings.standardFields,
                                    [RequisitionNewTemplateStandardFieldBackendNames[
                                        item.name
                                    ]]: formattedData,
                                };
                            } else if (
                                item.name !== 'Discounts' &&
                                item.name !== 'Tax'
                            ) {
                                if (!item.additional_information.is_hidden)
                                    response.sections.REQUISITION_DETAILS.fieldList.push(
                                        {
                                            fieldType: 'CUSTOM',
                                            field: item.name,
                                        }
                                    );
                                response.fieldSettings.customFields[item.name] =
                                    {
                                        label: item.alternate_name
                                            ? item.alternate_name
                                            : item.name,
                                        description: item.description,
                                        buyerSettings: {
                                            required: item.is_required,
                                            showField: !(
                                                item.additional_information
                                                    .is_hidden ?? false
                                            ),
                                        },
                                        sellerSettings: {
                                            negotiable:
                                                !(
                                                    item.additional_information
                                                        .is_hidden ?? false
                                                ) &&
                                                (item.additional_information
                                                    .is_visible ??
                                                    true) &&
                                                (item.additional_information
                                                    .is_negotiable ??
                                                    true),
                                            showField:
                                                !(
                                                    item.additional_information
                                                        .is_hidden ?? false
                                                ) &&
                                                (item.additional_information
                                                    .is_visible ??
                                                    true),
                                            isMandatory: item.is_mandatory,
                                        },
                                        type: convertTemplateConstraintsItemToCustomFIeldType(
                                            item.constraints.field_type
                                        ),
                                        constraints:
                                            getFieldTypeContraintsFromBackend({
                                                field_type:
                                                    item.constraints.field_type,
                                                choices:
                                                    item.constraints.choices,
                                                max_limit:
                                                    item.constraints.max_limit,
                                                min_limit:
                                                    item.constraints.min_limit,
                                                choice_type:
                                                    item.constraints
                                                        .choice_type,
                                            }),
                                    };
                            }
                        }
                    );
                    const essentialTermsfromBackend =
                        baseQueryReturnValue.section_list.find(
                            (section) => section.name === 'Item Terms'
                        );
                    // response.sections.ESSENTIAL_TERMS = {};
                    response.sections.ITEM_TERMS = {
                        fieldList: [],
                        label:
                            essentialTermsfromBackend &&
                            essentialTermsfromBackend?.alternate_name
                                ? essentialTermsfromBackend.alternate_name
                                : 'Item Terms',
                    };
                    const essentialTermsItemsfromBackend =
                        essentialTermsfromBackend!.section_items
                            .filter(
                                (item) =>
                                    [
                                        'Quantity',
                                        'Delivery date',
                                        'Cost center',
                                        'GL',
                                        'Project',
                                        'Additional costs and Taxes',
                                        'Discount',
                                    ].includes(item.name) ||
                                    item.parent_section_item === null
                            )
                            .filter(
                                (item) =>
                                    ![
                                        'Item',
                                        'Specification',
                                        'Measurement unit',
                                        'Quantity Information',
                                        'Discount information',
                                        'Overall Discount information',
                                    ].includes(item.name)
                            );
                    essentialTermsItemsfromBackend.forEach((item) => {
                        if (
                            Object.keys(
                                RequisitionNewTemplateStandardFieldBackendNames
                            ).includes(item.name)
                        ) {
                            if (!item.additional_information.is_hidden) {
                                response.sections.ITEM_TERMS.fieldList.push({
                                    fieldType: 'STANDARD',
                                    field: RequisitionNewTemplateStandardFieldBackendNames[
                                        item.name
                                    ],
                                });
                            }

                            let formattedData = {
                                label: item.alternate_name
                                    ? item.alternate_name
                                    : item.name,
                                description: item.description,
                                buyerSettings: {
                                    required: item.is_required,
                                    showField: !(
                                        item.additional_information.is_hidden ??
                                        false
                                    ),
                                },
                                sellerSettings: {
                                    negotiable:
                                        !(
                                            item.additional_information
                                                .is_hidden ?? false
                                        ) &&
                                        (item.additional_information
                                            .is_visible ??
                                            true) &&
                                        (item.additional_information
                                            .is_negotiable ??
                                            true),
                                    showField:
                                        !(
                                            item.additional_information
                                                .is_hidden ?? false
                                        ) &&
                                        (item.additional_information
                                            .is_visible ??
                                            true),
                                    isMandatory: item.is_mandatory,
                                },
                                fieldLevel: item.field_level,
                            };
                            if (
                                RequisitionNewTemplateStandardFieldBackendNames[
                                    item.name
                                ] ===
                                RequisitionNewTemplateStandardFieldBackendNames.PAYMENT_TERMS
                            ) {
                                const formattedDataWithPaymentOptions: ITemplatePaymentTermsOptions =
                                    {
                                        ...formattedData,
                                        paymentTermOptions:
                                            // TODO: Remove dummy data
                                            dummyPaymentTermsOptions,
                                        standardFieldCategory: 'PAYMENT_TERMS',
                                    };

                                response.fieldSettings.standardFields[
                                    RequisitionNewTemplateStandardFieldsEnum.PAYMENT_TERMS
                                ] = formattedDataWithPaymentOptions;
                            } else if (
                                RequisitionNewTemplateStandardFieldBackendNames[
                                    item.name
                                ] ===
                                RequisitionNewTemplateStandardFieldsEnum.TAX
                            ) {
                                response.fieldSettings.standardFields[
                                    RequisitionNewTemplateStandardFieldsEnum.TAX
                                ] = {
                                    ...formattedData,
                                    standardFieldCategory: 'TAX',
                                    taxesOptions:
                                        getTemplateFrontOptionFromListOfTemplateItems(
                                            essentialTermsfromBackend?.section_items.filter(
                                                (sectionItem) =>
                                                    sectionItem.parent_section_item ===
                                                    item.section_item_id
                                            ) ?? []
                                        ),
                                    buyerSettings: {
                                        ...formattedData.buyerSettings,
                                        showField: formattedData.buyerSettings
                                            .showField
                                            ? getTemplateFrontOptionFromListOfTemplateItems(
                                                  essentialTermsfromBackend?.section_items.filter(
                                                      (sectionItem) =>
                                                          sectionItem.parent_section_item ===
                                                          item.section_item_id
                                                  ) ?? []
                                              ).length > 0
                                            : false,
                                    },
                                    sellerSettings: {
                                        ...formattedData.sellerSettings,
                                        showField: formattedData.sellerSettings
                                            .showField
                                            ? getTemplateFrontOptionFromListOfTemplateItems(
                                                  essentialTermsfromBackend?.section_items.filter(
                                                      (sectionItem) =>
                                                          sectionItem.parent_section_item ===
                                                          item.section_item_id
                                                  ) ?? []
                                              ).length > 0
                                            : false,
                                    },
                                };
                            } else if (
                                RequisitionNewTemplateStandardFieldBackendNames[
                                    item.name
                                ] ===
                                RequisitionNewTemplateStandardFieldsEnum.ADDITIONAL_COSTS
                            ) {
                                response.fieldSettings.standardFields[
                                    RequisitionNewTemplateStandardFieldsEnum.ADDITIONAL_COSTS
                                ] = {
                                    ...formattedData,
                                    standardFieldCategory: 'ADDITIONAL_COSTS',
                                    additionalCostOptions:
                                        getTemplateFrontOptionFromListOfTemplateItems(
                                            essentialTermsfromBackend?.section_items.filter(
                                                (sectionItem) =>
                                                    sectionItem.parent_section_item ===
                                                    item.section_item_id
                                            ) ?? []
                                        ),
                                    buyerSettings: {
                                        ...formattedData.buyerSettings,
                                        showField: formattedData.buyerSettings
                                            .showField
                                            ? getTemplateFrontOptionFromListOfTemplateItems(
                                                  essentialTermsfromBackend?.section_items.filter(
                                                      (sectionItem) =>
                                                          sectionItem.parent_section_item ===
                                                          item.section_item_id
                                                  ) ?? []
                                              ).length > 0
                                            : false,
                                    },
                                    sellerSettings: {
                                        ...formattedData.sellerSettings,
                                        showField: formattedData.sellerSettings
                                            .showField
                                            ? getTemplateFrontOptionFromListOfTemplateItems(
                                                  essentialTermsfromBackend?.section_items.filter(
                                                      (sectionItem) =>
                                                          sectionItem.parent_section_item ===
                                                          item.section_item_id
                                                  ) ?? []
                                              ).length > 0
                                            : false,
                                    },
                                };
                            } else if (
                                RequisitionNewTemplateStandardFieldBackendNames[
                                    item.name
                                ] ===
                                RequisitionNewTemplateStandardFieldsEnum.DISCOUNT
                            ) {
                                const listOfSectionItemsFromTemplate =
                                    essentialTermsfromBackend?.section_items.filter(
                                        (sectionItem) =>
                                            sectionItem.parent_section_item ===
                                            item.section_item_id
                                    ) ?? [];

                                listOfSectionItemsFromTemplate.push(item);

                                response.fieldSettings.standardFields[
                                    RequisitionNewTemplateStandardFieldsEnum.DISCOUNT
                                ] = {
                                    ...formattedData,
                                    standardFieldCategory: 'DISCOUNT',
                                    discountOptions:
                                        getTemplateFrontOptionFromListOfTemplateItems(
                                            listOfSectionItemsFromTemplate
                                        ),
                                    buyerSettings: {
                                        ...formattedData.buyerSettings,
                                        showField: formattedData.buyerSettings
                                            .showField
                                            ? getTemplateFrontOptionFromListOfTemplateItems(
                                                  listOfSectionItemsFromTemplate
                                              ).length > 0
                                            : false,
                                    },
                                    sellerSettings: {
                                        ...formattedData.sellerSettings,
                                        showField: formattedData.sellerSettings
                                            .showField
                                            ? getTemplateFrontOptionFromListOfTemplateItems(
                                                  listOfSectionItemsFromTemplate
                                              ).length > 0
                                            : false,
                                    },
                                };
                            } else {
                                response.fieldSettings.standardFields = {
                                    ...response.fieldSettings.standardFields,
                                    [RequisitionNewTemplateStandardFieldBackendNames[
                                        item.name
                                    ]]: formattedData,
                                };
                            }
                        } else if (
                            item.name !== 'Discounts' &&
                            item.name !== 'Tax'
                        ) {
                            if (!item.additional_information.is_hidden)
                                response.sections.ITEM_TERMS.fieldList.push({
                                    fieldType: 'CUSTOM',
                                    field: item.name,
                                });
                            response.fieldSettings.customFields[item.name] = {
                                label: item.alternate_name
                                    ? item.alternate_name
                                    : item.name,
                                description: item.description,
                                buyerSettings: {
                                    required: item.is_required,
                                    showField: !(
                                        item.additional_information.is_hidden ??
                                        false
                                    ),
                                },
                                sellerSettings: {
                                    negotiable:
                                        !(
                                            item.additional_information
                                                .is_hidden ?? false
                                        ) &&
                                        (item.additional_information
                                            .is_visible ??
                                            true) &&
                                        (item.additional_information
                                            .is_negotiable ??
                                            true),
                                    showField:
                                        !(
                                            item.additional_information
                                                .is_hidden ?? false
                                        ) &&
                                        (item.additional_information
                                            .is_visible ??
                                            true),
                                    isMandatory: item.is_mandatory,
                                },
                                type: convertTemplateConstraintsItemToCustomFIeldType(
                                    item.constraints.field_type
                                ),
                                constraints: getFieldTypeContraintsFromBackend({
                                    field_type: item.constraints.field_type,
                                    choices: item.constraints.choices,
                                    max_limit: item.constraints.max_limit,
                                    min_limit: item.constraints.min_limit,
                                    choice_type: item.constraints.choice_type,
                                }),
                            };
                        }
                    });
                    const paymentDeliveryfromBackend =
                        baseQueryReturnValue.section_list.find(
                            (section) =>
                                section.name === 'Payment and Delivery Terms'
                        );

                    response.sections.PAYMENT_AND_DELIVERY_TERMS.label =
                        paymentDeliveryfromBackend &&
                        paymentDeliveryfromBackend.alternate_name
                            ? paymentDeliveryfromBackend.alternate_name
                            : 'Payment and Delivery Terms';
                    const paymentDeliveryItemsfromBackend =
                        paymentDeliveryfromBackend!.section_items
                            .filter((item) => item.parent_section_item === null)
                            .filter((item) => ![''].includes(item.name));

                    paymentDeliveryItemsfromBackend.forEach((item) => {
                        if (
                            Object.keys(
                                RequisitionNewTemplateStandardFieldBackendNames
                            ).includes(item.name)
                        ) {
                            if (!item.additional_information.is_hidden)
                                response.sections.PAYMENT_AND_DELIVERY_TERMS.fieldList.push(
                                    {
                                        fieldType: 'STANDARD',
                                        field: RequisitionNewTemplateStandardFieldBackendNames[
                                            item.name
                                        ],
                                    }
                                );

                            const formattedData = {
                                label: item.alternate_name
                                    ? item.alternate_name
                                    : item.name,
                                description: item.description,
                                buyerSettings: {
                                    required: item.is_required,
                                    showField: !(
                                        item.additional_information.is_hidden ??
                                        false
                                    ),
                                },
                                sellerSettings: {
                                    negotiable:
                                        !(
                                            item.additional_information
                                                .is_hidden ?? false
                                        ) &&
                                        (item.additional_information
                                            .is_visible ??
                                            true) &&
                                        (item.additional_information
                                            .is_negotiable ??
                                            true),
                                    showField:
                                        !(
                                            item.additional_information
                                                .is_hidden ?? false
                                        ) &&
                                        (item.additional_information
                                            .is_visible ??
                                            true),
                                    isMandatory: item.is_mandatory,
                                },
                                fieldLevel: item.field_level,
                            };
                            if (
                                RequisitionNewTemplateStandardFieldBackendNames[
                                    item.name
                                ] ===
                                RequisitionNewTemplateStandardFieldsEnum.PAYMENT_TERMS
                            ) {
                                const paymentTermsOption =
                                    getPaymentTermsDetails(
                                        paymentDeliveryfromBackend!
                                            .section_items
                                    );
                                const formattedDataWithPaymentOptions: ITemplatePaymentTermsOptions =
                                    {
                                        ...formattedData,
                                        paymentTermOptions: paymentTermsOption,
                                        standardFieldCategory: 'PAYMENT_TERMS',
                                    };
                                response.fieldSettings.standardFields[
                                    RequisitionNewTemplateStandardFieldsEnum.PAYMENT_TERMS
                                ] = formattedDataWithPaymentOptions;
                            } else {
                                response.fieldSettings.standardFields[
                                    RequisitionNewTemplateStandardFieldBackendNames[
                                        item.name
                                    ]
                                ] = formattedData as any;
                            }
                        } else if (item.name !== 'Prepayment percentage') {
                            // if (!item.additional_information.is_hidden)
                            response.sections.PAYMENT_AND_DELIVERY_TERMS.fieldList.push(
                                {
                                    fieldType: 'CUSTOM',
                                    field: item.name,
                                }
                            );
                            response.fieldSettings.customFields[item.name] = {
                                label: item.alternate_name
                                    ? item.alternate_name
                                    : item.name,
                                description: item.description,
                                buyerSettings: {
                                    required: item.is_required,
                                    showField: !(
                                        item.additional_information.is_hidden ??
                                        false
                                    ),
                                },
                                sellerSettings: {
                                    negotiable:
                                        !(
                                            item.additional_information
                                                .is_hidden ?? false
                                        ) &&
                                        (item.additional_information
                                            .is_visible ??
                                            true) &&
                                        (item.additional_information
                                            .is_negotiable ??
                                            true),
                                    showField:
                                        !(
                                            item.additional_information
                                                .is_hidden ?? false
                                        ) &&
                                        (item.additional_information
                                            .is_visible ??
                                            true),
                                    isMandatory: item.is_mandatory,
                                },
                                type: convertTemplateConstraintsItemToCustomFIeldType(
                                    item.constraints.field_type
                                ),
                                constraints: getFieldTypeContraintsFromBackend({
                                    field_type: item.constraints.field_type,
                                    choices: item.constraints.choices,
                                    max_limit: item.constraints.max_limit,
                                    min_limit: item.constraints.min_limit,
                                    choice_type: item.constraints.choice_type,
                                }),
                            };
                        }
                    });
                    const addtionalInformationfromBackend =
                        baseQueryReturnValue.section_list.find(
                            (section) =>
                                section.name === 'Additional Information'
                        );
                    response.sections.ADDITIONAL_DETAILS.label =
                        addtionalInformationfromBackend &&
                        addtionalInformationfromBackend.alternate_name
                            ? addtionalInformationfromBackend.alternate_name
                            : 'Additional Information';
                    const addtionalInformationItemsfromBackend =
                        addtionalInformationfromBackend!.section_items
                            .filter((item) => item.parent_section_item === null)
                            .filter((item) => ![''].includes(item.name));
                    addtionalInformationItemsfromBackend.forEach((item) => {
                        if (
                            Object.keys(
                                RequisitionNewTemplateStandardFieldBackendNames
                            ).includes(item.name)
                        ) {
                            if (!item.additional_information.is_hidden) {
                                response.sections.ADDITIONAL_DETAILS.fieldList.push(
                                    {
                                        fieldType: 'STANDARD',
                                        field: RequisitionNewTemplateStandardFieldBackendNames[
                                            item.name
                                        ],
                                    }
                                );

                                const formattedData = {
                                    label: item.alternate_name
                                        ? item.alternate_name
                                        : item.name,
                                    description: item.description,
                                    buyerSettings: {
                                        required: item.is_required,
                                        showField: !(
                                            item.additional_information
                                                .is_hidden ?? false
                                        ),
                                    },
                                    sellerSettings: {
                                        negotiable:
                                            !(
                                                item.additional_information
                                                    .is_hidden ?? false
                                            ) &&
                                            (item.additional_information
                                                .is_visible ??
                                                true) &&
                                            (item.additional_information
                                                .is_negotiable ??
                                                true),
                                        showField:
                                            !(
                                                item.additional_information
                                                    .is_hidden ?? false
                                            ) &&
                                            (item.additional_information
                                                .is_visible ??
                                                true),
                                    },
                                    fieldLevel: item.field_level,
                                };

                                response.fieldSettings.standardFields = {
                                    ...response.fieldSettings.standardFields,
                                    [RequisitionNewTemplateStandardFieldBackendNames[
                                        item.name
                                    ]]: formattedData,
                                };
                            }
                        } else {
                            response.sections.ADDITIONAL_DETAILS.fieldList.push(
                                {
                                    fieldType: 'CUSTOM',
                                    field: item.name,
                                }
                            );
                            response.fieldSettings.customFields[item.name] = {
                                label: item.alternate_name
                                    ? item.alternate_name
                                    : item.name,
                                description: item.description,
                                buyerSettings: {
                                    required: item.is_required,
                                    showField: !(
                                        item.additional_information.is_hidden ??
                                        false
                                    ),
                                },
                                sellerSettings: {
                                    negotiable:
                                        !(
                                            item.additional_information
                                                .is_hidden ?? false
                                        ) &&
                                        (item.additional_information
                                            .is_visible ??
                                            true) &&
                                        (item.additional_information
                                            .is_negotiable ??
                                            true),
                                    showField:
                                        !(
                                            item.additional_information
                                                .is_hidden ?? false
                                        ) &&
                                        (item.additional_information
                                            .is_visible ??
                                            true),
                                    isMandatory: item.is_mandatory,
                                },
                                type: convertTemplateConstraintsItemToCustomFIeldType(
                                    item.constraints.field_type
                                ),
                                constraints: getFieldTypeContraintsFromBackend({
                                    field_type: item.constraints.field_type,
                                    choices: item.constraints.choices,
                                    max_limit: item.constraints.max_limit,
                                    min_limit: item.constraints.min_limit,
                                    choice_type: item.constraints.choice_type,
                                }),
                            };
                        }
                    });
                    const vendorInformationFromBackend =
                        baseQueryReturnValue.section_list.find(
                            (section) => section.name === 'Vendor Information'
                        );

                    response.sections.VENDOR_INFORMATION.label =
                        vendorInformationFromBackend &&
                        vendorInformationFromBackend.alternate_name
                            ? vendorInformationFromBackend.alternate_name
                            : 'Vendor Information';
                    const vendorInformationItemsfromBackend =
                        vendorInformationFromBackend!.section_items
                            .filter((item) => item.parent_section_item === null)
                            .filter((item) => ![''].includes(item.name));

                    vendorInformationItemsfromBackend.forEach((item) => {
                        if (
                            Object.keys(
                                RequisitionNewTemplateStandardFieldBackendNames
                            ).includes(item.name)
                        ) {
                            if (!item.additional_information.is_hidden) {
                                response.sections.VENDOR_INFORMATION.fieldList.push(
                                    {
                                        fieldType: 'STANDARD',
                                        field: RequisitionNewTemplateStandardFieldBackendNames[
                                            item.name
                                        ],
                                    }
                                );
                                const formattedData = {
                                    label: item.alternate_name
                                        ? item.alternate_name
                                        : item.name,
                                    description: item.description,
                                    buyerSettings: {
                                        required: item.is_required,
                                        showField: !(
                                            item.additional_information
                                                .is_hidden ?? false
                                        ),
                                    },
                                    sellerSettings: {
                                        negotiable:
                                            !(
                                                item.additional_information
                                                    .is_hidden ?? false
                                            ) &&
                                            (item.additional_information
                                                .is_visible ??
                                                true) &&
                                            (item.additional_information
                                                .is_negotiable ??
                                                true),
                                        showField:
                                            !(
                                                item.additional_information
                                                    .is_hidden ?? false
                                            ) &&
                                            (item.additional_information
                                                .is_visible ??
                                                true),
                                    },
                                    fieldLevel: item.field_level,
                                };
                                response.fieldSettings.standardFields = {
                                    ...response.fieldSettings.standardFields,
                                    [RequisitionNewTemplateStandardFieldBackendNames[
                                        item.name
                                    ]]: formattedData,
                                };
                            }
                        } else {
                            response.sections.ADDITIONAL_DETAILS.fieldList.push(
                                {
                                    fieldType: 'CUSTOM',
                                    field: item.name,
                                }
                            );
                            response.fieldSettings.customFields[item.name] = {
                                label: item.alternate_name
                                    ? item.alternate_name
                                    : item.name,
                                description: item.description,
                                buyerSettings: {
                                    required: item.is_required,
                                    showField: !(
                                        item.additional_information.is_hidden ??
                                        false
                                    ),
                                },
                                sellerSettings: {
                                    negotiable:
                                        !(
                                            item.additional_information
                                                .is_hidden ?? false
                                        ) &&
                                        (item.additional_information
                                            .is_visible ??
                                            true) &&
                                        (item.additional_information
                                            .is_negotiable ??
                                            true),
                                    showField:
                                        !(
                                            item.additional_information
                                                .is_hidden ?? false
                                        ) &&
                                        (item.additional_information
                                            .is_visible ??
                                            true),
                                    isMandatory: item.is_mandatory,
                                },
                                type: convertTemplateConstraintsItemToCustomFIeldType(
                                    item.constraints.field_type
                                ),
                                constraints: getFieldTypeContraintsFromBackend({
                                    field_type: item.constraints.field_type,
                                    choices: item.constraints.choices,
                                    max_limit: item.constraints.max_limit,
                                    min_limit: item.constraints.min_limit,
                                    choice_type: item.constraints.choice_type,
                                }),
                            };
                        }
                    });
                    return response;
                },
            }),
        }),
    });

export const createNewRequisition = async (
    templateId: string,
    entityId: string,
    currency: string,
    name: string
) => {
    const FULLNAME = name ?? '';
    const initials = FULLNAME.split(' ')
        .map((n) => n[0])
        .join('');
    let reqName = `${initials.length > 0 ? initials + ' ' : ''}`;
    reqName = `${reqName}REQ - ${getFWDateTime(
        moment().format('YYYY-MM-DD HH:mm:ss')
    )}`;

    try {
        const payload = {
            requisition_name: reqName,
            buyer_entity_id: entityId,
            default_currency_id: currency,
            template_id: templateId,
        };
        const response = await post<any, any>('/requisitions/create/', payload);
        toast.success('Requisition Created Successfully');
        return response.data.requisition_id;
    } catch (err) {
        toast.error('Failed to create new requisition, try again later');
        return null;
    }
};

export const getRequisitionDetails = async (
    requisition_id: string
): Promise<any> => {
    try {
        const response: any = await get<any>(
            `/requisitions/${requisition_id}/`
        );
        return response.data;
    } catch (e) {
        console.error(e);
        return null;
    }
};

export const createNewRequisitionItem = async (
    newItem: NewRequisitionItemForPayload,
    requisitionId: string
) => {
    try {
        let res = await post<any, any>(
            `/requisitions/${requisitionId}/items/create/`,
            newItem
        );

        return res.data;
    } catch (error) {
        throw new Error('failed to create item');
    }
};

export const updateRequistionItemDetails = async (
    item: any,
    requisitionId: string,
    requisitionItemId: string
) => {
    try {
        let res = await put<any, any>(
            `/requisitions/${requisitionId}/items/${requisitionItemId}/update/`,
            item
        );
        return res;
    } catch (err) {
        return null;
    }
};

export const deleteRequistionItem = async (
    requisitionId: string,
    requistionItemId: string
) => {
    try {
        let res = await del<{
            requisitionId: string;
            requisitionItemId: string;
        }>(`/requisitions/${requisitionId}/items/${requistionItemId}/delete`);
        return res;
    } catch (err) {
        return null;
    }
};

export const RequisitionApiSlice = baseApiSlice.injectEndpoints({
    endpoints: (build) => ({
        getRequisitionItemsPaginatedQuery: build.query<
            {
                data: RequisitionItem[];
                metadata: {
                    current_page: number;
                    has_next: boolean;
                    has_previous: boolean;
                    total_pages: number;
                    page_range: {
                        start: number;
                        stop: number;
                    };
                    count: number;
                };
                counts: {
                    all: number;
                };
            },
            {
                dashboard_view: 'requisition_items';
                tab: 'all';
                sort_fields: any[];
                search_text: string;
                items_per_page: number;
                page_number: number;
                query_data: {
                    requisition_id: string;
                };
            }
        >({
            query: (body) => ({
                url: '/dashboard/',
                method: 'POST',
                body,
            }),
            transformResponse: (response: any) => {
                let newResponse = cloneDeep(response);
                newResponse.data = response.data.map((item: any) => {
                    return {
                        ...convertBackendRequisitionItemToFrontEnd(item),
                        paymentTerms:
                            transformPaymentTermsFromBackendToFrontend({
                                prepayment_percentage:
                                    item.prepayment_percentage,
                                deliverables_payment_terms:
                                    item.deliverables_payment_terms[0],
                                payment_terms: item.payment_terms,
                            }),
                    };
                });
                return newResponse;
            },
        }),
    }),
});

export const { useGetRequisitionItemsPaginatedQueryQuery } =
    RequisitionApiSlice;

export const submitRequisiton = async (requistionId: string) => {
    try {
        const payload = { status: 'SUBMITTED', notes: '' };
        const response = await put(
            `/requisitions/${requistionId}/state/`,
            payload
        );
        return response;
    } catch (err) {
        return null;
    }
};

export const updateRequistion = async (
    requisitonId: string,
    requisition: any
) => {
    try {
        // const payload = {
        //     requisition_name: requisitionName,
        //     default_currency_id: currencyId,
        //     total: total,
        // };
        const response = await put(
            `/requisitions/${requisitonId}/update/`,
            requisition
        );
        return response;
    } catch (err) {
        return null;
    }
};

export const deleteRequistion = async (requisitionId: string) => {
    try {
        let resp = await del(`/requisitions/${requisitionId}/delete/`);
        return resp;
    } catch (err) {
        return null;
    }
};

export const validateRequisitionName = async (
    name: string,
    entityId: string,
    id?: string
) => {
    try {
        let resp;
        if (id) {
            resp = await get(
                `/requisitions/name/exists/?name=${name}&buyer_entity_id=${entityId}&requisition_id=${id}`
            );
        } else {
            resp = await get(
                `/requisitions/name/exists/?name=${name}&buyer_entity_id=${entityId}`
            );
        }
        return resp;
    } catch (err) {
        return null;
    }
};

export const cloneRequisition = async (
    requisitionId: string,
    requisitionName: string
) => {
    try {
        let resp = await post(`/requisitions/${requisitionId}/clone/`, {
            requisition_name: requisitionName,
        });
        return resp;
    } catch (err) {
        return null;
    }
};

export const { useGetInboundRequisitionItemsQuery } =
    baseApiSlice.injectEndpoints({
        endpoints: (builder) => ({
            getInboundRequisitionItems: builder.query<
                {
                    data: RequisitionItemBackend[];
                    metadata: {
                        current_page: number;
                        has_next: boolean;
                        has_previous: boolean;
                        total_pages: number;
                        page_range: {
                            start: number;
                            stop: number;
                        };
                        count: number;
                    };
                    counts: {
                        all: number;
                    };
                },
                {
                    dashboard_view: 'inbound_requisition_items';
                    tab: 'all';
                    sort_fields: any[];
                    search_text: string;
                    items_per_page: number;
                    page_number: number;
                    query_data: {
                        requisition_id_list: string[];
                    };
                }
            >({
                query: (body) => ({
                    url: '/dashboard/',
                    method: 'POST',
                    body,
                }),
                transformResponse: (response: any) => {
                    return response;
                },
            }),
        }),
    });

export const getRequisitionUsersWithPermission = async (
    requisitionId: string
) => {
    try {
        const response = await get(
            `/requisitions/${requisitionId}/permissions/`
        );
        if (response) {
            return response.data;
        }
    } catch (err) {
        console.log(err);
        return null;
    }
};

export const getAllUsers = async () => {
    try {
        const resp = await get(
            `/organization/user_permission/?all_users=true&type=BUYER&action_api_group[]=REQUISITION_VIEW&action_api_group[]=REQUISITION_EDIT`
        );
        if (resp) return resp.data;
        else return null;
    } catch (err) {
        console.log(err, '');
        return null;
    }
};

export const updateRequisitionAccess = async (
    requisitionId: string,
    payload: IUpdateEventAccessApi
) => {
    try {
        const resp = await put(
            `/requisitions/${requisitionId}/permissions/update/`,
            payload
        );
        if (resp) {
            return resp;
        } else return null;
    } catch (err) {
        return null;
    }
};
