import { memo, SetStateAction, useEffect, useState } from 'react';
import { IRfqTemplate } from '../../../../Global/Interfaces/TemplateInterface';
import { useSelector } from 'react-redux';
import { IGlobalAppStore } from '../../../../Redux/Store';
import { Box, Grid, IconButton } from '@mui/material';
import {
    FWAccordion,
    FWAccordionDetails,
    FWAccordionSummary,
} from '../../../../Common/FWAccordian';
import WarningAccordion from './WarningAccordion';
import { FWTypography } from '../../../../Common/FWTypograhy';
import { FWTooltip } from '../../../../Common/FWCustomTooltip';

const IntegrationWarnings = ({
    rfqTemplate,
    fieldsForExport,
    setFieldsForExport,
    reqsStatus,
    setReqsStatus,
}: {
    rfqTemplate: IRfqTemplate | undefined;
    setFieldsForExport: React.Dispatch<SetStateAction<any>>;
    reqsStatus: any[];
    fieldsForExport: any;
    setReqsStatus: React.Dispatch<React.SetStateAction<any[]>>;
}) => {
    const selectedRequisitions = useSelector(
        (store: IGlobalAppStore) => store.EventIntegrationStore.selectedOrders
    );
    const source = useSelector(
        (store: IGlobalAppStore) => store.EventIntegrationStore.source
    );
    useEffect(() => {
        if (selectedRequisitions) {
            let temp = selectedRequisitions?.map((data) => {
                return {
                    id: data.requisition_id,
                    checked: true,
                };
            });

            setReqsStatus(temp);
        }
        if (selectedRequisitions) {
            let fields = {};
            selectedRequisitions.forEach((data) => {
                fields = { ...fields, [data.requisition_id]: null };
            });
            setFieldsForExport(fields);
        }
        //eslint-disable-next-line
    }, [selectedRequisitions, setReqsStatus]);

    const [warningExpanded, setWarningExpanded] = useState(false);

    const handleFieldsForExportChange = (
        fields: any[],
        requistionId: string
    ) => {
        fieldsForExport[requistionId] = fields;
        setFieldsForExport(fieldsForExport);
    };
    return (
        <>
            <Grid width={'100%'}>
                <FWAccordion
                    expanded={warningExpanded}
                    onChange={() => {
                        setWarningExpanded(!warningExpanded);
                    }}
                    style={{ width: '100%' }}
                    sx={{
                        '&': {
                            border: '0px',
                            padding: '0px',
                        },
                    }}
                >
                    <FWAccordionSummary
                        style={{ width: '100%' }}
                        expandIcon={null}
                    >
                        <Box
                            display={'flex'}
                            justifyContent={'space-between'}
                            alignItems={'center'}
                        >
                            <Box className="flex flex--jcc flex--aic">
                                <FWTypography color={'secondary.main'}>
                                    Fields to be exported
                                </FWTypography>
                                <FWTooltip
                                    placement="right"
                                    style={{
                                        borderRadius: '100%',
                                        maxHeight: '70%',
                                    }}
                                    title={`Selected ${
                                        source === 'CART_ORDERS'
                                            ? 'Cart Order'
                                            : 'Requisition'
                                    } will be used despite mismatches`}
                                >
                                    <IconButton name="">
                                        <FWTypography color={'primary.main'}>
                                            <i
                                                className="bi bi-info-circle"
                                                style={{ fontSize: '15px' }}
                                            ></i>
                                        </FWTypography>
                                    </IconButton>
                                </FWTooltip>
                            </Box>
                            <Box>
                                {warningExpanded ? (
                                    <i
                                        className="bi bi-chevron-up"
                                        style={{ fontSize: '15px' }}
                                    ></i>
                                ) : (
                                    <i
                                        className="bi bi-chevron-down"
                                        style={{ fontSize: '15px' }}
                                    ></i>
                                )}
                            </Box>
                        </Box>
                    </FWAccordionSummary>
                    <FWAccordionDetails>
                        {selectedRequisitions?.map((req) => {
                            return (
                                <>
                                    <WarningAccordion
                                        requisition={req}
                                        rfqTemplate={rfqTemplate}
                                        reqsStatus={reqsStatus}
                                        setReqsStatus={setReqsStatus}
                                        handleFieldsForExportChange={
                                            handleFieldsForExportChange
                                        }
                                    />
                                </>
                            );
                        })}
                    </FWAccordionDetails>
                </FWAccordion>
            </Grid>
        </>
    );
};
export default memo(IntegrationWarnings);
