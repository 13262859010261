import { useMemo } from 'react';
import { UuidRegEx } from '../../../Common/Utils/ValidationUtils';
import { IAddresses } from '../../../Models/Address.model';
import {
    ICurrencyDetails,
    ICurrencyRate,
} from '../../../Models/Currency.model';
import { IIncotermDetails } from '../../../Models/Incoterms.model';
import { IUserPermission } from '../../../Models/UserDetails.model';
import { baseApiSlice } from '../../../Redux/Slices/BaseApiSlice';
import { del, get, post } from '../../../Utils/api';
import { IBuyerItemList } from '../../Interfaces/Buyer/RFQ.model';
import { IEventLevelPermissionMap } from '../../Interfaces/Buyer/eventAccess.interface';

export const getAllCurrencies = (): Promise<ICurrencyDetails[]> => {
    return new Promise(async (resolve, reject) => {
        try {
            let currencyPromise = await get<ICurrencyDetails[]>(
                '/backbone/currency_code'
            );
            currencyPromise.data.sort((a, b) =>
                a.currency_code_abbreviation.localeCompare(
                    b.currency_code_abbreviation
                )
            );
            resolve(currencyPromise.data);
        } catch (err) {
            reject(err);
        }
    });
};

export const { useGetAllCurrenciesQuery } = baseApiSlice.injectEndpoints({
    endpoints: (build) => ({
        getAllCurrencies: build.query<ICurrencyDetails[], null>({
            queryFn: async (arg) => {
                try {
                    let currencyPromise = await get<ICurrencyDetails[]>(
                        '/backbone/currency_code'
                    );
                    currencyPromise.data.sort((a, b) =>
                        a.currency_code_abbreviation.localeCompare(
                            b.currency_code_abbreviation
                        )
                    );
                    return {
                        data: currencyPromise.data,
                    };
                } catch (err: any) {
                    return { error: err };
                }
            },
        }),
    }),
});

export const getCurrencyConversionRate = async (
    currency_from_id: string,
    currency_to_id: string,
    event_id: string,
    seller_id: string,
    type: string = 'BUYER'
): Promise<ICurrencyRate> => {
    try {
        const res = await post<
            {
                currency_from_id: string;
                currency_to_id: string;
                event_id: string;
                seller_id: string;
                type: string;
            },
            ICurrencyRate
        >(
            `/backbone/currency_conversion/${event_id}/${seller_id}/?enterprise_type=${type}`,
            {
                currency_from_id,
                currency_to_id,
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );
        return res.data;
    } catch (err) {
        throw err;
    }
};

export const updateCurrencyConversionRate = async (
    event_id: string,
    type: string = 'BUYER'
) => {
    try {
        const res = await get(
            `/backbone/currency_conversion/${event_id}/update/?enterprise_type=${type}`
        );
        return res.data;
    } catch (err) {
        throw err;
    }
};

export const updateCostingCurrencyConversionRate = async (
    costing_sheet_id: string
) => {
    try {
        const res = await get(
            `/cost_calculator/${costing_sheet_id}/currency_conversion/update/latest/`
        );
        return res.data;
    } catch (err) {
        throw err;
    }
};

export const { useGetCurrencyConversionRateQuery } =
    baseApiSlice.injectEndpoints({
        endpoints: (builder) => ({
            getCurrencyConversionRate: builder.query<
                ICurrencyRate,
                {
                    currency_from_id: string;
                    currency_to_id: string;
                    event_id: string;
                    seller_id: string;
                    type: string;
                }
            >({
                queryFn: async ({
                    currency_from_id,
                    currency_to_id,
                    event_id,
                    seller_id,
                    type,
                }) => {
                    if (!currency_from_id || !currency_to_id) {
                        return {
                            data: {
                                rate: 1,
                                precision: 2,
                            },
                        };
                    }
                    try {
                        const res = await post<
                            {
                                currency_from_id: string;
                                currency_to_id: string;
                                event_id: string;
                                seller_id: string;
                                type: string;
                            },
                            ICurrencyRate
                        >(
                            `/backbone/currency_conversion/${event_id}/${seller_id}/?enterprise_type=${type}`,
                            {
                                currency_from_id,
                                currency_to_id,
                            }
                        );
                        return {
                            data: res.data,
                        };
                    } catch (err: any) {
                        return { error: err };
                    }
                },
            }),
        }),
    });

export const CurrencyApiSlice = baseApiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getListOfAllCurrencies: builder.query<ICurrencyDetails[], {}>({
            query: () => `/backbone/currency_code`,
            transformResponse: (baseQueryReturnValue: ICurrencyDetails[]) => {
                baseQueryReturnValue.sort((a, b) =>
                    a.currency_code_abbreviation.localeCompare(
                        b.currency_code_abbreviation
                    )
                );
                return baseQueryReturnValue;
            },
        }),
    }),
});

export const { useGetListOfAllCurrenciesQuery } = CurrencyApiSlice;

export const useGetSelectedCurrency = (currencyUidOrAbbrevaition: string) => {
    const { data } = useGetListOfAllCurrenciesQuery({});

    const selectedCurrency = useMemo(() => {
        if (UuidRegEx.test(currencyUidOrAbbrevaition))
            return data?.find(
                (currency) => currency.entry_id === currencyUidOrAbbrevaition
            );
        else
            return data?.find(
                (currency) =>
                    currency.currency_code_abbreviation.trim() ===
                    currencyUidOrAbbrevaition?.trim()
            );
    }, [currencyUidOrAbbrevaition, data]);

    return selectedCurrency ? selectedCurrency : null;
};

export const getSelectedCurrency = (
    currencies: ICurrencyDetails[] | undefined,
    currencyUidOrAbbrevaition: string | null | undefined
) => {
    if (!currencyUidOrAbbrevaition) {
        return null;
    }
    if (UuidRegEx.test(currencyUidOrAbbrevaition)) {
        return currencies?.find(
            (currency) => currency.entry_id === currencyUidOrAbbrevaition
        );
    } else {
        return currencies?.find(
            (currency) =>
                currency.currency_code_abbreviation.trim() ===
                currencyUidOrAbbrevaition.trim()
        );
    }
};

export const getAllIncoterms = (): Promise<IIncotermDetails[]> => {
    return new Promise(async (resolve, reject) => {
        try {
            let incotermsPromise = await get<IIncotermDetails[]>(
                '/backbone/incoterms'
            );
            let sortedData = incotermsPromise.data.sort((x, y) =>
                x.incoterm_abbreviation.localeCompare(y.incoterm_abbreviation)
            );
            let evenMoreSortedData = sortedData
                .filter((x) => x.incoterm_abbreviation === 'NA')
                .concat(
                    sortedData.filter((x) => x.incoterm_abbreviation !== 'NA')
                );
            resolve(evenMoreSortedData);
        } catch (err) {
            reject();
        }
    });
};

export const { useGetAllIncotermsQuery } = baseApiSlice.injectEndpoints({
    endpoints: (build) => ({
        getAllIncoterms: build.query<IIncotermDetails[], {}>({
            queryFn: async () => {
                try {
                    let incotermsPromise = await get<IIncotermDetails[]>(
                        '/backbone/incoterms'
                    );
                    let sortedData = incotermsPromise.data.sort((x, y) =>
                        x.incoterm_abbreviation.localeCompare(
                            y.incoterm_abbreviation
                        )
                    );
                    let evenMoreSortedData = sortedData
                        .filter((x) => x.incoterm_abbreviation === 'NA')
                        .concat(
                            sortedData.filter(
                                (x) => x.incoterm_abbreviation !== 'NA'
                            )
                        );
                    return {
                        data: evenMoreSortedData,
                    };
                } catch (err: any) {
                    return { error: err };
                }
            },
        }),
    }),
});

export const { useGetAddressForEntityQuery } = baseApiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getAddressForEntity: builder.query<IAddresses[], { entity_id: string }>(
            {
                query: ({ entity_id }) => ({
                    url: `/organization/address/entity/${entity_id}/`,
                    method: 'GET',
                }),
            }
        ),
    }),
});

export const getAddressForEntity = (
    entity_id: string
): Promise<IAddresses[]> => {
    return new Promise<IAddresses[]>(async (resolve, reject) => {
        try {
            let adressesPromise = await get<IAddresses[]>(
                `/organization/address/entity/${entity_id}/`
            );
            resolve(adressesPromise.data);
        } catch (err) {
            reject();
        }
    });
};

//REHUL API TAKE FROM HERE
export const newgetBuyerMasterItemsForEntity = async ({
    sortFields,
    searchText,
    pageNumber,
    itemsPerPage,
    entityUid,
    itemIds = [],
}: {
    sortFields: {
        field: string;
        ascending: boolean;
    }[];
    searchText: string;
    pageNumber: number;
    itemsPerPage: number;
    entityUid: string;
    itemIds?: string[];
}): Promise<{
    items: IBuyerItemList[];
    pageDetails: {
        currentPage: number;
        totalPages: number;
        count: number;
        hasNext: boolean;
    };
}> => {
    const item_response = await post<any, any>(`/dashboard/`, {
        dashboard_view: 'entity_item',
        tab: 'all',
        sort_fields: sortFields,
        search_text: searchText,
        items_per_page: itemsPerPage,
        page_number: pageNumber,
        query_data: {
            entity_id: entityUid,
            item_ids: itemIds,
        },
    });

    const pageDetails = {
        currentPage: item_response.data.metadata.current_page,
        totalPages: item_response.data.metadata.total_pages,
        count: item_response.data.metadata.count,
        hasNext: item_response.data.metadata.has_next,
    };
    return {
        items: item_response.data.data,
        pageDetails: pageDetails,
    };
};

export const getBuyerMasterItemsForEntity = (
    entity_id: string
): Promise<IBuyerItemList[]> => {
    return new Promise<IBuyerItemList[]>(async (resolve, reject) => {
        try {
            let item_promise = await get<IBuyerItemList[]>(
                `/organization/items/entity/${entity_id}/top/`
            );
            resolve(item_promise.data);
        } catch (err) {
            reject();
        }
    });
};

export const getEventPermissionsOfAllUsers = (
    rfq_event_id: string
): Promise<IEventLevelPermissionMap> => {
    return new Promise(async (resolve, reject) => {
        try {
            let resp = await get<IEventLevelPermissionMap>(
                `/events/rfq/${rfq_event_id}/permissions?type=BUYER`
            );
            resolve(resp.data);
        } catch (err) {
            reject();
        }
    });
};

export const getEntityPermissionsOfAllUsers = (): Promise<{
    [key: string]: IUserPermission[];
}> => {
    return new Promise(async (resolve, reject) => {
        try {
            let resp = await get<{ [key: string]: IUserPermission[] }>(
                `/organization/user_permission?all_users=true&type=BUYER`
            );
            resolve(resp.data);
        } catch (err) {
            reject();
        }
    });
};

export interface IExistingEvent {
    custom_event_id: string;
    status: string;
    event_id: string;
    event_name: string;
}

export const getAllExistingEvents = (): Promise<IExistingEvent[]> => {
    return new Promise(async (resolve, reject) => {
        try {
            let resp = await get<IExistingEvent[]>(`/events/names/`);
            resolve(resp.data);
        } catch (err) {
            reject();
        }
    });
};

export const getAllExistingPOGroups = (): Promise<IExistingEvent[]> => {
    return new Promise(async (resolve, reject) => {
        try {
            let resp = await get<IExistingEvent[]>(
                `/purchase_orders/po_group/names/`
            );
            resolve(resp.data);
        } catch (err) {
            reject();
        }
    });
};

export const getSharedEventHistory = (rfqid: string): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            let history = await get(
                `/events/rfq/${rfqid}/permissions/history/?type=BUYER`
            );

            resolve(history.data);
        } catch (err) {
            reject();
        }
    });
};

export const deleteProxyQoute = async (
    rfq_event_id: string,
    rfq_bid_id: string
) => {
    try {
        const res: any = await del(
            `/events/rfq/${rfq_event_id}/bids/proxy_quote/${rfq_bid_id}/delete/`
        );
        return res.data;
    } catch (err) {
        throw err;
    }
};

export const deleteInvalidQuote = async (
    rfq_event_id: string,
    rfq_bid_id: string,
    enterprise_type: string
) => {
    try {
        const res: any = await del(
            `/events/rfq/${rfq_event_id}/bids/${rfq_bid_id}/delete/?enterprise_type=${enterprise_type}`
        );
        return res.data;
    } catch (err) {
        throw err;
    }
};

export const deleteDraftQuote = async (
    rfq_event_id: string,
    rfq_bid_id: string,
    enterprise_type: string
) => {
    try {
        const res: any = await del(
            `/events/rfq/${rfq_event_id}/bids/${rfq_bid_id}/delete/?enterprise_type=${enterprise_type}`
        );
        return res.data;
    } catch (err) {
        throw err;
    }
};
