import { cloneDeep } from 'lodash';
import { getTemplateFrontOptionFromListOfTemplateItems } from '../../AdditionalCost/helpers';
import { getFieldTypeContraintsFromBackend } from '../../FieldTypes/helpers/fieldTypesHelpers';
import { convertTemplateConstraintsItemToCustomFIeldType } from '../../Global/Helpers/templateApiSlice';
import { IRFQTemplateBackendResponse } from '../../Global/Interfaces/TemplateInterface';
import { baseApiSlice } from '../../Redux/Slices/BaseApiSlice';
import {
    CostingTemplateStandardFieldBackendNames,
    ICostingTemplate,
    IMultipleQuoteTemplateApiResponse,
    TemplateStandardFieldsEnum,
    blankCostingTemplate,
} from '../Interfaces/CostingTemplate.interface';
import { templateType } from '../../BuyerTemplates/Interfaces/TemplatePageInterfaces';

export const costingTemplateApiSlice = baseApiSlice.injectEndpoints({
    endpoints: (build) => ({
        getCostingTemplateById: build.query<
            ICostingTemplate,
            { templateId: string; entityId: string }
        >({
            query: ({ entityId, templateId }) =>
                `/module_templates/${entityId}/${templateId}`,
            transformResponse: (
                baseQueryReturnValue: IRFQTemplateBackendResponse,
                meta,
                arg
            ): ICostingTemplate => {
                const response: ICostingTemplate =
                    cloneDeep(blankCostingTemplate);

                response.templateId = baseQueryReturnValue.template_id;
                response.templateName = baseQueryReturnValue.name;
                response.assigned_users = baseQueryReturnValue.assigned_users;
                const templateSections = baseQueryReturnValue.section_list.map(
                    (section) => {
                        return {
                            ...section,
                            section_items: section.section_items.map(
                                (item: any) => {
                                    return {
                                        ...item,
                                        alternate_name:
                                            item.alternate_name.replace(
                                                '_',
                                                ''
                                            ),
                                        name: item.name.replace('_', ''),
                                    };
                                }
                            ),
                        };
                    }
                );
                baseQueryReturnValue = {
                    ...baseQueryReturnValue,
                    section_list: templateSections,
                };

                const quoteDetailsFromBackend =
                    baseQueryReturnValue.section_list.find(
                        (section) => section.name === 'Quote Details'
                    );

                response.sections.QUOTE_DETAILS = {
                    label:
                        quoteDetailsFromBackend &&
                        quoteDetailsFromBackend.alternate_name
                            ? quoteDetailsFromBackend.alternate_name
                            : 'Quote Details',
                    section_type: 'OTHER',
                    assigned_users:
                        quoteDetailsFromBackend?.assigned_users ?? [],
                    fieldList: [],
                };

                const quoteDetailsItemsFromBackend =
                    quoteDetailsFromBackend?.section_items.filter(
                        (item) => item.parent_section_item === null
                    ) ?? [];

                quoteDetailsItemsFromBackend.forEach((item) => {
                    if (
                        Object.keys(
                            CostingTemplateStandardFieldBackendNames
                        ).includes(item.name)
                    ) {
                        let formattedData = {
                            label: item.alternate_name
                                ? item.alternate_name
                                : item.name,
                            description: item.description,
                            buyerSettings: {
                                required: item.is_required,
                                showField:
                                    !(
                                        item.additional_information.is_hidden ??
                                        false
                                    ) &&
                                    (item.additional_information.is_visible ??
                                        true),
                            },
                            sellerSettings: {
                                negotiable:
                                    !(
                                        item.additional_information.is_hidden ??
                                        false
                                    ) &&
                                    (item.additional_information.is_visible ??
                                        true) &&
                                    (item.additional_information
                                        .is_negotiable ??
                                        true),
                                required:
                                    item.is_mandatory &&
                                    item.additional_information.is_visible,
                                showField:
                                    item.additional_information.is_visible ??
                                    true,
                            },
                        };

                        response.sections.QUOTE_DETAILS.fieldList.push({
                            field: CostingTemplateStandardFieldBackendNames[
                                item.name
                            ],
                            fieldType: 'STANDARD',
                        });

                        response.fieldSettings.standardFields = {
                            ...response.fieldSettings.standardFields,
                            [CostingTemplateStandardFieldBackendNames[
                                item.name
                            ]]: formattedData,
                        };
                    } else {
                        let formattedData = {
                            label: item.alternate_name
                                ? item.alternate_name
                                : item.name,
                            description: item.description,
                            buyerSettings: {
                                required: item.is_required,
                                showField:
                                    !(
                                        item.additional_information.is_hidden ??
                                        false
                                    ) &&
                                    (item.additional_information.is_visible ??
                                        true),
                            },
                            sellerSettings: {
                                negotiable:
                                    !(
                                        item.additional_information.is_hidden ??
                                        false
                                    ) &&
                                    (item.additional_information.is_visible ??
                                        true) &&
                                    (item.additional_information
                                        .is_negotiable ??
                                        true),
                                required:
                                    item.is_mandatory &&
                                    item.additional_information.is_visible,
                                showField:
                                    item.additional_information.is_visible ??
                                    true,
                            },
                            type: convertTemplateConstraintsItemToCustomFIeldType(
                                item.constraints.field_type
                            ),
                            constraints: getFieldTypeContraintsFromBackend({
                                field_type: item.constraints.field_type,
                                choices: item.constraints.choices,
                                max_limit: item.constraints.max_limit,
                                min_limit: item.constraints.min_limit,
                                choice_type: item.constraints.choice_type,
                            }),
                        };
                        response.sections.QUOTE_DETAILS.fieldList.push({
                            field: item.name,
                            fieldType: 'CUSTOM',
                        });
                        response.fieldSettings.customFields = {
                            ...response.fieldSettings.customFields,
                            [item.name]: formattedData,
                        };
                    }
                });

                const essentialTermsfromBackend =
                    baseQueryReturnValue.section_list.find(
                        (section) => section.name === 'Item Terms'
                    );

                response.sections.ESSENTIAL_TERMS = {
                    label:
                        essentialTermsfromBackend &&
                        essentialTermsfromBackend.alternate_name
                            ? essentialTermsfromBackend.alternate_name
                            : 'Item Terms',
                    assigned_users:
                        essentialTermsfromBackend?.assigned_users ?? [],
                    section_type: 'ITEM',
                    fieldList: [],
                };

                const essentialTermsItemsfromBackend =
                    essentialTermsfromBackend?.section_items.filter(
                        (item) =>
                            [
                                'Delivery date',
                                'Cost center',
                                'Quantity',
                                'GL',
                                'Project',
                            ].includes(item.name) ||
                            item.parent_section_item === null
                    ) ?? [];

                essentialTermsItemsfromBackend.forEach((item) => {
                    if (
                        Object.keys(
                            CostingTemplateStandardFieldBackendNames
                        ).includes(item.name)
                    ) {
                        let formattedData = {
                            label: item.alternate_name
                                ? item.alternate_name
                                : item.name,
                            description: item.description,
                            buyerSettings: {
                                required: item.is_required,
                                showField:
                                    !(
                                        item.additional_information.is_hidden ??
                                        false
                                    ) &&
                                    (item.additional_information.is_visible ??
                                        true),
                            },
                            sellerSettings: {
                                negotiable:
                                    !(
                                        item.additional_information.is_hidden ??
                                        false
                                    ) &&
                                    (item.additional_information.is_visible ??
                                        true) &&
                                    (item.additional_information
                                        .is_negotiable ??
                                        true),
                                required:
                                    item.is_mandatory &&
                                    item.additional_information.is_visible,
                                showField:
                                    item.additional_information.is_visible ??
                                    true,
                            },
                        };

                        response.sections.ESSENTIAL_TERMS.fieldList.push({
                            field: CostingTemplateStandardFieldBackendNames[
                                item.name
                            ],
                            fieldType: 'STANDARD',
                        });

                        if (
                            CostingTemplateStandardFieldBackendNames[
                                item.name
                            ] === TemplateStandardFieldsEnum.ADDITIONAL_COSTS
                        ) {
                            const additionalCostOptions =
                                getTemplateFrontOptionFromListOfTemplateItems(
                                    essentialTermsfromBackend?.section_items.filter(
                                        (sectionItem) =>
                                            sectionItem.parent_section_item ===
                                            item.section_item_id
                                    ) ?? []
                                );

                            formattedData.buyerSettings.showField =
                                formattedData.buyerSettings.showField
                                    ? additionalCostOptions.length > 0
                                    : false;

                            formattedData.sellerSettings.showField =
                                formattedData.sellerSettings.showField
                                    ? additionalCostOptions.length > 0
                                    : false;

                            response.fieldSettings.standardFields[
                                TemplateStandardFieldsEnum.ADDITIONAL_COSTS
                            ] = {
                                ...formattedData,
                                additionalCostOptions,
                                standardFieldCategory: 'ADDITIONAL_COSTS',
                            };
                        } else {
                            response.fieldSettings.standardFields = {
                                ...response.fieldSettings.standardFields,
                                [CostingTemplateStandardFieldBackendNames[
                                    item.name
                                ]]: formattedData,
                            };
                        }
                    } else if (!item.is_builtin_field) {
                        let formattedData = {
                            label: item.alternate_name
                                ? item.alternate_name
                                : item.name,
                            description: item.description,
                            buyerSettings: {
                                required: item.is_required,
                                showField:
                                    !(
                                        item.additional_information.is_hidden ??
                                        false
                                    ) &&
                                    (item.additional_information.is_visible ??
                                        true),
                            },
                            sellerSettings: {
                                negotiable:
                                    !(
                                        item.additional_information.is_hidden ??
                                        false
                                    ) &&
                                    (item.additional_information.is_visible ??
                                        true) &&
                                    (item.additional_information
                                        .is_negotiable ??
                                        true),
                                required:
                                    item.is_mandatory &&
                                    item.additional_information.is_visible,
                                showField:
                                    item.additional_information.is_visible ??
                                    true,
                            },
                            type: convertTemplateConstraintsItemToCustomFIeldType(
                                item.constraints.field_type
                            ),
                            constraints: getFieldTypeContraintsFromBackend({
                                field_type: item.constraints.field_type,
                                choices: item.constraints.choices,
                                max_limit: item.constraints.max_limit,
                                min_limit: item.constraints.min_limit,
                                choice_type: item.constraints.choice_type,
                            }),
                        };
                        response.sections.ESSENTIAL_TERMS.fieldList.push({
                            field: item.name,
                            fieldType: 'CUSTOM',
                        });
                        response.fieldSettings.customFields = {
                            ...response.fieldSettings.customFields,
                            [item.name]: formattedData,
                        };
                    }
                });

                const bomFromBackend = baseQueryReturnValue.section_list.find(
                    (section) => section.name === 'BOM'
                );

                response.sections.BOM = {
                    label:
                        bomFromBackend && bomFromBackend.alternate_name
                            ? bomFromBackend.alternate_name
                            : 'BOM',
                    section_type: 'BOM',
                    assigned_users: bomFromBackend?.assigned_users ?? [],
                    fieldList: [],
                };

                const bomItemsFromBackend =
                    bomFromBackend?.section_items.filter(
                        (item) => item.parent_section_item === null
                    ) ?? [];

                bomItemsFromBackend.forEach((item) => {
                    if (
                        Object.keys(
                            CostingTemplateStandardFieldBackendNames
                        ).includes(item.name)
                    ) {
                        let formattedData = {
                            label: item.alternate_name
                                ? item.alternate_name
                                : item.name,
                            description: item.description,
                            buyerSettings: {
                                required: item.is_required,
                                showField:
                                    !(
                                        item.additional_information.is_hidden ??
                                        false
                                    ) &&
                                    (item.additional_information.is_visible ??
                                        true),
                            },
                            sellerSettings: {
                                negotiable:
                                    !(
                                        item.additional_information.is_hidden ??
                                        false
                                    ) &&
                                    (item.additional_information.is_visible ??
                                        true) &&
                                    (item.additional_information
                                        .is_negotiable ??
                                        true),
                                required:
                                    item.is_mandatory &&
                                    item.additional_information.is_visible,
                                showField:
                                    item.additional_information.is_visible ??
                                    true,
                            },
                        };

                        response.sections.BOM.fieldList.push({
                            field: CostingTemplateStandardFieldBackendNames[
                                item.name
                            ],
                            fieldType: 'STANDARD',
                        });

                        if (
                            CostingTemplateStandardFieldBackendNames[
                                item.name
                            ] ===
                            TemplateStandardFieldsEnum.BOM_ADDITIONAL_COSTS
                        ) {
                            const additionalCostOptions =
                                getTemplateFrontOptionFromListOfTemplateItems(
                                    bomFromBackend?.section_items.filter(
                                        (sectionItem) =>
                                            sectionItem.parent_section_item ===
                                            item.section_item_id
                                    ) ?? []
                                );

                            formattedData.buyerSettings.showField =
                                formattedData.buyerSettings.showField
                                    ? additionalCostOptions.length > 0
                                    : false;

                            formattedData.sellerSettings.showField =
                                formattedData.sellerSettings.showField
                                    ? additionalCostOptions.length > 0
                                    : false;

                            response.fieldSettings.standardFields[
                                TemplateStandardFieldsEnum.BOM_ADDITIONAL_COSTS
                            ] = {
                                ...formattedData,
                                additionalCostOptions,
                                standardFieldCategory: 'ADDITIONAL_COSTS',
                            };
                        } else {
                            response.fieldSettings.standardFields = {
                                ...response.fieldSettings.standardFields,
                                [CostingTemplateStandardFieldBackendNames[
                                    item.name
                                ]]: formattedData,
                            };
                        }
                    } else if (!item.is_builtin_field) {
                        let formattedData = {
                            label: item.alternate_name
                                ? item.alternate_name
                                : item.name,
                            description: item.description,
                            buyerSettings: {
                                required: item.is_required,
                                showField:
                                    !(
                                        item.additional_information.is_hidden ??
                                        false
                                    ) &&
                                    (item.additional_information.is_visible ??
                                        true),
                            },
                            sellerSettings: {
                                negotiable:
                                    !(
                                        item.additional_information.is_hidden ??
                                        false
                                    ) &&
                                    (item.additional_information.is_visible ??
                                        true) &&
                                    (item.additional_information
                                        .is_negotiable ??
                                        true),
                                required:
                                    item.is_mandatory &&
                                    item.additional_information.is_visible,
                                showField:
                                    item.additional_information.is_visible ??
                                    true,
                            },
                            type: convertTemplateConstraintsItemToCustomFIeldType(
                                item.constraints.field_type
                            ),
                            constraints: getFieldTypeContraintsFromBackend({
                                field_type: item.constraints.field_type,
                                choices: item.constraints.choices,
                                max_limit: item.constraints.max_limit,
                                min_limit: item.constraints.min_limit,
                                choice_type: item.constraints.choice_type,
                            }),
                        };
                        response.sections.BOM.fieldList.push({
                            field: item.name,
                            fieldType: 'CUSTOM',
                        });
                        response.fieldSettings.customFields = {
                            ...response.fieldSettings.customFields,
                            [item.name]: formattedData,
                        };
                    }
                });

                const overallAdditionalCostsFromBackend =
                    baseQueryReturnValue.section_list.find(
                        (section) => section.name === 'Overall Additional costs'
                    );

                response.sections.OVERALL_ADDITIONAL_COSTS = {
                    label:
                        overallAdditionalCostsFromBackend &&
                        overallAdditionalCostsFromBackend.alternate_name
                            ? overallAdditionalCostsFromBackend.alternate_name
                            : 'Overall Additional costs',
                    section_type: 'OTHER',
                    assigned_users:
                        overallAdditionalCostsFromBackend?.assigned_users ?? [],
                    fieldList: [],
                };

                const overallAdditionalCostsItemsFromBackend =
                    overallAdditionalCostsFromBackend?.section_items.filter(
                        (item) => item.parent_section_item === null
                    ) ?? [];

                overallAdditionalCostsItemsFromBackend.forEach((item) => {
                    if (
                        Object.keys(
                            CostingTemplateStandardFieldBackendNames
                        ).includes(item.name)
                    ) {
                        let formattedData = {
                            label: item.alternate_name
                                ? item.alternate_name
                                : item.name,
                            description: item.description,
                            buyerSettings: {
                                required: item.is_required,
                                showField:
                                    !(
                                        item.additional_information.is_hidden ??
                                        false
                                    ) &&
                                    (item.additional_information.is_visible ??
                                        true),
                            },
                            sellerSettings: {
                                negotiable:
                                    !(
                                        item.additional_information.is_hidden ??
                                        false
                                    ) &&
                                    (item.additional_information.is_visible ??
                                        true) &&
                                    (item.additional_information
                                        .is_negotiable ??
                                        true),
                                required:
                                    item.is_mandatory &&
                                    item.additional_information.is_visible,
                                showField:
                                    item.additional_information.is_visible ??
                                    true,
                            },
                        };

                        response.sections.OVERALL_ADDITIONAL_COSTS.fieldList.push(
                            {
                                field: CostingTemplateStandardFieldBackendNames[
                                    item.name
                                ],
                                fieldType: 'STANDARD',
                            }
                        );

                        if (
                            CostingTemplateStandardFieldBackendNames[
                                item.name
                            ] ===
                            TemplateStandardFieldsEnum.OVERALL_ADDITIONAL_COSTS
                        ) {
                            const additionalCostOptions =
                                getTemplateFrontOptionFromListOfTemplateItems(
                                    overallAdditionalCostsFromBackend?.section_items.filter(
                                        (sectionItem) =>
                                            sectionItem.parent_section_item ===
                                            item.section_item_id
                                    ) ?? []
                                );

                            formattedData.buyerSettings.showField =
                                formattedData.buyerSettings.showField
                                    ? additionalCostOptions.length > 0
                                    : false;

                            formattedData.sellerSettings.showField =
                                formattedData.sellerSettings.showField
                                    ? additionalCostOptions.length > 0
                                    : false;

                            response.fieldSettings.standardFields[
                                TemplateStandardFieldsEnum.OVERALL_ADDITIONAL_COSTS
                            ] = {
                                ...formattedData,
                                additionalCostOptions,
                                standardFieldCategory: 'ADDITIONAL_COSTS',
                            };
                        } else {
                            response.fieldSettings.standardFields = {
                                ...response.fieldSettings.standardFields,
                                [CostingTemplateStandardFieldBackendNames[
                                    item.name
                                ]]: formattedData,
                            };
                        }
                    } else if (!item.is_builtin_field) {
                        let formattedData = {
                            label: item.alternate_name
                                ? item.alternate_name
                                : item.name,
                            description: item.description,
                            buyerSettings: {
                                required: item.is_required,
                                showField:
                                    !(
                                        item.additional_information.is_hidden ??
                                        false
                                    ) &&
                                    (item.additional_information.is_visible ??
                                        true),
                            },
                            sellerSettings: {
                                negotiable:
                                    !(
                                        item.additional_information.is_hidden ??
                                        false
                                    ) &&
                                    (item.additional_information.is_visible ??
                                        true) &&
                                    (item.additional_information
                                        .is_negotiable ??
                                        true),
                                required:
                                    item.is_mandatory &&
                                    item.additional_information.is_visible,
                                showField:
                                    item.additional_information.is_visible ??
                                    true,
                            },
                            type: convertTemplateConstraintsItemToCustomFIeldType(
                                item.constraints.field_type
                            ),
                            constraints: getFieldTypeContraintsFromBackend({
                                field_type: item.constraints.field_type,
                                choices: item.constraints.choices,
                                max_limit: item.constraints.max_limit,
                                min_limit: item.constraints.min_limit,
                                choice_type: item.constraints.choice_type,
                            }),
                        };
                        response.sections.OVERALL_ADDITIONAL_COSTS.fieldList.push(
                            {
                                field: item.name,
                                fieldType: 'CUSTOM',
                            }
                        );
                        response.fieldSettings.customFields = {
                            ...response.fieldSettings.customFields,
                            [item.name]: formattedData,
                        };
                    }
                });

                return response;
            },
        }),

        getListOfAllQuoteTemplates: build.query<
            IMultipleQuoteTemplateApiResponse[],
            { templateType: templateType; entityId?: string }
        >({
            query: ({ templateType, entityId }) => ({
                url: `/module_templates/?template_type=${
                    templateType === 'QUOTE CALCULATOR'
                        ? 'QUOTE_CALCULATOR'
                        : templateType
                }${entityId ? `&entity_id=${entityId}` : ''}`,
            }),
        }),
    }),
});

export const {
    useGetCostingTemplateByIdQuery,
    useGetListOfAllQuoteTemplatesQuery,
} = costingTemplateApiSlice;
