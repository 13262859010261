import { cloneDeep } from 'lodash';
import { defaultAutoReminderData } from '../../BuyerTemplates/Component/AddTemplatePopUp';
import {
    IEventItemCustomFieldSections,
    IEventItemCustomFieldsBackend,
    IEventItemDetails,
    custom_field_backend_section_names,
} from '../../Events/Interfaces/Buyer/RFQ.model';
import { IPurchaseOrderItem as poItemInterface1 } from '../../Models/AwardsAndPO.model';
import { IProjectTemplate } from '../../ProjectGlCostCenter/interface/project.model';
import { IPurchaseOrderItem as poItemInterface2 } from '../../PurchaseOrders/Interfaces/PO.model';
import { CustomTemplateItemTypes } from '../Interfaces/CustomTemplate.interface';
import {
    CustomFieldType,
    IRfqTemplate,
    ITemplatePaymentTermsOptionsInvoiceItemValues,
    ITemplatePaymentTermsOptionsPoItemPercentages,
    RfqStandardSections,
    TemplateStandardFieldsEnum,
    standardSectionsBackendName,
} from '../Interfaces/TemplateInterface';

export const getTemplateWithOnTheFlyFields = ({
    items,
    rfqTemplate,
}: {
    items: (poItemInterface1 | poItemInterface2 | IEventItemDetails)[];
    rfqTemplate: IRfqTemplate;
}): IRfqTemplate => {
    const newTemplate = cloneDeep(rfqTemplate);
    for (let item of items) {
        [item.custom_fields_negotiate, item.custom_fields].forEach(
            (area, idx) => {
                if (area) {
                    const negotiable = idx === 0;
                    for (let section of area.section_list) {
                        const curSection: RfqStandardSections | null =
                            section.name ===
                            custom_field_backend_section_names.payment_and_delivery_terms_template_name
                                ? RfqStandardSections.PAYMENT_AND_DELIVERY_TERMS
                                : section.name ===
                                  custom_field_backend_section_names.additional_information_template_name
                                ? RfqStandardSections.ADDITIONAL_DETAILS
                                : null;
                        if (curSection) {
                            for (let cf of section.fields) {
                                if (negotiable || cf.is_visible) {
                                    if (
                                        !Object.keys(
                                            newTemplate.fieldSettings
                                                .customFields
                                        ).includes(cf.name)
                                    ) {
                                        newTemplate.fieldSettings.customFields[
                                            cf.name
                                        ] = {
                                            label: cf.name,
                                            description: '',
                                            type: CustomFieldType.SHORT_TEXT,
                                            sendToPo: true,
                                            canSplit: false,

                                            buyerSettings: {
                                                required: false,
                                                showField: true,
                                            },
                                            sellerSettings: {
                                                showField: true,
                                                negotiable: negotiable,
                                                isMandatory: false,
                                            },
                                            constraints: {
                                                fieldType:
                                                    CustomFieldType.SHORTTEXT,
                                                maxLimit: 15,
                                                minLimit: 0,
                                            },
                                        };
                                        newTemplate.sections[
                                            curSection
                                        ].fieldList.push({
                                            fieldType: 'CUSTOM',
                                            field: cf.name,
                                        });
                                    }
                                }
                            }
                        }
                    }
                }
            }
        );
    }
    return newTemplate;
};

export const fetchRfqTemplate = async (
    templateId: string
): Promise<IRfqTemplate> => {
    return new Promise((resolve, reject) => {
        resolve(dummyTemplate);
    });
};

export const blankRfqTemplate: IRfqTemplate = {
    templateId: '',
    templateName: '',
    fieldSettings: {
        standardFields: {
            EVENT_NAME: {
                standardFieldCategory: 'STANDARD',
                label: 'Event name',
                description: '',
                buyerSettings: {
                    required: true,
                    showField: true,
                },

                sellerSettings: {
                    negotiable: true,
                    showField: true,
                    isMandatory: false,
                },
                sendToPo: true,
                canSplit: false,
            },
            CUSTOMER_NAME: {
                standardFieldCategory: 'STANDARD',
                label: 'Customer name',
                description: '',
                buyerSettings: {
                    required: false,
                    showField: false,
                },

                sellerSettings: {
                    negotiable: false,
                    showField: false,
                    isMandatory: false,
                },
                sendToPo: false,
                canSplit: false,
            },
            VENDOR: {
                standardFieldCategory: 'STANDARD',
                label: 'Vendor',
                description: '',
                buyerSettings: {
                    required: false,
                    showField: false,
                },

                sellerSettings: {
                    negotiable: false,
                    showField: false,
                    isMandatory: false,
                },
                sendToPo: false,
                canSplit: false,
            },
            BOM: {
                label: 'BOM',
                buyerSettings: {
                    required: false,
                    showField: false,
                },
                description: '',
                sellerSettings: {
                    isMandatory: false,
                    negotiable: false,
                    showField: false,
                },
                standardFieldCategory: 'STANDARD',
            },
            BOM_ID: {
                label: 'BOM',
                buyerSettings: {
                    required: false,
                    showField: false,
                },
                description: '',
                sellerSettings: {
                    isMandatory: false,
                    negotiable: false,
                    showField: false,
                },
                standardFieldCategory: 'STANDARD',
            },
            BUYER_ENTITY: {
                standardFieldCategory: 'STANDARD',
                label: 'Buyer Entity',
                description: '',
                buyerSettings: {
                    required: true,
                    showField: true,
                },
                sellerSettings: {
                    negotiable: true,
                    showField: true,
                    isMandatory: false,
                },
                sendToPo: true,
                canSplit: false,
            },
            CURRENCY: {
                standardFieldCategory: 'STANDARD',
                label: 'Currency',
                description: '',
                buyerSettings: {
                    required: true,
                    showField: true,
                },
                sellerSettings: {
                    negotiable: true,
                    showField: true,
                    isMandatory: false,
                },
                sendToPo: true,
                canSplit: false,
            },
            DEFAULT_ITEM_TERMS: {
                standardFieldCategory: 'STANDARD',
                label: 'Default item terms',
                description: '',
                buyerSettings: {
                    required: true,
                    showField: true,
                },
                sellerSettings: {
                    negotiable: true,
                    showField: true,
                    isMandatory: false,
                },
                sendToPo: true,
                canSplit: false,
            },
            EVENT_DEADLINE: {
                standardFieldCategory: 'STANDARD',
                label: 'Event deadline',
                description: '',
                buyerSettings: {
                    required: true,
                    showField: true,
                },
                sellerSettings: {
                    negotiable: true,
                    showField: true,
                    isMandatory: false,
                },
                sendToPo: true,
                canSplit: false,
            },
            TEMPLATE: {
                standardFieldCategory: 'STANDARD',
                label: 'Template',
                description: '',
                buyerSettings: {
                    required: true,
                    showField: true,
                },
                sellerSettings: {
                    negotiable: true,
                    showField: true,
                    isMandatory: false,
                },
                sendToPo: true,
                canSplit: false,
            },
            EVENT_TYPE: {
                standardFieldCategory: 'STANDARD',
                label: 'Event type',
                description: '',
                buyerSettings: {
                    required: true,
                    showField: true,
                },
                sellerSettings: {
                    negotiable: true,
                    showField: true,
                    isMandatory: false,
                },
                sendToPo: true,
                canSplit: false,
            },
            QUANTITY: {
                standardFieldCategory: 'STANDARD',
                label: '',
                description: '',
                buyerSettings: {
                    showField: false,
                    required: false,
                },
                sellerSettings: {
                    showField: false,
                    negotiable: false,
                    isMandatory: false,
                },
                sendToPo: true,
                canSplit: false,
            },
            DELIVERY_DATE: {
                standardFieldCategory: 'STANDARD',
                label: '',
                description: '',
                buyerSettings: {
                    showField: false,
                    required: false,
                },
                sellerSettings: {
                    showField: false,
                    negotiable: false,
                    isMandatory: false,
                },
                sendToPo: true,
                canSplit: false,
            },
            BASE_RATE: {
                standardFieldCategory: 'STANDARD',
                label: '',
                description: '',
                buyerSettings: {
                    showField: false,
                    required: false,
                },
                sellerSettings: {
                    showField: false,
                    negotiable: false,
                    isMandatory: false,
                },
                sendToPo: true,
                canSplit: false,
            },
            SHIPPING_RATE: {
                standardFieldCategory: 'STANDARD',
                label: '',
                description: '',
                buyerSettings: {
                    showField: false,
                    required: false,
                },
                sellerSettings: {
                    showField: false,
                    negotiable: false,
                    isMandatory: false,
                },
                sendToPo: true,
                canSplit: false,
            },
            PAYMENT_TERMS: {
                standardFieldCategory: 'PAYMENT_TERMS',
                label: '',
                description: '',
                buyerSettings: {
                    showField: false,
                    required: false,
                },
                sellerSettings: {
                    showField: false,
                    negotiable: false,
                    isMandatory: false,
                },
                paymentTermOptions: {
                    paymentType: 'poItemPercentages',
                    prepaymentAllowed: true,
                    allowOtherDeliverableNames: false,
                    deliverableNameOptions: [],
                },
                sendToPo: true,
                canSplit: false,
            },
            INCOTERMS: {
                standardFieldCategory: 'STANDARD',
                label: '',
                description: '',
                buyerSettings: {
                    showField: false,
                    required: false,
                },
                sellerSettings: {
                    showField: false,
                    negotiable: false,
                    isMandatory: false,
                },
                sendToPo: true,
                canSplit: false,
            },
            LEAD_TIME: {
                standardFieldCategory: 'STANDARD',
                label: '',
                description: '',
                buyerSettings: {
                    showField: false,
                    required: false,
                },
                sellerSettings: {
                    showField: false,
                    negotiable: false,
                    isMandatory: false,
                },
                sendToPo: true,
                canSplit: false,
            },
            GR_TOLERANCE: {
                standardFieldCategory: 'STANDARD',
                label: '',
                description: '',
                buyerSettings: {
                    showField: false,
                    required: false,
                },
                sellerSettings: {
                    showField: false,
                    negotiable: false,
                    isMandatory: false,
                },
                sendToPo: true,
                canSplit: false,
            },
            REQUISITION: {
                standardFieldCategory: 'STANDARD',
                label: '',
                description: '',
                buyerSettings: {
                    showField: false,
                    required: false,
                },
                sellerSettings: {
                    showField: false,
                    negotiable: false,
                    isMandatory: false,
                },
                sendToPo: true,
                canSplit: false,
            },
            ADDITIONAL_INFORMATION: {
                standardFieldCategory: 'STANDARD',
                label: '',
                description: '',
                buyerSettings: {
                    showField: false,
                    required: false,
                },
                sellerSettings: {
                    showField: false,
                    negotiable: false,
                    isMandatory: false,
                },
                sendToPo: true,
                canSplit: false,
            },
            ITEM_DESCRIPTION: {
                standardFieldCategory: 'STANDARD',
                label: '',
                description: '',
                buyerSettings: {
                    showField: false,
                    required: false,
                },
                sellerSettings: {
                    showField: false,
                    negotiable: false,
                    isMandatory: false,
                },
                sendToPo: true,
                canSplit: false,
            },
            COST_CENTER: {
                standardFieldCategory: 'STANDARD',
                label: '',
                description: '',
                buyerSettings: {
                    showField: false,
                    required: false,
                },
                sellerSettings: {
                    negotiable: false,
                    showField: false,
                    isMandatory: false,
                },
                sendToPo: true,
                canSplit: false,
            },
            PROJECT: {
                standardFieldCategory: 'STANDARD',
                label: '',
                description: '',
                buyerSettings: {
                    showField: false,
                    required: false,
                },
                sellerSettings: {
                    negotiable: false,
                    showField: false,
                    isMandatory: false,
                },
                sendToPo: true,
                canSplit: false,
            },
            GL: {
                standardFieldCategory: 'STANDARD',
                label: '',
                description: '',
                buyerSettings: {
                    showField: false,
                    required: false,
                },
                sellerSettings: {
                    negotiable: false,
                    showField: false,
                    isMandatory: false,
                },
                sendToPo: true,
                canSplit: false,
            },
            TAX: {
                standardFieldCategory: 'TAX',
                taxesOptions: [],
                label: '',
                description: '',
                buyerSettings: {
                    showField: false,
                    required: false,
                },
                sellerSettings: {
                    showField: false,
                    negotiable: false,
                    isMandatory: false,
                },
                sendToPo: true,
                canSplit: false,
            },
            ADDITIONAL_COSTS: {
                standardFieldCategory: 'ADDITIONAL_COSTS',
                label: '',
                buyerSettings: {
                    required: false,
                    showField: false,
                },
                description: '',
                sellerSettings: {
                    negotiable: false,
                    showField: false,
                    isMandatory: false,
                },
                additionalCostOptions: [],
                sendToPo: true,
                canSplit: false,
            },
            DISCOUNT: {
                standardFieldCategory: 'DISCOUNT',
                label: '',
                buyerSettings: {
                    required: false,
                    showField: false,
                },
                description: '',
                sellerSettings: {
                    isMandatory: false,
                    negotiable: false,
                    showField: false,
                },
                discountOptions: [],
                sendToPo: true,
                canSplit: false,
            },
            OVERALL_TAX: {
                standardFieldCategory: 'TAX',
                taxesOptions: [],
                label: '',
                description: '',
                buyerSettings: {
                    showField: false,
                    required: false,
                },
                sellerSettings: {
                    showField: false,
                    negotiable: false,
                    isMandatory: false,
                },
                sendToPo: true,
                canSplit: false,
            },
            OVERALL_ADDITIONAL_COSTS: {
                standardFieldCategory: 'ADDITIONAL_COSTS',
                label: '',
                buyerSettings: {
                    required: false,
                    showField: false,
                },
                description: '',
                sellerSettings: {
                    negotiable: false,
                    showField: false,
                    isMandatory: false,
                },
                additionalCostOptions: [],
                sendToPo: true,
                canSplit: false,
            },
            OVERALL_DISCOUNT: {
                standardFieldCategory: 'DISCOUNT',
                label: '',
                buyerSettings: {
                    required: false,
                    showField: false,
                },
                description: '',
                sellerSettings: {
                    isMandatory: false,
                    negotiable: false,
                    showField: false,
                },
                discountOptions: [],
                sendToPo: true,
                canSplit: false,
            },
        },
        customFields: {},
    },
    sections: {
        ESSENTIAL_TERMS: {
            label: '',
            fieldList: [],
        },
        PAYMENT_AND_DELIVERY_TERMS: {
            label: '',
            fieldList: [],
        },
        ADDITIONAL_DETAILS: {
            label: '',
            fieldList: [],
        },
        EVENT_DETAILS: {
            label: '',
            fieldList: [],
        },
        VENDOR_INFORMATION: {
            label: '',
            fieldList: [],
        },
    },
    auto_reminders: {
        initial_reminder_hours:
            defaultAutoReminderData.default_intital_reminder_hours,
        is_remainder_required:
            defaultAutoReminderData.default_reminder_required,
        max_reminders: defaultAutoReminderData.default_max_reminders,
        reminder_interval: defaultAutoReminderData.default_reminder_interval,
    },
};

export const dummyTemplate: IRfqTemplate = {
    templateId: '',
    templateName: '',
    auto_reminders: {
        initial_reminder_hours:
            defaultAutoReminderData.default_intital_reminder_hours,
        is_remainder_required:
            defaultAutoReminderData.default_reminder_required,
        max_reminders: defaultAutoReminderData.default_max_reminders,
        reminder_interval: defaultAutoReminderData.default_reminder_interval,
    },
    fieldSettings: {
        standardFields: {
            EVENT_NAME: {
                standardFieldCategory: 'STANDARD',
                label: 'Event name',
                description: '',
                buyerSettings: {
                    required: true,
                    showField: true,
                },
                sellerSettings: {
                    isMandatory: false,
                    negotiable: true,
                    showField: true,
                },
                sendToPo: true,
                canSplit: false,
            },
            VENDOR: {
                standardFieldCategory: 'STANDARD',
                label: 'Vendor',
                description: '',
                buyerSettings: {
                    required: false,
                    showField: false,
                },

                sellerSettings: {
                    negotiable: false,
                    showField: false,
                    isMandatory: false,
                },
                sendToPo: false,
                canSplit: false,
            },
            BOM: {
                label: 'BOM',
                buyerSettings: {
                    required: false,
                    showField: false,
                },
                description: '',
                sellerSettings: {
                    isMandatory: false,
                    negotiable: false,
                    showField: false,
                },
                standardFieldCategory: 'STANDARD',
            },
            BOM_ID: {
                label: 'BOM',
                buyerSettings: {
                    required: false,
                    showField: false,
                },
                description: '',
                sellerSettings: {
                    isMandatory: false,
                    negotiable: false,
                    showField: false,
                },
                standardFieldCategory: 'STANDARD',
            },
            CUSTOMER_NAME: {
                standardFieldCategory: 'STANDARD',
                label: 'Customer name',
                description: '',
                buyerSettings: {
                    required: true,
                    showField: true,
                },

                sellerSettings: {
                    negotiable: true,
                    showField: true,
                    isMandatory: false,
                },
                sendToPo: true,
                canSplit: false,
            },
            BUYER_ENTITY: {
                standardFieldCategory: 'STANDARD',
                label: 'Buyer Entity',
                description: '',
                buyerSettings: {
                    required: true,
                    showField: true,
                },
                sellerSettings: {
                    isMandatory: false,
                    negotiable: true,
                    showField: true,
                },
                sendToPo: true,
                canSplit: false,
            },
            CURRENCY: {
                standardFieldCategory: 'STANDARD',
                label: 'Currency',
                description: '',
                buyerSettings: {
                    required: true,
                    showField: true,
                },
                sellerSettings: {
                    isMandatory: false,
                    negotiable: true,
                    showField: true,
                },
                sendToPo: true,
                canSplit: false,
            },
            DEFAULT_ITEM_TERMS: {
                standardFieldCategory: 'STANDARD',
                label: 'Default item terms',
                description: '',
                buyerSettings: {
                    required: true,
                    showField: true,
                },
                sellerSettings: {
                    isMandatory: false,
                    negotiable: true,
                    showField: true,
                },
                sendToPo: true,
                canSplit: false,
            },
            EVENT_DEADLINE: {
                standardFieldCategory: 'STANDARD',
                label: 'Event deadline',
                description: '',
                buyerSettings: {
                    required: true,
                    showField: true,
                },
                sellerSettings: {
                    isMandatory: false,
                    negotiable: true,
                    showField: true,
                },
                sendToPo: true,
                canSplit: false,
            },
            TEMPLATE: {
                standardFieldCategory: 'STANDARD',
                label: 'Template',
                description: '',
                buyerSettings: {
                    required: true,
                    showField: true,
                },
                sellerSettings: {
                    isMandatory: false,
                    negotiable: true,
                    showField: true,
                },
                sendToPo: true,
                canSplit: false,
            },
            EVENT_TYPE: {
                standardFieldCategory: 'STANDARD',
                label: 'Event type',
                description: '',
                buyerSettings: {
                    required: true,
                    showField: true,
                },
                sellerSettings: {
                    isMandatory: false,
                    negotiable: true,
                    showField: true,
                },
                sendToPo: true,
                canSplit: false,
            },
            QUANTITY: {
                standardFieldCategory: 'STANDARD',
                label: 'Order quantity',
                description: 'Total PO quantity',
                buyerSettings: {
                    showField: true,
                    required: true,
                },
                sellerSettings: {
                    isMandatory: false,
                    showField: true,
                    negotiable: false,
                },
                sendToPo: true,
                canSplit: false,
            },
            DELIVERY_DATE: {
                standardFieldCategory: 'STANDARD',
                label: 'Delivery quantity',
                description: 'Total delivery quantity',
                buyerSettings: {
                    showField: true,
                    required: true,
                },
                sellerSettings: {
                    isMandatory: false,
                    showField: true,
                    negotiable: false,
                },
                sendToPo: true,
                canSplit: false,
            },
            BASE_RATE: {
                standardFieldCategory: 'STANDARD',
                label: 'Base rate',
                description: 'Price per unit excluding taxes/shipping',
                buyerSettings: {
                    showField: true,
                    required: true,
                },
                sellerSettings: {
                    isMandatory: false,
                    showField: true,
                    negotiable: true,
                },
                sendToPo: true,
                canSplit: false,
            },
            SHIPPING_RATE: {
                standardFieldCategory: 'STANDARD',
                label: 'Shipping rate',
                description: 'Shipping cost per unit',
                buyerSettings: {
                    showField: true,
                    required: true,
                },
                sellerSettings: {
                    isMandatory: false,
                    showField: true,
                    negotiable: true,
                },
                sendToPo: true,
                canSplit: false,
            },
            TAX: {
                standardFieldCategory: 'TAX',
                taxesOptions: [],
                label: 'Taxes',
                description: '',
                buyerSettings: {
                    showField: false,
                    required: false,
                },
                sellerSettings: {
                    isMandatory: false,
                    showField: false,
                    negotiable: false,
                },
                sendToPo: true,
                canSplit: false,
            },
            PAYMENT_TERMS: {
                standardFieldCategory: 'PAYMENT_TERMS',
                label: 'Credit period',
                description: 'Gap between delivery and payment on goods',
                sendToPo: true,
                canSplit: false,

                buyerSettings: {
                    showField: true,
                    required: true,
                },
                sellerSettings: {
                    isMandatory: false,
                    showField: true,
                    negotiable: true,
                },
                paymentTermOptions: {
                    paymentType: 'invoiceItemValues',
                    prepaymentAllowed: true,
                    standardReferenceDateOptions: {
                        INVOICE_DATE: {
                            label: 'Invoice date',
                            enabled: true,
                        },
                        DISPATCH_DATE: {
                            label: 'Dispatch date',
                            enabled: true,
                        },
                        RECEIPT_DATE: {
                            label: 'Receipt date',
                            enabled: true,
                        },
                    },
                    customReferenceDateOptions: [],
                    allowOtherReferenceDate: true,
                },
            },
            INCOTERMS: {
                standardFieldCategory: 'STANDARD',
                label: 'Incoterms',
                description: '',
                buyerSettings: {
                    showField: false,
                    required: false,
                },
                sellerSettings: {
                    isMandatory: false,
                    showField: false,
                    negotiable: false,
                },
                sendToPo: true,
                canSplit: false,
            },
            LEAD_TIME: {
                standardFieldCategory: 'STANDARD',
                label: 'Lead time',
                description: 'Time between order and delivery',
                buyerSettings: {
                    showField: true,
                    required: true,
                },
                sellerSettings: {
                    isMandatory: false,
                    showField: true,
                    negotiable: true,
                },
                sendToPo: true,
                canSplit: false,
            },
            GR_TOLERANCE: {
                standardFieldCategory: 'STANDARD',
                label: 'GR Tolerance %',
                description:
                    'If accepted qty is within this % of invoice, it is counted as complete acceptance',
                buyerSettings: {
                    showField: true,
                    required: false,
                },
                sellerSettings: {
                    isMandatory: false,
                    showField: true,
                    negotiable: false,
                },
                sendToPo: true,
                canSplit: false,
            },
            REQUISITION: {
                standardFieldCategory: 'STANDARD',
                label: 'Internal Order Number',
                description: 'For internal purposes only',
                buyerSettings: {
                    showField: true,
                    required: false,
                },
                sellerSettings: {
                    isMandatory: false,
                    showField: false,
                    negotiable: false,
                },
                sendToPo: true,
                canSplit: false,
            },
            ADDITIONAL_INFORMATION: {
                standardFieldCategory: 'STANDARD',
                label: 'Additional information',
                description: 'Additional information',
                buyerSettings: {
                    showField: true,
                    required: false,
                },
                sellerSettings: {
                    isMandatory: false,
                    showField: false,
                    negotiable: false,
                },
                sendToPo: true,
                canSplit: false,
            },
            ITEM_DESCRIPTION: {
                standardFieldCategory: 'STANDARD',
                label: 'Item description',
                description: 'Item description',
                buyerSettings: {
                    showField: true,
                    required: false,
                },
                sellerSettings: {
                    isMandatory: false,
                    showField: false,
                    negotiable: false,
                },
                sendToPo: true,
                canSplit: false,
            },
            COST_CENTER: {
                standardFieldCategory: 'STANDARD',
                label: 'Cost center',
                description: 'Cost center',
                buyerSettings: {
                    showField: true,
                    required: false,
                },
                sellerSettings: {
                    isMandatory: false,
                    showField: false,
                    negotiable: false,
                },
                sendToPo: true,
                canSplit: false,
            },
            GL: {
                standardFieldCategory: 'STANDARD',
                label: 'GL',
                description: 'GL',
                buyerSettings: {
                    showField: true,
                    required: false,
                },
                sellerSettings: {
                    isMandatory: false,
                    showField: false,
                    negotiable: false,
                },
                sendToPo: true,
                canSplit: false,
            },
            PROJECT: {
                standardFieldCategory: 'STANDARD',
                label: 'Project',
                description: 'Project',
                buyerSettings: {
                    showField: true,
                    required: false,
                },
                sellerSettings: {
                    isMandatory: false,
                    showField: false,
                    negotiable: false,
                },
                sendToPo: true,
                canSplit: false,
            },
            ADDITIONAL_COSTS: {
                standardFieldCategory: 'ADDITIONAL_COSTS',
                label: '',
                buyerSettings: {
                    required: false,
                    showField: false,
                },
                description: '',
                sellerSettings: {
                    isMandatory: false,
                    negotiable: false,
                    showField: false,
                },
                sendToPo: true,
                canSplit: false,

                additionalCostOptions: [],
            },
            DISCOUNT: {
                standardFieldCategory: 'DISCOUNT',
                label: '',
                buyerSettings: {
                    required: false,
                    showField: false,
                },
                description: '',
                sellerSettings: {
                    isMandatory: false,
                    negotiable: false,
                    showField: false,
                },
                sendToPo: true,
                canSplit: false,

                discountOptions: [],
            },
            OVERALL_TAX: {
                standardFieldCategory: 'TAX',
                taxesOptions: [],
                label: '',
                description: '',
                buyerSettings: {
                    showField: false,
                    required: false,
                },
                sellerSettings: {
                    showField: false,
                    negotiable: false,
                    isMandatory: false,
                },
                sendToPo: true,
                canSplit: false,
            },
            OVERALL_ADDITIONAL_COSTS: {
                standardFieldCategory: 'ADDITIONAL_COSTS',
                label: '',
                buyerSettings: {
                    required: false,
                    showField: false,
                },
                description: '',
                sellerSettings: {
                    negotiable: false,
                    showField: false,
                    isMandatory: false,
                },
                additionalCostOptions: [],
                sendToPo: true,
                canSplit: false,
            },
            OVERALL_DISCOUNT: {
                standardFieldCategory: 'DISCOUNT',
                label: '',
                buyerSettings: {
                    required: false,
                    showField: false,
                },
                description: '',
                sellerSettings: {
                    isMandatory: false,
                    negotiable: false,
                    showField: false,
                },
                discountOptions: [],
                sendToPo: true,
                canSplit: false,
            },
        },
        customFields: {
            Insurance: {
                label: 'Insurance',
                type: CustomFieldType.SHORT_TEXT,
                description: 'Insurance cover amount',
                buyerSettings: {
                    showField: true,
                    required: true,
                },
                sellerSettings: {
                    isMandatory: false,
                    showField: true,
                    negotiable: true,
                },
                constraints: {
                    fieldType: CustomFieldType.SHORTTEXT,
                    maxLimit: 15,
                    minLimit: 0,
                },
                sendToPo: true,
                canSplit: false,
            },
            Insurance2: {
                label: 'Insurance2',
                type: CustomFieldType.SHORT_TEXT,
                description: 'Insurance cover amount',
                buyerSettings: {
                    showField: true,
                    required: true,
                },
                sellerSettings: {
                    isMandatory: false,
                    showField: true,
                    negotiable: true,
                },
                constraints: {
                    fieldType: CustomFieldType.SHORTTEXT,
                    maxLimit: 15,
                    minLimit: 0,
                },
                sendToPo: true,
                canSplit: false,
            },
            Insurance3: {
                label: 'Insurance3',
                type: CustomFieldType.SHORT_TEXT,
                description: 'Insurance cover amount',
                buyerSettings: {
                    showField: true,
                    required: true,
                },
                sellerSettings: {
                    isMandatory: false,
                    showField: true,
                    negotiable: true,
                },
                constraints: {
                    fieldType: CustomFieldType.SHORTTEXT,
                    maxLimit: 15,
                    minLimit: 0,
                },
                sendToPo: true,
                canSplit: false,
            },
            Warranty: {
                label: 'Warranty',
                type: CustomFieldType.SHORT_TEXT,
                description: 'Warranty period in months',
                buyerSettings: {
                    showField: true,
                    required: true,
                },
                sellerSettings: {
                    isMandatory: false,
                    showField: true,
                    negotiable: true,
                },
                constraints: {
                    fieldType: CustomFieldType.SHORTTEXT,
                    maxLimit: 15,
                    minLimit: 0,
                },
                sendToPo: true,
                canSplit: false,
            },
            Warranty1: {
                label: 'Warranty',
                type: CustomFieldType.SHORT_TEXT,
                description: 'Warranty period in months',
                buyerSettings: {
                    showField: true,
                    required: true,
                },
                sellerSettings: {
                    isMandatory: false,
                    showField: true,
                    negotiable: true,
                },
                constraints: {
                    fieldType: CustomFieldType.SHORTTEXT,
                    maxLimit: 15,
                    minLimit: 0,
                },
                sendToPo: true,
                canSplit: false,
            },
            'GL Code': {
                label: 'GL Code',
                type: CustomFieldType.SHORT_TEXT,
                description: '',
                buyerSettings: {
                    showField: true,
                    required: true,
                },
                sellerSettings: {
                    isMandatory: false,
                    showField: false,
                    negotiable: false,
                },
                constraints: {
                    fieldType: CustomFieldType.SHORTTEXT,
                    maxLimit: 15,
                    minLimit: 0,
                },
                sendToPo: true,
                canSplit: false,
            },
        },
    },
    sections: {
        ESSENTIAL_TERMS: {
            label: '',
            fieldList: [
                {
                    fieldType: 'STANDARD',
                    field: TemplateStandardFieldsEnum.BASE_RATE,
                },
                {
                    fieldType: 'STANDARD',
                    field: TemplateStandardFieldsEnum.SHIPPING_RATE,
                },
                {
                    fieldType: 'CUSTOM',
                    field: 'Insurance',
                },
                {
                    fieldType: 'STANDARD',
                    field: TemplateStandardFieldsEnum.QUANTITY,
                },
                {
                    fieldType: 'CUSTOM',
                    field: 'Insurance2',
                },
                {
                    fieldType: 'CUSTOM',
                    field: 'Insurance3',
                },
            ],
        },
        PAYMENT_AND_DELIVERY_TERMS: {
            label: 'Payment and Delivery Details',
            fieldList: [
                {
                    fieldType: 'STANDARD',
                    field: TemplateStandardFieldsEnum.GR_TOLERANCE,
                },
                {
                    fieldType: 'CUSTOM',
                    field: 'Warranty',
                },
                {
                    fieldType: 'STANDARD',
                    field: TemplateStandardFieldsEnum.PAYMENT_TERMS,
                },
                {
                    fieldType: 'STANDARD',
                    field: TemplateStandardFieldsEnum.LEAD_TIME,
                },

                {
                    fieldType: 'CUSTOM',
                    field: 'Warranty1',
                },
            ],
        },
        ADDITIONAL_DETAILS: {
            label: 'Additional Data',
            fieldList: [
                {
                    fieldType: 'CUSTOM',
                    field: 'GL Code',
                },
                {
                    fieldType: 'STANDARD',
                    field: TemplateStandardFieldsEnum.REQUISITION,
                },
            ],
        },
        VENDOR_INFORMATION: {
            label: '',
            fieldList: [
                {
                    fieldType: 'STANDARD',
                    field: TemplateStandardFieldsEnum.VENDOR,
                },
            ],
        },
        EVENT_DETAILS: {
            label: '',
            fieldList: [
                {
                    fieldType: 'STANDARD',
                    field: TemplateStandardFieldsEnum.EVENT_NAME,
                },
                {
                    fieldType: 'STANDARD',
                    field: TemplateStandardFieldsEnum.EVENT_TYPE,
                },
                {
                    fieldType: 'STANDARD',
                    field: TemplateStandardFieldsEnum.EVENT_DEADLINE,
                },
                {
                    fieldType: 'STANDARD',
                    field: TemplateStandardFieldsEnum.DISCOUNT,
                },
                {
                    fieldType: 'STANDARD',
                    field: TemplateStandardFieldsEnum.CURRENCY,
                },
                {
                    fieldType: 'STANDARD',
                    field: TemplateStandardFieldsEnum.BUYER_ENTITY,
                },
                {
                    fieldType: 'STANDARD',
                    field: TemplateStandardFieldsEnum.DEFAULT_ITEM_TERMS,
                },
            ],
        },
    },
};

export const defaultFWTemplateFE: IRfqTemplate = {
    templateId: '6a54f87d-5f51-4e68-962f-6bdf2b8234a0',
    templateName: 'Default RFQ',
    auto_reminders: {
        initial_reminder_hours:
            defaultAutoReminderData.default_intital_reminder_hours,
        is_remainder_required:
            defaultAutoReminderData.default_reminder_required,
        max_reminders: defaultAutoReminderData.default_max_reminders,
        reminder_interval: defaultAutoReminderData.default_reminder_interval,
    },
    fieldSettings: {
        standardFields: {
            QUANTITY: {
                standardFieldCategory: 'STANDARD',
                label: 'Quantity',
                description: '',
                buyerSettings: {
                    required: true,
                    showField: true,
                },
                sellerSettings: {
                    isMandatory: true,
                    negotiable: true,
                    showField: true,
                },
                sendToPo: true,
                canSplit: false,
            },
            VENDOR: {
                standardFieldCategory: 'STANDARD',
                label: 'Vendor',
                description: '',
                buyerSettings: {
                    required: false,
                    showField: false,
                },

                sellerSettings: {
                    negotiable: false,
                    showField: false,
                    isMandatory: false,
                },
                sendToPo: false,
                canSplit: false,
            },
            BOM: {
                label: 'BOM',
                buyerSettings: {
                    required: false,
                    showField: false,
                },
                description: '',
                sellerSettings: {
                    isMandatory: false,
                    negotiable: false,
                    showField: false,
                },
                standardFieldCategory: 'STANDARD',
            },
            BOM_ID: {
                label: 'BOM',
                buyerSettings: {
                    required: false,
                    showField: false,
                },
                description: '',
                sellerSettings: {
                    isMandatory: false,
                    negotiable: false,
                    showField: false,
                },
                standardFieldCategory: 'STANDARD',
            },
            EVENT_NAME: {
                standardFieldCategory: 'STANDARD',
                label: 'Event name',
                description: '',
                buyerSettings: {
                    required: true,
                    showField: true,
                },
                sellerSettings: {
                    isMandatory: true,

                    negotiable: true,
                    showField: true,
                },
                sendToPo: true,
                canSplit: false,
            },
            CUSTOMER_NAME: {
                standardFieldCategory: 'STANDARD',
                label: 'Customer name',
                description: '',
                buyerSettings: {
                    required: true,
                    showField: true,
                },

                sellerSettings: {
                    negotiable: true,
                    showField: true,
                    isMandatory: false,
                },
                sendToPo: true,
                canSplit: false,
            },
            BUYER_ENTITY: {
                standardFieldCategory: 'STANDARD',
                label: 'Buyer Entity',
                description: '',
                buyerSettings: {
                    required: true,
                    showField: true,
                },
                sellerSettings: {
                    isMandatory: true,

                    negotiable: true,
                    showField: true,
                },
                sendToPo: true,
                canSplit: false,
            },
            CURRENCY: {
                standardFieldCategory: 'STANDARD',
                label: 'Currency',
                description: '',
                buyerSettings: {
                    required: true,
                    showField: true,
                },
                sellerSettings: {
                    isMandatory: true,

                    negotiable: true,
                    showField: true,
                },
                sendToPo: true,
                canSplit: false,
            },
            DEFAULT_ITEM_TERMS: {
                standardFieldCategory: 'STANDARD',
                label: 'Default item terms',
                description: '',
                buyerSettings: {
                    required: true,
                    showField: true,
                },
                sellerSettings: {
                    negotiable: true,
                    isMandatory: true,
                    showField: true,
                },
                sendToPo: true,
                canSplit: false,
            },
            EVENT_DEADLINE: {
                standardFieldCategory: 'STANDARD',
                label: 'Event deadline',
                description: '',
                buyerSettings: {
                    required: true,
                    showField: true,
                },
                sellerSettings: {
                    negotiable: true,
                    showField: true,
                    isMandatory: true,
                },
                sendToPo: true,
                canSplit: false,
            },
            TEMPLATE: {
                standardFieldCategory: 'STANDARD',
                label: 'Template',
                description: '',
                buyerSettings: {
                    required: true,
                    showField: true,
                },
                sellerSettings: {
                    negotiable: true,
                    showField: true,
                    isMandatory: true,
                },
                sendToPo: true,
                canSplit: false,
            },
            EVENT_TYPE: {
                standardFieldCategory: 'STANDARD',
                label: 'Event type',
                description: '',
                buyerSettings: {
                    required: true,
                    showField: true,
                },
                sellerSettings: {
                    negotiable: true,
                    showField: true,
                    isMandatory: true,
                },
                sendToPo: true,
                canSplit: false,
            },
            DELIVERY_DATE: {
                label: 'Delivery date',
                standardFieldCategory: 'STANDARD',
                description: '',
                buyerSettings: {
                    required: true,
                    showField: true,
                },
                sellerSettings: {
                    negotiable: true,
                    showField: true,
                    isMandatory: true,
                },
                sendToPo: true,
                canSplit: false,
            },
            BASE_RATE: {
                standardFieldCategory: 'STANDARD',
                label: 'Price',
                description: '',
                buyerSettings: {
                    required: true,
                    showField: true,
                },
                sellerSettings: {
                    negotiable: true,
                    showField: true,
                    isMandatory: true,
                },
                sendToPo: true,
                canSplit: false,
            },
            SHIPPING_RATE: {
                standardFieldCategory: 'STANDARD',
                label: 'Shipping rate',
                description: '',
                buyerSettings: {
                    required: false,
                    showField: true,
                },
                sellerSettings: {
                    negotiable: true,
                    showField: true,
                    isMandatory: true,
                },
                sendToPo: true,
                canSplit: false,
            },
            TAX: {
                standardFieldCategory: 'TAX',
                taxesOptions: [],
                label: 'Taxes',
                description: '',
                buyerSettings: {
                    required: false,
                    showField: true,
                },
                sellerSettings: {
                    negotiable: true,
                    showField: true,
                    isMandatory: true,
                },
                sendToPo: true,
                canSplit: false,
            },
            PAYMENT_TERMS: {
                standardFieldCategory: 'PAYMENT_TERMS',
                label: 'Payment terms',
                description: '',
                sendToPo: true,
                canSplit: false,

                buyerSettings: {
                    required: true,
                    showField: true,
                },
                sellerSettings: {
                    negotiable: true,
                    showField: true,
                    isMandatory: true,
                },
                paymentTermOptions: {
                    paymentType: 'invoiceItemValues',
                    prepaymentAllowed: true,
                    standardReferenceDateOptions: {
                        INVOICE_DATE: {
                            label: 'Invoice date',
                            enabled: true,
                        },
                        DISPATCH_DATE: {
                            label: 'Dispatch date',
                            enabled: true,
                        },
                        RECEIPT_DATE: {
                            label: 'Receipt date',
                            enabled: true,
                        },
                    },
                    customReferenceDateOptions: [],
                    allowOtherReferenceDate: true,
                },
            },

            INCOTERMS: {
                standardFieldCategory: 'STANDARD',
                label: 'Incoterms',
                description: '',
                buyerSettings: {
                    required: true,
                    showField: true,
                },
                sellerSettings: {
                    negotiable: true,
                    showField: true,
                    isMandatory: true,
                },
                sendToPo: true,
                canSplit: false,
            },
            LEAD_TIME: {
                standardFieldCategory: 'STANDARD',
                label: 'Lead time',
                description: '',
                buyerSettings: {
                    required: true,
                    showField: true,
                },
                sellerSettings: {
                    negotiable: true,
                    isMandatory: true,
                    showField: true,
                },
                sendToPo: true,
                canSplit: false,
            },
            GR_TOLERANCE: {
                standardFieldCategory: 'STANDARD',
                label: 'GR tolerance',
                description: '',
                buyerSettings: {
                    required: false,
                    showField: true,
                },
                sellerSettings: {
                    negotiable: false,
                    showField: false,
                    isMandatory: true,
                },
                sendToPo: true,
                canSplit: false,
            },
            REQUISITION: {
                standardFieldCategory: 'STANDARD',
                label: 'Requisition',
                description: '',
                buyerSettings: {
                    required: false,
                    showField: true,
                },
                sellerSettings: {
                    negotiable: false,
                    showField: false,
                    isMandatory: true,
                },
                sendToPo: true,
                canSplit: false,
            },
            ADDITIONAL_INFORMATION: {
                standardFieldCategory: 'STANDARD',
                label: 'Additional information',
                description: '',
                buyerSettings: {
                    required: false,
                    showField: true,
                },
                sellerSettings: {
                    negotiable: false,
                    showField: true,
                    isMandatory: true,
                },
                sendToPo: true,
                canSplit: false,
            },
            ITEM_DESCRIPTION: {
                standardFieldCategory: 'STANDARD',
                label: 'Item description',
                description: '',
                buyerSettings: {
                    required: false,
                    showField: true,
                },
                sellerSettings: {
                    negotiable: false,
                    showField: true,
                    isMandatory: true,
                },
                sendToPo: true,
                canSplit: false,
            },
            COST_CENTER: {
                standardFieldCategory: 'STANDARD',
                label: 'Cost center',
                description: '',
                buyerSettings: {
                    required: false,
                    showField: true,
                },
                sellerSettings: {
                    negotiable: false,
                    showField: true,
                    isMandatory: true,
                },
                sendToPo: true,
                canSplit: false,
            },
            GL: {
                standardFieldCategory: 'STANDARD',
                label: 'General Ledger',
                description: '',
                buyerSettings: {
                    required: false,
                    showField: true,
                },
                sellerSettings: {
                    negotiable: false,
                    showField: true,
                    isMandatory: true,
                },
                sendToPo: true,
                canSplit: false,
            },
            PROJECT: {
                standardFieldCategory: 'STANDARD',
                label: 'Project',
                description: '',
                buyerSettings: {
                    required: false,
                    showField: true,
                },
                sellerSettings: {
                    negotiable: false,
                    showField: true,
                    isMandatory: true,
                },
                sendToPo: true,
                canSplit: false,
            },
            ADDITIONAL_COSTS: {
                standardFieldCategory: 'ADDITIONAL_COSTS',
                label: '',
                buyerSettings: {
                    required: false,
                    showField: false,
                },
                description: '',
                sellerSettings: {
                    negotiable: false,
                    showField: false,
                    isMandatory: true,
                },
                additionalCostOptions: [],
                sendToPo: true,
                canSplit: false,
            },
            DISCOUNT: {
                standardFieldCategory: 'DISCOUNT',
                label: '',
                buyerSettings: {
                    required: false,
                    showField: false,
                },
                description: '',
                sellerSettings: {
                    negotiable: false,
                    showField: false,
                    isMandatory: true,
                },
                discountOptions: [],
                sendToPo: true,
                canSplit: false,
            },
            OVERALL_TAX: {
                standardFieldCategory: 'TAX',
                taxesOptions: [],
                label: '',
                description: '',
                buyerSettings: {
                    showField: false,
                    required: false,
                },
                sellerSettings: {
                    showField: false,
                    negotiable: false,
                    isMandatory: false,
                },
                sendToPo: true,
                canSplit: false,
            },
            OVERALL_ADDITIONAL_COSTS: {
                standardFieldCategory: 'ADDITIONAL_COSTS',
                label: '',
                buyerSettings: {
                    required: false,
                    showField: false,
                },
                description: '',
                sellerSettings: {
                    negotiable: false,
                    showField: false,
                    isMandatory: false,
                },
                additionalCostOptions: [],
                sendToPo: true,
                canSplit: false,
            },
            OVERALL_DISCOUNT: {
                standardFieldCategory: 'DISCOUNT',
                label: '',
                buyerSettings: {
                    required: false,
                    showField: false,
                },
                description: '',
                sellerSettings: {
                    isMandatory: false,
                    negotiable: false,
                    showField: false,
                },
                discountOptions: [],
                sendToPo: true,
                canSplit: false,
            },
        },
        customFields: {},
    },
    sections: {
        ESSENTIAL_TERMS: {
            fieldList: [
                {
                    fieldType: 'STANDARD',
                    field: TemplateStandardFieldsEnum.QUANTITY,
                },
                {
                    fieldType: 'STANDARD',
                    field: TemplateStandardFieldsEnum.BASE_RATE,
                },
                {
                    fieldType: 'STANDARD',
                    field: TemplateStandardFieldsEnum.SHIPPING_RATE,
                },
                {
                    fieldType: 'STANDARD',
                    field: TemplateStandardFieldsEnum.TAX,
                },
            ],
            label: 'Item Terms',
        },
        VENDOR_INFORMATION: {
            label: '',
            fieldList: [
                {
                    fieldType: 'STANDARD',
                    field: TemplateStandardFieldsEnum.VENDOR,
                },
            ],
        },
        PAYMENT_AND_DELIVERY_TERMS: {
            label: 'Payment and Delivery Terms',
            fieldList: [
                {
                    fieldType: 'STANDARD',
                    field: TemplateStandardFieldsEnum.PAYMENT_TERMS,
                },
                {
                    fieldType: 'STANDARD',
                    field: TemplateStandardFieldsEnum.INCOTERMS,
                },
                {
                    fieldType: 'STANDARD',
                    field: TemplateStandardFieldsEnum.LEAD_TIME,
                },
                {
                    fieldType: 'STANDARD',
                    field: TemplateStandardFieldsEnum.GR_TOLERANCE,
                },
            ],
        },
        ADDITIONAL_DETAILS: {
            label: 'Additional Details',
            fieldList: [
                {
                    fieldType: 'STANDARD',
                    field: TemplateStandardFieldsEnum.ITEM_DESCRIPTION,
                },
                {
                    fieldType: 'STANDARD',
                    field: TemplateStandardFieldsEnum.REQUISITION,
                },
                {
                    fieldType: 'STANDARD',
                    field: TemplateStandardFieldsEnum.ADDITIONAL_INFORMATION,
                },
            ],
        },
        EVENT_DETAILS: {
            label: '',
            fieldList: [
                {
                    fieldType: 'STANDARD',
                    field: TemplateStandardFieldsEnum.EVENT_NAME,
                },
                {
                    fieldType: 'STANDARD',
                    field: TemplateStandardFieldsEnum.EVENT_TYPE,
                },
                {
                    fieldType: 'STANDARD',
                    field: TemplateStandardFieldsEnum.EVENT_DEADLINE,
                },
                {
                    fieldType: 'STANDARD',
                    field: TemplateStandardFieldsEnum.DISCOUNT,
                },
                {
                    fieldType: 'STANDARD',
                    field: TemplateStandardFieldsEnum.CURRENCY,
                },
                {
                    fieldType: 'STANDARD',
                    field: TemplateStandardFieldsEnum.BUYER_ENTITY,
                },
                {
                    fieldType: 'STANDARD',
                    field: TemplateStandardFieldsEnum.DEFAULT_ITEM_TERMS,
                },
            ],
        },
    },
};

export const translateBackendCustomFieldSectionsViaTemplateToFrontEnd = (
    backendCustomFields: IEventItemCustomFieldsBackend,
    backendNegotiableCustomFields: IEventItemCustomFieldsBackend
): IEventItemCustomFieldSections => {
    let processedData: IEventItemCustomFieldSections = {
        existingSections: {
            essential_terms: [],
            payment_and_delivery_terms: [],
            additional_details: [],
        },
        newSections: {},
    };

    backendCustomFields = cloneDeep(backendCustomFields);
    backendNegotiableCustomFields = cloneDeep(backendNegotiableCustomFields);

    if (backendCustomFields?.section_list) {
        for (let section of backendCustomFields.section_list) {
            if (section.name === 'item_terms') {
                section.name =
                    custom_field_backend_section_names.essential_terms_template_name;
            }
            if (
                section.name ===
                    custom_field_backend_section_names.payment_and_delivery_terms_template_name ||
                section.name ===
                    custom_field_backend_section_names.additional_information_template_name ||
                section.name ===
                    custom_field_backend_section_names.essential_terms_template_name
            ) {
                processedData.existingSections[
                    section.name as
                        | 'payment_and_delivery_terms'
                        | 'additional_details'
                        | 'essential_terms'
                ] = section.fields.map((field) => ({
                    ...field,
                    is_negotiable: false,
                }));
            } else {
                processedData.newSections[section.name as string] =
                    section.fields.map((field) => ({
                        ...field,
                        is_negotiable: false,
                    }));
            }
        }
    }
    if (backendNegotiableCustomFields?.section_list) {
        for (let section of backendNegotiableCustomFields.section_list) {
            if (section.name === 'item_terms') {
                section.name =
                    custom_field_backend_section_names.essential_terms_template_name;
            }
            if (
                section.name ===
                    custom_field_backend_section_names.payment_and_delivery_terms_template_name ||
                section.name ===
                    custom_field_backend_section_names.additional_information_template_name ||
                section.name ===
                    custom_field_backend_section_names.essential_terms_template_name
            ) {
                if (
                    !processedData.existingSections[
                        section.name as
                            | 'payment_and_delivery_terms'
                            | 'additional_details'
                            | 'essential_terms'
                    ]
                ) {
                    processedData.existingSections[
                        section.name as
                            | 'payment_and_delivery_terms'
                            | 'additional_details'
                            | 'essential_terms'
                    ] = [];
                }
                processedData.existingSections[
                    section.name as
                        | 'payment_and_delivery_terms'
                        | 'additional_details'
                        | 'essential_terms'
                ] = processedData.existingSections[
                    section.name as
                        | 'payment_and_delivery_terms'
                        | 'additional_details'
                        | 'essential_terms'
                ].concat([
                    ...section.fields.map((field) => ({
                        ...field,
                        is_negotiable: true,
                    })),
                ]);
            } else {
                if (!processedData.newSections[section.name]) {
                    processedData.newSections[section.name] = [];
                }
                processedData.newSections[section.name] =
                    processedData.newSections[section.name].concat(
                        section.fields.map((field) => ({
                            ...field,
                            is_negotiable: true,
                        }))
                    );
            }
        }
    }

    return processedData;
};

export const backendSectionNamesMappedToFrontend: {
    [section in standardSectionsBackendName]: string;
} = {
    'Payment and Delivery Terms': 'PAYMENT_AND_DELIVERY_TERMS',
    'Additional Details': 'ADDITIONAL_DETAILS',
    'Item Terms': 'ESSENTIAL_TERMS',
};

export const backendTemplateSectionNameMappedToFrontend: {
    [key: string]: string;
} = {};

export const getListOfFieldsFromTemplateWhichIsNeotiable = (
    reftemplate: IRfqTemplate,
    sectionName: RfqStandardSections
) => {
    return reftemplate.sections[sectionName].fieldList.filter((item) => {
        if (item.fieldType === 'STANDARD') {
            return (
                reftemplate.fieldSettings.standardFields[item.field]
                    .sellerSettings.showField &&
                reftemplate.fieldSettings.standardFields[item.field]
                    .sellerSettings.negotiable
            );
        } else {
            return (
                reftemplate.fieldSettings.customFields[item.field]
                    .sellerSettings.showField &&
                reftemplate.fieldSettings.customFields[item.field]
                    .sellerSettings.negotiable
            );
        }
    });
};
export const dummyPaymentTermsOptionsInvoiceItemValues: ITemplatePaymentTermsOptionsInvoiceItemValues =
    {
        paymentType: 'invoiceItemValues',
        allowOtherReferenceDate: true,
        prepaymentAllowed: true,
        standardReferenceDateOptions: {
            DISPATCH_DATE: {
                label: 'Dispatch Date',
                enabled: true,
            },
            INVOICE_DATE: {
                label: 'Invoice Date',
                enabled: true,
            },
            RECEIPT_DATE: {
                label: 'Receipt Date',
                enabled: true,
            },
        },
        customReferenceDateOptions: ['BL Date'],
    };

export const dummyPaymentTermsOptionsPoItemPercentages: ITemplatePaymentTermsOptionsPoItemPercentages =
    {
        paymentType: 'poItemPercentages',
        prepaymentAllowed: true,
        allowOtherDeliverableNames: true,
        deliverableNameOptions: ['Installation', 'Completion'],
    };

export const convertTemplateTypeToCustomTemplateItemTypes: (
    templateType: CustomFieldType
) => CustomTemplateItemTypes = (templateType) => {
    switch (templateType) {
        case CustomFieldType.BOOLEAN:
            return CustomTemplateItemTypes.CHOICE;

        case CustomFieldType.CURRENCY:
            return CustomTemplateItemTypes.CURRENCY;

        case CustomFieldType.CHOICE:
            return CustomTemplateItemTypes.CHOICE;

        case CustomFieldType.DATE:
            return CustomTemplateItemTypes.DATE;

        case CustomFieldType.DATETIME:
            return CustomTemplateItemTypes.DATE;

        case CustomFieldType.DECIMAL:
            return CustomTemplateItemTypes.FLOAT;

        case CustomFieldType.FLOAT:
            return CustomTemplateItemTypes.FLOAT;

        case CustomFieldType.INTEGER:
            return CustomTemplateItemTypes.INTEGER;

        case CustomFieldType.LONG_TEXT:
            return CustomTemplateItemTypes.LONGTEXT;

        case CustomFieldType.SHORT_TEXT:
            return CustomTemplateItemTypes.SHORTTEXT;

        default:
            return CustomTemplateItemTypes.SHORTTEXT;
    }
};

export const blankProjectTemplate: IProjectTemplate = {
    templateId: '',
    templateName: '',
    assigned_users: {
        PROJECT_DETAILS: [],
        TEMPLATE_LEVEL: [],
    },
    sections: {
        PROJECT_DETAILS: {
            label: 'Project details',
            fieldList: [],
        },
    },
    fieldSettings: {
        PROJECT_DETAILS: {
            standardFields: {
                CUSTOMER_NAME: {
                    label: 'Customer name',
                    description: '',
                    constraints: {
                        fieldType: CustomFieldType.CHOICE,
                        choices: [],
                        choiceType: 'DROPDOWN',
                    },
                    buyerSettings: {
                        required: false,
                        showField: false,
                    },
                    standardFieldCategory: 'STANDARD',
                },
                ASSIGNED_TO: {
                    label: 'Assigned to',
                    description: '',
                    constraints: {
                        fieldType: CustomFieldType.CHOICE,
                        choices: [],
                        choiceType: 'DROPDOWN',
                    },
                    buyerSettings: {
                        required: false,
                        showField: false,
                    },
                    standardFieldCategory: 'STANDARD',
                },
                ATTACHMENTS: {
                    label: 'Attachments',
                    description: '',
                    buyerSettings: {
                        required: false,
                        showField: false,
                    },
                    standardFieldCategory: 'STANDARD',
                    constraints: {
                        fieldType: CustomFieldType.ATTACHMENT,
                    },
                },
                DESCRIPTION: {
                    label: 'Description',
                    description: '',
                    buyerSettings: {
                        required: false,
                        showField: false,
                    },
                    standardFieldCategory: 'STANDARD',
                    constraints: {
                        fieldType: CustomFieldType.LONGTEXT,
                        maxLimit: 120,
                        minLimit: 0,
                    },
                },
                DEADLINE_DATE: {
                    label: 'Deadline date',
                    description: '',
                    buyerSettings: {
                        required: false,
                        showField: false,
                    },
                    standardFieldCategory: 'STANDARD',
                    constraints: {
                        fieldType: CustomFieldType.DATE,
                    },
                },
                END_DATE: {
                    label: 'End date',
                    description: '',
                    buyerSettings: {
                        required: false,
                        showField: false,
                    },
                    standardFieldCategory: 'STANDARD',
                    constraints: {
                        fieldType: CustomFieldType.DATE,
                    },
                },
                PROJECT_CODE: {
                    label: 'Project code',
                    description: '',
                    buyerSettings: {
                        required: true,
                        showField: true,
                    },
                    standardFieldCategory: 'STANDARD',
                    constraints: {
                        fieldType: CustomFieldType.SHORTTEXT,
                        maxLimit: 15,
                        minLimit: 0,
                    },
                },
                PROJECT_NAME: {
                    label: 'Project name',
                    description: '',
                    buyerSettings: {
                        required: true,
                        showField: true,
                    },
                    standardFieldCategory: 'STANDARD',
                    constraints: {
                        fieldType: CustomFieldType.SHORTTEXT,
                        maxLimit: 15,
                        minLimit: 0,
                    },
                },
                INTERNAL_NOTES: {
                    label: 'Project nameInternal notes',
                    description: '',
                    buyerSettings: {
                        required: false,
                        showField: false,
                    },
                    standardFieldCategory: 'STANDARD',
                    constraints: {
                        fieldType: CustomFieldType.LONGTEXT,
                        maxLimit: 120,
                        minLimit: 0,
                    },
                },
                PROJECT_MANAGER: {
                    label: 'Project manager',
                    description: '',
                    buyerSettings: {
                        required: false,
                        showField: false,
                    },
                    standardFieldCategory: 'STANDARD',
                    constraints: {
                        fieldType: CustomFieldType.SHORTTEXT,
                        maxLimit: 15,
                        minLimit: 0,
                    },
                },
                START_DATE: {
                    label: 'Start date',
                    description: '',
                    buyerSettings: {
                        required: false,
                        showField: false,
                    },
                    standardFieldCategory: 'STANDARD',
                    constraints: {
                        fieldType: CustomFieldType.DATE,
                    },
                },
                STATUS: {
                    label: 'Status',
                    description: '',
                    buyerSettings: {
                        required: false,
                        showField: false,
                    },
                    standardFieldCategory: 'STANDARD',
                    constraints: {
                        fieldType: CustomFieldType.CHOICE,
                        choices: [],
                        choiceType: 'DROPDOWN',
                    },
                },
            },
            customFields: {},
        },
    },
};
