import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { HookStateValue, useHookState } from '../../Common/Hooks/StateHook';
import { AuthContext } from '../../Contexts/AuthContext';
import { IUserDetails, InvitationType } from '../../Models/UserDetails.model';
import { IEntity } from '../../Organizations/Admin/Interfaces/EnterpriseInterface';
import { fetchEntities } from '../../Organizations/Admin/Services/EnterpriseService';
import {
    IInvitee,
    blankInviteeWithAllEntities,
    inviteeRole,
} from '../Interfaces/NavbarUsersInterfaces';
import {
    getAllEnterpriseUsers,
    sendInvitations,
} from '../Services/QuickUserActionsService';

export default function useQuickUserInvites() {
    const { hookState, updateHookState } = useHookState(HookStateValue.LOADING);

    const [allEnterpriseUsers, setAllEnterpriseUsers] = useState<
        IUserDetails[]
    >([]);

    const [allEntities, setAllEntities] = useState<IEntity[]>([]);
    const { authData } = useContext(AuthContext);

    const [inviteList, setInviteList] = useState<IInvitee[]>([]);

    const [sendingInvites, setSendingInvites] = useState(false);

    const isSeller =
        authData.details &&
        authData.details.invitation_type &&
        (authData.details.invitation_type ===
            InvitationType.JOIN_ORGANIZATION_VENDOR_INVITE ||
            authData.details.invitation_type ===
                InvitationType.VENDOR_CONTACT_INVITE ||
            authData.details.invitation_type ===
                InvitationType.VENDOR_ENTERPRISE_INVITE);

    type emailError =
        | 'BLANK'
        | 'INVALID'
        | 'DUPLICATE'
        | 'ALREADY_IN_ORG'
        | 'NONE';

    const emailErrors = useMemo(() => {
        const errors: emailError[] = [];
        const emailList: string[] = [];
        inviteList.forEach((invitee) => {
            /* if (invitee.email.trim() === '') {
                errors.push('BLANK');
            } else */ if (invitee.email.trim() !== '') {
                if (!invitee.email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
                    errors.push('INVALID');
                } else if (
                    emailList
                        .map((email) => email.toLowerCase())
                        .includes(invitee.email.toLowerCase())
                ) {
                    errors.push('DUPLICATE');
                } else if (
                    allEnterpriseUsers.some(
                        (user) =>
                            user.email?.toLowerCase() ===
                            invitee.email?.toLowerCase()
                    )
                ) {
                    errors.push('ALREADY_IN_ORG');
                } else {
                    emailList.push(invitee.email);
                    errors.push('NONE');
                }
            }
        });
        return errors;
    }, [allEnterpriseUsers, inviteList]);

    const fetchData = useCallback(async () => {
        try {
            updateHookState(HookStateValue.LOADING);
            const [entitiesResp, allEnterpriseUsersResp] = await Promise.all([
                fetchEntities(),
                getAllEnterpriseUsers(),
            ]);
            setInviteList([
                blankInviteeWithAllEntities(
                    entitiesResp.map((entity) => entity.entityId),
                    '',
                    isSeller ?? true
                ),
            ]);

            setAllEnterpriseUsers(allEnterpriseUsersResp);
            setAllEntities(entitiesResp);
            updateHookState(HookStateValue.READY);
        } catch (error) {
            updateHookState(HookStateValue.ERROR);
        }
    }, [updateHookState, isSeller]);

    const resetForm = useCallback(
        (isOnWelcome: boolean) => {
            if (hookState.state === HookStateValue.READY) {
                if (isOnWelcome) {
                    setInviteList(
                        [1, 2, 3].map(() => {
                            return blankInviteeWithAllEntities(
                                allEntities.map((entity) => entity.entityId),
                                '',
                                isSeller ?? false
                            );
                        })
                    );
                } else {
                    setInviteList([
                        blankInviteeWithAllEntities(
                            allEntities.map((entity) => entity.entityId),
                            '',
                            isSeller ?? true
                        ),
                    ]);
                }
            }
        },
        [allEntities, hookState.state, isSeller]
    );

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const toggleAdmin = (index: number) => {
        const newList = [...inviteList];
        newList[index].isAdmin = true;
        newList[index].role = 'ADMIN';
        setInviteList(newList);
    };

    const changeRole = (index: number, role: inviteeRole) => {
        const newList = [...inviteList];
        newList[index].isAdmin = false;
        newList[index].role = role;
        setInviteList(newList);
    };

    const editEmail = (index: number, email: string) => {
        const newList = [...inviteList];
        newList[index].email = email;
        setInviteList(newList);
    };

    const toggleSkipOnboarding = (index: number) => {
        const newList = [...inviteList];
        newList[index].skip_onboarding = !newList[index].skip_onboarding;
        setInviteList(newList);
    };

    const updateEntities = (index: number, entities: string[]) => {
        const newList = [...inviteList];
        newList[index].entities = entities;
        setInviteList(newList);
    };

    const addRow = useCallback(() => {
        setInviteList((prev) => {
            return [
                ...prev,
                blankInviteeWithAllEntities(
                    allEntities.map((entity) => entity.entityId),
                    '',
                    isSeller ?? true
                ),
            ];
        });
    }, [allEntities, isSeller]);

    const removeRow = (index: number) => {
        const newList = [...inviteList];
        newList.splice(index, 1);
        setInviteList(newList);
    };

    const editManagers = (index: number, managers: string[]) => {
        const newList = [...inviteList];
        newList[index].managers = managers;
        setInviteList(newList);
    };

    const sendInvites = async (): Promise<void> => {
        return new Promise<void>(async (resolve, reject) => {
            try {
                setSendingInvites(true);
                inviteList.filter((invitee) => invitee.email.trim() !== '');
                inviteList.filter(
                    (invitee) =>
                        (invitee.name = invitee.email.replace(/@.*/, ''))
                );
                const lastInviteListData = inviteList.map((invitee) => {
                    const newInvitee = { ...invitee };
                    if (newInvitee.role === 'ADMIN') {
                        newInvitee.role = null;
                    }
                    newInvitee.managers = newInvitee.managers.filter(
                        (manager) => manager !== ''
                    );
                    return newInvitee;
                });
                await sendInvitations(lastInviteListData);
                setSendingInvites(false);
                toast.success('Successfully sent invitations');
                resolve();
            } catch (error) {
                toast.error('Failed to send invites');
                setSendingInvites(false);
                reject();
            }
        });
    };

    return {
        hookState,
        allEntities,
        allEnterpriseUsers,
        inviteList,
        editEmail,
        editManagers,
        toggleAdmin,
        changeRole,
        updateEntities,
        addRow,
        removeRow,
        resetForm,
        emailErrors,
        sendInvites,
        sendingInvites,
        toggleSkipOnboarding,
    };
}
