import { Avatar, Box, IconButton } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { FWTypography } from '../../Common/FWTypograhy';
import { IEventItemDetails } from '../../Events/Interfaces/Buyer/RFQ.model';
import { IAddedVendorList } from '../../Events/Services/Buyer/RFQ.service';
import ChatMessageBoxHeader from '../Components/ChatMessageBoxHeader';
import { ISession, IUserSession } from '../Interfaces/Chat.model';
import { fetchSessionDetails } from '../Services/chat.service';
import {
    BroadcastMessageButtonContainer,
    ChatMessageButtonContainer,
} from './Chat&BroadcastMessageButtonContainer';
import BroadcastMessageListContainer from './BroadcastMessageListContainer';
import ChatMessageListContainer from './ChatMessageListContainer';
import ChatSectionsExpandButton from '../Components/ChatSectionsExpandButton';
import { AuthContext } from '../../Contexts/AuthContext';

interface IChatMessageBoxContainer {
    selectedSessionID: string;
    selectedSessionMuteState: boolean;
    module_id: string;
    user_type: 'buyer' | 'seller';
    updateSessionMuteState(newState: boolean): void;
    isBroadCastSessionSelected: boolean;
    eventItemAndVendorDetails: {
        items: IEventItemDetails[];
        vendors: { [key: string]: IAddedVendorList[] };
    } | null;
    selectedSessionAvatarColor: string;
    openedFromPopup: boolean;
    floatingChat?: boolean;
    setSelectedSession?: React.Dispatch<
        React.SetStateAction<IUserSession | null>
    >;
    hideSessionListContainer?: boolean;
    setHideSessionListContainer?: React.Dispatch<React.SetStateAction<boolean>>;
    lastReadMsgId: string;
    setLastReadMsgId: React.Dispatch<React.SetStateAction<string>>;
    currentlySelectedEventEditStatus: 'EVENT_VIEW' | 'EVENT_EDIT';
    selectedSessionType: 'CHAT' | 'INTERNAL' | 'BROADCAST' | 'SELF' | null;
    subEventId: string;
}

export default function ChatMessageBoxContainer(
    props: IChatMessageBoxContainer
) {
    const [selectedSessionData, setSelectedSessionData] =
        useState<ISession | null>(null);

    useEffect(() => {
        if (props.selectedSessionID.trim().length > 0) {
            const controller = new AbortController();
            fetchSessionDetails(props.selectedSessionID, controller.signal)
                .then((res) => {
                    setSelectedSessionData(res);
                })
                .catch((err) => {
                    if (err.message !== 'canceled')
                        toast.error("Couldn't fetch group details");
                });
            return () => {
                controller.abort();
            };
        }
    }, [props.selectedSessionID]);

    const { checkPermission } = useContext(AuthContext);
    const [noteId, setNoteId] = useState<{
        id: string;
        status: 'ACTIVE' | 'INACTIVE';
    } | null>({ id: 'SELF', status: 'ACTIVE' });

    const noteClickHandler = (
        noteId: {
            id: string;
            status: 'ACTIVE' | 'INACTIVE';
        } | null
    ) => {
        setNoteId(noteId);
    };

    useEffect(() => {
        if (
            selectedSessionData &&
            selectedSessionData.session_type === 'INTERNAL'
        ) {
            setNoteId({ id: 'SELF', status: 'ACTIVE' });
        } else {
            setNoteId(null);
        }
    }, [selectedSessionData]);

    const [isSelfNoteContainerOpen, setIsSelfNoteContainerOpen] =
        useState<boolean>(false);

    /*     if (!props.isBroadCastSessionSelected) {
        return (
            <Box flex={1} className="flex flex--aic flex--jcc">
                <CircularProgress color="primary" />
            </Box>
        );
    } else */ if (
        props.isBroadCastSessionSelected &&
        props.eventItemAndVendorDetails !== null
    ) {
        return (
            <Box
                bgcolor="background.paper"
                className="flex flex--col"
                style={{
                    height: props.floatingChat ? '100%' : 'unset',
                    zIndex: 1,
                }}
            >
                <Box
                    bgcolor="background.default"
                    padding={'8px'}
                    className="flex flex--aic"
                >
                    <Box className="flex flex--aic">
                        {props.openedFromPopup && (
                            <ChatSectionsExpandButton
                                onClick={() => {
                                    props.setHideSessionListContainer &&
                                        props.setHideSessionListContainer(
                                            (prev) => !prev
                                        );
                                }}
                                hidden={props.hideSessionListContainer}
                            />
                        )}
                        {props.floatingChat && props.setSelectedSession && (
                            <IconButton
                                sx={{ marginRight: 1 }}
                                size="small"
                                onClick={() => {
                                    if (props.setSelectedSession) {
                                        props.setSelectedSession(null);
                                    }
                                }}
                            >
                                <i className="bi bi-chevron-left"></i>
                            </IconButton>
                        )}
                        <Avatar
                            sx={{
                                // height: '50px',
                                // width: '50px',
                                bgcolor: '#FF9500',
                            }}
                        >
                            <Box color="white">
                                <i className="bi bi-megaphone"></i>
                            </Box>
                        </Avatar>
                        &nbsp;
                        <Box>
                            <FWTypography fontWeight={600} color={'#4e4e4e'}>
                                Broadcast
                            </FWTypography>
                        </Box>
                    </Box>
                </Box>
                <BroadcastMessageListContainer
                    module_id={props.module_id}
                    session_id={props.selectedSessionID}
                />
                <BroadcastMessageButtonContainer
                    module_id={props.module_id}
                    eventItemAndVendorDetails={props.eventItemAndVendorDetails}
                    session_id={props.selectedSessionID}
                    showInput={
                        props.selectedSessionType === 'SELF' ||
                        props.user_type === 'seller' ||
                        (checkPermission('BUYER', 'EVENT', 'EDIT', null) &&
                            props.currentlySelectedEventEditStatus ===
                                'EVENT_EDIT')
                    }
                />
            </Box>
        );
    } else if (selectedSessionData !== null) {
        return (
            <Box
                bgcolor="background.paper"
                className="flex flex--col"
                height={props.floatingChat ? '100%' : 'unset'}
                position={'relative'}
            >
                <ChatMessageBoxHeader
                    subEventId={props.subEventId}
                    openedFromPopup={props.openedFromPopup}
                    floatingChat={props.floatingChat}
                    setSelectedSession={props.setSelectedSession}
                    selectedSessionAvatarColor={
                        props.selectedSessionAvatarColor
                    }
                    sessionID={
                        selectedSessionData === null
                            ? ''
                            : selectedSessionData.session_id
                    }
                    noOfUsers={
                        selectedSessionData === null
                            ? 0
                            : selectedSessionData.session_users.length
                    }
                    sessionUsers={
                        selectedSessionData === null
                            ? []
                            : selectedSessionData.session_users
                    }
                    selectedSessionMuteState={props.selectedSessionMuteState}
                    updateSessionMuteState={props.updateSessionMuteState}
                    user_type={props.user_type}
                    buyer_entity_name={selectedSessionData.buyer_entity_name}
                    seller_entity_name={selectedSessionData.seller_entity_name}
                    session_type={selectedSessionData.session_type}
                    hideSessionListContainer={props.hideSessionListContainer}
                    setHideSessionListContainer={
                        props.setHideSessionListContainer
                    }
                    module_id={props.module_id}
                    onNoteClick={noteClickHandler}
                    selfNoteContainerOpenCallback={(open) => {
                        setIsSelfNoteContainerOpen(open);
                    }}
                />
                {props.selectedSessionType === 'SELF' && (
                    <Box
                        marginY={isSelfNoteContainerOpen ? 4.8 : 0}
                        sx={{
                            transition: 'margin 0.3s ease-in-out',
                        }}
                    ></Box>
                )}
                <ChatMessageListContainer
                    session_id={selectedSessionData.session_id}
                    module_id={props.module_id}
                    openedFromPopup={props.openedFromPopup}
                    floatingChat={props.floatingChat}
                    lastReadMsgId={props.lastReadMsgId}
                    noteId={noteId}
                />
                <ChatMessageButtonContainer
                    session_id={selectedSessionData.session_id}
                    module_id={props.module_id}
                    setLastReadMsgId={props.setLastReadMsgId}
                    showInput={
                        props.selectedSessionType === 'SELF' ||
                        props.user_type === 'seller' ||
                        (checkPermission('BUYER', 'EVENT', 'EDIT', null) &&
                            props.currentlySelectedEventEditStatus ===
                                'EVENT_EDIT')
                    }
                    noteId={noteId}
                    selectedSessionType={props.selectedSessionType}
                />
            </Box>
        );
    } else {
        return <></>;
    }
}
