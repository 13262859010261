import { createSlice } from '@reduxjs/toolkit';
import {
    ChatTypes,
    IChatSliceState,
    ISessionMessageToSend,
} from '../Interfaces/Chat.model';

type IChatReducers = {
    addMessagesToSend: (
        state: IChatSliceState,
        action: { type: string; payload: any }
    ) => void;
    addMessagesReceived: (
        state: IChatSliceState,
        action: { type: string; payload: any }
    ) => void;
    updateSentMessages: (
        state: IChatSliceState,
        action: { type: string; payload: string[] }
    ) => void;
    updateReceivedMessages: (
        state: IChatSliceState,
        action: {
            type: string;
            payload: string[];
        }
    ) => void;
    updateUnreadMsgCount: (
        state: IChatSliceState,
        action: {
            type: string;
            payload: {
                module_id: string;
                session_id: string;
            };
        }
    ) => void;
    updateCurrentlyOpenedSession: (
        state: IChatSliceState,
        action: {
            type: string;
            payload: string | null;
        }
    ) => void;
};

function _addMessagesToSend(
    state: IChatSliceState,
    action: {
        type: string;
        payload: {
            message: string;
            session_id: string | null;
            module_id: string;
            message_type: ChatTypes;
            message_id: string;
            seller_entity_list: string[];
            attachment_ids: string[];
        };
    }
) {
    if (action.payload.message_type === ChatTypes.DIRECT) {
        let obj: ISessionMessageToSend = {
            message: action.payload.message,
            session_id: action.payload.session_id!,
            module_id: action.payload.module_id,
            message_type: action.payload.message_type,
            message_id: action.payload.message_id,
            seller_entity_list: [],
            attachment_ids: action.payload.attachment_ids,
        };
        state.messagesToSend.push(obj);
    } else {
        let obj: ISessionMessageToSend = {
            message: action.payload.message,
            session_id: action.payload.session_id!,
            module_id: action.payload.module_id,
            message_type: action.payload.message_type,
            message_id: action.payload.message_id,
            seller_entity_list: action.payload.seller_entity_list,
            attachment_ids: action.payload.attachment_ids,
        };
        state.messagesToSend.push(obj);
    }
}

function _updateSentMessages(
    state: IChatSliceState,
    action: {
        type: string;
        payload: string[]; // a list of message ids to be removed from the messagesToSend array
    }
) {
    state.messagesToSend =
        state.messagesToSend.filter(
            (message) => !action.payload.includes(message.message_id)
        ) ?? [];
}

function _addMessagesReceived(
    state: IChatSliceState,
    action: { type: string; payload: any }
) {
    if (action.payload.api_resp.type === 'NOTE') {
        const dat = action.payload.api_resp;
        state.messagesReceived.push({
            message: dat.message ?? '',
            session_id: dat.session_id ?? '',
            session_id_list: dat.session_id_list ?? '',
            sender_id: dat.sender_id ?? '',
            sender_name: dat.sender_name ?? '',
            sender_enterprise_id: dat.sender_enterprise_id ?? '',
            created_datetime: dat.created_datetime ?? '',
            module_id: dat.module_id ?? '',
            message_type: dat.message_type ?? '',
            message_id: dat.message_id ?? '',
            attachments: dat.attachments ?? [],
        });
    }

    /* if (action.payload.api_resp.response_type === 'SOCKET_INFO') {
        for (let unreadModuleSession of action.payload.api_resp.response_data) {
            // update the module info
            if (
                !(unreadModuleSession.module_id in state.messageCountDictionary)
            ) {
                (state.messageCountDictionary.total_count as number) += 1;
                state.messageCountDictionary[unreadModuleSession.module_id] = {
                    total_count: 0,
                };
            }
            // update the session info
            if (
                !(
                    unreadModuleSession.session_id in
                    (state.messageCountDictionary[
                        unreadModuleSession.module_id
                    ] as any)
                )
            ) {
                ((
                    state.messageCountDictionary[
                        unreadModuleSession.module_id
                    ] as any
                )['total_count'] as number) += 1;
                (
                    state.messageCountDictionary[
                        unreadModuleSession.module_id
                    ] as any
                )[unreadModuleSession.session_id] = 0;
            }
            (
                state.messageCountDictionary[
                    unreadModuleSession.module_id
                ] as any
            )[unreadModuleSession.session_id] =
                unreadModuleSession.unread_count;
        }
    } else  */ if (action.payload.api_resp.response_type === 'CHAT_RESPONSE') {
        let data = action.payload.api_resp.response_data;
        let module_id = data.module_id;
        // for (let session_id of data.session_id_list) {
        state.messagesReceived.push({
            message: data.message,
            session_id: data.session_id,
            session_id_list: data.session_id_list,
            sender_id: data.sender_id,
            sender_name: data.sender_name,
            sender_enterprise_id: data.sender_enterprise_id,
            created_datetime: data.created_datetime,
            module_id: module_id,
            message_type: data.message_type,
            message_id: data.message_id,
            attachments: data.attachments,
        });

        if (
            state.currentlyOpenedSession !== data.session_id &&
            action.payload.receiver_id !== data.sender_id /* &&
            data.alert_users.includes(action.payload.receiver_id) */
        ) {
            (state.messageCountDictionary.total_count as number) += 1;
            if (!(module_id in state.messageCountDictionary)) {
                state.messageCountDictionary[module_id] = {
                    total_count: 0,
                };
            }
            if (
                !(
                    data.session_id in
                    (state.messageCountDictionary[module_id] as any)
                )
            ) {
                (state.messageCountDictionary[module_id] as any)[
                    'total_count'
                ] += 1;
                (state.messageCountDictionary[module_id] as any)[
                    data.session_id
                ] = 0;
            }
            (state.messageCountDictionary[module_id] as any)[
                data.session_id
            ] += 1;
        }
        // }
    }

    if (action.payload.api_resp.response_type === 'SOCKET_INFO') {
        let data = action.payload.api_resp.response_data;
        // let module_id = data.module_id;

        if (
            state.currentlyOpenedSession !== data.session_id &&
            action.payload.receiver_id !== data.sender_id /* &&
            data.alert_users.includes(action.payload.receiver_id) */
        ) {
            (state.messageCountDictionary.total_count as number) = data.length;
            data.forEach((val: any) => {
                if (state.messageCountDictionary[val.module_id]) {
                    (state.messageCountDictionary[val.module_id] as any)[
                        val.session_id
                    ] = val.unread_count;
                } else {
                    state.messageCountDictionary[val.module_id] = {
                        [val.session_id]: val.unread_count,
                    };
                }
            });

            // if (!(module_id in state.messageCountDictionary)) {
            //     state.messageCountDictionary[module_id] = {
            //         total_count: 0,
            //     };
            // }
            // if (
            //     !(
            //         data.session_id in
            //         (state.messageCountDictionary[module_id] as any)
            //     )
            // ) {
            //     (state.messageCountDictionary[module_id] as any)[
            //         'total_count'
            //     ] += 1;
            //     (state.messageCountDictionary[module_id] as any)[
            //         data.session_id
            //     ] = 0;
            // }
            // (state.messageCountDictionary[module_id] as any)[
            //     data.session_id
            // ] += 1;
        }
        // }
    }
}

function _updateReceivedMessages(
    state: IChatSliceState,
    action: {
        type: string;
        payload: string[]; // a list of message ids to be removed from the messagesToSend array
    }
) {
    state.messagesReceived = state.messagesReceived.filter(
        (message) => !action.payload.includes(message.message_id)
    );
}

function _updateUnreadMsgCount(
    state: IChatSliceState,
    action: {
        type: string;
        payload: {
            module_id: string;
            session_id: string;
        };
    }
) {
    if (action.payload.module_id in state.messageCountDictionary) {
        if (
            action.payload.session_id in
            (state.messageCountDictionary[action.payload.module_id] as any)
        ) {
            delete (
                state.messageCountDictionary[action.payload.module_id] as any
            )[action.payload.session_id];
            if (
                +(state.messageCountDictionary[action.payload.module_id] as any)
                    .total_count > 0
            ) {
                (
                    state.messageCountDictionary[
                        action.payload.module_id
                    ] as any
                ).total_count =
                    +(
                        state.messageCountDictionary[
                            action.payload.module_id
                        ] as any
                    ).total_count - 1;
            }
            if (
                +(state.messageCountDictionary[action.payload.module_id] as any)
                    .total_count === 0 &&
                +(state.messageCountDictionary as any).total_count > 0
            ) {
                (state.messageCountDictionary as any).total_count =
                    +(state.messageCountDictionary as any).total_count - 1;
            }
        }
    }
}

const _updateCurrentlyOpenedSession = (
    state: IChatSliceState,
    action: {
        type: string;
        payload: string | null;
    }
) => {
    state.currentlyOpenedSession = action.payload;
};

export const ChatSlice = createSlice<IChatSliceState, IChatReducers, string>({
    name: 'Chat',
    initialState: {
        messagesToSend: [],
        messagesReceived: [],
        messageCountDictionary: {
            total_count: 0,
        },
        currentlyOpenedSession: null,
    },
    reducers: {
        addMessagesToSend: _addMessagesToSend,
        addMessagesReceived: _addMessagesReceived,
        updateSentMessages: _updateSentMessages,
        updateReceivedMessages: _updateReceivedMessages,
        updateUnreadMsgCount: _updateUnreadMsgCount,
        updateCurrentlyOpenedSession: _updateCurrentlyOpenedSession,
    },
});

export const {
    addMessagesToSend,
    addMessagesReceived,
    updateSentMessages,
    updateReceivedMessages,
    updateUnreadMsgCount,
    updateCurrentlyOpenedSession,
} = ChatSlice.actions;
const ChatStore = ChatSlice.reducer;
export default ChatStore;
