import { Box, Grid } from '@mui/material';
import { Fragment, useState } from 'react';
import FWPopover from '../../Common/FWPopover';
import { FWTypography } from '../../Common/FWTypograhy';

export interface IUserNamePopoverProps {
    names: string[];
    popoverTitle: string;
}

export default function UserNamePopover({
    names,
    popoverTitle,
}: IUserNamePopoverProps) {
    const [anchorEl, setAnchorEl] = useState<HTMLAnchorElement | null>(null);
    const handleClick = (event: any) => {
        if (names?.length > 1) {
            event.stopPropagation();
            setAnchorEl(event.currentTarget);
        }
    };
    const open = Boolean(anchorEl);
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Box
            component="span"
            onClick={handleClick}
            style={{ cursor: names.length > 1 ? 'pointer' : '' }}
        >
            {names?.length > 1 ? (
                <Box component={'span'} color="primary.main">
                    {names?.length === 1
                        ? names?.[0]
                        : `${names?.[0]} and ${names?.length - 1} more`}
                </Box>
            ) : (
                <FWTypography>{names[0]}</FWTypography>
            )}

            <FWPopover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Grid container>
                    {names?.length > 0 ? (
                        <div className="p--5">
                            <div className="mb--5">
                                <FWTypography color="text.secondary">
                                    {popoverTitle}
                                </FWTypography>
                            </div>
                            {names.map((name, idx) => (
                                <Fragment key={idx}>
                                    <Grid
                                        item
                                        md={12}
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                        }}
                                    >
                                        <FWTypography>
                                            {idx + 1}. {name}
                                        </FWTypography>
                                    </Grid>
                                </Fragment>
                            ))}
                        </div>
                    ) : (
                        <FWTypography>-</FWTypography>
                    )}
                </Grid>
            </FWPopover>
        </Box>
    );
}
