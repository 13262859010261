import { isEmpty, isNull } from 'lodash';
import moment from 'moment';
import { toast } from 'react-toastify';
import { getFWDate } from '../../Common/Utils/DateUtils';
import { IFileResourceType } from '../../Components/Shared/UploadFile';
import { CustomTemplateTypes } from '../../Global/Interfaces/CustomTemplate.interface';
import { getAttachmentsList } from '../../Global/Services/FileStorageService';
import { IAttachment } from '../../Models/RFQ.model';
import { get, post } from '../../Utils/api';
import { PendingView } from '../Containers/GRPendingListContainer';
import {
    calculatePendingQuantity,
    createGroupedInvoiceItemsList,
    generateAttributes,
} from '../Helpers/InvoiceItemHelpers';
import {
    GRDashboardType,
    GoodsReceiptsResponseInterface,
    IGRInvoiceDetails,
    IGRInvoiceItemBatchGetApi,
    IGRInvoiceItemGetApi,
    IGRInvoiceListSummary,
    IGRNDetails,
    IGRNItemSummary,
    IGRNewCreateBatchCustomFieldPayload,
    IGRNewCreateBatchPayload,
    IGRNewCreatePayload,
    IGRPastListSummary,
    IGroupedInvoiceItemsDict,
    IInvoiceItemsSummary,
    IPageDetails,
    ItemGRStatus,
} from '../Interfaces/GRInterface';

interface IFetchGRInvoiceListPayload {
    dashboard_view: string;
    search_text: string;
    tab: string;
    sort_fields: {
        field: string;
        ascending: boolean;
    }[];
    items_per_page: number;
    page_number: number;
}

export const fetchGRByPO = async ({
    searchText,
    sortFields,
    itemsPerPage,
    pageNumber,
}: {
    searchText: string;
    sortFields: {
        field: string;
        ascending: boolean;
    }[];
    itemsPerPage: number;
    pageNumber: number;
}): Promise<{
    grInoviceList: IGRInvoiceListSummary[];
    pageDetails: IPageDetails;
}> => {
    const postData: IFetchGRInvoiceListPayload = {
        dashboard_view: 'purchase_order_delivery_pending',
        tab: 'all',
        search_text:
            searchText?.length && searchText?.length > 0 ? searchText : '',
        sort_fields: sortFields ?? [],
        items_per_page: itemsPerPage,
        page_number: pageNumber,
    };

    const resp = await post<any, any>(`/dashboard/`, postData);
    const grInoviceList = resp.data.data.map((item: any) => {
        const newItem: IGRInvoiceListSummary = {
            poId: item.custom_purchase_order_id,
            poUid: item.purchase_order_id,
            invoiceId: item.custom_invoice_id ?? '-',
            invoiceUid: item.invoice_id ?? '-',
            invoiceDate: item.invoice_date ?? '-',
            buyerEntityName: item.buyer_information.buyer_entity_name,
            vendorName: item.seller_information.seller_entity_name,
            deliveryDate: item.delivery_information.delivery_date
                ? getFWDate(item.delivery_information.delivery_date)
                : null,
            noOfItems: item.po_item_count,
            grCount: item.goods_receipt_count,
            status:
                item.goods_receipt_count > 0
                    ? 'PARTIALLY_PENDING'
                    : 'DELIVERY_PENDING',
            poOnHold: item.hold_information ? true : false,
            poTermination: item.termination_information ? true : false,
            poTerminationAccepted:
                item.termination_information &&
                item.termination_information.status === 'ACCEPTED'
                    ? true
                    : false,
            itemOnHold: item.item_hold_status ? true : false,
            itemTermination: item.item_termination ? true : false,
            shippingAddress: {
                nickName: item.buyer_information.shipping_address_nickname,
                fullAddress: item.buyer_information.shipping_address,
            },
            measurementUnit: '',
            invoiceVerified: item.verification_status === 'VERIFIED',
            hasInvoice: item.custom_invoice_id ? true : false,
            pendingInvoiceCount: item.pending_gr_invoice_count,
            referenceId: item.reference_id || null,
            entityUid: item.buyer_entity,
            lots: item.delivery_information.lot_count || 0,
        };
        return newItem;
    });
    return {
        grInoviceList: grInoviceList,
        pageDetails: resp.data.metadata,
    };
};

export const fetchGRByInvoiceList = async ({
    searchText,
    sortFields,
    itemsPerPage,
    pageNumber,
}: {
    searchText: string;
    sortFields: {
        field: string;
        ascending: boolean;
    }[];
    itemsPerPage: number;
    pageNumber: number;
}): Promise<{
    grInoviceList: IGRInvoiceListSummary[];
    pageDetails: IPageDetails;
}> => {
    const postData: IFetchGRInvoiceListPayload = {
        dashboard_view: 'invoice_buyer',
        tab: 'gr_pending',
        search_text:
            searchText?.length && searchText?.length > 0 ? searchText : '',
        sort_fields: sortFields ?? [],
        items_per_page: itemsPerPage,
        page_number: pageNumber,
    };

    const resp = await post<any, any>(`/dashboard/`, postData);
    const grInoviceList = resp.data.data.map((grInvoice: any) => {
        const newItem: IGRInvoiceListSummary = {
            poId: grInvoice.purchase_order.custom_purchase_order_id,
            poUid: grInvoice.purchase_order.purchase_order_id,
            invoiceId: grInvoice.custom_invoice_id ?? '-',
            invoiceUid: grInvoice.invoice_id ?? '-',
            invoiceDate: grInvoice.invoice_date ?? '-',
            buyerEntityName: grInvoice.buyer_information.buyer_entity_name,
            vendorName: grInvoice.seller_information.seller_entity_name,
            deliveryDate: grInvoice.purchase_order.delivery_information
                .delivery_date
                ? getFWDate(
                      grInvoice.purchase_order.delivery_information
                          .delivery_date
                  )
                : null,
            noOfItems: grInvoice.invoice_item_count,
            grCount: grInvoice.goods_receipt_count,
            status:
                grInvoice.goods_receipt_count > 0
                    ? 'PARTIALLY_PENDING'
                    : 'DELIVERY_PENDING',
            poOnHold: grInvoice.purchase_order.hold_information ? true : false,
            poTermination: grInvoice.purchase_order.termination_information
                ? true
                : false,
            poTerminationAccepted:
                grInvoice.purchase_order.termination_information &&
                grInvoice.purchase_order.termination_information.status ===
                    'ACCEPTED'
                    ? true
                    : false,
            itemOnHold: grInvoice.item_hold_status ? true : false,
            itemTermination: grInvoice.item_termination ? true : false,
            shippingAddress: {
                nickName: grInvoice.buyer_information.shipping_address_nickname,
                fullAddress: grInvoice.buyer_information.shipping_address,
            },
            measurementUnit: '',
            invoiceVerified: grInvoice.verification_status === 'VERIFIED',
            hasInvoice: grInvoice.custom_invoice_id ? true : false,
            referenceId: grInvoice.reference_id || null,
            entityUid: grInvoice.buyer_entity,
            lots: grInvoice.purchase_order.delivery_information.lot_count || 0,
        };
        return newItem;
    });
    return {
        grInoviceList: grInoviceList,
        pageDetails: resp.data.metadata,
    };
};

export const fetchGRInvoiceList = async (): Promise<
    IGRInvoiceListSummary[]
> => {
    const response: any = await get<any>(`/goods_receipt/invoices/`);
    const poResponse: any = await get<any>(
        `/purchase_orders/delivery_pending/`
    );
    const data: any[] = response.data;
    const poData: any[] = poResponse.data;
    if (data) {
        const allPOUids: string[] = [];
        data.forEach((po) => {
            if (!allPOUids.includes(po.purchase_order.purchase_order_id)) {
                allPOUids.push(po.purchase_order.purchase_order_id);
            }
        });

        const poWithNoInvoices: any[] = [];
        poData.forEach((po) => {
            if (!allPOUids.includes(po.purchase_order_id)) {
                poWithNoInvoices.push(po);
            }
        });

        const poList = poWithNoInvoices.map((po) => {
            const newItem: IGRInvoiceListSummary = {
                poId: po.custom_purchase_order_id,
                poUid: po.purchase_order_id,
                invoiceId: '-',
                invoiceUid: '-',
                invoiceDate: '',
                buyerEntityName: po.buyer_information.buyer_entity_name,
                vendorName: po.seller_information.seller_entity_name,
                deliveryDate: po.expected_delivery_date
                    ? po.expected_delivery_date
                    : po.delivery_information.delivery_date
                    ? getFWDate(po.delivery_information.delivery_date)
                    : null,
                noOfItems: po.additional_information.purchase_order_items_count,
                grCount: 0,
                status: 'DELIVERY_PENDING',
                poOnHold: po.hold_information ? true : false,
                poTermination: po.termination_information ? true : false,
                poTerminationAccepted:
                    po.termination_information &&
                    po.termination_information.status === 'ACCEPTED',
                itemOnHold: false,
                itemTermination: false,
                shippingAddress: {
                    nickName: po.buyer_information.buyer_entity_name,
                    fullAddress: po.buyer_information.shipping_address,
                },
                measurementUnit: '',
                invoiceVerified: false,
                hasInvoice: false,
                referenceId: po.reference_id || null,
                entityUid: po.buyer_entity,
                lots: po.delivery_information.lot_count || 0,
            };
            return newItem;
        });

        const invoiceData = data
            .sort((a, b) => {
                if (a.expected_delivery_date && b.expected_delivery_date) {
                    if (
                        moment(a.expected_delivery_date).isBefore(
                            moment(b.expected_delivery_date)
                        )
                    ) {
                        return -1;
                    }
                    if (
                        moment(a.expected_delivery_date).isAfter(
                            moment(b.expected_delivery_date)
                        )
                    ) {
                        return 1;
                    }
                }
                return 0;
            })
            .map((item) => {
                const newItem: IGRInvoiceListSummary = {
                    poId: item.purchase_order.custom_purchase_order_id,
                    invoiceId: item.custom_invoice_id,
                    invoiceUid: item.invoice_id,
                    invoiceDate: item.invoice_date ?? '',
                    buyerEntityName: item.buyer_information.buyer_entity_name,
                    vendorName: item.seller_information.seller_entity_name,
                    deliveryDate: item.expected_delivery_date
                        ? getFWDate(item.expected_delivery_date)
                        : item.purchase_order.delivery_information.delivery_date
                        ? getFWDate(
                              item.purchase_order.delivery_information
                                  .delivery_date
                          )
                        : null,
                    noOfItems: item.invoice_item_count,
                    grCount: item.goods_receipt_count,
                    status:
                        item.goods_receipt_count > 0
                            ? 'PARTIALLY_PENDING'
                            : 'DELIVERY_PENDING',
                    poOnHold: item.purchase_order.hold_information
                        ? true
                        : false,
                    poTermination: item.purchase_order.termination_information,
                    poTerminationAccepted:
                        item.purchase_order &&
                        item.purchase_order.termination_information &&
                        item.purchase_order.termination_information.status ===
                            'ACCEPTED',
                    itemOnHold: item.item_hold_status ? true : false,
                    itemTermination: item.item_termination ? true : false,
                    shippingAddress: {
                        nickName:
                            item.buyer_information.shipping_address_nickname,
                        fullAddress: item.buyer_information.shipping_address,
                    },
                    poUid: item.purchase_order.purchase_order_id,
                    measurementUnit: '',
                    invoiceVerified: item.verification_status === 'VERIFIED',
                    hasInvoice: true,
                    referenceId: item.reference_id,
                    entityUid: item.buyer_entity,
                    lots:
                        item.purchase_order.delivery_information.lot_count || 0,
                };
                return newItem;
            });

        const poAndInvoicesList = [...invoiceData, ...poList];

        return poAndInvoicesList.sort((a, b) => {
            if (isNull(a.deliveryDate) && !isNull(b.deliveryDate)) return 1;
            if (!isNull(a.deliveryDate) && isNull(b.deliveryDate)) return -1;

            if (a.deliveryDate && b.deliveryDate) {
                if (
                    moment(new Date(a.deliveryDate)).isBefore(
                        moment(new Date(b.deliveryDate))
                    )
                ) {
                    return -1;
                }
                if (
                    moment(new Date(a.deliveryDate)).isAfter(
                        moment(new Date(b.deliveryDate))
                    )
                ) {
                    return 1;
                }
            }
            return 0;
        });
    }
    return response;
};

export const fetchPastGR = async ({
    searchText,
    sortFields,
    itemsPerPage,
    pageNumber,
}: {
    searchText: string;
    sortFields: {
        field: string;
        ascending: boolean;
    }[];
    itemsPerPage: number;
    pageNumber: number;
}): Promise<{
    goodsReceiptsList: IGRPastListSummary[];
    pageDetails: IPageDetails;
}> => {
    const postData: IFetchGRInvoiceListPayload = {
        dashboard_view: 'goods_receipt',
        tab: 'all',
        search_text:
            searchText?.length && searchText?.length > 0 ? searchText : '',
        sort_fields: sortFields,
        items_per_page: itemsPerPage,
        page_number: pageNumber,
    };

    const resp = await post<any, any>(`/dashboard/`, postData);
    const goodsReceiptsList = resp.data.data.map(
        (item: GoodsReceiptsResponseInterface) => {
            const newItem: IGRPastListSummary = {
                grnNo: item.custom_goods_receipt_id,
                grnUid: item.goods_receipt_id,
                buyerEntityName: item.buyer_information.buyer_entity_name,
                vendorName: item.seller_information.seller_entity_name,
                deliveryDate:
                    item.additional_information.invoice_information &&
                    item.additional_information.invoice_information
                        .expected_delivery_date
                        ? getFWDate(
                              item.additional_information.invoice_information
                                  .expected_delivery_date
                          )
                        : null,
                grnIssueDate: item.goods_receipt_date,
                grnCreator: item.created_by_user_name,
                status: item.invoice_item.status,
                poId: item.additional_information.purchase_order_information
                    .custom_purchase_order_id,
                poItemUid: item.invoice_item.purchase_order_item,
                invoiceId:
                    item.additional_information.invoice_information
                        .custom_invoice_id,
                invoiceUid:
                    item.additional_information.invoice_information.invoice_id,
                itemName: item.invoice_item.item_information.buyer_item_name,
                grnAcceptedQty: parseFloat(
                    item.quantity_information.quantity_accepted
                ),
                grnRejectedQty:
                    +item.quantity_information.total_quantity -
                    +item.quantity_information.quantity_accepted,
                grnInvoicedQty: parseFloat(
                    item.quantity_information.total_quantity
                ),
                itemDetails: {
                    name: item.invoice_item.item_information.buyer_item_name,
                    itemId: item.invoice_item.item_information.buyer_item_id,
                    description:
                        item.invoice_item.item_information
                            .buyer_item_description,
                    additionalInfo:
                        item.invoice_item.item_information
                            .item_additional_details,
                    attributes: generateAttributes(
                        item.invoice_item.attribute_information
                            .buyer_item_attributes,
                        item.invoice_item.attribute_information
                            .custom_item_attributes
                    ),
                },
                measurementUnit:
                    item.invoice_item.quantity_information
                        .measurement_unit_name,
            };
            return newItem;
        }
    );
    return {
        goodsReceiptsList: goodsReceiptsList,
        pageDetails: resp.data.metadata,
    };
};

// export const fetchPastGR = async (): Promise<IGRPastListSummary[]> => {
//     const response: any = await get<any>(`/goods_receipt/`);
//     const data: any[] = response.data;
//     if (data) {
//         return data
//             .map((item) => {
//                 const newItem: IGRPastListSummary = {
//                     grnNo: item.custom_goods_receipt_id,
//                     grnUid: item.goods_receipt_id,
//                     buyerEntityName: item.buyer_information.buyer_entity_name,
//                     vendorName: item.seller_information.seller_entity_name,
//                     deliveryDate:
//                         item.additional_information.invoice_information &&
//                         item.additional_information.invoice_information
//                             .expected_delivery_date
//                             ? getFWDate(
//                                   item.additional_information
//                                       .invoice_information
//                                       .expected_delivery_date
//                               )
//                             : null,
//                     grnIssueDate: item.goods_receipt_date,
//                     grnCreator: item.created_by_user_name,
//                     status: item.invoice_item.status,
//                     poId: item.additional_information.purchase_order_information
//                         .custom_purchase_order_id,
//                     poItemUid: item.invoice_item.purchase_order_item,
//                     invoiceId:
//                         item.additional_information.invoice_information
//                             .custom_invoice_id,
//                     invoiceUid:
//                         item.additional_information.invoice_information
//                             .invoice_id,
//                     itemName:
//                         item.invoice_item.item_information.buyer_item_name,
//                     grnAcceptedQty: parseFloat(
//                         item.quantity_information.quantity_accepted
//                     ),
//                     grnRejectedQty:
//                         +item.quantity_information.total_quantity -
//                         +item.quantity_information.quantity_accepted,
//                     grnInvoicedQty: parseFloat(
//                         item.quantity_information.total_quantity
//                     ),
//                     itemDetails: {
//                         name: item.invoice_item.item_information
//                             .buyer_item_name,
//                         itemId: item.invoice_item.item_information
//                             .buyer_item_id,
//                         description:
//                             item.invoice_item.item_information
//                                 .buyer_item_description,
//                         additionalInfo:
//                             item.invoice_item.item_information
//                                 .item_additional_details,
//                         attributes: generateAttributes(
//                             item.invoice_item.attribute_information
//                                 .buyer_item_attributes,
//                             item.invoice_item.attribute_information
//                                 .custom_item_attributes
//                         ),
//                     },
//                     measurementUnit:
//                         item.invoice_item.quantity_information
//                             .measurement_unit_name,
//                 };
//                 return newItem;
//             })
//             .sort((a, b) => {
//                 if (a.grnNo.toLowerCase() < b.grnNo.toLowerCase()) {
//                     return 1;
//                 }
//                 if (a.grnNo.toLowerCase() > b.grnNo.toLowerCase()) {
//                     return -1;
//                 }
//                 return 0;
//             });
//     }
//     return response;
// };
export const fetchTabCount = async (
    name: string,
    view: string
): Promise<number> => {
    try {
        if (name === GRDashboardType.PENDING) {
            if (view === PendingView.INVOICE) {
                const response = await post<
                    any,
                    {
                        tab_counts: {
                            [key: string]: number;
                        };
                    }
                >(`/dashboard/tab_counts/`, {
                    dashboard_view: 'invoice_buyer',
                });
                return response.data.tab_counts.gr_pending;
            } else if (view === PendingView.PURCHASE_ORDER) {
                const response = await post<
                    any,
                    {
                        tab_counts: {
                            [key: string]: number;
                        };
                    }
                >(`/dashboard/tab_counts/`, {
                    dashboard_view: 'purchase_order_delivery_pending',
                });
                return response.data.tab_counts.all;
            }
        } else if (name === GRDashboardType.PAST) {
            const response = await post<
                any,
                {
                    tab_counts: {
                        [key: string]: number;
                    };
                }
            >(`/dashboard/tab_counts/`, {
                dashboard_view: 'goods_receipt',
            });
            return response.data.tab_counts.all;
        }
    } catch (error) {
        return 0;
    }
    return 0;
};

export const fetchInvoiceDetails = async (
    invoiceUid: string
): Promise<[IGRInvoiceDetails, IGroupedInvoiceItemsDict]> => {
    const response: any = await get<any>(
        `/goods_receipt/invoices/${invoiceUid}/`
    );
    const data: any = response.data;
    if (data) {
        const invoiceDetails: IGRInvoiceDetails = {
            vendorName: data.seller_information.seller_entity_name,
            poId: data.purchase_order.custom_purchase_order_id,
            poUid: data.purchase_order.purchase_order_id,
            invoiceId: data.custom_invoice_id,
            contactPerson: data.purchase_order.buyer_contact_list
                ? data.purchase_order.buyer_contact_list[0].buyer_contact_name
                : '',
            contactPersonUid: data.purchase_order.buyer_contact_list
                ? data.purchase_order.buyer_contact_list[0].buyer_contact_id
                : '',
            vendorContact: data.purchase_order.seller_contact_list
                ? data.purchase_order.seller_contact_list[0].seller_contact_name
                : '',
            vendorContactUid: data.purchase_order.seller_contact_list
                ? data.purchase_order.seller_contact_list[0].seller_contact_id
                : '',
            createdOn: getFWDate(data.invoice_date),
            deliveryDate: data.expected_delivery_date
                ? getFWDate(data.expected_delivery_date)
                : null,
            poOnHold: data.purchase_order.hold_information ? true : false,
            poTermination: data.purchase_order.termination_information
                ? true
                : false,
            poTerminationAccepted:
                data.purchase_order &&
                data.purchase_order.termination_information &&
                data.purchase_order.termination_information.status ===
                    'ACCEPTED',
            invoiceVerified: data.verification_status === 'VERIFIED',
            invoiceCreated: true,
            referenceId: data.reference_id,
            attachments: data.attachments
                ? data.attachments.map((a: any) => ({
                      file_name: a.file_name,
                      attachment_id: a.attachment_id,
                      cannotRemove: true,
                  }))
                : [],
            entityUid: data.buyer_entity,
        };
        const invoiceItemsWithArrays: IInvoiceItemsSummary[][] =
            data.invoice_items
                .sort((a: IGRInvoiceItemGetApi, b: IGRInvoiceItemGetApi) => {
                    if (
                        a.item_information.global_item_id <
                        b.item_information.global_item_id
                    ) {
                        return -1;
                    }
                    if (
                        a.item_information.global_item_id >
                        b.item_information.global_item_id
                    ) {
                        return 1;
                    }
                    return 0;
                })
                .map(
                    (
                        item: IGRInvoiceItemGetApi,
                        idx: number
                    ): IInvoiceItemsSummary[] => {
                        const newItem = item.batch_items.map(
                            (
                                batch: IGRInvoiceItemBatchGetApi,
                                batchIdx: number
                            ): IInvoiceItemsSummary => ({
                                customIDs: item.item_information.custom_ids,
                                id: `${Date.now()}${idx}${batchIdx}`,
                                uId: item.item_information.global_item_id,
                                itemId: item.item_information.buyer_item_id,
                                invoiceItemUid: item.invoice_item_id,
                                invoiceItemBatchUid:
                                    batch.invoice_item_batch_id,
                                name: item.item_information.buyer_item_name,
                                batchNo: batch.custom_batch_id,
                                batchNotes: item.notes,
                                batchExpiryDate: batch.batch_expiry_date,
                                allowedToleranceFromPO:
                                    item.po_item_quantity_information
                                        .quantity_tolerance_percentage,
                                pendingQuantity: calculatePendingQuantity(
                                    parseFloat(
                                        item.po_item_quantity_information
                                            .quantity
                                    ),
                                    parseFloat(
                                        item.po_item_fulfilment_information
                                            .delivered
                                    ),
                                    parseFloat(
                                        item.po_item_fulfilment_information
                                            .rescheduled
                                    )
                                ),
                                invoicedBatchQuantity:
                                    batch.quantity_information.batch_quantity,
                                measurementUnit:
                                    item.quantity_information
                                        .measurement_unit_name,
                                measurementUnitId:
                                    item.quantity_information
                                        .measurement_unit_id,
                                measurementType:
                                    item.quantity_information
                                        .measurement_unit_value_type,
                                grAcceptedQuantity:
                                    item.status === ItemGRStatus.GOODS_RECEIVED
                                        ? batch.fulfilment_information
                                              .gr_quantity_accepted
                                        : '',
                                itemStatus: item.status,
                                grRejectedQuantity:
                                    item.status === ItemGRStatus.GOODS_RECEIVED
                                        ? batch.fulfilment_information
                                              .gr_quantity_rejected
                                        : '',
                                rejectionReason: '',
                                qcRejectionQuantity:
                                    batch.fulfilment_information
                                        .qc_quantity_rejected,
                                qcAcceptedQuantity:
                                    batch.fulfilment_information
                                        .qc_pl_quantity_accepted,
                                toleranceQty:
                                    item.status === ItemGRStatus.GOODS_RECEIVED
                                        ? (+batch.fulfilment_information
                                              .gr_quantity_tolerated).toFixed(2)
                                        : '0',
                                grOverDeliveredQuantity: (+batch
                                    .fulfilment_information
                                    .gr_quantity_over_delivered).toFixed(2),
                                description:
                                    item.item_information
                                        .buyer_item_description,
                                additionalInfo:
                                    item.item_information
                                        .item_additional_details,
                                attributes: generateAttributes(
                                    item.attribute_information
                                        .buyer_item_attributes,
                                    item.attribute_information
                                        .custom_item_attributes
                                ),
                                grnUid:
                                    batch.goods_receipt?.goods_receipt_id ??
                                    null,
                                itemOnHold: item.hold_information
                                    ? true
                                    : false,
                                itemTermination: item.termination_information
                                    ? true
                                    : false,
                                expectedDeliveryDate:
                                    data.expected_delivery_date,
                                poItemUid: item.purchase_order_item,
                                attachments: [],
                                customFields: {
                                    template_id:
                                        batch.custom_fields.template_id,
                                    template_name:
                                        batch.custom_fields.template_name,
                                    type: CustomTemplateTypes.GRN_CUSTOM_FIELDS,
                                    enterprise: batch.custom_fields.enterprise,
                                    entity: batch.custom_fields.entity,
                                    items:
                                        batch.goods_receipt?.custom_fields?.template_items?.map(
                                            (template_item) => ({
                                                is_required:
                                                    template_item.is_required,
                                                template:
                                                    template_item.template,
                                                template_item_id:
                                                    template_item.template_item_id,
                                                template_item_name:
                                                    template_item.template_item_name,
                                                template_item_type:
                                                    template_item.template_item_type,
                                                value: template_item.value,
                                            })
                                        ) ?? [],
                                },
                            })
                        );
                        return newItem;
                    }
                );

        const invoiceItems: IInvoiceItemsSummary[] = ([] as any).concat(
            ...invoiceItemsWithArrays
        );

        const groupedInvoiceItems = createGroupedInvoiceItemsList(invoiceItems);

        //

        return [invoiceDetails, groupedInvoiceItems];
    }
    return response;
};

export const fetchGRNDetails = async (
    grnUid: string
): Promise<[IGRNDetails, IGRNItemSummary]> => {
    const response: any = await get<any>(`/goods_receipt/${grnUid}/`);
    const data: any = response.data.goods_receipt;
    if (isEmpty(data.checklist)) {
        data.checklist = [];
    }
    if (data) {
        try {
            const grnDetails: IGRNDetails = {
                grnUid: data.goods_receipt_id,
                grnNo: data.custom_goods_receipt_id,
                grCreatedBy: data.created_by_user_name,
                grCreatedByDate: getFWDate(data.created_datetime),
                poId: data.additional_information.purchase_order_information
                    .custom_purchase_order_id,
                invoiceId:
                    data.additional_information.invoice_information
                        .custom_invoice_id || '',
                invoiceUid:
                    data.additional_information.invoice_information.invoice_id,
                receivedBy: data.created_by_user_name,
                grnIssueDate: getFWDate(data.goods_receipt_date),
                referenceId:
                    data.additional_information.invoice_information
                        .reference_id || '',
                poCreatedBy:
                    data.additional_information.purchase_order_information
                        .purchase_order_approver_name,
                poCreatedByDate: getFWDate(
                    data.additional_information.purchase_order_information
                        .purchase_order_submission_datetime
                ),
                invoiceCreatedBy:
                    data.additional_information.invoice_information
                        .invoice_creator_name,
                invoiceCreatedByDate: getFWDate(
                    data.additional_information.invoice_information.invoice_date
                ),
                vendorName: data.seller_information.seller_entity_name,
                vendorContactName:
                    data.additional_information.purchase_order_information
                        .seller_contact_list[0].seller_contact_name,
                grContactUid: data.created_by_user,
                poContactUid:
                    data.additional_information.purchase_order_information
                        .purchase_order_approver,
                invoiceContactUid:
                    data.additional_information.invoice_information
                        .invoice_creator,
                vendorContactUid:
                    data.additional_information.purchase_order_information
                        .seller_contact_list[0].seller_contact_id,
                invoiceAttachments: [],
            };
            try {
                const invoiceBuyerAttachments = await getAttachmentsList(
                    grnDetails.invoiceUid,
                    IFileResourceType.INVOICE_BUYER
                );
                const invoiceSellerAttachments = await getAttachmentsList(
                    grnDetails.invoiceUid,
                    IFileResourceType.INVOICE_SELLER
                );
                grnDetails.invoiceAttachments = [
                    ...invoiceBuyerAttachments,
                    ...invoiceSellerAttachments,
                ];
            } catch (error) {}
            const item = response.data.invoice_item;
            const newItem: IGRNItemSummary = {
                itemId: item.item_information.buyer_item_id,
                name: item.item_information.buyer_item_name,
                measurementUnit:
                    data.quantity_information.measurement_unit_name,
                invoiceQuantity: data.quantity_information.total_quantity,
                acceptedQuantity: data.quantity_information.quantity_accepted,
                rejectedQuantity: data.quantity_information.quantity_rejected
                    ? +data.quantity_information.quantity_rejected
                    : +data.quantity_information.total_quantity -
                      +data.quantity_information.quantity_accepted,
                overdeliveredQuantity: data.quantity_information
                    .quantity_over_delivered
                    ? +data.quantity_information.quantity_over_delivered
                    : 0,
                toleranceQuantity:
                    +data.quantity_information.total_quantity -
                    +data.quantity_information.quantity_accepted -
                    +data.quantity_information.quantity_rejected -
                    +data.quantity_information.quantity_over_delivered,
                deliveryDate: getFWDate(moment().format('YYYY-MM-DD')),
                rejectionReason: data.notes,
                batchNo: response.data.invoice_item_batch.custom_batch_id,
                description: item.item_information.buyer_item_description,
                attributes: generateAttributes(
                    item.attribute_information.buyer_item_attributes,
                    item.attribute_information.custom_item_attributes
                ),
                attachments:
                    response.data?.attachments?.map((a: any) => ({
                        attachment_id: a.attachment_id,
                        file_name: a.file_name,
                    })) ?? [],
                poItemUid: item.purchase_order_item,
                customFields:
                    data.custom_fields && 'template_items' in data.custom_fields
                        ? data.custom_fields
                        : {
                              template_id: '',
                              template_name: '',
                              template_type:
                                  CustomTemplateTypes.GRN_CUSTOM_FIELDS,
                              template_items: [],
                          },
                checklists: data.checklist ?? [],
            };
            return [grnDetails, newItem];
        } catch (error) {}
    }
    return response;
};

interface ICreateGRNItemsPayload {
    invoice_item_id: string;
    quantity_accepted: number;
    quantity_rejected: number;
    quantity_over_delivered: number;
    notes: string | null;
    gr_attachment_ids: string[];
    custom_fields?: {
        template_id: string;
        template_name: string;
        template_type: string;
        template_items: {
            template_item_id: string;
            value: string;
            template_item_name: string;
            template_item_type: string;
            is_required: boolean;
            template: string;
        }[];
    };
}

interface ICreateGRNPayload {
    invoice_id: string;
    invoice_items: ICreateGRNItemsPayload[];
    invoice_attachment_ids: string[];
    item_checklist: any[];
}

export interface IInvoiceItemInfoMap {
    [key: string]: string;
}
export interface ICreateGRNResponse {
    success: boolean;
    invoiceItemInfo: IInvoiceItemInfoMap;
}

export const createGRN = async (
    invoiceUid: string,
    items: IInvoiceItemsSummary[],
    invoiceAttachments: IAttachment[],
    checklists: any[]
): Promise<ICreateGRNResponse> => {
    const payloadItems: ICreateGRNItemsPayload[] = items.map((item) => {
        const payloadItem: ICreateGRNItemsPayload = {
            invoice_item_id: item.invoiceItemUid,
            quantity_accepted: +item.grAcceptedQuantity,
            quantity_rejected: +item.grRejectedQuantity,
            quantity_over_delivered: +item.grOverDeliveredQuantity,
            notes: item.rejectionReason,
            gr_attachment_ids: item.attachments.map((a) => a.attachment_id),
        };
        if (item.customFields.template_id !== '') {
            payloadItem.custom_fields = {
                template_id: item.customFields.template_id,
                template_name: item.customFields.template_name,
                template_type: item.customFields.type,
                template_items: item.customFields.items.map(
                    (template_item) => ({
                        template_item_id: template_item.template_item_id,
                        value: template_item.value,
                        template_item_name: template_item.template_item_name,
                        template_item_type: template_item.template_item_type,
                        is_required: template_item.is_required,
                        template: item.customFields.template_id,
                    })
                ),
            };
        }
        return payloadItem;
    });

    const payload: ICreateGRNPayload = {
        invoice_id: invoiceUid,
        invoice_items: payloadItems,
        invoice_attachment_ids: invoiceAttachments.map((a) => a.attachment_id),
        item_checklist: checklists,
    };

    const invoiceItemInfo: IInvoiceItemInfoMap = {};

    try {
        const response: any = await post<string, ICreateGRNPayload>(
            `/goods_receipt/create/batch/`,
            payload
        );

        response.data.forEach((item: any) => {
            invoiceItemInfo[item.invoice_item] = item.goods_receipt_id;
        });
        const grnSuccessResponse: ICreateGRNResponse = {
            success: true,
            invoiceItemInfo,
        };
        return grnSuccessResponse;
    } catch (error: any) {
        if (error?.response?.data?.ErrorCode === 'Exceeded Quota Limit') {
            toast.error(error.response.data.ErrorCode);
        }
        const grnFailureResponse: ICreateGRNResponse = {
            success: false,
            invoiceItemInfo,
        };
        return grnFailureResponse;
    }
};

export const createNewGRN = async (
    invoiceUid: string,
    items: IInvoiceItemsSummary[],
    invoiceAttachments: IAttachment[],
    checklists: any[]
): Promise<ICreateGRNResponse> => {
    const listOfUniqueInvoiceItemUid = new Set(
        items.map((item) => item.invoiceItemUid)
    );

    const payloadInvoiceItems: IGRNewCreatePayload[] = Array.from(
        listOfUniqueInvoiceItemUid
    ).map((invoiceItemUid, idx): IGRNewCreatePayload => {
        const listOfItemsWithBatches = items.filter(
            (item) => item.invoiceItemUid === invoiceItemUid
        );

        const batch_items: IGRNewCreateBatchPayload[] =
            listOfItemsWithBatches.map((item) => {
                const custom_fields: IGRNewCreateBatchCustomFieldPayload = {
                    template_id: item.customFields.template_id,
                    template_name: item.customFields.template_name,
                    template_type: item.customFields.type,
                    template_items:
                        item.customFields?.items?.map((customFieldItem) => ({
                            is_required: customFieldItem.is_required,
                            template: customFieldItem.template,
                            template_item_id: customFieldItem.template_item_id,
                            template_item_name:
                                customFieldItem.template_item_name,
                            template_item_type:
                                customFieldItem.template_item_type,
                            value: customFieldItem.value,
                        })) ?? [],
                };

                return {
                    invoice_item_batch_id: item.invoiceItemBatchUid,
                    quantity_accepted: item.grAcceptedQuantity,
                    quantity_rejected:
                        item.grRejectedQuantity === ''
                            ? '0'
                            : item.grRejectedQuantity,
                    quantity_over_delivered:
                        item.grOverDeliveredQuantity === ''
                            ? '0'
                            : item.grOverDeliveredQuantity,
                    quantity_tolerated: +item.toleranceQty,
                    notes: item.rejectionReason,
                    gr_attachment_ids: item.attachments.map(
                        (att) => att.attachment_id
                    ),
                    ...(custom_fields.template_id
                        ? {
                              custom_fields,
                          }
                        : {
                              custom_fields: null,
                          }),
                    checklist: [].concat(
                        ...checklists
                            .filter(
                                (checklist) =>
                                    checklist.purchase_order_item_id ===
                                    listOfItemsWithBatches[0].poItemUid
                            )
                            .map((checklist) => checklist.checklist)
                    ),
                };
            });

        return {
            ...(idx === 0 && {
                invoice_attachment_ids: invoiceAttachments.map(
                    (att) => att.attachment_id
                ),
            }),
            invoice_item_id: listOfItemsWithBatches[0].invoiceItemUid,
            batch_items,
        };
    });

    const invoiceItemInfo: IInvoiceItemInfoMap = {};

    try {
        for (let i = 0; i < payloadInvoiceItems.length; i++) {
            const res: any = await post<string, IGRNewCreatePayload>(
                `/goods_receipt/create/batch/`,
                payloadInvoiceItems[i]
            );
            // //

            invoiceItemInfo[payloadInvoiceItems[i].invoice_item_id] = res.data;
            //
            // //
        }
    } catch (error) {
        return {
            success: false,
            invoiceItemInfo,
        };
    }
    //

    return {
        success: true,
        invoiceItemInfo,
    };
};
