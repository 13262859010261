import { IconButton } from '@mui/material';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { FWMenu, FWMenuItem } from '../../../../Common/FWCustomMenu';
import FWIcon from '../../../../Common/FWIcon';
import { FWPopup } from '../../../../Common/FWPopup';
import {
    ConfirmationColors,
    useConfirmation,
} from '../../../../Common/Hooks/ConfirmationHook';
import {
    IEntityVendorListSummary,
    VendorStatus,
} from '../../Interfaces/VendorsInterface';
import { deleteVendorFromEntity } from '../../Services/VendorsService';

const EntityVendorsListOptionsMenu = ({
    data,
    context,
}: {
    data: IEntityVendorListSummary;
    context: {
        entityName: string;
        entityUid: string;
        latestFtchDashboard: () => void;
    };
}) => {
    const { entityName, entityUid, latestFtchDashboard } = context;
    const [loading, setLoading] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const openMenu = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const [
        confirmationValues,
        updateConfirmationDetails,
        resetConfirmationValues,
    ] = useConfirmation();

    const handleConfirmationToggle = () => {
        resetConfirmationValues();
    };

    const handleToggleVendorLink = (
        vendorUid: string,
        title: string,
        message: string,
        key: string,
        value: VendorStatus,
        color: ConfirmationColors
    ) => {
        handleClose();
        updateConfirmationDetails({
            title,
            message,
            key,
            value,
            data: { vendorUid },
            color,
        });
    };

    const handleUpdateStatus = async () => {
        setLoading(true);
        const statusChanged = await deleteVendorFromEntity(
            entityUid,
            data.vendorUid
        );
        if (statusChanged) {
            latestFtchDashboard();
        } else {
            toast.error(`Failed to remove vendor`);
        }
        handleConfirmationToggle();
        setLoading(false);
    };

    return (
        <>
            <div style={{ margin: '0 auto' }}>
                <IconButton
                    color="primary"
                    ref={(ref) => {
                        if (!ref) return;
                        ref.onclick = (e: any) => {
                            e.stopPropagation();
                            handleClick(e);
                        };
                    }}
                    // disabled={data.vendorStatus !== VendorStatus.ACTIVE}
                >
                    <FWIcon name="bi bi-three-dots" size={24} />
                </IconButton>
            </div>
            <FWMenu anchorEl={anchorEl} open={openMenu} onClose={handleClose}>
                <FWMenuItem
                    sx={{ color: 'error.main' }}
                    onClick={() => {
                        handleToggleVendorLink(
                            data.vendorUid,
                            `Remove vendor from entity`,
                            `This vendor will be removed from future events and POs for ${entityName}`,
                            `vendorStatus`,
                            VendorStatus.INACTIVE,
                            'error'
                        );
                    }}
                >
                    Remove from entity
                </FWMenuItem>
            </FWMenu>

            <FWPopup
                title={confirmationValues.title}
                msg={confirmationValues.message}
                btnColor={confirmationValues.color}
                btnTitle="Confirm"
                open={confirmationValues.open}
                setOpen={handleConfirmationToggle}
                onClickConfirm={handleUpdateStatus}
                btnLoading={loading}
            />
        </>
    );
};

export default EntityVendorsListOptionsMenu;
