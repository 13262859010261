import { createSlice } from '@reduxjs/toolkit';
import { HAConstantData } from '../../Historical/Constants/HAConstants';
import {
    IHistoricalAnalyticsChart,
    IHistoricalAnalytics,
} from '../../Historical/Interfaces/IHistoricalAnalytics.model';

export interface IHAGraphFiltersState {
    [graphId: string]: IHistoricalAnalyticsChart;
}

// initializing with hardcoded data
const mapHAConstantData: (
    HAConstantData: IHistoricalAnalytics[]
) => IHAGraphFiltersState = (HAConstantData: IHistoricalAnalytics[]) => {
    const filters: IHAGraphFiltersState = {};
    HAConstantData.forEach((tab) => {
        tab.charts.forEach((chart) => {
            // filters[chart.graphId] = chart;
            filters[chart.chartId] = chart;
        });
    });
    return filters;
};

const initialState: IHAGraphFiltersState = mapHAConstantData(HAConstantData);

export const HAFilterSlice = createSlice({
    name: 'HAFilterSlice',
    initialState,
    reducers: {
        setHAFilters: (
            state,
            action: { type: string; payload: { data: IHAGraphFiltersState } }
        ) => {
            const { data } = action.payload;
            for (let key in data) {
                state[key] = data[key];
            }
            for (let key in state) {
                if (!data[key]) {
                    delete state[key];
                }
            }
        },
        updateHAFilter: (
            state,
            action: {
                type: string;
                payload: {
                    graphId: string;
                    data: IHistoricalAnalyticsChart;
                };
            }
        ) => {
            const { graphId, data } = action.payload;
            state[graphId] = data;
        },
    },
});

export const { setHAFilters, updateHAFilter } = HAFilterSlice.actions;
export default HAFilterSlice.reducer;
