import { FWNumberFormatter } from '../../Common/Utils/CurrencyUtils';
import { CustomTemplateTypes } from '../../Global/Interfaces/CustomTemplate.interface';
import { ISellerPoTabCount } from '../../PurchaseOrders/Services/PO.service';
import { put, post, del, get } from '../../Utils/api';
import { invoiceItemEffectiveRate } from '../Constants/helperFunctions';
import {
    IInvoice,
    IInvoiceList,
    InvoiceItemStatus,
    IPostInvoiceDetails,
    IPrepayInvoiceDetails,
} from '../Interfaces/Invoice.model';
import { PoPendingResponse } from '../Interfaces/Po_pending';
import {
    INewInvoice,
    INewPostInvoiceDetails,
} from '../Interfaces/NewInvoice.model';
import {
    IBuyerInvoiceDashboardInterface,
    IInvoiceDashboardInterface,
    IPageDetails,
} from '../Interfaces/Shared.model';

export const postAndCreateNewInvoice = async (
    payload: INewPostInvoiceDetails
): Promise<{ invoice_id: string }> => {
    let resp = await post<IPostInvoiceDetails, { invoice_id: string }>(
        `/invoices/create/`,
        payload
    );
    return resp.data;
};

export const postAndCreateNewPrepayInvoice = async (
    payload: any
): Promise<{ invoice_id: string }> => {
    let resp = await post<IPostInvoiceDetails, { invoice_id: string }>(
        `/invoices/prepayment/create/`,
        payload
    );
    return resp.data;
};

export const postAndVerifyTier2Invoice = async (
    payload: INewPostInvoiceDetails,
    invoice_id: string,
    user_type: 'buyer' | 'seller'
): Promise<{ invoice_id: string }> => {
    let resp = await post<INewPostInvoiceDetails, { invoice_id: string }>(
        `/invoices/${user_type}/${invoice_id}/approve/`,
        payload
    );
    return resp.data;
};

export const deleteDraftInvoice = async (invoice_id: string): Promise<void> => {
    await del<void>(`/invoices/${invoice_id}/delete/`);
};

export const deleteDraftPrepayInvoice = async (
    invoice_id: string
): Promise<void> => {
    await del<void>(`invoices/prepayment/${invoice_id}/delete/`);
};

export const putAndUpdateInvoice = async (
    invoice_id: string,
    payload: INewPostInvoiceDetails
): Promise<{ invoice_id: string }> => {
    let resp = await put<INewPostInvoiceDetails, { invoice_id: string }>(
        `/invoices/${invoice_id}/update/`,
        payload
    );
    return resp.data;
};

export const putAndUpdatePrepayInvoice = async (
    invoice_id: string,
    payload: IPostInvoiceDetails
): Promise<{ invoice_id: string }> => {
    let resp = await put<IPostInvoiceDetails, { invoice_id: string }>(
        `/invoices/prepayment/${invoice_id}/update/`,
        payload
    );
    return resp.data;
};

export const getNewInvoiceDetails = async (
    invoice_id: string,
    user_type: 'BUYER' | 'SELLER'
): Promise<INewInvoice> => {
    let resp;
    if (user_type === 'SELLER')
        resp = await get<any>(
            `/invoices/${invoice_id}/?enterprise_type=SELLER`
        );
    else
        resp = await get<any>(`/invoices/${invoice_id}/?enterprise_type=BUYER`);
    let invoice: INewInvoice = resp.data;

    if (invoice.custom_invoice_id === null) {
        invoice.custom_invoice_id = '';
    }

    return invoice;
};

export const getInvoiceDetails = async (
    invoice_id: string,
    user_type: 'BUYER' | 'SELLER'
): Promise<IInvoice> => {
    let resp;
    if (user_type === 'SELLER')
        resp = await get<any>(
            `/invoices/${invoice_id}/?enterprise_type=SELLER`
        );
    else
        resp = await get<any>(`/invoices/${invoice_id}/?enterprise_type=BUYER`);
    let invoice = resp.data;

    if (invoice.custom_invoice_id === null) {
        invoice.custom_invoice_id = '';
    }
    invoice.invoice_items.forEach((item: any) => {
        // some initializations to do when item is termination requested
        if (item.termination_information !== null) {
            if (
                item.termination_information.credit_note_amount === null ||
                item.termination_information.credit_note_amount === ''
            ) {
                item.termination_information.credit_note_amount = '0';
            }
            item.termination_information.paid_termination_quantity = (
                parseFloat(item.termination_information.credit_note_amount) /
                invoiceItemEffectiveRate(
                    item.pricing_information.price,
                    item.pricing_information.shipping_per_unit,
                    item.pricing_information.additional_charges,
                    parseFloat(invoice.pricing_information.discount_percentage)
                )
            ).toFixed(2);
            item.termination_information.unpaid_termination_quantity = (
                parseFloat(item.termination_information.quantity) -
                parseFloat(
                    item.termination_information.paid_termination_quantity
                )
            ).toString();
            // check if item is terminated
            if (item.status === InvoiceItemStatus.TERMINATED) {
                item.fulfilment_information['invoice_termination_rejection'] =
                    item.termination_information === null
                        ? '0'
                        : item.termination_information.status ===
                          'SELLER_ACCEPTED'
                        ? item.termination_information.quantity
                        : '0';
            } else {
                item.fulfilment_information['invoice_termination_rejection'] =
                    '0';
            }
        } else {
            item.fulfilment_information['invoice_termination_rejection'] = '0';
        }
        // for all the values coming as null change them to empty string
        if (item.quantity_information.quantity === null)
            item.quantity_information.quantity = '';
        if (item.batch_number === null) item.batch_number = '';
        if (
            item.pricing_information !== null &&
            item.pricing_information.price === null
        )
            item.pricing_information.price = '';
        if (
            item.pricing_information !== null &&
            item.pricing_information.shipping_per_unit === null
        )
            item.pricing_information.shipping_per_unit = '';
        if (item.notes === null) {
            item.notes = '';
        }
        if (item.batch_expiry_date === null) {
            item.batch_expiry_date = '';
        }
        if (Object.keys(item.custom_fields).length === 0) {
            item.custom_fields = {
                template_id: '',
                template_name: '',
                type: CustomTemplateTypes.INVOICE_BATCH_ITEM_CUSTOM_FIELDS,
                enterprise: '',
                entity: '',
                items: [],
            };
        } else {
            item.custom_fields = {
                template_id: item.custom_fields.template_id,
                template_name: item.custom_fields.template_name,
                type: item.custom_fields.template_type,
                enterprise: '',
                entity: '',
                items: item.custom_fields.template_items.map(
                    (template_item: any) => ({
                        template_item_id: template_item.template_item_id,
                        template_item_name: template_item.template_item_name,
                        template_item_type: template_item.template_item_type,
                        is_required: template_item.is_required,
                        template: item.custom_fields.template_id,
                        value: template_item.value,
                    })
                ),
            };
        }
    });
    if (invoice.notes === null) {
        invoice.notes = '';
    }
    return invoice;
};

export const getPrepaymentInvoiceDetails = async (
    invoice_id: string,
    user_type: 'BUYER' | 'SELLER'
): Promise<IPrepayInvoiceDetails> => {
    let resp;
    if (user_type === 'SELLER')
        resp = await get<IPrepayInvoiceDetails>(
            `/invoices/${invoice_id}/?enterprise_type=SELLER`
        );
    else
        resp = await get<IPrepayInvoiceDetails>(
            `/invoices/${invoice_id}/?enterprise_type=BUYER`
        );
    let invoice = resp.data;

    if (invoice.custom_invoice_id === null) {
        invoice.custom_invoice_id = '';
    }
    invoice.invoice_items.forEach((item) => {
        // some initializations to do when item is termination requested
        // if (item.termination_information !== null) {
        //     if (
        //         item.termination_information.credit_note_amount === null ||
        //         item.termination_information.credit_note_amount === ''
        //     ) {
        //         item.termination_information.credit_note_amount = '0';
        //     }
        //     item.termination_information.paid_termination_quantity = (
        //         parseFloat(item.termination_information.credit_note_amount) /
        //         invoiceItemEffectiveRate(
        //             item.pricing_information.price,
        //             item.pricing_information.shipping_per_unit,
        //             item.pricing_information.additional_charges,
        //             parseFloat(invoice.pricing_information.discount_percentage ?? "0")
        //         )
        //     ).toFixed(2);
        //     item.termination_information.unpaid_termination_quantity = (
        //         parseFloat(item.termination_information.quantity) -
        //         parseFloat(
        //             item.termination_information.paid_termination_quantity
        //         )
        //     ).toString();
        //     // check if item is terminated
        //     // if (item.status === InvoiceItemStatus.TERMINATED) {
        //     //     item.fulfilment_information['invoice_termination_rejection'] =
        //     //         item.termination_information === null
        //     //             ? '0'
        //     //             : item.termination_information.status ===
        //     //               'SELLER_ACCEPTED'
        //     //             ? item.termination_information.quantity
        //     //             : '0';
        //     // } else {
        //     //     item.fulfilment_information['invoice_termination_rejection'] =
        //     //         '0';
        //     // }
        // } else {
        //     item.fulfilment_information['invoice_termination_rejection'] = '0';
        // }
        // for all the values coming as null change them to empty string
        if (item.quantity_information.quantity === null)
            item.quantity_information.quantity = '';
        if (item.batch_number === null) item.batch_number = '';
        if (item.batch_expiry_date === null) {
            item.batch_expiry_date = '';
        }
    });
    if (invoice.notes === null) {
        invoice.notes = '';
    }
    return invoice;
};

export const getAllAuditPrepaymentInvoiceDetails = async (
    po_id: string,
    user_type: 'BUYER' | 'SELLER'
): Promise<IInvoiceList[]> => {
    let resp;
    if (user_type === 'SELLER')
        resp = await get<IInvoiceList[]>(
            `/invoices/?enterprise_type=SELLER&invoice_type=SELLER_PREPAYMENT_INVOICE&purchase_order_id=${po_id}`
        );
    else
        resp = await get<IInvoiceList[]>(
            `/invoices/?enterprise_type=BUYER&invoice_type=SELLER_PREPAYMENT_INVOICE&purchase_order_id=${po_id}`
        );
    let invoices = resp.data;

    invoices.forEach((invoice) => {
        if (invoice.custom_invoice_id === null) {
            invoice.custom_invoice_id = '';
        }
        if (invoice.notes === null) {
            invoice.notes = '';
        }
    });
    return invoices;
};
export interface IBuyerInvoiceTabCount {
    tab_counts: {
        verified: number;
        unverified: number;
        all: number;
    };
}

export interface ISellerInvoiceTabCount {
    tab_counts: {
        draft: number;
        ongoing: number;
        all: number;
    };
}

//Invoice Dashboard Tab Count for Seller Side
export const getInvoiceDashboardTabCount = async (): Promise<{
    draft: number | null;
    ongoing: number | null;
    all: number | null;
    pending_po: number | null;
}> => {
    let data: {
        draft: number | null;
        ongoing: null | number;
        all: null | number;
        pending_po: null | number;
    } = {
        draft: null,
        ongoing: null,
        all: null,
        pending_po: null,
    };
    try {
        let resp = await post<any, ISellerInvoiceTabCount>(
            `/dashboard/tab_counts/`,
            {
                dashboard_view: 'invoice_seller',
            }
        );
        let po_resp = await post<any, ISellerPoTabCount>(
            `/dashboard/tab_counts/`,
            {
                dashboard_view: 'purchase_order_seller',
            }
        );
        data = {
            draft: resp.data.tab_counts.draft,
            ongoing: resp.data.tab_counts.ongoing,
            all: resp.data.tab_counts.all,
            pending_po: +po_resp.data.tab_counts.pending!,
        };
        return data;
    } catch (err) {
        return data;
    }
};

export const getBuyerInvoiceDashboardTabCount = async (): Promise<{
    unverified: number | null;
    verified: number | null;
    all: number | null;
}> => {
    let data: {
        unverified: number | null;
        verified: null | number;
        all: number | null;
    } = {
        unverified: null,
        verified: null,
        all: null,
    };
    try {
        let resp = await post<any, IBuyerInvoiceTabCount>(
            `/dashboard/tab_counts/`,
            {
                dashboard_view: 'invoice_buyer',
            }
        );
        return {
            unverified: resp.data.tab_counts.unverified,
            verified: resp.data.tab_counts.verified,
            all: resp.data.tab_counts.all,
        };
    } catch (err) {
        return data;
    }
};

const getInvoiceDisplayStatusSeller = (invoice: IInvoiceList) => {
    if (
        invoice.invoice_type === 'BUYER_GR_INVOICE' &&
        invoice.approver_information === null
    ) {
        if (invoice.reference_id === null) return 'CONFIRMATION_REQUIRED';
        else return 'INVOICE_REQUIRED';
    } else if (invoice.invoice_type === 'PREPAYMENT_INVOICE') {
        return 'PREPAYMENT';
    } else {
        return invoice.status;
    }
};

const getFormattedData = (
    invoice: IInvoiceList
): IInvoiceDashboardInterface => {
    let currency_code_abbreviation = null;

    if (invoice.pricing_information !== null) {
        currency_code_abbreviation =
            invoice.pricing_information.currency_code_abbreviation;
    }

    return {
        po_id: invoice.purchase_order.purchase_order_id,
        invoice_id: invoice.invoice_id,
        custom_po_id: invoice.purchase_order.custom_purchase_order_id,
        custom_invoice_id:
            invoice.custom_invoice_id === '' ||
            invoice.custom_invoice_id === null
                ? invoice.reference_id === '' || invoice.reference_id === null
                    ? 'NA'
                    : invoice.reference_id
                : invoice.custom_invoice_id,
        buyer_entity_name: invoice.buyer_information.buyer_entity_name,
        seller_entity_name: invoice.seller_information.seller_entity_name,
        seller_entity_id: invoice.seller_entity,
        invoice_item_count: invoice.invoice_item_count,
        invoice_total_amount:
            invoice.pricing_information !== null
                ? FWNumberFormatter(
                      parseFloat(invoice.pricing_information.total),
                      invoice.pricing_information.currency_code_abbreviation
                  )
                : '-',
        expected_delivery_date: invoice.expected_delivery_date ?? 'NA',
        invoice_payment_pending_amount: invoice.amount_pending,
        po_pending_item_count: '',
        po_delivery: '',
        po_next_payment_date: '',
        invoice_in_po_count: '',
        po_status:
            invoice.purchase_order.termination_information !== null &&
            invoice.purchase_order.termination_information.status === 'ACCEPTED'
                ? 'TERMINATED'
                : '',
        invoice_status: getInvoiceDisplayStatusSeller(invoice),
        po_hold_or_termination:
            invoice.purchase_order.termination_information !== null
                ? 'TERMINATION'
                : invoice.purchase_order.hold_information !== null
                ? 'HOLD'
                : null,
        invoice_type: invoice.invoice_type,
        invoice_approver: invoice.approver_information,
        invoice_hold_or_termination: invoice.item_termination
            ? 'TERMINATION'
            : invoice.item_hold_status !== null
            ? 'HOLD'
            : null,
        attachments: invoice.attachments,
        seller_prepayment_invoice_count:
            'seller_prepayment_invoices' in invoice.additional_information
                ? invoice.additional_information.seller_prepayment_invoices!
                      .length
                : 0,
        currency_code_abbreviation,
    };
};

//MrTea: Invoice Payload Interface
export interface IInvoicePayloadInterface {
    dashboard_view: string;
    search_text: string;
    tab: string;
    sort_fields: {
        field: string;
        ascending: boolean;
    }[];
    items_per_page: number;
    page_number: number;
    query_data: {
        seller_entity_list?: string[];
    };
}

export const getInvoiceDashboardRows = async ({
    tab,
    searchText,
    sortFields,
    itemsPerPage,
    pageNumber,
}: {
    tab: string;
    searchText: string;
    sortFields: {
        field: string;
        ascending: boolean;
    }[];
    itemsPerPage: number;
    pageNumber: number;
}): Promise<{
    invoices: IInvoiceDashboardInterface[];
    pageDetails: IPageDetails;
}> => {
    let invoices: IInvoiceDashboardInterface[] = [];
    let page_details = {
        current_page: 1,
        has_next: false,
        has_previous: false,
        total_pages: 1,
    };
    if (tab !== 'pending_PO') {
        const postData: IInvoicePayloadInterface = {
            dashboard_view: 'invoice_seller',
            tab: tab,
            search_text:
                searchText?.length && searchText?.length > 0 ? searchText : '',
            sort_fields: sortFields ?? [],
            items_per_page: itemsPerPage,
            page_number: pageNumber,
            query_data: {},
        };
        let resp = await post<any, any>(`/dashboard/`, postData);
        invoices = [
            ...resp.data.data.map((invoice: IInvoiceList) =>
                getFormattedData(invoice)
            ),
        ];
        page_details = resp.data.metadata;
    } else {
        const postData = {
            dashboard_view: 'purchase_order_seller',
            tab: 'pending',
            search_text:
                searchText?.length && searchText?.length > 0 ? searchText : '',
            sort_fields: sortFields ?? [],
            items_per_page: itemsPerPage,
            page_number: pageNumber,
        };
        let resp = await post<any, any>(`/dashboard/`, postData);
        invoices = resp.data.data.map((po: PoPendingResponse) => ({
            po_id: po.purchase_order_id,
            invoice_id: '',
            custom_po_id: po.custom_purchase_order_id,
            custom_invoice_id: '',
            buyer_entity_name: po.buyer_information.buyer_entity_name,
            seller_entity_id: po.seller_entity,
            seller_entity_name: po.seller_information.seller_entity_name,
            invoice_item_count: '',
            invoice_total_amount: '',
            expected_delivery_date: '',
            invoice_payment_pending_amount: '',
            po_pending_item_count:
                po.additional_information.purchase_order_items_count,
            po_hold_or_termination:
                po.termination_information !== null
                    ? 'TERMINATION'
                    : po.hold_information !== null
                    ? 'HOLD'
                    : null,
            po_delivery: '',
            po_next_payment_date: 'NA',
            invoice_in_po_count: po.invoice_count,
            po_status: po.status,
            invoice_status: '',
            invoice_hold_or_termination: null,
            invoice_type: '',
            invoice_approver: null,
            attachments: [],
            seller_prepayment_invoice_count: 0,
        }));

        page_details = resp.data.metadata;
    }

    return {
        invoices,
        pageDetails: page_details,
    };
};

// export const getInvoiceDashboardRows = async (
//     current_tab: string
// ): Promise<IInvoiceDashboardInterface[]> => {
//     let resp;
//     switch (current_tab) {
//         case 'draft':
//             resp = await get<IInvoiceList[]>(
//                 `/invoices/?enterprise_type=SELLER&invoice_type=SELLER_GOODS_INVOICE&invoice_type=BUYER_GR_INVOICE`
//             );
//             return resp.data
//                 .filter((invoice) => {
//                     return (
//                         (invoice.invoice_type === 'SELLER_GOODS_INVOICE' &&
//                             invoice.status === 'DRAFT') ||
//                         (invoice.invoice_type === 'BUYER_GR_INVOICE' &&
//                             invoice.approver_information === null)
//                     );
//                 })
//                 .map((invoice) => getFormattedData(invoice));
//         case 'ongoing':
//             resp = await get<IInvoiceList[]>(
//                 `/invoices/?enterprise_type=SELLER&invoice_type=SELLER_GOODS_INVOICE&invoice_type=BUYER_GR_INVOICE&invoice_type=PREPAYMENT_INVOICE`
//             );
//             return resp.data
//                 .filter((invoice) => {
//                     return (
//                         (invoice.invoice_type === 'SELLER_GOODS_INVOICE' &&
//                             (invoice.status === 'ISSUED' ||
//                                 invoice.status === 'ONGOING' ||
//                                 invoice.status === 'GOODS_RECEIVED')) ||
//                         (invoice.invoice_type === 'BUYER_GR_INVOICE' &&
//                             invoice.approver_information !== null) ||
//                         (invoice.invoice_type === 'PREPAYMENT_INVOICE' &&
//                             invoice.status !== 'TERMINATED' &&
//                             invoice.status !== 'COMPLETED')
//                     );
//                 })
//                 .map((invoice) => getFormattedData(invoice));
//         case 'all':
//             resp = await get<IInvoiceList[]>(
//                 `/invoices/?enterprise_type=SELLER&invoice_type=SELLER_GOODS_INVOICE&invoice_type=BUYER_GR_INVOICE&invoice_type=PREPAYMENT_INVOICE`
//             );
//             return resp.data.map((invoice) => getFormattedData(invoice));
//         case 'pending_PO':
//             resp = await get<IPOList[]>(`/purchase_orders/invoices/`);
//             return resp.data.map((po) => ({
//                 po_id: po.purchase_order_id,
//                 invoice_id: '',
//                 custom_po_id: po.custom_purchase_order_id,
//                 custom_invoice_id: '',
//                 buyer_entity_name: po.buyer_information.buyer_entity_name,
//                 seller_entity_id: po.seller_entity,
//                 seller_entity_name: po.seller_information.seller_entity_name,
//                 invoice_item_count: '',
//                 invoice_total_amount: '',
//                 expected_delivery_date: '',
//                 invoice_payment_pending_amount: '',
//                 po_pending_item_count: po.purchase_order_item_count,
//                 po_hold_or_termination:
//                     po.termination_information !== null
//                         ? 'TERMINATION'
//                         : po.hold_information !== null
//                         ? 'HOLD'
//                         : null,
//                 po_delivery: '',
//                 po_next_payment_date: 'NA',
//                 invoice_in_po_count: po.invoice_count,
//                 po_status: po.status,
//                 invoice_status: '',
//                 invoice_hold_or_termination: null,
//                 invoice_type: '',
//                 invoice_approver: null,
//                 attachments: [],
//                 seller_prepayment_invoice_count: 0,
//             }));
//         default:
//             return [];
//     }
// };

const getBuyerFormattedData = (
    invoice: IInvoiceList
): IBuyerInvoiceDashboardInterface => {
    let currency_code_abbreviation = null;

    if (invoice.pricing_information !== null) {
        currency_code_abbreviation =
            invoice.pricing_information.currency_code_abbreviation;
    }
    return {
        po_status:
            invoice.purchase_order.termination_information !== null &&
            invoice.purchase_order.termination_information.status === 'ACCEPTED'
                ? 'TERMINATED'
                : '',
        po_id: invoice.purchase_order.purchase_order_id,
        invoice_id: invoice.invoice_id,
        custom_po_id: invoice.purchase_order.custom_purchase_order_id,
        custom_invoice_id: invoice.custom_invoice_id,
        buyer_entity_name: invoice.buyer_information.buyer_entity_name,
        seller_entity_name: invoice.seller_information.seller_entity_name,
        invoice_item_count: invoice.invoice_item_count,
        expected_delivery_date: invoice.expected_delivery_date ?? 'NA',
        invoice_payment_pending_amount: FWNumberFormatter(
            +invoice.amount_pending,
            currency_code_abbreviation
        ),
        invoice_status: getInvoiceDisplayStatusSeller(invoice),
        inovice_item_hold_or_termination: invoice.item_termination
            ? 'TERMINATION'
            : invoice.item_hold_status !== null
            ? 'HOLD'
            : null,
        po_hold_or_termination:
            invoice.purchase_order.termination_information !== null
                ? 'TERMINATION'
                : invoice.purchase_order.hold_information !== null
                ? 'HOLD'
                : null,
        invoice_type: invoice.invoice_type,
        invoice_approver: invoice.approver_information,
        invoice_document_type:
            invoice.reference_id === null ? 'Invoice' : 'Delivery document',
        attachments: invoice.attachments,
        seller_prepayment_invoice_count:
            'seller_prepayment_invoices' in invoice.additional_information
                ? invoice.additional_information.seller_prepayment_invoices!
                      .length
                : 0,
        reference_id: invoice.reference_id,
    };
};

//MrTea: Paginated Dashboard Logic
export const getBuyerInvoiceDashboardRows = async ({
    tab,
    searchText,
    sortFields,
    itemsPerPage,
    pageNumber,
    sellerEntityUuid,
}: {
    tab: string;
    searchText: string;
    sortFields: {
        field: string;
        ascending: boolean;
    }[];
    itemsPerPage: number;
    pageNumber: number;
    sellerEntityUuid?: string;
}): Promise<{
    invoices: IBuyerInvoiceDashboardInterface[];
    pageDetails: IPageDetails;
}> => {
    const postData: IInvoicePayloadInterface = {
        dashboard_view: 'invoice_buyer',
        tab: tab,
        search_text:
            searchText?.length && searchText?.length > 0 ? searchText : '',
        sort_fields: sortFields ?? [],
        items_per_page: itemsPerPage,
        page_number: pageNumber,
        query_data: {
            ...(sellerEntityUuid
                ? { seller_entity_list: [sellerEntityUuid] }
                : {}),
        },
    };
    let resp = await post<any, any>(`/dashboard/`, postData);
    const invoices = [
        ...resp.data.data.map((invoice: IInvoiceList) =>
            getBuyerFormattedData(invoice)
        ),
    ];
    return {
        invoices,
        pageDetails: resp.data.metadata,
    };
};

// export const getBuyerInvoiceDashboardRows = async (
//     current_tab: string
// ): Promise<IBuyerInvoiceDashboardInterface[]> => {
//     let resp;
//     switch (current_tab) {
//         case 'unverified':
//             resp = await get<IInvoiceList[]>(
//                 `/invoices/?enterprise_type=BUYER&invoice_type=BUYER_GR_INVOICE`
//             );
//             return resp.data
//                 .filter((invoice) => invoice.approver_information === null)
//                 .map((invoice) => getBuyerFormattedData(invoice));
//         case 'verified':
//             resp = await get<IInvoiceList[]>(
//                 `/invoices/?enterprise_type=BUYER`
//             );
//             return resp.data
//                 .filter(
//                     (invoice) =>
//                         !(
//                             invoice.approver_information === null &&
//                             invoice.invoice_type === 'BUYER_GR_INVOICE'
//                         ) &&
//                         !(invoice.invoice_type === 'SELLER_PREPAYMENT_INVOICE')
//                 )
//                 .map((invoice) => getBuyerFormattedData(invoice));
//         case 'all':
//             resp = await get<IInvoiceList[]>(
//                 `/invoices/?enterprise_type=BUYER`
//             );
//             return [
//                 ...resp.data.map((invoice) => getBuyerFormattedData(invoice)),
//             ];
//         default:
//             return [];
//     }
// };

export const getAllInvoicesForAPO = async (
    po_id: string,
    user_type: 'BUYER' | 'SELLER'
): Promise<IInvoiceList[]> => {
    let resp = await get<IInvoiceList[]>(
        `/invoices/?enterprise_type=${user_type}&purchase_order_id=${po_id}`
    );
    return resp.data;
};

export const acceptInvoiceItemTerminationRequest = async (
    invoice_item_id: string
): Promise<any> => {
    let data: IPutTerminationRequest = {
        status: 'SELLER_ACCEPTED',
    };
    let resp = await put<IPutTerminationRequest, any>(
        `/invoices/items/${invoice_item_id}/terminate/`,
        data
    );
    return resp.data;
};

export const rescindSellerInvoice = async (
    invoice_id: string
): Promise<any> => {
    let req = {
        status: 'RESCINDED',
        enterprise_type: 'SELLER',
    };
    return await put(
        `/invoices/${invoice_id}/state/?enterprise_type=SELLER`,
        req
    );
};

interface IPutInvoiceItemHold {
    hold: boolean;
    notes?: string;
}

export const createOrUpdateInvoiceItemHold = async (
    invoice_item_id: string,
    notes: string
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        let data: IPutInvoiceItemHold = {
            hold: true,
            notes: notes.trim(),
        };
        try {
            let resp = await put<IPutInvoiceItemHold, any>(
                `/invoices/items/${invoice_item_id}/hold/`,
                data
            );
            resolve(resp.data);
        } catch (err) {
            reject(err);
        }
    });
};

export const removeInvoiceItemHold = async (
    invoice_item_id: string
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        let data: IPutInvoiceItemHold = {
            hold: false,
        };
        try {
            let resp = await put<IPutInvoiceItemHold, any>(
                `/invoices/items/${invoice_item_id}/hold/`,
                data
            );
            resolve(resp.data);
        } catch (err) {
            reject(err);
        }
    });
};

interface IPutTerminationRequest {
    status: 'BUYER_REQUESTED' | 'SELLER_ACCEPTED' | 'REVOKED';
    notes?: string;
    quantity?: number;
    credit_note_amount?: number;
}

export const createOrUpdateInvoiceItemTerminationRequest = async (
    invoice_item_id: string,
    total_termination_quantity: number,
    credit_note_amount: number,
    comment: string
): Promise<any> => {
    let trimmedNotes = comment.trim();
    let data: IPutTerminationRequest = {
        status: 'BUYER_REQUESTED',
        quantity: +total_termination_quantity.toFixed(2),
        credit_note_amount: +credit_note_amount.toFixed(2),
        notes: trimmedNotes,
    };
    let resp = await put<IPutTerminationRequest, any>(
        `/invoices/items/${invoice_item_id}/terminate/`,
        data
    );
    return resp.data;
};

export enum PutNewTerminationActions {
    INVOICE_ITEM_TERMINATION_NA = 'INVOICE_ITEM_TERMINATION_NA',
    INVOICE_ITEM_TERMINATION_BUYER_REQUEST = 'INVOICE_ITEM_TERMINATION_BUYER_REQUEST',
    INVOICE_ITEM_TERMINATION_BUYER_EDIT = 'INVOICE_ITEM_TERMINATION_BUYER_EDIT',
    INVOICE_ITEM_TERMINATION_SELLER_ACCEPT = 'INVOICE_ITEM_TERMINATION_SELLER_ACCEPT',
    INVOICE_ITEM_TERMINATION_REVOKE = 'INVOICE_ITEM_TERMINATION_REVOKE',
}

interface IPutNewTerminationRequest {
    action: PutNewTerminationActions;
    quantity?: number;
    notes?: string;
    credit_note_amount?: number;
}

export const createOrUpdateNewInvoiceItemTerminationRequest = async (
    invoice_item_id: string,
    action: PutNewTerminationActions,
    batch_items?: { invoice_item_batch_id: string; quantity: number }[],
    credit_note_amount?: number,
    notes?: string
): Promise<any> => {
    let payload: IPutNewTerminationRequest = {
        action: action,
        ...(batch_items && {
            batch_items,
        }),
        ...(credit_note_amount && {
            credit_note_amount: +credit_note_amount.toFixed(2),
        }),
        ...(notes && { notes: notes.trim() }),
    };

    let resp = await put(
        `/invoices/items/${invoice_item_id}/terminate/`,
        payload
    );
    return resp.data;
};

export const cancelInvoiceItemTerminationRequest = async (
    invoice_item_id: string
): Promise<any> => {
    let data: IPutTerminationRequest = {
        status: 'REVOKED',
    };
    let resp = await put<IPutTerminationRequest, any>(
        `/invoices/items/${invoice_item_id}/terminate/`,
        data
    );
    return resp.data;
};
