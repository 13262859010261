import { Grid, IconButton, InputLabel } from '@mui/material';
import { GridApi, RowNode } from 'ag-grid-community';
import { isEmpty } from 'lodash';
import { useContext, useEffect, useMemo, useState } from 'react';
import { CustomFormControl } from '../../../../Common/FWCustomInputs';
import { FWMenuItem } from '../../../../Common/FWCustomMenu';
import { FWTooltip } from '../../../../Common/FWCustomTooltip';
import FWDataGrid from '../../../../Common/FWDataGrid';
import FWIcon from '../../../../Common/FWIcon';
import { FWInput } from '../../../../Common/FWInput';
import FWSelect from '../../../../Common/FWSelect';
import { FWTypography } from '../../../../Common/FWTypograhy';
import { HookStateValue } from '../../../../Common/Hooks/StateHook';
import { AuthContext } from '../../../../Contexts/AuthContext';
import { VerificationStatus } from '../../../../Redux/Slices/VerificationSlice';
import { identificationsListColumns } from '../../Constants/IdentificationsConstants';
import { useEntityIdentificationList } from '../../Hooks/IdentificationHook';
import {
    IIdentificationDetail,
    IdentificationState,
} from '../../Interfaces/IdentificationInterface';
import { ItemStatus } from '../../Interfaces/ItemDirectoryInterface';
import IdentificationCreatePopup from '../../Pages/Identifications/IdentificationCreatePopup';
import IdentificationDetailPopup from '../../Pages/Identifications/IdentificationDetailsPopup';
import { IIdentificationUpdateValues } from '../../Pages/Identifications/IdentificationsListOptionsMenu';

export enum IdentificationEmitActions {
    CANCEL = 'CANCEL',
    SUCCESS = 'SUCCESS',
}

interface IIdentificationsProps {
    entityId: string;
    verificationStatus: VerificationStatus;
}

const IdentificationsListContainer = ({
    entityId,
    verificationStatus,
}: IIdentificationsProps) => {
    const {
        identificationList,
        hookState,
        refreshList,
        updateIdentificationList,
    } = useEntityIdentificationList(entityId);
    const [search, setSearch] = useState('');

    const [createPopupToggle, setCreatePopupToggle] = useState<boolean>(false);

    const [detailPopupToggle, setDetailPopupToggle] = useState<boolean>(false);
    const [identificationId, setIdentificationId] = useState<string>('');

    const [filterBy, setFilterBy] = useState<ItemStatus>(ItemStatus.ACTIVE);

    const [gridApi, setGridApi] = useState<GridApi | undefined>();
    // const [columnApi, setColumnApi] = useState<ColumnApi | undefined>();
    const { authData } = useContext(AuthContext);

    useEffect(() => {
        if (gridApi) {
            gridApi.onFilterChanged();
            gridApi.refreshCells({
                force: true,
                suppressFlash: true,
            });
        }
    }, [filterBy, gridApi, identificationList]);

    const rowClickHandler = ({ data }: { data: IIdentificationDetail }) => {
        if (data.status === IdentificationState.ACTIVE) {
            setDetailPopupToggle(!detailPopupToggle);
            setIdentificationId(data.id);
        }
    };

    const handleCreatePopupToggle = (response?: IdentificationEmitActions) => {
        setCreatePopupToggle(!createPopupToggle);
        if (response === IdentificationEmitActions.SUCCESS) refreshList();
    };

    const handleDetailPopupToggle = (response?: IdentificationEmitActions) => {
        setDetailPopupToggle(!detailPopupToggle);
        setIdentificationId('');
        if (response === IdentificationEmitActions.SUCCESS) refreshList();
    };

    const handleItemEmitAction = (data: IIdentificationUpdateValues) => {
        updateIdentificationList(data);
    };

    const handleFilterChange = (e: any) => {
        setFilterBy(e.target.value);
    };

    const isExternalFilterPresent = (): boolean => filterBy !== ItemStatus.ALL;

    const doesExternalFilterPass = (node: RowNode): boolean =>
        node.data.status === filterBy;

    const verifiedDefaultIdentifications: IIdentificationDetail[] =
        useMemo(() => {
            return identificationList.filter(
                (identification) =>
                    // identification.identificationStatus ===
                    //     IdentificationStatus.VERIFIED &&
                    identification.isDefault &&
                    identification.status === IdentificationState.ACTIVE
            );
        }, [identificationList]);

    const canEditDefault: boolean = useMemo(
        () => verifiedDefaultIdentifications.length > 1,
        [verifiedDefaultIdentifications]
    );

    const identificationNamesList: string[] = useMemo(
        () =>
            identificationList.map((identification) =>
                identification.name.toLowerCase()
            ),
        [identificationList]
    );

    // useEffect(() => {
    //     if (verificationStatus !== VerificationStatus.VERIFIED && columnApi) {
    //         columnApi.setColumnVisible('options', false);
    //     } else if (
    //         columnApi &&
    //         verificationStatus === VerificationStatus.VERIFIED
    //     ) {
    //         columnApi.setColumnVisible('options', true);
    //     }
    // }, [verificationStatus, columnApi]);

    return (
        <>
            <Grid container className="flex flex--jcsb flex--aic mb--20 mt--10">
                <div className="flex flex--aic">
                    <FWTypography variant="h4">
                        Company Identifications
                    </FWTypography>
                </div>
            </Grid>
            <Grid container className="flex flex--jcsb flex--aic mb--10">
                <div className="flex flex--aic">
                    <CustomFormControl>
                        <InputLabel id="item-directory-filter-label">
                            Filter by:
                        </InputLabel>
                        <FWSelect
                            labelId="item-directory-filter-label"
                            id="item-directory-filter"
                            label="Filter By:"
                            style={{ width: '150px' }}
                            onChange={handleFilterChange}
                            value={filterBy}
                        >
                            <FWMenuItem value={IdentificationState.ALL}>
                                All
                            </FWMenuItem>
                            <FWMenuItem value={IdentificationState.ACTIVE}>
                                Active
                            </FWMenuItem>
                            <FWMenuItem value={IdentificationState.INACTIVE}>
                                Disabled
                            </FWMenuItem>
                        </FWSelect>
                    </CustomFormControl>
                </div>
                <div className="flex flex--aic">
                    <FWInput
                        value={search}
                        onChange={(e: any) => setSearch(e.target.value)}
                        placeholder={'Search'}
                        iconStart={<i className="bi bi-search" />}
                        style={{ width: 400, marginRight: '5px' }}
                    />

                    {authData.details?.role === 'ADMIN' && (
                        <FWTooltip title="Add identification">
                            <div>
                                <IconButton
                                    color="primary"
                                    onClick={() => handleCreatePopupToggle()}
                                >
                                    <FWIcon
                                        name="bi bi-plus-circle"
                                        size={24}
                                    />
                                </IconButton>
                            </div>
                        </FWTooltip>
                    )}

                    {/* <FWButton variant="contained" style={{ marginLeft: 10 }}>
                        Add Identification
                    </FWButton> */}
                    <IdentificationCreatePopup
                        popupToggle={createPopupToggle}
                        emitPopupToggle={handleCreatePopupToggle}
                        entityId={entityId}
                        canEditDefault={
                            identificationList.length === 0 ? false : true
                            // : verifiedDefaultIdentifications.length > 0
                            // ? true
                            // : false
                        }
                        isFirstIdentification={
                            verifiedDefaultIdentifications.length === 0
                        }
                        identificationNamesList={identificationNamesList}
                    />
                </div>
            </Grid>
            <Grid container>
                <Grid item xs={12}>
                    <FWDataGrid
                        rows={
                            hookState.state === HookStateValue.LOADING
                                ? null
                                : identificationList
                        }
                        columns={identificationsListColumns(
                            authData.details?.role === 'ADMIN'
                        )}
                        onRowClicked={rowClickHandler}
                        getRowClass={(params) =>
                            params.data.status === IdentificationState.INACTIVE
                                ? 'disabled'
                                : ''
                        }
                        doesExternalFilterPass={doesExternalFilterPass}
                        isExternalFilterPresent={isExternalFilterPresent}
                        onReady={(event) => {
                            setGridApi(event.api);
                            // setColumnApi(event.columnApi);
                        }}
                        context={{
                            canEditDefault,
                            handleItemEmitAction,
                        }}
                        pagination={false}
                        search={search}
                    />
                </Grid>
                {!isEmpty(identificationId) && (
                    <IdentificationDetailPopup
                        popupToggle={detailPopupToggle}
                        emitPopupToggle={handleDetailPopupToggle}
                        entityId={entityId}
                        identificationId={identificationId}
                        canEditDefault={canEditDefault}
                        isVerified={
                            verificationStatus === VerificationStatus.VERIFIED
                        }
                    />
                )}
            </Grid>
        </>
    );
};

export default IdentificationsListContainer;
