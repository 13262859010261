import { Divider, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useRef, useState } from 'react';
import { Blocks } from '../../Interfaces/HelpInformationInterface';
import HelpSectionImage from './HelpSectionImage';
import { StyledBox, StyledGradient } from './ReadMoreStyle';

const BlocksDataComponent = ({ question, answer, image }: Blocks) => {
    const [viewMore, setViewMore] = useState(false);
    const [clientHeight, setClientHeight] = useState(0);
    const elemRef = useRef<HTMLDivElement>();

    const innerElemRef = useRef<HTMLDivElement>();

    useEffect(() => {
        if (elemRef.current && clientHeight > 125) {
            if (viewMore) {
                elemRef.current.style.height = 'auto';
            } else {
                elemRef.current.style.height = '125px';
            }
        } else {
        }
    }, [viewMore, clientHeight]);

    useEffect(() => {
        innerElemRef.current?.clientHeight &&
            setClientHeight(innerElemRef.current?.clientHeight);
    }, [innerElemRef.current?.clientHeight]);

    return (
        <>
            <Box marginBottom={2}>
                <StyledBox ref={elemRef}>
                    <Box ref={innerElemRef}>
                        <Typography fontWeight={500} marginBottom={0.5}>
                            {question}
                        </Typography>
                        <Typography color={'text.secondary'}>
                            {answer}
                        </Typography>
                        <HelpSectionImage image={image} />
                    </Box>
                </StyledBox>
                {clientHeight > 125 && (
                    <StyledGradient>
                        {!viewMore && <div className="gradient"></div>}
                        <Typography
                            position={'relative'}
                            textAlign={'right'}
                            color={'primary.main'}
                            fontSize={12}
                            onClick={(e) => {
                                e.stopPropagation();
                                setViewMore((prev) => !prev);
                            }}
                            style={{ cursor: 'pointer' }}
                        >
                            {viewMore ? 'Show Less' : 'Read more'}
                        </Typography>
                    </StyledGradient>
                )}
            </Box>
            <Box marginBottom={2} position={'relative'}>
                <Divider />
            </Box>
        </>
    );
};

export default BlocksDataComponent;
