import { dateFilterOptions } from '../Constants/HAFilterConstants';
import {
    ChartMetricDisplayModifier,
    SingleFilter,
} from '../Interfaces/IHistoricalAnalytics.model';
import {
    CurrencyDetails,
    DropdownDataDump,
} from '../Interfaces/IHistoricalAnalyticsDropdowns.model';

export const graphMaxVal = (array: any[]) => {
    const filteredArray = array.filter((val) => typeof val === 'number');
    return Math.max(...filteredArray) * 1.2;
};

export const constructSummaryString = (
    filters: SingleFilter[],
    dropdownDataDump: DropdownDataDump
) => {
    let summaryString = '';
    filters.forEach((filter) => {
        if (filter.filterApplied) {
            if (filter.filterType === 'multiselectDropdown') {
                if (filter.values.length > 0) {
                    summaryString += `${
                        filter.filterLabel
                    } includes ${filter.values
                        .map(
                            (option) =>
                                filter.dropdownId &&
                                (dropdownDataDump[
                                    filter.dropdownId
                                ].options.find((val) => val.id === option)
                                    ?.name ??
                                    '')
                        )
                        .join(', ')}; `;
                }
            } else if (filter.filterType === 'date') {
                if (filter.dateRange !== 'custom') {
                    summaryString += `${filter.filterLabel}: ${
                        dateFilterOptions.find(
                            (val) => val.id === filter.dateRange
                        )?.label ?? ''
                    }; `;
                } else {
                    summaryString += `${filter.filterLabel}: ${filter.customRange.startDate} - ${filter.customRange.endDate}; `;
                }
            }
            // TODO: Add string for other filter types
        }
    });

    return summaryString;
};

export const getChartTickLabel = (
    val: string,
    displayType: ChartMetricDisplayModifier,
    targetCurrency: CurrencyDetails | null
): string => {
    const currencySymbol = targetCurrency ? targetCurrency.currencySymbol : '$';
    switch (displayType) {
        case 'percentage':
            return `${val}%`;
        case 'currency':
            return `${currencySymbol}${val}`;
        case 'count':
            return `${val}`;
        case 'none':
            return `${val}`;
        default:
            return '';
    }
};

export const getStepSize = (
    maxNum: number,
    NUM_TICKS: number,
    modifier?: 'NONE' | 'PERCENTAGE' | 'COUNT' | 'DECIMAL'
) => {
    const maxNumWithGrace = 1.1 * maxNum;
    if (modifier === 'PERCENTAGE') {
        return 100 / NUM_TICKS;
    }
    const initStepSize = maxNumWithGrace / NUM_TICKS;

    const stepSizeScientific = initStepSize.toExponential(10);
    let [coefficient, exponent] = stepSizeScientific
        .split('e')
        .map((item) => Number(item));

    if (modifier === 'DECIMAL') {
        coefficient *= 10;
        exponent -= 1;
    }

    while (exponent < 0) {
        coefficient /= 10;
        exponent += 1;
    }

    let coefficientRounded = Math.ceil(coefficient);

    let stepSize = coefficientRounded * Math.pow(10, exponent);
    if (stepSize * NUM_TICKS > maxNumWithGrace * (4 / 3)) {
        let newStepSize = stepSize * 0.75;
        if (
            !(modifier === 'COUNT' && newStepSize !== +newStepSize.toFixed(0))
        ) {
            stepSize = newStepSize;
        }
    }

    return stepSize;
};

export const numberToAbbreviation = (
    max: number,
    num: (number | null)[],
    format: 'USD' | 'INR'
) => {
    let currencyUnit: string | null = null;
    const abbrevitedNumberArr: (number | null)[] = num.map((val, i) => {
        if (val !== null && val !== undefined) {
            if (format === 'USD') {
                if (max < 1000) {
                    if (!currencyUnit) currencyUnit = 'hundereds';
                    return val;
                } else if (max < 1000000) {
                    if (!currencyUnit) currencyUnit = 'thousands';
                    return +(val / 1000).toFixed(2);
                } else if (max < 1000000000) {
                    if (!currencyUnit) currencyUnit = 'millions';
                    return +(val / 1000000).toFixed(2);
                } else if (max < 1000000000000) {
                    if (!currencyUnit) currencyUnit = 'billions';
                    return +(val / 1000000000).toFixed(2);
                } else {
                    if (!currencyUnit) currencyUnit = 'trillions';
                    return +(val / 1000000000000).toFixed(2);
                }
            } else if (format === 'INR') {
                if (max < 1000) {
                    if (!currencyUnit) currencyUnit = 'hundereds';
                    return val;
                }
                else if (max < 100000) {
                    if (!currencyUnit) currencyUnit = 'thousands';
                    return +(val / 1000).toFixed(2);
                } else if (max < 10000000) {
                    if (!currencyUnit) currencyUnit = 'lakhs';
                    return +(val / 100000).toFixed(2);
                } else {
                    if (!currencyUnit) currencyUnit = 'crores';
                    return +(val / 10000000).toFixed(2);
                }
            } else {
                return val;
            }
        }
        return val;
    });
    return { abbrevitedNumberArr, currencyUnit };
};
