import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Checkbox,
    Chip,
    FormControlLabel,
    IconButton,
    Typography,
} from '@mui/material';
import { GridSelectionModel } from '@mui/x-data-grid';
import { ColDef } from 'ag-grid-community';
import { debounce } from 'lodash';
import {
    Fragment,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { FWTooltip } from '../../../Common/FWCustomTooltip';
import FWDataGrid from '../../../Common/FWDataGrid';
import { FWLink } from '../../../Common/FWLink';
import { FWTypography } from '../../../Common/FWTypograhy';
import { VendorAnalyticsDetailsType } from '../../../Events/Components/Buyer/ItemVendorDetails';
import ManageVendorContacts from '../../../Events/Components/Buyer/ManageVendorContacts';
import VendorAutoComplete from '../../../Events/Components/Buyer/VendorAutocomplete';
import VendorLinkedInPopup from '../../../Events/Components/Buyer/VendorLinkedInPopup';
import {
    VendorContactCell,
    VendorTagsCell,
    vendorPreferredForItem,
} from '../../../Events/Constants/Buyer/columns';
import { vendorAndItemIconMap } from '../../../Events/FILETODELETE';
import useSearchForVendors from '../../../Events/Hooks/Buyer/useSearchForVendors';
import { IVendorForEventItem } from '../../../Events/Interfaces/Buyer/RFQ.model';
import { Styledi } from '../../../Events/Shared/SharedStyle';
import SignupPendingIcon from '../../../Global/Components/SignupPendingIcon';
import VendorVerificationStatusIcon from '../../../Global/Components/VendorVerificationStatusIcon';
import { runVendorSearch } from '../../../Organizations/Admin/Services/VendorsService';
import { VerificationStatus } from '../../../Redux/Slices/VerificationSlice';
import { IGlobalAppStore } from '../../../Redux/Store';
import { ItemCartAccessContext } from '../../Pages/ItemCartAccess';
import { CartItemAccordionContext } from './CartItemAccordion';

export default function CartItemVendorInformation() {
    const {
        cartItemId,
        itemInCart,
        // updateItemData,
        enterpriseItemDetails,
        saveVendorContacts,
        saveVendorDetails,
        errors,
        allItemVendors,
        addNewNonPrefferedVendorToList,
    } = useContext(CartItemAccordionContext);

    useEffect(() => {}, [allItemVendors, enterpriseItemDetails?.itemName]);

    const [open, setOpen] = useState(false);

    const { buyerEntityId, templateDetails } = useContext(
        ItemCartAccessContext
    );

    const selectedVendorRowIDs = useSelector((state: IGlobalAppStore) =>
        itemInCart
            ? state.ItemCartStore.cartItems[cartItemId].seller_entities
            : state.ItemCartStore.itemsSavedForLater[cartItemId].seller_entities
    );

    const [openAllContactsForVendor, setOpenAllContactsForVendor] = useState<{
        open: HTMLAnchorElement | null;
        seller_id: string;
    } | null>(null);

    const editVendorContacts = (
        seller_id: string,
        active_element: HTMLAnchorElement | null
    ) => {
        setOpenAllContactsForVendor({
            open: active_element,
            seller_id: seller_id,
        });
    };

    const TOTAL_NO_OF_SELECTED_VENDORS =
        selectedVendorRowIDs == null ? 0 : selectedVendorRowIDs.length;

    const { allVendorsFromSearch, searchForVendors } =
        useSearchForVendors(buyerEntityId);

    const debounceSearch = useMemo(
        () =>
            debounce(async (searchText) => {
                await searchForVendors(searchText, true);
            }, 500),
        [searchForVendors]
    );

    const onInviteNewVendorSuccess = useCallback(
        (vendorCode): Promise<void> => {
            return new Promise(async (resolve, reject) => {
                try {
                    let vendorHits = await runVendorSearch({
                        searchByQuery: {
                            entityId: buyerEntityId,
                            query: vendorCode,
                        },
                    });
                    addNewNonPrefferedVendorToList(vendorHits[0]);
                    resolve();
                } catch {
                    toast.error('Failed to add vendor!');
                    reject();
                }
            });
        },
        [addNewNonPrefferedVendorToList, buyerEntityId]
    );

    const [vendorAnalyticsDetails, setVendorAnalyticsDetails] =
        useState<VendorAnalyticsDetailsType | null>(null);

    const openVendorAnalyticsPopup = (vendor: VendorAnalyticsDetailsType) => {
        setVendorAnalyticsDetails(vendor);
    };
    const closeVendorAnalyticsPopup = () => {
        setVendorAnalyticsDetails(null);
    };

    const pricing_information = useSelector((state: IGlobalAppStore) =>
        itemInCart
            ? state.ItemCartStore.cartItems[cartItemId].pricing_information
            : state.ItemCartStore.itemsSavedForLater[cartItemId]
                  .pricing_information
    );

    const itemInformation = useSelector((state: IGlobalAppStore) =>
        itemInCart
            ? state.ItemCartStore.cartItems[cartItemId].item_information
            : state.ItemCartStore.itemsSavedForLater[cartItemId]
                  .item_information
    );

    const measurement_unit = useMemo(() => {
        return enterpriseItemDetails?.measurementUnits[0] ?? null;
    }, [enterpriseItemDetails?.measurementUnits]);

    const vendorSummaryValue = useMemo(() => {
        const vendorDetails = allItemVendors;

        if (
            vendorDetails === null ||
            vendorDetails.length === 0 ||
            selectedVendorRowIDs === null
        ) {
            return null;
        } else {
            const vendorSummaryValue: {
                name: string;
                is_preferred: boolean;
                is_virtual: boolean;
                verification_status: VerificationStatus;
                seller_entity_id: string;
            }[] = [];
            selectedVendorRowIDs?.forEach((vendor_row_id: any, idx) => {
                const foundVendorDetails = vendorDetails.find(
                    (v) => v.id === vendor_row_id
                );

                if (foundVendorDetails) {
                    vendorSummaryValue.push({
                        name: foundVendorDetails.seller_entity.entity_name,
                        is_preferred: foundVendorDetails.status === 'PREFERRED',
                        is_virtual: foundVendorDetails.seller_entity.is_virtual,
                        verification_status:
                            foundVendorDetails.seller_entity
                                .verification_status,
                        seller_entity_id:
                            foundVendorDetails.seller_entity.entity_id,
                    });
                }
            });

            return vendorSummaryValue;
        }
    }, [allItemVendors, selectedVendorRowIDs]);

    return (
        <>
            <Accordion
                expanded={open}
                onChange={() => setOpen((prev) => !prev)}
                sx={{
                    backgroundColor: 'transparent',
                    '&:before': {
                        display: 'none',
                    },
                }}
                disableGutters
            >
                <AccordionSummary
                    sx={{ padding: '0px', margin: '0 0.5rem 0 0.5rem' }}
                >
                    <IconButton size="small" color="primary">
                        {open ? (
                            <i className="bi bi-chevron-down"></i>
                        ) : (
                            <i className="bi bi-chevron-right"></i>
                        )}
                    </IconButton>
                    &nbsp;
                    <Typography
                        variant="h4"
                        fontWeight={500}
                        color={
                            'seller_entities' in errors
                                ? 'error.main'
                                : 'text.primary'
                        }
                    >
                        {TOTAL_NO_OF_SELECTED_VENDORS > 0
                            ? TOTAL_NO_OF_SELECTED_VENDORS === 1
                                ? '1 Vendor'
                                : `${TOTAL_NO_OF_SELECTED_VENDORS} Vendors`
                            : templateDetails.sections.VENDOR_INFORMATION.label}
                    </Typography>
                    &nbsp;&nbsp;
                    {open ? (
                        <Box className="flex flex--aic" flexWrap={'wrap'}>
                            {TOTAL_NO_OF_SELECTED_VENDORS &&
                                selectedVendorRowIDs &&
                                TOTAL_NO_OF_SELECTED_VENDORS > 0 &&
                                selectedVendorRowIDs.map(
                                    (vendor_row_id, idx) => (
                                        <Chip
                                            size="small"
                                            key={idx}
                                            label={
                                                allItemVendors?.find(
                                                    (v) =>
                                                        v.seller_entity
                                                            .entity_id ===
                                                        vendor_row_id
                                                )?.seller_entity.entity_name ??
                                                ''
                                            }
                                            sx={{
                                                color: 'text.secondary',
                                                mr: '12px',
                                            }}
                                            onDelete={() => {
                                                let newArr = [
                                                    ...(selectedVendorRowIDs ??
                                                        []),
                                                ];
                                                newArr.splice(idx, 1);

                                                saveVendorDetails(newArr);
                                            }}
                                        />
                                    )
                                )}
                        </Box>
                    ) : (
                        <Box
                            width={'auto'}
                            className="flex flex--aic"
                            flexWrap={'wrap'}
                        >
                            {' '}
                            {vendorSummaryValue &&
                                vendorSummaryValue.map((vendor, index) => (
                                    <Typography
                                        sx={{
                                            whiteSpace: 'pre-wrap',
                                            wordWrap: 'break-word',
                                        }}
                                        color="text.secondary"
                                        key={index}
                                    >
                                        {vendor.name}
                                        &nbsp;
                                        {vendor.is_preferred && (
                                            <Styledi className="bi bi-check-circle-fill" />
                                        )}
                                        {index !==
                                        vendorSummaryValue!.length - 1 ? (
                                            <>&nbsp;|&nbsp;</>
                                        ) : (
                                            ''
                                        )}
                                    </Typography>
                                ))}
                        </Box>
                    )}
                </AccordionSummary>
                <AccordionDetails>
                    <Box>
                        <VendorAutoComplete
                            allVendorsFromSearch={allVendorsFromSearch}
                            debounceSearch={debounceSearch}
                            addedVendorsUid={allItemVendors.map(
                                (vendor) => vendor.seller_entity.entity_id
                            )} // ids of all vendors
                            addVendorToList={addNewNonPrefferedVendorToList}
                            onInviteNewVendorSuccess={onInviteNewVendorSuccess}
                            disableText={''}
                        />
                        <br />
                        {
                            <>
                                <FWDataGrid
                                    rows={allItemVendors}
                                    columns={CartItemVendorColumns(
                                        saveVendorDetails,
                                        allItemVendors,
                                        saveVendorDetails,
                                        selectedVendorRowIDs ?? [],
                                        openVendorAnalyticsPopup
                                    )}
                                    // onRowSelect={saveVendorDetails}
                                    selectedRows={selectedVendorRowIDs ?? []}
                                    context={{
                                        editVendorContacts,
                                    }}
                                    // onReady={(event) => {
                                    //     setGridApi(event.api);
                                    // }}
                                    rowSelection={'multiple'}
                                    pagination={
                                        TOTAL_NO_OF_SELECTED_VENDORS > 10
                                    }
                                />
                            </>
                        }
                    </Box>
                </AccordionDetails>
            </Accordion>
            {vendorAnalyticsDetails && (
                <VendorLinkedInPopup
                    sellerEntityName={vendorAnalyticsDetails.sellerEntityName}
                    open={open}
                    handleClose={closeVendorAnalyticsPopup}
                    currencyCodeAbbreviation={
                        pricing_information.currency_code_abbreviation
                    }
                    currencyCodeSymbol={pricing_information.currency_symbol}
                    currencyCodeUuid={pricing_information.currency_code_id}
                    enterpriseItemUuid={itemInformation.item_id}
                    itemName={itemInformation.item_name}
                    measurementUnitName={measurement_unit?.name ?? ''}
                    measurementUnitUuid={measurement_unit?.id ?? ''}
                    sellerEntityUuid={vendorAnalyticsDetails.sellerEntityUuid}
                />
            )}
            <ManageVendorContacts
                anchorEl={openAllContactsForVendor?.open ?? null}
                handleClose={() => setOpenAllContactsForVendor(null)}
                entity_id={buyerEntityId}
                seller_id={openAllContactsForVendor?.seller_id ?? ''}
                enterpriseItemUuid={itemInformation.item_id}
                vendorName={
                    allItemVendors?.find((vendor) => {
                        return (
                            vendor.seller_entity.entity_id ===
                            openAllContactsForVendor?.seller_id
                        );
                    })?.seller_entity.entity_name ?? ''
                }
                selectedVendorContacts={
                    allItemVendors
                        ?.find((vendor) => {
                            return (
                                vendor.seller_entity.entity_id ===
                                openAllContactsForVendor?.seller_id
                            );
                        })
                        ?.seller_contacts?.map((contact) => {
                            return contact.vendor_contact_id;
                        }) ?? []
                }
                updateVendorContacts={saveVendorContacts}
            />
        </>
    );
}

type vendorTableRowParam = {
    data: IVendorForEventItem;
    context: {
        editVendorContacts: (
            vendor_id: string,
            active_element: HTMLAnchorElement | null
        ) => void;
    };
    value: any;
};

const CartItemVendorColumns = (
    updateVendorRowSelectrion: (row_ids: GridSelectionModel) => void,
    vendorDetails: IVendorForEventItem[],
    saveVendorDetails: (row_ids: GridSelectionModel) => void,
    selectedVendorRowIDs: string[],
    openVendorAnalyticsPopup: (
        vendorDetails: VendorAnalyticsDetailsType
    ) => void
): ColDef[] => {
    return [
        {
            field: 'id',
            headerName: 'No.',
            width: 120,
            headerComponent: (params: { data: IVendorForEventItem }) => {
                const totalNumberOfVendorINTable =
                    vendorDetails?.length ?? null;

                return (
                    <>
                        {' '}
                        <Box marginLeft={'11px'}>
                            <FormControlLabel
                                label="No."
                                control={
                                    <Checkbox
                                        checked={
                                            totalNumberOfVendorINTable ===
                                            selectedVendorRowIDs?.length
                                        }
                                        onChange={(e) => {
                                            if (vendorDetails) {
                                                if (e.target.checked) {
                                                    saveVendorDetails(
                                                        vendorDetails
                                                            .map((v) => v.id)
                                                            .filter(
                                                                (v) =>
                                                                    v !==
                                                                    undefined
                                                            ) as string[]
                                                    );
                                                } else {
                                                    saveVendorDetails([]);
                                                }
                                            }
                                        }}
                                        size="small"
                                        color={
                                            totalNumberOfVendorINTable !==
                                            selectedVendorRowIDs.length
                                                ? 'secondary'
                                                : undefined
                                        }
                                    />
                                }
                            />
                        </Box>
                    </>
                );
            },
            cellRenderer: (params: { data: IVendorForEventItem }) => {
                return (
                    <>
                        <Checkbox
                            checked={
                                selectedVendorRowIDs?.includes(
                                    params.data.seller_entity.entity_id
                                )
                                    ? true
                                    : false
                            }
                            onChange={(e) => {
                                if (
                                    params.data.seller_entity.entity_id !==
                                        null &&
                                    params.data.seller_entity.entity_id !==
                                        undefined
                                ) {
                                    if (e.target.checked) {
                                        saveVendorDetails([
                                            ...selectedVendorRowIDs,
                                            params.data.seller_entity.entity_id,
                                        ]);
                                    } else {
                                        saveVendorDetails([
                                            ...selectedVendorRowIDs?.filter(
                                                (id) =>
                                                    params.data.seller_entity
                                                        .entity_id !== id
                                            ),
                                        ]);
                                    }
                                }
                            }}
                            inputProps={{ 'aria-label': 'A' }}
                            size={'small'}
                        />
                        <FWTypography>{(params.data.no ?? 0) + 1}</FWTypography>
                    </>
                );
            },
        },
        {
            field: 'vendor_code',
            headerName: 'Vendor ID',
            width: 250,
            cellRenderer: (params: vendorTableRowParam) => {
                return <FWTypography>{params.data.vendor_code}</FWTypography>;
            },
        },
        {
            field: 'seller_entity.seller_entity_name',
            headerName: 'Company name',
            flex: 1,
            cellRenderer: (params: vendorTableRowParam) => {
                // const rowData: IVendorForEventItem = params.data;

                return (
                    <>
                        &nbsp;
                        <FWLink
                            onClick={() =>
                                openVendorAnalyticsPopup({
                                    sellerEntityUuid:
                                        params.data.seller_entity.entity_id,
                                    sellerEntityName:
                                        params.data.seller_entity.entity_name,
                                })
                            }
                        >
                            {params.data.seller_entity.entity_name}
                        </FWLink>
                        {vendorPreferredForItem(params.data.status) && (
                            <>
                                {' '}
                                &nbsp;
                                <FWTooltip title="Preferred for this item">
                                    <Box color="primary.main">
                                        <i className="bi bi-check-circle-fill" />
                                    </Box>
                                </FWTooltip>
                            </>
                        )}
                        {params.data.seller_entity.entity_id in
                            vendorAndItemIconMap &&
                            vendorAndItemIconMap[
                                params.data.seller_entity.entity_id
                            ].icon.map((iconName, index) => (
                                <Fragment key={index}>
                                    &nbsp; &nbsp;
                                    <FWTooltip
                                        title={
                                            vendorAndItemIconMap[
                                                params.data.seller_entity
                                                    .entity_id
                                            ].iconTooltips[index]
                                        }
                                    >
                                        <Box
                                            color={
                                                vendorAndItemIconMap[
                                                    params.data.seller_entity
                                                        .entity_id
                                                ].iconColors[index]
                                            }
                                            className="flex flex--aic"
                                        >
                                            {vendorAndItemIconMap[
                                                params.data.seller_entity
                                                    .entity_id
                                            ].iconValues[index] !== null && (
                                                <FWTypography>
                                                    {
                                                        vendorAndItemIconMap[
                                                            params.data
                                                                .seller_entity
                                                                .entity_id
                                                        ].iconValues[index]
                                                    }
                                                </FWTypography>
                                            )}
                                            &nbsp;
                                            <i className={iconName} />
                                        </Box>
                                    </FWTooltip>
                                </Fragment>
                            ))}
                        <>
                            &nbsp;
                            <VendorVerificationStatusIcon
                                verificationStatus={
                                    params.data.seller_entity
                                        .verification_status
                                }
                            />
                        </>
                        {params.data.seller_entity.is_virtual && (
                            <>
                                &nbsp;
                                <SignupPendingIcon />
                            </>
                        )}
                        {params.data.seller_entity.entity_id ===
                            'fe7b74a1-7bd7-4445-a250-8b6ced759b38' && (
                            <Fragment>
                                &nbsp; &nbsp;
                                <FWTooltip title={'FW Recommended vendor'}>
                                    <img
                                        style={{
                                            height: 'auto',
                                            width: '2rem',
                                        }}
                                        src="/logo.png"
                                        alt="factwise logo"
                                    />
                                </FWTooltip>
                            </Fragment>
                        )}
                    </>
                );
            },
            valueGetter: (params: any) => {
                return params.data.seller_entity.entity_name;
            },
            getQuickFilterText: (params) =>
                params.data.seller_entity.entity_name,
        },
        {
            field: 'seller_entity.seller_entity_name',
            headerName: 'Tags',
            flex: 1,
            cellRenderer: VendorTagsCell,
        },
        {
            field: 'entity_type',
            headerName: 'Contact',
            flex: 1,
            cellRenderer: VendorContactCell,
            getQuickFilterText: (params) =>
                params.data.seller_entity.contacts &&
                params.data.seller_entity.contacts.emails.length > 0
                    ? params.data.seller_entity.contacts.emails[0]
                    : '',
        },
    ];
};
