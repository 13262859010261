import { IconButton } from '@mui/material';
import React from 'react';
import { FWPopup } from '../../../Common/FWPopup';

interface IDeleteItemPopupProps {
    deleteItem: () => void;
    fontsize?: string;
}

export default function DeleteItemPopup(props: IDeleteItemPopupProps) {
    const [openDeleteItemPopup, setOpenDeleteItemPopup] = React.useState(false);
    return (
        <>
            <IconButton
                color="error"
                sx={{ fontSize: props.fontsize ?? '1.5rem' }}
                onClick={(e) => {
                    e.stopPropagation();
                    setOpenDeleteItemPopup(true);
                }}
            >
                <i className="bi bi-trash3"></i>
            </IconButton>
            <FWPopup
                open={openDeleteItemPopup}
                setOpen={setOpenDeleteItemPopup}
                title="Delete item"
                msg="All item details and vendors will be deleted."
                onClickConfirm={() => {
                    setOpenDeleteItemPopup(false);
                    props.deleteItem();
                }}
                btnColor="error"
                btnTitle="Delete"
            />
        </>
    );
}
