import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { HookStateValue, useHookState } from '../../Common/Hooks/StateHook';
import { fetchAllEnterpriseUnits } from '../../Costing/Services/CostingApisCalls.service';
import { getAllCurrencies } from '../../Events/Services/Buyer/shared.service';
import { ICurrencyDetails } from '../../Models/Currency.model';
import { IMeasurementUnitDetails } from '../../Models/MeasurementUnit.model';

export const useItemCataloguePopup = () => {
    const { hookState, updateHookState } = useHookState(HookStateValue.INITIAL);
    const [units, setUnits] = useState<IMeasurementUnitDetails[]>([]);
    const [currencies, setCurrencies] = useState<ICurrencyDetails[]>([]);

    const fetchUnits = useCallback(async () => {
        try {
            updateHookState(HookStateValue.LOADING);
            const unitsPromise = fetchAllEnterpriseUnits();
            const currenciesPromise = getAllCurrencies();
            const [unitsResponse, currenciesResponse] = await Promise.all([
                unitsPromise,
                currenciesPromise,
            ]);
            setUnits(unitsResponse);
            setCurrencies(currenciesResponse);
            updateHookState(HookStateValue.READY);
        } catch (error) {
            updateHookState(HookStateValue.ERROR);
            toast.error('Error fetching units');
        }
    }, [updateHookState]);

    useEffect(() => {
        fetchUnits();
    }, [fetchUnits]);

    return {
        units,
        currencies,
        hookState,
    };
};
