import { useSelector } from 'react-redux';
import { CartItemDetails } from '../Interfaces/itemCartItemInterfaces';
import { IGlobalAppStore } from '../../Redux/Store';
import {
    addCartItemsInEvent,
    createOrderFromCart,
    getApproversForOrder,
} from '../Services/ItemAnalyticsCartService';
import { IPostRFQItemDetails } from '../../Models/RFQ.model';
// import { useCurrencyConversionVariants } from '../../UOM&Currency/Hooks/Currency/useCurrencyConversionVariants';
// import { useContext, useEffect, useState } from 'react';
// import { useGetCurrencyConversionQuery } from '../../UOM&Currency/Services/Currency.service';
import { getCurrencyConversion } from '../../Events/Services/Buyer/RFQ.service';
import { cloneDeep } from 'lodash';
// import { cloneDeep } from 'lodash';

const useItemCartIntegrationHook = () => {
    const cartItems = useSelector(
        (store: IGlobalAppStore) => store.ItemCartStore.cartDetails
    );
    const itemIDs = useSelector(
        (store: IGlobalAppStore) => store.ItemCartStore.itemIDs
    );
    const addCartItemsToRFQEvent = async (
        eventId: string,
        defaultCurrencyId: string,
        templateId: string,
        items?: any,
        cartId?: string
    ) => {
        return new Promise(async (resolve, reject) => {
            try {
                if (items) {
                    const eventItems = await convertRequisitionItemToRFQItem(
                        items,
                        defaultCurrencyId
                    );
                    addCartItemsInEvent(eventId, eventItems);
                    resolve(eventItems);
                } else {
                    //CONVERT CART ITEM TO RFQ ITEM
                    const eventItems = await convertCartItemToRFQItem(
                        cartItems,
                        defaultCurrencyId
                    );
                    //ADD ITEM TO EVENT
                    addCartItemsInEvent(eventId, eventItems);
                    resolve(eventItems);
                }
            } catch (e) {
                reject(e);
            }
        });
    };
    const createOrder = async (
        defaultCurrencyId: string,
        cartId: string,
        templateId: string,
        source_type: string,
        total?: number
    ) => {
        return new Promise(async (resolve, reject) => {
            try {
                const payloadForApproval = {
                    default_currency_id: defaultCurrencyId,
                    total: 1,
                    cart_id: cartId,
                    cart_item_ids: itemIDs,
                };
                const resp = await getApproversForOrder(
                    payloadForApproval,
                    cartId
                );
                const payload = {
                    requisition_name: `Requisition ${new Date().toISOString()}`,
                    template_id: templateId,
                    default_currency_id: defaultCurrencyId,
                    status:
                        resp?.data.length === 0
                            ? 'SUBMITTED'
                            : 'APPROVAL_PENDING',
                    notes: '',
                    total: 1,
                    cart_id: cartId,
                    cart_item_ids: itemIDs,
                    source_type: source_type,
                };
                const response = await createOrderFromCart(payload, cartId);
                resolve(response);
            } catch (e) {
                reject(e);
            }
        });
    };
    const getConversionRate = async (
        currencyFrom: string,
        currencyTo: string
    ) => {
        let currencyConversionRate = (await getCurrencyConversion(
            currencyFrom,
            currencyTo
        )) ?? {
            rate: 1,
        };
        return currencyConversionRate.rate;
    };
    const convertCartItemToRFQItem = async (
        cartItems: CartItemDetails[],
        defaultCurrencyId: string
    ): Promise<IPostRFQItemDetails[]> => {
        //NOTES FOR RAHUL
        //HOW TO GET EVENT_ITEM_ENTRY_ID
        //
        let newCartItems = cloneDeep(cartItems);
        newCartItems = newCartItems.map((item) => {
            return {
                ...item,
                custom_fields: {
                    section_list: item.custom_fields.section_list.map(
                        (fields) => {
                            return {
                                ...fields,
                                fields: fields.fields.map((field) => {
                                    return {
                                        ...field,
                                        is_locked: false,
                                    };
                                }),
                            };
                        }
                    ),
                },
            };
        });
        let eventItems: any = [];
        eventItems = await Promise.all(
            newCartItems.map(async (item, i) => {
                const rate = await getConversionRate(
                    item.pricing_information.currency_code_id,
                    defaultCurrencyId
                );

                return {
                    attachment_ids: [],
                    item_id: item.enterprise_item,
                    item_additional_details: '',
                    requisition_information: [],
                    attributes: [],
                    properties: [],
                    quantity: String(item.quantity),
                    quantity_tolerance_percentage:
                        item.quantity_tolerance_percentage,
                    custom_item_name: '',
                    measurement_unit_id: item.measurement_unit,
                    desired_price: String(
                        (item.pricing_information.desired_price * rate).toFixed(
                            6
                        )
                    ),
                    currency_code_id: defaultCurrencyId,
                    is_price_confidential: false,
                    delivery_schedule: item.delivery_schedule?.map((ds) => {
                        return {
                            bom_item: null,
                            delivery_date: ds.delivery_date,
                            quantity: Number(ds.quantity),
                            cost_centre_id: ds.cost_centre,
                            general_ledger_id: ds.general_ledger,
                            project_id: ds.project,
                            customer_entity: null,
                            bom_item_id: null,
                            parent_delivery_schedule_item_id:
                                ds.parent_delivery_schedule_item_id,
                        };
                    }),
                    allow_substitutes: false,
                    incoterm_id: item.incoterm ?? '',
                    lead_time:
                        String(item.procurement_information.lead_time) ?? '',
                    lead_time_period:
                        String(item.procurement_information.lead_time_period) ??
                        '',
                    payment_type: item.payment_type,
                    prepayment_percentage: item.prepayment_percentage,
                    payment_terms: {
                        term: item.payment_terms?.term,
                        period: item.payment_terms?.period,
                        applied_from: item.payment_terms?.applied_from,
                    },
                    deliverables_payment_terms: null,
                    shipping_per_unit: 0,
                    additional_charges: [],
                    custom_fields: {
                        section_list: [
                            {
                                name: 'item_terms',
                                fields: item.custom_fields.section_list.filter(
                                    (fields) => fields.name === 'Item Terms'
                                )[0].fields,
                            },
                            {
                                name: 'payment_and_delivery_terms',
                                fields: item.custom_fields.section_list.filter(
                                    (fields) =>
                                        fields.name ===
                                        'Payment and Delivery Terms'
                                )[0]
                                    ? item.custom_fields.section_list.filter(
                                          (fields) =>
                                              fields.name ===
                                              'Payment and Delivery Terms'
                                      )[0].fields
                                    : [],
                            },
                            {
                                name: 'additional_details',
                                fields: item.custom_fields.section_list.filter(
                                    (fields) =>
                                        fields.name === 'Additional Information'
                                )[0]
                                    ? item.custom_fields.section_list.filter(
                                          (fields) =>
                                              fields.name ===
                                              'Additional Information'
                                      )[0].fields
                                    : [],
                            },
                        ],
                    },
                    custom_fields_negotiate: {
                        section_list: [],
                    },
                    additional_costs: item.additional_costs.map((ac) => {
                        return {
                            cost_name: ac.cost_name,
                            cost_type: ac.cost_type,
                            cost_source: ac.cost_source,
                            cost_value: Number(ac.cost_value) * rate,
                            additional_cost_id: ac.additional_cost_id,
                            allocation_type: ac.allocation_type,
                            threshold: ac.threshold,
                            threshold_applied_on: ac.threshold_applied_on,
                        };
                    }),
                    taxes: item.taxes.map((tax) => {
                        return {
                            cost_name: tax.cost_name,
                            cost_type: tax.cost_type,
                            cost_source: tax.cost_source,
                            cost_value: Number(tax.cost_value) * rate,
                            additional_cost_id: tax.additional_cost_id,
                            allocation_type: tax.allocation_type,
                            threshold: tax.threshold,
                            threshold_applied_on: tax.threshold_applied_on,
                        };
                    }),
                    discounts: item.discounts.map((discount) => {
                        return {
                            cost_name: discount.cost_name,
                            cost_type: discount.cost_type,
                            cost_source: discount.cost_source,
                            cost_value: Number(discount.cost_value) * rate,
                            additional_cost_id: discount.additional_cost_id,
                            allocation_type: discount.allocation_type,
                            threshold: discount.threshold,
                            threshold_applied_on: discount.threshold_applied_on,
                        };
                    }),
                    total: 0,
                    // sellers_info: getSellerInfo(
                    //     item.seller_contacts_information
                    // ),
                };
            })
        );
        return eventItems;
    };
    const convertRequisitionItemToRFQItem = async (
        requisitionItems: any[],
        defaultCurrencyId: string
    ): Promise<IPostRFQItemDetails[]> => {
        //NOTES FOR RAHUL
        //HOW TO GET EVENT_ITEM_ENTRY_ID
        //
        let newRequisitionItems = cloneDeep(requisitionItems);
        newRequisitionItems = requisitionItems.map((item) => {
            return {
                ...item,
                custom_fields: {
                    section_list: item.custom_fields.section_list.map(
                        (fields: any) => {
                            return {
                                ...fields,
                                fields: fields.fields.map((field: any) => {
                                    return {
                                        ...field,
                                        is_locked: false,
                                    };
                                }),
                            };
                        }
                    ),
                },
            };
        });
        let eventItems: any = [];
        eventItems = await Promise.all(
            newRequisitionItems.map(async (item, i) => {
                const rate = await getConversionRate(
                    item.pricing_information.currency_code_id,
                    defaultCurrencyId
                );

                return {
                    attachment_ids: [],
                    item_id: item.enterprise_item,
                    item_additional_details: '',
                    requisition_information: [],
                    attributes: [],
                    properties: [],
                    quantity: String(item.quantity),
                    quantity_tolerance_percentage:
                        item.quantity_tolerance_percentage,
                    custom_item_name: '',
                    measurement_unit_id: item.measurement_unit,
                    desired_price: String(
                        (item.pricing_information.desired_price * rate).toFixed(
                            6
                        )
                    ),
                    currency_code_id: defaultCurrencyId,
                    is_price_confidential: false,
                    delivery_schedule: item.delivery_information.map(
                        (ds: {
                            delivery_date: any;
                            quantity: any;
                            cost_centre: any;
                            general_ledger: any;
                            project: any;
                            parent_delivery_schedule_item_id: string;
                        }) => {
                            return {
                                bom_item: null,
                                delivery_date: ds.delivery_date,
                                quantity: Number(ds.quantity),
                                cost_centre_id: ds.cost_centre,
                                general_ledger_id: ds.general_ledger,
                                project_id: ds.project,
                                customer_entity: null,
                                bom_item_id: null,
                                parent_delivery_schedule_item_id:
                                    ds.parent_delivery_schedule_item_id,
                            };
                        }
                    ),
                    allow_substitutes: false,
                    incoterm_id: item.incoterm,
                    lead_time: item.procurement_information.lead_time ?? 0,
                    lead_time_period:
                        item.procurement_information.lead_time_period ??
                        'WEEKS',
                    payment_type: item.payment_type,
                    prepayment_percentage: item.prepayment_percentage,
                    payment_terms: {
                        term: item.payment_terms?.term,
                        period: item.payment_terms?.period,
                        applied_from: item.payment_terms?.applied_from,
                    },
                    deliverables_payment_terms: null,
                    shipping_per_unit: 0,
                    additional_charges: [],
                    custom_fields: {
                        section_list: [
                            {
                                name: 'item_terms',
                                fields: item.custom_fields.section_list.filter(
                                    (fields: any) =>
                                        fields.name === 'Item Terms'
                                )[0]
                                    ? item.custom_fields.section_list.filter(
                                          (fields: any) =>
                                              fields.name === 'Item Terms'
                                      )[0].fields
                                    : [],
                            },
                            {
                                name: 'payment_and_delivery_terms',
                                fields: item.custom_fields.section_list.filter(
                                    (fields: any) =>
                                        fields.name ===
                                        'Payment and Delivery Terms'
                                )[0]
                                    ? item.custom_fields.section_list.filter(
                                          (fields: any) =>
                                              fields.name ===
                                              'Payment and Delivery Terms'
                                      )[0].fields
                                    : [],
                            },
                            {
                                name: 'additional_details',
                                fields: item.custom_fields.section_list.filter(
                                    (fields: any) =>
                                        fields.name === 'Additional Information'
                                )[0]
                                    ? item.custom_fields.section_list.filter(
                                          (fields: any) =>
                                              fields.name ===
                                              'Additional Information'
                                      )[0].fields
                                    : [],
                            },
                        ],
                    },
                    custom_fields_negotiate: {
                        section_list: [],
                    },
                    additional_costs: item.additional_costs.map(
                        (ac: {
                            cost_name: any;
                            cost_type: any;
                            cost_source: any;
                            cost_value: any;
                            additional_cost_id: any;
                            allocation_type: any;
                            threshold: any;
                            threshold_applied_on: any;
                        }) => {
                            return {
                                cost_name: ac.cost_name,
                                cost_type: ac.cost_type,
                                cost_source: ac.cost_source,
                                cost_value: ac.cost_value * rate,
                                additional_cost_id: ac.additional_cost_id,
                                allocation_type: ac.allocation_type,
                                threshold: ac.threshold,
                                threshold_applied_on: ac.threshold_applied_on,
                            };
                        }
                    ),
                    taxes: item.taxes.map(
                        (tax: {
                            cost_name: any;
                            cost_type: any;
                            cost_source: any;
                            cost_value: any;
                            additional_cost_id: any;
                            allocation_type: any;
                            threshold: any;
                            threshold_applied_on: any;
                        }) => {
                            return {
                                cost_name: tax.cost_name,
                                cost_type: tax.cost_type,
                                cost_source: tax.cost_source,
                                cost_value: tax.cost_value * rate,
                                additional_cost_id: tax.additional_cost_id,
                                allocation_type: tax.allocation_type,
                                threshold: tax.threshold,
                                threshold_applied_on: tax.threshold_applied_on,
                            };
                        }
                    ),
                    discounts: item.discounts.map(
                        (discount: {
                            cost_name: any;
                            cost_type: any;
                            cost_source: any;
                            cost_value: any;
                            additional_cost_id: any;
                            allocation_type: any;
                            threshold: any;
                            threshold_applied_on: any;
                        }) => {
                            return {
                                cost_name: discount.cost_name,
                                cost_type: discount.cost_type,
                                cost_source: discount.cost_source,
                                cost_value: discount.cost_value * rate,
                                additional_cost_id: discount.additional_cost_id,
                                allocation_type: discount.allocation_type,
                                threshold: discount.threshold,
                                threshold_applied_on:
                                    discount.threshold_applied_on,
                            };
                        }
                    ),
                    total: 0,
                    // sellers_info: getSellerInfo(
                    //     item.seller_contacts_information
                    // ),
                };
            })
        );
        return eventItems;
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const getSellerInfo = (data: any) => {
        const sellersInfoMap: { [key: string]: string[] } = {};

        data.forEach((item: any) => {
            const sellerEntityId = item.seller_entity;
            const vendorContactId = item.vendor_contact_id;

            if (!sellersInfoMap[sellerEntityId]) {
                sellersInfoMap[sellerEntityId] = [];
            }

            sellersInfoMap[sellerEntityId].push(vendorContactId);
        });

        const result: any = Object.keys(sellersInfoMap).map(
            (sellerEntityId) => ({
                seller_entity_id: sellerEntityId,
                vendor_contact_list: sellersInfoMap[sellerEntityId],
            })
        );

        return result;
    };
    return {
        addCartItemsToRFQEvent,
        createOrder,
    };
};

export default useItemCartIntegrationHook;
