import {
    Box,
    Checkbox,
    CircularProgress,
    DialogActions,
    DialogContent,
    FormControlLabel,
    Grid,
    Typography,
} from '@mui/material';
import { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { FWButton } from '../../../../Common/FWButton';
import { FWCustomGridKeyValue } from '../../../../Common/FWCustomGridKeyValue';
import { FWTooltip } from '../../../../Common/FWCustomTooltip';
import FWIcon from '../../../../Common/FWIcon';
import { FWInput } from '../../../../Common/FWInput';
import { FWPopupWithChildren } from '../../../../Common/FWPopupWithChildren';
import { FWTypography } from '../../../../Common/FWTypograhy';
import { HookStateValue } from '../../../../Common/Hooks/StateHook';
import UploadFile, {
    IFileResourceType,
} from '../../../../Components/Shared/UploadFile';
import UploadedFilesList from '../../../../Components/Shared/UploadedFilesList';
import { AuthContext } from '../../../../Contexts/AuthContext';
import { IAttachment } from '../../../../Models/RFQ.model';
import IdentificationStatusChip from '../../Components/Enterprise/IdentificationStatusChip';
import { IdentificationEmitActions } from '../../Containers/Identifications/IdentificationsListContainer';
import {
    IdentificationUpdateActions,
    useIdentificationDetail,
} from '../../Hooks/IdentificationHook';
import { IdentificationStatus } from '../../Interfaces/IdentificationInterface';

interface IIdentificationDetailProps {
    popupToggle: boolean;
    entityId: string;
    identificationId: string;
    canEditDefault: boolean;
    isVerified: boolean;
    emitPopupToggle: (response: IdentificationEmitActions) => void;
    canEdit?: boolean;
}

const IdentificationDetailPopup = ({
    popupToggle,
    entityId,
    identificationId,
    canEditDefault,
    emitPopupToggle,
    isVerified,
    canEdit = true,
}: IIdentificationDetailProps) => {
    const {
        originalIdentificationDetail,
        identificationDetail,
        hookState,
        handleUpdate,
        handleSubmitUpdate,
        isValidDetails,
        countryList,
    } = useIdentificationDetail(entityId, identificationId);

    const [loading, setLoading] = useState<boolean>(false);
    const { authData } = useContext(AuthContext);

    const handleFieldChange = (event: any) => {
        handleUpdate({
            value: event.target.value,
            type: event.target.name,
        });
    };

    const handleToggleChange = (event: any) => {
        handleUpdate({
            value: event.target.checked,
            type: event.target.name,
        });
    };

    const handleFileUpload = (files: IAttachment[]) => {
        handleUpdate({
            value: files,
            type: IdentificationUpdateActions.UpdateFiles,
        });
    };

    const handleFileRemove = (index: number) => {
        handleUpdate({
            value: index,
            type: IdentificationUpdateActions.RemoveFile,
        });
    };

    const setPopupToggle = (response?: IdentificationEmitActions) =>
        emitPopupToggle(response || IdentificationEmitActions.CANCEL);

    const handleFormSubmit = async (event: any) => {
        event.preventDefault();
        setLoading(true);
        const identificationCreated = await handleSubmitUpdate();
        if (identificationCreated) {
            // toast.success(`Identification updated successfully.`);
            setPopupToggle(IdentificationEmitActions.SUCCESS);
        } else {
            toast.error(`Identification updated failed.`);
        }
        setLoading(false);
    };

    return (
        <>
            <FWPopupWithChildren
                handleClose={() => {
                    emitPopupToggle(IdentificationEmitActions.CANCEL);
                }}
                open={popupToggle}
                title="Identification Details"
            >
                <form onSubmit={handleFormSubmit}>
                    {hookState.state === HookStateValue.LOADING ? (
                        <Grid container className="p--20 flex flex--jcc">
                            <CircularProgress
                                size={30}
                                style={{ color: '#c4c4c8' }}
                            />
                        </Grid>
                    ) : (
                        <DialogContent
                            style={{ paddingTop: 0, paddingBottom: 0 }}
                        >
                            {authData.details?.role === 'ADMIN' && (
                                <div className="mb--5 mt--10">
                                    {canEdit && (
                                        <div className="flex flex--aic mb--5">
                                            <UploadFile
                                                label="Upload attachment for verification purposes"
                                                existingFiles={
                                                    identificationDetail.attachments
                                                }
                                                allowedFileSizeInMB={5}
                                                maxAllowedFiles={1}
                                                onFileUpload={(files) => {
                                                    handleFileUpload(files);
                                                }}
                                                resourceType={
                                                    IFileResourceType.IDENTIFICATION
                                                }
                                                // disabled={!isVerified}
                                            />
                                            {/* {identificationDetail.attachments.length ===
                                        0 && (
                                        <FWTooltip title="Please upload a proof document for verification purposes">
                                            <div className="ml--10">
                                                <FWIcon
                                                    name="bi bi-info-circle"
                                                    color="primary.main"
                                                />
                                            </div>
                                        </FWTooltip>
                                    )} */}
                                        </div>
                                    )}
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            width: '100%',
                                            marginBottom: '10px',
                                        }}
                                    >
                                        <UploadedFilesList
                                            existingFiles={
                                                identificationDetail.attachments
                                            }
                                            toggleFileRemove={(fileIndex) => {
                                                handleFileRemove(fileIndex);
                                            }}
                                            // showRemove={isVerified}
                                        />
                                    </Box>
                                </div>
                            )}
                            <div className="mb--15">
                                <FWCustomGridKeyValue
                                    title={'Verification status'}
                                >
                                    <div className="flex flex--aic">
                                        <IdentificationStatusChip
                                            status={
                                                identificationDetail.identificationStatus
                                            }
                                        />
                                        {identificationDetail.identificationStatus ===
                                            IdentificationStatus.PENDING && (
                                            <FWTooltip title="Verification in process. This identification cannot be used in POs and Invoices until it's verified.">
                                                <div className="ml--10">
                                                    <FWIcon
                                                        name="bi bi-info-circle"
                                                        color="warning.main"
                                                    />
                                                </div>
                                            </FWTooltip>
                                        )}
                                        {identificationDetail.identificationStatus ===
                                            IdentificationStatus.VERIFIED && (
                                            <FWIcon
                                                name="bi bi-check"
                                                color="success.main"
                                                className="ml--10"
                                                size={20}
                                            />
                                        )}
                                    </div>
                                </FWCustomGridKeyValue>
                            </div>
                            <div className="mb--15">
                                {/* <FWInput
                                    type="text"
                                    label="Name"
                                    onChange={handleFieldChange}
                                    name={
                                        IdentificationUpdateActions.UpdateName
                                    }
                                    value={identificationDetail.name}
                                    style={{ width: '100%' }}
                                    disabled
                                    required
                                /> */}
                                <FWCustomGridKeyValue
                                    title={
                                        'Official enterprise identification ID'
                                    }
                                >
                                    {identificationDetail.name}
                                </FWCustomGridKeyValue>
                            </div>
                            <div className="mb--15">
                                {/* <FWInput
                                    type="text"
                                    label="Value"
                                    onChange={handleFieldChange}
                                    name={
                                        IdentificationUpdateActions.UpdateValue
                                    }
                                    value={identificationDetail.value}
                                    style={{ width: '100%' }}
                                    disabled
                                    required
                                /> */}
                                <FWCustomGridKeyValue
                                    title={
                                        'Official enterprise identification ID value'
                                    }
                                >
                                    {identificationDetail.value}
                                </FWCustomGridKeyValue>
                            </div>
                            {identificationDetail.country && (
                                <div className="mb--15">
                                    {/* <FWInput
                                    type="text"
                                    label="Value"
                                    onChange={handleFieldChange}
                                    name={
                                        IdentificationUpdateActions.UpdateValue
                                    }
                                    value={identificationDetail.value}
                                    style={{ width: '100%' }}
                                    disabled
                                    required
                                /> */}
                                    <FWCustomGridKeyValue
                                        title={'Country issuing ID'}
                                    >
                                        {countryList?.find(
                                            (country) =>
                                                country.countryCode ===
                                                identificationDetail.country
                                        )?.name ?? identificationDetail.country}
                                    </FWCustomGridKeyValue>
                                </div>
                            )}

                            {identificationDetail?.linked_addresses?.length >
                                0 && (
                                <Grid>
                                    <FWTypography color={'secondary'}>
                                        Linked Address(s)
                                    </FWTypography>

                                    {identificationDetail?.linked_addresses?.map(
                                        (address) => (
                                            <Grid>
                                                <Typography>
                                                    <Box
                                                        color="secondary.main"
                                                        display="inline"
                                                    >
                                                        {
                                                            address
                                                                .entity_address
                                                                .address
                                                                .address_nickname
                                                        }
                                                        :{' '}
                                                    </Box>
                                                    {`${
                                                        address.entity_address
                                                            .address.address1
                                                            ? address
                                                                  .entity_address
                                                                  .address
                                                                  .address1 +
                                                              ', '
                                                            : ''
                                                    }${
                                                        address.entity_address
                                                            .address.address2
                                                            ? address
                                                                  .entity_address
                                                                  .address
                                                                  .address2 +
                                                              ', '
                                                            : ''
                                                    }${
                                                        address.entity_address
                                                            .address.address3
                                                            ? address
                                                                  .entity_address
                                                                  .address
                                                                  .address3 +
                                                              ', '
                                                            : ''
                                                    }${
                                                        address.entity_address
                                                            .address.city
                                                    }, ${
                                                        address.entity_address
                                                            .address
                                                            .state_or_territory
                                                    } ${
                                                        address.entity_address
                                                            .address.postal_code
                                                    }`}
                                                </Typography>
                                            </Grid>
                                        )
                                    )}
                                </Grid>
                            )}
                            {/* {identificationDetail.category && (
                                <div className="mb--10">
                                    <FormControl style={{ width: '100%' }}>
                                    <FWTypography
                                        sx={{
                                            color: 'text.secondary',
                                            mb: '4px',
                                        }}
                                    >
                                        Identification Category
                                    </FWTypography>
                                    <Select
                                        style={{ width: '100%', height: 40 }}
                                        onChange={handleFieldChange}
                                        name={
                                            IdentificationUpdateActions.UpdateCategory
                                        }
                                        value={
                                            identificationDetail.category || ' '
                                        }
                                        disabled
                                        required
                                    >
                                        <MenuItem value=" " disabled>
                                            Select
                                        </MenuItem>
                                        <MenuItem value="Cat1">
                                            Category 1
                                        </MenuItem>
                                        <MenuItem value="Cat2">
                                            Category 2
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                                    <FWKeyValue
                                        xs={12}
                                        title={'Identification Category'}
                                        value={identificationDetail.category}
                                    ></FWKeyValue>
                                </div>
                            )} */}
                            {authData.details?.role === 'ADMIN' && (
                                <div className="flex flex--aic">
                                    <FormControlLabel
                                        onChange={handleToggleChange}
                                        control={
                                            <FWTooltip
                                                title={
                                                    !canEditDefault &&
                                                    originalIdentificationDetail.isDefault &&
                                                    originalIdentificationDetail.identificationStatus ===
                                                        IdentificationStatus.VERIFIED
                                                        ? 'The only verified identification cannot be disabled'
                                                        : !identificationDetail.isPublic
                                                        ? `Only public identifications can be set as defaults`
                                                        : ''
                                                }
                                                placement="right"
                                            >
                                                <div>
                                                    <Checkbox
                                                        checked={
                                                            !isVerified ||
                                                            identificationDetail.isDefault
                                                        }
                                                        name={
                                                            IdentificationUpdateActions.UpdateDefault
                                                        }
                                                        disabled={
                                                            // !identificationDetail.isPublic ||
                                                            (!canEditDefault &&
                                                                originalIdentificationDetail.isDefault &&
                                                                originalIdentificationDetail.identificationStatus ===
                                                                    IdentificationStatus.VERIFIED) ||
                                                            !isVerified
                                                        }
                                                    />
                                                </div>
                                            </FWTooltip>
                                        }
                                        label="Add as default for documents like POs"
                                    />
                                    {/* <FWTooltip
                                    title="Will be included in documents like POs and invoices by default"
                                    placement="right"
                                >
                                    <div>
                                        <FWIcon
                                            name="bi bi-info-circle"
                                            color="primary.main"
                                        />
                                    </div>
                                </FWTooltip> */}
                                </div>
                            )}
                            <div className="mb--5">
                                <FormControlLabel
                                    onChange={handleToggleChange}
                                    control={
                                        <FWTooltip
                                            title={
                                                authData.details?.role ===
                                                    'ADMIN' &&
                                                identificationDetail.isDefault
                                                    ? 'Cannot set default identifications as private'
                                                    : ''
                                            }
                                            placement="right"
                                        >
                                            <div>
                                                <Checkbox
                                                    disabled={
                                                        !isVerified ||
                                                        identificationDetail.isDefault ||
                                                        authData.details
                                                            ?.role !== 'ADMIN'
                                                    }
                                                    checked={
                                                        identificationDetail.isPublic
                                                    }
                                                    name={
                                                        IdentificationUpdateActions.UpdatePublic
                                                    }
                                                    icon={
                                                        <i
                                                            className="bi bi-eye-slash"
                                                            style={{
                                                                fontSize:
                                                                    '21px',
                                                                width: '27px',
                                                                textAlign:
                                                                    'center',
                                                            }}
                                                        ></i>
                                                    }
                                                    checkedIcon={
                                                        <i
                                                            className="bi bi-eye fs--20"
                                                            style={{
                                                                fontSize:
                                                                    '21px',
                                                                width: '27px',
                                                                textAlign:
                                                                    'center',
                                                            }}
                                                        ></i>
                                                    }
                                                />
                                            </div>
                                        </FWTooltip>
                                    }
                                    label={
                                        identificationDetail.isPublic
                                            ? 'Public'
                                            : 'Private'
                                    }
                                />
                            </div>

                            <div className="mb--20">
                                {authData.details?.role === 'ADMIN' &&
                                canEdit ? (
                                    <FWInput
                                        type="text"
                                        label="Notes (optional)"
                                        onChange={handleFieldChange}
                                        name={
                                            IdentificationUpdateActions.UpdateNotes
                                        }
                                        value={identificationDetail.notes}
                                        style={{ width: '100%' }}
                                        multiline={true}
                                        maxRows={4}
                                        disabled={!isVerified}
                                    />
                                ) : (
                                    <Box>
                                        <FWTypography color={'text.secondary'}>
                                            Notes
                                        </FWTypography>
                                        <FWTypography>
                                            {identificationDetail.notes || '-'}
                                        </FWTypography>
                                    </Box>
                                )}
                            </div>
                        </DialogContent>
                    )}
                    {authData.details?.role === 'ADMIN' && canEdit && (
                        <DialogActions style={{ padding: '0 24px 20px' }}>
                            <FWButton
                                type="button"
                                onClick={() => setPopupToggle()}
                                color="primary"
                                variant="outlined"
                            >
                                Cancel
                            </FWButton>
                            <FWButton
                                type="submit"
                                variant="contained"
                                disabled={!isValidDetails || loading}
                            >
                                {loading ? (
                                    <CircularProgress
                                        size={24}
                                        style={{ color: '#c4c4c8' }}
                                    />
                                ) : (
                                    'Save'
                                )}
                            </FWButton>
                        </DialogActions>
                    )}
                </form>
            </FWPopupWithChildren>
        </>
    );
};

export default IdentificationDetailPopup;
