import { Box, CircularProgress, Grid, styled } from '@mui/material';
import { cloneDeep, isEmpty, isEqual } from 'lodash';
import {
    createContext,
    useContext,
    useLayoutEffect,
    useMemo,
    useState,
} from 'react';
import { useSelector } from 'react-redux';
import { HookStateValue } from '../../../../Common/Hooks/StateHook';
import LoadingBackDrop from '../../../../Components/Shared/LoadingBackdrop';

import { toast } from 'react-toastify';
import { FWButton } from '../../../../Common/FWButton';
import { FWPopup } from '../../../../Common/FWPopup';
import { AuthContext } from '../../../../Contexts/AuthContext';
import { useFeatureAccess } from '../../../../FeatureAccess/Hooks/FeatureAccessHook';
import { TFeatureNames } from '../../../../FeatureAccess/Interfaces/FeaturesInterface';
import { IGlobalAppStore } from '../../../../Redux/Store';
import PageHeader from '../../Components/PageHeader';
import ASModuleAccordion from '../../Components/Settings/ASModuleAccordion';
import { useAdminSettings } from '../../Hooks/AdminSettingsHook';
import {
    IEntityListProviders,
    useEntitiesList,
    useEntityDetails,
} from '../../Hooks/EnterpriseHook';
import {
    ASModule,
    ASModuleSetting,
    ASOptionType,
    IAdminSettingModule,
} from '../../Interfaces/AdminSettingsInterface';
import { updateEntitySettings } from '../../Services/AdminSettingsService';

export const Spacer = styled('div')({
    paddingTop: '10px',
});

export const IsFeatureAvailableContext = createContext<
    ((feature: TFeatureNames) => boolean) | undefined
>(undefined);

const AdminSettingsHomePage = () => {
    const SINGLE_ENTITY = useSelector(
        (store: IGlobalAppStore) => store.VerificationStore.hasSignleEntity
    );

    const { authData } = useContext(AuthContext);

    const [selectedEntity, setSelectedEntity] = useState<string>('');

    const {
        hookState,
        settings,
        adminTemplateSettings,
        setAdminTemplateSettings,
        updateSettings,
        updateAllEntitySettings,
    } = useAdminSettings(selectedEntity);

    const [settingsValues, setSettingsValues] = useState<IAdminSettingModule[]>(
        cloneDeep(settings)
    );

    const { entities }: IEntityListProviders = useEntitiesList();

    const [loading, setLoading] = useState<boolean>(false);
    const [loadingAll, setLoadingAll] = useState<boolean>(false);

    const { currencies } = useEntityDetails(selectedEntity);

    const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);

    const handleEntityChange = (event: any) => {
        const entityUid = event.target.value;
        setSelectedEntity(entityUid);
    };

    const handleSubmit = async () => {
        setLoading(true);
        const settingsUpdated = await updateEntitySettings(
            cloneDeep(settingsValues),
            selectedEntity
        );
        if (settingsUpdated) {
            updateSettings(cloneDeep(settingsValues));
            toast.success(`Settings updated successfully`);
        } else {
            toast.error(`Settings update failed`);
        }
        setLoading(false);
    };

    const handleSubmitToAll = async () => {
        setLoadingAll(true);
        const settingsUpdated = await updateAllEntitySettings(
            cloneDeep(settingsValues)
        );
        if (settingsUpdated) {
            updateSettings(cloneDeep(settingsValues));
            toast.success(`Settings updated successfully`);
        } else {
            toast.error(`Settings update failed`);
        }
        setOpenConfirmation(false);
        setLoadingAll(false);
    };

    useLayoutEffect(() => {
        if (entities.length > 0 && isEmpty(selectedEntity)) {
            handleEntityChange({ target: { value: entities[0].entityId } });
        }
    }, [selectedEntity, entities]);

    const settingsValid: boolean = useMemo(() => {
        let errors = false;
        for (const module of settingsValues) {
            if (errors) break;
            if (module) {
                for (const setting of module.settings) {
                    //Check for "Default sampling percentage" field in QC module
                    if (
                        setting.option ===
                        ASModuleSetting.QC_PRIMARY_SAMPLE_PERCENTAGE
                    ) {
                        if (
                            setting.settings.type === ASOptionType.MAX_VALUE &&
                            setting.settings.value === '0'
                        ) {
                            errors = true;
                            break;
                        }
                    }
                    switch (setting.settings.type) {
                        case ASOptionType.MAX_CURRENCY:
                            if (
                                setting.settings.enabled &&
                                (isEmpty(setting.settings.value) ||
                                    isEmpty(setting.settings.currencyUid))
                            ) {
                                errors = true;
                                break;
                            }
                            break;
                        case ASOptionType.MAX_VALUE:
                            if (
                                setting.settings.enabled &&
                                isEmpty(setting.settings.value)
                            ) {
                                errors = true;
                                break;
                            }
                            break;
                    }
                }
            }
        }
        return !errors;
    }, [settingsValues]);

    const { isFeatureAvailable } = useFeatureAccess();

    return (
        <IsFeatureAvailableContext.Provider value={isFeatureAvailable}>
            {hookState.state === HookStateValue.LOADING && <LoadingBackDrop />}
            <PageHeader
                title={`Settings`}
                switchEntity={
                    !SINGLE_ENTITY
                        ? {
                              selectedEntity: selectedEntity || '',
                              entityList: entities,
                          }
                        : undefined
                }
                emitEntityChange={handleEntityChange}
            />
            <Grid container className="p--20" paddingBottom="80px">
                <Grid item xs={12} gap={2}>
                    {/* Hiding Invoice, GR, QC, Payment settings */}
                    {settings
                        .filter(
                            (setting) =>
                                setting.module === ASModule.EVENT ||
                                setting.module === ASModule.PURCHASE_ORDER ||
                                setting.module === ASModule.REQUISITION ||
                                setting.module === ASModule.PROJECT
                        )
                        .map((setting, parentIndex) => {
                            //TODO: PO module temporarily hidden as there are no working settings in it

                            return (
                                <Box sx={{ marginBottom: '15px' }}>
                                    <ASModuleAccordion
                                        key={setting.module}
                                        setting={setting}
                                        adminTemplateSettings={
                                            adminTemplateSettings
                                        }
                                        setAdminTemplateSettings={
                                            setAdminTemplateSettings
                                        }
                                        currencies={currencies}
                                        setSettingsValues={setSettingsValues}
                                        parentIndex={parentIndex}
                                        selectedEntity={selectedEntity}
                                    />
                                </Box>
                            );
                        })}
                </Grid>
            </Grid>
            {authData.details?.role === 'ADMIN' && (
                <Grid
                    item
                    xs={12}
                    lg={12}
                    className="flex flex--jcfs page-bottom-fixed-bar"
                >
                    <FWButton
                        variant="contained"
                        onClick={handleSubmit}
                        disabled={
                            loading ||
                            !settingsValid ||
                            isEqual(settings, settingsValues)
                        }
                    >
                        {loading ? (
                            <CircularProgress
                                size={24}
                                style={{ color: '#c4c4c8' }}
                            />
                        ) : (
                            'Save'
                        )}
                    </FWButton>
                    {!SINGLE_ENTITY && (
                        <>
                            <div className="mr--10"></div>
                            <FWButton
                                variant="outlined"
                                onClick={() => {
                                    setOpenConfirmation(true);
                                }}
                                disabled={loadingAll || !settingsValid}
                            >
                                {loadingAll ? (
                                    <CircularProgress
                                        size={24}
                                        style={{ color: '#c4c4c8' }}
                                    />
                                ) : (
                                    'Apply to all Entities'
                                )}
                            </FWButton>
                        </>
                    )}
                </Grid>
            )}

            <FWPopup
                title={`Copy settings`}
                msg={`These settings will get copied over to all entities of ${
                    authData.details
                        ? authData.details.enterprise_name
                        : 'your enterprise'
                }.
                (Note: The custom field and checklists won't be copied)`}
                preLine
                btnColor="primary"
                btnTitle="Confirm"
                open={openConfirmation}
                setOpen={() => {
                    setOpenConfirmation(false);
                }}
                onClickConfirm={handleSubmitToAll}
                btnLoading={loadingAll}
            />
        </IsFeatureAvailableContext.Provider>
    );
};

export default AdminSettingsHomePage;
