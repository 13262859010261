import { Avatar, Box, Button } from '@mui/material';
import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { FWMenu, FWMenuItem } from '../../Common/FWCustomMenu';
import FWIcon from '../../Common/FWIcon';
import FWSelect from '../../Common/FWSelect';
import { FWTypography } from '../../Common/FWTypograhy';
import { AuthContext } from '../../Contexts/AuthContext';
import AdminActionsContainer from '../../Global/Containers/AdminActionsContainer';
import AdminActionsForSellerContainer from '../../Global/Containers/AdminActionsForSellerContainer';
import HelpInformationContainer from '../../Global/Containers/HelpInformationContainer';
import NotificationsListContainer from '../../Global/Containers/NotificationsListContainer';
import QuickUserActionsContainer from '../../Global/Containers/QuickUserActionsContainer';
import { UserRoles } from '../../Organizations/Admin/Interfaces/UsersInterface';
import { HelpPageName } from '../../Redux/Slices/HelpInformationSlice';
import VerificationStatusButton from './VerificationStatusButton';
import { useLogout } from './useLogout';

export default function ProfileMenu({
    showOnlyLogoutButton = false,
    currentPage,
    hideVerificationChip = false,
}: {
    showOnlyLogoutButton?: boolean;
    currentPage?: HelpPageName;
    hideVerificationChip?: boolean;
}) {
    const { authData, checkPermission } = React.useContext(AuthContext);
    const history = useHistory();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const logOut = useLogout();

    // const isCustomerAccess = checkPermission(
    //     'CUSTOMER',
    //     'QUOTE_CALCULATOR_CUSTOMER',
    //     'VIEW',
    //     null
    // );

    const accessCustomizePortal =
        checkPermission('CUSTOMIZE', 'PROJECT', 'PROJECT_VIEW', null) ||
        authData.modulePermissions.GLOBAL_ADMIN;

    const accessOnlyProjectModule =
        checkPermission('CUSTOMIZE', 'PROJECT', 'PROJECT_VIEW', null) &&
        authData.details?.role !== UserRoles.ADMIN;

    const portalButtons = {
        ADMIN: showOnlyLogoutButton
            ? false
            : authData.modulePermissions.GLOBAL_ADMIN ||
              authData?.details?.role === 'ADMIN_VIEW',
        CUSTOM: showOnlyLogoutButton
            ? false
            : accessCustomizePortal || authData?.details?.role === 'ADMIN',
        BUYER: showOnlyLogoutButton
            ? false
            : authData.modulePermissions.BUYER !== null,
        SELLER: showOnlyLogoutButton
            ? false
            : authData.modulePermissions.SELLER !== null,
        BILLING: showOnlyLogoutButton
            ? false
            : authData.modulePermissions.GLOBAL_ADMIN,
        PROFILE: true,
        CUSTOMER: showOnlyLogoutButton
            ? false
            : !authData.modulePermissions.CUSTOMER?.QUOTE_CALCULATOR_CUSTOMER
            ? false
            : authData.modulePermissions.CUSTOMER?.QUOTE_CALCULATOR_CUSTOMER
                  ?.VIEW !== null,
    };

    //Hide the button for whichever portal the user is already in.
    const location = useLocation();
    const path = location.pathname.split('/');
    const currentPortalString =
        path[1] === 'seller' && path[2] === 'profile'
            ? path[1]
            : path[2] === 'profile' ||
              path[2] === 'settings' ||
              path[2] === 'user-settings' ||
              path[2] === 'billing-management'
            ? path[2]
            : path[1]
            ? path[1]
            : '';
    // const currentPortal =
    //     currentPortalString.toUpperCase() === 'ADMIN'
    //         ? 'ADMIN'
    //         : location.pathname.includes('billing-management')
    //         ? 'BILLING'
    //         : currentPortalString.toUpperCase() === 'BUYER'
    //         ? 'BUYER'
    //         : currentPortalString.toUpperCase() === 'SELLER'
    //         ? 'SELLER'
    //         : currentPortalString.toUpperCase() === 'USER'
    //         ? 'PROFILE'
    //         : null;

    //convert the above logic to switch case function

    const currentPortal = React.useMemo(() => {
        switch (currentPortalString) {
            case 'admin':
                return 'ADMIN';
            case 'custom':
                return 'CUSTOM';
            case 'billing-management':
                return 'BILLING';
            case 'buyer':
                return 'BUYER';
            case 'seller':
                return 'SELLER';
            case 'profile':
                return 'PROFILE';
            case 'settings':
                return 'SETTINGS';
            case 'user-settings':
                return 'USER_SETTINGS';
            case 'customer':
                return 'CUSTOMER';
            // case 'seller_admin':
            //     return 'SELLER_ADMIN';
            default:
                return null;
        }
    }, [currentPortalString]);

    const redirectBuyerOrSeller = React.useCallback(
        (portal: 'buyer' | 'seller' | 'billing') => {
            if (authData.details !== null) {
                if (portal === 'billing') {
                    history.push('/buyer/billing-management');
                    return;
                }
                if (authData.details!.defaults !== null) {
                    let url = authData.details!.defaults.homepage;
                    if (!url.includes(portal)) {
                        url = `/${portal}/events`;
                    }
                    history.push(url);
                }
            }
        },
        [authData.details, history]
    );

    //

    useEffect(() => {
        const lastClickedItem = localStorage.getItem('lastClickedPortal');
        if (!lastClickedItem) {
            localStorage.setItem('lastClickedPortal', currentPortal || '');
        }
    }, [currentPortal]);

    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) =>
        setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    return (
        <div className="flex flex--aic">
            {!hideVerificationChip && <VerificationStatusButton />}
            <Box
                component="div"
                display="inline-flex"
                style={{
                    margin: '0 20px 0 10px',
                    background: '#fff',
                    zIndex: 2,
                }}
            >
                {currentPage && (
                    <div style={{ alignSelf: 'center' }}>
                        <HelpInformationContainer currentPage={currentPage} />
                    </div>
                )}

                {authData.details?.role === 'ADMIN' && (
                    <QuickUserActionsContainer />
                )}

                {currentPortal !== 'SELLER' &&
                    authData.details?.role === 'ADMIN' && (
                        <AdminActionsContainer />
                    )}

                {currentPortal === 'SELLER' &&
                    authData.details?.role === 'ADMIN' && (
                        <AdminActionsForSellerContainer
                            entityId={
                                authData.details.defaults?.primary_entity_id
                            }
                        />
                    )}

                {!showOnlyLogoutButton && <NotificationsListContainer />}

                {/* Portal Select */}
                {!showOnlyLogoutButton && (
                    <Box position={'relative'} top={'0.4rem'} marginX={2}>
                        <FWSelect
                            value={
                                currentPortal === 'PROFILE' ||
                                currentPortal === 'SETTINGS' ||
                                currentPortal === 'USER_SETTINGS'
                                    ? localStorage.getItem('lastClickedPortal')
                                    : currentPortal
                            }
                            sx={{ width: 130 }}
                        >
                            <FWMenuItem
                                key={0}
                                onClick={() => {
                                    if (currentPortal !== 'ADMIN') {
                                        history.push(`/admin/home/`);
                                        localStorage.setItem(
                                            'lastClickedPortal',
                                            'ADMIN'
                                        );
                                    }
                                }}
                                value={'ADMIN'}
                                disabled={!portalButtons['ADMIN']}
                            >
                                <Box className="flex flex--aic ">
                                    <FWIcon
                                        name="bi bi-gear"
                                        className="mr--5"
                                        style={{ fontSize: '1.5rem' }}
                                    />
                                    <FWTypography>Admin</FWTypography>
                                </Box>
                            </FWMenuItem>
                            {/* {!(
                                process.env.REACT_APP_ENV === 'production' ||
                                process.env.REACT_APP_ENV === 'newdbtest1'
                            ) && (
                                <FWMenuItem
                                    key={0}
                                    onClick={() => {
                                        if (currentPortal !== 'SELLER_ADMIN') {
                                            history.push(
                                                `/seller_admin/buyers/`
                                            );
                                        }
                                    }}
                                    value={'SELLER_ADMIN'}
                                    disabled={!portalButtons['ADMIN']}
                                >
                                    <Box className="flex flex--aic ">
                                        <FWIcon
                                            name="bi bi-gear"
                                            className="mr--5"
                                            style={{ fontSize: '1.5rem' }}
                                        />
                                        <FWTypography>
                                            Seller admin
                                        </FWTypography>
                                    </Box>
                                </FWMenuItem>
                            )} */}
                            <FWMenuItem
                                key={1}
                                onClick={() => {
                                    if (currentPortal !== 'BUYER') {
                                        redirectBuyerOrSeller('buyer');
                                        localStorage.setItem(
                                            'lastClickedPortal',
                                            'BUYER'
                                        );
                                    }
                                }}
                                value={'BUYER'}
                                disabled={!portalButtons['BUYER']}
                            >
                                <Box className="flex flex--aic">
                                    <FWIcon
                                        name="bi bi-cash-coin"
                                        className="mr--5"
                                        style={{ fontSize: '1.5rem' }}
                                    />
                                    <FWTypography>Buyer</FWTypography>
                                </Box>
                            </FWMenuItem>

                            <FWMenuItem
                                key={1}
                                onClick={() => {
                                    if (currentPortal !== 'BILLING') {
                                        redirectBuyerOrSeller('billing');
                                        localStorage.setItem(
                                            'lastClickedPortal',
                                            'BILLING'
                                        );
                                    }
                                }}
                                value={'BILLING'}
                                disabled={!portalButtons['BILLING']}
                            >
                                <Box className="flex flex--aic">
                                    <FWIcon
                                        name={(() => {
                                            switch (
                                                authData.details?.enterprise
                                                    .billing_currency
                                            ) {
                                                case 'USD':
                                                    return 'bi bi-currency-dollar';
                                                case 'EUR':
                                                    return 'bi bi-currency-euro';
                                                case 'INR':
                                                    return 'bi bi-currency-rupee';
                                                default:
                                                    return 'bi bi-currency-dollar';
                                            }
                                        })()}
                                        className="mr--5"
                                        style={{ fontSize: '1.5rem' }}
                                    />
                                    <FWTypography>Billing</FWTypography>
                                </Box>
                            </FWMenuItem>

                            <FWMenuItem
                                key={2}
                                onClick={() => {
                                    if (currentPortal !== 'SELLER') {
                                        redirectBuyerOrSeller('seller');
                                        localStorage.setItem(
                                            'lastClickedPortal',
                                            'SELLER'
                                        );
                                    }
                                }}
                                value={'SELLER'}
                                disabled={!portalButtons['SELLER']}
                            >
                                <Box className="flex flex--aic">
                                    <FWIcon
                                        name="bi bi-truck"
                                        className="mr--5"
                                        style={{ fontSize: '1.5rem' }}
                                    />
                                    <FWTypography>Seller</FWTypography>
                                </Box>
                            </FWMenuItem>
                            <FWMenuItem
                                key={3}
                                onClick={() => {
                                    if (currentPortal !== 'CUSTOMER') {
                                        history.push(
                                            '/customer/quote?type=received'
                                        );
                                    }
                                    localStorage.setItem(
                                        'lastClickedPortal',
                                        'CUSTOMER'
                                    );
                                }}
                                value={'CUSTOMER'}
                                disabled={!portalButtons['CUSTOMER']}
                            >
                                <Box className="flex flex--aic ">
                                    <FWIcon
                                        name="bi bi-briefcase"
                                        className="mr--5"
                                        style={{ fontSize: '1.5rem' }}
                                    />
                                    <FWTypography>Customer</FWTypography>
                                </Box>
                            </FWMenuItem>
                            <FWMenuItem
                                key={0}
                                onClick={() => {
                                    if (currentPortal !== 'CUSTOM') {
                                        if (accessOnlyProjectModule) {
                                            history.push(
                                                `/custom/cost-tracking/projects/`
                                            );
                                        } else {
                                            history.push(`/custom/templates/`);
                                        }
                                        localStorage.setItem(
                                            'lastClickedPortal',
                                            'CUSTOM'
                                        );
                                    }
                                }}
                                value={'CUSTOM'}
                                disabled={!portalButtons['CUSTOM']}
                            >
                                <Box className="flex flex--aic ">
                                    <FWIcon
                                        name="bi bi-sliders"
                                        className="mr--5"
                                        style={{ fontSize: '1.5rem' }}
                                    />
                                    <FWTypography>Customize</FWTypography>
                                </Box>
                            </FWMenuItem>
                            {/* <Divider /> */}
                            {/* <FWMenuItem
                                onClick={() => {
                                    if (currentPortal !== 'PROFILE') {
                                        history.push(`/user/profile`);
                                    }
                                }}
                                value={'PROFILE'}
                            >
                                <FWIcon
                                    name="bi bi-person-badge"
                                    className="mr--5"
                                    style={{ fontSize: '1.5rem' }}
                                />
                                Profile
                            </FWMenuItem>

                            <FWMenuItem onClick={logOut}>
                                <FWIcon
                                    name="bi bi-box-arrow-in-left"
                                    className="mr--5"
                                    style={{ fontSize: '1.5rem' }}
                                />
                                Log out
                            </FWMenuItem> */}
                        </FWSelect>
                    </Box>
                )}
                <Button
                    aria-haspopup="true"
                    aria-controls="navbar-menu"
                    id="navbar-menu-button"
                    startIcon={
                        <Avatar
                            alt="user profile picture"
                            className="logo"
                            variant="circular"
                            src={
                                authData.details !== null
                                    ? authData.details.user_picture_url
                                    : ''
                            }
                        />
                    }
                    style={{
                        textAlign: 'left',
                        minWidth: 'max-content',
                        justifyContent: 'flex-start',
                    }}
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                    // disabled={!showOnlyLogoutButton}
                >
                    <div>
                        <FWTypography
                            sx={{
                                color: 'text.primary',
                                fontSize: '0.9rem',
                            }}
                        >
                            <Box gap={1} className="flex flex--aic">
                                {authData.details !== null
                                    ? `${authData.details.name}`
                                    : ''}
                                <Box color={'secondary.main'}>
                                    <i className="bi bi-chevron-down"></i>
                                </Box>
                            </Box>
                        </FWTypography>
                        <FWTypography
                            sx={{
                                color: 'text.secondary',
                                fontSize: '0.8rem',
                            }}
                        >
                            {authData.details !== null
                                ? authData.details.enterprise_name
                                : ''}
                        </FWTypography>
                    </div>
                </Button>

                <FWMenu
                    id="navbar-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'navbar-menu-button',
                    }}
                >
                    <FWMenuItem
                        onClick={() => {
                            //
                            if (currentPortal !== 'PROFILE') {
                                //
                                history.push(`/user/profile`);
                            }
                        }}
                        value={'PROFILE'}
                    >
                        <FWIcon
                            name="bi bi-person-badge"
                            className="mr--5"
                            style={{ fontSize: '1.5rem' }}
                        />
                        User Profile
                    </FWMenuItem>
                    <FWMenuItem
                        onClick={() => {
                            if (currentPortal !== 'USER_SETTINGS') {
                                history.push(`/user/user-settings`);
                            }
                        }}
                        value={'USER_SETTINGS'}
                    >
                        <FWIcon
                            name="bi bi-sliders2"
                            className="mr--5"
                            style={{ fontSize: '1.5rem' }}
                        />
                        User Settings
                    </FWMenuItem>
                    <FWMenuItem onClick={logOut}>
                        <FWIcon
                            name="bi bi-box-arrow-in-left"
                            className="mr--10"
                            style={{ fontSize: '1.5rem' }}
                        />
                        Log out
                    </FWMenuItem>
                </FWMenu>
            </Box>
        </div>
    );
}
