import { get } from '../../Utils/api';
import { IDeliveryScheduleList } from '../FWDeliverySchedulePopup';

export const fetchPODeliverySchedule = async (
    poUid: string
): Promise<IDeliveryScheduleList[]> => {
    const response: any = await get<string>(
        `/delivery_schedules/purchase_order/${poUid}/?enterprise_type=BUYER`
    );
    const data: any = response.data;
    if (data) {
        const deliveryScheduleItems: any[] = [];
        data.forEach((dsItem: any) => {
            deliveryScheduleItems.push(
                ...dsItem.delivery_schedule_items.map((itm: any) => {
                    return {
                        ...itm,
                        measurement_unit_name:
                            dsItem.po_item_quantity_information
                                .measurement_unit_name,
                        purchase_order_item: dsItem.purchase_order_item || '',
                        item_name: dsItem.po_item_information.buyer_item_name,
                    };
                })
            );
        });

        return deliveryScheduleItems.map((item: any) => {
            const newItem: IDeliveryScheduleList = {
                quantity: item.quantity,
                deliveryDate: item.delivery_date,
                measurementUnit: item.measurement_unit_name,
                poItemUid: item.purchase_order_item,
                itemName: item.item_name,
            };
            return newItem;
        });
    }
    return response;
};

export const fetchItemDeliverySchedule = async (
    poItemUid: string,
    type: 'SELLER' | 'BUYER' = 'BUYER'
): Promise<IDeliveryScheduleList[]> => {
    const response: any = await get<string>(
        `/delivery_schedules/?purchase_order_item_id=${poItemUid}&enterprise_type=${type}`
    );
    const data: any = response.data;
    if (data) {
        const deliveryScheduleItems: any[] = [];
        [data].forEach((dsItem: any) => {
            deliveryScheduleItems.push(
                ...dsItem.delivery_schedule_items.map((itm: any) => {
                    return {
                        ...itm,
                        measurement_unit_name:
                            dsItem.po_item_quantity_information
                                .measurement_unit_name,
                        purchase_order_item: dsItem.purchase_order_item || '',
                        item_name: dsItem.po_item_information
                            ? dsItem.po_item_information.buyer_item_name || ''
                            : '',
                    };
                })
            );
        });
        return deliveryScheduleItems.map((item: any) => {
            const newItem: IDeliveryScheduleList = {
                quantity: item.quantity,
                deliveryDate: item.delivery_date,
                measurementUnit: item.measurement_unit_name,
                poItemUid: item.purchase_order_item,
                itemName: item.item_name,
            };
            return newItem;
        });
    }
    return response;
};
