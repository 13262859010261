import { baseApiSlice } from '../../Redux/Slices/BaseApiSlice';
import { post } from '../../Utils/api';
import {
    IHARawResponseType,
    IHARequestPayloadType,
    IHASemiProcessedResponseType,
} from '../Interfaces/IHistoricalAnalyticsApi.model';

export const fetchGraphData = async (
    payload: IHARequestPayloadType
): Promise<IHASemiProcessedResponseType[]> => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await post<
                IHARequestPayloadType,
                IHARawResponseType[]
            >(`/analytics/historical_analytics/`, payload);
            //
            let processedData: IHASemiProcessedResponseType[] = [];

            response.data.forEach((row) => {
                const processedRow: IHASemiProcessedResponseType = {
                    group_details: {},
                    metric_value: 0,
                };
                Object.keys(row).forEach((key) => {
                    let formattedKey = key;
                    if (formattedKey.endsWith('_grouped')) {
                        formattedKey = formattedKey.slice(
                            0,
                            formattedKey.length - 8
                        );
                    }
                    if (key === 'metric_value') {
                        processedRow.metric_value = row[key] as number;
                    } else {
                        processedRow.group_details[formattedKey] = row[
                            key
                        ] as string;
                    }
                });
                processedData.push(processedRow);
            });
            processedData = processedData.filter(
                (element) =>
                    element['metric_value'] !== null &&
                    element['metric_value'] !== undefined
            );
            resolve(processedData);
        } catch (e) {
            reject(e);
        }
    });
};

export const { useFetchGraphDataMutation } = baseApiSlice.injectEndpoints({
    endpoints: (builder) => ({
        fetchGraphData: builder.mutation<
            IHASemiProcessedResponseType[],
            { payload: IHARequestPayloadType }
        >({
            query: ({ payload }) => ({
                url: `/analytics/historical_analytics/`,
                method: 'POST',
                body: payload,
            }),
            transformResponse: (baseQueryReturnValue: IHARawResponseType[]) => {
                let processedData: IHASemiProcessedResponseType[] = [];

                baseQueryReturnValue.forEach((row) => {
                    const processedRow: IHASemiProcessedResponseType = {
                        group_details: {},
                        metric_value: 0,
                    };
                    Object.keys(row).forEach((key) => {
                        let formattedKey = key;
                        if (formattedKey.endsWith('_grouped')) {
                            formattedKey = formattedKey.slice(
                                0,
                                formattedKey.length - 8
                            );
                        }
                        if (key === 'metric_value') {
                            processedRow.metric_value = row[key] as number;
                        } else {
                            processedRow.group_details[formattedKey] = row[
                                key
                            ] as string;
                        }
                    });
                    processedData.push(processedRow);
                });
                processedData = processedData.filter(
                    (element) =>
                        element['metric_value'] !== null &&
                        element['metric_value'] !== undefined
                );
                return processedData;
            },
        }),
    }),
});
