import { useContext, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FWButton } from '../../Common/FWButton';
import { FWTooltip } from '../../Common/FWCustomTooltip';
import FWIcon from '../../Common/FWIcon';
import { FWTypography } from '../../Common/FWTypograhy';
import { AuthContext } from '../../Contexts/AuthContext';
import { VerificationActions } from '../../Redux/Reducers/VerificationReducers';
import {
    IVerificationState,
    toggleVerificationPopup,
    VerificationStatus,
} from '../../Redux/Slices/VerificationSlice';
import { IGlobalAppStore } from '../../Redux/Store';

const VerificationStatusButton = () => {
    const dispatch = useDispatch();

    const { verificationPopupVisible, allUserEntities }: IVerificationState =
        useSelector(
            ({ VerificationStore }: IGlobalAppStore) => VerificationStore
        );

    const { checkPermission } = useContext(AuthContext);
    const isAdmin = checkPermission('GLOBAL_ADMIN', null, null, null);

    const handleVerificationPopup = () => {
        dispatch(
            toggleVerificationPopup({
                type: VerificationActions.TOGGLE_POPUP,
                value: !verificationPopupVisible,
            })
        );
    };

    const unverifiedEntities = useMemo(() => {
        return allUserEntities.filter(
            (entity) =>
                entity.verificationStatus !== VerificationStatus.VERIFIED
        );
    }, [allUserEntities]);

    const unverifiedEntityNames = useMemo(() => {
        return unverifiedEntities.map((entity) => entity.entityName);
    }, [unverifiedEntities]);

    return unverifiedEntities.length > 0 ? (
        <>
            {isAdmin ? (
                <>
                    <FWTooltip
                        title={`Verification required for ${unverifiedEntityNames.join(
                            ', '
                        )}`}
                    >
                        <div>
                            <FWButton
                                color="error"
                                variant="contained"
                                onClick={handleVerificationPopup}
                                sx={{
                                    minWidth: 'max-content',
                                    whiteSpace: 'nowrap',
                                }}
                                size="small"
                            >
                                <FWTypography variant="h5">
                                    Verification
                                </FWTypography>
                            </FWButton>
                        </div>
                    </FWTooltip>
                </>
            ) : (
                <FWTooltip
                    title={`Verification pending for ${unverifiedEntityNames.join(
                        ', '
                    )}`}
                >
                    <div>
                        <FWButton
                            color="error"
                            variant="outlined"
                            sx={{
                                minWidth: 'max-content',
                                whiteSpace: 'nowrap',
                                pointerEvents: 'none',
                            }}
                        >
                            <FWTypography variant="h5">
                                <FWIcon name="bi bi-exclamation-circle" />
                                &nbsp; Verification required
                            </FWTypography>
                        </FWButton>
                    </div>
                </FWTooltip>
            )}
        </>
    ) : (
        <></>
    );
};

export default VerificationStatusButton;
