import { Box, Chip } from '@mui/material';
import React from 'react';

const VLNProfileItemCatalogFilters = ({
    tagsWithCount,
    selectedFilter,
    onFilterChange,
}: {
    tagsWithCount: {
        [key: string]: number;
    };
    selectedFilter: string;
    onFilterChange: (filter: string) => void;
}) => {
    return (
        <Box marginBottom={4} className={'flex flex--aic'} gap={2}>
            {Object.keys(tagsWithCount)
                // 'all' should come first
                .sort((a, b) => (a === 'All' ? -1 : 0))
                .map((tag, i) => (
                    <Chip
                        key={i}
                        label={`${tag} (${tagsWithCount[tag]})`}
                        color={selectedFilter === tag ? 'primary' : 'default'}
                        onClick={() => onFilterChange(tag)}
                    />
                ))}
        </Box>
    );
};

export default VLNProfileItemCatalogFilters;
