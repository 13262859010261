import * as XLSX from 'xlsx';
import { IBulkImportTemplateFields } from '../Services/BulkImportService';

function restructureCSVHeaders(csvHeaders: IBulkImportTemplateFields[]) {
    const csvTemplateHeaderRows: [string[], string[], string[]] = [[], [], []];
    csvHeaders.forEach((header) => {
        csvTemplateHeaderRows[0].push(header.explanation);
        csvTemplateHeaderRows[1].push(header.format);
        csvTemplateHeaderRows[2].push(header.header);
    });
    return csvTemplateHeaderRows;
}

export const downloadCSVTemplate = (
    csvHeaders: IBulkImportTemplateFields[],
    resourceType: string,
    fileName: string | undefined
) => {
    const restructureCSVHeadersArr = restructureCSVHeaders(csvHeaders);

    // Add a blank row after the second row
    restructureCSVHeadersArr.splice(2, 0, [' ']);

    // export to csv
    // const csv = Papa.unparse(restructureCSVHeadersArr, {});
    // var BOM = new Uint8Array([0xef, 0xbb, 0xbf]);
    // const blob = new Blob([BOM, csv]);
    // // let csvContent = 'data:text/csv;charset=utf-8,' + csvHeaders.join(',');
    // // const encodedUri = encodeURI(csvContent);
    // const link = document.createElement('a');
    // link.setAttribute('href', /* encodedUri */ URL.createObjectURL(blob));
    // link.setAttribute('download', `${fileName}.csv`);
    // document.body.appendChild(link);
    // link.click();
    // link.remove();

    // export to xlsx
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(restructureCSVHeadersArr, {
        skipHeader: true,
    });
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(
        workbook,
        `${
            resourceType === 'BUYER'
                ? 'CUSTOMER'
                : resourceType === 'RFQ_ITEM' ||
                  resourceType === 'PO_GROUP_ITEM' ||
                  resourceType === 'VENDOR' ||
                  resourceType === 'ITEM'
                ? fileName
                : resourceType
        }.xlsx`
    );
};

export const downloadFileFromURL = (uri: string, name: string) => {
    const link = document.createElement('a');
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};
