import { cloneDeep } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { HookStateValue, useHookState } from '../../Common/Hooks/StateHook';
import {
    getGraphSettings_VendorBidWinPercentage,
    getGraphSettings_VendorItemsBought,
    getGraphSettings_VendorPoTypesOverTime,
    getGraphSettings_VendorPosOverTime,
    getGraphSettings_VendorQualityOverTime,
    getGraphSettings_VendorRateOverTime,
} from '../Constants/Dynamic_Vendor';
import { getHADataRequestPayload } from '../helperFunctions/PayloadHelper';
import {
    DynamicAnalyticsVendorBidWinPercentageData,
    DynamicAnalyticsVendorItemsBoughtData,
    DynamicAnalyticsVendorMetricByItemResponseType,
    DynamicAnalyticsVendorMetricByMonthResponseType,
    DynamicAnalyticsVendorMetricByQuarterResponseType,
    DynamicAnalyticsVendorPoTypesOverTimeData,
    DynamicAnalyticsVendorPosOverTimeData,
    DynamicAnalyticsVendorQualityOverTimeData,
    DynamicAnalyticsVendorRateOverTimeData,
    DynamicVendorAnalyticsHookDataType,
} from '../Interfaces/DynamicAnalyticsInterfaces.model';
import { IHistoricalAnalyticsChart } from '../Interfaces/IHistoricalAnalytics.model';
import { fetchGraphData } from '../Services/HAService';

type PeriodListItemTypeMonth = {
    period: {
        month: number;
        year: number;
    };
};

type PeriodListItemTypeQuarter = {
    period: {
        quarter: number;
        year: number;
    };
};

/**
 * We dont want holes in our data, so we populate the data with all months
 * from the first to the last one found in results
 */
const initializeListWithMonths = (
    returnedData: DynamicAnalyticsVendorMetricByMonthResponseType[][]
): PeriodListItemTypeMonth[] => {
    let minYear = 9999;
    let maxYear = 0;
    let minMonth = 9999;
    let maxMonth = 0;
    for (let curMetric of returnedData) {
        for (let curDataPoint of curMetric) {
            const curMonth =
                curDataPoint.group_details.po__submission_datetime__month;
            const curYear =
                curDataPoint.group_details.po__submission_datetime__year;
            if (curMonth && curYear) {
                if (
                    curYear < minYear ||
                    (curYear === minYear && curMonth < minMonth)
                ) {
                    minYear = curYear;
                    minMonth = curMonth;
                }
                if (
                    curYear > maxYear ||
                    (curYear === maxYear && curMonth > maxMonth)
                ) {
                    maxYear = curYear;
                    maxMonth = curMonth;
                }
            }
        }
    }

    const initList: PeriodListItemTypeMonth[] = [];
    if (minYear !== 9999 && maxYear !== 0) {
        for (let curYear = minYear; curYear <= maxYear; curYear++) {
            for (
                let curMonth = curYear === minYear ? minMonth : 1;
                curMonth <= (curYear === maxYear ? maxMonth : 12);
                curMonth++
            ) {
                const curData: PeriodListItemTypeMonth = {
                    period: {
                        month: curMonth,
                        year: curYear,
                    },
                };
                initList.push(curData);
            }
        }
    }
    return initList;
};

/**
 * We dont want holes in our data, so we populate the data with all quarters
 * from the first to the last one found in results
 */
const initializeListWithQuarters = (
    returnedData: DynamicAnalyticsVendorMetricByQuarterResponseType[][]
): PeriodListItemTypeQuarter[] => {
    let minYear = 9999;
    let maxYear = 0;
    let minQuarter = 9999;
    let maxQuarter = 0;
    for (let curMetric of returnedData) {
        for (let curDataPoint of curMetric) {
            const curQuarter =
                curDataPoint.group_details
                    .event__live_event_start_datetime__quarter;
            const curYear =
                curDataPoint.group_details
                    .event__live_event_start_datetime__year;
            if (curQuarter && curYear) {
                if (
                    curYear < minYear ||
                    (curYear === minYear && curQuarter < minQuarter)
                ) {
                    minYear = curYear;
                    minQuarter = curQuarter;
                }
                if (
                    curYear > maxYear ||
                    (curYear === maxYear && curQuarter > maxQuarter)
                ) {
                    maxYear = curYear;
                    maxQuarter = curQuarter;
                }
            }
        }
    }

    const initList: PeriodListItemTypeQuarter[] = [];
    if (minYear !== 9999 && maxYear !== 0) {
        for (let curYear = minYear; curYear <= maxYear; curYear++) {
            for (
                let curQuarter = curYear === minYear ? minQuarter : 1;
                curQuarter <= (curYear === maxYear ? maxQuarter : 4);
                curQuarter++
            ) {
                const curData: PeriodListItemTypeQuarter = {
                    period: {
                        quarter: curQuarter,
                        year: curYear,
                    },
                };
                initList.push(curData);
            }
        }
    }
    return initList;
};

export const useVendorDynamicAnalytics_PosOverTime = (
    hookData: DynamicVendorAnalyticsHookDataType
) => {
    const { hookState, updateHookState } = useHookState(HookStateValue.INITIAL);

    const [allData, setAllData] = useState<
        DynamicAnalyticsVendorPosOverTimeData[]
    >([]);

    const [visibleData, setVisibleData] = useState<
        DynamicAnalyticsVendorPosOverTimeData[]
    >([]);

    const [curGraphSettings, setCurGraphSettings] =
        useState<IHistoricalAnalyticsChart | null>(null);

    const toggleCurrencyFilter = useCallback(() => {
        setCurGraphSettings((prev) => {
            if (!prev) {
                return null;
            }
            const newSettings = cloneDeep(prev);
            newSettings.currencySettings.filterCurrenciesIds =
                newSettings.currencySettings.filterCurrenciesIds.length > 0
                    ? []
                    : [hookData.currencyDetails.currencyUuid];
            return newSettings;
        });
    }, [hookData.currencyDetails.currencyUuid]);

    const processResponse = useCallback(
        (
            responseDataList: DynamicAnalyticsVendorMetricByMonthResponseType[][]
        ): DynamicAnalyticsVendorPosOverTimeData[] => {
            const processedData: DynamicAnalyticsVendorPosOverTimeData[] =
                initializeListWithMonths(responseDataList).map((per) => {
                    return {
                        ...per,
                        poCount: 0,
                        poValue: 0,
                    };
                });

            for (let i = 0; i < responseDataList.length; i++) {
                const curMetric = i === 0 ? 'poValue' : 'poCount';
                for (let dataPt of responseDataList[i]) {
                    let matchingRes = processedData.find(
                        (p) =>
                            p.period.month ===
                                dataPt.group_details
                                    .po__submission_datetime__month &&
                            p.period.year ===
                                dataPt.group_details
                                    .po__submission_datetime__year
                    );

                    if (matchingRes) {
                        matchingRes[curMetric] =
                            +(matchingRes[curMetric]
                                ? matchingRes[curMetric]
                                : 0) + +(dataPt?.metric_value ?? 0);
                    }
                }
            }
            return processedData;
        },
        []
    );

    const fetchData = useCallback(
        async (settings: IHistoricalAnalyticsChart) => {
            updateHookState(HookStateValue.LOADING);
            try {
                const payloadList = settings.metrics.map((curMetric) =>
                    getHADataRequestPayload({
                        metricSettings: curMetric,
                        groupingOptions: {
                            groupBy: settings.groupBy,
                            groupFilter: settings.groupFilter,
                            groupSort: settings.groupSort,
                        },
                        filterOptions: settings.filterContoller,
                        currencySettings: settings.currencySettings,
                    })
                );
                const promiseList = payloadList.map((curPayload) =>
                    fetchGraphData(curPayload)
                );
                const responseDataList: any = await Promise.all(promiseList);

                const processedData = processResponse(responseDataList);
                setAllData(processedData);
                updateHookState(HookStateValue.READY);
            } catch (e) {
                updateHookState(HookStateValue.ERROR);
            }
        },
        [processResponse, updateHookState]
    );

    /**
     * Fetches fresh data when graph settings change.
     * Should only happen once on initial load and whenever user changes date range /Currency
     */
    useEffect(() => {
        if (curGraphSettings) {
            fetchData(curGraphSettings);
        }
    }, [fetchData, curGraphSettings]);

    /**
     * Initializes graph settings.
     * Runs if props change
     * Props should only change when user changes daterange
     * */
    useEffect(() => {
        setCurGraphSettings(
            getGraphSettings_VendorPosOverTime({
                currencyDetails: {
                    currencyCodeAbbreviation:
                        hookData.currencyDetails.currencyCodeAbbreviation,
                    currencyUuid: hookData.currencyDetails.currencyUuid,
                    currencyName: hookData.currencyDetails.currencyName,
                    currencySymbol: hookData.currencyDetails.currencySymbol,
                },
                dateRange: hookData.dateRange,
                enterpriseItemUuid: hookData.enterpriseItemUuid,
                measurementUnitUuid: hookData.measurementUnitUuid,
                sellerEntityUuid: hookData.sellerEntityUuid,
                sellerEntityName: hookData.sellerEntityName,
            })
        );
    }, [hookData]);

    /**
     * Sets visible data whenever all data changes or a frontend filter is applied.
     * Currently not useful as there are no frontend filters
     * */
    useEffect(() => {
        if (allData.every((datum) => !datum.poCount && !datum.poValue)) {
            setVisibleData([]);
        } else {
            setVisibleData(allData);
        }
    }, [allData]);

    return {
        hookState,
        visibleData,
        toggleCurrencyFilter,
        curGraphSettings,
    };
};

export const useVendorDynamicAnalytics_ItemsBought = (
    hookData: DynamicVendorAnalyticsHookDataType
) => {
    const { hookState, updateHookState } = useHookState(HookStateValue.INITIAL);

    const [allData, setAllData] = useState<
        DynamicAnalyticsVendorItemsBoughtData[]
    >([]);

    const [visibleData, setVisibleData] = useState<
        DynamicAnalyticsVendorItemsBoughtData[]
    >([]);

    const [curGraphSettings, setCurGraphSettings] =
        useState<IHistoricalAnalyticsChart | null>(null);

    const toggleCurrencyFilter = useCallback(() => {
        setCurGraphSettings((prev) => {
            if (!prev) {
                return null;
            }
            const newSettings = cloneDeep(prev);
            newSettings.currencySettings.filterCurrenciesIds =
                newSettings.currencySettings.filterCurrenciesIds.length > 0
                    ? []
                    : [hookData.currencyDetails.currencyUuid];
            return newSettings;
        });
    }, [hookData.currencyDetails.currencyUuid]);

    const processResponse = useCallback(
        (
            responseDataList: DynamicAnalyticsVendorMetricByItemResponseType[][]
        ): DynamicAnalyticsVendorItemsBoughtData[] => {
            const processedData: DynamicAnalyticsVendorItemsBoughtData[] = [];

            for (let i = 0; i < responseDataList.length; i++) {
                const curMetric =
                    i === 0
                        ? 'poValueThisVendor'
                        : i === 1
                        ? 'poValueOtherVendors'
                        : 'vendorCount';
                for (let dataPt of responseDataList[i]) {
                    let matchingRes = processedData.find(
                        (res) =>
                            res.itemDetails.enterpriseItemUuid ===
                            dataPt.group_details
                                .enterprise_item__enterprise_item_id
                    );

                    if (!matchingRes) {
                        let newPoint: DynamicAnalyticsVendorItemsBoughtData = {
                            itemDetails: {
                                enterpriseItemUuid:
                                    dataPt.group_details
                                        .enterprise_item__enterprise_item_id,
                                name: dataPt.group_details
                                    .enterprise_item__name,
                            },
                            poValueThisVendor: 0,
                            poValueOtherVendors: 0,
                            vendorCount: 0,
                        };
                        matchingRes = newPoint;
                        processedData.push(newPoint);
                    }
                    matchingRes[curMetric] = dataPt.metric_value ?? 0;
                }
            }
            const filteredData = processedData.filter(
                (res) => res.poValueThisVendor > 0
            );
            return filteredData;
        },
        []
    );

    const fetchData = useCallback(
        async (settings: IHistoricalAnalyticsChart) => {
            updateHookState(HookStateValue.LOADING);
            try {
                const payloadList = settings.metrics.map((curMetric) =>
                    getHADataRequestPayload({
                        metricSettings: curMetric,
                        groupingOptions: {
                            groupBy: settings.groupBy,
                            groupFilter: settings.groupFilter,
                            groupSort: settings.groupSort,
                        },
                        filterOptions: settings.filterContoller,
                        currencySettings: settings.currencySettings,
                    })
                );
                const promiseList = payloadList.map((curPayload) =>
                    fetchGraphData(curPayload)
                );
                const responseDataList: any = await Promise.all(promiseList);
                const processedData = processResponse(responseDataList);
                setAllData(processedData);
                updateHookState(HookStateValue.READY);
            } catch (e) {
                updateHookState(HookStateValue.ERROR);
            }
        },
        [processResponse, updateHookState]
    );

    /**
     * Fetches fresh data when graph settings change.
     * Should only happen once on initial load and whenever user changes date range/currency
     */
    useEffect(() => {
        if (curGraphSettings) {
            fetchData(curGraphSettings);
        }
    }, [fetchData, curGraphSettings]);

    /**
     * Initializes graph settings.
     * Runs if props change
     * Props should only change when user changes daterange
     * */
    useEffect(() => {
        setCurGraphSettings(
            getGraphSettings_VendorItemsBought({
                currencyDetails: {
                    currencyCodeAbbreviation:
                        hookData.currencyDetails.currencyCodeAbbreviation,
                    currencyUuid: hookData.currencyDetails.currencyUuid,
                    currencyName: hookData.currencyDetails.currencyName,
                    currencySymbol: hookData.currencyDetails.currencySymbol,
                },
                dateRange: hookData.dateRange,
                enterpriseItemUuid: hookData.enterpriseItemUuid,
                measurementUnitUuid: hookData.measurementUnitUuid,
                sellerEntityUuid: hookData.sellerEntityUuid,
                sellerEntityName: hookData.sellerEntityName,
            })
        );
    }, [hookData]);

    /**
     * Sets visible data whenever all data changes or a frontend filter is applied.
     * Keeps the top 10 items by poValueThisVendor
     * */
    useEffect(() => {
        const sortedFilteredData = allData.filter(
            (datum) => datum.poValueThisVendor > 0
        );
        sortedFilteredData.sort((a, b) => {
            return b.poValueThisVendor - a.poValueThisVendor; //largest first
        });
        const topTenData = sortedFilteredData.slice(0, 10);
        setVisibleData(topTenData);
    }, [allData]);

    return {
        hookState,
        visibleData,
        toggleCurrencyFilter,
        curGraphSettings,
    };
};

export const useVendorDynamicAnalytics_RateOverTime = (
    hookData: DynamicVendorAnalyticsHookDataType
) => {
    const { hookState, updateHookState } = useHookState(HookStateValue.INITIAL);

    const [allData, setAllData] = useState<
        DynamicAnalyticsVendorRateOverTimeData[]
    >([]);

    const [visibleData, setVisibleData] = useState<
        DynamicAnalyticsVendorRateOverTimeData[]
    >([]);

    const [curGraphSettings, setCurGraphSettings] =
        useState<IHistoricalAnalyticsChart | null>(null);

    const toggleCurrencyFilter = useCallback(() => {
        setCurGraphSettings((prev) => {
            if (!prev) {
                return null;
            }
            const newSettings = cloneDeep(prev);
            newSettings.currencySettings.filterCurrenciesIds =
                newSettings.currencySettings.filterCurrenciesIds.length > 0
                    ? []
                    : [hookData.currencyDetails.currencyUuid];
            return newSettings;
        });
    }, [hookData.currencyDetails.currencyUuid]);

    const processResponse = useCallback(
        (
            responseDataList: DynamicAnalyticsVendorMetricByMonthResponseType[][]
        ): DynamicAnalyticsVendorRateOverTimeData[] => {
            const processedData: DynamicAnalyticsVendorRateOverTimeData[] =
                initializeListWithMonths(responseDataList).map((per) => {
                    return {
                        ...per,
                        rate: null,
                        quantity: null,
                        avgRate: null,
                    };
                });

            for (let i = 0; i < responseDataList.length; i++) {
                const curMetric =
                    i === 0 ? 'rate' : i === 1 ? 'quantity' : 'avgRate';
                for (let dataPt of responseDataList[i]) {
                    let matchingRes = processedData.find(
                        (p) =>
                            p.period.month ===
                                dataPt.group_details
                                    .po__submission_datetime__month &&
                            p.period.year ===
                                dataPt.group_details
                                    .po__submission_datetime__year
                    );

                    if (matchingRes) {
                        matchingRes[curMetric] = dataPt.metric_value ?? 0;
                    }
                }
            }
            return processedData;
        },
        []
    );

    const fetchData = useCallback(
        async (settings: IHistoricalAnalyticsChart) => {
            updateHookState(HookStateValue.LOADING);
            try {
                const payloadList = settings.metrics.map((curMetric) =>
                    getHADataRequestPayload({
                        metricSettings: curMetric,
                        groupingOptions: {
                            groupBy: settings.groupBy,
                            groupFilter: settings.groupFilter,
                            groupSort: settings.groupSort,
                        },
                        filterOptions: settings.filterContoller,
                        currencySettings: settings.currencySettings,
                    })
                );
                const promiseList = payloadList.map((curPayload) =>
                    fetchGraphData(curPayload)
                );
                const responseDataList: any = await Promise.all(promiseList);
                const processedData = processResponse(responseDataList);
                setAllData(processedData);
                updateHookState(HookStateValue.READY);
            } catch (e) {
                updateHookState(HookStateValue.ERROR);
            }
        },
        [processResponse, updateHookState]
    );

    /**
     * Fetches fresh data when graph settings change.
     * Should only happen once on initial load and whenever user changes date range/currency
     */
    useEffect(() => {
        if (curGraphSettings) {
            fetchData(curGraphSettings);
        }
    }, [fetchData, curGraphSettings]);

    /**
     * Initializes graph settings.
     * Runs if props change
     * Props should only change when user changes daterange
     * */
    useEffect(() => {
        setCurGraphSettings(
            getGraphSettings_VendorRateOverTime({
                currencyDetails: {
                    currencyCodeAbbreviation:
                        hookData.currencyDetails.currencyCodeAbbreviation,
                    currencyUuid: hookData.currencyDetails.currencyUuid,
                    currencyName: hookData.currencyDetails.currencyName,
                    currencySymbol: hookData.currencyDetails.currencySymbol,
                },
                dateRange: hookData.dateRange,
                enterpriseItemUuid: hookData.enterpriseItemUuid,
                measurementUnitUuid: hookData.measurementUnitUuid,
                sellerEntityUuid: hookData.sellerEntityUuid,
                sellerEntityName: hookData.sellerEntityName,
            })
        );
    }, [hookData]);

    /**
     * Sets visible data whenever all data changes or a frontend filter is applied.
     * If all data points have no quantity/rate values (and only avg rate is available), then show nothing.
     * */
    useEffect(() => {
        if (allData.every((datum) => !datum.quantity && !datum.rate)) {
            setVisibleData([]);
        } else {
            setVisibleData(allData);
        }
    }, [allData]);

    return {
        hookState,
        visibleData,
        toggleCurrencyFilter,
        curGraphSettings,
    };
};

export const useVendorDynamicAnalytics_QualityOverTime = (
    hookData: DynamicVendorAnalyticsHookDataType
) => {
    const { hookState, updateHookState } = useHookState(HookStateValue.INITIAL);

    const [allData, setAllData] = useState<
        DynamicAnalyticsVendorQualityOverTimeData[]
    >([]);

    const [visibleData, setVisibleData] = useState<
        DynamicAnalyticsVendorQualityOverTimeData[]
    >([]);

    const [curGraphSettings, setCurGraphSettings] =
        useState<IHistoricalAnalyticsChart | null>(null);

    const toggleCurrencyFilter = useCallback(() => {
        setCurGraphSettings((prev) => {
            if (!prev) {
                return null;
            }
            const newSettings = cloneDeep(prev);
            newSettings.currencySettings.filterCurrenciesIds =
                newSettings.currencySettings.filterCurrenciesIds.length > 0
                    ? []
                    : [hookData.currencyDetails.currencyUuid];
            return newSettings;
        });
    }, [hookData.currencyDetails.currencyUuid]);

    const processResponse = useCallback(
        (
            responseDataList: DynamicAnalyticsVendorMetricByMonthResponseType[][]
        ): DynamicAnalyticsVendorQualityOverTimeData[] => {
            const processedData: DynamicAnalyticsVendorQualityOverTimeData[] =
                initializeListWithMonths(responseDataList).map((per) => {
                    return {
                        ...per,
                        quantity: null,
                        acceptanceRate: null,
                        avgAcceptanceRate: null,
                        onTimeDeliveryRate: null,
                        avgOnTimeDeliveryRate: null,
                    };
                });

            for (let i = 0; i < responseDataList.length; i++) {
                const curMetric =
                    i === 0
                        ? 'quantity'
                        : i === 1
                        ? 'acceptanceRate'
                        : i === 2
                        ? 'avgAcceptanceRate'
                        : i === 3
                        ? 'onTimeDeliveryRate'
                        : 'avgOnTimeDeliveryRate';
                for (let dataPt of responseDataList[i]) {
                    let matchingRes = processedData.find(
                        (p) =>
                            p.period.month ===
                                dataPt.group_details
                                    .po__submission_datetime__month &&
                            p.period.year ===
                                dataPt.group_details
                                    .po__submission_datetime__year
                    );

                    if (matchingRes) {
                        matchingRes[curMetric] = dataPt.metric_value ?? 0;
                    }
                }
            }
            return processedData;
        },
        []
    );

    const fetchData = useCallback(
        async (settings: IHistoricalAnalyticsChart) => {
            updateHookState(HookStateValue.LOADING);
            try {
                const payloadList = settings.metrics.map((curMetric) =>
                    getHADataRequestPayload({
                        metricSettings: curMetric,
                        groupingOptions: {
                            groupBy: settings.groupBy,
                            groupFilter: settings.groupFilter,
                            groupSort: settings.groupSort,
                        },
                        filterOptions: settings.filterContoller,
                        currencySettings: settings.currencySettings,
                    })
                );
                const promiseList = payloadList.map((curPayload) =>
                    fetchGraphData(curPayload)
                );
                const responseDataList: any = await Promise.all(promiseList);
                const processedData = processResponse(responseDataList);
                setAllData(processedData);
                updateHookState(HookStateValue.READY);
            } catch (e) {
                updateHookState(HookStateValue.ERROR);
            }
        },
        [processResponse, updateHookState]
    );

    /**
     * Fetches fresh data when graph settings change.
     * Should only happen once on initial load and whenever user changes date range/currency
     */
    useEffect(() => {
        if (curGraphSettings) {
            fetchData(curGraphSettings);
        }
    }, [fetchData, curGraphSettings]);

    /**
     * Initializes graph settings.
     * Runs if props change
     * Props should only change when user changes daterange
     * */
    useEffect(() => {
        setCurGraphSettings(
            getGraphSettings_VendorQualityOverTime({
                currencyDetails: {
                    currencyCodeAbbreviation:
                        hookData.currencyDetails.currencyCodeAbbreviation,
                    currencyUuid: hookData.currencyDetails.currencyUuid,
                    currencyName: hookData.currencyDetails.currencyName,
                    currencySymbol: hookData.currencyDetails.currencySymbol,
                },
                dateRange: hookData.dateRange,
                enterpriseItemUuid: hookData.enterpriseItemUuid,
                measurementUnitUuid: hookData.measurementUnitUuid,
                sellerEntityUuid: hookData.sellerEntityUuid,
                sellerEntityName: hookData.sellerEntityName,
            })
        );
    }, [hookData]);

    /**
     * Sets visible data whenever all data changes or a frontend filter is applied.
     * Currently not useful as there are no frontend filters
     * */
    useEffect(() => {
        if (
            allData.every((datum) => !datum.acceptanceRate && !datum.quantity)
        ) {
            setVisibleData([]);
        } else {
            setVisibleData(allData);
        }
    }, [allData]);

    return {
        hookState,
        visibleData,
        toggleCurrencyFilter,
        curGraphSettings,
    };
};

export const useVendorDynamicAnalytics_BidWinPercentage = (
    hookData: DynamicVendorAnalyticsHookDataType
) => {
    const { hookState, updateHookState } = useHookState(HookStateValue.INITIAL);

    const [allData, setAllData] = useState<
        DynamicAnalyticsVendorBidWinPercentageData[]
    >([]);

    const [visibleData, setVisibleData] = useState<
        DynamicAnalyticsVendorBidWinPercentageData[]
    >([]);

    const [curGraphSettings, setCurGraphSettings] =
        useState<IHistoricalAnalyticsChart | null>(null);

    const toggleCurrencyFilter = useCallback(() => {
        setCurGraphSettings((prev) => {
            if (!prev) {
                return null;
            }
            const newSettings = cloneDeep(prev);
            newSettings.currencySettings.filterCurrenciesIds =
                newSettings.currencySettings.filterCurrenciesIds.length > 0
                    ? []
                    : [hookData.currencyDetails.currencyUuid];

            return newSettings;
        });
    }, [hookData.currencyDetails.currencyUuid]);

    const processResponse = useCallback(
        (
            responseDataList: DynamicAnalyticsVendorMetricByQuarterResponseType[][]
        ): DynamicAnalyticsVendorBidWinPercentageData[] => {
            const processedData: DynamicAnalyticsVendorBidWinPercentageData[] =
                initializeListWithQuarters(responseDataList).map((per) => {
                    return {
                        ...per,
                        itemsWon: null,
                        totalItems: null,
                    };
                });

            for (let i = 0; i < responseDataList.length; i++) {
                const curMetric = i === 0 ? 'itemsWon' : 'totalItems';
                for (let dataPt of responseDataList[i]) {
                    let matchingRes = processedData.find(
                        (p) =>
                            p.period.quarter ===
                                dataPt.group_details
                                    .event__live_event_start_datetime__quarter &&
                            p.period.year ===
                                dataPt.group_details
                                    .event__live_event_start_datetime__year
                    );

                    if (matchingRes) {
                        matchingRes[curMetric] = dataPt.metric_value ?? 0;
                    }
                }
            }
            return processedData;
        },
        []
    );

    const fetchData = useCallback(
        async (settings: IHistoricalAnalyticsChart) => {
            updateHookState(HookStateValue.LOADING);
            try {
                const payloadList = settings.metrics.map((curMetric) =>
                    getHADataRequestPayload({
                        metricSettings: curMetric,
                        groupingOptions: {
                            groupBy: settings.groupBy,
                            groupFilter: settings.groupFilter,
                            groupSort: settings.groupSort,
                        },
                        filterOptions: settings.filterContoller,
                        currencySettings: settings.currencySettings,
                    })
                );
                const promiseList = payloadList.map((curPayload) =>
                    fetchGraphData(curPayload)
                );
                const responseDataList: any = await Promise.all(promiseList);
                const processedData = processResponse(responseDataList);
                setAllData(processedData);
                updateHookState(HookStateValue.READY);
            } catch (e) {
                updateHookState(HookStateValue.ERROR);
            }
        },
        [processResponse, updateHookState]
    );

    /**
     * Fetches fresh data when graph settings change.
     * Should only happen once on initial load and whenever user changes date range/currency
     */
    useEffect(() => {
        if (curGraphSettings) {
            fetchData(curGraphSettings);
        }
    }, [fetchData, curGraphSettings]);

    /**
     * Initializes graph settings.
     * Runs if props change
     * Props should only change when user changes daterange
     * */
    useEffect(() => {
        setCurGraphSettings(
            getGraphSettings_VendorBidWinPercentage({
                currencyDetails: {
                    currencyCodeAbbreviation:
                        hookData.currencyDetails.currencyCodeAbbreviation,
                    currencyUuid: hookData.currencyDetails.currencyUuid,
                    currencyName: hookData.currencyDetails.currencyName,
                    currencySymbol: hookData.currencyDetails.currencySymbol,
                },
                dateRange: hookData.dateRange,
                enterpriseItemUuid: hookData.enterpriseItemUuid,
                measurementUnitUuid: hookData.measurementUnitUuid,
                sellerEntityUuid: hookData.sellerEntityUuid,
                sellerEntityName: hookData.sellerEntityName,
            })
        );
    }, [hookData]);

    /**
     * Sets visible data whenever all data changes or a frontend filter is applied.
     * Currently not useful as there are no frontend filters
     * */
    useEffect(() => {
        setVisibleData(allData);
    }, [allData]);

    return {
        hookState,
        visibleData,
        toggleCurrencyFilter,
        curGraphSettings,
    };
};

export const useVendorDynamicAnalytics_PoTypesOverTime = (
    hookData: DynamicVendorAnalyticsHookDataType
) => {
    const { hookState, updateHookState } = useHookState(HookStateValue.INITIAL);

    const [allData, setAllData] = useState<
        DynamicAnalyticsVendorPoTypesOverTimeData[]
    >([]);

    const [visibleData, setVisibleData] = useState<
        DynamicAnalyticsVendorPoTypesOverTimeData[]
    >([]);

    const [curGraphSettings, setCurGraphSettings] =
        useState<IHistoricalAnalyticsChart | null>(null);

    const toggleCurrencyFilter = useCallback(() => {
        setCurGraphSettings((prev) => {
            if (!prev) {
                return null;
            }
            const newSettings = cloneDeep(prev);
            newSettings.currencySettings.filterCurrenciesIds =
                newSettings.currencySettings.filterCurrenciesIds.length > 0
                    ? []
                    : [hookData.currencyDetails.currencyUuid];
            return newSettings;
        });
    }, [hookData.currencyDetails.currencyUuid]);

    const processResponse = useCallback(
        (
            responseDataList: DynamicAnalyticsVendorMetricByMonthResponseType[][]
        ): DynamicAnalyticsVendorPoTypesOverTimeData[] => {
            const processedData: DynamicAnalyticsVendorPoTypesOverTimeData[] =
                initializeListWithMonths(responseDataList).map((per) => {
                    return {
                        ...per,
                        rfqPoCount: null,
                        directPoCount: null,
                    };
                });

            for (let i = 0; i < responseDataList.length; i++) {
                const curMetric = i === 0 ? 'rfqPoCount' : 'directPoCount';
                for (let dataPt of responseDataList[i]) {
                    let matchingRes = processedData.find(
                        (p) =>
                            p.period.month ===
                                dataPt.group_details
                                    .po__submission_datetime__month &&
                            p.period.year ===
                                dataPt.group_details
                                    .po__submission_datetime__year
                    );

                    if (matchingRes) {
                        matchingRes[curMetric] = dataPt.metric_value ?? 0;
                    }
                }
            }
            return processedData;
        },
        []
    );

    const fetchData = useCallback(
        async (settings: IHistoricalAnalyticsChart) => {
            updateHookState(HookStateValue.LOADING);
            try {
                const payloadList = settings.metrics.map((curMetric) =>
                    getHADataRequestPayload({
                        metricSettings: curMetric,
                        groupingOptions: {
                            groupBy: settings.groupBy,
                            groupFilter: settings.groupFilter,
                            groupSort: settings.groupSort,
                        },
                        filterOptions: settings.filterContoller,
                        currencySettings: settings.currencySettings,
                    })
                );
                const promiseList = payloadList.map((curPayload) =>
                    fetchGraphData(curPayload)
                );
                const responseDataList: any = await Promise.all(promiseList);
                const processedData = processResponse(responseDataList);
                setAllData(processedData);
                updateHookState(HookStateValue.READY);
            } catch (e) {
                updateHookState(HookStateValue.ERROR);
            }
        },
        [processResponse, updateHookState]
    );

    /**
     * Fetches fresh data when graph settings change.
     * Should only happen once on initial load and whenever user changes date range/currency
     */
    useEffect(() => {
        if (curGraphSettings) {
            fetchData(curGraphSettings);
        }
    }, [fetchData, curGraphSettings]);

    /**
     * Initializes graph settings.
     * Runs if props change
     * Props should only change when user changes daterange
     * */
    useEffect(() => {
        setCurGraphSettings(
            getGraphSettings_VendorPoTypesOverTime({
                currencyDetails: {
                    currencyCodeAbbreviation:
                        hookData.currencyDetails.currencyCodeAbbreviation,
                    currencyUuid: hookData.currencyDetails.currencyUuid,
                    currencyName: hookData.currencyDetails.currencyName,
                    currencySymbol: hookData.currencyDetails.currencySymbol,
                },
                dateRange: hookData.dateRange,
                enterpriseItemUuid: hookData.enterpriseItemUuid,
                measurementUnitUuid: hookData.measurementUnitUuid,
                sellerEntityUuid: hookData.sellerEntityUuid,
                sellerEntityName: hookData.sellerEntityName,
            })
        );
    }, [hookData]);

    /**
     * Sets visible data whenever all data changes or a frontend filter is applied.
     * If all data points have no values, then show nothing.
     * */
    useEffect(() => {
        if (
            allData.every((datum) => !datum.rfqPoCount && !datum.directPoCount)
        ) {
            setVisibleData([]);
        } else {
            setVisibleData(allData);
        }
    }, [allData]);

    return {
        hookState,
        visibleData,
        toggleCurrencyFilter,
        curGraphSettings,
    };
};
