import { CircularProgress, styled } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import { isUndefined } from 'lodash';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { FWTooltip } from '../../Common/FWCustomTooltip';
import FWIcon from '../../Common/FWIcon';
import { FWTypography } from '../../Common/FWTypograhy';
import { IFileResourceType } from '../../Components/Shared/UploadFile';
import { IBulkImport } from '../Interfaces/BulkImportInterface';
import { uploadBulkImportFile } from '../Services/BulkImportService';

interface IBulkImportUploadFileProps {
    id?: string; // unique id for the DOM element
    label?: string; // text besides the add file icon
    labelColor?: string; // FWTypography colors (defaulted to text.secondary)
    labelvariant?: Variant; // FWTypography variant (defaulted to body1)
    icon?: 'CLIP' | 'PLUS';
    allowedFileSizeInMB?: number; // in MB (defaulted to  MB)
    existingFiles: IBulkImport[]; // existing list of the IAttachment list from your main state
    maxAllowedFiles?: number;
    multiple?: boolean;
    resourceType: IFileResourceType;
    allowedFileTypes?: string[];
    disabled?: boolean;
    onFileUpload?: (file: IBulkImport[]) => void;
    clipIconSize?: number;
    maxFileNameLength?: number;
}

const Label = styled('label')({
    cursor: 'pointer',
    '&.disabled': {
        cursor: 'not-allowed',
    },
});

const fileType: {
    [key: string]: string;
} = {
    'text/csv': 'csv',
    'image/jpeg': 'jpeg',
    'image/jpg': 'jpg',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
};

const BulkImportUploadFile = ({
    id,
    label = 'File',
    labelColor = 'text.secondary',
    labelvariant = 'body1',
    icon = 'PLUS',
    allowedFileSizeInMB = 5,
    existingFiles,
    maxAllowedFiles,
    onFileUpload,
    multiple,
    resourceType,
    allowedFileTypes,
    disabled,
    clipIconSize = 16,
    maxFileNameLength = 100,
}: IBulkImportUploadFileProps) => {
    const buttonIcon =
        icon === 'PLUS' ? 'bi bi-plus-circle' : 'bi bi-paperclip';
    const [uploading, setUploading] = useState<boolean>(false);

    const allowedFileSizeInBytes = allowedFileSizeInMB * 1024 * 1024;
    const toastTimeout = 6000;

    const existingFileNames = existingFiles.map((file) => file.file_name);

    const reachedMaxFileLimit =
        existingFiles.length >= (maxAllowedFiles || Infinity);

    const handleFileUpload = async (e: any) => {
        if (e.target.files) {
            const selectedFiles: File[] = Array.from(e.target.files);
            const validFiles: File[] = [];
            const overSizeLimitFiles: File[] = [];
            const duplicateFiles: File[] = [];
            const longNameFiles: File[] = [];

            if (allowedFileTypes) {
                for (const file of selectedFiles) {
                    const ft = fileType[file.type];
                    if (!allowedFileTypes.includes(ft)) {
                        toast.error(
                            `File type '${ft || file.type}' not allowed - ${
                                file.name
                            }`
                        );
                        e.target.value = null;
                        return false;
                    }
                }
            }

            // stop upload if more than allowed files selected
            if (
                !isUndefined(maxAllowedFiles) &&
                selectedFiles.length > maxAllowedFiles - existingFiles.length
            ) {
                toast.error(`Maximum of ${maxAllowedFiles} file(s) allowed`);
                e.target.value = null;
                return false;
            }

            // sort out valid and invalid files
            for (let currentFile of selectedFiles) {
                if (
                    existingFileNames.includes(currentFile.name) ||
                    validFiles
                        .map((file) => file.name)
                        .includes(currentFile.name)
                ) {
                    duplicateFiles.push(currentFile);
                } else if (currentFile.size > allowedFileSizeInBytes) {
                    overSizeLimitFiles.push(currentFile);
                } else if (currentFile.name.length > maxFileNameLength) {
                    longNameFiles.push(currentFile);
                } else {
                    validFiles.push(currentFile);
                }
            }

            // show error for files which are duplicate
            if (Array.from(duplicateFiles).length !== 0) {
                toast.error(
                    duplicateFiles.length > 1
                        ? `${duplicateFiles.length} files had duplicate names`
                        : `File had duplicate name`,
                    {
                        autoClose: toastTimeout,
                    }
                );
                for (let file of duplicateFiles) {
                    toast.warn(`${file.name} not added`, {
                        autoClose: toastTimeout,
                    });
                }
            }

            // show error for files which are over the size limit
            if (Array.from(overSizeLimitFiles).length !== 0) {
                toast.error(
                    overSizeLimitFiles.length > 1
                        ? `${overSizeLimitFiles.length} files exceed maximum size of ${allowedFileSizeInMB} MB`
                        : `File exceeds maximum size of ${allowedFileSizeInMB} MB`,
                    {
                        autoClose: toastTimeout,
                    }
                );
                for (let file of overSizeLimitFiles) {
                    toast.warn(`${file.name} not added`, {
                        autoClose: toastTimeout,
                    });
                }
            }

            //Show error for files which are over the name limit
            if (Array.from(longNameFiles).length !== 0) {
                toast.error(
                    longNameFiles.length > 1
                        ? `${longNameFiles.length} files exceed maximum name length of ${maxFileNameLength} characters`
                        : `File exceeds maximum name length of ${maxFileNameLength} characters`,
                    {
                        autoClose: toastTimeout,
                    }
                );
                for (let file of longNameFiles) {
                    toast.warn(`${file.name} not added`, {
                        autoClose: toastTimeout,
                    });
                }
            }
            try {
                setUploading(true);

                // create a promise array to upload single file at a time
                const fileUploadPromiseList: any[] = [];
                validFiles.forEach((file) => {
                    fileUploadPromiseList.push(
                        uploadBulkImportFile(file, resourceType)
                    );
                });
                let bulkImportList = await Promise.all(fileUploadPromiseList);
                // final callback to update the main state
                if (onFileUpload) onFileUpload(bulkImportList);
            } catch {
                toast.error('File upload failed');
            } finally {
                setUploading(false);
                //     // reset the input for more files to be added
                // e.target.value = null;
            }
        }
    };
    return (
        <div className="flex flex--aic">
            {label && (
                <FWTypography color={labelColor} variant={labelvariant}>
                    {label}
                </FWTypography>
            )}
            <FWTooltip
                title={
                    reachedMaxFileLimit
                        ? `Reached maximum file upload limit of ${maxAllowedFiles} file(s)`
                        : disabled
                        ? ''
                        : 'Add file(s)'
                }
            >
                <Label
                    className={`${
                        reachedMaxFileLimit || uploading || disabled
                            ? 'disabled'
                            : ''
                    }`}
                >
                    <input
                        id={id}
                        type="file"
                        hidden
                        multiple={multiple}
                        onChange={handleFileUpload}
                        onClick={(e: any) => {
                            e.target.value = null;
                            if (reachedMaxFileLimit) e.preventDefault();
                        }}
                        disabled={disabled}
                    />
                    <div
                        className={label ? 'ml--5' : ''}
                        id="bulkImport_UploadFile"
                    >
                        {!uploading ? (
                            <FWIcon
                                name={buttonIcon}
                                size={clipIconSize}
                                color={
                                    reachedMaxFileLimit || disabled
                                        ? 'text.secondary'
                                        : 'primary.main'
                                }
                            />
                        ) : (
                            <CircularProgress size={20} />
                        )}
                    </div>
                </Label>
            </FWTooltip>
        </div>
    );
};

export default BulkImportUploadFile;
