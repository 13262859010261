export interface IAttributeValue {
    attribute_value_id: string | null;
    created_datetime: string;
    modified_datetime: string;
    deleted_datetime: string | null;
    value: string;
    created_by_user: string | null;
    modified_by_user: string | null;
    deleted_by_user: string | null;
    attribute: string;
    attribute_value_linkage_id: string | null;
    measurement_unit: string | null;
    currency: string | null;
}

export interface IAttribute {
    attribute_linkage_id: string | null;
    attribute_id: string | null;
    created_by_user_name: string;
    modified_by_user_name: string;
    attribute_values: IAttributeValue[];
    created_datetime: string;
    modified_datetime: string;
    deleted_datetime: string | null;
    attribute_name: string;
    allow_custom_value?: boolean;
    attribute_used?: boolean;
    attribute_type: string;
    status: string;
    created_by_user: string;
    modified_by_user: string;
    deleted_by_user: string | null;
    enterprise: string;
}

export interface IMetadata {
    current_page: number;
    has_next: boolean;
    has_previous: boolean;
    total_pages: number;
    page_range: {
        start: number;
        stop: number;
    };
    count: number;
}

export interface ICounts {
    all: number;
    active: number;
    disabled: number;
}

export interface ISpecificationsDataResponse {
    data: IAttribute[];
    metadata: IMetadata;
    counts: ICounts;
}

export interface SpecificationValue {
    attribute_value_id: null | string;
    value: string;
    measurement_unit_id: null | string;
    currency_id: null | string;
}

export enum SpecificationStatus {
    ACTIVE = 'active',
    DISABLED = 'disabled',
    ALL = 'all',
}

export interface AddSpecificationPayload {
    allow_custom_value: boolean;
    attribute_name: string;
    attribute_type: string;
    attribute_values: SpecificationValue[];
}

export interface UpdateSpecStatusPayload {
    specID: string;
    newStatus: string;
}
