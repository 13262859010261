import { IconButton } from '@mui/material';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { FWMenu, FWMenuItem } from '../../../../Common/FWCustomMenu';
import FWIcon from '../../../../Common/FWIcon';
import { FWPopup } from '../../../../Common/FWPopup';
import {
    ConfirmationColors,
    useConfirmation,
} from '../../../../Common/Hooks/ConfirmationHook';
import {
    IVendorListSummary,
    VendorStatus,
    VendorVerificationStatus,
} from '../../Interfaces/VendorsInterface';
import {
    deleteVendorInvitation,
    resendVendorEnterpriseInvitation,
    updateVendorStatus,
} from '../../Services/VendorsService';

const VendorsListOptionsMenu = ({
    data,
    context,
}: {
    data: IVendorListSummary;
    context: {
        emitStatusChange: (vendorDirUid: string, status: VendorStatus) => void;
        portal: 'VENDOR_MASTER' | 'BUYER_MASTER';
    };
}) => {
    const { emitStatusChange } = context;
    const [loading, setLoading] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const openMenu = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const [
        confirmationValues,
        updateConfirmationDetails,
        resetConfirmationValues,
    ] = useConfirmation();

    const handleConfirmationToggle = () => {
        resetConfirmationValues();
    };

    const handleToggleVendorLink = (
        vendorUid: string,
        title: string,
        message: string,
        key: string,
        value: VendorStatus,
        color: ConfirmationColors,
        type:
            | 'ENABLE_VENDOR'
            | 'DISBALE_VENDOR'
            | 'DELETE_INVITATION'
            | 'RESEND_INVITATION'
            | 'NONE'
    ) => {
        handleClose();
        updateConfirmationDetails({
            title,
            message,
            key,
            value,
            data: { vendorUid },
            color,
            type,
        });
    };

    const handleUpdateStatus = async () => {
        setLoading(true);
        handleClose();
        if (
            confirmationValues.type === 'ENABLE_VENDOR' ||
            confirmationValues.type === 'DISBALE_VENDOR'
        ) {
            const statusChanged = await updateVendorStatus(
                confirmationValues.value,
                data.vendorDirUid
            );
            if (statusChanged) {
                emitStatusChange(data.vendorDirUid, confirmationValues.value);
                handleConfirmationToggle();
            } else {
                toast.error(
                    `Error occurred while ${
                        data.vendorStatus === VendorStatus.ACTIVE
                            ? 'disabling'
                            : 'enabling'
                    } ${data.vendorName}`
                );
            }
        } else if (confirmationValues.type === 'DELETE_INVITATION') {
            const statusChanged = await deleteVendorInvitation(
                data.vendorDirUid
            );
            if (statusChanged) {
                emitStatusChange(data.vendorDirUid, VendorStatus.DELETED);
                handleConfirmationToggle();
            } else {
                toast.error(`Error occurred while deleting invitation, 
                the vendor may be added to existing event(s)`);
            }
        } else if (confirmationValues.type === 'RESEND_INVITATION') {
            handleConfirmationToggle();
            const statusChanged = await resendVendorEnterpriseInvitation(
                data.vendorDirUid,
                data.primaryContact.email,
                context.portal
            );
            if (statusChanged) {
                toast.success(`Invitation sent successfully`);
            } else {
                toast.error(
                    `Error occurred while sending invitation, please try later`
                );
            }
        }
        setLoading(false);
    };

    return (
        <>
            <div style={{ margin: '0 auto' }}>
                <IconButton
                    color="primary"
                    ref={(ref) => {
                        if (!ref) return;
                        ref.onclick = (e: any) => {
                            e.stopPropagation();
                            handleClick(e);
                        };
                    }}
                    disabled={
                        !(
                            data.vendorStatus === VendorStatus.INACTIVE ||
                            data.vendorStatus === VendorStatus.ACTIVE ||
                            data.vendorStatus === VendorStatus.INVITED
                        )
                    }
                >
                    <FWIcon name="bi bi-three-dots" size={24} />
                </IconButton>
            </div>

            <FWMenu anchorEl={anchorEl} open={openMenu} onClose={handleClose}>
                {/* {data.vendorStatus === VendorStatus.INVITED && ( */}
                {/* {data.primaryContact.invitationStatus === 'INVITED' && (
                    <FWMenuItem
                        sx={{ color: 'primary.main' }}
                        onClick={() => {
                            handleToggleVendorLink(
                                data.vendorUid,
                                `Resend invitation`,
                                `The invitation will be resent to this vendor`,
                                `vendorStatus`,
                                VendorStatus.INVITED,
                                'primary',
                                'RESEND_INVITATION'
                            );
                        }}
                    >
                        Resend Invitation
                    </FWMenuItem>
                )} */}
                {data.vendorStatus === VendorStatus.INACTIVE && (
                    <FWMenuItem
                        sx={{ color: 'primary.main' }}
                        onClick={() => {
                            handleToggleVendorLink(
                                data.vendorUid,
                                `Enable vendor`,
                                `This vendor will be enabled for future events and POs`,
                                `vendorStatus`,
                                VendorStatus.ACTIVE,
                                'primary',
                                'ENABLE_VENDOR'
                            );
                        }}
                    >
                        Enable vendor
                    </FWMenuItem>
                )}
                {data.vendorStatus === VendorStatus.ACTIVE && (
                    <FWMenuItem
                        sx={{ color: 'error.main' }}
                        onClick={() => {
                            handleToggleVendorLink(
                                data.vendorUid,
                                `Disable vendor`,
                                `This vendor will be disabled from future events and POs`,
                                `vendorStatus`,
                                VendorStatus.INACTIVE,
                                'error',
                                'DISBALE_VENDOR'
                            );
                        }}
                    >
                        Disable vendor
                    </FWMenuItem>
                )}

                <FWMenuItem
                    sx={{ color: 'error.main' }}
                    onClick={() => {
                        handleToggleVendorLink(
                            data.vendorUid,
                            `Delete invitation`,
                            `The invitation sent to this vendor will be deleted`,
                            `vendorStatus`,
                            VendorStatus.INVITED,
                            'error',
                            'DELETE_INVITATION'
                        );
                    }}
                >
                    Delete{' '}
                    {data.vendorVerificationStatus ===
                    VendorVerificationStatus.INVITATION_PENDING
                        ? 'invitation'
                        : 'vendor'}
                </FWMenuItem>
                {/* )} */}
            </FWMenu>

            <FWPopup
                title={confirmationValues.title}
                msg={confirmationValues.message}
                btnColor={confirmationValues.color}
                btnTitle="Confirm"
                open={confirmationValues.open}
                setOpen={handleConfirmationToggle}
                onClickConfirm={handleUpdateStatus}
                btnLoading={loading}
            />
        </>
    );
};

export default VendorsListOptionsMenu;
