import { DynamicVendorAnalyticsHookDataType } from '../Interfaces/DynamicAnalyticsInterfaces.model';
import { IHistoricalAnalyticsChart } from '../Interfaces/IHistoricalAnalytics.model';
import {
    getEventStartDateFilter,
    getItemFilter,
    getPoIssueDateFilter,
    getSellerEntityFilter,
} from './CommonFilters';

export const getGraphSettings_VendorPosOverTime = (
    hookData: DynamicVendorAnalyticsHookDataType
): IHistoricalAnalyticsChart => {
    return {
        chartType: 'bar',
        title: 'Relationship Timeline',
        chartId: 'vendorRelationshipTimeline',
        currencySettings: {
            showTargetCurrency: false,
            targetCurrency: hookData.currencyDetails,
            filterCurrenciesIds: [],
        },
        filterContoller: {
            fixedFilters: [
                getSellerEntityFilter({
                    sellerEntityId: hookData.sellerEntityUuid,
                }),
                {
                    filterId: '',
                    filterType: 'notNull',
                    filterLabel: '',
                    filterFields: {
                        metricFieldType: 'universal',
                        fieldName: 'po__purchase_order_id',
                    },
                    filterApplied: true,
                },
                {
                    filterId: '',
                    filterType: 'multiselectDropdown',
                    filterLabel: '',
                    filterFields: {
                        metricFieldType: 'universal',
                        fieldName: 'po__status',
                    },
                    filterApplied: true,
                    modifierType: 'CONSOLIDATED',
                    modifierValue: 'IN',
                    values: ['ISSUED', 'ONGOING', 'TERMINATED', 'COMPLETED'],
                },
                {
                    filterId: '',
                    filterType: 'multiselectDropdown',
                    filterLabel: '',
                    filterFields: {
                        metricFieldType: 'universal',
                        fieldName: 'po__purchase_order_type',
                    },
                    filterApplied: true,
                    modifierType: 'CONSOLIDATED',
                    modifierValue: 'NOT_IN',
                    values: ['STAGING'],
                },
            ],
            customizableFilters: {
                categoryConnector: 'AND',
                categories: [
                    {
                        categoryLabel: 'Date Range',
                        filterConnector: 'AND',
                        filters: [
                            getPoIssueDateFilter({
                                dateRange: hookData.dateRange,
                            }),
                        ],
                        expanded: true,
                    },
                ],
            },
        },
        axisLabelY: '',
        axisLabelX: '',
        metrics: [
            {
                metricId: 'poValue',
                displayModifier: 'currency',
                display: 'bar',
                metricLegendLabel: 'PO Value',
                metricDefinition: {
                    metricType: 'base',
                    metricField: 'po__pricing_information__total',
                    aggregation: 'sum',
                },
            },
            {
                metricId: 'poCount',
                displayModifier: 'count',
                display: 'bar',
                metricLegendLabel: 'PO Count',
                metricDefinition: {
                    metricType: 'base',
                    metricField: 'po__purchase_order_id',
                    aggregation: 'count',
                },
            },
        ],
        groupBy: [
            {
                type: 'date',
                field: 'po__submission_datetime',
                period: 'month',
            },
            {
                type: 'str',
                field: 'currency_code__entry_id',
                includeInGroupName: false,
            },
        ],
        groupSort: {
            sortBy: 'none',
            sortOrder: 'asc',
        },
        groupFilter: {
            filterBy: 'none',
            filterNumber: 0,
            customFilter: '',
        },
    };
};

export const getGraphSettings_VendorItemsBought = (
    hookData: DynamicVendorAnalyticsHookDataType
): IHistoricalAnalyticsChart => {
    return {
        chartType: 'bar',
        title: 'Items Purchased',
        chartId: 'vendorItemsBought',
        currencySettings: {
            showTargetCurrency: false,
            targetCurrency: hookData.currencyDetails,
            filterCurrenciesIds: [],
        },
        filterContoller: {
            fixedFilters: [
                {
                    filterId: '',
                    filterType: 'notNull',
                    filterLabel: '',
                    filterFields: {
                        metricFieldType: 'universal',
                        fieldName: 'po__purchase_order_id',
                    },
                    filterApplied: true,
                },
                {
                    filterId: '',
                    filterType: 'multiselectDropdown',
                    filterLabel: '',
                    filterFields: {
                        metricFieldType: 'universal',
                        fieldName: 'po__status',
                    },
                    filterApplied: true,
                    modifierType: 'CONSOLIDATED',
                    modifierValue: 'IN',
                    values: ['ISSUED', 'ONGOING', 'TERMINATED', 'COMPLETED'],
                },
                {
                    filterId: '',
                    filterType: 'multiselectDropdown',
                    filterLabel: '',
                    filterFields: {
                        metricFieldType: 'universal',
                        fieldName: 'po__purchase_order_type',
                    },
                    filterApplied: true,
                    modifierType: 'CONSOLIDATED',
                    modifierValue: 'NOT_IN',
                    values: ['STAGING'],
                },
                {
                    filterId: '',
                    filterType: 'singleselectDropdown',
                    filterFields: {
                        metricFieldType: 'metricSpecific',
                        metricFields: {
                            poValueCurrentVendor: 'seller_entity__entity_id',
                        },
                    },
                    filterLabel: 'Vendor',
                    modifier: 'EQUALS',
                    value: hookData.sellerEntityUuid,
                    filterApplied: true,
                },
                {
                    filterId: '',
                    filterType: 'singleselectDropdown',
                    filterFields: {
                        metricFieldType: 'metricSpecific',
                        metricFields: {
                            poValueOtherVendors: 'seller_entity__entity_id',
                        },
                    },
                    filterLabel: 'Vendor',
                    modifier: 'NOT_EQUALS',
                    value: hookData.sellerEntityUuid,
                    filterApplied: true,
                },
            ],
            customizableFilters: {
                categoryConnector: 'AND',
                categories: [
                    {
                        categoryLabel: 'Date Range',
                        filterConnector: 'AND',
                        filters: [
                            getPoIssueDateFilter({
                                dateRange: hookData.dateRange,
                            }),
                        ],
                        expanded: true,
                    },
                ],
            },
        },
        axisLabelY: '',
        axisLabelX: '',
        metrics: [
            {
                metricId: 'poValueCurrentVendor',
                displayModifier: 'currency',
                display: 'bar',
                metricLegendLabel: `PO Value (${hookData.sellerEntityName})`,
                metricDefinition: {
                    metricType: 'base',
                    metricField: 'po_item__pricing_information__total',
                    aggregation: 'sum',
                },
            },
            {
                metricId: 'poValueOtherVendors',
                displayModifier: 'currency',
                display: 'bar',
                metricLegendLabel: `PO Value (Other vendors)`,
                metricDefinition: {
                    metricType: 'base',
                    metricField: 'po_item__pricing_information__total',
                    aggregation: 'sum',
                },
            },
            {
                metricId: 'vendorCount',
                displayModifier: 'count',
                display: 'line',
                metricLegendLabel: `Vendor count`,
                metricDefinition: {
                    metricType: 'base',
                    metricField: 'seller_entity__entity_id',
                    aggregation: 'count',
                },
            },
        ],
        groupBy: [
            {
                type: 'str',
                field: 'enterprise_item__enterprise_item_id',
                includeInGroupName: false,
            },
            {
                type: 'str',
                field: 'enterprise_item__name',
                includeInGroupName: false,
            },
            {
                type: 'str',
                field: 'currency_code__entry_id',
                includeInGroupName: false,
            },
        ],
        groupSort: {
            sortBy: 'value',
            sortOrder: 'asc',
        },
        groupFilter: {
            filterBy: 'none',
            filterNumber: 0,
            customFilter: '',
        },
    };
};

export const getGraphSettings_VendorRateOverTime = (
    hookData: DynamicVendorAnalyticsHookDataType
): IHistoricalAnalyticsChart => {
    return {
        chartType: 'bar',
        title: 'Rate Analytics',
        chartId: 'vendorRateOverTime',
        currencySettings: {
            showTargetCurrency: false,
            targetCurrency: hookData.currencyDetails,
            filterCurrenciesIds: [],
        },
        filterContoller: {
            fixedFilters: [
                getItemFilter({
                    enterpriseItemIds: [hookData.enterpriseItemUuid],
                }),
                {
                    filterId: '',
                    filterType: 'notNull',
                    filterLabel: '',
                    filterFields: {
                        metricFieldType: 'universal',
                        fieldName: 'po__purchase_order_id',
                    },
                    filterApplied: true,
                },
                {
                    filterId: '',
                    filterType: 'multiselectDropdown',
                    filterLabel: '',
                    filterFields: {
                        metricFieldType: 'universal',
                        fieldName: 'po__status',
                    },
                    filterApplied: true,
                    modifierType: 'CONSOLIDATED',
                    modifierValue: 'IN',
                    values: ['ISSUED', 'ONGOING', 'TERMINATED', 'COMPLETED'],
                },
                {
                    filterId: '',
                    filterType: 'multiselectDropdown',
                    filterLabel: '',
                    filterFields: {
                        metricFieldType: 'universal',
                        fieldName: 'po__purchase_order_type',
                    },
                    filterApplied: true,
                    modifierType: 'CONSOLIDATED',
                    modifierValue: 'NOT_IN',
                    values: ['STAGING'],
                },
                {
                    filterId: '',
                    filterType: 'singleselectDropdown',
                    filterFields: {
                        metricFieldType: 'metricSpecific',
                        metricFields: {
                            effectiveRateCurrentVendor:
                                'seller_entity__entity_id',
                            quantityOrderedCurrentVendor:
                                'seller_entity__entity_id',
                        },
                    },
                    filterLabel: 'Vendor',
                    modifier: 'EQUALS',
                    value: hookData.sellerEntityUuid,
                    filterApplied: true,
                },
            ],
            customizableFilters: {
                categoryConnector: 'AND',
                categories: [
                    {
                        categoryLabel: 'Date Range',
                        filterConnector: 'AND',
                        filters: [
                            getPoIssueDateFilter({
                                dateRange: hookData.dateRange,
                            }),
                        ],
                        expanded: true,
                    },
                ],
            },
        },
        axisLabelY: '',
        axisLabelX: '',
        metrics: [
            {
                metricId: 'effectiveRateCurrentVendor',
                display: 'bar',
                displayModifier: 'currency',
                metricLegendLabel: `Effective Rate (${hookData.sellerEntityName})`,
                metricDefinition: {
                    metricType: 'base',
                    metricField:
                        'po_item__pricing_information__total / po_item__quantity_information__quantity',
                    aggregation: 'avg',
                },
            },
            {
                metricId: 'quantityOrderedCurrentVendor',
                display: 'line',
                displayModifier: 'none',
                metricLegendLabel: `Quantity ordered (${hookData.sellerEntityName})`,
                metricDefinition: {
                    metricType: 'base',
                    metricField: 'po_item__quantity_information__quantity',
                    aggregation: 'sum',
                },
            },
            {
                metricId: 'effectiveRateAllVendors',
                display: 'bar',
                displayModifier: 'currency',
                metricLegendLabel: `Effective Rate (All vendors)`,
                metricDefinition: {
                    metricType: 'base',
                    metricField:
                        'po_item__pricing_information__total / po_item__quantity_information__quantity',
                    aggregation: 'avg',
                },
            },
        ],
        groupBy: [
            {
                type: 'date',
                field: 'po__submission_datetime',
                period: 'month',
            },
            {
                type: 'str',
                field: 'currency_code__entry_id',
                includeInGroupName: false,
            },
        ],
        groupSort: {
            sortBy: 'none',
            sortOrder: 'asc',
        },
        groupFilter: {
            filterBy: 'none',
            filterNumber: 0,
            customFilter: '',
        },
    };
};

export const getGraphSettings_VendorQualityOverTime = (
    hookData: DynamicVendorAnalyticsHookDataType
): IHistoricalAnalyticsChart => {
    return {
        chartType: 'bar',
        title: 'Rate Analytics',
        chartId: 'vendorRateOverTime',
        currencySettings: {
            showTargetCurrency: false,
            targetCurrency: hookData.currencyDetails,
            filterCurrenciesIds: [],
        },
        filterContoller: {
            fixedFilters: [
                getItemFilter({
                    enterpriseItemIds: [hookData.enterpriseItemUuid],
                }),
                {
                    filterId: '',
                    filterType: 'notNull',
                    filterLabel: '',
                    filterFields: {
                        metricFieldType: 'universal',
                        fieldName: 'po__purchase_order_id',
                    },
                    filterApplied: true,
                },
                {
                    filterId: '',
                    filterType: 'multiselectDropdown',
                    filterLabel: '',
                    filterFields: {
                        metricFieldType: 'universal',
                        fieldName: 'po__status',
                    },
                    filterApplied: true,
                    modifierType: 'CONSOLIDATED',
                    modifierValue: 'IN',
                    values: ['ISSUED', 'ONGOING', 'TERMINATED', 'COMPLETED'],
                },
                {
                    filterId: '',
                    filterType: 'multiselectDropdown',
                    filterLabel: '',
                    filterFields: {
                        metricFieldType: 'universal',
                        fieldName: 'po__purchase_order_type',
                    },
                    filterApplied: true,
                    modifierType: 'CONSOLIDATED',
                    modifierValue: 'NOT_IN',
                    values: ['STAGING'],
                },
                {
                    filterId: '',
                    filterType: 'singleselectDropdown',
                    filterFields: {
                        metricFieldType: 'metricSpecific',
                        metricFields: {
                            quantityOrderedCurrentVendor:
                                'seller_entity__entity_id',
                            acceptanceRateCurrentVendor:
                                'seller_entity__entity_id',
                        },
                    },
                    filterLabel: 'Vendor',
                    modifier: 'EQUALS',
                    value: hookData.sellerEntityUuid,
                    filterApplied: true,
                },
                {
                    filterId: '',
                    filterType: 'notNull',
                    filterLabel: 'QC Exists',
                    filterApplied: true,
                    filterFields: {
                        metricFieldType: 'metricSpecific',
                        metricFields: {
                            acceptanceRateCurrentVendor:
                                'batch_1_qc_1__quality_check_id',
                            acceptanceRateAllVendors:
                                'batch_1_qc_1__quality_check_id',
                        },
                    },
                },
            ],
            customizableFilters: {
                categoryConnector: 'AND',
                categories: [
                    {
                        categoryLabel: 'Date Range',
                        filterConnector: 'AND',
                        filters: [
                            getPoIssueDateFilter({
                                dateRange: hookData.dateRange,
                            }),
                        ],
                        expanded: true,
                    },
                ],
            },
        },
        axisLabelY: '',
        axisLabelX: '',
        metrics: [
            {
                metricId: 'quantityOrderedCurrentVendor',
                displayModifier: 'none',
                display: 'line',
                metricLegendLabel: `Quantity ordered (${hookData.sellerEntityName})`,
                metricDefinition: {
                    metricType: 'base',
                    metricField: 'po_item__quantity_information__quantity',
                    aggregation: 'sum',
                },
            },
            {
                metricId: 'acceptanceRateCurrentVendor',
                displayModifier: 'percentage',
                display: 'bar',
                metricLegendLabel: `Acceptance Rate (${hookData.sellerEntityName})`,
                metricDefinition: {
                    metricType: 'percentage',
                    numerator: {
                        metricType: 'base',
                        metricField:
                            'invoice_item__pricing_information__total_amount - invoice_item__pricing_information__rejection_amount',
                        aggregation: 'sum',
                    },
                    denominator: {
                        metricType: 'base',
                        metricField:
                            'invoice_item__pricing_information__total_amount',
                        aggregation: 'sum',
                    },
                },
            },
            {
                metricId: 'acceptanceRateAllVendors',
                display: 'bar',
                displayModifier: 'percentage',
                metricLegendLabel: `Acceptance Rate (All Vendors)`,
                metricDefinition: {
                    metricType: 'percentage',
                    numerator: {
                        metricType: 'base',
                        metricField:
                            'invoice_item__pricing_information__total_amount - invoice_item__pricing_information__rejection_amount',
                        aggregation: 'sum',
                    },
                    denominator: {
                        metricType: 'base',
                        metricField:
                            'invoice_item__pricing_information__total_amount',
                        aggregation: 'sum',
                    },
                },
            },
        ],
        groupBy: [
            {
                type: 'date',
                field: 'po__submission_datetime',
                period: 'month',
            },
            {
                type: 'str',
                field: 'currency_code__entry_id',
                includeInGroupName: false,
            },
        ],
        groupSort: {
            sortBy: 'none',
            sortOrder: 'asc',
        },
        groupFilter: {
            filterBy: 'none',
            filterNumber: 0,
            customFilter: '',
        },
    };
};

export const getGraphSettings_VendorBidWinPercentage = (
    hookData: DynamicVendorAnalyticsHookDataType
): IHistoricalAnalyticsChart => {
    return {
        chartType: 'bar',
        title: 'Bids Overview',
        chartId: 'vendorRateOverTime',
        currencySettings: {
            showTargetCurrency: false,
            targetCurrency: hookData.currencyDetails,
            filterCurrenciesIds: [],
        },
        filterContoller: {
            fixedFilters: [
                getSellerEntityFilter({
                    sellerEntityId: hookData.sellerEntityUuid,
                }),
                {
                    filterId: '',
                    filterType: 'singleselectDropdown',
                    filterLabel: 'Event type',
                    modifier: 'EQUALS',
                    filterApplied: true,
                    value: 'RFQ',
                    filterFields: {
                        metricFieldType: 'universal',
                        fieldName: 'event__event_type',
                    },
                },
                {
                    filterId: '',
                    filterType: 'multiselectDropdown',
                    filterLabel: 'Event status',
                    modifierType: 'CONSOLIDATED',
                    modifierValue: 'IN',
                    filterApplied: true,
                    values: [
                        'PURCHASE_ORDER_ISSUED',
                        'PURCHASE_ORDER_ACCEPTED',
                        'COMPLETED',
                    ],
                    filterFields: {
                        metricFieldType: 'universal',
                        fieldName: 'event__status',
                    },
                },
                {
                    filterId: '',
                    filterType: 'notNull',
                    filterApplied: true,
                    filterLabel: 'PO Item Exists',
                    filterFields: {
                        metricFieldType: 'metricSpecific',
                        metricFields: {
                            bidsWon: 'po_item__purchase_order_item_id',
                        },
                    },
                },
            ],
            customizableFilters: {
                categoryConnector: 'AND',
                categories: [
                    {
                        categoryLabel: 'Date Range',
                        filterConnector: 'AND',
                        filters: [
                            getEventStartDateFilter({
                                dateRange: hookData.dateRange,
                            }),
                        ],
                        expanded: true,
                    },
                ],
            },
        },
        axisLabelY: '',
        axisLabelX: '',
        metrics: [
            {
                metricId: 'bidsWon',
                display: 'bar',

                displayModifier: 'none',
                metricLegendLabel: `Bids Won`,
                metricDefinition: {
                    metricType: 'base',
                    metricField: 'rfq_event_item__rfq_item_entry_id',
                    aggregation: 'count',
                },
            },
            {
                metricId: 'totalInvitedItems',
                display: 'bar',
                displayModifier: 'none',
                metricLegendLabel: `Total invited items`,
                metricDefinition: {
                    metricType: 'base',
                    metricField: 'rfq_event_item__rfq_item_entry_id',
                    aggregation: 'count',
                },
            },
        ],
        groupBy: [
            {
                type: 'date',
                field: 'event__live_event_start_datetime',
                period: 'quarter',
            },
            {
                type: 'str',
                field: 'currency_code__entry_id',
                includeInGroupName: false,
            },
        ],
        groupSort: {
            sortBy: 'none',
            sortOrder: 'asc',
        },
        groupFilter: {
            filterBy: 'none',
            filterNumber: 0,
            customFilter: '',
        },
    };
};

export const getGraphSettings_VendorPoTypesOverTime = (
    hookData: DynamicVendorAnalyticsHookDataType
): IHistoricalAnalyticsChart => {
    return {
        chartType: 'bar',
        // showBarMetricsAsSeparateBars: true,
        title: 'PO Types',
        chartId: 'vendorPoTypesOverTime',
        currencySettings: {
            showTargetCurrency: false,
            targetCurrency: hookData.currencyDetails,
            filterCurrenciesIds: [],
        },
        filterContoller: {
            fixedFilters: [
                {
                    filterId: 'poNotNull',
                    filterType: 'notNull',
                    filterLabel: '',
                    filterFields: {
                        metricFieldType: 'universal',
                        fieldName: 'po__purchase_order_id',
                    },
                    filterApplied: true,
                },
                {
                    filterId: 'validPoStatus',
                    filterType: 'multiselectDropdown',
                    filterLabel: '',
                    filterFields: {
                        metricFieldType: 'universal',
                        fieldName: 'po__status',
                    },
                    filterApplied: true,
                    modifierType: 'CONSOLIDATED',
                    modifierValue: 'IN',
                    values: ['ISSUED', 'ONGOING', 'TERMINATED', 'COMPLETED'],
                },
                {
                    filterId: 'validPoType',
                    filterType: 'multiselectDropdown',
                    filterLabel: '',
                    filterFields: {
                        metricFieldType: 'universal',
                        fieldName: 'po__purchase_order_type',
                    },
                    filterApplied: true,
                    modifierType: 'CONSOLIDATED',
                    modifierValue: 'NOT_IN',
                    values: ['STAGING'],
                },
                {
                    filterId: 'sellerEntityId',
                    filterType: 'singleselectDropdown',
                    filterFields: {
                        metricFieldType: 'universal',
                        fieldName: 'seller_entity__entity_id',
                    },
                    filterLabel: 'Vendor',
                    modifier: 'EQUALS',
                    value: hookData.sellerEntityUuid,
                    filterApplied: true,
                },
                {
                    filterId: 'rfqEventsOnly',
                    filterType: 'singleselectDropdown',
                    filterFields: {
                        metricFieldType: 'metricSpecific',
                        metricFields: {
                            rfqPoCount: 'event__event_type',
                        },
                    },
                    filterLabel: 'RFQ POs',
                    modifier: 'EQUALS',
                    value: 'RFQ',
                    filterApplied: true,
                },
                {
                    filterId: 'directPosOnly',
                    filterType: 'singleselectDropdown',
                    filterFields: {
                        metricFieldType: 'metricSpecific',
                        metricFields: {
                            directPoCount: 'event__event_type',
                        },
                    },
                    filterLabel: 'Direct POs',
                    modifier: 'EQUALS',
                    value: 'PO_GROUP',
                    filterApplied: true,
                },
            ],
            customizableFilters: {
                categoryConnector: 'AND',
                categories: [
                    {
                        categoryLabel: 'Date Range',
                        filterConnector: 'AND',
                        filters: [
                            getPoIssueDateFilter({
                                dateRange: hookData.dateRange,
                            }),
                        ],
                        expanded: true,
                    },
                ],
            },
        },
        axisLabelY: '',
        axisLabelX: '',
        metrics: [
            {
                metricId: 'rfqPoCount',
                display: 'bar',
                displayModifier: 'count',
                metricLegendLabel: `RFQ PO Count`,
                metricDefinition: {
                    metricType: 'base',
                    metricField: 'po__purchase_order_id',
                    aggregation: 'count',
                },
            },
            {
                metricId: 'directPoCount',
                display: 'bar',
                displayModifier: 'count',
                metricLegendLabel: `Direct PO Count`,
                metricDefinition: {
                    metricType: 'base',
                    metricField: 'po__purchase_order_id',
                    aggregation: 'count',
                },
            },
        ],
        groupBy: [
            {
                type: 'date',
                field: 'po__submission_datetime',
                period: 'month',
            },
            {
                type: 'str',
                field: 'currency_code__entry_id',
                includeInGroupName: false,
            },
        ],
        groupSort: {
            sortBy: 'none',
            sortOrder: 'asc',
        },
        groupFilter: {
            filterBy: 'none',
            filterNumber: 0,
            customFilter: '',
        },
    };
};
