import { Box, Grid, Paper, styled, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { FWTypography } from '../../Common/FWTypograhy';
import { HookStateValue } from '../../Common/Hooks/StateHook';
import { IGlobalAppStore } from '../../Redux/Store';
import ItemsBoughtGraph from '../Components/Graphs/VendorAnalyticsCharts/ItemsBoughtGraph';
import OverTimeGraph from '../Components/Graphs/VendorAnalyticsCharts/OverTimeGraph';
// import RateOverTimeGraph from '../Components/Graphs/VendorAnalyticsCharts/RateOverTimeGraph';
import { FWMenuItem } from '../../Common/FWCustomMenu';
import FWSelect from '../../Common/FWSelect';
import BidWinPercentageGraph from '../Components/Graphs/VendorAnalyticsCharts/BidWinPercentageGraph';
import POTypesOverTimeGraph from '../Components/Graphs/VendorAnalyticsCharts/POTypesOverTimeGraph';
import {
    useVendorDynamicAnalytics_BidWinPercentage,
    useVendorDynamicAnalytics_ItemsBought,
    useVendorDynamicAnalytics_PosOverTime,
    useVendorDynamicAnalytics_PoTypesOverTime,
} from '../Hooks/useVendorDynamicAnalytics';
import {
    DynamicAnalyticsVendorBidWinPercentageData,
    DynamicAnalyticsVendorItemsBoughtData,
    DynamicAnalyticsVendorPosOverTimeData,
    DynamicAnalyticsVendorPoTypesOverTimeData,
    DynamicAnalyticsVendorQualityOverTimeData,
    DynamicAnalyticsVendorRateOverTimeData,
    DynamicVendorAnalyticsHookDataType,
} from '../Interfaces/DynamicAnalyticsInterfaces.model';
import { DateRangeType } from '../Interfaces/IHistoricalAnalytics.model';
import { CurrencyDetails } from '../Interfaces/IHistoricalAnalyticsDropdowns.model';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    border: '1px solid',
    borderColor: theme.palette.text.secondary,
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    height: '100%',
    minWidth: 'unset !important',
}));

export const ErrorLoadingGraphComponent = () => {
    return (
        <Box sx={{ opacity: 0.5 }} className="flex flex--aic flex--jcc">
            <Box color="secondary.main">
                <i className="bi bi-exclamation-triangle-fill" />
            </Box>
            &nbsp;
            <Typography color="secondary.main" fontWeight={500}>
                Error loading graph
            </Typography>
        </Box>
    );
};

export default function DynamicAnalyticsVendor({
    enterpriseItemUuid,
    itemName,
    currencyDetails,
    measurementUnitUuid,
    measurementUnitName,
    sellerEntityUuid,
    sellerEntityName,
}: {
    enterpriseItemUuid: string;
    currencyDetails: CurrencyDetails;
    itemName: string;
    measurementUnitUuid: string;
    measurementUnitName: string;
    sellerEntityUuid: string;
    sellerEntityName: string;
}) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [dateRange, setDateRange] = useState<DateRangeType>('last_365_days');
    const { number_format } = useSelector(
        (store: IGlobalAppStore) => store.UserDefaultsStore
    );

    const hookData: DynamicVendorAnalyticsHookDataType = useMemo(() => {
        const temp: CurrencyDetails = {
            currencyUuid: 'a8c3e3fd-b05f-4d09-bd2f-9fedd07d0ec3',
            currencyCodeAbbreviation: 'INR',
            currencyName: 'Indian Rupee',
            currencySymbol: '₹`',
        };

        return {
            enterpriseItemUuid,
            currencyDetails: temp,
            measurementUnitUuid,
            sellerEntityUuid,
            sellerEntityName,
            dateRange,
        };
    }, [
        dateRange,
        enterpriseItemUuid,
        measurementUnitUuid,
        sellerEntityName,
        sellerEntityUuid,
        // currencyDetails,
    ]);
    const {
        hookState: posOverTimeHookState,
        visibleData: posOverTimeVisibleData,
        toggleCurrencyFilter: posOverTimeToggleCurrencyFilter,
        curGraphSettings: posOverTimeCurGraphSettings,
    } = useVendorDynamicAnalytics_PosOverTime(hookData);

    const {
        hookState: itemsBoughtHookState,
        visibleData: itemsBoughtVisibleData,
        toggleCurrencyFilter: itemsBoughtToggleCurrencyFilter,
        curGraphSettings: itemsBoughtCurGraphSettings,
    } = useVendorDynamicAnalytics_ItemsBought(hookData);

    // const {
    //     hookState: rateOverTimeHookState,
    //     visibleData: rateOverTimeVisibleData,
    //     toggleCurrencyFilter: rateOverTimeToggleCurrencyFilter,
    //     curGraphSettings: rateOverTimeCurGraphSettings,
    // } = useVendorDynamicAnalytics_RateOverTime(hookData);

    // const {
    //     hookState: qualityOverTimeHookState,
    //     visibleData: qualityOverTimeVisibleData,
    //     toggleCurrencyFilter: qualityOverTimeToggleCurrencyFilter,
    //     curGraphSettings: qualityOverTimeCurGraphSettings,
    // } = useVendorDynamicAnalytics_QualityOverTime(hookData);

    const {
        hookState: poTypesOverTimeHookState,
        visibleData: poTypesOverTimeVisibleData,
        toggleCurrencyFilter: poTypesOverTimeToggleCurrencyFilter,
        curGraphSettings: poTypesOverTimeCurGraphSettings,
    } = useVendorDynamicAnalytics_PoTypesOverTime(hookData);

    const {
        hookState: bidWinPercentageHookState,
        visibleData: bidWinPercentageVisibleData,
        toggleCurrencyFilter: bidWinPercentageToggleCurrencyFilter,
        curGraphSettings: bidWinPercentageCurGraphSettings,
    } = useVendorDynamicAnalytics_BidWinPercentage(hookData);

    const graphArray: {
        title: string;
        hookState: HookStateValue;
        visibleData:
            | DynamicAnalyticsVendorPosOverTimeData[]
            | DynamicAnalyticsVendorItemsBoughtData[]
            | DynamicAnalyticsVendorRateOverTimeData[]
            | DynamicAnalyticsVendorQualityOverTimeData[]
            | DynamicAnalyticsVendorBidWinPercentageData[]
            | DynamicAnalyticsVendorPoTypesOverTimeData[];
        graphComponent: JSX.Element;
    }[] = useMemo(
        () => [
            {
                title: posOverTimeCurGraphSettings?.title ?? '',
                hookState: posOverTimeHookState.state,
                visibleData: posOverTimeVisibleData,
                graphComponent: (
                    <OverTimeGraph
                        visibleData={posOverTimeVisibleData}
                        number_format={number_format}
                        currencyCodeSymbol={currencyDetails.currencySymbol}
                    />
                ),
            },
            {
                title: itemsBoughtCurGraphSettings?.title ?? '',
                hookState: itemsBoughtHookState.state,
                visibleData: itemsBoughtVisibleData,
                graphComponent: (
                    <ItemsBoughtGraph
                        visibleData={itemsBoughtVisibleData}
                        number_format={number_format}
                        sellerEntityName={sellerEntityName}
                        currencyCodeSymbol={currencyDetails.currencySymbol}
                    />
                ),
            },
            {
                title: bidWinPercentageCurGraphSettings?.title ?? '',
                hookState: bidWinPercentageHookState.state,
                visibleData: bidWinPercentageVisibleData,
                graphComponent: (
                    <BidWinPercentageGraph
                        visibleData={bidWinPercentageVisibleData}
                        number_format={number_format}
                        currencyCodeSymbol={currencyDetails.currencySymbol}
                        sellerEntityUuid={sellerEntityUuid}
                    />
                ),
            },
            // {
            //     title: `Orders (${itemName})`,
            //     hookState: rateOverTimeHookState.state,
            //     visibleData: rateOverTimeVisibleData,
            //     graphComponent: (
            //         <RateOverTimeGraph
            //             visibleData={rateOverTimeVisibleData}
            //             number_format={number_format}
            //             currencyCodeSymbol={currencyDetails.currencySymbol}
            //         />
            //     ),
            // },
            // {
            //     title: rateOverTimeCurGraphSettings?.title ?? '',
            //     hookState: rateOverTimeHookState.state,
            //     visibleData: rateOverTimeVisibleData,
            //     graphComponent: (
            //         <RateOverTimeGraph
            //             visibleData={rateOverTimeVisibleData}
            //             number_format={number_format}
            //             currencyCodeSymbol={currencyDetails.currencySymbol}
            //         />
            //     ),
            // },
            {
                title: poTypesOverTimeCurGraphSettings?.title ?? '',
                hookState: poTypesOverTimeHookState.state,
                visibleData: poTypesOverTimeVisibleData,
                graphComponent: (
                    <POTypesOverTimeGraph
                        visibleData={poTypesOverTimeVisibleData}
                        number_format={number_format}
                        currencyCodeSymbol={currencyDetails.currencySymbol}
                    />
                ),
            },
            // {
            //     title: 'Quality Over Time',
            // },
        ],
        [
            posOverTimeCurGraphSettings?.title,
            posOverTimeHookState.state,
            posOverTimeVisibleData,
            number_format,
            currencyDetails.currencySymbol,
            itemsBoughtCurGraphSettings?.title,
            itemsBoughtHookState.state,
            itemsBoughtVisibleData,
            sellerEntityName,
            bidWinPercentageCurGraphSettings?.title,
            bidWinPercentageHookState.state,
            bidWinPercentageVisibleData,
            sellerEntityUuid,
            poTypesOverTimeCurGraphSettings?.title,
            poTypesOverTimeHookState.state,
            poTypesOverTimeVisibleData,
        ]
    );

    const [filterCurrencies, setFilterCurrencies] = useState<boolean>(false);

    const toggleFilterCurrencies = () => {
        setFilterCurrencies(!filterCurrencies);
        posOverTimeToggleCurrencyFilter();
        itemsBoughtToggleCurrencyFilter();
        // qualityOverTimeToggleCurrencyFilter()
        // rateOverTimeToggleCurrencyFilter();
        bidWinPercentageToggleCurrencyFilter();
        poTypesOverTimeToggleCurrencyFilter();
    };

    // const CurrencyFilterElem = () => (
    //     <>
    //         <FWSelect
    //             value={
    //                 filterCurrencies ? 'filteredCurrencies' : 'allCurrencies'
    //             }
    //             onChange={toggleFilterCurrencies}
    //         >
    //             <FWMenuItem key={'allCurrencies'} value={'allCurrencies'}>
    //                 All currencies
    //             </FWMenuItem>
    //             <FWMenuItem
    //                 key={'filteredCurrencies'}
    //                 value={'filteredCurrencies'}
    //             >
    //                 {`${hookData.currencyDetails.currencyCodeAbbreviation} only`}
    //             </FWMenuItem>
    //         </FWSelect>
    //     </>
    // );

    return (
        <>
            <Box className="flex flex--jcfs" marginBottom={2}>
                <Box>
                    <FWTypography color={'text.secondary'}>
                        Filter by:
                    </FWTypography>
                    <FWSelect
                        value={
                            filterCurrencies
                                ? 'filteredCurrencies'
                                : 'allCurrencies'
                        }
                        onChange={toggleFilterCurrencies}
                    >
                        <FWMenuItem
                            key={'allCurrencies'}
                            value={'allCurrencies'}
                        >
                            All currencies
                        </FWMenuItem>
                        <FWMenuItem
                            key={'filteredCurrencies'}
                            value={'filteredCurrencies'}
                        >
                            {`${hookData.currencyDetails.currencyCodeAbbreviation} only`}
                        </FWMenuItem>
                    </FWSelect>
                </Box>
            </Box>
            <Grid
                container
                display="flex"
                columnSpacing={2}
                rowSpacing={2}
                alignItems="stretch"
            >
                {graphArray.map(
                    (
                        { title, hookState, visibleData, graphComponent },
                        index
                    ) => {
                        return (
                            <Grid item xs={6} key={index}>
                                <Item>
                                    <FWTypography marginBottom="10px">
                                        {title}
                                    </FWTypography>
                                    {hookState === HookStateValue.READY ? (
                                        visibleData.length > 0 ? (
                                            graphComponent
                                        ) : (
                                            <Box
                                                width={'100%'}
                                                height={'150px'}
                                                className="flex flex--aic flex--jcc"
                                            >
                                                <Typography
                                                    textAlign={'center'}
                                                    color={'secondary.main'}
                                                    // paddingY={3}
                                                >
                                                    No data available
                                                </Typography>
                                            </Box>
                                        )
                                    ) : (
                                        <Box
                                            className="flex flex--aic flex--jcc"
                                            height={'250px'}
                                            width={'100%'}
                                        >
                                            {hookState ===
                                            HookStateValue.LOADING ? (
                                                <CircularProgress />
                                            ) : (
                                                <ErrorLoadingGraphComponent />
                                            )}
                                        </Box>
                                    )}
                                </Item>
                            </Grid>
                        );
                    }
                )}
            </Grid>
        </>
    );
}
