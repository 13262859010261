import { cloneDeep, isEmpty, isUndefined } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
    HookStateValue,
    IHookState,
    useHookState,
} from '../../../Common/Hooks/StateHook';
import { stringToArray } from '../../../Common/Utils/ArrayUtils';
import {
    isValidEmail,
    isValidUrl,
} from '../../../Common/Utils/ValidationUtils';
import { ICurrencyDetails } from '../../../Models/Currency.model';
import { VerificationStatus } from '../../../Redux/Slices/VerificationSlice';
import {
    IEnterpriseSummary,
    IEntity,
    IEntitySummary,
} from '../Interfaces/EnterpriseInterface';
import {
    addEntityToEnterprise,
    fetchCurrencies,
    fetchEnterprise,
    fetchEntities,
    fetchEntity,
    IEnterpriseUpdatePayload,
    IEntityUpdatePayload,
    updateEnterprise,
    updateEntity,
} from '../Services/EnterpriseService';

/*****
 Enterprise details hook
 *****/

export enum UpdateInformationActions {
    NAME = 'NAME',
    DESCRIPTION = 'DESCRIPTION',
    BUSINESS_NAME = 'BUSINESS_NAME',
    WEBSITE = 'WEBSITE',
    EMAIL = 'EMAIL',
    TELEPHONE = 'TELEPHONE',
    CURRENCY = 'CURRENCY',
    RESET = 'RESET',
}

export interface IEnterpriseDetailProviders {
    hookState: IHookState;
    originalEnterpriseDetails: IEnterpriseSummary;
    enterpriseDetails: IEnterpriseSummary;
    updateEnterpriseDetails: (
        key: UpdateInformationActions,
        value: string
    ) => void;
    isValidDetails: boolean;
    saveEnterpriseDetails: () => Promise<boolean>;
}

const initialEnterpriseDetails: IEnterpriseSummary = {
    enterpriseId: '',
    enterpriseName: '',
    enterpriseLogo: '',
    description: '',
    doingBusinessAs: '',
    website: '',
    email: '',
    telephone: '',
    address: '',
};

export const useEnterpriseDetails = () => {
    const { hookState, updateHookState } = useHookState(HookStateValue.LOADING);
    const [originalEnterpriseDetails, setOriginalEnterpriseDetails] =
        useState<IEnterpriseSummary>(initialEnterpriseDetails);
    const [enterpriseDetails, setEnterpriseDetails] =
        useState<IEnterpriseSummary>(initialEnterpriseDetails);
    const [isValidDetails, setIsValidDetails] = useState<boolean>(false);

    const getEnterpriseDetails = useCallback(async () => {
        try {
            const enterprise: IEnterpriseSummary = await fetchEnterprise();
            setOriginalEnterpriseDetails(enterprise);
            setEnterpriseDetails(enterprise);
            updateHookState(HookStateValue.READY);
        } catch (error) {
            updateHookState(HookStateValue.ERROR);
        }
    }, [updateHookState]);

    const updateEnterpriseDetails = (
        key: UpdateInformationActions,
        value: string
    ) => {
        setEnterpriseDetails((prevDetails) => {
            let newEnterpriseDetails = { ...prevDetails };
            switch (key) {
                case UpdateInformationActions.NAME:
                    newEnterpriseDetails.enterpriseName = value;
                    break;
                case UpdateInformationActions.DESCRIPTION:
                    newEnterpriseDetails.description = value;
                    break;
                case UpdateInformationActions.BUSINESS_NAME:
                    newEnterpriseDetails.doingBusinessAs = value;
                    break;
                case UpdateInformationActions.WEBSITE:
                    newEnterpriseDetails.website = value;
                    break;
                case UpdateInformationActions.EMAIL:
                    newEnterpriseDetails.email = value;
                    break;
                case UpdateInformationActions.TELEPHONE:
                    newEnterpriseDetails.telephone = value;
                    break;
                case UpdateInformationActions.RESET:
                    newEnterpriseDetails = { ...originalEnterpriseDetails };
                    break;
            }
            return { ...newEnterpriseDetails };
        });
    };

    const saveEnterpriseDetails = async (): Promise<boolean> => {
        try {
            const payload: IEnterpriseUpdatePayload = {
                enterpriseName: enterpriseDetails.enterpriseName.trim(),
                emails: !isEmpty(enterpriseDetails.email)
                    ? stringToArray(enterpriseDetails.email)
                    : [],
                telephone: !isEmpty(enterpriseDetails.telephone)
                    ? stringToArray(enterpriseDetails.telephone)
                    : [],
                website: !isEmpty(enterpriseDetails.website)
                    ? stringToArray(
                          enterpriseDetails.website.includes('http')
                              ? enterpriseDetails.website
                              : 'http://' + enterpriseDetails.website
                      )
                    : [],
                doingBusinessAs: enterpriseDetails.doingBusinessAs.trim(),
                description: enterpriseDetails.description.trim(),
                enterpriseLogo: enterpriseDetails.enterpriseLogo.trim(),
            };
            await updateEnterprise(payload);
            setOriginalEnterpriseDetails(enterpriseDetails);
            return true;
        } catch (error) {
            return false;
        }
    };

    useEffect(() => {
        getEnterpriseDetails();
    }, [getEnterpriseDetails]);

    useEffect(() => {
        let errors = false;
        if (isEmpty(enterpriseDetails.enterpriseName.trim())) errors = true;
        // if (isEmpty(enterpriseDetails.description)) errors = true;
        if (!isEmpty(enterpriseDetails.website)) {
            stringToArray(enterpriseDetails.website).forEach((website) => {
                if (!isValidUrl(website)) errors = true;
            });
        }
        // if (isEmpty(enterpriseDetails.email)) errors = true;
        if (enterpriseDetails.email.length > 0) {
            stringToArray(enterpriseDetails.email).forEach((email) => {
                if (!isValidEmail(email)) errors = true;
            });
        }
        if (
            enterpriseDetails.telephone.length > 0 &&
            (enterpriseDetails.telephone.length < 8 ||
                enterpriseDetails.telephone.length > 20)
        )
            errors = true;
        setIsValidDetails(errors ? false : true);
    }, [enterpriseDetails]);

    const enterpriseDetailProviders: IEnterpriseDetailProviders = {
        originalEnterpriseDetails,
        enterpriseDetails,
        hookState,
        updateEnterpriseDetails,
        isValidDetails,
        saveEnterpriseDetails,
    };

    return enterpriseDetailProviders;
};

/*****
 Entity details hook
 *****/

export interface IEntityDetailProviders {
    hookState: IHookState;
    originalEntityDetails: IEntitySummary;
    entityDetails: IEntitySummary;
    isValidDetails: boolean;
    currencies: ICurrencyDetails[];
    updateEntityDetails: (key: UpdateInformationActions, value: string) => void;
    saveEntityDetails: () => Promise<boolean>;
}

export const initialEntityDetails: IEntitySummary = {
    entityId: '',
    entityName: '',
    entityType: '',
    entityLogo: [],
    description: '',
    doingBusinessAs: '',
    website: '',
    email: '',
    telephone: '',
    address: '',
    currency: '',
    currencyUid: '',
    currencySymbol: '',
    verificationStatus: VerificationStatus.UNVERIFIED,
    setupPending: false,
    setup: {
        companyDetails: false,
        companyIdentifications: false,
        items: false,
        vendors: false,
        companyAddress: false,
        companyTnC: false,
    },
    createdOn: '',
    default_procurement_currency: null,
    vendor_profile_id: null,
    is_user_email_verified: false,
};

export const useEntityDetails = (
    entityId: string | undefined,
    isEntityHomePage = false
) => {
    const { entities } = useEntitiesList();
    const history = useHistory();

    useEffect(() => {
        if (entities.length > 0 && isEntityHomePage) {
            const entityExists = entities.some(
                (entity) => entity.entityId === entityId
            );
            if (!entityExists) history.push('/admin/home/');
        }
    }, [entities, entityId, history, isEntityHomePage]);

    const { hookState, updateHookState } = useHookState(HookStateValue.LOADING);
    const [originalEntityDetails, setOriginalEntityDetails] =
        useState<IEntitySummary>(initialEntityDetails);
    const [entityDetails, setEntityDetails] =
        useState<IEntitySummary>(initialEntityDetails);
    const [isValidDetails, setIsValidDetails] = useState<boolean>(false);

    const [currencies, setCurrencies] = useState<ICurrencyDetails[]>([]);

    const getEntityDetails = useCallback(async () => {
        updateHookState(HookStateValue.LOADING);
        try {
            if (!isUndefined(entityId)) {
                const entityDetails: IEntitySummary = await fetchEntity(
                    entityId
                );
                const currencies: ICurrencyDetails[] = await fetchCurrencies();
                setEntityDetails(entityDetails);
                setOriginalEntityDetails(entityDetails);
                setCurrencies(currencies);
                updateHookState(HookStateValue.READY);
            }
        } catch (error) {
            updateHookState(HookStateValue.ERROR);
        }
    }, [entityId, updateHookState]);

    const updateEntityDetails = (
        key: UpdateInformationActions,
        value: string
    ) => {
        setEntityDetails((prevDetails) => {
            let newEnterpriseDetails = { ...prevDetails };
            switch (key) {
                case UpdateInformationActions.NAME:
                    if (typeof value === 'string')
                        newEnterpriseDetails.entityName = value;
                    break;
                case UpdateInformationActions.DESCRIPTION:
                    if (typeof value === 'string')
                        newEnterpriseDetails.description = value;
                    break;
                case UpdateInformationActions.BUSINESS_NAME:
                    if (typeof value === 'string')
                        newEnterpriseDetails.doingBusinessAs = value;
                    break;
                case UpdateInformationActions.WEBSITE:
                    if (typeof value === 'string')
                        newEnterpriseDetails.website = value;
                    break;
                case UpdateInformationActions.EMAIL:
                    if (typeof value === 'string')
                        newEnterpriseDetails.email = value;
                    break;
                case UpdateInformationActions.TELEPHONE:
                    if (typeof value === 'string')
                        newEnterpriseDetails.telephone = value;
                    break;
                case UpdateInformationActions.CURRENCY:
                    newEnterpriseDetails.default_procurement_currency = value;
                    break;
                case UpdateInformationActions.RESET:
                    newEnterpriseDetails = { ...originalEntityDetails };
                    break;
            }
            return { ...newEnterpriseDetails };
        });
    };

    const saveEntityDetails = async (): Promise<boolean> => {
        try {
            const payload: IEntityUpdatePayload = {
                entityName: entityDetails.entityName,
                emails: !isEmpty(entityDetails.email)
                    ? stringToArray(entityDetails.email)
                    : [],
                telephone: !isEmpty(entityDetails.telephone)
                    ? stringToArray(entityDetails.telephone)
                    : [],
                website: !isEmpty(entityDetails.website)
                    ? stringToArray(
                          entityDetails.website.includes('http')
                              ? entityDetails.website
                              : 'http://' + entityDetails.website
                      )
                    : [],
                doingBusinessAs: entityDetails.doingBusinessAs,
                description: entityDetails.description,
                entityType: entityDetails.entityType,
                entityLogo: entityDetails.entityLogo,
                currencyUid: entityDetails.currencyUid,
                default_procurement_currency:
                    entityDetails.default_procurement_currency,
            };
            await updateEntity(entityId!, payload);
            setOriginalEntityDetails(cloneDeep(entityDetails));
            getEntityDetails();
            return true;
        } catch (error) {
            return false;
        }
    };

    useEffect(() => {
        getEntityDetails();
    }, [getEntityDetails]);

    useEffect(() => {
        let errors = false;
        if (isEmpty(entityDetails.entityName.trim())) errors = true;
        // if (isEmpty(entityDetails.description)) errors = true;
        if (!isEmpty(entityDetails.website)) {
            stringToArray(entityDetails.website).forEach((website) => {
                if (!isValidUrl(website)) errors = true;
            });
        }
        // if (isEmpty(entityDetails.email)) errors = true;
        !isEmpty(entityDetails.email) &&
            stringToArray(entityDetails.email).forEach((email) => {
                if (!isValidEmail(email)) errors = true;
            });
        if (
            entityDetails.telephone.length > 0 &&
            (entityDetails.telephone.length < 8 ||
                entityDetails.telephone.length > 20)
        )
            errors = true;
        setIsValidDetails(errors ? false : true);
    }, [entityDetails]);

    const entityDetailProviders: IEntityDetailProviders = {
        hookState,
        originalEntityDetails,
        entityDetails,
        updateEntityDetails,
        saveEntityDetails,
        isValidDetails,
        currencies,
    };

    return entityDetailProviders;
};

export interface IAddNewEntityProviders {
    hookState: IHookState;
    entityDetails: IEntitySummary;
    isValidDetails: boolean;
    currencies: ICurrencyDetails[];
    entityDetailErrors: { [key: string]: boolean };
    updateEntityDetails: (
        key: UpdateInformationActions,
        value: string | null
    ) => void;
    validateInfo: (event: any) => void;
    addEntity: () => Promise<boolean>;
}

export const useAddNewEntity = () => {
    const { hookState, updateHookState } = useHookState(HookStateValue.LOADING);
    const [entityDetails, setEntityDetails] =
        useState<IEntitySummary>(initialEntityDetails);
    const [isValidDetails, setIsValidDetails] = useState<boolean>(false);

    const [currencies, setCurrencies] = useState<ICurrencyDetails[]>([]);

    const [entityDetailErrors, setEntityDetailErrors] = useState<{
        [key: string]: boolean;
    }>({
        email: false,
        firstName: false,
        lastName: false,
    });

    const getCurrencies = useCallback(async () => {
        updateHookState(HookStateValue.LOADING);
        try {
            const currencies: ICurrencyDetails[] = await fetchCurrencies();
            setCurrencies(currencies);
            updateHookState(HookStateValue.READY);
        } catch (error) {
            updateHookState(HookStateValue.ERROR);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const addEntity = async (): Promise<boolean> => {
        try {
            await addEntityToEnterprise(entityDetails);
            return true;
        } catch (error) {
            return false;
        }
    };
    const updateEntityDetails = (
        key: UpdateInformationActions,
        value: string | null
    ) => {
        setEntityDetails((prevDetails) => {
            let newEnterpriseDetails = { ...prevDetails };
            switch (key) {
                case UpdateInformationActions.NAME:
                    if (typeof value === 'string')
                        newEnterpriseDetails.entityName = value;
                    break;
                case UpdateInformationActions.DESCRIPTION:
                    if (typeof value === 'string')
                        newEnterpriseDetails.description = value;
                    break;
                case UpdateInformationActions.BUSINESS_NAME:
                    if (typeof value === 'string')
                        newEnterpriseDetails.doingBusinessAs = value;
                    break;
                case UpdateInformationActions.WEBSITE:
                    if (typeof value === 'string')
                        newEnterpriseDetails.website = value;
                    break;
                case UpdateInformationActions.EMAIL:
                    if (typeof value === 'string')
                        newEnterpriseDetails.email = value;
                    break;
                case UpdateInformationActions.TELEPHONE:
                    if (typeof value === 'string')
                        newEnterpriseDetails.telephone = value;
                    break;
                case UpdateInformationActions.CURRENCY:
                    if (typeof value === 'string')
                        newEnterpriseDetails.default_procurement_currency =
                            value;
                    break;
                case UpdateInformationActions.RESET:
                    newEnterpriseDetails = { ...initialEntityDetails };
                    break;
            }
            return { ...newEnterpriseDetails };
        });
    };

    const validateInfo = (event: any) => {
        event.preventDefault();
        const infoKey: string = event.target.name;
        if (
            isEmpty(event.target.value.trim()) &&
            event.target.hasAttribute('required')
        ) {
            setEntityDetailErrors((prevState: any) => {
                prevState[infoKey] = true;
                return { ...prevState };
            });
        } else {
            setEntityDetailErrors((prevState: any) => {
                prevState[infoKey] = false;
                if (
                    infoKey === UpdateInformationActions.EMAIL &&
                    event.target.value.length > 0
                ) {
                    prevState[infoKey] = isValidEmail(event.target.value)
                        ? false
                        : true;
                }
                if (
                    infoKey === UpdateInformationActions.TELEPHONE &&
                    event.target.value.length > 0
                ) {
                    if (
                        entityDetails.telephone.length < 8 ||
                        entityDetails.telephone.length > 20
                    ) {
                        prevState[infoKey] = true;
                    }
                }
                return { ...prevState };
            });
        }
    };

    useEffect(() => {
        getCurrencies();
    }, [getCurrencies]);

    useEffect(() => {
        let errors = false;
        if (isEmpty(entityDetails.entityName.trim())) errors = true;
        if (!isEmpty(entityDetails.website)) {
            stringToArray(entityDetails.website).forEach((website) => {
                if (!isValidUrl(website)) errors = true;
            });
        }
        // if (isEmpty(entityDetails.email)) errors = true;
        if (entityDetails.email.length > 0) {
            stringToArray(entityDetails.email).forEach((email) => {
                if (!isValidEmail(email)) errors = true;
            });
        }
        if (
            entityDetails.telephone.length > 0 &&
            (entityDetails.telephone.length < 8 ||
                entityDetails.telephone.length > 20)
        )
            errors = true;
        setIsValidDetails(errors ? false : true);
    }, [entityDetails]);

    const addNewEntityProvider: IAddNewEntityProviders = {
        hookState,
        entityDetails,
        updateEntityDetails,
        isValidDetails,
        currencies,
        validateInfo,
        entityDetailErrors,
        addEntity,
    };

    return addNewEntityProvider;
};

/*****
 Entity list hook
 *****/
export interface IEntityListProviders {
    entities: IEntity[];
}

export const useEntitiesList = (update?: number) => {
    const [entities, setEntities] = useState<IEntity[]>([]);

    const getEntities = useCallback(async () => {
        try {
            const entities: IEntity[] = await fetchEntities();
            setEntities(entities);
        } catch (error) {}
    }, []);

    useEffect(() => {
        getEntities();
    }, [getEntities, update]);

    return {
        entities,
    } as IEntityListProviders;
};
