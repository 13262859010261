import { createSlice } from '@reduxjs/toolkit';

export interface IRfqAnalyticsStoreListOfBomStruct {
    enterprise_bom_id: string;
    bom_code: string;
    listOfRfqItemIds: string[];
}

export interface IRfqAnalyticsStoreListOfBomStructWIthHeiriacy {
    enterprise_bom_id?: string;
    label: string;
    sub_bom_id?: string;
    listOfRfqItemIds: string[];
}

export interface IRfqAnalyticsStore {
    listOfBomsOfCurrentEvent: IRfqAnalyticsStoreListOfBomStruct[];
    listOfBomsOfCurrentEventsWithSubBomOptions: IRfqAnalyticsStoreListOfBomStructWIthHeiriacy[];
    selectedEnterpriseBomToBeShown: string | null;
    selectedEnterpriseSubBomToBeShown: string | null;
    shortListUpdateTrigger: number;
    loadingShortListedData: boolean;
    listOfRequisitions: { requisitionId: string; label: string }[];
    selectedRequisition: string;
}

export interface IUpdateRfqAnalyticsStore {
    type: string;
    payload:
        | {
              type: 'UPDATE_SLECTED_ENTERPRISE_BOM_ID';
              value: string | null;
          }
        | {
              type: 'UPDATE_SLECTED_ENTERPRISE_SUB_BOM_ID';
              value: string | null;
          }
        | {
              type: 'UPDATE_LIST_OF_BOMS_OF_CURRENT_EVENT';
              value: IRfqAnalyticsStoreListOfBomStruct[];
          }
        | {
              type: 'UPDATE_LIST_OF_REQUISITIONS';
              value: { requisitionId: string; label: string }[];
          }
        | {
              type: 'UPDATE_SELECTED_REQUISITION';
              value: string;
          }
        | {
              type: 'UPDATE_LIST_OF_BOMS_OF_CURRENT_EVENT_WITH_HEIRARY';
              value: IRfqAnalyticsStoreListOfBomStructWIthHeiriacy[];
          }
        | {
              type: 'UPDATE_SHORT_LIST_UPDATE_TRIGGER';
              value: null;
          }
        | {
              type: 'UPDATE_LOADING_SHORT_LISTED_DATA';
              value: boolean;
          }
        | {
              type: 'RESET';
              value: null;
          };
}

export const RFQAnalyticsSlice = createSlice<
    IRfqAnalyticsStore,
    {
        updateRfqAnalyticsStore: (
            state: IRfqAnalyticsStore,
            action: IUpdateRfqAnalyticsStore
        ) => void;
    },
    string
>({
    name: 'RfqAnalyticsStore',
    initialState: {
        selectedRequisition: 'null',
        listOfRequisitions: [],
        listOfBomsOfCurrentEvent: [],
        listOfBomsOfCurrentEventsWithSubBomOptions: [],
        selectedEnterpriseBomToBeShown: null,
        selectedEnterpriseSubBomToBeShown: null,
        shortListUpdateTrigger: 0,
        loadingShortListedData: false,
    },
    reducers: {
        updateRfqAnalyticsStore: (state, action) => {
            switch (action.payload.type) {
                case 'UPDATE_SLECTED_ENTERPRISE_BOM_ID':
                    state.selectedEnterpriseBomToBeShown =
                        action.payload.value !== 'null'
                            ? action.payload.value
                            : null;
                    break;
                case 'UPDATE_SLECTED_ENTERPRISE_SUB_BOM_ID':
                    state.selectedEnterpriseSubBomToBeShown =
                        action.payload.value;

                    break;
                case 'UPDATE_LIST_OF_BOMS_OF_CURRENT_EVENT':
                    state.listOfBomsOfCurrentEvent = action.payload.value;
                    break;
                case 'UPDATE_LIST_OF_REQUISITIONS':
                    state.listOfRequisitions = action.payload.value;
                    break;
                case 'UPDATE_SELECTED_REQUISITION':
                    state.selectedRequisition = action.payload.value;
                    break;
                case 'UPDATE_LIST_OF_BOMS_OF_CURRENT_EVENT_WITH_HEIRARY':
                    state.listOfBomsOfCurrentEventsWithSubBomOptions =
                        action.payload.value;
                    break;
                case 'UPDATE_SHORT_LIST_UPDATE_TRIGGER':
                    state.shortListUpdateTrigger += 1;
                    break;
                case 'UPDATE_LOADING_SHORT_LISTED_DATA':
                    state.loadingShortListedData = action.payload.value;
                    break;
                case 'RESET':
                    state.listOfBomsOfCurrentEvent = [];
                    state.selectedEnterpriseBomToBeShown = null;
                    state.shortListUpdateTrigger = 0;
                    state.loadingShortListedData = false;
                    break;
            }
        },
    },
});

export const { updateRfqAnalyticsStore } = RFQAnalyticsSlice.actions;
