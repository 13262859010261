import { IRFQItemCustomIDs } from '../../Events/Interfaces/Buyer/SharedRFQ.model';
import { IEntityPermissionMap } from '../../Global/Hooks/usePermissionCheck';
import {
    CustomTemplateItemTypes,
    CustomTemplateTypes,
} from '../../Global/Interfaces/CustomTemplate.interface';
import { IPOItemHoldTerminationInfo } from '../../Global/Interfaces/ItemAndPOHelperInterfaces';
import { IAttachment } from '../../Models/RFQ.model';
import { IQCCompletedListContext } from '../Containers/QCCompletedListContainer';
import { IQCPendingListContext } from '../Containers/QCPendingListContainer';

export enum QCType {
    PRIMARY = 'QUALITY_CHECK_PRIMARY',
    SECONDARY = 'QUALITY_CHECK_SECONDARY',
    PRODUCTION_LINE = 'QUALITY_CHECK_PRODUCTION_LINE',
}

export interface IPageDetails {
    current_page: number;
    has_next: boolean;
    has_previous: boolean;
    total_pages: number;
}

export enum QCState {
    VIEW = 'View',
    PRIMARY = 'Primary',
    SECONDARY = 'Secondary',
    PRODUCTION_LINE = 'Production Line',
}

export interface IQCGRNListSummary extends IPOItemHoldTerminationInfo {
    grnUid: string;
    // grnNo: string;
    batchNo: string;
    batchQuantity: string;
    itemName: string;
    itemDetails: IQCItemSummary;
    // receiptDate: string;
    // acceptedQty: number;
    measurementUnit: string;
    measurementUnitId: string;
    poId: string;
    invoiceId: string;
    qcQuantity: number;
    entityUid: string;
    invoiceVerified: boolean;
    buyerEntityName: string;
    invItmBtchUid: string;
    GrAccepted: string;
    isGrDone: boolean;
}

export interface IARNList {
    [key: string]: {
        acceptedARN: string;
        rejectedARN: string;
    }[];
}

export interface IAcceptedRejectedList {
    acceptedQty: string;
    rejectedQty: string;
    acceptedARN: string;
    rejectedARN: string;
    qcType: string;
    measurementUnit: string;
    label: string;
    batchUid?: string;
    batchId?: string;
    totalQty?: string;
    grUid?: string;
}

export interface IQCCompletedListSummary extends IQCGRNListSummary {
    qcDate: string[];
    arn: IARNList;
    acceptedRejected: IAcceptedRejectedList[];
    arnsString: string;
    acceptedQty: number;
}

interface IItemAttributes {
    buyerAttributes: {
        name: string;
        value: string[];
    }[];
    customAttributes: {
        name: string;
        value: string[];
    }[];
}

export interface IQCItemSummary {
    itemId: string;
    itemName: string;
    description: string;
    additionalInfo: string;
    attributes: IItemAttributes;
}

export interface IQCListContext {
    qcPermissions: IEntityPermissionMap;
    handleItemPopupToggle: (
        item: IQCPendingListContext | IQCCompletedListContext
    ) => void;
    handleAcceptRejectPopupToggle: (item: IQCCompletedListContext) => void;
}

export interface IQCCreationDetails {
    grnUid: string;
    qcType: string;
    qcQuantity: string;
    qcAcceptedQty: string;
    qcRejectedQty: number;
    note: string;
    checklist: {
        template_id: string;
        template_name: string;
        type: CustomTemplateTypes.QC_CHECKLIST;
        enterprise: string;
        entity: string;
        items: {
            value: string;
            template_item_id: string;
            template_item_name: string;
            template_item_type: CustomTemplateItemTypes;
            is_required: boolean;
            template: string;
        }[];
    }[];
    customFields: {
        template_id: string;
        template_name: string;
        type: CustomTemplateTypes.QC_CUSTOM_FIELDS;
        enterprise: string;
        entity: string;
        items: {
            value: string;
            template_item_id: string;
            template_item_name: string;
            template_item_type: CustomTemplateItemTypes;
            is_required: boolean;
            template: string;
        }[];
    };
    attachments: IAttachment[];
}

export interface IQCSummary {
    qcType: string;
    qcQuantity: string;
    qcAcceptedQty: string;
    qcRejectedQty: string;
    note: string;
    acceptanceARN: string;
    rejectionARN: string;
    qcCreatedBy: string;
    qcCreationDate: string;
    qcPersonUid: string;
    attachments: IAttachment[];
    checklist: {
        template_id: string;
        template_name: string;
        template_type: string;
        template_items: {
            value: string;
            template: string;
            is_required: boolean;
            template_item_id: string;
            template_item_name: string;
            template_item_type: string;
        }[];
    }[];
    custom_fields: {
        template_id: string;
        template_name: string;
        template_type: string;
        template_items: {
            value: string;
            template: string;
            is_required: boolean;
            template_item_id: string;
            template_item_name: string;
            template_item_type: string;
        }[];
    };
}

export interface IQCDetails extends IQCItemSummary, IPOItemHoldTerminationInfo {
    batchNo: string;
    batchQty: number;
    measurementUnit: string;
    measurementUnitId: string;
    vendorName: string;
    vendorContact: string;
    vendorPersonUid: string;
    poId: string;
    poCreatedBy: string;
    poCreationDate: string;
    poPersonUid: string;
    invoiceId: string;
    invoiceCreatedBy: string;
    invoiceCreationDate: string;
    invoicePersonUid: string;
    grnNo: string;
    customIDs: IRFQItemCustomIDs[];
    grnCreatedBy: string;
    grnCreationDate: string;
    grPersonUid: string;
    qcList: IQCSummary[];
    measurementType: string;
    buyerEntityUid: string;
    invoiceVerified: boolean;
    invoiceUid: string;
    invoiceAttachments: IAttachment[];
}
