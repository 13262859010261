import { createSlice } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';

export const daysNameToDaysNumberMapping: { [dayOfTheWeek: string]: number } = {
    sunday: 0,
    monday: 1,
    tuesday: 2,
    wednesday: 3,
    thursday: 4,
    friday: 5,
    saturday: 6,
};

const initialSettings: IAdminHolidaySettingStore = {
    holdays: [
        // {
        //     date: '2024-07-10T07:09:57.925Z',
        //     holidayName: '',
        // },
        // {
        //     date: '2024-07-15T07:11:30.857Z',
        //     holidayName: '',
        // },
        // {
        //     date: '2024-07-20T07:11:30.857Z',
        //     holidayName: '',
        // },
        // {
        //     date: '2024-07-25T07:11:30.857Z',
        //     holidayName: '',
        // },
        // {
        //     date: '2024-07-30T07:11:30.857Z',
        //     holidayName: '',
        // },
    ],
    nonWorkingInfo: [],
};

export const AdminHolidaySettingSlice = createSlice<
    IAdminHolidaySettingStore,
    {
        updateHolidayAdminSetting: (
            state: IAdminHolidaySettingStore,
            action: IAdminHolidaySettingActions
        ) => void;
    }
>({
    name: 'AdminHolidaySetting',
    initialState: cloneDeep(initialSettings),
    reducers: {
        updateHolidayAdminSetting: (state, action) => {
            switch (action.payload.type) {
                case 'UPDATE_HOLIDAYS':
                    state.holdays = action.payload.values;
                    break;
                case 'UPDATE_NON_WORKING_DAYS':
                    state.nonWorkingInfo = action.payload.values;
                    break;
                default:
                    break;
            }
        },
    },
});

export const { updateHolidayAdminSetting } = AdminHolidaySettingSlice.actions;

export default AdminHolidaySettingSlice.reducer;

export interface ISingleHolidayStruct {
    date: string;
    holidayName: string;
}

export interface IAdminHolidaySettingStore {
    holdays: ISingleHolidayStruct[];
    nonWorkingInfo: number[];
}

export interface IAdminHolidaySettingActions {
    type: string;
    payload:
        | {
              type: 'UPDATE_HOLIDAYS';
              values: ISingleHolidayStruct[];
          }
        | {
              type: 'UPDATE_NON_WORKING_DAYS';
              values: number[];
          };
}
