import React from 'react';

const CreatedByFactWise = ({ style = {} }: { style?: React.CSSProperties }) => {
    return (
        <span
            style={{
                position: 'absolute',
                bottom: -30,
                right: 40,
                ...style,
            }}
        >
            Created by FactWise
        </span>
    );
};

export default CreatedByFactWise;
