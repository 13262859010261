import { get } from '../../Utils/api';
import { TFeatures } from '../Interfaces/FeaturesInterface';

export const fetchAvailableFeatures = async (): Promise<TFeatures> => {
    const response = await get<any>('/organization/subscription/features/');
    const availableFeatures = response.data;
    //

    // hard coded dummy data with all features enabled
    // const availableFeatures: TFeatures = [
    // 'feature-admin-allsettings-enabled',
    // 'feature-admin-eventsettings-enabled',
    // 'feature-event-customattributes-enabled',
    // 'feature-event-customfields-enabled',
    // 'feature-event-module-enabled',
    // 'feature-gr-customchecklists-enabled',
    // 'feature-gr-customfields-enabled',
    // 'feature-admin-grsettings-enabled',
    // 'feature-gr-moduleaccess-enabled',
    // 'feature-invoice-management-enabled',
    // 'feature-po-management-enabled',
    // 'feature-qc-customchecklists-enabled',
    // 'feature-qc-customfields-enabled',
    // 'feature-admin-qcsettings-enabled',
    // 'feature-qc-moduleaccess-enabled',
    // 'feature-event-rfqanalytics-enabled',
    // 'feature-payment-module-enabled',
    // 'feature-invoice-module-enabled',
    // ];
    return availableFeatures;
};
