import { Box, Grid, IconButton } from '@mui/material';
import React, { useState } from 'react';
import { FWMenu, FWMenuItem } from '../../Common/FWCustomMenu';
import { FWTooltip } from '../../Common/FWCustomTooltip';
import { FWPopupWithChildren } from '../../Common/FWPopupWithChildren';
import { ILoading } from '../../Events/Interfaces/Buyer/SharedRFQ.model';
import SellerDraftDocumentDetails from '../Components/SellerDraftDocumentDetails';
import {
    DocumentStateEnum,
    IDocumentDetails,
    IDocumentDetailsErrors,
    INewDocument,
} from '../Constants/Document.model';
import { IDocumentTemplate } from '../Constants/Interfaces/documentTemplate';

interface IHeaderMenu {
    showMoreOptions: boolean;
    showShareVendors: boolean;
    onClickShareVendors(): void;
    showReviseDocument: boolean;
    onClickReviseDocument(): void;
    showDelete: boolean;
    onClickDelete(): void;
    documentDetails?: IDocumentDetails;
    templateDetails?: IDocumentTemplate;
    updateDocumentDetails?: (
        field: keyof INewDocument,
        value: any
    ) => Promise<void>;
    documentsPayload?: INewDocument;
    errors?: IDocumentDetailsErrors;

    loadingState?: ILoading;
    documentEssentials?: {
        entityName: string;
        templateName: string;
    };
}

export default function DocumentHeaderMenu({
    showMoreOptions,
    showShareVendors,
    onClickShareVendors,
    showReviseDocument,
    onClickReviseDocument,
    showDelete,
    documentDetails,
    onClickDelete,
    documentEssentials,
    documentsPayload,
    errors,
    loadingState,
    templateDetails,
    updateDocumentDetails,
}: IHeaderMenu) {
    const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(
        null
    );
    const menuIsOpen = Boolean(menuAnchorEl);
    const openMenu = (event: any) => {
        setMenuAnchorEl(event.currentTarget);
    };
    const closeMenu = () => {
        setMenuAnchorEl(null);
    };

    const [showPreview, setShowPreview] = useState(false);

    return (
        <div style={{ display: 'flex', gap: '5px' }}>
            <FWTooltip title={'Preview document'}>
                <div>
                    <IconButton
                        onClick={() => setShowPreview(true)}
                        color="primary"
                    >
                        <i
                            className="bi bi-eye"
                            style={{ fontSize: '25px' }}
                        ></i>
                    </IconButton>
                </div>
            </FWTooltip>
            {showShareVendors && (
                <FWTooltip title={'Share to vendors'}>
                    <div>
                        <IconButton
                            onClick={() => {
                                closeMenu();
                                onClickShareVendors();
                            }}
                            color="primary"
                        >
                            <i
                                className="bi bi-share"
                                style={{ fontSize: '25px' }}
                            ></i>
                        </IconButton>
                    </div>
                </FWTooltip>
            )}
            {/* {showShareVendors && (
                <FWTooltip title={'Share to vendors'}>
                    <div>
                        <IconButton
                            sx={{ color: 'primary.main' }}
                            onClick={() => {
                                closeMenu();
                                onClickShareVendors();
                            }}
                            id={'docNav_shareVendors'}
                        >
                            <i
                                className="bi bi-share"
                                style={{ fontSize: '24px' }}
                            />
                        </IconButton>
                    </div>
                </FWTooltip>
            )} */}
            {showMoreOptions && (
                <FWTooltip title={'More options'}>
                    <div>
                        <IconButton onClick={openMenu} color="primary">
                            <i
                                aria-controls={
                                    menuIsOpen ? 'basic-menu' : undefined
                                }
                                aria-haspopup="true"
                                aria-expanded={menuIsOpen ? 'true' : undefined}
                                className="bi bi-three-dots"
                                style={{ fontSize: '25px' }}
                            ></i>
                        </IconButton>
                    </div>
                </FWTooltip>
            )}

            <FWMenu
                anchorEl={menuAnchorEl}
                open={menuIsOpen}
                onClose={closeMenu}
            >
                {/* {showShareVendors && (
                    <FWMenuItem
                        sx={{ color: 'primary.main' }}
                        onClick={() => {
                            closeMenu();
                            onClickShareVendors();
                        }}
                        id={'docNav_shareVendors'}
                    >
                        <i className="bi bi-share" /> &nbsp;&nbsp;
                        {'Share to vendors'}
                    </FWMenuItem>
                )} */}
                {showReviseDocument && (
                    <FWMenuItem
                        sx={{ color: 'error.main' }}
                        onClick={() => {
                            closeMenu();
                            onClickReviseDocument();
                        }}
                        id={'docNav_reviseDocument'}
                    >
                        <i className="bi bi-arrow-clockwise" /> &nbsp;&nbsp;
                        {'Revise Document'}
                    </FWMenuItem>
                )}
                {showDelete && (
                    <FWMenuItem
                        sx={{ color: 'error.main' }}
                        onClick={() => {
                            closeMenu();
                            onClickDelete();
                        }}
                        id={'docNav_deleteDocument'}
                    >
                        <i className="bi bi-trash" /> &nbsp;&nbsp;
                        {'Delete Document'}
                    </FWMenuItem>
                )}
            </FWMenu>

            <FWPopupWithChildren
                open={showPreview}
                size="custom"
                popupWidth={'75vw'}
                hideTitle
                // titleAddOnElement={
                //     documentsPayload?.document_logo_id?.length !== 0 &&
                //     documentsPayload?.document_banner_id?.length !== 0 ? (
                //         <FWTypography
                //             fontSize={30}
                //             fontWeight={600}
                //             textAlign={'center'}
                //             marginX={'auto'}
                //         >
                //             {documentDetails
                //                 ? documentDetails.document_name
                //                 : ''}
                //         </FWTypography>
                //     ) : documentsPayload.document_validity_datetime ? (
                //         <FWTypography
                //             fontSize={16}
                //             // color={'text.secondary'}
                //             textAlign={'right'}
                //             marginLeft={'auto'}
                //             marginRight={'12.5%'}
                //         >
                //             Valid till:{' '}
                //             {getFWDateTime(
                //                 documentsPayload.document_validity_datetime
                //             )}
                //         </FWTypography>
                //     ) : (
                //         <></>
                //     )
                // }
                title={documentDetails ? documentDetails.document_name : ''}
                handleClose={() => setShowPreview(false)}
            >
                <Box
                    sx={{ overflowX: 'hidden', background: '#F0F0F2' }}
                    marginTop={2}
                >
                    <Grid
                        // marginX={4}
                        // sx={{ overflowX: 'hidden !important' }}
                        marginBottom={4}
                        sx={{
                            display: 'flex',
                            gap: '4px',
                            flexWrap: 'wrap',
                        }}
                        // gap={STANDARD_GRID_COL_GAP}
                    >
                        {templateDetails &&
                            documentsPayload &&
                            documentDetails &&
                            errors &&
                            updateDocumentDetails && (
                                <SellerDraftDocumentDetails
                                    buyerDocumentDetails={documentDetails}
                                    sellerDocumentDetails={{
                                        ...documentDetails,
                                        state: DocumentStateEnum.SUBMITTED,
                                    }}
                                    errors={errors}
                                    isBuyerSide={true}
                                    loadingState={
                                        loadingState ?? {
                                            error: false,
                                            error_msg: '',
                                            loading: false,
                                            saved: false,
                                        }
                                    }
                                    pageMode="VIEW"
                                    sellerDocumentPayload={documentsPayload}
                                    // allowTemplateUpload={
                                    //     sellerDocumentDetails.state ===
                                    //         DocumentStateEnum.DRAFT ||
                                    //     pageMode === 'EDIT'
                                    // }
                                    // gridSize={2.8}

                                    // allowFileUpload={false}
                                    // showRemoveButton={false}
                                    templateDetails={templateDetails}
                                    updateDocumentDetails={
                                        updateDocumentDetails
                                    }

                                    // sectionName={'DOCUMENT_DETAIL'}
                                />
                            )}
                    </Grid>
                </Box>
                {/* <DraftDocumentCustomFields
                    sectionName={templateDetails.sections.DOCUMENT_DETAIL.label}
                    documentsPayload={documentsPayload}
                    standardGridSize={STANDARD_GRID_SIZE}
                    customGridSize={{
                        LONGTEXT: STANDARD_LONGFIELD_GRID_SIZE,
                    }}
                    pageMode="VIEW"
                    documentDetails={documentDetails}
                    errors={errors}
                    templateDetails={templateDetails}
                    updateDocumentDetails={updateDocumentDetails}
                /> */}
            </FWPopupWithChildren>
        </div>
    );
}

interface ISellerHeaderMenu {
    showMoreOptions: boolean;

    showReviseDocument: boolean;
    onClickReviseDocument(): void;
}

export function SellerDocumentHeaderMenu({
    showMoreOptions,
    showReviseDocument,
    onClickReviseDocument,
}: ISellerHeaderMenu) {
    const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(
        null
    );
    const menuIsOpen = Boolean(menuAnchorEl);
    const openMenu = (event: any) => {
        setMenuAnchorEl(event.currentTarget);
    };
    const closeMenu = () => {
        setMenuAnchorEl(null);
    };
    return (
        <div>
            {showMoreOptions && (
                <FWTooltip title={'More options'}>
                    <div>
                        <IconButton onClick={openMenu} color="primary">
                            <i
                                aria-controls={
                                    menuIsOpen ? 'basic-menu' : undefined
                                }
                                aria-haspopup="true"
                                aria-expanded={menuIsOpen ? 'true' : undefined}
                                className="bi bi-three-dots"
                                style={{ fontSize: '25px' }}
                            ></i>
                        </IconButton>
                    </div>
                </FWTooltip>
            )}
            <FWMenu
                anchorEl={menuAnchorEl}
                open={menuIsOpen}
                onClose={closeMenu}
            >
                {showReviseDocument && (
                    <FWMenuItem
                        sx={{ color: 'error.main' }}
                        onClick={() => {
                            closeMenu();
                            onClickReviseDocument();
                        }}
                        id={'docNav_reviseDocument'}
                    >
                        <i className="bi bi-arrow-clockwise" /> &nbsp;&nbsp;
                        {'Revise submitted document'}
                    </FWMenuItem>
                )}
            </FWMenu>
        </div>
    );
}
