import { IHistoricalAnalytics } from '../Interfaces/IHistoricalAnalytics.model';
import {
    HA_Financial_CommittedSpend,
    HA_Financial_InvoicedValueByVendor,
    HA_Financial_PaymentTerms,
    HA_Financial_PaymentTimeliness,
} from './Charts_Financial';
import {
    HA_Items_PurchaseOrderCountByEventType,
    HA_Items_VendorsUsed,
    HA_Items_Quality,
    HA_Items_TagAnalysis,
} from './Charts_Items';
import {
    HA_Users_PurchaseOrderValue,
    HA_Users_RfqCounts,
    HA_Users_PurchaseOrderCountByEventType,
    HA_Users_ItemsAndVendors,
} from './Charts_Users';
import {
    HA_Vendors_TotalSpend,
    HA_Vendors_Quality,
    HA_Vendors_ResponseRate,
    HA_Vendors_PoValueByTag,
} from './Charts_Vendors';

export const HAConstantData: IHistoricalAnalytics[] = [
    {
        tabName: 'Financials',
        charts: [
            HA_Financial_PaymentTerms,
            HA_Financial_CommittedSpend,
            HA_Financial_PaymentTimeliness,
            HA_Financial_InvoicedValueByVendor,
        ],
    },
    {
        tabName: 'Vendors',
        charts: [
            HA_Vendors_TotalSpend,
            HA_Vendors_Quality,
            HA_Vendors_ResponseRate,
            HA_Vendors_PoValueByTag,
        ],
    },
    {
        tabName: 'Items',
        charts: [
            HA_Items_PurchaseOrderCountByEventType,
            HA_Items_VendorsUsed,
            // HA_Items_PriceAnalysis,
            HA_Items_TagAnalysis,
            HA_Items_Quality,
        ],
    },
    {
        tabName: 'Users',
        charts: [
            HA_Users_PurchaseOrderValue,
            HA_Users_RfqCounts,
            HA_Users_PurchaseOrderCountByEventType,
            HA_Users_ItemsAndVendors,
            // HA_Users_ItemsAndUsers,
            // HA_Users_PurchaseOrderValueAndVendors,
            // HA_Users_PurchaseOrderValueByEventType,
        ],
    },
    {
        tabName: 'Custom Analytics',
        charts: [],
    },
];
