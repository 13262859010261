import { isArray, isEmpty, isNull, isNumber } from 'lodash';
import { CustomTemplateItemTypes } from '../../Global/Interfaces/CustomTemplate.interface';
import { IAdminCustomField } from '../../Organizations/Admin/Interfaces/AdminSettingsInterface';
import {
    IGroupedInvoiceItemsDict,
    IGroupedInvoiceItemsSummary,
    IInvoiceItemsSummary,
    ItemGRStatus,
} from '../Interfaces/GRInterface';

export enum GRQuantityValidityType {
    BLANK = 'BLANK',
    SUCCESS = 'SUCCESS',
    ERROR = 'ERROR',
    WARNING = 'WARNING',
}

export enum ToleranceType {
    NEGATIVE = 'NEGATIVE',
    POSITIVE = 'POSITIVE',
}

interface IGRQuantityValid {
    type: GRQuantityValidityType;
    isValid: boolean;
    withinTolerance: boolean;
}

// const isWithinTolerance = (
//     minusToleranceQty: number,
//     plusToleranceQty: number,
//     acceptedQuantity: number,
//     lowerValueQuantity: number
// ): boolean => {
//     if (
//         minusToleranceQty <= acceptedQuantity &&
//         acceptedQuantity <= plusToleranceQty &&
//         acceptedQuantity <= lowerValueQuantity
//     ) {
//         return true;
//     }
//     return false;
// };

export const isGRQuantityValid = (
    invoiceItem: IInvoiceItemsSummary,
    pendingQuantity?: number,
    itemsList?: IInvoiceItemsSummary[]
): IGRQuantityValid => {
    // check which quantity is lower
    const invoicedBatchQuantity = +(invoiceItem.invoicedBatchQuantity || '0');

    const lowEndQuantity =
        invoicedBatchQuantity > invoiceItem.pendingQuantity
            ? invoiceItem.pendingQuantity
            : invoicedBatchQuantity;

    const accepted = +invoiceItem.grAcceptedQuantity || 0;
    const rejected = +invoiceItem.grRejectedQuantity || 0;
    const tolerance = +invoiceItem.toleranceQty || 0;

    const sumOfAcceptedRejectedTolerance = +(
        accepted +
        rejected +
        tolerance
    ).toFixed(2);

    const finalQuantity =
        sumOfAcceptedRejectedTolerance +
        +(
            +invoiceItem.grOverDeliveredQuantity < 0
                ? 0
                : +invoiceItem.grOverDeliveredQuantity
        ).toFixed(2);
    if (isEmpty(invoiceItem.grAcceptedQuantity)) {
        // when GR quantity is empty
        return {
            type: GRQuantityValidityType.BLANK,
            isValid: true,
            withinTolerance: false,
        };
    } else if (accepted > lowEndQuantity) {
        // when GR quantity is greater than lower value of invoiced quantity || pending po quantity
        return {
            type: GRQuantityValidityType.ERROR,
            isValid: false,
            withinTolerance: false,
        };
    } else if (accepted < lowEndQuantity && rejected > 0) {
        // when GR quantity is less than lower value of invoiced quantity || pending po quantity
        return {
            type: GRQuantityValidityType.WARNING,
            isValid: false,
            withinTolerance: false,
        };
    } else if (sumOfAcceptedRejectedTolerance > lowEndQuantity) {
        // when GR accepted + rejected quantity is greater than lower value of invoiced quantity || pending po quantity
        return {
            type: GRQuantityValidityType.WARNING,
            isValid: false,
            withinTolerance: false,
        };
    } else if (
        accepted === lowEndQuantity ||
        finalQuantity === invoicedBatchQuantity
    ) {
        // when GR quantity is equal to lower value of invoiced quantity || pending po quantity
        return {
            type: GRQuantityValidityType.SUCCESS,
            isValid: true,
            withinTolerance: false,
        };
    }

    if (accepted <= lowEndQuantity && accepted > 0) {
        return {
            type: GRQuantityValidityType.SUCCESS,
            isValid: true,
            withinTolerance: true,
        };
    }

    let itemTotals = 0;

    if (isArray(itemsList) && isNumber(pendingQuantity)) {
        itemTotals = calculateGRItemsTotalQty(itemsList);

        if (itemTotals > pendingQuantity) {
            return {
                type: GRQuantityValidityType.ERROR,
                isValid: false,
                withinTolerance: false,
            };
        }
    }

    return {
        type: GRQuantityValidityType.BLANK,
        isValid: true,
        withinTolerance: false,
    };
};

export const calculateFinalGRQty = (
    groupedInvoiceItem: IGroupedInvoiceItemsSummary,
    batchIdx: number
) => {
    let rejectedQty = 0;
    let invoiceFulfilQty = 0;
    let overDeliveredQty = 0;
    let toleranceQty = 0;

    const batch = groupedInvoiceItem.itemsList[batchIdx];

    const overdeliveryApplicable =
        groupedInvoiceItem.pendingQuantity <
        groupedInvoiceItem.itemsList.reduce(
            (acc, item) => acc + +item.invoicedBatchQuantity,
            0
        );

    if (overdeliveryApplicable) {
        let usedOverdeliveryQty = 0;
        for (let i = 0; i < groupedInvoiceItem.itemsList.length; i++) {
            if (i !== batchIdx) {
                usedOverdeliveryQty +=
                    +groupedInvoiceItem.itemsList[i].grOverDeliveredQuantity;
            }
        }

        const maxAllowedOverdeliveryForCurrentBatch = Math.max(
            groupedInvoiceItem.itemsList.reduce(
                (acc, item) => acc + +item.invoicedBatchQuantity,
                0
            ) -
                groupedInvoiceItem.pendingQuantity -
                usedOverdeliveryQty,
            0
        );

        overDeliveredQty = Math.min(
            maxAllowedOverdeliveryForCurrentBatch,
            +batch.invoicedBatchQuantity - +batch.grAcceptedQuantity
        );

        rejectedQty = Math.max(
            +batch.invoicedBatchQuantity -
                +batch.grAcceptedQuantity -
                overDeliveredQty,
            0
        );

        invoiceFulfilQty =
            +batch.invoicedBatchQuantity - rejectedQty - overDeliveredQty;
    } else {
        let usedTolerances = 0;
        let SumOfAllBatchQty = 0;
        for (let i = 0; i < groupedInvoiceItem.itemsList.length; i++) {
            if (i !== batchIdx) {
                usedTolerances += +groupedInvoiceItem.itemsList[i].toleranceQty;
            }
            SumOfAllBatchQty +=
                +groupedInvoiceItem.itemsList[i].invoicedBatchQuantity;
        }
        const maxAllowedToleranceForCurrentBatch = Math.max(
            SumOfAllBatchQty -
                groupedInvoiceItem.minusToleranceQty -
                usedTolerances,
            0
        );

        toleranceQty = Math.min(
            maxAllowedToleranceForCurrentBatch,
            +batch.invoicedBatchQuantity - +batch.grAcceptedQuantity
        );

        rejectedQty = Math.max(
            +batch.invoicedBatchQuantity -
                +batch.grAcceptedQuantity -
                toleranceQty,
            0
        );
        invoiceFulfilQty = +batch.invoicedBatchQuantity - rejectedQty;
    }

    return {
        rejectedQty,
        invoiceFulfilQty,
        overDeliveredQty,
        toleranceQty,
    };
};

export const isGRItemValid = (
    invoiceItem: IInvoiceItemsSummary,
    negativeToleranceQty: number,
    grnCustomFields?: IAdminCustomField[]
): boolean => {
    if (
        isEmpty(invoiceItem.grAcceptedQuantity) ||
        isEmpty(invoiceItem.grRejectedQuantity) ||
        isEmpty(invoiceItem.toleranceQty)
    ) {
        return false;
    }

    if (
        invoiceItem.grAcceptedQuantity +
            invoiceItem.grRejectedQuantity +
            invoiceItem.toleranceQty +
            invoiceItem.grOverDeliveredQuantity !==
        invoiceItem.invoicedBatchQuantity
    ) {
        return false;
    }

    // if GR rejected quantity is above 0 and no rejection reason provided
    if (
        +invoiceItem.grRejectedQuantity > 0 &&
        isEmpty(invoiceItem.rejectionReason)
    ) {
        return false;
    }
    if (
        !isEmpty(invoiceItem.rejectionReason) &&
        invoiceItem.rejectionReason.trim().split('').length < 10 &&
        +invoiceItem.grRejectedQuantity > 0
    ) {
        return false;
    }

    if (isEmpty(invoiceItem.batchNo)) {
        return false;
    }

    if (isEmpty(invoiceItem.invoicedBatchQuantity)) {
        return false;
    }

    if (+invoiceItem.invoicedBatchQuantity === 0) {
        return false;
    }

    for (const CF of invoiceItem.customFields.items) {
        if (CF.is_required && isEmpty(CF.value)) {
            return false;
        }
        if (
            CF.template_item_type === CustomTemplateItemTypes.FLOAT &&
            isNaN(+CF.value)
        ) {
            return false;
        }
    }

    return true;
};

export const toleranceQty = (
    percent: number,
    invoicedQty: number,
    type: ToleranceType
) => {
    let toleranceQty = 0;
    switch (type) {
        case ToleranceType.NEGATIVE:
            toleranceQty = +((1 - percent / 100) * invoicedQty).toFixed(2);
            break;
        case ToleranceType.POSITIVE:
            toleranceQty = +((1 + percent / 100) * invoicedQty).toFixed(2);
            break;
    }
    return toleranceQty;
};

export const calculatePendingQuantity = (
    actual: number,
    delivered: number,
    rescheduled: number
) => {
    const pq = actual - delivered + rescheduled;

    return pq < 0 ? 0 : pq;
};

export const generateAttributes = (
    buyerAttributes: any[],
    customAttributes: any[]
) => {
    return {
        buyerAttributes: buyerAttributes.map((attr: any) => {
            return {
                name: attr.attribute_name,
                value: attr.attribute_value,
            };
        }),
        customAttributes: customAttributes.map((attr: any) => {
            return {
                name: attr.attribute_name,
                value: attr.attribute_value,
            };
        }),
    };
};

export const createGroupedInvoiceItemsList = (
    items: IInvoiceItemsSummary[]
): IGroupedInvoiceItemsDict => {
    const itemsGroupDict: IGroupedInvoiceItemsDict = {};
    const totalInvoicedQtyForEachInvoiceItem: { [key: string]: number } = {};
    items.forEach((item) => {
        if (!totalInvoicedQtyForEachInvoiceItem[item.poItemUid]) {
            totalInvoicedQtyForEachInvoiceItem[item.poItemUid] =
                +item.invoicedBatchQuantity;
        } else {
            totalInvoicedQtyForEachInvoiceItem[item.poItemUid] +=
                +item.invoicedBatchQuantity;
        }
    });

    items.forEach((item, index) => {
        const tolerance = +item.allowedToleranceFromPO;
        if (!itemsGroupDict[item.poItemUid]) {
            const groupDetails: IGroupedInvoiceItemsSummary = {
                index,

                itemName: item.name,
                pendingQuantity: item.pendingQuantity,
                remainingQuantity: item.pendingQuantity,
                measurementUnit: item.measurementUnit,
                itemsList: [item],
                negativeTolerance: tolerance,
                positiveTolerance: 0,
                minusToleranceQty: toleranceQty(
                    tolerance,
                    totalInvoicedQtyForEachInvoiceItem[item.poItemUid],
                    ToleranceType.NEGATIVE
                ),
                plusToleranceQty:
                    totalInvoicedQtyForEachInvoiceItem[item.poItemUid],
                itemDetails: {
                    itemId: item.itemId,
                    itemName: item.name,
                    description: item.description,
                    additionalInfo: item.additionalInfo,
                    attributes: {
                        buyerAttributes: item.attributes.buyerAttributes,
                        customAttributes: item.attributes.customAttributes,
                    },
                    customIDs: item.customIDs,
                },
                expectedDeliverySchedule: [],
                isGRDone:
                    !isNull(item.grnUid) ||
                    item.itemStatus === ItemGRStatus.GOODS_RECEIVED,
                itemOnHold: item.itemOnHold,
                itemTermination: item.itemTermination,
                checked: false,
                checklist: [],
                poItemUid: item.poItemUid,
            };
            itemsGroupDict[item.poItemUid] = groupDetails;
        } else {
            itemsGroupDict[item.poItemUid].itemsList.push(item);
        }
    });

    return itemsGroupDict;
};

export const calculateGRItemsTotalQty = (
    itemsList: IInvoiceItemsSummary[]
): number => {
    return itemsList.reduce((acc, item) => {
        if (item.itemStatus !== ItemGRStatus.GOODS_RECEIVED) {
            let accepted: number = +item.grAcceptedQuantity;
            let rejected: number = +item.grRejectedQuantity;
            if (isEmpty(item.grAcceptedQuantity)) {
                accepted = 0;
                rejected = 0;
            }
            return acc + accepted + rejected;
        }
        return acc;
    }, 0);
};

export const calculateGRItemsTotalODQty = (
    itemsList: IInvoiceItemsSummary[]
): number => {
    return itemsList.reduce((acc, item) => {
        const addedNum = isEmpty(item.grOverDeliveredQuantity)
            ? 0
            : +item.grOverDeliveredQuantity;
        return acc + addedNum;
    }, 0);
};

export const calculateGRInvoicedQty = (
    itemsList: IInvoiceItemsSummary[]
): number => {
    return itemsList.reduce((acc, item) => {
        const addedNum =
            item.itemStatus !== ItemGRStatus.GOODS_RECEIVED
                ? +item.invoicedBatchQuantity
                : 0;
        return acc + addedNum;
    }, 0);
};

export const calculateGRTotalAcceptedQty = (
    itemsList: IInvoiceItemsSummary[]
): number => {
    return itemsList.reduce((acc, item) => {
        return acc + +item.grAcceptedQuantity;
    }, 0);
};

export const calculateGRTotalRejectedQty = (
    itemsList: IInvoiceItemsSummary[]
): number => {
    return itemsList.reduce((acc, item) => {
        return acc + +item.grRejectedQuantity;
    }, 0);
};

export const calculateQCTotalRejectedQty = (
    itemsList: IInvoiceItemsSummary[]
): number => {
    return itemsList.reduce((acc, item) => acc + +item.qcRejectionQuantity, 0);
};

// export const canChangeRejectedGRQty = (
//     group: IGroupedInvoiceItemsSummary
// ): boolean => {
//     const pendingQty = group.pendingQuantity;
//     const invoicedQty = calculateGRInvoicedQty(group.itemsList);

//     if (invoicedQty > pendingQty && group.itemsList.length > 1) return true;

//     return false;
// };

export const getDuplicateBatches = (
    itemsList: IInvoiceItemsSummary[]
): string[] => {
    const batches = itemsList.map((item) => item.batchNo);
    return batches.filter(
        (item, index) => batches.indexOf(item) !== index && !isEmpty(item)
    );
};
