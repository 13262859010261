import { Box, Dialog } from '@mui/material';
import React, { useState } from 'react';

const HelpSectionImage = ({ image }: { image: string | undefined }) => {
    const [open, setOpen] = useState(false);

    return image ? (
        <Box onClick={(e) => e.stopPropagation()}>
            <img
                style={{
                    // width: '100%',
                    height: '100%',
                    marginTop: '15px',
                    cursor: 'pointer',
                }}
                src={image}
                alt=""
                onClick={(e) => {
                    e.stopPropagation();
                    setOpen(true);
                }}
            />
            <Dialog
                open={open}
                onClose={(e) => {
                    setOpen(false);
                }}
                fullWidth
                maxWidth="xl"
            >
                <img src={image} alt="" />
            </Dialog>
        </Box>
    ) : null;
};

export default HelpSectionImage;
