import { PendingView } from '../Containers/PaymentsPendingListContainer';
import {
    IDefaultViewPaymentItem,
    IInvoiceViewPaymentItem,
    IPaymentsCompletedListSummary,
    IPaymentsPendingListSummary,
} from '../Interfaces/PaymentsInterface';
import {
    PaymentsDashboardType,
    IPaymentsDashboardState,
} from '../Slices/PaymentsDashboardSlice';

export interface IGRDashboardListActions {
    type: string;
    payload: {
        type: PaymentsDashboardType;
        value:
            | IPaymentsPendingListSummary[]
            | IPaymentsCompletedListSummary[]
            | IDefaultViewPaymentItem[]
            | IInvoiceViewPaymentItem[];
    };
}

export interface ITabsCountAction {
    payload: {
        type: PaymentsDashboardType;
        value: number;
    };
}

export interface ITabsChangeAction {
    payload: PaymentsDashboardType;
}
export interface ITabsViewChangeAction {
    payload: PendingView;
}

export const initDashboardListReducer = (
    state: IPaymentsDashboardState,
    action: IGRDashboardListActions
) => {
    switch (action.payload.type) {
        case PaymentsDashboardType.PENDING:
            state.pendingList = action.payload
                .value as IPaymentsPendingListSummary[];
            break;
        case PaymentsDashboardType.COMPLETED:
            state.completedList = action.payload
                .value as IPaymentsCompletedListSummary[];
            break;
    }
};

export const setTabCountReducer = (
    state: IPaymentsDashboardState,
    action: ITabsCountAction
) => {
    const tabsCount = state.tabsCount;
    switch (action.payload.type) {
        case PaymentsDashboardType.PENDING:
            tabsCount.pending = action.payload.value;
            break;
        case PaymentsDashboardType.COMPLETED:
            tabsCount.completed = action.payload.value;
            break;
    }
};

export const setSelectedTabReducer = (
    state: IPaymentsDashboardState,
    action: ITabsChangeAction
) => {
    state.selectedTab = action.payload;
};
