import { CircularProgress, IconButton } from '@mui/material';
import React from 'react';
import FWIcon from '../../../../Common/FWIcon';
import { FWInput } from '../../../../Common/FWInput';

const VLNRNotesEditTitle = ({
    oldTitle,
    onClickSave,
    onClose,
    isLoading,
    disableButton = false,
}: {
    oldTitle: string;
    onClickSave: (newTitle: string) => void;
    onClose: () => void;
    isLoading: boolean;
    disableButton?: boolean;
}) => {
    const [newTitle, setNewTitle] = React.useState(oldTitle);

    return (
        <div className={'flex flex--aic'}>
            <FWInput
                value={newTitle}
                onChange={(e) => setNewTitle(e.target.value)}
                disabled={isLoading}
            />

            {isLoading ? (
                <div className="ml--15">
                    <CircularProgress size={16} />
                </div>
            ) : (
                <>
                    <IconButton
                        color="error"
                        sx={{
                            ml: '10px',
                            height: '24px',
                            background: 'transparent',
                            padding: 0,
                        }}
                        disableRipple
                        onClick={onClose}
                    >
                        <FWIcon name="bi bi-x" size={20} />
                    </IconButton>
                    <IconButton
                        color="success"
                        sx={{
                            ml: '5px',
                            height: '24px',
                            background: 'transparent',
                            padding: 0,
                        }}
                        disableRipple
                        onClick={() => {
                            onClickSave(newTitle.trim());
                        }}
                        disabled={
                            newTitle === oldTitle ||
                            newTitle === '' ||
                            Boolean(disableButton)
                        }
                    >
                        <FWIcon name="bi bi-check2" size={20} />
                    </IconButton>
                </>
            )}
        </div>
    );
};

export default VLNRNotesEditTitle;
