import { cloneDeep, isNumber } from 'lodash';
import {
    AllocationTypeEnum,
    CostTypeEnum,
} from '../../AdditionalCost/models/AdditionalCost.model';
import { calculateAdditionalCostForFormulaCustomFields } from '../../AdditionalCost/shared/AdditionalCostAutofill';
import { IGetFormulaResponse } from '../../Formula/Models/formula.model';
import { ICostingCalculatorApiResponse } from '../Services/CostingApisCalls.service';
import {
    calculateFormulaAdditionalCostForQuoteItemAdditionalCost,
    combineMultipleQuoteSheetItemsWithoutCalcultion,
    getCostPerUnitOfACOQFromUpdatedItem,
} from './QuoteHelperFunctions';

export const updateAllCostingSheetItem = (
    allCostingSheetItems: ICostingCalculatorApiResponse[],
    updatedItemForReference: ICostingCalculatorApiResponse,
    currencyCodeAbbreviation: string,
    listOfFormulaForCustomFields: IGetFormulaResponse[],
    updatedItemIdx: number
) => {
    const combinedItemQuantity =
        allCostingSheetItems
            .filter((item, idx) => idx !== updatedItemIdx)
            .reduce((acc, curr) => acc + +curr.quantity, 0) +
        +updatedItemForReference.quantity;

    const combinedAdditionalCostValue =
        calculateFormulaAdditionalCostForQuoteItemAdditionalCost(
            updatedItemForReference.additional_costs,
            combinedItemQuantity.toString(),
            updatedItemForReference.rate,
            currencyCodeAbbreviation,
            updatedItemForReference.custom_sections,
            updatedItemForReference.enterprise_item_details.code
        );

    const costPerUnitMap = getCostPerUnitOfACOQFromUpdatedItem(
        combinedAdditionalCostValue,
        +combinedItemQuantity
    );

    for (let item of allCostingSheetItems) {
        const additionalCostForThisItem =
            updatedItemForReference.additional_costs.map((cost) => {
                if (
                    cost.cost_type === CostTypeEnum.ABSOLUTE_VALUE &&
                    cost.allocation_type === AllocationTypeEnum.OVERALL_QUANTITY
                ) {
                    let newCost = cloneDeep(cost);
                    const updatedValue =
                        costPerUnitMap[newCost.cost_name] * +item.quantity;

                    newCost.cost_value =
                        isNumber(updatedValue) && !isNaN(updatedValue)
                            ? updatedValue
                            : newCost.cost_value;

                    return newCost;
                }

                return cost;
            });

        // fields that should be update dbased on the updated item value.
        item.custom_sections = updatedItemForReference.custom_sections;
        item.custom_fields = updatedItemForReference.custom_fields;
        item.custom_fields_negotiate =
            updatedItemForReference.custom_fields_negotiate;
        item.notes = updatedItemForReference.notes;
        item.procurement_information =
            updatedItemForReference.procurement_information;
        item.additional_costs = additionalCostForThisItem;
        item.rate = updatedItemForReference.rate;
        item.vendor_rate = updatedItemForReference.vendor_rate;

        let additionalCostValuePostFormulaCalculation =
            calculateFormulaAdditionalCostForQuoteItemAdditionalCost(
                additionalCostForThisItem,
                combinedItemQuantity.toString(),
                item.rate?.toString(),
                currencyCodeAbbreviation,
                item.custom_sections,
                updatedItemForReference.enterprise_item_details.code
            );

        // updating additional cost value after formula calculation
        item.additional_costs = additionalCostValuePostFormulaCalculation.map(
            (cost) => {
                if (isNaN(+cost.cost_value)) {
                    let newCost = cloneDeep(cost);
                    newCost.cost_value = null as any;
                }

                return cost;
            }
        );

        const customFieldPostFormulaCalculation =
            calculateAdditionalCostForFormulaCustomFields({
                item: { ...item, quantity: combinedItemQuantity.toString() },
                listOfFormulaForCustomFields: listOfFormulaForCustomFields,
            });

        // updating custom fields value after formula calculation

        customFieldPostFormulaCalculation.additional_costs.map((cost) => {
            if (
                cost.cost_type === CostTypeEnum.ABSOLUTE_VALUE &&
                cost.allocation_type === AllocationTypeEnum.OVERALL_QUANTITY &&
                cost.formula !== null
            ) {
                if (isNaN(+cost.cost_value)) {
                    return cost;
                }
                let newCost = (
                    (+cost.cost_value / +combinedItemQuantity) *
                    +item.quantity
                ).toFixed(10);

                cost.cost_value = newCost;

                return cost;
            }

            return cost;
        });
        item.custom_fields = customFieldPostFormulaCalculation.custom_fields;
        item.custom_sections =
            customFieldPostFormulaCalculation.custom_sections;
        item.additional_costs =
            customFieldPostFormulaCalculation.additional_costs;
    }

    return allCostingSheetItems;
};

export const convertICostingCalculatorApiResponseListMappingToICostingCalculatorApiResponseMapping =
    (currentQuoteItemList: {
        [quoteItemId: string]: ICostingCalculatorApiResponse[];
    }) => {
        const combinedItemMap: {
            [quoteItemId: string]: ICostingCalculatorApiResponse;
        } = {};

        for (let itemId of Object.keys(currentQuoteItemList)) {
            const currentListofItems = currentQuoteItemList[itemId];

            const combinedItem =
                combineMultipleQuoteSheetItemsWithoutCalcultion(
                    currentListofItems
                );

            combinedItemMap[itemId] = combinedItem.itemForCalculation;
        }

        return combinedItemMap;
    };
