import { cloneDeep } from 'lodash';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { validate } from 'uuid';
import { AuthContext } from '../../../Contexts/AuthContext';
import useQuery from '../../../Events/Components/Shared/QueryHook';
import { IUserDetails } from '../../../Models/UserDetails.model';
import { UserDefaultsActions } from '../../../Redux/Reducers/UserDefaultsReducer';
import { IVerificationState } from '../../../Redux/Slices/VerificationSlice';
import { updateUserDefaults } from '../../../Redux/Slices/userDefaultsSlice';
import {
    IUserManagerDetails,
    IUserProfileError,
    IUserProfileKeys,
} from '../Interfaces/UserProfile.model';
import { validateUserProfileDetails } from '../helper/helperFunction';
import {
    getCurrentUsersManager,
    postNewUserDetails,
    updatePasswordService,
} from '../services/userProfile.service';

export default function useUserProfile(
    user_id: string,
    pageAfterSave: 'HOME_PAGE' | 'PREVIOUS_PAGE'
) {
    const { authData } = useContext(AuthContext);
    const { pathname } = useLocation();
    const query = useQuery();

    const { invitationCode, invitationUid } = useMemo(() => {
        const invitationUidFromQuery = query.get('invitationUid');
        const invitationCodeFromQuery = query.get('invitationCode');

        if (pathname.includes('verify-email')) {
            return {
                invitationUid:
                    pathname.split('/')[pathname.split('/').length - 2],
                invitationCode:
                    pathname.split('/')[pathname.split('/').length - 1],
            };
        } else if (
            validate(invitationUidFromQuery ?? '') &&
            invitationCodeFromQuery
        ) {
            return {
                invitationUid: invitationUidFromQuery,
                invitationCode: invitationCodeFromQuery,
            };
        }

        return {
            invitationUid: undefined,
            invitationCode: undefined,
        };
    }, [pathname, query]);

    const [selectableEntities, setSelectableEntities] = React.useState<{
        [key: string]: string;
    }>({});
    const [selectableHomePages, setSelectableHomePages] = React.useState<{
        [key: string]: string;
    }>({});
    const [userDetailsState, setUserDetailsState] =
        React.useState<IUserDetails | null>(null);
    const [errors, setErrors] = React.useState<IUserProfileError>({});
    const [loading, setLoading] = React.useState(false);
    const [managersList, setManagersList] = React.useState<
        IUserManagerDetails[]
    >([]);

    const { allUserEntities } = useSelector(
        ({ VerificationStore }: { VerificationStore: IVerificationState }) =>
            VerificationStore
    );

    const updateUserDetails = React.useCallback(
        (field: IUserProfileKeys, value: any) => {
            setUserDetailsState((prev) => {
                if (prev !== null) {
                    let newDetails: any = cloneDeep(prev);
                    let field_keys = field.split('_');
                    if (field === 'default_entity') {
                        newDetails.defaults.primary_entity_id = value;
                    } else if (field === 'default_homepage') {
                        newDetails.defaults.homepage = value;
                    } else if (field === 'default_number_format') {
                        newDetails.defaults.number_format = value;
                    } else if (
                        field_keys.length === 2 &&
                        field_keys[0] === 'phone'
                    ) {
                        newDetails.contacts.phone_numbers[
                            +field_keys[1]
                        ].phone = value;
                    } else if (
                        field_keys.length === 3 &&
                        field_keys[0] === 'phone' &&
                        field_keys[2] === 'visible'
                    ) {
                        newDetails.contacts.phone_numbers[
                            +field_keys[1]
                        ].is_public =
                            !newDetails.contacts.phone_numbers[+field_keys[1]]
                                .is_public;
                    } else if (
                        field_keys.length === 3 &&
                        field_keys[0] === 'phone' &&
                        field_keys[2] === 'delete'
                    ) {
                        newDetails.contacts.phone_numbers.splice(
                            +field_keys[1],
                            1
                        );
                    } else if (
                        field_keys.length === 2 &&
                        field_keys[0] === 'email'
                    ) {
                        newDetails.contacts.emails[+field_keys[1]].email =
                            value;
                    } else if (
                        field_keys.length === 3 &&
                        field_keys[0] === 'email' &&
                        field_keys[2] === 'visible'
                    ) {
                        newDetails.contacts.emails[+field_keys[1]].is_public =
                            !newDetails.contacts.emails[+field_keys[1]]
                                .is_public;
                    } else if (
                        field_keys.length === 3 &&
                        field_keys[0] === 'email' &&
                        field_keys[2] === 'delete'
                    ) {
                        newDetails.contacts.emails.splice(+field_keys[1], 1);
                    } else {
                        newDetails[field] = value;
                    }
                    setErrors((prev) =>
                        validateUserProfileDetails(field, newDetails, prev)
                    );
                    return newDetails;
                } else {
                    return prev;
                }
            });
        },
        []
    );

    const getSelectableHomepages = React.useCallback(() => {
        let _selectableHomePages: { [key: string]: string } = {};
        if (authData.modulePermissions.BUYER !== null) {
            if (authData.modulePermissions.BUYER.EVENT !== null) {
                _selectableHomePages['/buyer/events/'] = 'Buyer events';
            }
            if (authData.modulePermissions.BUYER.PURCHASE_ORDER !== null) {
                _selectableHomePages['/buyer/purchase_orders/'] =
                    'Buyer purchase orders';
            }
            if (authData.modulePermissions.BUYER.INVOICE !== null) {
                _selectableHomePages['/buyer/invoices/'] = 'Buyer invoices';
            }
            if (authData.modulePermissions.BUYER.GOODS_RECEIPT !== null) {
                _selectableHomePages['/buyer/goods-receipt/'] =
                    'Buyer goods receipt';
            }
            if (authData.modulePermissions.BUYER.QUALITY_CHECK !== null) {
                _selectableHomePages['/buyer/quality-check/'] =
                    'Buyer quality check';
            }
            if (authData.modulePermissions.BUYER.PAYMENT !== null) {
                _selectableHomePages['/buyer/payments/'] = 'Buyer payments';
            }
            if (
                authData.modulePermissions.BUYER
                    .SUPPLIER_RELATIONSHIP_MANAGEMENT !== null
            ) {
                _selectableHomePages['/buyer/srm/'] =
                    'Supplier relationship management';
            }
            if (authData.modulePermissions.BUYER.CONTRACT !== null) {
                _selectableHomePages['/buyer/clm/'] =
                    'Contract lifecycle management';
            }
        }
        if (authData.modulePermissions.SELLER !== null) {
            if (authData.modulePermissions.SELLER.SELLER !== null)
                _selectableHomePages['/seller/events/'] = 'Seller events';

            if (authData.modulePermissions.SELLER.QUOTE_CALCULATOR !== null)
                _selectableHomePages['/seller/costing/'] = 'Quote calculator';

            if (
                authData.modulePermissions.SELLER
                    .SUPPLIER_RELATIONSHIP_MANAGEMENT_SELLER !== null
            )
                _selectableHomePages['/seller/profile/'] = 'Seller profile';
        }
        if (authData.modulePermissions.CUSTOMIZE !== null) {
            if (authData.modulePermissions.CUSTOMIZE.PROJECT !== null)
                _selectableHomePages['/custom/cost-tracking/projects/'] =
                    'Project';
        }
        if (authData.modulePermissions.CUSTOMIZE !== null) {
            if (authData.modulePermissions.CUSTOMIZE.PROJECT !== null)
                _selectableHomePages['/custom/cost-tracking/projects/'] =
                    'Project';
        }

        if (authData.modulePermissions.GLOBAL_ADMIN) {
            _selectableHomePages['/admin/home/'] = 'Admin home page';
        }
        return _selectableHomePages;
    }, [authData.modulePermissions]);

    const getSelectableEntities = React.useCallback(() => {
        let _selectableEntities: { [key: string]: string } = {};
        for (let entity of allUserEntities) {
            _selectableEntities[entity.entityId] = entity.entityName;
        }
        return _selectableEntities;
    }, [allUserEntities]);

    React.useEffect(() => {
        let _user_details = cloneDeep(authData.details);
        let _primaryEntityOptions: { [key: string]: string } =
            getSelectableEntities();

        let _homepageOptions = getSelectableHomepages();
        getCurrentUsersManager(user_id).then((res) => {
            setManagersList(res);
        });
        if (
            _user_details !== null &&
            (_user_details.contacts === null ||
                Object.keys(_user_details.contacts).length === 0)
        ) {
            _user_details.contacts = {
                emails: [],
                phone_numbers: [],
            };
        }
        /*
        TODO: Creating empty phone number if not present. Temporary. 
        We currently want to allow only one phone number, so the plus button is also being removed.
        */
        if (
            _user_details &&
            _user_details.contacts &&
            _user_details.contacts.phone_numbers.length === 0
        ) {
            _user_details.contacts.phone_numbers.push({
                phone: '',
                is_public: false,
            });
        }

        //Only allowing 1 phone number for now:
        if (
            _user_details &&
            _user_details.contacts &&
            _user_details.contacts.phone_numbers.length > 1
        ) {
            _user_details.contacts.phone_numbers =
                _user_details.contacts.phone_numbers.slice(0, 1);
        }

        // Object.keys(_homepageOptions).length > 0
        // ? _user_details.invitation_type ===
        //       'VENDOR_ENTERPRISE_INVITE' ||
        //   authData.modulePermissions.BUYER === null
        //     ? '/seller/events/'
        //     : _user_details.invitation_type === undefined &&
        //       authData.modulePermissions.BUYER !== null
        //     ? '/buyer/events/'
        //     : '/custom/cost-tracking/projects/'
        // : ''
        // - Buyer Events
        // - Buyer purchase orders
        // - Seller events
        // - Quote calculator
        // - Project
        // - SRM
        // - CLM
        // - Seller Profile
        // - ADMIN
        if (
            _user_details !== null &&
            (_user_details.defaults === null ||
                Object.keys(_user_details.defaults).length === 0)
        ) {
            let defaultHome = '';

            if (
                _user_details.invitation_type === 'VENDOR_ENTERPRISE_INVITE' &&
                authData.modulePermissions.SELLER !== null &&
                authData.modulePermissions.SELLER !== undefined
            ) {
                defaultHome = '/seller/events/';
            } else if (
                authData.modulePermissions.BUYER?.EVENT !== null &&
                authData.modulePermissions.BUYER?.EVENT !== undefined
            ) {
                defaultHome = '/buyer/events/';
            } else if (
                authData.modulePermissions.BUYER?.PURCHASE_ORDER !== null &&
                authData.modulePermissions.BUYER?.PURCHASE_ORDER !== undefined
            ) {
                defaultHome = '/buyer/purchase_orders/';
            } else if (
                authData.modulePermissions.SELLER?.SELLER !== null &&
                authData.modulePermissions.SELLER?.SELLER !== undefined
            ) {
                defaultHome = '/seller/events/';
            } else if (
                authData.modulePermissions.SELLER?.QUOTE_CALCULATOR !== null &&
                authData.modulePermissions.SELLER?.QUOTE_CALCULATOR !==
                    undefined
            ) {
                defaultHome = '/seller/costing/';
            } else if (
                authData.modulePermissions.CUSTOMIZE?.PROJECT !== null &&
                authData.modulePermissions.CUSTOMIZE?.PROJECT !== undefined
            ) {
                defaultHome = '/custom/cost-tracking/projects/';
            } else if (
                authData.modulePermissions.BUYER
                    ?.SUPPLIER_RELATIONSHIP_MANAGEMENT !== null &&
                authData.modulePermissions.BUYER
                    ?.SUPPLIER_RELATIONSHIP_MANAGEMENT !== undefined
            ) {
                defaultHome = '/buyer/srm/';
            } else if (
                authData.modulePermissions.BUYER?.CONTRACT !== null &&
                authData.modulePermissions.BUYER?.CONTRACT !== undefined
            ) {
                defaultHome = '/buyer/clm/';
            } else if (
                authData.modulePermissions.SELLER?.SELLER !== null &&
                authData.modulePermissions.SELLER?.SELLER !== undefined
            ) {
                defaultHome = '/seller/profile/';
            } else if (
                authData.modulePermissions.GLOBAL_ADMIN !== null &&
                authData.modulePermissions.GLOBAL_ADMIN !== undefined
            ) {
                defaultHome = '/admin/home/';
            }

            _user_details.defaults = {
                homepage: defaultHome,
                timezone: '',
                number_format: 'USD',
                primary_entity_id:
                    Object.keys(_primaryEntityOptions).length > 0
                        ? Object.keys(_primaryEntityOptions)[0]
                        : '',
            };
        }
        setSelectableHomePages(_homepageOptions);
        setSelectableEntities(_primaryEntityOptions);
        setUserDetailsState(_user_details);
    }, [authData, getSelectableEntities, getSelectableHomepages, user_id]);

    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        if (query.get('type') === 'DOCUMENT') {
            const docuemnt_id = query.get('docuemnt_id');
            const entity_id = query.get('entity_id');

            if (docuemnt_id && entity_id && invitationUid && invitationCode) {
                localStorage.setItem(
                    `${invitationUid};${invitationCode};DOCUMENT`,
                    JSON.stringify({
                        entity_id: entity_id,
                        document_id: docuemnt_id,
                    })
                );
            }
        }
    }, [invitationCode, invitationUid, query]);

    // useEffect(() => {
    //     let documentInfoFromLocalStoarte = localStorage.getItem(
    //         `${invitationUid};${invitationCode};DOCUMENT`
    //     );
    //     let documentInfoFromLocalStorage: any =
    //         documentInfoFromLocalStoarte === null
    //             ? {}
    //             : JSON.parse(documentInfoFromLocalStoarte);
    // }, [invitationCode, invitationUid]);

    const location = useLocation();
    const saveDetails = () => {
        if (userDetailsState !== null) {
            setLoading(true);

            // TODO: Removing blank phone numbers. Temporary. Always showing just 1 phone number field, allowing user to leave it blank.
            let userDetailsStateWithoutBlankContactNumber =
                cloneDeep(userDetailsState);
            if (userDetailsStateWithoutBlankContactNumber.contacts) {
                userDetailsStateWithoutBlankContactNumber.contacts.phone_numbers =
                    userDetailsStateWithoutBlankContactNumber.contacts.phone_numbers.filter(
                        (phone_number) => phone_number.phone.trim() !== ''
                    );
            }
            postNewUserDetails(userDetailsStateWithoutBlankContactNumber)
                .then((res) => {
                    toast.success('Details saved successfully');
                    if (userDetailsState.defaults !== null) {
                        dispatch(
                            updateUserDefaults({
                                type: UserDefaultsActions.UPDATE_USER_NUMBER_FORMAT,
                                value: userDetailsState.defaults.number_format,
                            })
                        );
                    }

                    if (pageAfterSave === 'PREVIOUS_PAGE') {
                        //
                        history.goBack();
                        // window.location.reload();
                    } else {
                        //

                        let documentInfoFromLocalStoarte = localStorage.getItem(
                            `${invitationUid};${invitationCode};DOCUMENT`
                        );
                        let documentInfoFromLocalStorage: any =
                            documentInfoFromLocalStoarte === null
                                ? {}
                                : JSON.parse(documentInfoFromLocalStoarte);

                        const typeQueryParameters = query.get('type');

                        if (
                            (pathname.includes('verify-email') ||
                                (typeQueryParameters?.length ?? 0) > 0) &&
                            invitationUid &&
                            invitationCode &&
                            'entity_id' in documentInfoFromLocalStorage &&
                            'document_id' in documentInfoFromLocalStorage
                        ) {
                            // history.push(
                            //     `/seller/document/${documentInfoFromLocalStorage.entity_id}/${documentInfoFromLocalStorage.document_id}/`
                            // );
                            history.push(`/seller/profile/documents/`);
                        } else if (
                            !location.pathname.includes('/seller/document')
                        ) {
                            history.push('/');
                        }
                        // window.location.reload();
                    }
                    setTimeout(() => {
                        window.location.reload();
                    }, 100);
                })
                .catch((err) => {
                    console.error(err);
                    setLoading(false);
                    toast.error("Couldn't save details");
                });
        }
    };

    // update password

    const [updatePassowrdData, setUpdatePasswordData] = useState<{
        oldPassword: string;
        newPassword: string;
        confirmPassword: string;
    }>({
        oldPassword: '',
        confirmPassword: '',
        newPassword: '',
    });

    const [updatePasswordErrors, setUpdatePasswordErrors] = useState<{
        oldPassword?: string;
        newPassword?: string;
        confirmPassword?: string;
    }>({});

    const updatePassword = async (access_token: string) => {
        try {
            await updatePasswordService({
                new_password: updatePassowrdData.newPassword,
                old_password: updatePassowrdData.oldPassword,
                access_token,
            });
            toast.success('Password updated successfully');
        } catch (error: any) {
            if (
                error.response.data.ErrorCode ===
                'An error occurred (NotAuthorizedException) when calling the ChangePassword operation: Incorrect username or password.'
            ) {
                toast.error('Incorrect old password');
            } else if (
                error.response.data.ErrorCode ===
                'An error occurred (LimitExceededException) when calling the ChangePassword operation: Attempt limit exceeded, please try after some time.'
            ) {
                toast.error(
                    'Attempt limit exceeded, please try after some time.'
                );
            }
        }
    };

    const passwordUpdateChangeHandler = (e: any) => {
        setUpdatePasswordData({
            ...updatePassowrdData,
            [e.target.name]: e.target.value,
        });

        let passwordRegex = new RegExp(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@^_`{|}~])[a-zA-Z\d@$!%*?&]{8,100}$/
        );

        const newUpdatePasswordErrors = { ...updatePasswordErrors };
        if (e.target.name === 'oldPassword') {
            if (e.target.value?.trim() === '') {
                newUpdatePasswordErrors.oldPassword = 'Field is required';
            } else {
                delete newUpdatePasswordErrors.oldPassword;
            }
        } else if (e.target.name === 'newPassword') {
            if (e.target.value?.trim() === '') {
                newUpdatePasswordErrors.newPassword = 'Field is required';
            } else if (!e.target.value.match(passwordRegex)) {
                newUpdatePasswordErrors.newPassword = 'Field is required';
            } else {
                delete newUpdatePasswordErrors.newPassword;
            }

            if (e.target.value?.trim() !== updatePassowrdData.confirmPassword) {
                newUpdatePasswordErrors.confirmPassword =
                    'Passwords do not match';
            }
        } else if (e.target.name === 'confirmPassword') {
            if (e.target.value?.trim() === '') {
                newUpdatePasswordErrors.confirmPassword = 'Field is required';
            }
            if (e.target.value?.trim() !== updatePassowrdData.newPassword) {
                newUpdatePasswordErrors.confirmPassword =
                    'Passwords do not match';
            } else {
                delete newUpdatePasswordErrors.confirmPassword;
            }
        }
        setUpdatePasswordErrors(newUpdatePasswordErrors);
    };

    return {
        selectableEntities,
        selectableHomePages,
        userDetailsState,
        errors,
        updateUserDetails,
        saveDetails,
        loading,
        managersList,
        updatePassowrdData,
        passwordUpdateChangeHandler,
        updatePasswordErrors,
        updatePassword,
    };
}
