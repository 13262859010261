import { DialogActions, DialogContent, Grid } from '@mui/material';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { FWButton } from '../../../../Common/FWButton';
import { FWMenuItem } from '../../../../Common/FWCustomMenu';
import { FWInput } from '../../../../Common/FWInput';
import { FWPopupWithChildren } from '../../../../Common/FWPopupWithChildren';
import FWSelect from '../../../../Common/FWSelect';
import { FWTypography } from '../../../../Common/FWTypograhy';
import LoadingBackDrop from '../../../../Components/Shared/LoadingBackdrop';
import { useMeasurementUnits } from '../../Hooks/ItemDirectory/MeasurementUnitsHook';
import { IMeasurement } from '../../Interfaces/ItemDirectoryInterface';
import { saveNewMeasurementUnit } from '../../Services/ItemDirectoryService';

interface IMeasurementUnitContainer {
    open: boolean;
    handleClose: () => void;
    onSuccess: (addedMeasurementUnit: IMeasurement) => void;
}

export default function AddMeasurementUnitContainer(
    props: IMeasurementUnitContainer
) {
    const { measurementUnitNameMapping } = useMeasurementUnits();

    const [measurementUnitName, setMeasurementUnitName] = useState<string>('');

    const [measurementUnitCategory, setMeasurementUnitCategory] =
        useState<string>('WEIGHT');

    const [measurementUnitValueType, setMeasurementUnitValueType] = useState<
        'INTEGER' | 'DECIMAL'
    >('DECIMAL');

    const [errorType, setErrorType] = useState<
        'BLANK_NAME' | 'LONG_NAME' | 'DUPLICATE' | 'NONE'
    >('BLANK_NAME');

    const [suppressError, setSuppressError] = useState<boolean>(true);

    const [loading, setLoading] = useState<boolean>(false);

    const [duplicatedUnitPrimaryName, setDuplicatedUnitPrimaryName] =
        useState<string>('');

    const onSubmit = async () => {
        try {
            setLoading(true);
            let addedUnit = await saveNewMeasurementUnit(
                measurementUnitName,
                measurementUnitCategory,
                measurementUnitValueType
            );
            props.onSuccess({
                id: addedUnit.measurementUnitUuid,
                name: measurementUnitName,
                category: measurementUnitCategory,
                type: measurementUnitValueType,
                isVerifiedUnit: false,
                abbreviation: '',
            });
            resetAndClose();
        } catch (e) {
            toast.error('Failed to add measurement unit');
        } finally {
            setLoading(false);
        }
    };

    const resetAndClose = () => {
        props.handleClose();
        setMeasurementUnitName('');
        setMeasurementUnitCategory('WEIGHT');
        setErrorType('BLANK_NAME');
        setSuppressError(true);
    };

    const calculateError = (name: string) => {
        if (name.trim().length === 0) {
            setErrorType('BLANK_NAME');
        } else if (name.trim().length > 30) {
            setErrorType('LONG_NAME');
        } else if (
            Object.keys(measurementUnitNameMapping).includes(
                name.trim().toLowerCase()
            )
        ) {
            setErrorType('DUPLICATE');
            setDuplicatedUnitPrimaryName(
                measurementUnitNameMapping[name.trim().toLowerCase()]
                    .measurementUnitPrimaryName
            );
        } else {
            setErrorType('NONE');
        }
    };

    const errorHelperText = suppressError
        ? ''
        : errorType === 'BLANK_NAME'
        ? 'Name input required'
        : errorType === 'LONG_NAME'
        ? 'Name cannot exceed 30 characters'
        : errorType === 'DUPLICATE'
        ? duplicatedUnitPrimaryName.length > 0
            ? `Unit "${duplicatedUnitPrimaryName}" already exists`
            : 'Unit already exists'
        : '';

    return (
        <>
            {loading && <LoadingBackDrop />}
            <FWPopupWithChildren
                title="New Measurement Unit"
                open={props.open}
                handleClose={resetAndClose}
                size={'medium'}
            >
                <DialogContent>
                    <Grid container rowSpacing={2}>
                        <Grid item xs={12}>
                            <FWInput
                                label="Measurement Unit Name"
                                value={measurementUnitName}
                                onChange={(e) => {
                                    calculateError(e.target.value);
                                    setMeasurementUnitName(e.target.value);
                                    setSuppressError(false);
                                }}
                                error={errorType !== 'NONE' && !suppressError}
                                helper={{
                                    text: errorHelperText,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FWTypography color="text.secondary">
                                Measurement Unit Category
                            </FWTypography>
                            <FWSelect value={measurementUnitCategory} fullWidth>
                                {measurementUnitCategories.map((category) => (
                                    <FWMenuItem
                                        value={category.apiName}
                                        onClick={() =>
                                            setMeasurementUnitCategory(
                                                category.apiName
                                            )
                                        }
                                    >
                                        {category.displayName}
                                    </FWMenuItem>
                                ))}
                            </FWSelect>
                        </Grid>
                        <Grid item xs={12}>
                            <FWTypography color="text.secondary">
                                Value type
                            </FWTypography>
                            <FWSelect
                                value={measurementUnitValueType}
                                fullWidth
                            >
                                <FWMenuItem
                                    value={'DECIMAL'}
                                    onClick={() =>
                                        setMeasurementUnitValueType('DECIMAL')
                                    }
                                >
                                    Decimal
                                </FWMenuItem>
                                <FWMenuItem
                                    value={'INTEGER'}
                                    onClick={() =>
                                        setMeasurementUnitValueType('INTEGER')
                                    }
                                >
                                    Integer
                                </FWMenuItem>
                            </FWSelect>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions sx={{ margin: '12px' }}>
                    <FWButton variant="outlined" onClick={resetAndClose}>
                        Cancel
                    </FWButton>
                    <FWButton
                        variant="contained"
                        disabled={errorType !== 'NONE'}
                        onClick={onSubmit}
                    >
                        Save
                    </FWButton>
                </DialogActions>
            </FWPopupWithChildren>
        </>
    );
}

export const measurementUnitCategories = [
    {
        displayName: 'Weight',
        apiName: 'WEIGHT',
    },
    {
        displayName: 'Units',
        apiName: 'UNITS',
    },
    {
        displayName: 'Volume',
        apiName: 'VOLUME',
    },
    {
        displayName: 'Area',
        apiName: 'AREA',
    },
    {
        displayName: 'Length',
        apiName: 'LENGTH',
    },
    {
        displayName: 'Other',
        apiName: 'OTHER',
    },
];
