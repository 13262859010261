import { Box, IconButton, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FWPopupWithChildren } from '../../Common/FWPopupWithChildren';
import { SetupWizardActions } from '../../Redux/Reducers/SetupWizardReducers';
import { updateSetupWizardDetails } from '../../Redux/Slices/SetupWizardSlice';
import { IVerificationState } from '../../Redux/Slices/VerificationSlice';
import { IGlobalAppStore } from '../../Redux/Store';
import { OnboardingVideoPopupType } from '../Interfaces/OnboardingActionTypes';

const OnboardingVideoPopup = ({
    setOnBoardingOpen,
    setVideoPopupOpen,
    videoPopupOpen,
}: {
    setOnBoardingOpen: React.Dispatch<React.SetStateAction<boolean>>;
    videoPopupOpen: OnboardingVideoPopupType | null;
    setVideoPopupOpen: React.Dispatch<
        React.SetStateAction<OnboardingVideoPopupType | null>
    >;
}) => {
    const dispatch = useDispatch();
    const { allUserEntities }: IVerificationState = useSelector(
        ({ VerificationStore }: IGlobalAppStore) => VerificationStore
    );

    const setupPendingEntities = useMemo(() => {
        return allUserEntities.filter((e) => e.setupPending);
    }, [allUserEntities]);

    const closePopup = () => {
        if (
            videoPopupOpen === 'ADMIN_SETUP' &&
            setupPendingEntities.length > 0
        ) {
            dispatch(
                updateSetupWizardDetails({
                    type: SetupWizardActions.UPDATE_ENTITY,
                    value: setupPendingEntities[0].entityId,
                })
            );
        }
        setVideoPopupOpen(null);
    };

    const getPopupTitle = () => {
        switch (videoPopupOpen) {
            case 'ADMIN_SETUP':
                return 'Admin Setup';
            case 'CREATE_EVENT':
                return 'Create Event';
            case 'CREATE_PO':
                return 'Create Purchase Order';
            default:
                return '';
        }
    };

    const getPopupVideoUrl = () => {
        switch (videoPopupOpen) {
            case 'ADMIN_SETUP':
                return 'https://res.cloudinary.com/desto5ycc/video/upload/v1676978541/Onboarding%20videos/Factwise_welcome_admin.mp4';
            case 'CREATE_EVENT':
                return 'https://res.cloudinary.com/desto5ycc/video/upload/v1676977848/Onboarding%20videos/Factwise_welcome_event.mp4';
            case 'CREATE_PO':
                return 'https://res.cloudinary.com/desto5ycc/video/upload/v1676977850/Onboarding%20videos/Factwise_welcome_d2po.mp4';
            default:
                return '';
        }
    };

    const popupHeaderElement = (
        <div
            style={{
                display: 'flex',
                justifyContent: 'space-between',
                padding: '0px 10px',
                alignItems: 'center',
                height: '36px',
            }}
        >
            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <IconButton
                    size="small"
                    onClick={(e) => {
                        e.stopPropagation();
                        closePopup();
                        setOnBoardingOpen(true);
                    }}
                >
                    <i
                        className="bi bi-arrow-left-short"
                        style={{ fontSize: '2rem' }}
                    />
                </IconButton>
                <Typography variant="h4" fontSize="1rem">
                    {getPopupTitle()}
                </Typography>
                <IconButton
                    size="small"
                    onClick={(e) => {
                        e.stopPropagation();
                        closePopup();
                    }}
                >
                    <i className="bi bi-x" style={{ fontSize: '2rem' }} />
                </IconButton>
            </div>
        </div>
    );

    return (
        <FWPopupWithChildren
            title=""
            open={videoPopupOpen !== null}
            handleClose={closePopup}
            size={'custom'}
            popupWidth={'40%'}
            customHeaderElement={popupHeaderElement}
            sx={{ zIndex: 1000 }}
        >
            <Box display="contents">
                <video
                    src={getPopupVideoUrl()}
                    controls
                    disablePictureInPicture
                    controlsList="nodownload noplaybackrate"
                    // thumbnail url will come here
                ></video>
            </Box>
        </FWPopupWithChildren>
    );
};

export default OnboardingVideoPopup;
