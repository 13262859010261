import { isEmpty } from 'lodash';
import { useState, useCallback, useEffect } from 'react';
import { IDeliveryScheduleList } from '../FWDeliverySchedulePopup';
import {
    fetchItemDeliverySchedule,
    fetchPODeliverySchedule,
} from '../Services/DeliverySecheduleService';
import { useHookState, HookStateValue } from './StateHook';

export const useDeliverySchedule = (uidInfo: {
    id: string;
    type: 'MULTIPLE' | 'SINGLE';
}) => {
    const { hookState, updateHookState } = useHookState(HookStateValue.LOADING);
    const [schedule, setSchedule] = useState<IDeliveryScheduleList[]>([]);

    const getSchedule = useCallback(async () => {
        updateHookState(HookStateValue.LOADING);
        if (!isEmpty(uidInfo.id)) {
            try {
                let schedule: IDeliveryScheduleList[] = [];
                if (uidInfo.type === 'MULTIPLE') {
                    schedule = await fetchPODeliverySchedule(uidInfo.id);
                }
                if (uidInfo.type === 'SINGLE') {
                    schedule = await fetchItemDeliverySchedule(
                        uidInfo.id,
                        'BUYER'
                    );
                }
                setSchedule(schedule);
                updateHookState(HookStateValue.READY);
            } catch (error) {
                updateHookState(HookStateValue.ERROR);
            }
        } else {
            setSchedule([]);
            updateHookState(HookStateValue.LOADING);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uidInfo]);

    useEffect(() => {
        getSchedule();

        return () => {
            updateHookState(HookStateValue.LOADING);
            setSchedule([]);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getSchedule]);

    return {
        hookState,
        schedule,
    };
};
