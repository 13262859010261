import { Badge, Box, IconButton } from '@mui/material';
import React from 'react';
import { FWTooltip } from '../../Common/FWCustomTooltip';
import FWIcon from '../../Common/FWIcon';
import FWPopover from '../../Common/FWPopover';
import { FWTypography } from '../../Common/FWTypograhy';
import UploadedFilesList from '../../Components/Shared/UploadedFilesList';
import { IAttachment } from '../../Models/RFQ.model';

export default function ViewAttachmentsButtonAndPopover({
    attachments,
    clipIconSize = 16,
    hideBadge = false,
    id,
}: {
    attachments: IAttachment[];
    clipIconSize?: number;
    hideBadge?: boolean;
    id?: string;
}) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handlePopoverOpen = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    return (
        <>
            <FWTooltip title="View Attachments">
                <Box>
                    {attachments.length > 0 && (
                        <IconButton
                            id={id}
                            size="small"
                            sx={{ fontSize: '1rem' }}
                            color="primary"
                            ref={(ref: any) => {
                                if (!ref) return;
                                ref.onclick = (e: any) => {
                                    e.stopImmediatePropagation();
                                    handlePopoverOpen(e);
                                };
                            }}
                        >
                            {!hideBadge ? (
                                <Badge badgeContent={attachments.length}>
                                    <FWIcon
                                        name="bi bi-paperclip"
                                        size={clipIconSize}
                                    />
                                </Badge>
                            ) : (
                                <FWIcon
                                    name="bi bi-paperclip"
                                    size={clipIconSize}
                                />
                            )}
                        </IconButton>
                    )}
                </Box>
            </FWTooltip>
            <FWPopover
                id="mouse-over-popover"
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
                PaperProps={{
                    sx: {
                        p: '16px !important',
                    },
                }}
            >
                <div>
                    <FWTypography variant="h5" sx={{ marginBottom: '0.25rem' }}>
                        Attachments
                    </FWTypography>
                    <UploadedFilesList
                        existingFiles={attachments}
                        showRemove={false}
                    />
                </div>
            </FWPopover>
        </>
    );
}
