import { createSlice } from '@reduxjs/toolkit';

export interface IVendorMasterStoredState {
    [key: string]: {
        enterprise_vendor_master_id: string;
        seller_entity_id: string;
        vendor_name: string;
        tags: string[];
    };
}

const storeVendorMasterData = (
    state: IVendorMasterStoredState,
    action: {
        type: string;
        payload: { type: string; data: IVendorMasterStoredState };
    }
) => {
    if (action.payload.type === 'RESET_DATA') return action.payload.data;
    else if (action.payload.type === 'ADD_DATA')
        return { ...state, ...action.payload.data };
    return state;
};

export const vendorMasterTableSlice = createSlice<
    IVendorMasterStoredState,
    {
        storeDataInVendorMasterStore: (
            state: IVendorMasterStoredState,
            action: {
                type: string;
                payload: { type: string; data: IVendorMasterStoredState };
            }
        ) => void;
    },
    string
>({
    name: 'VendorMasterStoredState',
    initialState: {},
    reducers: {
        storeDataInVendorMasterStore: storeVendorMasterData,
    },
});

export const { storeDataInVendorMasterStore } = vendorMasterTableSlice.actions;

const VendorMasterStore = vendorMasterTableSlice.reducer;
export default VendorMasterStore;
