import { useCallback, useState } from 'react';

export enum HookStateValue {
    LOADING = 'LOADING',
    READY = 'READY',
    ERROR = 'ERROR',
    INITIAL = 'INITIAL',
}

export interface IHookState {
    state: HookStateValue;
    cause?: string; // For Error reason cases if needed
}

export const useHookState = (initialValue: HookStateValue) => {
    const [hookState, setHookState] = useState<IHookState>({
        state: initialValue,
    });

    const updateHookCauseState = useCallback(
        (state: HookStateValue, cause: string) => {
            setHookState({ state: state, cause: cause });
        },
        []
    );

    const updateHookState = useCallback((state: HookStateValue) => {
        setHookState({ state: state });
    }, []);

    const state: {
        hookState: IHookState;
        updateHookState: (state: HookStateValue) => void;
        updateHookCauseState: (state: HookStateValue, cause: string) => void;
    } = { hookState, updateHookState, updateHookCauseState };

    return state;
};
