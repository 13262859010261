import { createSlice } from '@reduxjs/toolkit';
import { IEntity } from '../../Organizations/Admin/Interfaces/EnterpriseInterface';
import {
    IVerificationActions,
    toggleVerificationPopupReducer,
    updateAllEntitiesReducer,
    updateUserEntitiesReducer,
} from '../Reducers/VerificationReducers';

export enum VerificationStatus {
    VERIFIED = 'VERIFIED',
    UNVERIFIED = 'UNVERIFIED',
    PENDING = 'PENDING',
    REJECTED = 'REJECTED',
}

export interface IVerificationState {
    verificationPopupVisible: boolean;
    userVerificationEntities: string[];
    allUserEntities: IEntity[];
    hasSignleEntity: boolean;
}

export const VerificationSlice = createSlice<
    IVerificationState,
    {
        toggleVerificationPopup: (
            state: IVerificationState,
            action: IVerificationActions
        ) => void;
        updateUserEntities: (
            state: IVerificationState,
            action: IVerificationActions
        ) => void;
        updateAllEntities: (
            state: IVerificationState,
            action: IVerificationActions
        ) => void;
    },
    string
>({
    name: 'VerificationStore',
    initialState: {
        verificationPopupVisible: false,
        userVerificationEntities: [],
        allUserEntities: [],
        hasSignleEntity: false,
    },
    reducers: {
        toggleVerificationPopup: toggleVerificationPopupReducer,
        updateUserEntities: updateUserEntitiesReducer,
        updateAllEntities: updateAllEntitiesReducer,
    },
});

export const {
    toggleVerificationPopup,
    updateUserEntities,
    updateAllEntities,
} = VerificationSlice.actions;

const VerificationStore = VerificationSlice.reducer;
export default VerificationStore;
