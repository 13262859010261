import { createSlice } from '@reduxjs/toolkit';
import { IEventItemDetails } from '../../../../Events/Interfaces/Buyer/RFQ.model';

export interface INegotiationsStore {
    paginationInformation: {
        currentPage: number;
        currentPageExcludedView: number;
        searchText: string;
    };
    cachingRfqData: {
        [rfq_item_entry_id: string]: IEventItemDetails;
    };
}

export interface INegotiationsStoreAction {
    type: string;
    payload:
        | {
              type: 'UPDATE_PAGINATION_CURRENT_PAGE';
              value: number;
          }
        | {
              type: 'UPDATE_PAGINATION_CURRENT_PAGE_EXCLUDED_VIEW';
              value: number;
          }
        | {
              type: 'UPDATE_PAGINATION_SEARCH_QUERY';
              value: string;
          }
        | {
              type: 'UPDATE_RFQ_CACHE_DATA';
              value: {
                  [rfq_item_entry_id: string]: IEventItemDetails;
              };
          }
        | {
              type: 'RESET';
              value: null;
          };
}

const initialState: INegotiationsStore = {
    paginationInformation: {
        currentPage: 1,
        currentPageExcludedView: 1,
        searchText: '',
    },
    cachingRfqData: {},
};

export const negotiationsSlice = createSlice<
    INegotiationsStore,
    {
        updateNegotiationsStore: (
            state: INegotiationsStore,
            action: INegotiationsStoreAction
        ) => void;
    },
    string
>({
    initialState,
    name: 'negotiationsStore',
    reducers: {
        updateNegotiationsStore: (state, action) => {
            switch (action.payload.type) {
                case 'UPDATE_PAGINATION_CURRENT_PAGE':
                    state.paginationInformation.currentPage =
                        action.payload.value;
                    break;
                case 'UPDATE_PAGINATION_CURRENT_PAGE_EXCLUDED_VIEW':
                    state.paginationInformation.currentPageExcludedView =
                        action.payload.value;
                    break;
                case 'UPDATE_PAGINATION_SEARCH_QUERY':
                    state.paginationInformation.searchText =
                        action.payload.value;
                    state.paginationInformation.currentPage = 1;
                    break;
                case 'UPDATE_RFQ_CACHE_DATA':
                    state.cachingRfqData = {
                        ...state.cachingRfqData,
                        ...action.payload.value,
                    };
                    break;
                case 'RESET':
                    state.cachingRfqData = {};
                    state.paginationInformation = {
                        currentPage: 1,
                        currentPageExcludedView: 1,
                        searchText: '',
                    };
                    break;

                default:
                    break;
            }
        },
    },
});

export const { updateNegotiationsStore } = negotiationsSlice.actions;
