import { Box, Chip } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { FWTypography } from '../../../../Common/FWTypograhy';
import { AuthContext } from '../../../../Contexts/AuthContext';
import VendorLinkedInPopup from '../../../../Events/Components/Buyer/VendorLinkedInPopup';
import { ICurrencyDetails } from '../../../../Models/Currency.model';
import { vendorStatusMap } from '../../Constants/VendorsConstants';
import {
    IEntityVendorListSummary,
    IVendorListSummary,
    VendorStatus,
} from '../../Interfaces/VendorsInterface';
import { useGetAllCurrenciesQuery } from '../../../../Events/Slices/sharedapiSlice';

const VendorEntitiesCell = ({
    data,
    context,
}: {
    data: IVendorListSummary | IEntityVendorListSummary;
    context: any;
}) => {
    const isInvitePending = data.vendorStatus === VendorStatus.INVITED;

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const { authData } = useContext(AuthContext);

    const [currency, setCurrency] = useState<ICurrencyDetails>({
        decimals: 2,
        entry_id: '',
        currency_code_abbreviation: '',
        currency_name: '',
        currency_symbol: '',
    });

    const { data: allCurrencies } = useGetAllCurrenciesQuery({});

    useEffect(() => {
        if (authData.details?.enterprise.billing_currency && allCurrencies) {
            const fetchCurrencies = async () => {
                const resp = allCurrencies;
                const currency = resp.find(
                    (currency) =>
                        currency.entry_id ===
                        'a8c3e3fd-b05f-4d09-bd2f-9fedd07d0ec3' // inr uid
                    // authData.details?.enterprise.billing_currency
                );
                currency && setCurrency(currency);
            };
            fetchCurrencies();
        }
    }, [allCurrencies, authData.details?.enterprise.billing_currency]);

    return (
        <>
            <div className="flex flex--aic" style={{ width: '100%' }}>
                <Box
                    color="primary.main"
                    sx={{ maxWidth: '100%' }}
                    ref={(ref: HTMLDivElement) => {
                        if (!ref) return;
                        ref.onclick = (e: any) => {
                            e.stopPropagation();
                            handleClickOpen();
                        };
                    }}
                >
                    <FWTypography sx={{ maxWidth: '100%', cursor: 'pointer' }}>
                        {data.vendorName}
                    </FWTypography>
                </Box>
                {data.vendorStatus !== VendorStatus.INACTIVE ? (
                    <Chip
                        label={vendorStatusMap[data.vendorVerificationStatus]}
                        size="small"
                        className="ml--15"
                        variant={isInvitePending ? 'outlined' : 'filled'}
                        color={isInvitePending ? 'primary' : undefined}
                        sx={{
                            backgroundColor: isInvitePending
                                ? 'rgba(35, 131, 226, 0.2)'
                                : '',
                            color: isInvitePending
                                ? 'rgb(35, 131, 226)'
                                : 'text.secondary',
                            border: isInvitePending ? ' 0px' : '',
                        }}
                    />
                ) : (
                    <Chip
                        label={vendorStatusMap[data.vendorStatus]}
                        size="small"
                        className="ml--15"
                        sx={{ color: 'text.secondary' }}
                    />
                )}
            </div>
            <VendorLinkedInPopup
                sellerEntityName={data.vendorName}
                open={open}
                handleClose={handleClose}
                currencyCodeAbbreviation={currency.currency_code_abbreviation}
                currencyCodeSymbol={currency.currency_symbol}
                currencyCodeUuid={currency.entry_id}
                enterpriseItemUuid={''}
                itemName={''}
                measurementUnitName={''}
                measurementUnitUuid={''}
                sellerEntityUuid={data.vendorUid}
            />
        </>
    );
};

export default VendorEntitiesCell;
