import { configureStore } from '@reduxjs/toolkit';
import { BomCreationSlice, IBomStore } from '../BOM/Admin/slice/bomSlice';
import CLMStore, { ICLMStore } from '../CLM/Slices/CLMSlice';
import { IChatSliceState } from '../Chats/Interfaces/Chat.model';
import ChatStore from '../Chats/Slice/Chat.slice';
import {
    IQouteComparisionStore,
    quoteComparisonSlice,
} from '../Components/Buyer/Events/QuoteComparison/quoteComparisionSlice';
import {
    INegotiationsStore,
    negotiationsSlice,
} from '../Components/Seller/Events/Negotiations/NegotiationSlice';
import QuoteCalculatorStore, {
    IQuoteCalculatorStore,
} from '../Costing/Store/Quote.store';
import CustomerPortalSlice, {
    ICustomerPortalStore,
} from '../CustomerPortal/Slices/CustomerPortalSlice';
import { IEventApprovalAndUserRolesStore } from '../Events/Interfaces/Buyer/eventApprovalAndUserRoles.interface';
import EventCreationStore, {
    IEventCreationStore,
} from '../Events/Slices/EventCreationSlice';
import OnBoardingChecklistStore, {
    IOnboardingCheckListData,
} from '../Events/Slices/OnBoardingEventChecklist';
import {
    IRfqAnalyticsStore,
    RFQAnalyticsSlice,
} from '../Events/Slices/RfqAnalyticsSlice';
import EventAccessStore, {
    IEventAccessStore,
} from '../Events/Slices/eventAccessSlice';
import EventApprovalAndUserRolesStore from '../Events/Slices/eventApprovalAndUserRolesSlice';
import VendorMasterStore, {
    IVendorMasterStoredState,
} from '../Events/Slices/vendorMasterTable';
import { TFeatures } from '../FeatureAccess/Interfaces/FeaturesInterface';
import AvailableFeaturesStore from '../FeatureAccess/Slices/AvailableFeaturesSlice';
import GRDashboardStore, {
    IGRDashboardState,
} from '../GoodsReceipt/Slices/GRDashboardSlice';
import { INewPostInvoiceDetails } from '../Invoices/Interfaces/NewInvoice.model';
import CreateInvoiceStore from '../Invoices/Slices/CreateInvoiceSlice';
import EventIntegrationStore, {
    IEventIntegrationStore,
} from '../ItemAnalytics/Slices/EventIntegrationSlice';
import ItemCartStore, {
    IItemCartStore,
} from '../ItemAnalytics/Slices/ItemAnalyticsCartSlice';
import PaymentsDashboardStore, {
    IPaymentsDashboardState,
} from '../Payments/Slices/PaymentsDashboardSlice';
import QCDashboardStore, {
    IQCDashboardState,
} from '../QualityCheck/Slices/QCDashboardSlice';
import RequisitionStore, {
    IRequisitionStore,
} from '../Requisitions/Slices/RequisitionSlice';
import SRMDocumentStore, {
    ISRMDocumentStore,
} from '../SRM/Constants/documentStore';
import { IAdminSettingsState } from './Interfaces/AdminSettingsReduxInterface';
import AdminHolidaySettingStore from './Slices/AdminHolidaySettingSlice'; // ISingleHolidayStruct,
import AdminSelectedSettingStore, {
    IAdminSelectedSettingState,
} from './Slices/AdminSelectedSettingSlice';
import AdminSettingsStore from './Slices/AdminSettingsSlice';
import { baseApiSlice } from './Slices/BaseApiSlice';
import CountStore, { ICountStore } from './Slices/CountSlice';
import HADataStore, { IHAGraphDataState } from './Slices/HADataSlice';
import HAFilterStore, { IHAGraphFiltersState } from './Slices/HAFilterSlice';
import HelpInformationStore, {
    IHelpInformationState,
} from './Slices/HelpInformationSlice';
import NotificationsStore, {
    INotificationState,
} from './Slices/NotificationsSlice';
import {
    IRFQOpeningBidStore,
    RfqOpeningBidSlice,
} from './Slices/RfqOpeningBidSlice';
import SetupWizardStore, { ISetupWizardState } from './Slices/SetupWizardSlice';
import VerificationStore, {
    IVerificationState,
} from './Slices/VerificationSlice';
import UserDefaultsStore, {
    IUserDefaultsState,
} from './Slices/userDefaultsSlice';
import SpecificationSlice, {
    SpecificationStore,
} from '../Specifications/Store/SpecificationSlice';

export interface IGlobalAppStore {
    // [x: string]: any;

    NotificationsStore: INotificationState;
    CountStore: ICountStore;
    QCDashboardStore: IQCDashboardState;
    GRDashboardStore: IGRDashboardState;
    PaymentsDashboardStore: IPaymentsDashboardState;
    VerificationStore: IVerificationState;
    AdminSettingsStore: IAdminSettingsState;
    EventAccessStore: IEventAccessStore;
    EventCreationStore: IEventCreationStore;
    ItemCartStore: IItemCartStore;
    QuoteCalculatorStore: IQuoteCalculatorStore;
    SRMDocumentStore: ISRMDocumentStore;
    HelpInformationStore: IHelpInformationState;
    EventApprovalAndUserRolesStore: IEventApprovalAndUserRolesStore;
    SetupWizardStore: ISetupWizardState;
    ChatStore: IChatSliceState;
    CreateInvoiceStore: INewPostInvoiceDetails;
    AvailableFeaturesStore: TFeatures;
    UserDefaultsStore: IUserDefaultsState;
    HADataStore: IHAGraphDataState;
    HAFilterStore: IHAGraphFiltersState;
    VendorMasterStore: IVendorMasterStoredState;
    OnBoardingChecklistStore: IOnboardingCheckListData[];
    AdminSelectedSetting: IAdminSelectedSettingState;
    RfqOpeningBid: IRFQOpeningBidStore;
    QuoteComparison: IQouteComparisionStore;
    Negotiations: INegotiationsStore;
    BomAdminStore: IBomStore;
    RfqAnalyticsStore: IRfqAnalyticsStore;
    EventIntegrationStore: IEventIntegrationStore;
    RequisitionStore: IRequisitionStore;
    CLMStore: ICLMStore;
    CustomerPortalStore: ICustomerPortalStore;
    SpecificationStore: SpecificationStore;
}

export const store = configureStore({
    // Add multiple stores inside reducer
    reducer: {
        UserDefaultsStore,
        NotificationsStore,
        CreateInvoiceStore,
        QCDashboardStore,
        GRDashboardStore,
        PaymentsDashboardStore,
        VerificationStore,
        AdminSettingsStore,
        EventAccessStore,
        EventCreationStore,
        QuoteCalculatorStore,
        ItemCartStore,
        SRMDocumentStore,
        HelpInformationStore,
        EventApprovalAndUserRolesStore,
        SetupWizardStore,
        ChatStore,
        AvailableFeaturesStore,
        HADataStore,
        HAFilterStore,
        VendorMasterStore,
        EventIntegrationStore,
        RequisitionStore,
        CLMStore,
        OnBoardingChecklistStore,
        AdminHolidaySettingStore,
        CountStore,
        AdminSelectedSetting: AdminSelectedSettingStore,
        RfqOpeningBid: RfqOpeningBidSlice.reducer,
        QuoteComparison: quoteComparisonSlice.reducer,
        Negotiations: negotiationsSlice.reducer,
        BomAdminStore: BomCreationSlice.reducer,
        RfqAnalyticsStore: RFQAnalyticsSlice.reducer,
        [baseApiSlice.reducerPath]: baseApiSlice.reducer,
        CustomerPortalStore: CustomerPortalSlice,
        SpecificationStore: SpecificationSlice,
        // counts: countsReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware()
            // .concat(middleware)
            .concat(baseApiSlice.middleware),
});

export type AppDispatch = typeof store.dispatch;

// const middleware: Middleware = (api) => (next) => (action) => {
//     if (action.type.includes('api'))
//

//     const response = next(action);

//     // Do something after the action hits the reducer
//     // const afterState = api.getState();
//     // if (action.type === 'some/action') {
//     //     void loadData(api);
//     // }

//     return response;
// };
