import {
    CircularProgress,
    DialogActions,
    DialogContent,
    Typography,
} from '@mui/material';
import { isNull, isUndefined } from 'lodash';
import { FormEvent, useEffect, useState } from 'react';
import {
    FWAccordion,
    FWAccordionDetails,
    FWAccordionSummary,
} from '../../Common/FWAccordian';
import { FWButton } from '../../Common/FWButton';
import { FWPopupWithChildren } from '../../Common/FWPopupWithChildren';
import { FWTypography } from '../../Common/FWTypograhy';
import { HookStateValue } from '../../Common/Hooks/StateHook';
import {
    IFileResourceType,
    IResourceFields,
} from '../../Components/Shared/UploadFile';
import UploadedFilesList from '../../Components/Shared/UploadedFilesList';
import BulkImportUploadFile from '../Components/BulkImportUploadFile';
import { useBulkImportTemplateDownload } from '../Hooks/BulkImport';
import { IBulkImport } from '../Interfaces/BulkImportInterface';

interface IBulkImportPopupProps {
    popupToggle: boolean;
    title: string;
    helpText?: string;
    resourceType: IFileResourceType;
    resource_fields: IResourceFields[];
    children: JSX.Element;
    showChildren?: boolean;
    label?: string;
    onSuccessDownload: () => void;
    handlePopupToggle: () => void;
    additionalInformation?: {
        [key: string]: any;
    };
    entity_id?: string;
    onFileUpload?: () => void;
    createNewEvent?: (
        resourceType: IFileResourceType
    ) => Promise<string | null>;
    templateId?: string;
    templateName?: string;
    onBulkImportSuccess?: () => void;
    setEventId?: React.Dispatch<React.SetStateAction<string>>;
}

const BulkImportPopup = ({
    popupToggle,
    title,
    helpText,
    resourceType,
    resource_fields,
    children,
    label = 'File',
    handlePopupToggle,
    onSuccessDownload,
    additionalInformation,
    onFileUpload,
    entity_id,
    createNewEvent,
    templateId,
    templateName,
    setEventId,
    onBulkImportSuccess,
    showChildren,
}: IBulkImportPopupProps) => {
    const subEventId =
        additionalInformation && 'rfq_event_id' in additionalInformation
            ? additionalInformation.rfq_event_id
            : additionalInformation && 'po_group_id' in additionalInformation
            ? additionalInformation.po_group_id
            : undefined;

    const {
        hookState,
        getTemplateHeaders,
        template,
        setTemplate,
        startCSVImport,
    } = useBulkImportTemplateDownload(
        resourceType,
        resource_fields,
        additionalInformation,
        subEventId,
        entity_id,
        setEventId
    );
    const [showLoader, setShowLoader] = useState<boolean>(false);

    useEffect(() => {
        if (hookState.state === HookStateValue.ERROR) {
            setTimeout(() => {
                setShowLoader(false);
            }, 500);
        }
    }, [hookState.state]);

    useEffect(() => {
        if (!popupToggle) {
            setTemplate([]);
        }
    }, [popupToggle, setTemplate]);

    const handleFormSubmit = async (event: FormEvent) => {
        event.preventDefault();
        getTemplateHeaders(
            event.target as HTMLFormElement,
            templateId,
            templateName,
            onSuccessDownload
        );
    };

    // const handleCSVImport = async () => {
    //     setLoading(true);
    //     try {
    //         const csvImportUid = await startCSVImport();
    //
    //         history.push(`/admin/bulk-import/${csvImportUid}`);
    //     } catch (error) {
    //         toast.error(`Error completing import, please try again`);
    //     }
    //     setLoading(false);
    // };

    const checkNullFields = () => {
        for (let resource_field of resource_fields) {
            // if (resourceType === 'RFQ_ITEM') {
            //     if (
            //         (resource_field.key === eventsResourceFields.VENDORS ||
            //             resource_field.key === eventsResourceFields.LOTS) &&
            //         resource_field.value !== null &&
            //         resource_field.value < 1
            //     ) {
            //         return true;
            //     }
            // } else if (resourceType === 'PO_GROUP_ITEM') {
            //     if (
            //         resource_field.key === eventsResourceFields.LOTS &&
            //         resource_field.value !== null &&
            //         resource_field.value < 1
            //     ) {
            //         return true;
            //     }
            // } else {
            //     if (resource_field.value !== null && resource_field.value < 1) {
            //         return true;
            //     }
            // }
            if (
                !resource_field.isOptional &&
                resource_field.value !== null &&
                resource_field.value < 1
            ) {
                return true;
            }
        }
    };

    return (
        <>
            <FWPopupWithChildren
                handleClose={handlePopupToggle}
                open={popupToggle}
                title={title}
            >
                <form onSubmit={handleFormSubmit}>
                    <DialogContent style={{ paddingTop: 0, paddingBottom: 0 }}>
                        {!isUndefined(helpText) && (
                            <Typography>{helpText}</Typography>
                        )}
                        {resource_fields.length > 0 ? (
                            <>
                                <FWAccordion
                                    sx={{
                                        '&.MuiPaper-root': {
                                            minWidth: 'auto',
                                            p: 0,
                                            border: 0,
                                        },
                                        '.MuiButtonBase-root': {
                                            width: 'max-content !important',
                                        },
                                        '.MuiAccordionSummary-content': {
                                            width: 'max-content !important',
                                        },
                                        '.MuiAccordionSummary-expandIconWrapper':
                                            {
                                                ml: '10px',
                                                zoom: 0.7,
                                            },
                                    }}
                                >
                                    <FWAccordionSummary>
                                        <FWTypography>
                                            Generate template
                                        </FWTypography>
                                    </FWAccordionSummary>
                                    <FWAccordionDetails>
                                        {children}
                                        <div className="flex flex--jcfe">
                                            <FWButton
                                                type="submit"
                                                color="primary"
                                                variant="contained"
                                                disabled={
                                                    hookState.state ===
                                                        HookStateValue.LOADING ||
                                                    checkNullFields()
                                                }
                                                id="bulkImport_downloadTemplate"
                                            >
                                                Download template
                                            </FWButton>
                                        </div>
                                    </FWAccordionDetails>
                                </FWAccordion>
                                <div>
                                    <FWTypography>OR</FWTypography>
                                </div>
                            </>
                        ) : showChildren ? (
                            <>{children}</>
                        ) : (
                            <></>
                        )}

                        <div className="mt--20">
                            <BulkImportUploadFile
                                label={label}
                                existingFiles={
                                    !isNull(template) ? template : []
                                }
                                allowedFileSizeInMB={5}
                                maxAllowedFiles={1}
                                maxFileNameLength={100}
                                // onFileUpload={(files) => {
                                //     setTemplate(files);
                                // }}
                                onFileUpload={(id: IBulkImport[]) => {
                                    setTemplate(id);
                                    onFileUpload && onFileUpload();
                                }}
                                resourceType={resourceType}
                                allowedFileTypes={['csv', 'xlsx', 'xls']}
                            />
                            <div className="mb--10"></div>
                            <UploadedFilesList
                                listFor="BULK_IMPORT"
                                existingFiles={
                                    !isNull(template) ? template : []
                                }
                                toggleFileRemove={() => {
                                    setTemplate([]);
                                }}
                                fromBulkImport
                            />
                        </div>
                    </DialogContent>
                    <DialogActions sx={{ padding: '0 24px 20px', mt: '20px' }}>
                        <FWButton
                            type="button"
                            variant="contained"
                            disabled={
                                hookState.state === HookStateValue.LOADING ||
                                template.length === 0 ||
                                showLoader
                            }
                            onClick={() => {
                                setShowLoader(true);
                                startCSVImport(
                                    'FRESH_UPLOAD',
                                    createNewEvent,
                                    (success) => {
                                        if (success) {
                                            handlePopupToggle();
                                            setShowLoader(false);
                                            setTemplate([]);
                                            onBulkImportSuccess &&
                                                onBulkImportSuccess();
                                        }
                                    }
                                );
                            }}
                            id="bulkImport_UploadTemplate"
                        >
                            {hookState.state === HookStateValue.LOADING ||
                            showLoader ? (
                                <CircularProgress
                                    size={24}
                                    style={{ color: '#c4c4c8' }}
                                />
                            ) : (
                                'Upload template'
                            )}
                        </FWButton>
                    </DialogActions>
                </form>
            </FWPopupWithChildren>
        </>
    );
};

export default BulkImportPopup;
