import { IHistoricalAnalyticsChart } from '../Interfaces/IHistoricalAnalytics.model';
import {
    getInvoiceDateFilter,
    getItemFilterCategory,
    getPaymentDueDateFilter,
    getPoIssueDateFilter,
    getUserFilterCategory,
    getVendorFilterCategory,
} from './CommonFilters';
import {
    getStandardInvoiceItemColumns,
    getStandardPoColumns,
} from './DownloadColumns';

export const HA_Financial_PaymentTerms: IHistoricalAnalyticsChart = {
    chartId: 'payment_terms',
    title: 'Payment Terms',
    downloadSettings: {
        object: 'invoice_item',
        columns: getStandardInvoiceItemColumns(),
    },
    chartType: 'bar',
    axisLabelY: 'Invoice Item Value',
    axisLabelY2: 'PO Count',
    axisLabelX: 'Payment Terms',
    currencySettings: {
        showTargetCurrency: true,
        targetCurrency: null,
        filterCurrenciesIds: [],
    },
    filterContoller: {
        fixedFilters: [
            {
                filterId: '',
                filterType: 'notNull',
                filterLabel: '',
                filterFields: {
                    metricFieldType: 'universal',
                    fieldName: 'invoice_item__invoice_item_id',
                },
                filterApplied: true,
            },
            {
                filterId: '',
                filterType: 'notNull',
                filterLabel: '',
                filterFields: {
                    metricFieldType: 'universal',
                    fieldName:
                        'invoice_item__payment_terms__term',
                },
                filterApplied: true,
            },
            {
                filterId: 'fromInvoiceDate',
                filterType: 'singleselectDropdown',
                filterLabel: 'From Invoice Date',
                filterApplied: true,
                filterFields: {
                    metricFieldType: 'metricSpecific',
                    metricFields: {
                        invoiceItemValueInvoiceDate:
                            'invoice_item__payment_terms__applied_from',
                    },
                },
                modifier: 'EQUALS',
                value: 'INVOICE_DATE',
            },
            {
                filterId: 'fromDispatchDate',
                filterType: 'singleselectDropdown',
                filterLabel: 'From Dispatch Date',
                filterApplied: true,
                filterFields: {
                    metricFieldType: 'metricSpecific',
                    metricFields: {
                        invoiceItemValueDispatchDate:
                            'invoice_item__payment_terms__applied_from',
                    },
                },
                modifier: 'EQUALS',
                value: 'DISPATCH_DATE',
            },
            {
                filterId: 'fromReceiptDate',
                filterType: 'singleselectDropdown',
                filterLabel: 'From Receipt Date',
                filterApplied: true,
                filterFields: {
                    metricFieldType: 'metricSpecific',
                    metricFields: {
                        invoiceItemValueReceiptDate:
                            'invoice_item__payment_terms__applied_from',
                    },
                },
                modifier: 'EQUALS',
                value: 'RECEIPT_DATE',
            },
        ],
        customizableFilters: {
            categoryConnector: 'AND',
            categories: [
                {
                    categoryLabel: 'Date Range',
                    filterConnector: 'AND',
                    filters: [getInvoiceDateFilter({})],
                    expanded: true,
                },
                getItemFilterCategory(),
                getVendorFilterCategory(),
                getUserFilterCategory({
                    event__original_event_creator: 'APPLICABLE',
                    po__created_by_user: 'DEFAULT',
                    po__final_buyer_approval: 'APPLICABLE',
                    rfq_event__final_approver: 'APPLICABLE',
                }),
            ],
        },
    },
    metrics: [
        {
            metricId: 'invoiceItemValueInvoiceDate',
            displayModifier: 'currency',
            display: 'bar',
            metricLegendLabel: 'From Invoice Date',
            metricDefinition: {
                metricType: 'base',
                metricField: 'invoice_item__pricing_information__total_amount',
                aggregation: 'sum',
            },
        },
        {
            metricId: 'invoiceItemValueDispatchDate',
            displayModifier: 'currency',
            display: 'bar',
            metricLegendLabel: 'From Dispatch Date',
            metricDefinition: {
                metricType: 'base',
                metricField: 'invoice_item__pricing_information__total_amount',
                aggregation: 'sum',
            },
        },
        {
            metricId: 'invoiceItemValueReceiptDate',
            displayModifier: 'currency',
            display: 'bar',
            metricLegendLabel: 'From Receipt Date',
            metricDefinition: {
                metricType: 'base',
                metricField: 'invoice_item__pricing_information__total_amount',
                aggregation: 'sum',
            },
        },
        /**
         * The below metric is for PO count but it only includes POs which have
         * at least 1 invoice item, so it can be misleading.
         * This metric can be deleted if need be.
         */
        {
            display: 'line',
            metricId: 'poCount',
            metricLegendLabel: 'Total PO count',
            displayModifier: 'count',
            metricDefinition: {
                metricType: 'base',
                metricField: 'po_id',
                aggregation: 'count',
            },
        },
    ],
    groupBy: [
        {
            type: 'metric',
            field: 'invoice_item__payment_terms__term',
            groupDefinitions: [
                {
                    groupingName: '0 days',
                    start: null,
                    end: 0,
                    startInclusive: true,
                    endInclusive: true,
                },
                {
                    groupingName: '1-29 days',
                    start: 1,
                    end: 29,
                    startInclusive: true,
                    endInclusive: true,
                },
                {
                    groupingName: '30-59 days',
                    start: 30,
                    end: 59,
                    startInclusive: true,
                    endInclusive: true,
                },
                {
                    groupingName: '60-89 days',
                    start: 50,
                    end: 89,
                    startInclusive: true,
                    endInclusive: true,
                },
                {
                    groupingName: '90-119 days',
                    start: 90,
                    end: 119,
                    startInclusive: true,
                    endInclusive: true,
                },
                {
                    groupingName: '>=120 days',
                    start: 120,
                    end: null,
                    startInclusive: true,
                    endInclusive: true,
                },
            ],
        },
    ],
    groupSort: {
        sortBy: 'none',
        sortOrder: 'desc',
    },
    groupFilter: {
        filterBy: 'none',
        filterNumber: 10,
        customFilter: null,
    },
};

export const HA_Financial_CommittedSpend: IHistoricalAnalyticsChart = {
    chartId: 'committed_spend',
    title: 'Committed Spend',
    downloadSettings: {
        object: 'po',
        columns: getStandardPoColumns(),
    },
    chartType: 'bar',
    axisLabelX: 'Quarters',
    axisLabelY: 'PO value',
    axisLabelY2: 'PO count',
    currencySettings: {
        showTargetCurrency: true,
        targetCurrency: null,
        filterCurrenciesIds: [],
    },
    filterContoller: {
        fixedFilters: [
            {
                filterId: '',
                filterType: 'notNull',
                filterLabel: '',
                filterFields: {
                    metricFieldType: 'universal',
                    fieldName: 'po__purchase_order_id',
                },
                filterApplied: true,
            },
            {
                filterId: '',
                filterType: 'multiselectDropdown',
                filterLabel: '',
                filterFields: {
                    metricFieldType: 'universal',
                    fieldName: 'po__purchase_order_type',
                },
                filterApplied: true,
                modifierType: 'CONSOLIDATED',
                modifierValue: 'NOT_IN',
                values: ['STAGING'],
            },
            {
                //PO status for PO count mainly but doesnt impact the PO value metrics either.
                filterId: '',
                filterType: 'multiselectDropdown',
                filterLabel: '',
                filterFields: {
                    metricFieldType: 'metricSpecific',
                    metricFields: {
                        poCount: 'po__status',
                    },
                },
                filterApplied: true,
                modifierType: 'CONSOLIDATED',
                modifierValue: 'IN',
                values: ['ISSUED', 'ONGOING', 'TERMINATED', 'COMPLETED'],
            },
            {
                //PO status accepted value metric
                filterId: '',
                filterType: 'multiselectDropdown',
                filterLabel: '',
                filterFields: {
                    metricFieldType: 'metricSpecific',
                    metricFields: {
                        poValueAccepted: 'po__status',
                    },
                },
                filterApplied: true,
                modifierType: 'CONSOLIDATED',
                modifierValue: 'IN',
                values: ['ONGOING', 'TERMINATED', 'COMPLETED'],
            },
            {
                //PO status issued value metric
                filterId: '',
                filterType: 'multiselectDropdown',
                filterLabel: '',
                filterFields: {
                    metricFieldType: 'metricSpecific',
                    metricFields: {
                        poValueIssued: 'po__status',
                    },
                },
                filterApplied: true,
                modifierType: 'CONSOLIDATED',
                modifierValue: 'IN',
                values: ['ISSUED'],
            },
        ],
        customizableFilters: {
            categoryConnector: 'AND',
            categories: [
                {
                    categoryLabel: 'Date Range',
                    filterConnector: 'AND',
                    filters: [getPoIssueDateFilter({})],
                    expanded: true,
                },
                getItemFilterCategory(),
                getVendorFilterCategory(),
                getUserFilterCategory({
                    event__original_event_creator: 'APPLICABLE',
                    po__created_by_user: 'DEFAULT',
                    po__final_buyer_approval: 'APPLICABLE',
                    rfq_event__final_approver: 'APPLICABLE',
                }),
            ],
        },
    },
    metrics: [
        {
            display: 'bar',
            metricId: 'poValueIssued',
            metricLegendLabel: 'Issued',
            displayModifier: 'currency',
            metricDefinition: {
                metricType: 'base',
                metricField: 'po__pricing_information__total',
                aggregation: 'sum',
            },
        },
        {
            display: 'bar',
            metricId: 'poValueAccepted',
            metricLegendLabel: 'Accepted',
            displayModifier: 'currency',
            metricDefinition: {
                metricType: 'base',
                metricField: 'po__pricing_information__total',
                aggregation: 'sum',
            },
        },
        {
            display: 'line',
            metricId: 'poCount',
            metricLegendLabel: 'Total PO count',
            displayModifier: 'count',
            metricDefinition: {
                metricType: 'base',
                metricField: 'po_id',
                aggregation: 'count',
            },
        },
    ],
    groupBy: [
        {
            type: 'date',
            field: 'po__submission_datetime',
            period: 'quarter',
        },
    ],
    groupSort: {
        sortBy: 'none',
        sortOrder: 'asc',
    },
    groupFilter: {
        filterBy: 'none',
        filterNumber: null,
        customFilter: null,
    },
};

export const HA_Financial_PaymentTimeliness: IHistoricalAnalyticsChart = {
    chartId: 'payment_timeliness',
    title: 'On-time Payment',
    downloadSettings: {
        object: 'invoice_item',
        columns: getStandardInvoiceItemColumns(),
    },
    chartType: 'bar',
    axisLabelY: 'Amount Paid',
    axisLabelX: 'Payment Timing',
    currencySettings: {
        showTargetCurrency: true,
        targetCurrency: null,
        filterCurrenciesIds: [],
    },
    filterContoller: {
        fixedFilters: [
            {
                filterId: '',
                filterType: 'notNull',
                filterLabel: '',
                filterFields: {
                    metricFieldType: 'universal',
                    fieldName: 'invoice_item__payment_due_date',
                },
                filterApplied: true,
            },
            {
                filterId: '',
                filterType: 'notNull',
                filterLabel: '',
                filterFields: {
                    metricFieldType: 'universal',
                    fieldName: 'invoice_item__payment_completed_datetime',
                },
                filterApplied: true,
            },
            {
                filterId: '',
                filterType: 'multiselectDropdown',
                filterLabel: '',
                filterFields: {
                    metricFieldType: 'universal',
                    fieldName: 'po__status',
                },
                filterApplied: true,
                modifierType: 'CONSOLIDATED',
                modifierValue: 'IN',
                values: ['ISSUED', 'ONGOING', 'TERMINATED', 'COMPLETED'],
            },
            {
                filterId: '',
                filterType: 'multiselectDropdown',
                filterLabel: '',
                filterFields: {
                    metricFieldType: 'universal',
                    fieldName: 'po__purchase_order_type',
                },
                filterApplied: true,
                modifierType: 'CONSOLIDATED',
                modifierValue: 'NOT_IN',
                values: ['STAGING'],
            },
        ],
        customizableFilters: {
            categoryConnector: 'AND',
            categories: [
                {
                    categoryLabel: 'Date Range',
                    filterConnector: 'AND',
                    filters: [getPaymentDueDateFilter({})],
                    expanded: true,
                },
                getItemFilterCategory(),
                getVendorFilterCategory(),
                getUserFilterCategory({
                    event__original_event_creator: 'APPLICABLE',
                    po__created_by_user: 'APPLICABLE',
                    po__final_buyer_approval: 'APPLICABLE',
                    rfq_event__final_approver: 'APPLICABLE',
                }),
            ],
        },
    },
    metrics: [
        {
            metricId: 'paymentCompletedAmount',
            displayModifier: 'currency',
            display: 'bar',
            metricLegendLabel: 'Payment amount',
            metricDefinition: {
                metricType: 'base',
                metricField: 'invoice_item__payment_information__completed',
                aggregation: 'sum',
            },
        },
    ],
    groupBy: [
        {
            type: 'metric',
            field: 'invoice_item__payment_completed_datetime-invoice_item__payment_due_date',
            groupDefinitions: [
                {
                    groupingName: '>=60 days early',
                    start: null,
                    end: -60,
                    startInclusive: true,
                    endInclusive: true,
                },
                {
                    groupingName: '30-59 days early',
                    start: -59,
                    end: -30,
                    startInclusive: true,
                    endInclusive: true,
                },
                {
                    groupingName: '1-29 days early',
                    start: -29,
                    end: -1,
                    startInclusive: true,
                    endInclusive: true,
                },
                {
                    groupingName: 'On Time',
                    start: 0,
                    end: 0,
                    startInclusive: true,
                    endInclusive: true,
                },
                {
                    groupingName: '1-29 days late',
                    start: 1,
                    end: 29,
                    startInclusive: true,
                    endInclusive: true,
                },
                {
                    groupingName: '30-59 days late',
                    start: 30,
                    end: 59,
                    startInclusive: true,
                    endInclusive: true,
                },
                {
                    groupingName: '>=60 days late',
                    start: 60,
                    end: null,
                    startInclusive: true,
                    endInclusive: true,
                },
            ],
        },
    ],
    groupSort: {
        sortBy: 'value',
        sortOrder: 'desc',
    },
    groupFilter: {
        filterBy: 'none',
        filterNumber: null,
        customFilter: null,
    },
};

export const HA_Financial_InvoicedValueByVendor: IHistoricalAnalyticsChart = {
    chartId: 'invoiced_value_by_vendor',
    title: 'Invoiced Value',
    downloadSettings: {
        object: 'invoice_item',
        columns: getStandardInvoiceItemColumns(),
    },
    chartType: 'bar',
    axisLabelY: 'Invoiced Value',
    axisLabelX: 'Vendor',
    currencySettings: {
        showTargetCurrency: true,
        targetCurrency: null,
        filterCurrenciesIds: [],
    },
    filterContoller: {
        fixedFilters: [
            {
                filterId: '',
                filterType: 'notNull',
                filterLabel: '',
                filterFields: {
                    metricFieldType: 'universal',
                    fieldName: 'invoice_item_id',
                },
                filterApplied: true,
            },
            {
                filterId: '',
                filterType: 'multiselectDropdown',
                filterLabel: '',
                filterFields: {
                    metricFieldType: 'universal',
                    fieldName: 'invoice__status',
                },
                filterApplied: true,
                modifierType: 'CONSOLIDATED',
                modifierValue: 'IN',
                values: [
                    'ISSUED',
                    'ONGOING',
                    'TERMINATED',
                    'GOODS_RECEIVED',
                    'COMPLETED',
                ],
            },
            {
                filterId: '',
                filterType: 'multiselectDropdown',
                filterLabel: '',
                filterFields: {
                    metricFieldType: 'universal',
                    fieldName: 'invoice__invoice_type',
                },
                filterApplied: true,
                modifierType: 'CONSOLIDATED',
                modifierValue: 'NOT_IN',
                values: [
                    'SELLER_PROFORMA_INVOICE',
                    'PREPAYMENT_INVOICE',
                    'SELLER_PREPAYMENT_INVOICE',
                ],
            },
        ],
        customizableFilters: {
            categoryConnector: 'AND',
            categories: [
                {
                    categoryLabel: 'Date Range',
                    filterConnector: 'AND',
                    filters: [getInvoiceDateFilter({})],
                    expanded: true,
                },
                getItemFilterCategory(),
                getVendorFilterCategory(),
                getUserFilterCategory({
                    event__original_event_creator: 'APPLICABLE',
                    po__created_by_user: 'DEFAULT',
                    po__final_buyer_approval: 'APPLICABLE',
                    rfq_event__final_approver: 'APPLICABLE',
                }),
            ],
        },
    },
    metrics: [
        {
            metricId: 'invoicedAmount',
            displayModifier: 'currency',
            display: 'bar',
            metricLegendLabel: 'Invoiced amount',
            metricDefinition: {
                metricType: 'base',
                metricField: 'invoice_item__pricing_information__total_amount',
                aggregation: 'sum',
            },
        },
    ],
    groupBy: [
        {
            type: 'str',
            field: 'seller_entity',
            includeInGroupName: false,
        },
        {
            type: 'str',
            field: 'seller_entity__entity_name',
            includeInGroupName: true,
        },
    ],
    groupSort: {
        sortBy: 'value',
        sortOrder: 'desc',
    },
    groupFilter: {
        filterBy: 'none',
        filterNumber: null,
        customFilter: null,
    },
};
