import { createSlice } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';
import { createInvoiceInitialState } from '../Constants/helperFunctions';
import { INewPostInvoiceDetails } from '../Interfaces/NewInvoice.model';
import { IUpdateInvoiceDetailsAction } from '../Interfaces/Shared.model';
import { updateInvoiceDetailsReducer } from '../Reducers/CreateInvoiceReducers';

export const CreateInvoiceDashboardSlice = createSlice<
    INewPostInvoiceDetails,
    {
        updateInvoiceData: (
            state: INewPostInvoiceDetails,
            action: IUpdateInvoiceDetailsAction
        ) => void;
    },
    string
>({
    name: 'CreateInvoiceStore',
    initialState: cloneDeep(createInvoiceInitialState),
    reducers: {
        updateInvoiceData: updateInvoiceDetailsReducer,
    },
});

export const { updateInvoiceData } = CreateInvoiceDashboardSlice.actions;

const CreateInvoiceStore = CreateInvoiceDashboardSlice.reducer;
export default CreateInvoiceStore;
