import styled from '@emotion/styled';
import {
    FWAccordion,
    FWAccordionSummary,
} from '../../../../../Common/FWAccordian';

export const StyledAccordion = styled(FWAccordion)({
    '&:before': {
        backgroundColor: 'rgba(0, 0,0, 0)',
    },
    '&': {
        backgroundColor: 'white',
        padding: '0px',
        // border: "1px solid #C4C4C8",
        // borderRadius: "10px",
    },
});

export const StyledAccordionSummary = styled(FWAccordionSummary)({
    '&': {
        // padding: "0",
        margin: '0px 0 5px 0',
    },
    '& .MuiAccordionSummary-content': {
        // margin: "0px",
    },
});

export const boxHeaderStyle = {
    backgroundColor: '#F0F0F0',
    height: '3rem',
    width: '100%',
    display: 'grid',
    // gridTemplateColumns: '3% 12% 10% 10% 12.5% 12.5% 12.5% 12.5% 15%',
    gridColumnGap: '5px',
    alignItems: 'center',
    borderRadius: '10px 10px 0 0',
    padding: '0 1%',
};

export const boxContentStyle = {
    width: '100%',
    display: 'grid',
    height: 'auto',
    // gridTemplateColumns: '3% 12% 10% 10% 12.5% 12.5% 12.5% 12.5% 15%',
    gridColumnGap: '5px',
    padding: '0 1%',
    marginTop: '5px',
    alignItems: 'center',
};

export const boxDetailsStyle = {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '5% 42.5% 0% 0% 12.5% 40% 0% 0%',
    gridColumnGap: '5px',
    padding: '2% 1%',
};
