import produce from 'immer';
import { TFeatures } from '../Interfaces/FeaturesInterface';

export enum FeaturesActions {
    UPDATE_LIST = 'UPDATE_LIST',
}

export interface IUpdateFeaturesAction {
    type: FeaturesActions.UPDATE_LIST;
    value: TFeatures;
}

export interface IFeaturesActions {
    payload: IUpdateFeaturesAction;
}

export const featuresReducer = produce(
    (state: TFeatures, action: IFeaturesActions) => {
        switch (action.payload.type) {
            case FeaturesActions.UPDATE_LIST: {
                state = action.payload.value;
                return state;
            }
        }
    }
);
