import { RequisitionStatusEnum } from '../Interfaces/Requisitions.interface';

export const getStatusLabelAndColor = (status: RequisitionStatusEnum) => {
    switch (status) {
        case RequisitionStatusEnum.DRAFT: {
            return {
                label: 'Draft',
                color: '',
            };
        }
        case RequisitionStatusEnum.REQUISITION_APPROVAL_PENDING: {
            return {
                label: 'Submitted',
                color: 'orange',
            };
        }
        case RequisitionStatusEnum.REWORK: {
            return {
                label: 'Submitted',
                color: 'orange',
            };
        }
        case RequisitionStatusEnum.REJECTED: {
            return {
                label: 'Submitted',
                color: 'error',
            };
        }
        case RequisitionStatusEnum.SUBMITTED: {
            return {
                label: 'Submitted',
                color: '#F9c80e',
            };
        }
        case RequisitionStatusEnum.ONGOING: {
            return {
                label: 'Ongoing',
                color: 'orange',
            };
        }
        case RequisitionStatusEnum.FULFILLED: {
            return {
                label: 'Ongoing',
                color: 'success.main',
            };
        }
        case RequisitionStatusEnum.PARTIALLY_FULFILLED: {
            return {
                label: 'Ongoing',
                color: 'primary',
            };
        }
        case RequisitionStatusEnum.CLOSED: {
            return {
                label: 'Ongoing',
                color: 'error',
            };
        }
        case RequisitionStatusEnum.COMPLETED: {
            return {
                label: 'Ongoing',
                color: 'success.main',
            };
        }
        default: {
            return {
                label:
                    (status as string).slice(0, 1) +
                    status.toLowerCase().slice(1, status.length + 1),
                color: 'text.secondary',
            };
        }
    }
};
