import { IconButton } from '@mui/material';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { FWMenu, FWMenuItem } from '../../../../Common/FWCustomMenu';
import { FWPopup } from '../../../../Common/FWPopup';
import { useConfirmation } from '../../../../Common/Hooks/ConfirmationHook';
import {
    IItemSummary,
    ItemEntityStatus,
} from '../../Interfaces/ItemDirectoryInterface';
import { updateEntitiesList } from '../../Services/ItemDirectoryService';
import { IItemUpdateValues } from './ItemsListOptionsMenu';

const EntityItemsListOptionsMenu = ({
    data,
    context,
}: {
    data: IItemSummary;
    context: {
        entityUid: string;
        entityName: string;
        handleItemEmitAction: (data: IItemUpdateValues) => void;
    };
}) => {
    const { entityUid, entityName, handleItemEmitAction } = context;
    const [loading, setLoading] = useState<boolean>(false);
    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
    const openMenu = Boolean(menuAnchorEl);
    const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setMenuAnchorEl(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setMenuAnchorEl(null);
    };

    const [
        confirmationValues,
        updateConfirmationDetails,
        resetConfirmationValues,
    ] = useConfirmation();

    const handleConfirmationToggle = () => {
        resetConfirmationValues();
    };

    const handleItemUpdate = async () => {
        setLoading(true);
        const itemUpdated = await updateEntitiesList(data.id, {
            entityId: entityUid,
            status: ItemEntityStatus.INACTIVE,
        });
        if (itemUpdated) {
            handleItemEmitAction({
                index: data.index || 0,
                key: confirmationValues.key,
                value: confirmationValues.value,
            });
            handleConfirmationToggle();
            handleCloseMenu();
        } else {
            toast.error(`Item updated failed.`);
        }
        setLoading(false);
    };

    const status: boolean = data.entityStatus === ItemEntityStatus.ACTIVE;
    const statusTitle = (status ? `Disable` : `Enable`) + ' item';
    const statusMessage = `${data.name} will be ${
        status ? 'disabled' : 'enabled'
    } for ${entityName}`;
    const statusValue = status
        ? ItemEntityStatus.INACTIVE
        : ItemEntityStatus.ACTIVE;

    return (
        <>
            <IconButton
                ref={(ref) => {
                    if (!ref) return;

                    ref.onclick = (e: any) => {
                        e.stopPropagation();
                        handleOpenMenu(e);
                    };
                }}
                color="primary"
                sx={{ margin: '0 auto' }}
            >
                <i className="bi bi-three-dots fs--14"></i>
            </IconButton>
            <FWMenu
                anchorEl={menuAnchorEl}
                open={openMenu}
                onClose={handleCloseMenu}
            >
                {/* {status && (
                    <FWMenuItem
                        onClick={() => {
                            updateConfirmationDetails({
                                title: lockedTitle,
                                message: lockedMessage,
                                key: 'locked',
                                value: !row.locked,
                            });
                        }}
                    >
                        <i
                            className={`bi bi-${
                                row.locked ? 'unlock' : 'lock'
                            }-fill mr--10 menuOptionIcon`}
                        ></i>
                        {lockedTitle}
                    </FWMenuItem>
                )} */}

                <FWMenuItem
                    sx={{ color: status ? 'error.main' : 'primary.main' }}
                    onClick={() => {
                        updateConfirmationDetails({
                            title: statusTitle,
                            message: statusMessage,
                            key: 'entityStatus',
                            value: statusValue,
                            color: status ? 'error' : 'primary',
                        });
                    }}
                >
                    {statusTitle}
                </FWMenuItem>
            </FWMenu>

            <FWPopup
                title={confirmationValues.title}
                msg={confirmationValues.message}
                btnColor={confirmationValues.color}
                btnTitle="Confirm"
                open={confirmationValues.open}
                setOpen={handleConfirmationToggle}
                onClickConfirm={handleItemUpdate}
                btnLoading={loading}
            />
        </>
    );
};

export default EntityItemsListOptionsMenu;
