import { createSlice } from '@reduxjs/toolkit';
import { IHAGraphData } from '../../Historical/Hooks/useHAGraph';

export interface IHAGraphDataState {
    [graphId: string]: IHAGraphData;
}

const initialState: IHAGraphDataState = {};

export const HADataSlice = createSlice({
    name: 'HADataSlice',
    initialState,
    reducers: {
        setHAData: (
            state,
            action: {
                type: string;
                payload: {
                    graphId: string;
                    data: IHAGraphData;
                };
            }
        ) => {
            const { graphId, data } = action.payload;
            state[graphId] = data;
        },
    },
});

export const { setHAData } = HADataSlice.actions;
export default HADataSlice.reducer;
