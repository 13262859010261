import { Box, Grid } from '@mui/material';
import { isNull } from 'lodash';
import { Fragment, useState } from 'react';
import FWPopover from '../../../../Common/FWPopover';
import { FWTypography } from '../../../../Common/FWTypograhy';
import {
    IVendorBuyerEntity,
    IVendorListSummary,
} from '../../Interfaces/VendorsInterface';

const VendorEntitiesLinkedCell = ({
    data,
    context,
}: {
    data: IVendorListSummary;
    context: {
        updateVendorBuyerEntities: (
            index: number,
            buyer: IVendorBuyerEntity[]
        ) => void;
    };
}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClose = () => setAnchorEl(null);
    const handleOpen = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    return (
        <>
            <Box
                color={
                    data.buyerEntitiesCount > 0
                        ? 'primary.main'
                        : 'text.secondary'
                }
                sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-end',
                }}
            >
                <div
                    ref={(ref) => {
                        if (!ref) return;
                        ref.onclick = (e: any) => {
                            e.stopPropagation();
                            if (data.buyerEntitiesCount > 0) handleOpen(e);
                        };
                    }}
                >
                    {data.buyerEntitiesCount}
                </div>
            </Box>

            <FWPopover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Grid container>
                    {!isNull(data.buyerEntities) && (
                        <div className="p--5">
                            <div className="mb--5">
                                <FWTypography color="text.secondary">
                                    Entities
                                </FWTypography>
                            </div>
                            {data.buyerEntities.map((entity, idx) => (
                                <Fragment key={idx}>
                                    <Grid
                                        item
                                        md={12}
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                        }}
                                    >
                                        <FWTypography>
                                            {idx + 1}. {entity.entityName}
                                        </FWTypography>
                                        {/* {data.preferredItemsCount > 0 && (
                                        <FWTooltip
                                            placement="right"
                                            title={`Vendor is preferred for ${data.vendorCode} item(s)`}
                                        >
                                            <div className="flex flex--aic mb--5">
                                                <FWIcon
                                                    name="bi bi-patch-check"
                                                    color="success.main"
                                                    className="mr--5"
                                                />
                                                <FWTypography color="text.secondary">
                                                    {entity.preferredItemsCount}
                                                </FWTypography>
                                            </div>
                                        </FWTooltip>
                                    )} */}
                                    </Grid>
                                </Fragment>
                            ))}
                        </div>
                    )}
                </Grid>
            </FWPopover>
        </>
    );
};

export default VendorEntitiesLinkedCell;
