import { Box, Grid } from '@mui/material';
import { ReactElement } from 'react';
import BackButton from '../../../Common/FWBackButton';
import { FWMenuItem } from '../../../Common/FWCustomMenu';
import FWSelect from '../../../Common/FWSelect';
import { FWTypography } from '../../../Common/FWTypograhy';
import { IEntity } from '../Interfaces/EnterpriseInterface';

export enum HeaderType {
    GLOBAL = 'GLOBAL',
    ENTITY = 'ENTITY',
}

interface IPageHeaderProps {
    back?: boolean;
    title?: string | ReactElement;
    backTitle?: string;
    switchEntity?: {
        selectedEntity: string;
        entityList: IEntity[];
    };
    emitEntityChange?: (event: any) => void;
    type?: HeaderType;
    enterprise?: boolean;
    children?: JSX.Element;
    onBackClick?: () => void;
    hideBgColor?: boolean;
    dropdownType?: boolean;
}

const PageHeader = ({
    back = false,
    title,
    backTitle = 'Back',
    switchEntity,
    emitEntityChange,
    type = HeaderType.ENTITY,
    enterprise,
    children,
    onBackClick,
    hideBgColor,
    dropdownType = false,
}: IPageHeaderProps) => {
    const selectBox = (
        <FWSelect
            labelId="entity-select-label"
            id="entity-select"
            value={
                switchEntity?.entityList.length
                    ? switchEntity.selectedEntity
                    : ''
            }
            onChange={emitEntityChange}
            style={{
                width: '200px',
                height: '40px',
                marginLeft: '10px',
                position: 'absolute',
                right: '10px',
            }}
            sx={{
                background: '#fff',
            }}
        >
            {type === HeaderType.GLOBAL && (
                <FWMenuItem value="global">Global</FWMenuItem>
            )}

            {switchEntity?.entityList.map((entity: IEntity) => {
                return (
                    <FWMenuItem value={entity.entityId} key={entity.entityId}>
                        {entity.entityName}
                    </FWMenuItem>
                );
            })}
        </FWSelect>
    );
    return (
        <>
            <Grid
                container
                sx={{
                    position: 'sticky',
                    top: '54px',
                    left: 0,
                    background: 'white',
                    zIndex: 9,
                    paddingY: '0.5rem',
                    backgroundColor: enterprise
                        ? hideBgColor
                            ? 'transparent'
                            : '#fff'
                        : '#fff',
                }}
            >
                <Grid
                    item
                    xs={12}
                    className="flex flex--jcsb flex--aic pl--20 pr--20 pt--5 pb--5"
                >
                    <Grid item xs={3} className="flex flex--aic">
                        {back ? (
                            <BackButton
                                title={backTitle}
                                onBackClick={onBackClick}
                            />
                        ) : (
                            <span></span>
                        )}
                    </Grid>
                    <Grid item xs={6} className="flex flex--jcc flex--aic">
                        <FWTypography variant="h4">{title}</FWTypography>
                        {switchEntity &&
                            switchEntity.entityList.length > 1 &&
                            type !== HeaderType.GLOBAL &&
                            selectBox}
                    </Grid>
                    <Grid item xs={3} className="flex flex--jcfe flex--aic">
                        {switchEntity &&
                            switchEntity.entityList.length > 1 &&
                            type === HeaderType.GLOBAL &&
                            selectBox}
                        <Box
                            sx={{ display: 'flex', alignItems: 'center' }}
                            id="admin-portal-placeholder"
                        ></Box>
                        {children}
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    {/* <Divider /> */}
                </Grid>
            </Grid>
        </>
    );
};

export default PageHeader;
