import { cloneDeep, debounce } from 'lodash';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { HookStateValue, useHookState } from '../../Common/Hooks/StateHook';
import { NavContext } from '../../Contexts/NavContext';
import { getTemplateForSelectedEntity } from '../../Global/Helpers/templateApiSlice';
import { IEntity } from '../../Organizations/Admin/Interfaces/EnterpriseInterface';
import {
    useFetchEntitiesQuery,
    useGetEntityListQuery,
} from '../../Organizations/Admin/Services/EnterpriseService';
import { useFetchTabCountMutation } from '../../Redux/Slices/tabCountApiSlice';
import { convertContractResponseToDashboard } from '../HelperFunctions/ItemAnalyticsHelper';
import {
    CLMPageEntityDetails,
    ICLMDashboardDetails,
} from '../Interface/ContractInterfaces';
import {
    cloneContract,
    deleteContract,
    entityAPI,
    getDashboardForCLM,
} from '../Services/CLM.service';

export type sortFieldColumns =
    | 'contract_id'
    | 'contract_name'
    | 'contract_start_date'
    | 'contract_end_date'
    | 'contract_status'
    | 'buyer_entity_name'
    | 'seller_entity_name'
    | 'contract_item_count'
    | 'project_code'
    | 'modified_datetime';

const sortColumnsNames = {
    contract_id: 'custom_contract_id',
    contract_name: 'contract_name',
    contract_start_date: 'contract_start_date',
    contract_end_date: 'contract_end_date',
    contract_status: 'status',
    buyer_entity_name: 'buyer_entity',
    seller_entity_name: 'seller_entity',
    contract_item_count: 'contract_item_count',
    project_code: 'project_code',
    modified_datetime: 'modified_datetime',
};

export const useDashBoard = (
    sellerEntityUuid?: string,
    fetchAllContracts?: boolean,
    enterpriseItemUuid?: string,
    initialTabValue?: string
) => {
    const { data: entities } = useFetchEntitiesQuery({ options: undefined });
    const history = useHistory();
    const { hookState, updateHookState } = useHookState(HookStateValue.INITIAL);
    const [fetchTabData] = useFetchTabCountMutation({
        fixedCacheKey: 'contract_buyer',
    });

    const searchParams = new URLSearchParams(window.location.search);
    // const tab = searchParams.get('type');
    const { currentTab } = useContext(NavContext);

    const [buyerEntities, setBuyerEntities] = useState<IEntity[]>([]);

    const [selectedBuyerEntity, setSelectedBuyerEntity] = useState<string>('');

    const updateSelectedBuyerEntity = (buyerEntity: {
        entityName: string;
        entityUid: string;
        preferredItemsCount: number;
        vendorEntitySelected: boolean;
    }) => {
        setSelectedBuyerEntity(buyerEntity.entityUid);
    };

    const [contractsDashboardSettings, setContractsDashboardSettings] =
        useState<{
            searchText: string;
            currentPage: number;
            sortFields: {
                field: string;
                ascending: boolean;
            }[];
            itemsPerPage: number;
            tab: string | null;
        }>({
            tab: initialTabValue ? initialTabValue : searchParams.get('type'),
            itemsPerPage: 10,
            currentPage: 1,
            searchText: '',
            sortFields: [],
        });

    const updateTab = useCallback((newTab: string) => {
        setContractsDashboardSettings((prev) => ({
            ...prev,
            tab: newTab,
        }));
    }, []);

    const getCLMTemplate = async (entityId: string) => {
        if (entities) {
            try {
                let resp = await getTemplateForSelectedEntity(
                    entities[0].entityId,
                    'CLM'
                );

                if (resp.length === 1) {
                    history.replace(
                        `/buyer/CLM/template/${resp[0].template_id}/contract`
                    );
                } else {
                    history.replace('/buyer/CLM/template/');
                }
            } catch (err) {}
        }
    };

    // const [currentPage, setCurrentPage] = useState(1);
    const [clmDashboardData, setCLMDashboardData] = useState<
        ICLMDashboardDetails[]
    >([]);
    const [pageEntity, setPageEntity] = useState<CLMPageEntityDetails[]>([]);
    // const [searchText, setSearchText] = useState('');

    const [totalPages, setTotalPages] = useState<number>(0);

    const cloneContractFromDashboard = useCallback(
        async ({
            contract_uuid,
            contract_name,
        }: {
            contract_uuid: string;
            contract_name: string;
        }) => {
            if (clmDashboardData) {
                const response = await cloneContract({
                    contract_uuid: contract_uuid,
                    contract_name: contract_name,
                });
                let ind = clmDashboardData.findIndex((row) => {
                    return row.contract_uuid === contract_uuid;
                });

                const newCLMDashboardData = cloneDeep(clmDashboardData);
                const newObj = cloneDeep(newCLMDashboardData[ind]);
                newObj.contract_uuid = response;
                newCLMDashboardData.push(newObj);
                setCLMDashboardData(newCLMDashboardData);

                return response;
            }
        },
        [clmDashboardData]
    );

    const deleteContractFromDashboard = useCallback(
        async ({ contract_uuid }: { contract_uuid: string }) => {
            await deleteContract({
                contract_uuid: contract_uuid,
            });
            fetchTabData('contract_buyer');
            let ind = clmDashboardData?.findIndex((row) => {
                return row.contract_uuid === contract_uuid;
            });

            const newCLMDashboardData = cloneDeep(clmDashboardData);
            newCLMDashboardData?.splice(ind ? ind : -1, 1);
            setCLMDashboardData(newCLMDashboardData);

            fetchContractDashboardDetails(
                contractsDashboardSettings.currentPage,
                contractsDashboardSettings.searchText,
                contractsDashboardSettings.sortFields
            );
        },

        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            clmDashboardData,
            contractsDashboardSettings.currentPage,
            contractsDashboardSettings.searchText,
            contractsDashboardSettings.sortFields,
            updateHookState,
        ]
    );

    const debouncedSearch = useMemo(
        () =>
            debounce((text: string) => {
                setContractsDashboardSettings((prev) => ({
                    ...prev,
                    searchText: text,
                    currentPage: 1,
                }));
                // setSearchText(text);
            }, 1000),
        []
    );
    const [showLoader, setshowLoader] = useState(false);
    const fetchContractDashboardDetails = useCallback(
        async (currentPage, searchText, sortFields) => {
            try {
                setshowLoader(true);
                const sortFieldsArray = sortFields.map(
                    (sortField: {
                        field: sortFieldColumns;
                        ascending: boolean;
                    }) => {
                        return {
                            field: sortColumnsNames[sortField.field],
                            ascending: sortField.ascending,
                        };
                    }
                );

                const result = await getDashboardForCLM({
                    dashboard_view: 'contract_buyer',
                    items_per_page: 10,
                    page_number: currentPage,
                    search_text: searchText,
                    sort_fields: sortFieldsArray as any,
                    tab: fetchAllContracts
                        ? 'all'
                        : currentTab
                        ? currentTab
                        : 'drafts',
                    sellerEntityUuid,
                    enterpriseItemUuid,
                    buyerEntityid: selectedBuyerEntity,
                });

                const mappedDashboardDetails =
                    convertContractResponseToDashboard(result.data);

                setCLMDashboardData(mappedDashboardDetails);
                setTotalPages(result.metadata.total_pages);
            } catch (e) {
            } finally {
                setshowLoader(false);
            }
        },
        [
            fetchAllContracts,
            sellerEntityUuid,
            enterpriseItemUuid,
            selectedBuyerEntity,
            currentTab,
        ]
    );
    const { data: listOfEntities } = useGetEntityListQuery({});

    useEffect(() => {
        if (listOfEntities) {
            setBuyerEntities(listOfEntities);
        }
    }, [listOfEntities]);

    const getEntityOfPage = async () => {
        const result = await entityAPI();
        setPageEntity(result);
    };

    useEffect(() => {
        getEntityOfPage();
    }, []);

    useEffect(() => {
        updateHookState(HookStateValue.LOADING);
        fetchContractDashboardDetails(
            contractsDashboardSettings.currentPage,
            contractsDashboardSettings.searchText,
            contractsDashboardSettings.sortFields
        );
        updateHookState(HookStateValue.READY);
    }, [
        contractsDashboardSettings.currentPage,
        fetchContractDashboardDetails,
        contractsDashboardSettings.searchText,
        contractsDashboardSettings.sortFields,
        contractsDashboardSettings.tab,
        updateHookState,
    ]);

    const handleCreateContract = () => {
        if (entities?.length === 1) {
            getCLMTemplate(entities[0].entityId);
        } else {
            history.replace('/buyer/CLM/template/');
        }
    };

    return {
        clmDashboardData,
        setCLMDashboardData,
        hookState,
        buyerEntities,
        updateSelectedBuyerEntity,
        deleteContractFromDashboard,
        cloneContractFromDashboard,
        sortFields: contractsDashboardSettings.sortFields,
        searchText: contractsDashboardSettings.searchText,
        currentPage: contractsDashboardSettings.currentPage,
        setContractsDashboardSettings,
        debouncedSearch,
        totalPages,
        fetchContractDashboardDetails,
        updateTab,
        pageEntity,
        handleCreateContract,
        showLoader
    };
};
