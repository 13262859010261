import { useCallback, useEffect, useState } from 'react';
import { IBuyerItemList } from '../../Events/Interfaces/Buyer/RFQ.model';
import { newgetBuyerMasterItemsForEntity } from '../../Events/Services/Buyer/shared.service';

const useSellerProfileItems = (entityId: string) => {
    const [buyerMaterItemList, setBuyerMasterItemList] = useState<
        IBuyerItemList[]
    >([]);
    const [hasMoreItemsInList, setHasMoreItemsInList] = useState(false);

    const [searchItemToAdd, setSearchItemToAdd] = useState({
        searchText: '',
        currentPage: 1,
    });
    const [isLoading, setIsLoading] = useState(false);
    const onSearchItem = useCallback((searchText: string) => {
        setSearchItemToAdd({ searchText: searchText, currentPage: 1 });
    }, []);
    const onShowMoreItems = useCallback(() => {
        setSearchItemToAdd((prev) => {
            return { ...prev, currentPage: prev.currentPage + 1 };
        });
    }, []);
    const fetchBuyerItems = useCallback(
        async (searchText: string, currentPage: number) => {
            try {
                setIsLoading(true);
                let resp = await newgetBuyerMasterItemsForEntity({
                    searchText: searchText,
                    itemsPerPage: 15,
                    pageNumber: currentPage,
                    sortFields: [{ field: 'name', ascending: true }],
                    entityUid: entityId ?? '',
                });
                if (currentPage === 1) {
                    setBuyerMasterItemList((prev) => [...resp.items]);
                } else {
                    setBuyerMasterItemList((prev) => [
                        ...prev.slice(0, prev.length - 1),
                        ...resp.items,
                    ]);
                }

                setHasMoreItemsInList(
                    resp.pageDetails.totalPages > currentPage
                );
                if (resp.pageDetails.totalPages > currentPage) {
                    const hasNext: IBuyerItemList = {
                        entity_item_id: 'SHOW_MORE_ITEMS',
                        preferred_vendors_count: 0,
                        preferred_vendors: [],
                        enterprise_item: {
                            item_type: 'RAW_MATERIAL',
                            enterprise_item_id: '',
                            code: '',
                            name: '',
                            description: '',
                            buyer_additional_costs: [],
                            seller_additional_costs: [],
                            notes: '',
                            measurement_units: {
                                item_measurement_units: [],
                            },
                            attributes: [],
                            custom_ids: {
                                custom_ids: [],
                            },
                            tags: [],
                            status: 'ACTIVE',
                            buyer_enterprise: '',
                            buyer_pricing_information: null,
                            seller_pricing_information: null,
                        },
                        status: 'ACTIVE',
                        buyer_enterprise: '',
                        buyer_entity: '',
                        hasNext: true,
                    };
                    setBuyerMasterItemList((prev) => [...prev, hasNext]);
                }
                setIsLoading(false);
            } catch (err) {
                console.log(err, '');
            }
        },
        [entityId]
    );

    //DEBOUNCED SEARCH FOR PROJECTS
    // const debouncedSearchText = useMemo(
    //     () =>
    //         debounce((searchText: string) => {
    //             fetchBuyerItems(searchText, currentPage);
    //         }, 500),
    //     [currentPage, fetchBuyerItems]
    // );

    // //DEBOUNCED SEARCH FOR PROJECTS
    // const handleSearchTextChange = useCallback(
    //     (searchText: string) => {
    //         debouncedSearchText(searchText);
    //     },
    //     [debouncedSearchText]
    // );

    // //DEBOUNCED SEARCH FOR PROJECTS
    // useEffect(() => {
    //     handleSearchTextChange(searchText);
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [searchText]);

    useEffect(() => {
        fetchBuyerItems(
            searchItemToAdd.searchText,
            searchItemToAdd.currentPage
        );
        //Disabled cause only want it to run once for search and page change
        //eslint-disable-next-line
    }, [searchItemToAdd]);
    return {
        onSearchItem,
        buyerMaterItemList,
        hasMoreItemsInList,
        onShowMoreItems,
        isLoading,
    };
};

export default useSellerProfileItems;
