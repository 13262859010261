import { toast } from 'react-toastify';
import { IGetRFQDetails, IGetRFQItemDetails } from '../../Models/RFQ.model';
import { useHistory } from 'react-router-dom';
import React from 'react';
import { Moment } from 'moment';
import moment from 'moment';
import { isEmpty } from 'lodash';
import {
    IEventDetails,
    IEventItemDetails,
} from '../../Events/Interfaces/Buyer/RFQ.model';

export const getBuyerItemName = (itemInformation: any): string => {
    if (
        itemInformation &&
        itemInformation.hasOwnProperty('custom_item_name') &&
        !!itemInformation.custom_item_name &&
        itemInformation.custom_item_name !== ''
    ) {
        return itemInformation.custom_item_name;
    } else if (
        itemInformation &&
        itemInformation.hasOwnProperty('buyer_item_name')
    ) {
        return itemInformation.buyer_item_name === ''
            ? '-'
            : itemInformation.buyer_item_name;
    } else {
        return '';
    }
};

export const useCorrectVersionHandler = () => {
    const history = useHistory();

    const checkVersion = React.useCallback(
        (rfqData: IGetRFQDetails | IEventDetails) => {
            if (rfqData.status === 'REVISED') {
                if (
                    rfqData.additional_details.child_rfq_id !== null &&
                    rfqData.additional_details.child_rfq_id.trim() !== ''
                ) {
                    let newUrl =
                        window.location.hostname + window.location.pathname;
                    if (newUrl.indexOf(rfqData.rfq_entry_id!) >= 0) {
                        newUrl.replace(
                            rfqData.rfq_entry_id!,
                            rfqData.additional_details.child_rfq_id
                        );
                        history.push(newUrl);
                    }
                } else {
                    toast.error(
                        'This event is invalid, redirecting to dashboard.'
                    );
                    history.push('/buyer/events');
                }
            }
        },
        [history]
    );

    return {
        checkVersion,
    };
};

export const leadTimeSurpassed = (
    value: number | string | null,
    period: string | null
): boolean => {
    let leadTimePassed = false;
    if (!period || !value) return leadTimePassed;
    switch (period.toLowerCase()) {
        case 'days' || 'day':
            leadTimePassed = +value > 365;
            break;
        case 'weeks' || 'week':
            leadTimePassed = +value > 52;
            break;
        case 'months' || 'month':
            leadTimePassed = +value > 12;
            break;
        default:
            leadTimePassed = false;
            break;
    }
    return leadTimePassed;
};

export const isDeliveryDateInvalid = (
    item: IGetRFQItemDetails | IEventItemDetails
) => {
    // returns list of delivery dates
    if (item.delivery_information.length === 1) {
        if (
            item.delivery_information[0].delivery_date === null ||
            isEmpty(item.delivery_information[0].delivery_date)
        ) {
            return false;
        }
    }
    const deliveryDatesList: Moment[] = [];
    item.delivery_information.forEach((deliveryItem) => {
        if (deliveryItem.delivery_date)
            deliveryDatesList.push(moment(deliveryItem.delivery_date));
    });
    if (deliveryDatesList.length > 0)
        return moment
            .min(deliveryDatesList)
            .isSameOrBefore(
                moment(
                    moment().add(
                        item.procurement_information.lead_time,
                        item.procurement_information.lead_time_period.toLocaleLowerCase() as any
                    )
                )
            );
    else return false;
};
