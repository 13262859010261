import { Divider, Grid } from '@mui/material';
import ProfileMenu from '../Components/Shared/ProfileMenu';

export default function Navbar({
    hideVerificationChip = false,
}: {
    hideVerificationChip?: boolean;
}) {
    return (
        <Grid
            container
            style={{
                position: 'sticky',
                top: 0,
                backgroundColor: 'white',
                zIndex: 999,
            }}
        >
            <Grid item xs={8}>
                <img
                    style={{ height: 'auto', width: '3rem' }}
                    src="/logo.png"
                    alt="factwise logo"
                />
            </Grid>
            <Grid item xs={4}>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                    }}
                >
                    <ProfileMenu
                        showOnlyLogoutButton
                        hideVerificationChip={hideVerificationChip}
                    />
                </div>
            </Grid>
            <Grid item xs={12}>
                <Divider />
            </Grid>
        </Grid>
    );
}
