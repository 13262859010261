import { Box, Avatar, Chip } from '@mui/material';
import { FWTypography } from '../../../Common/FWTypograhy';
import { IUserDetails } from '../../../Models/UserDetails.model';

interface IUserAvatar {
    user_details: IUserDetails;
}

export default function UserAvatar(props: IUserAvatar) {
    return (
        <Box className="flex flex--aic">
            <Avatar
                sx={{
                    width: '100px',
                    height: '100px',
                    marginRight: '20px',
                }}
                src={props.user_details.user_picture_url}
                alt="profile picture"
            >
                {props.user_details.name[0]}
            </Avatar>
            <Box>
                <Box className="flex flex--aic">
                    <FWTypography variant="h4">
                        {props.user_details.name}
                    </FWTypography>
                    <span style={{ width: '0.75rem' }}></span>
                    {props.user_details.role === 'ADMIN' && (
                        <Chip label={'Admin'} />
                    )}
                </Box>
                {props.user_details.username.length > 0 && (
                    <FWTypography>{props.user_details.username}</FWTypography>
                )}
                <FWTypography>{props.user_details.email}</FWTypography>
            </Box>
        </Box>
    );
}
