import { cloneDeep, debounce } from 'lodash';
import moment from 'moment';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { HookStateValue, useHookState } from '../../../Common/Hooks/StateHook';
import { getFWDateTime } from '../../../Common/Utils/DateUtils';
import { AuthContext } from '../../../Contexts/AuthContext';
import { IVLNNote } from '../../Interfaces/VendorLNInterfaces';
import {
    addVLNNoteApi,
    deleteVLNNoteApi,
    fetchVLNNotesApi,
    updateVLNNoteApi,
} from '../../Services/VendorLinkedIn.service';

export type IVLNNoteUpdate = (
    data: IVLNNote,
    payload: {
        type: 'title' | 'notes';
        value: string;
    },
    callback?: () => void
) => Promise<void>;

export const useVLNRelationshipNotes = (vendor_profile_id: string | null) => {
    const { hookState, updateHookState } = useHookState(HookStateValue.INITIAL);
    const [VLNNotes, setVLNNotes] = useState<IVLNNote[]>([]);
    // const [entities, setEntities] = useState<IEntity[]>([]);
    const [selectedVLNNote, setSelectedVLNNote] = useState<IVLNNote | null>(
        null
    );

    const [selectedNoteIndex, setSelectedNoteIndex] = useState<number | null>(
        null
    );

    const fetchNotes = useCallback(async () => {
        updateHookState(HookStateValue.LOADING);
        try {
            if (!vendor_profile_id) return;
            const fetchVLNNotesPromise = fetchVLNNotesApi(vendor_profile_id);
            // const fetchEntitiesPromise = fetchEntities();

            const [fetchVLNNotesResponse /* fetchEntitiesResponse */] =
                await Promise.all([
                    fetchVLNNotesPromise /* fetchEntitiesPromise */,
                ]);

            setVLNNotes(fetchVLNNotesResponse);
            setSelectedVLNNote(fetchVLNNotesResponse[0]);
            setSelectedNoteIndex(0);
            // setEntities(fetchEntitiesResponse);
            updateHookState(HookStateValue.READY);
        } catch (error) {
            updateHookState(HookStateValue.ERROR);

            toast.error('Error fetching notes');
        }
    }, [updateHookState, vendor_profile_id]);

    useEffect(() => {
        fetchNotes();
    }, [fetchNotes]);

    const { authData } = useContext(AuthContext);

    const addNote = async () => {
        try {
            if (!vendor_profile_id) return;
            const response = await addVLNNoteApi(vendor_profile_id, {
                notes: '',
                buyer_entity_id:
                    authData?.details?.defaults?.primary_entity_id || '',
                title: `Note ${getFWDateTime(
                    moment().format('YYYY-MM-DD HH:mm:ss')
                )}`,
            });
            setVLNNotes([response, ...VLNNotes]);
            setSelectedVLNNote({
                ...response,
            });
            setSelectedNoteIndex(0);
        } catch (error) {
            toast.error('Error adding note');
        }
    };

    const onAddNote = () => {
        addNote();
    };

    const onNoteSelect = (index: number) => {
        setSelectedVLNNote(cloneDeep(VLNNotes[index]));
        setSelectedNoteIndex(index);
    };

    const deleteNote = async (note_id: string, buyer_entity_id: string) => {
        try {
            if (!vendor_profile_id) return;
            await deleteVLNNoteApi(vendor_profile_id, note_id, buyer_entity_id);
            const filteredNotes = VLNNotes.filter(
                (note) => note.vendor_profile_note_id !== note_id
            );
            setVLNNotes(filteredNotes);
            setSelectedVLNNote(filteredNotes[0]);
            setSelectedNoteIndex(0);
            toast.warning('Note deleted');
        } catch (error) {
            toast.error('Error deleting note');
        }
    };

    const onDeleteNote = (note_id: string, buyer_entity_id: string) => {
        deleteNote(note_id, buyer_entity_id);
    };

    const {
        hookState: updateNoteTitleHookState,
        updateHookState: updateNoteTitleHookStateUpdate,
    } = useHookState(HookStateValue.INITIAL);
    const {
        hookState: updateNoteNoteHookState,
        updateHookState: updateNoteNoteHookStateUpdate,
    } = useHookState(HookStateValue.INITIAL);

    const debouncedUpdateNoteNote = useMemo(
        () =>
            debounce(
                async (
                    vendor_profile_id: string,
                    note_id: string,
                    data: {
                        title: string;
                        notes: string;
                        buyer_entity_id: string;
                    }
                ) => {
                    try {
                        updateNoteNoteHookStateUpdate(HookStateValue.LOADING);
                        await updateVLNNoteApi(
                            vendor_profile_id,
                            note_id,
                            data
                        );
                        updateNoteNoteHookStateUpdate(HookStateValue.READY);
                    } catch (error) {
                        updateNoteNoteHookStateUpdate(HookStateValue.ERROR);
                        toast.error('Error updating note');
                    }
                },
                1000
            ),
        [updateNoteNoteHookStateUpdate]
    );

    const updateNote: IVLNNoteUpdate = async (noteData, payload, callback) => {
        try {
            if (!vendor_profile_id) return;
            if (payload.type === 'title') {
                updateNoteTitleHookStateUpdate(HookStateValue.LOADING);
                await updateVLNNoteApi(
                    vendor_profile_id,
                    noteData.vendor_profile_note_id,
                    {
                        buyer_entity_id: noteData.buyer_entity,
                        notes: noteData.notes,
                        title: payload.value,
                    }
                );
            } else {
                debouncedUpdateNoteNote(
                    vendor_profile_id,
                    noteData.vendor_profile_note_id,
                    {
                        buyer_entity_id: noteData.buyer_entity,
                        notes: payload.value,
                        title: noteData.title,
                    }
                );
            }
            setVLNNotes(
                VLNNotes.map((note) => {
                    if (
                        note.vendor_profile_note_id ===
                        noteData.vendor_profile_note_id
                    ) {
                        return {
                            ...note,
                            notes:
                                payload.type === 'notes'
                                    ? payload.value
                                    : note.notes,
                            title:
                                payload.type === 'title'
                                    ? payload.value
                                    : note.title,
                        };
                    }
                    return note;
                })
            );
            setSelectedVLNNote((prev) => {
                if (prev) {
                    return {
                        ...prev,
                        notes:
                            payload.type === 'notes'
                                ? payload.value
                                : prev.notes,
                        title:
                            payload.type === 'title'
                                ? payload.value
                                : prev.title,
                    };
                }
                return null;
            });

            if (payload.type === 'title') {
                updateNoteTitleHookStateUpdate(HookStateValue.READY);
            } else {
                updateNoteNoteHookStateUpdate(HookStateValue.READY);
            }

            if (callback) {
                callback();
            }
        } catch (error) {
            toast.error('Error updating note');
            if (payload.type === 'title') {
                updateNoteTitleHookStateUpdate(HookStateValue.READY);
            } else {
                updateNoteNoteHookStateUpdate(HookStateValue.READY);
            }
        }
    };

    return {
        hookState,
        VLNNotes,
        // entities,
        onAddNote,
        onNoteSelect,
        selectedVLNNote,
        selectedNoteIndex,
        onDeleteNote,
        updateNote,
        updateNoteTitleHookState,
        updateNoteNoteHookState,
    };
};
