import React from 'react';
import { Typography, Grid, Divider, LinearProgress, Box } from '@mui/material';
import { isNull } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { FWTooltip } from '../../Common/FWCustomTooltip';
import { FWTypography } from '../../Common/FWTypograhy';
import { FWNumberFormatter } from '../../Common/Utils/CurrencyUtils';
import { AuthContext } from '../../Contexts/AuthContext';
import { IPaymentItemRejectionInfo } from '../Interfaces/PaymentsInterface';
import { fetchItemRejectionDetails } from '../Services/PaymentsService';

interface IItemRejectionDetailsProps {
    invoiceItemUid: string;
    onLoaded?: (val: boolean) => void;
    terminatedQuantity?: string;
    measurementUnitName: string;
    currentPortal: 'BUYER' | 'SELLER';
    buyerEntityId: string;
}

const ItemRejectionDetails = ({
    invoiceItemUid,
    onLoaded,
    terminatedQuantity,
    measurementUnitName,
    currentPortal,
    buyerEntityId,
}: IItemRejectionDetailsProps) => {
    const [rejData, setRejData] = useState<IPaymentItemRejectionInfo | null>(
        null
    );
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        if (isNull(rejData)) {
            setIsLoading(true);
            const getRejectionData = async () => {
                const rejData = await fetchItemRejectionDetails(invoiceItemUid);
                setRejData(rejData);
                setIsLoading(false);
            };
            getRejectionData();
        }

        return () => {
            if (!isNull(rejData)) {
                setRejData(null);
            }
        };
    }, [invoiceItemUid, rejData]);

    const totalRejections = useMemo(() => {
        if (rejData) {
            const grRejections = rejData.grn.reduce((pv, nv) => {
                return (pv += +nv.grRejected);
            }, 0);
            const grOverdeliveries = rejData.grn.reduce((pv, nv) => {
                return (pv += +nv.grOverdelivery);
            }, 0);
            const qcRejections = rejData.qc.reduce((pv, nv) => {
                return (pv += +nv.rejectedQty);
            }, 0);
            const terminationRejections = terminatedQuantity
                ? +terminatedQuantity
                : 0;

            return {
                num:
                    grRejections +
                    grOverdeliveries +
                    qcRejections +
                    terminationRejections,
                measure: measurementUnitName,
            };
        }
        return { num: 0, measure: measurementUnitName };
    }, [measurementUnitName, rejData, terminatedQuantity]);

    useEffect(() => {
        if (onLoaded) {
            onLoaded(isLoading);
        }
    }, [isLoading, onLoaded]);

    const redirectToGRDetails = (grUid: string) => {
        let params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,
        width=0,height=0,left=-1000,top=-1000`;

        window.open(`/buyer/goods-receipt/view/${grUid}`, grUid, params);
    };

    const redirectToQCDetails = (grUid: string) => {
        let params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,
        width=0,height=0,left=-1000,top=-1000`;

        window.open(
            `/buyer/quality-check/detail/${grUid}?state=VIEW`,
            grUid,
            params
        );
    };

    //

    const { checkPermission } = React.useContext(AuthContext);
    return (
        <div>
            {!isLoading && !isNull(rejData) && (
                <>
                    {rejData.grn.length === 0 ? (
                        <Grid container spacing={1} marginBottom="15px">
                            {/* <Grid item xs={12}>
                                <Divider />
                            </Grid> */}
                            <Grid item xs={12}>
                                <FWTypography
                                    fontWeight={500}
                                    color="warning.main"
                                >
                                    GR Pending
                                </FWTypography>
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid container spacing={1} marginBottom="15px">
                            {/* <Grid item xs={12}>
                                <Divider />
                            </Grid> */}
                            <Grid item xs={12}>
                                <FWTypography fontWeight={500}>
                                    GR Details
                                </FWTypography>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container flexWrap="nowrap" spacing={0.5}>
                                    <Grid item xs={2}>
                                        <Typography
                                            whiteSpace="nowrap"
                                            color="text.secondary"
                                        >
                                            GR ID
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography
                                            whiteSpace="nowrap"
                                            color="text.secondary"
                                        >
                                            Batch ID
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2.5}>
                                        <Typography
                                            whiteSpace="nowrap"
                                            color="text.secondary"
                                        >
                                            Accepted quantity
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2.5}>
                                        <Typography
                                            whiteSpace="nowrap"
                                            color="text.secondary"
                                        >
                                            Rejected quantity
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography
                                            whiteSpace="nowrap"
                                            color="text.secondary"
                                        >
                                            Total GR quantity
                                        </Typography>
                                    </Grid>
                                </Grid>
                                {rejData &&
                                    rejData.grn.map((gr, idx) => {
                                        return (
                                            <Grid
                                                container
                                                key={idx}
                                                flexWrap="nowrap"
                                                spacing={0.5}
                                            >
                                                <Grid
                                                    item
                                                    xs={2}
                                                    onClick={(e) => {
                                                        currentPortal ===
                                                            'BUYER' &&
                                                        checkPermission(
                                                            'BUYER',
                                                            'GOODS_RECEIPT',
                                                            'VIEW',
                                                            buyerEntityId
                                                        )
                                                            ? redirectToGRDetails(
                                                                  gr.grUid
                                                              )
                                                            : e.preventDefault();
                                                    }}
                                                >
                                                    <FWTypography
                                                        color={
                                                            currentPortal ===
                                                                'BUYER' &&
                                                            checkPermission(
                                                                'BUYER',
                                                                'GOODS_RECEIPT',
                                                                'VIEW',
                                                                null
                                                            )
                                                                ? 'primary.main'
                                                                : undefined
                                                        }
                                                        sx={{
                                                            cursor:
                                                                currentPortal ===
                                                                    'BUYER' &&
                                                                checkPermission(
                                                                    'BUYER',
                                                                    'GOODS_RECEIPT',
                                                                    'VIEW',
                                                                    null
                                                                )
                                                                    ? 'pointer'
                                                                    : 'initial',
                                                        }}
                                                    >
                                                        {gr.grNo}
                                                    </FWTypography>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <FWTypography>
                                                        {gr.batchId}
                                                    </FWTypography>
                                                </Grid>
                                                <Grid item xs={2.5}>
                                                    <FWTypography color="success.main">
                                                        {FWNumberFormatter(
                                                            +gr.grAccepted
                                                        )}{' '}
                                                        {gr.measurementUnit}(s)
                                                    </FWTypography>
                                                </Grid>
                                                <Grid item xs={2.5}>
                                                    <div className="flex flex--aic">
                                                        <FWTypography color="error.main">
                                                            {FWNumberFormatter(
                                                                +gr.grRejected +
                                                                    +gr.grOverdelivery
                                                            )}{' '}
                                                            {gr.measurementUnit}
                                                            (s)
                                                        </FWTypography>
                                                        {+gr.grOverdelivery >
                                                            0 && (
                                                            <>
                                                                &nbsp;
                                                                <FWTooltip
                                                                    title={`Includes ${FWNumberFormatter(
                                                                        +gr.grOverdelivery
                                                                    )} ${
                                                                        gr.measurementUnit
                                                                    }(s) overdelivered return quantity`}
                                                                >
                                                                    <Box color="warning.main">
                                                                        <i className="bi bi-exclamation-circle" />
                                                                    </Box>
                                                                </FWTooltip>
                                                            </>
                                                        )}
                                                        {+gr.grTolerance >
                                                            0 && (
                                                            <>
                                                                &nbsp;
                                                                <FWTooltip
                                                                    title={`Excludes allowable tolerance loss of ${FWNumberFormatter(
                                                                        +gr.grTolerance
                                                                    )} ${
                                                                        gr.measurementUnit
                                                                    }(s)`}
                                                                >
                                                                    <Box color="warning.main">
                                                                        <i className="bi bi-exclamation-circle" />
                                                                    </Box>
                                                                </FWTooltip>
                                                            </>
                                                        )}
                                                    </div>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <FWTypography>
                                                        {FWNumberFormatter(
                                                            +gr.totalQty
                                                        )}{' '}
                                                        {gr.measurementUnit}(s)
                                                    </FWTypography>
                                                </Grid>
                                            </Grid>
                                        );
                                    })}
                            </Grid>
                        </Grid>
                    )}
                    {rejData.qc.length > 0 && (
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                            <Grid item xs={12}>
                                <FWTypography fontWeight={500}>
                                    QC Details
                                </FWTypography>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container flexWrap="nowrap" spacing={0.5}>
                                    <Grid item xs={2}>
                                        <Typography
                                            whiteSpace="nowrap"
                                            color="text.secondary"
                                        >
                                            ARN
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography
                                            whiteSpace="nowrap"
                                            color="text.secondary"
                                        >
                                            Batch ID
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2.5}>
                                        <Typography
                                            whiteSpace="nowrap"
                                            color="text.secondary"
                                        >
                                            Accepted quantity
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2.5}>
                                        <Typography
                                            whiteSpace="nowrap"
                                            color="text.secondary"
                                        >
                                            Rejected Quantity
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography
                                            whiteSpace="nowrap"
                                            color="text.secondary"
                                        >
                                            Total QC quantity
                                        </Typography>
                                    </Grid>
                                </Grid>
                                {rejData &&
                                    rejData.qc.map((qc, idx) => {
                                        return (
                                            <Grid
                                                container
                                                key={idx}
                                                flexWrap="nowrap"
                                                spacing={0.5}
                                                marginBottom="5px"
                                            >
                                                <Grid
                                                    item
                                                    xs={2}
                                                    onClick={(e) => {
                                                        currentPortal ===
                                                            'BUYER' &&
                                                        checkPermission(
                                                            'BUYER',
                                                            'QUALITY_CHECK',
                                                            'VIEW',
                                                            null
                                                        )
                                                            ? redirectToQCDetails(
                                                                  qc.batchUid!
                                                              )
                                                            : e.preventDefault();
                                                    }}
                                                >
                                                    <FWTypography
                                                        color={
                                                            currentPortal ===
                                                                'BUYER' &&
                                                            checkPermission(
                                                                'BUYER',
                                                                'QUALITY_CHECK',
                                                                'VIEW',
                                                                null
                                                            )
                                                                ? 'primary.main'
                                                                : undefined
                                                        }
                                                        sx={{
                                                            cursor:
                                                                currentPortal ===
                                                                    'BUYER' &&
                                                                checkPermission(
                                                                    'BUYER',
                                                                    'QUALITY_CHECK',
                                                                    'VIEW',
                                                                    null
                                                                )
                                                                    ? 'pointer'
                                                                    : 'initial',
                                                        }}
                                                    >
                                                        {qc.acceptedARN}
                                                    </FWTypography>
                                                    <FWTypography
                                                        color={
                                                            currentPortal ===
                                                                'BUYER' &&
                                                            checkPermission(
                                                                'BUYER',
                                                                'QUALITY_CHECK',
                                                                'VIEW',
                                                                null
                                                            )
                                                                ? 'primary.main'
                                                                : undefined
                                                        }
                                                        sx={{
                                                            cursor:
                                                                currentPortal ===
                                                                    'BUYER' &&
                                                                checkPermission(
                                                                    'BUYER',
                                                                    'QUALITY_CHECK',
                                                                    'VIEW',
                                                                    null
                                                                )
                                                                    ? 'pointer'
                                                                    : 'initial',
                                                        }}
                                                    >
                                                        {qc.rejectedARN}
                                                    </FWTypography>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <FWTypography>
                                                        {qc.batchId}
                                                    </FWTypography>
                                                </Grid>
                                                <Grid item xs={2.5}>
                                                    <FWTypography color="success.main">
                                                        {qc.acceptedQty}{' '}
                                                        {qc.measurementUnit}(s)
                                                    </FWTypography>
                                                </Grid>
                                                <Grid item xs={2.5}>
                                                    <FWTypography color="error.main">
                                                        {qc.rejectedQty}{' '}
                                                        {qc.measurementUnit}(s)
                                                    </FWTypography>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <FWTypography>
                                                        {qc.totalQty}{' '}
                                                        {qc.measurementUnit}(s)
                                                    </FWTypography>
                                                </Grid>
                                            </Grid>
                                        );
                                    })}
                            </Grid>
                        </Grid>
                    )}
                    <Grid container spacing={1} marginTop="5px">
                        {totalRejections.num > 0 && (
                            <>
                                <Grid item xs={12}>
                                    <Divider />
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={2}>
                                        {terminatedQuantity &&
                                            +terminatedQuantity > 0 && (
                                                <>
                                                    <Grid item xs={6.5}>
                                                        <Typography
                                                            whiteSpace="nowrap"
                                                            fontWeight={500}
                                                        >
                                                            Terminated quantity
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={2.5}>
                                                        <Typography
                                                            whiteSpace="nowrap"
                                                            color="error.main"
                                                        >
                                                            {FWNumberFormatter(
                                                                +terminatedQuantity
                                                            )}{' '}
                                                            {
                                                                totalRejections.measure
                                                            }
                                                            (s)
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Divider />
                                                    </Grid>
                                                </>
                                            )}
                                        <Grid item xs={6.5}>
                                            <Typography
                                                whiteSpace="nowrap"
                                                fontWeight={500}
                                            >
                                                Total rejections
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2.5}>
                                            <Typography
                                                whiteSpace="nowrap"
                                                fontWeight={500}
                                            >
                                                {FWNumberFormatter(
                                                    totalRejections.num
                                                )}{' '}
                                                {totalRejections.measure}
                                                (s)
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </>
                        )}
                    </Grid>
                </>
            )}
            {isLoading && (
                <LinearProgress
                    color="primary"
                    sx={{
                        my: 2,
                    }}
                />
            )}
        </div>
    );
};

export default ItemRejectionDetails;
