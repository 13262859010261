import produce from 'immer';
import { ISetupWizardState } from '../Slices/SetupWizardSlice';

export enum SetupWizardActions {
    UPDATE_ENTITY = 'UPDATE_ENTITY',
}

export interface IEntityAction {
    type: SetupWizardActions.UPDATE_ENTITY;
    value: string;
}

export interface ISetupWizardActions {
    type: string;
    payload: IEntityAction;
}

export const updateSetupWizardDetailsReducer = produce(
    (state: ISetupWizardState, action: ISetupWizardActions) => {
        switch (action.payload.type) {
            case SetupWizardActions.UPDATE_ENTITY:
                state.setupEntityUid = action.payload.value;
                break;
        }
    }
);
