import { cloneDeep } from 'lodash';
import { IDocumentDetails, INewDocument } from './Document.model';
import {
    CustomFieldType,
    IDocumentTemplate,
    ISectionField,
} from './Interfaces/documentTemplate';

export const convertTemplateCFToPayload = (
    templateCF: ISectionField,
    templateDetails: IDocumentTemplate
) => {
    const customFieldInfoInTemplate =
        templateDetails.fieldSettings.customFields[templateCF.field];
    switch (customFieldInfoInTemplate?.constraints?.fieldType) {
        case CustomFieldType.INTEGER:
            return {
                name: customFieldInfoInTemplate.label,
                value: '',
                type: CustomFieldType.INTEGER,
                description: customFieldInfoInTemplate.description,
                is_locked: true,
                is_visible: customFieldInfoInTemplate.sellerSettings.showField,
                is_negotiable:
                    customFieldInfoInTemplate.sellerSettings.negotiable,
                is_required: customFieldInfoInTemplate.buyerSettings.required,
            };
        case CustomFieldType.BOOLEAN:
            return {
                name: customFieldInfoInTemplate.label,
                value: null,
                type: CustomFieldType.BOOLEAN,
                description: customFieldInfoInTemplate.description,
                is_locked: true,
                is_required: customFieldInfoInTemplate.buyerSettings.required,
                is_visible: customFieldInfoInTemplate.sellerSettings.showField,
                is_negotiable:
                    customFieldInfoInTemplate.sellerSettings.negotiable,
            };
        case 'SHORTTEXT':
            return {
                name: customFieldInfoInTemplate.label,
                value: null,
                type: CustomFieldType.SHORTTEXT,
                description: customFieldInfoInTemplate.description,
                is_locked: true,
                is_required: customFieldInfoInTemplate.buyerSettings.required,
                is_visible: customFieldInfoInTemplate.sellerSettings.showField,
                is_negotiable:
                    customFieldInfoInTemplate.sellerSettings.negotiable,
            };
        case 'LONGTEXT':
            return {
                name: customFieldInfoInTemplate.label,
                value: null,
                description: customFieldInfoInTemplate.description,
                type: CustomFieldType.LONGTEXT,
                is_locked: true,
                is_required: customFieldInfoInTemplate.buyerSettings.required,
                is_visible: customFieldInfoInTemplate.sellerSettings.showField,
                is_negotiable:
                    customFieldInfoInTemplate.sellerSettings.negotiable,
            };
        case 'DATE':
        case 'DATETIME':
            return {
                name: customFieldInfoInTemplate.label,
                value: null,
                type: CustomFieldType.DATE,
                description: customFieldInfoInTemplate.description,

                is_locked: true,
                is_required: customFieldInfoInTemplate.buyerSettings.required,
                is_visible: customFieldInfoInTemplate.sellerSettings.showField,
                is_negotiable:
                    customFieldInfoInTemplate.sellerSettings.negotiable,
            };

        case 'FLOAT':
            return {
                name: customFieldInfoInTemplate.label,
                value: '',
                type: CustomFieldType.FLOAT,
                description: customFieldInfoInTemplate.description,
                is_locked: true,
                is_required: customFieldInfoInTemplate.buyerSettings.required,
                is_visible: customFieldInfoInTemplate.sellerSettings.showField,
                is_negotiable:
                    customFieldInfoInTemplate.sellerSettings.negotiable,
            };

        case 'CHOICE':
            if (
                customFieldInfoInTemplate.constraints.choiceType === 'DROPDOWN'
            ) {
                return {
                    name: customFieldInfoInTemplate.label,
                    value: null,
                    type: 'CHOICE',
                    is_locked: true,
                    is_required:
                        customFieldInfoInTemplate.buyerSettings.required,
                    is_visible:
                        customFieldInfoInTemplate.sellerSettings.showField,
                    is_negotiable:
                        customFieldInfoInTemplate.sellerSettings.negotiable,
                    description: customFieldInfoInTemplate.description,
                };
            } else {
                return {
                    name: customFieldInfoInTemplate.label,
                    value: [],
                    description: customFieldInfoInTemplate.description,
                    type: 'MULTI_CHOICE',

                    is_locked: true,
                    is_required:
                        customFieldInfoInTemplate.buyerSettings.required,
                    is_visible:
                        customFieldInfoInTemplate.sellerSettings.showField,
                    is_negotiable:
                        customFieldInfoInTemplate.sellerSettings.negotiable,
                };
            }
        case 'ATTACHMENT':
            return {
                name: customFieldInfoInTemplate.label,
                value: null,
                type: CustomFieldType.ATTACHMENT,
                description: customFieldInfoInTemplate.description,

                is_locked: true,
                is_required: customFieldInfoInTemplate.buyerSettings.required,
                is_visible: customFieldInfoInTemplate.sellerSettings.showField,
                is_negotiable:
                    customFieldInfoInTemplate.sellerSettings.negotiable,
            };
        case 'TEMPLATE':
            return {
                name: customFieldInfoInTemplate.label,
                value: null,
                type: CustomFieldType.TEMPLATE,
                description: customFieldInfoInTemplate.description,

                is_locked: true,
                is_required: customFieldInfoInTemplate.buyerSettings.required,
                is_visible: customFieldInfoInTemplate.sellerSettings.showField,
                is_negotiable:
                    customFieldInfoInTemplate.sellerSettings.negotiable,
            };
        default:
            return {
                name: customFieldInfoInTemplate.label,
                value: null,
                type: CustomFieldType.SHORTTEXT,
                description: customFieldInfoInTemplate.description,
                is_locked: true,
                is_required: customFieldInfoInTemplate.buyerSettings.required,
                is_visible: customFieldInfoInTemplate.sellerSettings.showField,
                is_negotiable:
                    customFieldInfoInTemplate.sellerSettings.negotiable,
            };
    }
};

const fillSectionsInCustomFields = (
    documentDetails: IDocumentDetails,
    templateDetails: IDocumentTemplate
) => {
    let newDocumentDetails = cloneDeep(documentDetails);

    const sectionNames = Object.keys(templateDetails.sections);

    sectionNames.forEach((sectionInTemplate) => {
        const sectionName = templateDetails.sections[sectionInTemplate].label;

        if (
            !newDocumentDetails.custom_fields.section_list?.some(
                (section) => section.name === sectionName
            )
        ) {
            newDocumentDetails.custom_fields.section_list.push({
                fields: [],
                name: sectionName,
            });
        }
        if (
            !newDocumentDetails.custom_fields_negotiate.section_list?.some(
                (section) => section.name === sectionName
            )
        ) {
            newDocumentDetails.custom_fields_negotiate.section_list.push({
                fields: [],
                name: sectionName,
            });
        }
    });
    return newDocumentDetails;
};

export const appendDocumentTemplateCustomFieldsInPayload = (
    documentDetails: IDocumentDetails,
    templateDetails: IDocumentTemplate
) => {
    const updatedDocumentDetails = fillSectionsInCustomFields(
        documentDetails,
        templateDetails
    );

    // Neg -> is_negotiable = true
    let customFieldsNonNegInDocument = updatedDocumentDetails.custom_fields;
    let customFieldNegInDocument =
        updatedDocumentDetails.custom_fields_negotiate;

    customFieldNegInDocument.section_list?.forEach((currSection) => {
        const tempCFCurrSection =
            currSection.name === templateDetails.sections.DOCUMENT_DETAIL.label
                ? templateDetails.sections.DOCUMENT_DETAIL.fieldList?.filter(
                      (field) => field.fieldType === 'CUSTOM'
                  ) ?? []
                : templateDetails.sections[currSection.name]?.fieldList?.filter(
                      (field) => field.fieldType === 'CUSTOM'
                  ) ?? [];

        // missing custom fields for that section
        const missingCF =
            tempCFCurrSection?.filter(
                (field) =>
                    !currSection.fields.some(
                        (customField) => customField.name === field.field
                    )
            ) ?? [];

        missingCF.forEach((tempCF) => {
            const fieldSetting =
                templateDetails.fieldSettings.customFields[tempCF.field];

            const isFieldNegotiable = fieldSetting.sellerSettings.negotiable;
            if (isFieldNegotiable) {
                const fieldForPayload = convertTemplateCFToPayload(
                    tempCF,
                    templateDetails
                );

                currSection.fields.push(fieldForPayload);
            }
        });
    });

    customFieldsNonNegInDocument.section_list?.forEach((currSection) => {
        const tempCFCurrSection =
            currSection.name === templateDetails.sections.DOCUMENT_DETAIL.label
                ? templateDetails.sections.DOCUMENT_DETAIL.fieldList.filter(
                      (field) => field.fieldType === 'CUSTOM'
                  ) ?? []
                : templateDetails.sections[currSection.name]?.fieldList?.filter(
                      (field) => field.fieldType === 'CUSTOM'
                  ) ?? [];

        // missing custom fields for that section
        const missingCF = tempCFCurrSection?.filter(
            (field) =>
                !currSection.fields.some(
                    (customField) => customField.name === field.field
                )
        );

        missingCF.forEach((tempCF) => {
            const fieldSetting =
                templateDetails.fieldSettings.customFields[tempCF.field];

            const isFieldNegotiable = fieldSetting.sellerSettings.negotiable;

            if (!isFieldNegotiable) {
                const fieldForPayload = convertTemplateCFToPayload(
                    tempCF,
                    templateDetails
                );

                currSection.fields.push(fieldForPayload);
            }
        });
    });

    return { customFieldNegInDocument, customFieldsNonNegInDocument };
};

export const getAttachmentIdsFromCF = (documentDetails: INewDocument) => {
    let attachmentIds: string[] = [];

    documentDetails.custom_fields.section_list.forEach((section) => {
        section.fields.forEach((field) => {
            if (['TEMPLATE', 'ATTACHMENT'].includes(field.type)) {
                if (field.value) {
                    attachmentIds.push(...((field.value ?? []) as string[]));
                }
            }
        });
    });
    documentDetails.custom_fields_negotiate.section_list.forEach((section) => {
        section.fields.forEach((field) => {
            if (['TEMPLATE', 'ATTACHMENT'].includes(field.type)) {
                if (field.value) {
                    attachmentIds.push(...((field.value ?? []) as string[]));
                }
            }
        });
    });

    return attachmentIds;
};

export const getAttachmentIdsFromCFSellerSide = (
    sellerDocument: INewDocument,
    buyerDocument: INewDocument
) => {
    let attachmentIds: string[] = [];
    let newSellerDoc = cloneDeep(sellerDocument);

    newSellerDoc.custom_fields.section_list.forEach((section) => {
        section.fields.forEach((field) => {
            if (['TEMPLATE'].includes(field.type)) {
                const fieldValueInBuyerDoc =
                    buyerDocument.custom_fields.section_list
                        .find(
                            (buyerSection) => buyerSection.name === section.name
                        )
                        ?.fields?.find(
                            (buyerField) => buyerField.name === field.name
                        );
                if (field.value) {
                    attachmentIds.push(...((field.value ?? []) as string[]));
                    field.value = (field.value as string[])?.filter(
                        (id) =>
                            !(
                                (fieldValueInBuyerDoc?.value ?? []) as string[]
                            ).some((buyerID) => id === buyerID)
                    );
                }

                if (fieldValueInBuyerDoc) {
                    attachmentIds = attachmentIds.filter(
                        (id) =>
                            !(
                                (fieldValueInBuyerDoc?.value ?? []) as string[]
                            ).some((buyerID) => id === buyerID)
                    );
                }
            }
        });
    });
    newSellerDoc.custom_fields_negotiate.section_list.forEach((section) => {
        section.fields.forEach((field) => {
            const fieldValueInBuyerDoc =
                buyerDocument.custom_fields_negotiate.section_list
                    .find((buyerSection) => buyerSection.name === section.name)
                    ?.fields?.find(
                        (buyerField) => buyerField.name === field.name
                    );
            if (['TEMPLATE'].includes(field.type)) {
                if (field.value) {
                    attachmentIds.push(...((field.value ?? []) as string[]));
                    field.value = (field.value as string[]).filter(
                        (id) =>
                            !(
                                (fieldValueInBuyerDoc?.value ?? []) as string[]
                            ).some((buyerID) => id === buyerID)
                    );
                }

                if (fieldValueInBuyerDoc) {
                    attachmentIds = attachmentIds.filter(
                        (id) =>
                            !(
                                (fieldValueInBuyerDoc?.value ?? []) as string[]
                            ).some((buyerID) => id === buyerID)
                    );
                }
            }
        });
    });

    return attachmentIds;
};
export const getAttachmentIdsFromCFSellerSide2 = (
    sellerDocument: INewDocument,
    buyerDocument: IDocumentDetails
) => {
    let attachmentIds: string[] = [];
    let newSellerDoc = cloneDeep(sellerDocument);

    newSellerDoc.custom_fields.section_list.forEach((section) => {
        section.fields.forEach((field) => {
            if (['TEMPLATE'].includes(field.type)) {
                const fieldValueInBuyerDoc =
                    buyerDocument.custom_fields.section_list
                        .find(
                            (buyerSection) => buyerSection.name === section.name
                        )
                        ?.fields?.find(
                            (buyerField) => buyerField.name === field.name
                        );
                if (field.value) {
                    attachmentIds.push(...((field.value ?? []) as string[]));
                    field.value = (field.value as string[])?.filter(
                        (id) =>
                            !(
                                (fieldValueInBuyerDoc?.value ?? []) as string[]
                            ).some((buyerID) => id === buyerID)
                    );
                }

                if (fieldValueInBuyerDoc) {
                    attachmentIds = attachmentIds.filter(
                        (id) =>
                            !(
                                (fieldValueInBuyerDoc?.value ?? []) as string[]
                            ).some((buyerID) => id === buyerID)
                    );
                }
            }
        });
    });
    newSellerDoc.custom_fields_negotiate.section_list.forEach((section) => {
        section.fields.forEach((field) => {
            const fieldValueInBuyerDoc =
                buyerDocument.custom_fields_negotiate.section_list
                    .find((buyerSection) => buyerSection.name === section.name)
                    ?.fields?.find(
                        (buyerField) => buyerField.name === field.name
                    );
            if (['TEMPLATE'].includes(field.type)) {
                if (field.value) {
                    attachmentIds.push(...((field.value ?? []) as string[]));
                    field.value = (field.value as string[]).filter(
                        (id) =>
                            !(
                                (fieldValueInBuyerDoc?.value ?? []) as string[]
                            ).some((buyerID) => id === buyerID)
                    );
                }

                if (fieldValueInBuyerDoc) {
                    attachmentIds = attachmentIds.filter(
                        (id) =>
                            !(
                                (fieldValueInBuyerDoc?.value ?? []) as string[]
                            ).some((buyerID) => id === buyerID)
                    );
                }
            }
        });
    });

    return attachmentIds;
};
