import {
    CircularProgress,
    DialogActions,
    DialogContent,
    IconButton,
    Typography,
} from '@mui/material';
import React, { useMemo } from 'react';
import { toast } from 'react-toastify';
import { FWButton } from './FWButton';
import { FWInput } from './FWInput';
import { CustomDialog } from './FWPopup';

interface IPopupWithNotes {
    open: boolean;
    handleClose: () => void;
    onClickConfirm(msg: string): void;
    title: string;
    msg: string;
    defaultTextValue?: string;
    mandatory?: boolean;
    btnTitle?: string;
    btnColor?: 'primary' | 'error';
    secondaryBtnTitle?: string;
    placeholderText?: string;
    helperText?: string;
    maxLimit?: number;
}

export default function FWPopupWithNotes({
    placeholderText,
    open,
    handleClose,
    onClickConfirm,
    title,
    msg,
    maxLimit,
    helperText = '',
    defaultTextValue,
    mandatory = false,
    btnTitle = 'Confirm',
    secondaryBtnTitle = 'Cancel',
    btnColor = 'primary',
}: IPopupWithNotes) {
    const [comment, setComment] = React.useState('');
    const [isLoading, setIsLoading] = React.useState(false);

    React.useEffect(
        () =>
            setComment(
                typeof defaultTextValue === 'undefined'
                    ? ''
                    : defaultTextValue
                    ? defaultTextValue
                    : ''
            ),
        [defaultTextValue]
    );

    const disableSaveButton = useMemo(() => {
        return !Boolean(comment.trim()) && mandatory;
    }, [comment, mandatory]);

    const handleExcludeItemNotePopupClose = () => {
        setComment('');
        handleClose();
    };

    const onSubmit = async () => {
        if (comment!.trim() === '' && mandatory) {
            toast.error('Please enter a message to proceed');
        } else {
            setIsLoading(true); // Start the loader
            try {
                await onClickConfirm(comment!.trim());
                handleExcludeItemNotePopupClose();
            } catch (error) {
                toast.error('Something went wrong.');
            } finally {
                setIsLoading(false); // Stop the loader
            }
        }
    };

    return (
        <CustomDialog open={open}>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: '24px 24px 0px 24px',
                }}
            >
                <Typography variant="h4">{title}</Typography>
                <IconButton
                    size="small"
                    onClick={handleExcludeItemNotePopupClose}
                >
                    <i className="bi bi-x" style={{ fontSize: '2rem' }} />
                </IconButton>
            </div>
            <DialogContent sx={{ padding: '0px 24px 12px 24px' }}>
                {msg && (
                    <>
                        <Typography>{msg}</Typography>
                        <br />
                    </>
                )}
                <FWInput
                    multiline
                    required={mandatory}
                    error={disableSaveButton}
                    helper={{
                        text: disableSaveButton ? helperText ?? '' : '',
                    }}
                    value={comment}
                    onChange={(e: any) => setComment(e.target.value)}
                    placeholder={placeholderText}
                    {...(maxLimit
                        ? {
                              inputProps: {
                                  maxLength: maxLimit,
                              },
                          }
                        : {})}
                />
            </DialogContent>
            <DialogActions sx={{ padding: '12px 24px 24px 24px' }}>
                <FWButton
                    variant="outlined"
                    color={btnColor}
                    onClick={handleExcludeItemNotePopupClose}
                    disabled={isLoading}
                >
                    {secondaryBtnTitle}
                </FWButton>
                <FWButton
                    disabled={disableSaveButton || isLoading}
                    variant="contained"
                    color={btnColor}
                    onClick={onSubmit}
                >
                    {isLoading ? (
                        <CircularProgress size={24} color="inherit" />
                    ) : (
                        btnTitle
                    )}
                </FWButton>
            </DialogActions>
        </CustomDialog>
    );
}
