import React from 'react';
import { FWInput } from '../../../../Common/FWInput';
import { Box } from '@mui/material';

const VLNProfileItemCatalogSearchBar = ({
    setSearchText,
}: {
    setSearchText: React.Dispatch<React.SetStateAction<string>>;
}) => {
    return (
        <Box className="flex flex--aic " marginBottom={4} gap={1}>
            <FWInput
                placeholder="Search catalogue items"
                sx={{ width: '50%' }}
                iconStart={<i className="bi bi-search"></i>}
                onChange={(e) => setSearchText(e.target.value)}
            />
            {/* <IconButton size={'small'} color={'primary'}>
                <i className="bi bi-funnel"></i>
            </IconButton>
            <IconButton size={'small'} color={'primary'}>
                <i className="bi bi-sort-alpha-down"></i>
            </IconButton> */}
        </Box>
    );
};

export default VLNProfileItemCatalogSearchBar;
