import {
    Box,
    Checkbox,
    DialogActions,
    DialogContent,
    FormControlLabel,
    ListItemText,
    MenuItem,
    Select,
    Switch,
    ToggleButton,
    ToggleButtonGroup,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { FWButton } from '../../Common/FWButton';
import { FWMenuItem } from '../../Common/FWCustomMenu';
import { FWTooltip } from '../../Common/FWCustomTooltip';
import { FWPopupWithChildren } from '../../Common/FWPopupWithChildren';
import FWSelect, { OpenDownIcon } from '../../Common/FWSelect';
import { FWTypography } from '../../Common/FWTypograhy';
import { HookStateValue } from '../../Common/Hooks/StateHook';
import LoadingBackDrop from '../../Components/Shared/LoadingBackdrop';
import useQuickUserApprovals from '../Hooks/useQuickUserApproval';
import { IInvitee, inviteeRoleList } from '../Interfaces/NavbarUsersInterfaces';

interface IQuickApproveUserPopupProps {
    isOpen: boolean;
    onClose: () => void;
    onSuccess: () => void;
    focusUserId?: string;
}

export default function QuickApproveUserPopup(
    props: IQuickApproveUserPopupProps
) {
    const handleClose = () => {
        props.onClose();
    };

    const {
        hookState,
        allEntities,
        allEnterpriseUsers,
        pendingUserList,
        toggleSkipOnboarding,
        editManagers,
        toggleAdmin,
        changeRole,
        updateEntities,
        editDecision,
        confirmDecisionForOne,
        confirmDecisions,
        sendingDecisions,
        resetForm,
    } = useQuickUserApprovals();

    const [filterUsers, setFilterUsers] = useState(false);
    const filterIsActive =
        filterUsers && props.focusUserId && pendingUserList.length > 1;

    useEffect(() => {
        if (props.isOpen) {
            resetForm();
            setFilterUsers(true);
        }
    }, [props.isOpen, resetForm]);

    const rowIsValid = (u: IInvitee) => {
        return u.isAdmin || (u.entities.length > 0 && u.role !== null);
    };

    const approvingInvalidRow =
        pendingUserList.filter(
            (u) => u.decision === 'APPROVE' && !rowIsValid(u)
        ).length > 0;

    const noDecisionsTaken =
        pendingUserList.filter((u) => u.decision !== 'PENDING').length === 0;

    const disableConfirm = approvingInvalidRow || noDecisionsTaken;

    return (
        <FWPopupWithChildren
            open={props.isOpen}
            handleClose={handleClose}
            title={
                filterIsActive
                    ? 'Approve join request'
                    : 'Approve join requests'
            }
            size={'custom'}
            popupWidth={'80%'}
        >
            {(hookState.state === HookStateValue.LOADING ||
                sendingDecisions) && <LoadingBackDrop />}
            {hookState.state === HookStateValue.READY && (
                <>
                    <DialogContent sx={{ padding: '24px' }}>
                        <Box
                            display="grid"
                            flex={1}
                            alignItems="center"
                            columnGap={1.25}
                            rowGap={1.25}
                            gridTemplateColumns={'14% 15% 19% 19% 19% 12%'}
                        >
                            <Box>
                                <FWTypography variant="h5">Name</FWTypography>
                            </Box>
                            <Box>
                                <FWTypography variant="h5">Email</FWTypography>
                            </Box>
                            <Box>
                                <FWTypography variant="h5">
                                    Manager(s)
                                </FWTypography>
                            </Box>
                            <Box>
                                <FWTypography variant="h5">Role</FWTypography>
                            </Box>
                            <Box>
                                <FWTypography variant="h5">
                                    Entities
                                </FWTypography>
                            </Box>
                            <Box></Box>

                            {pendingUserList.map((pendingUser, index) => {
                                if (
                                    !props.focusUserId ||
                                    !filterUsers ||
                                    pendingUser.user_id === props.focusUserId
                                ) {
                                    return (
                                        <React.Fragment key={index}>
                                            <Box
                                                sx={{
                                                    position: 'relative',
                                                    top: 22,
                                                }}
                                            >
                                                <FWTypography>
                                                    {pendingUser.name}
                                                </FWTypography>
                                                <FormControlLabel
                                                    sx={{
                                                        color: 'text.secondary',
                                                    }}
                                                    control={
                                                        <Checkbox
                                                            checked={
                                                                pendingUser.skip_onboarding
                                                            }
                                                            name="skip_onboarding"
                                                            onChange={() => {
                                                                toggleSkipOnboarding(
                                                                    index
                                                                );
                                                            }}
                                                        />
                                                    }
                                                    label="Skip onboarding"
                                                />
                                            </Box>
                                            {/* EMAIL */}
                                            <Box>
                                                <FWTypography>
                                                    {pendingUser.email}
                                                </FWTypography>
                                            </Box>
                                            {/* MANAGER */}
                                            <Box>
                                                <Select
                                                    size="small"
                                                    fullWidth
                                                    multiple
                                                    value={pendingUser.managers}
                                                    onChange={(event) => {
                                                        const {
                                                            target: { value },
                                                        } = event;
                                                        editManagers(
                                                            index,
                                                            // On autofill we get a stringified value.
                                                            typeof value ===
                                                                'string'
                                                                ? value.split(
                                                                      ','
                                                                  )
                                                                : value
                                                        );
                                                    }}
                                                    renderValue={(selected) =>
                                                        allEnterpriseUsers
                                                            .filter(
                                                                (u) =>
                                                                    selected.indexOf(
                                                                        u.user_id
                                                                    ) > -1
                                                            )
                                                            .map((u) => u.name)
                                                            .join(', ')
                                                    }
                                                    MenuProps={{
                                                        PaperProps: {
                                                            sx: {
                                                                bgcolor:
                                                                    'white',
                                                                maxHeight:
                                                                    '300px',
                                                            },
                                                        },
                                                    }}
                                                    IconComponent={OpenDownIcon}
                                                >
                                                    {allEnterpriseUsers.map(
                                                        (u) => (
                                                            <MenuItem
                                                                key={u.user_id}
                                                                value={
                                                                    u.user_id
                                                                }
                                                            >
                                                                <Checkbox
                                                                    checked={
                                                                        pendingUser.managers.indexOf(
                                                                            u.user_id
                                                                        ) > -1
                                                                    }
                                                                    sx={{
                                                                        '&.MuiCheckbox-root':
                                                                            {
                                                                                padding:
                                                                                    '0px 9px',
                                                                            },
                                                                    }}
                                                                />
                                                                <ListItemText
                                                                    primary={
                                                                        u.name
                                                                    }
                                                                />
                                                            </MenuItem>
                                                        )
                                                    )}
                                                </Select>
                                            </Box>
                                            {/* ADMIN */}
                                            <Box sx={{ display: 'none' }}>
                                                <Switch
                                                    checked={
                                                        pendingUser.isAdmin
                                                    }
                                                    onChange={() => {
                                                        toggleAdmin(index);
                                                    }}
                                                />
                                            </Box>
                                            {/* ROLE */}
                                            <Box>
                                                <FWSelect
                                                    value={pendingUser.role}
                                                    fullWidth
                                                    // disabled={pendingUser.isAdmin}
                                                >
                                                    {inviteeRoleList.map(
                                                        (role, index2) => {
                                                            return (
                                                                <FWMenuItem
                                                                    key={index2}
                                                                    value={
                                                                        role.value
                                                                    }
                                                                    onClick={() => {
                                                                        if (
                                                                            index2 ===
                                                                            0
                                                                        ) {
                                                                            toggleAdmin(
                                                                                index
                                                                            );
                                                                        } else {
                                                                            changeRole(
                                                                                index,
                                                                                role.value
                                                                            );
                                                                        }
                                                                    }}
                                                                >
                                                                    {role.label}
                                                                </FWMenuItem>
                                                            );
                                                        }
                                                    )}
                                                </FWSelect>
                                            </Box>
                                            {/* ENTITIES */}
                                            <Box>
                                                <Select
                                                    size="small"
                                                    fullWidth
                                                    labelId="demo-multiple-checkbox-label"
                                                    id="demo-multiple-checkbox"
                                                    multiple
                                                    value={pendingUser.entities}
                                                    error={
                                                        !pendingUser.isAdmin &&
                                                        pendingUser.decision !==
                                                            'REJECT' &&
                                                        pendingUser.entities
                                                            .length === 0
                                                    }
                                                    disabled={
                                                        pendingUser.isAdmin ||
                                                        (allEntities.length <
                                                            2 &&
                                                            pendingUser.entities
                                                                .length !==
                                                                0) ||
                                                        allEntities?.length ===
                                                            1
                                                    }
                                                    onChange={(event) => {
                                                        const {
                                                            target: { value },
                                                        } = event;
                                                        updateEntities(
                                                            index,
                                                            // On autofill we get a stringified value.
                                                            typeof value ===
                                                                'string'
                                                                ? value.split(
                                                                      ','
                                                                  )
                                                                : value
                                                        );
                                                    }}
                                                    renderValue={(selected) =>
                                                        allEntities
                                                            .filter(
                                                                (entity) =>
                                                                    selected.indexOf(
                                                                        entity.entityId
                                                                    ) > -1
                                                            )
                                                            .map(
                                                                (entity) =>
                                                                    entity.entityName
                                                            )
                                                            .join(', ')
                                                    }
                                                    MenuProps={{
                                                        PaperProps: {
                                                            sx: {
                                                                bgcolor:
                                                                    'white',
                                                                maxHeight:
                                                                    '300px',
                                                            },
                                                        },
                                                    }}
                                                    IconComponent={OpenDownIcon}
                                                >
                                                    {allEntities.map(
                                                        (entity) => (
                                                            <MenuItem
                                                                key={
                                                                    entity.entityId
                                                                }
                                                                value={
                                                                    entity.entityId
                                                                }
                                                            >
                                                                <Checkbox
                                                                    checked={
                                                                        pendingUser.entities.indexOf(
                                                                            entity.entityId
                                                                        ) > -1
                                                                    }
                                                                    sx={{
                                                                        '&.MuiCheckbox-root':
                                                                            {
                                                                                padding:
                                                                                    '0px 9px',
                                                                            },
                                                                    }}
                                                                />
                                                                <ListItemText
                                                                    primary={
                                                                        entity.entityName
                                                                    }
                                                                />
                                                            </MenuItem>
                                                        )
                                                    )}
                                                </Select>
                                            </Box>
                                            {/* Add/Remove user */}
                                            <Box className="flex flex--aic">
                                                <ToggleButtonGroup
                                                    value={pendingUser.decision}
                                                    exclusive
                                                    aria-label="text alignment"
                                                    size="small"
                                                >
                                                    <ToggleButton
                                                        value="REJECT"
                                                        color="error"
                                                        selected={
                                                            pendingUser.decision ===
                                                            'REJECT'
                                                        }
                                                        onClick={() => {
                                                            editDecision(
                                                                index,
                                                                'REJECT'
                                                            );
                                                        }}
                                                        sx={{ padding: '10px' }}
                                                    >
                                                        <FWTooltip title="Reject request">
                                                            <Box>Reject</Box>
                                                        </FWTooltip>
                                                    </ToggleButton>
                                                    <ToggleButton
                                                        value="APPROVE"
                                                        color="success"
                                                        onClick={() => {
                                                            editDecision(
                                                                index,
                                                                'APPROVE'
                                                            );
                                                        }}
                                                        selected={
                                                            pendingUser.decision ===
                                                            'APPROVE'
                                                        }
                                                        sx={{ padding: '10px' }}
                                                    >
                                                        <FWTooltip title="Approve request">
                                                            <Box>Approve</Box>
                                                        </FWTooltip>
                                                    </ToggleButton>
                                                </ToggleButtonGroup>
                                            </Box>
                                        </React.Fragment>
                                    );
                                }
                                return <></>;
                            })}
                        </Box>
                        {filterIsActive && (
                            <Box className="flex flex--jcc" marginTop={'1rem'}>
                                <FWButton
                                    onClick={() => {
                                        setFilterUsers(false);
                                    }}
                                    variant="outlined"
                                    color="primary"
                                >
                                    {`View other pending requests (${
                                        pendingUserList.length - 1
                                    })`}
                                </FWButton>
                            </Box>
                        )}
                    </DialogContent>
                    <DialogActions sx={{ padding: '12px 24px 24px 24px' }}>
                        <FWButton
                            onClick={handleClose}
                            color="primary"
                            variant="outlined"
                            sx={{ marginRight: '1rem' }}
                        >
                            Cancel
                        </FWButton>
                        <FWButton
                            onClick={() => {
                                if (filterIsActive) {
                                    if (props.focusUserId) {
                                        const FocusedInvitee =
                                            pendingUserList.find(
                                                (pendingUser, index) =>
                                                    pendingUser.user_id ===
                                                    props.focusUserId
                                            );
                                        if (FocusedInvitee) {
                                            confirmDecisionForOne(
                                                FocusedInvitee
                                            ).then(() => {
                                                props.onSuccess();
                                            });
                                        }
                                    }
                                } else {
                                    confirmDecisions().then(() => {
                                        props.onSuccess();
                                    });
                                }
                            }}
                            color="primary"
                            autoFocus
                            variant="contained"
                            disabled={disableConfirm}
                        >
                            Confirm
                        </FWButton>
                    </DialogActions>
                </>
            )}
        </FWPopupWithChildren>
    );
}
