import { cloneDeep } from 'lodash';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { HookStateValue, useHookState } from '../../Common/Hooks/StateHook';
import { AuthContext } from '../../Contexts/AuthContext';
import { IUserDetails } from '../../Models/UserDetails.model';
import { IEntity } from '../../Organizations/Admin/Interfaces/EnterpriseInterface';
import { fetchEntities } from '../../Organizations/Admin/Services/EnterpriseService';
import { IInvitee, inviteeRole } from '../Interfaces/NavbarUsersInterfaces';
import {
    decideJoinRequests,
    getAllEnterpriseUsers,
    getApprovalPendingUsers,
} from '../Services/QuickUserActionsService';

export default function useQuickUserApprovals() {
    const { hookState, updateHookState } = useHookState(HookStateValue.LOADING);

    const [pendingUserListOriginal, setPendingUserListOriginal] = useState<
        IInvitee[]
    >([]);
    const [pendingUserList, setPendingUserList] = useState<IInvitee[]>([]);
    const [allEnterpriseUsers, setAllEnterpriseUsers] = useState<
        IUserDetails[]
    >([]);
    const [allEntities, setAllEntities] = useState<IEntity[]>([]);

    const [sendingDecisions, setSendingDecisions] = useState(false);

    const { authData } = useContext(AuthContext);

    const isSellerAccount = useMemo(() => {
        return (
            authData.details &&
            Object.keys(authData.details).includes('invitation_type')
        );
    }, [authData.details]);

    const fetchData = useCallback(async () => {
        try {
            updateHookState(HookStateValue.LOADING);
            const [entitiesResp, allEnterpriseUsersResp, pendingUsersResp] =
                await Promise.all([
                    fetchEntities(),
                    getAllEnterpriseUsers(),
                    getApprovalPendingUsers(),
                ]);

            setPendingUserList(
                pendingUsersResp.map((u) => {
                    return {
                        user_id: u.user_id,
                        email: u.email,
                        isAdmin: u?.role === 'ADMIN',
                        managers:
                            authData &&
                            authData.details &&
                            authData.details.user_id
                                ? [authData.details.user_id]
                                : [],
                        name: u.name,
                        role: isSellerAccount
                            ? 'SALES'
                            : u.entity_list[0]?.role,
                        entities:
                            u?.entity_list?.length === 0 &&
                            entitiesResp?.length === 1
                                ? [entitiesResp[0].entityId]
                                : u.entity_list.map(
                                      (entity) => entity.entity_id
                                  ),
                        decision: 'APPROVE',
                        skip_onboarding: false,
                    };
                })
            );
            setPendingUserListOriginal(
                pendingUsersResp.map((u) => {
                    return {
                        user_id: u.user_id,
                        email: u.email,
                        isAdmin: u?.role === 'ADMIN',
                        managers:
                            authData &&
                            authData.details &&
                            authData.details.user_id
                                ? [authData.details.user_id]
                                : [],
                        name: u.name,
                        role: isSellerAccount
                            ? 'SALES'
                            : u.entity_list[0]?.role,
                        entities:
                            u?.entity_list?.length === 0 &&
                            entitiesResp?.length === 1
                                ? [entitiesResp[0].entityId]
                                : u.entity_list.map(
                                      (entity) => entity.entity_id
                                  ),
                        decision: 'APPROVE',
                        skip_onboarding: false,
                    };
                })
            );

            setAllEnterpriseUsers(allEnterpriseUsersResp);
            setAllEntities(entitiesResp);
            updateHookState(HookStateValue.READY);
        } catch (error) {
            updateHookState(HookStateValue.ERROR);
        }
    }, [updateHookState, authData, isSellerAccount]);

    const resetForm = useCallback(() => {
        if (hookState.state === HookStateValue.READY) {
            setPendingUserList(cloneDeep(pendingUserListOriginal));
        }
    }, [hookState.state, pendingUserListOriginal]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const toggleSkipOnboarding = (index: number) => {
        const newList = [...pendingUserList];
        newList[index].skip_onboarding = !newList[index].skip_onboarding;
        setPendingUserList(newList);
    };

    const toggleAdmin = (index: number) => {
        const newList = [...pendingUserList];
        newList[index].isAdmin = true;
        newList[index].role = 'ADMIN';
        setPendingUserList(newList);
    };

    const changeRole = (index: number, role: inviteeRole) => {
        const newList = [...pendingUserList];
        newList[index].isAdmin = false;
        newList[index].role = role;
        setPendingUserList(newList);
    };

    const updateEntities = (index: number, entities: string[]) => {
        const newList = [...pendingUserList];
        newList[index].entities = entities;
        setPendingUserList(newList);
    };

    const editManagers = (index: number, managers: string[]) => {
        const newList = [...pendingUserList];
        newList[index].managers = managers;
        setPendingUserList(newList);
    };

    const editDecision = (index: number, clickType: 'APPROVE' | 'REJECT') => {
        const newList = [...pendingUserList];
        if (newList[index].decision === clickType) {
            newList[index].decision = 'PENDING';
        } else {
            newList[index].decision = clickType;
        }
        setPendingUserList(newList);
    };

    const confirmDecisionForOne = async (
        InviteUser: IInvitee
    ): Promise<void> => {
        return new Promise<void>(async (resolve, reject) => {
            try {
                const successMessage =
                    InviteUser.decision === 'APPROVE'
                        ? `Successfully approved request`
                        : `Successfully rejected request`;
                setSendingDecisions(true);
                await decideJoinRequests([InviteUser]);
                setSendingDecisions(false);
                toast.success(successMessage);
                resolve();
            } catch (error) {
                const failureMessage =
                    InviteUser.decision === 'APPROVE'
                        ? `Failed to approve request`
                        : `Failed to reject request`;
                toast.error(failureMessage);
                setSendingDecisions(false);
                reject();
            }
        });
    };

    const confirmDecisions = async (): Promise<void> => {
        return new Promise<void>(async (resolve, reject) => {
            const approveCount = pendingUserList.filter(
                (u) => u.decision === 'APPROVE'
            ).length;

            const rejectCount = pendingUserList.filter(
                (u) => u.decision === 'REJECT'
            ).length;
            try {
                const successMessage =
                    approveCount && rejectCount
                        ? `Successfully approved ${approveCount} and rejected ${rejectCount} request(s)`
                        : approveCount
                        ? `Successfully approved ${approveCount} request(s)`
                        : `Successfully rejected ${rejectCount} request(s)`;

                setSendingDecisions(true);
                await decideJoinRequests(pendingUserList);
                setSendingDecisions(false);
                toast.success(successMessage);
                resolve();
            } catch (error) {
                const failureMessage =
                    approveCount && rejectCount
                        ? `Failed to approve and reject request(s)`
                        : approveCount
                        ? `Failed to approve request(s)`
                        : `Failed to reject request(s)`;
                toast.error(failureMessage);
                setSendingDecisions(false);
                reject();
            }
        });
    };

    return {
        hookState,
        allEntities,
        pendingUserList,
        allEnterpriseUsers,
        editManagers,
        toggleSkipOnboarding,
        toggleAdmin,
        changeRole,
        updateEntities,
        editDecision,
        confirmDecisionForOne,
        confirmDecisions,
        sendingDecisions,
        resetForm,
    };
}
