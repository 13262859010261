import FWDataGrid from '../../../Common/FWDataGrid';
import { FWPopupWithChildren } from '../../../Common/FWPopupWithChildren';
import { Box, Grid } from '@mui/material';
import { FWButton } from '../../../Common/FWButton';
import LoadingBackDrop from '../../../Components/Shared/LoadingBackdrop';
import useOrderToEventIntegrationHook from '../../Hooks/Buyer/useOrderToEventIntegrationHook';
import {
    itemColumns,
    OrderColumns,
    RequisitionColumns,
} from '../../Components/Buyer/OrderIntegrationConstants';
import { Dispatch, SetStateAction } from 'react';

const OrderImportPopup = ({
    open,
    handleClose,
    title,
    defaultCurrency,
    templateId,
    cartId,
    selectedReqId,
    integrationType,
    setOpenTemplateSelectionPopup,
}: {
    open: boolean;
    handleClose: (value: React.SetStateAction<boolean>) => void;
    title: string;
    defaultCurrency: string;
    templateId?: string;
    cartId: string;
    selectedReqId: string;
    integrationType: 'CART_ORDERS' | 'REQUISITIONS';
    setOpenTemplateSelectionPopup: Dispatch<SetStateAction<boolean>>;
}) => {
    const {
        orders,
        selectedOrders,
        setSelectedOrder,
        selectedItems,
        setSelectedItems,
        itemsAvailable,
        showItemPopup,
        setShowItemPopup,
        setGridApi,
        shippingAddressId,
        // handleEventCreation,
        // showCombinePopup,
        // setShowCombinePopup,
        // duplicateArray,
        page,
        setPage,
        getCustomRequisitionId,
        // items,
        // setItems,
        showLoader,
        // ordersForEvent,
        ordersForDisplay,
        defaultCurrencyDetails,
        selectedEntity,
        dispatchRequiredValues,
        warningForShippingAddress,
        // handleEventCreationWithCombination,
    } = useOrderToEventIntegrationHook(
        defaultCurrency,
        cartId,
        selectedReqId,
        open,
        integrationType
    );
    if (!ordersForDisplay) {
        return <>{open && <LoadingBackDrop />}</>;
    }
    return (
        <>
            <Grid>
                {showLoader && <LoadingBackDrop />}
                {!orders && <LoadingBackDrop />}
                <FWPopupWithChildren
                    open={open}
                    handleClose={handleClose}
                    title={!showItemPopup ? title : 'Select Items'}
                    size="custom"
                    popupWidth={'70%'}
                >
                    <Box paddingX={3} paddingTop={2} paddingBottom={1}>
                        {!showItemPopup ? (
                            <FWDataGrid
                                fixedHeight={400}
                                rows={ordersForDisplay.data}
                                columns={
                                    integrationType === 'CART_ORDERS'
                                        ? OrderColumns(
                                              selectedOrders,
                                              setSelectedOrder,
                                              ordersForDisplay.data,
                                              selectedReqId
                                          )
                                        : RequisitionColumns(
                                              selectedOrders,
                                              setSelectedOrder,
                                              ordersForDisplay.data,
                                              selectedEntity
                                          )
                                }
                                onRowSelect={setSelectedOrder}
                                selectedRows={selectedOrders ?? []}
                                onReady={(event) => {
                                    setGridApi(event.api);
                                }}
                                useCustomPagination={
                                    ordersForDisplay.metadata.total_pages
                                }
                                customPaginationOnChange={(e, pageNumber) => {
                                    setPage(pageNumber);
                                }}
                                suppressPaginationPanel
                                customPaginationPageNumber={page}
                            ></FWDataGrid>
                        ) : (
                            <FWDataGrid
                                fixedHeight={400}
                                rows={itemsAvailable}
                                columns={itemColumns(
                                    selectedItems,
                                    setSelectedItems,
                                    itemsAvailable,
                                    shippingAddressId,
                                    getCustomRequisitionId,
                                    defaultCurrencyDetails,
                                    warningForShippingAddress
                                )}
                                onRowSelect={setSelectedItems}
                                selectedRows={selectedItems ?? []}
                                onReady={(event) => {
                                    setGridApi(event.api);
                                }}
                            ></FWDataGrid>
                        )}
                        {/* <CombineItemsForEvent
                            openPopUp={showCombinePopup}
                            setOpenPopup={setShowCombinePopup}
                            itemsAvailable={itemsAvailable}
                            duplicates={duplicateArray}
                            items={items}
                            setItems={setItems}
                            handleEventCreation={
                                handleEventCreationWithCombination
                            }
                            orderForEvents={ordersForEvent}
                            defaultCurrencyDetails={defaultCurrencyDetails}
                        /> */}
                    </Box>
                    <Box
                        className="flex flex--jcfe"
                        // paddingX={5}
                        // paddingBottom={3}
                        // paddingTop={6}
                    >
                        {!showItemPopup ? (
                            <Box paddingBottom={3} paddingTop={6}>
                                <FWButton
                                    variant="outlined"
                                    color="error"
                                    onClick={() => {
                                        handleClose(false);
                                    }}
                                    size="medium"
                                >
                                    Cancel
                                </FWButton>
                            </Box>
                        ) : (
                            <Box paddingTop={2} paddingBottom={3}>
                                <FWButton
                                    variant="outlined"
                                    color="error"
                                    onClick={() => {
                                        setShowItemPopup(false);
                                    }}
                                    size="medium"
                                >
                                    Back
                                </FWButton>
                            </Box>
                        )}

                        {!showItemPopup ? (
                            <Box
                                paddingRight={5}
                                paddingBottom={3}
                                paddingTop={6}
                            >
                                <FWButton
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        setShowItemPopup(true);
                                    }}
                                    size="medium"
                                    disabled={selectedOrders.length === 0}
                                    style={{ marginLeft: '12px' }}
                                >
                                    Continue
                                </FWButton>
                            </Box>
                        ) : (
                            <Box
                                paddingRight={5}
                                paddingBottom={3}
                                paddingTop={2}
                            >
                                <FWButton
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        dispatchRequiredValues();
                                        handleClose(false);
                                        setOpenTemplateSelectionPopup(true);
                                    }}
                                    disabled={selectedItems.length === 0}
                                    size="medium"
                                    style={{ marginLeft: '12px' }}
                                >
                                    {'Select Template'}
                                </FWButton>
                            </Box>
                        )}
                    </Box>
                </FWPopupWithChildren>
            </Grid>
        </>
    );
};

export default OrderImportPopup;
