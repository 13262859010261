import { Button, ButtonProps, IconButton } from '@mui/material';
import styled from '@emotion/styled';

export const CustomButton = styled(Button)((args) => ({
    '&.Mui-focusVisible': {
        // boxShadow: '0 0 0 2px rgba(0, 73, 152, 1)',
        boxShadow: '0 0 0 1px #ffffff, 0 0 0 3px #007AFF',
    },
    // textTransform: 'lowercase',
    // '&::first-letter': {
    //     textTransform: 'uppercase',
    // },
}));

export function FWButton(props: ButtonProps) {
    return (
        <CustomButton disableRipple {...props}>
            {props.children}
        </CustomButton>
    );
}

// TODO: Deprecate usage of all the below components and replace with the above one
export function FWButtonSmall(props: any) {
    return (
        <CustomButton variant="contained" color="primary" {...props}>
            {props.children}
        </CustomButton>
    );
}

export function FWOutlinedButton(props: any) {
    return (
        <CustomButton
            style={{
                minWidth: '7.5rem',
                // lineHeight: "1.5em",
                letterSpacing: '-0.011px',
                fontFamily: "'Inter', sans-serif",
                textTransform: 'none',
            }}
            variant="outlined"
            color="primary"
            {...props}
        >
            {props.children}
        </CustomButton>
    );
}

export function FWIconButton(props: any) {
    return (
        <IconButton variant="contained" color="primary" {...props}>
            {props.children}
        </IconButton>
    );
}

export function FWIconOutlinedButton(props: any) {
    return (
        <IconButton variant="outlined" color="primary" {...props}>
            {props.children}
        </IconButton>
    );
}
