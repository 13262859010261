import { Box, Button, Typography } from '@mui/material';
import _ from 'lodash';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useGetAdditionalCostFromAdminQuery } from '../../../AdditionalCost/Services/additionalCosts.services';
import AdditionalCostPopUp from '../../../AdditionalCost/components/AdditionalCostPopUp';
import {
    IAdditionalCostsBackend,
    IAdditionalCostsWithValue,
    IDiscountWithValue,
} from '../../../AdditionalCost/models/AdditionalCost.model';
import { FWCurrencyTypoGraphyWithCurrencyUid } from '../../../Common/CurrencyUtilsComponent';
import { FWTooltip } from '../../../Common/FWCustomTooltip';
import FWMultipleTooltipMessages from '../../../Common/FWMultipleTooltipMessages';
import { FWTypography } from '../../../Common/FWTypograhy';
import { getFWDate } from '../../../Common/Utils/DateUtils';
import {
    calculateNewItemEffectiveRateWithDecimal,
    calculateNewItemTotalWithDecimalPlaces,
} from '../../../Components/Shared/totalCalculator';
import QuantityAndDSCombinedField from '../../../Events/Components/Buyer/QuantityAndDSCombinedField';
import { updateEventItemDetailsActionType } from '../../../Events/Interfaces/Buyer/RFQ.model';
import { useGetSelectedCurrency } from '../../../Events/Services/Buyer/shared.service';
import SingleFieldTypeRenderer from '../../../FieldTypes/components/SingleFieldTypeRenderer';
import DynamicAnalyticsItemPrice from '../../../Historical/Containers/DynamicAnalyticsItemPrice';
import { IGlobalAppStore } from '../../../Redux/Store';
import { CustomFieldType } from '../../../SRM/Constants/Interfaces/documentTemplate';
import {
    convertIAdditionalCostBackendToIAdditionalCostWithValue,
    convertIAdditionalCostWithValueToIAdditionalCostBackend,
} from '../../Helpers/CartItemHelpers';
import { ItemCartTemplateStandardFieldsEnum } from '../../Interfaces/ItemCartTemplateInterfaces';
import { ItemCartAccessContext } from '../../Pages/ItemCartAccess';
import { CartItemAccordionContext } from './CartItemAccordion';
import CartItemCustomFields from './CartItemCustomFields';
import DeliveryScheduleForCartItem from './CartItemDeliverySchedule';

export default function CartItemAccordionSummary() {
    const { cartItemId, itemInCart, updateItemData, errors } = useContext(
        CartItemAccordionContext
    );

    const [clickedFirstTime, setClickedFirstTime] = useState(false);

    const { enterpriseItemDetails } = useContext(CartItemAccordionContext);

    const measurement_unit = useMemo(() => {
        return enterpriseItemDetails?.measurementUnits[0] ?? null;
    }, [enterpriseItemDetails?.measurementUnits]);

    const quantity = useSelector((state: IGlobalAppStore) =>
        itemInCart
            ? state.ItemCartStore.cartItems[cartItemId].quantity
            : state.ItemCartStore.itemsSavedForLater[cartItemId].quantity
    );

    const price = useSelector((state: IGlobalAppStore) =>
        itemInCart
            ? state.ItemCartStore.cartItems[cartItemId].pricing_information
                  .desired_price
            : state.ItemCartStore.itemsSavedForLater[cartItemId]
                  .pricing_information.desired_price
    );

    const allCurrencies = useSelector(
        (state: IGlobalAppStore) => state.ItemCartStore.allCurrencies
    );

    const itemCurrency = useSelector((state: IGlobalAppStore) =>
        itemInCart
            ? state.ItemCartStore.cartItems[cartItemId].pricing_information
                  .currency_code_id
            : state.ItemCartStore.itemsSavedForLater[cartItemId]
                  .pricing_information.currency_code_id
    );

    const additional_costs = useSelector((state: IGlobalAppStore) =>
        itemInCart
            ? state.ItemCartStore.cartItems[cartItemId].additional_costs
            : state.ItemCartStore.itemsSavedForLater[cartItemId]
                  .additional_costs
    );
    const taxes = useSelector((state: IGlobalAppStore) =>
        itemInCart
            ? state.ItemCartStore.cartItems[cartItemId].taxes
            : state.ItemCartStore.itemsSavedForLater[cartItemId].taxes
    );
    const discounts = useSelector((state: IGlobalAppStore) =>
        itemInCart
            ? state.ItemCartStore.cartItems[cartItemId].discounts
            : state.ItemCartStore.itemsSavedForLater[cartItemId].discounts
    );

    const delivery_schedule = useSelector((state: IGlobalAppStore) =>
        itemInCart
            ? state.ItemCartStore.cartItems[cartItemId].delivery_information
            : state.ItemCartStore.itemsSavedForLater[cartItemId]
                  .delivery_information
    );

    const currency_code_abbreviation = useSelector((state: IGlobalAppStore) =>
        itemInCart
            ? state.ItemCartStore.cartItems[cartItemId].pricing_information
                  .currency_code_abbreviation
            : state.ItemCartStore.itemsSavedForLater[cartItemId]
                  .pricing_information.currency_code_abbreviation
    );

    const currency_code_id = useSelector((state: IGlobalAppStore) =>
        itemInCart
            ? state.ItemCartStore.cartItems[cartItemId].currency
            : state.ItemCartStore.itemsSavedForLater[cartItemId].currency
    );

    const currency_code_symbol = useSelector((state: IGlobalAppStore) =>
        itemInCart
            ? state.ItemCartStore.cartItems[cartItemId].pricing_information
                  .currency_symbol
            : state.ItemCartStore.itemsSavedForLater[cartItemId]
                  .pricing_information.currency_symbol
    );

    const measurement_unit_id = useSelector((state: IGlobalAppStore) =>
        itemInCart
            ? state.ItemCartStore.cartItems[cartItemId].measurement_unit
            : state.ItemCartStore.itemsSavedForLater[cartItemId]
                  .measurement_unit
    );

    const { templateDetails, buyerEntityId } = useContext(
        ItemCartAccessContext
    );

    const enterprise_item_id = useSelector((state: IGlobalAppStore) =>
        itemInCart
            ? state.ItemCartStore.cartItems[cartItemId].enterprise_item
            : state.ItemCartStore.itemsSavedForLater[cartItemId].enterprise_item
    );

    const essentialTermCustomFields = useSelector((store: IGlobalAppStore) =>
        itemInCart
            ? store.ItemCartStore.cartItems[
                  cartItemId
              ].custom_fields.section_list.find(
                  (section) =>
                      section.name ===
                      templateDetails.sections.ESSENTIAL_TERMS.label
              ) ?? {
                  name: templateDetails.sections.ESSENTIAL_TERMS.label,
                  fields: [],
              }
            : store.ItemCartStore.itemsSavedForLater[
                  cartItemId
              ].custom_fields.section_list.find(
                  (section) =>
                      section.name ===
                      templateDetails.sections.ESSENTIAL_TERMS.label
              ) ?? {
                  name: templateDetails.sections.ESSENTIAL_TERMS.label,
                  fields: [],
              }
    );

    const newCustomFields = useMemo(() => {
        return essentialTermCustomFields.fields.filter(
            (customField) => !customField.is_locked
        );
    }, [essentialTermCustomFields]);

    const measurement_unit_name = useMemo(
        () => measurement_unit?.name ?? '',
        [measurement_unit?.name]
    );

    const [openDeliverySchedule, setOpenDeliverySchedule] = useState(false);

    const [finalAdditionalCostListForItem, setFinalAdditionalCostListForItem] =
        useState<IAdditionalCostsBackend[]>(additional_costs);

    const { data: adminAdditionalCost } = useGetAdditionalCostFromAdminQuery(
        {}
    );

    useEffect(() => {
        if (additional_costs && adminAdditionalCost) {
            let res = additional_costs.filter((cost) =>
                adminAdditionalCost?.some(
                    (adminCost) =>
                        cost.additional_cost_id ===
                        adminCost?.additional_cost_id
                )
            );

            // props.updateItemData([
            //     {
            //         field: 'additional_costs',
            //         value: res,
            //     },
            // ]);

            setFinalAdditionalCostListForItem(res);
        }
    }, [additional_costs, adminAdditionalCost]);

    const effectiveRate = useCallback(() => {
        let amount = calculateNewItemEffectiveRateWithDecimal({
            itemDS: delivery_schedule,
            item_price: price,
            decimalPlaces: 2,
            additional_cost:
                convertIAdditionalCostBackendToIAdditionalCostWithValue(
                    additional_costs
                ),
            discount:
                convertIAdditionalCostBackendToIAdditionalCostWithValue(
                    discounts
                ),
            tax: convertIAdditionalCostBackendToIAdditionalCostWithValue(taxes),
        });

        return amount;
    }, [additional_costs, delivery_schedule, discounts, price, taxes]);

    useEffect(() => {}, [effectiveRate]);

    const selectedCurrencyDetails = useGetSelectedCurrency(
        currency_code_abbreviation ?? ''
    );

    const totalAmount = useCallback(() => {
        let amount = calculateNewItemTotalWithDecimalPlaces({
            itemDS: delivery_schedule,
            item_price: price,
            decimalPlaces: selectedCurrencyDetails?.decimals ?? 2,
            additional_cost:
                convertIAdditionalCostBackendToIAdditionalCostWithValue(
                    additional_costs
                ),
            discount:
                convertIAdditionalCostBackendToIAdditionalCostWithValue(
                    discounts
                ),
            tax: convertIAdditionalCostBackendToIAdditionalCostWithValue(taxes),
        });

        return amount;
    }, [
        additional_costs,
        delivery_schedule,
        discounts,
        price,
        selectedCurrencyDetails?.decimals,
        taxes,
    ]);

    return (
        <>
            <br />
            <Box
                display="grid"
                gridTemplateColumns={{
                    xl: '19% 19% 19% 17% 16%',
                    lg: '19% 19% 19% 17% 16%',
                    md: 'repeat(3, 31%)',
                    sm: 'repeat(2, 47%)',
                    xs: 'repeat(1, 89%)',
                }}
                columnGap="1.5rem"
                rowGap="1rem"
                margin={'0 1rem 0 1rem'}
            >
                {' '}
                {templateDetails.sections.ESSENTIAL_TERMS.fieldList.map(
                    (fieldListItem) => {
                        if (
                            fieldListItem.fieldType === 'STANDARD' &&
                            templateDetails.fieldSettings.standardFields[
                                fieldListItem.field
                            ].buyerSettings.showField
                        )
                            switch (fieldListItem.field) {
                                case ItemCartTemplateStandardFieldsEnum.QUANTITY:
                                    return (
                                        <Box>
                                            <Box className="flex flex--jcsb">
                                                <Box className="flex flex--aic">
                                                    <Typography color="text.secondary">
                                                        {
                                                            templateDetails
                                                                .fieldSettings
                                                                .standardFields[
                                                                fieldListItem
                                                                    .field
                                                            ].label
                                                        }
                                                    </Typography>
                                                    {templateDetails
                                                        .fieldSettings
                                                        .standardFields[
                                                        fieldListItem.field
                                                    ].description.length >
                                                        0 && (
                                                        <>
                                                            &nbsp;
                                                            <FWTooltip
                                                                title={
                                                                    templateDetails
                                                                        .fieldSettings
                                                                        .standardFields[
                                                                        fieldListItem
                                                                            .field
                                                                    ]
                                                                        .description
                                                                }
                                                                placement="top"
                                                            >
                                                                <Box
                                                                    color={
                                                                        'primary.main'
                                                                    }
                                                                >
                                                                    <i className="bi bi-question-circle" />
                                                                </Box>
                                                            </FWTooltip>
                                                        </>
                                                    )}
                                                </Box>
                                                {templateDetails.fieldSettings
                                                    .standardFields
                                                    .DELIVERY_DATE.buyerSettings
                                                    .showField && (
                                                    <Button
                                                        sx={{
                                                            padding: '0px',
                                                            fontSize: '12px',
                                                        }}
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            setOpenDeliverySchedule(
                                                                true
                                                            );
                                                        }}
                                                        color={
                                                            'deliveryScheduleOverall' in
                                                                errors &&
                                                            !errors
                                                                .deliveryScheduleOverall
                                                                ?.suppress
                                                                ? 'error'
                                                                : 'primary'
                                                        }
                                                    >
                                                        <Box
                                                            fontSize={'15px'}
                                                            id="createEvent_deliverySchedule"
                                                        >
                                                            <i className="bi bi-truck"></i>
                                                        </Box>
                                                        &nbsp;
                                                        {delivery_schedule.length ===
                                                        1
                                                            ? `: ${getFWDate(
                                                                  delivery_schedule[0]
                                                                      .delivery_date
                                                              )}`
                                                            : `${delivery_schedule.length} lots`}
                                                    </Button>
                                                )}
                                            </Box>
                                            <Box
                                                onClick={(e) =>
                                                    e.stopPropagation()
                                                }
                                            >
                                                <QuantityAndDSCombinedField
                                                    quantity={quantity.toString()}
                                                    measurement_unit_id={
                                                        measurement_unit_id
                                                    }
                                                    measurement_units={
                                                        enterpriseItemDetails?.measurementUnits
                                                            ? enterpriseItemDetails?.measurementUnits?.map(
                                                                  (unit) => ({
                                                                      measurement_unit_category:
                                                                          unit.category,
                                                                      measurement_unit_id:
                                                                          unit.id,
                                                                      measurement_unit_primary_name:
                                                                          unit.name,
                                                                      measurement_unit_value_type:
                                                                          unit.type,
                                                                  })
                                                              )
                                                            : []
                                                    }
                                                    updateItemData={(
                                                        params: {
                                                            field: updateEventItemDetailsActionType;
                                                            value: any;
                                                        }[]
                                                    ) => {
                                                        updateItemData([
                                                            {
                                                                field: 'quantity',
                                                                value: params[0]
                                                                    .value,
                                                            },
                                                        ]);
                                                    }}
                                                    error={
                                                        'quantity' in errors ??
                                                        false
                                                    }
                                                    helperText={
                                                        'quantity' in errors
                                                            ? errors[
                                                                  'quantity'
                                                              ]!
                                                                  .fieldMessageText
                                                            : 'deliveryScheduleOverall' in
                                                                  errors &&
                                                              !errors
                                                                  .deliveryScheduleOverall
                                                                  ?.suppress
                                                            ? errors[
                                                                  'deliveryScheduleOverall'
                                                              ]!
                                                                  .fieldMessageText
                                                            : ''
                                                    }
                                                    setOpenDS={
                                                        setOpenDeliverySchedule
                                                    }
                                                    DSLength={
                                                        delivery_schedule.length
                                                    }
                                                />
                                            </Box>
                                        </Box>
                                    );
                                case ItemCartTemplateStandardFieldsEnum.TARGET_RATE:
                                    return (
                                        <Box>
                                            <Box
                                                className="flex "
                                                justifyContent="space-between"
                                            >
                                                <div>
                                                    <Box className="flex flex--aic">
                                                        <FWTypography color="text.secondary">
                                                            {
                                                                templateDetails
                                                                    .fieldSettings
                                                                    .standardFields[
                                                                    fieldListItem
                                                                        .field
                                                                ].label
                                                            }
                                                        </FWTypography>

                                                        {templateDetails
                                                            .fieldSettings
                                                            .standardFields[
                                                            fieldListItem.field
                                                        ].description.length >
                                                            0 && (
                                                            <>
                                                                &nbsp;
                                                                <FWTooltip
                                                                    title={
                                                                        templateDetails
                                                                            .fieldSettings
                                                                            .standardFields[
                                                                            fieldListItem
                                                                                .field
                                                                        ]
                                                                            .description
                                                                    }
                                                                    placement="top"
                                                                >
                                                                    <Box
                                                                        color={
                                                                            'primary.main'
                                                                        }
                                                                    >
                                                                        <i className="bi bi-question-circle" />
                                                                    </Box>
                                                                </FWTooltip>
                                                            </>
                                                        )}
                                                    </Box>
                                                </div>
                                                <div>
                                                    {!(
                                                        templateDetails
                                                            .fieldSettings
                                                            .standardFields.TAX
                                                            .buyerSettings
                                                            .showField ||
                                                        templateDetails
                                                            .fieldSettings
                                                            .standardFields[
                                                            ItemCartTemplateStandardFieldsEnum
                                                                .ADDITIONAL_COSTS
                                                        ].buyerSettings
                                                            .showField ||
                                                        templateDetails
                                                            .fieldSettings
                                                            .standardFields[
                                                            ItemCartTemplateStandardFieldsEnum
                                                                .DISCOUNT
                                                        ].buyerSettings
                                                            .showField
                                                    ) && (
                                                        <>
                                                            <DynamicAnalyticsItemPrice
                                                                clickedFirstTime={
                                                                    clickedFirstTime
                                                                }
                                                                setClickedFirstTime={
                                                                    setClickedFirstTime
                                                                }
                                                                enterpriseItemUuid={
                                                                    enterprise_item_id
                                                                }
                                                                currencyDetails={{
                                                                    currencyCodeAbbreviation:
                                                                        currency_code_abbreviation,
                                                                    currencyName:
                                                                        '',
                                                                    currencySymbol:
                                                                        currency_code_symbol,
                                                                    currencyUuid:
                                                                        currency_code_id,
                                                                }}
                                                                measurementUnitUuid={
                                                                    measurement_unit_id
                                                                }
                                                                measurementUnitName={
                                                                    enterpriseItemDetails
                                                                        ?.measurementUnits[0]
                                                                        .name ??
                                                                    ''
                                                                }
                                                                buttonPadding="0px"
                                                                entityId={
                                                                    buyerEntityId
                                                                }
                                                                handleSelectionOfContract={() => {}}
                                                            />
                                                        </>
                                                    )}
                                                </div>
                                            </Box>
                                            <Box
                                                onClick={(e) =>
                                                    e.stopPropagation()
                                                }
                                            >
                                                <SingleFieldTypeRenderer
                                                    fieldType="CURRENCY"
                                                    constraints={{
                                                        choices: allCurrencies,
                                                        fieldType:
                                                            CustomFieldType.CURRENCY,
                                                    }}
                                                    errors={
                                                        'price' in errors &&
                                                        !errors.price?.suppress
                                                    }
                                                    customDropDownAndInputSx={{
                                                        dropdown: {
                                                            width: '100%',
                                                        },
                                                        input: {
                                                            width: '200%',
                                                        },
                                                    }}
                                                    helper={{
                                                        text:
                                                            'price' in errors &&
                                                            !errors.price
                                                                ?.suppress
                                                                ? errors[
                                                                      'price'
                                                                  ]!
                                                                      .fieldMessageText
                                                                : '',
                                                    }}
                                                    value={{
                                                        decimal:
                                                            price.toString(),
                                                        currency: itemCurrency,
                                                    }}
                                                    updateHandler={(newValue: {
                                                        currency: string | null;
                                                        decimal: string;
                                                    }) => {
                                                        const selectedCurrencyDetails =
                                                            allCurrencies.find(
                                                                (currency) =>
                                                                    currency.entry_id ===
                                                                    newValue.currency
                                                            );
                                                        if (
                                                            selectedCurrencyDetails
                                                        ) {
                                                            updateItemData([
                                                                {
                                                                    field: 'price',
                                                                    value: newValue.decimal,
                                                                },
                                                                {
                                                                    field: 'currency',
                                                                    value: selectedCurrencyDetails,
                                                                },
                                                            ]);
                                                        }
                                                    }}
                                                />
                                            </Box>
                                        </Box>
                                    );

                                case ItemCartTemplateStandardFieldsEnum.TAX:
                                    return (
                                        <Box>
                                            <Box className="flex flex--aic">
                                                <Typography color="text.secondary">
                                                    {
                                                        templateDetails
                                                            .fieldSettings
                                                            .standardFields[
                                                            fieldListItem.field
                                                        ].label
                                                    }
                                                </Typography>
                                                {templateDetails.fieldSettings
                                                    .standardFields[
                                                    fieldListItem.field
                                                ].description.length > 0 && (
                                                    <>
                                                        &nbsp;
                                                        <FWTooltip
                                                            title={
                                                                templateDetails
                                                                    .fieldSettings
                                                                    .standardFields[
                                                                    fieldListItem
                                                                        .field
                                                                ].description
                                                            }
                                                            placement="top"
                                                        >
                                                            <Box
                                                                color={
                                                                    'primary.main'
                                                                }
                                                            >
                                                                <i className="bi bi-question-circle" />
                                                            </Box>
                                                        </FWTooltip>
                                                    </>
                                                )}
                                            </Box>
                                            <Box
                                                height="2.25rem"
                                                className="flex flex--aic"
                                                onClick={(e) =>
                                                    e.stopPropagation()
                                                }
                                            >
                                                <AdditionalCostPopUp
                                                    updateHandler={(
                                                        newAdditionalCost: IAdditionalCostsWithValue[]
                                                    ) => {
                                                        updateItemData([
                                                            {
                                                                field: 'taxes',
                                                                value: convertIAdditionalCostWithValueToIAdditionalCostBackend(
                                                                    newAdditionalCost
                                                                ),
                                                            },
                                                        ]);
                                                    }}
                                                    type="Taxes"
                                                    disountAdditionalCost={convertIAdditionalCostBackendToIAdditionalCostWithValue(
                                                        discounts
                                                    )}
                                                    showTextOrPencilIcon="TEXT"
                                                    additionalCost={convertIAdditionalCostBackendToIAdditionalCostWithValue(
                                                        taxes
                                                    )}
                                                    baseQuantity={quantity}
                                                    baseRate={price}
                                                    currency_code_abbreviation={
                                                        currency_code_abbreviation ??
                                                        ''
                                                    }
                                                    currency_code_symbol={
                                                        currency_code_symbol ??
                                                        ''
                                                    }
                                                    measurementUnitName={
                                                        measurement_unit?.name ??
                                                        ''
                                                    }
                                                    additionalCostTemplateOptions={
                                                        templateDetails
                                                            .fieldSettings
                                                            .standardFields.TAX
                                                            .taxesOptions
                                                    }
                                                    portal="BUYER"
                                                />
                                            </Box>
                                        </Box>
                                    );

                                case ItemCartTemplateStandardFieldsEnum.ADDITIONAL_COSTS:
                                    return (
                                        <Box>
                                            <Box className="flex flex--aic">
                                                <Typography color="text.secondary">
                                                    {
                                                        templateDetails
                                                            .fieldSettings
                                                            .standardFields[
                                                            fieldListItem.field
                                                        ].label
                                                    }
                                                </Typography>
                                                {templateDetails.fieldSettings
                                                    .standardFields[
                                                    fieldListItem.field
                                                ].description.length > 0 && (
                                                    <>
                                                        &nbsp;
                                                        <FWTooltip
                                                            title={
                                                                templateDetails
                                                                    .fieldSettings
                                                                    .standardFields[
                                                                    fieldListItem
                                                                        .field
                                                                ].description
                                                            }
                                                            placement="top"
                                                        >
                                                            <Box
                                                                color={
                                                                    'primary.main'
                                                                }
                                                            >
                                                                <i className="bi bi-question-circle" />
                                                            </Box>
                                                        </FWTooltip>
                                                    </>
                                                )}
                                            </Box>
                                            <Box
                                                height="2.25rem"
                                                className="flex flex--aic"
                                                onClick={(e) =>
                                                    e.stopPropagation()
                                                }
                                            >
                                                <AdditionalCostPopUp
                                                    updateHandler={(
                                                        newAdditionalCost: IAdditionalCostsWithValue[]
                                                    ) => {
                                                        updateItemData([
                                                            {
                                                                field: 'additional_costs',
                                                                value: convertIAdditionalCostWithValueToIAdditionalCostBackend(
                                                                    newAdditionalCost
                                                                ),
                                                            },
                                                        ]);
                                                    }}
                                                    type="AdditionalCost"
                                                    showTextOrPencilIcon="TEXT"
                                                    additionalCost={convertIAdditionalCostBackendToIAdditionalCostWithValue(
                                                        finalAdditionalCostListForItem
                                                    )}
                                                    // additionalCost={
                                                    //     additional_costs
                                                    // }
                                                    baseQuantity={quantity}
                                                    baseRate={price}
                                                    currency_code_abbreviation={
                                                        currency_code_abbreviation ??
                                                        ''
                                                    }
                                                    currency_code_symbol={
                                                        currency_code_symbol ??
                                                        ''
                                                    }
                                                    measurementUnitName={
                                                        measurement_unit?.name ??
                                                        ''
                                                    }
                                                    additionalCostTemplateOptions={
                                                        templateDetails
                                                            .fieldSettings
                                                            .standardFields
                                                            .ADDITIONAL_COSTS
                                                            .additionalCostOptions
                                                    }
                                                    portal="BUYER"
                                                />
                                            </Box>
                                        </Box>
                                    );
                                case ItemCartTemplateStandardFieldsEnum.DISCOUNT:
                                    return (
                                        <Box>
                                            <Box className="flex flex--aic">
                                                <Typography color="text.secondary">
                                                    {
                                                        templateDetails
                                                            .fieldSettings
                                                            .standardFields[
                                                            fieldListItem.field
                                                        ].label
                                                    }
                                                </Typography>
                                                {templateDetails.fieldSettings
                                                    .standardFields[
                                                    fieldListItem.field
                                                ].description.length > 0 && (
                                                    <>
                                                        &nbsp;
                                                        <FWTooltip
                                                            title={
                                                                templateDetails
                                                                    .fieldSettings
                                                                    .standardFields[
                                                                    fieldListItem
                                                                        .field
                                                                ].description
                                                            }
                                                            placement="top"
                                                        >
                                                            <Box
                                                                color={
                                                                    'primary.main'
                                                                }
                                                            >
                                                                <i className="bi bi-question-circle" />
                                                            </Box>
                                                        </FWTooltip>
                                                    </>
                                                )}
                                            </Box>
                                            <Box
                                                // height="2.25rem"
                                                className="flex flex--aic"
                                                onClick={(e) =>
                                                    e.stopPropagation()
                                                }
                                            >
                                                <AdditionalCostPopUp
                                                    updateHandler={(
                                                        newDiscount: IDiscountWithValue[]
                                                    ) => {
                                                        updateItemData([
                                                            {
                                                                field: 'discounts',
                                                                value: convertIAdditionalCostWithValueToIAdditionalCostBackend(
                                                                    newDiscount
                                                                ),
                                                            },
                                                        ]);
                                                    }}
                                                    type="Discount"
                                                    DiscountMode="EDIT"
                                                    showTextOrPencilIcon="TEXT"
                                                    additionalCost={convertIAdditionalCostBackendToIAdditionalCostWithValue(
                                                        discounts
                                                    )}
                                                    baseQuantity={quantity}
                                                    baseRate={price}
                                                    currency_code_abbreviation={
                                                        currency_code_abbreviation ??
                                                        ''
                                                    }
                                                    currency_code_symbol={
                                                        currency_code_symbol ??
                                                        ''
                                                    }
                                                    measurementUnitName={
                                                        measurement_unit?.name ??
                                                        ''
                                                    }
                                                    // effectiveRateWithOutDiscount={effectiveRateWithOutDiscount()}
                                                    additionalCostTemplateOptions={
                                                        templateDetails
                                                            .fieldSettings
                                                            .standardFields
                                                            .DISCOUNT
                                                            .discountOptions
                                                    }
                                                    portal="BUYER"
                                                />
                                            </Box>
                                        </Box>
                                    );
                            }

                        return <></>;
                    }
                )}
                {(templateDetails.fieldSettings.standardFields.TAX.buyerSettings
                    .showField ||
                    templateDetails.fieldSettings.standardFields
                        .ADDITIONAL_COSTS.buyerSettings.showField ||
                    templateDetails.fieldSettings.standardFields.DISCOUNT
                        .buyerSettings.showField) && (
                    <Box>
                        <Box className="flex flex--aic" height="1.6rem">
                            <Typography color="text.secondary">
                                Effective rate
                            </Typography>
                            <div
                                className="flex flex--aic"
                                onClick={(e) => e.stopPropagation()}
                            >
                                <>
                                    &nbsp;
                                    {/* UNCOMMENTED BUTTON THAT SHOWS DYNAMIC ANALYSIS */}
                                    <DynamicAnalyticsItemPrice
                                        neglectStoreAndShowGraph={true}
                                        enterpriseItemUuid={enterprise_item_id}
                                        currencyDetails={{
                                            currencyCodeAbbreviation:
                                                currency_code_abbreviation,
                                            // TODO: Name is missing from item interface
                                            currencyName: '',
                                            currencySymbol:
                                                currency_code_symbol,
                                            currencyUuid: currency_code_id,
                                        }}
                                        measurementUnitUuid={
                                            measurement_unit_id
                                        }
                                        measurementUnitName={
                                            measurement_unit?.name ?? ''
                                        }
                                        entityId={buyerEntityId}
                                        handleSelectionOfContract={() => {}}
                                    />
                                    &nbsp;
                                    <FWMultipleTooltipMessages
                                        removeNumberring
                                        messages={[]}
                                        iconColor={'primary.main'}
                                        icon={
                                            <i className="bi bi-clipboard-data EventsAddItemStage_ClickOnPoOfferedIcon"></i>
                                        }
                                    />
                                </>
                            </div>
                        </Box>
                        <Box height="1.5rem" className="flex flex--aic">
                            <FWCurrencyTypoGraphyWithCurrencyUid
                                currencyUidOrAbbrevaition={
                                    currency_code_abbreviation
                                }
                                currency_code_abbreviation={
                                    currency_code_abbreviation
                                }
                                value={effectiveRate()}
                            />
                        </Box>
                    </Box>
                )}
                <Box>
                    <Typography color="text.secondary">Total amount</Typography>
                    <Box height="2.25rem" className="flex flex--aic">
                        <FWCurrencyTypoGraphyWithCurrencyUid
                            currencyUidOrAbbrevaition={
                                currency_code_abbreviation
                            }
                            currency_code_abbreviation={
                                currency_code_abbreviation
                            }
                            value={totalAmount()}
                        />
                    </Box>
                </Box>
                {newCustomFields.map((field, idx) => (
                    <Box key={idx}>
                        <CartItemCustomFields
                            fieldName={field.name}
                            templateDetails={templateDetails}
                            sectionName={
                                templateDetails.sections.ESSENTIAL_TERMS.label
                            }
                            openEditCustomFieldPopup={() => {}}
                            updateItemData={updateItemData}
                            isCustomFieldFromTemplate={false}
                        />
                    </Box>
                ))}
            </Box>
            {!_.isEmpty(templateDetails.fieldSettings.customFields) && (
                <Box
                    display="grid"
                    gridTemplateColumns={{
                        xl: '19% 19% 19% 17% 16%',
                        lg: '19% 19% 19% 17% 16%',
                        md: 'repeat(3, 31%)',
                        sm: 'repeat(2, 47%)',
                        xs: 'repeat(1, 89%)',
                    }}
                    columnGap="1.5rem"
                    rowGap="1rem"
                    margin={'1rem'}
                >
                    {templateDetails.sections.ESSENTIAL_TERMS.fieldList.map(
                        (fieldListItem) => {
                            if (
                                fieldListItem.fieldType === 'CUSTOM' &&
                                templateDetails.fieldSettings.customFields[
                                    fieldListItem.field
                                ].buyerSettings.showField
                            ) {
                                return (
                                    <CartItemCustomFields
                                        fieldName={fieldListItem.field}
                                        templateDetails={templateDetails}
                                        sectionName={
                                            templateDetails.sections
                                                .ESSENTIAL_TERMS.label
                                        }
                                        openEditCustomFieldPopup={() => {}}
                                        updateItemData={updateItemData}
                                        isCustomFieldFromTemplate={true}
                                    />
                                );
                            } else {
                                return <></>;
                            }
                        }
                    )}
                </Box>
            )}
            <DeliveryScheduleForCartItem
                key={cartItemId}
                cartItem={{
                    delivery_information: delivery_schedule,
                    measurement_unit_name,
                    quantity: quantity.toString(),
                }}
                open={openDeliverySchedule}
                handleClose={() => setOpenDeliverySchedule(false)}
            />
        </>
    );
}
