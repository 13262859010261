import produce from 'immer';
import { IEntity } from '../../Organizations/Admin/Interfaces/EnterpriseInterface';
import { IVerificationState } from '../Slices/VerificationSlice';

export enum VerificationActions {
    UPDATE_STATUS = 'UPDATE_STATUS',
    TOGGLE_POPUP = 'TOGGLE_POPUP',
    UPDATE_ENTITIES = 'UPDATE_ENTITIES',
    UPDATE_ALL_ENTITIES = 'UPDATE_ALL_ENTITIES',
}

export interface IVerificationPopupAction {
    type: VerificationActions.TOGGLE_POPUP;
    value: boolean;
}

export interface IUserEntitiesAction {
    type: VerificationActions.UPDATE_ENTITIES;
    value: string[];
}

export interface IAllEntitiesAction {
    type: VerificationActions.UPDATE_ALL_ENTITIES;
    value: IEntity[];
}

export interface IVerificationActions {
    type: string;
    payload:
        | IVerificationPopupAction
        | IUserEntitiesAction
        | IAllEntitiesAction;
}

export const toggleVerificationPopupReducer = produce(
    (state: IVerificationState, action: IVerificationActions) => {
        switch (action.payload.type) {
            case VerificationActions.TOGGLE_POPUP:
                state.verificationPopupVisible = action.payload.value;
                break;
        }
    }
);

export const updateUserEntitiesReducer = produce(
    (state: IVerificationState, action: IVerificationActions) => {
        switch (action.payload.type) {
            case VerificationActions.UPDATE_ENTITIES:
                state.userVerificationEntities = action.payload.value;
                break;
        }
    }
);

export const updateAllEntitiesReducer = produce(
    (state: IVerificationState, action: IVerificationActions) => {
        switch (action.payload.type) {
            case VerificationActions.UPDATE_ALL_ENTITIES:
                state.allUserEntities = action.payload.value;
                if (action.payload.value.length === 1) {
                    state.hasSignleEntity = true;
                } else {
                    state.hasSignleEntity = false;

                }
                break;
        }
    }
);
