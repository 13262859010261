import { capitaliseFirstLetter } from '../../BuyerTemplates/Component/TemplateSuggestedFieldsSection';
import { CostingSheetStatusEnum } from '../Interfaces/CostingInterfaces.interface.';

export const getCostingDashboardStatusLabelAndColor = (
    status: CostingSheetStatusEnum,
    version: number
) => {
    switch (status) {
        case CostingSheetStatusEnum.DRAFT:
            if (version === 1) {
                return {
                    label: 'Draft',
                    color: '',
                };
            } else {
                return {
                    label: 'Revising',
                    color: 'warning.main',
                };
            }
        case CostingSheetStatusEnum.APPROVAL_PENDING:
            return {
                label: 'Approval Pending',
                color: 'warning.main',
            };
        case CostingSheetStatusEnum.PAUSE_AND_EDIT:
            return {
                label: 'Pause and Edit',
                color: 'warning.main',
            };
        case CostingSheetStatusEnum.REJECTED:
            return {
                label: 'Rejected',
                color: 'error.main',
            };

        case CostingSheetStatusEnum.SUBMITTED:
            return {
                label: 'Submitted',
                color: 'success.main',
            };
        case CostingSheetStatusEnum.REWORK:
            return {
                label: 'Changes requested',
                color: 'warning.main',
            };
        default:
            return {
                label: capitaliseFirstLetter(status?.toLowerCase()),
                color: '',
            };
    }
};
