import { Divider, IconButton } from '@mui/material';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { FWMenu, FWMenuItem } from '../../../../Common/FWCustomMenu';
import { FWTooltip } from '../../../../Common/FWCustomTooltip';
import { FWPopup } from '../../../../Common/FWPopup';
import {
    ConfirmationColors,
    useConfirmation,
} from '../../../../Common/Hooks/ConfirmationHook';
import {
    IIdentificationDetail,
    IdentificationState,
} from '../../Interfaces/IdentificationInterface';
import { updateIdentificationDetail } from '../../Services/IdentificationService';

export interface IIdentificationUpdateValues {
    index: number;
    key: string;
    value: boolean | string;
}

const IdentificationsListOptionsMenu = ({
    data,
    context,
}: {
    data: IIdentificationDetail;
    context: {
        canEditDefault: boolean;
        handleItemEmitAction: (data: IIdentificationUpdateValues) => void;
    };
}) => {
    const { handleItemEmitAction, canEditDefault } = context;
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const openMenu = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const [
        confirmationValues,
        updateConfirmationDetails,
        resetConfirmationValues,
    ] = useConfirmation();

    const [loading, setLoading] = useState<boolean>(false);

    const handleMenuClick = (
        title: string,
        message: string,
        key: string,
        value: boolean | string,
        color: ConfirmationColors
    ) => {
        handleCloseMenu();
        updateConfirmationDetails({
            title,
            message,
            key,
            value,
            color,
        });
    };

    const handleConfirmationToggle = () => {
        resetConfirmationValues();
    };

    const handleUpdateIdentification = async () => {
        setLoading(true);
        // TODO: fix any type for mapping in service layer
        const payload: any = {
            isPublic: data.isPublic,
            isDefault: data.isDefault,
            status: data.status,
            notes: data.notes || '',
            attachments: data.attachments,
        };
        payload[confirmationValues.key] = confirmationValues.value;
        const updatedIdentification = await updateIdentificationDetail(
            data?.entityId || '',
            data.id,
            payload
        );
        if (updatedIdentification) {
            //
            handleItemEmitAction({
                index: data.index || 0,
                key: 'isDefault',
                value: updatedIdentification.isDefault,
            });
            handleItemEmitAction({
                index: data.index || 0,
                key: 'isPublic',
                value: updatedIdentification.isPublic,
            });
            handleItemEmitAction({
                index: data.index || 0,
                key: 'status',
                value: updatedIdentification.status,
            });
            resetConfirmationValues();
            handleCloseMenu();
            // toast.success(`Identification updated successfully.`);
        } else {
            toast.error(`Identification updated failed.`);
        }
        setLoading(false);
    };

    // // popup messages
    // default
    const isDefaultTitle = data.isDefault
        ? `Remove from defaults`
        : `Add as default`;
    const isDefaultMessage = data.isDefault
        ? `This identification will be no longer be included by default in documents like POs and invoices`
        : `This identification will be included in documents like POs and invoices by default`;

    // public
    const isPublicTitle = data.isPublic
        ? `Set visibility to private`
        : `Set visibility to public`;
    const isPublicMessage = data.isPublic
        ? `Other organizations will not be able to access this identification when creating documents like POs or invoices for you`
        : `Other organizations will be able to access this identification when creating documents like POs or invoices for you`;

    // status
    const enabledStatus = data.status === IdentificationState.ACTIVE;
    const enabledStatusTitle = enabledStatus
        ? `Disable identification`
        : `Enable identification`;
    const enabledStatusMessage = enabledStatus
        ? `This identification will no longer be available to add to documents like POs or invoices and all addresses will be unlinked.`
        : `This identification will become available for use in documents like POs or invoices`;
    const enabledStatusValue = enabledStatus
        ? IdentificationState.INACTIVE
        : IdentificationState.ACTIVE;

    const canAccessMenu = !(
        (
            data.isDefault &&
            // !canEditDefault &&
            data.status === IdentificationState.ACTIVE &&
            !canEditDefault
        ) /*  &&
        data.identificationStatus === IdentificationStatus.VERIFIED */
    );
    return (
        <>
            <div style={{ margin: '0 auto' }}>
                <IconButton
                    color="primary"
                    ref={(ref) => {
                        if (!ref) return;
                        ref.onclick = (e: any) => {
                            e.stopPropagation();
                            handleClick(e);
                        };
                    }}
                >
                    <i className="bi bi-three-dots fs--14"></i>
                </IconButton>
            </div>
            <FWMenu
                anchorEl={anchorEl}
                open={openMenu}
                onClose={handleCloseMenu}
                PaperProps={{
                    sx: {
                        filter: 'drop-shadow(0px 3px 6px rgba(0,0,0,0.16))',
                    },
                }}
            >
                {/* default menu item */}
                {data.status !== 'INACTIVE' && (
                    <FWTooltip
                        title={
                            enabledStatus
                                ? !canAccessMenu
                                    ? 'The only verified identification cannot be disabled'
                                    : data?.isPublic
                                    ? ''
                                    : 'If the identification is set default, the visibility will set to public'
                                : data.isDefault
                                ? `Cannot remove disabled identification from defaults`
                                : `Cannot set disabled identification as default`
                        }
                        placement={'left'}
                    >
                        <div>
                            <FWMenuItem
                                onClick={() => {
                                    handleMenuClick(
                                        isDefaultTitle,
                                        isDefaultMessage,
                                        'isDefault',
                                        !data?.isDefault,
                                        data?.isDefault ? 'error' : 'primary'
                                    );
                                }}
                                // disabled={
                                //     !data ||
                                //     !data.isPublic ||
                                //     !canAccessMenu ||
                                //     !enabledStatus
                                // }
                                disabled={
                                    data.isDefault
                                        ? !data || !canEditDefault
                                        : false
                                }
                                sx={{
                                    color: data.isDefault
                                        ? 'error.main'
                                        : 'primary.main',
                                }}
                            >
                                {isDefaultTitle}
                            </FWMenuItem>
                        </div>
                    </FWTooltip>
                )}

                {/* public menu item */}
                {data.status !== 'INACTIVE' && (
                    <FWTooltip
                        title={
                            enabledStatus
                                ? !canAccessMenu ||
                                  (enabledStatus && data?.isDefault)
                                    ? 'Cannot set default identification as private'
                                    : ''
                                : 'Cannot change visibility of disabled identification'
                        }
                        placement="left"
                    >
                        <div>
                            <FWMenuItem
                                onClick={() => {
                                    handleMenuClick(
                                        isPublicTitle,
                                        isPublicMessage,
                                        'isPublic',
                                        !data?.isPublic,
                                        data?.isPublic ? 'error' : 'primary'
                                    );
                                }}
                                disabled={
                                    (enabledStatus && data?.isDefault) ||
                                    !canAccessMenu ||
                                    !enabledStatus
                                }
                                sx={{
                                    color: data.isPublic
                                        ? 'error.main'
                                        : 'primary.main',
                                }}
                            >
                                {isPublicTitle}
                            </FWMenuItem>
                        </div>
                    </FWTooltip>
                )}

                {data.status !== 'INACTIVE' && (
                    <Divider
                        light
                        sx={{
                            margin: '0 !important',
                        }}
                    />
                )}

                {/* status menu item */}
                <FWTooltip
                    title={
                        !canAccessMenu
                            ? 'Entity must have at least 1 default enabled identification'
                            : ''
                    }
                    placement="left"
                >
                    <div>
                        <FWMenuItem
                            sx={{
                                color: enabledStatus
                                    ? 'error.main'
                                    : 'primary.main',
                            }}
                            onClick={() => {
                                handleMenuClick(
                                    enabledStatusTitle,
                                    enabledStatusMessage,
                                    'status',
                                    enabledStatusValue,
                                    enabledStatusValue ===
                                        IdentificationState.ACTIVE
                                        ? 'primary'
                                        : 'error'
                                );
                            }}
                            disabled={!canAccessMenu}
                        >
                            {enabledStatusTitle}
                        </FWMenuItem>
                    </div>
                </FWTooltip>
            </FWMenu>

            <FWPopup
                title={confirmationValues.title}
                msg={confirmationValues.message}
                btnColor={confirmationValues.color}
                btnTitle="Confirm"
                open={confirmationValues.open}
                setOpen={handleConfirmationToggle}
                onClickConfirm={handleUpdateIdentification}
                btnLoading={loading}
            />
        </>
    );
};

export default IdentificationsListOptionsMenu;
