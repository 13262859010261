import { FWTypography } from '../../Common/FWTypograhy';
import image404 from '../../Assets/Images/404.png';
import '../../Theme/Partials/404.scss';

export default function NotFound404() {
    return (
        <div className="four04--container flex flex--aic">
            <div className="left">
                <FWTypography variant="h2" color="primary" fontWeight={600}>
                    404
                </FWTypography>
                <FWTypography
                    variant="h2"
                    fontWeight={600}
                    sx={{
                        lineHeight: 1.2,
                        marginBottom: '15px',
                    }}
                >
                    Ooops! You weren't
                    <br /> supposed to see this
                </FWTypography>
                <FWTypography color="text.secondary">
                    The page you're looking for no longer exists
                </FWTypography>
                <FWTypography color="text.secondary">
                    Return to the{' '}
                    <a
                        href="/"
                        style={{
                            color: '#007AFF',
                        }}
                    >
                        home page
                    </a>{' '}
                    and remember: you haven't seen anything
                </FWTypography>
            </div>
            <div className="right">
                <img src={image404} style={{ maxWidth: '100%' }} alt="404" />
            </div>
        </div>
    );
}
