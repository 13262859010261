import {
    FormControl,
    Select,
    TextField,
    InputAdornment,
    styled,
} from '@mui/material';
import { FWTypography } from './FWTypograhy';

export const CustomTextField = styled(TextField)({
    '&': {
        borderRadius: '12px',
        padding: '0px',
    },
    '& .MuiInputBase-root ': {
        padding: '0px 5px',
    },
    '& .MuiInputBase-input': {
        padding: '0 5px',
        minHeight: '40px',
    },
});

export const CustomBidTextField = styled(TextField)({
    '& .MuiOutlinedInput-root': {
        borderRadius: '12px',
        padding: '0px',
    },
    '& .MuiInputBase-root ': {
        padding: '0px 5px',
    },
    '& .MuiInputBase-input': {
        padding: '0 5px',
        minHeight: '40px',
    },
});
export const CustomFormControl = styled(FormControl)({
    '& .MuiInputBase-root': {
        // borderRadius: "12px",
        boxShadow: 'none',
        height: '40px',
    },
    // FIXME: not working
    '& .MuiPaper-root': {
        boxShadow: 'none',
    },
});
export const CustomSelect = styled(Select)({
    '& .MuiPaper-root': {
        boxShadow: 'none',
    },
});

interface IInputField {
    title?: any;
    type: 'NUM' | 'DEC' | 'ALPHA_NUM' | 'ONLY_ALPHA' | 'ICON';
    icon_classname?: string;
    label_classname?: string;
    props: any;
}
export const CustomInputField = ({
    title,
    label_classname,
    type,
    icon_classname,
    props,
}: IInputField) => {
    let displayTextField;
    switch (type) {
        case 'NUM':
            displayTextField = <CustomNumberTextField {...props} />;
            break;
        case 'DEC':
            displayTextField = <CustomDecimalTextField {...props} />;
            break;
        case 'ONLY_ALPHA':
            displayTextField = <CustomOnlyAlphabetTextField {...props} />;
            break;
        case 'ALPHA_NUM':
            displayTextField = <CustomAlphaNumTextField {...props} />;
            break;
        case 'ICON':
            displayTextField = (
                <CustomIconTextField
                    icon_classname={icon_classname}
                    {...props}
                />
            );
            break;
        default:
            break;
    }
    return (
        <div style={{ marginLeft: '10px', ...props.formStyle }}>
            {!icon_classname && (
                <FWTypography
                    variant="body1"
                    sx={{
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        color: 'text.secondary',
                    }}
                >
                    {title} &nbsp;
                    {label_classname && (
                        <i
                            style={{ fontSize: '1rem' }}
                            className={label_classname}
                        />
                    )}
                </FWTypography>
            )}
            {displayTextField}
        </div>
    );
};

const handleKeyDownDecimal = (e: any) => {
    if (
        e.keyCode === 109 ||
        e.keyCode === 189 ||
        e.keyCode === 190 ||
        e.keyCode === 107 ||
        e.keyCode === 69
    ) {
        e.preventDefault();
    }
};

const handleKeyDownNumber = (e: any) => {
    if (
        e.keyCode === 109 ||
        e.keyCode === 110 ||
        e.keyCode === 190 ||
        e.keyCode === 187 ||
        e.keyCode === 107 ||
        e.keyCode === 189 ||
        e.keyCode === 69
    ) {
        e.preventDefault();
    }
};

const handleKeyDownText = (e: any) => {
    if (
        e.keyCode === 188 ||
        e.keyCode === 191 ||
        e.keyCode === 190 ||
        (e.target.value.length > 19 && e.keyCode !== 46 && e.keyCode !== 8)
    )
        e.preventDefault();
};

export const handleKeyDownOnlyText = (e: any) => {
    if (
        (e.keyCode < 64 && e.keyCode !== 46 && e.keyCode !== 8) ||
        (e.keyCode > 91 && e.keyCode !== 46 && e.keyCode !== 8)
    )
        e.preventDefault();
};

export const CustomNumberTextField = (props: any) => {
    return (
        <CustomTextField
            {...props}
            onWheel={(e: any) => e.target.blur()}
            onKeyDown={handleKeyDownNumber}
            InputProps={{
                inputProps: { min: 0 },
            }}
            type="number"
        />
    );
};

export const CustomDecimalTextField = (props: any) => {
    return (
        <CustomTextField
            {...props}
            required
            onWheel={(e: any) => e.target.blur()}
            onKeyDown={handleKeyDownDecimal}
            InputProps={{
                inputProps: { step: '.01', min: 0 },
                ...props.InputProps,
            }}
            type="number"
        />
    );
};

export const CustomAlphaNumTextField = (props: any) => {
    return (
        <div style={{ height: 'max-content', width: '100%' }}>
            <CustomTextField {...props} onKeyDown={handleKeyDownText} />
        </div>
    );
};

export const CustomOnlyAlphabetTextField = (props: any) => {
    return (
        <div style={{ height: 'max-content', width: '100%' }}>
            <CustomTextField {...props} onKeyDown={handleKeyDownOnlyText} />
        </div>
    );
};

export const CustomIconTextField = (props: any) => {
    return (
        <div style={{ height: 'max-content', width: '100%' }}>
            <CustomTextField
                {...props}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <i className="bi bi-search" />
                            {/* <i className={icon_classname}/> */}
                        </InputAdornment>
                    ),
                }}
                onKeyDown={handleKeyDownOnlyText}
            />
        </div>
    );
};
