import { Box, Chip } from '@mui/material';
import { ColDef } from 'ag-grid-community';
import { FWTooltip } from '../../../Common/FWCustomTooltip';
import FWIcon from '../../../Common/FWIcon';
import { FWTypography } from '../../../Common/FWTypograhy';
import IdentificationStatusChip, {
    getIdentificationStatus,
} from '../Components/Enterprise/IdentificationStatusChip';
import {
    IIdentificationDetail,
    IdentificationState,
    IdentificationStatus,
} from '../Interfaces/IdentificationInterface';
import IdentificationsListOptionsMenu from '../Pages/Identifications/IdentificationsListOptionsMenu';

export const identificationsListColumns: (showOptions: boolean) => ColDef[] = (
    showOptions
) => [
    {
        field: 'name',
        headerName: 'ID name',
        flex: 4,
        minWidth: 110,
        cellRenderer: ({ data }: { data: IIdentificationDetail }) => (
            <Box className="flex flex--aic" maxWidth={'100%'}>
                <FWTypography color="text.secondary">{data.name}</FWTypography>
                {data.status === IdentificationState.INACTIVE ? (
                    <Chip label="Disabled" size="small" className="ml--15" />
                ) : data.isDefault ? (
                    <FWTooltip title="Will be included in documents like POs and invoices by default">
                        <div>
                            <Chip
                                label="Included in POs"
                                size="small"
                                className="ml--15"
                            />
                        </div>
                    </FWTooltip>
                ) : (
                    <></>
                )}
            </Box>
        ),
        sortable: true,
        filter: true,
        valueGetter: ({ data }: { data: IIdentificationDetail }) => data.name,
        getQuickFilterText: ({ data }: { data: IIdentificationDetail }) =>
            data.name,
    },
    {
        field: 'value',
        headerName: 'ID value',
        flex: 3,
        minWidth: 110,
        cellRenderer: (params: any) => (
            <FWTypography color="text.secondary">{params.value}</FWTypography>
        ),
        sortable: true,
        filter: true,
        valueGetter: ({ data }: { data: IIdentificationDetail }) => data.value,
        getQuickFilterText: ({ data }: { data: IIdentificationDetail }) =>
            data.value,
    },
    {
        field: 'linked_addresses',
        headerName: 'Linked Address(s)',
        flex: 3.5,
        minWidth: 110,
        cellRenderer: (params: any) => (
            <FWTypography color="text.secondary" sx={{ marginX: 'auto' }}>
                {params.data?.status === IdentificationState.INACTIVE
                    ? 0
                    : params.value?.length}
            </FWTypography>
        ),
        sortable: true,
        filter: true,
        // valueGetter: ({ data }: { data: IIdentificationDetail }) => data.value,
        // getQuickFilterText: ({ data }: { data: IIdentificationDetail }) =>
        //     data.value,
    },
    {
        field: 'identificationStatus',
        headerName: 'Status',
        flex: 2,
        width: 150,
        cellRenderer: ({ data }: { data: IIdentificationDetail }) => (
            <IdentificationStatusChip status={data.identificationStatus} />
        ),
        sortable: true,
        filter: true,
        valueGetter: ({ data }: { data: IIdentificationDetail }) =>
            getIdentificationStatus(data.identificationStatus).label,
        getQuickFilterText: ({ data }: { data: IIdentificationDetail }) =>
            getIdentificationStatus(data.identificationStatus).label,
    },
    {
        field: 'isPublic',
        headerName: 'Visibility',
        flex: 1,
        minWidth: 110,
        cellRenderer: ({ data }: { data: IIdentificationDetail }) => (
            <FWTooltip title={data.isPublic ? 'Public' : 'Private'}>
                <div style={{ margin: '0 auto' }}>
                    {data.isPublic ? (
                        <FWIcon
                            name="bi bi-eye"
                            size={19}
                            color="success.main"
                        />
                    ) : (
                        <FWIcon
                            name="bi bi-eye-slash"
                            size={19}
                            color="text.secondary"
                        />
                    )}
                </div>
            </FWTooltip>
        ),
    },
    // {
    //     field: 'isDefault',
    //     headerName: 'Default',
    //     flex: 1,
    //     minWidth: 110,
    //     headerClass: 'center',
    //     cellRenderer: (params: any) =>
    //         params.value ? (
    //             <FWTooltip title="Will be included in documents like POs and invoices by default">
    //                 <Box
    //                     color="success.main"
    //                     sx={{ width: '100%', textAlign: 'center' }}
    //                 >
    //                     <i className="bi bi-check fs--20"></i>
    //                 </Box>
    //             </FWTooltip>
    //         ) : (
    //             <></>
    //         ),
    // },
    ...(showOptions
        ? [
              {
                  field: 'options',
                  headerName: 'Options',
                  flex: 0.5,
                  minWidth: 100,
                  headerClass: 'center',
                  cellRenderer: IdentificationsListOptionsMenu,
              },
          ]
        : []),
];

export const identificationStatusMap: {
    [key: string]: string;
} = {
    [IdentificationStatus.VERIFIED]: 'Verified',
    [IdentificationStatus.PENDING]: 'Pending',
    [IdentificationStatus.UNVERIFIED]: 'Unverified',
};

export const identificationVerificationStatusMapService: {
    [key: string]: IdentificationStatus;
} = {
    VERIFIED: IdentificationStatus.VERIFIED,
    PENDING: IdentificationStatus.PENDING,
    UNVERIFIED: IdentificationStatus.UNVERIFIED,
};
