import { IUserDetails } from '../../Models/UserDetails.model';
import { UserRoles } from '../../Organizations/Admin/Interfaces/UsersInterface';
import { get, post, put } from '../../Utils/api';
import {
    IInvitee,
    IInviteeResponse,
    inviteeRole,
} from '../Interfaces/NavbarUsersInterfaces';

export const getAllEnterpriseUsers = async (): Promise<IUserDetails[]> => {
    return new Promise(async (resolve, reject) => {
        try {
            const response: any = await get<any>(`/organization/users/`);
            resolve(response.data);
        } catch (err) {
            reject(err);
        }
    });
};

export const getApprovalPendingUsers = async (): Promise<
    IInviteeResponse[]
> => {
    return new Promise(async (resolve, reject) => {
        try {
            const response: any = await get<any>(
                // `/organization/users/?status=REQUESTED`
                `/invitation/join_request/list/`
            );
            resolve(response.data);
        } catch (err) {
            reject(err);
        }
    });
};

export const getEnterpriseEventCount = async (): Promise<{
    event_count: number;
}> => {
    return new Promise(async (resolve, reject) => {
        try {
            // TODO: Undo these changes once API starts working
            resolve({ event_count: 0 });
            // const response: any = await get<any>(
            //     `/events/count/?event_type_list[]=PO_GROUP&event_type_list[]=RFQ`
            // );
            // resolve(response.data);
        } catch (err) {
            reject(err);
        }
    });
};

interface ISendInvitePayload {
    email: string;
    name: string;
    user_role: 'USER' | 'ADMIN';
    designation: string;
    managers: string[];
    entity_list: string[];
    permission_role: inviteeRole;
}

export const sendInvitations = async (
    inviteList: IInvitee[]
): Promise<void> => {
    return new Promise(async (resolve, reject) => {
        try {
            const payload: ISendInvitePayload[] = inviteList
                .filter((invitee) => invitee.email.trim() !== '')
                .map((invitee) => {
                    return {
                        email: invitee.email,
                        name: invitee.name,
                        user_role: invitee.isAdmin ? 'ADMIN' : 'USER',
                        designation: '',
                        managers: invitee.managers,
                        entity_list: invitee.entities,
                        permission_role: invitee.role!,
                        skip_onboarding: invitee.skip_onboarding,
                    };
                });
            await post<ISendInvitePayload[], any>(
                `/organization/users/admin/entities/add/`,
                payload
            );
            resolve();
        } catch (err) {
            reject(err);
        }
    });
};

interface IDecideRequestPayload {
    action_user_id: string;
    user_role: UserRoles;
    entity_list: string[];
    permission_role: inviteeRole | null;
    managers: string[];
    action: 'ACCEPT' | 'REJECT';
}

export const decideJoinRequests = async (
    decisionList: IInvitee[]
): Promise<void> => {
    return new Promise(async (resolve, reject) => {
        try {
            const payload: IDecideRequestPayload[] = decisionList
                .filter((user) => user.decision !== 'PENDING')
                .map((user) => {
                    return {
                        email: user.email,
                        // name: invitee.name,
                        action_user_id: user.user_id,
                        name: user.name,
                        user_role: (user.isAdmin
                            ? 'ADMIN'
                            : 'USER') as UserRoles,
                        designation: '',
                        managers: user.isAdmin ? [] : user.managers,
                        entity_list: user.entities,
                        permission_role: user.isAdmin ? null : user.role,
                        action: (user.decision === 'APPROVE'
                            ? 'ACCEPT'
                            : user.decision) as 'ACCEPT' | 'REJECT',
                        skip_onboarding: user.skip_onboarding,
                    };
                });
            await put<IDecideRequestPayload[], any>(
                // `/organization/users/admin/entities/temporary`,
                `/organization/users/request/entities/action/`,
                payload
            );
            resolve();
        } catch (err) {
            reject(err);
        }
    });
};
