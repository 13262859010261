import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { NotificationActions } from '../../Redux/Reducers/NotificationsReducers';
import {
    updateNotification,
    updateNotificationList,
} from '../../Redux/Slices/NotificationsSlice';
import {
    INotification,
    NotificationStatus,
} from '../Interfaces/NotificationInterfaces';
import {
    acknowledgeNotifications,
    fetchNotifications,
} from '../Services/NotificationService';

export const useNotifications = () => {
    const dispatch = useDispatch();

    // const {
    //     notificationsList,
    // }: {
    //     notificationsList: INotification[];
    // } = useSelector((state: any) => state.NotificationsStore);

    const getNotifications = useCallback(async () => {
        const notifications = await fetchNotifications();
        dispatch(
            updateNotificationList({
                type: NotificationActions.INIT,
                value: notifications,
            })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const markRead = async (notificationId: string): Promise<boolean> => {
        try {
            const markedRead = await acknowledgeNotifications([notificationId]);
            dispatch(
                updateNotification({
                    id: notificationId,
                    value: NotificationStatus.READ,
                })
            );
            return markedRead;
        } catch (error) {}
        return false;
    };

    const markAllRead = async (
        filteredNotificationList: INotification[]
    ): Promise<boolean> => {
        try {
            const allNotificationIds = filteredNotificationList
                .filter(
                    (notification) =>
                        notification.status === NotificationStatus.UNREAD
                )
                .map((notification) => notification.id);
            const acknowledged = await acknowledgeNotifications(
                allNotificationIds
            );
            if (acknowledged) getNotifications(); // fetch latest notifications when all are marked as read
            return true;
            // notificationsList.forEach(async (notification) => {
            //     dispatch(
            //         updateNotification({
            //             id: notification.id,
            //             value: NotificationStatus.READ,
            //         })
            //     );
            // });
        } catch (error) {}
        return false;
    };

    useEffect(() => {
        getNotifications();
        const notificationsInterval = setInterval(async () => {
            const notifications = await fetchNotifications();
            if (notifications.length > 0) {
                dispatch(
                    updateNotificationList({
                        type: NotificationActions.INIT,
                        value: notifications as INotification[],
                    })
                );
            }
        }, 1000 * 60 * 2); // 2 minutes

        return () => {
            clearInterval(notificationsInterval);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const NotificationProviders = {
        markRead,
        markAllRead,
    };

    return NotificationProviders;
};
