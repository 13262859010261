import { useCallback, useEffect, useState } from 'react';
import {
    HookStateValue,
    IHookState,
    useHookState,
} from '../../../../Common/Hooks/StateHook';
import {
    IEntityVendorListSummary,
    IVendorBuyerEntity,
    IVendorListSummary,
    IVendorTemplateFrontEnd,
    VendorStatus,
} from '../../Interfaces/VendorsInterface';
import {
    fetchEntityVendorsList,
    fetchVendorsList,
} from '../../Services/VendorsService';
import { useVendorTemplateHook } from './useVendorTemplateHook';

export interface IVendorsListProviders {
    hookState: IHookState;
    vendorsList: IVendorListSummary[];
    getVendorsList: () => void;
    templateDetails: IVendorTemplateFrontEnd;
    updateVendorBuyerEntities: (
        index: number,
        buyer: IVendorBuyerEntity[]
    ) => void;
    updateVendorStatus: (vendorDirUid: string, status: VendorStatus) => void;
}

export const useVendorsList = (filter?: boolean) => {
    const [vendorsList, setVendorsList] = useState<IVendorListSummary[]>([]);

    const { hookState, updateHookState } = useHookState(HookStateValue.LOADING);

    const { templateDetails } = useVendorTemplateHook();

    const getVendorsList = useCallback(async () => {
        updateHookState(HookStateValue.LOADING);
        try {
            const vendors: IVendorListSummary[] = await fetchVendorsList(
                filter
            );
            setVendorsList(vendors);
            updateHookState(HookStateValue.READY);
        } catch (error) {
            updateHookState(HookStateValue.ERROR);
        }
    }, [updateHookState, filter]);

    const updateVendorBuyerEntities = (
        index: number,
        buyer: IVendorBuyerEntity[]
    ) => {
        setVendorsList((prevList) => {
            const vendor = prevList[index];
            if (vendor) vendor.buyerEntities = buyer;
            return [...prevList];
        });
    };

    const updateVendorStatus = (vendorDirUid: string, status: VendorStatus) => {
        if (status === VendorStatus.DELETED) {
            setVendorsList((prevList) => {
                // const newList = [...prevList];
                // newList.splice(index, 1);
                // return newList;
                return prevList.filter(
                    (val) => val.vendorDirUid !== vendorDirUid
                );
            });
        } else {
            setVendorsList((prevList) => {
                // const vendor = prevList[index];
                // vendor.vendorStatus = status;
                // return [...prevList];
                return prevList.map((val) => {
                    if (val.vendorDirUid === vendorDirUid) {
                        val.vendorStatus = status;
                    }
                    return val;
                });
            });
        }
    };

    useEffect(() => {
        getVendorsList();
    }, [getVendorsList]);

    const userListProviders: IVendorsListProviders = {
        hookState,
        vendorsList,
        getVendorsList,
        updateVendorBuyerEntities,
        updateVendorStatus,
        templateDetails,
    };

    return userListProviders;
};

export interface IEntityVendorsListProviders {
    hookState: IHookState;
    entityVendorsList: IEntityVendorListSummary[];
    getVendorsList: () => void;
}

export const useEntityVendorsList = (entityUid: string) => {
    const [entityVendorsList, setEntityVendorsList] = useState<
        IEntityVendorListSummary[]
    >([]);

    const { hookState, updateHookState } = useHookState(HookStateValue.LOADING);

    const getVendorsList = useCallback(async () => {
        updateHookState(HookStateValue.LOADING);
        try {
            const vendors: IEntityVendorListSummary[] =
                await fetchEntityVendorsList(entityUid);
            setEntityVendorsList(vendors);
            updateHookState(HookStateValue.READY);
        } catch (error) {
            updateHookState(HookStateValue.ERROR);
        }
    }, [updateHookState, entityUid]);

    useEffect(() => {
        getVendorsList();
    }, [getVendorsList]);

    const userListProviders: IEntityVendorsListProviders = {
        hookState,
        entityVendorsList,
        getVendorsList,
    };

    return userListProviders;
};
