import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FWPopup } from '../../../../Common/FWPopup';
import { FWTypography } from '../../../../Common/FWTypograhy';
import { useConfirmation } from '../../../../Common/Hooks/ConfirmationHook';
import {
    IItemEntityDetail,
    ItemEntityStatus,
} from '../../Interfaces/ItemDirectoryInterface';
import { updateEntitiesList } from '../../Services/ItemDirectoryService';
import { AuthContext } from '../../../../Contexts/AuthContext';

const ItemEntitiesListOptionsMenu = ({
    row,
    emitItemAction,
}: {
    row: IItemEntityDetail;
    emitItemAction: () => void;
}) => {
    const { id: itemId }: { id: string } = useParams();
    const [loading, setLoading] = useState<boolean>(false);
    // const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
    // const openMenu = Boolean(menuAnchorEl);
    // const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    //     event.stopPropagation();
    //     setMenuAnchorEl(event.currentTarget);
    // };
    // const handleCloseMenu = () => {
    //     setMenuAnchorEl(null);
    // };

    const { authData } = useContext(AuthContext);

    const isAdmin = authData.details?.role === 'ADMIN';

    const [
        confirmationValues,
        updateConfirmationValues,
        resetConfirmationValues,
    ] = useConfirmation();

    const handleConfirmationToggle = () => {
        resetConfirmationValues();
    };

    const handleItemUpdate = async () => {
        setLoading(true);
        const entityUpdated = await updateEntitiesList(itemId, {
            entityId: row.entityId,
            status: confirmationValues.value as ItemEntityStatus,
        }); // api call here
        if (entityUpdated) {
            emitItemAction();
            handleConfirmationToggle();
        } else {
            toast.error(`Item updated failed.`);
        }
        setLoading(false);
    };

    // // popup messages
    // status
    const status: boolean = row.status === ItemEntityStatus.ACTIVE;
    const statusTitle = isAdmin
        ? status
            ? `Unlink`
            : `Link`
        : status
        ? 'Linked'
        : 'Unlinked';
    const statusMessage = `Do you wish to ${
        status ? 'unlink' : 'link'
    } the item to ${row.entityName}?`;
    const statusValue = status
        ? ItemEntityStatus.INACTIVE
        : ItemEntityStatus.ACTIVE;

    return (
        <>
            {/* <IconButton
                ref={(ref) => {
                    if (!ref) return;

                    ref.onclick = (e: any) => {
                        e.stopPropagation();
                        handleOpenMenu(e);
                    };
                }}
                color="primary"
            >
                <i className="bi bi-three-dots fs--14"></i>
            </IconButton> */}
            {/* <FWMenu
                anchorEl={menuAnchorEl}
                open={openMenu}
                onClose={handleCloseMenu}
            >
                <FWMenuItem
                    sx={{ color: status ? 'error.main' : 'primary.main' }}
                    onClick={() => {
                        updateConfirmationValues({
                            title: statusTitle,
                            message: statusMessage,
                            key: 'status',
                            value: statusValue,
                        });
                        handleCloseMenu();
                    }}
                > */}
            <FWTypography
                sx={{ cursor: isAdmin ? 'pointer' : 'default' }}
                color={status ? 'error.main' : 'primary.main'}
                fontSize={17}
                onClick={() => {
                    isAdmin &&
                        updateConfirmationValues({
                            title: statusTitle,
                            message: statusMessage,
                            key: 'status',
                            value: statusValue,
                        });
                }}
            >
                {statusTitle}
            </FWTypography>
            {/* </FWMenuItem>
            </FWMenu> */}

            <FWPopup
                title={confirmationValues.title}
                msg={confirmationValues.message}
                btnColor="primary"
                btnTitle="Yes"
                open={confirmationValues.open}
                setOpen={handleConfirmationToggle}
                onClickConfirm={handleItemUpdate}
                btnLoading={loading}
            />
        </>
    );
};

export default ItemEntitiesListOptionsMenu;
