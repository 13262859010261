import { IRFQItemCustomIDs } from '../../Events/Interfaces/Buyer/SharedRFQ.model';
import {
    BackendDeliverablePaymentTerms,
    BackendInvoicePaymentTerms,
    IAttachment,
} from '../../Models/RFQ.model';
import { IInvoiceCustomTemplate } from './Shared.model';

export interface IPOIDList {
    po_id: string;
    custom_po_id: string;
    termination_info: {
        notes: string;
        status: string;
    } | null;
    hold_information: {
        notes: string;
    } | null;
    buyer_entity_name: string;
    item_count: string;
    po_amount: string;
    po_issued_date: string;
}

export interface IInvoiceTopLevelDetails {
    custom_invoice_id: string;
    reference_id: string | null;
    invoice_date: string;
    expected_delivery_date: string | null;
    dispatch_date: string | null;
}

export interface IPostInvoiceDetails {
    purchase_order_id: string;
    custom_invoice_id: string;
    reference_id: string | null;
    invoice_date: string;
    expected_delivery_date: string;
    dispatch_date: string;
    seller_identifications: string[];
    seller_address_id: string;
    action: 'DRAFT' | 'ISSUED' | '';
    notes: string;
    discount_percentage: string;
    invoice_items: {
        [key: string]: IPostInvoiceItemDetails;
    };
    attachment_ids: string[];
}
export interface IPostInvoiceItemDetails {
    purchase_order_item_id: string;
    price: string;
    currency_code_id: string;
    shipping_per_unit: string;
    additional_charges: {
        charge_name: string;
        charge_value: string;
    }[];
    payment_terms: string;
    payment_terms_period: string;
    payment_terms_applied_from: string | null;
    all_batch_info: IPostInvoiceItemBatchDetails[];
}

export interface IPostInvoiceItemBatchDetails {
    invoice_item_id: string;
    item_batch_id: string;
    batch_expiry_date: string | null;
    batch_number: string;
    quantity: string;
    measurement_unit_id: string;
    notes: string;
    fulfillment_information?: {
        gr_acceptance: number;
        gr_rejection: number;
        gr_overdelivery: number;
        qc_rejection: number;
        invoice_termination_rejection: number;
    };
    custom_fields: IInvoiceCustomTemplate;
}

export interface IInvoice {
    invoice_id: string;
    invoice_type: InvoiceType;
    status: InvoiceStatus;
    custom_invoice_id: string;
    purchase_order: string;
    event: string | null;
    buyer_enterprise: string;
    buyer_entity: string;
    buyer_information: {
        buyer_entity_name: string;
        buyer_entity_address: string;
        buyer_identification_info: IEntityIdentification[];
        billing_address_id: string;
        billing_address: string;
        shipping_address_id: string;
        shipping_address: string;
    };

    seller_enterprise: string;
    seller_entity: string;
    seller_information: {
        seller_entity_name: string;
        seller_entity_address: string;
        seller_identification_information: IEntityIdentification[];
        seller_address_id: string;
    };

    invoice_date: string | null;
    dispatch_date: string | null;
    expected_delivery_date: string | null;
    pricing_information: {
        total: string;
        // shipping: string;
        // subtotal: string;
        currency_name: string;
        currency_symbol: string;
        // taxes_percentage: string;
        discount_percentage: string;
        currency_code_abbreviation: string;
    };
    notes: string;
    approver_information: {
        notes: string;
        user_id: string;
        date_time: string;
        entity_id: string;
        enterprise_type: string;
    } | null;
    invoice_items: IInvoiceItem[];
    created_by_user_name: string;
    attachment_list: {
        attachment_items?: IAttachment[];
    };
    attachments: IAttachment[];
    reference_id: string | null;
}

export interface IInvoiceWithGroupedItems {
    invoice_id: string;
    invoice_type: InvoiceType;
    status: InvoiceStatus;
    custom_invoice_id: string;
    purchase_order: string;
    event: string | null;
    buyer_enterprise: string;
    buyer_entity: string;
    buyer_information: {
        buyer_entity_name: string;
        buyer_entity_address: string;
        buyer_identification_info: IEntityIdentification[];
        billing_address_id: string;
        billing_address: string;
        shipping_address_id: string;
        shipping_address: string;
    };

    seller_enterprise: string;
    seller_entity: string;
    seller_information: {
        seller_entity_name: string;
        seller_entity_address: string;
        seller_identification_information: IEntityIdentification[];
        seller_address_id: string;
    };

    invoice_date: string | null;
    dispatch_date: string | null;
    expected_delivery_date: string | null;
    pricing_information: {
        total: string;
        // shipping: string;
        // subtotal: string;
        currency_name: string;
        currency_symbol: string;
        // taxes_percentage: string;
        discount_percentage: string;
        currency_code_abbreviation: string;
    };
    notes: string;
    approver_information: {
        notes: string;
        user_id: string;
        date_time: string;
        entity_id: string;
        enterprise_type: string;
    } | null;
    invoice_items: IGroupedInvoiceItem[];
    created_by_user_name: string;
    attachments: IAttachment[];
}

export interface IGroupedInvoiceItem {
    id?: number;
    purchase_order_item: {
        fulfilment_information: {
            delivered: string;
            discarded: string;
            invoiced: string;
            on_hold: string;
            over_delivered: string;
            rescheduled: string;
        };
        purchase_order_item_id: string;
    };
    buyer_entity: string;
    invoice: string;
    item_information: {
        buyer_item_id: string;
        global_item_id: string;
        buyer_item_name: string;
        custom_item_name: string;
        buyer_item_description: string;
        item_additional_details: string;
        custom_ids?: IRFQItemCustomIDs[];
    };
    pricing_information: IInvoiceItemPricingInformation;
    payment_terms_information: {
        payment_terms: string;
        payment_terms_period: string;
        payment_terms_applied_from: string;
    };
    attribute_information: {
        buyer_item_attributes: {
            attribute_name: string;
            attribute_value: string[];
            attribute_exclude: boolean;
        }[];
        custom_item_attributes: {
            attribute_name: string;
            attribute_value: string[];
            attribute_exclude: boolean;
        }[];
    };
    all_batch_info: IGroupedInvoiceItemBatch[];
    gr_tolerance_percentage: string;
}

export interface IGroupedInvoiceItemBatch {
    invoice_item_id: string;
    item_batch_id: string;
    status: 'GOODS_PENDING' | 'GOODS_RECEIVED' | 'COMPLETED' | 'TERMINATED';
    batch_number: string;
    batch_expiry_date: string | null;
    quantity_information: {
        quantity: string;
        measurement_unit_id: string;
        measurement_unit_name: string;
    };
    notes: string;
    hold_information: null | IInvoiceItemHoldInformation;
    termination_information: null | IInvoiceItemTerminationInformation;
    payment_status: 'PAYMENT_PENDING' | 'PAYMENT_COMPLETE';
    payment_information: {
        completed: string;
        computed: string;
        credited: string;
        due_date: null | string;
        pending: string;
    };
    fulfilment_information: {
        gr_rejection: string;
        gr_overdeliver: string;
        qc_rejection: string;
        invoice_termination_rejection: string;
    };
}

export interface IInvoiceItem {
    invoice_item_id: string;
    item_batch_id: string;
    invoice: string;
    purchase_order_item: {
        fulfilment_information: {
            delivered: string;
            discarded: string;
            invoiced: string;
            on_hold: string;
            over_delivered: string;
            rescheduled: string;
        };
        purchase_order_item_id: string;
    };
    status: InvoiceItemStatus;
    batch_number: string;
    batch_expiry_date: string | null;
    item_information: {
        buyer_item_id: string;
        global_item_id: string;
        buyer_item_name: string;
        custom_item_name: string;
        buyer_item_description: string;
        item_additional_details: string;
        custom_ids?: IRFQItemCustomIDs[];
    };
    quantity_information: {
        quantity: string;
        measurement_unit_id: string;
        measurement_unit_name: string;
        quantity_tolerance_percentage: string;
    };
    pricing_information: IInvoiceItemPricingInformation;
    payment_terms_information: {
        payment_terms: string;
        payment_terms_period: string;
        payment_terms_applied_from: string;
    };
    attribute_information: {
        buyer_item_attributes: {
            attribute_name: string;
            attribute_value: string[];
            attribute_exclude: boolean;
        }[];
        custom_item_attributes: {
            attribute_name: string;
            attribute_value: string[];
            attribute_exclude: boolean;
        }[];
    };
    notes: string;
    custom_fields: IInvoiceCustomTemplate;
    hold_information: null | IInvoiceItemHoldInformation;
    termination_information: null | IInvoiceItemTerminationInformation;
    payment_status: 'PAYMENT_PENDING' | 'PAYMENT_COMPLETE';
    payment_information: {
        completed: string;
        computed: string;
        credited: string;
        due_date: null | string;
        pending: string;
    };
    fulfilment_information: {
        gr_acceptance: string;
        gr_overdeliver: string;
        gr_rejection: string;
        qc_rejection: string;
        invoice_termination_rejection: string;
    };
}

export interface IInvoiceItemPricingInformation {
    price: string;
    currency_code_id: string;
    currency_code_abbreviation: string;
    shipping_per_unit: string;
    additional_charges: {
        charge_name: string;
        charge_value: string;
    }[];
}

export interface IEntityIdentification {
    identification_id: string;
    identification_name: string;
    identification_value: string;
}

export enum InvoiceStatus {
    DRAFT = 'DRAFT',
    ISSUED = 'ISSUED',
    ONGOING = 'ONGOING',
    GOODS_RECEIVED = 'GOODS_RECEIVED',
    COMPLETED = 'COMPLETED',
    RESCINDED = 'RESCINDED',
    DELETED = 'DELETED',
    TERMINATED = 'TERMINATED',
    EMPTY = '',
}

export enum InvoiceType {
    SELLER_GOODS = 'SELLER_GOODS_INVOICE',
    PROFORMA = 'SELLER_PROFORMA_INVOICE',
    BUYER_GR = 'BUYER_GR_INVOICE',
    PREPAYMENT = 'PREPAYMENT_INVOICE',
}

export enum InvoiceItemStatus {
    GOODS_PENDING = 'GOODS_PENDING',
    GOODS_RECEIVED = 'GOODS_RECEIVED',
    COMPLETED = 'COMPLETED',
    TERMINATED = 'TERMINATED',
}

export enum InvoiceItemStatusForDisplay {
    GOODS_PENDING = 'Goods pending',
    GOODS_PENDING_WITH_HOLD = 'Goods pending with hold',
    GOODS_PENDING_WITH_TERMINATION_REQUEST = 'Goods pending with termination request',
    GOODS_PENDING_WITH_TERMINATED = 'Goods pending with terminated ',
    GOODS_RECEIVED = 'Goods received',
    GOODS_RECEIVED_WITH_HOLD = 'Goods received with hold',
    GOODS_RECEIVED_WITH_TERMINATION_REQUEST = 'Goods received with termination request',
    GOODS_RECEIVED_WITH_TERMINATED = 'Goods received with terminated',
    COMPLETED = 'Completed',
    TERMINATED = 'Terminated',
    ON_HOLD = 'On hold',
    TERMINATION_REQUESTED = 'Termination requested',
    QUALITY_CHECK = 'Quality Check',
    QUALITY_CHECK_WITH_HOLD = 'Quality Check With hold',
    QUALITY_CHECK_WITH_TERMINATION_REQUEST = 'Quality Check with termination request',
    QUALITY_CHECK_WITH_TERMINATED = 'Quality Check with terminated',
    PAID = 'PAID',
    BLANK = '',
}

export enum InvoiceItemBatchStatus {}

export interface IInvoiceItemHoldInformation {
    status: 'AUTOMATIC' | 'MANUAL';
    notes: string;
    hold_datetime: string;
    user_id?: string | null;
}

export enum InvoiceItemHoldType {
    AUTOMATIC_HOLD = 'AUTOMATIC',
    MANUAL_HOLD = 'MANUAL',
}

export interface IInvoiceItemTerminationInformation {
    status:
        | 'INVOICE_ITEM_TERMINATION_BUYER_REQUESTED'
        | 'INVOICE_ITEM_TERMINATION_SELLER_ACCEPTED';
    buyer_user_id: string;
    buyer_datetime: string;
    buyer_notes: string;
    quantity: string;
    unpaid_termination_quantity: string | null;
    paid_termination_quantity: string | null;
    credit_note_amount: string | null;
    seller_user_id: string | null;
    seller_datetime: string | null;
    seller_notes: string | null;
}

export enum InvoiceItemTerminationStatus {
    REQUESTED = 'BUYER_REQUESTED',
    ACCEPTED = 'SELLER_ACCEPTED',
}

export interface IInvoiceList {
    invoice_id: string;
    reference_id: string | null;
    created_datetime: string;
    modified_datetime: string;
    deleted_datetime: string;
    buyer_information: {
        billing_address: string;
        shipping_address: string;
        buyer_entity_name: string;
        billing_address_id: string;
        shipping_address_id: string;
        buyer_entity_address: string;
        buyer_identification_info: {
            identification_id: string;
            identification_name: string;
            identification_value: string;
        }[];
    };
    seller_information: {
        seller_address_id: string;
        seller_entity_name: string;
        seller_entity_address: string;
        seller_identification_information: {
            identification_id: string;
            identification_name: string;
            identification_value: string;
        }[];
    };
    invoice_item_count: string;
    item_hold_status: 'MANUAL' | 'AUTOMATIC' | null;
    item_termination: boolean;
    custom_invoice_id: string;
    invoice_type: string;
    status: string;
    dispatch_date: string | null;
    invoice_date: string;
    expected_delivery_date: string | null;
    amount_pending: string;
    pricing_information: {
        total: string;
        shipping: string;
        subtotal: string;
        currency_name: string;
        currency_symbol: string;
        rejection_amount: string;
        taxes_percentage: string;
        discount_percentage: string;
        currency_code_abbreviation: string;
    };
    notes: string;
    approval_reason: string | null;
    payment_information: {
        credited: string;
        completed: string;
    };
    purchase_order: {
        buyer_entity: string;
        custom_purchase_order_id: string;
        hold_information: string | null;
        purchase_order_id: string;
        seller_entity: string;
        status: string;
        termination_information: {
            notes: string;
            status: string;
        } | null;
    };
    event: string;
    buyer_enterprise: string;
    seller_enterprise: string;
    buyer_entity: string;
    seller_entity: string;
    additional_information: {
        seller_prepayment_invoices?: string[];
    };
    approver_information: {
        notes: string;
        user_id: string;
        date_time: string;
        entity_id: string;
        enterprise_type: string;
    } | null;
    attachment_list: {
        attachment_items?: IAttachment[];
    };
    attachments: IAttachment[];
}

export interface IPrepayInvoiceDetails {
    invoice_id: string;
    invoice_items: IPrepaymentInvoiceItemDetails[];
    invoice_type: 'PREPAYMENT_INVOICE';
    buyer_information: {
        billing_address: string;
        shipping_address: string;
        buyer_entity_name: string;
        billing_address_id: string;
        shipping_address_id: string;
        buyer_entity_address: string;
        buyer_entity_address_id: string;
        billing_address_nickname: string;
        buyer_identification_info: {
            identification_id: string;
            identification_name: string;
            identification_value: string;
        }[];
        shipping_address_nickname: string;
        buyer_entity_address_nickname: string;
    };
    seller_information: {
        seller_address_id: string;
        seller_entity_name: string;
        seller_entity_address: string;
        seller_address_nickname: string;
        seller_identification_information: {
            identification_id: string;
            identification_name: string;
            identification_value: string;
        }[];
    };
    item_hold: boolean;
    item_termination: boolean;
    custom_invoice_id: string | null;
    reference_id: string | null;
    status: string;
    verification_status: 'UNVERIFIED' | 'VERIFIED';
    dispatch_date: string | null;
    invoice_date: string;
    expected_delivery_date: string | null;
    pricing_information: {
        total: string;
        shipping: string;
        subtotal: string;
        currency_name: string;
        currency_symbol: string;
        currency_code_id: string;
        rejection_amount: string;
        taxes_percentage: string | null;
        discount_percentage: null | string;
        currency_code_abbreviation: string;
    };
    payment_information: {
        credited: string;
        completed: string;
    };
    notes: string | null;
    approver_information: null;
    purchase_order: string;
    event: string;
    buyer_enterprise: string;
    seller_enterprise: string;
    buyer_entity: string;
    seller_entity: string;
    created_by_user_name: string | null;
}

export interface IPrepaymentInvoiceItemDetails {
    prepayment_percentage: string | number | null;
    payment_type: string;
    payment_terms: BackendInvoicePaymentTerms;
    deliverables_payment_terms: BackendDeliverablePaymentTerms;
    invoice_item_id: string;
    invoice: string;
    buyer_enterprise: string;
    seller_enterprise: string;
    buyer_entity: string;
    seller_entity: string;
    batch_number: null | string;
    batch_expiry_date: string | null;
    incoterm: string | null;
    incoterm_abbreviation: string | null;
    status: 'NOT_APPLICABLE';
    hold_information: null | IInvoiceItemHoldInformation;
    termination_information: null | IInvoiceItemTerminationInformation;
    purchase_order_item: {
        purchase_order_item_id: string;
        fulfilment_information: {
            on_hold: string;
            invoiced: string;
            delivered: string;
            discarded: string;
            rescheduled: string;
            over_delivered: string;
        };
    };
    item_information: {
        buyer_item_id: string;
        global_item_id: string;
        buyer_item_name: string;
        custom_item_name: string;
        buyer_item_description: string;
        item_additional_details: string;
        custom_ids?: IRFQItemCustomIDs[];
    };
    quantity_information: {
        quantity: string;
        measurement_unit_id: string;
        measurement_unit_name: string;
        quantity_tolerance_percentage: string;
        alternate_measurement_unit_list: [];
    };
    pricing_information: {
        total_amount: string;
        currency_name: string;
        currency_symbol: string;
        currency_code_id: string;
        rejection_amount: string;
        currency_code_abbreviation: string;
        seller_prepayment_amount: string;
    };
    payment_terms_information: null | {
        payment_terms: string;
        payment_terms_period: string;
        payment_terms_applied_from: string | null;
    };
    attribute_information: {
        buyer_item_attributes: {
            attribute_name: string;
            attribute_value: string[];
            attribute_exclude: false;
        }[];
        custom_item_attributes: {
            attribute_name: string;
            attribute_value: string[];
            attribute_exclude: false;
        }[];
    };
    property_information: [];
    payment_information: {
        credited: string;
        completed: string;
    };
    fulfilment_information: null;
    additional_information: null;
    notes: null;
}
