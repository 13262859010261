import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    DialogActions,
    DialogContent,
    FormControlLabel,
    FormGroup,
    IconButton,
} from '@mui/material';
import { cloneDeep } from 'lodash';
import { Dispatch, Fragment, SetStateAction, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { FWMenuItem } from '../../../../../Common/FWCustomMenu';
import { FWTooltip } from '../../../../../Common/FWCustomTooltip';
import FWIcon from '../../../../../Common/FWIcon';
import { FWInput } from '../../../../../Common/FWInput';
import { FWLink } from '../../../../../Common/FWLink';
import { FWPopupWithChildren } from '../../../../../Common/FWPopupWithChildren';
import FWSelect from '../../../../../Common/FWSelect';
import { FWTypography } from '../../../../../Common/FWTypograhy';
import { planUpgradeTooltipText } from '../../../../../FeatureAccess/Utils/planUpgradeTooltipText';
import {
    CustomTemplateItemTypes,
    ICustomTemplateList,
} from '../../../../../Global/Interfaces/CustomTemplate.interface';
import {
    ASModule,
    IAdminCustomFieldsAndChecklist,
} from '../../../Interfaces/AdminSettingsInterface';
import {
    createCustomTemplate,
    deleteCustomTemplate,
    updateCustomTemplate,
} from '../../../Services/AdminSettingsService';

interface IChecklistPopupProps {
    data: ICustomTemplateList;
    index: number;
    setAdminTemplateSettings: Dispatch<
        SetStateAction<IAdminCustomFieldsAndChecklist>
    >;
    disableCheckList: boolean;
    module_name: ASModule;
}

const ChecklistPopup = ({
    data,
    index,
    module_name,
    setAdminTemplateSettings,
    disableCheckList,
}: IChecklistPopupProps) => {
    const [checklistDetails, setChecklistDetails] = useState(cloneDeep(data));
    const [open, setOpen] = useState<boolean>(false);
    const [deletingChecklist, setDeletingChecklist] = useState<boolean>(false);
    const [errors, setErrors] = useState<{ [key: string]: string }>({});

    useEffect(() => {
        setChecklistDetails(cloneDeep(data));
    }, [data]);

    const handleFieldChange = (
        type: 'MANDATORY' | 'NAME' | 'TYPE',
        e: any,
        index: number
    ) => {
        let newObj = {
            ...checklistDetails,
            items: [...checklistDetails.items],
        };
        switch (type) {
            case 'MANDATORY':
                newObj.items[index].is_required = e.target.checked;
                break;
            case 'NAME':
                newObj.items[index].template_item_name = e.target.value;
                break;
            case 'TYPE':
                newObj.items[index].template_item_type = e.target.value;
                break;
        }
        let newErrors = calculateErrors(newObj);
        setChecklistDetails(newObj);
        setErrors(newErrors);
    };

    const handleRowUpdate = (type: 'ADD' | 'REMOVE', index: number) => {
        setChecklistDetails((prev) => {
            let newObj = { ...prev };
            switch (type) {
                case 'ADD':
                    newObj.items.push({
                        template_item_id: '',
                        template_item_name: '',
                        template_item_type: CustomTemplateItemTypes.SHORTTEXT,
                        is_required: false,
                        template: '',
                        additional_information: {},
                    });
                    break;
                case 'REMOVE':
                    newObj.items.splice(index, 1);
                    break;
            }

            return newObj;
        });
    };

    const saveChecklist = async () => {
        try {
            if (checklistDetails.template_id === '') {
                let resp = await createCustomTemplate(checklistDetails);
                setAdminTemplateSettings((prev) => {
                    let newObj = cloneDeep(prev);
                    newObj[module_name].checklist.push(resp);
                    return newObj;
                });
            } else {
                let resp = await updateCustomTemplate(checklistDetails);
                setAdminTemplateSettings((prev) => {
                    let newObj = cloneDeep(prev);
                    newObj[module_name].checklist[index] = resp;
                    return newObj;
                });
            }
        } catch (err) {
            toast.error("Couldn't update custom fields");
        }
        setOpen(false);
    };

    const deleteChecklist = async () => {
        try {
            setDeletingChecklist(true);
            await deleteCustomTemplate(
                checklistDetails.entity,
                checklistDetails.template_id
            );
            setAdminTemplateSettings((prev) => {
                let newObj = cloneDeep(prev);
                newObj[module_name].checklist.splice(index, 1);
                return newObj;
            });
        } catch (err) {
            toast.error("Couldn't delete checklist");
        }
        setDeletingChecklist(false);
        setOpen(false);
    };

    const calculateErrors = (checklistDetails: any) => {
        let newErrors: { [key: string]: string } = {};
        const namesList: string[] = [];
        if (checklistDetails.template_name.trim() === '') {
            newErrors['template_name'] = 'Name is required';
        }
        let idx = 0;
        for (const row of checklistDetails.items) {
            if (row.template_item_name.trim() === '') {
                newErrors[`template_item_name_${idx}`] = 'Name is required';
            } else if (row.template_item_type.trim() === '') {
                newErrors[`template_item_type_${idx}`] = 'Type is required';
            } else {
                namesList.push(row.template_item_name.trim().toLowerCase());
            }
            idx++;
        }
        namesList.forEach((item, index) => {
            if (namesList.indexOf(item) !== index) {
                newErrors[`template_item_name_${index}`] = 'Duplicate name';
            }
        });
        return newErrors;
    };

    return (
        <>
            <div className="flex flex--aic">
                <FWTypography>{index + 1}.&nbsp;</FWTypography>
                {disableCheckList ? (
                    <FWTypography>{data.template_name}</FWTypography>
                ) : (
                    <FWLink
                        color="primary.main"
                        onClick={() => setOpen(true)}
                        sx={{ cursor: 'pointer' }}
                    >
                        {data.template_name}
                    </FWLink>
                )}
                &nbsp;
                {deletingChecklist ? (
                    <CircularProgress size={20} />
                ) : (
                    <FWTooltip
                        title={
                            disableCheckList
                                ? planUpgradeTooltipText.featureUnavailableText
                                : ''
                        }
                    >
                        <div>
                            <IconButton
                                color="error"
                                size="small"
                                onClick={deleteChecklist}
                                disabled={disableCheckList}
                            >
                                <FWIcon name={'bi bi-trash3'} />
                            </IconButton>
                        </div>
                    </FWTooltip>
                )}
            </div>
            <FWPopupWithChildren
                open={open}
                handleClose={() => setOpen(false)}
                title="QC checklist"
            >
                <DialogContent sx={{ m: '12px' }}>
                    <FWInput
                        label="Checklist name"
                        value={checklistDetails.template_name}
                        name="CHECKLIST_NAME"
                        onChange={(e) => {
                            setChecklistDetails((prevDetails) => {
                                prevDetails.template_name = e.target.value;
                                return { ...prevDetails };
                            });
                        }}
                        sx={{
                            mb: '15px',
                        }}
                    />
                    <Box
                        display="grid"
                        gridTemplateColumns={'1.25fr 1.25fr 1fr 0.5fr'}
                        columnGap="10px"
                        rowGap="10px"
                        alignItems={'flex-start'}
                    >
                        {checklistDetails.items.map((field, index) => {
                            return (
                                <Fragment key={index}>
                                    <div>
                                        <FWInput
                                            label="Field name"
                                            value={field.template_item_name}
                                            name="NAME"
                                            onChange={(e) => {
                                                handleFieldChange(
                                                    'NAME',
                                                    e,
                                                    index
                                                );
                                            }}
                                            error={
                                                `template_item_name_${index}` in
                                                errors
                                            }
                                            helper={{
                                                text:
                                                    `template_item_name_${index}` in
                                                    errors
                                                        ? errors[
                                                              `template_item_name_${index}`
                                                          ]
                                                        : '',
                                            }}
                                        />
                                    </div>
                                    <Box>
                                        <FWTypography color="text.secondary">
                                            Field type
                                        </FWTypography>
                                        <FWSelect
                                            fullWidth
                                            value={field.template_item_type}
                                            onChange={(e) => {
                                                handleFieldChange(
                                                    'TYPE',
                                                    e,
                                                    index
                                                );
                                            }}
                                            name="TYPE"
                                        >
                                            <FWMenuItem
                                                value={
                                                    CustomTemplateItemTypes.SHORTTEXT
                                                }
                                            >
                                                Text
                                            </FWMenuItem>
                                            <FWMenuItem
                                                value={
                                                    CustomTemplateItemTypes.FLOAT
                                                }
                                            >
                                                Number
                                            </FWMenuItem>
                                        </FWSelect>
                                    </Box>
                                    <Box marginTop={'15px'}>
                                        <FormGroup>
                                            <FormControlLabel
                                                control={<Checkbox />}
                                                label="Mandatory"
                                                checked={field.is_required}
                                                onChange={(e) => {
                                                    handleFieldChange(
                                                        'MANDATORY',
                                                        e,
                                                        index
                                                    );
                                                }}
                                                name="MANDATORY"
                                            />
                                        </FormGroup>
                                    </Box>
                                    <Box
                                        marginTop={'15px'}
                                        className="flex flex--aic"
                                    >
                                        <IconButton
                                            color="error"
                                            onClick={() => {
                                                handleRowUpdate(
                                                    'REMOVE',
                                                    index
                                                );
                                            }}
                                            disabled={
                                                checklistDetails.items
                                                    .length === 1
                                            }
                                        >
                                            <FWIcon
                                                name="bi bi-dash-circle"
                                                size={20}
                                            />
                                        </IconButton>
                                        &nbsp;
                                        {index ===
                                            checklistDetails.items.length -
                                                1 && (
                                            <IconButton
                                                color="primary"
                                                onClick={() => {
                                                    handleRowUpdate(
                                                        'ADD',
                                                        index
                                                    );
                                                }}
                                            >
                                                <FWIcon
                                                    name="bi bi-plus-circle"
                                                    size={20}
                                                />
                                            </IconButton>
                                        )}
                                    </Box>
                                </Fragment>
                            );
                        })}
                    </Box>
                </DialogContent>
                <DialogActions sx={{ m: '12px' }}>
                    <Button variant="outlined" onClick={() => setOpen(false)}>
                        Cancel
                    </Button>
                    &nbsp;
                    <Button
                        variant="contained"
                        disabled={
                            !(
                                Object.keys(calculateErrors(checklistDetails))
                                    .length === 0
                            )
                        }
                        onClick={saveChecklist}
                    >
                        Submit
                    </Button>
                </DialogActions>
            </FWPopupWithChildren>
        </>
    );
};

export default ChecklistPopup;
