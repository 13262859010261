import { CustomFieldType } from '../../Global/Interfaces/TemplateInterface';
import { IFieldTypeLabel } from '../helpers/projectDashboardHelper';

export const PROJECT_DASHBOARD_FIELDS_NOT_IN_TEMPLATE = (
    currentTab: string
): IFieldTypeLabel[] => [
    {
        label: 'Submission Status',
        choices: ['DRAFT', 'ONGOING', 'COMPLETED'],
        column: 'Submission status',
        fieldType: CustomFieldType.CHOICE,
        is_built_in: true,
        sectionAlternameName: null,
        sectionName: null,
        isFieldFWFieldInCustomSection: false,
        fieldLabelWithSectionName: 'Submission Status',
    },
    {
        label: 'Creator',
        choices: [],
        column: 'Creator',
        fieldType: CustomFieldType.CHOICE,
        is_built_in: true,
        sectionAlternameName: null,
        sectionName: null,
        isFieldFWFieldInCustomSection: false,
        fieldLabelWithSectionName: 'Creator',
    },
    {
        label: 'Created on',
        choices: [],
        column: 'Created on',
        fieldType: CustomFieldType.DATETIME,
        is_built_in: true,
        sectionAlternameName: null,
        sectionName: null,
        isFieldFWFieldInCustomSection: false,
        fieldLabelWithSectionName: 'Created on',
    },
    ...(currentTab !== 'in_progress'
        ? [
              {
                  label: 'Submitted on',
                  choices: [],
                  column: 'Submitted on',
                  fieldType: CustomFieldType.DATETIME,
                  is_built_in: true,
                  sectionAlternameName: null,
                  sectionName: null,
                  isFieldFWFieldInCustomSection: false,
                  fieldLabelWithSectionName: 'Submitted on',
              },
              {
                  label: 'Submitted by',
                  choices: [],
                  column: 'Submitted by',
                  fieldType: CustomFieldType.CHOICE,
                  is_built_in: true,
                  sectionAlternameName: null,
                  sectionName: null,
                  isFieldFWFieldInCustomSection: false,
                  fieldLabelWithSectionName: 'Submitted by',
              },
          ]
        : []),
    {
        label: 'Last modified by',
        choices: [],
        column: 'Last modified by',
        fieldType: CustomFieldType.CHOICE,
        is_built_in: true,
        sectionAlternameName: null,
        sectionName: null,
        isFieldFWFieldInCustomSection: false,
        fieldLabelWithSectionName: 'Last modified by',
    },
    {
        label: 'Modified on',
        choices: [],
        column: 'Modified on',
        fieldType: CustomFieldType.DATETIME,
        is_built_in: true,
        sectionAlternameName: null,
        sectionName: null,
        isFieldFWFieldInCustomSection: false,
        fieldLabelWithSectionName: 'Modified on',
    },
];
