import {
    Box,
    IconButton,
    styled,
    TableCell,
    TableCellProps,
    Grid,
    DialogContent,
    DialogActions,
} from '@mui/material';
import { ColDef } from 'ag-grid-community';
import React, { useState } from 'react';
import { FWTooltip } from '../../Common/FWCustomTooltip';
import { FWLink } from '../../Common/FWLink';
import FWPopover from '../../Common/FWPopover';
import { FWTypography } from '../../Common/FWTypograhy';
import { FWNumberFormatter } from '../../Common/Utils/CurrencyUtils';
import DeliveryPopUp from '../../Components/Shared/DeliverySchedulePopup';
import POStatusChip from '../../Components/Shared/PoStatusChip';
import { calculateItemTotal } from '../../Components/Shared/totalCalculator';
import { getBuyerItemName } from '../../Components/Shared/Utils';
import { DatagridItemPopup } from '../../Global/Components/DatagridItemPopup';
import InvoiceStatusChip from '../Components/InvoiceStatusChip';
import PODeliverySchedulePopup from '../../Components/Shared/PODeliveryScheduleContainer';
import {
    InvoiceItemStatusForDisplay,
    InvoiceItemStatus,
} from '../Interfaces/Invoice.model';
import { IPurchaseOrderItem } from '../Interfaces/PO.model';
import { invoiceItemEffectiveRate } from './helperFunctions';
import { FWPopupWithChildren } from '../../Common/FWPopupWithChildren';
import InvoiceItemStatusChip from '../Components/InvoiceItemStatusChip';
import moment from 'moment';
import {
    InvoiceIdCellSeller,
    InvoiceIdCellBuyer,
} from '../Components/InvoiceDashboardCellRenderers';
import DotBadge from '../../Components/Shared/DotBadge';
import PendingAmountPopover from '../../Payments/Components/PendingAmountPopover';
import ItemRejectionDetails from '../../Payments/Components/ItemRejectionDetails';
import {
    INewGetInvoiceBatchDetail,
    INewGetInvoiceItemDetail,
} from '../Interfaces/NewInvoice.model';

export const StyledDiv = styled('div')({
    '&': {
        //width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    '& .numHeaderNumber': {
        fontSize: '50px',
        zIndex: 0,
        color: '#007Aff',
        opacity: 0.3,
        position: 'absolute',
    },
    '& .numHeader': {
        fontWeight: '500',
        fontSize: '24px',
        zIndex: 1,
        marginLeft: '20px',
    },
});

export const proformaItemTableColumnHeaders = [
    ['Quantity', '400px'],
    ['Notes (optional)', '150px'],
];

export const viewItemTableColumnHeaders = [
    ['Batch ID', '400px'],
    ['Quantity', '400px'],
    ['Batch expiry date', '400px'],
];

export const TableCellWithoutBorder = styled(TableCell)<TableCellProps>(() => ({
    border: '0px solid',
    verticalAlign: 'middle',
    align: 'center',
}));

export default function BatchRowhandlerMenu(props: {
    addRow: () => void;
    removeRow: () => void;
    showAddButton: boolean;
    disableRemoveButton: boolean;
}) {
    const handleMenuItemClick = (type: 'add_row' | 'remove_row') => {
        if (type === 'add_row') props.addRow();
        else props.removeRow();
    };

    return (
        <div className="flex flex--aic">
            <FWTooltip title="Delete row">
                <div>
                    <IconButton
                        id="createInvoice_removeBatch"
                        onClick={() => handleMenuItemClick('remove_row')}
                        disabled={props.disableRemoveButton}
                        size="small"
                        color="error"
                    >
                        <i className="bi bi-dash-circle"></i>
                    </IconButton>
                </div>
            </FWTooltip>
            &nbsp;
            {props.showAddButton && (
                <FWTooltip title="Add row">
                    <IconButton
                        id="createInvoice_addBatch"
                        onClick={() => handleMenuItemClick('add_row')}
                        size="small"
                        color="primary"
                    >
                        <i className="bi bi-plus-circle"></i>
                    </IconButton>
                </FWTooltip>
            )}
        </div>
    );
}

export const pendingColumns: ColDef[] = [
    {
        field: 'custom_po_id',
        headerName: 'PO ID',
        flex: 1,
        cellRenderer: (params: any) => {
            return (
                <div className="flex flex--aic">
                    <FWTypography color="text.secondary">
                        {params.value}
                    </FWTypography>
                    &nbsp;
                    {params.data.po_status !== 'TERMINATED' &&
                        params.data.po_hold_or_termination !== null && (
                            <FWTooltip
                                title={
                                    params.data.po_hold_or_termination ===
                                    'HOLD'
                                        ? 'PO on hold'
                                        : 'Termination requested for PO'
                                }
                            >
                                <Box sx={{ lineHeight: 0 }}>
                                    <DotBadge
                                        color={
                                            params.data
                                                .po_hold_or_termination ===
                                            'HOLD'
                                                ? 'warning'
                                                : 'error'
                                        }
                                    />
                                </Box>
                            </FWTooltip>
                        )}
                </div>
            );
        },
        filter: true,
        sortable: true,
    },
    {
        field: 'custom_invoice_id',
        headerName: 'Invoice ID',
        flex: 1,
        cellRenderer: InvoiceIdCellSeller,
        filter: true,
        sortable: true,
    },
    {
        field: 'seller_entity_name',
        headerName: 'Entity',
        width: 200,
        cellRenderer: (params: any) => (
            <FWTypography>{params.value}</FWTypography>
        ),
        filter: true,
        sortable: true,
    },
    {
        field: 'buyer_entity_name',
        headerName: 'Customer',
        width: 200,
        cellRenderer: (params: any) => (
            <FWTypography>{params.value}</FWTypography>
        ),
        filter: true,
        sortable: true,
    },
    {
        field: 'invoice_item_count',
        headerName: 'Item(s)',
        width: 150,
        cellRenderer: (params: any) => (
            <FWTypography color="text.primary" width={150} textAlign="center">
                {params.value}
            </FWTypography>
        ),
        filter: true,
        sortable: true,
    },
    {
        field: 'invoice_total_amount',
        headerName: 'Value',
        width: 150,
        cellRenderer: (params: any) => (
            <FWTypography color="text.primary" textAlign="right" width={200}>
                {params.value}
            </FWTypography>
        ),
        filter: true,
        sortable: true,
    },
    {
        field: 'invoice_status',
        headerName: 'Status',
        flex: 1,
        cellRenderer: (params: any) => (
            <InvoiceStatusChip status={params.value} />
        ),
        sortable: true,
        filter: true,
    },
];

export const ongoingColumns: ColDef[] = [
    {
        field: 'custom_po_id',
        headerName: 'PO ID',
        flex: 1,
        cellRenderer: (params: any) => {
            return (
                <div className="flex flex--aic">
                    <FWTypography color="text.secondary">
                        {params.value}
                    </FWTypography>
                    &nbsp;
                    {params.data.po_status !== 'TERMINATED' &&
                        params.data.po_hold_or_termination !== null && (
                            <FWTooltip
                                title={
                                    params.data.po_hold_or_termination ===
                                    'HOLD'
                                        ? 'PO on hold'
                                        : 'Termination requested for PO'
                                }
                            >
                                <Box sx={{ lineHeight: 0 }}>
                                    <DotBadge
                                        color={
                                            params.data
                                                .po_hold_or_termination ===
                                            'HOLD'
                                                ? 'warning'
                                                : 'error'
                                        }
                                    />
                                </Box>
                            </FWTooltip>
                        )}
                </div>
            );
        },
        filter: true,
        sortable: true,
    },
    {
        field: 'custom_invoice_id',
        headerName: 'Invoice ID',
        flex: 1,
        cellRenderer: InvoiceIdCellSeller,
        filter: true,
        sortable: true,
    },
    {
        field: 'seller_entity_name',
        headerName: 'Entity',
        width: 200,
        cellRenderer: (params: any) => (
            <FWTypography>{params.value}</FWTypography>
        ),
        filter: true,
        sortable: true,
    },
    {
        field: 'buyer_entity_name',
        headerName: 'Customer',
        width: 200,
        cellRenderer: (params: any) => (
            <FWTypography>{params.value}</FWTypography>
        ),
        filter: true,
        sortable: true,
    },
    {
        field: 'invoice_item_count',
        headerName: 'Item(s)',
        width: 150,
        cellRenderer: (params: any) => (
            <FWTypography color="text.primary" width={150} textAlign="center">
                {params.value}
            </FWTypography>
        ),
        filter: true,
        sortable: true,
    },
    {
        field: 'invoice_total_amount',
        headerName: 'Value',
        width: 150,
        cellRenderer: (params: any) => (
            <FWTypography color="text.primary" textAlign="right" width={200}>
                {params.value}
            </FWTypography>
        ),
        filter: true,
        sortable: true,
    },
    {
        field: 'invoice_payment_pending_amount',
        headerName: 'Payment pending',
        flex: 1,
        cellRenderer: (params: any) => (
            <FWTypography color="text.primary" textAlign="right" width={200}>
                {FWNumberFormatter(
                    params.value,
                    params.data.currency_code_abbreviation
                )}
            </FWTypography>
        ),

        comparator: function (valueA, valueB) {
            return +valueA === +valueB ? 0 : +valueA > +valueB ? 1 : -1;
        },
        filter: true,
        sortable: true,
    },
    {
        field: 'expected_delivery_date',
        headerName: 'Delivery date',
        flex: 1,
        cellRenderer: (params: any) => (
            <FWTypography color="text.secondary">{params.value}</FWTypography>
        ),
        filter: true,
        sortable: true,
    },
    {
        field: 'invoice_status',
        headerName: 'Status',
        flex: 1,
        cellRenderer: (params: any) => (
            <InvoiceStatusChip status={params.value} />
        ),
        sortable: true,
        filter: true,
    },
];

export const allInvoiceColumns: ColDef[] = [
    {
        field: 'custom_po_id',
        headerName: 'PO ID',
        flex: 1,
        cellRenderer: (params: any) => {
            return (
                <div className="flex flex--aic">
                    <FWTypography color="text.secondary">
                        {params.value}
                    </FWTypography>
                    &nbsp;
                    {params.data.po_status !== 'TERMINATED' &&
                        params.data.po_hold_or_termination !== null && (
                            <FWTooltip
                                title={
                                    params.data.po_hold_or_termination ===
                                    'HOLD'
                                        ? 'PO on hold'
                                        : 'Termination requested for PO'
                                }
                            >
                                <Box sx={{ lineHeight: 0 }}>
                                    <DotBadge
                                        color={
                                            params.data
                                                .po_hold_or_termination ===
                                            'HOLD'
                                                ? 'warning'
                                                : 'error'
                                        }
                                    />
                                </Box>
                            </FWTooltip>
                        )}
                </div>
            );
        },
        filter: true,
        sortable: true,
    },
    {
        field: 'custom_invoice_id',
        headerName: 'Invoice ID',
        flex: 1,
        //width: 220,
        cellRenderer: InvoiceIdCellSeller,
        filter: true,
        sortable: true,
    },
    {
        field: 'seller_entity_name',
        headerName: 'Entity',
        width: 200,
        cellRenderer: (params: any) => (
            <FWTypography>{params.value}</FWTypography>
        ),
        filter: true,
        sortable: true,
    },
    {
        field: 'buyer_entity_name',
        headerName: 'Customer',
        width: 200,
        cellRenderer: (params: any) => (
            <FWTypography>{params.value}</FWTypography>
        ),
        filter: true,
        sortable: true,
    },
    {
        field: 'invoice_item_count',
        headerName: 'Item(s)',
        width: 150,
        cellRenderer: (params: any) => (
            <FWTypography color="text.primary" width={150} textAlign="center">
                {params.value}
            </FWTypography>
        ),
        filter: true,
        sortable: true,
    },
    {
        field: 'invoice_total_amount',
        headerName: 'Value',
        width: 150,
        flex: 1,
        cellRenderer: (params: any) => (
            <FWTypography color="text.primary" textAlign="right" width={200}>
                {params.value}
            </FWTypography>
        ),
        filter: true,
        sortable: true,
    },
    {
        field: 'invoice_status',
        headerName: 'Status',
        flex: 1,
        cellRenderer: (params: any) => (
            <InvoiceStatusChip status={params.value} />
        ),
        sortable: true,
        filter: true,
    },
];

export const poPendingColumns: ColDef[] = [
    {
        field: 'custom_po_id_pending',
        headerName: 'PO ID',
        //width: 165,
        flex: 1,
        cellRenderer: (params: any) => {
            return (
                <div className="flex flex--aic">
                    <FWTypography color="text.secondary">
                        {params.data.custom_po_id}
                    </FWTypography>
                    &nbsp;
                    {params.data.po_status !== 'TERMINATED' &&
                        params.data.po_hold_or_termination !== null && (
                            <FWTooltip
                                title={
                                    params.data.po_hold_or_termination ===
                                    'HOLD'
                                        ? 'PO on hold'
                                        : 'Termination requested for PO'
                                }
                            >
                                <Box sx={{ lineHeight: 0 }}>
                                    <DotBadge
                                        color={
                                            params.data
                                                .po_hold_or_termination ===
                                            'HOLD'
                                                ? 'warning'
                                                : 'error'
                                        }
                                    />
                                </Box>
                            </FWTooltip>
                        )}
                </div>
            );
        },
        filter: true,
        sortable: true,
    },
    {
        field: 'seller_entity_name',
        headerName: 'Entity',
        width: 200,
        cellRenderer: (params: any) => (
            <FWTypography>{params.value}</FWTypography>
        ),
        filter: true,
        sortable: true,
    },
    {
        field: 'buyer_entity_name',
        headerName: 'Customer',
        width: 200,
        cellRenderer: (params: any) => (
            <FWTypography>{params.value}</FWTypography>
        ),
        filter: true,
        sortable: true,
    },
    {
        field: 'po_pending_item_count',
        headerName: 'Item(s)',
        width: 150,
        cellRenderer: (params: any) => (
            <FWTypography color="text.primary" width={150} textAlign="center">
                {params.value}
            </FWTypography>
        ),
        filter: true,
        sortable: true,
    },
    {
        field: 'po_delivery',
        headerName: 'Delivery',
        flex: 1,
        //width: 200,
        cellRenderer: (params: any) => {
            return (
                <PODeliverySchedulePopup
                    po_id={params.data.po_id}
                    user_type={'SELLER'}
                    buttonId="sellerInvoiceDashboard_deliveryButton"
                />
            );
        },
        filter: true,
        sortable: false,
    },
    // {
    //     field: 'po_next_payment_date',
    //     headerName: 'Next payment date',
    //     //width: 110,
    //     cellRenderer: (params: any) => (
    //         <FWTypography color="text.primary">{params.value}</FWTypography>
    //     ),
    //     sortable: true,
    //     filter: true,
    // },
    {
        field: 'invoice_in_po_count',
        headerName: 'Invoice(s)',
        width: 150,
        cellRenderer: (params: any) => (
            <FWTypography color="text.primary" width={150} textAlign="center">
                {params.value}
            </FWTypography>
        ),
        sortable: true,
        filter: true,
    },
    {
        field: 'po_status',
        headerName: 'Status',
        flex: 1,
        //width: 110,
        cellRenderer: (params: any) => <POStatusChip status={params.value} />,
        sortable: true,
        filter: true,
    },
];

export function getNewInvoiceItemDisplayStatus(
    invoiceItem: INewGetInvoiceItemDetail
): InvoiceItemStatusForDisplay {
    if (invoiceItem.status === InvoiceItemStatus.COMPLETED) {
        return InvoiceItemStatusForDisplay.PAID;
    } else if (invoiceItem.status === InvoiceItemStatus.TERMINATED) {
        return InvoiceItemStatusForDisplay.TERMINATED;
    }

    let no_of_batch_quality_check_partial = 0;
    let no_of_batches_on_hold = 0;
    let no_of_batches_terminated = 0;
    let no_of_batches_termination_requested = 0;

    invoiceItem.batch_items.forEach((batch) => {
        if (batch.quality_check_status === 'QUALITY_CHECK_PARTIAL') {
            no_of_batch_quality_check_partial += 1;
        }

        if (batch.hold_information) no_of_batches_on_hold += 1;

        if (batch.termination_information)
            if (
                batch.termination_information.status ===
                'INVOICE_ITEM_TERMINATION_BUYER_REQUESTED'
            )
                no_of_batches_termination_requested += 1;
            else no_of_batches_terminated += 1;
    });

    if (invoiceItem.status === InvoiceItemStatus.GOODS_PENDING) {
        if (
            no_of_batches_terminated > 0 ||
            (invoiceItem.termination_information !== null &&
                invoiceItem.termination_information.status ===
                    'INVOICE_ITEM_TERMINATION_SELLER_ACCEPTED')
        )
            return no_of_batch_quality_check_partial === 0
                ? InvoiceItemStatusForDisplay.GOODS_PENDING_WITH_TERMINATED
                : InvoiceItemStatusForDisplay.QUALITY_CHECK_WITH_TERMINATED;
        else if (
            no_of_batches_termination_requested > 0 ||
            invoiceItem.termination_information !== null
        )
            return no_of_batch_quality_check_partial === 0
                ? InvoiceItemStatusForDisplay.GOODS_PENDING_WITH_TERMINATION_REQUEST
                : InvoiceItemStatusForDisplay.QUALITY_CHECK_WITH_TERMINATION_REQUEST;
        else if (
            no_of_batches_on_hold > 0 ||
            invoiceItem.hold_information !== null
        )
            return no_of_batch_quality_check_partial === 0
                ? InvoiceItemStatusForDisplay.GOODS_PENDING_WITH_HOLD
                : InvoiceItemStatusForDisplay.QUALITY_CHECK_WITH_HOLD;
        else
            return no_of_batch_quality_check_partial === 0
                ? InvoiceItemStatusForDisplay.GOODS_PENDING
                : InvoiceItemStatusForDisplay.QUALITY_CHECK;

        // if (no_of_batch_quality_check_pending === invoiceItem.batch_items.length)
    } else if (invoiceItem.status === InvoiceItemStatus.GOODS_RECEIVED) {
        if (no_of_batch_quality_check_partial > 0) {
            if (
                no_of_batches_terminated > 0 ||
                (invoiceItem.termination_information !== null &&
                    invoiceItem.termination_information.status ===
                        'INVOICE_ITEM_TERMINATION_SELLER_ACCEPTED')
            )
                return InvoiceItemStatusForDisplay.QUALITY_CHECK_WITH_TERMINATED;
            else if (
                no_of_batches_termination_requested > 0 ||
                invoiceItem.termination_information !== null
            )
                return InvoiceItemStatusForDisplay.QUALITY_CHECK_WITH_TERMINATION_REQUEST;
            else if (
                no_of_batches_on_hold > 0 ||
                invoiceItem.hold_information !== null
            )
                return InvoiceItemStatusForDisplay.QUALITY_CHECK_WITH_HOLD;
            else return InvoiceItemStatusForDisplay.QUALITY_CHECK;
        } else {
            if (
                no_of_batches_terminated > 0 ||
                (invoiceItem.termination_information !== null &&
                    invoiceItem.termination_information.status ===
                        'INVOICE_ITEM_TERMINATION_SELLER_ACCEPTED')
            )
                return InvoiceItemStatusForDisplay.GOODS_RECEIVED_WITH_TERMINATED;
            else if (
                no_of_batches_termination_requested > 0 ||
                invoiceItem.termination_information !== null
            )
                return InvoiceItemStatusForDisplay.GOODS_RECEIVED_WITH_TERMINATION_REQUEST;
            else if (
                no_of_batches_on_hold > 0 ||
                invoiceItem.hold_information !== null
            )
                return InvoiceItemStatusForDisplay.GOODS_RECEIVED_WITH_HOLD;
            else
                return no_of_batch_quality_check_partial === 0
                    ? InvoiceItemStatusForDisplay.GOODS_RECEIVED
                    : InvoiceItemStatusForDisplay.QUALITY_CHECK;
        }
    }

    return InvoiceItemStatusForDisplay.BLANK;
}

// export function getInvoiceItemDisplayStatus(
//     invoiceItem: INewGetInvoiceItemDetail
// ): InvoiceItemStatusForDisplay {
//     let no_of_batches_on_hold = 0;
//     let no_of_batches_termination_requested = 0;
//     let no_of_batches_terminated = 0;
//     let no_of_batches_goods_received = 0;
//     let no_of_batches_goods_pending = 0;
//     let no_of_batches_completed = 0;
//     let total_no_of_batches = invoiceItem.batch_items.length;

//     for (let batch of invoiceItem.batch_items) {
//         if (batch.termination_information !== null) {
//             if (batch.status === InoviceItemBatchStatus.BATCH_TERMINATED)
//                 no_of_batches_terminated += 1;
//             else no_of_batches_termination_requested += 1;
//         } else if (batch.hold_information !== null) {
//             no_of_batches_on_hold += 1;
//         }

//         if (batch.hold_information) no_of_batches_on_hold += 1;

//         if (batch.termination_information)
//             if (
//                 batch.termination_information.status ===
//                 'INVOICE_ITEM_TERMINATION_BUYER_REQUESTED'
//             )
//                 no_of_batches_termination_requested += 1;
//             else no_of_batches_terminated += 1;
//     });

//

//     if (invoiceItem.status === InvoiceItemStatus.GOODS_PENDING) {
//         if (
//             no_of_batches_terminated > 0 ||
//             (invoiceItem.termination_information !== null &&
//                 invoiceItem.termination_information.status ===
//                     'INVOICE_ITEM_TERMINATION_SELLER_ACCEPTED')
//         )
//             return InvoiceItemStatusForDisplay.GOODS_PENDING_WITH_TERMINATED;
//         else if (
//             no_of_batches_termination_requested > 0 ||
//             invoiceItem.termination_information !== null
//         )
//             return InvoiceItemStatusForDisplay.GOODS_PENDING_WITH_TERMINATION_REQUEST;
//         else if (
//             no_of_batches_on_hold > 0 ||
//             invoiceItem.hold_information !== null
//         )
//             return InvoiceItemStatusForDisplay.GOODS_PENDING_WITH_HOLD;
//         else return InvoiceItemStatusForDisplay.GOODS_PENDING;

//         // if (no_of_batch_quality_check_pending === invoiceItem.batch_items.length)
//     } else if (invoiceItem.status === InvoiceItemStatus.GOODS_RECEIVED) {
//         if (no_of_batch_quality_check_partial > 0) {
//             if (
//                 no_of_batches_terminated > 0 ||
//                 (invoiceItem.termination_information !== null &&
//                     invoiceItem.termination_information.status ===
//                         'INVOICE_ITEM_TERMINATION_SELLER_ACCEPTED')
//             )
//                 return InvoiceItemStatusForDisplay.QUALITY_CHECK_WITH_TERMINATED;
//             else if (
//                 no_of_batches_termination_requested > 0 ||
//                 invoiceItem.termination_information !== null
//             )
//                 return InvoiceItemStatusForDisplay.QUALITY_CHECK_WITH_TERMINATION_REQUEST;
//             else if (
//                 no_of_batches_on_hold > 0 ||
//                 invoiceItem.hold_information !== null
//             )
//                 return InvoiceItemStatusForDisplay.QUALITY_CHECK_WITH_HOLD;
//             else return InvoiceItemStatusForDisplay.QUALITY_CHECK;
//         } else {
//             if (
//                 no_of_batches_terminated > 0 ||
//                 (invoiceItem.termination_information !== null &&
//                     invoiceItem.termination_information.status ===
//                         'INVOICE_ITEM_TERMINATION_SELLER_ACCEPTED')
//             )
//                 return InvoiceItemStatusForDisplay.GOODS_RECEIVED_WITH_TERMINATED;
//             else if (
//                 no_of_batches_termination_requested > 0 ||
//                 invoiceItem.termination_information !== null
//             )
//                 return InvoiceItemStatusForDisplay.GOODS_RECEIVED_WITH_TERMINATION_REQUEST;
//             else if (
//                 no_of_batches_on_hold > 0 ||
//                 invoiceItem.hold_information !== null
//             )
//                 return InvoiceItemStatusForDisplay.GOODS_RECEIVED_WITH_HOLD;
//             else return InvoiceItemStatusForDisplay.GOODS_RECEIVED;
//         }
//     }

//     return InvoiceItemStatusForDisplay.BLANK;
// }

// export function getInvoiceItemDisplayStatus(
//     invoiceItem: INewGetInvoiceItemDetail
// ): InvoiceItemStatusForDisplay {
//     let no_of_batches_on_hold = 0;
//     let no_of_batches_termination_requested = 0;
//     let no_of_batches_terminated = 0;
//     let no_of_batches_goods_received = 0;
//     let no_of_batches_goods_pending = 0;
//     let no_of_batches_completed = 0;
//     let total_no_of_batches = invoiceItem.batch_items.length;

// for (let batch of invoiceItem.batch_items) {
//     if (batch.termination_information !== null) {
//         if (batch.status === InoviceItemBatchStatus.BATCH_TERMINATED)
//             no_of_batches_terminated += 1;
//         else no_of_batches_termination_requested += 1;
//     } else if (batch.hold_information !== null) {
//         no_of_batches_on_hold += 1;
//     }
//     if (batch.status === 'GOODS_RECEIVED') {
//         no_of_batches_goods_received += 1;
//     } else if (batch.status === 'GOODS_PENDING') {
//         no_of_batches_goods_pending += 1;
//     } else if (batch.status === 'COMPLETED') {
//         no_of_batches_completed += 1;
//     }
// }

//     if (no_of_batches_goods_pending > 0) {
//         if (no_of_batches_terminated > 0)
//             return InvoiceItemStatusForDisplay.GOODS_PENDING_WITH_TERMINATED;
//         else if (no_of_batches_termination_requested > 0) {
//             return InvoiceItemStatusForDisplay.GOODS_PENDING_WITH_TERMINATION_REQUEST;
//         } else if (no_of_batches_on_hold > 0)
//             return InvoiceItemStatusForDisplay.GOODS_PENDING_WITH_HOLD;
//         else if (no_of_batches_goods_pending === total_no_of_batches)
//             return InvoiceItemStatusForDisplay.GOODS_PENDING;
//     } else if (no_of_batches_goods_received > 0) {
//         if (no_of_batches_terminated > 0)
//             return InvoiceItemStatusForDisplay.GOODS_RECEIVED_WITH_TERMINATED;
//         else if (no_of_batches_termination_requested > 0)
//             return InvoiceItemStatusForDisplay.GOODS_RECEIVED_WITH_TERMINATION_REQUEST;
//         else if (no_of_batches_on_hold > 0)
//             return InvoiceItemStatusForDisplay.GOODS_RECEIVED_WITH_HOLD;
//         else if (no_of_batches_goods_received === total_no_of_batches)
//             return InvoiceItemStatusForDisplay.GOODS_RECEIVED;
//     } else if (no_of_batches_completed === total_no_of_batches)
//         return InvoiceItemStatusForDisplay.COMPLETED;
//     else if (no_of_batches_terminated === total_no_of_batches)
//         return InvoiceItemStatusForDisplay.TERMINATED;
//     else if (no_of_batches_termination_requested === total_no_of_batches)
//         return InvoiceItemStatusForDisplay.TERMINATION_REQUESTED;
//     else if (no_of_batches_on_hold === total_no_of_batches)
//         return InvoiceItemStatusForDisplay.ON_HOLD;
//     return InvoiceItemStatusForDisplay.BLANK;
// }

export function getInvoiceItemBBatchDisplayStatus(
    is_item_terminated: boolean,
    invoiceItemBatchStatus: string,
    termination_requested: boolean,
    on_hold: boolean
): InvoiceItemStatusForDisplay {
    if (is_item_terminated) return InvoiceItemStatusForDisplay.TERMINATED;
    if (termination_requested)
        return InvoiceItemStatusForDisplay.TERMINATION_REQUESTED;
    else if (on_hold) return InvoiceItemStatusForDisplay.ON_HOLD;
    else if (invoiceItemBatchStatus === '') {
        return InvoiceItemStatusForDisplay.GOODS_PENDING;
    } else if (invoiceItemBatchStatus === 'GOODS_RECEIVED') {
        return InvoiceItemStatusForDisplay.GOODS_RECEIVED;
    } else if (invoiceItemBatchStatus === 'COMPLETED')
        return InvoiceItemStatusForDisplay.COMPLETED;
    else if (invoiceItemBatchStatus === 'TERMINATED')
        return InvoiceItemStatusForDisplay.TERMINATED;
    return InvoiceItemStatusForDisplay.BLANK;
}

export const getNewInvoiceItemBBatchDisplayStatus: (
    invoice_item_batch: INewGetInvoiceBatchDetail
) => InvoiceItemStatusForDisplay = (
    invoice_item_batch: INewGetInvoiceBatchDetail
): InvoiceItemStatusForDisplay => {
    if (invoice_item_batch.termination_information)
        if (
            invoice_item_batch.termination_information.status ===
            'INVOICE_ITEM_TERMINATION_SELLER_ACCEPTED'
        )
            return InvoiceItemStatusForDisplay.TERMINATED;
        else return InvoiceItemStatusForDisplay.TERMINATION_REQUESTED;
    else if (invoice_item_batch.hold_information)
        return InvoiceItemStatusForDisplay.ON_HOLD;
    if (invoice_item_batch.quality_checks.length > 0)
        return InvoiceItemStatusForDisplay.QUALITY_CHECK;
    else if (invoice_item_batch.goods_receipt)
        return InvoiceItemStatusForDisplay.GOODS_RECEIVED;
    else return InvoiceItemStatusForDisplay.GOODS_PENDING;
};

export const unverifiedColumns: ColDef[] = [
    {
        field: 'custom_po_id',
        headerName: 'PO ID',
        //width: 165,
        cellRenderer: (params: any) => {
            return (
                <div className="flex flex--aic">
                    <FWTypography color="text.secondary">
                        {params.value}
                    </FWTypography>
                    &nbsp;
                    {params.data.po_status !== 'TERMINATED' &&
                        params.data.po_hold_or_termination !== null && (
                            <FWTooltip
                                title={
                                    params.data.po_hold_or_termination ===
                                    'HOLD'
                                        ? 'PO on hold'
                                        : 'Termination requested for PO'
                                }
                            >
                                <Box sx={{ lineHeight: 0 }}>
                                    <DotBadge
                                        color={
                                            params.data
                                                .po_hold_or_termination ===
                                            'HOLD'
                                                ? 'warning'
                                                : 'error'
                                        }
                                    />
                                </Box>
                            </FWTooltip>
                        )}
                </div>
            );
        },
        filter: true,
        sortable: true,
    },
    {
        field: 'custom_invoice_id',
        headerName: 'Document ID',
        flex: 1,
        //width: 220,
        cellRenderer: InvoiceIdCellBuyer,
        filter: true,
        sortable: true,
    },
    {
        field: 'invoice_document_type',
        headerName: 'Document type',
        //width: 100,
        cellRenderer: (params: any) => (
            <FWTypography color="text.primary">{params.value}</FWTypography>
        ),
        filter: true,
        sortable: true,
    },
    {
        field: 'buyer_entity_name',
        headerName: 'Entity',
        //width: 185,
        cellRenderer: (params: any) => (
            <FWTypography color="text.primary">{params.value}</FWTypography>
        ),
        filter: true,
        sortable: true,
    },
    {
        field: 'seller_entity_name',
        headerName: 'Vendor',
        //width: 185,
        cellRenderer: (params: any) => (
            <FWTypography color="text.primary">{params.value}</FWTypography>
        ),
        filter: true,
        sortable: true,
    },
    {
        field: 'invoice_item_count',
        headerName: 'Item(s)',
        width: 200,
        cellRenderer: (params: any) => (
            <FWTypography color="text.primary" textAlign="center" width={200}>
                {params.value}
            </FWTypography>
        ),
        filter: true,
        sortable: true,
    },
    {
        field: 'invoice_status',
        headerName: 'Invoice status',
        //width: 165,
        cellRenderer: (params: any) => (
            <InvoiceStatusChip status={params.value} />
        ),
        filter: true,
        sortable: true,
    },
];

export const verifiedColumns: (isVLNDashboard?: boolean) => ColDef[] = (
    isVLNDashboard
) => [
    {
        field: 'custom_po_id',
        headerName: 'PO ID',
        flex: 1,
        cellRenderer: (params: any) => {
            return (
                <div className="flex flex--aic">
                    <FWTypography color="text.secondary">
                        {params.value}
                    </FWTypography>
                    &nbsp;
                    {params.data.po_status !== 'TERMINATED' &&
                        params.data.po_hold_or_termination !== null && (
                            <FWTooltip
                                title={
                                    params.data.po_hold_or_termination ===
                                    'HOLD'
                                        ? 'PO on hold'
                                        : 'Termination requested for PO'
                                }
                            >
                                <Box sx={{ lineHeight: 0 }}>
                                    <DotBadge
                                        color={
                                            params.data
                                                .po_hold_or_termination ===
                                            'HOLD'
                                                ? 'warning'
                                                : 'error'
                                        }
                                    />
                                </Box>
                            </FWTooltip>
                        )}
                </div>
            );
        },
        filter: true,
        sortable: true,
    },
    {
        field: 'custom_invoice_id',
        headerName: 'Invoice ID',
        flex: 1,
        //width: 220,
        cellRenderer: InvoiceIdCellBuyer,
        filter: true,
        sortable: true,
    },
    {
        field: 'buyer_entity_name',
        headerName: 'Entity',
        //width: 185,
        cellRenderer: (params: any) => (
            <FWTypography color="text.primary">{params.value}</FWTypography>
        ),
        filter: true,
        sortable: true,
    },
    ...(isVLNDashboard
        ? []
        : [
              {
                  field: 'seller_entity_name',
                  headerName: 'Vendor',
                  //width: 185,
                  cellRenderer: (params: any) => (
                      <FWTypography color="text.primary">
                          {params.value}
                      </FWTypography>
                  ),
                  filter: true,
                  sortable: true,
              },
          ]),
    {
        field: 'invoice_item_count',
        headerName: 'Item(s)',
        width: 150,
        cellRenderer: (params: any) => (
            <FWTypography color="text.primary" textAlign="center" width={150}>
                {params.value}
            </FWTypography>
        ),
        filter: true,
        sortable: true,
    },
    {
        field: 'expected_delivery_date',
        headerName: 'Delivery date',
        flex: 1,
        cellRenderer: (params: any) => (
            <FWTypography color="text.primary">{params.value}</FWTypography>
        ),
        filter: true,
        sortable: true,
    },
    {
        field: 'invoice_payment_pending_amount',
        headerName: 'Pending amount',
        width: 200,
        cellRenderer: (params: any) => (
            <FWTypography color="text.primary" textAlign="right" width={200}>
                {params.value}
            </FWTypography>
        ),
        filter: true,
        sortable: true,
    },
    {
        field: 'invoice_status',
        headerName: 'Invoice status',
        //width: 165,
        cellRenderer: (params: any) => (
            <InvoiceStatusChip status={params.value} />
        ),
        filter: true,
        sortable: true,
    },
];

export const ItemNameWithPopup = ({
    invoice_item,
    enterprise_type,
    buttonId,
}: {
    invoice_item: INewGetInvoiceItemDetail;
    enterprise_type: 'BUYER' | 'SELLER';
    buttonId?: string;
}) => {
    const [openItemDetailsPopup, setOpenItemDetailsPopup] =
        React.useState(false);
    return (
        <>
            {/* <FWLink
                sx={{
                    textOverflow: 'ellipsis',
                }}
                onClick={() => setOpenItemDetailsPopup(true)}
            > */}
            <FWTooltip
                title={getBuyerItemName(invoice_item.item_information)}
                placement={'top'}
            >
                <FWTypography
                    sx={{
                        textOverflow: 'ellipsis',
                        color: '#007AFF',
                        '&:hover': {
                            textDecoration: 'underline',
                        },
                        cursor: 'pointer',
                    }}
                    id={buttonId}
                    onClick={() => setOpenItemDetailsPopup(true)}
                >
                    {getBuyerItemName(invoice_item.item_information)}
                </FWTypography>
            </FWTooltip>
            {/* </FWLink> */}

            <DatagridItemPopup
                open={openItemDetailsPopup}
                setOpen={setOpenItemDetailsPopup}
                itemDetails={invoice_item}
                enterprise_type={enterprise_type}
            />
        </>
    );
};

export const InvoiceItemDetails = ({
    invoice_item,
    po_item,
}: {
    invoice_item: INewGetInvoiceItemDetail;
    po_item: IPurchaseOrderItem | null;
}) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
        null
    );

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'item-details-popover' : undefined;

    const calctax = () => {
        let total_tax = 0;
        invoice_item.batch_items.forEach((batch) => {
            total_tax +=
                calculateItemTotal(
                    batch.quantity_information.batch_quantity,
                    invoice_item.pricing_information.price,
                    '0',
                    invoice_item.pricing_information.additional_charges
                ) -
                parseFloat(batch.quantity_information.batch_quantity) *
                    parseFloat(invoice_item.pricing_information.price);
        });
        return FWNumberFormatter(
            total_tax,
            invoice_item.pricing_information.currency_code_abbreviation
        );
    };

    let total_qty = 0;

    for (let batch of invoice_item.batch_items) {
        total_qty += parseFloat(batch.quantity_information.batch_quantity);
    }

    return (
        <>
            <div style={{ width: 'min-content' }} className="flex flex--jcc">
                <IconButton color="primary" onClick={handleClick} size="small">
                    <i className="bi bi-info-circle"></i>
                </IconButton>
            </div>
            <FWPopover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Grid
                    width="400px"
                    container
                    rowGap={1}
                    columnSpacing={0.5}
                    margin="12px"
                >
                    <Grid item xs={6}>
                        <FWTypography>Lead time</FWTypography>
                    </Grid>
                    <Grid item xs={6}>
                        <FWTypography>
                            {po_item === null
                                ? ''
                                : `${
                                      po_item.procurement_information.lead_time
                                  } ${po_item.procurement_information.lead_time_period.toLowerCase()}`}
                        </FWTypography>
                    </Grid>
                    <Grid item xs={6}>
                        <FWTypography>Delivery schedule</FWTypography>
                    </Grid>
                    <Grid item xs={6}>
                        <DeliveryPopUp
                            deliverySchedule={
                                po_item === null
                                    ? []
                                    : po_item.delivery_schedule_items
                            }
                            measurement_unit={
                                po_item === null
                                    ? ''
                                    : po_item.quantity_information
                                          .measurement_unit_name
                            }
                            showCostCenter={false}
                            showGL={false}
                            showProject={false}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FWTypography>Payment termsedf</FWTypography>
                    </Grid>
                    <Grid item xs={6}>
                        {invoice_item.payment_terms_information
                            .payment_terms_applied_from === null ? (
                            '-'
                        ) : (
                            <FWTypography>
                                {
                                    invoice_item.payment_terms_information
                                        .payment_terms
                                }{' '}
                                {invoice_item.payment_terms_information.payment_terms_period.toLowerCase()}{' '}
                                {invoice_item.payment_terms_information.payment_terms_applied_from
                                    .toLowerCase()
                                    .split('_')
                                    .join(' ')}
                            </FWTypography>
                        )}
                    </Grid>
                    <Grid item xs={6}>
                        <FWTypography>Shipping</FWTypography>
                    </Grid>
                    <Grid item xs={6}>
                        <FWTypography>
                            {FWNumberFormatter(
                                parseFloat(
                                    invoice_item.pricing_information
                                        .shipping_per_unit
                                ) * total_qty,
                                invoice_item.pricing_information
                                    .currency_code_abbreviation
                            )}
                        </FWTypography>
                    </Grid>
                    <Grid item xs={6}>
                        <FWTypography>Tax</FWTypography>
                    </Grid>
                    <Grid item xs={6}>
                        <FWTypography>{calctax()}</FWTypography>
                    </Grid>
                    <Grid item xs={6}>
                        <FWTypography>Incoterms</FWTypography>
                    </Grid>
                    <Grid item xs={6}>
                        <FWTypography>
                            {po_item === null
                                ? ''
                                : po_item.incoterm_abbreviation}
                        </FWTypography>
                    </Grid>
                </Grid>
            </FWPopover>
        </>
    );
};

export const AmountPendingPopup = (params: {
    data: INewGetInvoiceItemDetail;
    discount_percentage: number;
    buttonId?: string;
}) => {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    let total_pending_amt = 0;
    let total_rejected_qty = 0;
    let total_amt = 0;
    let total_completed_payment_amt = 0;
    let total_credit_amt = 0;
    let total_qty_on_hold = 0;
    let total_qty_termination_req = 0;
    let _effective_rate = invoiceItemEffectiveRate(
        params.data.pricing_information.price,
        params.data.pricing_information.shipping_per_unit,
        params.data.pricing_information.additional_charges,
        params.discount_percentage
    );
    total_completed_payment_amt += parseFloat(
        params.data.payment_information.completed
    );
    total_credit_amt += parseFloat(params.data.payment_information.credited);
    for (let batch of params.data.batch_items) {
        total_pending_amt += calculatePendingAmount(
            params.data.pricing_information.price,
            params.data.pricing_information.shipping_per_unit,
            params.data.pricing_information.additional_charges,
            batch,
            params.discount_percentage,
            params.data.payment_information
        );
        let this_batch_rejected_qty =
            parseFloat(batch.fulfilment_information.qc_quantity_rejected) +
            parseFloat(batch.fulfilment_information.gr_quantity_rejected) +
            parseFloat(
                batch.fulfilment_information.gr_quantity_over_delivered
            ) +
            parseFloat(batch.fulfilment_information.quantity_terminated);
        total_rejected_qty += this_batch_rejected_qty;
        total_amt += calculateItemTotal(
            batch.quantity_information.batch_quantity,
            params.data.pricing_information.price,
            params.data.pricing_information.shipping_per_unit,
            params.data.pricing_information.additional_charges
        );

        if (batch.termination_information !== null) {
            if (
                batch.termination_information.status ===
                'INVOICE_ITEM_TERMINATION_BUYER_REQUESTED'
            ) {
                total_qty_termination_req += parseFloat(
                    batch.quantity_information.batch_quantity
                );
            }
        } else if (batch.hold_information !== null) {
            total_qty_on_hold +=
                +batch.quantity_information.batch_quantity -
                this_batch_rejected_qty;
        }
    }
    let rejected_amt = total_rejected_qty * _effective_rate;
    let totalQtyOnHoldTermination =
        total_qty_on_hold + total_qty_termination_req;
    let totalOnHoldTerminationAmount =
        totalQtyOnHoldTermination * _effective_rate;

    total_pending_amt =
        total_pending_amt - (total_completed_payment_amt - total_credit_amt);

    return (
        <>
            <Box className="flex flex--jcfe" width="100%">
                <FWLink
                    id={params.buttonId}
                    onClick={(e: any) => setAnchorEl(e.currentTarget)}
                >
                    {total_pending_amt
                        ? FWNumberFormatter(
                              total_pending_amt,
                              params.data.pricing_information
                                  .currency_code_abbreviation
                          )
                        : 'Paid'}
                </FWLink>
            </Box>
            <PendingAmountPopover
                data={{
                    totalAmount: total_amt,
                    amountPending: total_pending_amt,
                    rejectedAmount: rejected_amt,
                    onHoldTerminationAmount: totalOnHoldTerminationAmount,
                    creditedAmount: total_credit_amt,
                    amountPaid: total_completed_payment_amt,
                    currency:
                        params.data.pricing_information
                            .currency_code_abbreviation,
                    measurementUnit:
                        params.data.batch_items[0].quantity_information
                            .measurement_unit_name,
                    buyerEntityId: params.data.buyer_entity as string,
                }}
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
            />
        </>
    );
};

export const QuantityPendingPopup = ({
    invoice_item_batch,
    invoice_item_batch_pending_qty,
}: {
    invoice_item_batch: INewGetInvoiceBatchDetail;
    invoice_item_batch_pending_qty: number;
}) => {
    const [anchorEl, setAnchorEl] = React.useState<any | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
        setAnchorEl(event.target);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'qty-pending-popover' : undefined;

    return (
        <>
            <FWLink onClick={handleClick}>
                <FWTypography width={160} textAlign="right">
                    {FWNumberFormatter(invoice_item_batch_pending_qty)}{' '}
                    {
                        invoice_item_batch.quantity_information
                            .measurement_unit_name
                    }
                    (s)
                </FWTypography>
            </FWLink>
            <FWPopover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Grid
                    width="350px"
                    container
                    rowGap={1}
                    columnSpacing={0.5}
                    margin="12px"
                >
                    <Grid item xs={6}>
                        <FWTypography>Total quantity</FWTypography>
                    </Grid>
                    <Grid item xs={6}>
                        <FWTypography textAlign="right">
                            {FWNumberFormatter(
                                +invoice_item_batch.quantity_information
                                    .batch_quantity
                            )}{' '}
                            {
                                invoice_item_batch.quantity_information
                                    .measurement_unit_name
                            }
                            (s)
                        </FWTypography>
                    </Grid>
                    <Grid item xs={6}>
                        <FWTypography>GR rejection(s)</FWTypography>
                    </Grid>
                    <Grid item xs={6}>
                        <FWTypography textAlign="right">
                            {FWNumberFormatter(
                                +invoice_item_batch.fulfilment_information
                                    .gr_quantity_rejected
                            )}{' '}
                            {
                                invoice_item_batch.quantity_information
                                    .measurement_unit_name
                            }
                            (s)
                        </FWTypography>
                    </Grid>
                    <Grid item xs={6}>
                        <FWTypography>QC rejection(s)</FWTypography>
                    </Grid>
                    <Grid item xs={6}>
                        <FWTypography textAlign="right">
                            {FWNumberFormatter(
                                +invoice_item_batch.fulfilment_information
                                    .qc_quantity_rejected
                            )}{' '}
                            {
                                invoice_item_batch.quantity_information
                                    .measurement_unit_name
                            }
                            (s)
                        </FWTypography>
                    </Grid>
                    <Grid item xs={6}>
                        <FWTypography>Termination rejection(s)</FWTypography>
                    </Grid>
                    <Grid item xs={6}>
                        <FWTypography textAlign="right">
                            {FWNumberFormatter(
                                +invoice_item_batch.fulfilment_information
                                    .quantity_terminated
                            )}{' '}
                            {
                                invoice_item_batch.quantity_information
                                    .measurement_unit_name
                            }
                            (s)
                        </FWTypography>
                    </Grid>
                    <Grid item xs={6}>
                        <FWTypography>pl accepted(s)</FWTypography>
                    </Grid>
                    <Grid item xs={6}>
                        <FWTypography textAlign="right">
                            {FWNumberFormatter(
                                +invoice_item_batch.fulfilment_information
                                    .qc_pl_quantity_accepted
                            )}{' '}
                            {
                                invoice_item_batch.quantity_information
                                    .measurement_unit_name
                            }
                            (s)
                        </FWTypography>
                    </Grid>
                </Grid>
            </FWPopover>
        </>
    );
};

const calculatePendingAmount = (
    price: string,
    shipping_per_unit: string,
    additional_charges: { charge_name: string; charge_value: string }[],
    invoiceItemData: INewGetInvoiceBatchDetail,
    discount_percentage: number,
    payment_information: {
        completed: string;
        computed: string;
        credited: string;
        due_date: string | null;
        pending: string;
    }
) => {
    if (['COMPLETED', 'TERMINATED'].includes(invoiceItemData.status)) {
        return 0;
    }
    let fulfilled_qty =
        parseFloat(invoiceItemData.quantity_information.batch_quantity) -
        calculateRejectionQuantity(invoiceItemData);

    let baseAmount = parseFloat(price) * fulfilled_qty;
    let shippingAmount = parseFloat(shipping_per_unit) * fulfilled_qty;

    let taxPercentage = 0;
    for (let tax of additional_charges) {
        taxPercentage += parseFloat(tax.charge_value);
    }
    let taxAmount = (taxPercentage / 100) * baseAmount;
    let totalBeforeDiscount = baseAmount + shippingAmount + taxAmount;
    let totalAfterDiscount =
        ((100 - discount_percentage) / 100) * totalBeforeDiscount;

    // totalAfterDiscount =
    //     Math.round((totalAfterDiscount + Number.EPSILON) * 100) / 100;

    // let paidValue = parseFloat(
    //     payment_information.completed ? payment_information.completed : '0'
    // );
    // let creditedValue = parseFloat(
    //     payment_information.credited ? payment_information.credited : '0'
    // );
    // let netPaidValue = paidValue - creditedValue;
    return totalAfterDiscount;
};

const calculateRejectionQuantity = (
    invoiceItemData: INewGetInvoiceBatchDetail
) => {
    let rejection_quantity = 0;
    if (
        !isNaN(
            parseFloat(
                invoiceItemData.fulfilment_information.gr_quantity_rejected
            )
        )
    ) {
        rejection_quantity += parseFloat(
            invoiceItemData.fulfilment_information.gr_quantity_rejected
        );
    }
    if (
        !isNaN(
            parseFloat(
                invoiceItemData.fulfilment_information
                    .gr_quantity_over_delivered
            )
        )
    ) {
        rejection_quantity += parseFloat(
            invoiceItemData.fulfilment_information.gr_quantity_over_delivered
        );
    }
    if (
        !isNaN(
            parseFloat(
                invoiceItemData.fulfilment_information.qc_quantity_rejected
            )
        )
    ) {
        rejection_quantity += parseFloat(
            invoiceItemData.fulfilment_information.qc_quantity_rejected
        );
    }
    if (
        !isNaN(
            parseFloat(
                invoiceItemData.fulfilment_information.quantity_terminated
            )
        )
    ) {
        rejection_quantity += parseFloat(
            invoiceItemData.fulfilment_information.quantity_terminated
        );
    }
    return rejection_quantity;
};

export const RejectedQtyPopup = ({
    invoice_item,
    currentPortal,
    buttonId,
}: {
    invoice_item: INewGetInvoiceItemDetail;
    currentPortal: 'BUYER' | 'SELLER';
    buttonId?: string;
}) => {
    const [anchorEl, setAnchorEl] = React.useState<any | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
        setAnchorEl(event.target);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'rejected-qty-popover' : undefined;

    let total_rejected_qty = 0;
    // let batches_with_complete_payment = 0;
    // const no_of_batches = invoice_item.all_batch_info.length;
    for (let batch of invoice_item.batch_items) {
        total_rejected_qty +=
            parseFloat(batch.fulfilment_information.qc_quantity_rejected) +
            parseFloat(batch.fulfilment_information.gr_quantity_rejected);
        // parseFloat(batch.fulfilment_information.quantity_terminated);
        // if (batch.payment_status === 'PAYMENT_COMPLETE') {
        //     batches_with_complete_payment += 1;
        // }
    }

    if (
        invoice_item.termination_information?.status ===
        'INVOICE_ITEM_TERMINATION_SELLER_ACCEPTED'
    ) {
        total_rejected_qty += +invoice_item.termination_information.quantity;
    }

    return (
        <>
            {/* if total rejected quantity is zero  */}
            {total_rejected_qty === 0 ? (
                <FWLink id={buttonId} onClick={handleClick} width={130}>
                    <FWTypography textAlign="right" color={'success.main'}>
                        {`${FWNumberFormatter(total_rejected_qty)} ${
                            invoice_item.batch_items[0].quantity_information
                                .measurement_unit_name
                        }(s)`}
                    </FWTypography>
                </FWLink>
            ) : (
                <FWLink id={buttonId} onClick={handleClick} width={130}>
                    <FWTypography textAlign="right" color={'error.main'}>
                        {`${FWNumberFormatter(total_rejected_qty)} ${
                            invoice_item.batch_items[0].quantity_information
                                .measurement_unit_name
                        }(s)`}
                    </FWTypography>
                </FWLink>
            )}
            <FWPopover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                PaperProps={{
                    sx: {
                        maxWidth: 'unset',
                        minWidth: '750px',
                    },
                }}
            >
                <ItemRejectionDetails
                    invoiceItemUid={invoice_item.invoice_item_id}
                    terminatedQuantity={
                        invoice_item.termination_information?.status ===
                        'INVOICE_ITEM_TERMINATION_SELLER_ACCEPTED'
                            ? invoice_item.termination_information.quantity
                            : '0'
                    }
                    measurementUnitName={
                        invoice_item.batch_items[0].quantity_information
                            .measurement_unit_name
                    }
                    currentPortal={currentPortal}
                    buyerEntityId={invoice_item.buyer_entity ?? ''}
                />
            </FWPopover>
        </>
    );
};

export const getErrorHandlingProps = (
    field_name: string,
    is_quantity: boolean = false,
    errors: { [key: string]: any }
): {
    error: boolean;
    helper: {
        text: string;
        textColor: any;
    };
} => {
    return {
        error:
            is_quantity && 'quantity' in errors
                ? errors['quantity'].error_type === 'ERROR'
                : field_name in errors
                ? errors[field_name].error_type === 'ERROR'
                : false,
        helper: {
            text:
                field_name in errors
                    ? errors[field_name].error_msg
                    : is_quantity && 'quantity' in errors
                    ? errors['quantity'].error_msg
                    : '',
            textColor: (function () {
                let val = 'error';
                if (field_name in errors) {
                    if (errors[field_name].error_type === 'ERROR')
                        val = 'error';
                    if (errors[field_name].error_type === 'WARNING')
                        val = 'warning';
                }
                if (is_quantity && 'quantity' in errors) {
                    if (errors['quantity'].error_type === 'ERROR')
                        val = 'error';
                    if (errors['quantity'].error_type === 'WARNING')
                        val = 'warning';
                }
                return val;
            })(),
        },
    };
};

interface IInvoiceItemBatchesPopup {
    invoiceItem: INewGetInvoiceItemDetail;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const InvoiceItemBatchesPopup = (props: IInvoiceItemBatchesPopup) => {
    return (
        <>
            <FWPopupWithChildren
                open={props.open}
                handleClose={() => props.setOpen(false)}
                title={'Batch Details'}
            >
                <DialogContent>
                    <Box display="flex">
                        <FWTypography color="text.secondary">
                            Item Name:
                        </FWTypography>
                        &nbsp;
                        <FWTypography fontWeight={500}>
                            {getBuyerItemName(
                                props.invoiceItem.item_information
                            )}
                        </FWTypography>
                    </Box>
                    <br />
                    <Box
                        display="grid"
                        gridTemplateColumns={'auto auto auto auto'}
                        border={'1px solid'}
                        borderColor="dividerColor.main"
                        borderRadius={'12px'}
                        padding="8px"
                    >
                        <FWTypography color="text.secondary" margin={'5px 0'}>
                            Batch ID
                        </FWTypography>
                        <FWTypography color="text.secondary" margin={'5px 0'}>
                            Batch status
                        </FWTypography>
                        <FWTypography color="text.secondary" margin={'5px 0'}>
                            Expiry date
                        </FWTypography>
                        <FWTypography color="text.secondary" margin={'5px 0'}>
                            Batch quantity
                        </FWTypography>
                        {props.invoiceItem.batch_items.map((batch, idx) => {
                            return (
                                <React.Fragment key={idx}>
                                    <Box className="flex flex--aic">
                                        <FWTypography>
                                            {batch.custom_batch_id}
                                        </FWTypography>
                                        {batch.notes && (
                                            <FWTooltip title={batch.notes}>
                                                <Box color="primary.main">
                                                    <i className="bi bi-info-circle"></i>
                                                </Box>
                                            </FWTooltip>
                                        )}
                                    </Box>
                                    <InvoiceItemStatusChip
                                        displayStatus={getNewInvoiceItemBBatchDisplayStatus(
                                            batch
                                        )}
                                        hoverText={
                                            batch.hold_information !== null
                                                ? batch.hold_information
                                                      .status === 'AUTOMATIC'
                                                    ? 'Mismatch from PO'
                                                    : ''
                                                : ''
                                        }
                                    />
                                    <FWTypography>
                                        {batch.batch_expiry_date === '' ||
                                        batch.batch_expiry_date === null
                                            ? 'NA'
                                            : moment(
                                                  batch.batch_expiry_date
                                              ).format('YYYY-MM-DD')}
                                    </FWTypography>
                                    <FWTypography textAlign={'right'}>
                                        {FWNumberFormatter(
                                            parseFloat(
                                                batch.quantity_information
                                                    .batch_quantity
                                            )
                                        )}{' '}
                                        {
                                            batch.quantity_information
                                                .measurement_unit_name
                                        }
                                    </FWTypography>
                                </React.Fragment>
                            );
                        })}
                    </Box>
                </DialogContent>
                <DialogActions sx={{ m: '12px 24px' }}>
                    {/* buttons */}
                </DialogActions>
            </FWPopupWithChildren>
        </>
    );
};
