import {
    BarElement,
    CategoryScale,
    ChartData,
    Chart as ChartJS,
    ChartOptions,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip,
} from 'chart.js';
import { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { ChartLocaleMap } from '../../../Constants/ChartLocaleMap';
import { graphColor } from '../../../Constants/HAGraphColor';
import { DynamicAnalyticsVendorBidWinPercentageData } from '../../../Interfaces/DynamicAnalyticsInterfaces.model';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels
);

export default function BidWinPercentageGraph({
    visibleData,
    number_format,
    currencyCodeSymbol,
    sellerEntityUuid,
}: {
    visibleData: DynamicAnalyticsVendorBidWinPercentageData[];
    number_format: string;
    currencyCodeSymbol: string;
    sellerEntityUuid: string;
}) {
    const [graphData, setGraphData] = useState<{
        xLabels: string[];
        bidsWonData: (number | null)[];
        bidsLostData: (number | null)[];
    }>({
        xLabels: [],
        bidsWonData: [],
        bidsLostData: [],
    });

    useEffect(() => {
        // let xLabels: string[] = [];
        // let barData: (number | null)[] = [];
        // let lineData: (number | null)[] = [];
        // let bubbleData: (number | null)[] = [];
        // visibleData.forEach((item) => {
        //     xLabels.push(
        //         getFWDate(
        //             `1-${item.period.month}-${item.period.year}`,
        //             "MMM 'YY"
        //         )
        //     );
        //     barData.push(item.rate);
        //     lineData.push(item.quantity);
        //     bubbleData.push(item.avgRate);
        // });
        // setGraphData({
        //     xLabels,
        //     barData,
        //     lineData,
        //     bubbleData,
        // });

        let xLabels: string[] = [];
        let bidsWonData: (number | null)[] = [];
        let bidsLostData: (number | null)[] = [];

        visibleData.forEach((item) => {
            xLabels.push(
                `Q${item.period.quarter}, '${item.period.year
                    .toString()
                    .slice(2)}`
            );
            if (!item.totalItems) {
                bidsWonData.push(null);
                bidsLostData.push(null);
            } else {
                let bidWinPerc = Math.round(
                    ((item.itemsWon ?? 0) / item.totalItems) * 100
                );
                if (sellerEntityUuid === '89931a01-9a26-4356-895e-fe595fa30727')
                    bidWinPerc = 50;

                bidsWonData.push(bidWinPerc);
                bidsLostData.push(-(100 - bidWinPerc));
            }
        });
        setGraphData({
            xLabels,
            bidsWonData,
            bidsLostData,
        });
    }, [sellerEntityUuid, visibleData]);

    const options: ChartOptions<any> = {
        locale:
            ChartLocaleMap[number_format as 'INR' | 'USD' | 'EUR'] || 'en-US',
        responsive: true,
        indexAxis: 'y' as const,
        plugins: {
            tooltip: {
                enabled: false,
                callbacks: {
                    label: (tooltipItem: any, data: any) => {
                        return tooltipItem.datasetIndex === 0
                            ? `${graphData.bidsLostData[0]}%`
                            : `${graphData.bidsWonData[0]}%`;
                    },
                },
            },
            datalabels: {
                display: 'auto',
                align: function (context: any) {
                    var value = context.dataset.data[context.dataIndex];
                    return value > 0 ? 'end' : 'start'; // Adjust the alignment based on the value
                },
                anchor: function (context: any) {
                    var value = context.dataset.data[context.dataIndex];
                    return value > 0 ? 'end' : 'start';
                },
                clip: true,
                clamp: true,
                color: '#003F91',
                offset: 2,
            },
        },
        // plugins: {
        //     legend: {
        //         position: 'top' as const,
        //     },
        //     datalabels: {
        //         backgroundColor: function (context: any) {
        //             return 'purple';
        //         },
        //         borderRadius: 4,
        //         color: 'white',
        //         font: {
        //             weight: 'bold',
        //         },

        //         formatter: (value: any, context: any) => {
        //             return `${graphData.bubbleData[context.dataIndex]}`;
        //         },
        //         padding: 6,
        //         anchor: 'end', // set anchor to end
        //         align: 'end',
        //     },
        // },
        scales: {
            x: {
                type: 'linear',
                display: true,
                position: 'left',
                title: {
                    display: true,
                    text: 'Acceptance percentage',
                },
                min: -100,
                max: 100,
            },
            y: {
                display: true,
                position: 'left',
                title: {
                    display: true,
                    text: `Quarter`,
                },
                min: 0,
                grace: '5%',
                // max: graphMaxVal(graphData.barData),
                ticks: {
                    callback: function (
                        val: string | number,
                        index: number
                    ): string {
                        return typeof val === 'number'
                            ? (this as any)
                                  .getLabelForValue(val)
                                  .substring(0, 10)
                            : '';
                    },
                },
            },
            // y1: {
            //     display: true,
            //     position: 'right',
            //     title: {
            //         display: true,
            //         text: 'Bid response',
            //     },
            //     grid: {
            //         drawOnChartArea: false,
            //     },
            //     min: 0,
            //     // max: graphMaxVal(graphData.lineData),
            //     grace: '5%',
            //     scales: {
            //         yAxes: [
            //             {
            //                 ticks: {
            //                     beginAtZero: true,
            //                 },
            //             },
            //         ],
            //     },
            // },
        },
    };

    const labels = graphData.xLabels;

    const data: ChartData<any> = {
        labels,
        datasets: [
            {
                label: 'Bids Lost',
                data: graphData.bidsLostData,
                backgroundColor: graphColor[1],
                stack: '0',
                yAxisID: 'y',
                maxBarThickness: 80,
                // order: 1,
                // datalabels: {
                //     labels: {
                //         title: null, //hides datalabel for this metric
                //     },
                // },
            },
            {
                label: 'Bids Won',
                data: graphData.bidsWonData,
                backgroundColor: graphColor[0],
                yAxisID: 'y',
                stack: '0',
                maxBarThickness: 80,
                // order: 1,
                // datalabels: {
                //     labels: {
                //         title: null, //hides datalabel for this metric
                //     },
                // },
            },
        ],
    };
    return (
        <Bar options={options} data={data} /* plugins={[ChartDataLabels]} */ />
    );
}
