import { useCallback, useEffect, useState } from 'react';
import { batch, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useSocket } from '../Chats/Hooks/ChatSocketHook';
import { IAuthState } from '../Models/AuthContext.model';
import { IUserDetails, IUserPermission } from '../Models/UserDetails.model';
import { useSetAdminSettings } from '../Organizations/Admin/Hooks/AdminSettingsHook';
import { UserRoles } from '../Organizations/Admin/Interfaces/UsersInterface';
import { NotificationActions } from '../Redux/Reducers/NotificationsReducers';
import { UserDefaultsActions } from '../Redux/Reducers/UserDefaultsReducer';
import { VerificationActions } from '../Redux/Reducers/VerificationReducers';
import { updateNotificationList } from '../Redux/Slices/NotificationsSlice';
import {
    updateAllEntities,
    updateUserEntities,
} from '../Redux/Slices/VerificationSlice';
import { updateUserDefaults } from '../Redux/Slices/userDefaultsSlice';
import { createNewInstance } from '../Utils/config';
import {
    calculateRedirectRoute,
    checkPermissionHelper,
    createPermissionContext,
    generateUserEntitiesList,
    refreshAccessToken,
} from './helperFunctions';

declare global {
    interface Window {
        dataLayer: {
            event: string;
            user_id: string;
        }[];
    }
}

export default function useAuthContext() {
    const dispatch = useDispatch();
    // state containing authentication authData
    const [auth, setAuth] = useState<IAuthState>({
        access: '',
        modulePermissions: {
            BUYER: null,
            SELLER: null,
            CUSTOMIZE: null,
            GLOBAL_ADMIN: false,
            CUSTOMER: null,
        },
        details: null,
    });
    const userAccessToken = auth.access;
    const UserdefaultHomePage =
        auth.details === null || auth.details.defaults === null
            ? ''
            : auth.details.defaults.homepage;

    const [redirectStatus, setRedirectStatus] = useState({
        isLoading: true,
        path: '/',
    });
    const [originalPath, setOriginalPath] = useState({
        pathName: '/',
        search: '',
    });

    const history = useHistory();

    useSetAdminSettings();
    // initialize the socket hook to start socket connection
    useSocket(auth.details === null ? null : auth.details.user_id);

    const updatedUserVerificationData = useCallback(
        async (
            defaults: any,
            isAdmin: boolean
        ): Promise<{ entityUids: string[] }> => {
            // The below API call should only happen after refreshAccessToken() because that's
            // where we set the interceptor
            try {
                const { entities, entityUids } =
                    await generateUserEntitiesList();
                /*
                    USER VERIFICATION RELATED UPDATES
                    */
                batch(() => {
                    dispatch(
                        updateUserDefaults({
                            type: UserDefaultsActions.UPDATE_USER_DEFAULTS,
                            value: defaults,
                        })
                    );
                    dispatch(
                        updateAllEntities({
                            type: VerificationActions.UPDATE_ALL_ENTITIES,
                            value: entities,
                        })
                    );
                    if (isAdmin) {
                        dispatch(
                            updateUserEntities({
                                type: VerificationActions.UPDATE_ENTITIES,
                                value: entityUids,
                            })
                        );
                    }
                });
                return { entityUids };
                // -----------------------------------------------------------------------------
            } catch (err) {
                throw err;
            }
        },
        [dispatch]
    );

    const checkValidity = useCallback(async () => {
        setRedirectStatus({
            isLoading: true,
            path: '/' /* showUserPopup: false */,
        });
        /*check validity and renew access authData if required*/
        try {
            if (userAccessToken !== '') {
                // parse the JWT authData to get expiry time in epochs
                let currTime = new Date();
                let accessTokenTime = parseInt(
                    JSON.parse(atob(userAccessToken.split('.')[1])).exp
                );
                // convert epoch to UTC seconds
                let accessexpTime = new Date(0);
                accessexpTime.setUTCSeconds(accessTokenTime);
                // based on the expiry time of the refresh token
                // and the current time, decide whether to renew the token or create a new one
                if (currTime > accessexpTime) {
                    let refresh_resp: any = await refreshAccessToken();
                    // should only be called after refreshAccessToken() because that's where we set the interceptor
                    const { entityUids } = await updatedUserVerificationData(
                        refresh_resp.details.defaults || null,
                        refresh_resp.details.role === UserRoles.ADMIN /* ||
                            refresh_resp.details.role === UserRoles.ADMIN_VIEW */
                    );
                    let user_default = refresh_resp.details!.defaults;
                    let modulePermissions = await createPermissionContext(
                        refresh_resp.permissions,
                        refresh_resp.details
                            ? refresh_resp.details.role === 'ADMIN' /* ||
                                  refresh_resp.details.role === 'ADMIN_VIEW' */
                            : false,
                        entityUids
                    );
                    let path = calculateRedirectRoute(
                        window.location.pathname + window.location.search,
                        originalPath,
                        user_default.homepage
                    );
                    setAuth({
                        access: refresh_resp.access,
                        modulePermissions: modulePermissions,
                        details: refresh_resp.details,
                    });
                    dispatch(
                        updateUserDefaults({
                            type: UserDefaultsActions.UPDATE_ENTERPRISE_ID,
                            value: refresh_resp.details.enterprise_id,
                        })
                    );
                    setRedirectStatus({
                        isLoading: false,
                        path: path,
                    });
                } else {
                    let path = calculateRedirectRoute(
                        window.location.pathname + window.location.search,
                        originalPath,
                        UserdefaultHomePage
                    );
                    setRedirectStatus({
                        isLoading: false,
                        path: path,
                    });
                }
            } else {
                let refresh_resp: any = await refreshAccessToken();
                // should only be called after refreshAccessToken() because that's where we set the interceptor
                const { entityUids } = await updatedUserVerificationData(
                    refresh_resp.details.defaults || null,
                    refresh_resp.details.role === UserRoles.ADMIN /* ||
                        refresh_resp.details.role === UserRoles.ADMIN_VIEW */
                );
                let user_default = refresh_resp.details!.defaults;
                let modulePermissions = await createPermissionContext(
                    refresh_resp.permissions,
                    refresh_resp.details
                        ? refresh_resp.details.role === 'ADMIN' /* ||
                              refresh_resp.details.role === 'ADMIN_VIEW' */
                        : false,
                    entityUids
                );
                let path = calculateRedirectRoute(
                    window.location.pathname + window.location.search,
                    originalPath,
                    user_default === null ? '' : user_default.homepage
                );
                dispatch(
                    updateUserDefaults({
                        type: UserDefaultsActions.UPDATE_ENTERPRISE_ID,
                        value: refresh_resp.details.enterprise_id,
                    })
                );
                setAuth({
                    access: refresh_resp.access,
                    modulePermissions: modulePermissions,
                    details: refresh_resp.details,
                });
                setRedirectStatus({
                    isLoading: false,
                    path: path,
                });
            }
        } catch (err) {
            localStorage.removeItem('refreshToken');
            localStorage.removeItem('idToken');
            sessionStorage.removeItem('refreshToken');
            sessionStorage.removeItem('idToken');
            setAuth({
                access: '',
                modulePermissions: {
                    BUYER: null,
                    SELLER: null,
                    CUSTOMIZE: null,
                    CUSTOMER: null,
                    GLOBAL_ADMIN: false,
                },
                details: null,
            });
            setRedirectStatus({
                isLoading: false,
                path: '/',
            }); // goto login page
        }
    }, [
        userAccessToken,
        updatedUserVerificationData,
        originalPath,
        UserdefaultHomePage,
        dispatch,
    ]);

    // check for access authData and user Permission and if present redirect to dashboard
    useEffect(() => {
        checkValidity();
    }, [checkValidity]);

    // captures the intitial path using which the user opened the page
    // this is used to redirect the user after logging in
    useEffect(() => {
        setOriginalPath({
            pathName: window.location.pathname,
            search: window.location.search,
        });
    }, []);

    /* listen for updates to auth context and if a new authData is updated update the axios instance and
    also set a interval function to automatically update the authData next time*/
    useEffect(() => {
        if (userAccessToken !== '') {
            let accessTokenTime = parseInt(
                JSON.parse(atob(userAccessToken.split('.')[1])).exp
            );
            // convert epoch to UTC seconds
            let accessexpTime = new Date(0);
            accessexpTime.setUTCSeconds(accessTokenTime);
            let milliseconds = accessexpTime.getTime() - new Date().getTime();
            // check for how long current_access authData is valid and set a repetitive function to update access_token
            setInterval(checkValidity, milliseconds - 60000);
        }
    }, [userAccessToken, checkValidity]);

    useEffect(() => {
        try {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'userData',
                user_id: auth?.details?.username || '',
            });
        } catch (err: any) {}
    }, [auth?.details?.username]);

    // function to update auth details
    const toggleAuth = useCallback(
        async (
            access: string,
            permissions: Array<IUserPermission>,
            details: IUserDetails | null
        ) => {
            try {
                if (access === '') {
                    // if access token is empty reset everything
                    createNewInstance();
                    history.replace(`/${window.location.search}`);
                    // reset notifications
                    dispatch(
                        updateNotificationList({
                            type: NotificationActions.INIT,
                            value: [],
                        })
                    );
                    setAuth({
                        access: access,
                        modulePermissions: {
                            BUYER: null,
                            SELLER: null,
                            CUSTOMIZE: null,
                            CUSTOMER: null,
                            GLOBAL_ADMIN: false,
                        },
                        details: details,
                    });
                } else if (access !== null && details !== null) {
                    // if access token is not empty recalculate stuff
                    // should only be called after refreshAccessToken() because that's where we set the interceptor
                    const { entityUids } = await updatedUserVerificationData(
                        details.defaults || null,
                        details.role === UserRoles.ADMIN /* ||
                            details.role === UserRoles.ADMIN_VIEW */
                    );
                    const modulePermissions = await createPermissionContext(
                        permissions,
                        details
                            ? details.role === 'ADMIN' /* ||
                                  details.role === 'ADMIN_VIEW' */
                            : false,
                        entityUids
                    );

                    const query = new URLSearchParams(originalPath.search);

                    const entityId = query.get('entity_id');
                    const docuemntUid = query.get('docuemnt_id');
                    if (
                        query.get('type') === 'DOCUMENT' &&
                        entityId &&
                        docuemntUid
                    ) {
                        // history.push(
                        //     `/seller/document/${entityId}/${docuemntUid}/`
                        // );
                        history.push(`/seller/profile/documents/`);
                    } else {
                        history.push(originalPath.pathName);
                    }

                    setAuth({
                        access: access,
                        modulePermissions: modulePermissions,
                        details: details,
                    });
                }
            } catch (err) {
                // if access token is empty reset everything
                createNewInstance();
                history.replace(`/${window.location.search}`);
                // reset notifications
                dispatch(
                    updateNotificationList({
                        type: NotificationActions.INIT,
                        value: [],
                    })
                );
                setAuth({
                    access: access,
                    modulePermissions: {
                        BUYER: null,
                        SELLER: null,
                        CUSTOMIZE: null,
                        CUSTOMER: null,
                        GLOBAL_ADMIN: false,
                    },
                    details: details,
                });
                toast.error('Error could not authenticate user');
            }
        },
        [
            dispatch,
            history,
            originalPath.pathName,
            originalPath.search,
            updatedUserVerificationData,
        ]
    );

    // function that executes when user selects one of the user type from the popup
    const redirectOnPopup = (user_type: 'buyer' | 'seller') => {
        if (user_type === 'buyer') {
            let user_default = auth.details!.defaults;
            setRedirectStatus({
                isLoading: false,
                path:
                    originalPath.pathName !== '/'
                        ? originalPath.pathName + originalPath.search
                        : user_default === null
                        ? '' + originalPath.search
                        : user_default.homepage + originalPath.search,
            });
        } else
            setRedirectStatus({
                isLoading: false,
                path:
                    originalPath.pathName !== '/' ||
                    window.location.search.includes('docuemnt_id')
                        ? originalPath.pathName +
                          '?' +
                          originalPath.search.replace('?', '') +
                          '&' +
                          window.location.search.replace('?', '')
                        : '/seller/events' + originalPath.search,
            });
    };

    const checkPermission = useCallback(
        (
            portal:
                | 'BUYER'
                | 'SELLER'
                | 'CUSTOMIZE'
                | 'GLOBAL_ADMIN'
                | 'CUSTOMER',
            module:
                | 'EVENT'
                | 'PURCHASE_ORDER'
                | 'INVOICE'
                | 'GOODS_RECEIPT'
                | 'QUALITY_CHECK'
                | 'PAYMENT'
                | 'CONTRACT'
                | 'PROJECT'
                | 'SUPPLIER_RELATIONSHIP_MANAGEMENT'
                | 'SUPPLIER_RELATIONSHIP_MANAGEMENT_SELLER'
                | 'QUOTE_CALCULATOR'
                | 'QUOTE_CALCULATOR_CUSTOMER'
                | null,
            permission:
                | 'VIEW'
                | 'EDIT'
                | 'APPROVE'
                | 'ADMIN'
                | 'VERIFY'
                | 'CALCULATOR_EDIT'
                | 'CART_VIEW'
                | 'CART_EDIT'
                | 'CART_APPROVE'
                | 'CART_TEMPLATE'
                | 'PROJECT_VIEW'
                | 'PROJECT_CREATE'
                | 'PROJECT_EDIT'
                | 'PROJECT_TEMPLATE'
                | 'PROJECT_ASSIGN_USERS'
                | 'PROJECT_ASSIGN_MANAGERS'
                | null,
            entity_id: string | null
        ) =>
            checkPermissionHelper(
                portal,
                module,
                permission,
                entity_id,
                auth.modulePermissions
            ),
        [auth.modulePermissions]
    );

    return {
        redirectStatus,
        toggleAuth,
        redirectOnPopup,
        checkPermission,
        auth,
        originalPath,
    };
}
