import { VerificationStatus } from '../../Redux/Slices/VerificationSlice';

export enum EntityStatus {
    INACTIVE = 'INACTIVE',
    ACTIVE = 'ACTIVE',
    SETUP_PENDING = 'SETUP_PENDING',
}

export interface IEntityDetails {
    entity_id: string;
    entity_name: string;
    entity_logo: {
        attachment_id: string;
        attachment_type: string;
        storage_id: string;
        resource_id: string;
        file_name: string;
        attachment_status: string;
    }[];
    buyer_status: EntityStatus;
    seller_status: EntityStatus;
    contacts: {
        emails: string[];
        phone_numbers: string[];
        websites: string[];
    };
    default_procurement_currency: string | null;
    enterprise: string;
    entity_description: string;
    entity_doing_business_as: string;
    is_virtual: boolean;
    verification_status: VerificationStatus;
    entity_logo_urls?: string[];
    enterprise_name: string;
    vendor_profile: {
        vendor_profile_id: string;
    } | null;
}
