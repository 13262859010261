import { Popover, PopoverProps, styled } from '@mui/material';

export const StyledPopover = styled(Popover)({
    '& .MuiPopover-paper': {
        padding: '12px',
        backgroundColor: 'white',
        boxShadow: '1px 1px 8px #8B8B8B',
    },
});

export default function FWPopover(props: PopoverProps) {
    return (
        <StyledPopover
            {...props}
            onClick={(e) => {
                e.stopPropagation();
            }}
            // disableScrollLock
        />
    );
}
