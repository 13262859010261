import { Grid, FormControl, IconButton } from '@mui/material';
import { Box } from '@mui/system';
import { FWCombinedInput } from '../../../../Common/FWCombinedInput';
import { FWMenu, FWMenuItem } from '../../../../Common/FWCustomMenu';
import { FWTooltip } from '../../../../Common/FWCustomTooltip';
import { FWInput } from '../../../../Common/FWInput';
import FWSelect from '../../../../Common/FWSelect';
import { FWTypography } from '../../../../Common/FWTypograhy';
import {
    PaymentTermsAppliedFromMenuItems,
    PaymentTermsNumberMenuItems,
} from '../../../../Global/Components/PaymentTermsMenuItems';
import {
    IItemStandardTerms,
    IItemStandardTermsOptions,
} from '../../Interfaces/ItemDirectoryInterface';

export enum STActionType {
    ADD = 'ADD',
    REMOVE = 'REMOVE',
}

export enum STField {
    LEAD = 'LEAD',
    PAYMENT = 'PAYMENT',
    PREPAYMENT = 'PREPAYMENT',
}

export enum STFieldKey {
    VALUE = 'value',
    OPTION = 'option',
}

interface IItemStandardTermsProps {
    data: IItemStandardTerms;
    emitSTValuesChange: (
        type: STField,
        value: IItemStandardTermsOptions | null
    ) => void;
    anchorEl: null | HTMLElement;
    onClose: () => void;
    openMenu: (event: any) => void;
    closeMenu: () => void;
}

const ItemStandardTerms = ({
    data,
    emitSTValuesChange,
    anchorEl,
    onClose,
    openMenu,
    closeMenu,
}: IItemStandardTermsProps) => {
    const { lead, payment, prePayment }: IItemStandardTerms = data;

    let prePaymentMaxed =
        !(
            (prePayment && prePayment.value && +prePayment.value < 100) ||
            (prePayment && prePayment.value === '')
        ) && prePayment !== null;

    const handleTermValuesChange = (
        field: STField,
        key: STFieldKey,
        value: string
    ) => {
        let newValues = {} as IItemStandardTermsOptions;
        switch (field) {
            case STField.LEAD:
                newValues = { ...lead } as IItemStandardTermsOptions;
                break;
            case STField.PAYMENT:
                newValues = { ...payment } as IItemStandardTermsOptions;
                break;
            case STField.PREPAYMENT:
                if (+value >= 100) {
                    emitSTValuesChange(STField.PAYMENT, {
                        value: '',
                        option: '',
                    });
                } else {
                    emitSTValuesChange(STField.PAYMENT, {
                        value: payment?.value || '',
                        option: payment?.option || 'RECEIPT_DATE',
                    });
                }
                newValues = { ...prePayment } as IItemStandardTermsOptions;
                break;
        }
        newValues[key] = value;
        emitSTValuesChange(field, newValues);
    };

    const handleAddRemove = (type: STActionType, key: STField) => {
        switch (type) {
            case STActionType.ADD:
                emitSTValuesChange(key, {
                    value: '',
                    option: key === STField.PAYMENT ? 'RECEIPT_DATE' : '',
                });
                if (!payment && key === STField.PREPAYMENT) {
                    emitSTValuesChange(STField.PAYMENT, {
                        value: '',
                        option: 'RECEIPT_DATE',
                    });
                }
                if (!prePayment && key === STField.PAYMENT) {
                    emitSTValuesChange(STField.PREPAYMENT, {
                        value: '',
                        option: '',
                    });
                }
                break;
            case STActionType.REMOVE:
                emitSTValuesChange(key, null);
                // if (payment && key === STField.PREPAYMENT) {
                //     emitSTValuesChange(STField.PAYMENT, null);
                // }
                if (prePayment && key === STField.PAYMENT) {
                    emitSTValuesChange(STField.PREPAYMENT, null);
                }
                break;
        }
        closeMenu();
    };

    const IncludeTermButton = (
        <IconButton
            sx={{
                width: '40px',
                display: 'flex',
                alignItems: 'center',
                height: '40px',
            }}
            onClick={openMenu}
        >
            <Box color="primary.main">
                <i className="bi bi-plus-circle fs--11 flex flex--aic"></i>
            </Box>
        </IconButton>
    );

    const RemoveTermButton = (action: STActionType, term: STField) => {
        return (
            <IconButton
                className="ml--5"
                sx={{
                    width: '40px',
                    height: '40px',
                    display: 'flex',
                    alignItems: 'center',
                }}
                onClick={() => {
                    handleAddRemove(action, term);
                }}
            >
                <Box color="error.main">
                    <i className="bi bi-trash3 fs--11 flex flex--aic"></i>
                </Box>
            </IconButton>
        );
    };

    return (
        <>
            {(lead || payment || prePayment) && <div className="mt--5"></div>}
            <Grid container className="flex--aife">
                <Grid item xs={lead || payment || prePayment ? 10.5 : 0}>
                    {/* if lead time added */}
                    {lead && (
                        <div className="pl--10 mb--10">
                            <FWTypography color="text.secondary">
                                Lead time
                            </FWTypography>
                            <Grid container>
                                <Grid item xs={10.5} className="flex flex--aic">
                                    <FWCombinedInput
                                        required={true}
                                        isDropLeft={false}
                                        allowOnly="NUMBER"
                                        selectFieldMenuItems={[
                                            <FWMenuItem key={0} value={'DAYS'}>
                                                Day(s)
                                            </FWMenuItem>,
                                            <FWMenuItem key={1} value={'WEEKS'}>
                                                Week(s)
                                            </FWMenuItem>,
                                            <FWMenuItem
                                                key={2}
                                                value={'MONTHS'}
                                            >
                                                Month(s)
                                            </FWMenuItem>,
                                        ]}
                                        textFieldValue={lead.value}
                                        textFieldOnChange={(event: any) => {
                                            handleTermValuesChange(
                                                STField.LEAD,
                                                STFieldKey.VALUE,
                                                event.target.value
                                            );
                                        }}
                                        selectFieldValue={lead.option}
                                        selectFieldOnChange={(event: any) => {
                                            handleTermValuesChange(
                                                STField.LEAD,
                                                STFieldKey.OPTION,
                                                event.target.value
                                            );
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={1.5} className="flex flex--aic">
                                    {RemoveTermButton(
                                        STActionType.REMOVE,
                                        STField.LEAD
                                    )}
                                </Grid>
                            </Grid>
                        </div>
                    )}

                    {/* if pre-payment added */}
                    {prePayment && (
                        <div className="pl--10 mb--10">
                            <FWTypography color="text.secondary">
                                Prepayment (%)
                            </FWTypography>
                            <Grid container>
                                <Grid item xs={10.5} className="flex flex--aic">
                                    <FWInput
                                        allowOnly="DECIMAL_NUMBER"
                                        iconEnd={
                                            <FWTypography color="text.secondary">
                                                %
                                            </FWTypography>
                                        }
                                        value={prePayment.value || ''}
                                        onChange={(event: any) => {
                                            handleTermValuesChange(
                                                STField.PREPAYMENT,
                                                STFieldKey.VALUE,
                                                event.target.value
                                            );
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={1.5} className="flex flex--aic">
                                    {RemoveTermButton(
                                        STActionType.REMOVE,
                                        STField.PREPAYMENT
                                    )}
                                </Grid>
                            </Grid>
                        </div>
                    )}

                    {/* if payment added */}
                    {payment && (
                        <div className="pl--10 mb--10">
                            <Grid container className="flex--aife">
                                <FWTooltip
                                    title={`${
                                        prePaymentMaxed
                                            ? 'Payment terms are not required if prepayment is 100%'
                                            : ''
                                    }`}
                                >
                                    <Grid item xs={10.5}>
                                        <div
                                            style={{ width: '100%' }}
                                            className={`${
                                                prePaymentMaxed
                                                    ? 'disabled-opacity'
                                                    : ''
                                            }`}
                                        >
                                            <FWTypography color="text.secondary">
                                                Payment terms
                                            </FWTypography>
                                            <div className="flex">
                                                <FormControl fullWidth>
                                                    <FWSelect
                                                        sx={{
                                                            borderRadius:
                                                                '12px 0 0 12px',
                                                        }}
                                                        onChange={(
                                                            event: any
                                                        ) => {
                                                            handleTermValuesChange(
                                                                STField.PAYMENT,
                                                                STFieldKey.VALUE,
                                                                event.target
                                                                    .value
                                                            );
                                                        }}
                                                        value={
                                                            payment.value || ''
                                                        }
                                                        disabled={
                                                            prePaymentMaxed
                                                        }
                                                        required={
                                                            !prePaymentMaxed
                                                        }
                                                    >
                                                        {
                                                            PaymentTermsNumberMenuItems
                                                        }
                                                    </FWSelect>
                                                </FormControl>
                                                <FormControl fullWidth>
                                                    <FWSelect
                                                        sx={{
                                                            borderRadius:
                                                                '0 12px 12px 0',
                                                        }}
                                                        onChange={(
                                                            event: any
                                                        ) => {
                                                            handleTermValuesChange(
                                                                STField.PAYMENT,
                                                                STFieldKey.OPTION,
                                                                event.target
                                                                    .value
                                                            );
                                                        }}
                                                        value={
                                                            payment.option || ''
                                                        }
                                                        disabled={
                                                            prePaymentMaxed
                                                        }
                                                        required={
                                                            !prePaymentMaxed
                                                        }
                                                    >
                                                        {
                                                            PaymentTermsAppliedFromMenuItems
                                                        }
                                                    </FWSelect>
                                                </FormControl>
                                            </div>
                                        </div>
                                    </Grid>
                                </FWTooltip>
                                <Grid item xs={1.5} className="flex flex--aic">
                                    {RemoveTermButton(
                                        STActionType.REMOVE,
                                        STField.PAYMENT
                                    )}
                                </Grid>
                            </Grid>
                        </div>
                    )}
                </Grid>
                <Grid item xs={1.5}>
                    <div className="mb--10">
                        {(!lead || !payment || !prePayment) &&
                            IncludeTermButton}
                    </div>
                </Grid>
            </Grid>

            <FWMenu
                anchorEl={anchorEl}
                open={anchorEl !== null}
                onClose={onClose}
            >
                {!lead && (
                    <FWMenuItem
                        onClick={() => {
                            handleAddRemove(STActionType.ADD, STField.LEAD);
                        }}
                    >
                        <i className="bi bi-calendar4-week" /> &nbsp; Lead Time
                    </FWMenuItem>
                )}
                {!prePayment && (
                    <FWMenuItem
                        onClick={() => {
                            handleAddRemove(
                                STActionType.ADD,
                                STField.PREPAYMENT
                            );
                        }}
                    >
                        <i className="bi bi-cash-stack" /> &nbsp; Pre-payment
                    </FWMenuItem>
                )}
                {!payment && (
                    <FWMenuItem
                        onClick={() => {
                            handleAddRemove(STActionType.ADD, STField.PAYMENT);
                        }}
                    >
                        <i className="bi bi-body-text" /> &nbsp; Payment Terms
                    </FWMenuItem>
                )}
            </FWMenu>
        </>
    );
};

export default ItemStandardTerms;
