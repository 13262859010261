import { createTheme, ThemeOptions } from '@mui/material/styles';

// FIXME: Array(25).fill("none") add this with typescript
export const defaultTheme: ThemeOptions = createTheme({
    typography: {
        fontSize: 16,
        fontFamily: "'Inter', sans-serif",
        body1: {
            fontFamily: "'Inter', sans-serif",
            fontSize: 16,
            lineHeight: '1.5em',
            letterSpacing: '-0.011px',
        },
        caption: {
            lineHeight: '1.5em',
            letterSpacing: '-0.011px',
            fontSize: '14px',
        },
        h1: {
            fontWeight: '500',
            lineHeight: '1.5em',
            letterSpacing: '-0.02em',
            fontSize: '45px',
        },
        h2: {
            fontWeight: '500',
            lineHeight: '1.5em',
            letterSpacing: '-0.02em',
            fontSize: '40px',
        },
        h3: {
            fontWeight: '500',
            lineHeight: '1.5em',
            letterSpacing: '-0.02em',
            fontSize: '36px',
        },
        h4: {
            fontWeight: '500',
            lineHeight: '1.5em',
            letterSpacing: '-0.02px',
            fontSize: '24px',
        },
        h5: {
            fontWeight: '500',
            lineHeight: '1.5em',
            letterSpacing: '-0.011px',
            fontSize: '16px',
        },
        h6: {
            lineHeight: '1.5em',
            letterSpacing: '-0.011px',
            fontSize: '12px',
        },
        button: {
            lineHeight: '1.5em',
            textTransform: 'capitalize',
            letterSpacing: '-0.011px',
            padding: '12px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: 'max-content',
            fontWeight: 'normal',
        },
    },
    shape: {
        borderRadius: 12,
    },
    shadows: [
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
    ],
    palette: {
        mode: 'light',
        common: {
            black: '#1A1A1F',
            white: '#ffffff',
        },
        primary: {
            main: '#007AFF',
            light: 'rgba(50, 107, 255, 0.75)',
        },
        secondary: {
            main: 'rgba(26, 26, 31, 0.6)',
        },
        error: {
            main: '#FF3B30',
        },
        warning: {
            main: '#FF9500',
        },
        info: {
            main: '#007AFF',
        },
        dividerColor: {
            main: '#C4C4c8',
        },
        icon_primary: {
            main: '#007AFF',
        },
        icon_secondary: {
            main: '#747478',
        },
        icon_disabled: {
            main: 'rgba(26, 26, 31, 0.3)',
        },
        success: {
            main: '#34C759',
        },
        text: {
            primary: '#1A1A1F',
            secondary: 'rgba(26, 26, 31, 0.6)',
            disabled: 'rgba(26, 26, 31, 0.3)',
        },
        background: {
            paper: '#F0F0F2',
            default: '#FFFFFF',
        },
    },
});

declare module '@mui/material/styles' {
    interface Palette {
        dividerColor: Palette['primary'];
        icon_primary: Palette['primary'];
        icon_secondary: Palette['primary'];
        icon_disabled: Palette['primary'];
    }

    // allow configuration using `createTheme`
    interface PaletteOptions {
        dividerColor?: PaletteOptions['primary'];
        icon_primary?: PaletteOptions['primary'];
        icon_secondary?: PaletteOptions['primary'];
        icon_disabled?: PaletteOptions['primary'];
    }
}

declare module '@mui/material/Button' {
    interface ButtonPropsColorOverrides {
        dividerColor: true;
        icon_primary: true;
    }
}

// margin_12
// margin_16
// margin_24
// margin_32
// margin_40
