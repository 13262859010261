import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ChartOptions,
    ChartData,
} from 'chart.js';
import { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { getFWDate } from '../../../../Common/Utils/DateUtils';
import { ChartLocaleMap } from '../../../Constants/ChartLocaleMap';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { graphColor } from '../../../Constants/HAGraphColor';
import { DynamicAnalyticsVendorPosOverTimeData } from '../../../Interfaces/DynamicAnalyticsInterfaces.model';
import { max } from 'lodash';
import {
    getStepSize,
    numberToAbbreviation,
} from '../../../helperFunctions/chartHelpers';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels
);

export default function OverTimeGraph({
    visibleData,
    number_format,
    currencyCodeSymbol,
}: {
    visibleData: DynamicAnalyticsVendorPosOverTimeData[];
    number_format: string;
    currencyCodeSymbol: string;
}) {
    const [graphData, setGraphData] = useState<{
        xLabels: string[];
        barData: (number | null)[];
        lineData: (number | null)[];
    }>({
        xLabels: [],
        barData: [],
        lineData: [],
    });

    const [currencyUnit, setCurrencyUnit] = useState<string | null>(null);

    useEffect(() => {
        let xLabels: string[] = [];
        let barData: (number | null)[] = [];
        let lineData: (number | null)[] = [];
        visibleData.forEach((item) => {
            xLabels.push(
                getFWDate(
                    `${item.period.month}-1-${item.period.year}`,
                    "MMM 'YY"
                )
            );
            barData.push(item.poValue);
            lineData.push(item.poCount);
        });

        const maxVal = max(barData.filter((val) => val !== null));

        const { abbrevitedNumberArr, currencyUnit } = numberToAbbreviation(
            maxVal || 0,
            barData,
            number_format === 'EUR' ? 'USD' : (number_format as 'USD' | 'INR')
        );

        barData = abbrevitedNumberArr;
        setCurrencyUnit(currencyUnit);

        setGraphData({
            xLabels,
            barData,
            lineData,
        });
    }, [number_format, visibleData]);

    var stepSize1 = getStepSize(
        max(graphData.barData.filter((val) => val !== null)) || 0,
        6
    );

    var stepSize2 = getStepSize(
        max(graphData.lineData.filter((val) => val !== null)) || 0,
        6
    );

    const options: ChartOptions<any> = {
        locale:
            ChartLocaleMap[number_format as 'INR' | 'USD' | 'EUR'] || 'en-US',
        responsive: true,
        plugins: {
            legend: {
                position: 'top' as const,
            },
            datalabels: {
                display: 'auto',
                align: 'top',
                anchor: 'end',
                clip: true,
                clamp: true,
                color: '#003F91',
            },
        },
        scales: {
            x: {
                beginAtZero: true,
                grace: '20%',
                title: {
                    display: true,
                    text: 'Months',
                },
                grid: {
                    display: false, // hide the vertical grid lines for the x-axis
                },
                ticks: {
                    callback: function (
                        val: string | number,
                        index: number
                    ): string {
                        return typeof val === 'number'
                            ? (this as any)
                                  .getLabelForValue(val)
                                  .substring(0, 10)
                            : '';
                    },
                },
            },
            y: {
                type: 'linear',
                display: true,
                position: 'left',
                beginAtZero: true,
                grace: '20%',
                title: {
                    display: true,
                    text: `PO amount ${
                        currencyUnit ? `(In ${currencyUnit})` : ''
                    }`,
                },
                min: 0,
                ticks: {
                    stepSize: stepSize1,
                    callback: function (
                        val: string | number,
                        index: number
                    ): string {
                        return typeof val === 'number'
                            ? `${currencyCodeSymbol}${(
                                  this as any
                              ).getLabelForValue(val)}`
                            : '';
                    },
                },
            },
            y1: {
                type: 'linear',
                display: true,
                position: 'right',
                title: {
                    display: true,
                    text: `Orders`,
                },
                grid: {
                    drawOnChartArea: false,
                },
                min: 0,
                beginAtZero: true,
                grace: '20%',
                ticks: {
                    stepSize: stepSize2,
                },
            },
        },
    };

    const labels = graphData.xLabels;

    const data: ChartData<any> = {
        labels,
        datasets: [
            {
                label: `PO amount (${currencyCodeSymbol})`,
                data: graphData.barData.map((value) => value?.toFixed(2)),
                backgroundColor: graphColor[0],
                type: 'bar',
                yAxisID: 'y',
                order: 1,
                maxBarThickness: 80,
            },
            {
                label: `Orders`,
                data: graphData.lineData.map((value) => value?.toFixed(2)),
                backgroundColor: graphColor[1],
                yAxisID: 'y1',
                order: 0,
                maxBarThickness: 80,
            },
        ],
    };
    return <Line options={options} data={data} />;
}
