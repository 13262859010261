import {
    CostTypeEnum,
    IAdditionalCostsWithValue,
} from '../../../AdditionalCost/models/AdditionalCost.model';
import { FWNumberFormatter } from '../../../Common/Utils/CurrencyUtils';
import { getFWDate } from '../../../Common/Utils/DateUtils';
import { calculateItemTotal } from '../../../Components/Shared/totalCalculator';
import { IPaymentTerms } from '../../../Global/Interfaces/PaymentTermsInterface';
import {
    IRfqTemplate,
    TemplateStandardFieldsEnum,
} from '../../../Global/Interfaces/TemplateInterface';
import { ICurrencyRate } from '../../../Models/Currency.model';
import {
    IBomItemDeliverScheduleStructure,
    IEventItemDetails,
} from '../../Interfaces/Buyer/RFQ.model';
import { getCurrencyConversionRate } from '../../Services/Buyer/shared.service';
import { AdditionalCost } from '../Containers/DatabaseView';
import { IDBVRowData } from '../Services/DatabaseViewService';
import { IServiceRowMainDV } from '../Services/MainDataViewsService';

export const prepaymentFormatter = ({
    prepaymentPercentage,
}: {
    prepaymentPercentage: string;
}) => {
    return `${(+prepaymentPercentage).toFixed(2)}%`;
};

export const paymentTermsFormatter = ({
    paymentTerms,
    includePrepaymentString = true,
    delimiter = ';',
}: {
    paymentTerms: IPaymentTerms | null;
    includePrepaymentString?: boolean;
    delimiter?: string;
}) => {
    if (!paymentTerms) {
        return '-';
    }
    if (+paymentTerms.prepaymentPercentage === 100) {
        return '100% prepaid';
    }

    switch (paymentTerms.paymentType) {
        case 'invoiceItemValues':
            if (
                !(
                    paymentTerms.terms &&
                    paymentTerms.period &&
                    paymentTerms.appliedFrom
                )
            ) {
                return '-';
            }
            let s = ``;
            if (+paymentTerms.terms > 0) {
                s = `${
                    paymentTerms.terms
                } ${paymentTerms.period.toLowerCase()} from`;
            } else {
                s = 'On';
            }

            if (paymentTerms.appliedFrom.referenceDateCategory === 'CUSTOM') {
                s += ` ${paymentTerms.appliedFrom.referenceDateLabel}`;
            } else {
                s += ` ${paymentTerms.appliedFrom.referenceDateLabel
                    .toLowerCase()
                    .split('_')
                    .join(' ')}`;
            }
            if (
                includePrepaymentString &&
                +paymentTerms.prepaymentPercentage > 0
            ) {
                s += `${delimiter} Prepayment: ${(+paymentTerms.prepaymentPercentage).toFixed(
                    2
                )}%`;
            }
            return s.trim().length > 0 ? s : 'Edit payment terms';
        case 'poItemPercentages':
            const strList = paymentTerms.list.map((row) => {
                switch (row.milestoneType) {
                    case 'FIXED_DATE':
                        return `${(+row.poItemPercentage).toFixed(
                            2
                        )}% on ${getFWDate(row.paymentDate)}`;
                    case 'DELIVERABLE':
                        if (+row.terms > 0) {
                            return `${(+row.poItemPercentage).toFixed(2)}% ${
                                row.terms
                            } ${row.period.toLowerCase()} from ${
                                row.deliverableName
                            }`;
                        } else {
                            return `${(+row.poItemPercentage).toFixed(2)}% on ${
                                row.deliverableName
                            }`;
                        }

                    default:
                        return 'Edit payment terms';
                }
            });
            let st = strList.join(`${delimiter} `);
            if (
                includePrepaymentString &&
                +paymentTerms.prepaymentPercentage > 0
            ) {
                st += `${delimiter}  ${(+paymentTerms.prepaymentPercentage).toFixed(
                    2
                )}% Prepayment`;
            }
            return st.trim().length > 0 ? st : 'Edit payment terms';
    }
};

export const paymentTermsFormatterWithDefaultText = ({
    paymentTerms,
    includePrepaymentString = true,
    delimiter = ';',
}: {
    paymentTerms: IPaymentTerms | null;
    includePrepaymentString?: boolean;
    delimiter?: string;
}) => {
    return paymentTermsFormatter({
        paymentTerms,
        includePrepaymentString,
        delimiter,
    }).trim()
        ? paymentTermsFormatter({
              paymentTerms,
              includePrepaymentString,
              delimiter,
          }).trim()
        : 'Edit payment terms';
};

export const getListOfAlternatesItemIdsForAnItem = ({
    eventItemsList,
    rfqItemId,
}: {
    eventItemsList: IEventItemDetails[];
    rfqItemId: string;
}) => {
    const eventItem = eventItemsList?.find(
        (item) => item.rfq_item_id === rfqItemId
    );
    let idToFilter: string[] = [];

    const isalternateParent = !Boolean(
        eventItem?.delivery_schedule[0]?.bom_item?.alternate_parent_bom_item
            ?.raw_material_name
    );

    const alternateParentId = isalternateParent
        ? eventItem?.delivery_schedule[0]?.bom_item?.entry_id
        : null;

    if (alternateParentId) {
        if (isalternateParent) {
            idToFilter.push(rfqItemId);
        }
        eventItemsList?.forEach((eventItem) => {
            const itemAlternateId = eventItem?.delivery_schedule.find((ds) =>
                Boolean(
                    ds.bom_item?.alternate_parent_bom_item?.raw_material_name
                )
            )?.bom_item?.alternate_parent_bom_item?.entry_id;
            if (itemAlternateId === alternateParentId) {
                idToFilter.push(eventItem.rfq_item_id);
            }
        });
    } else {
        const alternateParentIdWhenCurrIsNotParent =
            eventItem?.delivery_schedule[0]?.bom_item?.alternate_parent_bom_item
                ?.entry_id;

        if (alternateParentIdWhenCurrIsNotParent) {
            const parentAlternateId = eventItemsList?.find((eventItem) =>
                eventItem.delivery_schedule.find(
                    (ds) =>
                        alternateParentIdWhenCurrIsNotParent ===
                        ds.bom_item?.entry_id
                )
            );

            if (parentAlternateId) {
                idToFilter.push(parentAlternateId.rfq_item_id);
                eventItemsList?.forEach((eventItem) => {
                    const itemAlternateId = eventItem?.delivery_schedule.find(
                        (ds) =>
                            Boolean(
                                ds.bom_item?.alternate_parent_bom_item
                                    ?.raw_material_name
                            )
                    )?.bom_item?.alternate_parent_bom_item?.entry_id;
                    if (
                        itemAlternateId ===
                        parentAlternateId.delivery_schedule[0]?.bom_item
                            ?.entry_id
                    ) {
                        idToFilter.push(eventItem.rfq_item_id);
                    }
                });
            }
        }
    }

    return idToFilter;
};

export const transformedSnakeCasedDataForDeliveryInformation = (
    deliverySchedule: {
        quantity: any;
        deliveryDate: any;
        cost_centre_id: any;
        general_ledger_id: any;
        project_id: any;
        bom_item: IBomItemDeliverScheduleStructure | null;
        parent_delivery_schedule_item?: any;
    }[]
) => {
    // The function only exists only to pass data in snake cased variable in some old component
    return deliverySchedule.map((delivery) => ({
        delivery_date: delivery.deliveryDate,
        quantity: delivery.quantity,
        cost_centre_id: delivery.cost_centre_id,
        general_ledger_id: delivery.general_ledger_id,
        project_id: delivery.project_id,
        bom_item: delivery.bom_item,
        parent_delivery_schedule_item: delivery.parent_delivery_schedule_item,
    }));
};

export const currencyFormatter = (
    price: string,
    currencyCodeAbbreviation: string
) => {
    if (+price > 0) {
        return FWNumberFormatter(parseFloat(price), currencyCodeAbbreviation);
    }
    return '-';
};
export const quantityFormatter = (
    quantity: string,
    measurementUnitName: string
) => {
    if (+quantity > 0) {
        return `${FWNumberFormatter(+quantity)} ${measurementUnitName}(s)`;
    } else {
        return '-';
    }
};
export const percentageFormatter = (value: string) => {
    return `${value} %`;
};

export const leadTimeFormatter = (
    leadTime: string | null,
    leadTimePeriod: string | null
) => {
    if (!leadTime || !leadTimePeriod) return '-';
    if (+leadTime === 0) {
        // const singularPeriod = leadTimePeriod.slice(0, -1);
        // return `Same ${singularPeriod.toLowerCase()}`;
        return `No lead time`;
    }
    return `${leadTime} ${leadTimePeriod.toLowerCase()}`;
};

export const totalAmountFormatter = ({
    quantity,
    price,
    shippingPerUnit,
    additionalCharges,
    currencyCodeAbbreviation,
}: {
    quantity: string;
    price: string;
    shippingPerUnit: string;
    additionalCharges: any[];
    currencyCodeAbbreviation: string;
}) => {
    const totalAmount = calculateItemTotal(
        quantity,
        price,
        shippingPerUnit,
        additionalCharges
    );
    return FWNumberFormatter(totalAmount, currencyCodeAbbreviation);
};

export const totalDaysCalculatorLeadTime = (value: string, period: string) => {
    const valueInInteger = parseInt(value);
    switch (period.toLowerCase()) {
        case 'weeks':
            return valueInInteger * 7;
        case 'months':
            return valueInInteger * 30;
        case 'years':
            return valueInInteger * 365;
        default:
            return valueInInteger;
    }
};

export const leadTimeGetterFromTotalDays = (days: number) => {
    let res = '';
    const leadTimeDefinition = [
        { totalDays: 365, displayVal: 'years' },
        { totalDays: 31, displayVal: 'months' },
        { totalDays: 7, displayVal: 'weeks' },
        { totalDays: 1, displayVal: 'days' },
    ];
    leadTimeDefinition.forEach((def, index) => {
        const unitTaken = Math.floor(days / def.totalDays);
        if (unitTaken > 0) {
            if (index > 0) res += ' ';
            res += `${unitTaken} ${def.displayVal}`;
            // removing the last 's' in case not required
            if (unitTaken === 1) res = res.slice(0, -1);
            days -= unitTaken * def.totalDays;
        }
    });
};

export const findEffectiveRateBidItem = ({
    price,
    shippingPerUnit,
    additionalCharges,
}: {
    price: string;
    shippingPerUnit: string;
    additionalCharges: {
        charge_name: string;
        charge_value: string;
    }[];
}) => {
    let totalTaxes = 0;
    for (let tax of additionalCharges) {
        totalTaxes += parseFloat(tax.charge_value);
    }
    const effectiveRate =
        +price * (1 + totalTaxes / 100) + (+shippingPerUnit ?? 0);
    return effectiveRate;
};

export const calculateItemTotalWithDiscount = ({
    quantity,
    price,
    shippingPerUnit,
    additionalCharges,
    bulkDiscountPercentage,
    bulkDiscountMinimumOrderValue,
}: {
    quantity: string;
    price: string;
    shippingPerUnit: string;
    additionalCharges: {
        charge_name: string;
        charge_value: string;
    }[];
    bulkDiscountPercentage: string;
    bulkDiscountMinimumOrderValue: string;
}) => {
    const totalAmount = calculateItemTotal(
        quantity,
        price,
        shippingPerUnit,
        additionalCharges
    );
    if (totalAmount >= +bulkDiscountMinimumOrderValue)
        return totalAmount * (1 - +bulkDiscountPercentage / 100);
    return totalAmount;
};

export const findVendorRankList = (data: IServiceRowMainDV[]) => {
    const uniqueRfqEventItems = Array.from(
        new Set(data.map((obj) => obj.rfqItem.rfqItemId))
    );
    const uniqueVendorsIds = Array.from(
        new Set(data.map((obj) => obj.sellerEntity.entityId))
    );
    // Filtered all vendors who fulfill total quantity also
    const bidItemsFullfillingTotalQuanity = data.filter(
        (obj) =>
            +obj.quantityInformation.quantity >=
            +obj.rfqItem.quantityInformation.quantity
    );
    const uniqueRfqItemDetails = uniqueRfqEventItems.map((rfqItemId) => {
        const vendorIdsForRfqItem = Array.from(
            new Set(
                bidItemsFullfillingTotalQuanity
                    .filter((obj) => obj.rfqItem.rfqItemId === rfqItemId)
                    .map((obj) => obj.sellerEntity.entityId)
            )
        );
        const vendorsDetails = vendorIdsForRfqItem.map(
            (vendorId) =>
                bidItemsFullfillingTotalQuanity.find(
                    (obj) => obj.sellerEntity.entityId === vendorId
                ) as IServiceRowMainDV
        );
        return {
            rfqItemId: rfqItemId,
            vendorList: vendorsDetails,
        };
    });

    const vendorsWhoFullfillAllRfqItem = uniqueVendorsIds.filter((vendorId) => {
        let didVendorBidOnAllRfqItems = true;
        uniqueRfqItemDetails.forEach((rfqItemDetails) => {
            if (
                !rfqItemDetails.vendorList.find(
                    (obj) => obj.sellerEntity.entityId === vendorId
                )
            )
                didVendorBidOnAllRfqItems = false;
        });
        return didVendorBidOnAllRfqItems;
    });

    const vendorRankList = vendorsWhoFullfillAllRfqItem
        .map((vendorId) => {
            let totalAmountAcrossAllRfqItems = 0;
            let vendorName = '';
            bidItemsFullfillingTotalQuanity.forEach((bidItem) => {
                if (bidItem.sellerEntity.entityId === vendorId) {
                    vendorName = bidItem.sellerEntity.entityName;
                    totalAmountAcrossAllRfqItems +=
                        calculateItemTotalWithDiscount({
                            ...bidItem.pricingInformation,
                            quantity: bidItem.quantityInformation.quantity,
                        });
                }
            });
            return {
                vendorId,
                vendorName,
                totalAmountAcrossAllRfqItems,
            };
        })
        .sort(
            (a, b) =>
                a.totalAmountAcrossAllRfqItems - b.totalAmountAcrossAllRfqItems
        );
    return vendorRankList;
};

export const convertRowDataCurrency = async (
    rowData: IServiceRowMainDV[],
    rfqEntryId: string
) => {
    let conversionRateMap = new Map<string, ICurrencyRate>();

    for (const obj of rowData) {
        if (
            obj.pricingInformation.currencyCodeId ===
                obj.rfqItem.pricingInformation.currencyCodeId ||
            obj.pricingInformation.currencyCodeId === ''
        ) {
            continue;
        }

        const key = `${obj.rfqItem.pricingInformation.currencyCodeId}-${obj.pricingInformation.currencyCodeId}`;
        let conversionRate = conversionRateMap.get(key);

        if (!conversionRate) {
            conversionRate = await getCurrencyConversionRate(
                obj.rfqItem.pricingInformation.currencyCodeId,
                obj.pricingInformation.currencyCodeId,
                rfqEntryId,
                obj.sellerEntity.entityId
            );
            conversionRateMap.set(key, conversionRate);

            // Fetch the conversion rate if not already fetched
        }

        let rate = conversionRate?.rate ?? 1;
        // let precision = 5;
        obj.conversionRate = rate;
        obj.event_currency_conversion = `1 ${
            obj.pricingInformation.currencyCodeAbbreviation
        } = ${rate.toFixed(4)} ${
            obj.rfqItem.pricingInformation.currencyCodeAbbreviation
        }`;
        obj.pricingInformation.bulkDiscountMinimumOrderValue = (
            Number(obj.pricingInformation.bulkDiscountMinimumOrderValue) * rate
        )
            .toFixed(10)
            .replace(/(\.[0-9]*[1-9])0+$|\.0*$/, '$1')
            .toString();

        obj.pricingInformation.effectiveRate = (
            Number(obj.pricingInformation.effectiveRate) * rate
        )
            .toFixed(10)
            .replace(/(\.[0-9]*[1-9])0+$|\.0*$/, '$1')
            .toString();
        const updateCosts = (costs: IAdditionalCostsWithValue[]) =>
            costs.map((charge) => {
                if (charge.costType !== 'PERCENTAGE') {
                    return {
                        ...charge,
                        value: (Number(charge.value) * Number(rate))
                            .toFixed(10)
                            .replace(/(\.[0-9]*[1-9])0+$|\.0*$/, '$1')
                            .toString(),
                    };
                }
                return charge;
            });

        obj.pricingInformation.price = (
            Number(obj.pricingInformation.price) * rate
        )
            .toFixed(10)
            .replace(/(\.[0-9]*[1-9])0+$|\.0*$/, '$1')
            .toString();
        obj.pricingInformation.additionalCosts = updateCosts(
            obj.pricingInformation.additionalCosts
        );

        obj.pricingInformation.discounts = updateCosts(
            obj.pricingInformation.discounts
        );
        obj.pricingInformation.taxes = updateCosts(
            obj.pricingInformation.taxes
        );

        obj.pricingInformation.currencyCodeId =
            obj.rfqItem.pricingInformation.currencyCodeId;
        obj.pricingInformation.currencyName =
            obj.rfqItem.pricingInformation.currencyName;
        obj.pricingInformation.currencySymbol =
            obj.rfqItem.pricingInformation.currencySymbol;
        obj.pricingInformation.currencyCodeAbbreviation =
            obj.rfqItem.pricingInformation.currencyCodeAbbreviation;
    }
    return rowData;
};

export const getEventAdditionalCostsHelper = (
    rfqitems: IDBVRowData[],
    rfqTemplate: IRfqTemplate
) => {
    const UniqueAdditionalCosts: {
        [key: string]: AdditionalCost;
    } = {};

    if (rfqitems) {
        rfqitems.forEach((rowData) => {
            const eventAdditionalCosts =
                rowData.pricingInformationRfqItem.additionalCosts;
            const eventTaxes = rowData.pricingInformationRfqItem.taxes;
            const eventDiscounts = rowData.pricingInformationRfqItem.discounts;

            eventAdditionalCosts.forEach((cost) => {
                if (!UniqueAdditionalCosts[cost.costName]) {
                    if (cost.costType === CostTypeEnum.ABSOLUTE_VALUE)
                        UniqueAdditionalCosts[cost.costName] = {
                            costName: cost.costName,
                            costType: CostTypeEnum.ABSOLUTE_VALUE,
                            allocationType: cost.allocationType,
                            additional_cost_id: cost.additional_cost_id,
                            additionalCostType: 'ADDITIONAL_COST',
                            costValue: cost.value,
                            otherCostValues: null,
                            selected: true,
                            additionalCostLinkageId: null,
                            cost_source: cost.cost_source,
                            is_calculated: cost.is_calculated ?? true,
                            rfq_event_item_ids: [],
                            seller_entity_ids: [],
                            formula: cost.formula,
                        };
                    else {
                        UniqueAdditionalCosts[cost.costName] = {
                            costName: cost.costName,
                            costType: CostTypeEnum.PERCENTAGE,
                            additional_cost_id: cost.additional_cost_id,
                            allocationType: cost.allocationType,
                            additionalCostType: 'ADDITIONAL_COST',
                            costValue: cost.value,
                            otherCostValues: null,
                            selected: true,
                            cost_source: cost.cost_source,
                            additionalCostLinkageId: null,
                            formula: cost.formula,
                            is_calculated: cost.is_calculated ?? true,
                            rfq_event_item_ids: [],
                            seller_entity_ids: [],
                        };
                    }
                }
            });
            eventTaxes.forEach((cost) => {
                if (!UniqueAdditionalCosts[cost.costName]) {
                    if (cost.costType === CostTypeEnum.ABSOLUTE_VALUE)
                        UniqueAdditionalCosts[cost.costName] = {
                            costName: cost.costName,
                            costType: CostTypeEnum.ABSOLUTE_VALUE,
                            allocationType: cost.allocationType,
                            additional_cost_id: cost.additional_cost_id,
                            additionalCostType: 'TAX',
                            costValue: cost.value,
                            otherCostValues: null,
                            selected: true,
                            cost_source: cost.cost_source,
                            additionalCostLinkageId: null,
                            rfq_event_item_ids: [],
                            seller_entity_ids: [],
                        };
                    else {
                        UniqueAdditionalCosts[cost.costName] = {
                            costName: cost.costName,
                            costType: CostTypeEnum.PERCENTAGE,
                            additional_cost_id: cost.additional_cost_id,
                            allocationType: cost.allocationType,
                            additionalCostType: 'TAX',
                            costValue: cost.value,
                            otherCostValues: null,
                            selected: true,
                            cost_source: cost.cost_source,
                            additionalCostLinkageId: null,
                            rfq_event_item_ids: [],
                            seller_entity_ids: [],
                        };
                    }
                }
            });
            eventDiscounts.forEach((cost) => {
                if (!UniqueAdditionalCosts[cost.costName]) {
                    if (cost.costType === CostTypeEnum.ABSOLUTE_VALUE)
                        UniqueAdditionalCosts[cost.costName] = {
                            costName: cost.costName,
                            costType: CostTypeEnum.ABSOLUTE_VALUE,
                            additional_cost_id: cost.additional_cost_id,
                            costValue: cost.value,
                            additionalCostType: 'DISCOUNT',
                            allocationType: cost.allocationType,
                            otherCostValues: null,
                            selected: true,
                            cost_source: cost.cost_source,
                            additionalCostLinkageId: null,
                            rfq_event_item_ids: [],
                            seller_entity_ids: [],
                        };
                    else {
                        UniqueAdditionalCosts[cost.costName] = {
                            costName: cost.costName,
                            costType: CostTypeEnum.PERCENTAGE,
                            additional_cost_id: cost.additional_cost_id,
                            allocationType: cost.allocationType,
                            additionalCostType: 'DISCOUNT',
                            costValue: cost.value,
                            otherCostValues: null,
                            selected: true,
                            cost_source: cost.cost_source,
                            additionalCostLinkageId: null,
                            rfq_event_item_ids: [],
                            seller_entity_ids: [],
                        };
                    }
                }
            });

            rfqTemplate.fieldSettings.standardFields[
                TemplateStandardFieldsEnum.ADDITIONAL_COSTS
            ].additionalCostOptions.forEach((costOptions) => {
                if (!UniqueAdditionalCosts[costOptions.costName]) {
                    if (costOptions.costType === CostTypeEnum.ABSOLUTE_VALUE)
                        UniqueAdditionalCosts[costOptions.costName] = {
                            costName: costOptions.costName,
                            costType: CostTypeEnum.ABSOLUTE_VALUE,
                            additional_cost_id: costOptions.additional_cost_id,
                            costValue: 0,
                            additionalCostType: 'ADDITIONAL_COST',
                            allocationType: costOptions.allocationType,
                            otherCostValues: null,
                            selected: true,
                            cost_source: costOptions.cost_source,
                            additionalCostLinkageId: null,
                            is_calculated: true,
                            rfq_event_item_ids: [],
                            seller_entity_ids: [],
                        };
                    else {
                        UniqueAdditionalCosts[costOptions.costName] = {
                            costName: costOptions.costName,
                            costType: CostTypeEnum.PERCENTAGE,
                            additional_cost_id: costOptions.additional_cost_id,
                            allocationType: costOptions.allocationType,
                            additionalCostType: 'ADDITIONAL_COST',
                            costValue: 0,
                            otherCostValues: null,
                            selected: true,
                            cost_source: costOptions.cost_source,
                            additionalCostLinkageId: null,
                            is_calculated: true,
                            rfq_event_item_ids: [],
                            seller_entity_ids: [],
                        };
                    }
                }
            });
            rfqTemplate.fieldSettings.standardFields[
                TemplateStandardFieldsEnum.TAX
            ].taxesOptions.forEach((costOptions) => {
                if (!UniqueAdditionalCosts[costOptions.costName]) {
                    if (costOptions.costType === CostTypeEnum.ABSOLUTE_VALUE)
                        UniqueAdditionalCosts[costOptions.costName] = {
                            costName: costOptions.costName,
                            costType: CostTypeEnum.ABSOLUTE_VALUE,
                            additional_cost_id: costOptions.additional_cost_id,
                            costValue: 0,
                            additionalCostType: 'TAX',
                            allocationType: costOptions.allocationType,
                            otherCostValues: null,
                            selected: true,
                            cost_source: costOptions.cost_source,
                            additionalCostLinkageId: null,
                            rfq_event_item_ids: [],
                            seller_entity_ids: [],
                        };
                    else {
                        UniqueAdditionalCosts[costOptions.costName] = {
                            costName: costOptions.costName,
                            costType: CostTypeEnum.PERCENTAGE,
                            additional_cost_id: costOptions.additional_cost_id,
                            allocationType: costOptions.allocationType,
                            additionalCostType: 'TAX',
                            costValue: 0,
                            otherCostValues: null,
                            selected: true,
                            cost_source: costOptions.cost_source,
                            additionalCostLinkageId: null,
                            rfq_event_item_ids: [],
                            seller_entity_ids: [],
                        };
                    }
                }
            });
        });
    }

    return UniqueAdditionalCosts;
};
