import { PendingView } from '../Containers/GRPendingListContainer';
import {
    IGRPastListSummary,
    IGRInvoiceListSummary,
} from '../Interfaces/GRInterface';
import { GRDashboardType, IGRDashboardState } from '../Slices/GRDashboardSlice';

export interface IGRDashboardListActions {
    type: string;
    payload: {
        type: GRDashboardType;
        value: IGRInvoiceListSummary[] | IGRPastListSummary[];
    };
}

export interface ITabsCountAction {
    payload: {
        type: GRDashboardType;
        value: number;
    };
}

export interface ITabsChangeAction {
    payload: GRDashboardType;
}
export interface ITabsViewChangeAction {
    payload: PendingView;
}

export const initDashboardListReducer = (
    state: IGRDashboardState,
    action: IGRDashboardListActions
) => {
    switch (action.payload.type) {
        case GRDashboardType.PENDING:
            state.pendingList = action.payload.value as IGRInvoiceListSummary[];
            break;
        case GRDashboardType.PAST:
            state.pastList = action.payload.value as IGRPastListSummary[];
            break;
    }
};

export const setTabCountReducer = (
    state: IGRDashboardState,
    action: ITabsCountAction
) => {
    const tabsCount = state.tabsCount;
    switch (action.payload.type) {
        case GRDashboardType.PENDING:
            tabsCount.pending = action.payload.value;
            break;
        case GRDashboardType.PAST:
            tabsCount.past = action.payload.value;
            break;
    }
};

export const setSelectedTabReducer = (
    state: IGRDashboardState,
    action: ITabsChangeAction
) => {
    state.selectedTab = action.payload;
};
