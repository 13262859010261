import React from 'react';
import { CurrencyDetails } from '../../../Historical/Interfaces/IHistoricalAnalyticsDropdowns.model';
import { FWPopupWithChildren } from '../../../Common/FWPopupWithChildren';
import VendorLNMainPage from '../../../VendorLinkedIn/Pages/VendorLNMainPage';

export interface IVLNContext {
    enterpriseItemUuid: string;
    currencyDetails: CurrencyDetails;
    itemName: string;
    measurementUnitUuid: string;
    measurementUnitName: string;
    sellerEntityUuid: string;
    sellerEntityName: string;
}

export const VLNContext = React.createContext<IVLNContext>({
    enterpriseItemUuid: '',
    currencyDetails: {
        currencyUuid: '',
        currencyCodeAbbreviation: '',
        currencySymbol: '',
        currencyName: '',
    },
    itemName: '',
    measurementUnitUuid: '',
    measurementUnitName: '',
    sellerEntityUuid: '',
    sellerEntityName: '',
});

const VendorLinkedInPopup = ({
    open,
    handleClose,
    sellerEntityName,
    enterpriseItemUuid,
    itemName,
    currencyCodeUuid,
    currencyCodeAbbreviation,
    currencyCodeSymbol,
    measurementUnitName,
    measurementUnitUuid,
    sellerEntityUuid,
}: {
    open: boolean;
    handleClose: () => void;
    sellerEntityName: string;
    enterpriseItemUuid: string;
    itemName: string;
    currencyCodeUuid: string;
    currencyCodeAbbreviation: string;
    currencyCodeSymbol: string;
    measurementUnitUuid: string;
    measurementUnitName: string;
    sellerEntityUuid: string;
}) => {
    // vendor analytics
    return (
        <FWPopupWithChildren
            open={open}
            handleClose={handleClose}
            title={`${sellerEntityName}`}
            size="custom"
            popupWidth={'90%'}
            PaperProps={{
                sx: {
                    height: '100% !important',
                },
            }}
            stickyHeader
            // titleAddOnElement={'currencyFilterElem'}
            // titleAddOnElement={
            //     <>
            //         <div className="flex flex--aic">
            //             <Box color="success.main">
            //                 <i className="bi bi-check-circle-fill" />
            //             </Box>
            //             {/* &nbsp; &nbsp;
            //             <FWTypography color="#FF9500">4.7</FWTypography>
            //             &nbsp;
            //             <Box color="#FF9500">
            //                 <i className="bi bi-star-fill" />
            //             </Box> */}
            //         </div>
            //     </>
            // }
            // PaperProps={{
            //     sx: {
            //         maxWidth: '1366px',
            //     },
            // }}
        >
            {/* <DialogContent sx={{ padding: '0 24px 24px' }}>
        <DynamicAnalyticsVendor
            enterpriseItemUuid={
                props.itemData.enterpriseItemUuid
            }
            itemName={props.itemData.itemName}
            currencyDetails={{
                currencyUuid:
                    props.itemData.currencyCodeUuid,
                currencyCodeAbbreviation:
                    props.itemData
                        .currencyCodeAbbreviation,
                // TODO: Add currency name to the item data
                currencyName: '',
                currencySymbol:
                    props.itemData.currencyCodeSymbol,
            }}
            measurementUnitUuid={
                props.itemData.measurementUnitUuid
            }
            measurementUnitName={
                props.itemData.measurementUnitName
            }
            sellerEntityUuid={
                vendorAnalyticsDetails.sellerEntityUuid
            }
            sellerEntityName={
                vendorAnalyticsDetails.sellerEntityName
            }
        />
    </DialogContent> */}
            <VLNContext.Provider
                value={{
                    enterpriseItemUuid: enterpriseItemUuid,
                    itemName: itemName,
                    currencyDetails: {
                        currencyUuid: currencyCodeUuid,
                        currencyCodeAbbreviation,
                        // TODO: Add currency name to the item data
                        currencyName: '',
                        currencySymbol: currencyCodeSymbol,
                    },
                    measurementUnitUuid,
                    measurementUnitName,
                    sellerEntityUuid,
                    sellerEntityName,
                }}
            >
                <VendorLNMainPage />
            </VLNContext.Provider>
        </FWPopupWithChildren>
    );
};

export default VendorLinkedInPopup;
