import { Box, IconButton } from '@mui/material';
import React from 'react';
import { FWTooltip } from '../../../Common/FWCustomTooltip';
import AddSpecificationPopup from './NewAddSpecificationPopup';
import { IGetAttributeDetails } from '../../../Models/Attributes.model';
import { ASModuleSetting } from '../../../Organizations/Admin/Interfaces/AdminSettingsInterface';
import { useGetAdminSettings } from '../../../Organizations/Admin/Hooks/AdminSettingsHook';
import { useFeatureAccess } from '../../../FeatureAccess/Hooks/FeatureAccessHook';
import { planUpgradeTooltipText } from '../../../FeatureAccess/Utils/planUpgradeTooltipText';

interface IAddAttributeButtonProps {
    item_attributes: IGetAttributeDetails[];
    updateAttribute: (
        attribute_id: string | null,
        attribute_name: string | null,
        attribute_value: string[] | null,
        index_of_edited_attribute: number
    ) => void;
    deleteAttribute: (idx: number) => void;
    openAttributePopup: {
        isOpen: boolean;
        currentAttribute: {
            idx: number;
            id: string | null;
            name: string;
            value: string[];
        } | null;
    };
    setOpenAttributePopup: React.Dispatch<
        React.SetStateAction<{
            isOpen: boolean;
            currentAttribute: {
                idx: number;
                id: string | null;
                name: string;
                value: string[];
            } | null;
        }>
    >;
    entity_id: string;
}

const AddAttributeButton = (props: IAddAttributeButtonProps) => {
    const [customSpecSettings] = useGetAdminSettings(
        props.entity_id,
        ASModuleSetting.EVENT_ALLOW_CUSTOM_SPECS
    );
    const { isFeatureAvailable } = useFeatureAccess();

    return (
        <>
            {customSpecSettings.enabled || true ? (
                <>
                    &nbsp;
                    <FWTooltip
                        title={
                            isFeatureAvailable(
                                'feature-event-customattributes-enabled'
                            )
                                ? 'Add specification'
                                : planUpgradeTooltipText.featureUnavailableText
                        }
                    >
                        <Box component="span">
                            <IconButton
                                disabled={
                                    !isFeatureAvailable(
                                        'feature-event-customattributes-enabled'
                                    )
                                }
                                size="small"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    props.setOpenAttributePopup({
                                        isOpen: true,
                                        currentAttribute: null,
                                    });
                                }}
                                sx={{
                                    color: 'primary.main',
                                    fontSize: '1.4rem',
                                }}
                                id="CreateEvent_addSpecificationsIcon"
                            >
                                <i className="bi bi-plus-circle"></i>
                            </IconButton>
                        </Box>
                    </FWTooltip>
                </>
            ) : (
                <span style={{ width: '1rem' }}></span>
            )}
            <AddSpecificationPopup
                currentAttribute={props.openAttributePopup.currentAttribute}
                updateAttribute={(...args) => {
                    props.updateAttribute(...args);
                    props.setOpenAttributePopup({
                        ...props.openAttributePopup,
                        isOpen: false,
                    });
                }}
                open={props.openAttributePopup.isOpen}
                setOpen={props.setOpenAttributePopup}
                all_attributes={props.item_attributes}
                deleteAttribute={props.deleteAttribute} // recom_attributes={recom_attributes}
            />
        </>
    );
};

export default AddAttributeButton;
