import { toast } from 'react-toastify';
import { IPageDetails } from '../../../../../Models/RFQ.model';
import { baseApiSlice } from '../../../../../Redux/Slices/BaseApiSlice';
import { get, post, put } from '../../../../../Utils/api';
import { ItemDashBoardResponse } from '../../../Services/ItemDirectoryService';
import { BOMListCard, IBOMDetails } from '../Interface/BOMInterface';
import { BOMCreatePayload, BOMUpdatePayload } from './payloadInterface';
import { IBOMRecivedDetails, IBom } from './responseInterfaces';

const convertBOMtoCreatePayload = (
    bomDetails: IBOMDetails
): BOMCreatePayload => {
    const createPayload: BOMCreatePayload = {
        bom_name: bomDetails.finished_good_name,
        bom_code: bomDetails.bom_code,
        finished_good_id: bomDetails.finished_good_id,
        quantity: bomDetails.quantity,
        measurement_unit_id: bomDetails.measurement_unit_id,
        currency_code_id: bomDetails.currency,
        locations: bomDetails.location,
        is_primary: bomDetails.is_primary,
        entity_ids: bomDetails.entities,
    };
    return createPayload;
};

const convertBOMtoUpdatePayload = (
    bomDetails: IBOMDetails
): BOMUpdatePayload => {
    const total = bomDetails.bom_items.reduce((acc, item) => {
        return acc + item.quantity * item.cost_of_unit;
    }, 0);
    const updatePayload: BOMUpdatePayload = {
        bom_status: bomDetails.bom_status,
        is_subcontract: bomDetails.is_subcontract,
        notes: bomDetails.notes,
        total: total,
        locations: bomDetails.location,
        is_primary: bomDetails.is_primary,
        bom_items: bomDetails.bom_items.map((item) => {
            return {
                raw_material_id: !item.sub_assembly
                    ? item.raw_material_id
                    : null,
                quantity: item.quantity,
                measurement_unit_id: item.measurement_unit_id,
                cost_per_unit: item.cost_of_unit,
                sub_bom_id: item.sub_assembly ? item.raw_material_id : null,
                subtotal: item.quantity * item.cost_of_unit,
                description: item.description,
                alternates: item.alternate_items.map((alt) => {
                    return {
                        raw_material_id: !alt.sub_assembly
                            ? alt.raw_material_id
                            : undefined,
                        quantity: alt.quantity,
                        measurement_unit_id: alt.measurement_unit_id,
                        cost_per_unit: alt.cost_of_unit,
                        sub_bom_id: alt.sub_assembly
                            ? alt.raw_material_id
                            : undefined,
                        subtotal: alt.quantity * alt.cost_of_unit,
                        description: alt.description,
                    };
                }),
            };
        }),
        entity_ids: bomDetails.entities,
    };
    return updatePayload;
};

//Fetch All BOMS for an Item API Service
export const fetchAllBOM = async (
    finished_good_id: string
): Promise<BOMListCard[]> => {
    try {
        const response = await get<BOMListCard[]>(
            '/organization/bom/admin/?finished_good_id=' + finished_good_id
        );
        return response.data;
    } catch (err) {
        toast.error('Error in fetching BOM');
    }
    return [];
};

export const { useFetchAllBOMQuery } = baseApiSlice.injectEndpoints({
    endpoints: (build) => ({
        fetchAllBOM: build.query<BOMListCard[], { finished_good_id: string }>({
            query: ({ finished_good_id }) =>
                '/organization/bom/admin/?finished_good_id=' + finished_good_id,
        }),
    }),
});

interface IFetchItemBOMListPayload {
    dashboard_view: string;
    search_text: string;
    tab: string;
    query_data?: {
        entity_id: string;
    };
    sort_fields: {
        field: string;
        ascending: boolean;
    }[];
    items_per_page: number;
    page_number: number;
}

export interface IDashBoardBOMDetails {
    item_id: string;
    item_name: string;
    item_code: string;
    activeBomNo: number;
    activeBoms: IDashboardBomItem[];
    totalBoms: number;
    allBoms: IDashboardBomItem[];
    entityCount: number;
    lastModified: string;
    bom_template: {
        template_id: string;
    };
}

export interface IDashboardBomItem {
    bom_code: any;
    bom_name: any;
    bom_status: any;
}

export const fetchBomDashboard = async ({
    tab,
    searchText,
    sortFields,
    itemsPerPage,
    pageNumber,
}: {
    tab: string;
    searchText: string;
    sortFields: {
        field: string;
        ascending: boolean;
    }[];
    itemsPerPage: number;
    pageNumber: number;
}): Promise<{
    bomList: IDashBoardBOMDetails[];
    pageDetails: IPageDetails;
}> => {
    const postData: IFetchItemBOMListPayload = {
        dashboard_view: 'enterprise_bom',
        tab: tab ?? 'all',
        search_text:
            searchText?.length && searchText?.length > 0 ? searchText : '',
        sort_fields: sortFields ?? [],
        items_per_page: itemsPerPage,
        page_number: pageNumber,
    };

    const resp = await post<any, any>(`/dashboard/`, postData);
    const bomList = resp.data.data;

    const requiredBomDashBoardData = bomList.map((bom: any) => {
        const allBoms = bom.boms.map((item: IBom) => {
            const bomItem = {
                bom_code: item.bom_code,
                bom_name: item.bom_name,
                bom_status: item.bom_status,
            };
            return bomItem;
        });
        const activeBoms = allBoms.filter((item: any) => {
            return item.bom_status === 'ACTIVE';
        });
        //
        const bomSummarized: IDashBoardBOMDetails = {
            item_id: bom.enterprise_item_id,
            item_name: bom.name,
            item_code: bom.code,
            activeBomNo: activeBoms.length,
            activeBoms: activeBoms,
            totalBoms: allBoms.length,
            allBoms: allBoms,
            entityCount: 0,
            lastModified: bom.modified_datetime,
            bom_template: {
                template_id: bom.bom_template?.template_id ?? '',
            },
        };
        return bomSummarized;
    });

    return {
        bomList: requiredBomDashBoardData,
        pageDetails: resp.data.metadata,
    };
};

export const fetchEntityBomDashboard = async ({
    tab,
    searchText,
    sortFields,
    entity_id,
    itemsPerPage,
    pageNumber,
}: {
    tab: string;
    searchText: string;
    sortFields: {
        field: string;
        ascending: boolean;
    }[];
    entity_id: string;
    itemsPerPage: number;
    pageNumber: number;
}): Promise<{
    bomList: IDashBoardBOMDetails[];
    pageDetails: IPageDetails;
}> => {
    const postData: IFetchItemBOMListPayload = {
        dashboard_view: 'entity_bom',
        tab: tab ?? 'all',
        search_text:
            searchText?.length && searchText?.length > 0 ? searchText : '',
        query_data: {
            entity_id: entity_id,
        },
        sort_fields: sortFields ?? [],
        items_per_page: itemsPerPage,
        page_number: pageNumber,
    };

    const resp = await post<any, any>(`/dashboard/`, postData);
    const bomList = resp.data.data;

    const requiredBomDashBoardData = bomList.map((bom: any) => {
        const allBoms = bom.boms.map((item: IBom) => {
            const bomItem = {
                bom_code: item.bom_code,
                bom_name: item.bom_name,
                bom_status: item.bom_status,
            };
            return bomItem;
        });
        const activeBoms = allBoms.filter((item: any) => {
            return item.bom_status === 'ACTIVE';
        });
        //
        const bomSummarized: IDashBoardBOMDetails = {
            item_id: bom.enterprise_item_id,
            item_name: bom.name,
            item_code: bom.code,
            activeBomNo: activeBoms.length,
            activeBoms: activeBoms,
            totalBoms: allBoms.length,
            allBoms: allBoms,
            entityCount: 0,
            lastModified: bom.modified_datetime,
            bom_template: bom.bom_template?.template_id ?? null,
        };
        return bomSummarized;
    });

    return {
        bomList: requiredBomDashBoardData,
        pageDetails: resp.data.metadata,
    };
};

export const fetchBOMDetails = async (
    bom_id: string
): Promise<IBOMDetails | null> => {
    try {
        const response = await get<IBOMRecivedDetails>(
            `/organization/bom/${bom_id}/admin/`
        );
        const bomDetails: IBOMDetails = {
            bom_id: response.data.enterprise_bom_id,
            bom_code: response.data.bom_code,
            item_code: response.data.enterprise_item.code,
            finished_good_id: response.data.enterprise_item.enterprise_item_id,
            finished_good_name: response.data.enterprise_item.name,
            quantity: +response.data.quantity,
            measurement_unit_id: response.data.measurement_unit,
            currency: response.data.currency,
            location: response.data.locations,
            is_primary: response.data.is_primary,
            bom_items: response.data.bom_items.map((item) => {
                return {
                    raw_material_id: item.raw_material_item
                        ? item.raw_material_item.enterprise_item_id
                        : item.sub_bom,
                    raw_material_code: item.raw_material_item?.code,
                    raw_material_name: item.raw_material_item?.name,
                    quantity: +item.quantity,
                    measurement_unit_id: item.measurement_unit,
                    measurement_unit_name: item.measurement_unit,
                    cost_of_unit: +item.cost_per_unit,
                    description: item.description,
                    sub_assembly: item.sub_bom ? true : false,
                    measurement_units:
                        item.raw_material_item?.measurement_units
                            .item_measurement_units,
                    bom_items: item.sub_bom,
                    alternate_items: [],
                };
            }),
            entities: response.data.entities.map((entity) => {
                return entity.buyer_entity_id;
            }),
            notes: response.data.notes,
            is_subcontract: response.data.is_subcontract,
            measurement_units:
                response.data.enterprise_item.measurement_units
                    .item_measurement_units,
            bom_status: response.data.bom_status,
        };
        return bomDetails;
    } catch (err) {
        toast.error('Error in fetching BOM Details');
    }
    return null;
};

//Create New BOM API Service
export const createNewBOM = async (bomDetails: IBOMDetails) => {
    const payload = convertBOMtoCreatePayload(bomDetails);
    try {
        const response = await post<
            any,
            {
                enterprise_bom_id: string;
            }
        >('/organization/bom/admin/create/', payload);
        toast.success('BOM Created Successfully');
        return response.data.enterprise_bom_id;
    } catch (err) {
        toast.error('Error in creating BOM');
    }
};

//Update BOM API Service
export const updateBOM = async (bomDetails: IBOMDetails) => {
    const payload = convertBOMtoUpdatePayload(bomDetails);

    try {
        await put<any, void>(
            `/organization/bom/admin/${bomDetails.bom_id}/update/`,
            payload
        );
        toast.success('BOM Updated Successfully');
    } catch (err) {
        toast.error('Error in updating BOM');
    }
};

// dashboard_view: 'enterprise_item'
// tab: 'finished_goods'

export const getFinishedGoods = async (pageNo = 1, searchText = '') => {
    try {
        const response: any = await post<any, any>(`/dashboard/`, {
            dashboard_view: 'enterprise_item',
            tab: 'finished_good',
            sort_fields: [],
            search_text: searchText,
            items_per_page: 10,
            page_number: pageNo,
        });
        const data: ItemDashBoardResponse = response.data;

        return data;
    } catch (err) {
        throw err;
    }
};
