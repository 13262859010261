import { Box } from '@mui/material';
import { FWTooltip } from '../../Common/FWCustomTooltip';
import { FWTypography } from '../../Common/FWTypograhy';

const POStatusChip = ({
    status,
    hoverText = '',
}: {
    status: string;
    hoverText?: string;
}) => {
    const StatusBlock = (param_status: string, param_color: string) => {
        return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <FWTypography sx={{ color: param_color }}>
                    {param_status}
                </FWTypography>
                &nbsp;
                {hoverText.trim.length > 0 && (
                    <FWTooltip title={hoverText}>
                        <Box color={param_color}>
                            <i className="bi bi-info-circle" />
                        </Box>
                    </FWTooltip>
                )}
            </div>
        );
    };

    switch (status) {
        case 'ONGOING':
            return StatusBlock('PO ongoing', 'success.main');
        case 'PAUSED':
            return StatusBlock('Paused', 'warning.main');
        case 'APPROVAL_PENDING':
            return StatusBlock('Approval pending', 'warning.main');
        case 'AWARD_STAGE':
            return StatusBlock('Award stage', 'warning.main');
        case 'AWARD':
            return StatusBlock('Award', 'warning.main');
        case 'REWORK':
            return StatusBlock('Changes requested', 'warning.main');
        case 'COMPLETED':
            return StatusBlock('PO completed', 'success.main');
        case 'TERMINATED':
            return StatusBlock('PO terminated', 'error.main');
        case 'REJECTED':
            return StatusBlock('PO rejected', 'error.main');
        case 'DRAFT':
            return StatusBlock('Draft', 'text.primary');
        case 'REVISED':
            return StatusBlock('PO revised', 'primary.main');
        case 'ISSUED':
            return StatusBlock('PO issued', 'primary.main');
        case 'RESCINDED':
            return StatusBlock('Rescinded', 'error.main');
        case 'DECLINED':
            return StatusBlock('Declined', 'error.main');
        case 'ON_HOLD':
            return StatusBlock('On hold', 'warning.main');
        case 'TERMINATION_REQUESTED':
            return StatusBlock('Termination requested', 'error.main');
        default:
            return (
                <FWTypography sx={{ color: 'text.primary' }}>
                    {status}
                </FWTypography>
            );
    }
};

export default POStatusChip;
