import { get } from '../../Utils/api';
import { eQuotaNames, TQuota } from '../Interfaces/FeaturesInterface';

export const fetchQuota = async (): Promise<TQuota> => {
    const response = await get<any>(`/organization/subscription/entitlements/`);
    const quotaData: TQuota = {};
    Object.keys(response.data).forEach((keyName) => {
        if (Object.values(eQuotaNames).includes(keyName)) {
            // quotaData[keyName as TQuotaNames] = response.data[keyName];
            quotaData['feature-po-events-count'] =
                response.data['feature-po-events-count'];
            quotaData['feature-po-direct-count'] =
                response.data['feature-po-direct-count'];
            quotaData['feature-po-total-count'] =
                response.data['feature-po-total-count'];
            quotaData['feature-gr-create-count'] =
                response.data['feature-gr-create-count'];
            quotaData['feature-qc-create-count'] =
                response.data['feature-qc-create-count'];
            quotaData['feature-entity-creation-count'] =
                response.data['feature-entity-creation-count'];
        }
    });
    // const quotaData: TQuota = {
    //     'feature-gr-create-count': {
    //         max: 0,
    //         used: 0,
    //         remaining: 10,
    //     },
    //     'feature-qc-create-count': {
    //         max: 0,
    //         used: 0,
    //         remaining: 10,
    //     },
    //     'feature-payment-create-count': {
    //         max: 0,
    //         used: 0,
    //         remaining: 10,
    //     },
    //     'feature-event-create-count': {
    //         max: 0,
    //         used: 0,
    //         remaining: 10,
    //     },
    //     'feature-event-cloning-count': {
    //         max: 0,
    //         used: 0,
    //         remaining: 10,
    //     },
    //     'feature-po-total-count': {
    //         max: 0,
    //         used: 0,
    //         remaining: 10,
    //     },
    //     'feature-po-direct-count': {
    //         max: 0,
    //         used: 0,
    //         remaining: 10,
    //     },
    // };
    return quotaData;
};
