import { cloneDeep, debounce } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { capitaliseFirstLetter } from '../../../BuyerTemplates/Component/TemplateSuggestedFieldsSection';
import { saveNewMeasurementUnit } from '../../../Organizations/Admin/Services/ItemDirectoryService';
import { IMeasurementUnit } from '../../Interfaces/UOMInterface';
import {
    checkMeasurementUnitNameExistsApi,
    updateMeasurementUnitApi,
    useGetMeasurementUnitListQuery,
} from '../../Services/UOM.service';

export type TUOMCategories =
    | 'WEIGHT'
    | 'UNITS'
    | 'VOLUME'
    | 'AREA'
    | 'LENGTH'
    | 'OTHER';
export type TUOMTypes = 'INTEGER' | 'DECIMAL';

export type newPopopValueType =
    | {
          key: 'name';
          value: string;
      }
    | {
          key: 'symbol';
          value: string;
      }
    | {
          key: 'synonyms';
          value: string[];
      }
    | {
          key: 'category';
          value: TUOMCategories;
      }
    | {
          key: 'type';
          value: TUOMTypes;
      }
    | {
          key: 'multiplier';
          value: string;
      };

export interface IMeasurementUnitData {
    name: string;
    symbol: string;
    synonyms: {
        editable: string[];
        nonEditable: string[];
    };
    category: TUOMCategories;
    type: TUOMTypes;
    multiplier: string;
}

export const useUOMMeasurementViewPopup = (initialData?: {
    name: string;
    symbol: string;
    synonyms: {
        editable: string[];
        nonEditable: string[];
    };
    category: TUOMCategories;
    type: TUOMTypes;
    measurementUnitId: string;
    multiplier: string;
}) => {
    const [measurementData, setMeasurementData] = useState<{
        name: string;
        symbol: string;
        synonyms: {
            editable: string[];
            nonEditable: string[];
        };
        category: TUOMCategories;
        type: TUOMTypes;
        multiplier: string;
    }>({
        name: '',
        symbol: '',
        synonyms: {
            editable: [],
            nonEditable: [],
        },
        category: 'WEIGHT',
        type: 'DECIMAL',
        multiplier: '',
    });

    const [measurementUnits, setMeasurementUnits] = useState<
        IMeasurementUnit[]
    >([]);

    const { data } = useGetMeasurementUnitListQuery({});

    useEffect(() => {
        if (data) {
            setMeasurementUnits(data);
        }
    }, [data]);

    // const fetchMeasurementUnitsList = useCallback(async () => {
    //     try {
    //         const measurementUnits = await fetchMeasurementUnitsListApi();
    //         setMeasurementUnits(measurementUnits);
    //     } catch (e) {
    //
    //     }
    // }, []);

    // useEffect(() => {
    //     fetchMeasurementUnitsList();
    // }, [fetchMeasurementUnitsList]);

    const [errors, setErrors] = useState<{
        name: string;
        symbol: string;
        synonyms: string;
        multiplier: string;
    }>({
        name: '',
        symbol: '',
        synonyms: '',
        multiplier: '',
    });

    useEffect(() => {
        if (initialData) {
            setMeasurementData(cloneDeep(initialData));
        }
    }, [initialData]);

    const [isValid, setIsValid] = useState<boolean>(false);
    const [nameAlreadyExists, setNameAlreadyExists] = useState<{
        name: boolean;
        symbol: boolean;
        synonym: boolean;
    }>({
        name: false,
        symbol: false,
        synonym: false,
    });
    const conversionAlreadyExistsFunc = useMemo(() => {
        return debounce(
            (value: string, field: 'name' | 'symbol' | 'synonym') => {
                const getConversionAlreadyExists = async () => {
                    try {
                        const resp = await checkMeasurementUnitNameExistsApi(
                            value
                        );
                        setNameAlreadyExists((prev) => ({
                            ...prev,
                            [field]: resp,
                        }));
                    } catch (error) {}
                };
                getConversionAlreadyExists();
            },
            500
        );
    }, []);

    useEffect(() => {
        if (nameAlreadyExists.name) {
            setErrors((prev) => ({
                ...prev,
                name: 'Unit already exists',
            }));
        } else {
            setErrors((prev) => ({
                ...prev,
                name: prev.name === 'Unit already exists' ? '' : prev.name,
            }));
        }

        if (nameAlreadyExists.symbol) {
            setErrors((prev) => ({
                ...prev,
                symbol: 'Abbreviation already exists',
            }));
        } else {
            setErrors((prev) => ({
                ...prev,
                symbol:
                    prev.symbol === 'Abbreviation already exists'
                        ? ''
                        : prev.symbol,
            }));
        }

        if (nameAlreadyExists.synonym) {
            setErrors((prev) => ({
                ...prev,
                synonyms: 'Synonym already exists',
            }));
        } else {
            setErrors((prev) => ({
                ...prev,
                synonyms:
                    prev.synonyms === 'Synonym already exists'
                        ? ''
                        : prev.synonyms,
            }));
        }
    }, [nameAlreadyExists]);

    const [fieldsTouched, setFieldsTouched] = useState<{
        name: boolean;
        symbol: boolean;
        synonyms: boolean;
        multiplier: boolean;
    }>({
        multiplier: false,
        name: false,
        symbol: false,
        synonyms: false,
    });

    const validateName = useCallback(
        (
            updatedData: IMeasurementUnitData,
            updatedFieldsTouched: {
                name: boolean;
                symbol: boolean;
                synonyms: boolean;
                multiplier: boolean;
            }
        ) => {
            if (updatedFieldsTouched.name) {
                if (updatedData.name?.trim()?.length === 0) {
                    return 'Name is required';
                } else if (updatedData.name?.trim()?.length > 100) {
                    return 'Cannot exceed 100 characters';
                } else if (
                    Boolean(updatedData.name) &&
                    Boolean(updatedData.symbol) &&
                    updatedData.name?.trim()?.toLowerCase() ===
                        updatedData.symbol?.trim()?.toLowerCase() &&
                    (updatedData.synonyms?.editable
                        .map((syn) => syn.toLowerCase())
                        ?.includes(updatedData.name?.trim()?.toLowerCase()) ||
                        updatedData.synonyms?.nonEditable
                            ?.map((syn) => syn.toLowerCase())
                            ?.includes(updatedData.name?.trim()?.toLowerCase()))
                ) {
                    return 'Name, synonyms and abbreviation cannot be same';
                } else if (
                    Boolean(updatedData.name) &&
                    (updatedData.synonyms?.editable
                        .map((syn) => syn.toLowerCase())
                        ?.includes(updatedData.name?.trim()?.toLowerCase()) ||
                        updatedData.synonyms?.nonEditable
                            ?.map((syn) => syn.toLowerCase())
                            ?.includes(updatedData.name?.trim()?.toLowerCase()))
                ) {
                    return 'Name and synonyms cannot be same';
                } else if (
                    Boolean(updatedData.name) &&
                    Boolean(updatedData.symbol) &&
                    updatedData.name?.trim()?.toLowerCase() ===
                        updatedData.symbol?.trim()?.toLowerCase()
                ) {
                    return 'Name and abbreviation cannot be same';
                } else {
                    conversionAlreadyExistsFunc(updatedData.name, 'name');

                    return '';
                }
            } else {
                return '';
            }
        },
        [conversionAlreadyExistsFunc]
    );

    const validateSymbol = useCallback(
        (
            updatedData: IMeasurementUnitData,
            updatedFieldsTouched: {
                name: boolean;
                symbol: boolean;
                synonyms: boolean;
                multiplier: boolean;
            }
        ) => {
            if (updatedFieldsTouched.symbol) {
                if (updatedData.symbol?.trim()?.length === 0) {
                    return 'Abbreviation is required';
                } else if (updatedData.symbol?.trim()?.length > 100) {
                    return 'Cannot exceed 100 characters';
                } else if (
                    Boolean(updatedData.name) &&
                    Boolean(updatedData.symbol) &&
                    updatedData.name?.trim()?.toLowerCase() ===
                        updatedData.symbol?.trim()?.toLowerCase() &&
                    (updatedData.synonyms?.editable
                        .map((syn) => syn.toLowerCase())
                        ?.includes(updatedData.name?.trim()?.toLowerCase()) ||
                        updatedData.synonyms?.nonEditable
                            ?.map((syn) => syn.toLowerCase())
                            ?.includes(updatedData.name?.trim()?.toLowerCase()))
                ) {
                    return 'Name, synonyms and abbreviation cannot be same';
                } else if (
                    Boolean(updatedData.symbol) &&
                    (updatedData.synonyms?.editable
                        .map((syn) => syn.toLowerCase())
                        ?.includes(updatedData.symbol?.trim()?.toLowerCase()) ||
                        updatedData.synonyms?.nonEditable
                            ?.map((syn) => syn.toLowerCase())
                            ?.includes(
                                updatedData.symbol?.trim()?.toLowerCase()
                            ))
                ) {
                    return 'Synonyms and abbreviation cannot be same';
                } else if (
                    Boolean(updatedData.name) &&
                    Boolean(updatedData.symbol) &&
                    updatedData.name?.trim()?.toLowerCase() ===
                        updatedData.symbol?.trim()?.toLowerCase()
                ) {
                    return 'Name and abbreviation cannot be same';
                } else {
                    conversionAlreadyExistsFunc(updatedData.symbol, 'symbol');

                    return '';
                }
            } else {
                return '';
            }
        },
        [conversionAlreadyExistsFunc]
    );

    const validateSynonym = useCallback(
        (
            updatedData: IMeasurementUnitData,
            updatedFieldsTouched: {
                name: boolean;
                symbol: boolean;
                synonyms: boolean;
                multiplier: boolean;
            }
        ) => {
            if (
                updatedData.synonyms?.editable?.some((syn) => syn?.length > 100)
            ) {
                return 'Cannot exceed 100 characters';
            } else if (
                Boolean(updatedData.name) &&
                Boolean(updatedData.symbol) &&
                updatedData.name?.trim()?.toLowerCase() ===
                    updatedData.symbol?.trim()?.toLowerCase() &&
                (updatedData.synonyms?.editable
                    .map((syn) => syn.toLowerCase())
                    ?.includes(updatedData.name?.trim()?.toLowerCase()) ||
                    updatedData.synonyms?.nonEditable
                        ?.map((syn) => syn.toLowerCase())
                        ?.includes(updatedData.name?.trim()?.toLowerCase()))
            ) {
                return 'Name, synonyms and abbreviation cannot be same';
            } else if (
                Boolean(updatedData.symbol) &&
                (updatedData.synonyms?.editable
                    .map((syn) => syn.toLowerCase())
                    ?.includes(updatedData.symbol?.trim()?.toLowerCase()) ||
                    updatedData.synonyms?.nonEditable
                        ?.map((syn) => syn.toLowerCase())
                        ?.includes(updatedData.symbol?.trim()?.toLowerCase()))
            ) {
                return 'Synonyms and abbreviation cannot be same';
            } else if (
                Boolean(updatedData.name) &&
                (updatedData.synonyms?.editable
                    .map((syn) => syn.toLowerCase())
                    ?.includes(updatedData.name?.trim()?.toLowerCase()) ||
                    updatedData.synonyms?.nonEditable
                        ?.map((syn) => syn.toLowerCase())
                        ?.includes(updatedData.name?.trim()?.toLowerCase()))
            ) {
                return 'Name and synonyms cannot be same';
            } else {
                const newSynonym =
                    updatedData?.synonyms?.editable[
                        updatedData?.synonyms?.editable.length - 1
                    ];
                if (newSynonym)
                    conversionAlreadyExistsFunc(newSynonym, 'synonym');

                return '';
            }
        },
        [conversionAlreadyExistsFunc]
    );

    const updateErrors = useCallback(
        (
            updatedData: IMeasurementUnitData,
            updatedFieldsTouched: {
                name: boolean;
                symbol: boolean;
                synonyms: boolean;
                multiplier: boolean;
            },
            lastUpdated: 'NAME' | 'SYMBOL' | 'SYNONYM'
        ) => {
            // NAME ONLY ERRORS

            const nameError = validateName(updatedData, updatedFieldsTouched);

            const symbolError = validateSymbol(
                updatedData,
                updatedFieldsTouched
            );

            const synonymError = validateSynonym(
                updatedData,
                updatedFieldsTouched
            );

            setErrors((prev) => ({
                ...prev,
                name: nameError,
                symbol: symbolError,
                synonyms: synonymError,
            }));

            // const updatedSynonyms = {
            //     editableSynonyms: updatedData.synonyms.editable?.map((syn) =>
            //         syn.toLowerCase()
            //     ),
            //     nonEditableSynonyms: updatedData.synonyms.nonEditable?.map(
            //         (syn) => syn.toLowerCase()
            //     ),
            // };

            // if (updatedFieldsTouched.name) {
            //     if (updatedData.name?.trim()?.length === 0) {
            //
            //         setErrors((prev) => ({
            //             ...prev,
            //             name: 'Name is required',
            //         }));
            //         return;
            //     } else if (updatedData.name?.trim()?.length > 100) {
            //
            //         setErrors((prev) => ({
            //             ...prev,
            //             name: 'Cannot exceed 100 characters',
            //         }));
            //         return;
            //     }
            // }

            // if (updatedFieldsTouched.symbol) {
            //     if (updatedData.symbol?.trim()?.length === 0) {
            //
            //         setErrors((prev) => ({
            //             ...prev,
            //             symbol: 'Abbreviation is required',
            //         }));
            //         return;
            //     } else if (updatedData.symbol?.trim()?.length > 100) {
            //
            //         setErrors((prev) => ({
            //             ...prev,
            //             symbol: 'Cannot exceed 100 characters',
            //         }));
            //         return;
            //     }
            // }

            // if (updatedFieldsTouched.synonyms) {
            //     if (
            //         updatedData.synonyms?.editable?.some(
            //             (syn) => syn?.length > 100
            //         )
            //     ) {
            //
            //         setErrors((prev) => ({
            //             ...prev,
            //             synonyms: 'Cannot exceed 100 characters',
            //         }));
            //         return;
            //     } else if (
            //         updatedData?.synonyms?.editable?.length === 0 &&
            //         updatedData?.synonyms?.nonEditable?.length === 0
            //     ) {
            //         setErrors((prev) => ({
            //             ...prev,
            //             synonyms: '',
            //         }));
            //         return;
            //     }
            // }

            // if (
            //     Boolean(updatedData.name) &&
            //     Boolean(updatedData.symbol) &&
            //     updatedData.name?.trim()?.toLowerCase() ===
            //         updatedData.symbol?.trim()?.toLowerCase() &&
            //     (updatedSynonyms?.editableSynonyms?.includes(
            //         updatedData.name?.trim()?.toLowerCase()
            //     ) ||
            //         updatedSynonyms?.nonEditableSynonyms?.includes(
            //             updatedData.name?.trim()?.toLowerCase()
            //         ))
            // ) {
            //
            //     setErrors((prev) => ({
            //         ...prev,
            //         name: 'Name, synonyms and abbreviation cannot be same',
            //         symbol: 'Name, synonyms and abbreviation cannot be same',
            //         synonyms: 'Name, synonyms and abbreviation cannot be same',
            //     }));
            //     return;
            // } else if (
            //     Boolean(updatedData.name) &&
            //     Boolean(updatedData.symbol) &&
            //     updatedData.name?.trim()?.toLowerCase() ===
            //         updatedData.symbol?.trim()?.toLowerCase()
            // ) {
            //
            //     setErrors((prev) => ({
            //         ...prev,
            //         name: 'Name and abbreviation cannot be same',
            //         symbol: 'Name and abbreviation cannot be same',
            //         synonyms:
            //             prev.synonyms ===
            //             'Name, synonyms and abbreviation cannot be same'
            //                 ? ''
            //                 : prev.synonyms,
            //     }));
            //     return;
            // } else if (
            //     Boolean(updatedData.name) &&
            //     (updatedSynonyms?.editableSynonyms?.includes(
            //         updatedData.name?.trim()?.toLowerCase()
            //     ) ||
            //         updatedSynonyms?.nonEditableSynonyms?.includes(
            //             updatedData.name?.trim()?.toLowerCase()
            //         ))
            // ) {
            //
            //     setErrors((prev) => ({
            //         ...prev,
            //         name: 'Name and synonyms cannot be same',
            //         synonyms: 'Name and synonyms cannot be same',
            //         symbol:
            //             prev.symbol ===
            //                 'Name, synonyms and abbreviation cannot be same' ||
            //             prev.symbol === 'Name and abbreviation cannot be same'
            //                 ? ''
            //                 : prev.symbol,
            //     }));
            //     return;
            // } else if (
            //     Boolean(updatedData.symbol) &&
            //     (updatedSynonyms?.editableSynonyms?.includes(
            //         updatedData.symbol?.trim()?.toLowerCase()
            //     ) ||
            //         updatedSynonyms?.nonEditableSynonyms?.includes(
            //             updatedData.symbol?.trim()?.toLowerCase()
            //         ))
            // ) {
            //
            //     setErrors((prev) => ({
            //         ...prev,
            //         symbol: 'Synonyms and abbreviation cannot be same',
            //         synonyms: 'Synonyms and abbreviation cannot be same',
            //         name:
            //             prev.name ===
            //                 'Name, synonyms and abbreviation cannot be same' ||
            //             prev.name === 'Name and synonyms cannot be same'
            //                 ? ''
            //                 : prev.name,
            //     }));
            //     return;
            // } else {
            //     if (Boolean(updatedData.name))
            //         conversionAlreadyExistsFunc(updatedData.name, 'name');

            //     if (Boolean(updatedData.symbol))
            //         conversionAlreadyExistsFunc(updatedData.symbol, 'symbol');
            //     if (
            //         updatedData?.synonyms?.editable.length >
            //         measurementData.synonyms?.editable.length
            //     ) {
            //         const newSynonym =
            //             updatedData?.synonyms?.editable[
            //                 updatedData?.synonyms?.editable.length - 1
            //             ];
            //         if (newSynonym)
            //             conversionAlreadyExistsFunc(newSynonym, 'synonym');
            //     }

            //     if (
            //         errors.name === 'Name and synonyms cannot be same' &&
            //         Boolean(updatedData.name)
            //     ) {
            //         conversionAlreadyExistsFunc(updatedData.name, 'name');
            //     }
            //
            //     setErrors((prev) => ({
            //         ...prev,
            //         name: '',
            //         symbol: '',
            //         synonyms: '',
            //     }));
            //     return;
            // }
        },
        [validateName, validateSymbol, validateSynonym]
    );

    useEffect(() => {
        for (const errorKey in errors) {
            if (
                errors[
                    errorKey as keyof {
                        name: string;
                        symbol: string;
                        synonyms: string;
                    }
                ].length > 0
            ) {
                setIsValid(false);
                return;
            }
        }

        if (
            measurementData.name.trim().length === 0 ||
            measurementData.symbol.trim().length === 0
        ) {
            setIsValid(false);
            return;
        }

        setIsValid(true);
    }, [errors, measurementData.name, measurementData.symbol]);

    const handleNameChange = (name: string) => {
        let newMeasurementData = cloneDeep(measurementData);

        let newFieldsTouched = cloneDeep(fieldsTouched);

        newMeasurementData = {
            ...newMeasurementData,
            name: capitaliseFirstLetter(name?.toLowerCase()),
        };

        newFieldsTouched = { ...newFieldsTouched, name: true };

        setFieldsTouched(newFieldsTouched);

        setMeasurementData(newMeasurementData);

        updateErrors(newMeasurementData, newFieldsTouched, 'NAME');
    };

    const handleAbbreviationChange = (abbreviation: string) => {
        let newMeasurementData = cloneDeep(measurementData);

        let newFieldsTouched = cloneDeep(fieldsTouched);

        newMeasurementData = {
            ...newMeasurementData,
            symbol: abbreviation,
        };

        newFieldsTouched = { ...newFieldsTouched, symbol: true };
        setMeasurementData(newMeasurementData);

        setFieldsTouched(newFieldsTouched);

        updateErrors(newMeasurementData, newFieldsTouched, 'SYMBOL');
    };

    const handleBaseQuantityChange = (baseQuantity: number) => {
        setMeasurementData((prev) => ({
            ...prev,
            baseQuantity,
        }));
        if (baseQuantity <= 0) {
            setErrors((prev) => ({
                ...prev,
                baseQuantity: 'Base quantity is required',
            }));
        } else {
            setErrors((prev) => ({
                ...prev,
                baseQuantity: '',
            }));
        }
    };

    const handleSynonymsChange = (synonyms: string[]) => {
        let newMeasurementData = cloneDeep(measurementData);

        let newFieldsTouched = cloneDeep(fieldsTouched);

        newMeasurementData = {
            ...newMeasurementData,
            synonyms: {
                nonEditable: measurementData.synonyms.nonEditable,
                editable: synonyms?.filter(
                    (syn) =>
                        !measurementData.synonyms?.nonEditable?.includes(syn)
                ),
            },
        };

        newFieldsTouched = { ...newFieldsTouched, synonyms: true };

        setFieldsTouched(newFieldsTouched);

        setMeasurementData(newMeasurementData);

        updateErrors(newMeasurementData, newFieldsTouched, 'SYNONYM');
    };

    const handleCategoryChange = (category: TUOMCategories) => {
        setMeasurementData((prev) => ({
            ...prev,
            category,
            multiplier: category === 'OTHER' ? '' : prev.multiplier,
        }));
    };

    const handleTypeChange = (type: TUOMTypes) => {
        setMeasurementData((prev) => ({
            ...prev,
            type,
        }));
    };

    const handleMultiplierChange = (multiplier: string) => {
        setMeasurementData((prev) => ({
            ...prev,
            multiplier,
        }));

        if (+multiplier <= 0) {
            setErrors((prev) => ({
                ...prev,
                multiplier: 'Conversion rate should be greater than 0',
            }));
        } else {
            setErrors((prev) => ({
                ...prev,
                multiplier: '',
            }));
        }
    };

    const resetState = () => {
        setMeasurementData({
            name: '',
            symbol: '',
            synonyms: {
                editable: [],
                nonEditable: [],
            },
            category: 'WEIGHT',
            type: 'DECIMAL',
            multiplier: '',
        });

        setErrors({
            name: '',
            symbol: '',
            synonyms: '',
            multiplier: '',
        });
        setFieldsTouched({
            multiplier: false,
            name: false,
            symbol: false,
            synonyms: false,
        });
        setIsValid(false);
    };

    const [submitting, setSubmitting] = useState<boolean>(false);
    const handleSubmit = async (
        callback: (success: boolean, measurementUnitUid: string) => void
    ) => {
        if (!initialData) {
            try {
                setSubmitting(true);

                const resp = await saveNewMeasurementUnit(
                    measurementData.name,
                    measurementData.category,
                    measurementData.type,
                    measurementData.symbol,
                    [
                        ...measurementData.synonyms.editable,
                        ...measurementData.synonyms.nonEditable,
                    ],
                    +measurementData.multiplier
                );
                toast.success('Measurement unit added successfully');
                callback(true, resp.measurementUnitUuid);
                setSubmitting(false);
            } catch (error) {
                toast.error('Something went wrong');
                callback(false, '');
                setSubmitting(false);
            }
        } else {
            try {
                setSubmitting(true);

                await updateMeasurementUnitApi({
                    synonyms: [...measurementData.synonyms.editable],
                    measurementUnitId: initialData.measurementUnitId,
                });
                toast.success('Synonyms edited successfully');
                callback(true, initialData.measurementUnitId);
                setSubmitting(false);
            } catch (error) {
                toast.error('Something went wrong');
                callback(false, initialData.measurementUnitId);
                setSubmitting(false);
            }
        }
    };

    const standardConversionUnit = useMemo(() => {
        const filteredConversionUnit = measurementUnits.filter(
            (val) =>
                val.measurement_unit.measurement_unit_category ===
                measurementData.category
        );

        const standardConversionUnit = filteredConversionUnit.find(
            (val) => val.measurement_unit.is_standard_unit
        );

        return standardConversionUnit || null;
    }, [measurementData.category, measurementUnits]);

    return {
        measurementData,
        handleNameChange,
        handleAbbreviationChange,
        handleSynonymsChange,
        handleCategoryChange,
        handleTypeChange,
        handleSubmit,
        handleBaseQuantityChange,
        errors,
        isValid,
        submitting,
        resetState,
        handleMultiplierChange,
        standardConversionUnit,
    };
};
