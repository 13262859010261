import { DialogContent } from '@mui/material';
import { FWPopupWithChildren } from '../../../../Common/FWPopupWithChildren';

interface IItemPreferredVendorsPopupProps {
    popupToggle: boolean;
    children: JSX.Element;
    handlePopupToggle: () => void;
}

const ItemPreferredVendorsPopup = ({
    popupToggle,
    handlePopupToggle,
    children,
}: IItemPreferredVendorsPopupProps) => {
    return (
        <FWPopupWithChildren
            handleClose={handlePopupToggle}
            open={popupToggle}
            title="Add preferred vendors"
            PaperProps={{
                sx: {
                    minWidth: '600px !important',
                },
            }}
        >
            <DialogContent style={{ paddingTop: 0, paddingBottom: '24px' }}>
                <div className="mt--5">{children}</div>
            </DialogContent>
        </FWPopupWithChildren>
    );
};

export default ItemPreferredVendorsPopup;
