import { createSlice } from '@reduxjs/toolkit';
import { TFeatures } from '../Interfaces/FeaturesInterface';
import {
    featuresReducer,
    IFeaturesActions,
} from '../Reducers/AvailableFeaturesReducer';

export const AvailableFeaturesSlice = createSlice<
    TFeatures,
    {
        availableFeatures: (state: TFeatures, action: IFeaturesActions) => void;
    },
    string
>({
    name: 'AvailableFeaturesStore',
    initialState: [],
    reducers: {
        availableFeatures: featuresReducer,
    },
});

export const { availableFeatures } = AvailableFeaturesSlice.actions;

const AvailableFeaturesStore = AvailableFeaturesSlice.reducer;
export default AvailableFeaturesStore;
