import { baseApiSlice } from '../../Redux/Slices/BaseApiSlice';
import { IMeasurementUnit } from '../../UOM&Currency/Interfaces/UOMInterface';
import {
    AddSpecificationPayload,
    IAttribute,
    ISpecificationsDataResponse,
    UpdateSpecStatusPayload,
} from '../Models/SpecificationInterfaces';

export const specificationApiSlice = baseApiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getSpecificationsData: builder.query<ISpecificationsDataResponse, any>({
            query: (payload) => {
                console.log('payload', payload);
                return {
                    url: '/dashboard/',
                    method: 'POST',
                    body: payload,
                };
            },
        }),
        getSpecificationsListQuery: builder.query<IAttribute[], any>({
            query: () => ({
                url: '/attribute/',
                method: 'GET',
            }),
        }),

        getSpecTabCount: builder.query<
            {
                tab_counts: {
                    active: number;
                    disabled: number;
                    all: number;
                };
            },
            {}
        >({
            query: () => ({
                url: '/dashboard/tab_counts/',
                method: 'POST',
                body: {
                    dashboard_view: 'attribute',
                },
            }),
        }),
        getMeasurementUnitActive: builder.query<IMeasurementUnit[], {}>({
            query: () => ({
                url: `/organization/measurement_unit?status=ACTIVE`,
            }),
        }),

        createSpecification: builder.mutation<any, AddSpecificationPayload>({
            query: (payload) => ({
                url: '/attribute/create/',
                method: 'POST',
                body: payload,
            }),
        }),

        deleteSpecification: builder.mutation<any, string>({
            query: (specID) => ({
                url: `/attribute/${specID}/delete/`,
                method: 'DELETE',
            }),
            async onQueryStarted(specID, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch (error) {
                    // Optionally handle or log the error here
                    throw new Error('Failed to delete the specification');
                }
            },
        }),

        editSpecification: builder.mutation<
            any,
            { specID: string; payload: AddSpecificationPayload }
        >({
            query: ({ specID, payload }) => ({
                url: `/attribute/${specID}/update/`,
                method: 'PUT',
                body: payload,
            }),
            async onQueryStarted(
                { specID, payload },
                { dispatch, queryFulfilled }
            ) {
                try {
                    await queryFulfilled; // Wait for the mutation to succeed
                } catch (error) {
                    // Optionally handle or log the error here
                    console.error('Failed to update the specification:', error);
                    throw new Error('Failed to update the specification');
                }
            },
        }),

        updateSpecificationStatus: builder.mutation<
            any,
            UpdateSpecStatusPayload
        >({
            query: ({ specID, newStatus }) => ({
                url: `/attribute/${specID}/update/status/`,
                method: 'PUT',
                body: { status: newStatus },
            }),
            async onQueryStarted(
                { specID, newStatus },
                { dispatch, queryFulfilled }
            ) {
                try {
                    await queryFulfilled; // Wait for the mutation to succeed
                } catch (error) {
                    // Optionally handle or log the error here
                    console.error('Failed to update status:', error);
                    throw new Error('Failed to update status');
                }
            },
        }),
    }),
});

export const {
    useGetSpecificationsDataQuery,
    useGetSpecificationsListQueryQuery,
    useGetSpecTabCountQuery,
    useGetMeasurementUnitActiveQuery,
    useCreateSpecificationMutation,
    useDeleteSpecificationMutation,
    useEditSpecificationMutation,
    useUpdateSpecificationStatusMutation,
} = specificationApiSlice;
