import { UserRoles } from '../../Organizations/Admin/Interfaces/UsersInterface';

export interface IInvitee {
    user_id: string;
    email: string;
    isAdmin: boolean;
    managers: string[];
    name: string;
    role: inviteeRole | null;
    entities: string[];
    decision: 'APPROVE' | 'REJECT' | 'PENDING';
    skip_onboarding: boolean;
}

export interface IInviteeEntityResponse {
    entity_id: string;
    entity_name: string;
    role: inviteeRole | null;
}
export interface IInviteeResponse {
    user_id: string;
    name: string;
    username: string;
    email: string;
    role: UserRoles;
    entity_list: IInviteeEntityResponse[];
}

export interface IApproveUserPayload {
    action_user_id: string;
    user_role: UserRoles;
    entity_list: string[];
    permission_role: inviteeRole;
    managers: string[];
    action: 'ACCEPT' | 'REJECT';
}

export const blankInviteeWithAllEntities = (
    entityList: string[],
    managerId: string,
    isSeller: boolean
): IInvitee => {
    return {
        user_id: '',
        email: '',
        isAdmin: false,
        managers: [managerId],
        name: '',
        role: isSeller ? 'SALES' : 'PROCUREMENT_EXECUTIVE',
        entities: entityList,
        decision: 'APPROVE',
        skip_onboarding: false,
    };
};

export type inviteeRole =
    | 'ADMIN'
    | 'SALES'
    | 'PROCUREMENT_EXECUTIVE'
    | 'PROCUREMENT_MANAGER'
    | 'GOODS_RECEIPT'
    | 'QUALITY_CHECK_ANALYST'
    | 'ACCOUNTS_PAYABLE_EXECUTIVE'
    | 'ANALYST';

export const inviteeRoleList: {
    value: inviteeRole;
    label: string;
}[] = [
    {
        value: 'ADMIN',
        label: 'Global admin',
    },
    {
        value: 'SALES',
        label: 'Sales',
    },
    {
        value: 'PROCUREMENT_EXECUTIVE',
        label: 'Procurement Executive',
    },
    {
        value: 'PROCUREMENT_MANAGER',
        label: 'Procurement Manager',
    },
    {
        value: 'GOODS_RECEIPT',
        label: 'Goods Receipt',
    },
    {
        value: 'QUALITY_CHECK_ANALYST',
        label: 'Quality Check Analyst',
    },
    {
        value: 'ACCOUNTS_PAYABLE_EXECUTIVE',
        label: 'Accounts Payable Executive',
    },
    {
        value: 'ANALYST',
        label: 'Analyst',
    },
];
