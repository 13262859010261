import { cloneDeep } from 'lodash';
import { useEffect, useState } from 'react';
import { HookStateValue, useHookState } from '../../Common/Hooks/StateHook';
import { useFetchItemAdditionalCostListQuery } from '../../Events/Services/Buyer/RFQ.service';
import {
    ICostingTemplate,
    TemplateStandardFieldsEnum,
    blankCostingTemplate,
} from '../Interfaces/CostingTemplate.interface';
import { useGetCostingTemplateByIdQuery } from '../Services/costingTemplate.service';

export const useCostingTemplate = (
    templateId: string | 'DEFAULT' | null,
    entityId: string
) => {
    const { hookState, updateHookState } = useHookState(HookStateValue.LOADING);

    const [costingTemplateDetails, setCostingTemplateDetails] =
        useState<ICostingTemplate>(blankCostingTemplate);

    const { data, isLoading, isError, error, isFetching } =
        useGetCostingTemplateByIdQuery(
            {
                entityId: entityId
                    ? entityId
                    : '097adb21-7e53-47aa-896c-fa2197396662',
                templateId: templateId ? templateId : '',
            },
            {
                skip:
                    templateId === '' ||
                    templateId === null ||
                    templateId === 'DEFAULT' ||
                    entityId === '',
            }
        );

    const { data: additionalCostForAdmin } =
        useFetchItemAdditionalCostListQuery(
            { filterModule: undefined },
            {
                skip:
                    templateId === '' ||
                    templateId === null ||
                    templateId === 'DEFAULT' ||
                    entityId === '',
            }
        );

    useEffect(() => {
        if (
            data &&
            !isLoading &&
            !isFetching &&
            additionalCostForAdmin !== undefined
        ) {
            const dataWithAdditionalCost = cloneDeep(data);

            if (additionalCostForAdmin) {
                for (let cost of dataWithAdditionalCost.fieldSettings
                    .standardFields[TemplateStandardFieldsEnum.ADDITIONAL_COSTS]
                    .additionalCostOptions) {
                    const findCostFromAdmin = additionalCostForAdmin.find(
                        (c) => c.additional_cost_id === cost.additional_cost_id
                    );
                    if (findCostFromAdmin && findCostFromAdmin.formula) {
                        cost.formula = findCostFromAdmin.formula.formula;
                    }
                }
            }
            setCostingTemplateDetails(dataWithAdditionalCost);
            updateHookState(HookStateValue.READY);
        }
    }, [additionalCostForAdmin, data, isFetching, isLoading, updateHookState]);

    useEffect(() => {
        if (isError && error) {
            updateHookState(HookStateValue.ERROR);
        }
    }, [isError, error, updateHookState]);

    return {
        hookState,
        isFetching,
        costingTemplateDetails,
        templateError: error,
    };
};
