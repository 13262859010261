import { Box, Grid, Typography } from '@mui/material';
import { Chart as ChartJS, Legend, Tooltip } from 'chart.js';

import {
    BarController,
    BarElement,
    CategoryScale,
    LineController,
    LineElement,
    LinearScale,
    PointElement,
} from 'chart.js';
import { Chart } from 'react-chartjs-2';
import { PROJECT_GRAPH_DIMENSIONS } from './ProjectGraphsPopup';

ChartJS.register(
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController
);

export default function ProjectOnTimeSubmission() {
    const labels = ['2020', '2021', '2022', '2023', '2024'];

    const options = {
        scales: {
            y: {
                title: {
                    display: true,
                    text: 'Quote Submission %',
                },
            },
            x: {
                title: {
                    display: true,
                    text: 'Years',
                },
            },
            y1: {
                grid: { display: false },
                stacked: true,
                position: 'right' as const,
                title: {
                    display: true,
                    text: 'Cost',
                },
                ticks: {
                    // Include a dollar sign in the ticks
                    callback: function (value: any, index: number, ticks: any) {
                        return '$' + value;
                    },
                },
                max: 500000,
            },
        },
    };

    const data = {
        labels,
        datasets: [
            {
                type: 'line' as const,
                label: 'Ontime Submission%',
                borderColor: '#4F1271',
                borderWidth: 2,
                fill: false,
                data: [75, 60, 95, 60, 70],
            },

            {
                type: 'bar' as const,
                label: 'Quotes',
                backgroundColor: 'rgb(75, 192, 192)',
                data: [70, 57, 100, 70, 60],
                borderColor: 'white',
                borderWidth: 2,
            },
        ],
    };
    return (
        <Grid
            sx={{
                cursor: 'pointer',
                transition: 'all 0.3s ease-in-out',
                '&:hover': {
                    boxShadow: '0px 3px 15px rgba(0,0,0,0.2)',
                },
            }}
            boxShadow={'0px 3px 15px rgb(0 0 0 / 7%)'}
            border={'3px solid transparent'}
            width={PROJECT_GRAPH_DIMENSIONS}
            height={PROJECT_GRAPH_DIMENSIONS}
            borderRadius={1}
            item
            padding={2}
            paddingTop={0}
            paddingBottom={4}
            xs={5.8}
        >
            <Box paddingY={1}>
                <Typography variant="h6" sx={{ padding: 1, fontSize: '1rem' }}>
                    On time Quote Submission
                </Typography>
            </Box>

            <Chart options={options} type="bar" data={data} />
        </Grid>
    );
}
