import { Button } from '@mui/material';
import React from 'react';
import { FWTypography } from '../../Common/FWTypograhy';
import {
    Provider,
    ErrorBoundary as RollbarErrorBoundary,
} from '@rollbar/react';

var _rollbarConfig = {
    accessToken: '1adaad79068e4210ada92ba865a93edc',
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
        environment: 'production',
    },
    enabled: process.env.REACT_APP_STAGE === 'production',
};

const ErrorUI = () => (
    <div
        style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
        }}
    >
        <img
            src="/res/ErrorBoundaryImage.png"
            alt="error 1"
            height="400px"
            width="400px"
        />
        <FWTypography variant="h4" fontWeight={600}>
            OH Snap!
        </FWTypography>
        <FWTypography variant="h5" textAlign="center">
            Unfortunately, we ran into a problem!
        </FWTypography>
        <FWTypography textAlign="center" variant="h5">
            We have logged it into our System!
        </FWTypography>
        <br />
        <Button
            href="/"
            style={{
                backgroundColor: 'black',
                color: 'white',
                padding: '10px 20px',
                margin: '20px 0',
            }}
        >
            Back to Home!
        </Button>
    </div>
);

export const ErrorBoundary = (
    props: React.PropsWithChildren<React.ReactNode>
) => {
    return (
        <Provider config={_rollbarConfig}>
            <RollbarErrorBoundary fallbackUI={ErrorUI}>
                {props.children}
            </RollbarErrorBoundary>
        </Provider>
    );
};
