import {
    Checkbox,
    DialogActions,
    DialogContent,
    Grid,
    Typography,
} from '@mui/material';
import { cloneDeep, isEqual } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import FWAutocomplete from '../../Common/FWAutocomplete';
import { FWButton } from '../../Common/FWButton';
import { FWMenuItem } from '../../Common/FWCustomMenu';
import { FWInput } from '../../Common/FWInput';
import { FWPopupWithChildren } from '../../Common/FWPopupWithChildren';
import { FWTypography } from '../../Common/FWTypograhy';
import { IProjectAssigenUserListResponse } from '../../ProjectGlCostCenter/interface/project.model';
import { useGetAssignUserListQuery } from '../../ProjectGlCostCenter/services/project.services';
import ShareSectionUserList from './ShareSectionUserList';
import {
    ProjectPermissionLabelEnum,
    ProjectPermissions,
    QuotePermissionLabelEnum,
    QuotePermissions,
} from '../../Organizations/Admin/Interfaces/UsersInterface';

export const PermissionsPriorityList = {
    View: 0,
    Edit: 1,
    Create: 2,
    'Assign Users': 3,
    'Assign Managers': 4,
    Template: 5,
};

export const SELECT_ALL_OPTION: IUserPermissionFEStructure = {
    name: 'SELECT_ALL',
    permissions: [],
    user_id: 'SELECT_ALL',
};

export interface IUserPermissionFEStructure {
    name: string;
    permissions: string[];
    user_id: string;
}

export interface ISharedAccessUserList {
    permissions: string[];
    user_id: string;
}
export interface IShareSectionPopupProps {
    open: boolean;
    buyerEntityId: string;
    templateModule: string;
    handleClose: () => void;
    updateSectionAccess: (
        payload: {
            user: {
                name: string;
                user_id: any;
            };
            permissions: string[];
        }[]
    ) => void;
    allowedPermissionList: string[];
    accessForName: string;
    sectionId?: string;
    templateId?: string;
    sharingAccessFor: 'TEMPLATE' | 'SECTION';
    initialAssignedUserList: {
        user: { name: string; user_id: string };
        permission: string;
    }[];
}

export default function ShareSectionPopup({
    open,
    buyerEntityId,
    templateModule,
    handleClose,
    updateSectionAccess,
    allowedPermissionList,
    accessForName,
    sectionId,
    templateId,
    sharingAccessFor,
    initialAssignedUserList,
}: IShareSectionPopupProps) {
    const [userOptions, setUserOptions] = useState<
        IUserPermissionFEStructure[]
    >([]);

    const [sharedAccessUserList, setSharedAccessUserList] = useState<
        IUserPermissionFEStructure[]
    >([]);

    const [originalAccessUserList, setOriginalAccessUserList] = useState<
        IUserPermissionFEStructure[]
    >([]);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [removedPermissionForUser, setRemovedPermissionForUser] = useState<
        string[]
    >([]);

    useEffect(() => {
        const removedUsers = initialAssignedUserList.filter((initialUser) =>
            userOptions.some(
                (user) => user.user_id === initialUser.user.user_id
            )
        );

        setRemovedPermissionForUser(
            removedUsers.map((user) => user.user.user_id)
        );
    }, [initialAssignedUserList, userOptions]);

    // fetch the names of the users whose access has been removed.

    useEffect(() => {
        if (userOptions?.length > 0 && open) {
            const userList: IUserPermissionFEStructure[] = [];

            initialAssignedUserList
                ?.filter((user) => user.permission !== undefined)
                .forEach((user) => {
                    const currUserIndexInList = userList.findIndex(
                        (userInList) => userInList.user_id === user.user.user_id
                    );

                    if (currUserIndexInList === -1) {
                        userList.push({
                            name:
                                userOptions?.find(
                                    (u) => u.user_id === user.user.user_id
                                )?.name ?? '',
                            permissions:
                                sharingAccessFor === 'TEMPLATE'
                                    ? [
                                          user.permission === 'ASSIGN'
                                              ? 'Assign Managers'
                                              : 'Create',
                                      ]
                                    : [
                                          user.permission === 'ASSIGN'
                                              ? 'Assign Users'
                                              : user.permission === 'EDIT'
                                              ? 'Edit'
                                              : 'View',
                                      ],
                            user_id: user.user.user_id,
                        });
                    } else {
                        if (sharingAccessFor === 'TEMPLATE') {
                            if (
                                userList[currUserIndexInList].permissions[0] ===
                                    'Assign Managers' &&
                                user.permission === 'CREATE'
                            ) {
                                userList[currUserIndexInList].permissions.push(
                                    'Create'
                                );
                            } else if (
                                userList[currUserIndexInList].permissions[0] ===
                                    'Create' &&
                                user.permission === 'ASSIGN'
                            ) {
                                userList[
                                    currUserIndexInList
                                ].permissions.unshift('Assign Managers');
                            }
                        } else {
                            if (
                                userList[currUserIndexInList].permissions[0] ===
                                    'Assign Users' &&
                                user.permission === 'EDIT'
                            ) {
                                userList[currUserIndexInList].permissions.push(
                                    'Edit'
                                );
                            } else if (
                                userList[currUserIndexInList].permissions[0] ===
                                    'Edit' &&
                                user.permission === 'ASSIGN'
                            ) {
                                userList[
                                    currUserIndexInList
                                ].permissions.unshift('Assign Users');
                            }
                        }
                    }
                });

            setSharedAccessUserList(userList);
            setOriginalAccessUserList(userList);
        }
    }, [
        initialAssignedUserList,
        open,
        sharingAccessFor,
        userOptions,
        userOptions?.length,
    ]);

    const handleShareAccess = useCallback(
        (option: IUserPermissionFEStructure) => {
            let newUserList = cloneDeep(sharedAccessUserList);

            const userIndex = newUserList.findIndex(
                (user) => user.user_id === option.user_id
            );

            if (userIndex === -1) {
                newUserList.push(cloneDeep(option));
            } else {
                newUserList.splice(userIndex, 1);
            }

            setSharedAccessUserList(newUserList);
        },
        [sharedAccessUserList]
    );

    const handleShareAccessToAllUsers = (selectAll: boolean) => {
        if (provideAccessToAllUsers) {
            setProvideAccessToAllUsers(false);
        }
        SetAutoSelectAllUsers(false);
        if (selectAll) {
            setSharedAccessUserList(
                userOptions.map((user) => ({
                    permissions: user.permissions,
                    user_id: user.user_id,
                    name: user.name,
                }))
            );
        } else {
            setSharedAccessUserList([]);
        }
    };

    const handlePopupClose = () => {
        handleClose();
    };

    const [autoSelectAllUsers, SetAutoSelectAllUsers] = useState(true);

    const [provideAccessToAllUsers, setProvideAccessToAllUsers] =
        useState(false);

    const [
        isAccessProvidedToAllUsersOriginally,
        setIsAccessProvidedToAllUsersOriginally,
    ] = useState(false);

    const { data: userPermissionList, refetch } = useGetAssignUserListQuery(
        {
            permissionList: allowedPermissionList,
            entityid: buyerEntityId,
            allUsers: true,
        },
        {
            skip: !Boolean(buyerEntityId) || !open,
        }
    );

    useEffect(() => {
        try {
            refetch();
        } catch (err) {
            console.error('errr', err);
        }
    }, [refetch, sectionId, templateId]);

    useEffect(() => {
        if (userPermissionList !== undefined) {
            let userPermissionListFiltered: IUserPermissionFEStructure[] = [];

            userPermissionList.forEach((user) => {
                const currUserIndexInList =
                    userPermissionListFiltered.findIndex(
                        (userInList) => userInList.user_id === user.user_id
                    );

                if (currUserIndexInList === -1) {
                    userPermissionListFiltered.push({
                        name: user.name,
                        user_id: user.user_id,
                        permissions: [
                            templateModule === 'PROJECT'
                                ? ProjectPermissionLabelEnum[
                                      user.action_api_group as ProjectPermissions
                                  ]
                                : QuotePermissionLabelEnum[
                                      user.action_api_group as QuotePermissions
                                  ],
                        ],
                    });
                } else {
                    userPermissionListFiltered[
                        currUserIndexInList
                    ].permissions = Array.from(
                        new Set([
                            ...(userPermissionListFiltered[currUserIndexInList]
                                .permissions as (keyof typeof PermissionsPriorityList)[]),
                            templateModule === 'PROJECT'
                                ? (ProjectPermissionLabelEnum[
                                      user.action_api_group as ProjectPermissions
                                  ] as keyof typeof PermissionsPriorityList)
                                : (QuotePermissionLabelEnum[
                                      user.action_api_group as QuotePermissions
                                  ] as keyof typeof PermissionsPriorityList),
                        ])
                    ).sort(
                        (
                            a: keyof typeof PermissionsPriorityList,
                            b: keyof typeof PermissionsPriorityList
                        ) =>
                            PermissionsPriorityList[b] -
                            PermissionsPriorityList[a]
                    );
                }
            });

            setUserOptions(userPermissionListFiltered);
        }
    }, [userPermissionList, sectionId, templateId, templateModule]);

    const removeUserAccess = useCallback(
        (user_id: string) => {
            let newUserList = cloneDeep(sharedAccessUserList);

            const userIndex = newUserList.findIndex(
                (user) => user.user_id === user_id
            );

            if (userIndex !== -1) {
                newUserList[userIndex].permissions = [];
                // newUserList.splice(userIndex, 1);
            }
            if (newUserList?.length === 0) {
                setProvideAccessToAllUsers(true);
            }

            setSharedAccessUserList(newUserList);
        },
        [sharedAccessUserList]
    );

    const updateUserAccess = (user_id: string, newPermission: string) => {
        const newUserList = cloneDeep(sharedAccessUserList);

        const currentUserIndex = newUserList.findIndex(
            (user) => user.user_id === user_id
        );

        if (currentUserIndex !== -1) {
            newUserList[currentUserIndex].permissions = [newPermission];
        }

        setSharedAccessUserList(newUserList);
    };

    const cancelUpdateAccess = () => {
        handlePopupClose();

        setProvideAccessToAllUsers(isAccessProvidedToAllUsersOriginally);

        // setSharedAccessUserList(originalAccessUserList);
    };

    const updatePermissionAndSubmit = () => {
        const payload: {
            user: {
                name: string;
                user_id: any;
            };
            permissions: string[];
        }[] = sharedAccessUserList.map((user) => {
            let permission: string[] = [];

            if (user.permissions[0] === 'Assign Users') {
                permission = ['ASSIGN', 'EDIT', 'VIEW'];
            } else if (user.permissions[0] === 'Edit') {
                permission = ['EDIT', 'VIEW'];
            } else if (user.permissions[0] === 'View') {
                permission = ['VIEW'];
            } else if (user.permissions[0] === 'Assign Managers') {
                permission = ['ASSIGN', 'CREATE'];
            } else if (user.permissions[0] === 'Create') {
                permission = ['CREATE'];
            }

            return {
                user: {
                    name: user.name,
                    user_id: user.user_id,
                },
                permissions: permission,
            };
        });
        updateSectionAccess(payload);
        setOriginalAccessUserList(sharedAccessUserList);
        handlePopupClose();
    };

    const disableSaveButton = useMemo(() => {
        if (!provideAccessToAllUsers && sharedAccessUserList?.length === 0) {
            return true;
        } else if (isEqual(originalAccessUserList, sharedAccessUserList)) {
            return true;
        } else {
            return false;
        }
    }, [originalAccessUserList, provideAccessToAllUsers, sharedAccessUserList]);

    useEffect(() => {
        if (
            sharedAccessUserList?.length === 0 ||
            (sharedAccessUserList?.length !== 0 &&
                sharedAccessUserList.filter(
                    (user) => user.permissions?.length !== 0
                )?.length === 0)
        ) {
            setProvideAccessToAllUsers(true);
            setIsAccessProvidedToAllUsersOriginally(true);
        } else {
            setProvideAccessToAllUsers(false);

            setIsAccessProvidedToAllUsersOriginally(false);
        }
    }, [
        autoSelectAllUsers,
        sharedAccessUserList,
        sharedAccessUserList?.length,
    ]);

    const removedUsers = useMemo(() => {
        return initialAssignedUserList.filter(
            (user) =>
                !userOptions?.some((usr) => usr.user_id === user.user.user_id)
        );
    }, [initialAssignedUserList, userOptions]);

    return (
        <FWPopupWithChildren
            open={open}
            handleClose={cancelUpdateAccess}
            title={`User Access for  ${accessForName}`}
            applyTitleStyling={false}
            popupWidth={'33vw'}
            size="custom"
        >
            <DialogContent>
                <Grid container>
                    <Grid item xs={12}>
                        <FWAutocomplete
                            popupIcon={
                                <i
                                    className="bi bi-chevron-down"
                                    style={{
                                        fontSize: '20px',
                                    }}
                                />
                            }
                            fullWidth
                            multiple
                            // disabled={provideAccessToAllUsers}
                            value={sharedAccessUserList.filter(
                                (user) => user.permissions?.length !== 0
                            )}
                            options={[SELECT_ALL_OPTION, ...userOptions]}
                            getOptionLabel={(
                                option: IProjectAssigenUserListResponse
                            ) => {
                                return option.name;
                            }}
                            renderInput={(params) => (
                                <FWInput
                                    {...params}
                                    placeholder="Search user"
                                />
                            )}
                            renderTags={() => {
                                return <></>;
                            }}
                            renderOption={(
                                props,
                                option: IUserPermissionFEStructure
                            ) => {
                                const accessSharedToAllUsers =
                                    userOptions?.length ===
                                    sharedAccessUserList.filter(
                                        (user) => user.permissions?.length !== 0
                                    )?.length;

                                if (option.user_id === 'SELECT_ALL') {
                                    return (
                                        <FWMenuItem
                                            {...props}
                                            key={option.user_id}
                                            sx={{
                                                display: 'flex',
                                            }}
                                            value={option.user_id}
                                            onClick={() => {
                                                handleShareAccessToAllUsers(
                                                    !accessSharedToAllUsers
                                                );
                                            }}
                                        >
                                            <Checkbox
                                                sx={{
                                                    paddingLeft: 0,
                                                }}
                                                checked={accessSharedToAllUsers}
                                            />
                                            <FWTypography>
                                                Select all
                                            </FWTypography>
                                        </FWMenuItem>
                                    );
                                }
                                const isUserSelected = sharedAccessUserList
                                    .filter(
                                        (user) => user.permissions?.length !== 0
                                    )
                                    .some(
                                        (manager) =>
                                            manager.user_id === option.user_id
                                    );

                                return (
                                    <FWMenuItem
                                        {...props}
                                        key={option.user_id}
                                        sx={{
                                            display: 'flex',
                                        }}
                                        value={option.user_id}
                                        onClick={() => {
                                            handleShareAccess(option);
                                        }}
                                    >
                                        <Checkbox
                                            sx={{
                                                paddingLeft: 0,
                                            }}
                                            checked={isUserSelected}
                                        />
                                        <FWTypography>
                                            {option.name}
                                        </FWTypography>
                                    </FWMenuItem>
                                );
                            }}
                        />
                    </Grid>

                    {sharedAccessUserList.filter(
                        (user) => user.permissions?.length !== 0
                    )?.length > 0 && (
                        <Grid container rowGap={2} marginY={2}>
                            <ShareSectionUserList
                                templateModule={templateModule}
                                userOptions={userOptions}
                                removedUsers={removedUsers}
                                sharingAccessFor={sharingAccessFor}
                                sharedAccessUserList={sharedAccessUserList.filter(
                                    (user) => user.permissions?.length !== 0
                                )}
                                disableRemoveButton={false}
                                removeUserAccess={(user_id: string) => {
                                    removeUserAccess(user_id);
                                }}
                                updateUserAccess={updateUserAccess}
                            />
                        </Grid>
                    )}

                    <Grid
                        item
                        xs={12}
                        marginTop={4}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <Checkbox
                            checked={provideAccessToAllUsers}
                            disabled={true}
                            onChange={() => {
                                setProvideAccessToAllUsers(
                                    !provideAccessToAllUsers
                                );
                            }}
                        ></Checkbox>
                        <Typography>
                            {sharingAccessFor === 'SECTION'
                                ? 'All users can view, edit and assign based on their respective permissions'
                                : 'All users can create and assign based on their respective permissions'}
                        </Typography>
                    </Grid>
                </Grid>
                <DialogActions
                    // sx={{ m: '12px 24px 24px 24px' }}
                    sx={{ marginTop: 2 }}
                    onClick={(e) => e.stopPropagation()}
                >
                    <FWButton
                        variant="outlined"
                        color="primary"
                        onClick={cancelUpdateAccess}
                    >
                        Cancel
                    </FWButton>
                    <FWButton
                        variant="contained"
                        color="primary"
                        disabled={disableSaveButton}
                        onClick={(e) => {
                            updatePermissionAndSubmit();
                        }}
                    >
                        Confirm
                    </FWButton>
                </DialogActions>
            </DialogContent>
        </FWPopupWithChildren>
    );
}
