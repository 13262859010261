import {
    Box,
    Checkbox,
    // FormControl,
    // MenuItem,
    // Select,
    CircularProgress,
    DialogActions,
    DialogContent,
    FormControlLabel,
    Grid,
} from '@mui/material';
import { FWButton } from '../../../../Common/FWButton';
import { FWInput } from '../../../../Common/FWInput';
import { FWPopupWithChildren } from '../../../../Common/FWPopupWithChildren';
import {
    IdentificationReducerAction,
    IdentificationUpdateActions,
    useIdentificationCreate,
} from '../../Hooks/IdentificationHook';
// import { FWTypography } from '../../../Common/FWTextField';
import { isEmpty } from 'lodash';
import { useState } from 'react';
import { toast } from 'react-toastify';
import FWAutocomplete from '../../../../Common/FWAutocomplete';
import { FWTooltip } from '../../../../Common/FWCustomTooltip';
import FWIcon from '../../../../Common/FWIcon';
import { FWTypography } from '../../../../Common/FWTypograhy';
import { HookStateValue } from '../../../../Common/Hooks/StateHook';
import UploadFile, {
    IFileResourceType,
} from '../../../../Components/Shared/UploadFile';
import UploadedFilesList from '../../../../Components/Shared/UploadedFilesList';
import { IAttachment } from '../../../../Models/RFQ.model';
import { IdentificationEmitActions } from '../../Containers/Identifications/IdentificationsListContainer';
import { ICountryList } from '../../Interfaces/AddressInterface';

interface IIdentificationCreateProps {
    popupToggle: boolean;
    entityId: string;
    canEditDefault: boolean;
    isFirstIdentification: boolean;
    identificationNamesList: string[];
    entityName?: string;
    emitPopupToggle: (response: IdentificationEmitActions) => void;
}

const IdentificationCreatePopup = ({
    popupToggle,
    entityId,
    canEditDefault,
    isFirstIdentification,

    emitPopupToggle,
    identificationNamesList,
    entityName,
}: IIdentificationCreateProps) => {
    const {
        identificationDetail,
        isValidDetails,
        handleIdentificationUpdate,
        handleReset,
        handleCreate,
        countryList,
        hookState,
    } = useIdentificationCreate(entityId, isFirstIdentification);

    const [errors, setErrors] = useState<{
        [key: string]: boolean;
    }>({});

    const handleFieldBlur = (
        field: IdentificationUpdateActions,
        value: string
    ) => {
        let trimmedVal = value.trim();
        if (value !== trimmedVal) {
            handleIdentificationUpdate({
                value: trimmedVal,
                type: field,
            } as IdentificationReducerAction);
        }
        setErrors((prevErrors) => {
            prevErrors[field] = isEmpty(trimmedVal);
            return {
                ...prevErrors,
            };
        });
    };

    const handleFieldChange = (event: any) => {
        handleIdentificationUpdate({
            value: event.target.value,
            type: event.target.name,
        });
    };

    const handleCountryChange = (value: string) => {
        handleIdentificationUpdate({
            type: IdentificationUpdateActions.UpdateCountry,
            value: value,
        });

        setErrors((prevErrors) => {
            prevErrors[IdentificationUpdateActions.UpdateCountry] =
                !Boolean(value);
            return {
                ...prevErrors,
            };
        });
    };

    const handleFileUpload = (files: IAttachment[]) => {
        handleIdentificationUpdate({
            value: files,
            type: IdentificationUpdateActions.UpdateFiles,
        });
    };

    const handleFileRemove = (index: number) => {
        handleIdentificationUpdate({
            value: index,
            type: IdentificationUpdateActions.RemoveFile,
        });
    };

    const handleToggleChange = (event: any) => {
        handleIdentificationUpdate({
            value: event.target.checked,
            type: event.target.name,
        });
    };

    const setPopupToggle = (response?: IdentificationEmitActions) => {
        emitPopupToggle(response || IdentificationEmitActions.CANCEL);
        handleReset();
    };

    const handleFormSubmit = async (event: any) => {
        event.preventDefault();
        const identificationCreated = await handleCreate();
        if (identificationCreated) {
            // toast.success(`Identification created successfully`);
            setPopupToggle(IdentificationEmitActions.SUCCESS);
        } else {
            toast.error(`Identification creation failed.`);
        }
    };

    const duplicateIdentification = identificationNamesList.includes(
        identificationDetail.name.toLowerCase()
    );

    return (
        <>
            <FWPopupWithChildren
                handleClose={() => {
                    emitPopupToggle(IdentificationEmitActions.CANCEL);
                }}
                open={popupToggle}
                size="custom"
                popupWidth={'650px'}
                style={{
                    width: '650px !important',
                }}
                title={
                    'Add identification' +
                    (entityName ? ` for ${entityName}` : '')
                }
            >
                <form onSubmit={handleFormSubmit}>
                    <DialogContent style={{ paddingTop: 0, paddingBottom: 0 }}>
                        <Grid marginY={2} className="mb--5">
                            <Grid sx={{ display: 'flex', gap: '5px' }}>
                                <FWTypography color={'text.secondary'}>
                                    Official enterprise identification ID
                                </FWTypography>

                                <FWTooltip title="This should be the government issued ID name such as GST, VAT, EIN etc.)">
                                    <Box>
                                        <FWIcon
                                            name="bi bi-info-circle"
                                            color="primary.main"
                                            size={14}
                                        />
                                    </Box>
                                </FWTooltip>
                            </Grid>
                            <FWInput
                                type="text"
                                // label="Official enterprise identification ID"
                                onChange={handleFieldChange}
                                name={IdentificationUpdateActions.UpdateName}
                                value={identificationDetail.name}
                                placeholder="Input ID name e.g., GST or VAT or EIN"
                                style={{ width: '100%' }}
                                error={
                                    duplicateIdentification ||
                                    errors[
                                        IdentificationUpdateActions.UpdateName
                                    ]
                                }
                                helper={{
                                    text: duplicateIdentification
                                        ? `Duplicate identification name 
                                          "${identificationDetail.name}"`
                                        : '',
                                }}
                                onBlur={(e) => {
                                    handleFieldBlur(
                                        IdentificationUpdateActions.UpdateName,
                                        e.target.value
                                    );
                                }}
                                required
                            />
                        </Grid>
                        <Grid marginY={2} className="mb--5">
                            <Grid sx={{ display: 'flex', gap: '5px' }}>
                                <FWTypography color={'text.secondary'}>
                                    Official enterprise identification ID value
                                </FWTypography>

                                <FWTooltip title="This should be the government issued ID value such as 22AAECF47349A1ZJ or GB731331179 etc.">
                                    <Box>
                                        <FWIcon
                                            name="bi bi-info-circle"
                                            color="primary.main"
                                            size={14}
                                        />
                                    </Box>
                                </FWTooltip>
                            </Grid>
                            <FWInput
                                type="text"
                                // label="ID value"
                                onChange={handleFieldChange}
                                name={IdentificationUpdateActions.UpdateValue}
                                placeholder="Input value e.g., 22AAECF47349A1ZJ or GB731331179 or  34-1234567"
                                value={identificationDetail.value}
                                style={{ width: '100%' }}
                                onBlur={(e) => {
                                    handleFieldBlur(
                                        IdentificationUpdateActions.UpdateValue,
                                        e.target.value
                                    );
                                }}
                                error={
                                    errors[
                                        IdentificationUpdateActions.UpdateValue
                                    ]
                                }
                                required
                            />
                        </Grid>
                        <Grid marginY={2} className="mb--5">
                            <Grid sx={{ display: 'flex', gap: '5px' }}>
                                <FWTypography color={'text.secondary'}>
                                    Country issuing ID
                                </FWTypography>
                            </Grid>

                            <FWAutocomplete
                                fullWidth
                                options={countryList}
                                renderInput={(params) => (
                                    <FWInput
                                        required
                                        error={
                                            errors[
                                                IdentificationUpdateActions
                                                    .UpdateCountry
                                            ]
                                        }
                                        {...params}
                                    />
                                )}
                                getOptionLabel={(option: ICountryList) => {
                                    return option.name || '';
                                }}
                                isOptionEqualToValue={(
                                    option: ICountryList,
                                    value: string
                                ) => {
                                    return option.countryCode === value;
                                }}
                                onBlur={(e) => {
                                    if (
                                        !Boolean(identificationDetail.country)
                                    ) {
                                        handleCountryChange('');
                                    }
                                }}
                                value={
                                    isEmpty(identificationDetail.country)
                                        ? ''
                                        : countryList.find(
                                              (country) =>
                                                  country.countryCode ===
                                                  identificationDetail.country
                                          )
                                }
                                onChange={(e, newValue: ICountryList) => {
                                    handleCountryChange(newValue.countryCode);
                                }}
                                popupIcon={
                                    <i
                                        className="bi bi-chevron-down"
                                        style={{ fontSize: '16px' }}
                                    />
                                }
                                disableClearable
                            />
                        </Grid>
                        <div>
                            <div className="flex flex--aic">
                                <FormControlLabel
                                    onChange={handleToggleChange}
                                    control={
                                        <FWTooltip
                                            title={
                                                !canEditDefault
                                                    ? 'Entity must have at least one default identification'
                                                    : !identificationDetail.isPublic
                                                    ? `Only public identifications can be set as defaults`
                                                    : ''
                                            }
                                            placement="right"
                                        >
                                            <div>
                                                <Checkbox
                                                    checked={
                                                        identificationDetail.isDefault
                                                    }
                                                    name={
                                                        IdentificationUpdateActions.UpdateDefault
                                                    }
                                                    disabled={
                                                        // !identificationDetail.isPublic ||
                                                        !canEditDefault
                                                    }
                                                />
                                            </div>
                                        </FWTooltip>
                                    }
                                    label="Add as default for documents like POs"
                                />
                            </div>
                        </div>
                        <div className="mb--5">
                            <FormControlLabel
                                onChange={handleToggleChange}
                                control={
                                    <FWTooltip
                                        title={
                                            identificationDetail.isDefault ||
                                            !canEditDefault
                                                ? 'Cannot set default identifications as private'
                                                : ''
                                        }
                                        placement="right"
                                    >
                                        <div>
                                            <Checkbox
                                                checked={
                                                    identificationDetail.isPublic
                                                }
                                                name={
                                                    IdentificationUpdateActions.UpdatePublic
                                                }
                                                icon={
                                                    <i
                                                        className="bi bi-eye-slash"
                                                        style={{
                                                            fontSize: '21px',
                                                            width: '27px',
                                                            textAlign: 'center',
                                                        }}
                                                    ></i>
                                                }
                                                checkedIcon={
                                                    <i
                                                        className="bi bi-eye fs--20"
                                                        style={{
                                                            fontSize: '21px',
                                                            width: '27px',
                                                            textAlign: 'center',
                                                        }}
                                                    ></i>
                                                }
                                                disabled={
                                                    identificationDetail.isDefault ||
                                                    !canEditDefault
                                                }
                                            />
                                        </div>
                                    </FWTooltip>
                                }
                                label={
                                    identificationDetail.isPublic
                                        ? 'Public'
                                        : 'Private'
                                }
                            />
                        </div>
                        <div className="mb--20">
                            <FWInput
                                type="text"
                                label="Notes (optional)"
                                onChange={handleFieldChange}
                                name={IdentificationUpdateActions.UpdateNotes}
                                value={identificationDetail.notes}
                                style={{ width: '100%' }}
                                multiline={true}
                                maxRows={4}
                            />
                        </div>
                        <Box marginY={2} className="flex flex--aic mb--5">
                            <UploadFile
                                label="Upload attachment for verification purposes"
                                existingFiles={identificationDetail.attachments}
                                allowedFileSizeInMB={5}
                                maxAllowedFiles={1}
                                onFileUpload={(files) => {
                                    handleFileUpload(files);
                                }}
                                resourceType={IFileResourceType.IDENTIFICATION}
                                labelColor=""
                                labelvariant="h5"
                            />
                            {/* <FWTooltip title="Please upload a proof document for verification purposes">
                                    <div className="ml--10">
                                        <FWIcon
                                            name="bi bi-info-circle"
                                            color="primary.main"
                                        />
                                    </div>
                                </FWTooltip> */}
                        </Box>

                        <Box
                            marginBottom={4}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                width: '100%',
                                marginBottom: '10px',
                            }}
                        >
                            <UploadedFilesList
                                existingFiles={identificationDetail.attachments}
                                toggleFileRemove={(fileIndex) => {
                                    handleFileRemove(fileIndex);
                                }}
                            />
                        </Box>
                    </DialogContent>
                    <DialogActions style={{ padding: '0 24px 20px' }}>
                        <FWButton
                            type="button"
                            onClick={() => setPopupToggle()}
                            color="primary"
                            variant="outlined"
                        >
                            Cancel
                        </FWButton>
                        <FWTooltip
                            title={
                                !isValidDetails
                                    ? 'Please fill all the required fields'
                                    : duplicateIdentification
                                    ? `Duplicate identification name "${identificationDetail.name}"`
                                    : identificationDetail.attachments
                                          .length === 0
                                    ? 'Please upload attachment for verification purposes'
                                    : ''
                            }
                            placement="bottom"
                        >
                            <Box>
                                <FWButton
                                    type="submit"
                                    variant="contained"
                                    disabled={
                                        !isValidDetails ||
                                        hookState.state ===
                                            HookStateValue.LOADING ||
                                        duplicateIdentification ||
                                        identificationDetail.attachments
                                            .length === 0
                                    }
                                >
                                    {hookState.state ===
                                    HookStateValue.LOADING ? (
                                        <CircularProgress
                                            size={24}
                                            style={{ color: '#c4c4c8' }}
                                        />
                                    ) : (
                                        'Request verification'
                                    )}
                                </FWButton>
                            </Box>
                        </FWTooltip>
                    </DialogActions>
                </form>
            </FWPopupWithChildren>
        </>
    );
};

export default IdentificationCreatePopup;
