import React, { useEffect, useState } from 'react';
import { useReactToPrint } from 'react-to-print';

const useGeneratePdf = (
    pdfRef: React.RefObject<HTMLDivElement>,
    fileName: string
) => {
    const [pdfWindowOpened, setPdfWindowOpened] = useState(false);

    const handlePrint = useReactToPrint({
        documentTitle: fileName,
        content: () => pdfRef.current,
        onAfterPrint: () => {
            setPdfWindowOpened(false);
        },
        removeAfterPrint: true,
    });

    useEffect(() => {
        if (pdfWindowOpened) {
            // added timeout to wait for PDF to show opened accordions and other pdfWindowOpened changes
            setTimeout(() => {
                handlePrint();
            }, 0);
        }
    }, [handlePrint, pdfWindowOpened]);

    const pdfPageStyles = {
        ...(pdfWindowOpened && {
            scale: '0.85',
            width: 1700,
            position: 'absolute',
            top: '-60px',
            bottom: '60px',
            left: '-100px',
            padding: 20,
            size: 'A4 portrait',
        }),
    };

    return {
        setPdfWindowOpened,
        pdfWindowOpened,
        pdfPageStyles,
    };
};

export default useGeneratePdf;
