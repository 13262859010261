import { toast } from 'react-toastify';
import { downloadFile } from '../../Global/Services/FileStorageService';

export const downloadAllChatAttachments = async (
    attachments: {
        attachment_id: string;
        file_name: string;
    }[]
) => {
    const promiseArr: Promise<string>[] = attachments.map(({ attachment_id }) =>
        downloadFile(attachment_id)
    );

    try {
        const downloadLinks = await Promise.all(promiseArr);
        downloadLinks.forEach((url, idx) => {
            setTimeout(() => {
                window.open(url, '_self');
            }, idx * 1000);
        });
    } catch (error) {
        toast.error('Error downloading file(s)');
    }
};
