import { cloneDeep, isEmpty, isEqual } from 'lodash';
import moment from 'moment';
import { toast } from 'react-toastify';
import { IBulkImport } from '../../Global/Interfaces/BulkImportInterface';
import { downloadFile } from '../../Global/Services/FileStorageService';
import { IAttachment } from '../../Models/RFQ.model';
import {
    CreateDocumentErrorFieldEnum,
    IDocumentDetails,
    IDocumentDetailsErrors,
    INewDocument,
} from './Document.model';
import {
    DocumentTemplateStandardFieldsEnum,
    IDocumentTemplate,
} from './Interfaces/documentTemplate';

export const validateDocumentDetails = ({
    field,
    data,
    errors,
    // allExistingEvents,
    templateDetails,
    side,
}: {
    field: CreateDocumentErrorFieldEnum | 'ALL';
    data: INewDocument;
    errors: IDocumentDetailsErrors;
    // allExistingEvents: Set<string>;
    templateDetails: IDocumentTemplate;
    side: 'BUYER' | 'SELLER';
}): IDocumentDetailsErrors => {
    let newErrors = cloneDeep(errors);

    if (
        side === 'BUYER' &&
        (field === CreateDocumentErrorFieldEnum.document_name ||
            field === 'ALL')
    ) {
        if (isEmpty(data.document_name.trim())) {
            newErrors.document_name = {
                type: 'ERROR',
                message: 'Please enter a document name',
            };
        } else if (data.document_name.trim().length > 50) {
            newErrors.document_name = {
                type: 'ERROR',
                message: 'Maximum 50 characters',
            };
        } else {
            delete newErrors.document_name;
        }
    }
    if (
        side === 'BUYER' &&
        (field === CreateDocumentErrorFieldEnum.entity_id || field === 'ALL')
    ) {
        delete newErrors.entity_id;
        if (data.entity_id === '') {
            newErrors.entity_id = {
                type: 'ERROR',
                message: 'Please select a valid entity',
            };
        }
    }

    if (
        side === 'BUYER' &&
        (field === CreateDocumentErrorFieldEnum.document_validity_datetime ||
            field === 'ALL')
    ) {
        delete newErrors.document_validity_datetime;

        if (
            templateDetails.fieldSettings.standardFields[
                DocumentTemplateStandardFieldsEnum.DOCUMENT_VALIDITY_DATE
            ].buyerSettings.required &&
            !Boolean(data.document_validity_datetime)
        ) {
            newErrors.document_validity_datetime = {
                type: 'ERROR',
                message: 'Please select a valid entity',
            };
        } else if (
            new Date(data.document_validity_datetime ?? '') < new Date()
        ) {
            newErrors.document_validity_datetime = {
                type: 'ERROR',
                message: 'Validity date cannot be in the past.',
            };
        }
    }
    if (
        side === 'BUYER' &&
        (field === CreateDocumentErrorFieldEnum.document_logo_id ||
            field === 'ALL')
    ) {
        delete newErrors.document_logo_id;

        if (
            templateDetails.fieldSettings.standardFields[
                DocumentTemplateStandardFieldsEnum.DOCUMENT_LOGO
            ].buyerSettings.required &&
            templateDetails.fieldSettings.standardFields[
                DocumentTemplateStandardFieldsEnum.DOCUMENT_LOGO
            ].buyerSettings.showField &&
            data.document_logo_id?.length === 0
        ) {
            newErrors.document_logo_id = {
                type: 'ERROR',
                message: 'Field is required',
            };
        }
    }
    if (
        side === 'BUYER' &&
        (field === CreateDocumentErrorFieldEnum.document_banner_id ||
            field === 'ALL')
    ) {
        delete newErrors.document_banner_id;

        if (
            templateDetails.fieldSettings.standardFields[
                DocumentTemplateStandardFieldsEnum.DOCUMENT_BANNER
            ].buyerSettings.required &&
            templateDetails.fieldSettings.standardFields[
                DocumentTemplateStandardFieldsEnum.DOCUMENT_BANNER
            ].buyerSettings.showField &&
            data.document_banner_id?.length === 0
        ) {
            newErrors.document_banner_id = {
                type: 'ERROR',
                message: 'Field is required',
            };
        }
    }
    if (
        side === 'BUYER' &&
        (field === CreateDocumentErrorFieldEnum.document_description ||
            field === 'ALL')
    ) {
        delete newErrors.document_description;
        if (
            templateDetails.fieldSettings.standardFields[
                DocumentTemplateStandardFieldsEnum.DOCUMENT_DESCRIPTION
            ].buyerSettings.required &&
            !Boolean(data.document_description)
        ) {
            newErrors.document_description = {
                type: 'ERROR',
                message: 'Field is required',
            };
        }
    }

    if (field === 'template_id' || field === 'entity_id' || field === 'ALL') {
        if (data.template_id === '') {
            newErrors.template_id = {
                type: 'ERROR',
                message: 'Please select a template',
            };
        } else {
            delete newErrors.template_id;
        }
    }
    if (
        field === CreateDocumentErrorFieldEnum.custom_fields ||
        field === 'ALL'
    ) {
        delete newErrors.custom_fields;

        data.custom_fields.section_list.forEach((section) => {
            section.fields.forEach((field) => {
                const templateSettings =
                    templateDetails.fieldSettings.customFields[field.name];

                if (
                    templateSettings?.buyerSettings?.required &&
                    (side === 'BUYER' ||
                        (side === 'SELLER' &&
                            templateSettings.type === 'TEMPLATE'))
                ) {
                    if (
                        newErrors.custom_fields &&
                        newErrors.custom_fields[field.name]
                    )
                        delete newErrors.custom_fields[field.name];

                    if (
                        field.value === '' ||
                        isEqual(field?.value, []) ||
                        field.value === null ||
                        field.value === 'undefined'
                    ) {
                        if (newErrors.custom_fields)
                            newErrors.custom_fields[field.name] = {
                                type: 'ERROR',
                                message: 'Field is required',
                            };
                        else {
                            newErrors.custom_fields = {
                                [field.name]: {
                                    type: 'ERROR',
                                    message: 'Field is required',
                                },
                            };
                        }
                    } else {
                        if (newErrors.custom_fields)
                            delete newErrors.custom_fields[field.name];
                    }
                }
            });
        });
    }

    if (
        field === CreateDocumentErrorFieldEnum.custom_fields_negotiate ||
        field === 'ALL'
    ) {
        delete newErrors.custom_fields_negotiate;
        data.custom_fields_negotiate.section_list.forEach((section) => {
            section.fields.forEach((field) => {
                const templateSettings =
                    templateDetails.fieldSettings.customFields[field.name];

                if (
                    templateSettings?.buyerSettings?.required &&
                    (side === 'BUYER' ||
                        (side === 'SELLER' &&
                            templateSettings.type === 'TEMPLATE'))
                ) {
                    if (
                        newErrors.custom_fields_negotiate &&
                        newErrors.custom_fields_negotiate[field.name]
                    )
                        delete newErrors.custom_fields_negotiate[field.name];

                    if (
                        field.value === '' ||
                        isEqual(field?.value, []) ||
                        field.value === null ||
                        field.value === 'undefined'
                    ) {
                        if (newErrors.custom_fields_negotiate)
                            newErrors.custom_fields_negotiate[field.name] = {
                                type: 'ERROR',
                                message: 'Field is required',
                            };
                        else {
                            newErrors.custom_fields_negotiate = {
                                [field.name]: {
                                    type: 'ERROR',
                                    message: 'Field is required',
                                },
                            };
                        }
                    } else {
                        if (newErrors.custom_fields_negotiate)
                            delete newErrors.custom_fields_negotiate[
                                field.name
                            ];
                    }
                }
            });
        });
    }

    return newErrors;
};

export const translateDocumentResponsetoPayload = (
    document_Details: IDocumentDetails,
    user_enterprise_id: string | undefined
): INewDocument => {
    let details = document_Details;
    let obj: INewDocument = {
        custom_fields: document_Details.custom_fields,
        custom_fields_negotiate: document_Details.custom_fields_negotiate,
        entity_id: details.buyer_entity_id,
        document_banner_id: document_Details.document_banner?.map(
            (attachment) => attachment.attachment_id
        ),
        document_logo_id: document_Details.document_logo?.map(
            (attachment) => attachment.attachment_id
        ),
        document_description: document_Details.document_description ?? null,
        document_validity_datetime: document_Details.document_validity_datetime
            ? moment(document_Details.document_validity_datetime).format(
                  'YYYY-MM-DDTHH:mm'
              )
            : null,
        document_name: details.document_name,
        document_id: details.document_id,
        template_id: details.additional_details?.template_id ?? '',
        seller_entity_id: details.seller_entity_id ?? null,
        document_entry_id: details.document_entry_id,
        document_history_id: details.document_history_id,
        role:
            user_enterprise_id === details.buyer_enterprise_id
                ? 'BUYER'
                : 'SELLER',
        attachment_ids: document_Details.attachments?.map(
            (attachment) => attachment.attachment_id
        ),
    };
    return obj;
};
export const translateSellerDocumentResponsetoPayload = (
    document_Details: IDocumentDetails,
    user_enterprise_id: string | undefined
): INewDocument => {
    let details = document_Details;
    let obj: INewDocument = {
        custom_fields: document_Details.custom_fields,
        document_banner_id: [],
        document_description: document_Details.document_description,
        document_logo_id: [],
        document_validity_datetime: document_Details.document_validity_datetime,
        custom_fields_negotiate: document_Details.custom_fields_negotiate,
        entity_id: details.buyer_entity_id,
        document_name: details.document_name,
        document_id: details.document_id,
        template_id: details.additional_details?.template_id ?? '',
        seller_entity_id: details.seller_entity_id ?? null,
        document_entry_id: details.document_entry_id,
        document_history_id: details.document_history_id,
        role: 'SELLER',
        attachment_ids: document_Details.attachments?.map(
            (attachment) => attachment.attachment_id
        ),
    };
    return obj;
};

export const getDocumenDisplayStatus = (backend_status: string) => {
    switch (backend_status) {
        case 'DRAFT':
            return 'Draft';
        case 'REVISING':
            return 'Revising';
        case 'SUBMITTED':
            return 'Submitted';
        default:
            return (
                backend_status.trim().charAt(0).toUpperCase() +
                backend_status.toLowerCase().trim().slice(1)
            );
    }
};

export const handleDownloadFile = async (attachmentId: string) => {
    try {
        const downloadUrl = await downloadFile(attachmentId);
        window.open(downloadUrl, '_self');
    } catch (error) {
        toast.error('Failed to download file');
    }
};

export const returnFileId = (file: IAttachment | IBulkImport) => {
    return (file as IAttachment).attachment_id;
};
