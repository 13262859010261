import { Box, IconButton, Skeleton } from '@mui/material';
import { useSelector } from 'react-redux';
import { FWAccordionDetails } from '../../../Common/FWAccordian';
import { FWTypography } from '../../../Common/FWTypograhy';
import { useRfqTemplate } from '../../../Global/Hooks/useRfqTemplate';
import { TemplateStandardFieldsEnum } from '../../../Global/Interfaces/TemplateInterface';
import { IGlobalAppStore } from '../../../Redux/Store';
import { memo } from 'react';

function BlankItemDetailsTemplate({
    animation_type,
}: {
    animation_type: 'pulse' | 'wave' | false;
}) {
    const templateId = useSelector(
        (store: IGlobalAppStore) =>
            store.EventCreationStore.eventDetails?.template_id
    );
    const entityId = useSelector(
        (store: IGlobalAppStore) =>
            store.EventCreationStore.eventDetails?.entity_id
    );
    const { templateDetails } = useRfqTemplate(
        templateId?.trim() ? templateId?.trim() : 'DEFAULT',
        entityId ?? ''
    );
    return (
        <>
            <Box
                onClick={(e) => e.stopPropagation()}
                display="grid"
                gridTemplateColumns={{
                    lg: 'repeat(6, 15%)',
                    md: 'repeat(3, 31%)',
                    sm: 'repeat(2, 47%)',
                    xs: 'repeat(1, 89%)',
                }}
                columnGap="1.25rem"
                rowGap="1rem"
                marginLeft={'1rem'}
            >
                {templateDetails.sections.ESSENTIAL_TERMS.fieldList.map(
                    (fieldItem) => {
                        if (fieldItem.fieldType === 'STANDARD') {
                            switch (fieldItem.field) {
                                case TemplateStandardFieldsEnum.QUANTITY:
                                    return (
                                        templateDetails.fieldSettings
                                            .standardFields[fieldItem.field]
                                            .buyerSettings.showField && (
                                            <Box>
                                                <Box className="flex flex--aic flex--jcsb">
                                                    <FWTypography color="text.secondary">
                                                        {
                                                            templateDetails
                                                                .fieldSettings
                                                                .standardFields[
                                                                fieldItem.field
                                                            ].label
                                                        }
                                                    </FWTypography>
                                                    {templateDetails
                                                        .fieldSettings
                                                        .standardFields
                                                        .DELIVERY_DATE
                                                        .buyerSettings
                                                        .showField && (
                                                        <Box className="flex flex--aic">
                                                            <Box
                                                                fontSize={
                                                                    '15px'
                                                                }
                                                                color="text.secondary"
                                                            >
                                                                <i className="bi bi-truck"></i>
                                                            </Box>
                                                            &nbsp;
                                                            <FWTypography
                                                                color="text.secondary"
                                                                fontSize={
                                                                    '0.75rem'
                                                                }
                                                            >
                                                                : TBD
                                                            </FWTypography>
                                                        </Box>
                                                    )}
                                                </Box>
                                                <Skeleton
                                                    animation={animation_type}
                                                    height={'3.5rem'}
                                                />
                                            </Box>
                                        )
                                    );
                                case TemplateStandardFieldsEnum.BASE_RATE:
                                    return (
                                        templateDetails.fieldSettings
                                            .standardFields[fieldItem.field]
                                            .buyerSettings.showField && (
                                            <Box>
                                                <Box className="flex flex--aic">
                                                    <FWTypography color="text.secondary">
                                                        {
                                                            templateDetails
                                                                .fieldSettings
                                                                .standardFields[
                                                                fieldItem.field
                                                            ].label
                                                        }
                                                    </FWTypography>
                                                    &nbsp;
                                                    <Box color="text.secondary">
                                                        <i className="bi bi-eye"></i>
                                                    </Box>
                                                </Box>
                                                <Skeleton
                                                    animation={animation_type}
                                                    height={'3.5rem'}
                                                />
                                            </Box>
                                        )
                                    );

                                case TemplateStandardFieldsEnum.TAX:
                                    return (
                                        templateDetails.fieldSettings
                                            .standardFields[fieldItem.field]
                                            .buyerSettings.showField && (
                                            <Box>
                                                <FWTypography color="text.secondary">
                                                    {
                                                        templateDetails
                                                            .fieldSettings
                                                            .standardFields[
                                                            fieldItem.field
                                                        ].label
                                                    }
                                                </FWTypography>
                                                <Skeleton
                                                    animation={animation_type}
                                                    height={'3.5rem'}
                                                />
                                            </Box>
                                        )
                                    );
                                case TemplateStandardFieldsEnum.ADDITIONAL_COSTS:
                                    return (
                                        templateDetails.fieldSettings
                                            .standardFields[fieldItem.field]
                                            .buyerSettings.showField && (
                                            <Box>
                                                <FWTypography color="text.secondary">
                                                    {
                                                        templateDetails
                                                            .fieldSettings
                                                            .standardFields[
                                                            fieldItem.field
                                                        ].label
                                                    }
                                                </FWTypography>
                                                <Skeleton
                                                    animation={animation_type}
                                                    height={'3.5rem'}
                                                />
                                            </Box>
                                        )
                                    );
                                case TemplateStandardFieldsEnum.DISCOUNT:
                                    return (
                                        templateDetails.fieldSettings
                                            .standardFields[fieldItem.field]
                                            .buyerSettings.showField && (
                                            <Box>
                                                <FWTypography color="text.secondary">
                                                    {
                                                        templateDetails
                                                            .fieldSettings
                                                            .standardFields[
                                                            fieldItem.field
                                                        ].label
                                                    }
                                                </FWTypography>
                                                <Skeleton
                                                    animation={animation_type}
                                                    height={'3.5rem'}
                                                />
                                            </Box>
                                        )
                                    );
                                default:
                                    return null;
                            }
                        } else {
                            return <></>;
                        }
                    }
                )}

                {(templateDetails.fieldSettings.standardFields.ADDITIONAL_COSTS
                    .buyerSettings.showField ||
                    templateDetails.fieldSettings.standardFields.DISCOUNT
                        .buyerSettings.showField ||
                    templateDetails.fieldSettings.standardFields.TAX
                        .buyerSettings.showField) && (
                    <Box>
                        <FWTypography color="text.secondary">
                            Effective rate
                        </FWTypography>
                        <Skeleton
                            animation={animation_type}
                            height={'3.5rem'}
                        />
                    </Box>
                )}
                <Box>
                    <FWTypography color="text.secondary">
                        Total amount
                    </FWTypography>
                    <Skeleton animation={animation_type} height={'3.5rem'} />
                </Box>
            </Box>
            <Box
                onClick={(e) => e.stopPropagation()}
                display="grid"
                gridTemplateColumns={{
                    lg: 'repeat(6, 15%)',
                    md: 'repeat(3, 31%)',
                    sm: 'repeat(2, 47%)',
                    xs: 'repeat(1, 89%)',
                }}
                columnGap="1.25rem"
                rowGap="1rem"
                marginLeft={'1rem'}
            >
                {templateDetails.sections.ESSENTIAL_TERMS.fieldList.map(
                    (field) => {
                        if (field.fieldType === 'CUSTOM') {
                            return (
                                <Box>
                                    <FWTypography color="text.secondary">
                                        {
                                            templateDetails.fieldSettings
                                                .customFields[field.field].label
                                        }
                                    </FWTypography>
                                    <Skeleton
                                        animation={animation_type}
                                        height={'3.5rem'}
                                    />
                                </Box>
                            );
                        } else return null;
                    }
                )}
            </Box>
            <FWAccordionDetails>
                <br />
                <br />
                <Box className="flex flex--aic">
                    <IconButton size="small" disabled>
                        <i className="bi bi-chevron-right"></i>
                    </IconButton>
                    &nbsp;
                    <FWTypography variant="h4" fontWeight={500}>
                        Vendors
                    </FWTypography>
                </Box>
                <br />
                <Box className="flex flex--aic">
                    <IconButton size="small" disabled>
                        <i className="bi bi-chevron-right"></i>
                    </IconButton>
                    &nbsp;
                    <FWTypography variant="h4" fontWeight={500}>
                        Payment and delivery terms
                    </FWTypography>
                </Box>
                <br />
                {(templateDetails.fieldSettings.standardFields[
                    TemplateStandardFieldsEnum.REQUISITION
                ].buyerSettings.showField ||
                    templateDetails.fieldSettings.standardFields[
                        TemplateStandardFieldsEnum.ADDITIONAL_INFORMATION
                    ].buyerSettings.showField) && (
                    <Box className="flex flex--aic">
                        <IconButton size="small" disabled>
                            <i className="bi bi-chevron-right"></i>
                        </IconButton>
                        &nbsp;
                        <FWTypography variant="h4" fontWeight={500}>
                            Additional details
                        </FWTypography>
                    </Box>
                )}
            </FWAccordionDetails>
        </>
    );
}

export default memo(BlankItemDetailsTemplate);
