import { cloneDeep } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import {
    HookStateValue,
    IHookState,
    useHookState,
} from '../../Common/Hooks/StateHook';
import { IAttachment } from '../../Models/RFQ.model';
import { isVerificationDetailValid } from '../Helpers/VerificationHelpers';
import {
    IVerificationDetails,
    IVerificationEntitySummary,
} from '../Interfaces/OrganizationVerificationInterface';
import {
    fetchEntityDetails,
    saveVerification,
} from '../Services/OrganizationVerificationService';

export interface IVerificationProviders {
    hookState: IHookState;
    entityDetails: IVerificationEntitySummary;
    isValidDetails: boolean;
    updateVerificationDetails: (data: VerificationUpdateType) => void;
    sumbitVerification: () => Promise<boolean>;
}

const emptyVerificationRow: IVerificationDetails = {
    verificationIdType: '',
    verificationIdValue: '',
    notes: '',
    attachments: [],
};

const initialEntitySummary: IVerificationEntitySummary = {
    entityUid: '',
    entityName: '',
    verificationStatus: '',
    verificationDetails: [
        {
            verificationIdType: '',
            verificationIdValue: '',
            notes: '',
            attachments: [],
        },
    ],
};

export enum VerificationUpdateActions {
    UPDATE_TYPE = 'UPDATE_TYPE',
    UPDATE_VALUE = 'UPDATE_VALUE',
    UPDATE_NOTES = 'UPDATE_NOTES',
    UPDATE_FILES = 'UPDATE_FILES',
    TOGGLE_FILE_DELETE = 'TOGGLE_FILE_DELETE',
    ADD_ROW = 'ADD_ROW',
    REMOVE_ROW = 'REMOVE_ROW',
}

interface IVerificationStringAction {
    idx: number;
    type:
        | VerificationUpdateActions.UPDATE_TYPE
        | VerificationUpdateActions.UPDATE_VALUE
        | VerificationUpdateActions.UPDATE_NOTES;
    value: string;
}

interface IVerificationAttachmentAction {
    idx: number;
    type: VerificationUpdateActions.UPDATE_FILES;
    value: IAttachment[];
}

interface IVerificationAddRemoveAction {
    idx: number;
    type:
        | VerificationUpdateActions.ADD_ROW
        | VerificationUpdateActions.REMOVE_ROW;
    value?: undefined;
}

interface IAttachmentToggleDeleteAction {
    idx: number;
    type: VerificationUpdateActions.TOGGLE_FILE_DELETE;
    value: number; // file index to flip toggle
}

export type VerificationUpdateType =
    | IVerificationStringAction
    | IVerificationAttachmentAction
    | IVerificationAddRemoveAction
    | IAttachmentToggleDeleteAction;

export const useVerification = (entityUid: string) => {
    const { hookState, updateHookState } = useHookState(HookStateValue.LOADING);

    const [entityDetails, setEntityDetails] =
        useState<IVerificationEntitySummary>(cloneDeep(initialEntitySummary));

    const [isValidDetails, setIsValidDetails] = useState<boolean>(true);

    const getEntityDetails = useCallback(async () => {
        updateHookState(HookStateValue.LOADING);
        try {
            const entityDetails = await fetchEntityDetails(entityUid);
            setEntityDetails(entityDetails);
            updateHookState(HookStateValue.READY);
        } catch (error) {
            updateHookState(HookStateValue.ERROR);
        }
    }, [entityUid, updateHookState]);

    const updateVerificationDetails = ({
        idx,
        type,
        value,
    }: VerificationUpdateType) => {
        setEntityDetails((prevDetails) => {
            const verification: IVerificationDetails =
                prevDetails.verificationDetails[idx];
            switch (type) {
                case VerificationUpdateActions.UPDATE_TYPE:
                    verification.verificationIdType = value;
                    break;
                case VerificationUpdateActions.UPDATE_VALUE:
                    verification.verificationIdValue = value;
                    break;
                case VerificationUpdateActions.UPDATE_NOTES:
                    verification.notes = value;
                    break;
                case VerificationUpdateActions.UPDATE_FILES:
                    verification.attachments = [
                        ...verification.attachments,
                        ...value,
                    ];
                    break;
                case VerificationUpdateActions.TOGGLE_FILE_DELETE:
                    if (verification.attachments[value].isNew) {
                        verification.attachments.splice(value, 1);
                    } else {
                        verification.attachments[value].to_be_deleted =
                            !verification.attachments[value].to_be_deleted;
                    }
                    break;
                case VerificationUpdateActions.ADD_ROW:
                    prevDetails.verificationDetails.push(
                        cloneDeep(emptyVerificationRow)
                    );
                    break;
                case VerificationUpdateActions.REMOVE_ROW:
                    prevDetails.verificationDetails.splice(idx, 1);
                    break;
            }
            return { ...prevDetails };
        });
    };

    const sumbitVerification = async (): Promise<boolean> => {
        try {
            await saveVerification(
                entityDetails.verificationDetails,
                entityUid
            );
            getEntityDetails();
            return true;
        } catch (error) {
            return false;
        }
    };

    useEffect(() => {
        let errors = false;

        for (let i = 0; i < entityDetails.verificationDetails.length; i++) {
            const detail = entityDetails.verificationDetails[i];
            if (!isVerificationDetailValid(detail)) {
                errors = true;
                break;
            }
        }

        setIsValidDetails(!errors);
    }, [entityDetails]);

    useEffect(() => {
        getEntityDetails();
    }, [getEntityDetails]);

    const useVerificationProvider: IVerificationProviders = {
        hookState,
        entityDetails,
        isValidDetails,
        updateVerificationDetails,
        sumbitVerification,
    };

    return useVerificationProvider;
};
