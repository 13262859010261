import { cloneDeep } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useGetAllCurrencyOptionsQuery } from '../../../AdditionalCost/Services/additionalCosts.services';
import { HookStateValue, useHookState } from '../../../Common/Hooks/StateHook';
import { transformPaymentTermsFromBackendToFrontend } from '../../../Global/Helpers/paymentTermsHelpers';
import { IGlobalAppStore } from '../../../Redux/Store';
import { convertTemplateDetailsToPayload } from '../../Helpers/itemAnalyticsTemplateHelpers';
import {
    CART_ITEM_PER_PAGE,
    ItemCartModuleSectionEnum,
} from '../../Interfaces/ItemAnalyticsInterfaces';
import { CartDashboardSettings } from '../../Interfaces/itemCartItemInterfaces';
import { useGetItemCartInformationMutation } from '../../Services/ItemAnalytics.service';
import { updateCartDataStore } from '../../Slices/ItemAnalyticsCartSlice';
import { cartTabType } from './useItemCartDashboard';
import { useItemCartTemplate } from './useItemCartTemplate';
import { AuthContext } from '../../../Contexts/AuthContext';

export default function useItemCart(cartId: string, buyerEntityid: string) {
    const { hookState, updateHookState } = useHookState(HookStateValue.LOADING);
    const [canCreateEvent, setCanCreateEvent] = useState(false);
    const [currentCartSection, setCurrentCartSection] =
        useState<ItemCartModuleSectionEnum>(ItemCartModuleSectionEnum.CART);

    const { data: allCurrencies } = useGetAllCurrencyOptionsQuery({});
    const { checkPermission } = React.useContext(AuthContext);

    const itemCount = useSelector(
        (store: IGlobalAppStore) =>
            store.ItemCartStore.cartDetails.filter((item) =>
                currentCartSection === ItemCartModuleSectionEnum.CART
                    ? !item.save_for_later
                    : currentCartSection ===
                      ItemCartModuleSectionEnum.SAVE_FOR_LATER
                    ? item.save_for_later
                    : true
            ).length
    );
    const [itemCountInCurrentTab, setItemCountInCurrentTab] = useState(0);

    useEffect(() => {
        setCanCreateEvent(
            checkPermission('BUYER', 'EVENT', 'EDIT', buyerEntityid)
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const updateCurrentCartSection = (
        newSection: ItemCartModuleSectionEnum
    ) => {
        setCurrentCartSection(newSection);
    };

    const allItems = useSelector(
        (store: IGlobalAppStore) => store.ItemCartStore.cartDetails
    );

    const [dashboardSettings, setDashboardSettings] =
        useState<CartDashboardSettings>({
            currentPage: 1,
            itemsPerPage: CART_ITEM_PER_PAGE,
            searchText: '',
            totalPages: 1,
        });

    const dispatch = useDispatch();

    useEffect(() => {
        if (allCurrencies) {
            dispatch(
                updateCartDataStore({
                    type: 'SET_ALL_CURRENCIES',
                    value: allCurrencies,
                })
            );
        }
    }, [allCurrencies, dispatch]);

    const updateDashboardSettings = (
        key: keyof CartDashboardSettings,
        value: any
    ) => {
        let newDashboardSettings = cloneDeep(dashboardSettings);

        newDashboardSettings = {
            ...newDashboardSettings,
            [key]: value,
        };

        setDashboardSettings(newDashboardSettings);
    };

    const { hookState: templateHookState, templateDetails } =
        useItemCartTemplate(buyerEntityid);

    const [fetchCartInformation, { data ,isLoading}] = useGetItemCartInformationMutation({
        fixedCacheKey: 'cartItemsFixedCacheKey',
    });

    useEffect(() => {
        if (data) {
            setItemCountInCurrentTab(data.data.length);
        }
    }, [data]);

    const getDataFromMutation = useCallback(() => {
        if (data && templateHookState.state !== HookStateValue.LOADING) {
            setDashboardSettings((prev) => ({
                currentPage: data.metadata.current_page,
                itemsPerPage: CART_ITEM_PER_PAGE,
                searchText: prev.searchText,
                totalPages: data.metadata.total_pages,
            }));

            updateHookState(HookStateValue.LOADING);
            const itemDetailsFromResponse = data.data;

            const itemDetails = convertTemplateDetailsToPayload(
                templateDetails,
                itemDetailsFromResponse
            );

            const allItemIds = data.data.map((item) => item.cart_item_id);

            const updatedStructureOfItems = itemDetails.map((item) => ({
                ...item,
                paymentTerms: transformPaymentTermsFromBackendToFrontend({
                    prepayment_percentage: item.prepayment_percentage,
                    deliverables_payment_terms:
                        item.deliverables_payment_terms[0],
                    payment_terms: item.payment_terms,
                }),
            }));

            batch(() => {
                dispatch(
                    updateCartDataStore({
                        type: 'UPDATE_ALL_ITEM_IDS',
                        value: allItemIds,
                    })
                );
                dispatch(
                    updateCartDataStore({
                        type: 'UPDATE_CART_DETAILS',
                        value: updatedStructureOfItems,
                    })
                );
            });
            updateHookState(HookStateValue.READY);
        }
    }, [
        data,
        dispatch,
        templateDetails,
        templateHookState.state,
        updateHookState,
    ]);

    useEffect(() => {
        getDataFromMutation();
    }, [getDataFromMutation]);

    const getItemCartInformation = useCallback(() => {
        let tab = cartTabType.ALL;
        if (currentCartSection === ItemCartModuleSectionEnum.CART) {
            tab = cartTabType.CART;
        } else if (
            currentCartSection === ItemCartModuleSectionEnum.SAVE_FOR_LATER
        ) {
            tab = cartTabType.SAVE_FOR_LATER;
        }
        fetchCartInformation({
            items_per_page: dashboardSettings.itemsPerPage,
            page_number: dashboardSettings.currentPage,
            search_text: dashboardSettings.searchText,
            query_data: {
                cart_id: cartId,
            },
            sort_fields: [],
            tab: tab,
        });
    }, [
        cartId,
        currentCartSection,
        dashboardSettings.currentPage,
        dashboardSettings.itemsPerPage,
        dashboardSettings.searchText,
        fetchCartInformation,
    ]);

    useEffect(() => {
        getItemCartInformation();
    }, [getItemCartInformation]);

    return {
        templateDetails,
        dashboardSettings,
        hookState,
        currentCartSection,
        allItems,
        itemCount,
        itemCountInCurrentTab,
        updateCurrentCartSection,
        getItemCartInformation,
        updateDashboardSettings,
        canCreateEvent,
        isLoading
    };
}
