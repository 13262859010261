import { Box, IconButton } from '@mui/material';
import { useState } from 'react';
import { FWTooltip } from '../../Common/FWCustomTooltip';
import { FWTypography } from '../../Common/FWTypograhy';
import DotBadge from '../../Components/Shared/DotBadge';
import ViewAttachmentsButtonAndPopover from '../../Global/Containers/ViewAttachmentsButtonAndPopover';
import PrepaymentInvoicesPopup from '../../Payments/Pages/PrepaymentInvoicesPopup';
import {
    IBuyerInvoiceDashboardInterface,
    IInvoiceDashboardInterface,
} from '../Interfaces/Shared.model';

export const InvoiceIdCellSeller = ({
    data,
    context,
}: {
    data: IInvoiceDashboardInterface;
    context: any;
}) => {
    // const { handlePrepayInvoiceClick } = context;
    const [prepayInvoicePopupForPO, setPrepayInvoicePopupForPO] =
        useState(false);
    return (
        <div className="flex flex--aic" style={{ width: '100%' }}>
            <FWTypography color="text.secondary" sx={{ maxWidth: '100%' }}>
                {data.custom_invoice_id}
            </FWTypography>
            {data.invoice_type === 'PREPAYMENT_INVOICE' &&
                data.seller_prepayment_invoice_count > 0 && (
                    <>
                        &nbsp;
                        <IconButton
                            id="sellerInvoiceDashboard_prepaymentInvoiceButton"
                            color="primary"
                            ref={(ref) => {
                                if (!ref) return;
                                ref.onclick = (e: any) => {
                                    e.stopPropagation();
                                    setPrepayInvoicePopupForPO(true);
                                };
                            }}
                        >
                            <i className="bi bi-file-earmark-ruled "></i>
                        </IconButton>
                        {prepayInvoicePopupForPO && (
                            <PrepaymentInvoicesPopup
                                open={prepayInvoicePopupForPO}
                                poUid={data.po_id}
                                handleClose={() => {
                                    setPrepayInvoicePopupForPO(false);
                                }}
                                user_type="SELLER"
                            />
                        )}
                    </>
                )}
            &nbsp;
            <div className="ml--5">
                <ViewAttachmentsButtonAndPopover
                    attachments={data.attachments || []}
                    clipIconSize={18}
                    hideBadge={true}
                    id="sellerInvoiceDashboard_InvoiceAttachments"
                />
            </div>
            &nbsp;
            {data.invoice_hold_or_termination !== null && (
                <FWTooltip
                    title={
                        data.invoice_hold_or_termination === 'HOLD'
                            ? 'Invoice item(s) on hold'
                            : 'Termination requested for invoice item(s)'
                    }
                >
                    <Box sx={{ lineHeight: 0, ml: '5px' }}>
                        <DotBadge
                            color={
                                data.invoice_hold_or_termination === 'HOLD'
                                    ? 'warning'
                                    : 'error'
                            }
                        />
                    </Box>
                </FWTooltip>
            )}
        </div>
    );
};

export const InvoiceIdCellBuyer = ({
    data,
    context,
}: {
    data: IBuyerInvoiceDashboardInterface;
    context: any;
}) => {
    // const { handlePrepayInvoiceClick } = context;
    const [prepayInvoicePopupForPO, setPrepayInvoicePopupForPO] =
        useState(false);

    return (
        <div
            className="flex flex--aic"
            style={{
                width: '100%',
            }}
        >
            <FWTypography color="text.secondary" sx={{ maxWidth: '100%' }}>
                {data.custom_invoice_id === null
                    ? data.reference_id
                    : data.custom_invoice_id}
            </FWTypography>
            {data.invoice_type === 'PREPAYMENT_INVOICE' &&
                data.seller_prepayment_invoice_count > 0 && (
                    <>
                        &nbsp;
                        <IconButton
                            id="buyerInvoiceDashboard_sellerPrepaymentInvoiceButton"
                            color="primary"
                            ref={(ref) => {
                                if (!ref) return;
                                ref.onclick = (e: any) => {
                                    e.stopPropagation();
                                    setPrepayInvoicePopupForPO(true);
                                };
                            }}
                        >
                            <i className="bi bi-file-earmark-ruled "></i>
                        </IconButton>
                        {prepayInvoicePopupForPO && (
                            <PrepaymentInvoicesPopup
                                open={prepayInvoicePopupForPO}
                                poUid={data.po_id}
                                handleClose={() => {
                                    setPrepayInvoicePopupForPO(false);
                                }}
                                user_type={'BUYER'}
                            />
                        )}
                    </>
                )}
            &nbsp;
            {data.inovice_item_hold_or_termination !== null && (
                <FWTooltip
                    title={
                        data.inovice_item_hold_or_termination === 'HOLD'
                            ? 'Invoice item(s) on hold'
                            : 'Termination requested for invoice item(s)'
                    }
                >
                    <Box sx={{ lineHeight: 0 }}>
                        <DotBadge
                            color={
                                data.inovice_item_hold_or_termination === 'HOLD'
                                    ? 'warning'
                                    : 'error'
                            }
                        />
                    </Box>
                </FWTooltip>
            )}
            <div className="ml--5">
                <ViewAttachmentsButtonAndPopover
                    attachments={data.attachments || []}
                    clipIconSize={18}
                    hideBadge={true}
                    id={'buyerInvoiceDashboard_InvoiceAttachments'}
                />
            </div>
        </div>
    );
};
