import React from 'react';

export interface INavContext {
    updateCurrentTab(newTab: string): void;
    currentTab: string;
}

export const NavContext = React.createContext<INavContext>({
    currentTab: '',
    updateCurrentTab: (newTab: string) => {},
});

function NavContextProvider({
    children,
    initialTab,
}: {
    children: React.ReactNode;
    initialTab: string;
}) {
    const [currentTab, setCurrentTab] = React.useState('');

    const updateCurrentTab = (newTab: string) => setCurrentTab(newTab);

    React.useEffect(() => setCurrentTab(initialTab), [initialTab]);

    return (
        <NavContext.Provider
            value={{
                currentTab: currentTab,
                updateCurrentTab: updateCurrentTab,
            }}
        >
            {children}
        </NavContext.Provider>
    );
}

export default NavContextProvider;
