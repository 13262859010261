import { DialogContent, Grid } from '@mui/material';
import { FWPopupWithChildren } from '../../../Common/FWPopupWithChildren';
import ProjectOnTimeSubmission from './ProjectOnTimeSubmission';
import ProjectPieChart from './ProjectPieChart';
import ProjectTimeSpentByTeams from './ProjectTimeSpentByTeams';
import UserProjectByCost from './UserProjectByCost';

export interface IsetOpenProjetAnalyticsGraphProps {
    open: boolean;
    handleClose: () => void;
    title: string;
}

export const PROJECT_GRAPH_DIMENSIONS = '550px';

export default function ProjetAnalyticsGraph({
    handleClose,
    open,
    title,
}: IsetOpenProjetAnalyticsGraphProps) {
    return (
        <FWPopupWithChildren
            open={open}
            handleClose={handleClose}
            title={title}
            size="fullscreen"
            // popupWidth={'90vw'}
        >
            <DialogContent>
                <Grid
                    container
                    gap={2}
                    rowGap={5}
                    justifyContent={'space-between'}
                >
                    <ProjectOnTimeSubmission />
                    <ProjectPieChart />
                    <ProjectTimeSpentByTeams />
                    <UserProjectByCost />
                </Grid>
            </DialogContent>
        </FWPopupWithChildren>
    );
}
