import { Grid } from '@mui/material';
import { FWCustomGridKeyValue } from '../../../../Common/FWCustomGridKeyValue';
import { FWTypography } from '../../../../Common/FWTypograhy';
import { InfoBlock } from '../../Containers/ItemDirectory/ItemDetailsContainer';
import {
    IItemDetails,
    IItemMeasurementUnits,
} from '../../Interfaces/ItemDirectoryInterface';

interface IItemDetailSummaryProps {
    data: IItemDetails;
    measurements: IItemMeasurementUnits;
}

const ItemDetailSummary = ({ data, measurements }: IItemDetailSummaryProps) => {
    return (
        <>
            <Grid container>
                <InfoBlock item xs={3}>
                    <FWCustomGridKeyValue title="ID">
                        {data.itemCode}
                    </FWCustomGridKeyValue>
                </InfoBlock>
                <InfoBlock item xs={3}>
                    <FWCustomGridKeyValue title="Item name">
                        {data.itemName}
                    </FWCustomGridKeyValue>
                </InfoBlock>
                {data.description && (
                    <InfoBlock item xs={6}>
                        <FWCustomGridKeyValue title="Item description">
                            {data.description}
                        </FWCustomGridKeyValue>
                    </InfoBlock>
                )}
                {data.notes && (
                    <InfoBlock item xs={12}>
                        <FWCustomGridKeyValue title="Global notes">
                            {data.notes}
                        </FWCustomGridKeyValue>
                    </InfoBlock>
                )}
            </Grid>
            <Grid container>
                {data.measurementUnits.length > 0 && (
                    <InfoBlock item xs={4}>
                        <FWCustomGridKeyValue title="Standard measurement units">
                            {data.measurementUnits
                                .map(
                                    (unit) =>
                                        measurements[unit.id] &&
                                        measurements[unit.id].name
                                )
                                .join(', ')}
                        </FWCustomGridKeyValue>
                    </InfoBlock>
                )}
                {data.standardTerms.lead &&
                    data.standardTerms.prePayment &&
                    data.standardTerms.payment && (
                        <InfoBlock item xs={8}>
                            <FWTypography>Standard Terms</FWTypography>
                            <Grid container className="pl--15">
                                {data.standardTerms.lead && (
                                    <Grid item xs={4}>
                                        <FWCustomGridKeyValue title="Lead time">
                                            {data.standardTerms.lead.value}{' '}
                                            <span
                                                style={{
                                                    textTransform: 'lowercase',
                                                }}
                                            >
                                                {data.standardTerms.lead.option}
                                            </span>
                                        </FWCustomGridKeyValue>
                                    </Grid>
                                )}
                                {data.standardTerms.prePayment && (
                                    <Grid item xs={4}>
                                        <FWCustomGridKeyValue title="Prepayment">
                                            {
                                                data.standardTerms.prePayment
                                                    .value
                                            }
                                            %
                                        </FWCustomGridKeyValue>
                                    </Grid>
                                )}
                                {data.standardTerms.payment &&
                                    data.standardTerms.payment.value && (
                                        <Grid item xs={4}>
                                            <FWCustomGridKeyValue title="Payment terms">
                                                {
                                                    data.standardTerms.payment
                                                        .value
                                                }
                                                {' days from '}
                                                {data.standardTerms.payment
                                                    .option === 'RECEIPT_DATE'
                                                    ? 'receipt date'
                                                    : data.standardTerms.payment
                                                          .option ===
                                                      'DISPATCH_DATE'
                                                    ? 'dispatch date'
                                                    : data.standardTerms.payment
                                                          .option ===
                                                      'INVOICE_DATE'
                                                    ? 'invoice date'
                                                    : ''}
                                            </FWCustomGridKeyValue>
                                        </Grid>
                                    )}
                            </Grid>
                        </InfoBlock>
                    )}
            </Grid>

            {(+(data?.buyerPricingInformation?.price || 0) > 0 ||
                +(data?.sellerPricingInformation?.price || 0) > 0) && (
                <Grid container>
                    {+(data?.buyerPricingInformation?.price || 0) > 0 && (
                        <InfoBlock item xs={3}>
                            <FWCustomGridKeyValue title="Buyer price">
                                {`${
                                    data.buyerPricingInformation
                                        ?.currency_symbol || '$'
                                }${data.buyerPricingInformation?.price}`}
                            </FWCustomGridKeyValue>
                        </InfoBlock>
                    )}
                    {+(data?.sellerPricingInformation?.price || 0) > 0 && (
                        <InfoBlock item xs={3}>
                            <FWCustomGridKeyValue title="Seller price">
                                {`${
                                    data.sellerPricingInformation
                                        ?.currency_symbol || '$'
                                }${data.sellerPricingInformation?.price}`}
                            </FWCustomGridKeyValue>
                        </InfoBlock>
                    )}
                </Grid>
            )}

            {data.tags.length > 0 && (
                <Grid container>
                    <InfoBlock item xs={12}>
                        <FWCustomGridKeyValue title="Item tags">
                            {data.tags.join(', ')}
                        </FWCustomGridKeyValue>
                    </InfoBlock>
                </Grid>
            )}

            {(data?.specifications?.length ?? 0) > 0 && (
                <>
                    <FWTypography sx={{ mb: '5px', fontWeight: 500 }}>
                        Specifications
                    </FWTypography>
                    <Grid container columnSpacing={2}>
                        {data.specifications?.map((spec) => {
                            return (
                                <InfoBlock
                                    item
                                    key={spec.name + spec.value}
                                    xs={3}
                                >
                                    <FWCustomGridKeyValue title={spec.name}>
                                        {spec.value.join(' / ')}
                                    </FWCustomGridKeyValue>
                                </InfoBlock>
                            );
                        })}
                    </Grid>
                </>
            )}

            {data.customIds.length > 0 && (
                <>
                    <FWTypography sx={{ mb: '5px', fontWeight: 500 }}>
                        Custom IDs
                    </FWTypography>
                    <Grid container columnSpacing={2}>
                        {data.customIds.map((customId) => {
                            return (
                                <InfoBlock
                                    item
                                    key={customId.name + customId.value}
                                    xs={3}
                                >
                                    <FWCustomGridKeyValue title={customId.name}>
                                        {customId.value}
                                    </FWCustomGridKeyValue>
                                </InfoBlock>
                            );
                        })}
                    </Grid>
                </>
            )}
        </>
    );
};

export default ItemDetailSummary;
