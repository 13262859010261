export enum UserRoles {
    ADMIN = 'ADMIN',
    USER = 'USER',
    EMPTY = '',
    ADMIN_VIEW = 'ADMIN_VIEW',
}

export enum ProjectPermissions {
    PROJECT_VIEW = 'PROJECT_VIEW',
    PROJECT_EDIT = 'PROJECT_EDIT',
    PROJECT_CREATE = 'PROJECT_CREATE',
    PROJECT_ASSIGN_USERS = 'PROJECT_ASSIGN_USERS',
    PROJECT_ASSIGN_MANAGERS = 'PROJECT_ASSIGN_MANAGERS',
    PROJECT_TEMPALTE = 'PROJECT_TEMPALTE',
}

export const ProjectPermissionLabelEnum = {
    [ProjectPermissions.PROJECT_VIEW]: 'View',
    [ProjectPermissions.PROJECT_EDIT]: 'Edit',
    [ProjectPermissions.PROJECT_CREATE]: 'Create',
    [ProjectPermissions.PROJECT_ASSIGN_USERS]: 'Assign Users',
    [ProjectPermissions.PROJECT_ASSIGN_MANAGERS]: 'Assign Managers',
    [ProjectPermissions.PROJECT_TEMPALTE]: 'Template',
};

export enum QuotePermissions {
    QUOTE_VIEW = 'QUOTE_VIEW',
    QUOTE_EDIT = 'QUOTE_EDIT',
    QUOTE_CREATE = 'QUOTE_CREATE',
    QUOTE_ASSIGN_USERS = 'QUOTE_ASSIGN_USERS',
    QUOTE_ASSIGN_MANAGERS = 'QUOTE_ASSIGN_MANAGERS',
    QUOTE_TEMPALTE = 'QUOTE_TEMPALTE',
}

export const QuotePermissionLabelEnum = {
    [QuotePermissions.QUOTE_VIEW]: 'View',
    [QuotePermissions.QUOTE_EDIT]: 'Edit',
    [QuotePermissions.QUOTE_CREATE]: 'Create',
    [QuotePermissions.QUOTE_ASSIGN_USERS]: 'Assign Users',
    [QuotePermissions.QUOTE_ASSIGN_MANAGERS]: 'Assign Managers',
    [QuotePermissions.QUOTE_TEMPALTE]: 'Template',
};

export enum UserStates {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
    INVITED = 'INVITED',
    SIGNED_UP = 'SIGNED_UP',
    REQUESTED = 'REQUESTED',
    RESCINDED = 'RESCINDED',
    ALL = 'ALL',
}

// Used for showing users list
export interface IUserSummary {
    id: number;
    userId: string;
    fullName: string;
    email: string;
    status: string;
    designation: string;
    role: UserRoles;
    totalPermissions: number;
    modifiedDateTime: string;
    managersCount: number;
    enterprise_name: string;
}

// Used for adding a user

export interface IUserInviteInfo {
    email: string;
    fullName: string;
    designation?: string;
    role: UserRoles;
    skip_onboarding: boolean;
}

// Used for viewing user detail
export interface IUserDetails {
    userId: string;
    fullName: string;
    email: string;
    designation: string;
    phoneNumber: string;
    role: UserRoles;
    status: UserStates;
}

export interface IUserManager {
    id: number;
    managerId: string;
    managerName: string;
}

export interface IUserEntity {
    id?: number | string;
    entityId: string;
    entityName: string;
}

//permissions for the portals
export interface IPermissionGroup {
    moduleGroupName: string; //BUYER OR SELLER
    moduleGroupNameKey: string;
    checked: boolean; // toggle on/off
    modulesList: IUserPermissions[];
}

export interface IUserPermissions {
    // permission for different modules in sidebar
    moduleName: string;
    moduleKey: string;
    description: string[];
    modulePermissions: IModulePermissions[]; //permissions within each module
}

export interface IModulePermissions {
    permissionName: string;
    permissionValue: string;
    permissionAccess: boolean;
    moduleKey: string;
}

export interface IPermissionDependencyMap {
    [key: string]: string[];
}

export interface ISelectedPermissions {
    [module: string]: {
        value: string; //name of the permission
        access: boolean;
        // Idx of the portal (buyer or seller) to which this permission belongs
        portalIndex: number;
        disabled?: boolean;
    };
}

export interface IUserInviteSignupDetails {
    invitedByEnterprise: string;
    invitedByUser: string;
    invitedByUserEmail: string;
    inviteValid: boolean;
    inviteType: string;
    inviteStatus: string;
    fullName: string;
    email: string;
    password: string;
    confirmPassword: string;
    userName: string;
    invitationCode: string;
    invitationUid: string;
    invitedFor: string;
    invite_type: 'BUYER' | 'SELLER';
}

export interface IUserEntityPermissionResponse {
    entity_permission: EntityPermission[];
}

export interface EntityPermission {
    type: string;
    module_permissions: ModulePermission[];
}

export interface ModulePermission {
    module: string;
    permissions: Permission[];
}

export interface Permission {
    name: string;
    display: string;
    access: boolean;
}
