import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { HookStateValue, useHookState } from '../../Common/Hooks/StateHook';
import { TFeatureNames, TFeatures } from '../Interfaces/FeaturesInterface';

export const useFeatureAccess = () => {
    const { hookState, updateHookState } = useHookState(HookStateValue.LOADING);
    const availableFeaturesData: TFeatures = useSelector(
        ({ AvailableFeaturesStore }: { AvailableFeaturesStore: TFeatures }) =>
            AvailableFeaturesStore
    );
    const isFeatureAvailable = useCallback(
        (feature: TFeatureNames) => availableFeaturesData.includes(feature),
        [availableFeaturesData]
    );

    useEffect(() => {
        if (availableFeaturesData.length > 0) {
            updateHookState(HookStateValue.READY);
        }
    }, [availableFeaturesData.length, updateHookState]);

    return {
        hookState,
        updateHookState,
        isFeatureAvailable,
    };
};
