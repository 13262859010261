import FWPopover from '../../../../Common/FWPopover';
import { FWTypography } from '../../../../Common/FWTypograhy';
import { IItemSummary } from '../../Interfaces/ItemDirectoryInterface';

interface IItemPreferredVendorsPopoverProps {
    data: IItemSummary;
    open: boolean;
    anchor: HTMLElement | null;
    handleClose: () => void;
}

const ItemPreferredVendorsPopover = ({
    data,
    open,
    anchor,
    handleClose,
}: IItemPreferredVendorsPopoverProps) => {
    return (
        <>
            <FWPopover
                open={open}
                anchorEl={anchor}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                onClose={handleClose}
            >
                <div className="p--5">
                    <FWTypography variant="h5" sx={{ fontWeight: '500' }}>
                        Preferred Vendors
                    </FWTypography>
                    <FWTypography>
                        {data.preferredVendors.map((vendor, index) => (
                            <FWTypography>
                                {index + 1}. {vendor.seller_entity_name}
                                {index + 1 !== data.preferredVendors.length ? (
                                    <br />
                                ) : (
                                    ''
                                )}
                            </FWTypography>
                        ))}
                    </FWTypography>
                </div>
            </FWPopover>
        </>
    );
};

export default ItemPreferredVendorsPopover;