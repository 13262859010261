import { FWTooltip } from '../../Common/FWCustomTooltip';
import FWIcon from '../../Common/FWIcon';

interface IGRNStatusIconProps {
    acceptedQty: number;
    rejectedQty: number;
    iconName: string;
    className?: string;
}

export const getGRNStatus = (acceptedQty: number, rejectedQty: number) => {
    let [color, status] = ['', ''];
    if (acceptedQty > 0 && rejectedQty === 0) {
        color = 'success.main';
        status = 'Accepted';
    }
    if (acceptedQty > 0 && rejectedQty > 0) {
        color = 'warning.main';
        status = 'Partially accepted';
    }
    if (acceptedQty === 0 && rejectedQty > 0) {
        color = 'error.main';
        status = 'Rejected';
    }
    return { color, status };
};

const GRNStatusIcon = ({
    acceptedQty,
    rejectedQty,
    iconName,
    className,
}: IGRNStatusIconProps) => {
    let { color, status } = getGRNStatus(acceptedQty, rejectedQty);

    return (
        <FWTooltip title={status}>
            <div
                style={{
                    lineHeight: 1,
                }}
            >
                <FWIcon name={iconName} className={className} color={color} />
            </div>
        </FWTooltip>
    );
};

export default GRNStatusIcon;
