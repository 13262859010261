import { QCState, QCType } from '../Interfaces/QCInterface';

export const qcRejectionMessages = [
    'Item quality issue',
    'Damaged items',
    'Damaged packaging',
    'Missing items',
    'Labelling mismatch',
    'Incorrect grade',
    'Item expiry passed',
    'Wrong manufacturer',
];

export const qcTypeMap: { [key: string]: string } = {
    [QCState.PRIMARY]: 'PRIMARY',
    [QCState.SECONDARY]: 'SECONDARY',
    [QCState.PRODUCTION_LINE]: 'PRODUCTION_LINE',
};

export const qcTypeMapFromService: { [key: string]: string } = {
    [QCType.PRIMARY]: QCState.PRIMARY,
    [QCType.SECONDARY]: QCState.SECONDARY,
    [QCType.PRODUCTION_LINE]: QCState.PRODUCTION_LINE,
};

export const QCStateMapToService: { [key: string]: string } = {
    [QCState.PRIMARY]: QCType.PRIMARY,
    [QCState.SECONDARY]: QCType.SECONDARY,
    [QCState.PRODUCTION_LINE]: QCType.PRODUCTION_LINE,
};
