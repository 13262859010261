import { cloneDeep, isEmpty } from 'lodash';
import moment from 'moment';
import {
    calculateTotalAdditionalCostAndDeviation,
    convertAdditionalCostBackendToFrontend,
} from '../../AdditionalCost/helpers';
import { leadTimeSurpassed } from '../../Components/Shared/Utils';
import { IBuyerEnterpriseItemDetails } from '../../Events/Interfaces/Buyer/RFQ.model';
import { isValueValidForStringArrNull } from '../../Global/Helpers/helpers';
import {
    DELIVERABLE_LENGTH_LIMIT,
    validatePaymentTerms,
} from '../../Global/Helpers/paymentTermsHelpers';
import {
    BackendPaymentType,
    BackendPoItemsPaymentTerms,
    StandardPaymentReferenceDateEnum,
} from '../../Global/Interfaces/PaymentTermsInterface';
import { PeriodEnum } from '../../Global/Interfaces/PeriodInterface';
import { CartItemBlankDeliveryInformation } from '../../ItemAnalytics/Constants/CartItemConstants';
import { IItemCartLevelCustomField } from '../../ItemAnalytics/Interfaces/itemCartItemInterfaces';
import {
    IRequisitionItemFieldValidation,
    IRequisitionItemValidation,
    updateRequisitionItemDetailsActionType,
} from '../Interfaces/Requisitions.interface';
import {
    IRequisitionNewTemplate,
    // IRequisitionNewTemplateSections,
    RequisitionNewTemplateStandardFieldsEnum,
} from '../Interfaces/RequisitionTemplateInterfacesNew';
import { RequisitionItem } from '../Slices/RequisitionSlice';

export interface NewRequisitionItemForPayload {
    //attachments_ids:string[]
    enterprise_item_id: string;
    total: number; //
    custom_item_name: string; //
    cart_id: string | null;
    item_additional_details?: string | null; //
    measurement_unit_id: string | null; //
    quantity: number | string | null; //
    quantity_tolerance_percentage: number; //
    desired_price: number | null; //
    delivery_schedule_items: {
        //
        delivery_schedule_item_id: string | null;
        requisition_item_id: string | null;
        parent_delivery_schedule_item_id: string | null;
        delivery_date: string | null;
        quantity: number | string;
        project_id: string | null;
        cost_centre_id: string | null;
        general_ledger_id: string | null;
    }[];
    deliverables_payment_terms: BackendPoItemsPaymentTerms[]; //
    shipping_address_id: string | null; //
    payment_terms: {
        //
        applied_from: string;
        term: number;
        period: PeriodEnum | '';
    } | null;
    prepayment_percentage: number | null; //
    seller_entity_ids: string[]; //
    seller_contact_ids: string[]; //
    currency_code_id: string; //
    attributes?: string[];
    additional_charges?: any[]; //
    taxes?: any[]; //
    additional_costs?: any[]; //
    discounts?: any[]; //
    lead_time?: number | null; //
    lead_time_period?: string | null; //
    payment_type?: BackendPaymentType | null; //
    incoterm_id?: string | null; //
    custom_fields?: { section_list: any[] }; //
    custom_fields_negotiate?: { section_list: any[] }; //
    questions?: { section_list: any[] }; //
}

export const translateEnterpriseItemToRequisitionItem = (
    enterprise_item: IBuyerEnterpriseItemDetails,
    buyerEntityId: string,
    defaultCurrencyId: string,
    cart_id: string | null,
    templateDetails: IRequisitionNewTemplate
): NewRequisitionItemForPayload => {
    return {
        total: 0,
        enterprise_item_id: enterprise_item.enterprise_item_id,
        custom_item_name: enterprise_item.name,
        cart_id: cart_id ? cart_id : null,
        measurement_unit_id:
            enterprise_item.measurement_units.item_measurement_units[0]
                ?.measurement_unit_id ?? null,
        quantity: '0',
        desired_price: 0,
        delivery_schedule_items: CartItemBlankDeliveryInformation.map(
            (delivery) => ({
                ...delivery,
                quantity: delivery.quantity ? String(delivery.quantity) : '0',
            })
        ),
        custom_fields: {
            section_list: getCustomFieldsFromTemplate(templateDetails),
        },
        quantity_tolerance_percentage: 0,
        payment_terms: {
            applied_from: StandardPaymentReferenceDateEnum.INVOICE_DATE as any,
            period: PeriodEnum.MONTHS,
            term: 1,
        },
        item_additional_details: '',
        payment_type:
            templateDetails.fieldSettings.standardFields['PAYMENT_TERMS']
                .paymentTermOptions.paymentType === 'invoiceItemValues'
                ? 'PER_INVOICE_ITEM'
                : 'PER_DELIVERABLE',
        prepayment_percentage: 0,
        deliverables_payment_terms: [],
        shipping_address_id: null,
        incoterm_id: 'bbb27fda-712f-4aa2-b42b-386fde9c2b50',
        lead_time: 0,
        lead_time_period: 'WEEKS',
        seller_contact_ids: [],
        seller_entity_ids: [],
        currency_code_id:
            defaultCurrencyId ??
            enterprise_item.buyer_pricing_information?.currency_code_id,
    };
};

const validateSpecificCustomField = ({
    fieldToCheck,
    sectionName,
    errorObj,
    suppressErrors,
    templateDetails,
}: {
    fieldToCheck: IItemCartLevelCustomField;
    sectionName: string;
    errorObj: IRequisitionItemValidation;
    suppressErrors: boolean;
    templateDetails: IRequisitionNewTemplate;
}) => {
    if (!errorObj.customFields[sectionName]) {
        errorObj.customFields[sectionName] = {};
    }
    delete errorObj.customFields[sectionName][fieldToCheck.name];
    const customfieldSettingFromTemplate =
        templateDetails.fieldSettings.customFields[fieldToCheck.name];

    if (
        fieldToCheck &&
        fieldToCheck.is_locked &&
        !isValueValidForStringArrNull(fieldToCheck.value)
    ) {
        errorObj.customFields[sectionName][fieldToCheck.name] = {
            type: 'ERROR',
            fieldMessageText: 'Input required',
            popupMessageText: `${fieldToCheck.name} input required`,
            suppress: suppressErrors,
        };
    } else if (
        fieldToCheck &&
        customfieldSettingFromTemplate &&
        customfieldSettingFromTemplate.buyerSettings.required &&
        !isValueValidForStringArrNull(fieldToCheck.value)
    ) {
        errorObj.customFields[sectionName][fieldToCheck.name] = {
            type: 'ERROR',
            fieldMessageText: 'Input required',
            popupMessageText: `${fieldToCheck.name} input required`,
            suppress: suppressErrors,
        };
    }
};

const validateRequisitionItemCustomFields = ({
    customFieldInfo,
    itemDetails,
    currentErrors,
    suppressErrors,
    templateDetails,
}: {
    customFieldInfo:
        | {
              sectionType: 'existingSections' | 'newSections';
              sectionName: string;
              fieldName: string;
          }
        | 'ALL';
    itemDetails: RequisitionItem;
    currentErrors: IRequisitionItemValidation;
    suppressErrors: boolean;
    templateDetails: IRequisitionNewTemplate;
}) => {
    itemDetails.customFields.sectionList.forEach((section) => {
        section.fields.forEach((data) => {
            validateSpecificCustomField({
                fieldToCheck: data,
                sectionName: section.name,
                errorObj: currentErrors,
                suppressErrors: suppressErrors,
                templateDetails: templateDetails,
            });
        });
        // for (let curField of curSectionFields) {
        //     validateSpecificCustomField({
        //         customFieldSections: itemDetails.customFields,
        //         errorObj: currentErrors,
        //         fieldName: curField.name,
        //         sectionName,
        //         sectionType: 'existingSections',
        //         suppressErrors,
        //         templateDetails,
        //     });
        // }
    });
    return currentErrors;
};

export const validateRequisitionItemDetails = ({
    field,
    itemDetails,
    currentErrors,
    preferredVendorCount,
    nonpreferredVendorCount,
    suppressErrors,
    templateDetails,
}: {
    field: keyof IRequisitionItemValidation | 'ALL';
    itemDetails: RequisitionItem;
    currentErrors: IRequisitionItemValidation;
    preferredVendorCount: number | null;
    nonpreferredVendorCount: number | null;
    suppressErrors: boolean;
    templateDetails: IRequisitionNewTemplate;
}): IRequisitionItemValidation => {
    // if (
    //     +itemDetails.poItem_approval_pending_quantity > 0 ||
    //     (itemDetails.po_status &&
    //         itemDetails.po_status !== 'PURCHASE_ORDER_PENDING')
    // ) {
    //     return {
    //         deliveryScheduleRows: [],
    //         paymentTerms_poItem_allocationRows: [],
    //         customFields: {},
    //     };
    // }
    let newErrors = cloneDeep(currentErrors);

    newErrors = validateRequisitionItemCustomFields({
        currentErrors: newErrors,
        itemDetails,
        customFieldInfo: 'ALL',
        suppressErrors,
        templateDetails,
    });
    if (field === 'ALL') {
    }
    if (
        field === 'ALL' ||
        field === 'quantity' ||
        field === 'deliveryScheduleRows' ||
        field === 'deliveryScheduleOverall' ||
        field === 'lead_time' ||
        field === 'lead_time_period'
    ) {
        if (
            typeof itemDetails.quantity !== 'number' &&
            isEmpty(itemDetails.quantity)
        ) {
            //Quantity is blank
            newErrors.quantity = {
                type: 'ERROR',
                fieldMessageText: 'Input required',
                popupMessageText: 'Quantity input required',
                suppress: suppressErrors,
            };
        } else if (+itemDetails.quantity <= 0) {
            newErrors.quantity = {
                //Quantity is 0
                type: 'ERROR',
                fieldMessageText: 'Must be greater than 0',
                popupMessageText: 'Invalid quantity',
                suppress: suppressErrors,
            };
        } else {
            delete newErrors['quantity'];
        }
        if (isEmpty(itemDetails.measurementUnit)) {
            //Measurement unit is blank
            newErrors.measurementUnit = {
                type: 'ERROR',
                fieldMessageText: 'Input required',
                popupMessageText: 'Measurement unit input required',
                suppress: suppressErrors,
            };
        } else {
            delete newErrors['measurementUnit'];
        }
        let totalDeliveryQuantity = 0;
        newErrors['deliveryScheduleOverall'] = [];
        newErrors['deliveryScheduleRows'] = [];
        for (let delivery of itemDetails.deliverySchedule) {
            let errorRow: {
                quantity?: IRequisitionItemFieldValidation;
                date?: IRequisitionItemFieldValidation;
                cost_centre_id?: IRequisitionItemFieldValidation;
                project_id?: IRequisitionItemFieldValidation;
                general_ledger_id?: IRequisitionItemFieldValidation;
            } = {};
            if (
                typeof delivery.quantity !== 'number' &&
                isEmpty(delivery.quantity)
            ) {
                //Delivery quantity is blank
                errorRow.quantity = {
                    type: 'ERROR',
                    fieldMessageText: 'Input required',
                    popupMessageText: '',
                    suppress: suppressErrors,
                };
                if (
                    !newErrors.deliveryScheduleOverall.find(
                        (err) =>
                            err.popupMessageText === 'Invalid delivery schedule'
                    )
                ) {
                    newErrors.deliveryScheduleOverall?.push({
                        type: 'ERROR',
                        fieldMessageText: '',
                        popupMessageText: 'Invalid delivery schedule',
                        suppress: suppressErrors,
                    });
                }
            } else if (+delivery.quantity <= 0) {
                //Delivery quantity is 0
                errorRow.quantity = {
                    type: 'ERROR',
                    fieldMessageText: 'Must be greater than 0',
                    popupMessageText: '',
                    suppress: suppressErrors,
                };
                if (
                    !newErrors.deliveryScheduleOverall.find(
                        (err) =>
                            err.popupMessageText === 'Invalid delivery schedule'
                    )
                ) {
                    newErrors.deliveryScheduleOverall?.push({
                        type: 'ERROR',
                        fieldMessageText: '',
                        popupMessageText: 'Invalid delivery schedule',
                        suppress: suppressErrors,
                    });
                }
            }
            if (
                templateDetails.fieldSettings.standardFields.DELIVERY_DATE
                    .buyerSettings.required &&
                delivery.delivery_date !== null &&
                !moment(delivery.delivery_date, 'yyyy-MM-DD', true).isValid()
            ) {
                //Delivery date is blank or invalid (cannot differentiate these two cases in code)
                errorRow.date = {
                    type: 'ERROR',
                    fieldMessageText: 'Invalid date',
                    popupMessageText: '',
                    suppress: suppressErrors,
                };
                if (
                    !newErrors.deliveryScheduleOverall.find(
                        (err) =>
                            err.popupMessageText === 'Invalid delivery schedule'
                    )
                ) {
                    newErrors.deliveryScheduleOverall?.push({
                        type: 'ERROR',
                        fieldMessageText: '',
                        popupMessageText: 'Invalid delivery schedule',
                        suppress: suppressErrors,
                    });
                }
            } else if (
                moment(delivery.delivery_date).isBefore(
                    moment().format('YYYY-MM-DD')
                )
            ) {
                errorRow.date = {
                    type: 'ERROR',
                    fieldMessageText: 'Past date cannot be entered',
                    popupMessageText: '',
                    suppress: suppressErrors,
                };
                if (
                    !newErrors.deliveryScheduleOverall.find(
                        (err) =>
                            err.popupMessageText === 'Invalid delivery schedule'
                    )
                ) {
                    newErrors.deliveryScheduleOverall?.push({
                        type: 'ERROR',
                        fieldMessageText: '',
                        popupMessageText: 'Invalid delivery schedule',
                        suppress: suppressErrors,
                    });
                }
            } else if (
                templateDetails.fieldSettings.standardFields.DELIVERY_DATE
                    .buyerSettings.required &&
                !delivery.delivery_date
            ) {
                errorRow.date = {
                    type: 'ERROR',
                    fieldMessageText: 'Delivery date is required',
                    popupMessageText: '',
                    suppress: suppressErrors,
                };
            }

            if (
                templateDetails.fieldSettings.standardFields.COST_CENTER
                    .buyerSettings.required &&
                templateDetails.fieldSettings.standardFields.COST_CENTER
                    .buyerSettings.showField &&
                templateDetails.sections.ITEM_TERMS.fieldList.some(
                    (field) =>
                        field.field ===
                        RequisitionNewTemplateStandardFieldsEnum.COST_CENTER
                )
            ) {
                if (
                    delivery.cost_centre_id === null ||
                    delivery.cost_centre_id === ''
                ) {
                    errorRow.cost_centre_id = {
                        type: 'ERROR',
                        fieldMessageText: 'Input required',
                        popupMessageText: 'Cost centre is required',
                        suppress: suppressErrors,
                    };
                    if (
                        !newErrors.deliveryScheduleOverall.find(
                            (err) =>
                                err.popupMessageText ===
                                'Cost centre is required'
                        )
                    ) {
                        newErrors.deliveryScheduleOverall?.push({
                            type: 'ERROR',
                            fieldMessageText: '',
                            popupMessageText: 'Cost centre is required',
                            suppress: suppressErrors,
                        });
                    }
                }
            }
            if (
                templateDetails.fieldSettings.standardFields.GL.buyerSettings
                    .required &&
                templateDetails.fieldSettings.standardFields.GL.buyerSettings
                    .showField &&
                templateDetails.sections.ITEM_TERMS.fieldList.some(
                    (field) =>
                        field.field ===
                        RequisitionNewTemplateStandardFieldsEnum.GL
                )
            ) {
                if (
                    delivery.general_ledger_id === null ||
                    delivery.general_ledger_id === ''
                ) {
                    errorRow.general_ledger_id = {
                        type: 'ERROR',
                        fieldMessageText: 'Input required',
                        popupMessageText: 'General ledger is required',
                        suppress: suppressErrors,
                    };
                    if (
                        !newErrors.deliveryScheduleOverall.find(
                            (err) =>
                                err.popupMessageText ===
                                'General ledger is required'
                        )
                    ) {
                        newErrors.deliveryScheduleOverall?.push({
                            type: 'ERROR',
                            fieldMessageText: '',
                            popupMessageText: 'General ledger is required',
                            suppress: suppressErrors,
                        });
                    }
                }
            }
            if (
                templateDetails.fieldSettings.standardFields.PROJECT
                    .buyerSettings.required &&
                templateDetails.fieldSettings.standardFields.PROJECT
                    .buyerSettings.showField &&
                templateDetails.sections.ITEM_TERMS.fieldList.some(
                    (field) =>
                        field.field ===
                        RequisitionNewTemplateStandardFieldsEnum.PROJECT
                )
            ) {
                if (
                    delivery.project_id === null ||
                    delivery.project_id === ''
                ) {
                    errorRow.project_id = {
                        type: 'ERROR',
                        fieldMessageText: 'Input required',
                        popupMessageText: 'Project is required',
                        suppress: suppressErrors,
                    };
                    if (
                        !newErrors.deliveryScheduleOverall.find(
                            (err) =>
                                err.popupMessageText === 'Project is required'
                        )
                    ) {
                        newErrors.deliveryScheduleOverall?.push({
                            type: 'ERROR',
                            fieldMessageText: '',
                            popupMessageText: 'Project is required',
                            suppress: suppressErrors,
                        });
                    }
                }
            }

            totalDeliveryQuantity += isNaN(+delivery.quantity)
                ? 0
                : +delivery.quantity;

            newErrors.deliveryScheduleRows.push(errorRow);
        }
        if (
            templateDetails.fieldSettings.standardFields.DELIVERY_DATE
                .buyerSettings.required
        ) {
            if (field === 'ALL' || field === 'deliveryScheduleOverall') {
                if (
                    itemDetails.deliverySchedule.length === 0 ||
                    itemDetails.deliverySchedule.some(
                        (delivery) => delivery.delivery_date === null
                    )
                ) {
                    newErrors.deliveryScheduleOverall?.push({
                        type: 'ERROR',
                        fieldMessageText: 'Input required',
                        popupMessageText: 'Delivery date input required',
                        suppress: suppressErrors,
                    });
                }
            }
        }
        if (
            // Only perform this validation if delivery schedule is valid but quantity mismatches.

            !isEmpty(itemDetails.quantity) &&
            totalDeliveryQuantity !== +itemDetails.quantity
        ) {
            //Total quantity does not match sum of delivery quantities
            newErrors.deliveryScheduleOverall?.push({
                type: 'ERROR',
                fieldMessageText: '',
                popupMessageText:
                    'Delivery schedule quantity does not match total desired quantity',
                suppress: suppressErrors,
            });
        }
    }
    if (field === 'ALL' || field === 'price') {
        if (
            templateDetails.fieldSettings.standardFields.TARGET_RATE
                .buyerSettings.required &&
            templateDetails.fieldSettings.standardFields.TARGET_RATE
                .buyerSettings.showField
        ) {
            if (
                typeof itemDetails.pricingInformation.desiredPrice !==
                    'number' &&
                isEmpty(itemDetails.pricingInformation.desiredPrice)
            ) {
                //Price is blank
                newErrors.price = {
                    type: 'ERROR',
                    fieldMessageText: 'Input required',
                    popupMessageText: 'Target rate input required',
                    suppress: suppressErrors,
                };
            } else if (+itemDetails.pricingInformation.desiredPrice <= 0) {
                //Price is 0
                newErrors.price = {
                    type: 'ERROR',
                    fieldMessageText: 'Must be greater than 0',
                    popupMessageText: 'Invalid target rate',
                    suppress: suppressErrors,
                };
            } else {
                delete newErrors['price'];
            }
        } else {
            delete newErrors['price'];
        }
    }
    if (
        templateDetails.fieldSettings.standardFields.GR_TOLERANCE.buyerSettings
            .showField &&
        templateDetails.sections.PAYMENT_AND_DELIVERY_TERMS.fieldList.some(
            (field) =>
                field.field ===
                RequisitionNewTemplateStandardFieldsEnum.GR_TOLERANCE
        ) &&
        (field === 'ALL' || field === 'quantity_tolerance_percentage')
    ) {
        if (+itemDetails.quantityTolerancePercentage > 10) {
            newErrors.quantity_tolerance_percentage = {
                type: 'ERROR',
                fieldMessageText: 'Cannot exceed 10%',
                popupMessageText: 'GR tolerance cannot exceed 10%',
                suppress: suppressErrors,
            };
        } else if (
            templateDetails.fieldSettings.standardFields.GR_TOLERANCE
                .buyerSettings.required &&
            templateDetails.sections.PAYMENT_AND_DELIVERY_TERMS.fieldList.some(
                (field) =>
                    field.field ===
                    RequisitionNewTemplateStandardFieldsEnum.GR_TOLERANCE
            )
        ) {
            if (String(itemDetails.quantityTolerancePercentage) === '') {
                newErrors['quantity_tolerance_percentage'] = {
                    type: 'ERROR',
                    fieldMessageText: 'GR tolerance is required',
                    popupMessageText: 'GR tolerance is required',
                    suppress: suppressErrors,
                };
            } else {
                delete newErrors['quantity_tolerance_percentage'];
            }
        } else {
            delete newErrors['quantity_tolerance_percentage'];
        }
    } else {
        delete newErrors['quantity_tolerance_percentage'];
    }

    // if (
    //     templateDetails.fieldSettings.standardFields.SHIPPING_RATE.buyerSettings
    //         .showField &&
    //     (field === 'ALL' || field === 'shipping')
    // ) {
    //     if (
    //         typeof itemDetails.quantity !== 'number' &&
    //         !isEmpty(itemDetails.shipping) &&
    //         isNaN(+itemDetails.shipping)
    //     ) {
    //         //Shipping is not a number
    //         newErrors.shipping = {
    //             type: 'ERROR',
    //             fieldMessageText: 'Invalid shipping rate',
    //             popupMessageText: 'Invalid shipping rate',
    //             suppress: suppressErrors,
    //         };
    //     } else {
    //         delete newErrors['shipping'];
    //     }
    // } else {
    //     delete newErrors['shipping'];
    // }

    const templatePaymentTermsOptions =
        templateDetails.fieldSettings.standardFields.PAYMENT_TERMS;
    const ptValidation = validatePaymentTerms({
        paymentTerms: itemDetails.paymentTerms!,
        prepaymentAllowed:
            templatePaymentTermsOptions.paymentTermOptions.prepaymentAllowed,
    });

    const paymentTermsErrorKeys: (keyof IRequisitionItemValidation)[] = [
        'paymentTerms_prepayment',
        'paymentTerms_invoiceItem_terms',
        'paymentTerms_invoiceItem_period',
        'paymentTerms_invoiceItem_appliedFrom',
        'paymentTerms_poItem_overallPercentage',
        'paymentTerms_poItem_allocationRows',
    ];

    if (
        templateDetails.fieldSettings.standardFields[
            RequisitionNewTemplateStandardFieldsEnum.PAYMENT_TERMS
        ].buyerSettings.showField &&
        templateDetails.sections.PAYMENT_AND_DELIVERY_TERMS.fieldList.some(
            (field) =>
                field.field ===
                RequisitionNewTemplateStandardFieldsEnum.PAYMENT_TERMS
        ) &&
        (field === 'ALL' || paymentTermsErrorKeys.includes(field))
    ) {
        paymentTermsErrorKeys.forEach((key) => {
            delete newErrors[key];
        });

        newErrors.paymentTerms_poItem_allocationRows = [];

        if (ptValidation.overallErrors.prepaymentBlank) {
            newErrors.paymentTerms_prepayment = {
                type: 'ERROR',
                fieldMessageText: 'Input required',
                popupMessageText: 'Prepayment input required',
                suppress: suppressErrors,
            };
        } else if (ptValidation.overallErrors.prepaymentAbove100) {
            newErrors.paymentTerms_prepayment = {
                type: 'ERROR',
                fieldMessageText: 'Cannot exceed 100%',
                popupMessageText: 'Invalid prepayment percentage',
                suppress: suppressErrors,
            };
        }
        if (ptValidation.overallErrors.termsBlank) {
            newErrors.paymentTerms_invoiceItem_terms = {
                type: 'ERROR',
                fieldMessageText: 'Input required',
                popupMessageText: 'Payment terms input required',
                suppress: suppressErrors,
            };
        }
        if (ptValidation.overallErrors.periodBlank) {
            newErrors.paymentTerms_invoiceItem_period = {
                type: 'ERROR',
                fieldMessageText: 'Input required',
                popupMessageText: 'Payment terms period input required',
                suppress: suppressErrors,
            };
        }
        if (ptValidation.overallErrors.appliedFromBlank) {
            newErrors.paymentTerms_invoiceItem_appliedFrom = {
                type: 'ERROR',
                fieldMessageText: 'Input required',
                popupMessageText: 'Payment terms reference date required',
                suppress: suppressErrors,
            };
        }
        if (ptValidation.overallErrors.allocationNot100) {
            const msg =
                +(itemDetails.paymentTerms?.prepaymentPercentage ?? '0') > 0
                    ? 'Allocations and prepayment must add up to 100%'
                    : 'Allocations must add up to 100%';
            newErrors.paymentTerms_poItem_overallPercentage = {
                type: 'ERROR',
                fieldMessageText: msg,
                popupMessageText: msg,
                suppress: suppressErrors,
            };
        }
        for (let rowIdx = 0; rowIdx < ptValidation.rowErrors.length; rowIdx++) {
            const curRowErrors: {
                rowAllocationPercentage?: IRequisitionItemFieldValidation;
                rowTerms?: IRequisitionItemFieldValidation;
                rowPeriod?: IRequisitionItemFieldValidation;
                rowDeliverableName?: IRequisitionItemFieldValidation;
                rowDate?: IRequisitionItemFieldValidation;
            } = {};
            if (ptValidation.rowErrors[rowIdx].allocationBlank) {
                curRowErrors.rowAllocationPercentage = {
                    fieldMessageText: 'Input required',
                    popupMessageText: 'Payment terms invalid',
                    suppress: suppressErrors,
                    type: 'ERROR',
                };
            } else if (ptValidation.rowErrors[rowIdx].allocationOver100) {
                curRowErrors.rowAllocationPercentage = {
                    fieldMessageText: 'Cannot exceed 100',
                    popupMessageText: 'Payment terms invalid',
                    suppress: suppressErrors,
                    type: 'ERROR',
                };
            }
            if (ptValidation.rowErrors[rowIdx].dateBlank) {
                curRowErrors.rowDate = {
                    fieldMessageText: 'Input required',
                    popupMessageText: 'Payment terms invalid',
                    suppress: suppressErrors,
                    type: 'ERROR',
                };
            } else if (ptValidation.rowErrors[rowIdx].datePast) {
                curRowErrors.rowDate = {
                    fieldMessageText: 'Input invalid',
                    popupMessageText: 'Payment terms invalid',
                    suppress: suppressErrors,
                    type: 'ERROR',
                };
            }
            if (ptValidation.rowErrors[rowIdx].termsBlank) {
                curRowErrors.rowTerms = {
                    fieldMessageText: 'Input required',
                    popupMessageText: 'Payment terms invalid',
                    suppress: suppressErrors,
                    type: 'ERROR',
                };
            }
            if (ptValidation.rowErrors[rowIdx].periodBlank) {
                curRowErrors.rowPeriod = {
                    fieldMessageText: 'Input required',
                    popupMessageText: 'Payment terms invalid',
                    suppress: suppressErrors,
                    type: 'ERROR',
                };
            }
            if (ptValidation.rowErrors[rowIdx].deliverableBlank) {
                curRowErrors.rowDeliverableName = {
                    fieldMessageText: 'Input required',
                    popupMessageText: 'Payment terms invalid',
                    suppress: suppressErrors,
                    type: 'ERROR',
                };
            } else if (ptValidation.rowErrors[rowIdx].deliverableTooLong) {
                curRowErrors.rowDeliverableName = {
                    fieldMessageText: `Cannot exceed ${DELIVERABLE_LENGTH_LIMIT} characters`,
                    popupMessageText: 'Payment terms invalid',
                    suppress: suppressErrors,
                    type: 'ERROR',
                };
            } else if (ptValidation.rowErrors[rowIdx].deliverableInvalid) {
                curRowErrors.rowDeliverableName = {
                    fieldMessageText: 'Input invalid',
                    popupMessageText: 'Payment terms invalid',
                    suppress: suppressErrors,
                    type: 'ERROR',
                };
            }

            newErrors.paymentTerms_poItem_allocationRows.push(curRowErrors);
        }
    }
    if (
        templateDetails.fieldSettings.standardFields.LEAD_TIME.buyerSettings
            .showField &&
        templateDetails.sections.PAYMENT_AND_DELIVERY_TERMS.fieldList.some(
            (field) =>
                field.field ===
                RequisitionNewTemplateStandardFieldsEnum.LEAD_TIME
        ) &&
        (field === 'ALL' ||
            field === 'lead_time_period' ||
            field === 'lead_time')
    ) {
        if (
            templateDetails.fieldSettings.standardFields.LEAD_TIME.buyerSettings
                .showField &&
            templateDetails.fieldSettings.standardFields.LEAD_TIME.buyerSettings
                .required &&
            itemDetails.procurementInformation.leadTime === ''
        ) {
            newErrors.lead_time = {
                type: 'ERROR',
                fieldMessageText: 'Input required',
                popupMessageText: 'Lead time input required',
                suppress: suppressErrors,
            };
        } else if (
            itemDetails.procurementInformation.leadTime &&
            isNaN(+itemDetails.procurementInformation.leadTime)
        ) {
            //Lead time is not a number
            newErrors.lead_time = {
                type: 'ERROR',
                fieldMessageText: 'Invalid lead time',
                popupMessageText: 'Invalid lead time',
                suppress: suppressErrors,
            };
        } else if (
            itemDetails.procurementInformation.leadTime &&
            templateDetails.fieldSettings.standardFields.LEAD_TIME.buyerSettings
                .showField &&
            templateDetails.fieldSettings.standardFields.LEAD_TIME.buyerSettings
                .required &&
            +itemDetails.procurementInformation.leadTime < 0
        ) {
            newErrors.lead_time = {
                type: 'ERROR',
                fieldMessageText: 'Lead time need to be greater then 0',
                popupMessageText: 'Lead time need to be greater then 0',
                suppress: suppressErrors,
            };
        } else if (
            templateDetails.fieldSettings.standardFields.LEAD_TIME.buyerSettings
                .showField &&
            itemDetails.procurementInformation.leadTimePeriod === '' &&
            templateDetails.fieldSettings.standardFields.LEAD_TIME.buyerSettings
                .required
        ) {
            //Lead time period is blank
            newErrors.lead_time = {
                type: 'ERROR',
                fieldMessageText: 'Input required',
                popupMessageText: 'Lead time period input required',
                suppress: suppressErrors,
            };
        } else {
            delete newErrors['lead_time'];
        }
        if (
            itemDetails.procurementInformation.leadTimePeriod !== '' &&
            itemDetails.procurementInformation.leadTimePeriod &&
            leadTimeSurpassed(
                itemDetails.procurementInformation.leadTime,
                itemDetails.procurementInformation.leadTimePeriod
            )
        ) {
            newErrors.excessive_lead_time = {
                type: 'WARNING',
                fieldMessageText: '',
                popupMessageText: 'Lead time exceeds one year',
                suppress: suppressErrors,
            };
        } else {
            delete newErrors['excessive_lead_time'];
        }
    } else {
        delete newErrors['lead_time'];
    }
    if (
        templateDetails.fieldSettings.standardFields.INCOTERMS.buyerSettings
            .showField &&
        templateDetails.sections.PAYMENT_AND_DELIVERY_TERMS.fieldList.some(
            (field) =>
                field.field ===
                RequisitionNewTemplateStandardFieldsEnum.INCOTERMS
        ) &&
        (field === 'ALL' || field === 'incoterm_id')
    ) {
        if (
            itemDetails.incoterm === '' &&
            templateDetails.fieldSettings.standardFields.INCOTERMS.buyerSettings
                .required
        ) {
            //Incoterm is blank
            newErrors.incoterm_id = {
                type: 'ERROR',
                fieldMessageText: 'Input required',
                popupMessageText: 'Incoterms input required',
                suppress: suppressErrors,
            };
        } else {
            delete newErrors['incoterm_id'];
        }
    } else {
        delete newErrors['incoterm_id'];
    }

    if (
        (field === 'ALL' || field === 'vendor_count') &&
        templateDetails.fieldSettings.standardFields.VENDOR.buyerSettings
            .required &&
        preferredVendorCount !== null &&
        nonpreferredVendorCount !== null
    ) {
        if (preferredVendorCount + nonpreferredVendorCount === 0) {
            //No of vendors is 0
            newErrors.vendor_count = {
                type: 'ERROR',
                fieldMessageText: '',
                popupMessageText: 'No vendors added',
                suppress: suppressErrors,
            };
        } else if (nonpreferredVendorCount) {
            //No of vendors is 0
            newErrors.vendor_count = {
                type: 'WARNING',
                fieldMessageText: '',
                popupMessageText: 'Non-preferred vendors added',
                suppress: suppressErrors,
            };
        } else {
            delete newErrors['vendor_count'];
        }
    }
    // specifications
    if (field === 'ALL' || field === 'specifications') {
        if (itemDetails.attributeInformation.customItemAttributes.length > 0) {
            // non standard specification(s) added
            newErrors.specifications = {
                type: 'WARNING',
                fieldMessageText: '',
                popupMessageText:
                    itemDetails.attributeInformation.customItemAttributes
                        .length > 1
                        ? `Item contains ${itemDetails.attributeInformation.customItemAttributes.length} non-standard specifications`
                        : `Item contains a non-standard specification`,

                suppress: suppressErrors,
            };
        } else {
            delete newErrors['specifications'];
        }
    }
    if (
        templateDetails.fieldSettings.standardFields.SHIPPING_ADDRESS
            .buyerSettings.showField &&
        templateDetails.fieldSettings.standardFields.SHIPPING_ADDRESS
            .buyerSettings.required &&
        templateDetails.sections.PAYMENT_AND_DELIVERY_TERMS.fieldList.some(
            (field) =>
                field.field ===
                RequisitionNewTemplateStandardFieldsEnum.SHIPPING_ADDRESS
        ) &&
        (field === 'ALL' || field === 'shipping_address')
    ) {
        if (itemDetails.shippingAddress.shippingAddressId === null) {
            newErrors.shipping_address = {
                type: 'ERROR',
                fieldMessageText: 'Shipping address is required',
                popupMessageText: 'Shipping address is required',
                suppress: suppressErrors,
            };
        } else delete newErrors['shipping_address'];
    }

    // requisition
    // if (
    //     templateDetails.fieldSettings.standardFields.REQUISITION.buyerSettings
    //         .showField &&
    //     templateDetails.sections.ADDITIONAL_DETAILS.fieldList.some(
    //         (field) => field.field === TemplateStandardFieldsEnum.REQUISITION
    //     ) &&
    //     (field === 'ALL' ||
    //         field === 'requisition_number' ||
    //         field === 'requisition_approval')
    // ) {
    //     if (
    //         itemDetails.requisition_number.length > 0 &&
    //         templateDetails.fieldSettings.standardFields.REQUISITION
    //             .buyerSettings.required &&
    //         !itemDetails.requisition_approved
    //     ) {
    //         // requisition is not approved
    //         newErrors.requisition_approval = {
    //             type: 'WARNING',
    //             fieldMessageText: 'Requisition not approved',
    //             popupMessageText: 'Requisition not approved',
    //             suppress: suppressErrors,
    //         };
    //     } else {
    //         delete newErrors['requisition_approval'];
    //     }

    //     if (
    //         templateDetails.fieldSettings.standardFields.REQUISITION
    //             .buyerSettings.required &&
    //         itemDetails.requisition_number?.trim() === ''
    //     ) {
    //         newErrors['requisition_number'] = {
    //             type: 'ERROR',
    //             fieldMessageText: 'Requisition is required',
    //             popupMessageText: 'Requisition is required',
    //             suppress: suppressErrors,
    //         };
    //     } else {
    //         delete newErrors['requisition_number'];
    //     }
    // }

    if (
        templateDetails.fieldSettings.standardFields.ADDITIONAL_INFORMATION
            .buyerSettings.showField &&
        templateDetails.fieldSettings.standardFields.ADDITIONAL_INFORMATION
            .buyerSettings.required &&
        templateDetails.sections.ADDITIONAL_DETAILS.fieldList.some(
            (field) =>
                field.field ===
                RequisitionNewTemplateStandardFieldsEnum.ADDITIONAL_INFORMATION
        ) &&
        itemDetails.itemInformation.itemAdditionalDetails === ''
    ) {
        newErrors.item_additional_information = {
            type: 'ERROR',
            fieldMessageText: 'Input required',
            popupMessageText: 'Additional information Input required',
            suppress: suppressErrors,
        };
    } else {
        delete newErrors['item_additional_information'];
    }

    // if (
    //     templateDetails.fieldSettings.standardFields.TAX.buyerSettings
    //         .showField &&
    //     (field === 'ALL' || field === 'additional_charges')
    // ) {
    //     const taxNames = itemDetails.additional_charges.map((charge) =>
    //         charge.charge_name.trim()
    //     );
    //     const duplicateNamesExist = new Set(taxNames).size !== taxNames.length;
    //     if (duplicateNamesExist) {
    //         //Duplicate tax names (warning)
    //         newErrors.additional_charges = {
    //             type: 'WARNING',
    //             fieldMessageText: 'Taxes may contain duplicates',
    //             popupMessageText: 'Taxes may contain duplicates',
    //             suppress: suppressErrors,
    //         };
    //     } else {
    //         delete newErrors['additional_charges'];
    //     }
    // }

    if (
        templateDetails.fieldSettings.standardFields.ADDITIONAL_COSTS
            .buyerSettings.showField &&
        templateDetails.sections.ITEM_TERMS.fieldList.some(
            (field) =>
                field.field ===
                RequisitionNewTemplateStandardFieldsEnum.ADDITIONAL_COSTS
        ) &&
        (field === 'ALL' || field === 'additional_cost')
    ) {
        const requiredAdditionalCost =
            templateDetails.fieldSettings.standardFields.ADDITIONAL_COSTS.additionalCostOptions.filter(
                (opt) => opt.is_required
            );
        if (requiredAdditionalCost.length > 0) {
            const isThereError = requiredAdditionalCost.reduce(
                (acc, additionalCostopt) => {
                    const foundItemAdditionalCost =
                        itemDetails.additionalCosts.find(
                            (costAdditional) =>
                                costAdditional.cost_name.trim() ===
                                additionalCostopt.costName.trim()
                        );

                    if (foundItemAdditionalCost) {
                        if (
                            typeof foundItemAdditionalCost.cost_value ===
                                'string' &&
                            foundItemAdditionalCost.cost_value.trim() === ''
                        ) {
                            return acc || true;
                        } else {
                            return acc || false;
                        }
                    } else {
                        return acc || true;
                    }
                },
                false
            );
            if (isThereError) {
                newErrors.additional_cost = {
                    type: 'ERROR',
                    fieldMessageText: 'Additional cost is required',
                    popupMessageText: 'Additional cost is required',
                    suppress: suppressErrors,
                };
            } else {
                delete newErrors['additional_cost'];
            }
        } else {
            delete newErrors['additional_cost'];
        }
    }
    if (
        templateDetails.fieldSettings.standardFields.TAX.buyerSettings
            .showField &&
        templateDetails.sections.ITEM_TERMS.fieldList.some(
            (field) =>
                field.field ===
                RequisitionNewTemplateStandardFieldsEnum.DISCOUNT
        ) &&
        (field === 'ALL' || field === 'taxes')
    ) {
        const requiredAdditionalCost =
            templateDetails.fieldSettings.standardFields.TAX.taxesOptions.filter(
                (opt) => opt.is_required
            );
        if (requiredAdditionalCost.length > 0) {
            const isThereError = requiredAdditionalCost.reduce(
                (acc, additionalCostTaxopt) => {
                    const foundItemTaxes = itemDetails.taxes.find(
                        (cost) =>
                            cost.cost_name.trim() ===
                            additionalCostTaxopt.costName.trim()
                    );

                    if (foundItemTaxes) {
                        if (
                            typeof foundItemTaxes.cost_value === 'string' &&
                            foundItemTaxes.cost_value.trim() === ''
                        ) {
                            return acc || true;
                        } else {
                            return acc || false;
                        }
                    } else {
                        return acc || true;
                    }
                },
                false
            );
            if (isThereError) {
                newErrors.taxes = {
                    type: 'ERROR',
                    fieldMessageText: 'Taxes is required',
                    popupMessageText: 'Taxes is required',
                    suppress: suppressErrors,
                };
            } else {
                delete newErrors['taxes'];
            }
        } else {
            delete newErrors['taxes'];
        }
    }

    if (
        templateDetails.fieldSettings.standardFields.DISCOUNT.buyerSettings
            .showField &&
        templateDetails.sections.ITEM_TERMS.fieldList.some(
            (field) =>
                field.field ===
                RequisitionNewTemplateStandardFieldsEnum.DISCOUNT
        ) &&
        (field === 'ALL' || field === 'discounts' || field === 'price')
    ) {
        const requiredDiscount =
            templateDetails.fieldSettings.standardFields.DISCOUNT.discountOptions.filter(
                (opt) => opt.is_required
            );
        if (requiredDiscount.length > 0) {
            const isThereError = requiredDiscount.reduce(
                (acc, additionalCostDiscountopt) => {
                    const foundItemDiscount = itemDetails.discounts.find(
                        (costDiscount) =>
                            costDiscount.cost_name.trim() ===
                            additionalCostDiscountopt.costName.trim()
                    );

                    if (foundItemDiscount) {
                        if (
                            typeof foundItemDiscount.cost_value === 'string' &&
                            foundItemDiscount.cost_value.trim() === ''
                        ) {
                            return acc || true;
                        } else {
                            return acc || false;
                        }
                    } else {
                        return acc || true;
                    }
                },
                false
            );

            if (isThereError) {
                newErrors.discounts = {
                    type: 'ERROR',
                    fieldMessageText: 'Discount is required',
                    popupMessageText: 'Discount is required',
                    suppress: suppressErrors,
                };
            } else {
                delete newErrors['discounts'];
            }
        } else {
            delete newErrors['discounts'];
        }

        // if (
        //     itemDetails.price &&
        //     calculateTotalAdditionalCostAndDeviation({
        //         additionalCost: itemDetails.discounts[0] ?? [],
        //         baseQuantity: itemDetails.quantity,
        //         baseRate: itemDetails.price,
        //     }).effectiveRateDeviation >= +itemDetails.price
        // ) {
        //     newErrors.discounts = {
        //         type: 'ERROR',
        //         fieldMessageText: 'Discount cannot exceed target rate',
        //         popupMessageText: 'Discount cannot exceed target rate',
        //         suppress: suppressErrors,
        //     };
        // } else {
        //     delete newErrors['discounts'];
        // }

        if (
            templateDetails.fieldSettings.standardFields[
                RequisitionNewTemplateStandardFieldsEnum.DISCOUNT
            ].buyerSettings.required &&
            itemDetails.discounts[0] &&
            itemDetails.discounts[0].cost_value === ''
        ) {
            newErrors.discounts = {
                type: 'ERROR',
                fieldMessageText: '',
                popupMessageText: 'Discount input required',
                suppress: suppressErrors,
            };
        } else if (
            itemDetails.pricingInformation.desiredPrice &&
            calculateTotalAdditionalCostAndDeviation({
                additionalCost:
                    convertAdditionalCostBackendToFrontend(
                        itemDetails.discounts
                    )[0] ?? [],
                baseQuantity: itemDetails.quantity,
                baseRate: itemDetails.pricingInformation.desiredPrice,
            }).effectiveRateDeviation >=
                +itemDetails.pricingInformation.desiredPrice
        ) {
            newErrors.discounts = {
                type: 'ERROR',
                fieldMessageText: 'Discount cannot exceed target rate',
                popupMessageText: 'Discount cannot exceed target rate',
                suppress: suppressErrors,
            };
        } else {
            delete newErrors['discounts'];
        }
    }
    if (itemDetails.paymentTerms.paymentType === 'poItemPercentages') {
        // console.log('amaan',itemDetails.paymentTerms.list)
        const list = itemDetails.paymentTerms.list;
        const seen: { [key: string]: number } = {};

        for (let i = 0; i < list.length; i++) {
            const temp = list[i];
            if (temp.milestoneType === 'DELIVERABLE') {
                const { deliverableName, terms, period } = temp;
                const itemKey = JSON.stringify({
                    deliverableName,
                    terms,
                    period,
                });

                if (seen[itemKey] !== undefined) {
                    newErrors.paymentTerms_poItem_allocationRows[i] = {
                        rowAllocationPercentage: {
                            type: 'ERROR',
                            fieldMessageText: 'error-terms',
                            popupMessageText:
                                'Same terms cannot be added. Combine or change terms',
                            suppress: suppressErrors,
                        },
                    };
                } else {
                    seen[itemKey] = i;
                }
            }
        }
        newErrors.paymentTerms_poItem_allocationRows =
            newErrors.paymentTerms_poItem_allocationRows.filter(
                (a: any) => Object.keys(a).length > 0
            );

        // return false;
    }

    // console.log('amaan',itemDetails.paymentTerms.paymentType ==='poItemPercentages' && itemDetails.paymentTerms.list)
    return newErrors;
};

export const getRequisitionItemValidationKey = (
    updatedFieldName: updateRequisitionItemDetailsActionType
): keyof IRequisitionItemValidation | 'ALL' => {
    switch (updatedFieldName) {
        case 'quantity':
            return 'quantity';
        case 'deliveryInformation':
            return 'deliveryScheduleRows';
        case 'deliveryScheduleOverall':
            return 'deliveryScheduleOverall';
        case 'price':
            return 'price';
        case 'shippingAddress':
            return 'shipping_address';
        case 'incoterm':
            return 'incoterm';
        case 'item_additional_information':
            return 'item_additional_information';
        case 'lead_time':
            return 'lead_time';
        case 'lead_time_period':
            return 'lead_time_period';

        case 'customFields':
            return 'customFields';
        case 'paymentTerms_prepaymentPercentage':
            return 'paymentTerms_prepayment';
        case 'paymentTerms_invoiceItem_terms':
            return 'paymentTerms_invoiceItem_terms';
        case 'paymentTerms_invoiceItem_period':
            return 'paymentTerms_invoiceItem_period';
        case 'paymentTerms_invoiceItem_appliedFrom':
            return 'paymentTerms_invoiceItem_appliedFrom';
        case 'paymentTerms_poItem_allocationRows':
            return 'paymentTerms_poItem_allocationRows';
        case 'quantity_tolerance_percentage':
            return 'quantity_tolerance_percentage';
        case 'additionalCosts':
            return 'additional_cost';
        case 'taxes':
            return 'taxes';
        case 'discounts':
            return 'discounts';
        default:
            return 'ALL';
    }
};

export const getCustomFieldsFromTemplate = (
    templateDetails: IRequisitionNewTemplate
) => {
    let itemTerms = {
        name: templateDetails.sections.ITEM_TERMS.label,
        fields: templateDetails.sections.ITEM_TERMS.fieldList
            .filter((fieldDetails: any) => fieldDetails.fieldType === 'CUSTOM')
            .map((data) => {
                let fieldDetails =
                    templateDetails.fieldSettings.customFields[
                        data.field as any
                    ];
                return {
                    name: fieldDetails.label,
                    type: fieldDetails.type,
                    value: [
                        'SHORT_TEXT',
                        'LONG_TEXT',
                        'SHORTTEXT',
                        'LONGTEXT',
                        'FLOAT',
                        'INTEGER',
                    ].includes(fieldDetails.type)
                        ? ''
                        : null,
                    is_locked: fieldDetails.buyerSettings.required,
                    is_visible: fieldDetails.buyerSettings.showField,
                    description: fieldDetails.description,
                    is_required: fieldDetails.sellerSettings.isMandatory,
                    is_negotiable: fieldDetails.sellerSettings.negotiable,
                };
            }),
    };
    let paymentTerms = {
        name: templateDetails.sections.PAYMENT_AND_DELIVERY_TERMS.label,
        fields: templateDetails.sections.PAYMENT_AND_DELIVERY_TERMS.fieldList
            .filter((fieldDetails: any) => fieldDetails.fieldType === 'CUSTOM')
            .map((data) => {
                let fieldDetails =
                    templateDetails.fieldSettings.customFields[
                        data.field as any
                    ];
                return {
                    name: fieldDetails.label,
                    type: fieldDetails.type,
                    value: [
                        'SHORT_TEXT',
                        'LONG_TEXT',
                        'SHORTTEXT',
                        'LONGTEXT',
                        'FLOAT',
                        'INTEGER',
                    ].includes(fieldDetails.type)
                        ? ''
                        : null,
                    is_locked: fieldDetails.buyerSettings.required,
                    is_visible: fieldDetails.buyerSettings.showField,
                    description: fieldDetails.description,
                    is_required: fieldDetails.sellerSettings.isMandatory,
                    is_negotiable: fieldDetails.sellerSettings.negotiable,
                };
            }),
    };
    let vendorInformation = {
        name: templateDetails.sections.VENDOR_INFORMATION.label,
        fields: templateDetails.sections.VENDOR_INFORMATION.fieldList
            .filter((fieldDetails: any) => fieldDetails.fieldType === 'CUSTOM')
            .map((data) => {
                let fieldDetails =
                    templateDetails.fieldSettings.customFields[
                        data.field as any
                    ];
                return {
                    name: fieldDetails.label,
                    type: fieldDetails.type,
                    value: [
                        'SHORT_TEXT',
                        'LONG_TEXT',
                        'SHORTTEXT',
                        'LONGTEXT',
                        'FLOAT',
                        'INTEGER',
                    ].includes(fieldDetails.type)
                        ? ''
                        : null,
                    is_locked: fieldDetails.buyerSettings.required,
                    is_visible: fieldDetails.buyerSettings.showField,
                    description: fieldDetails.description,
                    is_required: fieldDetails.sellerSettings.isMandatory,
                    is_negotiable: fieldDetails.sellerSettings.negotiable,
                };
            }),
    };
    let additional = {
        name: templateDetails.sections.ADDITIONAL_DETAILS.label,
        fields: templateDetails.sections.ADDITIONAL_DETAILS.fieldList
            .filter((fieldDetails: any) => fieldDetails.fieldType === 'CUSTOM')
            .map((data) => {
                let fieldDetails =
                    templateDetails.fieldSettings.customFields[
                        data.field as any
                    ];
                return {
                    name: fieldDetails.label,
                    type: fieldDetails.type,
                    value: [
                        'SHORT_TEXT',
                        'LONG_TEXT',
                        'SHORTTEXT',
                        'LONGTEXT',
                        'FLOAT',
                        'INTEGER',
                    ].includes(fieldDetails.type)
                        ? ''
                        : null,
                    is_locked: fieldDetails.buyerSettings.required,
                    is_visible: fieldDetails.buyerSettings.showField,
                    description: fieldDetails.description,
                    is_required: fieldDetails.sellerSettings.isMandatory,
                    is_negotiable: fieldDetails.sellerSettings.negotiable,
                };
            }),
    };

    return [itemTerms, paymentTerms, vendorInformation, additional];
};

export const validateRequisitionDetails = (
    reqDetails: any,
    field: string,
    currentErrors: any,
    doesNameExist: boolean
) => {
    if (reqDetails.reqName === '') {
        currentErrors = {
            ...currentErrors,
            reqName: {
                error: true,
                message: 'Requisition name cannot be empty',
            },
        };
    } else if (doesNameExist) {
        currentErrors = {
            ...currentErrors,
            reqName: {
                error: true,
                message: 'Requisition with this name already exists',
            },
        };
    } else {
        currentErrors = {
            ...currentErrors,
            reqName: {
                error: false,
                message: '',
            },
        };
    }
    return currentErrors;
};

export const checkValidityOfItem = (errors: any) => {
    let allKeys = true;
    let customFieldsKey = true;
    let dsKey = true;
    Object.keys(errors).forEach((key: any) => {
        if (
            ![
                'deliveryScheduleRows',
                'customFields',
                'paymentTerms_poItem_allocationRows',
            ].includes(key)
        ) {
            if (errors[key].type === 'ERROR') {
                allKeys = false;
            }
        } else if (key === 'customFields') {
            let customFieldErrors = errors[key];
            Object.keys(customFieldErrors).forEach((customFieldKey) => {
                Object.keys(customFieldErrors[customFieldKey]).forEach(
                    (finalKey) => {
                        if (
                            errors[key][customFieldKey][finalKey].type ===
                            'ERROR'
                        ) {
                            customFieldsKey = false;
                        }
                    }
                );
            });
        } else if (key === 'deliveryScheduleRows') {
            dsKey = !errors[key].some(
                (value: any) => Object.keys(value).length > 0
            );
        }
    });

    return allKeys && customFieldsKey && dsKey;
};
