import { Box, Typography } from '@mui/material';

const PasswordValidationList = ({ password }: { password: string }) => {
    return (
        <Box className="flex flex--col">
            <Typography
                variant="caption"
                color={password.match(/(?=.*[a-z])/) ? 'success.main' : 'error'}
            >
                <i
                    className={
                        password.match(/(?=.*[a-z])/)
                            ? 'bi bi-check'
                            : 'bi bi-x'
                    }
                ></i>{' '}
                One lowercase character
            </Typography>
            <Typography
                variant="caption"
                color={password.match(/(?=.*[A-Z])/) ? 'success.main' : 'error'}
            >
                <i
                    className={
                        password.match(/(?=.*[A-Z])/)
                            ? 'bi bi-check'
                            : 'bi bi-x'
                    }
                ></i>{' '}
                One uppercase character
            </Typography>
            <Typography
                variant="caption"
                color={password.match(/(?=.*[0-9])/) ? 'success.main' : 'error'}
            >
                <i
                    className={
                        password.match(/(?=.*[0-9])/)
                            ? 'bi bi-check'
                            : 'bi bi-x'
                    }
                ></i>{' '}
                One number
            </Typography>
            <Typography
                variant="caption"
                color={
                    password.match(/(?=.*[!"#$%&'()*+,-./:;<=>?@^_`{|}~])/)
                        ? 'success.main'
                        : 'error'
                }
            >
                <i
                    className={
                        password.match(/(?=.*[!"#$%&'()*+,-./:;<=>?@^_`{|}~])/)
                            ? 'bi bi-check'
                            : 'bi bi-x'
                    }
                ></i>{' '}
                One special character
            </Typography>
            <Typography
                variant="caption"
                color={
                    password.length >= 8 && password.length <= 100
                        ? 'success.main'
                        : 'error'
                }
            >
                <i
                    className={
                        password.length >= 8 && password.length <= 100
                            ? 'bi bi-check'
                            : 'bi bi-x'
                    }
                ></i>{' '}
                8 - 100 characters
            </Typography>
        </Box>
    );
};

export default PasswordValidationList;
