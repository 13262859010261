import { Link, LinkProps } from '@mui/material';

export const FWLink = (props: LinkProps) => {
    return (
        <Link
            sx={{ cursor: 'pointer !important' }}
            {...props}
            underline="hover"
        />
    );
};
