import {
    HelpInternalPageName,
    HelpPageName,
} from '../../Redux/Slices/HelpInformationSlice';

export enum DataType {
    BLOCKS = 'BLOCKS',
    FAQS = 'FAQS',
    LIST = 'LIST',
}

export interface Blocks {
    type: DataType.BLOCKS;
    question: string;
    answer: string;
    title?: string;
    button?: string;
    image?: string;
}
export interface FAQs {
    type: DataType.FAQS;
    q: string;
    a: string;
    image?: string;
}
export interface List {
    type: DataType.LIST;
    question: string;
    lists: { listText?: string; listData: string[] }[];
    image?: string;
}

interface sectionVideos {
    url: string;
    title: string;
    para: string;
    duration: string;
    stepByStep: {
        startPara?: string;
        steps: {
            point: string;
            imageUrl?: string;
            customHeading?: string;
        }[];
    };
}

export interface IHelpInformationItem {
    sectionTitle: string;
    sectionIdentifier: HelpPageName;
    sectionPageIdentifier?: HelpInternalPageName;
    sectionVideos: sectionVideos[];
    data: (Blocks | FAQs | List)[];
}

export interface IHelpGlossary {
    title: string;
    subtext: string;
}
