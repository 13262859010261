import { FWMenuItem } from '../../Common/FWCustomMenu';
import { StandardPaymentReferenceDateEnum } from '../Interfaces/PaymentTermsInterface';
import { PeriodEnum } from '../Interfaces/PeriodInterface';

const paymentTermsOptionsString = [
    '0',
    '7',
    '14',
    '30',
    '45',
    '60',
    '90',
    '120',
    '150',
    '180',
];

const paymentTermsTimeOptionsString = [
    {
        label: 'Day(s)',
        value: 'days',
    },
    {
        label: 'Week(s)',
        value: 'weeks',
    },
    {
        label: 'Month(s)',
        value: 'months',
    },
];

const rateCurrencyMenuOptions = [
    {
        label: '$',
        value: 'dollars',
    },
    {
        label: 'Rs',
        value: 'rupees',
    },
];

const measurementUnitMenuOptions = [
    {
        label: 'kg(s)',
        value: 'kgs',
    },
    {
        label: 'unit(s)',
        value: 'units',
    },
];

export const PaymentTermsNumberMenuItems = paymentTermsOptionsString.map(
    (option, index) => {
        let label = option + ' days';
        return <FWMenuItem value={option}>{label}</FWMenuItem>;
    }
);

export const PaymentTermsTimeUnitMenuItems = paymentTermsTimeOptionsString.map(
    (option, index) => {
        return (
            <FWMenuItem value={option.value} key={index}>
                {option.label}
            </FWMenuItem>
        );
    }
);

export const rateCurrencyMenuItems = rateCurrencyMenuOptions.map(
    (option, index) => {
        return (
            <FWMenuItem value={option.value} key={index}>
                {option.label}
            </FWMenuItem>
        );
    }
);

export const measurementUnitMenuItems = measurementUnitMenuOptions.map(
    (option, index) => {
        return (
            <FWMenuItem value={option.value} key={index}>
                {option.label}
            </FWMenuItem>
        );
    }
);

const paymentTermsAppliedFromOptions = [
    {
        label: 'Invoice date',
        value: 'INVOICE_DATE',
    },
    {
        label: 'Receipt date',
        value: 'RECEIPT_DATE',
    },
    {
        label: 'Dispatch date',
        value: 'DISPATCH_DATE',
    },
];

export const PaymentTermsAppliedFromMenuItems =
    paymentTermsAppliedFromOptions.map((option, index) => {
        return (
            <FWMenuItem value={option.value} key={index}>
                {option.label}
            </FWMenuItem>
        );
    });

export const getPaymentTermsAppliedFromMenuItems = ({
    standardReferenceDateOptions,
    customReferenceDateOptions,
}: {
    standardReferenceDateOptions:
        | {
              [key in StandardPaymentReferenceDateEnum]: {
                  label: string;
                  enabled: boolean;
              };
          }
        | null;
    customReferenceDateOptions: string[];
}) => {
    const menuItems: JSX.Element[] = [];
    paymentTermsAppliedFromOptions.forEach((option, index) => {
        if (
            standardReferenceDateOptions &&
            standardReferenceDateOptions[
                option.value as StandardPaymentReferenceDateEnum
            ].enabled
        ) {
            menuItems.push(
                <FWMenuItem value={option.value} key={index}>
                    {
                        standardReferenceDateOptions[
                            option.value as StandardPaymentReferenceDateEnum
                        ].label
                    }
                </FWMenuItem>
            );
        }
    });
    customReferenceDateOptions.forEach((option, index) => {
        menuItems.push(
            <FWMenuItem value={option} key={index}>
                {option}
            </FWMenuItem>
        );
    });
    return menuItems;
};

export const getPaymentTermsPeriodMenuItems = () => {
    const menuItems: JSX.Element[] = [];
    Object.keys(PeriodEnum).forEach((option, index) => {
        // Convert option to a format like Day(s), Week(s), Month(s)
        let singularLabel = option.charAt(0) + option.slice(1).toLowerCase().slice(0, -1);
        let label = singularLabel + '(s)';
        menuItems.push(
            <FWMenuItem value={option} key={index}>
                {label}
            </FWMenuItem>
        );
    });
    return menuItems;
};
