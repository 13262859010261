import { getTemplateFrontOptionFromListOfTemplateItems } from '../../AdditionalCost/helpers';
import { IVendorForEventItem } from '../../Events/Interfaces/Buyer/RFQ.model';
import { IBackendSingleLatestBidResponse } from '../../Events/RFQAnalytics/Services/MainDataViewsService';
import { getFieldTypeContraintsFromBackend } from '../../FieldTypes/helpers/fieldTypesHelpers';
import {
    convertTemplateConstraintsItemToCustomFIeldType,
    dummyPaymentTermsOptions,
    getPaymentTermsDetails,
} from '../../Global/Helpers/templateApiSlice';
import { ITemplatePaymentTermsOptions } from '../../Global/Interfaces/TemplateInterface';
import {
    IRFQItemAddedVendors,
    IRFQVendorWhoOfferedBack,
} from '../../Models/RFQ.model';
import { convertAdditionalCostInterface } from '../../Organizations/Admin/Helpers/ItemHelpers';
import {
    IItemDetails,
    IMeasurement,
} from '../../Organizations/Admin/Interfaces/ItemDirectoryInterface';
import { baseApiSlice } from '../../Redux/Slices/BaseApiSlice';
import { IResponseItemVendors } from '../../Services/events/eventOperations';
import { del, post, put } from '../../Utils/api';
import { blankItemCartTemplate } from '../Helpers/itemAnalyticsTemplateHelpers';
import { cartTabType } from '../Hooks/Relationship/useItemCartDashboard';
import {
    IBlankNote,
    IFetchCartItemHistoryDashboardData,
    IFetchItemAnalyticsDashboardData,
    IFetchItemCartDashboardData,
    IItemAnalyticsEntityDashboardData,
    IItemAnalyticsNotes,
    IItemCartDashboardData,
    IItemCustomtext,
    IItemCustomtextDashboardResponse,
    IItemIndices,
    IItemRSSFeed,
    ITranscations,
    NotesModuleEnum,
} from '../Interfaces/ItemAnalyticsInterfaces';
import {
    IItemCartTemplate,
    IItemCartTemplateDetailResponse,
    IItemCartTemplateDetails,
    IItemCartTemplateResponse,
    ItemCartTemplateStandardFieldBackendNames,
    ItemCartTemplateStandardFieldsEnum,
} from '../Interfaces/ItemCartTemplateInterfaces';
import {
    CartItemDetails,
    ICartItemResponse,
    IItemAnalyticsDashboardData,
    NewCartItem,
} from '../Interfaces/itemCartItemInterfaces';

export type IDashBoardDataTransactions = {
    data: ITranscations[];
    metadata: {
        current_page: number;
        has_next: boolean;
        has_previous: boolean;
        total_pages: number;
        page_range: {
            start: number;
            stop: number;
        };
        count: number;
    };
    counts: {
        draft: number;
        ongoing: number;
        finished: number;
        all: number;
    };
};

export const getDashboardForItemAnalyticsTransactions = async ({
    dashboard_view,
    items_per_page,
    page_number,
    search_text,
    sort_fields,
    tab,
    enterpriseItemUuid,
    searchText,
    selectedBuyerEntity,
    status,
}: {
    dashboard_view: string;
    items_per_page: number;
    page_number: number;
    search_text: string;
    status: string | null;
    sort_fields: {
        field: string;
        ascending: boolean;
    }[];
    tab: string;
    enterpriseItemUuid?: string;
    searchText: string;
    selectedBuyerEntity?: string;
}): Promise<IDashBoardDataTransactions> => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await post<any, any>(`/dashboard/`, {
                dashboard_view: dashboard_view,
                items_per_page: items_per_page,
                page_number: page_number,
                search_text: search_text,
                sort_fields: sort_fields,
                tab: tab,
                query_data: {
                    status: status ? status : null,
                    enterprise_item_id: enterpriseItemUuid,
                    search_text: searchText !== '' ? searchText : null,
                    buyer_entity_id: selectedBuyerEntity
                        ? selectedBuyerEntity
                        : null,
                },
            });
            resolve(response.data);
        } catch (e) {
            reject(e);
        }
    });
};

const itemAnalyticsNotesBaseApiSlice = baseApiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getNotes: builder.query<
            IItemAnalyticsNotes[],
            {
                buyer_entity_id: string;
                enterprise_item_id: string;
                module: NotesModuleEnum;
            }
        >({
            query: ({ buyer_entity_id, enterprise_item_id, module }) => ({
                url: `/note/`,
                method: 'POST',
                body: {
                    module,
                    buyer_entity_id,
                    enterprise_item_id,
                },
            }),
        }),
        getVendorDetails: builder.query<
            IRFQItemAddedVendors[],
            {
                eventId: string;
                eventItemId: string;
            }
        >({
            query: ({ eventId, eventItemId }) => ({
                url: `/events/rfq/${eventId}/items/${eventItemId}/sellers/`,
            }),
            transformResponse: (
                baseQueryReturnValue: IResponseItemVendors[],
                meta,
                args
            ) => {
                let processed_data: IRFQItemAddedVendors[] =
                    baseQueryReturnValue.map((vendor) => ({
                        entity_id: vendor.seller_entity.entity_id,
                        entity_name: vendor.seller_entity.entity_name,
                        entity_logo: vendor.seller_entity.entity_logo[0],
                        entity_website_url:
                            vendor.seller_entity.contacts.websites[0],
                        enterprise_name: vendor.seller_entity.enterprise_name,
                        is_virtual: vendor.seller_entity.is_virtual,
                        verification_status:
                            vendor.seller_entity.verification_status,
                        seller_contacts: vendor.seller_contacts,
                    }));
                return processed_data;
            },
        }),
        getOfferDetails: builder.query<
            IRFQVendorWhoOfferedBack[],
            {
                eventId: string;
                rfq_item_id: string;
            }
        >({
            query: ({ eventId, rfq_item_id }) => ({
                url: `events/rfq/${eventId}/bids/sellers/items/latest/`,
            }),
            transformResponse: (
                baseQueryReturnValue: IBackendSingleLatestBidResponse[],
                meta,
                args: {
                    eventId: string;
                    rfq_item_id: string;
                }
            ) => {
                let processed_data: IRFQVendorWhoOfferedBack[] =
                    baseQueryReturnValue
                        ?.filter(
                            (vendor) =>
                                vendor.rfq_item.rfq_item_entry_id ===
                                args.rfq_item_id
                        )
                        .map((vendor) => ({
                            entity_id: vendor.seller_entity.entity_id,
                            entity_name: vendor.seller_entity.entity_name,
                        }));
                return processed_data;
            },
        }),
        deleteNode: builder.mutation<any, { noteId: string }>({
            query: ({ noteId }) => ({
                url: `/note/${noteId}/delete/`,
                method: 'DELETE',
            }),
        }),
    }),
});

export const {
    useGetNotesQuery,
    useDeleteNodeMutation,
    useGetOfferDetailsQuery,
    useGetVendorDetailsQuery,
} = itemAnalyticsNotesBaseApiSlice;

export const createNewNote = async (newNotePayload: IBlankNote) => {
    try {
        const res = await post<
            IBlankNote,
            {
                note_id: string;
            }
        >(`/note/create/`, newNotePayload);

        return res.data.note_id;
    } catch (err) {}
};

export const updateItemNote = async (
    noteId: string,
    updatedNote: {
        title: string;
        notes: string;
    }
) => {
    try {
        const res = await put<IItemAnalyticsNotes, any>(
            `/note/${noteId}/update/`,
            updatedNote
        );

        return res.data;
    } catch (err) {}
};

export const itemCartBaseApiSlice = baseApiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getEnterpriseItemrssFeed: builder.query<
            { feeds: IItemRSSFeed[] },
            {
                enterprise_item_id: string;
            }
        >({
            query: ({ enterprise_item_id }) => ({
                url: `/organization/item/${enterprise_item_id}/rss_feeds/`,
            }),
        }),
        getEnterpriseItemDataMutation: builder.mutation<
            IItemDetails,
            {
                enterprise_item_id: string;
            }
        >({
            query: ({ enterprise_item_id }) => ({
                url: `/organization/items/${enterprise_item_id}/admin/`,
            }),
            transformResponse: (baseQueryReturnValue: any, meta, args) => {
                const additionalCost = convertAdditionalCostInterface(
                    baseQueryReturnValue.buyer_additional_costs
                );

                convertAdditionalCostInterface(
                    baseQueryReturnValue.seller_additional_costs
                ).forEach((cost) => {
                    if (
                        additionalCost.find(
                            (c) =>
                                c.additional_cost_id === cost.additional_cost_id
                        ) === undefined
                    ) {
                        additionalCost.push(cost);
                    }
                });
                const matchData: IItemDetails = {
                    id: baseQueryReturnValue.enterprise_item_id,
                    itemCode: baseQueryReturnValue.code,
                    item_type: baseQueryReturnValue.item_type,
                    rss_feed_links: baseQueryReturnValue.rss_feed_links,
                    itemName: baseQueryReturnValue.name,
                    linked_currencies: baseQueryReturnValue.linked_currencies,
                    primary_index: baseQueryReturnValue.primary_index,
                    secondary_indices: baseQueryReturnValue.secondary_indices,

                    image: baseQueryReturnValue.image,
                    description: baseQueryReturnValue.description,
                    additionalCost: additionalCost?.filter(
                        (cost) => cost.cost_value !== null
                    ),
                    internal_notes: baseQueryReturnValue.internal_notes,
                    custom_fields: baseQueryReturnValue.custom_fields ?? [],
                    notes: baseQueryReturnValue.notes,
                    measurementUnits:
                        baseQueryReturnValue.measurement_units.item_measurement_units.map(
                            (unit: any) => {
                                const measurement: IMeasurement = {
                                    id: unit.measurement_unit_id,
                                    name: unit.measurement_unit_primary_name,
                                    category: unit.measurement_unit_category,
                                    type: unit.measurement_unit_value_type,
                                    abbreviation: unit.abbreviation ?? '',
                                    isVerifiedUnit: unit.is_default ?? false,
                                };
                                return measurement;
                            }
                        ),
                    buyerPricingInformation: {
                        ...baseQueryReturnValue.buyer_pricing_information,
                        price: baseQueryReturnValue.buyer_pricing_information
                            ? baseQueryReturnValue.buyer_pricing_information
                                  .price
                            : null,
                        currencyCodeId:
                            baseQueryReturnValue.buyer_pricing_information
                                ? baseQueryReturnValue.buyer_pricing_information
                                      .currency_code_id
                                : null,
                        additionalCost: convertAdditionalCostInterface(
                            baseQueryReturnValue.buyer_additional_costs
                        )?.filter((cost) => cost.cost_value !== null),
                    },
                    sellerPricingInformation: {
                        ...baseQueryReturnValue.buyer_pricing_information,
                        price: baseQueryReturnValue.seller_pricing_information
                            ? baseQueryReturnValue.seller_pricing_information
                                  .price
                            : null,
                        currencyCodeId:
                            baseQueryReturnValue.seller_pricing_information
                                ? baseQueryReturnValue
                                      .seller_pricing_information
                                      .currency_code_id
                                : null,
                        additionalCost: convertAdditionalCostInterface(
                            baseQueryReturnValue.seller_additional_costs
                        )?.filter((cost) => cost.cost_value !== null),
                    },
                    isBuyer: baseQueryReturnValue.is_buyer,
                    isSeller: baseQueryReturnValue.is_seller,
                    standardTerms: {
                        lead: null,
                        payment: null,
                        prePayment: null,
                    },
                    specifications:
                        baseQueryReturnValue.attributes.attributes.map(
                            (attribute: any) => ({
                                name: attribute.attribute_name,
                                value: attribute.attribute_value,
                            })
                        ),
                    customIds: baseQueryReturnValue.custom_ids.custom_ids.map(
                        (customId: any) => {
                            return {
                                category: customId.category,
                                name: customId.name,
                                value: customId.value,
                            };
                        }
                    ),
                    status: baseQueryReturnValue.status,
                    tags: baseQueryReturnValue.tags,
                    bom_template: null,
                };

                return matchData;
            },
        }),
        getEnterpriseItemDetails: builder.query<
            IItemDetails,
            {
                enterprise_item_id: string;
            }
        >({
            query: ({ enterprise_item_id }) => ({
                url: `/organization/items/${enterprise_item_id}/admin/`,
            }),
            transformResponse: (baseQueryReturnValue: any, meta, args) => {
                const additionalCost = convertAdditionalCostInterface(
                    baseQueryReturnValue.buyer_additional_costs
                );

                convertAdditionalCostInterface(
                    baseQueryReturnValue.seller_additional_costs
                ).forEach((cost) => {
                    if (
                        additionalCost.find(
                            (c) =>
                                c.additional_cost_id === cost.additional_cost_id
                        ) === undefined
                    ) {
                        additionalCost.push(cost);
                    }
                });
                const matchData: IItemDetails = {
                    id: baseQueryReturnValue.enterprise_item_id,
                    itemCode: baseQueryReturnValue.code,
                    item_type: baseQueryReturnValue.item_type,
                    rss_feed_links: baseQueryReturnValue.rss_feed_links,
                    itemName: baseQueryReturnValue.name,
                    linked_currencies: baseQueryReturnValue.linked_currencies,
                    primary_index: baseQueryReturnValue.primary_index,
                    secondary_indices: baseQueryReturnValue.secondary_indices,
                    bom_template: baseQueryReturnValue.bom_template,
                    image: baseQueryReturnValue.image,
                    description: baseQueryReturnValue.description,
                    additionalCost: additionalCost?.filter(
                        (cost) => cost.cost_value !== null
                    ),
                    internal_notes: baseQueryReturnValue.internal_notes,
                    custom_fields: baseQueryReturnValue.custom_fields ?? [],
                    notes: baseQueryReturnValue.notes,
                    measurementUnits:
                        baseQueryReturnValue.measurement_units.item_measurement_units.map(
                            (unit: any) => {
                                const measurement: IMeasurement = {
                                    id: unit.measurement_unit_id,
                                    name: unit.measurement_unit_primary_name,
                                    category: unit.measurement_unit_category,
                                    type: unit.measurement_unit_value_type,
                                    abbreviation: unit.abbreviation ?? '',
                                    isVerifiedUnit: unit.is_default ?? false,
                                };
                                return measurement;
                            }
                        ),
                    buyerPricingInformation: {
                        ...baseQueryReturnValue.buyer_pricing_information,
                        price: baseQueryReturnValue.buyer_pricing_information
                            ? baseQueryReturnValue.buyer_pricing_information
                                  .price
                            : null,
                        currencyCodeId:
                            baseQueryReturnValue.buyer_pricing_information
                                ? baseQueryReturnValue.buyer_pricing_information
                                      .currency_code_id
                                : null,
                        additionalCost: convertAdditionalCostInterface(
                            baseQueryReturnValue.buyer_additional_costs
                        )?.filter((cost) => cost.cost_value !== null),
                    },
                    sellerPricingInformation: {
                        ...baseQueryReturnValue.buyer_pricing_information,
                        price: baseQueryReturnValue.seller_pricing_information
                            ? baseQueryReturnValue.seller_pricing_information
                                  .price
                            : null,
                        currencyCodeId:
                            baseQueryReturnValue.seller_pricing_information
                                ? baseQueryReturnValue
                                      .seller_pricing_information
                                      .currency_code_id
                                : null,
                        additionalCost: convertAdditionalCostInterface(
                            baseQueryReturnValue.seller_additional_costs
                        )?.filter((cost) => cost.cost_value !== null),
                    },
                    isBuyer: baseQueryReturnValue.is_buyer,
                    isSeller: baseQueryReturnValue.is_seller,
                    standardTerms: {
                        lead: null,
                        payment: null,
                        prePayment: null,
                    },
                    specifications: baseQueryReturnValue.attributes.map(
                        (attribute: any) => ({
                            name: attribute.attribute_name,
                            value: attribute.attribute_values.map(
                                (val: any) => val.value
                            ),
                        })
                    ),
                    customIds: baseQueryReturnValue.custom_ids.custom_ids.map(
                        (customId: any) => {
                            return {
                                category: customId.category,
                                name: customId.name,
                                value: customId.value,
                            };
                        }
                    ),
                    status: baseQueryReturnValue.status,
                    tags: baseQueryReturnValue.tags,
                };

                return matchData;
            },
        }),
        getItemQuantityInEvents: builder.query<
            {
                ONGOING: number;
                PAUSED: number;
                AWARD_STAGE: number;
            },
            {
                enterprise_item_id: string;
            }
        >({
            query: ({ enterprise_item_id }) => ({
                url: `/events/rfq/total_quantity/item/${enterprise_item_id}/`,
            }),
        }),
        getItemQuantityInRequisitions: builder.query<
            {
                ONGOING: number;
                SUBMITTED: number;
                PARTIALLY_FULFILLED: number;
            },
            {
                enterprise_item_id: string;
            }
        >({
            query: ({ enterprise_item_id }) => ({
                url: `/requisitions/total_quantity/item/${enterprise_item_id}/`,
            }),
        }),
        getAllVendorsForCartItem: builder.query<
            IVendorForEventItem[],
            {
                buyerEntityId: string;
                enterprise_item_id: string;
            }
        >({
            query: ({ buyerEntityId, enterprise_item_id }) => ({
                url: `/organization/vendor_master/entity/${buyerEntityId}/item/${enterprise_item_id}/`,
            }),
            transformResponse(baseQueryReturnValue: any[], meta, arg) {
                const preferredVendors: IVendorForEventItem[] =
                    baseQueryReturnValue
                        .filter((vendor: any) => vendor.status === 'PREFERRED')
                        .map((vendor: any, idx) => ({
                            id: vendor.seller_entity.entity_id,
                            entity_vendor_master_id:
                                vendor.entity_vendor_master_id,
                            enterprise_item: vendor.enterprise_item,
                            buyer_enterprise: vendor.buyer_enterprise,
                            status: vendor.status,
                            seller_entity: vendor.seller_entity,
                            seller_contacts: [vendor.primary_vendor_contact],
                            vendor_code:
                                vendor.enterprise_vendor_master.vendor_code,
                            no: idx,
                        }));

                return preferredVendors;
            },
        }),
        getItemCartDashboard: builder.mutation<
            IFetchItemCartDashboardData,
            {
                page_number: number;
                items_per_page: number;
                sort_fields: { field: string; ascending: boolean }[];
                search_text: string;
                tab: cartTabType;
                query_data: {
                    buyer_entity_id: string | null;
                    user_permission: string | null;
                    cart_permission: string | null;
                };
            }
        >({
            query: ({
                items_per_page,
                page_number,
                query_data,
                search_text,
                sort_fields,
                tab,
            }) => ({
                url: `/dashboard/`,
                method: 'POST',
                body: {
                    dashboard_view: 'item_analytics_carts',
                    tab,
                    query_data,
                    search_text,
                    page_number,
                    sort_fields,
                    items_per_page,
                },
            }),
        }),
        getCartItemHistoryDashboard: builder.mutation<
            IFetchCartItemHistoryDashboardData,
            {
                page_number: number;
                items_per_page: number;
                sort_fields: { field: string; ascending: boolean }[];
                search_text: string;
                tab: cartTabType;
                query_data: {
                    enterprise_item_id: string;
                };
            }
        >({
            query: ({
                items_per_page,
                page_number,
                query_data,
                search_text,
                sort_fields,
                tab,
            }) => ({
                url: `/dashboard/`,
                method: 'POST',
                body: {
                    dashboard_view: 'enterprise_item_purchase_order_items',
                    tab,
                    query_data,
                    search_text,
                    page_number,
                    sort_fields,
                    items_per_page,
                },
            }),
        }),
        getItemAnalyticsEnterpriseDashboard: builder.mutation<
            IFetchItemAnalyticsDashboardData,
            {
                page_number: number;
                items_per_page: number;
                sort_fields: { field: string; ascending: boolean }[];
                search_text: string;
                tab: cartTabType;
                dashboard_view: string;
                query_data: {
                    entity_id?: string | null;
                    user_permission?: string | null;
                    cart_permission?: string | null;
                };
            }
        >({
            query: ({
                items_per_page,
                page_number,
                query_data,
                search_text,
                sort_fields,
                tab,
                dashboard_view,
            }) => ({
                url: `/dashboard/`,
                method: 'POST',
                body: {
                    dashboard_view,
                    tab,
                    query_data,
                    search_text,
                    page_number,
                    sort_fields,
                    items_per_page,
                },
            }),
        }),
        getItemAnalyticsEntityDashboard: builder.mutation<
            IFetchItemAnalyticsDashboardData,
            {
                page_number: number;
                items_per_page: number;
                sort_fields: { field: string; ascending: boolean }[];
                search_text: string;
                tab: cartTabType;
                dashboard_view: string;
                query_data: {
                    entity_id?: string | null;
                    user_permission?: string | null;
                    cart_permission?: string | null;
                };
            }
        >({
            query: ({
                items_per_page,
                page_number,
                query_data,
                search_text,
                sort_fields,
                tab,
                dashboard_view,
            }) => ({
                url: `/dashboard/`,
                method: 'POST',
                body: {
                    dashboard_view,
                    tab,
                    query_data,
                    search_text,
                    page_number,
                    sort_fields,
                    items_per_page,
                },
            }),
            transformResponse(
                baseQueryReturnValue: any,
                meta,
                arg
            ): IFetchItemAnalyticsDashboardData {
                const res: IFetchItemAnalyticsDashboardData = {
                    counts: baseQueryReturnValue.counts,
                    data: baseQueryReturnValue.data.map(
                        (
                            data: IItemAnalyticsEntityDashboardData
                        ): IItemAnalyticsDashboardData => ({
                            ...data.enterprise_item,
                            additional_details: {
                                template_id:
                                    data.enterprise_item.additional_details
                                        .template_id,
                            },
                            attributes: data.enterprise_item.attributes,
                            buyer_enterprise: data.buyer_enterprise,
                            buyer_entity: data.buyer_entity,
                            buyer_pricing_information: {
                                ...data.enterprise_item
                                    .buyer_pricing_information,
                                additional_costs: null,
                                taxes: null,
                            },
                            seller_pricing_information: {
                                ...data.enterprise_item
                                    .seller_pricing_information,
                                additional_costs: null,
                                taxes: null,
                            },
                            code: data.enterprise_item.code,
                            created_by_user: data.created_by_user,
                            created_datetime: data.created_datetime,
                            custom_fields: data.enterprise_item
                                .custom_fields as any,
                            custom_ids: data.enterprise_item.custom_ids as any,
                            custom_text_count: data.custom_text_count,
                            deleted_by_user: data.deleted_by_user,
                            deleted_datetime: data.deleted_datetime,
                            description: data.enterprise_item.description,
                            enterprise_item_id:
                                data.enterprise_item.enterprise_item_id,
                            indices_count: data.indices_count,
                            linked_currencies_count:
                                data.linked_currencies_count,
                            internal_notes:
                                data.enterprise_item.internal_notes ?? '',
                            is_buyer: data.enterprise_item.is_buyer,
                            is_seller: data.enterprise_item.is_seller,
                            item_type: data.enterprise_item.item_type,
                        })
                    ),
                    metadata: baseQueryReturnValue.metadata,
                };

                return res;
            },
        }),
        getItemCartInformation: builder.mutation<
            ICartItemResponse,
            {
                page_number: number;
                items_per_page: number;
                sort_fields: { field: string; ascending: boolean }[];
                search_text: string;
                tab: cartTabType;
                query_data: {
                    cart_id: string | null;
                };
            }
        >({
            query: ({
                items_per_page,
                page_number,
                query_data,
                search_text,
                sort_fields,
                tab,
            }) => ({
                url: `/dashboard/`,
                method: 'POST',
                body: {
                    dashboard_view: 'item_analytics_cart_items',
                    tab,
                    query_data,
                    search_text,
                    page_number,
                    sort_fields,
                    items_per_page,
                },
            }),
        }),
        getItemCartInformationNonPaginated: builder.query<
            IItemCartDashboardData[],
            {
                buyer_entity_id: string;
                user_permission: string;
                cart_permission: string;
            }
        >({
            query: ({ buyer_entity_id, cart_permission, user_permission }) => ({
                url: `/organization/item_analytics/cart/${buyer_entity_id}/`,
                method: 'POST',
                body: { cart_permission, user_permission },
            }),
        }),
        getItemInformationInCart: builder.query<
            CartItemDetails[],
            {
                cart_id: string;
            }
        >({
            query: ({ cart_id }) => ({
                url: `/organization/item_analytics/cart/${cart_id}/items/`,
            }),
        }),
        getItemCustomTextDashboard: builder.mutation<
            IItemCustomtextDashboardResponse,
            {
                page_number: number;
                items_per_page: number;
                sort_fields: { field: string; ascending: boolean }[];
                search_text: string;
                query_data: {
                    item_id: string;
                };
            }
        >({
            query: ({
                items_per_page,
                page_number,
                query_data,
                search_text,
                sort_fields,
            }) => ({
                url: `/dashboard/`,
                method: 'POST',
                body: {
                    dashboard_view: 'item_analytics_custom_text',
                    tab: 'all',
                    query_data,
                    search_text,
                    page_number,
                    sort_fields,
                    items_per_page,
                },
            }),
        }),
        updateCartItemInformation: builder.mutation<
            CartItemDetails,
            {
                cartItemId: string;
                updatedCartItem: NewCartItem;
            }
        >({
            query: ({ cartItemId, updatedCartItem }) => ({
                url: `/organization/item_analytics/cart/item/${cartItemId}/update/`,
                method: 'PUT',
                body: updatedCartItem,
            }),
        }),
    }),
});

export const {
    useGetItemAnalyticsEnterpriseDashboardMutation,
    useGetItemAnalyticsEntityDashboardMutation,
    useGetItemCartDashboardMutation,
    useGetItemCartInformationMutation,
    useGetItemCustomTextDashboardMutation,
    useGetItemCartInformationNonPaginatedQuery,
    useGetItemInformationInCartQuery,
    useGetEnterpriseItemDetailsQuery,
    useGetEnterpriseItemDataMutationMutation,
    useGetItemQuantityInEventsQuery,
    useGetItemQuantityInRequisitionsQuery,
    useGetAllVendorsForCartItemQuery,
    useUpdateCartItemInformationMutation,
    useGetCartItemHistoryDashboardMutation,
    useGetEnterpriseItemrssFeedQuery,
} = itemCartBaseApiSlice;

export const createNewCartItem = async (newItem: NewCartItem) => {
    try {
        let res = await post<
            NewCartItem,
            { cart_id: string; cart_item: CartItemDetails }
        >(`/organization/item_analytics/cart/item/add/`, newItem);

        return res.data;
    } catch (error) {
        throw new Error('failed to create item');
    }
};

export const updateCartItem = async (
    cartItemId: string,
    updatedCartItem: NewCartItem
) => {
    try {
        let res = await put<{ updatedCartItem: NewCartItem }, CartItemDetails>(
            `/organization/item_analytics/cart/item/${cartItemId}/update/`,
            updatedCartItem
        );

        return res.data;
    } catch (error) {
        throw new Error('failed to update item');
    }
};

export const deleteCartItemAPI = async (cartItemId: string) => {
    try {
        let res = await del<{ cartItemId: string }>(
            `/organization/item_analytics/cart/item/${cartItemId}/delete/`
        );

        return res.data;
    } catch (error) {
        throw new Error('failed to update item');
    }
};

export const createCustomtextAPI = async (
    enterprise_item_id: string,
    customText: string
) => {
    try {
        const res = await post<
            {
                text: string;
            },
            IItemCustomtext
        >(`/organization/item/${enterprise_item_id}/custom_text/create/`, {
            text: customText,
        });

        return res.data;
    } catch (err) {
        throw new Error('failed to create custom text!');
    }
};

export const updateCustomtextAPI = async (
    enterprise_item_id: string,
    customTextId: string,
    customText: string
) => {
    try {
        const res = await put<
            {
                text: string;
            },
            IItemCustomtext
        >(
            `/organization/item/${enterprise_item_id}/custom_text/${customTextId}/update/`,
            {
                text: customText,
            }
        );

        return res.data;
    } catch (err) {
        throw new Error('failed to create custom text!');
    }
};

export const deleteCustomtextAPI = async (
    enterprise_item_id: string,
    customTextId: string
) => {
    try {
        const res = await del(
            `/organization/item/${enterprise_item_id}/custom_text/${customTextId}/delete/`
        );

        return res.data;
    } catch (err) {
        throw new Error('failed to create custom text!');
    }
};

const itemCartTemplateBaseApiSlice = baseApiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getItemCartTemplates: builder.query<
            IItemCartTemplateDetails[],
            {
                entityId?: string;
            }
        >({
            query: ({ entityId }) =>
                `/module_templates/?${
                    entityId ? `entity_id=${entityId}` : ''
                }&template_type=ITEM_CART`,
            transformResponse: (
                baseQueryReturnValue: IItemCartTemplateResponse[],
                meta,
                args
            ) => {
                return baseQueryReturnValue[0].templates;
            },
        }),

        getItemCartTemplateById: builder.query<
            IItemCartTemplate,
            { templateId: string; entityId: string }
        >({
            query: ({ entityId, templateId }) =>
                `/module_templates/${entityId}/${templateId}`,
            transformResponse: (
                baseQueryReturnValue: IItemCartTemplateDetailResponse,
                meta,
                args
            ) => {
                const response: IItemCartTemplate = JSON.parse(
                    JSON.stringify(blankItemCartTemplate)
                );

                response.templateId = baseQueryReturnValue.template_id;
                response.templateName = baseQueryReturnValue.name;

                const templateSections = baseQueryReturnValue.section_list.map(
                    (section) => {
                        return {
                            ...section,
                            section_items: section.section_items.map(
                                (item: any) => {
                                    return {
                                        ...item,
                                        alternate_name:
                                            item.alternate_name.replace(
                                                '_',
                                                ''
                                            ),
                                        name: item.name.replace('_', ''),
                                    };
                                }
                            ),
                        };
                    }
                );
                baseQueryReturnValue = {
                    ...baseQueryReturnValue,
                    section_list: templateSections,
                };

                const essentialTermsfromBackend =
                    baseQueryReturnValue.section_list.find(
                        (section) => section.name === 'Item Terms'
                    );

                response.sections.ESSENTIAL_TERMS = {
                    fieldList: [],
                    label:
                        essentialTermsfromBackend &&
                        essentialTermsfromBackend?.alternate_name
                            ? essentialTermsfromBackend.alternate_name
                            : 'Item Terms',
                };

                const essentialTermsItemsfromBackend =
                    essentialTermsfromBackend!.section_items
                        .filter(
                            (item) =>
                                [
                                    'Quantity',
                                    'Delivery date',
                                    'Cost center',
                                    'GL',
                                    'Project',
                                    'Additional costs and Taxes',
                                    'Discount',
                                ].includes(item.name) ||
                                item.parent_section_item === null
                        )
                        .filter(
                            (item) =>
                                ![
                                    'Item',
                                    'Specification',
                                    'Measurement unit',
                                    'Quantity Information',
                                    'Discount information',
                                    'Overall Discount information',
                                ].includes(item.name)
                        );

                essentialTermsItemsfromBackend.forEach((item) => {
                    if (
                        Object.keys(
                            ItemCartTemplateStandardFieldBackendNames
                        ).includes(item.name)
                    ) {
                        if (
                            !item.additional_information.is_hidden &&
                            item.name !== 'Delivery date'
                        ) {
                            response.sections.ESSENTIAL_TERMS.fieldList.push({
                                fieldType: 'STANDARD',
                                field: ItemCartTemplateStandardFieldBackendNames[
                                    item.name
                                ],
                            });
                        }

                        let formattedData = {
                            label: item.alternate_name
                                ? item.alternate_name
                                : item.name,
                            description: item.description,
                            buyerSettings: {
                                required: item.is_required,
                                showField: !(
                                    item.additional_information.is_hidden ??
                                    false
                                ),
                            },
                            sellerSettings: {
                                negotiable:
                                    !(
                                        item.additional_information.is_hidden ??
                                        false
                                    ) &&
                                    (item.additional_information.is_visible ??
                                        true) &&
                                    (item.additional_information
                                        .is_negotiable ??
                                        true),
                                showField:
                                    !(
                                        item.additional_information.is_hidden ??
                                        false
                                    ) &&
                                    (item.additional_information.is_visible ??
                                        true),
                                isMandatory: item.is_mandatory,
                            },
                            fieldLevel: item.field_level,
                        };
                        if (
                            ItemCartTemplateStandardFieldBackendNames[
                                item.name
                            ] ===
                            ItemCartTemplateStandardFieldsEnum.PAYMENT_TERMS
                        ) {
                            const formattedDataWithPaymentOptions: ITemplatePaymentTermsOptions =
                                {
                                    ...formattedData,
                                    paymentTermOptions:
                                        // TODO: Remove dummy data
                                        dummyPaymentTermsOptions,
                                    standardFieldCategory: 'PAYMENT_TERMS',
                                };

                            response.fieldSettings.standardFields[
                                ItemCartTemplateStandardFieldsEnum.PAYMENT_TERMS
                            ] = formattedDataWithPaymentOptions;
                        } else if (
                            ItemCartTemplateStandardFieldBackendNames[
                                item.name
                            ] === ItemCartTemplateStandardFieldsEnum.TAX
                        ) {
                            response.fieldSettings.standardFields[
                                ItemCartTemplateStandardFieldsEnum.TAX
                            ] = {
                                ...formattedData,
                                standardFieldCategory: 'TAX',
                                taxesOptions:
                                    getTemplateFrontOptionFromListOfTemplateItems(
                                        essentialTermsfromBackend?.section_items.filter(
                                            (sectionItem) =>
                                                sectionItem.parent_section_item ===
                                                item.section_item_id
                                        ) ?? []
                                    ),
                                buyerSettings: {
                                    ...formattedData.buyerSettings,
                                    showField: formattedData.buyerSettings
                                        .showField
                                        ? getTemplateFrontOptionFromListOfTemplateItems(
                                              essentialTermsfromBackend?.section_items.filter(
                                                  (sectionItem) =>
                                                      sectionItem.parent_section_item ===
                                                      item.section_item_id
                                              ) ?? []
                                          ).length > 0
                                        : false,
                                },
                                sellerSettings: {
                                    ...formattedData.sellerSettings,
                                    showField: formattedData.sellerSettings
                                        .showField
                                        ? getTemplateFrontOptionFromListOfTemplateItems(
                                              essentialTermsfromBackend?.section_items.filter(
                                                  (sectionItem) =>
                                                      sectionItem.parent_section_item ===
                                                      item.section_item_id
                                              ) ?? []
                                          ).length > 0
                                        : false,
                                },
                            };
                        } else if (
                            ItemCartTemplateStandardFieldBackendNames[
                                item.name
                            ] ===
                            ItemCartTemplateStandardFieldsEnum.ADDITIONAL_COSTS
                        ) {
                            response.fieldSettings.standardFields[
                                ItemCartTemplateStandardFieldsEnum.ADDITIONAL_COSTS
                            ] = {
                                ...formattedData,
                                standardFieldCategory: 'ADDITIONAL_COSTS',
                                additionalCostOptions:
                                    getTemplateFrontOptionFromListOfTemplateItems(
                                        essentialTermsfromBackend?.section_items.filter(
                                            (sectionItem) =>
                                                sectionItem.parent_section_item ===
                                                item.section_item_id
                                        ) ?? []
                                    ),
                                buyerSettings: {
                                    ...formattedData.buyerSettings,
                                    showField: formattedData.buyerSettings
                                        .showField
                                        ? getTemplateFrontOptionFromListOfTemplateItems(
                                              essentialTermsfromBackend?.section_items.filter(
                                                  (sectionItem) =>
                                                      sectionItem.parent_section_item ===
                                                      item.section_item_id
                                              ) ?? []
                                          ).length > 0
                                        : false,
                                },
                                sellerSettings: {
                                    ...formattedData.sellerSettings,
                                    showField: formattedData.sellerSettings
                                        .showField
                                        ? getTemplateFrontOptionFromListOfTemplateItems(
                                              essentialTermsfromBackend?.section_items.filter(
                                                  (sectionItem) =>
                                                      sectionItem.parent_section_item ===
                                                      item.section_item_id
                                              ) ?? []
                                          ).length > 0
                                        : false,
                                },
                            };
                        } else if (
                            ItemCartTemplateStandardFieldBackendNames[
                                item.name
                            ] === ItemCartTemplateStandardFieldsEnum.DISCOUNT
                        ) {
                            const listOfSectionItemsFromTemplate =
                                essentialTermsfromBackend?.section_items.filter(
                                    (sectionItem) =>
                                        sectionItem.parent_section_item ===
                                        item.section_item_id
                                ) ?? [];

                            listOfSectionItemsFromTemplate.push(item);

                            response.fieldSettings.standardFields[
                                ItemCartTemplateStandardFieldsEnum.DISCOUNT
                            ] = {
                                ...formattedData,
                                standardFieldCategory: 'DISCOUNT',
                                discountOptions:
                                    getTemplateFrontOptionFromListOfTemplateItems(
                                        listOfSectionItemsFromTemplate
                                    ),
                                buyerSettings: {
                                    ...formattedData.buyerSettings,
                                    showField: formattedData.buyerSettings
                                        .showField
                                        ? getTemplateFrontOptionFromListOfTemplateItems(
                                              listOfSectionItemsFromTemplate
                                          ).length > 0
                                        : false,
                                },
                                sellerSettings: {
                                    ...formattedData.sellerSettings,
                                    showField: formattedData.sellerSettings
                                        .showField
                                        ? getTemplateFrontOptionFromListOfTemplateItems(
                                              listOfSectionItemsFromTemplate
                                          ).length > 0
                                        : false,
                                },
                            };
                        } else {
                            response.fieldSettings.standardFields = {
                                ...response.fieldSettings.standardFields,
                                [ItemCartTemplateStandardFieldBackendNames[
                                    item.name
                                ]]: formattedData,
                            };
                        }
                    } else if (
                        item.name !== 'Discounts' &&
                        item.name !== 'Tax'
                    ) {
                        if (!item.additional_information.is_hidden)
                            response.sections.ESSENTIAL_TERMS.fieldList.push({
                                fieldType: 'CUSTOM',
                                field: item.name,
                            });
                        response.fieldSettings.customFields[item.name] = {
                            label: item.alternate_name
                                ? item.alternate_name
                                : item.name,
                            description: item.description,
                            buyerSettings: {
                                required: item.is_required,
                                showField: !(
                                    item.additional_information.is_hidden ??
                                    false
                                ),
                            },
                            sellerSettings: {
                                negotiable:
                                    !(
                                        item.additional_information.is_hidden ??
                                        false
                                    ) &&
                                    (item.additional_information.is_visible ??
                                        true) &&
                                    (item.additional_information
                                        .is_negotiable ??
                                        true),
                                showField:
                                    !(
                                        item.additional_information.is_hidden ??
                                        false
                                    ) &&
                                    (item.additional_information.is_visible ??
                                        true),
                                isMandatory: item.is_mandatory,
                            },
                            type: convertTemplateConstraintsItemToCustomFIeldType(
                                item.constraints.field_type
                            ),
                            constraints: getFieldTypeContraintsFromBackend({
                                field_type: item.constraints.field_type,
                                choices: item.constraints.choices,
                                max_limit: item.constraints.max_limit,
                                min_limit: item.constraints.min_limit,
                                choice_type: item.constraints.choice_type,
                            }),
                        };
                    }
                });

                const paymentDeliveryfromBackend =
                    baseQueryReturnValue.section_list.find(
                        (section) =>
                            section.name === 'Payment and Delivery Terms'
                    );

                response.sections.PAYMENT_AND_DELIVERY_TERMS.label =
                    paymentDeliveryfromBackend &&
                    paymentDeliveryfromBackend.alternate_name
                        ? paymentDeliveryfromBackend.alternate_name
                        : 'Payment and Delivery Terms';
                const paymentDeliveryItemsfromBackend =
                    paymentDeliveryfromBackend!.section_items
                        .filter((item) => item.parent_section_item === null)
                        .filter((item) => ![''].includes(item.name));

                paymentDeliveryItemsfromBackend.forEach((item) => {
                    if (
                        Object.keys(
                            ItemCartTemplateStandardFieldBackendNames
                        ).includes(item.name)
                    ) {
                        if (!item.additional_information.is_hidden)
                            response.sections.PAYMENT_AND_DELIVERY_TERMS.fieldList.push(
                                {
                                    fieldType: 'STANDARD',
                                    field: ItemCartTemplateStandardFieldBackendNames[
                                        item.name
                                    ],
                                }
                            );

                        const formattedData = {
                            label: item.alternate_name
                                ? item.alternate_name
                                : item.name,
                            description: item.description,
                            buyerSettings: {
                                required: item.is_required,
                                showField: !(
                                    item.additional_information.is_hidden ??
                                    false
                                ),
                            },
                            sellerSettings: {
                                negotiable:
                                    !(
                                        item.additional_information.is_hidden ??
                                        false
                                    ) &&
                                    (item.additional_information.is_visible ??
                                        true) &&
                                    (item.additional_information
                                        .is_negotiable ??
                                        true),
                                showField:
                                    !(
                                        item.additional_information.is_hidden ??
                                        false
                                    ) &&
                                    (item.additional_information.is_visible ??
                                        true),
                                isMandatory: item.is_mandatory,
                            },
                            fieldLevel: item.field_level,
                        };
                        if (
                            ItemCartTemplateStandardFieldBackendNames[
                                item.name
                            ] ===
                            ItemCartTemplateStandardFieldsEnum.PAYMENT_TERMS
                        ) {
                            const paymentTermsOption = getPaymentTermsDetails(
                                paymentDeliveryfromBackend!.section_items
                            );
                            const formattedDataWithPaymentOptions: ITemplatePaymentTermsOptions =
                                {
                                    ...formattedData,
                                    paymentTermOptions: paymentTermsOption,
                                    standardFieldCategory: 'PAYMENT_TERMS',
                                };
                            response.fieldSettings.standardFields[
                                ItemCartTemplateStandardFieldsEnum.PAYMENT_TERMS
                            ] = formattedDataWithPaymentOptions;
                        } else {
                            response.fieldSettings.standardFields[
                                ItemCartTemplateStandardFieldBackendNames[
                                    item.name
                                ]
                            ] = formattedData as any;
                        }
                    } else if (item.name !== 'Prepayment percentage') {
                        // if (!item.additional_information.is_hidden)
                        response.sections.PAYMENT_AND_DELIVERY_TERMS.fieldList.push(
                            {
                                fieldType: 'CUSTOM',
                                field: item.name,
                            }
                        );
                        response.fieldSettings.customFields[item.name] = {
                            label: item.alternate_name
                                ? item.alternate_name
                                : item.name,
                            description: item.description,
                            buyerSettings: {
                                required: item.is_required,
                                showField: !(
                                    item.additional_information.is_hidden ??
                                    false
                                ),
                            },
                            sellerSettings: {
                                negotiable:
                                    !(
                                        item.additional_information.is_hidden ??
                                        false
                                    ) &&
                                    (item.additional_information.is_visible ??
                                        true) &&
                                    (item.additional_information
                                        .is_negotiable ??
                                        true),
                                showField:
                                    !(
                                        item.additional_information.is_hidden ??
                                        false
                                    ) &&
                                    (item.additional_information.is_visible ??
                                        true),
                                isMandatory: item.is_mandatory,
                            },
                            type: convertTemplateConstraintsItemToCustomFIeldType(
                                item.constraints.field_type
                            ),
                            constraints: getFieldTypeContraintsFromBackend({
                                field_type: item.constraints.field_type,
                                choices: item.constraints.choices,
                                max_limit: item.constraints.max_limit,
                                min_limit: item.constraints.min_limit,
                                choice_type: item.constraints.choice_type,
                            }),
                        };
                    }
                });

                const addtionalInformationfromBackend =
                    baseQueryReturnValue.section_list.find(
                        (section) => section.name === 'Additional Information'
                    );
                response.sections.ADDITIONAL_DETAILS.label =
                    addtionalInformationfromBackend &&
                    addtionalInformationfromBackend.alternate_name
                        ? addtionalInformationfromBackend.alternate_name
                        : 'Additional Information';
                const addtionalInformationItemsfromBackend =
                    addtionalInformationfromBackend!.section_items
                        .filter((item) => item.parent_section_item === null)
                        .filter((item) => ![''].includes(item.name));
                addtionalInformationItemsfromBackend.forEach((item) => {
                    if (
                        Object.keys(
                            ItemCartTemplateStandardFieldBackendNames
                        ).includes(item.name)
                    ) {
                        response.sections.ADDITIONAL_DETAILS.fieldList.push({
                            fieldType: 'STANDARD',
                            field: ItemCartTemplateStandardFieldBackendNames[
                                item.name
                            ],
                        });

                        const formattedData = {
                            label: item.alternate_name
                                ? item.alternate_name
                                : item.name,
                            description: item.description,
                            buyerSettings: {
                                required: item.is_required,
                                showField: !(
                                    item.additional_information.is_hidden ??
                                    false
                                ),
                            },
                            sellerSettings: {
                                negotiable:
                                    !(
                                        item.additional_information.is_hidden ??
                                        false
                                    ) &&
                                    (item.additional_information.is_visible ??
                                        true) &&
                                    (item.additional_information
                                        .is_negotiable ??
                                        true),
                                showField:
                                    !(
                                        item.additional_information.is_hidden ??
                                        false
                                    ) &&
                                    (item.additional_information.is_visible ??
                                        true),
                            },
                            fieldLevel: item.field_level,
                        };

                        response.fieldSettings.standardFields = {
                            ...response.fieldSettings.standardFields,
                            [ItemCartTemplateStandardFieldBackendNames[
                                item.name
                            ]]: formattedData,
                        };
                    } else {
                        response.sections.ADDITIONAL_DETAILS.fieldList.push({
                            fieldType: 'CUSTOM',
                            field: item.name,
                        });
                        response.fieldSettings.customFields[item.name] = {
                            label: item.alternate_name
                                ? item.alternate_name
                                : item.name,
                            description: item.description,
                            buyerSettings: {
                                required: item.is_required,
                                showField: !(
                                    item.additional_information.is_hidden ??
                                    false
                                ),
                            },
                            sellerSettings: {
                                negotiable:
                                    !(
                                        item.additional_information.is_hidden ??
                                        false
                                    ) &&
                                    (item.additional_information.is_visible ??
                                        true) &&
                                    (item.additional_information
                                        .is_negotiable ??
                                        true),
                                showField:
                                    !(
                                        item.additional_information.is_hidden ??
                                        false
                                    ) &&
                                    (item.additional_information.is_visible ??
                                        true),
                                isMandatory: item.is_mandatory,
                            },
                            type: convertTemplateConstraintsItemToCustomFIeldType(
                                item.constraints.field_type
                            ),
                            constraints: getFieldTypeContraintsFromBackend({
                                field_type: item.constraints.field_type,
                                choices: item.constraints.choices,
                                max_limit: item.constraints.max_limit,
                                min_limit: item.constraints.min_limit,
                                choice_type: item.constraints.choice_type,
                            }),
                        };
                    }
                });

                const vendorInformationFromBackend =
                    baseQueryReturnValue.section_list.find(
                        (section) => section.name === 'Vendor Information'
                    );

                response.sections.VENDOR_INFORMATION.label =
                    vendorInformationFromBackend &&
                    vendorInformationFromBackend.alternate_name
                        ? vendorInformationFromBackend.alternate_name
                        : 'Vendor Information';
                const vendorInformationItemsfromBackend =
                    vendorInformationFromBackend!.section_items
                        .filter((item) => item.parent_section_item === null)
                        .filter((item) => ![''].includes(item.name));

                vendorInformationItemsfromBackend.forEach((item) => {
                    if (
                        Object.keys(
                            ItemCartTemplateStandardFieldBackendNames
                        ).includes(item.name)
                    ) {
                        response.sections.VENDOR_INFORMATION.fieldList.push({
                            fieldType: 'STANDARD',
                            field: ItemCartTemplateStandardFieldBackendNames[
                                item.name
                            ],
                        });
                        const formattedData = {
                            label: item.alternate_name
                                ? item.alternate_name
                                : item.name,
                            description: item.description,
                            buyerSettings: {
                                required: item.is_required,
                                showField: !(
                                    item.additional_information.is_hidden ??
                                    false
                                ),
                            },
                            sellerSettings: {
                                negotiable:
                                    !(
                                        item.additional_information.is_hidden ??
                                        false
                                    ) &&
                                    (item.additional_information.is_visible ??
                                        true) &&
                                    (item.additional_information
                                        .is_negotiable ??
                                        true),
                                showField:
                                    !(
                                        item.additional_information.is_hidden ??
                                        false
                                    ) &&
                                    (item.additional_information.is_visible ??
                                        true),
                            },
                            fieldLevel: item.field_level,
                        };
                        response.fieldSettings.standardFields = {
                            ...response.fieldSettings.standardFields,
                            [ItemCartTemplateStandardFieldBackendNames[
                                item.name
                            ]]: formattedData,
                        };
                    } else {
                        response.sections.ADDITIONAL_DETAILS.fieldList.push({
                            fieldType: 'CUSTOM',
                            field: item.name,
                        });
                        response.fieldSettings.customFields[item.name] = {
                            label: item.alternate_name
                                ? item.alternate_name
                                : item.name,
                            description: item.description,
                            buyerSettings: {
                                required: item.is_required,
                                showField: !(
                                    item.additional_information.is_hidden ??
                                    false
                                ),
                            },
                            sellerSettings: {
                                negotiable:
                                    !(
                                        item.additional_information.is_hidden ??
                                        false
                                    ) &&
                                    (item.additional_information.is_visible ??
                                        true) &&
                                    (item.additional_information
                                        .is_negotiable ??
                                        true),
                                showField:
                                    !(
                                        item.additional_information.is_hidden ??
                                        false
                                    ) &&
                                    (item.additional_information.is_visible ??
                                        true),
                                isMandatory: item.is_mandatory,
                            },
                            type: convertTemplateConstraintsItemToCustomFIeldType(
                                item.constraints.field_type
                            ),
                            constraints: getFieldTypeContraintsFromBackend({
                                field_type: item.constraints.field_type,
                                choices: item.constraints.choices,
                                max_limit: item.constraints.max_limit,
                                min_limit: item.constraints.min_limit,
                                choice_type: item.constraints.choice_type,
                            }),
                        };
                    }
                });

                return response;
            },
        }),
    }),
});

export const { useGetItemCartTemplateByIdQuery, useGetItemCartTemplatesQuery } =
    itemCartTemplateBaseApiSlice;

export const getItemIndices = async (textSearch: string) => {
    const baseURL = `https://symbol-search.tradingview.com/local_search/v3/?${
        Boolean(textSearch.trim()) ? `text=${textSearch.trim()}&` : ''
    }hl=1&exchange=&lang=en&domain=production&sort_by_country=US
`; // &search_type=index
    try {
        let response = await fetch(baseURL);

        let result: {
            symbols_remaining: number;
            symbols: IItemIndices[];
        } = await response.json();

        return result;
    } catch (err) {
        throw new Error('failed to get indices');
    }
};
