import { isEmpty } from 'lodash';
import { getFWDateTime } from '../../../Common/Utils/DateUtils';
import {
    getEntityType,
    setEntityType,
} from '../../../Common/Utils/EntityUtils';
import { verificationStatusMapService } from '../../../Global/Constants/VerificationConstants';
import { ICurrencyDetails } from '../../../Models/Currency.model';
import { baseApiSlice } from '../../../Redux/Slices/BaseApiSlice';
import { VerificationStatus } from '../../../Redux/Slices/VerificationSlice';
import { get, post, put } from '../../../Utils/api';
import {
    IEnterpriseSummary,
    IEntity,
    IEntitySummary,
} from '../Interfaces/EnterpriseInterface';

interface IEntityFetchListOptions {
    filter?: 'buyerActive' | 'sellerActive';
}
export const { useGetEntityListQuery } = baseApiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getEntityList: builder.query<IEntity[], {}>({
            query: () => ({
                url: `/organization/entity/`,
            }),
            transformResponse: (response: IEntity[], meta, args) => {
                return response
                    .map((item: any) => {
                        const newItem: IEntity = {
                            id: item.entity_id,
                            entityId: item.entity_id,
                            entityName: item.entity_name,
                            entity_description: item.entity_description ?? '',
                            entityType: setEntityType(
                                item.buyer_status,
                                item.seller_status
                            ),
                            verificationStatus:
                                verificationStatusMapService[
                                    item.verification_status
                                ],
                            setupPending: item.buyer_status === 'SETUP_PENDING',
                            createdOn: getFWDateTime(item.created_datetime),
                            default_procurement_currency:
                                item.default_procurement_currency,
                        };
                        return newItem;
                    })
                    .sort((a: IEntity, b: IEntity) => {
                        if (
                            a.entityName.toLowerCase() <
                            b.entityName.toLowerCase()
                        ) {
                            return -1;
                        }
                        if (
                            a.entityName.toLowerCase() >
                            b.entityName.toLowerCase()
                        ) {
                            return 1;
                        }
                        return 0;
                    });
            },
        }),
    }),
});

export const fetchEntities = async (
    options?: IEntityFetchListOptions
): Promise<IEntity[]> => {
    let response: any = await get<string>(`/organization/entity/`);
    const data: any = response.data;
    if (data) {
        const entities: IEntity[] = data
            .filter((item: any) => {
                if (options && options.filter) {
                    if (options.filter === 'buyerActive') {
                        return item.buyer_status !== 'INACTIVE';
                    }
                    if (options.filter === 'sellerActive') {
                        return item.seller_status !== 'INACTIVE';
                    }
                }
                return true;
            })
            .map((item: any) => {
                const newItem: IEntity = {
                    id: item.entity_id,
                    entityId: item.entity_id,
                    entityName: item.entity_name,
                    entity_description: item.entity_description ?? '',
                    entityType: setEntityType(
                        item.buyer_status,
                        item.seller_status
                    ),
                    verificationStatus:
                        verificationStatusMapService[item.verification_status],
                    setupPending: item.buyer_status === 'SETUP_PENDING',
                    createdOn: getFWDateTime(item.created_datetime),
                    default_procurement_currency:
                        item.default_procurement_currency,
                };
                return newItem;
            })
            .sort((a: IEntity, b: IEntity) => {
                if (a.entityName.toLowerCase() < b.entityName.toLowerCase()) {
                    return -1;
                }
                if (a.entityName.toLowerCase() > b.entityName.toLowerCase()) {
                    return 1;
                }
                return 0;
            });

        return entities;
    }
    return response;
};

export const { useFetchEntitiesQuery } = baseApiSlice.injectEndpoints({
    endpoints: (build) => ({
        fetchEntities: build.query<
            IEntity[],
            { options?: IEntityFetchListOptions }
        >({
            queryFn: async ({ options }) => {
                try {
                    let response: any = await get<string>(
                        `/organization/entity/`
                    );
                    const data: any = response.data;
                    if (data) {
                        const entities: IEntity[] = data
                            .filter((item: any) => {
                                if (options && options.filter) {
                                    if (options.filter === 'buyerActive') {
                                        return item.buyer_status !== 'INACTIVE';
                                    }
                                    if (options.filter === 'sellerActive') {
                                        return (
                                            item.seller_status !== 'INACTIVE'
                                        );
                                    }
                                }
                                return true;
                            })
                            .map((item: any) => {
                                const newItem: IEntity = {
                                    id: item.entity_id,
                                    entityId: item.entity_id,
                                    entityName: item.entity_name,
                                    entity_description:
                                        item.entity_description ?? '',
                                    entityType: setEntityType(
                                        item.buyer_status,
                                        item.seller_status
                                    ),
                                    verificationStatus:
                                        verificationStatusMapService[
                                            item.verification_status
                                        ],
                                    setupPending:
                                        item.buyer_status === 'SETUP_PENDING',
                                    createdOn: getFWDateTime(
                                        item.created_datetime
                                    ),
                                    default_procurement_currency:
                                        item.default_procurement_currency,
                                };
                                return newItem;
                            })
                            .sort((a: IEntity, b: IEntity) => {
                                if (
                                    a.entityName.toLowerCase() <
                                    b.entityName.toLowerCase()
                                ) {
                                    return -1;
                                }
                                if (
                                    a.entityName.toLowerCase() >
                                    b.entityName.toLowerCase()
                                ) {
                                    return 1;
                                }
                                return 0;
                            });

                        return {
                            data: entities,
                        };
                    }
                    return {
                        data: response,
                    };
                } catch (error: any) {
                    return { error: error };
                }
            },
        }),
    }),
});

export const fetchUsersEntities = async (): Promise<IEntity[]> => {
    let response: any = await get<string>(`/organization/entity/by_user/`);
    const data: any = response.data;
    if (data) {
        const allEntities: IEntity[] = data.map((item: any, idx: number) => {
            const newItem: IEntity = {
                id: item.entity_id,
                entityId: item.entity_id,
                entityName: item.entity_name,
                entityType: '',
                verificationStatus:
                    verificationStatusMapService[item.verification_status],
                setupPending: item.buyer_status === 'SETUP_PENDING',
                createdOn: '',
                default_procurement_currency: {
                    decimals: 2,
                    entry_id: '',
                    currency_code_abbreviation: '',
                    currency_name: '',
                    currency_symbol: '',
                },
            };
            return newItem;
        });

        const verified = allEntities.filter(
            (entity) =>
                entity.verificationStatus === VerificationStatus.VERIFIED
        );

        const unverified = allEntities.filter(
            (entity) =>
                entity.verificationStatus === VerificationStatus.UNVERIFIED
        );

        const rejected = allEntities.filter(
            (entity) =>
                entity.verificationStatus === VerificationStatus.REJECTED
        );

        const pending = allEntities.filter(
            (entity) => entity.verificationStatus === VerificationStatus.PENDING
        );

        return [...unverified, ...rejected, ...pending, ...verified];
    }
    return response;
};

export const fetchEnterprise = async (): Promise<IEnterpriseSummary> => {
    let response: any = await get<string>(`/organization/enterprise/`);
    const data: any = response.data;
    if (data) {
        const enterprise: IEnterpriseSummary = {
            enterpriseId: data.enterprise_id || '',
            enterpriseName: data.enterprise_name || '',
            enterpriseLogo: data.enterprise_logo || '',
            description: data.description ? data.description.trim() : '',
            doingBusinessAs: data.enterprise_doing_business_as
                ? data.enterprise_doing_business_as.trim()
                : '',
            website: data.contacts
                ? data.contacts.websites
                      .map((website: string) => website.split('//')[1])
                      .join(', ')
                : '',
            email: data.contacts ? data.contacts.emails.join(', ') : '',
            telephone: data.contacts
                ? data.contacts.phone_numbers.join(', ')
                : '',
            address: data.address || '',
        };
        return enterprise;
    }
    return response;
};

export const fetchEntity = async (
    entityId: string
): Promise<IEntitySummary> => {
    let response: any = await get<string>(`/organization/entity/${entityId}/`);
    const data: any = response.data;
    if (data) {
        try {
            const entity: IEntitySummary = {
                entityId: data.entity_id || '',
                entityName: data.entity_name || '',
                entityType:
                    setEntityType(data.buyer_status, data.seller_status) || '',
                entityLogo: data.entity_logo || '',
                verificationStatus: data.verification_status,
                description: data.entity_description
                    ? data.entity_description.trim()
                    : '',
                doingBusinessAs: data.entity_doing_business_as
                    ? data.entity_doing_business_as.trim()
                    : '',
                website: data.contacts
                    ? data.contacts.websites
                          .map((website: string) => website.split('//')[1])
                          .join(', ')
                    : '',
                email: data.contacts ? data.contacts.emails.join(', ') : '',
                telephone: data.contacts
                    ? data.contacts.phone_numbers.join(', ')
                    : '',
                address: data.address || '',
                currency: data.default_procurement_currency
                    ? data.default_procurement_currency
                          .currency_code_abbreviation
                    : '',
                currencyUid: data.default_procurement_currency
                    ? data.default_procurement_currency.entry_id
                    : '',
                currencySymbol: data.default_procurement_currency
                    ? data.default_procurement_currency.currency_symbol
                    : '',
                setupPending: data.buyer_status === 'SETUP_PENDING',
                setup: {
                    companyDetails:
                        data.setup_information && data.setup_information.buyer
                            ? data.setup_information.buyer.billing_address
                            : '',
                    companyIdentifications:
                        data.setup_information && data.setup_information.buyer
                            ? data.setup_information.buyer.identification
                            : '',
                    items:
                        data.setup_information && data.setup_information.buyer
                            ? data.setup_information.buyer.item
                            : '',
                    vendors:
                        data.setup_information && data.setup_information.buyer
                            ? data.setup_information.buyer.vendor
                            : '',
                    companyAddress:
                        data.setup_information && data.setup_information.buyer
                            ? data.setup_information.buyer.shipping_address
                            : '',
                    companyTnC:
                        data.setup_information && data.setup_information.buyer
                            ? data.setup_information.buyer.terms_and_conditions
                            : '',
                },
                createdOn: '',
                default_procurement_currency: data.default_procurement_currency
                    ? data.default_procurement_currency.entry_id
                    : null,
                vendor_profile_id:
                    data.vendor_profile?.vendor_profile_id || null,
                is_user_email_verified: data.is_user_email_verified,
            };

            return entity;
        } catch (error) {}
    }
    return response;
};

export interface IEnterpriseUpdatePayload {
    enterpriseName: string;
    doingBusinessAs: string;
    emails: string[];
    telephone: string[];
    website: string[];
    description: string;
    enterpriseLogo: string;
}

export const updateEnterprise = async (
    payload: IEnterpriseUpdatePayload
): Promise<any> => {
    const body = {
        enterprise_name: payload.enterpriseName,
        enterprise_doing_business_as: payload.doingBusinessAs,
        contacts: {
            emails: payload.emails,
            phone_numbers: payload.telephone,
            websites: payload.website,
        },
        enterprise_logo: payload.enterpriseLogo || null,
        description: payload.description,
    };
    let response: any = await put<any, any>(
        `/organization/enterprise/update/`,
        body
    );
    return response;
};

export interface IEntityUpdatePayload {
    entityName: string;
    doingBusinessAs: string;
    emails: string[];
    telephone: string[];
    website: string[];
    description: string;
    entityType: string;
    entityLogo?: {
        attachment_id: string;
        attachment_type: string;
        storage_id: string;
        resource_id: string;
        file_name: string;
        attachment_status: string;
    }[];
    currencyUid: string;
    default_procurement_currency: string | null;
}

export const updateEntity = async (
    entityId: string,
    payload: IEntityUpdatePayload
): Promise<any> => {
    const body = {
        entity_name: payload.entityName,
        entity_doing_business_as: payload.doingBusinessAs,
        contacts: {
            emails: payload.emails,
            phone_numbers: payload.telephone,
            websites: payload.website,
        },
        entity_logo: payload.entityLogo || null,
        entity_description: payload.description,
        entity_type: getEntityType(payload.entityType),
        default_procurement_currency: isEmpty(
            payload.default_procurement_currency
        )
            ? null
            : payload.default_procurement_currency,
    };
    let response: any = await put<any, any>(
        `/organization/entity/${entityId}/update/`,
        body
    );
    return response;
};

export const fetchCurrencies = async (): Promise<ICurrencyDetails[]> => {
    let response: any = await get<ICurrencyDetails[]>(
        `/backbone/currency_code`
    );
    const data: ICurrencyDetails[] = response.data;
    if (data) {
        try {
            data.sort((a, b) =>
                a.currency_code_abbreviation.localeCompare(
                    b.currency_code_abbreviation
                )
            );
        } catch {}
        return data;
    }
    return response;
};

interface IEntityAddPayload {
    entity_name: string;
    entity_doing_business_as: string;
    buyer: boolean;
    seller: boolean;
    contacts: {
        emails: string[];
        phone_numbers: string[];
        websites: string[];
    };
    currencyUid: string;
    default_procurement_currency?: string | null;
}

export const addEntityToEnterprise = async (
    entityDetails: IEntitySummary
): Promise<any> => {
    const payload: IEntityAddPayload = {
        default_procurement_currency:
            entityDetails.default_procurement_currency &&
            !isEmpty(entityDetails.default_procurement_currency)
                ? entityDetails.default_procurement_currency
                : null,
        entity_name: entityDetails.entityName,
        entity_doing_business_as: entityDetails.doingBusinessAs,
        buyer: true,
        seller: true,
        contacts: {
            emails: !isEmpty(entityDetails.email) ? [entityDetails.email] : [],
            phone_numbers: !isEmpty(entityDetails.telephone)
                ? [entityDetails.telephone]
                : [],
            websites: isEmpty(entityDetails.website)
                ? []
                : [
                      entityDetails.website.includes('http')
                          ? entityDetails.website
                          : 'http://' + entityDetails.website,
                  ],
        },
        currencyUid: entityDetails.currencyUid,
    };
    let response: any = await post<any, any>(
        `/organization/entity/create/`,
        payload
    );
    return response;
};

interface IOnboardingPayload {
    onboarding_information: {
        onboarding: boolean;
        welcome_pop_up: boolean;
        checklist: boolean;
    };
}

export const updateOnboardingInformation = async (
    payload: IOnboardingPayload
): Promise<void> => {
    await put<any, any>(`/organization/users/onboarding/update/`, payload);
};
