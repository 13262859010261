import { IconButton } from '@mui/material';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { FWMenu, FWMenuItem } from '../../../../Common/FWCustomMenu';
import { FWPopup } from '../../../../Common/FWPopup';
import { useConfirmation } from '../../../../Common/Hooks/ConfirmationHook';
import {
    IItemSummary,
    ItemStatus,
} from '../../Interfaces/ItemDirectoryInterface';
import { updateItemState } from '../../Services/ItemDirectoryService';

export interface IItemUpdateValues {
    index: number;
    key: string;
    value: boolean | string;
}

const ItemDirectoryListOptionsMenu = ({
    row,
    emitItemAction,
}: {
    row: IItemSummary;
    emitItemAction: (data: IItemUpdateValues) => void;
}) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
    const openMenu = Boolean(menuAnchorEl);
    const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setMenuAnchorEl(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setMenuAnchorEl(null);
    };

    const [
        confirmationValues,
        updateConfirmationDetails,
        resetConfirmationValues,
    ] = useConfirmation();

    const handleConfirmationToggle = () => {
        resetConfirmationValues();
    };

    const handleItemUpdate = async () => {
        setLoading(true);
        // const payload: any = {
        //     name: row.name,
        //     code: row.itemCode,
        //     description: row.description,
        //     attributes: {
        //         attributes: row.specifications.map((spec) => {
        //             return {
        //                 attribute_name: spec.name,
        //                 attribute_value: spec.value,
        //                 attribute_value_type: 'TEXT',
        //                 attribute_exclude: false,
        //             };
        //         }),
        //     },
        //     measurement_units: row.measurementUnits,
        //     notes: row.notes,
        //     custom_ids: { custom_ids: row.customIds },
        //     status: row.status,
        //     buyer_pricing_information: row.buyerPricingInformation,
        //     seller_pricing_information: row.sellerPricingInformation,
        //     is_buyer: row.isBuyer,
        //     is_seller: row.isSeller,
        // };
        // payload[confirmationValues.key] = confirmationValues.value;
        let payload: {
            status: 'ACTIVE' | 'INACTIVE';
        } = {
            status:
                confirmationValues.value === 'ACTIVE' ? 'ACTIVE' : 'INACTIVE',
        };
        const itemUpdated = await updateItemState(row.id, payload);
        // const itemUpdated = await updateItemValue(row.id, payload); // api call here
        if (itemUpdated) {
            emitItemAction({
                index: row.index || 0,
                key: confirmationValues.key,
                value: confirmationValues.value,
            });
            handleConfirmationToggle();
            handleCloseMenu();
            toast.success(`Item updated successfully.`);
        } else {
            toast.error(`Item updated failed.`);
        }
        setLoading(false);
    };

    // // popup messages
    // status
    const status: boolean = row.status === ItemStatus.ACTIVE;
    const statusTitle = (status ? `Disable` : `Enable`) + ' item';
    const statusMessage = `${row.name} will be ${
        status ? 'disabled' : 'enabled'
    } for all linked entities.`;
    const statusValue = status ? ItemStatus.INACTIVE : ItemStatus.ACTIVE;

    // lock
    // const lockedTitle = row.locked ? `Unlock` : `Lock`;
    // const lockedMessage = row.locked
    //     ? `Do you wish to unlock this item?`
    //     : `Do you wish to lock this item?`;

    return (
        <>
            <IconButton
                ref={(ref) => {
                    if (!ref) return;

                    ref.onclick = (e: any) => {
                        e.stopPropagation();
                        handleOpenMenu(e);
                    };
                }}
                color="primary"
            >
                <i className="bi bi-three-dots fs--14"></i>
            </IconButton>
            <FWMenu
                anchorEl={menuAnchorEl}
                open={openMenu}
                onClose={handleCloseMenu}
            >
                {/* {status && (
                    <FWMenuItem
                        onClick={() => {
                            updateConfirmationDetails({
                                title: lockedTitle,
                                message: lockedMessage,
                                key: 'locked',
                                value: !row.locked,
                            });
                        }}
                    >
                        <i
                            className={`bi bi-${
                                row.locked ? 'unlock' : 'lock'
                            }-fill mr--10 menuOptionIcon`}
                        ></i>
                        {lockedTitle}
                    </FWMenuItem>
                )} */}

                <FWMenuItem
                    sx={{ color: status ? 'error.main' : 'primary.main' }}
                    onClick={() => {
                        updateConfirmationDetails({
                            title: statusTitle,
                            message: statusMessage,
                            key: 'status',
                            value: statusValue,
                            color: status ? 'error' : 'primary',
                        });
                    }}
                >
                    {statusTitle}
                </FWMenuItem>
            </FWMenu>

            <FWPopup
                title={confirmationValues.title}
                msg={confirmationValues.message}
                btnColor={confirmationValues.color}
                btnTitle="Confirm"
                open={confirmationValues.open}
                setOpen={handleConfirmationToggle}
                onClickConfirm={handleItemUpdate}
                btnLoading={loading}
            />
        </>
    );
};

export default ItemDirectoryListOptionsMenu;
