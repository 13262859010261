import Decimal from 'decimal.js';
import { isEmpty } from 'lodash';
import moment, { Moment } from 'moment';
import { toast } from 'react-toastify';
import { getFWDate } from '../../Common/Utils/DateUtils';
import { calculateItemTotal } from '../../Components/Shared/totalCalculator';
import { generateAttributes } from '../../GoodsReceipt/Helpers/InvoiceItemHelpers';
import { qcLabelTypeMapService } from '../../QualityCheck/Components/QCTypeIcon';
import { qcTypeMapFromService } from '../../QualityCheck/Constants/QCDetailConstants';
import { get, post } from '../../Utils/api';
import {
    transactionTypeMap,
    transactionTypeMapService,
} from '../Constants/MakePaymentConstants';
import { creditTypeMap } from '../Constants/PaymentsDashboardConstants';
import {
    groupPaymentInvoiceItem,
    setPaymentDetailItemRow,
    setPaymentItemRow,
} from '../Helpers/PaymentsHelpers';
import {
    ICreditListItemSummary,
    IDefaultViewPaymentItem,
    IInvoiceViewPaymentItem,
    IPageDetails,
    IPaymentCompletedItemsList,
    IPaymentDetailSummary,
    IPaymentItemRejectionInfo,
    IPaymentListItemSummary,
    IPaymentListSummary,
    IPaymentsCompletedListSummary,
    IPaymentsPendingListSummary,
    IPOPrepaymentInvoiceSummary,
    IPrepaymentItemsList,
    PaymentItemStatus,
    PaymentItemType,
    PaymentStatus,
} from '../Interfaces/PaymentsInterface';
import { PaymentsDashboardType } from '../Slices/PaymentsDashboardSlice';

export const fetchPaymentsPendingListItemsView = async (): Promise<
    IPaymentsPendingListSummary[]
> => {
    const response: any = await get<any>(`/payments/pending/items/`);
    const data: any[] = response.data;

    if (data) {
        // const groupedItems = groupPaymentInvoiceItem(
        //     data
        //     // comm
        //     // .filter(
        //     //     (item) =>
        //     //         item.item_type === 'PREPAYMENT_ITEM' ||
        //     //         item.item_type === 'STANDARD_ITEM'
        //     // )
        // );

        let items =
            // grouped items by invoice_uid + __ + po_item_uid (dict). You can use data to separate out into batches again
            data
                .map((item, idx) => {
                    const {
                        rejectedAmount,
                        subTotal,
                        discount,
                        formattedTotal,
                        onHoldAmount,
                        grRejectedAmount,
                        qcRejectedAmount,
                    } = setPaymentItemRow(item);
                    const newItem: IPaymentsPendingListSummary = {
                        IPaymentsPendingListSummary: true,
                        id: idx,
                        buyerEntityId: item.buyer_entity,
                        itemUid: item.item_information
                            ? item.item_information.global_item_id
                            : '',
                        invoiceItemUid: item.invoice_item_id,
                        entityName: item.buyer_entity_name,
                        entityUid: item.buyer_entity,
                        poId: item.custom_po_id,
                        poUid: item.po_id,
                        invoiceId: item.custom_invoice_id,
                        invoiceUuid: item.invoice,
                        vendorName: item.seller_entity_name,
                        vendorUid: item.seller_entity,
                        vendorContact:
                            item.seller_contact_list &&
                            item.seller_contact_list.length > 0
                                ? item.seller_contact_list[0]
                                      .seller_contact_name
                                : '',
                        vendorContactUid:
                            item.seller_contact_list &&
                            item.seller_contact_list.length > 0
                                ? item.seller_contact_list[0].seller_contact_id
                                : '',
                        itemDetails: {
                            itemId: item.item_information
                                ? item.item_information.buyer_item_id
                                : '',
                            itemUid: item.item_information
                                ? item.item_information.global_item_id
                                : '',
                            itemName: item.item_information
                                ? item.item_information.buyer_item_name
                                : '',
                            description: item.item_information
                                ? item.item_information.buyer_item_description
                                : '',
                            additionalInfo: item.item_information
                                ? item.item_information.item_additional_details
                                : '',
                            tags: item.tags ? item.tags : [],
                            attributes: generateAttributes(
                                item.attribute_information
                                    ? item.attribute_information
                                          .buyer_item_attributes
                                    : [],
                                item.attribute_information
                                    ? item.attribute_information
                                          .custom_item_attributes
                                    : []
                            ),
                            customIDs: [],
                        },
                        paymentStatus: item.payment_status,
                        paymentType: item.item_type
                            .toLowerCase()
                            .includes('prepayment')
                            ? PaymentItemType.PREPAYMENT
                            : PaymentItemType.GOODS_PAYMENT,
                        paymentDueDate: item.payment_due_day_count,
                        amountPending:
                            isNaN(parseFloat(item.amount_pending)) ||
                            item.amount === null
                                ? 0
                                : +item.amount_pending,
                        totalAmount: item.pricing_information.total_amount,
                        currency:
                            item.pricing_information &&
                            item.pricing_information.currency_code_abbreviation
                                ? item.pricing_information
                                      .currency_code_abbreviation
                                : '',
                        currencyUid:
                            item.pricing_information &&
                            item.pricing_information.currency_code_id
                                ? item.pricing_information.currency_code_id
                                : '',
                        totalInfo: {
                            measurementUnit: item.quantity_information
                                ? item.quantity_information
                                      .measurement_unit_name
                                : '',
                            shippingPerUnit:
                                item.pricing_information.shipping_per_unit,
                            quantity: item.quantity_information
                                ? item.quantity_information.quantity
                                : '0',
                            taxList:
                                item.pricing_information.additional_charges,
                            subTotal,
                            currency:
                                item.pricing_information
                                    .currency_code_abbreviation,
                            currencySymbol:
                                item.pricing_information.currency_symbol,
                            price: item.pricing_information.price,
                            discount,
                            formattedTotal,
                        },
                        itemOnHold: item.hold_information ? true : false,
                        itemTermination: item.termination_information
                            ? true
                            : false,
                        poOnHold: item.po_hold_information ? true : false,
                        poTermination: item.po_termination_information
                            ? true
                            : false,
                        itemTerminated:
                            item.termination_information &&
                            item.termination_information.status ===
                                'INVOICE_ITEM_TERMINATION_SELLER_ACCEPTED'
                                ? true
                                : false,
                        poTerminationAccepted: false,
                        rejectedAmount,
                        creditedAmount: parseFloat(
                            item.payment_information.credited
                        ),
                        completedPayment: parseFloat(
                            item.payment_information.completed
                        ),
                        invoiceItemUids: item.invoice_item_uids,
                        onHoldTerminationAmount: onHoldAmount,
                        onHoldTerminationQuantity:
                            item.on_hold_termination_quantity,
                        showPrepayment:
                            item.additional_information
                                .seller_prepayment_invoices &&
                            item.additional_information
                                .seller_prepayment_invoices.length > 0
                                ? true
                                : false,
                        grRejectedAmount: grRejectedAmount,
                        qcRejectedAmount,
                        gr_status: item.status,
                        qc_status:
                            item.qc_count && +item.qc_count >= 1
                                ? 'COMPLETE'
                                : 'PENDING',
                    };
                    return newItem;
                })

                .sort((a, b) => {
                    // if (
                    //     a.paymentType === PaymentItemType.PREPAYMENT ||
                    //     b.paymentType === PaymentItemType.PREPAYMENT
                    // ) {
                    //     if (a.paymentType === b.paymentType) return 0;
                    //     if (a.dueDate === null) {
                    //         return -1;
                    //     }
                    //     if (a.dueDate < 0) {
                    //         return 1;
                    //     }
                    //     if (a.dueDate >= 0) {
                    //         return 1;
                    //     }
                    //     if (b.dueDate === null) {
                    //         return 1;
                    //     }
                    //     if (b.dueDate < 0) {
                    //         return -1;
                    //     }
                    //     if (b.dueDate >= 0) {
                    //         return -1;
                    //     }
                    // }

                    // if (a.dueDate === null || b.dueDate === null) {
                    //     if (a.dueDate === b.dueDate) return 0;
                    //     if (a.dueDate === null) return -1;
                    //     if (b.dueDate === null) return 1;
                    // }

                    // return a.dueDate - b.dueDate;

                    if (a.paymentDueDate === null) return 1;
                    if (b.paymentDueDate === null) return -1;

                    if (
                        a.paymentType === PaymentItemType.PREPAYMENT &&
                        b.paymentDueDate === 0
                    )
                        return -1;
                    if (
                        a.paymentType === PaymentItemType.PREPAYMENT &&
                        b.paymentDueDate === 0
                    )
                        return 1;

                    const x =
                        a.paymentType === PaymentItemType.PREPAYMENT
                            ? '0'
                            : a.paymentDueDate === null
                            ? '0'
                            : a.paymentDueDate.toFixed(2);
                    const y =
                        b.paymentType === PaymentItemType.PREPAYMENT
                            ? '0'
                            : b.paymentDueDate === null
                            ? '0'
                            : b.paymentDueDate.toFixed(2);
                    return parseFloat(x) - parseFloat(y);
                })
                .filter(
                    (item) =>
                        // (item.amountPending > 0 && item.totalAmount > 0) ||
                        // item.onHoldTerminationAmount !== 0 &&
                        !(isNaN(item.amountPending) || isNaN(item.totalAmount))
                );
        // extracting paid items and then pushing them to last
        const paidItems = items.filter(
            (item) =>
                item.paymentStatus === PaymentStatus.COMPLETED &&
                item.paymentType !== PaymentItemType.PREPAYMENT
        );

        items = items.filter(
            (item) =>
                item.paymentStatus !== PaymentStatus.COMPLETED ||
                item.paymentType === PaymentItemType.PREPAYMENT
        );
        // items.concat(paidItems);

        return [...items, ...paidItems].map((item, idx) => ({
            ...item,
            id: idx,
        }));
    }
    return response;
};

export interface IFetchPaymentsPayload {
    dashboard_view: string;
    search_text: string;
    tab: string;
    sort_fields: {
        field: string;
        ascending: boolean;
    }[];
    items_per_page: number;
    page_number: number;
}

export const newFetchPaymentsPendingListItemsView = async ({
    searchText,
    sortFields,
    itemsPerPage,
    pageNumber,
}: {
    searchText: string;
    sortFields: {
        field: string;
        ascending: boolean;
    }[];
    itemsPerPage: number;
    pageNumber: number;
}): Promise<{
    paymentPendingList: IPaymentsPendingListSummary[];
    pageDetails: IPageDetails;
}> => {
    const postData: IFetchPaymentsPayload = {
        dashboard_view: 'quality_check_pending',
        tab: 'all',
        search_text:
            searchText?.length && searchText?.length > 0 ? searchText : '',
        sort_fields: sortFields ?? [],
        items_per_page: itemsPerPage,
        page_number: pageNumber,
    };
    const response: any = await post<any, any>(`/dashboard/`, postData);
    const data: any[] = response.data.data;

    if (data) {
        // const groupedItems = groupPaymentInvoiceItem(
        //     data
        //     // comm
        //     // .filter(
        //     //     (item) =>
        //     //         item.item_type === 'PREPAYMENT_ITEM' ||
        //     //         item.item_type === 'STANDARD_ITEM'
        //     // )
        // );

        let items =
            // grouped items by invoice_uid + __ + po_item_uid (dict). You can use data to separate out into batches again
            data.map((item, idx) => {
                const {
                    rejectedAmount,
                    subTotal,
                    discount,
                    formattedTotal,
                    onHoldAmount,
                    grRejectedAmount,
                    qcRejectedAmount,
                } = setPaymentItemRow(item);
                const newItem: IPaymentsPendingListSummary = {
                    IPaymentsPendingListSummary: true,
                    id: idx,
                    buyerEntityId: item.buyer_entity,
                    itemUid: item.item_information
                        ? item.item_information.global_item_id
                        : '',
                    invoiceItemUid: item.invoice_item_id,
                    entityName: item.buyer_entity_name,
                    entityUid: item.buyer_entity,
                    poId: item.custom_po_id,
                    poUid: item.po_id,
                    invoiceId: item.custom_invoice_id,
                    invoiceUuid: item.invoice,
                    vendorName: item.seller_entity_name,
                    vendorUid: item.seller_entity,
                    vendorContact:
                        item.seller_contact_list &&
                        item.seller_contact_list.length > 0
                            ? item.seller_contact_list[0].seller_contact_name
                            : '',
                    vendorContactUid:
                        item.seller_contact_list &&
                        item.seller_contact_list.length > 0
                            ? item.seller_contact_list[0].seller_contact_id
                            : '',
                    itemDetails: {
                        itemId: item.item_information
                            ? item.item_information.buyer_item_id
                            : '',
                        itemUid: item.item_information
                            ? item.item_information.global_item_id
                            : '',
                        itemName: item.item_information
                            ? item.item_information.buyer_item_name
                            : '',
                        description: item.item_information
                            ? item.item_information.buyer_item_description
                            : '',
                        additionalInfo: item.item_information
                            ? item.item_information.item_additional_details
                            : '',
                        tags: item.tags ? item.tags : [],
                        attributes: generateAttributes(
                            item.attribute_information
                                ? item.attribute_information
                                      .buyer_item_attributes
                                : [],
                            item.attribute_information
                                ? item.attribute_information
                                      .custom_item_attributes
                                : []
                        ),
                        customIDs: [],
                    },
                    paymentStatus: item.payment_status,
                    paymentType: item.item_type
                        .toLowerCase()
                        .includes('prepayment')
                        ? PaymentItemType.PREPAYMENT
                        : PaymentItemType.GOODS_PAYMENT,
                    paymentDueDate: item.payment_due_day_count,
                    amountPending:
                        isNaN(parseFloat(item.amount_pending)) ||
                        item.amount === null
                            ? 0
                            : +item.amount_pending,
                    totalAmount: item.pricing_information.total_amount,
                    currency:
                        item.pricing_information &&
                        item.pricing_information.currency_code_abbreviation
                            ? item.pricing_information
                                  .currency_code_abbreviation
                            : '',
                    currencyUid:
                        item.pricing_information &&
                        item.pricing_information.currency_code_id
                            ? item.pricing_information.currency_code_id
                            : '',
                    totalInfo: {
                        measurementUnit: item.quantity_information
                            ? item.quantity_information.measurement_unit_name
                            : '',
                        shippingPerUnit:
                            item.pricing_information.shipping_per_unit,
                        quantity: item.quantity_information
                            ? item.quantity_information.quantity
                            : '0',
                        taxList: item.pricing_information.additional_charges,
                        subTotal,
                        currency:
                            item.pricing_information.currency_code_abbreviation,
                        currencySymbol:
                            item.pricing_information.currency_symbol,
                        price: item.pricing_information.price,
                        discount,
                        formattedTotal,
                    },
                    itemOnHold: item.hold_information ? true : false,
                    itemTermination: item.termination_information
                        ? true
                        : false,
                    poOnHold: item.po_hold_information ? true : false,
                    poTermination: item.po_termination_information
                        ? true
                        : false,
                    itemTerminated:
                        item.termination_information &&
                        item.termination_information.status ===
                            'INVOICE_ITEM_TERMINATION_SELLER_ACCEPTED'
                            ? true
                            : false,
                    poTerminationAccepted: false,
                    rejectedAmount,
                    creditedAmount: parseFloat(
                        item.payment_information.credited
                    ),
                    completedPayment: parseFloat(
                        item.payment_information.completed
                    ),
                    invoiceItemUids: item.invoice_item_uids,
                    onHoldTerminationAmount: onHoldAmount,
                    onHoldTerminationQuantity:
                        item.on_hold_termination_quantity,
                    showPrepayment:
                        item.additional_information
                            .seller_prepayment_invoices &&
                        item.additional_information.seller_prepayment_invoices
                            .length > 0
                            ? true
                            : false,
                    grRejectedAmount: grRejectedAmount,
                    qcRejectedAmount,
                    gr_status: item.status,
                    qc_status:
                        item.qc_count && +item.qc_count >= 1
                            ? 'COMPLETE'
                            : 'PENDING',
                };
                return newItem;
            });
        // items.concat(paidItems);

        let paymentPendingList = items.map((item, idx) => ({
            ...item,
            id: idx,
        }));
        let pageDetails: IPageDetails = response.data.metadata;
        return { paymentPendingList, pageDetails };
    }
    return response;
};

export const fetchPaymentsPendingListDefaultView = async (): Promise<
    IDefaultViewPaymentItem[]
> => {
    const response: any = await get<any>(`/payments/pending/default/`);
    const data: any[] = response.data;

    if (data) {
        // const groupedItems = groupPaymentInvoiceItem(
        //     data
        //     // comm
        //     // .filter(
        //     //     (item) =>
        //     //         item.item_type === 'PREPAYMENT_ITEM' ||
        //     //         item.item_type === 'STANDARD_ITEM'
        //     // )
        // );

        const items: IDefaultViewPaymentItem[] =
            // grouped items by invoice_uid + __ + po_item_uid (dict). You can use data to separate out into batches again
            data
                .map(
                    (item, idx): IDefaultViewPaymentItem => ({
                        IDefaultViewPaymentItem: true,
                        currency:
                            item.invoice.pricing_information
                                .currency_code_abbreviation,
                        currencyUid:
                            item.invoice.pricing_information.currency_code_id,
                        dueDate: item.invoice_items[0].payment_due_day_count,
                        entityName:
                            item.invoice.buyer_information.buyer_entity_name,
                        entityUid: item.invoice.buyer_entity,
                        gr_status: item.invoice_items
                            .map(
                                (invoice_item: any) =>
                                    invoice_item.status === 'GOODS_PENDING'
                            )
                            .includes(true)
                            ? PaymentItemStatus.GOODS_PENDING
                            : PaymentItemStatus.GOODS_RECEIVED,
                        id: idx,
                        invoiceId: item.invoice.custom_invoice_id,
                        invoiceItemUid: item.invoice_items[0].invoice_item_id,
                        invoiceItemUids: item.invoice_items.map(
                            (invoice_item: any) => invoice_item.invoice_item_id
                        ),
                        itemNames: item.invoice_items.map(
                            (invoice_item: any) =>
                                invoice_item.item_information.buyer_item_name
                        ),
                        itemOnHold: item.invoice_items
                            .map(
                                (invoice_item: any) =>
                                    invoice_item.hold_information !== null
                            )
                            .includes(true),
                        allItemsonHold: item.invoice_items
                            .map(
                                (invoice_item: any) =>
                                    invoice_item.hold_information !== null
                            )
                            .reduce((a: boolean, b: boolean) => a && b, true),
                        items: item.invoice_items.length,
                        itemTermination: item.invoice_items
                            .map(
                                (invoice_item: any) =>
                                    invoice_item.termination_information !==
                                    null
                            )
                            .includes(true),
                        allItemTermination: item.invoice_items
                            .map(
                                (invoice_item: any) =>
                                    invoice_item.termination_information !==
                                    null
                            )
                            .reduce((a: boolean, b: boolean) => a && b, true),
                        onHoldTerminationAmount: item.invoice_items
                            .filter(
                                (invoice_item: any) =>
                                    invoice_item.termination_information !==
                                    null
                            )
                            .reduce(
                                (acc: number, invoice_item: any) =>
                                    acc +
                                    +invoice_item.termination_information
                                        .quantity *
                                        +invoice_item.pricing_information.price,
                                0
                            ),
                        onHoldTerminationQuantity: item.invoice_items
                            .filter(
                                (invoice_item: any) =>
                                    invoice_item.termination_information !==
                                    null
                            )
                            .reduce(
                                (acc: number, invoice_item: any) =>
                                    acc +
                                    +invoice_item.termination_information
                                        .quantity,
                                0
                            ),
                        paymentType:
                            item.invoice.invoice_type === 'PREPAYMENT_INVOICE'
                                ? PaymentItemType.PREPAYMENT
                                : PaymentItemType.GOODS_PAYMENT,
                        pendingAmount: item.invoice_items
                            .map(
                                (invoice_item: any) =>
                                    invoice_item.amount_pending
                            )
                            .reduce(
                                (a: number, b: string | null | number) =>
                                    a + +(b ? b : 0),
                                0
                            ),
                        poId: item.invoice_items[0].custom_po_id,
                        poOnHold:
                            item.invoice_items[0].po_hold_information !== null,
                        poTermination:
                            item.invoice_items[0].po_termination_information !==
                            null,
                        poTerminationAccepted:
                            item.invoice_items[0].po_termination_information !==
                                null &&
                            item.invoice_items[0].po_termination_information
                                .status === 'SELLER_ACCEPTED'
                                ? true
                                : false,
                        poUid: item.invoice.purchase_order,
                        qc_status: item.invoice_items
                            .map(
                                (invoice_item: any) =>
                                    invoice_item.qc_count === 0
                            )
                            .includes(true)
                            ? 'PENDING'
                            : item.invoice_items
                                  .map(
                                      (invoice_item: any) =>
                                          invoice_item.qc_count === 0
                                  )
                                  .includes(true)
                            ? 'PENDING'
                            : 'COMPLETE',
                        all_qc_status: item.invoice_items
                            .map(
                                (invoice_item: any) => invoice_item.qc_count > 0
                            )
                            .includes(true)
                            ? 'COMPLETE'
                            : 'PENDING',
                        showPrepayment:
                            item.invoice.additional_information &&
                            item.invoice.additional_information
                                .seller_prepayment_invoices &&
                            item.invoice.additional_information
                                .seller_prepayment_invoices.length > 0
                                ? true
                                : false,
                        totalAmount: item.invoice_items
                            .map(
                                (invoice_item: any) =>
                                    invoice_item.pricing_information
                                        .total_amount
                            )
                            .reduce((a: number, b: string) => a + +b, 0),
                        totalInfo: {
                            measurementUnit: item.invoice_items[0]
                                .quantity_information
                                ? item.invoice_items[0].quantity_information
                                      .measurement_unit_name
                                : '',
                            shippingPerUnit:
                                item.invoice_items[0].pricing_information
                                    .shipping_per_unit,
                            quantity: item.invoice_items[0].quantity_information
                                ? item.invoice_items[0].quantity_information
                                      .quantity
                                : '0',
                            taxList:
                                item.invoice_items[0].pricing_information
                                    .additional_charges,
                            subTotal: 0,
                            currency:
                                item.invoice_items[0].pricing_information
                                    .currency_code_abbreviation,
                            currencySymbol:
                                item.invoice_items[0].pricing_information
                                    .currency_symbol,
                            price: item.invoice_items[0].pricing_information
                                .price,
                            discount: 0,
                            formattedTotal: 0,
                        },
                        vendorContact:
                            item.invoice.seller_information.seller_entity_name,
                        vendorContactUid: item.invoice.seller_entity,
                        vendorName:
                            item.invoice.seller_information.seller_entity_name,
                        vendorUid: item.invoice.seller_entity,
                        canMakePayment: item.invoice_items
                            .map(
                                (invoice_item: any) =>
                                    invoice_item.termination_information !==
                                        null ||
                                    invoice_item.hold_information !== null
                            )
                            .reduce((a: any, b: any) => a && b, true),
                    })
                )
                .sort((a, b) => {
                    // if (
                    //     a.paymentType === PaymentItemType.PREPAYMENT ||
                    //     b.paymentType === PaymentItemType.PREPAYMENT
                    // ) {
                    //     if (a.paymentType === b.paymentType) return 0;
                    //     if (a.dueDate === null) {
                    //         return -1;
                    //     }
                    //     if (a.dueDate < 0) {
                    //         return 1;
                    //     }
                    //     if (a.dueDate >= 0) {
                    //         return 1;
                    //     }
                    //     if (b.dueDate === null) {
                    //         return 1;
                    //     }
                    //     if (b.dueDate < 0) {
                    //         return -1;
                    //     }
                    //     if (b.dueDate >= 0) {
                    //         return -1;
                    //     }
                    // }

                    // if (a.dueDate === null || b.dueDate === null) {
                    //     if (a.dueDate === b.dueDate) return 0;
                    //     if (a.dueDate === null) return -1;
                    //     if (b.dueDate === null) return 1;
                    // }

                    // return a.dueDate - b.dueDate;
                    if (a.dueDate === null) return 1;
                    if (b.dueDate === null) return -1;

                    if (
                        a.paymentType === PaymentItemType.PREPAYMENT &&
                        b.dueDate === 0
                    )
                        return -1;
                    if (
                        a.paymentType === PaymentItemType.PREPAYMENT &&
                        b.dueDate === 0
                    )
                        return 1;

                    const x =
                        a.paymentType === PaymentItemType.PREPAYMENT
                            ? '0'
                            : a.dueDate === null
                            ? '0'
                            : a.dueDate.toFixed(2);
                    const y =
                        b.paymentType === PaymentItemType.PREPAYMENT
                            ? '0'
                            : b.dueDate === null
                            ? '0'
                            : b.dueDate.toFixed(2);
                    return parseFloat(x) - parseFloat(y);
                })
                .map((item, idx) => ({ ...item, id: idx }));

        return items;
    }
    return response;
};

export const fetchPaymentsPendingListInvoiceView = async (): Promise<
    IInvoiceViewPaymentItem[]
> => {
    const response: any = await get<any>(`/payments/pending/invoices/`);
    const data: any[] = response.data;

    if (data) {
        // const groupedItems = groupPaymentInvoiceItem(
        //     data
        //     // comm
        //     // .filter(
        //     //     (item) =>
        //     //         item.item_type === 'PREPAYMENT_ITEM' ||
        //     //         item.item_type === 'STANDARD_ITEM'
        //     // )
        // );

        const items: IInvoiceViewPaymentItem[] =
            // grouped items by invoice_uid + __ + po_item_uid (dict). You can use data to separate out into batches again
            data
                .map((item: any, idx: number): IInvoiceViewPaymentItem => {
                    return {
                        IInvoiceViewPaymentItem: true,
                        currency:
                            item.pricing_information.currency_code_abbreviation,
                        currencyUid: item.pricing_information.currency_code_id,
                        dueDate: item.payment_due_day_count,
                        entityName: item.buyer_information.buyer_entity_name,
                        entityUid: item.buyer_entity,
                        gr_status:
                            item.status === 'ISSUED' ||
                            item.status === 'DRAFT' ||
                            item.status === 'ONGOING'
                                ? 'GOODS_PENDING'
                                : 'GOODS_RECEIVED',
                        id: idx,
                        invoiceId: item.custom_invoice_id,
                        invoiceItemUid: item.invoice_items[0].invoice_item_id,
                        invoiceItemUids: item.invoice_items.map(
                            (invoice_item: any) => invoice_item.invoice_item_id
                        ),
                        itemNames: item.invoice_items.map(
                            (invoice_item: any) =>
                                invoice_item.item_information.buyer_item_name
                        ),
                        itemOnHold: item.invoice_items
                            .map(
                                (invoice_item: any) =>
                                    invoice_item.hold_information !== null
                            )
                            .includes(true), // kean is giving
                        allItemsonHold: item.invoice_items
                            .map(
                                (invoice_item: any) =>
                                    invoice_item.hold_information !== null
                            )
                            .reduce((a: boolean, b: boolean) => a && b, true),
                        items: item.invoice_item_count,
                        itemTermination: item.invoice_items
                            .map(
                                (invoice_item: any) =>
                                    invoice_item.termination_information !==
                                    null
                            )
                            .includes(true), // kean is giving
                        allItemTermination: item.invoice_items
                            .map(
                                (invoice_item: any) =>
                                    invoice_item.termination_information !==
                                    null
                            )
                            .reduce((a: boolean, b: boolean) => a && b, true),
                        onHoldTerminationAmount: item.invoice_items
                            .filter(
                                (invoice_item: any) =>
                                    invoice_item.termination_information !==
                                    null
                            )
                            .reduce(
                                (acc: number, invoice_item: any) =>
                                    acc +
                                    +invoice_item.termination_information
                                        .quantity *
                                        +item.pricing_information.price,
                                0
                            ), // kean is giving
                        onHoldTerminationQuantity: item.invoice_items
                            .filter(
                                (invoice_item: any) =>
                                    invoice_item.termination_information !==
                                    null
                            )
                            .reduce(
                                (acc: number, invoice_item: any) =>
                                    acc +
                                    +invoice_item.termination_information
                                        .quantity,
                                0
                            ),
                        paymentType: item.invoice_type
                            .toLowerCase()
                            .includes('prepayment')
                            ? PaymentItemType.PREPAYMENT
                            : PaymentItemType.GOODS_PAYMENT,
                        pendingAmount: item.amount_pending,
                        totalAmount: item.pricing_information.total,
                        poId: item.purchase_order.custom_purchase_order_id,
                        poUid: item.purchase_order.purchase_order_id,
                        poOnHold: item.purchase_order.hold_information !== null,
                        poTermination:
                            item.purchase_order.termination_information !==
                            null,
                        poTerminationAccepted:
                            item.purchase_order.termination_information !==
                                null &&
                            item.purchase_order.termination_information
                                .status === 'SELLER_ACCEPTED',
                        qc_status: item.invoice_type
                            .toLowerCase()
                            .includes('prepayment')
                            ? 'COMPLETE'
                            : 'PENDING',
                        all_qc_status: item.invoice_items
                            .map(
                                (invoice_item: any) => invoice_item.qc_count > 0
                            )
                            .includes(true)
                            ? 'COMPLETE'
                            : 'PENDING',
                        showPrepayment:
                            item.additional_information &&
                            item.additional_information
                                .seller_prepayment_invoices &&
                            item.additional_information
                                .seller_prepayment_invoices.length > 0
                                ? true
                                : false,
                        totalInfo: {
                            measurementUnit: item.invoice_items[0]
                                .purchase_order_item.quantity_information
                                ? item.invoice_items[0].purchase_order_item
                                      .quantity_information
                                      .measurement_unit_name
                                : '',
                            shippingPerUnit: (+item.pricing_information
                                .shipping).toString(),
                            quantity: item.quantity_information
                                ? item.quantity_information.quantity
                                : '0',
                            taxList:
                                item.pricing_information.additional_charges,
                            subTotal: +item.pricing_information.subtotal,
                            currency:
                                item.pricing_information
                                    .currency_code_abbreviation,
                            currencySymbol:
                                item.pricing_information.currency_symbol,
                            price: (+item.pricing_information.total).toString(),
                            discount:
                                +item.pricing_information.discount_percentage,
                            formattedTotal: 0,
                        },
                        vendorContact:
                            item.seller_information.seller_entity_name, // ask to send seller contact list,
                        vendorContactUid: item.seller_entity, // ask kean to send seller contact list
                        vendorName: item.seller_information.seller_entity_name, // ask kean to send seller contact list
                        vendorUid: item.seller_entity, // ask kean to send seller contact list
                        canMakePayment: item.invoice_items
                            .map(
                                (invoice_item: any) =>
                                    invoice_item.termination_information !==
                                        null ||
                                    invoice_item.hold_information !== null
                            )
                            .reduce((a: any, b: any) => a && b, true),
                    };
                })
                .sort((a, b) => {
                    if (a.dueDate === null) return 1;
                    if (b.dueDate === null) return -1;

                    if (
                        a.paymentType === PaymentItemType.PREPAYMENT &&
                        b.dueDate === 0
                    )
                        return -1;
                    if (
                        a.paymentType === PaymentItemType.PREPAYMENT &&
                        b.dueDate === 0
                    )
                        return 1;

                    const x =
                        a.paymentType === PaymentItemType.PREPAYMENT
                            ? '0'
                            : a.dueDate === null
                            ? '0'
                            : a.dueDate.toFixed(2);
                    const y =
                        b.paymentType === PaymentItemType.PREPAYMENT
                            ? '0'
                            : b.dueDate === null
                            ? '0'
                            : b.dueDate.toFixed(2);
                    return parseFloat(x) - parseFloat(y);
                })
                .map((item, idx) => ({ ...item, id: idx }));

        return items;
    }
    return response;
};

export const fetchPaymentsCompletedList = async (): Promise<
    IPaymentsCompletedListSummary[]
> => {
    const response: any = await get<any>(`/payments/`);
    const data: any[] = response.data;

    if (data) {
        return data
            .map((item) => {
                const poIdList: string[] = [];
                const invoiceIdList: string[] = [];

                item.payment_items.forEach((item: any) => {
                    if (!poIdList.includes(item.custom_po_id)) {
                        poIdList.push(item.custom_po_id);
                    }
                    if (!invoiceIdList.includes(item.custom_invoice_id)) {
                        invoiceIdList.push(item.custom_invoice_id);
                    }
                });

                const newItem: IPaymentsCompletedListSummary = {
                    paymentId: item.custom_payment_id,
                    paymentUid: item.payment_id,
                    poIdList,
                    invoiceIdList,
                    entityName: item.buyer_entity_name,
                    vendorName: item.seller_entity_name,
                    totalItems: item.payment_items.length,
                    transactionId: item.transaction_id || '-',
                    amountPaid: item.amount_information.total_amount,
                    currency:
                        item.amount_information.currency_code_abbreviation,
                    currencySymbol: item.amount_information.currency_symbol,
                    paymentType:
                        item.payment_type === 'PRE_PAYMENT'
                            ? PaymentItemType.PREPAYMENT
                            : PaymentItemType.GOODS_PAYMENT,
                    itemNames: item.payment_items
                        ? item.payment_items.map(
                              (item: any) =>
                                  item.item_information.buyer_item_name
                          )
                        : [],
                    creditAmount: parseFloat(
                        item.amount_information.total_credit
                    ),
                    transactionAmount: parseFloat(
                        new Decimal(
                            parseFloat(item.amount_information.total_amount)
                        )
                            .minus(
                                parseFloat(item.amount_information.total_credit)
                            )
                            .toFixed(2)
                    ),
                };
                return newItem;
            })
            .sort((a, b) => {
                if (a.paymentId.toLowerCase() < b.paymentId.toLowerCase()) {
                    return 1;
                }
                if (a.paymentId.toLowerCase() > b.paymentId.toLowerCase()) {
                    return -1;
                }
                return 0;
            });
    }
    return response;
};

export const fetchTabCount = async (name: string): Promise<number> => {
    let response: any;
    let data: any[] = [];
    switch (name) {
        case PaymentsDashboardType.PENDING:
            try {
                response = await fetchPaymentsPendingListDefaultView();
                data = response;
            } catch (error) {
                data = [];
            }
            break;
        case PaymentsDashboardType.COMPLETED:
            try {
                response = await get<any>(`/payments/`);
                data = response.data;
            } catch (error) {
                data = [];
            }
            break;
    }
    return data.length;
};

export const fetchPaymentItemsDetails = async (
    itemUids: string[]
): Promise<IPaymentListItemSummary[]> => {
    const queryParams = {
        invoice_item_ids: itemUids,
    };
    //

    const response: any = await get<any>(`/payments/pending/`, {
        params: queryParams,
    });

    //

    const data: any[] = response.data;

    if (data) {
        const groupedItems = groupPaymentInvoiceItem(data);

        return groupedItems.map((item, idx) => {
            let [dueDate, today, days]: [Moment | null, Moment, number | null] =
                [
                    item.payment_due_date
                        ? moment(item.payment_due_date)
                        : null,
                    moment(),
                    null,
                ];
            if (dueDate) {
                days = dueDate.diff(today, 'days');
            }

            const {
                rejectedAmount,
                amountPending,
                totalAmountWithTSD,
                subTotal,
                discount,
                formattedTotal,
                onHoldAmount,
                grRejectedAmount,
                qcRejectedAmount,
            } = setPaymentItemRow(item);

            const newItem: IPaymentListItemSummary = {
                id: idx,
                buyerEntityId: item.buyer_entity,
                invoiceItemUid: item.invoice_item_id,
                remainingDays: days,
                poId: item.custom_po_id,
                poUid: item.po_id,
                poItemUid: item.purchase_order_item,
                invoiceId: item.custom_invoice_id,
                invoiceUid: item.invoice,
                grnId:
                    item.additional_information &&
                    item.additional_information.goods_receipt_information
                        ? item.additional_information.goods_receipt_information
                              .custom_goods_receipt_id
                        : '',
                grnUid:
                    item.additional_information &&
                    item.additional_information.goods_receipt_information
                        ? item.additional_information.goods_receipt_information
                              .goods_receipt_id
                        : '',
                arNo: [
                    {
                        arType: 'Primary',
                        arId: 'AR-0000' + (idx + 1),
                    },
                    {
                        arType: 'Secondary',
                        arId: 'AR-0000' + (idx + 4),
                    },
                ],
                itemDetails: {
                    itemId: item.item_information.buyer_item_id,
                    itemUid: item.item_information.global_item_id,
                    itemName: item.item_information.buyer_item_name,
                    description: item.item_information.buyer_item_description,
                    additionalInfo:
                        item.item_information.item_additional_details,
                    tags: item.tags ? item.tags : [],
                    attributes: generateAttributes(
                        item.attribute_information
                            ? item.attribute_information.buyer_item_attributes
                            : [],
                        item.attribute_information
                            ? item.attribute_information.custom_item_attributes
                            : []
                    ),
                    customIDs:
                        'custom_ids' in item.item_information
                            ? item.item_information.custom_ids
                            : [],
                },
                quantity: item.quantity_information.quantity,
                measurementUnit:
                    item.quantity_information.measurement_unit_name,
                rate: parseFloat(item.pricing_information.price),
                totalAmount: totalAmountWithTSD,
                amountPending,
                amountToPay: amountPending.toFixed(2),
                currency: item.pricing_information.currency_code_abbreviation,
                currencySymbol: item.pricing_information.currency_symbol,
                currencyUid: item.pricing_information.currency_code_id,
                status: item.status,
                invoiceVerificationStatus: '',
                totalInfo: {
                    measurementUnit:
                        item.quantity_information.measurement_unit_name,
                    shippingPerUnit: item.pricing_information.shipping_per_unit,
                    quantity: item.quantity_information.quantity,
                    taxList: item.pricing_information.additional_charges,
                    subTotal,
                    currency:
                        item.pricing_information.currency_code_abbreviation,
                    currencySymbol: item.pricing_information.currency_symbol,
                    price: item.pricing_information.price,
                    discount,
                    formattedTotal,
                },
                itemOnHold: item.hold_information ? true : false,
                itemTermination: item.termination_information ? true : false,
                itemTerminated:
                    item.termination_information &&
                    item.termination_information.status === 'SELLER_ACCEPTED'
                        ? true
                        : false,
                poOnHold: item.po_hold_information ? true : false,
                poTermination: item.po_termination_information ? true : false,
                poTerminationAccepted: false,
                rejectedAmount,
                creditedAmount: parseFloat(item.payment_information.credited),
                completedPayment: parseFloat(
                    item.payment_information.completed
                ),
                onHoldTerminationAmount: onHoldAmount,
                onHoldTerminationQuantity: item.on_hold_termination_quantity,
                paymentType: item.item_type.toLowerCase().includes('prepayment')
                    ? PaymentItemType.PREPAYMENT
                    : PaymentItemType.GOODS_PAYMENT,
                showPrepayment:
                    item.additional_information.seller_prepayment_invoices &&
                    item.additional_information.seller_prepayment_invoices
                        .length > 0
                        ? true
                        : false,
                grRejectedAmount,
                qcRejectedAmount,
                poQuantity: item.po_item_quantity_information.quantity,
                qcDone: item.qcDone,
            };
            return newItem;
        });
    }
    return response;
};

export const fetchPaymentCreditDetails = async (
    buyerEntityId: string,
    sellerEntityId: string,
    currencyUid: string
): Promise<ICreditListItemSummary[]> => {
    try {
        const response: any = await get<any>(
            `/payments/credits?buyer_entity_id=${buyerEntityId}&seller_entity_id=${sellerEntityId}`
        );
        const data: any[] = response.data;
        if (data) {
            try {
                return data
                    .map((item, idx) => {
                        let newItem: ICreditListItemSummary =
                            {} as ICreditListItemSummary;
                        newItem = {
                            id: idx,
                            creditUid: item.credit_id,
                            poId: item.custom_po_id,
                            invoiceId: item.source_information
                                ? item.source_information.invoice_custom_id
                                : '',
                            itemName: item.item_information
                                ? item.item_information.buyer_item_name
                                : '',
                            totalAmount: parseFloat(
                                parseFloat(
                                    item.credit_amount_information.total_amount
                                ).toFixed(2)
                            ),
                            reasonForRejection: item.source_information
                                ? item.source_information.qc_rejection_reason
                                : '',
                            creditType: creditTypeMap[item.credit_type],
                            availableAmount: parseFloat(
                                parseFloat(
                                    item.credit_amount_information
                                        .balance_amount
                                ).toFixed(2)
                            ),
                            requestedAmount: '',
                            currency:
                                item.credit_amount_information
                                    .currency_code_abbreviation,
                            currencySymbol:
                                item.credit_amount_information.currency_symbol,
                            currencyUid: item.currency_code,
                            batchId: item.source_information
                                ? item.source_information.batch_number
                                : '',
                            rejectionGr: item.source_information
                                ? item.source_information.custom_gr_id ?? ''
                                : '',
                            rejectionArn: item.source_information
                                ? item.source_information.qc_rejected_ar ?? ''
                                : '',
                            rejectionDate: getFWDate(item.created_datetime),
                            selected: false,
                            poContact:
                                item.seller_contact_list.length > 0
                                    ? item.seller_contact_list[0]
                                          .seller_contact_name
                                    : '',
                            poContactUid:
                                item.seller_contact_list.length > 0
                                    ? item.seller_contact_list[0]
                                          .seller_contact_id
                                    : '',
                            terminatedByContact: item.source_information
                                ? item.source_information.buyer_user_name
                                : '',
                            terminatedByContactUid: item.source_information
                                ? item.source_information.buyer_user_id
                                : '',
                        };

                        return newItem;
                    })
                    .filter((credit) => credit.currencyUid === currencyUid)
                    .map((credit, id) => ({
                        ...credit,
                        id,
                    }));
            } catch (error) {}
        }
        return response;
    } catch (error) {
        return [];
    }
};

export interface IMakePaymentPayload {
    buyer_entity_id: string;
    seller_entity_id: string;
    payment_type: string;
    currency_id: string;
    payment_items: {
        // invoice_id: string;
        // po_item_id: string;
        invoice_item_id: string;
        amount: number;
    }[];
    applied_credits: {
        credit_id: string;
        amount: number;
    }[];
    transaction_type?: string | null;
    transaction_id?: string | null;
    provided_payment_time?: string | null;
    attachment_ids: string[];
}

export const makePaymentRequest = async (
    buyerEntityUid: string,
    sellerEntityUid: string,
    items: IPaymentListItemSummary[],
    credits: ICreditListItemSummary[],
    currencyId: string,
    paymentDetails: IPaymentListSummary,
    paymentType: 'GOODS_PAYMENT' | 'PRE_PAYMENT' = 'GOODS_PAYMENT'
): Promise<string | undefined> => {
    const payload: IMakePaymentPayload = {
        buyer_entity_id: buyerEntityUid,
        seller_entity_id: sellerEntityUid,
        payment_type: paymentType,
        currency_id: currencyId,
        payment_items: items.map((item) => {
            return {
                // invoice_id: item.invoiceUid,
                // po_item_id: item.poItemUid,
                invoice_item_id: item.invoiceItemUid,
                amount: parseFloat(item.amountToPay),
            };
        }),
        applied_credits: credits.map((credit) => {
            return {
                credit_id: credit.creditUid,
                amount: parseFloat(credit.requestedAmount),
            };
        }),
        attachment_ids: paymentDetails.attachments
            ? paymentDetails.attachments.map((a) => a.attachment_id)
            : [],
    };

    if (!isEmpty(paymentDetails.transactionType)) {
        payload.transaction_type =
            transactionTypeMap[paymentDetails.transactionType];
        payload.transaction_id = paymentDetails.transactionId;
        payload.provided_payment_time = paymentDetails.date;
    }

    const response: any = await post<string, IMakePaymentPayload>(
        `/payments/create/`,
        payload
    );

    try {
        const data: any = response.data;

        if (data) {
            return data.payment_id;
        }
        return response;
    } catch (err: any) {
        if (err.response.data.ErrorCode === 'Exceeded Quota Limit') {
            toast.error(err.response.data.ErrorCode);
            return response;
        }
    }
};

export const fetchPaymentDetails = async (
    paymentUid: string
): Promise<IPaymentDetailSummary> => {
    const response: any = await get<any>(`/payments/${paymentUid}`);
    const data: any = response.data;
    if (data) {
        const items: IPaymentCompletedItemsList[] = data.payment_items.map(
            (item: any) => {
                const {
                    discount,
                    formattedTotal,
                    totalQuantity,
                    totalAmount,
                    measurementUnit,
                    subTotal,
                    shippingPerUnit,
                    taxes,
                    price,
                    currency,
                    currencySymbol,
                } = setPaymentDetailItemRow(item);
                const newItem: IPaymentCompletedItemsList = {
                    itemDetails: {
                        itemId: item.item_information.buyer_item_id,
                        itemUid: item.item_information.global_item_id,
                        itemName: item.item_information.buyer_item_name,
                        description:
                            item.item_information.buyer_item_description,
                        additionalInfo:
                            item.item_information.item_additional_details,
                        tags: item.tags ? item.tags : [],
                        attributes: generateAttributes(
                            item.attribute_information.buyer_item_attributes,
                            item.attribute_information.custom_item_attributes
                        ),
                        customIDs: item.item_information.custom_ids,
                    },
                    amountPaid: parseFloat(
                        item.amount_information.total_amount
                    ),
                    invoiceId: item.custom_invoice_id,
                    poId: item.custom_po_id,
                    currency:
                        item.amount_information.currency_code_abbreviation,
                    currencySymbol: item.amount_information.currency_symbol,
                    totalInfo: {
                        measurementUnit,
                        shippingPerUnit,
                        quantity: totalQuantity,
                        taxList: taxes,
                        subTotal,
                        currency,
                        currencySymbol,
                        price,
                        discount,
                        formattedTotal,
                    },
                    totalAmount: parseFloat(totalAmount),
                    paymentType:
                        data.payment_type === 'PRE_PAYMENT'
                            ? PaymentItemType.PREPAYMENT
                            : PaymentItemType.GOODS_PAYMENT,
                    vendorContact:
                        item.seller_contact_list.length > 0
                            ? item.seller_contact_list[0].seller_contact_name
                            : '',
                    vendorContactUid:
                        item.seller_contact_list.length > 0
                            ? item.seller_contact_list[0].seller_contact_id
                            : '',
                };
                return newItem;
            }
        );
        const paymentDetails: IPaymentDetailSummary = {
            status: 'COMPLETED',
            amountPaid: parseFloat(data.amount_information.total_amount),
            totalTransactionAmount: new Decimal(
                parseFloat(data.amount_information.total_amount)
            )
                .minus(parseFloat(data.amount_information.total_credit))
                .toNumber(),
            totalCreditAmount: parseFloat(data.amount_information.total_credit),
            paidBy: data.created_by_user_name,
            paymentDate: getFWDate(data.provided_payment_time),
            currency: data.amount_information.currency_code_abbreviation,
            currencySymbol: data.amount_information.currency_symbol,
            items,
            transactionId: data.transaction_id || '-',
            transactionType: transactionTypeMapService[data.transaction_type],
            paymentType:
                data.payment_type === 'PRE_PAYMENT'
                    ? PaymentItemType.PREPAYMENT
                    : PaymentItemType.GOODS_PAYMENT,
            buyerEntityName: data.buyer_entity_name,
            sellerEntityName: data.seller_entity_name,
            attachments: data.attachments.map((a: any) => ({
                file_name: a.file_name,
                attachment_id: a.attachment_id,
            })),
            vendorContact: items.length > 0 ? items[0].vendorContact : '',
            vendorContactUid: items.length > 0 ? items[0].vendorContactUid : '',
        };

        return paymentDetails;
    }
    return response;
};

// export const fetchPrepaymentDetails = async (
//     poUid: string
// ): Promise<IPrepaymentSummary> => {
//     const response: any = await get<any>(`/payments/pending/`);
//     const data: any[] = response.data;
//     if (data) {
//         const prePaymentData: IPrepaymentSummary = {
//             eventName: 'Some random event name here',
//             poId: 'PO000201',
//             poUid: '8923-fwhef2323-eg23-grergr',
//             vendor: 'Nikeon Corporation',
//             totalPrepayment: 10000,
//             amountPaid: 2000,
//             pendingPrepayment: 8000,
//             amountToPay: '',
//             transactionDate: '',
//             transactionType: '' as TransactionType,
//             transactionId: '',
//             currency: 'INR',
//             currencySymbol: '₹',
//             poOnHold: false,
//             poTermination: false,
//             poTerminationAccepted: false,
//         };

//         return prePaymentData;
//     }
//     return response;
// };

interface IMakePrepaymentPayload {
    buyer_entity_id: string;
    seller_entity_id: string;
    currency_id: string;
    invoice_id: string;
    prepayment_items: {
        invoice_item_id: string;
        amount: number;
    }[];
    transaction_type: string;
    transaction_id: string;
    provided_payment_time: string;
    attachment_ids: string[];
}

export const makePrepaymentRequest = async (
    buyerEntityUid: string,
    sellerEntityUid: string,
    items: IPaymentListItemSummary[],
    currencyId: string,
    paymentDetails: IPaymentListSummary
): Promise<string | undefined> => {
    const payload: IMakePrepaymentPayload = {
        buyer_entity_id: buyerEntityUid,
        seller_entity_id: sellerEntityUid,
        currency_id: currencyId,
        invoice_id: items[0].invoiceUid,
        transaction_type: transactionTypeMap[paymentDetails.transactionType],
        transaction_id: paymentDetails.transactionId,
        provided_payment_time: paymentDetails.date,
        prepayment_items: items.map((item) => {
            return {
                invoice_item_id: item.invoiceItemUid,
                amount: parseFloat(item.amountToPay),
            };
        }),
        attachment_ids: paymentDetails.attachments
            ? paymentDetails.attachments.map((attach) => attach.attachment_id)
            : [],
    };

    try {
        const response: any = await post<string, IMakePrepaymentPayload>(
            `/payments/prepayment/create/`,
            payload
        );

        const data: any = response.data;

        if (data) {
            return data.payment_id;
        }
        return response;
    } catch (error: any) {
        if (error.response.data.ErrorCode === 'Exceeded Quota Limit') {
            toast.error(error.response.data.ErrorCode);
            return;
        }
        throw error;
    }
};

export const fetchPOPrepaymentInvoices = async (
    poUid: string,
    user_type: 'BUYER' | 'SELLER'
): Promise<IPOPrepaymentInvoiceSummary[]> => {
    const response: any = await get<any>(
        `/invoices/?purchase_order_id=${poUid}&enterprise_type=${user_type}&invoice_type=SELLER_PREPAYMENT_INVOICE&invoice_status=ISSUED&invoice_status=ONGOING`
    );
    const data: any[] = response.data;
    if (data) {
        return data.map((invoice) => {
            const newInvoice: IPOPrepaymentInvoiceSummary = {
                invoiceId: invoice.custom_invoice_id || '-',
                invoiceUid: invoice.invoice_id,
                itemsList: [],
                itemCount: 0,
                totalAmount: 0,
                currency: '',
            };
            return newInvoice;
        });
    }
    return response;
};

export const fetchPrepaymentInvoiceDetails = async (
    invoiceUid: string,
    user_type: 'BUYER' | 'SELLER'
): Promise<IPOPrepaymentInvoiceSummary> => {
    const response: any = await get<any>(
        `/invoices/${invoiceUid}/?enterprise_type=${user_type}`
    );
    const data: any = response.data;
    if (data) {
        const newInvoice: IPOPrepaymentInvoiceSummary = {
            invoiceId: data.custom_invoice_id || '-',
            invoiceUid: data.invoice_id,
            itemsList: data.invoice_items.map((item: any) => {
                const newItem: IPrepaymentItemsList = {
                    customIDs: item.item_information.custom_ids,
                    itemId: item.item_information
                        ? item.item_information.buyer_item_id
                        : '',
                    itemUid: item.item_information
                        ? item.item_information.global_item_id
                        : '',
                    itemName: item.item_information
                        ? item.item_information.buyer_item_name
                        : '',
                    description: item.item_information
                        ? item.item_information.buyer_item_description
                        : '',
                    additionalInfo: item.item_information
                        ? item.item_information.item_additional_details
                        : '',
                    tags: item.tags ? item.tags : [],
                    attributes: generateAttributes(
                        item.attribute_information
                            ? item.attribute_information.buyer_item_attributes
                            : [],
                        item.attribute_information
                            ? item.attribute_information.custom_item_attributes
                            : []
                    ),
                    quantity: parseFloat(
                        item.quantity_information
                            ? item.quantity_information.quantity
                            : '0'
                    ),
                    rate: parseFloat(
                        item.pricing_information
                            ? item.pricing_information.price
                            : '0'
                    ),
                    total: calculateItemTotal(
                        item.quantity_information &&
                            item.quantity_information.quantity
                            ? item.quantity_information.quantity
                            : '0',
                        item.pricing_information &&
                            item.pricing_information.price
                            ? item.pricing_information.price
                            : '0',
                        item.pricing_information &&
                            item.pricing_information.shipping_per_unit
                            ? item.pricing_information.shipping_per_unit
                            : '0',
                        item.pricing_information &&
                            item.pricing_information.additional_charges
                            ? item.pricing_information.additional_charges
                            : []
                    ),
                    prepaymentAmount: parseFloat(
                        item.pricing_information
                            ? item.pricing_information.total_amount
                            : '0'
                    ),
                    measurementUnit: item.quantity_information
                        ? item.quantity_information.measurement_unit_name
                        : '',
                    currency: item.pricing_information
                        ? item.pricing_information.currency_code_abbreviation
                        : '',
                };
                return newItem;
            }),
            itemCount: data.invoice_items.length,
            totalAmount: parseFloat(
                data.pricing_information ? data.pricing_information.total : '0'
            ),
            currency: data.pricing_information
                ? data.pricing_information.currency_code_abbreviation
                : '',
        };
        return newInvoice;
    }
    return response;
};

export const fetchItemRejectionDetails = async (
    invoiceItemUid: string
): Promise<IPaymentItemRejectionInfo> => {
    // const grRejections: any = await get<any>(
    //     `/goods_receipt/?invoice_item_id=${invoiceItemUid}`
    // );
    // const qcRejections: any = await get<any>(
    //     `/quality_checks/?invoice_item_id=${invoiceItemUid}`
    // );
    // const grs: any = grRejections.data;
    // const qcs: any = qcRejections.data;

    const invoiceItemInfoWithGRAndQC = await get<any>(
        `/invoices/item/detail/?invoice_item_id=${invoiceItemUid}`
    );

    // const labelCounts: {
    //     [key: string]: number;
    // } = {
    //     [QCType.SECONDARY]: 1,
    //     [QCType.PRODUCTION_LINE]: 1,
    // };

    const rejectionData: IPaymentItemRejectionInfo = {
        // grn: grs.map((gr: any) => {
        //     const newGr = {
        //         grAccepted: gr.quantity_information.quantity_accepted,
        //         grRejected: gr.quantity_information.quantity_rejected
        //             ? gr.quantity_information.quantity_rejected
        //             : new Decimal(
        //                   parseFloat(gr.quantity_information.total_quantity)
        //               )
        //                   .minus(
        //                       parseFloat(
        //                           gr.quantity_information.quantity_accepted
        //                       )
        //                   )
        //                   .toFixed(2),
        //         grTolerance:
        //             +gr.quantity_information.total_quantity -
        //             +gr.quantity_information.quantity_accepted -
        //             +gr.quantity_information.quantity_rejected -
        //             +gr.quantity_information.quantity_over_delivered,
        //         grOverdelivery:
        //             gr.quantity_information.quantity_over_delivered ?? 0,
        //         grNo: gr.custom_goods_receipt_id,
        //         measurementUnit: gr.quantity_information.measurement_unit_name,
        //         batchId: gr.invoice_item.batch_number,
        //         totalQty: gr.quantity_information.total_quantity,
        //         grUid: gr.goods_receipt_id,
        //     };
        //     return newGr;
        // }),
        // qc: qcs.map((qc: any) => {
        //     const newQc: IAcceptedRejectedList = {
        //         acceptedQty: qc.quantity_information.quantity_accepted,
        //         rejectedQty: new Decimal(
        //             parseFloat(qc.quantity_information.total_quantity)
        //         )
        //             .minus(
        //                 parseFloat(qc.quantity_information.quantity_accepted)
        //             )
        //             .toFixed(2),
        //         acceptedARN: qc.ar_accepted || '',
        //         rejectedARN: qc.ar_rejected || '',
        //         qcType: qcTypeMapFromService[qc.quality_check_type],
        //         measurementUnit: qc.quantity_information.measurement_unit_name,
        //         label:
        //             qcLabelTypeMapService[qc.quality_check_type] +
        //             (labelCounts[qc.quality_check_type] || ''),
        //         batchId: qc.batch_number,
        //         totalQty: qc.quantity_information.total_quantity,
        //         grUid: qc.goods_receipt,
        //     };
        //     if (qc.quality_check_type === QCType.SECONDARY) {
        //         labelCounts[QCType.SECONDARY] += 1;
        //     } else if (qc.quality_check_type === QCType.PRODUCTION_LINE) {
        //         labelCounts[QCType.PRODUCTION_LINE] += 1;
        //     }
        //     return newQc;
        // }),
        grn: [],
        qc: [],
    };

    invoiceItemInfoWithGRAndQC.data.invoice_item.batch_items.forEach(
        (batch_item: any) => {
            // populating gr
            if (batch_item.goods_receipt) {
                const gr = batch_item.goods_receipt;
                rejectionData.grn.push({
                    batchId: batch_item.custom_batch_id,
                    grAccepted: gr.quantity_information.quantity_accepted,
                    grNo: gr.custom_goods_receipt_id,
                    grOverdelivery:
                        gr.quantity_information.quantity_over_delivered,
                    grRejected: gr.quantity_information.quantity_rejected,
                    grTolerance: `${
                        +gr.quantity_information.total_quantity -
                        +gr.quantity_information.quantity_accepted -
                        +gr.quantity_information.quantity_rejected -
                        +gr.quantity_information.quantity_over_delivered
                    }`,
                    grUid: gr.goods_receipt_id,
                    measurementUnit:
                        gr.quantity_information.measurement_unit_name,
                    totalQty: gr.quantity_information.total_quantity,
                });
            }
            // batch_item?.goods_receipt?.forEach((gr: any) => {
            //     rejectionData.grn.push({
            //         batchId: batch_item.custom_batch_id,
            //         grAccepted: gr.quantity_information.quantity_accepted,
            //         grNo: gr.custom_goods_receipt_id,
            //         grOverdelivery:
            //             gr.quantity_information.quantity_over_delivered,
            //         grRejected: gr.quantity_information.quantity_rejected,
            //         grTolerance: `${
            //             +gr.quantity_information.total_quantity -
            //             +gr.quantity_information.quantity_accepted -
            //             +gr.quantity_information.quantity_rejected -
            //             +gr.quantity_information.quantity_over_delivered
            //         }`,
            //         grUid: gr.custom_goods_receipt_id,
            //         measurementUnit:
            //             gr.quantity_information.measurement_unit_name,
            //         totalQty: gr.quantity_information.total_quantity,
            //     });
            // });

            // populating qc
            batch_item.quality_checks?.forEach((qc: any) => {
                rejectionData.qc.push({
                    acceptedARN: qc.ar_accepted,
                    acceptedQty: qc.quantity_information.quantity_accepted,
                    label: qcLabelTypeMapService[qc.quality_check_type],
                    measurementUnit:
                        qc.quantity_information.measurement_unit_name,
                    qcType: qcTypeMapFromService[qc.quality_check_type],
                    rejectedARN: qc.ar_rejected,
                    rejectedQty: qc.quantity_information.quantity_rejected,
                    batchId: batch_item.custom_batch_id,
                    batchUid: batch_item.invoice_item_batch_id,
                    totalQty: qc.quantity_information.total_quantity,
                });
            });
        }
    );

    return rejectionData;
};
