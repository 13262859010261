import { CircularProgress, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { debounce } from 'lodash';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { FWInput } from '../../Common/FWInput';
import { FWTypography } from '../../Common/FWTypograhy';
import { IUserSession } from '../Interfaces/Chat.model';
import { fetchModuleUserSessions } from '../Services/chat.service';
import ChatSessionRows from '../Components/ChatSessionRows';
import BroadcastSessionRows from '../Components/BroadcastSessionRow';
import { shallowEqual, useSelector } from 'react-redux';
import { IGlobalAppStore } from '../../Redux/Store';
import FilterSessionType from './FilterSessionType';
import { customScrollbar } from '../../Common/Utils/UIUtils';
import ChatSectionsExpandButton from '../Components/ChatSectionsExpandButton';
import { AuthContext } from '../../Contexts/AuthContext';

interface IChatSessionDisplayContainer {
    selectedEventID: string;
    selectedSessionID: string | null;
    updateSelectedSession: (newSession: IUserSession | null) => void;
    user_type: 'buyer' | 'seller';
    selectedSessionMuteState: boolean;
    isBroadCastSessionSelected: boolean;
    updateBroadCastSessionSelected: (data: IUserSession | null) => void;
    setSelectedSessionAvatarColor: React.Dispatch<React.SetStateAction<string>>;
    floatingChat?: boolean;
    hideBroadcast?: boolean;
    currentlySelectedEventStatus: string;
    currentlySelectedEventID?: string | null;
    showEventsSection?: boolean;
    setShowEventsSection?: React.Dispatch<React.SetStateAction<boolean>>;
    updateLastReadMsgId: (newMsgId: string) => void;
    lastReadMsgId: string;
}

type SessionType = 'INTERNAL' | 'EXTERNAL' | 'ALL';

export default function ChatSessionDisplayContainer(
    props: IChatSessionDisplayContainer
) {
    const [sessionList, setSessionList] = React.useState<IUserSession[] | null>(
        null
    );
    const {
        selectedEventID,
        selectedSessionID,
        updateSelectedSession,
        user_type,
        selectedSessionMuteState,
        isBroadCastSessionSelected,
        updateBroadCastSessionSelected,
        setSelectedSessionAvatarColor,
        updateLastReadMsgId,
    } = props;
    const [filteredSessionList, setFilteredSessionList] = React.useState<
        IUserSession[] | null
    >(null);

    useEffect(() => {
        setSessionList((prev) => {
            return prev
                ? prev?.map((session) => {
                      if (
                          session?.last_acknowledged?.message_id ===
                          props.lastReadMsgId
                      ) {
                          return {
                              ...session,
                              last_acknowledged: null,
                          };
                      } else {
                          return session;
                      }
                  })
                : null;
        });
        setFilteredSessionList((prev) => {
            return prev
                ? prev?.map((session) => {
                      if (
                          session.last_acknowledged &&
                          session.last_acknowledged.message_id ===
                              props.lastReadMsgId
                      ) {
                          return {
                              ...session,
                              last_acknowledged: null,
                          };
                      } else {
                          return session;
                      }
                  })
                : null;
        });
    }, [props.lastReadMsgId]);

    const messagesReceived = useSelector(
        (state: IGlobalAppStore) => state.ChatStore.messagesReceived
    );

    useEffect(() => {
        if (messagesReceived.length > 0) {
            const receivedMsg = messagesReceived[messagesReceived.length - 1];
            setSessionList(
                (prev) =>
                    prev &&
                    prev.map((session) => {
                        if (
                            session.session.session_id ===
                            receivedMsg.session_id
                        ) {
                            return {
                                ...session,
                                session: {
                                    ...session.session,
                                    last_msg: {
                                        message: receivedMsg.message,
                                        message_id: receivedMsg.message_id,
                                        posted_time:
                                            receivedMsg.created_datetime,
                                        sender_id: receivedMsg.sender_id,
                                        sender_name: receivedMsg.sender_name,
                                        attachments: receivedMsg.attachments,
                                    },
                                },
                            };
                        } else {
                            return session;
                        }
                    })
            );
            setFilteredSessionList(
                (prev) =>
                    prev &&
                    prev.map((session) => {
                        if (
                            session.session.session_id ===
                            receivedMsg.session_id
                        ) {
                            return {
                                ...session,
                                session: {
                                    ...session.session,
                                    last_msg: {
                                        message: receivedMsg.message,
                                        message_id: receivedMsg.message_id,
                                        posted_time:
                                            receivedMsg.created_datetime,
                                        sender_id: receivedMsg.sender_id,
                                        sender_name: receivedMsg.sender_name,
                                        attachments: receivedMsg.attachments,
                                    },
                                },
                            };
                        } else {
                            return session;
                        }
                    })
            );
        }
    }, [messagesReceived]);

    const getAllSessionUnreadCount = useCallback(
        (globalState: IGlobalAppStore) => {
            if (
                selectedEventID in globalState.ChatStore.messageCountDictionary
            ) {
                let sessionIDCountMap: { [key: string]: number } = {};
                Object.keys(
                    globalState.ChatStore.messageCountDictionary[
                        selectedEventID
                    ]
                ).forEach((key) => {
                    if (key !== 'total_count') {
                        sessionIDCountMap[key] = (
                            globalState.ChatStore.messageCountDictionary[
                                selectedEventID
                            ] as any
                        )[key];
                    }
                });
                return sessionIDCountMap;
            }
            return {};
        },
        [selectedEventID]
    );

    const allSessionsUnreadCount: { [key: string]: number } = useSelector(
        getAllSessionUnreadCount,
        shallowEqual
    );
    // useEffect to get the list of all the events and store in state
    useEffect(() => {
        fetchModuleUserSessions(selectedEventID)
            .then((res) => {
                setSessionList(res);
                setFilteredSessionList(res);
                if (
                    props.floatingChat === false ||
                    props.floatingChat === undefined
                ) {
                    updateSelectedSession(res.length > 0 ? res[0] : null);
                }
            })
            .catch((err) => {
                toast.error("Couldn't fetch groups");
            });
    }, [props.floatingChat, selectedEventID, updateSelectedSession]);

    // this useEffect listens to updates happening to the mute state
    useEffect(() => {
        if (selectedSessionID !== null) {
            setSessionList((prev) =>
                prev === null
                    ? prev
                    : prev.map((session) => {
                          if (
                              session.session.session_id ===
                                  selectedSessionID &&
                              session.mute !== selectedSessionMuteState
                          ) {
                              session.mute = selectedSessionMuteState;
                          }
                          return session;
                      })
            );
            setFilteredSessionList((prev) =>
                prev === null
                    ? prev
                    : prev.map((session) => {
                          if (
                              session.session.session_id ===
                                  selectedSessionID &&
                              session.mute !== selectedSessionMuteState
                          ) {
                              session.mute = selectedSessionMuteState;
                          }
                          return session;
                      })
            );
        }
    }, [selectedSessionID, selectedSessionMuteState]);
    const [sessionType, setSessionType] = useState<SessionType>('ALL');

    // const internalChatExists: boolean = useMemo(() => {
    //     if (filteredSessionList && sessionType === 'INTERNAL') {
    //         return filteredSessionList.some(({ session: { session_type } }) => {
    //             return session_type === 'INTERNAL';
    //         });
    //     } else {
    //         return false;
    //     }
    // }, [filteredSessionList, sessionType]);

    if (user_type === 'buyer') {
        return (
            <Box
                /* borderRight={'1px solid'} */
                borderColor={'dividerColor.main'}
                overflow={'hidden'}
                minWidth={'300px'}
                height={'100%'}
            >
                {/* Event search bar */}
                <Box padding={'10px'} style={{ display: 'flex' }}>
                    {props.currentlySelectedEventID &&
                        props.setShowEventsSection &&
                        !props.showEventsSection && (
                            <ChatSectionsExpandButton
                                onClick={() => {
                                    props.setShowEventsSection &&
                                        props.setShowEventsSection(true);
                                }}
                                hidden={true}
                            />
                        )}
                    <Typography
                        fontWeight={'500'}
                        fontSize={'1.25rem'}
                        flex={1}
                    >
                        Groups
                    </Typography>
                    <br />
                    <Box flex={1.5}>
                        <SessionSearchField
                            user_type={user_type}
                            sessionList={sessionList}
                            setFilteredSessionList={setFilteredSessionList}
                        />
                    </Box>
                </Box>
                {/* <Divider /> */}
                {/* <Box display="flex" flexDirection={'column'}> */}
                {/* List of Sessions */}
                <Box
                    overflow={'auto'}
                    // flex={1}
                    minHeight={'60px'}
                    maxHeight={
                        props.floatingChat ? '90%' : 'calc(100vh - 177px)'
                    }
                    className="session-list"
                    sx={customScrollbar}
                >
                    {props.user_type === 'buyer' &&
                        props.hideBroadcast !== true &&
                        props.currentlySelectedEventStatus !== 'DRAFT' &&
                        props.currentlySelectedEventStatus !==
                            'APPROVAL_PENDING' && (
                            <FilterSessionType
                                sessionType={sessionType}
                                setSessionType={setSessionType}
                            />
                        )}
                    <Box>
                        {(sessionType === 'ALL' ||
                            sessionType === 'INTERNAL') &&
                            filteredSessionList
                                ?.filter(
                                    ({ session }) =>
                                        session.session_type === 'SELF'
                                )
                                ?.map((session, index) => (
                                    <ChatSessionRows
                                        setSelectedSessionAvatarColor={
                                            setSelectedSessionAvatarColor
                                        }
                                        idx={index}
                                        session_details={session}
                                        highlightBox={
                                            session.session.session_id ===
                                            selectedSessionID
                                        }
                                        key={session.session.session_id}
                                        updateSelectedSessionId={
                                            updateSelectedSession
                                        }
                                        containerSelector={'session-list'}
                                        user_type={user_type}
                                        unreadMsgCount={
                                            session.session.session_id in
                                            allSessionsUnreadCount
                                                ? allSessionsUnreadCount[
                                                      session.session.session_id
                                                  ]
                                                : 0
                                        }
                                        updateLastReadMsgId={
                                            updateLastReadMsgId
                                        }
                                    />
                                ))}

                        {sessionType !== 'INTERNAL' &&
                            props.hideBroadcast !== true &&
                            props.currentlySelectedEventStatus !== 'DRAFT' &&
                            props.currentlySelectedEventStatus !==
                                'APPROVAL_PENDING' &&
                            filteredSessionList?.some(
                                ({ session }) =>
                                    session.session_type === 'BROADCAST'
                            ) && (
                                <BroadcastSessionRows
                                    highlightBox={isBroadCastSessionSelected}
                                    updateBroadcastSessionSelection={() =>
                                        updateBroadCastSessionSelected(
                                            sessionList?.find(
                                                (session) =>
                                                    session.session
                                                        .session_type ===
                                                    'BROADCAST'
                                            ) ?? null
                                        )
                                    }
                                    containerSelector={'session-list'}
                                    user_type={user_type}
                                    unreadMsgCount={(() => {
                                        const session = sessionList?.find(
                                            (session) =>
                                                session.session.session_type ===
                                                'BROADCAST'
                                        );
                                        if (session) {
                                            return session.session.session_id in
                                                allSessionsUnreadCount
                                                ? allSessionsUnreadCount[
                                                      session.session.session_id
                                                  ]
                                                : 0;
                                        }
                                        return 0;
                                    })()}
                                    session_details={
                                        sessionList !== null
                                            ? sessionList.find(
                                                  ({
                                                      session: { session_type },
                                                  }) =>
                                                      session_type ===
                                                      'BROADCAST'
                                              )
                                            : undefined
                                    }
                                />
                            )}
                        {/* if event list is null show spinner */}
                        {filteredSessionList === null && (
                            <Box className="flex flex--jcc" padding="0.5rem">
                                <CircularProgress color="primary" size={40} />
                            </Box>
                        )}
                        {filteredSessionList !== null &&
                            //  if there are no event show default text
                            (filteredSessionList.length === 0 ? (
                                <Box
                                    padding={'20px 10px'}
                                    // borderBottom="1px solid"
                                    borderColor={'dividerColor.main'}
                                    // style={{ background: '#ebf5ff' }}
                                >
                                    <FWTypography
                                        color="text.secondary"
                                        textAlign={'center'}
                                        fontSize={'15px'}
                                    >
                                        No chats found
                                    </FWTypography>
                                </Box>
                            ) : (
                                // if there are events show them
                                filteredSessionList
                                    .filter(
                                        ({ session: { session_type } }) =>
                                            session_type !== 'SELF' &&
                                            (sessionType === 'ALL'
                                                ? true
                                                : sessionType === 'EXTERNAL'
                                                ? session_type === 'CHAT'
                                                : session_type ===
                                                  'INTERNAL') /*  ||
                                                  session_type === 'SELF' */ &&
                                            session_type !== 'BROADCAST'
                                    )
                                    .sort((a, b) => {
                                        const postedDate1 =
                                            a.last_message?.posted_time;
                                        const timestamp1 = postedDate1
                                            ? new Date(postedDate1).valueOf()
                                            : 0;
                                        const postedDate2 =
                                            b.last_message?.posted_time;
                                        const timestamp2 = postedDate2
                                            ? new Date(postedDate2).valueOf()
                                            : 0;
                                        return timestamp2 - timestamp1;
                                    })

                                    .map((session, i) => {
                                        return (
                                            <ChatSessionRows
                                                setSelectedSessionAvatarColor={
                                                    setSelectedSessionAvatarColor
                                                }
                                                idx={i}
                                                session_details={session}
                                                highlightBox={
                                                    session.session
                                                        .session_id ===
                                                    selectedSessionID
                                                }
                                                key={session.session.session_id}
                                                updateSelectedSessionId={
                                                    updateSelectedSession
                                                }
                                                containerSelector={
                                                    'session-list'
                                                }
                                                user_type={user_type}
                                                unreadMsgCount={
                                                    session.session
                                                        .session_id in
                                                    allSessionsUnreadCount
                                                        ? allSessionsUnreadCount[
                                                              session.session
                                                                  .session_id
                                                          ]
                                                        : 0
                                                }
                                                updateLastReadMsgId={
                                                    updateLastReadMsgId
                                                }
                                            />
                                        );
                                    })
                            ))}
                        {/* {sessionType === 'INTERNAL' && !internalChatExists && (
                            <Box
                                // bgcolor={'#8181811f'}
                                margin={'15px'}
                                padding={'8px'}
                                borderRadius={'15px'}
                            >
                                <Typography
                                    color={'text.secondary'}
                                    // fontWeight={'bolder'}
                                    fontSize={12}
                                >
                                    <span
                                        style={{
                                            // fontSize: 15,
                                            marginRight: 5,
                                        }}
                                    >
                                        <i
                                            className="bi bi-info-circle"
                                            style={{
                                                WebkitTextStrokeWidth: 0.5,
                                            }}
                                        ></i>
                                    </span>
                                    The internal chat is not created as no other
                                    user has access to this event.
                                </Typography>
                            </Box>
                        )} */}
                    </Box>
                </Box>
                {/* </Box> */}
            </Box>
        );
    } else {
        return <></>;
    }
}

const SessionSearchField = (props: {
    sessionList: IUserSession[] | null;
    setFilteredSessionList: (new_session_list: IUserSession[] | null) => void;
    user_type: 'buyer' | 'seller';
}) => {
    const [showLoading, setShowLoading] = React.useState<boolean>(false);

    const { authData } = useContext(AuthContext);

    const handleEventNameSearch = React.useMemo(
        () =>
            debounce((search_string: string) => {
                search_string = search_string.toLowerCase();
                if (props.sessionList !== null) {
                    if (search_string.trim() === '') {
                        props.setFilteredSessionList(props.sessionList);
                    } else {
                        let newFilteredEventsList = props.sessionList.filter(
                            (session) => {
                                if (
                                    (session.session.session_type === 'SELF' &&
                                        ('internal chat'.includes(
                                            search_string
                                        ) ||
                                            authData.details?.username
                                                ?.toLowerCase()
                                                ?.includes(search_string))) ||
                                    (session.session.session_type ===
                                        'BROADCAST' &&
                                        'broadcast'.includes(search_string))
                                ) {
                                    return true;
                                }
                                return props.user_type === 'buyer'
                                    ? session.session.seller_entity_name
                                          ?.toLowerCase()
                                          ?.includes(search_string)
                                    : session.session.buyer_entity_name
                                          ?.toLowerCase()
                                          ?.includes(search_string);
                            }
                        );
                        props.setFilteredSessionList(newFilteredEventsList);
                    }
                } else {
                    props.setFilteredSessionList(null);
                }
                setShowLoading(false);
            }, 1000),
        [authData.details?.username, props]
    );

    return (
        <FWInput
            placeholder="Search all groups"
            iconStart={<i className="bi bi-search"></i>}
            iconEnd={showLoading ? <CircularProgress size={20} /> : <></>}
            onChange={(e) => {
                setShowLoading(true);
                handleEventNameSearch(e.target.value);
            }}
        />
    );
};
