import React from 'react';
import { CustomBidTextField, CustomTextField } from './FWCustomInputs';
import { FWTypography } from './FWTypograhy';

export const FWCustomGridKeyValue = ({
    title,
    children,
}: {
    title: string;
    children: React.ReactNode;
}) => {
    return (
        <div style={{ width: '100%' }}>
            <FWTypography sx={{ color: 'text.secondary' }}>
                {title}
            </FWTypography>
            {children}
        </div>
    );
};
// TODO:Rename this component as FW*
// TODO: delete the below component and replce its usage with the first one
export const EditGridKeyValue = (props: any) => {
    return (
        <div>
            <FWTypography sx={{ color: 'text.secondary' }}>
                {props.title}
            </FWTypography>
            <CustomTextField {...props} fullWidth />
        </div>
    );
};

// TODO: delete the below component and replce its usage with the first one
export default function EditBidGridKeyValue(props: any) {
    return (
        <div style={{ marginTop: '8px' }}>
            <FWTypography sx={{ color: 'text.secondary' }}>
                {props.title}
            </FWTypography>
            <CustomBidTextField type="number" size="small" {...props} />
        </div>
    );
}
