import React from 'react';
import { IVLNEmployees } from '../../../Interfaces/VendorLNInterfaces';
import { FWPopupWithChildren } from '../../../../Common/FWPopupWithChildren';
import { DialogContent, Grid } from '@mui/material';
import VLNProfileAboutEmployeesComponent from './VLNProfileAboutEmployeesComponent';

const VLNProfileAboutOtherInfoEmployeesPopup = ({
    employeesList,
    open,
    handleClose,
}: {
    employeesList: IVLNEmployees[];
    open: boolean;
    handleClose: () => void;
}) => {
    return (
        <FWPopupWithChildren
            title={`Employees (${employeesList.length})`}
            open={open}
            handleClose={handleClose}
            size={'custom'}
            popupWidth={'1000px'}
        >
            <DialogContent>
                <Grid container columnSpacing={5} rowSpacing={3}>
                    {employeesList.map((employeeData, idx) => (
                        <Grid item xs={6} key={idx}>
                            <VLNProfileAboutEmployeesComponent
                                employeeData={employeeData}
                            />
                        </Grid>
                    ))}
                </Grid>
            </DialogContent>
        </FWPopupWithChildren>
    );
};

export default VLNProfileAboutOtherInfoEmployeesPopup;
