//TODO: remove this and use service layer instead to do such things
export const JsonNumsToStrings = (obj: any) => {
    for (let key in obj) {
        if (typeof obj[key] === 'object') {
            //TODO: Ayaan thinks this line is sketch
            JsonNumsToStrings(obj[key]);
        } else if (typeof obj[key] === 'number') {
            obj[key] = obj[key].toString();
        }
    }
};
