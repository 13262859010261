import React, { useState } from 'react';
import { FWTypography } from '../../../../Common/FWTypograhy';
import { Box } from '@mui/material';
import VLNProfileAboutEmployeesComponent from './VLNProfileAboutEmployeesComponent';
import { IVLNEmployees } from '../../../Interfaces/VendorLNInterfaces';
import VLNProfileAboutOtherInfoEmployeesPopup from './VLNProfileAboutOtherInfoEmployeesPopup';
import { VLNProfileStyledBox } from '../../Common/VLNProfileStyledBox';

const VLNProfileAboutOtherInfoEmployees = ({
    employeesList,
}: {
    employeesList: IVLNEmployees[];
}) => {
    const [open, setOpen] = useState(false);
    return (
        <VLNProfileStyledBox>
            <Box className="flex flex--aifs flex--jcsb" marginBottom={2}>
                <FWTypography variant={'h4'} fontWeight={500}>
                    Employees ({employeesList.length})
                </FWTypography>
                {employeesList.length > 1 && (
                    <FWTypography
                        color={'primary.main'}
                        sx={{
                            textDecoration: 'underline',
                            cursor: 'pointer',
                        }}
                        onClick={() => setOpen(true)}
                    >
                        View all
                    </FWTypography>
                )}
            </Box>
            {employeesList.length > 0 ? (
                employeesList.slice(0, 3).map((item, idx) => (
                    <Box
                        key={idx}
                        marginBottom={idx === employeesList.length - 1 ? 0 : 3}
                    >
                        <VLNProfileAboutEmployeesComponent
                            employeeData={item}
                        />
                    </Box>
                ))
            ) : (
                <FWTypography color={'text.secondary'} textAlign={'center'}>
                    No employees added
                </FWTypography>
            )}
            <VLNProfileAboutOtherInfoEmployeesPopup
                employeesList={employeesList}
                open={open}
                handleClose={() => setOpen(false)}
            />
        </VLNProfileStyledBox>
    );
};

export default VLNProfileAboutOtherInfoEmployees;
