import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IGlobalAppStore } from '../../Redux/Store';
import { ChatTypes } from '../Interfaces/Chat.model';
import { getMessageCount } from '../Services/chat.service';
import { addMessagesReceived, updateSentMessages } from '../Slice/Chat.slice';

const BASE_URL_FOR_SOCKET: string =
    process.env.REACT_APP_ENV === 'production' ||
    process.env.REACT_APP_ENV === 'newdbtest1'
        ? 'wss://0piaj4cpdb.execute-api.us-east-1.amazonaws.com/test'
        : process.env.REACT_APP_ENV === 'newdbtest' ||
          process.env.REACT_APP_ENV === 'start' ||
          process.env.REACT_APP_ENV === 'development'
        ? 'wss://2cpl0p2god.execute-api.us-east-1.amazonaws.com/test'
        : // ? 'ws://localhost:9000/ws/server/'
          'wss://2cpl0p2god.execute-api.us-east-1.amazonaws.com/test'; // doing this for demo purpose

export function useSocket(user_id: string | null) {
    const [socket, setSocket] = useState<WebSocket | null>(null);
    // get idToken from local or session storage
    const idToken =
        localStorage.getItem('idToken') ||
        sessionStorage.getItem('idToken') ||
        null;
    // get list of messagesToSend from chat store
    const messagesToSend = useSelector(
        (state: IGlobalAppStore) => state.ChatStore.messagesToSend
    );
    const dispatch = useDispatch();

    /** function to initialize the socket connection
     * @param {string} id_token - the id_token of the user obtained while logging in
     * @return {void}
     */
    const initSocket = useCallback(
        (id_token: string): void => {
            try {
                const socket = new WebSocket(
                    `${BASE_URL_FOR_SOCKET}?token=${id_token}`
                );
                // before storing the new socket
                // we close the previous socket if it exists
                socket.onopen = (e) => {
                    const timestamp = Date.now();
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    const currentTime = new Date(
                        timestamp
                    ).toLocaleTimeString();
                    if (
                        process.env.REACT_APP_ENV !== 'production' &&
                        process.env.REACT_APP_ENV !== 'newdbtest1'
                    ) {
                    }
                    (async () => {
                        const getChatMessageCount = await getMessageCount();

                        if (user_id !== null) {
                            dispatch(
                                addMessagesReceived({
                                    api_resp: getChatMessageCount,
                                    receiver_id: user_id,
                                })
                            );
                        }
                    })();
                };
                socket.onclose = (e) => {
                    const timestamp = Date.now();
                    const currentTime = new Date(
                        timestamp
                    ).toLocaleTimeString();
                    process.env.REACT_APP_ENV !== 'production' &&
                        process.env.REACT_APP_ENV !== 'newdbtest1' &&
                        console.error(
                            'Socket Connection Closed :',
                            e,
                            currentTime
                        );

                    // this should fix the issue with automatic reconnection
                    if (e.code === 1001 /* || e.code === 1006 */) {
                        initSocket(id_token);
                    }
                };
                socket.onerror = (e) => {
                    const timestamp = Date.now();
                    const currentTime = new Date(
                        timestamp
                    ).toLocaleTimeString();
                    process.env.REACT_APP_ENV !== 'production' &&
                        process.env.REACT_APP_ENV !== 'newdbtest1' &&
                        console.error(
                            'Error in Socket Connection :',
                            e,
                            currentTime
                        );
                };
                socket.onmessage = (e) => {
                    if (user_id !== null) {
                        dispatch(
                            addMessagesReceived({
                                api_resp: JSON.parse(e.data),
                                receiver_id: user_id,
                            })
                        );
                    }
                    (async () => {
                        const getChatMessageCount = await getMessageCount();

                        if (user_id !== null) {
                            dispatch(
                                addMessagesReceived({
                                    api_resp: getChatMessageCount,
                                    receiver_id: user_id,
                                })
                            );
                        }
                    })();
                };
                setSocket((prev) => {
                    if (prev !== null) {
                        prev.close();
                    }
                    return socket;
                });
            } catch (e) {
                //
            }
        },
        [dispatch, user_id]
    );

    /** function to close the socket connection
     * @return {void}
     */
    const closeSocket = useCallback((): void => {
        setSocket((prev) => {
            if (prev !== null) {
                prev.close();
            }
            return prev;
        });
    }, []);

    // useEffect hook that checks idToken
    // and initializes the socket if token is valid
    useEffect(() => {
        /* DISABLED FOR SANDBOX_DEPLOY */

        if (idToken !== null && user_id !== null) {
            initSocket(idToken);
        } else {
            closeSocket();
        }
    }, [closeSocket, idToken, initSocket, user_id]);

    // useEffect hook that checks idToken
    // and closes the socket if token is invalid
    useEffect(() => {
        return () => {
            closeSocket();
        };
    }, [closeSocket]);

    // useEffect hook that checks messagesToSend
    // and sends the messages to the server
    useEffect(() => {
        // socket.readyState === 1 means socket is open for connection
        if (socket !== null && idToken !== null && socket.readyState === 1) {
            let sent_message_ids: string[] = [];
            messagesToSend.forEach((message) => {
                sent_message_ids.push(message.message_id);
                let payload: any = {};
                if (message.message_type === ChatTypes.DIRECT) {
                    payload = {
                        session_id: message.session_id,
                        message: message.message,
                        user_id: user_id,
                        attachment_ids: message.attachment_ids,
                    };
                }
                if (message.message_type === ChatTypes.BROADCAST) {
                    payload = {
                        message: message.message,
                        module_id: message.module_id,
                        session_id: message.session_id,
                        seller_entity_id_list: message.seller_entity_list,
                        user_id: user_id,
                        attachment_ids: message.attachment_ids,
                    };
                }
                let obj = JSON.stringify({
                    action: message.message_type, // used for API Gateway Route Selection Expression
                    // token: idToken,
                    payload: payload,
                });
                socket.send(obj);
            });
            // very important check otherwise this useEffect will run infinitely
            if (sent_message_ids.length > 0) {
                dispatch(updateSentMessages(sent_message_ids));
            }
        }
    }, [dispatch, idToken, messagesToSend, socket, user_id]);

    return {};
}
