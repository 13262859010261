import { Badge, Box, CircularProgress } from '@mui/material';
import React, { useState } from 'react';
import { FWIconButton } from '../../Common/FWButton';
import { FWTooltip } from '../../Common/FWCustomTooltip';
import FWIcon from '../../Common/FWIcon';
import FWPopover from '../../Common/FWPopover';
import { FWTypography } from '../../Common/FWTypograhy';
import UploadFile, {
    IFileResourceType,
} from '../../Components/Shared/UploadFile';
import UploadedFilesList from '../../Components/Shared/UploadedFilesList';
import { IAttachment } from '../../Models/RFQ.model';

export default function EditAttachmentsButtonAndPopover({
    attachments,
    resourceType,
    popupTitle = 'Attachments',
    clipIconSize = 18,
    onFileUpload,
    removeAttachment,
    disabledTooltip = null,
    id = 'editAttachments',
    CustomComponentForFileUploadIcon,
    CustomLoaderForFileUpload,
    showRemoveButton = true,
    allowFileUpload = true,
    showAddFileToolip = true,
    allowedFileTypes,
    multiple = true,
    customIconTooltip = '',
}: {
    attachments: IAttachment[];
    resourceType: IFileResourceType;
    popupTitle?: string;
    customIconTooltip?: string;
    clipIconSize?: number;
    onFileUpload: (files: IAttachment[]) => void;
    removeAttachment: (idx: number) => Promise<void>;
    disabledTooltip?: string | null;
    id?: string;
    allowedFileTypes?: string[];
    CustomComponentForFileUploadIcon?: () => JSX.Element;
    CustomLoaderForFileUpload?: () => JSX.Element;
    showRemoveButton?: boolean;
    allowFileUpload?: boolean;
    showAddFileToolip?: boolean;
    multiple?: boolean;
}) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handlePopoverOpen = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);

    const [updatingFileList, setUpdatingFileList] = useState(false);

    return (
        <>
            {attachments.length > 0 || open || disabledTooltip !== null ? (
                CustomComponentForFileUploadIcon ? (
                    <Box
                        onClick={(e: any) => {
                            e.stopPropagation();
                            handlePopoverOpen(e);
                        }}
                    >
                        <CustomComponentForFileUploadIcon />
                    </Box>
                ) : (
                    <FWTooltip
                        title={
                            disabledTooltip === null
                                ? Boolean(customIconTooltip)
                                    ? customIconTooltip
                                    : 'Edit Attachments'
                                : disabledTooltip
                        }
                    >
                        <Box>
                            <FWIconButton
                                id={id}
                                size="small"
                                sx={{ fontSize: '1rem' }}
                                color="primary"
                                onClick={(e: any) => {
                                    e.stopPropagation();
                                    handlePopoverOpen(e);
                                }}
                                disabled={disabledTooltip !== null}
                            >
                                <Badge badgeContent={attachments.length}>
                                    <FWIcon
                                        name="bi bi-paperclip"
                                        size={clipIconSize}
                                        height={25}
                                    />
                                </Badge>
                            </FWIconButton>
                        </Box>
                    </FWTooltip>
                )
            ) : (
                <UploadFile
                    allowedFileSizeInMB={5}
                    existingFiles={attachments}
                    resourceType={resourceType}
                    onFileUpload={(files: IAttachment[]) => {
                        onFileUpload(files);
                    }}
                    label={''}
                    allowedFileTypes={allowedFileTypes}
                    multiple={multiple}
                    allowFileUpload={allowFileUpload}
                    CustomComponentForFileUploadIcon={
                        CustomComponentForFileUploadIcon
                    }
                    showAddFileToolip={showAddFileToolip}
                    CustomLoaderForFileUpload={CustomLoaderForFileUpload}
                    icon={'CLIP'}
                    clipIconSize={clipIconSize}
                />
            )}
            <FWPopover
                id="edit-attachments-popover"
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
                PaperProps={{
                    sx: {
                        p: '16px !important',
                    },
                }}
            >
                <>
                    <div>
                        <UploadFile
                            existingFiles={attachments}
                            resourceType={resourceType}
                            allowFileUpload={allowFileUpload}
                            showAddFileToolip={showAddFileToolip}
                            onFileUpload={(files: IAttachment[]) => {
                                onFileUpload(files);
                            }}
                            labelvariant="h5"
                            // CustomComponentForFileUploadIcon={
                            //     CustomComponentForFileUploadIcon
                            // }
                            labelColor="default"
                            allowedFileTypes={allowedFileTypes}
                            label={'Attachments'}
                            multiple
                        />
                        <Box height="0.25rem" />
                        {updatingFileList ? (
                            <>
                                <CircularProgress color="primary" size={20} />
                                {/* <FWTypography>hello</FWTypography> */}
                            </>
                        ) : attachments.length > 0 ? (
                            <UploadedFilesList
                                existingFiles={attachments}
                                toggleFileRemove={(idx: number) => {
                                    setUpdatingFileList(true);

                                    setTimeout(() => {
                                        removeAttachment(idx)
                                            .then(() => {
                                                setUpdatingFileList(false);
                                            })
                                            .catch(() => {
                                                setUpdatingFileList(false);
                                            });
                                    }, 500);
                                }}
                                showRemove={showRemoveButton}
                            />
                        ) : (
                            <FWTypography color="text.secondary">
                                No attachments added
                            </FWTypography>
                        )}
                    </div>
                </>
            </FWPopover>

            {/* {attachments.map((curAttachment) => {
                        return (
                            <FwFileDownloadLink
                                resourceId={resourceId}
                                resourceType={resourceType}
                                fileName={curAttachment.file_name}
                                attachmentId={curAttachment.attachment_id}
                                sourceEnterprise={
                                    curAttachment.source_enterprise
                                }
                            />
                        );
                    })} */}
        </>
    );
}
