import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { HookStateValue, useHookState } from '../../Common/Hooks/StateHook';
import { fetchRFQItemList } from '../../Components/Buyer/Events/DraftRfqSummary/helperFunctions';
import { IEventItemDetails } from '../../Events/Interfaces/Buyer/RFQ.model';
import { IRFQItemAtrributeValue } from '../../Events/Interfaces/Buyer/SharedRFQ.model';
import { IChatNotesList } from '../Interfaces/Chat.model';
import {
    createNewCustomNote,
    fetchChatNotesList,
} from '../Services/chat.service';

export const useChatNotes = (moduleId: string, subEventId: string) => {
    const {
        hookState: notesListHookState,
        updateHookState: updateNotesListHookState,
    } = useHookState(HookStateValue.LOADING);

    const [notesList, setNotesList] = useState<IChatNotesList>({
        custom: [],
        items: [],
        sellers: [],
    });
    const getChatNotesList = useCallback(async () => {
        try {
            const noteslist: IChatNotesList = await fetchChatNotesList(
                moduleId
            );
            setNotesList(noteslist);
            updateNotesListHookState(HookStateValue.READY);
        } catch (err) {
            updateNotesListHookState(HookStateValue.ERROR);

            toast.error('Error fetching notes list');
        }
    }, [moduleId, updateNotesListHookState]);

    useEffect(() => {
        getChatNotesList();
    }, [getChatNotesList]);

    const addCustomNote = async (customNoteTitle: string) => {
        try {
            const addNewNote = await createNewCustomNote(
                moduleId,
                customNoteTitle
            );
            setNotesList((prevNotesList) => {
                return {
                    ...prevNotesList,
                    custom: [...prevNotesList.custom, addNewNote],
                };
            });
        } catch (err) {
            toast.error('Error creating new note');
        }
    };

    const [rfqItem, setRfqItem] = useState<{
        [key: string]: IRFQItemAtrributeValue[];
    }>({});

    const getRFQDetails = useCallback(async () => {
        try {
            const rfqDetails = await fetchRFQItemList(subEventId);
            const stateData: {
                [key: string]: IRFQItemAtrributeValue[];
            } = {};
            rfqDetails.forEach((item: IEventItemDetails) => {
                stateData[item.item_information.buyer_item_name] = [
                    ...item.attribute_information.buyer_item_attributes,
                    ...item.attribute_information.custom_item_attributes,
                ];
            });
            setRfqItem(stateData);
            updateNotesListHookState(HookStateValue.READY);
        } catch (err) {
            updateNotesListHookState(HookStateValue.ERROR);

            toast.error('Error fetching RFQ details');
        }
    }, [subEventId, updateNotesListHookState]);

    useEffect(() => {
        getRFQDetails();
    }, [getRFQDetails]);

    return { notesList, notesListHookState, addCustomNote, rfqItem };
};
