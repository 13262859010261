import { DialogContent } from '@mui/material';
import { isEmpty } from 'lodash';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FWPopupWithChildren } from '../../Common/FWPopupWithChildren';
import IdentificationCreatePopup from '../../Organizations/Admin/Pages/Identifications/IdentificationCreatePopup';
import { VerificationActions } from '../../Redux/Reducers/VerificationReducers';
import {
    IVerificationState,
    toggleVerificationPopup,
} from '../../Redux/Slices/VerificationSlice';
import { IGlobalAppStore } from '../../Redux/Store';
import EntityVerificationContainer from './EntityVerificationContainer';

const VerificationStatusContainer = () => {
    const dispatch = useDispatch();

    const [selectedEntity, setSelectedEntity] = useState<{
        name: string;
        uid: string;
    }>({
        name: '',
        uid: '',
    });

    const {
        verificationPopupVisible,
        userVerificationEntities,
    }: IVerificationState = useSelector(
        ({ VerificationStore }: IGlobalAppStore) => VerificationStore
    );

    const handleVerificationPopup = () => {
        dispatch(
            toggleVerificationPopup({
                type: VerificationActions.TOGGLE_POPUP,
                value: !verificationPopupVisible,
            })
        );
    };

    const handleCreatePopupToggle = (data: { name: string; uid: string }) => {
        dispatch(
            toggleVerificationPopup({
                type: VerificationActions.TOGGLE_POPUP,
                value: false,
            })
        );
        setSelectedEntity(data);
    };

    return (
        <>
            <FWPopupWithChildren
                open={verificationPopupVisible}
                handleClose={handleVerificationPopup}
                title="Organization verification"
                PaperProps={{
                    sx: {
                        // maxWidth: '1024px',
                        width: '100%',
                    },
                }}
            >
                <DialogContent>
                    {/* <Grid item xs={12} sx={{ m: '0 0 20px' }}>
                        <FWTypography
                            sx={{ whiteSpace: 'normal', lineHeight: 1.2 }}
                        >
                            Please upload at least one document for verification.
                        </FWTypography>
                    </Grid> */}
                    <i>
                        Verification must be completed in order to submit events
                        or POs
                    </i>
                    <br />
                    <br />
                    {userVerificationEntities.map((entityUid, idx) => {
                        return (
                            <div
                                key={entityUid}
                                className={
                                    idx === userVerificationEntities.length - 1
                                        ? 'mb--5'
                                        : 'mb--15'
                                }
                            >
                                <EntityVerificationContainer
                                    entityUid={entityUid}
                                    onVerifyNow={handleCreatePopupToggle}
                                />
                            </div>
                        );
                    })}
                </DialogContent>
            </FWPopupWithChildren>
            <IdentificationCreatePopup
                popupToggle={!isEmpty(selectedEntity.uid)}
                emitPopupToggle={() => {
                    setSelectedEntity({
                        name: '',
                        uid: '',
                    });
                }}
                entityId={selectedEntity.uid}
                canEditDefault={false}
                isFirstIdentification={true}
                identificationNamesList={[]}
                entityName={selectedEntity.name}
            />
        </>
    );
};

export default VerificationStatusContainer;
