import { createSlice } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';
import {
    IAdminSettingsState,
    IAdminSettingsUpdateActions,
    IASEntitySettingsMap,
} from '../Interfaces/AdminSettingsReduxInterface';
import { updateAdminSettingsReducer } from '../Reducers/AdminSettingsReducer';

export const initialSettings: IASEntitySettingsMap = {};

export const UserAccessSlice = createSlice<
    IAdminSettingsState,
    {
        updateAdminSettings: (
            state: IAdminSettingsState,
            action: IAdminSettingsUpdateActions
        ) => void;
    },
    string
>({
    name: 'AdminSettingsStore',
    initialState: cloneDeep(initialSettings),
    reducers: {
        updateAdminSettings: updateAdminSettingsReducer,
    },
});

export const { updateAdminSettings } = UserAccessSlice.actions;

const AdminSettingsStore = UserAccessSlice.reducer;
export default AdminSettingsStore;
