import { Checkbox, FormControlLabel, Grid, InputLabel } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CustomFormControl } from '../../Common/FWCustomInputs';
import { FWMenuItem } from '../../Common/FWCustomMenu';
import { FWInput } from '../../Common/FWInput';
import FWSelect from '../../Common/FWSelect';
import { IAdminSettingsState } from '../../Redux/Interfaces/AdminSettingsReduxInterface';
import PaymentsPendingListDefaultViewContainer from './PaymentsPendingListDefaultViewContainer';
import PaymentsPendingListInvoiceViewContainer from './PaymentsPendingListInvoiceViewContainer';
import PaymentsPendingListItemisedViewContainer from './PaymentsPendingListItemisedViewContainer';
import { setSelectedTabView } from '../Slices/PaymentsDashboardSlice';
import { useDispatch } from 'react-redux';

export enum PendingView {
    ITEMISED = 'ITEMISED',
    DEFAULT = 'DEFAULT',
    INVOICE = 'INVOICE',
}

const PaymentsPendingListContainer = () => {
    const history = useHistory();
    const [search, setSearch] = useState<string>('');
    const [filter, setFilter] = useState<boolean>(true);

    const settings = useSelector(
        ({ AdminSettingsStore }: { AdminSettingsStore: IAdminSettingsState }) =>
            AdminSettingsStore
    );

    const allowPaymentBeforeQcForEntity = useMemo((): {
        [entityUid: string]: boolean;
    } => {
        let qcSettingMap: { [entityUid: string]: boolean } = {};
        for (let entity_id in settings) {
            qcSettingMap[entity_id] = false;
            let paymentModule = settings[entity_id].find(
                (module) => module.module === 'PAYMENT'
            );
            if (!paymentModule) {
                continue;
            }
            let qcSetting = paymentModule.settings.find(
                (setting) => setting.option === 'PAYMENTS_ALLOW_BEFORE_QC'
            );
            if (!qcSetting) {
                continue;
            }
            qcSettingMap[entity_id] = qcSetting.settings.enabled;
        }
        return qcSettingMap;
    }, [settings]);

    let params = new URLSearchParams(window.location.search);
    let initView = (params.get('view') as PendingView) || PendingView.DEFAULT;

    const [view, setView] = useState<PendingView>(initView);

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setSelectedTabView(view));
    }, [dispatch, view]);

    useEffect(() => {
        history.replace(`/buyer/payments?type=pending&view=${view}`);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [view]);

    return (
        <>
            <Grid
                item
                xs={12}
                className="flex flex--aic flex--jcsb"
                sx={{ mb: '20px' }}
            >
                <div>
                    {view === PendingView.ITEMISED && (
                        <FormControlLabel
                            control={<Checkbox />}
                            label="Show items with no pending payment"
                            checked={filter}
                            onChange={() => {
                                setFilter(!filter);
                            }}
                        />
                    )}
                </div>
                <div className="flex flex--aic flex--jcsb">
                    <CustomFormControl>
                        <InputLabel id="view-select-label">View</InputLabel>
                        <FWSelect
                            labelId="view-select-label"
                            label="View"
                            onChange={(e) => {
                                setView(e.target.value as PendingView);
                            }}
                            value={view}
                            sx={{ minWidth: '150px' }}
                        >
                            <FWMenuItem
                                id="paymentsDashboard_defaultView"
                                value={PendingView.DEFAULT}
                            >
                                Default
                            </FWMenuItem>
                            <FWMenuItem
                                id="paymentsDashboard_InvoiceView"
                                value={PendingView.INVOICE}
                            >
                                Invoice
                            </FWMenuItem>
                            <FWMenuItem
                                id="paymentsDashboard_InvoiceItemView"
                                value={PendingView.ITEMISED}
                            >
                                Invoice item
                            </FWMenuItem>
                        </FWSelect>
                    </CustomFormControl>
                    <div className="mr--15"></div>
                    <FWInput
                        value={search}
                        onChange={(e: any) => setSearch(e.target.value)}
                        placeholder={'Search'}
                        iconStart={<i className="bi bi-search" />}
                        style={{ width: 400 }}
                    />
                </div>
            </Grid>

            {view === PendingView.DEFAULT && (
                <PaymentsPendingListDefaultViewContainer
                    search={search}
                    filter={filter}
                    allowPaymentBeforeQcForEntity={
                        allowPaymentBeforeQcForEntity
                    }
                />
            )}

            {view === PendingView.ITEMISED && (
                <PaymentsPendingListItemisedViewContainer
                    search={search}
                    filter={filter}
                    allowPaymentBeforeQcForEntity={
                        allowPaymentBeforeQcForEntity
                    }
                />
            )}

            {view === PendingView.INVOICE && (
                <PaymentsPendingListInvoiceViewContainer
                    search={search}
                    filter={filter}
                    allowPaymentBeforeQcForEntity={
                        allowPaymentBeforeQcForEntity
                    }
                />
            )}
        </>
    );
};

export default PaymentsPendingListContainer;
