import { cloneDeep } from 'lodash';
import { IFieldTypeConstraints } from '../../FieldTypes/FieldTypes.model';
import { ICurrencyDetails } from '../../Models/Currency.model';
import { IAttachment } from '../../Models/RFQ.model';
import { baseApiSlice } from '../../Redux/Slices/BaseApiSlice';
import { del, get, post, put } from '../../Utils/api';
import {
    DocumentStateEnum,
    ICreateDocument,
    IDocumentCustomField,
    IDocumentDetails,
    IDocumentSellerDetails,
    INewDocument,
} from '../Constants/Document.model';
import {
    CustomFieldType,
    DocumentTemplateStandardFieldBackendNames,
    IDocumentTemplate,
    IDocumentTemplateBackendResponse,
    IDocumentTemplateSectionDetails,
} from '../Constants/Interfaces/documentTemplate';

export interface IDocumentData {
    document_id: string;
    document_name: string;
    state?: DocumentStateEnum;
    created_by_user_name: string;
    created_datetime: string;
    modified_datetime: string;
    buyer_enterprise: string;
    buyer_entity: string;
    buyer_entity_name: string;
    seller_entity: null;
    seller_enterprise: null;
    shared_vendor_count: number;
    seller_status?: 'Responded' | 'Not Responded' | 'Revising';
    seller_document_id?: string | null;
}

export interface IVendorData {
    document_id: string;
    document_name: string;
    created_by_user_name: string;
    created_datetime: string;
    modified_datetime: string;
    buyer_enterprise: string;
    buyer_entity: string;
    buyer_entity_name: string;
    seller_entity_id: string;
    seller_entity_name: string | null;
    seller_enterprise_id: string | null;
    attachments: IAttachment[];
    custom_fields: {
        section_list: {
            name: string;
            fields: IDocumentCustomField[];
        }[];
    };
    custom_fields_negotiate: {
        section_list: {
            name: string;
            fields: IDocumentCustomField[];
        }[];
    };
    additional_details: {
        template_id: string;
    };
}

export interface IVendorDocument {
    document_id: string;
    document_name: string;
    created_by_user_name: string;
    created_datetime: string;
    modified_datetime: string;
    seller_enterprise_id: string | null;
    seller_entity_id: string | null;
    seller_entity_name: string | null;
    attachments: IAttachment[];
}

interface IFetchVendorData {
    data: IVendorData[];
    metadata: {
        current_page: number;
        has_next: boolean;
        has_previous: boolean;
        total_pages: number;
        page_range: {
            start: number;
            stop: number;
        };
        count: number;
    };
    counts: IDocumentCount;
}

interface IDocumentCount {
    all: number;
}

interface IFetchDocumentData {
    data: IDocumentData[];
    metadata: {
        current_page: number;
        has_next: boolean;
        has_previous: boolean;
        total_pages: number;
        page_range: {
            start: number;
            stop: number;
        };
        count: number;
    };
    counts: IDocumentCount;
}

export interface IDocumentPageDetails {
    current_page: number;
    has_next: boolean;
    has_previous: boolean;
    total_pages: number;
    count: number;
    page_range: {
        start: number;
        stop: number;
    };
}

export interface IDocumentTabCountData {
    tab_counts: {
        all: number;
    };
}

export const createNewDocumentAPI = async (
    document_details: ICreateDocument
) => {
    let payload = {
        document_name: document_details.document_name,
        entity_id: document_details.entity_id,
        seller_entity_id: document_details.seller_entity_id,
        template_id: document_details.template_id,
        document_history_id: document_details.document_history_id,
        document_entry_id: document_details.document_entry_id,

        // custom_fields: document_details.custom_fields,
        // custom_fields_negotiate: document_details.custom_fields_negotiate,
        // attachment_ids: document_details.attachment_ids,
    };

    let resp = await post<
        any,
        {
            document_id: string;
        }
    >('/document/create/', payload);

    return resp.data;
};

export const updateDocumentVersionAPI = async (
    document_id: string,
    document_details: INewDocument,
    sellerEntityId: string | null
) => {
    let payload = {
        document_name: document_details.document_name,
        entity_id: document_details.entity_id,
        seller_entity_id: sellerEntityId,
        template_id: document_details.template_id,
        document_description: document_details.document_description
            ? document_details.document_description
            : null,
        document_logo_id: document_details.document_logo_id,
        document_banner_id: document_details.document_banner_id,
        document_validity_datetime: document_details.document_validity_datetime
            ? document_details.document_validity_datetime
            : null,
        custom_fields: document_details.custom_fields,
        custom_fields_negotiate: document_details.custom_fields_negotiate,
        role: document_details.role,
        attachment_ids: document_details.attachment_ids,
    };

    let resp = await post<
        any,
        {
            document_id: string;
        }
    >(`/document/${document_id}/version/`, payload);

    return resp.data;
};
export const updateDocumentAPI = async (
    document_id: string,
    document_details: INewDocument
) => {
    try {
        let payload = {
            document_name: document_details.document_name,
            entity_id: document_details.entity_id,
            seller_entity_id: document_details.seller_entity_id,
            document_description: document_details.document_description
                ? document_details.document_description
                : null,
            document_validity_datetime:
                document_details.document_validity_datetime
                    ? document_details.document_validity_datetime
                    : null,
            document_banner_id: document_details.document_banner_id,
            document_logo_id: document_details.document_logo_id,
            template_id: document_details.template_id,
            custom_fields: document_details.custom_fields,
            custom_fields_negotiate: document_details.custom_fields_negotiate,
            role: document_details.role,
            attachment_ids: document_details.attachment_ids,
        };

        let resp = await put<
            any,
            {
                document_id: string;
            }
        >(`/document/${document_id}/update/`, payload);

        return resp.data;
    } catch (err) {
        throw new Error('Failed to update document');
    }
};

export const updateDocumentStateAPI = async (
    document_id: string,
    state: DocumentStateEnum
) => {
    let payload = {
        state,
    };
    try {
        await put<any, any>(`/document/${document_id}/state/`, payload);
    } catch (e) {
        throw new Error('Error while updating document state');
    }
};

export const fetchDocumentDetails = async (document_id: string) => {
    let resp = await get<IDocumentDetails>(`/document/${document_id}/`);

    return resp.data;
};

export const fetchDocumentSellers = async (document_id: string) => {
    let resp = await get<IDocumentSellerDetails[]>(
        `/document/${document_id}/sellers/`
    );

    return resp.data;
};

export const updateDocumentSellers = async (
    document_id: string,
    seller_entity_id_list: string[]
) => {
    let payload = {
        seller_entity_id_list: seller_entity_id_list,
    };
    let resp = await post<any, any>(
        `/document/${document_id}/sellers/update/`,
        payload
    );

    return resp.data;
};

export const deleteDocument = async (documentId: string) => {
    try {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        let res = await del<any>(`/document/${documentId}/delete/`);

        return true;
    } catch (e) {
        return false;
    }
};

export const getAllAdditionalCostForTemplate = async () => {
    let res = await get<any>(`/additional_cost/`);

    return res.data;
};

export const documentApiSlice = baseApiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getDocumentDetails: builder.query<
            IDocumentDetails,
            { document_id: string }
        >({
            query: ({ document_id }) => ({
                url: `/document/${document_id}/`,
            }),
        }),
        getSellerDocumentResponse: builder.query<
            IDocumentDetails[],
            { document_id: string; seller_entity_id: string }
        >({
            query: ({ document_id, seller_entity_id }) => ({
                url: `/document/${document_id}/seller/${seller_entity_id}`,
            }),
        }),
        getDocumentTabCount: builder.mutation<
            {
                tab_counts: {
                    all: number;
                };
            },
            {}
        >({
            query: () => ({
                url: '/dashboard/tab_counts/',
                method: 'POST',
                body: {
                    dashboard_view: 'document_buyer',
                },
            }),
        }),
        getDocumentData: builder.mutation<
            {
                documentList: IDocumentData[];
                pageDetails: IDocumentPageDetails;
                countDetails: IDocumentCount;
            },
            {
                page_number: number;
                items_per_page: number;
                sort_fields: { field: string; ascending: boolean }[];
                search_text: string;
            }
        >({
            query: ({
                page_number,
                items_per_page,
                search_text,
                sort_fields,
            }) => ({
                url: `/dashboard/`,
                method: 'POST',
                body: {
                    dashboard_view: 'document_buyer',
                    tab: 'all',
                    query_data: {},
                    search_text,
                    page_number,
                    sort_fields,
                    items_per_page,
                },
            }),
            transformResponse: (
                baseQueryReturnValue: IFetchDocumentData,
                meta,
                arg
            ) => {
                let res: {
                    documentList: IDocumentData[];
                    pageDetails: IDocumentPageDetails;
                    countDetails: IDocumentCount;
                } = {
                    documentList: baseQueryReturnValue.data,
                    countDetails: baseQueryReturnValue.counts,
                    pageDetails: baseQueryReturnValue.metadata,
                };

                return res;
            },
        }),
        getVendorData: builder.mutation<
            {
                vendorList: IVendorData[];
                pageDetails: IDocumentPageDetails;
                countDetails: IDocumentCount;
            },
            // Add the missing type argument for transformResponse
            {
                page_number: number;
                items_per_page: number;
                sort_fields: { field: string; ascending: boolean }[];
                search_text: string;
                document_id: string;
            }
        >({
            query: ({
                page_number,
                items_per_page,
                search_text,
                sort_fields,
                document_id,
            }) => ({
                url: `/dashboard/`,
                method: 'POST',
                body: {
                    dashboard_view: 'document_seller_responses',
                    tab: 'all',
                    query_data: {
                        document_id,
                    },
                    search_text,
                    page_number,
                    sort_fields,
                    items_per_page,
                },
            }),
            transformResponse: (
                baseQueryReturnValue: IFetchVendorData,
                meta,
                arg
            ) => {
                let res: {
                    vendorList: IVendorData[];
                    pageDetails: IDocumentPageDetails;
                    countDetails: IDocumentCount;
                } = {
                    vendorList: baseQueryReturnValue.data,
                    countDetails: baseQueryReturnValue.counts,
                    pageDetails: baseQueryReturnValue.metadata,
                };

                return res;
            },
        }),

        getAllCurrencyOptions: builder.query<ICurrencyDetails[], {}>({
            query: () => ({
                url: `/backbone/currency_code`,
            }),
            transformResponse: (
                baseQueryReturnValue: ICurrencyDetails[],
                meta,
                args
            ) => {
                return baseQueryReturnValue.sort((a, b) =>
                    a.currency_code_abbreviation.localeCompare(
                        b.currency_code_abbreviation
                    )
                );
            },
        }),
        // getAllAdditionalCostNames: builder.query<string[], {}>({
        //     query: () => ({
        //         url: `/additional_cost/names/`,
        //     }),
        //     transformResponse: (
        //         baseQueryReturnValue: { cost_name: string }[],
        //         meta,
        //         args
        //     ) => {
        //         return baseQueryReturnValue.map((costNameObj) =>
        //             costNameObj.cost_name.toLowerCase()
        //         );
        //     },
        // }),
    }),
});

export const {
    useGetDocumentTabCountMutation,
    useGetDocumentDataMutation,
    useGetVendorDataMutation,
    useGetAllCurrencyOptionsQuery,
    // useGetAllAdditionalCostNamesQuery,
    useGetDocumentDetailsQuery,
    useGetSellerDocumentResponseQuery,
} = documentApiSlice;

const sellerDocumentResponse = baseApiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getSellerRsponseForBuyerDoc: builder.query<
            IDocumentDetails[],
            {
                document_id: string;
                seller_entity_id: string;
            }
        >({
            query: ({ document_id, seller_entity_id }) => ({
                url: `/document/${document_id}/seller/${seller_entity_id}`,
            }),
        }),
    }),
});

export const { useGetSellerRsponseForBuyerDocQuery } = sellerDocumentResponse;

export const getSellerRsponseForBuyerDoc = async ({
    document_id,
    seller_entity_id,
}: {
    document_id: string;
    seller_entity_id: string;
}) => {
    try {
        if (document_id && seller_entity_id) {
            const res = await get<IDocumentDetails[]>(
                `/document/${document_id}/seller/${seller_entity_id}`
            );

            return res.data;
        }
    } catch (err) {
        return null;
    }
};

export const getDocumentDetails = async ({
    document_id,
}: {
    document_id: string;
}) => {
    try {
        if (document_id) {
            const res = await get<IDocumentDetails>(
                `/document/${document_id}/`
            );

            return res.data;
        }
    } catch (err) {
        return null;
    }
};

export const blankDocumentTemplate: IDocumentTemplate = {
    fieldSettings: {
        customFields: {},
        standardFields: {
            DOCUMENT_BANNER: {
                label: 'Document banner',
                description: '',
                buyerSettings: {
                    required: false,
                    showField: true,
                },
                sellerSettings: {
                    isMandatory: true,
                    negotiable: true,
                    showField: true,
                },
                standardFieldCategory: 'STANDARD',
            },
            DOCUMENT_DESCRIPTION: {
                label: 'Document description',
                description: '',
                buyerSettings: {
                    required: false,
                    showField: true,
                },
                sellerSettings: {
                    isMandatory: true,
                    negotiable: true,
                    showField: true,
                },
                standardFieldCategory: 'STANDARD',
            },
            DOCUMENT_LOGO: {
                label: 'Document logo',
                description: '',
                buyerSettings: {
                    required: false,
                    showField: true,
                },
                sellerSettings: {
                    isMandatory: true,
                    negotiable: true,
                    showField: true,
                },
                standardFieldCategory: 'STANDARD',
            },
            DOCUMENT_NAME: {
                label: 'Document name',
                description: '',
                buyerSettings: {
                    required: true,
                    showField: true,
                },
                sellerSettings: {
                    isMandatory: true,
                    negotiable: true,
                    showField: true,
                },
                standardFieldCategory: 'STANDARD',
            },
            DOCUMENT_VALIDITY_DATE: {
                label: 'Document banner',
                description: '',
                buyerSettings: {
                    required: false,
                    showField: true,
                },
                sellerSettings: {
                    isMandatory: true,
                    negotiable: true,
                    showField: true,
                },
                standardFieldCategory: 'STANDARD',
            },

            TEMPLATE: {
                label: 'Attachment',
                description: '',
                buyerSettings: {
                    required: true,
                    showField: true,
                },
                sellerSettings: {
                    isMandatory: true,
                    negotiable: true,
                    showField: true,
                },
                standardFieldCategory: 'STANDARD',
            },
        },
    },
    sections: {
        DOCUMENT_DETAIL: {} as IDocumentTemplateSectionDetails & {
            [customSection: string]: IDocumentTemplateSectionDetails;
        },
    },
    templateId: '',
    templateName: '',
};

export const convertTemplateConstraintsItemToCustomFIeldType: (
    fieldType: string
) => CustomFieldType = (fieldType: string) => {
    switch (fieldType) {
        case 'BOOLEAN':
            return CustomFieldType.BOOLEAN;
        case 'DATE':
            return CustomFieldType.DATE;
        case 'DATETIME':
            return CustomFieldType.DATETIME;
        case 'INTEGER':
            return CustomFieldType.INTEGER;
        case 'FLOAT':
            return CustomFieldType.FLOAT;
        case 'SHORTTEXT':
            return CustomFieldType.SHORTTEXT;
        case 'LONGTEXT':
            return CustomFieldType.LONGTEXT;
        case 'CHOICE':
            return CustomFieldType.CHOICE;
        case 'ATTACHMENT':
            return CustomFieldType.ATTACHMENT;
        default:
            return CustomFieldType.SHORTTEXT;
    }
};

export const getFieldTypeContraintsFromBackend = ({
    field_type,
    choices,
    max_limit,
    min_limit,
    choice_type,
}: {
    field_type: string;
    choices?: string[];
    max_limit?: number;
    min_limit?: number;
    choice_type?: string;
}): IFieldTypeConstraints => {
    switch (field_type) {
        case 'BOOLEAN':
            return {
                fieldType: CustomFieldType.BOOLEAN,
            };
        case 'DATE':
            return {
                fieldType: CustomFieldType.DATE,
            };
        case 'DATETIME':
            return {
                fieldType: CustomFieldType.DATETIME,
            };
        // case 'COLLECTION':
        //     return {
        //         fieldType: 'COLLECTION',
        //     };
        // case 'LOCATION':
        //     return {
        //         // fieldType: 'LOCATION',
        //     };
        case 'INTEGER':
            return {
                fieldType: CustomFieldType.INTEGER,
                maxLimit: max_limit,
                minLimit: min_limit,
            };
        case 'FLOAT':
            return {
                fieldType: CustomFieldType.FLOAT,
                maxLimit: max_limit,
                minLimit: min_limit,
            };
        case 'SHORTTEXT':
            return {
                fieldType: CustomFieldType.SHORTTEXT,
                maxLimit: max_limit,
                minLimit: min_limit,
            };
        case 'LONGTEXT':
            return {
                fieldType: CustomFieldType.LONGTEXT,
                maxLimit: max_limit,
                minLimit: min_limit,
            };
        case 'CHOICE':
            if (choice_type) {
                return {
                    fieldType: CustomFieldType.CHOICE,
                    choices: choices ?? [],
                    choiceType: choice_type as 'DROPDOWN' | 'MULTI_SELECT',
                };
            }
            return {
                fieldType: CustomFieldType.CHOICE,
                choices: choices ?? [],
                choiceType: 'DROPDOWN',
            };

        case 'ATTACHMENT': {
            return {
                fieldType: CustomFieldType.ATTACHMENT,
            };
        }
        case 'TEMPLATE': {
            return {
                fieldType: CustomFieldType.TEMPLATE,
            };
        }

        default:
            return {
                fieldType: CustomFieldType.SHORTTEXT,
                maxLimit: 15,
                minLimit: 0,
            };
    }
};
export const getFieldTypeContraintsFromBackendForDocumentTemplate = ({
    field_type,
    choices,
    max_limit,
    min_limit,
    choice_type,
}: {
    field_type: string;
    choices?: string[];
    max_limit?: number;
    min_limit?: number;
    choice_type?: string;
}): IFieldTypeConstraints => {
    switch (field_type) {
        case 'BOOLEAN':
            return {
                fieldType: CustomFieldType.BOOLEAN,
            };
        case 'DATE':
            return {
                fieldType: CustomFieldType.DATE,
            };
        case 'DATETIME':
            return {
                fieldType: CustomFieldType.DATETIME,
            };
        // case 'COLLECTION':
        //     return {
        //         fieldType: 'COLLECTION',
        //     };
        // case 'LOCATION':
        //     return {
        //         // fieldType: 'LOCATION',
        //     };
        case 'INTEGER':
            return {
                fieldType: CustomFieldType.INTEGER,
                maxLimit: max_limit,
                minLimit: min_limit,
            };
        case 'FLOAT':
            return {
                fieldType: CustomFieldType.FLOAT,
                maxLimit: max_limit,
                minLimit: min_limit,
            };
        case 'SHORTTEXT':
            return {
                fieldType: CustomFieldType.SHORTTEXT,
                maxLimit: 50,
                minLimit: min_limit,
            };
        case 'LONGTEXT':
            return {
                fieldType: CustomFieldType.LONGTEXT,
                maxLimit: 1000,
                minLimit: min_limit,
            };
        case 'CHOICE':
            if (choice_type) {
                return {
                    fieldType: CustomFieldType.CHOICE,
                    choices: choices ?? [],
                    choiceType: choice_type as 'DROPDOWN' | 'MULTI_SELECT',
                };
            }
            return {
                fieldType: CustomFieldType.CHOICE,
                choices: choices ?? [],
                choiceType: 'DROPDOWN',
            };

        case 'ATTACHMENT': {
            return {
                fieldType: CustomFieldType.ATTACHMENT,
            };
        }
        case 'TEMPLATE': {
            return {
                fieldType: CustomFieldType.TEMPLATE,
            };
        }

        default:
            return {
                fieldType: CustomFieldType.SHORTTEXT,
                maxLimit: 15,
                minLimit: 0,
            };
    }
};

export const { useGetDocumentTemplateByIdQuery } =
    documentApiSlice.injectEndpoints({
        endpoints: (build) => ({
            getDocumentTemplateById: build.query<
                IDocumentTemplate,
                { templateId: string; entityId: string }
            >({
                query: ({ templateId, entityId }) => ({
                    url: `/module_templates/${entityId}/${templateId}`,
                }),
                transformResponse: (
                    baseQueryReturnValue: IDocumentTemplateBackendResponse,
                    meta,
                    args
                ) => {
                    const response = cloneDeep(blankDocumentTemplate);

                    response.templateId = baseQueryReturnValue.template_id;
                    response.templateName = baseQueryReturnValue.name;

                    const templateSections =
                        baseQueryReturnValue.section_list.map((section) => {
                            return {
                                ...section,
                                section_items: section.section_items.map(
                                    (item: any) => {
                                        return {
                                            ...item,
                                            alternate_name:
                                                item.alternate_name.replace(
                                                    '_',
                                                    ''
                                                ),
                                            name: item.name.replace('_', ''),
                                        };
                                    }
                                ),
                            };
                        });
                    baseQueryReturnValue = {
                        ...baseQueryReturnValue,
                        section_list: templateSections,
                    };

                    const documentDetailsSection =
                        baseQueryReturnValue.section_list.find(
                            (section) => section.name === 'Document Details'
                        );

                    response.sections.DOCUMENT_DETAIL = {
                        fieldList: [],
                        label:
                            documentDetailsSection &&
                            documentDetailsSection.alternate_name
                                ? documentDetailsSection.alternate_name
                                : 'Document Details',
                    };

                    documentDetailsSection?.section_items?.forEach(
                        (sectionItem) => {
                            if (sectionItem.is_builtin_field) {
                                response.sections.DOCUMENT_DETAIL.fieldList.push(
                                    {
                                        fieldType: 'STANDARD',
                                        field: DocumentTemplateStandardFieldBackendNames[
                                            sectionItem.name
                                        ],
                                    }
                                );

                                response.fieldSettings.standardFields[
                                    DocumentTemplateStandardFieldBackendNames[
                                        sectionItem.name
                                    ]
                                ] = {
                                    label: sectionItem.alternate_name
                                        ? sectionItem.alternate_name
                                        : sectionItem.name,
                                    buyerSettings: {
                                        required: sectionItem.is_required,
                                        showField: !(
                                            sectionItem.additional_information
                                                .is_hidden ?? false
                                        ),
                                    },
                                    description: sectionItem.description,
                                    sellerSettings: {
                                        negotiable:
                                            !(
                                                sectionItem
                                                    .additional_information
                                                    .is_hidden ?? false
                                            ) &&
                                            (sectionItem.additional_information
                                                .is_visible ??
                                                true) &&
                                            (sectionItem.additional_information
                                                .is_negotiable ??
                                                true),
                                        showField:
                                            !(
                                                sectionItem
                                                    .additional_information
                                                    .is_hidden ?? false
                                            ) &&
                                            (sectionItem.additional_information
                                                .is_visible ??
                                                true),
                                        isMandatory: true,
                                    },
                                    standardFieldCategory: 'STANDARD',
                                };
                            } else {
                                response.sections.DOCUMENT_DETAIL.fieldList.push(
                                    {
                                        field: sectionItem.name,
                                        fieldType: 'CUSTOM',
                                    }
                                );
                                response.fieldSettings.customFields[
                                    sectionItem.name
                                ] = {
                                    label: sectionItem.alternate_name
                                        ? sectionItem.alternate_name
                                        : sectionItem.name,
                                    buyerSettings: {
                                        required: sectionItem.is_required,
                                        showField: !(
                                            sectionItem.additional_information
                                                .is_hidden ?? false
                                        ),
                                    },
                                    description: sectionItem.description,
                                    sellerSettings: {
                                        negotiable:
                                            !(
                                                sectionItem
                                                    .additional_information
                                                    .is_hidden ?? false
                                            ) &&
                                            (sectionItem.additional_information
                                                .is_visible ??
                                                true) &&
                                            (sectionItem.additional_information
                                                .is_negotiable ??
                                                true),
                                        showField:
                                            !(
                                                sectionItem
                                                    .additional_information
                                                    .is_hidden ?? false
                                            ) &&
                                            (sectionItem.additional_information
                                                .is_visible ??
                                                true),
                                        isMandatory: true,
                                    },
                                    type: sectionItem.constraints
                                        .field_type as CustomFieldType,
                                    constraints:
                                        getFieldTypeContraintsFromBackendForDocumentTemplate(
                                            {
                                                choices:
                                                    sectionItem.constraints
                                                        .choices ?? [],
                                                choice_type:
                                                    sectionItem.constraints
                                                        .choice_type,
                                                field_type:
                                                    sectionItem.constraints
                                                        .field_type,
                                                max_limit:
                                                    sectionItem.constraints
                                                        .max_limit,
                                                min_limit:
                                                    sectionItem.constraints
                                                        .min_limit,
                                            }
                                        ),
                                };
                            }
                        }
                    );

                    return response;
                },
            }),
        }),
    });

export const documentSellerApiSlice = baseApiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getSellerDocumentTabCount: builder.mutation<
            {
                tab_counts: {
                    all: number;
                };
            },
            {}
        >({
            query: () => ({
                url: '/dashboard/tab_counts/',
                method: 'POST',
                body: {
                    dashboard_view: 'document_seller',
                },
            }),
        }),
        getSellerDocumentData: builder.mutation<
            {
                documentList: IDocumentData[];
                pageDetails: IDocumentPageDetails;
                countDetails: IDocumentCount;
            },
            {
                page_number: number;
                items_per_page: number;
                sort_fields: { field: string; ascending: boolean }[];
                search_text: string;
                seller_entity_id: string;
                buyer_entity_id: string | null;
            }
        >({
            query: ({
                page_number,
                items_per_page,
                search_text,
                sort_fields,
                seller_entity_id,
                buyer_entity_id,
            }) => ({
                url: `/dashboard/`,
                method: 'POST',
                body: {
                    dashboard_view: 'document_seller',
                    tab: 'all',
                    query_data: {
                        seller_entity_id,
                        buyer_entity_id,
                    },
                    search_text,
                    page_number,
                    sort_fields,
                    items_per_page,
                },
            }),
            transformResponse: (
                baseQueryReturnValue: IFetchDocumentData,
                meta,
                arg
            ) => {
                let res: {
                    documentList: IDocumentData[];
                    pageDetails: IDocumentPageDetails;
                    countDetails: IDocumentCount;
                } = {
                    documentList: baseQueryReturnValue.data,
                    countDetails: baseQueryReturnValue.counts,
                    pageDetails: baseQueryReturnValue.metadata,
                };

                return res;
            },
        }),
    }),
});

export const {
    useGetSellerDocumentTabCountMutation,
    useGetSellerDocumentDataMutation,
} = documentSellerApiSlice;
