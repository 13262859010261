import { Box } from '@mui/material';
import { FWTooltip } from '../../Common/FWCustomTooltip';
import { FWInput } from '../../Common/FWInput';
import { useGetAdminSettings } from '../../Organizations/Admin/Hooks/AdminSettingsHook';
import { ASModuleSetting } from '../../Organizations/Admin/Interfaces/AdminSettingsInterface';
import { isPaymentItemAmountValid } from '../Helpers/PaymentsHelpers';
import {
    IPaymentListItemSummary,
    IPaymentsListContext,
    PaymentItemStatus,
    PaymentItemType,
} from '../Interfaces/PaymentsInterface';

const PaymentAmountCell = ({
    data: paymentItem,
    context,
}: {
    data: IPaymentListItemSummary;
    context: IPaymentsListContext;
}) => {
    const { handlePaymentItemAmountUpdate, entityUid } = context;

    const [overpaymentSettings] = useGetAdminSettings(
        entityUid,
        ASModuleSetting.PAYMENT_ALLOW_PREPAYMENT_MORE_THAN_PO
    );

    const [paymentsAllowBeforeQC] = useGetAdminSettings(
        entityUid,
        ASModuleSetting.PAYMENTS_ALLOW_BEFORE_QC
    );

    let allowed = true;

    if (!paymentsAllowBeforeQC.enabled && paymentItem.qcDone === false) {
        allowed = false;
    }

    const isPrepayOverpayment =
        paymentItem.paymentType === PaymentItemType.PREPAYMENT &&
        +paymentItem.amountToPay > paymentItem.amountPending;

    return (
        <div
            className="flex flex--aic"
            style={{
                width: '100%',
                paddingRight: '10px',
            }}
        >
            {isPrepayOverpayment && (
                <>
                    <FWTooltip
                        title={
                            overpaymentSettings.enabled
                                ? 'Exceeds PO prepayment amount'
                                : 'Cannot exceed PO prepayment amount'
                        }
                    >
                        <Box
                            color={
                                overpaymentSettings.enabled
                                    ? 'warning.main'
                                    : 'error.main'
                            }
                        >
                            <i className="bi bi-exclamation-circle" />
                        </Box>
                    </FWTooltip>
                    &nbsp;
                </>
            )}
            <FWInput
                value={paymentItem.amountToPay}
                allowOnly="DECIMAL_NUMBER"
                iconStart={paymentItem.currencySymbol}
                onChange={(event: any) => {
                    handlePaymentItemAmountUpdate(
                        event.target.value,
                        paymentItem.id
                    );
                }}
                error={
                    !isPaymentItemAmountValid(
                        paymentItem,
                        overpaymentSettings.enabled &&
                            paymentItem.paymentType ===
                                PaymentItemType.PREPAYMENT
                    )
                }
                disabled={
                    paymentItem.poOnHold ||
                    paymentItem.poTermination ||
                    paymentItem.itemOnHold ||
                    paymentItem.itemTermination ||
                    (paymentItem.status !== PaymentItemStatus.GOODS_RECEIVED &&
                        paymentItem.status !==
                            PaymentItemStatus.GOODS_PENDING &&
                        paymentItem.paymentType !==
                            PaymentItemType.PREPAYMENT) ||
                    paymentItem.amountPending === 0 ||
                    !allowed
                }
            />
        </div>
    );
};

export default PaymentAmountCell;
