import { Divider, Grid } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { FWTooltip } from '../../Common/FWCustomTooltip';
import { FWLink } from '../../Common/FWLink';
import FWPopover from '../../Common/FWPopover';
import { FWTypography } from '../../Common/FWTypograhy';
import { FWNumberFormatter } from '../../Common/Utils/CurrencyUtils';
import { calculateItemTotal } from '../../Components/Shared/totalCalculator';
import {
    PurchaseOrderStatus,
    PurchaseOrderDisplayStatusBuyer,
    PurchaseOrderDisplayStatusSeller,
    IPurchaseOrderItem,
} from '../Interfaces/PO.model';

export const getPurchaseOrderDisplayStatusBuyer = (
    po_backend_status: PurchaseOrderStatus,
    po_is_on_hold: boolean,
    po_termination_is_requested: boolean
): PurchaseOrderDisplayStatusBuyer => {
    if (po_backend_status === PurchaseOrderStatus.ONGOING) {
        if (po_termination_is_requested) {
            return PurchaseOrderDisplayStatusBuyer.TERMINATION_REQUESTED;
        }
        if (po_is_on_hold) {
            return PurchaseOrderDisplayStatusBuyer.ON_HOLD;
        }
    }
    switch (po_backend_status) {
        case PurchaseOrderStatus.APPROVAL_PENDING:
            return PurchaseOrderDisplayStatusBuyer.APPROVAL_PENDING;
        case PurchaseOrderStatus.ISSUED:
            return PurchaseOrderDisplayStatusBuyer.ISSUED;
        case PurchaseOrderStatus.ONGOING:
            return PurchaseOrderDisplayStatusBuyer.ONGOING;
        case PurchaseOrderStatus.COMPLETED:
            return PurchaseOrderDisplayStatusBuyer.COMPLETED;
        case PurchaseOrderStatus.RESCINDED:
            return PurchaseOrderDisplayStatusBuyer.RESCINDED;
        case PurchaseOrderStatus.REJECTED:
            return PurchaseOrderDisplayStatusBuyer.REJECTED;
        case PurchaseOrderStatus.DECLINED:
            return PurchaseOrderDisplayStatusBuyer.DECLINED;
        case PurchaseOrderStatus.TERMINATED:
            return PurchaseOrderDisplayStatusBuyer.TERMINATED;
        default:
            return po_backend_status;
    }
};

export const getPurchaseOrderDisplayStatusSeller = (
    po_backend_status: string,
    po_is_on_hold: boolean,
    po_termination_is_requested: boolean
): PurchaseOrderDisplayStatusSeller => {
    if (po_backend_status === PurchaseOrderStatus.ONGOING) {
        if (po_termination_is_requested) {
            return PurchaseOrderDisplayStatusSeller.TERMINATION_REQUESTED;
        }
        if (po_is_on_hold) {
            return PurchaseOrderDisplayStatusSeller.ON_HOLD;
        }
    }
    switch (po_backend_status) {
        case 'ISSUED':
            return PurchaseOrderDisplayStatusSeller.ISSUED;
        case 'ONGOING':
            return PurchaseOrderDisplayStatusSeller.ONGOING;
        case 'COMPLETED':
            return PurchaseOrderDisplayStatusSeller.COMPLETED;
        case 'RESCINDED':
            return PurchaseOrderDisplayStatusSeller.RESCINDED;
        case 'ON HOLD':
            return PurchaseOrderDisplayStatusSeller.ON_HOLD;
        case 'DECLINED':
            return PurchaseOrderDisplayStatusSeller.DECLINED;
        case 'TERMINATION REQUESTED':
            return PurchaseOrderDisplayStatusSeller.TERMINATION_REQUESTED;
        case 'TERMINATED':
            return PurchaseOrderDisplayStatusSeller.TERMINATED;
        default:
            return PurchaseOrderDisplayStatusSeller.UNKNOWN;
    }
};

export const POItemTotalAmountPopup = ({
    po_item,
    discount_perc,
    getItemTotalValue,
}: {
    po_item: IPurchaseOrderItem;
    discount_perc: number;
    getItemTotalValue: (
        poItem: IPurchaseOrderItem,
        discount_percentage: number
    ) => number;
}) => {
    const [anchorEl, setAnchorEl] = React.useState<any | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
        setAnchorEl(event.target);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'total-amt-popover' : undefined;

    const total_amt = calculateItemTotal(
        po_item.quantity_information.quantity,
        po_item.pricing_information.price,
        po_item.pricing_information.shipping_per_unit,
        po_item.pricing_information.additional_charges
    );

    const discount_amt = (discount_perc / 100) * total_amt;

    const discounted_total = getItemTotalValue(po_item, discount_perc);

    let total_taxes = 0;
    for (let tax of po_item.pricing_information.additional_charges)
        total_taxes += parseFloat(tax.charge_value);

    const item_subtotal = calculateItemTotal(
        po_item.quantity_information.quantity,
        po_item.pricing_information.price,
        '0',
        []
    );

    const tax_amt = item_subtotal * (total_taxes / 100);

    return (
        <>
            <FWLink onClick={handleClick}>
                <FWTypography width={160} textAlign="right">
                    {FWNumberFormatter(
                        discounted_total,
                        po_item.pricing_information.currency_code_abbreviation
                    )}
                </FWTypography>
            </FWLink>
            <FWPopover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Grid
                    width="350px"
                    container
                    rowGap={1}
                    columnSpacing={0.5}
                    margin="12px"
                >
                    <Grid item xs={6}>
                        <FWTypography color="text.secondary">
                            Subtotal
                        </FWTypography>
                    </Grid>
                    <Grid item xs={6}>
                        <FWTypography textAlign="right">
                            {FWNumberFormatter(
                                item_subtotal,
                                po_item.pricing_information
                                    .currency_code_abbreviation
                            )}
                        </FWTypography>
                    </Grid>
                    <Grid item xs={6}>
                        <div className="flex flex--aic">
                            <FWTypography color="text.secondary">
                                Shipping
                            </FWTypography>
                            <span style={{ width: '0.75rem' }}></span>
                            <FWTooltip title="Includes taxes on shipping">
                                <Box color="primary.main">
                                    <i className="bi bi-info-circle"></i>
                                </Box>
                            </FWTooltip>
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <FWTypography textAlign="right">
                            {FWNumberFormatter(
                                calculateItemTotal(
                                    po_item.quantity_information.quantity,
                                    '0',
                                    po_item.pricing_information
                                        .shipping_per_unit,
                                    []
                                ),
                                po_item.pricing_information
                                    .currency_code_abbreviation
                            )}
                        </FWTypography>
                    </Grid>
                    <Grid item xs={6}>
                        <FWTypography color="text.secondary">
                            Taxes
                        </FWTypography>
                    </Grid>
                    <Grid item xs={6}>
                        <FWTypography textAlign="right">
                            {FWNumberFormatter(
                                tax_amt,
                                po_item.pricing_information
                                    .currency_code_abbreviation
                            )}
                        </FWTypography>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={6}>
                        <FWTypography color="text.secondary">
                            Total before discount
                        </FWTypography>
                    </Grid>
                    <Grid item xs={6}>
                        <FWTypography textAlign="right">
                            {FWNumberFormatter(
                                total_amt,
                                po_item.pricing_information
                                    .currency_code_abbreviation
                            )}
                        </FWTypography>
                    </Grid>

                    <Grid item xs={6}>
                        <FWTypography color="text.secondary">
                            Discount
                        </FWTypography>
                    </Grid>
                    <Grid item xs={6}>
                        <FWTypography textAlign="right">
                            {FWNumberFormatter(
                                discount_amt,
                                po_item.pricing_information
                                    .currency_code_abbreviation
                            )}
                        </FWTypography>
                    </Grid>

                    <Grid item xs={12}>
                        <Divider />
                    </Grid>

                    <Grid item xs={6}>
                        <FWTypography color="text.secondary">
                            Total after discount
                        </FWTypography>
                    </Grid>
                    <Grid item xs={6}>
                        <FWTypography textAlign="right">
                            {FWNumberFormatter(
                                discounted_total,
                                po_item.pricing_information
                                    .currency_code_abbreviation
                            )}
                        </FWTypography>
                    </Grid>
                </Grid>
            </FWPopover>
        </>
    );
};
