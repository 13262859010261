import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ICounts {
    all: number;
    finished: number;
    ongoing: number;
}

export interface ICustomerPortalStore {
    counts: ICounts;
}

const initialState: ICustomerPortalStore = {
    counts: {
        all: 0,
        finished: 0,
        ongoing: 0,
    },
};

const CustomerPortalSlice = createSlice({
    name: 'customerCart',
    initialState,
    reducers: {
        setCounts: (state, action: PayloadAction<ICounts>) => {
            state.counts = action.payload;
        },
    },
});

export const { setCounts } = CustomerPortalSlice.actions;
export default CustomerPortalSlice.reducer;
