import { Autocomplete, AutocompleteProps, Paper, styled } from '@mui/material';

const StyledAutocomplete = styled(Autocomplete)({
    '& .MuiAutocomplete-inputRoot': {
        padding: '0px 12px',
        height: '2.5rem',
    },
    "overscrollBehavior":"contain"
    // limit items to 5
    // '&.add-item-search-option + .MuiAutocomplete-popper .MuiAutocomplete-listbox .MuiAutocomplete-option:nth-child(5) ~ .MuiAutocomplete-option':
    //     {
    //         display: 'none',
    //     },
});

export const AutocompleteStyledPaper = styled(Paper)({
    '&': {
        width: 'inherit',
        backgroundColor: 'white',
        boxShadow: '0px 5px 16px #00000024 !important',
    },
    '& .MuiAutocomplete-listbox': {
        // margin: '0px',
        // padding: '0px',
    },
});

export default function FWAutocomplete(
    props: AutocompleteProps<any, boolean, boolean, boolean>
) {
    /*
    Always USE MUI TEXTFIELD while using renderprops in this component
    */
    return (
        <StyledAutocomplete
            PaperComponent={(props) => <AutocompleteStyledPaper {...props} />}
            {...props}
        />
    );
}
