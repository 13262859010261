export enum QuoteApproverStatus {
    PENDING = 'PENDING',
    EDITING = 'EDITING',
    ISSUED = 'ISSUED',
    REWORK = 'REWORK',
    RESOLVED = 'RESOLVED',
    REJECTED = 'REJECTED',
    DELETED = 'DELETED',
    NOT_REQUIRED = 'NOT_REQUIRED',
}

export interface IQuoteApprover {
    requestor: string;
    requestor_username: string;
    requestor_name: string;
    requestor_notes: string | null;
    approver: string;
    approver_username: string;
    approver_name: string;
    approver_notes: string | null;
    status: QuoteApproverStatus;
}
