import {
    Box,
    FormHelperText,
    Grid,
    InputAdornment,
    TextField,
} from '@mui/material';
import { cloneDeep } from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { FWInput } from '../../Common/FWInput';
import { OpenDownIcon } from '../../Common/FWSelect';
import { FWTypography } from '../../Common/FWTypograhy';
import { IFileResourceType } from '../../Components/Shared/UploadFile';
import { AuthContext } from '../../Contexts/AuthContext';
import DisplaySavedStatus from '../../Events/Components/Shared/DisplaySavedStatus';
import { ILoading } from '../../Events/Interfaces/Buyer/SharedRFQ.model';
import { IAttachment } from '../../Models/RFQ.model';
import {
    IDocumentDetails,
    IDocumentDetailsErrors,
    INewDocument,
} from '../Constants/Document.model';
import {
    DocumentTemplateStandardFieldsEnum,
    IDocumentTemplate,
} from '../Constants/Interfaces/documentTemplate';
import {
    STANDARD_GRID_COL_GAP,
    STANDARD_GRID_ROW_GAP,
    STANDARD_GRID_SIZE,
    STANDARD_LOGO_BANNER_FILE_TYPES,
    STANDARD_LONGFIELD_GRID_SIZE,
} from '../Pages/DraftDocumentPage';
import DraftDocumentCustomFields, {
    DummyUploadComponent,
    FieldNameDescription,
    UploadedDocument,
} from './DraftDocumentCustomFields';

export interface IDraftDocumentDetails {
    templateDetails: IDocumentTemplate;
    updateDocumentDetails: (field: keyof INewDocument, value: any) => void;
    documentsPayload: INewDocument;
    errors: IDocumentDetailsErrors;
    documentDetails: IDocumentDetails;
    loadingState: ILoading;
    documentEssentials: {
        entityName: string;
        templateName: string;
    };
}

export default function DraftDocumentDetails({
    documentDetails,
    documentsPayload,
    errors,
    loadingState,
    templateDetails,
    documentEssentials,
    updateDocumentDetails,
}: IDraftDocumentDetails) {
    const { authData } = useContext(AuthContext);

    const [docAttachments, setDocAttachments] = useState<{
        DOCUMENT_LOGO: IAttachment[];
        DOCUMENT_BANNER: IAttachment[];
    }>({ DOCUMENT_LOGO: [], DOCUMENT_BANNER: [] });

    useEffect(() => {
        setDocAttachments((prev) => {
            let newPrev = cloneDeep(prev);

            newPrev.DOCUMENT_LOGO = documentDetails.document_logo;

            newPrev.DOCUMENT_BANNER = documentDetails.document_banner;

            return newPrev;
        });
    }, [documentDetails.document_banner, documentDetails.document_logo]);

    return (
        <Grid container marginX={4} gap={STANDARD_GRID_ROW_GAP}>
            <Grid
                container
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <Box>
                    <FWTypography variant="h4">Document details</FWTypography>
                </Box>

                <Box>
                    <DisplaySavedStatus
                        saved={loadingState.saved}
                        error={loadingState.error}
                        loading={loadingState.loading}
                        error_msg={loadingState.error_msg}
                    />
                </Box>
            </Grid>
            <Grid container gap={STANDARD_GRID_COL_GAP}>
                <Grid item xs={STANDARD_GRID_SIZE}>
                    <Grid
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '0.5rem',
                        }}
                    >
                        <FWTypography color="text.secondary">
                            {
                                templateDetails.fieldSettings.standardFields[
                                    DocumentTemplateStandardFieldsEnum
                                        .DOCUMENT_NAME
                                ].label
                            }
                        </FWTypography>
                    </Grid>

                    <FWInput
                        value={documentsPayload.document_name}
                        onChange={(e) => {
                            updateDocumentDetails(
                                'document_name',
                                e.target.value
                            );
                        }}
                        error={'document_name' in errors}
                        helper={{
                            text:
                                'document_name' in errors
                                    ? errors['document_name']!.message
                                    : '',
                        }}
                    />
                </Grid>
                {templateDetails.fieldSettings.standardFields[
                    DocumentTemplateStandardFieldsEnum.DOCUMENT_VALIDITY_DATE
                ].buyerSettings.showField && (
                    <Grid item xs={STANDARD_GRID_SIZE}>
                        <Grid
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '0.5rem',
                            }}
                        >
                            <FWTypography color="text.secondary">
                                {
                                    templateDetails.fieldSettings
                                        .standardFields[
                                        DocumentTemplateStandardFieldsEnum
                                            .DOCUMENT_VALIDITY_DATE
                                    ].label
                                }
                            </FWTypography>
                        </Grid>

                        <FWInput
                            value={
                                documentsPayload.document_validity_datetime ??
                                ''
                            }
                            onChange={(e) => {
                                updateDocumentDetails(
                                    'document_validity_datetime',
                                    e.target.value
                                );
                            }}
                            type="datetime-local"
                            error={'document_validity_datetime' in errors}
                            helper={{
                                text:
                                    'document_validity_datetime' in errors
                                        ? errors['document_validity_datetime']!
                                              .message
                                        : '',
                            }}
                        />
                    </Grid>
                )}
                <Grid item xs={STANDARD_GRID_SIZE}>
                    <Grid
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '0.5rem',
                        }}
                    >
                        <FWTypography color="text.secondary">
                            Buyer entity
                        </FWTypography>
                    </Grid>

                    <FWInput
                        value={documentEssentials.entityName}
                        disabled
                        InputProps={{
                            endAdornment: (
                                <InputAdornment
                                    position={'end'}
                                    sx={{
                                        m: '0 0.75rem',
                                    }}
                                >
                                    <OpenDownIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>

                <Grid item xs={STANDARD_GRID_SIZE}>
                    <Grid
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '0.5rem',
                        }}
                    >
                        <FWTypography color="text.secondary">
                            Creator
                        </FWTypography>
                    </Grid>

                    <FWInput value={authData.details?.name} disabled />
                </Grid>
            </Grid>
            <Grid container gap={STANDARD_GRID_COL_GAP}>
                {templateDetails.fieldSettings.standardFields[
                    DocumentTemplateStandardFieldsEnum.DOCUMENT_LOGO
                ].buyerSettings.showField && (
                    <Grid item xs={STANDARD_GRID_SIZE}>
                        <Grid
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '0.5rem',
                            }}
                        >
                            <FieldNameDescription
                                name={
                                    templateDetails.fieldSettings
                                        .standardFields[
                                        DocumentTemplateStandardFieldsEnum
                                            .DOCUMENT_LOGO
                                    ].label
                                }
                                description={
                                    templateDetails.fieldSettings
                                        .standardFields[
                                        DocumentTemplateStandardFieldsEnum
                                            .DOCUMENT_LOGO
                                    ].description
                                }
                                required={
                                    templateDetails.fieldSettings
                                        .standardFields[
                                        DocumentTemplateStandardFieldsEnum
                                            .DOCUMENT_LOGO
                                    ].buyerSettings.required
                                }
                            />

                            <DummyUploadComponent
                                onFileUpload={(files: IAttachment[]) => {
                                    setDocAttachments((prev) => {
                                        let newPrev = cloneDeep(prev);

                                        newPrev.DOCUMENT_LOGO.push(...files);

                                        const newAttachmentIds =
                                            newPrev.DOCUMENT_LOGO.map(
                                                (logo) => logo.attachment_id
                                            );

                                        updateDocumentDetails(
                                            'document_logo_id',
                                            newAttachmentIds
                                        );

                                        return newPrev;
                                    });
                                }}
                                attachments={[]}
                                allowedFileTypes={
                                    STANDARD_LOGO_BANNER_FILE_TYPES
                                }
                                removeAttachment={async (index: number) => {
                                    setDocAttachments((prev) => {
                                        let newPrev = cloneDeep(prev);

                                        newPrev.DOCUMENT_LOGO.splice(index, 1);

                                        const newAttachmentIds =
                                            newPrev.DOCUMENT_LOGO.map(
                                                (logo) => logo.attachment_id
                                            );

                                        updateDocumentDetails(
                                            'document_logo_id',
                                            newAttachmentIds
                                        );

                                        return newPrev;
                                    });
                                }}
                                resourceType={IFileResourceType.DOCUMENT_LOGO}
                                tooltipText="Upload document logo"
                                xs={STANDARD_GRID_SIZE / 2}
                                disabled={
                                    docAttachments.DOCUMENT_LOGO.length >= 1
                                }
                                multiple={false}
                            />
                        </Grid>

                        <Grid container gap={STANDARD_GRID_COL_GAP}>
                            {docAttachments.DOCUMENT_LOGO.map(
                                (attachment, index) => {
                                    return (
                                        <UploadedDocument
                                            fileName={attachment.file_name}
                                            resourceType={
                                                IFileResourceType.DOCUMENT_LOGO
                                            }
                                            deleteTooltipText="Delete logo"
                                            downloadTooltipText="Download logo"
                                            allowDelete={true}
                                            onDeleteAttachment={() =>
                                                setDocAttachments((prev) => {
                                                    let newPrev =
                                                        cloneDeep(prev);

                                                    newPrev.DOCUMENT_LOGO.splice(
                                                        index,
                                                        1
                                                    );

                                                    const newAttachmentIds =
                                                        newPrev.DOCUMENT_LOGO.map(
                                                            (logo) =>
                                                                logo.attachment_id
                                                        );

                                                    updateDocumentDetails(
                                                        'document_logo_id',
                                                        newAttachmentIds
                                                    );

                                                    return newPrev;
                                                })
                                            }
                                            attachment={attachment}
                                            xs={12}
                                        />
                                    );
                                }
                            )}
                            <FormHelperText
                                sx={{
                                    color: 'error.main',
                                    fontSize: '0.8rem',
                                }}
                            >
                                {'document_logo_id' in errors
                                    ? errors['document_logo_id']!.message
                                    : ''}
                            </FormHelperText>
                        </Grid>
                    </Grid>
                )}
                {templateDetails.fieldSettings.standardFields[
                    DocumentTemplateStandardFieldsEnum.DOCUMENT_BANNER
                ].buyerSettings.showField && (
                    <Grid item xs={STANDARD_GRID_SIZE}>
                        <Grid
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '0.5rem',
                            }}
                        >
                            <FieldNameDescription
                                name={
                                    templateDetails.fieldSettings
                                        .standardFields[
                                        DocumentTemplateStandardFieldsEnum
                                            .DOCUMENT_BANNER
                                    ].label
                                }
                                description={
                                    templateDetails.fieldSettings
                                        .standardFields[
                                        DocumentTemplateStandardFieldsEnum
                                            .DOCUMENT_BANNER
                                    ].description
                                }
                                required={
                                    templateDetails.fieldSettings
                                        .standardFields[
                                        DocumentTemplateStandardFieldsEnum
                                            .DOCUMENT_BANNER
                                    ].buyerSettings.required
                                }
                            />

                            <DummyUploadComponent
                                onFileUpload={(files: IAttachment[]) => {
                                    setDocAttachments((prev) => {
                                        let newPrev = cloneDeep(prev);

                                        newPrev.DOCUMENT_BANNER.push(...files);

                                        const newAttachmentIds =
                                            newPrev.DOCUMENT_BANNER.map(
                                                (logo) => logo.attachment_id
                                            );

                                        updateDocumentDetails(
                                            'document_banner_id',
                                            newAttachmentIds
                                        );

                                        return newPrev;
                                    });
                                }}
                                allowedFileTypes={
                                    STANDARD_LOGO_BANNER_FILE_TYPES
                                }
                                attachments={[]}
                                removeAttachment={async (index: number) => {
                                    setDocAttachments((prev) => {
                                        let newPrev = cloneDeep(prev);

                                        newPrev.DOCUMENT_BANNER.splice(
                                            index,
                                            1
                                        );

                                        const newAttachmentIds =
                                            newPrev.DOCUMENT_BANNER.map(
                                                (logo) => logo.attachment_id
                                            );

                                        updateDocumentDetails(
                                            'document_banner_id',
                                            newAttachmentIds
                                        );

                                        return newPrev;
                                    });
                                }}
                                resourceType={IFileResourceType.DOCUMENT_BANNER}
                                tooltipText="Upload document banner"
                                xs={STANDARD_GRID_SIZE / 2}
                                disabled={
                                    docAttachments.DOCUMENT_BANNER.length >= 1
                                }
                                multiple={false}
                            />
                        </Grid>

                        <Grid container gap={STANDARD_GRID_COL_GAP}>
                            {docAttachments.DOCUMENT_BANNER.map(
                                (attachment, index) => {
                                    return (
                                        <UploadedDocument
                                            fileName={attachment.file_name}
                                            resourceType={
                                                IFileResourceType.DOCUMENT_BANNER
                                            }
                                            deleteTooltipText="Delete banner"
                                            downloadTooltipText="Download banner"
                                            allowDelete={true}
                                            onDeleteAttachment={() =>
                                                setDocAttachments((prev) => {
                                                    let newPrev =
                                                        cloneDeep(prev);

                                                    newPrev.DOCUMENT_BANNER.splice(
                                                        index,
                                                        1
                                                    );

                                                    const newAttachmentIds =
                                                        newPrev.DOCUMENT_BANNER.map(
                                                            (logo) =>
                                                                logo.attachment_id
                                                        );

                                                    updateDocumentDetails(
                                                        'document_banner_id',
                                                        newAttachmentIds
                                                    );

                                                    return newPrev;
                                                })
                                            }
                                            attachment={attachment}
                                            xs={12}
                                        />
                                    );
                                }
                            )}
                        </Grid>
                        <FormHelperText
                            sx={{
                                color: 'error.main',
                                fontSize: '0.8rem',
                            }}
                        >
                            {'document_banner_id' in errors
                                ? errors['document_banner_id']!.message
                                : ''}
                        </FormHelperText>
                    </Grid>
                )}
            </Grid>

            <Grid container gap={STANDARD_GRID_COL_GAP}>
                <Grid item xs={STANDARD_LONGFIELD_GRID_SIZE}>
                    <FieldNameDescription
                        name={
                            templateDetails.fieldSettings.standardFields[
                                DocumentTemplateStandardFieldsEnum
                                    .DOCUMENT_DESCRIPTION
                            ].label
                        }
                        description={
                            templateDetails.fieldSettings.standardFields[
                                DocumentTemplateStandardFieldsEnum
                                    .DOCUMENT_DESCRIPTION
                            ].description
                        }
                        required={
                            templateDetails.fieldSettings.standardFields[
                                DocumentTemplateStandardFieldsEnum
                                    .DOCUMENT_DESCRIPTION
                            ].buyerSettings.required
                        }
                    />

                    <TextField
                        multiline
                        maxRows={10}
                        value={documentsPayload.document_description}
                        error={'document_description' in errors}
                        helperText={
                            'document_description' in errors
                                ? errors['document_description']!.message
                                : ''
                        }
                        inputProps={{}}
                        onChange={(e) => {
                            if (e.target.value?.length <= 1000) {
                                updateDocumentDetails(
                                    'document_description',
                                    e.target.value
                                );
                            } else if (e.target.value?.length > 1000) {
                                updateDocumentDetails(
                                    'document_description',
                                    e.target.value?.slice(0, 1000)
                                );
                            }
                        }}
                        fullWidth
                        size="small"
                    />
                </Grid>
            </Grid>

            <Grid container gap={STANDARD_GRID_COL_GAP}>
                <DraftDocumentCustomFields
                    sectionName={templateDetails.sections.DOCUMENT_DETAIL.label}
                    documentsPayload={documentsPayload}
                    standardGridSize={STANDARD_GRID_SIZE}
                    customGridSize={{
                        LONGTEXT: STANDARD_LONGFIELD_GRID_SIZE,
                    }}
                    pageMode="EDIT"
                    documentDetails={documentDetails}
                    errors={errors}
                    templateDetails={templateDetails}
                    updateDocumentDetails={updateDocumentDetails}
                />
            </Grid>
        </Grid>
    );
}
