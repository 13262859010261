import {
    BackendDeliverablePaymentTerms,
    BackendInvoicePaymentTerms,
} from '../../Models/RFQ.model';
import {
    BackendInvoiceItemPaymentTerms,
    BackendPaymentType,
    BackendPoItemsPaymentTerms,
    IPaymentTerms,
    IPaymentTermsAllocation,
    PaymentTermsAllocationDeliverable,
    PaymentTermsAllocationFixedDate,
    PaymentTermsValidation,
    StandardPaymentReferenceDateEnum,
} from '../Interfaces/PaymentTermsInterface';
import { PeriodEnum, convertPeriodToDays } from '../Interfaces/PeriodInterface';

export const DELIVERABLE_LENGTH_LIMIT = 50;

const getInitPaymentTermsValidation = ({
    numAllocationRows,
}: {
    numAllocationRows: number;
}): PaymentTermsValidation => {
    const initValidationObj: PaymentTermsValidation = {
        overallErrors: {
            prepaymentBlank: false,
            prepaymentAbove100: false,
            allocationNot100: false,
            termsBlank: false,
            periodBlank: false,
            appliedFromBlank: false,
            appliedFromInvalid: false,
            appliedFromTooLong: false,
        },
        rowErrors: [],
        errorExists: false,
    };
    for (let i = 0; i < numAllocationRows; i++) {
        initValidationObj.rowErrors.push({
            allocationBlank: false,
            allocationOver100: false,
            termsBlank: false,
            periodBlank: false,
            deliverableBlank: false,
            deliverableTooLong: false,
            deliverableInvalid: false,
            dateBlank: false,
            datePast: false,
        });
    }
    return initValidationObj;
};

const getPrepaymentError = ({
    paymentTerms,
    prepaymentAllowed,
}: {
    paymentTerms: IPaymentTerms;
    prepaymentAllowed: boolean;
}): 'prepaymentBlank' | 'prepaymentAbove100' | null => {
    if (prepaymentAllowed) {
        // if (paymentTerms.prepaymentPercentage === '') {
        //     return 'prepaymentBlank';
        // }
        if (+paymentTerms.prepaymentPercentage > 100) {
            return 'prepaymentAbove100';
        }
    }

    return null;
};

export const paymentTermsAreSameOrBetter = ({
    inputPaymentTerms,
    compareToPaymentTerms,
    flagPrepaymentDifference,
}: {
    inputPaymentTerms: IPaymentTerms;
    compareToPaymentTerms: IPaymentTerms;
    flagPrepaymentDifference: boolean;
}): boolean => {
    if (inputPaymentTerms.paymentType !== compareToPaymentTerms.paymentType) {
        return false;
    }
    // PREPAYMENT
    if (
        +inputPaymentTerms.prepaymentPercentage >= 100 &&
        +compareToPaymentTerms.prepaymentPercentage >= 100
    ) {
        return true;
    } else if (
        +inputPaymentTerms.prepaymentPercentage >= 100 ||
        +compareToPaymentTerms.prepaymentPercentage >= 100
    ) {
        return false;
    } else if (
        flagPrepaymentDifference &&
        +inputPaymentTerms.prepaymentPercentage >
            +compareToPaymentTerms.prepaymentPercentage
    ) {
        return false;
    }

    if (
        inputPaymentTerms.paymentType === 'invoiceItemValues' &&
        compareToPaymentTerms.paymentType === 'invoiceItemValues'
    ) {
        if (
            !inputPaymentTerms.appliedFrom ||
            !compareToPaymentTerms.appliedFrom
        ) {
            return false;
        }
        if (
            inputPaymentTerms.appliedFrom.referenceDateLabel
                .toLowerCase()
                .trim() !==
            compareToPaymentTerms.appliedFrom.referenceDateLabel
                .toLowerCase()
                .trim()
        ) {
            return false;
        }

        if (!inputPaymentTerms.period || !compareToPaymentTerms.period) {
            return false;
        }

        const termsInDays = convertPeriodToDays({
            period: inputPaymentTerms.period,
            value: +inputPaymentTerms.terms,
        });

        const compareTermsInDays = convertPeriodToDays({
            period: compareToPaymentTerms.period,
            value: +compareToPaymentTerms.terms,
        });

        if (termsInDays > compareTermsInDays) {
            return false;
        }
        return true;
    } else if (
        inputPaymentTerms.paymentType === 'poItemPercentages' &&
        compareToPaymentTerms.paymentType === 'poItemPercentages'
    ) {
        const sortedList1 = allocationListSortedAndFormattedForComparisons(
            inputPaymentTerms.list
        );
        const sortedList2 = allocationListSortedAndFormattedForComparisons(
            compareToPaymentTerms.list
        );
        if (JSON.stringify(sortedList1) !== JSON.stringify(sortedList2)) {
            return false;
        }
    }
    return true;
};

const allocationListSortedAndFormattedForComparisons = (
    list: IPaymentTermsAllocation[]
) => {
    const fixedDateRows: PaymentTermsAllocationFixedDate[] = [];
    const deliverableRows: PaymentTermsAllocationDeliverable[] = [];

    list.forEach((row) => {
        if (row.milestoneType === 'FIXED_DATE') {
            fixedDateRows.push({
                milestoneType: 'FIXED_DATE',
                paymentDate: row.paymentDate,
                poItemPercentage: (+row.poItemPercentage).toFixed(2),
            });
        } else {
            deliverableRows.push({
                milestoneType: 'DELIVERABLE',
                deliverableName: row.deliverableName.toLowerCase().trim(),
                period: row.period,
                terms: (+row.terms).toString(),
                poItemPercentage: (+row.poItemPercentage).toFixed(2),
            });
        }
    });

    // Sort fixed date rows by date and then allocation %
    fixedDateRows.sort((a, b) => {
        if (a.paymentDate === b.paymentDate) {
            return +a.poItemPercentage - +b.poItemPercentage;
        } else {
            return (
                new Date(a.paymentDate).getTime() -
                new Date(b.paymentDate).getTime()
            );
        }
    });

    // sort deliverable rows by deliverableName, then %, then terms, then period

    deliverableRows.sort((a, b) => {
        return (
            a.deliverableName.localeCompare(b.deliverableName) ||
            +a.poItemPercentage - +b.poItemPercentage ||
            +a.terms - +b.terms ||
            a.period.localeCompare(b.period)
        );
    });
    const sortedList = [...fixedDateRows, ...deliverableRows];
    return sortedList;
};

export const validatePaymentTerms = ({
    paymentTerms,
    prepaymentAllowed,
}: {
    paymentTerms: IPaymentTerms;
    prepaymentAllowed: boolean;
}): PaymentTermsValidation => {
    let hasError = false;
    switch (paymentTerms.paymentType) {
        case 'invoiceItemValues':
            const validationResInvoiceItem: PaymentTermsValidation =
                getInitPaymentTermsValidation({ numAllocationRows: 0 });
            if (paymentTerms.paymentType === 'invoiceItemValues') {
                const prepaymentError = getPrepaymentError({
                    paymentTerms,
                    prepaymentAllowed,
                });
                if (prepaymentError) {
                    validationResInvoiceItem.overallErrors[prepaymentError] =
                        true;
                    hasError = true;
                }
                if (+paymentTerms.prepaymentPercentage < 100) {
                    if (paymentTerms.terms === '') {
                        validationResInvoiceItem.overallErrors.termsBlank =
                            true;
                        hasError = true;
                    }
                    if (paymentTerms.period === '') {
                        validationResInvoiceItem.overallErrors.periodBlank =
                            true;
                        hasError = true;
                    }
                    if (paymentTerms.appliedFrom === '') {
                        validationResInvoiceItem.overallErrors.appliedFromBlank =
                            true;
                        hasError = true;
                    } else {
                        if (!paymentTerms.appliedFrom.referenceDateLabel) {
                            validationResInvoiceItem.overallErrors.appliedFromBlank =
                                true;
                            hasError = true;
                        } else if (
                            paymentTerms.appliedFrom.referenceDateLabel.length >
                            DELIVERABLE_LENGTH_LIMIT
                        ) {
                            validationResInvoiceItem.overallErrors.appliedFromTooLong =
                                true;
                            hasError = true;
                        }
                    }
                }
            }
            validationResInvoiceItem.errorExists = hasError;
            return validationResInvoiceItem;
        case 'poItemPercentages':
            const validationResPoItem: PaymentTermsValidation =
                getInitPaymentTermsValidation({
                    numAllocationRows: paymentTerms.list.length,
                });
            if (paymentTerms.paymentType === 'poItemPercentages') {
                const prepaymentError = getPrepaymentError({
                    paymentTerms,
                    prepaymentAllowed,
                });
                if (prepaymentError) {
                    validationResPoItem.overallErrors[prepaymentError] = true;
                    hasError = true;
                }
                let allocationSum = 0;
                if (+paymentTerms.prepaymentPercentage < 100) {
                    paymentTerms.list.forEach((row, index) => {
                        allocationSum += +row.poItemPercentage;
                        if (
                            row.poItemPercentage === '' ||
                            +row.poItemPercentage <= 0
                        ) {
                            validationResPoItem.rowErrors[
                                index
                            ].allocationBlank = true;
                            hasError = true;
                        }
                        if (+row.poItemPercentage > 100) {
                            validationResPoItem.rowErrors[
                                index
                            ].allocationOver100 = true;
                            hasError = true;
                        }
                        switch (row.milestoneType) {
                            case 'DELIVERABLE':
                                if (row.terms === '') {
                                    validationResPoItem.rowErrors[
                                        index
                                    ].termsBlank = true;
                                    hasError = true;
                                }
                                if (row.period === '') {
                                    validationResPoItem.rowErrors[
                                        index
                                    ].periodBlank = true;
                                    hasError = true;
                                }
                                if (row.deliverableName === '') {
                                    validationResPoItem.rowErrors[
                                        index
                                    ].deliverableBlank = true;
                                    hasError = true;
                                } else {
                                    if (
                                        row.deliverableName.length >
                                        DELIVERABLE_LENGTH_LIMIT
                                    ) {
                                        validationResPoItem.rowErrors[
                                            index
                                        ].deliverableTooLong = true;
                                        hasError = true;
                                    }
                                }
                                break;
                            case 'FIXED_DATE':
                                if (!row.paymentDate) {
                                    validationResPoItem.rowErrors[
                                        index
                                    ].dateBlank = true;
                                    hasError = true;
                                } else {
                                    if (
                                        new Date(row.paymentDate) < new Date()
                                    ) {
                                        validationResPoItem.rowErrors[
                                            index
                                        ].datePast = true;
                                        hasError = true;
                                    }
                                }
                                break;
                        }
                    });
                }
                if (
                    allocationSum + +paymentTerms.prepaymentPercentage !==
                    100
                ) {
                    validationResPoItem.overallErrors.allocationNot100 = true;
                    hasError = true;
                }
            }
            validationResPoItem.errorExists = hasError;
            return validationResPoItem;
    }
};

export const transformPaymentTermsFromBackendToFrontend = ({
    prepayment_percentage,
    payment_terms,
    deliverables_payment_terms,
}: {
    prepayment_percentage: string | number | null;
    payment_terms: BackendInvoicePaymentTerms;
    deliverables_payment_terms: BackendDeliverablePaymentTerms;
}): IPaymentTerms => {
    if (payment_terms) {
        return {
            paymentType: 'invoiceItemValues',
            prepaymentPercentage:
                prepayment_percentage === null ||
                prepayment_percentage === undefined ||
                prepayment_percentage === ''
                    ? ''
                    : (+prepayment_percentage).toFixed(2),
            terms:
                payment_terms.term === null || payment_terms.term === undefined
                    ? ''
                    : payment_terms.term.toString(),
            period:
                payment_terms.period && payment_terms.period in PeriodEnum
                    ? (payment_terms.period as PeriodEnum)
                    : '',
            appliedFrom: payment_terms.applied_from
                ? payment_terms.applied_from in StandardPaymentReferenceDateEnum
                    ? {
                          referenceDateCategory: 'STANDARD',
                          referenceDateLabel:
                              payment_terms.applied_from as StandardPaymentReferenceDateEnum,
                      }
                    : {
                          referenceDateCategory: 'CUSTOM',
                          referenceDateLabel: payment_terms.applied_from,
                      }
                : {
                      referenceDateCategory: 'STANDARD',
                      referenceDateLabel: '',
                  },
        };
    }
    if (deliverables_payment_terms) {
        return {
            paymentType: 'poItemPercentages',
            prepaymentPercentage:
                prepayment_percentage === null ||
                prepayment_percentage === undefined ||
                prepayment_percentage === ''
                    ? ''
                    : (+prepayment_percentage).toFixed(2),
            list: deliverables_payment_terms.map(
                (row): IPaymentTermsAllocation => {
                    if (row.applied_from_fixed_date) {
                        return {
                            milestoneType: 'FIXED_DATE',
                            poItemPercentage:
                                row.allocation_percentage === null ||
                                row.allocation_percentage === undefined
                                    ? ''
                                    : row.allocation_percentage.toString(),
                            paymentDate: row.applied_from_fixed_date,
                        };
                    } else {
                        return {
                            milestoneType: 'DELIVERABLE',
                            poItemPercentage:
                                row.allocation_percentage === null ||
                                row.allocation_percentage === undefined
                                    ? ''
                                    : row.allocation_percentage.toString(),
                            deliverableName: row.applied_from_milestone ?? '',
                            period:
                                row.period && row.period in PeriodEnum
                                    ? (row.period as PeriodEnum)
                                    : '',
                            terms:
                                row.term === null || row.term === undefined
                                    ? ''
                                    : row.term.toString(),
                        };
                    }
                }
            ),
        };
    }
    return blankPaymentTerms;
};

export const transformPaymentTermsFromFrontendToBackend = ({
    paymentTerms,
}: {
    paymentTerms: IPaymentTerms | null;
}) => {
    if (!paymentTerms) {
        return {
            payment_type: null,
            prepayment_percentage: null,
            payment_terms: null,
            deliverables_payment_terms: null,
        };
    }
    let payment_type: BackendPaymentType =
        paymentTerms.paymentType === 'invoiceItemValues'
            ? 'PER_INVOICE_ITEM'
            : 'PER_DELIVERABLE';

    let prepayment_percentage: number | null = paymentTerms.prepaymentPercentage
        ? +paymentTerms.prepaymentPercentage
        : 0;
    let payment_terms: BackendInvoiceItemPaymentTerms | null = null;
    let deliverables_payment_terms: BackendPoItemsPaymentTerms[] | null = null;

    switch (paymentTerms.paymentType) {
        case 'invoiceItemValues':
            payment_terms = {
                term: +paymentTerms.terms,
                period: paymentTerms.period
                    ? paymentTerms.period
                    : PeriodEnum.DAYS,
                applied_from: paymentTerms.appliedFrom
                    ? paymentTerms.appliedFrom.referenceDateLabel
                    : StandardPaymentReferenceDateEnum.INVOICE_DATE,
            };
            break;
        case 'poItemPercentages':
            deliverables_payment_terms = [];
            paymentTerms.list.forEach((row) => {
                switch (row.milestoneType) {
                    case 'DELIVERABLE':
                        if (
                            +row.poItemPercentage &&
                            row.terms &&
                            row.period &&
                            row.deliverableName
                        ) {
                            deliverables_payment_terms!.push({
                                allocation_percentage: +row.poItemPercentage,
                                term: +row.terms,
                                period: row.period,
                                applied_from_milestone: row.deliverableName,
                                applied_from_fixed_date: null,
                            });
                        }
                        break;
                    case 'FIXED_DATE':
                        if (+row.poItemPercentage && row.paymentDate) {
                            deliverables_payment_terms!.push({
                                allocation_percentage: +row.poItemPercentage,
                                applied_from_fixed_date: row.paymentDate,
                                term: 0,
                                period: PeriodEnum.DAYS,
                                applied_from_milestone: null,
                            });
                        }
                        break;
                }
            });
            break;
    }

    return {
        payment_type,
        prepayment_percentage,
        payment_terms,
        deliverables_payment_terms,
    };
};

const blankPaymentTerms: IPaymentTerms = {
    paymentType: 'invoiceItemValues',
    terms: '',
    period: '',
    prepaymentPercentage: '',
    appliedFrom: '',
};
