import { debounce } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { IMeasurementUnit } from '../../Interfaces/UOMInterface';
import { useGetUOMDataMutation } from '../../Services/UOM.service';

export enum StatusFilterEnum {
    ACTIVE = 'ACTIVE',
    DISABLED = 'DISABLED',
    ALL = 'ALL',
}

export const useMeasurementView = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [searchText, setSearchText] = useState('');
    const [sortFields, setSortFields] = useState<
        {
            field: string;
            ascending: boolean;
        }[]
    >([]);

    const [measurementStatus, setMeasurementStatus] =
        useState<StatusFilterEnum>(StatusFilterEnum.ALL);

    const [totalPages, setTotalPages] = useState<number>(0);
    const [rowData, setRowData] = useState<IMeasurementUnit[]>([]);

    const [refetchUOMData, { data: UOMDetails }] = useGetUOMDataMutation({
        fixedCacheKey: 'fetchUOMData',
    });

    useEffect(() => {
        if (UOMDetails !== undefined) {
            setRowData(UOMDetails.data);
            setTotalPages(UOMDetails.metadata.total_pages);
        }
    }, [UOMDetails]);

    // const fetchMeasurements = useCallback(
    //     async ({
    //         currentPage,
    //         searchText,
    //         sortFields,
    //     }: {
    //         currentPage: number;
    //         searchText: string;
    //         sortFields: {
    //             field: string;
    //             ascending: boolean;
    //         }[];
    //     }) => {
    //
    //         try {
    //             refetchUOMData({
    //                 items_per_page: 10,
    //                 page_number: currentPage,
    //                 search_text: searchText,
    //                 sort_fields: sortFields,
    //             });
    //             //
    //             // const resp = await fetchMeasurementViewListApi({
    //             //     pageNumber: currentPage,
    //             //     searchText,
    //             //     sortFields,
    //             // });

    //             if (UOMDetails && 'data' in UOMDetails) {
    //                 setTotalPages(UOMDetails.metadata.total_pages);
    //                 setCurrentPage(UOMDetails.metadata.current_page);
    //             }
    //         } catch (error) {}
    //     },
    //     [UOMDetails, refetchUOMData]
    // );

    useEffect(() => {
        try {
            refetchUOMData({
                items_per_page: 10,
                page_number: 1,
                search_text: '',
                query_data: {
                    search: '',
                    measurement_status: StatusFilterEnum.ALL,
                },
                sort_fields: [],
            });
        } catch (e) {}
    }, [refetchUOMData]);

    const handlePageChange = (pageNumber: number) => {
        setCurrentPage(pageNumber);
        try {
            refetchUOMData({
                items_per_page: 10,
                page_number: pageNumber,
                search_text: '',
                query_data: {
                    search: searchText,
                    measurement_status: measurementStatus,
                },
                sort_fields: sortFields,
            });
        } catch (e) {}
    };

    const handleSortChange: (
        sortFields: {
            field: string;
            ascending: boolean;
        }[]
    ) => void = (sortFields) => {
        setSortFields(sortFields);
        try {
            refetchUOMData({
                items_per_page: 10,
                page_number: currentPage,
                search_text: '',
                query_data: {
                    search: searchText,
                    measurement_status: measurementStatus,
                },
                sort_fields: sortFields,
            });
        } catch (e) {}
        // fetchMeasurements({
        //     currentPage,
        //     searchText,
        //     sortFields,
        // });
    };

    const handleStatusChange = (status: StatusFilterEnum) => {
        setMeasurementStatus(status);
        setCurrentPage(1);

        try {
            refetchUOMData({
                items_per_page: 10,
                page_number: 1,
                search_text: '',
                query_data: {
                    search: searchText,
                    measurement_status: status,
                },
                sort_fields: sortFields,
            });
        } catch (e) {}
    };

    const debouncedSearch = useMemo(
        () =>
            debounce((searchText: string) => {
                try {
                    refetchUOMData({
                        items_per_page: 10,
                        page_number: currentPage,
                        search_text: '',
                        sort_fields: sortFields,
                        query_data: {
                            search: searchText,
                            measurement_status: measurementStatus,
                        },
                    });
                } catch (e) {}
            }, 1000),
        [currentPage, measurementStatus, refetchUOMData, sortFields]
    );

    // not used
    const handleTextChange = (searchText: string) => {
        setSearchText(searchText);
        debouncedSearch(searchText);
    };

    return {
        rowData,
        sortFields,
        currentPage,
        totalPages,
        searchText,
        handlePageChange,
        handleSortChange,
        handleTextChange,
        handleStatusChange,
        debouncedSearch,
    };
};
