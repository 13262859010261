import React, { Suspense, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
    Redirect,
    Route,
    Switch,
    useHistory,
    useParams,
} from 'react-router-dom';
import { toast } from 'react-toastify';
import LazyFallback from '../Components/Shared/LazyFallBack';
import NotFound404 from '../Components/Shared/NotFound404';
import useQuery from '../Events/Components/Shared/QueryHook';
import { FeaturesActions } from '../FeatureAccess/Reducers/AvailableFeaturesReducer';
import { fetchAvailableFeatures } from '../FeatureAccess/Services/FeatureAccess.service';
import { availableFeatures } from '../FeatureAccess/Slices/AvailableFeaturesSlice';
import QuickInviteUserPopup from '../Global/Containers/QuickInviteUserPopup';
import VerificationStatusContainer from '../Global/Containers/VerificationStatusContainer';
import WelcomePopup from '../Global/Containers/WelcomePopup';
import {
    IAuthContext,
    PermissionModule,
    PermissionPortal,
    PermissionType,
} from '../Models/AuthContext.model';
import {
    IUserDetails,
    IUserPermission,
    InvitationType,
} from '../Models/UserDetails.model';
import OnboardingPopup from '../Onboarding/Components/OnboardingPopup';
import OnboardingVideoPopup from '../Onboarding/Components/OnboardingVideoPopup';
import { OnboardingVideoPopupType } from '../Onboarding/Interfaces/OnboardingActionTypes';
import {
    updateOnboardingInformation,
    useGetEntityListQuery,
} from '../Organizations/Admin/Services/EnterpriseService';
import { fetchUserInviteSignupDetails } from '../Organizations/Signup/Services/SignupService';
import useAuthContext from './AuthContextHook';
import {
    InactiveAccountPopup,
    NoHomePage,
    NoPermissionsPopup,
    PendingAdminRequestPopup,
    UnverifiedEmailPopup,
} from './OnBoardingActionsPopup';

const LazyLogin = React.lazy(() => import('../Components/Login/Login'));
const LazyBuyer = React.lazy(() => import('../Components/Buyer/Buyer'));
const LazyCustom = React.lazy(
    () => import('../Organizations/Custom/Pages/Custom')
);

const LazySeller = React.lazy(() => import('../Components/Seller/Seller'));
const LazyAdmin = React.lazy(() => import('../Organizations/Admin/Admin'));
const LazyUser = React.lazy(() => import('../Users/Users'));
const LazyVerifyEmailPage = React.lazy(
    () => import('../Organizations/Signup/Pages/VerifyEmailPage')
);
const LazyChatWindow = React.lazy(
    () => import('../Components/Buyer/Events/ChatWindow')
);
const LazyCustomer = React.lazy(
    () => import('../CustomerPortal/Pages/CPRoutes')
);

export const AuthContext = React.createContext<IAuthContext>({
    authData: {
        access: '',
        modulePermissions: {
            BUYER: null,
            SELLER: null,
            CUSTOMIZE: null,
            GLOBAL_ADMIN: false,
            CUSTOMER: null,
        },
        details: null,
    },
    updateToken: (
        authData: string,
        permissions: Array<IUserPermission>,
        details: IUserDetails | null
    ) => {},
    checkPermission: (
        portal: PermissionPortal,
        buyerModule: PermissionModule,
        permission: PermissionType,
        entity_id: string | null
    ) => false,
});

function AuthContextProvider() {
    const dispatch = useDispatch();
    const history = useHistory();

    const { redirectStatus, toggleAuth, checkPermission, auth } =
        useAuthContext();
    const [welcomeUserPopupOpen, setWelcomeUserPopupOpen] = useState(false);
    const [quickInviteUserPopupOpen, setQuickInviteUserPopupOpen] =
        useState(false);
    const [onBoardingOpen, setOnBoardingOpen] = useState(false);

    const [videoPopupOpen, setVideoPopupOpen] =
        useState<OnboardingVideoPopupType | null>(null);

    const dontShowAgainAfterLogin = localStorage.getItem(
        'showOnboardingOnRefresh'
    );

    //Is Seller
    const isSeller =
        auth.details &&
        auth.details.invitation_type &&
        (auth.details.invitation_type ===
            InvitationType.JOIN_ORGANIZATION_VENDOR_INVITE ||
            auth.details.invitation_type ===
                InvitationType.VENDOR_CONTACT_INVITE ||
            auth.details.invitation_type ===
                InvitationType.VENDOR_ENTERPRISE_INVITE);

    //AuthPermissions
    const options = {
        adminSetup: checkPermission('GLOBAL_ADMIN', null, null, null),
        createEvent:
            !isSeller && checkPermission('BUYER', 'EVENT', 'EDIT', null),
        createPO:
            !isSeller &&
            checkPermission('BUYER', 'PURCHASE_ORDER', 'EDIT', null),
    };

    const optionsCount =
        (options.adminSetup ? 1 : 0) +
        (options.createEvent ? 1 : 0) +
        (options.createPO ? 1 : 0);

    const toShowFunction = useCallback(
        (showParam: string | undefined | null) => {
            return !(
                showParam &&
                auth.details &&
                auth.details.user_id &&
                JSON.parse(showParam)[auth.details.user_id] &&
                JSON.parse(showParam)[auth.details.user_id].toString() !==
                    '[object Object]'
            );
        },
        [auth.details]
    );

    const currentPath = window.location.pathname + window.location.search;
    // check if user has permission to atleast one portal or not
    const userHasAnyPermission =
        auth.modulePermissions.BUYER !== null ||
        auth.modulePermissions.SELLER !== null ||
        auth.modulePermissions.CUSTOMIZE !== null ||
        auth.modulePermissions.CUSTOMER !== null ||
        auth.modulePermissions.GLOBAL_ADMIN === true;
    useEffect(() => {
        const handleInvalidToken = (e: any) => {
            if (e.key === 'refreshToken' && e.newValue === null) {
                //
                history.replace(`/${window.location.search}`);
                setTimeout(async () => {
                    window.location.reload();
                }, 100);
            } else if (e.key === 'refreshToken' && e.newValue.length > 0) {
                window.location.reload();
            }
        };
        window.addEventListener('storage', handleInvalidToken);
        return function cleanup() {
            window.removeEventListener('storage', handleInvalidToken);
        };
    }, [history]);

    useEffect(() => {
        (async () => {
            if (auth.details !== null) {
                try {
                    const fetchAvailableFeaturesData =
                        await fetchAvailableFeatures();
                    dispatch(
                        availableFeatures({
                            type: FeaturesActions.UPDATE_LIST,
                            value: fetchAvailableFeaturesData,
                        })
                    );
                } catch (error) {
                    toast.error('Error fetching available features');
                }
            }
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth]);

    useEffect(() => {
        const redirectToSignUpPage = async () => {
            const queryFromWindow = new URLSearchParams(window.location.search);
            const invitation_id = queryFromWindow.get('invitation_id');
            const invitation_code = queryFromWindow.get('invitation_code');
            if (invitation_id && invitation_code) {
                const invitationValidation = await fetchUserInviteSignupDetails(
                    invitation_id,
                    invitation_code
                );

                if (
                    invitationValidation.inviteStatus === 'INVITATION_ACTIVE' ||
                    invitationValidation.inviteStatus ===
                        'INVITATION_NOT_REQUIRED'
                )
                    history.push(
                        `/is/${invitation_id}/${invitation_code}/${invitationValidation.inviteType}`
                    );
            }
        };
        redirectToSignUpPage();
    }, [auth.details, history]);

    //Making an useEffect cause auth onboarding info is coming undefined on first render
    useEffect(() => {
        setWelcomeUserPopupOpen(
            optionsCount > 0
                ? auth.details?.onboarding_information?.welcome_pop_up!
                : false
        );
        setQuickInviteUserPopupOpen(
            optionsCount > 0
                ? auth.details?.onboarding_information?.welcome_pop_up!
                : false
        );
        setOnBoardingOpen(
            optionsCount > 0
                ? !auth.details?.onboarding_information?.welcome_pop_up! &&
                      auth.details?.onboarding_information?.onboarding! &&
                      toShowFunction(dontShowAgainAfterLogin)
                : false
        );
        //the other dependencies are causing welcome popup to open again
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        auth.details?.onboarding_information?.welcome_pop_up,
        auth.details?.onboarding_information?.onboarding,
    ]);

    const { invitationUid, invitationCode }: { [key: string]: string } =
        useParams();
    // this code is for redirecting the user
    const { data: listOfEntity } = useGetEntityListQuery({}, { skip: true });
    const query = useQuery();
    useEffect(() => {
        if (
            listOfEntity &&
            listOfEntity.length > 0 &&
            query.get('type') === 'DOCUMENT'
        ) {
            const docuemnt_id = query.get('docuemnt_id');
            const entity_id = query.get('entity_id');

            if (docuemnt_id && entity_id && invitationUid && invitationCode) {
                localStorage.setItem(
                    `${invitationUid};${invitationCode};DOCUMENT`,
                    JSON.stringify({
                        entity_id: listOfEntity[0].entityId,
                        document_id: docuemnt_id,
                    })
                );
            }
        }
    }, [invitationCode, invitationUid, listOfEntity, query]);

    if (redirectStatus.isLoading) {
        return <LazyFallback />;
    } else if (auth.details !== null) {
        if (auth.details.is_email_verified === false) {
            if (window.location.pathname.includes('verify-email')) {
                return (
                    <Route
                        path="/verify-email/:userUid/:invitationUid/:invitationCode"
                        component={LazyVerifyEmailPage}
                        exact
                    />
                );
            } else {
                return (
                    <AuthContext.Provider
                        value={{
                            authData: auth,
                            updateToken: toggleAuth,
                            checkPermission: checkPermission,
                        }}
                    >
                        <UnverifiedEmailPopup
                            email={auth.details.email}
                            user_id={auth.details.user_id}
                        />
                    </AuthContext.Provider>
                );
            }
        } else if (auth.details.status === 'REQUESTED') {
            return (
                <AuthContext.Provider
                    value={{
                        authData: auth,
                        updateToken: toggleAuth,
                        checkPermission: checkPermission,
                    }}
                >
                    <PendingAdminRequestPopup
                        entity_name={auth.details.enterprise_name}
                        email={auth.details.email}
                        username={auth.details.username}
                    />
                </AuthContext.Provider>
            );
        } else if (auth.details.status === 'INACTIVE') {
            return (
                <AuthContext.Provider
                    value={{
                        authData: auth,
                        updateToken: toggleAuth,
                        checkPermission: checkPermission,
                    }}
                >
                    <InactiveAccountPopup />
                </AuthContext.Provider>
            );
        }
        // User has no permission
        else if (auth.details.status === 'ACTIVE' && !userHasAnyPermission) {
            return (
                <AuthContext.Provider
                    value={{
                        authData: auth,
                        updateToken: toggleAuth,
                        checkPermission: checkPermission,
                    }}
                >
                    <NoPermissionsPopup />
                </AuthContext.Provider>
            );
        }
        // handle the "first time login" case (user is active, has permission but has no default homepage)
        else if (
            auth.details.status === 'ACTIVE' &&
            userHasAnyPermission &&
            (auth.details.defaults === null ||
                auth.details.defaults.homepage === '')
        ) {
            return (
                <AuthContext.Provider
                    value={{
                        authData: auth,
                        updateToken: toggleAuth,
                        checkPermission: checkPermission,
                    }}
                >
                    <NoHomePage user_id={auth.details.user_id} />
                </AuthContext.Provider>
            );
        }
        // handle the "all good" case (user is active and has permission for atleast one portal setup)
        else if (auth.details.status === 'ACTIVE' && userHasAnyPermission) {
            return (
                <AuthContext.Provider
                    value={{
                        authData: auth,
                        updateToken: toggleAuth,
                        checkPermission: checkPermission,
                    }}
                >
                    <Suspense fallback={<LazyFallback />}>
                        {/* Onboarding popup */}
                        <WelcomePopup
                            isOpen={welcomeUserPopupOpen}
                            onClose={() => {
                                setWelcomeUserPopupOpen(false);
                            }}
                        />
                        <QuickInviteUserPopup
                            isOpen={
                                !welcomeUserPopupOpen &&
                                quickInviteUserPopupOpen
                            }
                            onSuccess={() => {
                                setQuickInviteUserPopupOpen(false);
                                updateOnboardingInformation({
                                    onboarding_information: {
                                        onboarding: true,
                                        welcome_pop_up: false,
                                        checklist:
                                            auth?.details
                                                ?.onboarding_information
                                                .checklist ?? false,
                                    },
                                });
                                setOnBoardingOpen(true);
                            }}
                            onClose={() => {
                                setQuickInviteUserPopupOpen(false);
                                updateOnboardingInformation({
                                    onboarding_information: {
                                        onboarding: true,
                                        welcome_pop_up: false,
                                        checklist:
                                            auth?.details
                                                ?.onboarding_information
                                                .checklist ?? false,
                                    },
                                });
                                setOnBoardingOpen(true);
                            }}
                            showAtOnboarding={true}
                            authUserName={auth.details.name}
                        />
                        <OnboardingPopup
                            open={onBoardingOpen ?? false}
                            setOpen={setOnBoardingOpen}
                            setVideoPopupOpen={setVideoPopupOpen}
                        />
                        <OnboardingVideoPopup
                            videoPopupOpen={videoPopupOpen}
                            setVideoPopupOpen={setVideoPopupOpen}
                            setOnBoardingOpen={setOnBoardingOpen}
                        />
                        {/* After OnBoarding Popup */}
                        {!(
                            (quickInviteUserPopupOpen ||
                                onBoardingOpen ||
                                videoPopupOpen) &&
                            optionsCount > 0
                        ) && (
                            <Switch>
                                <Route path="/" component={LazyLogin} exact />
                                <Route
                                    path="/buyer/chat-window/:event_id/:sub_event_id/:user_type/:hideBroadcast"
                                    component={LazyChatWindow}
                                />
                                <Route path="/buyer/*" component={LazyBuyer} />
                                <Route
                                    path="/custom/*"
                                    component={LazyCustom}
                                />
                                <Route
                                    path="/seller/*"
                                    component={LazySeller}
                                />
                                <Route
                                    path="/customer/"
                                    component={LazyCustomer}
                                />
                                <Route path="/admin/*" component={LazyAdmin} />
                                <Route path="/user/*" component={LazyUser} />

                                <Route component={NotFound404} />
                            </Switch>
                        )}
                    </Suspense>
                    <Redirect to={redirectStatus.path} />
                    <VerificationStatusContainer />
                </AuthContext.Provider>
            );
        } else {
            return <></>;
        }
    } else {
        // handle only one route i.e. login or home route(/)
        return (
            <AuthContext.Provider
                value={{
                    authData: auth,
                    updateToken: toggleAuth,
                    checkPermission: checkPermission,
                }}
            >
                <Suspense fallback={<LazyFallback />}>
                    <Switch>
                        <Route
                            path="/"
                            component={
                                /* redirectStatus.showUserPopup ? EMPTY : */ LazyLogin
                            }
                            exact
                        />
                        <Route component={NotFound404} />
                    </Switch>
                </Suspense>
                {
                    /* !redirectStatus.showUserPopup &&  */ currentPath !==
                        '/' && <Redirect to={redirectStatus.path} />
                }
            </AuthContext.Provider>
        );
    }
}

export default AuthContextProvider;
