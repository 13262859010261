import { cloneDeep, isEmpty } from 'lodash';
import { useCallback, useEffect, useReducer, useState } from 'react';
import { HookStateValue, useHookState } from '../../../Common/Hooks/StateHook';
import { IAttachment } from '../../../Models/RFQ.model';
import { ICountryList } from '../Interfaces/AddressInterface';
import {
    IIdentificationDetail,
    IdentificationState,
    IdentificationStatus,
} from '../Interfaces/IdentificationInterface';
import { IIdentificationUpdateValues } from '../Pages/Identifications/IdentificationsListOptionsMenu';
import { listCountries } from '../Services/AddressService';
import {
    createIdentification,
    getIdentificationDetail,
    getIdentificationList,
    updateIdentificationDetail,
} from '../Services/IdentificationService';

export enum IdentificationUpdateActions {
    UpdateName = 'NAME',
    UpdateValue = 'VALUE',
    UpdateDefault = 'DEFAULT',
    UpdatePublic = 'PUBLIC',
    UpdateCountry = 'COUNTRY',
    UpdateStatus = 'STATUS',
    UpdateFiles = 'FILES',
    RemoveFile = 'FILE_REMOVE',
    UpdateNotes = 'NOTES',
    UpdateCategory = 'CATEGORY',
    Reset = 'RESET',
}

interface IStringAction {
    type:
        | IdentificationUpdateActions.UpdateName
        | IdentificationUpdateActions.UpdateValue
        | IdentificationUpdateActions.UpdateNotes
        | IdentificationUpdateActions.UpdateCategory
        | IdentificationUpdateActions.UpdateCountry;
    value: string;
}

interface IBooleanAction {
    type:
        | IdentificationUpdateActions.UpdateDefault
        | IdentificationUpdateActions.UpdatePublic;
    value: boolean;
}

interface IStatusAction {
    type: IdentificationUpdateActions.UpdateStatus;
    value: IdentificationState;
}

interface IFileAction {
    type:
        | IdentificationUpdateActions.UpdateFiles
        | IdentificationUpdateActions.RemoveFile;
    value: IAttachment[] | number;
}

interface IResetAction {
    type: IdentificationUpdateActions.Reset;
    value: IIdentificationDetail;
}

export type IdentificationReducerAction =
    | IStringAction
    | IBooleanAction
    | IStatusAction
    | IResetAction
    | IFileAction;

const identificationDetailReducer = (
    state: IIdentificationDetail,
    action: IdentificationReducerAction
): IIdentificationDetail => {
    let newIdentificationDetail: IIdentificationDetail = { ...state };
    switch (action.type) {
        case IdentificationUpdateActions.UpdateName:
            newIdentificationDetail.name = action.value;
            break;
        case IdentificationUpdateActions.UpdateValue:
            newIdentificationDetail.value = action.value;
            break;
        case IdentificationUpdateActions.UpdatePublic:
            newIdentificationDetail.isPublic = action.value;
            break;
        case IdentificationUpdateActions.UpdateDefault:
            if (action.value) {
                newIdentificationDetail.isPublic = true;
            }
            newIdentificationDetail.isDefault = action.value;
            break;
        case IdentificationUpdateActions.UpdateStatus:
            newIdentificationDetail.status = action.value;
            break;
        case IdentificationUpdateActions.UpdateCountry:
            newIdentificationDetail.country = action.value;
            break;
        case IdentificationUpdateActions.UpdateNotes:
            newIdentificationDetail.notes = action.value;
            break;
        case IdentificationUpdateActions.UpdateFiles:
            newIdentificationDetail.attachments = [
                ...newIdentificationDetail.attachments,
                ...(action.value as IAttachment[]),
            ];
            break;
        case IdentificationUpdateActions.RemoveFile:
            newIdentificationDetail.attachments.splice(
                action.value as number,
                1
            );
            break;
        // case IdentificationUpdateActions.UpdateCategory:
        //     newIdentificationDetail.category = action.value;
        //     break;
        case IdentificationUpdateActions.Reset:
            newIdentificationDetail = { ...action.value };
    }
    return newIdentificationDetail;
};

const initialIdentificationState: IIdentificationDetail = {
    id: '',
    name: '',
    value: '',
    isDefault: false,
    isPublic: true,
    country: '',
    status: IdentificationState.ACTIVE,
    linked_addresses: [],
    notes: '',
    identificationStatus: IdentificationStatus.PENDING,
    attachments: [],
};

/*****
 Identification detail hook
 *****/

export const useIdentificationDetail = (
    entityId: string,
    identificationId: string
) => {
    const { hookState, updateHookState } = useHookState(HookStateValue.LOADING);
    const [isValidDetails, setIsValidDetails] = useState<boolean>(false);

    const [countryList, setCountryList] = useState<ICountryList[] | []>([]);

    const loadData = useCallback(async () => {
        // fetch list of users already having permission for this event
        updateHookState(HookStateValue.LOADING);
        try {
            let countryList = await listCountries();

            setCountryList(countryList);

            updateHookState(HookStateValue.READY);
        } catch (error) {
            updateHookState(HookStateValue.ERROR);
        }
    }, [updateHookState]);

    useEffect(() => {
        loadData();
    }, [loadData]);

    const loadIdentificationDetails = (): IIdentificationDetail => {
        const identificationDetail = getIdentificationDetail(
            entityId,
            identificationId
        );
        identificationDetail
            .then((response) => {
                identificationDetailDispatch({
                    type: IdentificationUpdateActions.Reset,
                    value: response,
                });
                setOriginalIdentificationDetail(cloneDeep(response));
                updateHookState(HookStateValue.READY);
                return response;
            })
            .catch((error) => {
                updateHookState(HookStateValue.ERROR);
            });
        return initialIdentificationState;
    };

    const [originalIdentificationDetail, setOriginalIdentificationDetail] =
        useState<IIdentificationDetail>(cloneDeep(initialIdentificationState));

    const [identificationDetail, identificationDetailDispatch] = useReducer(
        identificationDetailReducer,
        initialIdentificationState,
        loadIdentificationDetails
    );

    const handleUpdate = (action: IdentificationReducerAction) => {
        identificationDetailDispatch(action);
    };

    const handleSubmitUpdate = async (): Promise<boolean> => {
        try {
            await updateIdentificationDetail(
                entityId,
                identificationId,
                identificationDetail
            );
        } catch (error) {
            return false;
        }
        return true;
    };

    useEffect(() => {
        let errors = false;
        if (isEmpty(identificationDetail.name.trim())) errors = true;
        if (isEmpty(identificationDetail.value.trim())) errors = true;
        setIsValidDetails(errors ? false : true);
    }, [identificationDetail]);

    return {
        originalIdentificationDetail,
        identificationDetail,
        hookState,
        handleUpdate,
        handleSubmitUpdate,
        isValidDetails,
        countryList,
    };
};

/*****
 Identification create hook
 *****/

export const useIdentificationCreate = (
    entityId: string,
    isFirstIdentification: boolean
) => {
    const { hookState, updateHookState } = useHookState(HookStateValue.READY);
    const [identificationDetail, identificationDetailDispatch] = useReducer(
        identificationDetailReducer,
        {
            ...initialIdentificationState,
            isDefault: isFirstIdentification,
        }
    );

    const [isValidDetails, setIsValidDetails] = useState<boolean>(false);

    const [countryList, setCountryList] = useState<ICountryList[] | []>([]);

    const loadData = useCallback(async () => {
        // fetch list of users already having permission for this event
        updateHookState(HookStateValue.LOADING);
        try {
            let countryList = await listCountries();

            setCountryList(countryList);

            updateHookState(HookStateValue.READY);
        } catch (error) {
            updateHookState(HookStateValue.ERROR);
        }
    }, [updateHookState]);

    useEffect(() => {
        loadData();
    }, [loadData]);

    const handleReset = () => {
        identificationDetailDispatch({
            type: IdentificationUpdateActions.Reset,
            value: {
                ...initialIdentificationState,
                isDefault: isFirstIdentification,
            },
        });
        setIsValidDetails(false);
    };

    const handleIdentificationUpdate = (
        action: IdentificationReducerAction
    ) => {
        identificationDetailDispatch(action);
    };

    const handleCreate = async (): Promise<boolean> => {
        if (!isValidDetails) {
            return false;
        }
        updateHookState(HookStateValue.LOADING);
        try {
            await createIdentification(entityId, identificationDetail);
        } catch (error) {
            updateHookState(HookStateValue.ERROR);
            return false;
        }
        updateHookState(HookStateValue.READY);
        return true;
    };

    useEffect(() => {
        let errors = false;
        if (isEmpty(identificationDetail.name.trim())) errors = true;
        if (isEmpty(identificationDetail.value.trim())) errors = true;
        if (
            !Boolean(identificationDetail.country) ||
            isEmpty(identificationDetail.country?.trim())
        )
            errors = true;
        setIsValidDetails(errors ? false : true);
    }, [identificationDetail]);

    return {
        identificationDetail,
        isValidDetails,
        handleIdentificationUpdate,
        handleReset,
        handleCreate,
        hookState,
        countryList,
    };
};

/*****
 Identifications list hook
 *****/

export const useEntityIdentificationList = (entityId: string) => {
    const { hookState, updateHookState } = useHookState(HookStateValue.LOADING);
    const [identificationList, setIdentificationList] = useState<
        IIdentificationDetail[]
    >([]);

    const loadIndentificationList = useCallback(async () => {
        if (entityId)
            try {
                const responseList = await getIdentificationList(entityId);
                setIdentificationList(responseList);
                updateHookState(HookStateValue.READY);
            } catch (error) {
                updateHookState(HookStateValue.ERROR);
            }
    }, [entityId, updateHookState]);

    const refreshList = () => loadIndentificationList();

    const updateIdentificationList = (data: IIdentificationUpdateValues) => {
        setIdentificationList((prevList) => {
            // FIXME: identification type any
            const identification: any = prevList[data.index];
            identification[data.key] = data.value;
            return [...prevList];
        });
    };

    useEffect(() => {
        loadIndentificationList();
        return () => {
            // cleanup identifications list
            setIdentificationList([]);
        };
    }, [loadIndentificationList]);

    return {
        identificationList,
        hookState,
        refreshList,
        updateIdentificationList,
    };
};
