import { cloneDeep } from 'lodash';
import { CustomFieldType } from '../../Global/Interfaces/TemplateInterface';
import { DashboardSort, DashbordSortOptions } from '../interface/project.model';

export const BLANK_DASHBOARD_SORT: DashboardSort = {
    ascending: true,
    field: '',
    field_type: 'BUILTIN',
    type: CustomFieldType.SHORTTEXT,
};

export const getSortOptions = (
    field_type: CustomFieldType
): DashbordSortOptions[] => {
    switch (field_type) {
        case 'LONGTEXT':
        case CustomFieldType.LONG_TEXT:
        case CustomFieldType.SHORT_TEXT:
        case 'SHORTTEXT':
        case 'CHOICE':
        case 'MULTI_CHOICE':
            return [
                {
                    label: 'Ascending',
                    ascending: true,
                },
                {
                    label: 'Descending',
                    ascending: false,
                },
            ];
        case 'DATETIME':
        case 'DATE':
            return [
                {
                    label: 'Ascending',
                    ascending: true,
                },
                {
                    label: 'Descending',
                    ascending: false,
                },
            ];
        case 'FLOAT':
        case CustomFieldType.DECIMAL:
        case CustomFieldType.INTEGER:
            return [
                {
                    label: 'Ascending',
                    ascending: true,
                },
                {
                    label: 'Descending',
                    ascending: false,
                },
            ];
        default:
            return [
                {
                    label: 'Ascending',
                    ascending: true,
                },
                {
                    label: 'Descending',
                    ascending: false,
                },
            ];
    }
};

export const formatDashboardSortForBE = (
    dashboardSorting: DashboardSort[],
    builtInFieldsMap: Record<string, string>
) => {
    const newDashboardSortingList = dashboardSorting.map((sort) => {
        const newSort = cloneDeep(sort);
        if (sort.field_type === 'BUILTIN') {
            newSort.field = builtInFieldsMap[newSort.field] ?? newSort.field;
        }

        return newSort;
    });
    return newDashboardSortingList;
};

export const formatDashboardSortForFE = (
    dashboardSorting: DashboardSort[],
    filterFieldToNameMap: Record<string, string>,
    currentTab: string // in_progress - DRAFT | ongoing   | finished
) => {
    const newDashboardSortingList = dashboardSorting.map((sort) => {
        if (sort.field_type === 'BUILTIN') {
            if (sort.field === 'modified_datetime') {
                if (currentTab === 'in_progress') {
                    sort.field = 'Modified on';
                } else if (currentTab === 'VENDORS' || currentTab === 'ITEMS') {
                    sort.field = filterFieldToNameMap[sort.field] ?? sort.field;
                } else {
                    sort.field = 'Submitted on';
                }
            } else if (sort.field === 'modified_by_user_name') {
                if (currentTab === 'in_progress') {
                    sort.field = 'Last modified by';
                } else {
                    sort.field = 'Submitted by';
                }
            } else {
                sort.field = filterFieldToNameMap[sort.field] ?? sort.field;
            }
        }

        return sort;
    });

    return newDashboardSortingList;
};
