import { Box, TextField } from '@mui/material';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { AuthContext } from '../Contexts/AuthContext';
import { IFilterColumnMenuList } from '../ProjectGlCostCenter/constants/projectDashboardConstants';
import FWAutocomplete from './FWAutocomplete';
import { FWMenuItem } from './FWCustomMenu';
import { FWTypography } from './FWTypograhy';

interface SavedViewsDropdownProps {
    savedViews: { [key: string]: IFilterColumnMenuList[] };
    setSavedViews: (views: { [key: string]: IFilterColumnMenuList[] }) => void;
    selectedView: string;
    setSelectedView: (viewName: string) => void;
    onViewSelect: (viewName: string) => void;
    handleViewChange: (viewName: string) => void;
    dashboardType: string;
}

const SavedViewsDropdown: React.FC<SavedViewsDropdownProps> = ({
    savedViews,
    selectedView,
    onViewSelect,
    handleViewChange,
    setSelectedView,
    setSavedViews,
    dashboardType,
}) => {
    const [inputValue, setInputValue] = useState<string>('');
    const { authData } = useContext(AuthContext);
    // Extract view names from saved views
    const cookieName: string = useMemo(() => {
        return authData.details?.name
            ? `${authData.details?.name}-${dashboardType}-dashboard`
            : `${dashboardType}-dashboard`;
    }, [authData.details?.name, dashboardType]);

    // useEffect(() => {
    //     // Retrieve selected view from localStorage
    //     setSelectedView(localStorage.getItem('selectedView') || 'Default view');
    //     const storedView = localStorage.getItem('selectedView');
    //     const viewList = localStorage.getItem(cookieName);
    //     if (storedView) {
    //         setSelectedView(storedView);
    //     }
    //     if (viewList) {
    //         setSavedViews(JSON.parse(viewList));
    //     }
    // }, [cookieName, setSavedViews, setSelectedView]);

    useEffect(() => {
        // Retrieve saved views and selected view from localStorage
        const viewList = localStorage.getItem(cookieName);
        const storedSelectedView =
            localStorage.getItem('selectedView') || 'Factwise default view';

        if (viewList) {
            setSavedViews(JSON.parse(viewList));
        }

        setSelectedView(storedSelectedView);
    }, [cookieName, setSavedViews, setSelectedView]);

    useEffect(() => {
        localStorage.setItem(cookieName, JSON.stringify(savedViews));
        const selectedViewFromStorage = localStorage.getItem(
            `${cookieName}-selectedView`
        );
        setSelectedView(selectedViewFromStorage || 'Factwise default view');
    }, [cookieName, savedViews, setSelectedView]);

    useEffect(() => {
        // Save selected view to localStorage
        if (savedViews) {
            localStorage.setItem('savedViews', JSON.stringify(savedViews));
        }

        if (selectedView) {
            localStorage.setItem(
                'selectedView',
                selectedView || 'Factwise default view'
            );
        }
    }, [savedViews, selectedView]);

    const viewNames = Object.keys(savedViews);
    // Function to handle view selection
    const handleChange = (
        event: React.SyntheticEvent,
        newValue: string | null
    ) => {
        if (newValue) {
            onViewSelect(newValue);
            handleViewChange(newValue);
            // onClose();
        }
    };

    // Custom filter function to match the input value
    const filterOptions = (options: string[], state: any) =>
        options.filter((option) =>
            option.toLowerCase().includes(inputValue.toLowerCase())
        );

    return (
        <Box sx={{ padding: '8px' }}>
            <FWAutocomplete
                // freeSolo
                options={viewNames}
                popupIcon={
                    <Box fontSize={'16px'}>
                        <i className="bi bi-chevron-down"></i>
                    </Box>
                }
                sx={{ width: '100%' }}
                renderInput={(params: any) => (
                    <TextField
                        label="Dashboard View"
                        {...params}
                        error={false} // Customize this based on your error handling
                        helper={{
                            text: '', // Customize this based on your error handling
                            textColor: 'error',
                        }}
                        onChange={(e) => setInputValue(e.target.value)}
                        // placeholder="Search views..."
                    />
                )}
                value={selectedView}
                onChange={handleChange}
                clearIcon={false}
                filterOptions={filterOptions}
                getOptionLabel={(option: any) => option}
                renderOption={(props: any, option: any) => (
                    <FWMenuItem
                        {...props}
                        key={option}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            padding: '8px',
                        }}
                    >
                        <FWTypography variant="body2" sx={{ flexGrow: 1 }}>
                            {option}
                        </FWTypography>
                    </FWMenuItem>
                )}
                isOptionEqualToValue={(option: any, value: any) =>
                    option === value
                }
                // onInputChange={(event: any, newInputValue: any) =>
                //     setInputValue(newInputValue)
                // }
            />
        </Box>
    );
};

export default SavedViewsDropdown;
