import { IAdminSettingModule } from '../../Organizations/Admin/Interfaces/AdminSettingsInterface';

export enum AdminSettingsUpdateType {
    INIT_ENTITY = 'INIT_ENTITY',
    // GRN_CUSTOM_FIELDS_ADD = 'GRN_CUSTOM_FIELDS_ADD',
    // GRN_CUSTOM_FIELDS_REMOVE = 'GRN_CUSTOM_FIELDS_REMOVE',
    // GRN_CUSTOM_FIELDS_UPDATE = 'GRN_CUSTOM_FIELDS_UPDATE',
    // GRN_CHECKLIST_ADD = 'GRN_CHECKLIST_ADD',
    // GRN_CHECKLIST_UPDATE = 'GRN_CHECKLIST_UPDATE',
    // GRN_CHECKLIST_REMOVE = 'GRN_CHECKLIST_REMOVE',
    // QC_CUSTOM_FIELDS_ADD = 'QC_CUSTOM_FIELDS_ADD',
    // QC_CUSTOM_FIELDS_REMOVE = 'QC_CUSTOM_FIELDS_REMOVE',
    // QC_CUSTOM_FIELDS_UPDATE = 'QC_CUSTOM_FIELDS_UPDATE',
    // QC_CHECKLIST_ADD = 'QC_CHECKLIST_ADD',
    // QC_CHECKLIST_UPDATE = 'QC_CHECKLIST_UPDATE',
    // QC_CHECKLIST_REMOVE = 'QC_CHECKLIST_REMOVE',
}

export interface IASEntitySettingsMap {
    [key: string]: IAdminSettingModule[];
}

export type IAdminSettingsState = IASEntitySettingsMap;

interface IInitSettingsAction {
    type: AdminSettingsUpdateType.INIT_ENTITY;
    entityUid: string;
    value: IAdminSettingModule[];
}

// interface ICustomFieldAddAction {
//     type:
//         | AdminSettingsUpdateType.GRN_CUSTOM_FIELDS_ADD
//         | AdminSettingsUpdateType.QC_CUSTOM_FIELDS_ADD;
//     value: IAdminCustomField;
// }
// interface ICustomFieldRemoveAction {
//     type:
//         | AdminSettingsUpdateType.GRN_CUSTOM_FIELDS_REMOVE
//         | AdminSettingsUpdateType.QC_CUSTOM_FIELDS_REMOVE;
//     value: number;
// }
// interface ICustomFieldUpdateAction {
//     type:
//         | AdminSettingsUpdateType.GRN_CUSTOM_FIELDS_UPDATE
//         | AdminSettingsUpdateType.QC_CUSTOM_FIELDS_UPDATE;
//     value: {
//         index: number;
//         field: 'NAME' | 'TYPE' | 'MANDATORY';
//         data: string | boolean | CustomTemplateItemTypes;
//     };
// }

// interface IChecklistAddAction {
//     type:
//         | AdminSettingsUpdateType.GRN_CHECKLIST_ADD
//         | AdminSettingsUpdateType.QC_CHECKLIST_ADD;
//     value: IChecklistSummary;
// }

// interface IChecklistUpdateAction {
//     type:
//         | AdminSettingsUpdateType.GRN_CHECKLIST_UPDATE
//         | AdminSettingsUpdateType.QC_CHECKLIST_UPDATE;
//     value: {
//         index: number;
//         data: IChecklistSummary;
//     };
// }

// interface IChecklistRemoveAction {
//     type:
//         | AdminSettingsUpdateType.GRN_CHECKLIST_REMOVE
//         | AdminSettingsUpdateType.QC_CHECKLIST_REMOVE;
//     value: number;
// }

// type ChecklistActions =
//     | IChecklistAddAction
//     | IChecklistUpdateAction
//     | IChecklistRemoveAction;

// type CustomFieldActions =
//     | ICustomFieldAddAction
//     | ICustomFieldRemoveAction
//     | ICustomFieldUpdateAction;

export interface IAdminSettingsUpdateActions {
    type: string;
    payload: IInitSettingsAction;
    // | ChecklistActions | CustomFieldActions;
}
