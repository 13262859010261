import React from 'react';
import { FWTypography } from '../../../Common/FWTypograhy';
import { Box } from '@mui/material';
import { toast } from 'react-toastify';
import { downloadFile } from '../../../Global/Services/FileStorageService';

const DisplayAttachment = ({
    attachment_id,
    file_name,
}: {
    attachment_id: string;
    file_name: string;
}) => {
    const handleFileDownloadFile = async () => {
        try {
            const fileURl = await downloadFile(attachment_id);
            window.open(fileURl, '_self');
        } catch (error) {
            toast.error('Error downloading file');
        }
    };

    return (
        <Box
            className={'flex flex--aic flex--jcsb'}
            // width={'200px'}
            // bgcolor={'#007AFF'}
            padding={1.5}
            borderRadius={1}
            sx={{
                cursor: 'pointer',
                border: '1px solid rgba(0, 122, 255, 0.7)',
            }}
            marginBottom={1}
            onClick={() => handleFileDownloadFile()}
            bgcolor={'#2383e208'}
        >
            <FWTypography color={'primary.main'}>{file_name}</FWTypography>
            <Box fontSize={24} color={'primary.main'}>
                <i className="bi bi-file-earmark-arrow-down" />
            </Box>
        </Box>
    );
};

export default DisplayAttachment;
