import { capitaliseFirstLetter } from '../../BuyerTemplates/Component/TemplateSuggestedFieldsSection';
import {
    ContractStatusEnum,
    ICLMDashboardDetails,
} from '../Interface/ContractInterfaces';
import { ICLMDetailsResponse } from '../Services/CLM.service';

export const blankDashboardDetails: ICLMDashboardDetails[] = [
    {
        contract_uuid: '',
        contract_status: '',
        status: ContractStatusEnum.DRAFT,
        contract_name: '',
        contract_id: '',
        contract_item_count: 0,
        contract_start_date: '',
        contract_end_date: '',
        buyer_entity: '',
        buyer_entity_name: '',
        seller_entity: '',
        buyer_identification: [],
        seller_entity_name: '',
        template_id: '',
        item_details: [
            {
                item_name: '',
                item_code: '',
            },
        ],
        project_information: {
            project_code: '',
            project_id: '',
            project_name: '',
        },
    },
];

export const convertContractResponseToDashboard = (
    dashboardApiResponse: ICLMDetailsResponse[]
) => {
    const mappedDashboardDetails: ICLMDashboardDetails[] =
        dashboardApiResponse.map((data) => {
            return {
                contract_uuid: data.contract_id,
                contract_status: data.status,
                contract_name: data.contract_name,
                status: data.status,
                contract_id: data.custom_contract_id,
                contract_item_count: data.contract_item_count,
                contract_start_date: data.contract_start_date,
                contract_end_date: data.contract_end_date,
                buyer_entity: data.buyer_entity,
                buyer_entity_name: data.buyer_entity_details.buyer_entity_name,
                seller_entity: data.seller_entity ? data.seller_entity : '-',
                buyer_identification: data.buyer_identifications,
                seller_entity_name:
                    data.seller_entity_details.seller_entity_name,
                template_id: data.additional_details.template_id,
                item_details: data.item_details,
                project_information: data.project_information,
            };
        });

    return mappedDashboardDetails;
};

export const getStatusLabelAndColor = (status: ContractStatusEnum) => {
    switch (status) {
        case ContractStatusEnum.DRAFT:
            return {
                label: 'Draft',
                color: 'text.secondary',
            };
        case ContractStatusEnum.SUBMITTED:
            return {
                label: 'Ongoing',
                color: 'success.main',
            };
        case ContractStatusEnum.TERMINATED:
            return {
                label: 'Terminated',
                color: 'error.main',
            };
        case ContractStatusEnum.EXPIRED:
            return {
                label: 'Expired',
                color: 'error.main',
            };
        default:
            return {
                label: capitaliseFirstLetter(status.toLowerCase()),
                color: 'text.secondary',
            };
    }
};
