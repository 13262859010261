import React from 'react';
import { FWTypography } from '../../../../Common/FWTypograhy';
import { Box } from '@mui/material';
import TagsDisplay from '../../../../Global/Components/TagsDisplay';

const VLNProfileAboutOtherInfoCompetency = ({
    competencyList,
}: {
    competencyList: {
        vendor_competency_id: string | null;
        competency: string;
        endorsement_count: number;
    }[];
}) => {
    return (
        <>
            <FWTypography variant={'h4'} fontWeight={500}>
                Competency
            </FWTypography>
            <Box className="flex" marginY={1} gap={1} flexWrap={'wrap'}>
                {/* {competencyList.map((competency, idx) => (
                    <Chip
                        key={idx}
                        variant={'outlined'}
                        color="primary"
                        label={`${competency.competency} (${competency.endorsement_count})`}
                        sx={{}}
                    />
                    ))} */}
                <TagsDisplay
                    tags={competencyList.map(
                        (competency) => competency.competency
                    )}
                />
            </Box>
        </>
    );
};

export default VLNProfileAboutOtherInfoCompetency;
