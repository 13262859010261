import produce from 'immer';
import {
    HelpInternalPageName,
    HelpPageName,
    IHelpInformationState,
} from '../Slices/HelpInformationSlice';

export enum HelpPageActionsActions {
    TOGGLE_HELP = 'TOGGLE_HELP',
    UPDATE_NAME = 'UPDATE_NAME',
    UPDATE_INTERNAL_NAME = 'UPDATE_INTERNAL_NAME',
}

export interface IOpenHelpAction {
    type: HelpPageActionsActions.TOGGLE_HELP;
}

export interface IHelpPageNameUpdateAction {
    type: HelpPageActionsActions.UPDATE_NAME;
    value: HelpPageName;
}

export interface IHelpInternalPageNameUpdateAction {
    type: HelpPageActionsActions.UPDATE_INTERNAL_NAME;
    value: HelpInternalPageName | null;
}

export interface IHelpInformationActions {
    type: string;
    payload:
        | IHelpPageNameUpdateAction
        | IOpenHelpAction
        | IHelpInternalPageNameUpdateAction;
}

export const updateHelpInformationReducer = produce(
    (state: IHelpInformationState, action: IHelpInformationActions) => {
        switch (action.payload.type) {
            case HelpPageActionsActions.UPDATE_NAME:
                state.pageName = action.payload.value;
                break;
            case HelpPageActionsActions.UPDATE_INTERNAL_NAME:
                state.pageInternalName = action.payload.value;
                break;
            case HelpPageActionsActions.TOGGLE_HELP:
                state.openHelp = !state.openHelp;
                break;
        }
    }
);
