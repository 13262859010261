import { CircularProgress, Grid, IconButton } from '@mui/material';
import React from 'react';
import FWPopover from '../../Common/FWPopover';
import { FWTypography } from '../../Common/FWTypograhy';
import { FWNumberFormatter } from '../../Common/Utils/CurrencyUtils';
import { getFWDate } from '../../Common/Utils/DateUtils';
import { getPODeliverySchedule } from '../../Invoices/Services/PO.service';

const PODeliverySchedulePopup = ({
    po_id,
    user_type,
    buttonId,
}: {
    po_id: string;
    user_type: 'BUYER' | 'SELLER';
    buttonId?: string;
}) => {
    const [deliverySchedule, setDeliverySchedule] = React.useState<
        any[] | null
    >(null);
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
        null
    );
    const open = Boolean(anchorEl);
    const id = open ? 'po-delivery-schedule-popover' : undefined;

    // handler click of the delivery truck icon
    const handleClick = async (event: any) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        let resp: any[] = await getPODeliverySchedule(po_id, user_type);
        let arr: any[] = [];
        resp.forEach((data) => {
            if (data.status === 'ACTIVE') {
                const itemName = data.po_item_information.buyer_item_name;

                let item_arr = data.delivery_schedule_items.map((item: any) => {
                    return {
                        quantity: item.quantity,
                        deliveryDate: item.delivery_date,
                        measurementUnit: item.measurement_unit_name || 'kg',
                        itemName,
                    };
                });
                arr = arr.concat(item_arr);
            }
        });
        setDeliverySchedule(
            arr.sort((delivery1, delivery2) => {
                if (delivery1.deliveryDate === null) return 1;
                if (delivery2.deliveryDate === null) return -1;
                else {
                    let date1 = new Date(delivery1.deliveryDate).getTime();
                    let date2 = new Date(delivery2.deliveryDate).getTime();
                    return date1 - date2;
                }
            })
        );
    };
    const handleClose = () => setAnchorEl(null);

    return (
        <>
            <div className="flex flex--jcc">
                <IconButton
                    size="small"
                    color="primary"
                    ref={(ref) => {
                        if (!ref) return;
                        ref.onclick = handleClick;
                    }}
                    id={buttonId}
                >
                    <i className="bi bi-truck"></i>
                </IconButton>
            </div>
            <FWPopover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Grid
                    container
                    columnSpacing={1}
                    rowSpacing={1}
                    style={{ width: '300px' }}
                >
                    {/* <Grid item md={12}>
                        <FWTypography>Delivery Schedule</FWTypography>
                    </Grid> */}
                    {deliverySchedule === null ? (
                        <Grid item md={12}>
                            <div
                                className="flex flex--jcc"
                                style={{ margin: '12px' }}
                            >
                                <CircularProgress size={30} />
                            </div>
                        </Grid>
                    ) : (
                        <>
                            <Grid item md={4}>
                                <FWTypography color="text.secondary">
                                    Item Name
                                </FWTypography>
                            </Grid>
                            <Grid item md={4}>
                                <FWTypography color="text.secondary">
                                    Quantity
                                </FWTypography>
                            </Grid>
                            <Grid item md={4}>
                                <FWTypography color="text.secondary">
                                    Date
                                </FWTypography>
                            </Grid>
                            {deliverySchedule.map((delivery, idx) => (
                                <React.Fragment key={idx}>
                                    <Grid item md={4}>
                                        <FWTypography textAlign="left">
                                            {delivery.itemName}
                                        </FWTypography>
                                    </Grid>
                                    <Grid item md={4}>
                                        <FWTypography textAlign="left">
                                            {FWNumberFormatter(
                                                +delivery.quantity
                                            )}{' '}
                                            {delivery.measurementUnit}(s)
                                        </FWTypography>
                                    </Grid>
                                    {delivery.deliveryDate !== null ? (
                                        <Grid item md={4}>
                                            <FWTypography>
                                                {getFWDate(
                                                    delivery.deliveryDate
                                                )}
                                            </FWTypography>
                                        </Grid>
                                    ) : (
                                        <Grid item md={4}>
                                            <FWTypography>TBD</FWTypography>
                                        </Grid>
                                    )}
                                </React.Fragment>
                            ))}
                        </>
                    )}
                </Grid>
            </FWPopover>
        </>
    );
};

export default PODeliverySchedulePopup;
