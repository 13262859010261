import FWDataGrid from '../../../Common/FWDataGrid';
import CustomDashboardGridHeader from '../../../Global/Components/CustomDashboardGridHeader';
import { verifiedColumns } from '../../../Invoices/Constants/helper';
import { useVLNRelationshipInvoiceDashboard } from '../../Hooks/Relationship/useVLNRelationshipInvoiceDashboard';

const VLNRelationshipInvoiceDashboard = ({
    sellerEntityUuid,
}: {
    sellerEntityUuid: string;
}) => {
    const {
        rows,
        sortFields,
        setSortFields,
        totalPages,
        setCurrentPage,
        currentPage,
        debouncedSearch,
    } = useVLNRelationshipInvoiceDashboard(sellerEntityUuid);

    return (
        <FWDataGrid
            disableFESearch
            columns={verifiedColumns(true)}
            onTextChange={debouncedSearch}
            rows={rows}
            // search={searchText}
            // onRowClicked={(e) => onClickRowHandler(e.data.id)}
            // fixedHeight={500}
            context={{
                buyerDashboard: true,
            }}
            defaultColDef={{
                resizable: true,
                headerComponent: (props: any) => (
                    <CustomDashboardGridHeader
                        {...props}
                        sortFields={sortFields}
                        setSortFields={setSortFields}
                    />
                ),
            }}
            suppressPaginationPanel
            useCustomPagination={totalPages}
            customPaginationOnChange={(e, pageNumber) => {
                setCurrentPage(pageNumber);
            }}
            customPaginationPageNumber={currentPage}
            showSearchBar
            // showRefreshIcon={showRefreshIcon}
            // onRefreshIconClick={() => {
            //     setShowRefreshIcon(false);
            //     fetchData(1, currentTab, '', []);
            // }}
            // showFilterColumnMenu
        />
    );
};

export default VLNRelationshipInvoiceDashboard;
