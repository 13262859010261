import { Box, Grid, styled } from '@mui/material';
import moment from 'moment';
import { FWTooltip } from '../../Common/FWCustomTooltip';
import { FWTypography } from '../../Common/FWTypograhy';
import { ENABLE_EVENT_CONFIDENTIALITY } from '../../Global/Constants/FeatureFlags';
import { IChatEvent } from '../Interfaces/Chat.model';
import React from 'react';

const StyledBox = styled(Box)(({ theme }) => ({
    cursor: 'pointer',
    padding: '10px',
    '&:hover': {
        backgroundColor: '#f0f0f2',
    },
    // borderBottom: '1px solid',
    // borderColor: theme.palette.dividerColor.main,
    margin: 15,
    borderRadius: 15,
    transition: 'all 0.2s ease-in-out',
}));

interface IChatEventDisplayContainerProps {
    eventDetails: IChatEvent;
    highlightBox: boolean;
    updateEventID: (event_id: string, sub_event_id: string) => void;
    containerSelector: string;
    unreadMsgCount: number;
    user_type: 'buyer' | 'seller';
    setCurrentlySelectedEventStatus: React.Dispatch<
        React.SetStateAction<string>
    >;
}

const SubHeaders = ({ text, color }: { text: string; color: string }) => {
    return (
        <FWTypography
            fontSize={'0.8rem'}
            color={color}
            padding="3px"
            maxWidth={'225px'}
        >
            {text}{' '}
        </FWTypography>
    );
};

function ChatEventDetailsRow(props: IChatEventDisplayContainerProps) {
    // const [loaded, elRef] = useLazyLoadList(props.containerSelector);

    return (
        <StyledBox
            onClick={() => {
                props.updateEventID(
                    props.eventDetails.event_id,
                    props.eventDetails.sub_event_id
                );
                props.setCurrentlySelectedEventStatus(
                    props.eventDetails.status
                );
            }}
            bgcolor={props.highlightBox ? '#e5f1ff' : 'transparent'}
            // ref={elRef}
        >
            {
                /* loaded */ true ? (
                    <Box>
                        <Box className="flex flex--jcsb" padding="3px">
                            <Box className="flex flex--aic" maxWidth={'200px'}>
                                <FWTypography
                                    fontWeight={600}
                                    color={'#4e4e4e'}
                                >
                                    {props.eventDetails?.event_name}
                                </FWTypography>
                                {ENABLE_EVENT_CONFIDENTIALITY &&
                                    props.eventDetails.additional_details
                                        .access === 'ACCESS_RESTRICTED' && (
                                        <>
                                            &nbsp;
                                            <FWTooltip
                                                title="Event is confidential"
                                                placement="right"
                                            >
                                                <i className="bi bi-lock"></i>
                                            </FWTooltip>
                                        </>
                                    )}
                            </Box>
                            <Box className="flex flex--aic">
                                {props.unreadMsgCount > 0 && (
                                    <>
                                        <Box
                                            color="success.main"
                                            fontSize="10px"
                                        >
                                            <i className="bi bi-circle-fill"></i>
                                        </Box>
                                        &nbsp; &nbsp;
                                    </>
                                )}
                                {/* <i className="bi bi-chevron-right"></i> */}
                            </Box>
                        </Box>
                        {props.user_type === 'buyer' ? (
                            <Grid container flexWrap={'nowrap'}>
                                <Grid item>
                                    <SubHeaders
                                        text={`${
                                            props.eventDetails
                                                ?.sub_event_additional_details
                                                ?.item_count ?? 0
                                        } item${
                                            props.eventDetails
                                                ?.sub_event_additional_details
                                                ?.item_count > 0
                                                ? 's'
                                                : ''
                                        } · `}
                                        color="#4B86AA"
                                    />
                                </Grid>
                                <Grid item>
                                    <SubHeaders
                                        text={`${
                                            props.eventDetails
                                                ?.sub_event_additional_details
                                                ?.vendor_count ?? 0
                                        } vendor${
                                            props.eventDetails
                                                ?.sub_event_additional_details
                                                ?.vendor_count > 0
                                                ? 's'
                                                : ''
                                        } · `}
                                        color="#FFA400"
                                    />
                                </Grid>
                                <Grid item>
                                    <SubHeaders
                                        text={`Ends ${moment(
                                            props.eventDetails
                                                ?.event_end_datetime
                                        ).format('DD MMM')}`}
                                        color="#4B86AA"
                                    />
                                </Grid>
                            </Grid>
                        ) : (
                            <Grid container flexWrap={'nowrap'}>
                                <Grid item>
                                    <SubHeaders
                                        text={`${props.eventDetails?.sub_event_additional_details?.item_count} items · `}
                                        color="#4B86AA"
                                    />
                                </Grid>
                                <Grid item>
                                    <SubHeaders
                                        text={`Ends ${moment(
                                            props.eventDetails
                                                ?.event_end_datetime
                                        ).format('DD MMM')} · `}
                                        color="#FFA400"
                                    />
                                </Grid>
                                <Grid
                                    item
                                    sx={{
                                        maxWidth: '38%',
                                    }}
                                >
                                    <SubHeaders
                                        text={`${props.eventDetails?.buyer_entity?.buyer_entity_name}`}
                                        color="#4B86AA"
                                    />
                                </Grid>
                            </Grid>
                        )}
                    </Box>
                ) : (
                    <Box className="flex flex--jcsb" padding="5px">
                        <Box>
                            <Box
                                bgcolor={'background.paper'}
                                width="250px"
                                height="50px"
                            />
                        </Box>
                        <Box className="flex flex--aic">
                            <i className="bi bi-chevron-right"></i>
                        </Box>
                    </Box>
                )
            }
        </StyledBox>
    );
}

// export memoized component
export default React.memo(ChatEventDetailsRow);
