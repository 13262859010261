import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    LineElement,
    PointElement,
    ChartOptions,
    ChartData,
} from 'chart.js';
import { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { ChartLocaleMap } from '../../../Constants/ChartLocaleMap';
import { graphColor } from '../../../Constants/HAGraphColor';
import { DynamicAnalyticsVendorItemsBoughtData } from '../../../Interfaces/DynamicAnalyticsInterfaces.model';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { max } from 'lodash';
import {
    getStepSize,
    numberToAbbreviation,
} from '../../../helperFunctions/chartHelpers';

ChartJS.register(
    CategoryScale,
    LinearScale,
    LineElement,
    PointElement,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export default function ItemsBoughtGraph({
    visibleData,
    number_format,
    sellerEntityName,
    currencyCodeSymbol,
}: {
    visibleData: DynamicAnalyticsVendorItemsBoughtData[];
    number_format: string;
    sellerEntityName: string;
    currencyCodeSymbol: string;
}) {
    const [graphData, setGraphData] = useState<{
        xLabels: string[];
        bar1Data: (number | null)[];
        bar2Data: (number | null)[];
        bubbleData: (number | null)[];
    }>({
        xLabels: [],
        bar1Data: [],
        bar2Data: [],
        bubbleData: [],
    });

    const [currencyUnit, setCurrencyUnit] = useState<string | null>(null);

    useEffect(() => {
        let xLabels: string[] = [];
        let bar1Data: (number | null)[] = [];
        let bar2Data: (number | null)[] = [];
        let bubbleData: (number | null)[] = [];
        visibleData.forEach((item) => {
            xLabels.push(item.itemDetails.name);
            bar1Data.push(item.poValueThisVendor);
            bar2Data.push(item.poValueOtherVendors);
            bubbleData.push(item.vendorCount);
        });
        const maxVal1 = max(
            bar1Data
                .map((val, i) => (val || 0) + (bar2Data[i] || 0))
                .filter((val) => val !== null)
        );
        const maxVal2 = max(
            bar1Data
                .map((val, i) => (val || 0) + (bar2Data[i] || 0))
                .filter((val) => val !== null)
        );

        const {
            abbrevitedNumberArr: abbrevitedNumberArr1,
            currencyUnit: currencyUnit1,
        } = numberToAbbreviation(
            maxVal1 || 0,
            bar1Data,
            number_format === 'EUR' ? 'USD' : (number_format as 'USD' | 'INR')
        );
        const { abbrevitedNumberArr: abbrevitedNumberArr2 } =
            numberToAbbreviation(
                maxVal2 || 0,
                bar1Data,
                number_format === 'EUR'
                    ? 'USD'
                    : (number_format as 'USD' | 'INR')
            );

        setCurrencyUnit(currencyUnit1);
        bar1Data = abbrevitedNumberArr1;
        bar2Data = abbrevitedNumberArr2;

        setGraphData({
            xLabels,
            bar1Data,
            bar2Data,
            bubbleData,
        });
    }, [number_format, visibleData]);

    var stepSize = getStepSize(
        max(
            graphData.bar1Data
                .map((val, i) => (val || 0) + (graphData.bar2Data[i] || 0))
                .filter((val) => val !== null)
        ) || 0,
        6
    );

    const options: ChartOptions<any> = {
        locale:
            ChartLocaleMap[number_format as 'INR' | 'USD' | 'EUR'] || 'en-US',
        responsive: true,
        plugins: {
            legend: {
                position: 'top' as const,
            },
            datalabels: {
                backgroundColor: function (context: any) {
                    return 'purple';
                },
                borderRadius: 15,
                color: 'white',
                font: {
                    weight: 'bold',
                },

                formatter: (value: any, context: any) => {
                    const num = graphData.bubbleData[context.dataIndex];
                    //
                    return ` ${num || 0 % 1 === 0 ? num : num?.toFixed(2)} `;
                },
                padding: 4,
                anchor: 'center', // set anchor to end
                align: 'end',
            },
        },
        scales: {
            y: {
                grace: '30%',
                stacked: true,
                type: 'linear',
                display: true,
                position: 'left',
                title: {
                    display: true,
                    text: `PO amount ${
                        currencyUnit ? `(In ${currencyUnit})` : ''
                    }`,
                },
                // max: graphMaxVal([
                //     ...graphData.bar1Data,
                //     ...graphData.bar2Data,
                // ]),
                min: 0,
                ticks: {
                    stepSize: stepSize,
                    callback: function (
                        val: string | number,
                        index: number
                    ): string {
                        return typeof val === 'number'
                            ? `${currencyCodeSymbol}${(
                                  this as any
                              ).getLabelForValue(val)}`
                            : '';
                    },
                },
            },
            x: {
                stacked: true,
                title: {
                    display: true,
                    text: 'Items',
                },
                grid: {
                    display: false, // hide the vertical grid lines for the x-axis
                },
                ticks: {
                    callback: function (
                        val: string | number,
                        index: number
                    ): string {
                        return typeof val === 'number'
                            ? (this as any)
                                  .getLabelForValue(val)
                                  .substring(0, 10)
                            : '';
                    },
                },
            },
        },
    };

    const labels: any[] = graphData.xLabels;

    const data: ChartData<any> = {
        labels,
        datasets: [
            {
                label: `${sellerEntityName}`,
                // data: graphData.bar1Data,
                data: graphData.bar1Data,
                type: 'bar',
                backgroundColor: graphColor[0],
                borderWidth: 0,
                stack: 'stack1',
                datalabels: {
                    labels: {
                        title: null, //hides datalabel for this metric
                    },
                },
                maxBarThickness: 80,
            },
            {
                label: 'Other Vendors',

                data: graphData.bar2Data,
                // data: [110, 115],
                type: 'bar',
                backgroundColor: graphColor[1],
                borderWidth: 0,
                stack: 'stack1',
                datalabels: {
                    labels: {
                        title: null,
                    },
                },
                maxBarThickness: 80,
            },
            {
                label: 'Vendor count',
                type: 'line',
                data: graphData.bubbleData.map(
                    (val, i) =>
                        (graphData.bar1Data[i] || 0) +
                        (graphData.bar2Data[i] || 0)
                    // ? graphData.bar1Data[i]
                    // : graphData.bar2Data[i]
                ),
                backgroundColor: 'purple',
                borderWidth: 0,
                pointRadius: 0,
                hoverBackgroundColor: 'transparent', // Disable hover background color
                hoverBorderColor: 'transparent', // Disable hover border color
                hitRadius: 0, // Disable the mouse click detection for the points
                hoverRadius: 0, // Disable the point radius increase on hover
                showTooltip: false, // Disable the tooltip for this dataset
            },
        ],
    };

    return <Bar options={options} data={data} plugins={[ChartDataLabels]} />;
}
