import { Avatar, Chip } from '@mui/material';
import React from 'react';
import { FWTypography } from '../../../../Common/FWTypograhy';
import { IVLNEmployees } from '../../../Interfaces/VendorLNInterfaces';

const VLNProfileAboutEmployeesComponent = ({
    employeeData,
}: {
    employeeData: IVLNEmployees;
}) => {
    return (
        <div className="flex flex--aifs">
            <Avatar
                src={''}
                sx={{
                    width: '3.5rem',
                    height: '3.5rem',
                    marginRight: '1rem',
                }}
            />
            <div>
                <div className="flex flex--aic">
                    <FWTypography color={'text.secondary'}>
                        {employeeData.name}
                    </FWTypography>
                    &nbsp;
                    {employeeData.isPrimary && (
                        <Chip
                            label="Primary"
                            color={'success'}
                            size={'small'}
                            sx={{ color: 'white', fontSize: 12 }}
                        />
                    )}
                </div>
                <FWTypography>
                    {employeeData?.email} ·{' '}
                    {employeeData?.contacts?.phone_numbers?.[0]?.phone || '-'}
                </FWTypography>
            </div>
        </div>
    );
};

export default VLNProfileAboutEmployeesComponent;
