import { IAttachment } from '../../../Models/RFQ.model';

export enum IdentificationStatus {
    VERIFIED = 'VERIFIED',
    PENDING = 'PENDING',
    UNVERIFIED = 'UNVERIFIED',
}

export interface IIdentificationEntityAddress {
    entity_address: {
        entry_id: string;
        address: {
            address_id: string;
            address_nickname: string;
            enterprise_id: string;
            enterprise_name: string;
            country: string;
            country_name: string;
            address1: string;
            address2: string;
            address3: string;
            city: string;
            state_or_territory: string;
            postal_code: string;
            latitude: null;
            longitude: null;
            address_url: string;
        };
    };
}

export interface IIdentificationDetail {
    index?: number;
    id: string;
    name: string;
    value: string;
    isDefault: boolean;
    country?: string;
    isPublic: boolean;
    status: IdentificationState;
    notes?: string;
    entityId?: string;
    identificationStatus: IdentificationStatus;
    attachments: IAttachment[];
    linked_addresses: IIdentificationEntityAddress[];
}

export enum IdentificationState {
    ALL = 'ALL',
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
}

export interface IIdentificationCreateDetails {
    name: string;
    value: string;
    isDefault: boolean;
    isPublic: boolean;
    status: IdentificationState;
    notes: string;
}
