import { Box, Divider, IconButton } from '@mui/material';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FWMenu, FWMenuItem } from '../../Common/FWCustomMenu';
import { FWTooltip } from '../../Common/FWCustomTooltip';
import FWIcon from '../../Common/FWIcon';

const actionPaths = {
    addItemPath: '/admin/items/item-directory/add/new',
    addVendorPath: '/admin/vendors',
    addUsersPath: '/admin/users',
    manageAddressesPath: '/admin/address',
    settingsPath: '/admin/settings',
};

const addActions = [
    {
        text: 'Add item(s)',
        value: 'ADD_ITEM',
        path: actionPaths.addItemPath,
    },
    {
        text: 'Add vendor(s)',
        value: 'ADD_VENDOR',
        path: actionPaths.addVendorPath,
    },
    {
        text: 'Add user(s)',
        value: 'ADD_USERS',
        path: actionPaths.addUsersPath,
    },
];

const moreOptionsActions = [
    {
        text: 'Manage addresses',
        value: 'MANAGE_ADDRESSES',
        path: actionPaths.manageAddressesPath,
    },
    {
        text: 'Settings',
        value: 'SETTINGS',
        path: actionPaths.settingsPath,
    },
];

const AdminActionsContainer = () => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) =>
        setAnchorEl(event.currentTarget);
    const handleClose = () => {
        setAnchorEl(null);
        setTimeout(() => {
            setMoreOptionsClicked(false);
        }, 150);
    };

    const history = useHistory();
    const { pathname } = history.location;

    const [adminAction, setAdminAction] = useState<
        | 'ADD_ITEM'
        | 'ADD_VENDOR'
        | 'ADD_USERS'
        | 'MANAGE_ADDRESSES'
        | 'SETTINGS'
        | null
    >(null);

    const [moreOptionsClicked, setMoreOptionsClicked] = useState(false);

    useEffect(() => {
        if (pathname.includes(actionPaths.addItemPath)) {
            setAdminAction('ADD_ITEM');
        } else if (pathname.includes(actionPaths.addVendorPath)) {
            setAdminAction('ADD_VENDOR');
        } else if (pathname.includes(actionPaths.addUsersPath)) {
            setAdminAction('ADD_USERS');
        } else if (pathname.includes(actionPaths.manageAddressesPath)) {
            setAdminAction('MANAGE_ADDRESSES');
        } else if (pathname.includes(actionPaths.settingsPath)) {
            setAdminAction('SETTINGS');
        } else {
            setAdminAction(null);
        }
    }, [pathname]);

    return (
        <>
            <FWTooltip title="Admin actions">
                <Box className="flex flex--aic">
                    <IconButton color="primary" onClick={handleClick}>
                        <FWIcon name="bi bi-gear" size={23} />
                    </IconButton>
                </Box>
            </FWTooltip>
            <FWMenu
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                sx={{
                    maxHeight: '80vh',
                    padding: 0,
                    top: '4px',
                    width: 230,
                }}
            >
                {addActions.map(({ text, path, value }, idx) => (
                    <FWMenuItem
                        key={idx}
                        value={value}
                        onClick={() => {
                            if (adminAction !== value) {
                                history.push({
                                    pathname: path,
                                });
                            }
                            localStorage.setItem(
                                'openAdminActionPopup',
                                JSON.stringify({
                                    popUpToOpen: value,
                                    openPopup: true,
                                })
                            );
                            handleClose();
                        }}
                    >
                        {text}
                    </FWMenuItem>
                ))}
                <Divider />

                <Box
                    sx={{
                        height: moreOptionsClicked ? '70px' : '0px',
                        overflow: 'hidden',
                        transition: 'height 0.3s ease-in-out',
                    }}
                >
                    {moreOptionsActions.map(({ text, path, value }, idx) => (
                        <FWMenuItem
                            key={idx}
                            value={value}
                            onClick={() => {
                                history.push(path);
                                handleClose();
                            }}
                            disabled={adminAction === value}
                        >
                            {text}
                        </FWMenuItem>
                    ))}
                </Box>
                {!moreOptionsClicked && (
                    <FWMenuItem
                        value={'MORE_OPTIONS'}
                        onClick={() => setMoreOptionsClicked((prev) => !prev)}
                    >
                        <Box color="primary.main">
                            {moreOptionsClicked ? 'Less' : 'More'} options
                        </Box>
                    </FWMenuItem>
                )}
            </FWMenu>
        </>
    );
};

export default AdminActionsContainer;
