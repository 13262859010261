import { Box, Chip, CircularProgress } from '@mui/material';
import moment from 'moment';
import React, {
    Fragment,
    memo,
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react';
import { useSelector, useDispatch, batch } from 'react-redux';
import { toast } from 'react-toastify';
import FWScrollToBottom from '../../Common/FWScrollToBottom';
import { getFWDate } from '../../Common/Utils/DateUtils';
import { AuthContext } from '../../Contexts/AuthContext';
import { IGlobalAppStore } from '../../Redux/Store';
import ChatMessageRows from '../Components/ChatMessageRows';
import { ISessionMessage } from '../Interfaces/Chat.model';
import {
    acknowledgeSessionMessages,
    fetchNoteMessages,
    fetchSessionMessages,
} from '../Services/chat.service';
import {
    updateReceivedMessages,
    updateUnreadMsgCount,
} from '../Slice/Chat.slice';

interface IChatMessageListContainer {
    session_id: string;
    module_id: string;
    openedFromPopup: boolean;
    floatingChat?: boolean;
    lastReadMsgId: string;
    noteId: { id: string; status: 'ACTIVE' | 'INACTIVE' } | null;
    // setLastReadMsgId: React.Dispatch<React.SetStateAction<string>>;
}

const getTimeDifferences = (today: string, yesterday: string | null) => {
    if (yesterday === null) {
        return {
            isADayApart: false,
            isWithin15Minutes: false,
        };
    } else {
        let moment_yesterday = moment(yesterday);
        let moment_today = moment(today);
        return {
            isADayApart: moment_today.date() > moment_yesterday.date(),
            isWithin15Minutes:
                moment_today.diff(moment_yesterday, 'minutes') <= 15,
        };
    }
};

const ChatMessageListContainerMain = ({
    session_id,
    module_id,
    openedFromPopup,
    floatingChat,
    messageList,
    current_user_id,
    lastReadMsgId,
    newMessageRef,
}: {
    session_id: string;
    module_id: string;
    openedFromPopup: boolean;
    floatingChat?: boolean;
    messageList: ISessionMessage[] | null;
    current_user_id: string;
    lastReadMsgId: string;
    newMessageRef: React.RefObject<HTMLDivElement>;
}) => {
    return (
        <>
            {/* {!showScrollDwnBtn && ( */}
            {/* )} */}
            {messageList !== null &&
                messageList.map((message, idx) => {
                    let isUsersOwnChat = message.sender_id === current_user_id;
                    let senderSameAsPrevChat =
                        idx < 1
                            ? false
                            : messageList[idx - 1].sender_id ===
                              message.sender_id;
                    let timeDiff = getTimeDifferences(
                        messageList[idx].created_datetime,
                        idx > 0 ? messageList[idx - 1].created_datetime : null
                    );
                    return (
                        <Fragment key={idx}>
                            {(idx === 0 || timeDiff.isADayApart) && (
                                <Box
                                    className="flex flex--jcc"
                                    margin="0.5rem 0"
                                    sx={{
                                        scale:
                                            floatingChat || openedFromPopup
                                                ? '0.8'
                                                : 1,
                                    }}
                                >
                                    <Chip
                                        sx={{ color: 'grey' }}
                                        label={getFWDate(
                                            messageList[idx].created_datetime
                                        )}
                                    />
                                </Box>
                            )}
                            <ChatMessageRows
                                boxPosition={isUsersOwnChat ? 'RIGHT' : 'LEFT'}
                                showSenderName={
                                    // show sender name if its not the users own chat and sender is same as previous chat
                                    isUsersOwnChat
                                        ? false
                                        : !senderSameAsPrevChat
                                }
                                reduceMargins={
                                    senderSameAsPrevChat &&
                                    !timeDiff.isADayApart &&
                                    timeDiff.isWithin15Minutes
                                }
                                key={idx}
                                message_details={message}
                                containerSelector={'chat-list'}
                                index={idx}
                                openedFromPopup={openedFromPopup}
                                floatingChat={floatingChat}
                                lastReadMsgId={lastReadMsgId}
                                lastMsgCount={(() => {
                                    const index = messageList.findIndex(
                                        (msg) =>
                                            msg.message_id === lastReadMsgId
                                    );
                                    return messageList.length - index - 1;
                                })()}
                                newMessageRef={newMessageRef}
                            />
                        </Fragment>
                    );
                })}
        </>
    );
};

// using memoised component because setShowScrollDwnBtn state was rerendering the whole message list which was causing the scroll to lag
const MemoizedChatMessageListContainerMain = memo(ChatMessageListContainerMain);

export default function ChatMessageListContainer(
    props: IChatMessageListContainer
) {
    const [messageList, setMessageList] = useState<ISessionMessage[] | null>(
        null
    );
    // get list of messagesReceived from chat store
    const messagesReceived = useSelector(
        (state: IGlobalAppStore) => state.ChatStore.messagesReceived
    );
    const dispatch = useDispatch();
    const { authData } = useContext(AuthContext);
    const current_user_id =
        authData.details === null ? '' : authData.details.user_id;

    const getMessages = useCallback(() => {
        setMessageList([]);
        fetchSessionMessages(props.session_id)
            .then((messages) => {
                setMessageList(messages);
                if (
                    props.session_id !== '' &&
                    messages[messages.length - 1]?.message_id
                ) {
                    acknowledgeSessionMessages({
                        session_id: props.session_id,
                        message_id: messages[messages.length - 1]?.message_id,
                    });
                }
            })
            .catch((err) => {
                toast.error("Couldn't fetch messages");
            });
    }, [props.session_id]);

    // useEffect(() => {
    //     getMessages();
    // }, [getMessages]);

    useEffect(() => {
        if (props.noteId && props.noteId.id !== 'SELF') {
            setMessageList([]);
            fetchNoteMessages(props.noteId.id)
                .then((messages) => {
                    //
                    setMessageList(messages);
                })
                .catch((err) => {
                    toast.error("Couldn't fetch messages");
                });
        } else {
            getMessages();
        }
    }, [getMessages, props.noteId]);

    // we listen to newly arriving messages
    // and if there are new msgs we check whether they belong to currently opened session
    // if yes we update the msg list and also update the sent messages in slice
    useEffect(() => {
        let read_messages: string[] = []; // list of message ids to be marked as read
        let currentSessionMsgToRead = messagesReceived.filter((msg) => {
            if (msg.session_id === props.session_id || !msg.session_id) {
                // '!msg.session_id' is for note messages
                read_messages.push(msg.message_id);
                return true;
            }
            return false;
        });
        if (read_messages.length > 0) {
            batch(() => {
                dispatch(updateReceivedMessages(read_messages));
                dispatch(
                    updateUnreadMsgCount({
                        module_id: props.module_id,
                        session_id: props.session_id,
                    })
                );
            });
        } else {
            dispatch(
                updateUnreadMsgCount({
                    module_id: props.module_id,
                    session_id: props.session_id,
                })
            );
        }
        setMessageList((prev) =>
            prev === null ? prev : prev.concat(currentSessionMsgToRead)
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [messagesReceived, dispatch, props.session_id, props.module_id]);

    const newMessageRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (
            messageList !== null &&
            messageList.length > 0 &&
            props.lastReadMsgId.length > 0 &&
            newMessageRef.current !== null
        ) {
            // //
            newMessageRef.current?.scrollIntoView({ block: 'center' });
            // newMessageRef.current?.scrollBy(0, -100);
        }
    }, [props.lastReadMsgId.length, messageList]);

    if (messageList === null) {
        return (
            <Box flex={1} overflow="auto" className="flex flex--aic flex--jcc ">
                <CircularProgress color="primary" size={60} />
            </Box>
        );
    }

    return (
        <>
            {/* {showScrollDwnBtn && (
                <StyledBox onClick={handleScrollToBottomClick}>
                    <Box color={'gray'}>
                        <i className="bi bi-chevron-down"></i>
                    </Box>
                </StyledBox>
            )} */}
            <FWScrollToBottom
                variablesToCheck={[messageList, props.session_id]}
                style={{
                    position: 'relative',
                    zIndex: 0,
                }}
            >
                <MemoizedChatMessageListContainerMain
                    {...props}
                    messageList={messageList}
                    current_user_id={current_user_id}
                    newMessageRef={newMessageRef}
                />
            </FWScrollToBottom>
        </>
    );
}
