import styled from '@emotion/styled';
import { Box } from '@mui/system';

export const StyledBox = styled(Box)({
    '&': {
        overflow: 'hidden',
        transition: 'all 1s ease-in-out',
        position: 'relative',
    },
    '& .fade': {
        position: 'absolute',
        width: '100%',
        height: '20px',
        background: 'blue',
    },
});

export const StyledGradient = styled(Box)({
    '&': { position: 'absolute', width: '100%' },
    '& .gradient': {
        position: 'absolute',
        width: '100%',
        height: 100,
        background:
            'linear-gradient(0deg, rgba(255,255,255,1) 15%, rgba(0,212,255,0) 100%)',
        bottom: 0,
    },
});
