import { Box, Grid } from '@mui/material';
import { isEqual } from 'lodash';
import { FWTooltip } from '../../../../Common/FWCustomTooltip';
import { FWTypography } from '../../../../Common/FWTypograhy';
import SingleFieldTypeRenderer from '../../../../FieldTypes/components/SingleFieldTypeRenderer';
import SingleDateFieldRenderer from '../../../../ProjectGlCostCenter/components/SingleDateFieldRenderer';
import { CustomFieldType } from '../../../../SRM/Constants/Interfaces/documentTemplate';
import {
    IItemField,
    IItemTemplateCustomField,
} from '../../Helpers/ItemHelpers';
import { IINewItemTemplate } from '../../Hooks/ItemDirectory/ItemAdditionalCostHook';
import {
    ItemReducerAction,
    ItemUpdateActions,
} from '../../Hooks/ItemDirectory/ItemDirectoryHook';
import { IItemCustomFieldsBackend } from '../../Interfaces/ItemDirectoryInterface';

interface IItemCustomFieldsProps {
    customFields: IItemCustomFieldsBackend;
    handleChange: (action: ItemReducerAction) => void;
    newItemTemplateSectionList: IINewItemTemplate | undefined;
    customFieldTemplateSetting: {
        [field: string]: IItemField;
    };
}

const ItemCustomFields = ({
    customFields,
    handleChange,
    customFieldTemplateSetting,
    newItemTemplateSectionList,
}: IItemCustomFieldsProps) => {
    const handleCustomFieldUpdate = (
        customField: IItemTemplateCustomField,
        sectionName: string,
        newValue: string | string[] | boolean | number
    ) => {
        const currSection = customFields.section_list.find(
            (sectionItem) => sectionItem.name === sectionName
        );

        if (currSection) {
            let currField = currSection.fields.find(
                (field) => field.name === customField.name
            );

            if (currField) {
                currField.value = newValue;
            }
        }

        handleChange({
            type: ItemUpdateActions.CUSTOM_FIELDS,
            value: customFields,
        });
    };

    return (
        <>
            {customFields.section_list?.map((section) => {
                return (
                    <Grid
                        container
                        sx={{
                            display: 'grid',
                            gridTemplateColumns: 'repeat(4, 1fr)',
                            gap: '10px',
                        }}
                    >
                        {section.fields.map((customField) => {
                            let fieldSettingFromTemplate =
                                customFieldTemplateSetting[customField.name];

                            switch (
                                fieldSettingFromTemplate?.constraints
                                    ?.field_type
                            ) {
                                case 'SHORTTEXT':
                                    return (
                                        <Grid>
                                            <Grid
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: '0.5rem',
                                                }}
                                            >
                                                <FWTypography
                                                    color={'text.secondary'}
                                                >
                                                    {customField.name}{' '}
                                                    {!fieldSettingFromTemplate.is_required &&
                                                        '(optional)'}
                                                </FWTypography>
                                                {customField?.description && (
                                                    <FWTooltip
                                                        title={
                                                            customField?.description
                                                        }
                                                        placement="right"
                                                    >
                                                        <Box color="primary.main">
                                                            <i className="bi bi-question-circle" />
                                                        </Box>
                                                    </FWTooltip>
                                                )}
                                            </Grid>
                                            <SingleFieldTypeRenderer
                                                fieldType={
                                                    fieldSettingFromTemplate
                                                        .constraints.field_type
                                                }
                                                constraints={{
                                                    fieldType:
                                                        CustomFieldType.SHORTTEXT,
                                                    maxLimit:
                                                        fieldSettingFromTemplate
                                                            .constraints
                                                            .max_limit,
                                                    minLimit:
                                                        fieldSettingFromTemplate
                                                            .constraints
                                                            .min_limit,
                                                }}
                                                value={
                                                    customField.value as string
                                                }
                                                errors={
                                                    fieldSettingFromTemplate.is_required &&
                                                    ((typeof customField.value ===
                                                        'string' &&
                                                        customField.value?.trim()) ===
                                                        '' ||
                                                        customField.value ===
                                                            null)
                                                }
                                                helper={{
                                                    text:
                                                        fieldSettingFromTemplate.is_required &&
                                                        ((typeof customField.value ===
                                                            'string' &&
                                                            customField.value?.trim()) ===
                                                            '' ||
                                                            customField.value ===
                                                                null)
                                                            ? 'Field is requied'
                                                            : '',
                                                }}
                                                updateHandler={(
                                                    newValue: string
                                                ) => {
                                                    handleCustomFieldUpdate(
                                                        customField,
                                                        section.name,
                                                        newValue
                                                    );
                                                }}
                                            />
                                        </Grid>
                                    );
                                case 'LONGTEXT':
                                    return (
                                        <Grid>
                                            <Grid
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: '0.5rem',
                                                }}
                                            >
                                                <FWTypography
                                                    color={'text.secondary'}
                                                >
                                                    {customField.name}{' '}
                                                    {!fieldSettingFromTemplate.is_required &&
                                                        '(optional)'}
                                                </FWTypography>
                                                {customField?.description && (
                                                    <FWTooltip
                                                        title={
                                                            customField?.description
                                                        }
                                                        placement="right"
                                                    >
                                                        <Box color="primary.main">
                                                            <i className="bi bi-question-circle" />
                                                        </Box>
                                                    </FWTooltip>
                                                )}
                                            </Grid>
                                            <SingleFieldTypeRenderer
                                                fieldType={
                                                    fieldSettingFromTemplate
                                                        .constraints.field_type
                                                }
                                                constraints={{
                                                    fieldType:
                                                        CustomFieldType.LONGTEXT,
                                                    maxLimit:
                                                        fieldSettingFromTemplate
                                                            .constraints
                                                            .max_limit,
                                                    minLimit:
                                                        fieldSettingFromTemplate
                                                            .constraints
                                                            .min_limit,
                                                }}
                                                value={
                                                    customField.value as string
                                                }
                                                errors={
                                                    fieldSettingFromTemplate.is_required &&
                                                    ((typeof customField.value ===
                                                        'string' &&
                                                        customField.value?.trim()) ===
                                                        '' ||
                                                        customField.value ===
                                                            null)
                                                }
                                                helper={{
                                                    text:
                                                        fieldSettingFromTemplate.is_required &&
                                                        ((typeof customField.value ===
                                                            'string' &&
                                                            customField.value?.trim()) ===
                                                            '' ||
                                                            customField.value ===
                                                                null)
                                                            ? 'Field is requied'
                                                            : '',
                                                }}
                                                updateHandler={(
                                                    newValue: string
                                                ) => {
                                                    handleCustomFieldUpdate(
                                                        customField,
                                                        section.name,
                                                        newValue
                                                    );
                                                }}
                                            />
                                        </Grid>
                                    );
                                case 'DATE':
                                case 'DATETIME':
                                    return (
                                        <Grid>
                                            <Grid
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: '0.5rem',
                                                }}
                                            >
                                                <FWTypography
                                                    color={'text.secondary'}
                                                >
                                                    {customField.name}{' '}
                                                    {!fieldSettingFromTemplate.is_required &&
                                                        '(optional)'}{' '}
                                                </FWTypography>
                                                {customField?.description && (
                                                    <FWTooltip
                                                        title={
                                                            customField?.description
                                                        }
                                                        placement="right"
                                                    >
                                                        <Box color="primary.main">
                                                            <i className="bi bi-question-circle" />
                                                        </Box>
                                                    </FWTooltip>
                                                )}
                                            </Grid>
                                            <SingleDateFieldRenderer
                                                value={
                                                    customField.value as string
                                                }
                                                rendererProperty={{
                                                    gridSize: 12,
                                                    label: '',
                                                    required: true,
                                                    type: 'DATE',
                                                    is_hidden: false,
                                                }}
                                                error={
                                                    fieldSettingFromTemplate.is_required &&
                                                    !Boolean(customField.value)
                                                }
                                                helper={{
                                                    text:
                                                        fieldSettingFromTemplate.is_required &&
                                                        !Boolean(
                                                            customField.value
                                                        )
                                                            ? 'Field is requied'
                                                            : '',
                                                }}
                                                updateHandler={(
                                                    newValue: string | null
                                                ) => {
                                                    if (newValue)
                                                        handleCustomFieldUpdate(
                                                            customField,
                                                            section.name,
                                                            newValue
                                                        );
                                                }}
                                            />
                                        </Grid>
                                    );
                                case 'FLOAT':
                                    return (
                                        <Grid>
                                            <Grid
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: '0.5rem',
                                                }}
                                            >
                                                <FWTypography
                                                    color={'text.secondary'}
                                                >
                                                    {customField.name}{' '}
                                                    {!fieldSettingFromTemplate.is_required &&
                                                        '(optional)'}
                                                </FWTypography>
                                                {customField?.description && (
                                                    <FWTooltip
                                                        title={
                                                            customField?.description
                                                        }
                                                        placement="right"
                                                    >
                                                        <Box color="primary.main">
                                                            <i className="bi bi-question-circle" />
                                                        </Box>
                                                    </FWTooltip>
                                                )}
                                            </Grid>

                                            <SingleFieldTypeRenderer
                                                fieldType={
                                                    fieldSettingFromTemplate
                                                        .constraints.field_type
                                                }
                                                constraints={{
                                                    fieldType:
                                                        CustomFieldType.FLOAT,
                                                    maxLimit:
                                                        fieldSettingFromTemplate
                                                            .constraints
                                                            .max_limit,
                                                    minLimit:
                                                        fieldSettingFromTemplate
                                                            .constraints
                                                            .min_limit,
                                                }}
                                                value={
                                                    customField.value as string
                                                }
                                                errors={
                                                    fieldSettingFromTemplate.is_required &&
                                                    ((typeof customField.value ===
                                                        'string' &&
                                                        customField.value?.trim()) ===
                                                        '' ||
                                                        customField.value ===
                                                            null)
                                                }
                                                helper={{
                                                    text:
                                                        fieldSettingFromTemplate.is_required &&
                                                        ((typeof customField.value ===
                                                            'string' &&
                                                            customField.value?.trim()) ===
                                                            '' ||
                                                            customField.value ===
                                                                null)
                                                            ? 'Field is requied'
                                                            : '',
                                                }}
                                                updateHandler={(
                                                    newValue: string
                                                ) => {
                                                    handleCustomFieldUpdate(
                                                        customField,
                                                        section.name,
                                                        newValue
                                                    );
                                                }}
                                            />
                                        </Grid>
                                    );
                                case 'INTEGER':
                                    return (
                                        <Grid>
                                            <Grid
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: '0.5rem',
                                                }}
                                            >
                                                <FWTypography
                                                    color={'text.secondary'}
                                                >
                                                    {customField.name}{' '}
                                                    {!fieldSettingFromTemplate.is_required &&
                                                        '(optional)'}
                                                </FWTypography>
                                                {customField?.description && (
                                                    <FWTooltip
                                                        title={
                                                            customField?.description
                                                        }
                                                        placement="right"
                                                    >
                                                        <Box color="primary.main">
                                                            <i className="bi bi-question-circle" />
                                                        </Box>
                                                    </FWTooltip>
                                                )}
                                            </Grid>
                                            <SingleFieldTypeRenderer
                                                fieldType={
                                                    fieldSettingFromTemplate
                                                        .constraints.field_type
                                                }
                                                constraints={{
                                                    fieldType:
                                                        CustomFieldType.INTEGER,
                                                    maxLimit:
                                                        fieldSettingFromTemplate
                                                            .constraints
                                                            .max_limit,
                                                    minLimit:
                                                        fieldSettingFromTemplate
                                                            .constraints
                                                            .min_limit,
                                                }}
                                                value={
                                                    customField.value as string
                                                }
                                                errors={
                                                    fieldSettingFromTemplate.is_required &&
                                                    ((typeof customField.value ===
                                                        'string' &&
                                                        customField.value?.trim()) ===
                                                        '' ||
                                                        customField.value ===
                                                            null)
                                                }
                                                helper={{
                                                    text:
                                                        fieldSettingFromTemplate.is_required &&
                                                        ((typeof customField.value ===
                                                            'string' &&
                                                            customField.value?.trim()) ===
                                                            '' ||
                                                            customField.value ===
                                                                null)
                                                            ? 'Field is requied'
                                                            : '',
                                                }}
                                                updateHandler={(
                                                    newValue: string
                                                ) => {
                                                    handleCustomFieldUpdate(
                                                        customField,
                                                        section.name,
                                                        newValue
                                                    );
                                                }}
                                            />
                                        </Grid>
                                    );
                                case 'BOOLEAN':
                                    return (
                                        <Grid sx={{ width: 'fit-content' }}>
                                            <Grid
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: '0.5rem',
                                                }}
                                            >
                                                <FWTypography
                                                    color={'text.secondary'}
                                                >
                                                    {customField.name}

                                                    {!fieldSettingFromTemplate.is_required &&
                                                        '(optional)'}
                                                </FWTypography>
                                                {customField?.description && (
                                                    <FWTooltip
                                                        title={
                                                            customField?.description
                                                        }
                                                        placement="right"
                                                    >
                                                        <Box color="primary.main">
                                                            <i className="bi bi-question-circle" />
                                                        </Box>
                                                    </FWTooltip>
                                                )}
                                            </Grid>

                                            <SingleFieldTypeRenderer
                                                fieldType={'BOOLEAN'}
                                                constraints={{
                                                    fieldType:
                                                        CustomFieldType.BOOLEAN,
                                                }}
                                                value={
                                                    customField.value as boolean
                                                }
                                                errors={
                                                    fieldSettingFromTemplate.is_required &&
                                                    customField.value === null
                                                }
                                                helper={{
                                                    text:
                                                        fieldSettingFromTemplate.is_required &&
                                                        customField.value ===
                                                            null
                                                            ? 'Field is requied'
                                                            : '',
                                                }}
                                                updateHandler={(
                                                    newValue: boolean
                                                ) => {
                                                    handleCustomFieldUpdate(
                                                        customField,
                                                        section.name,
                                                        newValue
                                                    );
                                                }}
                                            />
                                        </Grid>
                                    );
                                case 'CHOICE':
                                    if (
                                        fieldSettingFromTemplate?.constraints
                                            .choice_type === 'DROPDOWN'
                                    ) {
                                        return (
                                            <Grid>
                                                <Grid
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        gap: '0.5rem',
                                                    }}
                                                >
                                                    <FWTypography
                                                        color={'text.secondary'}
                                                    >
                                                        {customField.name}{' '}
                                                        {!fieldSettingFromTemplate.is_required &&
                                                            '(optional)'}
                                                    </FWTypography>
                                                    {customField?.description && (
                                                        <FWTooltip
                                                            title={
                                                                customField?.description
                                                            }
                                                            placement="right"
                                                        >
                                                            <Box color="primary.main">
                                                                <i className="bi bi-question-circle" />
                                                            </Box>
                                                        </FWTooltip>
                                                    )}
                                                </Grid>
                                                <SingleFieldTypeRenderer
                                                    fieldType={
                                                        fieldSettingFromTemplate
                                                            ?.constraints
                                                            .field_type
                                                    }
                                                    constraints={{
                                                        fieldType:
                                                            CustomFieldType.CHOICE,
                                                        choices:
                                                            fieldSettingFromTemplate
                                                                ?.constraints
                                                                .choices ?? [],
                                                        choiceType:
                                                            fieldSettingFromTemplate
                                                                ?.constraints
                                                                .choice_type,
                                                    }}
                                                    value={
                                                        customField?.value as string
                                                    }
                                                    errors={
                                                        fieldSettingFromTemplate.is_required &&
                                                        !Boolean(
                                                            customField.value
                                                        )
                                                    }
                                                    helper={{
                                                        text:
                                                            fieldSettingFromTemplate.is_required &&
                                                            !Boolean(
                                                                customField.value
                                                            )
                                                                ? 'Field is requied'
                                                                : '',
                                                    }}
                                                    updateHandler={(
                                                        newValue: string
                                                    ) => {
                                                        handleCustomFieldUpdate(
                                                            customField,
                                                            section.name,
                                                            newValue
                                                        );
                                                    }}
                                                />
                                            </Grid>
                                        );
                                    } else {
                                        return (
                                            <Grid>
                                                <Grid
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        gap: '0.5rem',
                                                    }}
                                                >
                                                    <FWTypography
                                                        color={'text.secondary'}
                                                    >
                                                        {customField.name}{' '}
                                                        {!fieldSettingFromTemplate.is_required &&
                                                            '(optional)'}
                                                    </FWTypography>
                                                    {customField?.description && (
                                                        <FWTooltip
                                                            title={
                                                                customField?.description
                                                            }
                                                            placement="right"
                                                        >
                                                            <Box color="primary.main">
                                                                <i className="bi bi-question-circle" />
                                                            </Box>
                                                        </FWTooltip>
                                                    )}
                                                </Grid>

                                                <SingleFieldTypeRenderer
                                                    fieldType={
                                                        fieldSettingFromTemplate
                                                            ?.constraints
                                                            .field_type
                                                    }
                                                    constraints={{
                                                        fieldType:
                                                            CustomFieldType.CHOICE,
                                                        choices:
                                                            fieldSettingFromTemplate
                                                                ?.constraints
                                                                .choices ?? [],
                                                        choiceType:
                                                            'MULTI_SELECT',
                                                    }}
                                                    value={
                                                        customField.value as string[]
                                                    }
                                                    errors={
                                                        customField.is_required &&
                                                        (isEqual(
                                                            customField.value,
                                                            []
                                                        ) ||
                                                            !Boolean(
                                                                customField.value
                                                            ))
                                                    }
                                                    helper={{
                                                        text:
                                                            customField.is_required &&
                                                            (isEqual(
                                                                customField.value,
                                                                []
                                                            ) ||
                                                                !Boolean(
                                                                    customField.value
                                                                ))
                                                                ? 'Field is required'
                                                                : '',
                                                    }}
                                                    updateHandler={(
                                                        newValue: string[]
                                                    ) => {
                                                        handleCustomFieldUpdate(
                                                            customField,
                                                            section.name,
                                                            newValue
                                                        );
                                                    }}
                                                />
                                            </Grid>
                                        );
                                    }
                                default:
                                    return <></>;
                            }
                        })}
                    </Grid>
                );
            })}
        </>
    );
};

export default ItemCustomFields;
