import { Box, CircularProgress, Typography } from '@mui/material';
import { FWTooltip } from '../../../Common/FWCustomTooltip';

interface IDisplaySavedStatus {
    saved: boolean;
    loading?: boolean;
    error?: boolean;
    error_msg?: string;
}

export default function DisplaySavedStatus(props: IDisplaySavedStatus) {
    const { saved, loading, error, error_msg } = props;
    if (loading) {
        return (
            <Box
                className={'flex flex--aic'}
                color={'warning.main'}
                height={'1.875rem'}
            >
                <CircularProgress size={20} color={'warning'} />
            </Box>
        );
    }
    if (error) {
        return (
            <Box
                className={'flex flex--aic'}
                color={'error.main'}
                height={'1.875rem'}
            >
                <Typography>Error saving</Typography>
                &nbsp;
                <FWTooltip title={error_msg ?? ''}>
                    <i
                        className="bi bi-info-circle"
                        style={{ fontSize: '1.5rem' }}
                    ></i>
                </FWTooltip>
            </Box>
        );
    }
    if (saved) {
        return (
            <Box
                className={'flex flex--aic'}
                color={'success.main'}
                height={'1.875rem'}
            >
                <Typography>Changes saved</Typography>
                &nbsp;
                <i
                    className="bi bi-cloud-check"
                    style={{ fontSize: '1.5rem' }}
                ></i>
            </Box>
        );
    }
    return (
        <Box
            className={'flex flex--aic'}
            color={'warning.main'}
            height={'1.875rem'}
        >
            <Typography>Unsaved changes</Typography>
            &nbsp;
            <i className="bi bi-cloud-slash" style={{ fontSize: '1.5rem' }}></i>
        </Box>
    );
}
